<template>
    <div class="app-body"
         id="view">
        <image-assets-preloader></image-assets-preloader>

        <welcome-to-aloware></welcome-to-aloware>

        <div class="padding">
            <div class="padding card">
                <import-listing ref="importListing"/>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import ImportListing from '../components/import-wizard/import-listing'

export default {
    components: {
        ImportListing
    },

    data() {
        return {
            auth: auth
        }
    },

    mounted() {
        this.setTitle()
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Imports - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Imports - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        }
    },

    beforeRouteEnter(to, from, next) {
        if (to.query.api_token) {
            return next({name: 'Login', query: {api_token: to.query.api_token}})
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({name: 'Login', query: {redirect: to.fullPath}})
        })
    }
}
</script>
