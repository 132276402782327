var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "terms-conditions-dialog",
          attrs: {
            title: "Terms & Conditions",
            visible: _vm.terms_dialog,
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": _vm.is_impersonating,
          },
          on: {
            "update:visible": function ($event) {
              _vm.terms_dialog = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("h5", { staticClass: "mb-0" }, [
              _c("i", { staticClass: "fa fa-file-signature" }),
              _vm._v("\n                Terms & Conditions\n            "),
            ]),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-md _400 mb-1" }, [
            _vm._v(
              "By accepting this, you certify that you have read and will adhere to our\n            "
            ),
            _c(
              "a",
              {
                staticClass: "text-dark-greenish",
                attrs: { href: _vm.termsLink, target: "_blank" },
              },
              [_vm._v("\n                Terms & Conditions.\n            ")]
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-md _400" }, [
            _vm._v(
              "\n            We are electronically capturing your consent as of " +
                _vm._s(_vm.auth.user.profile.name) +
                " (" +
                _vm._s(_vm.auth.user.profile.email) +
                ") on " +
                _vm._s(_vm.date) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          !_vm.isSimpSocial
            ? _c("iframe", {
                staticClass: "terms-iframe mt-2 mb-2",
                attrs: {
                  sandbox: "allow-same-origin",
                  src: _vm.termsLink + "?embedded=1",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-checkbox",
            {
              staticClass: "checkbox-md mt-2",
              model: {
                value: _vm.terms_accepted,
                callback: function ($$v) {
                  _vm.terms_accepted = $$v
                },
                expression: "terms_accepted",
              },
            },
            [
              _vm._v(
                "\n            I have read and accept the terms and conditions\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", disabled: !_vm.terms_accepted },
                  on: { click: _vm.saveAgreeToTerms },
                },
                [_vm._v("\n                Agree\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }