var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c("div", { staticClass: "row p-relative mb-3" }, [
        _c(
          "div",
          { staticClass: "col-3" },
          [
            _c("inbound-connection-rate-widget", {
              ref: "inbound_connection_rate_widget",
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-9 h-100" },
          [_c("inbound-graph-widget")],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-3" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "card p-3 h-100",
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-3" },
                  [
                    _c("metric-widget", {
                      attrs: {
                        label: "Connected",
                        description: "Communications disposed as connected",
                        no_border: true,
                        is_card: true,
                        metric: _vm.metrics.inbound_call_disposition_connected,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-3" },
                  [
                    _c("metric-widget", {
                      attrs: {
                        label: "Busy",
                        description: "Communications disposed as busy",
                        no_border: true,
                        is_card: true,
                        metric: _vm.metrics.inbound_call_disposition_busy,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-3" },
                  [
                    _c("metric-widget", {
                      attrs: {
                        label: "No Answer",
                        description: "Communications disposed as no answer",
                        no_border: true,
                        is_card: true,
                        metric: _vm.metrics.inbound_call_disposition_no_answer,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-3" },
                  [
                    _c("metric-widget", {
                      attrs: {
                        label: "Left Live Message",
                        description:
                          "Communications disposed as left live message",
                        no_border: true,
                        is_card: true,
                        metric:
                          _vm.metrics
                            .inbound_call_disposition_left_live_message,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-3" },
                  [
                    _c("metric-widget", {
                      attrs: {
                        label: "Left Voicemail",
                        description:
                          "Communications disposed as left voicemail",
                        no_border: true,
                        is_card: true,
                        metric:
                          _vm.metrics.inbound_call_disposition_left_voicemail,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-3" },
                  [
                    _c("metric-widget", {
                      attrs: {
                        label: "Wrong Number",
                        description: "Communications disposed as wrong number",
                        no_border: true,
                        is_card: true,
                        metric:
                          _vm.metrics.inbound_call_disposition_wrong_number,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-9" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "row inbound-aggregates-wrapper",
            },
            [
              _c(
                "div",
                { staticClass: "col-md-2 mb-3" },
                [
                  _c("metric-widget", {
                    attrs: {
                      label: "Average Talk Time",
                      is_card: true,
                      format: "fixFullDuration",
                      metric: _vm.metrics.inbound_average_call_talk_time,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 mb-3" },
                [
                  _c("metric-widget", {
                    attrs: {
                      label: "Average Queue Hold Time",
                      is_card: true,
                      metric: _vm.metrics.inbound_average_queue_hold_time,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 mb-3" },
                [
                  _c("metric-widget", {
                    attrs: {
                      label: "Answered",
                      is_card: true,
                      metric: _vm.metrics.inbound_answered_calls,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 mb-3" },
                [
                  _c("metric-widget", {
                    attrs: {
                      label: "Missed",
                      is_card: true,
                      metric: _vm.metrics.inbound_missed_calls,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 mb-3" },
                [
                  _c("metric-widget", {
                    attrs: {
                      label: "Abandoned",
                      is_card: true,
                      metric: _vm.metrics.inbound_abandoned_calls,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 mb-3" },
                [
                  _c("metric-widget", {
                    attrs: {
                      label: "Voicemail",
                      is_card: true,
                      metric: _vm.metrics.inbound_voicemail_calls,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 mb-3" },
                [
                  _c("metric-widget", {
                    attrs: {
                      label: "Calls Above 2 Minutes",
                      description:
                        "The number of inbound calls that has a duration of more than 2 minutes.",
                      is_card: true,
                      metric: _vm.metrics.inbound_calls_above_two_minutes,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 mb-3" },
                [
                  _c("metric-widget", {
                    attrs: {
                      label: "Calls Below 30 Seconds",
                      description:
                        "The number of inbound calls that has a duration of less than 30 seconds.",
                      is_card: true,
                      metric:
                        _vm.metrics.inbound_calls_thirty_seconds_and_below,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 mb-3" },
                [
                  _c("metric-widget", {
                    attrs: {
                      label: "Untagged",
                      description:
                        "The number of total untagged inbound communications",
                      is_card: true,
                      metric: _vm.metrics.inbound_untagged,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-2 mb-3" },
                [
                  _c("metric-widget", {
                    attrs: {
                      label: "First Time",
                      description:
                        "The number of total first time communications",
                      is_card: true,
                      metric: _vm.metrics.inbound_first_time,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-3" },
          [_c("inbound-sms-rate-widget")],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex mb-3" }, [
      _c("i", {
        staticClass: "fa fa-phone text-greenish mr-2 align-self-center",
      }),
      _vm._v(" "),
      _c("h6", { staticClass: "mb-0 align-self-center" }, [
        _vm._v("Call Dispositions"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }