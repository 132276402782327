var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row b-b" }, [
      _c("div", { staticClass: "col-12 p-3" }, [_c("company-form-token")], 1),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 p-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("p", [
          _c("strong", [_vm._v("Example GET URL:")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "text-dark-greenish _600" }, [
            _vm._v(
              _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                "/api/v1/webhook/contact/phone-number?api_token=" +
                _vm._s(_vm.companyToken) +
                "&phone_number=8181234567"
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c(
          "pre",
          { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
          [
            _c("code", { staticClass: "json" }, [
              _vm._v('{\n    "error": "Phone number is empty or invalid."\n}'),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _c(
          "pre",
          { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
          [
            _c("code", { staticClass: "json" }, [
              _vm._v('{\n    "error": "Contact not found."\n}'),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        !_vm.loading_whitelabel
          ? _c(
              "pre",
              {
                directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
                key: _vm.renderRefreshToken,
                staticClass: "mb-3",
              },
              [
                _c("code", { staticClass: "bash" }, [
                  _vm._v(
                    "curl --location --request GET '" +
                      _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                      "/api/v1/webhook/contact/phone-number?api_token=" +
                      _vm._s(_vm.companyToken) +
                      "&phone_number=8181234567'"
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.statics.whitelabel
          ? _c("p", { staticClass: "_600" }, [
              _vm._v(
                "\n                    If you need more API functions, please contact our support at support@aloware.com.\n                "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2" }, [
      _c("strong", { staticClass: "integration-api-titles" }, [
        _vm._v("Lookup up a contact using phone number"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-md" }, [
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("GET /api/v1/webhook/contact/phone-number"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Required Query Parameters:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("api_token"),
      ]),
      _vm._v(",\n                    "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("phone_number"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 200 response if a contact is found with the contact information"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 400 on failure with a detailed response, Example failure response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 404 on failure with a detailed response, Example failure response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("cURL Example:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }