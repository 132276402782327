<template>
    <div class="filter-div white compact no-bottom-margin bold-label no-select width-230"
        :class="[is_bulk ? 'w-auto' : '']">
        <el-form v-if="!show_custom_date_range" 
                 data-testid="date-selector-form">
            <el-form-item>
                <el-select @change="changeDateRange"
                        @visible-change="closeAllMenus"
                        class="filter-select"
                        placeholder="Select Date Range"
                        popper-class="min-width-210"
                        data-testid="date-range-select"
                        v-model="date_range">
                    <el-option :key="index"
                            :label="item.value"
                            :value="item.value"
                            v-for="(item, index) in date_ranges">
                        <span>{{ item.label }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="d-flex justify-content-around pb-1"
            v-if="show_custom_date_range">
            <el-date-picker
                :key="prop_counter"
                @change="changeCustomDateRange"
                end-placeholder="End date"
                range-separator="-"
                start-placeholder="Start date"
                type="datetimerange"
                format="MMM/dd/yyyy h:mm:ss A"
                :default-time="['00:00:00', '23:59:59']"
                data-testid="custom-date-range-picker"
                v-model="custom_date_range"
            />
            <div class="px-1">
                <a data-testid="date-selector-link"
                   @click.prevent="clearCustomDateRange">
                    <span class="fa fa-close"></span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex"
    import {date_mixin} from "../mixins"
    import auth from '../auth'

    export default {
        name: "date-selector",

        mixins: [date_mixin],

        props: {
            default_date_range: {
                required: false,
                default: null
            },
            is_bulk: {
                required: false,
                default: false
            },
            disable_default_report_period: {
                required: false,
                default: false
            }
        },

        data() {
            return {
                auth: auth,
                date_ranges: [],
                date_range: '',
                custom_date_range: '',
                show_custom_date_range: false,
                prop_counter: 0
            }
        },

        computed: {
            ...mapGetters({
                filter: 'getFilter',
            }),

            ...mapState(['filter_options']),
            ...mapState('cache', ['current_company'])
        },

        created() {
            // if no date range from route query and default date range is set,
            // just assign the default date range to our date range
            if (this.default_date_range && !this.$route.query.from_date) {
                this.date_range = this.default_date_range
            } else {
                this.date_range = `${moment().tz(this.current_company.timezone).startOf('day').format('MM/DD/YYYY HH:mm:ss')} - ${moment().tz(this.current_company.timezone).endOf('day').format('MM/DD/YYYY HH:mm:ss')}`
            }

            VueEvent.listen('reset_date_range', () => {
                this.resetDateRange()
            })
            VueEvent.listen('change_date_range', (date_range) => {
                this.changeDateRange(date_range)
            })

            // initialize the date range options
            this.initializeOptions()

            // update the date range options ever 10 seconds
            this.$options.date_range_interval = setInterval(() => {
                this.initializeOptions()
            }, 10000)
        },

        mounted() {
            this.resetDateRange()
            let old_date_range = this.date_range
            // capture from_date and to_date if passed in route
            if (this.$route.query.from_date && this.$route.query.to_date) {
                this.filter.from_date = this.$route.query.from_date
                this.filter.to_date = this.$route.query.to_date
                this.date_range = moment(this.$route.query.from_date).startOf('day').format('MM/DD/YYYY HH:mm:ss') + ' - ' + moment(this.$route.query.to_date).endOf('day').format('MM/DD/YYYY HH:mm:ss')
            } else if (this.date_range) {
                // split the date and assign filter when mounted occur
                let date_from_to = this.date_range.split(' - ')
                if (date_from_to.length === 2) {
                    this.filter.from_date = moment(date_from_to[0], 'MM/DD/YYYY HH:mm:ss').startOf('day').format('YYYY-MM-DD HH:mm:ss')
                    this.filter.to_date = moment(date_from_to[1], 'MM/DD/YYYY HH:mm:ss').endOf('day').format('YYYY-MM-DD HH:mm:ss')
                } else if (this.date_range === 'All Time') {
                    this.filter.from_date = null
                    this.filter.to_date = null
                }
            }
            if (old_date_range != this.date_range) {
                this.changeDateRange(this.date_range)
            }
        },

        methods: {
            initializeOptions() {
                if (!this.current_company) {
                    return
                }

                const today = moment().tz(this.current_company.timezone);

                this.date_ranges = [{
                    value: 'Custom',
                    label: 'Custom'
                }, {
                    value: `${today.clone().startOf('day').format('MM/DD/YYYY HH:mm:ss')} - ${today.clone().endOf('day').format('MM/DD/YYYY HH:mm:ss')}`,
                    label: 'Today'
                }, {
                    value: `${today.clone().subtract(1, 'days').startOf('day').format('MM/DD/YYYY HH:mm:ss')} - ${today.clone().subtract(1, 'days').endOf('day').format('MM/DD/YYYY HH:mm:ss')}`,
                    label: 'Yesterday'
                }, {
                    value: `${today.clone().subtract(7, 'days').startOf('day').format('MM/DD/YYYY HH:mm:ss')} - ${today.clone().endOf('day').format('MM/DD/YYYY HH:mm:ss')}`,
                    label: 'Last 7 days'
                }, {
                    value: `${today.clone().subtract(30, 'days').startOf('day').format('MM/DD/YYYY HH:mm:ss')} - ${today.clone().endOf('day').format('MM/DD/YYYY HH:mm:ss')}`,
                    label: 'Last 30 Days'
                }, {
                    value: `${today.clone().startOf('month').format('MM/DD/YYYY HH:mm:ss')} - ${today.clone().endOf('month').format('MM/DD/YYYY HH:mm:ss')}`,
                    label: 'This Month So Far'
                }, {
                    value: `${today.clone().subtract(1, 'months').startOf('month').format('MM/DD/YYYY HH:mm:ss')} - ${today.clone().subtract(1, 'months').endOf('month').format('MM/DD/YYYY HH:mm:ss')}`,
                    label: 'Last Month'
                }, {
                    value: 'All Time',
                    label: 'All Time'
                }]
            },

            resetDateRange() {
                let no_change = false
                // in some pages, the date range resets to default report period;
                // we have to prevent the reset if from date exists in route query.
                // our default view for all reports is PAST 30 DAYS -- NOT one month.
                if (this.disable_default_report_period) {
                    this.date_range = 'All Time'
                    no_change = true
                } else if (
                    !this.$route.query.from_date &&
                    this.auth.user &&
                    this.auth.user.profile &&
                    this.auth.user.profile.default_report_period &&
                    this.current_company
                ) {
                    switch (this.auth.user.profile.default_report_period) {
                        case 'month':
                            this.date_range = `${moment().tz(this.current_company.timezone).subtract(30, 'days').startOf('day').format('MM/DD/YYYY HH:mm:ss')} - ${moment().tz(this.current_company.timezone).endOf('day').format('MM/DD/YYYY HH:mm:ss')}`
                            break
                        case 'week':
                            this.date_range = `${moment().tz(this.current_company.timezone).subtract(7, 'days').startOf('day').format('MM/DD/YYYY HH:mm:ss')} - ${moment().tz(this.current_company.timezone).endOf('day').format('MM/DD/YYYY HH:mm:ss')}`
                            break
                        case 'day':
                            this.date_range = `${moment().tz(this.current_company.timezone).startOf('day').format('MM/DD/YYYY HH:mm:ss')} - ${moment().tz(this.current_company.timezone).endOf('day').format('MM/DD/YYYY HH:mm:ss')}`
                            break
                    }
                    no_change = true
                    this.filter.changed = false
                } else if (this.$route.query.from_date) {
                    this.filter.from_date = this.$route.query.from_date
                    this.filter.to_date = this.$route.query.to_date
                    this.date_range = moment(this.$route.query.from_date).format('MM/DD/YYYY HH:mm:ss') + ' - ' + moment(this.$route.query.to_date).format('MM/DD/YYYY HH:mm:ss')
                } else if (this.filter.from_date && this.current_company) {
                    this.date_range = `${moment(this.filter.from_date).format('MM/DD/YYYY')} - ${moment(this.filter.to_date).format('MM/DD/YYYY')}`
                } else if (!this.filter.from_date) {
                    this.date_range = 'All Time'
                }
                this.changeDateRange(this.date_range + (no_change ? ' - nochange' : ''))
            },

            changeDateRange(date_range) {
                if (!date_range) {
                    return
                }
                if (date_range.includes("All Time")) {
                    this.filter.from_date = null
                    this.filter.to_date = null
                    if (!date_range.includes('nochange')) {
                        this.filter.changed = true
                    }
                    if (this.filter_options.is_bulk) {
                        this.$emit('updateCustomFilter', {
                            from_date: this.filter.from_date,
                            to_date: this.filter.to_date
                        })
                        // All Time should also trigger changes
                        this.filter_options.is_changed = !this.filter_options.is_changed
                    }
                    this.date_range = 'All Time'
                } else if (date_range == "Custom") {
                    // show chooser
                    this.show_custom_date_range = true
                } else {
                    let ranges = date_range.split(' - ')
                    const is_no_change = _.get(ranges, '2', null)
                    // this can be more than 2. One instance is when triggering
                    // the communication's reset filters. We expect that we should have 2 dates
                    // when we reach here but I added 1 more item which is a string ' - nochange'
                    // to be able to determine whether filter.changed should be set to true or false
                    // to prevent the communication filters button from changing color and showing
                    // the reset filters button since we're resetting the filters.
                    let from = null
                    let to = null
                    if (ranges.length >= 2) {
                        this.filter.from_date = moment(ranges[0], 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                        this.filter.to_date = moment(ranges[1], 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                        from = moment(ranges[0]).format('MM/DD/YYYY')
                        to = moment(ranges[1]).format('MM/DD/YYYY')

                        this.filter.changed = is_no_change ? false : true
                    }

                    if (ranges.length == 2) {
                        this.$emit('updateCustomFilter', {
                            from_date: this.filter.from_date,
                            to_date: this.filter.to_date
                        })
                        this.filter.changed = true
                        this.filter_options.is_changed = !this.filter_options.is_changed
                    }

                    // then remove nochange flag if it exists
                    if (is_no_change) {
                        ranges.splice(2, 1)
                    }

                    if (ranges.length >= 2) {
                        this.date_range = from + ' - ' + to
                    } else {
                        this.date_range = ranges.join(' - ')
                    }

                }
                this.$emit('clearDefaultDateRange')
            },

            closeAllMenus() {
                this.$emit('closeAllMenus')
            },

            changeCustomDateRange() {
                if (!this.custom_date_range || this.custom_date_range.length != 2) {
                    return
                }
                this.date_range = `${moment(this.custom_date_range[0]).format('MM/DD/YYYY HH:mm:ss')} - ${moment(this.custom_date_range[1]).format('MM/DD/YYYY HH:mm:ss')}`
                this.changeDateRange(this.date_range)
                // reset chooser data
                this.show_custom_date_range = false
                this.custom_date_range = ''
                this.prop_counter += 1
            },

            clearCustomDateRange() {
                this.date_range = `${moment().tz(this.current_company.timezone).subtract(30, 'days').startOf('day').format('MM/DD/YYYY HH:mm:ss')} - ${moment().tz(this.current_company.timezone).endOf('day').format('MM/DD/YYYY HH:mm:ss')}`

                this.changeDateRange(this.date_range)
                this.show_custom_date_range = false
                this.prop_counter += 1

                this.$emit('clearCustomDateRange')
            }
        },

        watch: {
            'filter.from_date': function (new_value) {
                if (this.filter.from_date != new_value) {
                    this.date_range = `${moment(this.filter.from_date).format('MM/DD/YYYY')} - ${moment(this.filter.to_date).format('MM/DD/YYYY')}`
                } else if(!this.filter.from_date && this.date_range != 'All Time') {
                    this.date_range = 'All Time'
                }
            },

            'filter.to_date': function (new_value) {
                if (this.filter.to_date != new_value) {
                    this.date_range = `${moment(this.filter.from_date).format('MM/DD/YYYY')} - ${moment(this.filter.to_date).format('MM/DD/YYYY')}`
                }
            },
        }
    }
</script>
