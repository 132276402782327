var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list campaign")
    ? _c(
        "div",
        [
          _c(
            "el-select",
            {
              class: _vm.computedClasses,
              attrs: {
                "data-testid": "campaign-selector",
                placeholder: _vm.computedPlaceholder,
                multiple: _vm.multiple,
                size: _vm.small ? "small" : "",
                disabled: _vm.disabled,
                clearable: _vm.clearable,
                "collapse-tags": _vm.collapsable,
                "default-first-option": "",
                filterable: "",
              },
              model: {
                value: _vm.campaign_id,
                callback: function ($$v) {
                  _vm.campaign_id = $$v
                },
                expression: "campaign_id",
              },
            },
            [
              _vm.activeCampaignsAlphabeticalOrder &&
              _vm.activeCampaignsAlphabeticalOrder.length > 0 &&
              _vm.show_active
                ? _c(
                    "el-option-group",
                    { key: "Active Lines", attrs: { label: "Active Lines" } },
                    _vm._l(
                      _vm.activeCampaignsAlphabeticalOrder,
                      function (campaign) {
                        return _c(
                          "el-option",
                          {
                            key: campaign.id,
                            attrs: {
                              label: campaign.name,
                              value: campaign.id,
                              disabled: _vm.shouldDisableOption(campaign),
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  disabled: !_vm.isMessagingBlocked(
                                    campaign,
                                    true
                                  ),
                                  content:
                                    "To send messages to the US, A2P 10DLC Brand and Campaign are required.",
                                  placement: "left",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "not-registered":
                                        _vm.isMessagingBlocked(campaign),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(campaign.name) +
                                        "\n                        "
                                    ),
                                    campaign.has_local_presence
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "label ml-2 p-1 text-xxs blue",
                                          },
                                          [
                                            _vm._v(
                                              "\n                            Has Local Presence\n                        "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pausedCampaignsAlphabeticalOrder &&
              _vm.pausedCampaignsAlphabeticalOrder.length > 0 &&
              _vm.show_paused
                ? _c(
                    "el-option-group",
                    { key: "Paused Lines", attrs: { label: "Paused Lines" } },
                    _vm._l(
                      _vm.pausedCampaignsAlphabeticalOrder,
                      function (campaign) {
                        return _c(
                          "el-option",
                          {
                            key: campaign.id,
                            attrs: { label: campaign.name, value: campaign.id },
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  disabled: !_vm.isMessagingBlocked(
                                    campaign,
                                    true
                                  ),
                                  content:
                                    "To send messages to the US, A2P 10DLC Brand and Campaign are required.",
                                  placement: "left",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "not-registered":
                                        _vm.isMessagingBlocked(campaign),
                                    },
                                  },
                                  [_vm._v(_vm._s(campaign.name))]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.blockedMessagingInformation["blocked"] &&
          (!_vm.blockedMessagingInformation["automated_only"] ||
            _vm.is_automated)
            ? _c("div", { staticClass: "not-registered-desc" }, [
                _c("p", { staticClass: "pb-4" }, [
                  _vm._v(_vm._s(_vm.blockedMessagingInformation["reason"])),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/account?tab=compliance",
                      "data-testid": "campaign-selector-register-now-button",
                    },
                  },
                  [_vm._v("\n           Register Now\n        ")]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }