export const CALL = 1
export const SMS = 2
export const CONFERENCE = 3
export const RVM = 4
// reserved types
export const EMAIL = 5
// social
export const FACEBOOK = 6
export const WHATSAPP = 7
export const FAX = 8
// user and system notes
export const NOTE = 10
export const SYSNOTE = 11
// appointment
export const APPOINTMENT = 12
// reminder
export const REMINDER = 13
