var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.is_loading,
          expression: "is_loading",
        },
      ],
    },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "container-listing px-0" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "container-body" }, [
            _c(
              "div",
              { staticClass: "row row-cols-3" },
              [
                _vm._l(_vm.usages, function (usage_group, i) {
                  return _vm._l(usage_group, function (usage, index) {
                    return _vm.shouldShowGauge(index)
                      ? _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("company-plan-usage-gauge", {
                              attrs: { usage: usage },
                            }),
                          ],
                          1
                        )
                      : _vm._e()
                  })
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container-header d-flex" }, [
      _c("div", { staticClass: "listing-heading" }, [
        _c("h3", { staticClass: "mb-0" }, [
          _vm._v("What's included in your plan"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }