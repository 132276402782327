<template>
  <div class="p-3">
    <div class="d-flex justify-content-center">
      <slot name="filters"></slot>
    </div>
    <iframe class="border-0 w-100 insights-iframe" frameborder="0" allowTransparency="true" :src="urlWithQuery"></iframe>
  </div>
</template>
<style scoped>
.insights-iframe{
  height: 100vh;
}
</style>
<script>
export default {
  name: 'insights-show',
  props: {
    url: {
      type: String,
      default: null
    },

    query: {
      type: Object,
      default: null
    }
  },

  computed: {
    urlWithQuery() {
      if (this.query) {
        const urlBase = this.url.split('#')[0]
        const parameters = new URLSearchParams(this.url.split('#')[1])
        let query = new URLSearchParams(this.query)
        query = this.formattedQuery(query)

        const keys = Object.keys(this.query).join(',')
        parameters.append('hide_parameters', keys)

        return this.url ? `${urlBase}?${query}#${parameters.toString()}` : ''
      } else {
        return this.url
      }
    }
  },

  methods: {
    formattedQuery (query) {
      Object.keys(this.query).forEach(key => {
        const value = this.query[key]
        if (Array.isArray(value)) {
          query.delete(key)
          value.forEach(param => {
            query.append(key, param)
          })
        }
      })

      return query.toString()
    }
  }
}
</script>
