<template>
    <el-select placeholder="Contact groups"
               v-model="target_contacts"
               class="no-select"
               :class="[ this.full_width ? 'w-full' : '']"
               filterable
               clearable>
        <el-option-group key="By Line"
                         label="By Line">
            <el-option v-for="campaign in activeCampaignsAlphabeticalOrder"
                       :key="campaign.id"
                       :label="campaign.name"
                       :value="'campaign:' + campaign.id">
                <span>{{ campaign.name }}</span>
            </el-option>
        </el-option-group>
        <el-option-group key="By Tag"
                         label="By Tag">
            <el-option v-for="tag in tagsAlphabeticalOrder"
                       :key="tag.id"
                       :label="tag.name"
                       :value="'tag:' + tag.id">
                <i class="fa fa-circle"
                   :style="{ color: tag.color }"></i>
                <span class="ml-1 text-grey-900">{{ tag.name }}</span>
            </el-option>
        </el-option-group>
        <el-option-group key="By Disposition Status"
                         label="By Disposition Status">
            <el-option v-for="disposition_status in disposition_statuses"
                       :key="disposition_status.id"
                       :label="disposition_status.name"
                       :value="'disposition_status:' + disposition_status.id">
                <template v-if="disposition_status.is_external !== undefined">
                    <i class="material-icons"
                       :style="{ color: disposition_status.color }"
                       v-if="disposition_status.is_external">lock</i>
                    <i class="material-icons"
                       :style="{ color: disposition_status.color }"
                       v-else>label</i>
                </template>
                <span class="ml-2">{{ disposition_status.name }}</span>
            </el-option>
        </el-option-group>
        <el-option-group key="Other options"
                         label="Other options">
            <el-option v-if="allow_all_contacts"
                       :key="(activeCampaignsAlphabeticalOrder) ? activeCampaignsAlphabeticalOrder.length + 1 : 1"
                       label="All contacts"
                       value="all">
                <span>All contacts</span>
            </el-option>
        </el-option-group>
    </el-select>
</template>

<script>
    import {mapState} from 'vuex'
    import {acl_mixin} from '../mixins'
    import auth from '../auth'

    export default {
        mixins: [acl_mixin],

        data() {
            return {
                auth: auth,
                target_contacts: this.value,
                loading_tags: false,
                tags: [],
            }
        },

        props: {
            value: {
                required: false,
            },

            full_width: {
                required: false,
                type: Boolean,
                default: false
            },

            allow_all_contacts: {
                type: Boolean,
                default: true
            },

            tag_category: {
                type: Number,
                required: false
            }
        },

        computed: {
            ...mapState(['campaigns', 'disposition_statuses']),

            activeCampaignsAlphabeticalOrder() {
                if (this.campaigns) {
                    let campaigns = _.clone(this.campaigns)
                    return campaigns
                        .filter(campaign => campaign.active == true)
                        .sort((a, b) => {
                            let textA = a.name.toUpperCase()
                            let textB = b.name.toUpperCase()
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                        })
                }

                return []
            },

            tagsAlphabeticalOrder() {
                let tags = this.tags

                if (tags) {
                    // if category is present, filter out the tags based on the category
                    if (this.tag_category) {
                        tags = tags.filter(tag => tag.category === this.tag_category)
                    }

                    return tags.sort((a, b) => {
                        let textA = a.name.toUpperCase()
                        let textB = b.name.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })
                }

                return []
            }
        },

        mounted() {
            this.getTags()
        },

        methods: {
            convertTargetContactsToAcceptableParams() {
                if (this.target_contacts.includes('campaign:')) {
                    return {
                        target_campaign_id: this.target_contacts.replace('campaign:', ''),
                    }
                }

                if (this.target_contacts.includes('tag:')) {
                    return {
                        target_tag_id: this.target_contacts.replace('tag:', ''),
                    }
                }

                if (this.target_contacts.includes('disposition_status:')) {
                    return {
                        target_disposition_status_id: this.target_contacts.replace('disposition_status:', ''),
                    }
                }

                return {}
            },

            getTags() {
                if (this.hasPermissionTo('list tag')) {
                    this.loading_tags = true
                    let params = {
                        full_load: true
                    }
                    return axios.get('/api/v1/tag', {params}).then(res => {
                        this.tags = res.data
                        this.loading_tags = false
                    }).catch(err => {
                        console.log(err)
                        this.loading_tags = false
                    })
                }
            }
        },

        watch: {
            value() {
                this.target_contacts = this.value
            },

            target_contacts(val) {
                if (this.value !== undefined && this.target_contacts !== this.value) {
                    this.$emit('change', val)
                }
            }
        }
    }
</script>
