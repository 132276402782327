<template>
    <div>
        <iframe style="width: 100%;min-height: 68vh;border: 0;"
                :src="source">
        </iframe>
    </div>
</template>

<script>
    export default {
        name: 'simpsocial-settings',

        data() {
            return {
                source: ''
            }
        },

        mounted() {
            axios.get('/integrations/simpsocial/source').then(res => {
                this.source = res.data.source
            }).catch(err => {
                console.log(err)
            })
        }
    }
</script>
