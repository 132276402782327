<template>
    <div class="app-body"
         id="view">
        <div class="row mb-0 mt-0">
            <div class="d-flex flex-fill flex-wrap p-2">
                <div class="d-flex flex-wrap flex-xl-nowrap flex-fill mr-auto">
                    <div class="mr-sm-2 flex-fill flex-xl-grow-0 search-toolbar">
                        <el-input ref="userSearch"
                                    placeholder="Search"
                                    prefix-icon="el-icon-search"
                                    clearable
                                    autofocus
                                    v-model="search_text"
                                    data-testid="user-search-input">
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="padding">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-header pb-0">
                            <div class="row">
                                <div class="col-3">
                                    <span class="pull-left font-120"
                                          v-if="filteredUsers">
                                        <strong>{{ filteredUsers.length || 0 }}</strong>
                                        {{ filteredUsers.length > 1 ? 'users' : 'user' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="box-body pt-0">
                            <div v-on:mouseleave="tableLeave">
                                <el-table
                                    v-loading="loading"
                                    :data="paginatedData"
                                    :default-sort="{prop: 'id', order: 'ascending'}"
                                    class="w-full"
                                    fit
                                    stripe
                                    @cell-mouse-enter="cellEnter">
                                    <el-table-column
                                        data-testid="users-name-column"
                                        prop="name"
                                        label="Name"
                                        :min-width="200"
                                        sortable >
                                        <template v-slot="scope">
                                            {{ scope.row.name || "-" }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        data-testid="users-name-column"
                                        prop="transcription_enabled"
                                        label="Transcription Status"
                                        :min-width="100" >
                                        <template v-slot="scope">
                                            <el-switch
                                                class="mr-4 align-self-center"
                                                v-model="scope.row.transcription_enabled"
                                                active-color="#00BF50"
                                                @change="handleTranscriptionStatusChange(scope.row, scope.row.transcription_enabled)">
                                            </el-switch>
                                        </template>
                                    </el-table-column>
                                    <template slot="empty"
                                              data-testid="no-data-users-template"
                                              class="text-center text-muted">
                                        <span v-if="!loading">No Data</span>
                                        <span v-if="loading"></span>
                                    </template>
                                </el-table>
                            </div>
                            <div class="mt-3"
                                 v-if="pageCount >= 1">
                                <el-pagination class="pull-right"
                                               background
                                               layout="sizes, prev, pager, next"
                                               :page-sizes="[10, 20, 50]"
                                               :page-size="pagination.per_page"
                                               :current-page.sync="pagination.current_page"
                                               :total="dataCount"
                                               data-testid="users-list-pagination"
                                               @current-change="changePage"
                                               @size-change="handleSizeChange">
                                </el-pagination>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import {paginator_mixin, scroll_mixin, styling_mixin, user_mixin} from '../../mixins'

export default {
    name: "account-transcription-user-enablement",
    mixins: [
        styling_mixin,
        paginator_mixin,
        scroll_mixin,
        user_mixin,
    ],

    data() {
        return {
            loading: false,
            search_fields: ['name'],
            search_text: '',
            pagination: {
                per_page: 10,
            },
            visible_row_id: null,
            user_filter: 'active',
            type_filter: 'Users',
            dataKey: 'filteredUsers',
            dataSort: 'id',
            dataSortType: 'ASC',
        }
    },

    computed: {
        ...mapState(['users']),

        visibleUsers() {
            if (this.users) {
                if (this.search_text != "") {
                    this.user_filter = 'all'
                    let filtered = []
                    for (let user of this.users) {
                        let keys = this.search_fields

                        for (let key of keys) {
                            if (key.indexOf('.') !== -1) {
                                let mixed = key.split(".")
                                if (user[mixed[0]]) {
                                    if (user[mixed[0]][mixed[1]].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                        filtered.push(user)
                                        break
                                    }
                                }
                            } else if (user[key]) {
                                if (user[key].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                    filtered.push(user)
                                    break
                                }
                            }
                        }
                    }
                    return filtered
                } else {
                    return this.users
                }
            }

            return []
        },

        filteredUsers() {
            // filter a specific user from a redirect
            if (this.$route.query.uid && this.$route.query.uid != '') {
                return this.visibleUsers.filter(user => +user.id === +this.$route.query.uid)
            }

            if (this.visibleUsers) {
                if (this.type_filter == 'Extensions') {
                    return this.visibleUsers.filter(user => user.is_destination)
                } else if (this.type_filter != 'Users') {
                    return this.visibleUsers.filter(user => this.inArray(this.type_filter, user.role_names) && !user.is_destination)
                } else if (this.type_filter == 'Users') {
                    return this.visibleUsers.filter(user => !user.is_destination)
                } else {
                    return this.visibleUsers
                }
            }

            return []
        },
    },

    mounted() {
        this.search_text = ''

        if (this.$route.query && typeof this.$route.query.status != 'undefined' && this.$route.query.status) {
            this.user_filter = this.allowed_statuses.includes(this.$route.query.status) ? this.$route.query.status : this.user_filter
        }

        if (this.$route.query && typeof this.$route.query.role != 'undefined' && this.$route.query.role) {
            this.type_filter = this.allowed_types.includes(this.$route.query.role.replace(/\s/g, "_").toLowerCase()) ? this.$route.query.role : this.type_filter
        }

        // focus user search input on page visit
        if (this.$refs.userSearch) {
            this.$refs.userSearch.focus()
        }
    },

    methods: {
        ...mapActions(['updateUser']),

        cellEnter: _.throttle(function (row, column, cell, event) {
            this.visible_row_id = row.id
        }, 50),

        tableLeave() {
            this.visible_row_id = null
        },

        handleTranscriptionStatusChange(user, status) {

            this.loading = true
            axios.post('/api/v1/user/' + user.id + '/toggle-transcription').then( res => {

                let completed_action = status ? 'enabled' : 'disabled'
                this.updateUser(res.data)

                this.$notify({
                    offset: 95,
                    title: 'User',
                    message: `Transcription successfully ${completed_action} for ${user.name}.`,
                    type: 'success'
                })

            }).catch(err => {
                this.$root.handleErrors(err.response)
            }).finally(() => {
                this.loading = false
            })

        },
    }
}
</script>
