<template>
    <div class="filter-attribution"
         v-on-clickaway="closeBroadcastList">
        <el-badge type="success"
                  class="filter-counter"
                  :value="broadcasts.length"
                  :hidden="broadcasts.length === 0">
            <el-button size="small"
                       round
                       plain
                       @click="toggleBroadcastList">
                Broadcasts
            </el-button>
        </el-badge>
        <div class="filter-attribution-data-list"
             v-if="show_broadcast_list">
            <div class="d-flex flex-row justify-content-end mb-2">
                <el-button size="small"
                           class="border-0" @click="closeBroadcastList">
                    Close
                </el-button>
            </div>
            <broadcast-selector class="w-100 filter-select"
                           place-holder="Filter Broadcasts"
                           :multiple="true"
                           :value="broadcasts"
                           @change="setTempBroadcasts">
            </broadcast-selector>
            <div class="d-flex flex-row justify-content-between mt-4">
                <el-button type="default" size="small" round @click="clear">
                    Clear
                </el-button>
                <el-button type="success" size="small" round @click="apply">
                    Apply
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import DashboardFilter from '../../../filters/dashboard-filter'
import {mixin as clickaway} from 'vue-clickaway'

export default {
    mixins: [clickaway],
    data() {
        return {
            show_broadcast_list: false,
            temp_broadcasts: []
        }
    },

    computed: {
        broadcasts() {
            return DashboardFilter.broadcasts()
        }
    },

    methods: {
        toggleBroadcastList() {
            this.show_broadcast_list = !this.show_broadcast_list
        },

        clear() {
            this.temp_broadcasts = []
            this.apply()
        },

        apply() {
            DashboardFilter.setBroadcasts(this.temp_broadcasts)
            this.show_broadcast_list = false
        },

        setTempBroadcasts(broadcasts) {
            this.temp_broadcasts = broadcasts
        },

        closeBroadcastList() {
            this.show_broadcast_list = false
        }
    }
}
</script>
<style lang="scss">
.filter-attribution {
    position: relative;
    z-index: 901;
}

.filter-counter sup.el-badge__content {
    top: 3px !important;
    right: 14px !important;
}

.filter-attribution-data-list {
    padding: 1rem;
    border: solid 1px #ddd;
    border-radius: 5px;
    position: absolute;
    top: 40px;
    left: 0;
    background-color: white;
    width: 20rem;
}
</style>
