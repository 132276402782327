<template>
    <div>
        <el-select v-model="selectedContacts"
                   class="w-full"
                   :placeholder="label"
                   :remote-method="fetchContacts"
                   :loading="loading"
                   filterable
                   remote
                   clearable
                   @change="selectContacts"
                   @clear="blur"
                   @blur="blur">
            <el-option
                v-for="contact in contacts"
                class="p-0"
                :key="contact.id"
                :label="contact.name || contact.phone_number"
                :value="contact.id">
                <div class="media">
                    <div class="media-body">
                        <label>{{ contact.name }}</label>
                        <small>{{ contact.phone_number | fixPhone }}</small>
                    </div>
                </div>
            </el-option>
        </el-select>
    </div>
</template>

<script>
    import {acl_mixin, validator_mixin} from '../mixins'

    export default {
        mixins: [acl_mixin, validator_mixin],

        props: ['label', 'contact_selected'],

        data() {
            return {
                loading: false,
                contacts: [],
                selectedContacts: null,
                source: null
            }
        },

        mounted() {
            // if there is a contact id pre selected
            // - fetch it and set
            if (this.contact_selected) {
                this.$emit('select-contacts', this.contact_selected)
            }
        },

        created() {
            this.CancelToken = axios.CancelToken
            this.source = this.CancelToken.source()
        },

        methods: {
            fetchContacts(query) {
                if (query.length >= 3) {
                    this.source.cancel('fetchContacts canceled by the user.')
                    this.source = this.CancelToken.source()
                    this.loading = true
                    const params = {
                        search_fields: ['name', 'email', 'phone_number'],
                        search_text: query
                    }
                    axios
                        .get('/api/v1/contact', {
                            params: params,
                            cancelToken: this.source.token
                        })
                        .then(res => {
                            this.loading = false
                            this.contacts = res.data.data
                        })
                        .catch(err => {
                            if (axios.isCancel(err)) {
                                console.log('Request canceled', err.message)
                            } else {
                                this.$root.handleErrors(err.response)
                                this.loading = false
                            }
                        })
                } else {
                    this.contacts = []
                }
            },

            selectContacts: function (selectedContactId) {
                this.$emit('select-contacts', _.find(this.contacts, {id: selectedContactId}))
            },

            reset: function () {
                this.selectedContacts = null
            },

            blur: function () {
                this.$emit('blur')
            }
        }
    }

</script>
