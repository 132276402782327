<template>
    <el-popover placement="right-start"
                width="220"
                trigger="click"
                v-model="show_popover">
        <el-form v-loading="loading">
            <el-form-item class="mb-3">
                <div scope="label"
                     class="form-label text-dark">
                    <h6 class="mb-2 text-black">Split tag</h6>
                    <p class="lh-1p4">
                        Split tag into smaller tags.
                    </p>
                </div>
                <div scope="label"
                     class="form-label text-dark">
                    <h6 class="mb-2 text-black">Page size:</h6>
                </div>
                <el-select v-model="option_selected">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <div>
                <p v-show="!allowSplit"
                   class="text-red">
                    Contact count is less than or equal to page size.
                </p>
                <el-button type="success"
                           size="small"
                           class="w-full"
                           @click="splitTag()"
                           :disabled="!allowSplit">
                    Split
                </el-button>
            </div>
        </el-form>
        <button class="btn btn-block btn-warning btn-sm text-white bg-deep-orange"
                slot="reference">
            <i class="fas fa-clone pull-left"></i>
            <slot>Split</slot>
        </button>
    </el-popover>
</template>

<script>
import auth from "../auth"

export default {
    name: "tag-splitter",

    props: {
        contacts_count: {
            type: Number,
            required: true
        },
        tag_id: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            options: [
                {
                    value: 50,
                    label: '50'
                },
                {
                    value: 100,
                    label: '100'
                },
                {
                    value: 200,
                    label: '200'
                },
                {
                    value: 500,
                    label: '500'
                },
                {
                    value: 1000,
                    label: '1000'
                }
            ],
            option_selected: 50,
            loading: false,
            show_popover: false
        }
    },

    computed: {
        allowSplit() {
            return this.contacts_count > this.option_selected
        }
    },

    methods: {
        splitTag() {
            if (this.allowSplit) {
                this.loading = true

                axios.post('/api/v1/tags/' + this.tag_id + '/split', {
                    page_size: this.option_selected
                }).then(res => {
                    this.loading = false
                    this.show_popover = false
                    switch (res.status) {
                        case 200:
                            this.$message({
                                message: 'Currently splitting the tag into smaller tags.',
                                type: 'info'
                            })
                            break
                        default:
                            this.$message({
                                message: res.data.message,
                                type: 'error'
                            })
                    }
                })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading = false
                    })
            }
        }
    },
}
</script>
