<template>
    <div class="w-full"
         v-if="hasPermissionTo('list disposition status')">
        <el-select v-model="call_disposition_id"
                   class="w-full"
                   v-if="communication"
                   :placeholder="placeholder"
                   :disabled="loading_call_disposition"
                   :size="size"
                   default-first-option
                   filterable
                   data-testid="has-permission-to-call-disposition-select"
                   @change="changeCallDisposition">
            <el-option
                v-for="call_disposition in callDispositionsAlphabeticalOrder"
                :key="call_disposition.id"
                :label="call_disposition.name"
                :value="call_disposition.id">
                <template>
                    <i class="material-icons"
                       :style="{ color: call_disposition.color }">
                        {{ call_disposition.is_external ? 'lock' : 'label' }}
                    </i>
                    <span class="ml-2">{{ call_disposition.name }}</span>
                </template>
            </el-option>
        </el-select>

        <el-select v-model="call_disposition_id"
                   class="w-full"
                   v-else
                   :placeholder="placeholder"
                   :multiple="multiple"
                   :clearable="clearable"
                   :collapse-tags="!noCollapse"
                   :disabled="loading_call_disposition"
                   :size="size"
                   default-first-option
                   filterable
                   data-testid="has-no-permission-to-call-disposition-select"
                   @change="selectCallDisposition">
            <el-option
                v-for="call_disposition in callDispositionsAlphabeticalOrder"
                :key="call_disposition.id"
                :label="call_disposition.name"
                :value="call_disposition.id">
                <i
                    class="material-icons"
                    :style="{ color: call_disposition.color }">
                    {{ call_disposition.is_external ? 'lock' : 'label' }}
                </i>
                <span class="ml-2">{{ call_disposition.name }}</span>
            </el-option>
        </el-select>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import auth from '../auth'
    import {acl_mixin} from '../mixins'

    export default {
        mixins: [acl_mixin],

        props: {
            communication: {
                required: false,
            },

            exclude: {
                required: false,
            },

            multiple: {
                type: Boolean,
                required: false,
                default: false
            },

            noCollapse: {
                type: Boolean,
                required: false,
                default: false
            },

            clearable: {
                type: Boolean,
                required: false,
                default: false
            },

            value: {
                required: false,
                default: null
            },

            size: {
                type: String,
                default: '',
                required: false
            }
        },

        data() {
            return {
                auth: auth,
                call_disposition_id: this.value,
                loading_call_disposition: false,
            }
        },

        computed: {
            ...mapState({
                call_dispositions: state => state.call_dispositions
            }),

            computedCommunication() {
                if (this.communication) {
                    return this.communication
                }

                return {
                    id: null,
                    call_disposition_id: null
                }
            },

            placeholder() {
                return 'Select Call Disposition'
            },

            availableDispositions() {
                if (this.call_dispositions) {
                    return this.call_dispositions.filter((call_disposition) => {
                        return call_disposition.id != this.exclude
                    })
                }

                return []
            },

            callDispositionsAlphabeticalOrder() {
                if (this.availableDispositions) {
                    let call_dispositions = _.clone(this.availableDispositions)
                    call_dispositions = call_dispositions.sort((a, b) => {
                        let textA = a.name.toUpperCase()
                        let textB = b.name.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })

                    return call_dispositions
                }

                return []
            },
        },

        created() {
            if (this.communication) {
                this.call_disposition_id = this.communication.call_disposition_id
            }
        },

        methods: {
            changeCallDisposition() {
                this.loading_call_disposition = true
                axios.post('/api/v1/communication/' + this.communication.id + '/dispose-call', {
                    call_disposition_id: this.call_disposition_id
                }).then((res) => {
                    this.loading_call_disposition = false
                    // Notify only if not in widget
                    if (!this.$root.$data.is_widget) {
                        this.$notify({
                            offset: 95,
                            title: 'Communication',
                            message: 'Call disposed',
                            type: 'success',
                            showClose: true,
                        })
                    }
                    this.$emit('callDisposed', res.data.call_disposition_id)
                }).catch((err) => {
                    this.loading_call_disposition = false
                    this.$root.handleErrors(err.response)
                })
            },

            selectCallDisposition(call_disposition) {
                this.call_disposition_id = call_disposition
                this.$emit('change', call_disposition)
            }
        },

        watch: {
            value() {
                this.call_disposition_id = this.value
            },

            'computedCommunication.call_disposition_id': function() {
                this.call_disposition_id = this.communication.call_disposition_id
                this.$emit('callDisposed')
            }
        }
    }
</script>
