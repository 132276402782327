var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c(
        "el-select",
        {
          staticClass: "w-full",
          attrs: {
            placeholder: _vm.placeholder,
            disabled: _vm.loading_activity_type,
            size: _vm.size,
            filterable: "",
          },
          on: { change: _vm.changeActivityType },
          model: {
            value: _vm.activity_type,
            callback: function ($$v) {
              _vm.activity_type = $$v
            },
            expression: "activity_type",
          },
        },
        _vm._l(_vm.activity_types, function (activity_type) {
          return _c(
            "el-option",
            {
              key: activity_type,
              attrs: { label: activity_type, value: activity_type },
            },
            [
              [
                _c("span", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(activity_type)),
                ]),
              ],
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }