export default Object.freeze([
    {
        label: "AloAi Enrollments",
        name: "aloai_enrollment"
    },
    {
        label: "Answering Machine Detections",
        name: "amd"
    },
    {
        label: "A2P Registration Fees",
        name: "a2p_registration_fees"
    },
    {
        label: "Credits",
        name: "credit"
    },
    {
        label: "Emails Sent",
        name: "email"
    },
    {
        label: "Faxes Sent/Received",
        name: "fax"
    },
    {
        label: "International Call Minutes",
        name: "intl_call"
    },
    {
        label: "International Text Messages",
        name: "intl_sms"
    },
    {
        label: "LRN Lookups",
        name: "lrn_lookup"
    },
    {
        label: "Local Minutes",
        name: "local_min"
    },
    {
        label: "Local Multimedia Messages",
        name: "local_mms"
    },
    {
        label: "Local Phone Numbers",
        name: "local_pn"
    },
    {
        label: "Local Text Messages",
        name: "local_sms"
    },
    {
        label: "Carrier fees for MMS messages",
        name: "mms_messages_carrierfees"
    },
    {
        label: "Promo Credits",
        name: "promo_credit"
    },
    {
        label: "Ringless Voicemails",
        name: "rvm"
    },
    {
        label: "ShortCode Multimedia Messages",
        name: "short_code_mms"
    },
    {
        label: "ShortCode Text Messages",
        name: "short_code_sms"
    },
    {
        label: "Carrier fees for SMS messages",
        name: "sms_messages_carrierfees"
    },
    {
        label: "Spam Lookups",
        name: "spam_lookup"
    },
    {
        label: "Toll-free Minutes",
        name: "tollfree_min"
    },
    {
        label: "Toll-free Local Multimedia Messages",
        name: "tollfree_mms"
    },
    {
        label: "Toll-free Phone Numbers",
        name: "tollfree_pn"
    },
    {
        label: "Toll-free Text Messages",
        name: "tollfree_sms"
    },
    {
        label: "Transcription Minutes",
        name: "transcription"
    },
    {
        label: "Users",
        name: "user"
    }
])
