<template>
    <div v-loading="loading"
         class="row">
        <div class="col-md-12"
             v-if="isBlockedFrom('line cnam')">
            <upgrade-now image-link="/assets/images/fomo/CNAM.png"
                         kb-link="https://support.aloware.com/en/articles/9037892-frequently-asked-questions-caller-id-cnam"
                         text="Setting a Caller ID is not included in your current plan. To use it, please contact us to upgrade today!"
                         title-text="Setting a Caller ID (CNAM)"
                         v-if="shouldShowUpgradeNow"
                         :default-click="true">
            </upgrade-now>
        </div>
        <template v-else>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                    <span class="page-anchor"
                          id="cnam_caller_id">
                    </span>
                        <h2 class="mb-2">Caller ID (CNAM) <span class="_300">(Optional)</span></h2>
                        <p>
                            <i class="fa mr-2"
                               :class="cnamRegistrationIconClass"></i>
                            <b>Set the Caller ID (CNAM) for this line</b>
                            <template v-if="cnamStatusData">
                            <span class="label text-xxs mx-1"
                                  :class="cnamStatusData.color">
                                {{ cnamStatusData.status }}
                            </span>
                            </template>
                        </p>
                        <p v-if="cnamStatusData.message && standardBusinessProfileApproved" class="text-grey _400 ml-4 pl-1">{{ cnamStatusData.message }}</p>
                        <p v-if="cnamStatusData.failure" class="text-danger _400 ml-4 pl-1"><span class="text-black">Reasons: </span>{{ cnamStatusData.failure }}</p>
                    </div>
                </div>
                <template v-if="current_company && current_company.registered_customer_profile">
                    <div class="ml-4 pl-1">
                        <div class="row">
                            <div class="col-md-12">
                                <p>Caller ID (CNAM) is a personal or business name associated with this line that displays on a contact's phone when you call them.</p>
                            </div>
                        </div>
                        <el-form ref="compliance_cnam_form"
                                 v-loading="cnam_form_loading"
                                 label-position="top"
                                 :model="cnam_form"
                                 :rules="cnam_form_rules">
                            <div class="row">
                                <div class="col-md-12">
                                    <el-form-item label="Caller ID"
                                                  class="mb-2 pb-0 border-0"
                                                  prop="caller_id">
                                        <el-input type="input"
                                                  :disabled="disableCnamInputs || viewOnly"
                                                  :maxlength="15"
                                                  @input="preValidateForm('compliance_cnam_form')"
                                                  v-model="cnam_form.caller_id">
                                        </el-input>
                                        <span class="_300"
                                           v-if="!disableCnamInputs && nameCharCounter">
                                            {{ nameCharCounter }}
                                        </span>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mb-2" :class="{'mt-3': nameCharCounter === ''}">
                                        <b class="_700">Notes:</b>
                                    </div>
                                    <ul>
                                        <li>CNAM should begin with a letter and can only contain letters, numbers, periods, commas and spaces.</li>
                                        <li>Caller ID (CNAM) must be relevant to your business and business name; No impersonation allowed</li>
                                        <li>Caller ID (CNAM) can take up to 48 hours to appear</li>
                                        <li>Some carriers may not show this Caller ID</li>
                                        <li>Some mobile numbers may need to turn a setting on or subscribe to see Caller IDs</li>
                                        <li>No guarantee this Caller ID will work for Canada or Puerto Rico numbers</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-left">
                                    <el-button
                                        :disabled="disableCnamInputs || viewOnly"
                                        type="primary"
                                        class="ml-auto w-full"
                                        @click="validateCnamForm">
                                        <strong>{{ cnamSubmitButtonText }}</strong>
                                    </el-button>
                                </div>
                                <div class="col-md-12 text-left pt-2">
                                    <el-button
                                        :disabled="!canDeleteBundle || cnam_delete_loading"
                                        type="danger"
                                        class="ml-auto w-full"
                                        @click="deleteCnam">
                                        <strong>Delete</strong>
                                    </el-button>
                                </div>
                            </div>
                        </el-form>
                        <div class="row mt-2"
                             v-if="caller_id && caller_id.submitted_at">
                            <div class="col-md-12">
                                <p class="_500">Last Submitted {{ caller_id.submitted_at | fixFullDayDateUTC }}</p>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="row mt-3 mb-3">
                    <div class="col-12">
                        <hr>
                    </div>
                </div>
                <div class="row mt-3 mb-3">
                    <div class="col-md-12 status-background">
                    <span class="page-anchor"
                          id="status_legends">
                    </span>
                        <h5 class="text-black mt-3">
                            Status Explanations:
                        </h5>
                        <div class="row">
                            <div class="col-md-2">
                                <p><span class="label text-xxs mx-2 danger">Unsubmitted</span></p>
                            </div>
                            <div class="col-md-10">
                                <p>If Unsubmitted - You need to fill out and submit the form.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <p><span class="label text-xxs mx-2 blue">Pending</span></p>
                            </div>
                            <div class="col-md-10">
                                <p>If Pending - May take up to 48 hours to process.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <p><span class="label text-xxs mx-2 green">Approved</span></p>
                            </div>
                            <div class="col-md-10">
                                <p>
                                    If Approved - Your request is approved and no further action is required.
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <p><span class="label text-xxs mx-2 danger">Failed</span></p>
                            </div>
                            <div class="col-md-10">
                                <p>If Failed - Our Carrier will provide a rejection reason. Action needed.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 d-none d-md-block ml-auto">
                <div class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                    <h6 class="list-group-item">
                        On This Page
                    </h6>
                    <a href="#cnam_caller_id"
                       class="list-group-item list-group-item-action">
                        CNAM Caller ID
                    </a>
                    <a href="#status_legends"
                       class="list-group-item list-group-item-action">
                        Status Explanations
                    </a>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {
    acl_mixin,
    compliance_mixin,
    form_handler,
    form_validation_mixin,
    kyc_mixin
} from '../../../mixins'
import * as TrustBundleStatus from "../../../constants/trust-bundle-status"
import UpgradeNow from "../../../components/upgrade-now.vue"
import _ from "lodash";

export default {
    name: "compliance",

    components: {UpgradeNow},

    mixins: [
        acl_mixin,
        compliance_mixin,
        form_handler,
        form_validation_mixin,
        kyc_mixin
    ],

    props: {
        campaign_id: {
            required: true
        },

        caller_id: {
            required: false
        }
    },

    data() {
        return {
            loading: false,
            disabled: false,
            cnam_form_loading: false,
            cnam_delete_loading: false,
            cnam_form: {
                caller_id: null,
            },
            cnam_form_rules: {
                caller_id: {
                    required: true,
                    trigger: 'change',
                    message: 'Please provide a caller id'
                },
            },
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
        }),

        cnamRegistrationIconClass() {
            if (this.caller_id) {
                if (this.caller_id.bundle_status === TrustBundleStatus.BUNDLE_STATUS_APPROVED) {
                    return ['fa-check', 'text-green']
                }
                if ([TrustBundleStatus.BUNDLE_STATUS_IN_REVIEW, TrustBundleStatus.BUNDLE_STATUS_PENDING].includes(this.caller_id.bundle_status)) {
                    return ['fa-clock-o', 'text-grey']
                }
            }

            return ['fa-times', 'text-red']
        },

        nameCharCounter() {
            // find how many characters are left
            const length = this.cnam_form.caller_id ? this.cnam_form.caller_id.length : 0
            const left = 15 - length
            switch (15 - length) {
                case 0:
                    return 'No characters left'
                case 1:
                    return '1 character left'
                case 15:
                    return ''
                default:
                    return `${left} characters left`
            }
        },

        disableCnamInputs() {
            return this.disabled || (this.caller_id && [TrustBundleStatus.BUNDLE_STATUS_APPROVED, TrustBundleStatus.BUNDLE_STATUS_IN_REVIEW, TrustBundleStatus.BUNDLE_STATUS_PENDING].includes(this.caller_id.bundle_status))
        },

        canDeleteBundle() {
            return this.caller_id && this.caller_id.bundle_status === TrustBundleStatus.BUNDLE_STATUS_APPROVED
        },

        cnamSubmitButtonText() {
            return this.caller_id && [TrustBundleStatus.BUNDLE_STATUS_DRAFT, TrustBundleStatus.BUNDLE_STATUS_REJECTED].includes(this.caller_id.bundle_status) ? 'Re-Submit' : 'Submit'
        },
    },

    mounted() {
        // Prepare cnam caller id form
        this.resetCnamForm()
    },

    methods: {
        resetCnamForm(force = false) {
            if (force) {
                this.cnam_form = {
                    caller_id: null,
                }
                return
            }
            this.cnam_form = {
                caller_id: this.caller_id ? this.caller_id.display_name : null,
            }
        },

        validateCnamForm: _.debounce(function () {
            this.preValidateForm('compliance_cnam_form')
            this.saveCnamForm()
        }, 100),

        saveCnamForm() {
            // If form have invalid fields, return
            if (!this.validated) {
                return
            }
            // go to loading state
            this.cnam_form_loading = true
            // Proceed
            axios.post('/api/v1/campaign/' + this.campaign_id + '/cnam-caller-id', this.cnam_form)
                .then(res => {
                    this.cnam_form_loading = false
                    this.disabled = true
                    this.$notify({
                        offset: 95,
                        title: 'Caller ID',
                        message: res.data.message,
                        type: 'success',
                        showClose: true
                    })
                })
                .catch(err => {
                    this.cnam_form_loading = false
                    this.$root.handleErrors(err.response)
                })
        },

        deleteCnam() {
            if (_.isEmpty(this.caller_id)) {
                return
            }
            // go to loading state
            this.cnam_delete_loading = true
            // Proceed
            axios.delete('/api/v1/campaign/' + this.campaign_id + '/cnam-caller-id')
                .then(res => {
                    this.cnam_delete_loading = false
                    this.disabled = false
                    this.$emit('CNAM-deleted')
                    this.resetCnamForm(true)
                    this.$notify({
                        offset: 95,
                        title: 'Caller ID',
                        message: res.data.message,
                        type: 'success',
                        showClose: true
                    })
                })
                .catch(err => {
                    this.cnam_delete_loading = false
                    this.$root.handleErrors(err.response)
                })
        },
    }
}
</script>

<style scoped>

</style>
