<template>
    <div class="row">
        <div class="col-xs-12 col-xl-8 p-3 mx-auto">
            <div class="text-center">
                <el-button type="success"
                           class="mb-3"
                           @click="testRingGroup">
                    <i class="material-icons loader"
                       v-if="loading_test">
                        &#xE863;
                    </i>
                    <i class="material-icons"
                       v-else>refresh
                    </i>
                    Refresh
                </el-button>
            </div>
            <div v-loading="loading_test">
                <div>
                    <div class="media mx-auto w-50"
                         v-if="available_users_count">
                        <i class="fas fa-phone mr-2 color-999" style="font-size: 40px"></i>
                        <div class="media-body">
                            <p v-if="ring_group.dial_mode === RingGroupDialMode.DIAL_MODE_SIMUL"
                               class="break-word">
                                All incoming calls to <strong>{{ ring_group.name }}</strong> will ring all available
                                users in the first layer below.
                            </p>
                            <p v-if="ring_group.dial_mode === RingGroupDialMode.DIAL_MODE_RAND"
                               class="break-word">
                                All incoming calls to <strong>{{ ring_group.name }}</strong> will randomly ring one
                                available user in the first layer below.
                            </p>
                            <p v-if="ring_group.dial_mode === RingGroupDialMode.DIAL_MODE_ROUND"
                               class="break-word">
                                All incoming calls to <strong>{{ ring_group.name }}</strong> will ring the first user
                                available in the first layer below and continues dialing in a round-robin fashion.
                            </p>
                            <p v-if="ring_group.dial_mode === RingGroupDialMode.DIAL_MODE_LONGEST_AVAILABLE"
                               class="break-word">
                                All incoming calls to <strong>{{ ring_group.name }}</strong> will ring the longest available user
                                available in the first layer below and continues dialing based on that.
                            </p>
                        </div>
                    </div>
                    <div class="media mx-auto w-50 text-danger"
                         v-else>
                        <i class="fas fa-phone-slash mr-2" style="font-size: 40px"></i>
                        <div class="media-body">
                            <p>
                                This ring group does not have any eligible agents to take call. See below.
                            </p>
                        </div>
                    </div>
                    <div class="text-center">
                        <div v-if="available_users_count">
                            <p class="mb-0">Number of available users: {{ available_users_count }}</p>
                        </div>
                        <div>
                            <p class="mb-0">Time of test: {{ new Date() | momentFormat('MM/DD h:mma z', true) }}</p>
                        </div>
                    </div>
                </div>
                <ring-group-routing-display :ring_group="ring_group"
                                            :layers="layers"
                                            v-if="layers">
                </ring-group-routing-display>

                <p class="text-center">
                    Please note that this utility does not look into contact / communication ownership.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../../auth'
import * as RingGroupDialMode from '../../constants/ring-group-dial-modes'
import RingGroupRoutingDisplay from './ring-group-routing-display'

export default {
    name: "ring-group-routing-test",

    props: ['ring_group'],

    components: {RingGroupRoutingDisplay},

    data() {
        return {
            auth: auth,
            loading_test: false,
            layers: [],
            RingGroupDialMode
        }
    },

    computed: {
        available_users_count() {
            
            let count = 0
            const unique_users = this.getUniqueUsers(this?.layers)
            
            unique_users.forEach( (user) => {
                if (user.agent_is_eligible_to_take_call) {
                    count++
                }
            })
            
            return count
        }
    },

    mounted() {
        this.testRingGroup()
    },

    methods: {
        testRingGroup() {
            this.loading_test = true

            axios.get(`/api/v1/diagnosis/ring-group/${this.ring_group.id}`).then(res => {
                this.loading_test = false
                this.layers = res.data
            }).catch(err => {
                console.log(err)
            })
        },

        getUniqueUsers(layers = []) {
            let unique_users = []

            layers.forEach((layer) => {
                const {user_results = [], team_users_results = []} = layer
            
                user_results.forEach( (user) => {
                    if (!unique_users.find(uniqueUser => uniqueUser.model.id === user.model.id)) {
                        unique_users.push(user)
                    }
                })

                team_users_results.forEach(function (team) {
                    const { users = [] } = team
                    users.forEach( (user) => {
                        if (!unique_users.find(uniqueUser => uniqueUser.model.id === user.model.id)) {
                            unique_users.push(user)
                        }
                    })
                })
            })
            
            return unique_users
        }
    }
}
</script>
