var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "custom-message-display",
      attrs: { "data-testid": "custom-message-display-wrapper" },
    },
    [
      _vm._v("\n    " + _vm._s(_vm.config.prefix) + "\n    "),
      _vm.config.showLink
        ? _c("router-link", { attrs: { to: _vm.config.linkRoute } }, [
            _c("u", { attrs: { "data-testid": "config-link-text" } }, [
              _vm._v(_vm._s(_vm.config.linkText)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "suffix",
          class: { "negative--margin": _vm.shouldUseNegativeMargin },
          attrs: { "data-testid": "config-suffix" },
        },
        [_vm._v("\n        " + _vm._s(_vm.config.suffix) + "\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }