<template>
    <div data-testid="communcation-audio-wrapper">
        <el-popover trigger="click"
                    popper-class="v-c"
                    v-model="isOpen"
                    v-if="!isDeleted && hasAudio"
                    :placement="popoverDirection"
                    :open-delay="100"
                    :append-to-body="true"
                    data-testid="communication-audio-popover"
                    @show="onShow"
                    @hide="onHide">
            <el-button slot="reference"
                       size="small"
                       data-testid="communication-audio-play-button"
                       type="text text-dark-greenish m-0 p-0">
                <i class="fa fa-play"></i>
                <span class="ml-1">{{ title }}</span>
            </el-button>

            <template v-if="remoteUrl && isOpen">
                <div class="d-flex flex-column width-300 fixed">
                    <div class="row">
                        <div class="col-12">
                            <waveform :remote_url="remoteUrl"></waveform>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <a class="btn btn-block btn-primary _600"
                               title="Download"
                               target="_blank"
                               role="button"
                               :href="downloadUrl"
                               download
                               data-testid="communication-audio-download-button"
                               @click="blur">
                                Download recording
                            </a>
                        </div>
                    </div>
                </div>
            </template>
        </el-popover>

        <span class="text-grey-900" v-if="!isDeleted && !hasAudio">-</span>
        <span class="text-grey-900" v-if="isDeleted">record was deleted</span>
    </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin} from '../mixins'
import * as UploadedFileTypes from '../constants/uploaded-file-types'
import Waveform from './waveform'

export default {
    mixins: [acl_mixin],

    components: {Waveform},

    props: {
        communication: {
            required: true
        },

        type: {
            required: true
        },

        popoverDirection: {
            required: false,
            type: String,
            default: 'bottom-start'
        }
    },

    data() {
        return {
            auth: auth,
            isOpen: false,
            remoteUrl: null,
            downloadUrl: null,
            UploadedFileTypes
        }
    },

    computed: {
        hasAudio() {
            return (this.type === this.UploadedFileTypes.TYPE_CALL_RECORDING) ? this.communication.has_recording : this.communication.has_voicemail
        },

        isDeleted() {
            return (this.type === this.UploadedFileTypes.TYPE_CALL_RECORDING) ? this.communication.recording_is_deleted : false
        },

        typeString() {
            return (this.type === this.UploadedFileTypes.TYPE_CALL_RECORDING) ? 'Recording' : 'Voicemail'
        },

        title() {
            return 'Play ' + this.typeString
        }
    },

    methods: {
        onHide() {
            this.remoteUrl = null
            this.downloadUrl = null
        },

        onShow() {
            if (this.hasAudio) {
                this.remoteUrl = null
                this.downloadUrl = null
                let options = {
                    params: {
                        type: this.type
                    }
                }
                axios.get(`/api/v1/communication/${this.communication.id}/file-url`, options).then((response) => {
                    this.remoteUrl = response.data.url
                    this.downloadUrl = response.data.download_url
                })
            }
        },

        closePopover() {
            this.isOpen = false
        },

        blur($event) {
            $event.target.blur()
            setTimeout(() => {
                this.closePopover()
            }, 25)
        },
    }
}
</script>
