// 'US', 'CA', 'PR', 'AS', 'GU'
export const TIER_1 = 1

// 'AU', 'AT', 'BE', 'CY', 'DK', 'FI', 'FR',
// 'DE', 'GR', 'HK', 'IS', 'IE', 'IL', 'IT',
// 'JP', 'LU', 'MX', 'NL', 'NZ', 'NO', 'PT',
// 'SG', 'ES', 'SE', 'CH', 'GB', 'MT',
export const TIER_2 = 2

// 'BR', 'BG', 'CN', 'CO', 'CR', 'HR', 'CZ',
// 'EE', 'IN', 'IM', 'LT', 'MD', 'NG', 'PH',
// 'PL', 'RO', 'SA', 'SK', 'SI', 'ZA', 'QA',
// 'TR', 'AE',
export const TIER_3 = 3

// 'AR', 'BB', 'BJ', 'BA', 'KY', 'CL', 'DO', 'EC',
// 'SV', 'GH', 'GD', 'HU', 'ID', 'JM', 'KE', 'MO',
// 'ML', 'MU', 'PA', 'LK', 'TH', 'TT', 'TN', 'UG',
// 'UY', 'AF', 'AL', 'DZ', 'AD', 'AO', 'AG', 'AM',
// 'AZ', 'BS', 'BH', 'BD', 'BZ', 'BT', 'BO', 'BW',
// 'BN', 'BF', 'BI', 'KH', 'CM', 'TD', 'KM', 'CD',
// 'CG', 'CI', 'DJ', 'DM', 'EG', 'GQ', 'ER', 'ET',
// 'FJ', 'GA', 'GM', 'GE', 'GT', 'GN', 'GW', 'GY',
// 'HT', 'HN', 'IQ', 'JO', 'KZ', 'KI', 'XK', 'KW',
// 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LI', 'MG',
// 'MW', 'MY', 'MV', 'MH', 'MR', 'FM', 'MC', 'MN',
// 'ME', 'MA', 'MZ', 'NA', 'NR', 'NP', 'NI', 'NE',
// 'MK', 'OM', 'PK', 'PW', 'PS', 'PG', 'PY', 'PE',
// 'QA', 'RW', 'KN', 'LC', 'VC', 'SM', 'SN', 'RS',
// 'SC', 'SL', 'SB', 'SO', 'KR', 'SS', 'SR', 'SZ',
// 'SY', 'TW', 'TJ', 'TZ', 'TL', 'TG', 'TO', 'TR',
// 'TM', 'UA', 'AE', 'UZ', 'VU', 'VN', 'ZM', 'ZW',
export const TIER_4 = 4
