<template>
    <div v-if="hasPermissionTo('send fax')">
        <!--send a new fax-->
        <el-dialog title="Send a Fax"
                   class="dialog-padding auto"
                   :width="isLargeEnough ? '500px' : '100%'"
                   :visible.sync="hide_add"
                   :before-close="beforeCloseModal"
                   append-to-body>
            <el-form ref="send_fax"
                     class="p-0"
                     label-position="top"
                     :rules="rules_fax"
                     :model="fax"
                     @submit.prevent.native="sendFax">
                <el-form-item prop="campaign_id"
                              label="Line">
                    <campaign-selector select_style="width: 100%;"
                                       v-model="fax.campaign_id"
                                       :disabled="loading_btn"
                                       :is_fax="true"
                                       @change="changeCampaignId">
                    </campaign-selector>
                </el-form-item>
                <el-form-item prop="phone_number"
                              label="Phone Number">
                    <el-input v-model="fax.phone_number"
                              :disabled="loading_btn"
                              @input="preValidateForm('send_fax')">
                    </el-input>
                </el-form-item>
                <el-form-item prop="file_name">
                    <el-upload drag
                               :action="'/api/v1/campaign/' + fax.campaign_id + '/upload/pdf'"
                               :headers="headers"
                               :on-success="onSuccessSendFax"
                               :on-error="onFailedSendFax"
                               :on-progress="progressUploadSendFax"
                               :before-upload="beforeUploadSendFax"
                               :file-list="uploadFileList.upload"
                               :multiple="false"
                               :disabled="fax.campaign_id === null || loading_btn">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                        </div>
                        <div class="el-upload__tip"
                             slot="tip">
                            PDF file (less than 600 KB)
                        </div>
                    </el-upload>
                    <el-progress :text-inside="true"
                                 :stroke-width="18"
                                 :percentage="uploadPercentage.upload"
                                 :status="uploadStatus.upload"
                                 v-if="fax.file_name === null">
                    </el-progress>
                </el-form-item>
            </el-form>

            <div class="row pb-3">
                <div class="col-12">
                    <el-button type="success"
                               :disabled="loading_btn || !validated"
                               class="pull-right ml-2"
                               @click="sendFax">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="material-icons"
                           v-show="!loading_btn">
                            send
                        </i>
                        <span>Send</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <slot name="trigger">
            <el-button slot="reference"
                       type="text text-sm text-dark-greenish"
                       @click="hide_add = true">
                <i class="el-icon-circle-plus-outline"></i>
                New Message
            </el-button>
        </slot>
    </div>
</template>

<script>
    import auth from '../auth'
    import {
        acl_mixin,
        validator_mixin,
        form_validation_mixin
    } from '../mixins'
    import {mapState} from 'vuex'
    import VariableDialog from "./variable-dialog"
    import TemplateListDialog from "./template-list-dialog"

    export default {
        components: {TemplateListDialog, VariableDialog},

        mixins: [
            acl_mixin,
            validator_mixin,
            form_validation_mixin
        ],

        data() {
            return {
                auth: auth,
                loading_btn: false,
                hide_add: false,
                fax: {
                    campaign_id: null,
                    phone_number: null,
                    file_name: null,
                    message: null
                },
                rules_fax: {
                    campaign_id: [
                        {
                            required: true,
                            message: 'Please select a campaign',
                            trigger: 'blur'
                        }
                    ],
                    phone_number: [
                        {
                            required: true,
                            message: 'Please provide a phone number',
                            trigger: 'blur'
                        },
                        {
                            required: true,
                            validator: this.phoneValidator,
                            trigger: 'blur'
                        },
                    ],
                    file_name: [
                        {
                            required: true,
                            message: 'Please upload your PDF file',
                            trigger: 'change'
                        }
                    ]
                },
                uploadPercentage: {
                    import: 0,
                    upload: 0
                },
                uploadStatus: {
                    import: 'success',
                    upload: 'success'
                },
                uploadFileList: {
                    upload: []
                },
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + auth.user.profile.api_token,
                    'X-Socket-Id': window.Echo.socketId()
                }
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            isLargeEnough() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            }
        },

        methods: {
            onSuccessSendFax(res) {
                this.$notify({
                    offset: 95,
                    title: 'PDF Upload',
                    message: 'PDF file has been uploaded successfully.',
                    type: 'success',
                    showClose: true,
                })
                this.$set(this.fax, 'file_name', res.file_name)
                this.uploadStatus.upload = 'success'
                this.preValidateForm('send_fax')
            },

            onFailedSendFax(err) {
                this.$root.handleUploadErrors(err.message)
                this.uploadStatus.upload = 'exception'
                this.uploadPercentage.upload = 0
                this.preValidateForm('send_fax')
            },

            beforeUploadSendFax() {
                this.uploadStatus.upload = 'success'
                this.uploadPercentage.upload = 0
            },

            progressUploadSendFax(event) {
                this.uploadPercentage.upload = parseInt(event.percent)
            },

            changeCampaignId(val) {
                this.fax.campaign_id = val
                this.preValidateForm('send_fax')
            },

            sendFax() {
                if (this.validateForm('send_fax')) {
                    this.loading_btn = true
                    axios.post('/api/v1/campaign/send-fax-to-phone-number/' + this.fax.campaign_id, this.fax)
                        .then(res => {
                            this.loading_btn = false
                            this.hide_add = false
                            this.$emit('success', res.data)
                            this.resetFax()
                            this.$notify({
                                title: 'Fax',
                                message: 'Fax has been successfully sent.',
                                type: 'success',
                                showClose: true
                            })
                        })
                        .catch(err => {
                            this.$root.handleErrors(err.response)
                            this.loading_btn = false
                        })
                }
            },

            resetFax() {
                this.fax = {
                    campaign_id: null,
                    phone_number: null,
                    file_name: null
                }
            },

            beforeCloseModal(done) {
                if (this.fax.campaign_id || this.fax.phone_number || this.fax.file_name) {
                    this.$confirm('Are you sure you want to leave?', 'Warning', {
                        confirmButtonText: 'Yes, Leave',
                        cancelButtonText: 'No, Stay',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(res => {
                        this.resetFax()
                        done()
                    }).catch(() => {

                    })
                } else {
                    this.resetFax()
                    done()
                }
            }
        },

        watch: {
            hide_add() {
                if (this.hide_add) {
                    this.$emit('hide')
                } else {
                    this.$emit('show')
                }
            }
        },
    }
</script>
