var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showDialer()
      ? _c(
          "div",
          {
            directives: [
              {
                name: "visible",
                rawName: "v-visible",
                value: _vm.is_widget ? true : _vm.is_visible,
                expression: "is_widget ? true : is_visible",
              },
            ],
            ref: "dialer",
            staticClass: "webrtc-dialer box-shadow-z4",
            class: [_vm.is_widget ? "widget" : ""],
            attrs: { id: "dialer" },
          },
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.show_help,
                  title: "Help",
                  top: "10vh",
                  "append-to-body": "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.show_help = $event
                  },
                },
              },
              [
                _c("ul", { staticClass: "list pl-3 pr-3 mb-0 text-md" }, [
                  _c("li", { staticClass: "_600 mb-2" }, [
                    _vm._v(
                      "Closing the phone will not make you offline. Choose on break or busy from the\n                    menu items to stop receiving calls.\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "_600 mb-2" }, [
                    _vm._v(
                      "If you refresh the page while on a call, the call will drop. Meanwhile you can\n                    navigate with the application during a call.\n                "
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.scriptDialogTitle,
                  visible: _vm.showScriptDialog,
                  "before-close": _vm.hideScript,
                  "append-to-body": "",
                  width: "50%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.showScriptDialog = $event
                  },
                },
              },
              [
                _vm.selected_script
                  ? _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.selected_script.text) },
                    })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "dialerHeader",
                staticClass: "row no-gutters webrtc-top grabbable",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-12 p-2 d-flex align-items-center justify-content-between",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pull-left d-flex align-items-center justify-content-between w-100 pr-2",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "_400 text-greyish text-xs no-select",
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.phone_status,
                                  disabled: _vm.isPhoneStatusTooltipDisabled,
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("truncate")(
                                            _vm.phone_status,
                                            20
                                          )
                                        ) +
                                        "\n\n                                "
                                    ),
                                    _vm.last_error_code === 31208 &&
                                    !_vm.$root.statics.whitelabel
                                      ? _c(
                                          "el-link",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              href: "https://support.aloware.com/en/articles/9037858-troubleshooting-tip-audio-issues-during-calls-here-s-how-to-fix-it",
                                              target: "_blank",
                                              underline: false,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    See fix\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.call
                          ? _c("span", {
                              staticClass: "text-muted text-xs ml-3 no-select",
                              domProps: { textContent: _vm._s(_vm.call_timer) },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pull-right d-flex" },
                      [
                        _vm.is_visible
                          ? _c(
                              "el-popover",
                              {
                                staticClass:
                                  "d-flex align-items-center p-0 mr-1",
                                attrs: {
                                  placement: "left-start",
                                  width: "200",
                                  trigger: "click",
                                },
                                on: { show: _vm.initializeSettings },
                              },
                              [
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-width": "200px",
                                      "label-position": "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "mb-0",
                                        attrs: { label: "Input Device:" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "Select",
                                              size: "mini",
                                            },
                                            on: { change: _vm.setInputDevice },
                                            model: {
                                              value: _vm.input_device,
                                              callback: function ($$v) {
                                                _vm.input_device = $$v
                                              },
                                              expression: "input_device",
                                            },
                                          },
                                          _vm._l(
                                            _vm.input_devices,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "mb-0",
                                        attrs: { label: "Output Device:" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "Select",
                                              size: "mini",
                                            },
                                            on: { change: _vm.setOutputDevice },
                                            model: {
                                              value: _vm.output_device,
                                              callback: function ($$v) {
                                                _vm.output_device = $$v
                                              },
                                              expression: "output_device",
                                            },
                                          },
                                          _vm._l(
                                            _vm.output_devices,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "d-block w-full mt-2",
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.testOutputDevice.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Speaker Test\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass:
                                      "p-0 ml-0 text-orange settings",
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference",
                                  },
                                  [
                                    _c(
                                      "i",
                                      { staticClass: "material-icons text-lg" },
                                      [_vm._v("settings")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.is_visible && !_vm.is_widget
                          ? _c(
                              "el-button",
                              {
                                staticClass: "p-0 ml-0 mr-1 help",
                                attrs: { type: "text" },
                                on: { click: _vm.toggleHelp },
                              },
                              [
                                _c(
                                  "i",
                                  { staticClass: "material-icons text-lg" },
                                  [_vm._v("help")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.is_visible && !_vm.is_widget
                          ? _c(
                              "el-button",
                              {
                                staticClass: "p-0 ml-0 text-danger minimize",
                                attrs: { type: "text" },
                                on: { click: _vm.closePhone },
                              },
                              [
                                _c(
                                  "i",
                                  { staticClass: "material-icons text-lg" },
                                  [_vm._v("clear")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "row no-gutters webrtc-header",
                class: _vm.getHeaderClass,
              },
              [
                _vm.is_ready
                  ? _c(
                      "div",
                      { staticClass: "col-12 pt-2 pr-2 pb-0 pl-2" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            staticClass: "phone-form",
                            attrs: { rules: _vm.rules, model: _vm.form },
                            nativeOn: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.validateAndCall.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm.call &&
                            _vm.communication &&
                            _vm.communication.contact &&
                            !_vm.is_widget
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Contact",
                                        params: {
                                          contact_id:
                                            _vm.communication.contact_id,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Go to contact's page",
                                          placement: "left",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "contact-name text-whitish opacity-8 text-ellipsis _400 text-md",
                                            class: [
                                              !_vm.is_widget
                                                ? "pull-left mr-1"
                                                : "",
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm._f("fixContactName")(
                                                    _vm.communication.contact
                                                      .name
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : (_vm.call &&
                                  _vm.communication &&
                                  _vm.communication.contact &&
                                  _vm.is_widget) ||
                                (_vm.on_wrap_up &&
                                  _vm.communication &&
                                  _vm.communication.contact &&
                                  !_vm.is_widget)
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: { click: _vm.goToContact },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "contact-name text-whitish opacity-8 text-ellipsis _400 text-md",
                                        class: [
                                          !_vm.is_widget
                                            ? "pull-left mr-1"
                                            : "",
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("fixContactName")(
                                                _vm.communication.contact.name
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "mb-0",
                                attrs: { prop: "current_number" },
                              },
                              [
                                !_vm.call && !_vm.on_call && !_vm.on_wrap_up
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.current_number,
                                          expression: "form.current_number",
                                        },
                                      ],
                                      ref: "current_number",
                                      staticClass: "pull-left _400 p-0",
                                      class: _vm.call ? "on-call" : "",
                                      attrs: {
                                        type: "tel",
                                        placeholder: "Click to type",
                                        disabled: !_vm.is_ready,
                                        autofocus: "",
                                      },
                                      domProps: {
                                        value: _vm.form.current_number,
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.form,
                                              "current_number",
                                              $event.target.value
                                            )
                                          },
                                          _vm.customPreValidateForm,
                                        ],
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.call || _vm.on_call || _vm.on_wrap_up
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "_500 pull-left original-line-height",
                                      },
                                      [
                                        _vm.form.current_number
                                          ? [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm._f("fixPhone")(
                                                      _vm.form.current_number
                                                    )
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          : _vm.communication
                                          ? [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm._f("fixPhone")(
                                                      _vm.communication
                                                        .lead_number
                                                    )
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      title: "",
                                      width: "50",
                                      trigger: "manual",
                                      content: "Copied!",
                                    },
                                    model: {
                                      value: _vm.copied,
                                      callback: function ($$v) {
                                        _vm.copied = $$v
                                      },
                                      expression: "copied",
                                    },
                                  },
                                  [
                                    (_vm.call &&
                                      !["pending"].includes(
                                        _vm.call.status()
                                      )) ||
                                    _vm.on_wrap_up
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass:
                                              "text-white ml-2 p-0 pull-left",
                                            attrs: {
                                              slot: "reference",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.copyPhoneNumber(
                                                  _vm.on_wrap_up
                                                    ? _vm.communication
                                                        .lead_number
                                                    : _vm.form.current_number
                                                )
                                              },
                                            },
                                            slot: "reference",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-copy text-sm",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.contact && !_vm.communication && !_vm.is_widget
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "Contact",
                                        params: { contact_id: _vm.contact.id },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Go to contact's page",
                                          placement: "left",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-whitish opacity-8 text-ellipsis _400",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm._f("fixContactName")(
                                                    _vm.contact.name
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.contact_local_time || _vm.getCompanyName
                              ? _c("div", [
                                  _vm.getCompanyName
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-whitish opacity-8 _400 text-xs",
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(_vm.getCompanyName) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.getCompanyName && _vm.contact_local_time
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                            -\n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.contact_local_time
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-whitish opacity-8 _400 text-xs",
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(_vm.contact_local_time) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.is_held
                              ? _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-whitish opacity-8 text-ellipsis _400",
                                    },
                                    [_vm._v("On Hold")]
                                  ),
                                ])
                              : _vm.contact &&
                                !_vm.communication &&
                                _vm.is_widget
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: { click: _vm.goToContact },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-whitish opacity-8 text-ellipsis _400",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("fixContactName")(
                                                _vm.contact.name
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.addedParty &&
                            _vm.show_added_party &&
                            (_vm.addingParty || _vm.partyAdded)
                              ? [
                                  true
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: _vm.addedParty,
                                            placement: "left",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm.addingParty
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-whitish text-ellipsis _400",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                Adding: " +
                                                        _vm._s(
                                                          _vm._f("truncate")(
                                                            _vm.addedParty,
                                                            32
                                                          )
                                                        ) +
                                                        "\n                            "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.partyAdded
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-whitish text-ellipsis _400",
                                                    staticStyle: {
                                                      "font-size": "11px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                Added: " +
                                                        _vm._s(
                                                          _vm._f("truncate")(
                                                            _vm.addedParty,
                                                            32
                                                          )
                                                        ) +
                                                        "\n                            "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.should_introduce
                                              ? _c(
                                                  "b",
                                                  {
                                                    staticClass:
                                                      "label blue webrtc-label-position-transfer",
                                                  },
                                                  [_vm._v("Introduce")]
                                                )
                                              : _vm.addedParty
                                              ? _c(
                                                  "b",
                                                  {
                                                    staticClass:
                                                      "label greenish webrtc-label-position-transfer",
                                                  },
                                                  [_vm._v("Add")]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.call
                          ? _c("div", { staticClass: "header-right" }, [
                              _c("ul", { attrs: { id: "signal-strength" } }, [
                                _vm._m(0),
                                _vm._v(" "),
                                _c("li", { staticClass: "weak" }, [
                                  _c("div", {
                                    class: [
                                      _vm.signalStrength >= 25 ? "active" : "",
                                    ],
                                    attrs: { id: "weak" },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("li", { staticClass: "strong" }, [
                                  _c("div", {
                                    class: [
                                      _vm.signalStrength >= 50 ? "active" : "",
                                    ],
                                    attrs: { id: "strong" },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("li", { staticClass: "pretty-strong" }, [
                                  _c("div", {
                                    class: [
                                      _vm.signalStrength >= 75 ? "active" : "",
                                    ],
                                    attrs: { id: "pretty-strong" },
                                  }),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.call === null && !_vm.on_call && _vm.parked_call
                          ? _c(
                              "div",
                              { staticClass: "row no-gutters clear-both" },
                              [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn unhold btn-block btn-flat greenish",
                                      attrs: {
                                        disabled:
                                          !_vm.parked_call || _vm.loading_park,
                                      },
                                      on: { click: _vm.unparkCall },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Unpark Call\n                        "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.is_ready && !_vm.loading_device
                  ? _c(
                      "div",
                      { staticClass: "el-table__empty-block no-min-height" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn solid bluish px-4",
                            on: {
                              click: function ($event) {
                                return _vm.rebootPhone(true, true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    Reconnect\n                "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.on_wrap_up || (_vm.call && _vm.call.status() == "closed")
              ? _c("div", { staticClass: "communication-info" }, [
                  _c(
                    "div",
                    { staticClass: "scrollable pt-2" },
                    [
                      _c("communication-info", {
                        ref: "communication_info",
                        attrs: {
                          communication: _vm.communication,
                          is_widget: _vm.is_widget,
                          display_contact: "",
                          dialer_mode: "",
                        },
                        on: {
                          callDisposed: _vm.callDisposed,
                          contactDisposed: _vm.contactDisposed,
                          contactNotDisposed: _vm.contactNotDisposed,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "pull-left ml-2",
                            attrs: {
                              size: "small",
                              disabled:
                                _vm.disableBackButton ||
                                _vm.temporary_disable_back_button,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.backToQueue()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-arrow-left" }),
                            _vm._v(
                              "\n                        Back\n                        "
                            ),
                            _vm.wrapUpTimerEnabled && _vm.wrap_up_timer > 0
                              ? _c("span", [
                                  _vm._v(
                                    "(" + _vm._s(_vm.wrap_up_timer) + "s)"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.wrapUpTimerEnabled &&
                            _vm.temporary_disable_back_button &&
                            _vm.temporary_disable_timer_count > 0
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        _vm.temporary_disable_timer_count
                                      ) +
                                      "s)"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.communication
                          ? _c(
                              "el-button",
                              {
                                staticClass: "pull-right mr-2",
                                attrs: {
                                  type: "success",
                                  size: "small",
                                  disabled: _vm.disableCallBackButton,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.callBack(
                                      _vm.communication.lead_number
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-phone" }),
                                _vm._v(
                                  "\n                        Call back\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.show_transfer &&
            !_vm.show_add &&
            !_vm.show_notes &&
            !_vm.show_dialpad &&
            !_vm.show_dialer_info &&
            _vm.call &&
            _vm.call.status() != "pending"
              ? _c("div", { staticClass: "dial-menu" }, [
                  _c("div", { staticClass: "row no-gutters" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "in" },
                        [
                          _vm.communication &&
                          _vm.communication.legc_uuid &&
                          _vm.communication.legc_status ==
                            _vm.CommunicationStatus.STATUS_INPROGRESS_NEW &&
                          !_vm.communication.in_cold_transfer &&
                          _vm.call.call_sid != _vm.communication.legc_uuid &&
                          (!_vm.communication.legz_uuid ||
                            _vm.call.call_sid != _vm.communication.legz_uuid)
                            ? [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-block btn-flat number-btn-wrapper",
                                    attrs: {
                                      disabled:
                                        _vm.agent_status ===
                                        _vm.AgentStatus.AGENT_STATUS_SENTRY,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.dropThirdParty.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._m(1),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "number-text-sub text-danger",
                                      },
                                      [_vm._v("Drop")]
                                    ),
                                  ]
                                ),
                              ]
                            : [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-block btn-flat number-btn-wrapper",
                                    attrs: {
                                      disabled:
                                        !_vm.communication ||
                                        _vm.communication.in_cold_transfer ||
                                        _vm.communication.current_status2 !==
                                          _vm.CommunicationCurrentStatus
                                            .CURRENT_STATUS_INPROGRESS_NEW ||
                                        (_vm.current_company &&
                                          !_vm.current_company
                                            .conferencing_enabled) ||
                                        (_vm.communication.legc_uuid &&
                                          [
                                            _vm.CommunicationStatus
                                              .STATUS_INPROGRESS_NEW,
                                            _vm.CommunicationStatus
                                              .STATUS_RINGING_NEW,
                                          ].includes(
                                            _vm.communication.legc_status
                                          )) ||
                                        (_vm.communication.legz_uuid &&
                                          _vm.call.call_sid ==
                                            _vm.communication.legz_uuid) ||
                                        _vm.agent_status ===
                                          _vm.AgentStatus.AGENT_STATUS_SENTRY,
                                    },
                                    on: { click: _vm.toggleAdd },
                                  },
                                  [
                                    _vm._m(2),
                                    _vm._v(" "),
                                    _vm.communication &&
                                    _vm.communication.legc_uuid &&
                                    _vm.communication.legc_status &&
                                    _vm.communication.legc_status ==
                                      _vm.CommunicationStatus.STATUS_RINGING_NEW
                                      ? _c(
                                          "span",
                                          { staticClass: "number-text-sub" },
                                          [
                                            _vm._v(
                                              "\n                                    Adding\n                                    "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "loader__dot" },
                                              [_vm._v(".")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "loader__dot" },
                                              [_vm._v(".")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "loader__dot" },
                                              [_vm._v(".")]
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "number-text-sub" },
                                          [
                                            _vm._v(
                                              "\n                                    Add\n                                "
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                              ],
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: {
                              disabled:
                                !_vm.communication ||
                                _vm.is_held ||
                                _vm.loading_hold ||
                                _vm.loading_unhold ||
                                _vm.loading_park ||
                                _vm.communication.current_status2 !==
                                  _vm.CommunicationCurrentStatus
                                    .CURRENT_STATUS_INPROGRESS_NEW ||
                                (_vm.current_company &&
                                  !_vm.current_company.conferencing_enabled) ||
                                (_vm.communication.legc_uuid &&
                                  [
                                    _vm.CommunicationStatus
                                      .STATUS_INPROGRESS_NEW,
                                    _vm.CommunicationStatus.STATUS_RINGING_NEW,
                                  ].includes(_vm.communication.legc_status)) ||
                                (_vm.communication.legz_uuid &&
                                  _vm.call.call_sid ==
                                    _vm.communication.legz_uuid) ||
                                _vm.agent_status ===
                                  _vm.AgentStatus.AGENT_STATUS_SENTRY,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.parkCall(true)
                              },
                            },
                          },
                          [
                            _vm._m(3),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("Park"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: {
                              disabled:
                                !_vm.communication ||
                                (_vm.communication.legc_uuid &&
                                  _vm.communication.legc_status ==
                                    _vm.CommunicationStatus
                                      .STATUS_INPROGRESS_NEW) ||
                                _vm.communication.current_status2 !==
                                  _vm.CommunicationCurrentStatus
                                    .CURRENT_STATUS_INPROGRESS_NEW ||
                                (_vm.current_company &&
                                  !_vm.current_company.conferencing_enabled) ||
                                (_vm.communication.legc_uuid &&
                                  [
                                    _vm.CommunicationStatus
                                      .STATUS_INPROGRESS_NEW,
                                    _vm.CommunicationStatus.STATUS_RINGING_NEW,
                                  ].includes(_vm.communication.legc_status)) ||
                                (_vm.communication.legz_uuid &&
                                  _vm.call.call_sid ==
                                    _vm.communication.legz_uuid) ||
                                _vm.agent_status ===
                                  _vm.AgentStatus.AGENT_STATUS_SENTRY,
                            },
                            on: { click: _vm.toggleTransfer },
                          },
                          [
                            _vm._m(4),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v(
                                "\n                                Transfer\n                            "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            on: { click: _vm.toggleNotes },
                          },
                          [
                            _vm._m(5),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("Add Notes"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "in" },
                        [
                          _vm.communication
                            ? _c("communication-tags", {
                                staticClass: "webrtc-communication-tags",
                                attrs: {
                                  communication: _vm.communication,
                                  small: _vm.is_widget,
                                  show_tags: false,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "trigger",
                                      fn: function () {
                                        return [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-block btn-flat number-btn-wrapper",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "number-text" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons text-xl",
                                                    },
                                                    [_vm._v("label_outline")]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "number-text-sub",
                                                },
                                                [_vm._v("Add Tags")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3329456662
                                ),
                              })
                            : _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-block btn-flat number-btn-wrapper",
                                  attrs: { disabled: "" },
                                },
                                [
                                  _c("span", { staticClass: "number-text" }, [
                                    _c(
                                      "i",
                                      { staticClass: "material-icons text-xl" },
                                      [_vm._v("label_outline")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "number-text-sub" },
                                    [_vm._v("Add Tags")]
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.communication },
                            on: { click: _vm.toggleDialpad },
                          },
                          [
                            _vm._m(6),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("Dialpad"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "in" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: _vm.is_widget ? "top" : "left",
                                width: _vm.is_widget ? 250 : 300,
                                title: "Drop Voicemail",
                                trigger: "click",
                              },
                            },
                            [
                              _vm.voicemail_drop_files &&
                              _vm.voicemail_drop_files.length > 5
                                ? [
                                    _c(
                                      "p",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "w-full",
                                            attrs: { placeholder: "Select" },
                                            on: { change: _vm.setVmDrop },
                                            model: {
                                              value: _vm.selected_user_vm_drop,
                                              callback: function ($$v) {
                                                _vm.selected_user_vm_drop = $$v
                                              },
                                              expression:
                                                "selected_user_vm_drop",
                                            },
                                          },
                                          _vm._l(
                                            _vm.voicemail_drop_files,
                                            function (user_vm) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key: user_vm.id,
                                                  attrs: {
                                                    label: user_vm.name,
                                                    value: user_vm.id,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(user_vm.name) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "m-0 text-right" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "success",
                                              size: "mini",
                                              disabled:
                                                !_vm.selected_user_vm_drop ||
                                                _vm.loading_play_prerecorded_voicemail,
                                            },
                                            on: {
                                              click:
                                                _vm.playPrerecordedVoicemailDrop,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Drop\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.voicemail_drop_files &&
                              _vm.voicemail_drop_files.length <= 5
                                ? [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-md-12" }, [
                                        _c(
                                          "ul",
                                          { staticClass: "button-custom-list" },
                                          _vm._l(
                                            _vm.voicemail_drop_files,
                                            function (user_vm) {
                                              return _c(
                                                "li",
                                                { key: user_vm.id },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-block btn-primary",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.playCustomPrerecordedVoicemailDrop(
                                                            user_vm.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "number-text-sub",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(user_vm.name)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-flat number-btn-wrapper",
                                  attrs: {
                                    slot: "reference",
                                    disabled:
                                      !_vm.communication ||
                                      _vm.communication.current_status2 !==
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_INPROGRESS_NEW ||
                                      _vm.loading_play_prerecorded_voicemail,
                                  },
                                  on: { click: _vm.toggleVmDrop },
                                  slot: "reference",
                                },
                                [
                                  _c("span", { staticClass: "number-text" }, [
                                    _c(
                                      "i",
                                      { staticClass: "material-icons text-xl" },
                                      [_vm._v("event_note")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "number-text-sub" },
                                    [_vm._v("VM Drop")]
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.hasPermissionTo("list script") && !_vm.is_widget
                      ? _c("div", { staticClass: "col-4" }, [
                          _c("div", { staticClass: "dummy" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "in" },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "left",
                                    title: "Scripts",
                                    width: "300",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.loading_script,
                                          expression: "loading_script",
                                        },
                                      ],
                                      attrs: { data: _vm.scripts },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "title",
                                          label: "Title",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "greenish",
                                                      attrs: { size: "mini" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showScript(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "material-icons",
                                                        },
                                                        [_vm._v("launch")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          91100332
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-flat number-btn-wrapper",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "number-text" },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons text-xl",
                                            },
                                            [_vm._v("event_note")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "number-text-sub" },
                                        [_vm._v("Scripts")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: {
                              disabled:
                                _vm.loading_toggle_recording_status ||
                                !_vm.communication ||
                                _vm.communication.current_status2 !==
                                  _vm.CommunicationCurrentStatus
                                    .CURRENT_STATUS_INPROGRESS_NEW ||
                                _vm.agent_status ===
                                  _vm.AgentStatus.AGENT_STATUS_SENTRY ||
                                _vm.communication.should_record === false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleRecordingStatus(
                                  _vm.recording_status
                                )
                              },
                            },
                          },
                          [
                            _vm.recording_status === "in-progress" &&
                            (!_vm.communication ||
                              _vm.communication.should_record == false)
                              ? [
                                  _vm._m(7),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "number-text-sub text-gray",
                                    },
                                    [_vm._v("Paused Rec")]
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.recording_status === "in-progress" &&
                            _vm.communication &&
                            _vm.communication.should_record == true
                              ? [
                                  _vm._m(8),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "number-text-sub text-danger",
                                    },
                                    [_vm._v("Pause Rec")]
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.recording_status === "paused"
                              ? [
                                  _vm._m(9),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "number-text-sub" },
                                    [_vm._v("Start Rec")]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: {
                              disabled:
                                !_vm.call ||
                                ["pending", "closed"].includes(
                                  _vm.call.status()
                                ),
                            },
                            on: { click: _vm.toggleMute },
                          },
                          [
                            _vm.muted
                              ? [
                                  _vm._m(10),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "number-text-sub text-danger",
                                    },
                                    [_vm._v("Unmute")]
                                  ),
                                ]
                              : [
                                  _vm._m(11),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "number-text-sub" },
                                    [_vm._v("Mute")]
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: {
                              disabled:
                                !_vm.communication ||
                                !_vm.communication.contact,
                            },
                            on: { click: _vm.goToContact },
                          },
                          [
                            _vm._m(12),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("Contact"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.show_notes
              ? _c("div", { staticClass: "note-menu" }, [
                  _c(
                    "div",
                    { staticClass: "row note-wrapper pl-3 pr-3 pt-2" },
                    [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c(
                                "el-form",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb-0",
                                      attrs: { label: "Notes:" },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "communication_notes",
                                        attrs: {
                                          type: "textarea",
                                          placeholder:
                                            "Write your call notes here...",
                                          autosize: { minRows: 5, maxRows: 10 },
                                          disabled:
                                            !_vm.hasPermissionTo(
                                              "note communication"
                                            ) || _vm.loading_notes,
                                          autofocus: "",
                                        },
                                        model: {
                                          value: _vm.communication_notes,
                                          callback: function ($$v) {
                                            _vm.communication_notes = $$v
                                          },
                                          expression: "communication_notes",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "mt-1 text-right h-24" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.loading_notes,
                                              expression: "loading_notes",
                                            },
                                          ],
                                          staticClass: "text-muted",
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons loader text-dark-greenish",
                                            },
                                            [_vm._v("")]
                                          ),
                                          _vm._v(
                                            "\n                                        saving\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.loading_notes &&
                                                _vm.loading_text,
                                              expression:
                                                "!loading_notes && loading_text",
                                            },
                                          ],
                                          staticClass: "text-muted",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        saved\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.communication &&
                                      _vm.communication_notes !=
                                        _vm.communication.notes &&
                                      !_vm.loading_notes
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-deep-orange _600",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        unsaved changes\n                                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mt-1" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "pull-left",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.toggleNotes.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-arrow-left" }),
                                  _vm._v(
                                    "\n                                Back\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "pull-right",
                                  attrs: {
                                    type: "success",
                                    size: "small",
                                    loading: _vm.loading_notes,
                                    disabled: _vm.loading_notes,
                                  },
                                  on: { click: _vm.saveNotes },
                                },
                                [
                                  _vm._v(
                                    "\n                                Save\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.show_transfer
              ? _c("div", { staticClass: "transfer-menu" }, [
                  _c(
                    "div",
                    { staticClass: "row transfer-wrapper pl-3 pr-3 pt-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "transfer_form",
                              staticClass: "h-full",
                              attrs: {
                                "label-position": "top",
                                rules: _vm.transfer_rules,
                                model: _vm.transfer,
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "transfer_mode" } },
                                [
                                  _c("div", { staticClass: "row mb-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "user" },
                                            on: {
                                              change:
                                                _vm.preValidateTransferForm,
                                            },
                                            model: {
                                              value: _vm.transfer.mode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transfer,
                                                  "mode",
                                                  $$v
                                                )
                                              },
                                              expression: "transfer.mode",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Transfer to a user\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "user" } },
                                          [
                                            _vm.transfer.mode == "user"
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        ref: "transfer_user",
                                                        staticClass:
                                                          "d-inline-flex",
                                                        staticStyle: {
                                                          width: "225px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "Select User",
                                                          filterable: "",
                                                          disabled:
                                                            _vm.loading_users,
                                                        },
                                                        on: {
                                                          "visible-change":
                                                            _vm.resetFilteredText,
                                                          change:
                                                            _vm.preValidateTransferForm,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.transfer
                                                              .user_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.transfer,
                                                              "user_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "transfer.user_id",
                                                        },
                                                      },
                                                      [
                                                        _vm.filteredAvailableUsers &&
                                                        _vm
                                                          .filteredAvailableUsers
                                                          .length > 0
                                                          ? _c(
                                                              "el-option-group",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "Available users",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.filteredAvailableUsers,
                                                                function (
                                                                  user
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: user.id,
                                                                      attrs: {
                                                                        label:
                                                                          user.name,
                                                                        value:
                                                                          user.id,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "media",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "media-body",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      user.name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "small",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.getLabel(
                                                                                        user
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Unavailable users",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.filteredUnavailableUsers,
                                                            function (user) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: user.id,
                                                                  attrs: {
                                                                    label:
                                                                      user.name,
                                                                    value:
                                                                      user.id,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "media",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "media-body",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  user.name
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "small",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.getLabel(
                                                                                    user
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-button", {
                                                      staticClass:
                                                        "d-inline-flex ml-2",
                                                      attrs: {
                                                        type: "primary",
                                                        icon: "fa fa-refresh",
                                                        size: "mini",
                                                        disabled:
                                                          _vm.loading_users,
                                                        loading:
                                                          _vm.loading_users,
                                                        circle: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.getAvailableUsers,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row mb-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "ring_group" },
                                            on: {
                                              change:
                                                _vm.preValidateTransferForm,
                                            },
                                            model: {
                                              value: _vm.transfer.mode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transfer,
                                                  "mode",
                                                  $$v
                                                )
                                              },
                                              expression: "transfer.mode",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Transfer to a ring group\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "ring_group" } },
                                          [
                                            _vm.transfer.mode == "ring_group"
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        ref: "transfer_ring_group",
                                                        staticClass:
                                                          "d-inline-flex",
                                                        staticStyle: {
                                                          width: "225px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "Select Ring Group",
                                                          filterable: "",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.preValidateTransferForm,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.transfer
                                                              .ring_group_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.transfer,
                                                              "ring_group_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "transfer.ring_group_id",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.visibleRingGroups,
                                                        function (ring_group) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: ring_group.id,
                                                              attrs: {
                                                                label:
                                                                  ring_group.name,
                                                                value:
                                                                  ring_group.id,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticClass:
                                                                    "material-icons",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "group"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    ring_group.name
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "phone" },
                                            on: {
                                              change:
                                                _vm.preValidateTransferForm,
                                            },
                                            model: {
                                              value: _vm.transfer.mode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.transfer,
                                                  "mode",
                                                  $$v
                                                )
                                              },
                                              expression: "transfer.mode",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Transfer to a phone number\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "phone_number" } },
                                          [
                                            _vm.transfer.mode == "phone"
                                              ? _c("div", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.transfer
                                                            .phone_number,
                                                        expression:
                                                          "transfer.phone_number",
                                                      },
                                                    ],
                                                    ref: "transfer_phone_number",
                                                    staticClass:
                                                      "el-input__inner _400",
                                                    attrs: { type: "tel" },
                                                    domProps: {
                                                      value:
                                                        _vm.transfer
                                                          .phone_number,
                                                    },
                                                    on: {
                                                      input: [
                                                        function ($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.transfer,
                                                            "phone_number",
                                                            $event.target.value
                                                          )
                                                        },
                                                        _vm.preValidateTransferForm,
                                                      ],
                                                    },
                                                  }),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "row transfer-buttons mt-3" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "pull-left",
                                          attrs: { size: "small" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.toggleTransfer.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-arrow-left",
                                          }),
                                          _vm._v(
                                            "\n                                    Back\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "pull-right",
                                          attrs: {
                                            type: "success",
                                            size: "small",
                                            loading:
                                              _vm.loading_users ||
                                              _vm.loading_transfer,
                                            disabled: !_vm.transfer_validated,
                                          },
                                          on: { click: _vm.transferCall },
                                        },
                                        [
                                          _c(
                                            "i",
                                            { staticClass: "material-icons" },
                                            [_vm._v("call_split")]
                                          ),
                                          _vm._v(
                                            "\n                                    Transfer\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.show_add
              ? _c("div", { staticClass: "transfer-menu" }, [
                  _c(
                    "div",
                    { staticClass: "row transfer-wrapper pl-3 pr-3 pt-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "add_form",
                              staticClass: "h-full",
                              attrs: {
                                "label-position": "top",
                                rules: _vm.add_rules,
                                model: _vm.add,
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "add_mode" } },
                                [
                                  _c("div", { staticClass: "row mb-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "user" },
                                            on: {
                                              change: _vm.preValidateAddForm,
                                            },
                                            model: {
                                              value: _vm.add.mode,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.add, "mode", $$v)
                                              },
                                              expression: "add.mode",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Add a user\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "user" } },
                                          [
                                            _vm.add.mode == "user"
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        ref: "add_user",
                                                        staticClass:
                                                          "d-inline-flex",
                                                        staticStyle: {
                                                          width: "225px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "Select User",
                                                          filterable: "",
                                                          disabled:
                                                            _vm.loading_users,
                                                        },
                                                        on: {
                                                          "visible-change":
                                                            _vm.resetFilteredText,
                                                          change:
                                                            _vm.preValidateAddForm,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.add.user_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.add,
                                                              "user_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "add.user_id",
                                                        },
                                                      },
                                                      [
                                                        _vm.filteredAvailableUsers &&
                                                        _vm
                                                          .filteredAvailableUsers
                                                          .length > 0
                                                          ? _c(
                                                              "el-option-group",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "Available users",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.filteredAvailableUsers,
                                                                function (
                                                                  user
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: user.id,
                                                                      attrs: {
                                                                        label:
                                                                          user.name,
                                                                        value:
                                                                          user.id,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "media",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "media-body",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      user.name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "small",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.getLabel(
                                                                                        user
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option-group",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Unavailable users",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.filteredUnavailableUsers,
                                                            function (user) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: user.id,
                                                                  attrs: {
                                                                    label:
                                                                      user.name,
                                                                    value:
                                                                      user.id,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "media",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "media-body",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  user.name
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "small",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.getLabel(
                                                                                    user
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-button", {
                                                      staticClass:
                                                        "d-inline-flex ml-2",
                                                      attrs: {
                                                        type: "primary",
                                                        icon: "fa fa-refresh",
                                                        size: "mini",
                                                        disabled:
                                                          _vm.loading_users,
                                                        loading:
                                                          _vm.loading_users,
                                                        circle: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getAvailableUsers(
                                                            false
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row mb-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "ring_group" },
                                            on: {
                                              change: _vm.preValidateAddForm,
                                            },
                                            model: {
                                              value: _vm.add.mode,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.add, "mode", $$v)
                                              },
                                              expression: "add.mode",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Add a ring group\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "ring_group" } },
                                          [
                                            _vm.add.mode == "ring_group"
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        ref: "add_ring_group",
                                                        staticClass:
                                                          "d-inline-flex",
                                                        staticStyle: {
                                                          width: "225px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "Select Ring Group",
                                                          filterable: "",
                                                          clearable: "",
                                                          disabled:
                                                            _vm.loading_ring_groups,
                                                        },
                                                        on: {
                                                          "visible-change":
                                                            _vm.resetFilteredText,
                                                          change:
                                                            _vm.preValidateAddForm,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.add
                                                              .ring_group_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.add,
                                                              "ring_group_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "add.ring_group_id",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.filteredAvailableRingGroups,
                                                        function (ring_group) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: ring_group.id,
                                                              attrs: {
                                                                label:
                                                                  ring_group.name,
                                                                value:
                                                                  ring_group.id,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "media",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "media-body",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              ring_group.name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-button", {
                                                      staticClass:
                                                        "d-inline-flex ml-2",
                                                      attrs: {
                                                        type: "primary",
                                                        icon: "fa fa-refresh",
                                                        size: "mini",
                                                        disabled:
                                                          _vm.loading_ring_groups,
                                                        loading:
                                                          _vm.loading_ring_groups,
                                                        circle: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.getAvailableRingGroups,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "phone" },
                                            on: {
                                              change: _vm.preValidateAddForm,
                                            },
                                            model: {
                                              value: _vm.add.mode,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.add, "mode", $$v)
                                              },
                                              expression: "add.mode",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Add a phone number\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "phone_number" } },
                                          [
                                            _vm.add.mode == "phone"
                                              ? _c("div", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.add.phone_number,
                                                        expression:
                                                          "add.phone_number",
                                                      },
                                                    ],
                                                    ref: "add_phone_number",
                                                    staticClass:
                                                      "el-input__inner _400",
                                                    attrs: { type: "tel" },
                                                    domProps: {
                                                      value:
                                                        _vm.add.phone_number,
                                                    },
                                                    on: {
                                                      input: [
                                                        function ($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.add,
                                                            "phone_number",
                                                            $event.target.value
                                                          )
                                                        },
                                                        _vm.preValidateAddForm,
                                                      ],
                                                    },
                                                  }),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "row transfer-buttons mt-3" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-6" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "pull-left",
                                          attrs: { size: "small" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.toggleAdd.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-arrow-left",
                                          }),
                                          _vm._v(
                                            "\n                                    Back\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-6" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "pull-right",
                                          attrs: {
                                            type: "success",
                                            size: "small",
                                            loading:
                                              _vm.loading_users ||
                                              _vm.loading_add,
                                            disabled: !_vm.add_validated,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addCall(false)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-user-plus",
                                          }),
                                          _vm._v(
                                            "\n                                    Add\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "pull-right mt-2",
                                          attrs: {
                                            type: "primary",
                                            size: "small",
                                            loading:
                                              _vm.loading_users ||
                                              _vm.loading_add,
                                            disabled: !_vm.add_validated,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addCall(true)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-user-friends",
                                          }),
                                          _vm._v(
                                            "\n                                    Introduce\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.show_transfer &&
            !_vm.show_add &&
            !_vm.show_notes &&
            _vm.show_dialpad &&
            !_vm.show_dialer_info &&
            (!_vm.call || (_vm.call && _vm.call.status() != "pending")) &&
            !(_vm.call && _vm.call.status() == "closed" && _vm.on_wrap_up)
              ? _c("div", { staticClass: "dial-pad" }, [
                  _c("div", { staticClass: "row no-gutters" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("1")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "number-text" }, [
                              _vm._v("1"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("2")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "number-text" }, [
                              _vm._v("2"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("A B C"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("3")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "number-text" }, [
                              _vm._v("3"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("D E F"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row no-gutters" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("4")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "number-text" }, [
                              _vm._v("4"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("G H I"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("5")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "number-text" }, [
                              _vm._v("5"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("J K L"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("6")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "number-text" }, [
                              _vm._v("6"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("M N O"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row no-gutters" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("7")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "number-text" }, [
                              _vm._v("7"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("P Q R S"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("8")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "number-text" }, [
                              _vm._v("8"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("T U V"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("9")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "number-text" }, [
                              _vm._v("9"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "number-text-sub" }, [
                              _vm._v("W X Y Z"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row no-gutters" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("*")
                              },
                            },
                          },
                          [_vm._m(13)]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("0")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "number-text" }, [
                              _vm._v("0"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "number-text-sub text-md" },
                              [_vm._v("+")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4" }, [
                      _c("div", { staticClass: "dummy" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "in" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-flat number-btn-wrapper",
                            attrs: { disabled: !_vm.is_ready },
                            on: {
                              click: function ($event) {
                                return _vm.sendDigit("#")
                              },
                            },
                          },
                          [_vm._m(14)]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.call && !_vm.on_wrap_up
              ? _c("div", { staticClass: "row no-gutters p-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 offset-4 d-flex align-items-center justify-content-center",
                    },
                    [
                      !_vm.outbound_campaign_id
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                trigger: "click",
                                "popper-class": "v-c dark-mode",
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  ref: "call_btn",
                                  staticClass:
                                    "md-btn md-fab d-flex greenish align-items-center justify-content-center",
                                  attrs: {
                                    slot: "reference",
                                    disabled:
                                      _vm.form.current_number.length === 0 ||
                                      !_vm.number_validated ||
                                      !_vm.is_ready ||
                                      _vm.isReceivingCall ||
                                      _vm.on_call ||
                                      _vm.loading_contact ||
                                      !_vm.enabledToCallNumber(
                                        _vm.form.current_number
                                      ),
                                  },
                                  slot: "reference",
                                },
                                [
                                  _vm.shouldWaitForContact &&
                                  _vm.loading_contact
                                    ? _c(
                                        "i",
                                        {
                                          staticClass: "material-icons loader",
                                        },
                                        [_vm._v("")]
                                      )
                                    : _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("call")]
                                      ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "Select a line",
                                    loading: _vm.loading_contact,
                                    disabled: _vm.loading_contact,
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.initial_campaign_id,
                                    callback: function ($$v) {
                                      _vm.initial_campaign_id = $$v
                                    },
                                    expression: "initial_campaign_id",
                                  },
                                },
                                [
                                  _c(
                                    "el-option-group",
                                    {
                                      key: "Agent Lines",
                                      attrs: { label: "Agent Lines" },
                                    },
                                    _vm._l(
                                      _vm.agentCampaigns,
                                      function (campaign) {
                                        return _c("el-option", {
                                          key: campaign.id,
                                          attrs: {
                                            label: campaign.name,
                                            value: campaign.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-option-group",
                                    {
                                      key: "All Lines",
                                      attrs: { label: "All Lines" },
                                    },
                                    _vm._l(
                                      _vm.visibleCampaigns,
                                      function (campaign) {
                                        return _c("el-option", {
                                          key: campaign.id,
                                          attrs: {
                                            label: campaign.name,
                                            value: campaign.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "ml-1 pl-2 pr-2",
                                  attrs: {
                                    type: "success",
                                    disabled:
                                      !_vm.initial_campaign_id ||
                                      !_vm.enabledToCallNumber(
                                        _vm.form.current_number
                                      ),
                                  },
                                  on: { click: _vm.callContact },
                                },
                                [
                                  _vm._v(
                                    "\n                        Call\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "button",
                            {
                              staticClass:
                                "md-btn md-fab d-flex greenish align-items-center justify-content-center",
                              attrs: {
                                disabled:
                                  _vm.form.current_number.length === 0 ||
                                  !_vm.number_validated ||
                                  !_vm.is_ready ||
                                  _vm.isReceivingCall ||
                                  _vm.on_call ||
                                  _vm.is_answering ||
                                  _vm.callDisabled ||
                                  !_vm.enabledToCallNumber(
                                    _vm.form.current_number
                                  ),
                              },
                              on: { click: _vm.answer },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("call"),
                              ]),
                            ]
                          ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 d-flex align-items-center justify-content-center dropup",
                    },
                    [
                      _vm.is_widget
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "nav-link d-flex align-items-center no-select",
                              class: [
                                _vm.disableAgentStatusButtons ? "disabled" : "",
                              ],
                              attrs: { "data-toggle": "dropdown" },
                            },
                            [
                              _vm.auth.user &&
                              _vm.auth.user.profile &&
                              _vm.auth.user.profile.name
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "w-40 avatar agent-avatar grey-300",
                                      style: _vm.avatarStyle(
                                        _vm.auth.user.profile.name
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("initials")(
                                              _vm.auth.user.profile.name
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "b-white bottom",
                                        class: [
                                          _vm.$options.filters.agentStatusClass(
                                            _vm.agent_status ??
                                              _vm.auth.user.profile.agent_status
                                          ),
                                        ],
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("keyboard_arrow_up"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("agent-status-dropdown", {
                        ref: "avatar_agent_statuses",
                        staticClass:
                          "dropdown-menu dropdown-menu-right dropdown-menu-overlay dropdown-menu-scale pull-right",
                        attrs: {
                          id: "avatar-agent-statuses",
                          user_id: _vm.auth.user.profile.id,
                          forced_disabled_ui: true,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.call && _vm.call.status() == "pending"
              ? _c("div", { staticClass: "row no-gutter p-2" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "call-communication-info" }, [
                      _c(
                        "table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: !_vm.communication,
                              expression: "!communication",
                            },
                          ],
                          staticClass: "mb-2 w-100",
                        },
                        [
                          _vm.communication
                            ? [
                                _vm.getSource(_vm.communication)
                                  ? _c("tr", [
                                      _c("td", { attrs: { colspan: "2" } }, [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getSource(_vm.communication)
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._m(15),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [_vm._v("Line:")]),
                                  _vm._v(" "),
                                  _vm.getCampaign(_vm.communication.campaign_id)
                                    ? _c("td", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getCampaign(
                                                _vm.communication.campaign_id
                                              ).name
                                            )
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [_vm._v("From:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixPhone")(
                                            _vm.communication.incoming_number
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.communication.contact
                                  ? [
                                      _vm._m(16),
                                      _vm._v(" "),
                                      _vm.communication.contact.company_name
                                        ? _c("tr", [
                                            _c("td", [_vm._v("Company:")]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.communication.contact
                                                      .company_name
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.communication.contact.website
                                        ? _c("tr", [
                                            _c("td", [_vm._v("Website:")]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.communication.contact
                                                      .website
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.communication.contact.cnam_city ||
                                      _vm.communication.contact.cnam_state
                                        ? _c("tr", [
                                            _c("td", [_vm._v("Location:")]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("strong", [
                                                _vm.communication.contact
                                                  .cnam_city
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.communication
                                                            .contact.cnam_city
                                                        ) + ", "
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.communication.contact
                                                  .cnam_state
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.communication
                                                            .contact.cnam_state
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.communication &&
                      _vm.communication.contact &&
                      _vm.hasCRMIntegration(_vm.communication)
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-collapse",
                                {
                                  model: {
                                    value: _vm.call_activename,
                                    callback: function ($$v) {
                                      _vm.call_activename = $$v
                                    },
                                    expression: "call_activename",
                                  },
                                },
                                [
                                  _vm.hubspotEnabled
                                    ? _c(
                                        "el-collapse-item",
                                        {
                                          attrs: {
                                            title: "Integration Cards",
                                            name: "integration-cards",
                                          },
                                        },
                                        [
                                          _c("integration-cards", {
                                            attrs: {
                                              contact:
                                                _vm.communication.contact,
                                              dialer_mode: true,
                                              no_padding: "",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.call && _vm.call.status() == "pending"
              ? _c("div", { staticClass: "row no-gutters p-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-3 offset-2 d-flex align-items-center justify-content-center",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "md-btn md-fab d-flex danger align-items-center justify-content-center",
                          on: { click: _vm.reject },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("call_end"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-3 offset-2 d-flex align-items-center justify-content-center",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "md-btn md-fab d-flex greenish align-items-center justify-content-center",
                          attrs: { disabled: _vm.is_answering },
                          on: { click: _vm.answer },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("call"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.call &&
            ["ringing", "open", "connecting", "reconnecting"].includes(
              _vm.call.status()
            )
              ? _c("div", { staticClass: "row no-gutters text-center p-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 d-flex align-items-center justify-content-center",
                    },
                    [
                      _vm.show_dialpad
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.toggleDialpad },
                            },
                            [
                              _vm._v(
                                "\n                    Hide\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.show_dialpad &&
                      _vm.should_introduce &&
                      _vm.communication &&
                      _vm.communication.legc_uuid &&
                      _vm.communication.legc_status &&
                      _vm.communication.legc_status ==
                        _vm.CommunicationStatus.STATUS_INPROGRESS_NEW
                        ? _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "Merge calls",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "md-btn md-flat d-flex new-blue align-items-center justify-content-center",
                                  on: { click: _vm.mergeCalls },
                                },
                                [
                                  _vm.loading_merge
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "loader__dot" },
                                          [_vm._v(".")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "loader__dot" },
                                          [_vm._v(".")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "loader__dot" },
                                          [_vm._v(".")]
                                        ),
                                      ]
                                    : _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("call_merge")]
                                      ),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.show_dialpad &&
                      (!_vm.should_introduce ||
                        _vm.communication.legc_status !==
                          _vm.CommunicationStatus.STATUS_INPROGRESS_NEW)
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-block btn-flat btn-white p-0",
                              attrs: { disabled: _vm.isHoldDisabled },
                              on: { click: _vm.toggleHold },
                            },
                            [
                              _c("span", { staticClass: "number-text" }, [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.is_held,
                                      expression: "is_held",
                                    },
                                  ],
                                  attrs: {
                                    src: "/assets/images/app-icons/phone/unhold-call.png",
                                  },
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.is_held,
                                      expression: "!is_held",
                                    },
                                  ],
                                  attrs: {
                                    src: "/assets/images/app-icons/phone/hold-call.png",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "number-text-sub",
                                  class: [_vm.is_held ? "text-blue-15" : ""],
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.is_held ? "Unhold" : "Hold")
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 d-flex align-items-center justify-content-center",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "md-btn md-fab d-flex danger align-items-center justify-content-center",
                          attrs: {
                            disabled: !_vm.enable_hangup || _vm.is_answering,
                          },
                          on: { click: _vm.hangup },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("call_end"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 d-flex align-items-center justify-content-center",
                    },
                    [
                      _vm.agent_status !== _vm.AgentStatus.AGENT_STATUS_SENTRY
                        ? _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "Refresh",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "md-btn md-flat d-flex success align-items-center justify-content-center",
                                  on: { click: _vm.forceRefreshCommunication },
                                },
                                [
                                  _vm.loading_communication
                                    ? _c(
                                        "i",
                                        {
                                          staticClass: "material-icons loader",
                                        },
                                        [_vm._v("")]
                                      )
                                    : _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("refresh")]
                                      ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showAlertAgentOnCall()
      ? _c("div", { staticClass: "p-3" }, [
          _vm._m(17),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "You're currently engaged in another call on Aloware Talk. Please complete your current conversation before initiating a new call."
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "very-weak" }, [
      _c("div", { staticClass: "active", attrs: { id: "very-weak" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text text-danger" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("call_end")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text" }, [
      _c("i", { staticClass: "material-icons text-xl" }, [_vm._v("add")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text" }, [
      _c("img", {
        attrs: { src: "/assets/images/app-icons/phone/park-call.png" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text" }, [
      _c("i", { staticClass: "material-icons text-xl" }, [
        _vm._v("call_split"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text" }, [
      _c("i", { staticClass: "material-icons text-xl" }, [_vm._v("note_add")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text" }, [
      _c("i", { staticClass: "material-icons text-xl" }, [_vm._v("dialpad")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text text-gray" }, [
      _c("i", { staticClass: "material-icons text-xl" }, [_vm._v("cancel")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text text-danger" }, [
      _c("i", { staticClass: "material-icons text-xl" }, [
        _vm._v("pause_circle_filled"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text" }, [
      _c("i", { staticClass: "material-icons text-xl" }, [_vm._v("lens")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text text-danger" }, [
      _c("i", { staticClass: "material-icons text-xl" }, [_vm._v("mic_off")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text" }, [
      _c("i", { staticClass: "material-icons text-xl" }, [_vm._v("mic")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "number-text" }, [
      _c("i", { staticClass: "material-icons text-xl" }, [
        _vm._v("open_in_new"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "number-text", staticStyle: { "font-size": "18px" } },
      [_c("i", { staticClass: "fas fa-star-of-life" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "number-text", staticStyle: { "font-size": "20px" } },
      [_c("i", { staticClass: "fas fa-hashtag" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "2" } }, [
        _c("strong", [_vm._v("Call Information")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "pt-md-2", attrs: { colspan: "2" } }, [
        _c("strong", [_vm._v("Contact Information")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Call in Progress on Another Device")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }