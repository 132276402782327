var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "none" }, [
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-number-of-communications.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-call-duration-per-campaign.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-call-time-of-the-day-per-campaign.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-caller-location.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-campaign-tag-1.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-campaign-tag-2.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-status-per-campaign.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-status-stacked-report-per-campaign.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-user-location.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-status-per-user-1.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-status-per-user-2.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: { src: "/assets/images/placeholders/placeholder-user-tag.png" },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-user-tag-stacked.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-incoming-number-location.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-communications-circle.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/assets/images/placeholders/placeholder-communications-chart.png",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: { src: "/assets/images/item-add-number-online.png" },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: { src: "/assets/images/item-add-number-offline.png" },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: { src: "/assets/images/item-add-number-your-website.png" },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: { src: "/assets/images/item-add-number-other-website.png" },
      }),
      _vm._v(" "),
      _c("img", { attrs: { src: "/assets/images/Contact-Center.png" } }),
      _vm._v(" "),
      _c("img", { attrs: { src: "/assets/images/Call-Tracking.png" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }