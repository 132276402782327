var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.loading_whitelabel,
          expression: "!loading_whitelabel",
        },
      ],
      staticClass: "container-fluid w-v h-v mellow-blue",
    },
    [
      _c("div", { staticClass: "row full-height" }, [
        !_vm.statics.whitelabel
          ? _c(
              "div",
              {
                staticClass:
                  "col-lg-7 d-none d-sm-none d-lg-flex justify-content-center align-items-center blue-purple-gradient pattern text-white text-center",
              },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.loading_whitelabel
          ? _c(
              "div",
              {
                staticClass:
                  "col-lg-5 d-flex justify-content-center align-items-center text-color",
                class: [_vm.statics.whitelabel ? "offset-lg-3" : ""],
              },
              [_c("new-login", { attrs: { statics: _vm.statics } })],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row", staticStyle: { "max-width": "550px" } },
      [
        _c("div", { staticClass: "col-12" }, [
          _c("h1", { staticClass: "mb-4 banner-font" }, [
            _vm._v("\n                        Our AI-powered "),
            _c("br"),
            _vm._v(" call transcription feature "),
            _c("br"),
            _vm._v(" is now available\n                    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mb-4 text-lg banner-font" }, [
            _c("br"),
            _vm._v(
              "\n                        Get up to 5000 minutes for free\n                    "
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                staticClass: "btn btn-primary btn-xl px-5",
                attrs: {
                  target: "_blank",
                  href: "https://aloware.com/features/ai-analytics/",
                },
              },
              [
                _vm._v(
                  "\n                            Request access\n                        "
                ),
              ]
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }