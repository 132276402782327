var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionToListWorkflows
    ? _c(
        "el-form",
        {
          ref: "pipedrive_extension_enroll_to_sequence",
          staticClass: "form-aloware text-left mb-1",
          attrs: { rules: _vm.form_rules, model: _vm.model },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { staticClass: "no-border", attrs: { prop: "workflow_id" } },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Select Sequence")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n        Choose the sequence you want to enroll the contacts\n      "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("app-extension-workflow-selector", {
                attrs: { loading: _vm.loading, multiple: false },
                on: { change: _vm.workflowChanged },
                model: {
                  value: _vm.model.workflow_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "workflow_id", $$v)
                  },
                  expression: "model.workflow_id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4" }, [
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", { staticClass: "mb-2" }, [
                _c("div", { staticClass: "d-flex flex-row" }, [
                  _c("div", { staticClass: "mr-2" }, [
                    _c("i", { staticClass: "fa fa-info-circle text-warning" }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n            Please note that only valid workflows are shown in the selection menu\n            "
                    ),
                    _c("ul", { staticClass: "list-unstyled" }, [
                      _c("li", [
                        _c("i", { staticClass: "fa fa-check mr-2" }),
                        _vm._v("Company is active"),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("i", { staticClass: "fa fa-check mr-2" }),
                        _vm._v("Line is active"),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("i", { staticClass: "fa fa-check mr-2" }),
                        _vm._v("Line has incoming numbers"),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("i", { staticClass: "fa fa-check mr-2" }),
                        _vm._v("Sufficient funds"),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("i", { staticClass: "fa fa-check mr-2" }),
                        _vm._v("Has Sequence steps"),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "mb-2" }, [
                _c("div", { staticClass: "d-flex flex-row" }, [
                  _c("div", { staticClass: "mr-2" }, [
                    _c("i", { staticClass: "fa fa-info-circle text-warning" }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n            Contacts that are already enrolled in a sequence will not be enrolled.\n          "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "mb-2" }, [
                _c("div", { staticClass: "d-flex flex-row" }, [
                  _c("div", { staticClass: "mr-2" }, [
                    _c("i", { staticClass: "fa fa-info-circle text-warning" }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n            Contacts that have a DNC status will not be enrolled.\n          "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "mb-2" }, [
                _c("div", { staticClass: "d-flex flex-row" }, [
                  _c("div", { staticClass: "mr-2" }, [
                    _c("i", { staticClass: "fa fa-info-circle text-warning" }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n            Having thousands of contacts will take time to enroll.\n          "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }