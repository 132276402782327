<template>
    <div v-if="hasPermissionTo('list disposition status')">
        <el-select v-model="disposition_status_id"
                   class="w-full"
                   :disabled="disable_selector"
                   :placeholder="placeholder"
                   :multiple="multiple"
                   :clearable="clearable"
                   :size="size"
                   default-first-option
                   collapse-tags
                   filterable>
            <el-option
                    v-for="disposition_status in dispositionsAlphabeticalOrder"
                    :key="disposition_status.id"
                    :label="disposition_status.name"
                    :value="disposition_status.id">
                <i class="fa fa-circle"
                   :style="{ color: disposition_status.color }"
                   v-if="disposition_status.id != 'not_disposed'"></i>
                <i class="fa fa-ban"
                   v-else></i>
                <span class="ml-2">{{ disposition_status.name }}</span>
            </el-option>
        </el-select>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import auth from '../auth'
    import {acl_mixin} from '../mixins'

    export default {
        mixins: [acl_mixin],

        data() {
            return {
                auth: auth,
                disposition_status_id: this.value,
                disable_selector: this.is_disabled,
            }
        },

        props: {
            value: {
                required: false,
            },

            exclude: {
                required: false,
            },

            multiple: {
                type: Boolean,
                required: false,
                default: false
            },

            clearable: {
                type: Boolean,
                required: false,
                default: false
            },

            show_not_disposed: {
                type: Boolean,
                required: false,
                default: true
            },

            is_disabled: {
                type: Boolean,
                default: false
            },

            size: {
                required: false,
                type: String,
                default: 'medium'
            }
        },

        computed: {
            ...mapState({
                disposition_statuses: state => state.disposition_statuses
            }),

            placeholder() {
                if (this.multiple) {
                    return 'Select Dispositions'
                }

                return 'Select Disposition'
            },

            availableDispositions() {
                if (this.disposition_statuses) {
                    return this.disposition_statuses.filter((disposition_status) => {
                        // if exclude is an array of to exclude ids
                        if (Array.isArray(this.exclude)) {
                            return !this.exclude.includes(disposition_status.id)
                        }

                        return disposition_status.id != this.exclude
                    })
                }

                return []
            },

            dispositionsAlphabeticalOrder() {
                if (this.availableDispositions) {
                    let disposition_statuses = _.clone(this.availableDispositions)
                    disposition_statuses = disposition_statuses.sort((a, b) => {
                        let textA = a.name.toUpperCase()
                        let textB = b.name.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })

                    if (this.show_not_disposed) {
                        disposition_statuses.unshift({
                            id: 'not_disposed',
                            name: 'Not Disposed',
                            color: '#000000'
                        })
                    }

                    return disposition_statuses
                }

                return []
            },
        },

        watch: {
            value() {
                this.disposition_status_id = this.value
            },

            disposition_status_id(val) {
                if (this.value !== undefined && this.disposition_status_id !== this.value) {
                    this.$emit('change', val)

                    // update also the value prop of the parent
                    // this is to ensure that both this component's data and the parent's data are synced
                    this.$emit('update:value', val)
                }
            },

            is_disabled() {
                this.disable_selector = this.is_disabled
            }
        }
    }
</script>
