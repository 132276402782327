<template>
    <div class="app-body"
         id="view">
        <aup-consent @loaded="loading_aup = false"></aup-consent>

        <div class="padding"
             v-if="hasPermissionTo('create broadcast rvm') && auth.user.profile.bulk_rvm_enabled && !loading_aup">
            <div class="row">
                <div class="box col-lg-7 col-12">
                    <div class="no-effect">
                        <div class="box-body pt-3">
                            <el-form ref="bulk_rvm_form"
                                     label-position="top"
                                     :model="bulk_rvm"
                                     :rules="rules"
                                     @submit.prevent.native="preventSubmit">

                                <el-form-item prop="name"
                                              label="Name:">
                                    <div class="width-400">
                                        <el-form-item prop="name">
                                            <el-input placeholder="Optional"
                                                      maxlength="190"
                                                      show-word-limit
                                                    v-model="bulk_rvm.name"
                                                    @input="preValidateForm('bulk_message_form')">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                </el-form-item>

                                <el-form-item label="From:"
                                              prop="campaign_id">
                                    <el-select placeholder="Lines"
                                               v-model="bulk_rvm.campaign_id"
                                               class="no-select"
                                               style="width: 300px"
                                               @change="preValidateForm('bulk_rvm_form')"
                                               filterable
                                               clearable>
                                        <el-option v-for="campaign in activeCampaignsAlphabeticalOrder"
                                                   :key="campaign.id"
                                                   :label="campaign.name"
                                                   :value="campaign.id">
                                            <span>{{ campaign.name }}</span>
                                        </el-option>
                                    </el-select>

                                    <el-popover ref="popover_campaign"
                                                placement="right"
                                                class="ml-2"
                                                title="Line"
                                                width="400"
                                                trigger="hover"
                                                content="The line you want to send the bulk RVM campaign from.">
                                    </el-popover>
                                    <span class="form-hover-help"
                                          v-popover:popover_campaign>
                                        What is this?
                                    </span>
                                </el-form-item>

                                <div class="row mt-5">
                                    <div class="col-12">
                                        <el-form-item label="Select a group of contacts:">
                                            <contact-tags-filters-selector
                                                ref="contactTagFilterSelector"
                                                :show_integrations="true"
                                                :tag_category="TagCategories.CAT_CONTACTS"
                                                @contactTagsGroupChanged="contactTagsGroupChanged"
                                                @hubspotListChanged="hubspotListChanged"
                                                @salesforceListChanged="salesforceListChanged"
                                                @zohoViewChanged="zohoViewChanged"
                                                @pipedriveFilterChanged="pipedriveFilterChanged"
                                                @resetTagSelector="resetTagSelector"
                                                @resetContactFiltersSelector="resetContactFiltersSelector">
                                            </contact-tags-filters-selector>
                                        </el-form-item>
                                    </div>
                                </div>

                                <div class="row mt-3 mb-2">
                                    <div class="col-12">
                                        <broadcast-schedule :message="bulk_rvm"
                                                            ref="broadcast_schedule">
                                        </broadcast-schedule>
                                    </div>
                                </div>

                                <el-form-item label="Throttling:"
                                              prop="throttle_limit">
                                    <el-select placeholder="Options"
                                               v-model="bulk_rvm.throttle_limit"
                                               class="no-select"
                                               style="width: 300px;"
                                               @change="preValidateForm('bulk_rvm_form')">
                                        <el-option v-for="option in throttlingOptions"
                                                   :key="option.id"
                                                   :label="option.name"
                                                   :value="option.value">
                                            <span>{{ option.name }}</span>
                                        </el-option>
                                    </el-select>

                                    <el-popover ref="popover_throttle_limit"
                                                placement="right"
                                                class="ml-2"
                                                title="Throttling"
                                                width="400"
                                                trigger="hover"
                                                content="This is an hourly throttling limit on your bulk RVM campaign.">
                                    </el-popover>
                                    <span class="form-hover-help"
                                          v-popover:popover_throttle_limit>
                                        What is this?
                                    </span>
                                </el-form-item>

                                <div class="row mb-3">
                                    <div class="col-12">
                                        <div class="box pt-2 pb-2 dker bulk-sms-counter pull-left mr-2"
                                             v-if="!bulk_rvm.view_id && !bulk_rvm.salesforce_list_id"
                                             v-loading="loading_count">
                                            <span class="inline p-x text-center">
                                                <span class="h4 block m-a-0">{{ contacts_count }}</span>
                                                <small class="text-xs text-muted">{{ (contacts_count > 1 || contacts_count == 0) ? 'Contacts' : 'Contact' }}</small>
                                            </span>
                                        </div>
                                        <div class="box pt-2 pb-2 bulk-sms-counter pull-left"
                                             v-if="current_company && current_company.reseller_id != 357 && !bulk_rvm.view_id"
                                             :class="getClass">
                                            <span class="inline p-x text-center">
                                                <span class="h4 block m-a-0">{{ messagesPrice | fixRounding | toCurrency }}</span>
                                                <small class="text-xs text-muted">Credits</small>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="no-bottom-margin mt-5 mb-5">
                                    <el-form-item prop="file_name">
                                        <audio-recorder
                                            v-if="bulk_rvm.file_name === null"
                                            class="audio-recorder-wrapper"
                                            :action="'/api/v1/broadcasts/upload/rvm'"
                                            :upload_type="'file_name'"
                                            @applyUploadedAudio="applyUploadedAudio">
                                        </audio-recorder>
                                        <div class="audio-uploader-wrapper">
                                            <el-upload ref="greetingAudio"
                                                       v-if="bulk_rvm.file_name === null"
                                                       drag
                                                       :action="'/api/v1/broadcasts/upload/rvm'"
                                                       :headers="headers"
                                                       :on-success="onSuccessUpload"
                                                       :on-error="onFailedUpload"
                                                       :on-progress="progressUploadUpload"
                                                       :before-upload="beforeUploadUpload"
                                                       :multiple="false"
                                                       :file-list="uploadFileList.upload">
                                                <i class="el-icon-upload"></i>
                                                <div class="el-upload__text">
                                                    Drop file here or <em>click to
                                                    upload</em>
                                                </div>
                                                <div class="el-upload__tip"
                                                     slot="tip">
                                                    MP3/WAV file (less than 8MB)
                                                </div>
                                            </el-upload>
                                            <el-progress :text-inside="true"
                                                         :stroke-width="18"
                                                         :percentage="uploadPercentage.upload"
                                                         :status="uploadStatus.upload"
                                                         v-if="bulk_rvm.file_name === null">
                                            </el-progress>
                                            <audio controls
                                                   v-if="bulk_rvm.file_name !== null">
                                                <source :src="'/static/uploaded_file/' + bulk_rvm.file_name">
                                                Your browser does not support the
                                                audio
                                                element.
                                            </audio>
                                            <button class="btn btn-sm btn-danger pull-right"
                                                    v-if="bulk_rvm.file_name !== null"
                                                    @click.prevent="deleteFile()">
                                                <i class="material-icons pull-left">&#xE872;</i>
                                                <span>Remove file</span>
                                            </button>
                                        </div>
                                    </el-form-item>
                                </div>
                            </el-form>

                            <div class="text-right mt-3">
                                <button class="btn btn-md greyish mr-2"
                                        @click="resetForm('bulk_rvm_form')">
                                    Reset
                                </button>

                                <button class="btn btn-md greenish"
                                        @click="sendBulkRVM"
                                        :disabled="loading || !validated || !countValidated || !checkCredits">
                                    <i class="material-icons loader"
                                       v-show="loading">
                                        &#xE863;
                                    </i>
                                    Send Bulk RVM
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <el-card class="col-lg-5 box-card help-card d-none d-sm-none d-md-block">
                    <div class="text item">
                        <span class="_600">Tips for a successful bulk RVM campaign:</span>
                        <br>
                        <span class="_400"
                              v-if="!statics.whitelabel">
                            Please contact our support at support@aloware.com before sending out the campaign.
                        </span>
                    </div>
                </el-card>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import { acl_mixin, form_validation_mixin, scroll_mixin, supervisor_mixin } from '../../mixins'
import AupConsent from '../../components/aup-consent'
import {CONTACTS_WITHOUT_DNC_WITHOUT_OPTOUT} from '../../constants/filter-dnc-options'
import * as TagCategories from '../../constants/tag-categories'

export default {
    mixins: [
        acl_mixin,
        scroll_mixin,
        form_validation_mixin,
        supervisor_mixin
    ],

    components: {AupConsent},

    data() {
        return {
            reporting_url: localStorage.getItem('reporting_url'),
            auth: auth,
            contacts_count: 0,
            loading: false,
            loading_count: false,
            checkAllUS: false,
            checkAllCA: false,
            isUSIndeterminate: true,
            isCAIndeterminate: true,
            filter_tags: null,
            bulk_rvm: {
                name: '',
                count: 0,
                file_name: null,
                filters: {
                    tags: []
                },
                list_id: null,
                view_id: null,
                salesforce_list_id: null,
                filter_id: null,
                campaign_id: null,
                throttle_limit: 4,
                run_at_date: null,
                run_at_time: null,
            },
            rules: {
                file_name: [
                    {
                        required: true,
                        message: 'Please upload your voicemail file',
                        trigger: 'change'
                    }
                ],
                filter_tags: [
                    {
                        required: true,
                        message: 'Please select a filter',
                        trigger: 'change'
                    }
                ],
                campaign_id: [
                    {
                        required: true,
                        message: 'Please select a line',
                        trigger: 'change'
                    }
                ],
                throttle_limit: [
                    {
                        required: true,
                        message: 'Please select a throttling option',
                        trigger: 'change'
                    }
                ],
            },
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + auth.user.profile.api_token,
                'X-Socket-Id': window.Echo.socketId()
            },
            uploadPercentage: {
                upload: 0
            },
            uploadStatus: {
                upload: 'success'
            },
            uploadFileList: {
                upload: []
            },
            loading_aup: true,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            send_next_business_hour_window: false,
            accept_outside_business_hours: false,
            is_before_broadcast_hours: false,
            TagCategories,
            throttlingOptions: [
                {
                    id: 1,
                    name: '240 per hour',
                    value: 4
                },
                {
                    id: 2,
                    name: '480 per hour',
                    value: 8
                },
                {
                    id: 3,
                    name: '960 per hour',
                    value: 16
                },
                {
                    id: 4,
                    name: '1980 per hour',
                    value: 33
                },
            ]
        }
    },

    computed: {
        ...mapState(['campaigns', 'usage', 'tag_options', 'filter_options']),
        ...mapState('cache', ['current_company']),

        ...mapGetters({
            filter: 'getFilter'
        }),

        ...mapGetters('cache',  ['isSimpSocial']),

        activeCampaignsAlphabeticalOrder() {
            if (this.campaigns) {
                let campaigns = _.clone(this.campaigns)
                return campaigns
                    .filter(campaign => campaign.active == true)
                    .sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        checkCredits() {
            // SimpSocial can send bulk RMV without checking credits
            if (this.isSimpSocial) {
                return true
            }

            if (this.usage && this.usage.credits >= this.messagesPrice) {
                return true
            }

            return false
        },

        messagesPrice() {
            return this.contacts_count * this.auth.user.profile.rate.rvm
        },

        getClass() {
            if (this.contacts_count == 0) {
                return 'dker'
            }

            if (this.checkCredits) {
                return 'dark-greenish'
            }

            return 'danger'
        },

        countValidated() {
            return this.contacts_count != 0 || this.bulk_rvm.salesforce_list_id != null || this.bulk_rvm.view_id != null || this.bulk_rvm.filter_id != null
        },

        hasBroadcastTimeframe() {
            return this.current_company &&
                this.current_company.hasOwnProperty('broadcast_open') &&
                this.current_company.hasOwnProperty('broadcast_close')
        },

        companyTimezone() {
            return moment().tz(this.current_company.timezone)
        },

        scheduleIsPast() {
            if (this.$refs.broadcast_schedule.scheduled) {
                const company_datetime_now = this.companyTimezone.format('MM/DD/YYYY HH:mm:ss')
                return moment(this.runAtDateTime).isBefore(company_datetime_now)
            }

            return false
        },

        runAtDateTime() {
            return this.bulk_rvm.run_at_date + ' ' + this.bulk_rvm.run_at_time + ':00'
        },

        isBeforeBroadcastTime() {
            if (this.hasBroadcastTimeframe) {
                const company = this.current_company
                const run_at_time = moment(this.runAtDateTime)
                const broadcast_open = moment(this.bulk_rvm.run_at_date + ' ' + company.broadcast_open)

                return run_at_time.isBefore(broadcast_open)
            }
        },
    },

    mounted() {
        this.setTitle()
        if (!this.auth.user.profile.bulk_rvm_enabled) {
            this.goBack()
        }
        if (!this.hasPermissionTo('create broadcast rvm')) {
            this.goBack()
        }

        // Set dates
        this.run_at_date = this.companyTimezone.format('MM/DD/YYYY')
        this.run_at_time = this.companyTimezone.format('HH:mm')

        this.initializeFilterOptions()
    },

    methods: {
        initializeFilterOptions() {
            VueEvent.fire('reset_events')
            this.resetContactFilters()
            this.filter_options.is_bulk = true
            this.filter_options.no_export = true
            this.filter_options.no_save_as_tag = true
        },

        setTitle() {
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.loading_whitelabel = false
                let name = res.data.name
                this.setPageTitle('Bulk RVM - ' + name)
            }).catch(err => {
                this.setPageTitle('Bulk RVM - Aloware')
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false
            })
        },

        // executes when there are tag changes from child component
        contactTagsGroupChanged(val = null) {
            this.filter_tags = val
            if (val !== null) {
                this.bulk_rvm.filters.tags = [val]
            }
            // refresh the count if not empty
            if (!_.isEmpty(this.bulk_rvm.filters.tags)) {
                this.loading_count = true
                this.bulk_rvm.filters.export_type = 'count'
                this.bulk_rvm.filters.is_bulk = true
                // exclude DNC'd contacts from query
                const params = Object.assign({}, this.bulk_rvm.filters, {'dnc_option': CONTACTS_WITHOUT_DNC_WITHOUT_OPTOUT})
                axios.get(this.reporting_url + '/api/v1/contact/get-contacts-count', {
                    params: params,
                }).then(res => {
                    this.contacts_count = res.data.total_contact_count
                    this.loading_count = false
                }).catch(err => {
                    this.contacts_count = 0
                    this.$root.handleErrors(err.response)
                    this.loading_count = false
                })
                this.preValidateForm('bulk_rvm_form')
            }
        },

        cleanUpFilter() {
            for (let index in this.bulk_rvm.filters) {
                if (isVariableEmpty(this.bulk_rvm.filters[index]) &&
                    (typeof this.bulk_rvm.filters[index] === 'number' &&
                        this.bulk_rvm.filters[index] != 0)) {
                    delete this.bulk_rvm.filters[index]
                }
            }
        },

        // executes when there are contact filter changes
        contactFiltersChanged() {
            this.bulk_rvm.filters = _.clone(this.filter)
            this.cleanUpFilter()
            this.loading_count = true
            this.bulk_rvm.filters.export_type = 'count'
            this.bulk_rvm.filters.is_bulk = true
            axios.get(this.reporting_url + '/api/v1/contact/get-contacts-count', {
                params: this.bulk_rvm.filters
            }).then(res => {
                this.contacts_count = res.data.total_contact_count
                this.loading_count = false
            }).catch(err => {
                this.contacts_count = 0
                this.$root.handleErrors(err.response)
                this.loading_count = false
            })
            this.preValidateForm('bulk_rvm_form')
        },

        showBroadcastTimeframeWarning() {
            const company = this.current_company

            // get extracted date string only to compose a valid datetime with broadcast timeframes
            const current_date = this.companyTimezone.format('YYYY-MM-DD')
            const format = 'h:mm a';
            let broadcast_open = `${current_date} ${company.broadcast_open}`
            let broadcast_close = `${current_date} ${company.broadcast_close}`

            broadcast_open = moment(broadcast_open).format(format)
            broadcast_close = moment(broadcast_close).format(format)

            if (this.$refs.broadcast_schedule.scheduled) {
                this.$refs.broadcast_schedule.setAssumedNextBusinessHourWindow()
            }

            let is_before_text = (this.isBeforeBroadcastTime || this.is_before_broadcast_hours) ? 'broadcast window' : 'day';
            let next_broadcast_schedule_text = (this.isBeforeBroadcastTime || this.is_before_broadcast_hours) ? moment(this.bulk_rvm.run_at_date).format('MM/DD/YYYY') : moment(this.bulk_rvm.run_at_date).add(1, 'days').format('MM/DD/YYYY')

            const confirmation_text = `Schedule this message to send on next ${is_before_text} <b>(${next_broadcast_schedule_text})</b> at <b>${broadcast_open} ${this.companyTimezone.format('z')}</b>`
            const message = `<div>Unable to send bulk voicemail since the current time is outside of your account's defined Broadcast Business Hours: <span class="font-weight-bold">${broadcast_open} - ${broadcast_close} ${this.companyTimezone.format('z')}.</span><span class="pl-2"><a href="/account?tab=settings#broadcasts_business_hours" class="border-bottom border-primary text-primary" target="_blank">Edit</a></span></div><div class="mt-3">${confirmation_text}?</div>`

            this.$msgbox({
                title: 'Warning - Outside of Business Hours',
                message: message,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'dialog-business-hours',
                dangerouslyUseHTMLString: true,
            }).then(() => {
                this.send_next_business_hour_window = true
                this.$refs.broadcast_schedule.setScheduleToNextBusinessHourWindow()
                this.accept_outside_business_hours = true
                // attempt to send again, this time, consent has been given to send outside broadcast time setting
                this.sendBulkRVM()
            }).catch(() => {
                // reset to current date & time setting
                this.$refs.broadcast_schedule.isScheduledToNextBusinessHourWindow = false
            })
        },

        updateCurrentCompanyDetails() {
            return axios.get(`/api/v1/company/${this.current_company.id}`)
                .then(res => {
                    this.setCurrentCompany(res.data)
                })
        },

        async sendBulkRVM() {
            this.loading = true

            // in case the broadcast setting gets updated, we will be able to reference to that
            await this.updateCurrentCompanyDetails()

            if (this.scheduleIsPast) {
                this.$notify({
                    offset: 95,
                    title: 'Send Bulk RVM',
                    message: 'Send schedule has already passed. Kindly update the send schedule. Thank you.',
                    type: 'warning',
                    showClose: true,
                })

                this.$refs.broadcast_schedule.initTimeSelectOptions()
                this.loading = false

                return
            }

            // If user already accepted send broadcast outside broadcast hours
            if (this.accept_outside_business_hours == true) {
                this.sendBroadcast()
            }

            // If user not yet accepted send broadcast outside broadcast hours, show a notification UI/warning
            if (this.accept_outside_business_hours == false) {
                this.$confirm('Are you sure that you have the consent of the all the recipients?', 'Warning', {
                    confirmButtonText: 'Yes, please send',
                    cancelButtonText: 'No',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(() => {
                    this.sendBroadcast()
                }).catch(() => {
                    this.send_next_business_hour_window = false
                    this.loading = false
                    // reset to current date & time setting
                    this.$refs.broadcast_schedule.isScheduledToNextBusinessHourWindow = false
                })
            }
        },

        sendBroadcast() {
            this.bulk_rvm.count = this.contacts_count

            axios.post('/api/v1/broadcasts/send-bulk-rvm', {
                ...this.bulk_rvm,
                accept_outside_business_hours: this.accept_outside_business_hours
            })
            .then(res => {
                if (this.accept_outside_business_hours == false && res.data.outside_business_hours == true) {
                    this.loading = false
                    this.outside_business_hours = true
                    this.is_before_broadcast_hours = res.data.is_before_broadcast_hours
                    this.showBroadcastTimeframeWarning()
                    return
                }
                // UI notification
                this.$notify.success({
                    offset: 95,
                    title: 'Bulk RVM',
                    dangerouslyUseHTMLString: true,
                    message: `We have put your RVMs on our outbound queue. Please wait a few minutes for us to send your RVMs. Meanwhile, you can monitor the progress from the dashboard's communication logs table.`,
                    duration: 5000
                })
                // Reset contact tag selector
                if (this.$refs.contactTagFilterSelector) {
                    this.$refs.contactTagFilterSelector.resetSelectors()
                }
                this.$router.push({name: 'Broadcast', query: {status: 'all'}})
                this.newBroadcast(res.data.broadcast)
            })
            .catch(err => {
                this.$root.handleErrors(err.response)
                this.loading = false
                this.send_next_business_hour_window = false
                this.accept_outside_business_hours = false
                this.is_before_broadcast_hours = false
            })
        },

        resetForm(form_name) {
            this.bulk_rvm.count = 0
            this.bulk_rvm.file_name = null
            this.bulk_rvm.campaign_id = null
            this.bulk_rvm.throttle_limit = 0
            this.bulk_rvm.run_at_date = null
            this.bulk_rvm.run_at_time = null
            this.contacts_count = 0
            this.validated = false
            this.isUSIndeterminate = true
            this.isCAIndeterminate = true

            let form_element = _.get(this.$refs, form_name, null)

            if (!form_element) {
                return
            }

            form_element.resetFields()
            form_element.clearValidate()
        },

        hubspotListChanged(list) {
            this.bulk_rvm.list_id = list.list_id
            if (list && list.raw_data && list.raw_data.additionalProperties.hs_list_size) {
                this.contacts_count = parseInt(list.raw_data.additionalProperties.hs_list_size)
            } else {
                this.contacts_count = 0
            }
            this.preValidateForm('bulk_rvm_form')
        },

        salesforceListChanged(list) {
            this.bulk_rvm.salesforce_list_id = list.list_id
            this.preValidateForm('bulk_rvm_form')
        },

        zohoViewChanged(view) {
            this.bulk_rvm.view_id = view.view_id
            this.preValidateForm('bulk_rvm_form')
        },

        pipedriveFilterChanged(filter) {
            this.bulk_rvm.filter_id = filter.filter_id
            this.preValidateForm('bulk_rvm_form')
        },

        // resets the tag selector when changing tabs
        resetTagSelector() {
            this.bulk_rvm.filters.tags = []
            this.tag_options.is_reset = !this.tag_options.is_reset
            this.contacts_count = 0
            this.preValidateForm('bulk_rvm_form')
        },

        // resets the contact filter when changing tabs
        resetContactFiltersSelector() {
            this.bulk_rvm.filters = {
                tags: []
            }
            this.contacts_count = 0
            this.initializeFilterOptions()
            this.preValidateForm('bulk_rvm_form')
        },

        // resets the integrations when changing tabs
        resetIntegrationsSelector() {
            this.bulk_rvm.list_id = null
            this.bulk_rvm.view_id = null
            this.bulk_rvm.salesforce_list_id = null
            this.contacts_count = 0
            this.preValidateForm('bulk_rvm_form')
        },

        onSuccessUpload(res) {
            this.$notify({
                offset: 95,
                title: 'Bulk RVM',
                message: 'File uploaded successfully.',
                type: 'success',
                showClose: true,
            })
            this.$set(this.bulk_rvm, 'file_name', res.file_name)
            this.uploadStatus.upload = 'success'
            this.uploadPercentage.upload = 0
            this.preValidateForm('bulk_rvm_form')
        },

        onFailedUpload(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.upload = 'exception'
            this.uploadPercentage.upload = 0
            this.preValidateForm('bulk_rvm_form')
        },

        beforeUploadUpload() {
            this.uploadStatus.upload = 'success'
            this.uploadPercentage.upload = 0
        },

        progressUploadUpload(event) {
            this.uploadPercentage.upload = parseInt(event.percent)
        },

        handleUploadErrors(error) {
            if (typeof error === 'string') {
                error = JSON.parse(error)
            }
            let err
            if (error.message === 'This action is unauthorized.') {
                err = {
                    status: 403,
                }
            } else {
                err = {
                    status: 422,
                    data: {
                        errors: error.errors.file
                    }
                }
            }

            this.$root.handleErrors(err)
        },

        applyUploadedAudio(data) {
            this.$set(this.bulk_rvm, 'file_name', null)
            setTimeout(() => {
                this.$notify({
                    offset: 95,
                    title: 'Campaign',
                    message: 'File uploaded successfully.',
                    type: 'success',
                    showClose: true,
                })
                this.$set(this.bulk_rvm, data.upload_type, data.uid)
                this.preValidateForm('bulk_rvm_form')
            }, 200)
        },

        deleteFile() {
            this.bulk_rvm.file_name = null
            this.uploadPercentage.upload = 0
            this.uploadStatus.upload = 'success'
            this.uploadFileList.upload = []
            this.preValidateForm('bulk_rvm_form')
        },

        preventSubmit() {
            return false
        },

        ...mapActions(['resetContactFilters']),

        ...mapActions('cache', ['setCurrentCompany'])
    },

    watch: {
        'filter_options.is_changed': function () {
            if (this.filter.changed) {
                this.contactFiltersChanged()
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                let auth = {
                    user: {
                        profile: res.data.user
                    }
                }

                if (!auth.user.profile.bulk_rvm_enabled) {
                    next({name: 'Dashboard'})
                }

                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next(vm => {
                        if (!vm.hasPermissionTo('create broadcast rvm', auth.user.profile.user_permissions)) {
                            next({name: 'Dashboard'})
                        }
                    })
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
