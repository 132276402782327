<template>
    <div class="d-flex flex-column width-400 fixed"
         v-if="hubspotEnabled">
        <div class="d-flex flex-row align-items-center">
            <el-input placeholder="Ticket name"
                      class="d-flex width-400 fixed mb-2"
                      v-model="name">
            </el-input>
            <variables @variable-selected="nameVariableSelected"
                       class="ml-2">
            </variables>
        </div>
        <div class="d-flex flex-row align-items-center mb-2">
            <el-select v-model="pipeline_id"
                       class="d-flex flex-grow-1 flex-shrink-0"
                       :placeholder="pipelinePlaceholder"
                       :loading="loading_hubspot_ticketing_pipelines"
                       :disabled="loading_hubspot_ticketing_pipelines || !hubspot_ticketing_pipelines.length"
                       filterable
                       clearable
                       default-first-option
                       collapse-tags>
                <el-option
                    v-for="pipeline in orderedPipelines"
                    :key="pipeline.pipelineId"
                    :label="pipeline.label"
                    :value="pipeline.pipelineId">
                </el-option>
            </el-select>
            <el-button type="primary"
                       icon="fa fa-refresh"
                       size="mini"
                       class="ml-2 w-30"
                       :disabled="loading_hubspot_ticketing_pipelines"
                       :loading="loading_hubspot_ticketing_pipelines"
                       circle
                       @click="getHubspotTicketingPipeline">
            </el-button>
        </div>
        <el-select v-model="stage_id"
                   class="d-flex flex-grow-1 flex-shrink-0 mb-2"
                   :placeholder="stagePlaceholder"
                   :loading="loading_hubspot_ticketing_pipelines"
                   :disabled="loading_hubspot_ticketing_pipelines || !hubspot_ticketing_pipelines.length || !activePipeline"
                   filterable
                   clearable
                   default-first-option
                   collapse-tags>
            <el-option
                v-for="stage in orderedStages"
                :key="stage.stageId"
                :label="stage.label"
                :value="stage.stageId">
            </el-option>
        </el-select>
        <div class="d-flex flex-row align-items-center">
            <el-input placeholder="Ticket description"
                      class="d-flex width-400 fixed"
                      v-model="description">
            </el-input>
            <variables @variable-selected="ticketVariableSelected"
                       class="ml-2">
            </variables>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../auth'
import {acl_mixin} from '../mixins'
import Variables from "./messenger/variables"

export default {
    mixins: [acl_mixin],

    components: {Variables},

    props: {
        ticketName: {
            required: false,
        },

        pipelineId: {
            required: false,
        },

        stageId: {
            required: false,
        },

        ticketDescription: {
            required: false,
        },
    },

    data() {
        return {
            auth: auth,
            name: this.ticketName,
            pipeline_id: this.pipelineId,
            stage_id: this.stageId,
            description: this.ticketDescription,
            loading_hubspot_ticketing_pipelines: false,
            hubspot_ticketing_pipelines: [],
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company
        }),

        hubspotEnabled() {
            if (this.current_company &&
                this.current_company.hubspot_integration_enabled) {
                return true
            }

            return false
        },

        pipelinePlaceholder() {
            if (this.loading_hubspot_ticketing_pipelines) {
                return 'Loading ...'
            }

            return 'Select a pipeline'
        },

        stagePlaceholder() {
            if (this.loading_hubspot_ticketing_pipelines) {
                return 'Loading ...'
            }

            return 'Select a status'
        },

        orderedPipelines() {
            if (this.hubspot_ticketing_pipelines) {
                return this.hubspot_ticketing_pipelines.sort((a, b) => {
                    let textA = a.displayOrder
                    let textB = b.displayOrder
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },

        activePipeline() {
            if (this.hubspot_ticketing_pipelines && this.pipeline_id) {
                return this.hubspot_ticketing_pipelines.find(pipeline => pipeline.pipelineId === this.pipeline_id)
            }

            return
        },

        orderedStages() {
            if (this.activePipeline && this.activePipeline.stages) {
                return this.activePipeline.stages.filter(stage => stage.active === true).sort((a, b) => {
                    let textA = a.displayOrder
                    let textB = b.displayOrder
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        }
    },

    mounted() {
        if (this.hubspotEnabled) {
            this.getHubspotTicketingPipeline()
        }
    },

    methods: {
        getHubspotTicketingPipeline() {
            this.hubspot_ticketing_pipelines = []
            this.loading_hubspot_ticketing_pipelines = true
            axios.get('/api/v1/integration/hubspot/ticketing-pipelines').then((res) => {
                this.loading_hubspot_ticketing_pipelines = false
                this.hubspot_ticketing_pipelines = res.data
            }).catch((err) => {
                this.loading_hubspot_ticketing_pipelines = false
                this.$root.handleErrors(err.response)
                console.log(err)
            })
        },

        nameVariableSelected(variable) {
            this.name = (this.name ?? '') + ' ' + variable
        },

        ticketVariableSelected(variable) {
            this.description = (this.description ?? '') + ' ' + variable
        }
    },

    watch: {
        ticketName() {
            this.name = this.ticketName
        },

        name() {
            if (this.name !== undefined && this.name !== this.ticketName) {
                this.$emit('changedTicketName', this.name)
            }
        },

        pipelineId() {
            this.pipeline_id = this.pipelineId
        },

        pipeline_id() {
            if (this.pipelineId !== undefined && this.pipeline_id !== this.pipelineId) {
                this.$emit('selectedPipeline', this.pipeline_id)
            }
        },

        stageId() {
            this.stage_id = this.stageId
        },

        stage_id() {
            if (this.stageId !== undefined && this.stage_id !== this.stageId) {
                this.$emit('selectedStage', this.stage_id)
            }
        },

        ticketDescription() {
            this.description = this.ticketDescription
        },

        description() {
            if (this.ticketDescription !== undefined && this.description !== this.ticketDescription) {
                this.$emit('changedTicketDescription', this.description)
            }
        }
    }
}
</script>
