var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid w-v bg-white" }, [
    _c("div", { staticClass: "row full-height" }, [
      _c(
        "div",
        {
          staticClass:
            "col-lg-6 d-flex flex-column align-items-center text-color m-auto bg-white",
          staticStyle: { height: "100vh", "padding-top": "10rem" },
        },
        [
          _c("div", { staticClass: "login-brand mb-2" }, [
            _c("img", { attrs: { src: _vm.statics.logo, alt: "." } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "my-4 text-center" }, [
            _c("p", { staticClass: "mb-1" }, [
              _vm._v(
                "Welcome " +
                  _vm._s(_vm.auth.user.profile.first_name) +
                  "! You have multiple accounts."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Please choose the account you want to proceed with."),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.accesses, function (access) {
            return access.enabled && access.company.enabled
              ? _c(
                  "el-button",
                  {
                    key: access.company_id,
                    staticClass: "mb-2 ml-0 lead",
                    on: {
                      click: function ($event) {
                        return _vm.companyLogin(access)
                      },
                    },
                  },
                  [_c("b", [_vm._v(_vm._s(access.company.name))])]
                )
              : _vm._e()
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }