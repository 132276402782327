<template>
    <div class="app-body page-ring-group-dialog"
         id="view">

        <div class="container-fluid d-relative">
            <div class="row">
                <div class="col-12 py-4">
                    <div class="card">
                        <div class="card-body">
                            <el-form ref="ring_group_form"
                                     class="p-0 form-aloware"
                                     label-position="top"
                                     v-if="ring_group"
                                     v-loading="loading"
                                     :rules="rules_ring_group"
                                     :model="ring_group"
                                     @submit.prevent.native="submitForm('ring_group_form')">
                                <el-tabs v-model="active_tab_name"
                                         @tab-click="tabClicked">
                                    <el-tab-pane name="general-settings"
                                                 class="p-2"
                                                 label="General">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <el-form-item prop="name"
                                                              class="no-border">
                                                   <span class="page-anchor"
                                                         id="name">
                                                   </span>
                                                    <div class="form-label">
                                                        <h5>Name</h5>
                                                        <small>
                                                            Use a name that is short and easily identifiable in your
                                                            reports.
                                                        </small>
                                                    </div>
                                                    <el-input v-model="ring_group.name"
                                                              @input="preValidateForm('ring_group_form')">
                                                    </el-input>
                                                </el-form-item>
                                                <el-form-item class="no-border"
                                                              prop="description">
                                                   <span class="page-anchor"
                                                         id="description">
                                                   </span>
                                                    <div class="form-label">
                                                        <h5>Description (Optional)</h5>
                                                    </div>
                                                    <el-input v-model="ring_group.description"
                                                              type="textarea"
                                                              maxlength="190"
                                                              :autosize="{ minRows: 2 }"
                                                              show-word-limit
                                                              @input="preValidateForm('ring_group_form')">
                                                    </el-input>
                                                </el-form-item>
                                                <el-form-item class="mt-4"
                                                              prop="ordered_user_team_ids">
                                                   <span class="page-anchor"
                                                         id="users">
                                                   </span>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <i class="fa fa-search-p"></i>
                                                            <div class="w-50 mx-auto text-center mb-3">
                                                                <i class="fas fa-phone mr-2 color-999"
                                                                   style="font-size: 40px"></i>
                                                                <p class="lh-1p4">
                                                                    When calls come in, it will run through the sequence of layers below.
                                                                    Each layer must contain one team, or one or more users.
                                                                    You can add and move layers as you like.
                                                                </p>
                                                            </div>

                                                            <ordered-user-team-selector ref="ordered_user_team_selector"
                                                                                        :ordered_user_ids_value="ring_group.ordered_user_ids"
                                                                                        :ordered_team_ids_value="ring_group.ordered_team_ids"
                                                                                        :disabledDialogTeam="ringGroupToTeam"
                                                                                        :ringGroup="attributesRingGroup"
                                                                                        @change="changeOrderedUsersAndTeams"
                                                                                        @ringGroupToTeam="addRingGroupToTeam">
                                                            </ordered-user-team-selector>
                                                        </div>
                                                    </div>
                                                </el-form-item>

                                                <el-form-item>
                                                   <span class="page-anchor"
                                                         id="layer_settings">
                                                   </span>
                                                    <div class="form-label mb-3">
                                                        <h5>Routing Settings</h5>
                                                        <small>Select how calls are being routed to each user of this ring group and and the dial duration.</small>
                                                    </div>

                                                    <el-popover placement="bottom-start"
                                                                :title="DIAL_MODE_SIMUL | humanReadableDialMode"
                                                                width="400"
                                                                trigger="hover"
                                                                ref="popover_simul">
                                                        <p>
                                                            Also known as SimulDial, this option rings all phones within the same order at the same time.
                                                            The first person that picks up the phone wins the call, all other rings will be disconnected.
                                                        </p>
                                                    </el-popover>

                                                    <el-popover placement="bottom-start"
                                                                :title="DIAL_MODE_RAND | humanReadableDialMode"
                                                                width="400"
                                                                trigger="hover"
                                                                ref="popover_rand">
                                                        <p>
                                                            Randomly select a user within the same order. If that user does not pick up,
                                                            randomly pick another user to offer the call to.
                                                        </p>
                                                    </el-popover>

                                                    <el-popover placement="bottom-start"
                                                                :title="DIAL_MODE_ROUND | humanReadableDialMode"
                                                                width="400"
                                                                trigger="hover"
                                                                content="Round-robin between users within the same order."
                                                                ref="popover_round">
                                                        <p>
                                                            In each layer, attempt to route the calls one by one to your reps in a round-robin fashion based on longest availability.
                                                            The user that previously answered a call will be excluded for the next attempt.
                                                            Each phone will ring until someone answers. If nobody answers, the next layer will be executed.
                                                        </p>
                                                    </el-popover>

                                                    <el-popover placement="bottom-start"
                                                                :title="DIAL_MODE_LONGEST_AVAILABLE | humanReadableDialMode"
                                                                width="400"
                                                                trigger="hover"
                                                                content="Route the calls between users within the same order based on longest availability."
                                                                ref="popover_longest_available">
                                                        <p>
                                                            In each layer, attempt to route the calls one by one to your reps based on longest availability.
                                                            Each phone will ring until someone answers. If nobody answers, the next layer will be executed. Optimized for reducing agent idle time.
                                                        </p>
                                                    </el-popover>

                                                    <div class="d-lg-flex">
                                                        <div class="mb-2">
                                                            <strong class="mr-2 align-self-center">On each layer</strong>
                                                            <el-radio-group v-model="ring_group.dial_mode" class="mr-2">
                                                                <el-radio-button :label="DIAL_MODE_SIMUL"
                                                                                 v-popover:popover_simul>
                                                                    {{ DIAL_MODE_SIMUL | humanReadableDialMode }}
                                                                </el-radio-button>
                                                                <el-radio-button :label="DIAL_MODE_RAND"
                                                                                 v-popover:popover_rand>
                                                                    {{ DIAL_MODE_RAND | humanReadableDialMode }}
                                                                </el-radio-button>
                                                                <el-radio-button :label="DIAL_MODE_ROUND"
                                                                                 v-popover:popover_round>
                                                                    {{ DIAL_MODE_ROUND | humanReadableDialMode }}
                                                                </el-radio-button>
                                                                <el-radio-button :label="DIAL_MODE_LONGEST_AVAILABLE"
                                                                                 v-popover:popover_longest_available>
                                                                    {{ DIAL_MODE_LONGEST_AVAILABLE | humanReadableDialMode }}
                                                                </el-radio-button>
                                                            </el-radio-group>
                                                        </div>
                                                        <div>
                                                            <strong class="mr-2 align-self-center">
                                                                Dial users for
                                                            </strong>
                                                            <el-select placeholder="Select"
                                                                       v-model="ring_group.call_handling_options.timeout">
                                                                <el-option v-for="item in call_timeout_options"
                                                                           :key="item.value"
                                                                           :label="item.label"
                                                                           :value="item.value">
                                                                </el-option>
                                                            </el-select>
                                                        </div>
                                                    </div>
                                                </el-form-item>

                                                <el-form-item>
                                                   <span class="page-anchor"
                                                         id="repeat_customer_call">
                                                   </span>
                                                    <div class="form-label">
                                                        <h5>Repeat Call Routing</h5>
                                                        <small>
                                                            Choose how this ring group handles repeat customer calls.
                                                        </small>
                                                    </div>
                                                    <el-radio-group v-model="ring_group.repeat_contact_route_to">
                                                        <el-radio class="d-block"
                                                                  :label="RingGroupRepeatContactTo.REPEAT_CONTACT_ROUTE_TO_RING_GROUP">
                                                            Route the call to ring group, do not respect contact ownership (default).
                                                        </el-radio>
                                                        <el-radio class="d-block"
                                                                  :label="RingGroupRepeatContactTo.REPEAT_CONTACT_ROUTE_TO_OWNER">
                                                            Route the call to the owner of the contact first and then route to the ring group.
                                                        </el-radio>
                                                        <el-radio class="d-block"
                                                                  :label="RingGroupRepeatContactTo.REPEAT_CONTACT_ROUTE_TO_OWNER_ONLY">
                                                            Route the call to the owner of the contact, and stop. For unassigned contacts, route to the ring group.
                                                        </el-radio>
                                                        <el-radio class="d-block"
                                                                  :label="RingGroupRepeatContactTo.REPEAT_CONTACT_ROUTE_TO_OWNER_ONLY_STRICT">
                                                            Route the call to the owner of the contact, and stop. For unassigned contacts, do not route.
                                                        </el-radio>
                                                        <el-radio class="d-block"
                                                                  :label="RingGroupRepeatContactTo.REPEAT_CONTACT_ROUTE_TO_PREVIOUS_USER">
                                                            Route the call to the agent who last spoke with this contact first and then route to the ring group.
                                                        </el-radio>
                                                    </el-radio-group>
                                                </el-form-item>

                                                <!-- Text Assign Mode Settings -->
                                                <el-form-item prop="text_assign_mode">
                                                   <span class="page-anchor"
                                                         id="text_assign_mode">
                                                   </span>
                                                    <div class="form-label">
                                                        <h5>Text Message Handling (Beta)</h5>
                                                        <small>Choose how this ring group handles incoming text messages.</small>
                                                    </div>
                                                    <el-radio-group class="mt-2" v-model="ring_group.text_assign_mode">
                                                        <br>
                                                        <el-radio
                                                            :label="RingGroupTextAssignModes.TEXT_ASSIGN_MODE_TO_CONTACT_OWNER">
                                                            {{
                                                                RingGroupTextAssignModes.TEXT_ASSIGN_MODE_TO_CONTACT_OWNER |
                                                                    humanReadableTextAssignMode
                                                            }}
                                                        </el-radio>
                                                        <br>
                                                        <el-radio
                                                            :label="RingGroupTextAssignModes.TEXT_ASSIGN_MODE_RANDOM_TO_AGENTS">
                                                            {{
                                                                RingGroupTextAssignModes.TEXT_ASSIGN_MODE_RANDOM_TO_AGENTS
                                                                    | humanReadableTextAssignMode
                                                            }}
                                                        </el-radio>
                                                        <br>
                                                        <el-radio
                                                            :label="RingGroupTextAssignModes.TEXT_ASSIGN_MODE_TO_LAST_CALLED_AGENT">
                                                            {{
                                                                RingGroupTextAssignModes.TEXT_ASSIGN_MODE_TO_LAST_CALLED_AGENT
                                                                    | humanReadableTextAssignMode
                                                            }}
                                                        </el-radio>
                                                        <br>
                                                        <el-radio
                                                            :label="RingGroupTextAssignModes.TEXT_ASSIGN_MODE_TO_LAST_CALLED_AGENT_REGARDLESS_OWNERSHIP">
                                                            {{
                                                                RingGroupTextAssignModes.TEXT_ASSIGN_MODE_TO_LAST_CALLED_AGENT_REGARDLESS_OWNERSHIP
                                                                    | humanReadableTextAssignMode
                                                            }}
                                                        </el-radio>
                                                        <br>
                                                        <el-radio
                                                            :label="RingGroupTextAssignModes.TEXT_ASSIGN_MODE_DO_NOTHING">
                                                            {{
                                                                RingGroupTextAssignModes.TEXT_ASSIGN_MODE_DO_NOTHING |
                                                                    humanReadableTextAssignMode
                                                            }}
                                                        </el-radio>
                                                    </el-radio-group>
                                                </el-form-item>

                                                <!-- Voicemail Settings -->
                                                <el-form-item prop="missed-call">
                                                   <span class="page-anchor"
                                                         id="missed-call">
                                                   </span>
                                                    <div class="form-label">
                                                        <h5>Missed Call Settings</h5>
                                                        <small>Ring groups can take a voicemail if no agents are available to take the call, and a call has been routed to this ring group.</small>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <el-form-item prop="missed_call_handling_mode" class="no-border">
                                                                    <el-radio-group
                                                                        v-model="ring_group.missed_calls_settings.missed_call_handling_mode"
                                                                        class="w-full mt-2">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <el-radio :label="MissedCallBehaviors.MISSED_CALL_BEHAVIOR_NOTHING">
                                                                                    Use the line's missed call settings (default)
                                                                                </el-radio>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <el-radio :label="MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL">
                                                                                    Take a voicemail
                                                                                </el-radio>
                                                                            </div>
                                                                        </div>
                                                                    </el-radio-group>
                                                                </el-form-item>
                                                            </div>
                                                        </div>
                                                        <div class="col-12"
                                                             v-show="ring_group.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL">
                                                            <div class="form-group">
                                                                <div class="b-b nav-active-greenish">
                                                                    <ul class="nav nav-tabs">
                                                                        <li class="nav-item">
                                                                            <a class="border border-right-0 rounded-top-left"
                                                                               :class="voicemailNavClass.read"
                                                                               href=""
                                                                               data-toggle="tab"
                                                                               data-target="#voicemail-tts"
                                                                               aria-expanded="true">
                                                                                <i class="fa fa-robot"></i>
                                                                                Text to Speech
                                                                            </a>
                                                                        </li>
                                                                        <li class="nav-item"
                                                                            v-if="hasPermissionTo('upload file campaign')">
                                                                            <a class="border rounded-top-right"
                                                                               :class="voicemailNavClass.play"
                                                                               href=""
                                                                               data-toggle="tab"
                                                                               data-target="#voicemail-file"
                                                                               aria-expanded="false">
                                                                                <i class="fa fa-play text-md"></i>
                                                                                Play Recording
                                                                            </a>
                                                                        </li>
                                                                        <li class="nav-item"
                                                                            v-if="ring_group.missed_calls_settings.voicemail_file">
                                                                            <a class="nav-link"
                                                                               @click.prevent="deleteFile('voicemail')">
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="tab-content p-a m-b-md border border-top-0">
                                                                    <div :class="voicemailContainerClass.read"
                                                                         id="voicemail-tts"
                                                                         aria-expanded="true">
                                                                        <el-form-item label="Voicemail Message"
                                                                                      class="no-border mb-4"
                                                                                      prop="voicemail">
                                                                            <el-popover ref="popover_voicemail"
                                                                                        placement="bottom-start"
                                                                                        title="Voicemail"
                                                                                        width="200"
                                                                                        trigger="hover"
                                                                                        content="We are sorry, but no agents
                                                                                      are available to take this call.
                                                                                      Please leave your message after the beep.">
                                                                            </el-popover>
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <div class="input-group">
                                                                                        <input type="text"
                                                                                               class="form-control pt-2 pb-2"
                                                                                               v-model="ring_group.missed_calls_settings.voicemail_tts"
                                                                                               @input="preValidateForm('ring_group_form')"/>
                                                                                        <variable-dialog></variable-dialog>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </el-form-item>
                                                                    </div>
                                                                    <div :class="voicemailContainerClass.play"
                                                                         id="voicemail-file"
                                                                         aria-expanded="false"
                                                                         v-if="hasPermissionTo('upload file campaign')">
                                                                        <audio-recorder
                                                                            v-if="ring_group.missed_calls_settings.voicemail_file === null"
                                                                            class="audio-recorder-wrapper"
                                                                            :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/voicemails'"
                                                                            :upload_type="'voicemail_file'"
                                                                            @applyUploadedAudio="applyUploadedAudio">
                                                                        </audio-recorder>
                                                                        <div class="audio-uploader-wrapper">
                                                                            <el-upload
                                                                                v-if="ring_group.missed_calls_settings.voicemail_file === null"
                                                                                drag
                                                                                :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/voicemails'"
                                                                                :headers="headers"
                                                                                :on-success="onSuccessVoicemail"
                                                                                :on-error="onFailedVoicemail"
                                                                                :on-progress="progressUploadVoicemail"
                                                                                :before-upload="beforeUploadVoicemail"
                                                                                :file-list="uploadFileList.voicemail">
                                                                                <i class="el-icon-upload"></i>
                                                                                <div class="el-upload__text">
                                                                                    Drop file here or <em>click to
                                                                                    upload</em>
                                                                                </div>
                                                                                <div class="el-upload__tip"
                                                                                     slot="tip">
                                                                                    MP3/WAV file (less than 8MB)
                                                                                </div>
                                                                            </el-upload>
                                                                            <el-progress :text-inside="true"
                                                                                         :stroke-width="18"
                                                                                         :percentage="uploadPercentage.voicemail"
                                                                                         :status="uploadStatus.voicemail"
                                                                                         v-if="ring_group.missed_calls_settings.voicemail_file === null">
                                                                            </el-progress>
                                                                            <audio v-if="ring_group.missed_calls_settings.voicemail_file !== null"
                                                                                   controls>
                                                                                <source :src="'/static/uploaded_file/' + ring_group.missed_calls_settings.voicemail_file">
                                                                                Your browser does not support the audio
                                                                                element.
                                                                            </audio>
                                                                            <button class="btn btn-sm btn-danger pull-right"
                                                                                    v-if="ring_group.missed_calls_settings.voicemail_file !== null && hasPermissionTo('delete file campaign')"
                                                                                    @click.prevent="deleteFile('voicemails')">
                                                                                <i class="material-icons loader pull-left"
                                                                                   v-show="loading_voicemail">&#xE863;</i>
                                                                                <i class="material-icons pull-left"
                                                                                   v-show="!loading_voicemail">&#xE872;</i>
                                                                                <span>Remove file</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-form-item>

                                                <el-form-item prop="enable_sms_notifications">
                                                    <span class="page-anchor"
                                                          id="enable_sms_notifications">
                                                    </span>
                                                    <div class="form-label mb-3">
                                                        <h5 class="mb-0">Inbound Text Notifications</h5>
                                                        <small>
                                                            By enabling this option, users on this ring group will receive notifications for inbound texts coming to this ring group if they have access
                                                            to the contact.
                                                        </small>
                                                    </div>
                                                    <div class="switch-label">
                                                        <el-switch
                                                            v-model="ring_group.enable_sms_notifications"
                                                            active-color="#00a344"
                                                            @change="preValidateForm('ring_group_form')">
                                                        </el-switch>
                                                        <label>Enable Inbound Text Notifications?</label>
                                                    </div>
                                                </el-form-item>

                                                <el-form-item prop="enable_voicemail_notifications"
                                                              class="mb-2 no-border">
                                                    <span class="page-anchor"
                                                          id="enable_voicemail_notifications">
                                                    </span>
                                                    <div class="form-label mb-3">
                                                        <h5 class="mb-0">Voicemail Notifications</h5>
                                                        <small>
                                                            Send voicemail notifications to all users of this ring group with access to the contact.
                                                        </small>
                                                    </div>
                                                    <div class="switch-label">
                                                        <el-switch
                                                            v-model="ring_group.enable_voicemail_notifications"
                                                            active-color="#00a344"
                                                            @change="preValidateForm('ring_group_form')">
                                                        </el-switch>
                                                        <label>Enable Voicemail Notifications?</label>
                                                    </div>
                                                </el-form-item>
                                            </div>
                                            <div class="col-3 d-none d-md-block ml-auto">
                                                <div
                                                    class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                                                    <h6 class="list-group-item">
                                                        On This Page
                                                    </h6>
                                                    <a href="#name"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('name')">
                                                        Name
                                                    </a>
                                                    <a href="#description"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('description')">
                                                        Description
                                                    </a>
                                                    <a href="#users"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('users')">
                                                        Ring group builder
                                                    </a>
                                                    <a href="#layer_settings"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('layer_settings')">
                                                        Routing Settings
                                                    </a>
                                                    <a href="#repeat_customer_call"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('repeat_customer_call')">
                                                        Repeat Call routing
                                                    </a>
                                                    <a href="#text_assign_mode"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('text_assign_mode')">
                                                        Text Message Handling
                                                    </a>
                                                    <a href="#missed-call"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('missed-call')">
                                                        Missed Call Settings
                                                    </a>
                                                    <a href="#enable_sms_notifications"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('enable_sms_notifications')">
                                                        Inbound Text Notifications
                                                    </a>
                                                    <a href="#enable_voicemail_notifications"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('enable_voicemail_notifications')">
                                                        Voicemail Notifications
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane name="queue-settings"
                                                 class="p-2"
                                                 label="Queue">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <el-form-item>
                                                    <span class="page-anchor"
                                                          id="smart_queue">
                                                    </span>
                                                    <div class="form-label mb-3">
                                                        <h5 class="mb-0">Smart Queue</h5>
                                                        <small>
                                                            If you receive more calls than your agents can answer, calls will be placed in a waiting queue.
                                                            As soon as an agent becomes available, call will be dispatched (best for contact center).
                                                        </small>
                                                    </div>
                                                    <div class="switch-label">
                                                        <el-switch
                                                            v-model="ring_group.should_queue"
                                                            active-color="#00a344"
                                                            :disabled="!current_company.conferencing_enabled"
                                                            @change="onChangeShouldQueue()">
                                                        </el-switch>
                                                        <label>Smart Queue</label>
                                                    </div>
                                                </el-form-item>

                                                <el-form-item v-show="ring_group.should_queue && current_company && current_company.conferencing_enabled">
                                                    <span class="page-anchor"
                                                          id="fishing_mode">
                                                    </span>
                                                    <div class="form-label mb-3">
                                                        <h5 class="mb-0">Fishing Mode (Beta)</h5>
                                                        <small>
                                                            When enabled, fishing mode notifications will simultaneously notify all users within a ring group at the same time. Fishing Mode will still
                                                            respect user agent status, operating hours, area code and state restrictions.
                                                        </small>
                                                    </div>
                                                    <div class="switch-label">
                                                        <el-switch
                                                            v-model="ring_group.fishing_mode"
                                                            active-color="#00a344"
                                                            @change="preValidateForm('ring_group_form')">
                                                        </el-switch>
                                                        <label>Enable Fishing Mode?</label>
                                                    </div>
                                                </el-form-item>

                                                <el-form-item v-show="ring_group.should_queue">
                                                    <span class="page-anchor"
                                                          id="queue_should_say_caller_position">
                                                    </span>
                                                    <div class="form-label mb-3">
                                                        <h5 class="mb-0">Say Caller's Position In Queue</h5>
                                                        <small>
                                                            Notify the caller of their position in queue.
                                                        </small>
                                                    </div>
                                                    <div class="switch-label mb-4">
                                                        <el-switch
                                                            v-model="ring_group.queue_should_say_caller_position"
                                                            active-color="#00a344"
                                                            @change="preValidateForm('ring_group_form')">
                                                        </el-switch>
                                                        <label>
                                                            Should Say Caller's Position In Queue?
                                                        </label>
                                                    </div>
                                                </el-form-item>

                                                <el-form-item v-show="ring_group.should_queue">
                                                    <span class="page-anchor"
                                                          id="show_custom_wait_music">
                                                    </span>
                                                    <div class="form-label mb-3">
                                                        <h5 class="mb-0">Wait Music</h5>
                                                        <small>
                                                            Here you can customize the wait music for this ring group. Leave blank to use our default wait music.
                                                        </small>
                                                    </div>
                                                    <div class="switch-label mb-4">
                                                        <el-switch
                                                            v-model="show_custom_wait_music"
                                                            active-color="#00a344">
                                                        </el-switch>
                                                        <label class="el-form-item__label ml-2">
                                                            Customize wait music
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div v-if="show_custom_wait_music && hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                                                            <audio-recorder v-if="ring_group.queue_wait_music_file === null"
                                                                            class="audio-recorder-wrapper"
                                                                            :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/queue_wait_musics'"
                                                                            :upload_type="'queue_wait_music_file'"
                                                                            @applyUploadedAudio="applyUploadedAudio">
                                                            </audio-recorder>
                                                            <div class="audio-uploader-wrapper">
                                                                <el-upload v-if="ring_group.queue_wait_music_file === null"
                                                                           :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/queue_wait_musics'"
                                                                           :headers="headers"
                                                                           :on-success="onSuccessWaitMusic"
                                                                           :on-error="onFailedWaitMusic"
                                                                           :on-progress="progressUploadWaitMusic"
                                                                           :before-upload="beforeUploadWaitMusic"
                                                                           :file-list="uploadFileList.queueWaitMusic"
                                                                           drag>
                                                                    <i class="el-icon-upload"></i>
                                                                    <div class="el-upload__text">
                                                                        Drop file here or <em>click to upload</em>
                                                                    </div>
                                                                    <div class="el-upload__tip"
                                                                         slot="tip">
                                                                        MP3/WAV file (less than 8MB)
                                                                    </div>
                                                                </el-upload>
                                                                <el-progress :text-inside="true"
                                                                             :stroke-width="18"
                                                                             :percentage="uploadPercentage.queueWaitMusic"
                                                                             :status="uploadStatus.queueWaitMusic"
                                                                             v-if="ring_group.queue_wait_music_file === null">
                                                                </el-progress>
                                                                <audio v-if="ring_group.queue_wait_music_file !== null"
                                                                       controls>
                                                                    <source :src="'/static/uploaded_file/' + ring_group.queue_wait_music_file">
                                                                    Your browser does not support the audio element.
                                                                </audio>
                                                                <button class="btn btn-sm btn-danger pull-right"
                                                                        v-if="ring_group.queue_wait_music_file !== null && hasPermissionTo('delete ring group')"
                                                                        @click.prevent="deleteFile('queue_wait_musics')">
                                                                    <i class="material-icons loader pull-left"
                                                                       v-show="loading_queue_wait_music">&#xE863;</i>
                                                                    <i class="material-icons pull-left"
                                                                       v-show="!loading_queue_wait_music">&#xE872;</i>
                                                                    <span>Remove file</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-form-item>

                                                <el-form-item v-show="ring_group.should_queue">
                                                    <span class="page-anchor"
                                                          id="queue_repeat_prompts_delay">
                                                    </span>
                                                    <div class="form-label mb-3">
                                                        <h5 class="mb-0">Repeat Prompts</h5>
                                                        <small>
                                                            Configure how frequently the system offers those prompts. Best is to use 30 seconds.
                                                        </small>
                                                    </div>
                                                    <el-select placeholder="Select"
                                                               v-model="ring_group.queue_repeat_prompts_delay">
                                                        <el-option
                                                            v-for="item in queue_repeat_prompts_delay_options"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>

                                                <el-form-item v-show="ring_group.should_queue"
                                                              class="no-border">
                                                    <span class="page-anchor"
                                                          id="max_wait_time">
                                                    </span>
                                                    <div class="form-label mb-3">
                                                        <h5 class="mb-0">Max Queue Hold Time</h5>
                                                        <small>
                                                            Max queue hold time is the maximum amount of time a call can stay on wait in the queued state.
                                                            After this period the call will be terminated and it will be considered as missed.
                                                            Please check Call Behavior > Missed Calls section to decide on what happens to call before termination.
                                                        </small>
                                                    </div>
                                                    <el-select class="mb-4"
                                                               placeholder="Select"
                                                               v-model="ring_group.max_wait_time">
                                                        <el-option v-for="item in max_wait_time_options"
                                                                   :key="item.value"
                                                                   :label="item.label"
                                                                   :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                    <div class="switch-label">
                                                        <el-switch
                                                            v-model="ring_group.queue_send_voicemail_when_no_agents"
                                                            active-color="#00a344">
                                                        </el-switch>
                                                        <label class="el-form-item__label ml-2">
                                                            Send to voicemail when no agents are available
                                                        </label>
                                                    </div>
                                                </el-form-item>
                                            </div>
                                            <div class="col-3 d-none d-md-block ml-auto">
                                                <div
                                                    class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                                                    <h6 class="list-group-item">
                                                        On This Page
                                                    </h6>
                                                    <a href="#smart_queue"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('smart_queue')">
                                                        Smart Queue
                                                    </a>
                                                    <a v-if="ring_group.should_queue && current_company && [7, 47, 460, 461, 574, 585, 662, 708, 775, 945, 1070, 1134, 1339, 1461].includes(current_company.id) && current_company.conferencing_enabled"
                                                       href="#fishing_mode"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('fishing_mode')">
                                                        Fishing Mode (Beta)
                                                    </a>
                                                    <a v-if="ring_group.should_queue"
                                                       href="#queue_should_say_caller_position"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('queue_should_say_caller_position')">
                                                        Say Caller's Position In Queue
                                                    </a>
                                                    <a v-if="ring_group.should_queue"
                                                       href="#queue_should_ask_for_callback"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('queue_should_ask_for_callback')">
                                                        Offer Callback Option
                                                    </a>
                                                    <a v-if="ring_group.should_queue"
                                                       href="#queue_should_handle_by_text"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('queue_should_handle_by_text')">
                                                        Offer Handle By Text Option
                                                    </a>
                                                    <a v-if="ring_group.should_queue"
                                                       href="#queue_should_ask_for_voicemail"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('queue_should_ask_for_voicemail')">
                                                        Offer Voicemail Option
                                                    </a>
                                                    <a v-if="ring_group.should_queue"
                                                       href="#show_custom_wait_music"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('show_custom_wait_music')">
                                                        Wait Music
                                                    </a>
                                                    <a v-if="ring_group.should_queue"
                                                       href="#queue_repeat_prompts_delay"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('queue_repeat_prompts_delay')">
                                                        Repeat Prompts
                                                    </a>
                                                    <a v-if="ring_group.should_queue"
                                                       href="#max_wait_time"
                                                       class="list-group-item list-group-item-action"
                                                       @click="shine('max_wait_time')">
                                                        Max Queue Hold Time
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane name="advanced-queue-settings"
                                                 class="p-2"
                                                 label="Advanced Queue Settings"
                                                 :disabled="!ring_group.should_queue">
                                        <div class="row">
                                            <div class="col-md-12"
                                                 v-if="isBlockedFrom('ring group advanced queue settings')">
                                                <upgrade-now image-link="/assets/images/fomo/AdvancedQueueSettings.png"
                                                             kb-link="https://support.aloware.com/en/articles/9031514-mapping-ring-groups-for-inbound-calls-in-aloware"
                                                             text="Advanced Queue Settings is not included in your current plan. To use it, please contact us to upgrade today!"
                                                             title-text="Advanced Queue Settings"
                                                             v-if="shouldShowUpgradeNow"
                                                             :default-click="true">
                                                </upgrade-now>
                                            </div>
                                            <template v-else>
                                                <div class="col-md-8">
                                                    <el-form-item>
                                                        <span class="page-anchor"
                                                              id="queue_should_ask_for_callback">
                                                        </span>
                                                        <div class="form-label mb-3">
                                                            <h5 class="mb-0">Offer Callback Option</h5>
                                                            <small>
                                                                Offer the caller a choice to keep their position in the queue
                                                                by hanging up and receiving a call back when it's their turn. Caller will be asked to press ONE (1) to confirm.
                                                            </small>
                                                        </div>

                                                        <div class="switch-label mb-4">
                                                            <el-switch
                                                                v-model="ring_group.queue_should_ask_for_callback"
                                                                active-color="#00a344"
                                                                @change="preValidateForm('ring_group_form')">
                                                            </el-switch>
                                                            <label>
                                                                Offer Callback Option
                                                            </label>
                                                        </div>

                                                        <div class="b-b nav-active-greenish"
                                                             v-if="ring_group.queue_should_ask_for_callback">
                                                            <ul class="nav nav-tabs">
                                                                <li class="nav-item">
                                                                    <a class="border border-right-0 rounded-top-left"
                                                                       :class="queueCallbackNavClass.read"
                                                                       href=""
                                                                       data-toggle="tab"
                                                                       data-target="#ask-for-callback-tts"
                                                                       aria-expanded="true">
                                                                        <i class="fa fa-robot"></i>
                                                                        Text to Speech
                                                                    </a>
                                                                </li>
                                                                <li class="nav-item"
                                                                    v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                                                                    <a class="border rounded-top-right"
                                                                       :class="queueCallbackNavClass.play"
                                                                       href=""
                                                                       data-toggle="tab"
                                                                       data-target="#ask-for-callback-file"
                                                                       aria-expanded="false">
                                                                        <i class="fa fa-play text-md"></i>
                                                                        Play Recording
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div class="tab-content p-a m-b-md border border-top-0"
                                                             v-if="ring_group.queue_should_ask_for_callback">
                                                            <div :class="queueCallbackContainerClass.read"
                                                                 id="ask-for-callback-tts"
                                                                 aria-expanded="true">
                                                                <el-form-item label="Prompt"
                                                                              prop="queue_should_ask_for_callback"
                                                                              class="mb-4">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="input-group">
                                                                                <input type="text"
                                                                                       class="form-control pt-2 pb-2"
                                                                                       v-model="ring_group.queue_ask_for_callback_tts"
                                                                                       @input="preValidateForm('ring_group_form')"/>
                                                                                <variable-dialog></variable-dialog>
                                                                            </div>
                                                                            <template-list-dialog :text_button="true"/>
                                                                        </div>
                                                                    </div>
                                                                </el-form-item>
                                                            </div>
                                                            <div :class="queueCallbackContainerClass.play"
                                                                 id="ask-for-callback-file"
                                                                 aria-expanded="false"
                                                                 v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                                                                <audio-recorder
                                                                    v-if="ring_group.queue_ask_for_callback_file === null"
                                                                    class="audio-recorder-wrapper"
                                                                    :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/queue_callbacks'"
                                                                    :upload_type="'queue_ask_for_callback_file'"
                                                                    @applyUploadedAudio="applyUploadedAudio">
                                                                </audio-recorder>
                                                                <div class="audio-uploader-wrapper">
                                                                    <el-upload
                                                                        v-if="ring_group.queue_ask_for_callback_file === null"
                                                                        :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/queue_callbacks'"
                                                                        :headers="headers"
                                                                        :on-success="onSuccessCallback"
                                                                        :on-error="onFailedCallback"
                                                                        :on-progress="progressUploadCallback"
                                                                        :before-upload="beforeUploadCallback"
                                                                        :file-list="uploadFileList.queueAskForCallback"
                                                                        drag>
                                                                        <i class="el-icon-upload"></i>
                                                                        <div class="el-upload__text">
                                                                            Drop file here or <em>click to upload</em>
                                                                        </div>
                                                                        <div class="el-upload__tip"
                                                                             slot="tip">
                                                                            MP3/WAV file (less than 8MB)
                                                                        </div>
                                                                    </el-upload>
                                                                    <el-progress :text-inside="true"
                                                                                 :stroke-width="18"
                                                                                 :percentage="uploadPercentage.queueAskForCallback"
                                                                                 :status="uploadStatus.queueAskForCallback"
                                                                                 v-if="ring_group.queue_ask_for_callback_file === null">
                                                                    </el-progress>
                                                                    <audio v-if="ring_group.queue_ask_for_callback_file !== null"
                                                                           controls>
                                                                        <source :src="'/static/uploaded_file/' + ring_group.queue_ask_for_callback_file">
                                                                        Your browser does not support the audio element.
                                                                    </audio>
                                                                    <button class="btn btn-sm btn-danger pull-right"
                                                                            v-if="ring_group.queue_ask_for_callback_file !== null && hasPermissionTo('delete ring group')"
                                                                            @click.prevent="deleteFile('queue_callbacks')">
                                                                        <i class="material-icons loader pull-left"
                                                                           v-show="loading_queue_ask_for_callback">&#xE863;</i>
                                                                        <i class="material-icons pull-left"
                                                                           v-show="!loading_queue_ask_for_callback">&#xE872;</i>
                                                                        <span>Remove file</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </el-form-item>

                                                    <el-form-item>
                                                        <span class="page-anchor"
                                                              id="queue_should_handle_by_text">
                                                        </span>
                                                        <div class="form-label mb-3">
                                                            <h5 class="mb-0">Offer Handle By Text Option</h5>
                                                            <small>
                                                                Ask the caller to hangup and handle their issue via text instead.
                                                                Useful for small requests, like appointment modifications or status checks.
                                                                Caller will be asked to press TWO (2) to confirm.
                                                            </small>
                                                        </div>
                                                        <div class="switch-label mb-4">
                                                            <el-switch
                                                                v-model="ring_group.queue_should_handle_by_text"
                                                                active-color="#00a344"
                                                                @change="preValidateForm('ring_group_form')">
                                                            </el-switch>
                                                            <label>
                                                                Offer Handle By Text Option
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <div class="b-b nav-active-greenish"
                                                                 v-if="ring_group.queue_should_handle_by_text">
                                                                <ul class="nav nav-tabs">
                                                                    <li class="nav-item">
                                                                        <a class="border border-right-0 rounded-top-left"
                                                                           :class="queueHandleByTextNavClass.read"
                                                                           href=""
                                                                           data-toggle="tab"
                                                                           data-target="#handle-by-text-tts"
                                                                           aria-expanded="true">
                                                                            <i class="fa fa-robot"></i>
                                                                            Text to Speech
                                                                        </a>
                                                                    </li>
                                                                    <li class="nav-item"
                                                                        v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                                                                        <a class="border rounded-top-right"
                                                                           :class="queueHandleByTextNavClass.play"
                                                                           href=""
                                                                           data-toggle="tab"
                                                                           data-target="#handle-by-text-file"
                                                                           aria-expanded="false">
                                                                            <i class="fa fa-play text-md"></i>
                                                                            Play Recording
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div class="tab-content p-a m-b-md border border-top-0"
                                                                 v-if="ring_group.queue_should_handle_by_text">
                                                                <div :class="queueHandleByTextContainerClass.read"
                                                                     id="handle-by-text-tts"
                                                                     aria-expanded="true">
                                                                    <el-form-item label="Prompt"
                                                                                  prop="queue_should_handle_by_text"
                                                                                  class="mb-4">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="input-group">
                                                                                    <input type="text"
                                                                                           class="form-control pt-2 pb-2"
                                                                                           v-model="ring_group.queue_handle_by_text_tts"
                                                                                           @input="preValidateForm('ring_group_form')"/>
                                                                                    <variable-dialog></variable-dialog>
                                                                                </div>
                                                                                <template-list-dialog :text_button="true"/>
                                                                            </div>
                                                                        </div>
                                                                    </el-form-item>
                                                                </div>
                                                                <div :class="queueHandleByTextContainerClass.play"
                                                                     id="handle-by-text-file"
                                                                     aria-expanded="false"
                                                                     v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                                                                    <audio-recorder
                                                                        v-if="ring_group.queue_handle_by_text_file === null"
                                                                        class="audio-recorder-wrapper"
                                                                        :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/queue_handle_by_texts'"
                                                                        :upload_type="'queue_handle_by_text_file'"
                                                                        @applyUploadedAudio="applyUploadedAudio">
                                                                    </audio-recorder>
                                                                    <div class="audio-uploader-wrapper">
                                                                        <el-upload
                                                                            v-if="ring_group.queue_handle_by_text_file === null"
                                                                            :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/queue_handle_by_texts'"
                                                                            :headers="headers"
                                                                            :on-success="onSuccessHandleByText"
                                                                            :on-error="onFailedHandleByText"
                                                                            :on-progress="progressUploadHandleByText"
                                                                            :before-upload="beforeUploadHandleByText"
                                                                            :file-list="uploadFileList.queueHandleByText"
                                                                            drag>
                                                                            <i class="el-icon-upload"></i>
                                                                            <div class="el-upload__text">
                                                                                Drop file here or <em>click to upload</em>
                                                                            </div>
                                                                            <div class="el-upload__tip"
                                                                                 slot="tip">
                                                                                MP3/WAV file (less than 8MB)
                                                                            </div>
                                                                        </el-upload>
                                                                        <el-progress :text-inside="true"
                                                                                     :stroke-width="18"
                                                                                     :percentage="uploadPercentage.queueHandleByText"
                                                                                     :status="uploadStatus.queueHandleByText"
                                                                                     v-if="ring_group.queue_handle_by_text_file === null">
                                                                        </el-progress>
                                                                        <audio v-if="ring_group.queue_handle_by_text_file !== null"
                                                                               controls>
                                                                            <source :src="'/static/uploaded_file/' + ring_group.queue_handle_by_text_file">
                                                                            Your browser does not support the audio element.
                                                                        </audio>
                                                                        <button class="btn btn-sm btn-danger pull-right"
                                                                                v-if="ring_group.queue_handle_by_text_file !== null && hasPermissionTo('delete ring group')"
                                                                                @click.prevent="deleteFile('queue_handle_by_texts')">
                                                                            <i class="material-icons loader pull-left"
                                                                               v-show="loading_queue_handle_by_text">&#xE863;</i>
                                                                            <i class="material-icons pull-left"
                                                                               v-show="!loading_queue_handle_by_text">&#xE872;</i>
                                                                            <span>Remove file</span>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <el-form-item label="Text message to be sent"
                                                                              prop="queue_handle_by_text_response_tts">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="input-group">
                                                                                <input type="text"
                                                                                       class="form-control pt-2 pb-2"
                                                                                       v-model="ring_group.queue_handle_by_text_response_tts"
                                                                                       @input="preValidateForm('ring_group_form')"/>
                                                                                <variable-dialog></variable-dialog>
                                                                            </div>
                                                                            <template-list-dialog :text_button="true"/>
                                                                        </div>
                                                                    </div>
                                                                </el-form-item>
                                                            </div>
                                                        </div>
                                                    </el-form-item>

                                                    <el-form-item>
                                                        <span class="page-anchor"
                                                              id="queue_should_ask_for_voicemail">
                                                        </span>
                                                        <div class="form-label mb-3">
                                                            <h5 class="mb-0">Offer Voicemail Option</h5>
                                                            <small>
                                                                Callers can press THREE (3) and leave a voicemail rather than staying in the queue.
                                                            </small>
                                                        </div>
                                                        <div class="switch-label mb-4">
                                                            <el-switch
                                                                v-model="ring_group.queue_should_ask_for_voicemail"
                                                                active-color="#00a344"
                                                                @change="preValidateForm('ring_group_form')">
                                                            </el-switch>
                                                            <label>
                                                                Offer Voicemail Option
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <div class="b-b nav-active-greenish"
                                                                 v-if="ring_group.queue_should_ask_for_voicemail">
                                                                <ul class="nav nav-tabs">
                                                                    <li class="nav-item">
                                                                        <a class="border border-right-0 rounded-top-left"
                                                                           :class="queueVoicemailNavClass.read"
                                                                           href=""
                                                                           data-toggle="tab"
                                                                           data-target="#ask-for-voicemail-tts"
                                                                           aria-expanded="true">
                                                                            <i class="fa fa-robot"></i>
                                                                            Text to Speech
                                                                        </a>
                                                                    </li>
                                                                    <li class="nav-item"
                                                                        v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                                                                        <a class="border rounded-top-right"
                                                                           :class="queueVoicemailNavClass.play"
                                                                           href=""
                                                                           data-toggle="tab"
                                                                           data-target="#ask-for-voicemail-file"
                                                                           aria-expanded="false">
                                                                            <i class="fa fa-play text-md"></i>
                                                                            Play Recording
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div class="tab-content p-a m-b-md border border-top-0"
                                                                 v-if="ring_group.queue_should_ask_for_voicemail">
                                                                <div :class="queueVoicemailContainerClass.read"
                                                                     id="ask-for-voicemail-tts"
                                                                     aria-expanded="true">
                                                                    <el-form-item label="Prompt"
                                                                                  prop="queue_should_ask_for_voicemail"
                                                                                  class="mb-4">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="input-group">
                                                                                    <input type="text"
                                                                                           class="form-control pt-2 pb-2"
                                                                                           v-model="ring_group.queue_ask_for_voicemail_tts"
                                                                                           @input="preValidateForm('ring_group_form')"/>
                                                                                    <variable-dialog></variable-dialog>
                                                                                </div>
                                                                                <template-list-dialog :text_button="true"/>
                                                                            </div>
                                                                        </div>
                                                                    </el-form-item>
                                                                </div>
                                                                <div :class="queueVoicemailContainerClass.play"
                                                                     id="ask-for-voicemail-file"
                                                                     aria-expanded="false"
                                                                     v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                                                                    <audio-recorder
                                                                        v-if="ring_group.queue_ask_for_voicemail_file === null"
                                                                        class="audio-recorder-wrapper"
                                                                        :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/queue_voicemails'"
                                                                        :upload_type="'queue_ask_for_voicemail_file'"
                                                                        @applyUploadedAudio="applyUploadedAudio">
                                                                    </audio-recorder>
                                                                    <div class="audio-uploader-wrapper">
                                                                        <el-upload
                                                                            v-if="ring_group.queue_ask_for_voicemail_file === null"
                                                                            :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/queue_voicemails'"
                                                                            :headers="headers"
                                                                            :on-success="onSuccessQueueVoicemail"
                                                                            :on-error="onFailedQueueVoicemail"
                                                                            :on-progress="progressUploadQueueVoicemail"
                                                                            :before-upload="beforeUploadQueueVoicemail"
                                                                            :file-list="uploadFileList.queueAskForVoicemail"
                                                                            drag>
                                                                            <i class="el-icon-upload"></i>
                                                                            <div class="el-upload__text">
                                                                                Drop file here or <em>click to upload</em>
                                                                            </div>
                                                                            <div class="el-upload__tip"
                                                                                 slot="tip">
                                                                                MP3/WAV file (less than 8MB)
                                                                            </div>
                                                                        </el-upload>
                                                                        <el-progress :text-inside="true"
                                                                                     :stroke-width="18"
                                                                                     :percentage="uploadPercentage.queueAskForVoicemail"
                                                                                     :status="uploadStatus.queueAskForVoicemail"
                                                                                     v-if="ring_group.queue_ask_for_voicemail_file === null">
                                                                        </el-progress>
                                                                        <audio v-if="ring_group.queue_ask_for_voicemail_file !== null"
                                                                               controls>
                                                                            <source :src="'/static/uploaded_file/' + ring_group.queue_ask_for_voicemail_file">
                                                                            Your browser does not support the audio element.
                                                                        </audio>
                                                                        <button class="btn btn-sm btn-danger pull-right"
                                                                                v-if="ring_group.queue_ask_for_voicemail_file !== null && hasPermissionTo('delete ring group')"
                                                                                @click.prevent="deleteFile('queue_voicemails')">
                                                                            <i class="material-icons loader pull-left"
                                                                               v-show="loading_queue_ask_for_voicemail">&#xE863;</i>
                                                                            <i class="material-icons pull-left"
                                                                               v-show="!loading_queue_ask_for_voicemail">&#xE872;</i>
                                                                            <span>Remove file</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </el-form-item>

                                                    <el-form-item>
                                                        <span class="page-anchor"
                                                              id="queue_should_call_screen">
                                                        </span>
                                                        <div class="form-label mb-3">
                                                            <h5 class="mb-0">Press One</h5>
                                                            <small>
                                                                Require called parties to press one to accept the call.
                                                            </small>
                                                        </div>
                                                        <div class="switch-label mb-4">
                                                            <el-switch
                                                                v-model="ring_group.queue_should_call_screen"
                                                                active-color="#00a344"
                                                                @change="preValidateForm('ring_group_form')">
                                                            </el-switch>
                                                            <label>
                                                                Enable Press One
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <div class="b-b nav-active-greenish"
                                                                 v-if="ring_group.queue_should_call_screen">
                                                                <ul class="nav nav-tabs">
                                                                    <li class="nav-item">
                                                                        <a class="border border-right-0 rounded-top-left"
                                                                           :class="queueCallScreenNavClass.read"
                                                                           href=""
                                                                           data-toggle="tab"
                                                                           data-target="#call-screen-tts"
                                                                           aria-expanded="true">
                                                                            <i class="fa fa-robot"></i>
                                                                            Text to Speech
                                                                        </a>
                                                                    </li>
                                                                    <li class="nav-item"
                                                                        v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                                                                        <a class="border rounded-top-right"
                                                                           :class="queueCallScreenNavClass.play"
                                                                           href=""
                                                                           data-toggle="tab"
                                                                           data-target="#call-screen-file"
                                                                           aria-expanded="false">
                                                                            <i class="fa fa-play text-md"></i>
                                                                            Play Recording
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="tab-content p-a m-b-md border border-top-0"
                                                                 v-if="ring_group.queue_should_call_screen">
                                                                <div :class="queueCallScreenContainerClass.read"
                                                                     id="call-screen-tts"
                                                                     aria-expanded="true">
                                                                    <el-form-item label="Prompt"
                                                                                  prop="queue_call_screen_tts"
                                                                                  class="mb-4">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="input-group">
                                                                                    <input type="text"
                                                                                           class="form-control pt-2 pb-2"
                                                                                           v-model="ring_group.queue_call_screen_tts"
                                                                                           @input="preValidateForm('ring_group_form')"/>
                                                                                    <variable-dialog></variable-dialog>
                                                                                </div>
                                                                                <template-list-dialog :text_button="true"/>
                                                                            </div>
                                                                        </div>
                                                                    </el-form-item>
                                                                </div>
                                                                <div :class="queueCallScreenContainerClass.play"
                                                                     id="call-screen-file"
                                                                     aria-expanded="false"
                                                                     v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                                                                    <audio-recorder
                                                                        v-if="ring_group.queue_call_screen_file === null"
                                                                        class="audio-recorder-wrapper"
                                                                        :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/queue_call_screens'"
                                                                        :upload_type="'queue_call_screen_file'"
                                                                        @applyUploadedAudio="applyUploadedAudio">
                                                                    </audio-recorder>
                                                                    <div class="audio-uploader-wrapper">
                                                                        <el-upload
                                                                            v-if="ring_group.queue_call_screen_file === null"
                                                                            :action="'/api/v1/ring-group/' + ring_group.id + '/upload-file/queue_call_screens'"
                                                                            :headers="headers"
                                                                            :on-success="onSuccessCallScreen"
                                                                            :on-error="onFailedCallScreen"
                                                                            :on-progress="progressUploadCallScreen"
                                                                            :before-upload="beforeUploadCallScreen"
                                                                            :file-list="uploadFileList.queueCallScreen"
                                                                            drag>
                                                                            <i class="el-icon-upload"></i>
                                                                            <div class="el-upload__text">
                                                                                Drop file here or <em>click to upload</em>
                                                                            </div>
                                                                            <div class="el-upload__tip"
                                                                                 slot="tip">
                                                                                MP3/WAV file (less than 8MB)
                                                                            </div>
                                                                        </el-upload>
                                                                        <el-progress :text-inside="true"
                                                                                     :stroke-width="18"
                                                                                     :percentage="uploadPercentage.queueCallScreen"
                                                                                     :status="uploadStatus.queueCallScreen"
                                                                                     v-if="ring_group.queue_call_screen_file === null">
                                                                        </el-progress>
                                                                        <audio v-if="ring_group.queue_call_screen_file !== null"
                                                                               controls>
                                                                            <source :src="'/static/uploaded_file/' + ring_group.queue_call_screen_file">
                                                                            Your browser does not support the audio element.
                                                                        </audio>
                                                                        <button class="btn btn-sm btn-danger pull-right"
                                                                                v-if="ring_group.queue_call_screen_file !== null && hasPermissionTo('delete ring group')"
                                                                                @click.prevent="deleteFile('queue_call_screens')">
                                                                            <i class="material-icons loader pull-left"
                                                                               v-show="loading_queue_call_screen">&#xE863;</i>
                                                                            <i class="material-icons pull-left"
                                                                               v-show="!loading_queue_call_screen">&#xE872;</i>
                                                                            <span>Remove file</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </el-form-item>
                                                </div>
                                                <div class="col-3 d-none d-md-block ml-auto">
                                                    <div
                                                        class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                                                        <h6 class="list-group-item">
                                                            On This Page
                                                        </h6>
                                                        <a href="#queue_should_ask_for_callback"
                                                           class="list-group-item list-group-item-action"
                                                           @click="shine('queue_should_ask_for_callback')">
                                                            Offer Callback Option
                                                        </a>
                                                        <a href="#queue_should_handle_by_text"
                                                           class="list-group-item list-group-item-action"
                                                           @click="shine('queue_should_handle_by_text')">
                                                            Offer Handle By Text Option
                                                        </a>
                                                        <a href="#queue_should_ask_for_voicemail"
                                                           class="list-group-item list-group-item-action"
                                                           @click="shine('queue_should_ask_for_voicemail')">
                                                            Offer Voicemail Option
                                                        </a>
                                                        <a href="#queue_should_call_screen"
                                                           class="list-group-item list-group-item-action"
                                                           @click="shine('queue_should_call_screen')">
                                                            Press One
                                                        </a>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane v-if="ring_group_id && ring_group.id"
                                                 name="routing-test"
                                                 label="Test Routing">
                                        <ring-group-routing-test ref="ringGroupRoutingTest"
                                                                 :ring_group="ring_group">
                                        </ring-group-routing-test>
                                    </el-tab-pane>
                                    <el-tab-pane name="connected-lines"
                                                 class="p-2"
                                                 label="Connected Lines"
                                                 v-if="ring_group_id && ring_group">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <el-form-item class="no-border">
                                                    <div class="form-label">
                                                        <h5 class="mb-0">Connected Lines</h5>
                                                        <small>
                                                            Here you can see the lines that are directly connected to this ring group. <br/>
                                                            Note: IVRs are not shown.
                                                        </small>
                                                    </div>

                                                    <div class="alert alert-danger alert-aloware d-inline-block"
                                                         v-if="ring_group.campaign_ids && ring_group.campaign_ids.length === 0">
                                                        This ring group is not directly connected to any lines.
                                                    </div>

                                                    <ul class="pl-0 ml-4"
                                                        v-if="ring_group.campaign_ids && ring_group.campaign_ids.length">
                                                        <li class="pl-0"
                                                            v-for="campaign_id in ring_group.campaign_ids"
                                                            :key="campaign_id">
                                                            <router-link
                                                                :to="{ name: 'Line Settings', params: {campaign_id: campaign_id }}">
                                                                <span class="text-md text-dark-greenish _600">{{ campaign_id | campaignName(campaigns) }}</span>
                                                            </router-link>
                                                        </li>
                                                    </ul>
                                                </el-form-item>
                                            </div>
                                            <div class="col-3 d-none d-md-block ml-auto">
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
            <form-height-indicator/>
        </div>

        <div class="fixed-footer padding pb-0">
            <div class="form-group row mb-0 mt-0">
                <div class="col-12 d-none d-md-block p-2 edit-operations">
                    <el-button class="pull-left"
                               v-if="canGoBack"
                               @click.prevent="goBack">
                        <i class="fa fa-arrow-left"></i>
                        Back
                    </el-button>
                    <el-button class="pull-right ml-0"
                               type="success"
                               :disabled="loading_btn || !validated"
                               @click.prevent="submitForm('ring_group_form')"
                               v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                        <i class="material-icons loader pull-left"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="fa fa-floppy-o"
                           v-show="!loading_btn"></i>
                        {{ ring_group_id ? 'Save' : 'Add' }}
                    </el-button>
                    <button class="btn el-btn pull-right danger mr-2"
                            v-if="hasPermissionTo('delete ring group') && ring_group_id"
                            :disabled="loading_delete"
                            @click="deleteRingGroupRemote()">
                        <i class="material-icons loader pull-left"
                           v-show="loading_delete">&#xE863;</i>
                        <i class="fa fa-trash"
                           v-show="!loading_delete"></i>
                        Delete
                    </button>
                </div>
                <div class="col-12 d-sm-block d-md-none p-2 edit-operations">
                    <el-button class="pull-left"
                               size="mini"
                               v-if="canGoBack"
                               @click.prevent="goBack">
                        <i class="fa fa-arrow-left"></i>
                        Back
                    </el-button>
                    <el-button class="pull-right"
                               size="mini"
                               type="success"
                               :disabled="loading_btn || !validated"
                               @click.prevent="submitForm('ring_group_form')"
                               v-if="hasPermissionTo(['update ring group', 'create ring group', 'view ring group'])">
                        <i class="material-icons loader pull-left"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="fa fa-floppy-o"
                           v-show="!loading_btn"></i>
                        {{ ring_group_id ? 'Save' : 'Add' }}
                    </el-button>

                    <el-popover placement="top"
                                width="100%"
                                trigger="click">
                        <el-button type="danger"
                                   size="mini"
                                   class="pull-right ml-0"
                                   v-if="hasPermissionTo('delete ring group') && ring_group_id"
                                   :disabled="loading_delete"
                                   @click="deleteRingGroupRemote()">
                            <i class="material-icons loader pull-left"
                               v-show="loading_delete">&#xE863;</i>
                            <i class="fa fa-trash"
                               v-show="!loading_delete"></i>
                            Delete
                        </el-button>
                        <el-button slot="reference"
                                   size="mini"
                                   type="primary"
                                   class="pull-right"
                                   icon="el-icon-more-outline"
                                   plain>
                        </el-button>
                    </el-popover>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.el-radio {
    white-space: normal;
}
</style>

<script>
import auth from '../../auth'
import {mapActions, mapState} from 'vuex'
import {
    acl_mixin,
    chargebee_mixin,
    form_handler,
    form_validation_mixin,
    scroll_mixin,
    tab_section_mixin,
    user_info_mixin,
    validator_mixin,
    kyc_mixin,
    teams_mixin
} from '../../mixins'
import * as AnswerTypes from '../../constants/answer-types'
import VariableDialog from '../../components/variable-dialog'
import TemplateListDialog from '../../components/template-list-dialog'
import RingGroupRoutingTest from "../../components/ring-groups/ring-group-routing-test"
import * as AgentStatusLabels from '../../constants/agent-status-labels'
import * as RingGroupTextAssignModes from '../../constants/ring-group-text-assign-mode'
import * as RingGroupRepeatContactTo from '../../constants/ring-group-repeat-calls'
import FormHeightIndicator from "../../components/form-height-indicator"
import * as MissedCallBehaviors from '../../constants/missed-call-behavior'
import UpgradeNow from "../../components/upgrade-now.vue"

export default {
    mixins: [
        acl_mixin,
        chargebee_mixin,
        scroll_mixin,
        form_handler,
        tab_section_mixin,
        user_info_mixin,
        validator_mixin,
        form_validation_mixin,
        kyc_mixin,
        teams_mixin
    ],

    components: {
        UpgradeNow,
        FormHeightIndicator,
        TemplateListDialog,
        VariableDialog,
        RingGroupRoutingTest
    },

    props: {
        ring_group_id: {
            required: false
        }
    },

    data() {
        return {
            auth: auth,
            env: null,
            loading: false,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: false,
            loading_delete: false,
            loading_voicemail: false,
            active_tab_name: 'general-settings',
            ring_group_name: null,
            ring_group: null,
            default_ring_group: {
                name: null,
                description: null,
                ordered_user_ids: {"1": []},
                ordered_team_ids: {"1": []},
                call_handling_options: {
                    timeout: 60
                },
                should_queue: false,
                fishing_mode: false,
                dial_mode: 0,
                text_assign_mode: RingGroupTextAssignModes.TEXT_ASSIGN_MODE_TO_CONTACT_OWNER,
                queue_should_say_caller_position: false,
                queue_should_ask_for_callback: false,
                queue_ask_for_callback_tts: null,
                queue_ask_for_callback_file: null,
                queue_should_handle_by_text: false,
                queue_handle_by_text_tts: null,
                queue_handle_by_text_file: null,
                queue_handle_by_text_response_tts: null,
                queue_should_ask_for_voicemail: false,
                queue_ask_for_voicemail_tts: null,
                queue_ask_for_voicemail_file: null,
                queue_should_call_screen: false,
                queue_call_screen_tts: null,
                queue_call_screen_file: null,
                queue_wait_music_file: null,
                queue_repeat_prompts_delay: 60,
                queue_send_voicemail_when_no_agents: true,
                missed_calls_settings: {
                    missed_call_handling_mode: MissedCallBehaviors.MISSED_CALL_BEHAVIOR_NOTHING,
                    voicemail_tts: "We are sorry, but no agents are available to take this call. Please leave your message after the beep.",
                    voicemail_file: null,
                },
                repeat_contact_route_to: RingGroupRepeatContactTo.REPEAT_CONTACT_ROUTE_TO_RING_GROUP,
                max_wait_time: 0,
                enable_sms_notifications: false,
                enable_voicemail_notifications: true,
            },
            call_timeout_options: [{
                value: 10,
                label: '10 seconds'
            }, {
                value: 15,
                label: '15 seconds'
            }, {
                value: 20,
                label: '20 seconds'
            }, {
                value: 30,
                label: '30 seconds'
            }, {
                value: 45,
                label: '45 seconds'
            }, {
                value: 60,
                label: '60 seconds'
            }, {
                value: 90,
                label: '90 seconds'
            }],
            loading_btn: false,
            DIAL_MODE_SIMUL: 0,
            DIAL_MODE_RAND: 1,
            DIAL_MODE_ROUND: 2,
            DIAL_MODE_LONGEST_AVAILABLE: 3,
            show_custom_wait_music: false,
            testRoutingDialog: false,
            loading_queue_ask_for_callback: false,
            loading_queue_handle_by_text: false,
            loading_queue_ask_for_voicemail: false,
            loading_queue_call_screen: false,
            loading_queue_wait_music: false,
            queue_repeat_prompts_delay_options: [
                {
                    value: 0,
                    label: 'Only Once'
                }, {
                    value: 10,
                    label: '10 seconds'
                }, {
                    value: 15,
                    label: '15 seconds'
                }, {
                    value: 20,
                    label: '20 seconds'
                }, {
                    value: 30,
                    label: '30 seconds'
                }, {
                    value: 45,
                    label: '45 seconds'
                }, {
                    value: 60,
                    label: '1 minute'
                }
            ],
            max_wait_time_options: [{
                value: 0,
                label: 'Indefinitely'
            }, {
                value: 30,
                label: '30 seconds'
            }, {
                value: 60,
                label: '1 minute'
            }, {
                value: 120,
                label: '2 minutes'
            }, {
                value: 180,
                label: '3 minutes'
            }, {
                value: 240,
                label: '4 minutes'
            }, {
                value: 300,
                label: '5 minutes'
            }, {
                value: 600,
                label: '10 minutes'
            }, {
                value: 900,
                label: '15 minutes'
            }],
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + auth.user.profile.api_token,
                'X-Socket-Id': window.Echo.socketId()
            },
            uploadPercentage: {
                queueAskForCallback: 0,
                queueHandleByText: 0,
                queueAskForVoicemail: 0,
                queueCallScreen: 0,
                queueWaitMusic: 0,
                voicemail: 0,
            },
            uploadStatus: {
                queueAskForCallback: 'success',
                queueHandleByText: 'success',
                queueAskForVoicemail: 'success',
                queueCallScreen: 'success',
                queueWaitMusic: 'success',
                voicemail: 'success',
            },
            uploadFileList: {
                queueAskForCallback: [],
                queueHandleByText: [],
                queueAskForVoicemail: [],
                queueCallScreen: [],
                queueWaitMusic: [],
                voicemail: [],
            },
            rules_ring_group: {
                name: [
                    {
                        required: true,
                        message: 'Please provide a ring group name',
                        trigger: 'blur'
                    }
                ],
                ordered_user_team_ids: [
                    {
                        validator: (rule, value, callback) => {
                            const list_layers = {
                                ordered_user_ids: this.ring_group.ordered_user_ids,
                                ordered_team_ids: this.ring_group.ordered_team_ids
                            }

                            if (list_layers.ordered_user_ids[1]?.length === 0 && list_layers.ordered_team_ids[1]?.length === 0) {
                                callback(new Error('Please select one or more users or teams'))
                            }

                            callback()
                        },
                        trigger: 'blur'
                    }
                ],
                queue_should_ask_for_callback: [
                    {
                        type: 'array',
                        validator: this.callbackValidator,
                        trigger: 'change'
                    },
                ],
                queue_should_handle_by_text: [
                    {
                        type: 'array',
                        validator: this.handleByTextValidator,
                        trigger: 'change'
                    },
                ],
                queue_handle_by_text_response_tts: [
                    {
                        validator: this.handleByTextMessageValidator,
                        trigger: 'change'
                    },
                ],
                queue_should_ask_for_voicemail: [
                    {
                        type: 'array',
                        validator: this.askForVoicemailValidator,
                        trigger: 'change'
                    },
                ],
                queue_call_screens: [
                    {
                        type: 'array',
                        validator: this.callScreenValidator,
                        trigger: 'change'
                    },
                ]
            },
            audio_file_messages: {
                queue_ask_for_callback_file: 'Callback recording uploaded successfully.',
                queue_handle_by_text_file: 'Handle by text recording uploaded successfully.',
                queue_ask_for_voicemail_file: 'Voicemail recording uploaded successfully.',
                queue_call_screen_file: 'Press one recording uploaded successfully.',
                queue_wait_music_file: 'Wait music uploaded successfully.',
                voicemail_file: 'Voicemail uploaded successfully.',
            },
            ringGroupToTeam: false,
            AnswerTypes,
            AgentStatusLabels,
            RingGroupTextAssignModes,
            RingGroupRepeatContactTo,
            MissedCallBehaviors
        }
    },

    computed: {
        ...mapState(['ring_groups', 'campaigns']),
        ...mapState('cache', ['current_company']),

        getTitle() {
            if (this.ring_group_id) {
                return 'Ring Group Settings'
            }

            return 'Add Ring Group'
        },

        queueCallbackNavClass() {
            return {
                read: {
                    'disabled': this.ring_group.queue_ask_for_callback_file !== null,
                    'nav-link': true,
                    'active': this.ring_group.queue_ask_for_callback_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.ring_group.queue_ask_for_callback_file !== null
                }
            }
        },

        queueHandleByTextNavClass() {
            return {
                read: {
                    'disabled': this.ring_group.queue_handle_by_text_file !== null,
                    'nav-link': true,
                    'active': this.ring_group.queue_handle_by_text_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.ring_group.queue_handle_by_text_file !== null
                }
            }
        },

        queueVoicemailNavClass() {
            return {
                read: {
                    'disabled': this.ring_group.queue_ask_for_voicemail_file !== null,
                    'nav-link': true,
                    'active': this.ring_group.queue_ask_for_voicemail_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.ring_group.queue_ask_for_voicemail_file !== null
                }
            }
        },

        queueCallScreenNavClass() {
            return {
                read: {
                    'disabled': this.ring_group.queue_call_screen_file !== null,
                    'nav-link': true,
                    'active': this.ring_group.queue_call_screen_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.ring_group.queue_call_screen_file !== null
                }
            }
        },

        queueCallbackContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.ring_group.queue_ask_for_callback_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.ring_group.queue_ask_for_callback_file !== null
                },
            }
        },

        queueHandleByTextContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.ring_group.queue_handle_by_text_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.ring_group.queue_handle_by_text_file !== null
                },
            }
        },

        queueVoicemailContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.ring_group.queue_ask_for_voicemail_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.ring_group.queue_ask_for_voicemail_file !== null
                },
            }
        },

        queueCallScreenContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.ring_group.queue_call_screen_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.ring_group.queue_call_screen_file !== null
                },
            }
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        voicemailNavClass() {
            return {
                read: {
                    'disabled': this.ring_group.missed_calls_settings.voicemail_file !== null,
                    'nav-link': true,
                    'active': this.ring_group.missed_calls_settings.voicemail_file === null
                },
                play: {
                    'nav-link': true,
                    'active': this.ring_group.missed_calls_settings.voicemail_file !== null
                }
            }
        },

        voicemailContainerClass() {
            return {
                read: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.ring_group.missed_calls_settings.voicemail_file === null
                },
                play: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': this.ring_group.missed_calls_settings.voicemail_file !== null
                },
            }
        },

        attributesRingGroup() {
            return {
                id: this.ring_group?.id,
                name: this.ring_group?.name
            }
        }
    },

    created() {
        VueEvent.listen('ring_group_updated', (ring_group) => {
            if (this.hasPermissionTo(['update ring group', 'view ring group', 'create ring group']) && ring_group.id == this.ring_group_id) {
                this.getRingGroup()
            }
        })
        this.getStatics()
    },

    mounted() {
        if (!this.hasPermissionTo(['update ring group', 'view ring group', 'create ring group'])) {
            this.goBack()
        }

        if (this.hasPermissionTo(['update ring group', 'view ring group', 'create ring group'])) {
            this.getRingGroup()
        }

        let tab = this.$route.query.tab
        if (tab) {
            this.active_tab_name = tab
        }
    },

    methods: {
        tabClicked(tab) {
            this.$router.replace({
                name: 'Ring Group Dialog',
                params: {
                    ring_group_id: this.ring_group.id
                },
                query: {
                    tab: tab.name
                }
            }).catch(err => {

            })
        },

        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.setPageTitle(this.getTitle + ' - ' + this.statics.name)
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    this.setPageTitle(this.getTitle + ' - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        },

        getRingGroup() {
            if (this.ring_group_id) {
                this.loading = true
                axios.get('/api/v1/ring-group/' + this.ring_group_id, {
                    mode: 'no-cors',
                }).then(res => {
                    this.setUpRingGroup(res.data)
                    this.loading = false
                }).catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading = false
                })
            } else {
                this.setUpRingGroup(this.default_ring_group)
            }
        },

        setUpRingGroup(data) {
            this.ring_group = data
            this.ring_group.dial_mode = parseInt(this.ring_group.dial_mode)
            this.ring_group.text_assign_mode = parseInt(this.ring_group.text_assign_mode)
            if (!this.ring_group.call_handling_options) {
                this.ring_group.call_handling_options = {
                    timeout: 60
                }
            }

            if (typeof this.ring_group.call_handling_options === 'string') {
                this.ring_group.call_handling_options = JSON.parse(this.ring_group.call_handling_options)
            }

            this.ring_group.call_handling_options.timeout = parseInt(this.ring_group.call_handling_options.timeout)

            if (!this.ring_group.queue_ask_for_callback_tts) {
                this.ring_group.queue_ask_for_callback_tts = 'Press 1 to get a callback from one of our agents.'
            }

            if (!this.ring_group.queue_handle_by_text_tts) {
                this.ring_group.queue_handle_by_text_tts = 'Press 2 to continue this call with text messages.'
                this.ring_group.queue_handle_by_text_response_tts = 'We will text you just about now! Talk soon!'
            }

            if (!this.ring_group.queue_ask_for_voicemail_tts) {
                this.ring_group.queue_ask_for_voicemail_tts = 'Press 3 for leaving a voicemail for us.'
            }

            if (!this.ring_group.queue_call_screen_tts) {
                this.ring_group.queue_call_screen_tts = 'Press 1 to take this call.'
            }

            this.show_custom_wait_music = this.ring_group.queue_wait_music_file != null

            this.ring_group_name = this.ring_group.name

            VueEvent.fire('page_title_updated', this.ring_group_name)

            Vue.nextTick(() => {
                this.preValidateForm('ring_group_form')
            })
        },

        submitForm: function (formName) {
            if (this.validateForm(formName) == true) {
                if (this.ring_group_id) {
                    this.saveRingGroup()
                }

                if (!this.ring_group_id) {
                    this.addRingGroup()
                }
            } else {
                this.$notify({
                    offset: 95,
                    title: 'Ring Group',
                    dangerouslyUseHTMLString: true,
                    message: 'Please resolve ring group configuration errors',
                    type: 'error',
                    showClose: true,
                })
                return false
            }
        },

        addRingGroup() {
            if (this.validateForm('ring_group_form')) {
                this.loading_btn = true
                axios.post('/api/v1/ring-group', this.ring_group)
                    .then(res => {
                        this.loading_btn = false
                        this.newRingGroup(res.data)
                        this.$notify({
                            offset: 95,
                            title: 'Ring Group',
                            message: 'Ring group created successfully.',
                            type: 'success'
                        })
                        this.$router.push({
                            name: 'Ring Group Dialog',
                            params: {
                                ring_group_id: res.data.id
                            }
                        })
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_btn = false
                    })
            }
        },

        saveRingGroup() {
            if (this.validateForm('ring_group_form')) {
                this.loading_btn = true

                // set music file to null when disabled
                if (!this.show_custom_wait_music) {
                    this.ring_group.queue_wait_music_file = null
                }

                axios.put('/api/v1/ring-group/' + this.ring_group.id, this.ring_group)
                    .then(res => {
                        this.loading_btn = false
                        if(this.ringGroupToTeam){
                            this.fetchTeams()
                        }
                        this.updateRingGroup(res.data)
                        this.setUpRingGroup(res.data)
                        this.$notify({
                            offset: 95,
                            title: 'Ring Group',
                            message: 'Ring group updated successfully.',
                            type: 'success'
                        })

                        // Refresh the test
                        this.$refs.ringGroupRoutingTest.testRingGroup()
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_btn = false
                    })
            }
        },

        deleteRingGroupRemote: function () {
            this.$confirm('Deleting a ring group will remove it from all lines and IVRs. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_delete = true
                axios.delete('/api/v1/ring-group/' + this.ring_group.id)
                    .then(res => {
                        this.deleteRingGroup(this.ring_group)
                        this.loading_delete = false
                        this.$notify({
                            offset: 95,
                            title: 'Ring Group',
                            message: 'Ring group has been deleted.',
                            type: 'success',
                            showClose: true
                        })
                        this.$router.push({
                            name: 'Ring Groups'
                        })
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_delete = false
                    })
            })
        },

        changeOrderedUsersAndTeams(val) {
            Object.keys(val).forEach(key => {
                this.$set(this.ring_group, key, val[key])
            })

            this.preValidateForm('ring_group_form')
        },

        changeCampaignIds(val) {
            this.ring_group.campaign_ids = val
            this.preValidateForm('ring_group_form')
        },

        onSuccessCallback(res) {
            this.$notify({
                offset: 95,
                title: 'Ring Group',
                message: this.audio_file_messages.queue_ask_for_callback_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.ring_group, 'queue_ask_for_callback_file', res.file_name)
            this.uploadStatus.queueAskForCallback = 'success'
            this.uploadPercentage.queueAskForCallback = 0
            this.preValidateForm('ring_group_form')
        },

        onFailedCallback(err) {
            this.$root.handleUploadErrors(err.message)
            this.uploadStatus.queueAskForCallback = 'exception'
            this.uploadPercentage.queueAskForCallback = 0
            this.preValidateForm('ring_group_form')
        },

        beforeUploadCallback() {
            this.uploadStatus.queueAskForCallback = 'success'
            this.uploadPercentage.queueAskForCallback = 0
        },

        progressUploadCallback(event) {
            this.uploadPercentage.queueAskForCallback = parseInt(event.percent)
        },

        onSuccessHandleByText(res) {
            this.$notify({
                offset: 95,
                title: 'Ring Group',
                message: this.audio_file_messages.queue_handle_by_text_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.ring_group, 'queue_handle_by_text_file', res.file_name)
            this.uploadStatus.queueHandleByText = 'success'
            this.uploadPercentage.queueHandleByText = 0
            this.preValidateForm('ring_group_form')
        },

        onFailedHandleByText(err) {
            this.$root.handleUploadErrors(err.message)
            this.uploadStatus.queueHandleByText = 'exception'
            this.uploadPercentage.queueHandleByText = 0
            this.preValidateForm('ring_group_form')
        },

        beforeUploadHandleByText() {
            this.uploadStatus.queueHandleByText = 'success'
            this.uploadPercentage.queueHandleByText = 0
        },

        progressUploadHandleByText(event) {
            this.uploadPercentage.queueHandleByText = parseInt(event.percent)
        },

        onSuccessQueueVoicemail(res) {
            this.$notify({
                offset: 95,
                title: 'Ring Group',
                message: this.audio_file_messages.queue_ask_for_voicemail_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.ring_group, 'queue_ask_for_voicemail_file', res.file_name)
            this.uploadStatus.queueVoicemail = 'success'
            this.uploadPercentage.queueAskForVoicemail = 0
            this.preValidateForm('ring_group_form')
        },

        onFailedQueueVoicemail(err) {
            this.$root.handleUploadErrors(err.message)
            this.uploadStatus.queueVoicemail = 'exception'
            this.uploadPercentage.queueAskForVoicemail = 0
            this.preValidateForm('ring_group_form')
        },

        beforeUploadQueueVoicemail() {
            this.uploadStatus.queueVoicemail = 'success'
            this.uploadPercentage.queueAskForVoicemail = 0
        },

        progressUploadQueueVoicemail(event) {
            this.uploadPercentage.queueAskForVoicemail = parseInt(event.percent)
        },

        onSuccessCallScreen(res) {
            this.$notify({
                offset: 95,
                title: 'Ring Group',
                message: this.audio_file_messages.queue_call_screen_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.ring_group, 'queue_call_screen_file', res.file_name)
            this.uploadStatus.queueCallScreen = 'success'
            this.uploadPercentage.queueCallScreen = 0
            this.preValidateForm('ring_group_form')
        },

        onFailedCallScreen(err) {
            this.$root.handleUploadErrors(err.message)
            this.uploadStatus.queueCallScreen = 'exception'
            this.uploadPercentage.queueCallScreen = 0
            this.preValidateForm('ring_group_form')
        },

        beforeUploadCallScreen() {
            this.uploadStatus.queueCallScreen = 'success'
            this.uploadPercentage.queueCallScreen = 0
        },

        progressUploadCallScreen(event) {
            this.uploadPercentage.queueCallScreen = parseInt(event.percent)
        },

        onSuccessWaitMusic(res) {
            this.$notify({
                offset: 95,
                title: 'Ring Group',
                message: this.audio_file_messages.queue_wait_music_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.ring_group, 'queue_wait_music_file', res.file_name)
            this.uploadStatus.queueWaitMusic = 'success'
            this.uploadPercentage.queueWaitMusic = 0
            this.preValidateForm('ring_group_form')
        },

        onFailedWaitMusic(err) {
            this.$root.handleUploadErrors(err.message)
            this.uploadStatus.queueWaitMusic = 'exception'
            this.uploadPercentage.queueWaitMusic = 0
            this.preValidateForm('ring_group_form')
        },

        beforeUploadWaitMusic() {
            this.uploadStatus.queueWaitMusic = 'success'
            this.uploadPercentage.queueWaitMusic = 0
        },

        progressUploadWaitMusic(event) {
            this.uploadPercentage.queueWaitMusic = parseInt(event.percent)
        },

        deleteFile(type) {
            switch (type) {
                case 'queue_callbacks':
                    this.loading_queue_ask_for_callback = true
                    break
                case 'queue_handle_by_texts':
                    this.loading_queue_handle_by_text = true
                    break
                case 'queue_voicemails':
                    this.loading_queue_ask_for_voicemail = true
                    break
                case 'queue_call_screens':
                    this.loading_queue_call_screen = true
                    break
                case 'queue_wait_musics':
                    this.loading_queue_wait_music = true
                    break
                case 'voicemails':
                    this.loading_voicemail = true
                    break
            }

            axios.delete('/api/v1/ring-group/' + this.ring_group.id + '/delete/' + type)
                .then(res => {
                    let message = ''
                    switch (type) {
                        case 'queue_callbacks':
                            this.loading_queue_ask_for_callback = false
                            this.ring_group.queue_ask_for_callback_file = null
                            message = 'Callback file deleted successfully'
                            break
                        case 'queue_handle_by_texts':
                            this.loading_queue_handle_by_text = false
                            this.ring_group.queue_handle_by_text_file = null
                            message = 'Handle by text file deleted successfully'
                            break
                        case 'queue_voicemails':
                            this.loading_queue_ask_for_voicemail = false
                            this.ring_group.queue_ask_for_voicemail_file = null
                            message = 'Voicemail file deleted successfully'
                            break
                        case 'queue_call_screens':
                            this.loading_queue_call_screen = false
                            this.ring_group.queue_call_screen_file = null
                            message = 'Press One file deleted successfully'
                            break
                        case 'queue_wait_musics':
                            this.loading_queue_wait_music = false
                            this.ring_group.queue_wait_music_file = null
                            message = 'Wait music file deleted successfully'
                            break
                        case 'voicemails':
                            this.uploadPercentage.voicemail = 0
                            this.uploadStatus.voicemail = 'success'
                            this.uploadFileList.voicemail = []
                            this.loading_voicemail = false
                            this.ring_group.missed_calls_settings.voicemail_file = null
                            message = 'Voicemail file deleted successfully'
                            break
                    }
                    this.$notify({
                        offset: 95,
                        title: 'Ring Group',
                        message: message,
                        type: 'success',
                        showClose: true,
                    })
                }).catch(err => {
                switch (type) {
                    case 'queue_callbacks':
                        this.loading_queue_ask_for_callback = false
                        this.uploadPercentage.queueAskForCallback = 0
                        break
                    case 'queue_handle_by_texts':
                        this.loading_queue_handle_by_text = false
                        this.uploadPercentage.queueHandleByText = 0
                        break
                    case 'queue_voicemails':
                        this.loading_queue_ask_for_voicemail = false
                        this.uploadPercentage.queueAskForVoicemail = 0
                        break
                    case 'queue_call_screens':
                        this.loading_queue_call_screen = false
                        this.uploadPercentage.queueCallScreen = 0
                        break
                    case 'queue_wait_musics':
                        this.loading_queue_wait_music = false
                        this.uploadPercentage.queueWaitMusic = 0
                        break
                    case 'voicemails':
                        this.loading_voicemail = false
                        this.uploadPercentage.voicemail = 0
                        break
                }
                this.$root.handleErrors(err.response)
            })
        },

        onChangeShouldQueue() {
            this.preValidateForm('ring_group_form')
            this.ring_group.max_wait_time = 120
        },

        applyUploadedAudio(data) {
            if (data.upload_type === 'voicemail_file') {
                this.$set(this.ring_group.missed_calls_settings, data.upload_type, null)
            } else if (data.upload_type === 'queue_wait_music_file') {
                this.$set(this.ring_group, 'queue_wait_music_file', data.uid)
                this.uploadStatus.queueWaitMusic = 'success'
                this.uploadPercentage.queueWaitMusic = 0
                this.preValidateForm('ring_group_form')
            } else {
                this.$set(this.ring_group, data.upload_type, null)
            }
            setTimeout(() => {
                if (data.upload_type === 'voicemail_file') {
                    this.$set(this.ring_group.missed_calls_settings, data.upload_type, data.uid)
                } else if (data.upload_type === 'queue_wait_music_file') {
                    this.$set(this.ring_group, 'queue_wait_music_file', data.uid)
                    this.uploadStatus.queueWaitMusic = 'success'
                    this.uploadPercentage.queueWaitMusic = 0
                    this.preValidateForm('ring_group_form')
                } else {
                    this.$set(this.ring_group, data.upload_type, data.uid)
                }
                this.$notify({
                    offset: 95,
                    title: 'Ring Group',
                    message: this.audio_file_messages[data.upload_type],
                    type: 'success',
                    showClose: true,
                })
                this.preValidateForm('ring_group_form')
            }, 100)
        },

        onSuccessVoicemail(res) {
            this.$notify({
                offset: 95,
                title: 'Ring Group',
                message: this.audio_file_messages.voicemail_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.ring_group.missed_calls_settings, 'voicemail_file', res.file_name)
            this.uploadPercentage.voicemail = 0
            this.preValidateForm('ring_group_form')
        },
        onFailedVoicemail(err) {
            this.$root.handleUploadErrors(err.message)
            this.uploadStatus.voicemail = 'exception'
            this.uploadPercentage.voicemail = 0
            this.preValidateForm('ring_group_form')
        },
        beforeUploadVoicemail() {
            this.uploadStatus.voicemail = 'success'
            this.uploadPercentage.voicemail = 0
        },
        progressUploadVoicemail(event) {
            this.uploadPercentage.voicemail = parseInt(event.percent)
        },

        addRingGroupToTeam (val, teamName) {
            this.ringGroupToTeam = true
            this.ring_group.ordered_team_user_ids = val
            this.ring_group.converted_team_name = teamName
            this.submitForm('ring_group_form')
        },

        ...mapActions(['newRingGroup', 'updateRingGroup', 'deleteRingGroup'])
    },

    watch: {
        ring_group_id: function (value) {
            this.getRingGroup(value)
        },

        'ring_group.queue_should_say_caller_position': function () {
            this.preValidateForm('ring_group_form')
        },

        'ring_group.queue_should_ask_for_callback': function () {
            this.preValidateForm('ring_group_form')
        },

        'ring_group.queue_should_handle_by_text': function () {
            this.preValidateForm('ring_group_form')
        },

        'ring_group.queue_should_ask_for_voicemail': function () {
            this.preValidateForm('ring_group_form')
        },

        'ring_group.queue_should_call_screen': function () {
            this.preValidateForm('ring_group_form')
        },

        all_teams: function () {
            if(this.ringGroupToTeam){
                this.$refs.ordered_user_team_selector.$emit('fixList')
                this.ringGroupToTeam = false
            }
        },
    },

    beforeCreate() {
        axios.get('/build-info.json')
            .then(res => {
                this.env = res.data.env
            })
            .catch(err => {
                console.log(err)
            })
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
