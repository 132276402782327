var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "card pb-3 h-100",
    },
    [
      _c("div", { staticClass: "px-4" }, [
        _c(
          "div",
          { staticClass: "container-outbound-connection-rate-widget" },
          [
            _c("div", { staticClass: "label-connection-rate" }, [
              _c("h1", [
                _vm._v(_vm._s(_vm.metrics.inbound_calls_connection_rate) + "%"),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Connection Rate")]),
            ]),
            _vm._v(" "),
            _c("highcharts", { ref: "chart", attrs: { options: _vm.options } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("metric-widget", {
              attrs: {
                label: "Total Inbound Calls",
                metric: _vm.metrics.inbound_calls,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("metric-widget", {
              attrs: {
                label: "Connected Calls",
                metric: _vm.metrics.inbound_connected_calls,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }