var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      staticClass: "filter-select",
      attrs: {
        multiple: "",
        placeholder: _vm.placeholder,
        disabled: !_vm.sources || _vm.sources.length === 0,
        "collapse-tags": "",
        filterable: "",
        clearable: "",
      },
      model: {
        value: _vm.source,
        callback: function ($$v) {
          _vm.source = $$v
        },
        expression: "source",
      },
    },
    _vm._l(_vm.sources, function (source) {
      return _c(
        "el-option",
        { key: source.code, attrs: { label: source.name, value: source.code } },
        [_c("span", [_vm._v(_vm._s(source.name))])]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }