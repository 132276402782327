var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show_help,
            title: "What are users?",
            top: "10vh",
            "data-testid": "what-are-users-dialog",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_help = $event
            },
          },
        },
        [
          _c("p", { staticClass: "text-md _600 break-word" }, [
            _vm._v(
              "Users can be your agents or external phone numbers that handle your\n            calls. You can view their activity, or temporarily pause them, on this page."
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-md _600 break-word" }, [
            _vm._v(
              "User settings can be edited to configure working hours, visibility,\n            notifications, and much more. "
            ),
            !_vm.isWhitelabelAccount
              ? _c("span", [
                  _vm._v("Learn more about users "),
                  _c(
                    "a",
                    {
                      staticClass: "text-decoration-underline el-link--primary",
                      attrs: {
                        "data-testid": "learn-more-about-users-link",
                        href: "https://support.aloware.com/en/articles/9034191-introducing-aloware-wallboard-your-real-time-communication-metrics-dashboard",
                        target: "_blank",
                      },
                    },
                    [_vm._v("here")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("add-user-to-team-modal", {
        attrs: {
          "is-visible": _vm.isAddUserToTeamModalVisible,
          user: _vm.selected_user,
          teams: _vm.teams,
          "is-loading-user-teams": _vm.isLoadingUserTeams,
        },
        on: {
          "update:isVisible": function ($event) {
            _vm.isAddUserToTeamModalVisible = $event
          },
          "update:is-visible": function ($event) {
            _vm.isAddUserToTeamModalVisible = $event
          },
          "fetch-user-teams": function ($event) {
            return _vm.fetchUserTeams(_vm.selected_user?.id)
          },
          close: _vm.closeAddUserToTeamModal,
        },
      }),
      _vm._v(" "),
      _c("user-extension-wizard", {
        ref: "newExtension",
        attrs: { "data-testid": "user-extension-wizard" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fixed-header padding pt-0 pb-0",
          style: { top: _vm.top_offset + 50 + "px" },
        },
        [
          _c("div", { staticClass: "row mb-0 mt-0" }, [
            _c("div", { staticClass: "d-flex flex-fill flex-wrap p-2" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-wrap flex-xl-nowrap flex-fill mr-auto",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mr-sm-2 flex-fill flex-xl-grow-0 search-toolbar",
                    },
                    [
                      _c("el-input", {
                        ref: "userSearch",
                        attrs: {
                          placeholder: "Search",
                          "prefix-icon": "el-icon-search",
                          clearable: "",
                          autofocus: "",
                          "data-testid": "user-search-input",
                        },
                        model: {
                          value: _vm.search_text,
                          callback: function ($$v) {
                            _vm.search_text = $$v
                          },
                          expression: "search_text",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "no-select mt-2 mt-sm-0 mr-md-2 flex-fill flex-xl-grow-0 w-m",
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "no-select",
                          attrs: {
                            placeholder: "Select",
                            "default-first-option": "",
                            filterable: "",
                            "data-testid": "role-type-dropdown",
                          },
                          on: { change: _vm.changedFilter },
                          model: {
                            value: _vm.type_filter,
                            callback: function ($$v) {
                              _vm.type_filter = $$v
                            },
                            expression: "type_filter",
                          },
                        },
                        [
                          _c("el-option", {
                            key: 1,
                            attrs: { label: "Users", value: "Users" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: 2,
                            attrs: { label: "Admins", value: "Company Admin" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: 3,
                            attrs: { label: "Agents", value: "Company Agent" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: 4,
                            attrs: {
                              label: "Reporters",
                              value: "Company Reporter Access",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: 5,
                            attrs: { label: "Extensions", value: "Extensions" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: 6,
                            attrs: {
                              label: "Supervisors",
                              value: "Company Supervisor",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "no-select mt-2 mt-xl-0 mr-2 flex-sm-shrink-0",
                    },
                    [
                      !_vm.isMobileSize
                        ? _c(
                            "el-radio-group",
                            {
                              staticClass: "no-select",
                              attrs: { "data-testid": "filter-radio-group" },
                              on: { change: _vm.changedFilter },
                              model: {
                                value: _vm.user_filter,
                                callback: function ($$v) {
                                  _vm.user_filter = $$v
                                },
                                expression: "user_filter",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                {
                                  attrs: {
                                    label: "all",
                                    "data-testid": "filter-all",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                All "
                                  ),
                                  _vm.current_company
                                    ? _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.current_company
                                                .purchased_licenses
                                            ) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                {
                                  attrs: {
                                    label: "active",
                                    "data-testid": "filter-active",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Active "
                                  ),
                                  _vm.activeCount
                                    ? _c("span", [
                                        _vm._v(
                                          "(" + _vm._s(_vm.activeCount) + ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                {
                                  class: [
                                    _vm.user_filter == "paused"
                                      ? "pause-deep-orange"
                                      : "",
                                  ],
                                  attrs: {
                                    label: "paused",
                                    "data-testid": "filter-paused",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Paused "
                                  ),
                                  _vm.pausedCount
                                    ? _c("span", [
                                        _vm._v(
                                          "(" + _vm._s(_vm.pausedCount) + ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                {
                                  attrs: {
                                    label: "suspended",
                                    "data-testid": "filter-suspended",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Suspended "
                                  ),
                                  _vm.suspendedCount
                                    ? _c("span", [
                                        _vm._v(
                                          "(" + _vm._s(_vm.suspendedCount) + ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "el-select",
                            {
                              staticClass: "no-select",
                              attrs: {
                                placeholder: "Select",
                                "default-first-option": "",
                                filterable: "",
                                "data-testid": "filter-dropdown-selector",
                              },
                              on: { change: _vm.changedFilter },
                              model: {
                                value: _vm.user_filter,
                                callback: function ($$v) {
                                  _vm.user_filter = $$v
                                },
                                expression: "user_filter",
                              },
                            },
                            [
                              _c("el-option", {
                                key: 1,
                                attrs: {
                                  label:
                                    "All " +
                                    (_vm.current_company
                                      ? "(" +
                                        _vm.current_company.purchased_licenses +
                                        ")"
                                      : ""),
                                  value: "all",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                key: 2,
                                attrs: {
                                  label:
                                    "Active " +
                                    (_vm.activeCount
                                      ? "(" + _vm.activeCount + ")"
                                      : ""),
                                  value: "active",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                key: 3,
                                attrs: {
                                  label:
                                    "Paused " +
                                    (_vm.pausedCount
                                      ? "(" + _vm.pausedCount + ")"
                                      : ""),
                                  value: "paused",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                key: 4,
                                attrs: {
                                  label:
                                    "Suspended " +
                                    (_vm.suspendedCount
                                      ? "(" + _vm.suspendedCount + ")"
                                      : ""),
                                  value: "suspended",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-wrap flex-wrap flex-xl-nowrap flex-shrink-0",
                },
                [
                  _c(
                    "div",
                    { staticClass: "mt-2 mt-xl-0 mr-2" },
                    [
                      _vm.canCreateUser
                        ? [
                            _c(
                              "button",
                              {
                                staticClass: "btn rounded greenish h-100",
                                attrs: { "data-testid": "add-user-button" },
                                on: { click: _vm.addUser },
                              },
                              [
                                _c("i", { staticClass: "fa fa-plus" }),
                                _vm._v(
                                  "\n                                Add User\n                            "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.shouldContactSupport
                    ? _c(
                        "div",
                        { staticClass: "mt-2 mt-xl-0 mr-2" },
                        [
                          _vm.shouldContactSupport
                            ? [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn rounded greenish h-100",
                                    attrs: {
                                      "data-testid": "contact-support-button",
                                    },
                                    on: { click: _vm.contactSupport },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-plus" }),
                                    _vm._v(
                                      "\n                                Add User\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-2 mt-xl-0 mr-1" },
                    [
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn rounded orangish h-100",
                            attrs: { "data-testid": "add-extension-button" },
                            on: { click: _vm.openExtensionForm },
                          },
                          [
                            _c("i", { staticClass: "fa fa-plus" }),
                            _vm._v(
                              "\n                                Add Extension\n                            "
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-3 mt-xl-0 mr-2" },
                    [
                      _vm.isTrial && !_vm.isSimpSocial
                        ? _c("video-modal-activator", {
                            staticClass: "pl-1",
                            attrs: { "data-testid": "video-activator-button" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isCompanyKYC
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 mt-lg-0 mr-2 mr-lg-0 d-flex flex-column justify-content-center text-blue",
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons text-lg cursor-pointer",
                              attrs: { "data-testid": "info-outline-i" },
                              on: { click: _vm.toggleHelp },
                            },
                            [
                              _vm._v(
                                "\n                            info_outline\n                        "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.hasPermissionTo("list report") &&
      !_vm.search_text &&
      !_vm.$route.query.hasOwnProperty("uid")
        ? _c("div", { staticClass: "padding mt-5 pt-3" }, [
            _c("div", { staticClass: "row box" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "no-effect" }, [
                  _c(
                    "div",
                    { staticClass: "box-header pb-0" },
                    [
                      _c("activity-graph", {
                        attrs: {
                          base: "user",
                          default_date_range: 7,
                          is_first_load: _vm.reports_first_load,
                          "data-testid": "activity-graph",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermissionTo("list user")
        ? _c("div", { staticClass: "padding" }, [
            _c("div", { staticClass: "row box" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "no-effect" }, [
                  _c("div", { staticClass: "box-header pb-0" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _vm.filteredUsers
                          ? _c("span", { staticClass: "pull-left font-120" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.filteredUsers.length || 0)),
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.filteredUsers.length > 1
                                      ? "users"
                                      : "user"
                                  ) +
                                  "\n                                    "
                              ),
                              _vm.user_filter == "all"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                        / "
                                    ),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.current_company.purchased_licenses
                                        )
                                      ),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.current_company
                                            .purchased_licenses > 1
                                            ? "licenses"
                                            : "license"
                                        ) +
                                        "\n                                    "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-9" },
                        [
                          _c("p"),
                          _vm.users &&
                          _vm.users.filter((user) => user.enabled == false)
                            .length > 0
                            ? _c("div", { staticClass: "font-120" }, [
                                _vm._v(
                                  "\n                                        Note: Suspended users are billable. "
                                ),
                                !_vm.isWhitelabelAccount
                                  ? _c("span", [
                                      _vm._v("Learn more "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "text-decoration-underline el-link--primary",
                                          attrs: {
                                            href: "https://support.aloware.com/en/articles/9033784-billing-implication-of-paused-suspended-users",
                                            target: "_blank",
                                            "data-testid":
                                              "billing-implication-link",
                                          },
                                        },
                                        [_vm._v("here")]
                                      ),
                                      _vm._v("."),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.users &&
                          _vm.current_company.min_user_seat > 1 &&
                          _vm.hasPerSeatPlanUseCase()
                            ? [
                                _vm.users.length <
                                _vm.current_company.min_user_seat
                                  ? _c("span", { staticClass: "font-120" }, [
                                      _vm._v(
                                        "\n                                            You still have "
                                      ),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.current_company.min_user_seat -
                                              _vm.users.length
                                          )
                                        ),
                                      ]),
                                      _vm._v(
                                        " more seats to assign. Invite more users today! (Minimum seat count = "
                                      ),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.current_company.min_user_seat
                                          )
                                        ),
                                      ]),
                                      _vm._v(
                                        ")\n                                        "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.users.length >=
                                  _vm.current_company.min_user_seat &&
                                _vm.getAvailableLicenses() > 0
                                  ? _c("span", { staticClass: "font-120" }, [
                                      _vm._v(
                                        "\n                                            You still have "
                                      ),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(this.getAvailableLicenses())
                                        ),
                                      ]),
                                      _vm._v(
                                        " more licenses to assign. Invite more users today! (Purchased licenses = "
                                      ),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.current_company
                                              .purchased_licenses
                                          )
                                        ),
                                      ]),
                                      _vm._v(
                                        ")\n                                        "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p"),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "box-body pt-0" }, [
                    _c(
                      "div",
                      { on: { mouseleave: _vm.tableLeave } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              data: _vm.paginatedData,
                              "default-sort": {
                                prop: "id",
                                order: "ascending",
                              },
                              fit: "",
                              stripe: "",
                              "data-testid": "users-table",
                            },
                            on: { "cell-mouse-enter": _vm.cellEnter },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                "data-testid": "users-status-column",
                                prop: "status",
                                align: "left",
                                width: "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "status d-flex align-items-center justify-content-left",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "b-white mr-2",
                                              class: [
                                                _vm.$options.filters.agentStatusClass(
                                                  scope.row.agent_status
                                                ),
                                              ],
                                            }),
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm._f("fixAgentStatus")(
                                                    scope.row.agent_status
                                                  )
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3262268568
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "data-testid": "users-id-column",
                                prop: "id",
                                label: "ID",
                                width: "100",
                                sortable: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-testid": "id-column-table",
                                            },
                                          },
                                          [_vm._v("ID")]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "text-greyish" },
                                          [_vm._v(_vm._s(scope.row.id))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2372952722
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "data-testid": "users-name-column",
                                prop: "name",
                                label: "Name",
                                "min-width": 200,
                                sortable: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-testid":
                                                "name-column-table",
                                            },
                                          },
                                          [_vm._v("Name")]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              "data-testid": "user-name-link",
                                              to: {
                                                name: "User Dialog",
                                                params: {
                                                  user_id: scope.row.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-dark-greenish text-ellipsis",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(scope.row.name || "-")
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            !scope.row.is_destination
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-greyish text-ellipsis",
                                                    attrs: {
                                                      title: scope.row.email,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          scope.row.email
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        scope.row.read_only_access
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block label blue",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Reporters are not billed\n                                        "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !scope.row.is_destination &&
                                        !scope.row.email_verified_at
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-info btn-sm mt-2",
                                                attrs: {
                                                  "data-testid":
                                                    "resend-welcome-email-button",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.resendWelcomeEmail(
                                                      scope.row.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Resend welcome email\n                                        "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4283919016
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "data-testid": "users-description-column",
                                prop: "description",
                                label: "Description",
                                "min-width": 200,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.description
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.description)
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                728432924
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "data-testid": "users-last-login-column",
                                prop: "last_login",
                                label: "Last login",
                                "min-width": 200,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.last_login &&
                                        !scope.row.is_destination
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f(
                                                    "fixFullDateUTCRelative"
                                                  )(scope.row.last_login)
                                                )
                                              ),
                                            ])
                                          : _c("span", [_vm._v("-")]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2349279931
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "data-testid": "users-last-role-names-column",
                                prop: "role_names",
                                label: "Role",
                                "min-width": 200,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        !scope.row.is_destination
                                          ? _vm._l(
                                              scope.row.role_names,
                                              function (role_name) {
                                                return _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-ellipsis",
                                                            attrs: {
                                                              title: role_name,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                        " +
                                                                _vm._s(
                                                                  role_name
                                                                ) +
                                                                "\n                                                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "text-ellipsis" },
                                              [
                                                _vm._v(
                                                  "\n                                            Extension\n                                        "
                                                ),
                                              ]
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2108571549
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "data-testid": "users-last-extension-column",
                                prop: "extension",
                                label: "Extension",
                                "min-width": 120,
                                sortable: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-testid":
                                                "extension-column-table",
                                            },
                                          },
                                          [_vm._v("Extension")]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-greyish text-ellipsis",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.extension || "-")
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3848422254
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "data-testid": "users-last-status-column",
                                label: "Status",
                                prop: "active",
                                align: "left",
                                width: "100",
                                sortable: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-testid":
                                                "status-column-table",
                                            },
                                          },
                                          [_vm._v("Status")]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "status d-flex align-items-center justify-content-left",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  scope.row.active
                                                    ? "Active"
                                                    : "Paused"
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                615958417
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "data-testid": "users-last-answer-by-column",
                                prop: "answer_by",
                                label: "Answer By",
                                align: "left",
                                "min-width": 150,
                                sortable: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-testid":
                                                "answer-by-column-table",
                                            },
                                          },
                                          [_vm._v("Answer by")]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.answer_by ===
                                        _vm.AnswerTypes.BY_BROWSER
                                          ? _c(
                                              "span",
                                              { staticClass: "text-greyish" },
                                              [
                                                _vm._v(
                                                  "\n                                            Browser / Apps\n                                        "
                                                ),
                                              ]
                                            )
                                          : scope.row.answer_by ===
                                            _vm.AnswerTypes.BY_PHONE_NUMBER
                                          ? _c(
                                              "span",
                                              { staticClass: "text-greyish" },
                                              [
                                                scope.row.phone_number
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("fixPhone")(
                                                            scope.row
                                                              .phone_number
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : _c("span", [_vm._v("-")]),
                                              ]
                                            )
                                          : scope.row.answer_by ===
                                            _vm.AnswerTypes.BY_IP_PHONE
                                          ? _c(
                                              "span",
                                              { staticClass: "text-greyish" },
                                              [
                                                _vm._v(
                                                  "\n                                            SIP\n                                        "
                                                ),
                                              ]
                                            )
                                          : scope.row.answer_by ===
                                            _vm.AnswerTypes.BY_NONE
                                          ? _c(
                                              "span",
                                              { staticClass: "text-greyish" },
                                              [
                                                _vm._v(
                                                  "\n                                            Will not answer\n                                        "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.answer_by ===
                                          _vm.AnswerTypes.BY_IP_PHONE &&
                                        scope.row.sip_uri &&
                                        _vm.current_company &&
                                        !_vm.current_company
                                          .default_outbound_campaign_id
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  "popper-class":
                                                    "rejection-tooltip",
                                                  content:
                                                    "This user will can not make outbound calls unless you select a default outbound line for this user or for your company.",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "status-icon d-inline-block",
                                                  },
                                                  [
                                                    _c(
                                                      "i",
                                                      {
                                                        staticClass:
                                                          "material-icons text-danger",
                                                      },
                                                      [_vm._v("error")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3713977845
                              ),
                            }),
                            _vm._v(" "),
                            _vm.auth.user.profile.lead_gen_tools_enabled
                              ? _c("el-table-column", {
                                  attrs: {
                                    "data-testid":
                                      "users-last-daily-call-limit-column",
                                    prop: "daily_call_limit",
                                    label: "Daily Call Limit",
                                    align: "left",
                                    "min-width": 150,
                                    sortable: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "text-greyish" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row
                                                      .daily_call_limit || "0"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1655744053
                                  ),
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "data-testid": "users-last-operations-column",
                                label: "Operations",
                                align: "center",
                                width: "150",
                                fixed: "right",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          { style: _vm.getStyle(scope.row.id) },
                                          [
                                            _vm.hasPermissionTo(
                                              "impersonate user"
                                            ) &&
                                            !_vm.isUserSuspended(scope.row) &&
                                            _vm.allowUserUpdate(scope.row)
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _vm.auth.user.profile
                                                          .id !==
                                                          scope.row.id &&
                                                        !scope.row
                                                          .is_destination &&
                                                        !_vm.inArray(
                                                          "Billing Admin",
                                                          scope.row.role_names
                                                        )
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm btn-block blackish",
                                                                attrs: {
                                                                  "data-testid":
                                                                    "login-as-button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.impersonate(
                                                                        scope
                                                                          .row
                                                                          .id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-user-secret pull-left",
                                                                }),
                                                                _vm._v(
                                                                  "\n                                                        Login As\n                                                    "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            (_vm.hasPermissionTo(
                                              "update user"
                                            ) ||
                                              (_vm.hasPermissionTo(
                                                "update profile"
                                              ) &&
                                                _vm.auth.user.profile.id ==
                                                  scope.row.id)) &&
                                            !_vm.isUserSuspended(scope.row)
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row mt-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: {
                                                                name: "User Timeline",
                                                                params: {
                                                                  user_id:
                                                                    scope.row
                                                                      .id,
                                                                },
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm btn-block purple-800",
                                                                attrs: {
                                                                  "data-testid":
                                                                    "timeline-button",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-history pull-left",
                                                                }),
                                                                _vm._v(
                                                                  "\n                                                            Timeline\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.hasPermissionTo([
                                              "view user",
                                              "list communication",
                                              "view communication",
                                            ])
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row mt-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: {
                                                                name: "User Activity",
                                                                params: {
                                                                  user_id:
                                                                    scope.row
                                                                      .id,
                                                                },
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm btn-block purplish",
                                                                attrs: {
                                                                  "data-testid":
                                                                    "activity-button",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-signal pull-left",
                                                                }),
                                                                _vm._v(
                                                                  "\n                                                            Activity\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            (_vm.hasPermissionTo(
                                              "update user"
                                            ) ||
                                              (_vm.hasPermissionTo(
                                                "update profile"
                                              ) &&
                                                _vm.auth.user.profile.id ==
                                                  scope.row.id)) &&
                                            !_vm.isUserSuspended(scope.row) &&
                                            _vm.allowUserUpdate(scope.row)
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row mt-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: {
                                                                name: "User Dialog",
                                                                params: {
                                                                  user_id:
                                                                    scope.row
                                                                      .id,
                                                                },
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm btn-block greenish",
                                                                attrs: {
                                                                  "data-testid":
                                                                    "settings-button",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-cogs pull-left",
                                                                }),
                                                                _vm._v(
                                                                  "\n                                                            Settings\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            (_vm.hasPermissionTo(
                                              "update user"
                                            ) ||
                                              (_vm.hasPermissionTo(
                                                "update profile"
                                              ) &&
                                                _vm.auth.user.profile.id ==
                                                  scope.row.id)) &&
                                            !_vm.isUserSuspended(scope.row) &&
                                            _vm.allowUserUpdate(scope.row)
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row mt-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-sm btn-block bluish d-flex align-items-center",
                                                            attrs: {
                                                              "data-testid":
                                                                "add-to-team-button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openAddUserToTeamModal(
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-plus mr-3",
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Add to team"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            (_vm.hasPermissionTo(
                                              "update user"
                                            ) ||
                                              (_vm.hasPermissionTo(
                                                "toggle active user"
                                              ) &&
                                                _vm.auth.user.profile.id ==
                                                  scope.row.id)) &&
                                            !_vm.isUserSuspended(scope.row) &&
                                            _vm.auth.user.profile.id !=
                                              scope.row.id &&
                                            _vm.allowUserUpdate(scope.row)
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row mt-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-sm btn-block",
                                                            class:
                                                              scope.row
                                                                .active == true
                                                                ? "deep-orange-500"
                                                                : "light-blue-500",
                                                            attrs: {
                                                              "data-testid":
                                                                "pause-active-button",
                                                              disabled:
                                                                _vm.loading_btn_id ==
                                                                scope.row.id,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.toggleActive(
                                                                  scope.row.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm.loading_btn_id ==
                                                            scope.row.id
                                                              ? _c("i", {
                                                                  staticClass:
                                                                    "fa fa-spin fa-spinner pull-left",
                                                                })
                                                              : [
                                                                  scope.row
                                                                    .active ==
                                                                  true
                                                                    ? _c("i", {
                                                                        staticClass:
                                                                          "fa fa-pause pull-left",
                                                                      })
                                                                    : _c("i", {
                                                                        staticClass:
                                                                          "fa fa-play pull-left",
                                                                      }),
                                                                ],
                                                            _vm._v(
                                                              "\n                                                        " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .active ==
                                                                    true
                                                                    ? "Pause"
                                                                    : "Activate"
                                                                ) +
                                                                "\n                                                    "
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            (_vm.hasPermissionTo(
                                              "archive user"
                                            ) ||
                                              _vm.hasPermissionTo(
                                                "update user"
                                              )) &&
                                            _vm.auth.user.profile.id !=
                                              scope.row.id &&
                                            !scope.row.is_destination &&
                                            _vm.allowUserUpdate(scope.row) &&
                                            _vm.allowUserSuspension(scope.row)
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row mt-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _vm.isUserSuspended(
                                                          scope.row
                                                        )
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm btn-block btn-info",
                                                                attrs: {
                                                                  "data-testid":
                                                                    "unsuspend-button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.unsuspendUserRemote(
                                                                        scope
                                                                          .row
                                                                          .id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-briefcase-medical pull-left",
                                                                }),
                                                                _vm._v(
                                                                  "\n                                                        Unsuspend\n                                                    "
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm btn-block purple-A400",
                                                                attrs: {
                                                                  "data-testid":
                                                                    "suspend-button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.suspendUserRemote(
                                                                        scope
                                                                          .row
                                                                          .id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-power-off pull-left",
                                                                }),
                                                                _vm._v(
                                                                  "\n                                                        Suspend\n                                                    "
                                                                ),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.hasPermissionTo(
                                              "archive user"
                                            ) &&
                                            _vm.auth.user.profile.id !=
                                              scope.row.id &&
                                            _vm.allowUserUpdate(scope.row) &&
                                            _vm.allowUserDelete(scope.row)
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row mt-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-sm btn-block danger",
                                                            attrs: {
                                                              "data-testid":
                                                                "delete-button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteUserRemote(
                                                                  scope.row.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-trash pull-left",
                                                            }),
                                                            _vm._v(
                                                              "\n                                                        Delete\n                                                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3632675696
                              ),
                            }),
                            _vm._v(" "),
                            _c(
                              "template",
                              {
                                staticClass: "text-center text-muted",
                                attrs: {
                                  "data-testid": "no-data-users-template",
                                },
                                slot: "empty",
                              },
                              [
                                !_vm.loading
                                  ? _c("span", [_vm._v("No Data")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.loading ? _c("span") : _vm._e(),
                              ]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.pageCount >= 1
                      ? _c(
                          "div",
                          { staticClass: "mt-3" },
                          [
                            _c("el-pagination", {
                              staticClass: "pull-right",
                              attrs: {
                                background: "",
                                layout: "sizes, prev, pager, next",
                                "page-sizes": [10, 20, 50, 100, 250],
                                "page-size": 20,
                                "current-page": _vm.pagination.current_page,
                                total: _vm.dataCount,
                                "data-testid": "users-list-pagination",
                              },
                              on: {
                                "update:currentPage": function ($event) {
                                  return _vm.$set(
                                    _vm.pagination,
                                    "current_page",
                                    $event
                                  )
                                },
                                "update:current-page": function ($event) {
                                  return _vm.$set(
                                    _vm.pagination,
                                    "current_page",
                                    $event
                                  )
                                },
                                "current-change": _vm.changePage,
                                "size-change": _vm.handleSizeChange,
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "clearfix" }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
      _vm._v(" "),
      _vm.isTrial && !_vm.isSimpSocial
        ? _c("video-modal", {
            staticClass: "pl-4",
            attrs: {
              title: "Adding Users to Your Aloware Account 👥",
              "cookie-name": "users",
              notes:
                "🔥 Meet <strong>Users</strong> - your agents or external digits mastering your calls!</br></br>🔍 Check their buzz, or hit pause, right here. Dive deep into user settings: tweak hours, control visibility, set notifications, and unleash more!</br></br>Dive deeper into the user universe here.",
              "video-url":
                "https://www.youtube.com/embed/ysMOYBj6MAE?si=OvKsYP8mEUuWApk8",
              "learn-more-link":
                "https://support.aloware.com/setting-user-status-to-available-in-aloware-admin-a-step-by-step-guide",
              "has-activator-button": true,
              "data-testid": "video-modal-to-add-user",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }