var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeAllMenus,
          expression: "closeAllMenus",
        },
      ],
      staticClass: "d-flex flex-fill flex-wrap wrap-auto-margin",
    },
    [
      _vm.hasPermissionTo("create tag") &&
      _vm.changed &&
      !_vm.filter_options.is_bulk &&
      !_vm.filter_options.no_save_as_tag
        ? _c(
            "div",
            [
              _c("tag-creator", {
                ref: "addTag",
                attrs: {
                  id: "add-tag",
                  title: "Save as Tag",
                  is_filter_tags: true,
                  filters: _vm.custom_filter,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "trigger",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-md rounded purple",
                              attrs: { "data-testid": "save-as-tag-button" },
                              on: {
                                click: function ($event) {
                                  _vm.$refs.addTag.hide_add = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    Save as Tag\n                "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1279551668
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.has_sort_communications
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "filter-div white compact no-bottom-margin bold-label no-select width-150",
              },
              [
                _c(
                  "el-form",
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "filter-select",
                            attrs: { "data-testid": "communicate-sort-select" },
                            on: { change: _vm.changeCommSortBy },
                            model: {
                              value: _vm.comm_sort_by,
                              callback: function ($$v) {
                                _vm.comm_sort_by = $$v
                              },
                              expression: "comm_sort_by",
                            },
                          },
                          _vm._l(_vm.comm_sort_by_options, function (option) {
                            return _c("el-option", {
                              key: option.value,
                              attrs: {
                                label: option.label,
                                value: option.value,
                                disabled: option.disabled,
                                title: option.tooltip,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            staticClass:
              "filter-div white compact no-bottom-margin bold-label no-select width-160",
          },
          [
            _c(
              "el-form",
              [
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "filter-select",
                        attrs: {
                          "popper-class": "min-width-240",
                          placeholder: "Select Date Field",
                          "data-testid": "communicate-date-sort-select",
                        },
                        on: { change: _vm.changeDateField },
                        model: {
                          value: _vm.date_field,
                          callback: function ($$v) {
                            _vm.date_field = $$v
                          },
                          expression: "date_field",
                        },
                      },
                      _vm._l(_vm.date_field_option, function (field, key) {
                        return _c(
                          "el-option",
                          {
                            key: key,
                            attrs: { label: field.label, value: field.value },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(field.label) +
                                    "\n                                 "
                                ),
                                _c(
                                  "el-popover",
                                  {
                                    staticClass: "ml-auto",
                                    attrs: {
                                      placement: "right",
                                      width: "400",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(field.info),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      class: `${field.icon} ml-2`,
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.filter_options.no_float
        ? _c(
            "div",
            [
              _c("date-selector", {
                attrs: {
                  is_bulk: _vm.filter_options.is_bulk,
                  default_date_range: _vm.default_date_range,
                  disable_default_report_period: true,
                  "data-testid": "timeframe-date-selector",
                },
                on: { updateCustomFilter: _vm.updateCustomFilter },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.filter_options.no_float
        ? _c(
            "div",
            { staticClass: "pb-2" },
            [
              _c("date-selector", {
                attrs: {
                  default_date_range: _vm.default_date_range,
                  disable_default_report_period: true,
                  "data-testid": "timeframe-no-flat-date-selector",
                },
                on: { updateCustomFilter: _vm.updateCustomFilter },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "no-select filters-menu" },
        [
          _vm.checkSize()
            ? _c(
                "button",
                {
                  staticClass: "btn btn-md white",
                  class: {
                    "w-full": _vm.filter_options.no_float,
                    "deep-orange": _vm.changed,
                  },
                  staticStyle: { height: "40px" },
                  attrs: {
                    type: "button",
                    "data-testid": "filter-w-full-button",
                  },
                  on: { click: _vm.toggleFiltersMenu },
                },
                [_vm._v("\n            Filters\n        ")]
              )
            : _c(
                "button",
                {
                  staticClass: "btn btn-md white",
                  class: [_vm.changed ? "deep-orange" : ""],
                  staticStyle: { height: "40px" },
                  attrs: { type: "button", "data-testid": "filter-md-button" },
                  on: { click: _vm.toggleFiltersMenu },
                },
                [_c("i", { staticClass: "fa fa-filter" })]
              ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "Filters",
                width: _vm.isLargeEnough ? "50%" : "100%",
                visible: _vm.filters_menu_visible,
                "before-close": _vm.closeFiltersMenu,
                top: "10vh",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.filters_menu_visible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "row nav-padding lean-bottom-margin bold-label right-align",
                },
                [
                  !_vm.only_campaign_selector
                    ? _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "span",
                            { staticClass: "filter-header text-muted" },
                            [_vm._v("Contact Properties")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "dropdown-divider" }),
                          _vm._v(" "),
                          !_vm.filter_options.is_bulk
                            ? _c(
                                "el-form",
                                {
                                  attrs: {
                                    "label-position": "left",
                                    "label-width": "150px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Line Type" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "filter-select",
                                          attrs: {
                                            placeholder: "Select Line Type",
                                            "data-testid":
                                              "contact-property-line-type-select",
                                            multiple: "",
                                            "collapse-tags": "",
                                          },
                                          on: { change: _vm.changeLrnTypes },
                                          model: {
                                            value: _vm.lrn_types,
                                            callback: function ($$v) {
                                              _vm.lrn_types = $$v
                                            },
                                            expression: "lrn_types",
                                          },
                                        },
                                        _vm._l(
                                          _vm.LrnTypes,
                                          function (lrn_type) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: lrn_type,
                                                attrs: {
                                                  label:
                                                    _vm._f("fixLrnType")(
                                                      lrn_type
                                                    ),
                                                  value: lrn_type,
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("fixLrnType")(
                                                        lrn_type
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Intake Source" } },
                                [
                                  _c("source-selector", {
                                    attrs: {
                                      source_type: "Intake Source",
                                      value: _vm.intake_source,
                                      sources: _vm.IntakeSources,
                                      "data-testid":
                                        "contact-property-intake-source-select",
                                    },
                                    on: { change: _vm.changeIntakeSource },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Lead Source" } },
                                [
                                  _c("source-selector", {
                                    attrs: {
                                      source_type: "Lead Source",
                                      value: _vm.lead_source,
                                      sources: _vm.leadSources,
                                      "data-testid":
                                        "contact-property-lead-source-select",
                                    },
                                    on: { change: _vm.changeLeadSource },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Tag" } },
                                [
                                  _c("tag-selector", {
                                    staticClass: "filter-select",
                                    attrs: {
                                      value: _vm.filter_tags,
                                      multiple: true,
                                      category_prop:
                                        _vm.TagCategory.CAT_CONTACTS,
                                      loaded: "",
                                      "data-testid":
                                        "contact-property-filter-tags-select",
                                    },
                                    on: { change: _vm.changeTags },
                                    model: {
                                      value: _vm.filter_tags,
                                      callback: function ($$v) {
                                        _vm.filter_tags = $$v
                                      },
                                      expression: "filter_tags",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Does Not Have Tag" } },
                                [
                                  _c("tag-selector", {
                                    staticClass: "filter-select",
                                    attrs: {
                                      value: _vm.filter_doesnt_have_tags,
                                      multiple: true,
                                      loaded: "",
                                      "data-testid":
                                        "contact-property-without-tag-select",
                                    },
                                    on: { change: _vm.changeDoesntHaveTags },
                                    model: {
                                      value: _vm.filter_doesnt_have_tags,
                                      callback: function ($$v) {
                                        _vm.filter_doesnt_have_tags = $$v
                                      },
                                      expression: "filter_doesnt_have_tags",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Country" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-select",
                                      attrs: {
                                        placeholder: "Select Country",
                                        filterable: "",
                                        clearable: "",
                                        "data-testid":
                                          "contact-property-country-select",
                                      },
                                      on: { change: _vm.changeContactCountry },
                                      model: {
                                        value: _vm.filter_contact_country,
                                        callback: function ($$v) {
                                          _vm.filter_contact_country = $$v
                                        },
                                        expression: "filter_contact_country",
                                      },
                                    },
                                    _vm._l(_vm.countries, function (country) {
                                      return _c("el-option", {
                                        key: country.id,
                                        attrs: {
                                          label: country.name,
                                          value: country.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              ref: "states-limit-form",
                              attrs: {
                                "label-position": "left",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "States" } },
                                [
                                  _c("states-province-selector", {
                                    staticClass: "filter-select",
                                    attrs: {
                                      value: _vm.states_limit,
                                      multiple: true,
                                      "data-testid":
                                        "contact-property-states-select",
                                    },
                                    on: { change: _vm.changeStatesLimitFilter },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Timezone" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-select",
                                      attrs: {
                                        placeholder: "Select Timezone",
                                        filterable: "",
                                        clearable: "",
                                        "data-testid":
                                          "contact-property-timezone-select",
                                      },
                                      on: { change: _vm.changeContactTimezone },
                                      model: {
                                        value: _vm.filter_contact_timezone,
                                        callback: function ($$v) {
                                          _vm.filter_contact_timezone = $$v
                                        },
                                        expression: "filter_contact_timezone",
                                      },
                                    },
                                    _vm._l(_vm.timezones, function (timezone) {
                                      return _c("el-option", {
                                        key: timezone.value,
                                        attrs: {
                                          label: timezone.name,
                                          value: timezone.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Has Untagged Calls" } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "contact-property-has-untagged-calls-switch",
                                    },
                                    on: { change: _vm.changeHasUntaggedCall },
                                    model: {
                                      value: _vm.has_untagged_call,
                                      callback: function ($$v) {
                                        _vm.has_untagged_call = $$v
                                      },
                                      expression: "has_untagged_call",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Untagged Contacts" } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "contact-property-untagged-contacts-switch",
                                    },
                                    on: { change: _vm.changeUntagged },
                                    model: {
                                      value: _vm.untagged_only,
                                      callback: function ($$v) {
                                        _vm.untagged_only = $$v
                                      },
                                      expression: "untagged_only",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "filter-header text-muted" },
                            [_vm._v("Contact Status")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "dropdown-divider" }),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Has Disposition Status" } },
                                [
                                  _c("disposition-status-selector", {
                                    ref: "hasDispositionStatus",
                                    staticClass: "filter-select",
                                    attrs: {
                                      value: _vm.filter_disposition_statuses,
                                      multiple: true,
                                      exclude:
                                        _vm.filter_no_disposition_statuses,
                                      show_not_disposed: false,
                                      is_disabled: Boolean(_vm.not_disposed),
                                      "data-testid":
                                        "contact-status-has-disposition-status-selector",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        _vm.filter_disposition_statuses = $event
                                      },
                                      change: _vm.changeDispositionStatuses,
                                    },
                                    model: {
                                      value: _vm.filter_disposition_statuses,
                                      callback: function ($$v) {
                                        _vm.filter_disposition_statuses = $$v
                                      },
                                      expression: "filter_disposition_statuses",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "250px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Does Not Have Disposition Status",
                                  },
                                },
                                [
                                  _c("disposition-status-selector", {
                                    ref: "notInDispositionStatus",
                                    staticClass: "filter-select ml-auto",
                                    attrs: {
                                      value: _vm.filter_no_disposition_statuses,
                                      multiple: true,
                                      exclude: _vm.filter_disposition_statuses,
                                      show_not_disposed: false,
                                      is_disabled: Boolean(_vm.not_disposed),
                                      "data-testid":
                                        "contact-status-without-disposition-status-selector",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        _vm.filter_no_disposition_statuses =
                                          $event
                                      },
                                      change: _vm.changeNoDispositionStatuses,
                                    },
                                    model: {
                                      value: _vm.filter_no_disposition_statuses,
                                      callback: function ($$v) {
                                        _vm.filter_no_disposition_statuses = $$v
                                      },
                                      expression:
                                        "filter_no_disposition_statuses",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Not Disposed" } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      disabled: _vm.isNotDisposeDisabled,
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "contact-status-not-disposed-switch",
                                    },
                                    on: { change: _vm.changeNotDisposed },
                                    model: {
                                      value: _vm.not_disposed,
                                      callback: function ($$v) {
                                        _vm.not_disposed = $$v
                                      },
                                      expression: "not_disposed",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Blocked Contacts" } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "contact-status-blocked-contacts-switch",
                                    },
                                    on: { change: _vm.changeIsBlocked },
                                    model: {
                                      value: _vm.is_blocked,
                                      callback: function ($$v) {
                                        _vm.is_blocked = $$v
                                      },
                                      expression: "is_blocked",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "DNC and Opt-Out Options" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-select ml-auto",
                                      attrs: {
                                        disabled: _vm.broadcast,
                                        "data-testid":
                                          "contact-status-dnc-opt-out-select",
                                      },
                                      on: { change: _vm.changeDncOption },
                                      model: {
                                        value: _vm.dnc_option,
                                        callback: function ($$v) {
                                          _vm.dnc_option = $$v
                                        },
                                        expression: "dnc_option",
                                      },
                                    },
                                    _vm._l(
                                      _vm.dnc_options,
                                      function (dnc_option) {
                                        return _c("el-option", {
                                          key: dnc_option.value,
                                          attrs: {
                                            value: dnc_option.value,
                                            label: dnc_option.label,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "filter-header text-muted" },
                            [_vm._v("Events")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "dropdown-divider" }),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Has Appointments" } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "events-has-appointments-switch",
                                    },
                                    on: { change: _vm.changeEventFilters },
                                    model: {
                                      value: _vm.has_appointments,
                                      callback: function ($$v) {
                                        _vm.has_appointments = $$v
                                      },
                                      expression: "has_appointments",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Has Reminders" } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "events-has-reminders-switch",
                                    },
                                    on: { change: _vm.changeEventFilters },
                                    model: {
                                      value: _vm.has_reminders,
                                      callback: function ($$v) {
                                        _vm.has_reminders = $$v
                                      },
                                      expression: "has_reminders",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.has_appointments == 1 || _vm.has_reminders == 1
                            ? _c(
                                "el-form",
                                {
                                  attrs: {
                                    "label-position": "left",
                                    "label-width": "200px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Range" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-right" },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "daterange",
                                              "range-separator": "To",
                                              "start-placeholder": "Start date",
                                              "end-placeholder": "End date",
                                              disabled:
                                                _vm.has_appointments == 0 &&
                                                _vm.has_reminders == 0,
                                              "data-testid":
                                                "events-date-range-picker",
                                            },
                                            on: {
                                              change: _vm.changeEventFilters,
                                            },
                                            model: {
                                              value: _vm.event_range,
                                              callback: function ($$v) {
                                                _vm.event_range = $$v
                                              },
                                              expression: "event_range",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.campaign_id
                            ? _c(
                                "div",
                                { staticClass: "mt-2" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "filter-header text-muted" },
                                    [_vm._v("Attribution")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "dropdown-divider",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-form",
                                    {
                                      attrs: {
                                        "label-position": "left",
                                        "label-width": "150px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Lines" } },
                                        [
                                          _c("campaign-selector", {
                                            staticClass:
                                              "filter-select pull-right p-0 campaign-selector",
                                            attrs: {
                                              value: _vm.filter_campaigns,
                                              multiple: true,
                                              small: true,
                                              "data-testid":
                                                "attribution-lines-select",
                                            },
                                            on: { change: _vm.changeCampaigns },
                                            model: {
                                              value: _vm.filter_campaigns,
                                              callback: function ($$v) {
                                                _vm.filter_campaigns = $$v
                                              },
                                              expression: "filter_campaigns",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-content-end",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-inline-flex align-items-center",
                                        },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              id: "initial_line_only",
                                              "active-color": "#00a344",
                                              "active-value": 1,
                                              "inactive-value": 0,
                                              "data-testid":
                                                "attribution-initial-line-only-switch",
                                            },
                                            on: {
                                              change: _vm.changeInitialLineOnly,
                                            },
                                            model: {
                                              value: _vm.initial_line_only,
                                              callback: function ($$v) {
                                                _vm.initial_line_only = $$v
                                              },
                                              expression: "initial_line_only",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "el-form-item__label mr-2",
                                              attrs: {
                                                for: "initial_line_only",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Initial line only\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermissionTo("list user") &&
                          !_vm.auth.user.profile.focus_mode
                            ? _c(
                                "div",
                                { staticClass: "mt-2" },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      attrs: {
                                        "label-position": "left",
                                        "label-width": "150px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Users" } },
                                        [
                                          _c("user-selector", {
                                            staticClass: "filter-select",
                                            attrs: {
                                              value: _vm.filter_users,
                                              multiple: true,
                                              "data-testid":
                                                "attribution-users-select",
                                            },
                                            on: { change: _vm.changeAgents },
                                            model: {
                                              value: _vm.filter_users,
                                              callback: function ($$v) {
                                                _vm.filter_users = $$v
                                              },
                                              expression: "filter_users",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermissionTo("list broadcast")
                            ? _c(
                                "div",
                                { staticClass: "mt-2" },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      attrs: {
                                        "label-position": "left",
                                        "label-width": "150px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Broadcasts" } },
                                        [
                                          _c("broadcast-selector", {
                                            staticClass: "filter-select",
                                            attrs: {
                                              value: _vm.filter_broadcasts,
                                              multiple: true,
                                              "data-testid":
                                                "attribution-broadcast-select",
                                            },
                                            on: {
                                              change: _vm.changeBroadcasts,
                                            },
                                            model: {
                                              value: _vm.filter_broadcasts,
                                              callback: function ($$v) {
                                                _vm.filter_broadcasts = $$v
                                              },
                                              expression: "filter_broadcasts",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "filter-header text-muted" },
                            [_vm._v("Other")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "dropdown-divider" }),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      _vm.current_company &&
                                      _vm.current_company.reseller_id == 357
                                        ? "Has Unread Texts"
                                        : "Has Unread Communications",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid": "other-unread-switch",
                                    },
                                    on: { change: _vm.changeHasUnread },
                                    model: {
                                      value: _vm.has_unread,
                                      callback: function ($$v) {
                                        _vm.has_unread = $$v
                                      },
                                      expression: "has_unread",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "New Leads" } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid": "other-new-leads-switch",
                                    },
                                    on: { change: _vm.changeIsNewLead },
                                    model: {
                                      value: _vm.is_new_lead,
                                      callback: function ($$v) {
                                        _vm.is_new_lead = $$v
                                      },
                                      expression: "is_new_lead",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Unassigned Leads" } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "other-unassigned-leads-switch",
                                    },
                                    on: { change: _vm.changeUnassignedLeads },
                                    model: {
                                      value: _vm.unassigned_leads,
                                      callback: function ($$v) {
                                        _vm.unassigned_leads = $$v
                                      },
                                      expression: "unassigned_leads",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Follow the sun" } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "other-follow-the-sun-switch",
                                    },
                                    on: {
                                      change: _vm.changeShouldFollowTheSun,
                                    },
                                    model: {
                                      value: _vm.should_follow_the_sun,
                                      callback: function ($$v) {
                                        _vm.should_follow_the_sun = $$v
                                      },
                                      expression: "should_follow_the_sun",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              ref: "responded-form",
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                                model: _vm.responded_model,
                                disabled: _vm.not_responded > 0,
                                rules: _vm.rules_responded,
                              },
                              nativeOn: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.preventSubmit.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "not-responded-filter",
                                  attrs: { prop: "responded_hours" },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "Filter contacts that responded in the last number of hours.",
                                            placement: "top",
                                          },
                                        },
                                        [_c("span", [_vm._v("Last Response")])]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "other-last-response-switch",
                                    },
                                    on: { change: _vm.changeResponded },
                                    model: {
                                      value: _vm.responded,
                                      callback: function ($$v) {
                                        _vm.responded = $$v
                                      },
                                      expression: "responded",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.responded
                                    ? _c(
                                        "el-input",
                                        {
                                          staticClass:
                                            "pull-right col-3 p-0 text-center el-input__inner mr-2",
                                          staticStyle: {
                                            "text-align": "center !important",
                                          },
                                          attrs: {
                                            type: "number",
                                            min: "0",
                                            "data-testid": "other-hour-input",
                                          },
                                          on: {
                                            input: _vm.changeRespondedHours,
                                          },
                                          model: {
                                            value:
                                              _vm.responded_model
                                                .responded_hours,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.responded_model,
                                                "responded_hours",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "responded_model.responded_hours",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("hour(s)"),
                                          ]),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              ref: "not-responded-form",
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                                model: _vm.not_responded_model,
                                disabled: _vm.responded > 0,
                                rules: _vm.rules_not_responded,
                              },
                              nativeOn: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.preventSubmit.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "not-responded-filter",
                                  attrs: { prop: "not_responded_hours" },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "Filter contacts has no response in the last number of hours.",
                                            placement: "top",
                                          },
                                        },
                                        [_c("span", [_vm._v("No Response")])]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid": "other-no-response-switch",
                                    },
                                    on: { change: _vm.changeNotResponded },
                                    model: {
                                      value: _vm.not_responded,
                                      callback: function ($$v) {
                                        _vm.not_responded = $$v
                                      },
                                      expression: "not_responded",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.not_responded
                                    ? _c(
                                        "el-input",
                                        {
                                          staticClass:
                                            "pull-right col-3 p-0 text-center el-input__inner mr-2",
                                          staticStyle: {
                                            "text-align": "center !important",
                                          },
                                          attrs: {
                                            type: "number",
                                            min: "0",
                                            "data-testid":
                                              "other-not-responded-hours-input",
                                          },
                                          on: {
                                            input: _vm.changeNotRespondedHours,
                                          },
                                          model: {
                                            value:
                                              _vm.not_responded_model
                                                .not_responded_hours,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.not_responded_model,
                                                "not_responded_hours",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "not_responded_model.not_responded_hours",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("hour(s)"),
                                          ]),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              ref: "not-contacted-form",
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                                model: _vm.not_contacted_model,
                                rules: _vm.rules_not_contacted,
                              },
                              nativeOn: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.preventSubmit.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "not-contacted-filter",
                                  attrs: { prop: "not_contacted_hours" },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "Filter contacts that has not been called or texted by an agent for the last number of hours",
                                            placement: "top",
                                          },
                                        },
                                        [_c("span", [_vm._v("No Contact")])]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid": "other-no-contact-switch",
                                    },
                                    on: { change: _vm.changeNotContacted },
                                    model: {
                                      value: _vm.not_contacted,
                                      callback: function ($$v) {
                                        _vm.not_contacted = $$v
                                      },
                                      expression: "not_contacted",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.not_contacted
                                    ? _c(
                                        "el-input",
                                        {
                                          staticClass:
                                            "pull-right col-3 p-0 text-center el-input__inner mr-2",
                                          staticStyle: {
                                            "text-align": "center !important",
                                          },
                                          attrs: {
                                            type: "number",
                                            min: "0",
                                            "data-testid":
                                              "other-no-contact-input",
                                          },
                                          on: {
                                            input: _vm.changeNotContactedHours,
                                          },
                                          model: {
                                            value:
                                              _vm.not_contacted_model
                                                .not_contacted_hours,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.not_contacted_model,
                                                "not_contacted_hours",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "not_contacted_model.not_contacted_hours",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("hour(s)"),
                                          ]),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "left",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "TCPA Approved" } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "other-tcpa-approved-switch",
                                    },
                                    on: { change: _vm.changeTextAuthorized },
                                    model: {
                                      value: _vm.text_authorized,
                                      callback: function ($$v) {
                                        _vm.text_authorized = $$v
                                      },
                                      expression: "text_authorized",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              ref: "outbound-call-count-start",
                              attrs: {
                                "label-position": "left",
                                "label-width": "140px",
                                model: _vm.outbound_call_count_model,
                                rules: _vm.rules_outbound_call_count,
                              },
                              nativeOn: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.preventSubmit.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "outbound-call-count-filter",
                                  attrs: {
                                    label: "Outbound Call Count",
                                    prop: "outbound_call_count_start",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "other-outbound-call-count-switch",
                                    },
                                    on: { change: _vm.changeCallCount },
                                    model: {
                                      value: _vm.outbound_call_count_switch,
                                      callback: function ($$v) {
                                        _vm.outbound_call_count_switch = $$v
                                      },
                                      expression: "outbound_call_count_switch",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-auto d-flex justify-content-end",
                                    },
                                    [
                                      _vm.outbound_call_count_switch
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "inputs pull-right d-flex justify-content-end pr-3",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mr-2 align-self-center small",
                                                },
                                                [_vm._v("Called between")]
                                              ),
                                              _vm._v(" "),
                                              _c("el-input", {
                                                staticClass: "col-2 p-0 mr-1",
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                  size: "mini",
                                                  "data-testid":
                                                    "other-called-between-start-input",
                                                },
                                                on: {
                                                  input:
                                                    _vm.changeCallCountStart,
                                                },
                                                model: {
                                                  value:
                                                    _vm
                                                      .outbound_call_count_model
                                                      .outbound_call_count_start,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.outbound_call_count_model,
                                                      "outbound_call_count_start",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "outbound_call_count_model.outbound_call_count_start",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mr-1 align-self-center small",
                                                },
                                                [_vm._v("and")]
                                              ),
                                              _vm._v(" "),
                                              _c("el-input", {
                                                staticClass: "col-2 p-0 mr-1",
                                                attrs: {
                                                  type: "number",
                                                  min: _vm.outbound_call_count_end_min,
                                                  size: "mini",
                                                  "data-testid":
                                                    "other-called-between-end-input",
                                                },
                                                on: {
                                                  change:
                                                    _vm.changeCallCountEnd,
                                                },
                                                model: {
                                                  value:
                                                    _vm
                                                      .outbound_call_count_model
                                                      .outbound_call_count_end,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.outbound_call_count_model,
                                                      "outbound_call_count_end",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "outbound_call_count_model.outbound_call_count_end",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "align-self-center small",
                                                },
                                                [_vm._v("times")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              ref: "scheduled-message-form",
                              attrs: {
                                "label-position": "left",
                                "label-width": "250px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Has Scheduled Messages" } },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "other-has-scheduled-messages-switch",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.scheduledMessageFilterChange(
                                          "has_scheduled_messages"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.has_scheduled_messages,
                                      callback: function ($$v) {
                                        _vm.has_scheduled_messages = $$v
                                      },
                                      expression: "has_scheduled_messages",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Does Not Have Scheduled Messages",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "other-does-not-have-scheduled-messages-switch",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.scheduledMessageFilterChange(
                                          "no_scheduled_messages"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.no_scheduled_messages,
                                      callback: function ($$v) {
                                        _vm.no_scheduled_messages = $$v
                                      },
                                      expression: "no_scheduled_messages",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              ref: "in-sequence",
                              attrs: {
                                "label-position": "left",
                                "label-width": "250px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Is Enrolled in Sequences" },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "other-is-enrolled-in-sequences-switch",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.enrolledInSequenceFilterChange(
                                          "enrolled_in_sequence"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.enrolled_in_sequence,
                                      callback: function ($$v) {
                                        _vm.enrolled_in_sequence = $$v
                                      },
                                      expression: "enrolled_in_sequence",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Not Enrolled in Sequences" },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#00a344",
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "data-testid":
                                        "other-not-enroleld-in-sequences-switch",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.enrolledInSequenceFilterChange(
                                          "not_enrolled_in_sequence"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.not_enrolled_in_sequence,
                                      callback: function ($$v) {
                                        _vm.not_enrolled_in_sequence = $$v
                                      },
                                      expression: "not_enrolled_in_sequence",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.only_campaign_selector
                    ? _c("div", { staticClass: "col-12" }, [
                        !_vm.campaign_id
                          ? _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "filter-header text-muted" },
                                  [_vm._v("Attribution")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "dropdown-divider" }),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "label-width": "150px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "Lines" } },
                                      [
                                        _c("campaign-selector", {
                                          staticClass: "filter-select",
                                          attrs: {
                                            value: _vm.filter_campaigns,
                                            multiple: true,
                                            "data-testid":
                                              "attribution-lines-selector",
                                          },
                                          on: { change: _vm.changeCampaigns },
                                          model: {
                                            value: _vm.filter_campaigns,
                                            callback: function ($$v) {
                                              _vm.filter_campaigns = $$v
                                            },
                                            expression: "filter_campaigns",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 mt-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn greenish p-x-md pull-right",
                        attrs: { "data-testid": "apply-our-filters-button" },
                        on: { click: _vm.applyOurFilters },
                      },
                      [
                        _vm._v(
                          "\n                        Apply\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn blackish p-x-md pull-right mr-2",
                        attrs: { "data-testid": "before-reset-filters-button" },
                        on: { click: _vm.beforeResetFilters },
                      },
                      [
                        _vm._v(
                          "\n                        Reset Filters\n                    "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.changed && !_vm.hide_reset_filter,
              expression: "changed && !hide_reset_filter",
            },
          ],
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-md blackish p-x-md",
              staticStyle: { height: "40px" },
              attrs: { "data-testid": "reset-contact-filters-button" },
              on: { click: _vm.resetContactFilters },
            },
            [_vm._v("\n            Reset Filters\n        ")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.hasRole("Company Admin") && !_vm.filter_options.no_export
        ? _c("div", { staticClass: "dropdown" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle",
                attrs: { "data-testid": "export-button-dropdown" },
                on: { click: _vm.toggleExportsMenu },
              },
              [_vm._v("\n            Export\n        ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dropdown-menu dropdown-menu-right",
                class: _vm.exports_menu_visible ? "show" : "",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { "data-testid": "export-to-csv-link" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.exportTo("csv-all", false)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-table" }),
                    _vm._v("\n                CSV File\n            "),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }