<template>
    <div class="app-body"
         id="view"
         v-if="broadcast">

        <div class="fixed-header padding pt-0 pb-0" :style="{top: (top_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <comm-advanced-filters-wrapper :broadcast_id="broadcast_id"></comm-advanced-filters-wrapper>
                </div>
            </div>
        </div>

        <div class="padding mt-5 pt-3 pb-0 row">
            <div class="col-12">
                <communication-textual-filters></communication-textual-filters>
            </div>
        </div>

        <div class="padding pt-0"
             v-if="hasPermissionTo('list report') && show_graph">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-body pt-0">
                            <activity-graph base="broadcast"
                                            :broadcast_id="broadcast_id"
                                            :is_first_load="reports_first_load">
                            </activity-graph>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="padding pt-0"
             v-if="hasPermissionTo('list communication')">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-body pt-0">
                            <comm-log-table :broadcast_id="broadcast_id"></comm-log-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../../auth'
import {
    chargebee_mixin,
    filter_mixin,
    scroll_mixin,
    styling_mixin
} from '../../mixins'

export default {
    mixins: [
        styling_mixin,
        chargebee_mixin,
        scroll_mixin,
        filter_mixin
    ],

    props: {
        broadcast_id: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            broadcast: null,
            reports_first_load: true
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            show_graph: state => state.cache.show_graph
        }),

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        }
    },

    mounted() {
        if (!this.auth.user.profile.automation_enabled && !this.auth.user.profile.bulk_sms_enabled && !this.auth.user.profile.bulk_rvm_enabled) {
            this.goBack()
        }
        if (this.hasPermissionTo('view workflow')) {
            this.getBroadcast(this.broadcast_id).then(() => {
                this.setTitle()
                VueEvent.fire('page_title_updated', this.broadcast.name)
            })
        }
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Broadcast Activity - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Broadcast Activity - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        getBroadcast(broadcast_id) {
            return axios.get('/api/v1/broadcasts/' + broadcast_id, {
                mode: 'no-cors',
            }).then(res => {
                this.broadcast = res.data
                return Promise.resolve(res)
            }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
                return Promise.reject(err)
            })
        },
    },

    watch: {
        'show_graph': function () {
            this.reports_first_load = false
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
