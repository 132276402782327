<template>
    <el-dialog custom-class="toast-kyc"
               :visible.sync="show" 
               :modal-append-to-body="true"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false"
               :append-to-body="true"
               top="20px"
               width="80%">
        <div class="text-center">
            Your trial account access has changed based on your business information submitted, to access the new permissions, 
            <el-button size="small"
                       type="text"
                       @click="reload">
                Reload Now
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import auth from '../auth'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'KycReloadDialog',

    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            profile: auth.user.profile
        }
    },

    computed: {
        ...mapState(['showedKycDialog']),

        ...mapState('cache', ['current_company']),

        userFullName () {
            return `${this.profile?.first_name} ${this.profile?.last_name}` || ''
        }
    },

    methods: {
        ...mapActions(['setShowedKycReloadDialog']),

        reload () {
            //this.setShowedKycReloadDialog(false)
            window.location.reload()
        }
    }
}
</script>
