<template>
    <div class="row">
        <div class="col-12 col-md-8 mx-auto">
            <div class="text-center mb-2">
                <el-button type="success"
                           class="mb-3"
                           :disabled="isViewOnlyAccess()"
                           data-testid="refresh-button"
                           @click="runDiagnosis">
                    <i class="material-icons loader"
                       v-if="loading">
                        &#xE863;
                    </i>
                    <i class="material-icons"
                       v-else>refresh
                    </i>
                    Refresh
                </el-button>
            </div>
            <div v-loading="loading" class="mh-100">
                <div class="text-center"
                     v-if="user_result">
                    <i class="fas mr-2 mb-2"
                       :class="user_result.agent_is_eligible_to_take_call ? 'fa-phone color-999': 'fa-phone-slash text-danger'"
                       style="font-size: 40px">
                    </i>
                    <h6>{{ user_result.model.full_name }}</h6>
                    <p>Time of test: {{ new Date() | momentFormat('MM/DD h:mma z', true) }}</p>
                </div>

                <div class="text-center py-2">
                    <i class="fas fa-arrow-down" style="font-size: 30px;color: #acacac"></i>
                </div>

                <div class="card p-2 mb-3">
                    <table v-if="user_result"
                           class="table table-sm m-0 table-routing-test">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Diagnosis</th>
                                <th class="text-center">Can take calls?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="pt-3 pr-0 d-flex flex-column align-items-center">
                                    <span v-if="user_result.model.is_destination"
                                          class="btn btn-xs text-white cursor-default btn-block"
                                          :class="[ $options.filters.agentStatusClass(1) ]">
                                        Available
                                    </span>
                                    <span v-else
                                          class="btn btn-xs text-white cursor-default btn-block w-auto"
                                          :class="[ $options.filters.agentStatusClass(user_result.model.agent_status) ]">
                                        {{ AgentStatusLabels.LABELS.find(label => label.value === user_result.model.agent_status).label }}
                                    </span>
                                    <el-button type="success"
                                        class="text-center mt-2"
                                        @click="handleResetStatusToAvailable"
                                        v-if="authUserIsCompanyAdmin && !userIsAvailable">
                                        <i class="material-icons">refresh</i>
                                        Reset status to available
                                    </el-button>
                                </td>
                                <td>
                                    <ul class="list-unstyled">
                                        <li v-for="result in user_result.results"
                                            :key="result.key">
                                            <i v-if="result.value"
                                               class="material-icons text-dark-greenish">
                                                check
                                            </i>
                                            <i v-else
                                               class="material-icons text-danger">
                                                close
                                            </i>
                                            {{ result.label }}
                                        </li>
                                    </ul>
                                </td>
                                <td class="td-can-take-calls text-center">
                                    <strong v-if="user_result.agent_is_eligible_to_take_call"
                                            class="text-dark-greenish">
                                        Yes
                                    </strong>
                                    <strong v-else class="text-danger">
                                        No
                                    </strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="text-center color-999 mb-4">
                    Please note that this utility does not look into contact / communication ownership.
                </p>
                <div v-if="on_going_calls.length > 0">
                    <h5>
                        Ongoing Calls
                    </h5>
                </div>
                <div class="card py-2 px-3 mb-3" v-for="ongoing_call in on_going_calls">
                    <div class="media">
                        <i :class="ongoing_call.direction === CommunicationDirection.OUTBOUND ? 'fa fa-arrow-circle-up' : 'fa fa-arrow-circle-down'"
                           class="align-self-center mr-3"
                           style="font-size: 35px; color: #acacac">
                        </i>
                        <div class="media-body">
                            {{ ongoing_call.direction === CommunicationDirection.OUTBOUND ? 'Outbound' : 'Inbound' }} call with
                            <div v-if="ongoing_call.contact && ongoing_call.contact.name">
                                <h6 class="mb-0">{{ ongoing_call.contact.name }} ({{ ongoing_call.contact.phone_number | fixPhone }})</h6>
                            </div>
                            <div v-else>
                                <h6 class="mb-0">{{ ongoing_call.lead_number | fixPhone }}</h6>
                            </div>
                        </div>
                        <button class="btn btn-md btn-danger"
                                data-testid="terminate-button"
                                @click="terminateCall(ongoing_call.id)">
                            Terminate
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as AgentStatus from '../../constants/agent-status'
import * as AgentStatusLabels from '../../constants/agent-status-labels'
import * as CommunicationDirection from '../../constants/communication-direction'
import { acl_mixin, kyc_mixin, helper_mixin } from '../../mixins'

export default {
    name: "user-diagnosis",
    props: ['user'],

    mixins: [
        acl_mixin,
        kyc_mixin,
        helper_mixin
    ],

    data() {
        return {
            loading: false,
            user_result: null,
            on_going_calls: [],
            AgentStatus,
            AgentStatusLabels,
            CommunicationDirection
        }
    },

    computed: {
        authUserIsCompanyAdmin() {
            return this.inArray('Company Admin', this.auth.user.profile.role_names)
        },

        userIsAvailable() {
            return this.user_result?.model?.agent_status === AgentStatus.AGENT_STATUS_ACCEPTING_CALLS
        }
    },

    mounted() {
        this.runDiagnosis()
    },

    methods: {
        runDiagnosis() {
            this.loading = true
            axios.get(`/api/v1/diagnosis/user/${this.user.id}`).then(res => {
                this.user_result = res.data.diagnosis
                this.on_going_calls = res.data.on_going_calls
                this.loading = false
            }).catch(err => {
                console.log(err)
                this.loading = true
            })
        },

        terminateCall(communication_id) {
            axios.post('/api/v1/communication/' + communication_id + '/force-terminate')
                .then(res => {
                    this.runDiagnosis()

                    this.$notify({
                        offset: 95,
                        title: 'User',
                        message: res.data.success,
                        type: 'success'
                    })
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                })
        },

        changeAgentStatus: _.debounce(function (val, signature = 'Unknown') {
            this.loading = true

            axios.post('/api/v1/user/' + this.user.id + '/agent-status', {
                agent_status: val
            }, {
                headers: {
                    'Signature': signature,
                }
            }).then(res => {
                this.runDiagnosis()
                this.$notify({
                    offset: 95,
                    title: 'Agent Status',
                    message: 'Agent is available now.',
                    type: 'success'
                })
            }).catch(err => {
                this.$root.handleErrors(err.response)
            }).finally(() => {
                this.loading = false
            })
        }, 500),

        handleResetStatusToAvailable() {
            this.changeAgentStatus(AgentStatus.AGENT_STATUS_ACCEPTING_CALLS, 'Classic-ResetAvailableStatus')
        }
    }
}
</script>
