<template>
    <div v-if="hasPermissionTo('list campaign')">
        <el-select v-model="campaign_id"
                   data-testid="campaign-selector"
                   :class="computedClasses"
                   :placeholder="computedPlaceholder"
                   :multiple="multiple"
                   :size="small ? 'small' : ''"
                   :disabled="disabled"
                   :clearable="clearable"
                   :collapse-tags="collapsable"
                   default-first-option
                   filterable>
            <el-option-group key="Active Lines"
                             label="Active Lines"
                             v-if="activeCampaignsAlphabeticalOrder && activeCampaignsAlphabeticalOrder.length > 0 && show_active">
                <el-option
                    v-for="campaign in activeCampaignsAlphabeticalOrder"
                    :key="campaign.id"
                    :label="campaign.name"
                    :value="campaign.id"
                    :disabled="shouldDisableOption(campaign)">
                    <el-tooltip
                        :disabled="!isMessagingBlocked(campaign, true)"
                        content="To send messages to the US, A2P 10DLC Brand and Campaign are required."
                        placement="left"
                    >
                        <span :class="{ 'not-registered': isMessagingBlocked(campaign) }">
                            {{ campaign.name }}
                            <span
                                v-if="campaign.has_local_presence"
                                class="label ml-2 p-1 text-xxs blue"
                            >
                                Has Local Presence
                            </span>
                        </span>
                    </el-tooltip>
                </el-option>
            </el-option-group>
            <el-option-group key="Paused Lines"
                             label="Paused Lines"
                             v-if="pausedCampaignsAlphabeticalOrder && pausedCampaignsAlphabeticalOrder.length > 0 && show_paused">
                <el-option
                    v-for="campaign in pausedCampaignsAlphabeticalOrder"
                    :key="campaign.id"
                    :label="campaign.name"
                    :value="campaign.id">
                    <el-tooltip
                        :disabled="!isMessagingBlocked(campaign, true)"
                        content="To send messages to the US, A2P 10DLC Brand and Campaign are required."
                        placement="left"
                    >
                        <span :class="{ 'not-registered': isMessagingBlocked(campaign) }">{{ campaign.name }}</span>
                    </el-tooltip>
                </el-option>
            </el-option-group>
        </el-select>
        <div class="not-registered-desc"
             v-if="blockedMessagingInformation['blocked'] && (!blockedMessagingInformation['automated_only'] || is_automated)">
            <p class="pb-4">{{ blockedMessagingInformation['reason'] }}</p>
            <a href="/account?tab=compliance"
               data-testid="campaign-selector-register-now-button">
               Register Now
            </a>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../auth'
import {acl_mixin} from '../mixins'
import isEmpty from 'lodash/isEmpty';

export default {
    mixins: [acl_mixin],

    props: {
        value: {
            required: false,
        },

        exclude: {
            required: false,
        },

        small: {
            default: false,
            type: Boolean,
            required: false
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        is_fax: {
            type: Boolean,
            required: false,
            default: false
        },

        is_sms: {
            type: Boolean,
            required: false,
            default: false
        },

        clearable: {
            type: Boolean,
            required: false,
            default: false
        },

        collapsable: {
            type: Boolean,
            required: false,
            default: true
        },

        show_active: {
            type: Boolean,
            required: false,
            default: true
        },

        show_paused: {
            type: Boolean,
            required: false,
            default: true
        },

        placeholder: {
            type: String,
            required: false,
            default: 'Select line'
        },

        class_prop: {
            type: String,
            required: false,
            default: 'w-full'
        },

        only_10_dlc: {
            type: Boolean,
            default: false
        },

        check_blocked_messaging: {
            type: Boolean,
            default: false
        },

        is_automated: {
            type: Boolean,
            default: false
        },

        use_first_option: {
            type: Boolean,
            required: false,
            default: false
        },

        unregistered_lines_only: {
            type: Boolean,
            required: false,
            default: false
        },

        is_a2p_campaign_wizard: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            auth: auth,
            campaign_id: this.value,
            loading: false,
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            campaigns: state => state.campaigns
        }),
        ...mapState(['unregistered_lines']),

        selectedLineIsNotRegistered() {
            if (!this.check_a2p_registered) {
                return false
            }

            const campaign = this.activeCampaignsAlphabeticalOrder.find(campaign => campaign.id === this.campaign_id)
            return !isEmpty(campaign) && campaign.is_10_dlc && !campaign.is_a2p_registered
        },

        blockedMessagingInformation() {
            const result = {
                'blocked': false,
                'reason': null,
                'automated_only': false
            }

            if (!this.check_blocked_messaging || !this.campaign_id) {
                return result
            }

            const campaign = this.activeCampaignsAlphabeticalOrder.find(campaign => campaign.id === this.campaign_id)
            if (isEmpty(campaign)) {
                return result
            }

            return campaign.blocked_messaging_information
        },

        computedClasses() {
            return this.selectedLineIsNotRegistered ? this.class_prop + ' not-registered' : this.class_prop
        },

        computedPlaceholder() {
            if (this.multiple) {
                return 'Select lines'
            }

            return this.placeholder
        },

        availableCampaigns() {
            if (this.unregistered_lines_only && this.unregistered_lines) {
                return this.unregistered_lines
            }

            if (this.campaigns) {
                return this.campaigns.filter((campaign) => {
                    return (campaign.id !== this.exclude && (this.only_10_dlc ? campaign.is_10_dlc : true))
                })
            }

            return []
        },

        campaignsAlphabeticalOrder() {
            if (this.availableCampaigns) {
                let campaigns = _.clone(this.availableCampaigns)
                return campaigns.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },

        activeCampaignsAlphabeticalOrder() {
            if (this.campaignsAlphabeticalOrder.length) {
                let campaigns = _.clone(this.campaignsAlphabeticalOrder)
                return campaigns.filter(campaign => campaign.active == true)
            }

            return []
        },

        pausedCampaignsAlphabeticalOrder() {
            if (this.campaignsAlphabeticalOrder.length) {
                let campaigns = _.clone(this.campaignsAlphabeticalOrder)
                return campaigns.filter(campaign => campaign.active == false)
            }

            return []
        },

        hasSelectedLocalPresenceLine() {
            const selectedCampaigns = this.campaigns.filter(campaign => this.campaign_id.includes(campaign.id))
            return selectedCampaigns.length > 0 && selectedCampaigns.some(campaign => campaign.has_local_presence)
        },

        hasSelectedRegularLine() {
            const selectedCampaigns = this.campaigns.filter(campaign => this.campaign_id.includes(campaign.id))
            return selectedCampaigns.length > 0 && selectedCampaigns.some(campaign => !campaign.has_local_presence)
        }
    },

    mounted() {
        if (!this.multiple) {
            this.updateCampaignIdIfNeeded()
        }
    },

    methods: {
        isMessagingBlocked(campaign) {
            // Keep showing the (i) blue mark alongside the lines even if bypassed.
            return this.check_blocked_messaging &&
                campaign.blocked_messaging_information['blocked'] &&
                (
                    (campaign.blocked_messaging_information['automated_only'] && this.is_automated) ||
                    (!campaign.blocked_messaging_information['automated_only'])
                )
        },

        getValidCampaign() {
            const campaign_id = this.campaign_id
                ? this.campaign_id
                : (this.use_first_option ? this.activeCampaignsAlphabeticalOrder[0].id : null)

            let campaign_index = null
            if (this.unregistered_lines_only) {
                campaign_index = this.unregistered_lines.findIndex(campaign => campaign.id === campaign_id)
            } else {
                campaign_index = this.campaigns.findIndex(campaign => campaign.id === campaign_id)
            }

            let campaign = null

            // return null campaign if index is not
            if (campaign_index < 0) {
                return campaign
            }

            // Get the campaign from input/value passed
            if (this.unregistered_lines_only) {
                campaign = this.unregistered_lines[campaign_index]
            } else {
                campaign = this.campaigns[campaign_index]
            }

            // If show_paused is off and campaign from input/value is paused, return null campaign
            if (this.show_paused === false && campaign && !campaign.active) {
                campaign = null
            }

            // if show_active is off and campaign from input/value is active, return null campaign
            if (this.show_active === false && campaign && campaign.active) {
                campaign = null
            }

            // If show_active and show_paused is both off and we have an existing campaign from input/value, return null.
            if (this.show_active === false && this.show_paused === false && campaign_index > -1) {
                campaign = null
            }

            // If the original input/value have value and we processed it to be null, then trigger the form validation of the form using this component
            if (this.campaign_id && campaign === null) {
                this.$emit('trigger_validation')
            }

            return campaign ? campaign.id : null
        },

        updateCampaignIdIfNeeded() {
            const campaign_id = this.getValidCampaign()
            if (campaign_id !== null) {
                this.campaign_id = campaign_id
            }
        },

        shouldDisableOption(campaign) {
            if (!this.is_a2p_campaign_wizard) {
                return false
            }

            if (!this.value) {
                return false
            }

            // If has selected a local presence line and the current line is not local presence, disable the option
            if (this.hasSelectedLocalPresenceLine && !campaign.has_local_presence) {
                return true
            }

            // If has selected a non-local presence line and the current line is local presence, disable the option
            if (this.hasSelectedRegularLine && campaign.has_local_presence) {
                return true
            }

            return false
        }
    },

    watch: {
        value() {
            this.campaign_id = this.value
            if (!this.value) {
                this.$emit('clear')
            }
        },

        campaign_id(val) {
            if (this.value !== undefined && this.campaign_id !== this.value) {
                this.$emit('change', val)
            } else {
                this.$emit('input', val)
            }
        },

        selectedLineIsNotRegistered(val) {
            if (val) {
                this.$emit('not_registered')
            }
        },

        campaigns(campaigns) {
            if (campaigns && campaigns.length) {
                this.updateCampaignIdIfNeeded()
            }
        }
    }
}
</script>

<style scoped>
.not-registered::after {
    content: "i";
    position: absolute;
    right: 50px;
    display: inline-block;
    width: 30px;
    height: 25px;
    line-height: 25px;
    border-radius: 10px;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background: #0071e2;
    color: #fff;
    z-index: 999999999;
}

.not-registered-desc {
    position: relative;
    width: 100%;
    background: #BD1A1A;
    border-radius: 10px;
    padding: 5px;
    margin-top: 10px;
}

.not-registered-desc p {
    color: #fff;
}

.unable-send-bulk-messaging {
    color: #C4183C;
}

.not-registered-desc a {
    position: absolute;
    text-align: center;
    right: 5px;
    bottom: 5px;
    border-radius: 4px;
    background: #fff;
    color: #000;
    height: 26px;
    line-height: 26px;
    width: 95px;
}

.not-registered-desc a:hover {
    color: #ccc;
}
</style>
