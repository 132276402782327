var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c("strong", { staticClass: "text-md" }, [
        _vm._v("Your account API token is: "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group width-300" }, [
        _c("input", {
          ref: "api_token",
          staticClass: "form-control text-md",
          staticStyle: { resize: "none", "background-color": "#eff0f1" },
          attrs: { type: _vm.companyTokenElementType, disabled: "" },
          domProps: { value: _vm.current_company.form_capture_token },
          on: { click: _vm.copyApiToken },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              attrs: { type: "button" },
              on: { click: _vm.toggleCompanyTokenVisibility },
            },
            [_c("i", { staticClass: "fa fa-eye-slash" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "popover",
                  rawName: "v-popover:api-popover",
                  arg: "api-popover",
                },
              ],
              staticClass: "btn greenish",
              attrs: { type: "button" },
              on: { click: _vm.copyApiToken },
            },
            [_vm._v("\n                Copy\n            ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("el-button", { on: { click: _vm.resetToken } }, [
            _vm._v("\n            Generate new token\n        "),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }