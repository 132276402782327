// handle by text.
export const QUEUE_RESOLUTION_HANDLE_BY_TEXT = 1
// request callback.
export const QUEUE_RESOLUTION_CALLBACK_REQUESTED = 2
// voicemail.
export const QUEUE_RESOLUTION_VOICEMAIL = 3

// handle by text.
export const QUEUE_RESOLUTION_TXT_HANDLE_BY_TEXT = 'handled-by-text'
// request callback.
export const QUEUE_RESOLUTION_TXT_CALLBACK_REQUESTED = 'callback-requested'
// voicemail.
export const QUEUE_RESOLUTION_TXT_VOICEMAIL = 'voicemail'