import { render, staticRenderFns } from "./recording-mode.vue?vue&type=template&id=46702d98"
import script from "./recording-mode.vue?vue&type=script&lang=js"
export * from "./recording-mode.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/api-core/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('46702d98')) {
      api.createRecord('46702d98', component.options)
    } else {
      api.reload('46702d98', component.options)
    }
    module.hot.accept("./recording-mode.vue?vue&type=template&id=46702d98", function () {
      api.rerender('46702d98', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/user/components/account/recording/recording-mode.vue"
export default component.exports