<template>
    <div>
        <div
            v-if="hasTimestamps"
            v-loading="loading"
            class="min-height-40-vh max-height-60-vh scroll-y">
            <el-timeline>
                <el-timeline-item
                    v-if="import_contact"
                    color="#00A344"
                    size="normal"
                    :timestamp="import_contact.created_at | fixDateTime">
                    Created
                </el-timeline-item>
                <el-timeline-item
                    v-for="record in import_timestamps"
                    :key="record.id"
                    :color="record.status_color"
                    size="normal"
                    :timestamp="record.logged_at | fixDateTime">
                    {{ record.status_name }}
                </el-timeline-item>
            </el-timeline>
        </div>

        <div
            v-else
            class="el-table__empty-block">
            <div
                class="text-center el-table__empty-text import-log-empty-text">
                <img-empty class="mx-auto w-100"/>
                <div>No timeline for this import.</div>
                <small class="text-italic import-log-note">Import status timeline starts at October 15, 2021. Previous imports will not show any status timeline.</small>
            </div>
        </div>
    </div>
</template>

<script>
    import ImgEmpty from "../../misc/img-empty"

    export default {
        name: "import-timestamps",

        components: {ImgEmpty},

        props: {
            import_contact: {
                required: true
            }
        },

        data() {
            return {
                loading: false,
                import_timestamps: []
            }
        },

        computed: {
            hasTimestamps() {
                return !!(this.import_timestamps && this.import_timestamps.length)
            }
        },

        methods: {
            getImportTimestamps() {
                this.loading = true

                axios.get(`/api/v1/imports/${this.import_contact.id}/import-timestamps`)
                    .then(res => {
                        this.loading = false

                        if (res.data) {
                            this.loaded_all = !res.data.length
                            // spread the value
                            this.import_timestamps.push(...res.data)
                        }
                    })
                    .catch(err => {
                        console.log(err)

                        this.loading = false
                    })
            }
        },

        watch: {
            'import_contact.id': {
                handler() {
                    if (this.import_contact && this.import_contact.id) {
                        // if the id is new, clear the timestamp record
                        this.import_timestamps = []

                        this.getImportTimestamps()
                    }
                },
                immediate: true
            }
        }
    }
</script>
