export default Object.freeze([
    {
        name: 'USA & Canada',
        value: 'USA_AND_CANADA',
    },
    {
        name: 'Latin America',
        value: 'LATIN_AMERICA',
    },
    {
        name: 'Europe',
        value: 'EUROPE',
    },
    {
        name: 'Asia',
        value: 'ASIA',
    },
    {
        name: 'Africa',
        value: 'AFRICA',
    },
])