<template>
    <div data-testid="target-users-tree-wrapper">
        <template v-if="!isForm">
            <p class="_600 mb-0">
                Target Users
                <span v-if="communication.target_users && communication.target_users.length">({{ attemptLabel }})</span>:
            </p>
            <template v-if="isTargetUsersWithAttempts">
                <el-tree node-key="id"
                         :data="generateTargetUsersTree"
                         :props="default_props"
                         :default-expanded-keys="[communication.attempt]"
                         data-testid="target-users-tree-no-form-with-attempts"
                         :indent="5">
                </el-tree>
            </template>
            <template v-else-if="isTargetUsersWithNoAttempts">
                <el-tree :data="generateTargetUsersTree"
                         :props="default_props"
                         data-testid="target-users-tree-no-form-without-attempts"
                         :indent="5">
                </el-tree>
            </template>
            <span v-else>-</span>
            <br>
        </template>
        <template v-else>
            <label class="form-control-label col-xl-5 col-12">
                Target Users
                <span v-if="communication.target_users && communication.target_users.length">({{attemptLabel}})</span>:
            </label>
            <div class="d-flex align-items-center col-xl-7 col-12">
                <template v-if="isTargetUsersWithAttempts">
                    <el-tree node-key="id"
                             :data="generateTargetUsersTree"
                             :props="default_props"
                             data-testid="target-users-tree-form-with-attempts"
                             :default-expanded-keys="[communication.attempt]"
                             :indent="5">
                    </el-tree>
                </template>
                <template v-else-if="isTargetUsersWithNoAttempts">
                    <el-tree :data="generateTargetUsersTree"
                             :props="default_props"
                             data-testid="target-users-tree-form-without-attempts"
                             :indent="5">
                    </el-tree>
                </template>
                <span v-else>-</span>
            </div>
        </template>
    </div>
</template>

<script>
import {user_info_mixin} from '../mixins'

export default {
    name: "target-users-tree",

    mixins: [user_info_mixin],

    props: {
        communication: {
            required: true,
            type: Object,
            default: () => {
                return {}
            }
        },
        isForm: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            default_props: {
                children: 'children',
                label: 'label'
            },
        }
    },

    computed: {
        attemptLabel() {
            return this.communication.attempt ? `attempt ${this.communication.attempt}` : 'no attempts'
        },

        generateTargetUsersTree() {
            if (!this.communication.target_users) {
                return []
            }

            let data = []
            let layer_number = 0
            for (let user_ids of this.communication.target_users) {
                layer_number++
                let entry = {
                    id: layer_number,
                    label: 'Layer ' + layer_number,
                    children: []
                }
                entry.children = this.generateAttemptingUsersTree(user_ids)
                data.push(entry)
            }

            // return the tree
            return data
        },

        isTargetUsersWithAttempts() {
            // attempt(s) should also be less than or equal to
            // the number of target user layers
            return this.communication.target_users &&
                this.communication.target_users.length &&
                this.communication.attempt &&
                this.communication.attempt <= this.communication.target_users.length
        },

        isTargetUsersWithNoAttempts() {
            // if attempt is null or more than the target user layers,
            // treat it as no attempt(s)
            return this.communication.target_users &&
                this.communication.target_users.length &&
                (this.communication.attempt == null ||
                    this.communication.attempt > this.communication.target_users.length)
        }
    },

    methods: {
        generateAttemptingUsersTree(attempting_users) {
            if (!attempting_users) {
                return
            }

            let data = []
            for (let user_id of attempting_users) {
                let user = this.getUser(user_id)
                if (user) {
                    data.push({
                        label: this.getUserName(user)
                    })
                }
            }

            // return the tree
            return data
        }
    }
}
</script>
