var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { "data-testid": "short-code-wizard-wrapper" } }, [
    _c(
      "div",
      { staticClass: "container-fluid" },
      [
        _c(
          "el-steps",
          {
            staticClass: "mb-3",
            attrs: {
              active: _vm.current_step,
              "align-center": "",
              "finish-status": "success",
            },
          },
          [
            _c("el-step", {
              attrs: { title: "Information", "data-testid": "info-step" },
            }),
            _vm._v(" "),
            _c("el-step", {
              attrs: {
                title: "Select your Package",
                "data-testid": "info-step",
              },
            }),
            _vm._v(" "),
            _c("el-step", {
              attrs: {
                title: "Confirmation",
                "data-testid": "confirmation-step",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.current_step === 0
          ? _c("div", { staticClass: "step-1 row" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 text-right mt-4" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "success" }, on: { click: _vm.nextStep } },
                    [_vm._v("\n                    Next\n                ")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.current_step === 1
          ? _c("div", { staticClass: "step-2 row" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "el-select",
                          {
                            key: _vm.current_step,
                            staticClass: "w-full",
                            attrs: {
                              id: "package-select",
                              placeholder: "Select Package",
                            },
                            model: {
                              value: _vm.selected_package,
                              callback: function ($$v) {
                                _vm.selected_package = $$v
                              },
                              expression: "selected_package",
                            },
                          },
                          _vm._l(_vm.packages, function (option) {
                            return _c(
                              "el-option",
                              {
                                key: option.value,
                                attrs: {
                                  value: option.value,
                                  label: `${option.label} - ${option.label_price}`,
                                },
                              },
                              [
                                _c("span", { staticClass: "wz-option-label" }, [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(option.label) +
                                      "\n                                            "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "wz-option-number" },
                                    [_vm._v(_vm._s(option.label_price))]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 d-flex justify-content-between mt-4" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info" },
                      on: { click: _vm.previousStep },
                    },
                    [_vm._v("\n                    Back\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success", disabled: !_vm.isValidStep },
                      on: { click: _vm.nextStep },
                    },
                    [_vm._v("\n                    Next\n                ")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.current_step === 2
          ? _c("div", { staticClass: "step-3 row" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 d-flex justify-content-between mt-4" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info" },
                      on: { click: _vm.previousStep },
                    },
                    [_vm._v("\n                    Back\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "pull-right",
                      attrs: { type: "success", disabled: _vm.loading },
                      on: { click: _vm.confirmAndSubmit },
                    },
                    [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "material-icons loader pull-right",
                        },
                        [_vm._v("")]
                      ),
                      _vm._v(
                        "\n                    Accept and Submit\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.current_step === 3
          ? _c("div", { staticClass: "step-4 text-center row" }, [_vm._m(4)])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "p",
        {
          staticClass:
            "form-head font-weight-bold mb-3 description wz-text-wrap",
        },
        [
          _c("b", [
            _vm._v(
              "Unlock the Power of High-Volume SMS with Aloware Short Codes!"
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-divider" }),
      _vm._v(" "),
      _c("p", { staticClass: "wz-text-wrap" }, [
        _vm._v(
          "\n                    Boost your brand and connect with your audience like never before:"
        ),
        _c("br"),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("b", [_vm._v("High-Volume Messaging")]),
            _vm._v(
              ": Reach thousands in seconds with carrier-approved,\n                            high-volume SMS delivery.\n                        "
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("b", [_vm._v("Unstoppable Reach")]),
            _vm._v(
              ": Approved short codes experience minimal filtering, ensuring\n                            your messages reach a broader audience.\n                        "
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("b", [_vm._v("Branded for Impact")]),
            _vm._v(
              ": Make your message stand out with custom vanity short codes\n                            that amplify brand recognition.\n                        "
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("b", [_vm._v("Hassle-free Procurement")]),
            _vm._v(
              ": Provides the fastest time to approval in the industry —\n                            as little as 8-12 weeks.\n                        "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      {
        staticClass: "form-head font-weight-bold mb-3 description wz-text-wrap",
      },
      [_c("b", [_vm._v("Short Code Types and Monthly Rental Fee")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "wz-text-wrap" }, [
      _c("ul", [
        _c("li", [
          _c("b", [_vm._v("Standard Short Code:")]),
          _vm._v(" This is assigned by carriers\n                        "),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("b", [_vm._v("Vanity Short Code:")]),
          _vm._v(
            " This is your preferred custom short code\n                        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "form-head font-weight-bold mb-3 wz-text-wrap" }, [
        _c("b", [_vm._v("Short Code - Terms of Service")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-divider" }),
      _vm._v(" "),
      _c("p", { staticClass: "wz-text-wrap mt-3" }, [
        _c("b", [_vm._v("Setup Fee:")]),
        _c("br"),
        _vm._v(" "),
        _c("b", [_vm._v("A non-refundable application fee of $1,500")]),
        _vm._v(
          " is required. A dedicated team member will be assigned\n                    to assist you throughout the application process.\n                "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "wz-text-wrap mt-3" }, [
        _c("b", [_vm._v("Bulk Messaging Rates:")]),
        _c("br"),
        _vm._v(
          "\n                    Pricing is customized based on your messaging volume, and our team will provide a quote tailored\n                    to your specific needs.\n                "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "wz-text-wrap mt-3" }, [
        _c("b", [_vm._v("Minimum Commitment:")]),
        _c("br"),
        _vm._v(
          "\n                    In accordance with carrier regulations, a minimum rental duration of 3 months is required for\n                    all short codes.\n                "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "wz-text-wrap" }, [
        _vm._v("\n                    By clicking "),
        _c("b", [_vm._v('"Accept and Submit"')]),
        _vm._v(
          ", you agree to these terms and allow Aloware to contact\n                    you with regarding your short code application.\n                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "wz-text-wrap" }, [
        _c("b", [
          _vm._v(
            "Your application for Short Code has been successfully submitted!"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "wz-text-wrap" }, [
        _vm._v(
          "\n                    Our team will reach out to you within 3 business days. Thank you!\n                "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }