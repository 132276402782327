var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row pb-5" }, [
    _c(
      "div",
      { staticClass: "col-9 mx-auto" },
      [
        _c("workflow-triggers", {
          attrs: {
            triggers: _vm.value.triggers,
            mailgun_intake_route: _vm.value.mailgun_intake_route,
            automation_trigger: true,
          },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }