<template>
    <div class="row nav-padding">
        <div class="col-12">
            <div class="filter-header d-flex align-items-center justify-content-between"
                 :class="{'text-muted': short}">
                <span class="font-weight-bold">{{ title }}</span>
                <template-dialog v-if="(template_scope == 'user' && canCreatePersonalTemplate) || (template_scope == 'company' && isAdmin)"
                                 class="pull-right"
                                 ref="templateDialog"
                                 :template_scope="template_scope"
                                 :template_id="template_id"
                                 :short="short"
                                 :user_id="user_id"
                                 @clearEdit="clearEdit">
                </template-dialog>
            </div>
            <div class="dropdown-divider no-overflow"
                 v-if="short">
            </div>
            <ul class="list inset"
                v-if="short">
                <li v-for="template in filteredTemplates"
                    :key="template.id"
                    class="filters pb-2 pt-2">
                    <div>
                        <span>{{ template.name }}</span>
                    </div>
                    <div class="pull-right mt--1">
                        <el-tooltip class="item"
                                    effect="dark"
                                    :content="mode == 'return' ? 'Use' : 'Copy' "
                                    placement="top">
                            <a href="#"
                               data-testid="template-content-copy-link"
                               @click.prevent="copyTemplate(template.body)"
                               class="btn btn-xs btn-icon">
                                <i class="fa fa-plus-square"
                                   v-if="mode == 'return'">
                                </i>
                                <i class="material-icons" v-else>content_copy</i>
                            </a>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="View" placement="top">
                            <el-popover placement="bottom-end"
                                        width="350"
                                        trigger="click"
                                        class="p-0">
                                <small>{{ template.body }}</small>
                                <a href="#"
                                   slot="reference"
                                   data-testid="template-visibility-link"
                                   @click.prevent
                                   class="btn btn-xs btn-icon">
                                    <i class="material-icons">visibility</i>
                                </a>
                            </el-popover>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Edit" placement="top"
                                    v-if="canEdit(template)">
                            <a href="#"
                               class="btn btn-xs btn-icon"
                               data-testid="template-edit-link"
                               @click.prevent="editTemplate(template.id)">
                                <i class="fa fa-edit"></i>
                            </a>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Delete" placement="top"
                                    v-if="canDelete(template)">
                            <a href="#"
                               data-testid="template-delete-link"
                               @click.prevent="deleteTemplate(template)"
                               class="btn btn-xs btn-icon">
                                <i class="material-icons">delete</i>
                            </a>
                        </el-tooltip>
                    </div>
                </li>
                <li v-if="!filteredTemplates.length"
                    class="filters pb-2 pt-2 text-center">
                    <strong>No Template</strong>
                </li>
            </ul>
            <div v-if="!short">
                <el-table :data="filteredTemplates"
                          :default-sort="{prop: 'id', order: 'ascending'}"
                          class="w-full"
                          fit
                          stripe>
                    <el-table-column
                        prop="id"
                        label="Id"
                        width="60">
                        <template v-slot="scope">
                            <span class="text-greyish">{{ scope.row.id }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name"
                                     label="Name"
                                     width="150">
                    </el-table-column>
                    <el-table-column label="Body"
                                     min-width="300">
                        <template v-slot="scope">
                            <span v-html="$options.filters.fixHtml($options.filters.truncate(scope.row.body, 100))"></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="150"
                        align="right">
                        <template v-slot="scope">
                            <el-dropdown trigger="click">
                                <el-button size="small">
                                    <i class="fa fa-ellipsis-v"></i>
                                </el-button>
                                <template v-slot:dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item v-if="canEdit(scope.row)"
                                                          data-testid="can-edit-dropdown"
                                                          @click.native="editTemplate(scope.row.id)">
                                            <i class="fa fa-edit"></i>
                                            Edit
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="canDelete(scope.row)"
                                                          data-testid="can-delete-dropdown"
                                                          @click.native="deleteTemplate(scope.row)">
                                            <i v-if="loading_archive_template"
                                               class="fa fa-spin fa-spinner"></i>
                                            <i v-else
                                               class="fa fa-trash"></i>
                                            Delete
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin} from "../mixins"
import TemplateDialog from "./template-dialog"
import {mapGetters, mapActions} from 'vuex'
import * as Roles from "../constants/roles";

export default {
    mixins: [acl_mixin],

    components: {TemplateDialog},

    props: {
        short: {
            type: Boolean,
            default: true
        },

        text_button: {
            type: Boolean,
            default: false
        },

        template_scope: {
            default: 'user',
            validator: function (value) {
                return ['user', 'company'].indexOf(value) !== -1;
            }
        },

        user_id: {
            type: Number,
            required: false,
            default: undefined
        },

        mode: {
            required: false,
            type: String,
            default: 'default'
        }
    },

    data() {
        return {
            auth: auth,
            loading_fetch: false,
            loading_delete: false,
            loading_restore_template: false,
            loading_archive_template: false,
            dialogVisible: false,
            template_id: null
        }
    },

    computed: {
        ...mapGetters({
            smsTemplates: 'getSmsTemplates',
        }),

        title() {
            if (this.template_scope == 'user') {
                return this.short ? 'Personal Templates' : ''
            }

            return 'Account Templates'
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        filteredTemplates() {
            const user_id = this.user_id ? this.user_id : _.get(this.auth, 'user.profile.id', null)
            if (this.template_scope == 'user') {
                return this.smsTemplates.filter((template) => template.is_on_user && template.user_id === user_id)
            }

            return this.smsTemplates.filter((template) => template.is_on_company)
        },

        isCompanyAdmin() {
            return this.hasRole(Roles.COMPANY_ADMIN) || this.hasRole(Roles.BILLING_ADMIN)
        },

        canCreatePersonalTemplate() {
            if (!this.user_id) {
                return true
            }

            const user_id = _.get(this.auth, 'user.profile.id', null)
            return parseInt(user_id) === this.user_id
        },
    },

    methods: {
        canEdit(template) {
            return this.canDoActions(template, 'update')
        },

        canDelete(template) {
            return this.canDoActions(template, 'archive')
        },

        canDoActions(template, action) {
            if (template.deleted_at) {
                return false
            }

            switch (this.template_scope) {
                case 'user':
                    return this.hasPermissionTo(action + ' sms template')
                case 'company':
                    return this.isCompanyAdmin &&
                        this.hasPermissionTo(action + ' sms template')
            }

            return false
        },

        editTemplate(template_id) {
            this.template_id = template_id
            this.$refs.templateDialog.edit()
        },

        deleteTemplate(template) {
            this.$confirm('This operation will remove the template. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_delete = true
                axios.delete('/api/v1/sms-template/' + template.id)
                    .then(res => {
                        this.loading_delete = false
                        this.deleteSmsTemplate(template.id)
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_delete = false
                    })
            }).catch(() => {
                // delete canceled
            })
        },

        copyTemplate(body) {
            if (this.mode === 'default') {
                this.$copyText(body)
                    .then((res) => {
                        this.$message({
                            message: 'Copied to clipboard!',
                            type: 'success'
                        })
                    }, (err) => {
                        this.$message({
                            message: 'Cannot copy to clipboard!',
                            type: 'error'
                        })
                        console.log(err)
                    })
            }

            if (this.mode === 'return') {
                this.$emit('template-selected', body)
            }
        },

        clearEdit() {
            this.template_id = null
        },

        ...mapActions(['deleteSmsTemplate'])
    },
}
</script>
