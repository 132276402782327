// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Lato-Regular.woff";
import ___CSS_LOADER_URL_IMPORT_1___ from "./Lato-Bold.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'Lato';\n    font-style: normal;\n    font-weight: 400;\n    src: local('Lato Regular'), local('Lato-Regular'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\n}\n@font-face {\n    font-family: 'Lato';\n    font-style: normal;\n    font-weight: 700;\n    src: local('Lato Bold'), local('Lato-Bold'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n}\n", "",{"version":3,"sources":["webpack://./resources/assets/fonts/Lato/font.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,yGAAwF;AAC5F;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,mGAA+E;AACnF","sourcesContent":["@font-face {\n    font-family: 'Lato';\n    font-style: normal;\n    font-weight: 400;\n    src: local('Lato Regular'), local('Lato-Regular'), url(Lato-Regular.woff) format('woff');\n}\n@font-face {\n    font-family: 'Lato';\n    font-style: normal;\n    font-weight: 700;\n    src: local('Lato Bold'), local('Lato-Bold'), url(Lato-Bold.woff) format('woff');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
