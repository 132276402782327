var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.in_input_group
    ? _c(
        "span",
        {
          class: [_vm.is_append ? "input-group-append" : "input-group-prepend"],
        },
        [
          _c(
            "el-popover",
            {
              staticClass: "p-0",
              attrs: {
                placement: "top-start",
                width: "400",
                trigger: "click",
                "popper-class": "variable-popover",
              },
              on: {
                show: function ($event) {
                  return _vm.popoverShow(1)
                },
              },
            },
            [
              _c("variables-dropdown", { ref: "variables_dropdown1" }),
              _vm._v(" "),
              !_vm.short
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-block bluish w-full h-full",
                      class: [
                        _vm.is_append
                          ? "no-left-border-radius"
                          : "no-right-border-radius",
                      ],
                      attrs: {
                        slot: "reference",
                        disabled: _vm.disabled,
                        "data-testid": "add-variable-in-dialog-button-group",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                        },
                      },
                      slot: "reference",
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add"),
                      ]),
                      _vm._v("\n            Add Variable\n        "),
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-block bluish w-full h-full",
                      class: [
                        _vm.is_append
                          ? "no-left-border-radius"
                          : "no-right-border-radius",
                      ],
                      attrs: {
                        slot: "reference",
                        disabled: _vm.disabled,
                        "data-testid": "add-in-dialog-button-group",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                        },
                      },
                      slot: "reference",
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add"),
                      ]),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "span",
        [
          _c(
            "el-popover",
            {
              staticClass: "p-0",
              attrs: {
                placement: "top-start",
                width: "400",
                trigger: "click",
                "popper-class": "variable-popover",
              },
              on: {
                show: function ($event) {
                  return _vm.popoverShow(2)
                },
              },
            },
            [
              _c("variables-dropdown", { ref: "variables_dropdown2" }),
              _vm._v(" "),
              !_vm.short
                ? _c(
                    "button",
                    {
                      class: "btn bluish mb-2 " + _vm.btn_class,
                      attrs: {
                        slot: "reference",
                        disabled: _vm.disabled,
                        "data-testid": "add-variable-in-dialog-button",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                        },
                      },
                      slot: "reference",
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add"),
                      ]),
                      _vm._v("\n            Add Variable\n        "),
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn bluish",
                      attrs: {
                        slot: "reference",
                        disabled: _vm.disabled,
                        "data-testid": "add-in-dialog-button",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                        },
                      },
                      slot: "reference",
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add"),
                      ]),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }