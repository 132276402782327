<template>
    <div class="app-body"
         id="view">
        <template v-if="shouldShowBroadcast && !is_loading">
            <aup-consent @loaded="loading_aup = false"></aup-consent>

            <el-dialog title="What are broadcasts?"
                       top="10vh"
                       append-to-body
                       :visible.sync="show_help">
                <p class="text-md _600 break-word"></p>
            </el-dialog>

            <div class="fixed-header padding pt-0 pb-0" :style="{top: (top_offset+50) + 'px'}"
                 v-if="!loading_aup">
                <div class="row mb-0 mt-0">
                    <div class="d-flex flex-fill flex-wrap p-2">
                        <div class="d-flex flex-wrap mr-auto">
                            <div class="mr-sm-2 flex-fill search-toolbar">
                                <el-input ref="broadcastSearch"
                                          placeholder="Search..."
                                          prefix-icon="el-icon-search"
                                          clearable
                                          v-model="search_text"
                                          @input="search">
                                </el-input>
                            </div>

                            <div class="no-select mt-2 mt-xl-0 mr-2">
                                <el-radio-group class="no-select"
                                                v-model="broadcast_status"
                                                @change="changedFilter">
                                    <el-radio-button label="new">
                                        New
                                    </el-radio-button>
                                    <el-radio-button label="enrolling">
                                        Enrolling
                                    </el-radio-button>
                                    <el-radio-button label="done">
                                        Sent
                                    </el-radio-button>
                                    <el-radio-button label="paused"
                                                     :class="[broadcast_status == 'paused' ? 'pause-deep-orange': '']">
                                        Paused
                                    </el-radio-button>
                                    <el-radio-button label="all">
                                        All
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>

                        <div class="d-flex flex-wrap">
                            <div class="mt-2 mt-xl-0 mr-2">
                                <button class="btn btn-md rounded greenish"
                                        :disabled="!canCreateBroadcastMessage"
                                        @click="$router.push({ name: 'Bulk Message'})">
                                    <i class="fa fa-plus"></i>
                                    New Bulk Message
                                </button>
                            </div>

                            <div class="mt-2 mt-xl-0 mr-2 mr-xl-0">
                                <button class="btn btn-md rounded bluish"
                                        :disabled="!canBroadcastRvm"
                                        @click="$router.push({ name: 'Bulk RVM'})">
                                    <i class="fa fa-plus"></i>
                                    New Bulk RVM
                                </button>
                            </div>

                            <video-modal-activator class="pl-2"
                                                   v-if="isTrial && !isSimpSocial"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="padding mt-5 pt-3"
                 v-if="hasPermissionTo('list report') && !search_text && !loading_aup">
                <div class="row box">
                    <div class="col-12">
                        <div class="no-effect">
                            <div class="box-body">
                                <!-- WAT-876: deprecation banner -->
                                <activity-graph base="broadcast"
                                                :notification="deprecationNotification"
                                                :default_date_range="7"
                                                :is_first_load="reports_first_load"
                                                :filter_ids="filteredBroadcasts.map(broadcast => broadcast.id)"
                                                @finished_loading="focusSearchBar">
                                </activity-graph>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="padding"
                 v-if="!loading_aup">
                <div class="row box">
                    <div class="col-12">
                        <div class="no-effect">
                            <div class="box-header pb-0">
                                <div class="row mt-2">
                                    <div class="col-12">
                                    <span class="pull-left font-120"
                                          v-if="broadcasts.length > 0">
                                        <strong>{{ total }}</strong>
                                        {{ total > 1 ? 'broadcasts' : 'broadcast' }}
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body pt-0">
                                <div v-on:mouseleave="tableLeave">
                                    <el-table class="w-full"
                                              row-key="id"
                                              stripe
                                              fit
                                              :data="paginatedData"
                                              :default-sort="{prop: 'id', order: 'descending'}"
                                              v-loading="loading_broadcasts"
                                              @cell-mouse-enter="cellEnter">
                                        <el-table-column
                                            prop="status"
                                            width="35">
                                            <template v-slot="scope">
                                            <span class="status">
                                                <i class="b-white"
                                                   :class="[scope.row.status != BroadcastStatuses.STATUS_PAUSED ? 'greenish' : 'danger']"></i>
                                            </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="id"
                                            label="ID"
                                            width="100"
                                            sortable>
                                            <template v-slot="scope">
                                                <span class="text-greyish">{{ scope.row.id }}</span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="name"
                                            label="Name"
                                            sortable
                                            :min-width="180">
                                            <template v-slot="scope">
                                                <span class="text-greyish">{{ scope.row.name || "-" }}</span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="status_name"
                                            label="Status"
                                            sortable
                                            :min-width="180">
                                            <template v-slot="scope">
                                                <template
                                                    v-if="![BroadcastStatuses.STATUS_PAUSED, BroadcastStatuses.STATUS_STOPPED].includes(scope.row.status)">
                                                    <span class="text-greyish">{{ scope.row.status_name }}</span>
                                                </template>
                                                <template v-else>
                                                    <span class="text-danger">{{ scope.row.status_name }}</span>
                                                </template>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="run_at"
                                            label="Scheduled Time"
                                            width="150">
                                            <template v-slot="scope">
                                                <relative-datetime v-if="scope.row.run_at"
                                                                   :datetime="scope.row.run_at">
                                                </relative-datetime>
                                            </template>
                                        </el-table-column>

                                        <el-table-column prop="pending_tasks"
                                                         label="Pending Tasks"
                                                         width="190"
                                                         align="center"
                                                         sortable>
                                            <template v-slot="scope">
                                              <span class="_600 text-md">
                                                  {{ scope.row.total_enrolled > 0 ? scope.row.pending_tasks : '-' }}
                                              </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            prop="total_failed"
                                            width="150"
                                            align="center"
                                            sortable>
                                            <template #header>
                                                Failed
                                                <el-popover
                                                    placement="right"
                                                    width="300"
                                                    trigger="hover"
                                                    popper-class="p-2">
                                                    <div class="lh-1">Number of contacts that failed to send because phone number is a landline.</div>
                                                    <img
                                                        slot="reference"
                                                        src="/assets/images/app-icons/info-icon-outline.png"
                                                        alt="info-icon"
                                                    />
                                                </el-popover>
                                            </template>
                                            <template v-slot="scope">
                                          <span class="_600 text-md">
                                              {{ scope.row.total_failed > 0 ? scope.row.total_failed : '-' }}
                                          </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column prop="total_enrolled"
                                                         label="Total Tasks"
                                                         width="180"
                                                         align="center"
                                                         sortable>
                                            <template v-slot="scope">
                                                <span class="_600 text-md">{{ scope.row.total_enrolled > 0 ? scope.row.total_enrolled : '-' }}</span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column prop="engagement_rate"
                                                         label="Engagement"
                                                         :min-width="110">
                                            <template v-slot="scope">
                                                <div class="text-center">
                                                    <span v-if="scope.row.engagement_rate > 0">{{ scope.row.engagement_rate }}%</span>
                                                    <span v-else>-</span>
                                                </div>
                                            </template>
                                        </el-table-column>

                                        <el-table-column prop="total_unsubscribed"
                                                         label="Unsubscribed"
                                                         :min-width="110">
                                            <template v-slot="scope">
                                                <div class="text-center">
                                                    <span v-if="scope.row.status == BroadcastStatuses.STATUS_DONE">{{ scope.row.total_unsubscribed }}</span>
                                                    <span v-else>-</span>
                                                </div>
                                            </template>
                                        </el-table-column>

                                        <el-table-column prop="tag"
                                                         label="Tag"
                                                         sortable
                                                         :min-width="180">
                                            <template v-slot="scope">
                                                <div v-if="scope.row.tag">
                                                    <el-tooltip class="item"
                                                                effect="dark"
                                                                placement="top"
                                                                :content="scope.row.tag.name">
                                                        <router-link :to="{ name: 'Contacts', query: {'tag_id': scope.row.tag.id}}">
                                                            <span :style="'color:' + scope.row.tag.color "><i class="fa fa-tag"></i> {{ scope.row.tag.name | strLimit(40) }}</span>
                                                        </router-link>
                                                    </el-tooltip>
                                                </div>
                                            </template>
                                        </el-table-column>

                                        <el-table-column prop="campaign_id"
                                                         label="Line Used"
                                                         sortable
                                                         :min-width="180">
                                            <template v-slot="scope">
                                                <router-link
                                                    :to="{ name: 'Line Settings', params: {campaign_id: scope.row.campaign_id }}"
                                                    v-if="getCampaign(scope.row.campaign_id)">
                                                    <el-tooltip class="item"
                                                                effect="dark"
                                                                content="Go to line settings"
                                                                placement="left">
                                                    <span class="text-dark-greenish">
                                                        {{ getCampaign(scope.row.campaign_id).name || '-' }}
                                                    </span>
                                                    </el-tooltip>
                                                </router-link>
                                                <span class="text-greyish"
                                                      v-else>
                                                -
                                            </span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column prop="throttle_limit"
                                                         label="Throttling"
                                                         :min-width="150">
                                            <template v-slot="scope">
                                                <div class="text-left">
                                                    <span v-if="scope.row.throttle_limit > 0">{{ getThrottling(scope.row.throttle_limit) }}</span>
                                                    <span v-else>-</span>
                                                </div>
                                            </template>
                                        </el-table-column>

                                        <el-table-column prop="created_at"
                                                         label="Date Created"
                                                         :min-width="150">
                                            <template v-slot="scope">
                                                <div class="text-left">
                                                    <span>{{ scope.row.created_at | fixDateTime }}</span>
                                                </div>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            label="Operations"
                                            align="center"
                                            width="120"
                                            fixed="right">
                                            <template v-slot="scope">
                                                <div v-bind:style="getStyle(scope.row.id)">
                                                    <el-popover
                                                        placement="right"
                                                        width="400"
                                                        trigger="click"
                                                        v-if="scope.row.description">
                                                        <p class="_600">Message:</p>
                                                        <p class="text-sm"
                                                           style="color: #606266;max-width: 90%;overflow: auto"
                                                           v-html="$options.filters.nl2br(scope.row.description)">
                                                        </p>
                                                        <button class="btn btn-sm btn-block btn-dark"
                                                                slot="reference">
                                                            <i class="fa fa-info-circle pull-left"></i>
                                                            Content
                                                        </button>
                                                    </el-popover>

                                                    <div class="row mt-2"
                                                         v-if="hasPermissionTo(['list broadcast', 'list communication', 'view communication'])">
                                                        <div class="col-12">
                                                            <router-link
                                                                :to="{ name: 'Broadcast Activity', params: {broadcast_id: scope.row.id }}">
                                                                <button class="btn btn-sm btn-block purplish">
                                                                    <i class="fa fa-signal pull-left"></i>
                                                                    Activity
                                                                </button>
                                                            </router-link>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-2"
                                                         v-if="hasPermissionTo('update broadcast') && [BroadcastStatuses.STATUS_ENROLLING, BroadcastStatuses.STATUS_PAUSED, BroadcastStatuses.STATUS_NEW].includes(scope.row.status)">
                                                        <div class="col-12">
                                                            <button class="btn btn-sm btn-block"
                                                                    :class="scope.row.status === BroadcastStatuses.STATUS_PAUSED  ? 'light-blue-500': 'deep-orange-500'"
                                                                    :disabled="loading_btn_id == scope.row.id"
                                                                    @click="toggleActive(scope.row.id)">
                                                                <i class="fa fa-spin fa-spinner pull-left"
                                                                   v-if="loading_btn_id == scope.row.id"></i>
                                                                <template v-else>
                                                                    <i class="fa fa-pause pull-left"
                                                                       v-if="[BroadcastStatuses.STATUS_ENROLLING, BroadcastStatuses.STATUS_NEW].includes(scope.row.status)"></i>
                                                                    <i class="fa fa-play pull-left"
                                                                       v-else></i>
                                                                </template>
                                                                {{ scope.row.status === BroadcastStatuses.STATUS_PAUSED ? 'Activate' : 'Pause' }}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-2">
                                                        <div class="col-12">
                                                            <rename-broadcast :broadcast="scope.row"
                                                                              @success="updateBroadcastData">
                                                            </rename-broadcast>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-2"
                                                         v-if="hasRole('Company Admin') && hasPermissionTo('delete broadcast')">
                                                        <div class="col-12">
                                                            <button class="btn btn-sm btn-block btn-danger"
                                                                    @click="deleteBroadcastRemote(scope.row.id)">
                                                                <i class="fa fa-trash"></i> Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>

                                        <template slot="empty"
                                                  class="text-center text-muted">
                                            <span v-if="!loading">No Data</span>
                                            <span v-if="loading"></span>
                                        </template>
                                    </el-table>
                                </div>
                                <div class="mt-3 no-select"
                                     v-if="pageCount >= 1">
                                    <el-pagination class="pull-right"
                                                   background
                                                   layout="prev, pager, next"
                                                   :page-size="per_page"
                                                   :current-page="pagination.current_page"
                                                   :total="total"
                                                   @current-change="changePage"
                                                   @size-change="handleSizeChange">
                                    </el-pagination>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <el-backtop target="html"
                        ref="backtop"
                        :bottom="20"
                        :right="20">
            </el-backtop>
        </template>

        <upgrade-now-page image-link="/assets/images/Broadcast.svg"
                          text="Scale your outreach with the click of a button. Send captivating SMS campaigns to many contacts at once via Broadcast."
                          extra-text="Upgrade today to unlock this feature"
                          title-text="Broadcast"
                          kb-link="https://support.aloware.com/en/articles/9031607-a-guide-to-aloware-admin-broadcast"
                          class="mt-5"
                          v-if="!shouldShowBroadcast && shouldShowUpgradeNow">
        </upgrade-now-page>

        <video-modal class="pl-2"
                     title="Master Bulk SMS with Aloware Broadcast 💬"
                     cookie-name="broadcasts"
                     notes="Dive deep into Aloware's Bulk SMS feature, perfect for boosting your marketing campaigns.#SuperchargeYourOutreach. </br></br> 📱 Explore the power of Aloware's Bulk SMS Broadcast: a game-changer for your marketing strategies."
                     video-url="https://www.youtube.com/embed/nSAAyHLAnk4?si=J6vLHzAAXlrDd6l6"
                     learn-more-link="https://support.aloware.com/en/articles/9031607-a-guide-to-aloware-admin-broadcast"
                     :has-activator-button="true"
                     v-if="isTrial && !isSimpSocial"/>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapActions, mapState, mapGetters} from 'vuex'
import {
    acl_mixin,
    paginator_mixin,
    scroll_mixin,
    styling_mixin,
    validator_mixin,
    kyc_mixin,
    supervisor_mixin
} from '../../mixins'
import AupConsent from '../../components/aup-consent'
import RenameBroadcast from "../../components/rename-broadcast"
import * as BroadcastTypes from '../../constants/broadcast-types'
import * as BroadcastStatuses from '../../constants/broadcast-status'
import _ from "lodash"
import UpgradeNowPage from "../../components/upgrade-now-page.vue"
import VideoModal from '../../components/video-modal.vue'
import VideoModalActivator from '../../components/video-modal-activator.vue'
import CustomMessageDisplay from '../../components/kyc/custom-message-display'

import { BROADCAST_DEPRECATION_MESSAGE, buildMessage } from '../../constants/deprecation.messages'
import { RESELLER_SIMPSOCIAL_ID } from '../../constants/resellers-ids'

export default {
    mixins: [
        styling_mixin,
        validator_mixin,
        acl_mixin,
        paginator_mixin,
        scroll_mixin,
        kyc_mixin,
        supervisor_mixin
    ],

    components: {
        UpgradeNowPage,
        RenameBroadcast,
        AupConsent,
        VideoModal,
        VideoModalActivator,
        CustomMessageDisplay
    },

    data() {
        return {
            auth: auth,
            loading: false,
            search_fields: ['id', 'name'],
            search_text: '',
            visible_row_id: null,
            broadcast_status: 'all',
            type_filter: 'bulk',
            per_page: 10,
            total: 0,
            broadcasts: [],
            loading_btn_id: null,
            dataKey: 'filteredBroadcasts',
            dataSort: 'id',
            dataSortType: 'DESC',
            show_help: false,
            loading_broadcasts: false,
            reports_first_load: true,
            loading_aup: true,
            allowed_statuses: [
                'new',
                'enrolling',
                'done',
                'stopped',
                'active',
                'paused'
            ],
            is_loading: true,
            BroadcastStatuses,
            BroadcastTypes
        }
    },

    computed: {
        ...mapState(['campaigns', 'ring_groups']),
        ...mapState('cache', ['current_company', 'show_graph']),
        ...mapGetters('cache', [
            'isTrial',
            'isSimpSocial'
        ]),

        visibleBroadcasts() {
            if (this.broadcasts) {
                if (this.search_text != '') {
                    this.broadcast_filter = 'all'
                    let filtered = []
                    for (let broadcast of this.broadcasts) {
                        let keys = this.search_fields

                        for (let key of keys) {
                            if (key.indexOf('.') !== -1) {
                                let mixed = key.split(".")
                                if (broadcast[mixed[0]]) {
                                    if (broadcast[mixed[0]][mixed[1]].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                        filtered.push(broadcast)
                                        break
                                    }
                                }
                            } else if (broadcast[key]) {
                                if (broadcast[key].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                    filtered.push(broadcast)
                                    break
                                }
                            }
                        }
                    }
                    return filtered
                } else if (this.broadcast_filter == 'new') {
                    return this.broadcasts.filter(broadcast => broadcast.status === BroadcastStatuses.STATUS_NEW)
                } else if (this.broadcast_filter == 'enrolling') {
                    return this.broadcasts.filter(broadcast => broadcast.status === BroadcastStatuses.STATUS_ENROLLING)
                } else if (this.broadcast_filter == 'done') {
                    return this.broadcasts.filter(broadcast => broadcast.status === BroadcastStatuses.STATUS_DONE)
                } else if (this.broadcast_filter == 'stopped') {
                    return this.broadcasts.filter(broadcast => broadcast.status === BroadcastStatuses.STATUS_STOPPED)
                } else if (this.broadcast_filter == 'paused') {
                    return this.broadcasts.filter(broadcast => broadcast.status === BroadcastStatuses.STATUS_PAUSED)
                } else {
                    return this.broadcasts
                }
            }
        },

        newCount() {
            if (!this.broadcasts) {
                return 0
            }

            return this.broadcasts.filter(broadcast => broadcast.status == BroadcastStatuses.STATUS_NEW).length
        },

        filteredBroadcasts() {
            if (this.visibleBroadcasts) {
                if (this.type_filter == 'bulk') {
                    return this.visibleBroadcasts.filter(broadcast => [BroadcastTypes.TYPE_SMS, BroadcastTypes.TYPE_RVM, BroadcastTypes.TYPE_MMS].includes(broadcast.type))
                } else {
                    return this.visibleBroadcasts
                }
            }

            return []
        },

        canCreateBroadcastMessage() {
            if (this.auth && _.has(this.auth, 'user.profile.bulk_sms_enabled')) {
                return this.auth.user.profile.bulk_sms_enabled && this.hasPermissionTo('create broadcast message')
            }

            return false
        },

        canBroadcastRvm() {
            if (this.auth && _.has(this.auth, 'user.profile.bulk_rvm_enabled') && !this.auth.user.profile.company.is_trial) {
                return this.auth.user.profile.bulk_rvm_enabled && this.hasPermissionTo('create broadcast rvm')
            }

            return false
        },

        deprecationNotification() {
            const TALK_URL = this.getTalkUrl()

            return {
                class: 'my-2',
                message: buildMessage(BROADCAST_DEPRECATION_MESSAGE, {
                    TALK_URL
                })
            }
        }
    },

    created() {
        VueEvent.listen('broadcasts_updated', (data) => {
            // check data loaded
            if (this.broadcasts) {
                // check if this should be changed
                let found = this.broadcasts.find(broadcast => broadcast.id === data.id)
                if (found) {
                    // update broadcast
                    this.$set(this.broadcasts, this.broadcasts.indexOf(found), data)
                }
            }
        })
        if (this.$route.query && typeof this.$route.query.status != 'undefined' && this.$route.query.status) {
            this.broadcast_filter = this.allowed_statuses.includes(this.$route.query.status) ? this.$route.query.status : this.broadcast_filter
        }
    },

    mounted() {
        this.setTitle()
        if (!this.hasPermissionTo(['create broadcast message', 'create broadcast rvm', 'update broadcast'])) {
            this.goBack()
        }
        this.search_text = ''
        this.loadBoradcasts()
    },

    methods: {
        changePage(val) {
            this.page = val
            this.loadBoradcasts();
        },
        loadBoradcasts() {
            let params = {
                search_text: this.search_text,
                status: this.broadcast_status,
                per_page: this.per_page,
                order_by: this.dataSort,
                order: this.dataSortType,
                page: this.page,
                paginate: true
            }
            this.loading_broadcasts = true
            axios.get('/api/v1/broadcasts', {params})
                .then(res => {
                    this.total = res.data.total
                    this.broadcasts = res.data.data
                    this.current_page = res.data.current_page
                    this.last_page = res.data.last_page
                    this.loading_broadcasts = false
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_broadcasts = false
                })
        },
        search: _.debounce(function () {
            this.loadBoradcasts()
        }, 700),
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Broadcast - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Broadcast - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        deleteBroadcastRemote: function (broadcast_id) {
            let broadcast = this.broadcasts.find(broadcast => broadcast.id === broadcast_id)
            this.$confirm('Are you sure you want to delete this broadcast?', 'Warning', {
                confirmButtonText: 'Yes, Remove',
                cancelButtonText: 'No, Keep',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading = true
                axios.delete('/api/v1/broadcasts/' + broadcast_id)
                    .then(res => {
                        this.deleteBroadcast(broadcast)
                        this.loading = false

                        this.$notify({
                            offset: 95,
                            title: 'Delete Broadcast',
                            message: 'Successfully deleted broadcast.',
                            type: 'success'
                        })
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading = false
                    })
            }).catch(() => {

            })
        },

        cellEnter: _.throttle(function (row, column, cell, event) {
            this.visible_row_id = row.id
        }, 50),

        tableLeave() {
            this.visible_row_id = null
        },

        getStyle(id) {
            let opacity = 0.5
            if (id == this.visible_row_id) {
                opacity = 1
            }

            return {
                opacity: opacity
            }
        },

        toggleActive(broadcast_id) {
            let broadcast = this.broadcasts.find(broadcast => broadcast.id === broadcast_id)
            let message = ''
            switch (broadcast.status) {
                case BroadcastStatuses.STATUS_PAUSED:
                    const type = broadcast.type === BroadcastTypes.TYPE_RVM ? 'RVMs' : 'messages'
                    message = `This will activate the broadcast. Scheduled ${type} will GO out.`
                    break;
                case BroadcastStatuses.STATUS_NEW:
                    message = 'This action will pause any future messages from this broadcast. However, already scheduled communications will not be sent.'
                    break;
                default:
                    message = 'This action will pause any future messages from this broadcast. However, already scheduled communications will still be sent.'
                    break;
            }
            message += ' Continue?'
            this.$confirm(message, 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_btn_id = broadcast_id
                axios.post('/api/v1/broadcasts/' + broadcast_id + '/toggle-active')
                    .then(res => {
                        this.updateBroadcast(res.data.broadcast)
                        this.loading_btn_id = null
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_btn_id = null
                    })
            }).catch(() => {

            })
        },

        getCampaign(id) {
            if (!id) {
                return null
            }
            let found = this.campaigns.find(campaign => campaign.id === id)
            if (found) {
                return found
            }

            return null
        },

        changedFilter() {
            this.search_text = ''
            this.loadBoradcasts()
        },

        toggleHelp() {
            this.show_help = !this.show_help
        },

        getThrottling(message_per_minute) {
            return (message_per_minute * 60) + ' per hour'
        },

        ...mapActions(['deleteBroadcast', 'updateBroadcast']),

        updateBroadcastData(broadcast) {
            let found = this.broadcasts.find(o => o.id === broadcast.id);
            if (found) {
                Vue.set(this.broadcasts, this.broadcasts.indexOf(found), broadcast)
            }
        },

        focusSearchBar() {
            // focus broadcast search input on page visit
            if (this.$refs.broadcastSearch) {
                this.$refs.broadcastSearch.focus()
            }
        }
    },

    watch: {
        'show_graph': function () {
            this.reports_first_load = false
        },
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                let auth = {
                    user: {
                        profile: res.data.user
                    }
                }

                if (res.data.user?.reseller_id === RESELLER_SIMPSOCIAL_ID) {
                    next(vm => {
                        return vm.simpsocialRedirectToTalkPage(to, false, 'Broadcasts', '/broadcasts')
                    })
                } else if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next(vm => {
                        vm.is_loading = false
                        if (!vm.hasPermissionTo('list broadcast', auth.user.profile.user_permissions) && !auth.user.profile.company.sms_enabled) {
                            next({name: 'Dashboard'})
                        }
                    })
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
