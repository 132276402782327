<template>
  <div class="height-600" id="prismatic-config-integration"></div>
</template>

<script >
import prismatic, { PrismaticMessageEvent } from '@prismatic-io/embedded'

export default {
  name: 'prismatic-config-page',
  props: {
    integration: {
      type: String,
      required: true,
    },
    company_id: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    token: null
  }),
  created() {
    this.getPrismaticToken()
  },
  mounted() {
    window.addEventListener('message', this.processPrismaticEvents);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.processPrismaticEvents);
  },
  methods: {
    async getPrismaticToken() {
      try {
        const { data } = await axios.get('api/v1/integration/prismatic/get-token')

        this.token = data?.token
        await this.prismaticAuth()
      } catch (error) {
        this.$emit('finishLoading')
        this.$emit('close')
        this.$root.handleErrors({
          status: 400,
          data: {
            error: `Failed to get token`
          }
        })
      }
    },
    async prismaticAuth() {
      try {
        const prismatic_url = localStorage.getItem('prismatic_api_url')

        await prismatic.init({
          prismaticUrl: prismatic_url,
        });
        await prismatic.authenticate({ token: this.token });
        this.showPrismaticIntegrationConfig()
      } catch (error) {
        this.$emit('finishLoading')
        this.$emit('close')
        this.$root.handleErrors({
          status: 400,
          data: {
            error: `Authentication failed`
          }
        })
      }
    },
    showPrismaticIntegrationConfig() {
      const integrationName = this.integration.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())
      this.$emit('finishLoading')

      prismatic.configureInstance({
        selector: "#prismatic-config-integration",
        integrationName: ` Aloware/ ${integrationName} Integrations`,
        skipRedirectOnRemove: true,
        usePopover: false,
        screenConfiguration: {
          instance: {
            hideTabs: ["Monitors", "Test", "Executions", "Logs"],
          },
          configurationWizard: {
            hideSidebar: true,
            isInModal: true,
            triggerDetailsConfiguration: "hidden",
          },
          marketplace: {
            configuration: "disallow-details",
          },
        }
      });
    },
    async processPrismaticEvents(event) {
      const eventType = event?.data?.event
      const eventData = event?.data?.data

      if (!eventType) return

      if (eventType === PrismaticMessageEvent.INSTANCE_DEPLOYED) {
        await axios.post('api/v1/integration/prismatic/save-instance-information', {
          "instance_id": eventData.instanceId,
          "integration_name": this.integration,
          "integration_version": eventData.integrationVersionNumber
        })
        this.$emit('reload')
        this.$emit('close')
      }

      if (eventType === PrismaticMessageEvent.INSTANCE_DELETED) {
        this.$emit('close')
        this.$emit('back')
      }

      if (eventType === 'PRISMATIC_INITIALIZED') {
        this.$emit('finishLoading')
      }

      // Add default configurations to the instance, after Prismatic configuration finish loading
      if (eventType === PrismaticMessageEvent.INSTANCE_CONFIGURATION_LOADED) {
        const domain = localStorage.getItem('deferred_webhooks_url')

        prismatic.setConfigVars({
          selector: "#prismatic-config-integration",
          configVars: {
            "webhook_url": {
              value: `${ domain }/api/v1/webhook/integrations/${ this.integration.toLowerCase() }`
            },
            "company": {
              value: this.company_id.toString()
            },
            "env": {
              value: localStorage.getItem('app_env')
            }
          }
        });
      }
    }
  }
}
</script>

<style scoped>

</style>