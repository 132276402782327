var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDataValid
    ? _c(
        "div",
        [
          _vm.single_button
            ? _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "Show Transcription",
                    "data-testid": "communication-transcription-tooltip",
                    placement: "bottom",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm blue btn-icon opaq",
                      attrs: {
                        "data-testid": "communication-transcription-button",
                      },
                      on: { click: _vm.openTranscriptionTalkLink },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("chat"),
                      ]),
                    ]
                  ),
                ]
              )
            : _c(
                "span",
                {
                  staticClass: "text text-dark-greenish m-0 p-0 pointer",
                  staticStyle: {
                    "font-size": "14px!important",
                    position: "relative",
                    top: "10px",
                    bottom: "10",
                  },
                  attrs: {
                    "data-test": "communication-smart-transcription-button",
                  },
                  on: { click: _vm.openTranscriptionTalkLink },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-file-text",
                    staticStyle: { "margin-left": "1px" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "margin-left": "5px" } }, [
                    _vm._v(_vm._s(this.button_text)),
                  ]),
                ]
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }