<template>
    <el-form-item :prop="id">
        <span class="page-anchor" :id="id"></span>

        <div class="form-label">
            <h5>{{ title }}</h5>
            <small>Prevent agents from overriding call recording settings.</small>
        </div>

        <div class="switch-label">
            <el-switch active-color="#00a344"
                       v-model="text"
                       @change="$emit('change', text)">
            </el-switch>

            <label><slot></slot></label>
        </div>
    </el-form-item>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },

        props: {
            id: {
                type: String,
                required: true
            },

            title: {
                type: String,
                required: true
            },

            value: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                text: this.value
            }
        }
    }
</script>
