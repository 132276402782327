<template>
    <div>
        <div class="pl-2 pr-2">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-header pt-0 pb-0">
                            <div class="row">
                                <div class="col-12">
                                    <span class="pull-left font-120">
                                        <strong>{{ pagination.total }}</strong> exports
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="box-body pt-0">
                            <div v-on:mouseleave="tableLeave">
                                <el-table :data="exports.data"
                                          :default-sort="{prop: 'id', order: 'descending'}"
                                          @cell-mouse-enter="cellEnter"
                                          class="w-full mt-3"
                                          fit
                                          row-key="id"
                                          stripe
                                          v-loading="loading">
                                    <el-table-column type="expand">
                                        <template v-slot="props">
                                            <div class="row"
                                                 v-if="props.row.type === ExportTypes.COMMUNICATION && props.row.filters">
                                                <div class="col-md-4 text-blackish">
                                                    <h6>
                                                        <b>From Date:</b>
                                                        {{ props.row.filters.from_date | fixFullDateLocal }}
                                                    </h6>
                                                    <h6>
                                                        <b>To Date:</b>
                                                        {{ props.row.filters.to_date | fixFullDateLocal }}
                                                    </h6>
                                                    <h6>
                                                        <b>Direction:</b>
                                                        {{ props.row.filters.direction | capitalize }}
                                                    </h6>
                                                    <h6>
                                                        <b>Answer Status:</b>
                                                        {{ props.row.filters.answer_status | capitalize }}
                                                    </h6>
                                                    <h6>
                                                        <b>Talk Time:</b>
                                                        <span v-if="props.row.filters.min_talk_time == 0">Any Talk Time</span>
                                                        <span v-else>{{ props.row.filters.min_talk_time | toInt | fixDuration }}</span>
                                                    </h6>
                                                    <h6>
                                                        <b>Type:</b>
                                                        {{ props.row.filters.type | capitalize }}
                                                    </h6>
                                                </div>
                                                <div class="col-md-4 text-blackish">
                                                    <h6>
                                                        <b>Tags:</b>
                                                        {{ formatTags(props.row) }}
                                                    </h6>
                                                    <h6>
                                                        <b>First-time Conversations:</b>
                                                        {{ formatBoolean(props.row.filters.first_time_only) }}
                                                    </h6>
                                                    <h6>
                                                        <b>Untagged Conversations:</b>
                                                        {{ formatBoolean(props.row.filters.untagged_only) }}
                                                    </h6>
                                                    <h6>
                                                        <b>Lines:</b>
                                                        {{ formatCampaigns(props.row) }}
                                                    </h6>
                                                    <h6>
                                                        <b>Numbers:</b>
                                                        {{ formatIncomingNumbers(props.row) }}
                                                    </h6>
                                                    <h6>
                                                        <b>Users:</b>
                                                        {{ formatUsers(props.row) }}
                                                    </h6>
                                                    <h6 v-if="props.row.filters.page">
                                                        <b>Page:</b>
                                                        {{ props.row.filters.page }}
                                                    </h6>
                                                </div>
                                                <div class="col-md-4 text-blackish">
                                                </div>
                                            </div>
                                            <div class="row"
                                                 v-else-if="sub_category_export_types.includes(props.row.type) && props.row.filters">
                                                <div class="col-md-4 text-blackish">
                                                    <h6>
                                                        <b>From Date:</b>
                                                        {{ props.row.filters.from_date | fixFullDateLocal }}
                                                    </h6>
                                                    <h6>
                                                        <b>To Date:</b>
                                                        {{ props.row.filters.to_date | fixFullDateLocal }}
                                                    </h6>
                                                </div>
                                                <div class="col-md-4 text-blackish">
                                                </div>
                                            </div>
                                            <div v-else>
                                        <span class="el-table__empty-text"
                                              style="color: #606266;">
                                            No Additional Data
                                        </span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :min-width="150"
                                                     label="Requested By"
                                                     prop="user.name"
                                                     sortable
                                                     v-if="isAdmin"/>
                                    <el-table-column :formatter="formatDate"
                                                     :min-width="150"
                                                     label="Requested At"
                                                     prop="created_at"
                                                     sortable/>
                                    <el-table-column :min-width="250"
                                                     label="Export Type"
                                                     prop="type"
                                                     sortable>
                                        <template v-slot="scope">
                                            Type: {{ generateTypeText(scope.row.type) }},
                                            Format: {{ scope.row.filters.export_type }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :formatter="getStatusDescription"
                                                     label="Status"
                                                     prop="status"
                                                     sortable
                                                     width="100px"/>
                                    <el-table-column align="center"
                                                     fixed="right"
                                                     label="Operations"
                                                     width="150px">
                                        <template v-slot="scope">
                                            <div v-bind:style="getStyle(scope.row.id)">
                                                <a :href="scope.row.url"
                                                   class="btn greenish btn-sm btn-block"
                                                   v-if="scope.row.status === ExportStatus.FINISHED">
                                                    <i class="fa fa-download pull-left"></i>
                                                    Download
                                                </a>

                                                <button :disabled="isDeletingItem(scope.row)"
                                                        @click="deleteItem(scope.row)"
                                                        class="btn btn-danger btn-sm btn-block"
                                                        v-if="scope.row.status !== ExportStatus.IN_PROGRESS">
                                                    <i class="fa fa-trash pull-left"></i>
                                                    Delete
                                                </button>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div class="mt-3"
                                 v-if="pagination.last_page >= 1">
                                <el-pagination :current-page.sync="pagination.current_page"
                                               :page-size="20"
                                               :page-sizes="[10, 20, 50, 100, 250]"
                                               :total="pagination.total"
                                               @current-change="pageChange"
                                               @size-change="pageSizeChange"
                                               background
                                               class="pull-right"
                                               layout="sizes, prev, pager, next">
                                </el-pagination>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
    import auth from '../auth'
    import {mapActions, mapState} from 'vuex'
    import * as ExportStatus from '../constants/export-status'
    import * as ExportTypes from '../constants/export-types'
    import * as Roles from '../constants/roles'
    import {acl_mixin, scroll_mixin} from '../mixins'
    import _ from 'lodash'

    export default {
        mixins: [acl_mixin, scroll_mixin],

        data() {
            return {
                auth: auth,
                loading: false,
                deletingItems: [],
                visible_row_id: null,
                sub_category_export_types: [
                    ExportTypes.USERS_ACTIVITY_REPORTING,
                    ExportTypes.LINES_ACTIVITY_REPORTING,
                    ExportTypes.RING_GROUPS_ACTIVITY_REPORTING,
                    ExportTypes.SEQUENCES_ACTIVITY_REPORTING,
                    ExportTypes.BROADCASTS_ACTIVITY_REPORTING,
                    ExportTypes.CONTACTS_ACTIVITY_REPORTING_LINES,
                    ExportTypes.CONTACTS_ACTIVITY_REPORTING_AGENTS,
                    ExportTypes.CONTACTS_ACTIVITY_REPORTING_NEW_CONTACTS_VS_DNC
                ],
                ExportStatus,
                ExportTypes,
                Roles,
                dataKey: 'filteredExports',
                pagination: {
                    page: 1,
                    current_page: 1,
                    per_page: 20,
                    total: 0,
                    last_page: 1
                },
                endpoint: '/api/v1/export'
            }
        },

        computed: {
            filteredExports() {
                // sanity check
                if (!this.auth.user || !this.auth.user.profile) {
                    return []
                }

                if (!this.isAdmin) {
                    return this.exports.data.filter((exportedData) => exportedData.user_id == this.auth.user.profile.id)
                }

                return this.exports.data
            },

            ...mapState(['exports']),
            ...mapState('cache', ['current_company'])
        },

        mounted() {
            this.getData()
        },

        methods: {
            getData() {
                this.loading = true
                axios.get(this.endpoint, {
                    params: {
                        scope: 'company',
                        paginate: true,
                        page: this.pagination.page,
                        per_page: this.pagination.per_page
                    }
                }).then(res => {
                    this.loading = false
                    this.pagination.total = res.data.total
                    this.pagination.last_page = res.data.last_page
                    this.setExports(res.data.data)
                }).catch(err => {
                    this.loading = false
                    this.$root.handleErrors(err.response)
                    console.log(err)
                })
            },

            getStatusDescription(row, col, status, index) {
                switch (status) {
                    case ExportStatus.NEW:
                        return 'Queued'
                    case ExportStatus.IN_PROGRESS:
                        return 'In Progress'
                    case ExportStatus.FINISHED:
                        return 'Finished'
                    case ExportStatus.FAILED:
                        return 'Failed'
                }
            },

            formatDate(row, col, value, index) {
                return this.$options.filters.fixDateTime(value)
            },

            formatTags(exp) {
                if (!exp.filters.tags) {
                    return '-'
                }
                return exp.filters.tags.map(item => {
                    return item.name
                }).join(', ')
            },

            formatBoolean(bool) {
                return parseInt(bool) === 1 ? 'Yes' : 'No'
            },

            formatCampaigns(exp) {
                if (!exp.filters.campaigns) {
                    return '-'
                }
                return exp.filters.campaigns.map(item => {
                    return item.name
                }).join(', ')
            },

            formatUsers(exp) {
                if (!exp.filters.users) {
                    return '-'
                }
                return exp.filters.users.map(item => {
                    return (item.first_name + ' ' + item.last_name).trim()
                }).join(', ')
            },

            formatIncomingNumbers(exp) {
                if (!exp.filters.incoming_numbers) {
                    return '-'
                }
                return exp.filters.incoming_numbers.map(item => {
                    return item.label && item.label.length > 0 ? item.label : item.phone_number
                }).join(', ')
            },

            isDeletingItem(exp) {
                return this.deletingItems.indexOf(exp) > -1
            },

            deleteItem(exp) {
                this.$confirm('Are you sure you want to delete the export file?', 'Warning', {
                    confirmButtonText: 'Yes, Remove',
                    cancelButtonText: 'No, Keep',
                    customClass: 'width-500 fixed',
                    type: 'warning',
                }).then(() => {
                    this.deletingItems.push(exp)
                    axios.delete(`/api/v1/export/${exp.id}`).then(() => {
                        this.deleteExport(exp)
                        this.$notify.success({
                            offset: 95,
                            title: 'Exports',
                            message: 'Export has been deleted.'
                        })
                    }).then(() => {
                        this.deletingItems.splice(this.deletingItems.indexOf(exp), 1)
                    })
                }).catch(() => {

                })
            },

            cellEnter: _.throttle(function (row, column, cell, event) {
                this.visible_row_id = row.id
            }, 50),

            tableLeave() {
                this.visible_row_id = null
            },

            getStyle(id) {
                let opacity = 0.5
                if (id == this.visible_row_id) {
                    opacity = 1
                }

                return {
                    opacity: opacity
                }
            },

            checkSize() {
                let valid_sizes = ['xxl', 'xl']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            },

            generateTypeText(type) {
                switch (type) {
                    case 'contacts_activity_reporting_lines':
                        return 'Contacts activity reporting: Lines'
                    case 'contacts_activity_reporting_agents':
                        return 'Contacts activity reporting: Agents'
                    case 'contacts_activity_reporting_new_contacts_vs_dnc':
                        return 'Contacts activity reporting: New Contacts vs DNC Contacts'
                    default:
                        return this.$options.filters.capitalize(type.replace(/_/g, ' '))
                }
            },

            pageChange(page) {
                this.pagination.page = page
                this.getData()
            },

            pageSizeChange(size) {
                this.pagination.per_page = size
                this.getData()
            },

            ...mapActions(['setExports', 'deleteExport'])
        }
    }
</script>
