var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contact && _vm.contact.id
    ? _c(
        "div",
        {
          staticClass: "row no-gutter",
          class: [_vm.no_padding ? "" : "pl-3 pr-3"],
        },
        [
          _vm.hubspotEnabled
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_integration_data.hubspot,
                      expression: "loading_integration_data.hubspot",
                    },
                  ],
                  staticClass: "col-12",
                  staticStyle: { "min-height": "60px" },
                },
                [
                  _vm.integration_data.hubspot &&
                  !_vm.isEmpty(_vm.integration_data.hubspot)
                    ? _c(
                        "el-card",
                        {
                          staticClass: "small-paddings",
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "d-flex align-items-center",
                                  attrs: {
                                    target: "_blank",
                                    "data-testid": "hubspot-contact-link",
                                    href: _vm.hubspotContactLink,
                                  },
                                },
                                [
                                  _c("button", {
                                    staticClass:
                                      "md-btn white hubspot-btn small",
                                  }),
                                  _vm._v(" "),
                                  _c("strong", { staticClass: "l-h-1x ml-2" }, [
                                    _vm._v("HubSpot"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("table", { staticClass: "mb-2 w-100" }, [
                            _vm.integration_data.hubspot.properties.firstname &&
                            _vm.integration_data.hubspot.properties.lastname
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("Name"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.hubspot
                                            .properties.firstname.value +
                                            " " +
                                            _vm.integration_data.hubspot
                                              .properties.lastname.value
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.integration_data.hubspot.properties.email
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("Email"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.hubspot
                                            .properties.email.value
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.integration_data.hubspot.properties.company
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("Company"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.hubspot
                                            .properties.company.value
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.integration_data.hubspot.properties
                              .hubspot_owner
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("Owner"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.hubspot
                                            .properties.hubspot_owner
                                            .firstName +
                                            " " +
                                            _vm.integration_data.hubspot
                                              .properties.hubspot_owner.lastName
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _vm.integration_data.hubspot.properties.deals
                            ? _vm._l(
                                _vm.integration_data.hubspot.properties.deals,
                                function (deal, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "b-a p-2 rounded mb-2",
                                    },
                                    [
                                      _c("table", { staticClass: "w-100" }, [
                                        deal.properties.dealname
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "2" } },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        target: "_blank",
                                                        "data-testid":
                                                          "hubspot-contact-base-link",
                                                        href:
                                                          _vm.hubspotContactBaseLink +
                                                          "deal/" +
                                                          deal.dealId,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "strong",
                                                        {
                                                          staticClass:
                                                            "text-md",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              deal.properties
                                                                .dealname.value
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        deal.properties.amount
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Amount")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-right _400",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("toCurrency")(
                                                          deal.properties.amount
                                                            .value
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        deal.properties.pipeline
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Pipeline")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-right _400",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        deal.properties.pipeline
                                                          .label
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        deal.properties.dealstage
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Stage")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-right _400",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        deal.properties
                                                          .dealstage.label
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.integration_data.hubspot.properties.email &&
                          _vm.integration_data.hubspot.properties.email.value &&
                          false
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row no-gutter centered-content mt-3 mb-3",
                                },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right",
                                        trigger: "click",
                                        width: "400",
                                      },
                                      on: {
                                        hide: _vm.resetHubspotContactEnroll,
                                      },
                                      model: {
                                        value:
                                          _vm.enroll_hubspot_popover_visibility,
                                        callback: function ($$v) {
                                          _vm.enroll_hubspot_popover_visibility =
                                            $$v
                                        },
                                        expression:
                                          "enroll_hubspot_popover_visibility",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "enroll_to_hubspot_workflow",
                                          staticClass: "mt-2 p-0",
                                          attrs: {
                                            "label-position": "top",
                                            rules: _vm.rules_hubspot_workflow,
                                            model: _vm.hubspot_workflow,
                                            "data-testid":
                                              "enroll-to-hubspot-workflow",
                                          },
                                          nativeOn: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              return _vm.enrollHubspotWorkflow.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  "Select a HubSpot workflow",
                                                prop: "workflow_id",
                                              },
                                            },
                                            [
                                              _c("hubspot-workflow-selector", {
                                                ref: "hubspotWorkflowSelector",
                                                attrs: {
                                                  value:
                                                    _vm.hubspot_workflow
                                                      .workflow_id,
                                                  "data-testid":
                                                    "hubspot-worklow-select",
                                                },
                                                on: {
                                                  change:
                                                    _vm.hubspotWorkflowChanged,
                                                },
                                                model: {
                                                  value:
                                                    _vm.hubspot_workflow
                                                      .workflow_id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.hubspot_workflow,
                                                      "workflow_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "hubspot_workflow.workflow_id",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row no-gutter mt-2 centered-content",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-block greenish",
                                                  attrs: {
                                                    disabled:
                                                      _vm.loading_hubspot_enroll_contact ||
                                                      !_vm.validated,
                                                    "data-testid":
                                                      "hubspot-enroll-button",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.enrollHubspotWorkflow.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.loading_hubspot_enroll_contact,
                                                          expression:
                                                            "loading_hubspot_enroll_contact",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "material-icons loader",
                                                    },
                                                    [_vm._v("")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          !_vm.loading_hubspot_enroll_contact,
                                                        expression:
                                                          "!loading_hubspot_enroll_contact",
                                                      },
                                                    ],
                                                    staticClass: "fa fa-plus",
                                                  }),
                                                  _vm._v(
                                                    "\n                                Enroll\n                            "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "md-btn md-raised deep-orange pl-3 pr-3",
                                          attrs: {
                                            slot: "reference",
                                            "data-testid":
                                              "hubspot-enroll-to-workflow-button",
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-user-plus mr-2",
                                          }),
                                          _vm._v(
                                            "\n                        Enroll to workflow\n                    "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.dialer_mode
                    ? _c(
                        "div",
                        { staticClass: "mt-2 centered-content" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass:
                                "bold text-deep-orange width-300 fixed",
                              attrs: {
                                size: "small",
                                type: "deep-orange",
                                disabled: _vm.loading_hubspot_sync,
                                plain: "",
                                round: "",
                                "data-testid": "sync-hubspot-button",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.syncHubspot.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "strong",
                                [
                                  _c(
                                    "i",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.loading_hubspot_sync,
                                          expression: "loading_hubspot_sync",
                                        },
                                      ],
                                      staticClass: "material-icons loader",
                                    },
                                    [_vm._v("")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "i",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.loading_hubspot_sync,
                                          expression: "!loading_hubspot_sync",
                                        },
                                      ],
                                      staticClass: "material-icons",
                                    },
                                    [_vm._v("sync")]
                                  ),
                                  _vm._v(" "),
                                  _vm._t("default", function () {
                                    return [_vm._v("Sync with HubSpot")]
                                  }),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right",
                                width: "350",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", { staticClass: "_400" }, [
                                _c("p", [
                                  _vm._v(
                                    "Click on this button to sync the data for this contact between Aloware and HubSpot. You'll want to click on this button if:"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("ol", [
                                  _c("li", [
                                    _vm._v(
                                      "The contact was recently merged in HubSpot with another contact."
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _vm._v(
                                      "You notice any inconsistencies between Aloware and HubSpot data on this contact."
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-blue cursor-pointer ml-2",
                                  attrs: {
                                    slot: "reference",
                                    "data-testid": "info-sync-hubspot",
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("info_outline")]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.pipedriveEnabled
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_integration_data.pipedrive,
                      expression: "loading_integration_data.pipedrive",
                    },
                  ],
                  staticClass: "col-12",
                  staticStyle: { "min-height": "60px" },
                },
                [
                  _vm.integration_data.pipedrive &&
                  !_vm.isEmpty(_vm.integration_data.pipedrive)
                    ? _c(
                        "el-card",
                        {
                          staticClass: "small-paddings",
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("button", {
                                    staticClass:
                                      "md-btn white pipedrive-btn small",
                                  }),
                                  _vm._v(" "),
                                  _c("strong", { staticClass: "l-h-1x ml-2" }, [
                                    _vm._v("Pipedrive"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("table", { staticClass: "mb-2 w-100" }, [
                            _vm.integration_data.pipedrive.contact_details.name
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("Name"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.pipedrive
                                            .contact_details.name
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.integration_data.pipedrive.contact_details
                              .email &&
                            _vm.integration_data.pipedrive.contact_details.email
                              .length > 0 &&
                            _vm.integration_data.pipedrive.contact_details
                              .email[0].value
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("Email"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.pipedrive
                                            .contact_details.email[0].value
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.integration_data.pipedrive.contact_details
                              .org_name
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("Company"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.pipedrive
                                            .contact_details.org_name
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.integration_data.pipedrive.contact_details
                              .owner_name
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("Owner"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.pipedrive
                                            .contact_details.owner_name
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _vm.integration_data.pipedrive.deals &&
                          _vm.integration_data.pipedrive.deals.length > 0
                            ? _vm._l(
                                _vm.integration_data.pipedrive.deals,
                                function (deal, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "b-a p-2 rounded mb-2",
                                    },
                                    [
                                      _c("table", { staticClass: "w-100" }, [
                                        deal.title
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "2" } },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        target: "_blank",
                                                        href: _vm.hubspotContactBaseLink,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "strong",
                                                        {
                                                          staticClass:
                                                            "text-md",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(deal.title)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        deal.formatted_value
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Amount")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-right _400",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        deal.formatted_value
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        deal.pipeline_details.name
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Pipeline")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-right _400",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        deal.pipeline_details
                                                          .name
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        deal.stage_details.name
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Stage")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-right _400",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        deal.stage_details.name
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.dialer_mode
                    ? _c(
                        "div",
                        { staticClass: "mt-2 centered-content" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "bold width-300 fixed",
                              attrs: {
                                size: "small",
                                disabled: _vm.loading_pipedrive_sync,
                                type: "deep-blue",
                                plain: "",
                                round: "",
                                "data-testid": "sync-pipedrive-button",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.syncPipedrive.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "strong",
                                [
                                  _c(
                                    "i",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.loading_pipedrive_sync,
                                          expression: "loading_pipedrive_sync",
                                        },
                                      ],
                                      staticClass: "material-icons loader",
                                    },
                                    [_vm._v("")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "i",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.loading_pipedrive_sync,
                                          expression: "!loading_pipedrive_sync",
                                        },
                                      ],
                                      staticClass: "material-icons",
                                    },
                                    [_vm._v("sync")]
                                  ),
                                  _vm._v(" "),
                                  _vm._t("default", function () {
                                    return [_vm._v("Sync with Pipedrive")]
                                  }),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right",
                                width: "350",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", { staticClass: "_400" }, [
                                _c("p", [
                                  _vm._v(
                                    "Click on this button to sync the data for this contact between Aloware and Pipedrive. You'll want to click on this button if:"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("ol", [
                                  _c("li", [
                                    _vm._v(
                                      'You want to add this contact in Pipedrive. Note that\n                            "'
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Don't create new contacts")]
                                    ),
                                    _vm._v(
                                      '"\n                            filter must be disabled in the integration settings.\n                        '
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _vm._v(
                                      "You notice any inconsistencies between Aloware and Pipedrive data on this contact."
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-blue cursor-pointer ml-2",
                                  attrs: {
                                    slot: "reference",
                                    "data-testid": "info-sync-pipedrive",
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("info_outline")]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.goHighLevelEnabled
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_integration_data.gohighlevel,
                      expression: "loading_integration_data.gohighlevel",
                    },
                  ],
                  staticClass: "col-12",
                  staticStyle: { "min-height": "60px" },
                },
                [
                  _vm.integration_data.gohighlevel &&
                  !_vm.isEmpty(_vm.integration_data.gohighlevel)
                    ? _c(
                        "el-card",
                        {
                          staticClass: "small-paddings",
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clearfix",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "d-flex align-items-center",
                                      attrs: {
                                        target: "_blank",
                                        "data-testid":
                                          "gohighlevel-contact-link",
                                        href: _vm.goHighLevelContactLink,
                                      },
                                    },
                                    [
                                      _c("button", {
                                        staticClass:
                                          "md-btn white gohighlevel-btn small",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("strong", { staticClass: "l-h-1x ml-2" }, [
                                    _vm._v("GoHighLevel"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("table", { staticClass: "mb-2 w-100" }, [
                            _vm.integration_data.gohighlevel.contact_details
                              .contact.firstName
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("First Name"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.gohighlevel
                                            .contact_details.contact.firstName
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.integration_data.gohighlevel.contact_details
                              .contact.lastName
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("Last Name"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.gohighlevel
                                            .contact_details.contact.lastName
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.integration_data.gohighlevel.contact_details
                              .contact.email &&
                            _vm.integration_data.gohighlevel.contact_details
                              .contact.email.length > 0
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("Email"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.gohighlevel
                                            .contact_details.contact.email
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.integration_data.gohighlevel.contact_details
                              .contact.phone
                              ? _c("tr", [
                                  _c("td", { staticClass: "text-muted" }, [
                                    _vm._v("Phone"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right _400" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.integration_data.gohighlevel
                                            .contact_details.contact.phone
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.dialer_mode
                    ? _c(
                        "div",
                        { staticClass: "mt-2 centered-content" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "bold width-300 fixed",
                              attrs: {
                                size: "small",
                                disabled: _vm.loading_gohighlevel_sync,
                                type: "deep-blue",
                                plain: "",
                                round: "",
                                "data-testid": "info-sync-gohighlevel",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.syncGHL.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "strong",
                                [
                                  _c(
                                    "i",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.loading_gohighlevel_sync,
                                          expression:
                                            "loading_gohighlevel_sync",
                                        },
                                      ],
                                      staticClass: "material-icons loader",
                                    },
                                    [_vm._v("")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "i",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.loading_gohighlevel_sync,
                                          expression:
                                            "!loading_gohighlevel_sync",
                                        },
                                      ],
                                      staticClass: "material-icons",
                                    },
                                    [_vm._v("sync")]
                                  ),
                                  _vm._v(" "),
                                  _vm._t("default", function () {
                                    return [_vm._v("Sync with GoHighLevel")]
                                  }),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right",
                                width: "350",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", { staticClass: "_400" }, [
                                _c("p", [
                                  _vm._v(
                                    "Click on this button to sync the data for this contact between Aloware and GoHighLevel. You'll want to click on this button if:"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("ol", [
                                  _c("li", [
                                    _vm._v(
                                      'You want to add this contact in GoHighLevel. Note that\n                            "'
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Don't create new contacts")]
                                    ),
                                    _vm._v(
                                      '"\n                            filter must be disabled in the integration settings.\n                        '
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _vm._v(
                                      "You notice any inconsistencies between Aloware and GoHighLevel data on this contact."
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons text-blue cursor-pointer ml-2",
                                  attrs: {
                                    slot: "reference",
                                    "data-testid": "info-sync-gohighlevel",
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("info_outline")]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }