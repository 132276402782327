var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "aup-dialog",
      attrs: {
        width: "50vw",
        top: "4vh",
        visible: _vm.show_form,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show_form = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("h5", { staticClass: "mb-0" }, [
          _c("i", { staticClass: "fa fa-file-signature" }),
          _vm._v(
            "\n            Notice on Carrier Fees for SMS and MMS\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "break-word" }, [
        _c("p", { staticClass: "text-md _400" }, [
          _vm._v(
            "\n            Welcome to " +
              _vm._s(_vm.statics.name) +
              "!\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-md _400" }, [
          _vm._v(
            "\n            Dear " +
              _vm._s(_vm.user.profile.name) +
              ",\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-md _400" }, [
          _vm._v(
            "\n            As you embark on your journey with us, we want to ensure you're fully informed about important details regarding carrier fees associated with our messaging services.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-md _400" }, [
          _vm._v(
            "\n            Beginning last year, major US carriers (AT&T, T-Mobile, Verizon) have implemented various fees on SMS/MMS traffic. We've made it our priority to transparently pass these fees through to you without any markup.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-md _400" }, [
          _vm._v(
            "\n            For a comprehensive understanding of these fees and our approach, please refer to:\n            "
          ),
          _c("ul", [
            _c(
              "li",
              [
                _c(
                  "el-link",
                  {
                    attrs: {
                      underline: false,
                      href: "https://support.aloware.com/en/articles/9032003-a2p-10dlc-fees-brand-registration-and-campaign-costs",
                      target: "_blank",
                      type: "primary",
                    },
                  },
                  [
                    _vm._v(
                      "\n                        A2P 10DLC Fees: Brand Registration and Campaign Costs\n                    "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "el-link",
                  {
                    attrs: {
                      underline: false,
                      href: "https://support.aloware.com/en/articles/9031981-a2p-10dlc-compliance-brand-registration-guide",
                      target: "_blank",
                      type: "primary",
                    },
                  },
                  [
                    _vm._v(
                      "\n                        A2P 10DLC Compliance: Brand Registration Guide\n                    "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-md _400" }, [
          _vm._v(
            "\n            Your acknowledgement of this notice serves as formal authorization for us to collect these fees and we will be recording your acceptance as well.\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-md _400" }, [
          _vm._v("\n            This is a non-refundable fee.\n        "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-md _400" }, [
          _vm._v("Date: "),
          _c("span", { staticClass: "text-decoration-underline" }, [
            _vm._v(_vm._s(_vm.now)),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-md _400" }, [
          _vm._v("Name: "),
          _c("span", { staticClass: "text-decoration-underline" }, [
            _vm._v(_vm._s(_vm.user.profile.name)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-checkbox",
        {
          staticClass: "checkbox-md mt-2",
          attrs: { disabled: _vm.loading },
          model: {
            value: _vm.agreed,
            callback: function ($$v) {
              _vm.agreed = $$v
            },
            expression: "agreed",
          },
        },
        [
          _vm._v(
            "\n        I acknowledge and authorize the collection of carrier fees.\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", disabled: !_vm.agreed || _vm.loading },
              on: { click: _vm.confirming },
            },
            [_vm._v("\n            Agree\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }