var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("create disposition status")
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              staticClass: "dialog-padding auto",
              attrs: {
                title: "Create a new call disposition",
                width: _vm.isLargeEnough ? "50%" : "100%",
                visible: _vm.hide_add,
                "before-close": _vm.beforeCloseModal,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.hide_add = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "add_call_disposition",
                  staticClass: "mt-2 p-0",
                  attrs: {
                    "label-position": "top",
                    rules: _vm.rules_call_disposition,
                    model: _vm.call_disposition,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.addCallDisposition.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "color", label: "Choose a color" } },
                    [
                      _c("el-color-picker", {
                        staticClass: "d-block",
                        on: {
                          "active-change": _vm.changeTagColor,
                          change: function ($event) {
                            return _vm.preValidateForm("add_call_disposition")
                          },
                        },
                        model: {
                          value: _vm.call_disposition.color,
                          callback: function ($$v) {
                            _vm.$set(_vm.call_disposition, "color", $$v)
                          },
                          expression: "call_disposition.color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name", label: "Name" } },
                    [
                      _c(
                        "div",
                        { staticClass: "width-300" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "190",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.preValidateForm(
                                      "add_call_disposition"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.call_disposition.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.call_disposition, "name", $$v)
                                  },
                                  expression: "call_disposition.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "description",
                        label: "Description (Optional)",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "width-300" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "description" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: "2",
                                  maxlength: "190",
                                  "show-word-limit": "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.preValidateForm(
                                      "add_call_disposition"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.call_disposition.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.call_disposition,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "call_disposition.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-right ml-2",
                        attrs: { type: "success", disabled: _vm.loading_btn },
                        on: { click: _vm.addCallDisposition },
                      },
                      [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading_btn,
                                expression: "loading_btn",
                              },
                            ],
                            staticClass: "material-icons loader",
                          },
                          [_vm._v("")]
                        ),
                        _vm._v(" "),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.loading_btn,
                              expression: "!loading_btn",
                            },
                          ],
                          staticClass: "fa fa-plus",
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Add")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-right",
                        attrs: { type: "info" },
                        on: {
                          click: function ($event) {
                            _vm.hide_add = false
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "fa fa-close" }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Cancel")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("trigger", function () {
            return [
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "reference",
                    type: "text text-sm text-dark-greenish",
                  },
                  on: {
                    click: function ($event) {
                      _vm.hide_add = true
                    },
                  },
                  slot: "reference",
                },
                [
                  _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                  _vm._v("\n            Add Call Disposition\n        "),
                ]
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }