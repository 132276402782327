var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wizard" }, [
    _c("div", { staticClass: "wizard-header" }, [
      _vm.success
        ? _c("h2", [_vm._v("Congratulations!")])
        : _c("h2", [_vm._v("Add Phone Number")]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "dropdown-divider" }),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-form",
          {
            ref: "country_code",
            staticClass: "mb-4",
            attrs: {
              "label-position": "top",
              model: _vm.incoming_number,
              rules: _vm.rules_country,
              inline: true,
            },
          },
          [
            _c(
              "el-form-item",
              {
                staticClass: "w-100",
                attrs: { prop: "country_code", label: "Country" },
              },
              [
                _c("country-selector", {
                  attrs: {
                    show_prefix: true,
                    supported_country_codes: _vm.supported_country_codes,
                    initial_country_code: _vm.initial_country_code,
                    force_initial_country_code: true,
                    show_tips: true,
                    disabled: _vm.loading,
                  },
                  on: { changed: _vm.changeCountry },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.incoming_number.country_code !== null
          ? _c("div", [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _vm.incoming_number.country_code !== null
                      ? _c(
                          "el-form",
                          {
                            ref: "campaign_number_search",
                            attrs: { "label-position": "top", inline: true },
                            nativeOn: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.changeAreaCode.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { staticClass: "d-inline-block width-300 fixed" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "el-form-item__label d-inline-block col-12",
                                  },
                                  [_vm._v("Number")]
                                ),
                                _vm._v(" "),
                                _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    pattern: "\\d*",
                                    maxlength: _vm.area_code_max_length,
                                    clearable: "",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.changeAreaCode.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.number_search,
                                    callback: function ($$v) {
                                      _vm.number_search = $$v
                                    },
                                    expression: "number_search",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { staticClass: "col-3 d-inline-block px-0" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "el-form-item__label d-inline-block col-12",
                                  },
                                  [_vm._v("Match To")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      "default-first-option": "",
                                      filterable: "",
                                      autocomplete: "off",
                                    },
                                    on: { change: _vm.changeMatchTo },
                                    model: {
                                      value: _vm.match_to,
                                      callback: function ($$v) {
                                        _vm.match_to = $$v
                                      },
                                      expression: "match_to",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        value: "area code",
                                        label: "Area Code",
                                        disabled: _vm.disableAreaCode,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "left",
                                          label: "First part of number",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    First part of number\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "any",
                                          label: "Anywhere in number",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Anywhere in number\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "right",
                                          label: "Last part of number",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Last part of number\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass:
                                  "col-2 d-inline-block pl-0 pr-2 align-bottom",
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      disabled: _vm.loading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.changeAreaCode.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-search" }),
                                    _vm._v(
                                      "\n                                Search\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form",
                      { attrs: { "label-position": "left" } },
                      [
                        _c("el-form-item", { staticClass: "mb-0 mt-3" }, [
                          _c(
                            "label",
                            { staticClass: "el-form-item__label col-12 px-0" },
                            [_vm._v("Type")]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    ["US", "CA"].includes(_vm.incoming_number.country_code)
                      ? _c(
                          "el-radio-group",
                          {
                            staticClass: "no-select mb-2",
                            attrs: { size: "small" },
                            on: { change: _vm.changePhoneNumberType },
                            model: {
                              value: _vm.incoming_number.phone_number_type,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.incoming_number,
                                  "phone_number_type",
                                  $$v
                                )
                              },
                              expression: "incoming_number.phone_number_type",
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.local_tooltip,
                                  placement: "top",
                                  disabled: !_vm.disableLocalNumbers,
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      label:
                                        _vm.IncomingNumberTypes
                                          .LOCAL_PHONE_NUMBERS,
                                      disabled: _vm.disableLocalNumbers,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Local\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "Can’t add a toll-free number to a line with one or more local numbers",
                                  placement: "top",
                                  disabled: !_vm.disableTollFreeNumbers,
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      label:
                                        _vm.IncomingNumberTypes
                                          .TOLL_FREE_NUMBERS,
                                      disabled: _vm.disableTollFreeNumbers,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Toll-free\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "el-radio-group",
                          {
                            staticClass: "no-select mb-2",
                            attrs: { size: "small" },
                            on: { change: _vm.changePhoneNumberType },
                            model: {
                              value: _vm.incoming_number.phone_number_type,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.incoming_number,
                                  "phone_number_type",
                                  $$v
                                )
                              },
                              expression: "incoming_number.phone_number_type",
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.local_tooltip,
                                  placement: "top",
                                  disabled: !_vm.disableLocalNumbers,
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      label:
                                        _vm.IncomingNumberTypes
                                          .LOCAL_PHONE_NUMBERS,
                                      disabled: _vm.disableLocalNumbers,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Local\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.mobile_tooltip,
                                  placement: "top",
                                  disabled: !_vm.disableMobileNumbers,
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      label:
                                        _vm.IncomingNumberTypes
                                          .MOBILE_PHONE_NUMBERS,
                                      disabled: _vm.disableMobileNumbers,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Mobile\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _c(
                      "el-form",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "campaign_incoming_number",
                        attrs: {
                          "label-position": "top",
                          "label-width": "300px",
                          rules: _vm.rules_phone_number,
                          model: _vm.incoming_number,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "phone_number" } },
                          [
                            [
                              _vm.IncomingNumberTypes.LOCAL_PHONE_NUMBERS,
                              _vm.IncomingNumberTypes.MOBILE_PHONE_NUMBERS,
                            ].includes(_vm.incoming_number.phone_number_type)
                              ? _c("div", [
                                  !_vm.changed
                                    ? _c("div", [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "el-form-item__label",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Local Phone Numbers "
                                            ),
                                            _vm.local_phone_number !== null
                                              ? _c("span", [
                                                  _vm._v(
                                                    "to " +
                                                      _vm._s(
                                                        _vm.local_phone_number
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                                        (" +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    _vm._f("fixRounding")(
                                                      _vm.auth.user.profile.rate
                                                        .local_pn,
                                                      2
                                                    )
                                                  )
                                                ) +
                                                "/mo surcharge)\n                                    "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _c("div", [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "el-form-item__label",
                                          },
                                          [
                                            _vm._v(
                                              "Phone Numbers in the " +
                                                _vm._s(_vm.number_search) +
                                                " area code"
                                            ),
                                          ]
                                        ),
                                      ]),
                                  _vm._v(" "),
                                  _vm.available_numbers
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "el-form-item__content",
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                staticClass: "w-full",
                                                on: {
                                                  change: _vm.changeNumber,
                                                },
                                                model: {
                                                  value:
                                                    _vm.incoming_number
                                                      .phone_number,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.incoming_number,
                                                      "phone_number",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "incoming_number.phone_number",
                                                },
                                              },
                                              [
                                                _vm._l(
                                                  Math.ceil(
                                                    _vm.available_numbers
                                                      .length / 4
                                                  ),
                                                  function (i) {
                                                    return _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      _vm._l(
                                                        _vm.available_numbers.slice(
                                                          (i - 1) * 4,
                                                          i * 4
                                                        ),
                                                        function (item) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-md-3 col-6",
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      item.sid,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                        " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "fixPhone"
                                                                        )(
                                                                          item.phone_number
                                                                        )
                                                                      ) +
                                                                      "\n                                                    "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _vm.available_numbers.length ===
                                                0
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "row" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "b-l pl-2 text-sm text-muted",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                            No numbers available\n                                                        "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.incoming_number.phone_number_type ===
                            _vm.IncomingNumberTypes.TOLL_FREE_NUMBERS
                              ? _c("div", [
                                  _c(
                                    "label",
                                    { staticClass: "el-form-item__label" },
                                    [
                                      _vm._v(
                                        "Toll-Free Numbers (" +
                                          _vm._s(
                                            _vm._f("toCurrency")(
                                              _vm._f("fixRounding")(
                                                _vm.auth.user.profile.rate
                                                  .tollfree_pn,
                                                2
                                              )
                                            )
                                          ) +
                                          "/mo surcharge)"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "el-form-item__content" },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticClass: "w-full",
                                          on: { change: _vm.changeNumber },
                                          model: {
                                            value:
                                              _vm.incoming_number.phone_number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.incoming_number,
                                                "phone_number",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "incoming_number.phone_number",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            Math.ceil(
                                              _vm.available_tollfree_numbers
                                                .length / 4
                                            ),
                                            function (i) {
                                              return _c(
                                                "div",
                                                { staticClass: "row" },
                                                _vm._l(
                                                  _vm.available_tollfree_numbers.slice(
                                                    (i - 1) * 4,
                                                    i * 4
                                                  ),
                                                  function (item) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-3 col-6",
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: item.sid,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "fixPhone"
                                                                  )(
                                                                    item.phone_number
                                                                  )
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _vm.available_tollfree_numbers
                                            .length === 0
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-12" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "b-l pl-2 text-sm text-muted",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        No numbers available\n                                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-7 text-left" }, [
            _vm.isLocalTypeSelected &&
            _vm.lineHasMessagingService &&
            _vm.is10DlcNumber
              ? _c("p", [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("Note:"),
                  ]),
                  _vm._v(
                    " This phone number will be automatically added to the line's A2P Campaign "
                  ),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "#" +
                        _vm._s(_vm.lineMessagingService.id) +
                        " " +
                        _vm._s(
                          _vm.messagingServiceFormattedUseCase(
                            _vm.lineMessagingService?.a2p_use_case?.use_case
                          )
                        )
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-5 text-right" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    disabled: _vm.loading_btn || !_vm.validated,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("incoming_number")
                    },
                  },
                },
                [
                  _c(
                    "i",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loading_btn,
                          expression: "loading_btn",
                        },
                      ],
                      staticClass: "material-icons loader",
                    },
                    [_vm._v("")]
                  ),
                  _vm._v(" "),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loading_btn,
                        expression: "!loading_btn",
                      },
                    ],
                    staticClass: "fa fa-plus",
                  }),
                  _vm._v("\n                    Add Number\n                "),
                ]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }