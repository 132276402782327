var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_400" },
    _vm._l(_vm.mapCallIssues, function (callIssue) {
      return _vm.networkIssues[callIssue.data.name]
        ? _c("div", [
            _c("div", { staticClass: "border-top text-left px-4 py-3" }, [
              _c("label", { staticClass: "form-control-label" }, [
                _vm._v(" Agent: "),
              ]),
              _vm._v(
                "\n            " + _vm._s(_vm.user.full_name) + "\n            "
              ),
              callIssue.data.name === "rtt"
                ? _c(
                    "div",
                    [
                      _c("network-signal-strength", {
                        attrs: {
                          "value-issue": callIssue.data.avg,
                          issue: _vm.networkIssues[callIssue.data.name],
                          title: _vm.networkIssues[callIssue.data.name].trigger,
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-control-label" }, [
                        _vm._v("Max outbound Latency:"),
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(callIssue.data.max) +
                          " ms "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-control-label" }, [
                        _vm._v("Avg outbound Latency:"),
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(callIssue.data.avg) +
                          " ms\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\n                " +
                          _vm._s(
                            _vm.networkIssues[callIssue.data.name].details
                          ) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : callIssue.data.name === "mos"
                ? _c(
                    "div",
                    [
                      _c("network-signal-strength", {
                        attrs: {
                          "value-issue": callIssue.data.avg,
                          issue: _vm.networkIssues[callIssue.data.name],
                          title: _vm.networkIssues[callIssue.data.name].trigger,
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-control-label" }, [
                        _vm._v("Outbound MOS:"),
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(callIssue.data.avg) +
                          "\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\n                " +
                          _vm._s(
                            _vm.networkIssues[callIssue.data.name].details
                          ) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : callIssue.data.name === "jitter"
                ? _c(
                    "div",
                    [
                      _c("network-signal-strength", {
                        attrs: {
                          "value-issue": callIssue.data.max,
                          issue: _vm.networkIssues[callIssue.data.name],
                          title: _vm.networkIssues[callIssue.data.name].trigger,
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-control-label" }, [
                        _vm._v("Max inbound Jitter:"),
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(callIssue.data.max) +
                          " ms "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-control-label" }, [
                        _vm._v("Avg inbound Jitter:"),
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(callIssue.data.avg) +
                          " ms\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\n                " +
                          _vm._s(
                            _vm.networkIssues[callIssue.data.name].details
                          ) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : callIssue.data.name === "bytesReceived"
                ? _c(
                    "div",
                    [
                      _c("network-signal-strength", {
                        attrs: {
                          "value-issue": callIssue.data.min,
                          issue: _vm.networkIssues[callIssue.data.name],
                          title: _vm.networkIssues[callIssue.data.name].trigger,
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-control-label" }, [
                        _vm._v("Bytes received:"),
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(callIssue.data.min) +
                          "\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\n                " +
                          _vm._s(
                            _vm.networkIssues[callIssue.data.name].details
                          ) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : callIssue.data.name === "bytesSent"
                ? _c(
                    "div",
                    [
                      _c("network-signal-strength", {
                        attrs: {
                          "value-issue": callIssue.data.min,
                          issue: _vm.networkIssues[callIssue.data.name],
                          title: _vm.networkIssues[callIssue.data.name].trigger,
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-control-label" }, [
                        _vm._v("Bytes sent:"),
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(callIssue.data.min) +
                          "\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\n                " +
                          _vm._s(
                            _vm.networkIssues[callIssue.data.name].details
                          ) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : callIssue.data.name === "packetsLostFraction"
                ? _c(
                    "div",
                    [
                      _c("network-signal-strength", {
                        attrs: {
                          "value-issue": callIssue.data.avg,
                          issue: _vm.networkIssues[callIssue.data.name],
                          title: _vm.networkIssues[callIssue.data.name].trigger,
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-control-label" }, [
                        _vm._v("Inbound Packet Loss:"),
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(callIssue.data.avg) +
                          "%\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n\n                " +
                          _vm._s(
                            _vm.networkIssues[callIssue.data.name].details
                          ) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }