var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasEnabledIntegration
    ? _c(
        "el-button",
        {
          attrs: {
            type: "success",
            size: "small",
            disabled: _vm.isTrialKYC,
            loading: _vm.loading,
          },
          on: { click: _vm.checkIntegrations },
        },
        [_vm._v(_vm._s(_vm.title) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }