var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.sidebarKey,
      ref: "sidebar",
      staticClass: "app-aside modal nav-dropdown no-select",
      class: { "folded md show-text": _vm.sidebar_folded },
      style: { top: _vm.top_offset + "px" },
      attrs: { id: "aside" },
    },
    [
      _c(
        "div",
        {
          staticClass: "left navside text-blackish",
          attrs: { "data-layout": "column" },
        },
        [
          _c("div", { staticClass: "navbar no-radius" }, [
            _c("a", { staticClass: "navbar-brand mx-auto d-block" }, [
              !_vm.sidebar_folded && !_vm.loading_whitelabel
                ? _c("img", { attrs: { src: _vm.statics.logo, alt: "." } })
                : _vm._e(),
              _vm._v(" "),
              _vm.sidebar_folded && !_vm.loading_whitelabel
                ? _c("img", {
                    attrs: { src: _vm.statics.logo_square, alt: "." },
                  })
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "hide-scroll pt-2", attrs: { "data-flex": "" } },
            [
              _c("nav", { staticClass: "scroll nav-border b-greenish h-v" }, [
                _c(
                  "ul",
                  { staticClass: "nav", attrs: { "ui-nav": "" } },
                  [
                    !_vm.isSupervisor ? _c("app-sidebar-dashboard") : _vm._e(),
                    _vm._v(" "),
                    _vm.hasWallboardAccess && !_vm.isSupervisor
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Wallboard" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-wallboard-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-wallboard-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Wallboard"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowLines
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Lines" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-lines-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-lines-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Lines"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo("list ring group") &&
                    !_vm.hasReporterAccess
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Ring Groups" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-ring-groups-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-ring-groups-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Ring Groups"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAdminOrSupervisor
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Teams" },
                              "active-class": "active green-gradient rounded",
                              "exact-active-class":
                                "active green-gradient rounded",
                              tag: "li",
                              "data-testid": "teams-sidebar",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-teams.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-teams-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-center",
                                  class: _vm.sidebar_folded
                                    ? "flex-column"
                                    : "",
                                },
                                [
                                  _c("span", { staticClass: "nav-text" }, [
                                    _vm._v("Teams"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-tag",
                                    {
                                      class: _vm.sidebar_folded ? "" : "ml-2",
                                      attrs: { size: "mini", type: "info" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Beta\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowUsers
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Users" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                              "data-testid": "users-sidebar",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-user-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-user-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Users"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAdminOrSupervisor
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              "active-class": "active green-gradient rounded",
                              "exact-active-class":
                                "active green-gradient rounded",
                              tag: "li",
                              "data-testid": "import-sidebar",
                              to: { name: "Imports" },
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-imports-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-imports-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-center",
                                  class: _vm.sidebar_folded
                                    ? "flex-column"
                                    : "",
                                },
                                [
                                  _c("span", { staticClass: "nav-text" }, [
                                    _vm._v("Imports"),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAdminOrSupervisor
                      ? _c("app-sidebar-admin-list")
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo("list tag") && !_vm.isSupervisor
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Tags" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-tags-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-tags-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Tags"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowAloAiMenu
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "AloAi" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c(
                                "span",
                                { staticClass: "nav-label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Pro",
                                        placement: "right",
                                      },
                                    },
                                    [
                                      _c("b", {
                                        staticClass:
                                          "fa fa-circle pro-label-text",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/aloai-text-bot-gray.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/aloai-text-bot-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("AloAi Text Bot"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowAloAiMenu
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "AloAi Voice Bot" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c(
                                "span",
                                { staticClass: "nav-label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Pro",
                                        placement: "right",
                                      },
                                    },
                                    [
                                      _c("b", {
                                        staticClass:
                                          "fa fa-circle pro-label-text",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/aloai-voice-bot-gray.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/aloai-voice-bot-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("AloAi Voice Bot"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowAloVoiceAiMenu
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "AloAi Voice Analytics" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c(
                                "span",
                                { staticClass: "nav-label" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Pro",
                                        placement: "right",
                                      },
                                    },
                                    [
                                      _c("b", {
                                        staticClass:
                                          "fa fa-circle pro-label-text",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/aloai-voice-analytics-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/aloai-voice-analytics-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("AloAi Voice Analytics"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldShowBroadcast
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Broadcast" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-label" }, [
                                !_vm.sidebar_folded
                                  ? _c(
                                      "b",
                                      { staticClass: "label pro-label" },
                                      [_vm._v("Pro")]
                                    )
                                  : _c("b", {
                                      staticClass:
                                        "fa fa-circle pro-label-text",
                                    }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-broadcast-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-broadcast-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Broadcast"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldSeeSequences
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Sequences 2" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-label" }, [
                                !_vm.sidebar_folded
                                  ? _c(
                                      "b",
                                      { staticClass: "label pro-label" },
                                      [_vm._v("Pro")]
                                    )
                                  : _c("b", {
                                      staticClass:
                                        "fa fa-circle pro-label-text",
                                    }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-workflow-grey.svg",
                                    width: "24",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-workflow-white.svg",
                                    width: "24",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Sequences+"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo("list report")
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Reports" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-reports-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-reports-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Reports"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo("list insights")
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Insights" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-insights-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-insights-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Insights"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo(
                      "change integration settings company"
                    ) &&
                    _vm.current_company &&
                    _vm.current_company.reseller_id !== 357
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Integrations" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-integrations-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-integrations-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Integrations"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.current_company &&
                    _vm.hasPermissionTo("update company") &&
                    _vm.auth.user.profile
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Account" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-company-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-company-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Account"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canSeeDealerProfile
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "Dealer Profile" },
                              tag: "li",
                              "exact-active-class":
                                "active green-gradient rounded",
                              "active-class": "active green-gradient rounded",
                            },
                          },
                          [
                            _c("a", [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-car-grey.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-icon active" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-car-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Dealer Profile"),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "h-2x my-4" }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }