<template>
    <div ref="transcription_reporting_panel">
        <communication-textual-filters></communication-textual-filters>

        <transcription-reporting-components
            :campaign_id="campaign_id"
            :user_id="user_id"
            :ring_group_id="ring_group_id"
            :broadcast_id="broadcast_id"
            v-if="companyHasTranscriptionEnabled"
        />

        <div class="row justify-content-center"
            v-else>

            <div class="col-8">
                <img src="/assets/images/transcriptions-demo-page.svg" 
                     data-testid="transcription-reporting-panel-img" />
            </div>

            <div class="col-12 text-center mt-5">
                <h5 class="text-dark">
                    <a class="text-primary"
                        href="https://support.aloware.com/en/articles/9037579-aloware-s-smart-transcription"
                        data-testid="transcription-reporting-click-here-link"
                        target="_blank">
                        Click here 
                    </a>
                    for more information. To start your free trial click on the "Request Free Trial" button.
                </h5>
                <el-button
                    class="mt-3"
                    type="success"
                    size="large"
                    data-testid="transcription-reporting-request-free-trial-button"
                    @click="requestFreeTrial()">
                    Request Free Trial
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import auth from '../auth'
import TranscriptionReportingComponents from "./transcription-reporting-components.vue";

export default {
    props: {
        campaign_id: {
            required: false,
        },
        user_id: {
            required: false,
        },
        ring_group_id: {
            required: false,
        },
        broadcast_id: {
            required: false,
        }
    },
    components: {
        "transcription-reporting-components": TranscriptionReportingComponents,
    },
    data() {
        return {
            auth,
        }
    },
    computed: {
        companyHasTranscriptionEnabled() {
            return this.current_company.transcription_enabled
        },
        ...mapState('cache', ['current_company']),
    },
    methods: {
        requestFreeTrial() {
            this.auth.user.profile.has_requested_transcriptions = true;
            axios.post(`/api/v1/transcription/request`)
                .then((res) => {
                    // Notification when requested for the first time.
                    this.$notify({
                        offset: 95,
                        title: 'Smart Transcription',
                        message: res.data.message,
                        type: 'success',
                    })
                }).catch((err) => {
                    this.$notify({
                        offset: 95,
                        title: 'Smart Transcription',
                        message: 'Whoops! Something went wrong.',
                        type: 'error',
                    })
                    this.auth.user.profile.has_requested_transcriptions = false;
                    console.log(err)
                })
        }
    }
};
</script>