var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.communication
    ? _c(
        "div",
        { staticClass: "app-body", attrs: { id: "view" } },
        [
          _vm.hasPermissionTo(["view communication"])
            ? _c("div", { staticClass: "header-padding header-info" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-4 col-md-6 col-12" }, [
                    _c("div", { staticClass: "box b-a no-effect" }, [
                      _c("div", { staticClass: "box-header" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row px-3 justify-content-between",
                          },
                          [
                            _vm._m(0),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "width-200 fixed" },
                              [
                                _vm.hasPermissionTo("archive communication")
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "opaq pull-right",
                                        staticStyle: {
                                          padding: "7px 12px",
                                          "border-radius": "8px",
                                          "font-size": "15px !important",
                                        },
                                        attrs: {
                                          type: "danger",
                                          loading: _vm.loading_archive,
                                          disabled: _vm.loading_archive,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.archiveCommunication(
                                              _vm.communication_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Archive\n                                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "pl-3 pr-3" }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "box-divider m-a-0" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "box-body" },
                        [
                          _c("communication-info", {
                            attrs: {
                              communication: _vm.communication,
                              verbose: true,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.communication &&
                  _vm.communication.type == _vm.CommunicationTypes.CALL
                    ? _c("div", { staticClass: "col-lg-8 col-md-6 col-12" }, [
                        _c("div", { staticClass: "box b-a no-effect" }, [
                          _c(
                            "div",
                            { staticClass: "box-body" },
                            [
                              _c(
                                "el-tabs",
                                {
                                  staticClass: "pl-2 pr-2",
                                  model: {
                                    value: _vm.tab_name,
                                    callback: function ($$v) {
                                      _vm.tab_name = $$v
                                    },
                                    expression: "tab_name",
                                  },
                                },
                                [
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "Ring Group Snapshot",
                                        name: "ring_group_snapshot",
                                      },
                                    },
                                    [
                                      _vm.showRingGroupSnapShot
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-center text-md _400",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _vm._v(
                                                      "This is a snapshot of the ring group at the point when this call arrived"
                                                    ),
                                                    _vm.communication.metadata
                                                      .ring_group_snapshot
                                                      .length > 0 &&
                                                    _vm.communication.metadata
                                                      .ring_group_snapshot[0]
                                                      .created_at
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "fixCommunicationDateTime"
                                                                )(
                                                                  _vm
                                                                    .communication
                                                                    .metadata
                                                                    .ring_group_snapshot[0]
                                                                    .created_at
                                                                )
                                                              )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v("."),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("ring-group-routing-display", {
                                              attrs: {
                                                ring_group: _vm.ringGroup,
                                                layers:
                                                  _vm.communication.metadata
                                                    .ring_group_snapshot,
                                              },
                                            }),
                                          ]
                                        : [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-center text-md _400",
                                              },
                                              [
                                                _vm.communication
                                                  .disposition_status2 ===
                                                _vm
                                                  .CommunicationDispositionStatus
                                                  .DISPOSITION_STATUS_ABANDONED_NEW
                                                  ? _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          "\n                                            Abandoned calls don't have a ring group snapshot.\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              !_vm.ringGroup
                                                                ? "This call does not have a ring group."
                                                                : "This feature is not yet available on your account."
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "box border no-effect mt-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "box-body" },
                              [
                                _c(
                                  "h5",
                                  { staticClass: "text-dark mx-4 my-3" },
                                  [_vm._v("Call Quality")]
                                ),
                                _vm._v(" "),
                                _vm.showNetworkLogs
                                  ? [
                                      _c("network-logs-display", {
                                        attrs: {
                                          callIssues:
                                            _vm.communication
                                              .call_quality_events,
                                          user: _vm.communication.user,
                                        },
                                      }),
                                    ]
                                  : [_vm._m(1)],
                              ],
                              2
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-backtop", {
            ref: "backtop",
            attrs: { target: "html", bottom: 20, right: 20 },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("b", [_vm._v("Communication Info")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex align-items-center _600 border-top px-4 pt-3" },
      [
        _c("img", {
          staticClass: "mr-2",
          attrs: {
            src: "/assets/images/app-icons/icon-check-circle-green.svg",
          },
        }),
        _vm._v(
          "\n                                No call quality issues detected\n                            "
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }