var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row nav-padding" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        {
          staticClass:
            "filter-header d-flex align-items-center justify-content-between",
          class: { "text-muted": _vm.short },
        },
        [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          (_vm.template_scope == "user" && _vm.canCreatePersonalTemplate) ||
          (_vm.template_scope == "company" && _vm.isAdmin)
            ? _c("template-dialog", {
                ref: "templateDialog",
                staticClass: "pull-right",
                attrs: {
                  template_scope: _vm.template_scope,
                  template_id: _vm.template_id,
                  short: _vm.short,
                  user_id: _vm.user_id,
                },
                on: { clearEdit: _vm.clearEdit },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.short
        ? _c("div", { staticClass: "dropdown-divider no-overflow" })
        : _vm._e(),
      _vm._v(" "),
      _vm.short
        ? _c(
            "ul",
            { staticClass: "list inset" },
            [
              _vm._l(_vm.filteredTemplates, function (template) {
                return _c(
                  "li",
                  { key: template.id, staticClass: "filters pb-2 pt-2" },
                  [
                    _c("div", [_c("span", [_vm._v(_vm._s(template.name))])]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pull-right mt--1" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.mode == "return" ? "Use" : "Copy",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-xs btn-icon",
                                attrs: {
                                  href: "#",
                                  "data-testid": "template-content-copy-link",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.copyTemplate(template.body)
                                  },
                                },
                              },
                              [
                                _vm.mode == "return"
                                  ? _c("i", {
                                      staticClass: "fa fa-plus-square",
                                    })
                                  : _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("content_copy"),
                                    ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "View",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-popover",
                              {
                                staticClass: "p-0",
                                attrs: {
                                  placement: "bottom-end",
                                  width: "350",
                                  trigger: "click",
                                },
                              },
                              [
                                _c("small", [_vm._v(_vm._s(template.body))]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-xs btn-icon",
                                    attrs: {
                                      slot: "reference",
                                      href: "#",
                                      "data-testid": "template-visibility-link",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("visibility"),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.canEdit(template)
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Edit",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-xs btn-icon",
                                    attrs: {
                                      href: "#",
                                      "data-testid": "template-edit-link",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.editTemplate(template.id)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-edit" })]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canDelete(template)
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Delete",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-xs btn-icon",
                                    attrs: {
                                      href: "#",
                                      "data-testid": "template-delete-link",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.deleteTemplate(template)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("delete"),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              !_vm.filteredTemplates.length
                ? _c("li", { staticClass: "filters pb-2 pt-2 text-center" }, [
                    _c("strong", [_vm._v("No Template")]),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.short
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticClass: "w-full",
                  attrs: {
                    data: _vm.filteredTemplates,
                    "default-sort": { prop: "id", order: "ascending" },
                    fit: "",
                    stripe: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "Id", width: "60" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", { staticClass: "text-greyish" }, [
                                _vm._v(_vm._s(scope.row.id)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2427425926
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "Name", width: "150" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Body", "min-width": "300" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$options.filters.fixHtml(
                                      _vm.$options.filters.truncate(
                                        scope.row.body,
                                        100
                                      )
                                    )
                                  ),
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1207799690
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "150", align: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-dropdown",
                                {
                                  attrs: { trigger: "click" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "dropdown",
                                        fn: function () {
                                          return [
                                            _c(
                                              "el-dropdown-menu",
                                              [
                                                _vm.canEdit(scope.row)
                                                  ? _c(
                                                      "el-dropdown-item",
                                                      {
                                                        attrs: {
                                                          "data-testid":
                                                            "can-edit-dropdown",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editTemplate(
                                                              scope.row.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-edit",
                                                        }),
                                                        _vm._v(
                                                          "\n                                        Edit\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.canDelete(scope.row)
                                                  ? _c(
                                                      "el-dropdown-item",
                                                      {
                                                        attrs: {
                                                          "data-testid":
                                                            "can-delete-dropdown",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteTemplate(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm.loading_archive_template
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-spin fa-spinner",
                                                            })
                                                          : _c("i", {
                                                              staticClass:
                                                                "fa fa-trash",
                                                            }),
                                                        _vm._v(
                                                          "\n                                        Delete\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { size: "small" } },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-ellipsis-v",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4125780237
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }