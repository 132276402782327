var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input-field", {
    attrs: {
      label: "Create a Password",
      placeholder: "Type here",
      "is-password": "",
      "padding-classes": _vm.passingClasses,
      "col-span": _vm.colSpan,
    },
    on: { input: _vm.onInput },
    scopedSlots: _vm._u(
      [
        _vm.password
          ? {
              key: "hint",
              fn: function () {
                return [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "text-left pl-2 text-weight-regular password-hint",
                    },
                    [
                      _c(
                        "li",
                        [
                          _c("el-icon", {
                            staticClass: "mr-xs",
                            class: _vm.getPasswordRuleClass(
                              _vm.validatePasswordLength
                            ),
                            attrs: {
                              name: _vm.iconForValidation(
                                _vm.validatePasswordLength
                              ),
                            },
                          }),
                          _vm._v(
                            "\n                8 characters long\n            "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("el-icon", {
                            staticClass: "mr-xs",
                            class: _vm.getPasswordRuleClass(
                              _vm.validatePasswordCases
                            ),
                            attrs: {
                              name: _vm.iconForValidation(
                                _vm.validatePasswordCases
                              ),
                            },
                          }),
                          _vm._v(
                            "\n                Must contain upper and lower case letters\n            "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c("el-icon", {
                            staticClass: "mr-xs",
                            class: _vm.getPasswordRuleClass(
                              _vm.validatePasswordDigit
                            ),
                            attrs: {
                              name: _vm.iconForValidation(
                                _vm.validatePasswordDigit
                              ),
                            },
                          }),
                          _vm._v(
                            "\n                Include at least one numerical digit\n            "
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v
      },
      expression: "password",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }