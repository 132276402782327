var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "row nav-padding",
    },
    [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "d-flex mb-3" }, [
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c("el-button", { on: { click: _vm.fetchImports } }, [
                _c("i", { staticClass: "fa fa-refresh" }),
                _vm._v(" Refresh\n                "),
              ]),
              _vm._v(" "),
              _vm.type === _vm.ImportType.TYPE_CONTACTS
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "popover",
                          rawName: "v-popover:import-contacts",
                          arg: "import-contacts",
                        },
                      ],
                      attrs: { plain: "", type: "success" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.startImportWizard()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-file-import" }),
                      _vm._v(
                        "\n                    Import from CSV\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      !_vm.loading && _vm.type === _vm.ImportType.TYPE_CONTACTS
        ? _c("al-alert", { staticClass: "mx-auto" }, [
            _c("span", {
              staticClass: "mr-5 text-dark",
              domProps: { innerHTML: _vm._s(_vm.TAGS_DEPRECATION_MESSAGE) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 el-tabs--border-card" },
        [
          _vm.type === _vm.ImportType.TYPE_CONTACTS
            ? _c(
                "el-table",
                { staticClass: "w-full", attrs: { data: _vm.imports } },
                [
                  _c("el-table-column", {
                    attrs: { label: "File Imported", width: "250px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    content: "Click to download",
                                    effect: "dark",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    { attrs: { href: scope.row.download_url } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "media text-success" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "media-body" },
                                            [
                                              _c("strong", [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-file-csv mr-1",
                                                }),
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f("strLimit")(
                                                        scope.row.uploaded_file
                                                          .original_file,
                                                        25
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                            ID: " +
                                          _vm._s(scope.row.id) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2637233904
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Imported By" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", {}, [
                                _c("h6", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.created_by != null
                                        ? scope.row.created_by.name
                                        : "-"
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("momentFormat")(
                                        scope.row.created_at,
                                        "MMM D, YYYY h:mma z",
                                        true
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      312210508
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Tags" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return _vm._l(scope.row.tags, function (tag) {
                              return _c(
                                "div",
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        content: tag.name,
                                        effect: "dark",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "Contacts",
                                              query: { tag_id: tag.id },
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { style: "color:" + tag.color },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-tag",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("strLimit")(
                                                      tag.name,
                                                      40
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      2563713055
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "List" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    scope.row.contact_list != null
                                      ? scope.row.contact_list.name
                                      : "-"
                                  ) +
                                  "\n                "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3048256251
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Line" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    scope.row.campaign != null
                                      ? scope.row.campaign.name
                                      : "-"
                                  ) +
                                  "\n                "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      448837659
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Assigned To" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "media" }, [
                                scope.row.assign_contacts_to ===
                                _vm.ImportAssignContactsTo
                                  .ASSIGN_CONTACTS_TO_USER
                                  ? _c("span", [
                                      _c("h6", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.user != null
                                              ? scope.row.user.name
                                              : "-"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      scope.row.user != null
                                        ? _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                "\n                                User\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.assign_contacts_to ===
                                _vm.ImportAssignContactsTo
                                  .ASSIGN_CONTACTS_TO_RING_GROUP
                                  ? _c("span", [
                                      _c("h6", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.ring_group != null
                                              ? scope.row.ring_group.name
                                              : "-"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      scope.row.ring_group != null
                                        ? _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                "\n                                Ring Group\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2775018818
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Rows" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    scope.row.rows > 0 ? scope.row.rows : "..."
                                  ) +
                                  "\n                "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1077298392
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Imported" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(scope.row.imported) +
                                  "\n                    "
                              ),
                              scope.row.cascade_contacts
                                ? _c("span", [_vm._v("(Multiple)")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      504913006
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Updated" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(scope.row.updated) +
                                  "\n                "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1952030984
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Failed" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.failed > 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass:
                                        "text-danger text-decoration-underline",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImportLogs(
                                            scope.row,
                                            _vm.ImportLogFilterTypes
                                              .FILTER_FAILED
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(scope.row.failed) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(scope.row.failed)),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2519263900
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Skipped" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.ignored > 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass:
                                        "text-warning text-decoration-underline",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImportLogs(
                                            scope.row,
                                            _vm.ImportLogFilterTypes
                                              .FILTER_SKIPPED
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(scope.row.ignored) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(scope.row.ignored)),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3682956609
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Status" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status === _vm.ImportStatus.STATUS_DRAFT
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        "import-status-" +
                                        _vm.ImportStatus.STATUS_DRAFT,
                                    },
                                    [
                                      _vm._v(
                                        "\n                        Draft\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.status ===
                              _vm.ImportStatus.STATUS_QUEUED
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        "import-status-" +
                                        _vm.ImportStatus.STATUS_QUEUED,
                                    },
                                    [
                                      _vm._v(
                                        "\n                        Queued\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.status ===
                              _vm.ImportStatus.STATUS_IN_PROGRESS
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        "import-status-" +
                                        _vm.ImportStatus.STATUS_IN_PROGRESS,
                                    },
                                    [
                                      _vm._v(
                                        "\n                        In Progress\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.status ===
                              _vm.ImportStatus.STATUS_FINISHED
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        "import-status-" +
                                        _vm.ImportStatus.STATUS_FINISHED,
                                    },
                                    [
                                      _vm._v(
                                        "\n                        Finished\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.status ===
                              _vm.ImportStatus.STATUS_FAILED
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        "import-status-" +
                                        _vm.ImportStatus.STATUS_FAILED,
                                    },
                                    [
                                      _vm._v(
                                        "\n                        Failed\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.status ===
                              _vm.ImportStatus.STATUS_STOPPED
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        "import-status-" +
                                        _vm.ImportStatus.STATUS_STOPPED,
                                    },
                                    [
                                      _vm._v(
                                        "\n                        Stopped\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2904639394
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { padding: "0", width: "60px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-dropdown",
                                {
                                  attrs: { trigger: "click" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "dropdown",
                                        fn: function () {
                                          return [
                                            _c(
                                              "el-dropdown-menu",
                                              [
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: {
                                                      disabled: !_vm.isRunnable(
                                                        scope.row
                                                      ),
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.rerunImport(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-file-import",
                                                    }),
                                                    _vm._v(
                                                      "\n                                    Run Import\n                                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.showImportLogs(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-exclamation-triangle",
                                                    }),
                                                    _vm._v(
                                                      "\n                                    Show Import Logs\n                                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.deleteImport(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "far fa-trash-alt",
                                                    }),
                                                    _vm._v(
                                                      "\n                                    Delete\n                                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                [
                                                  _vm.ImportStatus
                                                    .STATUS_IN_PROGRESS,
                                                  _vm.ImportStatus
                                                    .STATUS_QUEUED,
                                                ].includes(scope.row.status)
                                                  ? _c(
                                                      "el-dropdown-item",
                                                      {
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.disable(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "strong",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-remove",
                                                            }),
                                                            _vm._v(" Stop"),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { size: "small" } },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-ellipsis-v",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4214911429
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "py-4 clearfix" },
            [
              _c("el-pagination", {
                staticClass: "pull-right",
                attrs: {
                  "current-page": _vm.pager.current_page,
                  "hide-on-single-page": false,
                  "page-count": _vm.pager.last_page,
                  "page-size": parseInt(_vm.pager.per_page),
                  "page-sizes": [10, 20, 30, 50],
                  total: _vm.pager.total,
                  background: "",
                  layout: "sizes, prev, pager, next",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pager, "current_page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pager, "current_page", $event)
                  },
                  "current-change": _vm.fetchImports,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("import-wizard", {
            ref: "importWizard",
            on: { onClose: _vm.fetchImports },
          }),
          _vm._v(" "),
          _c("import-logs", {
            ref: "importLogs",
            attrs: { import_contact: _vm.selected_import },
            on: { "close-dialog": _vm.closeImportLogDialog },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }