<template>
    <el-popover
        :placement="placement"
        trigger="hover">
        <div
            v-if="workflow"
            slot="reference"
            class="cursor-pointer d-inline-block _400">
            <div
                v-if="workflow.real_status === Workflow.CURRENT_STATUS_INVALID">
                <i class="fa fa-exclamation-triangle text-danger mr-1"></i>
                <span v-if="is_long_message">This sequence is invalid and will not process enrolled contacts. </span>
                <span v-else>Invalid</span>

                <span class="badge badge-pill badge-danger">{{ failed_diagnosis_count }}</span>
            </div>
            <div v-else>
                <div v-if="workflow.active">
                    <div
                        v-if="workflow.real_status === Workflow.CURRENT_STATUS_RUNNING || workflow.real_status === Workflow.CURRENT_STATUS_PENDING">
                        <i class="fa fa-play-circle text-success mr-1"></i>
                        <span v-if="is_long_message">This sequence is active and running.</span>
                        <span v-else>Running</span>
                    </div>
                </div>
                <div v-else>
                    <i class="fa fa-pause-circle text-warning mr-1"></i>
                    <span v-if="is_long_message">This sequence is paused.</span>
                    <span v-else>Paused</span>
                </div>
            </div>
        </div>

        <div class="p-2">
            <h6 class="text-dark mb-2">Diagnosis</h6>
            <table>
                <tr
                    v-for="diagnosis in workflow.diagnosis"
                    :key="diagnosis.name">
                    <td class="pr-2">
                        <i v-if="diagnosis.value" class="fa fa-check text-success"></i>
                        <i v-if="!diagnosis.value" class="fa fa-remove text-danger"></i>
                    </td>
                    <td>
                        {{ diagnosis.name }}
                    </td>
                </tr>
            </table>
        </div>
    </el-popover>
</template>

<script>
import * as Workflow from '../../constants/workflow'

export default {
    name: "workflow-status",

    props: {
        workflow: {
            required: true,
            type: Object
        },

        is_long_message: {
            default: false
        },

        placement: {
            type: String,
            default: 'left-start'
        }
    },

    computed: {
        failed_diagnosis_count: function() {
            return _.filter(this.workflow.diagnosis, {value: false}).length
        }
    },

    data() {
        return {
            Workflow
        }
    }
}
</script>
