var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list user")
    ? _c("div", [
        !_vm.show_only
          ? _c(
              "div",
              [
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    style: "width: " + _vm.width,
                    attrs: {
                      multiple: _vm.multiple,
                      disabled:
                        _vm.disabled ||
                        (_vm.auth.user.profile.focus_mode &&
                          _vm.user_id !== null &&
                          !_vm.ignore_focus_mode),
                      "filter-method": _vm.filterOptions,
                      "default-first-option": "",
                      filterable: "",
                      clearable: "",
                      placeholder: _vm.placeholder,
                      size: _vm.size,
                      "collapse-tags": _vm.collapseTags,
                      "data-testid": "contact-details-owner-selector",
                      autocomplete: "off",
                    },
                    on: {
                      clear: _vm.emitClearEvent,
                      "visible-change": _vm.emitVisibleChangeEvent,
                      "remove-tag": _vm.emitRemoveTagEvent,
                    },
                    model: {
                      value: _vm.user_id,
                      callback: function ($$v) {
                        _vm.user_id = $$v
                      },
                      expression: "user_id",
                    },
                  },
                  [
                    _vm.normalUsers && _vm.normalUsers.length > 0
                      ? _c(
                          "el-option-group",
                          { key: "Users", attrs: { label: "Users" } },
                          _vm._l(_vm.normalUsers, function (user) {
                            return _c(
                              "el-option",
                              {
                                key: user.id,
                                attrs: { label: user.name, value: user.id },
                              },
                              [
                                _c("div", { staticClass: "media" }, [
                                  _c("div", { staticClass: "media-body" }, [
                                    _c("label", [_vm._v(_vm._s(user.name))]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        _vm._s(user.email) +
                                          " - " +
                                          _vm._s(_vm.getLabel(user))
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.hide_extensions &&
                    _vm.extensionUsers &&
                    _vm.extensionUsers.length > 0
                      ? _c(
                          "el-option-group",
                          { key: "Extensions", attrs: { label: "Extensions" } },
                          _vm._l(_vm.extensionUsers, function (user) {
                            return _c(
                              "el-option",
                              {
                                key: user.id,
                                attrs: { label: user.name, value: user.id },
                              },
                              [
                                _c("div", { staticClass: "media" }, [
                                  _c("div", { staticClass: "media-body" }, [
                                    _c("label", [_vm._v(_vm._s(user.name))]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(_vm._s(_vm.getLabel(user))),
                                    ]),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.show_only && _vm.selectedUser
          ? _c("div", [_c("span", [_vm._v(_vm._s(_vm.selectedUser.name))])])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }