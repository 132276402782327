import Vue from 'vue'
import VueMasonryGallery from 'vue-masonry-gallery/vendor/VueMasonryGallery/VueMasonryGallery'

// app template components
Vue.component('app-wrapper', require('./components/app-wrapper.vue').default)
Vue.component('app-header', require('./components/app-header.vue').default)
Vue.component('app-footer', require('./components/app-footer.vue').default)
Vue.component('app-sidebar', require('./components/app-sidebar.vue').default)
Vue.component('welcome-to-aloware', require('./components/welcome-to-aloware.vue').default)
Vue.component('user-notifications', require('./components/user-notifications.vue').default)
Vue.component('static-assets-preloader', require('./components/static-assets-preloader.vue').default)

// auth components
Vue.component('banner', require('./components/account-registration/banner').default)
Vue.component('input-field', require('./components/account-registration/input-field').default)
Vue.component('input-group', require('./components/account-registration/input-group').default)
Vue.component('phone-number-field', require('./components/account-registration/phone-number-field').default)
Vue.component('select-field', require('./components/account-registration/select-field').default)
Vue.component('step-header', require('./components/account-registration/step-header').default)
Vue.component('user-already-have-account-dialog', require('./components/account-registration/user-already-have-account-dialog').default)
Vue.component('new-login', require('./components/new-login.vue').default)
Vue.component('new-forgotpass', require('./components/new-forgotpass.vue').default)
Vue.component('new-resetpass', require('./components/new-resetpass.vue').default)
Vue.component('security-code', require('./components/security-code.vue').default)
Vue.component('signup', require('./components/signup.vue').default)

// dashboard components
Vue.component('image-assets-preloader', require('./components/image-assets-preloader.vue').default)
Vue.component('comm-log-table', require('./components/comm-log-table.vue').default)
Vue.component('queue-log-table', require('./components/queue-log-table.vue').default)
Vue.component('live-log-table', require('./components/live-log-table.vue').default)
Vue.component('parked-log-table', require('./components/parked-log-table.vue').default)
Vue.component('activity-graph', require('./components/activity-graph.vue').default) // this is used for all reporting of activity items against time.
Vue.component('comm-advanced-filters', require('./components/comm-advanced-filters.vue').default)
Vue.component('comm-advanced-filters-wrapper', require('./components/comm-advanced-filters-wrapper.vue').default)
Vue.component('wallboard-summary-report', require('./components/wallboard-summary-report.vue').default)
Vue.component('new-summary-report', require('./components/new-summary-report.vue').default)
Vue.component('summary-report-card', require('./components/summary-report-card.vue').default)
Vue.component('summary-report-sms', require('./components/summary-report-sms.vue').default)
Vue.component('summary-report-unreturned', require('./components/summary-report-unreturned.vue').default)
Vue.component('summary-report-big-donut-card', require('./components/summary-report-big-donut-card.vue').default)

// campaign related components
Vue.component('campaign-wizard', require('./components/campaign-wizard.vue').default)
Vue.component('line-wizard', require('./components/line-wizard.vue').default)
Vue.component('line-details', require('./components/line-details.vue').default)
Vue.component('campaign-details', require('./components/campaign-details.vue').default)
Vue.component('line-add-number', require('./components/line-add-number.vue').default)
Vue.component('line-add-caller-id', require('./components/line-add-caller-id.vue').default)
Vue.component('campaign-selector', require('./components/campaign-selector.vue').default)

// integration components
Vue.component('integrations-list', require('./components/integrations-list.vue').default)
Vue.component('contact-lookup-api', require('./components/contact-lookup-api.vue').default)
Vue.component('integrations-lead-api', require('./components/integrations-lead-api.vue').default)
Vue.component('integrations-sequence-enroll-api', require('./components/integrations-sequence-enroll-api.vue').default)
Vue.component('integrations-sms-api', require('./components/integrations-sms-api.vue').default)
Vue.component('integrations-form-connect', require('./components/integrations-form-connect.vue').default)
Vue.component('integrations-number-lookup', require('./components/integrations-number-lookup.vue').default)
Vue.component('integrations-users-api', require('./components/integrations-users-api.vue').default)

// integration error components
Vue.component('hubspot-duplicate-contact-error', require('./components/integrations/hubspot/duplicate-contact-error').default)

// reports components
Vue.component('reports-tags-column-graph', require('./components/reports-tags-column-graph').default)
Vue.component('reports-campaign-tags-stackedcolumninverse-graph', require('./components/reports-campaign-tags-stackedcolumninverse-graph.vue').default)
Vue.component('reports-status-column-graph', require('./components/reports-status-column-graph').default)
Vue.component('reports-com-status-stackedcolumninverse-graph', require('./components/reports-com-status-stackedcolumninverse-graph.vue').default)
Vue.component('reports-overview-pie-graph', require('./components/reports-overview-pie-graph.vue').default)
Vue.component('reports-talktime-column-graph', require('./components/reports-talktime-column-graph').default)
Vue.component('reports-talktime-column-inverse-graph', require('./components/reports-talktime-column-inverse-graph').default)
Vue.component('reports-avg-talktime-column-graph', require('./components/reports-avg-talktime-column-graph').default)
Vue.component('reports-average-waittime-column-graph', require('./components/reports-average-waittime-column-graph').default)
Vue.component('reports-call-time-per-campaign-graph', require('./components/reports-call-time-per-campaign-graph.vue').default)
Vue.component('reports-incoming-number-location-graph', require('./components/reports-incoming-number-location-graph.vue').default)
Vue.component('reports-user-location-graph', require('./components/reports-user-location-graph.vue').default)
Vue.component('reports-caller-location-graph', require('./components/reports-caller-location-graph.vue').default)
Vue.component('reports-user-status-inverse-graph', require('./components/reports-user-status-inverse-graph.vue').default)
Vue.component('reports-user-tags-inverse-graph', require('./components/reports-user-tags-inverse-graph.vue').default)
Vue.component('reports-agent-status-graph', require('./components/reports-agent-status-graph.vue').default)
Vue.component('graph-data-switch', require('./components/graph-data-switch.vue').default)
Vue.component('reports-leads-intake-graph', require('./components/reports-leads-intake-graph.vue').default)
Vue.component('reports-leads-created-vs-dnc-graph', require('./components/reports-leads-created-vs-dnc-graph.vue').default)
Vue.component('reports-descriptions', require('./views/report/descriptions.vue').default)
Vue.component('reports-activity-reporting-tab', require('./components/reports-activity-reporting-tab.vue').default)
Vue.component('reports-activity-reporting-graph', require('./components/reports-activity-reporting-graph.vue').default)
Vue.component('reports-activity-reporting-contacts-graph', require('./components/reports-activity-reporting-contacts-graph.vue').default)
Vue.component('reports-activity-reporting-contacts-overview-graph', require('./components/reports-activity-reporting-contacts-overview-graph.vue').default)

// contacts components
Vue.component('activity', require('./components/activity.vue').default)
Vue.component('communication-info', require('./components/communication-info.vue').default)
Vue.component('webrtc', require('./components/webrtc.vue').default)
Vue.component('contact-group-selector', require('./components/contact-group-selector.vue').default)
Vue.component('contact-advanced-filters', require('./components/contact-advanced-filters.vue').default)
Vue.component('contact-advanced-filters-wrapper', require('./components/contact-advanced-filters-wrapper.vue').default)
Vue.component('contact-select', require('./components/contact-select.vue').default)
Vue.component('states-province-selector', require('./components/states-province-selector').default)
Vue.component('contact-tags-filters-selector', require('./components/contact-tags-filters-selector').default)
Vue.component('contact-disposition-selector', require('./components/contact-disposition-selector').default)
Vue.component('send-template', require('./components/send-template').default)

// communication components
Vue.component('communication-audio', require('./components/communication-audio.vue').default)
Vue.component('communication-note', require('./components/communication-note.vue').default)
Vue.component('communication-tags', require('./components/communication-tags.vue').default)
Vue.component('communication-textual-filters', require('./components/communication-textual-filters.vue').default)
Vue.component('communication-notification', require('./components/communication-notification.vue').default)
Vue.component('communication-transcription-button', require('./components/communication-transcription-button').default)

// user components
Vue.component('user-selector', require('./components/user-selector.vue').default)
Vue.component('ordered-user-team-selector', require('./components/ordered-user-team-selector.vue').default)
Vue.component('user-extension-wizard', require('./components/user-extension-wizard.vue').default)

// source selector component
Vue.component('source-selector', require('./components/source-selector.vue').default)

// tag component
Vue.component('tag-selector', require('./components/tag-selector.vue').default)

// disposition component
Vue.component('disposition-status-selector', require('./components/disposition-status-selector.vue').default)
Vue.component('call-disposition-selector', require('./components/call-disposition-selector.vue').default)
Vue.component('call-disposition-creator', require('./components/call-disposition-creator.vue').default)

// fax component
Vue.component('new-fax', require('./components/new-fax.vue').default)

// workflow components
Vue.component('workflow-selector', require('./components/workflow-selector.vue').default)

// broadcast components
Vue.component('broadcast-selector', require('./components/broadcast-selector.vue').default)
Vue.component('broadcast-schedule', require('./views/broadcast/broadcast-schedule').default)

// HubSpot components
Vue.component('hubspot-list-selector', require('./components/hubspot-list-selector.vue').default)
Vue.component('hubspot-import-list', require('./components/hubspot-import-list.vue').default)

// Zoho components
Vue.component('zoho-view-selector', require('./components/zoho-view-selector.vue').default)
Vue.component('zoho-import-view', require('./components/zoho-import-view.vue').default)

// Pipedrive components
Vue.component('pipedrive-filter-selector', require('./components/pipedrive-filter-selector.vue').default)

// other components
Vue.component('vue-masonry-gallery', VueMasonryGallery)
Vue.component('audio-recorder', require('./components/audio-recorder.vue').default)
Vue.component('country-selector', require('./components/country-selector.vue').default)
Vue.component('terms-and-conditions', require('./components/terms-and-conditions.vue').default)
Vue.component('intercom', require('./components/intercom.vue').default)
Vue.component('default-app-selector', require('./components/default-app-selector').default)
Vue.component('force-talk-dialog', require('./components/force-talk-dialog').default)

// account components
Vue.component('account-billing', require('./components/account-billing').default)
Vue.component('account-international-pricing', require('./components/account-international-pricing').default)
Vue.component('account-exports', require('./components/account-exports').default)
Vue.component('account-script', require('./components/account-script').default)
Vue.component('account-contact-dispositions', require('./components/account-contact-dispositions').default)
Vue.component('account-call-dispositions', require('./components/account-call-dispositions').default)
Vue.component('lead-sources-list', require('./components/account/lead-sources/lead-sources-list').default)


// Widgets
Vue.component('time-range-picker', require('./components/misc/time-range-picker').default)
Vue.component('relative-datetime', require('./components/misc/relative-datetime').default)
Vue.component('phone', require('./components/utilities/phone').default)

// Customer Issue Report Form
Vue.component('customer-report-issue-form', require('./components/forms/customer-report-issue-form').default)

// Power Dialer Session Settings
Vue.component('power-dialer-session-setting', require('./views/power-dialer/settings/power-dialer-session-setting').default)
Vue.component('power-dialer-session-setting-form', require('./views/power-dialer/settings/power-dialer-session-setting-form').default)
