<template>
    <el-form-item :prop="id">
        <span class="page-anchor" :id="id"></span>

        <div class="form-label">
            <h5>{{ title }}</h5>

            <small><slot></slot></small>
        </div>

        <el-select class="w-full"
                   v-model="selected"
                   @change="$emit('change', selected)">
            <el-option v-for="(value, label) in options"
                       :key="value"
                       :value="value"
                       :label="label"></el-option>
        </el-select>
    </el-form-item>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },

        props: {
            id: {
                type: String,
                required: true
            },

            title: {
                type: String,
                required: true
            },

            options: {
                type: Object,
                required: true
            },

            value: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                selected: this.value
            }
        }
    }
</script>
