<template>
    <div v-if="hubspotEnabled">
        <el-dialog :title="title"
                   class="dialog-padding auto"
                   :width="isLargeEnough ? '500px' : '100%'"
                   :visible.sync="hide_add"
                   :before-close="beforeCloseModal"
                   append-to-body>
            <el-form ref="import_hubspot_list"
                     class="mt-2 p-0"
                     label-position="top"
                     :rules="rules_hubspot_list"
                     :model="hubspot_list"
                     @submit.prevent.native="importHubspotList">
                <el-form-item prop="list_id"
                              label="Select a HubSpot list"
                              class="mb-3">
                    <hubspot-list-selector v-model="hubspot_list.list_id"
                                           ref="hubspotListSelector"
                                           :value="hubspot_list.list_id"
                                           @change="hubspotListChanged">
                    </hubspot-list-selector>
                </el-form-item>
            </el-form>

            <div class="row pb-3">
                <div class="col-12">
                    <el-button type="success"
                               :disabled="loading_btn || !validated"
                               class="pull-right ml-2"
                               @click="importHubspotList">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="fa fa-plus"
                           v-show="!loading_btn"></i>
                        <span>Import</span>
                    </el-button>

                    <el-button type="info"
                               class="pull-right"
                               @click="hide_add = false">
                        <span class="fa fa-close"></span>
                        <span>Cancel</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <slot name="trigger">
            <el-button slot="reference"
                       type="text text-sm text-dark-greenish"
                       @click="hide_add = true">
                <i class="el-icon-circle-plus-outline"></i>
                Import HubSpot List
            </el-button>
        </slot>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../auth'
import {
    acl_mixin,
    form_validation_mixin
} from '../mixins'

export default {
    mixins: [
        acl_mixin,
        form_validation_mixin
    ],

    props: {
        title: {
            required: false,
            type: String,
            default: 'Import HubSpot List'
        },
    },

    data() {
        return {
            auth: auth,
            hide_add: false,
            loading_btn: false,
            hubspot_list: {
                list_id: null
            },
            rules_hubspot_list: {
                list_id: [
                    {
                        required: true,
                        message: 'Please select a HubSpot list',
                        trigger: 'change'
                    }
                ]
            }
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        hubspotEnabled() {
            if (this.current_company &&
                this.current_company.hubspot_integration_enabled) {
                return true
            }

            return false
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        }
    },

    methods: {

        importHubspotList() {
            this.loading_btn = true
            axios.post(`/api/v1/tag/import-hubspot-list/${this.hubspot_list.list_id}`).then(() => {
                this.loading_btn = false
                this.hide_add = false
                this.resetHubspotList()
                this.$notify({
                    offset: 150,
                    title: 'Tag',
                    message: 'HubSpot contact list is now being imported as a tag.',
                    type: 'success',
                    showClose: true,
                })
                this.$emit('success')
            }).catch((err) => {
                this.loading_btn = false
                this.resetHubspotList()
                console.log(err)
            })
        },

        resetHubspotList() {
            this.hubspot_list.list_id = null
            this.resetForm('import_hubspot_list')
        },

        beforeCloseModal(done) {
            this.resetHubspotList()
            done()
        },

        hubspotListChanged(list_id) {
            this.hubspot_list.list_id = list_id
            this.preValidateForm('import_hubspot_list')
        },
    },

    watch: {
        hide_add() {
            if (this.hide_add) {
                this.$emit('hide')
            } else {
                this.$emit('show')
                if (this.$refs.hubspotListSelector) {
                    this.$refs.hubspotListSelector.getHubspotLists()
                }
            }
        }
    }
}
</script>
