<template>
    <div>
        <el-dialog title="Import Wizard"
                   width="70vw"
                   class="import-wizard dialog-no-border"
                   top="4vh"
                   :visible.sync="importDialogVisible"
                   :append-to-body="true"
                   :close-on-click-modal="false"
                   :before-close="beforeClose">
            <div slot="title">
                <h5 class="mb-0">
                    <i class="fas fa-hat-wizard"></i>
                    Import Wizard
                </h5>
            </div>

            <el-steps
                align-center
                finish-status="success"
                class="mb-3"
                :active="currentStep">
                <el-step title="DNC Agreement"></el-step>
                <el-step title="Upload CSV"></el-step>
                <el-step title="Select Columns"></el-step>
                <el-step title="Review Data"></el-step>
                <el-step title="Settings"></el-step>
                <el-step title="Finish"></el-step>
            </el-steps>

            <div
                v-loading="loading"
                style="position: relative">
                <div
                    v-show="currentStep === 0"
                    class="step-0">
                    <div class="alert alert-success alert-aloware">
                        <div class="media">
                            <i class="fas fa-info-circle alert-icon"></i>
                            <div class="media-body lh-1">
                                <div>
                                    <el-checkbox
                                        data-testid="dnc-agreement-checkbox"
                                        v-model="is_dnc_agreed">
                                        <b>DNC Agreement</b> - You verify this list does not contain any phone numbers found in the United States DNC (Do Not Call) List or your own DNC records.
                                    </el-checkbox>

                                    <ul class="ml-4">
                                        <li>Organizations that contact phone numbers whose owners have requested DNC status may run the risk of legal action.</li>
                                        <li>
                                            To help avoid legal action, scrub all contact lists before you import them using a DNC Checking Service. {{ isSimpSocial ? 'SimpSocial' : 'Aloware' }} can
                                            assist you with this -
                                            <el-button
                                                data-testid="request-more-details-button"
                                                v-if="!is_dnc_scrubbing_requested"
                                                v-loading="requesting_dnc_scrubbing"
                                                @click="requestDncScrubbingDetails"
                                                type="text"
                                                class="text-decoration-underline pb-0 pt-0">
                                                <span>Request more details</span>
                                            </el-button>
                                            <span
                                                v-else
                                                class="text-success">
                                                DNC Scrubbing Request Has Been Sent
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="text-center dnc-info-container">
                        <h4>You must agree to the DNC Agreement above before importing.</h4>

                        <p class="mb-0 mt-5">
                            Want to test, but don't have a clean list?
                        </p>
                        <p>
                            Agree to the terms above, download, and then upload this example list to test import process.
                        </p>

                        <el-link
                            data-testid="download-contacts-template-step-1"
                            type="success"
                            target="_blank"
                            :underline="false"
                            class="text-md mt-2 a-hover"
                            href="/templates/Contacts-Template.csv">
                            <i class="fa fa-cloud-download"></i>
                            Download Contacts Template
                        </el-link>
                    </div>
                </div>
                <div v-show="currentStep === 1"
                     class="step-1">
                    <div class="container-template-download text-center">
                        <p>To get started with a file template, click link below.</p>
                        <el-link type="success"
                                 target="_blank"
                                 :underline="false"
                                 class="text-md mt-2 a-hover"
                                 data-testid="download-contacts-template-step-2"
                                 href="/templates/Contacts-Template.csv">
                            <i class="fa fa-cloud-download"></i>
                            Download Contacts Template
                        </el-link>
                    </div>
                    <el-upload action="/api/v1/import-wizard/parse"
                               ref="file_upload"
                               data-testid="upload-csv-file"
                               :auto-upload="true"
                               :headers="headers"
                               :multiple="false"
                               :on-success="renderColumnChooser"
                               :on-error="columnChooserFailed"
                               :show-file-list="false"
                               :before-upload="beforeUpload"
                               drag>
                        <div class="dz-label">
                            <i class="fas fa-file-csv"></i>
                            <p>Drop your CSV file here or</p>

                            <el-button type="success"
                                       class="mb-2"
                                       data-testid="upload-from-computer-button"
                                       v-if="!uploading">
                                <span>
                                    <i class="fa fa-upload"></i>
                                    Upload From Computer
                                </span>
                            </el-button>
                            <div v-if="uploadError.length > 0"
                                 class="text-danger"
                                 style="word-break: break-word">
                                <i class="fa fa-exclamation-circle"></i>
                                {{ uploadError }}
                            </div>
                        </div>
                    </el-upload>
                </div>
                <div v-show="currentStep === 2"
                     class="step-1">
                    <div class="mb-3">
                        <div class="alert alert-success alert-aloware">
                            <div class="media">
                                <i class="fas fa-info-circle alert-icon"></i>
                                <div class="media-body lh-1">
                                    <h6 class="mb-0">Instructions</h6>
                                    <p class="mb-0">
                                        We've fetched the first 10 rows in your file so you can assign each columns to
                                        its appropriate property. Take Note that at least one phone number
                                        (separated by comma if multiple) is required to move on to the next step.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-bordered table-csv table-striped">
                            <thead>
                            <tr>
                                <th></th>
                                <th v-for="(th, i) in tableHeader">
                                    <el-select v-model="tableHeaderValue[i]"
                                               placeholder="Select proper column type"
                                               class="select-table-header"
                                               filterable
                                               clearable
                                               :data-testid="'colum-type-selector-'+i"
                                               @change="tableHeaderChange">
                                        <el-option-group v-if="contact_fields.length"
                                                         v-for="cf in contact_fields"
                                                         :key="cf.group_name"
                                                         :label="cf.group_name">
                                            <el-option v-for="field in cf.fields"
                                                       :key="field.value"
                                                       :label="field.label"
                                                       :value="field.value"
                                                       :disabled="checkHeaderMap(field.value)">
                                            </el-option>
                                        </el-option-group>
                                    </el-select>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, index) in tableData"
                                v-show="!(noFirstColumn && index === 0)">
                                <td>
                                    {{ index>0 ? index+1 : '' }}
                                    <el-tooltip class="item"
                                                effect="dark"
                                                content="Remove this first row"
                                                placement="right">
                                        <el-button size="mini"
                                                   v-if="index === 0"
                                                   class="px-1"
                                                   data-testid="remove-first-row-button"
                                                   @click="noFirstColumn = true">
                                            <i class="fa fa-trash-o"></i>
                                        </el-button>
                                    </el-tooltip>
                                </td>
                                <td v-for="col in row">{{ col }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-if="currentStep === 3"
                     class="step-2">
                    <div class="alert alert-success alert-aloware">
                        <div class="media">
                            <i class="fas fa-info-circle alert-icon"></i>
                            <div class="media-body lh-1">
                                <h6 class="mb-0">Review Data</h6>
                                <p class="mb-0">
                                    We've fetched and analyzed the first 10 rows in your file and merged all phone
                                    numbers
                                    found in one column. Warnings and errors will show at the right most columns in the
                                    table below. Please review the partial rows before going to the next step.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="container-table-csv">
                        <table class="table table-bordered table-csv table-striped"
                               v-if="reviewData.length > 0">
                            <thead>
                            <tr>
                                <th></th>
                                <th v-for="title in Object.keys(reviewData[0])"
                                    v-if="title != 'unknown_columns'"
                                    :class="(title === 'warnings'?'cell-warning':'') + ' ' + (title === 'errors'?'cell-danger':'')">
                                    {{ fieldName(title) }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(contact, index) in reviewData">
                                <td>{{ index+1 }}</td>
                                <td v-for="key in Object.keys(contact)"
                                    v-if="key != 'unknown_columns'"
                                    :class="((key === 'warnings' && contact[key].length > 0) ? 'cell-warning' : '') + ' ' + ((key === 'errors' && contact[key].length > 0) ? 'cell-danger' : '')">
                                        <span v-if="key === 'phone_numbers'">
                                            {{ contact[key].join(', ') }}
                                        </span>
                                    <span v-else>
                                            {{ contact[key] }}
                                        </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-if="currentStep === 4"
                     class="step-3">
                    <el-form ref="form"
                             label-position="top"
                             :model="import_model"
                             @submit.prevent="">
                        
                        <!-- WAT-876: deprecation banner -->
                        <al-alert class="mx-auto mb-2"> 
                            <span class="mr-5 text-dark" 
                                  v-html="TAGS_DEPRECATION_IMPORT_CONTACTS_MESSAGE" />
                        </al-alert>

                        <div class="row">
                            <div class="col-md-6 pr-4">
                                <el-form-item v-if="!is_contact_list">
                                    <div scope="label"
                                         class="form-label">
                                        <label>Treat this as a DNC list</label>
                                        <small>
                                            <i class="fa fa-exclamation-triangle text-danger"></i>
                                            This will set imported contacts to DNC (Do Not Call) and is not reversible.
                                        </small>
                                    </div>
                                    <el-checkbox v-model="import_model.is_dnc"
                                                 label="Set all imported contacts on DNC"
                                                 class="w-full el-border-checkbox-danger"
                                                 data-testid="set-all-contacts-checkbox"
                                                 border>
                                    </el-checkbox>
                                </el-form-item>

                                <el-form-item v-if="!import_model.is_dnc">
                                    <div scope="label"
                                         class="form-label">
                                        <label>Update existing contacts</label>
                                        <small>
                                            Override the original existing contact data with what's in the CSV file?
                                        </small>
                                    </div>
                                    <el-checkbox v-model="import_model.update_existing"
                                                 label="Update existing contacts"
                                                 class="w-full"
                                                 data-testid="update-existing-contacts-checkbox"
                                                 border>
                                    </el-checkbox>
                                </el-form-item>

                                <el-form-item v-if="!import_model.is_dnc">
                                    <div scope="label"
                                         class="form-label">
                                        <label>Save unknown columns</label>
                                        <small>
                                            Columns that are not set will be saved to the contact's notes
                                        </small>
                                    </div>
                                    <el-checkbox v-model="import_model.unknown_columns_to_notes"
                                                 label="Save unknown columns to notes"
                                                 class="w-full"
                                                 data-testid="save-unkown-columns-checkbox"
                                                 border>
                                    </el-checkbox>
                                </el-form-item>

                                <el-form-item v-if="!import_model.is_dnc">
                                    <div scope="label"
                                         class="form-label">
                                        <label>Multiple contacts for each phone number</label>
                                        <small>
                                            Creates a separate contact for each phone number found in the file.
                                        </small>
                                    </div>
                                    <el-checkbox v-model="import_model.cascade_contacts"
                                                 label="Create a new contact for each contact’s phone number"
                                                 class="w-full"
                                                 data-testid="create-new-contact-checkbox"
                                                 border>
                                    </el-checkbox>
                                </el-form-item>

                                <el-form-item
                                    v-if="!import_model.is_dnc"
                                    :error="errors.first('campaign_id')">
                                    <div scope="label"
                                         class="form-label">
                                        <label>Line</label>
                                        <small>Select the line that you want to import these contacts to</small>
                                    </div>

                                    <el-select v-model="import_model.campaign_id"
                                               clearable
                                               filterable
                                               class="w-full"
                                               data-testid="line-selector-in-step-5"
                                               placeholder="Select line">
                                        <el-option v-for="campaign in sortedCampaigns"
                                                   :key="campaign.id"
                                                   :label="campaign.name"
                                                   :value="campaign.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <template v-if="is_contact_list">
                                    <el-form-item
                                        prop="name"
                                        :error="errors.first('name')"
                                        :rules="{required: true, message: 'You need to specify the contact list name', trigger: ['blur', 'change']}">
                                        <div scope="label"
                                             class="form-label">
                                            <label>Contact List Name</label>
                                            <small>This will be the contact list name where these contacts will be grouped</small>
                                        </div>
                                        <el-input
                                            v-model="import_model.name"
                                            clearable
                                            data-testid="my-contacts-input"
                                            placeholder="My contacts...">
                                        </el-input>
                                    </el-form-item>

                                    <el-form-item
                                        :error="errors.first('contact_folder_id')"
                                        prop="contact_folder_id">
                                        <div scope="label"
                                             class="form-label">
                                            <label>Contact Folder</label>
                                            <small>Select the folder where you want to place this list</small>
                                        </div>

                                        <el-select
                                            v-model="import_model.contact_folder_id"
                                            filterable
                                            clearable
                                            remote
                                            data-testid="search-a-folder-select"
                                            :remote-method="searchContactFolders"
                                            :loading="contact_folder_searching"
                                            :no-data-text="no_contact_folders_data"
                                            placeholder="Type to search for a folder"
                                            class="w-100">
                                            <el-option
                                                v-for="folder in contact_folders"
                                                :key="folder.id"
                                                :label="folder.name"
                                                :value="folder.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </div>
                            <div class="col-md-6">
                                <el-form-item v-if="!import_model.is_dnc">
                                    <div scope="label"
                                         class="form-label">
                                        <label>Assign contacts to</label>
                                        <small>Distribute the leads to:</small>
                                    </div>
                                    <el-tabs v-model="tab_name">
                                        <el-tab-pane label="User"
                                                     data-testid="user-tab"
                                                     name="user">
                                            <small>Assign the leads to this user</small>
                                            <user-selector ref="userSelector"
                                                           clearable
                                                           data-testid="assing-leads-user-tab-select"
                                                           v-model="import_model.user_id"
                                                           @change="changeUserId">
                                            </user-selector>
                                        </el-tab-pane>
                                        <el-tab-pane label="Ring Group"
                                                     data-testid="user-tab"
                                                     name="ring_group">
                                            <small>Assign the leads evenly and randomly between the users on this ring group</small>
                                            <ring-group-selector v-model="import_model.ring_group_id"
                                                                 data-testid="assing-leads-ring-group-tab-select"
                                                                 @change="ringGroupChange">
                                            </ring-group-selector>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-form-item>

                                <el-form-item v-if="!import_model.is_dnc">
                                    <div scope="label"
                                         class="form-label">
                                        <label>Sequence</label>
                                        <small>
                                            Select sequence that you want to enroll these contacts to
                                            <span class="text-muted">(optional)</span>
                                        </small>
                                    </div>

                                    <workflow-selector v-model="import_model.workflow_id"
                                                       ref="workflowSelector"
                                                       clearable
                                                       data-testid="work-flow-select-step-five"
                                                       @change="changeImportingWorkflow">
                                    </workflow-selector>
                                </el-form-item>
                                <el-form-item v-if="!import_model.is_dnc"
                                              class="mb-1">
                                    <div scope="label"
                                         class="form-label">
                                        <label>Tags</label>
                                        <small>
                                            Select tags that you want to assign to this contacts
                                            <span class="text-muted">(optional)</span>
                                        </small>
                                    </div>

                                    <tag-selector v-model="import_model.tag_ids"
                                                  ref="tagSelector"
                                                  :category_prop="TagCategory.CAT_CONTACTS"
                                                  :multiple="true"
                                                  clearable
                                                  data-testid="tag-select-step-five"
                                                  @change="changeTags($event, import_model)">
                                    </tag-selector>

                                    <tag-creator ref="addTag"
                                                 :category_prop="TagCategory.CAT_CONTACTS"
                                                 @success="addTagToImportContacts">
                                    </tag-creator>

                                    <div class="row"
                                         v-show="import_model.tag_ids.length > 0">
                                        <div class="col-md-12">
                                            <div scope="label"
                                                 class="form-label">
                                                <label>Split Tag</label>
                                                <small>
                                                    Splitting a tag would append " page-[:page_num]" to the original tag name, and create new tags accordingly.
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-12 pr-4">
                                            <el-checkbox
                                                v-model="split_tag.action"
                                                label="Do you want to split tag into smaller tag?"
                                                class="w-full break-word"
                                                data-testid="split-tag-checkbox"
                                                border>
                                            </el-checkbox>
                                        </div>
                                        <div class="col-md-5"
                                             v-show="split_tag.action">
                                            <el-select v-model="split_tag.selected"
                                                       data-testid="split-tag-select"
                                                       class="w-full">
                                                <el-option
                                                    v-for="item in split_tag.options"
                                                    :key="item.value"
                                                    :label="item.value"
                                                    :value="item.value"
                                                >
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </div>

                                    <div scope="label"
                                         class="form-label">
                                        <label>List</label>
                                        <small>
                                            Select a list that you want to assign to this contacts
                                        </small>
                                    </div>

                                    <contact-lists-selector ref="contactListSelector"
                                                            v-model="import_model.contact_list_id"
                                                            :use_first_list="false"
                                                            :user_id="auth.user.profile.id"
                                                            :list_type="ContactListTypes.STATIC_LIST"
                                                            :module_type="ContactListModuleTypes.PUBLIC_CONTACT_LIST"
                                                            data-testid="contact-list-select-step-five"
                                                            @change="changeContactList">
                                    </contact-lists-selector>

                                    <contact-list-creator ref="addContactList"
                                                          @success="setContactList">
                                    </contact-list-creator>
                                </el-form-item>
                                <el-form-item v-if="is_rerun && import_model.rows > 0">
                                    <div scope="label"
                                         class="form-label">
                                        <label>Restart or continue</label>
                                        <small>
                                            Do you want to start from the beginning of the file? Or continue at line
                                            {{ import_model.rows }}?
                                        </small>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <el-radio v-model="start_from_zero"
                                                      name="start_from_zero"
                                                      :label="false"
                                                      class="w-full"
                                                      data-testid="start-from-zero-radio"
                                                      border>
                                                Continue
                                            </el-radio>
                                        </div>
                                        <div class="col">
                                            <el-radio v-model="start_from_zero"
                                                      name="start_from_zero"
                                                      :label="true"
                                                      class="w-full"
                                                      data-testid="start-from-beginning-radio"
                                                      border>
                                                Start from beginning
                                            </el-radio>
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form>
                </div>

                <div v-show="currentStep === 5">
                    <div class="container-import-summary">
                        <h2>
                            <i class="fa fa-check"></i>
                            Importing contacts...
                        </h2>

                        <i class="fas fa-file-import"></i>

                        <p class="lead">This might take a moment. </p>
                        <p class="lead">
                            You can see the progress of the import in Account &raquo; Imports page.
                        </p>
                        <div class="text-center">
                            <el-button data-testid="close-wizard-step-6"
                                       @click="closeImportWizard">
                                Close
                            </el-button>
                            <router-link :to="{ name: 'Account', query: { tab: 'imports' }}"
                                         v-if="$route.name !== 'Account'">
                                <el-button type="success"
                                           data-testid="go-to-account-button">
                                    Go to Account &raquo; Imports
                                </el-button>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="d-flex pt-3"
                     v-if="currentStep < 5">
                    <el-button
                        v-show="showBackButton"
                        data-testid="import-wizard-back-button"
                        @click="back">
                        <i class="fa fa-chevron-left"></i>
                        Back
                    </el-button>

                    <div class="ml-auto">
                        <el-button v-if="currentStep === 0 || currentStep === 2 || currentStep === 3"
                                   :disabled="!stepIsValid"
                                   type="success"
                                   data-testid="import-wizard-nest-step-button"
                                   @click="next">
                            <strong>Next Step <i class="fa fa-chevron-right"></i></strong>
                        </el-button>

                        <el-button type="success"
                                   class="px-4"
                                   v-if="currentStep === 4"
                                   data-testid="import-wizard-start-import-button"
                                   @click="startImport">
                            <strong><i class="fas fa-file-import"></i> Start Import</strong>
                        </el-button>
                    </div>
                </div>
            </div>

            <el-dialog
                width="40vw"
                class="import-wizard dialog-no-border"
                :visible.sync="show_dnc_scrubbing_info_dialog"
                append-to-body
                @close="show_dnc_scrubbing_info_dialog = false">
                <div slot="title">
                    <h5 class="mb-0">
                        DNC Scrubbing Service Details
                    </h5>
                </div>

                <div>
                    <p>A contact representative will contact you or your team shortly.</p>
                </div>
            </el-dialog>
        </el-dialog>
    </div>
</template>

<script>
import auth from "../../auth"
import {mapGetters, mapState} from 'vuex'
import TagCreator from '../../components/tag-creator'
import ContactListCreator from '../../components/contact-list-creator'
import RingGroupSelector from "../../components/ring-group-selector"
import * as ImportAssignContactsTo from '../../constants/import-assign-contacts-to'
import * as TagCategory from '../../constants/tag-categories'
import * as ContactListModuleTypes from '../../constants/contact-list-module-types'
import * as ContactListTypes from '../../constants/contact-list-types'
import ErrorBag from '../../ErrorBag'
import ContactListsSelector from '../contact-lists-selector.vue'
import AlAlert from '../../components/alert'

import { TAGS_DEPRECATION_IMPORT_CONTACTS_MESSAGE } from '../../constants/deprecation.messages'


export default {
    name: "import-wizard",

    props: {
        is_contact_list: {
            default: false
        }
    },

    components: {
        TagCreator,
        ContactListCreator,
        RingGroupSelector,
        ContactListsSelector,
        RingGroupSelector,
        AlAlert
    },

    data() {
        let base_props = {
            update_existing: false,
            cascade_contacts: false,
            campaign_id: null,
            tag_ids: [],
            contact_list_id: null,
            assign_contacts_to: null,
            start_from_zero: false
        }

        // if the import wizard is called in the admin list
        // to import contact list, add additional properties
        if (this.is_contact_list) {
            base_props.name = null
            base_props.contact_folder_id = null
        }

        return {
            TAGS_DEPRECATION_IMPORT_CONTACTS_MESSAGE,
            auth: auth,
            uploading: false,
            loading: false,
            currentStep: 0,
            importDialogVisible: false,
            tableHeader: [],
            tableData: [],
            tableHeaderValue: [],
            currentFile: null,
            uploadError: '',
            noFirstColumn: false,
            updateExisting: false,
            forceRender: true,
            is_dnc_scrubbing_requested: false,
            requesting_dnc_scrubbing: false,
            show_dnc_scrubbing_info_dialog: false,
            is_dnc_agreed: false,
            reviewData: [],
            import_model: {},
            original_import_model: base_props,
            tab_name: 'user',
            is_rerun: false,
            start_from_zero: false,
            contact_fields: [ // categorized the contact fields
                {
                    'group_name': 'Common Fields',
                    'fields': [
                        {
                            value: 'full_name',
                            label: 'Full Name'
                        },
                        {
                            value: 'first_name',
                            label: 'First Name'
                        },
                        {
                            value: 'last_name',
                            label: 'Last Name'
                        },
                        {
                            value: 'phone_number',
                            label: 'Phone Number'
                        },
                        {
                            value: 'email',
                            label: 'Email'
                        },
                        {
                            value: 'company_name',
                            label: 'Company'
                        },
                        {
                            value: 'address',
                            label: 'Address'
                        },
                        {
                            value: 'cnam_city',
                            label: 'City'
                        },
                        {
                            value: 'cnam_state',
                            label: 'State'
                        },
                        {
                            value: 'cnam_zipcode',
                            label: 'Zip Code'
                        },
                        {
                            value: 'cnam_country',
                            label: 'Country'
                        },
                        {
                            value: 'date_of_birth',
                            label: 'Date of Birth'
                        },
                        {
                            value: 'notes',
                            label: 'Notes'
                        },
                        {
                            value: 'website',
                            label: 'Website'
                        },
                        {
                            value: 'csf1',
                            label: 'Custom Field 1'
                        },
                        {
                            value: 'csf2',
                            label: 'Custom Field 2'
                        },
                        {
                            value: 'lead_source',
                            label: 'Lead Source'
                        },
                        {
                            value: 'disposition_status',
                            label: 'Disposition Status'
                        },
                        {
                            value: 'tag',
                            label: 'Tag'
                        }
                    ]
                },
                {
                    'group_name': 'Custom Fields',
                    'fields': {}
                }
            ],
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + auth.user.profile.api_token
            },
            split_tag: {
                action: false,
                selected: 50,
                options: [
                    {
                        value: 50,
                    },
                    {
                        value: 100,
                    },
                    {
                        value: 200,
                    },
                    {
                        value: 500,
                    },
                    {
                        value: 1000,
                    }
                ]
            },
            contact_folders: [],
            contact_folder_searching: false,
            no_contact_folders_data: 'No data',
            ImportAssignContactsTo,
            TagCategory,
            ContactListModuleTypes,
            ContactListTypes,
            errors: new ErrorBag({}),
        }
    },

    computed: {
        ...mapState(['campaigns']),

        ...mapState('cache', ['current_company']),

        ...mapGetters({
            attribute_dictionaries: 'getAttributeDictionaries',
        }),

        ...mapGetters('cache', ['isSimpSocial']),

        stepIsValid() {
            if (this.currentStep === 0 && this.is_dnc_agreed === true) {
                return true
            } else if (this.currentStep === 2) {
                return this.tableHeaderValue.includes('phone_number')
            } else if (this.currentStep === 3) {
                return true
            }

            return false
        },

        showBackButton() {
            return this.currentStep > 0
        },

        sortedCampaigns() {
            return _.sortBy(this.campaigns, ['name'])
        },

        importable() {
            // when import is from contact list, contact list configuration is required
            return !(this.is_contact_list && !this.import_model.name);
        },

        csfFields() {
            let fields = []
            if (this.attribute_dictionaries && this.attribute_dictionaries.length) {
                for (let index in this.attribute_dictionaries) {
                    fields.push({
                        label: this.attribute_dictionaries[index].name,
                        value: this.attribute_dictionaries[index].slug
                    })
                }
            }

            return fields
        }
    },

    created() {
        // index 1 is for custom contact fields, let's populate it
        this.contact_fields[1].fields = this.csfFields
    },

    methods: {
        changeContactList(value) {
            this.import_model.contact_list_id = value
        },

        searchContactFolders(search) {
            this.no_contact_folders_data = 'Please input at least 3 character'
            // search should be at least 3 letters
            if (!search || search && search.length <= 2) {
                return
            }

            this.$APIV2.ContactFolder.searchContactFolders(search)
                .then(res => {
                    this.contact_folders = res.data
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(_ => {
                    this.no_contact_folders_data = 'No data'
                })
        },

        start() {
            this.import_model = _.clone(this.original_import_model)
            this.step(0)
        },

        renderColumnChooser(response, file, fileList) {
            this.currentStep = 2
            this.currentFile = file.raw
            this.tableData = response.data
            this.tableHeader = response.columns
            this.import_model = response.import

            this.tableHeaderValue = []
            this.tableHeader.forEach((value, key) => {
                this.tableHeaderValue[key] = value
            })

            this.uploading = false
            this.loading = false
            this.uploadError = ''
        },

        startImport() {
            this.loading = true

            this.errors.clearAll()
            this.import_model.is_import_contact_list = this.is_contact_list
            this.import_model.start_from_zero = this.start_from_zero
            this.import_model.split_tag = this.split_tag.action
            this.import_model.page_size = this.split_tag.selected
            axios.post('/api/v1/import-wizard/' + this.import_model.id + '/start', this.import_model)
                .then(res => {
                    this.import_model = res.data.import

                    this.currentStep = 5;

                    this.$emit('importStarted')

                    this.loading = false
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.errors = new ErrorBag(err.response.data.errors)
                    }
                    console.log(err)

                    this.loading = false
                })
        },

        rerun(import_model) {
            this.is_rerun = true
            this.importDialogVisible = true
            this.loading = true
            axios.post('/api/v1/import-wizard/parse-with-import/' + import_model.id).then(res => {
                let response = res.data

                this.currentStep = 2
                this.tableData = response.data
                this.tableHeader = response.columns
                this.import_model = response.import

                this.tab_name = {
                    [ImportAssignContactsTo.ASSIGN_CONTACTS_TO_RING_GROUP]: 'ring_group',
                    [ImportAssignContactsTo.ASSIGN_CONTACTS_TO_USER]: 'user'
                }[this.import_model.assign_contacts_to]

                if (!this.tab_name) {
                    this.tab_name = 'user'
                }

                this.tableHeaderValue = []
                this.tableHeader.forEach((value, key) => {
                    this.tableHeaderValue[key] = value
                })

                this.uploading = false
                this.loading = false
                this.uploadError = ''
            }).catch(err => {
                if (err.response.status === 422) {
                    let data = err.response.data
                    this.uploadError = data.errors.file[0]
                }
                this.loading = false
            });
        },

        columnChooserFailed(err, file, fileList) {
            this.currentFile = file
            try {
                let error = JSON.parse(err.message)
                this.uploadError = error.errors.file[0]
            } catch (e) {
                this.uploadError = 'File is invalid'
            }
            this.uploading = false
            this.loading = false
        },

        analyzeData() {
            this.loading = true
            axios.post('/api/v1/import-wizard/analyze', {
                headers: this.tableHeaderValue,
                ignore_first_row: this.noFirstColumn,
                import_id: this.import_model.id
            }).then(res => {
                let data = res.data
                this.import_model = data.import;
                this.reviewData = data.data;

                this.loading = false
            })
        },

        beforeUpload() {
            this.uploading = true
            this.loading = true
            this.uploadError = ''
        },

        back() {
            this.step(this.currentStep - 1)
        },

        next() {
            this.step(this.currentStep + 1)
        },

        step(step) {
            this.currentStep = step
            if (this.currentStep === 0) {
                this.importDialogVisible = true
                this.uploading = false
                this.noFirstColumn = false
            } else if (this.currentStep === 3) {
                this.analyzeData();
            }
        },

        closeImportWizard() {
            this.currentStep = 0;
            this.importDialogVisible = false
            this.uploading = false
            this.loading = false
            this.uploadError = ''
            this.is_dnc_agreed = false
            this.$emit('onClose')
        },

        beforeClose() {
            if (_.isEqual(this.import_model, this.original_import_model)) {
                this.closeImportWizard()
            } else {
                this.$confirm(`Are you sure you want to close this import wizard?`, 'Event', {
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: 'warning',
                    customClass: 'width-500 fixed'
                }).then(() => {
                    this.closeImportWizard()
                }).catch(err => {
                    console.log(err)
                })
            }
        },

        fieldName(field) {
            if (field.includes('phone_number')) {
                return 'Phone Numbers'
            } else if (field === 'warnings') {
                return 'Warnings';
            } else if (field === 'errors') {
                return 'Errors';
            } else {
                // collect all the contact fields.
                // index 1 - common contact fields
                // index 2 - custom contact fields
                let fields = _.concat(this.contact_fields[0].fields, this.contact_fields[1].fields)

                // find the field's label
                let fieldData = _.find(fields, {'value': field})

                if (fieldData != null) {
                    return fieldData.label
                }

                return ''
            }
        },

        checkHeaderMap(value) {
            if (value !== 'phone_number') {
                return this.tableHeaderValue.includes(value);
            }
            return false;
        },

        tableHeaderChange(value) {
            if (value === 'full_name') {
                let firstNameIndex = this.tableHeaderValue.indexOf('first_name')
                this.tableHeaderValue[firstNameIndex] = null
                let lastNameIndex = this.tableHeaderValue.indexOf('last_name')
                this.tableHeaderValue[lastNameIndex] = null
            }
            if (value === 'first_name' || value === 'last_name') {
                let fullNameIndex = this.tableHeaderValue.indexOf('full_name')
                this.tableHeaderValue[fullNameIndex] = null
            }
        },

        ringGroupChange(ring_group_id) {
            this.import_model.ring_group_id = ring_group_id
            this.tab_name = 'ring_group'
            this.import_model.assign_contacts_to = ImportAssignContactsTo.ASSIGN_CONTACTS_TO_RING_GROUP
        },

        /**
         * + -------------------------------------------------------------------------------------------------------
         * | Codes copied from contacts.vue
         * + -------------------------------------------------------------------------------------------------------
         * | These code is copied from contacts.vue to implement import settings
         */
        changeTags(event, model) {
            model.tag_ids = event
        },

        setContactList(contactList) {
          this.import_model.contact_list_id = contactList.id
          this.$refs.contactListSelector.addList(contactList)
        },

        addTagToImportContacts(tag) {
            this.import_model.tag_ids.push(tag.id)
        },

        changeUserId(user_id) {
            this.import_model.user_id = user_id
            this.tab_name = 'user'
            this.import_model.assign_contacts_to = ImportAssignContactsTo.ASSIGN_CONTACTS_TO_USER
        },

        changeImportingWorkflow(val) {
            this.import_model.workflow_id = val
        },

        requestDncScrubbingDetails() {
            this.requesting_dnc_scrubbing = true

            axios.get(`/api/v1/import-wizard/dnc-scrubbing-request`)
                .then(res => {
                    this.is_dnc_scrubbing_requested = true
                    this.show_dnc_scrubbing_info_dialog = true

                    this.requesting_dnc_scrubbing = false
                })
                .catch(err => {
                    console.log(err)

                    this.requesting_dnc_scrubbing = false
                })
        }
    }
}
</script>
