<template>
    <div>
        <div v-if="showAlertAgentOnCall"
             class="p-3">
            <p><strong>Call in Progress on Another Device</strong></p>
            <hr>
            <p>You're currently engaged in another call on Aloware Talk. Please complete your current conversation before initiating a new call.</p>
        </div>
        <webrtc :carrier_name="auth.user.profile.carrier_name"
                :is_widget="true"
                :class="[small ? 'small' : '']"
                v-else-if="allowed"
                @callConnected="handleCallConnectedEvent"
                @callCompleted="handleCallCompletedEvent">
        </webrtc>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapActions, mapState} from 'vuex'
import {acl_mixin} from '../../mixins'
import CallingExtensions from "@hubspot/calling-extensions-sdk"
import * as AgentStatus from '../../constants/agent-status'

export default {
    mixins: [acl_mixin],

    props: {
        api_key: {
            required: false
        }
    },

    data() {
        return {
            auth: auth,
            loading: false,
            small: false,
            initialized: false,
            needs_extensions: false,
            extensions_initialized: false,
            extensions_visibility: false,
            phone_number: null,
            extensions: null,
            timeout: null,
            showAlertAgentOnCall: false,
            callSdkOptions: {
                // Whether to log various inbound/outbound messages to console
                debugMode: true,
                // eventHandlers handle inbound messages
                eventHandlers: {
                    onReady: () => {
                        const payload = {
                            // Whether a user is logged-in
                            isLoggedIn: this.auth.user.authenticated,
                            // Optionally send the desired widget size
                            sizeInfo: {
                                height: 532,
                                width: 300
                            }
                        }
                        this.extensions.initialized(payload)
                        this.extensions_initialized = true
                    },
                    onDialNumber: event => {
                        if (event.phone_number) {
                            this.phone_number = event.phone_number
                            if (this.timeout) {
                                clearTimeout(this.timeout)
                            }
                            this.handleDialNumber(event.phone_number)
                        }
                    },
                    onVisibilityChanged: data => {
                        this.extensions_visibility = !data.isHidden
                    }
                }
            },
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),
        ...mapState(['on_call']),

        allowed () {
            return this.auth?.user?.profile && this.initialized
        }
    },

    created() {
        this.$root.$data.is_widget = true
        if (this.$route.query.small) {
            this.small = true
        }

        if (this.$route.name === 'Hubspot Call Extension') {
            this.needs_extensions = true
        } else {
            this.needs_extensions = false
        }

        if (!this.needs_extensions) {
            this.extensions_visibility = true
        }

        this.extensions = new CallingExtensions(this.callSdkOptions)
    },

    mounted() {
        this.init()
    },

    methods: {
        init() {
            if (this.api_key) {
                localStorage.setItem('api_token', this.api_key)
            }
            this.loading = true
            auth.check().then((res) => {
                if (!this.needs_extensions) {
                    localStorage.setItem('company_id', res.data.user.company.id)
                    this.setCurrentCompany(res.data.user.company)
                    this.resetVuex()
                }
                this.loading = false
                this.initialized = true
                this.handleUserLogin()
            }).catch((err) => {
                console.log('Error: api key is not valid', err)
                let error = new Error((err.response && err.response.data && err.response.data.error) ? 'Error while authenticating user in HubSpot Calling SDK. Error: ' + err.response.data.error : 'Error while authenticating user in HubSpot Calling SDK')
                error.code = err.response ? err.response.status : 400
                // do not report authentication errors to Sentry
                if (!error.message.includes("User is not authenticated")) {
                    Sentry.captureException(error)
                }
                this.loading = false
                this.$router.push({name: 'Login', query: {redirect: this.$route.fullPath}})
            })
        },

        handleDialNumber(phone_number) {
            if (this.needs_extensions && this.extensions_initialized && this.initialized && !this.showAlertAgentOnCall) {
                this.extensions_visibility = true
                this.phone_number = null
                VueEvent.fire('make_hs_new_call', {phone_number: phone_number})
            } else if (this.needs_extensions && this.extensions_initialized && !this.initialized) {
                this.timeout = setTimeout(() => {
                    console.log('Retry calling ' + phone_number)
                    this.handleDialNumber(phone_number)
                }, 250)
            }
        },

        handleUserLogin() {
            if (this.needs_extensions && this.extensions_initialized) {
                this.extensions.userLoggedIn()
            }
        },

        handleCallConnectedEvent() {
            if (this.needs_extensions && this.extensions_initialized) {
                this.extensions.callAnswered()
            }
        },

        handleCallCompletedEvent() {
            if (this.needs_extensions && this.extensions_initialized) {
                this.extensions.callEnded()
            }
        },

        ...mapActions(['resetVuex']),
        ...mapActions('cache', ['setCurrentCompany'])
    },

    watch: {
        extensions_visibility() {
            console.log('Extension visibility: ' + this.extensions_visibility)

            if (this.extensions_visibility) {
                this.showAlertAgentOnCall = this.auth?.user?.profile?.agent_status === AgentStatus.AGENT_STATUS_ON_CALL
            }
        }
    }
}
</script>

<style scoped>
html, body {
    background: transparent !important;
    width: 300px;
    height: 532px;
}
</style>

