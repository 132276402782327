var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contactId && _vm.isProfileLoaded
    ? _c("contact", {
        attrs: {
          contact_id: _vm.contactId,
          embedded: true,
          force_full_height: true,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }