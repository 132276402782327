var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "card summary-card-hover h-100",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "card-body py-0",
          staticStyle: { height: "150px", "overflow-y": "scroll" },
        },
        [
          _vm.lead_sources.length > 0
            ? _c(
                "table",
                {
                  staticClass: "table table-sm table-aloware",
                  staticStyle: { "font-size": "14px" },
                  attrs: { "data-testid": "lead-source-table" },
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    { attrs: { "data-testid": "lead-source-body" } },
                    _vm._l(_vm.lead_sources, function (lead_source) {
                      return _c(
                        "tr",
                        {
                          key: lead_source.id,
                          staticClass: "countup-hover",
                          attrs: { "data-testid": "lead-source-body-row" },
                        },
                        [
                          _c(
                            "td",
                            {
                              staticClass: "text-greyish",
                              attrs: {
                                "data-testid": "lead-source-body-source-column",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "d-block",
                                  attrs: {
                                    to: {
                                      name: "Contacts",
                                      query: {
                                        lead_source_id: lead_source.id,
                                        from_date: _vm.filter.from_date,
                                        to_date: _vm.filter.to_date,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(lead_source.name) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "text-black font-weight-bold hover-value",
                              attrs: {
                                "data-testid":
                                  "lead-source-body-contacts-column",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "d-block",
                                  attrs: {
                                    to: {
                                      name: "Contacts",
                                      query: {
                                        lead_source_id: lead_source.id,
                                        from_date: _vm.filter.from_date,
                                        to_date: _vm.filter.to_date,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          lead_source.contacts
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _c("div", { staticClass: "py-3 text-center" }, [
                _c("small", { staticClass: "text-dead metric-label" }, [
                  _vm._v(
                    "\n                No lead source in this date range.\n            "
                  ),
                ]),
              ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 p-1" }, [
        _c("div", { staticClass: "d-inline-block pt-3 pl-4 align-bottom" }, [
          _c("i", {
            staticClass: "fas fa-file-import",
            staticStyle: { color: "#03c04c" },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-inline-block pl-2" }, [
          _c("strong", { staticClass: "text-md title" }, [
            _vm._v("Leads by Sources"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { attrs: { "data-testid": "lead-source-head" } }, [
      _c("tr", { attrs: { "data-testid": "lead-source-head-row" } }, [
        _c(
          "th",
          {
            staticClass: "text-greyish",
            attrs: { "data-testid": "lead-source-head-source-column" },
          },
          [_vm._v("\n                    Source")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-greyish",
            attrs: { "data-testid": "lead-source-head-contacts-column" },
          },
          [_vm._v("\n                    Contacts\n                ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }