<template>
    <el-dialog width="40vw"
               top="4vh"
               :visible="modal_visible"
               :append-to-body="true"
               :close-on-click-modal="false"
               :before-close="beforeClose">
        <div slot="title">
            <h5 class="mb-0">
                <span v-if="mode === MODE_ADD">Add New</span>
                <span v-if="mode === MODE_EDIT">Edit</span>
                Custom Field
            </h5>
        </div>

        <el-form class="form-aloware"
                 ref="attribute_dictionary_form">
            <el-form-item class="no-border"
                          :error="errors.first('name')"
                          prop="name">
                <div class="form-label">
                    <h5>Name</h5>
                </div>
                <el-input v-model="attribute_dictionary.name"
                          placeholder="Name of the custom field">
                </el-input>
            </el-form-item>


            <el-form-item class="no-border"
                          :key="key"
                          :error="errors.first('slug')"
                          prop="name">
                <div class="form-label">
                    <h5>Key</h5>
                    <small>Only lowercase characters, numbers, and underscores are allowed. Example: "middle_name"</small>
                </div>
                <el-input v-model="attribute_dictionary.slug"
                          placeholder="Key of the custom field">
                </el-input>
            </el-form-item>


            <el-form-item>
                <div class="form-label">
                    <h5>Description</h5>
                    <small>Describe what this custom field is for.</small>
                </div>
                <el-input v-model="attribute_dictionary.description"
                          type="textarea"
                          :rows="2">
                </el-input>
            </el-form-item>




<!--            Temporarily hidden-->
            <el-form-item>
                <div class="form-label">
                    <h5>Data Type</h5>
                    <small>Select the proper data type of this custom field</small>
                </div>
                <el-select v-model="attribute_dictionary.type"
                           class="w-full mb-4">
                    <el-option v-for="type in AttributeDictionary.TYPES"
                               :key="type.key"
                               :label="type.label"
                               :value="type.key">
                    </el-option>
                </el-select>

                <el-form-item v-if="attribute_dictionary.type === AttributeDictionary.TYPE_DROPDOWN && false"
                              :error="errors.first('type_values')"
                              class="no-border">
                    <div class="form-label">
                        <h5>Dropdown Options</h5>
                        <small>Please add the drop down options. You can add as many as you want.</small>
                    </div>

                    <div class="d-flex mb-1"
                         v-for="(value, i) in attribute_dictionary.type_values"
                         :key="i">
                        <div class="flex-grow-1 mr-1">
                            <el-input class="w-full"
                                      placeholder="Input option name here..."
                                      v-model="attribute_dictionary.type_values[i]"></el-input>
                        </div>
                        <el-button size="small"
                                   class="text-danger"
                                   @click="removeDropdownOption(i)">
                            <i class="fa fa-remove"></i>
                        </el-button>
                    </div>

                    <div class="text-center mt-2">
                        <el-button size="medium"
                                   type="success"
                                   @click="addDropdownOption">
                            <i class="fa fa-plus"></i>
                            <strong>Add Option</strong>
                        </el-button>
                    </div>
                </el-form-item>
            </el-form-item>

            <el-form-item v-show="false"
                          class="no-border"
                          :error="errors.first('default_value')">
                <div class="form-label">
                    <h5>Default Value</h5>
                    <small>The default value of the custom field. Leave it blank if you don't need a default value.</small>
                    <small v-if="attribute_dictionary.type === AttributeDictionary.TYPE_DROPDOWN">
                        Make sure the default value matches one of the options above.
                    </small>
                </div>
                <el-input v-model="attribute_dictionary.default_value"></el-input>
            </el-form-item>

            <div class="d-flex mt-4">
                <el-button @click="beforeClose">Close</el-button>
                <el-button type="success"
                           class="ml-auto"
                           :loading="saving"
                           @click="save">
                    <strong>{{ saving ? 'Saving...' : 'Save' }}</strong>
                </el-button>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
import ErrorBag from "../../../ErrorBag"
import * as AttributeDictionary from '../../../constants/attribute-dictionary'

export default {
    name: "attribute-dictionary-manager",

    data() {
        return {
            key: 1,
            modal_visible: false,
            saving: false,
            MODE_ADD: 1,
            MODE_EDIT: 2,
            mode: this.MODE_ADD,

            // models
            attribute_dictionary: {},
            default_attribute_dictionary: {},
            errors: new ErrorBag({}),
            AttributeDictionary
        }
    },

    computed: {
        is_dirty() {
            return !_.isEqual(this.attribute_dictionary, this.default_attribute_dictionary)
        }
    },

    methods: {
        add() {
            this.modal_visible = true
            this.mode = this.MODE_ADD

            this.default_attribute_dictionary = {
                type: AttributeDictionary.TYPE_TEXT,
                type_values: []
            }
            this.attribute_dictionary = _.clone(this.default_attribute_dictionary)
        },

        edit(attribute_dictionary) {
            this.default_attribute_dictionary = attribute_dictionary
            this.attribute_dictionary = _.clone(this.default_attribute_dictionary)

            this.modal_visible = true
            this.mode = this.MODE_EDIT
        },

        addDropdownOption() {
            this.attribute_dictionary.type_values.push('')
        },

        removeDropdownOption(index) {
            this.attribute_dictionary.type_values.splice(index, 1)
        },

        save() {
            this.saving = true
            if (this.mode === this.MODE_ADD) {
                this.create()
            }
            else if (this.mode === this.MODE_EDIT) {
                this.update()
            }
        },

        create() {
            axios.post(`/api/v1/attribute-dictionary`, this.attribute_dictionary).then(res => {
                this.attribute_dictionary = res.data.attribute_dictionary
                this.notify(res.data.message, 'success')
                this.close()

                this.$emit('callback')
                this.saving = false
            }).catch(err => {
                if (err.response.status === 422) {
                    this.errors = new ErrorBag(err.response.data.errors)
                }

                this.saving = false
            })
        },

        update() {
            axios.put(`/api/v1/attribute-dictionary/${this.attribute_dictionary.id}`, this.attribute_dictionary).then(res => {
                this.attribute_dictionary = res.data.attribute_dictionary
                this.notify(res.data.message, 'success')
                this.close()

                this.$emit('callback')
                this.saving = false
            }).catch(err => {
                if (err.response.status === 422) {
                    this.errors = new ErrorBag(err.response.data.errors)
                }

                this.saving = false
            })
        },

        delete(attribute_dictionary) {
            this.$confirm(`Are you sure you want to delete this custom field? This action is irreversible.`, 'Confirm', {
                confirmButtonText: 'Yes, I\'m sure',
                cancelButtonText: 'No, I\'m not',
                type: 'warning',
                customClass: 'width-500 fixed'
            }).then(() => {
                axios.delete(`/api/v1/attribute-dictionary/${attribute_dictionary.id}`).then(res => {
                    this.notify(res.data.message, 'success')
                    this.$emit('callback')
                })
            }).catch((err) => {
                console.log(err)
            })
        },

        beforeClose() {
            if (this.is_dirty) {
                this.$confirm(`Are you sure you want to close this custom field form`, 'Event', {
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: 'warning',
                    customClass: 'width-500 fixed'
                }).then(() => {
                    this.close()
                }).catch(err => {
                    console.log(err)
                })
            } else {
                this.close()
            }
        },

        close() {
            this.mode = this.MODE_ADD
            this.attribute_dictionary = {}
            this.modal_visible = false

            this.$refs.attribute_dictionary_form.resetFields();
            this.errors = new ErrorBag({})
        },

        notify(message, type) {
            this.$notify({
                offset: 95,
                title: 'Success',
                message: message,
                type: type,
                duration: 6000,
            });
        }
    }
}
</script>
