<template>
    <div
        v-if="usage"
        class="card shadow-sm card-plan-usage mb-4">
        <div class="card-body py-2">
            <div class="row">
                <div class="col-5 px-0">
                    <div class="position-relative">
                        <highcharts :options="options"
                                    ref="chart">
                        </highcharts>
                        <div class="header">
                            <h4 class="mb-3">
                                {{ usage.percentage }}%
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="col-7 px-1">
                    <div class="pt-3">
                        <h4 class="mb-0">{{ usage.used | numFormat }}/{{ usage.included | numFormat}}</h4>
                        {{ usage.name }} Used
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "company-plan-usage-gauge",

    props: {
        usage: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            options: {
                exporting: {
                    enabled: false
                },
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    height: 100,
                    spacing: [0, 0, 0, 0],
                    marginTop: -20,
                    marginBottom: -50
                },
                title: {
                    text: null,
                    enabled: false
                },
                tooltip: {
                    enabled: false,
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '65%'],
                        allowPointSelect: false,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            distance: -50,
                        },
                        size: '100%'
                    },
                    series: {
                        animation: {
                            duration: 0
                        }
                    }
                },
                series: [{
                    name: 'Rate',
                    colorByPoint: true,
                    data: this.usage.data,
                    innerSize: '80%'
                }]
            }
        }
    }
}
</script>
