<template>
    <div v-if="hasPermissionTo('list lead source')">
        <el-select v-model="selected_lead_source"
                   class="w-full"
                   default-first-option
                   filterable
                   clearable
                   placeholder="Select Lead Source"
                   v-bind="$attrs"
                   data-testid="lead-source-selector"
                   @change="leadSourceChange">
            <el-option v-for="lead_source in lead_sources"
                       :key="lead_source.id"
                       :label="lead_source.name"
                       :value="lead_source.id">
                {{ lead_source.name }}
            </el-option>
        </el-select>
    </div>
</template>

<script>
import {acl_mixin} from "../mixins"
import auth from "../auth"
import {mapState} from "vuex"

export default {
    name: "lead-source-selector",

    mixins: [acl_mixin],

    props: {
        value: {
            required: false,
        },
    },

    data() {
        return {
            auth: auth,
            selected_lead_source: this.value
        }
    },

    computed: {
        ...mapState(['lead_sources']),
    },

    methods: {
        leadSourceChange(lead_source_id) {
            this.$emit('input', lead_source_id)
            this.$emit('change', _.find(this.lead_sources, {'id': lead_source_id}))
        }
    }
}
</script>
