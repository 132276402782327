export const AUTO_RECHARGE_VALUES = [
    {
        value: 50,
        label: '$50'
    }, {
        value: 100,
        label: '$100'
    }, {
        value: 250,
        label: '$250'
    }, {
        value: 500,
        label: '$500'
    }, {
        value: 1000,
        label: '$1000'
    }, {
        value: 5000,
        label: '$5000'
    }, {
        value: 10000,
        label: '$10000'
    }
]