import axios from 'axios'

const baseApiUrl = '/api/v1/integrations/app-extensions/pipedrive'

const state = {
  powerDialerFormInputs: null,
  enrollToSequenceFormInputs: null,
  pipedriveFilters: []
}

const getters = {
  getPowerDialerFormInputs: state => state.powerDialerFormInputs,
  getEnrollToSequenceFormInputs: state => state.enrollToSequenceFormInputs,
  getPipedriveFilters: state => state.pipedriveFilters
}

const actions = {
  async processPowerDialerRedirect({ _ }, payload) {
    const token = localStorage.getItem('api_token')
    return axios.post(`${baseApiUrl}/basic-app-action-add-to-power-dialer`, payload, {
          headers: { Authorization: `Bearer ${token}` }
        }
    )
  },
  async processEnrollToSequenceRedirect({ _ }, payload) {
    const token = localStorage.getItem('api_token')
    return axios.post(`${baseApiUrl}/basic-app-action-enroll-to-sequence`, payload, {
          headers: { Authorization: `Bearer ${token}` }
        }
    )
  },
  async fetchWorkflowsForAppExtension({ dispatch }) {
    const res = await axios.get('/api/v1/automations/workflows', {
      mode: 'no-cors',
      params: {
        size: 100
      }
    })
    res.data.data.forEach((workflow) => {
      // Only show workflows that passed diagnosis
      if (workflow.diagnosis.every(d => d.value === true)) {
        dispatch('newWorkflow', workflow)
      }
    })
  },
  async fetchAuthUserForAppExtension({ _ }) {
    const token = localStorage.getItem('api_token')
    const res = await axios.post('/get-auth-user', null, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return res.data.user
  },
  async fetchPipedriveFilters({ commit }) {
    const res = await axios.get('/api/v1/integration/pipedrive/filters')
    commit('SET_PIPEDRIVE_FILTERS', res.data)
  },
  async pullUsersForAppExtension({ _ }) {
    const token = localStorage.getItem('api_token')
    await axios.post('/api/v1/integration/pipedrive/pull-users', null, {
      headers: { Authorization: `Bearer ${token}` }
    })
  },
  async pullContactsForAppExtension({ _ }) {
    const token = localStorage.getItem('api_token')
    await axios.post('/api/v1/integration/pipedrive/pull-contacts', null, {
      headers: { Authorization: `Bearer ${token}` }
    })
  },
  async fetchPipedriveIntegrationSettingsFilters({ _ }) {
    const token = localStorage.getItem('api_token')
    const res = await axios.get(`${baseApiUrl}/integration-settings-filters`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    return res.data
  },
  updatePowerDialerFormInputs({ commit }, payload) {
    commit('SET_POWER_DIALER_FORM_INPUTS', payload)
  },
  updateEnrollToSequenceFormInputs({ commit }, payload) {
    commit('SET_ENROLL_TO_SEQUENCE_FORM_INPUTS', payload)
  },
}

const mutations = {
  SET_POWER_DIALER_FORM_INPUTS: (state, payload) => (state.powerDialerFormInputs = payload),
  SET_ENROLL_TO_SEQUENCE_FORM_INPUTS: (state, payload) => (state.enrollToSequenceFormInputs = payload),
  SET_PIPEDRIVE_FILTERS: (state, payload) => (state.pipedriveFilters = payload)
}

export default { state, getters, actions, mutations }
