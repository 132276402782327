const base_path = `/api/v1/ring-group`

export default {
    /**
     * Endpoint for fetching ring groups.
     * params parameter is used to paginate the data that will be fetch
     * @param params {Object} - { page: 1, per_page: 10 }
     * @handler Api/V1/RingGroup/RingGroupController@index
     * @return Promise
     */
    index(params) {
        return axios.get(`${base_path}`, { params })
    },

    /**
     * Endpoint for attaching a team to a ring group.
     * @param id {Number} - Ring Group ID
     * @param data {Object} - { team_id: 1 }
     * @handler Api/V1/RingGroup/RingGroupController@addTeam
     * @returns Promise
     */
    addTeam(id, data) {
        return axios.post(`${base_path}/${id}/team`, data)
    },

    /**
     * Endpoint for detaching a team from a ring group.
     * @param id {Number} - Ring Group ID
     * @param data {Object} - { team_id: 1 }
     * @handler Api/V1/RingGroup/RingGroupController@removeTeam
     * @returns Promise
     */
    removeTeam(id, data) {
        return axios.delete(`${base_path}/${id}/team`, { data })
    },

    /**
     * Endpoint for detaching multiple ring groups from a team.
     * @param data {Object} - { team_id: 1, ring_group_ids: [1, 2, 3] }
     * @handler Api/V1/RingGroup/RingGroupController@removeRingGroupsFromTeam
     * @returns Promise
     *
     */
    removeRingGroupsFromTeam(data) {
        return axios.delete(`${base_path}/remove/teams`, { data })
    }
}
