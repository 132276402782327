<template>
    <el-dialog width="70vw"
               top="4vh"
               :visible="modal_visible"
               :append-to-body="true"
               :close-on-click-modal="false"
               :before-close="beforeClose">
        <div slot="title">
            <h5 class="mb-0">
                <span v-if="mode === MODE_ADD">Add New</span>
                <span v-if="mode === MODE_EDIT">Edit</span>
                Webhook
            </h5>
        </div>

        <el-form class="form-aloware"
                 ref="webhook_form">
            <div class="row">
                <div class="col-6">
                    <div v-if="mode === MODE_EDIT">
                        <div v-if="webhook.status === Webhook.STATUS_UNTESTED"
                             class="alert alert-warning alert-aloware p-2">
                            <i class="fa fa-exclamation-triangle"></i> This webhook is untested and will not dispatch an event.
                        </div>
                        <div v-if="webhook.status === Webhook.STATUS_VALID"
                             class="alert alert-success alert-aloware p-2">
                            <i class="fa fa-check"></i> This webhook is tested and validated.
                        </div>
                        <div v-if="webhook.status === Webhook.STATUS_INVALID"
                             class="alert alert-danger alert-aloware p-2">
                            <i class="fa fa-remove"></i> This webhook failed the test. Please make sure the webhook end-point is valid URL.
                        </div>
                        <div v-if="webhook.status === Webhook.STATUS_DISABLED"
                             class="alert alert-warning alert-aloware p-2">
                            <i class="fa fa-ban"></i>
                            This webhook has been disabled.
                            You can enable this by pressing the Test & Save button.
                        </div>
                    </div>

                    <el-form-item class="no-border"
                                  :error="errors.first('name')"
                                  prop="name">
                        <div class="form-label">
                            <h5>Name</h5>
                        </div>
                        <el-input v-model="webhook.name"
                                  placeholder="Name of the Webhook">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="form-label">
                            <h5>Description</h5>
                            <small>Describe what this webhook is for.</small>
                        </div>
                        <el-input v-model="webhook.description"
                                  type="textarea"
                                  :rows="2">
                        </el-input>
                    </el-form-item>

                    <el-form-item class="no-border"
                                  :error="errors.first('url')"
                                  prop="url">
                        <div class="form-label">
                            <h5>URL</h5>
                            <small>Provide a valid webhook endpoint URL.</small>
                        </div>
                        <el-input v-model="webhook.url"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <div class="form-label">
                            <h5>Authentication Method</h5>
                            <small>Select your webhook endpoint authentication method.</small>
                        </div>
                        <el-radio-group v-model="webhook.http_auth_method"
                                        size="medium">
                            <el-radio-button :label="Webhook.HTTP_AUTH_METHOD_NONE">None</el-radio-button>
                            <el-radio-button :label="Webhook.HTTP_AUTH_METHOD_BASIC">Basic</el-radio-button>
                            <el-radio-button :label="Webhook.HTTP_AUTH_METHOD_BEARER">Bearer</el-radio-button>
                        </el-radio-group>

                        <div class="mt-3">
                            <div v-if="webhook.http_auth_method === Webhook.HTTP_AUTH_METHOD_BASIC"
                                 class="row">
                                <div class="col-6">
                                    <el-form-item class="no-border"
                                                  :error="errors.first('credentials.username')">
                                        <div class="form-label">
                                            <label>Username</label>
                                        </div>
                                        <el-input v-model="webhook.credentials.username"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="col-6">
                                    <el-form-item class="no-border"
                                                  :error="errors.first('credentials.password')">
                                        <div class="form-label">
                                            <label>Password</label>
                                        </div>
                                        <el-input v-model="webhook.credentials.password"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div v-if="webhook.http_auth_method === Webhook.HTTP_AUTH_METHOD_BEARER">
                                <el-form-item class="no-border"
                                              :error="errors.first('credentials.api_token')">
                                    <div class="form-label">
                                        <label>API Bearer Token</label>
                                        <small>Enter the API token of your webhook endpoint.</small>
                                    </div>
                                    <el-input v-model="webhook.credentials.api_token"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form-item>

                    <el-form-item :error="errors.first('delay')"
                                  prop="events">
                        <div class="d-flex">
                            <div class="mr-3">
                                <el-switch v-model="delay_enabled"
                                           active-color="#00a344"
                                           @change="changeDelay">
                                </el-switch>
                            </div>
                            <div class="form-label">
                                <label>Delay</label>
                                <small>The delay in seconds before sending the webhook</small>
                            </div>
                        </div>
                        <div v-if="delay_enabled">
                            <el-input-number v-model="webhook.delay" :min="0" :max="900"></el-input-number>
                        </div>
                    </el-form-item>

                    <el-form-item class="no-border"
                                  :error="errors.first('events')"
                                  prop="events"
                                  v-loading="events_loading">
                        <div class="form-label">
                            <h5>Events</h5>
                            <small>Select the events you want to receive on your webhook.</small>
                        </div>
                        <div>
                            <el-checkbox v-for="event in events"
                                         :key="event.key"
                                         :label="event.key"
                                         name="events"
                                         class="w-full checkbox-label-description"
                                         v-model="webhook.events"
                                         border>
                                <label>{{ event.name }}</label>
                                <small>{{ event.description }}</small>
                            </el-checkbox>
                        </div>
                    </el-form-item>
                </div>
                <div class="col-6">
                    <el-form-item class="no-border">
                        <webhook-filter-manager v-model="webhook"
                                                :key="tick">
                        </webhook-filter-manager>
                    </el-form-item>
                </div>
            </div>
            <div class="d-flex">
                <el-button @click="beforeClose">Close</el-button>
                <el-button type="success"
                           v-loading="saving"
                           class="ml-auto"
                           @click="save">
                    <strong>Save and Test Webhook</strong>
                </el-button>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
import auth from "../../../auth"
import * as Webhook from '../../../constants/webhook'
import WebhookFilterManager from './webhook-filter-manager'
import ErrorBag from "../../../ErrorBag"

export default {
    name: "webhook-manager",

    components: {WebhookFilterManager},

    data() {
        return {
            auth: auth,
            webhook: {},
            saving: false,
            pre_updated_webhook: null,
            delay_enabled: false,
            default_webhook: {
                http_method: Webhook.HTTP_METHOD_POST,
                http_auth_method: Webhook.HTTP_AUTH_METHOD_NONE,
                events: [],
                delay: 0,
                filters: {
                    status: false,
                    direction: [],
                    filter_direction: false,
                    filter_type: false,
                    type: [],
                    filter_communication_disposition_status: false,
                    communication_disposition_statuses: [],
                    filter_disposed_contacts: false,
                    disposition_status_ids: [],
                    filter_lines: false,
                    skip_line_ids: [],
                    filter_call_duration: false,
                    call_duration_type: null,
                    call_duration: null,
                    filter_qualified_only: false,
                },
                credentials: {}
            },
            modal_visible: false,
            MODE_ADD: 1,
            MODE_EDIT: 2,
            mode: this.MODE_ADD,
            events: [],
            events_loading: false,
            tick: 1,
            errors: new ErrorBag({}),
            Webhook,
        }
    },

    created() {
        this.fetchSupportedEvents()
        this.webhook = _.clone(this.default_webhook)
    },

    computed: {
        is_dirty() {
            let dirty = false
            if (this.mode === this.MODE_EDIT && !_.isEqual(this.webhook, this.pre_updated_webhook)) {
                dirty = true
            }

            if (this.mode === this.MODE_ADD && !_.isEqual(this.webhook, this.default_webhook)) {
                dirty = true
            }
            return dirty
        }
    },

    methods: {
        beforeClose() {
            if (this.is_dirty) {
                this.$confirm(`Are you sure you want to close this webhook form`, 'Event', {
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: 'warning',
                    customClass: 'width-500 fixed'
                }).then(() => {
                    this.close()
                }).catch(err => {
                    console.log(err)
                })
            } else {
                this.close()
            }
        },

        close() {
            this.mode = this.MODE_ADD
            this.webhook = _.clone(this.default_webhook)
            this.modal_visible = false

            this.$refs.webhook_form.resetFields();
            this.errors = new ErrorBag({})
        },

        add() {
            this.tick++

            this.webhook = _.clone(this.default_webhook)
            this.mode = this.MODE_ADD
            this.modal_visible = true

            this.delay_enabled = this.webhook.delay > 0 || this.delay_enabled
        },

        edit(webhook) {
            this.tick++

            webhook.filters = _.defaults({}, webhook.filters, this.default_webhook.filters)
            this.pre_updated_webhook = _.clone(webhook)
            this.webhook = webhook
            this.mode = this.MODE_EDIT
            this.modal_visible = true

            this.delay_enabled = this.webhook.delay > 0 || this.delay_enabled
        },

        fetchSupportedEvents() {
            this.events_loading = true
            axios.get(`/api/v1/integration/webhooks/events`).then(res => {
                this.events = res.data
                this.events_loading = false
            })
        },

        save() {
            this.$refs.webhook_form.validate(is_valid => {
                if (!is_valid) {
                    return false
                }
            })

            if (this.mode === this.MODE_EDIT) {
                return this.update();
            }

            if (this.mode === this.MODE_ADD) {
                return this.store();
            }
        },

        store() {
            this.saving = true
            axios.post(`/api/v1/integration/webhooks`, this.webhook).then(res => {
                this.webhook = res.data.webhook
                this.notify(res.data.message, 'success')
                this.close()

                this.$emit('callback')
                this.saving = false
            }).catch(err => {
                if (err.response.status == 422) {
                    this.errors = new ErrorBag(err.response.data.errors)
                }
                this.saving = false
            })
        },

        update() {
            this.saving = true
            axios.put(`/api/v1/integration/webhooks/${this.webhook.id}`, this.webhook).then(res => {
                this.webhook = res.data.webhook
                this.notify(res.data.message, 'success')
                this.close()

                this.$emit('callback')
                this.saving = false
            }).catch(err => {
                if (err.response.status == 422) {
                    this.errors = new ErrorBag(err.response.data.errors)
                }

                this.saving = false
            })
        },

        delete(webhook) {
            this.$confirm(`Are you sure you want to delete this webhook?`, 'Confirm', {
                confirmButtonText: 'Yes, I\'m sure',
                cancelButtonText: 'No, I\'m not',
                type: 'warning',
                customClass: 'width-500 fixed'
            }).then(() => {
                axios.delete(`/api/v1/integration/webhooks/${webhook.id}`).then(res => {
                    this.notify(res.data.message, 'success')
                    this.$emit('callback')
                })
            }).catch((err) => {
                console.log(err)
            })
        },

        test(webhook) {
            axios.post(`/api/v1/integration/webhooks/${webhook.id}/test`).then(res => {
                this.notify(res.data.message, res.data.type)
                this.$emit('callback')
            })
        },

        disable(webhook) {
            this.$confirm(`Are you sure you want to disable this webhook?`, 'Confirm', {
                confirmButtonText: 'Yes, I\'m sure',
                cancelButtonText: 'No, I\'m not',
                type: 'warning',
                customClass: 'width-500 fixed'
            }).then(() => {
                axios.put(`/api/v1/integration/webhooks/${webhook.id}/disable`).then(res => {
                    this.notify(res.data.message, 'success')
                    this.$emit('callback')
                })
            }).catch((err) => {
                console.log(err)
            })
        },

        changeDelay() {
            if (!this.delay_enabled) {
                this.webhook.delay = 0
            }
        },

        notify(message, type) {
            this.$notify({
                offset: 95,
                title: 'Webhooks',
                message: message,
                type: type,
                duration: 6000,
            });
        }
    }
}
</script>
