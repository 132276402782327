var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "pl-2 pr-2" }, [
        _c("div", { staticClass: "row box" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "no-effect" }, [
              _c("div", { staticClass: "box-header pt-0 pb-0" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("span", { staticClass: "pull-left font-120" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.pagination.total))]),
                      _vm._v(" exports\n                                "),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "box-body pt-0" }, [
                _c(
                  "div",
                  { on: { mouseleave: _vm.tableLeave } },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticClass: "w-full mt-3",
                        attrs: {
                          data: _vm.exports.data,
                          "default-sort": { prop: "id", order: "descending" },
                          fit: "",
                          "row-key": "id",
                          stripe: "",
                        },
                        on: { "cell-mouse-enter": _vm.cellEnter },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "expand" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (props) {
                                return [
                                  props.row.type ===
                                    _vm.ExportTypes.COMMUNICATION &&
                                  props.row.filters
                                    ? _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-4 text-blackish",
                                          },
                                          [
                                            _c("h6", [
                                              _c("b", [_vm._v("From Date:")]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm._f("fixFullDateLocal")(
                                                      props.row.filters
                                                        .from_date
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("h6", [
                                              _c("b", [_vm._v("To Date:")]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm._f("fixFullDateLocal")(
                                                      props.row.filters.to_date
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("h6", [
                                              _c("b", [_vm._v("Direction:")]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm._f("capitalize")(
                                                      props.row.filters
                                                        .direction
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("h6", [
                                              _c("b", [
                                                _vm._v("Answer Status:"),
                                              ]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm._f("capitalize")(
                                                      props.row.filters
                                                        .answer_status
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("h6", [
                                              _c("b", [_vm._v("Talk Time:")]),
                                              _vm._v(" "),
                                              props.row.filters.min_talk_time ==
                                              0
                                                ? _c("span", [
                                                    _vm._v("Any Talk Time"),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("fixDuration")(
                                                          _vm._f("toInt")(
                                                            props.row.filters
                                                              .min_talk_time
                                                          )
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                            ]),
                                            _vm._v(" "),
                                            _c("h6", [
                                              _c("b", [_vm._v("Type:")]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm._f("capitalize")(
                                                      props.row.filters.type
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-4 text-blackish",
                                          },
                                          [
                                            _c("h6", [
                                              _c("b", [_vm._v("Tags:")]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.formatTags(props.row)
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("h6", [
                                              _c("b", [
                                                _vm._v(
                                                  "First-time Conversations:"
                                                ),
                                              ]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.formatBoolean(
                                                      props.row.filters
                                                        .first_time_only
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("h6", [
                                              _c("b", [
                                                _vm._v(
                                                  "Untagged Conversations:"
                                                ),
                                              ]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.formatBoolean(
                                                      props.row.filters
                                                        .untagged_only
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("h6", [
                                              _c("b", [_vm._v("Lines:")]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.formatCampaigns(
                                                      props.row
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("h6", [
                                              _c("b", [_vm._v("Numbers:")]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.formatIncomingNumbers(
                                                      props.row
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("h6", [
                                              _c("b", [_vm._v("Users:")]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.formatUsers(props.row)
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            props.row.filters.page
                                              ? _c("h6", [
                                                  _c("b", [_vm._v("Page:")]),
                                                  _vm._v(
                                                    "\n                                                    " +
                                                      _vm._s(
                                                        props.row.filters.page
                                                      ) +
                                                      "\n                                                "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "col-md-4 text-blackish",
                                        }),
                                      ])
                                    : _vm.sub_category_export_types.includes(
                                        props.row.type
                                      ) && props.row.filters
                                    ? _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-4 text-blackish",
                                          },
                                          [
                                            _c("h6", [
                                              _c("b", [_vm._v("From Date:")]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm._f("fixFullDateLocal")(
                                                      props.row.filters
                                                        .from_date
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("h6", [
                                              _c("b", [_vm._v("To Date:")]),
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm._f("fixFullDateLocal")(
                                                      props.row.filters.to_date
                                                    )
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "col-md-4 text-blackish",
                                        }),
                                      ])
                                    : _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "el-table__empty-text",
                                            staticStyle: { color: "#606266" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        No Additional Data\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _vm.isAdmin
                          ? _c("el-table-column", {
                              attrs: {
                                "min-width": 150,
                                label: "Requested By",
                                prop: "user.name",
                                sortable: "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            formatter: _vm.formatDate,
                            "min-width": 150,
                            label: "Requested At",
                            prop: "created_at",
                            sortable: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            "min-width": 250,
                            label: "Export Type",
                            prop: "type",
                            sortable: "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n                                        Type: " +
                                      _vm._s(
                                        _vm.generateTypeText(scope.row.type)
                                      ) +
                                      ",\n                                        Format: " +
                                      _vm._s(scope.row.filters.export_type) +
                                      "\n                                    "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            formatter: _vm.getStatusDescription,
                            label: "Status",
                            prop: "status",
                            sortable: "",
                            width: "100px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            fixed: "right",
                            label: "Operations",
                            width: "150px",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { style: _vm.getStyle(scope.row.id) },
                                    [
                                      scope.row.status ===
                                      _vm.ExportStatus.FINISHED
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn greenish btn-sm btn-block",
                                              attrs: { href: scope.row.url },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-download pull-left",
                                              }),
                                              _vm._v(
                                                "\n                                                Download\n                                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      scope.row.status !==
                                      _vm.ExportStatus.IN_PROGRESS
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-danger btn-sm btn-block",
                                              attrs: {
                                                disabled: _vm.isDeletingItem(
                                                  scope.row
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteItem(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-trash pull-left",
                                              }),
                                              _vm._v(
                                                "\n                                                Delete\n                                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.pagination.last_page >= 1
                  ? _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _c("el-pagination", {
                          staticClass: "pull-right",
                          attrs: {
                            "current-page": _vm.pagination.current_page,
                            "page-size": 20,
                            "page-sizes": [10, 20, 50, 100, 250],
                            total: _vm.pagination.total,
                            background: "",
                            layout: "sizes, prev, pager, next",
                          },
                          on: {
                            "update:currentPage": function ($event) {
                              return _vm.$set(
                                _vm.pagination,
                                "current_page",
                                $event
                              )
                            },
                            "update:current-page": function ($event) {
                              return _vm.$set(
                                _vm.pagination,
                                "current_page",
                                $event
                              )
                            },
                            "current-change": _vm.pageChange,
                            "size-change": _vm.pageSizeChange,
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "clearfix" }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }