<template>
    <div
        v-loading="loading_sequences"
        class="mb-4"
        style="min-height: 50vh">
        <div
            v-if="sequences.length > 0"
            :key="sequence_list_key"
            class="container-steps mx-auto py-3 w-80">
            <div class="text-center mb-2">
                <div class="mx-auto d-inline-block">
                    <i class="fa fa-user-circle-o text-success" style="font-size: 35px;color:#dedede;"></i>
                    <div>Enrolled Contact</div>
                </div>
            </div>
            <div
                v-for="(sequence, index) in sequences"
                :key="sequence.id">
                <div class="mb-2">
                    <div class="text-center">
                        <div style="width: 3px;height: 20px;background-color: #e9e9e9; display: inline-block;border-radius: 3px 3px 0 0;vertical-align: bottom"></div>
                    </div>
                    <div class="d-flex">
                        <div class="card py-2 px-3 d-inline-block mx-auto text-center">
                            <i class="fa fa-clock-o"></i> <span v-html="sequence.delay_summary"></span>
                        </div>
                        <el-button
                            v-if="sequence.order != 1"
                            type="text"
                            small
                            @click="$refs.sequence_manager.addSequence(value, sequences[index - 1])"
                            class="text-success position-absolute right-10-per">
                            <i class="fas fa-plus"></i> Add Step
                        </el-button>
                    </div>

                    <div class="text-center">
                        <i class="fa fa-arrow-down" style="font-size: 20px;color: #e0e0e0; margin-top: -3px"></i>
                    </div>
                </div>
                <div class="card shadow-sm mb-2">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="align-self-center mr-4" style="font-size: 35px;color:#e9e9e9;line-height: 35px;vertical-align: middle;margin-top: 5px">
                                {{ index+1 }}
                            </div>
                            <img
                                :src="sequence.img_src"
                                class="align-self-center mr-3"/>
                            <div class="flex-grow-1 align-self-center mr-3">
                                <h6 class="mb-1">{{ sequence.name }}</h6>
                                <p class="mb-0" v-html="sequence.summary"></p>
                            </div>
                            <div class="align-self-center d-flex">
                                <el-popover
                                    v-if="sequence.diagnosis.length"
                                    trigger="hover"
                                    class="align-self-center">
                                    <div
                                        slot="reference"
                                        class="text-danger mr-3 cursor-pointer">
                                        <strong>
                                            <i class="fa fa-exclamation-triangle"></i> Invalid
                                        </strong>
                                    </div>
                                    <div class="p-2">
                                        <h6 class="text-dark mb-2">Diagnosis</h6>
                                        <table>
                                            <tr
                                                v-for="(diagnosis_item, index) in sequence.diagnosis"
                                                :key="index">
                                                <td class="pr-2">
                                                    <i class="fa fa-remove text-danger"></i>
                                                </td>
                                                <td>
                                                    {{ diagnosis_item }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </el-popover>
                                <el-button
                                    v-if="hasSequencePreview(sequence)"
                                    size="small"
                                    round
                                    class="mr-3"
                                    @click.native="setSequencePreview(sequence, !sequence.preview)">
                                    <span v-if="!sequence.preview">
                                        Preview <i class="fa fa-caret-down"></i>
                                    </span>
                                    <span v-else>
                                        Hide <i class="fa fa-caret-up"></i>
                                    </span>
                                </el-button>
                                <el-button-group>
                                    <el-button
                                        title="Edit step"
                                        size="small"
                                        @click="$refs.sequence_manager.editSequence(sequence)">
                                        <i class="fa fa-edit"></i>
                                    </el-button>
                                    <el-button
                                        title="Move up"
                                        size="small"
                                        :disabled="index === 0 || sequence.enroll_workflow_id > 0"
                                        @click="moveSequenceUp(index)">
                                        <i class="fa fa-arrow-up"></i>
                                    </el-button>
                                    <el-button
                                        title="Move down"
                                        :disabled="isMoveDownDisabled(index)"
                                        size="small"
                                        @click="moveSequenceDown(index)">
                                        <i class="fa fa-arrow-down"></i>
                                    </el-button>
                                    <el-button
                                        title="Delete"
                                        size="small"
                                        @click="$refs.sequence_manager.deleteSequence(sequence)">
                                        <i class="fa fa-trash-o text-danger"></i>
                                    </el-button>
                                </el-button-group>
                            </div>
                        </div>

                        <div v-if="sequenceTypeHasPreview(sequence.type)" v-show="sequence.preview" class="sequence-step-preview py-4">
                            <div
                                v-if="[Sequence.TYPE_MMS, Sequence.TYPE_SMS_LEAD, Sequence.TYPE_SMS_RING_GROUP].includes(sequence.type)"
                                class="text-center">
                                <div class="sequence-message">
                                    <div v-if="sequence.message_file" class="mb-3">
                                        <div v-if="sequence.message_file.includes('.gif')">
                                            <img
                                                :src="sequence.message_file"
                                                class="img-fluid"/>
                                        </div>
                                        <div v-else>
                                            <img
                                                :src="'/static/uploaded_file/' + sequence.message_file"
                                                class="img-fluid"/>
                                        </div>
                                    </div>
                                    {{ sequence.message_tts }}
                                    <i class="fa fa-caret-left"></i>
                                </div>
                            </div>

                            <div v-if="sequence.type === Sequence.TYPE_RVM && sequence.message_file !== null"
                                 class="text-center">
                                <div class="sequence-message">
                                    <audio controls>
                                        <source :src="'/static/uploaded_file/' + sequence.message_file">
                                        Your browser does not support the audio element.
                                    </audio>
                                    <i class="fa fa-caret-left"></i>
                                </div>
                            </div>

                            <div v-if="isEmailTypeSequence(sequence)"
                                 class="text-center">
                                <div class="sequence-message">
                                    <div class="mb-2">
                                        <strong>Subject:</strong> {{ sequence.message_subject }}
                                    </div>
                                    <div>
                                        <strong>Email Body:</strong>
                                        <p v-html="sequence.message_body" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <div class="mb-2 mt-3">
                    <i class="fa fa-arrow-down" style="font-size: 20px;color: #e0e0e0"></i>
                </div>
                <el-button
                    type="success"
                    round
                    @click="$refs.sequence_manager.addSequence(value, last_sequence)">
                    <strong>
                        <i class="fa fa-plus"></i> Add Step
                    </strong>
                </el-button>
            </div>
        </div>

        <div
            v-if="sequences.length <= 0"
            class="text-center py-5">
            <div class="w-50 mx-auto">
                <img-empty />
            </div>
            <p class="lead">
                No steps has been setup for this sequence yet.
            </p>
            <el-button
                type="success"
                round
                @click="$refs.sequence_manager.addSequence(value, last_sequence)">
                <strong>
                    <i class="fa fa-plus"></i> Add Step
                </strong>
            </el-button>
        </div>

        <sequence-manager ref="sequence_manager" @before-close="fetchSequences"/>
    </div>
</template>

<script>
import ImgEmpty from "../misc/img-empty"
import SequenceManager from "./sequence-manager"
import * as Workflow from "../../constants/workflow"
import * as Sequence from "../../constants/sequence"

export default {
    name: "workflow-steps-list",

    components: {SequenceManager, ImgEmpty},

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            Workflow,
            Sequence,
            sequences: [],
            loading_sequences: true,
            sequence_list_key: 1
        }
    },

    created() {
        this.fetchSequences()
    },

    computed: {
        last_sequence() {
            return _.last(this.sequences)
        }
    },

    methods: {
        fetchSequences() {
            this.loading_sequences = true
            axios.get(`/api/v1/automations/sequences/${this.value.id}`).then(res => {
                this.sequences = res.data
                this.loading_sequences = false

                // sets all the preview to true
                this.sequences.forEach((sequence) => {
                    sequence.preview = true
                })

                this.$emit('step-changed')
            })
        },

        sequenceTypeHasPreview(type) {
            return [
                Sequence.TYPE_MMS,
                Sequence.TYPE_SMS_LEAD,
                Sequence.TYPE_SMS_RING_GROUP,
                Sequence.TYPE_EMAIL_RING_GROUP,
                Sequence.TYPE_EMAIL_LEAD,
                Sequence.TYPE_RVM
            ].includes(type)
        },

        setSequencePreview(sequence, value) {
            sequence.preview = value
            this.sequence_list_key++
        },

        hasSequencePreview(sequence) {
            return [Sequence.TYPE_MMS, Sequence.TYPE_SMS_LEAD, Sequence.TYPE_RVM, Sequence.TYPE_SMS_RING_GROUP, Sequence.TYPE_EMAIL_RING_GROUP].includes(sequence.type)
        },

        moveSequenceUp(index) {
            this.$confirm('Are you sure you want to move this step up? This will affect the flow of the sequence.', 'Change Step Order', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.swapSequences(index, index-1);
                this.sequence_list_key++

                axios.put(`/api/v1/automations/workflows/${this.value.id}/steps-order`, {
                    sequence_ids: _.map(this.sequences, 'id')
                }).then(res => {
                    this.fetchSequences()
                    this.$notify({
                        offset: 75,
                        title: 'Sequence Step Order',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                        duration: 3000
                    })
                })
            }).catch(() => {})
        },

        moveSequenceDown(index) {
            this.$confirm('Are you sure you want to move this step down? This will affect the flow of the sequence.', 'Change Step Order', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.swapSequences(index, index+1);
                this.sequence_list_key++

                axios.put(`/api/v1/automations/workflows/${this.value.id}/steps-order`, {
                    sequence_ids: _.map(this.sequences, 'id')
                }).then(res => {
                    this.fetchSequences()
                    this.$notify({
                        offset: 75,
                        title: 'Sequence Step Order',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                        duration: 3000
                    })
                })
            }).catch(() => {})
        },

        isMoveDownDisabled(index) {
            const last_step_in_list = index === this.sequences.length-1
            const next_step_is_sequence_trigger = this.sequences[index+1]?.enroll_workflow_id > 0
            return last_step_in_list || next_step_is_sequence_trigger
        },

        swapSequences(index1, index2) {
            [this.sequences[index1], this.sequences[index2]] = [this.sequences[index2], this.sequences[index1]]
        },

        isEmailTypeSequence(sequence) {
            return [Sequence.TYPE_EMAIL_LEAD, Sequence.TYPE_EMAIL_RING_GROUP].includes(sequence.type)
        }
    },

    watch: {
        value: function() {
            this.fetchSequences()
        }
    }
}
</script>
