var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row nav-padding" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "d-flex mb-0 mt-2 ml-0" }, [
        _c(
          "div",
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.urlShortenerEnabled,
                  callback: function ($$v) {
                    _vm.urlShortenerEnabled = $$v
                  },
                  expression: "urlShortenerEnabled",
                },
              },
              [
                _vm._v(
                  "\n                    Enable URL Shortener\n                "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml-auto" },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "left-start",
                  width: "400",
                  trigger: "click",
                },
              },
              [
                _c(
                  "el-form",
                  { attrs: { "label-position": "top" } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Enter a long URL to make a short URL",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 4,
                            maxlength: "300",
                            "show-word-limit": "",
                            placeholder: "Insert link",
                          },
                          model: {
                            value: _vm.form.long_url,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "long_url", $$v)
                            },
                            expression: "form.long_url",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "pull-right",
                    attrs: {
                      type: "primary",
                      size: "small",
                      loading: _vm.saving,
                      icon: "el-icon-link",
                    },
                    on: { click: _vm.generateUrl },
                  },
                  [_vm._v("Shorten")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      slot: "reference",
                      type: "success",
                      icon: "el-icon-plus",
                    },
                    slot: "reference",
                  },
                  [_vm._v("Generate URL")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex flex-row my-3 px-3" }, [
      _c(
        "div",
        { staticClass: "mr-2" },
        [
          _c("el-input", {
            ref: "urlSearch",
            attrs: {
              placeholder: "Search",
              "prefix-icon": "el-icon-search",
              clearable: "",
              autofocus: "",
            },
            on: { input: _vm.search },
            model: {
              value: _vm.search_text,
              callback: function ($$v) {
                _vm.search_text = $$v
              },
              expression: "search_text",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-row align-items-center" },
        [
          _c("span", { staticClass: "mr-2" }, [_vm._v("URL Created At:")]),
          _vm._v(" "),
          _c("date-range-selector", { attrs: { filter: _vm.filter } }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "no-effect" }, [
        _c("div", { staticClass: "box-header pb-0" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm.pagination.total
                ? _c("span", { staticClass: "pull-left font-120" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.pagination.total || 0))]),
                    _vm._v(
                      "\n                                    " +
                        _vm._s(_vm.pagination.total > 1 ? "URLs" : "URL") +
                        "\n                                "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "box-body pt-0" }, [
          _c(
            "div",
            { on: { mouseleave: _vm.tableLeave } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    data: _vm.urls,
                    "default-sort": { prop: "id", order: "ascending" },
                    fit: "",
                    stripe: "",
                  },
                  on: { "cell-mouse-enter": _vm.cellEnter },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "short",
                      width: "230",
                      label: "Short URL",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "status d-flex align-items-center justify-content-left",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: scope.row.short,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                      " +
                                        _vm._s(scope.row.short) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "original",
                      label: "Original URL",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "status d-flex align-items-center justify-content-left",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: scope.row.original,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                      " +
                                        _vm._s(scope.row.original) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sent",
                      label: "Sent",
                      align: "center",
                      width: "120px",
                      sortable: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "total_clicks",
                      label: "Clicks",
                      align: "center",
                      width: "120px",
                      sortable: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sent",
                      label: "Click Rate",
                      align: "center",
                      width: "120px",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.calcSentRate(props.row)) +
                                "\n                            "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "created_at",
                      label: "Created At",
                      align: "center",
                      width: "150px",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm._f("fixFullDateUTCRelative")(
                                    props.row.created_at
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "template",
                    { staticClass: "text-center text-muted", slot: "empty" },
                    [
                      !_vm.loading ? _c("span", [_vm._v("No Data")]) : _vm._e(),
                      _vm._v(" "),
                      _vm.loading ? _c("span") : _vm._e(),
                    ]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.pagination.page_count >= 1 && _vm.pagination.total > 0
            ? _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c("el-pagination", {
                    staticClass: "pull-right",
                    attrs: {
                      background: "",
                      layout: "sizes, prev, pager, next",
                      "page-sizes": [10, 20, 50, 100, 250],
                      "page-size": _vm.pagination.per_page,
                      "current-page": _vm.pagination.page,
                      total: _vm.pagination.total,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.pagination, "page", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.pagination, "page", $event)
                      },
                      "current-change": _vm.fetchUrls,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "clearfix" }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label" }, [
      _c("h5", [_vm._v("URL Shortener")]),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "Here you can enable or disable auto Short URL generation feature for SMS/MMS forms."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }