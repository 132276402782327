var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-8" },
      [
        _c("div", { staticClass: "row b-b" }, [
          _c(
            "div",
            { staticClass: "col-12 p-3" },
            [_c("company-form-token")],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 p-3" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Example POST URL:")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "text-dark-greenish _600" }, [
                _vm._v(
                  _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                    "/api/v1/webhook/sequence-enroll"
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c(
              "pre",
              {
                directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
                key: _vm.renderRefreshToken + "_se_a",
              },
              [
                _c("code", { staticClass: "json" }, [
                  _vm._v("{\n    "),
                  _c("span", { staticClass: "text-dark-greenish _600" }, [
                    _vm._v("// Authentication Token"),
                  ]),
                  _vm._v(
                    '\n    "api_token": "' +
                      _vm._s(_vm.companyToken) +
                      '", // Required\n    "sequence_id": "4567", // Required\n    '
                  ),
                  _c("span", { staticClass: "text-dark-greenish _600" }, [
                    _vm._v("// Properties"),
                  ]),
                  _vm._v(
                    '\n    "force_enroll": true, // Optional | Default - false\n    "source": "hubspot", // Required\n    "id": 311, // Required if source is not phone_number\n    "phone_number": "8181234567", // Required if source is phone_number\n}'
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c(
              "pre",
              {
                directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
              },
              [
                _c("code", { staticClass: "json" }, [
                  _vm._v(
                    '{\n    "Accept": "application/json",\n    "Content-Type": "application/json"\n}'
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._m(5),
            _vm._v(" "),
            _c(
              "pre",
              {
                directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
              },
              [
                _c("code", { staticClass: "json" }, [
                  _vm._v(
                    '{\n    "message": "Contact is successfully enrolled to the sequence"\n}'
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._m(6),
            _vm._v(" "),
            _c(
              "pre",
              {
                directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
              },
              [
                _c("code", { staticClass: "json" }, [
                  _vm._v(
                    '{\n    "message": "Contact is already enrolled to a sequence.",\n}'
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._m(7),
            _vm._v(" "),
            !_vm.loading_whitelabel
              ? _c(
                  "pre",
                  {
                    directives: [
                      { name: "highlightjs", rawName: "v-highlightjs" },
                    ],
                    key: _vm.renderRefreshToken + "_se_b",
                    staticClass: "mb-3",
                  },
                  [
                    _c("code", { staticClass: "bash" }, [
                      _vm._v(
                        "curl --location --request POST '" +
                          _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                          "/api/v1/webhook/sequence-enroll' \\\n--header 'Content-Type: application/json' \\\n--header 'Accept: application/json' \\\n--data-raw '{\n    \"api_token\": \"" +
                          _vm._s(_vm.companyToken) +
                          '",\n    "sequence_id": "4567",\n    "force_enroll": true,\n    "source": "aloware",\n    "id": 142231,\n}\''
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("el-divider", { staticClass: "my-5" }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 p-3" }, [
            _vm._m(8),
            _vm._v(" "),
            _vm._m(9),
            _vm._v(" "),
            _vm._m(10),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Example POST URL:")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "text-dark-greenish _600" }, [
                _vm._v(
                  _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                    "/api/v1/webhook/sequence-disenroll"
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(11),
            _vm._v(" "),
            _c(
              "pre",
              {
                directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
                key: _vm.renderRefreshToken + "_se_c",
              },
              [
                _c("code", { staticClass: "json" }, [
                  _vm._v("{\n    "),
                  _c("span", { staticClass: "text-dark-greenish _600" }, [
                    _vm._v("// Authentication Token"),
                  ]),
                  _vm._v(
                    '\n    "api_token": "' +
                      _vm._s(_vm.companyToken) +
                      '", // Required\n    '
                  ),
                  _c("span", { staticClass: "text-dark-greenish _600" }, [
                    _vm._v("// Properties"),
                  ]),
                  _vm._v(
                    '\n    "source": "hubspot", // Required\n    "id": 311, // Required if source is not phone_number\n    "phone_number": "8181234567", // Required if source is phone_number\n}'
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._m(12),
            _vm._v(" "),
            _c(
              "pre",
              {
                directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
              },
              [
                _c("code", { staticClass: "json" }, [
                  _vm._v(
                    '{\n    "Accept": "application/json",\n    "Content-Type": "application/json"\n}'
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._m(13),
            _vm._v(" "),
            _c(
              "pre",
              {
                directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
              },
              [
                _c("code", { staticClass: "json" }, [
                  _vm._v(
                    '{\n    "message": "Contact is disenrolled from all sequences."\n}'
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._m(14),
            _vm._v(" "),
            !_vm.loading_whitelabel
              ? _c(
                  "pre",
                  {
                    directives: [
                      { name: "highlightjs", rawName: "v-highlightjs" },
                    ],
                    key: _vm.renderRefreshToken + "_se_d",
                    staticClass: "mb-3",
                  },
                  [
                    _c("code", { staticClass: "bash" }, [
                      _vm._v(
                        "curl --location --request POST '" +
                          _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                          "/api/v1/webhook/sequence-disenroll' \\\n--header 'Content-Type: application/json' \\\n--header 'Accept: application/json' \\\n--data-raw '{\n    \"api_token\": \"" +
                          _vm._s(_vm.companyToken) +
                          '",\n    "source": "aloware",\n    "id": 142231,\n}\''
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.statics.whitelabel
              ? _c("p", { staticClass: "_600" }, [
                  _vm._v(
                    "\n                        If you need more API functions, please contact our support at support@aloware.com.\n                    "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-3 d-none d-md-block ml-auto" }, [
      _c(
        "div",
        {
          staticClass:
            "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
        },
        [
          _c("h6", { staticClass: "list-group-item" }, [
            _vm._v("\n                    On This Page\n                "),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "list-group-item list-group-item-action",
              on: {
                click: function ($event) {
                  return _vm.shine("enroll")
                },
              },
            },
            [
              _vm._v(
                "\n                    Sequence Enroll API\n                "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "list-group-item list-group-item-action",
              on: {
                click: function ($event) {
                  return _vm.shine("disenroll")
                },
              },
            },
            [
              _vm._v(
                "\n                    Sequence Disenroll API\n                "
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "el-form-item mb-2" }, [
      _c("span", { staticClass: "page-anchor", attrs: { id: "enroll" } }),
      _vm._v(" "),
      _c("strong", { staticClass: "integration-api-titles" }, [
        _vm._v("Enroll a contact to a sequence through API"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-md" }, [
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("POST /api/v1/webhook/sequence-enroll"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Required Fields:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("api_token"),
      ]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("sequence_id"),
      ]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("source"),
      ]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("phone_number "),
        _c("span", { staticClass: "text-black-lt" }, [
          _vm._v("(if source is 'phone_number')"),
        ]),
      ]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("id "),
        _c("span", { staticClass: "text-black-lt" }, [
          _vm._v(
            "(if source is not 'phone_number', e.g. aloware, hubspot, zoho, guesty and pipedrive)"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Example POST body:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-muted" }, [
        _vm._v(
          "\n                                            The code sample below represents some example JSON with\n                                            standard fields to pass in the body of your request in\n                                            order to enroll a contact to a sequence:\n                                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Example POST header:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 200 response on successful contact sequence enrollment, Example\n                            success\n                            response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 400 on failure with a detailed response, Example\n                            failure\n                            response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("cURL Example:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "el-form-item mb-2" }, [
      _c("span", { staticClass: "page-anchor", attrs: { id: "disenroll" } }),
      _vm._v(" "),
      _c("strong", { staticClass: "integration-api-titles" }, [
        _vm._v("Disenroll a contact from a sequence through API"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-md" }, [
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("POST /api/v1/webhook/sequence-disenroll"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Required Fields:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("api_token"),
      ]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("source"),
      ]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("phone_number "),
        _c("span", { staticClass: "text-black-lt" }, [
          _vm._v("(if source is 'phone_number')"),
        ]),
      ]),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("id "),
        _c("span", { staticClass: "text-black-lt" }, [
          _vm._v(
            "(if source is not 'phone_number', e.g. aloware, hubspot, zoho, guesty and pipedrive)"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Example POST body:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-muted" }, [
        _vm._v(
          "\n                                            The code sample below represents some example JSON with\n                                            standard fields to pass in the body of your request in\n                                            order to enroll a contact to a sequence:\n                                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Example POST header:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 200 response on successful contact sequence dis enrollment, Example\n                            success\n                            response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("cURL Example:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }