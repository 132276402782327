var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list sms template")
    ? _c(
        "div",
        { staticClass: "w-100" },
        [
          _vm.user_id
            ? _c("template-list", {
                staticClass: "pt-2",
                attrs: {
                  template_scope: "user",
                  short: false,
                  user_id: _vm.user_id,
                },
              })
            : _c("template-list", {
                staticClass: "pt-2",
                attrs: { template_scope: "company", short: false },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }