<template>
    <div v-loading="is_loading">
        <div class="container-fluid">
            <div class="container-listing px-0">
                <div class="container-header d-flex">
                    <div class="listing-heading">
                        <h3 class="mb-0">What's included in your plan</h3>
                    </div>
                </div>
                <div class="container-body">
                    <div class="row row-cols-3">
                        <template v-for="(usage_group, i) in usages">
                            <div v-for="(usage, index) in usage_group"
                                 v-if="shouldShowGauge(index)"
                                 class="col-3">
                                <company-plan-usage-gauge :usage="usage"></company-plan-usage-gauge>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "../../../auth"
import CompanyPlanUsageGauge from "./company-plan-usage-gauge"
import * as Plan from "../../../constants/plan"

export default {
    name: "company-plan-usage",

    components: {CompanyPlanUsageGauge},

    props: {
        company: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            auth: auth,
            is_loading: false,
            usages: null,
            plan: {},
            Plan
        }
    },

    computed: {
        isPlanPAYG() {
            // chain check until plan
            const plan = this?.auth?.user?.profile?.usage?.plan

            return plan && plan.type === Plan.TYPE_PAY_AS_YOU_GO
        },

        hasTranscriptionEnabled() {
            // chain check until transcription enabled
            return this?.company?.transcription_enabled
        },

        hasAloAiEnabled() {
            // chain check until aloai enabled
            return this?.company?.aloai_enabled
        }
    },

    mounted() {
        this.fetchCompanyPlanUsage()
    },

    methods: {
        fetchCompanyPlanUsage() {
            this.is_loading = true
            axios.get(`/api/v1/company/usage/plan`).then(res => {
                this.usages = res.data.data
                this.plan = res.data.plan
                this.is_loading = false
            })
        },

        shouldShowGauge(index) {
            return (this.isPlanPAYG && index !== 'transcription' && index !== 'trial_lrn_lookup') || (this.hasTranscriptionEnabled && index === 'transcription') || (this.hasAloAiEnabled && index === 'aloai_enrollment') || (this.company.is_trial && index === 'trial_lrn_lookup')
        }
    },
}
</script>
