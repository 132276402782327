<template>
    <el-dialog
        width="50vw"
        class="aup-dialog"
        top="4vh"
        :visible.sync="show_form"
        :append-to-body="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose">
        <div slot="title">
            <h5 class="mb-0">
                <i class="fa fa-file-signature"></i>
                Notice on Carrier Fees for SMS and MMS
            </h5>
        </div>

        <div class="break-word">
            <p class="text-md _400">
                Welcome to {{ statics.name }}!
            </p>

            <p class="text-md _400">
                Dear {{ user.profile.name }},
            </p>

            <p class="text-md _400">
                As you embark on your journey with us, we want to ensure you're fully informed about important details regarding carrier fees associated with our messaging services.
            </p>

            <p class="text-md _400">
                Beginning last year, major US carriers (AT&T, T-Mobile, Verizon) have implemented various fees on SMS/MMS traffic. We've made it our priority to transparently pass these fees through to you without any markup.
            </p>

            <p class="text-md _400">
                For a comprehensive understanding of these fees and our approach, please refer to:
                <ul>
                    <li>
                        <el-link
                            :underline="false"
                            href="https://support.aloware.com/en/articles/9032003-a2p-10dlc-fees-brand-registration-and-campaign-costs"
                            target="_blank"
                            type="primary">
                            A2P 10DLC Fees: Brand Registration and Campaign Costs
                        </el-link>
                    </li>
                    <li>
                        <el-link
                            :underline="false"
                            href="https://support.aloware.com/en/articles/9031981-a2p-10dlc-compliance-brand-registration-guide"
                            target="_blank"
                            type="primary">
                            A2P 10DLC Compliance: Brand Registration Guide
                        </el-link>
                    </li>
                </ul>
            </p>

            <p class="text-md _400">
                Your acknowledgement of this notice serves as formal authorization for us to collect these fees and we will be recording your acceptance as well.
            </p>

            <p class="text-md _400">
                This is a non-refundable fee.
            </p>

            <p class="text-md _400">Date: <span class="text-decoration-underline">{{ now }}</span></p>
            <p class="text-md _400">Name: <span class="text-decoration-underline">{{ user.profile.name }}</span></p>
        </div>

        <el-checkbox
            class="checkbox-md mt-2"
            :disabled="loading"
            v-model="agreed">
            I acknowledge and authorize the collection of carrier fees.
        </el-checkbox>

        <span
            slot="footer"
            class="dialog-footer">
            <el-button
                type="success"
                :disabled="!agreed || loading"
                @click="confirming">
                Agree
            </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import auth from '../auth'

    export default {
        name: "agreement-on-sms-fees",

        props: {
            show_form: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                user: auth.user,
                now: moment().format('MMM D, YYYY'),
                agreed: false,
                loading: false,
                statics: {}
            }
        },

        created() {
            this.getStatics()
        },

        methods: {
            confirming() {
                this.loading = true

                axios.patch('/api/v1/user/' + auth.user.profile.id + '/agree-to-carrier-fees')
                    .then(res => {
                        if (res.data) {
                            this.agreed = true

                            this.$emit('agreed')
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        console.log(err)

                        this.loading = false

                        this.$notify.error({
                            title: 'Agreement on SMS Fees',
                            message: 'Failed to update. Please report this to our tech support.',
                            timeout: 5000
                        })

                        this.$emit('close')
                })
            },
            handleClose () {
                this.$emit('close')
            },
            getStatics() {
                axios.get('/get-statics').then(res => {
                    this.statics = res.data
                }).catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
            }
        }
    }
</script>
