var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.campaigns.length == 0 &&
    !_vm.loading &&
    !_vm.hasReporterAccess &&
    !_vm.loading_whitelabel
    ? _c("div", { staticClass: "padding mt-5" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 text-center" },
            [
              _c("h1", { staticClass: "welcome-head" }, [
                _vm._v("Welcome to " + _vm._s(_vm.name)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "welcome-message mb-0" }, [
                _vm._v("Add a number and begin your journey"),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "welcome-message mt-0 text-bluish font-italic" },
                [
                  _vm._v(
                    "we have pre-filled the reports with some nice\n                plots. As soon as your calls come in, you'll see actual info."
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.hasPermissionTo("create campaign")
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-block greenish add-campaign",
                      on: { click: _vm.openModal },
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v("\n                New Number\n            "),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermissionTo("create campaign")
                ? _c(
                    "el-dialog",
                    {
                      attrs: {
                        visible: _vm.dialogVisible,
                        top: "10vh",
                        "append-to-body": "",
                        "custom-class": "wizard-dialog dialog-no-border",
                        "before-close": _vm.beforeCloseModal,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogVisible = $event
                        },
                        close: _vm.closeModal,
                      },
                    },
                    [
                      _c("line-wizard", {
                        key: _vm.prop_counter,
                        on: { created: _vm.campaignCreated },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }