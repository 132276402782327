<template>
    <input-field label="Create a Password"
                 placeholder="Type here"
                 is-password
                 :padding-classes="passingClasses"
                 :col-span="colSpan"
                 v-model="password"
                 @input="onInput">
        <template v-slot:hint
                  v-if="password">
            <ul class="text-left pl-2 text-weight-regular password-hint">
                <li>
                    <el-icon class="mr-xs"
                             :class="getPasswordRuleClass(validatePasswordLength)"
                             :name="iconForValidation(validatePasswordLength)">
                    </el-icon>
                    8 characters long
                </li>
                <li>
                    <el-icon class="mr-xs"
                             :class="getPasswordRuleClass(validatePasswordCases)"
                             :name="iconForValidation(validatePasswordCases)">
                    </el-icon>
                    Must contain upper and lower case letters
                </li>
                <li>
                    <el-icon class="mr-xs"
                             :class="getPasswordRuleClass(validatePasswordDigit)"
                             :name="iconForValidation(validatePasswordDigit)">
                    </el-icon>
                    Include at least one numerical digit
                </li>
            </ul>
        </template>
    </input-field>
</template>

<script>
export default {
    props: {
        value: String,
        minLength: {
            type: Number,
            default: 8
        },
        colSpan: {
            type: Number,
            default: 12
        },
        passingClasses: {
            type: String,
            default: ''
        },
    },

    data() {
        return {
            password: this.value || '',
        }
    },

    watch: {
        password(newVal) {
            this.$emit('input', newVal)
            this.$emit('valid', this.isValid)
        }
    },

    computed: {
        validatePasswordLength() {
            return this.password.length >= this.minLength
        },
        validatePasswordCases() {
            return /[a-z]/.test(this.password) && /[A-Z]/.test(this.password)
        },
        validatePasswordDigit() {
            return /\d/.test(this.password)
        },
        isValid() {
            return this.validatePasswordLength && this.validatePasswordCases && this.validatePasswordDigit
        }
    },

    methods: {
        getPasswordRuleClass(rule) {
            return rule ? 'text-green' : 'text-red'
        },
        iconForValidation(rule) {
            return rule ? 'check' : 'close'
        },
        onInput() {
            this.$emit('input', this.password)
            this.$emit('valid', this.isValid)
        }
    }
}
</script>
