var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "account-registration row" },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loading-overlay" }, [
            _c("div", [
              _c("img", {
                staticClass: "loading-icon",
                attrs: { alt: "Loading", src: "/assets/images/loading.svg" },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "loading-text" }, [
                _vm._v(_vm._s(_vm.loadingText)),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.statics.name
        ? _c(
            "div",
            {
              staticClass: "px-10 bg-white",
              class: [_vm.shouldShowBanner ? "col-8" : "col-12"],
            },
            [
              _c("div", { staticClass: "logo absolute-top pt-xl px-xl" }, [
                _c("img", {
                  staticStyle: { width: "60px" },
                  attrs: { src: _vm.statics.logo_square, alt: "Logo" },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "stepper__container pt-20" },
                [
                  _c("step-header", {
                    attrs: {
                      description:
                        "We are thrilled for you to better communicate with your customers today. Set your password and create your account",
                      company_name: _vm.statics.name,
                      "is-mobile": _vm.isMobile,
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "stepper__content" }, [
                    _vm.shouldShowBanner || _vm.isTablet
                      ? _c(
                          "div",
                          { staticClass: "min-w-90 w-90" },
                          [
                            _c("input-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("input-field", {
                                          ref: "first_name-input",
                                          attrs: {
                                            label: "First Name",
                                            placeholder: "Type your first name",
                                            paddingClasses: _vm.isLargeScreen
                                              ? "pr-4"
                                              : "",
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            rules: [
                                              _vm.validateFieldError(
                                                "first_name"
                                              ),
                                            ],
                                            disabled:
                                              _vm.checkIfFieldIsPreFilled(
                                                "first_name"
                                              ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.cleanFieldError(
                                                "first_name"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm.fieldErrors.first_name
                                                ? {
                                                    key: "hint",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(
                                                          "\n                                " +
                                                            _vm._s(
                                                              _vm.fieldErrors
                                                                .first_name[0]
                                                            ) +
                                                            "\n                                "
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  }
                                                : null,
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.form.first_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "first_name",
                                                $$v
                                              )
                                            },
                                            expression: "form.first_name",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("input-field", {
                                          ref: "last_name-input",
                                          attrs: {
                                            label: "Last Name",
                                            placeholder: "Type your last name",
                                            paddingClasses: _vm.isLargeScreen
                                              ? "pl-4"
                                              : "",
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            rules: [
                                              _vm.validateFieldError(
                                                "last_name"
                                              ),
                                            ],
                                            disabled:
                                              _vm.checkIfFieldIsPreFilled(
                                                "last_name"
                                              ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.cleanFieldError(
                                                "last_name"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm.fieldErrors.last_name
                                                ? {
                                                    key: "hint",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.fieldErrors
                                                                .last_name[0]
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  }
                                                : null,
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.form.last_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "last_name",
                                                $$v
                                              )
                                            },
                                            expression: "form.last_name",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1416325121
                              ),
                            }),
                            _vm._v(" "),
                            _c("input-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("input-field", {
                                          ref: "email-input",
                                          attrs: {
                                            label: "Email Address",
                                            placeholder: "youremail@domain.com",
                                            type: "email",
                                            paddingClasses: _vm.isLargeScreen
                                              ? "pr-4"
                                              : "",
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            rules: [
                                              _vm.validateEmail,
                                              _vm.validateFieldError("email"),
                                            ],
                                            disabled:
                                              _vm.checkIfFieldIsPreFilled(
                                                "email"
                                              ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.cleanFieldError(
                                                "email"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.email,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "email", $$v)
                                            },
                                            expression: "form.email",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.isCompanyRegistration
                                          ? _c("phone-number-field", {
                                              ref: "phone_number-input",
                                              attrs: {
                                                label: "Phone Number",
                                                placeholder: "222 333 4444",
                                                rules: [
                                                  _vm.validatePhoneNumber,
                                                  _vm.validateFieldError(
                                                    "phone_number"
                                                  ),
                                                ],
                                                paddingClasses:
                                                  _vm.isLargeScreen
                                                    ? "pl-4"
                                                    : "",
                                                "col-span":
                                                  _vm.twoColumnsOrThreeColumnsForm,
                                                disabled:
                                                  _vm.checkIfFieldIsPreFilled(
                                                    "phone_number"
                                                  ),
                                                "country-code": _vm.countryCode,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.cleanFieldError(
                                                    "phone_number"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.phoneNumberValue,
                                                callback: function ($$v) {
                                                  _vm.phoneNumberValue = $$v
                                                },
                                                expression: "phoneNumberValue",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2754447253
                              ),
                            }),
                            _vm._v(" "),
                            _vm.isCompanyRegistration
                              ? _c("input-group", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "content",
                                        fn: function () {
                                          return [
                                            _c("select-field", {
                                              ref: "country-input",
                                              attrs: {
                                                label: "Country",
                                                placeholder:
                                                  "Ex: United States",
                                                "option-label": "name",
                                                "option-value": "id",
                                                rules: [
                                                  _vm.validateFieldError(
                                                    "country"
                                                  ),
                                                ],
                                                paddingClasses:
                                                  _vm.isLargeScreen
                                                    ? "pl-4"
                                                    : "",
                                                "col-span":
                                                  _vm.twoColumnsOrThreeColumnsForm,
                                                options: _vm.countries,
                                                disabled:
                                                  _vm.checkIfFieldIsPreFilled(
                                                    "country"
                                                  ),
                                              },
                                              model: {
                                                value: _vm.form.country,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "country",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.country",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("input-field", {
                                              ref: "company_name-input",
                                              attrs: {
                                                label: "Business Name",
                                                placeholder: "Ex. Aloware Inc.",
                                                paddingClasses:
                                                  _vm.isLargeScreen
                                                    ? "pr-4"
                                                    : "",
                                                "col-span":
                                                  _vm.twoColumnsOrThreeColumnsForm,
                                                rules: [
                                                  _vm.validateFieldError(
                                                    "company_name"
                                                  ),
                                                ],
                                                disabled:
                                                  _vm.checkIfFieldIsPreFilled(
                                                    "company_name"
                                                  ),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.cleanFieldError(
                                                    "company_name"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.company_name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "company_name",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.company_name",
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4047841303
                                  ),
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("input-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("password-input", {
                                          attrs: {
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                          },
                                          on: {
                                            valid: function ($event) {
                                              return _vm.updatePasswordValid(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.password,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression: "form.password",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("password-confirmation-input", {
                                          attrs: {
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            "main-password": _vm.form.password,
                                          },
                                          on: {
                                            match: function ($event) {
                                              return _vm.updatePasswordsMatch(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.form.password_confirmation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "password_confirmation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.password_confirmation",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1971283901
                              ),
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "accept-box" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "mb-3 q-pr-xs",
                                          attrs: {
                                            color: "primary",
                                            dense: "",
                                          },
                                          model: {
                                            value: _vm.form.agreed_to_terms,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "agreed_to_terms",
                                                $$v
                                              )
                                            },
                                            expression: "form.agreed_to_terms",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "default" }, [
                                            _vm._v(
                                              "\n                                        I agree to\n                                        "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-bluish _600",
                                                attrs: {
                                                  href: _vm.statics
                                                    .link_terms_conditions,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Terms and Conditions\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                        and\n                                        "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-bluish _600",
                                                attrs: {
                                                  href: _vm.statics
                                                    .acceptable_use_policy,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Acceptable Use Policy.\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.site_key
                                    ? _c("vue-recaptcha", {
                                        ref: "recaptcha",
                                        staticClass: "g-recaptcha pt-3",
                                        attrs: { sitekey: _vm.site_key },
                                        on: {
                                          verify: _vm.onVerifyRecaptcha,
                                          expired: _vm.onExpiredRecaptcha,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm.isMobile
                      ? _c(
                          "div",
                          { staticClass: "min-w-90 w-90" },
                          [
                            _c("input-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("input-field", {
                                          ref: "first_name-input",
                                          attrs: {
                                            label: "First Name",
                                            placeholder: "Type your first name",
                                            paddingClasses: _vm.isLargeScreen
                                              ? "pr-4"
                                              : "",
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            rules: [
                                              _vm.validateFieldError(
                                                "first_name"
                                              ),
                                            ],
                                            disabled:
                                              _vm.checkIfFieldIsPreFilled(
                                                "first_name"
                                              ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.cleanFieldError(
                                                "first_name"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm.fieldErrors.first_name
                                                ? {
                                                    key: "hint",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(
                                                          "\n                                " +
                                                            _vm._s(
                                                              _vm.fieldErrors
                                                                .first_name[0]
                                                            ) +
                                                            "\n                                "
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  }
                                                : null,
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.form.first_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "first_name",
                                                $$v
                                              )
                                            },
                                            expression: "form.first_name",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2579111943
                              ),
                            }),
                            _vm._v(" "),
                            _c("input-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("input-field", {
                                          ref: "last_name-input",
                                          attrs: {
                                            label: "Last Name",
                                            placeholder: "Type your last name",
                                            paddingClasses: _vm.isLargeScreen
                                              ? "pl-4"
                                              : "",
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            rules: [
                                              _vm.validateFieldError(
                                                "last_name"
                                              ),
                                            ],
                                            disabled:
                                              _vm.checkIfFieldIsPreFilled(
                                                "last_name"
                                              ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.cleanFieldError(
                                                "last_name"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm.fieldErrors.last_name
                                                ? {
                                                    key: "hint",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.fieldErrors
                                                                .last_name[0]
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  }
                                                : null,
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.form.last_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "last_name",
                                                $$v
                                              )
                                            },
                                            expression: "form.last_name",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                477317042
                              ),
                            }),
                            _vm._v(" "),
                            _c("input-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("input-field", {
                                          ref: "email-input",
                                          attrs: {
                                            label: "Email Address",
                                            placeholder: "youremail@domain.com",
                                            type: "email",
                                            paddingClasses: _vm.isLargeScreen
                                              ? "pr-4"
                                              : "",
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            rules: [
                                              _vm.validateEmail,
                                              _vm.validateFieldError("email"),
                                            ],
                                            disabled:
                                              _vm.checkIfFieldIsPreFilled(
                                                "email"
                                              ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.cleanFieldError(
                                                "email"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.email,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "email", $$v)
                                            },
                                            expression: "form.email",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2478997252
                              ),
                            }),
                            _vm._v(" "),
                            _vm.isCompanyRegistration
                              ? _c("input-group", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "content",
                                        fn: function () {
                                          return [
                                            _c("phone-number-field", {
                                              ref: "phone_number-input",
                                              attrs: {
                                                label: "Phone Number",
                                                placeholder: "222 333 4444",
                                                rules: [
                                                  _vm.validatePhoneNumber,
                                                  _vm.validateFieldError(
                                                    "phone_number"
                                                  ),
                                                ],
                                                paddingClasses:
                                                  _vm.isLargeScreen
                                                    ? "pl-4"
                                                    : "",
                                                "col-span":
                                                  _vm.twoColumnsOrThreeColumnsForm,
                                                disabled:
                                                  _vm.checkIfFieldIsPreFilled(
                                                    "phone_number"
                                                  ),
                                                "country-code": _vm.countryCode,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.cleanFieldError(
                                                    "phone_number"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.phoneNumberValue,
                                                callback: function ($$v) {
                                                  _vm.phoneNumberValue = $$v
                                                },
                                                expression: "phoneNumberValue",
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2425923926
                                  ),
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isCompanyRegistration
                              ? _c("input-group", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "content",
                                        fn: function () {
                                          return [
                                            _c("select-field", {
                                              ref: "country-input",
                                              attrs: {
                                                label: "Country",
                                                placeholder:
                                                  "Ex: United States",
                                                "option-label": "name",
                                                "option-value": "id",
                                                rules: [
                                                  _vm.validateFieldError(
                                                    "country"
                                                  ),
                                                ],
                                                paddingClasses:
                                                  _vm.isLargeScreen
                                                    ? "pl-4"
                                                    : "",
                                                "col-span":
                                                  _vm.twoColumnsOrThreeColumnsForm,
                                                options: _vm.countries,
                                                disabled:
                                                  _vm.checkIfFieldIsPreFilled(
                                                    "country"
                                                  ),
                                              },
                                              model: {
                                                value: _vm.form.country,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "country",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.country",
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1587110403
                                  ),
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isCompanyRegistration
                              ? _c("input-group", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "content",
                                        fn: function () {
                                          return [
                                            _c("input-field", {
                                              ref: "company_name-input",
                                              attrs: {
                                                label: "Business Name",
                                                placeholder: "Ex. Aloware Inc.",
                                                paddingClasses:
                                                  _vm.isLargeScreen
                                                    ? "pr-4"
                                                    : "",
                                                "col-span":
                                                  _vm.twoColumnsOrThreeColumnsForm,
                                                rules: [
                                                  _vm.validateFieldError(
                                                    "company_name"
                                                  ),
                                                ],
                                                disabled:
                                                  _vm.checkIfFieldIsPreFilled(
                                                    "company_name"
                                                  ),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.cleanFieldError(
                                                    "company_name"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.company_name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "company_name",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.company_name",
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2869181024
                                  ),
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("input-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("password-input", {
                                          attrs: {
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                          },
                                          on: {
                                            valid: function ($event) {
                                              return _vm.updatePasswordValid(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.password,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression: "form.password",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                991807221
                              ),
                            }),
                            _vm._v(" "),
                            _c("input-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("password-confirmation-input", {
                                          attrs: {
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            "main-password": _vm.form.password,
                                          },
                                          on: {
                                            match: function ($event) {
                                              return _vm.updatePasswordsMatch(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.form.password_confirmation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "password_confirmation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.password_confirmation",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3873614652
                              ),
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "accept-box" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "mb-3 q-pr-xs",
                                          attrs: {
                                            color: "primary",
                                            dense: "",
                                          },
                                          model: {
                                            value: _vm.form.agreed_to_terms,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "agreed_to_terms",
                                                $$v
                                              )
                                            },
                                            expression: "form.agreed_to_terms",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "default" }, [
                                            _vm._v(
                                              "\n                                        I agree to\n                                        "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-bluish _600",
                                                attrs: {
                                                  href: _vm.statics
                                                    .link_terms_conditions,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Terms and Conditions\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                        and\n                                        "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-bluish _600",
                                                attrs: {
                                                  href: _vm.statics
                                                    .acceptable_use_policy,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Acceptable Use Policy.\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.site_key
                                    ? _c("vue-recaptcha", {
                                        ref: "recaptcha",
                                        staticClass: "g-recaptcha pt-3",
                                        attrs: { sitekey: _vm.site_key },
                                        on: {
                                          verify: _vm.onVerifyRecaptcha,
                                          expired: _vm.onExpiredRecaptcha,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "min-w-90 w-90" },
                          [
                            _c("input-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("input-field", {
                                          ref: "first_name-input",
                                          attrs: {
                                            label: "First Name",
                                            placeholder: "Type your first name",
                                            paddingClasses: _vm.isLargeScreen
                                              ? "pr-4"
                                              : "",
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            rules: [
                                              _vm.validateFieldError(
                                                "first_name"
                                              ),
                                            ],
                                            disabled:
                                              _vm.checkIfFieldIsPreFilled(
                                                "first_name"
                                              ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.cleanFieldError(
                                                "first_name"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm.fieldErrors.first_name
                                                ? {
                                                    key: "hint",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(
                                                          "\n                                " +
                                                            _vm._s(
                                                              _vm.fieldErrors
                                                                .first_name[0]
                                                            ) +
                                                            "\n                                "
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  }
                                                : null,
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.form.first_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "first_name",
                                                $$v
                                              )
                                            },
                                            expression: "form.first_name",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("input-field", {
                                          ref: "last_name-input",
                                          attrs: {
                                            label: "Last Name",
                                            placeholder: "Type your last name",
                                            paddingClasses: _vm.isLargeScreen
                                              ? "pl-4"
                                              : "",
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            rules: [
                                              _vm.validateFieldError(
                                                "last_name"
                                              ),
                                            ],
                                            disabled:
                                              _vm.checkIfFieldIsPreFilled(
                                                "last_name"
                                              ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.cleanFieldError(
                                                "last_name"
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm.fieldErrors.last_name
                                                ? {
                                                    key: "hint",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.fieldErrors
                                                                .last_name[0]
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  }
                                                : null,
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.form.last_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "last_name",
                                                $$v
                                              )
                                            },
                                            expression: "form.last_name",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("input-field", {
                                          ref: "email-input",
                                          attrs: {
                                            label: "Email Address",
                                            placeholder: "youremail@domain.com",
                                            type: "email",
                                            paddingClasses: _vm.isLargeScreen
                                              ? "pr-4"
                                              : "",
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            rules: [
                                              _vm.validateEmail,
                                              _vm.validateFieldError("email"),
                                            ],
                                            disabled:
                                              _vm.checkIfFieldIsPreFilled(
                                                "email"
                                              ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.cleanFieldError(
                                                "email"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.email,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "email", $$v)
                                            },
                                            expression: "form.email",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                69239153
                              ),
                            }),
                            _vm._v(" "),
                            _vm.isCompanyRegistration
                              ? _c("input-group", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "content",
                                        fn: function () {
                                          return [
                                            _c("phone-number-field", {
                                              ref: "phone_number-input",
                                              attrs: {
                                                label: "Phone Number",
                                                placeholder: "222 333 4444",
                                                rules: [
                                                  _vm.validatePhoneNumber,
                                                  _vm.validateFieldError(
                                                    "phone_number"
                                                  ),
                                                ],
                                                paddingClasses:
                                                  _vm.isLargeScreen
                                                    ? "pl-4"
                                                    : "",
                                                "col-span":
                                                  _vm.twoColumnsOrThreeColumnsForm,
                                                disabled:
                                                  _vm.checkIfFieldIsPreFilled(
                                                    "phone_number"
                                                  ),
                                                "country-code": _vm.countryCode,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.cleanFieldError(
                                                    "phone_number"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.phoneNumberValue,
                                                callback: function ($$v) {
                                                  _vm.phoneNumberValue = $$v
                                                },
                                                expression: "phoneNumberValue",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("select-field", {
                                              ref: "country-input",
                                              attrs: {
                                                label: "Country",
                                                placeholder:
                                                  "Ex: United States",
                                                "option-label": "name",
                                                "option-value": "id",
                                                rules: [
                                                  _vm.validateFieldError(
                                                    "country"
                                                  ),
                                                ],
                                                paddingClasses:
                                                  _vm.isLargeScreen
                                                    ? "pl-4"
                                                    : "",
                                                "col-span":
                                                  _vm.twoColumnsOrThreeColumnsForm,
                                                options: _vm.countries,
                                                disabled:
                                                  _vm.checkIfFieldIsPreFilled(
                                                    "country"
                                                  ),
                                              },
                                              model: {
                                                value: _vm.form.country,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "country",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.country",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("input-field", {
                                              ref: "company_name-input",
                                              attrs: {
                                                label: "Business Name",
                                                placeholder: "Ex. Aloware Inc.",
                                                paddingClasses:
                                                  _vm.isLargeScreen
                                                    ? "pr-4"
                                                    : "",
                                                "col-span":
                                                  _vm.twoColumnsOrThreeColumnsForm,
                                                rules: [
                                                  _vm.validateFieldError(
                                                    "company_name"
                                                  ),
                                                ],
                                                disabled:
                                                  _vm.checkIfFieldIsPreFilled(
                                                    "company_name"
                                                  ),
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.cleanFieldError(
                                                    "company_name"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.company_name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "company_name",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.company_name",
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    483494741
                                  ),
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("input-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("password-input", {
                                          attrs: {
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                          },
                                          on: {
                                            valid: function ($event) {
                                              return _vm.updatePasswordValid(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.password,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression: "form.password",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("password-confirmation-input", {
                                          attrs: {
                                            "col-span":
                                              _vm.twoColumnsOrThreeColumnsForm,
                                            "main-password": _vm.form.password,
                                          },
                                          on: {
                                            match: function ($event) {
                                              return _vm.updatePasswordsMatch(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.form.password_confirmation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "password_confirmation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.password_confirmation",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1971283901
                              ),
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "accept-box" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "mb-3 q-pr-xs",
                                          attrs: {
                                            color: "primary",
                                            dense: "",
                                          },
                                          model: {
                                            value: _vm.form.agreed_to_terms,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "agreed_to_terms",
                                                $$v
                                              )
                                            },
                                            expression: "form.agreed_to_terms",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "default" }, [
                                            _vm._v(
                                              "\n                                        I agree to\n                                        "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-bluish _600",
                                                attrs: {
                                                  href: _vm.statics
                                                    .link_terms_conditions,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Terms and Conditions\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                        and\n                                        "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-bluish _600",
                                                attrs: {
                                                  href: _vm.statics
                                                    .acceptable_use_policy,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Acceptable Use Policy.\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.site_key
                                    ? _c("vue-recaptcha", {
                                        ref: "recaptcha",
                                        staticClass: "g-recaptcha pt-3",
                                        attrs: { sitekey: _vm.site_key },
                                        on: {
                                          verify: _vm.onVerifyRecaptcha,
                                          expired: _vm.onExpiredRecaptcha,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-actions mx-lg" }, [
                    _c(
                      "div",
                      { staticClass: "mx-xl-0" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn-block mt-3 signup-submit__button",
                            attrs: {
                              color: "primary",
                              size: "md",
                              label: "Create my Account",
                              rounded: "",
                              dense: "",
                              "no-caps": "",
                              unelevated: "",
                              disabled: _vm.isNextButtonDisabled,
                            },
                            on: { click: _vm.onSubmit },
                          },
                          [
                            _c(
                              "i",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isLoading,
                                    expression: "isLoading",
                                  },
                                ],
                                staticClass: "material-icons loader pull-right",
                              },
                              [
                                _vm._v(
                                  "\n                            \n                        "
                                ),
                              ]
                            ),
                            _vm._v(
                              "\n                        Create my Account\n                        "
                            ),
                            _c("i", {
                              staticClass:
                                "el-icon-arrow-right el-icon-right pull-right",
                              attrs: { show: "!isLoading" },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.statics.name && _vm.shouldShowBanner
        ? _c("banner", {
            attrs: { company_name: _vm.statics.name, "current-step": _vm.step },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }