var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full width-500" },
    [
      _c("div", { staticClass: "login-brand" }, [
        _c("img", { attrs: { src: _vm.statics.logo, alt: "." } }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dashboard-intro mt-3 mb-3" },
        [
          _c("input-group", {
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("input-field", {
                      attrs: {
                        label: "Email",
                        type: "email",
                        placeholder: "youremail@domain.com",
                        "col-span": 24,
                      },
                      model: {
                        value: _vm.user.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "email", $$v)
                        },
                        expression: "user.email",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("input-group", {
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("password-input", {
                      attrs: { "col-span": 24 },
                      on: {
                        valid: function ($event) {
                          return _vm.updatePasswordValid($event)
                        },
                      },
                      model: {
                        value: _vm.user.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "password", $$v)
                        },
                        expression: "user.password",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("input-group", {
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("password-confirmation-input", {
                      attrs: {
                        "col-span": 24,
                        "main-password": _vm.user.password,
                      },
                      on: {
                        match: function ($event) {
                          return _vm.updatePasswordsMatch($event)
                        },
                      },
                      model: {
                        value: _vm.user.password_confirmation,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "password_confirmation", $$v)
                        },
                        expression: "user.password_confirmation",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("vue-recaptcha", {
            ref: "recaptcha",
            staticClass: "g-recaptcha",
            attrs: { sitekey: _vm.site_key },
            on: { verify: _vm.onVerify, expired: _vm.onExpired },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "p-x-md btn-block mt-2",
              attrs: { type: "success", disabled: _vm.isButtonDisabled },
              on: { click: _vm.resetPass },
            },
            [
              _c(
                "i",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "material-icons loader pull-right",
                },
                [_vm._v("")]
              ),
              _vm._v("\n            Reset Password\n            "),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
                staticClass: "el-icon-arrow-right el-icon-right pull-right",
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("static-assets-preloader", { attrs: { assets: _vm.statics } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }