var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-blank inbox-filter-dialog",
      attrs: {
        width: "700px",
        top: "5vh",
        "append-to-body": "",
        "before-close": _vm.closeDialog,
        visible: _vm.visible,
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "filter_form",
          attrs: { model: _vm.filter_form, rules: _vm.rules },
        },
        [
          _c(
            "div",
            { staticClass: "row pl-3 pr-3" },
            [
              _c(
                "div",
                { staticClass: "d-none d-sm-none d-md-flex col-md-4" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "mb-0 filter-name w-100",
                      attrs: { prop: "name", error: _vm.errors.first("name") },
                    },
                    [
                      _vm.is_name_on_edit || !_vm.filter_form.name
                        ? _c("el-input", {
                            ref: "filterName",
                            attrs: {
                              clearable: "",
                              size: "medium",
                              placeholder: "(Unsaved) Filter",
                            },
                            on: {
                              blur: _vm.handleFilterNameBlur,
                              focus: _vm.focusFilterName,
                            },
                            model: {
                              value: _vm.filter_form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter_form, "name", $$v)
                              },
                              expression: "filter_form.name",
                            },
                          })
                        : _c(
                            "div",
                            [
                              _c("span", { staticClass: "filter-name-text" }, [
                                _vm._v(_vm._s(_vm.filter_form.name)),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass:
                                    "pt-0 pb-0 pl-2 filter-name-edit",
                                  attrs: { type: "text" },
                                  on: { click: _vm.editFilterName },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass: "material-icons font-14-400",
                                    },
                                    [_vm._v("create")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-none d-sm-none d-md-flex col-md-4 justify-content-center align-items-center",
                  },
                  [
                    _c("span", { staticClass: "dialog-title" }, [
                      _vm._v(_vm._s(_vm.modalTitle)),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex d-sm-flex d-md-none col-6 col-sm-6 align-items-center",
                  },
                  [
                    _c("span", { staticClass: "dialog-title" }, [
                      _vm._v(_vm._s(_vm.modalTitle)),
                    ]),
                  ]
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-6 col-sm-6 col-md-4 text-right" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-cancel-button",
                      attrs: { size: "mini" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "light",
                        disabled: !_vm.disableSaveButton,
                        content: _vm.hasModel
                          ? "No change detected."
                          : "Please input name and specify any filter.",
                        placement: "top",
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "disabled-wrapper el-button" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "item form-save-button",
                              attrs: {
                                disabled: _vm.disableSaveButton,
                                size: "mini",
                              },
                              on: { click: _vm.validateForm },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.hasModel ? "Update" : "Save as New"
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex d-sm-flex d-md-none col-md-12 mt-3 w-100" },
            [
              _vm.is_name_on_edit || !_vm.filter_form.name
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "mb-0 filter-name w-100",
                      attrs: { prop: "name", error: _vm.errors.first("name") },
                    },
                    [
                      _c("el-input", {
                        ref: "filterName",
                        staticClass: "w-100",
                        attrs: {
                          clearable: "",
                          size: "medium",
                          placeholder: "(Unsaved) Filter",
                        },
                        on: { blur: _vm.handleFilterNameBlur },
                        model: {
                          value: _vm.filter_form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter_form, "name", $$v)
                          },
                          expression: "filter_form.name",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("span", { staticClass: "filter-name-text" }, [
                        _vm._v(_vm._s(_vm.filter_form.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "pt-0 pb-0 pl-2 filter-name-edit",
                          attrs: { type: "text" },
                          on: { click: _vm.editFilterName },
                        },
                        [
                          _c(
                            "i",
                            { staticClass: "material-icons font-14-400" },
                            [_vm._v("create")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "inbox-main-form pl-3 pr-3" }, [
            _c("div", { staticClass: "mt-4" }, [
              _c("div", { staticClass: "inbox-filter-section-title" }, [
                _vm._v("Quick Access"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "handling-section-override",
                        attrs: { label: "Time", prop: "time" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w-100 borderless-input input-pl-0",
                            attrs: { size: "medium", placeholder: "Select" },
                            on: { "visible-change": _vm.showDatePicker },
                            model: {
                              value: _vm.range_type,
                              callback: function ($$v) {
                                _vm.range_type = $$v
                              },
                              expression: "range_type",
                            },
                          },
                          _vm._l(_vm.time_options, function (type) {
                            return _c("el-option", {
                              key: type.value,
                              attrs: { label: type.label, value: type.value },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          ref: "timePicker",
                          staticClass: "hidden-date-field",
                          attrs: {
                            type: "daterange",
                            size: "medium",
                            "start-placeholder": "Start date",
                            "end-placeholder": "End date",
                            "picker-options": _vm.pickerOptions,
                          },
                          on: { change: _vm.dateTimePicked },
                          model: {
                            value: _vm.hidden_date_value,
                            callback: function ($$v) {
                              _vm.hidden_date_value = $$v
                            },
                            expression: "hidden_date_value",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "override-switch",
                        attrs: { label: "My Contacts", prop: "my_contact" },
                      },
                      [
                        _c("el-switch", {
                          attrs: { "active-color": "#00BF4A" },
                          on: { change: _vm.handleMyContactsChange },
                          model: {
                            value: _vm.filter_form.filter.my_contact,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filter_form.filter,
                                "my_contact",
                                $$v
                              )
                            },
                            expression: "filter_form.filter.my_contact",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Lines", prop: "lines" } },
                      [
                        _c("campaign-selector", {
                          attrs: {
                            multiple: true,
                            small: true,
                            clearable: true,
                            size: "medium",
                            collapsable: true,
                          },
                          on: { change: _vm.addCampaign },
                          model: {
                            value: _vm.filter_form.filter.campaigns,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter_form.filter, "campaigns", $$v)
                            },
                            expression: "filter_form.filter.campaigns",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.showRingGroupSelector
                  ? _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-6" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Ring Groups",
                              prop: "ring_groups",
                            },
                          },
                          [
                            _c("ring-group-selector", {
                              attrs: {
                                multiple: true,
                                size: "medium",
                                collapsable: true,
                              },
                              on: { change: _vm.addRingGroup },
                              model: {
                                value: _vm.filter_form.filter.ring_groups,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter_form.filter,
                                    "ring_groups",
                                    $$v
                                  )
                                },
                                expression: "filter_form.filter.ring_groups",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _vm.type !== _vm.TYPE_INBOX
              ? _c("div", { staticClass: "mt-3" }, [
                  _c("div", { staticClass: "inbox-filter-section-title" }, [
                    _vm._v("Handling"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-6 col-lg-3" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "handling-section-override",
                            attrs: { label: "Direction", prop: "direction" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass:
                                  "w-100 borderless-input input-pl-0",
                                attrs: {
                                  size: "medium",
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "Select",
                                },
                                on: { clear: _vm.directionSelectorCleared },
                                model: {
                                  value: _vm.filter_form.filter.direction,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter_form.filter,
                                      "direction",
                                      $$v
                                    )
                                  },
                                  expression: "filter_form.filter.direction",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: "All",
                                    value:
                                      _vm.CommunicationDirection
                                        .DIRECTION_ALL_TEXT,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "Inbound",
                                    value:
                                      _vm.CommunicationDirection
                                        .DIRECTION_INBOUND_TEXT,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "Outbound",
                                    value:
                                      _vm.CommunicationDirection
                                        .DIRECTION_OUTBOUND_TEXT,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showAnswerStatusSelector
                      ? _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-6 col-lg-3" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "handling-section-override",
                                attrs: {
                                  label: "Answer Status",
                                  prop: "answer_status",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass:
                                      "w-100 borderless-input input-pl-0",
                                    attrs: {
                                      size: "medium",
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "Select",
                                    },
                                    on: {
                                      clear: _vm.answerStatusSelectorCleared,
                                    },
                                    model: {
                                      value:
                                        _vm.filter_form.filter.answer_status,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter_form.filter,
                                          "answer_status",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filter_form.filter.answer_status",
                                    },
                                  },
                                  _vm._l(
                                    _vm.AnswerStatuses.ANSWER_STATUSES,
                                    function (answer_status) {
                                      return _c("el-option", {
                                        key: answer_status.value,
                                        attrs: {
                                          label: answer_status.label,
                                          value: answer_status.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showTalkTimeSelector
                      ? _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-6 col-lg-3" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "handling-section-override",
                                attrs: {
                                  label: "Talk Time",
                                  prop: "talk_time",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass:
                                      "w-100 borderless-input input-pl-0",
                                    attrs: {
                                      size: "medium",
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "Select",
                                    },
                                    on: { clear: _vm.talkTimeSelectorCleared },
                                    model: {
                                      value:
                                        _vm.filter_form.filter.min_talk_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter_form.filter,
                                          "min_talk_time",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filter_form.filter.min_talk_time",
                                    },
                                  },
                                  _vm._l(
                                    _vm.TalkTimes.TALK_TIMES,
                                    function (talk_time) {
                                      return _c("el-option", {
                                        key: talk_time.value,
                                        attrs: {
                                          label: talk_time.label,
                                          value: talk_time.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showTransferTypeSelector
                      ? _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-6 col-lg-3" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "handling-section-override",
                                attrs: {
                                  label: "Transfer Type",
                                  prop: "transfer_type",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass:
                                      "w-100 borderless-input input-pl-0",
                                    attrs: {
                                      size: "medium",
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "Select",
                                    },
                                    model: {
                                      value:
                                        _vm.filter_form.filter.transfer_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter_form.filter,
                                          "transfer_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filter_form.filter.transfer_type",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: "Cold Transfer",
                                        value:
                                          _vm.CommunicationTransferTypes
                                            .TRANSFER_TYPE_COLD,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: "Warm Transfer",
                                        value:
                                          _vm.CommunicationTransferTypes
                                            .TRANSFER_TYPE_WARM,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: "Conference",
                                        value:
                                          _vm.CommunicationTransferTypes
                                            .TRANSFER_TYPE_CONF,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showCallbackStatusSelector
                      ? _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-6 col-lg-3" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "handling-section-override",
                                attrs: {
                                  label: "Callback Status",
                                  prop: "callback_status",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass:
                                      "w-100 borderless-input input-pl-0",
                                    attrs: {
                                      size: "medium",
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "Select",
                                    },
                                    model: {
                                      value:
                                        _vm.filter_form.filter.callback_status,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter_form.filter,
                                          "callback_status",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filter_form.filter.callback_status",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: "Initiated",
                                        value:
                                          _vm.CallbackStatuses
                                            .CALLBACK_STATUS_INITIATED,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: "Requested",
                                        value:
                                          _vm.CallbackStatuses
                                            .CALLBACK_STATUS_REQUESTED,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.type !== _vm.TYPE_INBOX
              ? _c("div", { staticClass: "mt-3" }, [
                  _c("div", { staticClass: "inbox-filter-section-title" }, [
                    _vm._v("Properties"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-6" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Tags", prop: "tags" } },
                          [
                            _c("tag-selector", {
                              attrs: {
                                multiple: true,
                                size: "medium",
                                clearable: true,
                              },
                              on: { change: _vm.addTag },
                              model: {
                                value: _vm.filter_form.filter.tags,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter_form.filter, "tags", $$v)
                                },
                                expression: "filter_form.filter.tags",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showCallDispositionStatusSelector
                      ? _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-6" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Call Dispositions",
                                  prop: "call_dispositions",
                                },
                              },
                              [
                                _c("call-disposition-selector", {
                                  attrs: {
                                    multiple: true,
                                    size: "medium",
                                    clearable: true,
                                  },
                                  on: { change: _vm.addCallDisposition },
                                  model: {
                                    value:
                                      _vm.filter_form.filter.call_dispositions,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter_form.filter,
                                        "call_dispositions",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filter_form.filter.call_dispositions",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-3" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "override-switch",
                            attrs: {
                              label: "Show Only First-time Communications",
                              prop: "show_first_time_communications",
                            },
                          },
                          [
                            _c("el-switch", {
                              staticClass: "w-100",
                              attrs: { "active-color": "#00BF4A" },
                              model: {
                                value: _vm.filter_form.filter.first_time_only,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter_form.filter,
                                    "first_time_only",
                                    $$v
                                  )
                                },
                                expression:
                                  "filter_form.filter.first_time_only",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-3" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "override-switch",
                            attrs: {
                              label: "Show Only Untagged Communications",
                              prop: "show_untagged_communications",
                            },
                          },
                          [
                            _c("el-switch", {
                              staticClass: "w-100",
                              attrs: { "active-color": "#00BF4A" },
                              model: {
                                value: _vm.filter_form.filter.untagged_only,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter_form.filter,
                                    "untagged_only",
                                    $$v
                                  )
                                },
                                expression: "filter_form.filter.untagged_only",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-3" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "override-switch",
                            attrs: {
                              label: "Exclude Comm. Sent From Sequences",
                              prop: "exclude_automated_communications",
                            },
                          },
                          [
                            _c("el-switch", {
                              staticClass: "w-100",
                              attrs: { "active-color": "#00BF4A" },
                              model: {
                                value:
                                  _vm.filter_form.filter
                                    .exclude_automated_communications,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter_form.filter,
                                    "exclude_automated_communications",
                                    $$v
                                  )
                                },
                                expression:
                                  "filter_form.filter.exclude_automated_communications",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-6 col-lg-3" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "handling-section-override",
                            attrs: {
                              label: "Creator Type",
                              prop: "creator_type",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass:
                                  "w-100 borderless-input input-pl-0",
                                attrs: {
                                  size: "medium",
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "Select",
                                },
                                model: {
                                  value: _vm.filter_form.filter.creator_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter_form.filter,
                                      "creator_type",
                                      $$v
                                    )
                                  },
                                  expression: "filter_form.filter.creator_type",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: "Manual",
                                    value:
                                      _vm.CommunicationCreatorTypes
                                        .CREATOR_TYPE_MANUAL,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "API",
                                    value:
                                      _vm.CommunicationCreatorTypes
                                        .CREATOR_TYPE_API,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "Workflow",
                                    value:
                                      _vm.CommunicationCreatorTypes
                                        .CREATOR_TYPE_WORKFLOW,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "Broadcast",
                                    value:
                                      _vm.CommunicationCreatorTypes
                                        .CREATOR_TYPE_BROADCAST,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "Power Dialer",
                                    value:
                                      _vm.CommunicationCreatorTypes
                                        .CREATOR_TYPE_POWER_DIALER,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "SMS Reminder",
                                    value:
                                      _vm.CommunicationCreatorTypes
                                        .CREATOR_TYPE_SMS_REMINDER,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "App Notifications",
                                    value:
                                      _vm.CommunicationCreatorTypes
                                        .CREATOR_TYPE_APP_NOTIFICATIONS,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "HubSpot",
                                    value:
                                      _vm.CommunicationCreatorTypes
                                        .CREATOR_TYPE_HUBSPOT,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "Zapier",
                                    value:
                                      _vm.CommunicationCreatorTypes
                                        .CREATOR_TYPE_ZAPIER,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "HighLevel",
                                    value:
                                      _vm.CommunicationCreatorTypes
                                        .CREATOR_TYPE_GOHIGHLEVEL,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "AloAi",
                                    value:
                                      _vm.CommunicationCreatorTypes
                                        .CREATOR_TYPE_ALOAI,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mt-3" }, [
              _c("div", { staticClass: "inbox-filter-section-title" }, [
                _vm._v("Attribution"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Line Phone Numbers",
                          prop: "phone_numbers",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w-100",
                            attrs: {
                              size: "medium",
                              multiple: "",
                              clearable: "",
                              filterable: "",
                              "collapse-tags": "",
                              placeholder: "Select Line Phone Numbers",
                            },
                            model: {
                              value: _vm.filter_form.filter.incoming_numbers,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.filter_form.filter,
                                  "incoming_numbers",
                                  $$v
                                )
                              },
                              expression: "filter_form.filter.incoming_numbers",
                            },
                          },
                          _vm._l(_vm.incomingNumbers, function (number) {
                            return _c("el-option", {
                              key: number.id,
                              attrs: {
                                label: _vm._f("fixPhone")(
                                  number.phone_number,
                                  "NATIONAL"
                                ),
                                value: number.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.showCommOwnerSelector
                  ? _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-6" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "display-grid",
                            attrs: { prop: "users" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "d-flex",
                                attrs: { slot: "label" },
                                slot: "label",
                              },
                              [
                                _vm._v(
                                  "\n                                Communication Owners\n                                "
                                ),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      width: "355",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "text-black" }, [
                                      _c("div", [
                                        _c("strong", [
                                          _vm._v(
                                            "Who is the communication owner?"
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "mt-2" }, [
                                        _c("div", [
                                          _c("strong", [
                                            _vm._v(
                                              "For outbound communication:"
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Calls, SMS, fax, emails:"),
                                        ]),
                                        _vm._v(" "),
                                        _c("ul", { staticClass: "mb-0" }, [
                                          _c("li", [
                                            _vm._v(
                                              "The agent that send the communication"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("div", [
                                          _c("strong", [
                                            _vm._v(
                                              "For inbound communication:"
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v("Calls:")]),
                                        _vm._v(" "),
                                        _c("ul", { staticClass: "mb-0" }, [
                                          _c("li", [
                                            _vm._v(
                                              "The agent that answered the call"
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v("SMS, fax, email:")]),
                                        _vm._v(" "),
                                        _c("ul", { staticClass: "mb-0" }, [
                                          _c("li", [
                                            _vm._v(
                                              "The most recent assigned contact owner owns all of these inbound communications"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "fas fa-info-circle ml-2",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("user-selector", {
                              attrs: {
                                size: "medium",
                                multiple: true,
                                placeholder: "Select Communication Owners",
                                hide_extensions: true,
                              },
                              on: { change: _vm.addUser },
                              model: {
                                value: _vm.filter_form.filter.users,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter_form.filter, "users", $$v)
                                },
                                expression: "filter_form.filter.users",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Sequences", prop: "sequences" } },
                      [
                        _c("workflow-selector", {
                          attrs: { multiple: true, size: "medium" },
                          on: { change: _vm.addWorkflow },
                          model: {
                            value: _vm.filter_form.filter.workflows,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter_form.filter, "workflows", $$v)
                            },
                            expression: "filter_form.filter.workflows",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Contact Owners",
                          prop: "contact_owner",
                        },
                      },
                      [
                        _c("user-selector", {
                          attrs: {
                            size: "medium",
                            multiple: true,
                            placeholder: "Select Contact Owners",
                            hide_extensions: true,
                          },
                          on: { change: _vm.addContactOwner },
                          model: {
                            value: _vm.filter_form.filter.contact_owner,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filter_form.filter,
                                "contact_owner",
                                $$v
                              )
                            },
                            expression: "filter_form.filter.contact_owner",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.showBroadcastSelector
                  ? _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-6" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "Broadcasts", prop: "broadcasts" },
                          },
                          [
                            _c("broadcast-selector", {
                              attrs: { multiple: true, size: "medium" },
                              on: { change: _vm.addBroadcast },
                              model: {
                                value: _vm.filter_form.filter.broadcasts,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter_form.filter,
                                    "broadcasts",
                                    $$v
                                  )
                                },
                                expression: "filter_form.filter.broadcasts",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }