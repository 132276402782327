<template>
    <div class="app-body">
        <div class="teams">
            <div v-if="!isEditingTeam">
                <div class="d-flex align-items-center justify-content-between px-3 pt-3">
                    <el-input class="w-50"
                              placeholder="Search Teams"
                              prefix-icon="el-icon-search"
                              clearable 
                              v-model="searchInput"
                              @input="onSearchInput" />
                    <div class="d-flex w-50 justify-content-end">
                        <el-button class="w-25"
                                   slot="reference"
                                   type="success"
                                   data-testid="team-creator-open-modal-button"
                                   @click="openTeamModalForm">
                            Create New Team
                        </el-button>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end py-3 px-3">
                    <el-pagination layout="sizes, prev, pager, next"
                                   :current-page.sync="teamsPagination.page"
                                   :page-sizes="[10, 20, 50, 100]"
                                   :page-size.sync="teamsPagination.size"
                                   :total="teamsPagination.total"
                                   @size-change="paginateTeams(1)"
                                   @current-change="paginateTeams">
                    </el-pagination>
                </div>
                <div class="px-3">
                    <el-table class="w-full company-teams-list"
                              ref="company-teams-list"
                              stripe
                              highlight-current-row
                              :data="teams"
                              v-loading="isLoading"
                              @row-click="onManageMembers">
                        <el-table-column label="Team"
                                         prop="name"
                                         sortable
                                         show-overflow-tooltip>
                            <template v-slot="scope">
                                {{ scope.row?.name }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Description"
                                         prop="description"
                                         sortable
                                         show-overflow-tooltip>
                            <template v-slot="scope">
                                {{ scope.row?.description || '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Ring Groups"
                                         prop="ring_groups"
                                         align="center">
                            <template v-slot="scope">
                                <span>{{ scope.row.total_ring_groups || 0 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Users"
                                         prop="users"
                                         align="center">
                            <template v-slot="scope">
                                <span>{{ scope.row.total_users || 0 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Created by"
                                         prop="created_by"
                                         sort-by="created_by"
                                         sortable>
                            <template v-slot="scope">
                                {{ getFullName(scope.row?.created_by) }}
                            </template>
                        </el-table-column>

                        <el-table-column label="Created on"
                                         prop="created_at"
                                         sortable>
                            <template v-slot="scope">
                                {{ scope.row?.created_at | fixFullDateLocal }}
                            </template>
                        </el-table-column>

                        <el-table-column label=""
                                         align="right">
                            <template v-slot="scope">
                                <el-dropdown trigger="click">
                                    <el-button icon="el-icon-more" 
                                               size="mini"
                                               @click.stop />
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>
                                            <div @click.stop="openEditTeamModal(scope.row)">
                                                Edit Information
                                            </div>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <div @click.stop="onManageMembers(scope.row)">
                                                Manage Members
                                            </div>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <div class="el-dropdown-menu__item--delete"
                                                  @click.stop="openDeleteTeamModal(scope.row)">
                                                Delete
                                            </div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="d-flex align-items-center justify-content-end py-3 px-3">
                    <el-pagination layout="sizes, prev, pager, next"
                                   :current-page.sync="teamsPagination.page"
                                   :page-sizes="[10, 20, 50, 100]"
                                   :page-size.sync="teamsPagination.size"
                                   :total="teamsPagination.total"
                                   @size-change="paginateTeams(1)"
                                   @current-change="paginateTeams">
                    </el-pagination>
                </div>
            </div>

            <edit-team-members :selected-team="selectedTeam"
                               :company="current_company"
                               v-else
                               @back-to-teams-list="onBackToTeamsList"/>

            <el-dialog width="30%"
                       center
                       :visible.sync="isTeamModalFormVisible">
                <div class="dialog--title mt-4"
                     slot="title">
                   {{ formTeamModalConfig.title }}
                </div>
                <el-form ref="teamFormRef"
                         :model="teamForm" 
                         :rules="rules">
                    <el-form-item label="Team Name"
                                  prop="name">
                        <el-input placeholder="Type here"
                                  :maxlength="80"
                                  v-model="teamForm.name" />
                    </el-form-item>
                    <el-form-item label="Description"
                                  prop="description">
                        <el-input placeholder="Type here"
                                  v-model="teamForm.description" />
                    </el-form-item>
                </el-form>

                <div class="d-flex justify-content-center mt-5">
                    <el-button type="success"
                               slot="reference"
                               data-testid="team-creator-create-button"
                               :disabled="isLoading"
                               @click="validateAndSubmitForm"
                               @keyup.enter.native="validateAndSubmitForm">
                        {{ formTeamModalConfig.buttonText }}
                    </el-button>
                </div>
            </el-dialog>

            <el-dialog title="Delete Team"
                       width="30%"
                       center
                       :visible.sync="isDeleteTeamModalVisible">
                <p class="break-word text-center">You're about to delete the <strong>{{ selectedTeam?.name }}</strong> Team. This action can't be undone.</p>
                <p class="break-word text-center mb-4">Are you sure?</p>
                <div class="d-flex justify-content-center mt-5 row">
                    <el-button class="mb-2"
                               type="default"
                               slot="reference"
                               @click="closeDeleteTeamModal">
                        No, Keep Team
                    </el-button>
                    <el-button class="mb-2"
                               type="danger"
                               slot="reference"
                               data-testid="team-deleter-delete-button"
                               :disabled="isLoading"
                               @click="onDeleteTeam">
                        Yes, Delete Team
                    </el-button>
                </div>
            </el-dialog>

            <el-dialog title="Delete Team"
                       width="30%"
                       center
                       :visible.sync="isDeleteTeamModalErrorVisible">
                <p>The <strong>{{ selectedTeam?.name }}</strong> Team may be being used on a Ring Group or has users assigned to it. To delete, you have to remove it from the Billing Ring Group or remove the users from the team.</p>
                <div class="d-flex justify-content-center mt-5">
                    <el-button type="default"
                               slot="reference"
                               @click="isDeleteTeamModalErrorVisible = false">
                        Close
                    </el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import auth from '../../auth'
import { acl_mixin, statics_mixin } from '../../mixins'
import { mapActions, mapState } from 'vuex'
import EditTeamMembers from '../../components/teams/edit-team-members.vue'
import { debounce } from 'lodash'

export default {
    components: {
        EditTeamMembers
    },

    mixins: [acl_mixin, statics_mixin],

    data() {
        return {
            auth: auth,
            isLoading: false,
            teams: [],
            searchInput: '',
            isTeamModalFormVisible: false,
            isDeleteTeamModalVisible: false,
            isDeleteTeamModalErrorVisible: false,
            selectedTeam: null,
            teamForm: {
                name: '',
                description: '',
            },
            rules: {
                name: [
                    { required: true, message: 'Team Name is required', trigger: 'blur' }
                ],
            },
            teamsPagination: {
                page: 1,
                size: 20,
                total: 0
            },
            isEditingTeam: false,
            totalTeams: 0
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        formTeamModalConfig() {
            if (this.selectedTeam) {
                return {
                    title: `Edit ${this.selectedTeam.name} Team`,
                    buttonText: 'Save Team',
                    action: this.onEditTeam
                }
            }

            return {
                title: 'Create a team',
                buttonText: 'Create Team',
                action: this.onSubmitCreateTeam
            }
        },

        filteredTeams() {
            if (this.searchInput.length > 0) {
                let teams = this.teams

                teams = this.teams.filter(team => {
                    return team.name.toLowerCase().includes(this.searchInput.toLowerCase())
                })
                this.teamsPagination.total = teams?.length

                return teams
            }

            this.teamsPagination.total = this.totalTeams
            return this.teams
        }
    },

    methods: {
        ...mapActions([
            'setCurrentTeam',
            'setShouldReloadAllTeams'
        ]),

        openTeamModalForm() {
            this.resetTeamForm()
            this.isTeamModalFormVisible = true
        },

        openDeleteTeamModal(team) {
            this.selectedTeam = team
            this.isDeleteTeamModalVisible = true
        },

        openEditTeamModal(team) {
            this.selectedTeam = team
            this.isTeamModalFormVisible = true
            this.teamForm = {
                name: team.name,
                description: team.description
            }
        },

        closeDeleteTeamModal() {
            this.selectedTeam = null
            this.isDeleteTeamModalVisible = false
        },

        resetTeamForm() {
            this.teamForm = {
                name: '',
                description: '',
            }
        },

        validateAndSubmitForm() {
            this.$refs.teamFormRef.validate(valid => {
                if (valid) {
                    return this.formTeamModalConfig.action();
                } 

                this.$notify({
                    title: 'Error',
                    message: 'Please fill in all required fields',
                    type: 'error',
                    showClose: true
                })
                return false;
            });
        },

        getFullName(object) {
            if (!object) {
                return '-'
            }

            return `${object?.first_name} ${object?.last_name}`
        },

        paginateTeams(page) {
            this.teamsPagination.page = page
            this.fetchTeams()
        },

        onManageMembers(team) {
            this.selectedTeam = team
            this.setCurrentTeam(team)
            this.$router.push({ name: 'Team', params: { team_id: team.id } })
        },

        onBackToTeamsList() {
            this.isEditingTeam = false
            this.selectedTeam = null
            this.fetchTeams()
        },

        fetchTeams() {
            this.isLoading = true

            const params = {
                page: this.teamsPagination.page,
                per_page: this.teamsPagination.size,
                search: this.searchInput
            }

            this.$APIV2.Teams.index(params)
                .then(res => {
                    this.teamsPagination.total = res.data?.total
                    this.totalTeams = res.data?.total
                    this.teams = res.data?.data

                    if (this.selectedTeam) {
                        this.selectedTeam = this.teams.find(team => team.id === this.selectedTeam.id)
                    }
                })
                .catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.isLoading = false
                })
        },

        updateSelectedTeam(team) {
            this.$set(this, 'selectedTeam', team)
            this.$set(this, 'teams', this.teams.map(t => {
                if (t.id === team.id) {
                    return team
                }

                return t
            }))
        },

        onSearchInput: debounce(function () {
            if (this.searchInput.length === 0 || this.searchInput.length >= 3) {
                this.teamsPagination.page = 1;
                this.fetchTeams();
            }
        }, 1000),

        handleSubmitError(errors) {
            if (errors.name) {
                this.$notify({
                    title: 'Error',
                    message: errors.name?.[0],
                    type: 'error',
                    showClose: true
                })
                return
            }
        },

        onSubmitCreateTeam() {
            this.isLoading = true

            this.$APIV2.Teams.store(this.teamForm)
                .then(res => {
                    this.isLoading = false
                    this.isTeamModalFormVisible = false
                    this.resetTeamForm()
                    this.$notify({
                        title: 'Success',
                        message: 'Team created successfully',
                        type: 'success',
                        showClose: true
                    })

                    if (res.data?.id) {
                        this.selectedTeam = res.data
                        this.setShouldReloadAllTeams(true)
                        return this.$router.push({ name: 'Team', params: { team_id: this.selectedTeam.id } })
                    }

                    this.fetchTeams()
                })
                .catch(err => {
                    this.isLoading = false
                    if (err?.response?.data?.errors) {
                        this.handleSubmitError(err.response.data.errors)
                    }
                })
        },

        onDeleteTeam() {
            this.isLoading = true

            this.$APIV2.Teams.destroy(this.selectedTeam.id)
                .then(res => {
                    this.$notify({
                        title: 'Success',
                        message: 'Team deleted successfully',
                        type: 'success',
                        showClose: true
                    })

                    this.isDeleteTeamModalVisible = false
                    this.setShouldReloadAllTeams(true)
                    this.fetchTeams()
                })
                .catch(err => {
                    this.$notify({
                        title: 'Error',
                        message: 'An error occurred while deleting the team.',
                        type: 'error',
                        showClose: true
                    })

                    this.isDeleteTeamModalVisible = true
                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        onEditTeam() {
            this.isLoading = true

            this.$APIV2.Teams.update(this.selectedTeam.id, this.teamForm)
                .then(res => {
                    this.isLoading = false
                    this.isTeamModalFormVisible = false
                    this.resetTeamForm()
                    this.$notify({
                        title: 'Success',
                        message: 'Team updated successfully',
                        type: 'success',
                        showClose: true
                    })

                    this.setShouldReloadAllTeams(true)
                    this.fetchTeams()
                })
                .catch(err => {
                    this.isLoading = false
                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
    },

    created() {
        this.fetchTeams()
        this.getStatics({ page_title: 'Teams Beta' })
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                let auth = {
                    user: {
                        profile: res.data.user
                    }
                }

                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next(vm => {
                        if (!vm.hasRole('Company Admin') && !vm.hasRole('Company Supervisor')) {
                            next({name: 'Dashboard'})
                        }
                    })
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
