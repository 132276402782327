<template>
  <div class="container-fluid pt-2">
    <div class="row">
      <div class="col-12">
        <h4 class="text-black">{{ title }}</h4>
        <p>{{ message }}</p>
        <p>Fix by:</p>
        <p>1. Merging these contacts in HubSpot</p>
        <p>- OR -</p>
        <p>2. Removing the duplicated phone number from either one of these contacts in HubSpot.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <el-button
            class="bold text-deep-orange width-300 fixed"
            size="small"
            type="deep-orange"
            :disabled="loading_hubspot_sync"
            plain
            round
            @click.prevent="syncHubspot">
          <strong>
            <i class="material-icons loader"
               v-show="loading_hubspot_sync">&#xE863;</i>
            <i class="material-icons"
               v-show="!loading_hubspot_sync">sync</i>
            <slot>Sync with HubSpot</slot>
          </strong>
        </el-button>

        <el-popover
            placement="right"
            width="350"
            trigger="hover">
          <div class="_400">
            <p>Click on this button to sync the data for this contact between Aloware and HubSpot. You'll want to click
              on
              this button if:</p>
            <ol>
              <li>The contact was recently merged in HubSpot with another contact.</li>
              <li>You notice any inconsistencies between Aloware and HubSpot data on this contact.</li>
            </ol>
          </div>
          <i class="material-icons text-blue cursor-pointer ml-2"
             slot="reference">info_outline</i>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '../../../auth'
import {mapActions} from 'vuex'
import {
  acl_mixin, avatar_mixin, contact_activities_mixin, form_validation_mixin,
} from "../../../mixins"
import * as Carriers from "../../../constants/carriers";

export default {
  mixins: [
    acl_mixin,
    avatar_mixin,
    contact_activities_mixin,
    form_validation_mixin
  ],

  props: {
    contact_id: {
      type: Number,
      default: 0
    },
    redirect_url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },

    api_key: {
      type: String,
      required: false,
      default: null
    },
  },

  data() {
    return {
      loading: false,
      initialized: false,
      loading_contact_phone_numbers: false,
      message_object: {},
      current_contact_id: null,
      loading_hubspot_sync: false,
      Carriers
    }
  },

  created() {
    // flag for this widget used in the mixin
    this.$root.$data.is_widget = true
    this.init()
  },

  watch: {
    contact_id(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.processFetchContactInfo()
      }
    },
  },

  methods: {
    init() {
      if (this.api_key) {
        localStorage.setItem('api_token', this.api_key)
      }
      this.loading = true
      auth.check().then((res) => {
        localStorage.setItem('company_id', res.data.user.company.id)
        this.setCurrentCompany(res.data.user.company)
        this.resetVuex()
        this.headers.Authorization = 'Bearer ' + this.auth.user.profile.api_token
        this.loading = false
        this.initialized = true
        if (this.contact_id) {
          this.processFetchContactInfo()
        }
      }).catch((err) => {
        this.loading = false

        if (this.api_key) {
          console.log('Error: api key is not valid', err)
        } else {
          console.log('Reloading')
          this.$router.push({name: 'Login', query: {redirect: this.$route.fullPath}})
        }
      })
    },

    processFetchContactInfo() {
      if (!this.initialized) {
        setTimeout(() => {
          this.processFetchContactInfo()
        }, 500)
        return
      }
      this.fetchContactInfo().then(res => {
        if (res && res.data) {
          this.processFetchedContactInfo(res.data)
        }
      }).catch(err => {
        console.log(err)
      });
    },

    processFetchedContactInfo(selected_contact) {
      this.message_object.contact = selected_contact
      this.fetchedContactInfo(selected_contact)
      this.loading_contact = false
    },

    syncHubspot() {
      this.loading_hubspot_sync = true
      axios.post('/api/v1/contact/' + this.contact_id + '/sync-hubspot').then(res => {
        this.loading_hubspot_sync = false
        this.$notify({
          offset: 95,
          title: 'HubSpot',
          message: 'Contact has been successfully synced.',
          type: 'success',
          showClose: true,
        })
        // open the contact in Aloware
        setTimeout(() => {
          window.location.href = this.redirect_url
        }, 500)
      }).catch(err => {
        this.$root.handleErrors(err.response)
        this.loading_hubspot_sync = false
      })
    },

    ...mapActions(['resetVuex']),
    ...mapActions('cache', ['setCurrentCompany'])
  },
}
</script>
