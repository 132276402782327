var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list disposition status")
    ? _c(
        "div",
        [
          _c(
            "el-select",
            {
              staticClass: "w-full",
              attrs: {
                disabled: _vm.disable_selector,
                placeholder: _vm.placeholder,
                multiple: _vm.multiple,
                clearable: _vm.clearable,
                size: _vm.size,
                "default-first-option": "",
                "collapse-tags": "",
                filterable: "",
              },
              model: {
                value: _vm.disposition_status_id,
                callback: function ($$v) {
                  _vm.disposition_status_id = $$v
                },
                expression: "disposition_status_id",
              },
            },
            _vm._l(
              _vm.dispositionsAlphabeticalOrder,
              function (disposition_status) {
                return _c(
                  "el-option",
                  {
                    key: disposition_status.id,
                    attrs: {
                      label: disposition_status.name,
                      value: disposition_status.id,
                    },
                  },
                  [
                    disposition_status.id != "not_disposed"
                      ? _c("i", {
                          staticClass: "fa fa-circle",
                          style: { color: disposition_status.color },
                        })
                      : _c("i", { staticClass: "fa fa-ban" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-2" }, [
                      _vm._v(_vm._s(disposition_status.name)),
                    ]),
                  ]
                )
              }
            ),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }