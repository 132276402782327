var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.active_tab_name,
            callback: function ($$v) {
              _vm.active_tab_name = $$v
            },
            expression: "active_tab_name",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "addons", label: "Current Addons" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_current,
                      expression: "loading_current",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.addons },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "Addon" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("fixCountryLetters")(
                                    _vm._f("ucwords")(
                                      _vm._f("prettifyCamelCase")(row.id)
                                    )
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Unit Price" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm._f("toCurrency")(row.unit_price)) +
                                "\n                    "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Quantity" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              "\n                        " +
                                _vm._s(row.quantity) +
                                "\n                    "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: _vm.totalPriceColumnName },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm._f("toCurrency")(row.amount)) +
                                "\n                    "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row px-3 mt-3" }, [
                _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                  _vm._v(
                    "Need a Local Presence package? \n                    "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "text-primary",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goToLines.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                       Go to lines page.\n                    "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }