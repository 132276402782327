<template>
  <div
      class="container"
      v-if="!isLoading">
    <div class="row mt-5">
      <div class="col-sm-12 col-md-8 mx-auto">
        <!-- Start Login Alert Banner -->
        <div v-if="showLoginBanner" class="alert alert-warning" role="alert">
          <h6 class="alert-heading mt-1">
            <i class="glyphicon glyphicon-log-in mt-1 mr-1" aria-hidden="true"></i>
            GO TO LOGIN PAGE
          </h6>
          <p>It looks like you're not logged in to Aloware. Open a new tab by clicking
            <a href="/login" target="_blank"
               class="font-weight-bold text-decoration-underline">
              here,
            </a>
            enter your credentials and go back to this tab
          </p>
        </div>
        <!-- End Login Alert Banner -->
        <!-- Start Forbidden Alert Banner -->
        <div v-if="showForbiddenBanner" class="alert alert-warning" role="alert">
          <h6 class="alert-heading mt-1">
            <i class="glyphicon glyphicon-lock mt-1 mr-1" aria-hidden="true"></i>
            FORBIDDEN
          </h6>
          <p>
            Sorry, but it looks like you don't have enough permissions to proceed with this action.
            Please check if your Aloware account has access to the integration settings or
            contact technical support.
          </p>
        </div>
        <!-- End Forbidden Alert Banner -->
        <!-- Start Bad Request Alert Banner -->
        <div v-if="showBadRequestBanner"
             class="alert alert-warning"
             role="alert">
          <h6 class="alert-heading mt-1">
            <i class="glyphicon glyphicon-lock mt-1 mr-1"
               aria-hidden="true">
            </i>
            WE CANNOT PROCESS YOUR REQUEST
          </h6>
          <p>
            {{ this.badRequestMessage }}
          </p>
        </div>
        <!-- End Bad Request Alert Banner -->
        <!-- Start Card -->
        <div v-if="actionIsAllowed" class="card text-center border-success py-3">
          <div class="card-body">
            <h3 class="card-title text-success">
              <i class="fa fa-check-circle mr-1"></i>
              <span>SUCCESS</span>
            </h3>
            <!-- Start card text -->
            <p class="card-text mt-3">
              You have requested to
              <span class="font-weight-bold">{{ appPanelRequest.requestName.toUpperCase() }}</span>
              to Aloware. Please wait for a few minutes for this process to finish.
              Please note that you will not immediately see the updates in Pipedrive
              and you will receive an email when we finish processing your request.
            </p>
            <!-- End card text -->
          </div>
        </div>
        <div v-if="!actionIsAllowed" class="card text-center border-danger py-3">
          <div class="card-body">
            <h3 class="card-title text-danger">
              <i class="fa fa-times mr-1"></i>
              <span>UNABLE TO PROCEED</span>
            </h3>
            <!-- Start card text -->
            <p class="card-text mt-3">
              You have requested to
              <span class="font-weight-bold">{{ appPanelRequest.requestName.toUpperCase() }}</span>
              to Aloware, but we cannot permit you to proceed. Please resolve the error messages shown
              and try again.
            </p>
            <!-- End card text -->
          </div>
        </div>
        <!-- End Card -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { handleVuexApiCall, ErrorCode, sleep } from '../../../utils/integration-helpers'

export default {
  name: 'integration-extensions-pipedrive-app-panel-request',
  data: () => ({
    showLoginBanner: false,
    showForbiddenBanner: false,
    showBadRequestBanner: false,
    badRequestMessage: '',
    authUser: null,
    isLoading: false,
    actionIsAllowed: true,

    appPanelRequest: {
      requestName: '',
      vuexAction: ''
    }
  }),
  methods: {
    ...mapActions(['fetchAuthUserForAppExtension', 'pullUsersForAppExtension', 'pullContactsForAppExtension']),
    async handleAppPanelRequest() {
      this.isLoading = true
      const vuexActionName = this.appPanelRequest.vuexAction
      const result = await handleVuexApiCall(this[vuexActionName])
      this.isLoading = false

      if (!result.success) return this.handleError(result)
    },
    handleError(result) {
      // Show login help if not authenticated
      if (result.error.code === ErrorCode.AUTHENTICATION_ERROR) {
        this.showLoginBanner = true
        this.actionIsAllowed = false
      }

      if (result.error.code === ErrorCode.AUTHORIZATION_ERROR) {
        this.showForbiddenBanner = true
        this.actionIsAllowed = false
      }

      if (result.error.code === ErrorCode.BAD_REQUEST) {
        this.showBadRequestBanner = true
        this.badRequestMessage = result.error.message
        this.actionIsAllowed = false
      }

      this.isLoading = false
    },
    async handleRequestSwitch(urlParam) {
      switch (urlParam) {
        case 'pull-contacts':
          this.appPanelRequest.requestName = 'Sync Contacts'
          this.appPanelRequest.vuexAction = 'pullContactsForAppExtension'
          break
        case 'pull-users':
          this.appPanelRequest.requestName = 'Sync Users'
          this.appPanelRequest.vuexAction = 'pullUsersForAppExtension'
          break
        default:
          // Redirect to home if none are provided
          await this.$router.replace('/')
      }
    },
    async getAuthUser() {
      const res = await handleVuexApiCall(this.fetchAuthUserForAppExtension)
      if (!res.success) return this.handleError(res)

      this.authUser = res.data
      this.showLoginBanner = false
    }
  },
  watch: {
    '$route.params.action_request': {
      handler: async function (urlParam) {
        await this.handleRequestSwitch(urlParam)
        await this.handleAppPanelRequest()
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    this.isLoading = true

    // Check if there is an authenticated user
    if (!localStorage.getItem('api_token')) {
      this.showLoginBanner = true
      this.actionIsAllowed = false
    }

    // Get auth user
    if (localStorage.getItem('api_token')) {
      await this.getAuthUser()
    }

    // listen for a log in on a different tab
    this.$tabEvent.on('user_logged_in', async () => {
      location.reload() // so we can recreate child components that need to load data from the api
    })

    this.isLoading = false
  },
  destroyed() {
    this.$tabEvent.off('user_logged_in')
  }
}
</script>
