var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      attrs: {
        content: `Type at least ${_vm.threshold} character(s)`,
        placement: "bottom",
      },
    },
    [
      _c(
        "el-select",
        {
          ref: "tag-select",
          staticClass: "w-full tag-selector",
          attrs: {
            filterable: "",
            remote: "",
            "default-first-option": "",
            placeholder: _vm.placeholder,
            multiple: _vm.multiple,
            "multiple-limit": _vm.multiple_limit,
            clearable: _vm.clearable,
            "collapse-tags": !_vm.noCollapse,
            size: _vm.size,
            disabled: _vm.disabled,
            "remote-method": _vm.getTags,
            loading: _vm.loading_tags,
          },
          on: { change: _vm.selectTag },
          model: {
            value: _vm.tag_id,
            callback: function ($$v) {
              _vm.tag_id = $$v
            },
            expression: "tag_id",
          },
        },
        [
          _vm.companyTagsAlphabeticalOrder &&
          _vm.companyTagsAlphabeticalOrder.length > 0
            ? _c(
                "el-option-group",
                { key: "Account Tags", attrs: { label: "Account Tags" } },
                _vm._l(_vm.companyTagsAlphabeticalOrder, function (tag) {
                  return _c(
                    "el-option",
                    {
                      key: tag.id,
                      attrs: {
                        label: tag.name,
                        value: tag.id,
                        disabled: _vm.disabled_options,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-circle",
                        style: { color: tag.color },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(tag.name) +
                            "\n                "
                        ),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.importTagsAlphabeticalOrder &&
          _vm.importTagsAlphabeticalOrder.length > 0
            ? _c(
                "el-option-group",
                { key: "Import Tags", attrs: { label: "Import Tags" } },
                _vm._l(_vm.importTagsAlphabeticalOrder, function (tag) {
                  return _c(
                    "el-option",
                    { key: tag.id, attrs: { label: tag.name, value: tag.id } },
                    [
                      _c("i", {
                        staticClass: "fa fa-circle",
                        style: { color: tag.color },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(tag.name) +
                            "\n                "
                        ),
                      ]),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }