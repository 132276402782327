<template>
    <div>
        <label class="p-0 m-0 _500"
               v-if="label">
            {{ label }}
        </label>
        <el-select
            v-model="ivr_id"
            :default-first-option="default_first"
            :placeholder="placeholder"
            autocomplete="off"
            class="w-full"
            clearable
            filterable
            :collapse-tags="collapsable"
            @clear="ivrChange"
            @change="ivrChange">
            <el-option
                :key="ivr.id"
                :label="ivr.name"
                :value="ivr.id"
                v-for="ivr in ivr_list">
                <span>{{ ivr.name }}</span>
            </el-option>
        </el-select>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import auth from '../auth'
    import {acl_mixin} from '../mixins'

    export default {
        mixins: [acl_mixin],

        props: {
            value: {
                required: false,
            },

            default_first: {
                type: Boolean,
                required: false,
                default: false
            },

            first_value: {
                type: String,
                required: false,
                default: null
            },

            label: {
                required: false,
                default: null
            },

            collapsable: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        data() {
            return {
                auth: auth,
                ivr_id: this.value,
                ivr_list: []
            }
        },

        computed: {
            ...mapState({
                current_company: state => state.cache.current_company,
                ivrs: state => state.ivrs
            }),

            placeholder() {
                return 'Select IVR'
            },
        },

        mounted() {
            this.updateIvrList()
        },

        watch: {
            value() {
                this.ivr_id = this.value
            },

            ivr_id(val) {
                if (this.value !== undefined) {
                    this.$emit('change', val)
                }
            },

            ivrs: {
                deep: true,
                handler: function() {
                    this.updateIvrList()
                }
            }
        },

        methods: {
            ivrChange(ivr_id) {
                if (!ivr_id && this.first_value) {
                    ivr_id = 'all'
                    this.ivr_id = 'all'
                }

                this.$emit('change', ivr_id)
            },

            updateIvrList() {
                if (this.first_value) {
                    return [{name: this.first_value.capitalize(), id: this.first_value, user_ids: []}]
                }
                this.ivr_list = this.ivrs
            },
        },
    }
</script>
