export default Object.freeze([
    {
        id: 1,
        value: 'Director'
    },
    {
        id: 2,
        value: 'GM',
    },
    {
        id: 3,
        value: 'VP',
    },
    {
        id: 4,
        value: 'CEO',
    },
    {
        id: 5,
        value: 'CFO',
    },
    {
        id: 6,
        value: 'General Counsel',
    },
    {
        id: 7,
        value: 'Other',
    },
])