<template>
    <div>
        <el-dialog title="Acceptable Use Policy"
                   width="50vw"
                   class="aup-dialog"
                   top="4vh"
                   :visible.sync="aup_dialog"
                   :append-to-body="true"
                   :close-on-click-modal="false"
                   :before-close="beforeClose">
            <div slot="title">
                <h5 class="mb-0">
                    <i class="fa fa-file-signature"></i>
                    Acceptable Use Policy
                </h5>
            </div>

            <p class="text-md _400 mb-1">By using Broadcast, you certify that you have read and will adhere to
                our
                <a :href="aupLink"
                   class="text-dark-greenish"
                   target="_blank">
                    acceptable use guidelines.
                </a>
            </p>

            <p class="text-md _400">
                We are electronically capturing your consent as of {{ auth.user.profile.name }} ({{
                    auth.user.profile.email
                }}) on {{ date }}
            </p>

            <iframe sandbox="allow-same-origin"
                    class="mt-2 mb-2"
                    style="border: 0; width:100%; height:600px;"
                    :src="aupLink">
            </iframe>

            <el-checkbox v-model="aup_accepted"
                         class="checkbox-md mt-2">
                I have read and accept the Acceptable Use Policy
            </el-checkbox>

            <span slot="footer"
                  class="dialog-footer">
                <el-button @click="beforeClose">
                    Cancel
                </el-button>
                <el-button type="success"
                           :disabled="!aup_accepted"
                           @click="saveConsent">
                    Confirm
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin} from '../mixins'
import {mapState} from "vuex"

export default {
    mixins: [acl_mixin],

    data() {
        return {
            auth: auth,
            aup_dialog: false,
            aup_accepted: false,
            loading_whitelabel: false,
            host: null,
            whitelabel: false,
            date: new Date().toLocaleString('en-US', {timeStyle: "short"}),
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company
        }),

        aupLink() {
            if (this.current_company && this.current_company.reseller_id == 357) {
                return 'https://simpsocial.com/acceptable-use-guidelines'
            }

            return 'https://aloware.com/acceptable-use-guidelines-and-policy?embedded=1'
        }
    },

    mounted() {
        this.getWhitelabelStatus().then(() => {
            if (!this.auth.user.profile.has_consent && !this.whitelabel) {
                this.aup_dialog = true
            }

            if (!this.auth.user.profile.has_consent && this.current_company && this.current_company.reseller_id === 357) {
                this.aup_dialog = true
            }

            if (this.auth.user.profile.has_consent || (this.whitelabel && this.current_company && this.current_company.reseller_id !== 357)) {
                this.aup_accepted = true
                this.$emit('loaded')
            }
        })
    },

    methods: {
        getWhitelabelStatus() {
            this.loading_whitelabel = true
            return axios.get('/get-statics').then(res => {
                this.host = res.data.host
                this.whitelabel = res.data.whitelabel
                this.loading_whitelabel = false
                return Promise.resolve(res.data)
            }).catch(err => {
                console.log(err)
                this.loading_whitelabel = false
                this.$root.handleErrors(err.response)
                return Promise.reject(err)
            })
        },

        saveConsent() {
            return axios.post('/api/v1/user/' + auth.user.profile.id + '/save-consent').then((res) => {
                // success
                this.aup_accepted = true
                this.$emit('loaded')
                this.aup_dialog = false
            }).catch((err) => {
                // error
                return Promise.reject(err)
            })
        },

        beforeClose(done) {
            let message = 'Are you sure to close this dialog?'
            this.$confirm(message, 'Warning', {
                customClass: 'width-500 fixed'
            }).then(_ => {
                this.goBack()
            }).catch(_ => {
            })
        },
    }
}
</script>
