<template>
    <div data-testid="short-code-wizard-wrapper">
        <div class="container-fluid">
            <el-steps
                class="mb-3"
                :active="current_step"
                align-center
                finish-status="success"
            >
                <el-step
                    title="Information"
                    data-testid="info-step"
                />
                <el-step
                    title="Select your Package"
                    data-testid="info-step"
                />
                <el-step
                    title="Confirmation"
                    data-testid="confirmation-step"
                />
            </el-steps>
            <div class="step-1 row" v-if="current_step === 0">
                <div class="col-12">
                    <p class="form-head font-weight-bold mb-3 description wz-text-wrap">
                        <b>Unlock the Power of High-Volume SMS with Aloware Short Codes!</b>
                    </p>
                    <div class="dropdown-divider"></div>
                    <p class="wz-text-wrap">
                        Boost your brand and connect with your audience like never before:<br>
                        <ul>
                            <li>
                                <b>High-Volume Messaging</b>: Reach thousands in seconds with carrier-approved,
                                high-volume SMS delivery.
                            </li>
                            <li>
                                <b>Unstoppable Reach</b>: Approved short codes experience minimal filtering, ensuring
                                your messages reach a broader audience.
                            </li>
                            <li>
                                <b>Branded for Impact</b>: Make your message stand out with custom vanity short codes
                                that amplify brand recognition.
                            </li>
                            <li>
                                <b>Hassle-free Procurement</b>: Provides the fastest time to approval in the industry —
                                as little as 8-12 weeks.
                            </li>
                        </ul>
                    </p>
                </div>
                <div class="col-12 text-right mt-4">
                    <el-button
                        type="success"
                        @click="nextStep"
                    >
                        Next
                    </el-button>
                </div>
            </div>
            <div class="step-2 row" v-if="current_step === 1">
                <div class="col-12">
                    <p class="form-head font-weight-bold mb-3 description wz-text-wrap">
                        <b>Short Code Types and Monthly Rental Fee</b>
                    </p>
                    <div class="dropdown-divider"></div>
                    <p class="wz-text-wrap">
                        <ul>
                            <li>
                                <b>Standard Short Code:</b> This is assigned by carriers
                            </li>
                            <li>
                                <b>Vanity Short Code:</b> This is your preferred custom short code
                            </li>
                        </ul>
                    </p>
                    <!-- Dropdowns Section -->
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <el-select
                                    class="w-full"
                                    id="package-select"
                                    placeholder="Select Package"
                                    :key="current_step"
                                    v-model="selected_package"
                                >
                                    <el-option
                                        v-for="option in packages"
                                        :key="option.value"
                                        :value="option.value"
                                        :label="`${option.label} - ${option.label_price}`"
                                    >
                                            <span class="wz-option-label">
                                                {{ option.label }}
                                                <span class="wz-option-number">{{ option.label_price }}</span>
                                            </span>
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-between mt-4">
                    <el-button
                        type="info"
                        @click="previousStep"
                    >
                        Back
                    </el-button>
                    <el-button
                        type="success"
                        @click="nextStep"
                        :disabled="!isValidStep"
                    >
                        Next
                    </el-button>
                </div>
            </div>
            <div class="step-3 row" v-if="current_step === 2">
                <div class="col-12">
                    <p class="form-head font-weight-bold mb-3 wz-text-wrap">
                        <b>Short Code - Terms of Service</b>
                    </p>
                    <div class="dropdown-divider"></div>
                    <!-- Additional opt-in message -->
                    <p class="wz-text-wrap mt-3">
                        <b>Setup Fee:</b><br>
                        <b>A non-refundable application fee of $1,500</b> is required. A dedicated team member will be assigned
                        to assist you throughout the application process.
                    </p>
                    <p class="wz-text-wrap mt-3">
                        <b>Bulk Messaging Rates:</b><br>
                        Pricing is customized based on your messaging volume, and our team will provide a quote tailored
                        to your specific needs.
                    </p>
                    <p class="wz-text-wrap mt-3">
                        <b>Minimum Commitment:</b><br>
                        In accordance with carrier regulations, a minimum rental duration of 3 months is required for
                        all short codes.
                    </p>
                    <p class="wz-text-wrap">
                        By clicking <b>"Accept and Submit"</b>, you agree to these terms and allow Aloware to contact
                        you with regarding your short code application.
                    </p>
                </div>
                <div class="col-12 d-flex justify-content-between mt-4">
                    <el-button
                        type="info"
                        @click="previousStep">
                        Back
                    </el-button>
                    <el-button
                        type="success"
                        class="pull-right"
                        @click="confirmAndSubmit"
                        :disabled="loading">
                        <i class="material-icons loader pull-right" v-show="loading">&#xE863;</i>
                        Accept and Submit
                    </el-button>
                </div>
            </div>
            <div class="step-4 text-center row" v-if="current_step === 3">
                <div class="col-12">
                    <p class="wz-text-wrap">
                        <b>Your application for Short Code has been successfully submitted!</b>
                    </p>
                    <p class="wz-text-wrap">
                        Our team will reach out to you within 3 business days. Thank you!
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import shortCode from '../../../constants/short-codes'

export default {
    props: ['onShortCodeWizardComplete'],
    data() {
        return {
            current_step: 0,
            selected_package: null,
            packages: shortCode.packages,
            loading: false,
            completed: false,
        }
    },
    methods: {
        nextStep() {
            this.current_step++
        },
        previousStep() {
            if (this.current_step > 0) {
                this.current_step--
            }
            if (this.current_step === 0) {
                this.resetWizard()
            }
        },
        confirmAndSubmit() {
            this.loading = true
            const param = {
                short_code_package: this.selected_package,
            }
            axios.post('/api/v1/compliance/short-code', param)
                .then(() => {
                    this.completed = true
                    this.onShortCodeWizardComplete()
                    this.current_step++
                })
                .catch(() => {
                    this.$message.error('There was an issue submitting your request. Please try again.')
                })
                .finally(() => {
                    this.loading = false
                })
        },
        resetWizard() {
            if (!this.completed) {
                // We only reset the wizard if it wasn't completed
                this.current_step = 0
                this.selected_package = null
            }
        }
    },
    computed: {
        isValidStep() {
            return this.selected_package
        },
    }
};
</script>
