var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticClass: "no-select",
          attrs: {
            placeholder: "Filter by Ring Group",
            clearable: _vm.clearable,
            filterable: "",
          },
          on: { change: _vm.filterRingGroup },
          model: {
            value: _vm.ring_group_id_filter,
            callback: function ($$v) {
              _vm.ring_group_id_filter = $$v
            },
            expression: "ring_group_id_filter",
          },
        },
        [
          _c(
            "el-option-group",
            {
              key: "Queued Ring Groups",
              attrs: { label: "Queued Ring Groups" },
            },
            _vm._l(_vm.queued_ring_groups, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-option-group",
            {
              key: "Regular Ring Groups",
              attrs: { label: "Regular Ring Groups" },
            },
            _vm._l(_vm.regular_ring_groups, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }