<template>
    <div>
        <div class="mt-5 pt-1">
            <upgrade-now-page
                class="mt-5"
                image-link="/assets/images/aloai-voice-bot.svg"
                title-text="AloAi Voice Bot"
                text="Seamlessly handle calls, answer FAQs, route queries, and create personalized experiences at scale."
                extra-text="And yes, it can also do outbound calls for you — coming soon!"
                :show-button="false"
            />
        </div>
    </div>
</template>

<script>
import UpgradeNowPage from '../../components/upgrade-now-page'
import auth from '../../auth'

export default {
    name: 'AloAiVoiceBotIndex',

    components: {
        UpgradeNowPage,
    },

    data() {
        return {
            auth: auth,
        }
    },

    beforeRouteEnter(to, from, next) {
        // This logic help us wrap the AloAi Voice Bot section with the Aloware App components.
        if (to.query.api_token) {
            return next({
                name: 'Login',
                query: {
                    api_token: to.query.api_token
                }
            })
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // Redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            })
        })
    },
}
</script>
