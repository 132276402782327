<template>
    <div class="row nav-padding">
        <div class="col-12">
            <div class="d-flex mb-3">
                <h4 class="mb-0">Calendar Services</h4>
                <div class="ml-auto">
                    <el-button
                        type="success"
                        size="medium"
                        round
                        @click="$refs.calendar_service_modal.add()">
                        <strong>Add New</strong>
                    </el-button>
                </div>
            </div>

            <el-table
                :data="services"
                stripe
                v-loading="loading">

                <el-table-column
                    label="ID"
                    prop="id"
                    sortable>
                    <template v-slot="scope">
                        <span class="text-greyish">{{ scope.row.id }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="Name"
                    prop="name"
                    sortable/>

                <el-table-column
                    label="Duration"
                    prop="duration"
                    sortable
                    align="center"/>

                <el-table-column
                    label="Description"
                    prop="description"/>

                <el-table-column
                    label="Number of users"
                    prop="users_count"
                    sortable
                    align="center"/>

                <el-table-column
                    padding="0"
                    width="80px">
                    <template v-slot="scope">
                        <el-dropdown trigger="click">
                            <el-button size="small">
                                <i class="fa fa-ellipsis-h"></i>
                            </el-button>
                            <template v-slot:dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click.native="editAttributeDictionary(scope.row)">
                                        <i class="fa fa-edit"></i> Edit
                                    </el-dropdown-item>

                                    <el-dropdown-item
                                        divided
                                        @click.native="deleteAttributeDictionary(scope.row)">
                                        <i class="fa fa-trash-o"></i> Delete
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <calendar-services-modal
            ref="calendar_service_modal"
            @callback="getCalendarServices"/>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import ErrorBag from "../../../ErrorBag"
import CalendarServicesModal from "./calendar-services-modal"

export default {
    name: "calendar-services-list",

    components: {CalendarServicesModal},

    data() {
        return {
            loading: false,
            services: [],
            errors: new ErrorBag({})
        }
    },

    computed: {
        ...mapState('cache', ['current_company'])
    },

    created() {
        this.getCalendarServices()
    },

    methods: {
        editAttributeDictionary(service) {
            this.$refs.calendar_service_modal.edit(service)
        },

        deleteAttributeDictionary(service) {
            const _this = this
            this.$confirm(`Are you sure you want to delete this service?`, 'Confirm', {
                confirmButtonText: 'Yes, I\'m sure',
                cancelButtonText: 'No, I\'m not',
                type: 'warning',
                customClass: 'width-500 fixed'
            }).then(() => {
                axios.delete(`/api/v1/calendar/service/${service.id}`)
                    .then(res => {
                        _this.$notify({
                            offset: 95,
                            title: 'Calendar Service',
                            message: 'Calendar service has been deleted successfully.',
                            type: 'success',
                            showClose: true,
                        })
                        _this.getCalendarServices()
                    })

            }).catch((err) => {
                console.log(err)
            })

        },

        getCalendarServices() {
            this.loading = true
            axios.get(`/api/v1/calendar/service`)
                .then(res => {
                    if (res.data.data) {
                        this.services = res.data.data
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false
                })
        }
    }
}
</script>
