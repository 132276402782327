<template>
    <el-alert
            v-show="alertMessage"
            type="error"
            :title="alertMessage"
            @close="submitAlert"
            class="h-full"
            show-icon>
    </el-alert>
</template>

<script>
import {compliance_mixin} from '../../../../mixins'

export default {
    name: 'AlertMessage',
    mixins: [compliance_mixin],
    methods: {
        submitAlert(alert) {
            this.$emit('alert', alert)
        },
    },
}
</script>
