<template>
    <div data-testid="number-guard-wizard-wrapper">
        <div class="container-fluid">
            <el-steps class="mb-3" :active="current_step" align-center finish-status="success">
                <el-step
                    title="Information"
                    data-testid="info-step"
                />
                <el-step
                    title="Select your package"
                    data-testid="info-step"
                />
                <el-step
                    title="Confirmation"
                    data-testid="confirmation-step"
                />
            </el-steps>
            <div class="step-1 row" v-if="current_step === 0">
                <div class="col-12">
                    <p class="form-head font-weight-bold mb-3 description wz-text-wrap">
                        <b>Are your outbound calls showing up as spam?</b>
                    </p>
                    <div class="dropdown-divider"></div>
                    <p class="wz-text-wrap">
                        <b>NumberGuard</b> by Aloware offers comprehensive protection for your phone numbers. Here’s what we do:<br>
                        <ul>
                            <li><b>Carrier Validation:</b> Verifies your phone numbers against US carrier databases and their analytical providers.</li>
                            <li><b>Real Device Testing:</b> Tests calls to real mobile phones and networks to check how your phone number is displayed.</li>
                            <li><b>Mislabeling Remediation:</b> Negotiates with US carriers to remove spam mislabeling (or CNAM records) from your numbers.</li>
                          <li><b>Caller ID Whitelisting:</b> Whitelists your outbound caller ID with mobile carriers, minimizing the risk of being flagged as spam.</li>
                        </ul>
                        Protect your phone numbers with NumberGuard today!
                    </p>
                </div>
                <div class="col-12 text-right mt-4">
                    <el-button
                        type="success"
                        @click="nextStep"
                    >
                        Next
                    </el-button>
                </div>
            </div>
            <div class="step-2 row" v-if="current_step === 1">
                <div class="col-12">
                    <p class="form-head font-weight-bold mb-3 description wz-text-wrap">
                        <b>Pricing Information</b>
                    </p>
                    <!-- Pricing Table -->
                    <div class="table-responsive">
                        <table class="wz-pricing-table">
                            <thead>
                            <tr>
                                <th><b>Quantity of Phone Numbers</b></th>
                                <th><b>Monthly</b></th>
                                <th><b>One-Time</b></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(price, index) in pricing"
                                :key="index">
                                <td>{{ getQuantityRange(price.phoneNumbers) }}</td>
                                <td>${{ price.monthly }}</td>
                                <td>${{ price.oneTime }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Dropdowns Section -->
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <b>Choose your Package</b>
                                <el-select
                                    class="w-full"
                                    id="package-select"
                                    placeholder="Select Package"
                                    :key="current_step"
                                    v-model="selected_package"
                                >
                                    <el-option
                                        v-for="option in computedPackages"
                                        :key="option.value"
                                        :value="option.value"
                                        :label="formattedPackageLabel"
                                        :disabled="option.disabled">
                                            <span class="wz-option-label">
                                                {{ option.label }}
                                                <span class="wz-option-number"
                                                      v-if="option.value == 1">
                                                    {{ localPresenceLines }} Numbers
                                                </span>
                                                <span class="wz-option-number" v-else-if="option.value == 2">
                                                    {{ nonLocalPresenceLines }} Numbers
                                                </span>
                                                <span class="wz-option-number" v-else-if="option.value == 3">
                                                    {{ totalLines }} Numbers
                                                </span>
                                            </span>
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="mb-3">
                                <b>Choose your Frequency</b>
                                <el-select
                                    class="w-full"
                                    v-model="selected_frequency"
                                    placeholder="Select Frequency">
                                    <el-option
                                        v-for="frequency in frequencies"
                                        :key="frequency.value"
                                        :value="frequency.value"
                                        :label="frequency.label">
                                        {{ frequency.label }}
                                    </el-option>
                                </el-select>
                            </div>
                            <div v-if="isValidStep2">
                                <b>Total cost:</b> ${{ totalCost }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-between mt-4">
                    <el-button
                        type="info"
                        @click="previousStep">
                        Back
                    </el-button>
                    <el-button
                        type="success"
                        @click="nextStep"
                        :disabled="!isValidStep2">
                        Next
                    </el-button>
                </div>
            </div>
            <div class="step-3 row" v-if="current_step === 2">
                <div class="col-12">
                    <p class="form-head font-weight-bold mb-3 wz-text-wrap">
                        <b>NumberGuard - Terms of Service</b>
                    </p>
                    <div class="dropdown-divider"></div>
                    <!-- Additional opt-in message -->
                    <p class="wz-text-wrap mt-3">
                        <b>Package:</b> {{ packageLabel }}<br>
                        <b>Frequency:</b> {{ frequencyLabel }}<br>
                        <b>Total cost:</b> ${{ totalCost }}<br>
                        By subscribing to this product, you consent to having your phone numbers scanned for risks and authorize Aloware to contact you regarding this order.
                    </p>
                </div>
                <div class="col-12 d-flex justify-content-between mt-4">
                    <el-button
                        type="info"
                        @click="previousStep">
                        Back
                    </el-button>
                    <el-button
                        type="success"
                        class="pull-right"
                        @click="confirmAndSubmit"
                        :disabled="loading">
                        <i class="material-icons loader pull-right" v-show="loading">&#xE863;</i> Accept and Submit
                    </el-button>
                </div>
            </div>
            <div class="step-4 text-center row" v-if="current_step === 3">
                <div class="col-12">
                    <p class="wz-text-wrap"><b>Your order for NumberGuard has been successfully submitted!</b></p>
                    <p class="wz-text-wrap">Our team will reach out to you within 1-2 business days. Thank you!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import numberGuard from '../../../constants/number-guard'
import {MONTHLY, ONE_TIME} from "../../../constants/number-guard-frequencies"

export default {
    props: {
        campaigns: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            current_step: 0,
            selected_package: null,
            selected_frequency: null,
            packages: numberGuard.packages,
            frequencies: numberGuard.frequencies,
            pricing: numberGuard.pricing,
            loading: false
        }
    },
    methods: {
        nextStep() {
            this.current_step++
        },
        previousStep() {
            if (this.current_step > 0) {
                this.current_step--
            }
            if (this.current_step === 0) {
                this.resetWizard()
            }
        },
        confirmAndSubmit() {
            this.loading = true
            const params = {
                number_guard_package: this.selected_package,
                number_guard_frequency: this.selected_frequency,
                total_cost: this.totalCost,
                quantity_phone_numbers: this.getLineCount
            }
            axios.post('/api/v1/compliance/number-guard/request', params).then(response => {
                this.current_step++
            }).catch(error => {
                console.log(error)
                this.$message.error('There was an issue submitting your request. Please try again.')
            }).finally(() => {
                this.loading = false
            })
        },
        resetWizard() {
            this.current_step = 0
            this.selected_package = null
            this.selected_frequency = null
        },
        getLineCountForPackage(packageValue) {
            switch (packageValue) {
                case 1:
                    return `${this.localPresenceLines} Numbers`
                case 2:
                    return `${this.nonLocalPresenceLines} Numbers`
                case 3:
                    return `${this.totalLines} Numbers`
                default:
                    return ''
            }
        },
        getQuantityRange(phoneNumbers) {
            return `${phoneNumbers.min} - ${phoneNumbers.max === Infinity ? phoneNumbers.defaultMaxQuantity : phoneNumbers.max}`
        }
    },
    computed: {
        isValidStep2() {
            return this.selected_package && this.selected_frequency
        },

        localPresenceLines() {
            return this.campaigns
                .filter(campaign => campaign.has_local_presence === true)
                .reduce((total, campaign) => total + campaign.incoming_numbers?.length, 0)
        },

        nonLocalPresenceLines() {
            return this.campaigns
                .filter(campaign => campaign.has_local_presence === false)
                .reduce((total, campaign) => total + campaign.incoming_numbers?.length, 0)
        },

        totalLines() {
            return this.campaigns.reduce((total, campaign) => total + campaign.incoming_numbers?.length, 0)
        },

        computedPackages() {
            return numberGuard.packages.map(option => {
                let disabled = false
                if (option.value === 1 && this.localPresenceLines === 0) {
                    disabled = true
                } else if (option.value === 2 && this.nonLocalPresenceLines === 0) {
                    disabled = true
                } else if (option.value === 3 && this.totalLines === 0) {
                    disabled = true
                }
                return {...option, disabled}
            })
        },

        formattedPackageLabel() {
            if (this.selected_package !== null) {
                const selectedOption = this.computedPackages.find(option => option.value === this.selected_package)
                return `${selectedOption.label}     [${this.getLineCountForPackage(this.selected_package)}]`
            }
            return ''
        },

        totalCost() {
            const lineCount = this.getLineCount
            const price = this.pricing.find(price => {
                return lineCount >= price.phoneNumbers.min && lineCount <= price.phoneNumbers.max
            })

            switch (this.frequencyLabel) {
                case ONE_TIME:
                    return price.oneTime
                case MONTHLY:
                    return price.monthly
                default:
                    return 0
            }
        },

        getLineCount() {
            //Gets the numeric part of the string returned by the method
            return this.getLineCountForPackage(this.selected_package).match(/\d+/)[0]
        },

        packageLabel() {
            return this.packages.find(p => p.value === this.selected_package)?.label
        },

        frequencyLabel() {
            return this.frequencies.find(f => f.value === this.selected_frequency)?.label
        }
    }
}
</script>
