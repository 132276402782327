// Default indefinite retention duration
export const CALL_RECORDS_RETENTION_DURATIONS_INDEFINITE = 0;
// Retention duration 10 days
export const CALL_RECORDS_RETENTION_DURATIONS_10_DAYS = 10;
// Retention duration 30 days
export const CALL_RECORDS_RETENTION_DURATIONS_30_DAYS = 30;
// Retention duration 60 days
export const CALL_RECORDS_RETENTION_DURATIONS_60_DAYS = 60;
// Follow Line’s Settings
export const CALL_RECORDS_RETENTION_DURATIONS_FOLLOW_LINE = -1;
