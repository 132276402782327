<template>
    <el-dialog width="50vw"
               class="dialog-blank"
               top="4vh"
               :before-close="closeDialog"
               :visible="is_visible"
               :append-to-body="true">
        <div slot="title"
             class="px-3 pt-3 pb-0">
            <h5 class="mb-0">
                Cost & Usage Summary
            </h5>
            <p class="_300 mb-0">
                <span v-if="date_range">
                    From {{ date_range[0] | momentFormat('MMM D, YYYY') }} to {{ date_range[1] | momentFormat('MMM D, YYYY') }}
                </span>
                <span v-else>
                    Showing all-time usage
                </span>
            </p>
        </div>

        <div v-if="data.length > 0"
             class="px-3 pb-3">
            <table class="table table-sm table-hover table-aloware">
                <thead>
                <tr>
                    <th>Resource</th>
                    <th>Paid Usage</th>
                    <th>Included Usage</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="datum in data">
                    <td>
                        <i class="fa fa-circle mr-2" :style="'color: ' + datum.color"></i> {{ datum.name }}
                    </td>
                    <td>
                        {{ datum.total_paid | numFormat }}
                    </td>
                    <td>
                        {{ datum.total_included | numFormat }}
                    </td>
                    <td>
                        {{ datum.total_quantity | numFormat }}
                    </td>
                    <td>
                        {{ datum.total_credit | fixRounding | toCurrency }}
                    </td>
                </tr>
                </tbody>
            </table>
            <div
                v-if="total_resource"
                class="card">
                <div class="card-body d-flex py-3 px-3">
                    <h4 class="mb-0">Total:</h4>
                    <h4 class="mb-0 ml-auto text-dark">{{ total_resource.total_credit | fixRounding | toCurrency }}</h4>
                </div>
            </div>
        </div>
        <div v-if="data.length === 0" class="text-center">
            <div class="w-75 mx-auto d-inline-block py-5">
                <img-empty></img-empty>
                <p class="lead">
                    No cost and usage on this date range.
                </p>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import ImgEmpty from "../../misc/img-empty"

export default {
    name: "company-usage-summary-dialog",

    components: {ImgEmpty},

    props: {
        date_range: {
            required: false
        },
        usages: {
            required: true
        },
    },

    data() {
        return {
            is_visible: false
        }
    },

    computed: {
        data() {
            return _.filter(this.usages, (usage, key) => {
                return key !== 'total'
            })
        },

        total_resource() {
            return this.usages?.total
        }
    },

    methods: {
        show() {
            this.is_visible = true
        },

        closeDialog() {
            this.is_visible = false
        }
    },
}
</script>
