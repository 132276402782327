<template>
    <el-tooltip class="item pointer"
                placement="top">
        <div slot="content">{{ from_time | fixFullDateUTCRelative }}</div>
        <span v-html="relative_time"></span>
    </el-tooltip>
</template>

<script>
export default {
    name: "relative-time",

    props: {
        from_time: {
            type: String,
            required: true
        },

        update_interval: {
            type: Number,
            required: false,
            default: 1000
        },

        humanized: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            relative_time: null,
        }
    },

    created() {
        this.getRelativeTime()
        setInterval(this.getRelativeTime, this.update_interval)
    },

    destroyed() {
        clearInterval(this.getRelativeTime)
    },

    methods: {
        getRelativeTime() {
            if (this.humanized) {
                this.relative_time = this.$options.filters.fixDurationHumanize(this.from_time) + ' ago'
            } else {
                this.relative_time = this.$options.filters.fixDurationUTCRelative(this.from_time)
            }
        },
    }
}
</script>
