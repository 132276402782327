export const MIN_REDIAL_OPTIONS = [
  {
    value: 0,
    label: "0 (No redial required)",
  },
  // fill with x times from 1 to 10
  ...Array(10)
    .fill(0)
    .map((_, index) => {
      const value = index + 1;
      return {
        value,
        label: `${value} time${value > 1 ? "s" : ""}`,
      };
    }),
];
