<template>
  <el-card class="salesforce-contact-settings-card">
    <!-- Start loader -->
    <div class="text-center mt-2"
         v-if="isLoading">
      <h5>
        <i class="fa fa-spinner fa-spin"></i>
        <span>Loading...</span>
      </h5>
    </div>
    <!-- End loader -->
    <div v-else>
        <!-- Start Contact Configuration -->
        <div class="d-flex">
          <h5>Contact</h5>
          <el-tooltip
              class="item"
              effect="dark"
              placement="top">
            <div slot="content">
              <p v-if="!salesforce_settings.enable_lead">Enable Contact as default Salesforce CRM Module</p>
              <p v-else>Contacts can't be enabled if Lead is already enabled</p>
            </div>
              <el-switch
                  v-model="salesforce_settings.enable_contact"
                  :disabled="salesforce_settings.enable_lead"
                  active-color="#00a344"
                  class="mt-1 ml-2"
              />
          </el-tooltip>
        </div>

        <div class="row form-group mt-3">
          <!-- Start Aloware and Salesforce Labels -->
          <template>
            <div class="col-md-4 mb-1">
              <small class="font-weight-bold">Aloware</small>
            </div>
            <el-tooltip
                class="item"
                effect="dark"
                placement="top">
              <div slot="content">
                <p>Direction on Contact Creation can't be changed.</p>
              </div>
              <div class="col-md-2 mb-1 text-center">
                <small class="font-weight-bold">Direction</small>
              </div>
            </el-tooltip>
            <div class="col-md-4 mb-1">
              <small class="font-weight-bold">Salesforce</small>
            </div>
          </template>
        </div>
        <!-- End Aloware and Salesforce Labels -->

        <!-- Start Aloware Fields -->
        <div class="row form-group mt-3">
          <div class="col-md-4">
            <el-form-item class="mb-1">
              <el-input
                         readonly
                         :disabled="!salesforce_settings.enable_contact || !salesforce_settings.contacts.should_create"
                        :value="getSalesforceLabels('contact.create')"
              />
            </el-form-item>
          </div>
          <!-- End Aloware Fields -->

          <div class="col-md-2 text-center">
            <direction-selector :initial-state="salesforce_settings.contacts.create_direction"
                                :disabled="!salesforce_settings.enable_contact
                                || !salesforce_settings.contacts.should_create"
                                @change="direction => salesforce_settings.contacts.create_direction = direction">
            </direction-selector>
          </div>

          <!-- Start Salesforce Fields -->
          <div class="col-md-4">
            <el-form-item class="mb-1">
              <el-input
                        readonly
                        :disabled="!salesforce_settings.enable_contact || !salesforce_settings.contacts.should_create"
                        :value="getSalesforceLabels('contact.create')"
              />
            </el-form-item>
          </div>
          <!-- End Salesforce Fields -->

          <!-- Start Delete button -->
          <div class="col-md-2 text-center">
            <el-tooltip
                class="item"
                effect="dark"
                placement="top">
              <div slot="content">
                <p>Enable creating a new contact in Salesforce when a new contact is created in Aloware</p>
              </div>
              <el-switch
                  v-model="salesforce_settings.contacts.should_create"
                  :disabled="!salesforce_settings.enable_contact"
                  class="mt-1 ml-2"
                  active-color="#00a344"
              />
            </el-tooltip>
          </div>
          <!-- End Delete Button -->
        </div>
        <!-- End Contact Configuration -->

        <!-- Start Aloware Fields -->
        <div class="row form-group mt-3">
          <div class="col-md-4">
            <el-form-item class="mb-1">
              <el-input
                  readonly
                  :disabled="!salesforce_settings.enable_contact || !salesforce_settings.contacts.should_update"
                  :value="getSalesforceLabels('contact.update')"
              />
            </el-form-item>
          </div>
          <!-- End Aloware Fields -->

          <div class="col-md-2 text-center">
            <direction-selector :initial-state="salesforce_settings.contacts.update_direction"
                                :disabled="!isDirectionEditable(getSalesforceLabels('contact.update'))
                                  || !salesforce_settings.enable_contact
                                  || !salesforce_settings.contacts.should_update"
                                @change="direction => salesforce_settings.contacts.update_direction = direction">
            </direction-selector>
          </div>

          <!-- Start Salesforce Fields -->
          <div class="col-md-4">
            <el-form-item class="mb-1">
              <el-input
                  readonly
                  :disabled="!salesforce_settings.enable_contact || !salesforce_settings.contacts.should_update"
                  :value="getSalesforceLabels('contact.update')"
              />
            </el-form-item>
          </div>
          <!-- End Salesforce Fields -->

          <!-- Start Enable button -->
          <div class="col-md-2 text-center">
            <el-tooltip
                class="item"
                effect="dark"
                placement="top">
              <div slot="content">
                <p>Enable updating a contact in Salesforce when a contact is updated in Aloware</p>
              </div>
              <el-switch
                  v-model="salesforce_settings.contacts.should_update"
                  :disabled="!salesforce_settings.enable_contact"
                  class="mt-1 ml-2"
                  active-color="#00a344"
              />
            </el-tooltip>
          </div>
          <!-- End Enable Button -->
        </div>
        <!-- End Contact Configuration -->

        <!-- Start Lead Configuration -->
        <div class="d-flex">
          <h5>Lead</h5>
          <el-tooltip
              class="item"
              effect="dark"
              placement="right">
            <div slot="content">
              <p v-if="!salesforce_settings.enable_contact">Enable Lead as default Salesforce CRM Module</p>
              <p v-else>Leads can't be enabled if Contact is already enabled</p>
            </div>
            <el-switch
                v-model="salesforce_settings.enable_lead"
                :disabled="salesforce_settings.enable_contact"
                class="mt-1 ml-2"
                active-color="#00a344"
            />
          </el-tooltip>
        </div>

        <div class="row form-group mt-3">
          <!-- Start Aloware and Salesforce Lables -->
          <template>
            <div class="col-md-4 mb-1">
              <small class="font-weight-bold">Aloware</small>
            </div>
            <el-tooltip
                class="item"
                effect="dark"
                placement="top">
              <div slot="content">
                <p>Direction on Contact Creation can't be changed.</p>
              </div>
              <div class="col-md-2 mb-1 text-center">
                <small class="font-weight-bold">Direction</small>
              </div>
            </el-tooltip>
            <div class="col-md-4 mb-1">
              <small class="font-weight-bold">Salesforce</small>
            </div>
          </template>
        </div>
        <!-- End Aloware and Salesforce Labels -->

        <div class="row form-group mt-3">
          <!-- Start Aloware Fields -->
          <div class="col-md-4">
            <el-form-item class="mb-1">
              <el-input
                  :value="getSalesforceLabels('contact.create')"
                  :disabled="!salesforce_settings.enable_lead || !salesforce_settings.leads.should_create"
                  readonly
              />
            </el-form-item>
          </div>
          <!-- End Aloware Fields -->

          <div class="col-md-2 text-center">
            <direction-selector :initial-state="salesforce_settings.leads.create_direction"
                                :disabled="!salesforce_settings.enable_lead"
                                @change="direction => salesforce_settings.leads.create_direction = direction">
            </direction-selector>
          </div>

          <!-- Start Salesforce Fields -->
          <div class="col-md-4">
            <el-form-item class="mb-1">
              <el-input
                  :value="getSalesforceLabels('lead.create')"
                  :disabled="!salesforce_settings.enable_lead || !salesforce_settings.leads.should_create"
                  readonly
              >
              </el-input>
            </el-form-item>
          </div>
          <!-- End Salesforce Fields -->

          <!-- Start Delete button -->
          <div
               class="col-md-2 text-center">
            <el-tooltip
                class="item"
                effect="dark"
                placement="right">
              <div slot="content">
                <p> Enable creating a new lead in Salesforce when a new contact is created in Aloware </p>
              </div>
              <el-switch
                  v-model="salesforce_settings.leads.should_create"
                  :disabled="!salesforce_settings.enable_lead"
                  class="mt-1 ml-2"
                  active-color="#00a344"
              />
            </el-tooltip>
          </div>
          <!-- End Delete Button -->
        </div>

      <div class="row form-group mt-3">
        <!-- Start Aloware Fields -->
        <div class="col-md-4">
          <el-form-item class="mb-1">
            <el-input
                :value="getSalesforceLabels('contact.update')"
                :disabled="!salesforce_settings.enable_lead || !salesforce_settings.leads.should_update"
                readonly
            />
          </el-form-item>
        </div>
        <!-- End Aloware Fields -->

        <div class="col-md-2 text-center">
          <direction-selector :initial-state="salesforce_settings.leads.update_direction"
                              :disabled="!isDirectionEditable(getSalesforceLabels('lead.update'))
                                  || !salesforce_settings.enable_lead
                                  || !salesforce_settings.leads.should_update"
                              @change="direction => salesforce_settings.leads.update_direction = direction">
          </direction-selector>
        </div>

        <!-- Start Salesforce Fields -->
        <div class="col-md-4">
          <el-form-item class="mb-1">
            <el-input
                :value="getSalesforceLabels('lead.update')"
                :disabled="!salesforce_settings.enable_lead || !salesforce_settings.leads.should_update"
                readonly
            >
            </el-input>
          </el-form-item>
        </div>
        <!-- End Salesforce Fields -->

        <!-- Start Delete button -->
        <div
            class="col-md-2 text-center">
          <el-tooltip
              class="item"
              effect="dark"
              placement="right">
            <div slot="content">
              <p> Enable updating a lead in Salesforce when a contact is updated in Aloware </p>
            </div>
            <el-switch
                v-model="salesforce_settings.leads.should_update"
                :disabled="!salesforce_settings.enable_lead"
                class="mt-1 ml-2"
                active-color="#00a344"
            />
          </el-tooltip>
        </div>
        <!-- End Delete Button -->
      </div>
        <!-- End Lead Configuration -->
    </div>
  </el-card>
</template>

<script>
import DirectionSelector from "../direction-selector.vue"
import * as IntegrationPropertyMapDirections from '../../../constants/integration-property-map-directions'
import * as SalesforceContactSettingsLabels from "../../../constants/integration-salesforce-contact-settings-labels"
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'salesforce-contact-settings',
  components: { DirectionSelector },
  props: {
    contact_settings: {
      type: Object|null,
      required: true
    }
  },
  data: () => ({
    // ui
    isLoading: false,
    // mappings
    salesforce_settings: {},
    IntegrationPropertyMapDirections
  }),
  async created() {
    this.isLoading = true

    this.resetSalesforceSettings()

    this.isLoading = false
  },
  methods: {
    resetSalesforceSettings() {
      // Check if this configuration already exists and sets the default value if not
      if (this.contact_settings) {
        this.salesforce_settings = cloneDeep(this.contact_settings)
      } else {
        this.salesforce_settings = {
          enable_contact: false,
          enable_lead: true,
          contacts: {
            should_create: true,
            create_direction: IntegrationPropertyMapDirections.DIRECTION_INBOUND,
            should_update: true,
            update_direction: IntegrationPropertyMapDirections.DIRECTION_BI,
          },
          leads: {
            should_create: true,
            create_direction: IntegrationPropertyMapDirections.DIRECTION_INBOUND,
            should_update: true,
            update_direction: IntegrationPropertyMapDirections.DIRECTION_BI,
          }
        }
      }
    },
    isDirectionEditable(key) {
      switch (key) {
        case SalesforceContactSettingsLabels.LABEL_CONTACT_CREATE:
        case SalesforceContactSettingsLabels.LABEL_LEAD_CREATE:
          return false
        case SalesforceContactSettingsLabels.LABEL_CONTACT_UPDATE:
        case SalesforceContactSettingsLabels.LABEL_LEAD_UPDATE:
          return true
        default:
          return true
      }
    },
    getSalesforceLabels(module_operation) {
      switch (module_operation) {
        case 'contact.create':
          return SalesforceContactSettingsLabels.LABEL_CONTACT_CREATE
        case 'contact.update':
          return SalesforceContactSettingsLabels.LABEL_CONTACT_UPDATE
        case 'lead.create':
          return SalesforceContactSettingsLabels.LABEL_LEAD_CREATE
        case 'lead.update':
          return SalesforceContactSettingsLabels.LABEL_LEAD_UPDATE
        default:
          return ''
      }
    },
    getSettings() {
      return this.salesforce_settings
    },
  }
}
</script>
