import { render, staticRenderFns } from "./assign-contacts-by-tag.vue?vue&type=template&id=04b193d9"
import script from "./assign-contacts-by-tag.vue?vue&type=script&lang=js"
export * from "./assign-contacts-by-tag.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/app/releases/20250215014035Z/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('04b193d9')) {
      api.createRecord('04b193d9', component.options)
    } else {
      api.reload('04b193d9', component.options)
    }
    module.hot.accept("./assign-contacts-by-tag.vue?vue&type=template&id=04b193d9", function () {
      api.rerender('04b193d9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/user/components/assign-contacts-by-tag.vue"
export default component.exports