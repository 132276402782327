<template>
    <contact v-if="contactId && isProfileLoaded"
             :contact_id="contactId"
             :embedded="true"
             :force_full_height="true">
    </contact>
</template>

<script>
import auth from '../../auth'

export default {
    data() {
        return {
            auth: auth
        }
    },

    computed: {
        contactId() {
            const contact_id =  _.get(this.$route, 'params.id', null)
            if (!contact_id) {
                return null
            }
            return parseInt(contact_id)
        },

        isProfileLoaded() {
            const user_profile = _.get(this.auth, 'user.profile', null)
            return user_profile ? true : false
        }
    },

    created() {
        this.$root.$data.is_widget = true
        this.init()
    },

    methods: {
        init() {
            if (this.api_key) {
                localStorage.setItem('api_token', this.api_key)
            }
            this.loading = true
            auth.check()
                .then((res) => {
                    if (!this.needs_extensions) {
                        localStorage.setItem('company_id', res.data.user.company.id)
                        this.setCurrentCompany(res.data.user.company)
                        this.resetVuex()
                    }
                    this.loading = false
                    this.initialized = true
                })
                .catch((err) => {
                    this.loading = false
                    if (!this.needs_extensions) {
                        console.log('Error: api key is not valid', err)
                    } else {
                        this.$router.push({name: 'Login', query: {redirect: this.$route.fullPath}})
                    }
                })
        },
    }
}
</script>
