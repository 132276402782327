<template>
    <div class="row nav-padding">
        <div class="col-12">
            <div class="d-flex mb-0 mt-2 ml-0">
                <div>
                    <div class="form-label">
                        <h5>URL Shortener</h5>
                        <small>Here you can enable or disable auto Short URL generation feature for SMS/MMS forms.</small>
                    </div>
                    <el-checkbox v-model="urlShortenerEnabled">
                        Enable URL Shortener
                    </el-checkbox>
                </div>
                <div class="ml-auto">
                    <el-popover placement="left-start" width="400" trigger="click">
                        <el-form label-position="top">
                            <el-form-item label="Enter a long URL to make a short URL">
                                <el-input v-model="form.long_url" type="textarea" :rows="4" maxlength="300" show-word-limit placeholder="Insert link"></el-input>
                            </el-form-item>
                        </el-form>
                        <el-button type="primary" size="small" @click="generateUrl" :loading="saving" class="pull-right" icon="el-icon-link">Shorten</el-button>
                        <el-button slot="reference" type="success" icon="el-icon-plus">Generate URL</el-button>
                    </el-popover>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row my-3 px-3">
            <div class="mr-2">
                <el-input ref="urlSearch"
                          placeholder="Search"
                          prefix-icon="el-icon-search"
                          clearable
                          autofocus
                          v-model="search_text"
                          @input="search">
                </el-input>
            </div>
            <div class="d-flex flex-row align-items-center">
                <span class="mr-2">URL Created At:</span>
                <date-range-selector :filter="filter"></date-range-selector>
            </div>
        </div>

        <div class="col-12">
            <div class="no-effect">
                <div class="box-header pb-0">
                    <div class="row">
                        <div class="col-12">
                                    <span class="pull-left font-120"
                                          v-if="pagination.total">
                                        <strong>{{ pagination.total || 0 }}</strong>
                                        {{ pagination.total > 1 ? 'URLs' : 'URL' }}
                                    </span>
                        </div>
                    </div>
                </div>
                <div class="box-body pt-0">
                    <div v-on:mouseleave="tableLeave">
                        <el-table
                            v-loading="loading"
                            :data="urls"
                            :default-sort="{prop: 'id', order: 'ascending'}"
                            class="w-full"
                            fit
                            stripe
                            @cell-mouse-enter="cellEnter"
                        >
                            <el-table-column
                                prop="short"
                                width="230"
                                label="Short URL"
                                sortable>
                                <template v-slot="scope">
                                    <span class="status d-flex align-items-center justify-content-left">
                                        <a :href="scope.row.short" target="_blank">
                                          {{ scope.row.short }}
                                        </a>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="original"
                                label="Original URL"
                                sortable>
                                <template v-slot="scope">
                                    <span class="status d-flex align-items-center justify-content-left">
                                        <a :href="scope.row.original" target="_blank">
                                          {{ scope.row.original }}
                                        </a>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="sent"
                                label="Sent"
                                align="center"
                                width="120px"
                                sortable>
                            </el-table-column>
                            <el-table-column
                                prop="total_clicks"
                                label="Clicks"
                                align="center"
                                width="120px"
                                sortable>
                            </el-table-column>
                            <el-table-column
                                prop="sent"
                                label="Click Rate"
                                align="center"
                                width="120px"
                                sortable>
                                <template v-slot="props">
                                    {{ calcSentRate(props.row) }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="created_at"
                                label="Created At"
                                align="center"
                                width="150px"
                                sortable>
                                <template v-slot="props">
                                    {{ props.row.created_at | fixFullDateUTCRelative }}
                                </template>
                            </el-table-column>
                            <template slot="empty"
                                      class="text-center text-muted">
                                <span v-if="!loading">No Data</span>
                                <span v-if="loading"></span>
                            </template>
                        </el-table>
                    </div>
                    <div class="mt-3"
                         v-if="pagination.page_count >= 1 && pagination.total > 0">
                        <el-pagination class="pull-right"
                                       background
                                       layout="sizes, prev, pager, next"
                                       :page-sizes="[10, 20, 50, 100, 250]"
                                       :page-size="pagination.per_page"
                                       :current-page.sync="pagination.page"
                                       :total="pagination.total"
                                       @current-change="fetchUrls"
                                       @size-change="handleSizeChange">
                        </el-pagination>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapState} from 'vuex'
import {chargebee_mixin, scroll_mixin} from '../../mixins'
import DateRangeSelector from "../utilities/date-range-selector"
import DashboardFilter from '../../filters/dashboard-filter'

export default {
    mixins: [chargebee_mixin, scroll_mixin],
    components: {DateRangeSelector},
    data() {
        return {
            auth: auth,
            env: null,
            loading: false,
            saving: false,
            search_text: '',
            loading_btn: false,
            visible_row_id: null,
            pagination: {
                page_count: 0,
                total: 0,
                per_page: 10,
                page: 1
            },
            urls: [],
            filter: DashboardFilter,
            form: {
                long_url: ''
            }
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),
        dateRange() {
            return DashboardFilter.dateRange()
        },

        isMobileSize() {
            return window.screen.width <= 425
        },

        urlShortenerEnabled: {
            get() {
                return this.current_company.url_shortener_enabled
            },
            set(value) {
                this.$emit('enabled', value)
            }
        }
    },

    watch: {
        dateRange: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val.from_date != null && val.to_date != null) {
                    this.fetchUrls()
                }
            }
        }
    },

    mounted() {
        this.setTitle()

        this.search_text = ''
        // focus user search input on page visit
        if (this.$refs.urlSearch) {
            this.$refs.urlSearch.focus()
        }
    },

    methods: {
        fetchUrls() {
            let params = {
                page: this.pagination.page,
                per_page: this.pagination.per_page,
                search: this.search_text,
                from_date: this.dateRange.from_date,
                to_date: this.dateRange.to_date
            }
            this.loading = true
            axios.get('/api/v1/url-shortener/urls', {params})
                .then(({data}) => {
                    this.urls = data.data
                    let {current_page, per_page} = data
                    this.pagination = {
                        page_count: data.last_page,
                        total: data.total,
                        per_page: data.per_page,
                        page: data.current_page
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$root.handleErrors(err.response)
                })
        },

        calcSentRate({total_clicks, sent}) {
            return total_clicks > 0 ? (sent > 0 ? ((total_clicks / sent) * 100).toFixed(2) + '%' : 'N/A') : '0%'
        },

        generateUrl() {
            this.saving = true
            axios.post('/api/v1/url-shortener/urls', this.form)
                .then(({data}) => {
                    this.urls.unshift(data)
                    this.form.long_url = ''
                    this.$copyText(data.short)
                        .then((res) => {
                            this.$message({
                                message: 'Shortened URL copied to clipboard!',
                                type: 'success',
                                customClass: 'variable-copied-notify',
                                showClose: true
                            })
                        }, (err) => {
                            this.$message({
                                message: 'Cannot copy to clipboard!',
                                type: 'error',
                                customClass: 'variable-copied-notify',
                                showClose: true
                            })
                            console.log(err)
                        })
                    this.saving = false
                })
                .catch(err => {
                    this.saving = false
                    this.$root.handleErrors(err.response)
                })
        },

        handleSizeChange(size) {
            this.pagination.perPage = size
            this.fetchUrls()
        },

        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Short URLs - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Short URLs - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        search: _.debounce(function () {
            this.fetchUrls()
        }, 500),

        cellEnter: _.throttle(function (row, column, cell, event) {
            this.visible_row_id = row.id
        }, 50),

        tableLeave() {
            this.visible_row_id = null
        },

        getStyle(id) {
            let opacity = 0.5
            if (id == this.visible_row_id) {
                opacity = 1
            }

            return {
                opacity: opacity
            }
        }
    }
}
</script>
