var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isSimpSocial
    ? _c(
        "el-dialog",
        {
          ref: "force-talk-dialog",
          staticClass: "dialog-blank alo-force-talk-dialog",
          attrs: {
            width: "800px",
            id: "force-talk-dialog",
            "append-to-body": "",
            visible: _vm.shouldForceTalk,
            "close-on-click-modal": false,
            "show-close": false,
          },
        },
        [
          _c(
            "div",
            { staticClass: "alo-force-talk-dialog__body" },
            [
              _c(
                "h4",
                {
                  staticClass:
                    "listing-heading d-flex align-items-center alo-force-talk-dialog__title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _vm._v("\n            New Aloware Talk\n            "),
                  _c("img", {
                    staticClass: "ml-2",
                    attrs: {
                      src: "/assets/images/app-icons/tada.png",
                      alt: "tada-icon",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "break-word" }, [
                _c("p", [
                  _vm._v(
                    "Your administrator is moving you off of " +
                      _vm._s(_vm.alowareClassic) +
                      ", and onto Aloware Talk, our new and improved platform built for agents! Please click on the button below to be redirected to Aloware Talk."
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "a",
                { attrs: { href: _vm.talk2Url } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass:
                        "w-100 d-flex justify-content-center align-items-center alo-force-talk-dialog__redirect-button",
                      attrs: { size: "large", type: "primary" },
                    },
                    [
                      _vm._v(
                        "\n                Continue to Aloware Talk\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }