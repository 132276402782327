<template>
    <div class="d-flex flex-row align-items-center"
         v-if="zohoEnabled">
        <el-select v-model="view_id"
                   class="d-flex flex-grow-1"
                   :placeholder="placeholder"
                   :multiple="multiple"
                   :multiple-limit="multiple ? multipleLimit : 0"
                   :loading="loading_zoho_views"
                   :disabled="!loading_zoho_views && !zoho_views.length"
                   filterable
                   clearable
                   default-first-option
                   collapse-tags>
            <el-option
                v-for="list in viewsAlphabeticalOrder"
                :key="list.id"
                :label="list.name"
                :value="list.id">
            </el-option>
        </el-select>
        <el-button type="primary"
                   icon="fa fa-refresh"
                   size="mini"
                   class="ml-2 w-30"
                   :disabled="loading_zoho_views"
                   :loading="loading_zoho_views"
                   circle
                   @click="getZohoViews">
        </el-button>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../auth'
import {acl_mixin} from '../mixins'

export default {
    mixins: [acl_mixin],

    props: {
        value: {
            required: false,
        },

        exclude: {
            required: false,
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        multipleLimit: {
            type: Number,
            required: false,
            default: 0
        },
    },

    data() {
        return {
            auth: auth,
            view_id: this.value,
            loading_zoho_views: false,
            zoho_views: [],
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company
        }),

        zohoEnabled() {
            if (this.current_company &&
                this.current_company.zoho_integration_enabled) {
                return true
            }

            return false
        },

        placeholder() {
            if (this.loading_zoho_views) {
                return 'Loading ...'
            }

            return 'Zoho Custom Views'
        },

        viewsAlphabeticalOrder() {
            if (this.zoho_views) {
                return this.zoho_views.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },

        activeView() {
            if (this.zoho_views && this.view_id) {
                return this.zoho_views.find(zoho_view => zoho_view.id === this.view_id)
            }

            return
        }
    },

    mounted() {
        if (this.zohoEnabled) {
            this.getZohoViews()
        }
    },

    methods: {
        getZohoViews() {
            this.zoho_views = []
            this.loading_zoho_views = true
            axios.get('/api/v1/integration/zoho/views').then((res) => {
                this.loading_zoho_views = false
                this.zoho_views = res.data
            }).catch((err) => {
                this.loading_zoho_views = false
                this.$root.handleErrors(err.response)
                console.log(err)
            })
        },
    },

    watch: {
        value() {
            this.view_id = this.value
        },

        view_id(val) {
            if (this.value !== undefined && this.view_id !== this.value) {
                this.$emit('selectedView', this.activeView)
                this.$emit('change', val)
            }
        }
    }
}
</script>
