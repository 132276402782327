<template>
    <div v-if="hasPermissionTo('list sms template')">
        <el-dialog title="Templates"
                   class="dialog-padding auto"
                   :width="isLargeEnough ? '500px' : '100%'"
                   :visible.sync="dialogVisible"
                   append-to-body>
            <template-list template_scope="user"/>
            <template-list template_scope="company"/>
        </el-dialog>
        <slot name="trigger">
            <template v-if="!short">
                <el-button slot="reference"
                           v-if="text_button"
                           type="text"
                           :class="'text-blackish pb-0 mb-2 font-alternative ' + btn_class"
                           :disabled="disabled"
                           @click.prevent="dialogVisible = true">
                    <i class="material-icons">description</i>
                    Templates
                </el-button>
                <el-button slot="reference"
                           type="success"
                           :class="btn_class"
                           :disabled="disabled"
                           v-else
                           @click.prevent="dialogVisible = true">
                    <i class="material-icons">description</i>
                    Templates
                </el-button>
            </template>
            <template v-else>
                <el-button slot="reference"
                           v-if="text_button"
                           type="text"
                           class="text-blackish pb-0 font-alternative"
                           @click.prevent="dialogVisible = true">
                    <i class="material-icons">description</i>
                </el-button>
                <el-button slot="reference"
                           v-else
                           type="success"
                           class="btn greenish"
                           @click.prevent="dialogVisible = true">
                    <i class="material-icons">description</i>
                </el-button>
            </template>
        </slot>
    </div>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin} from "../mixins"
    import TemplateDialog from "./template-dialog"
    import TemplateList from "./template-list"

    export default {
        components: {TemplateList, TemplateDialog},

        mixins: [acl_mixin],

        props: {
            short: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            },

            text_button: {
                type: Boolean,
                default: false
            },

            btn_class: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                auth: auth,
                dialogVisible: false,
            }
        },

        computed: {
            isLargeEnough() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            }
        }
    }
</script>
