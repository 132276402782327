var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "vertical-center text-center mb-4" }, [
      _vm.filter.from_date
        ? _c("span", { staticClass: "call-log-head" }, [
            _vm._v(
              "\n            Call Time of the Day per Line from\n            "
            ),
            _c("strong", [
              _vm._v(_vm._s(_vm._f("fixFullDateLocal")(_vm.filter.from_date))),
            ]),
            _vm._v("\n            to\n            "),
            _c("strong", [
              _vm._v(_vm._s(_vm._f("fixFullDateLocal")(_vm.filter.to_date))),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.filter.from_date
        ? _c("span", { staticClass: "call-log-head" }, [
            _vm._v("\n            Call Time of the Day per Line\n            "),
            _c("strong", [_vm._v("All Time")]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.loading || _vm.campaigns.length == 0
      ? _c(
          "div",
          {
            staticClass:
              "placeholder text-center d-flex align-items-center justify-content-center",
            class: { blink: _vm.loading },
            staticStyle: { height: "450px" },
          },
          [
            _c(
              "i",
              { staticClass: "material-icons loader text-greenish text-4x" },
              [_vm._v("\n            \n        ")]
            ),
          ]
        )
      : _c(
          "div",
          { staticStyle: { height: "450px" } },
          [
            _c("highcharts", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.is_done && _vm.options.series.length > 0,
                  expression: "is_done && options.series.length > 0",
                },
              ],
              ref: "highchart",
              staticStyle: { height: "450px" },
              attrs: { id: _vm.graph_id, options: _vm.options },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.is_done && !_vm.options.series.length,
                    expression: "is_done && !options.series.length",
                  },
                ],
                staticClass: "el-table__empty-block",
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "el-table__empty-text",
                    staticStyle: { color: "#606266" },
                  },
                  [_vm._v("\n                No Data\n            ")]
                ),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }