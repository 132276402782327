<template>
    <div>
        <el-radio-group v-if="!['activity_reporting', 'new_contacts_vs_dnc'].includes(report_type)"
                        v-show="!type"
                        v-model="data_switch"
                        class="data-switch"
                        size="mini"
                        @change="emitChange">
            <el-radio-button label="graph">Graph</el-radio-button>
            <el-radio-button label="table">Table</el-radio-button>
        </el-radio-group>
        <!-- highcharts -->
        <slot v-if="data_switch == 'graph'"></slot>
        <!-- table -->
        <div v-if="report_type && ['leads_intake'].includes(report_type) && data_switch == 'table'"
             style="height: 450px;">
            <el-table
                :data="highcharts_series"
                :max-height="450"
                class="w-full"
                stripe>
                <el-table-column
                    v-for="(val, index) in highcharts_series[0]"
                    :key="index"
                    :fixed="index == 'name' ? true : false"
                    :label="findLabel(index)"
                    :min-width="150"
                    :prop="index"
                    sortable>
                    <template v-slot="scope">
                        {{ scope.row[index] || 0 }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-else-if="data_switch == 'table'"
             style="height: 450px;">
            <el-table
                :data="highcharts_series"
                :max-height="450"
                class="w-full"
                stripe>
                <el-table-column
                    v-for="(val, index) in highcharts_series[0]"
                    :key="index"
                    :fixed="index == 'name' ? true : false"
                    :label="findLabel(index)"
                    :min-width="150"
                    :prop="index"
                    sortable>
                    <template v-slot="scope">
                        {{ scope.row[index] || 0 }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapState} from 'vuex'

export default {
    props: [
        'series',
        'type',
        'calls_only',
        'report_type',
        'table_label'
    ],

    data() {
        return {
            auth: auth,
            data_switch: 'graph'
        }
    },

    created() {
        if (this.type) {
            this.data_switch = this.$options.filters.ucfirst(this.type)
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company
        }),

        highcharts_series() {
            let data = []
            let first_column_name = ''
            let aggregate_column_name = ''
            switch (this.report_type) {
                case 'leads_intake':
                    aggregate_column_name = this.getAggregateColumnName()
                    first_column_name = this.findLabel(this.report_type)
                    for (let row of this.series) {
                        for (let column of row.data) {
                            let reduced_row = {}
                            reduced_row[first_column_name] = this.$options.filters.fixFullDateLocal(column[0])
                            reduced_row[aggregate_column_name] = column[1]
                            data.push(reduced_row)
                        }
                    }
                    return data
                case 'leads_created_vs_dnc':
                case 'new_contacts_vs_dnc':
                    // first, we create the data for the first series
                    first_column_name = this.findLabel(this.report_type)
                    for (let column in this.series[0].data) {
                        let reduced_row = {}
                        reduced_row[first_column_name] = this.$options.filters.fixFullDateLocal(this.series[0].data[column][0])
                        reduced_row[this.series[0].name] = this.series[0].data[column][1]
                        data.push(reduced_row)
                    }
                    // then, we check for existing dates in the data
                    // and add the second series' counts if date is found
                    // if not found, create a new data for the second series
                    for (let column in this.series[1].data) {
                        let found = false
                        for (let index in data) {
                            if (this.$options.filters.fixFullDateLocal(this.series[1].data[column][0]) === data[index][first_column_name]) {
                                data[index][this.series[1].name] = this.series[1].data[column][1]
                                found = true
                                break
                            }
                        }
                        if (!found) {
                            let reduced_row = {}
                            reduced_row[first_column_name] = this.$options.filters.fixFullDateLocal(this.series[1].data[column][0])
                            reduced_row[this.series[0].name] = 0
                            reduced_row[this.series[1].name] = this.series[1].data[column][1]
                            data.push(reduced_row)
                        }
                    }
                    // we then check if there are missing series, just add 0 count
                    for (let index in data) {
                        if (!data[index][this.series[1].name]) {
                            data[index][this.series[1].name] = 0
                        }
                    }
                    // lastly, we sort the data
                    data.sort((a, b) => moment(a[first_column_name]).valueOf() - moment(b[first_column_name]).valueOf())
                    return data
                case 'activity_reporting':
                    first_column_name = 'Name'
                    if (this.series[0]) {
                        for (let row in this.series[0].data) {
                            let reduced_row = {}
                            reduced_row[first_column_name] = this.series[0].data[row].name
                            reduced_row[this.table_label] = this.series[0].data[row].y
                            data.push(reduced_row)
                        }
                    }
                    return data
                default:
                    for (let row of this.series) {
                        let reduced_row = {
                            name: row.name
                        }
                        let index = 0
                        for (let column of row.data) {
                            if (column.name) {
                                reduced_row[column.name] = column.y
                            } else {
                                let label_type = this.report_type == 'activity_reporting' ? 'activity_reporting' : 'data-' + index
                                reduced_row[label_type] = column
                            }
                            index++
                        }
                        data.push(reduced_row)
                    }
                    return data
            }
        }
    },

    methods: {
        getAggregateColumnName() {
            return 'Count'
        },

        findLabel(label) {
            switch (label) {
                case 'name':
                    return '#'
                case 'leads_intake':
                case 'leads_created_vs_dnc':
                case 'new_contacts_vs_dnc':
                    return 'Date'
                case 'activity_reporting':
                    return 'Calls'
                default:
                    if (label.includes('data-') && !this.calls_only) {
                        return 'Calls & Texts'
                    }
                    if (label.includes('data-') && this.calls_only) {
                        return 'Calls'
                    }
            }

            return label
        },

        emitChange() {
            this.$emit('change', this.data_switch)
        }
    }
}
</script>
