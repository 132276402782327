var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { "data-testid": "alo-talk-info-wrapper" } }, [
    _c(
      "h4",
      {
        staticClass:
          "listing-heading d-flex align-items-center alo-default-app-selector-title",
        attrs: { slot: "title", "data-testid": "new-name-talk" },
        slot: "title",
      },
      [
        _vm._v("\n        New " + _vm._s(_vm.name) + " Talk\n        "),
        _c("img", {
          staticClass: "ml-2",
          attrs: {
            src: "/assets/images/app-icons/tada.png",
            alt: "tada-icon",
            "data-testid": "tada-icon-img",
          },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "break-word",
        attrs: { "data-testid": "talk-introduction" },
      },
      [
        _c("p", [
          _vm._v("We're introducing "),
          _c("b", [_vm._v(_vm._s(_vm.name) + " Talk")]),
          _vm._v(
            ", our newest experience for agents with an improved UI and powerful features:"
          ),
        ]),
        _vm._v(" "),
        _c("ul", { attrs: { "data-testid": "alo-talk-ul" } }, [
          _c(
            "li",
            { attrs: { "data-testid": "alo-talk-ul-li" } },
            [
              _c(
                "el-link",
                {
                  staticClass: "text-decoration-underline",
                  attrs: {
                    type: "primary",
                    href: "https://support.aloware.com/en/articles/9037639-managing-inbox-and-task-in-aloware-talk",
                    target: "_blank",
                    "data-testid": "li-inbox-task-manager",
                    underline: false,
                  },
                },
                [
                  _vm._v(
                    "\n                    Inbox + Task Manager\n                "
                  ),
                ]
              ),
              _vm._v(
                "\n                - Easily see and manage missed calls, messages, and more\n            "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { attrs: { "data-testid": "alo-talk-ul-li" } },
            [
              _c(
                "el-link",
                {
                  staticClass: "text-decoration-underline",
                  attrs: {
                    type: "primary",
                    href: "https://support.aloware.com/en/articles/9034179-creating-contact-lists-for-users-in-aloware-talk",
                    target: "_blank",
                    "data-testid": "li-contact-lists",
                    underline: false,
                  },
                },
                [
                  _vm._v(
                    "\n                    Contact Lists\n                "
                  ),
                ]
              ),
              _vm._v(
                "\n                - Intuitively manage contacts. Create powerful dynamic list\n            "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { attrs: { "data-testid": "alo-talk-ul-li" } },
            [
              _c(
                "el-link",
                {
                  staticClass: "text-decoration-underline",
                  attrs: {
                    type: "primary",
                    href: "https://support.aloware.com/en/articles/9037639-managing-inbox-and-task-in-aloware-talk",
                    target: "_blank",
                    "data-testid": "li-simple-views",
                    underline: false,
                  },
                },
                [_vm._v("\n                    Simple Views\n                ")]
              ),
              _vm._v(
                "\n                - Easily filter and switch between views across different channels\n            "
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("p", { attrs: { "data-testid": "switch-aloware-classic" } }, [
          _vm._v(
            "You can switch back to " +
              _vm._s(_vm.alowareClassic) +
              " at any time with the toggle in the top right of your screen."
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }