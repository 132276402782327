<template>
    <div>
        <div class="row">
            <div class="col-12">
                <el-table class="w-full mt-3"
                          v-if="voicemail_drop_files.length"
                          :data="voicemail_drop_files"
                          fit>
                    <el-table-column label="Title"
                                     prop="name"
                                     :min-width="150"
                                     sortable>
                        <template v-slot="scope">
                            <span v-if="getExcludedVoicemailDropListDisplay(scope.row)">{{ scope.row.name }}</span>
                            <el-form ref="vm_drop_edit_form"
                                     autocomplete="off"
                                     label-position="top"
                                     v-if="voicemail_drop_edit.editing && voicemail_drop_edit.data.id === scope.row.id"
                                     :model="voicemail_drop_edit"
                                     :rules="rules_edit"
                                     @submit.prevent.native="updateVmDropFile(scope.row.id)">
                                <el-form-item prop="title"
                                              class="p-0 mb-0 border-bottom-0">
                                    <el-input v-model="voicemail_drop_edit.title"
                                              type="text"
                                              placeholder="Title"
                                              class="w-100"
                                              :maxlength="20"
                                              @input="customPreValidateForm('vm_drop_edit_form')">
                                    </el-input>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column label="Audio"
                                     :min-width="150">
                        <template v-slot="scope">
                            <div class="d-flex justify-content-start align-items-center"
                                 v-if="scope.row.uploaded_file">
                                <audio controls>
                                    <source :src="`/static/uploaded_file/${scope.row.uploaded_file.uuid}`">
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Operation"
                                     :min-width="150">
                        <template v-slot="scope">
                            <button class="btn btn-sm light-blue-500"
                                    :disabled="isViewOnlyAccess()"
                                    v-if="getExcludedVoicemailDropListDisplay(scope.row)"
                                    @click.prevent="enableUpdateVoicemailDropFile(scope.row)">
                                <i class="fa fa-pencil"></i>
                                <span>Edit</span>
                            </button>
                            <button class="btn btn-sm greenish"
                                    v-if="voicemail_drop_edit.editing && voicemail_drop_edit.data.id === scope.row.id"
                                    :disabled="isViewOnlyAccess() || voicemail_drop_edit.loading || !edit_validated"
                                    @click.prevent="updateVmDropFile(scope.row.id)">
                                <i class="fa fa-spin fa-spinner"
                                   v-if="voicemail_drop_edit.loading"></i>
                                <i class="fa fa-floppy-o"
                                   v-else></i>
                                <span>Save</span>
                            </button>
                            <!-- cancel button -->
                            <button class="btn btn-sm purple-500 ml-2"
                                    @click.prevent="disableUpdateVoicemailDropFile"
                                    v-if="voicemail_drop_edit.editing && voicemail_drop_edit.data.id === scope.row.id">
                                <i class="fa fa-times"></i>
                                <span>Cancel</span>
                            </button>
                            <button class="btn btn-sm danger ml-2"
                                    :disabled="isViewOnlyAccess() || voicemail_drop_file_delete.loading"
                                    @click.prevent="deleteVmDropFile(scope.row.id)">
                                <i class="fa fa-spin fa-spinner"
                                   v-show="voicemail_drop_file_delete.id === scope.row.id && voicemail_drop_file_delete.loading"></i>
                                <i class="fa fa-trash"
                                   v-show="voicemail_drop_file_delete.id !== scope.row.id"></i>
                                <span>Remove</span>
                            </button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="form-group">
                    <div class="row">
                        <div class="col-12">
                            <div class="b-b nav-active-greenish">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                        <a class="border rounded-top nav-link active"
                                           href="#"
                                           data-toggle="tab"
                                           data-target="#voicemail-drop-file"
                                           aria-expanded="false">
                                            <i class="fa fa-upload text-md"></i>
                                            Upload Recording
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content pt-2 border border-top-0">
                                <div class="my-3 mx-3">
                                    <div class="mx-3 tab-pane animated fadeIn text-muted active"
                                         id="voicemail-drop-file"
                                         aria-expanded="true">
                                        <el-form ref="vm_drop_form"
                                                 autocomplete="off"
                                                 label-position="top"
                                                 v-loading="loading"
                                                 :model="voicemail_drop_file_upload"
                                                 :rules="rules_upload"
                                                 @submit.prevent.native="postVmDropFile">
                                            <el-form-item label="Title"
                                                          prop="vm_drop_file_title">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <el-input
                                                                v-model="voicemail_drop_file_upload.vm_drop_file_title"
                                                                type="text"
                                                                class="width-200"
                                                                :disabled="isViewOnlyAccess()"
                                                                :maxlength="20"
                                                                @input="customPreValidateForm('vm_drop_form')">
                                                        </el-input>
                                                    </div>
                                                </div>
                                            </el-form-item>
                                            <div>
                                                <audio-recorder
                                                        v-if="!recording_selected"
                                                        class="audio-recorder-wrapper"
                                                        :upload_text="'Select'"
                                                        :action="'/api/v1/user/pre-recorded-voicemail'"
                                                        :upload_type="'voicemail_file'"
                                                        @applyUploadedAudio="applyUploadedLiveAudio">
                                                </audio-recorder>
                                                <div class="audio-uploader-wrapper">
                                                    <el-form-item prop="data"
                                                                  class="mb-0">
                                                        <el-upload
                                                                drag
                                                                action="/api/v1/user/pre-recorded-voicemail"
                                                                :limit="1"
                                                                :disabled="isViewOnlyAccess()"
                                                                :headers="headers"
                                                                :on-success="onSuccessUploadPrerecordedVoicemail"
                                                                :on-error="onFailedUploadPrerecordedVoicemail"
                                                                :on-progress="progressUploadPrerecordedVoicemail"
                                                                :before-upload="beforeUploadPrerecordedVoicemail"
                                                                :on-remove="onPreRecordedFileRemove"
                                                                :file-list="uploadFileList.voicemail">
                                                            <i class="el-icon-upload"></i>
                                                            <div class="el-upload__text">
                                                                Drop file here or
                                                                <em>click to upload</em>
                                                            </div>
                                                            <div class="el-upload__tip"
                                                                 slot="tip">
                                                                MP3/WAV file (less than 8MB)
                                                            </div>
                                                        </el-upload>
                                                        <el-progress v-if="voicemail_drop_file_upload.data == null"
                                                                     :text-inside="true"
                                                                     :stroke-width="18"
                                                                     :percentage="uploadPercentage.voicemail"
                                                                     :status="uploadStatus.voicemail">
                                                        </el-progress>
                                                    </el-form-item>

                                                    <div class="row mt-3">
                                                        <div class="col-12">
                                                            <button class="btn greenish pull-right"
                                                                    :disabled="isViewOnlyAccess() || !add_validated || voicemail_drop_file_upload.loading"
                                                                    @click.prevent="postVmDropFile">
                                                                <i class="fa fa-spin fa-spinner"
                                                                   v-if="voicemail_drop_file_upload.loading"></i>
                                                                <i class="fa fa-plus"
                                                                   v-else></i>
                                                                <span>Add</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {form_validation_mixin, kyc_mixin} from '../mixins'
    import _ from "lodash";

    export default {
        name: 'user-vm-drop',

        mixins: [form_validation_mixin, kyc_mixin],

        props: {
            user_id: {
                required: true
            }
        },

        data() {
            return {
                auth: auth,
                loading: false,
                voicemail_drop_files: [],
                voicemail_drop_edit: {
                    title: '',
                    editing: false,
                    loading: false,
                    data: {}
                },
                voicemail_drop_file_upload: {
                    title: '',
                    uploading: false,
                    loading: false,
                    vm_drop_file_title: '',
                    uploaded_file_id: null,
                    data: null
                },
                voicemail_drop_file_delete: {
                    id: null,
                    loading: false
                },
                recording_selected: false,
                uploadPercentage: {
                    voicemail: 0,
                },
                uploadStatus: {
                    voicemail: 'success'
                },
                uploadFileList: {
                    voicemail: [],
                },
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + auth.user.profile.api_token,
                    'X-Socket-Id': window.Echo.socketId()
                },
                rules_upload: {
                    vm_drop_file_title: [
                        {
                            required: true,
                            message: 'Please provide a title',
                            trigger: 'blur'
                        }
                    ],
                    data: [
                        {
                            required: true,
                            message: 'Please upload or record a file',
                            trigger: 'change'
                        }
                    ]
                },
                rules_edit: {
                    title: [
                        {
                            required: true,
                            message: 'Please provide a title',
                            trigger: 'blur'
                        }
                    ]
                },
                add_validated: false,
                edit_validated: false
            }
        },

        mounted() {
            this.getVoicemailDropFileLibrary()
        },

        methods: {
            onSuccessUploadPrerecordedVoicemail(res) {
                this.$notify({
                    offset: 95,
                    title: 'Voicemail Drop',
                    message: 'Voicemail drop file uploaded successfully.',
                    type: 'success',
                    showClose: true,
                })
                this.uploadPercentage.voicemail = 0
                this.uploadStatus.voicemail = 'success'
                this.voicemail_drop_file_upload.data = res
                this.recording_selected = true
                this.customPreValidateForm('vm_drop_form')
            },

            onFailedUploadPrerecordedVoicemail(err) {
                this.handleUploadErrors(err.message)
                this.uploadStatus.voicemail = 'exception'
                this.uploadPercentage.voicemail = 0
            },

            beforeUploadPrerecordedVoicemail() {
                this.uploadStatus.voicemail = 'success'
                this.uploadPercentage.voicemail = 0
            },

            progressUploadPrerecordedVoicemail(event) {
                this.uploadPercentage.voicemail = parseInt(event.percent)
            },

            handleUploadErrors(error) {
                if (typeof error === 'string') {
                    error = JSON.parse(error)
                }
                let err
                if (error.message === 'This action is unauthorized.') {
                    err = {
                        status: 403,
                    }
                } else {
                    err = {
                        status: 422,
                        data: {
                            errors: error.errors.file
                        }
                    }
                }

                this.$root.handleErrors(err)
            },

            getExcludedVoicemailDropListDisplay(vmDopData) {
                if (this.voicemail_drop_edit.editing === false && this.voicemail_drop_edit.data.id != vmDopData.id) {
                    return true
                } else if (this.voicemail_drop_edit.editing === true && this.voicemail_drop_edit.data.id != vmDopData.id) {
                    return true
                } else {
                    return false
                }
            },

            enableUpdateVoicemailDropFile(audio) {
                this.voicemail_drop_edit.data = audio
                this.voicemail_drop_edit.editing = true
                this.voicemail_drop_edit.loading = false
                this.voicemail_drop_edit.title = audio.name
            },

            disableUpdateVoicemailDropFile() {
                this.voicemail_drop_edit.data = {}
                this.voicemail_drop_edit.editing = false
                this.voicemail_drop_edit.loading = false
                this.voicemail_drop_edit.title = ''
                this.customPreValidateForm('vm_drop_edit_form', true)
            },

            resetVoicemailDropFile() {
                this.voicemail_drop_file_upload.title = ''
                this.voicemail_drop_file_upload.uploading = false
                this.voicemail_drop_file_upload.loading = false
                this.voicemail_drop_file_upload.vm_drop_file_title = ''
                this.voicemail_drop_file_upload.uploaded_file_id = null
                this.voicemail_drop_file_upload.data = {}
                this.customPreValidateForm('vm_drop_edit_form', true)
            },

            updateVmDropFile(id) {
                if (!this.user_id || !id) {
                    return
                }

                this.voicemail_drop_edit.loading = true
                axios.put(`/api/v1/voicemail-drop/${id}`, {
                    vm_drop_file_title: this.voicemail_drop_edit.title,
                }).then(res => {
                    this.$notify({
                        offset: 95,
                        title: 'Voicemail Drop',
                        message: 'Voicemail drop file updated successfully',
                        type: 'success',
                        showClose: true,
                    })
                    this.disableUpdateVoicemailDropFile()
                    this.getVoicemailDropFileLibrary()
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.voicemail_drop_edit.loading = false
                })
            },

            postVmDropFile() {
                if (!this.user_id) {
                    return
                }

                this.voicemail_drop_file_upload.loading = true
                axios.post(`/api/v1/voicemail-drop`, {
                    user_id: this.user_id,
                    vm_drop_file_title: this.voicemail_drop_file_upload.vm_drop_file_title,
                    uploaded_file_id: this.voicemail_drop_file_upload.data.id
                }).then(res => {
                    this.$notify({
                        offset: 95,
                        title: 'Voicemail Drop',
                        message: 'Voicemail drop added.',
                        type: 'success',
                        showClose: true,
                    })

                    this.resetVoicemailDropFile()
                    this.uploadFileList.voicemail = []
                    this.recording_selected = false
                    this.getVoicemailDropFileLibrary()
                    this.customPreValidateForm('vm_drop_form', true)
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.voicemail_drop_file_upload.loading = false
                })
            },

            deleteVmDropFile(id) {
                if (!this.user_id || !id) {
                    return
                }

                this.$confirm('Deleting the voicemail drop file will remove it from the dialer VM Drop button. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(() => {
                    this.voicemail_drop_file_delete.id = id
                    this.voicemail_drop_file_delete.loading = true

                    axios.delete(`/api/v1/voicemail-drop/${id}`).then(res => {
                        this.$notify({
                            offset: 95,
                            title: 'Voicemail Drop',
                            message: 'Voicemail drop file deleted successfully',
                            type: 'success',
                            showClose: true,
                        })
                        this.customPreValidateForm('vm_drop_edit_form', true)
                        this.getVoicemailDropFileLibrary()
                    }).catch(err => {
                        this.$root.handleErrors(err.response)
                    }).then(_ => {
                        this.voicemail_drop_file_delete.id = null
                        this.voicemail_drop_file_delete.loading = false
                    })
                })
            },

            getVoicemailDropFileLibrary() {
                if (!this.user_id) {
                    return
                }

                this.loading = true
                axios.get('/api/v1/voicemail-drop', {
                    params: {
                        user_id: this.user_id
                    }
                }).then(res => {
                    this.voicemail_drop_files = res.data
                    this.loading = false
                }).catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading = false
                })
            },

            applyUploadedLiveAudio(data) {
                this.$notify({
                    offset: 95,
                    title: 'Voicemail Drop',
                    message: 'Voicemail drop file uploaded successfully.',
                    type: 'success',
                    showClose: true,
                })
                this.recording_selected = true
                this.voicemail_drop_file_upload.data = data
                this.uploadFileList.voicemail = []
                this.uploadFileList.voicemail.push({
                    name: data.file_name
                })
                this.customPreValidateForm('vm_drop_form')
            },

            onPreRecordedFileRemove(file, fileList) {
                if (fileList.length == 0) {
                    this.voicemail_drop_file_upload.data = null
                    this.recording_selected = false
                    this.customPreValidateForm('vm_drop_edit_form')
                }
            },

            customPreValidateForm(form_name, reset = false, force_validate = false) {
                let form_element = _.get(this.$refs, form_name, null)

                if (!form_element) {
                    return
                }

                if (reset == true) {
                    form_element.clearValidate()
                    this.add_validated = false
                    this.edit_validated = false

                    // halt if we don't want to continue the validation
                    if (!force_validate) {
                        return
                    }
                }

                let fields = form_element.fields
                if (fields.find((f) => f.validateState === 'validating')) {
                    setTimeout(() => {
                        this.customPreValidateForm(form_name)
                    }, 100)

                    return
                }

                switch (form_name) {
                    case 'vm_drop_form':
                        this.add_validated = this.validateForm(form_name)
                        break
                    case 'vm_drop_edit_form':
                        this.edit_validated = this.validateForm(form_name)
                        break
                }
            }
        }
    }
</script>
