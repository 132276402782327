<template>
    <el-input v-model="value"
              class="el-input-copy"
              ref="input_copy"
              readonly
              @click.native="copy">
        <template slot="suffix">
            <div class="input-suffix"
                 :class="copying ? 'text-dark-greenish':''">
                <i class="fa fa-copy"></i>
                <span v-if="copying">Copied!</span>
            </div>
        </template>
    </el-input>
</template>

<script>
export default {
    name: "input-readonly-with-copy",

    props: {
        value: {
            required: true
        }
    },

    data() {
        return {
            copying: false,
            timeout: null
        }
    },

    methods: {
        copy() {
            let input_element = this.$refs.input_copy.$el.querySelector('.el-input__inner')

            this.copying = true
            input_element.select()
            document.execCommand('copy')

            if (this.timeout) {
                clearTimeout(this.timeout)
            }

            this.timeout = setTimeout(() => {
                this.copying = false
            }, 1000)
        }
    }
}
</script>
