<template>
    <div v-loading="loading">
        <div class="row p-relative mb-3">
            <div class="col-3">
                <inbound-connection-rate-widget ref="inbound_connection_rate_widget"/>
            </div>
            <div class="col-9 h-100">
                <inbound-graph-widget/>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-3">
                <div class="card p-3 h-100"
                     v-loading="loading">
                    <div class="d-flex mb-3">
                        <i class="fa fa-phone text-greenish mr-2 align-self-center"></i>
                        <h6 class="mb-0 align-self-center">Call Dispositions</h6>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <metric-widget label="Connected"
                                           description="Communications disposed as connected"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.inbound_call_disposition_connected">
                            </metric-widget>
                        </div>
                        <div class="col-md-6 mb-3">
                            <metric-widget label="Busy"
                                           description="Communications disposed as busy"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.inbound_call_disposition_busy">
                            </metric-widget>
                        </div>
                        <div class="col-md-6 mb-3">
                            <metric-widget label="No Answer"
                                           description="Communications disposed as no answer"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.inbound_call_disposition_no_answer">
                            </metric-widget>
                        </div>
                        <div class="col-md-6 mb-3">
                            <metric-widget label="Left Live Message"
                                           description="Communications disposed as left live message"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.inbound_call_disposition_left_live_message">
                            </metric-widget>
                        </div>
                        <div class="col-md-6 mb-3">
                            <metric-widget label="Left Voicemail"
                                           description="Communications disposed as left voicemail"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.inbound_call_disposition_left_voicemail">
                            </metric-widget>
                        </div>
                        <div class="col-md-6 mb-3">
                            <metric-widget label="Wrong Number"
                                           description="Communications disposed as wrong number"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.inbound_call_disposition_wrong_number">
                            </metric-widget>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="row inbound-aggregates-wrapper" v-loading="loading">
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Average Talk Time"
                                       :is_card="true"
                                       format="fixFullDuration"
                                       :metric="metrics.inbound_average_call_talk_time">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Average Queue Hold Time"
                                       :is_card="true"
                                       :metric="metrics.inbound_average_queue_hold_time">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Answered"
                                       :is_card="true"
                                       :metric="metrics.inbound_answered_calls">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Missed"
                                       :is_card="true"
                                       :metric="metrics.inbound_missed_calls">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Abandoned"
                                       :is_card="true"
                                       :metric="metrics.inbound_abandoned_calls">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Voicemail"
                                       :is_card="true"
                                       :metric="metrics.inbound_voicemail_calls">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Calls Above 2 Minutes"
                                       description="The number of inbound calls that has a duration of more than 2 minutes."
                                       :is_card="true"
                                       :metric="metrics.inbound_calls_above_two_minutes">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Calls Below 30 Seconds"
                                       description="The number of inbound calls that has a duration of less than 30 seconds."
                                       :is_card="true"
                                       :metric="metrics.inbound_calls_thirty_seconds_and_below">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Untagged"
                                       description="The number of total untagged inbound communications"
                                       :is_card="true"
                                       :metric="metrics.inbound_untagged">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="First Time"
                                       description="The number of total first time communications"
                                       :is_card="true"
                                       :metric="metrics.inbound_first_time">
                        </metric-widget>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <inbound-sms-rate-widget></inbound-sms-rate-widget>
            </div>
        </div>
    </div>
</template>

<script>
import InboundConnectionRateWidget from "./widgets/inbound-connection-rate-widget";
import MetricWidget from "./widgets/metric-widget";
import InboundSmsRateWidget from "./widgets/inbound-sms-rate-widget";
import InboundGraphWidget from "./widgets/inbound-graph-widget";
import DashboardFilter from '../../filters/dashboard-filter'

export default {
    name: "inbound-dashboard",

    components: {
        MetricWidget,
        InboundSmsRateWidget,
        InboundConnectionRateWidget,
        InboundGraphWidget
    },

    data() {
        return {
            loading: false,
            metrics: {},
        }
    },

    computed: {
        kpiFilters() {
            return DashboardFilter.kpiFilters()
        }
    },

    mounted() {
        this.loadInboundDashboardKpis()
    },

    methods: {
        loadInboundDashboardKpis() {
            this.loading = true;
            axios.get('/api/v1/kpis/communication/inbound/calls', {
                params: {
                    aggregates: [
                        20, // INBOUND_ANSWERED_CALLS
                        21, // INBOUND_MISSED_CALLS
                        22, // INBOUND_ABANDONED_CALLS
                        23, // INBOUND_VOICEMAIL_CALLS
                        24, // INBOUND_CALLS_BELOW_THIRTY_SECONDS
                        25, // INBOUND_CALLS_ABOVE_TWO_MINUTES
                        27, // INBOUND_AVERAGE_TALK_TIME
                        26, // INBOUND_AVERAGE_QUEUE_HOLD_TIME
                        36, // INBOUND_UNTAGGED
                        38, // INBOUND_FIRST_TIME
                    ],
                    filters: DashboardFilter.get()
                }
            }).then(res => {
                this.loading = false
                this.metrics = res.data
            })
        }
    },

    watch: {
        kpiFilters() {
            this.loadInboundDashboardKpis()
        }
    }
}
</script>

