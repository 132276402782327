<template>
    <div class="container-form-height-indicator"
         :style="'bottom: ' + bottom + 'px'"
         v-if="show"
         @click="scrollToBottom">
        <button type="button"
                class="btn-form-height-indicator">
            <i class="fa fa-arrow-down"></i>
        </button>
    </div>
</template>

<script>
    export default {
        name: "form-height-indicator",

        props: {
            bottom: {
                default: 70,
                required: false
            }
        },

        data() {
            return {
                window_height: 0,
                document_height: 0,
                has_scrolled_to_bottom: false,
            }
        },

        computed: {
            show() {
                return this.document_height > (this.window_height + 70) && !this.has_scrolled_to_bottom
            }
        },

        created() {
            if (this.$attrs.bottom) {
                this.bottom = this.$attrs.bottom
            }

            // clear browser dimension check interval
            if (this.$options.browser_dimension_check_interval) {
                clearInterval(this.$options.browser_dimension_check_interval)
            }

            this.$options.browser_dimension_check_interval = setInterval(() => {
                this.getBrowserDimension()
            }, 1000)

            window.onscroll = () => {
                this.getBrowserDimension()
            }

            window.addEventListener('resize', () => {
                this.getBrowserDimension()
            })

            document.body.addEventListener('resize', () => {
                this.getBrowserDimension()
            })
        },

        methods: {
            getBrowserDimension() {
                let body = document.body
                let html = document.documentElement

                this.window_height = window.innerHeight
                this.document_height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

                let totalPageHeight = document.body.scrollHeight;
                let scrollPoint = window.scrollY + window.innerHeight;

                this.has_scrolled_to_bottom = scrollPoint >= (totalPageHeight - 70)
            },

            scrollToBottom() {
                window.scrollTo(0, document.body.scrollHeight);
            }
        },

        beforeDestroy() {
            clearInterval(this.$options.browser_dimension_check_interval)
        }
    }
</script>
