var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c("div", { staticClass: "col-sm-12 col-md-8 mx-auto" }, [
            _vm.showLoginBanner
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-warning",
                    attrs: { role: "alert" },
                  },
                  [_vm._m(0), _vm._v(" "), _vm._m(1)]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.actionIsAllowed && !_vm.hasSequenceAccess && _vm.authUser
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-warning",
                    attrs: { role: "alert" },
                  },
                  [_vm._m(2), _vm._v(" "), _vm._m(3)]
                )
              : _c("div", { staticClass: "card text-center" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("h6", { staticClass: "card-subtitle" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.basicAction.cardSubtitle)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.actionIsAllowed
                        ? _c("div", [
                            _c("hr"),
                            _vm._v(" "),
                            _c("p", { staticClass: "card-text mt-3" }, [
                              _vm._v(
                                "\n              Click the button below to proceed adding contacts to Aloware. Your newly added contacts\n              will be tagged as\n              "
                              ),
                              _c("span", { staticClass: "badge badge-dark" }, [
                                _vm._v(_vm._s(_vm.model.tag_name)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("hr"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.actionIsAllowed
                        ? _c("div", [
                            _c("hr"),
                            _vm._v(" "),
                            _c("p", { staticClass: "card-text mt-3" }, [
                              _vm._v(
                                "\n              Sorry! It seems that you're not allowed to proceed with this action.\n              Please resolve the error messages shown and try again.\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("hr"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "px-3" },
                        [
                          _vm.basicAction.showPowerDialerForm &&
                          _vm.actionIsAllowed
                            ? [
                                _c("add-to-power-dialer", {
                                  ref: "basicActionPowerDialerForm",
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.actionIsAllowed && _vm.authUser
                            ? [
                                _vm.basicAction.showSequenceForm
                                  ? _c("enroll-to-sequence", {
                                      ref: "basicActionEnrollToSequenceForm",
                                      attrs: { "auth-user": _vm.authUser },
                                    })
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      !_vm.isLoading
                        ? [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-success text-white mt-3 mb-1",
                                attrs: {
                                  id: "add-to-power-dialer-btn",
                                  type: "button",
                                  disabled: !_vm.actionIsAllowed,
                                },
                                on: { click: _vm.handleBasicActionRedirect },
                              },
                              [
                                _c("i", {
                                  class: `${_vm.basicAction.actionButtonIcon} mr-1`,
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.basicAction.actionButtonText) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        : [_vm._m(5)],
                    ],
                    2
                  ),
                ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "alert-heading mt-1" }, [
      _c("i", {
        staticClass: "glyphicon glyphicon-log-in mt-1 mr-1",
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v("\n          GO TO LOGIN PAGE\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "It looks like you're not logged in to Aloware. Open a new tab by clicking\n          "
      ),
      _c(
        "a",
        {
          staticClass: "font-weight-bold text-decoration-underline",
          attrs: { href: "/login", target: "_blank" },
        },
        [_vm._v("\n            here,\n          ")]
      ),
      _vm._v(
        "\n          enter your credentials and go back to this tab\n        "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "alert-heading mt-1" }, [
      _c("i", {
        staticClass: "glyphicon glyphicon-lock mt-1 mr-1",
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v("\n          FORBIDDEN\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n          Sorry, but it looks like you don't have enough permissions to proceed with this action.\n          Please check if your Aloware account has access to "
      ),
      _c("b", [_vm._v("Sequences+")]),
      _vm._v(" or\n          contact technical support.\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "card-title" }, [
      _c("span", { staticClass: "text-success" }, [
        _vm._v("Aloware & Pipedrive Integrations"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-success mt-3 mb-1",
        attrs: { type: "button", disabled: "" },
      },
      [
        _c("i", { staticClass: "fa fa-spinner fa-spin mr-2" }),
        _vm._v(" "),
        _c("span", [_vm._v("Loading...")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }