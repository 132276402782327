import state from './account-registration.store'
import getters from './account-registration.getters'
import mutations from './account-registration.mutations'
import actions from './account-registration.actions'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
