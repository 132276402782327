<template>
    <el-dialog id="el-dialog-compliance-wizard"
               width="60vw"
               top="4vh"
               append-to-body
               custom-class="dialog-no-border"
               :visible.sync="compliance_wizard_dialog_visible"
               :before-close="() => closeComplianceWizard()"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               @open="validateCurrentStepForm">
        <div slot="title">
            <h5 class="mb-0">
                <i class="fas fa-hat-wizard"></i>
                {{ compliaceWizardTitle }}
            </h5>
        </div>

        <el-steps :active="current_step"
                  finish-status="success"
                  class="mb-3"
                  align-center>
            <el-step title="Messaging Service Registration"></el-step>
            <el-step title="A2P Use Case Registration"></el-step>
            <el-step title="Finish"></el-step>
        </el-steps>

        <steps :current-step="current_step"
               :alert-message="alertMessage"
               :selected_lines="selected_lines"
               :selected_messaging_service="messaging_service"
               @alert="updateAlert"
               @next="goNext">
        </steps>
    </el-dialog>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {form_validation_mixin, compliance_mixin} from '../../../mixins'
import Steps from './steps/steps.vue'
import * as ComplianceBundleStatus from '../../../constants/compliance-bundle-statuses'
import BusinessRegistration from "./steps/business-registration.vue";

export default {
    mixins: [
        form_validation_mixin,
        compliance_mixin
    ],
    components: {
        BusinessRegistration,
        Steps,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ComplianceBundleStatus,
            customerProfileInUse: {},
            a2PBrandInUse: {},
            selected_lines: [],
            selected_messaging_service: null,
            current_step: 0
        }
    },
    mounted() {
        VueEvent.listen('compliance_wizard_opened', ({requested_step, lines, messaging_service}) => {
            this.selected_lines = lines
            this.messaging_service = messaging_service

            if (requested_step !== null) {
                this.current_step = requested_step
                return
            }

            this.current_step = this.wizardStep
        })

        // get Customer Profile in use
        this.getCustomerProfileInUse()

        // get A2P Brand in use
        this.getA2PBrandInUse()
    },
    methods: {
        ...mapActions('cache', ['setCurrentCompany']),
        ...mapActions(['setComplianceWizardDialogVisible', 'setEasyComplianceWizard']),

        closeComplianceWizard(force = false) {
            // If force is true, close the wizard without any confirmation
            if (force) {
                this.setComplianceWizardDialogVisible(false)
                this.setEasyComplianceWizard(false)
                return
            }

            this.$confirm(`Are you sure you want to exit the ${this.compliaceWizardTitle}? Your selected lines will get reset after closing.`, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'w-25 fixed',
                confirmButtonClass: 'el-button--success',
                type: 'warning'
            }).then(() => {
                this.setComplianceWizardDialogVisible(false)
                this.setEasyComplianceWizard(false)
                this.$emit('close')
                VueEvent.fire('compliance_wizard_closed')
            }).catch(() => {
                this.validateCurrentStepForm()
            })
        },
        getCustomerProfileInUse() {
            axios
                .get('/api/v1/compliance/customer-profiles', {
                    params: {
                        in_use_only: true,
                        status: [
                            ComplianceBundleStatus.STATUS_STAGED,
                            ComplianceBundleStatus.STATUS_PENDING,
                            ComplianceBundleStatus.STATUS_IN_REVIEW,
                            ComplianceBundleStatus.STATUS_REJECTED,
                            ComplianceBundleStatus.STATUS_APPROVED
                        ],
                    },
                })
                .then(({data}) => {
                    if (data.data && data.data.length > 0) {
                        this.customerProfileInUse = data.data[0]
                    }
                })
        },
        getA2PBrandInUse() {
            axios
                .get('/api/v1/compliance/a2p-brands', {
                    params: {
                        in_use_only: true,
                        status: [
                            ComplianceBundleStatus.STATUS_STAGED,
                            ComplianceBundleStatus.STATUS_PENDING,
                            ComplianceBundleStatus.STATUS_IN_REVIEW,
                            ComplianceBundleStatus.STATUS_REJECTED,
                            ComplianceBundleStatus.STATUS_APPROVED,
                        ],
                    },
                })
                .then(({data}) => {
                    if (data.data && data.data.length > 0) {
                        this.a2PBrandInUse = data.data[0]
                    }
                })
        },
        generateErrorMessage({data}) {
            const message = data.message || data.error || 'Oops, something went wrong. Please try again later or contact support.'

            if (data.errors) {
                // validation errors (pick the first one)
                return Object.values(data.errors).flat()[0] || message
            }

            return message
        },
        validateCurrentStepForm() {
            if (this.current_step === 1) {
                this.preValidateForm('messagingServiceForm')
            }
            if (this.current_step === 2) {
                this.preValidateForm('a2pUseCaseForm')
            }
        },
        updateAlert(alert) {
            // check if alert is string
            if (typeof alert === 'string') {
                this.alertMessage = alert
            }

            // check if alert is object
            if (typeof alert === 'object') {
                this.alertMessage = this.generateErrorMessage(alert)
            }
        },
        goNext(messaging_service) {
            this.$emit('next', messaging_service)

            this.validateCurrentStepForm()
            this.messaging_service = messaging_service
            // Do not proceed with A2P Campaign
            if (this.current_step === 2) {
                this.closeComplianceWizard(true)
                return
            }

            this.current_step++
        },
    },
    computed: {
        wizardStep() {
            // check if customer profile object is empty or status is rejected
            if (Object.keys(this.customerProfileInUse).length === 0 || this.customerProfileInUse.status === ComplianceBundleStatus.STATUS_REJECTED) {
                return 0
            }

            if (Object.keys(this.a2PBrandInUse).length === 0 || this.a2PBrandInUse.status === ComplianceBundleStatus.STATUS_REJECTED) {
                return 0
            }

            // If company is missing mandatory fields
            if (!this.current_company.auth_rep_first_name || !this.current_company.auth_rep_last_name || !this.current_company.auth_rep_email || !this.current_company.auth_rep_phone_number || !this.current_company.auth_rep_business_title || !this.current_company.auth_rep_job_position) {
                return 1
            }

            // By default takes to the Campaign Registration form.
            return 2
        },
        compliaceWizardTitle() {
            let title = 'A2P 10DLC Campaign Wizard'
            if (this.easy_wizard) {
                title = `Easy ${title}`
            }
            return title
        },
        ...mapState('cache', ['current_company']),
        ...mapState(['compliance_wizard_dialog_visible', 'easy_wizard']),
    },
}
</script>
