var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("hr"),
    _vm._v(" "),
    _c("p", [
      _c("span", [
        _vm._v("\n            Your CSM is:\n            "),
        _c("b", [_vm._v(_vm._s(_vm.csmInfo.name))]),
      ]),
    ]),
    _vm._v(" "),
    _c("p", [
      _c("span", [_vm._v("Email:")]),
      _vm._v(" "),
      _c("a", { attrs: { href: "mailto:" + _vm.csmInfo.email } }, [
        _c("b", [_vm._v(_vm._s(_vm.csmInfo.email))]),
      ]),
    ]),
    _vm._v(" "),
    _c("p", [
      _c("span", [_vm._v("Phone:")]),
      _vm._v(" "),
      _c("b", [_vm._v(_vm._s(_vm.csmInfo.phone))]),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "button",
        {
          staticClass: "btn btn-success btn-xs",
          attrs: { disabled: !_vm.hasCalendar },
          on: { click: _vm.handleRequestAction },
        },
        [_vm._v("\n            Request a CSM Call\n        ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "btn btn-success btn-xs text-white text-reset text-decoration-none",
          attrs: { target: "_blank", href: _vm.FORM_URL },
        },
        [
          _vm._v("\n            Order Training\n            "),
          _c("span", { staticClass: "text-xs" }, [_vm._v("(paid)")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("hr"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }