<template>
    <div :class="[('summary-card-' + status)] + ' h-100'">
        <a href="#"
           :class="{'cursor-initial': !is_whole_click}">
            <div class="card summary-card-hover"
                 :class="[is_transcription ? '' : 'h-100', {'border-0 shadow-none pb-3 bg-transparent d-flex justify-content-center': no_borders}]">
                <div class="row">
                    <div :class="[hasExtraAggregatesData ? 'col-10' : 'col-12', chart_type != null ? 'pb-3' : '']">
                        <div class="col-12 p-1" v-if="img_src || icon || title">
                            <div class="d-inline-block pt-3 align-bottom"
                                 :class="[no_borders ? 'pl-0' : 'pl-2']">
                                <img class="w-24"
                                     data-testid="summary-report-card-img"
                                     v-if="img_src"
                                     :src="img_src">
                                <i class="material-icons"
                                   style="color: #03c04c"
                                   v-if="icon">
                                    {{ icon }}
                                </i>
                            </div>
                            <div class="d-inline-block pl-2" v-if="title">
                                <strong class="text-md title">{{ title }}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div :class="chartContainerClass"
                                 v-if="chart_type != null"
                                 @click.prevent="changeCardStatus()">
                                <highcharts :options="graphOptions"
                                            ref="highchart"
                                            style="height: 150px;"
                                            data-testid="summary-report-card-graph"
                                            v-bind:id="graph_id">
                                </highcharts>
                            </div>
                            <div :class="(chart_type != null ? 'pl-0':'text-center mt-3') + ' col'"
                                 v-if="!hide_labels">
                                <div class="row "
                                     :class="{'summary-numbers-v-align-30': (chart_type != null && summary.data_length <= 2)}">
                                    <template v-for="(item, index) in summary.data">
                                        <div class="mt-3 countup-hover"
                                             :key="index"
                                             data-testid="summary-report-card-countup-template"
                                             :class="[(summary.data_length == 1 ? 'col-12' : dataColumnClass), (!item.clickable ? 'unclickable' : '')]"
                                             @mouseover="mouseHoverEvent('over', index)"
                                             @mouseleave="mouseHoverEvent('leave', index)"
                                             @click.prevent="changeCardStatus(index)"
                                             v-if="typeof item.show_data === 'undefined' || (typeof item.show_data !== 'undefined' && item.show_data)">
                                            <h4 class="m-a-0 mb-2 text-lg metric-value">
                                                <ICountUp v-if="typeof item.count !== 'undefined' && item.count !== null"
                                                          :ref="'countup-' + index"
                                                          :duration="count_up.duration"
                                                          :endVal="(chart_type == 'pie' && !is_donut ? parseFloat(item.count) : parseInt(item.count))"
                                                          :options="countUpOptions(index)"
                                                          data-testid="summary-report-card-countup"
                                                          :startVal="count_up.startVal"
                                                />
                                                <span v-if="item.count == null">-</span>
                                            </h4>
                                            <small :class="[is_transcription ? 'mb-2' : '']" class="text-muted text-sm metric-label">{{ item.label }}</small>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="hasExtraAggregatesData"
                        :class="classesFormultipleExtraData"
                    >
                        <div
                            class="row"
                            v-for="extra_aggregate in extra_aggregates"
                            :key="extra_aggregate.title"
                        >
                            <summary-report-sms
                                data-testid="summary-report-sms-component"
                                v-if="extra_aggregate.type === 'SMS'"
                                :title="extra_aggregate.title"
                                :data="extra_aggregate.data"
                                :img_src="extra_aggregate.img_src"
                                :icons="extra_aggregate.icons"
                                :is_donut="false"
                                :chart_type="null"
                                :loading="loading"
                                :card_index="0"
                                :campaign_id="campaign_id"
                                :workflow_id="workflow_id"
                                :ring_group_id="ring_group_id"
                                :user_id="user_id">
                            </summary-report-sms>
                            <summary-report-unreturned
                                v-else
                                data-testid="summary-report-sms-component"
                                :title="extra_aggregate.title"
                                :data="extra_aggregate.data"
                                :img_src="extra_aggregate.img_src"
                                :icons="extra_aggregate.icons"
                                :is_donut="false"
                                :chart_type="null"
                                :loading="loading"
                                :card_index="0"
                                :campaign_id="campaign_id"
                                :workflow_id="workflow_id"
                                :ring_group_id="ring_group_id"
                                :user_id="user_id">
                            </summary-report-unreturned>
                        </div>
                        
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import {summary_widgets_mixin} from '../mixins'
import ICountUp from 'vue-countup-v2'

export default {
    mixins: [summary_widgets_mixin],

    components: {
        ICountUp
    },

    props: {
        title: {
            required: false,
            default: null
        },
        data: {
            required: false,
            default: () => []
        },
        donut_value: {
            required: false,
            default: 0
        },
        img_src: {
            required: false,
            default: null
        },
        chart_type: {
            required: false,
            default: 'pie'
        },
        is_donut: {
            required: false,
            default: false
        },
        hide_labels: {
            required: false,
            default: false
        },
        status: {
            required: false,
            default: 'inactive'
        },
        loading: {
            required: false,
            default: true
        },
        icon: {
            required: false,
            default: null
        },
        card_index: {
            required: false,
            default: 0
        },
        is_whole_click: {
            required: false,
            default: false
        },
        no_borders: {
            required: false,
            default: false
        },
        hide_tooltip: {
            required: false,
            default: false
        },
        extra_aggregates: {
            required: false,
            default: () => []
        },
        campaign_id: {
            required: false
        },
        workflow_id: {
            required: false
        },
        ring_group_id: {
            required: false
        },
        user_id: {
            required: false
        },
        col: {
            required: false,
            default: null
        },
        is_transcription: {
            required: false,
            default: false
        }
    },

    data() {
        let easingFn = function (t, b, c, d) {
            let ts = (t /= d) * t
            let tc = ts * t
            return b + c * (tc + -3 * ts + 3 * t)
        }
        return {
            graph_id: this.title + '_graph',
            count_up: {
                startVal: 0,
                duration: 2.5,
                options: {
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true
                },
                decimal_options: {
                    decimalPlaces: 2,
                    suffix: '%',
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true
                },
                custom_formatting: {
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true,
                    formattingFn: this.formatSeconds
                },
                custom_formatting_without_seconds: {
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true,
                    formattingFn: this.formatTimeWithoutSeconds
                },
                percentage_formatting: {
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true,
                    suffix: '%',
                }
            },
            options: {
                chart: {
                    type: this.chart_type,
                    events: {
                        load: (event) => {
                            // when is chart ready
                            this.updateChart(event.target)
                        }
                    },
                    backgroundColor: 'rgba(0,0,0,0)',
                    spacingTop: 0,
                    spacingRight: 0,
                    spacingBottom: 0,
                    spacingLeft: 0,
                    margin: [0,0,0,0]
                },
                title: {
                    text: "",
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 19
                },
                plotOptions: {
                    pie: {
                        shadow: false
                    },
                    series: {
                        animation: {
                            duration: 0
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    followPointer: true,
                    split: false,
                    formatter: function (e) {
                        let toReturn = '<b style="font-size: 16px; font-weight: 400;">' + this.point.name + '</b>: ' + this.point.y.toLocaleString()
                        return toReturn
                    }
                },
                legend: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                series: []
            },
            chart: null
        }
    },

    computed: {
        graphOptions() {
            return this.options
        },
        dataColumnClass() {
            if (this.data.length < 6) {
                return 'col-' + (12  / this.data.length)
            }

            if (this.col !== null) {
                return 'col-' + this.col
            }

            let column_percent = (12 / Math.ceil(this.data.length / 2))
            return 'col-' + column_percent.toString().replace('.', '-')
        },
        summary() {
            let data = {}
            let keys = Object.keys(this.data)
            for (let index in keys) {
                data[keys[index]] = this.data[keys[index]]
                // fix the count's value.
                // if is_seconds, retain null value
                // else, count should always have 0 or its current numeric value
                if (!data[keys[index]].is_seconds && (this.data[keys[index]].count == null || this.data[keys[index]].count === undefined)) {
                    data[keys[index]].count = 0
                }
            }
            return {data: data, data_length: Object.keys(data).length}
        },
        chartContainerClass() {
            return [this.no_borders && this.summary.data_length == 2 ? 'col-5' : (this.summary.data_length ? 'col-4' : 'col-3')]
        },
        hasExtraAggregatesData() {
            return this.extra_aggregates && this.extra_aggregates[0] && this.extra_aggregates[0].data;
        },
        extraAggregatesDataCount() {
            return this.extra_aggregates.length;
        },
        classesFormultipleExtraData() {
            return [
                'col-2 pl-0 card-border-left d-flex align-items-center pl-3 pr-4',
                this.extraAggregatesDataCount > 1 ? 'flex-column' : 'justify-content-center'
            ]
        }
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.changeChartSize()
        })
    },

    methods: {
        changeCardStatus: _.debounce(function (index = null) {
            if (index != null && this.is_whole_click){
                return
            }
            if (index != null) {
                index = '-' + index
            } else {
                index = ''
            }
            this.$emit('changeCardStatus', this.card_index.toString() + index)
        }, 100),

        updateChart(chart) {
            this.chart = chart
            if (!this.options.title.text.length) {
                chart.redraw()
            }
        },

        mouseHoverEvent(direction, index) {
            if (this.data[index].count === null) {
                return
            }
            if (!this.chart || !this.chart.series || !this.chart.series[0] || !this.chart.series[0].data[index]) {
                return
            }
            let point = this.chart.series[0].data[index]
            if (direction == 'over') {
                point.slice(true)
                this.chart.tooltip.refresh(point)
                return
            }
            point.slice(false)
            this.chart.tooltip.hide()
        },

        formatSeconds(value) {
            return this.$options.filters.fixFullDuration(value)
        },

        formatTimeWithoutSeconds(value) {
            return this.$options.filters.fixFullDuration(value, false)
        },

        countUpOptions(index = null) {
            if (index != null && typeof this.data[index].is_seconds !== 'undefined' && this.data[index].is_seconds) {
                return this.count_up.custom_formatting
            }

            if (
                index != null &&
                typeof this.data[index].time_format_without_seconds !== 'undefined' &&
                this.data[index].time_format_without_seconds
            ) {
                return this.count_up.custom_formatting_without_seconds
            }

            if (
                index != null &&
                typeof this.data[index].percentage_format !== 'undefined' &&
                this.data[index].percentage_format
            ) {
                return this.count_up.percentage_formatting
            }

            if (this.chart_type == 'pie' && !this.is_donut) {
                return this.count_up.decimal_options
            }
            return this.count_up.options
        }
    },

    watch: {
        'loading': function () {
            // update the graph after the summary data is fetched
            if (!this.loading) {
                let new_data = []
                for (let index in this.data) {
                    if (this.data[index].show_in_chart) {
                        new_data[index] = [
                            this.data[index].label,
                            parseInt(this.data[index].count)
                        ]
                    }
                }
                this.options.series = [
                    {
                        name: '',
                        data: new_data,
                        showInLegend: false,
                        dataLabels: {
                            enabled: false
                        }
                    }
                ]
                if (this.is_donut) {
                    this.options.title.text = "<span class='text-lg metric-total-value'>" + this.$options.filters.numFormat(this.donut_value) + "</span><br><span class='metric-total-label'>TOTAL</span>"
                    this.options.series[0].size = '100%'
                    this.options.series[0].innerSize = '75%'
                }
            }
        }
    }
}
</script>
