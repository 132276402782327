<template>
    <div>
        <el-select class="d-inline-block mr-2"
                   placeholder="Select Country"
                   v-model="country_code"
                   size="large"
                   style="width:300px;"
                   default-first-option
                   filterable
                   autocomplete="off"
                   :disabled="disabled"
                   @change="changeCountry">
            <el-option-group v-if="supported_country_codes.length > 0"
                             key="Supported Countries"
                             label="Supported Countries">
                <el-option v-for="(country, index) in supported_countries"
                           :key="index"
                           :label="country.name + country_phone_prefix"
                           :value="country.code"
                           :disabled="disableCountry(country.code)">
                    <el-tooltip :content="country_tooltip"
                                :disabled="!disableCountry(country.code)">
                        <div class="text-md d-flex align-items-center pt-1 pb-1">
                            <span class="ml-2">{{ country.name }} ({{ country.prefix }})</span>
                        </div>
                    </el-tooltip>
                </el-option>
            </el-option-group>
            <el-option-group v-if="supported_country_codes.length > 0"
                             key="Unsupported Countries"
                             label="Unsupported Countries">
                <el-option v-for="(country, index) in unsupported_countries"
                           :key="index"
                           :label="country.name + country_phone_prefix"
                           :value="country.code"
                           :disabled="true">
                    <div class="text-md d-flex align-items-center pt-1 pb-1">
                        <span class="ml-2">{{ country.name }} ({{ country.prefix }})</span>
                    </div>
                </el-option>
            </el-option-group>
            <el-option v-for="(country, index) in countries"
                       :key="index"
                       :label="country.name + country_phone_prefix"
                       :value="country.code"
                       v-if="supported_country_codes.length === 0">
                <div class="text-md d-flex align-items-center pt-1 pb-1">
                    <span class="ml-2">{{ country.name }} ({{ country.prefix }})</span>
                </div>
            </el-option>
        </el-select>
        <div v-if="show_tips"
             class="d-inline-block">
            <span>
                Can't find the country you need? Please let us know.
            </span>
        </div>
    </div>
</template>

<script>
    import * as Countries from '../constants/countries'
    import auth from '../auth'
    import {mapState} from 'vuex'
    import {
        acl_mixin,
        phone_wizard_mixin
    } from '../mixins'

    export default {
        mixins: [
            acl_mixin,
            phone_wizard_mixin
        ],
        props: {
            show_prefix: {
                required: false,
                type: Boolean,
                default: false
            },
            show_tips: {
                required: false,
                type: Boolean,
                default: false
            },
            initial_country_code: {
                required: false,
                default: null
            },
            initial_countries: {
                required: false,
                type: Array,
                default: null
            },
            force_initial_country_code: {
                required: false,
                type: Boolean,
                default: false
            },
            supported_country_codes: {
                required: false,
                type: Array,
                default: () => []
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false
            },
            phone_number_type: {
                required: false,
                type: Number,
                default: 1
            }
        },

        data() {
            return {
                auth: auth,
                countries: Countries.COUNTRIES,
                country_code: null,
                country_phone_prefix: '',
                supported_countries: [],
                unsupported_countries: [],
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),
        },

        created() {
            if (this.supported_country_codes.length > 0) {
                let filter_countries = this.supported_country_codes
                if (this.current_company && !this.current_company.registered_address) {
                    filter_countries = ['US', 'CA', 'PR']
                }
                this.supported_countries = Countries.COUNTRIES.filter(country => filter_countries.includes(country.code))
                this.unsupported_countries = Countries.COUNTRIES.filter(country => !filter_countries.includes(country.code))
            }
            if (!_.isEmpty(this.initial_countries)) {
                this.countries = this.initial_countries
            }
            this.country_code = this.initial_country_code
            if (this.country_code === null && this.force_initial_country_code && this.auth.user && this.auth.user.profile && this.auth.user.profile.company) {
                this.country_code = this.auth.user.profile.company.country
            }
            this.getCountryPrefix(this.country_code)
        },

        methods: {
            getCountryPrefix(country_code) {
                if (this.show_prefix) {
                    let country = Countries.COUNTRIES.find(item => {
                        return item.code == country_code
                    })
                    this.country_phone_prefix = ' (' + country.prefix + ')'
                }
            },

            changeCountry(country_code) {
                this.getCountryPrefix(country_code)
                this.$emit('changed', this.country_code)
            },
        }
    }
</script>
