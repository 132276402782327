<template>
    <div class="property-card">
        <div class="d-flex justify-content-between align-items-center mb-8">
            <h6 class="text-dark">Properties (*)</h6>
            <div class="d-flex gap-2">
                <el-alert
                    v-if="propertyLength === 0"
                    title="You need add properties to the function. These properties will be used as variables in the function."
                    type="warning"
                    :closable="false"
                    show-icon>
                </el-alert>
                <el-button
                    type="primary"
                    round
                    plain
                    size="small"
                    icon="el-icon-plus"
                    title="Add property"
                    class="ml-2"
                    @click="dialogVisible = true">
                    Property
                </el-button>
            </div>
        </div>
        <el-table v-if="propertyLength > 0" :data="propertyList" style="width: 100%">
            <el-table-column
                prop="key"
                label="Property"
                width="180">
            </el-table-column>
            <el-table-column
                prop="type"
                label="Type"
                width="180">
            </el-table-column>
            <el-table-column
                prop="description"
                label="Description">
            </el-table-column>
            <el-table-column
                width="100">
                  <template v-slot="scope">
                      <div class="d-flex justify-content-end">
                          <el-button plain circle icon="el-icon-edit" size="mini" @click="editProperty(scope.$index, scope.row)"></el-button>
                          <el-button plain circle icon="el-icon-delete" size="mini" type="danger" @click="confirmDelete(scope.row.key)"></el-button>
                      </div>
                  </template>
            </el-table-column>
        </el-table>

        <el-dialog
            :visible.sync="dialogVisible"
            :before-close="clearValues"
            class="dialog-blank"
            width="50%"
            append-to-body>
            <div class="container-listing rounded">
                <div class="container-header d-flex">
                    <h4 v-if="isEdit" class="listing-heading">Edit property</h4>
                    <h4 v-else class="listing-heading">Add property</h4>
                </div>
                <div class="container-body pb-4">
                    <div class="d-flex">
                        <el-form-item class="w-full mr-3">
                            <div class="form-label">
                                <h5 class="text-dark">Property key</h5>
                            </div>
                            <el-input
                                v-model="newPropertyKey"
                                type="text"/>
                        </el-form-item>
                        <el-form-item>
                            <div class="form-label">
                                <h5 class="text-dark">Property type</h5>
                            </div>
                            <el-select
                                v-model="newPropertyType"
                                size="100%">
                                <el-option
                                    v-for="type in ['string', 'number', 'boolean']"
                                    :key="type"
                                    :label="type"
                                    :value="type"/>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <div class="form-label">
                            <h5 class="text-dark">Property description</h5>
                        </div>
                        <el-input
                            v-model="newPropertyDescription"
                            type="text"/>
                    </el-form-item>
                    <el-form-item class="d-flex justify-content-end">
                        <el-button @click="clearValues">Cancel</el-button>
                        <el-button v-if="isEdit" type="primary" class="mr-2" @click="updateProperty">Update</el-button>
                        <el-button v-else type="primary" class="mr-2" @click="onAddProperty">Add</el-button>
                    </el-form-item>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import { MessageBox } from 'element-ui';

    export default {
        name: 'aloai-function-property',
        props: {
            properties: {
                required: true,
                default: {}
            }
        },
        data() {
            return {
                newPropertyKey: '',
                newPropertyType: 'string',
                newPropertyDescription: '',
                dialogVisible: false,
                isEdit: false,
            }
        },
        computed: {
            propertyList() {
                return Object.entries(this.properties).map(([key, value], index) => {
                    return {
                        index,
                        key,
                        ...value
                    };
                });
            },
            propertyLength() {
                return Object.keys(this.properties).length
            }
        },
        methods: {
            onAddProperty() {
                this.$set(this.properties, this.newPropertyKey,{
                    type: this.newPropertyType,
                    description: this.newPropertyDescription,
                })

                this.clearValues()
            },
            confirmDelete(index) {
                MessageBox.confirm('Are you sure you want to delete this property?', 'Warning', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.deleteProperty(index);
                }).catch(() => {
                    // do nothing
                });
            },
            editProperty(index, row) {
                this.newPropertyKey = row.key
                this.newPropertyType = row.type
                this.newPropertyDescription = row.description
                this.dialogVisible = true
                this.isEdit = true
            },
            updateProperty() {
                this.$set(this.properties, this.newPropertyKey,{
                    type: this.newPropertyType,
                    description: this.newPropertyDescription,
                })

                this.clearValues()
            },
            deleteProperty(index) {
                this.$delete(this.properties, index)
            },
            clearValues() {
                this.newPropertyKey = ''
                this.newPropertyType = 'string'
                this.newPropertyDescription = ''
                this.dialogVisible = false
                this.isEdit = false
            }
        }
    }
</script>
<style scoped>
    .property-card{
        border: 1px solid #ebeef5;
        border-radius: 8px;
        padding: 10px;
        margin-bottom: 10px;
    }
</style>
