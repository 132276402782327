var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    [
      _c(
        "h6",
        { staticClass: "mb-2 text-black" },
        [
          _vm.hasTitleTextProp
            ? _vm._t("titleText")
            : _c("span", [
                _vm._v("\n            Assign contacts to\n        "),
              ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mb-3" },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.tab_name,
                callback: function ($$v) {
                  _vm.tab_name = $$v
                },
                expression: "tab_name",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "User", name: "user" } },
                [
                  _c("p", { staticClass: "lh-1p4 mb-2 text-dark" }, [
                    _vm._v("Assign the leads to this user"),
                  ]),
                  _vm._v(" "),
                  _c("user-selector", {
                    ref: "userSelector",
                    attrs: {
                      clearable: "",
                      hide_extensions: true,
                      placeholder: "Select User",
                    },
                    on: { change: _vm.changeUserId },
                    model: {
                      value: _vm.assign_to_id,
                      callback: function ($$v) {
                        _vm.assign_to_id = $$v
                      },
                      expression: "assign_to_id",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "Ring Group", name: "ring_group" } },
                [
                  _c("p", { staticClass: "lh-1p4 mb-2 text-dark" }, [
                    _vm._v(
                      "Assign the leads evenly and randomly between the users on this ring group"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("ring-group-selector", {
                    on: { change: _vm.ringGroupChange },
                    model: {
                      value: _vm.assign_to_id,
                      callback: function ($$v) {
                        _vm.assign_to_id = $$v
                      },
                      expression: "assign_to_id",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mt-2" }, [
        _c(
          "div",
          { staticClass: "form-label text-dark", attrs: { scope: "label" } },
          [
            _c("p", { staticClass: "lh-1p4" }, [
              _vm._v(
                "\n                By default, this tool only distributes unassigned contacts.\n            "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-label mb-1", attrs: { scope: "label" } },
          [
            _c("el-checkbox", {
              staticClass: "w-full",
              attrs: { label: "Also distribute assigned contacts" },
              on: { change: _vm.forceChanged },
              model: {
                value: _vm.force,
                callback: function ($$v) {
                  _vm.force = $$v
                },
                expression: "force",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }