<template>
    <div class="integrations banner">
        <div class="h3 flex-grow-1 m-0">
            So integrated, you won't know the difference
        </div>
        <div class="text">
            Aloware integrates into major CRM systems like
            <a class="link"
               target="_blank"
               :href="integrationUrl(integration.name)"
               :key="integration.name"
               v-for="(integration, index) in integrations">
                {{ integration.label }}<span v-if="index < integrations.length - 1">, </span>
            </a>
            and much more! You can check out all of them
            <a class="link"
               href="https://aloware.com/integrations/"
               target="_blank">
                here.
            </a>
        </div>
    </div>
</template>

<script>
import {acl_mixin, kyc_mixin} from '../../mixins'
import integrations from '../../constants/integrations-items'

export default {
    name: 'integrations-banner',

    mixins: [
        acl_mixin,
        kyc_mixin
    ],

    data() {
        return {
            integrations
        }
    },

    methods: {
        integrationUrl(name) {
            return `https://aloware.com/integrations/${name}`
        }
    }
}
</script>
