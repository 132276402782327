<template>
    <div class="p-2 px-3 border border-warning mb-3">
        <el-tooltip
            placement="top"
            effect="dark"
            content="Any changes made at the account level will cascade down to all lines, overriding individual line settings unless ‘Follow Line’s Setting’ is chosen.">
            <div>
                <div class="form-label">
                    <h5>
                        Feature Overview
                        <el-popover
                            placement="right"
                            width="450"
                            trigger="hover">
                            <div>

                                <span class="d-flex mb-2">Retention Duration Options:</span>
                                <ul class="mb-0">
                                    <li>Indefinite: Keep recordings without an expiration date. This is the default
                                        setting.
                                    </li>

                                    <li>Follow Line’s Setting: If set at the company level, recordings will adhere to
                                        each line’s specific retention settings.
                                    </li>

                                    <li>Timed Deletion Options: Choose from 10, 30, or 60 days for automatic recording
                                        deletion, optimizing storage and compliance.
                                    </li>
                                </ul>

                                <span class="d-flex mb-2">How to Modify Settings:</span>
                                <ul class="mb-0">
                                    <li>Navigate to the ‘Account Settings’ in your admin panel.</li>

                                    <li>Look for the ‘Call Recording Retention’ section.</li>

                                    <li>Click ‘Modify’ to set the preferred retention duration.</li>

                                    <li>Select from the predefined options, or set a custom duration to meet your
                                        company’s policy.
                                    </li>
                                </ul>
                            </div>
                            <i slot="reference"
                               class="fa fa-info-circle text-primary ml-2">
                            </i>
                        </el-popover>
                    </h5>
                    <small>
                        <span class="d-flex">Set by Admin in Account Settings: Billing can now define how long call recordings are kept.</span>

                        <span class="d-flex">Company Preference Priority: The company-wide setting takes precedence, ensuring all lines follow a unified retention policy.</span>
                    </small>
                </div>
            </div>
        </el-tooltip>
    </div>
</template>
<script>
export default {}
</script>
