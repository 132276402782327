<template>
    <el-form>
        <h6 class="mb-2 text-black">
            <slot
                v-if="hasTitleTextProp"
                name="titleText">
            </slot>
            <span v-else>
                Assign contacts to
            </span>
        </h6>
        <el-form-item class="mb-3">
            <el-tabs v-model="tab_name">
                <el-tab-pane
                    label="User"
                    name="user">
                    <p class="lh-1p4 mb-2 text-dark">Assign the leads to this user</p>
                    <user-selector
                        ref="userSelector"
                        clearable
                        v-model="assign_to_id"
                        :hide_extensions="true"
                        placeholder="Select User"
                        @change="changeUserId">
                    </user-selector>
                </el-tab-pane>
                <el-tab-pane
                    label="Ring Group"
                    name="ring_group">
                    <p class="lh-1p4 mb-2 text-dark">Assign the leads evenly and randomly between the users on this ring group</p>
                    <ring-group-selector
                        v-model="assign_to_id"
                        @change="ringGroupChange">
                    </ring-group-selector>
                </el-tab-pane>
            </el-tabs>
        </el-form-item>

        <el-form-item class="mt-2">
            <div
                scope="label"
                class="form-label text-dark">
                <p class="lh-1p4">
                    By default, this tool only distributes unassigned contacts.
                </p>
            </div>
            <div
                scope="label"
                class="form-label mb-1">
                <el-checkbox
                    @change="forceChanged"
                    v-model="force"
                    label="Also distribute assigned contacts"
                    class="w-full">
                </el-checkbox>
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
    import auth from "../auth";
    import RingGroupSelector from "../components/ring-group-selector"

    export default {
        name: "assign-contact",

        props: {
            value: {},
            assign_to: {},
            is_force: {}
        },

        components: {
            RingGroupSelector
        },

        data() {
            return {
                auth: auth,
                tab_name: 'user',
                assign_to_id: null,
                force: false
            }
        },

        computed: {
            hasTitleTextProp() {
                return !!this.$slots.titleText
            }
        },

        methods: {
            changeUserId(id) {
                this.assign_to_id = id || null
            },

            ringGroupChange(id) {
                this.assign_to_id = id || null
            },

            forceChanged(value) {
                this.$emit('update:is_force', value)
            },

            resetFields() {
                this.tab_name = 'user'
                this.assign_to_id = null
                this.force = false
            }
        },

        watch: {
            tab_name() {
                this.assign_to_id = null
            },

            assign_to_id() {
                this.$emit('update:value', this.assign_to_id)
                this.$emit('update:assign_to', this.tab_name)
            }
        }
    }
</script>

<style scoped>

</style>
