var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      ![
        "contact",
        "disposition",
        "call disposition",
        "appointment",
        "dnc status",
        "tag",
        "remove_tag",
        "contact_list",
        "remove_contact_list",
      ].includes(_vm.trigger.type)
        ? _c(
            "el-form",
            {
              ref: "trigger_form",
              attrs: {
                "label-position": "top",
                model: _vm.trigger,
                rules: _vm.form_trigger,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("span", { staticClass: "inline" }, [_vm._v("When an ")]),
              _vm._v(" "),
              _vm.automation_trigger || _vm.power_dialer_trigger
                ? _c("span", { staticClass: "inline" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("toLowerCase")(
                            _vm._f("fixCommDirection")(_vm.trigger.direction)
                          )
                        ) +
                        "\n        "
                    ),
                  ])
                : _c(
                    "el-form-item",
                    {
                      staticClass: "inline mb-3 no-border",
                      attrs: { prop: "direction" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "Select Direction",
                            disabled:
                              _vm.automation_trigger ||
                              _vm.power_dialer_trigger,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.customPreValidateForm("trigger_form")
                            },
                          },
                          model: {
                            value: _vm.trigger.direction,
                            callback: function ($$v) {
                              _vm.$set(_vm.trigger, "direction", $$v)
                            },
                            expression: "trigger.direction",
                          },
                        },
                        _vm._l(_vm.directions, function (direction) {
                          return _c("el-option", {
                            key: direction,
                            attrs: {
                              label: _vm._f("fixCommDirection")(direction),
                              value: direction,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.trigger.type !== _vm.CommunicationTypes.EMAIL
                ? _c("span", { staticClass: "inline" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("toLowerCase")(
                            _vm._f("fixCommType")(_vm.trigger.type)
                          )
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.trigger.type === _vm.CommunicationTypes.EMAIL &&
              _vm.trigger.direction
                ? _c("span", { staticClass: "inline" }, [
                    _vm._v(
                      " email comes " +
                        _vm._s(_vm.trigger.direction == 1 ? "in" : "out") +
                        ", then\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.trigger.direction
                ? [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.trigger.type !== _vm.CommunicationTypes.EMAIL,
                            expression:
                              "trigger.type !== CommunicationTypes.EMAIL",
                          },
                        ],
                        staticClass: "inline",
                      },
                      [_vm._v(" is ")]
                    ),
                    _vm._v(" "),
                    _vm.automation_trigger || _vm.power_dialer_trigger
                      ? _c("span", { staticClass: "inline" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("replaceDash")(
                                  _vm._f("translateDispositionStatusText")(
                                    _vm.trigger.disposition_status
                                  )
                                )
                              ) +
                              "\n            "
                          ),
                        ])
                      : _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.trigger.type !==
                                  _vm.CommunicationTypes.EMAIL,
                                expression:
                                  "trigger.type !== CommunicationTypes.EMAIL",
                              },
                            ],
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "disposition_status" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "220px" },
                                attrs: {
                                  placeholder: "Select Disposition Status",
                                  disabled:
                                    _vm.automation_trigger ||
                                    _vm.power_dialer_trigger,
                                },
                                on: { change: _vm.checkTriggerForm },
                                model: {
                                  value: _vm.trigger.disposition_status,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.trigger,
                                      "disposition_status",
                                      $$v
                                    )
                                  },
                                  expression: "trigger.disposition_status",
                                },
                              },
                              _vm._l(
                                _vm.availableDispositionStatus,
                                function (status) {
                                  return _c("el-option", {
                                    key: status,
                                    attrs: {
                                      label: _vm._f("capitalize")(
                                        _vm._f("replaceDash")(
                                          _vm._f(
                                            "translateDispositionStatusText"
                                          )(status)
                                        )
                                      ),
                                      value: status,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.trigger.direction &&
              _vm.trigger.disposition_status &&
              _vm.trigger.disposition_status !==
                _vm.CommunicationDispositionStatus
                  .DISPOSITION_STATUS_FAILED_NEW &&
              _vm.availableVariables.length > 0
                ? [
                    _vm.automation_trigger || _vm.power_dialer_trigger
                      ? _c("span", { staticClass: "inline" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("AND"),
                          ]),
                          _vm._v(" its\n            "),
                        ])
                      : _c(
                          "el-checkbox",
                          {
                            staticClass: "_600 mr-2 ml-2",
                            attrs: {
                              disabled:
                                _vm.automation_trigger ||
                                _vm.power_dialer_trigger,
                            },
                            on: { change: _vm.clearConditions },
                            model: {
                              value: _vm.trigger.has_condition,
                              callback: function ($$v) {
                                _vm.$set(_vm.trigger, "has_condition", $$v)
                              },
                              expression: "trigger.has_condition",
                            },
                          },
                          [_vm._v("\n                and if\n            ")]
                        ),
                    _vm._v(" "),
                    _vm.trigger.has_condition
                      ? [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 no-border",
                              attrs: { prop: "variable" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "170px" },
                                  attrs: {
                                    placeholder: "Select Variable",
                                    disabled: _vm.power_dialer_trigger,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.customPreValidateForm(
                                        "trigger_form"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.trigger.variable,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.trigger, "variable", $$v)
                                    },
                                    expression: "trigger.variable",
                                  },
                                },
                                _vm._l(
                                  _vm.availableVariables,
                                  function (variable) {
                                    return _c("el-option", {
                                      key: variable.id,
                                      attrs: {
                                        label: variable.name,
                                        value: variable.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.trigger.type === _vm.CommunicationTypes.CALL
                            ? [
                                _vm.trigger.variable &&
                                _vm.trigger.variable !== "last_transferred_leg"
                                  ? [
                                      _c("span", { staticClass: "inline" }, [
                                        _vm._v(" is "),
                                      ]),
                                      _vm._v(" "),
                                      _vm.automation_trigger
                                        ? _c(
                                            "span",
                                            { staticClass: "inline" },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(_vm.symbolWords) +
                                                  "\n                        "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "inline mb-3 no-border",
                                              attrs: { prop: "symbol" },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "130px",
                                                  },
                                                  attrs: {
                                                    placeholder: "Math Symbol",
                                                    disabled:
                                                      _vm.automation_trigger,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.customPreValidateForm(
                                                        "trigger_form"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.trigger.symbol,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.trigger,
                                                        "symbol",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "trigger.symbol",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.symbols,
                                                  function (symbol) {
                                                    return _c("el-option", {
                                                      key: symbol,
                                                      attrs: {
                                                        label: symbol,
                                                        value: symbol,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.trigger.symbol
                                  ? [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "inline mb-3 no-border",
                                          attrs: { prop: "value" },
                                        },
                                        [
                                          _c("el-input-number", {
                                            attrs: { min: 0 },
                                            on: {
                                              input: function ($event) {
                                                return _vm.customPreValidateForm(
                                                  "trigger_form"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.trigger.value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.trigger,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "trigger.value",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "inline" }, [
                                        _vm._v(" seconds"),
                                        _vm.automation_trigger
                                          ? _c("span", [_vm._v(",")])
                                          : _c("span", [_vm._v(".")]),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.trigger.type === _vm.CommunicationTypes.SMS
                            ? [
                                _vm.trigger.variable !== "first_time_message"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        staticClass: "inline mb-3 no-border",
                                        attrs: { prop: "keywords" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "300px" },
                                            attrs: {
                                              placeholder:
                                                "Choose or create keywords",
                                              multiple: "",
                                              filterable: "",
                                              "allow-create": "",
                                              "default-first-option": "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.customPreValidateForm(
                                                  "trigger_form"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.trigger.keywords,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.trigger,
                                                  "keywords",
                                                  $$v
                                                )
                                              },
                                              expression: "trigger.keywords",
                                            },
                                          },
                                          _vm._l(
                                            _vm.keywords,
                                            function (keyword) {
                                              return _c("el-option", {
                                                key: keyword.value,
                                                attrs: {
                                                  label: keyword.label,
                                                  value: keyword.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.trigger.disposition_status &&
              _vm.trigger.type === _vm.CommunicationTypes.EMAIL
                ? [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inline mb-3 no-border",
                        attrs: { prop: "operation" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "Select Operation",
                              "default-first-option": "",
                              filterable: "",
                              autocomplete: "off",
                              clearable: "",
                            },
                            on: { change: _vm.clearData },
                            model: {
                              value: _vm.trigger.operation,
                              callback: function ($$v) {
                                _vm.$set(_vm.trigger, "operation", $$v)
                              },
                              expression: "trigger.operation",
                            },
                          },
                          _vm._l(_vm.operations, function (operation) {
                            return _c("el-option", {
                              key: operation.id,
                              attrs: {
                                label: operation.name,
                                value: operation.value,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.trigger.operation &&
              _vm.trigger.type === _vm.CommunicationTypes.EMAIL
                ? [
                    _c("br"),
                    _vm._v(" "),
                    _vm.hasPermissionTo(["list tag", "view tag"]) &&
                    _vm.trigger.operation === "tag"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "tag_ids" },
                          },
                          [
                            _c("tag-selector", {
                              staticStyle: { width: "300px" },
                              attrs: {
                                multiple: true,
                                category_prop:
                                  _vm.TagCategory.CAT_COMMUNICATIONS,
                              },
                              on: { change: _vm.changeTags },
                              model: {
                                value: _vm.trigger.tag_ids,
                                callback: function ($$v) {
                                  _vm.$set(_vm.trigger, "tag_ids", $$v)
                                },
                                expression: "trigger.tag_ids",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo(["list tag", "view tag"]) &&
                    ["tag_contact", "untag_contact"].includes(
                      _vm.trigger.operation
                    )
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "tag_ids" },
                          },
                          [
                            _c("tag-selector", {
                              staticStyle: { width: "300px" },
                              attrs: {
                                multiple: true,
                                category_prop: _vm.TagCategory.CAT_CONTACTS,
                              },
                              on: { change: _vm.changeTags },
                              model: {
                                value: _vm.trigger.tag_ids,
                                callback: function ($$v) {
                                  _vm.$set(_vm.trigger, "tag_ids", $$v)
                                },
                                expression: "trigger.tag_ids",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo(["list workflow", "view workflow"]) &&
                    _vm.trigger.operation === "enroll"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "workflow_ids" },
                          },
                          [
                            _c("workflow-selector", {
                              staticStyle: { width: "300px" },
                              attrs: { multiple: false },
                              on: { change: _vm.changeWorkflows },
                              model: {
                                value: _vm.workflow_id,
                                callback: function ($$v) {
                                  _vm.workflow_id = $$v
                                },
                                expression: "workflow_id",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPermissionTo([
                      "list disposition status",
                      "view disposition status",
                    ]) && _vm.trigger.operation === "dispose"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "disposition_status_id" },
                          },
                          [
                            _c("disposition-status-selector", {
                              staticStyle: { width: "300px" },
                              attrs: { multiple: false },
                              on: { change: _vm.changeDispositionStatus },
                              model: {
                                value: _vm.trigger.disposition_status_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.trigger,
                                    "disposition_status_id",
                                    $$v
                                  )
                                },
                                expression: "trigger.disposition_status_id",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.trigger.operation === "create_hubspot_ticket"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "stage_id" },
                          },
                          [
                            _c("hubspot-ticketing-pipeline-selector", {
                              attrs: {
                                "ticket-name": _vm.trigger.ticket_name,
                                "pipeline-id": _vm.trigger.pipeline_id,
                                "stage-id": _vm.trigger.stage_id,
                                "ticket-description":
                                  _vm.trigger.ticket_description,
                              },
                              on: {
                                selectedPipeline: _vm.changeSelectedPipeline,
                                selectedStage: _vm.changeSelectedStage,
                                changedTicketName: _vm.changeTicketName,
                                changedTicketDescription:
                                  _vm.changeTicketDescription,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.trigger.disposition_status &&
              _vm.trigger.type !== _vm.CommunicationTypes.EMAIL
                ? [
                    !_vm.automation_trigger
                      ? _c("span", { staticClass: "inline" }, [
                          _vm._v("\n      Then\n    "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.automation_trigger
                      ? _c("span", { staticClass: "inline" }, [
                          _vm._v(
                            "\n      " +
                              _vm._s(
                                _vm._f("toLowerCase")(_vm.triggerOperationName)
                              ) +
                              "\n    "
                          ),
                        ])
                      : _c(
                          "el-form-item",
                          {
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "operation" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "220px" },
                                attrs: {
                                  placeholder: "Select Operation",
                                  autocomplete: "off",
                                  disabled: _vm.automation_trigger,
                                  "default-first-option": "",
                                  filterable: "",
                                  clearable: "",
                                },
                                on: { change: _vm.clearData },
                                model: {
                                  value: _vm.trigger.operation,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.trigger, "operation", $$v)
                                  },
                                  expression: "trigger.operation",
                                },
                              },
                              _vm._l(_vm.operations, function (operation) {
                                return _c("el-option", {
                                  key: operation.id,
                                  attrs: {
                                    label: operation.name,
                                    value: operation.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.trigger.type !== _vm.CommunicationTypes.EMAIL
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm.trigger.operation &&
                        [
                          "tag",
                          "tag_contact",
                          "untag_contact",
                          "enroll",
                          "dispose",
                          "create_hubspot_ticket",
                          "contact_list",
                          "remove_contact_list",
                          "add_to_list",
                          "remove_from_list",
                        ].includes(_vm.trigger.operation)
                          ? [
                              _vm.hasPermissionTo(["list tag", "view tag"]) &&
                              _vm.trigger.operation === "tag"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "inline mb-3 no-border",
                                      attrs: { prop: "tag_ids" },
                                    },
                                    [
                                      _c("tag-selector", {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          multiple: true,
                                          category_prop:
                                            _vm.TagCategory.CAT_COMMUNICATIONS,
                                        },
                                        on: { change: _vm.changeTags },
                                        model: {
                                          value: _vm.trigger.tag_ids,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.trigger,
                                              "tag_ids",
                                              $$v
                                            )
                                          },
                                          expression: "trigger.tag_ids",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasPermissionTo(["list tag", "view tag"]) &&
                              ["tag_contact", "untag_contact"].includes(
                                _vm.trigger.operation
                              )
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "inline mb-3 no-border",
                                      attrs: { prop: "tag_ids" },
                                    },
                                    [
                                      _c("tag-selector", {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          multiple: true,
                                          category_prop:
                                            _vm.TagCategory.CAT_CONTACTS,
                                        },
                                        on: { change: _vm.changeTags },
                                        model: {
                                          value: _vm.trigger.tag_ids,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.trigger,
                                              "tag_ids",
                                              $$v
                                            )
                                          },
                                          expression: "trigger.tag_ids",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasPermissionTo(["view contact list"]) &&
                              ["add_to_list", "remove_from_list"].includes(
                                _vm.trigger.operation
                              )
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "inline mb-3 no-border",
                                      attrs: { prop: "list_id" },
                                    },
                                    [
                                      _c("contact-lists-selector", {
                                        attrs: {
                                          multiple: false,
                                          value: _vm.selected_list,
                                          list_type:
                                            _vm.ContactListTypes.STATIC_LIST,
                                        },
                                        on: { change: _vm.changeList },
                                        model: {
                                          value: _vm.trigger.list_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.trigger,
                                              "list_id",
                                              $$v
                                            )
                                          },
                                          expression: "trigger.list_id",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasPermissionTo([
                                "list workflow",
                                "view workflow",
                              ]) && _vm.trigger.operation === "enroll"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "inline mb-3 no-border",
                                      attrs: { prop: "workflow_ids" },
                                    },
                                    [
                                      _c("workflow-selector", {
                                        staticStyle: { width: "300px" },
                                        attrs: { multiple: false },
                                        on: { change: _vm.changeWorkflows },
                                        model: {
                                          value: _vm.workflow_id,
                                          callback: function ($$v) {
                                            _vm.workflow_id = $$v
                                          },
                                          expression: "workflow_id",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasPermissionTo([
                                "list disposition status",
                                "view disposition status",
                              ]) && _vm.trigger.operation === "dispose"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "inline mb-3 no-border",
                                      attrs: { prop: "disposition_status_id" },
                                    },
                                    [
                                      _c("disposition-status-selector", {
                                        staticStyle: { width: "300px" },
                                        attrs: { multiple: false },
                                        on: {
                                          change: _vm.changeDispositionStatus,
                                        },
                                        model: {
                                          value:
                                            _vm.trigger.disposition_status_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.trigger,
                                              "disposition_status_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "trigger.disposition_status_id",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.trigger.operation === "create_hubspot_ticket"
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "inline mb-3 no-border",
                                      attrs: { prop: "stage_id" },
                                    },
                                    [
                                      _c(
                                        "hubspot-ticketing-pipeline-selector",
                                        {
                                          attrs: {
                                            "ticket-name":
                                              _vm.trigger.ticket_name,
                                            "pipeline-id":
                                              _vm.trigger.pipeline_id,
                                            "stage-id": _vm.trigger.stage_id,
                                            "ticket-description":
                                              _vm.trigger.ticket_description,
                                          },
                                          on: {
                                            selectedPipeline:
                                              _vm.changeSelectedPipeline,
                                            selectedStage:
                                              _vm.changeSelectedStage,
                                            changedTicketName:
                                              _vm.changeTicketName,
                                            changedTicketDescription:
                                              _vm.changeTicketDescription,
                                          },
                                        }
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row no-select" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pull-right _600 opacity-5",
                      class: [
                        _vm.trigger.valid
                          ? "text-dark-greenish"
                          : "text-danger",
                      ],
                    },
                    [
                      _vm.trigger.valid
                        ? _c("i", { staticClass: "fa fa-check-circle" })
                        : _c("i", { staticClass: "fa fa-times-circle" }),
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.trigger.valid
                              ? "Valid trigger"
                              : "Invalid trigger"
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          )
        : _vm.trigger.type == "contact" &&
          _vm.automation_trigger &&
          !_vm.power_dialer_trigger
        ? _c(
            "el-form",
            {
              ref: "trigger_form",
              attrs: { model: _vm.trigger, rules: _vm.form_trigger },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("span", { staticClass: "el-form-item__label inline" }, [
                    _vm._v(
                      "When a contact is created, then enroll to this sequence"
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row no-select" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pull-right _600 opacity-5",
                      class: [
                        _vm.trigger.valid
                          ? "text-dark-greenish"
                          : "text-danger",
                      ],
                    },
                    [
                      _vm.trigger.valid
                        ? _c("i", { staticClass: "fa fa-check-circle" })
                        : _c("i", { staticClass: "fa fa-times-circle" }),
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.trigger.valid
                              ? "Valid trigger"
                              : "Invalid trigger"
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm.trigger.type == "contact"
        ? _c(
            "el-form",
            {
              ref: "trigger_form",
              attrs: { model: _vm.trigger, rules: _vm.form_trigger },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("span", { staticClass: "el-form-item__label inline" }, [
                      _vm._v("When a contact is created, then"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inline mb-3 no-border",
                        attrs: { prop: "operation" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "Select Operation",
                              "default-first-option": "",
                              autocomplete: "off",
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.clearData },
                            model: {
                              value: _vm.trigger.operation,
                              callback: function ($$v) {
                                _vm.$set(_vm.trigger, "operation", $$v)
                              },
                              expression: "trigger.operation",
                            },
                          },
                          _vm._l(
                            _vm.contactTriggerOperations,
                            function (operation) {
                              return _c("el-option", {
                                key: operation.id,
                                attrs: {
                                  label: operation.name,
                                  value: operation.value,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.trigger.operation
                      ? [
                          _c("br"),
                          _vm._v(" "),
                          _vm.hasPermissionTo(["list tag", "view tag"]) &&
                          _vm.trigger.operation === "tag"
                            ? _c(
                                "el-form-item",
                                {
                                  staticClass: "inline mb-3 no-border",
                                  attrs: { prop: "tag_ids" },
                                },
                                [
                                  _c("tag-selector", {
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      multiple: true,
                                      category_prop:
                                        _vm.TagCategory.CAT_COMMUNICATIONS,
                                    },
                                    on: { change: _vm.changeTags },
                                    model: {
                                      value: _vm.trigger.tag_ids,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.trigger, "tag_ids", $$v)
                                      },
                                      expression: "trigger.tag_ids",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermissionTo(["list tag", "view tag"]) &&
                          ["tag_contact", "untag_contact"].includes(
                            _vm.trigger.operation
                          )
                            ? _c(
                                "el-form-item",
                                {
                                  staticClass: "inline mb-3 no-border",
                                  attrs: { prop: "tag_ids" },
                                },
                                [
                                  _c("tag-selector", {
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      multiple: true,
                                      category_prop:
                                        _vm.TagCategory.CAT_CONTACTS,
                                    },
                                    on: { change: _vm.changeTags },
                                    model: {
                                      value: _vm.trigger.tag_ids,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.trigger, "tag_ids", $$v)
                                      },
                                      expression: "trigger.tag_ids",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermissionTo(["view contact list"]) &&
                          ["add_to_list", "remove_from_list"].includes(
                            _vm.trigger.operation
                          )
                            ? _c(
                                "el-form-item",
                                {
                                  staticClass: "inline mb-3 no-border",
                                  attrs: { prop: "list_id" },
                                },
                                [
                                  _c("contact-lists-selector", {
                                    attrs: {
                                      multiple: false,
                                      value: _vm.selected_list,
                                      list_type:
                                        _vm.ContactListTypes.STATIC_LIST,
                                    },
                                    on: { change: _vm.changeList },
                                    model: {
                                      value: _vm.trigger.list_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.trigger, "list_id", $$v)
                                      },
                                      expression: "trigger.list_id",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermissionTo([
                            "list workflow",
                            "view workflow",
                          ]) && _vm.trigger.operation === "enroll"
                            ? _c(
                                "el-form-item",
                                {
                                  staticClass: "inline mb-3 no-border",
                                  attrs: { prop: "workflow_ids" },
                                },
                                [
                                  _c("workflow-selector", {
                                    staticStyle: { width: "300px" },
                                    attrs: { multiple: false },
                                    on: { change: _vm.changeWorkflows },
                                    model: {
                                      value: _vm.workflow_id,
                                      callback: function ($$v) {
                                        _vm.workflow_id = $$v
                                      },
                                      expression: "workflow_id",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermissionTo([
                            "list disposition status",
                            "view disposition status",
                          ]) && _vm.trigger.operation === "dispose"
                            ? _c(
                                "el-form-item",
                                {
                                  staticClass: "inline mb-3 no-border",
                                  attrs: { prop: "disposition_status_id" },
                                },
                                [
                                  _c("disposition-status-selector", {
                                    staticStyle: { width: "300px" },
                                    attrs: { multiple: false },
                                    on: { change: _vm.changeDispositionStatus },
                                    model: {
                                      value: _vm.trigger.disposition_status_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.trigger,
                                          "disposition_status_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "trigger.disposition_status_id",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row no-select" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pull-right _600 opacity-5",
                      class: [
                        _vm.trigger.valid
                          ? "text-dark-greenish"
                          : "text-danger",
                      ],
                    },
                    [
                      _vm.trigger.valid
                        ? _c("i", { staticClass: "fa fa-check-circle" })
                        : _c("i", { staticClass: "fa fa-times-circle" }),
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.trigger.valid
                              ? "Valid trigger"
                              : "Invalid trigger"
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm.trigger.type == "disposition"
        ? _c(
            "el-form",
            {
              ref: "trigger_form",
              attrs: { model: _vm.trigger, rules: _vm.form_trigger },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("span", { staticClass: "inline" }, [
                      _vm._v("When a contact is disposed to "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inline mb-3 no-border",
                        attrs: { prop: "disposition_status_id" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "Select Disposition",
                              "default-first-option": "",
                              autocomplete: "off",
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.clearData },
                            model: {
                              value: _vm.trigger.disposition_status_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.trigger,
                                  "disposition_status_id",
                                  $$v
                                )
                              },
                              expression: "trigger.disposition_status_id",
                            },
                          },
                          _vm._l(
                            _vm.availableContactDispositionStatus,
                            function (disposition) {
                              return _c(
                                "el-option",
                                {
                                  key: disposition.id,
                                  attrs: {
                                    label: disposition.name,
                                    value: disposition.id,
                                  },
                                },
                                [
                                  disposition.is_external !== undefined
                                    ? [
                                        disposition.is_external
                                          ? _c(
                                              "i",
                                              {
                                                staticClass: "material-icons",
                                                style: {
                                                  color: disposition.color,
                                                },
                                              },
                                              [_vm._v("lock")]
                                            )
                                          : _c(
                                              "i",
                                              {
                                                staticClass: "material-icons",
                                                style: {
                                                  color: disposition.color,
                                                },
                                              },
                                              [_vm._v("label")]
                                            ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(disposition.name)),
                                  ]),
                                ],
                                2
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "inline" }, [_vm._v(", then ")]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inline mb-3 no-border",
                        attrs: { prop: "enroll_action" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "Select Action",
                              autocomplete: "off",
                              "default-first-option": "",
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.clearData },
                            model: {
                              value: _vm.trigger.enroll_action,
                              callback: function ($$v) {
                                _vm.$set(_vm.trigger, "enroll_action", $$v)
                              },
                              expression: "trigger.enroll_action",
                            },
                          },
                          _vm._l(_vm.availableActions, function (action) {
                            return _c(
                              "el-option",
                              {
                                key: action.id,
                                attrs: {
                                  label: action.name,
                                  value: action.value,
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(action.name))])]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.hasPermissionTo(["list workflow", "view workflow"]) &&
                    _vm.trigger.enroll_action &&
                    _vm.trigger.enroll_action === "enroll" &&
                    _vm.power_dialer_trigger
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "workflow_ids" },
                          },
                          [
                            _c("workflow-selector", {
                              staticStyle: { width: "300px" },
                              attrs: { multiple: false },
                              on: { change: _vm.changeWorkflows },
                              model: {
                                value: _vm.workflow_id,
                                callback: function ($$v) {
                                  _vm.workflow_id = $$v
                                },
                                expression: "workflow_id",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row no-select" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pull-right _600 opacity-5",
                      class: [
                        _vm.trigger.valid
                          ? "text-dark-greenish"
                          : "text-danger",
                      ],
                    },
                    [
                      _vm.trigger.valid
                        ? _c("i", { staticClass: "fa fa-check-circle" })
                        : _c("i", { staticClass: "fa fa-times-circle" }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.trigger.valid
                              ? "Valid trigger"
                              : "Invalid trigger"
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm.trigger.type == "tag"
        ? _c(
            "el-form",
            {
              ref: "trigger_form",
              attrs: { model: _vm.trigger, rules: _vm.form_trigger },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("al-alert", { staticClass: "mb-2 mx-auto" }, [
                    _c("span", {
                      staticClass: "text-dark",
                      domProps: {
                        innerHTML: _vm._s(_vm.TAGS_DEPRECATION_MESSAGE),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("span", { staticClass: "inline" }, [
                        _vm._v("When added tag "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "inline mb-3 no-border",
                          attrs: { prop: "tag_option_id" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "220px" },
                              attrs: {
                                placeholder: "Select Option",
                                "default-first-option": "",
                                autocomplete: "off",
                                filterable: "",
                                clearable: "",
                              },
                              on: { change: _vm.clearData },
                              model: {
                                value: _vm.trigger.tag_option_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.trigger, "tag_option_id", $$v)
                                },
                                expression: "trigger.tag_option_id",
                              },
                            },
                            _vm._l(
                              _vm.availableTagOptions,
                              function (tag_option) {
                                return _c(
                                  "el-option",
                                  {
                                    key: tag_option.id,
                                    attrs: {
                                      label: tag_option.name,
                                      value: tag_option.id,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(tag_option.name)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "inline" }, [_vm._v(" of ")]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "inline mb-3 no-border",
                          attrs: { prop: "tag_ids" },
                        },
                        [
                          _c("tag-selector", {
                            staticStyle: { width: "300px" },
                            attrs: {
                              multiple: true,
                              category_prop: _vm.TagCategory.CAT_CONTACTS,
                            },
                            on: { change: _vm.changeTags },
                            model: {
                              value: _vm.trigger.tag_ids,
                              callback: function ($$v) {
                                _vm.$set(_vm.trigger, "tag_ids", $$v)
                              },
                              expression: "trigger.tag_ids",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "inline" }, [
                        _vm._v(", then "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "inline mb-3 no-border",
                          attrs: { prop: "enroll_action" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "220px" },
                              attrs: {
                                placeholder: "Select Action",
                                autocomplete: "off",
                                "default-first-option": "",
                                filterable: "",
                                clearable: "",
                              },
                              on: { change: _vm.clearData },
                              model: {
                                value: _vm.trigger.enroll_action,
                                callback: function ($$v) {
                                  _vm.$set(_vm.trigger, "enroll_action", $$v)
                                },
                                expression: "trigger.enroll_action",
                              },
                            },
                            _vm._l(_vm.availableActions, function (action) {
                              return _c(
                                "el-option",
                                {
                                  key: action.id,
                                  attrs: {
                                    label: action.name,
                                    value: action.value,
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(action.name))])]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.hasPermissionTo(["list workflow", "view workflow"]) &&
                      _vm.trigger.enroll_action &&
                      _vm.trigger.enroll_action === "enroll" &&
                      _vm.power_dialer_trigger
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 no-border",
                              attrs: { prop: "workflow_ids" },
                            },
                            [
                              _c("workflow-selector", {
                                staticStyle: { width: "300px" },
                                attrs: { multiple: false },
                                on: { change: _vm.changeWorkflows },
                                model: {
                                  value: _vm.workflow_id,
                                  callback: function ($$v) {
                                    _vm.workflow_id = $$v
                                  },
                                  expression: "workflow_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row no-select" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pull-right _600 opacity-5",
                      class: [
                        _vm.trigger.valid
                          ? "text-dark-greenish"
                          : "text-danger",
                      ],
                    },
                    [
                      _vm.trigger.valid
                        ? _c("i", { staticClass: "fa fa-check-circle" })
                        : _c("i", { staticClass: "fa fa-times-circle" }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.trigger.valid
                              ? "Valid trigger"
                              : "Invalid trigger"
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm.trigger.type == "remove_tag"
        ? _c(
            "el-form",
            {
              ref: "trigger_form",
              attrs: { model: _vm.trigger, rules: _vm.form_trigger },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("al-alert", { staticClass: "mb-2 mx-auto" }, [
                    _c("span", {
                      staticClass: "text-dark",
                      domProps: {
                        innerHTML: _vm._s(_vm.TAGS_DEPRECATION_MESSAGE),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("span", { staticClass: "inline" }, [
                        _vm._v("When removed tag "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "inline mb-3 no-border",
                          attrs: { prop: "tag_option_id" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "220px" },
                              attrs: {
                                placeholder: "Select Option",
                                "default-first-option": "",
                                autocomplete: "off",
                                filterable: "",
                                clearable: "",
                              },
                              on: { change: _vm.clearData },
                              model: {
                                value: _vm.trigger.tag_option_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.trigger, "tag_option_id", $$v)
                                },
                                expression: "trigger.tag_option_id",
                              },
                            },
                            _vm._l(
                              _vm.availableTagOptions,
                              function (tag_option) {
                                return _c(
                                  "el-option",
                                  {
                                    key: tag_option.id,
                                    attrs: {
                                      label: tag_option.name,
                                      value: tag_option.id,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(tag_option.name)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "inline" }, [_vm._v(" of ")]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "inline mb-3 no-border",
                          attrs: { prop: "tag_ids" },
                        },
                        [
                          _c("tag-selector", {
                            staticStyle: { width: "300px" },
                            attrs: {
                              multiple: true,
                              category_prop: _vm.TagCategory.CAT_CONTACTS,
                            },
                            on: { change: _vm.changeTags },
                            model: {
                              value: _vm.trigger.tag_ids,
                              callback: function ($$v) {
                                _vm.$set(_vm.trigger, "tag_ids", $$v)
                              },
                              expression: "trigger.tag_ids",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "inline" }, [
                        _vm._v(", then "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "inline mb-3 no-border",
                          attrs: { prop: "enroll_action" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "220px" },
                              attrs: {
                                placeholder: "Select Action",
                                autocomplete: "off",
                                "default-first-option": "",
                                filterable: "",
                                clearable: "",
                              },
                              on: { change: _vm.clearData },
                              model: {
                                value: _vm.trigger.enroll_action,
                                callback: function ($$v) {
                                  _vm.$set(_vm.trigger, "enroll_action", $$v)
                                },
                                expression: "trigger.enroll_action",
                              },
                            },
                            _vm._l(_vm.availableActions, function (action) {
                              return _c(
                                "el-option",
                                {
                                  key: action.id,
                                  attrs: {
                                    label: action.name,
                                    value: action.value,
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(action.name))])]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.hasPermissionTo(["list workflow", "view workflow"]) &&
                      _vm.trigger.enroll_action &&
                      _vm.trigger.enroll_action === "enroll" &&
                      _vm.power_dialer_trigger
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "inline mb-3 no-border",
                              attrs: { prop: "workflow_ids" },
                            },
                            [
                              _c("workflow-selector", {
                                staticStyle: { width: "300px" },
                                attrs: { multiple: false },
                                on: { change: _vm.changeWorkflows },
                                model: {
                                  value: _vm.workflow_id,
                                  callback: function ($$v) {
                                    _vm.workflow_id = $$v
                                  },
                                  expression: "workflow_id",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row no-select" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pull-right _600 opacity-5",
                      class: [
                        _vm.trigger.valid
                          ? "text-dark-greenish"
                          : "text-danger",
                      ],
                    },
                    [
                      _vm.trigger.valid
                        ? _c("i", { staticClass: "fa fa-check-circle" })
                        : _c("i", { staticClass: "fa fa-times-circle" }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.trigger.valid
                              ? "Valid trigger"
                              : "Invalid trigger"
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm.trigger.type == "contact_list" ||
          _vm.trigger.type == "remove_contact_list"
        ? _c(
            "el-form",
            {
              ref: "trigger_form",
              attrs: { model: _vm.trigger, rules: _vm.form_trigger },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("span", { staticClass: "inline" }, [
                      _vm._v(
                        "When " +
                          _vm._s(
                            _vm.trigger.type == "contact_list"
                              ? "add"
                              : "remove"
                          ) +
                          " contact list "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inline mb-3 no-border",
                        attrs: { prop: "contact_list_option_id" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "Select Option",
                              "default-first-option": "",
                              autocomplete: "off",
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.clearData },
                            model: {
                              value: _vm.trigger.contact_list_option_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.trigger,
                                  "contact_list_option_id",
                                  $$v
                                )
                              },
                              expression: "trigger.contact_list_option_id",
                            },
                          },
                          _vm._l(
                            _vm.availableContactListOptions,
                            function (contact_list_option) {
                              return _c(
                                "el-option",
                                {
                                  key: contact_list_option.id,
                                  attrs: {
                                    label: contact_list_option.name,
                                    value: contact_list_option.id,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(contact_list_option.name)),
                                  ]),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "inline" }, [_vm._v(" of ")]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inline mb-3 no-border",
                        attrs: { prop: "contact_list_id" },
                      },
                      [
                        _vm._v(
                          "\n                    Select a list\n                    "
                        ),
                        _c("contact-lists-selector", {
                          attrs: {
                            user_id: _vm.auth.user.profile.id,
                            module_type:
                              _vm.ContactListModuleTypes.PUBLIC_CONTACT_LIST,
                            list_type: _vm.ContactListTypes.STATIC_LIST,
                          },
                          on: { change: _vm.changeContactList },
                          model: {
                            value: _vm.trigger.contact_list_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.trigger, "contact_list_id", $$v)
                            },
                            expression: "trigger.contact_list_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "inline" }, [_vm._v(", then ")]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inline mb-3 no-border",
                        attrs: { prop: "enroll_action" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "Select Action",
                              autocomplete: "off",
                              "default-first-option": "",
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.clearData },
                            model: {
                              value: _vm.trigger.enroll_action,
                              callback: function ($$v) {
                                _vm.$set(_vm.trigger, "enroll_action", $$v)
                              },
                              expression: "trigger.enroll_action",
                            },
                          },
                          _vm._l(_vm.availableActions, function (action) {
                            return _c(
                              "el-option",
                              {
                                key: action.id,
                                attrs: {
                                  label: action.name,
                                  value: action.value,
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(action.name))])]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.hasPermissionTo(["list workflow", "view workflow"]) &&
                    _vm.trigger.enroll_action &&
                    _vm.trigger.enroll_action === "enroll" &&
                    _vm.power_dialer_trigger
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "workflow_ids" },
                          },
                          [
                            _c("workflow-selector", {
                              staticStyle: { width: "300px" },
                              attrs: { multiple: false },
                              on: { change: _vm.changeWorkflows },
                              model: {
                                value: _vm.workflow_id,
                                callback: function ($$v) {
                                  _vm.workflow_id = $$v
                                },
                                expression: "workflow_id",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row no-select" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pull-right _600 opacity-5",
                      class: [
                        _vm.trigger.valid
                          ? "text-dark-greenish"
                          : "text-danger",
                      ],
                    },
                    [
                      _vm.trigger.valid
                        ? _c("i", { staticClass: "fa fa-check-circle" })
                        : _c("i", { staticClass: "fa fa-times-circle" }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.trigger.valid
                              ? "Valid trigger"
                              : "Invalid trigger"
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm.trigger.type == "call disposition"
        ? _c(
            "el-form",
            {
              ref: "trigger_form",
              attrs: { model: _vm.trigger, rules: _vm.form_trigger },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("span", { staticClass: "inline" }, [
                      _vm._v("When a call is disposed to "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inline mb-3 no-border",
                        attrs: { prop: "call_disposition_id" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "Select Disposition",
                              "default-first-option": "",
                              autocomplete: "off",
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.clearData },
                            model: {
                              value: _vm.trigger.call_disposition_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.trigger,
                                  "call_disposition_id",
                                  $$v
                                )
                              },
                              expression: "trigger.call_disposition_id",
                            },
                          },
                          _vm._l(
                            _vm.callDispositionAlphabeticalOrder,
                            function (disposition) {
                              return _c(
                                "el-option",
                                {
                                  key: disposition.id,
                                  attrs: {
                                    label: disposition.name,
                                    value: disposition.id,
                                  },
                                },
                                [
                                  disposition.is_external !== undefined
                                    ? [
                                        disposition.is_external
                                          ? _c(
                                              "i",
                                              {
                                                staticClass: "material-icons",
                                                style: {
                                                  color: disposition.color,
                                                },
                                              },
                                              [_vm._v("lock")]
                                            )
                                          : _c(
                                              "i",
                                              {
                                                staticClass: "material-icons",
                                                style: {
                                                  color: disposition.color,
                                                },
                                              },
                                              [_vm._v("label")]
                                            ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(disposition.name)),
                                  ]),
                                ],
                                2
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "inline" }, [_vm._v(", then ")]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inline mb-3 no-border",
                        attrs: { prop: "enroll_action" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "Select Action",
                              autocomplete: "off",
                              "default-first-option": "",
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.clearData },
                            model: {
                              value: _vm.trigger.enroll_action,
                              callback: function ($$v) {
                                _vm.$set(_vm.trigger, "enroll_action", $$v)
                              },
                              expression: "trigger.enroll_action",
                            },
                          },
                          _vm._l(_vm.availableActions, function (action) {
                            return _c(
                              "el-option",
                              {
                                key: action.id,
                                attrs: {
                                  label: action.name,
                                  value: action.value,
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(action.name))])]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.workflowSelectorEnabledForCallDispositionTrigger
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "workflow_ids" },
                          },
                          [
                            _c("workflow-selector", {
                              staticStyle: { width: "300px" },
                              attrs: { multiple: false },
                              on: { change: _vm.changeWorkflows },
                              model: {
                                value: _vm.workflow_id,
                                callback: function ($$v) {
                                  _vm.workflow_id = $$v
                                },
                                expression: "workflow_id",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.tagsSelectorEnabledForCallDispositionTrigger
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "tag_ids" },
                          },
                          [
                            _c("tag-selector", {
                              staticStyle: { width: "300px" },
                              attrs: {
                                multiple: true,
                                category_prop: _vm.TagCategory.CAT_CONTACTS,
                              },
                              on: { change: _vm.changeTags },
                              model: {
                                value: _vm.trigger.tag_ids,
                                callback: function ($$v) {
                                  _vm.$set(_vm.trigger, "tag_ids", $$v)
                                },
                                expression: "trigger.tag_ids",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.contactListSelectorEnabledForCallDispositionTrigger
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "inline mb-3 no-border",
                            attrs: { prop: "list_id" },
                          },
                          [
                            _c("contact-lists-selector", {
                              attrs: {
                                multiple: false,
                                value: _vm.selected_list,
                                list_type: _vm.ContactListTypes.STATIC_LIST,
                              },
                              on: { change: _vm.changeList },
                              model: {
                                value: _vm.trigger.list_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.trigger, "list_id", $$v)
                                },
                                expression: "trigger.list_id",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row no-select" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pull-right _600 opacity-5",
                      class: [
                        _vm.trigger.valid
                          ? "text-dark-greenish"
                          : "text-danger",
                      ],
                    },
                    [
                      _vm.trigger.valid
                        ? _c("i", { staticClass: "fa fa-check-circle" })
                        : _c("i", { staticClass: "fa fa-times-circle" }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.trigger.valid
                              ? "Valid trigger"
                              : "Invalid trigger"
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm.trigger.type == "dnc status"
        ? _c(
            "el-form",
            {
              ref: "trigger_form",
              attrs: { model: _vm.trigger, rules: _vm.form_trigger },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("span", { staticClass: "inline" }, [
                      _vm._v("When a contact is "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inline mb-3 no-border",
                        attrs: { prop: "trigger_option" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "Select",
                              "default-first-option": "",
                              autocomplete: "off",
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.clearData },
                            model: {
                              value: _vm.trigger.trigger_option,
                              callback: function ($$v) {
                                _vm.$set(_vm.trigger, "trigger_option", $$v)
                              },
                              expression: "trigger.trigger_option",
                            },
                          },
                          _vm._l(
                            _vm.dnc_trigger_options,
                            function (trigger_option) {
                              return _c(
                                "el-option",
                                {
                                  key: trigger_option.value,
                                  attrs: {
                                    value: trigger_option.value,
                                    label: trigger_option.label,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(trigger_option.label)),
                                  ]),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "inline" }, [
                      _vm._v(", then enroll to this sequence."),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row no-select" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pull-right _600 opacity-5",
                      class: [
                        _vm.trigger.valid
                          ? "text-dark-greenish"
                          : "text-danger",
                      ],
                    },
                    [
                      _vm.trigger.valid
                        ? _c("i", { staticClass: "fa fa-check-circle" })
                        : _c("i", { staticClass: "fa fa-times-circle" }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.trigger.valid
                              ? "Valid trigger"
                              : "Invalid trigger"
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm.trigger.type == "appointment"
        ? _c(
            "el-form",
            {
              ref: "trigger_form",
              attrs: { model: _vm.trigger, rules: _vm.form_trigger },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("span", { staticClass: "inline" }, [
                      _vm._v("When an appointment is set to "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "inline mb-3 no-border",
                        attrs: { prop: "appointment_status_id" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: {
                              placeholder: "Select Appointment Status",
                              "default-first-option": "",
                              autocomplete: "off",
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.clearData },
                            model: {
                              value: _vm.trigger.appointment_status_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.trigger,
                                  "appointment_status_id",
                                  $$v
                                )
                              },
                              expression: "trigger.appointment_status_id",
                            },
                          },
                          _vm._l(
                            _vm.appointmentOptions,
                            function (appointment) {
                              return _c(
                                "el-option",
                                {
                                  key: appointment.value,
                                  attrs: {
                                    label: appointment.label,
                                    value: appointment.value,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(appointment.label)),
                                  ]),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "inline" }, [
                      _vm._v(", then enroll to this sequence"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row no-select" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pull-right _600 opacity-5",
                      class: [
                        _vm.trigger.valid
                          ? "text-dark-greenish"
                          : "text-danger",
                      ],
                    },
                    [
                      _vm.trigger.valid
                        ? _c("i", { staticClass: "fa fa-check-circle" })
                        : _c("i", { staticClass: "fa fa-times-circle" }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.trigger.valid
                              ? "Valid trigger"
                              : "Invalid trigger"
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }