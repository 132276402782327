<template>
    <div class="row nav-padding">
        <div class="col-12">
            <div class="d-flex mb-3">
                <h4 class="mb-0">Custom Contact Fields</h4>
                <div class="ml-auto">
                    <el-button type="success"
                               size="medium"
                               round
                               @click="$refs.attribute_dictionary_manager.add()">
                        <strong>Add New</strong>
                    </el-button>
                </div>
            </div>

            <div v-loading="loading_list">
                <div v-if="attribute_dictionaries.length > 0">
                    <el-table :data="attribute_dictionaries">
                        <el-table-column label="Custom Field">
                            <template v-slot="scope">
                                <h6>{{ scope.row.name }}</h6>
                            </template>
                        </el-table-column>
                        <el-table-column label="Key"
                                         prop="slug">
                        </el-table-column>
                        <el-table-column label="Description"
                                         prop="description">
                        </el-table-column>
                        <el-table-column label="Type"
                                         prop="type_values_text">
                        </el-table-column>
                        <el-table-column padding="0"
                                         width="80px">
                            <template v-slot="scope">
                                <el-dropdown trigger="click">
                                    <el-button size="small">
                                        <i class="fa fa-ellipsis-h"></i>
                                    </el-button>
                                    <template v-slot:dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item @click.native="editAttributeDictionary(scope.row)">
                                                <i class="fa fa-edit"></i> Edit
                                            </el-dropdown-item>
                                            <el-dropdown-item divided
                                                              @click.native="deleteAttributeDictionary(scope.row)">
                                                <i class="fa fa-trash-o"></i> Delete
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div v-else
                     class="py-5">
                    <img-empty class="w-50 text-center mx-auto" />
                    <div class="text-center">
                        <p class="lead">
                            You don't have any custom contact field setup yet.
                        </p>
                        <el-button round
                                   size="small"
                                   class="ml-auto"
                                   type="success"
                                   @click="$refs.attribute_dictionary_manager.add()">
                            <strong>Add New Custom Field</strong>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <attribute-dictionary-manager ref="attribute_dictionary_manager"
                                      @callback="fetchAttributeDictionaries" />
    </div>
</template>

<script>
import AttributeDictionaryManager from "./attribute-dictionary-manager"
import ImgEmpty from "../../misc/img-empty"
import * as AttributeDictionary from '../../../constants/attribute-dictionary'
import {mapActions, mapGetters} from "vuex"

export default {
    name: "attribute-dictionary-list",

    components: {ImgEmpty, AttributeDictionaryManager},

    data() {
        return {
            loading_list: false,
            AttributeDictionary
        }
    },

    computed: {
        ...mapGetters({
            attribute_dictionaries: 'getAttributeDictionaries',
        }),
    },

    methods: {
        fetchAttributeDictionaries() {
            this.loading_list = true
            axios.get(`/api/v1/attribute-dictionary`).then(res => {
                this.setAttributeDictionaries(res.data.data)
                this.loading_list = false
            })
        },

        editAttributeDictionary(attribute_dictionary) {
            this.$refs.attribute_dictionary_manager.edit(attribute_dictionary)
        },

        deleteAttributeDictionary(attribute_dictionary) {
            this.$refs.attribute_dictionary_manager.delete(attribute_dictionary)
        },

        ...mapActions(['setAttributeDictionaries'])
    }
}
</script>

