<template>
    <div class="btn btn-light bg-white border btn-block p-x-md">
        <img class="google-icon" src="/assets/images/google-logo.svg"/>
        &nbsp;{{ text }}
    </div>
</template>

<script>
export default {
    props: {
        text: {
            required: false,
            default: 'Sign in with Google'
        }
    }
}
</script>

<style scoped>
.google-icon {
    max-width: 18px
}
</style>