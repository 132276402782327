export const TYPE_NUMBER = 1
export const TYPE_TEXT = 2
export const TYPE_DROPDOWN = 3
export const TYPE_DATE_PICKER = 4

export const TYPES = {
    [TYPE_NUMBER]: {
        key: TYPE_NUMBER,
        label: 'Number'
    },
    [TYPE_TEXT]: {
        key: TYPE_TEXT,
        label: 'Text'
    },
    // inactive
    // [TYPE_DROPDOWN]: {
    //     key: TYPE_DROPDOWN,
    //     label: 'Dropdown'
    // },
    [TYPE_DATE_PICKER]: {
        key: TYPE_DATE_PICKER,
        label: 'Date Picker'
    },
};
