var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "function-card mt-3" },
    [
      _c(
        "div",
        { staticClass: "d-flex mb-3" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "mx-2",
            attrs: { placeholder: "Function name", type: "text" },
            model: {
              value: _vm.functionItem.name,
              callback: function ($$v) {
                _vm.$set(_vm.functionItem, "name", $$v)
              },
              expression: "functionItem.name",
            },
          }),
          _vm._v(" "),
          _c("el-button", {
            staticClass: "icon-rounded",
            attrs: {
              disabled: _vm.functionsCount === 1,
              icon: "el-icon-delete",
              circle: "",
              plain: "",
              size: "small",
              type: "danger",
              title:
                _vm.functionsCount === 1
                  ? "You cannot delete the last function"
                  : "Delete function",
            },
            on: { click: _vm.confirmDelete },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c("div", { staticClass: "form-label" }, [
            _c("h5", { staticClass: "text-dark" }, [_vm._v("Description (*)")]),
          ]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { type: "textarea", rows: 2 },
            model: {
              value: _vm.functionItem.description,
              callback: function ($$v) {
                _vm.$set(_vm.functionItem, "description", $$v)
              },
              expression: "functionItem.description",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("function-property", {
        attrs: { properties: _vm.functionItem.parameters.properties },
        on: { "add-property": _vm.onAddProperty },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "form-label no-wrap mt-2" }, [
      _c("strong", [_vm._v("Function name (*)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }