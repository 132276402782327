<template>
    <el-tooltip :content="`Type at least ${threshold} character(s)`"
                placement="bottom">
        <el-select ref="tag-select"
                   class="w-full tag-selector"
                   filterable
                   remote
                   default-first-option
                   :placeholder="placeholder"
                   :multiple="multiple"
                   :multiple-limit="multiple_limit"
                   :clearable="clearable"
                   :collapse-tags="!noCollapse"
                   :size="size"
                   :disabled="disabled"
                   :remote-method="getTags"
                   :loading="loading_tags"
                   v-model="tag_id"
                   @change="selectTag">
            <el-option-group key="Account Tags"
                             label="Account Tags"
                             v-if="companyTagsAlphabeticalOrder && companyTagsAlphabeticalOrder.length > 0">
                <el-option :key="tag.id"
                           :label="tag.name"
                           :value="tag.id"
                           :disabled="disabled_options"
                           v-for="tag in companyTagsAlphabeticalOrder">
                    <i class="fa fa-circle"
                       :style="{ color: tag.color }">
                    </i>
                    <span class="ml-2">
                        {{ tag.name }}
                    </span>
                </el-option>
            </el-option-group>
            <el-option-group key="Import Tags"
                             label="Import Tags"
                             v-if="importTagsAlphabeticalOrder && importTagsAlphabeticalOrder.length > 0">
                <el-option :key="tag.id"
                           :label="tag.name"
                           :value="tag.id"
                           v-for="tag in importTagsAlphabeticalOrder">
                    <i class="fa fa-circle"
                       :style="{ color: tag.color }">
                    </i>
                    <span class="ml-2">
                        {{ tag.name }}
                    </span>
                </el-option>
            </el-option-group>
        </el-select>
    </el-tooltip>
</template>

<script>
import {mapState} from 'vuex'
import {acl_mixin} from '../mixins'
import auth from '../auth'
import * as TagTypes from "../constants/tag-types"
import * as TagCategory from "../constants/tag-categories"

export default {
    mixins: [acl_mixin],

    data() {
        return {
            auth: auth,
            tag_id: this.value,
            loading_tags: false,
            tags: [],
            TagTypes,
            TagCategory,
            source: null
        }
    },

    props: {
        value: {
            required: false,
        },

        exclude: {
            required: false,
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        multiple_limit: {
            type: Number,
            required: false,
            default: 0
        },

        noCollapse: {
            type: Boolean,
            required: false,
            default: false
        },

        clearable: {
            type: Boolean,
            required: false,
            default: false
        },

        custom_tags: {
            type: Array,
            required: false,
            default: () => []
        },

        loaded: {
            type: Boolean,
            required: false,
            default: false
        },

        category_prop: {
            required: false,
            type: Number,
            default: null
        },

        size: {
            type: String,
            default: '',
            required: false
        },

        disabled: {
            type: Boolean,
            default: false,
            required: false
        },

        disabled_options: {
            type: Boolean,
            default: false,
            required: false
        },

        threshold: {
            type: Number,
            default: 3,
            required: false
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            tag_options: state => state.tag_options
        }),

        placeholder() {
            if (this.multiple) {
                return 'Type to search Tags'
            }

            return 'Type to search Tag'
        },

        availableTags() {
            if (this.tags) {
                let tags = this.tags.filter((tag) => {
                    return tag.id != this.exclude
                })

                if (this.category_prop) {
                    tags = tags.filter(tag => tag.category === this.category_prop)
                }

                return tags
            }

            return []
        },

        tagsAlphabeticalOrder() {
            if (this.availableTags) {
                let tags = _.clone(this.availableTags)
                return tags.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },

        companyTagsAlphabeticalOrder() {
            if (this.tagsAlphabeticalOrder.length) {
                return this.tagsAlphabeticalOrder.filter(tag => tag.type === TagTypes.TYPE_COMPANY)
            }

            return []
        },

        importTagsAlphabeticalOrder() {
            if (this.category_prop && this.category_prop != TagCategory.CAT_CONTACTS) {
                return []
            }

            if (this.tagsAlphabeticalOrder.length) {
                return this.tagsAlphabeticalOrder.filter(tag => tag.type === TagTypes.TYPE_IMPORT)
            }

            return []
        },
    },

    created() {
        VueEvent.listen('tag_created', (tag) => {
            this.addTag(tag)
        })
        VueEvent.listen('tag_updated', (tag) => {
            this.updateTag(tag)
        })
        VueEvent.listen('tag_deleting', (tag) => {
            this.deleteTag(tag.id)
        })
    },

    mounted() {
        if (this.custom_tags) {
            this.tags = this.custom_tags
        }

        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()

        this.getTags('', true)
    },

    methods: {
        selectTag(tag) {
            this.tag_id = tag
            this.$emit('change', tag)
        },

        getTags(query, force = false) {
            if (this.hasPermissionTo('list tag') && (query.length >= this.threshold || force)) {
                this.loading_tags = true

                let params = {
                    page: 1,
                    per_page: 50,
                    search: query
                }

                axios
                    .get('/api/v1/tag', {
                        params: params,
                        cancelToken: this.source.token
                    })
                    .then(res => {
                        this.loading_tags = false
                        this.tags = res.data.data
                    })
                    .catch(err => {
                        if (axios.isCancel(err)) {
                            console.log('Request canceled', err.message)
                        } else {
                            this.$root.handleErrors(err.response)
                            this.loading_tags = false
                        }
                    })
            } else {
                this.tags = []
            }
        },

        resourceExists(arr, resource) {
            return !!arr.find(item => item.id === resource.id)
        },

        addTag(tag) {
            if (this.resourceExists(this.tags, tag)) {
                return
            }
            this.tags.push(tag)
        },

        updateTag(updated_tag) {
            let found = this.tags.map(o => o.id).indexOf(updated_tag.id)
            if (found !== -1) {
                Vue.set(this.tags, found, updated_tag)
            }
        },

        deleteTag(deleted_tag_id) {
            let index = this.tags.findIndex(tag => tag.id === deleted_tag_id)

            if (index > -1) {
                this.tags.splice(index, 1)
            }
        },
    },

    watch: {
        value() {
            this.tag_id = this.value
        },

        'tag_options.is_reset': function () {
            this.tag_id = null
        },

        custom_tags() {
            this.tags = this.custom_tags
        }
    }
}
</script>
