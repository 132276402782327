<template>
    <div>
        Please register your business for carrier compliance.
        <div>
            <button class="btn rounded greenish btn-register btn-sm mt-2 pull-right">
                Register
            </button>
            <button class="btn rounded bluish btn-remind-later btn-sm mt-2 pull-left">
                Remind me later
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'compliance-invitation-notification',
    }
</script>
