var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row mt-5" }, [
          _c("div", { staticClass: "col-sm-12 col-md-8 mx-auto" }, [
            _vm.showLoginBanner
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-warning",
                    attrs: { role: "alert" },
                  },
                  [_vm._m(0), _vm._v(" "), _vm._m(1)]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showForbiddenBanner
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-warning",
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n          Sorry, but it looks like you don't have enough permissions to proceed with this action.\n          Please check if your Aloware account has access to the integration settings or\n          contact technical support.\n        "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showBadRequestBanner
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-warning",
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n          " +
                          _vm._s(this.badRequestMessage) +
                          "\n        "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.actionIsAllowed
              ? _c(
                  "div",
                  { staticClass: "card text-center border-success py-3" },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-text mt-3" }, [
                        _vm._v(
                          "\n            You have requested to\n            "
                        ),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            _vm._s(
                              _vm.appPanelRequest.requestName.toUpperCase()
                            )
                          ),
                        ]),
                        _vm._v(
                          "\n            to Aloware. Please wait for a few minutes for this process to finish.\n            Please note that you will not immediately see the updates in Pipedrive\n            and you will receive an email when we finish processing your request.\n          "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.actionIsAllowed
              ? _c(
                  "div",
                  { staticClass: "card text-center border-danger py-3" },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _vm._m(5),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-text mt-3" }, [
                        _vm._v(
                          "\n            You have requested to\n            "
                        ),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            _vm._s(
                              _vm.appPanelRequest.requestName.toUpperCase()
                            )
                          ),
                        ]),
                        _vm._v(
                          "\n            to Aloware, but we cannot permit you to proceed. Please resolve the error messages shown\n            and try again.\n          "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "alert-heading mt-1" }, [
      _c("i", {
        staticClass: "glyphicon glyphicon-log-in mt-1 mr-1",
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v("\n          GO TO LOGIN PAGE\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "It looks like you're not logged in to Aloware. Open a new tab by clicking\n          "
      ),
      _c(
        "a",
        {
          staticClass: "font-weight-bold text-decoration-underline",
          attrs: { href: "/login", target: "_blank" },
        },
        [_vm._v("\n            here,\n          ")]
      ),
      _vm._v(
        "\n          enter your credentials and go back to this tab\n        "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "alert-heading mt-1" }, [
      _c("i", {
        staticClass: "glyphicon glyphicon-lock mt-1 mr-1",
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v("\n          FORBIDDEN\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "alert-heading mt-1" }, [
      _c("i", {
        staticClass: "glyphicon glyphicon-lock mt-1 mr-1",
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v("\n          WE CANNOT PROCESS YOUR REQUEST\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "card-title text-success" }, [
      _c("i", { staticClass: "fa fa-check-circle mr-1" }),
      _vm._v(" "),
      _c("span", [_vm._v("SUCCESS")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "card-title text-danger" }, [
      _c("i", { staticClass: "fa fa-times mr-1" }),
      _vm._v(" "),
      _c("span", [_vm._v("UNABLE TO PROCEED")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }