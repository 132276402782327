<template>
    <div class="stepper__content__header"
         :class="isMobile ? 'px-4' : ''">
        <img alt="icon"
            :src="icon"
            v-if="icon"/>
        <h4 class="text-h4 text-weight-bold">
            Welcome to {{ company_name }}!
        </h4>
        <p class="text-body1">
            {{ description }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'StepHeader',

    props: {
        company_name: {
            type: String,
            default: ''
        },

        description: {
            type: String,
            default: ''
        },

        icon: {
            type: String,
            default: ''
        },

        isMobile: {
            type: Boolean,
            default: false
        }
    }
}
</script>
