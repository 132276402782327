<template>
    <div>
        <!--rename a broadcast-->
        <el-dialog title="Rename Broadcast"
                   class="dialog-padding auto"
                   append-to-body
                   :width="isLargeEnough ? '400px' : '100%'"
                   :visible.sync="hide_edit">
            <el-form ref="rename_broadcast"
                     class="p-0"
                     label-position="top"
                     :model="broadcast"
                     @submit.prevent.native="saveBroadcastName">
                <el-form-item prop="name"
                              label="Name">
                    <div class="width-400">
                        <el-input placeholder="Broadcast Name"
                            v-model="edit_broadcast.name"
                            @input="preValidateForm('rename_broadcast')">
                        </el-input>
                    </div>
                </el-form-item>
            </el-form>

            <div class="row pb-3">
                <div class="col-12">
                    <el-button type="success"
                               class="pull-right ml-2"
                               :disabled="loading_btn || edit_broadcast.name.length < 1"
                               @click="saveBroadcastName">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="material-icons"
                           v-show="!loading_btn">
                            save
                        </i>
                        <span>Save</span>
                    </el-button>

                    <el-button type="info"
                               class="pull-right"
                               @click="closeModal">
                        <span class="fa fa-close"></span>
                        <span>Cancel</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <slot name="trigger">
            <button slot="reference"
                    class="btn btn-sm btn-warning btn-block"
                    @click.prevent="hide_edit = true">
                <i class="fa fa-pencil"></i> Rename
            </button>
        </slot>
    </div>
</template>

<script>
import auth from '../auth'
import {mapState} from 'vuex'
import {
    acl_mixin,
    form_validation_mixin
} from '../mixins'

export default {
    mixins: [
        acl_mixin,
        form_validation_mixin
    ],

    props: {
        broadcast: {
            required: true
        },
    },

    data() {
        return {
            auth: auth,
            edit_broadcast: {
                id:'',
                name:'',
                timezone: window.timezone
            },
            hide_edit: false,
            loading_btn: false,
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            return valid_sizes.includes(this.$mq);
        }
    },

    methods: {
        saveBroadcastName() {
            if (this.validateForm('rename_broadcast')) {
                this.loading_btn = true
                axios.put('/api/v1/broadcasts/' + this.edit_broadcast.id, this.edit_broadcast).then(res => {
                    this.loading_btn = false
                    this.closeModal()
                    this.$emit('success', res.data)
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_btn = false
                })
            }
        },

        closeModal() {
            this.hide_edit = false
        },

        initBroadcast() {
            if (this.broadcast) {
                this.edit_broadcast.name = this.broadcast.name
                this.edit_broadcast.id = this.broadcast.id
            }
        }
    },

    watch: {
        broadcast() {
            this.initBroadcast()
        },

        hide_edit() {
            if (this.hide_edit) {
                this.initBroadcast()
                this.$emit('hide')
            } else {
                this.resetForm('rename_broadcast')
                this.$emit('show')
            }
        }
    }
}
</script>
