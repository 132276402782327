var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", _vm._b({}, "div", _vm.$attrs, false), [
    _c("img", {
      staticClass: "img-fluid",
      attrs: { src: "/assets/images/img-empty.png" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }