<template>
    <div class="row pb-5">
        <div class="col-9 mx-auto">
            <workflow-triggers
                v-model="value"
                :triggers="value.triggers"
                :mailgun_intake_route="value.mailgun_intake_route"
                :automation_trigger="true">
            </workflow-triggers>
        </div>
    </div>
</template>

<script>
import WorkflowTriggers from "./workflow-triggers";

export default {
    name: "workflow-trigger-intake-manager",
    components: {WorkflowTriggers},
    props: {
        value: {
            type: Object,
            required: true
        }
    }
}
</script>
