var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _c("div", { staticClass: "container-fluid py-3" }, [
        _c(
          "div",
          {
            staticClass: "card",
            staticStyle: { "min-height": "calc(100vh - 130px)" },
          },
          [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "d-flex mb-3" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "Integrations" } } },
                    [
                      _c(
                        "el-button",
                        { staticClass: "mr-3", attrs: { size: "small" } },
                        [_c("i", { staticClass: "fa fa-chevron-left" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { round: "", size: "small", type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.webhook_manager.add()
                        },
                      },
                    },
                    [_c("strong", [_vm._v("Add Webhook")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.webhooks.length > 0
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.webhooks_loading,
                          expression: "webhooks_loading",
                        },
                      ],
                    },
                    [
                      _c(
                        "table",
                        {
                          key: _vm.table_key,
                          staticClass: "table table-aloware",
                        },
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.webhooks, function (webhook) {
                              return _c("tr", { key: webhook.id }, [
                                _c(
                                  "td",
                                  { staticStyle: { "max-width": "350px" } },
                                  [
                                    _c("div", [
                                      _c(
                                        "h6",
                                        { staticClass: "mb-0 text-dark" },
                                        [_vm._v(_vm._s(webhook.name))]
                                      ),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("strLimit")(
                                              webhook.description,
                                              100
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  _c("div", [
                                    _c(
                                      "h6",
                                      { staticClass: "text-greenish mb-0" },
                                      [_vm._v(_vm._s(webhook.url))]
                                    ),
                                    _vm._v(" "),
                                    webhook.http_method ==
                                    _vm.Webhook.HTTP_METHOD_POST
                                      ? _c(
                                          "small",
                                          { staticClass: "text-dead" },
                                          [
                                            _vm._v(
                                              "\n                                        HTTP POST Request\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  webhook.delay > 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(webhook.delay) + " seconds"
                                        ),
                                      ])
                                    : _c("span", [_vm._v("No Delay")]),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "ul",
                                    { staticClass: "list-unstyled" },
                                    _vm._l(
                                      webhook.event_names,
                                      function (event) {
                                        return _c("li", { key: event }, [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(event) +
                                              "\n                                    "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  webhook.filter_size
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(webhook.filter_size) +
                                            " filters\n                                "
                                        ),
                                      ])
                                    : _c("span", { staticClass: "text-dead" }, [
                                        _vm._v(
                                          "\n                                    No filters added\n                                "
                                        ),
                                      ]),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  !webhook.testing
                                    ? _c("div", [
                                        webhook.status ===
                                        _vm.Webhook.STATUS_UNTESTED
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge badge-pill badge-warning font-weight-bold p-2 rounded",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-exclamation-triangle",
                                                }),
                                                _vm._v(
                                                  " UNTESTED\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        webhook.status ===
                                        _vm.Webhook.STATUS_VALID
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge badge-pill badge-success font-weight-bold p-2 rounded",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-check-circle",
                                                }),
                                                _vm._v(
                                                  " VALID & ACTIVE\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        webhook.status ===
                                        _vm.Webhook.STATUS_INVALID
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge badge-pill badge-danger font-weight-bold p-2 clickable rounded",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showResponseModal(
                                                      webhook
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-exclamation-triangle mb-2",
                                                }),
                                                _vm._v(" INVALID "),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v("Click for more"),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        webhook.status ===
                                        _vm.Webhook.STATUS_DISABLED
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge badge-pill badge-secondary font-weight-bold p-2 rounded",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-ban",
                                                }),
                                                _vm._v(
                                                  " DISABLED\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  webhook.testing
                                    ? _c("div", [_vm._m(2, true)])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { attrs: { width: "40" } },
                                  [
                                    _c(
                                      "el-dropdown",
                                      {
                                        attrs: { trigger: "click" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "dropdown",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "el-dropdown-menu",
                                                    [
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editWebhook(
                                                                webhook
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-edit",
                                                          }),
                                                          _vm._v(
                                                            " Edit\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.testWebhook(
                                                                webhook
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-send",
                                                          }),
                                                          _vm._v(
                                                            " Test & Validate\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      webhook.status !==
                                                      _vm.Webhook
                                                        .STATUS_DISABLED
                                                        ? _c(
                                                            "el-dropdown-item",
                                                            {
                                                              nativeOn: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.disableWebhook(
                                                                      webhook
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-ban",
                                                              }),
                                                              _vm._v(
                                                                " Disable\n                                            "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          attrs: {
                                                            divided: "",
                                                          },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteWebhook(
                                                                webhook
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-trash-o",
                                                          }),
                                                          _vm._v(
                                                            " Delete\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          { attrs: { size: "small" } },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-ellipsis-h",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "pt-5" },
                    [
                      _c("img-empty", {
                        staticClass: "w-50 text-center mx-auto",
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(
                              "\n                            You don't have any webhooks integration setup yet.\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "ml-auto",
                              attrs: {
                                round: "",
                                size: "small",
                                type: "success",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.webhook_manager.add()
                                },
                              },
                            },
                            [_c("strong", [_vm._v("Add Webhook")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("webhook-manager", {
        ref: "webhook_manager",
        on: { callback: _vm.fetchWebhooks },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "4vh",
            visible: _vm.show_response_modal,
            "append-to-body": true,
            title: "Webhook Endpoint Response",
          },
          on: { close: _vm.closeResponseModal },
        },
        [
          _vm.selected_webhook && _vm.selected_webhook.errors
            ? _c("div", [
                _c(
                  "pre",
                  { staticClass: "response-error-container pl-3 pr-3" },
                  [
                    _vm._v("                "),
                    _c("code", { staticClass: "display-table" }, [
                      _vm._v("\n                    "),
                      _c("div", { staticClass: "white-space-normal" }, [
                        _vm._v(
                          '\n                        "status_code": ' +
                            _vm._s(_vm.selected_webhook.errors.status_code) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v("\n\n                    "),
                      _c("div", { staticClass: "white-space-normal" }, [
                        _vm._v(
                          '\n                        "message": ' +
                            _vm._s(_vm.selected_webhook.errors.message) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v("\n                "),
                    ]),
                    _vm._v("\n            "),
                  ]
                ),
              ])
            : _c("div", [
                _vm._v("\n            No response error to show\n        "),
              ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("el-button", { on: { click: _vm.closeResponseModal } }, [
                _vm._v("Close"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-grow-1 align-self-center" }, [
      _c("h4", { staticClass: "text-dark mb-0 align-self-center" }, [
        _vm._v("Webhooks"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Webhook")]),
        _vm._v(" "),
        _c("th", [_vm._v("Target")]),
        _vm._v(" "),
        _c("th", [_vm._v("Delay")]),
        _vm._v(" "),
        _c("th", [_vm._v("Events")]),
        _vm._v(" "),
        _c("th", [_vm._v("Filters")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass:
          "badge badge-pill badge-warning font-weight-bold p-2 rounded",
      },
      [
        _c("i", { staticClass: "fa fa-send" }),
        _vm._v(" TESTING...\n                                    "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }