<template>
    <div class="form-group">
        <el-form-item prop="extension"
                      class="no-border">
            <div class="form-label">
                <label>{{ label }}</label>
            </div>
            <el-select placeholder="Extension"
                       autocomplete="off"
                       ref="extensionSelect"
                       default-first-option
                       filterable
                       clearable
                       :disabled="disabled"
                       :filter-method="filterExtensions"
                       :value="value"
                       data-testid="extension-selector"
                       @input="onInput"
                       @change="onChange">
                <el-option :key="extension"
                           :label="extension"
                           :value="extension"
                           v-for="extension in options">
                </el-option>

                <el-option label="No extensions found matching the search value"
                           :key="0"
                           :value="0"
                           v-if="!options.length">
                    {{ noOptionsLabel }}
                </el-option>
            </el-select>
        </el-form-item>
    </div>
</template>

<script>
export default {
    name: 'UserExtensionSelect',

    props: {
        value: {
            type: String,
            default: ''  
        },
        options: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            default: 'Select an extension:'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            extensionsSearchQuery: ''
        }
    },

    computed: {
        noOptionsLabel() {
            if (this.extensionsSearchQuery) {
                return 'No extensions found'
            }

            return 'No data available'
        }
    },

    methods: {
        filterExtensions(query) {
            this.extensionsSearchQuery = query
            this.$emit('filter-extensions', query)
            this.$nextTick(() => this.scrollToTop())
        },

        onInput(value) {
            this.extensionsSearchQuery = ''
            this.$emit('input', value)
        },

        onChange(value) {
            this.extensionsSearchQuery = ''
            this.$emit('change', value)
        },

        scrollToTop() {
            const dropdown = this.$refs.extensionSelect.popperElm.querySelector('.el-scrollbar__wrap')
            if (dropdown) {
                dropdown.scrollTop = 0
            }
        }
    }
}
</script>
