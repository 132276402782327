var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trial-banner button d-flex align-items-center" },
    [
      _c(
        "el-button",
        {
          staticClass: "h-100",
          attrs: {
            type: "primary",
            plain: "",
            size: _vm.size,
            "data-testid": "open-tutorial-video",
          },
          on: { click: _vm.openModal },
        },
        [_vm._v("\n        Open Tutorial Video 🤩\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }