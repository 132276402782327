<template>
  <el-dialog transition-show="scale"
            transition-hide="scale"
            persistent
            v-model="show">
    <el-card class="bg-white text-black text-center pt-lg"
            style="width: 635px">
      <div>
        <div class="text-h5">
          Welcome!! 🎉
        </div>

        <div class="text-h6 pt-lg">
          Looks like you already have an account with us. 🎉
        </div>

        <div class="text-h6 pt-lg">
          Please login to your account to continue. 🚀
        </div>
    </div>

      <div class="flex justify-content-center bg-white pb-lg"
                      text-align="center">
        <el-button label="Go to Login"
               color="primary"
               text-color="white"
               rounded
               @click="openLoginPage"
               v-close-popup>
        </el-button>
    </div>
    </el-card>
  </el-dialog>
</template>

<script>

export default {
  name: 'UserAlreadyHaveAccountDialog',

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    openLoginPage () {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
