<template>
    <div class="app-body messenger-container"
         :style="{height: '100vh'}"
         id="view">
        <iframe frameborder="0"
                :src="source">
        </iframe>
    </div>
</template>

<script>
    import {acl_mixin, chargebee_mixin, scroll_mixin} from '../../mixins'
    import auth from "../../auth";
import { RESELLER_SIMPSOCIAL_ID } from '../../constants/resellers-ids';
    export default {
        mixins: [acl_mixin, chargebee_mixin, scroll_mixin],
        data() {
            return {
                source : ''
            }
        },
        mounted() {
            axios.get('/integrations/simpsocial/messenger-source').then(res => {
                this.source = res.data.source
            }).catch(err => {
                console.log(err)
            })
        },
        beforeRouteEnter(to, from, next) {
            auth.check()
                .then((res) => {
                    if (res.data.user?.reseller_id === RESELLER_SIMPSOCIAL_ID && res.data.user?.company?.talk_enabled && res.data.user?.company?.force_talk) {
                        next(vm => {
                            return vm.simpsocialRedirectToTalkPage(to, false, 'Messenger', '/messenger')
                        })
                    } else if (res.data.user.is_reseller) {
                        // redirect to account management portal if the company is a reseller
                        next({name: 'Account Management Portal'})
                    } else {
                        next()
                    }
                })
                .catch((err) => {
                    next({name: 'Login', query: {redirect: to.fullPath}})
                })
        }
    }
</script>
