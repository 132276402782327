var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: { rules: _vm.rules, autocomplete: "off", "label-position": "top" },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("h5", { staticClass: "text-dark" }, [
            _vm._v("Welcome to the A2P 10DLC Campaign Wizard!"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-divider" }),
          _vm._v(" "),
          _c("p", { staticClass: "mt-3" }, [
            _vm._v(
              "\n                We are excited to have you on board with A2P Messaging, where we revolutionize communication through compliant messaging. Our A2P Campaign Wizard is designed to help you set up and manage your Messaging Campaigns with ease and compliance.\n            "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mt-3 font-weight-bold" }, [
            _vm._v("\n                Why 10DLC?\n            "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mt-3" }, [
            _vm._v(
              "\n                10DLC offers a reliable and cost-effective way to send High-Volume messages with improved deliverability and compliance. Our wizard ensures you meet all regulatory requirements and maximize your messaging potential.\n            "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mt-3 font-weight-bold" }, [
            _vm._v("\n                Let's Get Started!\n            "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mt-3" }, [
            _vm._v(
              'Click "Next" to begin setting up your A2P 10DLC campaign. If you need any assistance along the way, our dedicated support team is here to help.'
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row w-full mt-2" }, [
            _c(
              "div",
              { staticClass: "col-md-8 mb-3 mb-md-0 flex-3" },
              [
                _c("alert-message", {
                  attrs: { "alert-message": _vm.alertMessage },
                  on: { alert: _vm.submitAlert },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-full flex-1" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "w-full",
                    attrs: { type: "success", loading: _vm.loading },
                    on: { click: _vm.openConfirmationDialog },
                  },
                  [
                    _vm._v(
                      "\n                        Next: A2P Use Case\n                        "
                    ),
                    _c("i", {
                      staticClass: "el-icon-arrow-right el-icon-right",
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }