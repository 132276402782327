<template>
    <div class="app-body sold-report-container"
         id="view">
        <iframe :src="source"></iframe>
    </div>
</template>

<script>
import auth from '../../auth'
import { RESELLER_SIMPSOCIAL_ID } from '../../constants/resellers-ids';
import {acl_mixin} from '../../mixins'

export default {
    mixins: [acl_mixin],

    data() {
        return {
            auth: auth,
            source: ''
        }
    },

    mounted() {
        axios.get('/integrations/simpsocial/digital-lead-war-source').then(res => {
            this.source = res.data.source
        }).catch(err => {
            console.log(err)
        })
    },

    created() {
        this.getStatics()
    },

    methods: {
        getStatics() {
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.setPageTitle('Digital Lead War - ' + this.statics.name)
                })
                .catch(err => {
                    this.setPageTitle('Digital Lead War')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user?.reseller_id === RESELLER_SIMPSOCIAL_ID && res.data.user?.company?.talk_enabled && res.data.user?.company?.force_talk) {
                    next(vm => {
                        return vm.simpsocialRedirectToTalkPage(to, false, 'Digital Lead War', '/digital-lead')
                    })
                } else if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
