var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-signal-strength" }, [
    _c("ul", { staticClass: "signal-strength" }, [
      _c("li", { staticClass: "very-weak" }, [
        _c("div", {
          style: _vm.signalStrength >= 1 ? { backgroundColor: _vm.color } : {},
          attrs: { id: "very-weak" },
        }),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "weak" }, [
        _c("div", {
          style: _vm.signalStrength >= 2 ? { backgroundColor: _vm.color } : {},
          attrs: { id: "weak" },
        }),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "strong" }, [
        _c("div", {
          style: _vm.signalStrength >= 3 ? { backgroundColor: _vm.color } : {},
          attrs: { id: "strong" },
        }),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "pretty-strong" }, [
        _c("div", {
          style: _vm.signalStrength === 4 ? { backgroundColor: _vm.color } : {},
          attrs: { id: "pretty-strong" },
        }),
      ]),
    ]),
    _vm._v("\n  " + _vm._s(_vm.issue.description) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }