<template>
    <div class="row nav-padding inbox-filter">
        <div class="col-12">
            <el-form label-position="top"
                     class="form-aloware"
                     ref="companySettingsForm"
                     :model="company_clone">
                <el-form-item prop="contact_status_control_enabled">
                    <span class="page-anchor"
                          id="contact_status_control_enabled">
                    </span>
                    <div class="form-label">
                        <h5>
                            Contact Task Status
                        </h5>
                        <small>
                            <span class="d-flex">Allows for the transitioning of contacts between different statuses.</span>
                        </small>
                    </div>
                    <div class="switch-label">
                        <el-switch active-color="#00a344"
                                   :disabled="loading || !hasPermissionTo('update company')"
                                   v-model="company_clone.contact_status_control_enabled"
                                   @change="onChangeContactStatusControlEnabled">
                        </el-switch>
                        <label>Enable Contact Task Status</label>
                    </div>
                </el-form-item>
            </el-form>

            <div class="form-label">
                <h5>Company Wide Inbox Filters</h5>
                <small>Create company wide filters your users can select from.</small>
            </div>

            <div class="mt-3">
                <el-card v-for="(category, index) in categories"
                         class="box-card"
                         :key="index"
                         :class="`${index > 0 ? 'mt-5' : ''}`">
                    <div slot="header"
                         class="clearfix">
                        <span class="settings-title">{{ category.label }}</span>
                        <el-button size="mini"
                                   class="blue create-settings-button pull-right"
                                   @click="showForm(category.type)">
                            <i class="el-icon-plus"></i> Create Filter
                        </el-button>
                    </div>

                    <el-table v-loading="loading"
                              class="inbox-settings-table"
                              row-class-name="inbox-table-row"
                              :show-header="false"
                              :data="category.data"
                              fit>
                        <template slot="empty">
                            <img-empty class="mx-auto inbox-empty-image"/>
                            <div class="alo-empty-text mb-3">
                                <div class="empty-text-subject">No {{ category.label }} filter settings.</div>
                                <small class="empty-text-info">Click the <b>Create Filter</b> to create one.</small>
                            </div>
                        </template>

                        <el-table-column>
                            <template v-slot="scope">
                                <div class="d-flex">
                                    <div>
                                        <i class="el-icon-s-operation"></i>
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                    <div class="ml-3 d-flex">
                                        <el-button
                                            @click="showForm(scope.row.type, scope.row)"
                                            type="text"
                                            class="pt-0 pb-0 action-button update-button">
                                        <span class="d-flex align-items-center">
                                            <i class="material-icons font-14-400">create</i>Edit Filter
                                        </span>
                                        </el-button>
                                    </div>
                                    <div class="ml-auto">
                                        <el-button
                                            @click="deleteFilter(scope.row)"
                                            type="text"
                                            class="pt-0 pb-0 action-button delete-button">
                                            <i class="el-icon-delete text-danger"></i>
                                        </el-button>
                                    </div>
                                </div>

                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </div>

            <!-- Calls & Recording Form Filter -->
            <inbox-filter-form
                :visible.sync="show_form"
                :type="selected_type"
                :model="selected_model"
                @closed="closeForm">
            </inbox-filter-form>
            <!-- Calls & Recording Form Filter End-->
        </div>
    </div>
</template>

<script>
import auth from '../../../auth'
import { cloneDeep, debounce, pick } from 'lodash'
import ImgEmpty from '../../misc/img-empty'
import InboxFilterForm from './inbox-filter-form'
import { TYPE_CALLS, TYPE_MESSAGES, TYPE_VOICEMAILS, TYPE_INBOX } from '../../../constants/filter-types'
import { acl_mixin } from '../../../mixins'
import { mapActions } from 'vuex'

export default {
    name: "inbox-filter-index",

    components: {
        InboxFilterForm,
        ImgEmpty,
    },

    props: {
        company_clone: {
            type: Object,
            required: true
        }
    },

    mixins: [acl_mixin],

    data() {
        return {
            auth: auth,
            loading: false,
            show_form: false,
            selected_model: null,
            selected_type: null,
            categories: [
                { label: 'Calls & Recordings', type: TYPE_CALLS, data: [] },
                { label: 'Messages', type: TYPE_MESSAGES, data: [] },
                { label: 'Voicemails', type: TYPE_VOICEMAILS, data: [] },
                { label: 'Inbox', type: TYPE_INBOX, data: [] }
            ],
            contact_status_control_enabled: false,
        }
    },

    created() {
        if (this.company_clone?.id) {
            this.initializeProperties()
        }

        VueEvent.listen('inbox-filter-created', payload => {
            let index = this.getCollectionIndexFromType(payload.data.type)

            // sanity check: should not be null
            if (index >= 0) {
                this.categories[index].data.push(payload.data)

                this.notification('success', payload.message)
            }
        })

        VueEvent.listen('inbox-filter-updated', payload => {
            let index = this.getCollectionIndexFromType(payload.data.type)

            // sanity check: should not be null
            if (index >= 0) {
                let itemIndex = this.categories[index].data.findIndex(item => item.id === payload.data.id)

                this.categories[index].data.splice(itemIndex, 1, payload.data)

                this.notification('success', payload.message)
            }
        })

        this.fetchFilters()
    },

    methods: {
        ...mapActions('cache', ['setCurrentCompany']),
        /**
         * API methods
         */
        fetchFilters() {
            this.loading = true

            let params = {
                type: null,
                scope: 'company'
            }

            this.$APIV2.Filter.fetchFilters(params)
                .then(res => {
                    // sanity check: make sure the company property is present. A successful request
                    if (res.data.data && res.data.data.company) {
                        let filters = res.data.data.company
                        filters.forEach(filter => {
                            let index = this.getCollectionIndexFromType(filter.type)

                            // sanity check: should not be null
                            if (index >= 0) {
                                this.categories[index].data.push(filter)
                            }
                        })
                    }

                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    console.log(err)
                })
        },

        deleteFilter(filter) {
            this.$confirm(`Are you sure you want to delete this filter? This action is irreversible.`, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'w-50 fixed',
                confirmButtonClass: 'el-button--danger',
                type: 'warning'
            }).then(() => {
                this.loading = true

                this.$APIV2.Filter.deleteFiler(filter.id)
                    .then(res => {
                        let index = this.getCollectionIndexFromType(filter.type)

                        // sanity check: should not be null
                        if (index >= 0) {
                            let itemIndex = this.categories[index].data.findIndex(item => item.id === filter.id)

                            if (itemIndex > -1) {
                                this.categories[index].data.splice(itemIndex, 1)
                            }

                            this.notification('success', res.data.message)
                            this.loading = false
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        console.log(err)
                    })
            }).catch(() => {
            });
        },

        onChangeContactStatusControlEnabled: debounce(function () {
            if (this.hasPermissionTo('update company')) {
                this.updateCompanySettings()
            }
        }, 1000),

        updateCompanySettings() {
            this.loading = true
            let company = cloneDeep(this.company_clone)

            axios.patch('/api/v1/company/' + this.company_clone.id, company).then(res => {
                company = res.data
                this.setCurrentCompany(company)

                this.$notify({
                    offset: 95,
                    title: 'Account',
                    message: 'Account has been updated successfully.',
                    type: 'success',
                    showClose: true,
                })
            }).catch(err => {
                this.$root.handleErrors(err.response)
                if (err.response && err.response.data && err.response.data.company) {
                    company = err.response.data.company
                    this.setCurrentCompany(company)
                }
            }).finally(() => {
                this.loading = false
            })
        },

        /**
         * Helper methods
         */
        getCollectionIndexFromType(type) {
            return this.categories.findIndex(category => category.type === type)
        },

        notification(type, message) {
            this.$notify({
                offset: 75,
                title: 'Filter',
                message: message,
                type: type,
                dangerouslyUseHTMLString: true,
                showClose: true,
                duration: 5000
            })
        },

        initializeProperties() {
            this.contact_status_control_enabled = this.company_clone.contact_status_control_enabled
        },

        /**
         * Dialog manipulator methods
         */
        showForm(type, model = null) {
            this.show_form = true
            this.selected_type = type
            this.selected_model = cloneDeep(model)
        },

        closeForm() {
            this.selected_model = null
            this.selected_type = null
        },
    },

    beforeDestroy() {
        // destroy listeners
        VueEvent.unlisten('inbox-filter-created');
        VueEvent.unlisten('inbox-filter-updated');
    },
}
</script>
