var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    { attrs: { prop: _vm.id } },
    [
      _c("span", { staticClass: "page-anchor", attrs: { id: _vm.id } }),
      _vm._v(" "),
      _c("div", { staticClass: "form-label" }, [
        _c("h5", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("small", [_vm._t("default")], 2),
      ]),
      _vm._v(" "),
      _c(
        "el-select",
        {
          staticClass: "w-full",
          on: {
            change: function ($event) {
              return _vm.$emit("change", _vm.selected)
            },
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        _vm._l(_vm.options, function (value, label) {
          return _c("el-option", {
            key: value,
            attrs: { value: value, label: label },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }