<template>
    <div>
        <div class="row b-b">
            <div class="col-12 p-3">
                <company-form-token></company-form-token>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-3">
                <div class="mb-2">
                    <strong class="integration-api-titles">Lookup up a contact using phone number</strong>
                </div>

                <p class="text-md">
                    <span class="text-dark-greenish _600">GET /api/v1/webhook/contact/phone-number</span>
                </p>

                <p>
                    <strong>Required Query Parameters:</strong>
                    <br>
                    <span class="text-dark-greenish _600">api_token</span>,
                    <span class="text-dark-greenish _600">phone_number</span>
                </p>

                <p>
                    <strong>Example GET URL:</strong>
                    <br>
                    <span class="text-dark-greenish _600">{{ statics.domain | fixDomain }}/api/v1/webhook/contact/phone-number?api_token={{companyToken}}&phone_number=8181234567</span>
                </p>

                <p class="mb-2">
                    <strong>Returns an HTTP 200 response if a contact is found with the contact information</strong>
                </p>

                <p class="mb-2">
                    <strong>Returns an HTTP 400 on failure with a detailed response, Example failure response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "error": "Phone number is empty or invalid."
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 404 on failure with a detailed response, Example failure response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "error": "Contact not found."
}</code></pre>

                <p class="mb-2">
                    <strong>cURL Example:</strong>
                </p>
                <pre class="mb-3"
                     v-highlightjs
                     :key="renderRefreshToken"
                     v-if="!loading_whitelabel"><code class="bash">curl --location --request GET '{{
                        statics.domain | fixDomain
                    }}/api/v1/webhook/contact/phone-number?api_token={{ companyToken }}&phone_number=8181234567'</code></pre>

                <p class="_600"
                   v-if="!statics.whitelabel">
                    If you need more API functions, please contact our support at support@aloware.com.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {company_token_mixin} from '../mixins'
import CompanyFormToken from './account/company-form-token'

export default {
    mixins: [
        company_token_mixin
    ],

    components: {
        CompanyFormToken
    },

    data() {
        return {
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
        }
    },
    created() {
        this.getStatics()
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        }
    }
}
</script>
