var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "35vw",
            top: "10vh",
            "append-to-body": "",
            title: _vm.title,
            "before-close": _vm.closeDialog,
            visible: _vm.show_dialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_dialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "container-account-imports py-1",
            },
            [
              _c("div", { staticClass: "d-flex mb-3" }, [
                _c(
                  "code",
                  _vm._l(_vm.logs, function (log, index) {
                    return _c("span", { key: index, staticClass: "d-block" }, [
                      _vm._v(
                        "\n                      " +
                          _vm._s(log) +
                          "\n                    "
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.cursor !== null
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", loading: _vm.loading },
                      on: { click: _vm.fetchLogs },
                    },
                    [_vm._v("\n              Show older logs\n            ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }