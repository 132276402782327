var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show_extension_wizard,
            "before-close": _vm.beforeCloseModal,
            "custom-class": "wizard-dialog dialog-no-border",
            "data-testid": "user-extension-wizard-dialog",
            top: "10vh",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_extension_wizard = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("h5", { staticClass: "mb-0" }, [
              _vm._v("\n                Add Extension\n            "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wizard" }, [
            _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "extension_number",
                    attrs: {
                      "label-width": "200px",
                      "label-position": "top",
                      model: _vm.extension,
                      rules: _vm.rules,
                      "data-testid": "wizard-add-extension-form",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.addExtension.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Label", prop: "label" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Label Name",
                                  "data-testid": "add-extension-label-input",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.preValidateForm(
                                      "extension_number"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.extension.label,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.extension, "label", $$v)
                                  },
                                  expression: "extension.label",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Phone Number",
                                prop: "phone_number",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "+18185005050",
                                  "data-testid":
                                    "add-extension-phone-number-input",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.preValidateForm(
                                      "extension_number"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.extension.phone_number,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.extension, "phone_number", $$v)
                                  },
                                  expression: "extension.phone_number",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          disabled: _vm.loading_btn || !_vm.validated,
                          "data-testid": "create-extension-button",
                        },
                        on: { click: _vm.addExtension },
                      },
                      [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading_btn,
                                expression: "loading_btn",
                              },
                            ],
                            staticClass: "material-icons loader",
                          },
                          [_vm._v("")]
                        ),
                        _vm._v(" "),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.loading_btn,
                              expression: "!loading_btn",
                            },
                          ],
                          staticClass: "fa fa-plus",
                        }),
                        _vm._v(
                          "\n                        Create Extension\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }