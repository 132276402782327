<template>
    <el-form v-if="is_secure_origin">
        <label class="el-form-item__label full-width ml-2 font-weight-bold">
            Record an audio file:
        </label>
        <div class="audio-recorder-container">
            <div class="border"
                 :class="{'float-left' : !responsive}"
                 :style="[responsive ? { width: '100%' } : {}]">
                <el-form-item class="h-100">
                    <div v-if="recorded_audio === null"
                         class="col-12 audio-recorder h-100">
                        <div class="w-100 mt-1 d-flex justify-content-center align-items-end"
                             v-if="!buttons_toggle">
                            <button class="btn btn-md greenish mr-2"
                                    :disabled="is_uploading"
                                    @click.prevent="startRecorder">
                                <i class="material-icons align-bottom">mic</i>
                            </button>
                        </div>
                        <div class="w-100 mt-1 d-flex justify-content-center align-items-end"
                             v-else>
                            <button class="btn btn-md red mr-2 active"
                                    :disabled="!enabled_recording || is_uploading"
                                    @click.prevent="stopRecorder">
                                <i class="material-icons align-bottom">mic</i>
                            </button>
                        </div>
                        <div class="w-100 h-25 d-flex justify-content-center align-items-start"
                             v-if="!buttons_toggle">
                            <label class="el-form-item__label full-width mt-2 font-weight-bold"
                                   :class="{'ml-2': is_popover, 'mr-2': !is_popover}">
                                Press to record your voice
                            </label>
                        </div>
                        <div class="w-100 h-25 d-flex justify-content-center align-items-start"
                             v-else>
                            <label class="el-form-item__label full-width mt-2 font-weight-bold"
                                   :class="{'ml-2': is_popover, 'mr-2': !is_popover}">
                                {{ hours }}:{{ minutes }}:{{ seconds }}
                            </label>
                        </div>
                    </div>
                    <div v-else
                         class="h-100 col-12 p-0 mt-4 text-center">
                        <audio class="mr-2"
                               style="width:250px;"
                               controls
                               :disabled="is_uploading">
                            <source :src="recorded_audio.src">
                            Your browser does not support the audio element.
                        </audio>
                        <div class="d-flex justify-content-center align-items-center">
                            <button class="btn btn-sm red mr-3"
                                    :disabled="!enabled_recording || is_uploading"
                                    @click.prevent="removeRecordedAudio">
                                <i class="fa fa-sync-alt"></i>
                                <span>Change</span>
                            </button>
                            <button class="btn btn-sm blue align-top"
                                    :disabled="!enabled_recording || is_uploading"
                                    @click.prevent="uploadAudio()">
                                <i class="fa fa-spin fa-spinner"
                                   v-show="is_uploading"></i>
                                <i class="material-icons"
                                   v-if="upload_text === 'Upload'"
                                   v-show="!is_uploading">
                                    cloud_upload</i> {{ upload_text }}
                            </button>
                        </div>
                    </div>
                </el-form-item>
            </div>
            <div class="p-a"
                 :class="{'float-left' : !responsive}"
                 :style="[responsive ? { width: '100%', height: '70px'} : {}]">
                <div class="h-100 d-flex justify-content-center align-items-center">
                    <h4>OR</h4>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
    import Microphone from '@gkt/microphone'

    export default {
        props: {
            action: {
                required: true,
                type: String
            },
            upload_type: {
                required: true,
                type: String
            },
            upload_text: {
                required: false,
                type: String,
                default: 'Upload'
            },
            is_popover: {
                required: false,
                type: Boolean,
                default: false
            },
            no_margin_right: {
                required: false,
                type: Boolean,
                default: false
            },
            responsive: {
                required: false,
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                audio_blob: null,
                mic: null,
                buttons_toggle: false,
                enabled_recording: false,
                recorded_audio: null,
                stream: null,
                is_uploading: false,
                minutes: '00',
                seconds: '00',
                hours: '00',
                per_second_size: 56,
                time_started: null,
                time_interval: null,
                is_secure_origin: false
            }
        },

        created() {
            if (typeof navigator.mediaDevices !== 'undefined') {
                this.is_secure_origin = true
            }
        },

        methods: {
            startRecorder() {
                navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
                    this.stream = stream
                    this.buttons_toggle = !this.buttons_toggle
                    this.recorded_audio = null
                    this.audio_blob = null
                    this.enabled_recording = true
                    this.mic = new Microphone(stream)
                    this.mic.start()
                    this.startDuration()
                }).catch(err => {
                    console.log(err)
                    this.$notify({
                        offset: 95,
                        title: 'Audio Recorder',
                        message: 'Microphone is blocked by user.',
                        type: 'warning',
                        showClose: true,
                    })
                    this.is_uploading = false
                })
            },

            stopRecorder() {
                this.clearRecorder()
                this.storeAudio()
            },

            clearRecorder() {
                this.buttons_toggle = !this.buttons_toggle
                if (this.mic !== null && this.stream !== null && this.stream.active) {
                    this.mic.stop()
                }
                if (this.stream !== null) {
                    this.stream.getTracks().forEach(track => track.stop())
                }
                if (this.time_interval !== null) {
                    clearInterval(this.time_interval)
                }
            },

            startDuration() {
                this.resetDuration()
                let time_started = new Date().getTime()
                let estimated_file_size = 0
                this.time_interval = setInterval(() => {
                    let current_time = new Date().getTime()
                    let time_elapsed = new Date(current_time - time_started)
                    this.hours = _.padStart(time_elapsed.getUTCHours(), 2, '0');
                    this.minutes = _.padStart(time_elapsed.getUTCMinutes(), 2, '0');
                    this.seconds = _.padStart(time_elapsed.getUTCSeconds(), 2, '0');

                    // per_second_size is in kilobytes
                    estimated_file_size += this.per_second_size
                    // once the 8MB limit is reached, stop the recording.
                    if ((estimated_file_size + this.per_second_size) >= 8000) {
                        this.$notify({
                            offset: 95,
                            title: 'Audio Recorder',
                            message: 'The audio recording has reached the limit of 8MB. Recording is automatically stopped.',
                            type: 'warning',
                            showClose: true,
                        })
                        this.stopRecorder()
                    }
                }, 1000)
            },

            resetDuration() {
                this.hours = '00'
                this.minutes = '00'
                this.seconds = '00'
            },

            storeAudio() {
                this.audio_blob = this.mic.export()
                let reader = new FileReader()
                let this_component = this
                reader.onload = function () {
                    let str = this.result
                    this_component.recorded_audio = new Audio(str)
                }
                reader.readAsDataURL(this.audio_blob)
            },

            removeRecordedAudio() {
                this.audio_blob = null
                this.recorded_audio = null
            },

            uploadAudio() {
                this.is_uploading = true
                let file_name = new Date().getTime() + '_recording_upload.wav'
                let data = new FormData()
                data.append("file", this.audio_blob, file_name);
                axios.post(this.action, data).then(res => {
                    this.$emit('applyUploadedAudio', {
                        upload_type: this.upload_type,
                        file_name: file_name,
                        name: res.data.name,
                        uuid: res.data.uuid,
                        uid: res.data.file_name,
                        id: res.data.id
                    })
                    this.recorded_audio = null
                    this.is_uploading = false
                }).catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.is_uploading = false
                })
            }
        },
        beforeDestroy() {
            this.clearRecorder()
        }
    }
</script>
