<template>
    <div v-loading="is_loading"
         class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <div class="card shadow-sm">
                    <div class="card-body">
                        <div class="position-relative">
                            <highcharts
                                :key="donut_chart_key"
                                :options="donut_chart_options"
                                ref="chart">
                            </highcharts>

                            <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); text-align: center">
                                <h1 class="mb-0"
                                    style="font-weight: 600;font-size: 30px">
                                    {{ (total_usage ? total_usage.total_credit : 0) | fixRounding | toCurrency }}
                                </h1>
                                <p class="mb-0">
                                    Total Cost
                                </p>
                            </div>
                        </div>

                        <div class="text-center mb-3">
                            <el-button
                                class="px-4"
                                type="success"
                                round
                                size="medium"
                                @click="$refs.usage_summary_dialog.show()">
                                <strong>Cost & Usage Summary</strong>
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="card">
                    <div class="card-body shadow-sm">
                        <div class="mb-3">
                            <el-radio-group
                                class="mr-2 align-self-center"
                                v-model="current_series_data_type"
                                @change="changeLineSeriesDataType">
                                <el-radio-button :label="'credit'" style="height: 41px">Cost</el-radio-button>
                                <el-radio-button :label="'quantity'" style="height: 41px">Usage</el-radio-button>
                            </el-radio-group>

                            <el-popover
                                class="align-self-center"
                                placement="bottom-start"
                                width="400"
                                trigger="click">
                                <div class="p-2">
                                    <div>
                                        <h6 class="mb-0">Resources</h6>
                                        <p>Choose the resources you want to cast in the line graph.</p>
                                    </div>
                                    <div
                                        v-if="series_items.length > 0"
                                        v-for="series_item in series_items"
                                        :key="series_item.key">
                                        <el-radio
                                            v-model="current_series_item"
                                            :label="series_item"
                                            @change="toggleSeriesItem(series_item)">
                                            <i class="fa fa-circle mr-2" :style="'color: ' + series_item.color"></i> {{ series_item.name }}
                                        </el-radio>
                                    </div>
                                    <div
                                        v-if="series_items.length <= 0"
                                        v-for="(resource, i) in resources"
                                        :key="i">
                                        <el-radio
                                            :disabled="true"
                                            v-model="resource.value"
                                            :label="resource">
                                            <i class="fa fa-circle mr-2" :style="'color: ' + resource.color"></i> {{ resource.name }}
                                        </el-radio>
                                    </div>
                                </div>

                                <el-button
                                    style="height: 41px"
                                    size="medium"
                                    slot="reference">
                                    <span v-if="current_series_item">
                                        <i class="fa fa-circle mr-2" :style="'color: ' + current_series_item.color"></i> {{ current_series_item.name }}
                                    </span>
                                    <span v-else>
                                        <i class="fa fa-circle mr-2" :style="'color: #00BF50'"></i> Total
                                    </span>
                                    <i class="ml-2 fa fa-caret-down"></i>
                                </el-button>
                            </el-popover>
                        </div>
                        <div v-if="usages.labels.length > 1">
                            <highcharts
                                :key="line_chart_key"
                                :options="line_chart_options"
                                ref="chart">
                            </highcharts>
                        </div>
                        <div
                            v-else
                            class="text-center">
                            <div class="w-50 mx-auto">
                                <img-empty></img-empty>
                                <p class="lead">Data is not enough to render a line chart.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <company-usage-summary-dialog
            ref="usage_summary_dialog"
            :date_range="date_range"
            :usages="usages.data">
        </company-usage-summary-dialog>
    </div>
</template>

<script>
import ImgEmpty from "../../misc/img-empty"
import CompanyUsageSummaryDialog from "./company-usage-summary-dialog";

export default {
    name: "company-usage-graph",

    components: {CompanyUsageSummaryDialog, ImgEmpty},

    props: {
        date_range: {
            required: false
        }
    },

    data() {
        return {
            is_usage_summary_visible: false,
            filters: {},
            is_loading: false,
            line_chart_key: 1,
            usages: {
                labels: [],
                data: []
            },
            total_usage: null,
            resources: [],
            series_items: [],
            current_series_item: null,
            current_series_data_type: 'credit',
            line_chart_options: {
                exporting: {
                    enabled: false
                },

                chart: {
                    type: 'areaspline',
                },

                title: {
                    text: null
                },

                yAxis: {
                    title: {
                        text: 'Cost'
                    },
                    gridLineColor: 'transparent',
                    lineColor: 'transparent',
                    gridLineWidth: 0,
                    allowDecimals: false
                },

                xAxis: {
                    accessibility: {
                        rangeDescription: 'Range: 2010 to 2017'
                    },
                    gridLineWidth: 1,
                    gridLineColor: '#f6f6f6',
                    categories: ['Sep 1', 'Sep 4', 'Sep 5', 'Sep 8', 'Sep 18'],
                    min: 0.5,
                    tickInterval: 1,
                    maxPadding: 0,
                    endOnTick: false,
                    startOnTick: false,
                },

                legend: {
                    enabled: false
                },

                plotOptions: {
                    series: {
                        pointStart: 0,
                        tooltip: {
                            valuePrefix: '$'
                        }
                    },
                    areaspline: {
                        fillOpacity: 0.5
                    }
                },

                tooltip: {
                    shared: true
                },

                credits: {
                    enabled: false
                },

                series: [],

                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                }
            },
            donut_chart_key: 1,
            donut_chart_options: {
                exporting: {
                    enabled: false
                },
                noData: {
                    style: {
                        color: 'white'
                    }
                },
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: null,
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    pointFormat: '',
                    headerFormat: '<strong>{point.key}: {point.percentage:.1f}%</strong>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: false,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        },
                        events: {
                            click: function (event) {

                            }
                        }
                    },
                    series: {
                        animation: {
                            duration: 0
                        }
                    }
                },
                series: [{
                    name: 'Usage',
                    colorByPoint: true,
                    data: [],
                    innerSize: '92%'
                }]
            }
        }
    },

    created() {
        this.fetchGraphData()
        this.fetchUsageResources()
    },

    methods: {
        fetchGraphData() {
            this.is_loading = true
            this.prepareFilters()
            this.resetLineGraphData()

            axios.get(`/api/v1/company/usage/graph`, {
                params: this.filters
            }).then(res => {
                this.usages = res.data.usages

                if (this.usages) {
                    // render the x axis labels
                    this.setLineGraphCategories(this.usages.labels)
                    this.setLineGraphData(this.usages.data)
                    this.setDonutChartData(this.usages.data)
                    this.setLineGraphYTitle('Cost')

                    this.total_usage = this.usages.data?.total
                }

                this.is_loading = false
            })
        },

        fetchUsageResources() {
            axios.get(`/api/v1/company/usage/resources`).then(res => {
                this.resources = res.data.resources
            })
        },

        setLineGraphYTitle(title) {
            this.line_chart_options.yAxis.title.text = title
            this.line_chart_options.yAxis.labels = {
                format: this.current_series_data_type === 'credit' ? '${text}' : '{text}'
            }
        },

        setLineGraphCategories(categories) {
            this.line_chart_options.xAxis.categories = categories

            const length = categories.length
            if (length > 20) {
                // compute the tick interval
                this.line_chart_options.xAxis.tickInterval = Math.floor(length / 7)
            }

            // force the chart to rerender
            this.line_chart_key++
        },

        setLineGraphData(data, data_key = 'credit') {
            this.series_items = []
            this.line_chart_options.series = []

            for (let [resource, resource_data] of Object.entries(data)) {

                // check if resource is allowed to be rendered in the line graph
                if (!this.isAllowedSeriesItem(resource, resource_data, data_key)) {
                    continue
                }

                let is_total_resource = resource === 'total'
                let series_item = {
                    key: resource,
                    name: resource_data.name,
                    data: resource_data[data_key],
                    color: resource_data.color,
                    fillOpacity: 0.2,
                    marker: {
                        fillColor: resource_data.color,
                        lineWidth: 3,
                        lineColor: '#ffffff',
                        symbol: 'circle',
                        radius: 5
                    },
                    visible: is_total_resource,
                }

                // add to series items
                this.addLineGraphData(series_item, is_total_resource)
            }

            this.setCurrentSeriesItem(data_key)
            // force the chart to rerender
            this.line_chart_key++
        },

        isAllowedSeriesItem(resource, resource_data, data_key) {
            // total of usage quantity is not allowed
            if (resource === 'total' && data_key === 'quantity') {
                return false
            }

            // user cost & usage quantity is not allowed
            return resource !== 'user'
        },

        addLineGraphData(series_item, to_first_item = false) {
            this.line_chart_options.series.push(series_item)

            // push to first item in array
            if (to_first_item) {
                this.series_items.unshift(series_item)
            }
            // push to last
            else {
                this.series_items.push(series_item)
            }
        },

        setCurrentSeriesItem(data_key) {
            if (data_key === 'credit') {
                this.current_series_item = _.find(this.series_items, function (series_item) {
                    return series_item.key === 'total'
                })
            } else if (data_key === 'quantity') {
                this.current_series_item = _.head(this.series_items)
            }

            if (this.current_series_item) {
                this.toggleSeriesItem(this.current_series_item)
            }
        },

        setDonutChartData(data) {
            for (let [resource, resource_data] of Object.entries(data)) {
                if (resource_data.total_credit > 0 && resource !== 'total') {
                    this.donut_chart_options.series[0].data.push({
                        name: resource_data.name,
                        y: resource_data.total_credit,
                        color: resource_data.color,
                    })
                }
            }

            this.donut_chart_key++
        },

        resetLineGraphData() {
            this.series_items = []
            this.line_chart_options.series = []

            this.total_usage = null

            this.donut_chart_options.series[0].data = []

            this.line_chart_key++
        },

        prepareFilters() {
            if (Array.isArray(this.date_range)) {
                this.filters.from_date = this.date_range[0]
                this.filters.to_date = this.date_range[1]
            } else {
                this.filters.from_date = null
                this.filters.to_date = null
            }
        },

        toggleSeriesItem(series_item) {
            this.current_series_item = series_item
            this.series_items.forEach((series_item_inner) => {
                series_item_inner.visible = false
            })
            this.current_series_item.visible = true
        },

        changeLineSeriesDataType() {
            const is_credit = this.current_series_data_type === 'credit'
            const title = is_credit ? 'Amount' : 'Quantity'

            this.line_chart_options.plotOptions.series.tooltip.valuePrefix = is_credit ? '$' : ''

            this.setLineGraphYTitle(title)
            this.setLineGraphData(this.usages.data, this.current_series_data_type)
        },

    },

    watch: {
        'date_range': function (new_date_range) {
            this.fetchGraphData()
        }
    }
}
</script>
