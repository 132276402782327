export const LABELS = [
    {
        value: 0,
        label: 'Offline'
    }, {
        value: 1,
        label: 'Available'
    }, {
        value: 2,
        label: 'Busy'
    }, {
        value: 3,
        label: 'On break'
    }, {
        value: 5,
        label: 'Wrap-up',
        disabled: true
    }, {
        value: 4,
        label: 'On call',
        disabled: true
    }, {
        value: 6,
        label: 'Ringing',
        disabled: true
    }, {
        value: 7,
        label: 'Auto Dial',
        disabled: true
    }, {
        value: 8,
        label: 'Sentry',
        disabled: true
    }
]
