var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column h-100",
      class: ["summary-card-" + _vm.status],
    },
    [
      _c(
        "div",
        {
          staticClass:
            "card summary-card-hover h-100 d-flex justify-content-center",
          staticStyle: { "padding-bottom": "5px" },
        },
        [
          _vm.icons.length || _vm.title
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 p-1" }, [
                  _c(
                    "div",
                    { staticClass: "d-inline-block pt-3 pl-4 align-bottom" },
                    [
                      _vm.img_src
                        ? _c("img", {
                            staticClass: "w-24",
                            attrs: {
                              "data-testid":
                                "summary-report-big-donut-card-img",
                              src: _vm.img_src,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.icons, function (icon) {
                        return _vm.icons.length
                          ? _c(
                              "i",
                              {
                                staticClass: "material-icons",
                                staticStyle: { color: "#08C150" },
                              },
                              [_vm._v(_vm._s(icon))]
                            )
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-inline-block pl-2" }, [
                    _c("strong", { staticClass: "text-md" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 flex-equal-height" },
            [
              _c("highcharts", {
                ref: "highchart",
                staticClass: "left-align-chart",
                staticStyle: { height: "228px" },
                attrs: {
                  options: _vm.graphOptions,
                  "data-testid": "summary-report-card-graph",
                  id: _vm.graph_id,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 flex-equal-height" },
            [
              _vm.extra_aggregate
                ? _c("summary-report-card", {
                    attrs: {
                      title: _vm.extra_aggregate.title,
                      donut_value: _vm.extra_aggregate.total,
                      data: _vm.extra_aggregate.data,
                      img_src: _vm.extra_aggregate.img_src,
                      is_donut: true,
                      loading: _vm.loading,
                      no_borders: true,
                      "data-testid": "summary-report-big-donut-card",
                      hide_tooltip: true,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }