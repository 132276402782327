var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading_url
    ? _c("iframe", { attrs: { title: "email", src: _vm.url } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }