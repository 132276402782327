<template>
    <div class="trial-banner d-flex align-items-center"
         v-if="!app_loading">
        <video-modal-activator :size="activatorSize"
                               v-if="!hasActivatorButton"
                               @open-modal="openModal"/>
        <slot name="activator"
              v-else
              @click="openModal"/>
        <div v-if="showModal"
            class="video-modal">
            <div class="overlay"
                @click="closeModal"></div>
            <div class="modal-content">
                <div class="modal-header">
                    <h3 v-if="title">{{ title }}</h3>
                    <button class="close-button" @click="closeModal">✖</button>
                </div>
                <div class="modal-body">
                    <iframe :src="videoUrl"
                            frameborder="0"
                            allowfullscreen>
                    </iframe>
                </div>
                <div v-if="notes"
                    class="modal-notes">
                    <p v-html="notes" />

                    <div class="modal-link"
                        v-if="learnMoreLink">
                        <a :href="learnMoreLink"
                        target="_blank"
                        rel="noopener noreferrer">
                            Learn more
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VueCookies from 'vue-cookies'
import VideoModalActivator from './video-modal-activator.vue'
import auth from '../auth'

export default {
    name: 'VideoModal',

    components: {
        VideoModalActivator
    },

    props: {
        title: {
            type: String,
            default: null
        },
        notes: {
            type: String,
            default: null
        },
        cookieName: {
            type: String,
            required: true
        },
        videoUrl: {
            type: String,
            required: true
        },
        learnMoreLink: {
            type: String,
            default: null
        },
        hasActivatorButton: {
            type: Boolean,
            default: false
        },
        activatorSize: {
            type: String,
            default: 'medium'
        },
        shouldShowInFirstVisit: {
            type: Boolean,
            default: true
        },
        useCustomActivatorAttr: {
            type: Boolean,
            default: false
        },
        customActivatorAttr: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            showModal: false,
            profile: auth.user.profile
        }
    },

    computed: {
        ...mapState([
              'app_loading',
              'shouldShowVideoModal',
              'isIntroVideoVisible'
        ]),

        parsedCookieName() {
            return `${this.cookieName}-${this.profile?.id}`
        }
    },

    watch: {
        shouldShowVideoModal () {
            if (this.useCustomActivatorAttr) {
                return
            }

            this.showModal = this.shouldShowVideoModal
        },

        customActivatorAttr () {
            if (!this.useCustomActivatorAttr) {
                return
            }

            this.showModal = this.customActivatorAttr
        }
    },

    created() {
        if (this.isIntroVideoVisible) {
            return
        }

        this.$cookies = VueCookies

        if (!this.$cookies.get(this.parsedCookieName) && this.shouldShowInFirstVisit) {
            this.showModal = true
            return this.setIsIntroVideoVisible(true)
        }

        return this.setIsIntroVideoVisible(null)
    },

    methods: {
        ...mapActions([
            'setShouldShowVideoModal',
            'setIsIntroVideoVisible'
        ]),

        closeModal() {
            this.showModal = false
            this.setShouldShowVideoModal(false)
            this.setIsIntroVideoVisible(null)
            this.$cookies.set(this.parsedCookieName, 'viewed', 3650) // Set cookie to expire in 10 years 
            this.$emit('close-modal')
        },
        openModal() {
            this.showModal = true
            this.setShouldShowVideoModal(true)
        }
    }
}
</script>

<style lang="scss">


</style>
