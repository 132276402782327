<template>
    <div v-if="communication && communication.contact">
        <p v-if="(communication.type === CommunicationTypes.CALL && !communication.user_id) || communication.type !== CommunicationTypes.CALL">
            <template v-if="communication.type === CommunicationTypes.CALL">
                {{ getSource(communication) }} <br />
            </template>
            You have {{ getSource(communication) ? 'an' : 'a new' }} {{ communicationType }} from
            <b>{{ decodeHTML(communication.contact.name) | fixContactName }} {{ communication.contact.phone_number | fixPhone }}</b>
            on
            <b v-if="getCampaign(communication.campaign_id)">{{ getCampaign(communication.campaign_id).name }}</b>
            line.
        </p>
        <p v-else-if="communication.type === CommunicationTypes.CALL && communication.user_id">
            {{ getSource(communication) }} <br />
            {{ communicationType }} from
            <b>{{ decodeHTML(communication.contact.name) | fixContactName }} {{ communication.contact.phone_number | fixPhone }}</b>
            on
            <b v-if="getCampaign(communication.campaign_id)">{{ getCampaign(communication.campaign_id).name }}</b>
            line has been answered by
            <b v-if="getUser(communication.user_id)">{{ getUser(communication.user_id).name }}.</b>
        </p>
        <template v-if="communication.type == CommunicationTypes.SMS && communication.attachments && communication.attachments.length > 0">
            Inbound MMS
        </template>
        <div class="mt-2"
             v-if="communication.type == CommunicationTypes.SMS">
            <p class="mt-3 mb-0 _400 break-word">{{ decodeHTML(communication.body ?? '') }}</p>
            <button class="btn rounded greenish btn-reply btn-sm pull-right">
                <i class="material-icons">reply</i>
                Reply
            </button>
        </div>
        <div class="mt-2"
             v-if="canAnswerCommunication(communication) && communication.company_id != 585">
            <template v-if="on_call">
                <button class="btn rounded deep-orange btn-hold-answer btn-xs pull-right">
                    Park & Answer
                </button>
                <button class="btn rounded greenish btn-hangup-answer btn-xs mr-2 pull-right">
                    Hang Up & Answer
                </button>
            </template>
            <button class="btn rounded greenish btn-answer btn-xs pull-right"
                    v-else>
                Answer
            </button>
            <button class="btn rounded danger btn-reject btn-xs mr-2 pull-right">
                Ignore
            </button>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {
        acl_mixin,
        html_mixin,
        user_info_mixin,
        communication_mixin
    } from '../mixins'
    import {mapState} from 'vuex'
    import * as CommunicationTypes from '../constants/communication-types'
    import * as CommunicationDispositionStatus from "../constants/communication-disposition-status"
    import * as CommunicationCurrentStatus from "../constants/communication-current-status"
    import he from 'he'

    export default {
        mixins: [
            acl_mixin,
            html_mixin,
            user_info_mixin,
            communication_mixin
        ],

        props: ['communication'],

        data() {
            return {
                auth: auth,
                CommunicationTypes,
                CommunicationCurrentStatus,
                CommunicationDispositionStatus
            }
        },

        computed: {
            ...mapState(['campaigns', 'ring_groups', 'on_call']),
            ...mapState('cache', ['current_company']),

            communicationType() {
                if (this.communication.type == CommunicationTypes.SMS) {
                    return 'text message'
                }

                return 'incoming call'
            }
        },

        methods: {
            getCampaign(id) {
                if (!id) {
                    return null
                }

                // reference the campaign object in communication if it has
                if (this.communication?.campaign) {
                    return this.communication.campaign
                }

                let found = this.campaigns.find(campaign => campaign.id === id)
                if (found) {
                    return found
                }

                return null
            },

            getRingGroup(id) {
                if (!id) {
                    return null
                }
                let found = this.ring_groups.find(ring_group => ring_group.id === id)
                if (found) {
                    return found
                }

                return null
            },

            canAnswerCommunication(communication) {
                const ring_group = this.getRingGroup(communication.ring_group_id)
                const current_user = this.getCurrentUser()
                const in_user_ring_group = current_user ? current_user.ring_group_ids.includes(communication.ring_group_id) : false
                let in_fishing_mode = ring_group ? ring_group.should_queue && ring_group.fishing_mode : false

                return this.isCommunicationCallable(communication)
                    && in_fishing_mode
                    && in_user_ring_group
            },

            isCommunicationCallable(communication) {
                return communication.current_status2 !== CommunicationCurrentStatus.CURRENT_STATUS_RINGALL_NEW
                    && communication.disposition_status2 === CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW
                    && communication.type === CommunicationTypes.CALL
                    && !communication.user_id
            },

            getCurrentUser() {
                return (this.auth.user && this.auth.user.profile) ? this.auth.user.profile : null;
            },

            decodeHTML(html) {
              return he.decode(html);
            },
        }
    }
</script>
