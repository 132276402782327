var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "px-3" }, [
    _c("div", { staticClass: "container-listing min-height-screen px-0" }, [
      _c("div", { staticClass: "container-header d-flex" }, [
        _c("div", { staticClass: "listing-heading" }, [
          _c("h3", { staticClass: "mb-0" }, [_vm._v("Usage History")]),
          _vm._v(" "),
          _vm.sub_heading
            ? _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.sub_heading) +
                    "\n                "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container-body" }, [
        _c(
          "div",
          { staticClass: "container-listing-tools d-flex flex-wrap mb-1" },
          [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-right": "10px" },
                    attrs: { size: "medium" },
                    on: { click: _vm.refresh },
                  },
                  [
                    _c("i", { staticClass: "fa fa-refresh" }),
                    _vm._v(" Refresh\n                    "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    ref: "selectFilter",
                    staticStyle: { width: "400px" },
                    attrs: {
                      multiple: "",
                      "collapse-tags": "",
                      "collapse-tags-tooltip": "",
                      filterable: "",
                      placeholder: "Filter by item",
                      clearable: true,
                      disabled: _vm.resource_filter_disabled,
                    },
                    on: {
                      change: _vm.itemFilterChanged,
                      clear: function ($event) {
                        _vm.filters.resources_names = null
                      },
                    },
                    model: {
                      value: _vm.filters.resources_names,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "resources_names", $$v)
                      },
                      expression: "filters.resources_names",
                    },
                  },
                  _vm._l(_vm.usage_resource_names, function (item) {
                    return _c("el-option", {
                      key: item.name,
                      attrs: { label: item.label, value: item.name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-auto" },
              [
                _vm.usage_paginator
                  ? _c("alo-simple-pagination", {
                      attrs: { pagination: _vm.usage_paginator },
                      on: {
                        "prev-click": _vm.prevPage,
                        "next-click": _vm.nextPage,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.is_usage_logs_loading,
                expression: "is_usage_logs_loading",
              },
            ],
            staticClass: "min-height-40-vh",
          },
          [
            _vm.usage_paginator.data.length <= 0
              ? _c("div", { staticClass: "text-center" }, [
                  _c(
                    "div",
                    { staticClass: "w-50 mx-auto py-5" },
                    [
                      _c("img-empty"),
                      _vm._v(" "),
                      _c("p", { staticClass: "lead" }, [
                        _vm._v("No usage logs in this date range"),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.usage_paginator.data.length > 0
              ? _c("table", { staticClass: "table table-sm" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.usage_paginator.data, function (usage, i) {
                      return _c(
                        "tr",
                        { key: i, class: _vm.getUsageRowClass(usage) },
                        [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("fixFullDateLocal")(usage.date))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(usage.resource_name))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("removeSign")(usage.quantity)) +
                                " " +
                                _vm._s(_vm._f("fixUnit")(usage.resource))
                            ),
                          ]),
                          _vm._v(" "),
                          usage.credit > 0
                            ? _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      _vm._f("fixRounding")(
                                        usage.unit_price,
                                        3,
                                        usage.resource_name
                                      )
                                    )
                                  )
                                ),
                              ])
                            : _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(
                                      _vm._f("fixRounding")(
                                        0,
                                        3,
                                        usage.resource_name
                                      )
                                    )
                                  )
                                ),
                              ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("fixCreditInfo")(
                                  _vm._f("toCurrency")(
                                    _vm._f("removeSign")(
                                      _vm._f("fixRounding")(usage.credit)
                                    )
                                  )
                                )
                              )
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("alo-simple-pagination", {
              attrs: { pagination: _vm.usage_paginator },
              on: { "prev-click": _vm.prevPage, "next-click": _vm.nextPage },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Item")]),
        _vm._v(" "),
        _c("th", [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th", [_vm._v("Unit Price (USD)")]),
        _vm._v(" "),
        _c("th", [_vm._v("Cost (USD)")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }