export default Object.freeze([
    {
        value: 'EIN',
        name: 'USA: Employer Identification Number (EIN)',
        country: 'US',
    },
    {
        value: 'CBN',
        name: 'Canada: Business Number (CBN)',
        country: 'CA',
    },
    {
        value: 'CN',
        name: 'United Kingdom: Company Number',
        country: 'GB',
    },
    {
        value: 'ACN',
        name: 'Australia: Company Number from ASIC (ACN)',
        country: 'AU',
    },
    {
        value: 'CIN',
        name: 'India: Corporate Identity Number',
        country: 'IN',
    },
    {
        value: 'VAT',
        name: 'Estonia: VAT Number',
        country: 'EE',
    },
    {
        value: 'VATRN',
        name: 'Romania: VAT Registration Number',
        country: 'RO',
    },
    {
        value: 'RN',
        name: 'Israel: Registration Number',
        country: 'IL',
    },
    {
        value: 'Other',
        name: 'Other',
    },
])
