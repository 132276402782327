<template>
    <div v-if="!filter_options.filters_only">
        <div class="nav-active-greenish">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="border border-right-0 rounded-top-left"
                       href=""
                       data-toggle="tab"
                       aria-expanded="false"
                       :class="filtersNavClass.tag"
                       :data-target="tag_selector.target"
                       @click="resetContactFiltersSelector">
                        By Tag
                    </a>
                </li>
                <li class="nav-item">
                    <a class="border border-right-0 rounded-0"
                       href=""
                       data-toggle="tab"
                       aria-expanded="true"
                       :class="filtersNavClass.filter"
                       :data-target="contact_filter.target"
                       @click="resetTagSelector">
                        Filter
                    </a>
                </li>
                <li class="nav-item">
                    <a class="border rounded-top-right"
                       href=""
                       data-toggle="tab"
                       aria-expanded="true"
                       v-if="shouldShowIntegrations"
                       :class="filtersNavClass.filter"
                       :data-target="integrations.target"
                       @click="resetSelectors">
                        Integrations
                    </a>
                </li>
                <el-popover ref="popover_contacts"
                            placement="right"
                            class="ml-2 p-0"
                            title="Contacts"
                            width="400"
                            trigger="hover"
                            content="Filter group of contacts that will receive the bulk message by using the tag selector.">
                </el-popover>
            </ul>
            <div class="tab-content p-a border">
                <div id="by-tags"
                     aria-expanded="false"
                     :class="filtersContainerClass.tag">
                    <tag-selector
                        :value="null"
                        :multiple="false"
                        :clearable="true"
                        :category_prop="tag_category"
                        @change="contactTagsGroupChanged">
                    </tag-selector>
                </div>
                <div id="by-filters"
                     class="clearfix"
                     aria-expanded="true"
                     :class="filtersContainerClass.filter">
                    <contact-advanced-filters-wrapper custom_class="pull-left"
                                                      :default_date_range="default_date_range"
                                                      :broadcast="true"
                                                      @changed="contactFilterChanged"
                                                      @resetFilters="resetContactFilters">
                    </contact-advanced-filters-wrapper>
                </div>
                <div id="by-integrations"
                     class="clearfix"
                     aria-expanded="true"
                     v-if="shouldShowIntegrations"
                     :class="filtersContainerClass.integration">
                    <el-form ref="add_hubspot_list"
                             label-width="100px"
                             label-position="top"
                             :model="hubspot_list"
                             :rules="rules_hubspot_list"
                             v-if="hubspotEnabled">
                        <el-form-item prop="list_id"
                                      label="Select a HubSpot list"
                                      class="mb-3">
                            <hubspot-list-selector v-model="hubspot_list.list_id"
                                                   :value="hubspot_list.list_id"
                                                   @selectedList="selectedListChanged">
                            </hubspot-list-selector>
                        </el-form-item>
                    </el-form>

                    <el-form ref="add_zoho_view"
                             label-width="100px"
                             label-position="top"
                             :model="zoho_view"
                             :rules="rules_zoho_view"
                             v-if="zohoEnabled">
                        <el-form-item prop="view_id"
                                      label="Select a Zoho custom view"
                                      class="mb-3">
                            <zoho-view-selector v-model="zoho_view.view_id"
                                                :value="zoho_view.view_id"
                                                @selectedView="selectedViewChanged">
                            </zoho-view-selector>
                        </el-form-item>
                    </el-form>
                  <el-form ref="add_pipedrive_filter"
                           label-width="100px"
                           label-position="top"
                           :model="pipedrive_filter"
                           :rules="rules_pipedrive_filter"
                           v-if="pipedriveEnabled">
                    <el-form-item prop="filter_id"
                                  label="Select a Pipedrive list"
                                  class="mb-3">
                      <pipedrive-filter-selector v-model="pipedrive_filter.filter_id"
                                          :value="pipedrive_filter.filter_id"
                                          @selectedFilter="selectedFilterChanged">
                      </pipedrive-filter-selector>
                    </el-form-item>
                  </el-form>
                </div>
            </div>
        </div>
        <span class="form-hover-help"
              v-popover:popover_contacts>
            What is this?
        </span>
    </div>
    <div v-else
         class="row">
        <div class="col-12">
            <contact-advanced-filters-wrapper custom_class="pull-left"
                                              :hide_reset_filter="true"
                                              :broadcast="true"
                                              :default_date_range="default_date_range"
                                              @changed="contactFilterChanged"
                                              @resetFilters="resetContactFilters">
            </contact-advanced-filters-wrapper>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {
    acl_mixin,
    form_validation_mixin
} from "../mixins"
import PipedriveFilterSelector from './pipedrive-filter-selector'

export default {
  components: { PipedriveFilterSelector },
  mixins: [
        acl_mixin,
        form_validation_mixin
    ],

    props: {
        show_integrations: {
            required: false,
            type: Boolean,
            default: false
        },

        tag_category: {
            required: false,
            type: Number,
            default: null
        }
    },

    data() {
        return {
            tag_selector: {
                disabled: false,
                active: true,
                target: '#by-tags'
            },
            contact_filter: {
                disabled: false,
                active: false,
                target: '#by-filters'
            },
            integrations: {
                disabled: false,
                active: false,
                target: '#by-integrations'
            },
            default_date_range: null,
            hubspot_list: {
                list_id: null,
                raw_data: null
            },
            rules_hubspot_list: {
                list_id: [
                    {
                        required: true,
                        message: 'Please select a HubSpot list',
                        trigger: 'change'
                    }
                ]
            },
            zoho_view: {
                view_id: null,
                raw_data: null
            },
            rules_zoho_view: {
                view_id: [
                    {
                        required: true,
                        message: 'Please select a Zoho custom view',
                        trigger: 'change'
                    }
                ]
            },
          pipedrive_filter: {
              filter_id: null,
              raw_data: null
          },
          rules_pipedrive_filter: {
              filter_id: [
                  {
                      required: true,
                      message: 'Please select a Pipedrive filter',
                      trigger: 'change'
                  }
              ]
          },
        }
    },

    computed: {
        ...mapState(['filter', 'filter_options']),
        ...mapState('cache', ['current_company']),

        filtersNavClass() {
            return {
                tag: {
                    'disabled': this.tag_selector.disabled,
                    'nav-link': true,
                    'active': this.tag_selector.active
                },
                filter: {
                    'disabled': this.contact_filter.disabled,
                    'nav-link': true,
                    'active': this.contact_filter.active
                },
                integration: {
                    'disabled': this.integrations.disabled,
                    'nav-link': true,
                    'active': this.integrations.active
                },
            }
        },

        filtersContainerClass() {
            return {
                tag: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'active': true,
                    'width-400': this.filter_options.force_size !== 'small'
                },
                filter: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true
                },
                integration: {
                    'tab-pane': true,
                    'animated': true,
                    'fadeIn': true,
                    'text-muted': true,
                    'width-400': true
                }
            }
        },

        hubspotEnabled() {
            if (this.current_company &&
                this.current_company.hubspot_integration_enabled) {
                return true
            }

            return false
        },

        zohoEnabled() {
            if (this.current_company &&
                this.current_company.zoho_integration_enabled) {
                return true
            }

            return false
        },

        pipedriveEnabled() {
            if (this.current_company &&
                this.current_company.pipedrive_integration_enabled) {
              return true
            }

            return false
        },

        shouldShowIntegrations() {
            return this.show_integrations
                && (this.hubspotEnabled || this.zohoEnabled || this.pipedriveEnabled)
        }
    },

    mounted() {
        this.default_date_range = 'All Time - nochange'
    },

    methods: {
        resetTagSelector() {
            this.resetIntegrationsSelector()
            this.$emit('resetTagSelector')
        },

        resetContactFiltersSelector() {
            this.resetIntegrationsSelector()
            this.$emit('resetContactFiltersSelector')
        },

        resetIntegrationsSelector() {
            this.resetAddHubspotList()
            this.resetAddZohoView()
            this.$emit('resetIntegrationsSelector')
        },

        resetSelectors() {
            this.resetTagSelector()
            this.resetContactFiltersSelector()
        },

        contactTagsGroupChanged(tags) {
            this.$emit('contactTagsGroupChanged', tags)
        },

        contactFilterChanged(val) {
            if (!val) {
                this.resetContactFiltersSelector()
            }
        },

        resetAddHubspotList() {
            if (this.hubspotEnabled) {
                this.hubspot_list.list_id = null
                this.hubspot_list.raw_data = null
                this.resetForm('add_hubspot_list')
            }
        },

        resetAddZohoView() {
            if (this.zohoEnabled) {
                this.zoho_view.view_id = null
                this.zoho_view.raw_data = null
                this.resetForm('add_zoho_view')
            }
        },

        resetAddPipedriveFilter() {
          if (this.pipedriveEnabled) {
            this.pipedrive_filter.filter_id = null
            this.pipedrive_filter.raw_data = null
            this.resetForm('add_pipedrive_filter')
          }
        },

        selectedListChanged(list) {
            this.hubspot_list.list_id = list ? list.listId : null
            this.hubspot_list.raw_data = list ? list : null
            this.$emit('hubspotListChanged', this.hubspot_list)
        },

        selectedViewChanged(view) {
            this.zoho_view.view_id = view ? view.id : null
            this.zoho_view.raw_data = view ? view : null
            this.$emit('zohoViewChanged', this.zoho_view)
        },

        selectedFilterChanged(filter) {
            this.pipedrive_filter.filter_id = filter ? filter.id : null
            this.pipedrive_filter.raw_data = filter ? filter : null
            this.$emit('pipedriveFilterChanged', this.pipedrive_filter)
        },

        resetContactFilters() {
            this.$emit('resetFilters')
        }
    }
}
</script>
