var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.label
        ? _c("label", { staticClass: "p-0 m-0 _500" }, [
            _vm._v("\n        " + _vm._s(_vm.label) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-select",
        {
          staticClass: "w-full",
          attrs: {
            "default-first-option": _vm.default_first,
            placeholder: _vm.placeholder,
            autocomplete: "off",
            clearable: "",
            filterable: "",
            "collapse-tags": _vm.collapsable,
          },
          on: { clear: _vm.ivrChange, change: _vm.ivrChange },
          model: {
            value: _vm.ivr_id,
            callback: function ($$v) {
              _vm.ivr_id = $$v
            },
            expression: "ivr_id",
          },
        },
        _vm._l(_vm.ivr_list, function (ivr) {
          return _c(
            "el-option",
            { key: ivr.id, attrs: { label: ivr.name, value: ivr.id } },
            [_c("span", [_vm._v(_vm._s(ivr.name))])]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }