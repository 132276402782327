var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-form", { attrs: { model: _vm.chatbot } }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "el-form-item",
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", { staticClass: "text-dark" }, [_vm._v("Name (*)")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                        Specify a name for this chatbot.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.chatbot.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.chatbot, "name", $$v)
                  },
                  expression: "chatbot.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "el-form-item",
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", { staticClass: "text-dark" }, [_vm._v("Lines (*)")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                        Select 1 or multiple lines to connect to this chatbot.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w-100",
                  attrs: {
                    multiple: "",
                    filterable: "",
                    clearable: "",
                    placeholder: "Select lines",
                  },
                  model: {
                    value: _vm.selected_campaigns,
                    callback: function ($$v) {
                      _vm.selected_campaigns = $$v
                    },
                    expression: "selected_campaigns",
                  },
                },
                _vm._l(_vm.lines, function (line) {
                  return _c("el-option", {
                    key: line.id,
                    attrs: { label: line.name, value: line.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "el-form-item",
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", { staticClass: "text-dark" }, [_vm._v("Description")]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { type: "textarea", rows: 2 },
                model: {
                  value: _vm.chatbot.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.chatbot, "description", $$v)
                  },
                  expression: "chatbot.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col" },
        [
          _vm.chatbot.use_case === _vm.AloAi.USE_CASE_SALES
            ? _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", { staticClass: "text-dark" }, [
                      _vm._v("Opener (*)"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.chatbot.opener,
                      callback: function ($$v) {
                        _vm.$set(_vm.chatbot, "opener", $$v)
                      },
                      expression: "chatbot.opener",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    { attrs: { content: "Add variable", placement: "top" } },
                    [
                      _c("variables", {
                        on: { "variable-selected": _vm.variableSelected },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "el-form-item",
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", { staticClass: "text-dark" }, [
                  _vm._v("Use case (*)"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w-100",
                  model: {
                    value: _vm.chatbot.use_case,
                    callback: function ($$v) {
                      _vm.$set(_vm.chatbot, "use_case", $$v)
                    },
                    expression: "chatbot.use_case",
                  },
                },
                _vm._l(_vm.useCases, function (use_case) {
                  return _c("el-option", {
                    key: use_case.value,
                    attrs: { label: use_case.name, value: use_case.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "el-form-item",
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", { staticClass: "text-dark" }, [_vm._v("Model (*)")]),
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w-100",
                  model: {
                    value: _vm.chatbot.model,
                    callback: function ($$v) {
                      _vm.$set(_vm.chatbot, "model", $$v)
                    },
                    expression: "chatbot.model",
                  },
                },
                _vm._l(_vm.models, function (model) {
                  return _c("el-option", {
                    key: model.value,
                    attrs: { label: model.name, value: model.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "el-form-item",
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", { staticClass: "text-dark" }, [_vm._v("TTL (*)")]),
              ]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w-100",
                attrs: { min: 1, max: 20160, precision: 0, controls: false },
                model: {
                  value: _vm.chatbot.ttl,
                  callback: function ($$v) {
                    _vm.$set(_vm.chatbot, "ttl", $$v)
                  },
                  expression: "chatbot.ttl",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }