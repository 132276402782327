<template>
    <div v-loading="loading">
        <el-form
            :model="calendar_settings"
            ref="calendarSettingsForm"
            @submit.prevent.native="submitForm('companySettingsForm')">
            <div>
                <div class="font-weight-bold">Automatically Set Contact Disposition Upon Appointment Creation</div>

                <el-radio-group
                    @change="autoSetOnCreationChanged"
                    v-model="calendar_settings.auto_set_on_creation_enabled"
                    class="mt-2">
                    <el-radio
                        :label="false"
                        class="w-100">
                        Disabled
                    </el-radio>
                    <el-radio :label="true">
                        Enabled
                        <div
                            v-if="calendar_settings.auto_set_on_creation_enabled"
                            class="d-flex align-items-center ml-4">
                            <span class="ml-1 mr-1">If appointment is created, change Contact Disposition to</span>
                            <el-form-item
                                :error="errors.first('disposition_on_creation_id')"
                                prop="disposition_on_creation_id"
                                class="mb-0 pb-0 ml-1">
                                <disposition-status-selector
                                    :value.sync="calendar_settings.disposition_on_creation_id"
                                    :is_disabled="!calendar_settings.auto_set_on_creation_enabled"
                                    :show_not_disposed="false"
                                    size="mini"
                                    clearable>
                                </disposition-status-selector>
                            </el-form-item>
                        </div>
                    </el-radio>
                </el-radio-group>

                <!-- There might be instances where the radio data fails in the validation, so we need to display the errors as well -->
                <span
                    v-if="errors.has('auto_set_on_creation_enabled')"
                    class="el-form-item__error">
                    {{ errors.first('auto_set_on_creation_enabled') }}
                </span>
            </div>

            <div class="mt-5">
                <div class="font-weight-bold">Automatically Set Contact Disposition if Appointment is set to Completed</div>

                <el-radio-group
                    @change="autoSetOnMissedChanged"
                    v-model="calendar_settings.auto_set_on_completed_enabled"
                    class="mt-2">
                    <el-radio
                        :label="false"
                        class="w-100">
                        Disabled
                    </el-radio>
                    <el-radio :label="true">
                        Enabled
                        <div
                            v-if="calendar_settings.auto_set_on_completed_enabled"
                            class="ml-4">
                            <div class="d-flex ml-1">
                                <span style="margin-top: 10px !important;">If: </span>
                                <ul>
                                    <li>
                                        <span>Contact Disposition is not set to</span>
                                        <el-form-item
                                            :error="errors.first('disposition_not_set_to_id')"
                                            prop="disposition_not_set_to_id"
                                            class="mb-0 pb-0 ml-2 d-inline-block">
                                            <disposition-status-selector
                                                :value.sync="calendar_settings.disposition_not_set_to_id"
                                                :is_disabled="!calendar_settings.auto_set_on_completed_enabled"
                                                :show_not_disposed="false"
                                                size="mini"
                                                clearable>
                                            </disposition-status-selector>
                                        </el-form-item>
                                    </li>

                                    <li class="mt-4">
                                        And the appointment date (day) has already occured
                                    </li>
                                </ul>
                            </div>

                            <div class="d-flex align-items-center mt-2">
                                <span>Then change the Contact Disposition to</span>
                                <el-form-item
                                    :error="errors.first('contact_disposition_on_completed_id')"
                                    prop="contact_disposition_on_completed_id"
                                    class="mb-0 pb-0 ml-2">
                                    <disposition-status-selector
                                        :value.sync="calendar_settings.contact_disposition_on_completed_id"
                                        :is_disabled="!calendar_settings.auto_set_on_completed_enabled"
                                        :show_not_disposed="false"
                                        size="mini"
                                        clearable>
                                    </disposition-status-selector>
                                </el-form-item>
                            </div>

                            <el-alert
                                :closable="false"
                                title="* Automation runs at midnight (based on account's timezone)"
                                type="success"
                                class="mt-3">
                            </el-alert>
                        </div>
                    </el-radio>
                </el-radio-group>

                <!-- There might be instances where the radio data fails in the validation, so we need to display the errors as well -->
                <span
                    v-if="errors.has('auto_set_on_completed_enabled')"
                    class="el-form-item__error">
                    {{ errors.first('auto_set_on_completed_enabled') }}
                </span>
            </div>
        </el-form>

        <el-button
            type="success"
            class="pull-left mt-5"
            :disabled="loading"
            @click="submitCalendarSettings">
            <i
                v-show="loading"
                class="material-icons loader pull-right">
                &#xE863;
            </i>
            Submit
        </el-button>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import DispositionStatusSelector from '../../components/disposition-status-selector'
    import ErrorBag from "../../ErrorBag"

    export default {
        name: "account-calendar-settings",

        components: {
            DispositionStatusSelector
        },

        data() {
            return {
                loading: false,
                calendar_settings: {},
                errors: new ErrorBag({})
            }
        },

        computed: {
            ...mapState('cache', ['current_company'])
        },

        created() {
            this.getDefaults()

            this.getCalendarSettings()
        },

        methods: {
            submitCalendarSettings() {
                this.errors.clearAll()

                if (this.calendar_settings.id) {
                    this.updateCalendarSettings()

                    return
                }

                this.createCalendarSettings()
            },

            createCalendarSettings() {
                this.loading = true

                axios.post(`/api/v1/company/${this.current_company.id}/calendar-setting`, this.calendar_settings)
                    .then(res => {
                        if (res.data.data) {
                            this.calendar_settings = res.data.data

                            this.$notify.success({
                                title: 'Calendar Settings',
                                message: res.data.message
                            })
                        }

                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err)

                        // report the validation error to UI fields
                        if (err.response.status === 422) {
                            this.$notify.error({
                                title: 'Calendar Settings',
                                message: 'Failed to create calendar setting.'
                            })

                            this.errors = new ErrorBag(err.response.data.errors)
                        }

                        this.loading = false
                    })
            },

            updateCalendarSettings() {
                this.loading = true

                axios.patch(`/api/v1/company/${this.current_company.id}/calendar-setting/${this.calendar_settings.id}`, this.calendar_settings)
                    .then(res => {
                        if (res.data.data) {
                            this.calendar_settings = res.data.data

                            this.$notify.success({
                                title: 'Calendar Settings',
                                message: res.data.message
                            })
                        }

                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err)

                        // report the validation error to UI fields
                        if (err.response.status === 422) {
                            this.$notify.error({
                                title: 'Calendar Settings',
                                message: 'Failed to update calendar setting.'
                            })

                            this.errors = new ErrorBag(err.response.data.errors)
                        }

                        this.loading = false
                    })
            },

            getCalendarSettings() {
                this.loading = true

                axios.get(`/api/v1/company/${this.current_company.id}/calendar-setting`)
                    .then(res => {
                        if (res.data.data && res.data.data.id) {
                            this.calendar_settings = res.data.data

                            this.$notify.success({
                                title: 'Calendar Settings',
                                message: res.data.message
                            })
                        }

                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err)

                        this.loading = false
                    })
            },

            getDefaults() {
                this.calendar_settings = {
                    id: null,
                    company_id: this.current_company.id,
                    auto_set_on_creation_enabled: false,
                    disposition_on_creation_id: null,
                    auto_set_on_completed_enabled: false,
                    disposition_not_set_to_id: null,
                    contact_disposition_on_completed_id: null
                }
            },

            autoSetOnCreationChanged() {
                if (this.$refs.calendarSettingsForm) {
                    this.$refs.calendarSettingsForm.clearValidate('disposition_on_creation_id')
                }
            },

            autoSetOnMissedChanged() {
                if (this.$refs.calendarSettingsForm) {
                    this.$refs.calendarSettingsForm.clearValidate('disposition_not_set_to_id')
                    this.$refs.calendarSettingsForm.clearValidate('contact_disposition_on_completed_id')
                }
            }
        }
    }
</script>
