var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message" },
    [
      _vm.communication.type !== undefined &&
      _vm.communication.type !== _vm.CommunicationTypes.SYSNOTE
        ? _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                placement: "top",
                disabled: !_vm.avatarTooltip(_vm.contact, _vm.communication),
                content: _vm.avatarTooltip(_vm.contact, _vm.communication),
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "w-40 avatar grey-300 m-b",
                  class: [
                    _vm.communication.direction ===
                    _vm.CommunicationDirection.INBOUND
                      ? "pull-left"
                      : "pull-right",
                  ],
                  style: _vm.avatarStyle(_vm.contact, _vm.communication),
                },
                [
                  _vm.communication.direction ===
                    _vm.CommunicationDirection.OUTBOUND && _vm.current_company
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("initials")(
                              _vm._f("fixContactName")(_vm.current_company.name)
                            )
                          )
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("initials")(
                              _vm._f("fixContactName")(_vm.contact.name)
                            )
                          )
                        ),
                      ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.communication.type === _vm.CommunicationTypes.SYSNOTE &&
      _vm.communication.body
        ? _c("div", { staticClass: "clear" }, [
            _c(
              "div",
              { staticClass: "pt-3 pb-3 m-b" },
              [
                _c(
                  "el-divider",
                  { attrs: { "content-position": "center" } },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.communication.body))]),
                    _vm._v(" "),
                    _vm.communication.user_id &&
                    _vm.getUser(_vm.communication.user_id).name.length
                      ? _c("span", [
                          _vm._v(
                            "\n                    by " +
                              _vm._s(
                                _vm.getUser(_vm.communication.user_id).name
                              ) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v("-")]),
                    _vm._v(" "),
                    _c("relative-datetime", {
                      attrs: { datetime: _vm.communication.created_at },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.communication.property !== undefined &&
      !_vm.excluded_audits.includes(_vm.communication.property) &&
      (_vm.generalAuditsConditions(_vm.communication) ||
        _vm.customAuditsConditions(_vm.communication) ||
        _vm.hasAuditNotes(_vm.communication))
        ? _c("div", { staticClass: "clear" }, [
            _c(
              "div",
              { staticClass: "pt-3 pb-3 m-b" },
              [
                _c(
                  "el-divider",
                  { attrs: { "content-position": "center" } },
                  [
                    _vm.hasAuditNotes(_vm.communication)
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.communication.notes) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.generalAuditsConditions(_vm.communication)
                      ? _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.generalAuditMessages(_vm.communication)
                            ),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.customAuditsConditions(_vm.communication)
                      ? _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.generateCustomAuditMessageWithNotes(
                                _vm.communication
                              )
                            ),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.communication.user_id &&
                    _vm.getUser(_vm.communication.user_id).name.length &&
                    _vm.showAuthor(_vm.communication)
                      ? _c("span", [
                          _vm._v(
                            "\n                    by " +
                              _vm._s(
                                _vm.getUser(_vm.communication.user_id).name
                              ) +
                              "\n                "
                          ),
                        ])
                      : _vm.showAuthor(_vm.communication)
                      ? _c("span", [
                          _vm._v(
                            "\n                    by System\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v("-")]),
                    _vm._v(" "),
                    _vm.communication.property
                      ? _c("relative-datetime", {
                          attrs: { datetime: _vm.communication.created_at },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _c(
            "div",
            {
              staticClass: "clear d-flex flex-column",
              class: [
                _vm.communication.direction ===
                _vm.CommunicationDirection.INBOUND
                  ? "align-items-start"
                  : "align-items-end text-right",
              ],
            },
            [
              (_vm.communication.type == _vm.CommunicationTypes.SMS ||
                (_vm.communication.type == _vm.CommunicationTypes.NOTE &&
                  _vm.communication.direction ===
                    _vm.CommunicationDirection.INBOUND)) &&
              (_vm.communication.body || _vm.communication.attachments)
                ? _c(
                    "el-badge",
                    {
                      staticClass: "item px-2 d-flex flex-column",
                      class: [
                        _vm.communication.direction ==
                        _vm.CommunicationDirection.INBOUND
                          ? "align-items-start"
                          : "align-items-end",
                      ],
                      attrs: { "is-dot": !_vm.communication.is_read },
                    },
                    [
                      _vm._l(
                        _vm.communication.attachments,
                        function (attachment, index) {
                          return _vm.communication.attachments &&
                            _vm.communication.attachments.length > 0
                            ? _c(
                                "div",
                                { key: index, staticClass: "px-2" },
                                [
                                  (attachment.mime_type &&
                                    _vm.isAttachmentImage(
                                      attachment.mime_type
                                    )) ||
                                  !attachment.mime_type
                                    ? _c("vue-load-image", [
                                        _c("img", {
                                          staticClass:
                                            "img-fluid d-block r-2x width-400",
                                          class: index > 0 ? "mb-1" : "",
                                          attrs: {
                                            slot: "image",
                                            src: attachment.url,
                                          },
                                          slot: "image",
                                        }),
                                        _vm._v(" "),
                                        _c("img", {
                                          attrs: {
                                            slot: "preloader",
                                            src: "/assets/images/loading.svg",
                                          },
                                          slot: "preloader",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "error" },
                                            slot: "error",
                                          },
                                          [
                                            _vm._v(
                                              "Error on loading the image attachment"
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  attachment.mime_type
                                    ? [
                                        _vm.isAttachmentAudio(
                                          attachment.mime_type
                                        )
                                          ? _c("div", [
                                              _c(
                                                "audio",
                                                {
                                                  staticClass: "audio-player",
                                                  attrs: { controls: "" },
                                                },
                                                [
                                                  _c("source", {
                                                    attrs: {
                                                      src: attachment.url,
                                                      type: attachment.mime_type,
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                            Your browser does not support the audio element.\n                        "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isAttachmentVideo(
                                          attachment.mime_type
                                        )
                                          ? _c("div", [
                                              _c(
                                                "video",
                                                {
                                                  staticClass: "rounded",
                                                  attrs: {
                                                    width: "320",
                                                    controls: "",
                                                  },
                                                },
                                                [
                                                  _c("source", {
                                                    attrs: {
                                                      src: attachment.url,
                                                      type: attachment.mime_type,
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                            Your browser does not support the video tag.\n                        "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isAttachmentText(
                                          attachment.mime_type
                                        ) ||
                                        _vm.isAttachmentApplication(
                                          attachment.mime_type
                                        )
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: attachment.url,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "p-2 text-center",
                                                  },
                                                  [
                                                    _c("figure", [
                                                      _c("img", {
                                                        attrs: {
                                                          height: "100",
                                                          width: "100",
                                                          src: "/assets/images/app-icons/file.svg",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("figcaption", [
                                                        _vm._v(
                                                          _vm._s(
                                                            attachment.name
                                                              ? attachment.name
                                                              : "Click Here To Download"
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e()
                        }
                      ),
                      _vm._v(" "),
                      _vm.communication.body
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "inline r-2x message-body effect7 mt-1",
                              class: _vm.getCommunicationClass,
                            },
                            [
                              _c("span", {
                                staticClass: "arrow pull-top",
                                class: [
                                  _vm.communication.direction ===
                                  _vm.CommunicationDirection.INBOUND
                                    ? "arrow-dker left"
                                    : "arrow-dker right",
                                ],
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "p-a p-y-sm handle-whitespace" },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "linkified",
                                          rawName: "v-linkified:options",
                                          value: { target: "_blank" },
                                          expression: "{ target: '_blank' }",
                                          arg: "options",
                                        },
                                      ],
                                    },
                                    [_vm._v(_vm._s(_vm.communication.body))]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.communication.type !== undefined &&
              ![
                _vm.CommunicationTypes.SMS,
                _vm.CommunicationTypes.SYSNOTE,
              ].includes(_vm.communication.type) &&
              ((_vm.communication.direction ===
                _vm.CommunicationDirection.INBOUND &&
                _vm.communication.type !== _vm.CommunicationTypes.NOTE) ||
                _vm.communication.direction !==
                  _vm.CommunicationDirection.INBOUND)
                ? _c("el-badge", { staticClass: "item width-400" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "inline r-2x message-body text-xs effect7 mt-1",
                        class: [
                          _vm.communication.direction ===
                          _vm.CommunicationDirection.INBOUND
                            ? "white"
                            : "white text-left",
                        ],
                      },
                      [
                        _c("span", {
                          staticClass: "arrow pull-top",
                          class: [
                            _vm.communication.direction ===
                            _vm.CommunicationDirection.INBOUND
                              ? "arrow-dker left"
                              : "arrow-dker right",
                          ],
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "p-y-sm" },
                          [
                            _c("communication-info", {
                              attrs: {
                                communication: _vm.communication,
                                contact: _vm.contact,
                                activity_mode: true,
                                campaign_id: _vm.campaign_id,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.markable(_vm.communication) && !_vm.communication.is_read
                ? _c(
                    "el-button",
                    {
                      staticClass:
                        "pl-2 p-y-sm inline text-blue mark-read _400",
                      attrs: { type: "text" },
                      on: { click: _vm.markAsRead },
                    },
                    [_vm._v("\n            Mark as read\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.markable(_vm.communication) && _vm.communication.is_read
                ? _c(
                    "el-button",
                    {
                      staticClass:
                        "pl-2 p-y-sm inline text-blue mark-read _400",
                      attrs: { type: "text" },
                      on: { click: _vm.markAsUnread },
                    },
                    [_vm._v("\n            Mark as unread\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.communication.type !== undefined &&
              _vm.communication.type !== _vm.CommunicationTypes.SYSNOTE
                ? _c(
                    "div",
                    {
                      staticClass: "text-xxs m-t-xs width-600 m-b",
                      class: [
                        _vm.communication.direction ===
                        _vm.CommunicationDirection.INBOUND
                          ? "ml-2"
                          : "mr-2",
                      ],
                    },
                    [
                      _vm.communication.direction ===
                      _vm.CommunicationDirection.INBOUND
                        ? [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Communication",
                                    params: {
                                      communication_id: _vm.communication.id,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons help text-blue",
                                  },
                                  [_vm._v("info")]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("relative-datetime", {
                        attrs: { datetime: _vm.communication.created_at },
                      }),
                      _vm._v(" "),
                      _vm.communication.direction ===
                      _vm.CommunicationDirection.INBOUND
                        ? _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n                from " +
                                _vm._s(
                                  _vm._f("fixPhone")(
                                    _vm.communication.lead_number
                                  )
                                ) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.direction ===
                        _vm.CommunicationDirection.INBOUND &&
                      _vm.communication.campaign_id &&
                      _vm.getCampaign(_vm.communication.campaign_id)
                        ? _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n                to " +
                                _vm._s(
                                  _vm.getCampaign(_vm.communication.campaign_id)
                                    .name
                                ) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.direction ===
                        _vm.CommunicationDirection.OUTBOUND &&
                      _vm.communication.campaign_id &&
                      _vm.getCampaign(_vm.communication.campaign_id)
                        ? _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n                from " +
                                _vm._s(
                                  _vm.getCampaign(_vm.communication.campaign_id)
                                    .name
                                ) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.direction ===
                      _vm.CommunicationDirection.OUTBOUND
                        ? _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n                to " +
                                _vm._s(
                                  _vm._f("fixPhone")(
                                    _vm.communication.lead_number
                                  )
                                ) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.direction ===
                        _vm.CommunicationDirection.OUTBOUND &&
                      _vm.communication.workflow_id &&
                      _vm.getWorkflow(_vm.communication.workflow_id)
                        ? _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n                sent by " +
                                _vm._s(
                                  _vm.getWorkflow(_vm.communication.workflow_id)
                                    .name
                                ) +
                                " sequence\n            "
                            ),
                          ])
                        : _vm.communication.direction ===
                            _vm.CommunicationDirection.OUTBOUND &&
                          _vm.communication.broadcast_id &&
                          _vm.getBroadcast(_vm.communication.broadcast_id)
                        ? _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n                sent by " +
                                _vm._s(
                                  _vm.getBroadcast(
                                    _vm.communication.broadcast_id
                                  ).name
                                ) +
                                " broadcast\n            "
                            ),
                          ])
                        : _vm.communication.direction ===
                            _vm.CommunicationDirection.OUTBOUND &&
                          _vm.communication.user_id &&
                          _vm.getUser(_vm.communication.user_id).name.length
                        ? _c(
                            "span",
                            { staticClass: "text-muted" },
                            [
                              _vm._v(
                                "\n                sent by " +
                                  _vm._s(
                                    _vm.getUser(_vm.communication.user_id).name
                                  ) +
                                  "\n                "
                              ),
                              _vm.communication.creator_type &&
                              _vm.communication.creator_type !=
                                _vm.CreatorTypes.CREATOR_TYPE_MANUAL
                                ? [
                                    _vm._v(
                                      "\n                    - " +
                                        _vm._s(
                                          _vm._f("translateCreatorTypeText")(
                                            _vm.communication.creator_type
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.communication.direction ===
                      _vm.CommunicationDirection.OUTBOUND
                        ? [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Communication",
                                    params: {
                                      communication_id: _vm.communication.id,
                                    },
                                  },
                                },
                              },
                              [
                                [
                                  _vm.CommunicationDispositionStatus
                                    .DISPOSITION_STATUS_FAILED_NEW,
                                  _vm.CommunicationDispositionStatus
                                    .DISPOSITION_STATUS_INVALID_NEW,
                                ].includes(
                                  _vm.communication.disposition_status2
                                )
                                  ? [
                                      _vm.communication.current_status2 !==
                                      _vm.CommunicationCurrentStatus
                                        .CURRENT_STATUS_COMPLETED_NEW
                                        ? _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons help text-red-500",
                                              attrs: {
                                                title: _vm._f("fixName")(
                                                  _vm._f(
                                                    "translateCurrentStatusText"
                                                  )(
                                                    _vm.communication
                                                      .current_status2
                                                  )
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            error\n                        "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons help text-red-500",
                                              attrs: {
                                                title: _vm._f("fixName")(
                                                  _vm._f(
                                                    "translateDispositionStatusText"
                                                  )(
                                                    _vm.communication
                                                      .disposition_status2
                                                  )
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            error\n                        "
                                              ),
                                            ]
                                          ),
                                    ]
                                  : [
                                      [
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_SMS_RECEIVED_NEW,
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_SMS_DELIVERED_NEW,
                                      ].includes(
                                        _vm.communication.current_status2
                                      )
                                        ? [
                                            _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "material-icons help text-bluish",
                                                attrs: {
                                                  title: _vm._f("fixName")(
                                                    _vm._f(
                                                      "translateCurrentStatusText"
                                                    )(
                                                      _vm.communication
                                                        .current_status2
                                                    )
                                                  ),
                                                },
                                              },
                                              [_vm._v("done_all")]
                                            ),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      [
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_SMS_SENT_NEW,
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_SMS_ACCEPTED_NEW,
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_INPROGRESS_NEW,
                                      ].includes(
                                        _vm.communication.current_status2
                                      )
                                        ? _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons help text-bluish",
                                              attrs: {
                                                title: _vm._f("fixName")(
                                                  _vm._f(
                                                    "translateCurrentStatusText"
                                                  )(
                                                    _vm.communication
                                                      .current_status2
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("done")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      [
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_COMPLETED_NEW,
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_SMS_QUEUED_NEW,
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_SMS_SENDING_NEW,
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_SMS_RECEIVING_NEW,
                                      ].includes(
                                        _vm.communication.current_status2
                                      )
                                        ? _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons help text-blue",
                                              attrs: {
                                                title: _vm._f("fixName")(
                                                  _vm._f(
                                                    "translateCurrentStatusText"
                                                  )(
                                                    _vm.communication
                                                      .current_status2
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("done")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      [
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_SMS_UNDELIVERED_NEW,
                                        _vm.CommunicationCurrentStatus
                                          .CURRENT_STATUS_SMS_FAILED_NEW,
                                      ].includes(
                                        _vm.communication.current_status2
                                      )
                                        ? _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons help text-red-500",
                                              attrs: {
                                                title: _vm._f("fixName")(
                                                  _vm._f(
                                                    "translateCurrentStatusText"
                                                  )(
                                                    _vm.communication
                                                      .current_status2
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("error")]
                                          )
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }