var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row nav-padding" }, [
    _c("label", { staticClass: "ml-2 help-text" }, [
      _vm._v(
        "\n        Scripts can be displayed to reps and agents while they\n        are on a call with a customer.\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "el-table",
          {
            staticClass: "w-full",
            attrs: {
              data: _vm.paginatedData,
              "default-sort": { prop: "id", order: "ascending" },
              fit: "",
              stripe: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "id", label: "ID", width: "60" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", { staticClass: "text-greyish" }, [
                        _vm._v(_vm._s(scope.row.id)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "title", label: "Title", width: "150" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Text", "min-width": "300" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.fixHtml(
                              _vm.$options.filters.truncate(scope.row.text, 100)
                            )
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { width: "150", align: "right" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [_c("script-dialog")]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm.hasPermissionTo("update script") &&
                      !scope.row.deleted_at
                        ? _c("div", { staticClass: "row mt-2" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("script-dialog", {
                                  attrs: { script_id: scope.row.id },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasPermissionTo("restore script") &&
                      scope.row.deleted_at
                        ? _c("div", { staticClass: "row mt-2" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-block warning",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.restoreScript(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm.loading_restore_script
                                    ? _c("i", {
                                        staticClass: "fa fa-spin fa-spinner",
                                      })
                                    : _c("i", { staticClass: "fa fa-history" }),
                                  _vm._v(
                                    "\n                                Restore\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasPermissionTo("archive script") &&
                      !scope.row.deleted_at
                        ? _c("div", { staticClass: "row mt-2" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-block danger",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.archiveScript(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm.loading_archive_script
                                    ? _c("i", {
                                        staticClass: "fa fa-spin fa-spinner",
                                      })
                                    : _c("i", { staticClass: "fa fa-trash" }),
                                  _vm._v(
                                    "\n                                Archive\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.pageCount >= 1
          ? _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c("el-pagination", {
                  staticClass: "pull-right",
                  attrs: {
                    background: "",
                    layout: "sizes, prev, pager, next",
                    "page-sizes": [10, 20, 50, 100, 250],
                    "page-size": 20,
                    "current-page": _vm.pagination.current_page,
                    total: _vm.dataCount,
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.pagination, "current_page", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.pagination, "current_page", $event)
                    },
                    "current-change": _vm.changePage,
                    "size-change": _vm.handleSizeChange,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "clearfix" }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }