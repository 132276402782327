var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-input",
    {
      ref: "input_copy",
      staticClass: "el-input-copy",
      attrs: { readonly: "" },
      nativeOn: {
        click: function ($event) {
          return _vm.copy.apply(null, arguments)
        },
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "suffix" }, [
        _c(
          "div",
          {
            staticClass: "input-suffix",
            class: _vm.copying ? "text-dark-greenish" : "",
          },
          [
            _c("i", { staticClass: "fa fa-copy" }),
            _vm._v(" "),
            _vm.copying ? _c("span", [_vm._v("Copied!")]) : _vm._e(),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }