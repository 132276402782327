var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-blank",
      attrs: {
        width: "45vw",
        top: "7vh",
        "append-to-body": "",
        "show-close": false,
        visible: _vm.visible,
      },
    },
    [
      _c(
        "el-form",
        {
          key: _vm.form_key,
          staticClass: "form-aloware",
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "container-listing rounded p-4" }, [
            _c("div", { staticClass: "container-header d-flex" }, [
              _vm.mode === "add"
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        !_vm.select_type
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr-3",
                                attrs: { size: "medium", round: "" },
                                on: { click: _vm.selectType },
                              },
                              [
                                _c("i", { staticClass: "fa fa-arrow-left" }),
                                _vm._v(
                                  "\n                            Change Type\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("h4", { staticClass: "listing-heading" }, [
                          _vm._v("Add New Step"),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.mode === "edit"
                ? _c("div", [
                    _c("h4", { staticClass: "listing-heading" }, [
                      _vm._v("Edit Step"),
                    ]),
                    _vm._v(" "),
                    _vm.sequence.id
                      ? _c("small", { staticClass: "text-grey" }, [
                          _vm._v(
                            "\n                        #" +
                              _vm._s(_vm.sequence.id) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-dialog-close",
                      attrs: { type: "text", size: "large" },
                      on: { click: _vm.close },
                    },
                    [_c("i", { staticClass: "fa fa-remove" })]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "container-body" }, [
              _vm.mode === "add" && _vm.select_type
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "container-step-type-list" },
                      [
                        _c("div", { staticClass: "form-label" }, [
                          _c("h5", [_vm._v("Sequence Step Type")]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "Please choose one sequence step type below."
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "Search sequence step...",
                                  clearable: "",
                                },
                                on: { change: _vm.searchSequenceType },
                                nativeOn: {
                                  keyup: function ($event) {
                                    return _vm.searchSequenceType.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.sequence_type_search,
                                  callback: function ($$v) {
                                    _vm.sequence_type_search = $$v
                                  },
                                  expression: "sequence_type_search",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-input__icon el-icon-search",
                                  attrs: { slot: "prefix" },
                                  slot: "prefix",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "no-border",
                            attrs: {
                              error: _vm.errors.first("type"),
                              id: "form-item-sequence-types",
                            },
                          },
                          [
                            _vm.types.length > 0
                              ? _c(
                                  "el-radio-group",
                                  {
                                    staticClass: "w-full radio-group-hover",
                                    on: { change: _vm.changeType },
                                    model: {
                                      value: _vm.sequence.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.sequence, "type", $$v)
                                      },
                                      expression: "sequence.type",
                                    },
                                  },
                                  _vm._l(_vm.types, function (type) {
                                    return _c(
                                      "div",
                                      { key: type.type, staticClass: "mb-3" },
                                      [
                                        _c(
                                          "el-radio-button",
                                          {
                                            staticClass: "radio-btn-block",
                                            attrs: {
                                              label: type.type,
                                              disabled:
                                                _vm.disableSequenceTypeOption(
                                                  type.type
                                                ),
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "align-self-center mr-3",
                                                  attrs: { src: type.img_src },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex-grow-1 align-self-center text-left mr-3",
                                                  },
                                                  [
                                                    _c("h6", [
                                                      _vm._v(_vm._s(type.name)),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 sequence-type-description",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            type.description
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.disableSequenceTypeOption(
                                                      type.type
                                                    )
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "mt-2 sequence-type-description-danger mb-0 text-danger",
                                                          },
                                                          [
                                                            _vm.emailStepsDisabled(
                                                              type.type
                                                            )
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                        You are not allowed to add this step because the accounts has no email integration\n                                                    "
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.smsStepsDisabled(
                                                              type.type
                                                            )
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                        Company's SMS capability is currently disabled\n                                                    "
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.messagingDisabled(
                                                              type.type
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "block",
                                                                        "word-wrap":
                                                                          "break-word",
                                                                        "white-space":
                                                                          "normal",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                        " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .blockedMessagingInformation[
                                                                            "reason"
                                                                          ]
                                                                        ) +
                                                                        "\n                                                    "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.aloAiBotDisabled(
                                                              type.type
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "d-block w-100 text-center",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Company's AloAi currently disabled"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "align-self-center",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-chevron-right",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.types.length <= 0
                              ? _c(
                                  "div",
                                  { staticClass: "text-center py-5" },
                                  [
                                    _c("img-empty", {
                                      staticClass: "w-75 mx-auto",
                                    }),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "lead" }, [
                                      _vm._v(
                                        "\n                                    We couldn't find \"" +
                                          _vm._s(_vm.sequence_type_search) +
                                          '"\n                                '
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.select_type
                ? _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("div", { staticClass: "d-flex mb-3" }, [
                        _c("img", {
                          staticClass: "mr-3",
                          attrs: { src: _vm.sequence.img_src },
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _c("h6", { staticClass: "mb-0" }, [
                            _vm._v(_vm._s(_vm.sequence.name)),
                          ]),
                          _vm._v(" "),
                          _c("small", {
                            domProps: {
                              innerHTML: _vm._s(_vm.sequence.description),
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.showDeprecationBannersForTags
                        ? _c("al-alert", { staticClass: "mb-1" }, [
                            _c("span", {
                              staticClass: "text-dark",
                              domProps: {
                                innerHTML: _vm._s(_vm.TAGS_DEPRECATION_MESSAGE),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "card p-4 mb-3" }, [
                        _c("div", { staticClass: "form-label" }, [
                          _c("h5", [
                            _c("i", { staticClass: "fa fa-clock-o" }),
                            _vm._v(" Delay"),
                          ]),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              '\n                                Set the delay between the previous step and this current step. If you don\'t want\n                                a delay,\n                                just choose "No Delay".\n                            '
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.sequence.delay_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sequence, "delay_type", $$v)
                                  },
                                  expression: "sequence.delay_type",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  {
                                    staticClass: "no-select",
                                    attrs: { label: _vm.Sequence.DELAY_NONE },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    No Delay\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  {
                                    staticClass: "no-select",
                                    attrs: {
                                      label: _vm.Sequence.DELAY_TYPE_MINUTES,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Default Delay\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  {
                                    staticClass: "no-select",
                                    attrs: {
                                      label:
                                        _vm.Sequence.DELAY_TYPE_TIME_OF_DAY,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Time of Day\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.sequence.delay_type ===
                        _vm.Sequence.DELAY_TYPE_MINUTES
                          ? _c("div", [
                              _c("small", [
                                _vm._v("Choose the duration of the delay."),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mt-1" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "no-border",
                                        attrs: {
                                          error:
                                            _vm.errors.first("converted_delay"),
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          staticClass: "w-100",
                                          attrs: { min: 0 },
                                          model: {
                                            value: _vm.sequence.converted_delay,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sequence,
                                                "converted_delay",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sequence.converted_delay",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-6" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "no-border",
                                        attrs: {
                                          error: _vm.errors.first("delay_unit"),
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            key: _vm.delay_unit_key,
                                            staticClass: "w-full",
                                            on: { change: _vm.changeDelayUnit },
                                            model: {
                                              value: _vm.sequence.delay_unit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sequence,
                                                  "delay_unit",
                                                  $$v
                                                )
                                              },
                                              expression: "sequence.delay_unit",
                                            },
                                          },
                                          _vm._l(
                                            _vm.delay_units,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.delay_type ===
                        _vm.Sequence.DELAY_TYPE_TIME_OF_DAY
                          ? _c("div", [
                              _c("small", [
                                _vm._v(
                                  "\n                                Choose the delay in days and the time of the day you want this step to be\n                                processed.\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mb-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "no-border",
                                        attrs: {
                                          error:
                                            _vm.errors.first("converted_delay"),
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          staticClass: "w-80",
                                          attrs: { min: 0 },
                                          model: {
                                            value: _vm.sequence.converted_delay,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sequence,
                                                "converted_delay",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sequence.converted_delay",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v("Days"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "no-border",
                                        attrs: {
                                          error: _vm.errors.first(
                                            "converted_time_of_day"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-time-select", {
                                          staticClass: "w-100",
                                          attrs: {
                                            placeholder:
                                              "Choose the time of day",
                                            "picker-options":
                                              _vm.time_picker_options,
                                          },
                                          model: {
                                            value:
                                              _vm.sequence
                                                .converted_time_of_day,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sequence,
                                                "converted_time_of_day",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sequence.converted_time_of_day",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "no-border",
                                        attrs: {
                                          error:
                                            _vm.errors.first("delay_timezone"),
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "w-full",
                                            attrs: {
                                              clearable: "",
                                              placeholder: "Select Timezone",
                                            },
                                            model: {
                                              value:
                                                _vm.sequence.delay_timezone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sequence,
                                                  "delay_timezone",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sequence.delay_timezone",
                                            },
                                          },
                                          _vm._l(
                                            Object.keys(
                                              _vm.Sequence.DELAY_TIMEZONE_TYPES
                                            ),
                                            function (delayType) {
                                              return _c("el-option", {
                                                key: parseInt(delayType),
                                                attrs: {
                                                  value: parseInt(delayType),
                                                  label:
                                                    _vm.Sequence
                                                      .DELAY_TIMEZONE_TYPES[
                                                      delayType
                                                    ],
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "lh-1" }, [
                                _c("i", {
                                  staticClass: "fa fa-info-circle text-warning",
                                }),
                                _vm._v(
                                  "\n                                If the previous step is processed after this step's time of day settings,\n                                this will be executed in the next day.\n                            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.delay_type === _vm.Sequence.DELAY_NONE
                          ? _c("div", [
                              _c("p", { staticClass: "mb-0" }, [
                                _c("i", {
                                  staticClass: "fa fa-info-circle text-warning",
                                }),
                                _vm._v(
                                  "\n                                This step will be processed without delays.\n                            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                        _vm.Sequence.TYPE_REMOVE_FROM_POWER_DIALER
                          ? _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c("el-switch", {
                                  attrs: { "active-color": "#00a344" },
                                  on: {
                                    change:
                                      _vm.toggleRemoveAllUserPowerDialerLists,
                                  },
                                  model: {
                                    value:
                                      _vm.sequence
                                        .remove_all_users_from_powerdialer_lists,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.sequence,
                                        "remove_all_users_from_powerdialer_lists",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "sequence.remove_all_users_from_powerdialer_lists",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _c("b", [
                                    _vm._v(
                                      "Remove from all User's PowerDialer lists."
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                        _vm.Sequence.TYPE_REMOVE_FROM_CONTACT_LIST
                          ? _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c("el-switch", {
                                  attrs: { "active-color": "#00a344" },
                                  on: {
                                    change:
                                      _vm.toggleRemoveAllUserPowerDialerLists,
                                  },
                                  model: {
                                    value:
                                      _vm.sequence
                                        .remove_all_users_from_powerdialer_lists,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.sequence,
                                        "remove_all_users_from_powerdialer_lists",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "sequence.remove_all_users_from_powerdialer_lists",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _c("b", [
                                    _vm._v("Remove from all Contact lists."),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_CALL_LEAD
                          ? _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    staticClass:
                                      "w-full checkbox-label-description mb-4",
                                    attrs: { border: "" },
                                    model: {
                                      value: _vm.sequence.confirmation_by_sms,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sequence,
                                          "confirmation_by_sms",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sequence.confirmation_by_sms",
                                    },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Send an SMS message before the call"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        "\n                                    This will send a yes/no confirmation text to the customer\n                                "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pt-3" }, [
                        _vm.sequence.type == _vm.Sequence.TYPE_CALL_LEAD ||
                        _vm.sequence.type == _vm.Sequence.TYPE_CALL_RING_GROUP
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "alert alert-warning alert-aloware",
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-exclamation-circle",
                                }),
                                _vm._v(
                                  " Dialer only calls the contact if an agent is available in a Ring Group below.\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                        _vm.Sequence.TYPE_ADD_TO_CONTACT_LIST
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error:
                                        _vm.errors.first("contact_list_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("List")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        Choose the list to add the enrolled contacts.\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("contact-lists-selector", {
                                      attrs: {
                                        user_id: _vm.auth.user.profile.id,
                                        module_type:
                                          _vm.ContactListModuleTypes
                                            .PUBLIC_CONTACT_LIST,
                                      },
                                      on: { change: _vm.changeContactList },
                                      model: {
                                        value: _vm.sequence.contact_list_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "contact_list_id",
                                            $$v
                                          )
                                        },
                                        expression: "sequence.contact_list_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_CALL_LEAD
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("ring_group_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Ring Group")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Choose the ring group you want the enrolled contact to be connected."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("ring-group-selector", {
                                      staticClass: "w-full",
                                      on: { change: _vm.changeRingGroup },
                                      model: {
                                        value: _vm.sequence.ring_group_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "ring_group_id",
                                            $$v
                                          )
                                        },
                                        expression: "sequence.ring_group_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { staticClass: "no-border" },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Message")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        Please design your message to follow this guideline: "
                                        ),
                                        _c("br"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "alert alert-success alert-aloware",
                                        },
                                        [
                                          _vm._v(
                                            '\n                                        "If you want to be connected now, press 1. If you want to be called at a later time, press 2. To be removed from our list, please press 9."\n                                    '
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("comm-tools", {
                                      attrs: {
                                        errors: _vm.errors,
                                        is_optout_active: _vm.is_optout_active,
                                      },
                                      on: {
                                        "change-is-optout-active":
                                          _vm.updateIsOptoutActive,
                                      },
                                      model: {
                                        value: _vm.sequence,
                                        callback: function ($$v) {
                                          _vm.sequence = $$v
                                        },
                                        expression: "sequence",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_CALL_RING_GROUP
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("ring_group_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Ring Group")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Choose the ring group you want the enrolled contact to be connected."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("ring-group-selector", {
                                      staticClass: "w-full",
                                      on: { change: _vm.changeRingGroup },
                                      model: {
                                        value: _vm.sequence.ring_group_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "ring_group_id",
                                            $$v
                                          )
                                        },
                                        expression: "sequence.ring_group_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { staticClass: "no-border" },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Message")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        Please design your message to follow this guideline:\n                                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "alert alert-success alert-aloware",
                                        },
                                        [
                                          _vm._v(
                                            '\n                                        "Hey [AgentName], we are about to call [FirstName] [LastName] from [State]."\n                                    '
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("comm-tools", {
                                      attrs: {
                                        errors: _vm.errors,
                                        is_optout_active: _vm.is_optout_active,
                                      },
                                      on: {
                                        "change-is-optout-active":
                                          _vm.updateIsOptoutActive,
                                      },
                                      model: {
                                        value: _vm.sequence,
                                        callback: function ($$v) {
                                          _vm.sequence = $$v
                                        },
                                        expression: "sequence",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_SMS_LEAD
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "no-border" },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Text Message")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        To ensure delivery, try to make your message as personal as possible. "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        You can use short codes like [FirstName] or [TrackingNumber].\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("comm-tools", {
                                      attrs: {
                                        errors: _vm.errors,
                                        is_optout_active: _vm.is_optout_active,
                                      },
                                      on: {
                                        "change-is-optout-active":
                                          _vm.updateIsOptoutActive,
                                      },
                                      model: {
                                        value: _vm.sequence,
                                        callback: function ($$v) {
                                          _vm.sequence = $$v
                                        },
                                        expression: "sequence",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { staticClass: "no-border" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass:
                                          "w-full checkbox-label-description mb-4",
                                        attrs: { border: "" },
                                        model: {
                                          value: _vm.sequence.use_personal_line,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sequence,
                                              "use_personal_line",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "sequence.use_personal_line",
                                        },
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            "Use contact owner's personal line"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("small", [
                                          _vm._v(
                                            "\n                                        Uses the contact owner's personal line to send out the message.\n                                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.sequence.use_personal_line
                                      ? _c("p", { staticClass: "lh-1p4" }, [
                                          _vm._v(
                                            "\n                                    If the contact does not have an owner or the owner does not have a personal line, we will use the\n                                    sequence default outbound line to send the message.\n                                "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_MMS
                          ? _c(
                              "div",
                              [
                                _c("comm-tools", {
                                  attrs: {
                                    errors: _vm.errors,
                                    is_optout_active: _vm.is_optout_active,
                                  },
                                  on: {
                                    "change-is-optout-active":
                                      _vm.updateIsOptoutActive,
                                  },
                                  model: {
                                    value: _vm.sequence,
                                    callback: function ($$v) {
                                      _vm.sequence = $$v
                                    },
                                    expression: "sequence",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_SMS_RING_GROUP
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("ring_group_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Ring Group")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Choose the ring group you want the enrolled contact to be connected."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("ring-group-selector", {
                                      staticClass: "w-full",
                                      on: { change: _vm.changeRingGroup },
                                      model: {
                                        value: _vm.sequence.ring_group_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "ring_group_id",
                                            $$v
                                          )
                                        },
                                        expression: "sequence.ring_group_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { staticClass: "no-border" },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Text Message")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        To ensure delivery, try to make your message as personal as possible. "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                        You can use short codes like [FirstName] or [TrackingNumber].\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("comm-tools", {
                                      attrs: {
                                        errors: _vm.errors,
                                        is_optout_active: _vm.is_optout_active,
                                      },
                                      on: {
                                        "change-is-optout-active":
                                          _vm.updateIsOptoutActive,
                                      },
                                      model: {
                                        value: _vm.sequence,
                                        callback: function ($$v) {
                                          _vm.sequence = $$v
                                        },
                                        expression: "sequence",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_ADD_TAG_CONTACT
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("tag_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Tag")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Choose the tag you want the enrolled contacts to be tagged."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tag-selector", {
                                      staticClass: "w-full",
                                      attrs: {
                                        category_prop:
                                          _vm.TagCategory.CAT_CONTACTS,
                                      },
                                      on: { change: _vm.changeTag },
                                      model: {
                                        value: _vm.sequence.tag_id,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.sequence, "tag_id", $$v)
                                        },
                                        expression: "sequence.tag_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_CONTACT_TO_LINE
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("campaign_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Line")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Choose the line to add for the enrolled contacts."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("campaign-selector", {
                                      staticClass: "w-full",
                                      on: { change: _vm.changeCampaign },
                                      model: {
                                        value: _vm.sequence.campaign_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "campaign_id",
                                            $$v
                                          )
                                        },
                                        expression: "sequence.campaign_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                        _vm.Sequence.TYPE_UPDATE_CONTACT_LEAD_SOURCE
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("lead_source_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Lead Source")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v("Enter lead source name"),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("lead-source-selector", {
                                      model: {
                                        value: _vm.sequence.lead_source_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "lead_source_id",
                                            $$v
                                          )
                                        },
                                        expression: "sequence.lead_source_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                        _vm.Sequence.TYPE_REMOVE_TAG_CONTACT
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("tag_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Tag")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Choose the tag you want to remove from the enrolled contact."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "mt-1 mb-1" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            staticClass: "w-full",
                                            on: {
                                              change: _vm.toggleRemoveAllTags,
                                            },
                                            model: {
                                              value:
                                                _vm.sequence.remove_all_tags,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sequence,
                                                  "remove_all_tags",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sequence.remove_all_tags",
                                            },
                                          },
                                          [
                                            _c("small", [
                                              _vm._v("Remove All Tags"),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("tag-selector", {
                                      staticClass: "w-full",
                                      attrs: {
                                        category_prop:
                                          _vm.TagCategory.CAT_CONTACTS,
                                        disabled: _vm.sequence.remove_all_tags,
                                      },
                                      on: { change: _vm.changeTag },
                                      model: {
                                        value: _vm.sequence.tag_id,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.sequence, "tag_id", $$v)
                                        },
                                        expression: "sequence.tag_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                        _vm.Sequence.TYPE_ADD_TO_POWER_DIALER
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: { change: _vm.checkDistributeTo },
                                        model: {
                                          value: _vm.sequence.distribute_to,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sequence,
                                              "distribute_to",
                                              $$v
                                            )
                                          },
                                          expression: "sequence.distribute_to",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-button",
                                          {
                                            staticClass: "no-select",
                                            attrs: {
                                              label:
                                                _vm.Sequence.ASSIGN_TO_USER,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        User\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio-button",
                                          {
                                            staticClass: "no-select",
                                            attrs: {
                                              label:
                                                _vm.Sequence
                                                  .ASSIGN_TO_RING_GROUP,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Ring Group\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.sequence.distribute_to ===
                                _vm.Sequence.ASSIGN_TO_USER
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "no-border",
                                            attrs: {
                                              error:
                                                _vm.errors.first("user_id"),
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [_vm._v("User")]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                            Choose the PowerDialer user you want the enrolled contact to be added.\n                                        "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("user-selector", {
                                              attrs: { hide_extensions: true },
                                              on: { change: _vm.changeUser },
                                              model: {
                                                value: _vm.sequence.user_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.sequence,
                                                    "user_id",
                                                    $$v
                                                  )
                                                },
                                                expression: "sequence.user_id",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.sequence.user_id
                                          ? _c(
                                              "el-form-item",
                                              {
                                                staticClass: "no-border",
                                                attrs: {
                                                  error:
                                                    _vm.errors.first(
                                                      "contact_list_id"
                                                    ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "PowerDialer List"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                            Choose the PowerDialer user's list to which you want to add the enrolled contact.\n                                        "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("contact-lists-selector", {
                                                  attrs: {
                                                    user_id:
                                                      _vm.sequence.user_id,
                                                    module_type:
                                                      _vm.ContactListModuleTypes
                                                        .POWER_DIALER_LIST,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeContactList,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.sequence
                                                        .contact_list_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.sequence,
                                                        "contact_list_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "sequence.contact_list_id",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.sequence.distribute_to ===
                                _vm.Sequence.ASSIGN_TO_RING_GROUP
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "no-border",
                                            attrs: {
                                              error:
                                                _vm.errors.first(
                                                  "ring_group_id"
                                                ),
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v("Ring Group"),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "Choose the ring group you want the enrolled contact to be connected."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("ring-group-selector", {
                                              staticClass: "w-full",
                                              on: {
                                                change: _vm.changeRingGroup,
                                              },
                                              model: {
                                                value:
                                                  _vm.sequence.ring_group_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.sequence,
                                                    "ring_group_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "sequence.ring_group_id",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-checkbox",
                                          {
                                            staticClass:
                                              "w-full checkbox-label-description mb-4",
                                            attrs: {
                                              name: "events",
                                              border: "",
                                            },
                                            model: {
                                              value:
                                                _vm.sequence
                                                  .assign_to_available_users,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sequence,
                                                  "assign_to_available_users",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sequence.assign_to_available_users",
                                            },
                                          },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                "Assign to available users only"
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "\n                                        The enrolled contact will be added to power dialer list of available users\n                                    "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      prop: "push_contact_to",
                                      error:
                                        _vm.errors.first("push_contact_to"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [
                                        _vm._v("Power Dialer Position"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        Choose whether you want the enrolled contact to be added at the top or\n                                        bottom of the user's PowerDialer.\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: { change: _vm.pushContactToChange },
                                        model: {
                                          value: _vm.sequence.push_contact_to,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sequence,
                                              "push_contact_to",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "sequence.push_contact_to",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-button",
                                          {
                                            attrs: {
                                              label:
                                                _vm.Sequence
                                                  .PUSH_CONTACTS_TO_BOTTOM,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Bottom\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio-button",
                                          {
                                            attrs: {
                                              label:
                                                _vm.Sequence
                                                  .PUSH_CONTACTS_TO_TOP,
                                            },
                                          },
                                          [_vm._v("Top")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.sequence.distribute_to ===
                                _vm.Sequence.ASSIGN_TO_USER
                                  ? _c(
                                      "el-checkbox",
                                      {
                                        staticClass:
                                          "w-full checkbox-label-description mb-4",
                                        attrs: { name: "events", border: "" },
                                        model: {
                                          value:
                                            _vm.sequence.add_to_contact_owner,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sequence,
                                              "add_to_contact_owner",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "sequence.add_to_contact_owner",
                                        },
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            "Add to contact owner's PowerDialer"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("small", [
                                          _vm._v(
                                            "\n                                    If the enrolled contact does not have an owner, it will be added the the\n                                    selected user's PowerDialer.\n                                "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-checkbox",
                                  {
                                    staticClass:
                                      "w-full checkbox-label-description mb-4",
                                    attrs: { name: "events", border: "" },
                                    model: {
                                      value:
                                        _vm.sequence.multiple_phone_numbers,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sequence,
                                          "multiple_phone_numbers",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sequence.multiple_phone_numbers",
                                    },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v("Allow multiple phone numbers"),
                                    ]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        "\n                                    Add all contact's phone numbers when there are more than one.\n                                "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-checkbox",
                                  {
                                    staticClass:
                                      "w-full checkbox-label-description mb-4",
                                    attrs: { name: "events", border: "" },
                                    model: {
                                      value:
                                        _vm.sequence.prevent_duplicate_contacts,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sequence,
                                          "prevent_duplicate_contacts",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sequence.prevent_duplicate_contacts",
                                    },
                                  },
                                  [
                                    _c("label", [_vm._v("Prevent duplicates")]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        "\n                                    Remove duplicated contacts in the PowerDialer queue.\n                                "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-checkbox",
                                  {
                                    staticClass:
                                      "w-full checkbox-label-description mb-4",
                                    attrs: {
                                      name: "allow_international_phone_numbers",
                                      disabled: !_vm.hasInternationalAccess,
                                      border: "",
                                    },
                                    model: {
                                      value:
                                        _vm.sequence
                                          .allow_international_phone_numbers,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sequence,
                                          "allow_international_phone_numbers",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "sequence.allow_international_phone_numbers",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      [
                                        _vm._v(
                                          "\n                                    Allow international phone numbers\n\n                                    "
                                        ),
                                        !_vm.hasInternationalAccess
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item ml-2",
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    "This account has no international access",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-info-circle text-dark",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        "\n                                    Either to exclude or include international phone numbers in Power Dialer.\n                                "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_DISPOSE_CONTACT
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first(
                                        "disposition_status_id"
                                      ),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Disposition Status")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Choose the Disposition Status you want for the enrolled contact."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("disposition-status-selector", {
                                      attrs: { clearable: true },
                                      on: {
                                        change: _vm.changeDispositionStatus,
                                      },
                                      model: {
                                        value:
                                          _vm.sequence.disposition_status_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "disposition_status_id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "sequence.disposition_status_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_DNC_CONTACT
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "alert alert-warning alert-aloware",
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-exclamation-triangle",
                                  }),
                                  _vm._v(" "),
                                  _c("strong", [_vm._v("Warning:")]),
                                  _vm._v(
                                    "\n                                The enrolled contact will be marked as DNC (Do Not Contact) and you will never be able to\n                                communicate to the contact anymore.\n                            "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                        _vm.Sequence.TYPE_CHANGE_CONTACT_OWNER
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("user_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Contact's Owner")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Choose the the user you want to assign the enrolled contact."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("user-selector", {
                                      attrs: { hide_extensions: true },
                                      on: { change: _vm.changeUser },
                                      model: {
                                        value: _vm.sequence.user_id,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.sequence, "user_id", $$v)
                                        },
                                        expression: "sequence.user_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_WEBHOOK
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "no-border" },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [
                                        _vm._v("Authentication Method"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Select your webhook endpoint authentication method."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { size: "medium" },
                                        model: {
                                          value: _vm.sequence.auth_method,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sequence,
                                              "auth_method",
                                              $$v
                                            )
                                          },
                                          expression: "sequence.auth_method",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-button",
                                          {
                                            attrs: {
                                              label:
                                                _vm.Sequence.AUTH_METHOD_NONE,
                                            },
                                          },
                                          [_vm._v("None")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio-button",
                                          {
                                            attrs: {
                                              label:
                                                _vm.Sequence.AUTH_METHOD_BASIC,
                                            },
                                          },
                                          [_vm._v("Basic")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio-button",
                                          {
                                            attrs: {
                                              label:
                                                _vm.Sequence
                                                  .AUTH_METHOD_TOKEN_BASED,
                                            },
                                          },
                                          [_vm._v("Bearer")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "mt-3" }, [
                                      _vm.sequence.auth_method ===
                                      _vm.Sequence.AUTH_METHOD_BASIC
                                        ? _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticClass: "no-border",
                                                    attrs: {
                                                      error: _vm.errors.first(
                                                        "credentials.username"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-label",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Username"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          _vm.sequence
                                                            .credentials
                                                            .username,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.sequence
                                                              .credentials,
                                                            "username",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "sequence.credentials.username",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-6" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticClass: "no-border",
                                                    attrs: {
                                                      error: _vm.errors.first(
                                                        "credentials.password"
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-label",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v("Password"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          _vm.sequence
                                                            .credentials
                                                            .password,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.sequence
                                                              .credentials,
                                                            "password",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "sequence.credentials.password",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.sequence.auth_method ===
                                      _vm.Sequence.AUTH_METHOD_TOKEN_BASED
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass: "no-border",
                                                  attrs: {
                                                    error: _vm.errors.first(
                                                      "credentials.api_token"
                                                    ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-label",
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          "API Bearer Token"
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("small", [
                                                        _vm._v(
                                                          "Enter the API token of your webhook endpoint."
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("el-input", {
                                                    model: {
                                                      value:
                                                        _vm.sequence.credentials
                                                          .api_token,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.sequence
                                                            .credentials,
                                                          "api_token",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "sequence.credentials.api_token",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("url"),
                                      prop: "url",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("URL")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Provide a valid webhook endpoint URL."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      model: {
                                        value: _vm.sequence.url,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.sequence, "url", $$v)
                                        },
                                        expression: "sequence.url",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_EMAIL_LEAD
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error:
                                        _vm.errors.first("message_subject"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Email Subject")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v("Provide the email subject"),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w-full",
                                      model: {
                                        value: _vm.sequence.message_subject,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "message_subject",
                                            $$v
                                          )
                                        },
                                        expression: "sequence.message_subject",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  [
                                    _c("comm-tools", {
                                      attrs: {
                                        is_optout_active: _vm.is_optout_active,
                                      },
                                      on: {
                                        "change-is-optout-active":
                                          _vm.updateIsOptoutActive,
                                      },
                                      model: {
                                        value: _vm.sequence,
                                        callback: function ($$v) {
                                          _vm.sequence = $$v
                                        },
                                        expression: "sequence",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_EMAIL_RING_GROUP
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("ring_group_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Ring Group")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Choose the ring group you want the enrolled contact to be connected."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("ring-group-selector", {
                                      staticClass: "w-full",
                                      on: { change: _vm.changeRingGroup },
                                      model: {
                                        value: _vm.sequence.ring_group_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "ring_group_id",
                                            $$v
                                          )
                                        },
                                        expression: "sequence.ring_group_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error:
                                        _vm.errors.first("message_subject"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Email Subject")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v("Provide the email subject"),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w-full",
                                      model: {
                                        value: _vm.sequence.message_subject,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "message_subject",
                                            $$v
                                          )
                                        },
                                        expression: "sequence.message_subject",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  [
                                    _c("comm-tools", {
                                      attrs: {
                                        is_optout_active: _vm.is_optout_active,
                                      },
                                      on: {
                                        "change-is-optout-active":
                                          _vm.updateIsOptoutActive,
                                      },
                                      model: {
                                        value: _vm.sequence,
                                        callback: function ($$v) {
                                          _vm.sequence = $$v
                                        },
                                        expression: "sequence",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type === _vm.Sequence.TYPE_RVM
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("message_tts"),
                                    },
                                  },
                                  [
                                    _c("comm-tools", {
                                      attrs: {
                                        is_optout_active: _vm.is_optout_active,
                                      },
                                      on: {
                                        "change-is-optout-active":
                                          _vm.updateIsOptoutActive,
                                      },
                                      model: {
                                        value: _vm.sequence,
                                        callback: function ($$v) {
                                          _vm.sequence = $$v
                                        },
                                        expression: "sequence",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                        _vm.Sequence.TYPE_CHANGE_CONTACT_OWNER_ROUND_ROBIN
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { staticClass: "no-border" },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [
                                        _vm._v("Round-robin Contact Ownership"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        Choose whether you want to round-robin contact ownership to all users or a certain ring group.\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "mb-2",
                                        on: {
                                          change: _vm.changeRoundRobinSettings,
                                        },
                                        model: {
                                          value: _vm.round_robin_ring_group,
                                          callback: function ($$v) {
                                            _vm.round_robin_ring_group = $$v
                                          },
                                          expression: "round_robin_ring_group",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-button",
                                          { attrs: { label: false } },
                                          [
                                            _vm._v(
                                              "\n                                        All Users\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio-button",
                                          { attrs: { label: true } },
                                          [_vm._v("Users in a Ring Group")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    !_vm.round_robin_ring_group
                                      ? _c("div", [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-info-circle text-success",
                                          }),
                                          _vm._v(
                                            " The enrolled contact will be round-robin assigned to all users.\n                                "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.round_robin_ring_group
                                  ? _c(
                                      "el-form-item",
                                      {
                                        staticClass: "no-border",
                                        attrs: {
                                          error:
                                            _vm.errors.first("ring_group_id"),
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-label" },
                                          [
                                            _c("h5", [_vm._v("Ring Group")]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "\n                                        The enrolled contact will be round-robin assigned to the users of the chosen Ring group.\n                                    "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("ring-group-selector", {
                                          staticClass: "w-full",
                                          on: { change: _vm.changeRingGroup },
                                          model: {
                                            value: _vm.sequence.ring_group_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sequence,
                                                "ring_group_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sequence.ring_group_id",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                          _vm.Sequence.TYPE_REMOVE_FROM_POWER_DIALER &&
                        !_vm.sequence.remove_all_users_from_powerdialer_lists
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("user_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("User")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Choose the PowerDialer user you want the enrolled contact to be removed from."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("user-selector", {
                                      attrs: { hide_extensions: true },
                                      on: { change: _vm.changeUser },
                                      model: {
                                        value: _vm.sequence.user_id,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.sequence, "user_id", $$v)
                                        },
                                        expression: "sequence.user_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.sequence.user_id
                                  ? _c(
                                      "el-form-item",
                                      {
                                        staticClass: "no-border",
                                        attrs: {
                                          error:
                                            _vm.errors.first("contact_list_id"),
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-label" },
                                          [
                                            _c("h5", [
                                              _vm._v("PowerDialer List"),
                                            ]),
                                            _vm._v(" "),
                                            _c("small", [
                                              _vm._v(
                                                "Choose the PowerDialer user's list you want the enrolled contact to be removed from."
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("contact-lists-selector", {
                                          attrs: {
                                            user_id: _vm.sequence.user_id,
                                            module_type:
                                              _vm.ContactListModuleTypes
                                                .POWER_DIALER_LIST,
                                          },
                                          on: { change: _vm.changeContactList },
                                          model: {
                                            value: _vm.sequence.contact_list_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sequence,
                                                "contact_list_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sequence.contact_list_id",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                          _vm.Sequence.TYPE_REMOVE_FROM_CONTACT_LIST &&
                        !_vm.sequence.remove_all_users_from_powerdialer_lists
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error:
                                        _vm.errors.first("contact_list_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("List")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "Choose the List to remove from the enrolled contacts."
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("contact-lists-selector", {
                                      attrs: {
                                        user_id: _vm.auth.user.profile.id,
                                        module_type:
                                          _vm.ContactListModuleTypes
                                            .PUBLIC_CONTACT_LIST,
                                      },
                                      on: { change: _vm.changeContactList },
                                      model: {
                                        value: _vm.sequence.contact_list_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "contact_list_id",
                                            $$v
                                          )
                                        },
                                        expression: "sequence.contact_list_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                        _vm.Sequence.TYPE_ENROLL_TO_OTHER_WORKFLOW
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error:
                                        _vm.errors.first("enroll_workflow_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("Sequence")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        Choose the Sequence you want to enroll the contacts to.\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("workflow-selector", {
                                      on: { change: _vm.changeEnrollWorkflow },
                                      model: {
                                        value: _vm.sequence.enroll_workflow_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "enroll_workflow_id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "sequence.enroll_workflow_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sequence.type ===
                        _vm.Sequence.TYPE_START_ALOAI_CONVERSATION
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "no-border",
                                    attrs: {
                                      error: _vm.errors.first("aloai_bot_id"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", [_vm._v("AloAi Bot")]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                        Choose the AloAi bot you want to start a conversation.\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("alo-ai-bots-selector", {
                                      on: { change: _vm.changeAloAiBot },
                                      model: {
                                        value: _vm.sequence.aloai_bot_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sequence,
                                            "aloai_bot_id",
                                            $$v
                                          )
                                        },
                                        expression: "sequence.aloai_bot_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex mt-4" },
                [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v(
                      "\n                        Cancel\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.select_type
                    ? _c(
                        "el-button",
                        {
                          staticClass: "ml-auto",
                          attrs: { type: "success", loading: _vm.saving },
                          on: { click: _vm.saveSequence },
                        },
                        [
                          _vm.mode === "edit"
                            ? _c("strong", [_vm._v("Save Settings")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.mode === "add"
                            ? _c("strong", [_vm._v("Add Step")])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }