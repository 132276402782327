var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.auth.user.profile.country == "US"
    ? _c(
        "el-select",
        {
          staticClass: "w-full",
          attrs: {
            placeholder: "Select States",
            multiple: _vm.multiple,
            "default-first-option": "",
            "collapse-tags": "",
            filterable: "",
          },
          on: { change: _vm.selectState },
          model: {
            value: _vm.limit_us,
            callback: function ($$v) {
              _vm.limit_us = $$v
            },
            expression: "limit_us",
          },
        },
        _vm._l(_vm.states_us, function (item, index) {
          return _c(
            "el-option",
            { key: item + "_" + index, attrs: { label: item, value: item } },
            [_c("span", [_vm._v(_vm._s(item))])]
          )
        }),
        1
      )
    : _vm.auth.user.profile.country == "CA"
    ? _c(
        "el-select",
        {
          staticClass: "w-full",
          attrs: {
            placeholder: "Select States",
            multiple: _vm.multiple,
            "default-first-option": "",
            "collapse-tags": "",
            filterable: "",
          },
          on: { change: _vm.selectState },
          model: {
            value: _vm.limit_ca,
            callback: function ($$v) {
              _vm.limit_ca = $$v
            },
            expression: "limit_ca",
          },
        },
        _vm._l(_vm.states_ca, function (item, index) {
          return _c(
            "el-option",
            { key: item + "_" + index, attrs: { label: item, value: item } },
            [_c("span", [_vm._v(_vm._s(item))])]
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }