<template>
    <el-col class="p-2 pr-4" :span="colSpan">
        <label class="d-flex justify-content-start w-100 mb-1 text-weight-medium">
            {{ label }}
        </label>
        <el-input ref="inputRef"
                  rounded
                  outlined
                  autocomplete="off"
                  :placeholder="placeholder"
                  :rules="rules"
                  :type="fieldType"
                  :mask="mask"
                  :value="innerValue"
                  :disabled="disabled"
                  @input="emitUpdateEvent">
            <template v-slot:suffix
                      v-if="isPassword">
                <i class="input-field--icon"
                   :class="show_password ? 'el-icon-moon' : 'el-icon-sunny'"
                   @click="toggleVisibility" />
            </template>
        </el-input>
        <slot name="hint"></slot>
    </el-col>
</template>

<script>
export default {
    name: 'InputField',

    props: {
        label: {
            type: String,
            required: true
        },

        placeholder: {
            type: String,
            required: true
        },

        value: {
            type: String,
            default: ''
        },

        colMd: {
            type: String,
            default: 'col-md-5'
        },

        paddingClasses: {
            type: String,
            default: ''
        },

        rules: {
            type: Array,
            default: () => []
        },

        type: {
            type: String,
            default: 'text'
        },

        mask: {
            type: String,
            default: ''
        },

        isPassword: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        colSpan: {
            type: Number,
            default: 8
        }
    },

    data () {
        return {
            show_password: false,
            innerValue: this.value
        }
    },

    computed: {
        fieldType () {
            return this.isPassword && !this.show_password ? 'password' : this.type
        }
    },

    methods: {
        toggleVisibility () {
            this.show_password = !this.show_password
        },

        emitUpdateEvent (value) {
        this.innerValue = value
            this.$emit('input', this.innerValue)
        },

        validate () {
            if (this.$refs.inputRef && typeof this.$refs.inputRef.validate === 'function') {
                return this.$refs.inputRef.validate()
            }
        }
    },

    watch: {
        value (value) {
            this.innerValue = value
        }
    }
}
</script>
