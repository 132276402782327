<template>
    <div v-if="communication && communication.contact">
        <p>
            You have a new voicemail from
            <b>{{ sanitizeText(communication.contact.name) | fixContactName }} {{ communication.contact.phone_number | fixPhone }}</b>
            on
            <b v-if="getCampaign(communication.campaign_id)">{{ getCampaign(communication.campaign_id).name }}</b>
            line.
        </p>
        <button class="btn rounded greenish btn-listen btn-sm mt-2 pull-right">
            Listen
        </button>
        <p>
            Notes: {{ communication.notes }}
        </p>
    </div>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin, html_mixin} from '../mixins'
    import {mapState} from 'vuex'

    export default {
        mixins: [acl_mixin, html_mixin],

        props: ['communication'],

        data() {
            return {
                auth: auth
            }
        },

        computed: {
            ...mapState(['campaigns']),
            ...mapState('cache', ['current_company'])
        },

        methods: {
            getCampaign(id) {
                if (!id) {
                    return null
                }
                let found = this.campaigns.find(campaign => campaign.id === id)
                if (found) {
                    return found
                }

                return null
            }
        }
    }
</script>
