<template>
    <div v-if="pagination"
         class="text-center">
        <el-button-group>
            <el-button
                size="medium"
                @click="prevPage"
                :disabled="!pagination.prev_page_url">
                <i class="fa fa-caret-left"></i> Prev
            </el-button>
            <el-button
                size="medium"
                @click="nextPage"
                :disabled="!pagination.next_page_url">
                Next <i class="fa fa-caret-right"></i>
            </el-button>
        </el-button-group>
    </div>
</template>

<script>
export default {
    name: "alo-simple-pagination",

    props: {
        pagination: {
            required: true,
            type: Object
        }
    },

    methods: {
        nextPage() {
            this.$emit('next-click')
        },

        prevPage() {
            this.$emit('prev-click')
        }
    }
}
</script>
