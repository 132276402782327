var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.graph_can_load
    ? _c(
        "div",
        { attrs: { id: "activity-graph" } },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "vertical-center text-left pull-left" },
                [
                  _vm.filter.from_date
                    ? _c("span", { staticClass: "call-log-head" }, [
                        _vm._v(
                          "\n                    Communications\n                    from "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("fixFullDateLocal")(_vm.filter.from_date)
                            )
                          ),
                        ]),
                        _vm._v("\n                    to "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("fixFullDateLocal")(_vm.filter.to_date)
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.filter.from_date
                    ? _c("span", { staticClass: "call-log-head" }, [
                        _vm._v(
                          "\n                    Communications\n                    "
                        ),
                        _c("strong", [_vm._v("All Time")]),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pull-right no-select" },
                [
                  _c("strong", [_vm._v("Chart type:")]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.loading,
                        "data-testid": "activity-graph-chart-type-radio",
                        size: "mini",
                      },
                      on: { change: _vm.changeChartType },
                      model: {
                        value: _vm.chart_type,
                        callback: function ($$v) {
                          _vm.chart_type = $$v
                        },
                        expression: "chart_type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        {
                          attrs: {
                            label: "spline",
                            "data-testid": "chart-type-line",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Line\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        {
                          attrs: {
                            label: "areaspline",
                            "data-testid": "chart-type-area",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Area\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        {
                          attrs: {
                            label: "column",
                            "data-testid": "chart-type-bar",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Bar\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.notification
            ? _c(
                "al-alert",
                {
                  class: _vm.notification.class ? _vm.notification.class : "",
                  attrs: { dimisable: _vm.notification.dimisable },
                },
                [
                  _c("span", {
                    staticClass: "mr-5 text-dark",
                    domProps: { innerHTML: _vm._s(_vm.notification.message) },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "div",
                {
                  staticClass: "placeholder",
                  class: { blink: _vm.loading },
                  staticStyle: { height: "450px" },
                },
                [
                  _c("img", {
                    staticClass: "img-responsive",
                    attrs: {
                      src: "/assets/images/placeholders/placeholder-number-of-communications.png",
                      "data-testid": "activity-graph-img",
                    },
                  }),
                ]
              )
            : _c(
                "div",
                [
                  _c("highstock", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.is_done && _vm.options.series.length > 0,
                        expression: "is_done && options.series.length > 0",
                      },
                    ],
                    ref: "highchart",
                    style: _vm.getStyle,
                    attrs: {
                      options: _vm.options,
                      id: _vm.graph_id,
                      "data-testid": "activity-graph-highstock",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.is_done && !_vm.options.series.length,
                          expression: "is_done && !options.series.length",
                        },
                      ],
                      staticClass: "el-table__empty-block",
                      attrs: { "data-testid": "activity-graph-no-data" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "el-table__empty-text",
                          staticStyle: { color: "#606266" },
                        },
                        [_vm._v("\n                No Data\n            ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }