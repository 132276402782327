<template>
    <div>
        <div class="d-flex flex-row mb-3 py-2"
             v-loading="loading">
            <div class="card mr-3"
                 style="max-height: 18.8rem; min-width:30rem; overflow-x: hidden;overflow-y:auto">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th class="break-word">Call Disposition Status</th>
                        <th class="break-word">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="disposition in summary.dispositions">
                        <td>
                            <span class="mr-2">
                                <el-tooltip class="item"
                                            effect="dark"
                                            placement="top-start"
                                            :disabled="!isExternalDisposition(disposition[0])"
                                            v-if="true">
                                    <div slot="content">
                                        Pulled from CRM
                                    </div>
                                    <i class="material-icons"
                                       :style="{ color: getDispositionColor(disposition[0]) }"
                                       v-if="">
                                        {{ isExternalDisposition(disposition[0]) ? 'lock' : 'label' }}
                                    </i>
                                </el-tooltip>
                                <i class="material-icons"
                                   :style="{ color: scope.row.color }"
                                   v-if="false">label</i>
                            </span>
                            {{ disposition[0] | keyLabel }}
                        </td>
                        <td>{{ disposition[1] }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex flex-row justify-content-around">
                <div class="d-flex flex-column">
                    <div class="card p-2 mr-3 mb-3"
                         style="height: max-content; width: 15rem">
                        <div class="card-body text-center d-flex flex-column">
                            <div class="d-flex flex-row justify-content-center">
                                <div>
                                    <div class="mb-2">
                                        <h2 class="text-black">
                                            <span>
                                                {{ this.summary.total }}
                                            </span>
                                        </h2>
                                    </div>
                                    <p class="break-word">Total Calls</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-2 mr-3"
                         style="height: max-content; width: 15rem">
                        <div class="card-body text-center d-flex flex-column">
                            <div class="d-flex flex-row justify-content-center">
                                <div>
                                    <div class="mb-2">
                                        <h2 class="text-black">
                                            <span>
                                                {{ this.summary.outbound_total }}
                                            </span>
                                        </h2>
                                    </div>
                                    <p class="break-word">Outbound Calls</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card p-2 mr-3"
                     style="height: max-content; width: 15rem">
                    <div class="card-body text-center d-flex flex-column">
                        <div class="d-flex flex-row justify-content-center">
                            <div>
                                <div class="mb-2">
                                    <h2 class="text-black">
                                        <span>
                                            {{ this.summary.inbound_total }}
                                        </span>
                                    </h2>
                                </div>
                                <p class="break-word">Inbound Calls</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="p-4">
                    <div class="d-flex flex-row justify-content-between">
                        <div class="d-flex flex-column">
                            <label class="break-word">Group By</label>
                            <el-select v-model="group_by">
                                <el-option v-for="item in group_by_list"
                                           :key="item"
                                           :value="item"
                                           :label="item | groupByLabel">
                                </el-option>
                            </el-select>
                        </div>
                        <el-button type="success"
                                   size="small"
                                   @click="exportReport">
                            Export
                        </el-button>
                    </div>
                    <div class="mt-2 p-4">
                        <div style="min-height: 500px;"
                             v-loading="loading_table">
                            <el-table ref="activity-reporting-2"
                                      class="w-full mt-3 report-dispositions-table"
                                      :default-sort="{prop: group_by, order: 'descending'}"
                                      :data="data"
                                      v-if="data.length > 0">
                                <el-table-column
                                    :fixed="['day','name'].includes(key) ? 'left' : false"
                                    sortable
                                    :sort-method="sortTable"
                                    min-width="150"
                                    :prop="key"
                                    :label="key | keyLabel"
                                    v-for="key in dataKeys"
                                    :key="key">
                                </el-table-column>
                            </el-table>
                            <div class="p-2 text-center"
                                 v-if="data.length === 0">
                                <img-empty></img-empty>
                                <p class="lead">No data found from the selected date range</p>
                            </div>
                        </div>
                        <div class="mt-3">
                            <el-pagination  class="pull-right"
                                            layout="sizes, prev, pager, next"
                                            background
                                            :total="pagination.total"
                                            :page-size="pagination.per_page"
                                            :page-sizes="[20, 35, 50, 100, 250]"
                                            v-if="pagination.total > pagination.per_page"
                                            @next-click="nextPage"
                                            @prev-click="prevPage"
                                            @current-change="changePage"
                                            @size-change="paginationChangeSize">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from "lodash"
import {mapGetters} from 'vuex'
import {Notification} from "element-ui";
import ImgEmpty from "../misc/img-empty";

export default {
    name: 'reports-call-dispositions',
    components: {ImgEmpty},
    data() {
        return {
            loading: false,
            loading_table: false,
            data: [],
            headers: [],
            checked_table_fields: [],
            group_by: 'day',
            group_by_list: [
                'day',
                'user'
            ],
            pagination: {
                per_page: 20,
                current_page: 1,
                path: null,
                first_page_url: null,
                next_page_url: null,
                prev_page_url: null,
                total: 0
            },
            summary: {
                total: 0,
                inbound_total: 0,
                outbound_total: 0,
                dispositions: []
            }
        }
    },

    filters: {
        keyLabel(value) {
            let label = value

            if (!['calls', 'inbound_calls', 'outbound_calls', 'day', 'start_date', 'end_date', 'name'].includes(label)) {
                // key's format is -> {color}_{is_external}_{disposition name}
                let key_arr = value.split('_')
                let is_external = parseInt(key_arr[1])
                let disposition_name = key_arr.splice(2, key_arr.length - 1).join('_')

                label = disposition_name.toString().split('_').map(v => v.capitalize()).join(' ') + (is_external ? ' - CRM' : '')
            } else {
                switch (label) {
                    case 'day':
                        label = 'Date'
                        break;
                    case 'calls':
                        label = 'Total Calls'
                        break;
                    default:
                        label = label.toString().split('_').map(v => v.capitalize()).join(' ')
                }
            }

            return label
        },

        groupByLabel(value) {
            let label = value

            switch (value) {
                case 'day':
                    label = 'Date'
                    break;
                case 'user':
                    label = 'Agent'
                    break;
            }

            return label
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),

        dataKeys() {
            if (this.data.length > 0) {
                let dynamic_keys = _.keys(this.data[0])
                dynamic_keys = dynamic_keys.filter(k => !this.headers.includes(k))

                return this.headers.concat(dynamic_keys)
            }

            return []
        },
    },

    mounted() {
        this.fetchCommunicationCallDispositionReports()
        this.fetchCommunicationCallDispositionSummary()
    },

    methods: {
        isExternalDisposition(key) {
            // key format -> {color}_{is_external}_{disposition name}
            let key_arr = key.split('_')

            return parseInt(key_arr[1])
        },

        getDispositionColor(key) {
            // key format -> {color}_{is_external}_{disposition name}
            let key_arr = key.split('_')
            // Default - dark gray
            let color = "#7d7d7d"

            if (key_arr[0] != 'nocolor') {
                color = '#' + key_arr[0]
            }

            return color
        },

        sortTable(a, b) {
            // For Date
            if (this.group_by === 'day') {
                return new Date(a.day) - new Date(b.day)
            }
            // For Agent
            if (this.group_by === 'user') {
                return a.name < b.name
            }
        },

        changePage(val) {
            let url = this.pagination.path + '?page=' + val
            this.fetchCommunicationCallDispositionReports(url)
        },

        paginationChangeSize(val) {
            this.pagination.per_page = val
            this.fetchCommunicationCallDispositionReports()
        },

        nextPage() {
            let url = this.pagination.next_page_url
            this.fetchCommunicationCallDispositionReports(url)
        },

        prevPage() {
            let url = this.pagination.prev_page_url
            this.fetchCommunicationCallDispositionReports(url)
        },

        exportReport() {
            axios.get('/api/v1/reports/call-dispositions/' + this.group_by + '/export', {
                params: {
                    from_date: this.filter.from_date,
                    to_date: this.filter.to_date
                }
            }).then(res => {
                this.$notify.info({
                    offset: 95,
                    title: 'Exports',
                    dangerouslyUseHTMLString: true,
                    message: `Your export is on its way. You can check the progress from the export page or <b class="text-dark-greenish pointer">click here</b>.`,
                    duration: 3000,
                    onClick: () => {
                        this.$router.push({name: 'Account', query: {tab: 'exports'}}).catch((err) => {
                        })
                        Notification.closeAll()
                    }
                })
            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        fetchCommunicationCallDispositionSummary() {
            this.loading = true
            axios.get('/api/v1/reports/call-dispositions', {
                params: {
                    from_date: this.filter.from_date,
                    to_date: this.filter.to_date,
                }
            }).then(res => {
                let data = res.data

                if (data) {
                    this.summary.total = data['calls']
                    this.summary.inbound_total = data['inbound_calls']
                    this.summary.outbound_total = data['outbound_calls']
                    delete data['calls']
                    delete data['inbound_calls']
                    delete data['outbound_calls']
                    this.summary.dispositions = Object.entries(data).sort((a,b) => b[1] - a[1])
                }

                this.loading = false
            })
        },

        fetchCommunicationCallDispositionReports(url = '') {
            let endpoint = url ? url : '/api/v1/reports/call-dispositions/' + this.group_by
            this.loading_table = true

            axios.get(endpoint, {
                params: {
                    from_date: this.filter.from_date,
                    to_date: this.filter.to_date,
                    per_page: this.pagination.per_page
                }
            }).then(res => {
                if (this.group_by === 'day') {
                    this.headers = [
                        'day', 'calls', 'outbound_calls', 'inbound_calls'
                    ]
                }
                if (this.group_by === 'user') {
                    this.headers = [
                        'name', 'start_date', 'end_date', 'calls', 'outbound_calls', 'inbound_calls'
                    ]
                }

                this.data = res.data.data
                this.pagination.per_page = parseInt(res.data.per_page)
                this.pagination.current_page = parseInt(res.data.current_page)
                this.pagination.path = res.data.path
                this.pagination.first_page_url = res.data.first_page_url
                this.pagination.prev_page_url = res.data.prev_page_url
                this.pagination.next_page_url = res.data.next_page_url
                this.pagination.total = parseInt(res.data.total)

                this.loading_table = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },
    },

    watch: {
        'filter.from_date': function() {
            this.fetchCommunicationCallDispositionReports()
            this.fetchCommunicationCallDispositionSummary()
        },

        group_by() {
            this.fetchCommunicationCallDispositionReports()
            this.fetchCommunicationCallDispositionSummary()
        }
    }
}
</script>
