<template>
    <div class="card p-3">
        <div class="d-flex mb-3">
            <img src="/assets/images/app-icons/new/sms_icon.svg" class="mr-2" />
            <h6 class="mb-0 align-self-center">Inbound SMS Delivery</h6>
        </div>

        <div class="container-outbound-connection-rate-widget mb-5">
            <div class="label-connection-rate" style="transform: translateX(-50%); bottom: 0">
                <h2>{{ metrics.inbound_texts_delivery_rate }}%</h2>
                <p>SMS Delivery Rate</p>
            </div>

            <highcharts :options="options"
                        ref="chart">
            </highcharts>
        </div>

        <div class="row">
            <div class="col-6">
                <metric-widget label="Delivered Inbound SMS"
                               description="Text messages that have been successfully delivered"
                               :metric="metrics.inbound_texts_delivered">
                </metric-widget>
            </div>
            <div class="col-6">
                <metric-widget label="Failed Inbound SMS"
                               description="Text messages that have failed"
                               :metric="metrics.inbound_texts_failed">
                </metric-widget>
            </div>
        </div>
    </div>
</template>

<script>
import MetricWidget from "./metric-widget";
import DashboardFilter from '../../../filters/dashboard-filter'

export default {
    name: "inbound-sms-rate-widget",
    components: {MetricWidget},

    data() {
        return {
            metrics: {},
            options: {
                exporting: {
                    enabled: false
                },
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    height: 200,
                    spacing: [0,0,0,0],
                    marginTop: 60,
                    marginBottom: -60
                },
                title: {
                    text: null,
                    enabled: false
                },
                tooltip: {
                    enabled: false,
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '65%'],
                        allowPointSelect: false,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            distance: -50,
                        },
                        size: '175%'
                    },
                    series: {
                        animation: {
                            duration: 0
                        }
                    }
                },
                series: [{
                    name: 'Rate',
                    colorByPoint: true,
                    data: [],
                    innerSize: '95%'
                }]
            }
        }
    },

    computed: {
        kpiFilters() {
            return DashboardFilter.attributions()
        }
    },

    mounted() {
        this.loadInboundSmsDeliveryRate()
    },

    methods: {
        loadInboundSmsDeliveryRate() {
            this.loading = true
            axios.get(`/api/v1/kpis/communication/inbound/sms`, {
                params: {
                    aggregates: [
                        32, // INBOUND_DELIVERED_TEXTS
                        33, // INBOUND_FAILED_TEXTS
                        34, // INBOUND_SMS_DELIVERY_RATE
                    ],
                    filters: DashboardFilter.get()
                }
            }).then(res => {
                this.loading = false
                this.setInboundSmsDeliveryRate(res.data)
            })
        },

        setInboundSmsDeliveryRate(metrics) {
            this.metrics = metrics
            this.options.series[0].data = [
                {
                    name: 'Delivered Inbound SMS',
                    y: metrics.inbound_texts_delivered.current_value,
                    color: '#00bf4a'
                },
                {
                    name: 'Failed Inbound SMS',
                    y: metrics.inbound_texts_failed.current_value,
                    color: '#e9e9e9'
                },
            ]
            this.loading = false
        }
    },

    watch: {
        kpiFilters() {
            this.loadInboundSmsDeliveryRate()
        }
    }
}
</script>
