var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-padding auto",
          attrs: {
            title: "Rename Broadcast",
            "append-to-body": "",
            width: _vm.isLargeEnough ? "400px" : "100%",
            visible: _vm.hide_edit,
          },
          on: {
            "update:visible": function ($event) {
              _vm.hide_edit = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "rename_broadcast",
              staticClass: "p-0",
              attrs: { "label-position": "top", model: _vm.broadcast },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveBroadcastName.apply(null, arguments)
                },
              },
            },
            [
              _c("el-form-item", { attrs: { prop: "name", label: "Name" } }, [
                _c(
                  "div",
                  { staticClass: "width-400" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Broadcast Name" },
                      on: {
                        input: function ($event) {
                          return _vm.preValidateForm("rename_broadcast")
                        },
                      },
                      model: {
                        value: _vm.edit_broadcast.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.edit_broadcast, "name", $$v)
                        },
                        expression: "edit_broadcast.name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row pb-3" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "pull-right ml-2",
                    attrs: {
                      type: "success",
                      disabled:
                        _vm.loading_btn || _vm.edit_broadcast.name.length < 1,
                    },
                    on: { click: _vm.saveBroadcastName },
                  },
                  [
                    _c(
                      "i",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading_btn,
                            expression: "loading_btn",
                          },
                        ],
                        staticClass: "material-icons loader",
                      },
                      [_vm._v("")]
                    ),
                    _vm._v(" "),
                    _c(
                      "i",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.loading_btn,
                            expression: "!loading_btn",
                          },
                        ],
                        staticClass: "material-icons",
                      },
                      [
                        _vm._v(
                          "\n                        save\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v("Save")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "pull-right",
                    attrs: { type: "info" },
                    on: { click: _vm.closeModal },
                  },
                  [
                    _c("span", { staticClass: "fa fa-close" }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Cancel")]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("trigger", function () {
        return [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-warning btn-block",
              attrs: { slot: "reference" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.hide_edit = true
                },
              },
              slot: "reference",
            },
            [
              _c("i", { staticClass: "fa fa-pencil" }),
              _vm._v(" Rename\n        "),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }