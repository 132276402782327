// agent is offline
export const AGENT_STATUS_OFFLINE = 0
// agent is accepting calls
export const AGENT_STATUS_ACCEPTING_CALLS = 1
// agent is not accepting calls
export const AGENT_STATUS_NOT_ACCEPTING_CALLS = 2
// agent is on break
export const AGENT_STATUS_ON_BREAK = 3
// agent is on call
export const AGENT_STATUS_ON_CALL = 4
// agent is on wrap-up
export const AGENT_STATUS_ON_WRAP_UP = 5
// we are ringing agent
export const AGENT_STATUS_RINGING = 6
// agent is on auto dial state
export const AGENT_STATUS_AUTO_DIAL = 7
// agent is on sentry state
export const AGENT_STATUS_SENTRY = 8
