var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wizard" },
    [
      _c(
        "div",
        {
          staticClass:
            "wizard-header d-flex justify-content-between align-items-center",
        },
        [
          _c("h2", [_vm._v("Add Verified Caller ID")]),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "text-blue cursor-pointer",
              on: {
                click: function ($event) {
                  _vm.dialogWhatIsVerifiedCallerId = true
                },
              },
            },
            [_vm._v("\n            What is verified caller ID?\n        ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-divider" }),
      _vm._v(" "),
      _c("div", { class: { calling: _vm.pin } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _vm.pin
                ? _c("div", [
                    _c("p", { staticClass: "text-center text-lg mt-4" }, [
                      _vm._v(
                        "\n                        We are calling the following number: "
                      ),
                      _c("strong", [_vm._v(_vm._s(_vm.fixedPhone))]),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n                        Please answer and then enter the PIN code on your mobile device.\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-3x text-yellow text-monospace font-weight-bold text-separator text-center",
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "pin-code p-2 text-center" },
                          [_vm._v(_vm._s(_vm.pin))]
                        ),
                      ]
                    ),
                  ])
                : _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "add-new-caller-id",
                      attrs: { "label-position": "top", "prevent-default": "" },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "countryCode",
                          staticClass: "mb-4",
                          attrs: { "label-position": "top", inline: true },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "w-100",
                              attrs: { label: "Country" },
                            },
                            [
                              _c("country-selector", {
                                attrs: {
                                  show_prefix: true,
                                  force_initial_country_code: true,
                                  show_tips: true,
                                  disabled: _vm.loading,
                                },
                                on: { changed: _vm.changeCountry },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          ref: "phone_number",
                          attrs: { "prevent-default": "" },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "d-inline-block width-300 fixed",
                              attrs: { label: "Phone number" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  disabled: _vm.loading,
                                  placeholder: "Enter a phone number",
                                  clearable: "",
                                  maxlength: "15",
                                },
                                model: {
                                  value: _vm.phoneNumber,
                                  callback: function ($$v) {
                                    _vm.phoneNumber = $$v
                                  },
                                  expression: "phoneNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-right" },
          [
            !_vm.pin
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "success",
                      disabled: _vm.sendButtonDisabled,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submitForm.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n                Verify number\n            ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "10vh",
            title: "What is verified caller ID?",
            width: "550px",
            visible: _vm.dialogWhatIsVerifiedCallerId,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogWhatIsVerifiedCallerId = $event
            },
          },
        },
        [
          _c("p", { staticClass: "text-muted" }, [
            _vm._v(
              "\n            Verified Caller IDs cannot be used as the display name on outgoing SMS or MMS message.\n            "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "\n            Verified Caller IDs are intended to be used with voice services only.\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.dialogWhatIsVerifiedCallerId = false
                    },
                  },
                },
                [_vm._v("\n                Ok\n            ")]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }