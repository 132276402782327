var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("span", {
            staticClass: "page-anchor",
            attrs: { id: "cnam_caller_id" },
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "mb-2" }, [_vm._t("default")], 2),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-11 grey-background" }, [
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _vm._t("user-selector", null, {
              onUserFilterChange: _vm.onUserFilterChange,
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "el-select",
              {
                staticClass: "no-select",
                attrs: {
                  ignore_focus_mode: true,
                  placeholder: "Select an Event Type",
                  filterable: "",
                  "data-testid": "action-filter-dropdown",
                  clearable: "",
                },
                on: {
                  change: function ($event) {
                    return _vm.onActionFilterChange($event)
                  },
                },
                model: {
                  value: _vm.filters.type_filter,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "type_filter", $$v)
                  },
                  expression: "filters.type_filter",
                },
              },
              _vm._l(_vm.action_filters, function (action, index) {
                return _c("el-option", {
                  key: index,
                  attrs: {
                    value: _vm.generateOptionValue(action.filter),
                    label: action.name,
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-3" }, [_vm._t("date-selector")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-2 total-log" }, [
          _c("span", [
            _vm._v("Total Logs: "),
            _c("b", [_vm._v(_vm._s(this.pagination.total))]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "el-button-group",
                { staticClass: "button-group" },
                [
                  !_vm.pagination.prev_page_url ||
                  _vm.pagination_loading ||
                  _vm.loading
                    ? _c("el-button", {
                        attrs: {
                          type: "text",
                          size: "medium",
                          icon: "el-icon-arrow-left",
                          disabled: "",
                        },
                      })
                    : _c("el-button", {
                        attrs: {
                          type: "text",
                          size: "medium",
                          icon: "el-icon-arrow-left",
                        },
                        on: { click: _vm.previousPage },
                      }),
                  _vm._v(" "),
                  _c("div", { staticClass: "current_page" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.pagination.current_page) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.pagination.next_page_url ||
                  _vm.pagination_loading ||
                  _vm.loading
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "medium", disabled: "" },
                        },
                        [_c("i", { staticClass: "el-icon-arrow-right" })]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "medium" },
                          on: { click: _vm.nextPage },
                        },
                        [_c("i", { staticClass: "el-icon-arrow-right" })]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "w-full mt-3",
          attrs: {
            stripe: "",
            "row-key": "id",
            data: _vm.items,
            "data-testid": "audits-table",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand", "data-testid": "audits-table-column" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "row align-items-top" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item pull-left",
                              attrs: {
                                effect: "dark",
                                content: "Click For More Info",
                                "data-testid": "audits-tooltip-for-more-info",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text-dark-blueish",
                                  attrs: { title: "Changes" },
                                },
                                [
                                  _c("strong", [
                                    _vm._v("Audit #" + _vm._s(scope.row.id)),
                                  ]),
                                  _vm._v(" "),
                                  _c("hr"),
                                  _vm._v(" "),
                                  _vm._l(
                                    scope.row.changes,
                                    function (change, attribute) {
                                      return _c(
                                        "div",
                                        { key: attribute },
                                        [
                                          _c("div", [
                                            _c("strong", [_vm._v("Attribute")]),
                                            _vm._v(": " + _vm._s(attribute)),
                                          ]),
                                          _vm._v(" "),
                                          change && change.constructor === Array
                                            ? [
                                                _c("div", [
                                                  _c("strong", [
                                                    _vm._v("Before"),
                                                  ]),
                                                  _vm._v(
                                                    ": " +
                                                      _vm._s(
                                                        change[0] ?? "None"
                                                      )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _c("strong", [
                                                    _vm._v("After"),
                                                  ]),
                                                  _vm._v(
                                                    ": " +
                                                      _vm._s(
                                                        change[1] ?? "None"
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            : _c("div", [
                                                _c("strong", [_vm._v("Value")]),
                                                _vm._v(": " + _vm._s(change)),
                                              ]),
                                          _vm._v(" "),
                                          _c("hr"),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "User", prop: "user.name", "min-width": 300 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "row d-flex align-items-center" },
                      [
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "row" }, [
                            scope.row.user
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex align-items-center justify-content-left",
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "User Dialog",
                                            params: {
                                              user_id: scope.row.user.id,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item pull-left",
                                            attrs: {
                                              effect: "dark",
                                              content: "Click For More Info",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-dark-greenish",
                                                attrs: {
                                                  title: scope.row.user.name,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      scope.row.user.name
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex align-items-center justify-content-left",
                                  },
                                  [_c("b", [_vm._v("No user")])]
                                ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Action", "min-width": "600" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    _vm.actionLabels(scope.row),
                    function (label, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "truncate" },
                        [
                          index > 0 ? _c("hr") : _vm._e(),
                          _vm._v(" "),
                          _c("img", {
                            attrs: {
                              src: _vm.icons[label.icon],
                              alt: label.icon,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: { effect: "dark", placement: "top" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(label.text),
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", {
                                domProps: { innerHTML: _vm._s(label.text) },
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Time",
              prop: "created_at",
              "data-testid": "audits-table-column",
              width: "240",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "text-greyish" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("fixDateTimeCompanyTimeZone")(
                            scope.row.created_at
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            !_vm.loading ? _c("span", [_vm._v("No Data")]) : _vm._e(),
            _vm._v(" "),
            _vm.loading ? _c("span") : _vm._e(),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }