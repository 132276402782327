<template>
    <div>
        <div :class="{ blink: loading }"
             class="placeholder text-center d-flex align-items-center justify-content-center"
             style="height: 300px"
             v-if="!data_loaded || loading">

            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div class="force-scrollbar-view"
             style="height: 300px"
             v-else>
             <highcharts :options="graphOptions"
                        :style="{height: '300px'}"
                        ref="highchart"
                        v-bind:id="graph_id"
                        v-show="is_done && options.series.length > 0">
             </highcharts>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {mapGetters} from 'vuex'

    export default {
        props: {
            chart_data: {
                required: true,
                default: []
            },
            type: {
                required: true,
                default: 'all_calls'
            },
            data_loaded: {
                required: true,
                default: false
            },
            chart_type: {
                required: false,
                default: 'bar'
            }
        },

        data() {
            return {
                auth: auth,
                is_done: false,
                loading: true,
                axisLabels: [],
                graph_id: this.type.replace(/_/g, ' ') + '_csat_graph',
                options: {
                    chart: {
                        type: this.chart_type,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        },
                        series: {
                            cursor: 'pointer',
                            pointWidth: 30,
                            point: {
                                events: {
                                    click: (e) => {
                                        this.pointClick(e)
                                    },
                                }
                            }
                        }
                    },
                    tooltip: {
                        shared: false,
                        useHTML: true,
                        borderWidth: 0,
                        shadow: false,
                        backgroundColor: 'rgba(255,255,255,0)',
                        formatter: function() {
                            return '<div style="color:#fff; background-color:' + this.point.color + '" class="csat-tooltip"> ' + this.y + '</div>';
                        }
                    },
                    title: {
                        text: this.$options.filters.capitalize(this.type.replace(/_/g, ' ')),
                        style: {
                            color: 'transparent'
                        }
                    },
                    xAxis: {
                        categories: ['1', '2', '3', '4', '5'],
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Number of calls'
                        },
                    },
                    legend: {
                        enabled: false
                    },
                },
                graph_label: 'All Calls',
            }
        },

        computed: {
            ...mapGetters({
                filter: 'getFilter'
            }),

            graphOptions() {
                return this.options
            },
        },

        mounted() {
            this.generateGraphData()
        },

        methods: {
            pointClick(event) {
                this.$emit('clicked', {
                    user_id: event.point.series.userOptions.user_id,
                    csat_ratio: event.point.series.userOptions.csat_ratio,
                })
                console.log(event.point.series.userOptions.user_id)
            },
            generateGraphData: function () {
                this.loading = true
                this.is_done = false

                this.options.series = []
                let chartSeries  = this.chart_data

                // Show chart when no data available
                if(this.chart_data.length === 0) {
                    this.options.series = [];
                    chartSeries.push({
                        name: '1',
                        data: [0]
                    })
                }
                this.options.series = chartSeries

                this.is_done = true
                this.loading = false
            },
        },

        watch: {
            data_loaded(new_val, old_val) {
                if(new_val && !old_val) {
                    this.generateGraphData()
                }
            },
        },

        destroyed() {
            this.loading = true
            this.is_done = false
        }
    }
</script>
<style>
.csat-tooltip {
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 5px 10px;
    border: 1px dotted green;

}
</style>
