var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("create tag")
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              staticClass: "dialog-padding auto",
              attrs: {
                title: _vm.title,
                width: _vm.isLargeEnough ? "30%" : "100%",
                visible: _vm.hide_add,
                "before-close": _vm.beforeCloseModal,
                "data-testid": "tag-creator-dialog",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.hide_add = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "add_tag",
                  staticClass: "mt-2 p-0",
                  attrs: {
                    "label-position": "top",
                    rules: _vm.rules_tag,
                    model: _vm.tag,
                    "data-testid": "tag-creator-form",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.addTag.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "name", label: "Name" } },
                            [
                              _c(
                                "div",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "data-testid": "tag-creator-name-input",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.preValidateForm("add_tag")
                                      },
                                    },
                                    model: {
                                      value: _vm.tag.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tag, "name", $$v)
                                      },
                                      expression: "tag.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "tag-color-picker",
                              attrs: { prop: "color", label: "Choose a color" },
                            },
                            [
                              _c("el-color-picker", {
                                staticClass: "d-block",
                                attrs: {
                                  "data-testid": "tag-creator-color-picker",
                                },
                                on: {
                                  "active-change": _vm.changeTagColor,
                                  change: function ($event) {
                                    return _vm.preValidateForm("add_tag")
                                  },
                                },
                                model: {
                                  value: _vm.tag.color,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tag, "color", $$v)
                                  },
                                  expression: "tag.color",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          !_vm.category_prop
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "category",
                                    label: "Category",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "w-full",
                                          attrs: {
                                            "data-testid":
                                              "tag-creator-category-select",
                                          },
                                          model: {
                                            value: _vm.tag.category,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.tag, "category", $$v)
                                            },
                                            expression: "tag.category",
                                          },
                                        },
                                        _vm._l(
                                          _vm.categories,
                                          function (category) {
                                            return _c("el-option", {
                                              key: category,
                                              attrs: {
                                                value: category,
                                                label: _vm._f(
                                                  "translateTagCategory"
                                                )(category),
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "description",
                                label: "Description (Optional)",
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: "2",
                                      "data-testid":
                                        "tag-creator-description-textarea",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.preValidateForm("add_tag")
                                      },
                                    },
                                    model: {
                                      value: _vm.tag.description,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tag, "description", $$v)
                                      },
                                      expression: "tag.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-right ml-2",
                        attrs: {
                          type: "success",
                          disabled: _vm.loading_btn,
                          "data-testid": "tag-creator-add-button",
                        },
                        on: { click: _vm.addTag },
                      },
                      [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading_btn,
                                expression: "loading_btn",
                              },
                            ],
                            staticClass: "material-icons loader",
                          },
                          [_vm._v("")]
                        ),
                        _vm._v(" "),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.loading_btn,
                              expression: "!loading_btn",
                            },
                          ],
                          staticClass: "fa fa-plus",
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Add")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-right",
                        attrs: {
                          type: "info",
                          "data-testid": "tag-creator-cancel-input",
                        },
                        on: {
                          click: function ($event) {
                            _vm.hide_add = false
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "fa fa-close" }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Cancel")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("trigger", function () {
            return [
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "reference",
                    type: "text text-sm text-dark-greenish",
                    "data-testid": "tag-creator-add-tag-slot",
                  },
                  on: {
                    click: function ($event) {
                      _vm.hide_add = true
                    },
                  },
                  slot: "reference",
                },
                [
                  _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                  _vm._v("\n            Add Tag\n        "),
                ]
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }