<template>
    <div>
        <div class="row b-b">
            <div class="col-12 p-3">
                <p class="form-head-message mb-2">Connect your website forms and capture your leads by embedding our simple script on your website.</p>
                <p class="form-head-message mb-2">By default our script will bind itself to the first form in your page. You can pass the options object to <b>alowareInit</b> function as the secondary argument to override the default settings.</p>
                <p class="mb-2">
                    <strong>Options:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    form_id: "myform", // optional if not set will bind to first form on the page
    line_id: 1234, // optional
    sequence_id: 1234, // optional
    user_id: 1234, // optional
    tag_id: 1234, // optional
    disposition_status_id: 1234, // optional
    force_update: true, // optional
    force_update_sequence: false // optional
}</code></pre>
                <p class="mb-2 mt-4">
                    <strong>Vanilla JavaScript:</strong>
                </p>
                <div v-if="!loading_whitelabel">
                    <p class="form-head-message text-left">
                        Add the script below before the closing <b>&lt;/body&gt;</b> tag:
                    </p>
                    <div class="input-group">
                        <pre v-highlightjs class="code-bg form-control text-sm"><code @click="copyVanillaScriptTag"
                                                                                      ref="vanilla_script"
                                                                                      class="html javascript json">&lt;script src="{{ host | fixDomain }}/form/vanilla-js-lead-api.min.js"&gt;&lt;/script&gt;
&lt;script&gt;alowareInit("{{ current_company.form_capture_token }}", { force_update: true });&lt;/script&gt;</code></pre>
                        <span class="input-group-append">
                            <button class="btn greenish"
                                    type="button"
                                    @click="copyVanillaScriptTag">
                                Copy
                            </button>
                        </span>
                    </div>
                </div>
                <p class="mb-2 mt-4">
                    <strong>jQuery:</strong>
                </p>
                <div v-if="!loading_whitelabel">
                    <p class="form-head-message text-left">
                        Add the script below before the closing <b>&lt;/body&gt;</b> tag:
                    </p>
                    <div class="input-group">
                        <pre v-highlightjs class="code-bg form-control text-sm"><code @click="copyJqueryScriptTag"
                                                                                      ref="jquery_script"
                                                                                      class="html javascript json">&lt;script src="{{ host | fixDomain }}/form/jquery-lead-api.min.js"&gt;&lt;/script&gt;
&lt;script&gt;alowareInit("{{ current_company.form_capture_token }}", { force_update: true });&lt;/script&gt;</code></pre>
                        <span class="input-group-append">
                            <button class="btn greenish"
                                    type="button"
                                    @click="copyJqueryScriptTag">
                                Copy
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {mapState} from 'vuex'

    export default {
        data() {
            return {
                auth: auth,
                host: null,
                whitelabel: false,
                loading_whitelabel: true
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),
        },

        created() {
            this.getWhitelabelStatus()
        },

        methods: {
            selectText(element) {
                var range
                if (document.selection) {
                    // IE
                    range = document.body.createTextRange()
                    range.moveToElementText(element)
                    range.select()
                } else if (window.getSelection) {
                    range = document.createRange()
                    range.selectNode(element);
                    window.getSelection().removeAllRanges()
                    window.getSelection().addRange(range)
                }
            },

            copyJqueryScriptTag() {
                let element = this.$refs['jquery_script']
                this.selectText(element)
                document.execCommand("copy")
            },

            copyVanillaScriptTag() {
                let element = this.$refs['vanilla_script']
                this.selectText(element)
                document.execCommand('copy')
            },

            getWhitelabelStatus() {
                this.loading_whitelabel = true
                axios.get('/get-statics')
                    .then(res => {
                        this.host = res.data.host
                        this.whitelabel = res.data.whitelabel
                        this.loading_whitelabel = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.loading_whitelabel = false
                        this.$root.handleErrors(err.response)
                    })
            }
        }
    }
</script>

