var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-card", { staticClass: "salesforce-contact-settings-card" }, [
    _vm.isLoading
      ? _c("div", { staticClass: "text-center mt-2" }, [
          _c("h5", [
            _c("i", { staticClass: "fa fa-spinner fa-spin" }),
            _vm._v(" "),
            _c("span", [_vm._v("Loading...")]),
          ]),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("h5", [_vm._v("Contact")]),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: { effect: "dark", placement: "top" },
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    !_vm.salesforce_settings.enable_lead
                      ? _c("p", [
                          _vm._v(
                            "Enable Contact as default Salesforce CRM Module"
                          ),
                        ])
                      : _c("p", [
                          _vm._v(
                            "Contacts can't be enabled if Lead is already enabled"
                          ),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticClass: "mt-1 ml-2",
                    attrs: {
                      disabled: _vm.salesforce_settings.enable_lead,
                      "active-color": "#00a344",
                    },
                    model: {
                      value: _vm.salesforce_settings.enable_contact,
                      callback: function ($$v) {
                        _vm.$set(_vm.salesforce_settings, "enable_contact", $$v)
                      },
                      expression: "salesforce_settings.enable_contact",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row form-group mt-3" },
            [
              [
                _c("div", { staticClass: "col-md-4 mb-1" }, [
                  _c("small", { staticClass: "font-weight-bold" }, [
                    _vm._v("Aloware"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "top" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _c("p", [
                        _vm._v(
                          "Direction on Contact Creation can't be changed."
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-2 mb-1 text-center" }, [
                      _c("small", { staticClass: "font-weight-bold" }, [
                        _vm._v("Direction"),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4 mb-1" }, [
                  _c("small", { staticClass: "font-weight-bold" }, [
                    _vm._v("Salesforce"),
                  ]),
                ]),
              ],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row form-group mt-3" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mb-1" },
                  [
                    _c("el-input", {
                      attrs: {
                        readonly: "",
                        disabled:
                          !_vm.salesforce_settings.enable_contact ||
                          !_vm.salesforce_settings.contacts.should_create,
                        value: _vm.getSalesforceLabels("contact.create"),
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-2 text-center" },
              [
                _c("direction-selector", {
                  attrs: {
                    "initial-state":
                      _vm.salesforce_settings.contacts.create_direction,
                    disabled:
                      !_vm.salesforce_settings.enable_contact ||
                      !_vm.salesforce_settings.contacts.should_create,
                  },
                  on: {
                    change: (direction) =>
                      (_vm.salesforce_settings.contacts.create_direction =
                        direction),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mb-1" },
                  [
                    _c("el-input", {
                      attrs: {
                        readonly: "",
                        disabled:
                          !_vm.salesforce_settings.enable_contact ||
                          !_vm.salesforce_settings.contacts.should_create,
                        value: _vm.getSalesforceLabels("contact.create"),
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-2 text-center" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "top" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _c("p", [
                        _vm._v(
                          "Enable creating a new contact in Salesforce when a new contact is created in Aloware"
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-switch", {
                      staticClass: "mt-1 ml-2",
                      attrs: {
                        disabled: !_vm.salesforce_settings.enable_contact,
                        "active-color": "#00a344",
                      },
                      model: {
                        value: _vm.salesforce_settings.contacts.should_create,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.salesforce_settings.contacts,
                            "should_create",
                            $$v
                          )
                        },
                        expression:
                          "salesforce_settings.contacts.should_create",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row form-group mt-3" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mb-1" },
                  [
                    _c("el-input", {
                      attrs: {
                        readonly: "",
                        disabled:
                          !_vm.salesforce_settings.enable_contact ||
                          !_vm.salesforce_settings.contacts.should_update,
                        value: _vm.getSalesforceLabels("contact.update"),
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-2 text-center" },
              [
                _c("direction-selector", {
                  attrs: {
                    "initial-state":
                      _vm.salesforce_settings.contacts.update_direction,
                    disabled:
                      !_vm.isDirectionEditable(
                        _vm.getSalesforceLabels("contact.update")
                      ) ||
                      !_vm.salesforce_settings.enable_contact ||
                      !_vm.salesforce_settings.contacts.should_update,
                  },
                  on: {
                    change: (direction) =>
                      (_vm.salesforce_settings.contacts.update_direction =
                        direction),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mb-1" },
                  [
                    _c("el-input", {
                      attrs: {
                        readonly: "",
                        disabled:
                          !_vm.salesforce_settings.enable_contact ||
                          !_vm.salesforce_settings.contacts.should_update,
                        value: _vm.getSalesforceLabels("contact.update"),
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-2 text-center" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "top" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _c("p", [
                        _vm._v(
                          "Enable updating a contact in Salesforce when a contact is updated in Aloware"
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-switch", {
                      staticClass: "mt-1 ml-2",
                      attrs: {
                        disabled: !_vm.salesforce_settings.enable_contact,
                        "active-color": "#00a344",
                      },
                      model: {
                        value: _vm.salesforce_settings.contacts.should_update,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.salesforce_settings.contacts,
                            "should_update",
                            $$v
                          )
                        },
                        expression:
                          "salesforce_settings.contacts.should_update",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("h5", [_vm._v("Lead")]),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: { effect: "dark", placement: "right" },
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    !_vm.salesforce_settings.enable_contact
                      ? _c("p", [
                          _vm._v(
                            "Enable Lead as default Salesforce CRM Module"
                          ),
                        ])
                      : _c("p", [
                          _vm._v(
                            "Leads can't be enabled if Contact is already enabled"
                          ),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticClass: "mt-1 ml-2",
                    attrs: {
                      disabled: _vm.salesforce_settings.enable_contact,
                      "active-color": "#00a344",
                    },
                    model: {
                      value: _vm.salesforce_settings.enable_lead,
                      callback: function ($$v) {
                        _vm.$set(_vm.salesforce_settings, "enable_lead", $$v)
                      },
                      expression: "salesforce_settings.enable_lead",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row form-group mt-3" },
            [
              [
                _c("div", { staticClass: "col-md-4 mb-1" }, [
                  _c("small", { staticClass: "font-weight-bold" }, [
                    _vm._v("Aloware"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "top" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _c("p", [
                        _vm._v(
                          "Direction on Contact Creation can't be changed."
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-2 mb-1 text-center" }, [
                      _c("small", { staticClass: "font-weight-bold" }, [
                        _vm._v("Direction"),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4 mb-1" }, [
                  _c("small", { staticClass: "font-weight-bold" }, [
                    _vm._v("Salesforce"),
                  ]),
                ]),
              ],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row form-group mt-3" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mb-1" },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm.getSalesforceLabels("contact.create"),
                        disabled:
                          !_vm.salesforce_settings.enable_lead ||
                          !_vm.salesforce_settings.leads.should_create,
                        readonly: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-2 text-center" },
              [
                _c("direction-selector", {
                  attrs: {
                    "initial-state":
                      _vm.salesforce_settings.leads.create_direction,
                    disabled: !_vm.salesforce_settings.enable_lead,
                  },
                  on: {
                    change: (direction) =>
                      (_vm.salesforce_settings.leads.create_direction =
                        direction),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mb-1" },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm.getSalesforceLabels("lead.create"),
                        disabled:
                          !_vm.salesforce_settings.enable_lead ||
                          !_vm.salesforce_settings.leads.should_create,
                        readonly: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-2 text-center" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "right" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _c("p", [
                        _vm._v(
                          " Enable creating a new lead in Salesforce when a new contact is created in Aloware "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-switch", {
                      staticClass: "mt-1 ml-2",
                      attrs: {
                        disabled: !_vm.salesforce_settings.enable_lead,
                        "active-color": "#00a344",
                      },
                      model: {
                        value: _vm.salesforce_settings.leads.should_create,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.salesforce_settings.leads,
                            "should_create",
                            $$v
                          )
                        },
                        expression: "salesforce_settings.leads.should_create",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row form-group mt-3" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mb-1" },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm.getSalesforceLabels("contact.update"),
                        disabled:
                          !_vm.salesforce_settings.enable_lead ||
                          !_vm.salesforce_settings.leads.should_update,
                        readonly: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-2 text-center" },
              [
                _c("direction-selector", {
                  attrs: {
                    "initial-state":
                      _vm.salesforce_settings.leads.update_direction,
                    disabled:
                      !_vm.isDirectionEditable(
                        _vm.getSalesforceLabels("lead.update")
                      ) ||
                      !_vm.salesforce_settings.enable_lead ||
                      !_vm.salesforce_settings.leads.should_update,
                  },
                  on: {
                    change: (direction) =>
                      (_vm.salesforce_settings.leads.update_direction =
                        direction),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mb-1" },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm.getSalesforceLabels("lead.update"),
                        disabled:
                          !_vm.salesforce_settings.enable_lead ||
                          !_vm.salesforce_settings.leads.should_update,
                        readonly: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-2 text-center" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "right" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _c("p", [
                        _vm._v(
                          " Enable updating a lead in Salesforce when a contact is updated in Aloware "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-switch", {
                      staticClass: "mt-1 ml-2",
                      attrs: {
                        disabled: !_vm.salesforce_settings.enable_lead,
                        "active-color": "#00a344",
                      },
                      model: {
                        value: _vm.salesforce_settings.leads.should_update,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.salesforce_settings.leads,
                            "should_update",
                            $$v
                          )
                        },
                        expression: "salesforce_settings.leads.should_update",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }