var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pipedriveEnabled
    ? _c(
        "div",
        { staticClass: "d-flex flex-row align-items-center" },
        [
          _c(
            "el-select",
            {
              staticClass: "d-flex flex-grow-1",
              attrs: {
                placeholder: _vm.placeholder,
                multiple: _vm.multiple,
                "multiple-limit": _vm.multiple ? _vm.multipleLimit : 0,
                loading: _vm.loading_pipedrive_filters,
                disabled:
                  !_vm.loading_pipedrive_filters &&
                  !_vm.getPipedriveFilters.length,
                filterable: "",
                clearable: "",
                "default-first-option": "",
                "collapse-tags": "",
              },
              model: {
                value: _vm.filter_id,
                callback: function ($$v) {
                  _vm.filter_id = $$v
                },
                expression: "filter_id",
              },
            },
            _vm._l(_vm.filtersAlphabeticalOrder, function (list) {
              return _c("el-option", {
                key: list.id,
                attrs: { label: list.name, value: list.id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-button", {
            staticClass: "ml-2 w-30",
            attrs: {
              type: "primary",
              icon: "fa fa-refresh",
              size: "mini",
              disabled: _vm.loading_pipedrive_filters,
              loading: _vm.loading_pipedrive_filters,
              circle: "",
            },
            on: { click: _vm.handleFetchPipedriveFilters },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }