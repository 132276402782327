var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "card p-3 h-100",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-7" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "container-outbound-connection-rate-widget mb-5 col-12",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "label-connection-rate",
                    staticStyle: { transform: "translateX(-50%)", bottom: "0" },
                  },
                  [
                    _c("metric-widget", {
                      attrs: {
                        label: "Engagement Rate",
                        description: "",
                        metric: _vm.metrics.engagement_rate,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("highcharts", {
                  ref: "chart",
                  attrs: { options: _vm.options },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("metric-widget", {
                  attrs: {
                    label: "Total Sent",
                    description: "Total delivered broadcast",
                    metric: _vm.metrics.total_sent,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("metric-widget", {
                  attrs: {
                    label: "Total Failed",
                    description: "Total failed broadcast",
                    metric: _vm.metrics.total_failed,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-5 d-flex flex-column justify-content-center justify-content-around",
          },
          [
            _c("metric-widget", {
              attrs: {
                label: "Total",
                description: "Total Broadcast",
                metric: _vm.metrics.count,
              },
            }),
            _vm._v(" "),
            _c("metric-widget", {
              attrs: {
                label: "Total Replied",
                description: "Total Reply Broadcast",
                metric: _vm.metrics.total_replied,
              },
            }),
            _vm._v(" "),
            _c("metric-widget", {
              attrs: {
                label: "Total Unsubscribed",
                description: "Total unsubscribed broadcast",
                metric: _vm.metrics.total_unsubscribed,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex mb-3" }, [
      _c("i", {
        staticClass: "fa fa-bullhorn text-greenish mr-2 align-self-center",
      }),
      _vm._v(" "),
      _c("h6", { staticClass: "mb-0 align-self-center" }, [
        _vm._v("Broadcasts"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }