<template>
    <div class="row">
        <div class="col-md-8">
            <div class="row b-b">
                <div class="col-12 p-3">
                    <company-form-token></company-form-token>
                </div>
            </div>
            <div class="row">
                <div class="col-12 p-3">
                    <div class="el-form-item mb-2">
                        <span class="page-anchor"
                              id="enroll">
                        </span>
                        <strong class="integration-api-titles">Enroll a contact to a sequence through API</strong>
                    </div>
                    <p class="text-md">
                        <span class="text-dark-greenish _600">POST /api/v1/webhook/sequence-enroll</span>
                    </p>

                    <p>
                        <strong>Required Fields:</strong>
                        <br>
                        <span class="text-dark-greenish _600">api_token</span><br>
                        <span class="text-dark-greenish _600">sequence_id</span><br>
                        <span class="text-dark-greenish _600">source</span><br>
                        <span class="text-dark-greenish _600">phone_number <span class="text-black-lt">(if source is 'phone_number')</span></span><br>
                        <span class="text-dark-greenish _600">id <span class="text-black-lt">(if source is not 'phone_number', e.g. aloware, hubspot, zoho, guesty and pipedrive)</span></span>
                    </p>

                    <p>
                        <strong>Example POST URL:</strong>
                        <br>
                        <span class="text-dark-greenish _600">{{ statics.domain | fixDomain }}/api/v1/webhook/sequence-enroll</span>
                    </p>

                    <p class="mb-2">
                        <strong>Example POST body:</strong>
                        <br>
                        <span class="text-muted">
                                            The code sample below represents some example JSON with
                                            standard fields to pass in the body of your request in
                                            order to enroll a contact to a sequence:
                                        </span>
                    </p>
                    <pre v-highlightjs :key="renderRefreshToken + '_se_a'"><code class="json">{
    <span class="text-dark-greenish _600">// Authentication Token</span>
    "api_token": "{{ companyToken }}", // Required
    "sequence_id": "4567", // Required
    <span class="text-dark-greenish _600">// Properties</span>
    "force_enroll": true, // Optional | Default - false
    "source": "hubspot", // Required
    "id": 311, // Required if source is not phone_number
    "phone_number": "8181234567", // Required if source is phone_number
}</code></pre>
                    <p class="mb-2">
                        <strong>Example POST header:</strong>
                    </p>
                    <pre v-highlightjs><code class="json">{
    "Accept": "application/json",
    "Content-Type": "application/json"
}</code></pre>

                    <p class="mb-2">
                        <strong>Returns an HTTP 200 response on successful contact sequence enrollment, Example
                            success
                            response:</strong>
                    </p>
                    <pre v-highlightjs><code class="json">{
    "message": "Contact is successfully enrolled to the sequence"
}</code></pre>
                    <p class="mb-2">
                        <strong>Returns an HTTP 400 on failure with a detailed response, Example
                            failure
                            response:</strong>
                    </p>
                    <pre v-highlightjs><code class="json">{
    "message": "Contact is already enrolled to a sequence.",
}</code></pre>

                    <p class="mb-2">
                        <strong>cURL Example:</strong>
                    </p>
                    <pre class="mb-3"
                         v-highlightjs
                         :key="renderRefreshToken + '_se_b'"
                         v-if="!loading_whitelabel"><code class="bash">curl --location --request POST '{{ statics.domain | fixDomain }}/api/v1/webhook/sequence-enroll' \
--header 'Content-Type: application/json' \
--header 'Accept: application/json' \
--data-raw '{
    "api_token": "{{ companyToken }}",
    "sequence_id": "4567",
    "force_enroll": true,
    "source": "aloware",
    "id": 142231,
}'</code></pre>
                </div>
            </div>
            <el-divider class="my-5"></el-divider>
            <div class="row">
                <div class="col-12 p-3">
                    <div class="el-form-item mb-2">
                        <span class="page-anchor"
                              id="disenroll">
                        </span>
                        <strong class="integration-api-titles">Disenroll a contact from a sequence through API</strong>
                    </div>
                    <p class="text-md">
                        <span class="text-dark-greenish _600">POST /api/v1/webhook/sequence-disenroll</span>
                    </p>

                    <p>
                        <strong>Required Fields:</strong>
                        <br>
                        <span class="text-dark-greenish _600">api_token</span><br>
                        <span class="text-dark-greenish _600">source</span><br>
                        <span class="text-dark-greenish _600">phone_number <span class="text-black-lt">(if source is 'phone_number')</span></span><br>
                        <span class="text-dark-greenish _600">id <span class="text-black-lt">(if source is not 'phone_number', e.g. aloware, hubspot, zoho, guesty and pipedrive)</span></span>
                    </p>

                    <p>
                        <strong>Example POST URL:</strong>
                        <br>
                        <span class="text-dark-greenish _600">{{ statics.domain | fixDomain }}/api/v1/webhook/sequence-disenroll</span>
                    </p>

                    <p class="mb-2">
                        <strong>Example POST body:</strong>
                        <br>
                        <span class="text-muted">
                                            The code sample below represents some example JSON with
                                            standard fields to pass in the body of your request in
                                            order to enroll a contact to a sequence:
                                        </span>
                    </p>
                    <pre v-highlightjs :key="renderRefreshToken + '_se_c'"><code class="json">{
    <span class="text-dark-greenish _600">// Authentication Token</span>
    "api_token": "{{ companyToken }}", // Required
    <span class="text-dark-greenish _600">// Properties</span>
    "source": "hubspot", // Required
    "id": 311, // Required if source is not phone_number
    "phone_number": "8181234567", // Required if source is phone_number
}</code></pre>
                    <p class="mb-2">
                        <strong>Example POST header:</strong>
                    </p>
                    <pre v-highlightjs><code class="json">{
    "Accept": "application/json",
    "Content-Type": "application/json"
}</code></pre>

                    <p class="mb-2">
                        <strong>Returns an HTTP 200 response on successful contact sequence dis enrollment, Example
                            success
                            response:</strong>
                    </p>
                    <pre v-highlightjs><code class="json">{
    "message": "Contact is disenrolled from all sequences."
}</code></pre>
                    <p class="mb-2">
                        <strong>cURL Example:</strong>
                    </p>
                    <pre class="mb-3"
                         v-highlightjs
                         :key="renderRefreshToken + '_se_d'"
                         v-if="!loading_whitelabel"><code class="bash">curl --location --request POST '{{ statics.domain | fixDomain }}/api/v1/webhook/sequence-disenroll' \
--header 'Content-Type: application/json' \
--header 'Accept: application/json' \
--data-raw '{
    "api_token": "{{ companyToken }}",
    "source": "aloware",
    "id": 142231,
}'</code></pre>
                    <p class="_600"
                       v-if="!statics.whitelabel">
                        If you need more API functions, please contact our support at support@aloware.com.
                    </p>
                </div>
            </div>
        </div>

        <div class="col-md-3 d-none d-md-block ml-auto">
            <div class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70">
                <h6 class="list-group-item">
                    On This Page
                </h6>
                <a class="list-group-item list-group-item-action"
                   @click="shine('enroll')">
                    Sequence Enroll API
                </a>
                <a class="list-group-item list-group-item-action"
                   @click="shine('disenroll')">
                    Sequence Disenroll API
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {form_handler, company_token_mixin} from "../mixins"
import CompanyFormToken from './account/company-form-token'

export default {
    mixins: [
        form_handler,
        company_token_mixin
    ],

    components: {
        CompanyFormToken
    },

    data() {
        return {
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
        }
    },

    created() {
        this.getStatics()
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        }
    }
}
</script>
