var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-date-picker", {
    staticClass: "mr-2",
    attrs: {
      type: "daterange",
      align: _vm.align,
      "unlink-panels": "",
      "range-separator": "To",
      "start-placeholder": "From date",
      "end-placeholder": "To date",
      format: "MMM d, yyyy",
      "value-format": "yyyy-MM-dd",
      "default-time": ["00:00:00", "23:59:59"],
      "picker-options": _vm.pickerOptions,
    },
    on: { change: _vm.change },
    model: {
      value: _vm.date_range_model,
      callback: function ($$v) {
        _vm.date_range_model = $$v
      },
      expression: "date_range_model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }