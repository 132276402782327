<template>
    <div class="app-body page-user-dialog"
         id="view"
         v-if="user">
        <div class="fixed-header padding pt-0 pb-0"
             :style="{top: (top_offset+50) + 'px'}"
             v-if="hasPermissionTo('view user') && !user.read_only_access && !auth.user.profile.is_reseller">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <router-link :to="{ name: 'User Activity', params: {user_id: user_id}}"
                                 v-if="user_id">
                        <button class="btn purplish pull-left"
                                data-testid="activity-user-button">
                            <i class="fa fa-signal"></i>
                            Activity
                        </button>
                    </router-link>

                    <button class="btn pull-left ml-2 blue"
                            v-if="hasRole('Company Admin') && (!user_id || auth.user.profile.id !== user_id)"
                            data-testid="help-user-button"
                            @click="toggleHelp">
                        <i class="material-icons text-lg">help</i>
                        Help
                    </button>
                </div>
            </div>
        </div>

        <al-alert class="my-2 mt-5"
                  :dimisable="true"
                  v-if="hasPerSeatPlanUseCase() && getAvailableLicenses() == 0 && !user_id">
            <span class="mr-5 text-dark"
                  v-html="notificationMessage"/>
        </al-alert>

        <div class="container-fluid d-relative"
             :class="[(hasPermissionTo('view user') && !user.read_only_access && !auth.user.profile.is_reseller && (user.id || getAvailableLicenses() > 0)) ? 'mt-5' : '']">
            <div class="row">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="card my-4">
                            <div class="card-body pb-0">
                                <el-form ref="user_form"
                                         class="form-aloware"
                                         autocomplete="off"
                                         label-position="top"
                                         v-loading="loading"
                                         :model="user"
                                         :rules="rules"
                                         data-testid="dialog-form"
                                         @submit.prevent.native="submitForm('user_form')">
                                    <template v-on:keyup.enter="submitForm('user_form')">
                                        <el-tabs v-model="active_tab_name" @tab-click="tabClicked">
                                            <el-tab-pane name="profile-settings"
                                                         class="pt-2"
                                                         data-testid="profile-settings-pane"
                                                         label="Profile Settings">
                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <!-- Profile Settings -->
                                                        <div class="pl-3 pr-3">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div v-if="user.is_destination"
                                                                         class="alert alert-warning alert-aloware">
                                                                        <strong>
                                                                            <i class="fa fa-exclamation-triangle"></i>
                                                                            This is an extension. To upgrade it to a real user, please
                                                                            contact us.
                                                                        </strong>
                                                                    </div>
                                                                    <span class="page-anchor"
                                                                          id="profile-info">
                                                                    </span>
                                                                    <div class="form-group">
                                                                        <el-form-item>
                                                                            <span class="page-anchor"
                                                                                  id="name">
                                                                            </span>
                                                                            <div class="row">
                                                                                <div class="col-6">
                                                                                    <el-form-item prop="first_name"
                                                                                                  data-testid="first-name-form-item"
                                                                                                  class="no-border">
                                                                                        <div class="form-label">
                                                                                            <h5>First Name</h5>
                                                                                        </div>
                                                                                        <el-input
                                                                                            v-model="user.first_name"
                                                                                            placeholder="John"
                                                                                            class="w-100"
                                                                                            :disabled="disableFormField"
                                                                                            data-testid="fist-name-input"
                                                                                            @input="preValidateForm('user_form')">
                                                                                        </el-input>
                                                                                    </el-form-item>
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <el-form-item prop="last_name"
                                                                                                  data-testid="last-name-form-item"
                                                                                                  class="no-border">
                                                                                        <div class="form-label">
                                                                                            <h5>Last Name</h5>
                                                                                        </div>
                                                                                        <el-input
                                                                                            v-model="user.last_name"
                                                                                            placeholder="Doe"
                                                                                            class="w-100"
                                                                                            :disabled="disableFormField"
                                                                                            data-testid="last-name-input"
                                                                                            @input="preValidateForm('user_form')">
                                                                                        </el-input>
                                                                                    </el-form-item>
                                                                                </div>
                                                                            </div>
                                                                        </el-form-item>

                                                                        <el-form-item prop="description"
                                                                                      data-testid="description-item">
                                                                            <div class="form-label">
                                                                                <span class="page-anchor"
                                                                                      id="description">
                                                                                </span>
                                                                                <h5>Description (Optional)</h5>
                                                                            </div>
                                                                            <el-input v-model="user.description"
                                                                                      type="textarea"
                                                                                      maxlength="190"
                                                                                      :autosize="{ minRows: 3, maxRows: 3}"
                                                                                      show-word-limit
                                                                                      data-testid="description-input"
                                                                                      @input="preValidateForm('user_form')">
                                                                            </el-input>
                                                                        </el-form-item>

                                                                        <el-form-item prop="email"
                                                                                      data-testid="email-form-item"
                                                                                      v-if="!user.is_destination">
                                                                            <div class="form-label">
                                                                                <span class="page-anchor"
                                                                                      id="email">
                                                                                </span>
                                                                                <h5>Email</h5>
                                                                            </div>
                                                                            <el-input type="email"
                                                                                      placeholder="john.doe@email.com"
                                                                                      v-model="user.email"
                                                                                      class="w-100"
                                                                                      :disabled="!!user.access_user_id || disableFormField"
                                                                                      data-testid="email-input"
                                                                                      @input="preValidateForm('user_form')">
                                                                            </el-input>
                                                                        </el-form-item>

                                                                        <template v-if="!auth.user.profile.is_reseller">
                                                                            <el-form-item
                                                                                data-testid="role-name-form-item"
                                                                                v-if="allowUserRoleUpdate(user)"
                                                                                prop="role_name">
                                                                                <span class="page-anchor"
                                                                                      id="role">
                                                                                </span>
                                                                                <div class="form-label">
                                                                                    <h5>Role</h5>
                                                                                    <small>Choose a role for this user</small>
                                                                                </div>
                                                                                <div class="row mb-3">
                                                                                    <div class="col-12">
                                                                                        <el-radio-group
                                                                                            v-model="user.role_name"
                                                                                            data-testid="role-radio-group"
                                                                                            @change="preValidateForm('user_form')">
                                                                                            <el-popover
                                                                                                placement="bottom-start"
                                                                                                trigger="hover"
                                                                                                data-testid="admin-role-popover"
                                                                                                content="Admins have full access to everything.">
                                                                                                <el-radio
                                                                                                    slot="reference"
                                                                                                    label="Company Admin"
                                                                                                    class="_600"
                                                                                                    border
                                                                                                    data-testid="admin-role-radio"
                                                                                                    @change="roleChange">
                                                                                                    Admin
                                                                                                </el-radio>
                                                                                            </el-popover>
                                                                                            <el-popover
                                                                                                placement="bottom-start"
                                                                                                trigger="hover"
                                                                                                data-testid="agent-role-popover"
                                                                                                content="">
                                                                                                <p>
                                                                                                    Agents have read access to all your lines, users, contacts, and sequences. <br/>
                                                                                                    Agent's visibility can be configured in the Visibility Settings tab.
                                                                                                </p>
                                                                                                <el-radio
                                                                                                    label="Company Agent"
                                                                                                    slot="reference"
                                                                                                    class="_600"
                                                                                                    border
                                                                                                    @change="roleChange"
                                                                                                    data-testid="agent-role-radio">
                                                                                                    Agent
                                                                                                </el-radio>
                                                                                            </el-popover>
                                                                                            <el-popover
                                                                                                placement="bottom-start"
                                                                                                trigger="hover"
                                                                                                data-testid="supervisor-role-popover"
                                                                                                content="Supervisors have limited admin access.">
                                                                                                <el-radio
                                                                                                    slot="reference"
                                                                                                    label="Company Supervisor"
                                                                                                    class="_600"
                                                                                                    border
                                                                                                    data-testid="supervisor-role-radio"
                                                                                                    @change="roleChange">
                                                                                                    Supervisor
                                                                                                </el-radio>
                                                                                            </el-popover>
                                                                                        </el-radio-group>
                                                                                    </div>
                                                                                </div>

                                                                                <el-form-item class="no-border"
                                                                                              data-testid="billing-name-form-item"
                                                                                              v-if="hasRole('Billing Admin') && user.role_name === 'Company Admin'">
                                                                                    <div class="form-label">
                                                                                        <h5>Billing Admin?</h5>
                                                                                        <small>This user will be responsible
                                                                                            for billing, invoices, and
                                                                                            payments.</small>
                                                                                    </div>
                                                                                    <el-checkbox
                                                                                        v-model="mark_as_billing_admin"
                                                                                        :disabled="user.role_name !== 'Company Admin'"
                                                                                        border
                                                                                        data-testid="billing-admin-checkbox">
                                                                                        Billing Admin
                                                                                    </el-checkbox>
                                                                                </el-form-item>
                                                                            </el-form-item>

                                                                            <el-form-item prop="answer_by"
                                                                                          data-testid="answer-by-form-item"
                                                                                          v-if="canBeEdited">
                                                                                <span class="page-anchor"
                                                                                      id="answer">
                                                                                </span>
                                                                                <div class="form-label">
                                                                                    <h5>Answer Type</h5>
                                                                                    <small>Choose how this user answers calls</small>
                                                                                </div>
                                                                                <el-select placeholder="Select a type"
                                                                                           v-model="user.answer_by"
                                                                                           class="width-500 fixed lh-1 multi-line"
                                                                                           data-testid="answer-type-selector"
                                                                                           @change="checkUserPhoneNumber">
                                                                                    <el-option
                                                                                        v-for="answer_type in answer_types"
                                                                                        class="p-0"
                                                                                        :key="answer_type.id"
                                                                                        :label="answer_type.description"
                                                                                        :value="answer_type.id">
                                                                                        <div class="media">
                                                                                            <div class="media-body">
                                                                                                <label>{{ answer_type.description }}</label>
                                                                                                {{ answer_type.help_text }}
                                                                                            </div>
                                                                                        </div>
                                                                                    </el-option>
                                                                                </el-select>
                                                                            </el-form-item>

                                                                            <el-form-item
                                                                                data-testid="phone-number-as-backup-item"
                                                                                v-if="canBeEdited && [AnswerTypes.BY_BROWSER, AnswerTypes.BY_IP_PHONE].includes(user.answer_by)"
                                                                                prop="phone_number_as_backup">
                                                                                <span class="page-anchor"
                                                                                      id="backup-routing">
                                                                                </span>
                                                                                <div class="form-label">
                                                                                    <h5>Backup routing (Beta)</h5>
                                                                                    <small>
                                                                                        Call routing will check if
                                                                                        the user is online on
                                                                                        Aloware. If you check this,
                                                                                        the backup phone number will
                                                                                        ring if you are not available.
                                                                                    </small>
                                                                                </div>
                                                                                <el-checkbox
                                                                                    label="Use a phone number as backup"
                                                                                    v-model="user.phone_number_as_backup"
                                                                                    data-testid="backup-routing-checkbox"
                                                                                    @change="checkUserPhoneNumber">
                                                                                </el-checkbox>
                                                                            </el-form-item>

                                                                            <el-form-item prop="phone_number"
                                                                                          data-testid="phone-number-form-item"
                                                                                          v-show="userDestinationEditable && (([AnswerTypes.BY_BROWSER, AnswerTypes.BY_IP_PHONE].includes(user.answer_by) && user.phone_number_as_backup) || user.answer_by === AnswerTypes.BY_PHONE_NUMBER)">
                                                                                <span class="page-anchor"
                                                                                      id="phone-number">
                                                                                </span>
                                                                                <div class="form-label">
                                                                                    <h5>
                                                                                        <span
                                                                                            v-if="user.answer_by === AnswerTypes.BY_BROWSER && !user.read_only_access">Backup</span>
                                                                                        Phone Number
                                                                                    </h5>
                                                                                </div>
                                                                                <el-input type="tel"
                                                                                          placeholder="(123) 456-7890"
                                                                                          class="w-100"
                                                                                          autocomplete="off"
                                                                                          v-model="user.phone_number"
                                                                                          data-testid="phone-number-input"
                                                                                          @input="preValidateForm('user_form')">
                                                                                </el-input>
                                                                            </el-form-item>

                                                                            <el-form-item prop="respect_agent_status"
                                                                                          data-testid="respect-agent-form-item"
                                                                                          v-if="canBeEdited && user.answer_by !== AnswerTypes.BY_BROWSER && user.answer_by !== AnswerTypes.BY_NONE">
                                                                                <span class="page-anchor" id="respect_agent_status"></span>
                                                                                <div class="form-label">
                                                                                    <h5>Respect agent availability status (Beta)</h5>
                                                                                </div>
                                                                                <el-checkbox
                                                                                    label="Respect agent availability status"
                                                                                    v-model="user.respect_agent_status"
                                                                                    data-testid="agent-availability-status-checkbox">
                                                                                </el-checkbox>
                                                                            </el-form-item>

                                                                            <el-form-item prop="answers_messages"
                                                                                          data-testid="answers-messages-form-item"
                                                                                          v-if="canBeEdited && isNotOwnSettings && user.role_name === 'Company Agent'">
                                                                                <span class="page-anchor"
                                                                                      id="answers-messages">
                                                                                </span>
                                                                                <div class="form-label">
                                                                                    <h5>Text Message Handling (Beta)</h5>
                                                                                    <small>
                                                                                        If you don't want this user to
                                                                                        be assigned to text
                                                                                        messages please uncheck "Answers
                                                                                        text messages."
                                                                                    </small>
                                                                                </div>
                                                                                <el-checkbox
                                                                                    label="Answers text messages"
                                                                                    v-model="user.answers_messages"
                                                                                    data-testid="text-message-handling-checkbox">
                                                                                </el-checkbox>
                                                                            </el-form-item>

                                                                            <template v-if="!userIsCompanyAdmin && !user.is_destination">
                                                                                <el-form-item prop="can_change_contact_ownership"
                                                                                              data-testid="phone-number-form-item"
                                                                                              v-if="isNotOwnSettings && user.role_name === 'Company Agent'">
                                                                                <span class="page-anchor"
                                                                                      id="change-contact-ownership">
                                                                                </span>
                                                                                    <div class="form-label">
                                                                                        <h5>Change Contact Ownership</h5>
                                                                                        <small>
                                                                                            If you don't want to allow this user to change contact ownership, please uncheck the checkbox below.
                                                                                        </small>
                                                                                    </div>
                                                                                    <el-checkbox
                                                                                        label="Can change contact ownership"
                                                                                        v-model="user.can_change_contact_ownership"
                                                                                        data-testid="contact-ownership-checkbox">
                                                                                    </el-checkbox>
                                                                                </el-form-item>

                                                                                <el-form-item prop="can_modify_contact_ring_groups"
                                                                                              data-testid="allow-ring-groups-modifications-form-item"
                                                                                              v-if="isNotOwnSettings && user.role_name === 'Company Agent'">
                                                                                    <span class="page-anchor"
                                                                                          id="modify-contact-ring-groups">
                                                                                    </span>
                                                                                    <div class="form-label">
                                                                                        <h5>Allow Contact Ring Groups Modification</h5>
                                                                                        <small>
                                                                                            If you don't want to allow this user to modify the contact ring groups, please uncheck the checkbox below.
                                                                                        </small>
                                                                                    </div>
                                                                                    <el-checkbox
                                                                                        label="Can modify contact ring groups"
                                                                                        v-model="user.can_modify_contact_ring_groups"
                                                                                        data-testid="contact-ring-groups-checkbox">
                                                                                    </el-checkbox>
                                                                                </el-form-item>

                                                                                <el-form-item prop="can_barge_and_whisper_on_call"
                                                                                              data-testid="call-barge-and-whisper-form-item"
                                                                                              v-if="isNotOwnSettings && user.role_name === 'Company Agent'">
                                                                                    <span class="page-anchor"
                                                                                          id="barge-and-whisper-on-call">
                                                                                    </span>
                                                                                    <div class="form-label">
                                                                                        <h5>Allow Call Barge and Whisper</h5>
                                                                                        <small>
                                                                                            If you don't want to allow this user to barge and/or whisper on a call, please uncheck "Can barge and
                                                                                            whisper on a call".
                                                                                        </small>
                                                                                    </div>
                                                                                    <el-checkbox
                                                                                        label="Can barge and whisper on a call"
                                                                                        v-model="user.can_barge_and_whisper_on_call"
                                                                                        data-testid="call-barge-and-whisper-checkbox">
                                                                                    </el-checkbox>
                                                                                </el-form-item>

                                                                                <el-form-item prop="has_broadcast_access"
                                                                                              data-testid="can-broadcast-form-item"
                                                                                              v-if="isNotOwnSettings && user.role_name === 'Company Agent'">
                                                                                    <span class="page-anchor"
                                                                                          id="user-broadcast-access">
                                                                                    </span>
                                                                                    <div class="form-label">
                                                                                        <h5>Can Broadcast</h5>
                                                                                        <small>Grant broadcast access to the user.</small>
                                                                                    </div>
                                                                                    <el-checkbox
                                                                                        label="Can create and update broadcast"
                                                                                        v-model="user.has_broadcast_access"
                                                                                        data-testid="grant-broadcast-checkbox">
                                                                                    </el-checkbox>
                                                                                </el-form-item>

                                                                                <el-form-item prop="can_delete_contact"
                                                                                              data-testid="can-delete-contact-form-item"
                                                                                              v-if="isNotOwnSettings && user.role_name === 'Company Agent'">
                                                                                    <span class="page-anchor"
                                                                                          id="can_delete_contact">
                                                                                    </span>
                                                                                    <div class="form-label">
                                                                                        <h5 class="mb-0">Delete Contact</h5>
                                                                                        <small>Grant user the ability to delete a contact.</small>
                                                                                    </div>
                                                                                    <el-checkbox
                                                                                        label="Can delete a contact"
                                                                                        v-model="user.can_delete_contact"
                                                                                        data-testid="delete-contact-checkbox">
                                                                                    </el-checkbox>
                                                                                </el-form-item>
                                                                            </template>

                                                                            <el-form-item prop="user_campaign"
                                                                                          data-testid="users-personal-line-form-item"
                                                                                          v-if="connectedCampaigns.length > 0">
                                                                                <span class="page-anchor"
                                                                                      id="user-personal-line">
                                                                                </span>
                                                                                <div class="form-label">
                                                                                    <h5>User's personal line</h5>
                                                                                    <el-select class="mt-2"
                                                                                               prop="campaign_id"
                                                                                               v-model="user.campaign_id"
                                                                                               :disabled="!canEditPersonalLine"
                                                                                               data-testid="connected-campaigns-selector">
                                                                                        <el-option v-for="campaign in connectedCampaigns"
                                                                                                   :key="campaign.id"
                                                                                                   :label="campaign.name"
                                                                                                   :value="campaign.id">
                                                                                        </el-option>
                                                                                    </el-select>
                                                                                </div>
                                                                            </el-form-item>
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 d-none d-md-block ml-auto">
                                                        <div
                                                            class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header">
                                                            <h6 class="list-group-item">
                                                                On This Page
                                                            </h6>
                                                            <a href="#name"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-name"
                                                               @click="shine('name')">
                                                                Name
                                                            </a>
                                                            <a href="#description"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-description"
                                                               @click="shine('description')">
                                                                Description
                                                            </a>
                                                            <a v-if="!user.is_destination"
                                                               href="#email"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-email"
                                                               @click="shine('email')">
                                                                Email
                                                            </a>
                                                            <a v-if="hasRole('Company Admin') && !user.is_destination && (!user_id || auth.user.profile.id !== user_id)"
                                                               href="#role"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-role"
                                                               @click="shine('role')">
                                                                Role
                                                            </a>
                                                            <a v-if="canBeEdited"
                                                               href="#answer"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-answer-type"
                                                               @click="shine('answer')">
                                                                Answer Type
                                                            </a>
                                                            <a v-if="canBeEdited && [AnswerTypes.BY_BROWSER, AnswerTypes.BY_IP_PHONE].includes(user.answer_by)"
                                                               href="#backup-routing"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-back-routing"
                                                               @click="shine('backup-routing')">
                                                                Backup routing
                                                            </a>
                                                            <a v-if="userDestinationEditable && (([AnswerTypes.BY_BROWSER, AnswerTypes.BY_IP_PHONE].includes(user.answer_by) && user.phone_number_as_backup) || user.answer_by === AnswerTypes.BY_PHONE_NUMBER)"
                                                               href="#phone-number"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-phone-number"
                                                               @click="shine('phone-number')">
                                                                <span
                                                                    v-if="user.answer_by === AnswerTypes.BY_BROWSER && !user.read_only_access">Backup</span>
                                                                Phone number
                                                            </a>
                                                            <a v-if="canBeEdited && [AnswerTypes.BY_PHONE_NUMBER, AnswerTypes.BY_IP_PHONE].includes(user.answer_by)"
                                                               href="#respect_agent_status"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-respect-agent-status"
                                                               @click="shine('respect_agent_status')">
                                                                Respect Agent Status
                                                            </a>
                                                            <a v-if="canBeEdited && isNotOwnSettings && user.role_name === 'Company Agent'"
                                                               href="#answers-messages"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-answers-messages"
                                                               @click="shine('answers-messages')">
                                                                Text message handling
                                                            </a>
                                                            <a v-if="hasRole('Company Admin') && user.role_name === 'Company Agent' && !user.is_destination"
                                                               href="#change-contact-ownership"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-change-contact-ownership"
                                                               @click="shine('change-contact-ownership')">
                                                                Change contact ownership
                                                            </a>
                                                            <a v-if="hasRole('Company Admin') && user.role_name === 'Company Agent' && !user.is_destination"
                                                               href="#modify-contact-ring-groups"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-modify-contact-ring-groups"
                                                               @click="shine('modify-contact-ring-groups')">
                                                                Modify contact ring groups
                                                            </a>
                                                            <a v-if="hasRole('Company Admin') && user.role_name === 'Company Agent' && !user.is_destination"
                                                               href="#barge-and-whisper-on-call"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-barge-and-whisper-on-call"
                                                               @click="shine('barge-and-whisper-on-call')">
                                                                Call Barge and Whisper
                                                            </a>
                                                            <a v-if="hasRole('Company Admin') && user.role_name === 'Company Agent' && !user.is_destination"
                                                               href="#user-broadcast-access"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-can-broadcast"
                                                               @click="shine('user-broadcast-access')">
                                                                Can Broadcast
                                                            </a>
                                                            <a v-if="hasRole('Company Admin') && user.role_name === 'Company Agent' && !user.is_destination"
                                                               href="#can_delete_contact"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-delete-contact"
                                                               @click="shine('can_delete_contact')">
                                                                Delete Contact
                                                            </a>
                                                            <a href="#user-personal-line"
                                                               v-if="connectedCampaigns.length > 0"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="profile-settings-user-personal-line"
                                                               @click="shine('user-personal-line')">
                                                                User's personal line
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-tab-pane>
                                            <el-tab-pane name="visibility"
                                                         class="py-2 px-3"
                                                         label="Visibility Settings"
                                                         data-testid="visibility-settings-pane"
                                                         v-if="hasRole('Company Admin') && !user.is_destination && !auth.user.profile.is_reseller">
                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <el-form-item>
                                                            <div class="form-label">
                                                                <span class="page-anchor"
                                                                      id="contacts_visibility">
                                                                </span>
                                                                <h5 class="mb-0">Contacts Visibility</h5>
                                                                <small>Select the contacts visibility settings for this user</small>
                                                            </div>

                                                            <el-form-item prop="contacts_visibility"
                                                                          class="no-border">
                                                                <el-select v-model="user.contacts_visibility"
                                                                           placeholder="Select user's visibility"
                                                                           class="w-50"
                                                                           data-testid="contact-visibility-selector"
                                                                           @change="preValidateForm('user_form')">
                                                                    <el-option
                                                                        v-for="item in contactVisibilityOptions"
                                                                        class="p-0"
                                                                        :key="item.value"
                                                                        :label="item.label"
                                                                        :value="item.value">
                                                                        <div class="media">
                                                                            <div class="media-body">
                                                                                <label>{{ item.label }}</label>
                                                                                <small>{{ item.description }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </el-option>
                                                                </el-select>

                                                                <el-form-item prop="can_view_unassigned_contacts"
                                                                              class="no-border"
                                                                              v-if="user.contacts_visibility !== ContactAccessTypes.CONTACTS_ACCESS_EVERYONE">
                                                                    <el-checkbox
                                                                        label="Can view unassigned contacts"
                                                                        v-model="user.can_view_unassigned_contacts"
                                                                        data-testid="unassigned-contacts-checkbox">
                                                                    </el-checkbox>
                                                                </el-form-item>
                                                            </el-form-item>
                                                        </el-form-item>

                                                        <el-form-item>
                                                            <span class="page-anchor"
                                                                  id="communication_visibility">
                                                            </span>
                                                            <div class="form-label">
                                                                <h5 class="mb-0">Communications Visibility</h5>
                                                                <small>Communication visibility settings for this user</small>
                                                            </div>
                                                            <el-form-item prop="communications_visibility"
                                                                          class="no-border">
                                                                <el-select v-model="user.communications_visibility"
                                                                           placeholder="Select user's visibility"
                                                                           class="w-50"
                                                                           data-testid="communications-visibility-selector"
                                                                           @change="preValidateForm('user_form')">
                                                                    <el-option
                                                                        v-for="item in communicationVisibilityOptions"
                                                                        class="p-0"
                                                                        :key="item.value"
                                                                        :label="item.label"
                                                                        :value="item.value">
                                                                        <div class="media">
                                                                            <div class="media-body">
                                                                                <label>{{ item.label }}</label>
                                                                                <small>{{ item.description }}</small>
                                                                            </div>
                                                                        </div>
                                                                    </el-option>
                                                                </el-select>
                                                            </el-form-item>
                                                        </el-form-item>

                                                        <el-form-item class="no-border"
                                                                      prop="line_access_limit">
                                                            <span class="page-anchor"
                                                                  id="line_visibility">
                                                            </span>
                                                            <div class="form-label">
                                                                <h5 class="mb-0">Line Visibility Limit</h5>
                                                                <small>
                                                                    By default, agents have access to all lines. Here, you can define exactly what lines
                                                                    they have access to.
                                                                </small>
                                                            </div>

                                                            <el-checkbox
                                                                v-model="user.line_access_limit"
                                                                label="Limit user's line visibility"
                                                                data-testid="line-access-limit-checkbox"
                                                                @change="toggleLineAccessLimit">
                                                            </el-checkbox>

                                                            <div class="mt-2"
                                                                 v-if="user.line_access_limit && hasRole('Company Admin')">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <el-transfer
                                                                            class="d-inline-block text-left"
                                                                            v-model="user.selected_campaign_ids"
                                                                            :titles="['Available', 'Current']"
                                                                            :format="{
                                                                                        noChecked: '${total}',
                                                                                        hasChecked: '${checked}/${total}'
                                                                                    }"
                                                                            :data="campaignsData"
                                                                            filterable
                                                                            data-testid="select-campaigs-transfer"
                                                                            @change="preValidateForm('user_form')">
                                                                            <el-button
                                                                                class="transfer-footer mr-2 mt-1 pull-right"
                                                                                type="danger"
                                                                                slot="right-footer"
                                                                                size="mini"
                                                                                data-testid="clear-campaings-button"
                                                                                @click="clearSelectedCampaigns">
                                                                                Clear
                                                                            </el-button>
                                                                        </el-transfer>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="mt-2 pointer text-primary">
                                                                <a href="/account?tab=settings"
                                                                   data-testid="restric-agents-account-settigs-link">
                                                                    Do you want to restrict all agents' line? Go to account settings
                                                                </a>
                                                            </div>
                                                        </el-form-item>

                                                        <div class="d-flex flex-column"
                                                             v-if="user.line_access_limit">
                                                            <el-checkbox class="mt-2"
                                                                         v-model="user.line_communication_limit"
                                                                         label="Apply visibility limit to communications"
                                                                         data-testid="line-communication-limit-checkbox"
                                                                         @change="preValidateForm('user_form')">
                                                            </el-checkbox>
                                                            <el-checkbox class="mt-2"
                                                                         v-model="user.line_contact_limit"
                                                                         label="Apply visibility limit to contacts"
                                                                         data-testid="line-contact-limit-checkbox"
                                                                         @change="preValidateForm('user_form')">
                                                            </el-checkbox>
                                                        </div>
                                                        <el-form-item prop="read_only_access"
                                                                      class="mt-2"
                                                                      v-if="user.role_name && user.role_name !== 'Company Admin'">
                                                                <span class="page-anchor"
                                                                      id="read_only_access">
                                                                </span>
                                                            <div class="form-label">
                                                                <h5 class="mb-0">Reporter Access</h5>
                                                                <small>Users with reporter access can only see the reports and they can not interact with the contacts or make calls.</small>
                                                            </div>

                                                            <el-checkbox
                                                                v-model="user.read_only_access"
                                                                label="Make this user a reporter"
                                                                data-testid="make-user-reporter-checkbox">
                                                            </el-checkbox>
                                                        </el-form-item>

                                                        <el-form-item
                                                            prop="user_access_limit"
                                                            class="no-border"
                                                            v-if="user.role_name && user.read_only_access && hasRole('Company Admin')">

                                                            <span class="page-anchor"
                                                                  id="user_access_limit">
                                                            </span>
                                                            <div class="form-label">
                                                                <h5 class="mb-0">User Access Limit</h5>
                                                                <small>Limit which users this user has reporting access to.</small>
                                                            </div>

                                                            <el-checkbox v-model="user.user_access_limit"
                                                                         label="Limit visibility of other users"
                                                                         data-testid="limit-other-users-visibility-checkbox"
                                                                         @change="preValidateForm('user_form')">
                                                            </el-checkbox>

                                                            <div class="mt-2"
                                                                 v-if="user.user_access_limit">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <el-transfer
                                                                            class="d-inline-block text-left"
                                                                            v-model="user.selected_user_ids"
                                                                            :titles="['Available', 'Current']"
                                                                            :format="{
                                                                                        noChecked: '${total}',
                                                                                        hasChecked: '${checked}/${total}'
                                                                                    }"
                                                                            :data="usersData"
                                                                            filterable
                                                                            data-testid="select-users-transfer"
                                                                            @change="preValidateForm('user_form')">
                                                                            <el-button
                                                                                class="transfer-footer mr-2 mt-1 pull-right"
                                                                                type="danger"
                                                                                slot="right-footer"
                                                                                size="mini"
                                                                                data-testid="clear-selected-users-transfer"
                                                                                @click="clearSelectedUsers">
                                                                                Clear
                                                                            </el-button>
                                                                        </el-transfer>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </el-form-item>
                                                    </div>
                                                    <div class="col-3 d-none d-md-block ml-auto">
                                                        <div class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header">
                                                            <h6 class="list-group-item">
                                                                On This Page
                                                            </h6>
                                                            <a href="#contacts_visibility"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="contacts-visibility-link"
                                                               @click="shine('contacts_visibility')">
                                                                Contacts
                                                            </a>
                                                            <a href="#communication_visibility"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="communication-visibility-link"
                                                               @click="shine('communication_visibility')">
                                                                Communications
                                                            </a>
                                                            <a href="#line_visibility"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="line-visibility-link"
                                                               @click="shine('line_visibility')">
                                                                Line Visibility Limit
                                                            </a>
                                                            <a href="#read_only_access"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="reporter-access-link"
                                                               @click="shine('read_only_access')">
                                                                Reporter Access
                                                            </a>
                                                            <a href="#user_access_limit"
                                                               class="list-group-item list-group-item-action"
                                                               v-if="user.role_name && user.read_only_access && hasRole('Company Admin')"
                                                               data-testid="user-access-link"
                                                               @click="shine('user_access_limit')">
                                                                User Access Limit
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-tab-pane>
                                            <el-tab-pane v-if="canBeEdited && !auth.user.profile.is_reseller"
                                                         data-testid="personalization-pane"
                                                         name="personalization"
                                                         label="Personalization"
                                                         class="p-2">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <el-form-item prop="transcription"
                                                                      v-if="current_company && current_company.transcription_enabled">
                                                            <span class="page-anchor"
                                                                  id="transcription">
                                                            </span>
                                                            <div class="form-label">
                                                                <h5>AloAi Voice Analytics</h5>
                                                                <small>Activate AloAi Voice Analytics on voice calls.</small>
                                                            </div>
                                                            <div class="switch-label">
                                                                <el-switch v-model="user.transcription_enabled"
                                                                           active-color="#00a344"
                                                                           data-testid="smart-transcription-switch"
                                                                           @change="preValidateForm('user_form')">
                                                                </el-switch>
                                                                <label>Enable AloAi Voice Analytics</label>
                                                            </div>
                                                        </el-form-item>

                                                        <el-form-item prop="url_shortener"
                                                                      v-if="current_company && !current_company.is_whitelabel && current_company.url_shortener_enabled">
                                                            <span class="page-anchor"
                                                                  id="url_shortener">
                                                            </span>
                                                            <div class="form-label">
                                                                <h5>URL Shortener</h5>
                                                                <small>Enable URL Shortener for SMS/MMS forms.</small>
                                                            </div>
                                                            <div class="switch-label">
                                                                <el-switch v-model="user.url_shortener_enabled"
                                                                           active-color="#00a344"
                                                                           data-testid="url-shortener-switch"
                                                                           @change="preValidateForm('user_form')">
                                                                </el-switch>
                                                                <label>Enable URL Shortener</label>
                                                            </div>
                                                        </el-form-item>

                                                        <el-form-item prop="go_to_available_after_login"
                                                                      :class="isCompanyForcedAvailable ? 'pl-3 border-left ': ''">
                                                            <span class="page-anchor"
                                                                  id="go_to_available_after_login">
                                                            </span>
                                                            <div v-if="isCompanyForcedAvailable"
                                                                 class="cursor-not-allowed width-fit-content">
                                                                <span class="badge badge-warning py-1 mb-2 ">
                                                                    Set by Admin in Account Settings
                                                                </span>
                                                                <div class="p-2 px-3 border border-warning">
                                                                    <el-tooltip
                                                                        placement="top"
                                                                        effect="dark"
                                                                        content="Requires Admin to change in Account Setting">
                                                                        <div>
                                                                            <div class="form-label">
                                                                                <h5>
                                                                                    Force Users to Always Available
                                                                                    <el-popover
                                                                                        placement="right"
                                                                                        width="450"
                                                                                        trigger="hover"
                                                                                        data-testid="set-by-admin-popover">
                                                                                        <div>
                                                                                            <span class="d-flex font-weight-bold">Turning on 'Force Users to Always Available' Setting:</span>
                                                                                            <ul class="mb-0">
                                                                                                <li>Users' statuses are set to 'Available' even after they login</li>
                                                                                                <li>Users cannot manually change their status</li>
                                                                                                <li>User's application will receive inbound calls until they logout</li>
                                                                                                <li>Admins can still manually change agent's statuses in Wallboard</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <i slot="reference"
                                                                                           class="fa fa-info-circle text-primary ml-2">
                                                                                        </i>
                                                                                    </el-popover>
                                                                                </h5>
                                                                                <small>
                                                                                    <span class="d-flex">Set all users' statuses to <b class="pl-1">Available</b></span>
                                                                                    <span class="d-flex">Remove users' ability to manually change their status to <b
                                                                                        class="pl-1">Busy / Break</b></span>
                                                                                    <span class="d-flex">Admins can still manually change agents' statuses in Wallboard</span>
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                    </el-tooltip>
                                                                </div>
                                                                <div class="mbt-2 mb-3"
                                                                     v-if="hasRole(['Company Admin'])">
                                                                    <a href="/account?tab=calling-settings#force_users_always_available"
                                                                       class="text-blue-14"
                                                                       target="_blank"
                                                                       data-testid="set-by-admin-modify-link">
                                                                        <u>Modify</u>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <el-tooltip
                                                                placement="top"
                                                                effect="dark"
                                                                content="Disabled - Requires Admin to change Account Setting"
                                                                :disabled="!isCompanyForcedAvailable">
                                                                <div class="width-fit-content"
                                                                     :class="isCompanyForcedAvailable ? 'opacity cursor-not-allowed' : ''">
                                                                    <div class="form-label">
                                                                        <h5>
                                                                            Available by Default, But Allow Manual Changes
                                                                            <el-popover
                                                                                placement="right"
                                                                                width="450"
                                                                                trigger="hover"
                                                                                data-testid="available-by-default-popover">
                                                                                <div>
                                                                                    <span class="d-flex font-weight-bold">Turning on 'Available by Default, But Allow Manual Changes' Setting</span>
                                                                                    <ul class="mb-0">
                                                                                        <li>Agent status is set to 'Available' even after they login</li>
                                                                                        <li>
                                                                                            However, Agent can still manually set themselves to
                                                                                            <ul>
                                                                                                <li>Offline</li>
                                                                                                <li>On Break</li>
                                                                                                <li>Busy</li>
                                                                                            </ul>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <i slot="reference"
                                                                                   class="fa fa-info-circle text-primary ml-2">
                                                                                </i>
                                                                            </el-popover>
                                                                        </h5>
                                                                        <small>Put user on available status after login and disable idle mode detection (auto offline).</small>
                                                                    </div>
                                                                    <div class="switch-label">
                                                                        <el-switch v-model="user.go_to_available_after_login"
                                                                                   active-color="#00a344"
                                                                                   :disabled="isCompanyForcedAvailable"
                                                                                   data-testid="enable-by-default-switch"
                                                                                   @change="preValidateForm('user_form')">
                                                                        </el-switch>
                                                                        <label>Enable available by default, but allow manual changes</label>
                                                                        <span class="badge badge-warning py-1"
                                                                              v-if="isCompanyForcedAvailable">
                                                                    Forced at account level
                                                                </span>
                                                                    </div>
                                                                    <div>
                                                                        <a href="#go_to_available_after_login"
                                                                           class="border-bottom"
                                                                           v-if="hasRole('Company Agent')"
                                                                           data-testid="has-role-account-settings-link">
                                                                            See related account settings
                                                                        </a>
                                                                        <a href="/account?tab=calling-settings#force_users_always_available"
                                                                           class="border-bottom border-primary text-primary"
                                                                           data-testid="has-not-role-account-settings-link"
                                                                           v-else>
                                                                            See related account settings
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </el-tooltip>
                                                        </el-form-item>

                                                        <el-form-item prop="wrap_up_seconds"
                                                                      class="no-border">
                                                            <div class="form-label">
                                                                                <span class="page-anchor"
                                                                                      id="wrap_up_seconds">
                                                                                </span>
                                                                <h5>Wrap up duration</h5>
                                                                <small>Stay on wrap up for this amount of time before you go back to available for the next call.</small>
                                                            </div>
                                                            <div class="width-600">
                                                                <el-select class="width-300"
                                                                           placeholder="Select wrap up seconds"
                                                                           v-model="user.wrap_up_seconds"
                                                                           :disabled="current_company && current_company.force_wrap_up"
                                                                           data-testid="wrap-up-duration-selector">
                                                                    <el-option
                                                                        :key="item.value"
                                                                        :label="item.label"
                                                                        :value="item.value"
                                                                        v-for="item in wrap_up_seconds">
                                                                    </el-option>
                                                                </el-select>
                                                                <span
                                                                    v-if="current_company && current_company.force_wrap_up"
                                                                    class="badge badge-warning py-1">
                                                                    Forced at account level
                                                                </span>
                                                            </div>
                                                        </el-form-item>
                                                    </div>
                                                    <div class="col-3 d-none d-md-block ml-auto">
                                                        <div
                                                            class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header">
                                                            <h6 class="list-group-item">
                                                                On This Page
                                                            </h6>
                                                            <a href="#transcription"
                                                               class="list-group-item list-group-item-action"
                                                               v-if="current_company && current_company.transcription_enabled"
                                                               data-testid="personalization-smart-transcription-link"
                                                               @click="shine('transcription')">
                                                                AloAi Voice Analytics
                                                            </a>
                                                            <a href="#url_shortener"
                                                               class="list-group-item list-group-item-action"
                                                               v-if="current_company && !current_company.is_whitelabel && current_company.url_shortener_enabled"
                                                               data-testid="personalization-url-shortener-link"
                                                               @click="shine('url_shortener')">
                                                                URL Shortener
                                                            </a>
                                                            <a href="#go_to_available_after_login"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="personalization-available-link"
                                                               @click="shine('go_to_available_after_login')">
                                                                Available by Default, But Allow Manual Changes
                                                            </a>
                                                            <a href="#wrap_up_seconds"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="personalization-wrap-up-duration-link"
                                                               @click="shine('wrap_up_seconds')">
                                                                Wrap up duration
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-tab-pane>
                                            <el-tab-pane name="inbound-call-settings"
                                                         data-testid="inbound-call-settings-pane"
                                                         class="p-2"
                                                         label="Inbound Call Settings"
                                                         v-if="user_id && canBeEdited && !auth.user.profile.is_reseller">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <!-- Inbound Call Settings -->
                                                        <div>
                                                            <div class="alert alert-warning alert-aloware d-inline-block"
                                                                 v-if="!hasRole(['Company Admin', 'Company Agent'])">
                                                                <i class="fa fa-exclamation-triangle"></i> Contact your admin to change these settings.
                                                            </div>

                                                            <el-form-item class="row">
                                                                <div class="col-12">
                                                                    <div class="mb-2">
                                                                        <span class="page-anchor"
                                                                              id="extension">
                                                                        </span>
                                                                        <div class="form-label">
                                                                            <h5>Extension</h5>
                                                                            <small>
                                                                                An extension is a unique company-wide
                                                                                identifier
                                                                                for a
                                                                                user. You can select an extension here and
                                                                                setup your line's IVR to listen for
                                                                                extensions.
                                                                                When a
                                                                                caller enters an extension we will
                                                                                automatically
                                                                                connect the call to the respective user.
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                    <user-extension-select :options="options"
                                                                                           :disabled="!hasRole(['Company Admin'])"
                                                                                           v-model="user.extension"
                                                                                           @filter-extensions="filterExtensions"
                                                                                           @change="preValidateForm('user_form')">
                                                                    </user-extension-select>
                                                                </div>
                                                            </el-form-item>

                                                            <el-form-item
                                                                v-if="hasRole(['Company Admin', 'Company Agent'], user.role_names)">
                                                                <span class="page-anchor"
                                                                      id="scheduling">
                                                                </span>
                                                                <div class="form-label">
                                                                    <h5 class="mt-3">Working Hours</h5>
                                                                    <small>
                                                                        This setting sets the working hours for
                                                                        this user. If a call comes in outside of these hours
                                                                        it won't ring this user. Note that the times are
                                                                        local to <b>your company</b> timezone, currently set
                                                                        to
                                                                        <b v-if="current_company">{{ current_company.timezone }}</b>
                                                                    </small>
                                                                </div>
                                                                <div class="form-group">
                                                                    <business-hours
                                                                        v-if="!loading_operating_hours && user.operating_hours"
                                                                        type="select"
                                                                        color="#00BF50"
                                                                        :days="user.operating_hours"
                                                                        :time-increment="timeIncrement">
                                                                    </business-hours>
                                                                </div>
                                                            </el-form-item>

                                                            <!-- Personal Voicemail -->
                                                            <el-form-item
                                                                v-if="!user.is_destination && !auth.user.profile.is_reseller">
                                                                <span class="page-anchor"
                                                                      id="personal-voicemail">
                                                                </span>
                                                                <div class="form-label">
                                                                    <h5 class="mt-3">
                                                                        Personal Voicemail
                                                                    </h5>
                                                                    <small>
                                                                        Should {{ statics.name }} take a voicemail when this
                                                                        user is not
                                                                        available and their extension is dialed?
                                                                    </small>
                                                                </div>
                                                                <div class="row mt-2">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <el-form-item
                                                                                        class="no-border"
                                                                                        prop="missed_call_handling_mode">
                                                                                        <div class="form-label">
                                                                                            <label>When a direct call is missed:</label>
                                                                                        </div>
                                                                                        <el-radio-group
                                                                                            v-model="user.missed_calls_settings.missed_call_handling_mode"
                                                                                            data-testid="direct-call-missed-radio"
                                                                                            @change="preValidateForm('user_form')">
                                                                                            <el-radio :label="MissedCallBehaviors.MISSED_CALL_BEHAVIOR_NOTHING"
                                                                                                      class="_600"
                                                                                                      border>
                                                                                                Do Nothing
                                                                                            </el-radio>
                                                                                            <el-radio :label="MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL"
                                                                                                      class="_600"
                                                                                                      border>
                                                                                                Voicemail
                                                                                            </el-radio>
                                                                                        </el-radio-group>
                                                                                    </el-form-item>

                                                                                    <div class="pt-3"
                                                                                         v-if="user.missed_calls_settings.missed_call_handling_mode === MissedCallBehaviors.MISSED_CALL_BEHAVIOR_VOICEMAIL">
                                                                                        <div
                                                                                            class="b-b nav-active-greenish">
                                                                                            <ul class="nav nav-tabs">
                                                                                                <li class="nav-item">
                                                                                                    <a class="border rounded-top nav-link active"
                                                                                                       href="#"
                                                                                                       data-toggle="tab"
                                                                                                       data-target="#voicemail-greeting-file"
                                                                                                       aria-expanded="false"
                                                                                                       data-testid="play-recording-link">
                                                                                                        <i class="fa fa-play text-md"></i>
                                                                                                        Play Recording
                                                                                                    </a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div
                                                                                            class="tab-content pt-2 border border-top-0">
                                                                                            <div class="my-3 mx-3">
                                                                                                <div class="mx-3 tab-pane animated fadeIn _400 active"
                                                                                                     id="voicemail-greeting-file"
                                                                                                     aria-expanded="true">
                                                                                                    <div>
                                                                                                        <audio-recorder v-if="user.missed_calls_settings.voicemail_file === null"
                                                                                                                        class="audio-recorder-wrapper"
                                                                                                                        :action="'/api/v1/user/' + user.id + '/missed-call-voicemail'"
                                                                                                                        :upload_type="'voicemail_file'"
                                                                                                                        data-testid="audio-recored-action"
                                                                                                                        @applyUploadedAudio="applyUploadedAudio">
                                                                                                        </audio-recorder>
                                                                                                        <div
                                                                                                            class="audio-uploader-wrapper">
                                                                                                            <el-upload
                                                                                                                v-if="user.missed_calls_settings.voicemail_file === null"
                                                                                                                drag
                                                                                                                :action="'/api/v1/user/' + user.id + '/missed-call-voicemail'"
                                                                                                                :headers="headers"
                                                                                                                :on-success="onSuccessUploadMissedCallVoicemail"
                                                                                                                :on-error="onFailedUploadMissedCallVoicemail"
                                                                                                                :on-progress="progressUploadMissedCallVoicemail"
                                                                                                                :before-upload="beforeUploadMissedCallVoicemail"
                                                                                                                :file-list="uploadFileList.missed_call_voicemail"
                                                                                                                data-testid="audio-upload-wrapper">
                                                                                                                <i class="el-icon-upload"></i>
                                                                                                                <div
                                                                                                                    class="el-upload__text">
                                                                                                                    Drop
                                                                                                                    file
                                                                                                                    here
                                                                                                                    or
                                                                                                                    <em>click
                                                                                                                        to
                                                                                                                        upload</em>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    class="el-upload__tip"
                                                                                                                    slot="tip">
                                                                                                                    MP3/WAV
                                                                                                                    file
                                                                                                                    (less
                                                                                                                    than
                                                                                                                    8MB)
                                                                                                                </div>
                                                                                                            </el-upload>
                                                                                                            <el-progress
                                                                                                                v-if="user.missed_calls_settings.voicemail_file === null"
                                                                                                                :text-inside="true"
                                                                                                                :stroke-width="18"
                                                                                                                :percentage="uploadPercentage.missed_call_voicemail"
                                                                                                                :status="uploadStatus.missed_call_voicemail">
                                                                                                            </el-progress>
                                                                                                            <div
                                                                                                                class="d-flex justify-content-start align-items-center">
                                                                                                                <audio
                                                                                                                    v-if="user.missed_calls_settings.voicemail_file !== null"
                                                                                                                    controls
                                                                                                                    data-testid="audio-component">
                                                                                                                    <source
                                                                                                                        :src="'/static/uploaded_file/' + user.missed_calls_settings.voicemail_file">
                                                                                                                    Your
                                                                                                                    browser
                                                                                                                    does
                                                                                                                    not
                                                                                                                    support
                                                                                                                    the
                                                                                                                    audio
                                                                                                                    element.
                                                                                                                </audio>
                                                                                                                <button
                                                                                                                    class="btn btn-sm btn-danger ml-3"
                                                                                                                    v-if="user.missed_calls_settings.voicemail_file !== null"
                                                                                                                    data-testid="remove-file-button"
                                                                                                                    @click.prevent="deleteMissedCallVoicemailFile">
                                                                                                                    <i class="fa fa-spin fa-spinner"
                                                                                                                       v-show="loading_delete_missed_call_voicemail"></i>
                                                                                                                    <i class="fa fa-trash"
                                                                                                                       v-show="!loading_delete_missed_call_voicemail"></i>
                                                                                                                    <span>Remove file</span>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>

                                                            <el-form-item v-if="hasRole('Company Admin')">
                                                                <span class="page-anchor"
                                                                      id="operating-states">
                                                                    </span>
                                                                <div class="form-label">
                                                                    <h5>Operating States</h5>
                                                                    <small>Check all that apply. If you operate
                                                                        nation-wide, click "Check All".</small>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <el-form-item class="no-border">
                                                                                        <el-checkbox
                                                                                            v-model="disable_geo_routing"
                                                                                            :disabled="!hasRole(['Company Admin', 'Company Agent'])"
                                                                                            data-testid="disable-geo-routing">
                                                                                            Do not enable
                                                                                            geo-routing for this user
                                                                                        </el-checkbox>
                                                                                    </el-form-item>
                                                                                </div>
                                                                                <div class="col-12"
                                                                                     prop="operating_states_limit"
                                                                                     v-if="!disable_geo_routing">
                                                                                    <el-form-item label="United States" class="no-border">
                                                                                        <el-checkbox-group
                                                                                            v-model="user.operating_states_limit.us"
                                                                                            data-testid="us-states-checkbox"
                                                                                            @change="handleCheckedUSChange">
                                                                                            <el-checkbox
                                                                                                v-for="us_state in states.us"
                                                                                                :label="us_state"
                                                                                                :key="us_state">{{
                                                                                                    us_state
                                                                                                }}
                                                                                            </el-checkbox>
                                                                                        </el-checkbox-group>
                                                                                        <el-checkbox
                                                                                            class="pull-right _600"
                                                                                            :indeterminate="isUSIndeterminate"
                                                                                            v-model="checkAllUS"
                                                                                            data-testid="check-all-us-checkbox"
                                                                                            @change="handleCheckAllUSChange">
                                                                                            Check all
                                                                                        </el-checkbox>
                                                                                    </el-form-item>
                                                                                    <el-form-item label="Canada"
                                                                                                  class="no-border"
                                                                                                  v-if="auth.user.profile.country=='CA'">
                                                                                        <el-checkbox-group
                                                                                            v-model="user.operating_states_limit.ca"
                                                                                            data-testid="states-ca-checkbox"
                                                                                            @change="handleCheckedCAChange">
                                                                                            <el-checkbox
                                                                                                v-for="ca_province in states.ca"
                                                                                                :label="ca_province"
                                                                                                :key="ca_province">
                                                                                                {{ ca_province }}
                                                                                            </el-checkbox>
                                                                                        </el-checkbox-group>
                                                                                        <el-checkbox
                                                                                            class="pull-right _600"
                                                                                            :indeterminate="isCAIndeterminate"
                                                                                            v-model="checkAllCA"
                                                                                            data-testid="check-all-ca-checkbox"
                                                                                            @change="handleCheckAllCAChange">
                                                                                            Check all
                                                                                        </el-checkbox>
                                                                                    </el-form-item>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>

                                                            <el-form-item>
                                                                <span class="page-anchor"
                                                                      id="operating-area-codes">
                                                                    </span>
                                                                <div class="form-label">
                                                                    <h5>Operating Area Codes (Beta)</h5>
                                                                    <small>Customize the area codes that you operate on.</small>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <el-form-item class="no-border">
                                                                                        <el-checkbox
                                                                                            v-model="disable_area_code_routing"
                                                                                            :disabled="!hasRole(['Company Admin', 'Company Agent'])"
                                                                                            data-testid="disable-area-code-routing-checkbox">
                                                                                            Do not enable area code routing for this user
                                                                                        </el-checkbox>
                                                                                    </el-form-item>
                                                                                </div>
                                                                                <div class="col-12"
                                                                                     prop="area_codes_limit"
                                                                                     v-if="!disable_area_code_routing">
                                                                                    <us-area-code-selector v-model="user.operating_area_codes_limit"
                                                                                                           :multiple="true"
                                                                                                           data-testid="user-area-codes-selector"
                                                                                                           @change="changeUserSelectedAreaCodes">
                                                                                    </us-area-code-selector>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>

                                                            <el-form-item>
                                                                <span class="page-anchor"
                                                                      id="follow-up">
                                                                    </span>
                                                                <div class="form-label">
                                                                    <h5>Follow Up</h5>
                                                                    <small>
                                                                        Send a message to the attempted agent who missed
                                                                        a call.
                                                                        The lead information (phone number) will be
                                                                        automatically appended.
                                                                    </small>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <el-form-item class="mb-0 no-border">
                                                                                        <el-checkbox
                                                                                            v-model="user.should_message_if_missed"
                                                                                            :disabled="!hasRole(['Company Admin', 'Company Agent'])"
                                                                                            data-testid="check-message-if-missed-checkbox"
                                                                                            @change="checkMessageIfMissed">
                                                                                            If the call is missed, send
                                                                                            a text
                                                                                            message to this agent.
                                                                                        </el-checkbox>
                                                                                    </el-form-item>
                                                                                    <el-form-item
                                                                                        class="no-border"
                                                                                        prop="missed_call_message"
                                                                                        v-if="user.should_message_if_missed">
                                                                                        <div class="input-group">
                                                                                            <textarea type="text"
                                                                                                      class="form-control pt-2 pb-2"
                                                                                                      rows="2"
                                                                                                      v-model="user.missed_call_message"
                                                                                                      data-testid="missed-call-message-text-area"
                                                                                                      @input="preValidateForm('user_form')">
                                                                                            </textarea>
                                                                                            <variable-dialog></variable-dialog>
                                                                                        </div>
                                                                                        <template-list-dialog
                                                                                            :text_button="true"/>
                                                                                    </el-form-item>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>

                                                            <el-form-item>
                                                                <span class="page-anchor"
                                                                      id="caller-notification">
                                                                </span>
                                                                <div class="form-label">
                                                                    <h5 class="mt-3">Caller Notification</h5>
                                                                    <small>Notify caller with a text message</small>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <el-form-item class="mb-0 no-border">
                                                                                        <el-checkbox
                                                                                            v-model="user.should_message_caller_if_completed"
                                                                                            :disabled="!hasRole(['Company Admin', 'Company Agent'])"
                                                                                            data-testid="send-message-to-the-caller-checkbox"
                                                                                            @change="checkMessageCallerIfCompleted">
                                                                                            If the call is completed,
                                                                                            send a
                                                                                            text
                                                                                            message
                                                                                            to the caller.
                                                                                        </el-checkbox>
                                                                                    </el-form-item>
                                                                                    <el-form-item
                                                                                        class="no-border"
                                                                                        prop="completed_call_message_caller"
                                                                                        v-if="user.should_message_caller_if_completed">
                                                                                        <div class="input-group">
                                                                                    <textarea type="text"
                                                                                              class="form-control pt-2 pb-2"
                                                                                              rows="2"
                                                                                              v-model="user.completed_call_message_caller"
                                                                                              data-testid="completed-call-message-caller-text-area"
                                                                                              @input="preValidateForm('user_form')">
                                                                                    </textarea>
                                                                                            <variable-dialog
                                                                                                :has_agent="true"></variable-dialog>
                                                                                        </div>
                                                                                        <template-list-dialog
                                                                                            :text_button="true"/>
                                                                                    </el-form-item>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>

                                                            <el-form-item
                                                                class="no-border"
                                                                v-if="auth.user.profile.lead_gen_tools_enabled">
                                                                <h5 class="mt-3 mb-5">Advanced Settings</h5>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <el-form-item
                                                                                        prop="daily_call_limit"
                                                                                        class="mt-5">
                                                                                        <span class="page-anchor"
                                                                                              id="daily-call-limit">
                                                                                            </span>
                                                                                        <div class="form-label">
                                                                                            <h5>Daily Call Limit</h5>
                                                                                            <small>
                                                                                                The maximum number of
                                                                                                *qualified* calls that
                                                                                                this user may receive in
                                                                                                a day.
                                                                                                If you have not
                                                                                                configured auto
                                                                                                qualification, this will
                                                                                                be total answered calls.
                                                                                                It's best to set this to
                                                                                                a high number, such as
                                                                                                1000.
                                                                                            </small>
                                                                                        </div>
                                                                                        <el-input-number
                                                                                            placeholder="1000"
                                                                                            v-model="user.daily_call_limit"
                                                                                            :min=0
                                                                                            :disabled="!hasRole(['Company Admin', 'Company Agent'])"
                                                                                            data-testid="maximun-number-of-calls-input-number"
                                                                                            @change="preValidateForm('user_form')">
                                                                                        </el-input-number>
                                                                                    </el-form-item>
                                                                                    <el-form-item class="mt-5 mb-0 no-border">
                                                                                        <span class="page-anchor"
                                                                                              id="duplicate-call-prevention">
                                                                                        </span>
                                                                                        <div class="form-label">
                                                                                            <h5>Duplicate Call
                                                                                                Prevention</h5>
                                                                                            <small>
                                                                                                Prevent the same lead
                                                                                                from going to this user
                                                                                                for a period of time.
                                                                                                This setting is useful
                                                                                                for lead sellers only
                                                                                                and might conflict with
                                                                                                your routing settings.
                                                                                            </small>
                                                                                        </div>
                                                                                        <el-checkbox
                                                                                            v-model="dontDuplicateCallPrevention"
                                                                                            :disabled="!hasRole(['Company Admin', 'Company Agent'])"
                                                                                            data-testid="duplicate-call-prevention-checkbox"
                                                                                            @change="checkDuplicateCallPrevention">
                                                                                            Don't prevent duplicate
                                                                                            calls from a
                                                                                            lead
                                                                                        </el-checkbox>
                                                                                    </el-form-item>
                                                                                    <el-form-item
                                                                                        class="no-border"
                                                                                        v-if="!dontDuplicateCallPrevention">
                                                                                        <el-select
                                                                                            placeholder="Select a time period"
                                                                                            v-model="user.duplicate_call_prevention_period"
                                                                                            data-testid="duplicated-call-prevention-options-selector">
                                                                                            <el-option
                                                                                                v-for="item in duplicateCallPreventionOptions"
                                                                                                :key="item.value"
                                                                                                :label="item.label"
                                                                                                :value="item.value">
                                                                                            </el-option>
                                                                                        </el-select>
                                                                                    </el-form-item>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 d-none d-md-block ml-auto">
                                                        <div
                                                            class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header">
                                                            <h6 class="list-group-item">
                                                                On This Page
                                                            </h6>
                                                            <a href="#extension"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="inbound-call-settings-extension-link"
                                                               @click="shine('extension')">
                                                                Extension
                                                            </a>
                                                            <a href="#scheduling"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="inbound-call-settings-working-hours-link"
                                                               @click="shine('scheduling')">
                                                                Working Hours
                                                            </a>
                                                            <a href="#personal-voicemail"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="inbound-call-settings-personal-voicemail-link"
                                                               @click="shine('personal-voicemail')">
                                                                Personal Voicemail
                                                            </a>
                                                            <a href="#operating-states"
                                                               class="list-group-item list-group-item-action"
                                                               v-if="hasRole('Company Admin')"
                                                               data-testid="inbound-call-settings-operating-states-link"
                                                               @click="shine('operating-states')">
                                                                Operating States
                                                            </a>
                                                            <a href="#operating-area-codes"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="inbound-call-settings-operating-area-codes-link"
                                                               @click="shine('operating-area-codes')">
                                                                Operating Area Codes
                                                            </a>
                                                            <a href="#follow-up"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="inbound-call-settings-follow-up-link"
                                                               @click="shine('follow-up')">
                                                                Follow Up
                                                            </a>
                                                            <a href="#caller-notification"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="inbound-call-settings-caller-notification-link"
                                                               @click="shine('caller-notification')">
                                                                Caller Notification
                                                            </a>
                                                            <a href="#concurrent-call-limit"
                                                               v-if="auth.user.profile.lead_gen_tools_enabled"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="inbound-call-settings-concurrent-call-limit-link"
                                                               @click="shine('concurrent-call-limit')">
                                                                Concurrent Call Limit
                                                            </a>
                                                            <a href="#daily-call-limit"
                                                               v-if="auth.user.profile.lead_gen_tools_enabled"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="inbound-call-settings-daily-call-limit-link"
                                                               @click="shine('daily-call-limit')">
                                                                Daily Call Limit
                                                            </a>
                                                            <a href="#duplicate-call-prevention"
                                                               v-if="auth.user.profile.lead_gen_tools_enabled"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="inbound-call-settings-duplicate-call-prevention-link"
                                                               @click="shine('duplicate-call-prevention')">
                                                                Duplicate Call Prevention
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-tab-pane>
                                            <el-tab-pane name="outbound-call-settings"
                                                         data-testid="outbound-call-settings-pane"
                                                         class="p-2"
                                                         label="Outbound Call Settings"
                                                         v-if="user_id && canBeEdited && !auth.user.profile.is_reseller">
                                                <!-- Outbound Call Settings -->
                                                <div>
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <h2 class="text-danger"
                                                                v-if="!hasRole(['Company Admin', 'Company Agent'])">
                                                                Contact your admin to change these settings.
                                                            </h2>
                                                            <el-form-item>
                                                                <span class="page-anchor"
                                                                      id="default-outbound-line">
                                                                    </span>
                                                                <div class="form-label">
                                                                    <h5>Default Outbound Line</h5>
                                                                    <small>Decide what line is used when this user makes
                                                                        an outbound call.</small>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <el-form-item label="Outbound Line:"
                                                                                          class="no-border"
                                                                                          prop="outbound_calling_mode">
                                                                                <el-radio-group
                                                                                    v-model="user.outbound_calling_selector"
                                                                                    :disabled="!hasRole(['Company Admin', 'Company Agent']) || (current_company && current_company.force_outbound_line)"
                                                                                    data-testid="outbond-line-radio-group">
                                                                                    <el-popover
                                                                                        placement="bottom-start"
                                                                                        width="350"
                                                                                        trigger="hover"
                                                                                        content="Use account's default outbound line for this user's outbound calls."
                                                                                        data-testid="outbond-use-company-default-popover">
                                                                                        <el-radio class="_600"
                                                                                                  slot="reference"
                                                                                                  :label="2"
                                                                                                  border>
                                                                                            Use Company Default
                                                                                        </el-radio>
                                                                                    </el-popover>
                                                                                    <el-popover
                                                                                        placement="bottom-start"
                                                                                        width="350"
                                                                                        trigger="hover"
                                                                                        content="Choose a single outgoing line for this user."
                                                                                        data-testid="outbond-select-manually-popover">
                                                                                        <el-radio slot="reference"
                                                                                                  class="_600"
                                                                                                  :label="1"
                                                                                                  border>
                                                                                            Select Manually
                                                                                        </el-radio>
                                                                                    </el-popover>
                                                                                    <el-popover
                                                                                        placement="bottom-start"
                                                                                        width="350"
                                                                                        trigger="hover"
                                                                                        content="Always asks the user what line they want to use before making an outbound call."
                                                                                        data-testid="outbond-always-popover">
                                                                                        <el-radio slot="reference"
                                                                                                  class="_600"
                                                                                                  :label="3"
                                                                                                  border>
                                                                                            Always Ask
                                                                                        </el-radio>
                                                                                    </el-popover>
                                                                                </el-radio-group>
                                                                                <span
                                                                                    v-if="current_company && current_company.force_outbound_line"
                                                                                    class="badge badge-warning py-1">
                                                                                    Forced at account level
                                                                                </span>
                                                                            </el-form-item>
                                                                            <el-form-item label="Outbound Calling"
                                                                                          class="no-border"
                                                                                          prop="default_outbound_campaign_id"
                                                                                          v-show="user.outbound_calling_selector == 1"
                                                                                          filterable
                                                                                          clearable>
                                                                                <div class="width-300">
                                                                                    <campaign-selector
                                                                                        class_prop="no-select width-300"
                                                                                        placeholder="Default line for outbound calls"
                                                                                        :show_paused="false"
                                                                                        v-model="user.default_outbound_campaign_id"
                                                                                        :disabled="!hasRole(['Company Admin', 'Company Agent']) || (current_company && current_company.force_outbound_line)"
                                                                                        filterable
                                                                                        clearable
                                                                                        @change="changeDefaultOutboundCampaignId"
                                                                                        @trigger_validation="preValidateForm('user_form')">
                                                                                    </campaign-selector>
                                                                                </div>
                                                                            </el-form-item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>

                                                            <el-form-item class="mb-3">
                                                                <span class="page-anchor"
                                                                      id="call-recording">
                                                                </span>
                                                                <div class="form-label">
                                                                    <h5 class="mt-3">Call Recordings</h5>
                                                                    <small>
                                                                        Should outbound calls
                                                                    </small>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <el-form-item
                                                                                class="no-border"
                                                                                prop="outbound_call_recording_mode">
                                                                                <el-radio-group
                                                                                    v-model="user.outbound_call_recording_mode"
                                                                                    data-testid="call-recordings-radio-group"
                                                                                    @change="preValidateForm('user_form')"
                                                                                    :disabled="!hasRole(['Company Admin', 'Company Agent']) || (current_company && current_company.force_outbound_recording)">
                                                                                    <el-radio
                                                                                        :label="OutboundCallRecordingModes.OUTBOUND_CALL_RECORDING_MODE_DEFAULT"
                                                                                        class="_600"
                                                                                        border>
                                                                                        Use Company Default
                                                                                    </el-radio>
                                                                                    <el-radio
                                                                                        :label="OutboundCallRecordingModes.OUTBOUND_CALL_RECORDING_MODE_ALWAYS"
                                                                                        class="_600"
                                                                                        border>
                                                                                        Always Record
                                                                                    </el-radio>
                                                                                    <el-radio
                                                                                        :label="OutboundCallRecordingModes.OUTBOUND_CALL_RECORDING_MODE_NEVER"
                                                                                        class="_600"
                                                                                        border>
                                                                                        Never Record
                                                                                    </el-radio>
                                                                                </el-radio-group>
                                                                                <span
                                                                                    v-if="current_company && current_company.force_outbound_recording"
                                                                                    class="badge badge-warning py-1">
                                                                                    Forced at account level
                                                                                </span>
                                                                            </el-form-item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>

                                                            <el-form-item class="mb-3">
                                                                <span class="page-anchor"
                                                                      id="two-legged-outbound-call">
                                                                    </span>
                                                                <div class="form-label">
                                                                    <h5 class="mt-3">Two Legged Outbound Calls
                                                                        (Beta)</h5>
                                                                    <small>
                                                                        By enabling this option we will call your
                                                                        contacts with your secondary phone number.
                                                                    </small>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <el-form-item
                                                                                class="no-border"
                                                                                prop="enabled_two_legged_outbound">
                                                                                <div class="switch-label">
                                                                                    <el-switch
                                                                                        v-model="user.enabled_two_legged_outbound"
                                                                                        active-color="#00a344"
                                                                                        :disabled="!hasRole(['Company Admin', 'Company Agent'])"
                                                                                        data-testid="two-legged-outbond-calls-switch">
                                                                                    </el-switch>
                                                                                    <label>Enable Two Legged Outbound Calls</label>
                                                                                </div>
                                                                            </el-form-item>
                                                                        </div>
                                                                        <div class="form-group no-border"
                                                                             v-show="user.enabled_two_legged_outbound">
                                                                            <el-form-item label="Secondary Phone Number"
                                                                                          prop="secondary_phone_number">
                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <el-input type="tel"
                                                                                                  placeholder="(123) 456-7890"
                                                                                                  class="w-100"
                                                                                                  v-model="user.secondary_phone_number"
                                                                                                  :disabled="user.role_name && user.read_only_access"
                                                                                                  data-testid="secondary-phone-number-input"
                                                                                                  @input="preValidateForm('user_form')">
                                                                                        </el-input>
                                                                                    </div>
                                                                                </div>
                                                                            </el-form-item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-form-item>

                                                            <el-form-item class="mb-2 no-border">
                                                                <span class="page-anchor"
                                                                      id="voicemail-drop-library">
                                                                </span>

                                                                <!-- voicemail drop library section -->
                                                                <div class="form-label">
                                                                    <h5 class="mt-3">Voicemail Drop Library</h5>
                                                                    <small>
                                                                        Build you voicemail drop library here.
                                                                        When using the PowerDialer or calling a lot of leads
                                                                        manually,
                                                                        you
                                                                        can use voicemail drop to put a voicemail after the
                                                                        beep without
                                                                        being on the call.
                                                                    </small>
                                                                </div>
                                                                <user-vm-drop :user_id="user.id"></user-vm-drop>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="col-3 d-none d-md-block ml-auto">
                                                            <div class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header">
                                                                <h6 class="list-group-item">
                                                                    On This Page
                                                                </h6>
                                                                <a href="#default-outbound-line"
                                                                   class="list-group-item list-group-item-action"
                                                                   data-testid="default-outbond-line-link"
                                                                   @click="shine('default-outbound-line')">
                                                                    Default Outbound Line
                                                                </a>
                                                                <a href="#call-recording"
                                                                   class="list-group-item list-group-item-action"
                                                                   data-testid="outbond-calls-recording-link"
                                                                   @click="shine('call-recording')">
                                                                    Call Recordings
                                                                </a>
                                                                <a href="#two-legged-outbound-call"
                                                                   class="list-group-item list-group-item-action"
                                                                   data-testid="two-legged-outbound-calls-link"
                                                                   @click="shine('two-legged-outbound-call')">
                                                                    Two Legged Outbound Calls
                                                                </a>
                                                                <a href="#voicemail-drop-library"
                                                                   class="list-group-item list-group-item-action"
                                                                   data-testid="outbond-voicemail-drop-library-link"
                                                                   @click="shine('voicemail-drop-library')">
                                                                    Voicemail Drop Library
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-tab-pane>
                                            <el-tab-pane name="notification-settings"
                                                         data-testid="notification-settings-pane"
                                                         class="p-2"
                                                         label="Notification Settings"
                                                         v-if="user_id && canBeEdited && !auth.user.profile.is_reseller">
                                                <!-- Notification Settings -->
                                                <div class="row">
                                                    <div class="col-6">
                                                        <el-form-item>
                                                            <span class="page-anchor"
                                                                  id="notification-channels">
                                                            </span>
                                                            <div class="form-label">
                                                                <h5>Notification Channels</h5>
                                                                <small>Choose what channels you want this user to get notified on.</small>
                                                            </div>

                                                            <div class="switch-label mb-2">
                                                                <el-switch active-color="#00a344"
                                                                           data-testid="in-app-switch"
                                                                           v-model="inapp_notifs"
                                                                           @change="checkNotificationsSettings">
                                                                </el-switch>
                                                                <label>In-App</label>
                                                            </div>

                                                            <div class="switch-label mb-2">
                                                                <el-switch active-color="#00a344"
                                                                           data-testid="desktop-switch"
                                                                           v-model="desktop_notifs"
                                                                           @change="checkNotificationsSettings">
                                                                </el-switch>
                                                                <label>Desktop</label>
                                                            </div>

                                                            <div class="switch-label mb-2">
                                                                <el-switch active-color="#00a344"
                                                                           data-testid="mobile-switch"
                                                                           v-model="mobile_notifs"
                                                                           @change="checkNotificationsSettings">
                                                                </el-switch>
                                                                <label>Mobile</label>
                                                            </div>

                                                            <div class="switch-label mb-2">
                                                                <el-switch active-color="#00a344"
                                                                           data-testid="email-switch"
                                                                           v-model="email_notifs"
                                                                           @change="checkNotificationsSettings">
                                                                </el-switch>
                                                                <label>Email</label>
                                                            </div>

                                                            <div class="switch-label mb-2">
                                                                <el-switch active-color="#00a344"
                                                                           data-testid="text-message-switch"
                                                                           v-model="text_notifs"
                                                                           @change="handleTextMessageChange">
                                                                </el-switch>
                                                                <label>Text Message</label>
                                                            </div>
                                                            <div class="d-flex align-items-center mb-3"
                                                                 v-if="text_notifs">
                                                                <el-checkbox class="ml-5"
                                                                             data-testid="send-text-to-custom-number-switch"
                                                                             v-model="set_custom_number"
                                                                             @change="handleCustomPhoneNumberOff">
                                                                    Send texts to custom number:
                                                                </el-checkbox>

                                                                <el-form-item class="pb-0 ml-2"
                                                                              prop="notification_phone_number"
                                                                              :required="set_custom_number">
                                                                    <el-input class="w-100"
                                                                              placeholder="(123) 456-7890"
                                                                              data-testid="notification-phone-number-input"
                                                                              :disabled="!set_custom_number"
                                                                              v-model="user.notification_phone_number"
                                                                              @input="preValidateForm('user_form')">
                                                                    </el-input>
                                                                </el-form-item>
                                                            </div>
                                                        </el-form-item>
                                                        <el-form-item class="no-border">
                                                            <span class="page-anchor"
                                                                  id="communication-notifications">
                                                            </span>
                                                            <div class="form-label mb-3">
                                                                <h5>Communication Notifications</h5>
                                                                <small v-if="user_id === auth.user.profile.id">
                                                                    Notify me when the following events happen:
                                                                </small>
                                                                <small v-else>
                                                                    Notify this user when the following communication
                                                                    occurs:
                                                                </small>
                                                            </div>
                                                            <div class="switch-label mb-2">
                                                                <el-tooltip placement="top"
                                                                            :content="isCommunicationNotificationsDisabledTooltipText"
                                                                            :disabled="!isCommunicationNotificationsDisabled">
                                                                    <el-switch active-color="#00a344"
                                                                               data-testid="communication-notification-switch-1"
                                                                               :disabled="isCommunicationNotificationsDisabled"
                                                                               v-model="my_calls">
                                                                    </el-switch>
                                                                </el-tooltip>
                                                                <label>Call to personal line or to the ring groups this user belongs to</label>
                                                            </div>
                                                            <div class="switch-label mb-2">
                                                                <el-tooltip placement="top"
                                                                            :content="isCommunicationNotificationsDisabledTooltipText"
                                                                            :disabled="!isCommunicationNotificationsDisabled">
                                                                    <el-switch active-color="#00a344"
                                                                               data-testid="communication-notification-switch-2"
                                                                               :disabled="isCommunicationNotificationsDisabled"
                                                                               v-model="my_texts">
                                                                    </el-switch>
                                                                </el-tooltip>
                                                                <label>Text message to personal line or to the ring groups this user belongs to</label>
                                                            </div>
                                                            <div class="switch-label mb-2">
                                                                <el-tooltip placement="top"
                                                                            :content="isCommunicationNotificationsDisabledTooltipText"
                                                                            :disabled="!isCommunicationNotificationsDisabled">
                                                                    <el-switch active-color="#00a344"
                                                                               data-testid="communication-notification-switch-3"
                                                                               :disabled="isCommunicationNotificationsDisabled"
                                                                               v-model="my_voicemails">
                                                                    </el-switch>
                                                                </el-tooltip>
                                                                <label>Voicemail to personal line or to the ring groups this user belongs to</label>
                                                            </div>
                                                            <div class="switch-label mb-2">
                                                                <el-tooltip placement="top"
                                                                            :content="isCommunicationNotificationsDisabledTooltipText"
                                                                            :disabled="!isCommunicationNotificationsDisabled">
                                                                    <el-switch active-color="#00a344"
                                                                               data-testid="communication-notification-switch-4"
                                                                               :disabled="isCommunicationNotificationsDisabled"
                                                                               v-model="my_faxes">
                                                                    </el-switch>
                                                                </el-tooltip>
                                                                <label>Fax to personal line</label>
                                                            </div>
                                                            <div class="switch-label mb-2">
                                                                <el-tooltip placement="top"
                                                                            :content="isCommunicationNotificationsDisabledTooltipText"
                                                                            :disabled="!isCommunicationNotificationsDisabled">
                                                                    <el-switch active-color="#00a344"
                                                                               data-testid="communication-notification-switch-5"
                                                                               :disabled="isCommunicationNotificationsDisabled"
                                                                               v-model="my_mentions">
                                                                    </el-switch>
                                                                </el-tooltip>
                                                                <label>When this user is mentioned</label>
                                                            </div>
                                                            <div class="switch-label mb-2">
                                                                <el-tooltip placement="top"
                                                                            :content="isCommunicationNotificationsDisabledTooltipText"
                                                                            :disabled="!isCommunicationNotificationsDisabled">
                                                                    <el-switch active-color="#00a344"
                                                                               data-testid="communication-notification-switch-6"
                                                                               :disabled="isCommunicationNotificationsDisabled"
                                                                               v-model="my_contacts">
                                                                    </el-switch>
                                                                </el-tooltip>
                                                                <label>When a contact is assigned to this user</label>
                                                            </div>
                                                            <div class="switch-label mb-2">
                                                                <el-tooltip placement="top"
                                                                            :content="isCommunicationNotificationsDisabledTooltipText"
                                                                            :disabled="!isCommunicationNotificationsDisabled">
                                                                    <el-switch active-color="#00a344"
                                                                               data-testid="communication-notification-switch-7"
                                                                               :disabled="isCommunicationNotificationsDisabled"
                                                                               v-model="my_appointments">
                                                                    </el-switch>
                                                                </el-tooltip>
                                                                <label>When an appointment is assigned to this user</label>
                                                            </div>
                                                            <div class="switch-label mb-2">
                                                                <el-tooltip placement="top"
                                                                            :content="isCommunicationNotificationsDisabledTooltipText"
                                                                            :disabled="!isCommunicationNotificationsDisabled">
                                                                    <el-switch active-color="#00a344"
                                                                               data-testid="communication-notification-switch-8"
                                                                               :disabled="isCommunicationNotificationsDisabled"
                                                                               v-model="my_reminders">
                                                                    </el-switch>
                                                                </el-tooltip>
                                                                <label>When a reminder is assigned to this user</label>
                                                            </div>
                                                        </el-form-item>

                                                        <el-form-item v-if="userHasAdminRole">
                                                            <span class="page-anchor"
                                                                  id="account-level-notifications">
                                                            </span>
                                                            <div class="form-label mb-3">
                                                                <h5>Account Level Notifications (Admins Only)</h5>
                                                                <small>Receive daily emails for account activity:</small>
                                                            </div>
                                                            <div class="switch-label mb-2 d-flex flex-wrap"
                                                                 v-if="userIsBillingAdmin">
                                                                <div>
                                                                    <el-switch
                                                                        active-color="#00a344"
                                                                        :disabled="!userIsBillingAdmin"
                                                                        v-model="user.enabled_billing_warnings"
                                                                        data-testid="billing-warnings-switch">
                                                                    </el-switch>
                                                                </div>
                                                                <div class="flex-basis-25 flex-grow-1 flex-sm-grow-0">
                                                                    <label>
                                                                        Billing Warnings
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <el-popover width="250"
                                                                                trigger="hover"
                                                                                placement="right"
                                                                                data-testid="only-for-billing-admins-popover">
                                                                        <div>
                                                                            Only enabled for billing admins
                                                                        </div>
                                                                        <span slot="reference">
                                                                            <i class="fa fa-info-circle text-primary"></i>
                                                                        </span>
                                                                    </el-popover>
                                                                </div>
                                                            </div>
                                                            <div class="switch-label mb-2 d-flex flex-wrap"
                                                                 v-if="userIsCompanyAdmin">
                                                                <div>
                                                                    <el-switch
                                                                        active-color="#00a344"
                                                                        v-model="user.enabled_integration_reports"
                                                                        data-testid="integration-reports-switch">
                                                                    </el-switch>
                                                                </div>
                                                                <div class="flex-basis-25 flex-grow-1 flex-sm-grow-0">
                                                                    <label>
                                                                        Integration Reports
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <el-popover width="230"
                                                                                trigger="hover"
                                                                                placement="right"
                                                                                data-testid="integration-reports-popover">
                                                                        <div>
                                                                            <div class="pb-2 font-weight-bold">Integration Reports include:</div>
                                                                            <ul class="mb-0">
                                                                                <li>Users Sync</li>
                                                                                <li>Contacts Sync</li>
                                                                                <li>Contact Dispositions Sync</li>
                                                                                <li>Call Dispositions Sync</li>
                                                                                <li>Failed Integration Connection Status</li>
                                                                            </ul>
                                                                        </div>
                                                                        <span slot="reference">
                                                                            <i class="fa fa-info-circle text-primary"></i>
                                                                        </span>
                                                                    </el-popover>
                                                                </div>
                                                            </div>
                                                            <div class="switch-label mb-2 d-flex flex-wrap"
                                                                 v-if="userIsCompanyAdmin">
                                                                <div>
                                                                    <el-switch
                                                                        active-color="#00a344"
                                                                        v-model="user.enabled_account_reports"
                                                                        data-testid="account-reports-switch">
                                                                    </el-switch>
                                                                </div>
                                                                <div class="flex-basis-25 flex-grow-1 flex-sm-grow-0">
                                                                    <label>
                                                                        Account Reports
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <el-popover width="230"
                                                                                trigger="hover"
                                                                                placement="right"
                                                                                data-testid="account-reports-popover">
                                                                        <div>
                                                                            <div class="pb-2 font-weight-bold">Account Reports include:</div>
                                                                            <ul class="mb-0">
                                                                                <li>Daily Activity Reports</li>
                                                                                <li>Daily Inbound SMS Report</li>
                                                                                <li>Daily Outbound SMS Report</li>
                                                                                <li>Daily Spending Report</li>
                                                                            </ul>
                                                                        </div>
                                                                        <span slot="reference">
                                                                            <i class="fa fa-info-circle text-primary"></i>
                                                                        </span>
                                                                    </el-popover>
                                                                </div>
                                                                <div>
                                                                    <a href="/account?tab=reporting-settings"
                                                                       class="border-bottom border-primary ml-0 ml-md-2 text-primary"
                                                                       data-testid="reporting-settings-link">
                                                                        Click here to enable Account Reports at the account level
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="switch-label mb-2 d-flex flex-wrap"
                                                                 v-if="userIsCompanyAdmin">
                                                                <div>
                                                                    <el-switch
                                                                        active-color="#00a344"
                                                                        v-model="user.enabled_other_reports"
                                                                        data-testid="other-notifications-switch">
                                                                    </el-switch>
                                                                </div>
                                                                <div class="flex-basis-25 flex-grow-1 flex-sm-grow-0">
                                                                    <label>
                                                                        Other Notifications
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <el-popover width="230"
                                                                                trigger="hover"
                                                                                placement="right"
                                                                                data-testid="other-notifications-popover">
                                                                        <div>
                                                                            <div class="pb-2 font-weight-bold">Other Notifications:</div>
                                                                            <ul class="mb-0">
                                                                                <li>Imports/Exports Reports</li>
                                                                                <li>Webhook Failure Reports</li>
                                                                            </ul>
                                                                        </div>
                                                                        <span slot="reference">
                                                                            <i class="fa fa-info-circle text-primary"></i>
                                                                        </span>
                                                                    </el-popover>
                                                                </div>
                                                            </div>
                                                        </el-form-item>

                                                        <el-form-item>
                                                            <span class="page-anchor"
                                                                  id="event-notifications">
                                                            </span>
                                                            <div class="form-label mb-3">
                                                                <h5>Event Notifications</h5>
                                                                <small v-if="user_id === auth.user.profile.id">
                                                                    Notify me when the following appointment or reminder event occurs:
                                                                </small>
                                                                <small v-else>
                                                                    Notify this user when the following appointment or reminder event occurs:
                                                                </small>
                                                            </div>

                                                            <el-checkbox-group
                                                                v-model="user.reminders_options">
                                                                <el-checkbox
                                                                    v-for="reminders_option in reminders_options"
                                                                    style="width: 200px;"
                                                                    :label="reminders_option.value"
                                                                    :key="reminders_option.value"
                                                                    :data-testid="reminders_option.value + '-checkbox'"
                                                                >
                                                                    {{ reminders_option.title }}
                                                                </el-checkbox>
                                                            </el-checkbox-group>
                                                        </el-form-item>

                                                        <el-form-item
                                                            v-if="hasRole(['Company Admin', 'Company Agent'], user.role_names)">
                                                            <el-form-item class="no-border">
                                                                <span class="page-anchor"
                                                                      id="line-notifications">
                                                                </span>
                                                                <div class="form-label">
                                                                    <h5>Line Notifications</h5>
                                                                    <small>Get extra notifications from the lines you choose below.</small>
                                                                </div>
                                                                <div class="switch-label mb-3">
                                                                    <el-switch
                                                                        v-model="should_observe"
                                                                        active-color="#00a344"
                                                                        data-testid="line-notifications-checkbox">
                                                                    </el-switch>
                                                                    <label>Receive notifications from lines</label>
                                                                </div>

                                                                <div v-if="should_observe">
                                                                    <div class="form-label">
                                                                        <label>Select the lines to get notified from</label>
                                                                    </div>
                                                                    <campaign-selector
                                                                        select_style="width: 100%;"
                                                                        multiple
                                                                        :collapsable="false"
                                                                        :value="user.observing_campaigns"
                                                                        v-model="user.observing_campaigns"
                                                                        @change="changeObservingCampaigns">
                                                                    </campaign-selector>
                                                                </div>
                                                            </el-form-item>
                                                        </el-form-item>

                                                        <el-form-item v-if="hasRole(['Company Admin', 'Company Agent'], user.role_names)">
                                                            <span class="page-anchor" id="high-sms-volume-notifications"></span>
                                                            <div class="form-label">
                                                                <h5>High SMS Volume Notifications</h5>
                                                                <small>Receive notifications when too many messages have been sent within a short time period:</small>
                                                            </div>
                                                            <div class="switch-label mb-3">
                                                                <el-switch v-model="high_sms_volume_notifs"
                                                                           active-color="#00a344"
                                                                           data-testid="high-sms-notification-switch"/>
                                                                <label>When an SMS reply exceeds 15 messages within 30 seconds</label>
                                                            </div>
                                                        </el-form-item>
                                                    </div>
                                                    <div class="col-3 d-hide d-md-block ml-auto">
                                                        <div
                                                            class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header">
                                                            <h6 class="list-group-item">
                                                                On This Page
                                                            </h6>
                                                            <a href="#communication-notifications"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="communication-notifications"
                                                               @click="shine('communication-notifications')">
                                                                Communication Notifications
                                                            </a>
                                                            <a href="#account-level-notifications"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="account-level-notifications"
                                                               @click="shine('account-level-notifications')">
                                                                Account Level Notifications (Admins Only)
                                                            </a>
                                                            <a href="#event-notifications"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="event-notifications"
                                                               @click="shine('event-notifications')">
                                                                Event Notifications
                                                            </a>
                                                            <a href="#line-notifications"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="line-notifications"
                                                               @click="shine('line-notifications')">
                                                                Line Notifications
                                                            </a>
                                                            <a href="#notification-channels"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="notifications-channels"
                                                               @click="shine('notification-channels')">
                                                                Notification Channels
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-tab-pane>
                                            <el-tab-pane name="general-information"
                                                         data-testid="general-information-pane"
                                                         class="p-2"
                                                         label="General Information"
                                                         v-if="user_id && canBeEdited && !auth.user.profile.is_reseller">
                                                <!-- General Information -->
                                                <div class="row">
                                                    <div class="col-8">
                                                        <template v-if="user.sip_uri && user.sip_details">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="mb-3"
                                                                                     v-if="!statics.whitelabel">
                                                                                    <div
                                                                                        class="alert alert-success alert-aloware d-inline-block">
                                                                                        <div class="d-flex">
                                                                                            <div class="align-self-center font-weight-bold mr-3">
                                                                                                Have you considered
                                                                                                using
                                                                                                Aloware app on your
                                                                                                smartphone 📱?
                                                                                            </div>
                                                                                            <a class="ml-auto"
                                                                                               target="_blank"
                                                                                               href="https://aloware.com/apps"
                                                                                               data-testid="get-the-app-link">
                                                                                                <el-button size="small"
                                                                                                           type="success"
                                                                                                           plain>
                                                                                                    <strong>Get the
                                                                                                        App</strong>
                                                                                                </el-button>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="alert alert-danger alert-aloware"
                                                                                     v-if="current_company && !current_company.default_outbound_campaign_id && user.answer_by === AnswerTypes.BY_IP_PHONE">
                                                                                    This user will not send outbound calls
                                                                                    unless you
                                                                                    select a default outbound line for your
                                                                                    company.
                                                                                </div>

                                                                                <el-form-item>
                                                                                    <span class="page-anchor"
                                                                                          id="ring_group_member">
                                                                                    </span>
                                                                                    <div class="form-label">
                                                                                        <h5>Ring Groups</h5>
                                                                                        <small>
                                                                                            This user is a member of the following ring groups
                                                                                        </small>
                                                                                    </div>
                                                                                    <ul class="list inset mb-0 ml-3"
                                                                                        v-if="user.ring_group_ids && user.ring_group_ids.length > 0">
                                                                                        <li v-for="ring_group_id in user.ring_group_ids"
                                                                                            :key="ring_group_id"
                                                                                            v-if="getRingGroup(ring_group_id) && getRingGroup(ring_group_id).name && !getRingGroup(ring_group_id).call_waiting"
                                                                                            class="pb-0">
                                                                                            <span class="text-dark-greenish _400">
                                                                                                {{
                                                                                                    getRingGroup(ring_group_id).name | fixName
                                                                                                }}
                                                                                            </span>
                                                                                        </li>
                                                                                    </ul>
                                                                                    <span class="text-md text-danger _600" v-else>This user is not connected to any ring group.</span>
                                                                                </el-form-item>

                                                                                <div v-if="!statics.whitelabel && isUsingSip">
                                                                                    <el-form-item>
                                                                                        <span class="page-anchor"
                                                                                              id="sip_uri">
                                                                                        </span>
                                                                                        <div class="form-label">
                                                                                            <el-alert
                                                                                                class="mb-4"
                                                                                                type="warning"
                                                                                                :closable="false">
                                                                                                <h4 class="font-weight-bold m-auto">We will decommission our SIP phone set up by March 2nd (v10.60),
                                                                                                    2023. Please make sure your users download the Aloware app.</h4>
                                                                                            </el-alert>
                                                                                            <h5>SIP URI</h5>
                                                                                            <small>
                                                                                                A SIP-URI is the SIP
                                                                                                addressing scheme that
                                                                                                communicates who to call via
                                                                                                SIP.
                                                                                                In other words, a SIP URI is
                                                                                                a user’s SIP phone number.
                                                                                                The SIP URI resembles an
                                                                                                e-mail address.
                                                                                            </small>
                                                                                        </div>
                                                                                        <input-readonly-with-copy
                                                                                            :value="user.sip_uri"></input-readonly-with-copy>
                                                                                    </el-form-item>
                                                                                    <el-form-item>
                                                                                        <span class="page-anchor"
                                                                                              id="sip_domain">
                                                                                        </span>
                                                                                        <div class="form-label">
                                                                                            <h5>SIP Domain</h5>
                                                                                            <small>
                                                                                                This is the domain the SIP
                                                                                                phones connect to.
                                                                                            </small>
                                                                                        </div>
                                                                                        <input-readonly-with-copy
                                                                                            :value="user.sip_uri | filterDomain"></input-readonly-with-copy>
                                                                                    </el-form-item>
                                                                                    <el-form-item>
                                                                                        <span class="page-anchor"
                                                                                              id="sip_username">
                                                                                        </span>
                                                                                        <div class="form-label">
                                                                                            <h5>SIP Username</h5>
                                                                                            <small>
                                                                                                The username / authorization
                                                                                                name for this SIP device.
                                                                                            </small>
                                                                                        </div>
                                                                                        <input-readonly-with-copy
                                                                                            :value="user.sip_details.username"></input-readonly-with-copy>
                                                                                    </el-form-item>
                                                                                    <el-form-item>
                                                                                        <span class="page-anchor"
                                                                                              id="sip_password">
                                                                                        </span>
                                                                                        <div class="form-label">
                                                                                            <h5>SIP Password</h5>
                                                                                            <small>
                                                                                                Password for this SIP
                                                                                                device.
                                                                                            </small>
                                                                                        </div>
                                                                                        <input-readonly-with-copy
                                                                                            :value="user.sip_details.password"></input-readonly-with-copy>
                                                                                    </el-form-item>
                                                                                    <el-form-item class="no-border">
                                                                                        <span class="page-anchor"
                                                                                              id="sip_alias">
                                                                                        </span>
                                                                                        <div class="form-label">
                                                                                            <h5>SIP Alias</h5>
                                                                                            <small>
                                                                                                An internal label for this
                                                                                                device. Might be displayed
                                                                                                on the physical phone.
                                                                                            </small>
                                                                                        </div>
                                                                                        <input-readonly-with-copy
                                                                                            :value="user.sip_details.alias"></input-readonly-with-copy>
                                                                                    </el-form-item>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <div class="col-3 d-none d-md-block ml-auto">
                                                        <div class="list-group list-group-alo list-group-flush sticky-top stick-top-with-header">
                                                            <h6 class="list-group-item">
                                                                On This Page
                                                            </h6>
                                                            <a href="#ring_group_member"
                                                               class="list-group-item list-group-item-action"
                                                               data-testid="ring-groups-link"
                                                               @click="shine('ring_group_member')">
                                                                Ring Groups
                                                            </a>
                                                            <template v-if="!statics.whitelabel && isUsingSip">
                                                                <a href="#sip_uri"
                                                                   class="list-group-item list-group-item-action"
                                                                   data-testid="sip-uri-link"
                                                                   @click="shine('sip_uri')">
                                                                    SIP URI
                                                                </a>
                                                                <a href="#sip_domain"
                                                                   class="list-group-item list-group-item-action"
                                                                   data-testid="sip-domain-link"
                                                                   @click="shine('sip_domain')">
                                                                    SIP Domain
                                                                </a>
                                                                <a href="#sip_username"
                                                                   class="list-group-item list-group-item-action"
                                                                   data-testid="sip-username-link"
                                                                   @click="shine('sip_username')">
                                                                    SIP Username
                                                                </a>
                                                                <a href="#sip_password"
                                                                   class="list-group-item list-group-item-action"
                                                                   data-testid="sip-password-link"
                                                                   @click="shine('sip_password')">
                                                                    SIP Password
                                                                </a>
                                                                <a href="#sip_alias"
                                                                   class="list-group-item list-group-item-action"
                                                                   data-testid="sip-alias-link"
                                                                   @click="shine('sip_alias')">
                                                                    SIP Alias
                                                                </a>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>

                                            </el-tab-pane>
                                            <el-tab-pane name="teams"
                                                         data-testid="teams-pane"
                                                         class="p-2"
                                                         label="Teams">
                                                <!-- Teams -->
                                                <teams-tab :user="user"/>
                                            </el-tab-pane>
                                            <el-tab-pane v-if="allowUserSmsTemplate"
                                                         data-testid="sms-template-pane"
                                                         label="SMS Templates"
                                                         name="sms-templates">
                                                <sms-template-list class="pl-3"
                                                                   :user_id="parseInt(user_id)"/>
                                            </el-tab-pane>
                                            <el-tab-pane name="user-diagnosis"
                                                         data-testid="user-diagnosis-pane"
                                                         class="pt-2"
                                                         label="Diagnosis"
                                                         v-if="user_id && userDestinationEditable && !auth.user.profile.is_reseller">
                                                <user-diagnosis :user="user"
                                                                data-testid="user-diagnosis"
                                                                ref="user_diagnosis">
                                                </user-diagnosis>
                                            </el-tab-pane>
                                        </el-tabs>
                                    </template>
                                </el-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <form-height-indicator/>
        </div>

        <div class="fixed-footer padding pb-0">
            <div class="form-group row mb-0 mt-0">
                <div class="col-12 d-none d-md-block p-2 edit-operations">
                    <el-button class="pull-left"
                               v-if="canGoBack"
                               data-testid="back-button"
                               @click.prevent="goBack">
                        <i class="fa fa-arrow-left"></i>
                        Back
                    </el-button>
                    <el-button class="pull-right ml-0"
                               type="success"
                               slot="reference"
                               :disabled="loading_btn || !validated"
                               data-testid="save-add-button"
                               @click.prevent="submitForm('user_form')">
                        <i class="material-icons loader pull-left"
                           v-show="loading_btn">&#xE863;</i>
                        {{ user_id ? 'Save' : 'Add' }}
                    </el-button>

                    <button class="btn el-btn pull-right mr-2"
                            :class="user.active ? 'deep-orange-500' : 'light-blue-500'"
                            data-testid="active-button"
                            @click="toggleActive"
                            v-if="user_id && hasPermissionTo('update user') && !auth.user.profile.is_reseller"
                            :disabled="loading_active">
                        <i class="fa fa-spin fa-spinner"
                           v-if="loading_active"></i>
                        <template v-else>
                            <i class="fa fa-pause"
                               v-if="user.active"></i>
                            <i class="fa fa-play"
                               v-else></i>
                        </template>
                        {{ user.active ? 'Pause' : 'Activate' }}
                    </button>
                    <button class="btn el-btn purple-500 pull-right mr-2"
                            data-testid="test-call-button"
                            @click="testRouting"
                            v-if="user_id && user.answer_by != AnswerTypes.BY_NONE"
                            :disabled="loading_test_call_route">
                        <i class="fa fa-spin fa-spinner"
                           v-if="loading_test_call_route"></i>
                        Test Call
                    </button>
                    <button class="btn el-btn pull-right danger mr-2"
                            v-if="user_id && hasPermissionTo('delete user')"
                            :disabled="loading_delete"
                            data-testid="delete-button"
                            @click="deleteUserRemote">
                        <i class="material-icons loader pull-left"
                           v-show="loading_delete">&#xE863;</i>
                        <i class="fa fa-trash"
                           v-show="!loading_delete"></i>
                        Delete
                    </button>
                </div>
                <div class="col-12 d-sm-block d-md-none p-2 edit-operations">
                    <el-button class="pull-left"
                               size="mini"
                               v-if="canGoBack"
                               data-testid="can-go-back-button"
                               @click.prevent="goBack">
                        <i class="fa fa-arrow-left"></i>
                        Back
                    </el-button>
                    <el-button class="pull-right"
                               size="mini"
                               :disabled="loading_btn || !validated"
                               data-testid="save-add-mini-button"
                               @click.prevent="submitForm('user_form')">
                        <i class="material-icons loader pull-left"
                           v-show="loading_btn">&#xE863;</i>
                        {{ user_id ? 'Save' : 'Add' }}
                    </el-button>
                    <el-popover placement="top"
                                width="100%"
                                trigger="click">
                        <el-button size="mini"
                                   :type="user.active ? 'deep-orange-500' : 'light-blue-500'"
                                   class="pull-right ml-2"
                                   v-if="user_id && hasPermissionTo('update user')"
                                   :disabled="loading_active"
                                   data-testid="active-mini-button"
                                   @click="toggleActive">
                            <i class="material-icons loader pull-left"
                               v-if="loading_active">&#xE863;</i>
                            <template v-else>
                                <i class="material-icons pull-left"
                                   v-if="user.active">pause</i>
                                <i class="material-icons pull-left"
                                   v-else>autorenew</i>
                            </template>
                            {{ user.active == true ? 'Pause' : 'Activate' }}
                        </el-button>
                        <el-button type="danger"
                                   size="mini"
                                   class="pull-right ml-0"
                                   v-if="user_id && hasPermissionTo('delete user')"
                                   :disabled="loading_delete"
                                   @click="deleteUserRemote">
                            <i class="material-icons loader pull-left"
                               v-show="loading_delete">&#xE863;</i>
                            <i class="fa fa-trash"
                               v-show="!loading_delete"></i>
                            Delete
                        </el-button>
                        <el-button slot="reference"
                                   size="mini"
                                   type="primary"
                                   class="pull-right"
                                   icon="el-icon-more-outline"
                                   plain>
                        </el-button>
                    </el-popover>
                </div>
            </div>
        </div>

        <!--help video-->
        <el-dialog title="Roles"
                   :width="isLargeEnough ? '700px' : '100%'"
                   :visible.sync="show_help"
                   v-if="show_help"
                   center>
            <iframe width="100%"
                    height="500px"
                    src="https://www.youtube.com/embed/7Qzgk_gh-Ho?autoplay=1&enablejsapi=1&fs=1&iv_load_policy=1&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://app.aloware.com"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
            </iframe>
        </el-dialog>
    </div>
</template>

<style scoped>
.el-date-editor .el-range-separator {
    width: 10%;
}
</style>

<script>
import auth from '../../auth'
import {mapActions, mapState} from 'vuex'
import {
    acl_mixin,
    chargebee_mixin,
    date_mixin,
    form_handler,
    form_validation_mixin,
    helper_mixin,
    kyc_mixin,
    scroll_mixin,
    styling_mixin,
    tab_section_mixin,
    user_info_mixin,
    user_mixin,
    users_mixin,
    validator_mixin
} from '../../mixins'
import Alert from '../../components/alert/index.vue'
import VariableDialog from '../../components/variable-dialog'
import TemplateListDialog from '../../components/template-list-dialog'
import UserVmDrop from '../../components/user-vm-drop'
import UserDiagnosis from '../../components/users/user-diagnosis'
import InputReadonlyWithCopy from '../../components/input-readonly-with-copy'
import FormHeightIndicator from "../../components/form-height-indicator"
import UsAreaCodeSelector from "../../components/us-area-code-selector"
import SmsTemplateList from "../../components/sms-templates/sms-template-list"
import TeamsTab from "../../components/users/teams-tab"
import UserExtensionSelect from '../../components/user-extension-select'
import * as AnswerTypes from '../../constants/answer-types'
import * as UserOutboundCallingModes from '../../constants/user-outbound-calling-modes'
import * as InboundCallRecordingModes from '../../constants/inbound-call-recording-modes'
import * as OutboundCallRecordingModes from '../../constants/outbound-call-recording-modes'
import * as MissedCallBehaviors from '../../constants/missed-call-behavior'
import * as ContactAccessTypes from '../../constants/contact-access-types'
import * as CommunicationAccessTypes from '../../constants/communication-access-types'
import * as Roles from '../../constants/roles'
import _ from 'lodash'

export default {
    mixins: [
        styling_mixin,
        validator_mixin,
        date_mixin,
        chargebee_mixin,
        scroll_mixin,
        form_handler,
        acl_mixin,
        tab_section_mixin,
        user_mixin,
        users_mixin,
        user_info_mixin,
        form_validation_mixin,
        helper_mixin,
        kyc_mixin
    ],

    components: {
        'al-alert': Alert,
        UsAreaCodeSelector,
        FormHeightIndicator,
        InputReadonlyWithCopy,
        UserDiagnosis,
        UserVmDrop,
        TemplateListDialog,
        VariableDialog,
        SmsTemplateList,
        TeamsTab,
        UserExtensionSelect
    },

    props: {
        user_id: {
            type: [Number, String],
            required: false,
            validator(value) {
                // check if passed value is a number, when it exists
                return value ? parseInt(value) !== NaN : true
            }
        }
    },

    data() {
        return {
            auth: auth,
            should_observe: false,
            env: null,
            loading: false,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: false,
            loading_delete: false,
            loading_active: false,
            loading_btn: false,
            loading_operating_hours: false,
            loading_delete_missed_call_voicemail: false,
            loading_test_call_route: false,
            loading_clear_new_leads: false,
            active_tab_name: 'profile-settings',
            user_name: null,
            user: null,
            roles: [],
            permissions: [],
            checkAllUS: false,
            checkAllCA: false,
            isUSIndeterminate: true,
            isCAIndeterminate: true,
            my_calls: false,
            my_texts: false,
            my_voicemails: false,
            my_contacts: false,
            my_appointments: false,
            my_reminders: false,
            my_faxes: false,
            my_mentions: false,
            high_sms_volume_notifs: false,
            inapp_notifs: false,
            email_notifs: false,
            desktop_notifs: false,
            mobile_notifs: false,
            text_notifs: false,
            set_custom_number: false,
            show_help: false,
            mark_as_billing_admin: false,
            wrap_up_seconds: [
                {
                    label: 'No Wrap up',
                    value: -1
                },
                {
                    label: '10 seconds',
                    value: 10
                },
                {
                    label: '15 seconds',
                    value: 15
                },
                {
                    label: '30 seconds',
                    value: 30
                },
                {
                    label: '45 seconds',
                    value: 45
                },
                {
                    label: '60 seconds',
                    value: 60
                },
                {
                    label: '120 seconds',
                    value: 120
                },
                {
                    label: '180 seconds',
                    value: 180
                },
                {
                    label: '300 seconds',
                    value: 300
                },
                {
                    label: 'Indefinitely',
                    value: 0
                }
            ],
            qualification_seconds: [
                {
                    label: '30 seconds',
                    value: 30
                },
                {
                    label: '40 seconds',
                    value: 40
                },
                {
                    label: '50 seconds',
                    value: 50
                },
                {
                    label: '60 seconds',
                    value: 60
                },
                {
                    label: '70 seconds',
                    value: 70
                },
                {
                    label: '80 seconds',
                    value: 80
                },
                {
                    label: '90 seconds',
                    value: 90
                },
                {
                    label: '120 seconds',
                    value: 120
                },
                {
                    label: '180 seconds',
                    value: 180
                },
                {
                    label: '300 seconds',
                    value: 300
                }
            ],
            reminders_options: [
                {
                    value: 'now',
                    title: 'It is created'
                },
                {
                    value: 'one_minute_before',
                    title: 'One minute before'
                },
                {
                    value: 'fifteen_minutes_before',
                    title: 'Fifteen minutes before'
                },
                {
                    value: 'one_hour_before',
                    title: 'One hour before'
                },
                {
                    value: 'one_day_before',
                    title: 'One day before'
                }
            ],
            timepicker_options: {
                format: 'HH:mm',
            },
            states: {
                us: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
                ca: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT']
            },
            disable_geo_routing: true,
            disable_area_code_routing: true,
            schedule_cb: null,
            duplicateCallPreventionOptions: [
                {
                    label: 'Forever',
                    value: 'forever'
                },
                {
                    label: '1 day',
                    value: '-1 day'
                },
                {
                    label: '2 days',
                    value: '-2 days'
                },
                {
                    label: '1 week',
                    value: '-1 week'
                },
                {
                    label: '1 month',
                    value: '-1 month'
                },
                {
                    label: '3 months',
                    value: '-3 months'
                }
            ],
            dontDuplicateCallPrevention: false,
            timeIncrement: 30,
            rules: {
                first_name: [
                    {
                        validator: this.firstNameRequiredValidator,
                        trigger: 'blur'
                    }
                ],
                last_name: [
                    {
                        validator: this.lastNameRequiredValidator,
                        trigger: 'blur'
                    }
                ],
                phone_number: [
                    {
                        validator: this.phoneValidator,
                        trigger: 'blur'
                    },
                ],
                secondary_phone_number: [
                    {
                        validator: this.secondPhoneValidator,
                        trigger: 'blur'
                    },
                ],
                notification_phone_number: [
                    {
                        validator: this.customPhoneNumberValidator,
                        trigger: 'blur'
                    },
                ],
                email: [
                    {
                        required: true,
                        message: 'Please provide an email address',
                        trigger: 'blur'
                    },
                    {
                        type: 'email',
                        message: 'Please provide a correct email address',
                        trigger: 'blur'
                    }
                ],
                role_name: [
                    {
                        required: true,
                        message: 'Please select a role for user',
                        trigger: 'blur'
                    }
                ],
                answer_by: [
                    {
                        validator: this.answerByValidator,
                        trigger: 'blur'
                    }
                ],
                default_outbound_campaign_id: [
                    {
                        validator: this.userOutboundCampaignValidator
                    }
                ],
                daily_call_limit: [
                    {
                        required: true,
                        message: 'Please provide a daily call limit',
                        trigger: 'blur'
                    }
                ],
                missed_call_message: [
                    {
                        required: () => this.user.should_message_if_missed,
                        message: 'Please provide a missed call message',
                        trigger: 'blur'
                    }
                ],
                completed_call_message_caller: [
                    {
                        required: () => this.user.should_message_caller_if_completed,
                        message: 'Please provide a completed call message to caller',
                        trigger: 'blur'
                    }
                ],
                missed_call_handling_mode: [
                    {
                        validator: this.validateMissedCallVoicemail
                    }
                ],
                contacts_visibility: [
                    {
                        required: true,
                        message: 'Please select the contact visibility settings for the user',
                        trigger: 'change',
                    },
                ],
                communications_visibility: [
                    {
                        required: true,
                        message: 'Please select the communication visibility settings for the user',
                        trigger: 'change',
                    },
                ],
                can_view_unassigned_contacts: [
                    {
                        type: "boolean",
                        message: 'Please check the box',
                        trigger: 'change',
                    },
                ],
                can_change_contact_ownership: [
                    {
                        type: "boolean",
                        message: 'Please check the box',
                        trigger: 'change',
                    },
                ],
                can_modify_contact_ring_groups: [
                    {
                        type: "boolean",
                        message: 'Please check the box',
                        trigger: 'change',
                    },
                ],
                can_barge_and_whisper_on_call: [
                    {
                        type: "boolean",
                        message: 'Please check the box',
                        trigger: 'change',
                    },
                ],
                line_access_limit: [
                    {
                        validator: this.lineAccessLimitValidator
                    }
                ],
                user_access_limit: [
                    {
                        validator: this.userAccessLimitValidator
                    }
                ],
                campaign_id: [
                    {
                        required: false,
                    },
                ]
            },
            default_user: {
                first_name: null,
                last_name: null,
                description: null,
                email: null,
                phone_number: null,
                secondary_phone_number: null,
                notification_phone_number: null,
                enabled_two_legged_outbound: false,
                wrap_up_seconds: 60,
                role_name: null,
                role_names: [],
                answer_by: 3,
                selected_campaign_ids: [],
                selected_user_ids: [],
                reminders_options: [
                    'now',
                    'one_minute_before',
                    'fifteen_minutes_before',
                    'one_hour_before',
                    'one_day_before'
                ],
                outbound_calling_selector: 2,
                outbound_calling_mode: UserOutboundCallingModes.OUTBOUND_CALLING_MODE_DEFAULT,
                outbound_call_recording_mode: OutboundCallRecordingModes.OUTBOUND_CALL_RECORDING_MODE_DEFAULT,
                inbound_call_recording_mode: InboundCallRecordingModes.INBOUND_CALL_RECORDING_MODE_DEFAULT,
                default_outbound_campaign_id: null,
                missed_calls_settings: {},
                prerecorded_voicemail_file: null,
                answers_messages: false,
                is_destination: false,
                can_view_unassigned_contacts: false,
                can_change_contact_ownership: false,
                can_modify_contact_ring_groups: false,
                can_barge_and_whisper_on_call: false,
                contacts_visibility: ContactAccessTypes.CONTACTS_ACCESS_OWNED_ONLY,
                communications_visibility: CommunicationAccessTypes.COMMUNICATIONS_ACCESS_ALL,
                line_access_limit: false,
                read_only_access: false,
                user_access_limit: false,
                has_broadcast_access: false
            },
            answer_types: [
                {
                    id: AnswerTypes.BY_BROWSER,
                    description: 'Using Apps',
                    help_text: 'This user answers calls using the web browser, desktop or mobile apps. This is the default behavior.'
                },
                {
                    id: AnswerTypes.BY_PHONE_NUMBER,
                    description: 'Using phone number',
                    help_text: 'Forward all calls to this user to the provided phone number (usually a cell phone or off-site landline). Mostly used for call tracking.'
                },
                {
                    id: AnswerTypes.BY_NONE,
                    description: 'Will not answer',
                    help_text: 'This user does not answer the phone at all. Calls can\'t be routed to this person. Suitable for managers, controllers, etc.'
                },
            ],
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + auth.user.profile.api_token,
                'X-Socket-Id': window.Echo.socketId()
            },
            uploadPercentage: {
                missed_call_voicemail: 0,
            },
            uploadStatus: {
                missed_call_voicemail: 'success'
            },
            uploadFileList: {
                missed_call_voicemail: [],
            },
            audio_file_messages: {
                voicemail_file: 'Missed call voicemail uploaded successfully.'
            },
            contactVisibilityOptions: [{
                value: ContactAccessTypes.CONTACTS_ACCESS_EVERYONE,
                label: 'Everything',
                description: 'Can see all contacts.'
            }, {
                value: ContactAccessTypes.CONTACTS_ACCESS_TEAM,
                label: 'Team Only (Beta)',
                description: "Can only see contacts owned by users in any ring groups that this user belongs to."
            }, {
                value: ContactAccessTypes.CONTACTS_ACCESS_RING_GROUP,
                label: 'Ring Group Only',
                description: "Can only see owned contacts and contacts that have interacted with the ring groups that this user belongs to."
            }, {
                value: ContactAccessTypes.CONTACTS_ACCESS_OWNED_ONLY,
                label: 'Owned Only',
                description: 'Can only see their own contacts.'
            }],
            communicationVisibilityOptions: [{
                value: CommunicationAccessTypes.COMMUNICATIONS_ACCESS_ALL,
                label: 'Everything',
                description: 'Can see all communications from the contacts that they have access to.'
            }, {
                value: CommunicationAccessTypes.COMMUNICATIONS_OWNED_ONLY,
                label: 'Owned Only',
                description: 'Can only see their own outbound communications and all inbound communications from the contacts that they have access to.'
            }],
            isCommunicationNotificationsDisabledTooltipText: 'To enable Communication Notifications, you should enable at least one of the Notification Channels: In-App, Desktop, Mobile, Email, or Text Notifications.',
            options: [],
            UserOutboundCallingModes,
            InboundCallRecordingModes,
            OutboundCallRecordingModes,
            MissedCallBehaviors,
            ContactAccessTypes,
            CommunicationAccessTypes,
            AnswerTypes,
            Roles,
        }
    },

    computed: {
        ...mapState(['campaigns', 'ring_groups', 'usage']),
        ...mapState('cache', ['current_company']),

        notificationMessage() {
            let endOfBillingTerm = ''
            if (this.current_company.end_of_billing_term) {
                endOfBillingTerm = (new Date(this.current_company.end_of_billing_term)).toLocaleDateString()
            }
            return `You are purchasing a new license for this user, which will be valid until the end of your billing period ${endOfBillingTerm}`
        },

        connectedCampaigns() {
            return this.campaigns.filter((campaign) => {
                return campaign.user_id === this.user.id
            })
        },

        canBeEdited() {
            return this.user.role_name && !this.user.read_only_access && !this.user.is_destination
        },

        userDestinationEditable() {
            return this.user.role_name && !this.user.read_only_access
        },

        getTitle() {
            if (this.user_id) {
                return 'User Settings'
            }

            return 'Add User'
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        campaignsData() {
            const data = []
            for (let item of this.campaigns) {
                data.push({
                    key: item.id,
                    label: `${item.name}`,
                    disabled: false
                })
            }
            return data
        },

        usersData() {
            const data = []
            for (let item of this.users) {
                data.push({
                    key: item.id,
                    label: `${item.name}`,
                    disabled: false
                })
            }
            return data
        },

        isUserRoleExtension() {
            return this.user && this.user.is_destination
        },

        allowUserSmsTemplate() {
            // we don't show the sms template tab in add user page
            if (!this.user_id) {
                return false
            }

            // we don't allow end clients, reps, reporters, and extensions
            // see the sms template tab
            if (this.user &&
                (this.hasRole(Roles.COMPANY_REPORTER_ACCESS, this.user.role_names) ||
                    this.isUserRoleExtension)) {
                return false
            }

            const user_id = _.get(this.auth, 'user.profile.id', null)
            return this.hasPermissionTo('list sms template') &&
                ((this.hasRole(Roles.COMPANY_AGENT) && user_id && user_id === parseInt(this.user_id)) ||
                    this.hasRole(Roles.COMPANY_ADMIN) || this.hasRole(Roles.BILLING_ADMIN))
        },

        isNotOwnSettings() {
            if (this.auth && (!this.auth.hasOwnProperty('user') || !this.auth.user.hasOwnProperty('profile'))) {
                return false
            }

            return this.hasRole(Roles.COMPANY_ADMIN) && +this.user.id !== +this.auth.user.profile.id
        },

        userHasAdminRole() {
            if (this.user) {
                return this.userIsCompanyAdmin ||
                    this.userIsBillingAdmin
            }
        },

        userIsCompanyAdmin() {
            return this.inArray('Company Admin', this.user.role_names)
        },

        userIsBillingAdmin() {
            return this.inArray('Billing Admin', this.user.role_names)
        },

        isCompanyForcedAvailable() {
            return this.current_company?.force_users_always_available
        },

        isUsingSip() {
            let company_ids = localStorage.getItem('aloware_demo_companies').split(',').map(Number)
            return company_ids.concat([11, 98, 135, 206, 370, 1004, 1038, 1039, 1040, 1721]).includes(this.current_company?.id)
        },

        disableFormField() {
            return this.isEdittingCurrentUser
        },

        isEdittingCurrentUser() {
            return this.user_id === this.auth.user.profile.id
        },

        usersLength() {
            return this.usersData?.length || 0
        },

        canEditPersonalLine() {
            return this.isSupervisor
                ? this.auth?.user?.profile?.id == this.user_id
                : this.isAdmin
        },

        isCommunicationNotificationsDisabled() {
            return !this.inapp_notifs && !this.desktop_notifs && !this.mobile_notifs && !this.email_notifs && !this.text_notifs
        }
    },

    created() {
        this.getStatics()

        // required api resources
        if (this.hasPermissionTo('update user') || (this.hasPermissionTo('update profile') && this.auth.user.profile.id == this.user_id)) {
            this.getRolesAndPermissions()
            this.getUser()
        }
    },

    mounted() {
        if (!this.user_id && !this.hasPermissionTo('create user')) {
            this.goBack()
        }

        if (!this.hasPermissionTo('update user') && this.hasPermissionTo('update profile') && this.auth.user.profile.id != this.user_id) {
            this.goBack()
        }

        if (!this.user_id && this.usage && this.usage.user.included != 0 && this.usage.user.used >= this.usage.user.included) {
            this.goBack()
        }

        if (!this.isUsingSip) {
            // remove id 2 of answer types
            this.answer_types = this.answer_types.filter((item) => item.id !== AnswerTypes.BY_IP_PHONE)
        }

        let tab = this.$route.query.tab
        if (tab) {
            this.active_tab_name = tab
        }

        this.filterExtensions('')
    },

    methods: {
        roleChange(role) {
            if (['Company Admin', 'Company Agent', 'Company Supervisor'].includes(role)) {
                this.user.contacts_visibility = ContactAccessTypes.CONTACTS_ACCESS_EVERYONE
                this.user.communications_visibility = CommunicationAccessTypes.COMMUNICATIONS_ACCESS_ALL
                this.user.can_view_unassigned_contacts = true
                this.user.can_change_contact_ownership = true
                this.user.can_modify_contact_ring_groups = true
                this.user.can_barge_and_whisper_on_call = true

                this.user.line_access_limit = false
                this.user.user_access_limit = false
                this.user.read_only_access = false
            }
        },

        changeObservingCampaigns(val) {
            this.user.observing_campaigns = val
            this.preValidateForm('user_form')
        },

        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.setPageTitle(this.getTitle + ' - ' + this.statics.name)
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    this.setPageTitle(this.getTitle + ' - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        },

        getUser() {
            if (this.user_id) {
                this.loading = true
                axios.get('/api/v1/user/' + this.user_id, {
                    mode: 'no-cors',
                }).then(res => {
                    // For Secondary User Checks
                    if (!this.allowUserUpdate(res.data)) {
                        this.goBack()
                    }

                    this.setUpUser(res.data)
                    this.loading = false
                }).catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading = false
                })
            } else {
                if (this.auth.user.profile.is_reseller) {
                    this.goBack()
                } else {
                    this.setUpUser(this.default_user, false)
                }
            }
        },

        fixOperatingHours() {
            this.loading_operating_hours = true
            // fixes null issue
            for (let day of ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']) {
                for (let item in this.user.operating_hours[day]) {
                    if (!this.user.operating_hours[day][item].open) {
                        this.user.operating_hours[day][item].open = ''
                    }

                    if (!this.user.operating_hours[day][item].close) {
                        this.user.operating_hours[day][item].close = ''
                    }

                    if (this.user.operating_hours[day][item].isOpen && this.user.operating_hours[day][item].open === '') {
                        this.user.operating_hours[day][item].open = '24hrs'
                    }

                    if (this.user.operating_hours[day][item].isOpen && this.user.operating_hours[day][item].close === '') {
                        this.user.operating_hours[day][item].close = '24hrs'
                    }

                    if (this.user.operating_hours[day][item].isOpen && this.user.operating_hours[day][item].open !== '24hrs' && this.user.operating_hours[day][item].close === '24hrs') {
                        this.user.operating_hours[day][item].open = '24hrs'
                    }
                }
            }

            // fixes the order of weekdays
            this.user.operating_hours = {
                monday: this.user.operating_hours.monday,
                tuesday: this.user.operating_hours.tuesday,
                wednesday: this.user.operating_hours.wednesday,
                thursday: this.user.operating_hours.thursday,
                friday: this.user.operating_hours.friday,
                saturday: this.user.operating_hours.saturday,
                sunday: this.user.operating_hours.sunday,
            }

            this.loading_operating_hours = false
        },

        setUpUser(data) {
            this.user = data

            if (data.notification_phone_number) {
                this.set_custom_number = true
            }

            if (typeof this.user.operating_hours === 'string') {
                this.user.operating_hours = JSON.parse(this.user.operating_hours)
                this.fixOperatingHours()
            }

            if (typeof this.user.operating_states_limit === 'string') {
                this.user.operating_states_limit = JSON.parse(this.user.operating_states_limit)
            }

            if (typeof this.user.operating_area_codes_limit === 'string') {
                this.user.operating_area_codes_limit = JSON.parse(this.user.operating_area_codes_limit)
            }

            if (typeof this.user.sip_details === 'string') {
                this.user.sip_details = JSON.parse(this.user.sip_details)
            }

            if (typeof this.user.reminders_options === 'string') {
                this.user.reminders_options = JSON.parse(this.user.reminders_options)
            }

            if (this.user.reminders_options === null) {
                this.user.reminders_options = [
                    'now',
                    'one_minute_before',
                    'fifteen_minutes_before',
                    'one_hour_before',
                    'one_day_before'
                ]
            }

            if (!this.user.operating_states_limit || this.user.operating_states_limit.length == 0) {
                this.user.operating_states_limit = {
                    us: [],
                    ca: [],
                }
            }

            if (!this.user.operating_area_codes_limit || this.user.operating_area_codes_limit.length == 0) {
                this.user.operating_area_codes_limit = []
            }

            if (typeof this.user.observing_campaigns === 'string') {
                this.user.observing_campaigns = JSON.parse(this.user.observing_campaigns)
            }

            if (!this.user.observing_campaigns) {
                this.user.observing_campaigns = []
            }

            this.user.role_name = data.role_name
            this.user.answer_by = data.answer_by
            this.user.daily_call_limit = data.daily_call_limit
            this.user.phone_number = data.phone_number

            if (this.user.phone_number && this.user.answer_by === AnswerTypes.BY_BROWSER) {
                this.user.phone_number_as_backup = true
            }

            this.user.secondary_phone_number = data.secondary_phone_number
            this.user.enabled_two_legged_outbound = data.enabled_two_legged_outbound
            this.user.sip_uri = data.sip_uri
            this.user.active = data.active
            this.user.duplicate_call_prevention_period = data.duplicate_call_prevention_period
            this.user.should_message_if_missed = !!data.should_message_if_missed // !! will make it boolean
            this.user.should_message_caller_if_completed = !!data.should_message_caller_if_completed
            this.user.missed_call_message = data.missed_call_message
            this.user.completed_call_message_caller = data.completed_call_message_caller

            // set indeterminate checkboxes state
            this.handleCheckedUSChange(this.user.operating_states_limit.us)
            this.handleCheckedCAChange(this.user.operating_states_limit.ca)

            if (this.user.operating_states_limit.us.length || this.user.operating_states_limit.ca.length) {
                this.disable_geo_routing = false
            }

            if (this.user.operating_area_codes_limit.length) {
                this.disable_area_code_routing = false
            }

            if (this.user.observing_campaigns.length) {
                this.should_observe = true
            } else {
                this.should_observe = false
            }

            // check for dontDuplicateCallPrevention
            if (!this.user.duplicate_call_prevention_period) {
                this.dontDuplicateCallPrevention = true
            }

            if (data.outbound_calling_mode == UserOutboundCallingModes.OUTBOUND_CALLING_MODE_DEFAULT && data.default_outbound_campaign_id) {
                // Set Manually
                this.$set(this.user, 'outbound_calling_selector', 1)
                this.user.default_outbound_campaign_id = data.default_outbound_campaign_id
            } else if (data.outbound_calling_mode == UserOutboundCallingModes.OUTBOUND_CALLING_MODE_ALWAYS_ASK) {
                // Always Ask
                this.$set(this.user, 'outbound_calling_selector', 3)
            } else {
                // Use Company Default
                this.$set(this.user, 'outbound_calling_selector', 2)
            }

            // default
            this.$set(this.user, 'selected_campaign_ids', [])
            this.$set(this.user, 'selected_user_ids', [])

            if (this.user.line_access_limit && this.user.accessible_campaigns && this.user.accessible_campaigns.length > 0) {
                this.$set(this.user, 'selected_campaign_ids', this.user.accessible_campaigns)
            }

            if (this.user.user_access_limit && this.user.accessible_users && this.user.accessible_users.length > 0) {
                this.$set(this.user, 'selected_user_ids', this.user.accessible_users)
            }

            let user_name = this.getUserName(this.user)

            if (user_name) {
                VueEvent.fire('page_title_updated', user_name)
            }

            this.my_calls = this.user.calls_inapp_notifs || this.user.calls_desktop_notifs || this.user.calls_push_notifs || this.user.calls_email_notifs || this.user.calls_text_notifs
            this.my_texts = this.user.texts_inapp_notifs || this.user.texts_desktop_notifs || this.user.texts_push_notifs || this.user.texts_email_notifs || this.user.texts_text_notifs
            this.my_voicemails = this.user.voicemails_inapp_notifs || this.user.voicemails_desktop_notifs || this.user.voicemails_push_notifs || this.user.voicemails_email_notifs || this.user.voicemails_text_notifs
            this.my_contacts = this.user.contacts_inapp_notifs || this.user.contacts_desktop_notifs || this.user.contacts_push_notifs || this.user.contacts_email_notifs || this.user.contacts_text_notifs
            this.my_appointments = this.user.appointments_inapp_notifs || this.user.appointments_desktop_notifs || this.user.appointments_push_notifs || this.user.appointments_email_notifs
            this.my_reminders = this.user.reminders_inapp_notifs || this.user.reminders_desktop_notifs || this.user.reminders_push_notifs || this.user.reminders_email_notifs
            this.my_faxes = this.user.faxes_inapp_notifs || this.user.faxes_desktop_notifs || this.user.faxes_push_notifs || this.user.faxes_email_notifs || this.user.faxes_text_notifs
            this.my_mentions = this.user.mentions_inapp_notifs || this.user.mentions_desktop_notifs || this.user.mentions_push_notifs || this.user.mentions_email_notifs || this.user.mentions_text_notifs
            this.high_sms_volume_notifs = this.user.high_sms_volume_inapp_notifs || this.user.high_sms_volume_desktop_notifs || this.user.high_sms_volume_push_notifs || this.user.high_sms_volume_email_notifs || this.user.high_sms_volume_text_notifs

            this.inapp_notifs = this.user.calls_inapp_notifs || this.user.texts_inapp_notifs || this.user.voicemails_inapp_notifs || this.user.contacts_inapp_notifs || this.user.appointments_inapp_notifs || this.user.reminders_inapp_notifs || this.user.faxes_inapp_notifs || this.user.mentions_inapp_notifs || this.user.high_sms_volume_inapp_notifs
            this.desktop_notifs = this.user.calls_desktop_notifs || this.user.texts_desktop_notifs || this.user.voicemails_desktop_notifs || this.user.contacts_desktop_notifs || this.user.appointments_desktop_notifs || this.user.reminders_desktop_notifs || this.user.faxes_desktop_notifs || this.user.mentions_desktop_notifs || this.user.high_sms_volume_desktop_notifs
            this.mobile_notifs = this.user.calls_push_notifs || this.user.texts_push_notifs || this.user.voicemails_push_notifs || this.user.contacts_push_notifs || this.user.appointments_push_notifs || this.user.reminders_push_notifs || this.user.faxes_push_notifs || this.user.mentions_push_notifs || this.user.high_sms_volume_push_notifs
            this.email_notifs = this.user.calls_email_notifs || this.user.texts_email_notifs || this.user.voicemails_email_notifs || this.user.contacts_email_notifs || this.user.appointments_email_notifs || this.user.reminders_email_notifs || this.user.faxes_email_notifs || this.user.mentions_email_notifs || this.user.high_sms_volume_email_notifs
            this.text_notifs = this.user.calls_text_notifs || this.user.texts_text_notifs || this.user.voicemails_text_notifs || this.user.contacts_text_notifs || this.user.faxes_text_notifs || this.user.mentions_text_notifs || this.user.high_sms_volume_text_notifs

            // make user's ring group ids unique
            this.user.ring_group_ids = _.uniq(this.user.ring_group_ids)
            this.mark_as_billing_admin = this.hasRole('Billing Admin', this.user.role_names)

            // Set user line
            this.user.campaign_id = data.campaign_id

            // Set user broadcast access setting
            this.user.has_broadcast_access = data.has_broadcast_access

            // Set filtered extensions
            this.filterExtensions('')

            Vue.nextTick(() => {
                this.preValidateForm('user_form')
            })
        },

        submitForm(formName) {
            if (this.validateForm(formName) == true) {
                if (this.disable_geo_routing) {
                    //set indeterminate checkboxes state
                    this.handleCheckAllUSChange(0)
                    this.handleCheckAllCAChange(0)
                }

                if (this.user_id) {
                    this.saveUser()
                }

                if (!this.user_id) {
                    this.addUser()
                }
            } else {
                this.$notify({
                    offset: 95,
                    title: 'User',
                    dangerouslyUseHTMLString: true,
                    message: 'Please resolve user configuration errors',
                    type: 'error',
                    showClose: true,
                })
                return false
            }
        },

        addUser() {
            if (this.validateForm('user_form')) {
                this.loading_btn = true
                axios.post('/api/v1/user', {
                    ...this.user
                }).then(res => {
                    this.loading_btn = false
                    this.newUser(res.data.user)
                    this.$notify({
                        offset: 95,
                        title: 'User',
                        message: 'User created successfully.',
                        type: 'success'
                    })
                    this.$router.push({
                        name: 'User Dialog',
                        params: {
                            user_id: res.data.user.id
                        }
                    })

                    this.$refs.user_diagnosis.runDiagnosis()
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_btn = false
                })
            }
        },

        saveUser() {
            if (this.validateForm('user_form')) {
                this.loading_btn = true
                if (this.user.operating_hours) {
                    this.fixOperatingHours()
                }
                this.processNotificationSettings()
                const params = _.assign(this.user, {mark_as_billing_admin: this.mark_as_billing_admin})

                if (!this.text_notifs || !this.set_custom_number) {
                    params.notification_phone_number = null
                }

                axios.put('/api/v1/user/' + this.user.id, params).then(res => {
                    this.loading_btn = false
                    this.updateUser(res.data)
                    this.setUpUser(res.data)

                    // refresh screen
                    if (this.auth.user && this.auth.user.profile && this.user.id === this.auth.user.profile.id) {
                        // reload after two seconds
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000)

                        this.$notify({
                            offset: 95,
                            title: 'User',
                            message: 'Updated successfully. Refreshing in two seconds.',
                            type: 'success'
                        })
                    } else {
                        this.$notify({
                            offset: 95,
                            title: 'User',
                            message: 'User updated successfully.',
                            type: 'success'
                        })
                    }

                    this.$refs.user_diagnosis.runDiagnosis()
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_btn = false
                })
            }
        },

        deleteUserRemote() {
            this.$confirm('Deleting a user will remove it from all reports, plots and deletes all communication data. Furthermore, user will be detached from the lines associated with it. If you want to temporarily stop calls to a user, use the pause button. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_delete = true
                axios.delete('/api/v1/user/' + this.user.id)
                    .then(res => {
                        this.deleteUser(this.user)
                        this.loading_delete = false
                        this.$notify({
                            offset: 95,
                            title: 'User',
                            message: 'User has been deleted.',
                            type: 'success',
                            showClose: true
                        })
                        this.$router.push({
                            name: 'Users'
                        })
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_delete = false
                    })
            })
        },

        testRouting() {
            this.loading_test_call_route = true
            axios.post('/api/v1/user/' + this.user.id + '/test-routing').then(() => {
                this.$notify({
                    offset: 95,
                    title: 'User',
                    message: 'User should receive a call in a moment.',
                    type: 'success',
                    showClose: true,
                })
                setTimeout(() => {
                    this.loading_test_call_route = false
                }, 5000)
            }).catch((err) => {
                this.$root.handleErrors(err.response)
                setTimeout(() => {
                    this.loading_test_call_route = false
                }, 5000)
            })
        },

        toggleActive() {
            let new_state = this.user.active ? 'deactivate' : 'activate'
            this.$confirm('This will ' + new_state + ' the user. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_active = true
                axios.post('/api/v1/user/' + this.user.id + '/toggle-active')
                    .then(res => {
                        this.setUpUser(res.data)
                        this.updateUser(res.data)
                        this.loading_active = false
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_active = false
                    })
            }).catch(() => {

            })
        },

        handleTextMessageChange(val) {
            if (!val) {
                this.set_custom_number = false
                this.checkNotificationsSettings()
            }
        },

        handleCustomPhoneNumberOff(val) {
            // if we disable using a custom phone number, we should also clear the
            // custom phone number field
            if (!val) {
                this.$refs.user_form.clearValidate('notification_phone_number')
                this.preValidateForm('user_form')
            }
        },

        handleCheckAllUSChange(val) {
            this.user.operating_states_limit.us = val ? this.states.us : []
            this.isUSIndeterminate = false
        },

        handleCheckAllCAChange(val) {
            this.user.operating_states_limit.ca = val ? this.states.ca : []
            this.isCAIndeterminate = false
        },

        handleCheckedUSChange(value) {
            if (!value || !value.length) {
                return
            }
            let checkedCount = value.length
            this.checkAllUS = checkedCount === this.states.us.length
            this.isUSIndeterminate = checkedCount > 0 && checkedCount < this.states.us.length
        },

        handleCheckedCAChange(value) {
            if (!value || !value.length) {
                return
            }
            let checkedCount = value.length
            this.checkAllCA = checkedCount === this.states.ca.length
            this.isCAIndeterminate = checkedCount > 0 && checkedCount < this.states.ca.length
        },

        checkDuplicateCallPrevention(status) {
            if (status == true) {
                this.user.duplicate_call_prevention_period = null
            }
            this.preValidateForm('user_form')
        },

        checkMessageIfMissed(status) {
            if (!status) {
                this.user.missed_call_message = null
            }
            this.preValidateForm('user_form')
        },

        checkMessageCallerIfCompleted(status) {
            if (!status) {
                this.user.completed_call_message_caller = null
            }
            this.preValidateForm('user_form')
        },

        handleCommand(command) {
            if (command == 'view') {
                this.$router.push({name: 'User Activity', params: {user_id: this.user.id}})
            }
        },

        getRingGroup(id) {
            if (!id) {
                return null
            }
            let found = this.ring_groups.find(ring_group => ring_group.id === id)
            if (found) {
                return found
            }

            return null
        },

        clearSelectedCampaigns() {
            this.user.selected_campaign_ids = []
            this.preValidateForm('user_form')
        },

        clearSelectedUsers() {
            this.user.selected_user_ids = []
            this.preValidateForm('user_form')
        },

        getRolesAndPermissions() {
            this.loading = true
            axios.get('/api/v1/roles-permissions')
                .then(res => {
                    let roles = res.data.roles
                    this.permissions = res.data.permissions
                    this.loading = false
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false
                    this.$root.handleErrors(err.response)
                })
        },

        onSuccessUploadMissedCallVoicemail(res) {
            this.$notify({
                offset: 95,
                title: 'User',
                message: this.audio_file_messages.voicemail_file,
                type: 'success',
                showClose: true,
            })
            this.$set(this.user.missed_calls_settings, 'voicemail_file', res.file_name)
            this.uploadStatus.missed_call_voicemail = 'success'
            this.uploadPercentage.missed_call_voicemail = 0
            this.preValidateForm('user_form')
            this.submitForm('user_form')
        },

        onFailedUploadMissedCallVoicemail(err) {
            this.handleUploadErrors(err.message)
            this.uploadStatus.missed_call_voicemail = 'exception'
            this.uploadPercentage.missed_call_voicemail = 0
            this.preValidateForm('user_form')
        },

        beforeUploadMissedCallVoicemail() {
            this.uploadStatus.missed_call_voicemail = 'success'
            this.uploadPercentage.missed_call_voicemail = 0
        },

        progressUploadMissedCallVoicemail(event) {
            this.uploadPercentage.missed_call_voicemail = parseInt(event.percent)
        },

        deleteMissedCallVoicemailFile() {
            if (!this.user_id) {
                return
            }
            this.loading_delete_missed_call_voicemail = true
            axios.delete('/api/v1/user/' + this.user.id + '/missed-call-voicemail')
                .then(res => {
                    this.loading_delete_missed_call_voicemail = false
                    this.user.missed_calls_settings.voicemail_file = null
                    this.uploadPercentage.missed_call_voicemail = 0
                    this.uploadStatus.missed_call_voicemail = 'success'
                    this.uploadFileList.missed_call_voicemail = []
                    this.user.missed_calls_settings.missed_call_handling_mode = MissedCallBehaviors.MISSED_CALL_BEHAVIOR_NOTHING
                    this.submitForm('user_form')
                    this.$notify({
                        offset: 95,
                        title: 'User',
                        message: 'Missed call voicemail deleted successfully',
                        type: 'success',
                        showClose: true,
                    })
                })
                .catch(err => {
                    this.loading_delete_missed_call_voicemail = false
                    this.uploadPercentage.missed_call_voicemail = 0
                    this.$root.handleErrors(err.response)
                })
        },

        processNotificationSettings() {
            this.user.calls_inapp_notifs = this.inapp_notifs && this.my_calls
            this.user.texts_inapp_notifs = this.inapp_notifs && this.my_texts
            this.user.voicemails_inapp_notifs = this.inapp_notifs && this.my_voicemails
            this.user.contacts_inapp_notifs = this.inapp_notifs && this.my_contacts
            this.user.appointments_inapp_notifs = this.inapp_notifs && this.my_appointments
            this.user.reminders_inapp_notifs = this.inapp_notifs && this.my_reminders
            this.user.faxes_inapp_notifs = this.inapp_notifs && this.my_faxes
            this.user.mentions_inapp_notifs = this.inapp_notifs && this.my_mentions
            this.user.high_sms_volume_inapp_notifs = this.inapp_notifs && this.high_sms_volume_notifs

            this.user.calls_desktop_notifs = this.desktop_notifs && this.my_calls
            this.user.texts_desktop_notifs = this.desktop_notifs && this.my_texts
            this.user.voicemails_desktop_notifs = this.desktop_notifs && this.my_voicemails
            this.user.contacts_desktop_notifs = this.desktop_notifs && this.my_contacts
            this.user.appointments_desktop_notifs = this.desktop_notifs && this.my_appointments
            this.user.reminders_desktop_notifs = this.desktop_notifs && this.my_reminders
            this.user.faxes_desktop_notifs = this.desktop_notifs && this.my_faxes
            this.user.mentions_desktop_notifs = this.desktop_notifs && this.my_mentions
            this.user.high_sms_volume_desktop_notifs = this.desktop_notifs && this.high_sms_volume_notifs

            this.user.calls_push_notifs = this.mobile_notifs && this.my_calls
            this.user.texts_push_notifs = this.mobile_notifs && this.my_texts
            this.user.voicemails_push_notifs = this.mobile_notifs && this.my_voicemails
            this.user.contacts_push_notifs = this.mobile_notifs && this.my_contacts
            this.user.appointments_push_notifs = this.mobile_notifs && this.my_appointments
            this.user.reminders_push_notifs = this.mobile_notifs && this.my_reminders
            this.user.faxes_push_notifs = this.mobile_notifs && this.my_faxes
            this.user.mentions_push_notifs = this.mobile_notifs && this.my_mentions
            this.user.high_sms_volume_push_notifs = this.mobile_notifs && this.high_sms_volume_notifs

            this.user.calls_email_notifs = this.email_notifs && this.my_calls
            this.user.texts_email_notifs = this.email_notifs && this.my_texts
            this.user.voicemails_email_notifs = this.email_notifs && this.my_voicemails
            this.user.contacts_email_notifs = this.email_notifs && this.my_contacts
            this.user.appointments_email_notifs = this.email_notifs && this.my_appointments
            this.user.reminders_email_notifs = this.email_notifs && this.my_reminders
            this.user.faxes_email_notifs = this.email_notifs && this.my_faxes
            this.user.mentions_email_notifs = this.email_notifs && this.my_mentions
            this.user.high_sms_volume_email_notifs = this.email_notifs && this.high_sms_volume_notifs

            this.user.calls_text_notifs = this.text_notifs && this.my_calls
            this.user.texts_text_notifs = this.text_notifs && this.my_texts
            this.user.voicemails_text_notifs = this.text_notifs && this.my_voicemails
            this.user.contacts_text_notifs = this.text_notifs && this.my_contacts
            this.user.faxes_text_notifs = this.text_notifs && this.my_faxes
            this.user.mentions_text_notifs = this.text_notifs && this.my_mentions
            this.user.high_sms_volume_text_notifs = this.text_notifs && this.high_sms_volume_notifs
        },

        toggleHelp() {
            this.show_help = !this.show_help
        },

        handleUploadErrors(error) {
            if (typeof error === 'string') {
                error = JSON.parse(error)
            }
            let err
            if (error.message === 'This action is unauthorized.') {
                err = {
                    status: 403,
                }
            } else {
                err = {
                    status: 422,
                    data: {
                        errors: error.errors.file
                    }
                }
            }

            this.$root.handleErrors(err)
        },

        applyUploadedAudio(data) {
            this.$set(this.user.missed_calls_settings, data.upload_type, null)
            setTimeout(() => {
                this.$set(this.user.missed_calls_settings, data.upload_type, data.uid)
                this.$notify({
                    offset: 95,
                    title: 'User',
                    message: this.audio_file_messages[data.upload_type],
                    type: 'success',
                    showClose: true,
                })
                this.submitForm('user_form')
            }, 100)
        },

        tabClicked(tab) {
            this.$router.replace({
                name: 'User Dialog',
                params: {
                    user_id: this.user_id
                },
                query: {
                    tab: tab.name
                }
            }).catch(err => {

            })
        },

        checkUserPhoneNumber() {
            if (![AnswerTypes.BY_BROWSER, AnswerTypes.BY_IP_PHONE].includes(this.user.answer_by)) {
                this.user.phone_number_as_backup = false
            }

            if (!this.user.phone_number_as_backup) {
                this.user.phone_number = null
            }
        },

        changeUserSelectedAreaCodes(area_codes) {
            this.user.operating_area_codes_limit = area_codes
        },

        toggleLineAccessLimit() {
            // Reset visibility options
            if (!this.user.line_access_limit) {
                this.user.line_contact_limit = false
                this.user.line_communication_limit = false
            }

            this.preValidateForm('user_form')
        },

        allowUserRoleUpdate(user) {
            return !user.is_destination
                && (!this.user_id || this.auth.user.profile.id != this.user_id)
                && this.hasRole('Company Admin')
                && (
                    // User to be updated should not be a billing admin
                    !this.hasRole('Billing Admin', user.role_names)
                    // Unless authenticated user is also a billing admin
                    || this.hasRole('Billing Admin')
                )
        },

        changeDefaultOutboundCampaignId(campaign_id) {
            this.user.default_outbound_campaign_id = campaign_id
            this.preValidateForm('user_form')
        },

        updateCanDeleteContact(val) {
            this.user.can_delete_contact = val
        },

        resetCommunicationFlags() {
            this.my_calls = false
            this.my_texts = false
            this.my_voicemails = false
            this.my_faxes = false
            this.my_mentions = false
            this.my_contacts = false
            this.my_appointments = false
            this.my_reminders = false

            this.$notify({
                offset: 95,
                title: 'Important information',
                message: 'Please enable at least one Notification Channel to be able to enable Communication Notifications.',
                type: 'warning',
                showClose: true,
            })
        },

        checkNotificationsSettings() {
            if (this.isCommunicationNotificationsDisabled) {
                this.processNotificationSettings()
                this.resetCommunicationFlags()
            }
        },

        filterExtensions: _.debounce(function (query) {
            const MIN_EXTENSION = 100; // Minimum extension number
            const MAX_EXTENSION = 99999; // Maximum extension number
            const MAX_OPTIONS = 101; // Maximum number of options to generate
            const MAX_DISPLAY_OPTIONS = 100; // Maximum number of options to display
            const EXTENSION_RANGE = 99; // Range to subtract from user's extension
            const USER_EXTENSION_THRESHOLD = 201; // Threshold to adjust initial extension based on user's extension

            // Determine the initial extension based on the user's extension
            const extensionInitial = (this.user?.extension > USER_EXTENSION_THRESHOLD && !query)
                ? (this.user.extension - EXTENSION_RANGE)
                : MIN_EXTENSION;

            const extensionsInRange = [];

            if (!query) {
                // Generate up to MAX_OPTIONS extensions starting from extensionInitial
                for (let extension = extensionInitial; extension <= MAX_EXTENSION; extension++) {
                    const extensionStr = extension.toString();
                    extensionsInRange.push(extensionStr);
                    if (extensionsInRange.length >= MAX_OPTIONS) {
                        break;
                    }
                }

                this.options = extensionsInRange.slice(0, MAX_OPTIONS);
                return;
            }

            // Parse the query to an integer
            let minExtension = parseInt(query, 10);
            if (isNaN(minExtension)) {
                this.options = [];
                return;
            }

            // Ensure minExtension is at least extensionInitial
            minExtension = Math.max(minExtension, extensionInitial);

            // Calculate maxExtension to display up to MAX_DISPLAY_OPTIONS options
            let maxExtension = minExtension + EXTENSION_RANGE;

            // Ensure maxExtension does not exceed MAX_EXTENSION
            maxExtension = Math.min(maxExtension, MAX_EXTENSION);

            // Collect all possible extensions in the range
            for (let extension = minExtension; extension <= maxExtension; extension++) {
                const extensionStr = extension.toString();
                extensionsInRange.push(extensionStr);
            }

            // Limit options to the first MAX_DISPLAY_OPTIONS entries
            this.options = extensionsInRange.slice(0, MAX_DISPLAY_OPTIONS);
        }, 10),

        ...mapActions(['newUser', 'updateUser', 'deleteUser'])
    },

    watch: {
        user_id: function (value) {
            this.getUser(value)
        },

        should_observe(value) {
            if (!value) {
                this.user.observing_campaigns = []
            }
        },

        disable_area_code_routing(value) {
            if (value) {
                this.user.operating_area_codes_limit = []
            }
        },

        'user.answer_by': function () {
            setTimeout(() => {
                this.preValidateForm('user_form')
            }, 100)
        },

        'user.outbound_calling_selector': function () {
            if (this.user.outbound_calling_selector === 1) {
                this.user.outbound_calling_mode = UserOutboundCallingModes.OUTBOUND_CALLING_MODE_DEFAULT
            }
            if (this.user.outbound_calling_selector === 2) {
                this.user.default_outbound_campaign_id = null
                this.user.outbound_calling_mode = UserOutboundCallingModes.OUTBOUND_CALLING_MODE_DEFAULT
            }
            if (this.user.outbound_calling_selector === 3) {
                this.user.default_outbound_campaign_id = null
                this.user.outbound_calling_mode = UserOutboundCallingModes.OUTBOUND_CALLING_MODE_ALWAYS_ASK
            }
            setTimeout(() => {
                this.preValidateForm('user_form')
            }, 100)
        },

        'user.outbound_call_recording_mode': function () {
            setTimeout(() => {
                this.preValidateForm('user_form')
            }, 100)
        },

        'user.enabled_two_legged_outbound': function () {
            if (!this.user.enabled_two_legged_outbound) {
                this.user.secondary_phone_number = null
            }
            setTimeout(() => {
                this.preValidateForm('user_form')
            }, 100)
        },

        'user.role_name': function (newValue, oldValue) {
            if (newValue && newValue !== 'Company Admin' && this.mark_as_billing_admin) {
                this.mark_as_billing_admin = false
            }

            if (newValue && newValue === 'Company Admin' && this.user.read_only_access) {
                this.user.read_only_access = false
            }
        }
    },

    beforeCreate() {
        axios.get('/build-info.json')
            .then(res => {
                this.env = res.data.env
            })
            .catch(err => {
                console.log(err)
            })
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                next()
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
