var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("MessagingServiceRegistration", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentStep === 1,
            expression: "currentStep === 1",
          },
        ],
        attrs: {
          "alert-message": _vm.alertMessage,
          selected_lines: _vm.selected_lines,
        },
        on: { alert: _vm.submitAlert, next: _vm.goToNextStep },
      }),
      _vm._v(" "),
      _vm.messaging_service
        ? _c("UseCaseRegistration", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentStep === 2,
                expression: "currentStep === 2",
              },
            ],
            attrs: {
              "alert-message": _vm.alertMessage,
              selected_lines: _vm.selected_lines,
              selected_messaging_service: _vm.messaging_service,
            },
            on: { alert: _vm.submitAlert, next: _vm.goToNextStep },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }