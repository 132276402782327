<template>
    <el-row class="p-0">
        <slot name="content"></slot>
    </el-row>
</template>

<script>
    export default {
        name: 'InputGroup'
    }
</script>
