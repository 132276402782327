var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading_sequences,
          expression: "loading_sequences",
        },
      ],
      staticClass: "mb-4",
      staticStyle: { "min-height": "50vh" },
    },
    [
      _vm.sequences.length > 0
        ? _c(
            "div",
            {
              key: _vm.sequence_list_key,
              staticClass: "container-steps mx-auto py-3 w-80",
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.sequences, function (sequence, index) {
                return _c("div", { key: sequence.id }, [
                  _c("div", { staticClass: "mb-2" }, [
                    _vm._m(1, true),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card py-2 px-3 d-inline-block mx-auto text-center",
                          },
                          [
                            _c("i", { staticClass: "fa fa-clock-o" }),
                            _vm._v(" "),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(sequence.delay_summary),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        sequence.order != 1
                          ? _c(
                              "el-button",
                              {
                                staticClass:
                                  "text-success position-absolute right-10-per",
                                attrs: { type: "text", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.sequence_manager.addSequence(
                                      _vm.value,
                                      _vm.sequences[index - 1]
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-plus" }),
                                _vm._v(" Add Step\n                    "),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._m(2, true),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card shadow-sm mb-2" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          {
                            staticClass: "align-self-center mr-4",
                            staticStyle: {
                              "font-size": "35px",
                              color: "#e9e9e9",
                              "line-height": "35px",
                              "vertical-align": "middle",
                              "margin-top": "5px",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(index + 1) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "align-self-center mr-3",
                          attrs: { src: sequence.img_src },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex-grow-1 align-self-center mr-3" },
                          [
                            _c("h6", { staticClass: "mb-1" }, [
                              _vm._v(_vm._s(sequence.name)),
                            ]),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "mb-0",
                              domProps: { innerHTML: _vm._s(sequence.summary) },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "align-self-center d-flex" },
                          [
                            sequence.diagnosis.length
                              ? _c(
                                  "el-popover",
                                  {
                                    staticClass: "align-self-center",
                                    attrs: { trigger: "hover" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger mr-3 cursor-pointer",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c("strong", [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-exclamation-triangle",
                                          }),
                                          _vm._v(
                                            " Invalid\n                                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "p-2" }, [
                                      _c(
                                        "h6",
                                        { staticClass: "text-dark mb-2" },
                                        [_vm._v("Diagnosis")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "table",
                                        _vm._l(
                                          sequence.diagnosis,
                                          function (diagnosis_item, index) {
                                            return _c("tr", { key: index }, [
                                              _c(
                                                "td",
                                                { staticClass: "pr-2" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-remove text-danger",
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(diagnosis_item) +
                                                    "\n                                            "
                                                ),
                                              ]),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasSequencePreview(sequence)
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "mr-3",
                                    attrs: { size: "small", round: "" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.setSequencePreview(
                                          sequence,
                                          !sequence.preview
                                        )
                                      },
                                    },
                                  },
                                  [
                                    !sequence.preview
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                                    Preview "
                                          ),
                                          _c("i", {
                                            staticClass: "fa fa-caret-down",
                                          }),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                                    Hide "
                                          ),
                                          _c("i", {
                                            staticClass: "fa fa-caret-up",
                                          }),
                                        ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button-group",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      title: "Edit step",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.sequence_manager.editSequence(
                                          sequence
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-edit" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      title: "Move up",
                                      size: "small",
                                      disabled:
                                        index === 0 ||
                                        sequence.enroll_workflow_id > 0,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.moveSequenceUp(index)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-arrow-up" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      title: "Move down",
                                      disabled: _vm.isMoveDownDisabled(index),
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.moveSequenceDown(index)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-arrow-down" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { title: "Delete", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$refs.sequence_manager.deleteSequence(
                                          sequence
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-trash-o text-danger",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.sequenceTypeHasPreview(sequence.type)
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: sequence.preview,
                                  expression: "sequence.preview",
                                },
                              ],
                              staticClass: "sequence-step-preview py-4",
                            },
                            [
                              [
                                _vm.Sequence.TYPE_MMS,
                                _vm.Sequence.TYPE_SMS_LEAD,
                                _vm.Sequence.TYPE_SMS_RING_GROUP,
                              ].includes(sequence.type)
                                ? _c("div", { staticClass: "text-center" }, [
                                    _c(
                                      "div",
                                      { staticClass: "sequence-message" },
                                      [
                                        sequence.message_file
                                          ? _c("div", { staticClass: "mb-3" }, [
                                              sequence.message_file.includes(
                                                ".gif"
                                              )
                                                ? _c("div", [
                                                    _c("img", {
                                                      staticClass: "img-fluid",
                                                      attrs: {
                                                        src: sequence.message_file,
                                                      },
                                                    }),
                                                  ])
                                                : _c("div", [
                                                    _c("img", {
                                                      staticClass: "img-fluid",
                                                      attrs: {
                                                        src:
                                                          "/static/uploaded_file/" +
                                                          sequence.message_file,
                                                      },
                                                    }),
                                                  ]),
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(sequence.message_tts) +
                                            "\n                                "
                                        ),
                                        _c("i", {
                                          staticClass: "fa fa-caret-left",
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              sequence.type === _vm.Sequence.TYPE_RVM &&
                              sequence.message_file !== null
                                ? _c("div", { staticClass: "text-center" }, [
                                    _c(
                                      "div",
                                      { staticClass: "sequence-message" },
                                      [
                                        _c(
                                          "audio",
                                          { attrs: { controls: "" } },
                                          [
                                            _c("source", {
                                              attrs: {
                                                src:
                                                  "/static/uploaded_file/" +
                                                  sequence.message_file,
                                              },
                                            }),
                                            _vm._v(
                                              "\n                                    Your browser does not support the audio element.\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass: "fa fa-caret-left",
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isEmailTypeSequence(sequence)
                                ? _c("div", { staticClass: "text-center" }, [
                                    _c(
                                      "div",
                                      { staticClass: "sequence-message" },
                                      [
                                        _c("div", { staticClass: "mb-2" }, [
                                          _c("strong", [_vm._v("Subject:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(sequence.message_subject) +
                                              "\n                                "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("strong", [_vm._v("Email Body:")]),
                                          _vm._v(" "),
                                          _c("p", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                sequence.message_body
                                              ),
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ])
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success", round: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.sequence_manager.addSequence(
                            _vm.value,
                            _vm.last_sequence
                          )
                        },
                      },
                    },
                    [
                      _c("strong", [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v(" Add Step\n                "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sequences.length <= 0
        ? _c(
            "div",
            { staticClass: "text-center py-5" },
            [
              _c("div", { staticClass: "w-50 mx-auto" }, [_c("img-empty")], 1),
              _vm._v(" "),
              _c("p", { staticClass: "lead" }, [
                _vm._v(
                  "\n            No steps has been setup for this sequence yet.\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "success", round: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.sequence_manager.addSequence(
                        _vm.value,
                        _vm.last_sequence
                      )
                    },
                  },
                },
                [
                  _c("strong", [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v(" Add Step\n            "),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("sequence-manager", {
        ref: "sequence_manager",
        on: { "before-close": _vm.fetchSequences },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mb-2" }, [
      _c("div", { staticClass: "mx-auto d-inline-block" }, [
        _c("i", {
          staticClass: "fa fa-user-circle-o text-success",
          staticStyle: { "font-size": "35px", color: "#dedede" },
        }),
        _vm._v(" "),
        _c("div", [_vm._v("Enrolled Contact")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("div", {
        staticStyle: {
          width: "3px",
          height: "20px",
          "background-color": "#e9e9e9",
          display: "inline-block",
          "border-radius": "3px 3px 0 0",
          "vertical-align": "bottom",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("i", {
        staticClass: "fa fa-arrow-down",
        staticStyle: {
          "font-size": "20px",
          color: "#e0e0e0",
          "margin-top": "-3px",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2 mt-3" }, [
      _c("i", {
        staticClass: "fa fa-arrow-down",
        staticStyle: { "font-size": "20px", color: "#e0e0e0" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }