var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "card p-3",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container-outbound-connection-rate-widget mb-5" },
        [
          _c(
            "div",
            {
              staticClass: "label-connection-rate",
              staticStyle: { transform: "translateX(-50%)", bottom: "0" },
            },
            [
              _c("h2", [
                _vm._v(_vm._s(_vm.metrics.outbound_texts_delivery_rate) + "%"),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("SMS Delivery Rate")]),
            ]
          ),
          _vm._v(" "),
          _c("highcharts", { ref: "chart", attrs: { options: _vm.options } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("metric-widget", {
              attrs: {
                label: "Delivered Outbound SMS",
                description:
                  "Text messages that have been successfully delivered",
                metric: _vm.metrics.outbound_texts_delivered,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("metric-widget", {
              attrs: {
                label: "Failed Outbound SMS",
                description: "Text messages that have failed",
                metric: _vm.metrics.outbound_texts_failed,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex mb-3" }, [
      _c("img", {
        staticClass: "mr-2",
        attrs: { src: "/assets/images/app-icons/new/sms_icon.svg" },
      }),
      _vm._v(" "),
      _c("h6", { staticClass: "mb-0 align-self-center" }, [
        _vm._v("Outbound SMS Delivery"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }