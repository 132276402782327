var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn btn-light bg-white border btn-block p-x-md" },
    [
      _c("img", {
        staticClass: "google-icon",
        attrs: { src: "/assets/images/google-logo.svg" },
      }),
      _vm._v("\n     " + _vm._s(_vm.text) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }