<template>
    <div id="aside"
         class="app-aside modal nav-dropdown no-select"
         :class="{ 'folded md show-text': sidebar_folded }"
         :style="{top: top_offset + 'px'}"
         :key="sidebarKey"
         ref="sidebar">
        <!-- fluid app aside -->
        <div class="left navside text-blackish"
             data-layout="column">
            <div class="navbar no-radius">
                <!-- brand -->
                <a class="navbar-brand mx-auto d-block">
                    <img :src="statics.logo"
                         v-if="!sidebar_folded && !loading_whitelabel"
                         alt=".">
                    <img :src="statics.logo_square"
                         v-if="sidebar_folded && !loading_whitelabel"
                         alt=".">
                </a>
                <!-- / brand -->
            </div>
            <div v-if="canCommunicate"
                 flex-no-shrink>
                <nav ui-nav>
                    <ul class="nav">
                        <div class="p-2">
                            <a class="btn btn-block pl-3 mt-1 text-center"
                               :class="dialerButtonClass"
                               data-tour-step="5"
                               ref="alowareDialer"
                               @click="toggleDialer">
                                <i class="material-icons dialer-icon pull-left"
                                   v-if="!sidebar_folded">phone_iphone</i>
                                <i class="material-icons dialer-icon"
                                   v-else>phone_iphone</i>
                                <template v-if="!sidebar_folded">
                                    <span v-if="!webrtc_visible">Open Phone</span>
                                    <span v-else>Close Phone</span>
                                </template>
                            </a>
                        </div>
                    </ul>
                </nav>
            </div>
            <div class="hide-scroll"
                 :class="[canCommunicate ? '' : 'pt-2']"
                 data-flex>
                <nav
                    class="scroll nav-border b-greenish h-v"
                >
                    <ul class="nav"
                        ui-nav
                    >

            <app-sidebar-dashboard v-if="!isSupervisor"/>

            <router-link :to="{ name: 'Wallboard' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded"
                         v-if="hasWallboardAccess && !isSupervisor">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-wallboard-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-wallboard-white.svg">
                                </span>
                <span class="nav-text">Wallboard</span>
              </a>
            </router-link>

            <router-link :to="{ name: 'Lines' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded"
                         v-if="shouldShowLines">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-lines-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-lines-white.svg">
                                </span>
                <span class="nav-text">Lines</span>
              </a>
            </router-link>

            <router-link :to="{ name: 'Ring Groups' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded"
                         v-if="hasPermissionTo('list ring group') && !hasReporterAccess">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-ring-groups-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-ring-groups-white.svg">
                                </span>
                <span class="nav-text">Ring Groups</span>
              </a>
            </router-link>

            <router-link :to="{ name: 'Teams' }"
                         active-class="active green-gradient rounded"
                         exact-active-class="active green-gradient rounded"
                         tag="li"
                         data-testid="teams-sidebar"
                         v-if="isAdminOrSupervisor">
              <a>
                <span class="nav-icon">
                    <img src="/assets/images/app-icons/icon-teams.svg">
                </span>
                <span class="nav-icon active">
                    <img src="/assets/images/app-icons/icon-teams-white.svg">
                </span>

                <div class="d-flex align-items-center justify-center"
                     :class="sidebar_folded ? 'flex-column' : ''"
                >
                  <span class="nav-text">Teams</span>
                  <el-tag  :class="sidebar_folded ? '' : 'ml-2' "
                          size="mini"
                          type="info">
                    Beta
                  </el-tag>
                </div>
              </a>
            </router-link>

            <router-link :to="{ name: 'Users' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded"
                         v-if="shouldShowUsers"
                         data-testid="users-sidebar">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-user-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-user-white.svg">
                                </span>
                <span class="nav-text">Users</span>
              </a>
            </router-link>

            <app-sidebar-admin-list v-if="isAdminOrSupervisor"/>

            <router-link :to="{ name: 'Tags' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded"
                         v-if="hasPermissionTo('list tag') && !isSupervisor">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-tags-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-tags-white.svg">
                                </span>
                <span class="nav-text">Tags</span>
              </a>
            </router-link>

            <router-link tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded"
                         :to="{ name: 'Messenger' }"
                         v-if="isSimpSocialSidebarItem && !forceTalk">
              <a>
                                <span class="nav-icon messenger">
                                    <img src="/assets/images/messenger-line.png"/>
                                </span>
                <span class="nav-icon active messenger">
                                    <img src="/assets/images/messenger-line.png"/>
                                </span>
                <span class="nav-text">Messenger</span>
              </a>
            </router-link>

            <router-link tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded"
                         :to="{ name: 'DMS Equity' }"
                         v-if="isSimpSocialSidebarItem && !forceTalk">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-dms-grey.svg"/>
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-dms-white.svg"/>
                                </span>
                <span class="nav-text">DMS Equity</span>
              </a>
            </router-link>

            <router-link tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded"
                         :to="{ name: 'Digital Lead War' }"
                         v-if="isSimpSocialSidebarItem && !forceTalk">
              <a>
                                <span class="nav-icon">
                                    <i class="fa fa-fighter-jet text-grey"></i>
                                </span>
                <span class="nav-icon active">
                                    <i class="fa fa-fighter-jet text-white1"></i>
                                </span>
                <span class="nav-text">Digital Lead War</span>
              </a>
            </router-link>

            <router-link tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded"
                         :to="{ name: 'Email Blast' }"
                         v-if="isSimpSocialSidebarItem && !forceTalk">
              <a>
                                <span class="nav-icon">
                                    <i class="fa fa-envelope-o text-grey"></i>
                                </span>
                <span class="nav-icon active">
                                    <i class="fa fa-envelope-o text-white1"></i>
                                </span>
                <span class="nav-text">Email Blast</span>
              </a>
            </router-link>

            <router-link
                v-if="shouldShowAloAiMenu"
                :to="{ name: 'AloAi' }"
                tag="li"
                exact-active-class="active green-gradient rounded"
                active-class="active green-gradient rounded">
              <a>
                                <span class="nav-label">
                                    <el-tooltip
                                        content="Pro"
                                        placement="right"
                                    >
                                        <b class="fa fa-circle pro-label-text" />
                                    </el-tooltip>
                                </span>
                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/aloai-text-bot-gray.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/aloai-text-bot-white.svg">
                                </span>
                                <span class="nav-text">AloAi Text Bot</span>
                            </a>
                        </router-link>

                        <router-link
                            v-if="shouldShowAloAiMenu"
                            :to="{ name: 'AloAi Voice Bot' }"
                            tag="li"
                            exact-active-class="active green-gradient rounded"
                            active-class="active green-gradient rounded"
                        >
                            <a>
                                <span class="nav-label">
                                    <el-tooltip
                                        content="Pro"
                                        placement="right"
                                    >
                                        <b class="fa fa-circle pro-label-text" />
                                    </el-tooltip>
                                </span>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/aloai-voice-bot-gray.svg">
                                </span>
                                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/aloai-voice-bot-white.svg">
                                </span>
                                <span class="nav-text">AloAi Voice Bot</span>
                            </a>
                        </router-link>

            <router-link
                v-if="shouldShowAloVoiceAiMenu"
                :to="{ name: 'Alo Voice AI' }"
                tag="li"
                exact-active-class="active green-gradient rounded"
                active-class="active green-gradient rounded">
              <a>
                                <span class="nav-label">
                                    <el-tooltip
                                        content="Pro"
                                        placement="right"
                                    >
                                        <b class="fa fa-circle pro-label-text" />
                                    </el-tooltip>
                                </span>
                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/alo-voice-ai-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/alo-voice-ai-white.svg">
                                </span>
                                <span class="nav-text">AloAi Voice Analytics</span>
                            </a>
                        </router-link>

            <router-link
                v-if="!hasReporterAccess && !forceTalk && !isSupervisor"
                :to="{ name: 'Calendar' }"
                tag="li"
                exact-active-class="active green-gradient rounded"
                active-class="active green-gradient rounded">
              <a>
                                <span class="nav-label">
                                    <b class="label pro-label"
                                       v-if="!sidebar_folded">Pro</b>
                                    <b class="fa fa-circle pro-label-text"
                                       v-else></b>
                                </span>
                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-calendar-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-calendar-white.svg">
                                </span>
                <span class="nav-text">Calendar</span>
              </a>
            </router-link>

            <router-link
                v-if="shouldShowBroadcast"
                :to="{ name: 'Broadcast' }"
                tag="li"
                exact-active-class="active green-gradient rounded"
                active-class="active green-gradient rounded">
              <a>
                                <span class="nav-label">
                                    <b class="label pro-label"
                                       v-if="!sidebar_folded">Pro</b>
                                    <b class="fa fa-circle pro-label-text"
                                       v-else></b>
                                </span>
                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-broadcast-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-broadcast-white.svg">
                                </span>
                <span class="nav-text">Broadcast</span>
              </a>
            </router-link>

            <router-link v-if="shouldSeeSequences"
                         :to="{ name: 'Sequences 2' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded">
              <a>
                                <span class="nav-label">
                                    <b class="label pro-label"
                                       v-if="!sidebar_folded">Pro</b>
                                    <b class="fa fa-circle pro-label-text"
                                       v-else></b>
                                </span>
                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-workflow-grey.svg"
                                         width="24">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-workflow-white.svg"
                                         width="24">
                                </span>
                <span class="nav-text">Sequences+</span>
              </a>
            </router-link>

            <router-link :to="{ name: 'Reports' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded"
                         v-if="hasPermissionTo('list report')">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-reports-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-reports-white.svg">
                                </span>
                <span class="nav-text">Reports</span>
              </a>
            </router-link>
            <router-link :to="{ name: 'Insights' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded"
                         v-if="hasPermissionTo('list insights')">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-insights-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-insights-white.svg">
                                </span>
                <span class="nav-text">Insights</span>
              </a>
            </router-link>

            <li v-if="isSimpSocialSidebarItem && !forceTalk">
              <a class="is-link"
                 target="_blank"
                 href="https://calendly.com/training-2652">
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-training-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-training-white.svg">
                                </span>
                <span class="nav-text">Training</span>
              </a>
            </li>

            <router-link v-if="hasPermissionTo('change integration settings company') && current_company && current_company.reseller_id !== 357"
                         :to="{ name: 'Integrations' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-integrations-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-integrations-white.svg">
                                </span>
                <span class="nav-text">Integrations</span>
              </a>
            </router-link>

            <router-link v-if="current_company && hasPermissionTo('update company') && auth.user.profile"
                         :to="{ name: 'Account' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-company-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-company-white.svg">
                                </span>
                <span class="nav-text">Account</span>
              </a>
            </router-link>
            <router-link v-if="isSimpSocialSidebarItem && !forceTalk"
                         :to="{ name: 'Sold Report' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-soldreport-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-soldreport-white.svg">
                                </span>
                <span class="nav-text">Sold Report</span>
              </a>
            </router-link>

            <router-link v-if="canSeeDealerProfile"
                         :to="{ name: 'Dealer Profile' }"
                         tag="li"
                         exact-active-class="active green-gradient rounded"
                         active-class="active green-gradient rounded">
              <a>
                                <span class="nav-icon">
                                    <img src="/assets/images/app-icons/icon-car-grey.svg">
                                </span>
                <span class="nav-icon active">
                                    <img src="/assets/images/app-icons/icon-car-white.svg">
                                </span>
                <span class="nav-text">Dealer Profile</span>
              </a>
            </router-link>
            <div class="h-2x my-4"></div>
          </ul>
        </nav>
      </div>
    </div>
    <div>
      <el-dialog
          width="20%"
          custom-class="lost-connection-dialog"
          title="Dialer Error"
          :visible="dialogDialerError"
          :append-to-body="true"
          @close="closeDialerLostConnectionDialog()">
        <div class="break-word">
          <span>One of the following may have occured:</span>
          <ul>
            <li>Poor connection to servers</li>
            <li>Poor connection to our carrier network</li>
            <li>You have a second tab open with any other open instances of dialer on this browser</li>
          </ul>
          <hr>
          <span>Try refreshing this web app or close any other instances of dialer open on this browser</span>
        </div>
        <span slot="footer"
              class="dialog-footer text-center">
                  <el-button class="btn-block"
                             type="primary"
                             @click="reloadDialer">
                      Reload Dialer
                  </el-button>
              </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapState, mapGetters} from 'vuex'
import {
    acl_mixin,
    styling_mixin,
    webrtc_mixin
} from '../mixins'
import AppSidebarDashboard from '../components/app-sidebar-items/dashboard'
import AppSidebarAdminList from '../components/app-sidebar-items/admin-list'

export default {
  components: {
    AppSidebarDashboard,
    AppSidebarAdminList,
  },

    mixins: [
        styling_mixin,
        acl_mixin,
        webrtc_mixin
    ],

    data() {
        return {
            auth: auth,
            is_impersonated: localStorage.getItem('impersonate'),
            env: null,
            profile: auth.user.profile,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            webrtc_visible: false,
            webrtc_ready: false,
            dialogDialerError: false,
            simpsocialEmailModalOpen: false,
            sidebarKey: 0
        }
    },

  computed: {
    ...mapState({
      on_call: state => state.on_call,
      campaigns: state => state.campaigns,
      current_company: state => state.cache.current_company,
      sidebar_folded: state => state.cache.sidebar_folded,
    }),

    ...mapGetters('cache', ['isSimpSocial']),

    canCommunicate() {
      return !this.hasReporterAccess && !this.forceTalk
    },

    dialerButtonClass() {
      if (this.on_call) {
        return ['danger text-whitish']
      }
      if (this.webrtc_visible) {
        return ['new-blue text-whitish']
      }
      if (!this.supportsWebrtc) {
        return ['blackish text-whitish btn-disabled']
      }
      if (!this.webrtc_ready) {
        return ['amber-500 text-whitish']
      }
      return ['amber-700 text-whitish']
    },

    shouldShowLines() {
      if (!this.hasPermissionTo('list campaign')) {
        return false
      }

      if (this.current_company && this.current_company.reseller_id == 357 && !this.hasRole('Billing Admin')) {
        return false
      }

      return true
    },

    shouldShowUsers() {
      if (!this.hasPermissionTo('list user') || this.isSupervisor) {
        return false
      }

      return true
    },

    shouldShowShortUrls() {
      return true
    },

    isSimpSocialSidebarItem() {
      return (this.current_company && this.current_company.simpsocial_integration_enabled && this.auth.user.profile) || this.isSimpSocial
    },

    canSeeDealerProfile () {
        return (this.current_company?.simpsocial_integration_enabled || this.isSimpSocial) &&
            this.hasPermissionTo('update company') &&
            localStorage.getItem('is_admin_impersonating')
    },
  },

  created() {
    VueEvent.listen('webrtc_visibility', (data) => {
      this.webrtc_visible = data
    })
    VueEvent.listen('agent_status_updated', (event) => {
      if (this.auth && this.profile && event.user_id === this.profile.id) {
        this.profile.agent_status = event.agent_status
      }
    })
    VueEvent.listen('webrtc_ready', (ready) => {
      this.webrtc_ready = ready
    })
    this.getStatics()
  },

  mounted() {
    this.addActiveClass()
  },

  methods: {
    toggleDialer(e) {
      if (this.forceTalk) {
        this.$alert('Sorry, please use Talk to make calls.', 'Oops!', {
          confirmButtonText: 'Ok',
          center: false,
          callback: cancel => {
          }
        })
        return
      }

      if (!this.supportsWebrtc) {
        this.$alert('Sorry, your browser does not have the support for the WebRTC feature.', 'Oops!', {
          confirmButtonText: 'Ok',
          center: true,
          callback: cancel => {
          }
        })
        return
      }
      if (!this.campaigns || this.campaigns.length == 0) {
        this.$alert('Sorry, you need to add a line first to use the dialer.', 'Oops!', {
          confirmButtonText: 'Ok',
          center: true,
          callback: cancel => {
          }
        })
        return
      }
      if (this.on_call && sessionStorage.getItem('on_call') == 'false') {
        this.dialogDialerError = true
        return
      }
      VueEvent.fire('toggle_webrtc')
    },

    contactSupport() {
      this.$alert('Sorry, you don\'t have permission to use this feature. Please contact support to activate it.', 'Oops!', {
        confirmButtonText: 'Ok',
        center: true,
        callback: cancel => {
        }
      })
    },

    getStatics() {
      this.loading_whitelabel = true
      axios.get('/get-statics')
          .then(res => {
            this.statics = res.data
            this.loading_whitelabel = false
          })
          .catch(err => {
            console.log(err)
            this.loading_whitelabel = false
            this.$root.handleErrors(err.response)
          })
    },

    addActiveClass() {
      setTimeout(() => {
        $("body").find(".navside .nav li.active").each(function () {
          $(this).parent().parent().addClass("active")
        })
      }, 100)
    },

    beforeCloseModal(done) {
      if (!this.campaign_created) {
        this.$confirm('Are you sure you want to leave. Your changes will not be saved.', 'Warning', {
          confirmButtonText: 'Yes, Leave',
          cancelButtonText: 'No, Stay',
          customClass: 'width-500 fixed',
          type: 'warning'
        })
            .then(res => {
              done()
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        done()
      }
    },

    campaignCreated() {
      this.campaign_created = true
    },

    reloadDialer() {
      VueEvent.fire('reload_dialer')
      this.closeDialerLostConnectionDialog()
    },

    closeDialerLostConnectionDialog() {
      this.dialogDialerError = false
    },

    openSimpsocialEmailModal() {
      this.simpsocialEmailModalOpen = !this.simpsocialEmailModalOpen
    },

    ...mapActions('cache', ['resetCache'])
  },

    watch: {
        '$route'(to, from) {
            this.addActiveClass()
        },

        forceTalk(newVal) {
            this.sidebarKey++
        }
    },

  beforeCreate() {
    axios.get('/build-info.json')
        .then(res => {
          this.env = res.data.env
        })
        .catch(err => {
          console.log(err)
        })
  }
}
</script>
