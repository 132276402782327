var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "powerDialerSessionSettingForm",
          staticClass: "rounded d-flex flex-column",
          staticStyle: { "border-radius": "8px !important" },
          attrs: {
            id: "powerDialerSessionSettingForm",
            rules: _vm.form_rules,
            model: _vm.setting_clone,
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-row justify-content-between mb-4" },
            [
              _c("el-form-item", { attrs: { prop: "name" } }, [
                _c("div", { staticClass: "d-flex flex-row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.setting_clone.name,
                        expression: "setting_clone.name",
                      },
                    ],
                    staticClass: "power-dialer-form-input",
                    staticStyle: { "border-radius": "5px", width: "102px" },
                    attrs: { placeholder: "Untitled", type: "text" },
                    domProps: { value: _vm.setting_clone.name },
                    on: {
                      focus: function ($event) {
                        _vm.show_edit_title_icon = false
                      },
                      blur: function ($event) {
                        _vm.show_edit_title_icon = true
                      },
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.setting_clone,
                            "name",
                            $event.target.value
                          )
                        },
                        function ($event) {
                          return _vm.preValidateForm(
                            "powerDialerSessionSettingForm"
                          )
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("mode_edit"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _vm._v("Session Settings"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-row" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "alo-button",
                      staticStyle: {
                        "font-size": "13px !important",
                        height: "30px",
                        "border-radius": "5px !important",
                      },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "alo-button",
                      staticStyle: {
                        "font-size": "13px !important",
                        height: "30px",
                        "border-radius": "5px !important",
                      },
                      attrs: { type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("\n                    Save\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mb-2" }, [
            _c(
              "b",
              {
                staticClass: "text-black",
                staticStyle: { "font-size": "14px" },
              },
              [_vm._v("Basics")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-row" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column w-50 mr-3" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mb-3", attrs: { prop: "campaign_id" } },
                  [
                    _c("span", { staticClass: "mb-2 title-label" }, [
                      _vm._v("Line"),
                    ]),
                    _vm._v(" "),
                    _c("campaign-selector", {
                      staticClass: "w-full",
                      attrs: { clearable: true },
                      on: { change: _vm.changeCampaign },
                      model: {
                        value: _vm.setting_clone.campaign_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.setting_clone, "campaign_id", $$v)
                        },
                        expression: "setting_clone.campaign_id",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-column w-50 mb-3" },
              [
                _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c("span", { staticClass: "mr-2 title-label" }, [
                      _vm._v("Skip Outside Daytime Hours"),
                    ]),
                    _vm._v(" "),
                    true
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              placement: "top-start",
                              width: "300",
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("div", { staticStyle: { width: "300px" } }, [
                                  _vm._v(
                                    "\n                                Skip Outside Daytime Hours: Enabling this will only call contacts within the Open & Closed Times defined in Power Dialer Settings. If a contact is outside the Open and Closd Times, the dialer will push the task to the bottom of the list (instead of failing it).\n                            "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "i",
                              {
                                staticClass: "material-icons text-blue-5",
                                staticStyle: {
                                  cursor: "pointer",
                                  "font-size": "15px",
                                },
                              },
                              [_vm._v("info_outline")]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.setting_clone.skip_outside_daytime_hours,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.setting_clone,
                        "skip_outside_daytime_hours",
                        $$v
                      )
                    },
                    expression: "setting_clone.skip_outside_daytime_hours",
                  },
                }),
                _vm._v(" "),
                !_vm.isCompanyPartOfAlowareDemoCompanies()
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "mb-2" },
                          [
                            _c("span", { staticClass: "mr-2 title-label" }, [
                              _vm._v("Require Agent Redial"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  placement: "top-start",
                                  width: "300",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "300px" } },
                                      [
                                        _vm._v(
                                          "\n                                  Require Agent Redial: By selecting this option, agents are required to redial a contact when they don't pick the call up.\n                              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "i",
                                  {
                                    staticClass: "material-icons text-blue-5",
                                    staticStyle: {
                                      cursor: "pointer",
                                      "font-size": "15px",
                                    },
                                  },
                                  [_vm._v("info_outline")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: { "active-value": 1, "inactive-value": 0 },
                          model: {
                            value: _vm.setting_clone.force_redial,
                            callback: function ($$v) {
                              _vm.$set(_vm.setting_clone, "force_redial", $$v)
                            },
                            expression: "setting_clone.force_redial",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-row mb-4" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column w-50 mr-3" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "mb-0",
                    attrs: { prop: "warmup_period_in_seconds" },
                  },
                  [
                    _c("span", { staticClass: "mr-2 title-label" }, [
                      _vm._v(
                        "\n                        Warmup Period (seconds)\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    true
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              placement: "top-start",
                              width: "300",
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("div", { staticStyle: { width: "300px" } }, [
                                  _vm._v(
                                    "\n                                Warmup Period: The amount of time given to the agent between calls. This allows the agent to prepare for the next call.\n                            "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "i",
                              {
                                staticClass: "material-icons text-blue-5",
                                staticStyle: {
                                  cursor: "pointer",
                                  "font-size": "15px",
                                },
                              },
                              [_vm._v("info_outline")]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-100",
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.setting_clone.warmup_period_in_seconds,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.setting_clone,
                              "warmup_period_in_seconds",
                              $$v
                            )
                          },
                          expression: "setting_clone.warmup_period_in_seconds",
                        },
                      },
                      _vm._l(_vm.warm_up_durations, function (duration) {
                        return _c("el-option", {
                          key: duration,
                          attrs: { value: duration },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-column w-50" },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mb-0", attrs: { prop: "script_id" } },
                  [
                    _c("span", { staticClass: "mb-2 title-label" }, [
                      _vm._v("Phone Scripts"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-100",
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.setting_clone.script_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.setting_clone, "script_id", $$v)
                          },
                          expression: "setting_clone.script_id",
                        },
                      },
                      _vm._l(_vm.scripts, function (script) {
                        return _c("el-option", {
                          key: script.id,
                          attrs: { label: script.title, value: script.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isCompanyPartOfAlowareDemoCompanies(),
                  expression: "isCompanyPartOfAlowareDemoCompanies()",
                },
              ],
            },
            [
              _c("div", { staticClass: "mb-2" }, [
                _c(
                  "b",
                  {
                    staticClass: "text-black",
                    staticStyle: { "font-size": "14px" },
                  },
                  [
                    _vm._v(
                      "\n                  Redial Settings\n              "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-row" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column w-50 mr-3" },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mb-3", attrs: { prop: "min_redials" } },
                      [
                        _c("span", { staticClass: "mb-2 mr-2 title-label" }, [
                          _vm._v("Minimum Number of Redials"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              placement: "top-start",
                              width: "300",
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("div", { staticStyle: { width: "300px" } }, [
                                  _vm._v(
                                    "\n                                  Choose how many times a contact has to be redialed until a successful call disposition is achieved, select 0 to disable the redial requirement\n                              "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "i",
                              {
                                staticClass: "material-icons text-blue-5",
                                staticStyle: {
                                  cursor: "pointer",
                                  "font-size": "15px",
                                },
                              },
                              [_vm._v("info_outline")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticClass: "w-100",
                            on: {
                              change: function ($event) {
                                return _vm.preValidateForm(
                                  "powerDialerSessionSettingForm"
                                )
                              },
                            },
                            model: {
                              value: _vm.setting_clone.min_redials,
                              callback: function ($$v) {
                                _vm.$set(_vm.setting_clone, "min_redials", $$v)
                              },
                              expression: "setting_clone.min_redials",
                            },
                          },
                          _vm._l(
                            _vm.MIN_REDIAL_OPTIONS,
                            function (redial_options) {
                              return _c("el-option", {
                                key: redial_options.key,
                                attrs: {
                                  label: redial_options.label,
                                  value: redial_options.value,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex flex-column w-50 mb-3" },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-1" },
                      [
                        _c("span", { staticClass: "mr-2 title-label" }, [
                          _vm._v("Force Immediate Redial"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              placement: "top-start",
                              width: "300",
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("div", { staticStyle: { width: "300px" } }, [
                                  _vm._v(
                                    "\n                                  Whenever a Redial is required, immediately redial the contact, instead of pushing to the bottom of the queue.\n                              "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "i",
                              {
                                staticClass: "material-icons text-blue-5",
                                staticStyle: {
                                  cursor: "pointer",
                                  "font-size": "15px",
                                },
                              },
                              [_vm._v("info_outline")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.setting_clone.force_immediate_redial,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.setting_clone,
                            "force_immediate_redial",
                            $$v
                          )
                        },
                        expression: "setting_clone.force_immediate_redial",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt-2 mb-1" },
                      [
                        _c("span", { staticClass: "mr-2 title-label" }, [
                          _vm._v("Force SMS Sending on Unsuccessful Calls"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              placement: "top-start",
                              width: "300",
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("div", { staticStyle: { width: "300px" } }, [
                                  _vm._v(
                                    "\n                                  Request sending a SMS if the selected call disposition is not a Successful Call Disposition\n                              "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "i",
                              {
                                staticClass: "material-icons text-blue-5",
                                staticStyle: {
                                  cursor: "pointer",
                                  "font-size": "15px",
                                },
                              },
                              [_vm._v("info_outline")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.setting_clone.force_sms,
                        callback: function ($$v) {
                          _vm.$set(_vm.setting_clone, "force_sms", $$v)
                        },
                        expression: "setting_clone.force_sms",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-column w-full mb-4" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "span",
                        { staticClass: "mb-2 mr-2 title-label d-inline-block" },
                        [_vm._v("Select Successful Call Dispositions")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top-start",
                            width: "300",
                          },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c("div", { staticStyle: { width: "300px" } }, [
                                _vm._v(
                                  "\n                              Select the dispositions that won't trigger a double dial, meaning that the call was successfully answered.\n                          "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons text-blue-5",
                              staticStyle: {
                                cursor: "pointer",
                                "font-size": "15px",
                              },
                            },
                            [_vm._v("info_outline")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "successful_call_disposition_ids" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { multiple: "" },
                          on: {
                            change: function ($event) {
                              return _vm.preValidateForm(
                                "powerDialerSessionSettingForm"
                              )
                            },
                          },
                          model: {
                            value:
                              _vm.setting_clone.successful_call_disposition_ids,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.setting_clone,
                                "successful_call_disposition_ids",
                                $$v
                              )
                            },
                            expression:
                              "setting_clone.successful_call_disposition_ids",
                          },
                        },
                        _vm._l(
                          _vm.call_dispositions,
                          function (call_disposition) {
                            return _c(
                              "el-option",
                              {
                                key: call_disposition.id,
                                attrs: {
                                  label: call_disposition.name,
                                  value: call_disposition.id,
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-bolt",
                                  style: { color: call_disposition.color },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v(_vm._s(call_disposition.name)),
                                ]),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mb-2" }, [
            _c(
              "b",
              {
                staticClass: "text-black",
                staticStyle: { "font-size": "14px" },
              },
              [_vm._v("Customizations")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-row mb-3" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column w-50 mr-3" },
              [
                _c("span", { staticClass: "mb-2 title-label" }, [
                  _vm._v(
                    "\n                    Set Call Disposition Shortcuts\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: { multiple: "" },
                    model: {
                      value: _vm.setting_clone.call_disposition_ids,
                      callback: function ($$v) {
                        _vm.$set(_vm.setting_clone, "call_disposition_ids", $$v)
                      },
                      expression: "setting_clone.call_disposition_ids",
                    },
                  },
                  _vm._l(_vm.call_dispositions, function (call_disposition) {
                    return _c(
                      "el-option",
                      {
                        key: call_disposition.id,
                        attrs: {
                          label: call_disposition.name,
                          value: call_disposition.id,
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-bolt",
                          style: { color: call_disposition.color },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(call_disposition.name)),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-column w-50" },
              [
                _c("span", { staticClass: "mb-2 title-label" }, [
                  _vm._v(
                    "\n                    Set Contact Disposition Shortcuts\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: { multiple: "" },
                    model: {
                      value: _vm.setting_clone.contact_disposition_ids,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.setting_clone,
                          "contact_disposition_ids",
                          $$v
                        )
                      },
                      expression: "setting_clone.contact_disposition_ids",
                    },
                  },
                  _vm._l(_vm.disposition_statuses, function (disposition) {
                    return _c(
                      "el-option",
                      {
                        key: disposition.id,
                        attrs: {
                          label: disposition.name,
                          value: disposition.id,
                        },
                      },
                      [
                        disposition.is_external !== undefined
                          ? [
                              disposition.is_external
                                ? _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      style: { color: disposition.color },
                                    },
                                    [_vm._v("lock")]
                                  )
                                : _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      style: { color: disposition.color },
                                    },
                                    [_vm._v("label")]
                                  ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(disposition.name))]),
                      ],
                      2
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-row mb-3" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column w-50 mr-3" },
              [
                _c("span", { staticClass: "mb-2 title-label" }, [
                  _vm._v(
                    "\n                    Set Session Metrics\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: { multiple: "", "multiple-limit": 4 },
                    model: {
                      value: _vm.setting_clone.metric_options,
                      callback: function ($$v) {
                        _vm.$set(_vm.setting_clone, "metric_options", $$v)
                      },
                      expression: "setting_clone.metric_options",
                    },
                  },
                  _vm._l(_vm.metric_options, function (metric_group) {
                    return _c(
                      "el-option-group",
                      {
                        key: metric_group.label,
                        attrs: { label: metric_group.label },
                      },
                      _vm._l(metric_group.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "w-50" }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }