var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: { placement: "right-start", width: "350", trigger: "click" },
      on: { show: _vm.resetForm },
    },
    [
      _c(
        "el-form",
        [
          _c("h6", { staticClass: "mb-2 text-black" }, [
            _vm._v("Convert to List"),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mb-3" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.tab_name,
                    callback: function ($$v) {
                      _vm.tab_name = $$v
                    },
                    expression: "tab_name",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "User List", name: "user_list" } },
                    [
                      _c("p", { staticClass: "lh-1p4 mb-2 text-dark" }, [
                        _vm._v("Convert into a list of contacts for this user"),
                      ]),
                      _vm._v(" "),
                      _c("user-selector", {
                        ref: "userSelector",
                        attrs: { clearable: "", hide_extensions: true },
                        on: { change: _vm.changeUserId },
                        model: {
                          value: _vm.user_id,
                          callback: function ($$v) {
                            _vm.user_id = $$v
                          },
                          expression: "user_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Public List", name: "public_list" } },
                    [
                      _c("p", { staticClass: "lh-1p4 mb-2 text-dark" }, [
                        _vm._v("Convert into a public list of contacts"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "w-full",
                  attrs: {
                    type: "success",
                    size: "small",
                    disabled: _vm.unassignable,
                  },
                  on: { click: _vm.convertTag },
                },
                [
                  _c("i", { staticClass: "fas fa-address-book-alt" }),
                  _vm._v(" Convert\n            "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-block btn-dark-warning text-white btn-sm",
          attrs: { slot: "reference", disabled: !_vm.enabled },
          slot: "reference",
        },
        [
          _c("i", { staticClass: "fas fa-address-book pull-left" }),
          _vm._v(" "),
          _vm._t("default", function () {
            return [_vm._v("Convert to List")]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }