var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row b-b" }, [
      _c("div", { staticClass: "col-12 p-3" }, [_c("company-form-token")], 1),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 p-3" },
        [
          _c("info-alert-box", {
            attrs: {
              text: "Please note that the RVM API usage is considered automated & paid. You are agreeing to our billing terms by using the API.",
              "kb-link":
                "https://support.aloware.com/en/articles/9033804-aloware-pricing-plans-empowering-your-teams-with-flexible-solutions",
            },
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Example POST URL:")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("span", { staticClass: "text-dark-greenish _600" }, [
              _vm._v(
                _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                  "/api/v1/webhook/rvm-gateway/send"
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _c(
            "pre",
            {
              directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
              key: _vm.renderRefreshToken + "_rvm_a",
            },
            [
              _c("code", { staticClass: "json" }, [
                _vm._v(
                  '{\n    "api_token": "' +
                    _vm._s(_vm.companyToken) +
                    '",\n    "from": "+18552562001", // put one of your line\'s phone numbers here\n    "to": "+18181234567", // put your lead’s phone number here\n    "audio_url": "http://www.yourwebsite.com/MeetingReminder.wav"\n}'
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _c(
            "pre",
            {
              directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
              key: _vm.renderRefreshToken + "_rvm_b",
            },
            [
              _c("code", { staticClass: "json" }, [
                _vm._v(
                  '{\n    "api_token": "' +
                    _vm._s(_vm.companyToken) +
                    '",\n    "line_id": 1234, // Aloware Line ID to send RVM from.\n    "to": "+18181234567", // put your lead’s phone number here\n    "audio_url": "http://www.yourwebsite.com/MeetingReminder.wav"\n}'
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(5),
          _vm._v(" "),
          _c(
            "pre",
            { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
            [
              _c("code", { staticClass: "json" }, [
                _vm._v(
                  '{\n    "Accept": "application/json",\n    "Content-Type": "application/json"\n}'
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(6),
          _vm._v(" "),
          _c(
            "pre",
            { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
            [
              _c("code", { staticClass: "json" }, [
                _vm._v('{\n    "message": "RVM sent."\n}'),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(7),
          _vm._v(" "),
          _c(
            "pre",
            { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
            [
              _c("code", { staticClass: "json" }, [
                _vm._v(
                  '{\n    "message": "The given data was invalid.",\n    "errors": {\n        "from": [\n            "The from field is required when line id is empty."\n        ],\n        "line_id": [\n            "The line_id field is required when line id is empty."\n        ],\n        "to": [\n            "The to field is required."\n        ],\n        "audio_url": [\n            "The audio url field is required."\n        ]\n    }\n}'
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(8),
          _vm._v(" "),
          !_vm.loading_whitelabel
            ? _c(
                "pre",
                {
                  directives: [
                    { name: "highlightjs", rawName: "v-highlightjs" },
                  ],
                  key: _vm.renderRefreshToken + "_rvm_c",
                },
                [
                  _c("code", { staticClass: "bash" }, [
                    _vm._v(
                      "curl -X POST \\\n  " +
                        _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                        "/api/v1/webhook/rvm-gateway/send \\\n  -H 'Accept: application/json' \\\n  -H 'Content-Type: application/json' \\\n  -d '{\n    \"api_token\": \"" +
                        _vm._s(_vm.companyToken) +
                        '",\n    "from": "+18552562001",\n    "to": "+18181234567",\n    "audio_url": "http://www.yourwebsite.com/MeetingReminder.wav"\n  }\''
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._m(9),
          _vm._v(" "),
          !_vm.loading_whitelabel
            ? _c(
                "pre",
                {
                  directives: [
                    { name: "highlightjs", rawName: "v-highlightjs" },
                  ],
                  key: _vm.renderRefreshToken + "_rvm_d",
                },
                [
                  _c("code", { staticClass: "bash" }, [
                    _vm._v(
                      "curl -X POST \\\n  " +
                        _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                        "/api/v1/webhook/rvm-gateway/send \\\n  -H 'Accept: application/json' \\\n  -H 'Content-Type: application/json' \\\n  -d '{\n    \"api_token\": \"" +
                        _vm._s(_vm.companyToken) +
                        '",\n    "line_id": 1234, // Aloware Line ID to send RVM from.\n    "to": "+18181234567",\n    "audio_url": "http://www.yourwebsite.com/MeetingReminder.wav"\n  }\''
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.statics.whitelabel
            ? _c("p", { staticClass: "_600" }, [
                _vm._v(
                  "\n                    If you need more API functions, please contact our support at support@aloware.com .\n                "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2" }, [
      _c("strong", { staticClass: "integration-api-titles" }, [
        _vm._v("Send a RVM to a phone number."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-md" }, [
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("POST /api/v1/webhook/rvm-gateway/send"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Required Fields:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("api_token"),
      ]),
      _vm._v(",\n                    "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("from or line_id"),
      ]),
      _vm._v(",\n                    "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [_vm._v("to")]),
      _vm._v(",\n                    "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("audio_url"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Example POST body:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-muted" }, [
        _vm._v(
          "\n                        The code sample below represents some example JSON with\n                        standard fields to pass in the body of your request in\n                        order to send an RVM:\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Example POST body:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-muted" }, [
        _vm._v(
          "\n                        The code sample below represents some example JSON with\n                        standard fields to pass in the body of your request in\n                        order to send an RVM with Line ID:\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Example POST header:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 202 response on RVM is sent, Example success response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 422 on failure with a detailed response, Example failure response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("cURL Example:")]),
      _vm._v(" "),
      _c("span", { staticClass: "text-muted" }, [
        _vm._v(
          "\n                        With phone number.\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("cURL Example:")]),
      _vm._v(" "),
      _c("span", { staticClass: "text-muted" }, [
        _vm._v("\n                        With Line ID.\n                    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }