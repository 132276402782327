var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-testid": "target-users-tree-wrapper" } },
    [
      !_vm.isForm
        ? [
            _c("p", { staticClass: "_600 mb-0" }, [
              _vm._v("\n            Target Users\n            "),
              _vm.communication.target_users &&
              _vm.communication.target_users.length
                ? _c("span", [_vm._v("(" + _vm._s(_vm.attemptLabel) + ")")])
                : _vm._e(),
              _vm._v(":\n        "),
            ]),
            _vm._v(" "),
            _vm.isTargetUsersWithAttempts
              ? [
                  _c("el-tree", {
                    attrs: {
                      "node-key": "id",
                      data: _vm.generateTargetUsersTree,
                      props: _vm.default_props,
                      "default-expanded-keys": [_vm.communication.attempt],
                      "data-testid": "target-users-tree-no-form-with-attempts",
                      indent: 5,
                    },
                  }),
                ]
              : _vm.isTargetUsersWithNoAttempts
              ? [
                  _c("el-tree", {
                    attrs: {
                      data: _vm.generateTargetUsersTree,
                      props: _vm.default_props,
                      "data-testid":
                        "target-users-tree-no-form-without-attempts",
                      indent: 5,
                    },
                  }),
                ]
              : _c("span", [_vm._v("-")]),
            _vm._v(" "),
            _c("br"),
          ]
        : [
            _c("label", { staticClass: "form-control-label col-xl-5 col-12" }, [
              _vm._v("\n            Target Users\n            "),
              _vm.communication.target_users &&
              _vm.communication.target_users.length
                ? _c("span", [_vm._v("(" + _vm._s(_vm.attemptLabel) + ")")])
                : _vm._e(),
              _vm._v(":\n        "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-items-center col-xl-7 col-12" },
              [
                _vm.isTargetUsersWithAttempts
                  ? [
                      _c("el-tree", {
                        attrs: {
                          "node-key": "id",
                          data: _vm.generateTargetUsersTree,
                          props: _vm.default_props,
                          "data-testid": "target-users-tree-form-with-attempts",
                          "default-expanded-keys": [_vm.communication.attempt],
                          indent: 5,
                        },
                      }),
                    ]
                  : _vm.isTargetUsersWithNoAttempts
                  ? [
                      _c("el-tree", {
                        attrs: {
                          data: _vm.generateTargetUsersTree,
                          props: _vm.default_props,
                          "data-testid":
                            "target-users-tree-form-without-attempts",
                          indent: 5,
                        },
                      }),
                    ]
                  : _c("span", [_vm._v("-")]),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }