<template>
    <div v-if="hasPermissionTo('update lead source')">
        <el-dialog ref="lead-source-dialog"
                   class="dialog-padding auto"
                   :title="title"
                   :width="isLargeEnough ? '500px' : '100%'"
                   :visible.sync="dialogVisible"
                   append-to-body
                   @open="initDialog"
                   @close="resetDialog">
            <el-form ref="lead_source_form"
                     class="p-0"
                     label-position="top"
                     :rules="rules"
                     :model="lead_source"
                     @submit.prevent.native="save">
                <el-form-item prop="name"
                              label="Name">
                    <el-input v-model="lead_source.name"
                              maxlength="190"
                              show-word-limit
                              ref="leadSourceName"
                              @input="preValidateForm('lead_source_form')">
                    </el-input>
                </el-form-item>
                <div class="row pb-3">
                    <div class="col-12 text-left">
                        <el-button type="success"
                                   :disabled="loading_btn || !validated"
                                   class="pull-right ml-2"
                                   @click="save">
                            <i class="material-icons loader"
                               v-show="loading_btn">&#xE863;</i>
                            <i class="material-icons"
                               v-show="!loading_btn">
                                save
                            </i>
                            <span>Save</span>
                        </el-button>
                        <el-button type="info"
                                   @click="dialogVisible = false">
                            <span class="fa fa-close"></span>
                            <span>Cancel</span>
                        </el-button>
                    </div>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import auth from '../../../auth'
import {
    acl_mixin,
    form_validation_mixin
} from '../../../mixins'
import {mapActions} from 'vuex'

export default {
    mixins: [
        acl_mixin,
        form_validation_mixin
    ],

    props: {
        buttonSize: {
            default: 'mini'
        },
        leadSource: {
            required: true,
        }
    },

    data() {
        return {
            auth: auth,
            loading_btn: false,
            lead_source: {
                name: null
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Please provide the lead source name',
                        trigger: 'blur'
                    },
                    {
                        max: 190,
                        message: 'Name is too long',
                        trigger: 'blur'
                    }
                ]
            },
            dialogVisible: false
        }
    },

    computed: {
        title() {
            const action = this.leadSource ? 'Edit' : 'Create'
            return action + ' Lead Source'
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        }
    },

    methods: {
        initDialog() {
            // keep tempalte object empty if we're not editting
            if (!this.leadSource) {
                return
            }

            // populate the lead source object with values
            this.lead_source = {
                name: this.leadSource.name
            }

            setTimeout(() => {
                this.$refs.leadSourceName.focus()
                this.preValidateForm('lead_source_form')
                this.validated = false
            })
        },

        save() {
            if (!this.validateForm('lead_source_form')) {
                return
            }

            this.loading_btn = true

            axios.patch('/api/v1/lead-sources/' + this.leadSource.id, this.lead_source)
                .then(res => {
                    this.loading_btn = false
                    this.dialogVisible = false
                    this.$notify({
                        offset: 95,
                        title: 'Lead Source',
                        message: 'Lead Source updated successfully',
                        type: 'success'
                    })
                    this.updateLeadSource(res.data)
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_btn = false
                })
        },

        resetDialog() {
            this.lead_source = {
                name: null,
            }
            this.preValidateForm('lead_source_form', true)

            if (this.template_id) {
                this.$emit('clearEdit')
            }
        },

        edit() {
            this.dialogVisible = true
        },

        closeDialog() {
            this.$refs['lead-source-dialog'].close()
        },

        ...mapActions(['updateLeadSource'])
    }
}
</script>
