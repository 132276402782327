<template>
    <div>
        <el-dialog title="Terms & Conditions"
                   class="terms-conditions-dialog"
                   :visible.sync="terms_dialog"
                   :append-to-body="true"
                   :close-on-click-modal="false"
                   :close-on-press-escape="false"
                   :show-close="is_impersonating">
            <div slot="title">
                <h5 class="mb-0">
                    <i class="fa fa-file-signature"></i>
                    Terms & Conditions
                </h5>
            </div>

            <p class="text-md _400 mb-1">By accepting this, you certify that you have read and will adhere to our
                <a :href="termsLink"
                   class="text-dark-greenish"
                   target="_blank">
                    Terms & Conditions.
                </a>
            </p>

            <p class="text-md _400">
                We are electronically capturing your consent as of {{ auth.user.profile.name }} ({{
                auth.user.profile.email }}) on {{ date }}
            </p>

            <iframe sandbox="allow-same-origin"
                    class="terms-iframe mt-2 mb-2"
                    :src="termsLink + '?embedded=1'"
                    v-if="!isSimpSocial">
            </iframe>

            <el-checkbox class="checkbox-md mt-2"
                         v-model="terms_accepted">
                I have read and accept the terms and conditions
            </el-checkbox>

            <span slot="footer"
                  class="dialog-footer">
                <el-button type="success"
                           :disabled="!terms_accepted"
                           @click="saveAgreeToTerms">
                    Agree
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import auth from '../auth'
import { acl_mixin, helper_mixin } from '../mixins'
import { mapState, mapGetters } from "vuex"

export default {
    mixins: [
        acl_mixin,
        helper_mixin
    ],

    data() {
        return {
            auth: auth,
            terms_accepted: false,
            terms_dialog: !auth.user.profile.agreed_to_terms,
            date: new Date().toLocaleString('en-US', {timeStyle: "short"}),
            is_impersonating: !!localStorage.getItem('is_admin_impersonating')
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        ...mapGetters('cache', ['isSimpSocial']),

        termsLink() {
            if (this.isSimpSocial) {
                return 'https://simpsocial.com/terms-and-conditions'
            }

            return 'https://aloware.com/terms-and-conditions'
        },
    },

    methods: {
        saveAgreeToTerms() {
            return axios.post('/api/v1/user/' + auth.user.profile.id + '/agree-to-terms').then((res) => {
                this.terms_accepted = true
                this.$emit('loaded')
                this.terms_dialog = false
            }).catch((err) => {
                return Promise.reject(err)
            })
        },
    }
}
</script>
