<template>
    <div>
        <el-dialog top="15vh"
                   width="35vw"
                   custom-class="dialog-no-border text-center team-dialog"
                   data-testid="restriction-message-dialog"
                   append-to-body
                   :visible.sync="dialogVisible"
                   :show-close="false">
            <div class="px-5 pb-3">
                <h4 class="text-dark pb-3">Convert users into a Team</h4>
                <span class="text-dark px-3 d-inline-block">Create a new team "{{ teamName }}" <br/>
                    The following users will be added to the team: </span>
                <div class="d-flex justify-content-center mt-3 text-dark text-left">
                    <ul>
                        <li :key="index"
                            v-for="(user, index) in users">
                            {{ user.name }}
                        </li>
                    </ul>
                </div>
                <div class="row py-3 justify-content-center">
                    <el-button class="mr-4"
                               plain
                               @click="closeModal()">
                        Cancel
                    </el-button>
                    <el-button type="success"
                               @click="addToTeam()">
                        Confirm
                    </el-button>
                </div>
            </div>
        </el-dialog>
        <img :src="teamImg"
             @click="openModal()"/>
    </div>
</template>

<script>
import {mapState} from "vuex"
import {MAX_TEAMS_ALLOWED} from '../constants/teams/teams';

export default {
    name: "dialog-team-creator",

    props: {
        disabled: {
            default: false
        },
        layer: {
            required: true
        },
        ringGroupName: {
            required: true
        },
        teams: {
            required: true,
            default: []
        },
        users: {
            required: true,
            default: []
        },
    },

    data () {
        return {
            dialogVisible: false
        }
    },

    methods: {
        openModal () {
            if(this.users.length > 0 && !this.disabled){

                if(this.all_teams.length >= MAX_TEAMS_ALLOWED){
                    this.$alert(`Sorry, there are a maximum of ${MAX_TEAMS_ALLOWED} teams, please delete teams to add more`, 'Oops!', {
                        confirmButtonText: 'Ok',
                        type: 'error',
                        center: false,
                        callback: cancel => {
                        }
                    })
                }else{
                    this.dialogVisible = true
                }
            }
        },

        closeModal (){
            this.dialogVisible = false
        },

        addToTeam () {
            this.$emit('addToTeam', this.teamName)
            this.closeModal()
        }
    },

    computed: {
        ...mapState(['all_teams']),

        teamImg () {
            return '/assets/images/app-icons/' + (this.users.length > 0 ? 'icon-teams-enable.svg' : 'icon-teams.svg')
        },

        teamName () {
            const teamName = `Team Layer ${this.layer} ${this.ringGroupName}`
            let uniqueTeamName = `${teamName}${this.teams.length > 0 ? ` (${this.teams.length})` : ''}`
            let attempt = 1

            while (this.teams.find(team => team.name.replace(/\s+/g, '') === uniqueTeamName.replace(/\s+/g, ''))){
                uniqueTeamName = `${teamName} (${this.teams.length + attempt})`
                attempt++
            }

            return uniqueTeamName
        }
    }
}
</script>
