var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      staticClass: "p-0",
      attrs: {
        placement: _vm.popover_placement,
        width: "400",
        trigger: "click",
        "popper-class": "variable-popover",
      },
      on: { show: _vm.popoverShow },
    },
    [
      _c("variables-dropdown", {
        ref: "variables_dropdown",
        attrs: { mode: "return" },
        on: { "variable-selected": _vm.emitSelectedVariable },
      }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          class: _vm.variable_btn_class,
          attrs: {
            slot: "reference",
            id: "variable-popover-trigger",
            size: "mini",
            circle: "",
            disabled: _vm.disabled,
          },
          slot: "reference",
        },
        [_c("i", { class: "" + _vm.icon })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }