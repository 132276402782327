<template>
    <div v-loading="loading">
        <div class="card">
            <div class="card-header p-4 text-center bg-white"
                 style="border-bottom: none">
                <h3>Outbound SMS Compliance</h3>
            </div>
            <div class="card-body">
                <div class="p-4">
                    <div class="mb-2">Group by :</div>
                    <div class="d-flex flex-row justify-content-between">
                        <div class="d-flex flex-row">
                            <el-select class="mr-2"
                                       v-model="group_by">
                                <el-option v-for="item in group_by_list"
                                           :key="item"
                                           :value="item"
                                           :label="item.capitalize()">
                                </el-option>
                            </el-select>
                            <campaign-selector v-model="selected_line"
                                               v-if="group_by === 'date'"
                                               clearable
                                               @clear="clearLine"
                                               @change="lineChanged">
                            </campaign-selector>
                        </div>
                        <el-button type="success"
                                   size="small"
                                   @click="exportReport">
                            Export
                        </el-button>
                    </div>
                </div>
                <highcharts :options="graph_options"
                           ref="highchart"
                           v-show="data.length > 1">
                </highcharts>
                <div class="mt-2 p-4">
                    <div style="min-height: 500px;"
                         v-loading="loading_table">
                        <el-table ref="activity-reporting-2"
                                  class="w-full mt-3 report-dispositions-table"
                                  :default-sort="{prop: group_by, order: 'descending'}"
                                  :data="data"
                                  v-if="data.length > 0">
                            <el-table-column
                                :fixed="['date','line_name'].includes(key) ? 'left' : false"
                                sortable
                                :sort-method="sortTable"
                                min-width="150"
                                :prop="key"
                                :label="key | columnLabel"
                                v-for="key in dataKeys"
                                :key="key">
                                <template v-slot="scope">
                                    <span v-if="key === 'date'">
                                        {{scope.row[key] | dateFormat}}
                                    </span>
                                    <span v-else>
                                        {{scope.row[key]}}
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="p-2 text-center"
                             v-if="data.length === 0">
                            <img-empty class="w-25 mt-5 m-auto"></img-empty>
                            <p class="lead">No data found from the selected date range</p>
                        </div>
                    </div>
                    <div class="mt-3">
                        <el-pagination  class="pull-right"
                                        layout="sizes, prev, pager, next"
                                        background
                                        :total="pagination.total"
                                        :page-size="pagination.per_page"
                                        :page-sizes="[20, 35, 50, 100, 250]"
                                        v-if="pagination.total > pagination.per_page"
                                        @next-click="nextPage"
                                        @prev-click="prevPage"
                                        @current-change="changePage"
                                        @size-change="paginationChangeSize">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ImgEmpty from "../misc/img-empty";
import _ from "lodash";
import CampaignSelector from "../campaign-selector";
import {Notification} from "element-ui";
import {date_mixin} from "../../mixins";

export default {
    name: "reports-compliance",

    mixins: [date_mixin],

    components: {CampaignSelector, ImgEmpty},

    props: {
        show_graph: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            loading: false,
            loading_table: false,
            loading_graph: false,
            selected_line: null,
            data: [],
            headers: [],
            pagination: {
                per_page: 20,
                current_page: 1,
                path: null,
                first_page_url: null,
                next_page_url: null,
                prev_page_url: null,
                total: 0
            },
            group_by: 'date',
            group_by_list: [
                'date', 'line'
            ],
            graph_options: {
                chart: {
                    type: 'areaspline',
                },

                title: {
                    text: ''
                },

                rangeSelector: {
                    enabled: false
                },

                plotOptions: {
                    areaspline: {
                        fillOpacity: 0.3
                    }
                },

                xAxis: {
                    type: 'category',
                    categories: [],
                    gridLineWidth: 1,
                    gridLineColor: '#f6f6f6',
                    min: 0.5,
                    tickInterval: 2,
                    maxPadding: 0,
                    endOnTick: false,
                    startOnTick: false,
                    labels: {
                        enabled: true
                    },
                    max: 12,
                    scrollbar: {
                        enabled: true,
                    },
                    tickLength: 0
                },

                yAxis: {
                    allowDecimals: false,
                    title: {
                        text: null,
                        style: {
                            'font-size': '14px',
                            'color': '#090A0D'
                        }
                    },
                    gridLineColor: 'transparent',
                    lineColor: 'transparent',
                    gridLineWidth: 0,
                },

                tooltip: {
                    useHTML: true,
                    shared: true,
                    style: {
                        color: '#2e3e4e',
                        fontSize: '14px'
                    },
                    formatter: function () {
                        let s = []
                        s.push(`<span class="text-grey-900 mt-0 mb-3" style="font-size: 0.65rem">${moment(this.x).format('dddd, MMMM D YYYY').toString()}</span>`)
                        for (let point of this.points.sort((a, b) => b.y - a.y)) {
                            s.push(`<i class="fa fa-circle" style="color: ${point.series.color};"></i><span class="text-grey-900" style="font-size: 0.875rem"> ${point.series.name}: <b>${point.y.toLocaleString()}</b></span>`)
                        }
                        s = s.join("</br>")
                        return s
                    }
                },

                credits: {
                    enabled: false
                },

                exporting: {
                    enabled: false
                },

                legend: {
                    enabled: true
                },

                series: []
            },
        }
    },

    filters: {
        columnLabel(val) {
            return val.split('_')[0].capitalize()
        },

        dateFormat(val) {
            return moment(val).format('ll')
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),

        dataKeys() {
            if (this.data.length > 0) {
                let dynamic_keys = _.keys(this.data[0])
                dynamic_keys = dynamic_keys.filter(k => !this.headers.includes(k))

                return this.headers.concat(dynamic_keys)
            }

            return []
        },
    },

    mounted() {
        this.fetchComplianceReport()
        this.loadGraph()
    },

    methods: {
        clearLine() {
            this.selected_line = null
            this.fetchComplianceReport()
            this.loadGraph()
        },

        sortTable(a, b) {
            // For Date
            if (this.group_by === 'date') {
                return new Date(a.date).getTime() - new Date(b.date).getTime()
            }
            // For Agent
            if (this.group_by === 'line') {
                return a.line_name < b.line_name
            }
        },

        changePage(val) {
            let url = this.pagination.path + '?page=' + val
            this.fetchComplianceReport(url)
        },

        paginationChangeSize(val) {
            this.pagination.per_page = val
            this.fetchComplianceReport()
        },

        nextPage() {
            let url = this.pagination.next_page_url
            this.fetchComplianceReport(url)
        },

        prevPage() {
            let url = this.pagination.prev_page_url
            this.fetchComplianceReport(url)
        },

        lineChanged(line) {
            this.selected_line = line
        },

        exportReport() {
            axios.get('/api/v1/reports/compliance/' + this.group_by + '/export')
            .then(res => {
                this.$notify.info({
                    offset: 95,
                    title: 'Exports',
                    dangerouslyUseHTMLString: true,
                    message: `Your export is on its way. You can check the progress from the export page or <b class="text-dark-greenish pointer">click here</b>.`,
                    duration: 3000,
                    onClick: () => {
                        this.$router.push({name: 'Account', query: {tab: 'exports'}}).catch((err) => {
                        })
                        Notification.closeAll()
                    }
                })
            })
        },

        generateGraphSeries(data) {
            if (data && Array.isArray(data) && data.length > 0) {
                let x_axis_categories = []
                let total = []
                let completed = []
                let failed = []
                // prepare min & max of time range
                let min_date, max_date

                if (this.filter.from_date) {
                    min_date = moment(this.filter.from_date).startOf('day') // moment
                }
                if (this.filter.to_date) {
                    max_date = moment(this.filter.to_date).startOf('day') // moment object
                }

                for (let d of data) {
                    total.push(parseInt(d.total))
                    completed.push(parseInt(d.completed))
                    failed.push(parseInt(d.failed))

                    switch (this.group_by) {
                        case 'date':
                            this.graph_options.chart.type = 'areaspline'
                            x_axis_categories.push(d.date)
                            break;
                        case 'line':
                            this.graph_options.chart.type = 'column'
                            x_axis_categories.push(d.line_name)
                            break;
                    }
                }

                this.graph_options.xAxis.categories = x_axis_categories.sort((a, b) => {
                    return new Date(a) - new Date(b)
                })
                this.graph_options.xAxis.max = data.length > 20 ? 20 : data.length
                this.graph_options.series = []

                this.graph_options.series.push({
                    name: 'Completed',
                    data: completed,
                    color: '#00BF50'
                })
                this.graph_options.series.push({
                    name: 'Failed',
                    data: failed,
                    color: '#ff00007d'
                })
            } else {
                this.graph_options.xAxis.categories = []
                this.graph_options.series = []
            }
        },

        loadGraph() {
            this.loading_graph = true
            let endpoint = '/api/v1/reports/compliance/' + this.group_by

            axios.get(endpoint, {
                params: {
                    line_id: this.selected_line,
                    paginate: false,
                    from_date: this.filter.from_date,
                    to_date: this.filter.to_date
                }
            }).then(res => {
                this.loading_graph = false
                this.generateGraphSeries(res.data)
            }).catch(err => {
                this.loading_graph = false
            })
        },

        fetchComplianceReport(url = '') {
            this.loading = true
            let endpoint = url ? url : '/api/v1/reports/compliance/' + this.group_by

            axios.get(endpoint, {
                params: {
                    from_date: this.filter.from_date,
                    to_date: this.filter.to_date,
                    per_page: this.pagination.per_page,
                    line_id: this.selected_line
                }
            }).then(res => {
                this.loading = false
                switch (this.group_by) {
                    case 'date':
                        this.headers = [
                            'date', 'completed', 'failed', 'total'
                        ]
                        break;
                    case 'line':
                        this.headers = [
                            'line_name', 'completed', 'failed', 'total'
                        ]
                        break;
                }

                this.data = res.data.data
                this.pagination.per_page = parseInt(res.data.per_page)
                this.pagination.current_page = parseInt(res.data.current_page)
                this.pagination.path = res.data.path
                this.pagination.first_page_url = res.data.first_page_url
                this.pagination.prev_page_url = res.data.prev_page_url
                this.pagination.next_page_url = res.data.next_page_url
                this.pagination.total = parseInt(res.data.total)

                this.loading_table = false
            })
        }
    },

    watch: {
        'filter.from_date': function() {
            this.fetchComplianceReport()
            this.loadGraph()
        },

        group_by() {
            this.fetchComplianceReport()
            this.loadGraph()
        },

        selected_line() {
            if (this.selected_line) {
                this.fetchComplianceReport()
                this.loadGraph()
            }
        }
    }
}
</script>

<style scoped>

</style>
