var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "70vw",
        top: "4vh",
        visible: _vm.modal_visible,
        "append-to-body": true,
        "close-on-click-modal": false,
        "before-close": _vm.beforeClose,
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("h5", { staticClass: "mb-0" }, [
          _vm.mode === _vm.MODE_ADD
            ? _c("span", [_vm._v("Add New")])
            : _vm._e(),
          _vm._v(" "),
          _vm.mode === _vm.MODE_EDIT ? _c("span", [_vm._v("Edit")]) : _vm._e(),
          _vm._v("\n            Webhook\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("el-form", { ref: "webhook_form", staticClass: "form-aloware" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _vm.mode === _vm.MODE_EDIT
                ? _c("div", [
                    _vm.webhook.status === _vm.Webhook.STATUS_UNTESTED
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-warning alert-aloware p-2",
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-exclamation-triangle",
                            }),
                            _vm._v(
                              " This webhook is untested and will not dispatch an event.\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.webhook.status === _vm.Webhook.STATUS_VALID
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-success alert-aloware p-2",
                          },
                          [
                            _c("i", { staticClass: "fa fa-check" }),
                            _vm._v(
                              " This webhook is tested and validated.\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.webhook.status === _vm.Webhook.STATUS_INVALID
                      ? _c(
                          "div",
                          {
                            staticClass: "alert alert-danger alert-aloware p-2",
                          },
                          [
                            _c("i", { staticClass: "fa fa-remove" }),
                            _vm._v(
                              " This webhook failed the test. Please make sure the webhook end-point is valid URL.\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.webhook.status === _vm.Webhook.STATUS_DISABLED
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-warning alert-aloware p-2",
                          },
                          [
                            _c("i", { staticClass: "fa fa-ban" }),
                            _vm._v(
                              "\n                        This webhook has been disabled.\n                        You can enable this by pressing the Test & Save button.\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "no-border",
                  attrs: { error: _vm.errors.first("name"), prop: "name" },
                },
                [
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("Name")]),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { placeholder: "Name of the Webhook" },
                    model: {
                      value: _vm.webhook.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.webhook, "name", $$v)
                      },
                      expression: "webhook.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("Description")]),
                    _vm._v(" "),
                    _c("small", [_vm._v("Describe what this webhook is for.")]),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 2 },
                    model: {
                      value: _vm.webhook.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.webhook, "description", $$v)
                      },
                      expression: "webhook.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "no-border",
                  attrs: { error: _vm.errors.first("url"), prop: "url" },
                },
                [
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("URL")]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v("Provide a valid webhook endpoint URL."),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.webhook.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.webhook, "url", $$v)
                      },
                      expression: "webhook.url",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("Authentication Method")]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "Select your webhook endpoint authentication method."
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      model: {
                        value: _vm.webhook.http_auth_method,
                        callback: function ($$v) {
                          _vm.$set(_vm.webhook, "http_auth_method", $$v)
                        },
                        expression: "webhook.http_auth_method",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: _vm.Webhook.HTTP_AUTH_METHOD_NONE } },
                        [_vm._v("None")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        {
                          attrs: { label: _vm.Webhook.HTTP_AUTH_METHOD_BASIC },
                        },
                        [_vm._v("Basic")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        {
                          attrs: { label: _vm.Webhook.HTTP_AUTH_METHOD_BEARER },
                        },
                        [_vm._v("Bearer")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _vm.webhook.http_auth_method ===
                    _vm.Webhook.HTTP_AUTH_METHOD_BASIC
                      ? _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "no-border",
                                  attrs: {
                                    error: _vm.errors.first(
                                      "credentials.username"
                                    ),
                                  },
                                },
                                [
                                  _c("div", { staticClass: "form-label" }, [
                                    _c("label", [_vm._v("Username")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    model: {
                                      value: _vm.webhook.credentials.username,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.webhook.credentials,
                                          "username",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "webhook.credentials.username",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "no-border",
                                  attrs: {
                                    error: _vm.errors.first(
                                      "credentials.password"
                                    ),
                                  },
                                },
                                [
                                  _c("div", { staticClass: "form-label" }, [
                                    _c("label", [_vm._v("Password")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    model: {
                                      value: _vm.webhook.credentials.password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.webhook.credentials,
                                          "password",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "webhook.credentials.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.webhook.http_auth_method ===
                    _vm.Webhook.HTTP_AUTH_METHOD_BEARER
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "no-border",
                                attrs: {
                                  error: _vm.errors.first(
                                    "credentials.api_token"
                                  ),
                                },
                              },
                              [
                                _c("div", { staticClass: "form-label" }, [
                                  _c("label", [_vm._v("API Bearer Token")]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(
                                      "Enter the API token of your webhook endpoint."
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  model: {
                                    value: _vm.webhook.credentials.api_token,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.webhook.credentials,
                                        "api_token",
                                        $$v
                                      )
                                    },
                                    expression: "webhook.credentials.api_token",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { error: _vm.errors.first("delay"), prop: "events" } },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "mr-3" },
                      [
                        _c("el-switch", {
                          attrs: { "active-color": "#00a344" },
                          on: { change: _vm.changeDelay },
                          model: {
                            value: _vm.delay_enabled,
                            callback: function ($$v) {
                              _vm.delay_enabled = $$v
                            },
                            expression: "delay_enabled",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-label" }, [
                      _c("label", [_vm._v("Delay")]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "The delay in seconds before sending the webhook"
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.delay_enabled
                    ? _c(
                        "div",
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, max: 900 },
                            model: {
                              value: _vm.webhook.delay,
                              callback: function ($$v) {
                                _vm.$set(_vm.webhook, "delay", $$v)
                              },
                              expression: "webhook.delay",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.events_loading,
                      expression: "events_loading",
                    },
                  ],
                  staticClass: "no-border",
                  attrs: { error: _vm.errors.first("events"), prop: "events" },
                },
                [
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("Events")]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "Select the events you want to receive on your webhook."
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    _vm._l(_vm.events, function (event) {
                      return _c(
                        "el-checkbox",
                        {
                          key: event.key,
                          staticClass: "w-full checkbox-label-description",
                          attrs: {
                            label: event.key,
                            name: "events",
                            border: "",
                          },
                          model: {
                            value: _vm.webhook.events,
                            callback: function ($$v) {
                              _vm.$set(_vm.webhook, "events", $$v)
                            },
                            expression: "webhook.events",
                          },
                        },
                        [
                          _c("label", [_vm._v(_vm._s(event.name))]),
                          _vm._v(" "),
                          _c("small", [_vm._v(_vm._s(event.description))]),
                        ]
                      )
                    }),
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c(
                "el-form-item",
                { staticClass: "no-border" },
                [
                  _c("webhook-filter-manager", {
                    key: _vm.tick,
                    model: {
                      value: _vm.webhook,
                      callback: function ($$v) {
                        _vm.webhook = $$v
                      },
                      expression: "webhook",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("el-button", { on: { click: _vm.beforeClose } }, [
              _vm._v("Close"),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.saving,
                    expression: "saving",
                  },
                ],
                staticClass: "ml-auto",
                attrs: { type: "success" },
                on: { click: _vm.save },
              },
              [_c("strong", [_vm._v("Save and Test Webhook")])]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }