<template>
    <div v-if="hasPermissionTo('list workflow')">
        <el-select v-model="workflow_id"
                   class="w-full"
                   :placeholder="placeholder"
                   :multiple="multiple"
                   :multiple-limit="multiple ? multipleLimit : 0"
                   :size="size"
                   filterable
                   clearable
                   data-testid="workflow-selector"
                   default-first-option
                   collapse-tags>
            <el-option-group key="Active Sequences"
                             label="Active Sequences"
                             v-if="activeWorkflowsAlphabeticalOrder && activeWorkflowsAlphabeticalOrder.length > 0">
                <el-option
                    v-for="workflow in activeWorkflowsAlphabeticalOrder"
                    :key="workflow.id"
                    :label="workflow.name"
                    :value="workflow.id">
                </el-option>
            </el-option-group>
            <el-option-group key="Paused Sequences"
                             label="Paused Sequences"
                             v-if="pausedWorkflowsAlphabeticalOrder && pausedWorkflowsAlphabeticalOrder.length > 0">
                <el-option
                    v-for="workflow in pausedWorkflowsAlphabeticalOrder"
                    :key="workflow.id"
                    :label="workflow.name"
                    :value="workflow.id">
                </el-option>
            </el-option-group>
        </el-select>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../auth'
import {acl_mixin} from '../mixins'

export default {
    mixins: [acl_mixin],

    props: {
        value: {
            required: false,
        },

        exclude: {
            required: false,
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        multipleLimit: {
            type: Number,
            required: false,
            default: 0
        },

        size: {
            type: String,
            default: '',
            required: false
        }
    },

    data() {
        return {
            auth: auth,
            workflow_id: this.value,
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            workflows: state => state.workflows
        }),

        placeholder() {
            if (this.multiple) {
                return 'Select Sequences'
            }

            return 'Select Sequence'
        },

        availableWorkflows() {
            if (this.workflows) {
                return this.workflows.filter((workflow) => {
                    return workflow.id != this.exclude
                })
            }

            return []
        },

        workflowsAlphabeticalOrder() {
            if (this.availableWorkflows) {
                let workflows = this.availableWorkflows
                return workflows.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },

        activeWorkflowsAlphabeticalOrder() {
            if (this.workflowsAlphabeticalOrder.length) {
                let workflows = this.workflowsAlphabeticalOrder
                return workflows.filter(workflow => workflow.active == true)
            }

            return []
        },

        pausedWorkflowsAlphabeticalOrder() {
            if (this.workflowsAlphabeticalOrder.length) {
                let workflows = this.workflowsAlphabeticalOrder
                return workflows.filter(workflow => workflow.active == false)
            }

            return []
        }
    },

    watch: {
        value() {
            this.workflow_id = this.value
        },

        workflow_id(val) {
            // since we are utilizing the v-model two way binding in this component
            // we should try to consider updating/syncing with the parent's data variable whenever
            // data changed in this component
            this.$emit('input', val)

            if (this.value !== undefined && this.workflow_id !== this.value) {
                this.$emit('change', val)
            }
        }
    }
}
</script>
