export default Object.freeze([
  {
    value: 'EIN',
    label: 'USA: Employer Identification Number (EIN)'
  },
  {
    value: 'BIN',
    label: 'Canada: Business Number (BIN)'
  },
  {
    value: 'CN',
    label: 'United Kingdom: Company Number'
  },
  {
    value: 'ACN',
    label: 'Australia: Company Number from ASIC (ACN)'
  },
  {
    value: 'CIN',
    label: 'India: Corporate Identity Number'
  },
  {
    value: 'VAT',
    label: 'Estonia: VAT Number'
  },
  {
    value: 'VATRN',
    label: 'Romania: VAT Registration Number'
  },
  {
    value: 'RN',
    label: 'Israel: Registration Number'
  },
  {
    value: 'Other',
    label: 'Other'
  }
])
