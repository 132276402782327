// the endpoint's base url
let base_path = `/api/v2/filters`

export default {
    /**
     * Fetch company filters
     *
     * @param params
     *
     * @handler Api/V2/Filters/FilterController@index
     *
     * @returns Promise
     */
    fetchFilters(params) {
        return axios.get(`${base_path}`, {params: params})
    },

    /**
     * Save new company filter. This route is mainly utilized to create new company wide filter.
     *
     * @param post_data
     *
     * @handler Api/V2/Filters/FilterController@create
     *
     * @return Promise
     */
    createFilter(post_data) {
        return axios.post(`${base_path}`, post_data)
    },

    /**
     * Save new company filter. This route is mainly utilized to create new company wide filter.
     *
     * @param id
     * @param post_data
     *
     * @handler Api/V2/Filters/FilterController@update
     *
     * @return Promise
     */
    updateFilter(id, post_data) {
        return axios.put(`${base_path}/${id}`, post_data)
    },

    /**
     * Delete a company wide filter.
     *
     * @param id
     *
     * @handler Api/V2/Filters/FilterController@delete
     *
     * @return Promise
     */
    deleteFiler(id) {
        return axios.delete(`${base_path}/${id}`)
    }
}
