var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("iframe", {
      staticStyle: { width: "100%", "min-height": "68vh", border: "0" },
      attrs: { src: _vm.source },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }