var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.broadcast
    ? _c(
        "div",
        { staticClass: "app-body", attrs: { id: "view" } },
        [
          _c(
            "div",
            {
              staticClass: "fixed-header padding pt-0 pb-0",
              style: { top: _vm.top_offset + 50 + "px" },
            },
            [
              _c("div", { staticClass: "row mb-0 mt-0" }, [
                _c(
                  "div",
                  { staticClass: "col-12 p-2" },
                  [
                    _c("comm-advanced-filters-wrapper", {
                      attrs: { broadcast_id: _vm.broadcast_id },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "padding mt-5 pt-3 pb-0 row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [_c("communication-textual-filters")],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.hasPermissionTo("list report") && _vm.show_graph
            ? _c("div", { staticClass: "padding pt-0" }, [
                _c("div", { staticClass: "row box" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "no-effect" }, [
                      _c(
                        "div",
                        { staticClass: "box-body pt-0" },
                        [
                          _c("activity-graph", {
                            attrs: {
                              base: "broadcast",
                              broadcast_id: _vm.broadcast_id,
                              is_first_load: _vm.reports_first_load,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermissionTo("list communication")
            ? _c("div", { staticClass: "padding pt-0" }, [
                _c("div", { staticClass: "row box" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "no-effect" }, [
                      _c(
                        "div",
                        { staticClass: "box-body pt-0" },
                        [
                          _c("comm-log-table", {
                            attrs: { broadcast_id: _vm.broadcast_id },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-backtop", {
            ref: "backtop",
            attrs: { target: "html", bottom: 20, right: 20 },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }