var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.communication && _vm.communication.contact
    ? _c("div", [
        _c("p", [
          _vm._v("\n        You have a new voicemail from\n        "),
          _c("b", [
            _vm._v(
              _vm._s(
                _vm._f("fixContactName")(
                  _vm.sanitizeText(_vm.communication.contact.name)
                )
              ) +
                " " +
                _vm._s(
                  _vm._f("fixPhone")(_vm.communication.contact.phone_number)
                )
            ),
          ]),
          _vm._v("\n        on\n        "),
          _vm.getCampaign(_vm.communication.campaign_id)
            ? _c("b", [
                _vm._v(
                  _vm._s(_vm.getCampaign(_vm.communication.campaign_id).name)
                ),
              ])
            : _vm._e(),
          _vm._v("\n        line.\n    "),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn rounded greenish btn-listen btn-sm mt-2 pull-right",
          },
          [_vm._v("\n        Listen\n    ")]
        ),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        Notes: " + _vm._s(_vm.communication.notes) + "\n    "
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }