<template>
    <div class="sms-reminders-wrapper mt-4 border-0 p-0">
        <div class="text-center">
            <div v-if="!campaign_id">
                <el-select class="p-1"
                           filterable
                           placeholder="Send from"
                           v-model="selected_campaign">
                    <el-option :key="campaign.id"
                               :label="campaign.name"
                               :value="campaign.id"
                               v-for="campaign in campaigns">
                    </el-option>
                </el-select>
            </div>
            <el-tooltip effect="dark mb-4"
                        placement="top"
                        content="The contact must have a timezone to send an SMS reminder."
                        :disabled="!disableSendSmsReminderButton">
                <span>
                    <el-button v-loading="loading"
                               v-if="show_send_sms_reminder_button"
                               :disabled="disableSendSmsReminderButton"
                               type="primary"
                               @click="sendDefaultSmsReminder">
                        Send SMS reminder now
                    </el-button>
                </span>
            </el-tooltip>
        </div>
    </div>
</template>
<script>
import auth from '../auth'
import {mapState} from "vuex"

export default {
    props: {
        communication_id: {
            required: true
        },

        campaign_id: {
            required: true
        },

        appointment_datetime: {
            required: true
        },

        contact: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            loading: false,
            selected_campaign: this.campaign_id,
            show_send_sms_reminder_button: false,
        }
    },

    computed: {
        ...mapState(['campaigns']),

        disableSendSmsReminderButton() {
            return this.contact && !this.contact.timezone
        }
    },

    mounted() {
        this.showSendSmsReminderButton()
    },

    methods: {
        showSendSmsReminderButton() {
            let appointment_date = moment(new Date(this.appointment_datetime)).tz('UTC')
            this.show_send_sms_reminder_button = appointment_date.isAfter()
        },

        sendDefaultSmsReminder() {
            if (!this.selected_campaign) {
                this.$notify({
                    duration: 2500,
                    title: 'Line is required',
                    message: 'Please select a line where to send from.',
                    type: 'warning',
                    showClose: true,
                    position: 'top-right'
                })
            }

            if (this.selected_campaign) {
                this.loading = true
                axios
                .post(`/api/v1/communications/${this.communication_id}/send-sms-reminder`, {
                    campaign_id: this.selected_campaign
                })
                .then(res => {
                    this.loading = false
                    switch (res.status) {
                        case 200:
                            this.$notify({
                                duration: 5000,
                                title: 'SMS reminder',
                                message: 'SMS reminder is sent.',
                                type: 'success',
                                showClose: true,
                                position: 'top-right'
                            })
                            break
                        default:
                            this.$notify({
                                duration: 5000,
                                title: 'Sending SMS reminder',
                                message: res.data.message,
                                type: 'error',
                                showClose: true,
                                position: 'top-right'
                            })
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.$notify({
                        duration: 5000,
                        title: 'Sending SMS reminder',
                        message: 'Something went wrong.',
                        type: 'error',
                        showClose: true,
                        position: 'top-right'
                    })
                })
            }
        }
    }
}
</script>
