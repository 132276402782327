var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pagination
    ? _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "el-button-group",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "medium",
                    disabled: !_vm.pagination.prev_page_url,
                  },
                  on: { click: _vm.prevPage },
                },
                [
                  _c("i", { staticClass: "fa fa-caret-left" }),
                  _vm._v(" Prev\n        "),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "medium",
                    disabled: !_vm.pagination.next_page_url,
                  },
                  on: { click: _vm.nextPage },
                },
                [
                  _vm._v("\n            Next "),
                  _c("i", { staticClass: "fa fa-caret-right" }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }