var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "container-form-height-indicator",
          style: "bottom: " + _vm.bottom + "px",
          on: { click: _vm.scrollToBottom },
        },
        [_vm._m(0)]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "btn-form-height-indicator", attrs: { type: "button" } },
      [_c("i", { staticClass: "fa fa-arrow-down" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }