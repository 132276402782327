export const STATUS_STAGED = 1 // internal use only, not applicable to Twilio API calls
export const STATUS_DRAFT = 2
export const STATUS_PENDING = 3
export const STATUS_IN_REVIEW = 4
export const STATUS_REJECTED = 5
export const STATUS_APPROVED = 6
export const STATUS_PROVISIONALLY_APPROVED = 7
export const STATUS_EXPIRED = 8
export const STATUS_DELETED = 9
export const STATUS_SUSPENDED = 10
