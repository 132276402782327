var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body" }, [
    _c(
      "div",
      { staticClass: "teams" },
      [
        !_vm.isEditingTeam
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between px-3 pt-3",
                },
                [
                  _c("el-input", {
                    staticClass: "w-50",
                    attrs: {
                      placeholder: "Search Teams",
                      "prefix-icon": "el-icon-search",
                      clearable: "",
                    },
                    on: { input: _vm.onSearchInput },
                    model: {
                      value: _vm.searchInput,
                      callback: function ($$v) {
                        _vm.searchInput = $$v
                      },
                      expression: "searchInput",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex w-50 justify-content-end" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "w-25",
                          attrs: {
                            slot: "reference",
                            type: "success",
                            "data-testid": "team-creator-open-modal-button",
                          },
                          on: { click: _vm.openTeamModalForm },
                          slot: "reference",
                        },
                        [
                          _vm._v(
                            "\n                        Create New Team\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-end py-3 px-3",
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      layout: "sizes, prev, pager, next",
                      "current-page": _vm.teamsPagination.page,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.teamsPagination.size,
                      total: _vm.teamsPagination.total,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.teamsPagination, "page", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.teamsPagination, "page", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.teamsPagination, "size", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.teamsPagination, "size", $event)
                      },
                      "size-change": function ($event) {
                        return _vm.paginateTeams(1)
                      },
                      "current-change": _vm.paginateTeams,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "px-3" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      ref: "company-teams-list",
                      staticClass: "w-full company-teams-list",
                      attrs: {
                        stripe: "",
                        "highlight-current-row": "",
                        data: _vm.teams,
                      },
                      on: { "row-click": _vm.onManageMembers },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "Team",
                          prop: "name",
                          sortable: "",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(scope.row?.name) +
                                      "\n                        "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1860622565
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Description",
                          prop: "description",
                          sortable: "",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(scope.row?.description || "-") +
                                      "\n                        "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3258043561
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Ring Groups",
                          prop: "ring_groups",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.total_ring_groups || 0)
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4293648394
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Users",
                          prop: "users",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.total_users || 0)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3374729641
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Created by",
                          prop: "created_by",
                          "sort-by": "created_by",
                          sortable: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.getFullName(scope.row?.created_by)
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          744274693
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "Created on",
                          prop: "created_at",
                          sortable: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("fixFullDateLocal")(
                                          scope.row?.created_at
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3815715180
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "", align: "right" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-dropdown",
                                    { attrs: { trigger: "click" } },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          icon: "el-icon-more",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c("el-dropdown-item", [
                                            _c(
                                              "div",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.openEditTeamModal(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Edit Information\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("el-dropdown-item", [
                                            _c(
                                              "div",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.onManageMembers(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Manage Members\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("el-dropdown-item", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "el-dropdown-menu__item--delete",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.openDeleteTeamModal(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Delete\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3047791030
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-end py-3 px-3",
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      layout: "sizes, prev, pager, next",
                      "current-page": _vm.teamsPagination.page,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.teamsPagination.size,
                      total: _vm.teamsPagination.total,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.teamsPagination, "page", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.teamsPagination, "page", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.teamsPagination, "size", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.teamsPagination, "size", $event)
                      },
                      "size-change": function ($event) {
                        return _vm.paginateTeams(1)
                      },
                      "current-change": _vm.paginateTeams,
                    },
                  }),
                ],
                1
              ),
            ])
          : _c("edit-team-members", {
              attrs: {
                "selected-team": _vm.selectedTeam,
                company: _vm.current_company,
              },
              on: { "back-to-teams-list": _vm.onBackToTeamsList },
            }),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              width: "30%",
              center: "",
              visible: _vm.isTeamModalFormVisible,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isTeamModalFormVisible = $event
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "dialog--title mt-4",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _vm._v(
                  "\n               " +
                    _vm._s(_vm.formTeamModalConfig.title) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "teamFormRef",
                attrs: { model: _vm.teamForm, rules: _vm.rules },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Team Name", prop: "name" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Type here", maxlength: 80 },
                      model: {
                        value: _vm.teamForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.teamForm, "name", $$v)
                        },
                        expression: "teamForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "Description", prop: "description" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Type here" },
                      model: {
                        value: _vm.teamForm.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.teamForm, "description", $$v)
                        },
                        expression: "teamForm.description",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center mt-5" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      slot: "reference",
                      type: "success",
                      "data-testid": "team-creator-create-button",
                      disabled: _vm.isLoading,
                    },
                    on: { click: _vm.validateAndSubmitForm },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.validateAndSubmitForm.apply(null, arguments)
                      },
                    },
                    slot: "reference",
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.formTeamModalConfig.buttonText) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "Delete Team",
              width: "30%",
              center: "",
              visible: _vm.isDeleteTeamModalVisible,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isDeleteTeamModalVisible = $event
              },
            },
          },
          [
            _c("p", { staticClass: "break-word text-center" }, [
              _vm._v("You're about to delete the "),
              _c("strong", [_vm._v(_vm._s(_vm.selectedTeam?.name))]),
              _vm._v(" Team. This action can't be undone."),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "break-word text-center mb-4" }, [
              _vm._v("Are you sure?"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center mt-5 row" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "mb-2",
                    attrs: { slot: "reference", type: "default" },
                    on: { click: _vm.closeDeleteTeamModal },
                    slot: "reference",
                  },
                  [
                    _vm._v(
                      "\n                    No, Keep Team\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "mb-2",
                    attrs: {
                      slot: "reference",
                      type: "danger",
                      "data-testid": "team-deleter-delete-button",
                      disabled: _vm.isLoading,
                    },
                    on: { click: _vm.onDeleteTeam },
                    slot: "reference",
                  },
                  [
                    _vm._v(
                      "\n                    Yes, Delete Team\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "Delete Team",
              width: "30%",
              center: "",
              visible: _vm.isDeleteTeamModalErrorVisible,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isDeleteTeamModalErrorVisible = $event
              },
            },
          },
          [
            _c("p", [
              _vm._v("The "),
              _c("strong", [_vm._v(_vm._s(_vm.selectedTeam?.name))]),
              _vm._v(
                " Team may be being used on a Ring Group or has users assigned to it. To delete, you have to remove it from the Billing Ring Group or remove the users from the team."
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center mt-5" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { slot: "reference", type: "default" },
                    on: {
                      click: function ($event) {
                        _vm.isDeleteTeamModalErrorVisible = false
                      },
                    },
                    slot: "reference",
                  },
                  [_vm._v("\n                    Close\n                ")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }