var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _vm.shouldShowSequences
        ? [
            _c("div", { staticClass: "container-listing min-height-screen" }, [
              _c("div", { staticClass: "container-header d-flex" }, [
                _c("h3", { staticClass: "listing-heading" }, [
                  _vm._v("Sequences"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ml-auto" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      !_vm.canCreateSequence
                        ? _c(
                            "el-popover",
                            {
                              ref: "new-sequence-popover",
                              staticClass: "custom-popover",
                              attrs: {
                                placement: "top",
                                width: "200",
                                "popper-class": "btn-primary",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("custom-message-display", {
                                attrs: {
                                  config: _vm.customMessage("sequences"),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "popover",
                              rawName: "v-popover:new-sequence-popover",
                              arg: "new-sequence-popover",
                            },
                          ],
                          staticClass: "mr-3",
                          attrs: {
                            type: "success",
                            round: "",
                            size: "medium",
                            disabled: !_vm.canCreateSequence,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.workflow_creator.addWorkflow()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus" }),
                          _vm._v(" "),
                          _c("strong", [_vm._v("New Sequence")]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isTrial && !_vm.isSimpSocial
                        ? _c("video-modal", {
                            staticClass: "pl-2",
                            attrs: {
                              title:
                                "Mastering Aloware Automation Sequences 🚀",
                              "cookie-name": "workflows",
                              notes:
                                "🤖 Dive into the world of Aloware's Automation Sequences: from managing leads, powering SMS drip campaigns to sending timely reminders. </br></br> Customize, trigger, and automate for a seamless experience! 🚀 #MasterAutomationWithAloware",
                              "video-url":
                                "https://www.youtube.com/embed/ABDa6fgL5F4?si=F9eEcDPylwxAmBDY",
                              "learn-more-link":
                                "https://support.aloware.com/en/articles/9037395-aloware-sequence-explained-how-it-works-and-how-to-get-started",
                              "activator-size": "small",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "container-body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "container-listing-tools d-flex flex-wrap mb-1",
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-wrap mr-auto" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex mr-2 search-toolbar" },
                        [
                          _c(
                            "el-input",
                            {
                              ref: "sequenceSearch",
                              attrs: {
                                id: "search-input",
                                placeholder: "Search sequences...",
                                clearable: "",
                                autofocus: "",
                              },
                              on: { change: _vm.searchWorkflows },
                              nativeOn: {
                                keyup: function ($event) {
                                  return _vm.searchWorkflows.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              model: {
                                value: _vm.filters.search,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "search", $$v)
                                },
                                expression: "filters.search",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-input__icon el-icon-search",
                                attrs: { slot: "prefix" },
                                slot: "prefix",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex mt-2 mt-sm-0 mr-2 mr-xl-0" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "medium" },
                              on: {
                                change: function ($event) {
                                  return _vm.fetchWorkflows(1)
                                },
                              },
                              model: {
                                value: _vm.filters.active,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "active", $$v)
                                },
                                expression: "filters.active",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "-3" } },
                                [
                                  _vm._v(
                                    "\n                                    All\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v(
                                  "\n                                    Active\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v(
                                  "\n                                    Paused\n                                "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex mt-2 mt-xl-0 flex-wrap flex-lg-nowrap",
                      },
                      [
                        _c("div", { staticClass: "mr-2 align-self-center" }, [
                          _c("span", { staticClass: "text-grey" }, [
                            _vm._v("Total Sequences:"),
                          ]),
                          _vm._v(" "),
                          _c("strong", [_vm._v(_vm._s(_vm.pagination.total))]),
                        ]),
                        _vm._v(" "),
                        _c("el-pagination", {
                          staticClass: "align-self-center",
                          attrs: {
                            layout: "prev, pager, next",
                            "current-page": _vm.filters.page,
                            "page-size": _vm.filters.size,
                            total: _vm.pagination.total,
                            "hide-on-single-page": "",
                            "pager-count": 5,
                          },
                          on: {
                            "update:currentPage": function ($event) {
                              return _vm.$set(_vm.filters, "page", $event)
                            },
                            "update:current-page": function ($event) {
                              return _vm.$set(_vm.filters, "page", $event)
                            },
                            "update:pageSize": function ($event) {
                              return _vm.$set(_vm.filters, "size", $event)
                            },
                            "update:page-size": function ($event) {
                              return _vm.$set(_vm.filters, "size", $event)
                            },
                            "size-change": function ($event) {
                              return _vm.fetchWorkflows(1)
                            },
                            "current-change": _vm.fetchWorkflows,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.workflows_loading,
                        expression: "workflows_loading",
                      },
                    ],
                    staticClass: "table-responsive",
                  },
                  [
                    _vm.pagination.data.length > 0
                      ? _c("table", { staticClass: "table" }, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.pagination.data, function (workflow) {
                              return _c("tr", [
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c("el-switch", {
                                        staticClass: "mr-4 align-self-center",
                                        attrs: {
                                          "active-color": "#00BF50",
                                          disabled:
                                            workflow.real_status ===
                                              _vm.Workflow
                                                .CURRENT_STATUS_INVALID &&
                                            !workflow.active,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeActiveStatus(
                                              workflow
                                            )
                                          },
                                        },
                                        model: {
                                          value: workflow.active,
                                          callback: function ($$v) {
                                            _vm.$set(workflow, "active", $$v)
                                          },
                                          expression: "workflow.active",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "Sequence Manager",
                                                  params: {
                                                    workflow_id: workflow.id,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "hover-lower-opacity",
                                                },
                                                [
                                                  _c(
                                                    "h6",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(workflow.name)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "#" + _vm._s(workflow.id)
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  workflow.created_at
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-block b-t pt-1 text-xs",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "fixDateTime"
                                                                )(
                                                                  workflow.created_at
                                                                )
                                                              ) +
                                                              "\n                                            "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Sequence Manager",
                                            params: {
                                              workflow_id: workflow.id,
                                            },
                                            query: { tab: "steps" },
                                          },
                                        },
                                      },
                                      [
                                        workflow.sequences_count
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "hover-lower-opacity",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      workflow.sequences_count
                                                    ) +
                                                    " step" +
                                                    _vm._s(
                                                      workflow.sequences_count >
                                                        1
                                                        ? "s"
                                                        : ""
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "text-grey" },
                                              [_vm._v("No steps")]
                                            ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        workflow.process_condition
                                      ),
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  workflow.campaign
                                    ? _c("div", [
                                        _c("h6", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            _vm._s(workflow.campaign.name)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "#" + _vm._s(workflow.campaign.id)
                                          ),
                                        ]),
                                      ])
                                    : _c("div", [_vm._v("-")]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Sequence Manager",
                                            params: {
                                              workflow_id: workflow.id,
                                            },
                                            query: { tab: "contacts" },
                                          },
                                        },
                                      },
                                      [
                                        workflow.contacts_count
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "hover-lower-opacity",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f("numFormat")(
                                                        workflow.contacts_count
                                                      )
                                                    ) +
                                                    " contacts\n                                    "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "text-grey" },
                                              [
                                                _vm._v(
                                                  "\n                                        None enrolled\n                                    "
                                                ),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("td", [
                                  workflow.remaining_tasks
                                    ? _c(
                                        "div",
                                        { staticClass: "hover-lower-opacity" },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm._f("numFormat")(
                                                  workflow.remaining_tasks
                                                )
                                              ) +
                                              " "
                                          ),
                                          workflow.total_tasks > 0
                                            ? _c("span", [
                                                _vm._v(
                                                  "/ " +
                                                    _vm._s(
                                                      _vm._f("numFormat")(
                                                        workflow.total_tasks
                                                      )
                                                    )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _c("div", { staticClass: "text-grey" }, [
                                        _vm._v(
                                          "\n                                    0\n                                "
                                        ),
                                      ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c("workflow-status", {
                                      attrs: { workflow: workflow },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-right" },
                                  [
                                    _c(
                                      "el-dropdown",
                                      {
                                        attrs: { trigger: "click" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "dropdown",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "el-dropdown-menu",
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: "Sequence Manager",
                                                              params: {
                                                                workflow_id:
                                                                  workflow.id,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-dropdown-item",
                                                            [_vm._v("Settings")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: "Sequence Manager",
                                                              params: {
                                                                workflow_id:
                                                                  workflow.id,
                                                              },
                                                              query: {
                                                                tab: "steps",
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-dropdown-item",
                                                            [_vm._v("Steps")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: "Sequence Manager",
                                                              params: {
                                                                workflow_id:
                                                                  workflow.id,
                                                              },
                                                              query: {
                                                                tab: "triggers",
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-dropdown-item",
                                                            [_vm._v("Triggers")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: "Sequence Manager",
                                                              params: {
                                                                workflow_id:
                                                                  workflow.id,
                                                              },
                                                              query: {
                                                                tab: "contacts",
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-dropdown-item",
                                                            [_vm._v("Contacts")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: "Sequence Manager",
                                                              params: {
                                                                workflow_id:
                                                                  workflow.id,
                                                              },
                                                              query: {
                                                                tab: "history",
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-dropdown-item",
                                                            [_vm._v("History")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          attrs: {
                                                            divided: "",
                                                          },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.copyWorkflow(
                                                                workflow
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-copy",
                                                          }),
                                                          _vm._v(
                                                            " Create Copy\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasPermissionTo(
                                                        "delete workflow"
                                                      )
                                                        ? _c(
                                                            "el-dropdown-item",
                                                            {
                                                              staticClass:
                                                                "text-danger",
                                                              nativeOn: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteWorkflow(
                                                                      workflow
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-trash-o",
                                                              }),
                                                              _vm._v(
                                                                " Delete\n                                            "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "px-3 py-2",
                                            attrs: { size: "medium" },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-ellipsis-h",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pagination.data.length <= 0
                      ? _c(
                          "div",
                          { staticClass: "text-center mx-auto w-50 py-5" },
                          [
                            _c(
                              "div",
                              { staticClass: "w-75 mx-auto" },
                              [_c("img-empty")],
                              1
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "lead" }, [
                              _vm._v(
                                "\n                            We can't seem to find sequences\n                            "
                              ),
                              _vm.filters.search.length > 0
                                ? _c("span", [
                                    _vm._v(
                                      'that matches "' +
                                        _vm._s(_vm.filters.search) +
                                        '"'
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "mr-3",
                                attrs: {
                                  type: "success",
                                  round: "",
                                  size: "medium",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.workflow_creator.addWorkflow()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-plus" }),
                                _vm._v(" "),
                                _c("strong", [_vm._v("New Sequence")]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "container-footer d-flex" }, [
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        layout: "sizes, prev, pager, next",
                        "current-page": _vm.filters.page,
                        "page-sizes": [10, 20, 50, 100],
                        "page-size": _vm.filters.size,
                        "hide-on-single-page": true,
                        total: _vm.pagination.total,
                      },
                      on: {
                        "update:currentPage": function ($event) {
                          return _vm.$set(_vm.filters, "page", $event)
                        },
                        "update:current-page": function ($event) {
                          return _vm.$set(_vm.filters, "page", $event)
                        },
                        "update:pageSize": function ($event) {
                          return _vm.$set(_vm.filters, "size", $event)
                        },
                        "update:page-size": function ($event) {
                          return _vm.$set(_vm.filters, "size", $event)
                        },
                        "size-change": function ($event) {
                          return _vm.fetchWorkflows(1)
                        },
                        "current-change": _vm.fetchWorkflows,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("workflow-creator", { ref: "workflow_creator" }),
            _vm._v(" "),
            _c("form-height-indicator", { attrs: { bottom: 20 } }),
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.shouldShowSequences && _vm.shouldShowUpgradeNow
        ? _c("upgrade-now-page", {
            staticClass: "mt-5",
            attrs: {
              "image-link": "/assets/images/Sequences.svg",
              text: "Nurture your contacts through drip campaigns and automate many actions based on predefined steps with Sequences+",
              "extra-text": "Upgrade today to unlock this feature",
              "title-text": "Sequences+",
              "kb-link":
                "https://support.aloware.com/en/articles/9031607-a-guide-to-aloware-admin-broadcast",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Sequence")]),
        _vm._v(" "),
        _c("th", [_vm._v("Steps")]),
        _vm._v(" "),
        _c("th", [_vm._v("Process On")]),
        _vm._v(" "),
        _c("th", [_vm._v("Line Used")]),
        _vm._v(" "),
        _c("th", [_vm._v("Contacts")]),
        _vm._v(" "),
        _c("th", [_vm._v("Remaining Tasks")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }