<template>
    <div class="h-100"
         :class="[('summary-card-' + status)]"
         data-testid="summary-report-unreturned-wrapper">
        <a href="#"
           class="w-100 cursor-initial">
            <div class="card summary-card-hover h-100 border-0 shadow-none bg-transparent">
                <div class="w-100 p-1 pl-3">
                    <div class="d-inline-block align-bottom">
                        <img
                            data-testid="summary-report-unreturned-img"
                            v-if="img_src"
                            :src="img_src">
                        <template
                                v-if="icons.length"
                                v-for="icon in icons">
                            <i
                                class="material-icons"
                                :class="{'img-with-icon': img_src}"
                                style="color: #03c04c">
                                {{ icon }}
                            </i>
                        </template>
                    </div>
                    <div
                        class="d-inline-block pl-2">
                        <strong
                            class="text-md title">{{ title }}</strong>
                    </div>
                </div>
                <div
                    class="text-center pt-4 w-100">
                    <template v-for="(item, index) in data">
                        <div
                            class="mt-2 countup-hover"
                            :class="classesForMultipleAgreggateData(item)"
                            data-testid="summary-report-unreturned-card"
                            @click.prevent="filtering()">
                            <h4
                                class="m-a-0 mb-2 text-lg metric-value">
                                <ICountUp
                                    v-if="typeof item.count !== 'undefined' && item.count !== null"
                                    :ref="'countup-' + index"
                                    :duration="count_up.duration"
                                    :endVal="parseInt(item.count)"
                                    :options="countUpOptions(index)"
                                    :startVal="count_up.startVal"
                                    data-testid="summary-report-unreturned-countup"
                                />
                                <span
                                    v-if="item.count === null">
                                    -
                                </span>
                            </h4>
                            <small
                                class="text-muted text-sm sms-text metric-label">
                                {{ item.label }}
                            </small>
                        </div>
                    </template>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {summary_widgets_mixin} from '../mixins'
import ICountUp from 'vue-countup-v2'

export default {
    mixins: [summary_widgets_mixin],

    components: {
        ICountUp
    },

    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        data: {
            type: Array,
            required: false,
            default: () => []
        },
        img_src: {
            type: String,
            required: false,
            default: null
        },
        loading: {
            type: Boolean,
            required: false,
            default: true
        },
        icons: {
            type: Array,
            required: false,
            default: () => []
        },
        campaign_id: {
            type: Number,
            required: false
        },
        workflow_id: {
            type: Number,
            required: false
        },
        ring_group_id: {
            type: Number,
            required: false
        },
        user_id: {
            type: Number,
            required: false
        },
        status: {
            type: String,
            required: false,
            default: 'inactive'
        }
    },

    data() {
        const easingFn = function (t, b, c, d) {
            const ts = (t /= d) * t
            const tc = ts * t
            return b + c * (tc + -3 * ts + 3 * t)
        }
        
        return {
            count_up: {
                startVal: 0,
                duration: 2.5,
                options: {
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true
                },
                decimal_options: {
                    decimalPlaces: 2,
                    suffix: '%',
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true
                },
                custom_formatting: {
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true,
                    formattingFn: this.formatSeconds
                }
            }
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),
        
        dataColumnClass() {
            if (this.data.length < 6) {
                return 'col-' + (12  / this.data.length)
            }
            
            const column_percent = (12 / Math.ceil(this.data.length / 2))
            return 'col-' + column_percent.toString().replace('.', '-')
        }
    },

    methods: {
        classesForMultipleAgreggateData(item) {
            return [
                this.data.length === 1 ? 'w-100' : this.dataColumnClass,
                (!item.clickable ? 'unclickable' : '')
            ]
        },

        countUpOptions(index) {
            if (this.data[index]?.is_seconds) {
                return this.count_up.custom_formatting
            }
            return this.count_up.options
        },

        filtering() {
            this.resetFiltersExceptDates()
            this.filter.unread_only = 1
            this.filter.direction = 'inbound'
            this.filter.timezone = window.timezone
            this.filter.type = 'all'
            this.filter.campaign_id = this.campaign_id
            this.filter.workflow_id = this.workflow_id
            this.filter.ring_group_id = this.ring_group_id
            this.filter.user_id = this.user_id
            this.filter.changed = true
            this.scrollWindowToGraph()
            VueEvent.fire('update_summary', true)
        },

        resetFiltersExceptDates() {
            const from_date = this.filter.from_date
            const to_date = this.filter.to_date
            this.resetFilters()
            this.filter.from_date = from_date
            this.filter.to_date = to_date
        },

        scrollWindowToGraph() {
            if (!$('#activity-graph').length) {
                return
            }

            const top_position = $("#activity-graph").offset().top
            const header_height = $(".app-header").height()

            $("html,body").animate({
                scrollTop: top_position - header_height - 90
            }, 500)
        },

        ...mapActions(['resetFilters'])
    }
    
}
</script>
