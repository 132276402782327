<template>
    <div class="time-range-picker row p-2">
        <div class="col-md-6">
            <div class="d-flex flex-column">
                <b>{{start_time_label}}</b>
                <v-date-picker class="start-time-picker"
                               v-model="start_time"
                               mode="time"
                               title-position="left"
                               color="green">
                </v-date-picker>
            </div>

        </div>
        <div class="col-md-6">
            <div class="d-flex flex-column">
                <b>{{end_time_label}}</b>
                <v-date-picker class="end-time-picker"
                               v-model="end_time"
                               mode="time"
                               title-position="left"
                               color="green">
                </v-date-picker>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                start_time: null,
                end_time: null,
                time_range: {
                    start: null,
                    end: null
                },
                is_initial_load: true
            }
        },

        props: {
            value: {
                required: true
            },

            start_time_label: {
                required: false,
                type: String,
                default: 'Start Time'
            },

            end_time_label: {
                required: false,
                type: String,
                default: 'End Time'
            },

            default_start_time: {
                required: false,
                type: String,
                default: '09:00:00' // 9 AM
            },

            default_end_time: {
                required: false,
                type: String,
                default: '18:00:00' // 6 PM
            },

            min_start_time: {
                required: false,
                type: Number
            },

            max_start_time: {
                required: false,
                type: Number
            },

            min_end_time: {
                required: false,
                type: Number
            },

            max_end_time: {
                required: false,
                type: Number
            },

            disabled: {
                required: false,
                default: false
            }
        },

        mounted() {
            this.enforceMaxMin('.start-time-picker')
            this.enforceMaxMin('.end-time-picker')
            this.setDefaultTimes()
        },

        methods: {
            setDefaultTimes() {
                let start_time_arr = this.default_start_time.split(':')
                let end_time_arr = this.default_end_time.split(':')
                let start_time = new Date()
                let end_time = new Date()

                this.start_time = start_time.setHours(start_time_arr[0], start_time_arr[1])
                this.end_time = end_time.setHours(end_time_arr[0], end_time_arr[1])
            },

            enforceMaxMin(time_picker_class) {
                setTimeout(() => {
                    let timepicker = this.$el.querySelector(time_picker_class)
                    let ampm = $(timepicker).find('.vc-am-pm button.active')[0].innerText
                    let ampm_add = (ampm === 'PM') ? 12 : 0
                    let time_options = $(timepicker).find('.vc-time .vc-select')
                    let hour_options = $(time_options[0]).find('select option')

                    if (this.disabled) {
                        $($(time_options[0]).find('select')[0]).attr('disabled', 'disabled')
                        $($(time_options[1]).find('select')[0]).attr('disabled', 'disabled')
                        $($(timepicker).find('.vc-am-pm button')[0]).attr('disabled', 'disabled')
                        $($(timepicker).find('.vc-am-pm button')[1]).attr('disabled', 'disabled')
                    } else {
                        $($(time_options[0]).find('select')[0]).removeAttr('disabled')
                        $($(time_options[1]).find('select')[0]).removeAttr('disabled')
                        $($(timepicker).find('.vc-am-pm button')[0]).removeAttr('disabled')
                        $($(timepicker).find('.vc-am-pm button')[1]).removeAttr('disabled')
                    }

                    for (let hour_option of hour_options) {
                        $(hour_option).attr('style', 'display:initial')
                        let hour_value = parseInt(hour_option.innerText) + ampm_add
                        // Enforce start time min value if min start prop is passed
                        if (
                            time_picker_class === '.start-time-picker'
                            && this.min_start_time
                            &&  hour_value < this.min_start_time
                        ) {
                            $(hour_option).attr('style', 'display:none')
                        }
                        // Enforce start time max value if max start prop is passed
                        if (
                            time_picker_class === '.start-time-picker'
                            && this.max_start_time
                            &&  hour_value > this.max_start_time
                        ) {
                            $(hour_option).attr('style', 'display:none')
                        }
                        // Enforce end time min value if min start prop is passed
                        if (
                            time_picker_class === '.end-time-picker'
                            && this.min_end_time
                            &&  hour_value < this.min_end_time
                        ) {
                            $(hour_option).attr('style', 'display:none')
                        }
                        // Enforce end time max value if max start prop is passed
                        if (
                            time_picker_class === '.end-time-picker'
                            && this.max_end_time
                            &&  hour_value > this.max_end_time
                        ) {
                            $(hour_option).attr('style', 'display:none')
                        }
                    }
                }, 200)
            },

            formatTimeString(hour, minute) {
                let hour_string = ''
                let minute_string = ''

                if (hour < 10) {
                    hour_string = '0' + hour
                } else {
                    hour_string = '' + hour
                }

                if (minute < 10) {
                    minute_string = '0' + minute
                } else {
                    minute_string = '' + minute
                }

                return hour_string + ':' + minute_string + ':00'
            }
        },

        watch: {
            start_time() {
                let hour = new Date(this.start_time).getHours()
                let minute = new Date(this.start_time).getMinutes()

                this.time_range.start = this.formatTimeString(hour, minute)
                this.enforceMaxMin('.start-time-picker')
            },

            end_time() {
                let hour = new Date(this.end_time).getHours()
                let minute = new Date(this.end_time).getMinutes()

                this.time_range.end = this.formatTimeString(hour, minute)
                this.enforceMaxMin('.end-time-picker')
            },

            time_range: {
                handler() {
                    this.$emit('input', this.time_range)

                    // only emit change if its not initial load
                    if (!this.is_initial_load) {
                        this.$emit('change', this.time_range)
                    } else {
                        this.is_initial_load = false
                    }
                },
                deep: true
            }
        }
    }
</script>
<style>
    .time-range-picker .vc-date {
        display: none !important;
    }

    .time-range-picker .vc-time-container {
        padding: 8px !important;
    }
</style>
