<template>
    <el-popover :placement="popover_placement"
                width="400"
                trigger="click"
                class="p-0"
                popper-class="variable-popover"
                @show="popoverShow">
        <variables-dropdown ref="variables_dropdown"
                            mode="return"
                            @variable-selected="emitSelectedVariable"/>
        <el-button id="variable-popover-trigger"
                   slot="reference"
                   :class="variable_btn_class"
                   size="mini"
                   circle
                   :disabled="disabled">
            <i :class="'' + icon"></i>
        </el-button>
    </el-popover>
</template>

<script>
import VariablesDropdown from '../../components/variables-dropdown'

export default {
    components: {
        VariablesDropdown
    },

    props: {
        disabled: {
            required: false,
            default: false
        },
        popover_placement: {
            required: false,
            default: "bottom-start"
        },
        icon: {
            required: false,
            default: "fa fa-code"
        },
        variable_btn_class: {
            required: false,
            default: "border-0 bg-transparent"
        },
    },

    data() {
        return {
            selected_variable: ''
        }
    },

    methods: {
        popoverShow() {
            if (this.$refs[('variables_dropdown')]) {
                this.$refs[('variables_dropdown')].focusToSelector()
            }
        },

        emitSelectedVariable(variable) {
            this.$emit('variable-selected', variable)

            this.$el.querySelector('#variable-popover-trigger').click()
        }
    },
}
</script>
