<template>
    <div class="container-listing px-0 py-3">
        <div class="container-header d-flex">
            <h4 class="listing-heading">Sequence Activity History</h4>
        </div>

        <div class="container-body">
            <div class="container-listing-tools d-flex flex-fill flex-wrap flex-lg-nowrap mb-1">
                <div class="d-flex flex-wrap flex-lg-nowrap mr-auto mr-5">
                    <div class="d-flex mr-2 search-toolbar"
                         style="width: 400px;">
                        <!-- Contact search field -->
                        <el-input placeholder="Search contact ID, phone number, or name..."
                                  v-model="filters.search"
                                  clearable>
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                        <!-- Event type -->
                    </div>
                    <el-select v-model="filters.type"
                               class="mr-2"
                               ref="admin_selector"
                               autocomplete="off"
                               default-first-option
                               filterable
                               clearable
                               placeholder="Select an event type"
                               @clear="clearEventTypeSelect">
                        <el-option v-for="event_type in event_types"
                                   :key="event_type.id"
                                   :label="event_type.label"
                                   :value="event_type.id">
                            <span>{{ event_type.label }}</span>
                        </el-option>
                    </el-select>
                    <!-- Timestamp selector -->
                    <date-selector class="mr-2"
                                   :default_date_range="'All Time'"
                                   @clearDefaultDateRange="clearDefaultDateRange"
                                   @clearCustomDateRange="clearCustomDateRange">
                    </date-selector>
                    <el-button round
                               size="medium"
                               @click="getLogs">
                        <i class="fa fa-refresh"></i> Refresh List
                    </el-button>
                </div>
                <div class="d-flex mt-2 mt-md-0 flex-wrap flex-lg-nowrap">
                    <div class="mr-2 align-self-center">
                        <span class="text-grey">Total Logs:</span> <strong>{{
                            total | numFormat
                        }}</strong>
                    </div>
                    <el-pagination class="align-self-center"
                                   layout="prev, pager, next"
                                   :current-page.sync="filters.page"
                                   :page-sizes="sizes"
                                   :page-size.sync="filters.size"
                                   :total="total"
                                   :pager-count="5"
                                   @size-change="getLogs"
                                   @current-change="getLogs">
                    </el-pagination>
                </div>
            </div>
            <div class="table-responsive"
                 v-loading="is_logs_loading">
                <el-table :data="logs"
                          :show-header="true">
                    <el-table-column label="Contact">
                        <template v-slot="scope">
                            <a target="_blank"
                               :href="getContactTalkRedirectURL(scope.row.contact_id)"
                               v-if="scope.row.contact_id">
                                <div class="hover-lower-opacity">
                                    <h6 class="mb-0">{{ scope.row | contactFullName }}</h6>
                                    <small class="text-grey">{{ scope.row.contact_phone_number | fixPhone }}</small><br/>
                                </div>
                            </a>
                            <h6 class="mb-0"
                                v-else>
                                Deleted Contact
                            </h6>
                        </template>
                    </el-table-column>
                    <el-table-column label="Action">
                        <template v-slot="scope">
                            <template v-if="![1, 2].includes(scope.row.type)">
                                <span>{{ scope.row.action }}</span>
                                <div class="d-flex"
                                    v-if="scope.row.data.sequence">
                                    <div></div>
                                    <img :src="scope.row.data.sequence.img_src" class="mr-3"/>
                                    <div>
                                        <h6 class="mb-0">{{ scope.row.data.sequence.name }}</h6>
                                        <small class="text-grey">Step #{{ scope.row.data.sequence.order }}</small>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <span>-</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="Event">
                        <template v-slot="scope">
                            <div class="d-flex align-items-top">
                                <i class="b-white bottom workflow-status mr-2"
                                   :class="scope.row.data.failed ? 'bg-workflow-error' : 'bg-workflow-success'">
                                </i>
                                <div>
                                    <router-link :to="{ name: 'Communication', params: { communication_id: scope.row.communication_id } }"
                                                 tag="a"
                                                 target="_blank"
                                                 v-if="scope.row.communication_id"
                                                 class="flex-grow-1 workflow-link">
                                        <span>{{ scope.row.event }}</span> <i class="fa fa-external-link fa-xs" aria-hidden="true"></i>
                                    </router-link>
                                    <template class="flex-grow-1"
                                              v-else>
                                        <span>{{ scope.row.event }}</span>
                                    </template>
                                    <template v-if="scope.row.data.reason">
                                        <br/>
                                        <small class="text-muted"
                                               v-html="scope.row.data.reason"/>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Time">
                        <template v-slot="scope">
                            <span>{{ scope.row.created_at | momentFormat('MMM D, YYYY h:mma z', true) }}</span>
                        </template>
                    </el-table-column>

                    <template slot="empty">
                        <div class="text-center mx-auto w-50 py-5">
                            <div class="w-75 mx-auto">
                                <img-empty></img-empty>
                            </div>
                            <p class="lead">
                                <span v-if="filters.search">
                                    We could not find an activity log that matches "{{ filters.search }}".
                                </span>
                                <span v-else>There are no activity history for this sequence.</span>
                            </p>
                            <el-button round
                                       size="medium"
                                       @click="getLogs">
                                <i class="fa fa-refresh"></i> Refresh List
                            </el-button>
                        </div>
                    </template>
                </el-table>
            </div>
        </div>

        <div class="container-footer d-flex justify-content-center">
            <el-pagination layout="sizes, prev, pager, next"
                           :current-page.sync="filters.page"
                           :page-sizes="sizes"
                           :page-size.sync="filters.size"
                           :total="total"
                           :pager-count="5"
                           :hide-on-single-page="true"
                           @size-change="getLogs"
                           @current-change="getLogs">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { contact_talk_mixin } from '../../mixins'
import {mapState} from 'vuex'
import DateSelector from '../date-selector.vue'
import ImgEmpty from "../../../../assets/user/components/misc/img-empty.vue"
import _ from 'lodash'
import moment from 'moment'

const event_types = [
    {
        id: 1,
        label: 'Enrolling contacts'
    },
    {
        id: 2,
        label: 'Disenrolling contacts'
    },
    {
        id: 3,
        label: 'Updating contact properties'
    },
    {
        id: 4,
        label: 'Communicating with contacts'
    },
    {
        id: 5,
        label: 'Creating information'
    }
]

export default {
    mixins: [
        contact_talk_mixin,
    ],

    components: {
        DateSelector,
        ImgEmpty
    },

    props: {
        workflow: {
            required: true,
            type: Object
        }
    },

    data () {
        return {
            event_types,
            logs: [],
            total: 0,
            filters: {
                search: null,
                type: null,
                page: 1,
                size: 20
            },
            default_date_range: null,
            is_logs_loading: false,
            sizes: [
                10, 20, 30, 40, 50
            ]
        }
    },

    mounted () {
        VueEvent.fire('change_date_range', 'All Time')
        this.getLogs()
    },

    computed: {
        ...mapState(['filter']),

        queryParams () {
            let params = {
                page: this.filters.page,
                size: this.filters.size
            }

            if (this.from_date || this.to_date) {
                params.to_date = this.to_date
                params.from_date = this.from_date
            }

            if (this.filters.type) {
                params.type = this.filters.type
            }

            if (this.filters.search) {
                params.search = this.filters.search
            }

            params.timezone = window.timezone

            return params
        },

        from_date () {
            return this.filter.from_date ? moment(this.filter.from_date).format('YYYY-MM-DD HH:mm:ss') : null
        },

        to_date () {
            return this.filter.to_date ? moment(this.filter.to_date).format('YYYY-MM-DD HH:mm:ss') : null
        }
    },

    methods: {
        getLogs () {
            if (this.is_logs_loading) {
                return
            }

            this.is_logs_loading = true
            let params = this.queryParams

            axios.get('/api/v1/automations/workflows/' + this.workflow.id + '/logs', {
                params: params
            }).then(res => {
                this.logs = res.data.data
                this.total = res.data.total
                this.is_logs_loading = false
            }).catch(err => {
                this.logs = []
                this.total = 0
                this.is_logs_loading = false
            })
        },

        clearDefaultDateRange () {
            this.default_date_range = null
        },

        clearCustomDateRange () {
            VueEvent.fire('change_date_range', 'All Time')
        },

        clearEventTypeSelect () {
            this.filters.type = null
        }
    },

    watch: {
        'filters.type': function() {
            this.filters.page = 1
            this.getLogs()
        },
        'from_date': function() {
            this.filters.page = 1
            this.getLogs()
        },
        'to_date': function() {
            this.filters.page = 1
            this.getLogs()
        },
        'filters.search': _.debounce(function() {
            if (!this.filters.search ||
                (this.filters.search &&
                (this.filters.search.length >= 3 || this.filters.search.length == 0))) {
                this.filters.page = 1
                this.getLogs()
            }
        }, 500),
    }

}
</script>
