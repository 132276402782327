var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDataValid
    ? _c(
        "div",
        [
          _vm.single_button
            ? _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "Show Transcription",
                    "data-testid": "communication-transcription-tooltip",
                    placement: "bottom",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm blue btn-icon opaq",
                      attrs: {
                        "data-testid": "communication-transcription-button",
                      },
                      on: { click: _vm.openTranscriptionTalkLink },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("chat"),
                      ]),
                    ]
                  ),
                ]
              )
            : _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    "data-testid": "communication-smart-transcription-button",
                    type: "text text-dark-greenish m-0 p-0",
                  },
                  on: { click: _vm.openTranscriptionTalkLink },
                },
                [
                  _c("i", { staticClass: "fa fa-file-text" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-1" }, [
                    _vm._v(_vm._s(this.button_text)),
                  ]),
                ]
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }