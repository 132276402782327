<template>
    <div class="width-500" style="height: 400px">
        <div class="row d-flex align-items-center">
            <div class="col-8">
                <el-input placeholder="Search"
                          prefix-icon="el-icon-search"
                          v-model="search_text"
                          @input="findGif">
                </el-input>
            </div>
            <div class="col-4">
                <img src="/assets/images/giphy.png"
                     class="width-120">
            </div>
        </div>
        <div class="mt-3 height-400">
            <el-row class="full-height"
                    v-if="gifs_arr.length == 0">
                <el-col class="full-height"
                        v-loading="gifs_loading">
                    <div class="d-flex full-height justify-content-center align-items-center"
                         v-if="!gifs_loading">
                        <span>No results found</span>
                    </div>
                </el-col>
            </el-row>
            <vue-masonry-gallery v-if="gifs_arr.length > 0"
                                 :imgsArr="gifs_arr"
                                 :width="500"
                                 :height="400"
                                 :img-width="140"
                                 @scrollReachBottom="loadMoreGiphy"
                                 @click="selected">
            </vue-masonry-gallery>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import GiphyClient from 'giphy-js-sdk-core'

    export default {
        name: 'search-giphy',

        data() {
            return {
                search_text: null,
                gifs_arr: [],
                page: 0,
                total_pages: 0,
                per_page: 25,
                total_trending_pages: 0,
                search_type: 'trending',
                client: GiphyClient(localStorage.getItem('giphy_api_key')),
                gifs_loading: false
            }
        },

        mounted() {
            this.init()
        },

        methods: {
            trendingGiphy() {
                this.gifs_loading = true
                this.client.trending('gifs', {
                    offset: this.trending_page * this.per_page,
                    limit: this.per_page
                }).then((res) => {
                    this.gifs_loading = false
                    this.total_trending_pages = res.pagination.count
                    this.addGifs(res.data)
                    //
                }).catch((err) => {
                    console.log(err)
                })
            },

            searchGiphy() {
                if (!this.search_text && this.search_type === 'custom') {
                    this.init()
                    return
                }

                this.gifs_loading = true
                this.client.search('gifs', {
                    q: this.search_text,
                    offset: this.page * this.per_page,
                    limit: this.per_page
                }).then((res) => {
                    this.gifs_loading = false
                    this.total_pages = res.pagination.count
                    this.addGifs(res.data)
                    //
                }).catch((err) => {
                    console.log(err)
                })
            },

            loadMoreGiphy() {
                if (this.search_type === 'custom' && this.page < this.total_pages) {
                    this.page++
                    this.searchGiphy()
                }

                if (this.search_type === 'trending' && this.trending_page < this.total_trending_pages) {
                    this.trending_page++
                    this.trendingGiphy()
                }
            },

            reset() {
                this.gifs_arr = []
                this.page = 0
                this.total_pages = 0
                this.trending_page = 0
                this.total_trending_pages = 0
            },

            init() {
                this.reset()
                this.search_type = 'trending'
                this.trendingGiphy()
            },

            addGifs(gifs) {
                if (!gifs || gifs.length === 0) {
                    this.reset()
                    return
                }

                let new_gifs = gifs.map((gif) => ({
                    src: gif.images.downsized.url
                }))

                for (let gif of new_gifs) {
                    this.gifs_arr.push(gif)
                }
            },

            findGif: _.debounce(function () {
                this.reset()
                this.search_type = 'custom'
                this.searchGiphy()
            }, 500),

            selected(event, target) {
                this.$emit('selected', target.value.src)
            }
        }
    }
</script>
