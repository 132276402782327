var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.is_secure_origin
    ? _c("el-form", [
        _c(
          "label",
          {
            staticClass: "el-form-item__label full-width ml-2 font-weight-bold",
          },
          [_vm._v("\n        Record an audio file:\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "audio-recorder-container" }, [
          _c(
            "div",
            {
              staticClass: "border",
              class: { "float-left": !_vm.responsive },
              style: [_vm.responsive ? { width: "100%" } : {}],
            },
            [
              _c("el-form-item", { staticClass: "h-100" }, [
                _vm.recorded_audio === null
                  ? _c("div", { staticClass: "col-12 audio-recorder h-100" }, [
                      !_vm.buttons_toggle
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-100 mt-1 d-flex justify-content-center align-items-end",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-md greenish mr-2",
                                  attrs: { disabled: _vm.is_uploading },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.startRecorder.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons align-bottom",
                                    },
                                    [_vm._v("mic")]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "w-100 mt-1 d-flex justify-content-center align-items-end",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-md red mr-2 active",
                                  attrs: {
                                    disabled:
                                      !_vm.enabled_recording ||
                                      _vm.is_uploading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.stopRecorder.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons align-bottom",
                                    },
                                    [_vm._v("mic")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                      _vm._v(" "),
                      !_vm.buttons_toggle
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-100 h-25 d-flex justify-content-center align-items-start",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "el-form-item__label full-width mt-2 font-weight-bold",
                                  class: {
                                    "ml-2": _vm.is_popover,
                                    "mr-2": !_vm.is_popover,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Press to record your voice\n                        "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "w-100 h-25 d-flex justify-content-center align-items-start",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "el-form-item__label full-width mt-2 font-weight-bold",
                                  class: {
                                    "ml-2": _vm.is_popover,
                                    "mr-2": !_vm.is_popover,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.hours) +
                                      ":" +
                                      _vm._s(_vm.minutes) +
                                      ":" +
                                      _vm._s(_vm.seconds) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                    ])
                  : _c(
                      "div",
                      { staticClass: "h-100 col-12 p-0 mt-4 text-center" },
                      [
                        _c(
                          "audio",
                          {
                            staticClass: "mr-2",
                            staticStyle: { width: "250px" },
                            attrs: { controls: "", disabled: _vm.is_uploading },
                          },
                          [
                            _c("source", {
                              attrs: { src: _vm.recorded_audio.src },
                            }),
                            _vm._v(
                              "\n                        Your browser does not support the audio element.\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm red mr-3",
                                attrs: {
                                  disabled:
                                    !_vm.enabled_recording || _vm.is_uploading,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeRecordedAudio.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-sync-alt" }),
                                _vm._v(" "),
                                _c("span", [_vm._v("Change")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm blue align-top",
                                attrs: {
                                  disabled:
                                    !_vm.enabled_recording || _vm.is_uploading,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.uploadAudio()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.is_uploading,
                                      expression: "is_uploading",
                                    },
                                  ],
                                  staticClass: "fa fa-spin fa-spinner",
                                }),
                                _vm._v(" "),
                                _vm.upload_text === "Upload"
                                  ? _c(
                                      "i",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.is_uploading,
                                            expression: "!is_uploading",
                                          },
                                        ],
                                        staticClass: "material-icons",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                cloud_upload"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.upload_text) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "p-a",
              class: { "float-left": !_vm.responsive },
              style: [_vm.responsive ? { width: "100%", height: "70px" } : {}],
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "h-100 d-flex justify-content-center align-items-center",
                },
                [_c("h4", [_vm._v("OR")])]
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }