<template>
    <div class="app-body"
         id="view">
        <el-dialog :title="selected_campaign.name"
                   :visible.sync="detailDialogVisible"
                   width="50%"
                   v-if="selected_campaign && hasPermissionTo('view campaign')">
            <campaign-details :campaign="selected_campaign"></campaign-details>
        </el-dialog>

        <el-dialog title="What are lines?"
                   top="10vh"
                   :visible.sync="show_help"
                   append-to-body>
            <p class="text-md _600 break-word">Lines are the core of your phone system: managing Calls and SMS intake.
                Here you can
                buy phone numbers, define greeting prompts, record calls, or set up IVR phone trees.</p>
            <p class="text-md _600 break-word">Each Line has an activity page showing its calls and messages. Use
                multiple lines
                for call tracking and attribution, or set up a dedicated phone number for a department.</p>
        </el-dialog>

        <div class="fixed-header padding pt-0 pb-0"
             :style="{top: (top_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="d-flex flex-fill flex-wrap p-2">
                    <div class="d-flex flex-wrap flex-xl-nowrap mr-auto pr-xl-2 mt-2">
                        <div class="mr-sm-2 flex-fill search-toolbar">
                            <el-input ref="lineSearch"
                                      placeholder="Search"
                                      prefix-icon="el-icon-search"
                                      clearable
                                      autofocus
                                      v-model="search_text">
                            </el-input>
                        </div>

                        <div class="no-select mt-2 mt-md-0 mr-2 w-m">
                            <el-select placeholder="Select"
                                       class="no-select"
                                       default-first-option
                                       filterable
                                       v-model="type_filter"
                                       @change="changedFilter">
                                <el-option :key="1"
                                           label="All"
                                           value="All">
                                </el-option>
                                <el-option :key="2"
                                           label="Personal Lines"
                                           value="Personal Lines">
                                </el-option>
                                <el-option :key="3"
                                           label="IVRs"
                                           value="IVRs">
                                </el-option>
                                <el-option :key="4"
                                           label="Ring Groups"
                                           value="Ring Groups">
                                </el-option>
                                <el-option :key="5"
                                           label="Dead ends"
                                           value="Dead ends">
                                </el-option>
                                <el-option :key="6"
                                           label="Local Presence"
                                           value="Local Presence">
                                </el-option>
                                <el-option :key="7"
                                           label="Toll-Free"
                                           value="Toll-Free">
                                </el-option>
                            </el-select>
                        </div>

                        <div class="no-select mt-2 mt-md-0">
                            <el-radio-group class="no-select"
                                            v-model="campaign_filter"
                                            v-if="!isMobileSize"
                                            @change="changedFilter">
                                <el-radio-button label="active">
                                    Active <span v-if="activeCount">({{ activeCount }})</span>
                                </el-radio-button>
                                <el-radio-button label="paused"
                                                 :class="[campaign_filter == 'paused' ? 'pause-deep-orange': '']">
                                    Paused <span v-if="pausedCount">({{ pausedCount }})</span>
                                </el-radio-button>
                                <el-radio-button label="all">
                                    All <span v-if="allCount">({{ allCount }})</span>
                                </el-radio-button>
                            </el-radio-group>
                            <el-select v-else
                                       placeholder="Select"
                                       class="no-select"
                                       default-first-option
                                       filterable
                                       v-model="campaign_filter"
                                       @change="changedFilter">
                                <el-option :key="1"
                                           :label="'Active ' + (activeCount ? '(' + activeCount + ')' : '')"
                                           value="active">
                                </el-option>
                                <el-option :key="2"
                                           :label="'Paused '  + (pausedCount ? '(' + pausedCount + ')' : '')"
                                           value="paused">
                                </el-option>
                                <el-option :key="3"
                                           :label="'All '  + (allCount ? '(' + allCount + ')' : '')"
                                           value="all">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap flex-xl-nowrap mt-2">
                        <line-creator
                            class="mr-2 mt-2 mt-xl-0"
                            v-if="canCreateFaxAndNewLine"
                            :is_fax="true">
                        </line-creator>

                        <line-creator
                            class="mr-2 mt-2 mt-xl-0"
                            v-if="canCreateFaxAndNewLine">
                        </line-creator>

                        <!-- ShortCode Wizard -->
                        <div data-testid="short-code-creator-wrapper" class="mr-2 mt-2 mt-xl-0" v-if="shouldShowShortCode">
                            <el-tooltip
                                effect="dark"
                                content="Your Short Code request has been received! Our team will contact you soon."
                                placement="bottom"
                                v-if="shortCodeWizardUsed"
                            >
                                <button
                                    class="btn btn-md rounded greenish"
                                    data-testid="create-short-code-btn"
                                    :disabled="shortCodeWizardUsed">
                                    Apply for Short Code
                                </button>
                            </el-tooltip>
                            <button
                                v-else
                                class="btn btn-md rounded greenish"
                                data-testid="create-short-code-btn"
                                @click="openShortCodeWizardModal">
                                Apply for Short Code
                            </button>
                        </div>

                        <!-- NumberGuard Wizard -->
                        <div data-testid="number-guard-creator-wrapper" class="mr-2 mt-2 mt-xl-0" v-if="shouldShowNumberGuard">
                            <el-tooltip
                                effect="dark"
                                content="Scan phone numbers for spam risk."
                                placement="bottom"
                            >
                                <button
                                    class="btn btn-md rounded greenish"
                                    data-testid="create-number-guard-btn"
                                    @click="openNumberGuardWizardModal">
                                    NumberGuard
                                </button>
                            </el-tooltip>
                        </div>

                        <div class="mt-2 mt-xl-0 mr-2"
                             v-if="shouldShowLPPurchaseButton">
                            <a @click.prevent="$event => openLocalPresenceWizard()">
                                <button class="btn btn-md btn-primary">
                                    <i class="fa fa-thumb-tack"></i>
                                    Add Local Presence
                                </button>
                            </a>
                        </div>

                        <div class="mt-2 mt-xl-0 mr-2 mr-xl-0 d-flex flex-column justify-content-center text-blue">
                            <i class="material-icons text-lg cursor-pointer"
                               @click="toggleHelp">
                                info_outline
                            </i>
                        </div>

                        <video-modal-activator size="small"
                                               v-if="isTrial && !isSimpSocial"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="padding mt-5 pt-3"
             v-if="shouldShowReport">
            <div class="row box mt-5">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-header pb-0">
                            <activity-graph base="campaign"
                                            :default_date_range="7"
                                            :is_first_load="reports_first_load">
                            </activity-graph>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="table"
             class="padding"
             :class="{ 'mt-5': !shouldShowReport }">
            <div class="row box mt-5">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-header">
                            <div class="row">
                                <div class="col-12">
                                    <span class="pull-left font-120"
                                          v-if="filteredVisibleCampaigns">
                                        <strong>{{ filteredVisibleCampaigns.length || 0 }}</strong>
                                        {{ filteredVisibleCampaigns > 1 ? 'lines' : 'line' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <div v-on:mouseleave="tableLeave">
                                <el-table v-loading="loading"
                                          :data="paginatedData"
                                          :default-sort="{prop: 'id', order: 'ascending'}"
                                          class="w-full"
                                          fit
                                          stripe
                                          @cell-mouse-enter="cellEnter">
                                    <el-table-column
                                        prop="active"
                                        width="35">
                                        <template v-slot="scope">
                                            <span class="status">
                                                <i :class="[scope.row.active ? 'greenish' : 'danger']"
                                                   class="b-white"></i>
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="id"
                                        label="ID"
                                        width="100"
                                        sortable>
                                        <template v-slot="scope">
                                            <span class="text-greyish">{{ scope.row.id }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="name"
                                                     label="Name"
                                                     :min-width="200"
                                                     sortable>
                                        <template v-slot="scope">
                                            <router-link
                                                :to="{ name: 'Line Settings', params: {campaign_id: scope.row.id}}">
                                                <span class="text-dark-greenish text-ellipsis"
                                                      :title="scope.row.name">
                                                    {{ scope.row.name }}
                                                </span>
                                                <span class="label p-1 text-xxs blue"
                                                      v-if="scope.row.has_local_presence">
                                                    Has Local Presence
                                                </span>
                                                <span class="text-greyish">{{ scope.row.description || '' }}</span>
                                            </router-link>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="cnam_caller_id"
                                                     label="Caller ID (CNAM)">
                                        <template v-slot="scope">
                                            <span v-if="scope.row.cnam_caller_id" class="text-greyish">{{ scope.row.cnam_caller_id.display_name }}</span>
                                            <span v-else class="text-greyish"> - </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="type"
                                                     label="Type"
                                                     align="center">
                                        <template v-slot="scope">
                                            <span class="ml-2 d-inline-block label deep-orange"
                                                  v-if="lineHasLocal(scope.row)">
                                                Local
                                            </span>
                                            <span class="ml-2 d-inline-block label purple"
                                                  v-if="lineHasUkNumbers(scope.row)">
                                                {{ ukLineTag(scope.row) }}
                                            </span>
                                            <span class="ml-2 d-inline-block label blue"
                                                  v-if="lineHasTollFree(scope.row)">
                                                Toll-free
                                            </span>
                                            <span class="ml-2 d-inline-block label light-blue"
                                                  v-if="hasShortCode(scope.row)">
                                                Short Code
                                            </span>
                                            <span class="ml-2 d-inline-block label amber text-dark"
                                                  v-if="lineHasInternationalMobile(scope.row)">
                                                International Mobile
                                            </span>
                                            <span class="ml-2 d-inline-block label gray text-dark"
                                                  v-if="lineHasInternationalLocal(scope.row)">
                                                International Local
                                            </span>
                                            <span class="ml-2 d-inline-block label light-green text-white"
                                                  v-if="lineHasFax(scope.row)">
                                                Fax
                                            </span>
                                            <span class="ml-2 d-inline-block label dark"
                                                  v-if="lineHasFake(scope.row)">
                                                Fake
                                            </span>
                                            <span class="ml-2 d-inline-block label gray"
                                                  v-if="lineIsEmpty(scope.row)">
                                                Empty Line
                                            </span>
                                            <span class="ml-2 d-inline-block label black"
                                                  v-if="lineIsCallingOnly(scope.row)">
                                                Calling Only
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="incoming_number"
                                                     label="Numbers"
                                                     width="180"
                                                     align="left"
                                                     sortable>
                                        <template v-slot="scope">
                                            <el-tooltip class="item"
                                                        effect="dark"
                                                        content="View Details"
                                                        placement="bottom"
                                                        v-if="scope.row.incoming_numbers">
                                                <a @click.prevent="viewDetails(scope.row)"
                                                   v-if="hasPermissionTo('update campaign')">
                                                    <span class="text-dark-greenish"
                                                          v-if="scope.row.incoming_numbers.length > 1">
                                                        {{ scope.row.incoming_numbers.length }} Numbers
                                                    </span>
                                                    <span class="text-dark-greenish"
                                                          v-if="scope.row.incoming_numbers.length == 1">
                                                        {{ scope.row.incoming_numbers[0].phone_number | fixPhone }}
                                                    </span>
                                                </a>
                                                <router-link
                                                    :to="{ name: 'Line Activity', params: {campaign_id: scope.row.id }}"
                                                    v-else>
                                                    <span class="text-dark-greenish"
                                                          v-if="scope.row.incoming_numbers.length > 1">
                                                        {{ scope.row.incoming_numbers.length }} Numbers
                                                    </span>
                                                    <span class="text-dark-greenish"
                                                          v-if="scope.row.incoming_numbers.length == 1">
                                                        {{ scope.row.incoming_numbers[0].phone_number | fixPhone }}
                                                    </span>
                                                </router-link>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Routing"
                                                     width="160"
                                                     align="left">
                                        <template v-slot="scope">
                                            <template v-if="!scope.row.is_fax">
                                                <span class="text-greyish">
                                                    {{ scope.row.call_router_behavior | humanReadableCallRouterBehavior }}
                                                </span>
                                                <br>
                                                <router-link
                                                    v-if="scope.row.ring_group_id && getRingGroup(scope.row.ring_group_id)"
                                                    :to="{ name: 'Ring Group Dialog', params: {ring_group_id: scope.row.ring_group_id }}">
                                                    <span class="text-dark-greenish">{{ getRingGroup(scope.row.ring_group_id).name }}</span>
                                                </router-link>
                                                <router-link
                                                    v-else-if="scope.row.user_id && getUser(scope.row.user_id)"
                                                    :to="{ name: 'User Dialog', params: {user_id: scope.row.user_id }}">
                                                    <span class="text-dark-greenish">{{ getUserName(getUser(scope.row.user_id)) }}</span>
                                                </router-link>
                                            </template>
                                            <template v-else>
                                                <span class="text-greyish">
                                                    Fax
                                                </span>
                                            </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="active"
                                                     label="Status"
                                                     width="120"
                                                     align="left"
                                                     sortable>
                                        <template v-slot="scope">
                                            <span v-if="scope.row.active == true">Active</span>
                                            <span v-else>Paused</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Operations"
                                                     align="center"
                                                     width="120"
                                                     fixed="right">
                                        <template v-slot="scope">
                                            <div v-bind:style="getStyle(scope.row.id)">
                                                    <div class="row"
                                                        v-if="hasPermissionTo(['view campaign', 'list communication', 'view communication'])">
                                                        <div class="col-12">
                                                            <router-link
                                                                :to="{ name: 'Line Activity', params: {campaign_id: scope.row.id }}">
                                                                <button class="btn btn-sm btn-block purplish">
                                                                    <i class="fa fa-signal pull-left"></i>
                                                                    Activity
                                                                </button>
                                                            </router-link>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-2"
                                                        v-if="hasPermissionTo('update campaign')">
                                                        <div class="col-12">
                                                            <router-link
                                                                :to="{ name: 'Line Settings', params: {campaign_id: scope.row.id }}">
                                                                <button class="btn btn-sm btn-block info">
                                                                    <i class="fa fa-cogs pull-left"></i>
                                                                    Settings
                                                                </button>
                                                            </router-link>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-2"
                                                        v-if="hasPermissionTo('toggle active status campaign')">
                                                        <div class="col-12">
                                                            <button class="btn btn-sm btn-block"
                                                                    :class="scope.row.active == true ? 'deep-orange-500' : 'light-blue-500'"
                                                                    @click="toggleActive(scope.row.id)"
                                                                    :disabled="(loading_btn_id == scope.row.id)">
                                                                <i class="fa fa-spin fa-spinner pull-left"
                                                                v-if="loading_btn_id == scope.row.id"></i>
                                                                <template v-else>
                                                                    <i class="fa fa-pause pull-left"
                                                                    v-if="scope.row.active == true"></i>
                                                                    <i class="fa fa-play pull-left"
                                                                    v-else></i>
                                                                </template>
                                                                {{ scope.row.active == true ? 'Pause' : 'Activate' }}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-2"
                                                        v-if="hasPermissionTo('create campaign') && !scope.row.deleted_at && !isTrial">
                                                        <div class="col-12">
                                                            <button class="btn btn-sm btn-block blue"
                                                                    @click="duplicateCampaign(scope.row.id)">
                                                                <i class="fa fa-spin fa-spinner pull-left"
                                                                v-if="loading_duplicate_btn_id === scope.row.id"></i>
                                                                <i class="fa fa-copy pull-left"
                                                                v-else></i>
                                                                Duplicate
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-2"
                                                        v-if="hasPermissionTo('archive campaign')">
                                                        <div class="col-12">
                                                            <button class="btn btn-sm btn-block danger"
                                                                    @click="deleteCampaignRemote(scope.row.id, scope.row.has_local_presence)">
                                                                <i class="fa fa-trash pull-left"></i>
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div class="mt-3"
                                 v-if="pageCount >= 1">
                                <el-pagination class="pull-right"
                                               background
                                               layout="sizes, prev, pager, next"
                                               :page-sizes="[10, 20, 50, 100, 250]"
                                               :page-size="20"
                                               :current-page.sync="pagination.current_page"
                                               :total="dataCount"
                                               @current-change="changePage"
                                               @size-change="handleSizeChange">
                                </el-pagination>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <local-presence-wizard ref="localPresenceWizard"
                               v-if="!isSimpSocial"/>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>

        <video-modal class="pl-2"
                     title="Adding Phone Lines to Your Account 📞"
                     cookie-name="lines"
                     notes="🔥 Supercharge your phone system with <strong>Lines</strong>! </br></br>🚀 Handle calls, SMS, snag cool numbers, drop epic greetings, record, and unleash powerful IVR trees. Dive into each Line's buzzing activity.</br></br>Multi-line call tracking? Easy. A dedicated number for a department? Done. Elevate NOW! 🌟📞🔥"
                     video-url="https://www.youtube.com/embed/-hk1p0j0feI?si=a8BC36dxCjCz4yIc"
                     learn-more-link="https://support.aloware.com/en/articles/9031270-lines-menu-general-settings"
                     :has-activator-button="true"
                     v-if="isTrial && !isSimpSocial"/>

        <!-- ShortCode Wizard Modal -->
        <el-dialog
            :visible.sync="short_code_visible"
            width="70vw"
            top="4vh"
            title="Short Code"
            append-to-body
            custom-class="wizard-dialog dialog-no-border"
            data-testid="short-code-creator-dialog"
            @close="closeShortCodeWizardModal"
        >
            <div slot="title" data-testid="short-code-wizard-title" class="wz-title-wrapper">
                <h5 class="mb-0 wz-title-content">
                    <img
                        src="/assets/images/icons/short-code-icon.svg"
                        alt="Short Code Icon Shield"
                        class="wz-icon-size"
                    />
                    <span class="wz-title-text">Short Code</span>
                </h5>
            </div>
            <short-code-wizard
                ref="ShortCodeWizard"
                :onShortCodeWizardComplete="onShortCodeWizardComplete"
            />
        </el-dialog>

        <!-- NumberGuard Wizard Modal -->
        <el-dialog
            :visible.sync="number_guard_wizard_visible"
            width="70vw"
            top="4vh"
            title="Aloware Managed Compliance"
            append-to-body
            custom-class="wizard-dialog dialog-no-border"
            data-testid="number-guard-creator-dialog"
            @close="closeNumberGuardWizardModal">
            <div slot="title" data-testid="new-wizard-title" class="wz-title-wrapper">
                <h5 class="mb-0 wz-title-content">
                    <img src="/assets/images/icons/amc-shield-check-mark.svg"
                         alt="Number Guard Shield"
                         class="wz-icon-size" />
                    <span class="wz-title-text">NumberGuard</span>
                </h5>
            </div>
            <number-guard-wizard
                ref="NumberGuardWizard"
                :campaigns="campaigns">
            </number-guard-wizard>
        </el-dialog>
    </div>
</template>

<script>
import auth from '../../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import {
    acl_mixin,
    campaign_mixin,
    chargebee_mixin,
    kyc_mixin,
    paginator_mixin,
    styling_mixin,
    scroll_mixin,
    user_info_mixin
} from '../../mixins'
import _ from 'lodash'
import * as AnswerTypes from '../../constants/answer-types'
import * as CampaignCallRouterBehavior from '../../constants/campaign-call-router-behaviors'
import LineCreator from '../../components/line-creator'
import LocalPresenceWizard from '../../components/local-presence-wizard.vue'
import * as TrialStatus from "../../constants/trial-account-status"
import VideoModal from '../../components/video-modal.vue'
import VideoModalActivator from '../../components/video-modal-activator.vue'
import ShortCodeWizard from '../../components/account/compliance/short-code-wizard.vue'
import NumberGuardWizard from '../../components/account/compliance/number-guard-wizard.vue'

export default {
    mixins: [
        acl_mixin,
        campaign_mixin,
        chargebee_mixin,
        kyc_mixin,
        paginator_mixin,
        styling_mixin,
        scroll_mixin,
        user_info_mixin
    ],

    components: {
        NumberGuardWizard,
        LineCreator,
        LocalPresenceWizard,
        VideoModal,
        VideoModalActivator,
        ShortCodeWizard
    },

    data() {
        return {
            auth: auth,
            is_impersonated: localStorage.getItem('impersonate'),
            detailDialogVisible: false,
            loading: false,
            loading_btn_id: null,
            loading_duplicate_btn_id: null,
            selected_campaign: null,
            search_text: '',
            search_fields: ['id', 'name', 'incoming_number', 'incoming_numbers'],
            visible_row_id: null,
            campaign_filter: 'active',
            dataKey: 'filteredVisibleCampaigns',
            dataSort: 'id',
            dataSortType: 'ASC',
            show_help: false,
            reports_first_load: true,
            allowed_statuses: [
                'all',
                'active',
                'paused'
            ],
            allowed_types: [
                'all',
                'personal_lines',
                'ivrs',
                'ring_groups',
                'dead_ends',
                'toll-free',
            ],
            type_filter: 'All',
            whitelabel: false,
            loading_whitelabel: true,
            AnswerTypes,
            CampaignCallRouterBehavior,
            TrialStatus,
            short_code_visible: false,
            short_code_wizard_completed: false,
            number_guard_wizard_visible: false
        }
    },

    computed: {
        ...mapState(['campaigns', 'ring_groups']),
        ...mapState('cache', ['current_company', 'show_graph']),
        ...mapGetters('cache', ['isSimpSocial', 'isTrial']),

        isMobileSize() {
            return window.screen.width <= 425
        },

        visibleCampaigns() {
            if (this.campaigns) {
                if (this.search_text != '') {
                    this.campaign_filter = 'all'
                    let filtered = []
                    for (let campaign of this.campaigns) {
                        let keys = this.search_fields

                        for (let key of keys) {
                            if (key.indexOf('.') !== -1) {
                                let mixed = key.split(".")
                                if (campaign[mixed[0]]) {
                                    if (campaign[mixed[0]][mixed[1]].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                        filtered.push(campaign)
                                        break
                                    }
                                }
                            } else if (campaign[key]) {
                                if (key === 'incoming_numbers' && Object.keys(campaign[key]).length >= 1) {
                                    let incoming_number_obj = campaign[key].find(incoming_number =>
                                        [this.$options.filters.fixPhone(incoming_number.phone_number), incoming_number.phone_number].includes(this.search_text.toString().trim()) ||
                                        incoming_number.phone_number.toString().indexOf(this.search_text.toString().trim()) !== -1
                                    )
                                    if (incoming_number_obj) {
                                        filtered.push(campaign)
                                        break
                                    }
                                }

                                if (campaign[key].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                    filtered.push(campaign)
                                    break
                                }
                            }
                        }
                    }
                    return filtered
                } else if (this.campaign_filter == 'active') {
                    return this.campaigns.filter(campaign => campaign.active == true)
                } else if (this.campaign_filter == 'paused') {
                    return this.campaigns.filter(campaign => campaign.active == false)
                } else {
                    return this.campaigns
                }
            }

            return []
        },

        activeCount() {
            if (!this.campaigns || (this.campaigns && !this.campaigns.length)) {
                return 0
            }
            return this.filteredCampaigns.filter(campaign => campaign.active == true).length
        },

        allCount() {
            if (!this.campaigns || (this.campaigns && !this.campaigns.length)) {
                return 0
            }
            return this.filteredCampaigns.length
        },

        pausedCount() {
            if (!this.campaigns || (this.campaigns && !this.campaigns.length)) {
                return 0
            }
            return this.filteredCampaigns.filter(campaign => campaign.active == false).length
        },

        filteredVisibleCampaigns() {
            if (this.visibleCampaigns) {
                return this.filterByType(this.visibleCampaigns)
            }

            return []
        },

        filteredCampaigns() {
            if (this.campaigns) {
                return this.filterByType(this.campaigns)
            }

            return []
        },

        isAnImpersonatedSimpSocialCompany() {
            if (this.current_company && this.current_company.hasOwnProperty('reseller_id')) {
                return this.current_company.reseller_id === 357 &&
                    this.is_impersonated
            }

            return false
        },

        isSimpSocialRelatedOrNotAReseller() {
            if (this.current_company && this.current_company.hasOwnProperty('reseller_id')) {
                return !this.current_company.reseller_id ||
                    this.isAnImpersonatedSimpSocialCompany ||
                    this.current_company.reseller_id !== 357
            }

            return false
        },

        canCreateFaxAndNewLine() {
            return this.current_company && this.isSimpSocialRelatedOrNotAReseller
        },

        shouldShowLPPurchaseButton() {
            return this.auth.user.profile.role_names.includes('Billing Admin') && this.current_company?.trial_status === TrialStatus.TRIAL_STATUS_SUCCESS && !this.isSimpSocial
        },

        shortCodeWizardUsed() {
            return (this.current_company.short_code_requested_at !== null) || this.short_code_wizard_completed
        },

        shouldShowShortCode() {
            return !this.isTrial && !this.isSimpSocial
        },

        shouldShowNumberGuard() {
            return !this.isTrial && !this.isSimpSocial
        },

        shouldShowReport() {
            return this.hasPermissionTo('list report') && !this.search_text;
        }
    },

    created() {
        this.getWhitelabelStatus()
    },

    mounted() {
        this.setTitle()
        if (!this.hasPermissionTo('list campaign')) {
            this.goBack()
        }
        if (this.current_company && this.current_company.reseller_id == 357 && !this.hasRole('Billing Admin')) {
            this.goBack()
        }
        this.search_text = ''
        if (this.$route.query && typeof this.$route.query.status != 'undefined' && this.$route.query.status) {
            this.campaign_filter = this.allowed_statuses.includes(this.$route.query.status) ? this.$route.query.status : this.campaign_filter
        }
        if (this.$route.query && typeof this.$route.query.type != 'undefined' && this.$route.query.type) {
            this.type_filter = this.allowed_types.includes(this.$route.query.type.replace(/\s/g, "_").toLowerCase()) ? this.$route.query.type : this.type_filter
        }

        // focus line search input on page visit
        if (this.$refs.lineSearch) {
            this.$refs.lineSearch.focus()
        }
    },

    methods: {
        getWhitelabelStatus() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.whitelabel = res.data.whitelabel
                this.loading_whitelabel = false
            }).catch(err => {
                console.log(err)
                this.loading_whitelabel = false
                this.$root.handleErrors(err.response)
            })
        },

        filterByType(campaigns) {
            switch (this.type_filter) {
                case 'Ring Groups':
                    return campaigns.filter(campaign => campaign.call_router_behavior == CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD && campaign.ring_group_id)
                case 'Personal Lines':
                    return campaigns.filter(campaign => campaign.call_router_behavior == CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD && campaign.user_id)
                case 'IVRs':
                    return campaigns.filter(campaign => campaign.call_router_behavior == CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_IVR)
                case 'Dead ends':
                    return campaigns.filter(campaign => campaign.call_router_behavior == CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END)
                case 'Local Presence':
                    return campaigns.filter(campaign => campaign.has_local_presence === true)
                case 'Toll-Free':
                    return campaigns.filter(campaign => campaign.has_tollfree_pn === true)
                default:
                    return campaigns
            }
        },

        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Lines - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Lines - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        reDesign: function (should_record) {
            if (should_record == 1) {
                return '<i class="fa fa-check text-success inline"></i>'
            } else {
                return '<i class="fa fa-times text-danger inline"></i>'
            }
        },

        deleteCampaignRemote: function (campaign_id, is_local_presence = false) {
            if (this.campaigns) {
                let campaign = this.campaigns.find(cmp => cmp.id === campaign_id)
                let modal_body = 'Deleting a line will permanently remove it from all reports.<br>The phone numbers in this line will also be removed.'
                let modal_title = 'Warning, are you sure you want to delete?'

                if (is_local_presence) {
                    modal_title = 'Deleting local presence'
                    modal_body = 'This is a local presence line that is critical to your operations. Please contact us to delete it.'

                    this.$alert(modal_body, modal_title, {
                        confirmButtonText: 'Ok',
                    })

                    return
                }

                this.$confirm(modal_body, modal_title, {
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Back',
                    customClass: 'width-500 fixed',
                    dangerouslyUseHTMLString: true,
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    axios.delete('/api/v1/campaign/' + campaign_id)
                        .then(res => {
                            this.deleteCampaign(campaign)
                            this.loading = false
                        })
                        .catch(err => {
                            this.$root.handleErrors(err.response)
                            this.loading = false
                        })
                }).catch(() => {

                })
            }
        },

        toggleActive(campaign_id) {
            if (this.campaigns) {
                let campaign = this.campaigns.find(cmp => cmp.id === campaign_id)
                let new_state = campaign.active == true ? 'pause' : 'activate'
                this.$confirm('This will ' + new_state + ' the line. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(() => {
                    this.loading_btn_id = campaign_id
                    axios.post('/api/v1/campaign/' + campaign_id + '/toggle-active')
                        .then(res => {
                            this.updateCampaign(res.data)
                            this.loading_btn_id = null
                        })
                        .catch(err => {
                            this.$root.handleErrors(err.response)
                            this.loading_btn_id = null
                        })
                }).catch(() => {

                })
            }
        },

        editCampaign: function (campaign_id) {
            this.$router.push({name: 'Line Settings', params: {campaign_id: campaign_id}})
        },

        viewDetails: function (campaign) {
            this.selected_campaign = campaign
            this.detailDialogVisible = true
        },

        cellEnter: _.throttle(function (row, column, cell, event) {
            this.visible_row_id = row.id
        }, 50),

        tableLeave() {
            this.visible_row_id = null
        },

        getStyle(id) {
            let opacity = 0.5
            if (id == this.visible_row_id) {
                opacity = 1
            }

            return {
                opacity: opacity
            }
        },

        changedFilter() {
            this.search_text = ''
            this.pagination.current_page = 1
            this.$router.push({name: 'Lines', query: {type: this.type_filter, status: this.campaign_filter}})
        },

        getRingGroup(id) {
            if (!id) {
                return null
            }
            let found = this.ring_groups.find(ring_group => ring_group.id === id)
            if (found) {
                return found
            }

            return null
        },

        toggleHelp() {
            this.show_help = !this.show_help
        },

        duplicateCampaign(campaign_id) {
            const campaign = this.campaigns.find(campaign => campaign.id === campaign_id)
            const message = 'Are you sure you want to make a duplicate of <strong class="text-primary">' + campaign.name + '</strong>?'
            this.$confirm(message, 'Warning', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_duplicate_btn_id = campaign_id
                axios.post('/api/v1/campaign/' + campaign_id + '/duplicate')
                    .then(res => {
                        this.loading_duplicate_btn_id = null
                        this.$notify({
                            offset: 95,
                            title: 'Line',
                            dangerouslyUseHTMLString: true,
                            message: 'Line duplicated.<br>Please refresh the page to get the new one.',
                            type: 'success',
                            showClose: true,
                        })
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_duplicate_btn_id = null
                    })
            }).catch(() => {

            })
        },

        openLocalPresenceWizard() {
            this.$refs.localPresenceWizard.open()
        },

        lineHasLocal(line) {
            // check we have any incoming number that has is_local flag set to true
            return line.incoming_numbers?.length > 0 && line.incoming_numbers.some(number => number.is_local && !this.isUkNumber(number.phone_number))
        },

        lineHasTollFree(line) {
            // check we have any incoming number that has is_tollfree flag set to true
            return line.incoming_numbers?.length > 0 && line.incoming_numbers.some(number => number.is_tollfree)
        },

        hasShortCode(line) {
            // check we have any incoming number that has is_tollfree flag set to true
            return line.incoming_numbers?.length > 0 && line.incoming_numbers.some(number => number.is_short_code)
        },

        lineHasInternationalMobile(line) {
            // check we have any incoming number that has is_international_mobile flag set to true
            return line.incoming_numbers?.length > 0 && line.incoming_numbers.some(number => number.is_international_mobile)
        },

        lineHasInternationalLocal(line) {
            // check we have any incoming number that has is_international_local flag set to true
            return line.incoming_numbers?.length > 0 && line.incoming_numbers.some(number => number.is_international_local)
        },

        lineHasFax(line) {
            // check we have any incoming number that has is_fax flag set to true
            return line.incoming_numbers?.length > 0 && line.incoming_numbers.some(number => number.is_fax)
        },

        lineHasFake(line) {
            // check we have any incoming number that has is fake flag set to true
            return line.incoming_numbers?.length > 0 && line.incoming_numbers.some(number => {
                return number.sid && typeof number.sid === 'string' && number.sid.includes('fake-')
            })
        },

        lineIsEmpty(line) {
            // check we have no incoming numbers
            return line.incoming_numbers?.length === 0
        },

        lineIsCallingOnly(line) {
          return line.calling_only
        },

        openShortCodeWizardModal() {
            if (this.$refs.ShortCodeWizard) {
                this.$refs.ShortCodeWizard.resetWizard()
            }
            this.short_code_visible = true
        },

        closeShortCodeWizardModal() {
            this.short_code_visible = false
        },

        onShortCodeWizardComplete() {
            this.short_code_wizard_completed = true
        },

        openNumberGuardWizardModal() {
            if (this.$refs.NumberGuardWizard) {
                this.$refs.NumberGuardWizard.resetWizard();
            }
            this.number_guard_wizard_visible = true
        },

        closeNumberGuardWizardModal() {
            this.number_guard_wizard_visible = false
        },

        ...mapActions(['deleteCampaign', 'updateCampaign'])
    },

    watch: {
        'show_graph': function () {
            this.reports_first_load = false
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
