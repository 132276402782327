<template>
    <div>
        <!--send a Car-->
        <el-dialog title="Send a Car"
                   class="auto pb-3"
                   id="dialog-simpsocial-car"
                   top="4vh"
                   :width="isLargeEnough ? '40%' : '100%'"
                   :visible.sync="show_add"
                   :before-close="beforeCloseModal"
                   :close-on-click-modal="false"
                   append-to-body>
            <el-form ref="search_car"
                     class="p-3 border-bottom"
                     label-position="top"
                     :rules="rules_search_car"
                     :model="search_car"
                     @submit.prevent.native="searchCar(contact_id, true)">
                <el-form-item prop="q"
                              class="pb-0">
                    <el-input placeholder="Search for model, make, vin, stock #"
                              prefix-icon="el-icon-search"
                              v-model="search_car.q">
                        <el-button slot="append"
                                   type="submit"
                                   @click="searchCar(contact_id, true)">
                            <i class="fa fa-search"></i>
                        </el-button>
                    </el-input>
                </el-form-item>

                <el-form-item label="Price range:"
                              class="pb-0 mb-0">
                    <div class="pl-2 pr-2">
                        <el-slider v-model="price_range"
                                   :min="0"
                                   :max="100000"
                                   :step="1000"
                                   :format-tooltip="formatTooltip"
                                   range
                                   @change="updatePriceRange(contact_id)">
                        </el-slider>
                    </div>
                </el-form-item>
            </el-form>

            <div class="container-cars"
                 v-infinite-scroll="load">
                <div class="row pb-3 mb-2"
                     v-if="cars.length > 0"
                     v-for="car in cars"
                     :key="car.id">
                    <div class="col-12">
                        <el-card class="box-card">
                            <div slot="header">
                                <div class="row">
                                    <div class="col align-self-center">
                                        <el-switch v-model="car.hide_price" active-text="Hide price?" class="align-self-center">
                                        </el-switch>
                                    </div>
                                    <div class="col">
                                        <el-button type="primary"
                                                   class="w-full"
                                                   @click="sendCar(contact_id, car, false)">
                                            <b>Send Link</b>
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                            <img :src="car.galleries[0].url"
                                 v-if="car.galleries && car.galleries.length > 0 && car.galleries[0].url"
                                 class="image img-responsive"/>
                            <div class="p-2">
                                <h5><span class="_600 d-flex">Vin #: {{ car.vin }}</span></h5>
                                <b>Text to Customer:</b>
                                <p>{{ car.description | truncate(100) }}</p>
                                <template v-if="car.year">
                                    <b>Year:</b>
                                    <span>{{ car.year }}</span>
                                    <br>
                                </template>
                                <template v-if="car.make">
                                    <b>Make:</b>
                                    <span>{{ car.make }}</span>
                                    <br>
                                </template>
                                <template v-if="car.model">
                                    <b>Model:</b>
                                    <span>{{ car.model }}</span>
                                    <br>
                                </template>
                                <template v-if="car.trim">
                                    <b>Trim:</b>
                                    <span>{{ car.trim }}</span>
                                    <br>
                                </template>
                                <template v-if="car.colour">
                                    <b>Color:</b>
                                    <span>{{ car.colour }}</span>
                                    <br>
                                </template>
                                <template v-if="car.interior_color">
                                    <b>Interior Color:</b>
                                    <span>{{ car.interior_color }}</span>
                                    <br>
                                </template>
                                <template v-if="car.odometer">
                                    <b>Mileage:</b>
                                    <span>{{ car.odometer | numFormat }}</span>
                                    <br>
                                </template>
                                <template v-if="car.price">
                                    <b>Price:</b>
                                    <span>{{ car.price | toCurrency }}</span>
                                    <br>
                                </template>
                            </div>
                        </el-card>
                    </div>
                </div>
                <div v-if="loading"
                     class="height-80"
                     style="background-color: transparent"
                     v-loading="true">
                </div>

                <div class="text-center"
                     v-if="no_next && !loading && cars.length > 0">
                    <strong class="text-success">
                        <i class="fa fa-check-circle"></i> All data is loaded
                    </strong>
                </div>

                <div v-if="pagination.total == 0 && !loading">
                    <p class="text-center py-5 text-danger">
                        No search result
                        <span v-if="searched_term.length > 0">for "<strong>{{ searched_term }}</strong>"</span>
                    </p>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import auth from '../auth'
    import {
        acl_mixin,
        form_validation_mixin
    } from '../mixins'
    import {mapState} from 'vuex'
    import VariableDialog from "./variable-dialog"
    import TemplateListDialog from "./template-list-dialog"
    import _ from "lodash"

    const _send_car_default = () => {
        return {
            campaign_id: null,
            body: '',
            image_url: ''
        }
    }

    const _search_car_default = () => {
        return {
            q: '',
            page: 0,
            page_size: 20,
            price_min: 0,
            price_max: 500000
        }
    }

    export default {
        components: {TemplateListDialog, VariableDialog},

        mixins: [
            acl_mixin,
            form_validation_mixin
        ],

        props: ['contact_id', 'selected_campaign_id'],

        data() {
            return {
                auth: auth,
                loading: false,
                loading_btn: false,
                loading_send: false,
                show_add: false,
                search_car: _search_car_default(),
                send_car: _send_car_default(),
                price_range: [0, 100000],
                cars: [],
                rules_search_car: {},
                bulk_message_mode: false,
                pagination: {
                    current_page: 1,
                    total_pages: 100, // temp value
                    total: 0
                },
                searched_term: ''
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            isLargeEnough() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            },

            no_next: function () {
                return this.pagination.current_page >= this.pagination.total_pages
            }
        },

        mounted() {
            this.send_car.campaign_id = this.selected_campaign_id
        },

        methods: {
            formatTooltip(val) {
                return this.$options.filters.toCurrency(val)
            },

            debouncedSearchCar: _.debounce(function (contact_id) {
                this.searchCar(contact_id, true)
            }, 500),

            changeCampaignId(val) {
                this.send_car.campaign_id = val
                this.preValidateForm('search_car')
            },

            searchCar(contact_id, reset = false) {
                if (reset) {
                    this.search_car.page = 1
                    this.pagination.total_pages = 100
                    this.cars = []
                }

                if (!this.no_next) {
                    this.loading_btn = true
                    this.loading = true

                    this.search_car.contact_id = contact_id

                    let url = `/integrations/simpsocial/cars`
                    axios.get(url, {
                        params: this.search_car
                    }).then(res => {
                        this.cars = _.concat(this.cars, res.data.data)
                        this.loading = false
                        this.pagination = res.data.pagination
                        this.search_car.page = res.data.pagination.current_page
                        this.searched_term = this.search_car.q
                    }).catch(err => {
                        this.$root.handleErrors(err.response)
                    }).then(_ => {
                        this.loading_btn = false
                    })
                }
            },

            load() {
                if (!this.loading) {
                    this.search_car.page += 1
                    this.searchCar(this.contact_id)
                }
            },

            startInventory(campaign_id) {
                this.send_car.campaign_id = campaign_id
                this.show_add = true

                // this.searchCar(this.contact_id)
            },

            updatePriceRange(contact_id) {
                if (!this.price_range || this.price_range.length !== 2) {
                    return
                }

                this.search_car.price_min = this.price_range[0]
                this.search_car.price_max = this.price_range[1]
                this.searchCar(contact_id, true)
            },

            sendCar(contact_id, car, has_credit_application = false) {
                // Send to contact when not bulked_message_mode
                if (!this.bulk_message_mode) {
                    this.loading_btn = true
                    let url = `/api/v1/contact/${contact_id}/send-car`
                    if (car.galleries && car.galleries.length > 0 && car.galleries[0].url) {
                        this.send_car.image_url = car.galleries[0].url
                    }

                    this.send_car.body = this.$options.filters.truncate(car.description, 100)
                    this.send_car.agent_link = car.agent_link
                    this.send_car.has_credit_link = has_credit_application
                    this.send_car.hide_price = car.hide_price

                    axios.post(url, this.send_car).then(res => {
                        this.show_add = false
                        this.$emit('success', res.data)
                        this.resetSendCar()
                    }).catch(err => {
                        this.$root.handleErrors(err.response)
                    }).then(_ => {
                        this.loading_btn = false
                    })
                } else {
                    this.$emit('send-car', car)
                    this.show_add = false
                    this.loading_btn = false
                }
            },

            resetSendCar() {
                this.send_car = _send_car_default()
                this.search_car = _search_car_default()
                let form_element = _.get(this.$refs, 'search_car', null)

                if (form_element) {
                    form_element.clearValidate()
                }

                this.send_car.campaign_id = this.selected_campaign_id
                this.cars = []
            },

            beforeCloseModal(done) {
                if (this.send_car.body || this.send_car.image_url) {
                    this.$confirm('Are you sure you want to leave?', 'Warning', {
                        confirmButtonText: 'Yes, Leave',
                        cancelButtonText: 'No, Stay',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(res => {
                        this.resetSendCar()
                        done()
                    }).catch(() => {

                    })
                } else {
                    this.resetSendCar()
                    done()
                }
            }
        },

        watch: {
            show_add() {
                if (this.show_add) {
                    this.$emit('show')
                } else {
                    this.$emit('hide')
                }
            }
        }
    }
</script>

<style lang="scss">
    #dialog-simpsocial-car {
        .el-dialog__header {
            padding: 1rem;
            border-bottom: 1px solid #dedede;
        }

        .el-dialog__body {
            background-color: #fafafa;
            padding: 0;
        }

        .container-cars {
            max-height: 65vh;
            height: 65vh;
            width: 100%;
            overflow-y: scroll;
            padding: 1rem;
        }
    }
</style>
