<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h5 class="text-dark">
                    <i class="el-icon-top-right"></i>
                    <span v-if="bot.type === AloAi.TYPE_TEXT && bot.direction === AloAi.DIRECTION_OUTBOUND">Opener Message</span>
                    <span v-else>Greeting Message</span>
                </h5>

                <el-form-item prop="opener">
                    <div class="form-label">
                        <small v-if="bot.type === AloAi.TYPE_TEXT">
                            The first message the agent sends to the contact. Use variables to personalize it.
                        </small>
                        <small v-else>
                            The first message the agent says to the contact. Use variables to personalize it.
                        </small>
                    </div>
                    <el-input v-model="bot.opener"
                              type="textarea"
                              ref="openerInput"
                              @input="preValidateForm('agent_settings')"
                              @focus="onOpenerFocus"
                              @blur="onOpenerBlur">
                    </el-input>
                    <el-tooltip content="Add variable"
                                placement="top"
                                v-if="bot.allow_access_contact_information"
                    >
                        <variables @variable-selected="variableSelectedOnOpener"/>
                    </el-tooltip>
                    <hr class="mb-0 mt-1">
                </el-form-item>
            </div>
            <div class="col-md-12">
                <h5 class="text-dark">
                    <i class="el-icon-document"></i>
                    Instructions
                </h5>
                <el-form-item prop="instructions">
                    <div class="form-label">
                        <small>
                            Choose a template to quickly start with predefined instructions for common agent roles:
                        </small>
                    </div>

                    <template-selector
                        v-if="isCompanyPartOfAlowareDemoCompanies(false)"
                        ref="templateSelector"
                        @template-selected="onTemplateSelected"
                    />

                    <div class="form-label">
                        <small>
                            Write a personality for the agent and try to explain what you want the agent to perform in details.
                        </small>
                    </div>

                    <formatted-instructions-input
                        class="w-100 aloai-instructions"
                        ref="textareaInput"
                        v-model="bot.instructions[0].content"
                        :rows="14"
                        :readonly="loadingTags"
                        :minRows="14"
                        :isEditing="isEditing"
                        @update:isEditing="enableEditMode"
                        @input="emitValidate"
                        @focus="onInstructionsFocus"
                        @blur="onInstructionsBlur"
                    />

                    <div class="d-flex align-items-center">
                        <template v-if="!isEditing">
                            <el-button
                                type="success"
                                plain
                                size="mini"
                                icon="el-icon-edit"
                                class="my-2 ml-auto"
                                @click="enableEditMode"
                            >
                                Edit
                            </el-button>
                        </template>
                        <template v-else>
                            <el-tooltip content="Add variable"
                                        placement="top"
                                        v-if="bot.allow_access_contact_information">
                                <variables @variable-selected="onVariableSelected"/>
                            </el-tooltip>

                            <tool-selector
                                ref="toolSelector"
                                :selected-disposition="currentDisposition"
                                :custom-tags="currentTags"
                                :selected-tags-ids="currentTagsIds"
                                :custom-lists="currentLists"
                                :selected-list-ids="currentListsIds"
                                :selected-campaign="selectCampaign"
                                :loading="loadingTags"
                                :available-tools="toolsList"
                                :instructions-content="bot.instructions[0].content"
                                @variable-selected="onVariableSelected"
                                @property-selected="onPropertySelected"
                                @campaign-selected="onCampaignSelected"
                                @send-sms-selected="onSmsSelected"
                                @create-appointment-selected="onCreateAppointmentSelected"
                                @disposition-selected="onDispositionSelected"
                                @tags-selected="onTagsSelected"
                                @lists-selected="onListsSelected"
                                @disengage-contact-selected="onDisengageContactSelected"
                                @custom-function-selected="onCustomFunctionSelected"
                            />

                            <el-button
                                type="success"
                                plain
                                size="mini"
                                class="ml-2 my-2"
                                @click="preSaveInstructions"
                            >
                                Save
                            </el-button>
                        </template>
                    </div>
                    <hr class="mb-0 mt-1">
                </el-form-item>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h5 class="text-dark">
                    <i class="el-icon-collection"></i>
                    Knowledge Base Files
                </h5>
                <el-form-item class="mb-0" v-loading="loading">
                    <div class="form-label">
                        <small>
                            This help AloAi Bot give more accurate responses by learning from your uploaded documents. This makes replies smarter, faster, and more consistent while improving over
                            time.
                        </small>
                        <small v-if="bot.id"><i>Supported formats are: docx, json, md, pdf, pptx, txt.</i></small>
                    </div>

                    <multi-upload
                        v-if="bot.id && bot.model !== AloAi.MODEL_GPT_35_T"
                        :action="getAction"
                        :delete-action="deleteAction"
                        :showFileList=false
                        @success="successFileUpload"
                        @failed="failedFileUpload"
                        @before-upload="beforeFileUpload">
                    </multi-upload>
                    <p class="text-md _400 text-danger"
                       v-if="bot.model === AloAi.MODEL_GPT_35_T">
                        You can't have KB files with this model.
                    </p>

                    <p class="text-md _400 text-primary"
                       v-if="!bot.id">
                        You need to create an AloAi Bot before uploading files.
                    </p>

                    <el-table class="w-full"
                              row-key="id"
                              :data="knowledge_base_files"
                              v-if="bot.id && knowledge_base_files"
                              stripe>
                        <el-table-column label="Name">
                            <template v-slot="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Uploaded At">
                            <template v-slot="scope">
                                <span>{{ scope.row.created_at | fixDateTime }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Uploaded by">
                            <template v-slot="scope">
                                <span>{{ scope.row.created_by.name + ' - #' + scope.row.created_by.id }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            width="100"
                            label="Status"
                            align="center"
                        >
                            <template v-slot="scope">
                                <span v-if="scope.row.status === 'PROCESSING'" class="ml-2 d-inline-block label yellow">Processing</span>
                                <span v-if="scope.row.status === 'SUCCESS'" class="ml-2 d-inline-block label green">Success</span>
                                <span v-if="scope.row.status === 'FAILED'" class="ml-2 d-inline-block label red">Error</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            width="50"
                            align="center"
                        >
                            <template v-slot="scope">
                                <div class="d-flex">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="Delete"
                                        placement="top">
                                        <div
                                            class="hover-lower-opacity-danger"
                                            @click="handleKnowledgeBaseFileDelete(scope.row)">
                                            <i style="font-size:17px" class="fa fa-trash-o" aria-hidden="true"/>
                                        </div>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </div>
        </div>
    </div>
</template>
<script>
import {MessageBox} from 'element-ui'
import cloneDeep from 'lodash/cloneDeep'
import {mapState} from 'vuex'
import {aloai_mixin, form_validation_mixin, acl_mixin} from '../../../mixins'
import Variables from '../../messenger/variables.vue'
import MultiUpload from '../../multi-upload.vue'
import CampaignsDropdown from './campaigns.vue'
import DispositionsDropdown from './dispositions.vue'
import FormFunctions from './function/index.vue'
import ListsDropdown from './lists.vue'
import TagsDropdown from './tags.vue'
import ToolSelector from './tool-selector.vue'
import FormattedInstructionsInput from './formatted-instructions-input.vue'
import * as AloAi from '../../../constants/aloai'
import TemplateSelector from './template-selector.vue'
export default {
    name: 'AloAiSettings',

    mixins: [
        form_validation_mixin,
        aloai_mixin,
        acl_mixin,
    ],

    components: {
        MultiUpload,
        FormFunctions,
        TagsDropdown,
        DispositionsDropdown,
        ListsDropdown,
        CampaignsDropdown,
        Variables,
        ToolSelector,
        FormattedInstructionsInput,
        TemplateSelector,
    },

    props: {
        bot: {
            required: true,
        },
        toolsList: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            loading: false,
            knowledge_base_files: this.bot.knowledge_base_files,
            tags: [],
            currentTags: [], // To store tags for the current section
            currentTagsIds: [],
            readonlySections: [],
            currentSection: {},
            cursorPosition: 0,
            openerCursorPosition: 0, // Separate cursor position for opener
            currentDisposition: {},
            tagsDropdownKey: 0, // Key to force re-render of tags-dropdown
            dispositionsDropdownKey: 0,
            isEditingReadonlySection: false,
            loadingTags: false,
            AloAi,
            lists: [],
            currentLists: [],
            currentListsIds: [],
            selectCampaign: null,
            isEditing: false, // Track focus state of instructions input
            currentTemplateId: 0, // Track which template is currently applied
            currentTemplateContent: '', // Track original template content
            specialElements: ['{Create appointment with:', '{Disengage with the contact}', '{Send SMS:', '{Run this function:', '{Collect and update:', '{Transfer to this line:']
        }
    },

    computed: {
        ...mapState(['campaigns']),
        getAction() {
            return this.bot.id ? '/api/v1/aloai/bot/' + this.bot.id + '/upload-file' : '/api/v1/aloai/upload-file'
        },

        deleteAction() {
            return this.bot.id ? '/api/v1/aloai/bot/' + this.bot.id + '/delete-file' : '/api/v1/aloai/delete-file'
        },
    },

    methods: {
        preSaveInstructions() {
            this.disableEditMode()

            this.saveInstructions()
        },
        enableEditMode() {
            this.isEditing = true
        },
        disableEditMode() {
            this.isEditing = false
        },
        updateBot() {
            this.loading = true
            axios.get('/api/v1/aloai/bot/' + this.bot.id)
                .then((res) => {
                    this.knowledge_base_files = res.data.knowledge_base_files
                })
                .catch((err) => {
                    console.error(err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        handleKnowledgeBaseFileDelete(row) {
            MessageBox.confirm('Are you sure you want to delete this file?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(async () => {
                this.loading = true

                axios.delete(this.deleteAction, {
                    data: {
                        uuid: row.uuid,
                    }
                }).then((res) => {
                    this.updateBot()
                    this.$notify({
                        offset: 95,
                        title: 'File Delete',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                    })
                }).finally(() => {
                    this.loading = false
                })
            }).catch(() => {
                // do nothing
            })
        },
        failedFileUpload() {
            this.loading = false
        },
        successFileUpload() {
            this.loading = false
            this.updateBot()
        },
        beforeFileUpload() {
            this.loading = true
        },
        handleSectionUpdate(template, sectionData, skipCollectInstructions = false) {
            let newContent = this.bot.instructions[0].content

            // Check if cursor is within any readonly section
            let updated = false
            const updatedSectionData = this.updateReadOnlySection(newContent, template, sectionData)
            updated = updatedSectionData.isUpdated
            newContent = updatedSectionData.newContent

            // If no section was updated, insert at the cursor position
            if (!updated) {
                newContent = this.createReadOnlySection(newContent, template, sectionData)
            }

            this.bot.instructions[0].content = newContent
            
            if (!skipCollectInstructions) {
                this.collectSpecialInstructionsToSave()
            }
            
            return newContent
        },
        onDispositionSelected(disposition) {
            if (!disposition) {
                // check if cursor is withing any readonly section and remove it
                this.removeReadonlySection()
                return
            }

            const dispositionName = `${disposition.id}: ${disposition.name}`
            const dispositionNameTemplate = `( ${disposition.id}: ${disposition.name} )`
            
            const template = `{Add ${dispositionNameTemplate} disposition to the contact}`
            const sectionData = {
                disposition: cloneDeep(disposition),
                dispositionName: dispositionName
            }

            this.handleSectionUpdate(template, sectionData)
        },
        async onTagsSelected(tags) {
            if (!tags.length) {
                // check if cursor is withing any readonly section and remove it
                this.removeReadonlySection()
                return
            }

            await this.getTags(tags)

            // join the tags name into a separated string with each tag wrapped in square brackets
            const joinedTags = this.tags.map((tag) => `[ ${tag.name} ]`).join(' ')

            const template = `{Add ${joinedTags} tag(s) to the contact}`
            const sectionData = {
                tags: [...this.tags],
                tagNames: this.transformTagsString(joinedTags)
            }

            this.handleSectionUpdate(template, sectionData)
        },
        onPropertySelected(property) {
            this.selectProperty = property

            const template = `{Collect and update: ${property.slug}}`
            const sectionData = { property: property }
    
            this.handleSectionUpdate(template, sectionData)
        },
        onCampaignSelected(id) {
            const campaign = this.getCampaign(id)
            this.selectCampaign = id

            const template = `{Transfer to this line: ${campaign.name}|${campaign.id}}`
            const sectionData = { campaign: campaign }

            this.handleSectionUpdate(template, sectionData)
        },
        onSmsSelected(message) {
            if (!message) {
                // check if cursor is withing any readonly section and remove it
                this.removeReadonlySection()
                return
            }

            const template = `{Send SMS: '${message}'}`
            const sectionData = { sms: message }

            this.handleSectionUpdate(template, sectionData)
        },
        onListsSelected(listIds, listItems) {
            if (!listIds.length) {
                // check if cursor is withing any readonly section and remove it
                this.removeReadonlySection()
                return
            }

            // join the tags name into a separated string with each tag wrapped in square brackets
            const joinedLists = listItems.filter((item) => listIds.includes(item.id)).map((item) => {
                return `[ ${item.name} ]`
            }).join(' ')

            const template = `{Add contact to ${joinedLists} list(s)}`
            const sectionData = {
                lists: [...this.lists],
                listNames: this.transformListsString(joinedLists)
            }

            this.handleSectionUpdate(template, sectionData)
        },
        async addDisengageTrigger() {
            if (this.isEditingReadonlySection) {
                return
            }
            
            const template = '{Disengage with the contact}'
            const sectionData = { disengageContact: true }
            
            this.handleSectionUpdate(template, sectionData, true)
        },
        removeReadonlySection() {
            // check if cursor is withing any readonly section and remove it
            for (const section of this.readonlySections) {
                if (this.cursorPosition >= section.start && this.cursorPosition <= section.end) {
                    this.bot.instructions[0].content = this.bot.instructions[0].content.slice(0, section.start) + this.bot.instructions[0].content.slice(section.end)
                    this.cursorPosition = section.start

                    //remove the section
                    this.readonlySections = this.readonlySections.filter(s => s.start !== section.start)
                    this.resetTagsDropdown()
                    this.resetDispositionsDropdown()
                    this.updateReadonlySections()
                    break
                }
            }
            
            // Check for special elements not properly tracked in readonlySections
            for (const template of this.specialElements) {
                this.removeSpecialElement(template)
            }
        },
        
        removeSpecialElement(template) {
            const content = this.bot.instructions[0].content
            const templateIndex = content.indexOf(template, Math.max(0, this.cursorPosition - template.length))
            
            // If special templates with content after colon
            if (
                template === '{Send SMS:' || 
                template === '{Create appointment with:' ||
                template === '{Run this function:' ||
                template === '{Collect and update:' ||
                template === '{Transfer to this line:'
            ) {
                // If the template is found near the cursor position
                if (templateIndex >= 0 && Math.abs(templateIndex - this.cursorPosition) <= template.length) {
                    // Find the closing brace
                    const endPos = content.indexOf('}', templateIndex)
                    if (endPos >= 0 && this.cursorPosition <= endPos + 1) {
                        // Remove the template including closing brace
                        this.bot.instructions[0].content = content.slice(0, templateIndex) + content.slice(endPos + 1)
                        this.cursorPosition = templateIndex
                        this.updateReadonlySections()
                    }
                }
            } else {
                // For simpler templates
                // If the template is found near the cursor position
                if (templateIndex >= 0 && Math.abs(templateIndex - this.cursorPosition) <= template.length) {
                    // Remove the template
                    this.bot.instructions[0].content = content.slice(0, templateIndex) + content.slice(templateIndex + template.length)
                    this.cursorPosition = templateIndex
                    this.updateReadonlySections()
                }
            }
        },

        transformTagsString(input) {
            // Use regular expression to find the occurrences of " ] [ "
            const regex = /] \[ /g

            return input
                .replace(regex, '|| ') // Replace " ] [ " with " || "
                .replace(/\[|\]/g, '') // Remove remaining brackets
        },

        reverseTransformTagsString(input) {
            // Use regular expression to find all occurrences of "{Add "..." tag(s) to the contact}"
            const regex = /\{Add\s+"([^"]+)"\s+tag\(s\)\s+to\s+the\s+contact\}/g

            // Replace each occurrence found with the transformed version
            const transformed = input.replace(regex, (match, p1) => {
                // Split tags by " || "
                const tags = p1.split('||').map(tag => tag.trim())

                // Create the new string with square brackets around each tag
                const bracketedTags = tags.map(tag => `[ ${tag} ]`).join(' ')

                // Replace the content of the tags in the original string
                return `{Add ${bracketedTags} tag(s) to the contact}`
            })

            return transformed
        },

        reverseTransformDispositionsString(input) {
            // Use regular expression to find all occurrences of "{Add '...' disposition to the contact}"
            const regex = /\{Add\s+'([^']+)'\s+disposition\s+to\s+the\s+contact\}/g

            // Replace each occurrence found with the transformed version
            const transformed = input.replace(regex, (match, p1) => {
                // Create the new string with parentheses around the disposition
                const parenthesizedDisposition = `( ${p1.trim()} )`

                // Replace the content of the disposition in the original string
                return `{Add ${parenthesizedDisposition} disposition to the contact}`
            })

            return transformed
        },

        transformListsString(input) {
            // Use regular expression to find the occurrences of " ] [ "
            const regex = /] \[ /g

            return input
                .replace(regex, '|| ') // Replace " ] [ " with " || "
                .replace(/\[|\]/g, '') // Remove remaining brackets
        },

        reverseTransformDisengageContact(input) {
            if (this.bot.type === AloAi.TYPE_TEXT) {
                return input.replace(/\{Disengage with contact, STOP replying the contact immediately, no messages of farewell or notifications to let the contact know about the disengage\}/g, '{\Disengage with the contact\}')
            }

            if (this.bot.type === AloAi.TYPE_VOICE) {
                return input.replace(/\{Disengage with the contact and end call\}/g, '{\Disengage with the contact\}')
            }

            return input
        },

        reverseTransformListsString(input) {
            // Use regular expression to find all occurrences of "{Add contact to "..." list(s)}"
            const regex = /\{Add\s+\s+contact+s\to+\s"([^"]+)"\s+list\(s\)}/g

            // Replace each occurrence found with the transformed version
            const transformed = input.replace(regex, (match, p1) => {
                // Split lists by " || "
                const items = p1.split('||').map(item => item.trim())

                // Create the new string with square brackets around each item
                const bracketedItems = items.map(item => `[ ${item} ]`).join(' ')

                // Replace the content of the tags in the original string
                return `{Add contact to ${bracketedItems} list(s)}`
            })

            return transformed
        },


        getCampaign(id) {
            const campaign = this.campaigns.filter(c => c.id == id)

            return campaign[0]
        },

        async getTags(ids = []) {
            if (!ids.length) {
                return
            }

            const params = {
                full_load: true,
                tag_ids: ids
            }

            this.loadingTags = true
            return axios.get('/api/v1/tag', {params}).then(res => {
                this.tags = res.data
                this.loadingTags = false
            }).catch(err => {
                console.log(err)
                this.loadingTags = false
            })
        },

        getTagsInCurrentSection(tagsInCurrentSection) {
            // Get the tags ids from tagsInCurrentSection and set tags
            const tagIds = tagsInCurrentSection.map(tag => tag.id)

            this.tags = cloneDeep(tagsInCurrentSection)
            this.currentTagsIds = tagIds
            this.currentTags = cloneDeep(tagsInCurrentSection)
        },

        getListsInCurrentSection(listsInCurrentSection) {
            // Get the list ids from listsInCurrentSection and set lists
            const listIds = listsInCurrentSection.map(item => item.id)

            this.lists = cloneDeep(listsInCurrentSection)
            this.currentListsIds = listIds
            this.currentLists = cloneDeep(listsInCurrentSection)
        },

        updateReadonlySections() {
            // find with either "tag(s) to the contact" or "disposition to the contact" as part of the template
            const regex = /\{Add \[(.*?)\] tag\(s\) to the contact\}|\{Add \((.*?)\) disposition to the contact\}|\{Disengage with the contact\}|\{Add contact to \[(.*?)\] list\(s\)\}|\{Create Appointment\}|\{Transfer to this line:[^{}]*\}/g
            const backup = cloneDeep(this.readonlySections)
            const content = this.bot.instructions[0].content
            this.readonlySections = []

            let match
            // Find any match for the templates
            while ((match = regex.exec(content)) !== null) {
                if (match[0] === '{Disengage with the contact}') {
                    this.readonlySections.push({
                        start: match.index,
                        end: match.index + match[0].length
                    })
                    continue
                }

                // Handle Create Appointment tool
                if (match[0].includes('{Create appointment with:')) {
                    this.readonlySections.push({
                        start: match.index,
                        end: match.index + match[0].length
                    })
                    continue
                }

                // Handle Transfer tool
                if (match[0].includes('{Transfer to this line:')) {
                    this.readonlySections.push({
                        start: match.index,
                        end: match.index + match[0].length,
                        transfer: true
                    })
                    continue
                }

                // Handle Custom Function tool
                if (match[0].includes('{Run this function:')) {
                    this.readonlySections.push({
                        start: match.index,
                        end: match.index + match[0].length,
                        customFunction: true
                    })
                    continue
                }

                if (match[1]) {
                    // Template {Add [ ... ] tag(s) to the contact}
                    const tagNames = this.transformTagsString(match[1])
                    const backupSection = backup.find(section => section.tagNames === tagNames)

                    if (backupSection) {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            tags: backupSection.tags,
                            tagNames: backupSection.tagNames
                        })
                    } else {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            tagNames: tagNames,
                            tags: cloneDeep(this.tags)
                        })
                    }

                    continue
                }

                if (match[2]) {
                    // Template {Add ( ... ) disposition to the contact}
                    let dispositionName = match[2]
                    const backupSection = backup.find(section => section.dispositionName && section.dispositionName.trim() === dispositionName.trim())

                    if (backupSection) {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            disposition: backupSection.disposition,
                            dispositionName: backupSection.dispositionName
                        })
                    } else {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            dispositionName: dispositionName,
                            disposition: cloneDeep(this.disposition)
                        })
                    }

                    continue
                }

                if (match[3]) {
                    // Template {Add contact to [ ... ] list(s)}
                    const listNames = this.transformListsString(match[3])
                    const backupSection = backup.find(section => section.listNames === listNames)

                    if (backupSection) {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            lists: backupSection.lists,
                            listNames: backupSection.listNames
                        })
                    } else {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            lists: cloneDeep(this.lists),
                            listNames: listNames
                        })
                    }

                    continue
                }
            }
        },

        onInput() {
            this.updateCursorPosition()
            this.updateReadonlySections()
            const newVal = this.bot.instructions[0].content

            if (!newVal || newVal.trim() === "") {
                this.resetTagsDropdown()
                this.resetDispositionsDropdown()
                this.readonlySections = []
            } else {
                // Check if any readonly section has been deleted
                const removed = this.readonlySections.filter(section => {
                    return newVal.slice(section.start + 1, section.end - 1) === section.tagNames
                })

                this.collectSpecialInstructionsToSave()
            }

        },

        /**
         * CreateBotRequest and UpdateBotRequest use these instructions to validate the tool existence
         */
        collectSpecialInstructionsToSave() {
            // iterate over tags in every element inside this.readonlySections
            // and get an array of names so we can validate in backend endpoint
            const tag_names = []
            const disposition_names = []
            const list_names = []

            this.readonlySections.forEach(section => {
                if (section.tags) {
                    const names = section.tags.map(tag => tag.name)
                    tag_names.push(...names)
                }
                if (section.disposition) {
                    disposition_names.push(section.dispositionName)
                }
                if (section.lists) {
                    const names = section.lists.map(list => list.name)
                    list_names.push(...names)
                }
            })

            this.bot.instructions_tags = tag_names
            this.bot.instructions_dispositions = disposition_names
            this.bot.instructions_lists = list_names
        },

        onKeyDown(event) {
            this.validateForbiddenKeys(event)
            this.validateIfArrowKeysArePresent(event)

            const positionUpdated = this.updatePositionForArrowKeys(event)
            if (positionUpdated) {
                return
            }

            // Handle backspace in opener field specially to remove variables
            if (event.key === 'Backspace' && event.target === this.$refs.openerInput.getInput()) {
                this.removeOpenerVariable(event)
                return
            }

            this.validateIfAttemptingToEditSectionContent(event)

            if (event.key === 'Backspace') {
                this.removeSectionCompletely(event)
            }
        },

        validateForbiddenKeys(event) {
            if (['[', ']', '{', '}', '(', ')'].includes(event.key)) {
                this.preventEvent(event)
            }
        },

        validateIfArrowKeysArePresent(event) {
            if (!['ArrowLeft', 'ArrowRight'].includes(event.key)) {
                this.updateCursorPosition() // Update cursor position
            }
        },

        updatePositionForArrowKeys(event) {
            // If the key pressed is Arrow Left/Right just update the cursorPosition
            if (event.key === 'ArrowLeft') {
                this.cursorPosition--
                return true
            }

            if (event.key === 'ArrowRight') {
                this.cursorPosition++
                return true
            }

            return false
        },

        validateIfAttemptingToEditSectionContent(event) {
            const selectionEndPosition = event.target.selectionEnd
            const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']
            const instructionsContent = this.bot.instructions[0].content
            
            // Check for special elements not fully tracked in readonlySections
            for (const template of this.specialElements) {
                // Find if we're inside any special element
                const templateIndex = instructionsContent.indexOf(template, Math.max(0, this.cursorPosition - template.length))
                
                if (templateIndex >= 0) {
                    // For tools with content after colon, check if cursor is between template start and closing brace
                    if (
                        template === '{Send SMS:' || 
                        template === '{Create appointment with:' ||
                        template === '{Run this function:' ||
                        template === '{Collect and update:' ||
                        template === '{Transfer to this line:'
                    ) {
                        const endPos = instructionsContent.indexOf('}', templateIndex)
                        if (endPos >= 0 && this.cursorPosition > templateIndex && this.cursorPosition <= endPos) {
                            if (!allowedKeys.includes(event.key)) {
                                this.preventEvent(event)
                                return
                            }
                        }
                    } 
                    // For simpler tools, check if cursor is within the template text
                    else if (this.cursorPosition > templateIndex && 
                        this.cursorPosition < templateIndex + template.length) {
                        if (!allowedKeys.includes(event.key)) {
                            this.preventEvent(event)
                            return
                        }
                    }
                }
            }
        },

        removeSectionCompletely(event) {
            // Check if the event is coming from the opener input
            if (event.target === this.$refs.openerInput.getInput()) {
                // Don't apply section removal logic to opener input
                return false;
            }
            
            // First, check for special elements not fully tracked in readonlySections
            for (const template of this.specialElements) {
                const content = this.bot.instructions[0].content
                
                // Special handling for templates with content after the colon
                if (
                    template === '{Send SMS:' || 
                    template === '{Create appointment with:' ||
                    template === '{Run this function:' ||
                    template === '{Collect and update:' ||
                    template === '{Transfer to this line:'
                ) {
                    const templateIndex = content.lastIndexOf(template, this.cursorPosition);
                    
                    // If template found and cursor is inside or just after the template
                    if (templateIndex >= 0 && this.cursorPosition >= templateIndex) {
                        // Find the closing brace to get the full template including content
                        let endPos = content.indexOf('}', templateIndex);
                        
                        // If end position found and cursor is at or before the end of template + 1
                        if (endPos >= 0 && this.cursorPosition <= endPos + 1) {
                            // Remove the entire template with its content
                            this.bot.instructions[0].content = content.slice(0, templateIndex) + content.slice(endPos + 1);
                            this.$nextTick(() => {
                                event.target.selectionStart = templateIndex;
                                event.target.selectionEnd = templateIndex;
                            });
                            this.preventEvent(event);
                            this.updateReadonlySections();
                            return true;
                        }
                    }
                } else {
                    // Handle other templates normally
                    const templateIndex = content.lastIndexOf(template, this.cursorPosition);
                    
                    // If found and cursor is directly at or just after the template
                    if (templateIndex >= 0 && 
                        (this.cursorPosition >= templateIndex && 
                         this.cursorPosition <= templateIndex + template.length + 1)) {
                        
                        // Remove the template
                        this.bot.instructions[0].content = content.slice(0, templateIndex) + content.slice(templateIndex + template.length);
                        this.$nextTick(() => {
                            event.target.selectionStart = templateIndex;
                            event.target.selectionEnd = templateIndex;
                        });
                        this.preventEvent(event);
                        this.updateReadonlySections();
                        return true;
                    }
                }
            }
            
            // Then check for tracked readonly sections
            for (const section of this.readonlySections) {
                if (this.cursorPosition === section.end || 
                    // Only detect if cursor is actually within the section or right after it
                    (this.cursorPosition >= section.start && this.cursorPosition <= section.end + 1)) {
                    this.bot.instructions[0].content = this.bot.instructions[0].content.slice(0, section.start) + this.bot.instructions[0].content.slice(section.end)
                    this.$nextTick(() => {
                        event.target.selectionStart = section.start
                        event.target.selectionEnd = section.start
                    })
                    //remove the section
                    this.readonlySections = this.readonlySections.filter(s => s.start !== section.start)
                    this.preventEvent(event)
                    this.resetTagsDropdown()
                    this.resetDispositionsDropdown()
                    this.updateReadonlySections()
                    return true
                }
            }
            
            return false
        },

        checkTagsInCurrentSection() {
            let tagsInCurrentSection = []

            for (const section of this.readonlySections) {
                if (section.tags && this.cursorPosition > section.start && this.cursorPosition < section.end) {
                    tagsInCurrentSection = section.tags
                    this.isEditingReadonlySection = true
                    this.currentSection = cloneDeep(section)
                    break
                }
            }

            return tagsInCurrentSection
        },

        checkDispositionInCurrentSection() {
            let found = false

            for (const section of this.readonlySections) {
                if (section.disposition && this.cursorPosition > section.start && this.cursorPosition < section.end) {
                    this.isEditingReadonlySection = true
                    this.currentSection = cloneDeep(section)
                    this.currentDisposition = cloneDeep(section.disposition)
                    found = true
                    break
                }
            }

            return found
        },

        checkContactDisengageInCurrentSection() {
            for (const section of this.readonlySections) {
                if (section.disengageContact && this.cursorPosition > section.start && this.cursorPosition < section.end) {
                    this.isEditingReadonlySection = true
                    this.currentSection = cloneDeep(section)

                    return true
                }
            }

            return false
        },

        checkListsInCurrentSection() {
            let listsInCurrentSection = []

            for (const section of this.readonlySections) {
                if (section.lists && this.cursorPosition > section.start && this.cursorPosition < section.end) {
                    listsInCurrentSection = section.lists
                    this.isEditingReadonlySection = true
                    this.currentSection = cloneDeep(section)
                    break
                }
            }

            return listsInCurrentSection
        },

        handleClick() {
            this.updateCursorPosition()
        },

        updateCursorPositionOnOpener() {
            const input = this.$refs.openerInput.getInput()

            if (input) {
                this.openerCursorPosition = input.selectionStart
            }
        },

        updateCursorPosition() {
            const textarea = this.$refs.textareaInput.getTextarea()

            if (textarea) {
                this.cursorPosition = textarea.selectionStart
            }
        },

        preventEvent(event) {
            event.preventDefault()
        },

        resetTagsDropdown() {
            this.tagsDropdownKey += 1
            this.currentTagsIds = []
            this.currentTags = []
        },

        resetDispositionsDropdown() {
            this.dispositionsDropdownKey += 1
            this.currentDisposition = {}
        },

        variableSelectedOnOpener(variable) {
            let content = this.bot.opener
            content = content.slice(0, this.openerCursorPosition) + variable + content.slice(this.openerCursorPosition)
            this.openerCursorPosition = this.openerCursorPosition + variable.length
            this.bot.opener = content
        },

        setCursorPosition(toolData) {
            if (!toolData || !toolData?.text) {
                return
            }
            const cursorPosition = this.cursorPosition || 0
            let newContent = this.bot.instructions[0].content
            // Insert the appointment text at the cursor position
            newContent = newContent.slice(0, cursorPosition) + toolData.text + newContent.slice(cursorPosition)
            this.bot.instructions[0].content = newContent
            // Update cursor position
            this.cursorPosition = cursorPosition + toolData.text.length
            // Emit validation
            this.emitValidate()
        },

        onCustomFunctionSelected(customFunctionData) {
            this.setCursorPosition(customFunctionData)
        },

        onCreateAppointmentSelected(appointmentData) {
            this.setCursorPosition(appointmentData)
        },

        onDisengageContactSelected(disengageData) {
            this.setCursorPosition(disengageData)
        },

        onInstructionsFocus() {
            // Access the textarea element inside FormattedInstructionsInput
            const textarea = this.$refs.textareaInput.getTextarea()
            if (textarea) {
                textarea.addEventListener('keydown', this.onKeyDown)
                textarea.addEventListener('click', this.handleClick)
                textarea.addEventListener('keyup', this.updateCursorPosition)
                textarea.addEventListener('input', this.onInput)
            }
        },

        onInstructionsBlur() {
            // Cleanup event listener when unfocused
            const textarea = this.$refs.textareaInput.getTextarea()
            if (textarea) {
                textarea.removeEventListener('keydown', this.onKeyDown)
                textarea.removeEventListener('click', this.handleClick)
                textarea.removeEventListener('keyup', this.updateCursorPosition)
                textarea.removeEventListener('input', this.onInput)
            }
        },

        onOpenerFocus() {
            const input = this.$refs.openerInput.getInput()

            if (input) {
                input.addEventListener('keydown', this.onKeyDown)
                input.addEventListener('click', this.updateCursorPositionOnOpener)
                input.addEventListener('keyup', this.updateCursorPositionOnOpener)
            }
        },

        onOpenerBlur() {
            const input = this.$refs.openerInput.getInput()

            if (input) {
                input.removeEventListener('keydown', this.onKeyDown)
                input.removeEventListener('click', this.updateCursorPositionOnOpener)
                input.removeEventListener('keyup', this.updateCursorPositionOnOpener)
            }
        },

        onTemplateSelected(template, templateId) {
            // If we're switching from one template to another and no edits were made
            if (this.currentTemplateId !== 0 && 
                this.currentTemplateId !== templateId && 
                this.bot.instructions[0].content === this.currentTemplateContent) {
                // No changes were made to the current template, just switch directly
                this.bot.instructions[0].content = template
                this.currentTemplateId = templateId
                this.currentTemplateContent = template
                this.emitValidate()
                this.isEditing = false
                return
            }

            // If there's content and it's not the initial load
            if (this.bot.instructions[0].content && this.bot.instructions[0].content.trim() !== '') {
                MessageBox.confirm(
                    'This will replace your current instructions with the selected template. Continue?', 
                    'Warning', 
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                ).then(() => {
                    // User confirmed, update the content
                    this.bot.instructions[0].content = template
                    this.emitValidate()
                    
                    // Make sure we're in display mode to show the formatted template
                    this.isEditing = false
                    this.currentTemplateId = templateId
                    this.currentTemplateContent = template
                }).catch(() => {
                    // User cancelled, reset the template selector to Custom Template
                    if (this.$refs.templateSelector) {
                        this.$refs.templateSelector.resetSelection()
                    }
                })
            } else {
                // No existing content, just update
                this.bot.instructions[0].content = template
                this.emitValidate()

                // Make sure we're in display mode to show the formatted template
                this.isEditing = false
                this.currentTemplateId = templateId
                this.currentTemplateContent = template
            }
        },

        adjustReadonlySections(startPosition, lengthToAdd) {
            this.readonlySections.forEach(section => {
                if (section.start > startPosition) {
                    section.start += lengthToAdd
                    section.end += lengthToAdd
                }
            })
        },

        onVariableSelected(variable) {
            this.isEditingReadonlySection = true
            let newContent = this.bot.instructions[0].content
            newContent = newContent.slice(0, this.cursorPosition) + variable + newContent.slice(this.cursorPosition)

            const templateLenght = variable.length
            const endSection = this.cursorPosition + templateLenght
            const section = {
                start: this.cursorPosition,
                end: endSection,
                variable,
            }

            // Update readonlySections with the new section
            this.readonlySections.push(section)
            this.cursorPosition = endSection
            this.currentSection = cloneDeep(section)

            // Adjust the start and end positions of the following sections
            this.adjustReadonlySections(this.currentSection.start, templateLenght)

            this.bot.instructions[0].content = newContent
            this.collectSpecialInstructionsToSave()
        },

        createReadOnlySection(newContent, template, sectionData) {
            this.isEditingReadonlySection = true
            newContent = newContent.slice(0, this.cursorPosition) + template + newContent.slice(this.cursorPosition)
            const templateLength = template.length
            const endSection = this.cursorPosition + templateLength
            const section = {
                start: this.cursorPosition,
                end: endSection,
                ...sectionData
            }

            // Update readonlySections with the new section
            this.readonlySections.push(section)
            this.cursorPosition = endSection
            this.currentSection = cloneDeep(section)

            // Adjust the start and end positions of the following sections
            this.adjustReadonlySections(this.currentSection.start, templateLength)

            return newContent
        },

        updateReadOnlySection(newContent, template, sectionData) {
            let updated = false
            let endPositionDiff = 0

            for (const section of this.readonlySections) {
                if (this.cursorPosition >= section.start && this.cursorPosition <= section.end) {
                    this.isEditingReadonlySection = true
                    const endPositionBeforeUpdate = section.end

                    newContent = newContent.slice(0, section.start) + template + newContent.slice(section.end)
                    
                    // Update section with new data
                    Object.assign(section, sectionData)
                    section.end = section.start + template.length

                    endPositionDiff = section.end - endPositionBeforeUpdate
                    this.cursorPosition = section.end
                    this.currentSection = cloneDeep(section)
                    updated = true

                    // Adjust the start and end positions of the following sections
                    this.adjustReadonlySections(this.currentSection.start, endPositionDiff)
                    break
                }
            }

            return {
                isUpdated: updated,
                newContent
            }
        },

        emitValidate() {
            this.$emit('validate')
        },

        // New method to handle removing variables in opener
        removeOpenerVariable(event) {
            const content = this.bot.opener
            const cursorPos = this.openerCursorPosition
            
            // Look for variables like [customer_name], [phone], etc.
            const varRegex = /\[\w+\]/g;
            let match;
            
            // Find variables near cursor position
            while ((match = varRegex.exec(content)) !== null) {
                const varStart = match.index;
                const varEnd = varStart + match[0].length;
                
                // If cursor is at the end of or within a variable
                if (cursorPos > varStart && cursorPos <= varEnd) {
                    // Remove the entire variable
                    this.bot.opener = content.slice(0, varStart) + content.slice(varEnd);
                    this.$nextTick(() => {
                        const input = this.$refs.openerInput.getInput();
                        if (input) {
                            input.selectionStart = varStart;
                            input.selectionEnd = varStart;
                            this.openerCursorPosition = varStart;
                        }
                    });
                    this.preventEvent(event);
                    return true;
                }
            }
            
            return false;
        },
    },

    watch: {
        bot(newVal) {
            if (newVal.knowledge_base_files && newVal.knowledge_base_files.length) {
                this.knowledge_base_files = newVal.knowledge_base_files
            }

            if (newVal.readonly_sections && newVal.readonly_sections.length) {
                this.readonlySections = newVal.readonly_sections
                delete this.bot.readonly_sections

                // check if inside we found \" and replace them with '[' or ']' respectively
                // to manage tags content inside instructions
                this.bot.instructions[0].content = this.reverseTransformTagsString(this.bot.instructions[0].content)
                this.bot.instructions[0].content = this.reverseTransformDispositionsString(this.bot.instructions[0].content)
                this.bot.instructions[0].content = this.reverseTransformDisengageContact(this.bot.instructions[0].content)
                this.bot.instructions[0].content = this.reverseTransformListsString(this.bot.instructions[0].content)

                this.updateReadonlySections()
                this.collectSpecialInstructionsToSave()
            }

            if (this.bot.instructions.length > 0 && this.bot.instructions[0].content) {
                this.bot.instructions[0].content = this.reverseTransformDisengageContact(this.bot.instructions[0].content)
            }

            // Force initial check for cursor position and focus
            this.$nextTick(() => {
                if (this.$refs.textareaInput) {
                    // Start unfocused to display formatted tools
                    this.$refs.textareaInput.isFocused = false
                }
            })
        },

        async cursorPosition(newVal, oldVal) {
            const tagsInCurrentSection = this.checkTagsInCurrentSection()
            if (tagsInCurrentSection.length) {
                this.resetDispositionsDropdown()
                this.getTagsInCurrentSection(tagsInCurrentSection)
                return
            }

            const dispositionInCurrentSection = this.checkDispositionInCurrentSection()
            if (dispositionInCurrentSection) {
                this.resetTagsDropdown()
                return
            }

            const disengageContactInCurrentSection = this.checkContactDisengageInCurrentSection()
            if (disengageContactInCurrentSection) {
                return
            }

            const listsInCurrentSection = this.checkListsInCurrentSection()
            if (listsInCurrentSection.length) {
                this.getListsInCurrentSection(listsInCurrentSection)
                return
            }

            if (this.currentSection && (oldVal >= this.currentSection.start && oldVal <= this.currentSection.end) && (newVal < this.currentSection.start || newVal > this.currentSection.end)) {
                this.currentSection = {}
                this.isEditingReadonlySection = false
            }
        },

        isEditingReadonlySection(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.resetTagsDropdown()
                this.resetDispositionsDropdown()
            }
        }
    },

    mounted() {
        VueEvent.listen('aloai_bot_agent_file_updated', (data) => {
            this.updateBot()
            this.$notify({
                offset: 95,
                title: 'File ' + data.file + ' on bot ' + data.bot,
                message: data.status === 'SUCCESS' ? 'File processed successfully' : 'There was an error processing the file',
                type: data.status === 'SUCCESS' ? 'success' : 'error',
                showClose: true,
            })
        })
        this.updateReadonlySections()

        // Make sure component starts with formatted (unfocused) view
        this.$nextTick(() => {
            if (this.$refs.textareaInput) {
                this.$refs.textareaInput.isFocused = false
                // Explicitly ensure the tool selector is disabled on initial load
                this.disableEditMode()
            }
        })
    },
}
</script>
