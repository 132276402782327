import Vuex from 'vuex'
import Vue from 'vue'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    key: 'aloware_filters_vuex_local',
    storage: window.localStorage,
    modules: ['cache']
})

Vue.use(Vuex)
window.timezone = 'Intl' in window ? new Intl.DateTimeFormat().resolvedOptions().timeZone : 'America/Los_Angeles'

const cachedStatesModule = {}

export default new Vuex.Store({
    modules: {
        cache: cachedStatesModule,
    },

    state: {
        dashboard: {
            date: {
                from: null,
                to: null,
                group_by: 'default',
            },
            attributions: {
                users: [],
                lines: [],
                ring_groups: [],
                contact_lists: [],
                phone_numbers: [],
                sequences: [],
                broadcasts: [],
                tags: []
            }
        }
    },

    getters: {

    },

    actions: {

    },

    mutations: {
        SET_DASHBOARD_FROM_DATE(state, from_date) {
            state.dashboard.date.from = from_date
        },

        SET_DASHBOARD_TO_DATE(state, to_date) {
            state.dashboard.date.to = to_date
        },

        SET_DASHBOARD_DATE_GROUP_BY(state, group_by) {
            state.dashboard.date.group_by = group_by
        },

        SET_DASHBOARD_ATTRIBUTION_USERS(state, users) {
            state.dashboard.attributions.users = users
        },

        SET_DASHBOARD_ATTRIBUTION_LINES(state, lines) {
            state.dashboard.attributions.lines = lines
        },

        SET_DASHBOARD_ATTRIBUTION_RING_GROUPS(state, ring_groups) {
            state.dashboard.attributions.ring_groups = ring_groups
        },

        SET_DASHBOARD_ATTRIBUTION_PHONE_NUMBERS(state, phone_numbers) {
            state.dashboard.attributions.phone_numbers = phone_numbers
        },

        SET_DASHBOARD_ATTRIBUTION_SEQUENCES(state, sequences) {
            state.dashboard.attributions.sequences = sequences
        },

        SET_DASHBOARD_ATTRIBUTION_BROADCASTS(state, broadcasts) {
            state.dashboard.attributions.broadcasts = broadcasts
        },

        SET_DASHBOARD_ATTRIBUTION_TAGS(state, tags) {
            state.dashboard.attributions.tags = tags
        },
    }
})
