<template>
    <div>
        <div class="vertical-center text-left mb-4">
			<span v-if="filter.from_date"
                  class="call-log-head">
				{{ filter_by ? filter_by + '\'s ' : filter_by }}{{ title_prefix }}Contacts from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
			</span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
                {{ filter_by ? filter_by + '\'s ' : filter_by }}{{ title_prefix }}Contacts
                <strong>All Time</strong>
            </span>
        </div>
        <div v-if="!data_loaded || loading"
             :class="{ blink: loading }"
             class="placeholder text-center d-flex align-items-center justify-content-center">

            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div v-else
             class="force-scrollbar-view">
            <graph-data-switch :report_type="'activity_reporting'"
                               :series="options.series"
                               :table_label="graph_label">
                <highcharts v-show="is_done && options.series.length > 0"
                            v-bind:id="graph_id"
                            ref="highchart"
                            :class="{'overflow-y-scroll': (chart_type == 'bar' && data.length > 25)}"
                            :options="graphOptions">
                </highcharts>
                <div v-show="is_done && !options.series.length"
                     class="el-table__empty-block">
                    <span class="el-table__empty-text"
                          style="color: #606266;">
                        No Data
                    </span>
                </div>
            </graph-data-switch>
        </div>
        <div class="mt-2">
            <div class="col-12">
                <el-table ref="new-contacts-vs-dnc-table"
                          v-loading="!is_done"
                          :data="sortedPaginatedData"
                          class="w-full mt-3 activity-reporting-table"
                          fit
                          stripe
                          @sort-change="sortChange">
                    <el-table-column fixed="left"
                                     label="Date"
                                     prop="date"
                                     sortable
                                     width="auto">
                        <template v-slot="scope">
                            {{ scope.row.date }}
                        </template>
                    </el-table-column>
                    <el-table-column label="New Contacts"
                                     prop="new_contacts"
                                     sortable
                                     width="auto">
                        <template v-slot="scope">
                            {{ scope.row.new_contacts }}
                        </template>
                    </el-table-column>
                    <el-table-column label="DNC Contacts"
                                     prop="dnc_contacts"
                                     sortable
                                     width="auto">
                        <template v-slot="scope">
                            {{ scope.row.dnc_contacts }}
                        </template>
                    </el-table-column>
                </el-table>
                <div class="mt-3 d-inline-block w-100 pb-2">
                    <el-pagination :page-size="per_page"
                                   :page-sizes="[10, 20, 50, 100, 250]"
                                   :total="total"
                                   background
                                   class="pull-right"
                                   layout="sizes, prev, pager, next"
                                   @current-change="changePage"
                                   @size-change="handleSizeChange">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapGetters} from 'vuex'
import {date_mixin, graph_mixin, report_mixin, custom_highcharts_mixin} from '../mixins'

export default {
    mixins: [date_mixin, graph_mixin, report_mixin, custom_highcharts_mixin],

    props: {
        data: {
            required: true,
            default: () => []
        },
        type: {
            required: true,
            default: 'new_contacts'
        },
        data_loaded: {
            required: true,
            default: false
        },
        chart_type: {
            required: false,
            default: 'line'
        },
        base: {
            required: true,
            default: 'Lines'
        },
        overview_max_y_axis: {
            required: false,
            default: 0
        },
        unfiltered: {
            required: false,
            default: false
        },
        title_prefix: {
            default: ''
        },
        filter_by: {
            default: ''
        },
        report_type_override: {
            default: 'leads_created_vs_dnc'
        },
    },

    data() {
        return {
            auth: auth,
            is_done: false,
            loading: true,
            is_new_data: false,
            aggregated_counts: [],
            axisLabels: [],
            report_type: this.report_type_override,
            graph_id: this.type.replace(/_/g, ' ') + '_graph',
            options: {
                chart: {
                    events: {
                        load: (event) => {
                            // when is chart ready
                            this.updateChartYAxis(event.target)
                        }
                    }
                },
                title: {
                    text: '',
                },
                rangeSelector: {
                    enabled: false
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            style: {
                                fontSize: '14px'
                            }
                        }
                    },
                    spline: {
                        marker: {
                            enabled: true,
                            radius: 3
                        }
                    },
                    areaspline: {
                        fillOpacity: 0.5,
                        marker: {
                            enabled: true,
                            radius: 3
                        }
                    },
                    series: {
                        connectNulls: true,
                        events: {
                            legendItemClick: function (e) {
                                // Upon cmd-click of a legend item, rather than toggling visibility, we want to hide all other items.
                                let hideAllOthers = e.browserEvent.metaKey
                                if (hideAllOthers) {
                                    if (!this.visible) {
                                        return true
                                    }

                                    let seriesIndex = this.index
                                    let series = this.chart.series

                                    for (let i = 0; i < series.length; i++) {
                                        // rather than calling 'show()' and 'hide()' on the series', we use setVisible and then
                                        // call chart.redraw --- this is significantly faster since it involves fewer chart redraws
                                        if (series[i].index !== seriesIndex) {
                                            if (series[i].visible) {
                                                series[i].setVisible(false, false)
                                            } else {
                                                series[i].setVisible(true, false)
                                            }
                                        }
                                    }
                                    this.chart.redraw()
                                    return false
                                }
                            }
                        }
                    }
                },
                time: {
                    useUTC: false,
                    timezone: window.timezone
                },
                // force the plot to show all ticks daily
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%e %b', // always use day as highest resolution.
                        second: '%e %b', // always use day as highest resolution.
                        minute: '%e %b', // always use day as highest resolution.
                        hour: '%H:%M',
                        day: '%e %b', // always use day as highest resolution.
                        week: '%e %b', // always use day as highest resolution.
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    // minRange: 1 * 24 * 3600000, // 1 day
                    labels: {
                        rotation: 45,
                        // step: 1,
                        style: {
                            fontSize: '14px'
                        }
                    }
                },
                yAxis: [
                    {
                        type: 'logarithmic',
                        custom: {
                            allowNegativeLog: true
                        },
                        gridLineWidth: 0,
                        allowDecimals: false,
                        offset: 20,
                        title: {
                            text: 'New Contacts',
                            style: {
                                'font-size': '14px',
                                'font-weight': 'bold',
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        minTickInterval: 1
                    }, {
                        type: 'logarithmic',
                        custom: {
                            allowNegativeLog: true
                        },
                        allowDecimals: false,
                        offset: 20,
                        title: {
                            text: 'DNC Contacts',
                            style: {
                                'font-size': '14px',
                                'font-weight': 'bold',
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        minTickInterval: 1,
                        opposite: true
                    }
                ],
                tooltip: {
                    useHTML: true,
                    shared: true,
                    crosshairs: true,
                    style: {
                        color: '#2e3e4e',
                        fontSize: '14px'
                    },
                    formatter: function () {
                        let s = []
                        try {
                            let first_point = this.points.filter((a) => a.y !== 0)
                            let axis_text = 'Contacts'
                            if (typeof first_point[0] !== 'undefined') {
                                first_point = first_point[0]
                                axis_text = first_point.series ? first_point.series.yAxis.axisTitle.textStr : axis_text
                            }

                            s.push(`<strong class="mb-0">Number of ` + axis_text + `</strong>`)
                            s.push(`<span class="text-grey-900 mt-0 mb-3" style="font-size: 0.65rem">${moment(this.x).format('dddd, MMMM D YYYY').toString()}</span>`)
                            for (let point of this.points.filter((a) => a.y !== 0).sort((a, b) => b.y - a.y)) {
                                let point_name = point.series.name.toString();
                                point_name = point_name.length > 0 && point_name[(point_name.length - 1)].toLowerCase() == 's' && point.y <= 1 ? point_name.substring(0, (point_name.length - 1)) : point_name
                                s.push(`<i class="fa fa-circle" style="color: ${point.series.color};"></i><span class="text-grey-900" style="font-size: 0.875rem"> ${point_name}: <b>${point.y.toLocaleString()}</b></span>`)
                            }
                            s = s.join("</br>")
                        } catch (err) {
                            // handle error
                        }
                        return s
                    }
                },
                legend: {
                    layout: "horizontal",
                    enabled: true,
                    verticalAlign: "bottom",
                    floating: false
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    sourceWidth: 0,
                    sourceHeight: 0
                },
                series: []
            },
            dataSort: 'date',
            dataSortType: 'ASC',
            per_page: 20,
            current_page: 1,
            full_series_data: []
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),

        graphOptions() {
            return this.options
        },

        highchartSeries() {
            if (!this.full_series_data.length) {
                return []
            }
            let data = {}
            let final_data = []
            let count_property_names = [
                'new_contacts', 'dnc_contacts'
            ]

            for (let series_index in this.full_series_data) {
                let raw_data = this.data.find((a) => a.series_name == this.full_series_data[series_index].name)
                let raw_data_index = this.data.indexOf(raw_data)
                raw_data = raw_data !== 'undefined' ? '_' + raw_data : ''
                for (let series_data_index in this.full_series_data[series_index].data) {
                    let count_property_name = count_property_names[(this.full_series_data[series_index].yAxis)]
                    let unique_index = this.full_series_data[series_index].data[series_data_index][0].toString() + raw_data_index
                    if (typeof data[unique_index] === 'undefined') {
                        let row = {
                            date: this.$options.filters.fixFullDateLocal(this.full_series_data[series_index].data[series_data_index][0]),
                            timestamp: this.full_series_data[series_index].data[series_data_index][0]
                        }
                        row[count_property_name] = this.full_series_data[series_index].data[series_data_index][1]
                        data[unique_index] = row
                    } else {
                        data[unique_index][count_property_name] = this.full_series_data[series_index].data[series_data_index][1]
                    }
                }
            }

            for (let index in data) {
                final_data.push(data[index])
            }
            // lastly, we sort the data
            //data.sort((a, b) => a.timestamp - b.timestamp)
            return final_data
        },

        sortedPaginatedData() {
            if (this.highchartSeries.length) {
                let page_number = this.current_page - 1
                if (this.dataSort) {
                    if (this.dataSortType) {
                        switch (this.dataSortType) {
                            case 'ASC':
                                return this.highchartSeries.sort((a, b) => parseFloat(a[this.dataSort]) - parseFloat(b[this.dataSort])).slice(page_number * this.per_page, (page_number + 1) * this.per_page)
                            case 'DESC':
                                return this.highchartSeries.sort((a, b) => parseFloat(b[this.dataSort]) - parseFloat(a[this.dataSort])).slice(page_number * this.per_page, (page_number + 1) * this.per_page)
                            default:
                                return this.highchartSeries.slice(page_number * this.per_page, (page_number + 1) * this.per_page)
                        }
                    }
                }
                return this.highchartSeries.slice(page_number * this.per_page, (page_number + 1) * this.per_page)
            }

            return []
        },

        total() {
            return this.highchartSeries.length
        }
    },

    created() {
        VueEvent.listen('copy_filtered_baseline' + this.$vnode.key, (data) => {
            this.copy()
        })
    },

    mounted() {
        this.graph_label = this.changeXAxisLabel()
        if (_.isEmpty(this.data)) {
            this.is_new_data = true
            this.getLeadsIntakeReport().then(() => {
                this.$emit('reportLoaded', this.report_index)
            })
        } else {
            this.is_new_data = false
            this.generateGraphData(false)

            this.loading = false
            this.is_done = true
            this.$nextTick(() => {
                let highcharts_container = document.getElementById(this.graph_id)

                if (highcharts_container) {
                    this.options.exporting.sourceWidth = highcharts_container.clientWidth
                    this.options.exporting.sourceHeight = highcharts_container.clientHeight
                }
            })
            this.$emit('reportLoaded', this.report_index)
        }
    },

    methods: {
        generateGraphData(should_emit = true) {
            if (should_emit) {
                this.$emit('reportData', this.aggregated_counts)
            }

            if ((!this.data.length && _.isEmpty(this.aggregated_counts.length)) || this.unfiltered) {
                this.loading = false
                this.is_done = true
                return;
            }
            this.loading = true
            this.is_done = false

            if (!_.isEmpty(this.data)) {
                this.aggregated_counts = _.clone(this.data)
            }

            // if its not json it will crash the server.
            if (!Array.isArray(this.aggregated_counts)) {
                console.log("Non json response provided")
                // stop execution
                return Promise.reject("Non json response provided")
            }

            let count_names = [
                ['new_contacts', 'dnc_contacts'],
                ['New Contacts', 'DNC Contacts']
            ]
            let metric_types = [
                'dnc_contacts', 'new_contacts'
            ]
            let series = []

            // loop over what you got and setup the plots.
            let keys = Object.keys(this.aggregated_counts)
            let min_overall, max_overall
            for (let index in keys) {
                let series_data = this.aggregated_counts[keys[index]]
                if (this.filter_ids && !this.filter_ids.includes(series_data.series_id)) {
                    continue
                }

                for (index in count_names[0]) {
                    if (this.type != 'overview' && this.type != count_names[0][index]) {
                        this.options.yAxis[index].visible = false
                    } else {
                        this.options.yAxis[index].visible = true
                    }

                    // prepare min & max of time range
                    let min, max

                    // arrange this in hash table with timezone in mind.
                    let base_data = {}
                    let date

                    for (let item in series_data.data) {
                        let ts = series_data.data[item].timestamp // seconds
                        // get timezone diff
                        // let offset = moment().local().utcOffset() * 60  // seconds
                        // adjust timezone
                        // ts = ts + offset
                        // removes time from datetime this will fix timezone issues
                        date = parseInt(this.localizedMoment(ts, 'YYYY-MM-DD HH:mm:ss').startOf('day').format('x') / 1000)
                        // push to hash table
                        if (base_data[date]) {
                            base_data[date] = base_data[date] + series_data.data[item][count_names[0][index]]
                        } else {
                            base_data[date] = series_data.data[item][count_names[0][index]]
                        }
                        if (min == undefined || min > date) {
                            min = date
                        }
                        if (max == undefined || max < date) {
                            max = date
                        }
                    }


                    if (min_overall > min || !min_overall) {
                        min_overall = min
                    }

                    if (max_overall < max || !max_overall) {
                        max_overall = max
                    }

                    // convert min, max to moment objects
                    min = moment.unix(min)
                    max = moment.unix(max)

                    // create a base series based on this with all ticks available.
                    let base_series = []

                    // when period is daily
                    if (this.filter.chart_period == "day") {
                        // force the plot to show all ticks
                        // this.options.xAxis.tickInterval = 24 * 3600 * 1000 // 1 day
                        // this.options.xAxis.labels.step = 1 // 1 day
                        this.options.xAxis.tickInterval = undefined // let the plot decide
                        this.options.xAxis.labels.step = undefined // let the plot decide
                        if (this.filter.from_date) {
                            min = moment(this.filter.from_date).startOf('day') // moment
                        }
                        if (this.filter.to_date) {
                            max = moment(this.filter.to_date).startOf('day') // moment object
                        }
                    }
                    // week
                    if (this.filter.chart_period == "week") {
                        // force the plot to show all ticks
                        this.options.xAxis.tickInterval = undefined // let the plot decide
                        this.options.xAxis.labels.step = undefined // let the plot decide
                        // set the plot to use definitive time-ticks based on period selected
                        if (this.filter.from_date) {
                            min = moment(this.filter.from_date).startOf('isoWeek').startOf('day') // moment object
                        }
                        if (this.filter.to_date) {
                            max = moment(this.filter.to_date).startOf('isoWeek').startOf('day') // moment object
                        }
                        // ^^ we use isoWeek because we want it to start on Monday
                    }
                    // month to month handling
                    if (this.filter.chart_period == "month") {
                        // force the plot to show all ticks
                        this.options.xAxis.tickInterval = undefined // let the plot decide
                        this.options.xAxis.labels.step = undefined // let the plot decide
                        // set the plot to use definitive time-ticks based on period selected
                        if (this.filter.from_date) {
                            min = moment(this.filter.from_date).startOf('month').startOf('day') // moment object
                        }
                        if (this.filter.to_date) {
                            max = moment(this.filter.to_date).startOf('month').startOf('day') // moment object
                        }
                    }

                    // print each day
                    while (min <= max) {
                        let base_point = []
                        let min_unix_timestamp = min.format('X') // seconds
                        // is there a match in our data?
                        if (base_data[min_unix_timestamp]) {
                            base_point = [min_unix_timestamp * 1000, base_data[min_unix_timestamp]]
                        } else {
                            base_point = [min_unix_timestamp * 1000, 0]
                        }
                        base_series.push(base_point)
                        min = min.add(1, this.filter.chart_period)
                    }

                    let point_start = this.getPointStart(base_series)

                    // form the series
                    let new_series_data = {
                        // temp to give us a feeling. Will give line for campaigns and column for dashboard (spline is a curved line)
                        // type can be line, column, spline
                        yAxis: parseInt(index),
                        type: this.chart_type,
                        name: count_names[1][index],
                        // color: "#0033A0",
                        data: base_series,
                        pointStart: point_start,
                        // pointInterval: moment.duration(1, this.filter.chart_period).asMilliseconds()
                        showInLegend: (Object.keys(base_series).length > 0) ? true : false
                    }

                    if (this.type != 'overview') {
                        let default_overview_types = ['new_contacts', 'dnc_contacts']
                        let enabled_overview_index = default_overview_types.indexOf(this.type)
                        if (enabled_overview_index) {
                            new_series_data.color = Highcharts.getOptions().colors[enabled_overview_index]
                        }
                    }
                    series.push(new_series_data)
                }
            }

            this.full_series_data = _.cloneDeep(series)
            if (this.type != 'overview') {
                let metric_index = metric_types.indexOf(this.type)
                if (typeof series[metric_index] !== 'undefined') {
                    delete series[metric_index]
                    series = _.compact(series)
                }
            }
            // have high charts re-draw this.
            this.$set(this.options, 'series', series)

            if (!this.filter.from_date && !this.filter.to_date) {
                this.options.xAxis.min = min_overall * 1000
                this.options.xAxis.max = max_overall * 1000
            } else {
                this.options.xAxis.min = undefined
                this.options.xAxis.max = undefined
            }

            if (this.type == 'dnc_contacts') {
                this.options.yAxis[1].opposite = false
            }

            this.options.xAxis.tickInterval = moment.duration(1, this.filter.chart_period).asMilliseconds()

            this.loading = false
            this.is_done = true
            this.$nextTick(() => {
                let highcharts_container = document.getElementById(this.graph_id)

                if (highcharts_container) {
                    this.options.exporting.sourceWidth = highcharts_container.clientWidth
                    this.options.exporting.sourceHeight = highcharts_container.clientHeight
                }
            })

            this.is_done = true
            this.loading = false
        },

        changeXAxisLabel() {
            switch (this.type) {
                case 'all_activity':
                    return 'Number of Activities'
                case 'new_contacts':
                    return 'Number of New Contacts'
                case 'dnc_contacts':
                    return 'Number of DNC contacts'
            }
        },

        updateChartHeight() {
            if (this.chart_type == 'spline' && this.data.length > 25) {
                this.options.chart.height = 20 * this.data.length
            }
        },

        updateChartYAxis(chart) {
            let maxYAxisValue = 0
            let yAxis1 = chart.yAxis[0].getExtremes().max
            let yAxis2 = chart.yAxis[1].getExtremes().max
            // check if first y axis' max point is NaN and skip it
            if (yAxis1 !== yAxis1) {
                maxYAxisValue = Math.max(chart.yAxis[1].getExtremes().max)
            } else if (yAxis2 !== yAxis2) { // check if second y axis' max point is NaN and skip it
                maxYAxisValue = Math.max(chart.yAxis[0].getExtremes().max)
            } else { // use all y axis max point
                maxYAxisValue = Math.max(chart.yAxis[0].getExtremes().max, chart.yAxis[1].getExtremes().max)
            }
            if (this.is_new_data || maxYAxisValue > this.overview_max_y_axis) {
                this.$emit('topGraphMaxYAxisValueLoaded', maxYAxisValue)
            } else {
                maxYAxisValue = this.overview_max_y_axis
            }
            this.updateChartHeight()
            chart.yAxis[0].setExtremes(0, maxYAxisValue, true)
            chart.yAxis[1].setExtremes(0, maxYAxisValue, true)
            chart.redraw();
        },

        sortChange(data) {
            if (data.order) {
                this.dataSort = data.prop
                switch (data.order) {
                    case 'descending':
                        this.dataSortType = 'DESC'
                        break
                    case 'ascending':
                        this.dataSortType = 'ASC'
                        break
                }
            } else {
                this.dataSort = null
                this.dataSortType = null
            }
        },

        changePage(val) {
            this.current_page = val
        },

        handleSizeChange(val) {
            this.current_page = 1
            this.per_page = val
        },

        toVariableName(name) {
            return name.toLowerCase().replace(/ /g, '_')
        },

        copy() {
            if (this.highchartSeries.length == 0) {
                return
            }
            let first_data_keys = _.clone(_.first(this.highchartSeries))
            let data_keys = _.clone(first_data_keys)
            first_data_keys = Object.keys(first_data_keys)
            data_keys = Object.keys(data_keys)
            let copied = ''
            for (let index in data_keys) {
                if (data_keys[index] == 'timestamp') {
                    delete data_keys[index]
                    continue
                }
                data_keys[index] = this.$options.filters.ucwords(data_keys[index].replace(/_/g, ' ')).replace('Dnc', 'DNC')
                copied += data_keys[index] + '\t'
            }
            data_keys = _.compact(data_keys)
            copied += '\n'

            let report = _.clone(this.highchartSeries)
            if (this.dataSort) {
                report.sort((a, b) => {
                    return b[this.dataSort] - a[this.dataSort]
                })
            }
            if (report.length) {
                for (let key in report) {
                    for (let data_key in first_data_keys) {
                        if (first_data_keys[data_key] == 'timestamp') {
                            continue
                        }
                        copied += report[key][first_data_keys[data_key]] + '\t'
                    }
                    copied += '\n'
                }
            }

            const el = document.createElement('textarea')
            el.value = copied
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)

            this.$notify({
                offset: 95,
                title: 'Reports',
                dangerouslyUseHTMLString: true,
                message: 'Data has been copied to your clipboard.',
                type: 'success',
                showClose: true
            })
        },
    },

    watch: {
        data: {
            deep: true,
            handler: function () {
                this.generateGraphData()
            }
        },
        'type': function () {
            this.graph_label = this.changeXAxisLabel()
            this.generateGraphData()
        }
    },

    destroyed() {
        this.loading = true
        this.is_done = false
    }
}
</script>
