<template>
    <div class="py-2"
         v-if="notEmptyEnabledFilters">
        <div class="col-12 p-0">
            <small>Active Filters:</small>
            <span v-for="(filter, index) in enabled_filters_text"
                  v-if="(typeof filter.value !== 'object' && filter.value) || (typeof filter.value === 'object' && filter.value && filter.value.length)"
                  class="btn btn-xs indigo-A200 mr-1 mb-1 text-left white-space-normal"
                  style="max-width: 100%;">
                <strong>{{ filter.name | ucwords }}</strong>
                <b v-for="value in filter.value"
                   v-if="!flag_filters.includes(filter.name)"
                   class="white-space-initial d-inline label label-sm rounded success m-l-xs">
                    <span v-if="typeof value == 'string'">{{ value | capitalize }}</span>
                    <span v-else>{{ value }}</span>
                </b>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            enabled_filters_text: [],
            flag_filters: [
                'First-time conversations',
                'Untagged conversations',
                'Unread Conversations',
                'Not disposed',
                'Exclude automated communications'
            ]
        }
    },

    computed: {
        notEmptyEnabledFilters() {
            for (let index in this.enabled_filters_text) {
                if ((typeof this.enabled_filters_text[index].value !== 'object' && this.enabled_filters_text[index].value) || (typeof this.enabled_filters_text[index].value === 'object' && this.enabled_filters_text[index].value && this.enabled_filters_text[index].value.length)) {
                    return true
                }
            }
            return false
        }
    },

    created() {
        VueEvent.listen('update_summary_filter_text', (data) => {
            this.enabled_filters_text = data
        })
    },
}
</script>
