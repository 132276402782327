<template>
    <div class="d-flex flex-row align-items-center"
         v-if="hubspotEnabled">
        <el-select v-model="workflow_id"
                   class="d-flex flex-grow-1"
                   :placeholder="placeholder"
                   :multiple="multiple"
                   :multiple-limit="multiple ? multipleLimit : 0"
                   :loading="loading_hubspot_workflows"
                   :disabled="loading_hubspot_workflows || !hubspot_workflows.length"
                   filterable
                   clearable
                   default-first-option
                   collapse-tags>
            <el-option
                v-for="workflow in workflowsAlphabeticalOrder"
                :key="workflow.id"
                :label="workflow.name"
                :value="workflow.id">
            </el-option>
        </el-select>
        <el-button type="primary"
                   icon="fa fa-refresh"
                   size="mini"
                   class="ml-2 w-30"
                   :disabled="loading_hubspot_workflows"
                   :loading="loading_hubspot_workflows"
                   @click="getHubspotWorkflows"
                   circle>
        </el-button>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../auth'
import {acl_mixin} from '../mixins'

export default {
    mixins: [acl_mixin],

    props: {
        value: {
            required: false,
        },

        exclude: {
            required: false,
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        multipleLimit: {
            type: Number,
            required: false,
            default: 0
        },
    },

    data() {
        return {
            auth: auth,
            workflow_id: this.value,
            loading_hubspot_workflows: false,
            hubspot_workflows: [],
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company
        }),

        hubspotEnabled() {
            if (this.current_company &&
                this.current_company.hubspot_integration_enabled) {
                return true
            }

            return false
        },

        placeholder() {
            if (this.loading_hubspot_workflows) {
                return 'Loading ...'
            }

            return 'HubSpot workflows'
        },

        workflowsAlphabeticalOrder() {
            if (this.hubspot_workflows) {
                return this.hubspot_workflows.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },
    },

    mounted() {
        if (this.hubspotEnabled) {
            this.getHubspotWorkflows()
        }
    },

    methods: {
        getHubspotWorkflows() {
            this.hubspot_workflows = []
            this.loading_hubspot_workflows = true
            axios.get('/api/v1/integration/hubspot/workflows').then((res) => {
                this.loading_hubspot_workflows = false
                this.hubspot_workflows = res.data
            }).catch((err) => {
                this.loading_hubspot_workflows = false
                this.$root.handleErrors(err.response)
                console.log(err)
            })
        },
    },

    watch: {
        value() {
            this.workflow_id = this.value
        },

        workflow_id(val) {
            if (this.value !== undefined && this.workflow_id !== this.value) {
                this.$emit('change', val)
            }
        }
    }
}
</script>
