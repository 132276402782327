<template>
    <div class="app-body" >
        <div class="text-center pos-rlt p-y-md"
             style="overflow: hidden;">
            <div class="row">
                <div class="col-12">
                    <!-- brand -->
                    <a class="mx-auto d-block">
                        <img :src="statics.logo"
                             height="70px"
                             v-if="!loading_whitelabel"
                             alt="logo">
                    </a>
                    <!-- / brand -->
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <p class="h5 m-y-lg font-bold text-justify"
                       style="line-height: 30px;">Hey! It looks like that your {{ current_company.name }} - #{{ current_company.id }} account has been suspended. Please
                        contact our support at
                        <span class="text-dark-greenish"
                              v-if="current_company && current_company.reseller_id != 357">
                            support@aloware.com
                        </span>
                        <span class="text-dark-greenish"
                              v-if="current_company && current_company.reseller_id == 357">
                            support@simpsocial.com
                        </span>
                        or call
                        <span class="text-dark-greenish"
                              v-if="current_company && current_company.reseller_id != 357">
                            855-256-2001
                        </span>
                        <span class="text-dark-greenish"
                              v-if="current_company && current_company.reseller_id == 357">
                            888-829-1110
                        </span>
                        for assistance.
                    </p>

                    <a href="mailto:support@aloware.com"
                       v-if="current_company && current_company.reseller_id != 357"
                       class="btn greenish p-x-md">
                        Send an Email
                    </a>
                    <a href="mailto:support@simpsocial.com"
                       v-if="current_company && current_company.reseller_id == 357"
                       class="btn greenish p-x-md">
                        Send an Email
                    </a>
                    <a href="tel:+18552562001"
                       v-if="current_company && current_company.reseller_id != 357"
                       class="btn new-blue p-x-md ml-2">
                        Call us
                    </a>
                    <a href="tel:+18888291110"
                       v-if="current_company && current_company.reseller_id == 357"
                       class="btn new-blue p-x-md ml-2">
                        Call us
                    </a>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../../auth'
    import store from '../../store'
    import {mapState} from 'vuex'

    export default {
        data() {
            return {
                auth: auth,
                statics: {
                    logo: null,
                    logo_inverse: null,
                    logo_square: null,
                    logo_square_inverse: null,
                    host: null,
                    referer: null,
                    name: null,
                    domain: null,
                    whitelabel: false,
                    path: null
                },
                loading_whitelabel: true
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),
        },

        created() {
            this.getStatics()
        },

        methods: {
            getStatics() {
                this.loading_whitelabel = true
                axios.get('/get-statics')
                    .then(res => {
                        this.statics = res.data
                        this.loading_whitelabel = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.loading_whitelabel = false
                        this.$root.handleErrors(err.response)
                    })
            }
        },

        beforeRouteEnter(to, from, next) {
            auth.check()
                .then((res) => {
                    if (store.state.user_status) {
                        next({name: 'Dashboard'})
                    } else {
                        next()
                    }
                })
                .catch((err) => {
                    next({name: 'Login', query: {redirect: to.fullPath}})
                })
        }
    }
</script>
