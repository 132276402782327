// note: no soft deletes here.
export const DELAY_NONE = 0
export const DELAY_TYPE_MINUTES = 1
export const DELAY_TYPE_TIME_OF_DAY = 2

// types
export const TYPE_CALL_LEAD = 1
export const TYPE_SMS_LEAD = 2
export const TYPE_CONFERENCE = 3
export const TYPE_RVM = 4
export const TYPE_MMS = 5
export const TYPE_CALL_RING_GROUP = 6
export const TYPE_SMS_RING_GROUP = 7
export const TYPE_WEBHOOK = 8
export const TYPE_AUTO_DIAL = 9 // [DEPRECATED]
export const TYPE_BIRTHDAY = 10
export const TYPE_ADD_TAG_CONTACT = 11
export const TYPE_CHANGE_CONTACT_OWNER = 12
export const TYPE_EMAIL_LEAD = 13
export const TYPE_EMAIL_RING_GROUP = 14
export const TYPE_ADD_TO_POWER_DIALER = 15
export const TYPE_PREDICTIVE_DIAL = 16 // [DEPRECATED]
export const TYPE_REMOVE_TAG_CONTACT = 17
export const TYPE_DISPOSE_CONTACT = 18
export const TYPE_DNC_CONTACT = 19
export const TYPE_CHANGE_CONTACT_OWNER_ROUND_ROBIN = 20
export const TYPE_CONTACT_TO_LINE = 21
export const TYPE_UPDATE_CONTACT_LEAD_SOURCE = 22
export const TYPE_REMOVE_FROM_POWER_DIALER = 23
export const TYPE_ENROLL_TO_OTHER_WORKFLOW = 24
export const TYPE_START_ALOAI_CONVERSATION = 25
export const TYPE_ADD_TO_CONTACT_LIST = 26
export const TYPE_REMOVE_FROM_CONTACT_LIST = 27

// Distribute to
export const ASSIGN_TO_USER = 1;
export const ASSIGN_TO_RING_GROUP = 2;

// units
export const UNIT_SECONDS = 'seconds'
export const UNIT_MINUTES = 'minutes'
export const UNIT_HOURS = 'hours'
export const UNIT_DAYS = 'days'
export const UNIT_WEEKS = 'weeks'
export const UNIT_MONTHS = 'months'

// authentication methods
export const AUTH_METHOD_NONE = 1
export const AUTH_METHOD_BASIC = 2
export const AUTH_METHOD_TOKEN_BASED = 3

// http methods
export const HTTP_METHOD_GET = 1
export const HTTP_METHOD_POST = 2

// push contacts to
export const PUSH_CONTACTS_TO_BOTTOM = 1
export const PUSH_CONTACTS_TO_TOP = 2

// delay timezones types
export const DELAY_TIMEZONE_COMPANY = 1
export const DELAY_TIMEZONE_CONTACT = 2
export const DELAY_TIMEZONE_TYPES = {
    [DELAY_TIMEZONE_COMPANY]: 'Company Timezone',
    [DELAY_TIMEZONE_CONTACT]: 'Contact Timezone',
}
