<template>
    <div class="d-flex flex-column mb-4"
         style="font-size: 13px;">
        <b class="el-form-item__label">Select a time to send:</b>
        <div class="warning-card rounded d-flex flex-column mb-3 p-3"
             style="width: fit-content">
            <div class="mb-3">An admin has restricted broadcasts to only be sent during:</div>
            <div class="d-flex flex-row">
                <div class="d-flex flex-column justify-content-end">
                    <b class="mr-2"
                       style="font-size: 14px;font-weight: 600">
                        {{ broadcastOperatingHoursText }}
                    </b>
                </div>
                <div class="d-flex flex-column justify-content-end">
                    <span class="rounded border mr-2"
                          style="padding: 3px 8px;">
                        {{ companyTimezone }}
                    </span>
                </div>
                <div class="d-flex flex-column justify-content-end">
                    <a class="text-blue hover"
                       href="/account?tab=settings#broadcasts_business_hours"
                       target="_blank">
                        <u>Edit</u>
                    </a>
                </div>
            </div>
        </div>
        <el-radio-group size="small"
                        style="display: inline-grid;width: fit-content;"
                        v-model="scheduled"
                        @change="changeSchedule">
            <el-radio :label="false">Send now</el-radio>
            <el-radio :label="true">Pick a time</el-radio>
        </el-radio-group>
        <div class="p-2 d-flex flex-column"
             v-if="scheduled">
            <div class="pl-4">
                <table>
                    <tbody>
                    <tr>
                        <td style="width: 50px">
                            <label>Day</label>
                        </td>
                        <td style="width: 220px">
                            <el-date-picker class="mb-2 broadcast-schedule-input broadcast-schedule-input-date"
                                            placeholder="Pick a date"
                                            format="MM/dd/yyyy"
                                            value-format="MM/dd/yyyy"
                                            type="date"
                                            :picker-options="pickerOptions"
                                            :default-date="defaultDate"
                                            v-model="message.run_at_date"
                                            @change="fixTimePickerOptions">
                            </el-date-picker>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 50px">
                            <label>Time</label>
                        </td>
                        <td style="width: 220px">
                            <el-time-picker class="broadcast-schedule-input"
                                            placeholder="Start time"
                                            format="h:mm A"
                                            value-format="HH:mm"
                                            :picker-options="timePickerOptions"
                                            v-model="message.run_at_time">
                            </el-time-picker>
                        </td>
                        <td class="pl-2">
                            <span class="rounded border mr-2"
                                  style="padding: 3px 8px;">
                                {{ current_company.timezone }}
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div>
            <i class="material-icons mr-1">send</i>
            <b class="mr-2">Send <span v-if="scheduled">message</span><span v-else>today</span> at {{ sendTimeText }}</b>
            <span class="rounded border mr-2"
                  style="padding: 3px 8px;">
                {{ companyTimezone }}
            </span>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import {acl_mixin} from '../../mixins'

export default {
    mixins: [acl_mixin],

    props: {
        message: {
            required: true
        }
    },

    data() {
        return {
            timePickerOptions: {
                format: 'h:mm A'
            },
            pickerOptions: {},
            send_datetime_interval: null,
            isScheduledToNextBusinessHourWindow: false,
            schedule_time: '',
            scheduled: false
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        broadcastOperatingHoursText() {
            let open = this.getCurrentDate() + ' ' + this.current_company.broadcast_open
            let close = this.getCurrentDate() + ' ' + this.current_company.broadcast_close

            return moment(open).format('h:mm A') + ' - ' + moment(close).format('h:mm A')
        },

        broadcastOpenHour() {
            // HH:mm format
            return this.current_company.broadcast_open.slice(0, -3);
        },

        broadcastCloseHour() {
            // HH:mm format
            return this.current_company.broadcast_close.slice(0, -3);
        },

        sendTimeText() {
            let format = this.scheduled ? 'MM/DD/YYYY h:mm A' : 'h:mm A'
            return moment(this.message.run_at_date + ' ' + this.schedule_time).format(format)
        },

        defaultDate() {
            return this.companyDate.toDate()
        },

        companyDate() {
            return moment().tz(this.current_company.timezone)
        },

        companyTimezone() {
            return this.companyDate.format('z')
        },
    },

    mounted() {
        this.initTimeSelectOptions()
        this.initDateSelectOptions()

        // Set initial date and time
        this.message.run_at_time = this.companyDate.format('HH:mm')
        this.message.run_at_date = this.getCurrentDate()
        this.schedule_time = this.message.run_at_time
    },

    methods: {
        initDateSelectOptions() {
            // Set default options base on company timezone
            let date_tz = this.companyDate;
            let ref_date = new Date(date_tz.year(), date_tz.month(), date_tz.date(), date_tz.hours(), date_tz.minutes(), date_tz.seconds(), date_tz.milliseconds()).getTime()
            this.pickerOptions = {
                disabledDate(time) {
                    return time.getTime() < (ref_date - 8.64e7)
                }
            }
        },

        initTimeSelectOptions() {
            let start_range = this.message.run_at_date + ' ' + this.current_company.broadcast_open
            let end_range = this.message.run_at_date + ' ' + this.current_company.broadcast_close

            // Set default options base on company timezone
            this.timePickerOptions = {
                selectableRange: `${start_range} - ${end_range}`
            }
        },

        sendTimeNowText() {
            this.send_datetime_interval = setInterval(() => {
                // update displayed time info according to currently selected schedule
                this.schedule_time = this.scheduled ? this.message.run_at_time : this.companyDate.format('HH:mm')
            }, 1000)
        },

        changeSchedule(is_scheduled) {
            this.scheduled = is_scheduled
        },

        fixTimePickerOptions(value) {
            // if date is in the future, should start with business window open hour
            if (value && value != this.companyDate.format('L')) {
                this.message.run_at_time = this.broadcastOpenHour
                this.schedule_time = this.broadcastOpenHour
            }
        },

        getCurrentDate() {
            return this.companyDate.format('MM/DD/YYYY')
        },

        setAssumedNextBusinessHourWindow() {
            const open = this.getCurrentDate() + ' ' + this.current_company.broadcast_open
            const close = this.getCurrentDate() + ' ' + this.current_company.broadcast_close

            // if scheduled to future date, set to company's broadcast open hour with the picked date
            if (this.scheduled && this.message.run_at_date !== this.getCurrentDate()) {
                // HH:mm format
                this.message.run_at_time = this.broadcastOpenHour
                return
            }

            // if scheduled but the date is current day
            if (this.scheduled && this.message.run_at_date == this.getCurrentDate()) {
                this.message.run_at_date = this.companyDate.add(1, 'day').format('MM/DD/YYYY')
                this.message.run_at_time = this.broadcastOpenHour
                return
            }

            // on send now, determine if current time is before or after their broadcast open hour
            // then adjust date accordingly
            const current_datetime = this.companyDate.format('MM/DD/YYYY HH:mm:ss')

            // set schedule date & time to today's date and open business hour
            if (moment(current_datetime).isBefore(open)) {
                this.message.run_at_date = this.getCurrentDate()
                this.message.run_at_time = this.broadcastOpenHour
            }

            // past the current date's business hours, set schedule to next day's open business hour
            if (moment(current_datetime).isSameOrAfter(close)) {
                this.message.run_at_date = this.companyDate.add(1, 'day').format('MM/DD/YYYY')
                this.message.run_at_time = this.broadcastOpenHour
            }
        },

        setScheduleToNextBusinessHourWindow() {
            this.isScheduledToNextBusinessHourWindow = true
            this.setAssumedNextBusinessHourWindow()
        },
    },

    watch: {
        message: {
            handler(value) {
                // reset it to current date when date picker is cleared
                if (!this.message.run_at_date) {
                    this.message.run_at_date = this.getCurrentDate()
                }

                // reset it to broadcast open hour when time picker is cleared
                if (this.scheduled && !this.message.run_at_time) {
                    this.message.run_at_time = this.broadcastOpenHour
                }

                // update displayed time info according to time picker selected value
                if (this.scheduled) {
                    this.schedule_time = this.message.run_at_time
                }

                value.is_scheduled = this.scheduled
                this.$emit('input', value)
            },
            deep: true
        },

        scheduled: function (value) {
            // determine the datetime and format to display based on selected send schedule option
            if (value) {
                // fix time display once value is changed from "send now" option
                // set it to always start with current date and broadcast open hour
                this.message.run_at_time = this.broadcastOpenHour
                this.message.run_at_date = this.getCurrentDate()

                // stop the interval timer; we don't need to show and update current time
                if (this.send_datetime_interval) {
                    clearInterval(this.send_datetime_interval)
                }
                // on browsers, the interval variable is guaranteed to be a number that isn't equal to 0
                // making it null to make sure it is not treated as true when cleared
                this.send_datetime_interval = null;
                // update the time display
                this.schedule_time = this.message.run_at_time
            } else {
                // set current date & time if it is inside the business hour window
                // otherwise, date & time will have the value of the next business hour window programatically set
                if (!this.isScheduledToNextBusinessHourWindow) {
                    this.message.run_at_date = this.getCurrentDate()
                    this.message.run_at_time = this.companyDate.format('HH:mm')
                }

                // update the time display for interval updating
                this.schedule_time = this.companyDate.format('HH:mm')

                // for send now, we need to show current time
                this.sendTimeNowText()
            }
        },

        isScheduledToNextBusinessHourWindow: function (value) {
            // reset datetime picker
            if (!value) {
                this.message.run_at_time = this.scheduled ? this.broadcastOpenHour : this.companyDate.format('HH:mm')
                this.message.run_at_date = this.getCurrentDate()
            }
        }
    }
}
</script>
