<template>
    <div>
        <div class="row">
            <div class="col-12 p-3">
                <el-form ref="number-lookup"
                         label-position="top"
                         :model="number_lookup"
                         :rules="rules_number_lookup"
                         @submit.prevent.native="lookupPhoneNumber">
                    <el-form-item label="Enter a phone number:">
                        <el-input class="width-200 p-0"
                                  placeholder="+18185005050"
                                  v-model="number_lookup.phone_number"
                                  @input="preValidateForm('number-lookup')">
                        </el-input>
                        <el-button type="success"
                                   class="p-x-md"
                                   :loading="loading_lookup_btn"
                                   :disabled="!validated"
                                   @click="lookupPhoneNumber">
                            Lookup
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="row"
             v-if="has_result">
            <div class="col-md-6">
                <strong>Formatted response:</strong>
                <p class="text-danger mt-2"
                   v-if="lookup_result.error">
                    <strong>Error!</strong>
                </p>
                <p class="mt-2 mb-2"
                   v-if="lookup_result.sid">
                    <strong>Phone Number:</strong>
                    {{ lookup_result.sid | fixPhone('E164', true) }}
                </p>
                <p class="mb-2"
                   v-if="lookup_result.data">
                    <strong>National Format:</strong>
                    {{ lookup_result.sid | fixPhone('NATIONAL') }}
                </p>
                <p class="mb-2"
                   v-if="lookup_result.data">
                    <strong>Carrier Name:</strong>
                    {{ lookup_result.carrier }}
                </p>
                <p class="mb-2"
                   v-if="lookup_result.data">
                    <strong>Line Type:</strong>
                    {{ lookup_result.line_type | fixLrnType | fixName }}
                </p>
                <p class="mb-2"
                   v-if="lookup_result.data">
                    <strong>City:</strong>
                    {{ lookup_result.cnam_city }}
                </p>
                <p class="mb-2"
                   v-if="lookup_result.data">
                    <strong>State:</strong>
                    {{ lookup_result.cnam_state }}
                </p>
                <p class="mb-2"
                   v-if="lookup_result.data">
                    <strong>Country:</strong>
                    {{ lookup_result.cnam_country }}
                </p>
            </div>
            <div class="col-md-6">
                <strong>Response body:</strong>
                <pre v-highlightjs><code class="json">{{ lookup_result }}</code></pre>
            </div>
        </div>
        <div class="row b-t b-b">
            <div class="col-12 p-3">
                <company-form-token></company-form-token>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-3">
                <info-alert-box text="Please note that the Number Lookup API usage is considered paid. You are agreeing to our billing terms by using the API."
                                kb-link="https://support.aloware.com/en/articles/9033804-aloware-pricing-plans-empowering-your-teams-with-flexible-solutions">
                </info-alert-box>

                <div class="mb-2">
                    <strong class="integration-api-titles">Number lookup API (LRN API)</strong>
                </div>

                <p class="text-md">
                    <span class="text-dark-greenish _600">POST /api/v1/webhook/lookup</span>
                </p>

                <p>
                    <strong>Required Fields:</strong>
                    <br>
                    <span class="text-dark-greenish _600">api_token</span>,
                    <span class="text-dark-greenish _600">phone_number</span>
                </p>

                <p>
                    <strong>Example POST URL:</strong>
                    <br>
                    <span class="text-dark-greenish _600">{{ statics.domain | fixDomain }}/api/v1/webhook/lookup</span>
                </p>

                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to create a new contact:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_nl_a'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "phone_number": "8181234567",
}</code></pre>

                <p class="mb-2">
                    <strong>Example POST header:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "Accept": "application/json",
    "Content-Type": "application/json"
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 200 response on success, Example success response:
                    </strong>
                </p>
                <pre v-highlightjs><code class="json">{
  "sid": "4243942058",
  "line_type": "1",
  "carrier": "Sprint PCS/2",
  "cnam_city": "Burbank",
  "cnam_state": "CA",
  "cnam_country": "US",
  "data": {
    "tn": "4243942058",
    "lrn": "818795",
    "ported_status": "Y",
    "ported_date": "20190104222010",
    "ocn": "6664",
    <span class="text-dark-greenish _600">// Line Types: 0 = Landline, 1 = Wireless, 2 = VOIP, 3 = Other</span>
    "line_type": "1",
    "spid": "6664",
    "spid_carrier_name": "Sprint PCS/2",
    "spid_carrier_type": "1",
    "altspid": "",
    "altspid_carrier_name": "",
    "altspid_carrier_type": "",
    "city": "BURBANK",
    "state": "California",
    "country": "United States"
  }
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 400 on failure with a detailed response, Example failure response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "The given data was invalid.",
    "errors": {
        "phone_number": [
            "The phone number is required."
        ]
    }
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 402 when the LRN lookup limit has been reached out by trial accounts, Example failure response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "error": {
        "code": 402,
        "message": "LRN lookup limit has been reached out"
    }
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 404 if lookup can't identify the number, Example failure response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "error": {
        "code": 404,
        "message": "LRN data is empty for {number}"
    }
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 500 on failure with a detailed response, Example failure response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
  "error": {
    "code": 500,
    "message": "Could not run lrnLookup"
  }
}</code></pre>

                <p class="mb-2">
                    <strong>cURL Example:</strong>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_nl_b'" v-if="!loading_whitelabel"><code class="bash">curl -X POST \
  {{ statics.domain | fixDomain }}/api/v1/webhook/lookup \
  -H 'Accept: application/json' \
  -H 'Content-Type: application/json' \
  -d '{
    "api_token": "{{ companyToken }}",
    "phone_number": "8181234567",
  }'</code></pre>

                <p class="_600"
                   v-if="!statics.whitelabel">
                    If you need more API functions, please contact our support at support@aloware.com .
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {form_validation_mixin, validator_mixin, company_token_mixin} from '../mixins'
import CompanyFormToken from './account/company-form-token'
import InfoAlertBox from "./info-alert-box.vue"

export default {
    mixins: [
        validator_mixin,
        form_validation_mixin,
        company_token_mixin
    ],

    components: {
        InfoAlertBox,
        CompanyFormToken
    },

    data() {
        return {
            rules_number_lookup: {
                phone_number: {
                    validator: this.phoneValidator,
                    trigger: 'blur'
                }
            },
            number_lookup: {
                phone_number: null
            },
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            loading_lookup_btn: false,
            lookup_result: {},
            has_result: false
        }
    },

    created() {
        this.getStatics()
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        },

        lookupPhoneNumber() {
            this.has_result = false
            if (this.validateForm('number-lookup') == true) {
                this.loading_lookup_btn = true
                this.lookup_result = {}
                let data = {
                    'phone_number': this.number_lookup.phone_number,
                    'api_token': this.current_company.form_capture_token
                }
                axios.post('api/v1/webhook/lookup', data).then(res => {
                    this.loading_lookup_btn = false
                    this.lookup_result = res.data
                    this.has_result = true
                    this.resetNumberLookup()
                }).catch(err => {
                    console.log(err)
                    this.lookup_result = err.response.data
                    this.has_result = true
                    this.loading_lookup_btn = false
                    this.resetNumberLookup()
                })
            }

            return false
        },

        resetNumberLookup() {
            this.number_lookup.phone_number = null
            this.validated = false
        }
    }
}
</script>
