var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "d-flex flex-column bg-white",
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            top: "4vh",
            "custom-class": "power-dialer-session-setting-form",
            width: "616px",
            visible: _vm.show_form,
            "append-to-body": true,
            "close-on-click-modal": true,
            "show-close": false,
          },
        },
        [
          _c("power-dialer-session-setting-form", {
            ref: "power_dialer_session_setting_form",
            attrs: { session_setting: _vm.selected_setting },
            on: {
              cancel: function ($event) {
                _vm.show_form = false
              },
              success: _vm.updateSessionSettingList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex mb-2 flex-row justify-content-between" },
        [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "btn btn-primary alo-button",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.showForm },
            },
            [
              _c("i", { staticClass: "fa fa-plus mr-2" }),
              _vm._v(" "),
              _c("span", [_vm._v("Create Setting")]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "p-3 border rounded" }, [
        _vm.settings.length === 0
          ? _c("div", [
              _vm._v("\n            No session setting created yet.\n        "),
            ])
          : _c(
              "div",
              { staticClass: "d-flex flex-column" },
              _vm._l(_vm.settings, function (setting) {
                return _c(
                  "div",
                  {
                    key: setting.id,
                    staticClass:
                      "dialer-session-setting-item p-3 d-flex flex-row justify-content-between",
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dialer-session-setting-edit d-flex flex-column justify-content-center mr-2",
                          on: {
                            click: function ($event) {
                              return _vm.selectSessionSetting(setting)
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticStyle: { width: "18px", height: "18px" },
                              attrs: { viewBox: "0 0 24 24" },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "currentColor",
                                  d: "M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12A2,2 0 0,0 12,10M10,22C9.75,22 9.54,21.82 9.5,21.58L9.13,18.93C8.5,18.68 7.96,18.34 7.44,17.94L4.95,18.95C4.73,19.03 4.46,18.95 4.34,18.73L2.34,15.27C2.21,15.05 2.27,14.78 2.46,14.63L4.57,12.97L4.5,12L4.57,11L2.46,9.37C2.27,9.22 2.21,8.95 2.34,8.73L4.34,5.27C4.46,5.05 4.73,4.96 4.95,5.05L7.44,6.05C7.96,5.66 8.5,5.32 9.13,5.07L9.5,2.42C9.54,2.18 9.75,2 10,2H14C14.25,2 14.46,2.18 14.5,2.42L14.87,5.07C15.5,5.32 16.04,5.66 16.56,6.05L19.05,5.05C19.27,4.96 19.54,5.05 19.66,5.27L21.66,8.73C21.79,8.95 21.73,9.22 21.54,9.37L19.43,11L19.5,12L19.43,13L21.54,14.63C21.73,14.78 21.79,15.05 21.66,15.27L19.66,18.73C19.54,18.95 19.27,19.04 19.05,18.95L16.56,17.95C16.04,18.34 15.5,18.68 14.87,18.93L14.5,21.58C14.46,21.82 14.25,22 14,22H10M11.25,4L10.88,6.61C9.68,6.86 8.62,7.5 7.85,8.39L5.44,7.35L4.69,8.65L6.8,10.2C6.4,11.37 6.4,12.64 6.8,13.8L4.68,15.36L5.43,16.66L7.86,15.62C8.63,16.5 9.68,17.14 10.87,17.38L11.24,20H12.76L13.13,17.39C14.32,17.14 15.37,16.5 16.14,15.62L18.57,16.66L19.32,15.36L17.2,13.81C17.6,12.64 17.6,11.37 17.2,10.2L19.31,8.65L18.56,7.35L16.15,8.39C15.38,7.5 14.32,6.86 13.12,6.62L12.75,4H11.25Z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(setting.name))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "hover delete",
                        on: {
                          click: function ($event) {
                            return _vm.deleteSetting(setting.id)
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticStyle: {
                              width: "18px",
                              height: "18px",
                              color: "red",
                            },
                            attrs: { viewBox: "0 0 24 24" },
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                d: "M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label" }, [
      _c("h5", [_vm._v("Power Dialer Session Settings")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Create Company Session Settings for agents that use the Power Dialer in Aloware Talk"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex flex-column justify-content-center" },
      [_c("b", [_vm._v("Session Settings")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }