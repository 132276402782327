<template>
    <div v-if="hasPermissionTo('list ring group')">
        <label class="p-0 m-0 _500"
               v-if="label">
            {{ label }}
        </label>
        <el-tooltip effect="dark"
                    placement="top"
                    :content="disabled_tooltip_content"
                    :disabled="!disabled">
            <el-select class="w-full"
                       autocomplete="off"
                       clearable
                       filterable
                       :default-first-option="default_first"
                       :multiple="multiple"
                       :placeholder="placeholder"
                       :size="size"
                       :disabled="disabled"
                       :collapse-tags="collapsable"
                       data-testid="ring-group-selector"
                       v-model="ring_group_id"
                       @clear="ringGroupChange"
                       @change="ringGroupChange">
                <el-option :key="ring_group.id"
                           :label="ring_group.name"
                           :value="ring_group.id"
                           v-for="ring_group in ring_group_list">
                    <span>{{ ring_group.name }}</span>
                </el-option>
            </el-select>
        </el-tooltip>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import auth from '../auth'
    import {acl_mixin} from '../mixins'

    export default {
        mixins: [acl_mixin],

        props: {
            value: {
                required: false,
            },

            multiple: {
                type: Boolean,
                required: false,
                default: false
            },

            default_first: {
                type: Boolean,
                required: false,
                default: false
            },

            first_value: {
                type: String,
                required: false,
                default: null
            },

            label: {
                required: false,
                default: null
            },

            size: {
                type: String,
                default: '',
                required: false
            },

            collapsable: {
                type: Boolean,
                required: false,
                default: false
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            },

            disabled_tooltip_content: {
                type: String,
                required: false,
                default: null
            }
        },

        data() {
            return {
                auth: auth,
                ring_group_id: this.value,
                ring_group_list: []
            }
        },

        computed: {
            ...mapState({
                current_company: state => state.cache.current_company,
                ring_groups: state => state.ring_groups
            }),

            filteredRingGroups() {
                return this.ring_groups.filter(ring_group => !ring_group.call_waiting)
            },

            placeholder() {
                if (this.multiple) {
                    return 'Select Ring Groups'
                }

                return 'Select Ring Group'
            },
        },

        mounted() {
            this.updateRingGroupList()
        },

        watch: {
            value() {
                this.ring_group_id = this.value
            },

            ring_group_id(val) {
                if (this.value !== undefined) {
                    this.$emit('change', val)
                }
            },

            ring_groups: {
                deep: true,
                handler: function() {
                    this.updateRingGroupList()
                }
            }
        },

        methods: {
            ringGroupChange(ring_group_id) {
                if (!ring_group_id && this.first_value) {
                    ring_group_id = 'all'
                    this.ring_group_id = 'all'
                }

                this.$emit('change', ring_group_id)
            },

            updateRingGroupList() {
                if (this.first_value) {
                    const all = [{name: this.first_value.capitalize(), id: this.first_value, user_ids: []}]
                    this.ring_group_list = _.concat(all, this.filteredRingGroups)
                    return
                }
                this.ring_group_list = this.filteredRingGroups
            },
        },
    }
</script>
