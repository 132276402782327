<template>
    <div>
        <div class="vertical-center text-left"
             v-if="['campaigns', 'ring_groups'].includes(base)">
            <span v-if="filter.from_date"
                  class="call-log-head"
                  v-visible="!loading">
				{{ total_comms || 0 }} Calls & Texts from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
			</span>
            <span v-if="!filter.from_date"
                  class="call-log-head"
                  v-visible="!loading">
				{{ total_comms || 0 }} Calls & Texts
                <strong>All Time</strong>
			</span>
        </div>
        <div class="vertical-center text-left"
             v-else-if="base == 'callers'">
            <span v-if="filter.from_date"
                  class="call-log-head"
                  v-visible="!loading">
                {{ total_comms || 0 }} First Time Calls & Texts from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
            </span>
            <span v-if="!filter.from_date"
                  class="call-log-head"
                  v-visible="!loading">
                {{ total_comms || 0 }} First Time Calls & Texts
                <strong>All Time</strong>
            </span>
        </div>
        <div class="vertical-center text-left"
             v-else>
            <span v-if="filter.from_date"
                  class="call-log-head"
                  v-visible="!loading">
                {{ total_comms || 0 }} Calls & Texts from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
            </span>
            <span v-if="!filter.from_date"
                  class="call-log-head"
                  v-visible="!loading">
                {{ total_comms || 0 }} Calls & Texts
                <strong>All Time</strong>
            </span>
        </div>
        <div class="row mt-2 mb-2"
             v-if="is_graph">
            <div class="col-12">
                <div class="pull-left no-select">
                    <strong>Chart type:</strong>
                    <el-radio-group v-model="chart_type"
                                    :disabled="loading"
                                    size="mini"
                                    @change="changeChartType">
                        <el-radio-button label="pie">Pie</el-radio-button>
                        <el-radio-button label="column">Bar</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
        </div>
        <div class="placeholder text-center d-flex align-items-center justify-content-center"
             :class="{ blink: loading }"
             v-if="loading || campaigns.length == 0"
             style="height: 450px">
            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div style="height: 450px"
             v-else>
            <graph-data-switch :series="options.series"
                               :type="type"
                               @change="checkType">
                <highcharts v-bind:id="graph_id"
                            v-show="is_done && options.series.length > 0 && options.series[0].data.length != 0"
                            :options="options"
                            style="height: 450px"
                            ref="highchart">
                </highcharts>
                <div class="el-table__empty-block"
                     v-show="is_done && (options.series[0].data.length == 0 || !options.series.length)">
                    <span class="el-table__empty-text"
                          style="color: #606266;">
                        No Data
                    </span>
                </div>
            </graph-data-switch>
        </div>
    </div>
</template>
<script>
    import {graph_mixin, report_mixin} from '../mixins'
    import auth from '../auth'
    import Highcharts from '../HighchartsTheme'

    export default {
        props: {
            report_index: {
                default: 0
            },
            base: {},
            type: {}
        },

        mixins: [report_mixin, graph_mixin],

        data() {
            return {
                auth: auth,
                loading: false,
                total_comms: 0,
                graph_id: 'overview-pie-graph-' + this.base,
                report_type: this.base === 'users' ? 'count_v_user' : (this.base === 'lines' ? 'count_v_campaign' : 'count_v_ring_group'),
                first_time_only: '',
                chart_type: 'pie',
                is_graph: true,
                options: {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        events: {
                            drilldown: (e) => {
                                let total = 0
                                for (let i = 0, len = e.seriesOptions.data.length; i < len; i++) {
                                    total += e.seriesOptions.data[i][1]
                                }
                                let date_range = ''
                                if (this.filter.from_date) {
                                    date_range = this.$options.filters.fixFullDateLocal(this.filter.from_date) + ' to ' + this.$options.filters.fixFullDateLocal(this.filter.to_date)
                                } else {
                                    date_range = 'All Time'
                                }
                                this.$refs.highchart.chart.setTitle({text: e.point.name + '<br>Date Range: ' + date_range + '<br>Total: ' + total})
                            },
                            drillup: (e) => {
                                let total = 0 // get total of data
                                for (let i = 0, len = e.seriesOptions.data.length; i < len; i++) {
                                    total += e.seriesOptions.data[i].y
                                }
                                let date_range = ''
                                if (this.filter.from_date) {
                                    date_range = this.$options.filters.fixFullDateLocal(this.filter.from_date) + ' to ' + this.$options.filters.fixFullDateLocal(this.filter.to_date)
                                } else {
                                    date_range = 'All Time'
                                }
                                this.$refs.highchart.chart.setTitle({text: this.seriesTitle ? this.seriesTitle + ' Overview' + '<br>Date Range: ' + date_range + '<br>Total: ' + total : ''})
                            }
                        }
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        enabled: true,
                        followPointer: true,
                        split: false,
                        formatter: function (e) {
                            let drilldown = e.chart.options.drilldown.series.find(item => item.name == this.point.name)
                            let toReturn = '<b style="font-size: 16px; font-weight: 400;">' + this.point.name + '</b><br>'
                            if (drilldown && e.chart.series[0].options._levelNumber !== 1) {
                                for (let i = 0; i < drilldown.data.length; i++) {
                                    toReturn += '<b>' + drilldown.data[i][0] + ':</b> ' + drilldown.data[i][1] + '<br>'
                                }
                            }
                            if (this.point.percentage) {
                                toReturn += '<b>Total:</b> ' + this.point.y.toLocaleString() + ' (' + this.point.percentage.toFixed(2) + '%)'
                            } else {
                                toReturn += '<b>Total:</b> ' + this.point.y.toLocaleString()
                            }

                            return toReturn
                        }
                    },
                    plotOptions: {
                        column: {
                            stacking: null,
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    if (this.y != 0) {
                                        return this.y
                                    }
                                },
                                crop: false,
                                style: {
                                    fontSize: '14px'
                                }
                            }
                        },
                        pie: {
                            size: '100%',
                            dataLabels: {
                                enabled: true,
                                style: {
                                    color: 'inherit',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                                formatter: function () {
                                    if (this.point.percentage) {
                                        return this.point.name + ': ' + this.point.y.toLocaleString() + ' (' + this.point.percentage.toFixed(2) + '%)'
                                    }

                                    return this.point.name + ': ' + this.point.y.toLocaleString()

                                }
                            }
                        }
                    },
                    xAxis: {
                        visible: false,
                        type: 'category'
                    },
                    legend: {
                        enabled: false
                    },
                    drilldown: {
                        activeDataLabelStyle: {
                            color: 'inherit',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            textDecoration: 'none'
                        },
                        drillUpButton: {
                            relativeTo: 'spacingBox',
                            position: {
                                align: 'right',
                                x: 0,
                                y: 30
                            }
                        },
                        series: []
                    }
                }
            }
        },

        computed: {
            seriesTitle() {
                let title = ''
                if (this.base) {
                    title = this.base.capitalize()
                    title = title.replace('_', ' ')
                }

                return title
            }
        },

        created() {
            this.initiateGraph()
        },

        methods: {
            generateGraphData: function () {
                // form the series
                let series = {
                    name: this.seriesTitle,
                    colorByPoint: true,
                    data: [],
                }

                this.options.drilldown.series = []
                this.options.series = []

                // loop over what you got and setup the plots.
                this.total_comms = 0

                let keys = Object.keys(this.aggregated_counts)
                for (let index in keys) {
                    let campaign_data = this.aggregated_counts[keys[index]]

                    //skip null series_names
                    if (campaign_data.series_name == null) {
                        continue
                    }

                    let color = Highcharts.themes.future.colors[index]
                    if (campaign_data.count > 0) {
                        if (Object.keys(campaign_data.data).length > 0) {
                            series.data.push({
                                name: campaign_data.series_name,
                                y: campaign_data.count,
                                color: color,
                                drilldown: campaign_data.series_name
                            })

                            let drilldown_data = []
                            for (let key in campaign_data.data) {
                                if (campaign_data.data[key].outbound_count > 0) {
                                    drilldown_data.push(['Outbound ' + campaign_data.data[key].name + 's', campaign_data.data[key].outbound_count])
                                }
                                if (campaign_data.data[key].inbound_count > 0) {
                                    drilldown_data.push(['Inbound ' + campaign_data.data[key].name + 's', campaign_data.data[key].inbound_count])
                                }
                            }

                            this.options.drilldown.series.push({
                                name: campaign_data.series_name,
                                id: campaign_data.series_name,
                                data: drilldown_data,
                            })

                        }

                        this.total_comms += parseInt(campaign_data.count)
                    }
                }

                this.options.series.push(series)
                this.setTitle()
                this.$emit('reportLoaded', this.report_index)
            },

            setReportType: function () {
                this.first_time_only = ''

                switch (this.base) {
                    case 'users':
                        this.report_type = 'count_v_user'
                        break
                    case 'callers':
                        this.report_type = 'count_v_campaign'
                        this.first_time_only = 1
                        break
                    case 'campaigns':
                        this.report_type = 'count_v_campaign'
                        break
                    case 'ring_groups':
                        this.report_type = 'count_v_ring_group'
                        break
                }
            },

            setGraphId: function () {
                this.graph_id = 'overview-pie-graph-' + this.base
            },

            setTitle() {
                let total = 0 // get total of data
                if (this.options.series[0]) {
                    for (let i = 0, len = this.options.series[0].data.length; i < len; i++) {
                        total += this.options.series[0].data[i].y
                    }
                }
                let date_range = ''
                if (this.filter.from_date) {
                    date_range = this.$options.filters.fixFullDateLocal(this.filter.from_date) + ' to ' + this.$options.filters.fixFullDateLocal(this.filter.to_date)
                } else {
                    date_range = 'All Time'
                }
                this.options.title.text = this.seriesTitle ? this.seriesTitle + ' Overview' + '<br>Date Range: ' + date_range + '<br>Total: ' + total : ''
            },

            changeChartType() {
                this.$refs.highchart.chart.update({
                    xAxis: {
                        visible: this.chart_type === 'column' ? true : false
                    }
                })

                this.$refs.highchart.chart.update({
                    chart: {
                        type: this.chart_type
                    },
                })
            },

            checkType(type) {
                if (type == 'graph') {
                    this.chart_type = 'pie'
                    this.is_graph = true
                } else {
                    this.is_graph = false
                }
            },

            initiateGraph() {
                this.chart_type = 'pie'
                this.setGraphId()
                this.setReportType()
                this.getCommunications()
            }
        },

        watch: {
            base(newVal, oldVal) {
                this.initiateGraph()
            }
        }
    }
</script>
