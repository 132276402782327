var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row nav-padding" },
    [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "d-flex mb-3" }, [
          _c("h4", { staticClass: "mb-0" }, [_vm._v("Custom Contact Fields")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", size: "medium", round: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.attribute_dictionary_manager.add()
                    },
                  },
                },
                [_c("strong", [_vm._v("Add New")])]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading_list,
                expression: "loading_list",
              },
            ],
          },
          [
            _vm.attribute_dictionaries.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "el-table",
                      { attrs: { data: _vm.attribute_dictionaries } },
                      [
                        _c("el-table-column", {
                          attrs: { label: "Custom Field" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("h6", [_vm._v(_vm._s(scope.row.name))]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            548011667
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "Key", prop: "slug" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "Description", prop: "description" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "Type", prop: "type_values_text" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { padding: "0", width: "80px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-dropdown",
                                      {
                                        attrs: { trigger: "click" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "dropdown",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "el-dropdown-menu",
                                                    [
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editAttributeDictionary(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-edit",
                                                          }),
                                                          _vm._v(
                                                            " Edit\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-dropdown-item",
                                                        {
                                                          attrs: {
                                                            divided: "",
                                                          },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteAttributeDictionary(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-trash-o",
                                                          }),
                                                          _vm._v(
                                                            " Delete\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          { attrs: { size: "small" } },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-ellipsis-h",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3098647891
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "py-5" },
                  [
                    _c("img-empty", {
                      staticClass: "w-50 text-center mx-auto",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("p", { staticClass: "lead" }, [
                          _vm._v(
                            "\n                        You don't have any custom contact field setup yet.\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "ml-auto",
                            attrs: {
                              round: "",
                              size: "small",
                              type: "success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.attribute_dictionary_manager.add()
                              },
                            },
                          },
                          [_c("strong", [_vm._v("Add New Custom Field")])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("attribute-dictionary-manager", {
        ref: "attribute_dictionary_manager",
        on: { callback: _vm.fetchAttributeDictionaries },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }