var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _c("aup-consent", {
        on: {
          loaded: function ($event) {
            _vm.loading_aup = false
          },
        },
      }),
      _vm._v(" "),
      _vm.hasPermissionTo("create broadcast rvm") &&
      _vm.auth.user.profile.bulk_rvm_enabled &&
      !_vm.loading_aup
        ? _c("div", { staticClass: "padding" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("div", { staticClass: "box col-lg-7 col-12" }, [
                  _c("div", { staticClass: "no-effect" }, [
                    _c(
                      "div",
                      { staticClass: "box-body pt-3" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "bulk_rvm_form",
                            attrs: {
                              "label-position": "top",
                              model: _vm.bulk_rvm,
                              rules: _vm.rules,
                            },
                            nativeOn: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.preventSubmit.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "name", label: "Name:" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "width-400" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "name" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "Optional",
                                            maxlength: "190",
                                            "show-word-limit": "",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.preValidateForm(
                                                "bulk_message_form"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.bulk_rvm.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.bulk_rvm,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "bulk_rvm.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "From:", prop: "campaign_id" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "no-select",
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      placeholder: "Lines",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.preValidateForm(
                                          "bulk_rvm_form"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.bulk_rvm.campaign_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.bulk_rvm,
                                          "campaign_id",
                                          $$v
                                        )
                                      },
                                      expression: "bulk_rvm.campaign_id",
                                    },
                                  },
                                  _vm._l(
                                    _vm.activeCampaignsAlphabeticalOrder,
                                    function (campaign) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: campaign.id,
                                          attrs: {
                                            label: campaign.name,
                                            value: campaign.id,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(campaign.name)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-popover", {
                                  ref: "popover_campaign",
                                  staticClass: "ml-2",
                                  attrs: {
                                    placement: "right",
                                    title: "Line",
                                    width: "400",
                                    trigger: "hover",
                                    content:
                                      "The line you want to send the bulk RVM campaign from.",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "popover",
                                        rawName: "v-popover:popover_campaign",
                                        arg: "popover_campaign",
                                      },
                                    ],
                                    staticClass: "form-hover-help",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    What is this?\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-5" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Select a group of contacts:",
                                      },
                                    },
                                    [
                                      _c("contact-tags-filters-selector", {
                                        ref: "contactTagFilterSelector",
                                        attrs: {
                                          show_integrations: true,
                                          tag_category:
                                            _vm.TagCategories.CAT_CONTACTS,
                                        },
                                        on: {
                                          contactTagsGroupChanged:
                                            _vm.contactTagsGroupChanged,
                                          hubspotListChanged:
                                            _vm.hubspotListChanged,
                                          zohoViewChanged: _vm.zohoViewChanged,
                                          pipedriveFilterChanged:
                                            _vm.pipedriveFilterChanged,
                                          resetTagSelector:
                                            _vm.resetTagSelector,
                                          resetContactFiltersSelector:
                                            _vm.resetContactFiltersSelector,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mt-3 mb-2" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("broadcast-schedule", {
                                    ref: "broadcast_schedule",
                                    attrs: { message: _vm.bulk_rvm },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Throttling:",
                                  prop: "throttle_limit",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "no-select",
                                    staticStyle: { width: "300px" },
                                    attrs: { placeholder: "Options" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.preValidateForm(
                                          "bulk_rvm_form"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.bulk_rvm.throttle_limit,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.bulk_rvm,
                                          "throttle_limit",
                                          $$v
                                        )
                                      },
                                      expression: "bulk_rvm.throttle_limit",
                                    },
                                  },
                                  _vm._l(
                                    _vm.throttlingOptions,
                                    function (option) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: option.id,
                                          attrs: {
                                            label: option.name,
                                            value: option.value,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(option.name)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-popover", {
                                  ref: "popover_throttle_limit",
                                  staticClass: "ml-2",
                                  attrs: {
                                    placement: "right",
                                    title: "Throttling",
                                    width: "400",
                                    trigger: "hover",
                                    content:
                                      "This is an hourly throttling limit on your bulk RVM campaign.",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "popover",
                                        rawName:
                                          "v-popover:popover_throttle_limit",
                                        arg: "popover_throttle_limit",
                                      },
                                    ],
                                    staticClass: "form-hover-help",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    What is this?\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row mb-3" }, [
                              _c("div", { staticClass: "col-12" }, [
                                !_vm.bulk_rvm.view_id
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.loading_count,
                                            expression: "loading_count",
                                          },
                                        ],
                                        staticClass:
                                          "box pt-2 pb-2 dker bulk-sms-counter pull-left mr-2",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "inline p-x text-center",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "h4 block m-a-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.contacts_count)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              {
                                                staticClass:
                                                  "text-xs text-muted",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.contacts_count > 1 ||
                                                      _vm.contacts_count == 0
                                                      ? "Contacts"
                                                      : "Contact"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.current_company &&
                                _vm.current_company.reseller_id != 357 &&
                                !_vm.bulk_rvm.view_id
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "box pt-2 pb-2 bulk-sms-counter pull-left",
                                        class: _vm.getClass,
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "inline p-x text-center",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "h4 block m-a-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("toCurrency")(
                                                      _vm._f("fixRounding")(
                                                        _vm.messagesPrice
                                                      )
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              {
                                                staticClass:
                                                  "text-xs text-muted",
                                              },
                                              [_vm._v("Credits")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "no-bottom-margin mt-5 mb-5" },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "file_name" } },
                                  [
                                    _vm.bulk_rvm.file_name === null
                                      ? _c("audio-recorder", {
                                          staticClass: "audio-recorder-wrapper",
                                          attrs: {
                                            action:
                                              "/api/v1/broadcasts/upload/rvm",
                                            upload_type: "file_name",
                                          },
                                          on: {
                                            applyUploadedAudio:
                                              _vm.applyUploadedAudio,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "audio-uploader-wrapper" },
                                      [
                                        _vm.bulk_rvm.file_name === null
                                          ? _c(
                                              "el-upload",
                                              {
                                                ref: "greetingAudio",
                                                attrs: {
                                                  drag: "",
                                                  action:
                                                    "/api/v1/broadcasts/upload/rvm",
                                                  headers: _vm.headers,
                                                  "on-success":
                                                    _vm.onSuccessUpload,
                                                  "on-error":
                                                    _vm.onFailedUpload,
                                                  "on-progress":
                                                    _vm.progressUploadUpload,
                                                  "before-upload":
                                                    _vm.beforeUploadUpload,
                                                  multiple: false,
                                                  "file-list":
                                                    _vm.uploadFileList.upload,
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-upload",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "el-upload__text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Drop file here or "
                                                    ),
                                                    _c("em", [
                                                      _vm._v(
                                                        "click to\n                                                upload"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "el-upload__tip",
                                                    attrs: { slot: "tip" },
                                                    slot: "tip",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                MP3/WAV file (less than 8MB)\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.bulk_rvm.file_name === null
                                          ? _c("el-progress", {
                                              attrs: {
                                                "text-inside": true,
                                                "stroke-width": 18,
                                                percentage:
                                                  _vm.uploadPercentage.upload,
                                                status: _vm.uploadStatus.upload,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.bulk_rvm.file_name !== null
                                          ? _c(
                                              "audio",
                                              { attrs: { controls: "" } },
                                              [
                                                _c("source", {
                                                  attrs: {
                                                    src:
                                                      "/static/uploaded_file/" +
                                                      _vm.bulk_rvm.file_name,
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                                            Your browser does not support the\n                                            audio\n                                            element.\n                                        "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.bulk_rvm.file_name !== null
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-danger pull-right",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.deleteFile()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons pull-left",
                                                  },
                                                  [_vm._v("")]
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v("Remove file"),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-right mt-3" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-md greyish mr-2",
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm("bulk_rvm_form")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Reset\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-md greenish",
                              attrs: {
                                disabled:
                                  _vm.loading ||
                                  !_vm.validated ||
                                  !_vm.countValidated ||
                                  !_vm.checkCredits,
                              },
                              on: { click: _vm.sendBulkRVM },
                            },
                            [
                              _c(
                                "i",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                  ],
                                  staticClass: "material-icons loader",
                                },
                                [
                                  _vm._v(
                                    "\n                                    \n                                "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                                Send Bulk RVM\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-card",
                  {
                    staticClass:
                      "col-lg-5 box-card help-card d-none d-sm-none d-md-block",
                  },
                  [
                    _c("div", { staticClass: "text item" }, [
                      _c("span", { staticClass: "_600" }, [
                        _vm._v("Tips for a successful bulk RVM campaign:"),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      !_vm.statics.whitelabel
                        ? _c("span", { staticClass: "_400" }, [
                            _vm._v(
                              "\n                        Please contact our support at support@aloware.com before sending out the campaign.\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }