var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: { placement: "right-start", width: "350", trigger: "click" },
      on: { show: _vm.resetForm },
    },
    [
      _c(
        "el-form",
        [
          _c("h6", { staticClass: "mb-2 text-black" }, [
            _vm._v("Assign contacts to"),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mb-3" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.tab_name,
                    callback: function ($$v) {
                      _vm.tab_name = $$v
                    },
                    expression: "tab_name",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "User", name: "user" } },
                    [
                      _c("p", { staticClass: "lh-1p4 mb-2 text-dark" }, [
                        _vm._v("Assign the leads to this user"),
                      ]),
                      _vm._v(" "),
                      _c("user-selector", {
                        ref: "userSelector",
                        attrs: { hide_extensions: true, clearable: "" },
                        on: { change: _vm.changeUserId },
                        model: {
                          value: _vm.user_id,
                          callback: function ($$v) {
                            _vm.user_id = $$v
                          },
                          expression: "user_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Ring Group", name: "ring_group" } },
                    [
                      _c("p", { staticClass: "lh-1p4 mb-2 text-dark" }, [
                        _vm._v(
                          "Assign the leads evenly and randomly between the users on this ring group"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("ring-group-selector", {
                        on: { change: _vm.ringGroupChange },
                        model: {
                          value: _vm.ring_group_id,
                          callback: function ($$v) {
                            _vm.ring_group_id = $$v
                          },
                          expression: "ring_group_id",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { staticClass: "mt-2" }, [
            _c(
              "div",
              {
                staticClass: "form-label text-dark",
                attrs: { scope: "label" },
              },
              [
                _c("p", { staticClass: "lh-1p4" }, [
                  _vm._v(
                    "\n                    By default, this tool only distributes unassigned contacts.\n                "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-label mb-1", attrs: { scope: "label" } },
              [
                _c("el-checkbox", {
                  staticClass: "w-full",
                  attrs: { label: "Also distribute assigned contacts" },
                  model: {
                    value: _vm.force,
                    callback: function ($$v) {
                      _vm.force = $$v
                    },
                    expression: "force",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "w-full",
                  attrs: {
                    type: "success",
                    size: "small",
                    disabled: _vm.unassignable,
                  },
                  on: { click: _vm.assignContactTag },
                },
                [
                  _c("i", { staticClass: "fas fa-sign-in-alt" }),
                  _vm._v(" Assign\n            "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-block btn-warning btn-sm",
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [
          _c("i", { staticClass: "fas fa-sign-in-alt pull-left" }),
          _vm._v(" "),
          _vm._t("default", function () {
            return [_vm._v("Assign Contacts")]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }