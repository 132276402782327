var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeTagList,
          expression: "closeTagList",
        },
      ],
      staticClass: "filter-attribution",
    },
    [
      _c(
        "el-badge",
        {
          staticClass: "filter-counter",
          attrs: {
            type: "success",
            value: _vm.tags.length,
            hidden: _vm.tags.length === 0,
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", round: "", plain: "" },
              on: { click: _vm.toggleTagList },
            },
            [_vm._v("\n            Tags\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.show_tag_list
        ? _c(
            "div",
            { staticClass: "filter-attribution-data-list" },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-row justify-content-end mb-2" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "border-0",
                      attrs: { size: "small" },
                      on: { click: _vm.closeTagList },
                    },
                    [_vm._v("\n                Close\n            ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("tag-selector", {
                staticClass: "w-100 filter-select",
                attrs: {
                  "place-holder": "Filter Tags",
                  multiple: true,
                  value: _vm.tags,
                },
                on: { change: _vm.setTempTags },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-row justify-content-between mt-4" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small", round: "" },
                      on: { click: _vm.clear },
                    },
                    [_vm._v("\n                Clear\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success", size: "small", round: "" },
                      on: { click: _vm.apply },
                    },
                    [_vm._v("\n                Apply\n            ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }