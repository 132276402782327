<template>
    <div v-if="currentUser" 
         data-testid="agent-status-dropdown-wrapper">
        <p class="font-weight-normal mb-0 text-center"
           v-if="!forced_disabled_ui">Modify Agent's Status</p>
        <div class="col px-0"
             v-for="item in AgentStatusLabels.LABELS"
             v-show="!item.disabled"
             :key="item.value">
            <button class="dropdown-item cursor-pointer pt-2 pb-2"
                    :class="[item.disabled || isForcedDisabled(item.value) ? 'btn-disabled' : '']"
                    :disabled="item.disabled || isForcedDisabled(item.value)"
                    data-testid="change-agent-status-btn"
                    @click.prevent="changeAgentStatus(item.value)">
                <i class="b-white bottom status-badge"
                   :class="[ $options.filters.agentStatusClass(item.value) ]">
                </i>
                <span :class="[ currentUser.agent_status == item.value ? 'text-blue _600' : '' ]">{{ item.label }}</span>

                <el-popover
                    placement="left-bottom"
                    trigger="hover"
                    data-testid="popover"
                    v-if="isForcedDisabled(item.value)">
                    <span class="badge badge-warning py-1 w-100">
                        Forced at account level
                    </span>
                    <i class="fa fa-info-circle pull-right"
                       slot="reference">
                    </i>
                </el-popover>
            </button>
        </div>
    </div>
</template>

<script>
import auth from '../../auth'
import {acl_mixin} from '../../mixins'
import {mapState} from 'vuex'
import * as AgentStatus from '../../constants/agent-status'
import * as AgentStatusLabels from '../../constants/agent-status-labels'

export default {
    mixins: [acl_mixin],

    data() {
        return {
            auth: auth,
            disable_change_statuses: [
                AgentStatus.AGENT_STATUS_AUTO_DIAL,
                AgentStatus.AGENT_STATUS_RINGING
            ],
            AgentStatus,
            AgentStatusLabels
        }
    },

    props: {
        user_id: {required: true},
        forced_disabled_ui: {required: true},
        index: {required: false},
        is_wallboard: {required: false, default: false, type: Boolean}
    },

    computed: {
        ...mapState('cache', ['current_company']),
        ...mapState(['users', 'on_call']),

        isCompanyForcedAvailable() {
            return this.current_company?.force_users_always_available
        },

        currentUser() {
            if (this.user_id) {
                const user = this.users.find(user => +user.id === +this.user_id)

                return user
            }

            if (this.auth && this.auth.user && this.auth.user.profile) {
                return this.auth.user.profile
            }

            return null
        }
    },

    methods: {
        isForcedDisabled(status) {
            const forced_disabled_statuses = [
                AgentStatus.AGENT_STATUS_ON_BREAK,
                AgentStatus.AGENT_STATUS_NOT_ACCEPTING_CALLS
            ]

            return this.isCompanyForcedAvailable &&
                this.hasRole('Company Agent') && // apply for agents only
                forced_disabled_statuses.includes(status) &&
                this.forced_disabled_ui
        },

        changeAgentStatus(agent_status) {
            const user = this.users.find(user => +user.id === +this.user_id)
            const current_state = {
                user_id: user.id,
                company_id: user.company_id,
                agent_status: user.agent_status
            }

            // do not allow change if current agent status of agent is auto dial, ringing or on call (special case)
            if (this.disable_change_statuses.includes(user.agent_status) || (this.on_call && user.agent_status === AgentStatus.AGENT_STATUS_ON_CALL)) {
                return
            }

            // current user changed their status from avatar
            if (!this.is_wallboard) {
                VueEvent.fire('change_agent_status', {agent_status, is_from_dropdown: true, signature: 'Classic-DropDown'})
                return
            }

            // other user's status is changed from wallboard
            axios.post(`/api/v1/user/${this.user_id}/agent-status`, {
                agent_status
            }, {
              headers: {
                'Signature': 'Classic-Wallboard'
              }
            }).then(({ data }) => {
                VueEvent.fire('agent_status_updated', data)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                VueEvent.fire('agent_status_updated', current_state)
            })
        },
    }
}
</script>
