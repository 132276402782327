<template>
    <el-select
        class="width-500 fixed lh-1 multi-line"
        placeholder="Choose the area codes"
        v-model="selected_area_codes"
        multiple
        filterable
        default-first-option>
        <el-option-group
            v-for="(group, index) in area_codes"
            :key="index"
            :label="group.state_name">
            <el-option
                v-for="item in group.area_codes"
                :key="item.value"
                :label="item.value"
                :value="item.value">
                <div class="media">
                    <div class="media-body">
                        <label>{{ item.value }}</label>
                        <small>{{ item.label }}</small>
                    </div>
                </div>
            </el-option>
        </el-option-group>
    </el-select>
</template>

<script>
    import {mapState} from 'vuex'
    import auth from '../auth'
    import area_codes from '../us-area-code-state.json'

    export default {
        props: {
            value: {
                required: false,
                default: null
            },

            multiple: {
                type: Boolean,
                required: false,
                default: false
            },

            show_only: {
                default: false,
                type: Boolean,
                required: false
            },

            disabled: {
                default: false,
                type: Boolean,
                required: false
            },
        },

        data() {
            return {
                auth: auth,
                area_codes: area_codes,
                selected_area_codes: this.value,
            }
        },

        computed: {
            ...mapState({
                current_company: state => state.cache.current_company
            }),

            placeholder() {
                if (this.multiple) {
                    return 'Select Area Codes'
                }

                return 'Select Area Code'
            },
        },

        watch: {
            value() {
                this.selected_area_codes = this.value
            },

            selected_area_codes(val) {
                if (this.value !== undefined && this.selected_area_codes !== this.value) {
                    this.$emit('change', val)
                }
            }
        }
    }
</script>
