var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-form", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("h5", { staticClass: "text-dark" }, [
            _c("i", { staticClass: "el-icon-document" }),
            _vm._v("\n                Instructions (*)\n            "),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("div", { staticClass: "form-label" }, [
                _c("small", [
                  _vm._v(
                    "\n                        Write a personality for the bot and try to explain what you want the bot to perform in details.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                ref: "textareaInput",
                staticClass: "w-100 aloai-instructions",
                attrs: {
                  type: "textarea",
                  rows: 14,
                  readonly: _vm.loadingTags,
                },
                model: {
                  value: _vm.chatbot.instructions[0].content,
                  callback: function ($$v) {
                    _vm.$set(_vm.chatbot.instructions[0], "content", $$v)
                  },
                  expression: "chatbot.instructions[0].content",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { content: "Add Disposition", placement: "top" } },
                    [
                      _c("dispositions-dropdown", {
                        key: _vm.dispositionsDropdownKey,
                        attrs: {
                          "selected-disposition": _vm.currentDisposition,
                        },
                        on: { "disposition-selected": _vm.dispositionSelected },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    { attrs: { content: "Add Tags", placement: "top" } },
                    [
                      _c("tags-dropdown", {
                        key: _vm.tagsDropdownKey,
                        attrs: {
                          "custom-tags": _vm.currentTags,
                          "selected-tags-list": _vm.currentTagsIds,
                          disabled: _vm.loadingTags,
                        },
                        on: { "tags-selected": _vm.tagsSelected },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    { attrs: { content: "Add Lists", placement: "top" } },
                    [
                      _c("lists-dropdown", {
                        attrs: {
                          "custom-lists": _vm.currentLists,
                          "selected-list-ids": _vm.currentListsIds,
                        },
                        on: { "lists-selected": _vm.listsSelected },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "Add Disengage Trigger",
                        placement: "top",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "border-0 bg-transparent",
                          staticStyle: { "margin-left": "-2px" },
                          attrs: { size: "mini", circle: "" },
                          on: { click: _vm.addDisengageTrigger },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-ellipsis-h",
                            staticStyle: {
                              padding: "3px",
                              "border-radius": "50%",
                              "font-size": "10px",
                              color: "rgb(186, 186, 186)",
                              border: "1px solid rgb(186, 186, 186)",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("h5", { staticClass: "text-dark" }, [
          _c("i", { staticClass: "el-icon-setting" }),
          _vm._v("\n                Tools\n            "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml-4" },
          [
            _c(
              "el-form-item",
              [
                _c("div", { staticClass: "form-label" }, [
                  _c("small", [
                    _vm._v(
                      "\n                            Select the functions to use with this chatbot.\n                        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-100",
                    attrs: {
                      multiple: "",
                      filterable: "",
                      placeholder: "Select functions",
                    },
                    on: { change: _vm.changeFunction },
                    model: {
                      value: _vm.functions_list,
                      callback: function ($$v) {
                        _vm.functions_list = $$v
                      },
                      expression: "functions_list",
                    },
                  },
                  _vm._l(_vm.getHardcodedFunctions, function (tool, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: tool.label, value: tool.function.name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("h5", { staticClass: "text-dark" }, [
          _c("i", { staticClass: "el-icon-collection" }),
          _vm._v("\n                KB Files\n            "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml-4" },
          [
            _c(
              "el-form-item",
              [
                _c("div", { staticClass: "form-label" }, [
                  _c("small", [
                    _vm._v(
                      "\n                            Upload the docs you want the bot to use as knowledge base. Supported formats are: docx, html, json, md, pdf, pptx, txt, csv\n                        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.chatbot.is_assistant &&
                _vm.chatbot.model !== _vm.AloAi.MODEL_GPT_35_T
                  ? _c("multi-upload", {
                      attrs: {
                        action: _vm.getAction,
                        "delete-action": _vm.deleteAction,
                        files: _vm.uploaded_files,
                      },
                      on: { change: _vm.uploadedFilesChanged },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.chatbot.is_assistant
                  ? _c("p", { staticClass: "text-md _400 text-danger" }, [
                      _vm._v(
                        "\n                        This is a chatbot, reach out to support to upgrade it to an assistant bot to use this feature.\n                    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.chatbot.model === _vm.AloAi.MODEL_GPT_35_T
                  ? _c("p", { staticClass: "text-md _400 text-danger" }, [
                      _vm._v(
                        "\n                        You can't have KB files with this model.\n                    "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }