<template>
    <router-link
        :to="{ name: 'Lists' }"
        active-class="active green-gradient rounded"
        exact-active-class="active green-gradient rounded"
        tag="li"
        v-if="showLink">
        <a>
            <span class="nav-icon">
                <img src="/assets/images/app-icons/list-icon.png">
            </span>
            <span class="nav-icon active">
                <img src="/assets/images/app-icons/list-icon-white.png">
            </span>
            <span class="nav-text">Lists</span>
        </a>
    </router-link>
</template>

<script>
import auth from '../../auth'
import {acl_mixin} from '../../mixins'
import {mapState} from 'vuex'

export default {
    mixins: [acl_mixin],

    data() {
        return {
            auth: auth
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        showLink() {
            return !!(this.current_company && this.current_company.talk_enabled)
        }
    }
}
</script>
