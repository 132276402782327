var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-xs-12 col-xl-8 p-3 mx-auto" }, [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "el-button",
            {
              staticClass: "mb-3",
              attrs: { type: "success" },
              on: { click: _vm.testRingGroup },
            },
            [
              _vm.loading_test
                ? _c("i", { staticClass: "material-icons loader" }, [
                    _vm._v("\n                    \n                "),
                  ])
                : _c("i", { staticClass: "material-icons" }, [
                    _vm._v("refresh\n                "),
                  ]),
              _vm._v("\n                Refresh\n            "),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading_test,
              expression: "loading_test",
            },
          ],
        },
        [
          _c("div", [
            _vm.available_users_count
              ? _c("div", { staticClass: "media mx-auto w-50" }, [
                  _c("i", {
                    staticClass: "fas fa-phone mr-2 color-999",
                    staticStyle: { "font-size": "40px" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "media-body" }, [
                    _vm.ring_group.dial_mode ===
                    _vm.RingGroupDialMode.DIAL_MODE_SIMUL
                      ? _c("p", { staticClass: "break-word" }, [
                          _vm._v(
                            "\n                            All incoming calls to "
                          ),
                          _c("strong", [_vm._v(_vm._s(_vm.ring_group.name))]),
                          _vm._v(
                            " will ring all available\n                            users in the first layer below.\n                        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ring_group.dial_mode ===
                    _vm.RingGroupDialMode.DIAL_MODE_RAND
                      ? _c("p", { staticClass: "break-word" }, [
                          _vm._v(
                            "\n                            All incoming calls to "
                          ),
                          _c("strong", [_vm._v(_vm._s(_vm.ring_group.name))]),
                          _vm._v(
                            " will randomly ring one\n                            available user in the first layer below.\n                        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ring_group.dial_mode ===
                    _vm.RingGroupDialMode.DIAL_MODE_ROUND
                      ? _c("p", { staticClass: "break-word" }, [
                          _vm._v(
                            "\n                            All incoming calls to "
                          ),
                          _c("strong", [_vm._v(_vm._s(_vm.ring_group.name))]),
                          _vm._v(
                            " will ring the first user\n                            available in the first layer below and continues dialing in a round-robin fashion.\n                        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ring_group.dial_mode ===
                    _vm.RingGroupDialMode.DIAL_MODE_LONGEST_AVAILABLE
                      ? _c("p", { staticClass: "break-word" }, [
                          _vm._v(
                            "\n                            All incoming calls to "
                          ),
                          _c("strong", [_vm._v(_vm._s(_vm.ring_group.name))]),
                          _vm._v(
                            " will ring the longest available user\n                            available in the first layer below and continues dialing based on that.\n                        "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _c("div", { staticClass: "media mx-auto w-50 text-danger" }, [
                  _c("i", {
                    staticClass: "fas fa-phone-slash mr-2",
                    staticStyle: { "font-size": "40px" },
                  }),
                  _vm._v(" "),
                  _vm._m(0),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-center" }, [
              _vm.available_users_count
                ? _c("div", [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "Number of available users: " +
                          _vm._s(_vm.available_users_count)
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "Time of test: " +
                      _vm._s(
                        _vm._f("momentFormat")(
                          new Date(),
                          "MM/DD h:mma z",
                          true
                        )
                      )
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.layers
            ? _c("ring-group-routing-display", {
                attrs: { ring_group: _vm.ring_group, layers: _vm.layers },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "text-center" }, [
            _vm._v(
              "\n                Please note that this utility does not look into contact / communication ownership.\n            "
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "media-body" }, [
      _c("p", [
        _vm._v(
          "\n                            This ring group does not have any eligible agents to take call. See below.\n                        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }