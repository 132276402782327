<template>
    <input-field label="Repeat your Password"
                 placeholder="Type your password again"
                 is-password
                 :padding-classes="passingClasses"
                 :col-span="colSpan"
                 v-model="passwordConfirmation"
                 @input="onInput">
        <template v-slot:hint
                  v-if="passwordConfirmation">
            <ul class="text-left pl-2 text-weight-regular password-hint">
                <li>
                    <el-icon class="mr-xs"
                             :class="getPasswordRuleClass(isMatch)"
                             :name="iconForValidation(isMatch)">
                    </el-icon>
                    {{ passwordMatchText }}
                </li>
            </ul>
        </template>
    </input-field>
</template>

<script>
export default {
    props: {
        value: String,
        mainPassword: String,
        colSpan: {
            type: Number,
            default: 12
        },
        passingClasses: {
            type: String,
            default: ''
        },
    },

    data() {
        return {
            passwordConfirmation: this.value || ''
        }
    },

    computed: {
        isMatch() {
            return this.passwordConfirmation === this.mainPassword
        },
        passwordMatchText() {
            return this.isMatch ? 'The passwords match' : "The passwords don't match"
        }
    },

    watch: {
        mainPassword() {
            this.$emit('match', this.isMatch)
        },
        passwordConfirmation(newVal) {
            this.$emit('input', newVal)
            this.$emit('match', this.isMatch)
        }
    },

    methods: {
        getPasswordRuleClass(rule) {
            return rule ? 'text-green' : 'text-red'
        },
        iconForValidation(rule) {
            return rule ? 'check' : 'close'
        },
        onInput() {
            this.$emit('input', this.passwordConfirmation)
            this.$emit('match', this.isMatch)
        }
    }
}
</script>
