var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "time-range-picker row p-2" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c(
        "div",
        { staticClass: "d-flex flex-column" },
        [
          _c("b", [_vm._v(_vm._s(_vm.start_time_label))]),
          _vm._v(" "),
          _c("v-date-picker", {
            staticClass: "start-time-picker",
            attrs: { mode: "time", "title-position": "left", color: "green" },
            model: {
              value: _vm.start_time,
              callback: function ($$v) {
                _vm.start_time = $$v
              },
              expression: "start_time",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c(
        "div",
        { staticClass: "d-flex flex-column" },
        [
          _c("b", [_vm._v(_vm._s(_vm.end_time_label))]),
          _vm._v(" "),
          _c("v-date-picker", {
            staticClass: "end-time-picker",
            attrs: { mode: "time", "title-position": "left", color: "green" },
            model: {
              value: _vm.end_time,
              callback: function ($$v) {
                _vm.end_time = $$v
              },
              expression: "end_time",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }