<template>
    <div class="d-inline">
        <el-tooltip placement="top"
                    width="280">
            <div slot="content">
                {{ fullTimeStamp }}
            </div>
            <span class="text-muted">
                {{ relative_datetime }}
            </span>
        </el-tooltip>
    </div>
</template>
<script>
    export default {
        props: {
            datetime: {
                required: true,
                type: String
            }
        },

        data() {
            return {
                relative_datetime: null
            }
        },

        computed: {
            fullTimeStamp() {
                return moment.utc(this.datetime).tz(window.timezone).format('h:mma MMM D, YYYY z')
            }
        },

        methods: {
            getRelativeDateTime() {
                this.relative_datetime = this.$options.filters.fixFullDateUTCRelative(this.datetime)
            }
        },

        created() {
            this.getRelativeDateTime()
            setInterval(this.getRelativeDateTime, 10000)
        },

        destroyed() {
            clearInterval(this.getRelativeDateTime)
        },
    }
</script>
