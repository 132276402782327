var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._v(
      "\n    Your " +
        _vm._s(_vm.compliance_model.class_name) +
        " has been " +
        _vm._s(_vm.compliance_model.status_name) +
        ".\n    "
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "button",
        {
          staticClass: "btn rounded greenish btn-status btn-sm mt-2 pull-right",
        },
        [_vm._v("\n            Check Status\n        ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }