export const PRE_SIGNUP_SUBMISSION_TYPES = {
    ssu: {
        id: 1,
        visible_name: 'Self-sign up'
    },
    admin: {
        id: 2,
        visible_name: 'Aloware'
    },
    reseller: {
        id: 3,
        visible_name: 'Partner'
    }
}
