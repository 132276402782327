var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.canAddExtensionStep
        ? _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addExtensionStep },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("headset_mic"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "_700 pointer" }, [
                    _vm._v("Listen For Extensions?"),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition-group",
        { attrs: { name: "ivr-list" } },
        _vm._l(_vm.steps, function (ivr_step, idx) {
          return !ivr_step.type || ivr_step.type !== "exit"
            ? _c(
                "el-card",
                { key: ivr_step.id, staticClass: "box-card mb-3 ivr-step" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      !ivr_step.listen_for_extension
                        ? [
                            _c("span", { staticClass: "text-md _600" }, [
                              _vm._v(
                                "#" + _vm._s(ivr_step.key) + " - IVR Option"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "pull-right text-danger",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeAt(idx)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("delete"),
                                ]),
                                _vm._v(
                                  "\n                        Delete\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "pull-right text-blue mr-3",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.duplicate(ivr_step, idx)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("content_copy"),
                                ]),
                                _vm._v(
                                  "\n                        Duplicate\n                    "
                                ),
                              ]
                            ),
                          ]
                        : [
                            _c("span", { staticClass: "text-md _600" }, [
                              _vm._v("Listen For Extensions"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "pull-right text-danger",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeAt(idx)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("delete"),
                                ]),
                                _vm._v(
                                  "\n                        Delete\n                    "
                                ),
                              ]
                            ),
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("campaign-ivr-step", {
                    attrs: {
                      campaign: _vm.campaign,
                      ivr_step: ivr_step,
                      used_keys: _vm.usedKeys,
                    },
                  }),
                ],
                1
              )
            : _vm._e()
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card mb-3" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_c("span", { staticClass: "text-md _600" }, [_vm._v("IVR Exit")])]
          ),
          _vm._v(" "),
          _c("campaign-ivr-step", {
            attrs: {
              campaign: _vm.campaign,
              ivr_step: _vm.exitStep,
              used_keys: _vm.usedKeys,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addStep } },
            [
              _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
              _vm._v(" "),
              _c("span", { staticClass: "_700 pointer" }, [_vm._v("Add Step")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }