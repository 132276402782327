var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading_whitelabel && !_vm.statics.whitelabel
    ? _c(
        "div",
        { staticClass: "signup-container container-fluid w-v h-v mellow-blue" },
        [
          _c("div", { staticClass: "row full-height" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-lg-12 d-flex justify-content-center align-items-center text-color",
                class: [_vm.statics.whitelabel ? "offset-lg-3" : ""],
              },
              [_c("signup", { attrs: { statics: _vm.statics } })],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }