import {MONTHLY, ONE_TIME} from "./number-guard-frequencies"

export default Object.freeze({
    packages: [
        {
            label: 'Local Presence Line',
            value: 1,
        },
        {
            label: 'Non-Local Presence Lines',
            value: 2,
        },
        {
            label: 'All Lines',
            value: 3,
        }
    ],
    frequencies: [
        {
            label: ONE_TIME,
            value: 1,
        },
        {
            label: MONTHLY,
            value: 3,
        }
    ],
    pricing: [
        {
            phoneNumbers: { min: 1, max: 20 },
            monthly: 100,
            oneTime: 250
        },
        {
            phoneNumbers: { min: 21, max: 100 },
            monthly: 250,
            oneTime: 500
        },
        {
            phoneNumbers: { min: 101, max: 300 },
            monthly: 500,
            oneTime: 1000
        },
        {
            phoneNumbers: { min: 301, max: 500 },
            monthly: 750,
            oneTime: 1500
        },
        {
            phoneNumbers: { min: 501, max: Infinity, defaultMaxQuantity: 1000},
            monthly: 1000,
            oneTime: 2000
        }
    ]
});
