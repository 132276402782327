export const TYPE_CALL_LEAD = 1
export const TYPE_SMS_LEAD = 2
export const TYPE_CONFERENCE = 3
export const TYPE_RVM = 4
export const TYPE_MMS = 5
export const TYPE_CALL_RING_GROUP = 6
export const TYPE_SMS_RING_GROUP = 7
export const TYPE_WEBHOOK = 8
export const TYPE_AUTO_DIAL = 9 // [DEPRECATED]
export const TYPE_BIRTHDAY = 10
export const TYPE_ADD_TAG_CONTACT = 11
export const TYPE_CHANGE_CONTACT_OWNER = 12
export const TYPE_EMAIL_LEAD = 13
export const TYPE_EMAIL_RING_GROUP = 14
export const TYPE_ADD_TO_POWER_DIALER = 15
export const TYPE_PREDICTIVE_DIAL = 16 // [DEPRECATED]
export const TYPE_REMOVE_TAG_CONTACT = 17
export const TYPE_DISPOSE_CONTACT = 18
export const TYPE_DNC_CONTACT = 19
export const TYPE_REMOVE_FROM_POWER_DIALER = 23
export const TYPE_ENROLL_TO_OTHER_WORKFLOW = 24
export const TYPE_START_ALOAI_CONVERSATION = 25
export const TYPE_COMPLETE = 99
