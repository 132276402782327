var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list disposition status")
    ? _c(
        "div",
        { staticClass: "w-full" },
        [
          _vm.communication
            ? _c(
                "el-select",
                {
                  staticClass: "w-full",
                  attrs: {
                    placeholder: _vm.placeholder,
                    disabled: _vm.loading_call_disposition,
                    size: _vm.size,
                    "default-first-option": "",
                    filterable: "",
                    "data-testid": "has-permission-to-call-disposition-select",
                  },
                  on: { change: _vm.changeCallDisposition },
                  model: {
                    value: _vm.call_disposition_id,
                    callback: function ($$v) {
                      _vm.call_disposition_id = $$v
                    },
                    expression: "call_disposition_id",
                  },
                },
                _vm._l(
                  _vm.callDispositionsAlphabeticalOrder,
                  function (call_disposition) {
                    return _c(
                      "el-option",
                      {
                        key: call_disposition.id,
                        attrs: {
                          label: call_disposition.name,
                          value: call_disposition.id,
                        },
                      },
                      [
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              style: { color: call_disposition.color },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    call_disposition.is_external
                                      ? "lock"
                                      : "label"
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(_vm._s(call_disposition.name)),
                          ]),
                        ],
                      ],
                      2
                    )
                  }
                ),
                1
              )
            : _c(
                "el-select",
                {
                  staticClass: "w-full",
                  attrs: {
                    placeholder: _vm.placeholder,
                    multiple: _vm.multiple,
                    clearable: _vm.clearable,
                    "collapse-tags": !_vm.noCollapse,
                    disabled: _vm.loading_call_disposition,
                    size: _vm.size,
                    "default-first-option": "",
                    filterable: "",
                    "data-testid":
                      "has-no-permission-to-call-disposition-select",
                  },
                  on: { change: _vm.selectCallDisposition },
                  model: {
                    value: _vm.call_disposition_id,
                    callback: function ($$v) {
                      _vm.call_disposition_id = $$v
                    },
                    expression: "call_disposition_id",
                  },
                },
                _vm._l(
                  _vm.callDispositionsAlphabeticalOrder,
                  function (call_disposition) {
                    return _c(
                      "el-option",
                      {
                        key: call_disposition.id,
                        attrs: {
                          label: call_disposition.name,
                          value: call_disposition.id,
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            style: { color: call_disposition.color },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  call_disposition.is_external
                                    ? "lock"
                                    : "label"
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(call_disposition.name)),
                        ]),
                      ]
                    )
                  }
                ),
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }