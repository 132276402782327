export default Object.freeze([
    {
        id: 1,
        value: 'Partnership',
    },
    {
        id: 2,
        value: 'Sole Proprietorship',
    },
    {
        id: 3,
        value: 'Limited Liability Corporation',
    },
    {
        id: 4,
        value: 'Corporation',
    },
    {
        id: 5,
        value: 'Co-operative',
    },
    {
        id: 6,
        value: 'Non-profit Corporation',
    },
])
