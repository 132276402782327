<template>
    <el-select class="filter-select"
               v-model="source"
               multiple
               :placeholder="placeholder"
               :disabled="!sources || sources.length === 0"
               collapse-tags
               filterable
               clearable>
        <el-option v-for="source in sources"
                   :key="source.code"
                   :label="source.name"
                   :value="source.code">
            <span>{{ source.name }}</span>
        </el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        sources: {
            type: Array,
            required: true
        },

        source_type: {
            type: String,
            required: true
        },

        value: {
            required: false
        }
    },

    data() {
        return {
            source: []
        }
    },

    mounted() {
        this.initSource()
    },

    computed: {
        placeholder() {
            return `Select ${this.source_type}`
        }
    },

    methods: {
        initSource() {
            this.source = this.value
        },
    },

    watch: {
        value() {
            this.initSource()
        },

        source(val) {
            if (this.value !== undefined && this.source !== this.value) {
                this.$emit('change', val)
            }
        }
    }
}
</script>
