<template>
    <div class="card h-100 p-3"
         v-loading="loading">
        <div class="mb-3 d-flex flex-row"
             v-if="mode.selected === 'compare'">
            <el-select size="small"
                       v-model="mode.compare.first"
                       @change="fetchGraphData">
                <el-option v-for="item in this.aggregates"
                           :key="item"
                           :label="getAggregateName(item)"
                           :value="item"
                           :disabled="item === mode.compare.second">
                </el-option>
            </el-select>
            <label style="padding: 7px 20px;">
                <strong>Versus</strong>
            </label>
            <el-select size="small"
                       v-model="mode.compare.second"
                       @change="fetchGraphData">
                <el-option v-for="item in this.aggregates"
                           :key="item"
                           :label="getAggregateName(item)"
                           :value="item"
                           :disabled="item === mode.compare.first">
                </el-option>
            </el-select>
            <el-select size="small"
                       v-model="date_group_by"
                       placeholder="Group by"
                       class="ml-auto"
                       style="width: 8rem"
                       @change="changeDateGroupBy">
                <el-option v-for="option in date_group_by_options"
                           :key="option.value"
                           :label="option.label"
                           :value="option.value">
                </el-option>
            </el-select>
        </div>
        <div v-if="no_data" class="text-center py-5">
            <img-empty class="w-25 mx-auto"></img-empty>
            <p class="lead">We cannot render the chart from the given date range.</p>
        </div>
        <highcharts :options="options"
                    v-if="!no_data"
                    ref="chart">
        </highcharts>
    </div>
</template>

<script>
import DashboardFilter from "../../../filters/dashboard-filter";
import ImgEmpty from "../../misc/img-empty";

export default {
    name: "outbound-graph-widget",
    components: {ImgEmpty},
    data() {
        return {
            loading: true,
            no_data: false,
            chartKey: 0,
            date_group_by: '',
            date_group_by_options: [
                {
                    label: 'Month',
                    value: 'month'
                },
                {
                    label: 'Week',
                    value: 'week'
                },
                {
                    label: 'Day',
                    value: 'day'
                }
            ],
            mode: {
                selected: 'compare',
                compare: {
                    first: 1,
                    second: 2
                },
                compare_colors: {
                    first: '#d1f3de',
                    second: '#00bf4a'
                }
            },
            aggregates: [
                1, // OUTBOUND_CALLS
                2, // OUTBOUND_CONNECTED_CALLS
                // 3, // OUTBOUND_AVERAGE_CALL_TALK_TIME
                4, // OUTBOUND_CALLS_ABOVE_FIVE_MINUTES
                5, // OUTBOUND_CALLS_ABOVE_TWO_MINUTES
                6, // OUTBOUND_CALLS_BELOW_THIRTY_SECONDS
                7, // OUTBOUND_COLD_TRANSFERRED_CALLS
                8, // OUTBOUND_WARM_TRANSFERRED_CALLS
                // 9, // OUTBOUND_CALLS_CONNECTION_RATE
                // 10, // OUTBOUND_UNCONNECTED_CALLS
                35, // OUTBOUND_UNTAGGED
                37, // OUTBOUND_FIRST_TIME
            ],
            aggregate_graph_settings: [
                {
                    id: 1,
                    aggregate_key: 'outbound_calls',
                    name: 'All',
                    color: '#d1f3de', // light green
                    fillOpacity: 0.2,
                    marker: {
                        fillColor: '#d1f3de', // light green
                        lineWidth: 4,
                        lineColor: '#d1f3de', // light green
                        symbol: 'circle',
                        radius: 4
                    }
                },
                {
                    id: 2,
                    aggregate_key: 'outbound_connected_calls',
                    name: 'Connected ',
                    color: '#2563EB', // blue
                    fillOpacity: 0.2,
                    marker: {
                        fillColor: '#2563EB',
                        lineWidth: 4,
                        lineColor: '#2563EB',
                        symbol: 'circle',
                        radius: 4
                    }
                },
                // {
                //     id: 3,
                //     aggregate_key: 'outbound_average_call_talk_time',
                //     name: 'Average call talk time',
                //     color: '#DC2626', // red
                //     fillOpacity: 0.2,
                //     marker: {
                //         fillColor: '#00bf4a',
                //         lineWidth: 4,
                //         lineColor: '#d1f3de',
                //         symbol: 'circle',
                //         radius: 4
                //     }
                // },
                {
                    id: 4,
                    aggregate_key: 'outbound_calls_above_five_minutes',
                    name: 'Above 5 minutes', // orange
                    color: '#D97706',
                    fillOpacity: 0.2,
                    marker: {
                        fillColor: '#D97706',
                        lineWidth: 4,
                        lineColor: '#D97706',
                        symbol: 'circle',
                        radius: 4
                    }
                },
                {
                    id: 5,
                    aggregate_key: 'outbound_calls_above_two_minutes',
                    name: 'Above 2 minutes',
                    color: '#DB2777', // pink
                    fillOpacity: 0.2,
                    marker: {
                        fillColor: '#DB2777',
                        lineWidth: 4,
                        lineColor: '#DB2777',
                        symbol: 'circle',
                        radius: 4
                    }
                },
                {
                    id: 6,
                    aggregate_key: 'outbound_calls_thirty_seconds_and_below',
                    name: '30 seconds and below',
                    color: '#4B5563', // gray
                    fillOpacity: 0.2,
                    marker: {
                        fillColor: '#4B5563',
                        lineWidth: 4,
                        lineColor: '#4B5563',
                        symbol: 'circle',
                        radius: 4
                    }
                },
                {
                    id: 7,
                    aggregate_key: 'outbound_calls_cold_transferred',
                    name: 'Cold Transfers',
                    color: '#D1D5DB', // light gray
                    fillOpacity: 0.2,
                    marker: {
                        fillColor: '#D1D5DB',
                        lineWidth: 4,
                        lineColor: '#D1D5DB',
                        symbol: 'circle',
                        radius: 4
                    }
                },
                {
                    id: 8,
                    aggregate_key: 'outbound_calls_warm_transferred',
                    name: 'Warm Transfers',
                    color: '#F87171', // light red
                    fillOpacity: 0.2,
                    marker: {
                        fillColor: '#F87171',
                        lineWidth: 4,
                        lineColor: '#F87171',
                        symbol: 'circle',
                        radius: 4
                    }
                },
                // {
                //     id: 9,
                //     aggregate_key: 'inbound_average_call_talk_time',
                //     name: 'Average Talk Time',
                //     color: '#A3E635', // Lime
                //     fillOpacity: 0.2,
                //     marker: {
                //         fillColor: '#A3E635',
                //         lineWidth: 4,
                //         lineColor: '#A3E635',
                //         symbol: 'circle',
                //         radius: 4
                //     }
                // },
                // {
                //     id: 10,
                //     aggregate_key: 'inbound_connected_calls',
                //     name: 'Connected',
                //     color: '#00bf4a', // Green
                //     fillOpacity: 0.2,
                //     marker: {
                //         fillColor: '#00bf4a',
                //         lineWidth: 4,
                //         lineColor: '#d1f3de',
                //         symbol: 'circle',
                //         radius: 4
                //     }
                // },
            ],
            labels: [],
            options: {
                exporting: {
                    enabled: false
                },

                chart: {
                    type: 'areaspline',
                },

                title: {
                    text: null
                },

                yAxis: {
                    title: {
                        text: null
                    },
                    gridLineColor: 'transparent',
                    lineColor: 'transparent',
                    gridLineWidth: 0,
                },

                xAxis: {
                    accessibility: {
                        rangeDescription: 'Range: 2010 to 2017'
                    },
                    gridLineWidth: 1,
                    gridLineColor: '#f6f6f6',
                    categories: this.labels,
                    min: 0.5,
                    tickInterval: 2,
                    maxPadding: 0,
                    endOnTick: false,
                    startOnTick: false,
                    labels: {
                        enabled: true
                    }
                },

                legend: {
                    enabled: true
                },

                plotOptions: {
                    series: {
                        pointStart: 0
                    },
                    areaspline: {
                        fillOpacity: 0.5
                    }
                },

                tooltip: {
                    shared: true
                },

                credits: {
                    enabled: false
                },

                series: [],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                }
            }
        }
    },

    computed: {
        graphFilters() {
            return DashboardFilter.graphFilters()
        },

        chartData() {
            return this.options
        },

        selectedAggregates() {
            let aggregates = []

            if (this.mode.selected === 'compare') {
                aggregates.push(this.mode.compare.first)
                aggregates.push(this.mode.compare.second)
            }

            if (this.mode.selected === 'general') {
                aggregates = this.mode.general
            }

            return aggregates
        },

        availableAggregates(dont_include) {
            let aggregates = [];

            for (agg of this.aggregate_graph_settings) {
                if (agg.id != dont_include) {
                    let aggregate = {};
                    aggregate.id = agg.id
                    aggregate.name = agg.name
                    aggregates.push(aggregate)
                }
            }

            return aggregates
        }
    },

    mounted() {
        this.fetchGraphData()
    },

    methods: {
        changeMode(mode) {
            this.mode.selected = mode
            this.fetchGraphData()
        },

        getAggregateName(aggregate) {
            let name = 'Not found';

            for (let agg of this.aggregate_graph_settings) {
                if (agg.id == aggregate) {
                    name = agg.name
                }
            }

            return name
        },

        fetchGraphData() {
            this.loading = true
            this.options.xAxis.categories = []
            this.options.series = []

            axios.get(`/api/v1/graph/communication/outbound/calls`, {
                params: {
                    aggregates: this.selectedAggregates,
                    filters: DashboardFilter.get()
                }
            }).then(res => {
                let data = res.data

                if (data) {
                    this.labels = data.label
                    this.options.xAxis.categories = this.labels
                    this.options.series = this.fetchAggregateSettings(data)

                    if (this.options.series.length > 0 && this.mode.selected === 'compare') {
                        // Set 2 color only
                        this.options.series[0].color = this.mode.compare_colors.first
                        this.options.series[0].marker.fillColor = this.mode.compare_colors.first
                        this.options.series[0].marker.lineColor = this.mode.compare_colors.first
                        this.options.series[1].color = this.mode.compare_colors.second
                        this.options.series[1].marker.fillColor = this.mode.compare_colors.second
                        this.options.series[1].marker.lineColor = this.mode.compare_colors.second

                        this.no_data = false
                    } else {
                        this.no_data = true
                    }
                }

                this.loading = false
            })
        },

        fetchAggregateSettings(data) {
            let settings = [];
            for (let aggregate of this.aggregate_graph_settings) {
                // console.log(aggregate, data[aggregate.aggregate_key], data, aggregate.aggregate_key)
                if (data[aggregate.aggregate_key]) {
                    aggregate.data = data[aggregate.aggregate_key];
                    settings.push(aggregate)
                }
            }
            return settings
        },

        changeDateGroupBy() {
            DashboardFilter.setDateGroupBy(this.date_group_by)
        },
    },

    watch: {
        graphFilters() {
            this.fetchGraphData()
        }
    }
}
</script>
