var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "dialog-kyc",
        visible: _vm.show,
        "before-close": _vm.handleClose,
        "modal-append-to-body": true,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c("div", { staticClass: "text-center" }, [
        _c("h6", [
          _vm._v("\n            Welcome aboard! 🎉\n            "),
          _c("br"),
          _vm._v(
            "\n            We're thrilled to have you join us! 🌟\n        "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            To unlock your full trial and explore all the features, please submit your business information. Until then, you're set to call only your own numbers. 📞🔒\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-kyc-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "text", round: "" },
              on: { click: _vm.changeShowedKycDialog },
            },
            [_vm._v("\n            Remind me later\n        ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.openKycBusinessRegistration },
            },
            [_vm._v("\n            Submit info\n        ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }