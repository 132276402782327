export default Object.freeze([
    {
        label: 'Alberta',
        value: 'AB'
    },
    {
        label: 'British Columbia',
        value: 'BC'
    },
    {
        label: 'Manitoba',
        value: 'MB'
    },
    {
        label: 'New Brunswick',
        value: 'NB'
    },
    {
        label: 'Newfoundland and Labrador',
        value: 'NL'
    },
    {
        "label": "Northwest Territories",
        "value": "NT"
    },
    {
        label: 'Nova Scotia',
        value: 'NS'
    },
    {
        "label": "Nunavut",
        "value": "NU"
    },
    {
        label: 'Ontario',
        value: 'ON'
    },
    {
        label: 'Prince Edward Island',
        value: 'PE'
    },
    {
        label: 'Quebec',
        value: 'QC'
    },
    {
        label: 'Saskatchewan',
        value: 'SK'
    },
    {
        "label": "Yukon",
        "value": "YT"
    }
])
