var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-md-4 d-sm-none d-md-block box-card help-card p-3" },
    [
      _vm.report_type_index === 0 && _vm.category == "lines"
        ? _c("div", [_vm._m(0), _vm._v(" "), _vm._m(1)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 1 && _vm.category == "lines"
        ? _c("div", [_vm._m(2)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 2 && _vm.category == "lines"
        ? _c("div", [_vm._m(3), _vm._v(" "), _vm._m(4)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 3 && _vm.category == "lines"
        ? _c("div", [_vm._m(5)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 4 && _vm.category == "lines"
        ? _c("div", [_vm._m(6)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 5 && _vm.category == "lines"
        ? _c("div", [_vm._m(7), _vm._v(" "), _vm._m(8)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 0 && _vm.category == "ring_groups"
        ? _c("div", [_vm._m(9), _vm._v(" "), _vm._m(10)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 1 && _vm.category == "ring_groups"
        ? _c("div", [_vm._m(11)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 2 && _vm.category == "ring_groups"
        ? _c("div", [_vm._m(12), _vm._v(" "), _vm._m(13)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 3 && _vm.category == "ring_groups"
        ? _c("div", [_vm._m(14)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 4 && _vm.category == "ring_groups"
        ? _c("div", [_vm._m(15)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 0 && _vm.category == "callers"
        ? _c("div", [_vm._m(16), _vm._v(" "), _vm._m(17)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 1 && _vm.category == "callers"
        ? _c("div", [_vm._m(18)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 2 && _vm.category == "callers"
        ? _c("div", [_vm._m(19), _vm._v(" "), _vm._m(20)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 3 && _vm.category == "callers"
        ? _c("div", [_vm._m(21)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 4 && _vm.category == "callers"
        ? _c("div", [_vm._m(22), _vm._v(" "), _vm._m(23)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 5 && _vm.category == "callers"
        ? _c("div", [_vm._m(24)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 6 && _vm.category == "callers"
        ? _c("div", [_vm._m(25)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 7 && _vm.category == "callers"
        ? _c("div", [_vm._m(26)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 8 && _vm.category == "callers"
        ? _c("div", [_vm._m(27), _vm._v(" "), _vm._m(28)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 0 && _vm.category == "users"
        ? _c("div", [_vm._m(29), _vm._v(" "), _vm._m(30)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 1 && _vm.category == "users"
        ? _c("div", [_vm._m(31)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 2 && _vm.category == "users"
        ? _c("div", [_vm._m(32), _vm._v(" "), _vm._m(33)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 3 && _vm.category == "users"
        ? _c("div", [_vm._m(34)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 4 && _vm.category == "users"
        ? _c("div", [_vm._m(35)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 5 && _vm.category == "users"
        ? _c("div", [_vm._m(36), _vm._v(" "), _vm._m(37)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 0 && _vm.category == "performance"
        ? _c("div", [_vm._m(38)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 1 && _vm.category == "performance"
        ? _c("div", [_vm._m(39)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 2 && _vm.category == "performance"
        ? _c("div", [_vm._m(40)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 3 && _vm.category == "performance"
        ? _c("div", [_vm._m(41)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 4 && _vm.category == "performance"
        ? _c("div", [_vm._m(42)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 0 && _vm.category == "leads"
        ? _c("div", [_vm._m(43)])
        : _vm._e(),
      _vm._v(" "),
      _vm.report_type_index === 1 && _vm.category == "leads"
        ? _c("div", [_vm._m(44)])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Overall Line Traffic")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                You're viewing a comparison of all calls and messages, across your lines. You may\n                change the time frame of this\n                report using the date selectors above.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          '\n                Clicking on the "slice of the pie"\n                will drill down into that line and show you\n                traffic info.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(
          " You can determine the total inbound and outbound calls & texts by\n                clicking on the area labels.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Lines by Tags")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          '\n                Sometimes you want to see the performance of a certain tag across each of your\n                lines. This is really useful\n                when you\'re only interested in "converted"\n                calls or "qualified"\n                calls. This report\n                allows you\n                to show & hide tags and tune the view.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Disposition by Lines")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                Although every business strives for a 100% answer rate on phone calls, reaching that\n                metric takes time.\n                This report shows how your calls have been answered (or missed) across your lines.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(
          " A call is considered abandoned when caller hangs up before reaching\n                your agent. Missed calls\n                happen when they hangup while waiting.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Call Talk Times")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                This report shows how long your calls typically take across 5-minute bins.\n                For a lot of businesses, having longer calls means more conversions / sales.\n                "
        ),
        _c("br"),
        _vm._v(
          "\n                For support centers, longer calls usually means they can improve their rep performance.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Lines by Average Talk Time")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Numbers Map")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                If you have multiple locations in the US, and have assigned each location a\n                unique local phone number, this report shows you the individual state / region\n                performance of your numbers.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(
          " for city-level data, or Canadian provinces, please contact us.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Overall Ring Group Traffic")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          '\n                You\'re viewing a comparison of all calls and messages, across your ring groups.\n                You may change the time frame of this report using the date selectors above.\n                Clicking on the "slice of the pie" will drill down into that line and show you traffic info.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(
          " You can determine the total inbound and outbound calls & texts by\n                clicking on the area labels.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Ring Groups by Tags")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          '\n                Sometimes you want to see the performance of a certain tag across each of your ring groups.\n                This is really useful when you\'re only interested in "converted" calls or "qualified" calls.\n                This report allows you to show & hide tags and tune the view.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Disposition by Ring Groups")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                Although every business strives for a 100% answer rate on phone calls, reaching that\n                metric takes time. This report shows how your calls have been answered (or missed) across\n                your ring groups.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(
          " A call is considered abandoned when caller hangs up before reaching\n                your agent. Missed calls\n                happen when they hangup while waiting.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Call Talk Times")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                This report shows how long your calls typically take across 5-minute bins.\n                For a lot of businesses, having longer calls means more conversions / sales.\n                For support centers, longer calls usually means they can improve their rep performance.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Ring Groups by Average Talk Time")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [_vm._v("\n                -\n            ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("First Time Conversations")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                The chart shows the total number of calls & texts from new numbers per line.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          '\n                Clicking on the "slice of the pie"\n                will drill down into that line and show you\n                traffic info.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(
          " You can determine the total inbound and outbound calls & texts by\n                clicking on the area labels.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Tags by Lines")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                Tags are powerful tools to categorize almost everything. When you tag your calls, they\n                will\n                show up here.\n                Try clicking on your line names to show/hide them.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Call Status by Lines")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          '\n                Be it for back office intelligence or selling leads to your partners, You may want to\n                see\n                how your calls are "ending"\n                (or being disposed, in telco talk). This report shows an\n                overview\n                of call disposition broken down by lines.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(
          ' Hovering over each "stack"\n                shows you the exact number of calls &\n                texts.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Tags by Users")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                Your user performance is broken down by your tags in this report. You can see\n                how your users (rep / call buyer) are performing across your tags.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Call Results by Users")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          '\n                Be it for back office intelligence or selling leads to your partners, You may want to\n                see\n                how your calls are "ending"\n                (or being disposed, in telco talk). This report shows an\n                overview\n                of call disposition broken down by users.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(
          ' Hovering over each "stack"\n                shows you the exact number of calls &\n                texts.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Line Call Talk Times")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                This report shows how long your calls typically take across 5-minute bins.\n                For a lot of businesses, having longer calls means more conversions / sales.\n                "
        ),
        _c("br"),
        _vm._v(
          "\n                For support centers, longer calls usually means they can improve their rep performance.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("User Call Talk Times")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                This report shows how long your calls typically take across 5-minute bins.\n                For a lot of businesses, having longer calls means more conversions / sales.\n                "
        ),
        _c("br"),
        _vm._v(
          "\n                For support centers, longer calls usually means they can improve their rep performance.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Time of Day Call Traffic")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                The following graph shows what time of day you are receiving most of your call traffic.\n                This report is exceptionally useful to businesses that experience a surge in calls\n                during certain hours of the day.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Caller Number Map")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                This report shows you where you are receiving more calls based on caller phone number's\n                area code\n                and our other data sources (called CNAM).\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(
          " If you need city-level data or looking to capture names and zipcodes,\n                please contact us.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Overall User Traffic")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                You're viewing a comparison of all calls and messages by users, across your\n                lines. You may change the time frame of this\n                report using the date selectors above.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          '\n                Clicking on the "slice of the pie"\n                will drill down into that user and show you\n                traffic info.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(
          " You can determine the total inbound and outbound calls & texts by\n                clicking on the area labels.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Tags by Users")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                Your user performance is broken down by your tags in this report. You can see\n                how your users (rep / call buyer) are performing across your tags.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Call Results by Users")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          '\n                Be it for back office intelligence or selling leads to your partners, You may want to\n                see\n                how your calls are "ending"\n                (or being disposed, in telco talk). This report shows an\n                overview\n                of call disposition broken down by users.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(
          ' Hovering over each "stack"\n                shows you the exact number of calls &\n                texts.\n            '
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Call Talk Times")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                This report shows how long your calls typically take across 5-minute bins.\n                For a lot of businesses, having longer calls means more conversions / sales.\n                "
        ),
        _c("br"),
        _vm._v(
          "\n                For support centers, longer calls usually means they can improve their rep performance.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Users by Average Talk Time")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("User Map")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                If you are routing your calls to different offices / buyers across the US, and have\n                assigned\n                each of them a unique local phone number, this report shows you how each location is\n                receiving\n                your call traffic.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item card-bottom" }, [
      _c("small", [
        _c("b", [_vm._v("Pro tip:")]),
        _vm._v(" for geo-routing assistance, please contact us."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Agent Status")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                Users are broken down by their status within this period of time. Times are reported in\n                HH:mm:ss format.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [_vm._v("Note that:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [_vm._v("- All means all users aggregated report")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Users by Performance")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                Using the data above, Users are broken down by their performance over all your\n                calls within this period of time.\n                "
        ),
        _c("br"),
        _vm._v("\n                Note that: "),
        _c("br"),
        _vm._v("\n                - All means all calls attempted "),
        _c("br"),
        _vm._v(
          "\n                - Answered is every call that is not missed "
        ),
        _c("br"),
        _vm._v(
          "\n                - Billable are all calls that are qualified, per user settings "
        ),
        _c("br"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Users by Average Talk Time")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Users by Average Wait Time")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Lines by Average Wait Time")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("Contact Phone Numbers")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                This report shows the total number of contact phone numbers created per day in the selected date\n                range.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text item" }, [
      _c("strong", [_vm._v("New Contacts vs DNC Contacts")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n                This report shows the total number of new contacts vs DNC contacts per day in the\n                selected date range.\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }