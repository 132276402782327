var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-testid": "campaign-details-wrapper" } },
    [
      !_vm.campaign.is_fax
        ? [
            _c(
              "p",
              {
                staticClass: "form-head-message text-left",
                attrs: { "data-testid": "tracking-type-template" },
              },
              [_vm._v("\n            Tracking Type:\n        ")]
            ),
            _vm._v(" "),
            _c("p", {
              staticClass: "text-left",
              domProps: { textContent: _vm._s(_vm.campaignTrackingType) },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "form-head-message text-left",
          attrs: { "data-testid": "your-numbers" },
        },
        [_vm._v("\n       Your numbers:\n    ")]
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "text-left" },
        _vm._l(_vm.campaign.incoming_numbers, function (ins, index) {
          return _c("span", { key: index, staticClass: "ins_item" }, [
            _c("span", [_vm._v(_vm._s(_vm._f("fixPhone")(ins.phone_number)))]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.hasPermissionTo("list ring group") && !_vm.campaign.is_fax
        ? [
            _c(
              "p",
              {
                staticClass: "form-head-message text-left mt-3",
                attrs: { "data-testid": "routing-call-to-template" },
              },
              [_vm._v("\n           We are routing calls to:\n        ")]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "text-left" },
              [
                _vm.campaign.ring_group_id &&
                _vm.getRingGroup(_vm.campaign.ring_group_id)
                  ? [
                      _c(
                        "span",
                        { staticClass: "dn_item" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Ring Group Dialog",
                                  params: {
                                    ring_group_id: _vm.campaign.ring_group_id,
                                  },
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-dark-greenish _400" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("fixName")(
                                          _vm.getRingGroup(
                                            _vm.campaign.ring_group_id
                                          ).name
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermissionTo(["list user", "list ring group"]) &&
      !_vm.campaign.is_fax
        ? [
            _c(
              "p",
              {
                staticClass: "form-head-message text-left mt-3",
                attrs: { "data-testid": "users-template" },
              },
              [_vm._v("\n           Users:\n        ")]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "text-left" },
              [
                _vm.campaign.ring_group_id &&
                _vm.getRingGroup(_vm.campaign.ring_group_id)
                  ? _vm._l(
                      _vm.getRingGroup(_vm.campaign.ring_group_id).user_ids,
                      function (user_id) {
                        return _vm.getUser(user_id)
                          ? _c(
                              "span",
                              { key: user_id, staticClass: "dn_item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "User Dialog",
                                        params: { user_id: user_id },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text-dark-greenish _400",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.getUserName(
                                                _vm.getUser(user_id)
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e()
                      }
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          staticClass: "text-left mt-4",
          staticStyle: { height: "350px" },
          attrs: {
            "tab-position": "left",
            "data-testid": "review-number-tabs",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: "Review Number",
                "data-testid": "review-number-pane",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "row p-2",
                  attrs: { "data-testid": "campaign-name" },
                },
                [
                  _c("div", { staticClass: "col-4" }, [
                    _c("strong", [_vm._v("Name:")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-8" }, [
                    _c("span", [_vm._v(_vm._s(_vm.campaign.name))]),
                  ]),
                ]
              ),
              _vm._v(" "),
              !_vm.campaign.is_fax
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "row p-2",
                        attrs: { "data-testid": "campaign-dial-mode" },
                      },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _c("strong", [_vm._v("Dial Mode:")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("humanReadableDialMode")(
                                  _vm.campaign.dial_mode
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "row p-2",
                        attrs: { "data-testid": "campaign-whispering" },
                      },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _c("strong", [_vm._v("Whispering:")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("humanReadableBool")(
                                  _vm.campaign.should_whisper
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "row p-2",
                        attrs: { "data-testid": "campaign-whisper-message" },
                      },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _c("strong", [_vm._v("Whisper Message:")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("span", [
                            _vm._v(
                              '"' + _vm._s(_vm.campaign.whisper_tts) + '"'
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "row p-2",
                        attrs: { "data-testid": "campaign-greeting" },
                      },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _c("strong", [_vm._v("Greeting:")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("humanReadableBool")(
                                  _vm.campaign.should_greet
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "row p-2",
                        attrs: { "data-testid": "campaign-greeting-message" },
                      },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _c("strong", [_vm._v("Greeting Message:")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("span", [
                            _vm._v(
                              '"' + _vm._s(_vm.campaign.greeting_tts) + '"'
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "row p-2",
                        attrs: { "data-testid": "campaign-recording" },
                      },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _c("strong", [_vm._v("Recording:")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("humanReadableBool")(
                                  _vm.campaign.should_record
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "row p-2",
                        attrs: { "data-testid": "campaign-recording-message" },
                      },
                      [
                        _c("div", { staticClass: "col-4" }, [
                          _c("strong", [_vm._v("Recording Message:")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-8" }, [
                          _c("span", [
                            _vm._v('"' + _vm._s(_vm.campaign.record_tts) + '"'),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: "Suggested Next Steps",
                "data-testid": "suggested-nex-steps-pane",
              },
            },
            [
              _vm.campaign.is_fax
                ? _c("div", { staticClass: "row p-2" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("span", [
                        _vm._v("Send a fax to: "),
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm._f("fixPhone")(_vm.campaign.incoming_number)
                            )
                          ),
                        ]),
                        _vm._v(
                          " and make sure you receive the fax in your dashboard."
                        ),
                      ]),
                    ]),
                  ])
                : _c("div", { staticClass: "row p-2" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("span", [
                        _vm._v(
                          "Give yourself a call :) Call the tracking number: "
                        ),
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm._f("fixPhone")(_vm.campaign.incoming_number)
                            )
                          ),
                        ]),
                        _vm._v(" and make sure it rings you on the other end."),
                      ]),
                    ]),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "row p-2" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("span", [_vm._v("Checkout the Activity tab:")]),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Line Activity",
                            params: { campaign_id: _vm.campaign.id },
                          },
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "text-dark-greenish",
                            attrs: { type: "text" },
                          },
                          [
                            _vm._v(
                              "\n                            Go to Activity Dashboard\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.campaign.is_fax
                      ? _c("span", [
                          _vm._v(
                            ". As soon as your calls rake in, we will fill this page up."
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer clear",
          attrs: { slot: "footer", "data-testid": "campaign-details-footer" },
          slot: "footer",
        },
        [
          _vm.hasPermissionTo("update campaign")
            ? _c(
                "router-link",
                {
                  staticClass: "pull-right",
                  attrs: {
                    to: {
                      name: "Line Settings",
                      params: { campaign_id: _vm.campaign.id },
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        "data-testid": "edit-configuration-btn",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                Edit Configuration\n            "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$route.name != "Line Activity" ||
          (_vm.$route.name == "Line Activity" &&
            _vm.$route.params.campaign_id &&
            _vm.$route.params.campaign_id != _vm.campaign.id)
            ? _c(
                "router-link",
                {
                  staticClass: "pull-right mr-2",
                  attrs: {
                    to: {
                      name: "Line Activity",
                      params: { campaign_id: _vm.campaign.id },
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { "data-testid": "go-to-activity-dashboard-btn" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            Go to Activity Dashboard\n            "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }