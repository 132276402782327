var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.checkPermission
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              staticClass: "dialog-padding auto",
              attrs: {
                title: _vm.dialogTitle,
                width: _vm.isLargeEnough ? "500px" : "100%",
                visible: _vm.hide_dialog,
                "before-close": _vm.beforeCloseModal,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.hide_dialog = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "script_form",
                  staticClass: "p-0",
                  attrs: {
                    "label-position": "top",
                    rules: _vm.rules_script,
                    model: _vm.script,
                    submit: _vm.doOperation,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "title", label: "Title" } },
                    [
                      _c("el-input", {
                        on: {
                          input: function ($event) {
                            return _vm.preValidateForm("script_form")
                          },
                        },
                        model: {
                          value: _vm.script.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.script, "title", $$v)
                          },
                          expression: "script.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "text", label: "Text Body" } },
                    [
                      _c("vue-editor", {
                        attrs: { editorToolbar: _vm.customToolbar },
                        on: {
                          "text-change": function ($event) {
                            return _vm.preValidateForm("script_form")
                          },
                        },
                        model: {
                          value: _vm.script.text,
                          callback: function ($$v) {
                            _vm.$set(_vm.script, "text", $$v)
                          },
                          expression: "script.text",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("variable-dialog", {
                      attrs: { in_input_group: false, has_agent: true },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-right ml-2",
                        attrs: {
                          type: "success",
                          disabled: _vm.loading_btn || !_vm.validated,
                        },
                        on: { click: _vm.doOperation },
                      },
                      [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading_btn,
                                expression: "loading_btn",
                              },
                            ],
                            staticClass: "material-icons loader",
                          },
                          [_vm._v("")]
                        ),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loading_btn,
                                expression: "!loading_btn",
                              },
                            ],
                            staticClass: "material-icons",
                          },
                          [
                            _vm._v(
                              "\n                        save\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("Save")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("trigger", function () {
            return [
              _c(
                "button",
                {
                  class: _vm.triggerButtonClass,
                  attrs: { slot: "reference" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.hide_dialog = true
                    },
                  },
                  slot: "reference",
                },
                [
                  _c("i", { class: _vm.triggerButtonIcon }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.triggerButtonTitle) +
                      "\n        "
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }