var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-2 bg-white" },
    [
      _c("h5", { staticClass: "text-dark mb-1 vertical-center mt-1" }, [
        _vm._v("Users by Status"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _vm._v("\n        Total Users:\n        "),
        _vm.agents
          ? _c(
              "small",
              { staticClass: "ml-1 label badge greenish align-self-center" },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.agents.length) + "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex my-2" },
        [
          _c("el-input", {
            ref: "agentStatusTableSearch",
            staticClass: "width-500",
            attrs: {
              placeholder: "Press ENTER to search agents...",
              "suffix-icon": "el-icon-search",
              clearable: "",
              autofocus: "",
            },
            on: { change: _vm.searchAndFilterAgents },
            model: {
              value: _vm.search_text,
              callback: function ($$v) {
                _vm.search_text = $$v
              },
              expression: "search_text",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "ml-2 width-150",
              attrs: {
                placeholder: "Select",
                "popper-class": "agent-status-filter",
              },
              on: { change: _vm.searchAndFilterAgents },
              model: {
                value: _vm.agent_status_filter,
                callback: function ($$v) {
                  _vm.agent_status_filter = $$v
                },
                expression: "agent_status_filter",
              },
            },
            _vm._l(_vm.statusFilterOptions, function (group) {
              return _c(
                "el-option-group",
                { key: group.label, attrs: { label: group.label } },
                _vm._l(group.options, function (item, index) {
                  return _c(
                    "el-option",
                    {
                      key: index,
                      attrs: { label: item.label, value: item.value },
                    },
                    [
                      _c("i", {
                        staticClass: "b-white bottom status-badge",
                        class: _vm.getAgentStatusClass(item.value),
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                    ]
                  )
                }),
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("ring-group-dropdown", {
            staticClass: "ml-2 w-md",
            attrs: { ring_group_id: _vm.ringGroupIdFilter, clearable: "" },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn greenish pointer ml-2",
              staticStyle: { height: "40px" },
              attrs: { disabled: _vm.isLoading },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.refreshAgents.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "i",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLoading,
                      expression: "isLoading",
                    },
                  ],
                  staticClass: "material-icons loader",
                },
                [_vm._v("\n                \n            ")]
              ),
              _vm._v(" "),
              _c(
                "i",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                  staticClass: "material-icons",
                },
                [_vm._v("refresh")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass:
                "align-self-center text-xxs text-greyish font-weight-normal ml-2",
            },
            [
              _vm._v(
                "\n            Ring Group Filter applies to entire page\n        "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading_agents,
              expression: "loading_agents",
            },
          ],
          ref: "agents_table",
          staticClass:
            "w-full mt-3 table-condensed agents-condensed-summary-report-table",
          attrs: {
            "empty-text": _vm.users_table_empty_text,
            "row-key": "id",
            data: _vm.agents,
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "100", sortable: "" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Agents",
              prop: "full_name",
              width: "220",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `/users?uid=${scope.row.id}`,
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(scope.row.full_name) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Status",
              prop: "agent_status",
              width: "150",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasRole("Company Admin")
                      ? [
                          _c(
                            "el-popover",
                            {
                              ref: `agent_status-${scope.row.id}`,
                              attrs: { placement: "right", width: "200" },
                            },
                            [
                              _c("agent-status-dropdown", {
                                attrs: {
                                  is_wallboard: "",
                                  user_id: scope.row.id,
                                  forced_disabled_ui: false,
                                  index: scope.row.id,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-block width-200 text-white font-weight-600 agent-status-button",
                                  class: _vm.$options.filters.agentStatusClass(
                                    scope.row.agent_status
                                  ),
                                  attrs: {
                                    slot: "reference",
                                    disabled: scope.row.disable_change_status,
                                  },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.getAgentStatusLabel(
                                          scope.row.agent_status
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-block text-white width-200 agent-status-button",
                              class: [
                                _vm.$options.filters.agentStatusClass(
                                  scope.row.agent_status
                                ),
                              ],
                              attrs: { disabled: true },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.getAgentStatusLabel(
                                      scope.row.agent_status
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Status Duration",
              prop: "last_agent_status_change",
              width: "230",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.last_agent_status_change
                      ? _c("alo-timeago", {
                          attrs: {
                            from_time: _vm.convertDateTime(
                              scope.row.last_agent_status_change
                            ),
                          },
                        })
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Ring Groups", prop: "ring_group_ids" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.getUserRingGroups(scope.row).length >= 1
                      ? _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _vm._l(
                              _vm.getUserRingGroups(scope.row, true),
                              function (ring_group_id) {
                                return _c(
                                  "div",
                                  {
                                    key: ring_group_id + "-" + scope.row.id,
                                    staticClass: "align-self-center",
                                  },
                                  [
                                    _vm.getRingGroup(ring_group_id) &&
                                    _vm.getRingGroup(ring_group_id).name
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fixName")(
                                                _vm.getRingGroup(ring_group_id)
                                                  .name
                                              )
                                            )
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v("Deleted Ring Group"),
                                        ]),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _vm.getUserRingGroups(scope.row).length > 1
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      width: "200",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.getUserRingGroups(scope.row),
                                      function (ring_group_id) {
                                        return _c(
                                          "div",
                                          {
                                            key:
                                              ring_group_id +
                                              "-" +
                                              scope.row.id,
                                          },
                                          [
                                            _vm.getRingGroup(ring_group_id) &&
                                            _vm.getRingGroup(ring_group_id).name
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("fixName")(
                                                        _vm.getRingGroup(
                                                          ring_group_id
                                                        ).name
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v("Deleted Ring Group"),
                                                ]),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "ml-2 pointer text-success",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            + " +
                                            _vm._s(
                                              _vm.getUserRingGroups(scope.row)
                                                .length - 1
                                            ) +
                                            " more\n                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _c("div", [
                          _vm._v("\n                    --\n                "),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Last Login", prop: "last_login", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.last_login
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("fixFullDateUTCRelative")(
                                  scope.row.last_login
                                )
                              ) +
                              "\n                "
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Last Updated",
              prop: "last_agent_status_change",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.last_agent_status_change
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("fixFullDateUTCRelative")(
                                  scope.row.last_agent_status_change
                                )
                              ) +
                              "\n                "
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }