<template>
    <div class="function-card mt-3">
        <div class="d-flex mb-3">
            <p class="form-label no-wrap mt-2"><strong>Function name (*)</strong></p>
            <el-input
                class="mx-2"
                v-model="functionItem.name"
                placeholder="Function name"
                type="text"/>
            <el-button
                :disabled="functionsCount === 1"
                icon="el-icon-delete"
                class="icon-rounded"
                circle
                plain
                size="small"
                type="danger"
                :title="functionsCount === 1 ? 'You cannot delete the last function' : 'Delete function'"
                @click="confirmDelete">
            </el-button>
        </div>
        <el-form-item>
            <div class="form-label">
                <h5 class="text-dark">Description (*)</h5>
            </div>
            <el-input
                v-model="functionItem.description"
                type="textarea"
                :rows="2">
            </el-input>
        </el-form-item>
        <function-property
            v-on:add-property="onAddProperty"
            :properties="functionItem.parameters.properties">
        </function-property>
    </div>
</template>
<script>
    import FunctionProperty from './property.vue'
    import { MessageBox } from 'element-ui';
    export default {
        name: 'AloAiFormFunction',
        props: {
            functionItem: {
                required: true,
            },
            index: {
                required: true,
            },
            functionsCount: {
                required: true,
            }
        },
        components: {
            FunctionProperty
        },
        methods: {
            onAddProperty(property) {
                this.$set(this.functionItem.parameters.properties, property.key,{
                    type: property.type,
                    description: property.description,
                })
            },
            confirmDelete() {
                MessageBox.confirm('Are you sure you want to delete this function?', 'Warning', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.deleteProperty();
                }).catch(() => {
                    // do nothing
                });
            },
            deleteProperty() {
                this.$emit('remove-function', this.index)
            }
        }
    }
</script>
<style scoped>
    .function-card{
        border: 1px solid #ebeef5;
        border-radius: 8px;
        padding: 10px;
        margin-bottom: 10px;
    }
    .icon-rounded{
        width: 34px;
        height: 34px;
        margin-top: 2px;
    }
</style>
