
// KYC form not submitted, aka “clicking around“
export const KYC_STATUS_ONE = 1

export const KYC_STATUS_REJECTED = 2

export const KYC_STATUS_PENDING = 3

export const KYC_STATUS_APPROVED = 5

export const KYC_STATUS_FRAUD_SUSPECTED = 6

export const ONESELF_CALLS_ALLOWED = [
  KYC_STATUS_ONE,
  KYC_STATUS_REJECTED,
  KYC_STATUS_PENDING,
  KYC_STATUS_APPROVED
]

export const CALLS_TO_OTHERS_ALLOWED = [
  KYC_STATUS_APPROVED
]

export const SINGLE_TEST_NUMBER_PURCHASED_ALLOWED = [
  KYC_STATUS_ONE,
  KYC_STATUS_REJECTED,
  KYC_STATUS_PENDING
]

export const BUY_NEW_NUMBERS_ALLOWED = [
  KYC_STATUS_APPROVED
]

export const IMPORT_CONTACTS_ALLOWED = [
  KYC_STATUS_APPROVED
]

export const CREATE_CONTACTS_ALLOWED = [
  KYC_STATUS_APPROVED
]

export const ENABLE_INTEGRATIONS_ALLOWED = [
  KYC_STATUS_APPROVED
]

export const SKIP_TRIAL_ALLOWED = [
  KYC_STATUS_APPROVED
]

export const ADD_LINES_ALLOWED = [
  KYC_STATUS_PENDING,
  KYC_STATUS_APPROVED
]

export const VIEW_ONLY_ALLOWED = [
  KYC_STATUS_ONE,
  KYC_STATUS_REJECTED,
  KYC_STATUS_PENDING,
  KYC_STATUS_APPROVED
]

export const KYC_FILLED_BUT_NOT_REVIEWED = [
  KYC_STATUS_PENDING
]
