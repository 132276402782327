var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "alo-ai-bots-selector",
      staticClass: "w-full",
      attrs: {
        placeholder: _vm.placeholder,
        multiple: _vm.multiple,
        clearable: _vm.clearable,
        "collapse-tags": !_vm.no_collapse,
        size: _vm.size,
        filterable: "",
        "default-first-option": "",
      },
      on: { change: _vm.selectAloAiBot },
      model: {
        value: _vm.aloai_bot_id,
        callback: function ($$v) {
          _vm.aloai_bot_id = $$v
        },
        expression: "aloai_bot_id",
      },
    },
    _vm._l(_vm.aloAiBotsAlphabeticalOrder, function (bot) {
      return _c(
        "el-option",
        { key: bot.id, attrs: { label: bot.name, value: bot.id } },
        [_c("span", { staticClass: "ml-2" }, [_vm._v(_vm._s(bot.name))])]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }