var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.summary
    ? _c("div", { staticClass: "container" }, [
        _vm.title ? _c("h4", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _vm.summary.from
            ? _c(
                "div",
                { staticClass: "col-md-6 mb-2" },
                [
                  _c("el-card", { staticClass: "box-card h-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("span", { staticClass: "_600 text-md" }, [
                          _vm._v("From"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("table", { staticClass: "mb-2 w-100" }, [
                      _vm.summary.from.caller
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Caller"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.summary.from.caller)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.from.carrier
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Carrier"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.summary.from.carrier)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.from.city
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("City"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.summary.from.city)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.from.location
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Location"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(
                                  "Lat: " +
                                    _vm._s(_vm.summary.from.location.lat) +
                                    ", Long: " +
                                    _vm._s(_vm.summary.from.location.lon)
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.from.connection
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Connection"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("snakeToTitle")(
                                      _vm.summary.from.connection
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.from.country_code
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Country Code"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.summary.from.country_code)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.from.country_subdivision
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Country subdivision"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.summary.from.country_subdivision)
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.from.ip_address
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("IP address"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.summary.from.ip_address)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.from.number_prefix
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Number Prefix"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.summary.from.number_prefix)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.summary.to
            ? _c(
                "div",
                { staticClass: "col-md-6 mb-2" },
                [
                  _c("el-card", { staticClass: "box-card h-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("span", { staticClass: "_600 text-md" }, [
                          _vm._v("To"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("table", { staticClass: "mb-2 w-100" }, [
                      _vm.summary.to.callee
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Callee"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.summary.to.callee)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.to.carrier
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Carrier"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.summary.to.carrier)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.to.location
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Location"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(
                                  "Lat: " +
                                    _vm._s(_vm.summary.to.location.lat) +
                                    ", Long: " +
                                    _vm._s(_vm.summary.to.location.lon)
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.to.connection
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Connection"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("fixName")(_vm.summary.to.connection)
                                  )
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.to.country_code
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Country Code"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.summary.to.country_code)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.summary.to.number_prefix
                        ? _c("tr", [
                            _c("td", { staticClass: "pt-2 text-muted" }, [
                              _vm._v("Number Prefix"),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-right _400" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.summary.to.number_prefix)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.summary.carrier_edge
            ? _c(
                "div",
                { staticClass: "col-md-6 mb-2" },
                [
                  _c("el-card", { staticClass: "box-card h-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("span", { staticClass: "_600 text-md" }, [
                          _vm._v("Carrier Edge"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.summary.carrier_edge.metrics
                      ? _c("table", { staticClass: "mb-2 w-100" }, [
                          _vm.summary.carrier_edge.metrics.inbound
                            ? _c("tr", { staticClass: "b-b" }, [
                                _c("td", { staticClass: "text-muted pb-2" }, [
                                  _vm._v("Inbound Metrics"),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.carrier_edge.metrics.inbound &&
                          _vm.summary.carrier_edge.metrics.inbound.codec_name
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Codec Name"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.summary.carrier_edge.metrics.inbound
                                          .codec_name
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.carrier_edge.metrics.inbound &&
                          _vm.summary.carrier_edge.metrics.inbound.jitter
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Jitter"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Avg: " +
                                        _vm._s(
                                          _vm.summary.carrier_edge.metrics
                                            .inbound.jitter.avg
                                        ) +
                                        ", Max: " +
                                        _vm._s(
                                          _vm.summary.carrier_edge.metrics
                                            .inbound.jitter.max
                                        )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.carrier_edge.metrics.inbound &&
                          _vm.summary.carrier_edge.metrics.inbound
                            .packets_received !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packets received"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.summary.carrier_edge.metrics
                                            .inbound.packets_received
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.carrier_edge.metrics.inbound &&
                          _vm.summary.carrier_edge.metrics.inbound
                            .packets_lost !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packets lost"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.summary.carrier_edge.metrics
                                            .inbound.packets_lost
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.carrier_edge.metrics.inbound &&
                          _vm.summary.carrier_edge.metrics.inbound
                            .packets_loss_percentage !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packet loss percentage"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      "%" +
                                        _vm._s(
                                          _vm.summary.carrier_edge.metrics
                                            .inbound.packets_loss_percentage
                                        )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.carrier_edge.metrics.outbound
                            ? _c("tr", { staticClass: "b-b" }, [
                                _c(
                                  "td",
                                  { staticClass: "text-muted pt-4 pb-2" },
                                  [_vm._v("Outbound Metrics")]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.carrier_edge.metrics.outbound &&
                          _vm.summary.carrier_edge.metrics.outbound.codec_name
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Codec Name"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.summary.carrier_edge.metrics
                                          .outbound.codec_name
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.carrier_edge.metrics.outbound &&
                          _vm.summary.carrier_edge.metrics.outbound.jitter
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Jitter"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Avg: " +
                                        _vm._s(
                                          _vm.summary.carrier_edge.metrics
                                            .outbound.jitter.avg
                                        ) +
                                        ", Max: " +
                                        _vm._s(
                                          _vm.summary.carrier_edge.metrics
                                            .outbound.jitter.max
                                        )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.carrier_edge.metrics.outbound &&
                          _vm.summary.carrier_edge.metrics.outbound
                            .packets_lost !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packets lost"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.summary.carrier_edge.metrics
                                            .outbound.packets_lost
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.carrier_edge.metrics.outbound &&
                          _vm.summary.carrier_edge.metrics.outbound
                            .packets_sent !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packets sent"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.summary.carrier_edge.metrics
                                            .outbound.packets_sent
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.carrier_edge.metrics.outbound &&
                          _vm.summary.carrier_edge.metrics.outbound
                            .packets_loss_percentage !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packet loss percentage"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      "%" +
                                        _vm._s(
                                          _vm.summary.carrier_edge.metrics
                                            .outbound.packets_loss_percentage
                                        )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.summary.client_edge
            ? _c(
                "div",
                { staticClass: "col-md-6 mb-2" },
                [
                  _c("el-card", { staticClass: "box-card h-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("span", { staticClass: "_600 text-md" }, [
                          _vm._v("Carrier Edge"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.summary.client_edge.metrics
                      ? _c("table", { staticClass: "mb-2 w-100" }, [
                          _vm.summary.client_edge.metrics.inbound
                            ? _c("tr", { staticClass: "b-b" }, [
                                _c("td", { staticClass: "text-muted pb-2" }, [
                                  _vm._v("Inbound Metrics"),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.client_edge.metrics.inbound &&
                          _vm.summary.client_edge.metrics.inbound.codec_name
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Codec Name"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.summary.client_edge.metrics.inbound
                                          .codec_name
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.client_edge.metrics.inbound &&
                          _vm.summary.client_edge.metrics.inbound.jitter
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Jitter"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Avg: " +
                                        _vm._s(
                                          _vm.summary.client_edge.metrics
                                            .inbound.jitter.avg
                                        ) +
                                        ", Max: " +
                                        _vm._s(
                                          _vm.summary.client_edge.metrics
                                            .inbound.jitter.max
                                        )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.client_edge.metrics.inbound &&
                          _vm.summary.client_edge.metrics.inbound
                            .packets_received !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packets received"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.summary.client_edge.metrics
                                            .inbound.packets_received
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.client_edge.metrics.inbound &&
                          _vm.summary.client_edge.metrics.inbound
                            .packets_lost !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packets lost"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.summary.client_edge.metrics
                                            .inbound.packets_lost
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.client_edge.metrics.inbound &&
                          _vm.summary.client_edge.metrics.inbound
                            .packets_loss_percentage !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packet loss percentage"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      "%" +
                                        _vm._s(
                                          _vm.summary.client_edge.metrics
                                            .inbound.packets_loss_percentage
                                        )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.client_edge.metrics.outbound
                            ? _c("tr", { staticClass: "b-b" }, [
                                _c(
                                  "td",
                                  { staticClass: "text-muted pt-4 pb-2" },
                                  [_vm._v("Outbound Metrics")]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.client_edge.metrics.outbound &&
                          _vm.summary.client_edge.metrics.outbound.codec_name
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Codec Name"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.summary.client_edge.metrics.outbound
                                          .codec_name
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.client_edge.metrics.outbound &&
                          _vm.summary.client_edge.metrics.outbound.jitter
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Jitter"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      "Avg: " +
                                        _vm._s(
                                          _vm.summary.client_edge.metrics
                                            .outbound.jitter.avg
                                        ) +
                                        ", Max: " +
                                        _vm._s(
                                          _vm.summary.client_edge.metrics
                                            .outbound.jitter.max
                                        )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.client_edge.metrics.outbound &&
                          _vm.summary.client_edge.metrics.outbound
                            .packets_lost !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packets lost"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.summary.client_edge.metrics
                                            .outbound.packets_lost
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.client_edge.metrics.outbound &&
                          _vm.summary.client_edge.metrics.outbound
                            .packets_sent !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packets sent"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("numFormat")(
                                          _vm.summary.client_edge.metrics
                                            .outbound.packets_sent
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.summary.client_edge.metrics.outbound &&
                          _vm.summary.client_edge.metrics.outbound
                            .packets_loss_percentage !== undefined
                            ? _c("tr", [
                                _c("td", { staticClass: "pt-2 text-muted" }, [
                                  _vm._v("Packet loss percentage"),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right _400" }, [
                                  _c("span", [
                                    _vm._v(
                                      "%" +
                                        _vm._s(
                                          _vm.summary.client_edge.metrics
                                            .outbound.packets_loss_percentage
                                        )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.summary.properties || _vm.summary.carrier_edge
            ? _c(
                "div",
                { staticClass: "col-md-6 mb-2" },
                [
                  _c("el-card", { staticClass: "box-card h-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("span", { staticClass: "_600 text-md" }, [
                          _vm._v("Properties"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "table",
                      { staticClass: "mb-2 w-100" },
                      [
                        _vm.summary.properties.disconnected_by
                          ? _c("tr", [
                              _c("td", { staticClass: "pt-2 text-muted" }, [
                                _vm._v("Disconnected by"),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right _400" }, [
                                _c(
                                  "span",
                                  { staticClass: "_600 text-danger" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("fixName")(
                                          _vm.summary.properties.disconnected_by
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.summary.properties.pdd_ms
                          ? _c("tr", [
                              _c("td", { staticClass: "pt-2 text-muted" }, [
                                _vm._v("PDD"),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right _400" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.summary.properties.pdd_ms) + "ms"
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.summary.carrier_edge &&
                        _vm.summary.carrier_edge.properties
                          ? [
                              _vm.summary.carrier_edge.properties.media_region
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      { staticClass: "pt-2 text-muted" },
                                      [_vm._v("Media region")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "text-right _400" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.summary.carrier_edge
                                                .properties.media_region
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.summary.carrier_edge.properties.edge_location
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      { staticClass: "pt-2 text-muted" },
                                      [_vm._v("Edge location")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "text-right _400" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fixName")(
                                                _vm.summary.carrier_edge
                                                  .properties.edge_location
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.summary.carrier_edge.properties
                                .signaling_region
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      { staticClass: "pt-2 text-muted" },
                                      [_vm._v("Signaling region")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "text-right _400" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.summary.carrier_edge
                                                .properties.signaling_region
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }