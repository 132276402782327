<template>
    <div>
        <div v-if="!data_loaded || loading"
             :class="{ blink: loading }"
             class="placeholder text-center d-flex align-items-center justify-content-center"
             style="height: 450px">

            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div v-else
             class="force-scrollbar-view"
             style="height: 450px">
            <graph-data-switch :report_type="'activity_reporting'"
                               :series="options.series"
                               :table_label="graph_label">
                <highcharts v-show="is_done && options.series.length > 0"
                            v-bind:id="graph_id"
                            ref="highchart"
                            :class="{'overflow-y-scroll': (chart_type == 'bar' && data.length > 25)}"
                            :options="graphOptions"
                            :style="{height: '450px'}">
                </highcharts>
                <div v-show="is_done && !options.series.length"
                     class="el-table__empty-block">
                    <span class="el-table__empty-text"
                          style="color: #606266;">
                        No Data
                    </span>
                </div>
            </graph-data-switch>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapGetters} from 'vuex'
import {custom_highcharts_mixin} from '../mixins'

export default {
    props: {
        data: {
            required: true,
            default: []
        },
        type: {
            required: true,
            default: 'overview'
        },
        data_loaded: {
            required: true,
            default: false
        },
        chart_type: {
            required: false,
            default: 'bar'
        },
        overview_max_y_axis: {
            required: false,
            default: 0
        }
    },

    mixins: [custom_highcharts_mixin],

    data() {
        return {
            auth: auth,
            is_done: false,
            loading: true,
            axisLabels: [],
            graph_id: this.type.replace(/_/g, ' ') + '_graph',
            options: {
                chart: {
                    type: this.chart_type,
                    events: {
                        load: (event) => {
                            // when is chart ready
                            this.updateChartYAxis(event.target)
                        }
                    }
                },
                plotOptions: {
                    series: {
                        connectNulls: true
                    }
                },
                title: {
                    text: '',
                },
                spline: {
                    marker: {
                        enabled: true,
                        radius: 3
                    }
                },
                areaspline: {
                    fillOpacity: 0.5,
                    marker: {
                        enabled: true,
                        radius: 3
                    }
                },
                xAxis: [{
                    categories: []
                }],
                yAxis: [{
                    type: 'logarithmic',
                    custom: {
                        allowNegativeLog: true
                    },
                    tickInterval: 1,
                    title: {
                        text: 'New Contacts',
                        style: {
                            'font-size': '14px',
                            'font-weight': 'bold',
                            color: Highcharts.getOptions().colors[0]
                        },
                    },
                    labels: {
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    allowDecimals: false,
                }, {
                    type: 'logarithmic',
                    custom: {
                        allowNegativeLog: true
                    },
                    tickInterval: 1,
                    title: {
                        text: 'DNC Contacts',
                        style: {
                            'font-size': '14px',
                            'font-weight': 'bold',
                            color: Highcharts.getOptions().colors[1]
                        },
                    },
                    labels: {
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    opposite: true,
                }],
                tooltip: {
                    headerFormat: "",
                    useHTML: true,
                    shared: true,
                    crosshairs: true,
                    formatter: function () {
                        let s = []
                        let label = ''
                        s.push('<b>' + this.x + '</b>')
                        for (let point of this.points.filter((a) => a.y !== 0).sort((a, b) => b.y - a.y)) {
                            label = point.series.name ? point.series.name : 'Contacts'
                            label = point.y > 1 ? label : label.substring(0, (label.length - 1))
                            s.push(`<i class="fa fa-circle" style="color: ${point.series.color};"></i><span class="text-grey-900" style="font-size: 0.875rem"> ${label}: <b>${point.y.toLocaleString()}</b></span>`)
                        }
                        s = s.join("</br>")
                        return s
                    },
                    split: false,
                    enabled: true
                },
                legend: {
                    enabled: false
                },
                series: []
            },
            graph_label: 'All Calls'
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),

        graphOptions() {
            return this.options
        },
    },

    mounted() {
        this.graph_label = this.changeGraphLabel()
        this.generateGraphData()
    },

    methods: {
        generateGraphData: function () {
            if (this.data.length == 0) {
                this.loading = false
                this.is_done = true
                return
            }
            this.updateChartHeight()
            this.loading = true
            this.is_done = false

            let sorted_data = _.clone(this.data)

            if (this.type != 'overview') {
                // now we can sort the data
                sorted_data.sort((a, b) => b[this.type] - a[this.type])
            }
            // setup the data attrs
            let data_series = []
            let default_overview_types = ['new_contacts', 'dnc_contacts']
            let overview_types = default_overview_types
            let base_points = []
            let labels = ['New Contacts', 'DNC Contacts']
            let disabeld_y_axis_index = 0

            // we identify the type's counts
            if (this.type != 'overview') {
                overview_types = [this.type]
                disabeld_y_axis_index = this.type == 'new_contacts' ? 1 : 0
                this.options.yAxis[disabeld_y_axis_index].visible = false
            }

            // get distinct chart series values
            for (let index in sorted_data) {
                this.axisLabels.push(sorted_data[index].name)
                for (let type_index in overview_types) {
                    let value = sorted_data[index][overview_types[type_index]]
                    value = parseInt(value)
                    if (typeof base_points[type_index] === 'undefined') {
                        base_points[type_index] = []
                    }
                    base_points[type_index].push(value)
                }
            }

            if (this.type == 'dnc_contacts') {
                this.options.yAxis[1].opposite = false
            }

            data_series = []
            for (let type_index in overview_types) {
                let data = {
                    name: labels[type_index],
                    data: base_points[type_index],
                    yAxis: parseInt(type_index),
                    type: this.chart_type
                }
                let enabled_overview_index = default_overview_types.indexOf(overview_types[type_index])
                if (enabled_overview_index) {
                    data.color = Highcharts.getOptions().colors[enabled_overview_index]
                }
                data_series.push(data)
            }

            // set the x-axis labels
            this.options.xAxis[0].categories = this.axisLabels

            //no data flag
            if (data_series.length) {
                this.options.series = data_series
            } else {
                this.options.series = []
            }

            this.is_done = true
            this.loading = false
        },

        changeGraphLabel() {
            switch (this.type) {
                case 'overview':
                    return 'Contacts'
                case 'new_contacts':
                    return 'Number of New Contacts'
                case 'dnc_contacts':
                    return 'Number of DNC Contacts'
            }
        },

        updateChartHeight() {
            if (this.chart_type == 'bar' && this.data.length > 25) {
                this.options.chart.height = 20 * this.data.length
            }
        },

        updateChartYAxis(chart) {
            let maxYAxisValue = 0
            let yAxis1 = chart.yAxis[0].getExtremes().max
            let yAxis2 = chart.yAxis[1].getExtremes().max
            // check if first y axis' max point is NaN and skip it
            if (yAxis1 !== yAxis1) {
                maxYAxisValue = Math.max(chart.yAxis[1].getExtremes().max)
            } else if (yAxis2 !== yAxis2) { // check if second y axis' max point is NaN and skip it
                maxYAxisValue = Math.max(chart.yAxis[0].getExtremes().max)
            } else { // use all y axis max point
                maxYAxisValue = Math.max(chart.yAxis[0].getExtremes().max, chart.yAxis[1].getExtremes().max)
            }
            if (this.type == 'overview' || maxYAxisValue > this.overview_max_y_axis) {
                this.$emit('bottomGraphMaxYAxisValueLoaded', maxYAxisValue)
            } else {
                maxYAxisValue = this.overview_max_y_axis
            }
            maxYAxisValue = maxYAxisValue != 0 ? maxYAxisValue : this.overview_max_y_axis
            chart.yAxis[0].setExtremes(0, maxYAxisValue, true)
            chart.yAxis[1].setExtremes(0, maxYAxisValue, true)
            chart.redraw();
        }
    },

    watch: {
        data: {
            deep: true,
            handler: function () {
                this.generateGraphData()
            }
        },
        'type': function () {
            this.graph_label = this.changeGraphLabel()
            this.generateGraphData()
        }
    },

    destroyed() {
        this.loading = true
        this.is_done = false
    }
}
</script>
