var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "dropdown-menu pull-right w-xl text-color box-shadow-z2 dropdown-menu-right dropdown-menu-overlay dropdown-menu-scale p-3 allow-select",
    },
    [
      _vm.serviceCode
        ? _c(
            "span",
            {
              staticClass: "text-md",
              on: {
                click: function ($event) {
                  return _vm.noClose($event)
                },
              },
            },
            [
              _vm._v("\n        Service code:\n        "),
              _c("b", [_vm._v(_vm._s(_vm.serviceCode))]),
              _vm._v(" "),
              _c("br"),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "text-md",
          on: {
            click: function ($event) {
              return _vm.noClose($event)
            },
          },
        },
        [
          _vm._v("\n        Role:\n        "),
          _c("b", [_vm._v(_vm._s(_vm.roleName))]),
          _vm._v(" "),
          _c("br"),
        ]
      ),
      _vm._v(" "),
      _vm.showSalesRepInfo
        ? [
            _c("hr"),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "text-md",
                on: {
                  click: function ($event) {
                    return _vm.noClose($event)
                  },
                },
              },
              [
                _vm._v("\n            Your Sales Rep is:\n            "),
                _c("b", [_vm._v(_vm._s(_vm.salesRepInfo.name))]),
                _vm._v(" "),
                _c("br"),
              ]
            ),
            _vm._v(" "),
            _vm.salesRepInfo.email
              ? _c(
                  "span",
                  {
                    staticClass: "text-md",
                    on: {
                      click: function ($event) {
                        return _vm.noClose($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("Email:")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      { attrs: { href: "mailto:" + _vm.salesRepInfo.email } },
                      [_c("b", [_vm._v(_vm._s(_vm.salesRepInfo.email))])]
                    ),
                    _vm._v(" "),
                    _c("br"),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.salesRepInfo.phone
              ? _c(
                  "span",
                  {
                    staticClass: "text-md",
                    on: {
                      click: function ($event) {
                        return _vm.noClose($event)
                      },
                    },
                  },
                  [
                    _vm._v("\n            Phone:\n            "),
                    _c("b", [_vm._v(_vm._s(_vm.salesRepInfo.phone))]),
                    _vm._v(" "),
                    _c("br"),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showCsmInfo
        ? [
            _c("hr"),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "text-md",
                on: {
                  click: function ($event) {
                    return _vm.noClose($event)
                  },
                },
              },
              [
                _vm._v("\n            Your CSM is:\n            "),
                _c("b", [_vm._v(_vm._s(_vm.csmInfo.name))]),
                _vm._v(" "),
                _c("br"),
              ]
            ),
            _vm._v(" "),
            _vm.csmInfo.email
              ? _c(
                  "span",
                  {
                    staticClass: "text-md",
                    on: {
                      click: function ($event) {
                        return _vm.noClose($event)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("Email:")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      { attrs: { href: "mailto:" + _vm.csmInfo.email } },
                      [_c("b", [_vm._v(_vm._s(_vm.csmInfo.email))])]
                    ),
                    _vm._v(" "),
                    _c("br"),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.csmInfo.phone
              ? _c(
                  "span",
                  {
                    staticClass: "text-md",
                    on: {
                      click: function ($event) {
                        return _vm.noClose($event)
                      },
                    },
                  },
                  [
                    _vm._v("\n            Phone:\n            "),
                    _c("b", [_vm._v(_vm._s(_vm.csmInfo.phone))]),
                    _vm._v(" "),
                    _c("br"),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm._t("customLegend"),
      _vm._v(" "),
      _vm.showDivider ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm.showSetupGuide
        ? _c(
            "a",
            {
              staticClass: "btn btn-block btn-success",
              attrs: {
                href: "https://aloware.com/setup-guide",
                target: "_blank",
              },
            },
            [_vm._v("\n        Setup Guide\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showRequestSolutionEngineerButton
        ? _c(
            "button",
            {
              staticClass: "btn btn-block btn-success",
              on: { click: _vm.handleRequestSolutionEngineer },
            },
            [_vm._v("\n        Request a Solution Engineer\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSalesRepInfo
        ? _c(
            "button",
            {
              staticClass: "btn btn-block btn-success",
              attrs: { disabled: !_vm.salesRepInfo.calendar_link },
              on: { click: _vm.handleRequestSalesCall },
            },
            [_vm._v("\n        Request a Sales Rep call\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showCsmInfo
        ? _c(
            "button",
            {
              staticClass: "btn btn-block btn-success",
              attrs: { disabled: !_vm.csmInfo.calendar_link },
              on: { click: _vm.handleRequestCSMCall },
            },
            [_vm._v("\n        Request a CSM Call\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showOrderTrainingButton
        ? _c(
            "a",
            {
              staticClass: "btn btn-block btn-success",
              attrs: { target: "_blank", href: _vm.FORM_URL },
            },
            [
              _vm._v("\n        Order Training\n        "),
              _c("span", { staticClass: "text-xs" }, [_vm._v("(paid)")]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.debuggingInfo.env !== "production" ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm.debuggingInfo.env !== "production"
        ? _c("p", { staticClass: "mb-0 mt-2 text-xs" }, [
            _vm._v("\n        Environment:\n        "),
            _c("span", { staticClass: "_600 text-break" }, [
              _vm._v(_vm._s(_vm.debuggingInfo.env)),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v("\n        Branch:\n        "),
            _c("span", { staticClass: "_600 text-break" }, [
              _vm._v(_vm._s(_vm.debuggingInfo.branch)),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v("\n        Fake Number Purchase:\n        "),
            _c("span", { staticClass: "_600 text-break" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("fixBooleanType")(
                      _vm.debuggingInfo.fake_number_purchase
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v("\n        Fake Power Dialer Calls:\n        "),
            _c("span", { staticClass: "_600 text-break" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("fixBooleanType")(
                      _vm.debuggingInfo.fake_power_dialer_calls
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v("\n        Fake Text Messages:\n        "),
            _c("span", { staticClass: "_600 text-break" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("fixBooleanType")(
                      _vm.debuggingInfo.fake_text_messages
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v("\n        Fake Toll-Free Verification:\n        "),
            _c("span", { staticClass: "_600 text-break" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("fixBooleanType")(
                      _vm.debuggingInfo.fake_toll_free_verification
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v("\n        Mock A2P Brands:\n        "),
            _c("span", { staticClass: "_600 text-break" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("fixBooleanType")(
                      _vm.debuggingInfo.mock_a2p_brand_registration
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v("\n        Mock A2P Campaigns:\n        "),
            _c("span", { staticClass: "_600 text-break" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("fixBooleanType")(
                      _vm.debuggingInfo.mock_a2p_campaign_registration
                    )
                  ) +
                  "\n        "
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }