<template>
    <el-dialog width="40%"
               top="4vh"
               append-to-body
               :visible.sync="showManager"
               :before-close="closeFiltersMenu">
        <div slot="title">
            <h6>
                <i class="fa fa-circle mr-2"
                   v-if="mode === 'edit'"
                   :class="'event-color-type-' + schedule.type + ' status-' + schedule.status + ' ' + (schedule.is_past ? 'is_past' : '')">
                </i>
                {{ title }}
            </h6>
        </div>
        <div>
            <el-form ref="scheduleForm"
                     label-position="top"
                     :model="schedule"
                     :rules="rules"
                     data-testid="schedule-form"
                     @submit.prevent.native="saveSchedule()">
                <el-form-item
                    label="Title"
                    prop="text">
                    <el-input type="text"
                              placeholder="Add title"
                              v-model="schedule.text"
                              data-testid="reminder-manager-add-title-input"
                              :disabled="!isEditable">
                    </el-input>
                </el-form-item>

                <el-form-item
                    label="Event Type"
                    prop="type"
                    v-if="called_from !== 'contact'">
                    <el-select
                        class="w-100"
                        v-model="schedule.type"
                        placeholder="Select type"
                        data-testid="reminder-manager-event-type-select"
                        @change="customPreValidateForm"
                        :disabled="mode === 'edit'">
                        <el-option
                            v-for="t in event_types"
                            :key="t.value"
                            :label="t.label"
                            :value="t.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item
                    label="Contact"
                    prop="contact"
                    :class="mode === 'add' ? '': 'd-none'"
                    v-if="show_contact_selector">
                    <contact-select
                        ref="contactSelector"
                        class="w-100"
                        label="Select Contact"
                        data-testid="reminder-manager-contact-select"
                        :contact_selected="contact"
                        @blur="customPreValidateForm"
                        @select-contacts="setContact">
                    </contact-select>
                </el-form-item>

                <div class="row">
                    <div class="col">
                        <el-form-item
                            label="User"
                            v-if="mode === 'edit'">
                            <div
                                class="card py-2 px-3"
                                style="line-height: 1.35"
                                v-if="schedule && schedule.user">
                                <strong class="mb-0 d-block">
                                    {{ schedule.user.first_name }} {{ schedule.user.last_name }}
                                </strong>
                                <span>ID: {{ schedule.user.id }}</span>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="col">
                        <el-form-item label="Contact"
                                      v-if="mode === 'edit' && schedule.contact">
                            <a target="_blank"
                               :href="getContactTalkRedirectURL(schedule.contact.id)">
                                <div class="card py-2 px-3"
                                     style="line-height: 1.35">
                                    <el-tooltip class="item"
                                                effect="dark"
                                                content="Click to go to Contacts screen"
                                                placement="bottom">
                                        <div class="media">
                                            <div class="media-body">
                                                <strong class="mb-0 d-block">
                                                    {{ schedule.contact.name }}
                                                </strong>
                                                <span>{{ schedule.contact.phone_number | fixPhone }}</span>
                                            </div>
                                            <span class="align-self-center text-muted"><i class="fa fa-chevron-right"></i></span>
                                        </div>
                                    </el-tooltip>
                                </div>
                            </a>
                        </el-form-item>

                        <el-form-item label="Contact"
                                      v-if="mode === 'edit' && schedule.contact === null">
                            <div class="card py-2 px-3"
                                 style="line-height: 1.35">
                                <div class="media-body">
                                    <strong class="mb-0 d-block">
                                        {{ schedule.text }}
                                    </strong>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                </div>

                <template v-if="schedule.type > 0 && schedule.contact && schedule.contact.id">
                    <div class="row">
                        <div class="col-md">
                            <el-form-item label="Date"
                                          class="mb-0"
                                          prop="date">
                                <el-date-picker placeholder="Pick a date"
                                                v-model="schedule.date"
                                                class="w-100"
                                                format="MM/dd/yyyy"
                                                value-format="MM/dd/yyyy"
                                                default-value="04/03/2020"
                                                data-testid="reminder-manager-date-picker"
                                                type="date"
                                                :disabled="schedule.is_past || !isEditable"
                                                :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="col-md">
                            <el-form-item label="Time"
                                          class="mb-0"
                                          prop="time">
                                <el-select
                                    placeholder="Start time"
                                    v-model="schedule.time"
                                    class="w-100"
                                    data-testid="reminder-manager-start-time-select"
                                    :disabled="schedule.is_past || !isEditable">
                                    <el-option
                                        v-for="time in times"
                                        :key="time.value"
                                        :label="time.label"
                                        :value="time.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-md"
                             v-if="schedule.type == CommunicationTypes.APPOINTMENT">
                            <el-form-item label="Duration (minutes)"
                                          class="mb-0"
                                          v-if="schedule.type == CommunicationTypes.APPOINTMENT">
                                <el-select class="w-100"
                                           v-model="schedule.duration"
                                           placeholder="Select duration"
                                           data-testid="reminder-manager-duration-select"
                                           :disabled="schedule.is_past || !isEditable">
                                    <el-option label="15 minutes"
                                               value="15">
                                    </el-option>
                                    <el-option label="30 minutes"
                                               value="30">
                                    </el-option>
                                    <el-option label="1 hour"
                                               value="60">
                                    </el-option>
                                    <el-option label="1 hour and 30 minutes"
                                               value="90">
                                    </el-option>
                                    <el-option label="2 hours"
                                               value="120">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>

                    <el-form-item
                        label="Timezone"
                        class="mt-4">
                        <el-select v-model="schedule.timezone"
                                   placeholder="Select Timezone"
                                   class="w-100"
                                   filterable
                                   data-testid="reminder-manager-timezone-select"
                                   :disabled="!isEditable">
                            <el-option v-for="timezone in filteredTimezones"
                                       :key="timezone.value"
                                       :label="timezone.name"
                                       :value="timezone.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="Note">
                        <el-input type="textarea"
                                  placeholder="Write a note for this event..."
                                  v-model="schedule.body"
                                  :autosize="autoResizeOpt"
                                  data-testid="reminder-manager-event-note-input"
                                  :disabled="!isEditable">
                        </el-input>
                    </el-form-item>

                    <el-form-item v-if="!schedule.is_past && schedule.type === CommunicationTypes.APPOINTMENT">
                        <p class="m-1">
                            <strong>SMS reminder :</strong>
                        </p>
                        <el-checkbox v-model="sms_reminder_fields.enabled"
                                     data-testid="reminder-manager-event-sms-checkbox"
                                     @change="updateSmsReminderVariables">Enable SMS reminder
                        </el-checkbox>
                        <template v-if="sms_reminder_fields.enabled">
                            <el-form-item>
                                <strong>Send from :</strong>
                                <campaign-selector
                                    ref="campaignSelector"
                                    class_prop="w-100"
                                    v-model="sms_reminder_fields.campaign_id"
                                    use_first_option
                                    data-testid="reminder-manager-send-from-select"
                                    @change="updateSmsReminderLine"
                                    :show_paused="false">
                                </campaign-selector>
                            </el-form-item>
                            <el-form-item>
                                <strong>Time :</strong>
                                <el-select
                                    v-model="sms_reminder_fields.time"
                                    placeholder="Select time"
                                    filterable
                                    data-testid="reminder-manager-time-select"
                                    class="w-100">
                                    <el-option
                                        v-for="time in times"
                                        :key="time.value"
                                        :label="time.label"
                                        :value="time.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <strong>Send (n) days before:</strong>
                            <el-select
                                class="w-full"
                                v-model="sms_reminder_fields.frequencies"
                                filterable
                                data-testid="reminder-manager-send-days-before-select"
                                multiple>
                                <el-option value="0" label="On appointment day"></el-option>
                                <el-option value="1" label="1 day before"></el-option>
                                <el-option value="2" label="2 days before"></el-option>
                                <el-option value="3" label="3 days before"></el-option>
                                <el-option value="4" label="4 days before"></el-option>
                                <el-option value="5" label="5 days before"></el-option>
                                <el-option value="6" label="6 days before"></el-option>
                                <el-option value="7" label="7 days before"></el-option>
                            </el-select>
                            <b>Template variables :</b>
                            <div class="pb-2"
                                 style="line-height: initial">
                                    <span class="text-danger sms-reminder-template-variables"
                                          v-for="item in sms_reminder_fields.template_variables"
                                          :key="item"
                                          @click="addTemplateVariableToBody(item)">
                                        {{ item }}
                                    </span>
                            </div>
                            <el-input ref="sms_reminder_body"
                                      type="textarea"
                                      maxlength="255"
                                      show-word-limit
                                      data-testid="reminder-manager-sms-reminder-textarea"
                                      v-model="sms_reminder_fields.body">
                            </el-input>
                        </template>
                    </el-form-item>
                </template>

                <el-form-item label="Status"
                              v-if="mode === 'edit'">
                    <el-radio-group v-model="schedule.status"
                                    size="small"
                                    data-testid="reminder-manager-status-radio-group"
                                    :disabled="!isEditable">
                        <template v-if="schedule.type == CommunicationTypes.APPOINTMENT">
                            <el-radio-button data-testid="reminder-manager-status-set"
                                             :label="CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SET">
                                Set
                            </el-radio-button>
                            <el-radio-button data-testid="reminder-manager-status-shown"
                                             :label="CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_SHOWN">
                                {{ isSimpSocial ? 'Shown' : 'Completed' }}
                            </el-radio-button>
                            <el-radio-button data-testid="reminder-manager-status-cancel"
                                             :label="CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_CANCELED">
                                Canceled
                            </el-radio-button>
                            <el-radio-button data-testid="reminder-manager-status-missed"
                                             :label="CommunicationDispositionStatus.DISPOSITION_STATUS_APPOINTMENT_MISSED">
                                Missed
                            </el-radio-button>
                        </template>
                        <template v-else>
                            <el-radio-button data-testid="reminder-manager-status-pending"
                                             :label="CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW">
                                Pending
                            </el-radio-button>
                            <el-radio-button data-testid="reminder-manager-status-completed"
                                             :label="CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW">
                                Completed
                            </el-radio-button>
                            <el-radio-button data-testid="reminder-manager-status-canceled"
                                             :label="CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW">
                                Canceled
                            </el-radio-button>
                            <el-radio-button data-testid="reminder-manager-status-missed"
                                             :label="CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW">
                                Missed
                            </el-radio-button>
                        </template>
                    </el-radio-group>
                </el-form-item>

                <hr/>

                <div class="mt-2 d-flex">
                    <div v-if="mode === 'edit'">
                        <el-button v-if="isEditable"
                                   type="danger"
                                   :disabled="loading"
                                   data-testid="reminder-manager-remove-button"
                                   @click="deleteSchedule(schedule.id)">
                            <i class="fa fa-remove"></i>
                            Remove
                        </el-button>
                    </div>
                    <div class="ml-auto">
                        <el-button data-testid="reminder-manager-cancel-button"
                                   @click="closeFiltersMenu">Cancel</el-button>
                        <el-button v-if="isEditable"
                                   type="success"
                                   :disabled="loading || !validated"
                                   data-testid="reminder-manager-set-event-button"
                                   @click="saveSchedule">
                            Save Event
                        </el-button>
                    </div>
                </div>
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import auth from '../../auth'
import {
    acl_mixin,
    contact_talk_mixin,
    date_mixin,
    form_validation_mixin,
    validator_mixin
} from '../../mixins'
import {mapGetters, mapState} from 'vuex'
import CampaignSelector from '../campaign-selector'
import * as CommunicationDispositionStatus from '../../constants/communication-disposition-status'
import * as CommunicationTypes from '../../constants/communication-types'

export default {
    mixins: [
        acl_mixin,
        contact_talk_mixin,
        validator_mixin,
        form_validation_mixin,
        date_mixin
    ],

    components: {
        CampaignSelector
    },

    props: {
        called_from: {
            required: false,
            default: 'calendar' // calendar, contact
        },
        contact: {
            required: false,
            default: null
        },
        campaign: {
            required: false
        }
    },

    data() {
        return {
            auth: auth,
            showManager: false,
            loading: false,
            mode: 'add',
            schedule: {
                contact: {},
                user: auth.user.profile,
                calendar_response: 1,
                disposition_status2: null,
                is_past: true,
                text: '',
                timezone: auth.user.profile.timezone,
                time: '06:00',
                type: null
            },
            original_schedule: {},
            sms_reminder_fields: {
                enabled: true,
                template_variables: [
                    '[FirstName]', '[CompanyName]', '[AgentName]', '[DateTime]', '[TimeLeft]'
                ],
                body: '',
                campaign_id: null,
                frequencies: ["1"],
                time: '10:00'
            },
            lines: [],
            selected_line: null,
            title: 'Add Event',
            pickerOptions: {
                disabledDate: time => this.disabledDate(time)
            },
            timePickerOptions: {
                start: '06:00',
                step: '00:15',
                end: '23:45',
            },
            startDate: new Date(),
            event_types: [
                {
                    label: 'Reminder',
                    value: CommunicationTypes.REMINDER
                },
                {
                    label: 'Appointment',
                    value: CommunicationTypes.APPOINTMENT
                }
            ],
            errors: {
                type: false,
                contact: false
            },
            autoResizeOpt: {
                minRows: 2,
                maxRows: 4
            },
            rules: {
                type: [
                    {
                        required: true,
                        message: 'Please select a type for this event',
                        trigger: 'change'
                    }
                ],
                date: [
                    {
                        required: true,
                        message: 'Please select a date for this event',
                        trigger: 'change'
                    }
                ],
                time: [
                    {
                        required: true,
                        message: 'Please select a time for this event',
                        trigger: 'change'
                    }
                ],
                contact: [
                    {
                        validator: this.contactValidator
                    }
                ],
            },
            show_contact_selector: true,
            CommunicationDispositionStatus,
            CommunicationTypes
        }
    },

    computed: {
        ...mapState(['campaigns']),

        ...mapState('cache', ['current_company']),

        ...mapGetters('cache', ['isSimpSocial']),

        times() {
            const times = []
            for (let hour = 6; hour < 24; hour++) {
                times.push({
                    label: moment({hour}).format('h:mm A'),
                    value: moment({hour}).format('HH:mm')
                })
                times.push({
                        label: moment({hour, minute: 15}).format('h:mm A'),
                        value: moment({hour, minute: 15}).format('HH:mm')
                    }
                )
                times.push({
                        label: moment({hour, minute: 30}).format('h:mm A'),
                        value: moment({hour, minute: 30}).format('HH:mm')
                    }
                )
                times.push({
                        label: moment({hour, minute: 45}).format('h:mm A'),
                        value: moment({hour, minute: 45}).format('HH:mm')
                    }
                )
            }
            return times
        },

        isEditable: function () {
            if (this.mode === 'edit') {
                if (this.hasRole('Company Admin')) {
                    return true
                }
                return this.schedule.user.id === auth.user.profile.id
            }

            return this.mode === 'add'
        },
    },

    mounted() {
        // If there is campaign ID passed, use it as default for selected line
        if (this.campaign_id) {
            this.selected_line = this.campaign_id
        }

        // Preset sms reminder text
        this.setSmsReminderFields()

        this.sms_reminder_fields.enabled = this.current_company.sms_reminder_enabled
    },

    methods: {
        disabledDate(time) {
            let contact = this.schedule.contact
            let timezone = this.schedule.timezone

            if (!timezone || !contact || (contact && !contact.id)) {
                return false
            }

            let base_time = moment(time).tz(timezone)
            let current_datetime = moment(Date.now() - 8.64e7).tz(timezone)

            return base_time < current_datetime
        },

        editSchedule: function (sched) {
            let date = moment(sched.start_date)

            this.original_schedule = {
                id: sched.id,
                type: sched.type,
                date: date.format('MM/DD/YYYY'),
                time: date.format('HH:mm'),
                duration: sched.duration,
                contact: sched.contact,
                user: sched.user,
                body: sched.body,
                status: sched.status,
                date_time: 1,
                is_past: sched.is_past,
                status_name: sched.status_name,
                text: sched.text,
                timezone: sched.contact_timezone || null
            }
            this.schedule = _.clone(this.original_schedule)

            if (sched.is_past) {
                this.resetSmsReminder()
            } else {
                this.setSmsReminder(sched.sms_reminders)
            }

            this.showManager = true
            this.title = sched.status_name + ' - Edit Event'
            this.mode = 'edit'
            this.validated = true
        },

        addSchedule: function (date) {
            let d = moment(date)

            let no_tome = {hour: 0, minute: 0, second: 0, millisecond: 0}
            let just_date = d.clone().set(no_tome)
            let now_date = moment().set(no_tome)

            // not allowed to add date in the past
            if (just_date.isBefore(now_date)) {
                return
            }

            // Presets
            this.startDate = d
            let contact = {}
            let type = null
            let timezone = null

            if (this.called_from === 'contact') {
                contact = this.schedule.contact
                type = CommunicationTypes.APPOINTMENT
                timezone = this.schedule.contact.timezone
            }

            this.original_schedule = {
                id: null,
                type: type,
                date: d.format('MM/DD/YYYY'),
                time: '06:00',
                duration: 15,
                contact: contact,
                user: auth.user.profile,
                status: CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW,
                body: '',
                text: '',
                date_time: 1,
                timezone: timezone
            }

            this.schedule = _.clone(this.original_schedule)

            this.mode = 'add'

            this.showManager = true
            this.title = 'Add Event'
            this.validated = false

            if (this.called_from === 'contact') {
                this.title = 'Add Appointment'

                this.customPreValidateForm()
            }
        },

        deleteSchedule(schedule_id) {
            this.$confirm(`Are you sure you want to delete this event?`, 'Warning', {
                confirmButtonText: 'Yes, Remove',
                cancelButtonText: 'No, Keep',
                customClass: 'width-500 fixed',
                type: 'warning',
            }).then(() => {
                this.loading = true
                axios.delete(`/api/v1/calendar/events/contact/${this.schedule.contact.id}/${schedule_id}`).then(r => {
                    this.$emit('render-schedule', {
                        data: r.data.data,
                        action: 'delete'
                    })
                    this.loading = false
                    this.showManager = false

                    this.$notify({
                        offset: 95,
                        title: 'Calendar',
                        message: 'Event removed.',
                        type: 'success',
                        showClose: true
                    })
                }).catch(err => {
                    this.loading = false
                    this.showManager = false
                })
            }).catch(err => {
                console.log(err)
            })
        },

        saveSchedule() {
            this.customPreValidateForm()
            if (!this.validated) {
                return
            }
            const cid = this.schedule.contact.id
            const eid = this.schedule.id

            if (this.mode === 'add') {
                if (cid != null && this.schedule.type != null) {
                    this.loading = true

                    // If event type is APPOINTMENT and sms reminder option is enabled
                    // - include sms reminder option
                    if (this.schedule.type == CommunicationTypes.APPOINTMENT && this.sms_reminder_fields.enabled) {
                        this.schedule.sms_reminder = this.sms_reminder_fields
                    }

                    let post_data = cloneDeep(this.schedule)
                    post_data.called_from = this.called_from
                    post_data.user_timezone = window.timezone

                    axios.post(`/api/v1/calendar/events/contact/${cid}/create`, post_data).then(res => {
                        this.loading = false

                        this.$emit('render-schedule', {
                            data: res.data,
                            action: 'add'
                        })
                        this.showManager = false

                        this.resetForm()

                        this.$notify({
                            offset: 95,
                            title: 'Calendar',
                            message: 'Event added.',
                            type: 'success',
                            showClose: true
                        })
                    }).catch(err => {
                        this.loading = false
                        this.$root.handleErrors(err.response)
                    })
                } else {
                    this.errors.contact = true
                    if (this.schedule.type == null) {
                        this.errors.type = true
                    }
                }
            } else {
                if (cid != null && eid != null) {
                    this.loading = true

                    // If event type is APPOINTMENT and sms reminder option is enabled and event isnt past
                    // - include sms reminder option
                    if (this.schedule.type == CommunicationTypes.APPOINTMENT && this.sms_reminder_fields.enabled && !this.schedule.is_past) {
                        this.schedule.sms_reminder = this.sms_reminder_fields
                    }

                    let post_data = cloneDeep(this.schedule)
                    post_data.called_from = this.called_from
                    post_data.user_timezone = window.timezone
                    post_data.entity_type = "event" // it means entity id for events table

                    axios.post(`/api/v1/calendar/events/contact/${cid}/update/${eid}`, post_data).then(res => {
                        this.loading = false

                        this.$emit('render-schedule', {
                            data: res.data,
                            action: 'update'
                        })
                        this.showManager = false

                        this.resetForm()

                        this.$notify({
                            offset: 95,
                            title: 'Calendar',
                            message: 'Event updated.',
                            type: 'success',
                            showClose: true
                        })
                    }).catch(err => {
                        this.loading = false
                        this.$root.handleErrors(err.response)
                    })
                }
            }
        },

        resetForm: function () {
            if (this.called_from === 'contact') {
                this.schedule.user = auth.user.profile
                this.schedule.calendar_response = 1
            }

            if (this.called_from === 'calendar') {
                this.schedule = {
                    contact: {},
                    user: auth.user.profile,
                    calendar_response: 1
                }
                this.errors = {
                    type: false,
                    contact: false
                }

                if (this.$refs.contactSelector) {
                    this.$refs.contactSelector.reset()
                }
            }

            if (this.$refs.scheduleForm) {
                this.$refs.scheduleForm.clearValidate()
            }
        },

        setContact: function (contact) {
            this.schedule.contact = {}
            this.schedule.timezone = auth.user.profile.timezone

            if (contact) {
                this.schedule.contact = contact
                this.schedule.timezone = contact.timezone
                // Hide contact selector if this component is called from contact page
                if (this.called_from === 'contact') {
                    this.show_contact_selector = false
                }
            }

            this.customPreValidateForm()
        },

        closeFiltersMenu(done) {
            if (_.isEqual(this.schedule, this.original_schedule)) {
                this.resetForm()
                this.showManager = false
            } else {
                this.$confirm(`Are you sure you want to close this form?`, 'Event', {
                    confirmButtonText: "Yes, I'm sure",
                    cancelButtonText: "No, I'm not",
                    type: 'warning',
                    customClass: 'width-500 fixed'
                }).then(() => {
                    this.resetForm()
                    this.showManager = false
                }).catch(err => {
                    console.log(err)
                })
            }
        },

        customPreValidateForm: _.debounce(function (reset = false) {
            this.preValidateForm('scheduleForm')
        }, 100),

        updateSmsReminderVariables() {
            if (this.schedule.time) {
                this.sms_reminder_fields.time = this.schedule.time
            }
            // Set default time to 09:00 if reseller is SimpSocial
            if (this.current_company.reseller_id == 357) {
                this.sms_reminder_fields.time = '09:00'
            }

            this.setSmsReminderFields()
        },

        setSmsReminderFields() {
            this.sms_reminder_fields.body = this.current_company.sms_reminder_default_text

            // use personal line or default campaign_id
            this.sms_reminder_fields.campaign_id = !this.current_company.sms_reminder_use_personal_line
                ? this.current_company.sms_reminder_default_campaign_id
                : auth.user.profile.campaign_id

            // update time if its set in account config
            if (this.current_company.sms_reminder_default_time) {
                this.sms_reminder_fields.time = this.current_company.sms_reminder_default_time
            }

            // update frequency if its set in account config
            if (this.current_company.sms_reminder_default_send_before_days) {
                this.sms_reminder_fields.frequencies = this.current_company.sms_reminder_default_send_before_days.split(',')
            }
        },

        addTemplateVariableToBody(variable) {
            let body = this.sms_reminder_fields.body ?? ''
            this.sms_reminder_fields.body = body + ' ' + variable
        },

        updateSmsReminderLine(line) {
            this.sms_reminder_fields.campaign_id = line
        },

        resetSmsReminder() {
            this.sms_reminder_fields = {
                enabled: true,
                template_variables: [
                    '[FirstName]', '[CompanyName]', '[AgentName]', '[DateTime]', '[TimeLeft]'
                ],
                body: '',
                campaign_id: null,
                frequencies: ["1"],
                time: '10:00'
            }

            this.setSmsReminderFields()
        },

        setSmsReminder(sms_reminder) {
            // the only property that changes among all reminders is fire_at
            if (sms_reminder) {
                this.sms_reminder_fields = {
                    enabled: true,
                    template_variables: [
                        '[FirstName]', '[CompanyName]', '[AgentName]', '[DateTime]', '[TimeLeft]'
                    ],
                    body: sms_reminder.body,
                    campaign_id: sms_reminder.campaign_id,
                    time: sms_reminder.time,
                    frequencies: sms_reminder.frequencies
                }
            } else {
                this.sms_reminder_fields.enabled = false
            }
        }
    }
}

</script>
