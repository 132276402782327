var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-blank",
      attrs: {
        width: "50vw",
        top: "4vh",
        "before-close": _vm.closeDialog,
        visible: _vm.is_visible,
        "append-to-body": true,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "px-3 pt-3 pb-0",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("h5", { staticClass: "mb-0" }, [
            _vm._v("\n            Cost & Usage Summary\n        "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "_300 mb-0" }, [
            _vm.date_range
              ? _c("span", [
                  _vm._v(
                    "\n                From " +
                      _vm._s(
                        _vm._f("momentFormat")(_vm.date_range[0], "MMM D, YYYY")
                      ) +
                      " to " +
                      _vm._s(
                        _vm._f("momentFormat")(_vm.date_range[1], "MMM D, YYYY")
                      ) +
                      "\n            "
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    "\n                Showing all-time usage\n            "
                  ),
                ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.data.length > 0
        ? _c("div", { staticClass: "px-3 pb-3" }, [
            _c(
              "table",
              { staticClass: "table table-sm table-hover table-aloware" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("Resource")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Paid Usage")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Included Usage")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Quantity")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Amount")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.data, function (datum) {
                    return _c("tr", [
                      _c("td", [
                        _c("i", {
                          staticClass: "fa fa-circle mr-2",
                          style: "color: " + datum.color,
                        }),
                        _vm._v(" " + _vm._s(datum.name) + "\n                "),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("numFormat")(datum.total_paid)) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("numFormat")(datum.total_included)) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("numFormat")(datum.total_quantity)) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toCurrency")(
                                _vm._f("fixRounding")(datum.total_credit)
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _vm.total_resource
              ? _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body d-flex py-3 px-3" }, [
                    _c("h4", { staticClass: "mb-0" }, [_vm._v("Total:")]),
                    _vm._v(" "),
                    _c("h4", { staticClass: "mb-0 ml-auto text-dark" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm._f("fixRounding")(
                              _vm.total_resource.total_credit
                            )
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.length === 0
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "div",
              { staticClass: "w-75 mx-auto d-inline-block py-5" },
              [
                _c("img-empty"),
                _vm._v(" "),
                _c("p", { staticClass: "lead" }, [
                  _vm._v(
                    "\n                No cost and usage on this date range.\n            "
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }