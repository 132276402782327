var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list sms template")
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              staticClass: "dialog-padding auto",
              attrs: {
                title: "Templates",
                width: _vm.isLargeEnough ? "500px" : "100%",
                visible: _vm.dialogVisible,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("template-list", { attrs: { template_scope: "user" } }),
              _vm._v(" "),
              _c("template-list", { attrs: { template_scope: "company" } }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("trigger", function () {
            return [
              !_vm.short
                ? [
                    _vm.text_button
                      ? _c(
                          "el-button",
                          {
                            class:
                              "text-blackish pb-0 mb-2 font-alternative " +
                              _vm.btn_class,
                            attrs: {
                              slot: "reference",
                              type: "text",
                              disabled: _vm.disabled,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.dialogVisible = true
                              },
                            },
                            slot: "reference",
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("description"),
                            ]),
                            _vm._v("\n                Templates\n            "),
                          ]
                        )
                      : _c(
                          "el-button",
                          {
                            class: _vm.btn_class,
                            attrs: {
                              slot: "reference",
                              type: "success",
                              disabled: _vm.disabled,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.dialogVisible = true
                              },
                            },
                            slot: "reference",
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("description"),
                            ]),
                            _vm._v("\n                Templates\n            "),
                          ]
                        ),
                  ]
                : [
                    _vm.text_button
                      ? _c(
                          "el-button",
                          {
                            staticClass: "text-blackish pb-0 font-alternative",
                            attrs: { slot: "reference", type: "text" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.dialogVisible = true
                              },
                            },
                            slot: "reference",
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("description"),
                            ]),
                          ]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "btn greenish",
                            attrs: { slot: "reference", type: "success" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.dialogVisible = true
                              },
                            },
                            slot: "reference",
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("description"),
                            ]),
                          ]
                        ),
                  ],
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }