<template>
    <div>
        <el-dialog :visible.sync="show_extension_wizard"
                   :before-close="beforeCloseModal"
                   custom-class="wizard-dialog dialog-no-border"
                   data-testid="user-extension-wizard-dialog"
                   top="10vh"
                   append-to-body>
            <div slot="title">
                <h5 class="mb-0">
                    Add Extension
                </h5>
            </div>

            <div class="wizard">
                <div>
                    <el-form ref="extension_number"
                             label-width="200px"
                             label-position="top"
                             :model="extension"
                             :rules="rules"
                             data-testid="wizard-add-extension-form"
                             @submit.prevent.native="addExtension">
                        <div class="row">
                            <div class="col-6">
                                <el-form-item label="Label"
                                              prop="label">
                                    <el-input v-model="extension.label"
                                              placeholder="Label Name"
                                              data-testid="add-extension-label-input"
                                              @input="preValidateForm('extension_number')">
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="col-6">
                                <el-form-item label="Phone Number"
                                              prop="phone_number">
                                    <el-input v-model="extension.phone_number"
                                              placeholder="+18185005050"
                                              data-testid="add-extension-phone-number-input"
                                              @input="preValidateForm('extension_number')">
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form>

                    <div class="text-right">
                        <el-button type="success"
                                   :disabled="loading_btn || !validated"
                                   data-testid="create-extension-button"
                                   @click="addExtension">
                            <i class="material-icons loader"
                               v-show="loading_btn">&#xE863;</i>
                            <i class="fa fa-plus"
                               v-show="!loading_btn"></i>
                            Create Extension
                        </el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import auth from '../auth'
    import {
        acl_mixin,
        validator_mixin,
        form_validation_mixin
    } from '../mixins'

    export default {
        mixins: [
            acl_mixin,
            validator_mixin,
            form_validation_mixin
        ],

        data() {
            return {
                auth: auth,
                loading: false,
                show_extension_wizard: false,
                extension: {
                    label: null,
                    phone_number: null
                },
                rules: {
                    label: [
                        {
                            required: true,
                            message: 'Please input a label to proceed',
                            trigger: 'blur'
                        }
                    ],
                    phone_number: [
                        {
                            required: true,
                            validator: this.addExtensionPhoneNumberValidator,
                            trigger: 'blur'
                        }
                    ]
                },
                loading_btn: false,
                success: false
            }
        },

        methods: {
            addExtension() {
                axios.post(`/api/v1/user/new-extension`, this.extension).then(res => {
                    this.show_extension_wizard = false
                    this.$notify({
                        offset: 95,
                        title: `User Extension`,
                        message: `User Extension ${this.extension.label} has been added successfully.`,
                        type: 'success',
                        showClose: true,
                    })
                    this.resetExtension()
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                })
            },

            resetExtension() {
                this.extension = {
                    label: null,
                    phone_number: null
                }

                let form_element = _.get(this.$refs, 'extension_number', null)

                if (form_element) {
                    form_element.clearValidate()
                }
            },

            beforeCloseModal(done) {
                if (this.extension.label || this.extension.phone_number) {
                    this.$confirm('Are you sure you want to leave?', 'Warning', {
                        confirmButtonText: 'Yes, Leave',
                        cancelButtonText: 'No, Stay',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(res => {
                        this.resetExtension()
                        done()
                    }).catch(() => {

                    })
                } else {
                    this.resetExtension()
                    done()
                }
            }
        },

        watch: {
            show_extension_wizard() {
                if (this.show_extension_wizard) {
                    this.$emit('hide')
                } else {
                    this.$emit('show')
                    this.preValidateForm('extension_number', true)
                }
            }
        }
    }
</script>
