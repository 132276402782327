import store from '../stores/filters-store'

export default {

    // DATE helpers

    setDateRange(from_date = null, to_date = null) {
        if (from_date) {
            store.commit('SET_DASHBOARD_FROM_DATE', from_date)
        }
        if (to_date) {
            store.commit('SET_DASHBOARD_TO_DATE', to_date)
        }
    },

    setDateGroupBy(group_by) {
        store.commit('SET_DASHBOARD_DATE_GROUP_BY', group_by)
    },

    setFromDate(from_date) {
        this.setDateRange(from_date)
    },

    setToDate(to_date) {
        this.setDateRange(null, to_date)
    },

    dateRange() {
        return {
            from_date: store.state.dashboard.date.from,
            to_date: store.state.dashboard.date.to
        }
    },

    dateGroupBy() {
        return {
            date_group_by: store.state.dashboard.date.group_by
        }
    },

    // Attribution - Users

    setUsers(users) {
        store.commit('SET_DASHBOARD_ATTRIBUTION_USERS', users)
    },

    users() {
        return store.state.dashboard.attributions.users
    },

    // Attribution - Lines

    setLines(lines) {
        store.commit('SET_DASHBOARD_ATTRIBUTION_LINES', lines)
    },

    lines() {
        return store.state.dashboard.attributions.lines
    },

    // Attribution - Ring Groups

    setRingGroups(ring_groups) {
        store.commit('SET_DASHBOARD_ATTRIBUTION_RING_GROUPS', ring_groups)
    },

    ringGroups() {
        return store.state.dashboard.attributions.ring_groups
    },

    // Attribution - Phone Numbers

    setPhoneNumbers(phone_numbers) {
        store.commit('SET_DASHBOARD_ATTRIBUTION_PHONE_NUMBERS', phone_numbers)
    },

    phoneNumbers() {
        return store.state.dashboard.attributions.phone_numbers
    },

    // Attribution - Sequences

    setSequences(sequences) {
        store.commit('SET_DASHBOARD_ATTRIBUTION_SEQUENCES', sequences)
    },

    sequences() {
        return store.state.dashboard.attributions.sequences
    },

    // Attribution - Broadcasts

    setBroadcasts(broadcasts) {
        store.commit('SET_DASHBOARD_ATTRIBUTION_BROADCASTS', broadcasts)
    },

    broadcasts() {
        return store.state.dashboard.attributions.broadcasts
    },

    // Attribution - Tags

    setTags(tags) {
        store.commit('SET_DASHBOARD_ATTRIBUTION_TAGS', tags)
    },

    tags() {
        return store.state.dashboard.attributions.tags
    },

    attributions() {
        return {
            users: store.state.dashboard.attributions.users,
            lines: store.state.dashboard.attributions.lines,
            ring_groups: store.state.dashboard.attributions.ring_groups,
            phone_numbers: store.state.dashboard.attributions.phone_numbers,
            sequences: store.state.dashboard.attributions.sequences,
            broadcasts: store.state.dashboard.attributions.broadcasts,
            tags: store.state.dashboard.attributions.tags
        }
    },

    // Filters for kpi
    kpiFilters() {
        return {
            from_date: store.state.dashboard.date.from,
            to_date: store.state.dashboard.date.to,
            attributions: {
                users: store.state.dashboard.attributions.users,
                lines: store.state.dashboard.attributions.lines,
                ring_groups: store.state.dashboard.attributions.ring_groups,
                phone_numbers: store.state.dashboard.attributions.phone_numbers,
                sequences: store.state.dashboard.attributions.sequences,
                broadcasts: store.state.dashboard.attributions.broadcasts,
                tags: store.state.dashboard.attributions.tags,
            }
        }
    },

    // Filters for graph
    graphFilters() {
        return {
            from_date: store.state.dashboard.date.from,
            to_date: store.state.dashboard.date.to,
            date_group_by: store.state.dashboard.date.group_by,
            attributions: {
                users: store.state.dashboard.attributions.users,
                lines: store.state.dashboard.attributions.lines,
                ring_groups: store.state.dashboard.attributions.ring_groups,
                phone_numbers: store.state.dashboard.attributions.phone_numbers,
                sequences: store.state.dashboard.attributions.sequences,
                broadcasts: store.state.dashboard.attributions.broadcasts,
                tags: store.state.dashboard.attributions.tags,
            }
        }
    },

    // all filters
    get() {
        return {
            from_date: store.state.dashboard.date.from,
            to_date: store.state.dashboard.date.to,
            date_group_by: store.state.dashboard.date.group_by,
            attributions: {
                users: store.state.dashboard.attributions.users,
                lines: store.state.dashboard.attributions.lines,
                ring_groups: store.state.dashboard.attributions.ring_groups,
                phone_numbers: store.state.dashboard.attributions.phone_numbers,
                sequences: store.state.dashboard.attributions.sequences,
                broadcasts: store.state.dashboard.attributions.broadcasts,
                tags: store.state.dashboard.attributions.tags,
            }
        }
    }
}
