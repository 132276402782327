var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contact_id && _vm.campaign_id && _vm.phone_number
    ? _c(
        "div",
        { staticClass: "w-full" },
        [
          _c(
            "el-select",
            {
              staticStyle: { "max-width": "170px" },
              attrs: {
                placeholder: "Select Template",
                loading: _vm.loading_templates,
                "default-first-option": "",
                clearable: "",
                filterable: "",
              },
              model: {
                value: _vm.message,
                callback: function ($$v) {
                  _vm.message = $$v
                },
                expression: "message",
              },
            },
            [
              _vm.agentTemplates && _vm.agentTemplates.length > 0
                ? _c(
                    "el-option-group",
                    {
                      key: "Agent Templates",
                      attrs: { label: "Agent Templates" },
                    },
                    _vm._l(_vm.agentTemplates, function (template) {
                      return _c(
                        "el-option",
                        {
                          key: template.id,
                          attrs: { label: template.name, value: template.body },
                        },
                        [
                          _c("div", { staticClass: "media" }, [
                            _c("div", { staticClass: "media-body" }, [
                              _c("label", [_vm._v(_vm._s(template.name))]),
                              _vm._v(" "),
                              _c("small", { staticClass: "text-xxs" }, [
                                _vm._v(_vm._s(template.body)),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.accountTemplates && _vm.accountTemplates.length > 0
                ? _c(
                    "el-option-group",
                    {
                      key: "Account Templates",
                      attrs: { label: "Account Templates" },
                    },
                    _vm._l(_vm.accountTemplates, function (template) {
                      return _c(
                        "el-option",
                        {
                          key: template.id,
                          attrs: { label: template.name, value: template.body },
                        },
                        [
                          _c("div", { staticClass: "media" }, [
                            _c("div", { staticClass: "media-body" }, [
                              _c("label", [_vm._v(_vm._s(template.name))]),
                              _vm._v(" "),
                              _c("small", { staticClass: "text-xxs" }, [
                                _vm._v(_vm._s(template.body)),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-md dark-greenish pull-right",
              attrs: {
                disabled:
                  _vm.loading_send_message ||
                  !_vm.message ||
                  _vm.message.length === 0,
              },
              on: { click: _vm.sendMessage },
            },
            [
              _c(
                "i",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading_send_message,
                      expression: "loading_send_message",
                    },
                  ],
                  staticClass: "material-icons loader",
                },
                [_vm._v("\n            \n        ")]
              ),
              _vm._v("\n        Send\n    "),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }