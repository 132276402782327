<template>
    <div>
        <div class="row b-b">
            <div class="col-12 p-3">
                <company-form-token></company-form-token>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-3">
                <info-alert-box text="Please note that the Two-Legged Call API usage is considered automated & paid. You are agreeing to our billing terms by using the API."
                                kb-link="https://support.aloware.com/en/articles/9033804-aloware-pricing-plans-empowering-your-teams-with-flexible-solutions">
                </info-alert-box>

                <div class="mb-2">
                    <strong class="integration-api-titles">Establish a two-legged call with a contact.</strong>
                </div>

                <p class="text-md">
                    <span class="text-dark-greenish _600">POST /api/v1/webhook/two-legged-call</span>
                </p>

                <div class="mb-2">
                    <strong>Required Fields:</strong>
                    <br>
                    <div class="text-dark-greenish _600">api_token</div>
                    <div class="text-dark-greenish _600">user_id <span class="text-black-lt">or</span> ring_group_id</div>
                    <div class="text-dark-greenish _600">contact_phone_number <span class="text-black-lt">or</span> contact_id</div>
                    <div class="text-dark-greenish _600">line_phone_number <span class="text-black-lt">or</span> line_id</div>
                </div>

                <div class="mb-2">
                    <strong>Optional Fields:</strong>
                    <br>
                    <div class="text-dark-greenish _600">user_phone_number if user_id is selected</div>
                </div>

                <p>
                    <strong>Example POST URL:</strong>
                    <br>
                    <span class="text-dark-greenish _600">{{ statics.domain | fixDomain }}/api/v1/webhook/two-legged-call</span>
                </p>

                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to establish a two legged call between a ring group and a contact with Line Phone Number:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_tlc_a'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "ring_group_id": "1", // put your ring group's id here
    "contact_phone_number": "+18181276543", // put your lead’s phone number here
    "line_phone_number": "+18552562001", // put one of your line's phone numbers here
}</code></pre>


                <pre v-highlightjs :key="renderRefreshToken + '_tlc_b'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "ring_group_id": "1", // put your ring group's id here
    "contact_id": "1234", // put your contact's id here
    "line_phone_number": "+18552562001", // put one of your line's phone numbers here
}</code></pre>
                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to establish a two legged call between a ring group and a contact with Line ID:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_tlc_c'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "ring_group_id": "1", // put your ring group's id here
    "contact_phone_number": "+18181276543", // put your lead’s phone number here
    "line_id": "1234", // Aloware Line ID to establish the call from.
}</code></pre>

                <pre v-highlightjs :key="renderRefreshToken + '_tlc_d'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "ring_group_id": "1", // put your ring group's id here
    "contact_id": "1234", // put your contact's id here
    "line_id": "1234", // Aloware Line ID to establish the call from.
}</code></pre>

                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to establish a two legged call between a user and a contact using User ID and User Phone Number with Line Phone Number:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_tlc_e'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "user_id": "1", // put your agent's user id here
    "user_phone_number": "+18181234567", // put your agent's phone number here
    "contact_phone_number": "+18181276543", // put your lead’s phone number here
    "line_phone_number": "+18552562001", // put one of your line's phone numbers here
}</code></pre>

                <pre v-highlightjs :key="renderRefreshToken + '_tlc_f'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "user_id": "1", // put your agent's user id here
    "user_phone_number": "+18181234567", // put your agent's phone number here
    "contact_id": "1234", // put your contact's id here
    "line_phone_number": "+18552562001", // put one of your line's phone numbers here
}</code></pre>

                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to establish a two legged call between a user and a contact using User ID and User Phone Number with Line ID:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_tlc_g'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "user_id": "1", // put your agent's user id here
    "user_phone_number": "+18181234567", // put your agent's phone number here
    "contact_phone_number": "+18181276543", // put your lead’s phone number here
    "line_id": "1234", // Aloware Line ID to establish the call from.
}</code></pre>

                <pre v-highlightjs :key="renderRefreshToken + '_tlc_h'"><code class="json">{
    "api_token": "{{ companyToken }}",
    "user_id": "1", // put your agent's user id here
    "user_phone_number": "+18181234567", // put your agent's phone number here
    "contact_id": "1234", // put your contact's id here
    "line_id": "1234", // Aloware Line ID to establish the call from.
}</code></pre>

                <p class="mb-2">
                    <strong>Example POST header:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "Accept": "application/json",
    "Content-Type": "application/json"
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 202 response when Two Legged Call is established, Example success response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "Two legged call established."
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 422 on failure with a detailed response, Example failure response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "The given data was invalid.",
    "errors": {
        "contact_phone_number": [
            "The contact_phone_number field is required when contact_id is empty."
        ],
        "contact_id": [
            "The contact_id field is required when contact_phone_number is empty."
        ],
        "line_phone_number": [
            "The line_phone_number field is required when line_id is empty."
        ],
        "line_id": [
            "TThe line_id field is required when line_phone_number is empty."
        ],
        "user_id": [
            "The user_id field is required."
        ]
    }
}</code></pre>

                <p class="mb-2">
                    <strong>cURL Example:</strong>
                    <span class="text-muted">
                        With phone number.
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_tlc_i'" v-if="!loading_whitelabel"><code class="bash">curl -X POST \
  {{ statics.domain | fixDomain }}/api/v1/webhook/two-legged-call \
  -H 'Accept: application/json' \
  -H 'Content-Type: application/json' \
  -d '{
    "api_token": "{{ companyToken }}",
    "user_id": "1", // put your agent's user id here
    "user_phone_number": "+18181234567", // put your agent's phone number here
    "contact_phone_number": "+18181276543", // put your lead’s phone number here
    "line_phone_number": "+18552562001", // put one of your line's phone numbers here
  }'</code></pre>

                <pre v-highlightjs :key="renderRefreshToken + '_tlc_j'" v-if="!loading_whitelabel"><code class="bash">curl -X POST \
  {{ statics.domain | fixDomain }}/api/v1/webhook/two-legged-call \
  -H 'Accept: application/json' \
  -H 'Content-Type: application/json' \
  -d '{
    "api_token": "{{ companyToken }}",
    "user_id": "1", // put your agent's user id here
    "user_phone_number": "+18181234567", // put your agent's phone number here
    "contact_id": "1234", // put your contact's id here
    "line_phone_number": "+18552562001", // put one of your line's phone numbers here
  }'</code></pre>

                <p class="mb-2">
                    <strong>cURL Example:</strong>
                    <span class="text-muted">
                        With Line ID.
                    </span>
                </p>

                <pre v-highlightjs :key="renderRefreshToken + '_tlc_k'" v-if="!loading_whitelabel"><code class="bash">curl -X POST \
  {{ statics.domain | fixDomain }}/api/v1/webhook/two-legged-call \
  -H 'Accept: application/json' \
  -H 'Content-Type: application/json' \
  -d '{
    "api_token": "{{ companyToken }}",
    "user_id": "1", // put your agent's user id here
    "user_phone_number": "+18181234567", // put your agent's phone number here
    "contact_phone_number": "+18181276543", // put your lead’s phone number here
    "line_phone_number": "+18552562001", // put one of your line's phone numbers here
  }'</code></pre>

                <pre v-highlightjs :key="renderRefreshToken + '_tlc_l'" v-if="!loading_whitelabel"><code class="bash">curl -X POST \
  {{ statics.domain | fixDomain }}/api/v1/webhook/two-legged-call \
  -H 'Accept: application/json' \
  -H 'Content-Type: application/json' \
  -d '{
    "api_token": "{{ companyToken }}",
    "user_id": "1", // put your agent's user id here
    "user_phone_number": "+18181234567", // put your agent's phone number here
    "contact_id": "1234", // put your contact's id here
    "line_id": "1234", // put one of your line's phone numbers here
  }'</code></pre>

                <p class="_600"
                   v-if="!statics.whitelabel">
                    If you need more API functions, please contact our support at support@aloware.com .
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {company_token_mixin} from '../../mixins'
import CompanyFormToken from '../account/company-form-token'
import InfoAlertBox from "../info-alert-box.vue"

export default {
    name: "integrations-two-legged-call-api",

    mixins: [
        company_token_mixin
    ],

    components: {
        InfoAlertBox,
        CompanyFormToken
    },

    data() {
        return {
            statics: {
                domain: null,
                whitelabel: false,
            },
            loading_whitelabel: true
        }
    },

    created() {
        this.getStatics()
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        }
    }
}
</script>
