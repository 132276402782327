var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body", attrs: { id: "view" } }, [
    _c("div", { staticClass: "insights" }, [
      _c(
        "div",
        {
          staticClass: "padding mt-5 pt-3",
          staticStyle: { position: "relative", overflow: "hidden" },
          attrs: { id: "insights-filters" },
        },
        [
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-12 text-center" },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "col-12 no-select pull-left mt-1",
                    attrs: { size: "small" },
                    model: {
                      value: _vm.selectedInsight,
                      callback: function ($$v) {
                        _vm.selectedInsight = $$v
                      },
                      expression: "selectedInsight",
                    },
                  },
                  _vm._l(_vm.insights, function (insight) {
                    return _c(
                      "el-radio-button",
                      { key: insight.id, attrs: { label: insight } },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(insight.name) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.loadedInsightUrl
        ? _c(
            "div",
            { attrs: { id: "insights-show" } },
            [
              _c("insights-show", {
                attrs: {
                  url: _vm.loadedInsightUrl,
                  query: _vm.showFilters ? _vm.query : null,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.showFilters
                      ? {
                          key: "filters",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "filter-container" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-5 p-0" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "p-0 m-0 _500 w-100" },
                                      [_vm._v("Date Filter:")]
                                    ),
                                    _vm._v(" "),
                                    _c("date-selector", {
                                      staticClass: "date-filter",
                                      attrs: {
                                        default_date_range:
                                          _vm.initialDateRange,
                                        allow_initial_reset: false,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.isContactReport
                                  ? _c(
                                      "div",
                                      { staticClass: "col-md-5 p-0" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "p-0 m-0 _500 w-100" },
                                          [_vm._v("Baseline:")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "no-select",
                                            model: {
                                              value: _vm.query.baseline,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.query,
                                                  "baseline",
                                                  $$v
                                                )
                                              },
                                              expression: "query.baseline",
                                            },
                                          },
                                          _vm._l(
                                            _vm.baselines,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item,
                                                attrs: { value: item },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isContactReport
                                  ? _c(
                                      "div",
                                      { staticClass: "col-md-5 p-0" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "p-0 m-0 _500 w-100" },
                                          [_vm._v("Metric:")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "no-select",
                                            model: {
                                              value: _vm.query.metrics,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.query,
                                                  "metrics",
                                                  $$v
                                                )
                                              },
                                              expression: "query.metrics",
                                            },
                                          },
                                          _vm._l(_vm.metrics, function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-5 p-0" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "p-0 m-0 _500 w-100" },
                                      [_vm._v("Filter by User:")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "user-selector",
                                      {
                                        attrs: {
                                          placeholder: "Select User",
                                          multiple: !_vm.isContactReport,
                                          hide_extensions: true,
                                          value: _vm.query.filter_by_user,
                                          collapseTags: true,
                                        },
                                        on: { change: _vm.changeUser },
                                      },
                                      [
                                        _vm.isContactReport
                                          ? _c(
                                              "el-option-group",
                                              {
                                                key: "No Users",
                                                attrs: { label: "No Users" },
                                              },
                                              _vm._l(
                                                _vm.moreUserOptions,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item,
                                                    attrs: { value: item },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-5 p-0" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "p-0 m-0 _500 w-100" },
                                      [_vm._v("Filter by Line:")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "campaign-selector",
                                      {
                                        attrs: {
                                          multiple: !_vm.isContactReport,
                                          clearable: true,
                                          value: _vm.query.filter_by_line,
                                        },
                                        on: {
                                          input: _vm.changeCampaigns,
                                          change: _vm.changeCampaigns,
                                        },
                                      },
                                      [
                                        _vm.isContactReport
                                          ? _c(
                                              "el-option-group",
                                              {
                                                key: "No Lines",
                                                attrs: { label: "No Lines" },
                                              },
                                              _vm._l(
                                                _vm.moreLineOptions,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item,
                                                    attrs: { value: item },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                !_vm.isContactReport
                                  ? _c(
                                      "div",
                                      { staticClass: "col-md-5 p-0" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "p-0 m-0 _500 w-100" },
                                          [_vm._v("Filter by Team:")]
                                        ),
                                        _vm._v(" "),
                                        _c("team-selector", {
                                          attrs: {
                                            multiple: !_vm.isContactReport,
                                            collapsable: true,
                                            clearable: true,
                                            value: _vm.query.filter_by_team,
                                          },
                                          on: { change: _vm.changeTeam },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }