<template>
  <el-form ref="ivr_step_form"
           v-if="ivr_step"
           :model="ivr_step"
           :rules="rules"
           @submit.prevent.native="">
    <template
      v-if="!ivr_step.listen_for_extension && (!ivr_step.type || ivr_step.type !== 'exit')">
      <el-form-item class="inline mb-3 mr-1"
                    prop="key">
        <span class="el-form-item__label inline">When the caller presses</span>
        <el-select placeholder="Select a key"
                   style="width: 120px"
                   v-model="ivr_step.key"
                   @change="preValidateForm('ivr_step_form')">
          <el-option v-for="key in availableKeys"
                     :label="key"
                     :key="key"
                     :value="key">
          </el-option>
        </el-select>
      </el-form-item>

      <template v-if="ivr_step.key">
        <el-form-item prop="task"
                      class="inline mb-3">
          <span class="el-form-item__label inline">then </span>
          <el-select placeholder="Select a task"
                     style="width: 300px"
                     filterable
                     clearable
                     v-model="ivr_step.task"
                     @change="changeIvrTask"
                     @clear="preValidateForm('ivr_step_form')">
            <el-option v-for="task in tasksAlphabeticalOrder"
                       :label="task.name"
                       :key="task.id"
                       :value="task.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="reroute_to_campaign_id"
                      class="inline mb-3 ml-2"
                      v-if="ivr_step.task === 'route_to_other_campaign'">
          <campaign-selector ref="campaign_selector"
                             :value="ivr_step.reroute_to_campaign_id"
                             v-model="ivr_step.reroute_to_campaign_id"
                             @change="changeRerouteToCampaign">
          </campaign-selector>
        </el-form-item>

        <el-form-item prop="reroute_to_ivr_id"
                      class="inline mb-3 ml-2"
                      v-if="ivr_step.task === 'route_to_another_ivr'">
          <ivr-selector ref="ivr_selector"
                        :value="ivr_step.reroute_to_ivr_id"
                        v-model="ivr_step.reroute_to_ivr_id"
                        @change="changeRerouteToIvr">
          </ivr-selector>
        </el-form-item>

        <el-form-item prop="route_to_ring_group_id"
                      class="inline mb-3 ml-2"
                      v-if="ivr_step.task === 'route_to_ring_group'">
          <ring-group-selector ref="ring_group_selector"
                               v-model="ivr_step.route_to_ring_group_id"
                               @change="changeRouteToRingGroup">
          </ring-group-selector>
        </el-form-item>

        <el-form-item prop="route_to_user_id"
                      class="inline mb-3 ml-2"
                      v-if="ivr_step.task === 'route_to_user'">
          <user-selector ref="user_selector"
                         v-model="ivr_step.route_to_user_id"
                         :value="ivr_step.route_to_user_id"
                         :ignore_focus_mode="true"
                         @change="changeRouteToUser">
          </user-selector>
        </el-form-item>

        <el-form-item prop="route_to_hs_deal_contact_owner"
                      class="inline mb-3 ml-2"
                      v-if="showHubSpotOptions && ivr_step.task === 'route_to_hs_deal_contact_owner'">
          <span class="el-form-item__label inline"> on </span>
          <el-select placeholder="Select a pipeline"
                     multiple
                     style="width: 200px"
                     v-model="ivr_step.route_to_hs_deal_contact_owner"
                     @change="preValidateForm('ivr_step_form')">
              <el-option v-for="pipeline in pipelines"
                         :label="pipeline.label"
                         :key="pipeline.pipelineId"
                         :value="pipeline.pipelineId">
              </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="workflow_ids"
                      class="inline mb-3"
                      v-if="hasPermissionTo(['list workflow', 'view workflow']) && ivr_step.task === 'enroll_to_workflow'">
          <workflow-selector v-model="ivr_step.workflow_ids"
                             style="width: 300px"
                             :multiple="true"
                             :multipleLimit="1"
                             @change="changeWorkflows">
          </workflow-selector>
        </el-form-item>
      </template>

      <div class="row" v-if="showHubSpotOptions && ivr_step.task === 'route_to_hs_deal_contact_owner'">
        <div class="col-12">
          <span class="el-form-item__label inline">If agents were unavailable route to </span>
          <el-form-item prop="fallback_ring_group_id"
                        class="inline mb-3 ml-2">
            <ring-group-selector ref="ring_group_selector"
                                 v-model="ivr_step.fallback_ring_group_id"
                                 @change="changeFallbackRingGroup">
            </ring-group-selector>
          </el-form-item>
        </div>
      </div>

      <!-- has Say / Play -->
      <div class="row"
           v-if="has_say">
        <div class="col-12 d-flex">
          <el-form-item class="inline mb-3">
            <el-select placeholder="Say / Play"
                       style="width: 80px"
                       v-model="is_say"
                       @change="preValidateForm('ivr_step_form')">
              <el-option v-for="option in message_options"
                         :label="option.name"
                         :key="option.id"
                         :value="option.value">
              </el-option>
            </el-select>
          </el-form-item>
          <template v-if="ivr_step.key && ivr_step.message_file === null && is_say === false">
            <el-form-item prop="message_file"
                          class="inline mb-3 ml-2 mt-1">
              <el-upload :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/general_ivr_file'"
                         :headers="headers"
                         :on-success="onSuccessMessage"
                         :on-error="onFailedMessage"
                         :on-progress="progressUploadMessage"
                         :before-upload="beforeUploadMessage"
                         :file-list="uploadFileList.upload"
                         :show-file-list="true"
                         :multiple="false"
                         :disabled="loading_upload">
                <el-button size="small"
                           type="primary"
                           class="inline"
                           :disabled="loading_upload">
                  Select File
                </el-button>
              </el-upload>
            </el-form-item>
            <span class="ml-1 inline text-xxs">* MP3/WAV file (less than 8MB)</span>
          </template>

          <template v-if="ivr_step.key && is_say === true">
            <el-form-item prop="message_tts"
                          class="inline mb-3 ml-2">
              <div class="input-group">
                <input type="text"
                       class="form-control pt-1 pb-1"
                       style="width: 500px"
                       v-model="ivr_step.message_tts"
                       @input="preValidateForm('ivr_step_form')"/>
                <variable-dialog></variable-dialog>
              </div>
            </el-form-item>
          </template>
        </div>
      </div>

      <div class="row"
           v-if="ivr_step.message_file && is_say !== true">
        <div class="col-12">
          <el-form-item class="inline mb-3">
            <div class="d-flex">
              <audio controls>
                <source :src="'/static/uploaded_file/' + ivr_step.message_file">
                Your browser does not support the audio element.
              </audio>
              <div class="d-flex justify-content-center align-items-center">
                <button class="btn btn-sm btn-danger ml-2"
                        v-if="ivr_step.message_file !== null"
                        @click.prevent="deleteFile('general_ivr_file')">
                  <i class="material-icons loader pull-left"
                     v-show="loading_delete">&#xE863;</i>
                  <i class="material-icons pull-left"
                     v-show="!loading_delete">&#xE872;</i>
                  <span>Remove file</span>
                </button>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <el-checkbox v-model="has_extra"
                       @change="preValidateForm('ivr_step_form')"
                       class="_600">
            Also apply tags
          </el-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <template v-if="has_extra">
            <el-form-item prop="tag_ids"
                          class="inline mb-3"
                          v-if="hasPermissionTo(['list tag', 'view tag'])">
              <tag-selector v-model="ivr_step.tag_ids"
                            :multiple="true"
                            style="width: 300px"
                            @change="changeTags">
              </tag-selector>
            </el-form-item>
          </template>
        </div>
      </div>
    </template>
    <template v-else-if="ivr_step.listen_for_extension">
      <span class="el-form-item__label inline">When the caller enters an extension, connect the call to the user.</span>
    </template>
    <template v-else-if="ivr_step.type && ivr_step.type === 'exit'">
      <el-form-item prop="task"
                    class="inline mb-3">
        <span class="el-form-item__label inline">If the caller didn't enter anything</span>
        <el-select placeholder="Select an exit strategy"
                   style="width: 300px"
                   v-model="ivr_step.task"
                   @change="changeIvrTask"
                   filterable
                   clearable>
          <el-option v-for="exit in exitsAlphabeticalOrder"
                     :label="exit.name"
                     :key="exit.id"
                     :value="exit.value">
          </el-option>
        </el-select>
      </el-form-item>

      <template v-if="ivr_step.task">
        <el-form-item prop="reroute_to_campaign_id"
                      class="inline mb-3 ml-2"
                      v-if="ivr_step.task === 'route_to_other_campaign'">
          <campaign-selector ref="campaign_selector"
                             :value="ivr_step.reroute_to_campaign_id"
                             v-model="ivr_step.reroute_to_campaign_id"
                             @change="changeRerouteToCampaign">
          </campaign-selector>
        </el-form-item>

        <el-form-item prop="reroute_to_ivr_id"
                      class="inline mb-3 ml-2"
                      v-if="ivr_step.task === 'route_to_another_ivr'">
          <ivr-selector ref="ivr_selector"
                        :value="ivr_step.reroute_to_ivr_id"
                        v-model="ivr_step.reroute_to_ivr_id"
                        @change="changeRerouteToIvr">
          </ivr-selector>
        </el-form-item>

        <el-form-item prop="route_to_ring_group_id"
                      class="inline mb-3 ml-2"
                      v-if="ivr_step.task === 'route_to_ring_group'">
          <ring-group-selector ref="ring_group_selector"
                               v-model="ivr_step.route_to_ring_group_id"
                               @change="changeRouteToRingGroup">
          </ring-group-selector>
        </el-form-item>

        <el-form-item prop="route_to_user_id"
                      class="inline mb-3 ml-2"
                      v-if="ivr_step.task === 'route_to_user'">
          <user-selector ref="user_selector"
                         v-model="ivr_step.route_to_user_id"
                         :value="ivr_step.route_to_user_id"
                         :ignore_focus_mode="true"
                         @change="changeRouteToUser">
          </user-selector>
        </el-form-item>
      </template>

      <!-- has Say / Play -->
      <div class="row"
           v-if="has_say">
        <div class="col-12 d-flex">
          <el-form-item class="inline mb-3">
            <el-select placeholder="Say / Play"
                       style="width: 80px"
                       v-model="is_say"
                       @change="preValidateForm('ivr_step_form')">
              <el-option v-for="option in message_options"
                         :label="option.name"
                         :key="option.id"
                         :value="option.value">
              </el-option>
            </el-select>
          </el-form-item>

          <template v-if="is_say === false && ivr_step.message_file === null">
            <el-form-item prop="message_file"
                          class="inline mb-3 ml-2 mt-1">
              <el-upload :action="'/api/v1/campaign/' + campaign.id + '/upload-ivr/general_ivr_file'"
                         :headers="headers"
                         :on-success="onSuccessMessage"
                         :on-error="onFailedMessage"
                         :on-progress="progressUploadMessage"
                         :before-upload="beforeUploadMessage"
                         :file-list="uploadFileList.upload"
                         :show-file-list="true"
                         :multiple="false"
                         :disabled="loading_upload">
                <el-button size="small"
                           type="primary"
                           class="inline"
                           :disabled="loading_upload">
                  Select File
                </el-button>
              </el-upload>
            </el-form-item>
            <span class="ml-1 inline text-xxs">* MP3/WAV file (less than 8MB)</span>
          </template>

          <template v-if="is_say === true">
            <el-form-item prop="message_tts"
                          class="inline mb-3 ml-2">
              <div class="input-group">
                <input type="text"
                       class="form-control pt-1 pb-1"
                       style="width: 500px"
                       v-model="ivr_step.message_tts"
                       @input="preValidateForm('ivr_step_form')"/>
                <variable-dialog></variable-dialog>
              </div>
            </el-form-item>
          </template>
        </div>
      </div>

      <div class="row"
           v-if="ivr_step.message_file && is_say !== true">
        <div class="col-12">
          <el-form-item class="dinline mb-3">
            <div class="d-flex">
              <audio controls>
                <source :src="'/static/uploaded_file/' + ivr_step.message_file">
                Your browser does not support the audio element.
              </audio>
              <div class="d-flex justify-content-center align-items-center">
                <button class="btn btn-sm btn-danger ml-2"
                        v-if="ivr_step.message_file !== null"
                        @click.prevent="deleteFile('general_ivr_file')">
                  <i class="material-icons loader pull-left"
                     v-show="loading_delete">&#xE863;</i>
                  <i class="material-icons pull-left"
                     v-show="!loading_delete">&#xE872;</i>
                  <span>Remove file</span>
                </button>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <el-checkbox v-model="has_extra"
                       @change="preValidateForm('ivr_step_form')"
                       class="_600">
            Also apply tags
          </el-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <template v-if="has_extra">
            <el-form-item prop="tag_ids"
                          class="inline mb-3"
                          v-if="hasPermissionTo(['list tag', 'view tag'])">
              <tag-selector v-model="ivr_step.tag_ids"
                            style="width: 300px"
                            :category_prop="TagCategory.CAT_COMMUNICATIONS"
                            :multiple="true"
                            @change="changeTags">
              </tag-selector>
            </el-form-item>
          </template>
        </div>
      </div>
    </template>

    <div class="row no-select">
      <div class="col-12">
                <span class="pull-right _600 opacity-5"
                      :class="[ ivr_step.valid ? 'text-dark-greenish' : 'text-danger' ]">
                    <i class="fa fa-check-circle"
                       v-if="ivr_step.valid"></i>
                    <i class="fa fa-times-circle"
                       v-else></i>
                    {{ ivr_step.valid ? 'Valid step' : 'Invalid step' }}
                </span>
      </div>
    </div>
  </el-form>
</template>

<script>
import auth from '../auth'
import {acl_mixin, form_validation_mixin} from '../mixins'
import {mapState} from 'vuex'
import CampaignSelector from './campaign-selector.vue'
import IvrSelector from './ivr-selector'
import RingGroupSelector from './ring-group-selector'
import TagSelector from './tag-selector.vue'
import UserSelector from './user-selector.vue'
import VariableDialog from './variable-dialog'
import WorkflowSelector from './workflow-selector.vue'
import * as CampaignCallRouterBehavior from '../constants/campaign-call-router-behaviors'
import * as TagCategory from '../constants/tag-categories'
import _ from 'lodash'

export default {
  mixins: [
    acl_mixin,
    form_validation_mixin
  ],

  components: {CampaignSelector, IvrSelector, RingGroupSelector, TagSelector, UserSelector, VariableDialog, WorkflowSelector},

  props: {
    campaign: {
      required: true
    },

    ivr_step: {
      required: true
    },

    used_keys: {
      required: true
    }
  },

  data() {
    return {
      auth: auth,
      keys: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*'],
      has_extra: false,
      has_say: false,
      is_say: true,
      message_options: [
        {
          id: 1,
          name: 'Say',
          value: true
        },
        {
          id: 2,
          name: 'Play',
          value: false
        }
      ],
      tasks: [
        {
          id: 1,
          name: 'Route to other line',
          value: 'route_to_other_campaign'
        },
        {
          id: 2,
          name: 'Set to DNC and hangup call',
          value: 'dnc'
        },
        {
          id: 3,
          name: 'Enroll to sequence',
          value: 'enroll_to_workflow'
        },
        {
          id: 4,
          name: 'Send to voicemail',
          value: 'voicemail'
        },
        {
          id: 5,
          name: 'Hangup call',
          value: 'hangup'
        },
        {
          id: 6,
          name: 'Route to ring group',
          value: 'route_to_ring_group'
        },
        {
          id: 7,
          name: 'Route to user',
          value: 'route_to_user',
        },
        {
          id: 8,
          name: 'Replay IVR prompt',
          value: 'replay',
        },
        {
          id: 9,
          name: 'Play extension directory',
          value: 'employee_directory',
        },
        {
          id: 10,
          name: 'Route to another IVR',
          value: 'route_to_another_ivr'
        }
      ],
      exits: [
        {
          id: 1,
          name: 'Route to other line',
          value: 'route_to_other_campaign'
        },
        {
          id: 2,
          name: 'Send to voicemail',
          value: 'voicemail'
        },
        {
          id: 3,
          name: 'Hangup call',
          value: 'hangup'
        },
        {
          id: 4,
          name: 'Route to ring group',
          value: 'route_to_ring_group'
        },
        {
          id: 5,
          name: 'Route to user',
          value: 'route_to_user',
        },
        {
          id: 6,
          name: 'Replay IVR prompt',
          value: 'replay',
        }
      ],
      rules: {
        key: [
          {
            required: true,
            message: 'Please select a key',
            trigger: 'change'
          }
        ],
        message_tts: [
          {
            required: true,
            message: 'Please provide a message',
            trigger: 'change'
          }
        ],
        message_file: [
          {
            required: true,
            message: 'Please upload a file',
            trigger: 'change'
          }
        ],
        task: [
          {
            required: true,
            message: 'Please select a task',
            trigger: 'change'
          }
        ],
        reroute_to_campaign_id: [
          {
            required: true,
            message: 'Please select a line',
            trigger: 'change'
          }
        ],
        reroute_to_ivr_id: [
          {
            required: true,
            message: 'Please select an IVR',
            trigger: 'change'
          }
        ],
        route_to_ring_group_id: [
          {
            required: true,
            message: 'Please select a ring group',
            trigger: 'change'
          }
        ],
        route_to_user_id: [
          {
            required: true,
            message: 'Please select a user',
            trigger: 'change'
          }
        ],
        workflow_ids: [
          {
            type: 'array',
            required: true,
            message: 'Please select a sequence',
            trigger: 'change'
          },
        ],
        tag_ids: [
          {
            type: 'array',
            required: true,
            message: 'Please select a tag',
            trigger: 'change'
          }
        ]
      },
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + auth.user.profile.api_token,
        'X-Socket-Id': window.Echo.socketId()
      },
      uploadPercentage: {
        upload: 0
      },
      uploadStatus: {
        upload: 'success'
      },
      uploadFileList: {
        upload: []
      },
      editingRingGroup: null,
      loading_delete: false,
      loading_upload: false,
      pipelines: [],
      CampaignCallRouterBehavior,
      TagCategory
    }
  },

  computed: {
    ...mapState(['ring_groups']),
    ...mapState('cache', ['current_company']),

    availableKeys() {
      if (this.used_keys) {
        return this.keys.filter(key => !this.used_keys.includes(key))
      }

      return this.keys
    },

    tasksAlphabeticalOrder() {
      if (this.tasks) {
        let tasks = _.clone(this.tasks)
        return tasks.sort((a, b) => {
          let textA = a.name.toUpperCase()
          let textB = b.name.toUpperCase()
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        })
      }

      return []
    },

    exitsAlphabeticalOrder() {
      if (this.exits) {
        let exits = _.clone(this.exits)
        return exits.sort((a, b) => {
          let textA = a.name.toUpperCase()
          let textB = b.name.toUpperCase()
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        })
      }

      return []
    },

    showHubSpotOptions() {
      return this.current_company && this.current_company.hubspot_integration_enabled
    }
  },

  created() {
    if ((this.ivr_step.workflow_ids && this.ivr_step.workflow_ids.length > 0) || (this.ivr_step.tag_ids && this.ivr_step.tag_ids.length > 0)) {
      this.has_extra = true
    }

    if (this.ivr_step.message_tts || this.ivr_step.message_file) {
      this.has_say = true
      if (this.ivr_step.message_file) {
        this.is_say = false
      }
    } else {
      this.updateHasSay()
    }

    if (this.ivr_step.route_to_ring_group_id) {
      this.editingRingGroup = this.ring_groups.find(ring_group => ring_group.id == this.ivr_step.route_to_ring_group_id)
    }

    if (this.ivr_step.listen_for_extension) {
      this.ivr_step.valid = true
    }

    if (this.showHubSpotOptions) {
      this.tasks.push({
        id: 11,
        name: 'Route to HubSpot Deal/Contact Owner',
        value: 'route_to_hs_deal_contact_owner'
      })
      this.rules.route_to_hs_deal_contact_owner = [
        {
          required: true,
          message: 'Please select a pipeline',
          trigger: 'change'
        }
      ]
      this.rules.fallback_ring_group_id = [
        {
          required: true,
          message: 'Please select a ring group',
          trigger: 'change'
        }
      ]
        this.loadPipelines()
    }

    this.preValidateForm('ivr_step_form')
  },

  methods: {
    resetSettings() {
      this.ivr_step.message_tts = null
      this.ivr_step.message_file = null
      this.ivr_step.reroute_to_campaign_id = null
      this.ivr_step.reroute_to_ivr_id = null
      this.ivr_step.route_to_ring_group_id = null
      this.ivr_step.route_to_ring_group_id = null
      this.ivr_step.route_to_user_id = null
      this.ivr_step.call_router_behavior = null
      this.ivr_step.workflow_ids = []
    },

    clearExtra() {
      this.has_extra = false
      this.ivr_step.tag_ids = []
      this.preValidateForm('ivr_step_form')
    },

    updateHasSay() {
      if (['enroll_to_workflow', 'dnc', 'hangup', 'voicemail'].includes(this.ivr_step.task)) {
        this.has_say = true
      } else {
        this.has_say = false
      }
    },

      loadPipelines() {
          if (this.showHubSpotOptions && this.ivr_step.task === 'route_to_hs_deal_contact_owner') {
              axios.get('/api/v1/integration/hubspot/deal-pipelines')
                  .then(response => {
                      this.pipelines = response?.data?.pipelines ?? []
                  })
                  .catch(error => {
                      this.$root.handleErrors(error.response)
                  });
          }
      },

    changeIvrTask() {
      this.resetSettings()
      this.updateHasSay()
      this.clearExtra()
      this.loadPipelines()
    },

    changeRerouteToCampaign(id) {
      this.ivr_step.reroute_to_campaign_id = id
      this.preValidateForm('ivr_step_form')
    },

    changeRerouteToIvr(id) {
      this.ivr_step.reroute_to_ivr_id = id
      this.preValidateForm('ivr_step_form')
    },

    changeRouteToRingGroup(id) {
      this.ivr_step.route_to_ring_group_id = id
      if (this.ivr_step.route_to_ring_group_id) {
        this.editingRingGroup = this.ring_groups.find(ring_group => ring_group.id === this.ivr_step.route_to_ring_group_id)
      } else {
        this.editingRingGroup = null
      }
      this.preValidateForm('ivr_step_form')
    },

    changeFallbackRingGroup(id) {
      this.ivr_step.fallback_ring_group_id = id
      this.preValidateForm('ivr_step_form')
    },

    changeRouteToUser(id) {
      this.ivr_step.route_to_user_id = id
      this.preValidateForm('ivr_step_form')
    },

    changeTags(event) {
      this.ivr_step.tag_ids = event
      this.manualValidateForm()
    },

    changeWorkflows(event) {
      this.ivr_step.workflow_ids = event
      this.manualValidateForm()
    },

    manualValidateForm() {
      if (this.validateForm('ivr_step_form')) {
        this.ivr_step.valid = true
      } else {
        this.ivr_step.valid = false
      }
    },

    onSuccessMessage(res, file) {
      this.$notify({
        offset: 95,
        title: 'Campaign',
        message: 'IVR file uploaded successfully.',
        type: 'success',
        showClose: true,
      })
      this.ivr_step.message_file = res.file_name
      this.uploadStatus.message = 'success'
      this.loading_upload = false
      setTimeout(() => {
        this.preValidateForm('ivr_step_form')
      }, 200)
    },

    onFailedMessage(err) {
      this.handleUploadErrors(err.message)
      this.uploadStatus.message = 'exception'
      this.uploadPercentage.message = 0
      this.loading_upload = false
      this.preValidateForm('ivr_step_form')
    },

    handleUploadErrors(error) {
      if (typeof error === 'string') {
        error = JSON.parse(error)
      }
      let err
      if (error.message === 'This action is unauthorized.') {
        err = {
          status: 403,
        }
      } else {
        err = {
          status: 422,
          data: {
            errors: error.errors.file
          }
        }
      }

      this.$root.handleErrors(err)
    },

    beforeUploadMessage() {
      this.loading_upload = true
      this.uploadStatus.message = 'success'
      this.uploadPercentage.message = 0
    },

    progressUploadMessage(event) {
      this.uploadPercentage.message = parseInt(event.percent)
    },

    applyUploadedAudio(data) {
      this.$set(this.ivr_step, data.upload_type, null)
      setTimeout(() => {
        this.$set(this.ivr_step, data.upload_type, data.file_name)
        this.$notify({
          offset: 95,
          title: 'IVR',
          message: 'IVR file uploaded successfully.',
          type: 'success',
          showClose: true,
        })
        this.preValidateForm('ivr_step_form')
      }, 100)
    },

    deleteFile(type) {
      this.loading_delete = true
      axios.delete('/api/v1/campaign/' + this.campaign.id + '/delete/' + type)
        .then(res => {
          let message = 'IVR prompt file deleted successfully'
          this.uploadPercentage.upload = 0
          this.uploadStatus.upload = 'success'
          this.uploadFileList.upload = []
          this.loading_delete = false
          this.ivr_step.message_file = null
          this.$notify({
            offset: 95,
            title: 'Line',
            message: message,
            type: 'success',
            showClose: true,
          })
          this.preValidateForm('ivr_step_form')
        }).catch(err => {
        this.loading_delete = false
        this.uploadPercentage.upload = 0
        this.$root.handleErrors(err.response)
      })
    },

    preValidateForm: _.debounce(function (form_name) {
      let form_element = _.get(this.$refs, form_name, null)

      if (!form_element) {
        return
      }

      let fields = form_element.fields
      if (fields.find((f) => f.validateState === 'validating')) {
        setTimeout(() => {
          this.preValidateForm(form_name)
        }, 100)

        return
      }

      if (this.validateForm(form_name)) {
        this.ivr_step.valid = true
        return
      }

      this.ivr_step.valid = false
    }, 100)
  }
}
</script>
