var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body pb-5", attrs: { id: "view" } }, [
    _c(
      "div",
      {
        staticClass: "container-fluid mb-2",
        attrs: { id: "container-dashboard" },
      },
      [
        _c("div", { staticClass: "my-3" }, [
          _c(
            "div",
            { staticClass: "d-flex flex-row" },
            [
              _c(
                "el-button-group",
                { staticClass: "rounded" },
                [
                  _c("el-button", { attrs: { type: "success" } }, [
                    _vm._v(
                      "\n                        Outbound\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ name: "Inbound Dashboard" })
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Inbound\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "ml-auto", attrs: { type: "info" } },
                [_vm._v("\n                    Settings\n                ")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-row my-3 mt-4" }, [
          _c(
            "div",
            { staticClass: "d-flex flex-row" },
            [
              _c("user-selector", {
                staticClass: "button-select",
                attrs: {
                  multiple: "",
                  placeholder: "Users",
                  "collapse-tags": "",
                },
              }),
              _vm._v(" "),
              _c("filter-attribution-user", { staticClass: "mr-2" }),
              _vm._v(" "),
              _c("filter-attribution-line", { staticClass: "mr-2" }),
              _vm._v(" "),
              _c("filter-attribution-ring-group", { staticClass: "mr-2" }),
              _vm._v(" "),
              _c("filter-attribution-phone-number", { staticClass: "mr-2" }),
              _vm._v(" "),
              _c("filter-attribution-sequence", { staticClass: "mr-2" }),
              _vm._v(" "),
              _c("filter-attribution-broadcast", { staticClass: "mr-2" }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-auto d-flex flex-row" },
            [_c("date-range-selector", { attrs: { filter: _vm.filter } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c("outbound-dashboard"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }