<template>
    <div class="flex-fill">
        <div class="dropdown"
             id="advanced_filters"
             ref="advanced_filters_desktop"
             data-testid="advanced-filters-dropdown"
             v-show="checkSize()">
            <comm-advanced-filters ref="filters"
                                   :campaign_id="campaign_id"
                                   :workflow_id="workflow_id"
                                   :broadcast_id="broadcast_id"
                                   :ring_group_id="ring_group_id"
                                   :user_id="user_id"
                                   :team_id="team_id"
                                   :tag_id="tag_id"
                                   :call_disposition_id="call_disposition_id"
                                   :only_date_filter="only_date_filter"
                                   :report_category="report_category"
                                   :no_exports="no_exports">
            </comm-advanced-filters>
        </div>
        <el-popover placement="bottom-end"
                    width="100%"
                    trigger="click"
                    data-testid="advanced-filters-wrapper-popover"
                    v-if="!checkSize()"
                    @show="popover = true"
                    @hide="popover = false">
            <div ref="advanced_filters_mobile"></div>
            <el-button slot="reference"
                       size="mini"
                       type="primary"
                       class="pull-right mt-1"
                       icon="el-icon-more-outline"
                       data-testid="advanced-filters-button"
                       plain>
            </el-button>
        </el-popover>
    </div>
</template>

<script>
    import auth from '../auth'
    import {mapGetters, mapState} from 'vuex'

    export default {
        props: {
            campaign_id: {required: false},
            workflow_id: {required: false},
            broadcast_id: {required: false},
            ring_group_id: {required: false},
            user_id: {required: false},
            team_id: {required: false},
            tag_id: {required: false},
            call_disposition_id: {required: false},
            only_campaign_selector: {
                default: false,
                type: Boolean,
                required: false
            },
            only_date_filter: {
                default: false,
                type: Boolean,
                required: false
            },
            report_category: {
                default: null,
                required: false
            },
            no_exports: {
                default: false,
                type: Boolean,
                required: false
            }
        },

        data() {
            return {
                auth: auth,
                popover: false
            }
        },

        methods: {
            checkSize() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    this.popover = false // force to hide popover
                    return true
                }
                return false
            }
        },

        watch: {
            popover(state) {
                // remove from old parent
                this.$refs.filters.$el.parentNode.removeChild(this.$refs.filters.$el)

                if (state) {
                    // move to popover body
                    this.$refs.advanced_filters_mobile.appendChild(this.$refs.filters.$el)
                } else {
                    // move to default header
                    this.$refs.advanced_filters_desktop.appendChild(this.$refs.filters.$el)
                }
            }
        }
    }
</script>
