var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "reports-activity-reporting-tab" } },
    [
      _vm.base == "Contact Dispositions"
        ? _c("reports-contact-dispositions")
        : _vm.base == "Call Dispositions"
        ? _c("reports-call-dispositions")
        : [
            _c("div", { staticClass: "row box" }, [
              _c("div", { staticClass: "col-12 box-body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center",
                  },
                  [
                    _vm.base == "Users"
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-content-end w-100" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-5 d-inline-block" },
                              [
                                _c("ring-group-selector", {
                                  attrs: {
                                    first_value: "all",
                                    label: "Ring Group:",
                                    value: "all",
                                    disabled_tooltip_content:
                                      "Ring group selector is disabled when a team is selected. Reset the team selection to enable it.",
                                    disabled:
                                      _vm.selected_team_id !==
                                        _vm.DEFAULT_SELECTED_TEAM_ID ||
                                      !_vm.loaded,
                                  },
                                  on: { change: _vm.selectRingGroup },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.hasPermissionTo("list team")
                              ? _c(
                                  "div",
                                  { staticClass: "col-5 d-inline-block" },
                                  [
                                    _c("team-selector", {
                                      attrs: {
                                        label: "Team:",
                                        disabled_tooltip_content:
                                          "Team selector is disabled when a ring group is selected. Reset the ring group selection to enable it.",
                                        disabled:
                                          _vm.selected_ring_group !==
                                            _vm.DEFAULT_SELECTED_RING_GROUP ||
                                          !_vm.loaded,
                                        first_value:
                                          _vm.DEFAULT_SELECTED_TEAM_ID,
                                        value: _vm.DEFAULT_SELECTED_TEAM_ID,
                                      },
                                      on: { change: _vm.selectTeam },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.base === "CSAT"
                      ? _c("div", { staticClass: "col-3 d-inline-block" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "p-0 m-0 _500 w-100" },
                                  [_vm._v("Agents")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w-full no-select",
                                    attrs: {
                                      disabled: !_vm.loaded,
                                      max: 4,
                                      multiple: "",
                                      clearable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateReportGraph()
                                      },
                                    },
                                    model: {
                                      value: _vm.agent_ids,
                                      callback: function ($$v) {
                                        _vm.agent_ids = $$v
                                      },
                                      expression: "agent_ids",
                                    },
                                  },
                                  _vm._l(_vm.users, function (user) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: user.id,
                                        attrs: {
                                          label: user.name,
                                          value: user.id,
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v(_vm._s(user.name)),
                                        ]),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "col-3 d-inline-block" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "p-0 m-0 _500 w-100" },
                                  [_vm._v("Metric:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w-full no-select",
                                    attrs: { disabled: !_vm.loaded },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeGraphByMetric()
                                      },
                                    },
                                    model: {
                                      value: _vm.metric,
                                      callback: function ($$v) {
                                        _vm.metric = $$v
                                      },
                                      expression: "metric",
                                    },
                                  },
                                  _vm._l(_vm.metrics, function (item) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item,
                                        attrs: {
                                          label: _vm.fixMetricsLabel(item),
                                          value: item,
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v(
                                            _vm._s(_vm.fixMetricsLabel(item))
                                          ),
                                        ]),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                    _vm._v(" "),
                    _vm.isTimeMetric
                      ? _c("div", { staticClass: "col-1 d-inline-block" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "p-0 m-0 _500 w-100" },
                                  [_vm._v("Unit:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w-full no-select",
                                    attrs: { disabled: !_vm.loaded },
                                    model: {
                                      value: _vm.metric_time_mode,
                                      callback: function ($$v) {
                                        _vm.metric_time_mode = $$v
                                      },
                                      expression: "metric_time_mode",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: "Minutes",
                                        value: "minutes",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "Hours", value: "hours" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !["CSAT", "Sequences", "Broadcasts"].includes(_vm.base)
                      ? _c("div", { staticClass: "col-3 d-inline-block" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "p-0 m-0 _500 w-100" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.baseSingular) + " Filter:"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width-150 no-select",
                                    attrs: {
                                      disabled:
                                        !_vm.loaded ||
                                        _vm.shouldDisableDueTeamAndUserFilterCombination,
                                    },
                                    on: { change: _vm.updateReportGraph },
                                    model: {
                                      value: _vm.overview_base_filter,
                                      callback: function ($$v) {
                                        _vm.overview_base_filter = $$v
                                      },
                                      expression: "overview_base_filter",
                                    },
                                  },
                                  _vm._l(
                                    _vm.overview_base_filters,
                                    function (item) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item + "-baseline_filter",
                                          attrs: { label: item, value: item },
                                        },
                                        [
                                          _c("span", { staticClass: "ml-2" }, [
                                            _vm._v(_vm._s(item)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.base === "CSAT"
                      ? _c("div", { staticClass: "col-2 d-inline-block" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "p-0 m-0 _500 w-100" },
                                  [_vm._v("CSAT Score:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w-full no-select",
                                    attrs: {
                                      disabled: !_vm.loaded,
                                      clearable: "",
                                    },
                                    on: { change: _vm.updateReportGraph },
                                    model: {
                                      value: _vm.score_filter,
                                      callback: function ($$v) {
                                        _vm.score_filter = $$v
                                      },
                                      expression: "score_filter",
                                    },
                                  },
                                  _vm._l(_vm.scores, function (item) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item + "-score_filter",
                                        attrs: { label: item, value: item },
                                      },
                                      [
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v(_vm._s(item)),
                                        ]),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.base === "CSAT"
                      ? _c("div", { staticClass: "col-2 d-inline-block" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "p-0 m-0 _500 w-100" },
                                  [_vm._v("Communication tag(s)")]
                                ),
                                _vm._v(" "),
                                _c("tag-selector", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    multiple: true,
                                    disabled: !_vm.loaded,
                                  },
                                  on: { change: _vm.changeTags },
                                  model: {
                                    value: _vm.tags,
                                    callback: function ($$v) {
                                      _vm.tags = $$v
                                    },
                                    expression: "tags",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                !["CSAT"].includes(_vm.base)
                  ? _c("div", { staticClass: "row mt-1 p-3" }, [
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("reports-activity-reporting-graph", {
                            key: _vm.bottom_report_key,
                            attrs: {
                              chart_type: "pie",
                              data: _vm.sortedPaginatedData,
                              data_loaded: _vm.loaded,
                              type: _vm.metric,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("reports-activity-reporting-graph", {
                            key: _vm.bottom_report_key,
                            attrs: {
                              data: _vm.sortedPaginatedData,
                              data_loaded: _vm.loaded,
                              metric_time_mode: _vm.metric_time_mode,
                              type: _vm.metric,
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.base === "CSAT"
                  ? _c("div", { staticClass: "row mt-1 p-3" }, [
                      !_vm.loadings.total_csat_ratio
                        ? _c("div", { staticClass: "col-4 mt-5" }, [
                            _vm.total_csat_ratio
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center flex-column justify-content-center",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticStyle: {
                                          "font-size": "48px",
                                          color: "#000",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.total_csat_ratio) + "%"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h3",
                                      {
                                        staticStyle: {
                                          "font-size": "28px",
                                          color: "#000",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.current_company.name) +
                                            "' CSAT Score"
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center flex-column justify-content-center",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticStyle: {
                                          "font-size": "28px",
                                          color: "#000",
                                        },
                                      },
                                      [_vm._v("No Data To Display!")]
                                    ),
                                  ]
                                ),
                          ])
                        : _c("div", { staticClass: "col-4 text-center mt-5" }, [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons loader text-greenish text-4x",
                              },
                              [_vm._v("")]
                            ),
                          ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("reports-activity-reporting-csat-graph", {
                            ref: "csat-graph",
                            attrs: {
                              chart_data: _vm.csatChartData,
                              chart_type: "column",
                              data_loaded: !_vm.loadings.csat_scores,
                              type: "data",
                            },
                            on: { clicked: _vm.pointClicked },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.base === "CSAT" && !_vm.hasCsatFilter
                  ? _c("div", { staticClass: "row mt-1 p-3" }, [
                      !_vm.loadings.csat_lowest && !_vm.loadings.csat_highest
                        ? _c("div", { staticClass: "col-4 text-center mt-5" }, [
                            _vm.csatLowestChartData.length ||
                            _vm.csatHighestChartData.length
                              ? _c("div", { staticClass: "text-center" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mb-5",
                                      staticStyle: {
                                        display: "inline-flex",
                                        gap: "15px",
                                      },
                                    },
                                    _vm._l(
                                      _vm.csatHighestChartData,
                                      function (csatData) {
                                        return _c(
                                          "div",
                                          {
                                            key: csatData.name,
                                            staticClass: "agent-csat-rate",
                                          },
                                          [
                                            _c(
                                              "el-avatar",
                                              {
                                                style: `background: ${csatData.color}; color: white; margin: auto 10px`,
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("initials")(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(csatData.name)
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    csatData.total_csat_ratio
                                                  ) + "%"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(csatData.name) +
                                                  " CSAT Score"
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "inline-flex",
                                        gap: "15px",
                                      },
                                    },
                                    _vm._l(
                                      _vm.csatLowestChartData,
                                      function (csatData) {
                                        return _c(
                                          "div",
                                          {
                                            key: csatData.name,
                                            staticClass: "agent-csat-rate",
                                          },
                                          [
                                            _c(
                                              "el-avatar",
                                              {
                                                style: `background: ${csatData.color}; color: white; margin: auto 10px`,
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("initials")(
                                                        _vm._f(
                                                          "fixContactName"
                                                        )(csatData.name)
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    csatData.total_csat_ratio
                                                  ) + "%"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(csatData.name) +
                                                  " CSAT Score"
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ])
                              : _c("div", { staticClass: "text-center" }, [
                                  _c(
                                    "h3",
                                    {
                                      staticStyle: {
                                        "font-size": "28px",
                                        color: "#000",
                                      },
                                    },
                                    [_vm._v("No Data To Display!")]
                                  ),
                                ]),
                          ])
                        : _c("div", { staticClass: "col-4 text-center mt-5" }, [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons loader text-greenish text-4x",
                              },
                              [_vm._v("")]
                            ),
                          ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c(
                            "reports-activity-reporting-csat-graph-lowest-highest",
                            {
                              ref: "csat-graph",
                              attrs: {
                                chart_data: _vm.csatLowestChartData,
                                chart_type: "column",
                                title:
                                  "Agents with <strong>lowest</strong> score rating",
                                categories: [1, 2],
                                data_loaded: !_vm.loadings.csat_lowest,
                                type: "data",
                              },
                              on: { clicked: _vm.pointClicked },
                            }
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c(
                            "reports-activity-reporting-csat-graph-lowest-highest",
                            {
                              ref: "csat-graph",
                              attrs: {
                                chart_data: _vm.csatHighestChartData,
                                chart_type: "column",
                                title:
                                  "Agents with <strong>highest</strong> score rating",
                                categories: [4, 5],
                                data_loaded: !_vm.loadings.csat_highest,
                                type: "data",
                              },
                              on: { clicked: _vm.pointClicked },
                            }
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 pt-3 text-right" },
                    [
                      _c(
                        "div",
                        { staticClass: "dropdown d-inline-block mr-3" },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle",
                              attrs: {
                                "data-toggle": "dropdown",
                                "data-flip": "false",
                                "aria-expanded": "false",
                              },
                              on: { click: _vm.checkBlur },
                            },
                            [
                              _vm._v(
                                "\n                                Table Settings "
                              ),
                              _c("i", { staticClass: "el-icon-arrow-down" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu dropdown-menu-right",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "filter-item p-3 py-1" },
                                [
                                  _c("li", [_c("b", [_vm._v("Columns:")])]),
                                  _vm._v(" "),
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: { change: _vm.changeTableFields },
                                      model: {
                                        value: _vm.checked_table_fields,
                                        callback: function ($$v) {
                                          _vm.checked_table_fields = $$v
                                        },
                                        expression: "checked_table_fields",
                                      },
                                    },
                                    _vm._l(_vm.table_fields, function (field) {
                                      return _c(
                                        "li",
                                        {
                                          key: field.text,
                                          on: {
                                            click: function ($event) {
                                              return _vm.noClose($event)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            { attrs: { label: field.val } },
                                            [_vm._v(_vm._s(field.text))]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.hasRole("Company Admin")
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "dropdown dropdown-toggle-caret-none d-inline-block mr-3",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle",
                                  attrs: {
                                    "data-testid":
                                      "comm-advanced-filters-toggle-export-menu-button",
                                    disabled:
                                      !_vm.loaded || _vm.base === "CSAT",
                                  },
                                  on: { click: _vm.toggleExportsMenu },
                                },
                                [
                                  _vm._v(
                                    "\n                                Export "
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-arrow-down",
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "dropdown-menu dropdown-menu-right",
                                  class: _vm.exports_menu_visible ? "show" : "",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        "data-testid":
                                          "comm-advanced-filters-cvs-current-view-link",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportCurrentViewToCsv()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-table" }),
                                      _vm._v(
                                        "\n                                    Export Current View\n                                    "
                                      ),
                                      _vm.exportingData
                                        ? _c("i", {
                                            staticClass:
                                              "fa fa-spinner fa-spin",
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        "data-testid":
                                          "comm-advanced-filters-cvs-all-columns-link",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportData("csv")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-table" }),
                                      _vm._v(
                                        "\n                                    Export All\n                                    "
                                      ),
                                      _vm.exportingData
                                        ? _c("i", {
                                            staticClass:
                                              "fa fa-spinner fa-spin",
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasRole("Company Admin")
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: !_vm.loaded || _vm.base === "CSAT",
                                type: "primary",
                              },
                              on: { click: _vm.copy },
                            },
                            [
                              _vm._v(
                                "\n                            Copy Table Data\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-2 p-3" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _vm.base !== "CSAT"
                        ? _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: !_vm.loaded,
                                  expression: "!loaded",
                                },
                              ],
                              ref: "activity-reporting",
                              staticClass:
                                "w-full mt-3 activity-reporting-table",
                              attrs: {
                                data: _vm.sortedPaginatedData,
                                "default-sort": {
                                  prop: "all_activity",
                                  order: "descending",
                                },
                                fit: "",
                                stripe: "",
                                height: "70vh",
                              },
                              on: { "sort-change": _vm.sortChange },
                            },
                            [
                              _vm.base == "Users"
                                ? _c("el-table-column", {
                                    attrs: {
                                      prop: "user_id",
                                      label: "Id",
                                      fixed: "left",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(scope.row.user_id) +
                                                  "\n                                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      745762238
                                    ),
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.base == "Users"
                                ? _c("el-table-column", {
                                    attrs: {
                                      "min-width": "150",
                                      prop: "email",
                                      label: "Email",
                                      fixed: "left",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(scope.row.email) +
                                                  "\n                                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3748844657
                                    ),
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.base !== "CSAT"
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: _vm.firstColumn(),
                                      "min-width": ["Broadcasts"].includes(
                                        _vm.base
                                      )
                                        ? "auto"
                                        : 150,
                                      fixed: "left",
                                      prop: "name",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(scope.row.name) +
                                                  "\n                                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2586370842
                                    ),
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !["CSAT"].includes(_vm.base)
                                ? [
                                    _vm.showColumn("all_activity")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            "min-width":
                                              _vm.base == "Broadcasts"
                                                ? "auto"
                                                : 150,
                                            label: "All Activity",
                                            prop: "all_activity",
                                            sortable: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row.all_activity
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            524332686
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("all_calls")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "All Calls",
                                            prop: "all_calls",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row.all_calls
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1220855858
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("all_qualified_calls")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: `All Calls over ${_vm.current_company.qualified_calls_threshold1} minutes`,
                                            prop: "all_qualified_calls",
                                            sortable: "",
                                            "min-width": "130",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .all_qualified_calls
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            916987011
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("all_missed_calls")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "All Missed Calls",
                                            prop: "all_missed_calls",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .all_missed_calls
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2586379688
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("all_abandoned_calls")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "All Abandoned Calls",
                                            prop: "all_abandoned_calls",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .all_abandoned_calls
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2259140357
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("inbound_calls")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Inbound Calls",
                                            prop: "inbound_calls",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .inbound_calls
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1222952966
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("qualified_inbound_calls")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: `Inbound calls over ${_vm.current_company.qualified_calls_threshold1} minutes`,
                                            prop: "qualified_inbound_calls",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .qualified_inbound_calls
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1813554615
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("inbound_answered_calls")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Inbound Answered Calls",
                                            prop: "inbound_answered_calls",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .inbound_answered_calls
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2222693604
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("inbound_missed_calls")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Inbound Missed Calls",
                                            prop: "inbound_missed_calls",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .inbound_missed_calls
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1399371804
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("missed_calls_percent")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "All Missed Call %",
                                            prop: "missed_calls_percent",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .missed_calls_percent
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1885671346
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("outbound_calls")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Outbound Calls",
                                            prop: "outbound_calls",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .outbound_calls
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1336444751
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("qualified_outbound_calls")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: `Outbound calls over ${_vm.current_company.qualified_calls_threshold1} minutes`,
                                            prop: "qualified_outbound_calls",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .qualified_outbound_calls
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1027994622
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn(
                                      "qualified_outbound_calls_percent"
                                    )
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Qualified Outbound Calls %",
                                            prop: "qualified_outbound_calls_percent",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .qualified_outbound_calls_percent
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2905540218
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("average_duration")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Average Duration",
                                            prop: "average_duration",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixFullLongDuration"
                                                          )(
                                                            scope.row
                                                              .average_duration
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3018136598
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("total_duration")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Total Duration",
                                            prop: "total_duration",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixFullLongDuration"
                                                          )(
                                                            scope.row
                                                              .total_duration
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4025887063
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("average_talk_time")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Average Talk Time",
                                            prop: "average_talk_time",
                                            sortable: "",
                                            "min-width": "130",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixFullLongDuration"
                                                          )(
                                                            scope.row
                                                              .average_talk_time
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            34177488
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("total_talk_time")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Total Talk Time",
                                            prop: "total_talk_time",
                                            sortable: "",
                                            width: "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixFullLongDuration"
                                                          )(
                                                            scope.row
                                                              .total_talk_time
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            683930897
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("average_wait_time")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Average Wait Time",
                                            prop: "average_wait_time",
                                            sortable: "",
                                            "min-width": "130",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixFullLongDuration"
                                                          )(
                                                            scope.row
                                                              .average_wait_time
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3626861257
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("total_wait_time")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Total Wait Time",
                                            prop: "total_wait_time",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "fixFullLongDuration"
                                                          )(
                                                            scope.row
                                                              .total_wait_time
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1120956296
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("all_text_messages")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "All Text Messages",
                                            prop: "all_text_messages",
                                            sortable: "",
                                            "min-width": "130",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .all_text_messages
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1413979065
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("inbound_text_messages")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Inbound Text Messages",
                                            prop: "inbound_text_messages",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .inbound_text_messages
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4283074637
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("outbound_text_messages")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Outbound Text Messages",
                                            prop: "outbound_text_messages",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .outbound_text_messages
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1656094180
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("all_emails")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "All Emails",
                                            prop: "all_emails",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row.all_emails
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3420247868
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("inbound_emails")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Inbound Emails",
                                            prop: "inbound_emails",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .inbound_emails
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            163712648
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("outbound_emails")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Outbound Emails",
                                            prop: "outbound_emails",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .outbound_emails
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            164409857
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("all_faxes")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "All Faxes",
                                            prop: "all_faxes",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row.all_faxes
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2896148170
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("inbound_faxes")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Inbound Faxes",
                                            prop: "inbound_faxes",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .inbound_faxes
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            757780798
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("outbound_faxes")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Outbound Faxes",
                                            prop: "outbound_faxes",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .outbound_faxes
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            168985783
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("appointments")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Appointments",
                                            prop: "appointments",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row.appointments
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            981870913
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("reminders")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Reminders",
                                            prop: "reminders",
                                            sortable: "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row.reminders
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3081969920
                                          ),
                                        })
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.base == "Broadcasts"
                                ? [
                                    _vm.showColumn("text_messages")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Text Messages",
                                            prop: "text_messages",
                                            sortable: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .text_messages
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            401934567
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("ringless_voicemails")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "Ringless Voicemails",
                                            prop: "ringless_voicemails",
                                            sortable: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row
                                                            .ringless_voicemails
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            654425525
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showColumn("failed")
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "failed",
                                            sortable: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "header",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        Failed\n                                        "
                                                    ),
                                                    _c(
                                                      "el-popover",
                                                      {
                                                        attrs: {
                                                          placement: "right",
                                                          width: "300",
                                                          trigger: "hover",
                                                          "popper-class": "p-2",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "lh-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Number of contacts that failed to send because phone number is a landline."
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("img", {
                                                          attrs: {
                                                            slot: "reference",
                                                            src: "/assets/images/app-icons/info-icon-outline.png",
                                                            alt: "info-icon",
                                                          },
                                                          slot: "reference",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          scope.row.failed
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3403451121
                                          ),
                                        })
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              !["agents-contacts", "lines-contacts"].includes(
                                _vm.baseline
                              )
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: "Action",
                                      "max-width": "150",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm.base == "Users" ||
                                              _vm.baseline == "agents-contacts"
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "User Activity",
                                                          params: {
                                                            user_id:
                                                              scope.row.user_id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-xs btn-block purplish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            View\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.base == "Lines" ||
                                              _vm.baseline == "lines-contacts"
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Line Activity",
                                                          params: {
                                                            campaign_id:
                                                              scope.row
                                                                .campaign_id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-xs btn-block purplish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            View\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.base == "Ring Groups"
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Ring Group Activity",
                                                          params: {
                                                            ring_group_id:
                                                              scope.row
                                                                .ring_group_id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-xs btn-block purplish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            View\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.base == "Sequences"
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Sequence Manager",
                                                          params: {
                                                            workflow_id:
                                                              scope.row
                                                                .workflow_id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-xs btn-block purplish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            View\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.base == "Broadcasts"
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Broadcast Activity",
                                                          params: {
                                                            broadcast_id:
                                                              scope.row
                                                                .broadcast_id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-xs btn-block purplish",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            View\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1985604111
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.base === "CSAT"
                        ? _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: !_vm.loaded,
                                  expression: "!loaded",
                                },
                              ],
                              ref: "activity-reporting",
                              staticClass:
                                "w-full mt-3 activity-reporting-table csat-table",
                              attrs: {
                                data: _vm.sortedPaginatedData,
                                "default-sort": {
                                  prop: "all_activity",
                                  order: "descending",
                                },
                                fit: "",
                                stripe: "",
                                height: "70vh",
                              },
                              on: {
                                "row-click": _vm.onCsatRowClick,
                                "sort-change": _vm.sortChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  "max-width": "50",
                                  prop: "user_id",
                                  fixed: "left",
                                  align: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-avatar", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getNameInitials(
                                                    scope.row.user
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2395596915
                                ),
                              }),
                              _vm._v(" "),
                              _vm.showColumn("agent")
                                ? _c("el-table-column", {
                                    attrs: {
                                      "min-width": "150",
                                      prop: "user_id",
                                      label: "Agent",
                                      fixed: "left",
                                      "header-align": "left",
                                      align: "left",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "user-name font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.user?.name)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3962911175
                                    ),
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showColumn("total_calls")
                                ? _c("el-table-column", {
                                    attrs: {
                                      "max-width": "200",
                                      prop: "total_calls",
                                      label: "Total Calls",
                                      fixed: "left",
                                      "header-align": "center",
                                      align: "center",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.total_calls
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1977027578
                                    ),
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showColumn("csat_scores")
                                ? _c("el-table-column", {
                                    attrs: {
                                      "min-width": "200",
                                      prop: "csat_scores",
                                      label: "CSAT Scores",
                                      fixed: "left",
                                      "header-align": "center",
                                      align: "center",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "calls-badges d-flex align-items-center justify-content-center",
                                                },
                                                [
                                                  !_vm.score_filter ||
                                                  _vm.score_filter == 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "calls-badge",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "box bg-red",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v("1"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .score_calls[0]
                                                              ) + " calls"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.score_filter ||
                                                  _vm.score_filter == 2
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "calls-badge",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "box bg-orange",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v("2"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .score_calls[1]
                                                              ) + " calls"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.score_filter ||
                                                  _vm.score_filter == 3
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "calls-badge",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "box bg-blue",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v("3"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .score_calls[2]
                                                              ) + " calls"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.score_filter ||
                                                  _vm.score_filter == 4
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "calls-badge",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "box bg-purple",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v("4"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .score_calls[3]
                                                              ) + " calls"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.score_filter ||
                                                  _vm.score_filter == 5
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "calls-badge",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "box bg-green",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v("5"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .score_calls[4]
                                                              ) + " calls"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3143016975
                                    ),
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showColumn("csat_ratio")
                                ? _c("el-table-column", {
                                    attrs: {
                                      "min-width": "150",
                                      prop: "csat_ratio",
                                      label: "CSAT",
                                      fixed: "left",
                                      "header-align": "center",
                                      align: "center",
                                      sortable: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.csat_ratio
                                                    ) + "%"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2956007171
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c("el-pagination", {
                            staticClass: "pull-right",
                            attrs: {
                              "page-size": _vm.per_page,
                              "page-sizes": [10, 20, 50, 100, 250],
                              total: _vm.total,
                              background: "",
                              layout: "sizes, prev, pager, next",
                            },
                            on: {
                              "current-change": _vm.changePage,
                              "size-change": _vm.handleSizeChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }