var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.communication && _vm.communication.contact
    ? _c(
        "div",
        [
          (_vm.communication.type === _vm.CommunicationTypes.CALL &&
            !_vm.communication.user_id) ||
          _vm.communication.type !== _vm.CommunicationTypes.CALL
            ? _c(
                "p",
                [
                  _vm.communication.type === _vm.CommunicationTypes.CALL
                    ? [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getSource(_vm.communication)) +
                            " "
                        ),
                        _c("br"),
                      ]
                    : _vm._e(),
                  _vm._v(
                    "\n        You have " +
                      _vm._s(
                        _vm.getSource(_vm.communication) ? "an" : "a new"
                      ) +
                      " " +
                      _vm._s(_vm.communicationType) +
                      " from\n        "
                  ),
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        _vm._f("fixContactName")(
                          _vm.decodeHTML(_vm.communication.contact.name)
                        )
                      ) +
                        " " +
                        _vm._s(
                          _vm._f("fixPhone")(
                            _vm.communication.contact.phone_number
                          )
                        )
                    ),
                  ]),
                  _vm._v("\n        on\n        "),
                  _vm.getCampaign(_vm.communication.campaign_id)
                    ? _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.getCampaign(_vm.communication.campaign_id).name
                          )
                        ),
                      ])
                    : _vm._e(),
                  _vm._v("\n        line.\n    "),
                ],
                2
              )
            : _vm.communication.type === _vm.CommunicationTypes.CALL &&
              _vm.communication.user_id
            ? _c("p", [
                _vm._v(
                  "\n        " + _vm._s(_vm.getSource(_vm.communication)) + " "
                ),
                _c("br"),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.communicationType) +
                    " from\n        "
                ),
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm._f("fixContactName")(
                        _vm.decodeHTML(_vm.communication.contact.name)
                      )
                    ) +
                      " " +
                      _vm._s(
                        _vm._f("fixPhone")(
                          _vm.communication.contact.phone_number
                        )
                      )
                  ),
                ]),
                _vm._v("\n        on\n        "),
                _vm.getCampaign(_vm.communication.campaign_id)
                  ? _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.getCampaign(_vm.communication.campaign_id).name
                        )
                      ),
                    ])
                  : _vm._e(),
                _vm._v("\n        line has been answered by\n        "),
                _vm.getUser(_vm.communication.user_id)
                  ? _c("b", [
                      _vm._v(
                        _vm._s(_vm.getUser(_vm.communication.user_id).name) +
                          "."
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.communication.type == _vm.CommunicationTypes.SMS &&
          _vm.communication.attachments &&
          _vm.communication.attachments.length > 0
            ? [_vm._v("\n        Inbound MMS\n    ")]
            : _vm._e(),
          _vm._v(" "),
          _vm.communication.type == _vm.CommunicationTypes.SMS
            ? _c("div", { staticClass: "mt-2" }, [
                _c("p", { staticClass: "mt-3 mb-0 _400 break-word" }, [
                  _vm._v(_vm._s(_vm.decodeHTML(_vm.communication.body ?? ""))),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.canAnswerCommunication(_vm.communication) &&
          _vm.communication.company_id != 585
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _vm.on_call
                    ? [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn rounded deep-orange btn-hold-answer btn-xs pull-right",
                          },
                          [
                            _vm._v(
                              "\n                Park & Answer\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn rounded greenish btn-hangup-answer btn-xs mr-2 pull-right",
                          },
                          [
                            _vm._v(
                              "\n                Hang Up & Answer\n            "
                            ),
                          ]
                        ),
                      ]
                    : _c(
                        "button",
                        {
                          staticClass:
                            "btn rounded greenish btn-answer btn-xs pull-right",
                        },
                        [_vm._v("\n            Answer\n        ")]
                      ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn rounded danger btn-reject btn-xs mr-2 pull-right",
                    },
                    [_vm._v("\n            Ignore\n        ")]
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "btn rounded greenish btn-reply btn-sm pull-right" },
      [
        _c("i", { staticClass: "material-icons" }, [_vm._v("reply")]),
        _vm._v("\n            Reply\n        "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }