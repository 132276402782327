<template>
    <div class="row nav-padding">
        <el-dialog ref="dialog_add_lead_source"
                   title="Add a lead source"
                   width="20rem"
                   append-to-body
                   :visible="add_lead_source_form.show"
                   :before-close="resetAddLeadSourceForm">
            <el-form ref="add_lead_source_form"
                     class="d-flex flex-column p-2"
                     v-loading="loading"
                     label-position="top"
                     :model="add_lead_source_form"
                     :rules="rules"
                     @submit.prevent.native="preventSubmit">
                <el-form-item label="Lead Source"
                              prop="lead_source">
                    <el-input placeholder="Lead source name"
                              v-model="add_lead_source_form.lead_source"
                              @input="preValidateForm('add_lead_source_form')">
                    </el-input>
                </el-form-item>
                <div class="d-flex flex-row justify-content-end pt-2">
                    <el-button type="info"
                               round
                               @click="resetAddLeadSourceForm">
                        <span class="fa fa-close"></span>
                        <span>Cancel</span>
                    </el-button>
                    <el-button type="success"
                               round
                               :disabled="!validated"
                               @click="saveLeadSource">
                        Add
                    </el-button>
                </div>
            </el-form>
        </el-dialog>
        <div class="col-12">
            <div class="pull-left font-120">
                <strong>{{ leadSources.length }}</strong>
                {{  leadSources.length > 1 ? 'Lead sources' : 'Lead source' }}
            </div>
            <div class="pull-right">
                <button class="btn btn-md rounded greenish mb-2"
                        @click="add_lead_source_form.show = true">
                    <i class="fa fa-plus"></i>
                    Add Lead Source
                </button>
            </div>
        </div>
        <el-table style="min-width: 100%"
                  :data="paginatedData">
            <el-table-column prop="id"
                             label="Id"
                             width="60">
                <template v-slot="scope">
                    <span class="text-greyish">{{ scope.row.id }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="name"
                             label="Name">
                <template v-slot="scope">
                    <span class="text-greyish">
                        {{ scope.row.name }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="contacts_count"
                             label="Number Of Contacts"
                             align="right"
                             sortable>
                <template v-slot="scope">
                    <button class="btn b-bluish text-bluish btn-sm"
                            @click="showContacts(scope.row.id)">
                        Show Contacts
                        <b class="label rounded danger m-l-xs">{{ scope.row.contacts_count || 0 }}</b>
                    </button>
                </template>
            </el-table-column>
            <el-table-column padding="0"
                             width="60">
                <template v-slot="scope">
                    <el-dropdown trigger="click">
                        <el-button size="small">
                            <i class="fa fa-ellipsis-v"></i>
                        </el-button>
                        <template v-slot:dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-if="hasPermissionTo('update lead source')"
                                                  @click.native="edit(scope.row)">
                                    <i class="fa fa-edit"></i>
                                    Edit
                                </el-dropdown-item>
                                <el-dropdown-item v-if="hasPermissionTo('delete lead source')"
                                                  @click.native="archiveLeadSource(scope.row.id)">
                                    <i v-if="loading_delete"
                                       class="fa fa-spin fa-spinner"></i>
                                    <i v-else
                                       class="fa fa-trash"></i>
                                    Delete
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <div class="py-4 clearfix w-100">
            <el-pagination :page-count="page_count"
                           :page-size="per_page"
                           :page-sizes="[10, 20, 30, 50]"
                           :total="pageTotal"
                           @current-change="changePage"
                           @size-change="handleSizeChange"
                           background
                           class="pull-right"
                           layout="sizes, prev, pager, next">
            </el-pagination>
        </div>
        <lead-source-dialog ref="leadSourceDialog"
                            :leadSource="lead_source"/>
    </div>
</template>

<script>
import auth from '../../../auth'
import {
    acl_mixin,
    form_validation_mixin
} from '../../../mixins'
import {mapActions, mapGetters} from 'vuex'
import LeadSourceDialog from './lead-source-dialog'

export default {
    name: 'lead-sources-list',

    mixins: [
        acl_mixin,
        form_validation_mixin
    ],

    components: {LeadSourceDialog},

    data () {
        return {
            auth: auth,
            loading: false,
            loading_delete: false,
            lead_source: null,
            per_page: 20,
            page_count: 1,
            current_page: 1,
            add_lead_source_form: {
                show: false,
                lead_source: ''
            },
            rules: {
                lead_source : [
                    {
                        required: true,
                        message: 'Please enter a lead source name',
                        trigger: 'blur'
                    }
                ],
            }
        }
    },

    computed: {
        ...mapGetters({
            leadSources: 'getLeadSources',
        }),

        pageTotal() {
            return this.leadSources.length
        },

        paginatedData() {
            let data = [...this.leadSources]
            if (data) {
                this.page_count = Math.ceil(data.length / this.per_page)
                let page_number = this.current_page - 1
                data = data.slice(page_number * this.per_page, (page_number + 1) * this.per_page)
                return data
            }

            return []
        },
    },

    methods: {
        handleSizeChange(per_page) {
            this.per_page = per_page
        },

        edit(lead_source) {
            this.lead_source = lead_source
            this.$refs.leadSourceDialog.edit()
        },

        clearEdit() {
            this.lead_source = null
        },

        archiveLeadSource(lead_source_id) {
            this.$confirm('This operation will remove the lead source. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.loading_delete = true
                axios.delete('/api/v1/lead-sources/' + lead_source_id)
                    .then(res => {
                        this.loading_delete = false
                        this.deleteLeadSource(lead_source_id)
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_delete = false
                    })
            }).catch(() => {
                // delete canceled
            })
        },

        changePage(page) {
            this.current_page = page
        },

        showContacts(lead_source_id) {
            this.$router.push({
                name: 'Contacts',
                query: {
                    lead_source_id: lead_source_id
                }
            }).catch(err => {
            })
        },

        resetAddLeadSourceForm() {
            this.add_lead_source_form = {
                show: false,
                name: ''
            }
            this.preValidateForm('add_lead_source_form', true)
        },

        saveLeadSource() {
            if (!this.validateForm('add_lead_source_form')) {
                this.preValidateForm('add_lead_source_form')
                return
            }

            this.loading = true
            axios.post('api/v1/lead-sources', this.add_lead_source_form)
                .then(res => {
                    this.loading = false

                    this.resetAddLeadSourceForm()
                    this.addLeadSource(res.data.lead_source)

                    this.$notify({
                        offset: 95,
                        title: 'Lead Source',
                        message: res.data.message,
                        type: 'success',
                        showClose: true
                    })
                })
                .catch(err => {
                    this.loading = false
                    this.$root.handleErrors(err.response)
                })
        },

        preventSubmit() {
            return
        },

        ...mapActions(['addLeadSource', 'deleteLeadSource'])
    },

    watch: {
        'add_lead_source_form.lead_source': function() {
            this.preValidateForm('add_lead_source_form')
        }
    }
}
</script>
