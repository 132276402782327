var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: { placement: "right", width: "450", trigger: "click" },
      on: { hide: _vm.reset },
      model: {
        value: _vm.show_popover,
        callback: function ($$v) {
          _vm.show_popover = $$v
        },
        expression: "show_popover",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "container-listing rounded p-2",
        },
        [
          _c("div", { staticClass: "container-header d-flex" }, [
            _c("h5", { staticClass: "listing-heading" }, [
              _vm._v("Enroll To Sequence"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-auto" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn-dialog-close",
                    attrs: {
                      type: "text",
                      size: "large",
                      "data-testid": "close-popover",
                    },
                    on: {
                      click: function ($event) {
                        _vm.show_popover = false
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-remove" })]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container-body" },
            [
              _c("p", [_vm._v(_vm._s(_vm.helperText))]),
              _vm._v(" "),
              _c("workflow-selector", {
                staticClass: "mb-2",
                attrs: { multiple: false, "data-testid": "workflow-selector" },
                on: { change: _vm.workflowChanged },
                model: {
                  value: _vm.selected_workflow_id,
                  callback: function ($$v) {
                    _vm.selected_workflow_id = $$v
                  },
                  expression: "selected_workflow_id",
                },
              }),
              _vm._v(" "),
              _vm.model !== "contact"
                ? _c("div", { staticClass: "mt-4" }, [
                    _c("ul", { staticClass: "list-unstyled" }, [
                      _c("li", { staticClass: "mb-2" }, [
                        _c("div", { staticClass: "d-flex flex-row" }, [
                          _c("div", { staticClass: "mr-2" }, [
                            _c("i", {
                              staticClass: "fa fa-info-circle text-warning",
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n                                Contacts that are already enrolled in a sequence will not be enrolled.\n                            "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "mb-2" }, [
                        _c("div", { staticClass: "d-flex flex-row" }, [
                          _c("div", { staticClass: "mr-2" }, [
                            _c("i", {
                              staticClass: "fa fa-info-circle text-warning",
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n                                Contacts the has a DNC status will not be enrolled.\n                            "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "mb-2" }, [
                        _c("div", { staticClass: "d-flex flex-row" }, [
                          _c("div", { staticClass: "mr-2" }, [
                            _c("i", {
                              staticClass: "fa fa-info-circle text-warning",
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n                                Contact groups that has thousands of contacts will take time to enroll.\n                            "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-4 text-center mb-3" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        disabled: _vm.disabled,
                        type: "success",
                        size: "medium",
                        round: "",
                        "data-testid": "enroll-contact-button-popover",
                      },
                      on: { click: _vm.enroll },
                    },
                    [
                      _c("strong", [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v(" Enroll Contacts\n                    "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.selected_workflow && !_vm.selected_workflow.is_enrollable
            ? _c(
                "div",
                { staticClass: "container-body text-center py-5" },
                [
                  _c("img-empty", { staticClass: "w-75 mx-auto mb-3" }),
                  _vm._v(" "),
                  _c("p", { staticClass: "lead" }, [
                    _c("i", { staticClass: "fa fa-ban text-danger" }),
                    _vm._v(" Enrollment is only allowed between "),
                    _c("br"),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.selected_workflow.converted_open)),
                    ]),
                    _vm._v("\n                and "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.selected_workflow.converted_close)),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.phone_number
        ? _c(
            "span",
            { attrs: { slot: "reference" }, slot: "reference" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "top",
                    width: "300",
                    trigger: "hover",
                    "popper-class": "p-2",
                    disabled: !_vm.isNumberInvalidOrWrong(_vm.phone_number),
                  },
                },
                [
                  _c("span", { staticClass: "text-black-dk" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getTooltipMessage(_vm.phone_number)) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { attrs: { slot: "reference" }, slot: "reference" },
                    [
                      _vm.model === "contact"
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "popover",
                                  rawName:
                                    "v-popover:contact-secuence-number-popover",
                                  arg: "contact-secuence-number-popover",
                                },
                              ],
                              staticClass: "bold text-success",
                              attrs: {
                                round: "",
                                size: "small",
                                disabled:
                                  _vm.isNumberInvalidOrWrong(
                                    _vm.phone_number
                                  ) || !_vm.isEnabledToEnroll,
                                "data-testid":
                                  "enroll-to-sequence-contact-button",
                              },
                              on: { click: _vm.showEnroller },
                            },
                            [
                              _c(
                                "strong",
                                [
                                  _c("i", { staticClass: "fa fa-plus" }),
                                  _vm._v(" "),
                                  _vm._t("default", function () {
                                    return [_vm._v("Enroll To Sequence")]
                                  }),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.model !== "contact"
                        ? _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "popover",
                                  rawName:
                                    "v-popover:tag-secuence-number-popover",
                                  arg: "tag-secuence-number-popover",
                                },
                              ],
                              staticClass: "btn btn-block purple btn-sm",
                              attrs: { disabled: !_vm.isEnabledToEnroll },
                              on: { click: _vm.showEnroller },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-user-plus pull-left",
                              }),
                              _vm._v(" "),
                              _vm._t("default", function () {
                                return [_vm._v("Enroll")]
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : [
            _vm.model === "contact"
              ? _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "popover",
                        rawName: "v-popover:contact-secuence-popover",
                        arg: "contact-secuence-popover",
                      },
                    ],
                    staticClass: "bold text-success",
                    attrs: {
                      slot: "reference",
                      round: "",
                      size: "small",
                      "data-testid": "enroll-to-sequence-popover-button",
                      disabled: !_vm.isEnabledToEnroll,
                    },
                    slot: "reference",
                  },
                  [
                    _c(
                      "strong",
                      [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v(" "),
                        _vm._t("default", function () {
                          return [_vm._v("Enroll To Sequence")]
                        }),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.model !== "contact"
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "popover",
                        rawName: "v-popover:tag-secuence-popover",
                        arg: "tag-secuence-popover",
                      },
                    ],
                    staticClass: "btn btn-block purple btn-sm",
                    attrs: {
                      slot: "reference",
                      disabled: !_vm.isEnabledToEnroll,
                    },
                    slot: "reference",
                  },
                  [
                    _c("i", { staticClass: "fa fa-user-plus pull-left" }),
                    _vm._v(" "),
                    _vm._t("default", function () {
                      return [_vm._v("Enroll")]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }