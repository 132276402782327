<template>
    <div id="scheduler"
         ref="scheduler">
    </div>
</template>

<script>
import 'dhtmlx-scheduler'
import {mapState} from 'vuex'

export default {
    name: 'scheduler',

    props: {
        events: {
            type: Array,
            default() {
                return {
                    events: []
                }
            }
        }
    },

    computed: {
        ...mapState({
            sidebar_folded: state => state.cache.sidebar_folded
        })
    },

    mounted: function () {
        scheduler.skin = 'material'
        scheduler.config.header = [
            'today',
            {
                html: 'Go to Date',
                click: () => {
                    this.toggleGotoDate()
                },
                css: 'btn-goto-date'
            },
            'prev',
            'next',
            'date',
            'day',
            'week',
            'month',
            {
                html: 'Filters',
                click: () => {
                    this.showFilter()
                }
            }
        ]

        scheduler.config.date_format = '%Y-%m-%d %g:%i %A'
        scheduler.config.dblclick_create = false
        scheduler.config.details_on_dblclick = false
        scheduler.config.drag_event_body = false
        scheduler.config.drag_create = false
        scheduler.config.drag_highlight = false
        scheduler.config.drag_move = false
        scheduler.config.drag_resize = false
        scheduler.xy.nav_height = 0;

        scheduler.templates.week_date = function (start, end) {
            const startDate = moment(start)
            const endDate = moment(end)

            const startMonth = startDate.format('MMM')
            const endMonth = endDate.format('MMM')
            const year = endDate.format('YYYY')

            let text = startMonth + '&ndash;' + endMonth + ' ' + year
            if (startMonth === endMonth) {
                text = startMonth + ' ' + year
            }

            return text
        }

        scheduler.attachEvent("onEventDrag", (id, mode, e) => {
            return false
        })

        scheduler.templates.event_class = function (start, end, event) {
            return 'event-bg-color-type-' + event.type + ' status-' + event.status
        }

        scheduler.templates.day_scale_date = function (date) {
            return ''
        }

        scheduler.templates.month_scale_date = function (date) {
            return moment(date).format('ddd')
        }

        scheduler.templates.month_day = function (date) {
            return moment(date).format('D')
        }

        scheduler.attachEvent('onEmptyClick', (date, e) => {
            this.addSchedule(date)
        })

        scheduler.attachEvent('onClick', (id, e) => {
            this.editSchedule(scheduler.getEvent(id))
        })

        scheduler.attachEvent('onViewChange', (new_mode, new_date) => {
            let state = scheduler.getState()
            this.renderEvents(state)
            this.updateCurrentDate(new_date)
        })

        scheduler.init(this.$refs.scheduler, new Date(), 'month')
        scheduler.parse(this.$props.events)
    },

    methods: {
        clearAll() {
            scheduler.clearAll()
        },

        customParse(data) {
            scheduler.parse(data)
            scheduler.updateView()
        },

        showFilter() {
            this.$emit('filter-click')
        },

        renderEvents(state) {
            this.$emit('render-events', state)
        },

        addSchedule(date) {
            this.$emit('add-schedule', date)
        },

        editSchedule(event) {
            this.$emit('edit-schedule', event)
        },

        updateScheduler() {
            scheduler.updateView()
        },

        deleteEvent(id) {
            scheduler.deleteEvent(id)
        },

        toggleGotoDate() {
            this.$emit('toggle-goto-date')
        },

        setCurrentView(date, view) {
            scheduler.setCurrentView(date, view)
            setTimeout(function () {
                scheduler.updateView()
            }, 300)
        },

        updateCurrentDate(date) {
            this.$emit('update-current-date', date);
        },

        insertWeekDays() {
            $(function () {
                $("#scheduler table").append('<thead><tr><th>Mon</th><th>Tue</th><th>Wed</th><th>Thu</th><th>Fri</th><th>Sat</th><th>Sun</th></tr></thead>');
            });
        },
    },

    watch: {
        sidebar_folded() {
            this.updateScheduler()
        }
    }
}
</script>
