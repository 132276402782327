<template>
    <div class="d-flex flex-column h-100"
         :class="[('summary-card-' + status)]">
        <div class="card summary-card-hover h-100 d-flex justify-content-center" style="padding-bottom: 5px">
            <div class="row"
                 v-if="icons.length || title">
                <div class="col-12 p-1">
                    <div class="d-inline-block pt-3 pl-4 align-bottom">
                        <img class="w-24"
                             data-testid="summary-report-big-donut-card-img"
                             v-if="img_src"
                             :src="img_src">
                        <i v-for="icon in icons"
                           v-if="icons.length"
                           class="material-icons"
                           style="color: #08C150">{{ icon }}</i>
                    </div>
                    <div class="d-inline-block pl-2">
                        <strong class="text-md">{{ title }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-12 flex-equal-height">
                <highcharts class="left-align-chart"
                            :options="graphOptions"
                            ref="highchart"
                            style="height: 228px;"
                            data-testid="summary-report-card-graph"
                            v-bind:id="graph_id">
                </highcharts>
            </div>
            <div class="col-12 flex-equal-height">
                <summary-report-card v-if="extra_aggregate"
                                     :title="extra_aggregate.title"
                                     :donut_value="extra_aggregate.total"
                                     :data="extra_aggregate.data"
                                     :img_src="extra_aggregate.img_src"
                                     :is_donut="true"
                                     :loading="loading"
                                     :no_borders="true"
                                     data-testid="summary-report-big-donut-card"
                                     :hide_tooltip="true">
                </summary-report-card>
            </div>
        </div>
    </div>
</template>

<script>
import {summary_widgets_mixin} from '../mixins'
import {mapActions, mapGetters} from 'vuex'
import ICountUp from 'vue-countup-v2'

export default {
    mixins: [summary_widgets_mixin],

    components: {
        ICountUp
    },

    props: {
        title: {
            required: false,
            default: null
        },
        data: {
            required: false,
            default: () => []
        },
        img_src: {
            required: false,
            default: null
        },
        chart_type: {
            required: false,
            default: 'pie'
        },
        is_donut: {
            required: false,
            default: false
        },
        status: {
            required: false,
            default: 'inactive'
        },
        loading: {
            required: false,
            default: true
        },
        icons: {
            required: false,
            default: () => []
        },
        extra_aggregate: {
            required: false,
            default: {}
        }
    },

    data() {
        return {
            graph_id: this.title + '_graph',
            options: {
                chart: {
                    type: this.chart_type,
                    events: {
                        load: (event) => {
                            // when is chart ready
                            this.updateChart(event.target)
                        },

                        render: function() {
                            let title = this.title
                            if (this.series.length > 0) {
                                title.attr({
                                    x: this.plotLeft + (this.series[0].center[0])
                                })
                            }
                        }
                    },
                    backgroundColor: 'rgba(0,0,0,0)',
                    spacingTop: 0,
                    spacingRight: 0,
                    spacingBottom: 0,
                    spacingLeft: 0,
                    margin: [0,0,0,0]
                },
                title: {
                    text: "",
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 24
                },
                plotOptions: {
                    pie: {
                        shadow: false,
                        center: ['23%', 100],
                        point: {
                            events: {
                                legendItemClick: (e) => {
                                    this.clickFilter(e.target.index)
                                    return false;
                                }
                            }
                        },
                        minSize: 200
                    },
                    series: {
                        animation: {
                            duration: 0
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    followPointer: true,
                    split: false,
                    formatter: function (e) {
                        let toReturn = '<b style="font-size: 16px; font-weight: 400;">' + this.point.name + '</b>: ' + this.point.y.toLocaleString()
                        return toReturn
                    }
                },
                legend: {
                    enabled: true,
                    floating: true,
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle',
                    itemMarginTop: 10,
                    itemMarginBottom: 10,
                    labelFormatter: function () {
                        return this.name + ' (' + this.y.toLocaleString() + ')'
                    }
                },
                exporting: {
                    enabled: false
                },
                series: []
            },
            count_keys: [],
            chart: null
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),
        graphOptions() {
            return this.options
        },
        chartClasses() {
            return this.hide_labels ? 'col-12' : (this.counts.length == 1 ? 'col-12' : ('col-' + Math.ceil(12 / this.counts.length)))
        }
    },

    methods: {
        updateChart(chart) {
            this.chart = chart
            if (!this.options.title.text.length) {
                chart.redraw()
            }
        },

        clickFilter(index) {
            let direction = 'all'
            let type = 'all'
            if (index < 2) {
                type = 'call'
            } else {
                type = 'sms'
            }
            switch (index) {
                case 0:
                case 2:
                    direction = 'inbound'
                    break
                case 1:
                case 3:
                    direction = 'outbound'
                    break
            }
            this.resetFiltersExceptDates()
            this.filter.direction = direction
            this.filter.timezone = window.timezone
            this.filter.answer_status = 'all'
            this.filter.type = type
            this.filter.campaign_id = this.campaign_id
            this.filter.workflow_id = this.workflow_id
            this.filter.ring_group_id = this.ring_group_id
            this.filter.user_id = this.user_id
            this.filter.changed = true
            this.scrollWindowToGraph()
            VueEvent.fire('update_summary', true)
        },

        resetFiltersExceptDates() {
            let from_date = this.filter.from_date
            let to_date = this.filter.to_date
            this.resetFilters()
            this.filter.from_date = from_date
            this.filter.to_date = to_date
        },

        scrollWindowToGraph() {
            if (!$('#activity-graph').length) {
                return
            }

            let top_position = $("#activity-graph").offset().top
            let header_height = $(".app-header").height()

            $("html,body").animate({
                scrollTop: top_position - header_height - 90
            }, 500)
        },

        ...mapActions(['resetFilters'])
    },

    watch: {
        'loading': function () {
            // update the graph after the summary data is fetched
            if (!this.loading) {
                let new_data = []
                let total = 0
                let count = 0
                for (let index in this.data) {
                    count = parseInt(this.data[index].y)
                    total += count
                    new_data[index] = [
                        this.data[index].name,
                        count
                    ]
                }
                this.options.series = [
                    {
                        name: '',
                        data: new_data,
                        showInLegend: true,
                        dataLabels: {
                            enabled: false
                        }
                    }
                ]
                if (this.is_donut) {
                    this.options.title.text = "<span class='text-lg metric-total-value'>" + this.$options.filters.numFormat(total) + "</span><br><span class='metric-total-label'>TOTAL</span>"
                    this.options.series[0].size = '70%'
                    this.options.series[0].innerSize = '75%'
                }
            }
        }
    }
}
</script>
