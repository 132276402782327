<template>
    <div class="wizard">
        <div class="wizard-header d-flex justify-content-between align-items-center">
            <h2>Add Verified Caller ID</h2>
            <p class="text-blue cursor-pointer"
               @click="dialogWhatIsVerifiedCallerId = true">
                What is verified caller ID?
            </p>
        </div>
        <div class="dropdown-divider"></div>
        <div :class="{'calling' : pin}">
            <div class="row">
                <div class="col-12">
                    <div v-if="pin">
                        <p class="text-center text-lg mt-4">
                            We are calling the following number: <strong>{{ fixedPhone }}</strong><br/><br>
                            Please answer and then enter the PIN code on your mobile device.
                        </p>
                        <p class="text-3x text-yellow text-monospace font-weight-bold text-separator text-center">
                            <span class="pin-code p-2 text-center">{{ pin }}</span>
                        </p>
                    </div>
                    <el-form ref="add-new-caller-id"
                             label-position="top"
                             v-loading="loading"
                             prevent-default v-else>
                        <el-form ref="countryCode"
                                 class="mb-4"
                                 label-position="top"
                                 :inline="true">
                            <el-form-item class="w-100"
                                          label="Country">
                                <country-selector :show_prefix="true"
                                                  :force_initial_country_code="true"
                                                  :show_tips="true"
                                                  :disabled="loading"
                                                  @changed="changeCountry">
                                </country-selector>
                            </el-form-item>
                        </el-form>
                        <el-form ref="phone_number"
                                 prevent-default>
                            <el-form-item label="Phone number"
                                          class="d-inline-block width-300 fixed">
                                <el-input type="text"
                                          v-model="phoneNumber"
                                          :disabled="loading"
                                          placeholder="Enter a phone number"
                                          clearable
                                          maxlength="15">
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </el-form>
                </div>
            </div>
            <div class="text-right">
                <el-button type="success"
                           v-if="!pin"
                           @click.prevent="submitForm"
                           :disabled="sendButtonDisabled">
                    Verify number
                </el-button>
            </div>
        </div>
        <el-dialog top="10vh"
                   title="What is verified caller ID?"
                   :width="'550px'"
                   :visible.sync="dialogWhatIsVerifiedCallerId"
                   append-to-body>
            <p class="text-muted">
                Verified Caller IDs cannot be used as the display name on outgoing SMS or MMS message.
                <br/><br/>
                Verified Caller IDs are intended to be used with voice services only.
            </p>
            <span slot="footer"
                  class="dialog-footer">
                <button class="btn"
                        @click.prevent="dialogWhatIsVerifiedCallerId = false">
                    Ok
                </button>
            </span>
        </el-dialog>
    </div>
</template>

<style scoped>
.pin-code {
    letter-spacing: 20px;
    color: #a2ffc9;
    text-align: center;
    background-color: #606266;
    border-radius: 20px !important;
    padding-left: 28px !important;
}

.calling {
    background-size: 320px;
    background-position-y: -40px;
    padding-top: 120px;
}
</style>

<script>
import {form_validation_mixin} from '../mixins'
import * as Countries from "../constants/countries"
import * as VerifiedCallerId from "../constants/number-spoofing-verified-status"

export default {
    mixins: [form_validation_mixin],
    props: {
        campaign: {
            required: true
        }
    },
    data() {
        return {
            loadingBtn: false,
            loading: false,
            dialogWhatIsVerifiedCallerId: false,
            pin: null,
            validationId: null,
            phoneNumber: '',
            countryCode: '+1',
        }
    },
    computed: {
        sendButtonDisabled() {
            if (this.loading) return true
            return this.phoneNumber.length <= 7

        },
        fixedPhone() {
            if (this.phoneNumber.length > 7) {
                return this.$options.filters.fixPhone(this.countryCode + this.phoneNumber)
            }

            return null;
        }
    },
    methods: {
        submitForm() {
            if (this.loading) return
            this.loading = true
            const payload = {phone_number: this.countryCode + this.phoneNumber}
            axios.post(`/api/v1/campaign/${this.campaign.id}/add-verified-caller-id`, payload).then(response => {
                if (response.data.message === 'Phone number is already verified.') {
                    this.$message({
                        message: 'Phone number is already verified',
                        type: 'warning'
                    })
                    this.loading = false
                    this.$emit('close')
                    this.$emit('moved')
                }

                if (response.data.message === 'Phone number added to line') {
                    this.$message({
                        message: 'Caller ID added successfully',
                        type: 'success'
                    })
                    this.loading = false
                    this.$emit('close')
                    this.$emit('moved')
                }

                if (response.data.validation_code) {
                    this.pin = response.data.validation_code
                    this.validationId = response.data.validation_id
                }
            }).catch(error => {
                this.$message({
                    message: 'Oops, something went wrong, please, try again in a few minutes',
                    type: 'error'
                })
                this.loading = false
                console.error(error)
                this.$emit('close')
            })
        },
        closeIfLazy() {
            setTimeout(() => {
                if (this.loading) {
                    this.loading = false
                    this.$message({
                        message: 'Oops, it took too long to validate, please try again',
                        type: 'error'
                    })
                    this.$emit('close')
                }
            }, 100 * 1000)
        },
        getCountryPrefix(countryCode) {
            let country = Countries.COUNTRIES.find(item => {
                return item.code === countryCode
            })
            this.countryCode = country.prefix
        },
        changeCountry(countryCode) {
            this.getCountryPrefix(countryCode)
            this.phoneNumber = ''
        },
    },
    beforeCreate() {
        this.pin = null
        this.loading = false
    },
    created() {
        VueEvent.listen('in_app_new_caller_id_return', (event_data) => {
            const validation = event_data.validation
            if (validation.id === this.validationId) {
                this.$emit('close')
                this.$emit('moved')
                switch (validation.status) {
                    case VerifiedCallerId.STATUS_VERIFIED:
                        this.$message({
                            message: 'Caller ID added successfully',
                            type: 'success'
                        })
                        break
                    case VerifiedCallerId.STATUS_FAILED:
                        this.$message({
                            message: 'The validation of caller id was failed',
                            type: 'error'
                        })
                        break
                }
            }
        })
    }
}
</script>
