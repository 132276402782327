var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid lato-regular" }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-wrap align-items-stretch justify-content-between text-center",
      },
      [
        _vm.showMainHeader
          ? _c("div", { staticClass: "section-margin col-md-12" }, [
              _c("h1", {}, [_vm._v("SMS Segment Calculator")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Calculate the length of the message segment and the character count of your text message."
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-fill text-center section-margin col-md-6" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.message.body,
                  expression: "message.body",
                },
              ],
              staticClass: "form-control text-monospace p-4 message-container",
              staticStyle: { "border-radius": "0.625rem" },
              attrs: { id: "message" },
              domProps: { value: _vm.message.body },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.message, "body", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "pull-right" }, [
              _vm._v(_vm._s(_vm.message.bodyLength)),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-fill text-center section-margin col-md-6" },
          [
            _c("div", { staticStyle: { "padding-bottom": "1.25rem" } }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "table-responsive card details-table-border",
                  staticStyle: { "border-radius": "0.625rem" },
                },
                [
                  _c(
                    "table",
                    {
                      staticClass: "table overflow-hidden bg-blue-16",
                      staticStyle: { "margin-bottom": "0px" },
                    },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("tbody", {}, [
                        _c("tr", { staticClass: "bg-white" }, [
                          _c(
                            "td",
                            {
                              staticClass:
                                "font-weight-bold text-center align-middle details-table-border border rounded-bottom-left",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.numberOfSegments) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "font-weight-bold text-center align-middle details-table-border border",
                              staticStyle: {
                                "border-left-width": "2px !important",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.numberOfSegments *
                                      _vm.limitOfCharactersPerSegment
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "font-weight-bold text-center align-middle details-table-border border",
                              staticStyle: {
                                "border-left-width": "2px !important",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.limitOfCharactersPerSegment) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "font-weight-bold text-center align-middle details-table-border border rounded-bottom-right",
                              staticStyle: {
                                "border-left-width": "2px !important",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.message.smsEncodingType) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "padding-top": "1.25rem",
                  "padding-bottom": "1.25rem",
                },
              },
              [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "table-responsive card details-table-border",
                    staticStyle: { "border-radius": "0.625rem" },
                  },
                  [
                    _c(
                      "table",
                      {
                        staticClass: "table overflow-hidden bg-blue-16",
                        staticStyle: { "margin-bottom": "0px" },
                      },
                      [
                        _vm._m(4),
                        _vm._v(" "),
                        _c("tbody", {}, [
                          _c("tr", { staticClass: "bg-white" }, [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "font-weight-bold text-center align-middle details-table-border border rounded-bottom-left",
                              },
                              [
                                _vm._v(
                                  "\n               " +
                                    _vm._s(_vm.iProTotalPrice) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "font-weight-bold text-center align-middle details-table-border border",
                                staticStyle: {
                                  "border-left-width": "2px !important",
                                },
                              },
                              [
                                _vm._v(
                                  "\n               " +
                                    _vm._s(_vm.uProTotalPrice) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "font-weight-bold text-center align-middle details-table-border border rounded-bottom-right",
                                staticStyle: {
                                  "border-left-width": "2px !important",
                                },
                              },
                              [
                                _vm._v(
                                  "\n               " +
                                    _vm._s(_vm.xProTotalPrice) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "footnote" }, [
                  _vm._v(
                    "\n          *For toll-free, short code, and international numbers, charges are being calculated accordingly.\n        "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "padding-top": "1.25rem",
                  "padding-bottom": "1.25rem",
                  "flex-direction": "column",
                  "justify-content": "flex-start",
                  "align-items": "center",
                  display: "inline-flex",
                },
              },
              [
                _c(
                  "a",
                  {
                    staticStyle: { "text-decoration": "none" },
                    attrs: { href: _vm.startTrialButtonUrl, target: "_blank" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-top": "0.03125rem",
                          "padding-left": "2.5625rem",
                          "padding-right": "2.5625rem",
                          background: "#0CA855",
                          "box-shadow":
                            "0px 0.25rem 0.5625rem rgba(0, 0, 0, 0.05)",
                          "border-radius": "0.5rem",
                          overflow: "hidden",
                          "justify-content": "flex-start",
                          "align-items": "center",
                          gap: "0.1875rem",
                          display: "inline-flex",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              color: "white",
                              "font-size": "1rem",
                              "line-height": "3.25rem",
                              "word-wrap": "break-word",
                            },
                          },
                          [
                            _vm._v(
                              "\n              Start free trial\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticStyle: {
                            width: "6.25rem",
                            height: "6.25rem",
                            position: "relative",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "justify-content": "center",
                              "align-items": "flex-start",
                              display: "flex",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "justify-content": "flex-start",
                                  "align-items": "flex-start",
                                  display: "flex",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "17",
                                      height: "17",
                                      viewBox: "0 0 17 17",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M10.0837 13.2756C10.0317 13.2756 9.98218 13.2626 9.9353 13.2366C9.88843 13.2105 9.84937 13.1871 9.81812 13.1663C9.7452 13.0829 9.70874 12.984 9.70874 12.8694C9.70874 12.7548 9.7452 12.6558 9.81812 12.5725L13.615 8.79126L9.81812 5.01001C9.7452 4.92668 9.70874 4.82772 9.70874 4.71313C9.70874 4.59855 9.7452 4.49959 9.81812 4.41626C9.90145 4.33293 10.0004 4.29126 10.115 4.29126C10.2296 4.29126 10.3285 4.33293 10.4119 4.41626L14.4587 8.46313C14.49 8.49438 14.516 8.53345 14.5369 8.58032C14.5577 8.6272 14.5681 8.68188 14.5681 8.74438C14.5681 8.79647 14.5577 8.84595 14.5369 8.89282C14.516 8.9397 14.49 8.97876 14.4587 9.01001L10.4119 13.0569C10.3598 13.1611 10.3077 13.2236 10.2556 13.2444C10.2035 13.2652 10.1462 13.2756 10.0837 13.2756ZM14.0369 9.27563H2.83374C2.72957 9.27563 2.63322 9.23657 2.54468 9.15845C2.45614 9.08032 2.41187 8.97355 2.41187 8.83813C2.41187 8.71313 2.44832 8.61157 2.52124 8.53345C2.59416 8.45532 2.69832 8.41626 2.83374 8.41626H14.0369C14.141 8.41626 14.2374 8.45532 14.3259 8.53345C14.4145 8.61157 14.4587 8.71313 14.4587 8.83813C14.4587 8.97355 14.4145 9.08032 14.3259 9.15845C14.2374 9.23657 14.141 9.27563 14.0369 9.27563Z",
                                        fill: "white",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _vm.showMessageOutput
      ? _c("div", { staticClass: "flex-fill section-margin col-md-12" }, [
          _c("div", { staticClass: "flex-1" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("div", { staticClass: "card message-container overflow-auto" }, [
              _c(
                "div",
                {
                  class: [
                    "card-body p-4",
                    _vm.message.bodyLength === 0 &&
                      "d-flex align-items-center justify-content-center",
                  ],
                },
                [
                  _vm.message.bodyLength
                    ? _c(
                        "p",
                        { staticClass: "text-monospace text-md text-left" },
                        [
                          _vm._l(_vm.message.characters, function (character) {
                            return [
                              _vm.shouldPrintConcatenationHeaders(character)
                                ? _c(
                                    "span",
                                    {
                                      class: [
                                        "font-weight-bold character-block",
                                        _vm.getCharacterBackgroundClass(
                                          character,
                                          true
                                        ),
                                      ],
                                    },
                                    [_vm._v("HEADER")]
                                  )
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  class: [
                                    "character-block",
                                    _vm.getCharacterBackgroundClass(character),
                                  ],
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      character.visibleChar === "\n" ||
                                        character.visibleChar === "\r\n"
                                        ? " "
                                        : character.visibleChar
                                    )
                                  ),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      )
                    : _c("p", { staticClass: "card-text" }, [
                        _vm._v("\n            No message entered\n          "),
                      ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pull-right" }, [
              _vm._v(_vm._s(_vm.message.encodedLength)),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showWarnings && _vm.message.hasLineBreaks
      ? _c("div", { staticClass: "flex-fill section-margin col-md-12" }, [
          _vm._m(6),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "subtitle-text subtitle-text-margin lato-bold pull-left" },
      [_c("label", { attrs: { for: "message" } }, [_vm._v("Original Message")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "subtitle-text subtitle-text-margin lato-bold pull-left" },
      [_c("label", { attrs: { for: "message" } }, [_vm._v("Message Detail")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("tr", {}, [
        _c(
          "th",
          {
            staticClass:
              "mobile-table-header text-white text-center align-middle details-table-border",
          },
          [_vm._v("\n                Segments\n              ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "mobile-table-header text-white text-center align-middle details-table-border",
          },
          [_vm._v("\n                Message Length\n              ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "mobile-table-header text-white text-center align-middle details-table-border",
          },
          [_vm._v("\n                Max Segment Length\n              ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "mobile-table-header text-white text-center align-middle details-table-border",
          },
          [_vm._v("\n                Encoding Type\n              ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "subtitle-text subtitle-text-margin lato-bold pull-left" },
      [_c("label", { attrs: { for: "message" } }, [_vm._v("Price Detail")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("tr", {}, [
        _c(
          "th",
          {
            staticClass:
              "mobile-table-header text-white text-center align-middle details-table-border",
          },
          [_vm._v("\n                iPro\n              ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "mobile-table-header text-white text-center align-middle details-table-border",
          },
          [_vm._v("\n                uPro\n              ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "mobile-table-header text-white text-center align-middle details-table-border",
          },
          [_vm._v("\n                xPro\n              ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("div", { staticClass: "subtitle-text lato-bold" }, [
        _c("label", { attrs: { for: "message" } }, [
          _vm._v("Encoded Message and Segments"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "subtitle-description lato-regular" }, [
        _vm._v(
          "Each segment will be displayed with a different background\n          color.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-fill" }, [
      _c("span", { staticClass: "text-xl" }, [_vm._v("Warnings")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n        If the message has line breaks, the web page utility only supports LF style.\n        If you insert a CRLF it will be converted to LF.\n      "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }