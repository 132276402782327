export default Object.freeze({
    packages: [
        {
            label: 'Local Presence Line',
            value: 1,
        },
        {
            label: 'Non-Local Presence Lines',
            value: 2,
        },
        {
            label: 'All Lines',
            value: 3,
        }
    ],
    frequencies: [
        {
            label: 'One-time',
            value: 1,
        },
        {
            label: 'Monthly',
            value: 3,
        }
    ]
});
