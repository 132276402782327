var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.loading_whitelabel,
          expression: "!loading_whitelabel",
        },
      ],
      staticClass: "container-fluid w-v h-v mellow-blue",
    },
    [
      _c("div", { staticClass: "row full-height" }, [
        !_vm.statics.whitelabel
          ? _c(
              "div",
              {
                staticClass:
                  "col-lg-6 d-none d-sm-none d-lg-flex justify-content-center align-items-center blue-purple-gradient pattern text-white text-center",
              },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-lg-6 d-flex justify-content-center align-items-center text-color",
            class: [_vm.statics.whitelabel ? "offset-lg-3" : ""],
          },
          [
            _c("new-resetpass", {
              attrs: { token: _vm.token, statics: _vm.statics },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row", staticStyle: { "max-width": "550px" } },
      [
        _c("div", { staticClass: "col-12" }, [
          _c("h1", { staticClass: "mb-4" }, [
            _vm._v(
              "Enterprise call center software, built for smart business."
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mb-4 text-lg" }, [
            _c("br"),
            _vm._v(
              "\n                        Supports calls & texts. CRM Integration. 5 min setup.\n                    "
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                staticClass: "btn btn-primary btn-xl px-5",
                attrs: {
                  target: "_blank",
                  href: "https://aloware.com/get-demo/",
                },
              },
              [
                _vm._v(
                  "\n                            Get Demo\n                        "
                ),
              ]
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }