<template>
    <div>
        <h5 class="text-dark mb-1 vertical-center mt-1">Queued Monitoring</h5>
        <div class="d-flex">
            <div class="d-flex vertical-center">
                Total Queued:
                <small class="ml-1 label badge greenish align-self-center"
                   v-if="communications">
                    {{ communications.length }}
                </small>
            </div>
            <div class="d-flex ml-auto filter-call-logs">
                <button class="btn greenish pointer mr-2"
                        style="height: 40px"
                        @click.prevent="getCommunications">
                    <i class="material-icons">refresh</i> Refresh
                </button>
                <div class="dropdown">
                    <button class="btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle"
                            data-toggle="dropdown"
                            data-flip="false"
                            aria-expanded="false"
                            @click="checkBlur">
                        Table Settings <i class="el-icon-arrow-down"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <div class="filter-item">
                            <li>
                                <b>Columns:</b>
                            </li>
                            <el-checkbox-group v-model="checked_table_fields"
                                               @change="changeTableFields">
                                <li v-for="field in table_fields"
                                    :key="field.text"
                                    v-on:click="noClose($event)">
                                    <el-checkbox :label="field.val">{{field.text}}</el-checkbox>
                                </li>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-table class="w-full mt-3"
                  v-loading="loading"
                  row-key="id"
                  :default-sort="{prop: 'created_at', order: 'descending'}"
                  :min-height="table_height"
                  :data="communications"
                  :expand-row-keys="expandableCommunicationsId"
                  stripe>
            <el-table-column type="expand">
                <template v-slot="scope">
                    <div class="row align-items-center">
                        <div class="col-md-4"
                             v-if="scope.row.contact">
                            <strong class="text-greenish">{{ scope.row.contact.name | fixContactName }}</strong>
                            <br>
                            <span class="text-greyish"
                                  v-if="scope.row.type !== 5">
                                {{ scope.row.lead_number | fixPhone }}
                            </span>
                            <span class="text-greyish text-xs"
                                  v-else>
                                {{ scope.row.lead_number }}
                            </span>
                            <div class="mt-1"
                                 v-if="scope.row.contact.disposition_status_id">
                                <span class="text-greyish text-xs"
                                      v-if="scope.row.contact.disposition_status_id">
                                    <i class="material-icons"
                                       :style="{ color: $options.filters.dispositionStatusColor(scope.row.contact.disposition_status_id) }">label</i>
                                    <span class="text-grey-900">{{ scope.row.contact.disposition_status_id | dispositionStatusName }}</span>
                                </span>
                            </div>
                            <div class="mt-1 text-pink-500 text-xs"
                                 v-if="scope.row.contact.company_name">
                                <i class="material-icons">business_center</i>
                                {{ scope.row.contact.company_name }}
                            </div>
                            <div class="mt-1"
                                 v-if="scope.row.contact.tags && scope.row.contact.tags.length > 0">
                                <div v-for="tag in $options.filters.lastTags(scope.row.contact.tags, 3)"
                                     :key="tag.id"
                                     class="text-xs">
                                    <i class="fa fa-circle"
                                       :style="{ color: tag.color }"></i>
                                    <span class="ml-1 text-grey-900">{{ tag.name }}</span>
                                </div>
                                <div class="d-flex align-items-center justify-content-center text-muted text-sm"
                                     v-if="scope.row.contact.tags.length > 3">
                                    ...
                                </div>
                            </div>
                            <div class="mt-1"
                                 v-if="scope.row.contact.cnam_city || scope.row.contact.cnam_state">
                                <span class="text-greyish"
                                      v-if="scope.row.contact.cnam_city">
                                    {{ scope.row.contact.cnam_city }},
                                </span>
                                <span class="text-greyish"
                                      v-if="scope.row.contact.cnam_state">
                                    {{ scope.row.contact.cnam_state }}
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4"
                             :class="[checkSize() ? 'b-l' : 'mt-2']"
                             v-if="scope.row.contact">
                            <target-users-tree v-if="scope.row.type === CommunicationTypes.CALL"
                                               :communication="scope.row"/>
                        </div>
                        <div class="col-md-4"
                             :class="[checkSize() ? 'b-l' : 'mt-2']"
                             v-if="scope.row.notes">
                            <strong class="text-greyish">Notes:</strong>
                            <br>
                            <span class="text-greyish">
                                {{ scope.row.notes }}
                            </span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="disposition_status"
                    width="150"
                    v-if="checkField('disposition_status')">
                <template v-slot="scope">
                    <router-link
                        :to="{ name: 'Communication', params: {communication_id: scope.row.id }}">
                        <div :state="scope.row.disposition_status2"
                             class="status-icon centered-content"
                             v-html="stateToIcon(scope.row.disposition_status2, scope.row.direction, scope.row.type, scope.row.callback_status)">
                        </div>
                        <div class="text-center">
                            <span :class="stateToIcon(scope.row.disposition_status2, scope.row.direction, scope.row.type, scope.row.callback_status, true)"
                                  class="badge d-inline-flex align-items-center py-1" style="max-width: 100%">
                                {{ dispositionTooltipData(scope.row.disposition_status2, scope.row.direction, scope.row.type, scope.row.callback_status) }}
                            </span>
                        </div>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column
                    label="Line"
                    v-if="checkField('campaign')"
                    :min-width="150">
                <template v-slot="scope">
                    <router-link :to="{ name: 'Line Activity', params: {campaign_id: scope.row.campaign_id }}"
                                 v-if="scope.row.campaign_id">
                        <a class="text-dark-greenish"
                           v-if="getCampaign(scope.row.campaign_id)">
                            {{ getCampaign(scope.row.campaign_id).name }}
                        </a>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column
                    label="Number"
                    prop="incoming_number"
                    v-if="checkField('incoming_number')"
                    :min-width="150">
                <template v-slot="scope">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <router-link
                                    :to="{ name: 'Line Activity', params: {campaign_id: scope.row.campaign_id }}"
                                    v-if="scope.row.campaign_id">
                                <a class="text-dark-greenish"
                                   v-if="getCampaign(scope.row.campaign_id)">
                                    {{ getCampaign(scope.row.campaign_id).name }}
                                </a>
                            </router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left"
                             v-if="scope.row.type !== 5">
                            <span class="text-greyish">{{ scope.row.incoming_number | fixPhone }}</span>
                        </div>
                        <div class="col-12 d-flex align-items-center justify-content-left"
                             v-else>
                            <span class="text-greyish">{{ scope.row.incoming_number }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    label="Ring Group"
                    v-if="checkField('ring_group')"
                    :min-width="150">
                <template v-slot="scope">
                    <router-link
                            v-if="scope.row.ring_group_id && getRingGroup(scope.row.ring_group_id)"
                            :to="{ name: 'Ring Group Dialog', params: {ring_group_id: scope.row.ring_group_id }}">
                        <span class="text-dark-greenish">
                            {{ getRingGroup(scope.row.ring_group_id).name }}
                        </span>
                    </router-link>
                    <span class="text-greyish"
                          v-else>
                        -
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                    label="Callback Status"
                    prop="callback_status"
                    v-if="checkField('callback_status')"
                    width="150"
                    sortable>
                <template v-slot="scope">
              <span class="text-greyish"
                    v-if="scope.row.callback_status">
                  {{ scope.row.callback_status | translateCallbackStatusText }}
              </span>
                    <div v-else>
                <span class="text-greyish">
                    -
                </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Sequence"
                v-if="checkField('workflow')"
                :min-width="150">
                <template v-slot="scope">
                    <router-link
                        v-if="scope.row.workflow_id && getWorkflow(scope.row.workflow_id)"
                        :to="{ name: 'Sequence Manager', params: { workflow_id: scope.row.workflow_id }}">
                        <span class="text-dark-greenish">
                            {{ getWorkflow(scope.row.workflow_id).name }}
                        </span>
                    </router-link>
                    <span class="text-greyish"
                          v-else>
                        -
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                    label="Start Time"
                    prop="created_at"
                    v-if="checkField('created_at')"
                    width="150"
                    sortable>
                <template v-slot="scope">
                    <span class="text-greyish">{{ scope.row.created_at | fixDateTime }}</span>
                    <div class="row"
                         v-if="scope.row.call_disposition_id">
                        <div class="col-12 d-flex align-items-center justify-content-left text-xs">
                            <i class="fa fa-bolt"
                               :style="{ color: $options.filters.callDispositionColor(scope.row.call_disposition_id) }"></i>
                            <span class="ml-1 text-grey-900">{{ scope.row.call_disposition_id | callDispositionName }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    label="Wait Time"
                    prop="wait_time"
                    v-if="checkField('wait_time')"
                    sortable
                    width="120">
                <template v-slot="scope">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span v-if="![CommunicationTypes.SMS, CommunicationTypes.EMAIL].includes(scope.row.type)">{{ scope.row.wait_time | fixDuration }}</span>
                            <span v-else>
                                -
                            </span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    label="Talk Time"
                    prop="talk_time"
                    v-if="checkField('talk_time')"
                    sortable
                    width="120">
                <template v-slot="scope">
                    <div class="row"
                         v-if="![CommunicationTypes.SMS, CommunicationTypes.EMAIL, CommunicationTypes.FAX].includes(scope.row.type)">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span>{{ scope.row.talk_time | fixDuration }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span>{{ getVisibleStatus(scope.row) }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    label="Duration"
                    prop="duration"
                    v-if="checkField('duration')"
                    sortable
                    width="110">
                <template v-slot="scope">
                    <div class="row"
                         v-if="![CommunicationTypes.SMS, CommunicationTypes.EMAIL].includes(scope.row.type)">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span>{{ scope.row.duration | fixDuration }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span>{{ getVisibleStatus(scope.row) }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    label="Contact"
                    prop="lead_number"
                    v-if="checkField('lead_number')"
                    :min-width="300">
                <template v-slot="scope">
                    <div class="row d-flex align-items-center">
                        <div :class="[(scope.row.contact && scope.row.contact.tags && scope.row.contact.tags.length > 0) ? 'col-6' : 'col-12']">
                            <div class="row">
                                <div class="col-12 d-flex align-items-center justify-content-left"
                                     v-if="scope.row.contact">
                                    <a target="_blank"
                                       :href="getContactTalkRedirectURL(scope.row.contact.id)">
                                        <el-tooltip class="item pull-left"
                                                    effect="dark"
                                                    content="Click For More Info"
                                                    placement="top">
                                        <span class="text-dark-greenish"
                                              :title="scope.row.contact.name | fixContactName">
                                            {{ scope.row.contact.name | fixContactName }}
                                        </span>
                                        </el-tooltip>
                                        <i v-if="scope.row.first_time_caller"
                                           class="on b-white bottom caller-status pull-left"
                                           title="First Time Conversation"></i>
                                    </a>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-left"
                                     v-else>
                                    <span class="text-dark-greenish pull-left">{{ scope.row.lead_number | fixPhone }}</span>
                                    <i v-if="scope.row.first_time_caller"
                                       class="on b-white bottom caller-status pull-left"
                                       title="First Time Conversation"></i>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex align-items-center justify-content-left">
                                    <span class="text-greyish">
                                        {{ scope.row.lead_number | fixPhone }}
                                    </span>
                                </div>
                            </div>
                            <div class="row"
                                 v-if="scope.row.contact && scope.row.contact.disposition_status_id">
                                <div class="col-12 d-flex align-items-center justify-content-left text-xs">
                                    <i class="material-icons"
                                       :style="{ color: $options.filters.dispositionStatusColor(scope.row.contact.disposition_status_id) }">label</i>
                                    <span class="ml-1 text-grey-900">{{ scope.row.contact.disposition_status_id | dispositionStatusName }}</span>
                                </div>
                            </div>
                            <div class="row"
                                 v-if="scope.row.contact && scope.row.contact.company_name">
                                <div class="col-12 d-flex align-items-center justify-content-left text-pink-500 text-xs">
                                    <i class="material-icons">business_center</i>
                                    <span class="ml-1">{{ scope.row.contact.company_name }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6"
                             v-if="scope.row.contact && scope.row.contact.tags && scope.row.contact.tags.length">
                            <div v-for="tag in $options.filters.lastTags(scope.row.contact.tags, 3)"
                                 :key="tag.id"
                                 class="d-flex align-items-center justify-content-left text-xs">
                                <i class="fa fa-circle"
                                   :style="{ color: tag.color }"></i>
                                <span class="ml-1 text-grey-900">{{ tag.name }}</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-center text-muted text-sm"
                                 v-if="scope.row.contact.tags.length > 3">
                                ...
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    label="Location"
                    prop="lead_location"
                    v-if="checkField('lead_location')"
                    sortable
                    width="140">
                <template v-slot="scope">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span class="text-greyish"
                                  v-if="scope.row.state">
                                {{ scope.row.city || '' }}{{ (scope.row.city && scope.row.state) ? ', ' : '' }}{{ scope.row.state }}
                            </span>
                            <span class="text-greyish"
                                  v-else>
                                -
                            </span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    label="Attempting"
                    prop="attempting_users"
                    v-if="checkField('attempting_users')"
                    sortable
                    :min-width="150">
                <template v-slot="scope">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-left">
                            <span class="text-greyish"
                                  v-if="scope.row.attempting_users && scope.row.attempting_users.length > 0">
                                <ul class="list list-unstyled inset mb-0">
                                    <div v-if="!show_more_list.includes(scope.row.id)">
                                        <li v-for="attempting_user in scope.row.attempting_users.slice(0, 3)"
                                            :key="attempting_user"
                                            v-if="getUser(attempting_user) && getUser(attempting_user).id"
                                            class="pb-1">
                                            <router-link
                                                    :to="{ name: 'User Activity', params: {user_id: getUser(attempting_user).id }}">
                                                <span :class="getAttemptingClass(attempting_user, scope.row.disposition_status2, scope.row.user_id)"
                                                      :title="getUserName(getUser(attempting_user))">
                                                    {{ getUserName(getUser(attempting_user)) }}
                                                </span>
                                            </router-link>
                                        </li>
                                    </div>
                                    <div v-else>
                                        <li v-for="attempting_user in scope.row.attempting_users"
                                            :key="attempting_user"
                                            v-if="getUser(attempting_user) && getUser(attempting_user).id"
                                            class="pb-1">
                                            <router-link
                                                    :to="{ name: 'User Activity', params: {user_id: getUser(attempting_user).id }}">
                                                <span :class="getAttemptingClass(attempting_user, scope.row.disposition_status2, scope.row.user_id)"
                                                      :title="getUserName(getUser(attempting_user))">
                                                    {{ getUserName(getUser(attempting_user)) }}
                                                </span>
                                            </router-link>
                                        </li>
                                    </div>
                                    <a class="d-flex btn align-items-center justify-content-center text-muted text-sm"
                                       v-if="scope.row.attempting_users.length > 3"
                                       @click="showMore(scope.row)">
                                        {{ !show_more_list.includes(scope.row.id) ? 'more ...' : 'less'}}
                                    </a>
                                </ul>
                            </span>
                            <span class="text-greyish"
                                  v-else>
                                -
                            </span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    label="Cold Transferred?"
                    prop="in_cold_transfer"
                    v-if="checkField('in_cold_transfer')"
                    sortable
                    :min-width="180">
                <template v-slot="scope">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <span class="text-dark-greenish _400">{{ scope.row.in_cold_transfer ? 'Yes' : 'No' }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="Transfer Type"
                prop="transfer_type"
                v-if="checkField('transfer_type')"
                width="150"
                sortable>
              <template v-slot="scope">
                <span class="text-greyish"
                      v-if="scope.row.transfer_type">
                    {{ scope.row.transfer_type | translateTransferTypeText }}
                </span>
                <div v-else>
                  <span class="text-greyish">
                      -
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                    label="Tags"
                    v-if="checkField('tags')"
                    :min-width="140">
                <template v-slot="scope">
                    <communication-tags :communication="scope.row"></communication-tags>
                </template>
            </el-table-column>
            <el-table-column
                    align="center"
                    label="Notes"
                    v-if="checkField('notes')"
                    :min-width="70">
                <template v-slot="scope">
                    <communication-note :communication="scope.row"></communication-note>
                </template>
            </el-table-column>
            <el-table-column
                    align="left"
                    label="Operations"
                    v-if="checkField('operations')"
                    width="220">
                <template v-slot="scope">
                    <el-tooltip class="item"
                                effect="dark"
                                content="Answer the call"
                                placement="bottom"
                                v-if="canAnswerCommunication(scope.row)">
                        <button class="btn btn-sm greenish btn-icon opaq"
                                @click="answerCommunication(scope.row)">
                            <i class="material-icons">check</i>
                        </button>
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="dark"
                                content="Reply"
                                placement="bottom"
                                v-if="scope.row.contact && scope.row.type == 2 && hasPermissionTo('send sms')">
                        <a class="btn btn-sm blue btn-icon opaq"
                           target="_blank"
                           :href="getContactTalkRedirectURL(scope.row.contact.id)">
                            <i class="material-icons">reply</i>
                        </a>
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="dark"
                                content="More Details"
                                placement="bottom">
                        <button class="btn btn-sm greyish btn-icon opaq"
                                @click="gotoCommunicationInfo(scope.row.id)">
                            <i class="material-icons">info_outline</i>
                        </button>
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="dark"
                                content="Terminate"
                                placement="bottom"
                                v-if="hasRole('Company Admin')">
                        <button class="btn btn-sm danger btn-icon opaq"
                                :loading="terminate_loading"
                                :disabled="terminate_loading"
                                @click="terminateCommunication(scope.row)">
                            <i class="material-icons">power_settings_new</i>
                        </button>
                    </el-tooltip>
                </template>
            </el-table-column>
            <template slot="empty"
                      class="text-center text-muted">
                <span v-if="!loading">No queued calls</span>
                <span v-if="loading"></span>
            </template>
        </el-table>
    </div>
</template>

<script>
    import auth from '../auth'
    import TargetUsersTree from './target-users-tree'
    import VueLoadImage from 'vue-load-image'
    import {
        acl_mixin,
        contact_talk_mixin,
        communication_info_mixin,
        communication_mixin,
        date_mixin,
        report_mixin,
        user_info_mixin
    } from '../mixins'
    import {mapActions, mapState} from 'vuex'
    import * as CommunicationDispositionStatus from '../constants/communication-disposition-status'
    import * as CommunicationCurrentStatus from '../constants/communication-current-status'
    import * as CommunicationResolutions from '../constants/communication-resolution'
    import * as CommunicationDirections from '../constants/communication-direction'
    import * as CommunicationTypes from '../constants/communication-types'
    import * as AnswerTypes from '../constants/answer-types'
    import * as CallbackStatus from '../constants/callback-status'

    export default {
        mixins: [
            date_mixin,
            report_mixin,
            acl_mixin,
            contact_talk_mixin,
            communication_mixin,
            communication_info_mixin,
            user_info_mixin
        ],

        components: {
            TargetUsersTree,
            'vue-load-image': VueLoadImage
        },

        props: {
            campaign_id: {required: false},
            workflow_id: {required: false},
            ring_group_id: {required: false},
            user_id: {required: false},
        },

        data() {
            return {
                auth: auth,
                env: null,
                loading: false,
                terminate_loading: false,
                dequeue_loading: false,
                webrtc_ready: false,
                campaign: null,
                communications: [],
                show_more_list: [],
                table_fields: [{
                    text: 'Number',
                    val: 'incoming_number'
                }, {
                    text: 'Ring Group',
                    val: 'ring_group'
                }, {
                    text: 'Callback Status',
                    val: 'callback_status'
                }, {
                    text: 'Sequence',
                    val: 'workflow'
                }, {
                    text: 'Start Time',
                    val: 'created_at'
                }, {
                    text: 'Contact',
                    val: 'lead_number'
                }, {
                    text: 'Location',
                    val: 'lead_location'
                }, {
                    text: 'Attempting',
                    val: 'attempting_users'
                }, {
                    text: 'Tags',
                    val: 'tags'
                }, {
                    text: 'Notes',
                    val: 'notes'
                }, {
                    text: 'Operations',
                    val: 'operations'
                }],
                checked_table_fields: ['disposition_status', 'incoming_number', 'created_at', 'ring_group', 'callback_status', 'lead_number', 'lead_location', 'attempting_users', 'tags', 'operations'],
                table_height: 769,
                extra_height: 0,
                is_first_load: true,
                CommunicationDispositionStatus,
                CommunicationCurrentStatus,
                CommunicationResolutions,
                CommunicationDirections,
                CommunicationTypes,
                AnswerTypes,
            }
        },

        computed: {
            ...mapState(['ring_groups', 'workflows', 'disposition_statuses', 'call_dispositions', 'on_call']),
            ...mapState('cache', ['current_company', 'queue_table_fields', 'show_graph']),

            expandableCommunicationsId() {
                // check if loaded
                if (this.communications) {
                    return this.communications.filter(communication => [CommunicationTypes.SMS, CommunicationTypes.FAX].includes(communication.type)).map(item => {
                        return item.id
                    })
                }
            },

            allTableFields() {
                return _.map(this.table_fields, item => {
                    return item.val
                })
            }
        },

        created() {
            this.checked_table_fields = this.queue_table_fields
            if (this.hasPermissionTo('list communication')) {
                this.getCommunications()
            }

            VueEvent.listen('webrtc_ready', (ready) => {
                this.webrtc_ready = ready
            })

            VueEvent.listen('new_communication', (data) => {
                // ignore all other communication types
                if (![CommunicationTypes.CALL].includes(data.type)) {
                    return
                }
                // disable live dashboard for reporters
                if (this.hasReporterAccess) {
                    return
                }
                // check if loaded
                if (Array.isArray(this.communications)) {
                    // check new communication exists in the old list
                    let found = this.communications.filter(communication => {
                        return communication.id === data.id
                    })
                    if (!found.length) {
                        if (this.checkCommunicationMatchesFilters(data) && this.checkCommunicationMatchesUserAccessibility(data) && this.checkCommunicationMatchesCampaign(data) && this.checkCommunicationMatchesWorkflow(data) && this.checkCommunicationMatchesRingGroup(data)) {
                            // push new data to top of array
                            this.communications.unshift(data)
                        }
                    }
                }
            })

            VueEvent.listen('update_communication', (data) => {
                // ignore all other communication types
                if (data.type != CommunicationTypes.CALL) {
                    return
                }
                // disable live dashboard for reporters
                if (this.hasReporterAccess) {
                    return
                }
                // check if loaded
                if (Array.isArray(this.communications)) {
                    // check new communication exists in the old list
                    let found = this.communications.filter(communication => {
                        return communication.id === data.id
                    })
                    if (found.length) {
                        // update communication
                        data = _.merge(found[0], data)
                        if (this.checkCommunicationMatchesFilters(data) && this.checkCommunicationMatchesUserAccessibility(data) && this.checkCommunicationMatchesCampaign(data) && this.checkCommunicationMatchesWorkflow(data) && this.checkCommunicationMatchesRingGroup(data)) {
                            this.$set(this.communications, this.communications.indexOf(found[0]), data)
                        } else {
                            this.communications = this.communications.filter(communication => {
                                return communication.id !== data.id
                            })
                        }
                    } else {
                        // add the communication if it's not already there and if it matches the criteria
                        if (this.checkCommunicationMatchesFilters(data) && this.checkCommunicationMatchesUserAccessibility(data) && this.checkCommunicationMatchesCampaign(data) && this.checkCommunicationMatchesWorkflow(data) && this.checkCommunicationMatchesRingGroup(data)) {
                            // push new data to top of array
                            this.communications.unshift(data)
                        }
                    }
                }
            })

            VueEvent.listen('delete_communication', (data) => {
                // check if loaded
                if (Array.isArray(this.communications)) {
                    // try to find the communication
                    let found = this.communications.find(communication => communication.id === data.id)
                    if (found) {
                        // remove it from the list
                        this.communications.splice(this.communications.indexOf(found), 1)
                    }
                }
            })
        },

        methods: {
            showMore(comm) {
                if (this.show_more_list.includes(comm.id)) {
                    let index = this.show_more_list.indexOf(comm.id);

                    if (index > -1) {
                       this.show_more_list.splice(index, 1);
                    }
                } else {
                    this.show_more_list.push(comm.id)
                }
            },

            changeTableFields() {
                this.setQueueTableFields(this.checked_table_fields)
            },

            checkSize() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            },

            getCampaign(id) {
                if (!id) {
                    return null
                }
                let found = this.campaigns.find(campaign => campaign.id === id)
                if (found) {
                    return found
                }

                return null
            },

            getRingGroup(id) {
                if (!id) {
                    return null
                }
                let found = this.ring_groups.find(ring_group => ring_group.id === id)
                if (found) {
                    return found
                }

                return null
            },

            getWorkflow(id) {
                if (!id) {
                    return null
                }
                let found = this.workflows.find(workflow => workflow.id === id)
                if (found) {
                    return found
                }

                return null
            },

            getCommunications() {
                this.source.cancel('getCommunications canceled by the user.')
                this.source = this.CancelToken.source()
                this.loading = true

                return axios.post('/api/v1/contact-center/queued-calls', {
                    campaign_id: this.campaign_id,
                    ring_group_id: this.ring_group_id,
                    cancelToken: this.source.token
                }).then(res => {
                    this.communications = res.data
                    this.extra_height = 0
                    this.communications.map((o) => {
                        o.tag_ids = o.tags.map((a) => a.id)
                        if (o.tag_ids.length > 0) {
                            this.extra_height += (o.tag_ids.length * 23) - 6
                        }
                    })

                    this.resize()
                    this.loading = false

                    return Promise.resolve(res)
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message)

                        // return Promise.reject(err)
                    } else {
                        console.log(err)
                        this.loading = false

                        return Promise.reject(err)
                    }
                })
            },

            canAnswerCommunication(communication) {
                let ring_group = this.getRingGroup(communication.ring_group_id)
                let current_user = this.getCurrentUser()
                let in_user_ring_group = current_user ? current_user.ring_group_ids.includes(communication.ring_group_id) : false;
                let in_fishing_mode = ring_group ? ring_group.fishing_mode : false;

                return this.isCommunicationCallable(communication)
                    && !this.on_call
                    && in_fishing_mode
                    && in_user_ring_group
            },

            isCommunicationCallable(communication) {
                return communication.current_status2 !== CommunicationCurrentStatus.CURRENT_STATUS_RINGALL_NEW
                    && communication.disposition_status2 === CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW
                    && !communication.user_id
            },

            getCurrentUser() {
                return (this.auth.user && this.auth.user.profile) ? this.auth.user.profile : null;
            },

            answerCommunication(communication) {
                if (this.forceTalk) {
                    let talk_url = this.getTalkUrl()

                    if (talk_url) {
                        talk_url += '?call=call:' + communication.id
                        this.openInNewTab(talk_url)
                    }
                } else {
                    VueEvent.fire('make_new_call', {
                        task: 'call:' + communication.id,
                        phone_number: communication.lead_number,
                        campaign_id: communication.campaign_id
                    })
                }
            },

            openInNewTab(url) {
                let win = window.open(url, '_blank')
                win.focus()
            },

            terminateCommunication(communication) {
                this.$confirm('Terminating will end this call and assign it a <b>Failed</b> call disposition. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    customClass: 'width-500 fixed',
                    dangerouslyUseHTMLString: true,
                    type: 'warning'
                }).then(() => {
                    if (communication.disposition_status2 == CommunicationDispositionStatus.DISPOSITION_STATUS_INPROGRESS_NEW) {
                        return this.forceTerminateCommunication(communication.id)
                    }

                    this.forceDequeueCommunication(communication.id)
                }).catch(() => {
                    // terminate canceled
                })
            },

            forceTerminateCommunication(communication_id) {
                this.source.cancel('forceTerminateCommunication canceled by the user.')
                this.source = this.CancelToken.source()
                this.terminate_loading = true
                this.loading = true
                axios.post(`/api/v1/communication/${communication_id}/force-terminate`, {
                    cancelToken: this.source.token
                }).then(res => {
                    this.terminate_loading = false
                    this.loading = false
                    // send an notification to inform user the communication is terminated
                    this.$notify({
                        offset: 95,
                        title: 'Communication',
                        message: 'Communication terminated successfully.',
                        type: 'success',
                        showClose: true
                    })
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message)

                        // return Promise.reject(err)
                    } else {
                        this.loading = false
                        this.terminate_loading = false

                        this.$root.handleErrors(err.response)
                    }
                })
            },

            forceDequeueCommunication(communication_id) {
                this.source.cancel('forceDequeueCommunication canceled by the user.')
                this.source = this.CancelToken.source()
                this.dequeue_loading = true
                this.loading = true
                axios.post(`/api/v1/communication/${communication_id}/force-dequeue`, {
                    cancelToken: this.source.token
                }).then(res => {
                    this.dequeue_loading = false
                    this.loading = false
                    // send an notification to inform user the communication is dequeued
                    this.$notify({
                        offset: 95,
                        title: 'Communication',
                        message: 'Communication terminated successfully.',
                        type: 'success',
                        showClose: true
                    })
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message)

                        // return Promise.reject(err)
                    } else {
                        this.loading = false
                        this.dequeue_loading = false

                        this.$root.handleErrors(err.response)
                    }
                })
            },

            checkBlur: function (e) {
                if (e.currentTarget.getAttribute('aria-expanded') == 'true') {
                    e.currentTarget.blur()
                }
            },

            noClose(event) {
                if (event) {
                    event.stopPropagation()
                }
            },

            resize() {
                if (this.communications.length == 0) {
                    this.table_height = 769
                } else {
                    this.table_height = Math.max(59 + (this.communications.length * 71), 769)
                }

                this.table_height += this.extra_height
            },

            checkField(field) {
                if (this.checked_table_fields.indexOf(field) !== -1) {
                    return true
                } else {
                    return false
                }
            },

            checkCommunicationMatchesFilters(communication) {
                if (communication.callback_status === CallbackStatus.CALLBACK_STATUS_REQUESTED && communication.current_status2 === CommunicationCurrentStatus.CURRENT_STATUS_COMPLETED_NEW) {
                    return false
                }

                if (![CommunicationCurrentStatus.CURRENT_STATUS_QUEUED_NEW, CommunicationCurrentStatus.CURRENT_STATUS_RINGALL_NEW].includes(communication.current_status2) && communication.callback_status !== CallbackStatus.CALLBACK_STATUS_REQUESTED) {
                    return false
                }

                if (communication.direction != CommunicationDirections.INBOUND) {
                    return false
                }

                return true
            },

            checkCommunicationMatchesCampaign(communication) {
                // checks if communication belongs to this campaign
                if (this.campaign_id) {
                    if (communication.campaign_id != this.campaign_id) {
                        return false
                    }
                }

                return true
            },

            checkCommunicationMatchesRingGroup(communication) {
                // checks if communication belongs to this ring group
                if (this.ring_group_id) {
                    if (communication.ring_group_id != this.ring_group_id) {
                        return false
                    }
                }

                return true
            },

            checkCommunicationMatchesWorkflow(communication) {
                // checks if communication belongs to this workflow
                if (this.workflow_id) {
                    if (communication.workflow_id != this.workflow_id) {
                        return false
                    }
                }

                return true
            },

            gotoCommunicationInfo(communication_id) {
                this.$router.push({name: 'Communication', params: {communication_id: communication_id}})
            },

            getVisibleStatus(communication) {
                if (communication.current_status2 != CommunicationCurrentStatus.CURRENT_STATUS_COMPLETED_NEW) {
                    return this.$options.filters.capitalize(this.$options.filters.replaceDash(this.$options.filters.translateCurrentStatusText(communication.current_status2)))
                }

                return this.$options.filters.capitalize(this.$options.filters.replaceDash(this.$options.filters.translateDispositionStatusText(communication.disposition_status2)))
            },

            ...mapActions('cache', ['setQueueTableFields'])
        },

        watch: {
            ring_group_id() {
                this.getCommunications()
            },

            campaign_id() {
                this.getCommunications()
            }
        },

        beforeCreate() {
            axios.get('/build-info.json')
                .then(res => {
                    this.env = res.data.env
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
</script>
