export const HTTP_METHOD_POST = 1
export const HTTP_METHOD_GET = 2

export const HTTP_AUTH_METHOD_NONE = 1
export const HTTP_AUTH_METHOD_BASIC = 2
export const HTTP_AUTH_METHOD_BEARER = 3

// Webhooks Status
export const STATUS_VALID = 1
export const STATUS_UNTESTED = 2
export const STATUS_INVALID = 3
export const STATUS_DISABLED = 4
