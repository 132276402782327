<template>
  <div class="p-3">
    <iframe class="border-0 w-100 height-600" frameborder="0" allowTransparency="true" :src="url"></iframe>
  </div>
</template>
<script>
export default {
  name: 'insights-show',
  props: {
    url: {
      type: String,
      default: null
    },
  },
}
</script>
