import cloneDeep from 'lodash/cloneDeep'

/**
 * ErrorBag class is an error wrapper from Laravel's form
 * validation response
 */
export default class ErrorBag {
    /**
     * Set the error bag from Laravel's error array
     * @param errors
     */
    constructor(errors) {
        this.errors = errors
    }

    /**
     * Return the first error message of the field
     *
     * @param key
     * @returns {*|null}
     */
    first(key) {
        return this.has(key) ? this.errors[key][0]: null
    }

    /**
     * Checks if there is an error with the given key
     *
     * @param key
     * @returns {boolean}
     */
    has(key) {
        return key in this.errors
    }

    /**
     * Deletes a key from error messages
     *
     * @param key
     * @return void
     */
    clear(key) {
        // check if key exists
        if (this.has(key)) {
            // delete property. This is done this way coz for some reason the error message
            // in the form item is not removed after prop delete
            delete this.errors[key]
            this.errors = cloneDeep(this.errors)
        }
    }

    /**
     * Removes all the registered error messages
     *
     * @return void
     */
    clearAll() {
        this.errors = {}
    }

}
