<template>
    <div class="container-fluid w-v h-v mellow-blue"
         v-show="!loading_whitelabel">
        <div class="row full-height">
            <div class="col-lg-6 d-none d-sm-none d-lg-flex justify-content-center align-items-center blue-purple-gradient pattern text-white text-center"
                 v-if="!statics.whitelabel">
                <div class="row"
                     style="max-width:550px">
                    <div class="col-12">
                        <h1 class="mb-4">Enterprise call center software, built for smart business.</h1>
                        <p class="mb-4 text-lg">
                            <br>
                            Supports calls &amp; texts. CRM Integration. 5 min setup.
                        </p>
                        <div>
                            <a class="btn btn-primary btn-xl px-5"
                               target="_blank"
                               href="https://aloware.com/get-demo/">
                                Get Demo
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 d-flex justify-content-center align-items-center text-color"
                 :class="[ statics.whitelabel ? 'offset-lg-3' : '' ]">
                <new-forgotpass :statics="statics"></new-forgotpass>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                statics: {
                    logo: null,
                    logo_inverse: null,
                    logo_square: null,
                    logo_square_inverse: null,
                    host: null,
                    referer: null,
                    name: null,
                    domain: null,
                    whitelabel: false,
                    path: null
                },
                loading_whitelabel: true,
            }
        },

        created() {
            this.getStatics()
        },

        methods: {
            getStatics() {
                this.loading_whitelabel = true
                axios.get('/get-statics')
                    .then(res => {
                        this.statics = res.data
                        this.setPageTitle('Forgot Password - ' + this.statics.name)
                        this.loading_whitelabel = false
                    })
                    .catch(err => {
                        this.setPageTitle('Forgot Password - Aloware')
                        console.log(err)
                        this.$root.handleErrors(err.response)
                        this.loading_whitelabel = false
                    })
            }
        }
    }
</script>
