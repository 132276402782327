<template>
    <el-date-picker class="mr-2"
                    type="daterange"
                    :align="align"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="From date"
                    end-placeholder="To date"
                    format="MMM d, yyyy"
                    v-model="date_range_model"
                    value-format="yyyy-MM-dd"
                    :default-time="['00:00:00', '23:59:59']"
                    :picker-options="pickerOptions"
                    @change="change">
    </el-date-picker>
</template>

<script>
export default {
    name: "alo-date-range-picker",

    props: {
        value: {
            required: true,
        },

        align: {
            required: false,
            default: 'left'
        }
    },

    data() {
        return {
            date_range_model: this.value,
            pickerOptions: {
                disabledDate(time) {
                    const max_date = moment().endOf('day')
                    const calendar_date = moment(time)

                    return calendar_date.isAfter(max_date)
                },
                shortcuts: [
                    {
                        text: 'Today',
                        onClick: (picker) => {
                            const start = moment().startOf('day').toDate();
                            const end = moment().endOf('day').toDate();
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'Yesterday',
                        onClick: (picker) => {
                            const start = moment().subtract(1, 'day').startOf('day').toDate();
                            const end = moment().subtract(1, 'day').endOf('day').toDate();
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'This Week',
                        onClick: (picker) => {
                            const start = moment().startOf('week').toDate();
                            const end = moment().endOf('week').toDate();
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'This Month',
                        onClick: (picker) => {
                            const start = moment().startOf('month').toDate();
                            const end = moment().endOf('day').toDate();
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'Last 7 Days',
                        onClick: (picker) => {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'Last 30 Days',
                        onClick: (picker) => {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'Last 3 months',
                        onClick: (picker) => {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'This Year',
                        onClick: (picker) => {
                            const start = moment().startOf('year').toDate();
                            const end = moment().toDate();
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'Last Year',
                        onClick: (picker) => {
                            const start = moment().subtract(1, 'year').startOf('year').toDate();
                            const end = moment().subtract(1, 'year').endOf('year').toDate();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        }
    },

    created() {
        if (this.value.length === 2) {
            this.date_range_model = this.value
        } else {
            this.date_range_model = [
                moment().subtract(3, 'month').toDate(),
                moment().toDate()
            ]
        }
    },

    methods: {
        change() {
            this.$emit('input', this.date_range_model)
            this.$emit('change', this.date_range_model)
        }
    },

    watch: {
        'value': function(new_value) {
            this.date_range_model = new_value
        }
    }
}
</script>
