var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hubspotEnabled
    ? _c(
        "div",
        { staticClass: "d-flex flex-row align-items-center" },
        [
          _c(
            "el-select",
            {
              staticClass: "d-flex flex-grow-1",
              attrs: {
                "popper-class": "hubspotListSelector",
                filterable: "",
                clearable: "",
                "default-first-option": "",
                "collapse-tags": "",
                remote: "",
                "remote-method": _vm.fetchHubspotLists,
                placeholder: _vm.placeholder,
                multiple: _vm.multiple,
                "multiple-limit": _vm.multiple ? _vm.multipleLimit : 0,
                loading: _vm.loading_hubspot_lists,
                "reserve-keyword": true,
              },
              on: {
                "visible-change": _vm.onDropdownVisibleChange,
                clear: _vm.onClear,
              },
              model: {
                value: _vm.list_id,
                callback: function ($$v) {
                  _vm.list_id = $$v
                },
                expression: "list_id",
              },
            },
            [
              _vm._l(_vm.hubspot_lists, function (list) {
                return _c("el-option", {
                  key: list.listId,
                  attrs: { label: list.name, value: list.listId },
                })
              }),
              _vm._v(" "),
              _vm.is_loading_more
                ? _c(
                    "el-option",
                    {
                      staticClass: "loading-option",
                      attrs: { value: "", disabled: "" },
                    },
                    [_vm._v("\n            Loading More...\n        ")]
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-button", {
            staticClass: "ml-2 w-30",
            attrs: {
              type: "primary",
              icon: "fa fa-refresh",
              size: "mini",
              disabled: _vm.loading_hubspot_lists,
              loading: _vm.loading_hubspot_lists,
              circle: "",
            },
            on: { click: _vm.getHubspotLists },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }