<template>
    <div class="padding mt-5"
         v-if="campaigns.length == 0 && !loading && !hasReporterAccess && !loading_whitelabel">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="welcome-head">Welcome to {{ name }}</h1>
                <p class="welcome-message mb-0">Add a number and begin your journey</p>
                <p class="welcome-message mt-0 text-bluish font-italic">we have pre-filled the reports with some nice
                    plots. As soon as your calls come in, you'll see actual info.</p>
                <button class="btn btn-block greenish add-campaign"
                        @click="openModal"
                        v-if="hasPermissionTo('create campaign')">
                    <i class="fa fa-plus"></i>
                    New Number
                </button>

                <el-dialog :visible.sync="dialogVisible"
                           @close="closeModal"
                           top="10vh"
                           append-to-body
                           custom-class="wizard-dialog dialog-no-border"
                           :before-close="beforeCloseModal"
                           v-if="hasPermissionTo('create campaign')">
                    <line-wizard :key="prop_counter"
                                 @created="campaignCreated">
                    </line-wizard>
                </el-dialog>
            </div>
        </div>
    </div>
</template>


<script>
    import auth from '../auth'
    import {mapState} from 'vuex'
    import {acl_mixin} from '../mixins'

    export default {
        mixins: [acl_mixin],

        data() {
            return {
                auth: auth,
                dialogVisible: false,
                prop_counter: 0,
                campaign_created: false,
                name: null,
                loading_whitelabel: true
            }
        },

        computed: {
            ...mapState(['campaigns']),

            loading() {
                return this.$root.loading_campaigns
            }
        },

        created() {
            this.setName()
        },

        methods: {
            setName() {
                this.loading_whitelabel = true
                axios.get('/get-statics')
                    .then(res => {
                        this.name = res.data.name
                        this.loading_whitelabel = false
                    })
                    .catch(err => {
                        this.name = 'Aloware'
                        console.log(err)
                        this.$root.handleErrors(err.response)
                        this.loading_whitelabel = false
                    })
            },

            addActiveClass() {
                setTimeout(() => {
                    $("body").find(".navside .nav li.active").each(function () {
                        $(this).parent().parent().addClass("active")
                    })
                }, 100)
            },

            openModal() {
                this.dialogVisible = true
                this.campaign_created = false
            },

            closeModal() {
                this.dialogVisible = false
                this.campaign_created = false
                setTimeout(() => {
                    this.prop_counter += 1
                }, 200)
            },

            beforeCloseModal(done) {
                if (!this.campaign_created) {
                    this.$confirm('Are you sure you want to leave? Your changes will not be saved.', 'Warning', {
                        confirmButtonText: 'Yes, Leave',
                        cancelButtonText: 'No, Stay',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    })
                        .then(res => {
                            done()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                } else {
                    done()
                }
            },

            campaignCreated() {
                this.campaign_created = true
            }
        }
    }
</script>
