<template>
    <span v-html="updating_date"></span>
</template>

<script>
    import auth from "../auth"

    export default {
        name: "updating-date",

        props: {
            update_interval: {
                type: Number,
                required: false,
                default: 60 * 60 * 1000
            }
        },

        data() {
            return {
                auth: auth,
                updating_date: null,
            }
        },

        created() {
            this.getRelativeDate()
            setInterval(this.getRelativeDate, this.update_interval)
        },

        destroyed() {
            clearInterval(this.getRelativeDate)
        },

        methods: {
            getRelativeDate() {
                this.updating_date = this.$options.filters.fixFullDateLocal(Date.now())
            },
        }
    }
</script>
