<template>
    <div>
        <el-dialog width="35vw"
                   top="10vh"
                   append-to-body
                   :title="title"
                   :before-close="closeDialog"
                   :visible.sync="show_dialog">
            <div class="container-account-imports py-1"
                 v-loading="loading">
                <div class="d-flex mb-3">
                    <code>
                        <span v-for="(log, index) in logs"
                              :key="index"
                              class="d-block">
                          {{ log }}
                        </span>
                    </code>
                </div>
            </div>

            <div slot="footer"
                 class="dialog-footer">
                <el-button v-if="cursor !== null"
                           size="small"
                           :loading="loading"
                           @click="fetchLogs">
                  Show older logs
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  name: "pull-hubspot-list-logs",

  props: {
    contactList: {
      default: false
    }
  },

  data() {
    return {
      imports: [],
      rawLogs: [],
      per_page: 20,
      loading: false,
      cursor: null,
      show_dialog: false,
    }
  },

  watch: {
    show_dialog(val) {
      if (val === true) {
        this.fetchLogs(true)
      }
    }
  },

  computed: {
    title() {
      return `Logs for ${this.contactList?.name}`
    },

    logs() {
      return this.rawLogs?.map(d => d.contents)
    }
  },

  methods: {
    closeDialog() {
      this.show_dialog = false
    },

    /**
     * Retrieve the logs related to the HS list pull attempts.
     * @param reset
     */
    fetchLogs(reset = false) {
      // fetch the most recent logs and reset the existing logs in the front-end
      if (reset === true) {
        this.cursor = null
        this.rawLogs = []
      }

      this.loading = true
      axios.get(`/api/v2/contacts-list/${this.contactList?.id}/logs`, {
        params: {
          cursor: this.cursor,
        }
      }).then(response => {
        this.cursor = response?.data?.next
        // append the data into the existing list to make it easier for the users to revisit old results
        this.rawLogs.push(...response?.data?.data)

        this.loading = false
      }).catch(err => {
        this.$notify({
          offset: 95,
          title: 'Failed to retrieve logs.',
          message: err.response?.data?.message,
          type: 'error',
          showClose: true,
        })
      })
    },
  },
}
</script>
