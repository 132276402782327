var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "40%",
        top: "4vh",
        "append-to-body": "",
        visible: _vm.showManager,
        "before-close": _vm.closeFiltersMenu,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showManager = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("h6", [
          _vm.mode === "edit"
            ? _c("i", {
                staticClass: "fa fa-circle mr-2",
                class:
                  "event-color-type-" +
                  _vm.schedule.type +
                  " status-" +
                  _vm.schedule.status +
                  " " +
                  (_vm.schedule.is_past ? "is_past" : ""),
              })
            : _vm._e(),
          _vm._v("\n            " + _vm._s(_vm.title) + "\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "scheduleForm",
              attrs: {
                "label-position": "top",
                model: _vm.schedule,
                rules: _vm.rules,
                "data-testid": "schedule-form",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveSchedule()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Title", prop: "text" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "Add title",
                      "data-testid": "reminder-manager-add-title-input",
                      disabled: !_vm.isEditable,
                    },
                    model: {
                      value: _vm.schedule.text,
                      callback: function ($$v) {
                        _vm.$set(_vm.schedule, "text", $$v)
                      },
                      expression: "schedule.text",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.called_from !== "contact"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Event Type", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w-100",
                          attrs: {
                            placeholder: "Select type",
                            "data-testid": "reminder-manager-event-type-select",
                            disabled: _vm.mode === "edit",
                          },
                          on: { change: _vm.customPreValidateForm },
                          model: {
                            value: _vm.schedule.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.schedule, "type", $$v)
                            },
                            expression: "schedule.type",
                          },
                        },
                        _vm._l(_vm.event_types, function (t) {
                          return _c("el-option", {
                            key: t.value,
                            attrs: { label: t.label, value: t.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.show_contact_selector
                ? _c(
                    "el-form-item",
                    {
                      class: _vm.mode === "add" ? "" : "d-none",
                      attrs: { label: "Contact", prop: "contact" },
                    },
                    [
                      _c("contact-select", {
                        ref: "contactSelector",
                        staticClass: "w-100",
                        attrs: {
                          label: "Select Contact",
                          "data-testid": "reminder-manager-contact-select",
                          contact_selected: _vm.contact,
                        },
                        on: {
                          blur: _vm.customPreValidateForm,
                          "select-contacts": _vm.setContact,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _vm.mode === "edit"
                      ? _c("el-form-item", { attrs: { label: "User" } }, [
                          _vm.schedule && _vm.schedule.user
                            ? _c(
                                "div",
                                {
                                  staticClass: "card py-2 px-3",
                                  staticStyle: { "line-height": "1.35" },
                                },
                                [
                                  _c(
                                    "strong",
                                    { staticClass: "mb-0 d-block" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.schedule.user.first_name) +
                                          " " +
                                          _vm._s(_vm.schedule.user.last_name) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "ID: " + _vm._s(_vm.schedule.user.id)
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _vm.mode === "edit" && _vm.schedule.contact
                      ? _c("el-form-item", { attrs: { label: "Contact" } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.getContactTalkRedirectURL(
                                  _vm.schedule.contact.id
                                ),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "card py-2 px-3",
                                  staticStyle: { "line-height": "1.35" },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "Click to go to Contacts screen",
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "media" }, [
                                        _c(
                                          "div",
                                          { staticClass: "media-body" },
                                          [
                                            _c(
                                              "strong",
                                              { staticClass: "mb-0 d-block" },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(
                                                      _vm.schedule.contact.name
                                                    ) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("fixPhone")(
                                                    _vm.schedule.contact
                                                      .phone_number
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "align-self-center text-muted",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-chevron-right",
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mode === "edit" && _vm.schedule.contact === null
                      ? _c("el-form-item", { attrs: { label: "Contact" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "card py-2 px-3",
                              staticStyle: { "line-height": "1.35" },
                            },
                            [
                              _c("div", { staticClass: "media-body" }, [
                                _c("strong", { staticClass: "mb-0 d-block" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.schedule.text) +
                                      "\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [_vm._v(" ")]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.schedule.type > 0 &&
              _vm.schedule.contact &&
              _vm.schedule.contact.id
                ? [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md" },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mb-0",
                              attrs: { label: "Date", prop: "date" },
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: "Pick a date",
                                  format: "MM/dd/yyyy",
                                  "value-format": "MM/dd/yyyy",
                                  "default-value": "04/03/2020",
                                  "data-testid": "reminder-manager-date-picker",
                                  type: "date",
                                  disabled:
                                    _vm.schedule.is_past || !_vm.isEditable,
                                  "picker-options": _vm.pickerOptions,
                                },
                                model: {
                                  value: _vm.schedule.date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.schedule, "date", $$v)
                                  },
                                  expression: "schedule.date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md" },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mb-0",
                              attrs: { label: "Time", prop: "time" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder: "Start time",
                                    "data-testid":
                                      "reminder-manager-start-time-select",
                                    disabled:
                                      _vm.schedule.is_past || !_vm.isEditable,
                                  },
                                  model: {
                                    value: _vm.schedule.time,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.schedule, "time", $$v)
                                    },
                                    expression: "schedule.time",
                                  },
                                },
                                _vm._l(_vm.times, function (time) {
                                  return _c("el-option", {
                                    key: time.value,
                                    attrs: {
                                      label: time.label,
                                      value: time.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.schedule.type == _vm.CommunicationTypes.APPOINTMENT
                        ? _c(
                            "div",
                            { staticClass: "col-md" },
                            [
                              _vm.schedule.type ==
                              _vm.CommunicationTypes.APPOINTMENT
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb-0",
                                      attrs: { label: "Duration (minutes)" },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            placeholder: "Select duration",
                                            "data-testid":
                                              "reminder-manager-duration-select",
                                            disabled:
                                              _vm.schedule.is_past ||
                                              !_vm.isEditable,
                                          },
                                          model: {
                                            value: _vm.schedule.duration,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.schedule,
                                                "duration",
                                                $$v
                                              )
                                            },
                                            expression: "schedule.duration",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "15 minutes",
                                              value: "15",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "30 minutes",
                                              value: "30",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "1 hour",
                                              value: "60",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "1 hour and 30 minutes",
                                              value: "90",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "2 hours",
                                              value: "120",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt-4", attrs: { label: "Timezone" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w-100",
                            attrs: {
                              placeholder: "Select Timezone",
                              filterable: "",
                              "data-testid": "reminder-manager-timezone-select",
                              disabled: !_vm.isEditable,
                            },
                            model: {
                              value: _vm.schedule.timezone,
                              callback: function ($$v) {
                                _vm.$set(_vm.schedule, "timezone", $$v)
                              },
                              expression: "schedule.timezone",
                            },
                          },
                          _vm._l(_vm.filteredTimezones, function (timezone) {
                            return _c("el-option", {
                              key: timezone.value,
                              attrs: {
                                label: timezone.name,
                                value: timezone.value,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Note" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "Write a note for this event...",
                            autosize: _vm.autoResizeOpt,
                            "data-testid": "reminder-manager-event-note-input",
                            disabled: !_vm.isEditable,
                          },
                          model: {
                            value: _vm.schedule.body,
                            callback: function ($$v) {
                              _vm.$set(_vm.schedule, "body", $$v)
                            },
                            expression: "schedule.body",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.schedule.is_past &&
                    _vm.schedule.type === _vm.CommunicationTypes.APPOINTMENT
                      ? _c(
                          "el-form-item",
                          [
                            _c("p", { staticClass: "m-1" }, [
                              _c("strong", [_vm._v("SMS reminder :")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-checkbox",
                              {
                                attrs: {
                                  "data-testid":
                                    "reminder-manager-event-sms-checkbox",
                                },
                                on: { change: _vm.updateSmsReminderVariables },
                                model: {
                                  value: _vm.sms_reminder_fields.enabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sms_reminder_fields,
                                      "enabled",
                                      $$v
                                    )
                                  },
                                  expression: "sms_reminder_fields.enabled",
                                },
                              },
                              [
                                _vm._v(
                                  "Enable SMS reminder\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.sms_reminder_fields.enabled
                              ? [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("strong", [_vm._v("Send from :")]),
                                      _vm._v(" "),
                                      _c("campaign-selector", {
                                        ref: "campaignSelector",
                                        attrs: {
                                          class_prop: "w-100",
                                          use_first_option: "",
                                          "data-testid":
                                            "reminder-manager-send-from-select",
                                          show_paused: false,
                                        },
                                        on: {
                                          change: _vm.updateSmsReminderLine,
                                        },
                                        model: {
                                          value:
                                            _vm.sms_reminder_fields.campaign_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sms_reminder_fields,
                                              "campaign_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "sms_reminder_fields.campaign_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c("strong", [_vm._v("Time :")]),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          attrs: {
                                            placeholder: "Select time",
                                            filterable: "",
                                            "data-testid":
                                              "reminder-manager-time-select",
                                          },
                                          model: {
                                            value: _vm.sms_reminder_fields.time,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sms_reminder_fields,
                                                "time",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sms_reminder_fields.time",
                                          },
                                        },
                                        _vm._l(_vm.times, function (time) {
                                          return _c("el-option", {
                                            key: time.value,
                                            attrs: {
                                              label: time.label,
                                              value: time.value,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("strong", [
                                    _vm._v("Send (n) days before:"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w-full",
                                      attrs: {
                                        filterable: "",
                                        "data-testid":
                                          "reminder-manager-send-days-before-select",
                                        multiple: "",
                                      },
                                      model: {
                                        value:
                                          _vm.sms_reminder_fields.frequencies,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.sms_reminder_fields,
                                            "frequencies",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "sms_reminder_fields.frequencies",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "0",
                                          label: "On appointment day",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "1",
                                          label: "1 day before",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "2",
                                          label: "2 days before",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "3",
                                          label: "3 days before",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "4",
                                          label: "4 days before",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "5",
                                          label: "5 days before",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "6",
                                          label: "6 days before",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "7",
                                          label: "7 days before",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("b", [_vm._v("Template variables :")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pb-2",
                                      staticStyle: { "line-height": "initial" },
                                    },
                                    _vm._l(
                                      _vm.sms_reminder_fields
                                        .template_variables,
                                      function (item) {
                                        return _c(
                                          "span",
                                          {
                                            key: item,
                                            staticClass:
                                              "text-danger sms-reminder-template-variables",
                                            on: {
                                              click: function ($event) {
                                                return _vm.addTemplateVariableToBody(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(item) +
                                                "\n                                "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    ref: "sms_reminder_body",
                                    attrs: {
                                      type: "textarea",
                                      maxlength: "255",
                                      "show-word-limit": "",
                                      "data-testid":
                                        "reminder-manager-sms-reminder-textarea",
                                    },
                                    model: {
                                      value: _vm.sms_reminder_fields.body,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.sms_reminder_fields,
                                          "body",
                                          $$v
                                        )
                                      },
                                      expression: "sms_reminder_fields.body",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.mode === "edit"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: {
                            size: "small",
                            "data-testid":
                              "reminder-manager-status-radio-group",
                            disabled: !_vm.isEditable,
                          },
                          model: {
                            value: _vm.schedule.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.schedule, "status", $$v)
                            },
                            expression: "schedule.status",
                          },
                        },
                        [
                          _vm.schedule.type ==
                          _vm.CommunicationTypes.APPOINTMENT
                            ? [
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      "data-testid":
                                        "reminder-manager-status-set",
                                      label:
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_APPOINTMENT_SET,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Set\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      "data-testid":
                                        "reminder-manager-status-shown",
                                      label:
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_APPOINTMENT_SHOWN,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.isSimpSocial
                                            ? "Shown"
                                            : "Completed"
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      "data-testid":
                                        "reminder-manager-status-cancel",
                                      label:
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_APPOINTMENT_CANCELED,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Canceled\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      "data-testid":
                                        "reminder-manager-status-missed",
                                      label:
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_APPOINTMENT_MISSED,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Missed\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            : [
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      "data-testid":
                                        "reminder-manager-status-pending",
                                      label:
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_PLACED_NEW,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Pending\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      "data-testid":
                                        "reminder-manager-status-completed",
                                      label:
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_COMPLETED_NEW,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Completed\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      "data-testid":
                                        "reminder-manager-status-canceled",
                                      label:
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_FAILED_NEW,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Canceled\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-button",
                                  {
                                    attrs: {
                                      "data-testid":
                                        "reminder-manager-status-missed",
                                      label:
                                        _vm.CommunicationDispositionStatus
                                          .DISPOSITION_STATUS_MISSED_NEW,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Missed\n                        "
                                    ),
                                  ]
                                ),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2 d-flex" }, [
                _vm.mode === "edit"
                  ? _c(
                      "div",
                      [
                        _vm.isEditable
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  disabled: _vm.loading,
                                  "data-testid":
                                    "reminder-manager-remove-button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteSchedule(_vm.schedule.id)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-remove" }),
                                _vm._v(
                                  "\n                        Remove\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          "data-testid": "reminder-manager-cancel-button",
                        },
                        on: { click: _vm.closeFiltersMenu },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _vm.isEditable
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              disabled: _vm.loading || !_vm.validated,
                              "data-testid":
                                "reminder-manager-set-event-button",
                            },
                            on: { click: _vm.saveSchedule },
                          },
                          [
                            _vm._v(
                              "\n                        Save Event\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }