var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            visible: _vm.visible,
            title: "Local Presence Wizard",
            width: "70vw",
            top: "4vh",
            "append-to-body": "",
            "custom-class": "wizard-dialog dialog-no-border",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.closeModal,
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("h5", { staticClass: "mb-0" }, [
              _c("i", { staticClass: "fas fa-hat-wizard" }),
              _vm._v("\n                Local Presence Wizard\n            "),
            ]),
          ]),
          _vm._v(" "),
          [
            _c(
              "el-steps",
              {
                staticClass: "mb-3",
                attrs: {
                  "align-center": "",
                  "finish-status": "success",
                  active: _vm.current_step,
                },
              },
              [
                _c("el-step", { attrs: { title: "Information" } }),
                _vm._v(" "),
                _c("el-step", { attrs: { title: "Confirmation" } }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.current_step === 0,
                      expression: "current_step === 0",
                    },
                  ],
                  staticClass: "step-1",
                },
                [
                  _c("p", { staticClass: "form-head font-weight-bold" }, [
                    _vm._v(
                      "\n                        Local Presence\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dropdown-divider" }),
                  _vm._v(" "),
                  _c("p", { staticStyle: { "word-break": "keep-all" } }, [
                    _vm._v(
                      "\n                        By dynamically matching your outbound Caller ID with the prospect's phone number,\n                        our local presence engine increases the likelihood of pickups and connections.\n                        With our service, you can use local area codes from anywhere in the US, the UK, and\n                        Canada, making your calls appear like they're coming from your customer's own neighborhood.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticStyle: { "word-break": "keep-all" } }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Note: "),
                    ]),
                    _vm._v(
                      " To enable SMS/MMS, a separate A2P Messaging Campaign is required for Local Presence lines.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("p", { staticClass: "mb3" }, [
                        _vm._v(
                          "\n                            Please, choose from the following\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "Select Country",
                            size: "large",
                          },
                          model: {
                            value: _vm.country,
                            callback: function ($$v) {
                              _vm.country = $$v
                            },
                            expression: "country",
                          },
                        },
                        _vm._l(
                          Object.entries(_vm.availableCountries),
                          function ([key, value]) {
                            return _c(
                              "el-option",
                              {
                                key: key,
                                attrs: {
                                  value: key,
                                  disabled:
                                    _vm.shouldDisableLocalPresenceOption(key),
                                },
                              },
                              [
                                _vm.shouldDisableLocalPresenceOption(key)
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: { content: _vm.country_tooltip },
                                      },
                                      [_c("span", [_vm._v(_vm._s(value))])]
                                    )
                                  : _c("span", [_vm._v(_vm._s(value))]),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "ml-3",
                      model: {
                        value: _vm.selectedLocalPresencePackage,
                        callback: function ($$v) {
                          _vm.selectedLocalPresencePackage = $$v
                        },
                        expression: "selectedLocalPresencePackage",
                      },
                    },
                    _vm._l(_vm.options, function (option, id) {
                      return _c(
                        "div",
                        { key: id, staticClass: "mb-3" },
                        [
                          _c(
                            "el-radio",
                            { attrs: { size: "large", label: option.name } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(option?.name) +
                                  " | " +
                                  _vm._s(
                                    _vm._f("toCurrency")(option?.data?.price)
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-right mt-4" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            disabled: _vm.selectedLocalPresencePackage == null,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.current_step += 1
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Next\n                            "
                          ),
                          _c("i", {
                            staticClass: "el-icon-arrow-right el-icon-right",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.current_step === 1,
                      expression: "current_step === 1",
                    },
                  ],
                  staticClass: "step-2",
                },
                [
                  _c("p", { staticClass: "form-head font-weight-bold" }, [
                    _vm._v(
                      "\n                        Confirm your purchase\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dropdown-divider" }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                        Your selected plan:\n                        "
                    ),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.countries[_vm.country]) +
                          " - " +
                          _vm._s(_vm.selectedLocalPresencePackage) +
                          " | " +
                          _vm._s(
                            _vm._f("toCurrency")(
                              _vm.getPriceByName(
                                _vm.selectedLocalPresencePackage
                              )
                            )
                          ) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                        By clicking Confirm, you are agreeing to be charged for this package on a per " +
                        _vm._s(_vm.period) +
                        " basis.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-right mt-4" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "warning" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.back.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Back\n                            "
                          ),
                          _c("el-icon", [_c("Close")], 1),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.closeModal.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Cancel\n                            "
                          ),
                          _c("el-icon", [_c("Close")], 1),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.proceed.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Confirm\n                            "
                          ),
                          _c("el-icon", [_c("Select")], 1),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }