<template>
    <div class="upgrade-now no-select"
         v-if="!isSimpSocial">
        <h3 class="title-text mt-4">{{ titleText }}</h3>
        <img class="mt-3 mb-3"
             :src="imageLink"/>
        <p class="info-text font-weight-bold">{{ text }}</p>
        <p class="info-text"
           v-if="extraText">
            {{ extraText }}
        </p>
        <div class="flex-row mb-4 mt-3">
            <el-button
                v-if="kbLink"
                size="large"
                type="text"
                @click="openKnowledgeBaseLink">
                Learn More
            </el-button>
            <el-button
                type="primary"
                size="large"
                v-if="showButton"
                @click="requestAloAiFeature"
                :loading="request_in_progress"
                round>
                {{ buttonText }}
            </el-button>
        </div>

        <el-dialog
            title="Welcome onboard!"
            :visible.sync="display_dialog"
            @close="hideRequestAloAIDialog"
            width="20%">
            <div class="dialog-text">
                <img src="/assets/images/app-icons/aloai-text-bot-gray.svg"
                    style="width: 50px;"
                    alt="aloai-icon">
                <p class="mt-2">
                    We have notified your Account Manager about your request. They should be in touch within a day or two. Thank you for your interest in AloAi.
                </p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="hideRequestAloAIDialog">Close</el-button>
            </span>
        </el-dialog>

    </div>

</template>

<script>

import {mapGetters, mapState} from "vuex"
import auth from '../auth'

export default {
    data() {
        return {
            auth: auth,
            display_dialog: false,
            request_error: null,
            request_in_progress: false
        }
    },

    props: {
        extraText: {
            type: String,
            required: false
        },
        text: {
            type: String,
            default: "This is not included in your current plan. To use it, please contact us to upgrade today!",
            required: false
        },
        buttonText: {
            type: String,
            default: "Request access to AloAi Text Bot",
            required: false
        },
        kbLink: {
            type: String,
            required: false
        },
        customLink: {
            type: String,
            required: false
        },
        defaultClick: {
            type: Boolean,
            default: true,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        titleText: {
            type: String,
            required: true
        },
        imageLink: {
            type: String,
            required: true
        },
        showButton: {
            type: Boolean,
            default: true,
            required: false
        }
    },

    computed: {
        ...mapGetters('cache', ['isSimpSocial', 'isModGen']),
        ...mapState('cache', ['current_company']),

        getPopoverMessageTask () {
            let task = ''

            switch (this.titleText) {
                case 'Broadcast':
                    task = 'broadcasts'
                    break;
                case 'Sequences+':
                    task = 'sequences'
                    break;
            }

            return task
        }
    },

    methods: {
        openKnowledgeBaseLink() {
            window.open(this.kbLink, "_blank")
        },

        requestAloAiFeature() {
            this.request_in_progress = true;
            axios.post(`/api/v1/company/${this.current_company.id}/request-aloai-access`)
                .then((res) => {
                    this.showRequestAloAIDialog()
                }).catch((err) => {
                    this.$notify({
                        offset: 95,
                        title: 'Alo Ai',
                        message: err.response?.data?.message || 'Whoops! Something went wrong.',
                        type: 'error',
                    })
                    console.log(err)
                })
                .finally(() => {
                    this.request_in_progress = false;
                })
        },

        showRequestAloAIDialog() {
            this.display_dialog = true
        },

        hideRequestAloAIDialog() {
            this.display_dialog = false
        },

    }
}
</script>

<style scoped>
    .upgrade-now {
        background: url('/assets/images/fomo/Gradient.png') no-repeat center center;
        background-size: contain;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .upgrade-now img {
        width: 100%;
        max-width: 616px;
    }

    .title-text {
        color: #000 !important;
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .info-text {
        font-size: 18px;
        line-height: 1.5;
        margin: 15px auto;
        max-width: 600px;
        text-align: center;
    }

    .el-button {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
    }
</style>
