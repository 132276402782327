var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "aup-dialog",
          attrs: {
            title: "Acceptable Use Policy",
            width: "50vw",
            top: "4vh",
            visible: _vm.aup_dialog,
            "append-to-body": true,
            "close-on-click-modal": false,
            "before-close": _vm.beforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.aup_dialog = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("h5", { staticClass: "mb-0" }, [
              _c("i", { staticClass: "fa fa-file-signature" }),
              _vm._v("\n                Acceptable Use Policy\n            "),
            ]),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-md _400 mb-1" }, [
            _vm._v(
              "By using Broadcast, you certify that you have read and will adhere to\n            our\n            "
            ),
            _c(
              "a",
              {
                staticClass: "text-dark-greenish",
                attrs: { href: _vm.aupLink, target: "_blank" },
              },
              [
                _vm._v(
                  "\n                acceptable use guidelines.\n            "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-md _400" }, [
            _vm._v(
              "\n            We are electronically capturing your consent as of " +
                _vm._s(_vm.auth.user.profile.name) +
                " (" +
                _vm._s(_vm.auth.user.profile.email) +
                ") on " +
                _vm._s(_vm.date) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("iframe", {
            staticClass: "mt-2 mb-2",
            staticStyle: { border: "0", width: "100%", height: "600px" },
            attrs: { sandbox: "allow-same-origin", src: _vm.aupLink },
          }),
          _vm._v(" "),
          _c(
            "el-checkbox",
            {
              staticClass: "checkbox-md mt-2",
              model: {
                value: _vm.aup_accepted,
                callback: function ($$v) {
                  _vm.aup_accepted = $$v
                },
                expression: "aup_accepted",
              },
            },
            [
              _vm._v(
                "\n            I have read and accept the Acceptable Use Policy\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.beforeClose } }, [
                _vm._v("\n                Cancel\n            "),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "success", disabled: !_vm.aup_accepted },
                  on: { click: _vm.saveConsent },
                },
                [_vm._v("\n                Confirm\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }