var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _c(
        "div",
        {
          staticClass: "fixed-header padding pt-0 pb-0",
          style: { top: _vm.top_offset + 50 + "px" },
        },
        [
          _c("div", { staticClass: "row mb-0 mt-0" }, [
            _c("div", { staticClass: "col-12 p-2" }, [
              _c(
                "div",
                { staticClass: "block pull-right" },
                [
                  _c("el-date-picker", {
                    ref: "datetime_picker",
                    attrs: {
                      type: "datetimerange",
                      "picker-options": _vm.datePickerOptions,
                      "default-time": ["00:00:00", "23:59:59"],
                      "range-separator": "To",
                      "start-placeholder": "Start date",
                      "end-placeholder": "End date",
                      align: "right",
                      "data-testid": "timeline-date-picker",
                    },
                    on: { change: _vm.changeDateRange },
                    model: {
                      value: _vm.date_time_rage,
                      callback: function ($$v) {
                        _vm.date_time_rage = $$v
                      },
                      expression: "date_time_rage",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasRole("Company Admin")
                    ? _c("div", { staticClass: "dropdown pull-right ml-2" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline b-dark-greenish text-dark-greenish",
                            attrs: {
                              disabled:
                                !_vm.user_activity.length ||
                                _vm.export_loading_btn,
                              "data-testid": "export-timeline-button",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.exportTimeline.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.export_loading_btn,
                                  expression: "export_loading_btn",
                                },
                              ],
                              staticClass: "fa fa-spin fa-spinner",
                            }),
                            _vm._v(
                              "\n                            Export\n                        "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.can_reset
                    ? _c(
                        "button",
                        {
                          staticClass: "btn deep-orange p-x-md pull-right ml-2",
                          attrs: { "data-testid": "reset-timeline-button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.resetDateFilters.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Reset Filters\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "padding mt-5" }, [
        _c("div", { staticClass: "row box" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "col-12 pt-lg-5",
              style: { "min-height": "600px" },
            },
            [
              _c("div", { staticClass: "block" }, [
                _vm.user_activity.length
                  ? _c(
                      "div",
                      { staticClass: "ml-lg-5" },
                      [
                        _c(
                          "el-timeline",
                          {
                            staticClass: "user_timeline",
                            attrs: { "data-testid": "el-timeline" },
                          },
                          _vm._l(_vm.user_activity, function (activity) {
                            return _c(
                              "el-timeline-item",
                              {
                                key: activity.id,
                                attrs: {
                                  "data-testid": "timeline-item",
                                  timestamp: _vm.getTimestamp(activity),
                                  color: _vm.getColor(activity.to),
                                  placement: "top",
                                },
                              },
                              [
                                _c("div", [
                                  activity.property == "agent_status"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                        Availability status has changed from\n                                        "
                                        ),
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fixAgentStatus")(
                                                activity.from
                                              )
                                            )
                                          ),
                                        ]),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "\n                                        Status has changed from\n                                        "
                                        ),
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("capitalize")(
                                                activity.from
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("to")]),
                                  _vm._v(" "),
                                  activity.property == "agent_status"
                                    ? _c("span", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fixAgentStatus")(
                                                activity.to
                                              )
                                            )
                                          ),
                                        ]),
                                      ])
                                    : _c("span", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("capitalize")(activity.to)
                                            )
                                          ),
                                        ]),
                                      ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n                                        at "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixDateTimeFull")(
                                            activity.created_at
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _vm.agent_audit_status &&
                                  _vm.agent_audit_status.id == activity.id
                                    ? _c(
                                        "span",
                                        { staticClass: "badge badge-warning" },
                                        [
                                          _vm._v(
                                            "\n                                        Current status of the user\n                                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  activity.property == "agent_status"
                                    ? _c("span", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fixAgentStatus")(
                                                activity.from
                                              )
                                            )
                                          ),
                                        ]),
                                      ])
                                    : _c("span", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("capitalize")(
                                                activity.from
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                  _vm._v(
                                    "\n                                    Duration: "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("fixFullLongDuration")(
                                          activity.duration
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                activity.property == "agent_status"
                                  ? _c(
                                      "small",
                                      { staticClass: "small text-grey-6" },
                                      [
                                        _vm._v(
                                          "\n                                    By: " +
                                            _vm._s(activity.source) +
                                            "\n                                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                activity.communication_id
                                  ? _c(
                                      "small",
                                      { staticClass: "small text-bluish _600" },
                                      [
                                        _vm._v(
                                          "\n                                    - "
                                        ),
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Communication",
                                                params: {
                                                  communication_id:
                                                    activity.communication_id,
                                                },
                                              },
                                            },
                                          },
                                          [_vm._v("Communication info")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "text-center mb-5" }, [
                      _vm._v("No activity\n                    "),
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 text-center" },
                    [
                      _vm.user_activity.length &&
                      _vm.last_page > 1 &&
                      _vm.last_page > _vm.page_counter
                        ? _c(
                            "el-button",
                            {
                              staticClass: "mb-4 mt-3",
                              attrs: {
                                type: "success",
                                size: "medium",
                                plain: "",
                                round: "",
                                "data-testid": "load-more-button",
                                disabled: _vm.loading_btn,
                                loading: _vm.loading_btn,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.loadMoreUserActivity.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Load more...\n                            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: {
          target: "html",
          bottom: 20,
          "data-testid": "timeline-backtop",
          right: 20,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }