// the endpoint's base url
let base_path = `/api/v2/contact-folders`

export default {
    /**
     * APIv2 endpoint for fetching contact folders.
     * This endpoint caters searching and has minimal return values
     *
     * @param search
     *
     * @handler Api/V2/Contacts/ContactFolderController@index
     *
     * @return Promise
     */
    searchContactFolders(search) {
        return axios.get(`${base_path}/search-contact-folders?search=${search}`)
    },
}
