var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      staticClass: "w-full",
      attrs: {
        loading: _vm.loading,
        remote: "",
        placeholder: "Choose a sequence",
        filterable: "",
        clearable: "",
      },
      model: {
        value: _vm.workflow_id,
        callback: function ($$v) {
          _vm.workflow_id = $$v
        },
        expression: "workflow_id",
      },
    },
    [
      _vm.activeWorkflowsAlphabeticalOrder &&
      _vm.activeWorkflowsAlphabeticalOrder.length > 0
        ? _c(
            "el-option-group",
            { key: "Active Sequences", attrs: { label: "Active Sequences" } },
            _vm._l(_vm.activeWorkflowsAlphabeticalOrder, function (workflow) {
              return _c("el-option", {
                key: workflow.id,
                attrs: { label: workflow.name, value: workflow.id },
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.pausedWorkflowsAlphabeticalOrder &&
      _vm.pausedWorkflowsAlphabeticalOrder.length > 0
        ? _c(
            "el-option-group",
            { key: "Paused Sequences", attrs: { label: "Paused Sequences" } },
            _vm._l(_vm.pausedWorkflowsAlphabeticalOrder, function (workflow) {
              return _c("el-option", {
                key: workflow.id,
                attrs: { label: workflow.name, value: workflow.id },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }