<template>
    <el-form
        v-loading="loading"
        :rules="rules"
        autocomplete="off"
        label-position="top"
    >
        <div class="row">
            <div class="col-md-12">
                <h5 class="text-dark">Welcome to the A2P 10DLC Campaign Wizard!</h5>
                <div class="dropdown-divider" />

                <p class="mt-3">
                    We are excited to have you on board with A2P Messaging, where we revolutionize communication through compliant messaging. Our A2P Campaign Wizard is designed to help you set up and manage your Messaging Campaigns with ease and compliance.
                </p>
                <p class="mt-3 font-weight-bold">
                    Why 10DLC?
                </p>
                <p class="mt-3">
                    10DLC offers a reliable and cost-effective way to send High-Volume messages with improved deliverability and compliance. Our wizard ensures you meet all regulatory requirements and maximize your messaging potential.
                </p>
                <p class="mt-3 font-weight-bold">
                    Let's Get Started!
                </p>
                <p class="mt-3">Click "Next" to begin setting up your A2P 10DLC campaign. If you need any assistance along the way, our dedicated support team is here to help.</p>

                <div class="row w-full mt-2">
                    <div class="col-md-8 mb-3 mb-md-0 flex-3">
                        <alert-message
                            :alert-message="alertMessage"
                            @alert="submitAlert"
                        />
                    </div>
                    <div class="w-full flex-1">
                        <el-button
                            type="success"
                            class="w-full"
                            :loading="loading"
                            @click="openConfirmationDialog"
                        >
                            Next: A2P Use Case
                            <i class="el-icon-arrow-right el-icon-right"></i>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
    compliance_mixin,
    form_validation_mixin,
    campaign_mixin
} from '../../../../mixins'
import AlertMessage from './alert-message'
import CampaignSelector from '../../../campaign-selector'
import {
    USE_CASES as UseCases,
    USE_CASE_UNDECLARED as UseCaseUndeclared,
    SCAN_MESSAGE_CONTENT as ScanMessageContentOptions
} from '../../../../constants/compliance/messaging-service'

export default {
    name: 'MessagingServiceRegistration',
    mixins: [
        compliance_mixin,
        form_validation_mixin,
        campaign_mixin
    ],
    components: {
        AlertMessage,
        CampaignSelector
    },
    props: {
        selected_lines: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            UseCases,
            ScanMessageContentOptions,
            current_pool_size: 400,
            loading: false,
            messagingServiceForm: {
                use_case: UseCaseUndeclared,
                sticky_sender: true,
                mms_converter: true,
                smart_encoding: true,
                area_code_geomatch: true,
                scan_message_content: 'inherit',
            },
            rules: {
                use_case: [{
                    required: true,
                    message: 'Please select a Use Case.',
                    trigger: 'change'
                }],
            },
        }
    },
    computed: {
        ...mapState({
            campaigns: state => state.campaigns
        }),
        ...mapState(['easy_wizard', 'unregistered_lines']),
        ...mapGetters('cache', ['isSimpSocial']),
    },
    methods: {
        openConfirmationDialog() {
            this.$confirm(`Are you sure you want to register this Messaging Service?`, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'w-25 fixed',
                confirmButtonClass: 'el-button--success',
                type: 'warning'
            }).then(() => {
                this.submitMessagingServiceForm()
            }).catch(() => {
                // User dismissed Messaging Service registration.
            })
        },
    },
}
</script>
