var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid pt-2" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h4", { staticClass: "text-black" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.message))]),
        _vm._v(" "),
        _c("p", [_vm._v("Fix by:")]),
        _vm._v(" "),
        _c("p", [_vm._v("1. Merging these contacts in HubSpot")]),
        _vm._v(" "),
        _c("p", [_vm._v("- OR -")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "2. Removing the duplicated phone number from either one of these contacts in HubSpot."
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "el-button",
            {
              staticClass: "bold text-deep-orange width-300 fixed",
              attrs: {
                size: "small",
                type: "deep-orange",
                disabled: _vm.loading_hubspot_sync,
                plain: "",
                round: "",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.syncHubspot.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "strong",
                [
                  _c(
                    "i",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loading_hubspot_sync,
                          expression: "loading_hubspot_sync",
                        },
                      ],
                      staticClass: "material-icons loader",
                    },
                    [_vm._v("")]
                  ),
                  _vm._v(" "),
                  _c(
                    "i",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.loading_hubspot_sync,
                          expression: "!loading_hubspot_sync",
                        },
                      ],
                      staticClass: "material-icons",
                    },
                    [_vm._v("sync")]
                  ),
                  _vm._v(" "),
                  _vm._t("default", function () {
                    return [_vm._v("Sync with HubSpot")]
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-popover",
            { attrs: { placement: "right", width: "350", trigger: "hover" } },
            [
              _c("div", { staticClass: "_400" }, [
                _c("p", [
                  _vm._v(
                    "Click on this button to sync the data for this contact between Aloware and HubSpot. You'll want to click\n            on\n            this button if:"
                  ),
                ]),
                _vm._v(" "),
                _c("ol", [
                  _c("li", [
                    _vm._v(
                      "The contact was recently merged in HubSpot with another contact."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v(
                      "You notice any inconsistencies between Aloware and HubSpot data on this contact."
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "i",
                {
                  staticClass: "material-icons text-blue cursor-pointer ml-2",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [_vm._v("info_outline")]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }