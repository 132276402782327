var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body", attrs: { id: "view" } }, [
    _c("div", { staticClass: "insights" }, [
      _c(
        "div",
        {
          staticClass: "padding mt-5 pt-3",
          staticStyle: { position: "relative", overflow: "hidden" },
          attrs: { id: "insights-filters" },
        },
        [
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-12 text-center" },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "col-12 no-select pull-left mt-1",
                    attrs: { size: "small" },
                    model: {
                      value: _vm.selectedInsight,
                      callback: function ($$v) {
                        _vm.selectedInsight = $$v
                      },
                      expression: "selectedInsight",
                    },
                  },
                  _vm._l(_vm.insights, function (insight) {
                    return _c(
                      "el-radio-button",
                      { key: insight.id, attrs: { label: insight } },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(insight.name) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.loadedInsightUrl
        ? _c(
            "div",
            { attrs: { id: "insights-show" } },
            [_c("insights-show", { attrs: { url: _vm.loadedInsightUrl } })],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }