<template>
    <div class="container"
         v-if="summary">
        <h4 v-if="title">{{ title }}</h4>
        <div class="row">
            <div class="col-md-6 mb-2"
                 v-if="summary.from">
                <el-card class="box-card h-100">
                    <div slot="header"
                         class="clearfix">
                        <span class="_600 text-md">From</span>
                    </div>
                    <table class="mb-2 w-100">
                        <tr v-if="summary.from.caller">
                            <td class="pt-2 text-muted">Caller</td>
                            <td class="text-right _400">
                                <span>{{ summary.from.caller }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.from.carrier">
                            <td class="pt-2 text-muted">Carrier</td>
                            <td class="text-right _400">
                                <span>{{ summary.from.carrier }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.from.city">
                            <td class="pt-2 text-muted">City</td>
                            <td class="text-right _400">
                                <span>{{ summary.from.city }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.from.location">
                            <td class="pt-2 text-muted">Location</td>
                            <td class="text-right _400">
                                <span>Lat: {{ summary.from.location.lat }}, Long: {{ summary.from.location.lon }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.from.connection">
                            <td class="pt-2 text-muted">Connection</td>
                            <td class="text-right _400">
                                <span>{{ summary.from.connection | snakeToTitle }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.from.country_code">
                            <td class="pt-2 text-muted">Country Code</td>
                            <td class="text-right _400">
                                <span>{{ summary.from.country_code }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.from.country_subdivision">
                            <td class="pt-2 text-muted">Country subdivision</td>
                            <td class="text-right _400">
                                <span>{{ summary.from.country_subdivision }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.from.ip_address">
                            <td class="pt-2 text-muted">IP address</td>
                            <td class="text-right _400">
                                <span>{{ summary.from.ip_address }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.from.number_prefix">
                            <td class="pt-2 text-muted">Number Prefix</td>
                            <td class="text-right _400">
                                <span>{{ summary.from.number_prefix }}</span>
                            </td>
                        </tr>
                    </table>
                </el-card>
            </div>
            <div class="col-md-6 mb-2"
                 v-if="summary.to">
                <el-card class="box-card h-100">
                    <div slot="header"
                         class="clearfix">
                        <span class="_600 text-md">To</span>
                    </div>
                    <table class="mb-2 w-100">
                        <tr v-if="summary.to.callee">
                            <td class="pt-2 text-muted">Callee</td>
                            <td class="text-right _400">
                                <span>{{ summary.to.callee }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.to.carrier">
                            <td class="pt-2 text-muted">Carrier</td>
                            <td class="text-right _400">
                                <span>{{ summary.to.carrier }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.to.location">
                            <td class="pt-2 text-muted">Location</td>
                            <td class="text-right _400">
                                <span>Lat: {{ summary.to.location.lat }}, Long: {{ summary.to.location.lon }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.to.connection">
                            <td class="pt-2 text-muted">Connection</td>
                            <td class="text-right _400">
                                <span>{{ summary.to.connection | fixName }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.to.country_code">
                            <td class="pt-2 text-muted">Country Code</td>
                            <td class="text-right _400">
                                <span>{{ summary.to.country_code }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.to.number_prefix">
                            <td class="pt-2 text-muted">Number Prefix</td>
                            <td class="text-right _400">
                                <span>{{ summary.to.number_prefix }}</span>
                            </td>
                        </tr>
                    </table>
                </el-card>
            </div>
            <div class="col-md-6 mb-2"
                 v-if="summary.carrier_edge">
                <el-card class="box-card h-100">
                    <div slot="header"
                         class="clearfix">
                        <span class="_600 text-md">Carrier Edge</span>
                    </div>
                    <table class="mb-2 w-100"
                           v-if="summary.carrier_edge.metrics">
                        <tr class="b-b"
                            v-if="summary.carrier_edge.metrics.inbound">
                            <td class="text-muted pb-2">Inbound Metrics</td>
                        </tr>
                        <tr v-if="summary.carrier_edge.metrics.inbound && summary.carrier_edge.metrics.inbound.codec_name">
                            <td class="pt-2 text-muted">Codec Name</td>
                            <td class="text-right _400">
                                <span>{{ summary.carrier_edge.metrics.inbound.codec_name }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.carrier_edge.metrics.inbound && summary.carrier_edge.metrics.inbound.jitter">
                            <td class="pt-2 text-muted">Jitter</td>
                            <td class="text-right _400">
                                <span>Avg: {{ summary.carrier_edge.metrics.inbound.jitter.avg }}, Max: {{ summary.carrier_edge.metrics.inbound.jitter.max }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.carrier_edge.metrics.inbound && summary.carrier_edge.metrics.inbound.packets_received !== undefined">
                            <td class="pt-2 text-muted">Packets received</td>
                            <td class="text-right _400">
                                <span>{{ summary.carrier_edge.metrics.inbound.packets_received | numFormat }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.carrier_edge.metrics.inbound && summary.carrier_edge.metrics.inbound.packets_lost !== undefined">
                            <td class="pt-2 text-muted">Packets lost</td>
                            <td class="text-right _400">
                                <span>{{ summary.carrier_edge.metrics.inbound.packets_lost | numFormat }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.carrier_edge.metrics.inbound && summary.carrier_edge.metrics.inbound.packets_loss_percentage !== undefined">
                            <td class="pt-2 text-muted">Packet loss percentage</td>
                            <td class="text-right _400">
                                <span>%{{ summary.carrier_edge.metrics.inbound.packets_loss_percentage }}</span>
                            </td>
                        </tr>
                        <tr class="b-b"
                            v-if="summary.carrier_edge.metrics.outbound">
                            <td class="text-muted pt-4 pb-2">Outbound Metrics</td>
                        </tr>
                        <tr v-if="summary.carrier_edge.metrics.outbound && summary.carrier_edge.metrics.outbound.codec_name">
                            <td class="pt-2 text-muted">Codec Name</td>
                            <td class="text-right _400">
                                <span>{{ summary.carrier_edge.metrics.outbound.codec_name }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.carrier_edge.metrics.outbound && summary.carrier_edge.metrics.outbound.jitter">
                            <td class="pt-2 text-muted">Jitter</td>
                            <td class="text-right _400">
                                <span>Avg: {{ summary.carrier_edge.metrics.outbound.jitter.avg }}, Max: {{ summary.carrier_edge.metrics.outbound.jitter.max }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.carrier_edge.metrics.outbound && summary.carrier_edge.metrics.outbound.packets_lost !== undefined">
                            <td class="pt-2 text-muted">Packets lost</td>
                            <td class="text-right _400">
                                <span>{{ summary.carrier_edge.metrics.outbound.packets_lost | numFormat }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.carrier_edge.metrics.outbound && summary.carrier_edge.metrics.outbound.packets_sent !== undefined">
                            <td class="pt-2 text-muted">Packets sent</td>
                            <td class="text-right _400">
                                <span>{{ summary.carrier_edge.metrics.outbound.packets_sent | numFormat }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.carrier_edge.metrics.outbound && summary.carrier_edge.metrics.outbound.packets_loss_percentage !== undefined">
                            <td class="pt-2 text-muted">Packet loss percentage</td>
                            <td class="text-right _400">
                                <span>%{{ summary.carrier_edge.metrics.outbound.packets_loss_percentage }}</span>
                            </td>
                        </tr>
                    </table>
                </el-card>
            </div>
            <div class="col-md-6 mb-2"
                 v-if="summary.client_edge">
                <el-card class="box-card h-100">
                    <div slot="header"
                         class="clearfix">
                        <span class="_600 text-md">Carrier Edge</span>
                    </div>
                    <table class="mb-2 w-100"
                           v-if="summary.client_edge.metrics">
                        <tr class="b-b"
                            v-if="summary.client_edge.metrics.inbound">
                            <td class="text-muted pb-2">Inbound Metrics</td>
                        </tr>
                        <tr v-if="summary.client_edge.metrics.inbound && summary.client_edge.metrics.inbound.codec_name">
                            <td class="pt-2 text-muted">Codec Name</td>
                            <td class="text-right _400">
                                <span>{{ summary.client_edge.metrics.inbound.codec_name }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.client_edge.metrics.inbound && summary.client_edge.metrics.inbound.jitter">
                            <td class="pt-2 text-muted">Jitter</td>
                            <td class="text-right _400">
                                <span>Avg: {{ summary.client_edge.metrics.inbound.jitter.avg }}, Max: {{ summary.client_edge.metrics.inbound.jitter.max }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.client_edge.metrics.inbound && summary.client_edge.metrics.inbound.packets_received !== undefined">
                            <td class="pt-2 text-muted">Packets received</td>
                            <td class="text-right _400">
                                <span>{{ summary.client_edge.metrics.inbound.packets_received | numFormat }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.client_edge.metrics.inbound && summary.client_edge.metrics.inbound.packets_lost !== undefined">
                            <td class="pt-2 text-muted">Packets lost</td>
                            <td class="text-right _400">
                                <span>{{ summary.client_edge.metrics.inbound.packets_lost | numFormat }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.client_edge.metrics.inbound && summary.client_edge.metrics.inbound.packets_loss_percentage !== undefined">
                            <td class="pt-2 text-muted">Packet loss percentage</td>
                            <td class="text-right _400">
                                <span>%{{ summary.client_edge.metrics.inbound.packets_loss_percentage }}</span>
                            </td>
                        </tr>
                        <tr class="b-b"
                            v-if="summary.client_edge.metrics.outbound">
                            <td class="text-muted pt-4 pb-2">Outbound Metrics</td>
                        </tr>
                        <tr v-if="summary.client_edge.metrics.outbound && summary.client_edge.metrics.outbound.codec_name">
                            <td class="pt-2 text-muted">Codec Name</td>
                            <td class="text-right _400">
                                <span>{{ summary.client_edge.metrics.outbound.codec_name }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.client_edge.metrics.outbound && summary.client_edge.metrics.outbound.jitter">
                            <td class="pt-2 text-muted">Jitter</td>
                            <td class="text-right _400">
                                <span>Avg: {{ summary.client_edge.metrics.outbound.jitter.avg }}, Max: {{ summary.client_edge.metrics.outbound.jitter.max }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.client_edge.metrics.outbound && summary.client_edge.metrics.outbound.packets_lost !== undefined">
                            <td class="pt-2 text-muted">Packets lost</td>
                            <td class="text-right _400">
                                <span>{{ summary.client_edge.metrics.outbound.packets_lost | numFormat }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.client_edge.metrics.outbound && summary.client_edge.metrics.outbound.packets_sent !== undefined">
                            <td class="pt-2 text-muted">Packets sent</td>
                            <td class="text-right _400">
                                <span>{{ summary.client_edge.metrics.outbound.packets_sent | numFormat }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.client_edge.metrics.outbound && summary.client_edge.metrics.outbound.packets_loss_percentage !== undefined">
                            <td class="pt-2 text-muted">Packet loss percentage</td>
                            <td class="text-right _400">
                                <span>%{{ summary.client_edge.metrics.outbound.packets_loss_percentage }}</span>
                            </td>
                        </tr>
                    </table>
                </el-card>
            </div>
            <div class="col-md-6 mb-2"
                 v-if="summary.properties || summary.carrier_edge">
                <el-card class="box-card h-100">
                    <div slot="header"
                         class="clearfix">
                        <span class="_600 text-md">Properties</span>
                    </div>
                    <table class="mb-2 w-100">
                        <tr v-if="summary.properties.disconnected_by">
                            <td class="pt-2 text-muted">Disconnected by</td>
                            <td class="text-right _400">
                                <span class="_600 text-danger">{{ summary.properties.disconnected_by | fixName }}</span>
                            </td>
                        </tr>
                        <tr v-if="summary.properties.pdd_ms">
                            <td class="pt-2 text-muted">PDD</td>
                            <td class="text-right _400">
                                <span>{{ summary.properties.pdd_ms }}ms</span>
                            </td>
                        </tr>
                        <template v-if="summary.carrier_edge && summary.carrier_edge.properties">
                            <tr v-if="summary.carrier_edge.properties.media_region">
                                <td class="pt-2 text-muted">Media region</td>
                                <td class="text-right _400">
                                    <span>{{ summary.carrier_edge.properties.media_region }}</span>
                                </td>
                            </tr>
                            <tr v-if="summary.carrier_edge.properties.edge_location">
                                <td class="pt-2 text-muted">Edge location</td>
                                <td class="text-right _400">
                                    <span>{{ summary.carrier_edge.properties.edge_location | fixName }}</span>
                                </td>
                            </tr>
                            <tr v-if="summary.carrier_edge.properties.signaling_region">
                                <td class="pt-2 text-muted">Signaling region</td>
                                <td class="text-right _400">
                                    <span>{{ summary.carrier_edge.properties.signaling_region }}</span>
                                </td>
                            </tr>
                        </template>
                    </table>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
import {acl_mixin} from '../mixins'

export default {
    name: "communication-summary",

    mixins: [acl_mixin],

    props: {
        title: {
            required: false,
            type: String,
        },

        summary: {
            required: true,
            type: Object,
            default: function() {
                return null
            }
        }
    },

    data() {
        return {
            auth: auth,
        }
    },
}
</script>
