<template>
  <div class="row">
    <div class="col-md-12">
      <AuditsTable
          model_alias="line"
          :model_id="campaign_id"
          :action_filters="AuditConfig.actionFilters"
          :text_configs="AuditConfig.textConfigs"
          :filter_options="filter_options"
          ref="auditTable"
      >Line Activity Audit Log
        <template #user-selector="{onUserFilterChange}">
          <user-selector
              :ignore_focus_mode="true" placeholder="Search Agent" @change="onUserFilterChange"
          ></user-selector>
        </template>
        <template #date-selector>
          <date-selector class="filter-select-audits" :disable_default_report_period="true"></date-selector>
        </template>
      </AuditsTable>
    </div>
  </div>
</template>

<script>
import AuditsTable from "../../../../../../public/vendor/auditable/audits-table.vue";
import DateSelector from "../../../components/date-selector.vue";
import {mapGetters} from "vuex";
import * as AuditConfig from "../../../constants/audits/line/audit-config";

export default {
  name: 'line-audit',

  components: {
    DateSelector,
    AuditsTable,
  },

  computed: {
    ...mapGetters({
      filter_options: 'getFilter'
    }),
  },

  props: {
    campaign_id: {required: true},
  },

  data() {
    return {
      AuditConfig
    }
  },

  methods: {
    update() {
      this.$refs.auditTable?.getAudits()
    }
  }
}
</script>
