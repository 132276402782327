<template>
    <div v-if="hasPermissionTo('create campaign')" data-testid="line-creator-wrapper">
        <el-dialog v-if="use_old_wizard"
                   :visible.sync="dialogVisible"
                   :before-close="beforeCloseModal"
                   top="10vh"
                   append-to-body
                   custom-class="wizard-dialog dialog-no-border"
                   data-testid="line-creator-dialog"
                   @close="closeModal">
            <campaign-wizard :key="prop_counter"
                             :is_fax="is_fax"
                             data-testid="campaign-wizard"
                             @created="campaignCreated">
            </campaign-wizard>
        </el-dialog>
        <el-dialog v-else
                   :visible.sync="dialogVisible_new"
                   :before-close="beforeCloseModal"
                   :title="is_fax ? 'New Fax' : 'New Line'"
                   width="70vw"
                   top="4vh"
                   append-to-body
                   custom-class="wizard-dialog dialog-no-border"
                   data-testid="line-creator-dialog"
                   @close="closeModal">
            <div slot="title"
                 data-testid="new-wizard-title">
                <h5 class="mb-0">
                    <i class="fas fa-hat-wizard"></i>
                    New {{ is_fax ? 'Fax' : 'Line' }} Wizard
                </h5>
            </div>
            <line-wizard :key="prop_counter"
                         :is_fax="is_fax"
                         data-testid="line-wizard"
                         @created="campaignCreated">
            </line-wizard>
        </el-dialog>

        <el-dialog :visible.sync="restrictionMessage"
                   top="10vh"
                   title="New Line"
                   append-to-body
                   custom-class="wizard-dialog dialog-no-border"
                   data-testid="restriction-message-dialog"
                   @close="closeRestrictionMessage">
            <span> You can only add up to {{ kycLimitOfLines }} lines </span>
            <span slot="footer"
                  class="dialog-footer">
                <el-button data-testid="close-restriction-message-btn"
                           @click="restrictionMessage = false">
                           Close
                </el-button>
            </span>
        </el-dialog>

        <slot name="trigger">
            <el-popover v-if="!enabledToAddLines()"
                        ref="create-line-popover"
                        placement="top"
                        width="200"
                        data-testid="create-line-popover"
                        popper-class="btn-primary"
                        trigger="hover">
                <custom-message-display :config="customMessage('line.create')" />
            </el-popover>
            <button v-if="!enabledToAddLines()"
                    class="btn btn-md rounded"
                    :class="is_fax ? 'orangish' : 'greenish'"
                    v-popover:create-line-popover
                    disabled
                    data-testid="create-line-btn-disabled"
                    @click="openModal">
                <i class="fa" :class="is_fax ? 'fa-fax' : 'fa-plus'"></i>
                <span v-if="is_fax">
                    Add Fax
                </span>
                <span v-else>
                    Add Line
                </span>
            </button>
            <button v-if="enabledToAddLines()"
                    class="btn btn-md rounded"
                    :class="is_fax ? 'orangish' : 'greenish'"
                    data-testid="create-line-btn"
                    @click="openModal">
                <i class="fa" :class="is_fax ? 'fa-fax' : 'fa-plus'"></i>
                <span v-if="is_fax">
                    Add Fax
                </span>
                <span v-else>
                    Add Line
                </span>
            </button>
        </slot>
    </div>
</template>

<script>
import auth from '../auth'
import { acl_mixin, kyc_mixin } from '../mixins'
import CampaignContactCenterWizard from "./line-wizard";
import {mapState} from 'vuex'
import CustomMessageDisplay from './kyc/custom-message-display'

export default {
    components: {CampaignContactCenterWizard, CustomMessageDisplay},
    mixins: [ acl_mixin, kyc_mixin ],

    props: {
        is_fax: {
            required: false,
            type: Boolean,
            default: false
        },
        use_old_wizard: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            auth: auth,
            prop_counter: 0,
            dialogVisible: false,
            dialogVisible_new: false,
            campaign_created: false,
            kycLimitOfLines: 5,
            restrictionMessage: false
        }
    },

    computed: {
        ...mapState(['campaigns', 'ring_groups']),

        limitOfNumbersReached () {
            return this.isTrialKYC && this.campaigns && this.campaigns.length >= this.kycLimitOfLines
        }
    },

    methods: {
        openModal() {
            if (this.limitOfNumbersReached) {
                this.restrictionMessage = true
                return
            }
            if (this.use_old_wizard) {
                this.dialogVisible = true
            } else {
                this.dialogVisible_new = true
            }
            this.campaign_created = false
        },

        beforeCloseModal(done) {
            if (!this.campaign_created) {
                this.$confirm('Are you sure you want to leave. Your changes will not be saved.', 'Warning', {
                    confirmButtonText: 'Yes, Leave',
                    cancelButtonText: 'No, Stay',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(res => {
                    done()
                }).catch(err => {
                    console.log(err)
                })
            } else {
                done()
            }
        },

        closeModal() {
            this.dialogVisible = false
            this.campaign_created = false
            setTimeout(() => {
                this.prop_counter += 1
            }, 200)
        },

        closeRestrictionMessage() {
            this.restrictionMessage = false
        },

        campaignCreated() {
            this.campaign_created = true
        }
    }
}
</script>
