export const BROADCAST_DEPRECATION_MESSAGE = `This <b>Admin Broadcast</b> feature will be sunset by end of the year. Please <a class="alert-link text-decoration-underline" href="<%TALK_URL%>/broadcasts">click here</a> for the new <b>Talk Broadcast</b>.`
export const TAGS_DEPRECATION_MESSAGE = `We are migrating <b>contact tags</b> behavior into <b>Lists</b> by the end of the year. Please <a class="alert-link text-decoration-underline" href="${window.location.origin}/lists/">click here</a> to utilize the <b>lists page.</b>`
export const TAGS_DEPRECATION_IMPORT_CONTACTS_MESSAGE = `Starting Oct 15, <b>contact imports</b> will generate a <b>List</b> in addition to a tag. By the end of the year, we will <b>migrate contact tags to lists.</b>`
export const WALLBOARD_BARGE_WHISPER_DEPRECATION_MESSAGE = `By the end of the year, the ability to barge and whisper in Admin Wallboard will no longer be available. Please <a class="alert-link text-decoration-underline" href="<%TALK_URL%>/wallboard/overview" >click here</a> to barge and whisper in <b>Talk Wallboard.</b>`

// MESSAGE FUNCTION TO REPLACE THE PLACEHOLDERS
export const buildMessage = (message, placeholders) => {
  let newMessage = message
  for (const key in placeholders) {
    newMessage = newMessage.replace(new RegExp(`<%${key.toUpperCase()}%>`, 'g'), placeholders[key])
  }
  return newMessage
}
