<template>
    <div class="w-full">
        <el-select v-if="contact"
                   v-model="dispositionStatusId"
                   :disabled="disabled || loading_dispose"
                   :multiple="false"
                   class="w-full"
                   clearable
                   filterable
                   :placeholder="placeholder"
                   data-testid="contact-disposition-select"
                   @change="dispose">
            <el-option
                v-for="(disposition_status, index) in dispositionStatusesAlphabeticalOrder"
                :key="disposition_status.id + '-disp-' + index"
                :label="disposition_status.name"
                :value="disposition_status.id">
                <template v-if="disposition_status.is_external !== undefined">
                    <i v-if="disposition_status.is_external"
                       :style="{ color: disposition_status.color }"
                       class="material-icons">lock</i>
                    <i v-else
                       :style="{ color: disposition_status.color }"
                       class="material-icons">label</i>
                </template>
                <span>{{ disposition_status.name }}</span>
            </el-option>
        </el-select>
    </div>
</template>

<script>
import auth from '../auth'
import {mapState} from 'vuex'
import {acl_mixin} from '../mixins'
import * as Roles from "../constants/roles";
export default {
    mixins: [acl_mixin],

    props: {
        is_contact_page: {
            required: false,
            default: false,
            type: Boolean
        },

        disabled: {
            required: false,
            default: false,
            type: Boolean
        },

        placeholder: {
            required: false,
            default: 'Select disposition code',
            type: String
        },

        contact: {
            required: false
        },
    },

    data() {
        return {
            auth: auth,
            loading_dispose: false,
            disposition_status_id: null,
            Roles
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        isCompanyAgent() {
            return this.hasRole(Roles.COMPANY_AGENT)
        },

        dispositionStatusesAlphabeticalOrder() {
            if (this.disposition_statuses) {
                let disposition_statuses = _.clone(this.disposition_statuses)
                    .sort((a, b) => {
                        let textA = a.name.toUpperCase()
                        let textB = b.name.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })

                return disposition_statuses
            }
            return []
        },

        computedContact() {
            if (this.contact) {
                return this.contact
            }

            return {
                id: null,
                disposition_status_id: null
            }
        },

        dispositionStatusId: {
            get: function() {
                return this.disposition_status_id
            },

            set: function(disposition_status_id) {
                this.disposition_status_id = disposition_status_id
            }
        },

        ...mapState(['disposition_statuses']),
    },

    mounted() {
        this.fixDispositionStatusId()

        if (!this.is_contact_page && this.contact && this.contact.disposition_status_id) {
            this.$emit('contactDisposed')
        }
    },

    methods: {
        fixDispositionStatusId() {
            // if contact disposition is hidden from agent and it's the contact disposition set in the contact,
            // retain the null value. else, use the contact disposition
            let found = this.dispositionStatusesAlphabeticalOrder.find(disposition_status => disposition_status.id === this.contact.disposition_status_id)
            if (found) {
                this.disposition_status_id = this.contact.disposition_status_id
            }
        },

        dispose(disposition_status) {
            this.loading_dispose = true
            axios.post('/api/v1/contact/' + this.contact.id + '/dispose', {disposition_status})
                .then((res) => {
                    this.loading_dispose = false
                    this.disposition_status_id = res.data.disposition_status_id
                    let message = 'Contact disposed'
                    if (!this.dispositionStatusId) {
                        message = 'Contact undisposed'
                    }
                    if (this.is_contact_page) {
                        message = 'Contact disposition status updated successfully'
                    }
                    // Notify only if not in widget
                    if (!this.$root.$data.is_widget) {
                        this.$notify({
                            offset: 95,
                            title: 'Contact',
                            message: message,
                            type: 'success',
                            showClose: true,
                        })
                    }
                    if (this.is_contact_page) {
                        this.$emit('contactUpdated', res.data)
                    } else {
                        this.$emit('contactDisposed', res.data.disposition_status_id)
                    }
                }).catch((err) => {
                this.loading_dispose = false
                this.$root.handleErrors(err.response)
            })
        }
    },

    watch: {
        'computedContact.disposition_status_id': function () {
            this.disposition_status_id = this.computedContact.disposition_status_id
            if (!this.is_contact_page && this.computedContact.disposition_status_id) {
                this.$emit('contactDisposed')
            }
        }
    }
}
</script>
