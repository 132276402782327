<template>
    <div>
        <div class="pl-2 pr-2 clear">
            <div class="row box">
                <div class="col-12">
                    <div class="box-header">
                        <p class="text-muted text-xl mb-0">Phone Numbers</p>
                        <hr>
                        <strong class="text-md">Rent number from:</strong>
                        <country-selector :initial_countries="countries"
                                          :disabled="disable_country_phone_number_selector"
                                          @changed="changeCountryPhoneNumber">
                        </country-selector>
                    </div>
                    <div class="box-body">
                        <el-divider v-if="country_phone_number">
                        <span class="text-lg">
                            <span class="ml-2">{{ getCountryName(country_phone_number) }}</span>
                        </span>
                        </el-divider>
                        <el-table :data="phone_number_prices"
                                  v-loading="loading_phone_number"
                                  class="w-full"
                                  cell-class-name="text-md _600"
                                  fit
                                  stripe>
                          <el-table-column label="Type" prop="type_label"></el-table-column>
                          <el-table-column label="Price" prop="price" :formatter="formatPhoneNumberPrice"></el-table-column>
                          <el-table-column align="right">
                            <template v-slot="scope">
                              <el-button size="mini"
                                         type="success"
                                         @click="addPhoneNumberRequestTicket(scope.row.type)">
                                {{scope.row.price ? 'Request Phone Number' : 'Request Price'}}
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-3 pl-2 pr-2 clear">
            <div class="row box">
                <div class="col-12">
                    <div class="box-header">
                        <p class="text-muted text-xl mb-0">Call Rates</p>
                        <hr>
                        <strong class="text-md">Make calls to:</strong>
                        <country-selector :initial_countries="countries"
                                          :disabled="disable_country_call_selector"
                                          @changed="changeCountryCall">
                        </country-selector>
                    </div>
                    <div class="box-body">
                        <el-divider v-if="country_call">
                        <span class="text-lg">
                            <span class="ml-2">{{ getCountryName(country_call) }}</span>
                        </span>
                        </el-divider>
                        <el-table :data="call_pricing_info"
                                  class="w-full"
                                  v-loading="loading_call"
                                  stripe>
                            <el-table-column type="expand">
                                <template v-slot="props">
                                    <strong>Origination Prefixes:</strong>
                                    <span class="ml-2">{{ props.row.origination_prefixes.join(', ') }}</span>
                                    <br>
                                    <strong>Destination Prefixes:</strong>
                                    <span class="ml-2">{{ props.row.destination_prefixes.join(', ') }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="friendly_name"
                                             label="Route"
                                             min-width="300">
                            </el-table-column>
                            <el-table-column prop="current_price"
                                             label="Price starts from"
                                             width="150">
                                <template v-slot="scope">
                                    <div>
                                        <span class="text-md _600">{{ scope.row.current_price | fixRounding | toCurrency }}</span>
                                        <span class="text-muted">/min</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-3 pl-2 pr-2 clear">
            <div class="row box">
                <div class="col-12">
                    <div class="box-header">
                        <p class="text-muted text-xl mb-0">SMS Rates</p>
                        <hr>
                        <strong class="text-md">Send messages to:</strong>
                        <country-selector :initial_countries="countries"
                                          :disabled="disable_country_sms_selector"
                                          @changed="changeCountrySms">
                        </country-selector>
                    </div>
                    <div class="box-body">
                        <el-divider v-if="country_sms">
                        <span class="text-lg">
                            <span class="ml-2">{{ getCountryName(country_sms) }}</span>
                        </span>
                        </el-divider>
                        <el-table :data="sms_pricing_info"
                                  class="w-full"
                                  v-loading="loading_sms"
                                  stripe>
                            <el-table-column prop="carrier"
                                             label="Carrier"
                                             min-width="300">
                            </el-table-column>
                            <el-table-column prop="mobile"
                                             label="To Mobile"
                                             width="150">
                                <template v-slot="scope">
                                    <div>
                                        <span class="text-md _600">{{ scope.row.mobile | fixRounding | toCurrency }}</span>
                                        <span class="text-muted">/message</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="local"
                                             label="To Local"
                                             width="150">
                                <template v-slot="scope">
                                    <div>
                                        <span class="text-md _600">{{ scope.row.local | fixRounding | toCurrency }}</span>
                                        <span class="text-muted">/message</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin, scroll_mixin} from '../mixins'
    import * as Countries from '../constants/countries'

    export default {
        mixins: [acl_mixin, scroll_mixin],

        data() {
            return {
                auth: auth,
                loading_call: false,
                loading_sms: false,
                loading_phone_number: false,
                country_call: null,
                country_sms: null,
                country_phone_number: null,
                call_pricing_info: null,
                sms_pricing_info: null,
                phone_number_pricing_info: null,
                countries: Countries.COUNTRIES.filter(country => !['US', 'UM', 'CA'].includes(country.code)),
                disable_country_call_selector: false,
                disable_country_sms_selector: false,
                disable_country_phone_number_selector: false
            }
        },
        computed: {
          phone_number_prices : function() {
            return this.phone_number_pricing_info ? [
              {
                type: 'mobile',
                type_label: 'Mobile',
                price: this.phone_number_pricing_info.mobile
              },
              {
                type: 'local',
                type_label: 'Local',
                price: this.phone_number_pricing_info.local
              },
              {
                type: 'tollfree',
                type_label: 'Toll Free',
                price: this.phone_number_pricing_info.tollfree
              }
            ] : []
          }
        },
        methods: {
            formatPhoneNumberPrice(row, column) {
              if (row.price) {
                return this.$options.filters.toCurrency((row.price));
              }
              return '-';
            },
            getCountryName(iso_code) {
                let country = this.countries.find(country => country.code === iso_code)
                if (country) {
                    return country.name
                }
            },

            getCallPricing() {
                this.loading_call = true
                this.disable_country_call_selector = true
                axios.get('/api/v1/international-pricing/call', {
                    params: {
                        country: this.country_call,
                    }
                }).then((res) => {
                    this.call_pricing_info = res.data
                    this.loading_call = false
                    this.disable_country_call_selector = false
                }).catch(err => {
                    this.loading_call = false
                    this.$root.handleErrors(err.response)
                    this.disable_country_call_selector = false
                    console.log(err)
                })
            },

            getPhoneNumberPricing() {
                this.loading_phone_number = true
                this.disable_country_phone_number_selector = true
                axios.get('/api/v1/international-pricing/phone-number', {
                    params: {
                        country: this.country_phone_number,
                    }
                }).then((res) => {
                    this.phone_number_pricing_info = res.data
                    this.loading_phone_number = false
                    this.disable_country_phone_number_selector = false
                }).catch(err => {
                    this.loading_phone_number = false
                    this.$root.handleErrors(err.response)
                    this.disable_country_phone_number_selector = false
                    console.log(err)
                })
            },

            getSmsPricing() {
                this.loading_sms = true
                this.disable_country_sms_selector = true
                axios.get('/api/v1/international-pricing/sms', {
                    params: {
                        country: this.country_sms,
                    }
                }).then((res) => {
                    this.sms_pricing_info = res.data
                    this.loading_sms = false
                    this.disable_country_sms_selector = false
                }).catch(err => {
                    this.loading_sms = false
                    this.$root.handleErrors(err.response)
                    this.disable_country_sms_selector = false
                    console.log(err)
                })
            },

            changeCountryCall(country_code) {
                this.country_call = country_code
                this.getCallPricing()
            },

            changeCountrySms(country_code) {
                this.country_sms = country_code
                this.getSmsPricing()
            },

            changeCountryPhoneNumber(country_code) {
                this.country_phone_number = country_code
                this.getPhoneNumberPricing()
            },

          addPhoneNumberRequestTicket(phone_number_price_type = null) {
                if (!this.country_phone_number) {
                    this.$notify({
                        offset: 95,
                        title: 'Phone Number Request',
                        message: 'Please select a country first.',
                        type: 'error',
                    })
                }

                if (!phone_number_price_type) {
                    this.$notify({
                        offset: 95,
                        title: 'Phone Number Request',
                        message: 'Invalid phone number type.',
                        type: 'error',
                    })
                }

                axios.post('/api/v1/international-pricing/phone-number/request-ticket', {
                    country: this.getCountryName(this.country_phone_number),
                    phone_number_price_type
                }).then((res) => {
                    this.$notify({
                        offset: 95,
                        title: 'Phone Number Request',
                        message: 'Successfully requested!',
                        type: 'success',
                    })
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    console.log(err)
                })
            }
        }
    }

</script>
