<template>
    <input ref="phoneInput"
           class="iti__tel-input"
           :class="{ 'error': hasError }">
</template>

<script>
import jQuery from 'jquery'
import IntlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'

export default {
    name: "phone",

    props: {
        hasError: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            input: null,
            phoneNumber: ''
        }
    },

    methods: {
        updateValue() {
            try {
                const rawNumber = this.input.getNumber();
                this.phoneNumber = rawNumber;

                if (this.input.isValidNumber()) {
                    const countryData = this.input.getSelectedCountryData();

                    return this.$emit('input', { phoneNumber: this.phoneNumber, countryData });
                }

                return this.$emit('input', { phoneNumber: this.phoneNumber });
            } catch (e) {
                console.log(e);
            }
        },

        restrictToNumbers(event) {
            if (!/[0-9]/.test(event.key) && !event.ctrlKey && !event.metaKey && !event.altKey && event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                event.preventDefault();
            }
        }
    },

    mounted () {
        const phoneInput = this.$refs.phoneInput

        this.input = IntlTelInput(phoneInput, {
            initialCountry: 'us',
            separateDialCode: true,
            preferredCountries: ['us', 'gb', 'ca', 'au', 'nz', 'ie'],
            utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.12/build/js/utils.js',
        })

        jQuery(phoneInput).on("countrychange", () => {
            this.updateValue()
        })

        phoneInput.addEventListener('input', this.updateValue)
        phoneInput.addEventListener('keydown', this.restrictToNumbers)
    },

    beforeDestroy() {
        this.input.destroy()
    }
}

</script>
