<template>
    <el-dialog custom-class="dialog-kyc"
               :visible.sync="show"
               :before-close="handleClose"
               :modal-append-to-body="true"
               :append-to-body="true">
        <div class="text-center">
            <h6>
                Welcome aboard! 🎉
                <br>
                We're thrilled to have you join us! 🌟
            </h6>

            <p>
                To unlock your full trial and explore all the features, please submit your business information. Until then, you're set to call only your own numbers. 📞🔒
            </p>
        </div>

        <span slot="footer" class="dialog-kyc-footer">
            <el-button type="text"
                       round
                       @click="changeShowedKycDialog">
                Remind me later
            </el-button>
            <el-button type="primary"
                       round
                       @click="openKycBusinessRegistration">
                Submit info
            </el-button>
        </span>
    </el-dialog>
</template>

<script>
import auth from '../auth'
import {mapActions, mapState} from 'vuex'
import {kyc_mixin} from '../mixins'

export default {
    name: 'KycFillDialog',

    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    mixins: [kyc_mixin],

    data() {
        return {
            profile: auth.user.profile
        }
    },

    computed: {
        ...mapState(['showedKycDialog']),

        ...mapState('cache', ['current_company']),

        userFullName() {
            return `${this.profile?.first_name} ${this.profile?.last_name}` || ''
        }
    },

    methods: {
        ...mapActions(['setShowedKycDialog']),

        openKycBusinessRegistration() {
            this.changeShowedKycDialog()
            this.onOpenFinishRegistration()
        },

        changeShowedKycDialog() {
            this.setShowedKycDialog(true)
        },

        handleClose(done) {
            this.changeShowedKycDialog()
        }
    }
}
</script>
