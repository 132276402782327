<template>
    <div class="app-body"
         id="view">
        <div class="container-fluid py-3">
            <div class="card"
                 style="min-height: calc(100vh - 130px)">
                <div class="card-body">
                    <div class="d-flex mb-3">
                        <router-link :to="{name: 'Integrations'}">
                            <el-button class="mr-3" size="small"><i class="fa fa-chevron-left"></i></el-button>
                        </router-link>
                        <div class="flex-grow-1 align-self-center">
                            <h4 class="text-dark mb-0 align-self-center">Webhooks</h4>
                        </div>
                        <el-button round
                                   size="small"
                                   class="ml-auto"
                                   type="success"
                                   @click="$refs.webhook_manager.add()">
                            <strong>Add Webhook</strong>
                        </el-button>
                    </div>

                    <div v-if="webhooks.length > 0"
                         v-loading="webhooks_loading">
                        <table class="table table-aloware"
                               :key="table_key">
                            <thead>
                            <tr>
                                <th>Webhook</th>
                                <th>Target</th>
                                <th>Delay</th>
                                <th>Events</th>
                                <th>Filters</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="webhook in webhooks"
                                :key="webhook.id">
                                <td style="max-width: 350px">
                                    <div>
                                        <h6 class="mb-0 text-dark">{{ webhook.name }}</h6>
                                        <p>{{ webhook.description | strLimit(100) }}</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <h6 class="text-greenish mb-0">{{ webhook.url }}</h6>
                                        <small class="text-dead" v-if="webhook.http_method == Webhook.HTTP_METHOD_POST">
                                            HTTP POST Request
                                        </small>
                                    </div>
                                </td>
                                <td>
                                    <span v-if="webhook.delay > 0">{{ webhook.delay }} seconds</span>
                                    <span v-else>No Delay</span>
                                </td>
                                <td>
                                    <ul class="list-unstyled">
                                        <li v-for="event in webhook.event_names"
                                            :key="event">
                                            {{ event }}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <span v-if="webhook.filter_size">
                                        {{ webhook.filter_size }} filters
                                    </span>
                                    <span v-else
                                          class="text-dead">
                                        No filters added
                                    </span>
                                </td>
                                <td>
                                    <div v-if="!webhook.testing">
                                        <span class="badge badge-pill badge-warning font-weight-bold p-2 rounded"
                                            v-if="webhook.status === Webhook.STATUS_UNTESTED">
                                            <i class="fa fa-exclamation-triangle"></i> UNTESTED
                                        </span>
                                        <span class="badge badge-pill badge-success font-weight-bold p-2 rounded"
                                            v-if="webhook.status === Webhook.STATUS_VALID">
                                            <i class="fa fa-check-circle"></i> VALID & ACTIVE
                                        </span>
                                        <span class="badge badge-pill badge-danger font-weight-bold p-2 clickable rounded"
                                            v-if="webhook.status === Webhook.STATUS_INVALID"
                                            @click="showResponseModal(webhook)">
                                            <i class="fa fa-exclamation-triangle mb-2"></i> INVALID <br> <span>Click for more</span>
                                            </span>
                                        </span>
                                        <span class="badge badge-pill badge-secondary font-weight-bold p-2 rounded"
                                            v-if="webhook.status === Webhook.STATUS_DISABLED">
                                            <i class="fa fa-ban"></i> DISABLED
                                        </span>
                                    </div>
                                    <div v-if="webhook.testing">
                                        <span class="badge badge-pill badge-warning font-weight-bold p-2 rounded">
                                            <i class="fa fa-send"></i> TESTING...
                                        </span>
                                    </div>
                                </td>
                                <td width="40">
                                    <el-dropdown trigger="click">
                                        <el-button size="small">
                                            <i class="fa fa-ellipsis-h"></i>
                                        </el-button>
                                        <template v-slot:dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click.native="editWebhook(webhook)">
                                                    <i class="fa fa-edit"></i> Edit
                                                </el-dropdown-item>
                                                <el-dropdown-item @click.native="testWebhook(webhook)">
                                                    <i class="fa fa-send"></i> Test & Validate
                                                </el-dropdown-item>
                                                <el-dropdown-item v-if="webhook.status !== Webhook.STATUS_DISABLED"
                                                                  @click.native="disableWebhook(webhook)">
                                                    <i class="fa fa-ban"></i> Disable
                                                </el-dropdown-item>
                                                <el-dropdown-item divided @click.native="deleteWebhook(webhook)">
                                                    <i class="fa fa-trash-o"></i> Delete
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else
                         class="pt-5">
                        <img-empty class="w-50 text-center mx-auto" />
                        <div class="text-center">
                            <p class="lead">
                                You don't have any webhooks integration setup yet.
                            </p>
                            <el-button round
                                       size="small"
                                       class="ml-auto"
                                       type="success"
                                       @click="$refs.webhook_manager.add()">
                                <strong>Add Webhook</strong>
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <webhook-manager ref="webhook_manager"
            @callback="fetchWebhooks">
        </webhook-manager>

        <!-- Webhook Response Error Dialog -->
        <el-dialog top="4vh"
            :visible="show_response_modal"
            :append-to-body="true"
            title="Webhook Endpoint Response"
            @close="closeResponseModal">
            <div v-if="selected_webhook && selected_webhook.errors">
                <pre class="response-error-container pl-3 pr-3">
                    <code class="display-table">
                        <div class="white-space-normal">
                            "status_code": {{ selected_webhook.errors.status_code }}
                        </div>

                        <div class="white-space-normal">
                            "message": {{ selected_webhook.errors.message }}
                        </div>
                    </code>
                </pre>
            </div>

            <div v-else>
                No response error to show
            </div>

            <div class="d-flex">
                <el-button @click="closeResponseModal">Close</el-button>
            </div>
        </el-dialog>
        <!-- End Webhook Response Error Dialog -->
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import auth from "../../../auth"
import * as Webhook from '../../../constants/webhook'
import WebhookManager from "./webhook-manager"
import ImgEmpty from "../../misc/img-empty"

export default {
        components: {ImgEmpty, WebhookManager},

        name: "index",

        data() {
            return {
                show_response_modal: false,
                selected_webhook: null,
                auth: auth,
                webhooks: [],
                webhooks_loading: false,
                table_key: 1,
                Webhook
            }
        },

        mounted() {
            this.setTitle()
            this.fetchWebhooks();
        },

        methods: {
            fetchWebhooks() {
                this.webhooks_loading = true
                axios.get(`/api/v1/integration/webhooks`).then(res => {
                    this.webhooks = res.data
                    this.webhooks_loading = false

                    // Sanitation
                    this.webhooks.forEach((webhook, i) => {
                        webhook.testing = false
                        if (webhook.credentials == null || Array.isArray(webhook.credentials)) {
                            webhook.credentials = {}
                        }
                    })
                })
                .catch(err => {
                    console.error("Webhook sanitazion error:", err)
                })
            },

            editWebhook(webhook) {
                this.$refs.webhook_manager.edit(webhook)
            },

            deleteWebhook(webhook) {
                this.$refs.webhook_manager.delete(webhook)
            },

            testWebhook(webhook) {
                this.table_key++
                this.$refs.webhook_manager.test(webhook)
                webhook.testing = true
            },

            disableWebhook(webhook) {
                this.$refs.webhook_manager.disable(webhook)
            },

            setTitle() {
                axios.get('/get-statics')
                    .then(res => {
                        let name = res.data.name
                        this.setPageTitle('Webhooks - ' + name)
                    })
                    .catch(err => {
                        this.setPageTitle('Webhooks - Aloware')
                        console.log(err)
                        this.$root.handleErrors(err.response)
                    })
            },

            showResponseModal(webhook) {
                this.selected_webhook = cloneDeep(webhook)
                this.show_response_modal = true
            },

            closeResponseModal() {
                this.selected_webhook = null
                this.show_response_modal = false
            }
        },

        beforeRouteEnter(to, from, next) {
            auth.check()
                .then((res) => {
                    if (res.data.user.is_reseller) {
                        // redirect to account management portal if the company is a reseller
                        next({name: 'Account Management Portal'})
                    } else {
                        next()
                    }
                })
                .catch((err) => {
                    next({name: 'Login', query: {redirect: to.fullPath}})
                })
        }
    }
</script>
