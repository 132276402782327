import { render, staticRenderFns } from "./contact-select.vue?vue&type=template&id=08b118a2"
import script from "./contact-select.vue?vue&type=script&lang=js"
export * from "./contact-select.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/app/releases/20250215014035Z/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08b118a2')) {
      api.createRecord('08b118a2', component.options)
    } else {
      api.reload('08b118a2', component.options)
    }
    module.hot.accept("./contact-select.vue?vue&type=template&id=08b118a2", function () {
      api.rerender('08b118a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/user/components/contact-select.vue"
export default component.exports