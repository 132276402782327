// Communication that initiated manually
export const CREATOR_TYPE_MANUAL = 1
// Communication that initiated from API
export const CREATOR_TYPE_API = 2
// Communication that initiated from Aloware workflows
export const CREATOR_TYPE_WORKFLOW = 3
// Communication that initiated from Aloware broadcasts
export const CREATOR_TYPE_BROADCAST = 4
// Communication that initiated from Aloware power dialer
export const CREATOR_TYPE_POWER_DIALER = 5
// Communication that initiated from appointments sms reminders
export const CREATOR_TYPE_SMS_REMINDER = 6
// Communication that initiated from app notifications
export const CREATOR_TYPE_APP_NOTIFICATIONS = 7
// Communication that initiated from HubSpot
export const CREATOR_TYPE_HUBSPOT = 8
// Communication that initiated from Zapier
export const CREATOR_TYPE_ZAPIER = 9
// Communication that initiated from AlohaBot
export const CREATOR_TYPE_ALOHABOT = 10 // Deprecated
// Communication that initiated from GoHighLevel
export const CREATOR_TYPE_GOHIGHLEVEL = 11
// Communication that initiated from AloAi
export const CREATOR_TYPE_ALOAI = 12
