<template>
    <div class="signup-container container-fluid w-v h-v mellow-blue"
         v-if="!loading_whitelabel && !statics.whitelabel">
        <div class="row full-height">
            <div class="col-lg-12 d-flex justify-content-center align-items-center text-color"
                 :class="[ statics.whitelabel ? 'offset-lg-3' : '' ]">
                <signup :statics="statics"></signup>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions} from 'vuex'
import {login_mixin} from '../mixins'

export default {
    mixins: [login_mixin],

    data() {
        return {
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            register_status: false,
            loading: false,
        }
    },

    created() {
        this.register_status = localStorage.getItem('register_status')
        this.getStatics()
    },

    mounted() {
        this.init()
    },

    beforeRouteEnter(to, from, next) {
        auth.check().then((res) => {
            next(vm => {
                vm.verifyRedirect(res, next)
            })
        }).catch((err) => {
            next()
        })
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.setPageTitle('Sign Up - ' + this.statics.name)
                    this.loading_whitelabel = false

                    // don't show this page for whitelabel accounts
                    if (this.statics.whitelabel) {
                        this.$router.push('/')
                    }
                })
                .catch(err => {
                    this.setPageTitle('Sign Up - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        },

        init() {
        },

        ...mapActions(['resetVuex']),
    },
}
</script>
