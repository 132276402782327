<template>
    <div ref="new_summary_component">
        <communication-textual-filters></communication-textual-filters>
        <div class="d-flex">
            <h5 class="text-dark mb-0 align-self-end">Communication Metrics</h5>
            <div class="d-flex ml-auto">
                <div class="row"
                     v-if="hasRole('Company Admin')">
                     <video-modal
                        class="pl-2"
                        data-testid="new-summary-report-video"
                        title="📈 Exploring Aloware Dashboard: A Hub for Comprehensive Communication Management"
                        cookie-name="dashboard"
                        notes="🚀 Navigate the future with <strong>Aloware Dashboard!</strong> <br><br> 🌟 Synthesize, analyze, and strategize. Embark on a data-driven journey that's leagues ahead. <br><br> Your control center evolution is here!"
                        video-url="https://www.youtube.com/embed/SXe0RF8AjRQ?si=66cai6gq3MPMswM8"
                        learn-more-link="https://support.aloware.com/en/articles/9033811-dashboard-communication-activities"
                        v-if="isTrial && !isSimpSocial"/>
                    <div class="col" v-on-clickaway="closeAllMenus">
                        <div class="dropdown ml-2 pull-right dropdown-toggle-caret-none">
                            <button data-testid="export-summary-button"
                                    @click="toggleExportsMenu()"
                                    class="btn btn-info dropdown-toggle">
                                Export Summary <i class="el-icon-arrow-down"></i>
                            </button>
                            <div :class="{show: exports_menu_visible}"
                                 class="dropdown-menu dropdown-menu-right export-summary-dropdown">
                                <a data-testid="export-summary-to-pdf-button"
                                   @click.prevent="exportSummaryReportTo('pdf')"
                                   class="dropdown-item">
                                    <i class="fa fa-file-pdf-o"></i>
                                    To PDF
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isAdminOrAgentOrSupervisor">
            <!-- Call Tracking -->
            <div class="pb-0 pt-2"
                 v-if="current_company && current_company.reseller_id != 357">
                <div class="row">
                    <div class="col-4"
                         v-loading="loading || contact_summary_loading">
                        <summary-report-big-donut-card v-if="big_donut"
                                                       :data="big_donut"
                                                       :is_donut="true"
                                                       :hide_labels="true"
                                                       :show_slices="true"
                                                       :loading="loading || contact_summary_loading"
                                                       :card_index="1"
                                                       :extra_aggregate="aggregateData.contact_engaged"
                                                       @changeCardStatus="changeCardStatus">
                        </summary-report-big-donut-card>
                    </div>
                    <div class="col-8">
                        <div class="w-100"
                             v-loading="loading">
                            <summary-report-card v-if="summary"
                                                 :title="aggregateData.calls_inbound.title"
                                                 :data="aggregateData.calls_inbound.data"
                                                 :donut_value="aggregateData.calls_inbound.total"
                                                 :img_src="aggregateData.calls_inbound.img_src"
                                                 :is_donut="true"
                                                 :loading="loading"
                                                 :card_index="2"
                                                 :campaign_id="campaign_id"
                                                 :workflow_id="workflow_id"
                                                 :ring_group_id="ring_group_id"
                                                 :user_id="user_id"
                                                 :extra_aggregates="inboundSectionExtraAggregates"
                                                 data-testid="summary-report-card-change-status-1"
                                                 @changeCardStatus="changeCardStatus">
                            </summary-report-card>
                        </div>
                        <div class="mt-3 w-100"
                             v-loading="loading">
                            <summary-report-card v-if="summary"
                                                 :title="aggregateData.calls_outbound.title"
                                                 :data="aggregateData.calls_outbound.data"
                                                 :donut_value="aggregateData.calls_outbound.total"
                                                 :img_src="aggregateData.calls_outbound.img_src"
                                                 :is_donut="true"
                                                 :loading="loading"
                                                 :card_index="3"
                                                 :campaign_id="campaign_id"
                                                 :workflow_id="workflow_id"
                                                 :ring_group_id="ring_group_id"
                                                 :user_id="user_id"
                                                 :extra_aggregates="[aggregateData.sms_outbound]"
                                                 data-testid="summary-report-card-change-status-2"
                                                 @changeCardStatus="changeCardStatus">
                            </summary-report-card>
                        </div>
                    </div>
                </div>
                <div class="row mt-3"
                     v-if="showExtendedSummary">
                    <div class="col-3"
                         v-loading="loading || contact_audit_summary_loading">
                        <summary-report-card v-if="summary"
                                             :title="aggregateData.appointments.title"
                                             :data="aggregateData.appointments.data"
                                             :img_src="aggregateData.appointments.img_src"
                                             :chart_type="null"
                                             :is_donut="false"
                                             :loading="loading || contact_audit_summary_loading"
                                             :card_index="4"
                                             data-testid="summary-report-card-change-status-3"
                                             @changeCardStatus="changeCardStatus">
                        </summary-report-card>
                    </div>
                    <div class="col-3">
                        <broadcast-summary-widget></broadcast-summary-widget>
                    </div>
                    <div class="col-3"
                         v-loading="loading">
                        <summary-report-card v-if="summary"
                                             :title="aggregateData.sms.title"
                                             :data="aggregateData.sms.data"
                                             :img_src="aggregateData.sms.img_src"
                                             :chart_type="null"
                                             :is_donut="false"
                                             :loading="loading"
                                             :card_index="5"
                                             data-testid="summary-report-card-change-status-4"
                                             @changeCardStatus="changeCardStatus">
                        </summary-report-card>
                    </div>
                    <div class="col-3">
                        <lead-source-widget></lead-source-widget>
                    </div>
                </div>
            </div>
            <div class="pb-0 pt-2"
                 v-if="current_company && current_company.reseller_id == 357">
                <div class="row">
                    <div class="col-4"
                         v-loading="loading || contact_summary_loading">
                        <summary-report-big-donut-card v-if="big_donut"
                                                       :data="big_donut"
                                                       :is_donut="true"
                                                       :hide_labels="true"
                                                       :show_slices="true"
                                                       :loading="loading || contact_summary_loading"
                                                       :card_index="1"
                                                       :extra_aggregate="aggregateData.contact_engaged"
                                                       data-testid="new-summary-report-big-donut-card"
                                                       @changeCardStatus="changeCardStatus">
                        </summary-report-big-donut-card>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col-6"
                                 v-loading="loading">
                                <summary-report-card v-if="summary"
                                                     :title="aggregateData.calls_inbound.title"
                                                     :data="aggregateData.calls_inbound.data"
                                                     :donut_value="aggregateData.calls_inbound.total"
                                                     :img_src="aggregateData.calls_inbound.img_src"
                                                     :is_donut="true"
                                                     :loading="loading"
                                                     :card_index="2"
                                                     :campaign_id="campaign_id"
                                                     :workflow_id="workflow_id"
                                                     :ring_group_id="ring_group_id"
                                                     :user_id="user_id"
                                                     data-testid="summary-report-card-change-status-5"
                                                     @changeCardStatus="changeCardStatus">
                                </summary-report-card>
                            </div>
                            <div class="col-6"
                                 v-loading="loading">
                                <summary-report-card v-if="summary"
                                                     :title="aggregateData.calls_outbound.title"
                                                     :data="aggregateData.calls_outbound.data"
                                                     :donut_value="aggregateData.calls_outbound.total"
                                                     :img_src="aggregateData.calls_outbound.img_src"
                                                     :is_donut="true"
                                                     :loading="loading"
                                                     :card_index="3"
                                                     :campaign_id="campaign_id"
                                                     :workflow_id="workflow_id"
                                                     :ring_group_id="ring_group_id"
                                                     :user_id="user_id"
                                                     data-testid="summary-report-card-change-status-6"
                                                     @changeCardStatus="changeCardStatus">
                                </summary-report-card>
                            </div>
                        </div>
                        <div class="row">
                            <div class="mt-3 col-6"
                                 v-loading="loading || contact_audit_summary_loading">
                                <summary-report-card v-if="summary"
                                                     :title="aggregateData.appointments.title"
                                                     :title_class="'pl-3'"
                                                     :data="aggregateData.appointments.data"
                                                     :img_src="aggregateData.appointments.img_src"
                                                     :chart_type="null"
                                                     :is_donut="false"
                                                     :loading="loading || contact_audit_summary_loading"
                                                     :card_index="4"
                                                     data-testid="summary-report-card-change-status-7"
                                                     @changeCardStatus="changeCardStatus">
                                </summary-report-card>
                            </div>
                            <div class="mt-3 col-6">
                                <broadcast-summary-widget :title_class="'pl-3'"></broadcast-summary-widget>
                            </div>
                            <div class="mt-3 col-6"
                                 v-loading="loading">
                                <summary-report-card v-if="summary"
                                                     :title="aggregateData.sms.title"
                                                     :title_class="'pl-3'"
                                                     :data="aggregateData.sms.data"
                                                     :img_src="aggregateData.sms.img_src"
                                                     :chart_type="null"
                                                     :is_donut="false"
                                                     :loading="loading"
                                                     :card_index="5"
                                                     data-testid="summary-report-card-change-status-8"
                                                     @changeCardStatus="changeCardStatus">
                                </summary-report-card>
                            </div>
                            <div class="mt-3 col-6">
                                <lead-source-widget :title_class="'pl-3'">
                                </lead-source-widget>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin} from '../mixins'
import {mapActions, mapGetters, mapState} from 'vuex'
import ICountUp from 'vue-countup-v2'
import UpdatingDate from './updating-date'
import LeadSourceWidget from './dashboard/widgets/lead-source-widget'
import BroadcastSummaryWidget from './dashboard/widgets/broadcast-summary-widget'
import {Notification} from "element-ui"
import VideoModal from './video-modal.vue'
import {mixin as clickaway} from 'vue-clickaway'

export default {
    mixins: [acl_mixin, clickaway],

    components: {
        BroadcastSummaryWidget,
        LeadSourceWidget,
        UpdatingDate,
        ICountUp,
        VideoModal
    },

    props: {
        campaign_id: {required: false},
        workflow_id: {required: false},
        ring_group_id: {required: false},
        user_id: {required: false},
        broadcast_id: {required: false},
    },

    data() {
        return {
            reporting_url: localStorage.getItem('reporting_url'),
            auth: auth,
            profile: auth.user.profile,
            CancelToken: null,
            source: null,
            contact_summary_source: null,
            contact_audit_summary_source: null,
            loading: false,
            contact_summary_loading: false,
            contact_audit_summary_loading: false,
            summary: {},
            big_donut: {},
            contact_summary: {},
            contact_audit_summary: {},
            users_summary: {},
            extended_summary_reseller_ids: [],
            extended_summary_company_ids: [],
            exports_menu_visible: false
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
        }),
        ...mapGetters({
            filter: 'getFilter'
        }),
        ...mapGetters('cache', [
            'isTrial',
            'isSimpSocial'
        ]),

        showExtendedSummary() {
            if (!this.current_company) {
                return false
            }

            if (this.extended_summary_reseller_ids.includes(this.current_company.reseller_id)) {
                return true
            }

            if (this.extended_summary_company_ids.includes(this.current_company.id)) {
                return true
            }

            return false
        },

        aggregateData() {
            let summary_aggregate = {
                calls_inbound: [],
                calls_outbound: [],
                appointments: [],
                contact_engaged: [],
                sms: [],
                sms_inbound: [],
                sms_outbound: []
            }
            if (!_.isEmpty(this.summary)) {
                summary_aggregate.calls_inbound = {
                    title: 'Calls, Inbound',
                    total: this.summary.inbound_calls,
                    img_src: '/assets/images/app-icons/new/call_inbound_icon.svg',
                    data: [
                        {
                            label: 'Answered',
                            count: this.summary.answered_inbound_calls,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true
                        },
                        {
                            label: 'Missed',
                            count: this.summary.missed_inbound_calls,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true
                        },
                        {
                            label: 'Abandoned',
                            count: this.summary.abandoned_inbound_calls,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true
                        },
                        {
                            label: 'Voicemail',
                            count: this.summary.voicemail_inbound_calls,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true
                        },
                        {
                            label: 'Below 30 Seconds',
                            count: this.summary.total_inbound_calls_less_then_thirty_seconds,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: false
                        },
                        {
                            label: `Above ${this.current_company.qualified_calls_threshold1} Mins`,
                            count: this.summary.total_inbound_calls_above_qualified_calls_threshold1_minutes,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: true
                        },
                        {
                            label: 'Queue Hold time',
                            count: this.summary.average_inbound_calls_wait_time,
                            is_seconds: true,
                            show_in_chart: false,
                            clickable: false
                        },
                        {
                            label: 'Avg Talk time',
                            count: this.summary.average_inbound_calls_talk_time,
                            is_seconds: true,
                            show_in_chart: false,
                            clickable: false
                        }
                    ]
                }
                summary_aggregate.calls_outbound = {
                    title: 'Calls, Outbound',
                    total: this.summary.outbound_calls,
                    img_src: '/assets/images/app-icons/new/call_outbound_icon.svg',
                    data: [
                        {
                            label: 'Connected',
                            count: this.summary.connected_outbound_calls,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true
                        },
                        {
                            label: 'Not Connected',
                            count: this.summary.not_connected_outbound_calls,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: false
                        },
                        {
                            label: 'Cold Transfers',
                            count: this.summary.cold_transferred,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: false
                        },
                        {
                            label: 'Warm Transfers',
                            count: this.summary.warm_transferred,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: false
                        },
                        {
                            label: 'Below 30 Seconds',
                            count: this.summary.total_outbound_calls_shorter_than_thirty,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: false
                        },
                        {
                            label: `Above ${this.current_company.qualified_calls_threshold1} Mins`,
                            count: this.summary.total_outbound_calls_above_qualified_calls_threshold1_minutes,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: true
                        },
                        {
                            label: `Above ${this.current_company.qualified_calls_threshold2} Mins`,
                            count: this.summary.total_outbound_calls_above_qualified_calls_threshold2_minutes,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: true
                        },
                        {
                            label: 'Avg Talk time',
                            count: this.summary.average_outbound_calls_talk_time,
                            is_seconds: true,
                            show_in_chart: false,
                            clickable: false
                        }
                    ]
                }
                if (this.current_company.reseller_id == 357) {
                    // remove inbound calls' abandoned, voicemail, < 30 seconds, > 2 mins, Queue Hold time, and
                    // avg talk time
                    summary_aggregate.calls_inbound.data.splice(2)
                    // remove outbound calls' cold & warm transfers, < 30 seconds, > 2 mins, > 5 mins, and
                    // avg talk time
                    summary_aggregate.calls_outbound.data.splice(2)
                }
                summary_aggregate.appointments = {
                    title: 'Appointments',
                    total: (this.summary.appointments_set + this.summary.appointments_shown),
                    img_src: '/assets/images/app-icons/new/appointment_icon.svg',
                    data: [
                        {
                            label: 'Set',
                            count: this.summary.appointments_set,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: true
                        },
                        {
                            label: 'Shown',
                            count: this.summary.appointments_shown,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: true
                        },
                    ]
                }
                if (this.contact_audit_summary) {
                    summary_aggregate.appointments.data.push(
                        {
                            label: 'Sold',
                            count: this.contact_audit_summary.sold,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: true
                        }
                    )
                }
                summary_aggregate.sms = {
                    title: 'SMS',
                    img_src: '/assets/images/app-icons/new/sms_icon.svg',
                    data: [
                        {
                            label: 'Received',
                            count: this.summary.sms_received,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: true
                        },
                        {
                            label: 'Sent',
                            count: this.summary.sms_sent,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: true
                        },
                    ]
                }
                if (!this.showExtendedSummary) {
                    summary_aggregate.sms_inbound = {
                        type: 'SMS',
                        data: [
                            {
                                label: 'Texts Received',
                                count: this.summary.sms_received,
                                is_seconds: false,
                                show_in_chart: false,
                                index: 0,
                                clickable: true
                            },
                        ]
                    }

                    summary_aggregate.unreturned_conversations = {
                        type: 'Conversations',
                        data: [
                            {
                                label: 'Unreturned Conversations',
                                count: this.summary.unreturned_conversations,
                                is_seconds: false,
                                show_in_chart: false,
                                index: 0,
                                clickable: true
                            },
                        ]
                    }

                    summary_aggregate.sms_outbound = {
                        type: 'SMS',
                        data: [
                            {
                                label: 'Texts Sent',
                                count: this.summary.sms_sent,
                                is_seconds: false,
                                show_in_chart: false,
                                index: 1,
                                clickable: true
                            },
                        ]
                    }
                }
            }
            if (!_.isEmpty(this.contact_summary)) {
                summary_aggregate.contact_engaged = {
                    title: 'Contact Engaged',
                    total: this.contact_summary.new_leads,
                    img_src: '/assets/images/app-icons/new/contacts_icon.svg',
                    data: [
                        {
                            label: 'Net new leads engaged',
                            count: this.contact_summary.engaged_leads,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: false
                        },
                        {
                            label: 'Net new leads unengaged',
                            count: this.contact_summary.unengaged_leads,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: false
                        }
                    ]
                }
            }
            return summary_aggregate
        },

        inboundSectionExtraAggregates() {
            return [this.aggregateData.unreturned_conversations, this.aggregateData.sms_inbound]
        }
    },

    created() {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()
        this.contact_summary_source = this.CancelToken.source()
        this.contact_audit_summary_source = this.CancelToken.source()

        VueEvent.listen('update_summary', () => {
            if (this.$refs.new_summary_component) {
                this.getSummary()
            }
        })
    },

    mounted() {
        this.filter.campaign_id = this.campaign_id
        this.filter.workflow_id = this.workflow_id
        this.filter.ring_group_id = this.ring_group_id
        this.filter.user_id = this.user_id
        this.filter.broadcast_id = this.broadcast_id
        if (this.hasPermissionTo('list report')) {
            this.getSummary()
        }
    },

    methods: {
        getSummary() {
            this.getDashboardSummary()
            this.getContactSummary()
            this.getContactAuditSummary()
        },

        getDashboardSummary() {
            const params = {...this.filter}

            delete params.timezone;
            delete params.contact_timezone;

            this.loading = true
            this.source.cancel('getSummary canceled by the user.')
            this.source = this.CancelToken.source()

            axios.get(this.reporting_url + '/api/v1/analytics/dashboard/summary', {
                params,
                cancelToken: this.source.token
            }).then(res => {
                this.big_donut = res.data.donut_data
                this.summary = res.data.summary
                this.users_summary = res.data.users_summary
                this.summary.sms_total = this.summary.sms_received + this.summary.sms_sent
                this.loading = false
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                } else {
                    //this.$root.handleErrors(err.response)
                    this.loading = false
                }
            })
        },

        getContactSummary() {
            const params = {...this.filter}

            delete params.timezone;
            delete params.contact_timezone;

            this.contact_summary_loading = true
            this.contact_summary_source.cancel('getContactSummary canceled by the user.')
            this.contact_summary_source = this.CancelToken.source()
            axios.get(this.reporting_url + '/api/v1/analytics/dashboard/contact-summary', {
                params,
                cancelToken: this.source.token
            }).then(res => {
                this.contact_summary = res.data
                // prevent non-numeric computation
                let new_leads = this.contact_summary.new_leads === undefined ? 0 : this.contact_summary.new_leads
                let engaged_leads = this.contact_summary.engaged_leads === undefined ? 0 : this.contact_summary.engaged_leads
                this.contact_summary.unengaged_leads = new_leads - engaged_leads
                this.contact_summary_loading = false
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                } else {
                    //this.$root.handleErrors(err.response)
                    this.contact_summary_loading = false
                }
            })
        },

        getContactAuditSummary() {
            const params = {...this.filter}

            delete params.timezone;
            delete params.contact_timezone;

            this.contact_audit_summary_loading = true
            this.contact_audit_summary_source.cancel('getContactAuditSummary canceled by the user.')
            this.contact_audit_summary_source = this.CancelToken.source()
            axios.get(this.reporting_url + '/api/v1/analytics/dashboard/contact-audit-summary', {
                params,
                cancelToken: this.contact_audit_summary_source.token
            }).then(res => {
                this.contact_audit_summary = res.data
                this.contact_audit_summary_loading = false
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                } else {
                    //this.$root.handleErrors(err.response)
                    this.contact_audit_summary_loading = false
                }
            })
        },

        exportSummaryReportTo(export_type) {
            const params = {...this.filter}

            delete params.timezone;
            delete params.contact_timezone;

            this.filter.export_type = export_type
            axios.get(this.reporting_url + '/api/v1/analytics/dashboard/summary', {
                params
            }).then(res => {
                this.filter.export_type = 'json'
                this.$notify.info({
                    offset: 95,
                    title: 'Exports',
                    dangerouslyUseHTMLString: true,
                    message: `Your export is on its way. You can check the progress from the export page or <b class="text-dark-greenish pointer">click here</b>.`,
                    duration: 3000,
                    onClick: () => {
                        this.$router.push({name: 'Account', query: {tab: 'exports'}}).catch((err) => {
                        })
                        Notification.closeAll()
                    }
                })
            })
            this.toggleExportsMenu()
        },

        scrollWindowToGraph() {
            if (!$('#activity-graph').length) {
                return
            }

            let top_position = $("#activity-graph").offset().top
            let header_height = $(".app-header").height()

            $("html,body").animate({
                scrollTop: top_position - header_height - 90
            }, 500)
        },

        changeCardStatus(index) {
            switch (index) {
                // inbound calls
                case '2':
                    this.filterByCalls('inbound', null)
                    break
                // inbound calls, answered
                case '2-0':
                    this.filterByCalls('inbound', 'answered')
                    break
                // inbound calls, missed
                case '2-1':
                    this.filterByCalls('inbound', 'missed')
                    break
                // inbound calls, abandoned
                case '2-2':
                    this.filterByCalls('inbound', 'abandoned')
                    break
                // inbound calls, voicemail
                case '2-3':
                    this.filterByCalls('inbound', 'voicemail')
                    break
                // inbound calls talk time above 2 minutes
                case '2-5':
                    this.filterByTalkTime('inbound', 120)
                    break
                // outbound calls
                case '3':
                    this.filterByCalls('outbound', null)
                    break
                // outbound calls, connected
                case '3-0':
                    this.filterByCalls('outbound', 'answered')
                    break
                // outbound calls, not connected
                case '3-1':
                    break
                // outbound calls, cold transfers
                case '3-2':
                    break
                // outbound calls, warm transfers
                case '3-3':
                    break
                // outbound talk time greater than 2 minutes
                case '3-5':
                    this.filterByTalkTime('outbound', 120)
                    break
                // outbound talk time greater than 5 minutes
                case '3-6':
                    this.filterByTalkTime('outbound', 300)
                    break
                case '4-0':
                case '4-1':
                case '4-2':
                    this.goToCalendar()
                    break;
                case '5-0':
                    this.filterByTexts('inbound')
                    break
                case '5-1':
                    this.filterByTexts('outbound')
                    break
            }
        },

        filterByCalls(direction, answer_status) {
            this.resetFiltersExceptDates()
            this.filter.direction = direction
            this.filter.timezone = window.timezone
            if (answer_status) {
                this.filter.answer_status = answer_status
            }
            this.filter.type = 'call'
            this.filter.campaign_id = this.campaign_id
            this.filter.workflow_id = this.workflow_id
            this.filter.ring_group_id = this.ring_group_id
            this.filter.user_id = this.user_id
            this.filter.broadcast_id = this.broadcast_id
            this.filter.changed = true
            this.scrollWindowToGraph()
            VueEvent.fire('update_summary', true)
        },

        filterByTexts(direction) {
            this.resetFiltersExceptDates()
            this.filter.direction = direction
            this.filter.timezone = window.timezone
            this.filter.type = 'sms'
            this.filter.campaign_id = this.campaign_id
            this.filter.workflow_id = this.workflow_id
            this.filter.ring_group_id = this.ring_group_id
            this.filter.user_id = this.user_id
            this.filter.broadcast_id = this.broadcast_id
            this.filter.changed = true
            this.scrollWindowToGraph()
            VueEvent.fire('update_summary', true)
        },

        filterByTalkTime(direction, talk_time) {
            this.resetFiltersExceptDates()
            this.filter.min_talk_time = talk_time
            this.filter.direction = direction
            this.filter.timezone = window.timezone
            this.filter.type = 'call'
            this.filter.campaign_id = this.campaign_id
            this.filter.workflow_id = this.workflow_id
            this.filter.ring_group_id = this.ring_group_id
            this.filter.user_id = this.user_id
            this.filter.broadcast_id = this.broadcast_id
            this.filter.changed = true
            this.scrollWindowToGraph()
            VueEvent.fire('update_summary', true)
        },

        goToCalendar() {
            this.$router.push({name: 'Calendar'})
        },

        resetFiltersExceptDates() {
            let from_date = this.filter.from_date
            let to_date = this.filter.to_date
            this.resetFilters()
            this.filter.from_date = from_date
            this.filter.to_date = to_date
        },

        toggleExportsMenu() {
            this.exports_menu_visible = !this.exports_menu_visible
        },

        closeAllMenus() {
            if (this.exports_menu_visible) {
                this.exports_menu_visible = false
            }
        },

        ...mapActions(['resetFilters'])
    },

    watch: {
        'campaign_id': function () {
            this.getSummary()
        },

        'workflow_id': function () {
            this.getSummary()
        },

        'ring_group_id': function () {
            this.getSummary()
        },

        'user_id': function () {
            this.getSummary()
        },

        'broadcast_id': function () {
            this.getSummary()
        }
    },
}
</script>
