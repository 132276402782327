<template>
    <el-col class="p-2 pr-4" :span="colSpan">
        <label class="d-flex w-100 justify-content-start mb-1 text-weight-medium">
            {{ label }}
        </label>
        <el-select class="w-100"
                   ref="selectRef"
                   rounded
                   outlined
                   map-options
                   autocomplete="off"
                   :class="{ 'disabled': disabled }"
                   :rules="rules"
                   :placeholder="placeholder"
                   :option-label="optionLabel"
                   :option-value="optionValue"
                   :options="options"
                   :disabled="disabled"
                   v-model="innerValue" />
        <slot name="hint"></slot>
    </el-col>
</template>

<script>
export default {
    name: 'SelectField',

    props: {
        label: {
            type: String,
            required: true
        },

        placeholder: {
            type: String,
            default: 'Select'
        },

        value: {
            default: null
        },

        colMd: {
            type: String,
            default: 'col-md-5'
        },

        paddingClasses: {
            type: String,
            default: ''
        },

        options: {
            type: Array,
            required: true
        },

        optionLabel: {
            type: String,
            default: 'label'
        },

        optionValue: {
            type: String,
            default: 'value'
        },

        rules: {
            type: Array,
            default: () => []
        },

        disabled: {
            type: Boolean,
            default: false
        },
        colSpan: {
            type: Number,
            default: 8
        }
    },

    data () {
        return {
            innerValue: this.value
        }
    },

    methods: {
        validate () {
            if (this.$refs.selectRef && typeof this.$refs.selectRef.validate === 'function') {
                return this.$refs.selectRef.validate()
            }
        }
    },

    watch: {
        value (newValue) {
            this.innerValue = newValue
        },

        innerValue (newValue) {
            this.$emit('input', newValue)
        }
    }
}
</script>
