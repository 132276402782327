<template>
    <div class="app-body pb-0"
         id="view">
        <template v-if="shouldShowCalendar">
            <div class="pl-3 pr-3">
                <calendar></calendar>
            </div>

            <el-backtop target="html"
                        ref="backtop"
                        :bottom="20"
                        :right="20">
            </el-backtop>
        </template>
        <upgrade-now-page image-link="/assets/images/Calendar.svg"
                     text="Simplify your appointment scheduling and receive timely reminders with Calendar"
                     extra-text="Upgrade today to unlock this feature"
                     title-text="Calendar"
                     kb-link="https://support.aloware.com/en/articles/9034189-the-aloware-talk-calendar"
                     class="mt-5"
                     v-if="!shouldShowCalendar && shouldShowUpgradeNow">
        </upgrade-now-page>
    </div>
</template>

<script>
import auth from '../../auth'
import { acl_mixin, supervisor_mixin } from '../../mixins'
import Calendar from '../../components/calendar/calendar.vue'
import UpgradeNowPage from "../../components/upgrade-now-page.vue"

export default {
    mixins: [
        acl_mixin,
        supervisor_mixin
    ],

    components: {
        UpgradeNowPage,
        Calendar
    },

    data() {
        return {
            auth: auth,
            env: null,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true
        }
    },

    created() {
        if (this.hasReporterAccess || this.forceTalk) {
            this.goBack()
        }

        this.getStatics()
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.setPageTitle('Calendar - ' + this.statics.name)
                this.loading_whitelabel = false
            }).catch(err => {
                this.setPageTitle('Calendar - Aloware')
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false
            })
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({name: 'Login', query: {redirect: to.fullPath}})
        })
    },

    beforeCreate() {
        axios.get('/build-info.json').then(res => {
            this.env = res.data.env
        }).catch(err => {
            console.log(err)
        })
    }
}
</script>
