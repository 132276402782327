var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "align-self-center" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.checkSize(),
              expression: "checkSize()",
            },
          ],
          ref: "advanced_filters_desktop",
          staticClass: "dropdown",
          class: _vm.custom_class,
          attrs: { id: "advanced_filters" },
        },
        [
          _c("contact-advanced-filters", {
            ref: "filters",
            attrs: {
              campaign_id: _vm.campaign_id,
              tag_id: _vm.tag_id,
              disposition_status_id: _vm.disposition_status_id,
              lead_source_id: _vm.lead_source_id,
              hide_reset_filter: _vm.hide_reset_filter,
              default_date_range: _vm.default_date_range,
              has_sort_communications: _vm.has_sort_communications,
              broadcast: _vm.broadcast,
            },
            on: {
              changed: _vm.updateChanged,
              resetFilters: _vm.resetContactFilters,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.checkSize()
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-end",
                width: "100%",
                trigger: "click",
              },
              on: {
                show: function ($event) {
                  _vm.popover = true
                },
                hide: function ($event) {
                  _vm.popover = false
                },
              },
            },
            [
              _c("div", { ref: "advanced_filters_mobile" }),
              _vm._v(" "),
              _c("el-button", {
                staticClass: "mt-1",
                attrs: {
                  slot: "reference",
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-more-outline",
                  plain: "",
                },
                slot: "reference",
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }