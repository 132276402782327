var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-col",
    { staticClass: "p-2 pr-4", attrs: { span: _vm.colSpan } },
    [
      _c(
        "label",
        {
          staticClass:
            "d-flex justify-content-start w-100 mb-1 text-weight-medium",
        },
        [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "phone-row" },
        [
          _c("el-input", {
            staticClass: "col-md-3 pl-0",
            attrs: {
              rounded: "",
              outlined: "",
              placeholder: "+1",
              mask: "+###",
              type: "text",
              autocomplete: "off",
              disabled: _vm.disabled,
            },
            model: {
              value: _vm.countryCode,
              callback: function ($$v) {
                _vm.countryCode = $$v
              },
              expression: "countryCode",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            ref: "inputRef",
            staticClass: "col-md-9 pr-0",
            attrs: {
              type: "text",
              rounded: "",
              outlined: "",
              autocomplete: "off",
              placeholder: _vm.placeholder,
              rules: _vm.rules,
              mask: _vm.phoneMask,
              value: _vm.formattedValue,
              disabled: _vm.disabled,
            },
            on: { input: _vm.emitUpdateEvent },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("hint"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }