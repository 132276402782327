<template>
    <div v-loading="loading"
         class="card pb-3 h-100">
        <div class="px-4">
            <div class="container-outbound-connection-rate-widget">
                <div class="label-connection-rate">
                    <h1>{{ metrics.outbound_calls_connection_rate }}%</h1>
                    <p>Connection Rate</p>
                </div>

                <highcharts :options="options"
                            ref="chart">
                </highcharts>
            </div>
        </div>


        <div class="row">
            <div class="col">
                <metric-widget label="Total Outbound Calls"
                               :metric="metrics.outbound_calls">
                </metric-widget>
            </div>
            <div class="col">
                <metric-widget label="Connected Calls"
                               :metric="metrics.outbound_connected_calls">
                </metric-widget>
            </div>
        </div>
    </div>
</template>

<script>
    import MetricWidget from "./metric-widget"
    import DashboardFilter from "../../../filters/dashboard-filter"


    export default {
        name: "outbound-connection-rate-widget",

        components: {MetricWidget},

        data() {
            return {
                loading: false,
                metrics: {},
                options: {
                    exporting: {
                        enabled: false
                    },
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    title: {
                        text: null,
                        enabled: false
                    },
                    tooltip: {
                        enabled: false,
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: false,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            }
                        },
                        series: {
                            animation: {
                                duration: 0
                            }
                        }
                    },
                    series: [{
                        name: 'Rate',
                        colorByPoint: true,
                        data: [],
                        innerSize: '95%'
                    }]
                }
            }
        },

        computed: {
            kpiFilters() {
                return DashboardFilter.kpiFilters()
            },
        },

        mounted() {
            this.loadOutboundConnectionRate()
        },

        methods: {
            loadOutboundConnectionRate() {
                this.loading = true
                axios.get('/api/v1/kpis/communication/outbound/calls', {
                    params: {
                        aggregates: [
                            9, // OUTBOUND_CALLS_CONNECTION_RATE
                            1, // OUTBOUND_CALLS
                            2, // CONNECTED_OUTBOUND_CALLS
                            10, // UNCONNECTED_OUTBOUND_CALLS
                        ],
                        filters: DashboardFilter.get()
                    }
                }).then(res => {
                    this.loading = false
                    this.setOutboundConnectionRate(res.data)
                })
            },

            setOutboundConnectionRate(metrics) {
                this.metrics = metrics
                this.options.series[0].data = [
                    {
                        name: 'Unconnected Outbound Calls',
                        y: metrics.outbound_calls_unconnected,
                        color: '#d1f3de'
                    },
                    {
                        name: 'Connected Outbound Calls',
                        y: metrics.outbound_connected_calls.current_value,
                        color: '#00bf4a'
                    },
                ]
                this.loading = false
            }
        },

        watch: {
            kpiFilters() {
                this.loadOutboundConnectionRate()
            }
        }
    }
</script>
