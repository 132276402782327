var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "userNotificationsContainer",
      style: "top: " + _vm.top_offset + "px",
      attrs: { id: "container-user-notifications" },
    },
    [
      _vm.shouldShowAnnouncementNotification
        ? _c(
            "header-notification",
            {
              attrs: { type: "info", visible: _vm.visible },
              on: { afterClose: _vm.afterCloseAndSaveCookie },
            },
            [
              _vm.announcement && _vm.announcement.enabled
                ? _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "align-self-center text-center text-md-left",
                      },
                      [
                        _c("strong", [
                          _c("i", { staticClass: "fa fa-bullhorn" }),
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.announcement.value.message) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "a",
                            {
                              staticClass: "d-md-none",
                              attrs: {
                                href: _vm.announcement.value.cta_url,
                                target: "_blank",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr-2 px-4 mt-2",
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    round: "",
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.announcement.value.cta_text)
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.announcement.value.cta_url != null &&
                    _vm.announcement.value.cta_text != null
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ml-auto align-self-center d-none d-md-block",
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.announcement.value.cta_url,
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mr-2 px-4",
                                    attrs: {
                                      type: "primary",
                                      size: "small",
                                      round: "",
                                    },
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.announcement.value.cta_text)
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowDiagnosisNotification
        ? _c(
            "header-notification",
            {
              attrs: {
                type:
                  _vm.is10DlcError || _vm.isTollFreeError
                    ? "10dlc-warning"
                    : "danger",
                visible: _vm.show_diagnosis,
              },
              on: { afterClose: _vm.afterClose },
            },
            [
              _vm.diagnosis.length > 0
                ? _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "align-self-center flex-grow-1" },
                      [
                        _c(
                          "strong",
                          [
                            _c("i", {
                              staticClass: "fa fa-exclamation-triangle",
                            }),
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.firstDiagnosis) +
                                "\n                    "
                            ),
                            _vm.secondDiagnosis
                              ? [
                                  _c("br"),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.secondDiagnosis) +
                                      "\n                    "
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.thirdDiagnosis
                              ? [
                                  _c("br"),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.thirdDiagnosis) +
                                      "\n                    "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.link && _vm.link.learn_more_href
                          ? _c(
                              "a",
                              {
                                staticClass: "text-u-l",
                                attrs: { target: "_blank" },
                                on: {
                                  click: function ($event) {
                                    return _vm.finishRegistration(
                                      _vm.link.learn_more_href
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.link.learn_more_title ?? "Learn more"
                                    ) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.loading_whitelabel && !_vm.statics.whitelabel
                      ? _c(
                          "div",
                          { staticClass: "ml-auto align-self-center" },
                          [
                            !_vm.link &&
                            _vm.issue_codes.includes(
                              _vm.CompanyIssues.ISSUE_ACTIVE_DUNNING
                            ) &&
                            _vm.hasPermissionTo("see chargebee portal")
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          type: "danger",
                                          size: "small",
                                          round: "",
                                        },
                                        on: { click: _vm.chargebeePortal },
                                      },
                                      [_c("strong", [_vm._v("Manage Payment")])]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.link && !_vm.link.external
                              ? _c(
                                  "router-link",
                                  { attrs: { to: _vm.link.href } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "default-app-button",
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.link.title) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.link && _vm.link.external
                              ? _c(
                                  "a",
                                  {
                                    attrs: { target: "_blank" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.finishRegistration(
                                          _vm.link.href
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "default-app-button",
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.link.title) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "header-notification",
        {
          attrs: { visible: _vm.isTrialNotificationVisible, type: "info" },
          on: { afterClose: _vm.afterClose },
        },
        [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "align-self-center text-center text-md-left flex-grow-1",
              },
              [
                _vm.current_company && _vm.isTrialStatusActive
                  ? _c("strong", [
                      _vm._v(
                        "\n                    Welcome " +
                          _vm._s(_vm.auth.user.profile.first_name) +
                          ", you have " +
                          _vm._s(_vm.trialRemainingDays) +
                          "\n                    day" +
                          _vm._s(_vm.trialRemainingDays === 1 ? "" : "s") +
                          " left until your " +
                          _vm._s(_vm.current_company.trial_days) +
                          "-day\n                    trial account expires.\n                "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTrialExpired
                  ? _c("strong", [
                      _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.auth.user.profile.first_name) +
                          ", your " +
                          _vm._s(_vm.current_company.trial_days) +
                          "-day trial period has ended.\n                "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-auto d-none d-md-block" }, [
              !_vm.isWhitelabelAccount
                ? _c(
                    "div",
                    { staticClass: "mr-2 d-none d-lg-flex align-items-center" },
                    [
                      !_vm.show_cancelled_account &&
                      !_vm.show_trial_expired &&
                      !_vm.show_is_risky_user &&
                      !_vm.show_needs_phone_verification
                        ? _c("video-modal", {
                            ref: "videoModal",
                            attrs: {
                              title: "📞 Welcome to Aloware Talk!",
                              "cookie-name": "welcome",
                              notes:
                                "🔥 Ignite your communication game with <strong>Aloware Talk!</strong> </br></br> 📞 Dive into seamless conversations, build stronger connections, and make every word count. </br></br> Amplify your talk experience now! 💥🔊",
                              videoUrl:
                                "https://www.youtube.com/embed/6CAkvtjJMBQ?si=_4uvXnXGqEj7Ntxr",
                              learnMoreLink:
                                "https://support.aloware.com/en/articles/9034164-logging-in-to-aloware-talk-a-step-by-step-guide-for-agents",
                              "should-show-in-first-visit": true,
                              "has-activator-button": true,
                              "use-custom-activator-attr": true,
                              "custom-activator-attr": _vm.show_welcome_video,
                            },
                            on: { "close-modal": _vm.closeWatchGuideVideo },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "button-index q-mr-lg demo--button",
                                          on: {
                                            click: _vm.openWatchGuideVideo,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/assets/images/icons/film.svg",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              attrs: { target: "_blank" },
                                              on: {
                                                click: _vm.openWatchGuideVideo,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Watch Guide Video\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1232575082
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isResold
                        ? _c(
                            "div",
                            {
                              staticClass: "button-index q-mr-lg demo--button",
                              on: { click: _vm.bookDemo },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/assets/images/icons/calendar.svg",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: { target: "_blank" },
                                  on: { click: _vm.bookDemo },
                                },
                                [
                                  _vm._v(
                                    "\n                            Book a Demo\n                        "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.shouldShowUnlockTrialExperienceButton
                        ? _c(
                            "el-button",
                            {
                              staticClass: "mr-2 px-4 d-none d-md-inline-block",
                              attrs: {
                                type: "primary",
                                size: "small",
                                round: "",
                              },
                              on: { click: _vm.onOpenFinishRegistration },
                            },
                            [_c("strong", [_vm._v("Unlock trial experience")])]
                          )
                        : _vm.shouldShowRegistrationReviewButton
                        ? _c(
                            "el-button",
                            {
                              staticClass: "mr-2 px-4 d-none d-md-inline-block",
                              attrs: {
                                type: "primary",
                                size: "small",
                                round: "",
                              },
                              on: { click: _vm.onOpenRegistrationInReview },
                            },
                            [_c("strong", [_vm._v("Registration in Review")])]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.enabledToSkipTrialAndSubscribe()
                        ? _c(
                            "el-popover",
                            {
                              ref: "trial-skip-popover",
                              staticClass: "custom-popover",
                              attrs: {
                                placement: "top",
                                width: "200",
                                "popper-class": "btn-primary",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("custom-message-display", {
                                attrs: {
                                  config:
                                    _vm.customMessage("billing.subscribe"),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isSimpSocial &&
                      _vm.hasPermissionTo("see chargebee portal")
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "popover",
                                  rawName: "v-popover:trial-skip-popover",
                                  arg: "trial-skip-popover",
                                },
                              ],
                              staticClass: "mr-2 px-4 d-none d-md-inline-block",
                              attrs: {
                                type: "primary",
                                size: "small",
                                disabled:
                                  _vm.isChargeBeeSessionOrSubscriptionDisabled,
                                round: "",
                              },
                              on: { click: _vm.chargebeePaymentSources },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("credit_card_outline"),
                              ]),
                              _vm._v(" "),
                              !_vm.cardAdded
                                ? _c("strong", [_vm._v("Add Payment Method")])
                                : _c("strong", [
                                    _vm._v("Manage Payment Methods"),
                                  ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.show_is_risky_user && !_vm.app_loading
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                width: "40%",
                visible: _vm.show_is_risky_user,
                "modal-append-to-body": true,
                "append-to-body": true,
                "show-close": _vm.isAdminImpersonating,
                "before-close": _vm.beforeDialogClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show_is_risky_user = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "container-trial-modal" },
                [
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.user.first_name) +
                        ", it looks like there is something wrong with your account and we flagged you as a\n                risky user because of the following reasons:\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "alert alert-warning alert-aloware" },
                    [_c("strong", [_vm._v(_vm._s(_vm.user.risky_reason))])]
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "To resolve these issues, please add your credit card information."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "px-5 mb-4",
                      attrs: { type: "success", size: "large", round: "" },
                      on: { click: _vm.chargebeeUpdatePaymentMethod },
                    },
                    [_c("strong", [_vm._v("Add Credit Card")])]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm.show_trial_expired && !_vm.app_loading
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                width: "40%",
                visible: _vm.show_trial_expired,
                "modal-append-to-body": true,
                "append-to-body": true,
                "show-close": _vm.isAdminImpersonating,
                "before-close": _vm.beforeDialogClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show_trial_expired = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "container-trial-modal" },
                [
                  _c("h2", [_vm._v("Your trial account has expired")]),
                  _vm._v(" "),
                  _c("i", { staticClass: "fas fa-user-clock" }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.auth.user.profile.first_name) +
                        ", your trial period has ended, and what an incredible journey it's been! We trust you've discovered the wealth of features and benefits aimed at streamlining your connection with prospects and customers, making every interaction more impactful.\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                Though your trial has concluded, your path to success doesn't need to end. Dive into our variety of plans and flexible billing options, and upgrade today. Ensure you never miss a lead and your customers have uninterrupted access to you! Should you have any inquiries or require additional support, remember, our team is just a message away.\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.current_company.sales_rep
                    ? _c(
                        "el-button",
                        {
                          staticClass: "px-5 mb-4",
                          attrs: { type: "success", size: "large", round: "" },
                          on: { click: _vm.emailSalesRep },
                        },
                        [_c("strong", [_vm._v("Upgrade Now")])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.current_company.sales_rep
                    ? _c(
                        "el-button",
                        {
                          staticClass: "px-5 mb-4",
                          attrs: { type: "success", size: "large", round: "" },
                          on: { click: _vm.openDemo },
                        },
                        [_c("strong", [_vm._v("Upgrade Now")])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "px-5 mb-4",
                      attrs: { type: "danger", size: "large", round: "" },
                      on: { click: _vm.logout },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.is_impersonated
                              ? "Stop impersonating"
                              : "Sign out"
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.current_company.sales_rep
                    ? _c("div", [
                        _vm.current_company.sales_rep
                          ? _c("p", { staticClass: "mb-1" }, [
                              _vm._v("Sales Contact:\n                    "),
                              _c("strong", { staticClass: "mb-1 text-dark" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      this.current_company.sales_rep.name
                                    ) +
                                    "\n                    "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.current_company.sales_rep
                          ? _c("p", { staticClass: "mb-1" }, [
                              _vm._v("Email address:\n                    "),
                              _c("strong", { staticClass: "mb-1 text-dark" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "mailto:" +
                                        this.current_company.sales_rep.email,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          this.current_company.sales_rep.email
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v("Contact Support:"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-1" }, [
                    _c("strong", { staticClass: "text-success" }, [
                      _c("a", { staticClass: "tel:(855) 256-2001" }, [
                        _c("i", { staticClass: "fa fa-phone-alt" }),
                        _vm._v(
                          "\n                        (855) 256-2001\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "mx-2 text-dark" }, [
                        _vm._v("|"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://aloware.com",
                            target: "_blank",
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-globe" }),
                          _vm._v(
                            "\n                        Visit Website\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm.show_cancelled_account && !_vm.app_loading
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                width: "40%",
                visible: _vm.show_cancelled_account,
                "modal-append-to-body": true,
                "append-to-body": true,
                "show-close": _vm.isAdminImpersonating,
                "before-close": _vm.beforeDialogClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show_cancelled_account = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "container-trial-modal" },
                [
                  _c("h2", [_vm._v("Your subscription has been cancelled.")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.auth.user.profile.first_name) +
                        ", even though you have canceled your subscription, that doesn't mean this has to be the end of our journey together. If you'd like to renew your subscription and continue enjoying Aloware, we've made the process easy for you.\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                If there's anything we can assist with or if you have feedback that could help us improve, we're all ears. You can reach out to your CSM, " +
                        _vm._s(
                          _vm.current_company.csm_rep
                            ? _vm.current_company.csm_rep.name + ","
                            : ""
                        ) +
                        " directly through " +
                        _vm._s(
                          _vm.current_company.csm_rep?.email ??
                            "sales@aloware.com"
                        ) +
                        ".\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                We value you as a customer and would love to serve you again!\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "px-5 mb-4",
                      attrs: { type: "success", size: "large", round: "" },
                      on: { click: _vm.emailCsmRep },
                    },
                    [_c("strong", [_vm._v("Restore Account")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "px-5 mb-4",
                      attrs: { type: "danger", size: "large", round: "" },
                      on: { click: _vm.logout },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.is_impersonated
                              ? "Stop impersonating"
                              : "Sign out"
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm.show_needs_phone_verification && !_vm.app_loading
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                width: "40%",
                visible: _vm.show_needs_phone_verification,
                "modal-append-to-body": true,
                "append-to-body": true,
                "show-close": _vm.isAdminImpersonating,
                "before-close": _vm.beforeDialogClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show_needs_phone_verification = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "container-trial-modal" },
                [
                  _c("h2", [_vm._v("Verify Phone")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.auth.user.profile.first_name) +
                        ", we sent you a phone verification code to " +
                        _vm._s(
                          _vm._f("fixPhone")(_vm.auth.user.profile.phone_number)
                        ) +
                        ". Please input the code below\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-5" },
                    [
                      _c("security-code", {
                        ref: "security_code",
                        staticClass: "mb-2",
                        on: { completed: _vm.verifyPhone },
                        model: {
                          value: _vm.token,
                          callback: function ($$v) {
                            _vm.token = $$v
                          },
                          expression: "token",
                        },
                      }),
                      _vm._v(" "),
                      _vm.phone_verification_message.length > 0
                        ? _c(
                            "small",
                            {
                              class:
                                "text-" + _vm.phone_verification_message_type,
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.phone_verification_message) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-1" }, [
                    _vm._v(
                      "If you did not receive the code. You can resend a new one"
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.resending_phone_validation,
                          expression: "resending_phone_validation",
                        },
                      ],
                      attrs: { disabled: _vm.phone_verification_request_sent },
                      on: { click: _vm.requestPhoneVerification },
                    },
                    [
                      _vm._v(
                        "\n                Request Verification Code\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("kyc-fill-dialog", { attrs: { show: _vm.shouldShowKycFillDialog } }),
      _vm._v(" "),
      _c("kyc-reload-dialog", {
        attrs: { show: _vm.shouldShowKycReloadDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }