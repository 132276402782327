import * as Carriers from '../../../constants/carriers'
import MainDevice from './../device'

export default class Device extends MainDevice {
    constructor() {
        super(Carriers.TWILIO)
    }

    status() {
        if (!this._device) {
            return null
        }
        return this._device.status()
    }

    disconnectAll(call) {
        this._device.disconnectAll(call._call)
    }

    activeCall() {
        if (!this._device || this._device.calls.length === 0) {
            return undefined
        }
        const latestCall = this._device.calls[this._device.calls.length - 1]
        // need to track all calls and return the active one
        return this._createCall(latestCall)
    }

    availableInputDevices() {
        if (!this._device || !this._device.audio) {
            return []
        }
        return this._device.audio.availableInputDevices
    }

    setInputDevice(input_device) {
        if (!this._device || !this._device.audio) {
            return Promise.reject('Device is not yet initialized.')
        }
        return this._device.audio.setInputDevice(input_device)
    }

    availableOutputDevices() {
        if (!this._device || !this._device.audio) {
            return []
        }
        return this._device.audio.availableOutputDevices
    }

    getSpeakerDevices() {
        if (!this._device || !this._device.audio) {
            return []
        }
        return this._device.audio.speakerDevices.get()
    }

    setSpeakerDevice(output_device) {
        if (!this._device || !this._device.audio) {
            return Promise.reject('Device is not yet initialized.')
        }
        return this._device.audio.speakerDevices.set(output_device)
    }

    testSpeakerDevice() {
        if (!this._device || !this._device.audio) {
            return Promise.reject('Device is not yet initialized.')
        }
        return this._device.audio.speakerDevices.test()
    }

    connect(params) {
        return new Promise((resolve, reject) => {
            this._device.connect({params})
                .then(call => {
                    resolve(this._createCall(call))
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    destroy() {
        // webrtc _device is null when page gets refreshed
        if (this._device) {
            this._device.destroy()
            this._device._is_initialized = false
        }
    }
}
