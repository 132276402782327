var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 p-3" },
        [
          _c(
            "el-form",
            {
              ref: "number-lookup",
              attrs: {
                "label-position": "top",
                model: _vm.number_lookup,
                rules: _vm.rules_number_lookup,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.lookupPhoneNumber.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Enter a phone number:" } },
                [
                  _c("el-input", {
                    staticClass: "width-200 p-0",
                    attrs: { placeholder: "+18185005050" },
                    on: {
                      input: function ($event) {
                        return _vm.preValidateForm("number-lookup")
                      },
                    },
                    model: {
                      value: _vm.number_lookup.phone_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.number_lookup, "phone_number", $$v)
                      },
                      expression: "number_lookup.phone_number",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "p-x-md",
                      attrs: {
                        type: "success",
                        loading: _vm.loading_lookup_btn,
                        disabled: !_vm.validated,
                      },
                      on: { click: _vm.lookupPhoneNumber },
                    },
                    [
                      _vm._v(
                        "\n                            Lookup\n                        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.has_result
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("strong", [_vm._v("Formatted response:")]),
            _vm._v(" "),
            _vm.lookup_result.error
              ? _c("p", { staticClass: "text-danger mt-2" }, [
                  _c("strong", [_vm._v("Error!")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.lookup_result.sid
              ? _c("p", { staticClass: "mt-2 mb-2" }, [
                  _c("strong", [_vm._v("Phone Number:")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("fixPhone")(_vm.lookup_result.sid, "E164", true)
                      ) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.lookup_result.data
              ? _c("p", { staticClass: "mb-2" }, [
                  _c("strong", [_vm._v("National Format:")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("fixPhone")(_vm.lookup_result.sid, "NATIONAL")
                      ) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.lookup_result.data
              ? _c("p", { staticClass: "mb-2" }, [
                  _c("strong", [_vm._v("Carrier Name:")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.lookup_result.carrier) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.lookup_result.data
              ? _c("p", { staticClass: "mb-2" }, [
                  _c("strong", [_vm._v("Line Type:")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("fixName")(
                          _vm._f("fixLrnType")(_vm.lookup_result.line_type)
                        )
                      ) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.lookup_result.data
              ? _c("p", { staticClass: "mb-2" }, [
                  _c("strong", [_vm._v("City:")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.lookup_result.cnam_city) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.lookup_result.data
              ? _c("p", { staticClass: "mb-2" }, [
                  _c("strong", [_vm._v("State:")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.lookup_result.cnam_state) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.lookup_result.data
              ? _c("p", { staticClass: "mb-2" }, [
                  _c("strong", [_vm._v("Country:")]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.lookup_result.cnam_country) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [
            _c("strong", [_vm._v("Response body:")]),
            _vm._v(" "),
            _c(
              "pre",
              {
                directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
              },
              [
                _c("code", { staticClass: "json" }, [
                  _vm._v(_vm._s(_vm.lookup_result)),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row b-t b-b" }, [
      _c("div", { staticClass: "col-12 p-3" }, [_c("company-form-token")], 1),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 p-3" },
        [
          _c("info-alert-box", {
            attrs: {
              text: "Please note that the Number Lookup API usage is considered paid. You are agreeing to our billing terms by using the API.",
              "kb-link":
                "https://support.aloware.com/en/articles/9033804-aloware-pricing-plans-empowering-your-teams-with-flexible-solutions",
            },
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Example POST URL:")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("span", { staticClass: "text-dark-greenish _600" }, [
              _vm._v(
                _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                  "/api/v1/webhook/lookup"
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _c(
            "pre",
            {
              directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
              key: _vm.renderRefreshToken + "_nl_a",
            },
            [
              _c("code", { staticClass: "json" }, [
                _vm._v(
                  '{\n    "api_token": "' +
                    _vm._s(_vm.companyToken) +
                    '",\n    "phone_number": "8181234567",\n}'
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _c(
            "pre",
            { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
            [
              _c("code", { staticClass: "json" }, [
                _vm._v(
                  '{\n    "Accept": "application/json",\n    "Content-Type": "application/json"\n}'
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(5),
          _vm._v(" "),
          _c(
            "pre",
            { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
            [_vm._m(6)]
          ),
          _vm._v(" "),
          _vm._m(7),
          _vm._v(" "),
          _c(
            "pre",
            { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
            [
              _c("code", { staticClass: "json" }, [
                _vm._v(
                  '{\n    "message": "The given data was invalid.",\n    "errors": {\n        "phone_number": [\n            "The phone number is required."\n        ]\n    }\n}'
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(8),
          _vm._v(" "),
          _c(
            "pre",
            { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
            [
              _c("code", { staticClass: "json" }, [
                _vm._v(
                  '{\n    "error": {\n        "code": 402,\n        "message": "LRN lookup limit has been reached out"\n    }\n}'
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(9),
          _vm._v(" "),
          _c(
            "pre",
            { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
            [
              _c("code", { staticClass: "json" }, [
                _vm._v(
                  '{\n    "error": {\n        "code": 404,\n        "message": "LRN data is empty for {number}"\n    }\n}'
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(10),
          _vm._v(" "),
          _c(
            "pre",
            { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
            [
              _c("code", { staticClass: "json" }, [
                _vm._v(
                  '{\n  "error": {\n    "code": 500,\n    "message": "Could not run lrnLookup"\n  }\n}'
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(11),
          _vm._v(" "),
          !_vm.loading_whitelabel
            ? _c(
                "pre",
                {
                  directives: [
                    { name: "highlightjs", rawName: "v-highlightjs" },
                  ],
                  key: _vm.renderRefreshToken + "_nl_b",
                },
                [
                  _c("code", { staticClass: "bash" }, [
                    _vm._v(
                      "curl -X POST \\\n  " +
                        _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                        "/api/v1/webhook/lookup \\\n  -H 'Accept: application/json' \\\n  -H 'Content-Type: application/json' \\\n  -d '{\n    \"api_token\": \"" +
                        _vm._s(_vm.companyToken) +
                        '",\n    "phone_number": "8181234567",\n  }\''
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.statics.whitelabel
            ? _c("p", { staticClass: "_600" }, [
                _vm._v(
                  "\n                    If you need more API functions, please contact our support at support@aloware.com .\n                "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2" }, [
      _c("strong", { staticClass: "integration-api-titles" }, [
        _vm._v("Number lookup API (LRN API)"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-md" }, [
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("POST /api/v1/webhook/lookup"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Required Fields:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("api_token"),
      ]),
      _vm._v(",\n                    "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("phone_number"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Example POST body:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-muted" }, [
        _vm._v(
          "\n                        The code sample below represents some example JSON with\n                        standard fields to pass in the body of your request in\n                        order to create a new contact:\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Example POST header:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 200 response on success, Example success response:\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("code", { staticClass: "json" }, [
      _vm._v(
        '{\n  "sid": "4243942058",\n  "line_type": "1",\n  "carrier": "Sprint PCS/2",\n  "cnam_city": "Burbank",\n  "cnam_state": "CA",\n  "cnam_country": "US",\n  "data": {\n    "tn": "4243942058",\n    "lrn": "818795",\n    "ported_status": "Y",\n    "ported_date": "20190104222010",\n    "ocn": "6664",\n    '
      ),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v(
          "// Line Types: 0 = Landline, 1 = Wireless, 2 = VOIP, 3 = Other"
        ),
      ]),
      _vm._v(
        '\n    "line_type": "1",\n    "spid": "6664",\n    "spid_carrier_name": "Sprint PCS/2",\n    "spid_carrier_type": "1",\n    "altspid": "",\n    "altspid_carrier_name": "",\n    "altspid_carrier_type": "",\n    "city": "BURBANK",\n    "state": "California",\n    "country": "United States"\n  }\n}'
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 400 on failure with a detailed response, Example failure response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 402 when the LRN lookup limit has been reached out by trial accounts, Example failure response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 404 if lookup can't identify the number, Example failure response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 500 on failure with a detailed response, Example failure response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("cURL Example:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }