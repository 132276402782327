var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input-field", {
    attrs: {
      label: "Repeat your Password",
      placeholder: "Type your password again",
      "is-password": "",
      "padding-classes": _vm.passingClasses,
      "col-span": _vm.colSpan,
    },
    on: { input: _vm.onInput },
    scopedSlots: _vm._u(
      [
        _vm.passwordConfirmation
          ? {
              key: "hint",
              fn: function () {
                return [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "text-left pl-2 text-weight-regular password-hint",
                    },
                    [
                      _c(
                        "li",
                        [
                          _c("el-icon", {
                            staticClass: "mr-xs",
                            class: _vm.getPasswordRuleClass(_vm.isMatch),
                            attrs: { name: _vm.iconForValidation(_vm.isMatch) },
                          }),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.passwordMatchText) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
    model: {
      value: _vm.passwordConfirmation,
      callback: function ($$v) {
        _vm.passwordConfirmation = $$v
      },
      expression: "passwordConfirmation",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }