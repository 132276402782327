var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row b-b" }, [
      _c("div", { staticClass: "col-12 p-3" }, [
        _c("p", { staticClass: "form-head-message mb-2" }, [
          _vm._v(
            "Connect your website forms and capture your leads by embedding our simple script on your website."
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c(
          "pre",
          { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
          [
            _c("code", { staticClass: "json" }, [
              _vm._v(
                '{\n    form_id: "myform", // optional if not set will bind to first form on the page\n    line_id: 1234, // optional\n    sequence_id: 1234, // optional\n    user_id: 1234, // optional\n    tag_id: 1234, // optional\n    disposition_status_id: 1234, // optional\n    force_update: true, // optional\n    force_update_sequence: false // optional\n}'
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        !_vm.loading_whitelabel
          ? _c("div", [
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "input-group" }, [
                _c(
                  "pre",
                  {
                    directives: [
                      { name: "highlightjs", rawName: "v-highlightjs" },
                    ],
                    staticClass: "code-bg form-control text-sm",
                  },
                  [
                    _c(
                      "code",
                      {
                        ref: "vanilla_script",
                        staticClass: "html javascript json",
                        on: { click: _vm.copyVanillaScriptTag },
                      },
                      [
                        _vm._v(
                          '<script src="' +
                            _vm._s(_vm._f("fixDomain")(_vm.host)) +
                            '/form/vanilla-js-lead-api.min.js"></script>\n<script>alowareInit("' +
                            _vm._s(_vm.current_company.form_capture_token) +
                            '", { force_update: true });</script>'
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "input-group-append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn greenish",
                      attrs: { type: "button" },
                      on: { click: _vm.copyVanillaScriptTag },
                    },
                    [
                      _vm._v(
                        "\n                                Copy\n                            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        !_vm.loading_whitelabel
          ? _c("div", [
              _vm._m(5),
              _vm._v(" "),
              _c("div", { staticClass: "input-group" }, [
                _c(
                  "pre",
                  {
                    directives: [
                      { name: "highlightjs", rawName: "v-highlightjs" },
                    ],
                    staticClass: "code-bg form-control text-sm",
                  },
                  [
                    _c(
                      "code",
                      {
                        ref: "jquery_script",
                        staticClass: "html javascript json",
                        on: { click: _vm.copyJqueryScriptTag },
                      },
                      [
                        _vm._v(
                          '<script src="' +
                            _vm._s(_vm._f("fixDomain")(_vm.host)) +
                            '/form/jquery-lead-api.min.js"></script>\n<script>alowareInit("' +
                            _vm._s(_vm.current_company.form_capture_token) +
                            '", { force_update: true });</script>'
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "input-group-append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn greenish",
                      attrs: { type: "button" },
                      on: { click: _vm.copyJqueryScriptTag },
                    },
                    [
                      _vm._v(
                        "\n                                Copy\n                            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "form-head-message mb-2" }, [
      _vm._v(
        "By default our script will bind itself to the first form in your page. You can pass the options object to "
      ),
      _c("b", [_vm._v("alowareInit")]),
      _vm._v(
        " function as the secondary argument to override the default settings."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Options:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2 mt-4" }, [
      _c("strong", [_vm._v("Vanilla JavaScript:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "form-head-message text-left" }, [
      _vm._v(
        "\n                        Add the script below before the closing "
      ),
      _c("b", [_vm._v("</body>")]),
      _vm._v(" tag:\n                    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2 mt-4" }, [
      _c("strong", [_vm._v("jQuery:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "form-head-message text-left" }, [
      _vm._v(
        "\n                        Add the script below before the closing "
      ),
      _c("b", [_vm._v("</body>")]),
      _vm._v(" tag:\n                    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }