<template>
    <div class="filter-attribution"
         v-on-clickaway="closeSequenceList">
        <el-badge type="success"
                  class="filter-counter"
                  :value="sequences.length"
                  :hidden="sequences.length === 0">
            <el-button size="small"
                       round
                       plain
                       @click="toggleSequenceList">
                Sequences
            </el-button>
        </el-badge>
        <div class="filter-attribution-data-list"
             v-if="show_sequence_list">
            <div class="d-flex flex-row justify-content-end mb-2">
                <el-button size="small"
                           class="border-0" @click="closeSequenceList">
                    Close
                </el-button>
            </div>
            <workflow-selector class="w-100 filter-select"
                           place-holder="Filter Sequences"
                           :multiple="true"
                           :value="sequences"
                           @change="setTempSequences">
            </workflow-selector>
            <div class="d-flex flex-row justify-content-between mt-4">
                <el-button type="default" size="small" round @click="clear">
                    Clear
                </el-button>
                <el-button type="success" size="small" round @click="apply">
                    Apply
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import DashboardFilter from '../../../filters/dashboard-filter'
import {mixin as clickaway} from 'vue-clickaway'

export default {
    mixins: [clickaway],
    data() {
        return {
            show_sequence_list: false,
            temp_sequences: []
        }
    },

    computed: {
        sequences() {
            return DashboardFilter.sequences()
        }
    },

    methods: {
        toggleSequenceList() {
            this.show_sequence_list = !this.show_sequence_list
        },

        clear() {
            this.temp_sequences = []
            this.apply()
        },

        apply() {
            DashboardFilter.setSequences(this.temp_sequences)
            this.show_sequence_list = false
        },

        setTempSequences(sequences) {
            this.temp_sequences = sequences
        },

        closeSequenceList() {
            this.show_sequence_list = false
        }
    }
}
</script>
<style lang="scss">
.filter-attribution {
    position: relative;
    z-index: 901;
}

.filter-counter sup.el-badge__content {
    top: 3px !important;
    right: 14px !important;
}

.filter-attribution-data-list {
    padding: 1rem;
    border: solid 1px #ddd;
    border-radius: 5px;
    position: absolute;
    top: 40px;
    left: 0;
    background-color: white;
    width: 20rem;
}
</style>
