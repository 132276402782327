<template>
    <el-form
        v-loading="loading"
        :model="businessRegistrationForm"
        :rules="rules"
        label-position="top"
        ref="businessRegistrationForm">
        <div class="row">
            <div
                v-if="isA2pRejected"
                class="col-12"
            >
                <el-alert
                    show-icon
                    type="warning"
                    :closable="false"
                >
                    <p class="mb-0">
                        <span class="text-dark">
                            Your A2P Brand Pre-Vetting was <b>Rejected</b>: {{ a2pFailureReason }}
                        </span>
                    </p>
                </el-alert>
            </div>
            <div
                v-if="this.a2p_brand?.status === ComplianceBundleStatuses.STATUS_REJECTED"
                class="col-12"
            >
                <el-alert
                    v-for="error in a2p_brand.errors"
                    :key="error.error_code"
                    :description="`Error Code ${error.registrationerrorcode}: ${error.registrationerrordescription}`"
                    :title="fixToUcFirst(error.registrationerrorfields[0])"
                    class="mb-2"
                    type="error"
                    show-icon
                    :closable="false"
                />
            </div>
            <div class="col-12 mt-3">
                <h5 class="mb-0">Business Information</h5>
                <div class="dropdown-divider"/>

                <div class="row">
                    <div class="col-12 mb-3" v-if="isSimpSocial">
                        <h6>Fee Disclosure</h6>
                        <small>There is a passthrough fee of $44 to register the A2P Brand. If the information submitted does not match your legal business registration, it will be rejected by
                            TCR and will incur a passthrough fee of $10 to appeal.</small>
                        <div class="mt-2">
                            <span id="accepted_compliance_one_time_fee" class="page-anchor"/>
                            <el-form-item prop="accepted_compliance_one_time_fee">
                                <el-checkbox
                                    v-model="businessRegistrationForm.accepted_compliance_one_time_fee"
                                    @change="preValidateForm('businessRegistrationForm')"
                                    :disabled="disableBusinessRegistrationForm">
                                    I agree to the one-time non-refundable $44 Brand Registration Fee and $10 Appeal Fee in case the A2P Brand is rejected.
                                </el-checkbox>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Legal Name" prop="customer_name">
                            <span id="customer_name" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.customer_name"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Business Type" prop="business_type">
                            <span id="business_type" class="page-anchor"/>
                            <el-select
                                v-model="businessRegistrationForm.business_type"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                                class="w-full"
                                placeholder="Select Business Type"
                                filterable>
                                <el-option
                                    v-for="businessType in businessTypes"
                                    :key="businessType.id + businessType.value"
                                    :label="businessType.value"
                                    :value="businessType.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Company Status" prop="company_status">
                            <span id="company_status" class="page-anchor"/>
                            <el-radio-group
                                v-model="businessRegistrationForm.company_status"
                                :disabled="disableBusinessRegistrationForm"
                                size="small">
                                <el-radio-button :label="1">Private</el-radio-button>
                                <el-radio-button :label="2">Public</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div v-show="businessRegistrationForm.company_status === 2" class="col-12 col-sm-6 col-md-2">
                        <el-form-item
                            prop="stock_ticker"
                            label="Stock Ticker">
                            <span id="stock_ticker" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.stock_ticker"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div v-show="businessRegistrationForm.company_status === 2" class="col-12 col-sm-6 col-md-2">
                        <el-form-item
                            prop="stock_exchange"
                            label="Stock Exchange">
                            <span id="stock_exchange" class="page-anchor"/>
                            <el-select
                                :disabled="disableBusinessRegistrationForm"
                                v-model="businessRegistrationForm.stock_exchange"
                                placeholder="Select Stock Exchange"
                                class="w-full"
                                filterable
                                @change="preValidateForm('customerProfileRegistrationForm')"
                            >
                                <el-option
                                    v-for="stock_exchange in stockExchanges"
                                    :key="stock_exchange.id + stock_exchange.value"
                                    :label="stock_exchange.name"
                                    :value="stock_exchange.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Business ID Type" prop="business_registration_identifier">
                            <span id="business_registration_identifier" class="page-anchor"/>
                            <el-select
                                v-model="businessRegistrationForm.business_registration_identifier"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                                class="w-full"
                                placeholder="Select Business ID Type"
                                filterable>
                                <el-option
                                    v-for="businessRegistrationIdentifier in businessRegistrationIdentifiers"
                                    :key="businessRegistrationIdentifier.value"
                                    :label="businessRegistrationIdentifier.name"
                                    :value="businessRegistrationIdentifier.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div v-show="businessRegistrationForm.business_registration_identifier === 'Other'" class="col-12 col-sm-6 col-md-4">
                        <el-form-item
                            prop="other_identifier"
                            label="Name of Other Registration Type">
                            <span id="other_identifier" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.other_identifier"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Business Industry" prop="business_industry">
                            <span id="business_industry" class="page-anchor"/>
                            <el-select
                                v-model="businessRegistrationForm.business_industry"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                                placeholder="Select Business Industry"
                                class="w-full"
                                filterable>
                                <el-option
                                    v-for="businessIndustry in businessIndustries"
                                    :key="businessIndustry.value"
                                    :label="businessIndustry.name"
                                    :value="businessIndustry.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Business Registration Number" prop="business_registration_number">
                            <template slot="label">
                                <span>Business Registration Number</span>
                                <el-tooltip
                                    v-if="showCanadianBusinessRegistrationNumberTooltip"
                                    effect="dark"
                                    placement="top"
                                >
                                    <div slot="content" v-html="showCanadianBusinessRegistrationNumberContent"></div>
                                    <i class="fa fa-exclamation-triangle text-blue" />
                                </el-tooltip>
                            </template>
                            <span id="business_registration_number" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.business_registration_number"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Business Regions of Operation" prop="business_regions_of_operation">
                            <span id="business_regions_of_operation" class="page-anchor"/>
                            <el-select
                                v-model="businessRegistrationForm.business_regions_of_operation"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                                placeholder="Select Business Regions of Operation"
                                class="w-full"
                                multiple
                                filterable>
                                <el-option
                                    v-for="businessRegionsOfOperation in businessRegionsOfOperations"
                                    :key="businessRegionsOfOperation.value"
                                    :label="businessRegionsOfOperation.name"
                                    :value="businessRegionsOfOperation.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item prop="website_url">
                            <template slot="label">
                                <span>Website URL</span>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="Domain of Website URL needs to match the domain of the email provided below."
                                    placement="top">
                                    <i class="fa fa-exclamation-triangle text-blue"/>
                                </el-tooltip>
                            </template>
                            <span id="website_url" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.website_url"
                                placeholder="https://example.com"
                                @input="preValidateForm('businessRegistrationForm')"
                                @blur="() => { website_url = website_url }"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <el-form-item prop="business_registration_file">
                            <template slot="label">
                                <span>Business Registration Documents</span>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="Provide valid Business Registration Documents for your country. Providing an invalid document will result in immediate Rejection."
                                    placement="top"
                                >
                                    <i class="fa fa-exclamation-circle text-blue"/>
                                </el-tooltip>
                            </template>
                            <el-upload
                                :action="'/api/v1/compliance/upload-business-registration-document'"
                                :headers="headers"
                                :on-success="onSuccessMessage"
                                :on-error="onFailedMessage"
                                :on-progress="progressUploadMessage"
                                :before-upload="beforeUploadMessage"
                                :file-list="upload_file_list.upload"
                                :show-file-list="true"
                                :multiple="true"
                                :disabled="loading_upload || disableBusinessRegistrationForm"
                                class="text-left"
                            >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                                <div class="el-upload__tip" slot="tip">PDF file with a size less than 2MB</div>
                            </el-upload>
                        </el-form-item>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <h5 class="mb-0">Address Information</h5>
                <div class="dropdown-divider"/>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Legal Country" prop="iso_country">
                            <span id="iso_country" class="page-anchor"/>
                            <el-select
                                v-model="businessRegistrationForm.iso_country"
                                @change="handleCountryChange"
                                :disabled="disableBusinessRegistrationForm"
                                placeholder="Select Country"
                                class="w-full"
                                filterable>
                                <el-option
                                    v-for="country in countries"
                                    :key="country.id"
                                    :label="country.name"
                                    :value="country.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="State/Province/Region" prop="region">
                            <span id="region" class="page-anchor"/>
                            <el-select
                                v-if="['US', 'CA'].includes(businessRegistrationForm.iso_country)"
                                v-model="businessRegistrationForm.region"
                                placeholder="Select State/Province/Region"
                                filterable
                                :disabled="disableBusinessRegistrationForm"
                                class="w-full"
                                @change="preValidateForm('businessRegistrationForm')"
                            >
                                <el-option
                                    v-for="state in states"
                                    :key="state.value"
                                    :label="state.label"
                                    :value="state.value"
                                />
                            </el-select>
                            <el-input
                                v-else
                                v-model="businessRegistrationForm.region"
                                @input="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="City" prop="city">
                            <span id="city" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.city"
                                @input="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Street" prop="street">
                            <span id="street" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.street"
                                @input="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Street 2" prop="street_secondary">
                            <span id="street_secondary" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.street_secondary"
                                @input="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Postal Code" prop="postal_code">
                            <span id="postal_code" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.postal_code"
                                @input="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <h5 class="mb-0">Point of Contact</h5>
                <div class="dropdown-divider"/>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="First Name" prop="auth_rep_first_name">
                            <span id="auth_rep_first_name" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.auth_rep_first_name"
                                @input="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Last Name" prop="auth_rep_last_name">
                            <span id="auth_rep_last_name" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.auth_rep_last_name"
                                @input="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Email" prop="auth_rep_email">
                            <span id="auth_rep_email" class="page-anchor"/>
                            <el-input
                                v-model.trim="businessRegistrationForm.auth_rep_email"
                                @input="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Work Cell" prop="auth_rep_phone_number">
                            <span id="auth_rep_phone_number" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.auth_rep_phone_number"
                                @input="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Title" prop="auth_rep_business_title">
                            <span id="auth_rep_business_title" class="page-anchor"/>
                            <el-input
                                v-model="businessRegistrationForm.auth_rep_business_title"
                                @input="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item label="Job Position" prop="auth_rep_job_position">
                            <span id="auth_rep_job_position" class="page-anchor"/>
                            <el-select
                                v-model="businessRegistrationForm.auth_rep_job_position"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm"
                                placeholder="Select Job Position"
                                class="w-full"
                                filterable>
                                <el-option
                                    v-for="jobPosition in authRepJobPositions"
                                    :key="jobPosition.id + jobPosition.value"
                                    :label="jobPosition.value"
                                    :value="jobPosition.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <h5 class="mb-0">Planning to Use</h5>
                <div class="dropdown-divider"/>

                <div class="row mt-2">
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item prop="want_local_presence_phone_numbers">
                            <span id="want_local_presence_phone_numbers" class="page-anchor"/>
                            <el-checkbox
                                v-model="businessRegistrationForm.want_local_presence_phone_numbers"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm">
                                Local Presence Numbers
                            </el-checkbox>
                            <br />
                            <a
                                v-if="!isSimpSocial"
                                href="https://support.aloware.com/en/articles/9020267-step-by-step-guide-setting-up-local-presence-with-aloware"
                                class="text-bluish"
                                target="_blank">
                                Learn more
                            </a>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item prop="want_toll_free_numbers">
                            <span id="want_toll_free_numbers" class="page-anchor"/>
                            <el-checkbox
                                v-model="businessRegistrationForm.want_toll_free_numbers"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm">
                                Toll-Free Numbers
                            </el-checkbox>
                            <br />
                            <a
                                v-if="!isSimpSocial"
                                href="https://support.aloware.com/en/articles/9032116-understanding-toll-free-number-registry-use-cases-acceptable-and-restricted-categories"
                                class="text-bluish"
                                target="_blank">
                                Learn more
                            </a>
                        </el-form-item>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <el-form-item prop="want_international_numbers">
                            <span id="want_international_numbers" class="page-anchor"/>
                            <el-checkbox
                                v-model="businessRegistrationForm.want_international_numbers"
                                @change="preValidateForm('businessRegistrationForm')"
                                :disabled="disableBusinessRegistrationForm">
                                International Numbers
                            </el-checkbox>
                        </el-form-item>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="row w-full">
                    <div class="col-md-4 mb-3 mb-md-0 flex-3">
                        <alert-message :alert-message="alertMessage" @alert="submitAlert"/>
                    </div>
                    <div class="col-md-4 w-full flex-1">
                        <el-button
                            @click="saveAndCompleteLater"
                            :loading="loading"
                            :disabled="disableBusinessRegistrationForm"
                            type="info"
                            class="w-full">
                            Save & Complete Later
                        </el-button>
                    </div>
                    <div class="col-md-4 w-full flex-1">
                        <el-button
                            @click="submitBusinessRegistrationForm"
                            :loading="loading"
                            :disabled="disableSubmitButton"
                            type="success"
                            class="w-full">
                            Submit
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import auth from '../../../../auth'
import { mapGetters } from 'vuex'
import {compliance_mixin, contact_phone_number_mixin, form_validation_mixin,} from '../../../../mixins'
import businessTypes from '../../../../constants/compliance/business-types'
import businessRegistrationIdentifiers from '../../../../constants/compliance/business-registration-identifiers'
import businessIndustries from '../../../../constants/compliance/business-industries'
import businessRegionsOfOperations from '../../../../constants/compliance/business-regions-of-operations'
import authRepJobPositions from '../../../../constants/compliance/auth-rep-job-positions'
import stockExchanges from '../../../../constants/compliance/stock-exchanges'
import * as ComplianceBundleStatuses from '../../../../constants/compliance-bundle-statuses'
import AlertMessage from './alert-message.vue'
import usStates from '../../../../constants/compliance/compliance-business-registration-us-states'
import caProvinces from '../../../../constants/compliance/compliance-business-registration-ca-provinces'

export default {
    name: 'BusinessRegistration',
    mixins: [
        compliance_mixin,
        form_validation_mixin,
        contact_phone_number_mixin,
    ],
    components: {AlertMessage},
    props: {
        customer_profile: {
            type: Object,
            required: false,
        },
        a2p_profile_bundle: {
            type: Object,
            required: false,
        },
        a2p_brand: {
            type: Object,
            required: false,
        },
    },
    data: () => ({
        businessTypes,
        businessRegistrationIdentifiers,
        businessIndustries,
        businessRegionsOfOperations,
        authRepJobPositions,
        stockExchanges,
        ComplianceBundleStatuses,
        loading: false,
        countries: [],
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + auth.user.profile.api_token,
            'X-Socket-Id': window.Echo.socketId()
        },
        upload_status: {
            message: 'success'
        },
        upload_percentage: {
            message: 0
        },
        upload_file_list: {
            upload: []
        },
        loading_upload: false,
        usStates,
        caProvinces,
        states: [],
        manualCountryChange: false,
        businessRegistrationForm: {
            accepted_compliance_one_time_fee: false,
            // business information
            customer_name: '',
            business_type: '',
            company_status: 1,
            stock_ticker: '',
            stock_exchange: '',
            business_registration_identifier: '',
            other_identifier: '',
            business_industry: '',
            business_registration_number: '',
            business_regions_of_operation: [],
            website_url: '',
            // address information
            street: '',
            street_secondary: '',
            city: '',
            region: '',
            postal_code: '',
            iso_country: '',
            // point of contact
            auth_rep_first_name: '',
            auth_rep_last_name: '',
            auth_rep_email: '',
            auth_rep_phone_number: '',
            auth_rep_business_title: '',
            auth_rep_job_position: '',
            // exceptions & limitations
            want_local_presence_phone_numbers: false,
            want_toll_free_numbers: false,
            want_international_numbers: false,
        },
        rules: {
            accepted_compliance_one_time_fee: [
                {
                    validator: (rule, value, callback) => {
                        if (value === true) {
                            callback()
                        } else {
                            callback(new Error('Please agree one-time fee.'))
                        }
                    },
                    trigger: 'change',
                },
            ],
            customer_name: [
                {required: true, message: 'Please provide a business name.', trigger: 'blur'},
            ],
            business_type: [
                {required: true, message: 'Business type cannot be empty.', trigger: 'change'},
            ],
            company_status: [
                {
                    required: true,
                    type: 'enum',
                    enum: [1, 2],
                    message: 'Please select a company status.',
                    trigger: 'change'
                },
            ],
            stock_ticker: [],
            stock_exchange: [],
            business_registration_identifier: [
                {
                    required: true,
                    message: 'Business registration identifier cannot be empty.',
                    trigger: 'change'
                },
            ],
            business_industry: [
                {required: true, message: 'Business industry cannot be empty.', trigger: 'change'},
            ],
            website_url: [
                {required: true, message: 'Please provide a website URL.', trigger: 'blur'},
                {
                    validator: (rule, value, callback) => {
                        if (value === 'https://') {
                            callback(new Error('Website URL must include a domain.'));
                        } else if (typeof value !== 'string' || !value.startsWith('https://')) {
                            callback(new Error('Website URL must be a valid url.'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'blur'
                }
            ],
            business_regions_of_operation: [
                {required: true, message: 'Business regions of operation cannot be empty.', trigger: 'change'},
            ],
            street: [
                {required: true, message: 'Please provide a street address.', trigger: 'blur'},
            ],
            city: [
                {required: true, message: 'Please provide a city', trigger: 'blur'},
            ],
            region: [
                {required: true, message: 'Please provide a state/province/region.', trigger: 'blur'},
            ],
            postal_code: [
                {required: true, message: 'Please provide a postal code.', trigger: 'blur'},
            ],
            iso_country: [
                {required: true, message: 'Legal Country cannot be empty.', trigger: 'change'},
            ],
            auth_rep_first_name: [
                {required: true, message: 'Please provide a first name.', trigger: 'blur'},
            ],
            auth_rep_last_name: [
                {required: true, message: 'Please provide a last name.', trigger: 'blur'},
            ],
            auth_rep_email: [
                {required: true, message: 'Please provide an email address', trigger: 'blur'},
            ],
            auth_rep_phone_number: [
                {required: true, message: 'Please provide a phone number.', trigger: 'blur'},
            ],
            auth_rep_business_title: [
                {required: true, message: 'Please provide a business title.', trigger: 'blur'},
            ],
            auth_rep_job_position: [
                {required: true, message: 'Job position cannot be empty.', trigger: 'blur'},
            ],
            local_presence_phone_numbers: [
                {required: true, type: 'boolean'},
            ],
            toll_free_numbers: [
                {required: true, type: 'boolean'},
            ],
            international_numbers: [
                {required: true, type: 'boolean'},
            ],
        }
    }),
    created() {
        // Append rules that need a computed function or method
        this.rules.business_registration_number = [
            {required: true, message: 'Business registration number cannot be empty.', trigger: 'blur'},
            {validator: this.validateBusinessRegistrationNumber, trigger: 'blur'},
        ]

        this.countries = this.getCountries()

        // pre-populate the business registration form fields
        this.businessRegistrationForm.accepted_compliance_one_time_fee = this.current_company.accepted_compliance_one_time_fee
        this.businessRegistrationForm.customer_name = this.current_company.legal_name
        this.businessRegistrationForm.business_type = this.current_company.business_type
        this.businessRegistrationForm.company_status = this.current_company.company_status
        this.businessRegistrationForm.stock_ticker = this.current_company.stock_ticker
        this.businessRegistrationForm.stock_exchange = this.current_company.stock_exchange
        this.businessRegistrationForm.business_registration_identifier = this.current_company.business_registration_identifier
        if (!this.businessRegistrationForm.business_registration_identifier) {
            // try to find the identifier from the list by comparing the country code to current_company.country
            if (this.guessedBusinessIdentifier) {
                this.businessRegistrationForm.business_registration_identifier = this.guessedBusinessIdentifier.value
            }
        }
        this.businessRegistrationForm.other_identifier = this.current_company.other_identifier
        this.businessRegistrationForm.business_industry = this.current_company.business_industry
        this.businessRegistrationForm.business_registration_number = this.current_company.business_registration_number
        this.businessRegistrationForm.business_regions_of_operation = this.current_company.business_regions_of_operation
        this.businessRegistrationForm.website_url = this.current_company.website_url
        this.businessRegistrationForm.street = this.current_company.street
        this.businessRegistrationForm.street_secondary = this.current_company.street_secondary
        this.businessRegistrationForm.city = this.current_company.city
        this.businessRegistrationForm.region = this.current_company.region
        this.businessRegistrationForm.postal_code = this.current_company.postal_code
        this.businessRegistrationForm.iso_country = this.current_company.legal_country
        // pre-populate the point of contact fields
        this.businessRegistrationForm.auth_rep_first_name = this.current_company.auth_rep_first_name
        this.businessRegistrationForm.auth_rep_last_name = this.current_company.auth_rep_last_name
        this.businessRegistrationForm.auth_rep_email = this.current_company.auth_rep_email
        this.businessRegistrationForm.auth_rep_phone_number = this.current_company.auth_rep_phone_number
        this.businessRegistrationForm.auth_rep_business_title = this.current_company.auth_rep_business_title
        this.businessRegistrationForm.auth_rep_job_position = this.current_company.auth_rep_job_position
        // pre-populate the exceptions & limitations fields
        this.businessRegistrationForm.want_local_presence_phone_numbers = this.current_company.want_local_presence_phone_numbers
        this.businessRegistrationForm.want_toll_free_numbers = this.current_company.want_toll_free_numbers
        this.businessRegistrationForm.want_international_numbers = this.current_company.want_international_numbers
        // pre-populate the upload file list
        if (this.current_company.business_registration_documents?.length > 0) {
            // for loop to push the file names to the upload_file_list.upload array
            this.current_company.business_registration_documents.forEach((document) => {
                this.upload_file_list.upload.push({
                    name: document.original_file,
                })
            })
        }

        this.setInitialRegion()
    },
    mounted() {
        this.preValidateFormNow('businessRegistrationForm')
    },
    computed: {
        ...mapGetters('cache', [
            'isSimpSocial',
        ]),
        disableBusinessRegistrationForm() {
            return this.current_company.kyc_filled
        },
        disableSubmitButton() {
            return !this.validated || this.current_company.kyc_filled
        },
        guessedBusinessIdentifier() {
            return this.businessRegistrationIdentifiers.find(identifier => identifier.country === this.current_company.country || identifier.country === this.current_company.legal_country)
        },
        isA2pRejected() {
            if (this.customer_profile && this.customer_profile.status === ComplianceBundleStatuses.STATUS_REJECTED) {
                return true
            }

            if (this.a2p_profile_bundle && this.a2p_profile_bundle.prevetting_status === ComplianceBundleStatuses.STATUS_REJECTED) {
                return true
            }

            if (this.a2p_profile_bundle && this.a2p_profile_bundle.status === ComplianceBundleStatuses.STATUS_REJECTED) {
                return true
            }

            return false
        },
        a2pFailureReason() {
            if (this.customer_profile && this.customer_profile.status === ComplianceBundleStatuses.STATUS_REJECTED) {
                return this.customer_profile.failure_reason
            }

            if (this.a2p_profile_bundle && this.a2p_profile_bundle.prevetting_status === ComplianceBundleStatuses.STATUS_REJECTED) {
                return this.a2p_profile_bundle.failure_reason
            }

            if (this.a2p_profile_bundle && this.a2p_profile_bundle.status === ComplianceBundleStatuses.STATUS_REJECTED) {
                return this.a2p_profile_bundle.failure_reason
            }

            return ''
        },
        website_url: {
            get() {
                return this.businessRegistrationForm.website_url;
            },
            set(value) {
                this.businessRegistrationForm.website_url = this.transformUrl(value);
            }
        },
        showCanadianBusinessRegistrationNumberTooltip() {
            return this.businessRegistrationForm.business_registration_identifier === 'CBN';
        },
        showCanadianBusinessRegistrationNumberContent() {
            return `Our carrier is ONLY accepting the first 9 numeric digits of the Canadian Business Number (CBN) <br>
              rather than the Corporation/Incorporation/Registry ID numbers for Canadian Brand Registration.
              <br><br><b>Eg. 123456789 instead of &#42;123456789&#42;RT0001.</b>`
        }
    },
    methods: {
        getCountries() {
            const countries = window.countriesAndTimezones.getAllCountries()

            // first exclude US & CA, and then extract only id, name fields of the countries
            const countriesArr = Object.values(countries)
                .filter(({id}) => !['US', 'CA'].includes(id))
                .map(({id, name}) => {
                    return {id, name}
                })

            // add US & CA to the top and return
            return [
                {id: 'US', name: 'United States'},
                {id: 'CA', name: 'Canada'},
                ...countriesArr,
            ]
        },

        submitBusinessRegistrationForm() {
            if (!this.validated) {
                console.error('Form is not valid')
                return
            }

            this.loading = true

            axios.patch('/api/v1/compliance/business-information', this.businessRegistrationForm).then(({data}) => {
                var message = "Thanks for submitting your business info! 📋🌟 We're on it! Expect a call or email from us within the next 1-2 business days. Keep an eye out! 📞📧"

                if (this.isSimpSocial) {
                    message = 'Customer Profile has been submitted successfully'
                }

                this.$message({
                    showClose: true,
                    message: message,
                    type: 'success',
                    duration: 7000,
                })

                this.setCurrentCompany({
                    ...this.current_company,
                    accepted_compliance_one_time_fee: data.accepted_compliance_one_time_fee,
                    customer_name: data.legal_name,
                    business_type: data.business_type,
                    company_status: data.company_status,
                    stock_ticker: data.stock_ticker,
                    business_registration_identifier: data.business_registration_identifier,
                    other_identifier: data.other_identifier,
                    business_industry: data.business_industry,
                    business_registration_number: data.business_registration_number,
                    business_regions_of_operation: data.business_regions_of_operation,
                    website_url: data.website_url,
                    street: data.street,
                    street_secondary: data.street_secondary,
                    city: data.city,
                    region: data.region,
                    postal_code: data.postal_code,
                    iso_country: data.legal_country,
                    auth_rep_first_name: data.auth_rep_first_name,
                    auth_rep_last_name: data.auth_rep_last_name,
                    auth_rep_email: data.auth_rep_email,
                    auth_rep_phone_number: data.auth_rep_phone_number,
                    auth_rep_business_title: data.auth_rep_business_title,
                    auth_rep_job_position: data.auth_rep_job_position,
                    kyc_filled: data.kyc_filled,
                    want_local_presence_phone_numbers: data.want_local_presence_phone_numbers,
                    want_toll_free_numbers: data.want_toll_free_numbers,
                    want_international_numbers: data.want_international_numbers,
                    customer_profile: data.customer_profile,
                })

                this.$emit('customer-profile-submitted')
                this.submitAlert()
                this.preValidateForm('businessRegistrationForm')
            }).catch((error) => {
                let message = 'Request failed' // Default error message
                if (error.response && error.response.data && error.response.data.message) {
                    // Extract the message from the response data
                    message = error.response.data.message
                } else if (error.response && error.response.statusText) {
                    // Use statusText if no message is present in data
                    message = error.response.statusText
                } else if (error.message) {
                    // Last resort: the error message Axios provides
                    message = error.message
                }

                this.$message({
                    message: message,
                    type: 'error',
                })
            }).finally(() => {
                this.loading = false
            })
        },

        saveAndCompleteLater() {
            this.loading = true

            axios.patch('/api/v1/compliance/save-and-complete-later', this.businessRegistrationForm).then(({data}) => {
                this.$message({
                    showClose: true,
                    message: 'Business information saved successfully',
                    type: 'success',
                })
                this.setCurrentCompany({
                    ...this.current_company,
                    customer_name: data.legal_name,
                    business_type: data.business_type,
                    company_status: data.company_status,
                    stock_ticker: data.stock_ticker,
                    stock_exchange: data.stock_exchange,
                    business_registration_identifier: data.business_registration_identifier,
                    other_identifier: data.other_identifier,
                    business_industry: data.business_industry,
                    business_registration_number: data.business_registration_number,
                    business_regions_of_operation: data.business_regions_of_operation,
                    website_url: data.website_url,
                    street: data.street,
                    street_secondary: data.street_secondary,
                    city: data.city,
                    region: data.region,
                    postal_code: data.postal_code,
                    iso_country: data.legal_country,
                    auth_rep_first_name: data.auth_rep_first_name,
                    auth_rep_last_name: data.auth_rep_last_name,
                    auth_rep_email: data.auth_rep_email,
                    auth_rep_phone_number: data.auth_rep_phone_number,
                    auth_rep_business_title: data.auth_rep_business_title,
                    auth_rep_job_position: data.auth_rep_job_position,
                    kyc_filled: data.kyc_filled,
                    want_local_presence_phone_numbers: data.want_local_presence_phone_numbers,
                    want_toll_free_numbers: data.want_toll_free_numbers,
                    want_international_numbers: data.want_international_numbers,
                    customer_profile: data.customer_profile,
                })
                this.$emit('next')
                this.submitAlert()
                this.preValidateForm('businessRegistrationForm')
            }).catch((error) => {
                let message = 'Request failed' // Default error message
                if (error.response && error.response.data && error.response.data.message) {
                    // Extract the message from the response data
                    message = error.response.data.message
                } else if (error.response && error.response.statusText) {
                    // Use statusText if no message is present in data
                    message = error.response.statusText
                } else if (error.message) {
                    // Last resort: the error message Axios provides
                    message = error.message
                }
                // Display the message using your alert system
                this.$message({
                    message: message,
                    type: 'error',
                })
            }).finally(() => {
                this.loading = false
            })
        },

        validateBusinessRegistrationNumber(rule, value, callback) {
            // We take the value and remove all non-digit characters
            let clean_value = value.replace(/\D+/g, '')

            if (this.businessRegistrationForm.business_registration_identifier === 'CBN') {
                // Business registration number must be 9 digits long
                if (clean_value.length !== 9) {
                    return callback(new Error('The business registration number must be 9 digits long.'));
                }
                this.businessRegistrationForm.business_registration_number = clean_value
            } else if (this.businessRegistrationForm.business_registration_identifier === 'EIN') {
                // EIN must be 9 digits long as well
                if (clean_value.length !== 9) {
                    return callback(new Error('EIN must be 9 digits long'))
                }
                // For EIN, we format it as XX-XXXXXXX
                this.businessRegistrationForm.business_registration_number = `${clean_value.slice(0, 2)}-${clean_value.slice(2)}`
            }
            callback()
        },

        onSuccessMessage(res, file) {
            this.$notify({
                offset: 95,
                title: 'Compliance',
                message: 'Business Registration final uploaded successfully.',
                type: 'success',
                showClose: true,
            })
            this.upload_status.message = 'success'
            this.loading_upload = false
            setTimeout(() => {
                this.preValidateForm('businessRegistrationForm')
            }, 200)
        },

        onFailedMessage(err) {
            this.handleUploadErrors(err.message)
            this.upload_status.message = 'exception'
            this.upload_percentage.message = 0
            this.loading_upload = false
            this.preValidateForm('businessRegistrationForm')
        },

        handleUploadErrors(error) {
            if (typeof error === 'string') {
                error = JSON.parse(error)
            }
            let err
            if (error.message === 'This action is unauthorized.') {
                err = {
                    status: 403,
                }
            } else {
                err = {
                    status: 422,
                    data: {
                        errors: error.errors.file
                    }
                }
            }

            this.$root.handleErrors(err)
        },

        progressUploadMessage(event) {
            this.upload_percentage.message = parseInt(event.percent)
        },

        beforeUploadMessage() {
            this.loading_upload = true
            this.upload_status.message = 'success'
            this.upload_percentage.message = 0
        },

        handleCountryChange() {
            this.manualCountryChange = true;
            this.businessRegistrationForm.region = '';
            this.preValidateForm('businessRegistrationForm');
        },

        setInitialRegion() {
            if (this.businessRegistrationForm.iso_country === 'US') {
                this.states = this.usStates;
            } else if (this.businessRegistrationForm.iso_country === 'CA') {
                this.states = this.caProvinces;
            } else {
                this.states = [];
            }
            const selected_region = this.states.find(state => state.value === this.businessRegistrationForm.region);

            if (selected_region) {
                this.businessRegistrationForm.region = selected_region.value;
            }
        },
    },
    watch: {
        'businessRegistrationForm.iso_country'(new_country) {
            if (this.manualCountryChange) {
                this.businessRegistrationForm.region = '';
            }
            if (new_country === 'US') {
                this.states = this.usStates;
            } else if (new_country === 'CA') {
                this.states = this.caProvinces;
            } else {
                this.states = [];
            }
            this.setInitialRegion();
        },
    },
}
</script>
