var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h5", { staticClass: "text-dark mb-1 vertical-center mt-1" }, [
        _vm._v("Live Call Monitoring"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "d-flex vertical-center" }, [
          _vm._v("\n            Total Live Calls:\n            "),
          _vm.communications
            ? _c(
                "small",
                { staticClass: "ml-1 label badge greenish align-self-center" },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.communications.length) +
                      "\n            "
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex ml-auto filter-call-logs" }, [
          _c(
            "button",
            {
              staticClass: "btn greenish pointer mr-2",
              staticStyle: { height: "40px" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.getCommunications.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons" }, [_vm._v("refresh")]),
              _vm._v(" Refresh\n            "),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle",
                attrs: {
                  "data-toggle": "dropdown",
                  "data-flip": "false",
                  "aria-expanded": "false",
                },
                on: { click: _vm.checkBlur },
              },
              [
                _vm._v("\n                    Table Settings "),
                _c("i", { staticClass: "el-icon-arrow-down" }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
              _c(
                "div",
                { staticClass: "filter-item" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.changeTableFields },
                      model: {
                        value: _vm.checked_table_fields,
                        callback: function ($$v) {
                          _vm.checked_table_fields = $$v
                        },
                        expression: "checked_table_fields",
                      },
                    },
                    _vm._l(_vm.table_fields, function (field) {
                      return _c(
                        "li",
                        {
                          key: field.text,
                          on: {
                            click: function ($event) {
                              return _vm.noClose($event)
                            },
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: field.val } }, [
                            _vm._v(_vm._s(field.text)),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "w-full mt-3",
          attrs: {
            "row-key": "id",
            "default-sort": { prop: "created_at", order: "descending" },
            "min-height": _vm.table_height,
            data: _vm.communications,
            "expand-row-keys": _vm.expandableCommunicationsId,
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "row align-items-center" }, [
                      scope.row.contact
                        ? _c("div", { staticClass: "col-md-4" }, [
                            _c("strong", { staticClass: "text-greenish" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("fixContactName")(
                                    scope.row.contact.name
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            scope.row.type !== 5
                              ? _c("span", { staticClass: "text-greyish" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("fixPhone")(
                                          scope.row.lead_number
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ])
                              : _c(
                                  "span",
                                  { staticClass: "text-greyish text-xs" },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(scope.row.lead_number) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                            _vm._v(" "),
                            scope.row.contact.disposition_status_id
                              ? _c("div", { staticClass: "mt-1" }, [
                                  scope.row.contact.disposition_status_id
                                    ? _c(
                                        "span",
                                        { staticClass: "text-greyish text-xs" },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "material-icons",
                                              style: {
                                                color:
                                                  _vm.$options.filters.dispositionStatusColor(
                                                    scope.row.contact
                                                      .disposition_status_id
                                                  ),
                                              },
                                            },
                                            [_vm._v("label")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "text-grey-900" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f(
                                                    "dispositionStatusName"
                                                  )(
                                                    scope.row.contact
                                                      .disposition_status_id
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.contact.company_name
                              ? _c(
                                  "div",
                                  { staticClass: "mt-1 text-pink-500 text-xs" },
                                  [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("business_center"),
                                    ]),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(scope.row.contact.company_name) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.contact.tags &&
                            scope.row.contact.tags.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "mt-1" },
                                  [
                                    _vm._l(
                                      _vm.$options.filters.lastTags(
                                        scope.row.contact.tags,
                                        3
                                      ),
                                      function (tag) {
                                        return _c(
                                          "div",
                                          {
                                            key: tag.id,
                                            staticClass: "text-xs",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-circle",
                                              style: { color: tag.color },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ml-1 text-grey-900",
                                              },
                                              [_vm._v(_vm._s(tag.name))]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    scope.row.contact.tags.length > 3
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center justify-content-center text-muted text-sm",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                ...\n                            "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.contact.cnam_city ||
                            scope.row.contact.cnam_state
                              ? _c("div", { staticClass: "mt-1" }, [
                                  scope.row.contact.cnam_city
                                    ? _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                scope.row.contact.cnam_city
                                              ) +
                                              ",\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.contact.cnam_state
                                    ? _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                scope.row.contact.cnam_state
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.contact
                        ? _c(
                            "div",
                            {
                              staticClass: "col-md-4",
                              class: [_vm.checkSize() ? "b-l" : "mt-2"],
                            },
                            [
                              scope.row.type === _vm.CommunicationTypes.CALL
                                ? _c("target-users-tree", {
                                    attrs: { communication: scope.row },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.notes
                        ? _c(
                            "div",
                            {
                              staticClass: "col-md-4",
                              class: [_vm.checkSize() ? "b-l" : "mt-2"],
                            },
                            [
                              _c("strong", { staticClass: "text-greyish" }, [
                                _vm._v("Notes:"),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-greyish" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(scope.row.notes) +
                                    "\n                        "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.checkField("disposition_status")
            ? _c("el-table-column", {
                attrs: { prop: "disposition_status", width: "150" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Communication",
                                  params: { communication_id: scope.row.id },
                                },
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "status-icon centered-content",
                                attrs: { state: scope.row.disposition_status2 },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.stateToIcon(
                                      scope.row.disposition_status2,
                                      scope.row.direction,
                                      scope.row.type,
                                      scope.row.callback_status
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge d-inline-flex align-items-center py-1",
                                    class: _vm.stateToIcon(
                                      scope.row.disposition_status2,
                                      scope.row.direction,
                                      scope.row.type,
                                      scope.row.callback_status,
                                      true
                                    ),
                                    staticStyle: { "max-width": "100%" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.dispositionTooltipData(
                                            scope.row.disposition_status2,
                                            scope.row.direction,
                                            scope.row.type,
                                            scope.row.callback_status
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2189321814
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("campaign")
            ? _c("el-table-column", {
                attrs: { label: "Line", "min-width": 150 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.campaign_id
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Line Activity",
                                      params: {
                                        campaign_id: scope.row.campaign_id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm.getCampaign(scope.row.campaign_id)
                                    ? _c(
                                        "a",
                                        { staticClass: "text-dark-greenish" },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.getCampaign(
                                                  scope.row.campaign_id
                                                ).name
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3918480520
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("incoming_number")
            ? _c("el-table-column", {
                attrs: {
                  label: "Number",
                  prop: "incoming_number",
                  "min-width": 150,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-left",
                              },
                              [
                                scope.row.campaign_id
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "Line Activity",
                                            params: {
                                              campaign_id:
                                                scope.row.campaign_id,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _vm.getCampaign(scope.row.campaign_id)
                                          ? _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "text-dark-greenish",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.getCampaign(
                                                        scope.row.campaign_id
                                                      ).name
                                                    ) +
                                                    "\n                            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            scope.row.type !== 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex align-items-center justify-content-left",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-greyish" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("fixPhone")(
                                              scope.row.incoming_number
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex align-items-center justify-content-left",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-greyish" },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.incoming_number)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3249115681
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("ring_group")
            ? _c("el-table-column", {
                attrs: { label: "Ring Group", "min-width": 150 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.ring_group_id &&
                          _vm.getRingGroup(scope.row.ring_group_id)
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Ring Group Dialog",
                                      params: {
                                        ring_group_id: scope.row.ring_group_id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-dark-greenish" },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.getRingGroup(
                                              scope.row.ring_group_id
                                            ).name
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c("span", { staticClass: "text-greyish" }, [
                                _vm._v(
                                  "\n                    -\n                "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  191716312
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("workflow")
            ? _c("el-table-column", {
                attrs: { label: "Sequence", "min-width": 150 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.workflow_id &&
                          _vm.getWorkflow(scope.row.workflow_id)
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Sequence Manager",
                                      params: {
                                        workflow_id: scope.row.workflow_id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-dark-greenish" },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.getWorkflow(
                                              scope.row.workflow_id
                                            ).name
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c("span", { staticClass: "text-greyish" }, [
                                _vm._v(
                                  "\n                    -\n                "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1411311888
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("created_at")
            ? _c("el-table-column", {
                attrs: {
                  label: "Start Time",
                  prop: "created_at",
                  width: "150",
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "text-greyish" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("fixDateTime")(scope.row.created_at)
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          scope.row.call_disposition_id
                            ? _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex align-items-center justify-content-left text-xs",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-bolt",
                                      style: {
                                        color:
                                          _vm.$options.filters.callDispositionColor(
                                            scope.row.call_disposition_id
                                          ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "ml-1 text-grey-900" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("callDispositionName")(
                                              scope.row.call_disposition_id
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3645177186
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("wait_time")
            ? _c("el-table-column", {
                attrs: {
                  label: "Wait Time",
                  prop: "wait_time",
                  sortable: "",
                  width: "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-left",
                              },
                              [
                                ![
                                  _vm.CommunicationTypes.SMS,
                                  _vm.CommunicationTypes.EMAIL,
                                ].includes(scope.row.type)
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixDuration")(
                                            scope.row.wait_time
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                            -\n                        "
                                      ),
                                    ]),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3346503417
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("talk_time")
            ? _c("el-table-column", {
                attrs: {
                  label: "Talk Time",
                  prop: "talk_time",
                  sortable: "",
                  width: "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.type === _vm.CommunicationTypes.CALL
                            ? _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex align-items-center justify-content-left",
                                  },
                                  [
                                    scope.row.disposition_status2 !==
                                      _vm.CommunicationDispositionStatus
                                        .DISPOSITION_STATUS_INPROGRESS_NEW &&
                                    scope.row.current_status2 ===
                                      _vm.CommunicationCurrentStatus
                                        .CURRENT_STATUS_COMPLETED_NEW
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("fixDuration")(
                                                scope.row.talk_time
                                              )
                                            )
                                          ),
                                        ])
                                      : scope.row.disposition_status2 ===
                                          _vm.CommunicationDispositionStatus
                                            .DISPOSITION_STATUS_INPROGRESS_NEW &&
                                        scope.row.current_status2 ===
                                          _vm.CommunicationCurrentStatus
                                            .CURRENT_STATUS_INPROGRESS_NEW
                                      ? _c("relative-time", {
                                          staticClass: "text-muted",
                                          attrs: {
                                            from_time: scope.row.created_at,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-left",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.getVisibleStatus(scope.row))
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2570744349
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("duration")
            ? _c("el-table-column", {
                attrs: {
                  label: "Duration",
                  prop: "duration",
                  sortable: "",
                  width: "110",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          ![
                            _vm.CommunicationTypes.SMS,
                            _vm.CommunicationTypes.EMAIL,
                          ].includes(scope.row.type)
                            ? _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex align-items-center justify-content-left",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixDuration")(
                                            scope.row.duration
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-left",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.getVisibleStatus(scope.row))
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3898782448
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("lead_number")
            ? _c("el-table-column", {
                attrs: {
                  label: "Contact",
                  prop: "lead_number",
                  "min-width": 300,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "row d-flex align-items-center" },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    scope.row.contact &&
                                    scope.row.contact.tags &&
                                    scope.row.contact.tags.length > 0
                                      ? "col-6"
                                      : "col-12",
                                  ],
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    scope.row.contact
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-flex align-items-center justify-content-left",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  target: "_blank",
                                                  href: _vm.getContactTalkRedirectURL(
                                                    scope.row.contact.id
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass:
                                                      "item pull-left",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Click For More Info",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-dark-greenish",
                                                        attrs: {
                                                          title: _vm._f(
                                                            "fixContactName"
                                                          )(
                                                            scope.row.contact
                                                              .name
                                                          ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "fixContactName"
                                                              )(
                                                                scope.row
                                                                  .contact.name
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                scope.row.first_time_caller
                                                  ? _c("i", {
                                                      staticClass:
                                                        "on b-white bottom caller-status pull-left",
                                                      attrs: {
                                                        title:
                                                          "First Time Conversation",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-flex align-items-center justify-content-left",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-dark-greenish pull-left",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("fixPhone")(
                                                      scope.row.lead_number
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            scope.row.first_time_caller
                                              ? _c("i", {
                                                  staticClass:
                                                    "on b-white bottom caller-status pull-left",
                                                  attrs: {
                                                    title:
                                                      "First Time Conversation",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 d-flex align-items-center justify-content-left",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-greyish" },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm._f("fixPhone")(
                                                    scope.row.lead_number
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  scope.row.contact &&
                                  scope.row.contact.disposition_status_id
                                    ? _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-flex align-items-center justify-content-left text-xs",
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "material-icons",
                                                style: {
                                                  color:
                                                    _vm.$options.filters.dispositionStatusColor(
                                                      scope.row.contact
                                                        .disposition_status_id
                                                    ),
                                                },
                                              },
                                              [_vm._v("label")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ml-1 text-grey-900",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f(
                                                      "dispositionStatusName"
                                                    )(
                                                      scope.row.contact
                                                        .disposition_status_id
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.contact &&
                                  scope.row.contact.company_name
                                    ? _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 d-flex align-items-center justify-content-left text-pink-500 text-xs",
                                          },
                                          [
                                            _c(
                                              "i",
                                              { staticClass: "material-icons" },
                                              [_vm._v("business_center")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "ml-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.contact
                                                      .company_name
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              scope.row.contact &&
                              scope.row.contact.tags &&
                              scope.row.contact.tags.length
                                ? _c(
                                    "div",
                                    { staticClass: "col-6" },
                                    [
                                      _vm._l(
                                        _vm.$options.filters.lastTags(
                                          scope.row.contact.tags,
                                          3
                                        ),
                                        function (tag) {
                                          return _c(
                                            "div",
                                            {
                                              key: tag.id,
                                              staticClass:
                                                "d-flex align-items-center justify-content-left text-xs",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-circle",
                                                style: { color: tag.color },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ml-1 text-grey-900",
                                                },
                                                [_vm._v(_vm._s(tag.name))]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      scope.row.contact.tags.length > 3
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center justify-content-center text-muted text-sm",
                                            },
                                            [
                                              _vm._v(
                                                "\n                            ...\n                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2613567701
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("lead_location")
            ? _c("el-table-column", {
                attrs: {
                  label: "Location",
                  prop: "lead_location",
                  sortable: "",
                  width: "140",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-left",
                              },
                              [
                                scope.row.state
                                  ? _c(
                                      "span",
                                      { staticClass: "text-greyish" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(scope.row.city || "") +
                                            _vm._s(
                                              scope.row.city && scope.row.state
                                                ? ", "
                                                : ""
                                            ) +
                                            _vm._s(scope.row.state) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "text-greyish" },
                                      [
                                        _vm._v(
                                          "\n                            -\n                        "
                                        ),
                                      ]
                                    ),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2911093349
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("user")
            ? _c("el-table-column", {
                attrs: {
                  label: "User",
                  prop: "user",
                  sortable: "",
                  "min-width": 150,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-left",
                              },
                              [
                                scope.row.rejected_by_app != 0
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          "popper-class": "rejection-tooltip",
                                          content: _vm.rejectionTooltipData(
                                            scope.row.rejected_by_app,
                                            scope.row.type
                                          ),
                                          placement: "right",
                                        },
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Communication",
                                                params: {
                                                  communication_id:
                                                    scope.row.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "status-icon d-inline-block",
                                              attrs: {
                                                state:
                                                  scope.row.rejected_by_app,
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.rejectionToIcon(
                                                    scope.row.rejected_by_app
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : scope.row.user_id &&
                                    _vm.getUser(scope.row.user_id)
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "User Activity",
                                                params: {
                                                  user_id: scope.row.user_id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-dark-greenish",
                                                attrs: {
                                                  title: _vm.getUserName(
                                                    _vm.getUser(
                                                      scope.row.user_id
                                                    )
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.getUserName(
                                                        _vm.getUser(
                                                          scope.row.user_id
                                                        )
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "text-greyish" },
                                        [
                                          _vm._v(
                                            "\n                                -\n                            "
                                          ),
                                        ]
                                      ),
                                    ]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  449430451
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("in_cold_transfer")
            ? _c("el-table-column", {
                attrs: {
                  label: "Cold Transferred?",
                  prop: "in_cold_transfer",
                  sortable: "",
                  "min-width": 180,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 d-flex align-items-center justify-content-center",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-dark-greenish _400" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.in_cold_transfer
                                          ? "Yes"
                                          : "No"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2335635542
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("transfer_type")
            ? _c("el-table-column", {
                attrs: {
                  label: "Transfer Type",
                  prop: "transfer_type",
                  width: "150",
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.transfer_type
                            ? _c("span", { staticClass: "text-greyish" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("translateTransferTypeText")(
                                        scope.row.transfer_type
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _c("div", [
                                _c("span", { staticClass: "text-greyish" }, [
                                  _vm._v(
                                    "\n                  -\n              "
                                  ),
                                ]),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  243763201
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("callback_status")
            ? _c("el-table-column", {
                attrs: {
                  label: "Callback Status",
                  prop: "callback_status",
                  width: "150",
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.callback_status
                            ? _c("span", { staticClass: "text-greyish" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("translateCallbackStatusText")(
                                        scope.row.callback_status
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _c("div", [
                                _c("span", { staticClass: "text-greyish" }, [
                                  _vm._v(
                                    "\n                  -\n              "
                                  ),
                                ]),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3329069199
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("tags")
            ? _c("el-table-column", {
                attrs: { label: "Tags", "min-width": 140 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("communication-tags", {
                            attrs: { communication: scope.row },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1817138699
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("notes")
            ? _c("el-table-column", {
                attrs: { align: "center", label: "Notes", "min-width": 70 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("communication-note", {
                            attrs: { communication: scope.row },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3530389722
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.checkField("operations")
            ? _c("el-table-column", {
                attrs: { align: "left", label: "Operations", width: "220" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.contact &&
                          scope.row.type == 2 &&
                          _vm.hasPermissionTo("send sms")
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "Reply",
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-sm blue btn-icon opaq",
                                      attrs: {
                                        target: "_blank",
                                        href: _vm.getContactTalkRedirectURL(
                                          scope.row.contact.id
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("reply")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "More Details",
                                placement: "bottom",
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm greyish btn-icon opaq",
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoCommunicationInfo(
                                        scope.row.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("info_outline"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.hasRole("Company Admin") &&
                          scope.row.disposition_status2 ==
                            _vm.CommunicationDispositionStatus
                              .DISPOSITION_STATUS_INPROGRESS_NEW
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "Terminate",
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm danger btn-icon opaq",
                                      attrs: {
                                        loading: _vm.terminate_loading,
                                        disabled: _vm.terminate_loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.terminateCommunication(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("power_settings_new")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.userCanBargeAndWhisper(scope.row)
                            ? [
                                _vm.isBlockedFrom("barge & whisper")
                                  ? [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content:
                                              "The Barge option is not included in your current plan, to use it, you have to upgrade to one of our plans that offers it!",
                                            effect:
                                              "power-dialer-start-button-tooltip",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm deep-purple btn-icon opaq",
                                              attrs: { disabled: true },
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                },
                                                [_vm._v("volume_up")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content:
                                              "The Whisper option is not included in your current plan, to use it, you have to upgrade to one of our plans that offers it!",
                                            effect:
                                              "power-dialer-start-button-tooltip",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm lime btn-icon opaq",
                                              attrs: { disabled: true },
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "material-icons",
                                                },
                                                [_vm._v("hearing")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : [
                                      _vm.userCanBargeAndWhisper(scope.row)
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "Barge",
                                                placement: "bottom",
                                              },
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-sm deep-purple btn-icon opaq",
                                                  attrs: {
                                                    loading: _vm.barge_loading,
                                                    disabled: _vm.barge_loading,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.bargeCommunicationDialog(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons",
                                                    },
                                                    [_vm._v("volume_up")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.userCanBargeAndWhisper(scope.row)
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "Whisper",
                                                placement: "bottom",
                                              },
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-sm lime btn-icon opaq",
                                                  attrs: {
                                                    loading:
                                                      _vm.whisper_loading,
                                                    disabled:
                                                      _vm.whisper_loading,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.whisperCommunicationDialog(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons",
                                                    },
                                                    [_vm._v("hearing")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1423242336
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { staticClass: "text-center text-muted", slot: "empty" },
            [
              !_vm.loading ? _c("span", [_vm._v("No live calls")]) : _vm._e(),
              _vm._v(" "),
              _vm.loading ? _c("span") : _vm._e(),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("b", [_vm._v("Columns:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }