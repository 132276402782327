<template>
    <div></div>
</template>

<script>
import auth from '../auth'
import {acl_mixin, custom_script_mixin, helper_mixin} from '../mixins'
import {mapState} from "vuex"

export default {
    mixins: [acl_mixin, helper_mixin, custom_script_mixin],

    data() {
        return {
            intercom: {
                height: 0
            },
            auth: auth,
            env: null,
            loading_whitelabel: true,
            statics: null
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),
    },

    created() {
        this.getStatics().then(() => {
            // Does not allow Intercom in local environments
            if (this.isLocal) {
                return
            }

            if (!this.hasReporterAccess && this.statics && !this.statics.whitelabel && this.auth.user && this.auth.user.profile && localStorage.getItem('intercom_app_id') !== '') {
                this.setupIntercom()
            }
        })
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            return axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.loading_whitelabel = false

                return Promise.resolve(res.data)
            }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false

                return Promise.reject(err)
            })
        },

        setupIntercom() {
            // @custom for SimpSocial
            if (this.isSimpSocial) {
                return
            }

            axios.get('/api/v1/profile/intercom-user-hash').then(res => {
                // make sure intercom is loaded
                if (window.Intercom) {
                    window.Intercom('boot', {
                        alignment: 'left',
                        api_base: "https://api-iam.intercom.io",
                        app_id: localStorage.getItem('intercom_app_id'),
                        name: this.auth.user.profile.name, // Current user name
                        email: this.auth.user.profile.email, // Current user email address
                        user_id: this.auth.user.profile.id, // Current user id
                        user_hash: res.data, // Current user hash
                        created_at: Math.floor(Date.now() / 1000),
                    })

                    window.dispatchEvent(new Event('customResize'))

                    // Check if the height of the intercom iframe changes
                    // Every second
                    let self = this
                    setInterval(function () {
                        let intercom_iframe = document.querySelector('[name=intercom-banner-frame]')
                        let intercom_iframe_height = self.getIntercomIframeHeight(intercom_iframe)

                        if (intercom_iframe_height !== self.intercom.height) {
                            window.dispatchEvent(new Event('customResize'))
                        }

                        self.intercom.height = intercom_iframe_height
                    }, 1 * 1000)
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    },

    beforeCreate() {
        axios.get('/build-info.json').then(res => {
            this.env = res.data.env
        }).catch(err => {
            console.log(err)
        })
    },
}
</script>
