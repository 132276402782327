<template>
    <el-alert type="warning"
              v-if="!isSimpSocial"
              :closable="false">
        <div class="upgrade-now no-select">
            <p class="info-text">
                <span>{{ text }}</span>
                <el-button
                    v-if="kbLink"
                    size="large"
                    type="text"
                    @click="openKnowledgeBaseLink">
                    Learn More
                </el-button>
            </p>
            <div class="flex-row mb-3">
                <el-button
                    type="primary"
                    size="large"
                    :disabled="disabled"
                    @click="checkClick"
                    round>
                    {{ buttonText }}
                </el-button>
            </div>
        </div>
    </el-alert>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    props: {
        text: {
            type: String,
            default: "This is not included in your current plan. To use it, please contact us to upgrade today!",
            required: false
        },
        buttonText: {
            type: String,
            default: "Request a Plan Upgrade",
            required: false
        },
        kbLink: {
            type: String,
            required: false
        },
        defaultClick: {
            type: Boolean,
            default: true,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
    },

    computed: {
        ...mapGetters('cache', ['isSimpSocial', 'isModGen']),
    },

    methods: {
        openKnowledgeBaseLink() {
            window.open(this.kbLink, "_blank")
        },

        checkClick() {
            let defaultLink = (this.isModGen) ? 'https://moderategeni.us/aloware-info' : 'https://meetings.hubspot.com/alwr/aloware-demo'

            if (this.defaultClick) {
                window.open(defaultLink, "_blank")
            } else {
                this.$emit("click")
            }
        },
    }
}
</script>

<style scoped>
    .upgrade-now {
        display: flex;
        flex-direction: column;
    }

    .el-button--text {
        text-decoration: underline;
    }

    .el-button {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
    }
</style>
