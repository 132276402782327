var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "div",
        { staticClass: "app-body page-user-dialog", attrs: { id: "view" } },
        [
          _vm.hasPermissionTo("view user") &&
          !_vm.user.read_only_access &&
          !_vm.auth.user.profile.is_reseller
            ? _c(
                "div",
                {
                  staticClass: "fixed-header padding pt-0 pb-0",
                  style: { top: _vm.top_offset + 50 + "px" },
                },
                [
                  _c("div", { staticClass: "row mb-0 mt-0" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 p-2" },
                      [
                        _vm.user_id
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "User Activity",
                                    params: { user_id: _vm.user_id },
                                  },
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn purplish pull-left",
                                    attrs: {
                                      "data-testid": "activity-user-button",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-signal" }),
                                    _vm._v(
                                      "\n                        Activity\n                    "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasRole("Company Admin") &&
                        (!_vm.user_id ||
                          _vm.auth.user.profile.id !== _vm.user_id)
                          ? _c(
                              "button",
                              {
                                staticClass: "btn pull-left ml-2 blue",
                                attrs: { "data-testid": "help-user-button" },
                                on: { click: _vm.toggleHelp },
                              },
                              [
                                _c(
                                  "i",
                                  { staticClass: "material-icons text-lg" },
                                  [_vm._v("help")]
                                ),
                                _vm._v(
                                  "\n                    Help\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerSeatPlanUseCase() &&
          _vm.getAvailableLicenses() == 0 &&
          !_vm.user_id
            ? _c(
                "al-alert",
                { staticClass: "my-2 mt-5", attrs: { dimisable: true } },
                [
                  _c("span", {
                    staticClass: "mr-5 text-dark",
                    domProps: { innerHTML: _vm._s(_vm.notificationMessage) },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "container-fluid d-relative",
              class: [
                _vm.hasPermissionTo("view user") &&
                !_vm.user.read_only_access &&
                !_vm.auth.user.profile.is_reseller &&
                (_vm.user.id || _vm.getAvailableLicenses() > 0)
                  ? "mt-5"
                  : "",
              ],
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "no-effect" }, [
                    _c("div", { staticClass: "card my-4" }, [
                      _c(
                        "div",
                        { staticClass: "card-body pb-0" },
                        [
                          _c(
                            "el-form",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading",
                                },
                              ],
                              ref: "user_form",
                              staticClass: "form-aloware",
                              attrs: {
                                autocomplete: "off",
                                "label-position": "top",
                                model: _vm.user,
                                rules: _vm.rules,
                                "data-testid": "dialog-form",
                              },
                              nativeOn: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submitForm("user_form")
                                },
                              },
                            },
                            [
                              [
                                _c(
                                  "el-tabs",
                                  {
                                    on: { "tab-click": _vm.tabClicked },
                                    model: {
                                      value: _vm.active_tab_name,
                                      callback: function ($$v) {
                                        _vm.active_tab_name = $$v
                                      },
                                      expression: "active_tab_name",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tab-pane",
                                      {
                                        staticClass: "pt-2",
                                        attrs: {
                                          name: "profile-settings",
                                          "data-testid":
                                            "profile-settings-pane",
                                          label: "Profile Settings",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-8" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pl-3 pr-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-12",
                                                        },
                                                        [
                                                          _vm.user
                                                            .is_destination
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "alert alert-warning alert-aloware",
                                                                },
                                                                [
                                                                  _c("strong", [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fa fa-exclamation-triangle",
                                                                    }),
                                                                    _vm._v(
                                                                      "\n                                                                        This is an extension. To upgrade it to a real user, please\n                                                                        contact us.\n                                                                    "
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "page-anchor",
                                                            attrs: {
                                                              id: "profile-info",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group",
                                                            },
                                                            [
                                                              _c(
                                                                "el-form-item",
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "page-anchor",
                                                                    attrs: {
                                                                      id: "name",
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "row",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-6",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-form-item",
                                                                            {
                                                                              staticClass:
                                                                                "no-border",
                                                                              attrs:
                                                                                {
                                                                                  prop: "first_name",
                                                                                  "data-testid":
                                                                                    "first-name-form-item",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-label",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "h5",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "First Name"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "el-input",
                                                                                {
                                                                                  staticClass:
                                                                                    "w-100",
                                                                                  attrs:
                                                                                    {
                                                                                      placeholder:
                                                                                        "John",
                                                                                      disabled:
                                                                                        _vm.isCompanyKYCNotAbleToAddUser,
                                                                                      "data-testid":
                                                                                        "fist-name-input",
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.preValidateForm(
                                                                                          "user_form"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .user
                                                                                          .first_name,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.user,
                                                                                            "first_name",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "user.first_name",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-6",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-form-item",
                                                                            {
                                                                              staticClass:
                                                                                "no-border",
                                                                              attrs:
                                                                                {
                                                                                  prop: "last_name",
                                                                                  "data-testid":
                                                                                    "last-name-form-item",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-label",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "h5",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Last Name"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "el-input",
                                                                                {
                                                                                  staticClass:
                                                                                    "w-100",
                                                                                  attrs:
                                                                                    {
                                                                                      placeholder:
                                                                                        "Doe",
                                                                                      disabled:
                                                                                        _vm.isCompanyKYCNotAbleToAddUser,
                                                                                      "data-testid":
                                                                                        "last-name-input",
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.preValidateForm(
                                                                                          "user_form"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .user
                                                                                          .last_name,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.user,
                                                                                            "last_name",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "user.last_name",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    prop: "description",
                                                                    "data-testid":
                                                                      "description-item",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-label",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "page-anchor",
                                                                          attrs:
                                                                            {
                                                                              id: "description",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("h5", [
                                                                        _vm._v(
                                                                          "Description (Optional)"
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      attrs: {
                                                                        type: "textarea",
                                                                        maxlength:
                                                                          "190",
                                                                        autosize:
                                                                          {
                                                                            minRows: 3,
                                                                            maxRows: 3,
                                                                          },
                                                                        "show-word-limit":
                                                                          "",
                                                                        "data-testid":
                                                                          "description-input",
                                                                      },
                                                                      on: {
                                                                        input:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.preValidateForm(
                                                                              "user_form"
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .user
                                                                            .description,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.user,
                                                                              "description",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "user.description",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              !_vm.user
                                                                .is_destination
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        prop: "email",
                                                                        "data-testid":
                                                                          "email-form-item",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-label",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "page-anchor",
                                                                              attrs:
                                                                                {
                                                                                  id: "email",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "h5",
                                                                            [
                                                                              _vm._v(
                                                                                "Email"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          staticClass:
                                                                            "w-100",
                                                                          attrs:
                                                                            {
                                                                              type: "email",
                                                                              placeholder:
                                                                                "john.doe@email.com",
                                                                              disabled:
                                                                                !!_vm
                                                                                  .user
                                                                                  .access_user_id ||
                                                                                _vm.isCompanyKYCNotAbleToAddUser,
                                                                              "data-testid":
                                                                                "email-input",
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.preValidateForm(
                                                                                  "user_form"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .user
                                                                                  .email,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.user,
                                                                                    "email",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "user.email",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              !_vm.auth.user
                                                                .profile
                                                                .is_reseller
                                                                ? [
                                                                    _vm.allowUserRoleUpdate(
                                                                      _vm.user
                                                                    )
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "data-testid":
                                                                                  "role-name-form-item",
                                                                                prop: "role_name",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "page-anchor",
                                                                                attrs:
                                                                                  {
                                                                                    id: "role",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "form-label",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h5",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Role"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "small",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Choose a role for this user"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "row mb-3",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-12",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-radio-group",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            "data-testid":
                                                                                              "role-radio-group",
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.preValidateForm(
                                                                                                "user_form"
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .user
                                                                                                .role_name,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.user,
                                                                                                  "role_name",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "user.role_name",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "el-popover",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                placement:
                                                                                                  "bottom-start",
                                                                                                trigger:
                                                                                                  "hover",
                                                                                                "data-testid":
                                                                                                  "admin-role-popover",
                                                                                                content:
                                                                                                  "Admins have full access to everything.",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "el-radio",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "_600",
                                                                                                attrs:
                                                                                                  {
                                                                                                    slot: "reference",
                                                                                                    label:
                                                                                                      "Company Admin",
                                                                                                    border:
                                                                                                      "",
                                                                                                    "data-testid":
                                                                                                      "admin-role-radio",
                                                                                                  },
                                                                                                on: {
                                                                                                  change:
                                                                                                    _vm.roleChange,
                                                                                                },
                                                                                                slot: "reference",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                                                Admin\n                                                                                            "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "el-popover",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                placement:
                                                                                                  "bottom-start",
                                                                                                trigger:
                                                                                                  "hover",
                                                                                                "data-testid":
                                                                                                  "agent-role-popover",
                                                                                                content:
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "p",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                                                Agents have read access to all your lines, users, contacts, and sequences. "
                                                                                                ),
                                                                                                _c(
                                                                                                  "br"
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  "\n                                                                                                Agent's visibility can be configured in the Visibility Settings tab.\n                                                                                            "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "el-radio",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "_600",
                                                                                                attrs:
                                                                                                  {
                                                                                                    slot: "reference",
                                                                                                    label:
                                                                                                      "Company Agent",
                                                                                                    border:
                                                                                                      "",
                                                                                                    "data-testid":
                                                                                                      "agent-role-radio",
                                                                                                  },
                                                                                                on: {
                                                                                                  change:
                                                                                                    _vm.roleChange,
                                                                                                },
                                                                                                slot: "reference",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                                                Agent\n                                                                                            "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "el-popover",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                placement:
                                                                                                  "bottom-start",
                                                                                                trigger:
                                                                                                  "hover",
                                                                                                "data-testid":
                                                                                                  "supervisor-role-popover",
                                                                                                content:
                                                                                                  "Supervisors have limited admin access.",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "el-radio",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "_600",
                                                                                                attrs:
                                                                                                  {
                                                                                                    slot: "reference",
                                                                                                    label:
                                                                                                      "Company Supervisor",
                                                                                                    border:
                                                                                                      "",
                                                                                                    "data-testid":
                                                                                                      "supervisor-role-radio",
                                                                                                  },
                                                                                                on: {
                                                                                                  change:
                                                                                                    _vm.roleChange,
                                                                                                },
                                                                                                slot: "reference",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                                                Supervisor\n                                                                                            "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.hasRole(
                                                                              "Billing Admin"
                                                                            ) &&
                                                                            _vm
                                                                              .user
                                                                              .role_name ===
                                                                              "Company Admin"
                                                                              ? _c(
                                                                                  "el-form-item",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-border",
                                                                                    attrs:
                                                                                      {
                                                                                        "data-testid":
                                                                                          "billing-name-form-item",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "form-label",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "h5",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Billing Admin?"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "small",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "This user will be responsible\n                                                                                        for billing, invoices, and\n                                                                                        payments."
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "el-checkbox",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            disabled:
                                                                                              _vm
                                                                                                .user
                                                                                                .role_name !==
                                                                                              "Company Admin",
                                                                                            border:
                                                                                              "",
                                                                                            "data-testid":
                                                                                              "billing-admin-checkbox",
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm.mark_as_billing_admin,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.mark_as_billing_admin =
                                                                                                  $$v
                                                                                              },
                                                                                            expression:
                                                                                              "mark_as_billing_admin",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                                    Billing Admin\n                                                                                "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _vm.canBeEdited
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                prop: "answer_by",
                                                                                "data-testid":
                                                                                  "answer-by-form-item",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "page-anchor",
                                                                                attrs:
                                                                                  {
                                                                                    id: "answer",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "form-label",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h5",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Answer Type"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "small",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Choose how this user answers calls"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-select",
                                                                              {
                                                                                staticClass:
                                                                                  "width-500 fixed lh-1 multi-line",
                                                                                attrs:
                                                                                  {
                                                                                    placeholder:
                                                                                      "Select a type",
                                                                                    "data-testid":
                                                                                      "answer-type-selector",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.checkUserPhoneNumber,
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .user
                                                                                        .answer_by,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.user,
                                                                                          "answer_by",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "user.answer_by",
                                                                                  },
                                                                              },
                                                                              _vm._l(
                                                                                _vm.answer_types,
                                                                                function (
                                                                                  answer_type
                                                                                ) {
                                                                                  return _c(
                                                                                    "el-option",
                                                                                    {
                                                                                      key: answer_type.id,
                                                                                      staticClass:
                                                                                        "p-0",
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            answer_type.description,
                                                                                          value:
                                                                                            answer_type.id,
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "media",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "media-body",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "label",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      answer_type.description
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                "\n                                                                                            " +
                                                                                                  _vm._s(
                                                                                                    answer_type.help_text
                                                                                                  ) +
                                                                                                  "\n                                                                                        "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _vm.canBeEdited &&
                                                                    [
                                                                      _vm
                                                                        .AnswerTypes
                                                                        .BY_BROWSER,
                                                                      _vm
                                                                        .AnswerTypes
                                                                        .BY_IP_PHONE,
                                                                    ].includes(
                                                                      _vm.user
                                                                        .answer_by
                                                                    )
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "data-testid":
                                                                                  "phone-number-as-backup-item",
                                                                                prop: "phone_number_as_backup",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "page-anchor",
                                                                                attrs:
                                                                                  {
                                                                                    id: "backup-routing",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "form-label",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h5",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Backup routing (Beta)"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "small",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                    Call routing will check if\n                                                                                    the user is online on\n                                                                                    Aloware. If you check this,\n                                                                                    the backup phone number will\n                                                                                    ring if you are not available.\n                                                                                "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-checkbox",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "Use a phone number as backup",
                                                                                    "data-testid":
                                                                                      "backup-routing-checkbox",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.checkUserPhoneNumber,
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .user
                                                                                        .phone_number_as_backup,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.user,
                                                                                          "phone_number_as_backup",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "user.phone_number_as_backup",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-form-item",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "show",
                                                                              rawName:
                                                                                "v-show",
                                                                              value:
                                                                                _vm.userDestinationEditable &&
                                                                                (([
                                                                                  _vm
                                                                                    .AnswerTypes
                                                                                    .BY_BROWSER,
                                                                                  _vm
                                                                                    .AnswerTypes
                                                                                    .BY_IP_PHONE,
                                                                                ].includes(
                                                                                  _vm
                                                                                    .user
                                                                                    .answer_by
                                                                                ) &&
                                                                                  _vm
                                                                                    .user
                                                                                    .phone_number_as_backup) ||
                                                                                  _vm
                                                                                    .user
                                                                                    .answer_by ===
                                                                                    _vm
                                                                                      .AnswerTypes
                                                                                      .BY_PHONE_NUMBER),
                                                                              expression:
                                                                                "userDestinationEditable && (([AnswerTypes.BY_BROWSER, AnswerTypes.BY_IP_PHONE].includes(user.answer_by) && user.phone_number_as_backup) || user.answer_by === AnswerTypes.BY_PHONE_NUMBER)",
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          prop: "phone_number",
                                                                          "data-testid":
                                                                            "phone-number-form-item",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "page-anchor",
                                                                            attrs:
                                                                              {
                                                                                id: "phone-number",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "form-label",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h5",
                                                                              [
                                                                                _vm
                                                                                  .user
                                                                                  .answer_by ===
                                                                                  _vm
                                                                                    .AnswerTypes
                                                                                    .BY_BROWSER &&
                                                                                !_vm
                                                                                  .user
                                                                                  .read_only_access
                                                                                  ? _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Backup"
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  "\n                                                                                    Phone Number\n                                                                                "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "el-input",
                                                                          {
                                                                            staticClass:
                                                                              "w-100",
                                                                            attrs:
                                                                              {
                                                                                type: "tel",
                                                                                placeholder:
                                                                                  "(123) 456-7890",
                                                                                autocomplete:
                                                                                  "off",
                                                                                "data-testid":
                                                                                  "phone-number-input",
                                                                              },
                                                                            on: {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.preValidateForm(
                                                                                    "user_form"
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .user
                                                                                    .phone_number,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.user,
                                                                                      "phone_number",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "user.phone_number",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _vm.canBeEdited &&
                                                                    _vm.user
                                                                      .answer_by !==
                                                                      _vm
                                                                        .AnswerTypes
                                                                        .BY_BROWSER &&
                                                                    _vm.user
                                                                      .answer_by !==
                                                                      _vm
                                                                        .AnswerTypes
                                                                        .BY_NONE
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                prop: "respect_agent_status",
                                                                                "data-testid":
                                                                                  "respect-agent-form-item",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "page-anchor",
                                                                                attrs:
                                                                                  {
                                                                                    id: "respect_agent_status",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "form-label",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h5",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Respect agent availability status (Beta)"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-checkbox",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "Respect agent availability status",
                                                                                    "data-testid":
                                                                                      "agent-availability-status-checkbox",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .user
                                                                                        .respect_agent_status,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.user,
                                                                                          "respect_agent_status",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "user.respect_agent_status",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _vm.canBeEdited &&
                                                                    _vm.isNotOwnSettings &&
                                                                    _vm.user
                                                                      .role_name ===
                                                                      "Company Agent"
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                prop: "answers_messages",
                                                                                "data-testid":
                                                                                  "answers-messages-form-item",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "page-anchor",
                                                                                attrs:
                                                                                  {
                                                                                    id: "answers-messages",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "form-label",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h5",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Text Message Handling (Beta)"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "small",
                                                                                  [
                                                                                    _vm._v(
                                                                                      '\n                                                                                    If you don\'t want this user to\n                                                                                    be assigned to text\n                                                                                    messages please uncheck "Answers\n                                                                                    text messages."\n                                                                                '
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-checkbox",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "Answers text messages",
                                                                                    "data-testid":
                                                                                      "text-message-handling-checkbox",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .user
                                                                                        .answers_messages,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.user,
                                                                                          "answers_messages",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "user.answers_messages",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    !_vm.userIsCompanyAdmin &&
                                                                    !_vm.user
                                                                      .is_destination
                                                                      ? [
                                                                          _vm.isNotOwnSettings &&
                                                                          _vm
                                                                            .user
                                                                            .role_name ===
                                                                            "Company Agent"
                                                                            ? _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      prop: "can_change_contact_ownership",
                                                                                      "data-testid":
                                                                                        "phone-number-form-item",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "page-anchor",
                                                                                      attrs:
                                                                                        {
                                                                                          id: "change-contact-ownership",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "form-label",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "h5",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Change Contact Ownership"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "small",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                                        If you don't want to allow this user to change contact ownership, please uncheck the checkbox below.\n                                                                                    "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-checkbox",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "Can change contact ownership",
                                                                                          "data-testid":
                                                                                            "contact-ownership-checkbox",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .user
                                                                                              .can_change_contact_ownership,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.user,
                                                                                                "can_change_contact_ownership",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "user.can_change_contact_ownership",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.isNotOwnSettings &&
                                                                          _vm
                                                                            .user
                                                                            .role_name ===
                                                                            "Company Agent"
                                                                            ? _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      prop: "can_modify_contact_ring_groups",
                                                                                      "data-testid":
                                                                                        "allow-ring-groups-modifications-form-item",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "page-anchor",
                                                                                      attrs:
                                                                                        {
                                                                                          id: "modify-contact-ring-groups",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "form-label",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "h5",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Allow Contact Ring Groups Modification"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "small",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                                        If you don't want to allow this user to modify the contact ring groups, please uncheck the checkbox below.\n                                                                                    "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-checkbox",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "Can modify contact ring groups",
                                                                                          "data-testid":
                                                                                            "contact-ring-groups-checkbox",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .user
                                                                                              .can_modify_contact_ring_groups,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.user,
                                                                                                "can_modify_contact_ring_groups",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "user.can_modify_contact_ring_groups",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.isNotOwnSettings &&
                                                                          _vm
                                                                            .user
                                                                            .role_name ===
                                                                            "Company Agent"
                                                                            ? _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      prop: "can_barge_and_whisper_on_call",
                                                                                      "data-testid":
                                                                                        "call-barge-and-whisper-form-item",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "page-anchor",
                                                                                      attrs:
                                                                                        {
                                                                                          id: "barge-and-whisper-on-call",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "form-label",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "h5",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Allow Call Barge and Whisper"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "small",
                                                                                        [
                                                                                          _vm._v(
                                                                                            '\n                                                                                        If you don\'t want to allow this user to barge and/or whisper on a call, please uncheck "Can barge and\n                                                                                        whisper on a call".\n                                                                                    '
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-checkbox",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "Can barge and whisper on a call",
                                                                                          "data-testid":
                                                                                            "call-barge-and-whisper-checkbox",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .user
                                                                                              .can_barge_and_whisper_on_call,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.user,
                                                                                                "can_barge_and_whisper_on_call",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "user.can_barge_and_whisper_on_call",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.isNotOwnSettings &&
                                                                          _vm
                                                                            .user
                                                                            .role_name ===
                                                                            "Company Agent"
                                                                            ? _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      prop: "has_broadcast_access",
                                                                                      "data-testid":
                                                                                        "can-broadcast-form-item",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "page-anchor",
                                                                                      attrs:
                                                                                        {
                                                                                          id: "user-broadcast-access",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "form-label",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "h5",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Can Broadcast"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "small",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Grant broadcast access to the user."
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-checkbox",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "Can create and update broadcast",
                                                                                          "data-testid":
                                                                                            "grant-broadcast-checkbox",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .user
                                                                                              .has_broadcast_access,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.user,
                                                                                                "has_broadcast_access",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "user.has_broadcast_access",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.isNotOwnSettings &&
                                                                          _vm
                                                                            .user
                                                                            .role_name ===
                                                                            "Company Agent"
                                                                            ? _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      prop: "can_delete_contact",
                                                                                      "data-testid":
                                                                                        "can-delete-contact-form-item",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "page-anchor",
                                                                                      attrs:
                                                                                        {
                                                                                          id: "can_delete_contact",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "form-label",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "h5",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mb-0",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Delete Contact"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "small",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Grant user the ability to delete a contact."
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-checkbox",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "Can delete a contact",
                                                                                          "data-testid":
                                                                                            "delete-contact-checkbox",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .user
                                                                                              .can_delete_contact,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.user,
                                                                                                "can_delete_contact",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "user.can_delete_contact",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _vm
                                                                      .connectedCampaigns
                                                                      .length >
                                                                    0
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                prop: "user_campaign",
                                                                                "data-testid":
                                                                                  "users-personal-line-form-item",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "page-anchor",
                                                                                attrs:
                                                                                  {
                                                                                    id: "user-personal-line",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "form-label",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "h5",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "User's personal line"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "el-select",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mt-2",
                                                                                    attrs:
                                                                                      {
                                                                                        prop: "campaign_id",
                                                                                        disabled:
                                                                                          !_vm.canEditPersonalLine,
                                                                                        "data-testid":
                                                                                          "connected-campaigns-selector",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .user
                                                                                            .campaign_id,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.user,
                                                                                              "campaign_id",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "user.campaign_id",
                                                                                      },
                                                                                  },
                                                                                  _vm._l(
                                                                                    _vm.connectedCampaigns,
                                                                                    function (
                                                                                      campaign
                                                                                    ) {
                                                                                      return _c(
                                                                                        "el-option",
                                                                                        {
                                                                                          key: campaign.id,
                                                                                          attrs:
                                                                                            {
                                                                                              label:
                                                                                                campaign.name,
                                                                                              value:
                                                                                                campaign.id,
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-3 d-none d-md-block ml-auto",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "list-group list-group-alo list-group-flush sticky-top stick-top-with-header",
                                                },
                                                [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "list-group-item",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                            On This Page\n                                                        "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "list-group-item list-group-item-action",
                                                      attrs: {
                                                        href: "#name",
                                                        "data-testid":
                                                          "profile-settings-name",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.shine(
                                                            "name"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                            Name\n                                                        "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "list-group-item list-group-item-action",
                                                      attrs: {
                                                        href: "#description",
                                                        "data-testid":
                                                          "profile-settings-description",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.shine(
                                                            "description"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                            Description\n                                                        "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  !_vm.user.is_destination
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#email",
                                                            "data-testid":
                                                              "profile-settings-email",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "email"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Email\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasRole(
                                                    "Company Admin"
                                                  ) &&
                                                  !_vm.user.is_destination &&
                                                  (!_vm.user_id ||
                                                    _vm.auth.user.profile.id !==
                                                      _vm.user_id)
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#role",
                                                            "data-testid":
                                                              "profile-settings-role",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "role"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Role\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.canBeEdited
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#answer",
                                                            "data-testid":
                                                              "profile-settings-answer-type",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "answer"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Answer Type\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.canBeEdited &&
                                                  [
                                                    _vm.AnswerTypes.BY_BROWSER,
                                                    _vm.AnswerTypes.BY_IP_PHONE,
                                                  ].includes(_vm.user.answer_by)
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#backup-routing",
                                                            "data-testid":
                                                              "profile-settings-back-routing",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "backup-routing"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Backup routing\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.userDestinationEditable &&
                                                  (([
                                                    _vm.AnswerTypes.BY_BROWSER,
                                                    _vm.AnswerTypes.BY_IP_PHONE,
                                                  ].includes(
                                                    _vm.user.answer_by
                                                  ) &&
                                                    _vm.user
                                                      .phone_number_as_backup) ||
                                                    _vm.user.answer_by ===
                                                      _vm.AnswerTypes
                                                        .BY_PHONE_NUMBER)
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#phone-number",
                                                            "data-testid":
                                                              "profile-settings-phone-number",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "phone-number"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm.user.answer_by ===
                                                            _vm.AnswerTypes
                                                              .BY_BROWSER &&
                                                          !_vm.user
                                                            .read_only_access
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "Backup"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                                                            Phone number\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.canBeEdited &&
                                                  [
                                                    _vm.AnswerTypes
                                                      .BY_PHONE_NUMBER,
                                                    _vm.AnswerTypes.BY_IP_PHONE,
                                                  ].includes(_vm.user.answer_by)
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#respect_agent_status",
                                                            "data-testid":
                                                              "profile-settings-respect-agent-status",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "respect_agent_status"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Respect Agent Status\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.canBeEdited &&
                                                  _vm.isNotOwnSettings &&
                                                  _vm.user.role_name ===
                                                    "Company Agent"
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#answers-messages",
                                                            "data-testid":
                                                              "profile-settings-answers-messages",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "answers-messages"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Text message handling\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasRole(
                                                    "Company Admin"
                                                  ) &&
                                                  _vm.user.role_name ===
                                                    "Company Agent" &&
                                                  !_vm.user.is_destination
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#change-contact-ownership",
                                                            "data-testid":
                                                              "profile-settings-change-contact-ownership",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "change-contact-ownership"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Change contact ownership\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasRole(
                                                    "Company Admin"
                                                  ) &&
                                                  _vm.user.role_name ===
                                                    "Company Agent" &&
                                                  !_vm.user.is_destination
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#modify-contact-ring-groups",
                                                            "data-testid":
                                                              "profile-settings-modify-contact-ring-groups",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "modify-contact-ring-groups"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Modify contact ring groups\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasRole(
                                                    "Company Admin"
                                                  ) &&
                                                  _vm.user.role_name ===
                                                    "Company Agent" &&
                                                  !_vm.user.is_destination
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#barge-and-whisper-on-call",
                                                            "data-testid":
                                                              "profile-settings-barge-and-whisper-on-call",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "barge-and-whisper-on-call"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Call Barge and Whisper\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasRole(
                                                    "Company Admin"
                                                  ) &&
                                                  _vm.user.role_name ===
                                                    "Company Agent" &&
                                                  !_vm.user.is_destination
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#user-broadcast-access",
                                                            "data-testid":
                                                              "profile-settings-can-broadcast",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "user-broadcast-access"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Can Broadcast\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasRole(
                                                    "Company Admin"
                                                  ) &&
                                                  _vm.user.role_name ===
                                                    "Company Agent" &&
                                                  !_vm.user.is_destination
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#can_delete_contact",
                                                            "data-testid":
                                                              "profile-settings-delete-contact",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "can_delete_contact"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Delete Contact\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.connectedCampaigns
                                                    .length > 0
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#user-personal-line",
                                                            "data-testid":
                                                              "profile-settings-user-personal-line",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "user-personal-line"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            User's personal line\n                                                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.hasRole("Company Admin") &&
                                    !_vm.user.is_destination &&
                                    !_vm.auth.user.profile.is_reseller
                                      ? _c(
                                          "el-tab-pane",
                                          {
                                            staticClass: "py-2 px-3",
                                            attrs: {
                                              name: "visibility",
                                              label: "Visibility Settings",
                                              "data-testid":
                                                "visibility-settings-pane",
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-8" },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-label",
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "page-anchor",
                                                            attrs: {
                                                              id: "contacts_visibility",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "mb-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Contacts Visibility"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("small", [
                                                            _vm._v(
                                                              "Select the contacts visibility settings for this user"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          staticClass:
                                                            "no-border",
                                                          attrs: {
                                                            prop: "contacts_visibility",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticClass:
                                                                "w-50",
                                                              attrs: {
                                                                placeholder:
                                                                  "Select user's visibility",
                                                                "data-testid":
                                                                  "contact-visibility-selector",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.preValidateForm(
                                                                      "user_form"
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.user
                                                                    .contacts_visibility,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.user,
                                                                      "contacts_visibility",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "user.contacts_visibility",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.contactVisibilityOptions,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.value,
                                                                    staticClass:
                                                                      "p-0",
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      value:
                                                                        item.value,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "media",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "media-body",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "small",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.description
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.user
                                                            .contacts_visibility !==
                                                          _vm.ContactAccessTypes
                                                            .CONTACTS_ACCESS_EVERYONE
                                                            ? _c(
                                                                "el-form-item",
                                                                {
                                                                  staticClass:
                                                                    "no-border",
                                                                  attrs: {
                                                                    prop: "can_view_unassigned_contacts",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Can view unassigned contacts",
                                                                        "data-testid":
                                                                          "unassigned-contacts-checkbox",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .user
                                                                            .can_view_unassigned_contacts,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.user,
                                                                              "can_view_unassigned_contacts",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "user.can_view_unassigned_contacts",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "page-anchor",
                                                        attrs: {
                                                          id: "communication_visibility",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-label",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "mb-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Communications Visibility"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("small", [
                                                            _vm._v(
                                                              "Communication visibility settings for this user"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          staticClass:
                                                            "no-border",
                                                          attrs: {
                                                            prop: "communications_visibility",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticClass:
                                                                "w-50",
                                                              attrs: {
                                                                placeholder:
                                                                  "Select user's visibility",
                                                                "data-testid":
                                                                  "communications-visibility-selector",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.preValidateForm(
                                                                      "user_form"
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.user
                                                                    .communications_visibility,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.user,
                                                                      "communications_visibility",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "user.communications_visibility",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.communicationVisibilityOptions,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.value,
                                                                    staticClass:
                                                                      "p-0",
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      value:
                                                                        item.value,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "media",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "media-body",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.label
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "small",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.description
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass: "no-border",
                                                      attrs: {
                                                        prop: "line_access_limit",
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "page-anchor",
                                                        attrs: {
                                                          id: "line_visibility",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-label",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "mb-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Line Visibility Limit"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("small", [
                                                            _vm._v(
                                                              "\n                                                                By default, agents have access to all lines. Here, you can define exactly what lines\n                                                                they have access to.\n                                                            "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("el-checkbox", {
                                                        attrs: {
                                                          label:
                                                            "Limit user's line visibility",
                                                          "data-testid":
                                                            "line-access-limit-checkbox",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.toggleLineAccessLimit,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.user
                                                              .line_access_limit,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.user,
                                                              "line_access_limit",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "user.line_access_limit",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.user
                                                        .line_access_limit &&
                                                      _vm.hasRole(
                                                        "Company Admin"
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-12",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-transfer",
                                                                        {
                                                                          staticClass:
                                                                            "d-inline-block text-left",
                                                                          attrs:
                                                                            {
                                                                              titles:
                                                                                [
                                                                                  "Available",
                                                                                  "Current",
                                                                                ],
                                                                              format:
                                                                                {
                                                                                  noChecked:
                                                                                    "${total}",
                                                                                  hasChecked:
                                                                                    "${checked}/${total}",
                                                                                },
                                                                              data: _vm.campaignsData,
                                                                              filterable:
                                                                                "",
                                                                              "data-testid":
                                                                                "select-campaigs-transfer",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.preValidateForm(
                                                                                  "user_form"
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .user
                                                                                  .selected_campaign_ids,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.user,
                                                                                    "selected_campaign_ids",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "user.selected_campaign_ids",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-button",
                                                                            {
                                                                              staticClass:
                                                                                "transfer-footer mr-2 mt-1 pull-right",
                                                                              attrs:
                                                                                {
                                                                                  slot: "right-footer",
                                                                                  type: "danger",
                                                                                  size: "mini",
                                                                                  "data-testid":
                                                                                    "clear-campaings-button",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  _vm.clearSelectedCampaigns,
                                                                              },
                                                                              slot: "right-footer",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                            Clear\n                                                                        "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "mt-2 pointer text-primary",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: "/account?tab=settings",
                                                                "data-testid":
                                                                  "restric-agents-account-settigs-link",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                Do you want to restrict all agents' line? Go to account settings\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.user.line_access_limit
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column",
                                                        },
                                                        [
                                                          _c("el-checkbox", {
                                                            staticClass: "mt-2",
                                                            attrs: {
                                                              label:
                                                                "Apply visibility limit to communications",
                                                              "data-testid":
                                                                "line-communication-limit-checkbox",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.preValidateForm(
                                                                  "user_form"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.user
                                                                  .line_communication_limit,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.user,
                                                                    "line_communication_limit",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "user.line_communication_limit",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("el-checkbox", {
                                                            staticClass: "mt-2",
                                                            attrs: {
                                                              label:
                                                                "Apply visibility limit to contacts",
                                                              "data-testid":
                                                                "line-contact-limit-checkbox",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.preValidateForm(
                                                                  "user_form"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.user
                                                                  .line_contact_limit,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.user,
                                                                    "line_contact_limit",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "user.line_contact_limit",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.user.role_name &&
                                                  _vm.user.role_name !==
                                                    "Company Admin"
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          staticClass: "mt-2",
                                                          attrs: {
                                                            prop: "read_only_access",
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "page-anchor",
                                                            attrs: {
                                                              id: "read_only_access",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "mb-0",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Reporter Access"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "Users with reporter access can only see the reports and they can not interact with the contacts or make calls."
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("el-checkbox", {
                                                            attrs: {
                                                              label:
                                                                "Make this user a reporter",
                                                              "data-testid":
                                                                "make-user-reporter-checkbox",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.user
                                                                  .read_only_access,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.user,
                                                                    "read_only_access",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "user.read_only_access",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.user.role_name &&
                                                  _vm.user.read_only_access &&
                                                  _vm.hasRole("Company Admin")
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          staticClass:
                                                            "no-border",
                                                          attrs: {
                                                            prop: "user_access_limit",
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "page-anchor",
                                                            attrs: {
                                                              id: "user_access_limit",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "mb-0",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "User Access Limit"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "Limit which users this user has reporting access to."
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("el-checkbox", {
                                                            attrs: {
                                                              label:
                                                                "Limit visibility of other users",
                                                              "data-testid":
                                                                "limit-other-users-visibility-checkbox",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.preValidateForm(
                                                                  "user_form"
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.user
                                                                  .user_access_limit,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.user,
                                                                    "user_access_limit",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "user.user_access_limit",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _vm.user
                                                            .user_access_limit
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "row",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-12",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-transfer",
                                                                            {
                                                                              staticClass:
                                                                                "d-inline-block text-left",
                                                                              attrs:
                                                                                {
                                                                                  titles:
                                                                                    [
                                                                                      "Available",
                                                                                      "Current",
                                                                                    ],
                                                                                  format:
                                                                                    {
                                                                                      noChecked:
                                                                                        "${total}",
                                                                                      hasChecked:
                                                                                        "${checked}/${total}",
                                                                                    },
                                                                                  data: _vm.usersData,
                                                                                  filterable:
                                                                                    "",
                                                                                  "data-testid":
                                                                                    "select-users-transfer",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.preValidateForm(
                                                                                      "user_form"
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .user
                                                                                      .selected_user_ids,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.user,
                                                                                        "selected_user_ids",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "user.selected_user_ids",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  staticClass:
                                                                                    "transfer-footer mr-2 mt-1 pull-right",
                                                                                  attrs:
                                                                                    {
                                                                                      slot: "right-footer",
                                                                                      type: "danger",
                                                                                      size: "mini",
                                                                                      "data-testid":
                                                                                        "clear-selected-users-transfer",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      _vm.clearSelectedUsers,
                                                                                  },
                                                                                  slot: "right-footer",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                            Clear\n                                                                        "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-3 d-none d-md-block ml-auto",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "list-group list-group-alo list-group-flush sticky-top stick-top-with-header",
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "list-group-item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            On This Page\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#contacts_visibility",
                                                            "data-testid":
                                                              "contacts-visibility-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "contacts_visibility"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Contacts\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#communication_visibility",
                                                            "data-testid":
                                                              "communication-visibility-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "communication_visibility"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Communications\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#line_visibility",
                                                            "data-testid":
                                                              "line-visibility-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "line_visibility"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Line Visibility Limit\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#read_only_access",
                                                            "data-testid":
                                                              "reporter-access-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "read_only_access"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Reporter Access\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.user.role_name &&
                                                      _vm.user
                                                        .read_only_access &&
                                                      _vm.hasRole(
                                                        "Company Admin"
                                                      )
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "list-group-item list-group-item-action",
                                                              attrs: {
                                                                href: "#user_access_limit",
                                                                "data-testid":
                                                                  "user-access-link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.shine(
                                                                      "user_access_limit"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            User Access Limit\n                                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.canBeEdited &&
                                    !_vm.auth.user.profile.is_reseller
                                      ? _c(
                                          "el-tab-pane",
                                          {
                                            staticClass: "p-2",
                                            attrs: {
                                              "data-testid":
                                                "personalization-pane",
                                              name: "personalization",
                                              label: "Personalization",
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-6" },
                                                [
                                                  !_vm.auth.user.profile.company
                                                    .force_talk
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "focus_mode",
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "page-anchor",
                                                            attrs: {
                                                              id: "focus-mode",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  "Focus Mode"
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "Only show me contacts that are assigned to me."
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "switch-label",
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  disabled:
                                                                    _vm.current_company &&
                                                                    _vm
                                                                      .current_company
                                                                      .id ===
                                                                      522 &&
                                                                    _vm.env ===
                                                                      "production",
                                                                  "data-testid":
                                                                    "focus-mode-switch",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.preValidateForm(
                                                                        "user_form"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.user
                                                                      .focus_mode,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.user,
                                                                        "focus_mode",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "user.focus_mode",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Enable focus mode"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.current_company &&
                                                  _vm.current_company
                                                    .transcription_enabled
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "transcription",
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "page-anchor",
                                                            attrs: {
                                                              id: "transcription",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  "Smart Transcription"
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "Activate smart transcription on voice calls."
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "switch-label",
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  "data-testid":
                                                                    "smart-transcription-switch",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.preValidateForm(
                                                                        "user_form"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.user
                                                                      .transcription_enabled,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.user,
                                                                        "transcription_enabled",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "user.transcription_enabled",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Enable smart transcription"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.current_company &&
                                                  !_vm.current_company
                                                    .is_whitelabel &&
                                                  _vm.current_company
                                                    .url_shortener_enabled
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "url_shortener",
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "page-anchor",
                                                            attrs: {
                                                              id: "url_shortener",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  "URL Shortener"
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "Enable URL Shortener for SMS/MMS forms."
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "switch-label",
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  "data-testid":
                                                                    "url-shortener-switch",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.preValidateForm(
                                                                        "user_form"
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.user
                                                                      .url_shortener_enabled,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.user,
                                                                        "url_shortener_enabled",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "user.url_shortener_enabled",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Enable URL Shortener"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      class:
                                                        _vm.isCompanyForcedAvailable
                                                          ? "pl-3 border-left "
                                                          : "",
                                                      attrs: {
                                                        prop: "go_to_available_after_login",
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "page-anchor",
                                                        attrs: {
                                                          id: "go_to_available_after_login",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.isCompanyForcedAvailable
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "cursor-not-allowed width-fit-content",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "badge badge-warning py-1 mb-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                                Set by Admin in Account Settings\n                                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "p-2 px-3 border border-warning",
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        placement:
                                                                          "top",
                                                                        effect:
                                                                          "dark",
                                                                        content:
                                                                          "Requires Admin to change in Account Setting",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-label",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h5",
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                                Force Users to Always Available\n                                                                                "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-popover",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          placement:
                                                                                            "right",
                                                                                          width:
                                                                                            "450",
                                                                                          trigger:
                                                                                            "hover",
                                                                                          "data-testid":
                                                                                            "set-by-admin-popover",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "d-flex font-weight-bold",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Turning on 'Force Users to Always Available' Setting:"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "ul",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mb-0",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "li",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Users' statuses are set to 'Available' even after they login"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "li",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Users cannot manually change their status"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "li",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "User's application will receive inbound calls until they logout"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "li",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Admins can still manually change agent's statuses in Wallboard"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "i",
                                                                                        {
                                                                                          staticClass:
                                                                                            "fa fa-info-circle text-primary ml-2",
                                                                                          attrs:
                                                                                            {
                                                                                              slot: "reference",
                                                                                            },
                                                                                          slot: "reference",
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "small",
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Set all users' statuses to "
                                                                                      ),
                                                                                      _c(
                                                                                        "b",
                                                                                        {
                                                                                          staticClass:
                                                                                            "pl-1",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Available"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Remove users' ability to manually change their status to "
                                                                                      ),
                                                                                      _c(
                                                                                        "b",
                                                                                        {
                                                                                          staticClass:
                                                                                            "pl-1",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Busy / Break"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Admins can still manually change agents' statuses in Wallboard"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.hasRole([
                                                                "Company Admin",
                                                              ])
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "mbt-2 mb-3",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "text-blue-14",
                                                                          attrs:
                                                                            {
                                                                              href: "/account?tab=calling-settings#force_users_always_available",
                                                                              target:
                                                                                "_blank",
                                                                              "data-testid":
                                                                                "set-by-admin-modify-link",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "u",
                                                                            [
                                                                              _vm._v(
                                                                                "Modify"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top",
                                                            effect: "dark",
                                                            content:
                                                              "Disabled - Requires Admin to change Account Setting",
                                                            disabled:
                                                              !_vm.isCompanyForcedAvailable,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "width-fit-content",
                                                              class:
                                                                _vm.isCompanyForcedAvailable
                                                                  ? "opacity cursor-not-allowed"
                                                                  : "",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-label",
                                                                },
                                                                [
                                                                  _c(
                                                                    "h5",
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                                        Available by Default, But Allow Manual Changes\n                                                                        "
                                                                      ),
                                                                      _c(
                                                                        "el-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              placement:
                                                                                "right",
                                                                              width:
                                                                                "450",
                                                                              trigger:
                                                                                "hover",
                                                                              "data-testid":
                                                                                "available-by-default-popover",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex font-weight-bold",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Turning on 'Available by Default, But Allow Manual Changes' Setting"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "ul",
                                                                                {
                                                                                  staticClass:
                                                                                    "mb-0",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "li",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Agent status is set to 'Available' even after they login"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "li",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                                        However, Agent can still manually set themselves to\n                                                                                        "
                                                                                      ),
                                                                                      _c(
                                                                                        "ul",
                                                                                        [
                                                                                          _c(
                                                                                            "li",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Offline"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "li",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "On Break"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "li",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Busy"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-info-circle text-primary ml-2",
                                                                              attrs:
                                                                                {
                                                                                  slot: "reference",
                                                                                },
                                                                              slot: "reference",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("small", [
                                                                    _vm._v(
                                                                      "Put user on available status after login and disable idle mode detection (auto offline)."
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "switch-label",
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-switch",
                                                                    {
                                                                      attrs: {
                                                                        "active-color":
                                                                          "#00a344",
                                                                        disabled:
                                                                          _vm.isCompanyForcedAvailable,
                                                                        "data-testid":
                                                                          "enable-by-default-switch",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.preValidateForm(
                                                                              "user_form"
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .user
                                                                            .go_to_available_after_login,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.user,
                                                                              "go_to_available_after_login",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "user.go_to_available_after_login",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("label", [
                                                                    _vm._v(
                                                                      "Enable available by default, but allow manual changes"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _vm.isCompanyForcedAvailable
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "badge badge-warning py-1",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                Forced at account level\n                                                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c("div", [
                                                                _vm.hasRole(
                                                                  "Company Agent"
                                                                )
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "border-bottom",
                                                                        attrs: {
                                                                          href: "#go_to_available_after_login",
                                                                          "data-testid":
                                                                            "has-role-account-settings-link",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                        See related account settings\n                                                                    "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "border-bottom border-primary text-primary",
                                                                        attrs: {
                                                                          href: "/account?tab=calling-settings#force_users_always_available",
                                                                          "data-testid":
                                                                            "has-not-role-account-settings-link",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                        See related account settings\n                                                                    "
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass: "no-border",
                                                      attrs: {
                                                        prop: "wrap_up_seconds",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-label",
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "page-anchor",
                                                            attrs: {
                                                              id: "wrap_up_seconds",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("h5", [
                                                            _vm._v(
                                                              "Wrap up duration"
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("small", [
                                                            _vm._v(
                                                              "Stay on wrap up for this amount of time before you go back to available for the next call."
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "width-600",
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticClass:
                                                                "width-300",
                                                              attrs: {
                                                                placeholder:
                                                                  "Select wrap up seconds",
                                                                disabled:
                                                                  _vm.isViewOnlyAccess() ||
                                                                  (_vm.current_company &&
                                                                    _vm
                                                                      .current_company
                                                                      .force_wrap_up),
                                                                "data-testid":
                                                                  "wrap-up-duration-selector",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.user
                                                                    .wrap_up_seconds,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.user,
                                                                      "wrap_up_seconds",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "user.wrap_up_seconds",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.wrap_up_seconds,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.value,
                                                                    attrs: {
                                                                      label:
                                                                        item.label,
                                                                      value:
                                                                        item.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.current_company &&
                                                          _vm.current_company
                                                            .force_wrap_up
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "badge badge-warning py-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                                Forced at account level\n                                                            "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-3 d-none d-md-block ml-auto",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "list-group list-group-alo list-group-flush sticky-top stick-top-with-header",
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "list-group-item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            On This Page\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#focus-mode",
                                                            "data-testid":
                                                              "personalization-focus-mode-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "focus-mode"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Focus mode\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.current_company &&
                                                      _vm.current_company
                                                        .transcription_enabled
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "list-group-item list-group-item-action",
                                                              attrs: {
                                                                href: "#transcription",
                                                                "data-testid":
                                                                  "personalization-smart-transcription-link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.shine(
                                                                      "transcription"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            Smart Transcription\n                                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.current_company &&
                                                      !_vm.current_company
                                                        .is_whitelabel &&
                                                      _vm.current_company
                                                        .url_shortener_enabled
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "list-group-item list-group-item-action",
                                                              attrs: {
                                                                href: "#url_shortener",
                                                                "data-testid":
                                                                  "personalization-url-shortener-link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.shine(
                                                                      "url_shortener"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            URL Shortener\n                                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#go_to_available_after_login",
                                                            "data-testid":
                                                              "personalization-available-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "go_to_available_after_login"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Available by Default, But Allow Manual Changes\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#wrap_up_seconds",
                                                            "data-testid":
                                                              "personalization-wrap-up-duration-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "wrap_up_seconds"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Wrap up duration\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.user_id &&
                                    _vm.canBeEdited &&
                                    !_vm.auth.user.profile.is_reseller
                                      ? _c(
                                          "el-tab-pane",
                                          {
                                            staticClass: "p-2",
                                            attrs: {
                                              name: "inbound-call-settings",
                                              "data-testid":
                                                "inbound-call-settings-pane",
                                              label: "Inbound Call Settings",
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-8" },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      !_vm.hasRole([
                                                        "Company Admin",
                                                        "Company Agent",
                                                      ])
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "alert alert-warning alert-aloware d-inline-block",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-exclamation-triangle",
                                                              }),
                                                              _vm._v(
                                                                " Contact your admin to change these settings.\n                                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-form-item",
                                                        { staticClass: "row" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "page-anchor",
                                                                    attrs: {
                                                                      id: "extension",
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-label",
                                                                    },
                                                                    [
                                                                      _c("h5", [
                                                                        _vm._v(
                                                                          "Extension"
                                                                        ),
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "small",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                            An extension is a unique company-wide\n                                                                            identifier\n                                                                            for a\n                                                                            user. You can select an extension here and\n                                                                            setup your line's IVR to listen for\n                                                                            extensions.\n                                                                            When a\n                                                                            caller enters an extension we will\n                                                                            automatically\n                                                                            connect the call to the respective user.\n                                                                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "user-extension-select",
                                                                {
                                                                  attrs: {
                                                                    options:
                                                                      _vm.options,
                                                                    "is-view-only":
                                                                      _vm.isViewOnlyAccess(),
                                                                    disabled:
                                                                      !_vm.hasRole(
                                                                        [
                                                                          "Company Admin",
                                                                        ]
                                                                      ) ||
                                                                      _vm.isViewOnlyAccess(),
                                                                  },
                                                                  on: {
                                                                    "filter-extensions":
                                                                      _vm.filterExtensions,
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.preValidateForm(
                                                                          "user_form"
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.user
                                                                        .extension,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.user,
                                                                          "extension",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "user.extension",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasRole(
                                                        [
                                                          "Company Admin",
                                                          "Company Agent",
                                                        ],
                                                        _vm.user.role_names
                                                      )
                                                        ? _c("el-form-item", [
                                                            _c("span", {
                                                              staticClass:
                                                                "page-anchor",
                                                              attrs: {
                                                                id: "scheduling",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-label",
                                                              },
                                                              [
                                                                _c(
                                                                  "h5",
                                                                  {
                                                                    staticClass:
                                                                      "mt-3",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Working Hours"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("small", [
                                                                  _vm._v(
                                                                    "\n                                                                    This setting sets the working hours for\n                                                                    this user. If a call comes in outside of these hours\n                                                                    it won't ring this user. Note that the times are\n                                                                    local to "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "your company"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    " timezone, currently set\n                                                                    to\n                                                                    "
                                                                  ),
                                                                  _vm.current_company
                                                                    ? _c("b", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .current_company
                                                                              .timezone
                                                                          )
                                                                        ),
                                                                      ])
                                                                    : _vm._e(),
                                                                ]),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group",
                                                              },
                                                              [
                                                                !_vm.loading_operating_hours &&
                                                                _vm.user
                                                                  .operating_hours
                                                                  ? _c(
                                                                      "business-hours",
                                                                      {
                                                                        attrs: {
                                                                          type: "select",
                                                                          color:
                                                                            "#00BF50",
                                                                          days: _vm
                                                                            .user
                                                                            .operating_hours,
                                                                          "time-increment":
                                                                            _vm.timeIncrement,
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      !_vm.user
                                                        .is_destination &&
                                                      !_vm.auth.user.profile
                                                        .is_reseller
                                                        ? _c("el-form-item", [
                                                            _c("span", {
                                                              staticClass:
                                                                "page-anchor",
                                                              attrs: {
                                                                id: "personal-voicemail",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-label",
                                                              },
                                                              [
                                                                _c(
                                                                  "h5",
                                                                  {
                                                                    staticClass:
                                                                      "mt-3",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                    Personal Voicemail\n                                                                "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("small", [
                                                                  _vm._v(
                                                                    "\n                                                                    Should " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .statics
                                                                          .name
                                                                      ) +
                                                                      " take a voicemail when this\n                                                                    user is not\n                                                                    available and their extension is dialed?\n                                                                "
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row mt-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-12",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-12",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-form-item",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-border",
                                                                                    attrs:
                                                                                      {
                                                                                        prop: "missed_call_handling_mode",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "form-label",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "When a direct call is missed:"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "el-radio-group",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            "data-testid":
                                                                                              "direct-call-missed-radio",
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.preValidateForm(
                                                                                                "user_form"
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm
                                                                                                .user
                                                                                                .missed_calls_settings
                                                                                                .missed_call_handling_mode,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm
                                                                                                    .user
                                                                                                    .missed_calls_settings,
                                                                                                  "missed_call_handling_mode",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                            expression:
                                                                                              "user.missed_calls_settings.missed_call_handling_mode",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "el-radio",
                                                                                          {
                                                                                            staticClass:
                                                                                              "_600",
                                                                                            attrs:
                                                                                              {
                                                                                                label:
                                                                                                  _vm
                                                                                                    .MissedCallBehaviors
                                                                                                    .MISSED_CALL_BEHAVIOR_NOTHING,
                                                                                                border:
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                                            Do Nothing\n                                                                                        "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "el-radio",
                                                                                          {
                                                                                            staticClass:
                                                                                              "_600",
                                                                                            attrs:
                                                                                              {
                                                                                                label:
                                                                                                  _vm
                                                                                                    .MissedCallBehaviors
                                                                                                    .MISSED_CALL_BEHAVIOR_VOICEMAIL,
                                                                                                border:
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                                            Voicemail\n                                                                                        "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .user
                                                                                  .missed_calls_settings
                                                                                  .missed_call_handling_mode ===
                                                                                _vm
                                                                                  .MissedCallBehaviors
                                                                                  .MISSED_CALL_BEHAVIOR_VOICEMAIL
                                                                                  ? _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "pt-3",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "b-b nav-active-greenish",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "ul",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "nav nav-tabs",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "li",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "nav-item",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "a",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "border rounded-top nav-link active",
                                                                                                        attrs:
                                                                                                          {
                                                                                                            href: "#",
                                                                                                            "data-toggle":
                                                                                                              "tab",
                                                                                                            "data-target":
                                                                                                              "#voicemail-greeting-file",
                                                                                                            "aria-expanded":
                                                                                                              "false",
                                                                                                            "data-testid":
                                                                                                              "play-recording-link",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "i",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "fa fa-play text-md",
                                                                                                          }
                                                                                                        ),
                                                                                                        _vm._v(
                                                                                                          "\n                                                                                                    Play Recording\n                                                                                                "
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "tab-content pt-2 border border-top-0",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "my-3 mx-3",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "mx-3 tab-pane animated fadeIn _400 active",
                                                                                                    attrs:
                                                                                                      {
                                                                                                        id: "voicemail-greeting-file",
                                                                                                        "aria-expanded":
                                                                                                          "true",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      [
                                                                                                        _vm
                                                                                                          .user
                                                                                                          .missed_calls_settings
                                                                                                          .voicemail_file ===
                                                                                                        null
                                                                                                          ? _c(
                                                                                                              "audio-recorder",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "audio-recorder-wrapper",
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    action:
                                                                                                                      "/api/v1/user/" +
                                                                                                                      _vm
                                                                                                                        .user
                                                                                                                        .id +
                                                                                                                      "/missed-call-voicemail",
                                                                                                                    upload_type:
                                                                                                                      "voicemail_file",
                                                                                                                    "data-testid":
                                                                                                                      "audio-recored-action",
                                                                                                                  },
                                                                                                                on: {
                                                                                                                  applyUploadedAudio:
                                                                                                                    _vm.applyUploadedAudio,
                                                                                                                },
                                                                                                              }
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                        _vm._v(
                                                                                                          " "
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "div",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "audio-uploader-wrapper",
                                                                                                          },
                                                                                                          [
                                                                                                            _vm
                                                                                                              .user
                                                                                                              .missed_calls_settings
                                                                                                              .voicemail_file ===
                                                                                                            null
                                                                                                              ? _c(
                                                                                                                  "el-upload",
                                                                                                                  {
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        drag: "",
                                                                                                                        action:
                                                                                                                          "/api/v1/user/" +
                                                                                                                          _vm
                                                                                                                            .user
                                                                                                                            .id +
                                                                                                                          "/missed-call-voicemail",
                                                                                                                        headers:
                                                                                                                          _vm.headers,
                                                                                                                        "on-success":
                                                                                                                          _vm.onSuccessUploadMissedCallVoicemail,
                                                                                                                        "on-error":
                                                                                                                          _vm.onFailedUploadMissedCallVoicemail,
                                                                                                                        "on-progress":
                                                                                                                          _vm.progressUploadMissedCallVoicemail,
                                                                                                                        "before-upload":
                                                                                                                          _vm.beforeUploadMissedCallVoicemail,
                                                                                                                        "file-list":
                                                                                                                          _vm
                                                                                                                            .uploadFileList
                                                                                                                            .missed_call_voicemail,
                                                                                                                        "data-testid":
                                                                                                                          "audio-upload-wrapper",
                                                                                                                      },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "i",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "el-icon-upload",
                                                                                                                      }
                                                                                                                    ),
                                                                                                                    _vm._v(
                                                                                                                      " "
                                                                                                                    ),
                                                                                                                    _c(
                                                                                                                      "div",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "el-upload__text",
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "\n                                                                                                                Drop\n                                                                                                                file\n                                                                                                                here\n                                                                                                                or\n                                                                                                                "
                                                                                                                        ),
                                                                                                                        _c(
                                                                                                                          "em",
                                                                                                                          [
                                                                                                                            _vm._v(
                                                                                                                              "click\n                                                                                                                    to\n                                                                                                                    upload"
                                                                                                                            ),
                                                                                                                          ]
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                    _vm._v(
                                                                                                                      " "
                                                                                                                    ),
                                                                                                                    _c(
                                                                                                                      "div",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "el-upload__tip",
                                                                                                                        attrs:
                                                                                                                          {
                                                                                                                            slot: "tip",
                                                                                                                          },
                                                                                                                        slot: "tip",
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "\n                                                                                                                MP3/WAV\n                                                                                                                file\n                                                                                                                (less\n                                                                                                                than\n                                                                                                                8MB)\n                                                                                                            "
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                            _vm._v(
                                                                                                              " "
                                                                                                            ),
                                                                                                            _vm
                                                                                                              .user
                                                                                                              .missed_calls_settings
                                                                                                              .voicemail_file ===
                                                                                                            null
                                                                                                              ? _c(
                                                                                                                  "el-progress",
                                                                                                                  {
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        "text-inside": true,
                                                                                                                        "stroke-width": 18,
                                                                                                                        percentage:
                                                                                                                          _vm
                                                                                                                            .uploadPercentage
                                                                                                                            .missed_call_voicemail,
                                                                                                                        status:
                                                                                                                          _vm
                                                                                                                            .uploadStatus
                                                                                                                            .missed_call_voicemail,
                                                                                                                      },
                                                                                                                  }
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                            _vm._v(
                                                                                                              " "
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "d-flex justify-content-start align-items-center",
                                                                                                              },
                                                                                                              [
                                                                                                                _vm
                                                                                                                  .user
                                                                                                                  .missed_calls_settings
                                                                                                                  .voicemail_file !==
                                                                                                                null
                                                                                                                  ? _c(
                                                                                                                      "audio",
                                                                                                                      {
                                                                                                                        attrs:
                                                                                                                          {
                                                                                                                            controls:
                                                                                                                              "",
                                                                                                                            "data-testid":
                                                                                                                              "audio-component",
                                                                                                                          },
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "source",
                                                                                                                          {
                                                                                                                            attrs:
                                                                                                                              {
                                                                                                                                src:
                                                                                                                                  "/static/uploaded_file/" +
                                                                                                                                  _vm
                                                                                                                                    .user
                                                                                                                                    .missed_calls_settings
                                                                                                                                    .voicemail_file,
                                                                                                                              },
                                                                                                                          }
                                                                                                                        ),
                                                                                                                        _vm._v(
                                                                                                                          "\n                                                                                                                Your\n                                                                                                                browser\n                                                                                                                does\n                                                                                                                not\n                                                                                                                support\n                                                                                                                the\n                                                                                                                audio\n                                                                                                                element.\n                                                                                                            "
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  : _vm._e(),
                                                                                                                _vm._v(
                                                                                                                  " "
                                                                                                                ),
                                                                                                                _vm
                                                                                                                  .user
                                                                                                                  .missed_calls_settings
                                                                                                                  .voicemail_file !==
                                                                                                                null
                                                                                                                  ? _c(
                                                                                                                      "button",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "btn btn-sm btn-danger ml-3",
                                                                                                                        attrs:
                                                                                                                          {
                                                                                                                            "data-testid":
                                                                                                                              "remove-file-button",
                                                                                                                          },
                                                                                                                        on: {
                                                                                                                          click:
                                                                                                                            function (
                                                                                                                              $event
                                                                                                                            ) {
                                                                                                                              $event.preventDefault()
                                                                                                                              return _vm.deleteMissedCallVoicemailFile.apply(
                                                                                                                                null,
                                                                                                                                arguments
                                                                                                                              )
                                                                                                                            },
                                                                                                                        },
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "i",
                                                                                                                          {
                                                                                                                            directives:
                                                                                                                              [
                                                                                                                                {
                                                                                                                                  name: "show",
                                                                                                                                  rawName:
                                                                                                                                    "v-show",
                                                                                                                                  value:
                                                                                                                                    _vm.loading_delete_missed_call_voicemail,
                                                                                                                                  expression:
                                                                                                                                    "loading_delete_missed_call_voicemail",
                                                                                                                                },
                                                                                                                              ],
                                                                                                                            staticClass:
                                                                                                                              "fa fa-spin fa-spinner",
                                                                                                                          }
                                                                                                                        ),
                                                                                                                        _vm._v(
                                                                                                                          " "
                                                                                                                        ),
                                                                                                                        _c(
                                                                                                                          "i",
                                                                                                                          {
                                                                                                                            directives:
                                                                                                                              [
                                                                                                                                {
                                                                                                                                  name: "show",
                                                                                                                                  rawName:
                                                                                                                                    "v-show",
                                                                                                                                  value:
                                                                                                                                    !_vm.loading_delete_missed_call_voicemail,
                                                                                                                                  expression:
                                                                                                                                    "!loading_delete_missed_call_voicemail",
                                                                                                                                },
                                                                                                                              ],
                                                                                                                            staticClass:
                                                                                                                              "fa fa-trash",
                                                                                                                          }
                                                                                                                        ),
                                                                                                                        _vm._v(
                                                                                                                          " "
                                                                                                                        ),
                                                                                                                        _c(
                                                                                                                          "span",
                                                                                                                          [
                                                                                                                            _vm._v(
                                                                                                                              "Remove file"
                                                                                                                            ),
                                                                                                                          ]
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  : _vm._e(),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.hasRole(
                                                        "Company Admin"
                                                      )
                                                        ? _c("el-form-item", [
                                                            _c("span", {
                                                              staticClass:
                                                                "page-anchor",
                                                              attrs: {
                                                                id: "operating-states",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-label",
                                                              },
                                                              [
                                                                _c("h5", [
                                                                  _vm._v(
                                                                    "Operating States"
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("small", [
                                                                  _vm._v(
                                                                    'Check all that apply. If you operate\n                                                                    nation-wide, click "Check All".'
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-12",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-12",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-form-item",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-border",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-checkbox",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            disabled:
                                                                                              _vm.isViewOnlyAccess() ||
                                                                                              !_vm.hasRole(
                                                                                                [
                                                                                                  "Company Admin",
                                                                                                  "Company Agent",
                                                                                                ]
                                                                                              ),
                                                                                            "data-testid":
                                                                                              "disable-geo-routing",
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm.disable_geo_routing,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.disable_geo_routing =
                                                                                                  $$v
                                                                                              },
                                                                                            expression:
                                                                                              "disable_geo_routing",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                                        Do not enable\n                                                                                        geo-routing for this user\n                                                                                    "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            !_vm.disable_geo_routing
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-12",
                                                                                    attrs:
                                                                                      {
                                                                                        prop: "operating_states_limit",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-form-item",
                                                                                      {
                                                                                        staticClass:
                                                                                          "no-border",
                                                                                        attrs:
                                                                                          {
                                                                                            label:
                                                                                              "United States",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "el-checkbox-group",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                "data-testid":
                                                                                                  "us-states-checkbox",
                                                                                              },
                                                                                            on: {
                                                                                              change:
                                                                                                _vm.handleCheckedUSChange,
                                                                                            },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .user
                                                                                                    .operating_states_limit
                                                                                                    .us,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      _vm
                                                                                                        .user
                                                                                                        .operating_states_limit,
                                                                                                      "us",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "user.operating_states_limit.us",
                                                                                              },
                                                                                          },
                                                                                          _vm._l(
                                                                                            _vm
                                                                                              .states
                                                                                              .us,
                                                                                            function (
                                                                                              us_state
                                                                                            ) {
                                                                                              return _c(
                                                                                                "el-checkbox",
                                                                                                {
                                                                                                  key: us_state,
                                                                                                  attrs:
                                                                                                    {
                                                                                                      label:
                                                                                                        us_state,
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      us_state
                                                                                                    ) +
                                                                                                      "\n                                                                                        "
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            }
                                                                                          ),
                                                                                          1
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "el-checkbox",
                                                                                          {
                                                                                            staticClass:
                                                                                              "pull-right _600",
                                                                                            attrs:
                                                                                              {
                                                                                                indeterminate:
                                                                                                  _vm.isUSIndeterminate,
                                                                                                "data-testid":
                                                                                                  "check-all-us-checkbox",
                                                                                              },
                                                                                            on: {
                                                                                              change:
                                                                                                _vm.handleCheckAllUSChange,
                                                                                            },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  _vm.checkAllUS,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.checkAllUS =
                                                                                                      $$v
                                                                                                  },
                                                                                                expression:
                                                                                                  "checkAllUS",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                                        Check all\n                                                                                    "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _vm
                                                                                      .auth
                                                                                      .user
                                                                                      .profile
                                                                                      .country ==
                                                                                    "CA"
                                                                                      ? _c(
                                                                                          "el-form-item",
                                                                                          {
                                                                                            staticClass:
                                                                                              "no-border",
                                                                                            attrs:
                                                                                              {
                                                                                                label:
                                                                                                  "Canada",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "el-checkbox-group",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    "data-testid":
                                                                                                      "states-ca-checkbox",
                                                                                                  },
                                                                                                on: {
                                                                                                  change:
                                                                                                    _vm.handleCheckedCAChange,
                                                                                                },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .user
                                                                                                        .operating_states_limit
                                                                                                        .ca,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          _vm
                                                                                                            .user
                                                                                                            .operating_states_limit,
                                                                                                          "ca",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                    expression:
                                                                                                      "user.operating_states_limit.ca",
                                                                                                  },
                                                                                              },
                                                                                              _vm._l(
                                                                                                _vm
                                                                                                  .states
                                                                                                  .ca,
                                                                                                function (
                                                                                                  ca_province
                                                                                                ) {
                                                                                                  return _c(
                                                                                                    "el-checkbox",
                                                                                                    {
                                                                                                      key: ca_province,
                                                                                                      attrs:
                                                                                                        {
                                                                                                          label:
                                                                                                            ca_province,
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                                                                            " +
                                                                                                          _vm._s(
                                                                                                            ca_province
                                                                                                          ) +
                                                                                                          "\n                                                                                        "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                }
                                                                                              ),
                                                                                              1
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "el-checkbox",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "pull-right _600",
                                                                                                attrs:
                                                                                                  {
                                                                                                    indeterminate:
                                                                                                      _vm.isCAIndeterminate,
                                                                                                    "data-testid":
                                                                                                      "check-all-ca-checkbox",
                                                                                                  },
                                                                                                on: {
                                                                                                  change:
                                                                                                    _vm.handleCheckAllCAChange,
                                                                                                },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      _vm.checkAllCA,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.checkAllCA =
                                                                                                          $$v
                                                                                                      },
                                                                                                    expression:
                                                                                                      "checkAllCA",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                                        Check all\n                                                                                    "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("el-form-item", [
                                                        _c("span", {
                                                          staticClass:
                                                            "page-anchor",
                                                          attrs: {
                                                            id: "operating-area-codes",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-label",
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                "Operating Area Codes (Beta)"
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("small", [
                                                              _vm._v(
                                                                "Customize the area codes that you operate on."
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-12",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-form-item",
                                                                              {
                                                                                staticClass:
                                                                                  "no-border",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-checkbox",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        disabled:
                                                                                          _vm.isViewOnlyAccess() ||
                                                                                          !_vm.hasRole(
                                                                                            [
                                                                                              "Company Admin",
                                                                                              "Company Agent",
                                                                                            ]
                                                                                          ),
                                                                                        "data-testid":
                                                                                          "disable-area-code-routing-checkbox",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm.disable_area_code_routing,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.disable_area_code_routing =
                                                                                              $$v
                                                                                          },
                                                                                        expression:
                                                                                          "disable_area_code_routing",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                        Do not enable area code routing for this user\n                                                                                    "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        !_vm.disable_area_code_routing
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-12",
                                                                                attrs:
                                                                                  {
                                                                                    prop: "area_codes_limit",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "us-area-code-selector",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        multiple: true,
                                                                                        "data-testid":
                                                                                          "user-area-codes-selector",
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        _vm.changeUserSelectedAreaCodes,
                                                                                    },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .user
                                                                                            .operating_area_codes_limit,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.user,
                                                                                              "operating_area_codes_limit",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "user.operating_area_codes_limit",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("el-form-item", [
                                                        _c("span", {
                                                          staticClass:
                                                            "page-anchor",
                                                          attrs: {
                                                            id: "follow-up",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-label",
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                "Follow Up"
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("small", [
                                                              _vm._v(
                                                                "\n                                                                    Send a message to the attempted agent who missed\n                                                                    a call.\n                                                                    The lead information (phone number) will be\n                                                                    automatically appended.\n                                                                "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-12",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-form-item",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-0 no-border",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-checkbox",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        disabled:
                                                                                          _vm.isViewOnlyAccess() ||
                                                                                          !_vm.hasRole(
                                                                                            [
                                                                                              "Company Admin",
                                                                                              "Company Agent",
                                                                                            ]
                                                                                          ),
                                                                                        "data-testid":
                                                                                          "check-message-if-missed-checkbox",
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        _vm.checkMessageIfMissed,
                                                                                    },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .user
                                                                                            .should_message_if_missed,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.user,
                                                                                              "should_message_if_missed",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "user.should_message_if_missed",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                        If the call is missed, send\n                                                                                        a text\n                                                                                        message to this agent.\n                                                                                    "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm
                                                                              .user
                                                                              .should_message_if_missed
                                                                              ? _c(
                                                                                  "el-form-item",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-border",
                                                                                    attrs:
                                                                                      {
                                                                                        prop: "missed_call_message",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "input-group",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "textarea",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .user
                                                                                                      .missed_call_message,
                                                                                                  expression:
                                                                                                    "user.missed_call_message",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "form-control pt-2 pb-2",
                                                                                            attrs:
                                                                                              {
                                                                                                type: "text",
                                                                                                rows: "2",
                                                                                                "data-testid":
                                                                                                  "missed-call-message-text-area",
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .user
                                                                                                    .missed_call_message,
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                [
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      _vm.user,
                                                                                                      "missed_call_message",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.preValidateForm(
                                                                                                      "user_form"
                                                                                                    )
                                                                                                  },
                                                                                                ],
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "variable-dialog"
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "template-list-dialog",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            text_button: true,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("el-form-item", [
                                                        _c("span", {
                                                          staticClass:
                                                            "page-anchor",
                                                          attrs: {
                                                            id: "caller-notification",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-label",
                                                          },
                                                          [
                                                            _c(
                                                              "h5",
                                                              {
                                                                staticClass:
                                                                  "mt-3",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Caller Notification"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("small", [
                                                              _vm._v(
                                                                "Notify caller with a text message"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-12",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-12",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-form-item",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-0 no-border",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-checkbox",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        disabled:
                                                                                          _vm.isViewOnlyAccess() ||
                                                                                          !_vm.hasRole(
                                                                                            [
                                                                                              "Company Admin",
                                                                                              "Company Agent",
                                                                                            ]
                                                                                          ),
                                                                                        "data-testid":
                                                                                          "send-message-to-the-caller-checkbox",
                                                                                      },
                                                                                    on: {
                                                                                      change:
                                                                                        _vm.checkMessageCallerIfCompleted,
                                                                                    },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .user
                                                                                            .should_message_caller_if_completed,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.user,
                                                                                              "should_message_caller_if_completed",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "user.should_message_caller_if_completed",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                        If the call is completed,\n                                                                                        send a\n                                                                                        text\n                                                                                        message\n                                                                                        to the caller.\n                                                                                    "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm
                                                                              .user
                                                                              .should_message_caller_if_completed
                                                                              ? _c(
                                                                                  "el-form-item",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-border",
                                                                                    attrs:
                                                                                      {
                                                                                        prop: "completed_call_message_caller",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "input-group",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "textarea",
                                                                                          {
                                                                                            directives:
                                                                                              [
                                                                                                {
                                                                                                  name: "model",
                                                                                                  rawName:
                                                                                                    "v-model",
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .user
                                                                                                      .completed_call_message_caller,
                                                                                                  expression:
                                                                                                    "user.completed_call_message_caller",
                                                                                                },
                                                                                              ],
                                                                                            staticClass:
                                                                                              "form-control pt-2 pb-2",
                                                                                            attrs:
                                                                                              {
                                                                                                type: "text",
                                                                                                rows: "2",
                                                                                                "data-testid":
                                                                                                  "completed-call-message-caller-text-area",
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .user
                                                                                                    .completed_call_message_caller,
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                [
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      _vm.user,
                                                                                                      "completed_call_message_caller",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.preValidateForm(
                                                                                                      "user_form"
                                                                                                    )
                                                                                                  },
                                                                                                ],
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "variable-dialog",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                has_agent: true,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "template-list-dialog",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            text_button: true,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.auth.user.profile
                                                        .lead_gen_tools_enabled
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              staticClass:
                                                                "no-border",
                                                            },
                                                            [
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "mt-3 mb-5",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Advanced Settings"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-12",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-group",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "row",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "col-12",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-form-item",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mt-5",
                                                                                      attrs:
                                                                                        {
                                                                                          prop: "daily_call_limit",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "page-anchor",
                                                                                          attrs:
                                                                                            {
                                                                                              id: "daily-call-limit",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "form-label",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "h5",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Daily Call Limit"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "small",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                                                                            The maximum number of\n                                                                                            *qualified* calls that\n                                                                                            this user may receive in\n                                                                                            a day.\n                                                                                            If you have not\n                                                                                            configured auto\n                                                                                            qualification, this will\n                                                                                            be total answered calls.\n                                                                                            It's best to set this to\n                                                                                            a high number, such as\n                                                                                            1000.\n                                                                                        "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "el-input-number",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              placeholder:
                                                                                                "1000",
                                                                                              min: 0,
                                                                                              disabled:
                                                                                                !_vm.hasRole(
                                                                                                  [
                                                                                                    "Company Admin",
                                                                                                    "Company Agent",
                                                                                                  ]
                                                                                                ),
                                                                                              "data-testid":
                                                                                                "maximun-number-of-calls-input-number",
                                                                                            },
                                                                                          on: {
                                                                                            change:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.preValidateForm(
                                                                                                  "user_form"
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .user
                                                                                                  .daily_call_limit,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm.user,
                                                                                                    "daily_call_limit",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "user.daily_call_limit",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-form-item",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mt-5 mb-0 no-border",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "page-anchor",
                                                                                          attrs:
                                                                                            {
                                                                                              id: "duplicate-call-prevention",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "form-label",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "h5",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Duplicate Call\n                                                                                            Prevention"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "small",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                                                                            Prevent the same lead\n                                                                                            from going to this user\n                                                                                            for a period of time.\n                                                                                            This setting is useful\n                                                                                            for lead sellers only\n                                                                                            and might conflict with\n                                                                                            your routing settings.\n                                                                                        "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "el-checkbox",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              disabled:
                                                                                                !_vm.hasRole(
                                                                                                  [
                                                                                                    "Company Admin",
                                                                                                    "Company Agent",
                                                                                                  ]
                                                                                                ),
                                                                                              "data-testid":
                                                                                                "duplicate-call-prevention-checkbox",
                                                                                            },
                                                                                          on: {
                                                                                            change:
                                                                                              _vm.checkDuplicateCallPrevention,
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm.dontDuplicateCallPrevention,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.dontDuplicateCallPrevention =
                                                                                                    $$v
                                                                                                },
                                                                                              expression:
                                                                                                "dontDuplicateCallPrevention",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                                        Don't prevent duplicate\n                                                                                        calls from a\n                                                                                        lead\n                                                                                    "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  !_vm.dontDuplicateCallPrevention
                                                                                    ? _c(
                                                                                        "el-form-item",
                                                                                        {
                                                                                          staticClass:
                                                                                            "no-border",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "el-select",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  placeholder:
                                                                                                    "Select a time period",
                                                                                                  "data-testid":
                                                                                                    "duplicated-call-prevention-options-selector",
                                                                                                },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .user
                                                                                                      .duplicate_call_prevention_period,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        _vm.user,
                                                                                                        "duplicate_call_prevention_period",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "user.duplicate_call_prevention_period",
                                                                                                },
                                                                                            },
                                                                                            _vm._l(
                                                                                              _vm.duplicateCallPreventionOptions,
                                                                                              function (
                                                                                                item
                                                                                              ) {
                                                                                                return _c(
                                                                                                  "el-option",
                                                                                                  {
                                                                                                    key: item.value,
                                                                                                    attrs:
                                                                                                      {
                                                                                                        label:
                                                                                                          item.label,
                                                                                                        value:
                                                                                                          item.value,
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              }
                                                                                            ),
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-3 d-none d-md-block ml-auto",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "list-group list-group-alo list-group-flush sticky-top stick-top-with-header",
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "list-group-item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            On This Page\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#extension",
                                                            "data-testid":
                                                              "inbound-call-settings-extension-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "extension"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Extension\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#scheduling",
                                                            "data-testid":
                                                              "inbound-call-settings-working-hours-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "scheduling"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Working Hours\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#personal-voicemail",
                                                            "data-testid":
                                                              "inbound-call-settings-personal-voicemail-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "personal-voicemail"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Personal Voicemail\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasRole(
                                                        "Company Admin"
                                                      )
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "list-group-item list-group-item-action",
                                                              attrs: {
                                                                href: "#operating-states",
                                                                "data-testid":
                                                                  "inbound-call-settings-operating-states-link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.shine(
                                                                      "operating-states"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            Operating States\n                                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#operating-area-codes",
                                                            "data-testid":
                                                              "inbound-call-settings-operating-area-codes-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "operating-area-codes"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Operating Area Codes\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#follow-up",
                                                            "data-testid":
                                                              "inbound-call-settings-follow-up-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "follow-up"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Follow Up\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#caller-notification",
                                                            "data-testid":
                                                              "inbound-call-settings-caller-notification-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "caller-notification"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Caller Notification\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.auth.user.profile
                                                        .lead_gen_tools_enabled
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "list-group-item list-group-item-action",
                                                              attrs: {
                                                                href: "#concurrent-call-limit",
                                                                "data-testid":
                                                                  "inbound-call-settings-concurrent-call-limit-link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.shine(
                                                                      "concurrent-call-limit"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            Concurrent Call Limit\n                                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.auth.user.profile
                                                        .lead_gen_tools_enabled
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "list-group-item list-group-item-action",
                                                              attrs: {
                                                                href: "#daily-call-limit",
                                                                "data-testid":
                                                                  "inbound-call-settings-daily-call-limit-link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.shine(
                                                                      "daily-call-limit"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            Daily Call Limit\n                                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.auth.user.profile
                                                        .lead_gen_tools_enabled
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "list-group-item list-group-item-action",
                                                              attrs: {
                                                                href: "#duplicate-call-prevention",
                                                                "data-testid":
                                                                  "inbound-call-settings-duplicate-call-prevention-link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.shine(
                                                                      "duplicate-call-prevention"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            Duplicate Call Prevention\n                                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.user_id &&
                                    _vm.canBeEdited &&
                                    !_vm.auth.user.profile.is_reseller
                                      ? _c(
                                          "el-tab-pane",
                                          {
                                            staticClass: "p-2",
                                            attrs: {
                                              name: "outbound-call-settings",
                                              "data-testid":
                                                "outbound-call-settings-pane",
                                              label: "Outbound Call Settings",
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-8" },
                                                    [
                                                      !_vm.hasRole([
                                                        "Company Admin",
                                                        "Company Agent",
                                                      ])
                                                        ? _c(
                                                            "h2",
                                                            {
                                                              staticClass:
                                                                "text-danger",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                            Contact your admin to change these settings.\n                                                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("el-form-item", [
                                                        _c("span", {
                                                          staticClass:
                                                            "page-anchor",
                                                          attrs: {
                                                            id: "default-outbound-line",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-label",
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                "Default Outbound Line"
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("small", [
                                                              _vm._v(
                                                                "Decide what line is used when this user makes\n                                                                    an outbound call."
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-12",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-form-item",
                                                                      {
                                                                        staticClass:
                                                                          "no-border",
                                                                        attrs: {
                                                                          label:
                                                                            "Outbound Line:",
                                                                          prop: "outbound_calling_mode",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-radio-group",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  _vm.isViewOnlyAccess() ||
                                                                                  !_vm.hasRole(
                                                                                    [
                                                                                      "Company Admin",
                                                                                      "Company Agent",
                                                                                    ]
                                                                                  ) ||
                                                                                  (_vm.current_company &&
                                                                                    _vm
                                                                                      .current_company
                                                                                      .force_outbound_line),
                                                                                "data-testid":
                                                                                  "outbond-line-radio-group",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .user
                                                                                    .outbound_calling_selector,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.user,
                                                                                      "outbound_calling_selector",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "user.outbound_calling_selector",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-popover",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    placement:
                                                                                      "bottom-start",
                                                                                    width:
                                                                                      "350",
                                                                                    trigger:
                                                                                      "hover",
                                                                                    content:
                                                                                      "Use account's default outbound line for this user's outbound calls.",
                                                                                    "data-testid":
                                                                                      "outbond-use-company-default-popover",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-radio",
                                                                                  {
                                                                                    staticClass:
                                                                                      "_600",
                                                                                    attrs:
                                                                                      {
                                                                                        slot: "reference",
                                                                                        label: 2,
                                                                                        border:
                                                                                          "",
                                                                                      },
                                                                                    slot: "reference",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                        Use Company Default\n                                                                                    "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-popover",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    placement:
                                                                                      "bottom-start",
                                                                                    width:
                                                                                      "350",
                                                                                    trigger:
                                                                                      "hover",
                                                                                    content:
                                                                                      "Choose a single outgoing line for this user.",
                                                                                    "data-testid":
                                                                                      "outbond-select-manually-popover",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-radio",
                                                                                  {
                                                                                    staticClass:
                                                                                      "_600",
                                                                                    attrs:
                                                                                      {
                                                                                        slot: "reference",
                                                                                        label: 1,
                                                                                        border:
                                                                                          "",
                                                                                      },
                                                                                    slot: "reference",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                        Select Manually\n                                                                                    "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-popover",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    placement:
                                                                                      "bottom-start",
                                                                                    width:
                                                                                      "350",
                                                                                    trigger:
                                                                                      "hover",
                                                                                    content:
                                                                                      "Always asks the user what line they want to use before making an outbound call.",
                                                                                    "data-testid":
                                                                                      "outbond-always-popover",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-radio",
                                                                                  {
                                                                                    staticClass:
                                                                                      "_600",
                                                                                    attrs:
                                                                                      {
                                                                                        slot: "reference",
                                                                                        label: 3,
                                                                                        border:
                                                                                          "",
                                                                                      },
                                                                                    slot: "reference",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                        Always Ask\n                                                                                    "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm.current_company &&
                                                                        _vm
                                                                          .current_company
                                                                          .force_outbound_line
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "badge badge-warning py-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                                Forced at account level\n                                                                            "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "el-form-item",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "show",
                                                                              rawName:
                                                                                "v-show",
                                                                              value:
                                                                                _vm
                                                                                  .user
                                                                                  .outbound_calling_selector ==
                                                                                1,
                                                                              expression:
                                                                                "user.outbound_calling_selector == 1",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "no-border",
                                                                        attrs: {
                                                                          label:
                                                                            "Outbound Calling",
                                                                          prop: "default_outbound_campaign_id",
                                                                          filterable:
                                                                            "",
                                                                          clearable:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "width-300",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "campaign-selector",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    class_prop:
                                                                                      "no-select width-300",
                                                                                    placeholder:
                                                                                      "Default line for outbound calls",
                                                                                    show_paused: false,
                                                                                    disabled:
                                                                                      _vm.isViewOnlyAccess() ||
                                                                                      !_vm.hasRole(
                                                                                        [
                                                                                          "Company Admin",
                                                                                          "Company Agent",
                                                                                        ]
                                                                                      ) ||
                                                                                      (_vm.current_company &&
                                                                                        _vm
                                                                                          .current_company
                                                                                          .force_outbound_line),
                                                                                    filterable:
                                                                                      "",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    _vm.changeDefaultOutboundCampaignId,
                                                                                  trigger_validation:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.preValidateForm(
                                                                                        "user_form"
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .user
                                                                                        .default_outbound_campaign_id,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.user,
                                                                                          "default_outbound_campaign_id",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "user.default_outbound_campaign_id",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-form-item",
                                                        { staticClass: "mb-3" },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "page-anchor",
                                                            attrs: {
                                                              id: "call-recording",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "mt-3",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Call Recordings"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "\n                                                                    Should outbound calls\n                                                                "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        {
                                                                          staticClass:
                                                                            "no-border",
                                                                          attrs:
                                                                            {
                                                                              prop: "outbound_call_recording_mode",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-radio-group",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  "data-testid":
                                                                                    "call-recordings-radio-group",
                                                                                  disabled:
                                                                                    _vm.isViewOnlyAccess() ||
                                                                                    !_vm.hasRole(
                                                                                      [
                                                                                        "Company Admin",
                                                                                        "Company Agent",
                                                                                      ]
                                                                                    ) ||
                                                                                    (_vm.current_company &&
                                                                                      _vm
                                                                                        .current_company
                                                                                        .force_outbound_recording),
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.preValidateForm(
                                                                                      "user_form"
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .user
                                                                                      .outbound_call_recording_mode,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.user,
                                                                                        "outbound_call_recording_mode",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "user.outbound_call_recording_mode",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-radio",
                                                                                {
                                                                                  staticClass:
                                                                                    "_600",
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        _vm
                                                                                          .OutboundCallRecordingModes
                                                                                          .OUTBOUND_CALL_RECORDING_MODE_DEFAULT,
                                                                                      border:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                                    Use Company Default\n                                                                                "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "el-radio",
                                                                                {
                                                                                  staticClass:
                                                                                    "_600",
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        _vm
                                                                                          .OutboundCallRecordingModes
                                                                                          .OUTBOUND_CALL_RECORDING_MODE_ALWAYS,
                                                                                      border:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                                    Always Record\n                                                                                "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "el-radio",
                                                                                {
                                                                                  staticClass:
                                                                                    "_600",
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        _vm
                                                                                          .OutboundCallRecordingModes
                                                                                          .OUTBOUND_CALL_RECORDING_MODE_NEVER,
                                                                                      border:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                                    Never Record\n                                                                                "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.current_company &&
                                                                          _vm
                                                                            .current_company
                                                                            .force_outbound_recording
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "badge badge-warning py-1",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                                Forced at account level\n                                                                            "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-form-item",
                                                        { staticClass: "mb-3" },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "page-anchor",
                                                            attrs: {
                                                              id: "two-legged-outbound-call",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "mt-3",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Two Legged Outbound Calls\n                                                                    (Beta)"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "\n                                                                    By enabling this option we will call your\n                                                                    contacts with your secondary phone number.\n                                                                "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        {
                                                                          staticClass:
                                                                            "no-border",
                                                                          attrs:
                                                                            {
                                                                              prop: "enabled_two_legged_outbound",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "switch-label",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-switch",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      "active-color":
                                                                                        "#00a344",
                                                                                      disabled:
                                                                                        _vm.isViewOnlyAccess() ||
                                                                                        !_vm.hasRole(
                                                                                          [
                                                                                            "Company Admin",
                                                                                            "Company Agent",
                                                                                          ]
                                                                                        ),
                                                                                      "data-testid":
                                                                                        "two-legged-outbond-calls-switch",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .user
                                                                                          .enabled_two_legged_outbound,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.user,
                                                                                            "enabled_two_legged_outbound",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "user.enabled_two_legged_outbound",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Enable Two Legged Outbound Calls"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              _vm
                                                                                .user
                                                                                .enabled_two_legged_outbound,
                                                                            expression:
                                                                              "user.enabled_two_legged_outbound",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "form-group no-border",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Secondary Phone Number",
                                                                              prop: "secondary_phone_number",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "row",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "col-md-6",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-input",
                                                                                    {
                                                                                      staticClass:
                                                                                        "w-100",
                                                                                      attrs:
                                                                                        {
                                                                                          type: "tel",
                                                                                          placeholder:
                                                                                            "(123) 456-7890",
                                                                                          disabled:
                                                                                            _vm.isViewOnlyAccess() ||
                                                                                            (_vm
                                                                                              .user
                                                                                              .role_name &&
                                                                                              _vm
                                                                                                .user
                                                                                                .read_only_access),
                                                                                          "data-testid":
                                                                                            "secondary-phone-number-input",
                                                                                        },
                                                                                      on: {
                                                                                        input:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.preValidateForm(
                                                                                              "user_form"
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .user
                                                                                              .secondary_phone_number,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.user,
                                                                                                "secondary_phone_number",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "user.secondary_phone_number",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          staticClass:
                                                            "mb-2 no-border",
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "page-anchor",
                                                            attrs: {
                                                              id: "voicemail-drop-library",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "mt-3",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Voicemail Drop Library"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "\n                                                                    Build you voicemail drop library here.\n                                                                    When using the PowerDialer or calling a lot of leads\n                                                                    manually,\n                                                                    you\n                                                                    can use voicemail drop to put a voicemail after the\n                                                                    beep without\n                                                                    being on the call.\n                                                                "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("user-vm-drop", {
                                                            attrs: {
                                                              user_id:
                                                                _vm.user.id,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-3 d-none d-md-block ml-auto",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "list-group list-group-alo list-group-flush sticky-top stick-top-with-header",
                                                        },
                                                        [
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "list-group-item",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                On This Page\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "list-group-item list-group-item-action",
                                                              attrs: {
                                                                href: "#default-outbound-line",
                                                                "data-testid":
                                                                  "default-outbond-line-link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.shine(
                                                                      "default-outbound-line"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                Default Outbound Line\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "list-group-item list-group-item-action",
                                                              attrs: {
                                                                href: "#call-recording",
                                                                "data-testid":
                                                                  "outbond-calls-recording-link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.shine(
                                                                      "call-recording"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                Call Recordings\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "list-group-item list-group-item-action",
                                                              attrs: {
                                                                href: "#two-legged-outbound-call",
                                                                "data-testid":
                                                                  "two-legged-outbound-calls-link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.shine(
                                                                      "two-legged-outbound-call"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                Two Legged Outbound Calls\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "list-group-item list-group-item-action",
                                                              attrs: {
                                                                href: "#voicemail-drop-library",
                                                                "data-testid":
                                                                  "outbond-voicemail-drop-library-link",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.shine(
                                                                      "voicemail-drop-library"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                Voicemail Drop Library\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.user_id &&
                                    _vm.canBeEdited &&
                                    !_vm.auth.user.profile.is_reseller
                                      ? _c(
                                          "el-tab-pane",
                                          {
                                            staticClass: "p-2",
                                            attrs: {
                                              name: "notification-settings",
                                              "data-testid":
                                                "notification-settings-pane",
                                              label: "Notification Settings",
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c("el-form-item", [
                                                    _c("span", {
                                                      staticClass:
                                                        "page-anchor",
                                                      attrs: {
                                                        id: "notification-channels",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-label",
                                                      },
                                                      [
                                                        _c("h5", [
                                                          _vm._v(
                                                            "Notification Channels"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("small", [
                                                          _vm._v(
                                                            "Choose what channels you want this user to get notified on."
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "switch-label mb-2",
                                                      },
                                                      [
                                                        _c("el-switch", {
                                                          attrs: {
                                                            "active-color":
                                                              "#00a344",
                                                            "data-testid":
                                                              "in-app-switch",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.checkNotificationsSettings,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.inapp_notifs,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.inapp_notifs =
                                                                $$v
                                                            },
                                                            expression:
                                                              "inapp_notifs",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("label", [
                                                          _vm._v("In-App"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "switch-label mb-2",
                                                      },
                                                      [
                                                        _c("el-switch", {
                                                          attrs: {
                                                            "active-color":
                                                              "#00a344",
                                                            "data-testid":
                                                              "desktop-switch",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.checkNotificationsSettings,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.desktop_notifs,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.desktop_notifs =
                                                                $$v
                                                            },
                                                            expression:
                                                              "desktop_notifs",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("label", [
                                                          _vm._v("Desktop"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "switch-label mb-2",
                                                      },
                                                      [
                                                        _c("el-switch", {
                                                          attrs: {
                                                            "active-color":
                                                              "#00a344",
                                                            "data-testid":
                                                              "mobile-switch",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.checkNotificationsSettings,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.mobile_notifs,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.mobile_notifs =
                                                                $$v
                                                            },
                                                            expression:
                                                              "mobile_notifs",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("label", [
                                                          _vm._v("Mobile"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "switch-label mb-2",
                                                      },
                                                      [
                                                        _c("el-switch", {
                                                          attrs: {
                                                            "active-color":
                                                              "#00a344",
                                                            "data-testid":
                                                              "email-switch",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.checkNotificationsSettings,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.email_notifs,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.email_notifs =
                                                                $$v
                                                            },
                                                            expression:
                                                              "email_notifs",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("label", [
                                                          _vm._v("Email"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "switch-label mb-2",
                                                      },
                                                      [
                                                        _c("el-switch", {
                                                          attrs: {
                                                            "active-color":
                                                              "#00a344",
                                                            "data-testid":
                                                              "text-message-switch",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.handleTextMessageChange,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.text_notifs,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.text_notifs =
                                                                $$v
                                                            },
                                                            expression:
                                                              "text_notifs",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("label", [
                                                          _vm._v(
                                                            "Text Message"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.text_notifs
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-items-center mb-3",
                                                          },
                                                          [
                                                            _c(
                                                              "el-checkbox",
                                                              {
                                                                staticClass:
                                                                  "ml-5",
                                                                attrs: {
                                                                  "data-testid":
                                                                    "send-text-to-custom-number-switch",
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.handleCustomPhoneNumberOff,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.set_custom_number,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.set_custom_number =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "set_custom_number",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                Send texts to custom number:\n                                                            "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticClass:
                                                                  "pb-0 ml-2",
                                                                attrs: {
                                                                  prop: "notification_phone_number",
                                                                  required:
                                                                    _vm.set_custom_number,
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticClass:
                                                                    "w-100",
                                                                  attrs: {
                                                                    placeholder:
                                                                      "(123) 456-7890",
                                                                    "data-testid":
                                                                      "notification-phone-number-input",
                                                                    disabled:
                                                                      _vm.isViewOnlyAccess() ||
                                                                      !_vm.set_custom_number,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.preValidateForm(
                                                                          "user_form"
                                                                        )
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.user
                                                                        .notification_phone_number,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.user,
                                                                          "notification_phone_number",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "user.notification_phone_number",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass: "no-border",
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "page-anchor",
                                                        attrs: {
                                                          id: "communication-notifications",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-label mb-3",
                                                        },
                                                        [
                                                          _c("h5", [
                                                            _vm._v(
                                                              "Communication Notifications"
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _vm.user_id ===
                                                          _vm.auth.user.profile
                                                            .id
                                                            ? _c("small", [
                                                                _vm._v(
                                                                  "\n                                                                Notify me when the following events happen:\n                                                            "
                                                                ),
                                                              ])
                                                            : _c("small", [
                                                                _vm._v(
                                                                  "\n                                                                Notify this user when the following communication\n                                                                occurs:\n                                                            "
                                                                ),
                                                              ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "switch-label mb-2",
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top",
                                                                content:
                                                                  _vm.isCommunicationNotificationsDisabledTooltipText,
                                                                disabled:
                                                                  !_vm.isCommunicationNotificationsDisabled,
                                                              },
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  "data-testid":
                                                                    "communication-notification-switch-1",
                                                                  disabled:
                                                                    _vm.isCommunicationNotificationsDisabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.my_calls,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.my_calls =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "my_calls",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c("label", [
                                                            _vm._v(
                                                              "Call to personal line or to the ring groups this user belongs to"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "switch-label mb-2",
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top",
                                                                content:
                                                                  _vm.isCommunicationNotificationsDisabledTooltipText,
                                                                disabled:
                                                                  !_vm.isCommunicationNotificationsDisabled,
                                                              },
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  "data-testid":
                                                                    "communication-notification-switch-2",
                                                                  disabled:
                                                                    _vm.isCommunicationNotificationsDisabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.my_texts,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.my_texts =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "my_texts",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c("label", [
                                                            _vm._v(
                                                              "Text message to personal line or to the ring groups this user belongs to"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "switch-label mb-2",
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top",
                                                                content:
                                                                  _vm.isCommunicationNotificationsDisabledTooltipText,
                                                                disabled:
                                                                  !_vm.isCommunicationNotificationsDisabled,
                                                              },
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  "data-testid":
                                                                    "communication-notification-switch-3",
                                                                  disabled:
                                                                    _vm.isCommunicationNotificationsDisabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.my_voicemails,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.my_voicemails =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "my_voicemails",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c("label", [
                                                            _vm._v(
                                                              "Voicemail to personal line or to the ring groups this user belongs to"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "switch-label mb-2",
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top",
                                                                content:
                                                                  _vm.isCommunicationNotificationsDisabledTooltipText,
                                                                disabled:
                                                                  !_vm.isCommunicationNotificationsDisabled,
                                                              },
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  "data-testid":
                                                                    "communication-notification-switch-4",
                                                                  disabled:
                                                                    _vm.isCommunicationNotificationsDisabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.my_faxes,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.my_faxes =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "my_faxes",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c("label", [
                                                            _vm._v(
                                                              "Fax to personal line"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "switch-label mb-2",
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top",
                                                                content:
                                                                  _vm.isCommunicationNotificationsDisabledTooltipText,
                                                                disabled:
                                                                  !_vm.isCommunicationNotificationsDisabled,
                                                              },
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  "data-testid":
                                                                    "communication-notification-switch-5",
                                                                  disabled:
                                                                    _vm.isCommunicationNotificationsDisabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.my_mentions,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.my_mentions =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "my_mentions",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c("label", [
                                                            _vm._v(
                                                              "When this user is mentioned"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "switch-label mb-2",
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top",
                                                                content:
                                                                  _vm.isCommunicationNotificationsDisabledTooltipText,
                                                                disabled:
                                                                  !_vm.isCommunicationNotificationsDisabled,
                                                              },
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  "data-testid":
                                                                    "communication-notification-switch-6",
                                                                  disabled:
                                                                    _vm.isCommunicationNotificationsDisabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.my_contacts,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.my_contacts =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "my_contacts",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c("label", [
                                                            _vm._v(
                                                              "When a contact is assigned to this user"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "switch-label mb-2",
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top",
                                                                content:
                                                                  _vm.isCommunicationNotificationsDisabledTooltipText,
                                                                disabled:
                                                                  !_vm.isCommunicationNotificationsDisabled,
                                                              },
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  "data-testid":
                                                                    "communication-notification-switch-7",
                                                                  disabled:
                                                                    _vm.isCommunicationNotificationsDisabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.my_appointments,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.my_appointments =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "my_appointments",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c("label", [
                                                            _vm._v(
                                                              "When an appointment is assigned to this user"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "switch-label mb-2",
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top",
                                                                content:
                                                                  _vm.isCommunicationNotificationsDisabledTooltipText,
                                                                disabled:
                                                                  !_vm.isCommunicationNotificationsDisabled,
                                                              },
                                                            },
                                                            [
                                                              _c("el-switch", {
                                                                attrs: {
                                                                  "active-color":
                                                                    "#00a344",
                                                                  "data-testid":
                                                                    "communication-notification-switch-8",
                                                                  disabled:
                                                                    _vm.isCommunicationNotificationsDisabled,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.my_reminders,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.my_reminders =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "my_reminders",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c("label", [
                                                            _vm._v(
                                                              "When a reminder is assigned to this user"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.userHasAdminRole
                                                    ? _c("el-form-item", [
                                                        _c("span", {
                                                          staticClass:
                                                            "page-anchor",
                                                          attrs: {
                                                            id: "account-level-notifications",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-label mb-3",
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                "Account Level Notifications (Admins Only)"
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("small", [
                                                              _vm._v(
                                                                "Receive daily emails for account activity:"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.userIsBillingAdmin
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "switch-label mb-2 d-flex flex-wrap",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-switch",
                                                                      {
                                                                        attrs: {
                                                                          "active-color":
                                                                            "#00a344",
                                                                          disabled:
                                                                            _vm.isViewOnlyAccess() ||
                                                                            !_vm.userIsBillingAdmin,
                                                                          "data-testid":
                                                                            "billing-warnings-switch",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .user
                                                                              .enabled_billing_warnings,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.user,
                                                                                "enabled_billing_warnings",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "user.enabled_billing_warnings",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "flex-basis-25 flex-grow-1 flex-sm-grow-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    Billing Warnings\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-popover",
                                                                      {
                                                                        attrs: {
                                                                          width:
                                                                            "250",
                                                                          trigger:
                                                                            "hover",
                                                                          placement:
                                                                            "right",
                                                                          "data-testid":
                                                                            "only-for-billing-admins-popover",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                        Only enabled for billing admins\n                                                                    "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                slot: "reference",
                                                                              },
                                                                            slot: "reference",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa fa-info-circle text-primary",
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.userIsCompanyAdmin
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "switch-label mb-2 d-flex flex-wrap",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-switch",
                                                                      {
                                                                        attrs: {
                                                                          "active-color":
                                                                            "#00a344",
                                                                          "data-testid":
                                                                            "integration-reports-switch",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .user
                                                                              .enabled_integration_reports,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.user,
                                                                                "enabled_integration_reports",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "user.enabled_integration_reports",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "flex-basis-25 flex-grow-1 flex-sm-grow-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    Integration Reports\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-popover",
                                                                      {
                                                                        attrs: {
                                                                          width:
                                                                            "230",
                                                                          trigger:
                                                                            "hover",
                                                                          placement:
                                                                            "right",
                                                                          "data-testid":
                                                                            "integration-reports-popover",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "pb-2 font-weight-bold",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Integration Reports include:"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "ul",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-0",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "li",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Users Sync"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "li",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Contacts Sync"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "li",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Contact Dispositions Sync"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "li",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Call Dispositions Sync"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "li",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Failed Integration Connection Status"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                slot: "reference",
                                                                              },
                                                                            slot: "reference",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa fa-info-circle text-primary",
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.userIsCompanyAdmin
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "switch-label mb-2 d-flex flex-wrap",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-switch",
                                                                      {
                                                                        attrs: {
                                                                          "active-color":
                                                                            "#00a344",
                                                                          "data-testid":
                                                                            "account-reports-switch",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .user
                                                                              .enabled_account_reports,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.user,
                                                                                "enabled_account_reports",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "user.enabled_account_reports",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "flex-basis-25 flex-grow-1 flex-sm-grow-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    Account Reports\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-popover",
                                                                      {
                                                                        attrs: {
                                                                          width:
                                                                            "230",
                                                                          trigger:
                                                                            "hover",
                                                                          placement:
                                                                            "right",
                                                                          "data-testid":
                                                                            "account-reports-popover",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "pb-2 font-weight-bold",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Account Reports include:"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "ul",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-0",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "li",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Daily Activity Reports"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "li",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Daily Inbound SMS Report"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "li",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Daily Outbound SMS Report"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "li",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Daily Spending Report"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                slot: "reference",
                                                                              },
                                                                            slot: "reference",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa fa-info-circle text-primary",
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c("div", [
                                                                  !_vm.isViewOnlyAccess()
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "border-bottom border-primary ml-0 ml-md-2 text-primary",
                                                                          attrs:
                                                                            {
                                                                              href: "/account?tab=reporting-settings",
                                                                              "data-testid":
                                                                                "reporting-settings-link",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                    Click here to enable Account Reports at the account level\n                                                                "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.userIsCompanyAdmin
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "switch-label mb-2 d-flex flex-wrap",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-switch",
                                                                      {
                                                                        attrs: {
                                                                          "active-color":
                                                                            "#00a344",
                                                                          "data-testid":
                                                                            "other-notifications-switch",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .user
                                                                              .enabled_other_reports,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.user,
                                                                                "enabled_other_reports",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "user.enabled_other_reports",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "flex-basis-25 flex-grow-1 flex-sm-grow-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                    Other Notifications\n                                                                "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-popover",
                                                                      {
                                                                        attrs: {
                                                                          width:
                                                                            "230",
                                                                          trigger:
                                                                            "hover",
                                                                          placement:
                                                                            "right",
                                                                          "data-testid":
                                                                            "other-notifications-popover",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "pb-2 font-weight-bold",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Other Notifications:"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "ul",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-0",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "li",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Imports/Exports Reports"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "li",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Webhook Failure Reports"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                slot: "reference",
                                                                              },
                                                                            slot: "reference",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa fa-info-circle text-primary",
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-form-item",
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "page-anchor",
                                                        attrs: {
                                                          id: "event-notifications",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-label mb-3",
                                                        },
                                                        [
                                                          _c("h5", [
                                                            _vm._v(
                                                              "Event Notifications"
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _vm.user_id ===
                                                          _vm.auth.user.profile
                                                            .id
                                                            ? _c("small", [
                                                                _vm._v(
                                                                  "\n                                                                Notify me when the following appointment or reminder event occurs:\n                                                            "
                                                                ),
                                                              ])
                                                            : _c("small", [
                                                                _vm._v(
                                                                  "\n                                                                Notify this user when the following appointment or reminder event occurs:\n                                                            "
                                                                ),
                                                              ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-checkbox-group",
                                                        {
                                                          model: {
                                                            value:
                                                              _vm.user
                                                                .reminders_options,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.user,
                                                                "reminders_options",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "user.reminders_options",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.reminders_options,
                                                          function (
                                                            reminders_option
                                                          ) {
                                                            return _c(
                                                              "el-checkbox",
                                                              {
                                                                key: reminders_option.value,
                                                                staticStyle: {
                                                                  width:
                                                                    "200px",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    reminders_option.value,
                                                                  "data-testid":
                                                                    reminders_option.value +
                                                                    "-checkbox",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                " +
                                                                    _vm._s(
                                                                      reminders_option.title
                                                                    ) +
                                                                    "\n                                                            "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.hasRole(
                                                    [
                                                      "Company Admin",
                                                      "Company Agent",
                                                    ],
                                                    _vm.user.role_names
                                                  )
                                                    ? _c(
                                                        "el-form-item",
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticClass:
                                                                "no-border",
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "page-anchor",
                                                                attrs: {
                                                                  id: "line-notifications",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-label",
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      "Line Notifications"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("small", [
                                                                    _vm._v(
                                                                      "Get extra notifications from the lines you choose below."
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "switch-label mb-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-switch",
                                                                    {
                                                                      attrs: {
                                                                        "active-color":
                                                                          "#00a344",
                                                                        "data-testid":
                                                                          "line-notifications-checkbox",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.should_observe,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.should_observe =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "should_observe",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("label", [
                                                                    _vm._v(
                                                                      "Receive notifications from lines"
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.should_observe
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-label",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            [
                                                                              _vm._v(
                                                                                "Select the lines to get notified from"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "campaign-selector",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              select_style:
                                                                                "width: 100%;",
                                                                              multiple:
                                                                                "",
                                                                              collapsable: false,
                                                                              value:
                                                                                _vm
                                                                                  .user
                                                                                  .observing_campaigns,
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              _vm.changeObservingCampaigns,
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .user
                                                                                  .observing_campaigns,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.user,
                                                                                    "observing_campaigns",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "user.observing_campaigns",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.hasRole(
                                                    [
                                                      "Company Admin",
                                                      "Company Agent",
                                                    ],
                                                    _vm.user.role_names
                                                  )
                                                    ? _c("el-form-item", [
                                                        _c("span", {
                                                          staticClass:
                                                            "page-anchor",
                                                          attrs: {
                                                            id: "high-sms-volume-notifications",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-label",
                                                          },
                                                          [
                                                            _c("h5", [
                                                              _vm._v(
                                                                "High SMS Volume Notifications"
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("small", [
                                                              _vm._v(
                                                                "Receive notifications when too many messages have been sent within a short time period:"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "switch-label mb-3",
                                                          },
                                                          [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-color":
                                                                  "#00a344",
                                                                "data-testid":
                                                                  "high-sms-notification-switch",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.high_sms_volume_notifs,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.high_sms_volume_notifs =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "high_sms_volume_notifs",
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("label", [
                                                              _vm._v(
                                                                "When an SMS reply exceeds 15 messages within 30 seconds"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-3 d-hide d-md-block ml-auto",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "list-group list-group-alo list-group-flush sticky-top stick-top-with-header",
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "list-group-item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            On This Page\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#communication-notifications",
                                                            "data-testid":
                                                              "communication-notifications",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "communication-notifications"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Communication Notifications\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#account-level-notifications",
                                                            "data-testid":
                                                              "account-level-notifications",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "account-level-notifications"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Account Level Notifications (Admins Only)\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#event-notifications",
                                                            "data-testid":
                                                              "event-notifications",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "event-notifications"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Event Notifications\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#line-notifications",
                                                            "data-testid":
                                                              "line-notifications",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "line-notifications"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Line Notifications\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#notification-channels",
                                                            "data-testid":
                                                              "notifications-channels",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "notification-channels"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Notification Channels\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.user_id &&
                                    _vm.canBeEdited &&
                                    !_vm.auth.user.profile.is_reseller
                                      ? _c(
                                          "el-tab-pane",
                                          {
                                            staticClass: "p-2",
                                            attrs: {
                                              name: "general-information",
                                              "data-testid":
                                                "general-information-pane",
                                              label: "General Information",
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-8" },
                                                [
                                                  _vm.user.sip_uri &&
                                                  _vm.user.sip_details
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-md-12",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-12",
                                                                          },
                                                                          [
                                                                            !_vm
                                                                              .statics
                                                                              .whitelabel
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mb-3",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "alert alert-success alert-aloware d-inline-block",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "d-flex",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "align-self-center font-weight-bold mr-3",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                                            Have you considered\n                                                                                            using\n                                                                                            Aloware app on your\n                                                                                            smartphone 📱?\n                                                                                        "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "a",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "ml-auto",
                                                                                                attrs:
                                                                                                  {
                                                                                                    target:
                                                                                                      "_blank",
                                                                                                    href: "https://aloware.com/apps",
                                                                                                    "data-testid":
                                                                                                      "get-the-app-link",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "el-button",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        size: "small",
                                                                                                        type: "success",
                                                                                                        plain:
                                                                                                          "",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "strong",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "Get the\n                                                                                                    App"
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.current_company &&
                                                                            !_vm
                                                                              .current_company
                                                                              .default_outbound_campaign_id &&
                                                                            _vm
                                                                              .user
                                                                              .answer_by ===
                                                                              _vm
                                                                                .AnswerTypes
                                                                                .BY_IP_PHONE
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "alert alert-danger alert-aloware",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                This user will not send outbound calls\n                                                                                unless you\n                                                                                select a default outbound line for your\n                                                                                company.\n                                                                            "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-form-item",
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "page-anchor",
                                                                                    attrs:
                                                                                      {
                                                                                        id: "ring_group_member",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "form-label",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "h5",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Ring Groups"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "small",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                                                                        This user is a member of the following ring groups\n                                                                                    "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm
                                                                                  .user
                                                                                  .ring_group_ids &&
                                                                                _vm
                                                                                  .user
                                                                                  .ring_group_ids
                                                                                  .length >
                                                                                  0
                                                                                  ? _c(
                                                                                      "ul",
                                                                                      {
                                                                                        staticClass:
                                                                                          "list inset mb-0 ml-3",
                                                                                      },
                                                                                      _vm._l(
                                                                                        _vm
                                                                                          .user
                                                                                          .ring_group_ids,
                                                                                        function (
                                                                                          ring_group_id
                                                                                        ) {
                                                                                          return _vm.getRingGroup(
                                                                                            ring_group_id
                                                                                          ) &&
                                                                                            _vm.getRingGroup(
                                                                                              ring_group_id
                                                                                            )
                                                                                              .name &&
                                                                                            !_vm.getRingGroup(
                                                                                              ring_group_id
                                                                                            )
                                                                                              .call_waiting
                                                                                            ? _c(
                                                                                                "li",
                                                                                                {
                                                                                                  key: ring_group_id,
                                                                                                  staticClass:
                                                                                                    "pb-0",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "text-dark-greenish _400",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                                                                            " +
                                                                                                          _vm._s(
                                                                                                            _vm._f(
                                                                                                              "fixName"
                                                                                                            )(
                                                                                                              _vm.getRingGroup(
                                                                                                                ring_group_id
                                                                                                              )
                                                                                                                .name
                                                                                                            )
                                                                                                          ) +
                                                                                                          "\n                                                                                        "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e()
                                                                                        }
                                                                                      ),
                                                                                      0
                                                                                    )
                                                                                  : _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-md text-danger _600",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "This user is not connected to any ring group."
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            !_vm
                                                                              .statics
                                                                              .whitelabel &&
                                                                            _vm.isUsingSip
                                                                              ? _c(
                                                                                  "div",
                                                                                  [
                                                                                    _c(
                                                                                      "el-form-item",
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "page-anchor",
                                                                                            attrs:
                                                                                              {
                                                                                                id: "sip_uri",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "form-label",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "el-alert",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "mb-4",
                                                                                                attrs:
                                                                                                  {
                                                                                                    type: "warning",
                                                                                                    closable: false,
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "h4",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "font-weight-bold m-auto",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "We will decommission our SIP phone set up by March 2nd (v10.60),\n                                                                                                2023. Please make sure your users download the Aloware app."
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "h5",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "SIP URI"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "small",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                                            A SIP-URI is the SIP\n                                                                                            addressing scheme that\n                                                                                            communicates who to call via\n                                                                                            SIP.\n                                                                                            In other words, a SIP URI is\n                                                                                            a user’s SIP phone number.\n                                                                                            The SIP URI resembles an\n                                                                                            e-mail address.\n                                                                                        "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "input-readonly-with-copy",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .user
                                                                                                    .sip_uri,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "el-form-item",
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "page-anchor",
                                                                                            attrs:
                                                                                              {
                                                                                                id: "sip_domain",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "form-label",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "h5",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "SIP Domain"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "small",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                                            This is the domain the SIP\n                                                                                            phones connect to.\n                                                                                        "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "input-readonly-with-copy",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                value:
                                                                                                  _vm._f(
                                                                                                    "filterDomain"
                                                                                                  )(
                                                                                                    _vm
                                                                                                      .user
                                                                                                      .sip_uri
                                                                                                  ),
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "el-form-item",
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "page-anchor",
                                                                                            attrs:
                                                                                              {
                                                                                                id: "sip_username",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "form-label",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "h5",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "SIP Username"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "small",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                                            The username / authorization\n                                                                                            name for this SIP device.\n                                                                                        "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "input-readonly-with-copy",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .user
                                                                                                    .sip_details
                                                                                                    .username,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "el-form-item",
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "page-anchor",
                                                                                            attrs:
                                                                                              {
                                                                                                id: "sip_password",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "form-label",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "h5",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "SIP Password"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "small",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                                            Password for this SIP\n                                                                                            device.\n                                                                                        "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "input-readonly-with-copy",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .user
                                                                                                    .sip_details
                                                                                                    .password,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "el-form-item",
                                                                                      {
                                                                                        staticClass:
                                                                                          "no-border",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "page-anchor",
                                                                                            attrs:
                                                                                              {
                                                                                                id: "sip_alias",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "form-label",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "h5",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "SIP Alias"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "small",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                                            An internal label for this\n                                                                                            device. Might be displayed\n                                                                                            on the physical phone.\n                                                                                        "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "input-readonly-with-copy",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                value:
                                                                                                  _vm
                                                                                                    .user
                                                                                                    .sip_details
                                                                                                    .alias,
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-3 d-none d-md-block ml-auto",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "list-group list-group-alo list-group-flush sticky-top stick-top-with-header",
                                                    },
                                                    [
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "list-group-item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            On This Page\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "list-group-item list-group-item-action",
                                                          attrs: {
                                                            href: "#ring_group_member",
                                                            "data-testid":
                                                              "ring-groups-link",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.shine(
                                                                "ring_group_member"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                            Ring Groups\n                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      !_vm.statics.whitelabel &&
                                                      _vm.isUsingSip
                                                        ? [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "list-group-item list-group-item-action",
                                                                attrs: {
                                                                  href: "#sip_uri",
                                                                  "data-testid":
                                                                    "sip-uri-link",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.shine(
                                                                        "sip_uri"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                SIP URI\n                                                            "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "list-group-item list-group-item-action",
                                                                attrs: {
                                                                  href: "#sip_domain",
                                                                  "data-testid":
                                                                    "sip-domain-link",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.shine(
                                                                        "sip_domain"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                SIP Domain\n                                                            "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "list-group-item list-group-item-action",
                                                                attrs: {
                                                                  href: "#sip_username",
                                                                  "data-testid":
                                                                    "sip-username-link",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.shine(
                                                                        "sip_username"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                SIP Username\n                                                            "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "list-group-item list-group-item-action",
                                                                attrs: {
                                                                  href: "#sip_password",
                                                                  "data-testid":
                                                                    "sip-password-link",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.shine(
                                                                        "sip_password"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                SIP Password\n                                                            "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "list-group-item list-group-item-action",
                                                                attrs: {
                                                                  href: "#sip_alias",
                                                                  "data-testid":
                                                                    "sip-alias-link",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.shine(
                                                                        "sip_alias"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                                SIP Alias\n                                                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "el-tab-pane",
                                      {
                                        staticClass: "p-2",
                                        attrs: {
                                          name: "teams",
                                          "data-testid": "teams-pane",
                                          label: "Teams",
                                        },
                                      },
                                      [
                                        _c("teams-tab", {
                                          attrs: { user: _vm.user },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.allowUserSmsTemplate
                                      ? _c(
                                          "el-tab-pane",
                                          {
                                            attrs: {
                                              "data-testid":
                                                "sms-template-pane",
                                              label: "SMS Templates",
                                              name: "sms-templates",
                                            },
                                          },
                                          [
                                            _c("sms-template-list", {
                                              staticClass: "pl-3",
                                              attrs: {
                                                user_id: parseInt(_vm.user_id),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.user_id &&
                                    _vm.userDestinationEditable &&
                                    !_vm.auth.user.profile.is_reseller
                                      ? _c(
                                          "el-tab-pane",
                                          {
                                            staticClass: "pt-2",
                                            attrs: {
                                              name: "user-diagnosis",
                                              "data-testid":
                                                "user-diagnosis-pane",
                                              label: "Diagnosis",
                                            },
                                          },
                                          [
                                            _c("user-diagnosis", {
                                              ref: "user_diagnosis",
                                              attrs: {
                                                user: _vm.user,
                                                "data-testid": "user-diagnosis",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("form-height-indicator"),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "fixed-footer padding pb-0" }, [
            _c("div", { staticClass: "form-group row mb-0 mt-0" }, [
              _c(
                "div",
                { staticClass: "col-12 d-none d-md-block p-2 edit-operations" },
                [
                  _vm.canGoBack
                    ? _c(
                        "el-button",
                        {
                          staticClass: "pull-left",
                          attrs: { "data-testid": "back-button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.goBack.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-arrow-left" }),
                          _vm._v(
                            "\n                    Back\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top-start",
                        width: "200",
                        trigger: "hover",
                        content:
                          "You have reached the maximum number of users for your plan.",
                        disabled: !_vm.isCompanyKYC,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "pull-right ml-0",
                          attrs: {
                            slot: "reference",
                            type: "success",
                            disabled: _vm.loading_btn || !_vm.validated,
                            "data-testid": "save-add-button",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.submitForm("user_form")
                            },
                          },
                          slot: "reference",
                        },
                        [
                          _c(
                            "i",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.loading_btn,
                                  expression: "loading_btn",
                                },
                              ],
                              staticClass: "material-icons loader pull-left",
                            },
                            [_vm._v("")]
                          ),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.user_id ? "Save" : "Add") +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.user_id &&
                  _vm.hasPermissionTo("update user") &&
                  !_vm.auth.user.profile.is_reseller
                    ? _c(
                        "button",
                        {
                          staticClass: "btn el-btn pull-right mr-2",
                          class: _vm.user.active
                            ? "deep-orange-500"
                            : "light-blue-500",
                          attrs: {
                            "data-testid": "active-button",
                            disabled: _vm.loading_active,
                          },
                          on: { click: _vm.toggleActive },
                        },
                        [
                          _vm.loading_active
                            ? _c("i", { staticClass: "fa fa-spin fa-spinner" })
                            : [
                                _vm.user.active
                                  ? _c("i", { staticClass: "fa fa-pause" })
                                  : _c("i", { staticClass: "fa fa-play" }),
                              ],
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.user.active ? "Pause" : "Activate") +
                              "\n                "
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user_id && _vm.user.answer_by != _vm.AnswerTypes.BY_NONE
                    ? _c(
                        "button",
                        {
                          staticClass: "btn el-btn purple-500 pull-right mr-2",
                          attrs: {
                            "data-testid": "test-call-button",
                            disabled: _vm.loading_test_call_route,
                          },
                          on: { click: _vm.testRouting },
                        },
                        [
                          _vm.loading_test_call_route
                            ? _c("i", { staticClass: "fa fa-spin fa-spinner" })
                            : _vm._e(),
                          _vm._v(
                            "\n                    Test Call\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user_id && _vm.hasPermissionTo("delete user")
                    ? _c(
                        "button",
                        {
                          staticClass: "btn el-btn pull-right danger mr-2",
                          attrs: {
                            disabled: _vm.loading_delete,
                            "data-testid": "delete-button",
                          },
                          on: { click: _vm.deleteUserRemote },
                        },
                        [
                          _c(
                            "i",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.loading_delete,
                                  expression: "loading_delete",
                                },
                              ],
                              staticClass: "material-icons loader pull-left",
                            },
                            [_vm._v("")]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loading_delete,
                                expression: "!loading_delete",
                              },
                            ],
                            staticClass: "fa fa-trash",
                          }),
                          _vm._v(
                            "\n                    Delete\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 d-sm-block d-md-none p-2 edit-operations",
                },
                [
                  _vm.canGoBack
                    ? _c(
                        "el-button",
                        {
                          staticClass: "pull-left",
                          attrs: {
                            size: "mini",
                            "data-testid": "can-go-back-button",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.goBack.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-arrow-left" }),
                          _vm._v(
                            "\n                    Back\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "pull-right",
                      attrs: {
                        size: "mini",
                        disabled: _vm.loading_btn || !_vm.validated,
                        "data-testid": "save-add-mini-button",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitForm("user_form")
                        },
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.loading_btn,
                              expression: "loading_btn",
                            },
                          ],
                          staticClass: "material-icons loader pull-left",
                        },
                        [_vm._v("")]
                      ),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.user_id ? "Save" : "Add") +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top",
                        width: "100%",
                        trigger: "click",
                      },
                    },
                    [
                      _vm.user_id && _vm.hasPermissionTo("update user")
                        ? _c(
                            "el-button",
                            {
                              staticClass: "pull-right ml-2",
                              attrs: {
                                size: "mini",
                                type: _vm.user.active
                                  ? "deep-orange-500"
                                  : "light-blue-500",
                                disabled: _vm.loading_active,
                                "data-testid": "active-mini-button",
                              },
                              on: { click: _vm.toggleActive },
                            },
                            [
                              _vm.loading_active
                                ? _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons loader pull-left",
                                    },
                                    [_vm._v("")]
                                  )
                                : [
                                    _vm.user.active
                                      ? _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons pull-left",
                                          },
                                          [_vm._v("pause")]
                                        )
                                      : _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons pull-left",
                                          },
                                          [_vm._v("autorenew")]
                                        ),
                                  ],
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.user.active == true
                                      ? "Pause"
                                      : "Activate"
                                  ) +
                                  "\n                    "
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user_id && _vm.hasPermissionTo("delete user")
                        ? _c(
                            "el-button",
                            {
                              staticClass: "pull-right ml-0",
                              attrs: {
                                type: "danger",
                                size: "mini",
                                disabled: _vm.loading_delete,
                              },
                              on: { click: _vm.deleteUserRemote },
                            },
                            [
                              _c(
                                "i",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.loading_delete,
                                      expression: "loading_delete",
                                    },
                                  ],
                                  staticClass:
                                    "material-icons loader pull-left",
                                },
                                [_vm._v("")]
                              ),
                              _vm._v(" "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.loading_delete,
                                    expression: "!loading_delete",
                                  },
                                ],
                                staticClass: "fa fa-trash",
                              }),
                              _vm._v(
                                "\n                        Delete\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-button", {
                        staticClass: "pull-right",
                        attrs: {
                          slot: "reference",
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-more-outline",
                          plain: "",
                        },
                        slot: "reference",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.show_help
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Roles",
                    width: _vm.isLargeEnough ? "700px" : "100%",
                    visible: _vm.show_help,
                    center: "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.show_help = $event
                    },
                  },
                },
                [
                  _c("iframe", {
                    attrs: {
                      width: "100%",
                      height: "500px",
                      src: "https://www.youtube.com/embed/7Qzgk_gh-Ho?autoplay=1&enablejsapi=1&fs=1&iv_load_policy=1&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://app.aloware.com",
                      frameborder: "0",
                      allow:
                        "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                      allowfullscreen: "",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }