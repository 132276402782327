var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "el-dialog",
        {
          staticClass: "user-to-teams--dialog",
          attrs: {
            width: "40%",
            center: "",
            "append-to-body": "",
            title: _vm.modalConfig.title,
            visible: _vm.visible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "user-to-teams" }, [
            _c("p", { staticClass: "break-word text-center" }, [
              _vm._v(_vm._s(_vm.modalConfig.description)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content" },
              [
                _vm._l(_vm.all_teams, function (team) {
                  return _c(
                    "div",
                    {
                      key: team.id,
                      staticClass:
                        "d-flex align-items-center justify-content-between user-to-teams--divider",
                    },
                    [
                      _c("div", { staticClass: "user-to-teams--label" }, [
                        _vm._v(_vm._s(team.name)),
                      ]),
                      _vm._v(" "),
                      !_vm.isUserInTeam(team)
                        ? _c("el-button", {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-plus",
                              disabled: _vm.isLoadingData,
                              loading: _vm.isLoadingData,
                              "data-testid": "add-user-to-team",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onAddUserToTeam(team)
                              },
                            },
                          })
                        : _c("el-button", {
                            attrs: {
                              type: "success",
                              icon: "el-icon-check",
                              disabled: _vm.isLoadingData,
                              loading: _vm.isLoadingData,
                              "data-testid": "user-remove-from-team-button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onRemoveUserFromTeam(team)
                              },
                            },
                          }),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.isLoadingData && !_vm.all_teams.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "user-to-teams d-flex align-items-center justify-content-center",
                      },
                      [_c("el-spinner")],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }