var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-blank",
      attrs: {
        width: "70vw",
        top: "5vh",
        "append-to-body": "",
        "show-close": false,
        visible: _vm.visible,
      },
    },
    [
      _c("div", { staticClass: "container-listing rounded p-4" }, [
        _c("div", { staticClass: "container-header d-flex" }, [
          _c("h4", { staticClass: "listing-heading" }, [
            _vm._v("New Sequence"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-dialog-close",
                  attrs: { type: "text", size: "large" },
                  on: { click: _vm.close },
                },
                [_c("i", { staticClass: "fa fa-remove" })]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container-body" },
          [
            _c("workflow-settings-manager", {
              attrs: { mode: "add" },
              on: { onAddClose: _vm.close, saved: _vm.close },
              model: {
                value: _vm.workflow,
                callback: function ($$v) {
                  _vm.workflow = $$v
                },
                expression: "workflow",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }