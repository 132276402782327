var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible && !_vm.closed
    ? _c("div", { class: "header-notification" + " hn-" + _vm.type }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "div",
              { staticClass: "flex-grow-1 align-self-center" },
              [
                _vm._t("default", function () {
                  return [
                    _vm._v(
                      "\n                There is no notification provided.\n            "
                    ),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.closable
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn-close ml-2",
                    attrs: { size: "small", type: "text" },
                    on: { click: _vm.destroy },
                  },
                  [_c("i", { staticClass: "fa fa-close" })]
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }