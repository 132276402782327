<template>
    <div>
        <p v-if="data.message">{{ data.message }}</p>
        <p v-if="data.error">{{ data.error }}</p>
        <br>
        <p v-if="!data.success && data.error_code">Error code: {{ data.error_code }}</p>
    </div>
</template>

<script>
export default {
    props: ['data']
}
</script>
