<template>
    <div class="h-100"
         :class="[('summary-card-' + status)]"
         data-testid="summary-report-sms-wrapper">
        <a href="#"
           class="w-100 cursor-initial">
            <div class="card summary-card-hover h-100 border-0 shadow-none bg-transparent">
                <div class="text-center mt-2 w-100">
                    <template v-for="(item, index) in data">
                        <div class="countup-hover"
                             :class="classesForMultipleAgreggateData(item)"
                             data-testid="summary-report-sms-card"
                             @click.prevent="clickFilter(item.index)">
                            <h4 class="m-a-0 mb-2 text-lg metric-value">
                                <ICountUp v-if="typeof item.count !== 'undefined' && item.count !== null"
                                          :ref="'countup-' + index"
                                          :duration="count_up.duration"
                                          :endVal="parseInt(item.count)"
                                          :options="countUpOptions(index)"
                                          data-testid="summary-report-sms-card-countup"
                                          :startVal="count_up.startVal"
                                />
                                <span v-if="item.count == null">-</span>
                            </h4>
                            <small class="text-muted text-sm sms-text metric-label">{{ item.label }}</small>
                        </div>
                    </template>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {summary_widgets_mixin} from '../mixins'
import ICountUp from 'vue-countup-v2'

export default {
    mixins: [summary_widgets_mixin],

    components: {
        ICountUp
    },

    props: {
        title: {
            required: false,
            default: null
        },
        data: {
            required: false,
            default: () => []
        },
        img_src: {
            required: false,
            default: null
        },
        loading: {
            required: false,
            default: true
        },
        icons: {
            required: false,
            default: () => []
        },
        campaign_id: {
            required: false
        },
        workflow_id: {
            required: false
        },
        ring_group_id: {
            required: false
        },
        user_id: {
            required: false
        },
        status: {
            required: false,
            default: 'inactive'
        }
    },

    data() {
        let easingFn = function (t, b, c, d) {
            let ts = (t /= d) * t
            let tc = ts * t
            return b + c * (tc + -3 * ts + 3 * t)
        }
        return {
            count_up: {
                startVal: 0,
                duration: 2.5,
                options: {
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true
                },
                decimal_options: {
                    decimalPlaces: 2,
                    suffix: '%',
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true
                },
                custom_formatting: {
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true,
                    formattingFn: this.formatSeconds
                }
            }
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),
        dataColumnClass() {
            if (this.data.length < 6) {
                return 'col-' + (12  / this.data.length)
            }
            let column_percent = (12 / Math.ceil(this.data.length / 2))
            return 'col-' + column_percent.toString().replace('.', '-')
        }
    },

    methods: {
        classesForMultipleAgreggateData(item) {
            return [
                this.data.length == 1 ? 'w-100' : this.dataColumnClass,
                (!item.clickable ? 'unclickable' : '')
            ]
        },

        countUpOptions(index) {
            if (this.data[index]?.is_seconds) {
                return this.count_up.custom_formatting
            }
            return this.count_up.options
        },

        clickFilter(index) {
            switch (index) {
                // inbound texts
                case 0:
                    this.filterByTexts('inbound')
                    break
                // outbound texts
                case 1:
                    this.filterByTexts('outbound')
                    break
            }
        },

        filterByTexts(direction) {
            this.resetFiltersExceptDates()
            this.filter.direction = direction
            this.filter.timezone = window.timezone
            this.filter.type = 'sms'
            this.filter.campaign_id = this.campaign_id
            this.filter.workflow_id = this.workflow_id
            this.filter.ring_group_id = this.ring_group_id
            this.filter.user_id = this.user_id
            this.filter.changed = true
            this.scrollWindowToGraph()
            VueEvent.fire('update_summary', true)
        },

        resetFiltersExceptDates() {
            let from_date = this.filter.from_date
            let to_date = this.filter.to_date
            this.resetFilters()
            this.filter.from_date = from_date
            this.filter.to_date = to_date
        },

        scrollWindowToGraph() {
            if (!$('#activity-graph').length) {
                return
            }

            let top_position = $("#activity-graph").offset().top
            let header_height = $(".app-header").height()

            $("html,body").animate({
                scrollTop: top_position - header_height - 90
            }, 500)
        },

        ...mapActions(['resetFilters'])
    }
}
</script>
