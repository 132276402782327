var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { "data-testid": "line-details-wrapper" } }, [
    _c("div", { staticClass: "container-campaign-summary mt-5 pt-5 mb-5" }, [
      _vm.campaign.is_fax
        ? _c("h2", { attrs: { "data-testid": "fax-created-title" } }, [
            _c("i", { staticClass: "fa fa-check text-greenish" }),
            _vm._v("\n            Fax created\n        "),
          ])
        : _c("h2", { attrs: { "data-testid": "line-created-title" } }, [
            _c("i", { staticClass: "fa fa-check text-greenish" }),
            _vm._v("\n            Line created\n        "),
          ]),
      _vm._v(" "),
      _vm.campaign.is_fax
        ? _c("i", {
            staticClass: "fa fa-fax is-fax finish-icon",
            attrs: { "aria-hidden": "true" },
          })
        : _c("img", {
            staticClass: "finish-icon",
            attrs: { src: "/assets/images/app-icons/icon-lines-grey.svg" },
          }),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "col-10 my-0 mx-auto mb-2 text-md" },
        [
          _c(
            "el-tabs",
            { staticClass: "text-left mt-4" },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "Review Line",
                    "data-testid": "review-line-pane",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row p-2",
                      attrs: { "data-testid": "name-data" },
                    },
                    [
                      _c("div", { staticClass: "col-5" }, [
                        _vm._v(
                          "\n                            Name:\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-7" }, [
                        _c("span", [_vm._v(_vm._s(_vm.campaign.name))]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row p-2",
                      attrs: { "data-testid": "number-data" },
                    },
                    [
                      _c("div", { staticClass: "col-5" }, [
                        _vm._v(
                          "\n                            Your number:\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-7" },
                        _vm._l(
                          _vm.campaign.incoming_numbers,
                          function (ins, index) {
                            return _c(
                              "span",
                              { key: index, staticClass: "ins_item" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("fixPhone")(ins.phone_number))
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.hasPermissionTo("list ring group") &&
                  !_vm.campaign.is_fax &&
                  _vm.campaign.ring_group_id
                    ? _c("div", { staticClass: "row p-2" }, [
                        _c("div", { staticClass: "col-5" }, [
                          _vm._v(
                            "\n                            We are routing calls to:\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7" },
                          [
                            _vm.campaign.ring_group_id &&
                            _vm.getRingGroup(_vm.campaign.ring_group_id)
                              ? [
                                  _c(
                                    "span",
                                    { staticClass: "dn_item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "Ring Group Dialog",
                                              params: {
                                                ring_group_id:
                                                  _vm.campaign.ring_group_id,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-dark-greenish _400",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm._f("fixName")(
                                                      _vm.getRingGroup(
                                                        _vm.campaign
                                                          .ring_group_id
                                                      ).name
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasPermissionTo(["list user", "list ring group"]) &&
                  !_vm.campaign.is_fax &&
                  _vm.campaign.ring_group_id
                    ? _c("div", { staticClass: "row p-2" }, [
                        _c("div", { staticClass: "col-5" }, [
                          _vm._v(
                            "\n                            User:\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7" },
                          [
                            _vm.campaign.ring_group_id &&
                            _vm.getRingGroup(_vm.campaign.ring_group_id)
                              ? _vm._l(
                                  _vm.getRingGroup(_vm.campaign.ring_group_id)
                                    .user_ids,
                                  function (user_id) {
                                    return _vm.getUser(user_id)
                                      ? _c(
                                          "span",
                                          {
                                            key: user_id,
                                            staticClass: "dn_item",
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "User Dialog",
                                                    params: {
                                                      user_id: user_id,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-dark-greenish _400",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.getUserName(
                                                            _vm.getUser(user_id)
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  }
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.campaign.user
                    ? _c("div", { staticClass: "row p-2" }, [
                        _c("div", { staticClass: "col-5" }, [
                          _vm._v(
                            "\n                            User:\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7" },
                          [
                            _vm.campaign.user
                              ? [
                                  _c(
                                    "span",
                                    { staticClass: "dn_item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "User Dialog",
                                              params: {
                                                user_id: _vm.campaign.user_id,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-dark-greenish _400",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    _vm.getUserName(
                                                      _vm.getUser(
                                                        _vm.campaign.user_id
                                                      )
                                                    )
                                                  ) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "Suggested Next Steps" } }, [
                _vm.campaign.is_fax
                  ? _c("div", { staticClass: "row p-2" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("span", { staticClass: "break-word" }, [
                          _vm._v("Send a fax to: "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm._f("fixPhone")(_vm.campaign.incoming_number)
                              )
                            ),
                          ]),
                          _vm._v(
                            " and make sure you receive the fax in your dashboard."
                          ),
                        ]),
                      ]),
                    ])
                  : _c("div", { staticClass: "row p-2" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("span", { staticClass: "break-word" }, [
                          _vm._v(
                            "Give yourself a call :) Call the phone number: "
                          ),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm._f("fixPhone")(_vm.campaign.incoming_number)
                              )
                            ),
                          ]),
                          _vm._v(
                            " and make sure it rings you on the other end."
                          ),
                        ]),
                      ]),
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "row p-2" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("span", [_vm._v("Checkout the Activity tab:")]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Line Activity",
                              params: { campaign_id: _vm.campaign.id },
                            },
                            target: "_blank",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "text-dark-greenish",
                              attrs: {
                                type: "text",
                                "data-testid": "activity-dashboard-button",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    Go to Activity Dashboard\n                                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.campaign.is_fax
                        ? _c("span", [
                            _vm._v(
                              ". As soon as your calls rake in, we will fill this page up."
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "el-button",
            {
              attrs: { "data-testid": "close-button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("\n                Close\n            ")]
          ),
          _vm._v(" "),
          _vm.hasPermissionTo("update campaign")
            ? _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Line Settings",
                      params: { campaign_id: _vm.campaign.id },
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        "data-testid": "setup-line-button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Set up your line\n                "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }