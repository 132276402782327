var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("create campaign")
    ? _c(
        "div",
        { attrs: { "data-testid": "line-creator-wrapper" } },
        [
          _vm.use_old_wizard
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.dialogVisible,
                    "before-close": _vm.beforeCloseModal,
                    top: "10vh",
                    "append-to-body": "",
                    "custom-class": "wizard-dialog dialog-no-border",
                    "data-testid": "line-creator-dialog",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                    close: _vm.closeModal,
                  },
                },
                [
                  _c("campaign-wizard", {
                    key: _vm.prop_counter,
                    attrs: {
                      is_fax: _vm.is_fax,
                      "data-testid": "campaign-wizard",
                    },
                    on: { created: _vm.campaignCreated },
                  }),
                ],
                1
              )
            : _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.dialogVisible_new,
                    "before-close": _vm.beforeCloseModal,
                    title: _vm.is_fax ? "New Fax" : "New Line",
                    width: "70vw",
                    top: "4vh",
                    "append-to-body": "",
                    "custom-class": "wizard-dialog dialog-no-border",
                    "data-testid": "line-creator-dialog",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible_new = $event
                    },
                    close: _vm.closeModal,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      attrs: {
                        slot: "title",
                        "data-testid": "new-wizard-title",
                      },
                      slot: "title",
                    },
                    [
                      _c("h5", { staticClass: "mb-0" }, [
                        _c("i", { staticClass: "fas fa-hat-wizard" }),
                        _vm._v(
                          "\n                New " +
                            _vm._s(_vm.is_fax ? "Fax" : "Line") +
                            " Wizard\n            "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("line-wizard", {
                    key: _vm.prop_counter,
                    attrs: { is_fax: _vm.is_fax, "data-testid": "line-wizard" },
                    on: { created: _vm.campaignCreated },
                  }),
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.restrictionMessage,
                top: "10vh",
                title: "New Line",
                "append-to-body": "",
                "custom-class": "wizard-dialog dialog-no-border",
                "data-testid": "restriction-message-dialog",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.restrictionMessage = $event
                },
                close: _vm.closeRestrictionMessage,
              },
            },
            [
              _c("span", [
                _vm._v(
                  " You can only add up to " +
                    _vm._s(_vm.kycLimitOfLines) +
                    " lines "
                ),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { "data-testid": "close-restriction-message-btn" },
                      on: {
                        click: function ($event) {
                          _vm.restrictionMessage = false
                        },
                      },
                    },
                    [_vm._v("\n                       Close\n            ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm._t("trigger", function () {
            return [
              !_vm.enabledToAddLines()
                ? _c(
                    "el-popover",
                    {
                      ref: "create-line-popover",
                      attrs: {
                        placement: "top",
                        width: "200",
                        "data-testid": "create-line-popover",
                        "popper-class": "btn-primary",
                        trigger: "hover",
                      },
                    },
                    [
                      _c("custom-message-display", {
                        attrs: { config: _vm.customMessage("line.create") },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.enabledToAddLines()
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "popover",
                          rawName: "v-popover:create-line-popover",
                          arg: "create-line-popover",
                        },
                      ],
                      staticClass: "btn btn-md rounded",
                      class: _vm.is_fax ? "orangish" : "greenish",
                      attrs: {
                        disabled: "",
                        "data-testid": "create-line-btn-disabled",
                      },
                      on: { click: _vm.openModal },
                    },
                    [
                      _c("i", {
                        staticClass: "fa",
                        class: _vm.is_fax ? "fa-fax" : "fa-plus",
                      }),
                      _vm._v(" "),
                      _vm.is_fax
                        ? _c("span", [
                            _vm._v("\n                Add Fax\n            "),
                          ])
                        : _c("span", [
                            _vm._v("\n                Add Line\n            "),
                          ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.enabledToAddLines()
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-md rounded",
                      class: _vm.is_fax ? "orangish" : "greenish",
                      attrs: { "data-testid": "create-line-btn" },
                      on: { click: _vm.openModal },
                    },
                    [
                      _c("i", {
                        staticClass: "fa",
                        class: _vm.is_fax ? "fa-fax" : "fa-plus",
                      }),
                      _vm._v(" "),
                      _vm.is_fax
                        ? _c("span", [
                            _vm._v("\n                Add Fax\n            "),
                          ])
                        : _c("span", [
                            _vm._v("\n                Add Line\n            "),
                          ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }