<template>
    <el-popover width="400"
                trigger="click"
                class="p-0"
                popper-class="variable-popover"
                :placement="popoverPlacement"
                @show="popoverShow"
                @hide="popoverHide">
        <div class="row">
            <div class="col-12">
                <contact-lists-selector v-model="selected_lists"
                                        :user_id="current_user_id"
                                        :module_type="ContactListModuleTypes.PUBLIC_CONTACT_LIST"
                                        :multiple="true"
                                        :value="selected_lists"
                                        :list_type="ContactListTypes.STATIC_LIST"
                                        @change="emitSelectedLists"/>
            </div>
        </div>
        <el-button id="tags-popover-trigger"
                   slot="reference"
                   size="mini"
                   circle
                   :class="variableBtnClass"
                   :disabled="disabled">
            <i :class="'aloai-fa-btn-custom ' + icon"></i>
        </el-button>
    </el-popover>
</template>

<script>
import ContactListsSelector from '../../contact-lists-selector'
import * as ContactListModuleTypes from '../../../constants/contact-list-module-types'
import * as ContactListTypes from '../../../constants/contact-list-types'

export default {
    components: {
        ContactListsSelector
    },

    props: {
        disabled: {
            required: false,
            default: false
        },
        popoverPlacement: {
            required: false,
            default: "right-start"
        },
        icon: {
            required: false,
            default: "fa fa-list"
        },
        variableBtnClass: {
            required: false,
            default: "border-0 bg-transparent"
        },
    },

    data() {
        return {
            selected_lists: [],	
            ContactListModuleTypes,
            ContactListTypes,
            current_user_id: +localStorage.getItem('current_user_id'),
        }
    },

    methods: {
        popoverShow() {
            if (this.$refs[('lists_dropdown')]) {
                this.$refs[('lists_dropdown')].focusToSelector()
            }
        },

        popoverHide() {
            this.selected_lists = []
        },

        emitSelectedLists(selectedIds, contactList) {
            this.$emit('lists-selected', selectedIds, contactList)
        }
    },
}
</script>
