import * as Events from '../../../constants/webrtc-events'

export default class Call {
    constructor(device, call) {
        this.device = device
        this._call = call
        this.call_sid = call.parameters.CallSid
        this.call_from = call.parameters.From
        this.call_direction = call.call_direction
        this._callbacks = {
            'accept': [],
            'cancel': [],
            'disconnect': [],
            'error': [],
            'mute': [],
            'reconnecting': [],
            'reconnected': [],
            'reject': [],
            'ringing': [],
            'sample': [],
            'volume': [],
            'warning': [],
            'warning-cleared': [],
        }
        this._initEvents()
    }

    _executeCallback(cb_name, args = []) {
        if (!this._callbacks[cb_name]) {
            this._callbacks[cb_name] = []
        }

        this._callbacks[cb_name].forEach(cb => {
            cb.apply(null, args)
        })
    }

    on(event, handler) {
        this._callbacks[event].push(handler)
    }

    _initEvents() {
        this._call.on(Events.CALL_ACCEPT, (call) => {
            // for outbound calls, there might not be a SID assigned first,
            // need to update the call object and SID
            // @see https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#callparameters
            this._call = call
            this.call_sid = call.parameters.CallSid
            this._executeCallback(Events.CALL_ACCEPT, [this])
        })
        this._call.on(Events.CALL_CANCEL, () => {
            this._executeCallback(Events.CALL_CANCEL)
        })
        this._call.on(Events.CALL_DISCONNECT, (call) => {
            this._executeCallback(Events.CALL_DISCONNECT, [this])
        })
        this._call.on(Events.CALL_ERROR, (error) => {
            this._executeCallback(Events.CALL_ERROR, [error])
        })
        this._call.on(Events.CALL_MUTE, (is_mute, call) => {
            this._executeCallback(Events.CALL_MUTE, [is_mute, this])
        })
        this._call.on(Events.CALL_RECONNECTING, (error) => {
            this._executeCallback(Events.CALL_RECONNECTING, [error])
        })
        this._call.on(Events.CALL_RECONNECTED, () => {
            this._executeCallback(Events.CALL_RECONNECTED)
        })
        this._call.on(Events.CALL_REJECT, () => {
            this._executeCallback(Events.CALL_REJECT)
        })
        this._call.on(Events.CALL_RINGING, (has_early_media) => {
            this._executeCallback(Events.CALL_RINGING, [has_early_media])
        })
        this._call.on(Events.CALL_SAMPLE, (rtc_sample) => {
            this._executeCallback(Events.CALL_SAMPLE, [rtc_sample])
        })
        this._call.on(Events.CALL_VOLUME, (input_volume, output_volume) => {
            this._executeCallback(Events.CALL_VOLUME, [input_volume, output_volume])
        })
        this._call.on(Events.CALL_WARNING, (warning_name, warning_data) => {
            this._executeCallback(Events.CALL_WARNING, [warning_name, warning_data])
        })
        this._call.on(Events.CALL_WARNING_CLEARED, (warning_name) => {
            this._executeCallback(Events.CALL_WARNING_CLEARED, [warning_name])
        })
    }

    isMuted () {
        return this._call.isMuted()
    }

    status() {
        return this._call.status()
    }

    accept() {
        this._call.accept()
    }

    hangup() {
        this._call.disconnect()
    }

    reject() {
        this._call.reject()
    }

    sendDigits(digit) {
        this._call.sendDigits(digit)
    }

    mute(mute) {
        this._call.mute(mute)
    }
}
