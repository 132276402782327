<template>
    <div>
        <div class="vertical-center text-left mb-4">
			<span v-if="filter.from_date"
                  class="call-log-head">
				Agent Status from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
			</span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
				Agent Status
                <strong>All Time</strong>
			</span>
        </div>
        <div class="placeholder text-center d-flex align-items-center justify-content-center"
             :class="{ blink: loading }"
             v-if="loading || campaigns.length == 0"
             style="height: 450px">
            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div style="height: 450px"
             v-else>
            <graph-data-switch :series="options.series"
                               :type="type">
                <highcharts v-bind:id="graph_id"
                            v-show="is_done && options.series.length > 0"
                            :options="options"
                            style="height: 450px"
                            ref="highchart">
                </highcharts>
                <div class="el-table__empty-block"
                     v-show="is_done && !options.series.length">
                    <span class="el-table__empty-text"
                          style="color: #606266;">
                        No Data
                    </span>
                </div>
            </graph-data-switch>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {graph_mixin, report_mixin} from '../mixins'

    export default {
        props: {
            stacked: {
                type: Boolean,
                default: false
            },
            report_index: {
                default: 0
            },
            type: {}
        },

        mixins: [report_mixin, graph_mixin],

        data() {
            return {
                auth: auth,
                loading: false,
                axisLabels: [],
                graph_id: 'reports-agent-status-graph',
                report_type: 'status_v_agent',
                options: {
                    chart: {
                        type: 'column',
                    },
                    plotOptions: {
                        column: {
                            stacking: null,
                            dataLabels: {
                                enabled: false,
                                formatter: function () {
                                    if (this.y != 0) {
                                        return this.y
                                    }
                                },
                                crop: false,
                                style: {
                                    fontSize: '14px'
                                }
                            }
                        }
                    },
                    xAxis: {
                        type: "category"
                    },
                    yAxis: {
                        visible: false,
                    },
                    tooltip: {
                        headerFormat: "",
                        pointFormatter: function () {
                            // convert float hour to hh:mm
                            let decimalTime = parseFloat(this.y)
                            decimalTime = decimalTime * 60 * 60
                            let hours = Math.floor((decimalTime / (60 * 60)))
                            decimalTime = decimalTime - (hours * 60 * 60)
                            let minutes = Math.floor((decimalTime / 60))
                            decimalTime = decimalTime - (minutes * 60)
                            let seconds = Math.floor(decimalTime)
                            if (hours < 10) {
                                hours = "0" + hours
                            }
                            if (minutes < 10) {
                                minutes = "0" + minutes
                            }
                            if (seconds < 10) {
                                seconds = "0" + seconds
                            }
                            const formattedTime = "" + hours + ":" + minutes + ":" + seconds
                            let toReturn = ((this.series.name != 'All') ? 'Agent <b>' + this.series.name + '</b> has' : 'All Agents have')
                            toReturn += ' been on <b>' + this.name + '</b> status<br> for <b>' + formattedTime


                            return toReturn
                        },
                        split: false,
                        enabled: true
                    },
                }
            }
        },

        mounted() {
            this.setIsStacked()
            this.getCommunications()
        },

        methods: {
            generateGraphData: function () {
                // setup the data attrs
                let data_series = []
                let seriesLabels = []
                let seriesNames = []

                //get distinct chart series values
                let keys = Object.keys(this.aggregated_counts)
                for (let index in keys) {
                    let campaign_data = this.aggregated_counts[keys[index]]

                    //do not include empty data
                    if (Object.keys(campaign_data.data).length > 0) {
                        for (let key in campaign_data.data) {
                            if (seriesNames.indexOf(campaign_data.data[key].name) < 0) {
                                seriesLabels.push({
                                    name: campaign_data.data[key].name,
                                    label: campaign_data.data[key].label,
                                    color: campaign_data.data[key].color,
                                })
                                seriesNames.push(campaign_data.data[key].name)
                            }
                        }
                    }
                }

                this.aggregated_counts.forEach((number_data, index) => {
                    //do not include empty data
                    if (Object.keys(number_data.data).length > 0) {
                        let campaigns = []
                        let labelValue = '',
                            yVal = 0
                        seriesLabels.forEach(axisLabel => {
                            labelValue = number_data.name
                            yVal = parseFloat(number_data.data[axisLabel.label].count)

                            campaigns.push({
                                name: axisLabel.name,
                                y: yVal
                            })
                        })

                        //assign statuses to chart series
                        data_series.push({
                            name: number_data.name,
                            data: campaigns
                        })
                    }
                })

                this.axisLabels = seriesNames

                //no data flag
                if (data_series.length) {
                    this.options.series = data_series
                } else {
                    this.options.series = []
                }

                this.$emit('reportLoaded', this.report_index)
            },

            setIsStacked: function () {
                if (!this.stacked) {
                    this.options.plotOptions.column.stacking = null
                } else {
                    this.options.plotOptions.column.stacking = 'normal'
                }
            }
        },

        watch: {
            stacked: {
                handler: function (newValue, oldValue) {
                    this.setIsStacked()
                    this.getCommunications()
                }
            },
        }
    }
</script>
