var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-body messenger-container",
      style: { height: "100vh" },
      attrs: { id: "view" },
    },
    [_c("iframe", { attrs: { frameborder: "0", src: _vm.source } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }