var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.active.includes(_vm.name)
    ? _c(
        "div",
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "Integration connection is working",
                placement: "top",
              },
            },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("strong", [_vm._v("Test Connection")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                This will check your if your connection to " +
                      _vm._s(_vm.name) +
                      " still works.\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.testing, plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.checkStatus()
                    },
                  },
                },
                [
                  _vm.testing ? _c("span", [_vm._v("Checking...")]) : _vm._e(),
                  _vm._v(" "),
                  !_vm.testing
                    ? _c("span", [_vm._v("Check Connection")])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.testing
                    ? _c("i", { staticClass: "fa fa-plug mr-2" })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pt-2" }, [
            _c(
              "div",
              { staticClass: "d-flex text-black-lt text-xs" },
              [
                _c("div", { staticClass: "mr-2" }, [_vm._v("Status:")]),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "right" },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "content" }, slot: "content" },
                      [
                        _vm.card.connection_status ===
                        _vm.ConnectionStatus.UNKNOWN
                          ? [
                              _vm._v(
                                "\n                        Connection testing has not yet performed\n                    "
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.card.connection_status ===
                        _vm.ConnectionStatus.CONNECTED
                          ? [
                              _vm._v(
                                "\n                        Integration connection is working\n                    "
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.card.connection_status ===
                        _vm.ConnectionStatus.FAILED
                          ? [
                              _vm._v(
                                "\n                        Connection health check failed. Try to re-authenticate.\n                    "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.card.connection_status === _vm.ConnectionStatus.UNKNOWN
                      ? _c(
                          "div",
                          { staticClass: "text-muted font-weight-bold d-flex" },
                          [_c("span", [_vm._v("Unknown")])]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.connection_status ===
                    _vm.ConnectionStatus.CONNECTED
                      ? _c(
                          "div",
                          {
                            staticClass: "text-success font-weight-bold d-flex",
                          },
                          [_c("span", [_vm._v("Connected")])]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.connection_status === _vm.ConnectionStatus.FAILED
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-danger font-weight-bold d-flex pointer",
                            on: { click: _vm.toggleDialog },
                          },
                          [_c("u", [_vm._v("Failed")])]
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "30%",
                title: "Debug Connection Error",
                visible: _vm.dialog,
                modal: false,
              },
              on: { close: _vm.closeDialog },
            },
            [
              _c("div", { staticClass: "p-3 border" }, [
                _c("code", [
                  _vm._v(
                    "\n                " +
                      _vm._s(String(_vm.card.last_connection_error).trim()) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.closeDialog } }, [
                    _vm._v("Close"),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }