<template>
    <div class="app-body pb-5"
         id="view">
        <div class="container-fluid mb-2"
             id="container-dashboard">
            <div class="my-3">
                <div class="d-flex flex-row">
                    <el-button-group class="rounded">
                        <el-button type="success">
                            Outbound
                        </el-button>
                        <el-button plain
                                   @click="$router.push({name: 'Inbound Dashboard'})">
                            Inbound
                        </el-button>
                    </el-button-group>
                    <el-button class="ml-auto"
                               type="info">
                        Settings
                    </el-button>
                </div>
            </div>
            <div class="d-flex flex-row my-3 mt-4">
                <div class="d-flex flex-row">
                    <user-selector multiple
                                   placeholder="Users"
                                   collapse-tags
                                   class="button-select">
                    </user-selector>

                    <filter-attribution-user class="mr-2"></filter-attribution-user>
                    <filter-attribution-line class="mr-2"></filter-attribution-line>
                    <filter-attribution-ring-group class="mr-2"></filter-attribution-ring-group>
                    <filter-attribution-phone-number class="mr-2"></filter-attribution-phone-number>
                    <filter-attribution-sequence class="mr-2"></filter-attribution-sequence>
                    <filter-attribution-broadcast class="mr-2"></filter-attribution-broadcast>
                    <!--                    <filter-attribution-tag class="mr-2"></filter-attribution-tag>-->
                </div>
                <div class="ml-auto d-flex flex-row">
                    <date-range-selector :filter="filter"></date-range-selector>
                </div>
            </div>
            <outbound-dashboard/>
        </div>
    </div>
</template>

<script>
import auth from '../../auth'
import DashboardFilter from '../../filters/dashboard-filter'

import {acl_mixin} from '../../mixins'
import CampaignSelector from "../campaign-selector"
import OutboundDashboard from "./outbound"
import DateRangeSelector from "../utilities/date-range-selector"
import FilterAttributionUser from "./filters/filter-attribution-user"
import FilterAttributionLine from "./filters/filter-attribution-line"
import FilterAttributionRingGroup from "./filters/filter-attribution-ring-group"
import FilterAttributionSequence from "./filters/filter-attribution-sequence"
import FilterAttributionBroadcast from "./filters/filter-attribution-broadcast"
import FilterAttributionTag from "./filters/filter-attribution-tag"
import FilterAttributionPhoneNumber from "./filters/filter-attribution-phone-number";
import UserSelector from "../user-selector"

export default {
    name: "admin-dashboard",
    components: {
        UserSelector,
        FilterAttributionPhoneNumber,
        FilterAttributionUser,
        FilterAttributionLine,
        FilterAttributionRingGroup,
        FilterAttributionSequence,
        FilterAttributionBroadcast,
        FilterAttributionTag,
        DateRangeSelector,
        OutboundDashboard,
        CampaignSelector,
    },

    mixins: [acl_mixin],

    data() {
        return {
            auth: auth,
            filter: DashboardFilter,
            env: null,
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
            screen: 'outbound',
        }
    },

    created() {
        this.getStatics()
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.setPageTitle('Admin Dashboard - ' + this.statics.name)
                this.loading_whitelabel = false
            }).catch(err => {
                this.setPageTitle('Admin Dashboard - Aloware')
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false
            })
        },

        changeScreen(screen) {
            this.screen = screen
        },
    },

    beforeRouteEnter(to, from, next) {
        auth.check().then((res) => {
            let allowed_company = [7, 47]

            if (!allowed_company.includes(res.data.user.company_id)) {
                next({name: 'Dashboard'})
            }

            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({name: 'Login', query: {redirect: to.fullPath}})
        })
    },

    beforeCreate() {
        axios.get('/build-info.json').then(res => {
            this.env = res.data.env
        }).catch(err => {
            console.log(err)
        })
    }
}
</script>
