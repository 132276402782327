var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "card p-3 h-100",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.metrics == {},
              expression: "metrics == {}",
            },
          ],
          staticClass: "pt-5",
        },
        [
          _c("div", { staticClass: "row mb-5" }, [
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("metric-widget", {
                  attrs: { label: "Sold", metric: _vm.metrics.simpsocial_sold },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("metric-widget", {
                  attrs: {
                    label: "Appointments Set",
                    description:
                      "Number of appointments set from this date range.",
                    metric: _vm.metrics.appointment_set,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("metric-widget", {
                  attrs: {
                    label: "Appointments Shown",
                    description:
                      "Number of appointments shown from this date range.",
                    metric: _vm.metrics.appointment_shown,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("metric-widget", {
                  attrs: {
                    label: "Appointments Canceled",
                    description:
                      "Number of appointments canceled from this date range.",
                    metric: _vm.metrics.appointment_canceled,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex mb-3" }, [
      _c("img", {
        staticClass: "mr-2",
        attrs: { src: "/assets/images/app-icons/new/appointment_icon.svg" },
      }),
      _vm._v(" "),
      _c("h6", { staticClass: "mb-0 align-self-center" }, [
        _vm._v("Appointments"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }