export const ANSWER_STATUSES = [
    {value: 'all', label: 'All'},
    {value: 'live', label: 'Live'},
    {value: 'answered', label: 'Answered'},
    {value: 'unanswered', label: 'Unanswered'},
    {value: 'missed', label: 'Missed'},
    {value: 'abandoned', label: 'Abandoned'},
    {value: 'voicemail', label: 'Voicemail'},
    {value: 'in-progress', label: 'In Progress'},
    {value: 'failed', label: 'Failed'},
    {value: 'queued', label: 'Queued'},
    {value: 'hold', label: 'Hold'},
    {value: 'deadend', label: 'Dead-end'}
]
