<template>
    <div class="card p-3 h-100"
         v-loading="loading">
        <div class="d-flex mb-3">
            <i class="fa fa-bullhorn text-greenish mr-2 align-self-center"></i>
            <h6 class="mb-0 align-self-center">Broadcasts</h6>
        </div>
        <div class="row">
            <div class="col-7">
                <div class="row">
                    <div class="container-outbound-connection-rate-widget mb-5 col-12">
                        <div class="label-connection-rate" style="transform: translateX(-50%); bottom: 0">
                            <metric-widget label="Engagement Rate"
                                           description=""
                                           :metric="metrics.engagement_rate">
                            </metric-widget>
                        </div>
                        <highcharts :options="options"
                                    ref="chart">
                        </highcharts>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <metric-widget label="Total Sent"
                                       description="Total delivered broadcast"
                                       :metric="metrics.total_sent">
                        </metric-widget>
                    </div>
                    <div class="col-6">
                        <metric-widget label="Total Failed"
                                       description="Total failed broadcast"
                                       :metric="metrics.total_failed">
                        </metric-widget>
                    </div>
                </div>
            </div>
            <div class="col-5 d-flex flex-column justify-content-center justify-content-around">
                <metric-widget label="Total"
                               description="Total Broadcast"
                               :metric="metrics.count">
                </metric-widget>
                <metric-widget label="Total Replied"
                               description="Total Reply Broadcast"
                               :metric="metrics.total_replied">
                </metric-widget>
                <metric-widget label="Total Unsubscribed"
                               description="Total unsubscribed broadcast"
                               :metric="metrics.total_unsubscribed">
                </metric-widget>
            </div>
        </div>
    </div>
</template>

<script>
import MetricWidget from "./metric-widget";
import DashboardFilter from "../../../filters/dashboard-filter"

export default {
    name: "broadcast-metrics-widget",
    components: {MetricWidget},
    props: {
        broadcast_metrics: {
            default: function() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false,
            metrics: {},
            broadcast_delivery_rate: 0,
            options: {
                exporting: {
                    enabled: false
                },
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    height: 200,
                    spacing: [0,0,0,0],
                    marginTop: 60,
                    marginBottom: -60
                },
                title: {
                    text: null,
                    enabled: false
                },
                tooltip: {
                    enabled: false,
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '65%'],
                        allowPointSelect: false,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            distance: -50,
                        },
                        size: '175%'
                    },
                    series: {
                        animation: {
                            duration: 0
                        }
                    }
                },
                series: [{
                    name: 'Rate',
                    colorByPoint: true,
                    data: [],
                    innerSize: '95%'
                }]
            }
        }
    },

    computed: {
        kpiFilters() {
            return DashboardFilter.kpiFilters()
        }
    },

    mounted() {
        this.loadBroadcastMetrics()
    },

    methods: {
        loadBroadcastMetrics() {
            this.loading = true
            axios.get('/api/v1/kpis/broadcast', {
                params: {
                    aggregates: [
                        1, // COUNT
                        2, // TOTAL_SENT
                        3, // TOTAL_FAILED
                        4, // TOTAL_REPLIED
                        5, // TOTAL_UNSUBSCRIBED
                        6 // ENGAGEMENT_RATE
                    ],
                    filters: DashboardFilter.get()
                }
            }).then(res => {
                this.loading = false
                this.setBroadcastMetrics(res.data)
            })
        },

        setBroadcastMetrics(metrics) {
            this.metrics = metrics
            this.options.series[0].data = [
                {
                    name: 'Sent',
                    y: parseInt(metrics.total_sent.current_value),
                    color: '#00bf4a'
                },
                {
                    name: 'Failed',
                    y: parseInt(metrics.total_failed.current_value),
                    color: '#e9e9e9'
                },
            ]
        }
    },

    watch: {
        kpiFilters() {
            this.loadBroadcastMetrics()
        }
    }
}
</script>
