var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wizard",
      attrs: { "data-testid": "campaign-wizard-wrapper" },
    },
    [
      _c(
        "div",
        {
          staticClass: "wizard-header",
          attrs: { "data-testid": "wizard-header" },
        },
        [
          _vm.success ? _c("h2", [_vm._v("Congratulations!")]) : _vm._e(),
          _vm._v(" "),
          !_vm.success && !_vm.is_fax
            ? _c("h2", [_vm._v("New Line")])
            : _vm._e(),
          _vm._v(" "),
          !_vm.success && _vm.is_fax ? _c("h2", [_vm._v("New Fax")]) : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-10 col-8" },
          [
            _c("el-progress", {
              staticStyle: { "margin-top": "4px" },
              attrs: {
                percentage: _vm.percentage,
                "show-text": false,
                "stroke-width": 9,
                "data-testid": "wizard-el-progress",
                status: "success",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2 col-4" }, [
          _c(
            "small",
            {
              staticClass: "text-muted",
              attrs: { "data-testid": "wizard-percentage" },
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.percentage) + "% ")]),
              _vm._v("\n                Complete\n            "),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.step == "start"
        ? _c(
            "div",
            { attrs: { id: "step_start", "data-testid": "wizard-steps" } },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-4" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 text-center" }, [
                      _c("img", {
                        staticClass: "w-300 pointer",
                        attrs: {
                          src: "/assets/images/Contact-Center.png",
                          "data-testid": "contact-center-image",
                        },
                        on: { click: _vm.setContactCenter },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              "data-testid": "contact-center-button",
                              plain: "",
                            },
                            on: { click: _vm.setContactCenter },
                          },
                          [
                            _vm._v(
                              "\n                            Contact Center\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 text-center" }, [
                      _c("img", {
                        staticClass: "w-300 pointer",
                        attrs: {
                          src: "/assets/images/Call-Tracking.png",
                          "data-testid": "call-tracking-image",
                        },
                        on: { click: _vm.setCallTracking },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "warning",
                              "data-testid": "call-tracking-button",
                              plain: "",
                            },
                            on: { click: _vm.setCallTracking },
                          },
                          [
                            _vm._v(
                              "\n                            Call Tracking\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 0
        ? _c(
            "div",
            { attrs: { id: "step_0", "data-testid": "wizard-step-0" } },
            [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-4" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 text-center" }, [
                      _c("img", {
                        staticClass: "w-200 r-2x _600 pointer",
                        attrs: {
                          src: "/assets/images/item-add-number-online.png",
                          "data-testid": "online-image",
                        },
                        on: { click: _vm.setOnline },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              "data-testid": "online-button",
                              plain: "",
                            },
                            on: { click: _vm.setOnline },
                          },
                          [
                            _vm._v(
                              "\n                            Yes, I'll use it online\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(2),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 text-center" }, [
                      _c("img", {
                        staticClass: "w-200 r-2x _600 pointer",
                        attrs: {
                          src: "/assets/images/item-add-number-offline.png",
                          "data-testid": "offline-image",
                        },
                        on: { click: _vm.setOffline },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              "data-testid": "set-offline-button",
                              plain: "",
                            },
                            on: { click: _vm.setOffline },
                          },
                          [
                            _vm._v(
                              "\n                            No, I'll use it offline\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(3),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-4" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "text-right mt-4" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "pull-left",
                          attrs: {
                            icon: "el-icon-arrow-left",
                            type: "info",
                            "data-testid": "back-button",
                          },
                          on: { click: _vm.backForm },
                        },
                        [
                          _vm._v(
                            "\n                        Back\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 1.2
        ? _c(
            "div",
            { attrs: { id: "step_1_2", "data-testid": "wizard-step-1-2" } },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-4" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 text-center" }, [
                      _c("img", {
                        staticClass: "w-200 r-2x _600 pointer",
                        attrs: {
                          src: "/assets/images/item-add-number-your-website.png",
                          "data-testid": "your-website-image",
                        },
                        on: { click: _vm.setOnlineOur },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              "data-testid": "your-website-button",
                              plain: "",
                            },
                            on: { click: _vm.setOnlineOur },
                          },
                          [
                            _vm._v(
                              "\n                            On my website\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(5),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 text-center" }, [
                      _c("img", {
                        staticClass: "w-200 r-2x _600 pointer",
                        attrs: {
                          src: "/assets/images/item-add-number-other-website.png",
                          "data-testid": "other-website-image",
                        },
                        on: { click: _vm.setOnlineOther },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              "data-testid": "other-website-button",
                              plain: "",
                            },
                            on: { click: _vm.setOnlineOther },
                          },
                          [
                            _vm._v(
                              "\n                            Somewhere else\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(6),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-right mt-4" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-left",
                        attrs: {
                          icon: "el-icon-arrow-left",
                          type: "info",
                          "data-testid": "back-button",
                        },
                        on: { click: _vm.backForm },
                      },
                      [_vm._v("\n                    Back\n                ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 1.5
        ? _c(
            "div",
            { attrs: { id: "step_1_5", "data-testid": "wizard-step-1-5" } },
            [
              _vm._m(7),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-4" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 text-center" }, [
                      _c(
                        "span",
                        {
                          staticClass: "w-200 r-2x _600 blue",
                          attrs: { "data-testid": "visitor-type-1-span" },
                          on: {
                            click: function ($event) {
                              return _vm.setOnlineType(1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Visitor\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              "data-testid": "visitor-type-1-button",
                              plain: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setOnlineType(1)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            Track visitor activity\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(8),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 text-center" }, [
                      _c(
                        "span",
                        {
                          staticClass: "w-200 r-2x _600 purple",
                          attrs: { "data-testid": "visitor-type-2-span" },
                          on: {
                            click: function ($event) {
                              return _vm.setOnlineType(2)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Campaign\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              "data-testid": "visitor-type-2-button",
                              plain: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setOnlineType(2)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            Track a marketing campaign\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(9),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-right mt-4" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-left",
                        attrs: {
                          "data-testid": "back-button",
                          icon: "el-icon-arrow-left",
                          type: "info",
                        },
                        on: { click: _vm.backForm },
                      },
                      [_vm._v("\n                    Back\n                ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 2
        ? _c(
            "div",
            { attrs: { id: "step_2", "data-testid": "wizard-step-2" } },
            [
              _c(
                "el-form",
                {
                  ref: "campaign_info",
                  attrs: {
                    "label-position": "top",
                    "label-width": "300px",
                    "data-testid": "campaign-info-form",
                    rules: _vm.rules_2,
                    model: _vm.campaign,
                  },
                },
                [
                  _vm.is_fax
                    ? [
                        _c("p", { staticClass: "form-head" }, [
                          _vm._v(
                            "\n                    Name\n                "
                          ),
                        ]),
                      ]
                    : [
                        _vm.campaign.type == _vm.TYPE_OFFLINE ||
                        (_vm.campaign.type == _vm.TYPE_ONLINE &&
                          (_vm.campaign.subtype ==
                            _vm.SUBTYPE_ONLINE_MARKETING ||
                            _vm.campaign.subtype ==
                              _vm.SUBTYPE_ONLINE_EXTERNAL))
                          ? _c("p", { staticClass: "form-head" }, [
                              _vm._v(
                                "\n                    Name, Ring Group and Call Routing\n                "
                              ),
                            ])
                          : _c("p", { staticClass: "form-head" }, [
                              _vm._v(
                                "\n                    Pool Name, Pool Size & Ring Group\n                "
                              ),
                            ]),
                      ],
                  _vm._v(" "),
                  _c("div", { staticClass: "dropdown-divider" }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "data-testid": "campaign-info-name-item",
                        label:
                          _vm.campaign.type == _vm.TYPE_ONLINE &&
                          _vm.campaign.subtype == _vm.SUBTYPE_ONLINE_VISITORS
                            ? "Pool Name"
                            : "Name",
                        prop: "name",
                      },
                    },
                    [
                      _vm.is_fax
                        ? _c("em", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n                    Example: Office Fax\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.is_fax
                        ? [
                            _vm.campaign.type == _vm.TYPE_OFFLINE &&
                            _vm.purpose == "Call Tracking"
                              ? _c("em", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "\n                        Example: Radio Ad\n                    "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.campaign.type == _vm.TYPE_OFFLINE &&
                            _vm.purpose == "Contact Center"
                              ? _c("em", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "\n                        Example: Local Support Number\n                    "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.campaign.type == _vm.TYPE_ONLINE &&
                            _vm.campaign.subtype == _vm.SUBTYPE_ONLINE_EXTERNAL
                              ? _c("em", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "\n                        Example: External\n                    "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.campaign.type == _vm.TYPE_ONLINE &&
                            _vm.campaign.subtype == _vm.SUBTYPE_ONLINE_VISITORS
                              ? _c("em", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "\n                        Example: Visitor Tracking\n                    "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.campaign.type == _vm.TYPE_ONLINE &&
                            _vm.campaign.subtype == _vm.SUBTYPE_ONLINE_MARKETING
                              ? _c("em", { staticClass: "text-muted" }, [
                                  _vm._v(
                                    "\n                        Example: Facebook referral\n                    "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-8 col-12" },
                          [
                            _c("el-input", {
                              attrs: {
                                "data-testid": "campaign-info-name-input",
                              },
                              on: { input: _vm.customPreValidateForm },
                              model: {
                                value: _vm.campaign.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.campaign, "name", $$v)
                                },
                                expression: "campaign.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-4" },
                          [
                            _c("el-popover", {
                              ref: "popover_name",
                              attrs: {
                                placement: "right-start",
                                title: "Line Name",
                                width: "200",
                                trigger: "hover",
                                "data-testid": "popover-line-name",
                                content:
                                  "Use a name that is short and easily identifiable in your reports.",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "popover",
                                    rawName: "v-popover:popover_name",
                                    arg: "popover_name",
                                  },
                                ],
                                staticClass: "form-hover-help",
                              },
                              [
                                _vm._v(
                                  "\n                            What should I name this?\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.campaign.type == _vm.TYPE_ONLINE &&
                  _vm.campaign.subtype == _vm.SUBTYPE_ONLINE_VISITORS
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Pool Size",
                            prop: "pool_size",
                            "data-testid": "campaign-info-pool-size-item",
                          },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-8 col-12 pt-2" },
                              [
                                _vm._v(
                                  "\n                        Add\n                        "
                                ),
                                _c("el-input-number", {
                                  attrs: {
                                    size: "mini",
                                    "data-testid":
                                      "campaign-info-pool-size-input",
                                    min: 1,
                                  },
                                  model: {
                                    value: _vm.campaign.pool_size,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.campaign, "pool_size", $$v)
                                    },
                                    expression: "campaign.pool_size",
                                  },
                                }),
                                _vm._v(
                                  "\n                        new numbers.\n                    "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-4 col-12" },
                              [
                                _c("el-popover", {
                                  ref: "popover_size",
                                  attrs: {
                                    placement: "right-start",
                                    title: "Line Pool Size",
                                    width: "200",
                                    trigger: "hover",
                                    "data-testid": "popover-line-pool-size",
                                    content:
                                      "Each visitor will be assigned a unique number for 30 minutes. If you expect a higher visit rate, choose a larger pool size.",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "popover",
                                        rawName: "v-popover:popover_size",
                                        arg: "popover_size",
                                      },
                                    ],
                                    staticClass: "form-hover-help",
                                    attrs: { "data-testid": "pool-size-help" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            How do I select a pool size?\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.is_fax
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "call_router_behavior",
                            "data-testid":
                              "campaign-info-call-router-behavior-item",
                            label: "Call Routing",
                          },
                        },
                        [
                          _c("el-popover", {
                            ref: "popover_fast_forward",
                            attrs: {
                              placement: "right-start",
                              title: _vm._f("humanReadableCallRouterBehavior")(
                                _vm.CampaignCallRouterBehavior
                                  .CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD
                              ),
                              width: "400",
                              trigger: "hover",
                              "data-testid": "popover-fast-forward",
                              content:
                                "Connect calls without a queue (best for call tracking).",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-popover", {
                            ref: "popover_dead_end",
                            attrs: {
                              placement: "right-start",
                              title: _vm._f("humanReadableCallRouterBehavior")(
                                _vm.CampaignCallRouterBehavior
                                  .CALL_ROUTER_BEHAVIOR_MODE_DEAD_END
                              ),
                              width: "400",
                              trigger: "hover",
                              "data-testid": "popover-dead-end",
                              content:
                                "Do not connect calls & hangup after playing greeting (not recommended).",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                "data-testid":
                                  "campaign-info-call-router-behavior-radio",
                                size: "small",
                              },
                              model: {
                                value: _vm.campaign.call_router_behavior,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.campaign,
                                    "call_router_behavior",
                                    $$v
                                  )
                                },
                                expression: "campaign.call_router_behavior",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                {
                                  directives: [
                                    {
                                      name: "popover",
                                      rawName: "v-popover:popover_fast_forward",
                                      arg: "popover_fast_forward",
                                    },
                                  ],
                                  attrs: {
                                    label:
                                      _vm.CampaignCallRouterBehavior
                                        .CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD,
                                    "data-testid": "fast-forward-radio",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f(
                                          "humanReadableCallRouterBehavior"
                                        )(
                                          _vm.CampaignCallRouterBehavior
                                            .CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                {
                                  directives: [
                                    {
                                      name: "popover",
                                      rawName: "v-popover:popover_dead_end",
                                      arg: "popover_dead_end",
                                    },
                                  ],
                                  attrs: {
                                    label:
                                      _vm.CampaignCallRouterBehavior
                                        .CALL_ROUTER_BEHAVIOR_MODE_DEAD_END,
                                    "data-testid": "dead-end-radio",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f(
                                          "humanReadableCallRouterBehavior"
                                        )(
                                          _vm.CampaignCallRouterBehavior
                                            .CALL_ROUTER_BEHAVIOR_MODE_DEAD_END
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.campaign.call_router_behavior &&
                  _vm.campaign.call_router_behavior !==
                    _vm.CampaignCallRouterBehavior
                      .CALL_ROUTER_BEHAVIOR_MODE_DEAD_END
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: "Ring Group",
                            prop: "ring_group_id",
                            "data-testid": "campaign-info-ring-group-item",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.hide_add,
                                  expression: "!hide_add",
                                },
                              ],
                              staticClass: "row",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-8 col-12" },
                                [
                                  _vm.hasPermissionTo("list ring group")
                                    ? _c("ring-group-selector", {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          "data-testid":
                                            "campaign-info-ring-group-selector",
                                          default_first: true,
                                        },
                                        on: { change: _vm.changeRingGroup },
                                        model: {
                                          value: _vm.campaign.ring_group_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.campaign,
                                              "ring_group_id",
                                              $$v
                                            )
                                          },
                                          expression: "campaign.ring_group_id",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-4 col-12" },
                                [
                                  _c("el-popover", {
                                    ref: "popover_ring_group_id",
                                    attrs: {
                                      placement: "right-start",
                                      title: "Ring Group",
                                      width: "200",
                                      trigger: "hover",
                                      "data-testid": "popover-ring-group",
                                      content:
                                        "Where to deliver the call when a customer rings.",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "popover",
                                          rawName:
                                            "v-popover:popover_ring_group_id",
                                          arg: "popover_ring_group_id",
                                        },
                                      ],
                                      staticClass: "form-hover-help",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Which ring group do I use?\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.campaign.call_router_behavior &&
                  _vm.campaign.call_router_behavior !==
                    _vm.CampaignCallRouterBehavior
                      .CALL_ROUTER_BEHAVIOR_MODE_DEAD_END
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Dial Mode",
                            prop: "dial_mode",
                            "data-testid": "campaign-info-dial-mode-item",
                          },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-8 col-12" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: {
                                      size: "small",
                                      "data-testid":
                                        "campaign-info-dial-mode-radio",
                                    },
                                    model: {
                                      value: _vm.campaign.dial_mode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.campaign, "dial_mode", $$v)
                                      },
                                      expression: "campaign.dial_mode",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      '\n                                        class="no-select mt-2">\n                            '
                                    ),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: _vm.DIAL_MODE_SIMUL } },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm._f("humanReadableDialMode")(
                                                _vm.DIAL_MODE_SIMUL
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: _vm.DIAL_MODE_RAND } },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm._f("humanReadableDialMode")(
                                                _vm.DIAL_MODE_RAND
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: _vm.DIAL_MODE_ROUND } },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm._f("humanReadableDialMode")(
                                                _vm.DIAL_MODE_ROUND
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio-button",
                                      {
                                        attrs: {
                                          label:
                                            _vm.DIAL_MODE_LONGEST_AVAILABLE,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm._f("humanReadableDialMode")(
                                                _vm.DIAL_MODE_LONGEST_AVAILABLE
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c("el-popover", {
                                  ref: "popover_dial_mode",
                                  attrs: {
                                    placement: "right-start",
                                    title: "Dial Modes",
                                    width: "200",
                                    trigger: "hover",
                                    "data-testid": "popover-dial-mode",
                                    content:
                                      "Choose how your calls are routed to the users in your ring group. You can change this settings after creating your campaign.",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "popover",
                                        rawName: "v-popover:popover_dial_mode",
                                        arg: "popover_dial_mode",
                                      },
                                    ],
                                    staticClass: "form-hover-help",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            What are dial modes?\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-right mt-4" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "pull-left",
                          attrs: {
                            icon: "el-icon-arrow-left",
                            type: "info",
                            "data-testid": "back-button",
                          },
                          on: { click: _vm.backForm },
                        },
                        [_vm._v("\n                    Back\n                ")]
                      ),
                      _vm._v(" "),
                      _vm.campaign.type == _vm.TYPE_OFFLINE ||
                      _vm.campaign.subtype == _vm.SUBTYPE_ONLINE_EXTERNAL
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                "data-testid": "next-phone-button",
                                disabled:
                                  !_vm.validated || !_vm.enabledToAddLines(),
                              },
                              on: { click: _vm.submitForm },
                            },
                            [
                              _vm._v(
                                "\n                    Next: Select a Phone Number\n                    "
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-right el-icon-right",
                              }),
                            ]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                "data-testid": "next-tracking-button",
                                disabled:
                                  !_vm.validated || !_vm.enabledToAddLines(),
                              },
                              on: { click: _vm.submitForm },
                            },
                            [
                              _vm._v(
                                "\n                    Next: Select Tracking Sources\n                    "
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-right el-icon-right",
                              }),
                            ]
                          ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 2.5
        ? _c(
            "div",
            { attrs: { id: "step_2_5", "data-testid": "wizard-step-2-5" } },
            [
              _c("p", { staticClass: "form-head" }, [
                _vm._v("Which visitors do you want to track?"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-divider" }),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "campaign_tracking",
                  attrs: {
                    "label-position": "top",
                    "label-width": "300px",
                    "data-testid": "campaign-tracking-form",
                    rules: _vm.rules_2_5,
                    model: _vm.campaign,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "visitor_tracking_mode",
                        "data-testid": "tracking-phone-item",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "row mt-2",
                          attrs: { "data-testid": "always-swap" },
                        },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("p", { staticClass: "text" }, [
                              _c("b", [_vm._v("Always Swap")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-8 col-12" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.campaign.visitor_tracking_mode,
                                  expression: "campaign.visitor_tracking_mode",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                value: "all",
                                id: "all",
                                "data-testid": "tracking-mode-all-input",
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.campaign.visitor_tracking_mode,
                                  "all"
                                ),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    return _vm.$set(
                                      _vm.campaign,
                                      "visitor_tracking_mode",
                                      "all"
                                    )
                                  },
                                  _vm.changeCampaignTracking,
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "all" } }, [
                              _vm._v(
                                "Show the tracking number to all visitors, regardless of source"
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row mt-2",
                          attrs: { "data-testid": "direct-visit" },
                        },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("p", { staticClass: "text" }, [
                              _c("b", [_vm._v("Direct Visit")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-8 col-12" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.campaign.visitor_tracking_mode,
                                  expression: "campaign.visitor_tracking_mode",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                value: "direct_only",
                                id: "direct_only",
                                "data-testid": "tracking-mode-direct-input",
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.campaign.visitor_tracking_mode,
                                  "direct_only"
                                ),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    return _vm.$set(
                                      _vm.campaign,
                                      "visitor_tracking_mode",
                                      "direct_only"
                                    )
                                  },
                                  _vm.changeCampaignTracking,
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "direct_only" } }, [
                              _vm._v(
                                "Show the tracking number to all direct visitors"
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row mt-2",
                          attrs: { "data-testid": "social-media" },
                        },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("p", { staticClass: "text mt-wizard" }, [
                              _c("b", [_vm._v("Social Media")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-8 col-12" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.campaign.visitor_tracking_mode,
                                    expression:
                                      "campaign.visitor_tracking_mode",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "sm_only",
                                  id: "sm_only",
                                  "data-testid": "tracking-mode-sm-input",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.campaign.visitor_tracking_mode,
                                    "sm_only"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.campaign,
                                        "visitor_tracking_mode",
                                        "sm_only"
                                      )
                                    },
                                    _vm.changeCampaignTracking,
                                  ],
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "sm_only" } }, [
                                _vm._v("Visitors from"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline",
                                  attrs: {
                                    prop: "visitor_tracking_options.sm_only",
                                    "data-testid":
                                      "tracking-mode-sm-options-item",
                                  },
                                },
                                [
                                  _vm.campaign.visitor_tracking_mode !=
                                  "sm_only"
                                    ? _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            placeholder: "Select",
                                            "data-testid":
                                              "tracking-mode-sm-options-select",
                                            disabled:
                                              _vm.campaign
                                                .visitor_tracking_mode !=
                                              "sm_only",
                                          },
                                          model: {
                                            value: _vm.sm_only,
                                            callback: function ($$v) {
                                              _vm.sm_only = $$v
                                            },
                                            expression: "sm_only",
                                          },
                                        },
                                        _vm._l(
                                          _vm.social_media_sites,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item,
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            placeholder: "Select",
                                            "data-testid":
                                              "tracking-mode-sm-options-select",
                                            disabled:
                                              _vm.campaign
                                                .visitor_tracking_mode !=
                                              "sm_only",
                                          },
                                          on: {
                                            change: _vm.customPreValidateForm,
                                          },
                                          model: {
                                            value:
                                              _vm.campaign
                                                .visitor_tracking_options,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.campaign,
                                                "visitor_tracking_options",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "campaign.visitor_tracking_options",
                                          },
                                        },
                                        _vm._l(
                                          _vm.social_media_sites,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item,
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row mt-3",
                          attrs: { "data-testid": "paid-search" },
                        },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("p", { staticClass: "text mt-wizard" }, [
                              _c("b", [_vm._v("Paid Search")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-8 col-12" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.campaign.visitor_tracking_mode,
                                    expression:
                                      "campaign.visitor_tracking_mode",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "ppc_only",
                                  id: "ppc_only",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.campaign.visitor_tracking_mode,
                                    "ppc_only"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.campaign,
                                        "visitor_tracking_mode",
                                        "ppc_only"
                                      )
                                    },
                                    _vm.changeCampaignTracking,
                                  ],
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "ppc_only" } }, [
                                _vm._v("Visitors from"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline",
                                  attrs: {
                                    prop: "visitor_tracking_options.ppc_only",
                                    "data-testid":
                                      "tracking-mode-ppc-options-item",
                                  },
                                },
                                [
                                  _vm.campaign.visitor_tracking_mode !=
                                  "ppc_only"
                                    ? _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            placeholder: "Select",
                                            "data-testid":
                                              "tracking-mode-ppc-options-select",
                                            disabled:
                                              _vm.campaign
                                                .visitor_tracking_mode !=
                                              "ppc_only",
                                          },
                                          model: {
                                            value: _vm.ppc_only,
                                            callback: function ($$v) {
                                              _vm.ppc_only = $$v
                                            },
                                            expression: "ppc_only",
                                          },
                                        },
                                        _vm._l(
                                          _vm.search_engines,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: _vm._f("ucfirst")(item),
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            placeholder: "Select",
                                            "data-testid":
                                              "tracking-mode-ppc-options-select",
                                            disabled:
                                              _vm.campaign
                                                .visitor_tracking_mode !=
                                              "ppc_only",
                                          },
                                          on: {
                                            change: _vm.customPreValidateForm,
                                          },
                                          model: {
                                            value:
                                              _vm.campaign
                                                .visitor_tracking_options,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.campaign,
                                                "visitor_tracking_options",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "campaign.visitor_tracking_options",
                                          },
                                        },
                                        _vm._l(
                                          _vm.search_engines,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: _vm._f("ucfirst")(item),
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row mt-3",
                          attrs: { "data-testid": "organic-search" },
                        },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("p", { staticClass: "text mt-wizard" }, [
                              _c("b", [_vm._v("Organic Search")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-8 col-12" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.campaign.visitor_tracking_mode,
                                    expression:
                                      "campaign.visitor_tracking_mode",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "organic_only",
                                  id: "organic_only",
                                  "data-testid": "tracking-mode-organic-input",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.campaign.visitor_tracking_mode,
                                    "organic_only"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.campaign,
                                        "visitor_tracking_mode",
                                        "organic_only"
                                      )
                                    },
                                    _vm.changeCampaignTracking,
                                  ],
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "organic_only" } }, [
                                _vm._v("Visitors from"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline",
                                  attrs: {
                                    prop: "visitor_tracking_options.organic_only",
                                    "data-testid":
                                      "tracking-mode-organic-options-item",
                                  },
                                },
                                [
                                  _vm.campaign.visitor_tracking_mode !=
                                  "organic_only"
                                    ? _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            placeholder: "Select",
                                            "data-testid":
                                              "tracking-mode-organic-options-select",
                                            disabled:
                                              _vm.campaign
                                                .visitor_tracking_mode !=
                                              "organic_only",
                                          },
                                          model: {
                                            value: _vm.organic_only,
                                            callback: function ($$v) {
                                              _vm.organic_only = $$v
                                            },
                                            expression: "organic_only",
                                          },
                                        },
                                        _vm._l(
                                          _vm.search_engines,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: _vm._f("ucfirst")(item),
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            placeholder: "Select",
                                            "data-testid":
                                              "tracking-mode-organic-options-select",
                                            disabled:
                                              _vm.campaign
                                                .visitor_tracking_mode !=
                                              "organic_only",
                                          },
                                          on: {
                                            change: _vm.customPreValidateForm,
                                          },
                                          model: {
                                            value:
                                              _vm.campaign
                                                .visitor_tracking_options,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.campaign,
                                                "visitor_tracking_options",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "campaign.visitor_tracking_options",
                                          },
                                        },
                                        _vm._l(
                                          _vm.search_engines,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: _vm._f("ucfirst")(item),
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row mt-3",
                          attrs: { "data-testid": "web-referrals" },
                        },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("p", { staticClass: "text mt-wizard" }, [
                              _c("b", [_vm._v("Web Referrals")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-8 col-12" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.campaign.visitor_tracking_mode,
                                    expression:
                                      "campaign.visitor_tracking_mode",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "ref_only",
                                  id: "ref_only",
                                  "data-testid": "tracking-mode-ref-input",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.campaign.visitor_tracking_mode,
                                    "ref_only"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.campaign,
                                        "visitor_tracking_mode",
                                        "ref_only"
                                      )
                                    },
                                    _vm.changeCampaignTracking,
                                  ],
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "ref_only" } }, [
                                _vm._v("Visitors from"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline",
                                  attrs: {
                                    prop: "visitor_tracking_options.ref_only",
                                    "data-testid":
                                      "tracking-mode-ref-options-item",
                                  },
                                },
                                [
                                  _vm.campaign.visitor_tracking_mode !=
                                  "ref_only"
                                    ? _c("el-input", {
                                        staticStyle: { width: "250px" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "yelp.com",
                                          disabled:
                                            _vm.campaign
                                              .visitor_tracking_mode !=
                                            "ref_only",
                                          "data-testid":
                                            "tracking-mode-ref-options-input",
                                        },
                                        on: {
                                          input: _vm.customPreValidateForm,
                                        },
                                      })
                                    : _c("el-input", {
                                        staticStyle: { width: "250px" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "yelp.com",
                                          "data-testid":
                                            "tracking-mode-ref-options-input",
                                          disabled:
                                            _vm.campaign
                                              .visitor_tracking_mode !=
                                            "ref_only",
                                        },
                                        on: {
                                          input: _vm.customPreValidateForm,
                                        },
                                        model: {
                                          value:
                                            _vm.campaign
                                              .visitor_tracking_options,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.campaign,
                                              "visitor_tracking_options",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "campaign.visitor_tracking_options",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row mt-3",
                          attrs: { "data-testid": "landing-page" },
                        },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("p", { staticClass: "text mt-wizard" }, [
                              _c("b", [_vm._v("Landing Page")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-8 col-12" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.campaign.visitor_tracking_mode,
                                    expression:
                                      "campaign.visitor_tracking_mode",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "lp_only",
                                  id: "lp_only",
                                  "data-testid": "tracking-mode-lp-input",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.campaign.visitor_tracking_mode,
                                    "lp_only"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.campaign,
                                        "visitor_tracking_mode",
                                        "lp_only"
                                      )
                                    },
                                    _vm.changeCampaignTracking,
                                  ],
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "lp_only" } }, [
                                _vm._v("Visitors who land on"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline",
                                  attrs: {
                                    prop: "visitor_tracking_options.lp_only",
                                    "data-testid":
                                      "tracking-mode-lp-options-item",
                                  },
                                },
                                [
                                  _vm.campaign.visitor_tracking_mode !=
                                  "lp_only"
                                    ? _c("el-input", {
                                        staticStyle: { width: "250px" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "landingpage.com",
                                          disabled:
                                            _vm.campaign
                                              .visitor_tracking_mode !=
                                            "lp_only",
                                          "data-testid":
                                            "tracking-mode-lp-options-input",
                                        },
                                        on: {
                                          input: _vm.customPreValidateForm,
                                        },
                                      })
                                    : _c("el-input", {
                                        staticStyle: { width: "250px" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "landingpage.com",
                                          "data-testid":
                                            "tracking-mode-lp-options-input",
                                          disabled:
                                            _vm.campaign
                                              .visitor_tracking_mode !=
                                            "lp_only",
                                        },
                                        on: {
                                          input: _vm.customPreValidateForm,
                                        },
                                        model: {
                                          value:
                                            _vm.campaign
                                              .visitor_tracking_options,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.campaign,
                                              "visitor_tracking_options",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "campaign.visitor_tracking_options",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row mt-3",
                          attrs: { "data-testid": "landing-params" },
                        },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("p", { staticClass: "text mt-wizard" }, [
                              _c("b", [_vm._v("Landing Params")]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-8 col-12" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.campaign.visitor_tracking_mode,
                                    expression:
                                      "campaign.visitor_tracking_mode",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "utm_only",
                                  id: "utm_only",
                                  "data-testid": "tracking-mode-utm-input",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.campaign.visitor_tracking_mode,
                                    "utm_only"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.campaign,
                                        "visitor_tracking_mode",
                                        "utm_only"
                                      )
                                    },
                                    _vm.changeCampaignTracking,
                                  ],
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "utm_only" } }, [
                                _vm._v("Visitors to a landing page containing"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline",
                                  attrs: {
                                    prop: "visitor_tracking_options.utm_only",
                                    "data-testid":
                                      "tracking-mode-utm-options-item",
                                  },
                                },
                                [
                                  _vm.campaign.visitor_tracking_mode !=
                                  "utm_only"
                                    ? _c("el-input", {
                                        staticStyle: { width: "180px" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "utm_source=example",
                                          "data-testid":
                                            "tracking-mode-utm-options-input",
                                          disabled:
                                            _vm.campaign
                                              .visitor_tracking_mode !=
                                            "utm_only",
                                        },
                                        on: {
                                          input: _vm.customPreValidateForm,
                                        },
                                      })
                                    : _c("el-input", {
                                        staticStyle: { width: "180px" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "utm_source=example",
                                          "data-testid":
                                            "visitor-tracking-options-input",
                                          disabled:
                                            _vm.campaign
                                              .visitor_tracking_mode !=
                                            "utm_only",
                                        },
                                        on: {
                                          input: _vm.customPreValidateForm,
                                        },
                                        model: {
                                          value:
                                            _vm.campaign
                                              .visitor_tracking_options,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.campaign,
                                              "visitor_tracking_options",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "campaign.visitor_tracking_options",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-right mt-4" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "pull-left",
                      attrs: {
                        icon: "el-icon-arrow-left",
                        type: "info",
                        "data-testid": "back-button",
                      },
                      on: { click: _vm.backForm },
                    },
                    [_vm._v("\n                Back\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        "data-testid": "next-other-options-button",
                        disabled: !_vm.validated,
                      },
                      on: { click: _vm.submitForm },
                    },
                    [
                      _vm._v(
                        "\n                Next: Other Options\n                "
                      ),
                      _c("i", {
                        staticClass: "el-icon-arrow-right el-icon-right",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 3
        ? _c(
            "div",
            { attrs: { id: "step_3", "data-testid": "wizard-step-3" } },
            [
              _vm.campaign.type == _vm.TYPE_OFFLINE ||
              (_vm.campaign.type == _vm.TYPE_ONLINE &&
                (_vm.campaign.subtype == _vm.SUBTYPE_ONLINE_MARKETING ||
                  _vm.campaign.subtype == _vm.SUBTYPE_ONLINE_EXTERNAL))
                ? _c("p", { staticClass: "form-head" }, [
                    _vm._v("\n            Phone Number\n        "),
                  ])
                : _c("p", { staticClass: "form-head" }, [
                    _vm._v("Fill Pool With"),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-divider" }),
              _vm._v(" "),
              _vm.campaign.type == _vm.TYPE_OFFLINE ||
              (_vm.campaign.type == _vm.TYPE_ONLINE &&
                (_vm.campaign.subtype == _vm.SUBTYPE_ONLINE_MARKETING ||
                  _vm.campaign.subtype == _vm.SUBTYPE_ONLINE_EXTERNAL))
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "campaign_area_code",
                          staticClass: "text-right",
                          attrs: {
                            "data-testid": "campaign-area-code-form",
                            inline: true,
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.changeAreaCode.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Area Code:" } },
                            [
                              _c("el-input", {
                                staticClass: "area-code",
                                attrs: {
                                  type: "text",
                                  pattern: "\\d*",
                                  maxlength: 3,
                                  "data-testid": "area-code-input",
                                },
                                model: {
                                  value: _vm.area_code,
                                  callback: function ($$v) {
                                    _vm.area_code = $$v
                                  },
                                  expression: "area_code",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary pull-right",
                                    "data-testid": "search-button",
                                    disabled: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.changeAreaCode.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-search" }),
                                  _vm._v(
                                    "\n                        Search\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          ref: "campaign_incoming_number",
                          attrs: {
                            "label-position": "top",
                            "label-width": "300px",
                            rules: _vm.rules_3,
                            model: _vm.campaign,
                            "data-testid": "campaign-incoming-number-form",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "incoming_number",
                                "data-testid": "incoming-numbr-item",
                              },
                            },
                            [
                              !_vm.changed
                                ? _c("div", [
                                    _c(
                                      "label",
                                      { staticClass: "el-form-item__label" },
                                      [
                                        _vm._v(
                                          "\n                            Local Phone Numbers to " +
                                            _vm._s(_vm.campaign_first_user) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _c("div", [
                                    _c(
                                      "label",
                                      { staticClass: "el-form-item__label" },
                                      [
                                        _vm._v(
                                          "Phone Numbers in the " +
                                            _vm._s(_vm.changed_area_code) +
                                            " area\n                            code"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "text-muted text-dark-greenish",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Warning: These numbers may not be local to " +
                                            _vm._s(_vm.campaign_first_user) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]),
                              _vm._v(" "),
                              _vm.available_numbers
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "el-form-item__content" },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            staticClass: "w-full",
                                            attrs: {
                                              "data-testid":
                                                "incoming-number-radio",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeNumber(false)
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.campaign.incoming_number,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.campaign,
                                                  "incoming_number",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "campaign.incoming_number",
                                            },
                                          },
                                          _vm._l(
                                            Math.ceil(
                                              _vm.available_numbers.length / 4
                                            ),
                                            function (i) {
                                              return _c(
                                                "div",
                                                { staticClass: "row" },
                                                _vm._l(
                                                  _vm.available_numbers.slice(
                                                    (i - 1) * 4,
                                                    i * 4
                                                  ),
                                                  function (item) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-3 col-6",
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: item.sid,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "fixPhone"
                                                                  )(
                                                                    item.phone_number
                                                                  )
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.available_tollfree_numbers
                                ? _c("div", [
                                    _c(
                                      "label",
                                      { staticClass: "el-form-item__label" },
                                      [
                                        _vm._v(
                                          "Toll-Free Numbers (" +
                                            _vm._s(
                                              _vm._f("toCurrency")(
                                                _vm._f("fixRounding")(
                                                  _vm.auth.user.profile.rate
                                                    .tollfree_pn
                                                )
                                              )
                                            ) +
                                            "/mo surcharge)"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "el-form-item__content" },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            staticClass: "w-full",
                                            attrs: {
                                              "data-testid":
                                                "incoming-number-radio",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeNumber(true)
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.campaign.incoming_number,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.campaign,
                                                  "incoming_number",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "campaign.incoming_number",
                                            },
                                          },
                                          _vm._l(
                                            Math.ceil(
                                              _vm.available_tollfree_numbers
                                                .length / 4
                                            ),
                                            function (i) {
                                              return _c(
                                                "div",
                                                { staticClass: "row" },
                                                _vm._l(
                                                  _vm.available_tollfree_numbers.slice(
                                                    (i - 1) * 4,
                                                    i * 4
                                                  ),
                                                  function (item) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-3 col-6",
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: item.sid,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "fixPhone"
                                                                  )(
                                                                    item.phone_number
                                                                  )
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "campaign_incoming_number",
                      attrs: {
                        "label-position": "top",
                        "label-width": "300px",
                        rules: _vm.rules_3,
                        model: _vm.campaign,
                        "data-testid": "campaign-incoming-number-form",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "pool_options.type",
                            "data-testid": "pool-options-item",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "w-full",
                              attrs: { "data-testid": "pool-options-radio" },
                              on: { change: _vm.changeNumberPoolOption },
                              model: {
                                value: _vm.campaign.pool_options.type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.campaign.pool_options,
                                    "type",
                                    $$v
                                  )
                                },
                                expression: "campaign.pool_options.type",
                              },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: {
                                          value: "local_to_user",
                                          label: "local_to_user",
                                          "data-testid": "local-to-user-radio",
                                          disabled:
                                            _vm.available_numbers.length <
                                            _vm.campaign.pool_size,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Numbers local to " +
                                            _vm._s(_vm.campaign_first_user) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        attrs: {
                                          "data-testid": "no-number-available",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "col-12" }, [
                                          _vm.available_numbers.length <
                                          _vm.campaign.pool_size
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "b-l pl-2 text-sm text-muted",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        No numbers available\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row mt-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: {
                                          value: "tollfree_area_code",
                                          "data-testid":
                                            "tollfree-area-code-radio",
                                          label: "tollfree_area_code",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Toll-free numbers (" +
                                            _vm._s(
                                              _vm._f("toCurrency")(
                                                _vm._f("fixRounding")(
                                                  _vm.auth.user.profile.rate
                                                    .tollfree_pn
                                                )
                                              )
                                            ) +
                                            " per month\n                                surcharge)\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "b-l pl-2",
                                        attrs: {
                                          label: "Area Code:",
                                          "data-testid":
                                            "tollfree-area-code-item",
                                          prop: "pool_options.area_code",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.campaign.pool_options
                                                        .type !=
                                                      "tollfree_area_code",
                                                    "data-testid":
                                                      "tollfree-area-code-select",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeTollFreeAreaCode,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.tollfree_area_code,
                                                    callback: function ($$v) {
                                                      _vm.tollfree_area_code =
                                                        $$v
                                                    },
                                                    expression:
                                                      "tollfree_area_code",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.tollfree_area_codes,
                                                  function (area_code) {
                                                    return _c("el-option", {
                                                      key: area_code,
                                                      attrs: {
                                                        label: area_code,
                                                        value: area_code,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.advanced,
                                      expression: "!advanced",
                                    },
                                  ],
                                  staticClass: "mt-4",
                                  attrs: {
                                    type: "text",
                                    "data-testid": "advanced-button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.advanced = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        Show Advanced Options\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    name: "el-fade-in-linear",
                                    "data-testid": "transition",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.advanced,
                                          expression: "advanced",
                                        },
                                      ],
                                    },
                                    [
                                      _c("div", { staticClass: "row mt-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12" },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  value: "local_area_code",
                                                  "data-testid":
                                                    "local-area-code-radio",
                                                  label: "local_area_code",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Numbers in a specific area code\n                                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass: "b-l pl-2",
                                                      attrs: {
                                                        label: "Area Code:",
                                                        "data-testid":
                                                          "area-code-item",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        staticClass:
                                                          "area-code",
                                                        attrs: {
                                                          type: "text",
                                                          pattern: "\\d*",
                                                          maxlength: 3,
                                                          "data-testid":
                                                            "area-code-input",
                                                          disabled:
                                                            _vm.campaign
                                                              .pool_options
                                                              .type !=
                                                            "local_area_code",
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.resetChangeAreaCode,
                                                        },
                                                        model: {
                                                          value: _vm.area_code,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.area_code = $$v
                                                          },
                                                          expression:
                                                            "area_code",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: {
                                                            type: "success",
                                                            "data-testid":
                                                              "search-area-code-button",
                                                            disabled:
                                                              _vm.campaign
                                                                .pool_options
                                                                .type !=
                                                              "local_area_code",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.searchIncomingNumberOnCampaignAreaCode(
                                                                _vm.campaign
                                                                  .pool_size
                                                              )
                                                            },
                                                            change: function (
                                                              $event
                                                            ) {
                                                              _vm.search_area_code_done = false
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Check Area Code\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm
                                                        .area_code_available_numbers
                                                        .length >=
                                                        _vm.campaign
                                                          .pool_size &&
                                                      _vm.search_area_code_done
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-success",
                                                              attrs: {
                                                                "data-testid":
                                                                  "number-available",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-alert__icon el-icon-success",
                                                              }),
                                                              _vm._v(
                                                                "\n                                                    Numbers available\n                                                "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm
                                                        .area_code_available_numbers
                                                        .length <
                                                        _vm.campaign
                                                          .pool_size &&
                                                      _vm.search_area_code_done
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-danger",
                                                              attrs: {
                                                                "data-testid":
                                                                  "no-number-available",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-alert__icon el-icon-error",
                                                              }),
                                                              _vm._v(
                                                                "\n                                                    No numbers available\n                                                "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-right mt-4" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "pull-left",
                      attrs: {
                        icon: "el-icon-arrow-left",
                        type: "info",
                        "data-testid": "back-button",
                      },
                      on: { click: _vm.backForm },
                    },
                    [_vm._v("\n                Back\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        "data-testid": "activated-fax",
                        disabled: !_vm.validated,
                      },
                      on: { click: _vm.submitForm },
                    },
                    [
                      _vm.is_fax
                        ? _c("span", [
                            _vm._v(
                              "\n                    Activate Fax\n                "
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n                    Next: Other Options\n                "
                            ),
                          ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-arrow-right el-icon-right",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 4
        ? _c(
            "div",
            { attrs: { id: "step_4", "data-testid": "wizard-step-4" } },
            [
              _c(
                "el-form",
                {
                  ref: "campaign_rest",
                  attrs: {
                    "label-position": "top",
                    "label-width": "300px",
                    "data-testid": "campaign-rest-form",
                    rules: _vm.rules_4,
                    model: _vm.campaign,
                  },
                },
                [
                  _c("p", { staticClass: "form-head" }, [
                    _vm._v("Whisper & Recording"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dropdown-divider" }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Call Greeting",
                        "data-testid": "campaign-rest-greeting-item",
                        prop: "should_greet",
                      },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-1 col-1 centered-content" },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                attrs: {
                                  "data-testid": "should-greet-checkbox",
                                },
                                on: { change: _vm.customPreValidateForm },
                                model: {
                                  value: _vm.campaign.should_greet,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.campaign, "should_greet", $$v)
                                  },
                                  expression: "campaign.should_greet",
                                },
                              },
                              [
                                _c("el-checkbox", {
                                  attrs: { name: "should_greet" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-5 col-11" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                "data-testid": "greeting-tts-input",
                                disabled: !_vm.campaign.should_greet,
                              },
                              model: {
                                value: _vm.campaign.greeting_tts,
                                callback: function ($$v) {
                                  _vm.$set(_vm.campaign, "greeting_tts", $$v)
                                },
                                expression: "campaign.greeting_tts",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-12" },
                          [
                            _c("el-popover", {
                              ref: "popover_greeting_tts",
                              attrs: {
                                placement: "right-start",
                                title: "Greeting Message",
                                width: "200",
                                trigger: "hover",
                                "data-testid": "popover-greeting-tts",
                                content:
                                  "This message is read / played to the caller before connecting them to you. You can upload an audio file later on.",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "popover",
                                    rawName: "v-popover:popover_greeting_tts",
                                    arg: "popover_greeting_tts",
                                  },
                                ],
                                staticClass: "form-hover-help",
                              },
                              [_vm._v("What is a greeting message?")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Whisper Message",
                        "data-testid": "campaign-rest-whisper-item",
                        prop: "should_whisper",
                      },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-1 centered-content" },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                attrs: {
                                  "data-testid": "should-whisper-checkbox",
                                },
                                on: { change: _vm.customPreValidateForm },
                                model: {
                                  value: _vm.campaign.should_whisper,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.campaign,
                                      "should_whisper",
                                      $$v
                                    )
                                  },
                                  expression: "campaign.should_whisper",
                                },
                              },
                              [
                                _c("el-checkbox", {
                                  attrs: { name: "should_whisper" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-5" },
                          [
                            _c("el-input", {
                              attrs: {
                                "data-testid": "whisper-tts-input",
                                disabled: !_vm.campaign.should_whisper,
                              },
                              model: {
                                value: _vm.campaign.whisper_tts,
                                callback: function ($$v) {
                                  _vm.$set(_vm.campaign, "whisper_tts", $$v)
                                },
                                expression: "campaign.whisper_tts",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("el-popover", {
                              ref: "popover_whisper_message",
                              attrs: {
                                placement: "right-start",
                                title: "Whisper Message",
                                width: "200",
                                trigger: "hover",
                                "data-testid": "popover-whisper-message",
                                content:
                                  "This messaged is played to you / your staff when you pick up the call, but\n                                not to the caller. Use it as a way to identify the line, for example 'a new call from Facebook Referral'.",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "popover",
                                    rawName:
                                      "v-popover:popover_whisper_message",
                                    arg: "popover_whisper_message",
                                  },
                                ],
                                staticClass: "form-hover-help",
                              },
                              [
                                _vm._v(
                                  "\n                            What is a whisper message?\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Call Recording Notice",
                        "data-testid": "campaign-rest-recording-item",
                        prop: "should_record",
                      },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-1 centered-content" },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                attrs: {
                                  "data-testid": "should-record-checkbox",
                                },
                                on: { change: _vm.customPreValidateForm },
                                model: {
                                  value: _vm.campaign.should_record,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.campaign, "should_record", $$v)
                                  },
                                  expression: "campaign.should_record",
                                },
                              },
                              [
                                _c("el-checkbox", {
                                  attrs: { name: "should_record" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-5" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                "data-testid": "record-tts-input",
                                disabled: !_vm.campaign.should_record,
                              },
                              model: {
                                value: _vm.campaign.record_tts,
                                callback: function ($$v) {
                                  _vm.$set(_vm.campaign, "record_tts", $$v)
                                },
                                expression: "campaign.record_tts",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("el-popover", {
                              ref: "popover_record_tts",
                              attrs: {
                                placement: "right-start",
                                title: "Call Recording Notice",
                                width: "200",
                                trigger: "hover",
                                "data-testid": "popover-record-tts",
                                content:
                                  "In the United States, you *must* let the caller know that their conversation is being recorded.",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "popover",
                                    rawName: "v-popover:popover_record_tts",
                                    arg: "popover_record_tts",
                                  },
                                ],
                                staticClass: "form-hover-help",
                              },
                              [
                                _vm._v(
                                  "\n                            Important note!\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-right mt-4" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "pull-left",
                          attrs: {
                            icon: "el-icon-arrow-left",
                            type: "info",
                            "data-testid": "back-button",
                          },
                          on: { click: _vm.backForm },
                        },
                        [_vm._v("\n                    Back\n                ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            "data-testid": "next-activate-button",
                            loading: _vm.loading,
                            disabled: !_vm.validated,
                          },
                          on: { click: _vm.submitForm },
                        },
                        [
                          _vm._v(
                            "\n                    Activate Line\n                    "
                          ),
                          _c("i", {
                            staticClass: "el-icon-arrow-right el-icon-right",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step == 5
        ? _c(
            "div",
            {
              staticClass: "text-center",
              attrs: { id: "step_5", "data-testid": "wizard-step-5" },
            },
            [
              _c("campaign-details", {
                attrs: {
                  campaign: _vm.campaign_created,
                  is_fax: _vm.is_fax,
                  "data-testid": "campaign-details",
                },
                on: { close: _vm.close },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row mt-4", attrs: { "data-testid": "wizard-purpose" } },
      [
        _c("div", { staticClass: "col-12 text-center" }, [
          _c("p", { staticClass: "form-head-message" }, [
            _vm._v("What is the purpose of this line?"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "row mt-4",
        attrs: { "data-testid": "tracking-phone-number" },
      },
      [
        _c("div", { staticClass: "col-12 text-center" }, [
          _c("p", { staticClass: "form-head-message" }, [
            _vm._v("Will you use this tracking phone number online?"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-12 text-center" }, [
        _c("small", { staticClass: "text-muted" }, [
          _vm._v(
            "\n                            Your website, PPC ads, Social Media Referrals\n                        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row mt-2", attrs: { "data-testid": "tv-radio-print" } },
      [
        _c("div", { staticClass: "col-12 text-center" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              "\n                            On TV, Radio, Print Ads, Billboards\n                        "
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "row mt-4",
        attrs: { "data-testid": "display-tracking-number" },
      },
      [
        _c("div", { staticClass: "col-12 text-center" }, [
          _c("p", { staticClass: "form-head-message" }, [
            _vm._v("Where will you display this tracking number?"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row mt-2", attrs: { "data-testid": "provide-code" } },
      [
        _c("div", { staticClass: "col-12 text-center" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v("We'll provide the code to install call tracking."),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row mt-2", attrs: { "data-testid": "adwords-email" } },
      [
        _c("div", { staticClass: "col-12 text-center" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v("Google AdWords, Email signatures, and more"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "row mt-4",
        attrs: { "data-testid": "track-on-your-website" },
      },
      [
        _c("div", { staticClass: "col-12 text-center" }, [
          _c("p", { staticClass: "form-head-message" }, [
            _vm._v("What do you want to track on your website?"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "row mt-2",
        attrs: { "data-testid": "create-8-phone-numbers" },
      },
      [
        _c("div", { staticClass: "col-12 text-center" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              "\n                            Create 8+ phone numbers that correlate calls to website visitors, PPC keywords, and more\n                        "
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "row mt-2",
        attrs: { "data-testid": "create-one-phone-number" },
      },
      [
        _c("div", { staticClass: "col-12 text-center" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              "\n                            Create one phone number that tracks all callers to single marketing campaign.\n                        "
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }