var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c("el-tooltip", { attrs: { placement: "top", width: "280" } }, [
        _c("div", { attrs: { slot: "content" }, slot: "content" }, [
          _vm._v("\n            " + _vm._s(_vm.fullTimeStamp) + "\n        "),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "text-muted" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.relative_datetime) + "\n        "
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }