var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("send fax")
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              staticClass: "dialog-padding auto",
              attrs: {
                title: "Send a Fax",
                width: _vm.isLargeEnough ? "500px" : "100%",
                visible: _vm.hide_add,
                "before-close": _vm.beforeCloseModal,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.hide_add = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "send_fax",
                  staticClass: "p-0",
                  attrs: {
                    "label-position": "top",
                    rules: _vm.rules_fax,
                    model: _vm.fax,
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.sendFax.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "campaign_id", label: "Line" } },
                    [
                      _c("campaign-selector", {
                        attrs: {
                          select_style: "width: 100%;",
                          disabled: _vm.loading_btn,
                          is_fax: true,
                        },
                        on: { change: _vm.changeCampaignId },
                        model: {
                          value: _vm.fax.campaign_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.fax, "campaign_id", $$v)
                          },
                          expression: "fax.campaign_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone_number", label: "Phone Number" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.loading_btn },
                        on: {
                          input: function ($event) {
                            return _vm.preValidateForm("send_fax")
                          },
                        },
                        model: {
                          value: _vm.fax.phone_number,
                          callback: function ($$v) {
                            _vm.$set(_vm.fax, "phone_number", $$v)
                          },
                          expression: "fax.phone_number",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "file_name" } },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            drag: "",
                            action:
                              "/api/v1/campaign/" +
                              _vm.fax.campaign_id +
                              "/upload/pdf",
                            headers: _vm.headers,
                            "on-success": _vm.onSuccessSendFax,
                            "on-error": _vm.onFailedSendFax,
                            "on-progress": _vm.progressUploadSendFax,
                            "before-upload": _vm.beforeUploadSendFax,
                            "file-list": _vm.uploadFileList.upload,
                            multiple: false,
                            disabled:
                              _vm.fax.campaign_id === null || _vm.loading_btn,
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v(
                              "\n                        Drop file here or "
                            ),
                            _c("em", [_vm._v("click to upload")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                "\n                        PDF file (less than 600 KB)\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.fax.file_name === null
                        ? _c("el-progress", {
                            attrs: {
                              "text-inside": true,
                              "stroke-width": 18,
                              percentage: _vm.uploadPercentage.upload,
                              status: _vm.uploadStatus.upload,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row pb-3" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-right ml-2",
                        attrs: {
                          type: "success",
                          disabled: _vm.loading_btn || !_vm.validated,
                        },
                        on: { click: _vm.sendFax },
                      },
                      [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading_btn,
                                expression: "loading_btn",
                              },
                            ],
                            staticClass: "material-icons loader",
                          },
                          [_vm._v("")]
                        ),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loading_btn,
                                expression: "!loading_btn",
                              },
                            ],
                            staticClass: "material-icons",
                          },
                          [
                            _vm._v(
                              "\n                        send\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("Send")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("trigger", function () {
            return [
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "reference",
                    type: "text text-sm text-dark-greenish",
                  },
                  on: {
                    click: function ($event) {
                      _vm.hide_add = true
                    },
                  },
                  slot: "reference",
                },
                [
                  _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                  _vm._v("\n            New Message\n        "),
                ]
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }