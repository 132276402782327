var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _c(
        "div",
        {
          staticClass: "container-listing min-height-screen d-flex flex-column",
          attrs: { "v-loading": _vm.workflow_loading },
        },
        [
          _vm.workflow
            ? _c("div", [
                _vm.holiday_name && !_vm.workflow.process_on_holidays
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row w-100 justify-content-center mb-3",
                      },
                      [
                        _c(
                          "el-alert",
                          {
                            staticClass: "sequence-holiday-banner",
                            attrs: {
                              type: "warning",
                              "show-icon": "",
                              center: "",
                              closable: false,
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "14px" } },
                              [
                                _vm._v(
                                  "This sequence is turned off today to respect " +
                                    _vm._s(_vm.holiday_name)
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "container-header d-flex flex-row w-100 justify-content-between",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "align-self-center mr-3 d-flex flex-row" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "Sequences 2" } } },
                          [
                            _c(
                              "el-button",
                              { attrs: { round: "", size: "medium" } },
                              [
                                _c("i", { staticClass: "fa fa-arrow-left" }),
                                _vm._v(" Back\n                        "),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row justify-content-between flex-grow-1",
                      },
                      [
                        _c("div", { staticClass: "ml-2" }, [
                          _c("h3", { staticClass: "listing-heading" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.workflow.name) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "small",
                            {
                              staticClass: "text-grey",
                              staticStyle: { "font-size": "16px" },
                            },
                            [
                              _vm._v(
                                "\n                            #" +
                                  _vm._s(_vm.workflow.id) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "sequence-switch",
                              class: _vm.sequence_switch_class,
                            },
                            [
                              _c("workflow-status", {
                                key: _vm.workflow_status_key,
                                staticClass: "mr-3",
                                attrs: {
                                  placement: "bottom",
                                  is_long_message: true,
                                  workflow: _vm.workflow,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#00BF50",
                                  disabled:
                                    _vm.workflow.real_status ===
                                      _vm.Workflow.CURRENT_STATUS_INVALID &&
                                    !_vm.workflow.active,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeActiveStatus(_vm.workflow)
                                  },
                                },
                                model: {
                                  value: _vm.workflow.active,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.workflow, "active", $$v)
                                  },
                                  expression: "workflow.active",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "container-body flex-grow-1" },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.tabClicked },
                        model: {
                          value: _vm.tab,
                          callback: function ($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab",
                        },
                      },
                      [
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "Settings", name: "settings" } },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-10 mx-auto py-4" },
                                [
                                  _c("workflow-settings-manager", {
                                    on: { saved: _vm.fetchWorkflowStatus },
                                    model: {
                                      value: _vm.workflow,
                                      callback: function ($$v) {
                                        _vm.workflow = $$v
                                      },
                                      expression: "workflow",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "Steps", name: "steps" } },
                          [
                            _c("workflow-steps-list", {
                              on: { "step-changed": _vm.fetchWorkflowStatus },
                              model: {
                                value: _vm.workflow,
                                callback: function ($$v) {
                                  _vm.workflow = $$v
                                },
                                expression: "workflow",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tab-pane",
                          {
                            attrs: {
                              label: "Intake & Triggers",
                              name: "triggers",
                            },
                          },
                          [
                            _c("workflow-trigger-intake-manager", {
                              model: {
                                value: _vm.workflow,
                                callback: function ($$v) {
                                  _vm.workflow = $$v
                                },
                                expression: "workflow",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "Contacts", name: "contacts" } },
                          [
                            _c("workflow-contacts", {
                              ref: "workflow_contacts",
                              attrs: { workflow: _vm.workflow },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "History", name: "history" } },
                          [
                            _c("sequence-logs", {
                              attrs: { workflow: _vm.workflow },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("form-height-indicator", { attrs: { bottom: 20 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }