<template>
    <el-dialog
        :key="dialog_key"
        class="dialog-blank"
        width="32vw"
        top="7vh"
        append-to-body
        :show-close="false"
        :visible="visible">
        <div class="container-listing rounded p-4">
            <div class="container-header d-flex">
                <h5 class="listing-heading">Enroll Contacts</h5>
                <div class="ml-auto">
                    <el-button type="text"
                               class="btn-dialog-close"
                               size="large"
                               @click="close">
                        <i class="fa fa-remove"></i>
                    </el-button>
                </div>
            </div>
            <div
                v-if="workflow && workflow.is_enrollable"
                class="container-body">
                <p class="lead mb-2 lh-1">
                    Select a contact or a group of contacts you want to enroll to <strong>{{ workflow.name }}</strong> sequence.
                </p>
                <el-tabs v-model="tab">
                    <el-tab-pane label="Contact Group" name="contact_group">
                        <contact-group-selector
                            v-model="contact_group"
                            full_width
                            :tag_category="TagCategories.CAT_CONTACTS"
                            @change="contactGroupChanged">
                        </contact-group-selector>
                    </el-tab-pane>
                    <el-tab-pane label="Single Contact" name="contact">
                        <contact-select
                            v-model="selected_contact"
                            label="Search contact"
                            @select-contacts="selectContact">
                        </contact-select>
                    </el-tab-pane>
                </el-tabs>

                <div class="mt-4 text-center mb-3">
                    <el-button
                        :disabled="!is_enrollable"
                        class="mb-2"
                        type="success"
                        size="medium"
                        round
                        @click="enrollContactsToWorkflow">
                        <strong>
                            <i class="fa fa-plus"></i> Enroll Contact{{ tab === 'contact_group' ? 's': '' }}
                        </strong>
                    </el-button>
                </div>

                <div>
                    <ul class="list-unstyled">
                        <li>
                            <i class="fa fa-info-circle text-warning"></i>
                            Contacts that are already enrolled in a sequence will not be enrolled.
                        </li>
                        <li>
                            <i class="fa fa-info-circle text-warning"></i>
                            Contacts the has a DNC status will not be enrolled.
                        </li>
                        <li>
                            <i class="fa fa-info-circle text-warning"></i>
                            Contact groups that has thousands of contacts will take time to enroll.
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="workflow && !workflow.is_enrollable"
                 class="container-body text-center py-5">
                <img-empty class="w-75 mx-auto mb-3"/>
                <p class="lead">
                    <i class="fa fa-ban text-danger"></i> Enrollment is only allowed between <br/>
                    <strong>{{ workflow.converted_open }}</strong>
                    and <strong>{{ workflow.converted_close }}</strong>
                </p>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import ContactGroupSelector from "../contact-group-selector"
import ContactSelect from "../contact-select"
import ImgEmpty from "../misc/img-empty";
import * as TagCategories from '../../constants/tag-categories'

export default {
    name: "workflow-enroller",

    components: {ImgEmpty, ContactSelect, ContactGroupSelector},

    data() {
        return {
            visible: false,
            tab: 'contact_group',
            workflow: null,
            contact_group: null,
            selected_contact: null,
            dialog_key: 1,
            TagCategories,
        }
    },

    computed: {
        is_enrollable() {
            return (this.tab === 'contact' && this.selected_contact !== null) || (this.tab === 'contact_group' && this.contact_group)
        }
    },

    methods: {
        enrollContacts(workflow) {
            this.workflow = workflow
            this.visible = true

            this.contact_group = null
            this.selected_contact = null
            this.tab = 'contact_group'

            this.dialog_key++
        },

        enrollContactsToWorkflow() {
            let payload = this.getEnrollmentPayload()

            axios.post(`/api/v1/automations/workflows/${this.workflow.id}/sequence-contacts`, payload).then(res => {
                this.close();

                this.$notify({
                    offset: 75,
                    title: 'Sequence',
                    message: res.data.message,
                    type: 'success',
                    showClose: true,
                    duration: 6000
                })
            }).catch(err => {

            })
        },

        /**
         * Generates the payload to enroll a contact, line, tag, ds
         *
         * @returns {Object|null}
         */
        getEnrollmentPayload() {
            let payload = null
            if (this.tab === 'contact') {
                payload = {
                    model: 'contact',
                    id: this.selected_contact.id
                }
            }
            else if (this.tab === 'contact_group') {
                let data = this.contact_group.split(':')
                payload = {
                    model: data[0],
                    id: data[1]
                }
            }

            return payload
        },

        selectContact(contact) {
            this.selected_contact = contact
        },

        contactGroupChanged(contact_group) {
            this.contact_group = contact_group
        },

        close() {
            this.$emit('before-close')
            this.visible = false
        }
    }
}
</script>

