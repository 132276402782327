var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            top: "15vh",
            width: "35vw",
            "custom-class": "dialog-no-border text-center team-dialog",
            "data-testid": "restriction-message-dialog",
            "append-to-body": "",
            visible: _vm.dialogVisible,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "px-5 pb-3" }, [
            _c("h4", { staticClass: "text-dark pb-3" }, [
              _vm._v("Convert users into a Team"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "text-dark px-3 d-inline-block" }, [
              _vm._v('Create a new team "' + _vm._s(_vm.teamName) + '" '),
              _c("br"),
              _vm._v(
                "\n                The following users will be added to the team: "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center mt-3 text-dark text-left",
              },
              [
                _c(
                  "ul",
                  _vm._l(_vm.users, function (user, index) {
                    return _c("li", { key: index }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(user.name) +
                          "\n                    "
                      ),
                    ])
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row py-3 justify-content-center" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "mr-4",
                    attrs: { plain: "" },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal()
                      },
                    },
                  },
                  [_vm._v("\n                    Cancel\n                ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.addToTeam()
                      },
                    },
                  },
                  [_vm._v("\n                    Confirm\n                ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("img", {
        attrs: { src: _vm.teamImg },
        on: {
          click: function ($event) {
            return _vm.openModal()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }