<template>
    <div class="card summary-card-hover h-100"
         v-loading="loading">
        <div class="p-3">
            <img class="w-24"
                 data-testid="broadcast-summary-img"
                 src="/assets/images/app-icons/new/broadcast.png">
            <strong class="text-md title pl-2">Broadcast</strong>
        </div>
        <div class="card-body pt-4">
            <div class="row">
                <div class="col text-center countup-hover unclickable">
                    <h4 class="text-black metric-value">{{ broadcast_summary.count | numFormat }}</h4>
                    <label class="text-muted metric-label">Sent</label>
                </div>
                <div class="col text-center countup-hover unclickable">
                    <h4 class="text-black metric-value">{{ broadcast_summary.recipients | numFormat }}</h4>
                    <label class="text-muted metric-label">Total Recipients</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    name: "broadcast-summary-widget",

    data() {
        return {
            reporting_url: localStorage.getItem('reporting_url'),
            loading: true,
            CancelToken: null,
            source: null,
            broadcast_summary: {}
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter'
        }),
    },

    created() {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()

        VueEvent.listen('update_summary', () => {
            this.fetchBroadcastSummary()
        })
    },

    mounted() {
        this.fetchBroadcastSummary()
    },

    methods: {
        fetchBroadcastSummary() {
            this.source.cancel('fetchBroadcastSummary canceled by the user.')
            this.source = this.CancelToken.source()
            this.loading = true
            axios.get(this.reporting_url + '/api/v1/analytics/dashboard/broadcast-summary', {
                params: this.filter,
                cancelToken: this.source.token
            }).then(res => {
                this.broadcast_summary = res.data
                this.loading = false
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                } else {
                    //this.$root.handleErrors(err.response)
                    this.loading = false
                }
            })
        }
    }
}
</script>
