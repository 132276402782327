<template>
  <div class="app-body" id="view">
    <div class="insights">
      <div id="insights-filters" class="padding mt-5 pt-3" style="position: relative; overflow: hidden;">
        <div class="row mt-2">
          <div class="col-12 text-center">
            <!-- `selectedInsight` watcher calls `loadInsight` method -->
            <el-radio-group class="col-12 no-select pull-left mt-1"
                            size="small"
                            v-model="selectedInsight"
                            >
              <el-radio-button :key="insight.id"
                               :label="insight"
                               v-for="(insight) in insights">
                {{insight.name}}
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div id="insights-show" v-if="loadedInsightUrl">
        <insights-show :url="loadedInsightUrl"
                       :query="showFilters? query : null">
          <template class="d-block"
                    #filters
                    v-if="showFilters">
            <div class="filter-container">
              <div class="col-md-5 p-0">
                <label class="p-0 m-0 _500 w-100">Date Filter:</label>
                <date-selector class="date-filter"
                               :default_date_range="initialDateRange"
                               :allow_initial_reset="false"/>
              </div>

              <div class="col-md-5 p-0"
                   v-if="isContactReport">
                <label class="p-0 m-0 _500 w-100">Baseline:</label>
                <el-select class="no-select"
                           v-model="query.baseline">
                  <el-option :key="item"
                             :value="item"
                             v-for="item in baselines">
                  </el-option>
                </el-select>
              </div>

              <div class="col-md-5 p-0"
                   v-if="isContactReport" >
                <label class="p-0 m-0 _500 w-100">Metric:</label>
                <el-select class="no-select"
                           v-model="query.metrics">
                  <el-option :key="item.value"
                             :label="item.label"
                             :value="item.value"
                             v-for="item in metrics">
                  </el-option>
                </el-select>
              </div>

              <div class="col-md-5 p-0">
                <label class="p-0 m-0 _500 w-100">Filter by User:</label>
                <user-selector placeholder="Select User"
                               :multiple="!isContactReport"
                               :hide_extensions="true"
                               :value="query.filter_by_user"
                               :collapseTags="true"
                               @change="changeUser">
                  <el-option-group key="No Users"
                                   label="No Users"
                                   v-if="isContactReport">
                    <el-option :key="item"
                               :value="item"
                               v-for="item in moreUserOptions">
                    </el-option>
                  </el-option-group>
                </user-selector>
              </div>

              <div class="col-md-5 p-0">
                <label class="p-0 m-0 _500 w-100">Filter by Line:</label>
                <campaign-selector :multiple="!isContactReport"
                                   :clearable="true"
                                   :value="query.filter_by_line"
                                   @input="changeCampaigns"
                                   @change="changeCampaigns">
                  <el-option-group key="No Lines"
                                   label="No Lines"
                                   v-if="isContactReport">
                    <el-option :key="item"
                               :value="item"
                               v-for="item in moreLineOptions">
                    </el-option>
                  </el-option-group>
                </campaign-selector>
              </div>

              <div class="col-md-5 p-0"
                   v-if="!isContactReport">
                <label class="p-0 m-0 _500 w-100">Filter by Team:</label>
                <team-selector :multiple="!isContactReport"
                               :collapsable="true"
                               :clearable="true"
                               :value="query.filter_by_team"
                               @change="changeTeam"/>
              </div>
            </div>
          </template>
        </insights-show>
      </div>
    </div>
  </div>
</template>
<style scoped>
.el-select {
  display: inline;
}

.date-filter{
  min-width: 100%;
}

.filter-container{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
}
</style>
<script>
import auth from '../../auth'
import {mapGetters, mapState} from 'vuex'
import {acl_mixin, date_mixin, scroll_mixin, styling_mixin} from '../../mixins'
import InsightsShow from "./insights-show.vue"
import DateSelector from "../../components/date-selector"
import TeamSelector from "../../components/team-selector"
import {debounce} from "lodash"

export default {
  name: 'insights-index',

  mixins: [
    styling_mixin,
    date_mixin,
    acl_mixin,
    scroll_mixin
  ],

  components: {DateSelector, InsightsShow, TeamSelector},

  props: {
    slug: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      auth: auth,
      env: null,
      loading: false,
      selectedInsight: null,
      loadedInsightUrl: null,
      insights: [],
      query: {
        baseline: 'Lines',
        date_filter: '',
        filter_by_line: '',
        filter_by_user: '',
        filter_by_team: '',
        metrics: ''
      },
      baselines: ['Lines', 'Users'],
      metrics: [
        {
          label: 'Overview',
          value: ''
        },
        {
          label: 'New Contacts',
          value: 'New Contacts'
        },
        {
          label: 'DNC Contacts',
          value: 'DNC Contacts'
        }
      ],
      moreUserOptions: ['Contacts with no user'],
      moreLineOptions: ['Contacts with no line'],
      reportsWithFilters: ['contacts', 'hourly-heatmap'],
      initialDateRange: ''
    }
  },

  computed: {
    ...mapState('cache', ['current_company']),
    ...mapGetters('cache', ['isTrial', 'isSimpSocial']),
    ...mapGetters({
      filter: 'getFilter',
    }),

    showFilters () {
      return this.reportsWithFilters.includes(this.selectedInsight?.slug)
    },

    isContactReport () {
      return this.selectedInsight?.slug === 'contacts'
    }
  },

  /**
   * Fetches the build information and sets the environment variable.
   * Called before the component is created.
   */
  beforeCreate() {
    axios.get('/build-info.json')
        .then(res => {
          this.env = res.data.env
        })
        .catch(err => {
          console.log(err)
        })
  },

  /**
   * Sets the title and fetches insights when the component is mounted.
   */
  mounted() {
    this.setTitle()
    this.fetchInsights()
    this.applySavedFilters()
  },

  /**
   * Checks user authentication and permissions before entering the route.
   * Redirects to appropriate pages based on user role and permissions.
   */
  beforeRouteEnter(to, from, next) {
    auth.check()
        .then((res) => {
          next(vm => {
            if (!vm.hasPermissionTo('list insights')) {
              next(vm.goBack())
            }
          })

          if (res.data.user.is_reseller) {
            // redirect to account management portal if the company is a reseller
            next({name: 'Account Management Portal'})
          } else {
            next()
          }
        })
        .catch((err) => {
          next({name: 'Login', query: {redirect: to.fullPath}})
        })
  },

  watch: {
    selectedInsight: function () {
      this.getInsightUrl()

      if(this.showFilters){
        this.applySavedFilters()
      }
    },

    'filter.from_date': function (data) {
      if (data) {
        this.query.date_filter = `${data.split(' ')[0]}~${this.filter.to_date?.split(' ')[0]}`
      } else {
        this.query.date_filter = ''
      }

      if(this.showFilters && !this.isContactReport){
        this.saveFilters()
      }
    },

    'filter.to_date': function (data) {
      if (data) {
        this.query.date_filter = `${this.filter.from_date?.split(' ')[0]}~${data.split(' ')[0]}`
      } else {
        this.query.date_filter = ''
      }

      if(this.showFilters && !this.isContactReport){
        this.saveFilters()
      }
    },
  },

  methods: {
    /**
     * Initializes the selected insight based on the provided slug or the number of insights available.
     *
     * If a slug is provided in the url, it searches for an insight with a matching slug and sets it as the selected insight.
     * If no slug is provided and there is exactly one insight available, it sets that insight as the selected insight.
     */
    initializeSelectedInsight() {
      if (this.slug) {
        this.selectedInsight = this.insights.find(insight => insight.slug === this.slug)
      } else if (this.insights.length > 0) {
        this.selectedInsight = this.insights[0]
      }
    },

    /**
     * Loads the selected insight by making an API call.
     * Sets the `loadedInsight` and updates the URL with the selected insight's slug.
     */
    getInsightUrl() {
      this.loading = true
      this.loadedInsightUrl = null

      axios.get('/api/v2/insights/' + this.selectedInsight.id + '/url')
          .then(response => {
            this.loadedInsightUrl = response.data.url
              this.setupTokenRefresh();
          })
          .catch(error => {
            console.error('Error fetching insight url:', error)
          })
      this.loading = false

      // check if the current route is `/insights` and the selected insight has a slug
      this.$router.push({
        name: 'View Insight',
        params: {
          slug: this.selectedInsight.slug
        }
      })
    },
      // Set up a timer to refresh the token
      setupTokenRefresh() {
          const refreshIntervalMinutes = 14; // 1 minute less than the token's validity (which is 15 minutes)
          const refreshIntervalMs = refreshIntervalMinutes * 60 * 1000; // Convert minutes to milliseconds

          setInterval(this.refreshUrl, refreshIntervalMs);
      },
      refreshUrl() {
          axios.get(`/api/v2/insights/${this.selectedInsight.id}/url`)
              .then(response => {
                  this.loadedInsightUrl = response.data.url; // Update child via prop
              })
              .catch(error => {
                  console.error('Error refreshing URL:', error);
              });
      },
    /**
     * Fetches the list of insights by making an API call.
     * Initializes the selected insight after fetching the insights.
     */
    fetchInsights() {
      this.loading = true
      axios.get('/api/v2/insights')
          .then(response => {
            this.insights = response.data
            this.initializeSelectedInsight()
          })
          .catch(error => {
            console.error('Error fetching insights:', error)
          })

      this.loading = false
    },

    /**
     * Sets the page title by making an API call to fetch static data.
     * If an error occurs, sets a default title and handles the error.
     */
    setTitle() {
      axios.get('/get-statics')
          .then(res => {
            let name = res.data.name
            this.setPageTitle('Insights - ' + name)
          })
          .catch(err => {
            this.setPageTitle('Insights - Aloware')
            console.log(err)
            this.$root.handleErrors(err.response)
          })
    },

    changeUser (values) {
      this.query.filter_by_user = values
      this.query.filter_by_line = !values || !this.isContactReport? this.query.filter_by_line : ''

      if(this.showFilters  && !this.isContactReport){
        this.saveFilters()
      }
    },

    changeCampaigns (values) {
      this.query.filter_by_line = values
      this.query.filter_by_user = !values || !this.isContactReport? this.query.filter_by_user : ''

      if(this.showFilters && !this.isContactReport){
        this.saveFilters()
      }
    },

    changeTeam (values) {
      this.query.filter_by_team = values ? values : ''

      if(this.showFilters && !this.isContactReport){
        this.saveFilters()
      }
    },

    applySavedFilters () {
      const savedQuery = localStorage.getItem(this.selectedInsight?.slug)
      if(savedQuery){
        this.query = JSON.parse(savedQuery)
        this.initialDateRange = this.query.date_filter.replace(/-/g, '/').replace('~', '-')
        if(!this.initialDateRange){
          this.initialDateRange = 'All Time'
        }
      }else{
        // set date filter to Last 30 days
        const days = 30
        const today = moment().tz(this.current_company.timezone)
        this.initialDateRange = `${today.clone().subtract(days, 'days').startOf('day').format('MM/DD/YYYY')} - ${today.clone().endOf('day').format('MM/DD/YYYY')}`
      }
    },

    saveFilters: debounce(function () {
      if(!this.query.date_filter && this.filter?.from_date) {
        this.query.date_filter = `${this.filter?.from_date?.split(' ')[0]}~${this.filter?.to_date?.split(' ')[0]}`
      }
      localStorage.setItem(this.selectedInsight.slug, JSON.stringify(this.query))
    }, 500)
  },

}
</script>
