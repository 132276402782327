var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _vm.contact
        ? _c(
            "el-select",
            {
              staticClass: "w-full",
              attrs: {
                disabled: _vm.disabled || _vm.loading_dispose,
                multiple: false,
                clearable: "",
                filterable: "",
                placeholder: _vm.placeholder,
                "data-testid": "contact-disposition-select",
              },
              on: { change: _vm.dispose },
              model: {
                value: _vm.dispositionStatusId,
                callback: function ($$v) {
                  _vm.dispositionStatusId = $$v
                },
                expression: "dispositionStatusId",
              },
            },
            _vm._l(
              _vm.dispositionStatusesAlphabeticalOrder,
              function (disposition_status, index) {
                return _c(
                  "el-option",
                  {
                    key: disposition_status.id + "-disp-" + index,
                    attrs: {
                      label: disposition_status.name,
                      value: disposition_status.id,
                    },
                  },
                  [
                    disposition_status.is_external !== undefined
                      ? [
                          disposition_status.is_external
                            ? _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  style: { color: disposition_status.color },
                                },
                                [_vm._v("lock")]
                              )
                            : _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  style: { color: disposition_status.color },
                                },
                                [_vm._v("label")]
                              ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(disposition_status.name))]),
                  ],
                  2
                )
              }
            ),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }