var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading_whitelabel &&
    _vm.current_company &&
    _vm.current_company.simpsocial_integration_enabled
    ? _c("div", { staticClass: "app-body", attrs: { id: "view" } }, [
        _c("div", { staticClass: "padding" }, [
          _c("div", { staticClass: "row box" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "no-effect" }, [
                _c(
                  "div",
                  { staticClass: "box-body" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("hr", { staticClass: "clear-both no-top-margin" }),
                    _vm._v(" "),
                    _c("simpsocial-settings"),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h2", { staticClass: "pull-left" }, [_vm._v("Dealer Profile")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }