var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    ["campaigns", "ring_groups"].includes(_vm.base)
      ? _c("div", { staticClass: "vertical-center text-left" }, [
          _vm.filter.from_date
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "visible",
                      rawName: "v-visible",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticClass: "call-log-head",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.total_comms || 0) +
                      " Calls & Texts from\n                "
                  ),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm._f("fixFullDateLocal")(_vm.filter.from_date))
                    ),
                  ]),
                  _vm._v("\n                to\n                "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm._f("fixFullDateLocal")(_vm.filter.to_date))
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.filter.from_date
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "visible",
                      rawName: "v-visible",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticClass: "call-log-head",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.total_comms || 0) +
                      " Calls & Texts\n                "
                  ),
                  _c("strong", [_vm._v("All Time")]),
                ]
              )
            : _vm._e(),
        ])
      : _vm.base == "callers"
      ? _c("div", { staticClass: "vertical-center text-left" }, [
          _vm.filter.from_date
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "visible",
                      rawName: "v-visible",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticClass: "call-log-head",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.total_comms || 0) +
                      " First Time Calls & Texts from\n                "
                  ),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm._f("fixFullDateLocal")(_vm.filter.from_date))
                    ),
                  ]),
                  _vm._v("\n                to\n                "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm._f("fixFullDateLocal")(_vm.filter.to_date))
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.filter.from_date
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "visible",
                      rawName: "v-visible",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticClass: "call-log-head",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.total_comms || 0) +
                      " First Time Calls & Texts\n                "
                  ),
                  _c("strong", [_vm._v("All Time")]),
                ]
              )
            : _vm._e(),
        ])
      : _c("div", { staticClass: "vertical-center text-left" }, [
          _vm.filter.from_date
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "visible",
                      rawName: "v-visible",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticClass: "call-log-head",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.total_comms || 0) +
                      " Calls & Texts from\n                "
                  ),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm._f("fixFullDateLocal")(_vm.filter.from_date))
                    ),
                  ]),
                  _vm._v("\n                to\n                "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm._f("fixFullDateLocal")(_vm.filter.to_date))
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.filter.from_date
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "visible",
                      rawName: "v-visible",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticClass: "call-log-head",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.total_comms || 0) +
                      " Calls & Texts\n                "
                  ),
                  _c("strong", [_vm._v("All Time")]),
                ]
              )
            : _vm._e(),
        ]),
    _vm._v(" "),
    _vm.is_graph
      ? _c("div", { staticClass: "row mt-2 mb-2" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "pull-left no-select" },
              [
                _c("strong", [_vm._v("Chart type:")]),
                _vm._v(" "),
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: _vm.loading, size: "mini" },
                    on: { change: _vm.changeChartType },
                    model: {
                      value: _vm.chart_type,
                      callback: function ($$v) {
                        _vm.chart_type = $$v
                      },
                      expression: "chart_type",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "pie" } }, [
                      _vm._v("Pie"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "column" } }, [
                      _vm._v("Bar"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loading || _vm.campaigns.length == 0
      ? _c(
          "div",
          {
            staticClass:
              "placeholder text-center d-flex align-items-center justify-content-center",
            class: { blink: _vm.loading },
            staticStyle: { height: "450px" },
          },
          [
            _c(
              "i",
              { staticClass: "material-icons loader text-greenish text-4x" },
              [_vm._v("\n                \n            ")]
            ),
          ]
        )
      : _c(
          "div",
          { staticStyle: { height: "450px" } },
          [
            _c(
              "graph-data-switch",
              {
                attrs: { series: _vm.options.series, type: _vm.type },
                on: { change: _vm.checkType },
              },
              [
                _c("highcharts", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.is_done &&
                        _vm.options.series.length > 0 &&
                        _vm.options.series[0].data.length != 0,
                      expression:
                        "is_done && options.series.length > 0 && options.series[0].data.length != 0",
                    },
                  ],
                  ref: "highchart",
                  staticStyle: { height: "450px" },
                  attrs: { id: _vm.graph_id, options: _vm.options },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.is_done &&
                          (_vm.options.series[0].data.length == 0 ||
                            !_vm.options.series.length),
                        expression:
                          "is_done && (options.series[0].data.length == 0 || !options.series.length)",
                      },
                    ],
                    staticClass: "el-table__empty-block",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "el-table__empty-text",
                        staticStyle: { color: "#606266" },
                      },
                      [
                        _vm._v(
                          "\n                        No Data\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }