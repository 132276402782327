<template>
    <div v-if="hasPermissionTo('create campaign')" data-testid="line-creator-wrapper">
        <el-dialog v-if="use_old_wizard"
                   :visible.sync="dialogVisible"
                   :before-close="beforeCloseModal"
                   top="10vh"
                   append-to-body
                   custom-class="wizard-dialog dialog-no-border"
                   data-testid="line-creator-dialog"
                   @close="closeModal">
            <campaign-wizard :key="prop_counter"
                             :is_fax="is_fax"
                             data-testid="campaign-wizard"
                             @created="campaignCreated">
            </campaign-wizard>
        </el-dialog>
        <el-dialog v-else
                   :visible.sync="dialogVisible_new"
                   :before-close="beforeCloseModal"
                   :title="is_fax ? 'New Fax' : 'New Line'"
                   width="70vw"
                   top="4vh"
                   append-to-body
                   custom-class="wizard-dialog dialog-no-border"
                   data-testid="line-creator-dialog"
                   @close="closeModal">
            <div slot="title"
                 data-testid="new-wizard-title">
                <h5 class="mb-0">
                    <i class="fas fa-hat-wizard"></i>
                    New {{ is_fax ? 'Fax' : 'Line' }} Wizard
                </h5>
            </div>
            <line-wizard :key="prop_counter"
                         :is_fax="is_fax"
                         data-testid="line-wizard"
                         @created="campaignCreated">
            </line-wizard>
        </el-dialog>

        <slot name="trigger">
            <button class="btn btn-md rounded"
                    :class="is_fax ? 'orangish' : 'greenish'"
                    data-testid="create-line-btn"
                    @click="openModal">
                <i class="fa" :class="is_fax ? 'fa-fax' : 'fa-plus'"></i>
                <span v-if="is_fax">
                    Add Fax
                </span>
                <span v-else>
                    Add Line
                </span>
            </button>
        </slot>
    </div>
</template>

<script>
import auth from '../auth'
import { acl_mixin, kyc_mixin } from '../mixins'
import CampaignContactCenterWizard from "./line-wizard";
import {mapState} from 'vuex'

export default {
    components: {
      CampaignContactCenterWizard
    },

    mixins: [ acl_mixin, kyc_mixin ],

    props: {
        is_fax: {
            required: false,
            type: Boolean,
            default: false
        },
        use_old_wizard: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            auth: auth,
            prop_counter: 0,
            dialogVisible: false,
            dialogVisible_new: false,
            campaign_created: false,
        }
    },

    computed: {
        ...mapState(['campaigns', 'ring_groups']),
    },

    methods: {
        openModal() {
            if (this.use_old_wizard) {
                this.dialogVisible = true
            } else {
                this.dialogVisible_new = true
            }
            this.campaign_created = false
        },

        beforeCloseModal(done) {
            if (!this.campaign_created) {
                this.$confirm('Are you sure you want to leave. Your changes will not be saved.', 'Warning', {
                    confirmButtonText: 'Yes, Leave',
                    cancelButtonText: 'No, Stay',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(res => {
                    done()
                }).catch(err => {
                    console.log(err)
                })
            } else {
                done()
            }
        },

        closeModal() {
            this.dialogVisible = false
            this.campaign_created = false
            setTimeout(() => {
                this.prop_counter += 1
            }, 200)
        },

        campaignCreated() {
            this.campaign_created = true
        }
    }
}
</script>
