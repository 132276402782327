<template>
    <el-dialog
        width="350px"
        title="Delete Tag"
        :visible.sync="dialog_visible"
        :before-close="toggleDialog"
    >
        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
        >
            <span class="text-md text-left mb-2 block break-word">
                Deleting a tag will remove it from all contacts and communications. Continue?
            </span>
            <template v-if="delete_all_checked">
                <p class="text-left block break-word">
                    You're about to delete <span class="font-weight-bold">{{ tagged_contacts_count }} contacts</span>.
                    Use the text field below to confirm the number of contacts you want to delete.
                </p>
                <el-form-item prop="confirmed_contacts_count">
                    <el-input
                        v-model.number="ruleForm.confirmed_contacts_count"
                        placeholder="Confirm number of contacts here"
                    ></el-input>
                </el-form-item>
            </template>
            <el-checkbox
                class="break-word"
                v-model="delete_all_checked"
                v-if="tagged_contacts_count && !isSimpSocial"
                v-show="!delete_all_checked"
            >
                Do you also want to delete the tagged contacts of this tag?
            </el-checkbox>
            <div class="mt-2 text-right">
                <el-button @click="toggleDialog">Cancel</el-button>
                <el-button
                    type="danger"
                    :disabled="isFormValid"
                    @click="triggerDelete"
                >Delete</el-button>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
    import {acl_mixin} from '../mixins'

    export default {
        name: "delete-tag-contacts-dialog",
        mixins: [acl_mixin],
        props: {
            tag_id: {
                type: Number,
                required: true,
                default: 0
            },
            tagged_contacts_count: {
                type: Number,
                required: true,
                default: 0
            },
            show_hide_dialog: {
                type: Boolean,
                required: true
            }
        },

        data() {
            let validateConfirmedContacts = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('Please confirm number of contacts'))
                    this.is_form_valid = false
                } else if (value !== this.tagged_contacts_count ) {
                    callback(new Error('Number of contacts does not match'))
                    this.is_form_valid = false
                } else {
                    callback()
                    this.is_form_valid = true
                }
            };
            return {
                is_form_valid: false,
                dialog_visible: false,
                delete_all_checked: false,
                num_of_contacts_matched: true,
                ruleForm: { confirmed_contacts_count: '' },
                rules: {
                    confirmed_contacts_count: [
                        { validator: validateConfirmedContacts, trigger: 'change' }
                    ]
                }
            }
        },

        methods: {
            triggerDelete() {
                let info = {
                    tag_id: this.tag_id,
                    data: {should_delete_contacts: false}
                }

                if (this.delete_all_checked) {
                    if (this.tagged_contacts_count == this.ruleForm.confirmed_contacts_count) {
                        info.data.should_delete_contacts = true
                        this.resetVars()
                        this.$emit('dialog-delete-btn-clicked', info)
                    } else {
                        this.num_of_contacts_matched = false;
                    }
                } else {
                    this.$emit('dialog-delete-btn-clicked', info)
                }
            },

            toggleDialog() {
                this.resetVars()
                this.$emit('toggle-dialog',0, 0)
            },

            resetVars() {
                this.ruleForm.confirmed_contacts_count = ''
                this.delete_all_checked = false
                this.num_of_contacts_matched = true
                this.is_form_valid = false
            }
        },

        computed: {
            isFormValid() {
                return (this.delete_all_checked && !this.is_form_valid)
            }
        },

        watch: {
            show_hide_dialog(val) {
                this.dialog_visible = val
            }
        },
    }
</script>
