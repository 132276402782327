<template>
    <div>
        <el-tooltip class="item pointer"
                    placement="top">
            <div slot="content">From: {{ from_time | fixDateTimeFull }}</div>
            <span>{{ timeago }}</span>
        </el-tooltip>
    </div>
</template>

<script>
import Moment from 'moment'

export default {
    name: "alo-timeago",

    props: {
        from_time: {
            required: true,
            type: Moment
        },
    },

    data() {
        return {
            timeago: null
        }
    },

    mounted() {
        this.computeDuration()
        setInterval(this.computeDuration, 1000)
    },

    methods: {
        computeDuration() {
            const now = moment.utc(new Date())
            const diff = now.diff(this.from_time, 'seconds')
            let hours = Math.floor(diff / 3600)
            let minutes = Math.floor(diff % 3600 / 60)
            let seconds = Math.floor(diff % 3600 % 60)

            // show x day(s) ago for
            if (hours >= 24) {
                let days = Math.floor(hours / 24)
                let days_label = days === 1 ? 'a day' : `${days} days`

                this.timeago = `${days_label} ago`
                return
            }

            let hours_label = (hours < 10) ? `0${hours}` : hours
            let minutes_label = (minutes < 10) ? `0${minutes}` : minutes
            let seconds_label = (seconds < 10) ? `0${seconds}` : seconds

            this.timeago = `${hours_label}:${minutes_label}:${seconds_label}`
        }
    }
}
</script>