<template>
    <div class="app-body"
         id="view">
        <div class="fixed-header padding pt-0 pb-0"
             :style="{top: (top_offset+50) + 'px'}"
             v-if="companyHasTranscriptionEnabled">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <div class="d-flex">
                        <div class="d-flex flex-fill">
                            <comm-advanced-filters-wrapper/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="padding mt-5 pt-3">
                <transcription-reporting-panel/>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import TranscriptionReportingPanel from '../../components/transcription-reporting-panel'
import {acl_mixin, filter_mixin, html_mixin, scroll_mixin, styling_mixin} from '../../mixins'
import auth from "../../auth"

export default {
    name: 'AloVoiceAiIndex',
    components: {
        'transcription-reporting-panel': TranscriptionReportingPanel,
    },
    mixins: [
        filter_mixin,
        scroll_mixin,
        styling_mixin,
        acl_mixin,
        html_mixin
    ],
    data() {
        return {
            auth: auth,
        }
    },
    computed: {
        ...mapState('cache', ['current_company']),

        companyHasTranscriptionEnabled() {
            return this.current_company.transcription_enabled
        },
    },
    mounted() {
        this.setPageTitle('AloAi Voice Analytics')

        if (!this.shouldShowAloVoiceAiMenu) {
            this.goBack()
        }
    },
    watch: {
        $route({params, query}) {
            if (!this.shouldShowAloVoiceAiMenu) {
                this.goBack()
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        // This logic help us wrap the Chatbots section with the Aloware App components.
        if (to.query.api_token) {
            return next({
                name: 'Login',
                query: {
                    api_token: to.query.api_token
                }
            })
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            })
        })
    },
}
</script>
