export const USE_CASE_NOTIFICATIONS = 'notifications'
export const USE_CASE_MARKETING = 'marketing'
export const USE_CASE_VERIFICATION = 'verification'
export const USE_CASE_DISCUSSION = 'discussion'
export const USE_CASE_POLL = 'poll'
export const USE_CASE_UNDECLARED = 'undeclared'

export const SCAN_MESSAGE_CONTENT_INHERIT = 'inherit'
export const SCAN_MESSAGE_CONTENT_ENABLED = 'enabled'
export const SCAN_MESSAGE_CONTENT_DISABLED = 'disabled'

export const SCAN_MESSAGE_CONTENT = Object.freeze([
    {
        value: SCAN_MESSAGE_CONTENT_INHERIT,
        label: 'Inherit',
        description: 'Inherit the parent setting.',
    },
    {
        value: SCAN_MESSAGE_CONTENT_ENABLED,
        label: 'Enabled',
        description: 'Enable content scanning.',
    },
    {
        value: SCAN_MESSAGE_CONTENT_DISABLED,
        label: 'Disabled',
        description: 'Disable content scanning.',
    }
])

export const USE_CASES = Object.freeze([
    {
        value: USE_CASE_NOTIFICATIONS,
        label: 'Notifications',
        description: 'Information about the status of a delivery.',
    },
    {
        value: USE_CASE_MARKETING,
        label: 'Marketing',
        description: 'Promotional content such as sales and limited time offers.',
    },
    {
        value: USE_CASE_VERIFICATION,
        label: 'Verification',
        description: 'Any authentication or account verification such as OTP.',
    },
    {
        value: USE_CASE_DISCUSSION,
        label: 'Discussion',
        description: 'Support, account management, and other avenues of customer interaction.',
    },
    {
        value: USE_CASE_POLL,
        label: 'Poll',
        description: 'Polling and voting.',
    },
    {
        value: USE_CASE_UNDECLARED,
        label: 'Undeclared',
        description: 'Undeclared use case.',
    }
])
