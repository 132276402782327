var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "row",
    },
    [
      _vm.isBlockedFrom("line cnam")
        ? _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _vm.shouldShowUpgradeNow
                ? _c("upgrade-now", {
                    attrs: {
                      "image-link": "/assets/images/fomo/CNAM.png",
                      "kb-link":
                        "https://support.aloware.com/en/articles/9037892-frequently-asked-questions-caller-id-cnam",
                      text: "Setting a Caller ID is not included in your current plan. To use it, please contact us to upgrade today!",
                      "title-text": "Setting a Caller ID (CNAM)",
                      "default-click": true,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "cnam_caller_id" },
                    }),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c("i", {
                          staticClass: "fa mr-2",
                          class: _vm.cnamRegistrationIconClass,
                        }),
                        _vm._v(" "),
                        _c("b", [
                          _vm._v("Set the Caller ID (CNAM) for this line"),
                        ]),
                        _vm._v(" "),
                        _vm.cnamStatusData
                          ? [
                              _c(
                                "span",
                                {
                                  staticClass: "label text-xxs mx-1",
                                  class: _vm.cnamStatusData.color,
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.cnamStatusData.status) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.cnamStatusData.message &&
                    _vm.standardBusinessProfileApproved
                      ? _c("p", { staticClass: "text-grey _400 ml-4 pl-1" }, [
                          _vm._v(_vm._s(_vm.cnamStatusData.message)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.cnamStatusData.failure
                      ? _c("p", { staticClass: "text-danger _400 ml-4 pl-1" }, [
                          _c("span", { staticClass: "text-black" }, [
                            _vm._v("Reasons: "),
                          ]),
                          _vm._v(_vm._s(_vm.cnamStatusData.failure)),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _vm.current_company &&
                _vm.current_company.registered_customer_profile
                  ? [
                      _c(
                        "div",
                        { staticClass: "ml-4 pl-1" },
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "el-form",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.cnam_form_loading,
                                  expression: "cnam_form_loading",
                                },
                              ],
                              ref: "compliance_cnam_form",
                              attrs: {
                                "label-position": "top",
                                model: _vm.cnam_form,
                                rules: _vm.cnam_form_rules,
                              },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "mb-2 pb-0 border-0",
                                        attrs: {
                                          label: "Caller ID",
                                          prop: "caller_id",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "input",
                                            disabled: _vm.disableCnamInputs,
                                            maxlength: 15,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.preValidateForm(
                                                "compliance_cnam_form"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.cnam_form.caller_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.cnam_form,
                                                "caller_id",
                                                $$v
                                              )
                                            },
                                            expression: "cnam_form.caller_id",
                                          },
                                        }),
                                        _vm._v(" "),
                                        !_vm.disableCnamInputs &&
                                        _vm.nameCharCounter
                                          ? _c(
                                              "span",
                                              { staticClass: "_300" },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.nameCharCounter
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-12" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mb-2",
                                      class: {
                                        "mt-3": _vm.nameCharCounter === "",
                                      },
                                    },
                                    [
                                      _c("b", { staticClass: "_700" }, [
                                        _vm._v("Notes:"),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        "CNAM should begin with a letter and can only contain letters, numbers, periods, commas and spaces."
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(
                                        "Caller ID (CNAM) must be relevant to your business and business name; No impersonation allowed"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(
                                        "Caller ID (CNAM) can take up to 48 hours to appear"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(
                                        "Some carriers may not show this Caller ID"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(
                                        "Some mobile numbers may need to turn a setting on or subscribe to see Caller IDs"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(
                                        "No guarantee this Caller ID will work for Canada or Puerto Rico numbers"
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12 text-left" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ml-auto w-full",
                                        attrs: {
                                          disabled: _vm.disableCnamInputs,
                                          type: "primary",
                                        },
                                        on: { click: _vm.validateCnamForm },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(_vm.cnamSubmitButtonText)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-12 text-left pt-2" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ml-auto w-full",
                                        attrs: {
                                          disabled:
                                            !_vm.canDeleteBundle ||
                                            _vm.cnam_delete_loading,
                                          type: "danger",
                                        },
                                        on: { click: _vm.deleteCnam },
                                      },
                                      [_c("strong", [_vm._v("Delete")])]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.caller_id && _vm.caller_id.submitted_at
                            ? _c("div", { staticClass: "row mt-2" }, [
                                _c("div", { staticClass: "col-md-12" }, [
                                  _c("p", { staticClass: "_500" }, [
                                    _vm._v(
                                      "Last Submitted " +
                                        _vm._s(
                                          _vm._f("fixFullDayDateUTC")(
                                            _vm.caller_id.submitted_at
                                          )
                                        )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3),
              ],
              2
            ),
            _vm._v(" "),
            _vm._m(4),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "mb-2" }, [
      _vm._v("Caller ID (CNAM) "),
      _c("span", { staticClass: "_300" }, [_vm._v("(Optional)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("p", [
          _vm._v(
            "Caller ID (CNAM) is a personal or business name associated with this line that displays on a contact's phone when you call them."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-3 mb-3" }, [
      _c("div", { staticClass: "col-12" }, [_c("hr")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-3 mb-3" }, [
      _c("div", { staticClass: "col-md-12 status-background" }, [
        _c("span", {
          staticClass: "page-anchor",
          attrs: { id: "status_legends" },
        }),
        _vm._v(" "),
        _c("h5", { staticClass: "text-black mt-3" }, [
          _vm._v(
            "\n                        Status Explanations:\n                    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _c("p", [
              _c("span", { staticClass: "label text-xxs mx-2 danger" }, [
                _vm._v("Unsubmitted"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-10" }, [
            _c("p", [
              _vm._v(
                "If Unsubmitted - You need to fill out and submit the form."
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _c("p", [
              _c("span", { staticClass: "label text-xxs mx-2 blue" }, [
                _vm._v("Pending"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-10" }, [
            _c("p", [
              _vm._v("If Pending - May take up to 48 hours to process."),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _c("p", [
              _c("span", { staticClass: "label text-xxs mx-2 green" }, [
                _vm._v("Approved"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-10" }, [
            _c("p", [
              _vm._v(
                "\n                                If Approved - Your request is approved and no further action is required.\n                            "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _c("p", [
              _c("span", { staticClass: "label text-xxs mx-2 danger" }, [
                _vm._v("Failed"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-10" }, [
            _c("p", [
              _vm._v(
                "If Failed - Our Carrier will provide a rejection reason. Action needed."
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2 d-none d-md-block ml-auto" }, [
      _c(
        "div",
        {
          staticClass:
            "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
        },
        [
          _c("h6", { staticClass: "list-group-item" }, [
            _vm._v("\n                    On This Page\n                "),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "list-group-item list-group-item-action",
              attrs: { href: "#cnam_caller_id" },
            },
            [_vm._v("\n                    CNAM Caller ID\n                ")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "list-group-item list-group-item-action",
              attrs: { href: "#status_legends" },
            },
            [
              _vm._v(
                "\n                    Status Explanations\n                "
              ),
            ]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }