var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.data_loaded || _vm.loading
      ? _c(
          "div",
          {
            staticClass:
              "placeholder text-center d-flex align-items-center justify-content-center",
            class: { blink: _vm.loading },
            staticStyle: { height: "300px" },
          },
          [
            _c(
              "i",
              { staticClass: "material-icons loader text-greenish text-4x" },
              [_vm._v("\n            \n        ")]
            ),
          ]
        )
      : _c(
          "div",
          {
            staticClass: "force-scrollbar-view",
            staticStyle: { height: "300px" },
          },
          [
            _c("highcharts", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.is_done && _vm.options.series.length > 0,
                  expression: "is_done && options.series.length > 0",
                },
              ],
              ref: "highchart",
              style: { height: "300px" },
              attrs: { options: _vm.graphOptions, id: _vm.graph_id },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }