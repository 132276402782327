export const INCOMING = 'incoming'
export const ERROR = 'error'
export const DISCONNECT = 'disconnect'
export const CONNECT = 'connect'
export const CANCEL = 'cancel'
export const UNREGISTERED = 'unregistered'
export const REGISTERING = 'registering'
export const REGISTERED = 'registered'
export const TOKEN_WILL_EXPIRE = 'tokenWillExpire'

export const CALL_ACCEPT = 'accept'
export const CALL_CANCEL = 'cancel'
export const CALL_DISCONNECT = 'disconnect'
export const CALL_ERROR = 'error'
export const CALL_MUTE = 'mute'
export const CALL_RECONNECTING = 'reconnecting'
export const CALL_RECONNECTED = 'reconnected'
export const CALL_REJECT = 'reject'
export const CALL_RINGING = 'ringing'
export const CALL_SAMPLE = 'sample'
export const CALL_VOLUME = 'volume'
export const CALL_WARNING = 'warning'
export const CALL_WARNING_CLEARED = 'warning-cleared'

