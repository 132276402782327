<template>
    <el-select v-if="auth.user.profile.country=='US'"
               v-model="limit_us"
               placeholder="Select States"
               class="w-full"
               :multiple="multiple"
               default-first-option
               collapse-tags
               filterable
               @change="selectState">
        <el-option v-for="(item, index) in states_us"
                   :key="item + '_' + index"
                   :label="item"
                   :value="item">
            <span>{{ item }}</span>
        </el-option>
    </el-select>
    <el-select v-else-if="auth.user.profile.country=='CA'"
               v-model="limit_ca"
               placeholder="Select States"
               class="w-full"
               :multiple="multiple"
               default-first-option
               collapse-tags
               filterable
               @change="selectState">
        <el-option v-for="(item, index) in states_ca"
                   :key="item + '_' + index"
                   :label="item"
                   :value="item">
            <span>{{ item }}</span>
        </el-option>
    </el-select>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin} from '../mixins'

    export default {
        mixins: [acl_mixin],

        props: {
            value: {
                type: Object,
                required: true
            },
            multiple: {
                type: Boolean,
                required: false,
                default: false
            },
        },

        data() {
            return {
                auth: auth,
                states_us: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
                states_ca: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
                limit_us: [],
                limit_ca: [],
            }
        },

        mounted() {
            this.limit_us = this.value.us
            this.limit_ca = this.value.ca
        },

        methods: {
            selectState() {
                this.$emit('change', {us: this.limit_us, ca: this.limit_ca})
            }
        },

        watch: {
            value: {
                deep: true,
                handler: function() {
                    this.limit_us = this.value.us
                    this.limit_ca = this.value.ca
                }
            }
        }
    }
</script>
