var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-form", { staticClass: "form-aloware" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "no-border",
              attrs: { error: _vm.errors.first("name") },
            },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Name")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                        Specify a name for this automated sequence.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w-full",
                model: {
                  value: _vm.value.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "name", $$v)
                  },
                  expression: "value.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { error: _vm.errors.first("description") } },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Description")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                        Describe what this sequence is about.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.value.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "description", $$v)
                  },
                  expression: "value.description",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "no-border",
              attrs: { error: _vm.errors.first("process_on") },
            },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Process On")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                        Choose if you want the sequence to process everyday or business days only.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  staticClass: "mb-3",
                  attrs: { size: "medium" },
                  model: {
                    value: _vm.value.send_on,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "send_on", $$v)
                    },
                    expression: "value.send_on",
                  },
                },
                [
                  _c(
                    "el-radio-button",
                    { attrs: { label: _vm.Workflow.SEND_ON_ANY_DAYS } },
                    [_vm._v("Everyday")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-button",
                    { attrs: { label: _vm.Workflow.SEND_ON_BIZ_DAYS } },
                    [_vm._v("Business Days")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  staticClass: "w-full checkbox-label-description mt-2",
                  attrs: { name: "process-holidays", border: "" },
                  model: {
                    value: _vm.value.process_on_holidays,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "process_on_holidays", $$v)
                    },
                    expression: "value.process_on_holidays",
                  },
                },
                [
                  _c(
                    "label",
                    { staticClass: "mb-1" },
                    [
                      _c("span", { staticClass: "mr-1" }, [
                        _vm._v("Run on national holidays"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-popover",
                        {
                          staticClass: "ml-auto",
                          attrs: {
                            placement: "right",
                            trigger: "hover",
                            disabled: _vm.current_company.reseller_id === 357,
                          },
                        },
                        [
                          _c("div", [
                            _c("span", [_vm._v("See")]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "text-blue-6",
                                attrs: {
                                  href: "https://support.aloware.com/en/articles/9037395-aloware-sequence-explained-how-it-works-and-how-to-get-started",
                                  target: "_blank",
                                },
                              },
                              [_c("u", [_vm._v("list of holidays")])]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              staticClass: "material-icons text-blue-6 mr-2",
                              staticStyle: {
                                cursor: "pointer",
                                "font-size": "15px",
                              },
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _vm._v(
                                "\n                                info_outline\n                            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Enabling this will make this sequence run on (US) holidays.\n                    "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "no-border",
              attrs: { error: _vm.errors.first("always_open") },
            },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Day Schedule")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                        Choose if you want the sequence to process on a specific time range or process at any time.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  model: {
                    value: _vm.value.always_open,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "always_open", $$v)
                    },
                    expression: "value.always_open",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: true } }, [
                    _vm._v("Anytime"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: false } }, [
                    _vm._v("Set Time Range"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "no-border",
              attrs: {
                error: _vm.errors.first(
                  "prevent_enrollment_for_contacts_with_inbound_communications"
                ),
              },
            },
            [
              _c(
                "el-checkbox",
                {
                  staticClass: "w-full checkbox-label-description mt-2",
                  attrs: { name: "process-holidays", border: "" },
                  model: {
                    value:
                      _vm.value
                        .prevent_enrollment_for_contacts_with_inbound_communications,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.value,
                        "prevent_enrollment_for_contacts_with_inbound_communications",
                        $$v
                      )
                    },
                    expression:
                      "value.prevent_enrollment_for_contacts_with_inbound_communications",
                  },
                },
                [
                  _c("label", { staticClass: "mb-1 break-word" }, [
                    _vm._v(
                      "Prevent Enrollment If Contact is Communicating (or has Already Communicated) with an Agent"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        When enabled, if a contact decides to text or call your lines immediately after submitting a form etc., they won’t be enrolled into this sequence.\n                    "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.value.always_open
            ? _c("el-form-item", { staticClass: "no-border" }, [
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Specify time")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Choose the start and end time for this sequence to process.\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "no-border",
                          attrs: { error: _vm.errors.first("converted_open") },
                        },
                        [
                          _c("strong", [_vm._v("Start time")]),
                          _vm._v(" "),
                          _c("el-time-select", {
                            staticClass: "input-block",
                            attrs: {
                              placeholder: "Start time",
                              "picker-options": {
                                start: "00:00",
                                step: "00:30",
                                end: "24:00",
                              },
                              clearable: false,
                            },
                            model: {
                              value: _vm.value.converted_open,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "converted_open", $$v)
                              },
                              expression: "value.converted_open",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "no-border",
                          attrs: { error: _vm.errors.first("converted_close") },
                        },
                        [
                          _c("strong", [_vm._v("End time")]),
                          _vm._v(" "),
                          _c("el-time-select", {
                            staticClass: "input-block",
                            attrs: {
                              placeholder: "End time",
                              "picker-options": {
                                start: "00:00",
                                step: "00:30",
                                end: "24:00",
                                minTime: _vm.value.converted_open,
                              },
                              clearable: false,
                            },
                            model: {
                              value: _vm.value.converted_close,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "converted_close", $$v)
                              },
                              expression: "value.converted_close",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "w-full checkbox-label-description",
                        attrs: { name: "events", border: "" },
                        model: {
                          value: _vm.value.do_not_enroll_after_hours,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.value,
                              "do_not_enroll_after_hours",
                              $$v
                            )
                          },
                          expression: "value.do_not_enroll_after_hours",
                        },
                      },
                      [
                        _c("label", [
                          _vm._v(
                            "Do not enroll leads that come in outside the time range."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "\n                            If you want leads to be enrolled anytime, leave this unchecked.\n                        "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "no-border",
              attrs: { error: _vm.errors.first("campaign_id") },
            },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Outbound Line")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                        Selects what line to use to send outbound calls and messages from.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("campaign-selector", {
                attrs: {
                  check_blocked_messaging: "",
                  is_automated: "",
                  show_paused: false,
                },
                on: {
                  change: _vm.campaignChange,
                  trigger_validation: _vm.validateCampaignId,
                },
                model: {
                  value: _vm.value.campaign_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "campaign_id", $$v)
                  },
                  expression: "value.campaign_id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { error: _vm.errors.first("batch_size") } },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Throttle")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                        Determines how many contacts are processed per minute. For most live cases, "
                  ),
                  _c("strong", [_vm._v("20 per minute")]),
                  _vm._v(
                    " is a good selection. If you need a higher volume, please contact\n                        us.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.value.batch_size,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "batch_size", $$v)
                    },
                    expression: "value.batch_size",
                  },
                },
                _vm._l(_vm.throttles, function (throttle) {
                  return _c("el-option", {
                    key: throttle.value,
                    attrs: { value: throttle.value, label: throttle.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "no-border" },
            [
              _c(
                "el-checkbox",
                {
                  staticClass: "w-full checkbox-label-description",
                  attrs: { name: "events", border: "" },
                  model: {
                    value: _vm.value.disenroll_on_response,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "disenroll_on_response", $$v)
                    },
                    expression: "value.disenroll_on_response",
                  },
                },
                [
                  _c("label", [_vm._v("Remove lead if they respond.")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Automatically remove lead when they respond to a message or pick up a call.\n                    "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "no-border" },
            [
              _c(
                "el-checkbox",
                {
                  staticClass: "w-full checkbox-label-description",
                  attrs: { name: "events", border: "" },
                  model: {
                    value: _vm.value.disenroll_on_opt_out,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "disenroll_on_opt_out", $$v)
                    },
                    expression: "value.disenroll_on_opt_out",
                  },
                },
                [
                  _c("label", [_vm._v("Remove lead if they opt-out.")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Automatically remove lead upon enrollment time if they have chosen opt-out.\n                    "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "no-border" },
            [
              _c(
                "el-checkbox",
                {
                  staticClass: "w-full checkbox-label-description",
                  attrs: { name: "events", border: "" },
                  model: {
                    value: _vm.value.disenroll_on_calls,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "disenroll_on_calls", $$v)
                    },
                    expression: "value.disenroll_on_calls",
                  },
                },
                [
                  _c("label", [
                    _vm._v(
                      "Remove lead if a user manually calls an enrolled lead."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Automatically remove lead when an agent/user manually calls the lead.\n                    "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "no-border" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content:
                      "To enable this field, you must first disable Use contact's last communication line field",
                    disabled: !_vm.value.use_last_communication_campaign,
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "w-full checkbox-label-description",
                      attrs: {
                        name: "events",
                        disabled: _vm.value.use_last_communication_campaign,
                        border: "",
                      },
                      model: {
                        value: _vm.value.use_contact_owner_campaign,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "use_contact_owner_campaign", $$v)
                        },
                        expression: "value.use_contact_owner_campaign",
                      },
                    },
                    [
                      _c("label", [_vm._v("Use contact's owner line.")]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "\n                            Use contact's owner line instead of the selected one (selected is the fallback).\n                        "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "no-border" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content:
                      "To enable this field, you must first disable Use contact's owner line field",
                    disabled: !_vm.value.use_contact_owner_campaign,
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "w-full checkbox-label-description",
                      attrs: {
                        name: "events",
                        disabled: _vm.value.use_contact_owner_campaign,
                        border: "",
                      },
                      model: {
                        value: _vm.value.use_last_communication_campaign,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.value,
                            "use_last_communication_campaign",
                            $$v
                          )
                        },
                        expression: "value.use_last_communication_campaign",
                      },
                    },
                    [
                      _c("label", [
                        _vm._v("Use contact's last communication line."),
                      ]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "\n                            Use contact's last communication line instead of the selected one (selected is the fallback).\n                        "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex" },
      [
        _vm.mode === "add"
          ? _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("onAddClose")
                  },
                },
              },
              [_vm._v("\n            Cancel\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml-auto" },
          [
            _vm.mode === "edit"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "success", loading: _vm.saving },
                    on: {
                      click: function ($event) {
                        return _vm.update(_vm.value)
                      },
                    },
                  },
                  [_c("strong", [_vm._v("Save Settings")])]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.mode === "add"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "success", loading: _vm.saving },
                    on: {
                      click: function ($event) {
                        return _vm.createWorkflow(_vm.value)
                      },
                    },
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        "\n                    Save & Continue\n                    "
                      ),
                      _c("i", { staticClass: "fa fa-arrow-right" }),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }