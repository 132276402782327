var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "import-wizard dialog-no-border",
      attrs: {
        "append-to-body": true,
        "before-close": _vm.beforeClose,
        "close-on-click-modal": false,
        visible: _vm.import_dialog_visible,
        title: "Import Ring Group Wizard",
        top: "4vh",
        width: "60vw",
      },
      on: {
        "update:visible": function ($event) {
          _vm.import_dialog_visible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("h5", { staticClass: "mb-0" }, [
          _c("i", { staticClass: "fas fa-h-wizard" }),
          _vm._v("\n            Import Ring Group Wizard\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-steps",
        {
          staticClass: "mb-3",
          attrs: {
            active: _vm.current_step,
            "align-center": "",
            "finish-status": "success",
          },
        },
        [
          _c("el-step", { attrs: { title: "Settings" } }),
          _vm._v(" "),
          _c("el-step", { attrs: { title: "Select Columns" } }),
          _vm._v(" "),
          _c("el-step", { attrs: { title: "Finish" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.uploading,
              expression: "uploading",
            },
          ],
          ref: "form",
          attrs: { model: _vm.import_model, "label-position": "top" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", [
            _vm.current_step === 1
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-5" },
                      [
                        _c("el-form-item", [
                          _c("div", { staticClass: "form-label" }, [
                            _c("label", [
                              _vm._v("Choose or create a Ring Group"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass:
                                      "w-full new-ring-group p-2 d-flex align-items-center",
                                    attrs: { label: true, border: "" },
                                    model: {
                                      value: _vm.import_model.create_ring_group,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.import_model,
                                          "create_ring_group",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "import_model.create_ring_group",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        Create new Ring Group\n                                    "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass:
                                      "w-full choose-ring-group p-2 d-flex align-items-center",
                                    attrs: { label: false, border: "" },
                                    model: {
                                      value: _vm.import_model.create_ring_group,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.import_model,
                                          "create_ring_group",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "import_model.create_ring_group",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        Choose a Ring Group\n                                    "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-divider"),
                        _vm._v(" "),
                        _vm.import_model.create_ring_group
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { error: _vm.firstError("name") } },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("label", [_vm._v("Name")]),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "Name of this Ring Group",
                                      },
                                      model: {
                                        value: _vm.import_model.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.import_model,
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression: "import_model.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      error: _vm.firstError("description"),
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("label", [_vm._v("Description")]),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      attrs: {
                                        autosize: { minRows: 3, maxRows: 5 },
                                        placeholder:
                                          "Description of this new Ring Group",
                                        type: "textarea",
                                      },
                                      model: {
                                        value: _vm.import_model.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.import_model,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "import_model.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.import_model.create_ring_group
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      error: _vm.firstError("ring_group_id"),
                                    },
                                  },
                                  [
                                    _c("ring-group-selector", {
                                      staticClass: "mb-2",
                                      on: { change: _vm.ringGroupChange },
                                    }),
                                    _vm._v(" "),
                                    !_vm.import_model.create_ring_group &&
                                    _vm.import_model.ring_group_id > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "alert alert-warning alert-aloware p-1",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-exclamation-triangle",
                                            }),
                                            _vm._v(
                                              " This will override the ring group's\n                                    users and layers\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-7" },
                      [
                        _c("el-form-item", [
                          _c("div", { staticClass: "form-label" }, [
                            _c("label", [_vm._v("Import File")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "step-0" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "container-template-download text-center",
                                  staticStyle: { width: "340px" },
                                },
                                [
                                  _c("p", { staticClass: "text-sm" }, [
                                    _vm._v(
                                      "To get started with a file template, click link below."
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-link",
                                    {
                                      staticClass: "text-md mt-2",
                                      attrs: {
                                        type: "success",
                                        target: "_blank",
                                        href: "/templates/Ring-Groups-Template.csv",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-cloud-download",
                                      }),
                                      _vm._v(
                                        "\n                                        Download Ring Groups Template\n                                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-upload",
                                {
                                  ref: "file_upload",
                                  attrs: {
                                    "auto-upload": true,
                                    "before-upload": _vm.beforeUpload,
                                    data: _vm.upload_data,
                                    headers: _vm.headers,
                                    multiple: false,
                                    "on-error": _vm.columnChooserFailed,
                                    "on-success": _vm.renderColumnChooser,
                                    "show-file-list": false,
                                    action: "/api/v1/import-ring-group/parse",
                                    drag: "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dz-label" },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-file-csv",
                                      }),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v("Drop your CSV file here or"),
                                      ]),
                                      _vm._v(" "),
                                      !_vm.uploading
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "mb-2",
                                              attrs: { type: "success" },
                                            },
                                            [
                                              _c("span", [
                                                _c("i", {
                                                  staticClass: "fa fa-upload",
                                                }),
                                                _vm._v(
                                                  "\n                                    Upload From Computer and Start\n                                "
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.upload_errors.file > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "text-danger",
                                              staticStyle: {
                                                "word-break": "break-word",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-exclamation-circle",
                                              }),
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(_vm.upload_error) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.current_step === 2
              ? _c(
                  "div",
                  [
                    _c("el-form-item", [
                      _c(
                        "div",
                        { staticClass: "alert alert-success alert-aloware" },
                        [
                          _c("div", { staticClass: "media" }, [
                            _c("i", {
                              staticClass: "fas fa-info-circle alert-icon",
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "media-body lh-1" }, [
                              _c("p", { staticClass: "mb-0" }, [
                                _vm._v(
                                  "\n                                    We've fetched the first 10 rows in your file so you can assign each columns to\n                                    its appropriate property. Take Note that email or user ID is required\n                                "
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-bordered table-csv table-striped table-sm",
                          },
                          [
                            _c("thead", [
                              _c(
                                "tr",
                                [
                                  _c("th"),
                                  _vm._v(" "),
                                  _vm._l(_vm.header_map, function (th, i) {
                                    return _c(
                                      "th",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "select-table-header",
                                            attrs: {
                                              clearable: "",
                                              placeholder:
                                                "Select proper column type",
                                            },
                                            on: { change: _vm.headerMapChange },
                                            model: {
                                              value: _vm.header_map[i],
                                              callback: function ($$v) {
                                                _vm.$set(_vm.header_map, i, $$v)
                                              },
                                              expression: "header_map[i]",
                                            },
                                          },
                                          _vm._l(
                                            _vm.user_fields,
                                            function (uf) {
                                              return _c("el-option", {
                                                key: uf.value,
                                                attrs: {
                                                  label: uf.label,
                                                  value: uf.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.data_map, function (row, index) {
                                return _c(
                                  "tr",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !(
                                          _vm.ignore_first_row && index === 0
                                        ),
                                        expression:
                                          "!(ignore_first_row && index === 0)",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "td",
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(index > 0 ? index + 1 : "") +
                                            "\n                                    "
                                        ),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              content: "Remove this first row",
                                              effect: "dark",
                                              placement: "right",
                                            },
                                          },
                                          [
                                            index === 0
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "px-1",
                                                    attrs: { size: "mini" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.ignore_first_row = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-trash-o",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm._l(row, function (col) {
                                      return _c("td", [_vm._v(_vm._s(col))])
                                    }),
                                  ],
                                  2
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.current_step = 1
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-arrow-circle-left" }),
                            _vm._v(" Back to Settings\n                    "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "ml-auto",
                            attrs: {
                              disabled: !_vm.importable,
                              type: "success",
                            },
                            on: { click: _vm.startImport },
                          },
                          [
                            _c("strong", [
                              _c("i", { staticClass: "fas fa-file-import" }),
                              _vm._v(" Start Import"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.current_step === 3
              ? _c("div", [
                  _c("div", { staticClass: "container-import-summary" }, [
                    _c("h2", [
                      _c("i", { staticClass: "fa fa-check" }),
                      _vm._v(
                        "\n                        Importing users to " +
                          _vm._s(_vm.import_model.ring_group.name) +
                          "...\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "fas fa-file-import" }),
                    _vm._v(" "),
                    _c("p", { staticClass: "lead" }, [
                      _vm._v("This might take a moment. "),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "lead" }, [
                      _vm._v(
                        "\n                        You can see the progress of the import in Account » Imports » Ring Groups page.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.closeImportWizard } },
                        [_vm._v("Close")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Ring Group Dialog",
                                  params: {
                                    ring_group_id:
                                      _vm.import_model.ring_group.id,
                                  },
                                },
                              },
                            },
                            [_c("el-button", [_vm._v("Ring Group Settings")])],
                            1
                          ),
                          _vm._v(" "),
                          _vm.$route.name !== "Account"
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Account",
                                      query: {
                                        tab: "imports",
                                        type: _vm.ImportType.TYPE_RING_GROUPS,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { type: "success" } },
                                    [
                                      _vm._v(
                                        "Go to Account » Imports » Ring Groups"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }