var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "integrations banner" }, [
    _c("div", { staticClass: "h3 flex-grow-1 m-0" }, [
      _vm._v("\n        So integrated, you won't know the difference\n    "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text" },
      [
        _vm._v(
          "\n        Aloware integrates into major CRM systems like\n        "
        ),
        _vm._l(_vm.integrations, function (integration, index) {
          return _c(
            "a",
            {
              key: integration.name,
              staticClass: "link",
              attrs: {
                target: "_blank",
                href: _vm.integrationUrl(integration.name),
              },
            },
            [
              _vm._v("\n            " + _vm._s(integration.label)),
              index < _vm.integrations.length - 1
                ? _c("span", [_vm._v(", ")])
                : _vm._e(),
            ]
          )
        }),
        _vm._v(
          "\n        and much more! You can check out all of them\n        "
        ),
        _c(
          "a",
          {
            staticClass: "link",
            attrs: {
              href: "https://aloware.com/integrations/",
              target: "_blank",
            },
          },
          [_vm._v("\n            here.\n        ")]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }