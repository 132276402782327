<template>
    <el-form :model="chatbot">
        <div class="row">
            <div class="col">
                <el-form-item>
                    <div class="form-label">
                        <h5 class="text-dark">Name (*)</h5>
                        <small>
                            Specify a name for this chatbot.
                        </small>
                    </div>
                    <el-input v-model="chatbot.name"/>
                </el-form-item>
            </div>
            <div class="col">
                <el-form-item>
                    <div class="form-label">
                        <h5 class="text-dark">Lines (*)</h5>
                        <small>
                            Select 1 or multiple lines to connect to this chatbot.
                        </small>
                    </div>
                    <el-select
                        class="w-100"
                        v-model="selected_campaigns"
                        multiple
                        filterable
                        clearable
                        placeholder="Select lines">
                        <el-option
                            v-for="line in lines"
                            :key="line.id"
                            :label="line.name"
                            :value="line.id"/>
                    </el-select>
                </el-form-item>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <el-form-item>
                    <div class="form-label">
                        <h5 class="text-dark">Description</h5>
                    </div>
                    <el-input
                        type="textarea"
                        :rows="2"
                        v-model="chatbot.description"/>
                </el-form-item>
            </div>
            <div class="col">
                <el-form-item
                    v-if="chatbot.use_case === AloAi.USE_CASE_SALES">
                    <div class="form-label">
                        <h5 class="text-dark">Opener (*)</h5>
                    </div>
                    <el-input
                        v-model="chatbot.opener"
                        type="textarea"
                    ></el-input>
                    <el-tooltip content="Add variable"
                                placement="top">
                        <variables
                            @variable-selected="variableSelected">
                        </variables>
                    </el-tooltip>
                </el-form-item>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <el-form-item>
                    <div class="form-label">
                        <h5 class="text-dark">Use case (*)</h5>
                    </div>
                    <el-select
                        class="w-100"
                        v-model="chatbot.use_case">
                        <el-option
                            v-for="use_case in useCases"
                            :key="use_case.value"
                            :label="use_case.name"
                            :value="use_case.value"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="col">
                <el-form-item>
                    <div class="form-label">
                        <h5 class="text-dark">Model (*)</h5>
                    </div>
                    <el-select
                        class="w-100"
                        v-model="chatbot.model">
                        <el-option
                            v-for="model in models"
                            :key="model.value"
                            :label="model.name"
                            :value="model.value"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="col">
                <el-form-item>
                    <div class="form-label">
                        <h5 class="text-dark">TTL (*)</h5>
                    </div>
                    <el-input-number
                        class="w-100"
                        v-model="chatbot.ttl"
                        :min="1"
                        :max="20160"
                        :precision="0"
                        :controls="false"/>
                </el-form-item>
            </div>
        </div>
    </el-form>
</template>

<script>
import * as AloAi from '../../../constants/aloai'
import Variables from '../../messenger/variables.vue'

export default {
    name: 'aloai-form-settings',
    components: {Variables},
    props: {
        lines: {
            required: false,
            default: []
        },
        chatbot: {
            required: true,
        },
    },
    data() {
        return {
            models: [{
                name: 'GPT-4o Mini',
                value: AloAi.MODEL_GPT_4O_MINI
            }, {
                name: 'GPT-4o',
                value: AloAi.MODEL_GPT_4O
            }],
            useCases: [{
                name: 'Sales',
                value: AloAi.USE_CASE_SALES
            }, {
                name: 'Question and Answer',
                value: AloAi.USE_CASE_QUESTION_AND_ANSWER
            }, {
                name: 'Support',
                value: AloAi.USE_CASE_SUPPORT
            }],
            loading: false,
            selected_campaigns: [],
            AloAi
        }
    },
    methods: {
        variableSelected(variable) {
            this.chatbot.opener += `${variable}`
        }
    },
    watch: {
        'chatbot': function (newVal) {
            if (newVal.campaign_ids) {
                this.selected_campaigns = newVal.campaign_ids
            }
        },
        'selected_campaigns': function (newVal) {
            this.chatbot.campaign_ids = newVal
        }
    }
}
</script>
