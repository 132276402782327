var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "security-code clearfix" }, [
    _c(
      "div",
      { staticClass: "security-code-wrapper" },
      _vm._l(_vm.securityCodeLength, function (n) {
        return _c("div", { key: n, staticClass: "security-code-field" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.securityCode[n - 1],
                expression: "securityCode[n-1]",
              },
            ],
            staticClass: "form-control r-3x is-valid",
            attrs: {
              maxlength: "1",
              autocorrect: "off",
              autocomplete: "off",
              autocapitalize: "off",
              spellcheck: "false",
              type: "tel",
              id: "security_input_" + n,
            },
            domProps: { value: _vm.securityCode[n - 1] },
            on: {
              focus: _vm.setSelected,
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.securityCode, n - 1, $event.target.value)
                },
                function ($event) {
                  $event.stopPropagation()
                  return _vm.inputEvent.apply(null, arguments)
                },
              ],
              keydown: function ($event) {
                $event.stopPropagation()
                return _vm.downEvent.apply(null, arguments)
              },
              keypress: function ($event) {
                $event.stopPropagation()
                return _vm.pressEvent.apply(null, arguments)
              },
              paste: function ($event) {
                return _vm.pasteEvent(n - 1, $event)
              },
            },
          }),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }