var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    { staticClass: "item pointer", attrs: { placement: "top" } },
    [
      _c("div", { attrs: { slot: "content" }, slot: "content" }, [
        _vm._v(_vm._s(_vm._f("fixFullDateUTCRelative")(_vm.from_time))),
      ]),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.relative_time) } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }