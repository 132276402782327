var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hubspotEnabled
    ? _c(
        "div",
        { staticClass: "d-flex flex-column width-400 fixed" },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-row align-items-center" },
            [
              _c("el-input", {
                staticClass: "d-flex width-400 fixed mb-2",
                attrs: { placeholder: "Ticket name" },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
              _vm._v(" "),
              _c("variables", {
                staticClass: "ml-2",
                on: { "variable-selected": _vm.nameVariableSelected },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-row align-items-center mb-2" },
            [
              _c(
                "el-select",
                {
                  staticClass: "d-flex flex-grow-1 flex-shrink-0",
                  attrs: {
                    placeholder: _vm.pipelinePlaceholder,
                    loading: _vm.loading_hubspot_ticketing_pipelines,
                    disabled:
                      _vm.loading_hubspot_ticketing_pipelines ||
                      !_vm.hubspot_ticketing_pipelines.length,
                    filterable: "",
                    clearable: "",
                    "default-first-option": "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.pipeline_id,
                    callback: function ($$v) {
                      _vm.pipeline_id = $$v
                    },
                    expression: "pipeline_id",
                  },
                },
                _vm._l(_vm.orderedPipelines, function (pipeline) {
                  return _c("el-option", {
                    key: pipeline.pipelineId,
                    attrs: {
                      label: pipeline.label,
                      value: pipeline.pipelineId,
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("el-button", {
                staticClass: "ml-2 w-30",
                attrs: {
                  type: "primary",
                  icon: "fa fa-refresh",
                  size: "mini",
                  disabled: _vm.loading_hubspot_ticketing_pipelines,
                  loading: _vm.loading_hubspot_ticketing_pipelines,
                  circle: "",
                },
                on: { click: _vm.getHubspotTicketingPipeline },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "d-flex flex-grow-1 flex-shrink-0 mb-2",
              attrs: {
                placeholder: _vm.stagePlaceholder,
                loading: _vm.loading_hubspot_ticketing_pipelines,
                disabled:
                  _vm.loading_hubspot_ticketing_pipelines ||
                  !_vm.hubspot_ticketing_pipelines.length ||
                  !_vm.activePipeline,
                filterable: "",
                clearable: "",
                "default-first-option": "",
                "collapse-tags": "",
              },
              model: {
                value: _vm.stage_id,
                callback: function ($$v) {
                  _vm.stage_id = $$v
                },
                expression: "stage_id",
              },
            },
            _vm._l(_vm.orderedStages, function (stage) {
              return _c("el-option", {
                key: stage.stageId,
                attrs: { label: stage.label, value: stage.stageId },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-row align-items-center" },
            [
              _c("el-input", {
                staticClass: "d-flex width-400 fixed",
                attrs: { placeholder: "Ticket description" },
                model: {
                  value: _vm.description,
                  callback: function ($$v) {
                    _vm.description = $$v
                  },
                  expression: "description",
                },
              }),
              _vm._v(" "),
              _c("variables", {
                staticClass: "ml-2",
                on: { "variable-selected": _vm.ticketVariableSelected },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }