var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-2 px-3 border border-warning mb-3" },
    [
      _c(
        "el-tooltip",
        {
          attrs: {
            placement: "top",
            effect: "dark",
            content:
              "Any changes made at the account level will cascade down to all lines, overriding individual line settings unless ‘Follow Line’s Setting’ is chosen.",
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "form-label" }, [
              _c(
                "h5",
                [
                  _vm._v(
                    "\n                    Feature Overview\n                    "
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right",
                        width: "450",
                        trigger: "hover",
                      },
                    },
                    [
                      _c("div", [
                        _c("span", { staticClass: "d-flex mb-2" }, [
                          _vm._v("Retention Duration Options:"),
                        ]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "mb-0" }, [
                          _c("li", [
                            _vm._v(
                              "Indefinite: Keep recordings without an expiration date. This is the default\n                                    setting.\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "Follow Line’s Setting: If set at the company level, recordings will adhere to\n                                    each line’s specific retention settings.\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "Timed Deletion Options: Choose from 10, 30, or 60 days for automatic recording\n                                    deletion, optimizing storage and compliance.\n                                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "d-flex mb-2" }, [
                          _vm._v("How to Modify Settings:"),
                        ]),
                        _vm._v(" "),
                        _c("ul", { staticClass: "mb-0" }, [
                          _c("li", [
                            _vm._v(
                              "Navigate to the ‘Account Settings’ in your admin panel."
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "Look for the ‘Call Recording Retention’ section."
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "Click ‘Modify’ to set the preferred retention duration."
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "Select from the predefined options, or set a custom duration to meet your\n                                    company’s policy.\n                                "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa fa-info-circle text-primary ml-2",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("small", [
                _c("span", { staticClass: "d-flex" }, [
                  _vm._v(
                    "Set by Admin in Account Settings: Billing can now define how long call recordings are kept."
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "d-flex" }, [
                  _vm._v(
                    "Company Preference Priority: The company-wide setting takes precedence, ensuring all lines follow a unified retention policy."
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }