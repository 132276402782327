<template>
    <div v-if="hasPermissionTo('create tag')">
        <!--create new tag-->
        <el-dialog :title="title"
                   class="dialog-padding auto"
                   :width="isLargeEnough ? '30%' : '100%'"
                   :visible.sync="hide_add"
                   :before-close="beforeCloseModal"
                   data-testid="tag-creator-dialog"
                   append-to-body>
            <el-form ref="add_tag"
                     class="mt-2 p-0"
                     label-position="top"
                     :rules="rules_tag"
                     :model="tag"
                     data-testid="tag-creator-form"
                     @submit.prevent.native="addTag">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item prop="name"
                                      label="Name">
                            <div>
                                <el-input v-model="tag.name"
                                          data-testid="tag-creator-name-input"
                                          @input="preValidateForm('add_tag')">
                                </el-input>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item
                            class="tag-color-picker"
                            prop="color"
                            label="Choose a color">
                            <el-color-picker
                                class="d-block"
                                v-model="tag.color"
                                data-testid="tag-creator-color-picker"
                                @active-change="changeTagColor"
                                @change="preValidateForm('add_tag')">
                            </el-color-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item
                            prop="category"
                            label="Category"
                            v-if="!category_prop">
                            <div>
                                <el-select class="w-full"
                                           data-testid="tag-creator-category-select"
                                           v-model="tag.category">
                                    <el-option v-for="category in categories"
                                               :value="category"
                                               :label="category | translateTagCategory"
                                               :key="category">
                                    </el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="description"
                                      label="Description (Optional)">
                            <div>
                                <el-input type="textarea"
                                          rows="2"
                                          v-model="tag.description"
                                          data-testid="tag-creator-description-textarea"
                                          @input="preValidateForm('add_tag')">
                                </el-input>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div class="row pb-3">
                <div class="col-12">
                    <el-button type="success"
                               :disabled="loading_btn"
                               class="pull-right ml-2"
                               data-testid="tag-creator-add-button"
                               @click="addTag">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="fa fa-plus"
                           v-show="!loading_btn"></i>
                        <span>Add</span>
                    </el-button>

                    <el-button type="info"
                               class="pull-right"
                               data-testid="tag-creator-cancel-input"
                               @click="hide_add = false">
                        <span class="fa fa-close"></span>
                        <span>Cancel</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <slot name="trigger">
            <el-button slot="reference"
                       type="text text-sm text-dark-greenish"
                       data-testid="tag-creator-add-tag-slot"
                       @click="hide_add = true">
                <i class="el-icon-circle-plus-outline"></i>
                Add Tag
            </el-button>
        </slot>
    </div>
</template>

<script>
    import auth from '../auth'
    import {
        acl_mixin,
        form_validation_mixin
    } from '../mixins'
    import {mapState} from 'vuex'
    import * as TagCategory from '../constants/tag-categories'

    export default {
        mixins: [
            acl_mixin,
            form_validation_mixin
        ],

        props: {
            title: {
                required: false,
                type: String,
                default: 'Create a new tag'
            },
            is_filter_tags: {
                required: false,
                type: Boolean,
                default: false
            },
            filters: {
                required: false,
                type: Object,
                default: null
            },
            category_prop: {
              required: false,
              type: Number,
              default: null
            }
        },

        data() {
            return {
                auth: auth,
                loading_btn: false,
                loading_tag: false,
                hide_add: false,
                tag: {
                    color: '#409EFF',
                    name: null,
                    description: null,
                    category: this.category_prop ? this.category_prop : null,
                },
                categories: [TagCategory.CAT_CONTACTS, TagCategory.CAT_COMMUNICATIONS],
                rules_tag: {
                    color: [
                        {
                            required: true,
                            message: 'Please select a tag color',
                            trigger: 'blur'
                        }
                    ],
                    name: [
                        {
                            required: true,
                            message: 'Please provide a tag name',
                            trigger: 'blur'
                        }
                    ],
                    category: [
                      {
                        required: this.category_prop === null,
                        message: 'Please select a category',
                        trigger: 'blur'
                      }
                    ],
                }
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            isLargeEnough() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            },
        },
        methods: {
            addTag() {
                if (this.validateForm('add_tag')) {
                    this.loading_btn = true
                    let url = '/api/v1/tag'
                    let data = _.clone(this.tag)
                    if (this.is_filter_tags) {
                        url = '/api/v1/save-filters-tag'
                        data.filters = this.filters
                        data.filters = _.pickBy(data.filters, _.identity);
                    }
                    axios.post(url, data)
                        .then(res => {
                            this.loading_btn = false
                            this.hide_add = false
                            this.$emit('success', res.data)
                            this.resetTag()
                        })
                        .catch(err => {
                            this.$root.handleErrors(err.response)
                            this.loading_btn = false
                            this.resetTag()
                        })
                }
            },

            resetTag() {
                this.tag = {
                    color: '#409EFF',
                    name: null,
                    description: null,
                    category: this.category_prop ? this.category_prop : null,
                }
            },

            changeTagColor(val) {
                this.tag.color = val
            },

            beforeCloseModal(done) {
                if (this.tag.color || this.tag.name) {
                    this.$confirm('Are you sure you want to leave? Your changes will not be saved.', 'Warning', {
                        confirmButtonText: 'Yes, Leave',
                        cancelButtonText: 'No, Stay',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(res => {
                        this.resetTag()
                        done()
                    }).catch(() => {

                    })
                } else {
                    this.resetTag()
                    done()
                }
            },
        },

        watch: {
            hide_add() {
                if (this.hide_add) {
                    this.$emit('hide')
                } else {
                    this.$emit('show')
                }
            }
        }
    }
</script>
