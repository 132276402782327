var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12" },
      [
        _c(
          "AuditsTable",
          {
            ref: "auditTable",
            attrs: {
              model_alias: "line",
              model_id: _vm.campaign_id,
              action_filters: _vm.AuditConfig.actionFilters,
              text_configs: _vm.AuditConfig.textConfigs,
              filter_options: _vm.filter_options,
            },
            scopedSlots: _vm._u([
              {
                key: "user-selector",
                fn: function ({ onUserFilterChange }) {
                  return [
                    _c("user-selector", {
                      attrs: {
                        ignore_focus_mode: true,
                        placeholder: "Search Agent",
                      },
                      on: { change: onUserFilterChange },
                    }),
                  ]
                },
              },
              {
                key: "date-selector",
                fn: function () {
                  return [
                    _c("date-selector", {
                      staticClass: "filter-select-audits",
                      attrs: { disable_default_report_period: true },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [_vm._v("Line Activity Audit Log\n      ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }