var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teams-tab" },
    [
      _c("div", { staticClass: "header" }, [
        _c("h5", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.user.first_name) + " Team"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex w-50 justify-content-end" },
          [
            _c(
              "el-button",
              {
                staticClass: "w-25",
                attrs: {
                  slot: "reference",
                  type: "success",
                  "data-testid": "team-creator-open-modal-button",
                },
                on: { click: _vm.openAddUserToTeamModal },
                slot: "reference",
              },
              [_vm._v("\n                Add to new team\n            ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "actions-bar" }, [
        _c(
          "div",
          { staticClass: "d-flex w-50 align-items-center" },
          [
            _c("el-input", {
              staticClass: "w-60 mr-2",
              attrs: {
                placeholder: "Search Teams",
                "prefix-icon": "el-icon-search",
                clearable: "",
              },
              model: {
                value: _vm.searchInput,
                callback: function ($$v) {
                  _vm.searchInput = $$v
                },
                expression: "searchInput",
              },
            }),
            _vm._v(" "),
            _vm.selectedTeamsToRemove.length
              ? _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("span", { staticClass: "text mr-2" }, [
                      _vm._v(_vm._s(_vm.selectedTeamsLabel)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c("el-button", {
                          attrs: { icon: "el-icon-more", size: "mini" },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("el-dropdown-item", [
                              _c(
                                "span",
                                {
                                  staticClass: "el-dropdown-menu__item--delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openRemoveUserFromTeamModal()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Remove " +
                                      _vm._s(_vm.user.first_name) +
                                      " from teams\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.teams.length
          ? _c(
              "div",
              { staticClass: "d-flex align-items-center justify-content-end" },
              [
                _c("span", { staticClass: "text" }, [
                  _vm._v("Total Teams: " + _vm._s(_vm.teams.length)),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoadingData,
              expression: "isLoadingData",
            },
          ],
          ref: "company-teams-list",
          staticClass: "w-full",
          attrs: { stripe: "", data: _vm.filteredTeams },
          on: { "selection-change": _vm.handleSelectedTeamsToRemoveChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", "show-header": false },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Team", prop: "name", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(scope.row?.name) +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Description",
              prop: "description",
              sortable: "",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(scope.row?.description || "-") +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Ring Groups",
              prop: "ring_groups",
              align: "center",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.total_ring_groups || 0)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Users",
              prop: "users",
              align: "center",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.total_users || 0))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Created by",
              prop: "created_by",
              "sort-by": "created_by",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getFullName(scope.row?.created_by)) +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Created on", prop: "created_at", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("fixFullDateLocal")(scope.row?.created_at)
                        ) +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c("el-button", {
                          attrs: { icon: "el-icon-more", size: "mini" },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("el-dropdown-item", [
                              _c(
                                "span",
                                {
                                  staticClass: "el-dropdown-menu__item--delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openRemoveUserFromTeamModal(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Remove " +
                                      _vm._s(_vm.user.first_name) +
                                      " from team\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("add-user-to-team-modal", {
        attrs: {
          "is-visible": _vm.isAddUserToTeamModalVisible,
          user: _vm.user,
          teams: _vm.teams,
          "is-loading-user-teams": _vm.isLoadingUserTeams,
        },
        on: {
          "update:isVisible": function ($event) {
            _vm.isAddUserToTeamModalVisible = $event
          },
          "update:is-visible": function ($event) {
            _vm.isAddUserToTeamModalVisible = $event
          },
          "fetch-user-teams": function ($event) {
            return _vm.fetchUserTeams(_vm.user?.id)
          },
          close: _vm.closeAddUserToTeamModal,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            center: "",
            visible: _vm.isRemoveUserFromTeamModalVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isRemoveUserFromTeamModalVisible = $event
            },
            close: _vm.closeRemoveUserFromTeamModal,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog--title mt-4",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.removeUserFromTeamModalConfig.title) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", {
            staticClass: "break-word text-center",
            domProps: {
              innerHTML: _vm._s(_vm.removeUserFromTeamModalConfig.description),
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "break-word text-center" }, [
            _vm._v("Are you sure?"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex justify-content-center row mt-5" },
            [
              _c(
                "el-button",
                {
                  staticClass: "mb-2",
                  attrs: { slot: "reference", type: "default" },
                  on: { click: _vm.closeRemoveUserFromTeamModal },
                  slot: "reference",
                },
                [_vm._v("\n                No, Keep Ring Group\n            ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mb-2",
                  attrs: {
                    slot: "reference",
                    type: "danger",
                    "data-testid": "team-remove-ring-group-button",
                    disabled: _vm.isLoadingData,
                  },
                  on: { click: _vm.onRemoveTeamsFromUser },
                  slot: "reference",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.removeUserFromTeamModalConfig.buttonText) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }