var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "transcription_reporting_panel" },
    [
      _c("communication-textual-filters"),
      _vm._v(" "),
      _vm.companyHasTranscriptionEnabled
        ? _c("transcription-reporting-components", {
            attrs: {
              campaign_id: _vm.campaign_id,
              user_id: _vm.user_id,
              ring_group_id: _vm.ring_group_id,
              broadcast_id: _vm.broadcast_id,
            },
          })
        : _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-8" }, [
              _c("img", {
                attrs: {
                  src: "/assets/images/transcriptions-demo-page.svg",
                  "data-testid": "transcription-reporting-panel-img",
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 text-center mt-5" },
              [
                _c("h5", { staticClass: "text-dark" }, [
                  _c(
                    "a",
                    {
                      staticClass: "text-primary",
                      attrs: {
                        href: "https://support.aloware.com/en/articles/9037579-aloware-s-smart-transcription",
                        "data-testid":
                          "transcription-reporting-click-here-link",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Click here\n                "
                      ),
                    ]
                  ),
                  _vm._v(
                    '\n                for more information. To start your free trial click on the "Request Free Trial" button.\n            '
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "mt-3",
                    attrs: {
                      type: "success",
                      size: "large",
                      "data-testid":
                        "transcription-reporting-request-free-trial-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.requestFreeTrial()
                      },
                    },
                  },
                  [_vm._v("\n                Request Free Trial\n            ")]
                ),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }