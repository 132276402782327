<template>
    <el-popover placement="right-start"
                width="350"
                trigger="click"
                @show="resetForm">
        <el-form>
            <h6 class="mb-2 text-black">Assign contacts to</h6>
            <el-form-item class="mb-3">
                <el-tabs v-model="tab_name">
                    <el-tab-pane label="User"
                                 name="user">
                        <p class="lh-1p4 mb-2 text-dark">Assign the leads to this user</p>
                        <user-selector ref="userSelector"
                                       v-model="user_id"
                                       :hide_extensions="true"
                                       clearable
                                       @change="changeUserId">
                        </user-selector>
                    </el-tab-pane>
                    <el-tab-pane label="Ring Group"
                                 name="ring_group">
                        <p class="lh-1p4 mb-2 text-dark">Assign the leads evenly and randomly between the users on this ring group</p>
                        <ring-group-selector v-model="ring_group_id"
                                             @change="ringGroupChange">
                        </ring-group-selector>
                    </el-tab-pane>
                </el-tabs>
            </el-form-item>

            <el-form-item class="mt-2">
                <div scope="label"
                     class="form-label text-dark">
                    <p class="lh-1p4">
                        By default, this tool only distributes unassigned contacts.
                    </p>
                </div>
                <div scope="label"
                     class="form-label mb-1">
                    <el-checkbox v-model="force"
                                 label="Also distribute assigned contacts"
                                 class="w-full">
                    </el-checkbox>
                </div>
            </el-form-item>

            <div>
                <el-button type="success"
                           size="small"
                           class="w-full"
                           :disabled="unassignable"
                           @click="assignContactTag">
                    <i class="fas fa-sign-in-alt"></i> Assign
                </el-button>
            </div>
        </el-form>
        <button class="btn btn-block btn-warning btn-sm"
                slot="reference">
            <i class="fas fa-sign-in-alt pull-left"></i>
            <slot>Assign Contacts</slot>
        </button>
    </el-popover>
</template>

<script>
import auth from "../auth"
import RingGroupSelector from "../components/ring-group-selector"

export default {
    name: "assign-contacts-by-tag",

    props: {
        tag: {
            type: Object,
            required: true
        }
    },

    components: {
        RingGroupSelector
    },

    data() {
        return {
            auth: auth,
            tab_name: 'user',
            ring_group_id: null,
            user_id: null,
            force: false
        }
    },

    computed: {
        model() {
            return {
                'user': 'User',
                'ring_group': 'Ring Group'
            }[this.tab_name]
        },

        unassignable() {
            return (this.tab_name === 'user' && !this.user_id) || (this.tab_name === 'ring_group' && !this.ring_group_id)
        }
    },

    methods: {
        assignContactTag() {
            this.$confirm(`Are you sure you want the contacts under this tag to be assigned to this ${this.model}?`, 'Event', {
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: 'warning',
                customClass: 'width-500 fixed'
            }).then(() => {
                let data = {
                    assign_contacts_to: this.tab_name,
                    user_id: this.user_id,
                    ring_group_id: this.ring_group_id,
                    force: this.force
                }
                axios.post(`/api/v1/tags/${this.tag.id}/assign-contacts-to`, data).then(res => {
                    this.$notify({
                        offset: 95,
                        title: 'Assignment',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                    })
                    this.resetForm()
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    console.log(err)
                })
            })
        },

        changeUserId(user_id) {
            this.user_id = user_id
            this.tab_name = 'user'
        },

        ringGroupChange(ring_group_id) {
            this.ring_group_id = ring_group_id
            this.tab_name = 'ring_group'
        },

        resetForm() {
            this.ring_group_id = null
            this.user_id = null
            this.tab_name = 'user'
        }
    },
}
</script>
