var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "vertical-center text-left mb-4" }, [
      _vm.filter.from_date
        ? _c("span", { staticClass: "call-log-head" }, [
            _vm._v(
              "\n\t\t\t\t" +
                _vm._s(_vm.title_prefix) +
                "Contacts from\n                "
            ),
            _c("strong", [
              _vm._v(_vm._s(_vm._f("fixDateTimeFull")(_vm.filter.from_date))),
            ]),
            _vm._v("\n                to\n                "),
            _c("strong", [
              _vm._v(_vm._s(_vm._f("fixDateTimeFull")(_vm.filter.to_date))),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.filter.from_date
        ? _c("span", { staticClass: "call-log-head" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.title_prefix) +
                "Contacts\n                "
            ),
            _c("strong", [_vm._v("All Time")]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c(
          "div",
          {
            staticClass:
              "placeholder text-center d-flex align-items-center justify-content-center",
            class: { blink: _vm.loading },
            staticStyle: { height: "450px" },
          },
          [
            _c(
              "i",
              { staticClass: "material-icons loader text-greenish text-4x" },
              [_vm._v("\n                \n            ")]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { height: "450px" } },
      [
        _vm.report_type == "new_contacts_vs_dnc"
          ? [
              _c("highcharts", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.is_done && _vm.options.series.length > 0,
                    expression: "is_done && options.series.length > 0",
                  },
                ],
                ref: "highchart",
                staticStyle: { height: "450px" },
                attrs: { id: _vm.graph_id, options: _vm.options },
              }),
            ]
          : [
              _c(
                "graph-data-switch",
                {
                  attrs: {
                    report_type: _vm.report_type,
                    series: _vm.options.series,
                    type: _vm.type,
                  },
                },
                [
                  _c("highcharts", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.is_done && _vm.options.series.length > 0,
                        expression: "is_done && options.series.length > 0",
                      },
                    ],
                    ref: "highchart",
                    staticStyle: { height: "450px" },
                    attrs: { id: _vm.graph_id, options: _vm.options },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.is_done && _vm.options.series.length == 0,
                          expression: "is_done && options.series.length == 0",
                        },
                      ],
                      staticClass: "el-table__empty-block",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "el-table__empty-text",
                          staticStyle: { color: "#606266" },
                        },
                        [
                          _vm._v(
                            "\n                            No Data\n                        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _vm.report_type == "new_contacts_vs_dnc"
      ? _c("div", { staticClass: "mt-2" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: !_vm.is_done,
                      expression: "!is_done",
                    },
                  ],
                  ref: "new-contacts-vs-dnc-table",
                  staticClass: "w-full mt-3 activity-reporting-table",
                  attrs: { data: _vm.sortedPaginatedData, fit: "", stripe: "" },
                  on: { "sort-change": _vm.sortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      fixed: "left",
                      label: "Date",
                      prop: "date",
                      sortable: "",
                      width: "auto",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(scope.row.date) +
                                  "\n                        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      653400233
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "New Contacts",
                      prop: "new_contacts",
                      sortable: "",
                      width: "auto",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(scope.row.new_contacts) +
                                  "\n                        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1662783213
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "DNC Contacts",
                      prop: "dnc_contacts",
                      sortable: "",
                      width: "auto",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(scope.row.dnc_contacts) +
                                  "\n                        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1533621496
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-3 d-inline-block w-100 pb-2" },
                [
                  _c("el-pagination", {
                    staticClass: "pull-right",
                    attrs: {
                      "page-size": _vm.per_page,
                      "page-sizes": [10, 20, 50, 100, 250],
                      total: _vm.total,
                      background: "",
                      layout: "sizes, prev, pager, next",
                    },
                    on: {
                      "current-change": _vm.changePage,
                      "size-change": _vm.handleSizeChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }