var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "companySettingsForm",
      staticClass: "form-aloware",
      attrs: { model: _vm.transcription_settings },
    },
    [
      _vm.current_company &&
      _vm.current_company.transcription_settings &&
      _vm.current_company.transcription_settings?.call_transcription_enabled &&
      this.transcription_usage.is_ninety_percent_usage
        ? _c(
            "div",
            { staticClass: "row pb-3" },
            [
              _c("el-alert", { attrs: { "show-icon": "", type: "warning" } }, [
                _c("span", { staticClass: "d-block mb-1 text-black" }, [
                  _vm._v("\n                 You have used "),
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numFormat")(this.transcription_usage.used)
                      ) +
                        " / " +
                        _vm._s(
                          _vm._f("numFormat")(
                            this.transcription_usage.available
                          )
                        )
                    ),
                  ]),
                  _vm._v(" of Smart Transcription minutes.\n                "),
                  !_vm.transcription_settings.overusage_restriction_enabled
                    ? _c("span", [
                        _vm._v(
                          "\n                    Additional minutes will be billed at "
                        ),
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toCurrency")(
                                _vm._f("fixRounding")(
                                  _vm.auth.user.profile.rate.transcription,
                                  2
                                )
                              )
                            )
                          ),
                        ]),
                        _vm._v(" per minute.\n                "),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.current_company &&
      _vm.current_company.transcription_settings?.call_transcription_enabled &&
      _vm.current_company.hubspot_integration_enabled
        ? _c(
            "div",
            { staticClass: "row pb-3" },
            [
              _c("el-alert", { attrs: { "show-icon": "" } }, [
                _c("span", { staticClass: "d-block mb-1" }, [
                  _c("b", [_vm._v("Attention:")]),
                  _vm._v(
                    " HubSpot integration is enabled for this account. To avoid redundancy, consider disabling transcriptions in HubSpot.\n             "
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "div",
            { staticClass: "col-md-7" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "pb-1",
                  attrs: { prop: "call_transcription_enabled" },
                },
                [
                  _c("span", {
                    staticClass: "page-anchor",
                    attrs: { id: "call_transcription_enabled" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("Call Transcription")]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n                        This feature allows you to review your calls with Smart\n                        Transcription\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "switch-label" },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#00a344" },
                        on: { change: _vm.updateSetting },
                        model: {
                          value:
                            _vm.transcription_settings
                              .call_transcription_enabled,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.transcription_settings,
                              "call_transcription_enabled",
                              $$v
                            )
                          },
                          expression:
                            "transcription_settings.call_transcription_enabled",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [_vm._v("Enable call transcription")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled
                    ? [
                        _c(
                          "el-button",
                          {
                            staticClass: "my-2",
                            attrs: { type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.updateTranscriptionEnabledUsers(true)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v("Enable Transcription for all users"),
                            ]),
                            _vm._v(" "),
                            _vm.loading_enable_transcription_for_users
                              ? _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons loader mr-2 pull-left",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            \n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "ml-md-2",
                            attrs: { type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.updateTranscriptionEnabledUsers(
                                  false
                                )
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v("Disable Transcription for all users"),
                            ]),
                            _vm._v(" "),
                            _vm.loading_disable_transcription_for_users
                              ? _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons loader mr-2 pull-left",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            \n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.transcription_settings.call_transcription_enabled
                    ? [
                        _c("p", [
                          !_vm.loading_users_list
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "text-dark-greenish el-green-hover mt-1",
                                  on: {
                                    click: function ($event) {
                                      return _vm.displayUserTranscriptionManagementDialog()
                                    },
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      "\n                                Show all Users Transcription Status\n                                "
                                    ),
                                    _c("i", {
                                      staticClass: "fas fa-angle-right mr-1",
                                    }),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.loading_users_list
                            ? _c(
                                "i",
                                {
                                  staticClass:
                                    "text-dark-greenish material-icons loader mr-2 pull-left mb-1",
                                },
                                [
                                  _vm._v(
                                    "\n                            \n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.transcription_settings?.call_transcription_enabled
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "pb-1",
                      attrs: { prop: "summarization_enabled" },
                    },
                    [
                      _c("span", {
                        staticClass: "page-anchor",
                        attrs: { id: "summarization_enabled" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-label" }, [
                        _c("h5", [_vm._v("Summarization Settings")]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "\n                        This feature allows you to summarize your calls using Alo Voice AI.\n                    "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "switch-label" },
                        [
                          _c("el-switch", {
                            attrs: { "active-color": "#00a344" },
                            on: { change: _vm.updateSetting },
                            model: {
                              value:
                                _vm.transcription_settings
                                  .summarization_enabled,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transcription_settings,
                                  "summarization_enabled",
                                  $$v
                                )
                              },
                              expression:
                                "transcription_settings.summarization_enabled",
                            },
                          }),
                          _vm._v(" "),
                          _c("label", [_vm._v("Enable call summarization")]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.transcription_settings?.summarization_enabled &&
              _vm.transcription_settings?.call_transcription_enabled
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "no-border pb-2",
                      attrs: { prop: "summary_length" },
                    },
                    [
                      _c("span", {
                        staticClass: "page-anchor",
                        attrs: { id: "summary_length" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-label" }, [
                        _c("h5", [_vm._v("Call Summary Length")]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "Target length for the call summary. AI will do its best to honor this value, but it is not guaranteed."
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        attrs: { min: 50, max: 1000, step: 50, size: "mini" },
                        on: { change: _vm.handleInputConfirm },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.transcription_settings.summary_length,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.transcription_settings,
                              "summary_length",
                              $$v
                            )
                          },
                          expression: "transcription_settings.summary_length",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [_vm._v("words")]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.transcription_settings?.summarization_enabled &&
              _vm.transcription_settings?.call_transcription_enabled
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "no-border pb-2",
                      attrs: { prop: "summary_highlights" },
                    },
                    [
                      _c("span", {
                        staticClass: "page-anchor",
                        attrs: { id: "summary_highlights" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-label" }, [
                        _c("h5", [_vm._v("Call Summary Highlights")]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "Include key details in the call summary as well as specify custom highlights."
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "switch-label" },
                        [
                          _c("el-switch", {
                            attrs: { "active-color": "#00a344" },
                            on: { change: _vm.updateSetting },
                            model: {
                              value:
                                _vm.transcription_settings
                                  .summary_custom_prompt_enabled,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transcription_settings,
                                  "summary_custom_prompt_enabled",
                                  $$v
                                )
                              },
                              expression:
                                "transcription_settings.summary_custom_prompt_enabled",
                            },
                          }),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v("Enable custom prompts (not recommended)"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.transcription_settings.summary_custom_prompt_enabled
                        ? _c(
                            "div",
                            { staticClass: "summary_prompt_list" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w-full",
                                  attrs: {
                                    placeholder: "Select highlights",
                                    filterable: "",
                                    multiple: "",
                                  },
                                  on: { change: _vm.handleInputConfirm },
                                  model: {
                                    value:
                                      _vm.transcription_settings
                                        .summary_prompt_list,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.transcription_settings,
                                        "summary_prompt_list",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "transcription_settings.summary_prompt_list",
                                  },
                                },
                                _vm._l(
                                  _vm.summary_highlight_options,
                                  function (summary_highlight_option) {
                                    return _c("el-option", {
                                      key: summary_highlight_option.id,
                                      attrs: {
                                        label: summary_highlight_option.label,
                                        value: summary_highlight_option.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.transcription_settings.summary_custom_prompt_enabled
                        ? _c(
                            "div",
                            { staticClass: "summary_custom_prompt" },
                            [
                              _vm.transcription_settings
                                .summary_custom_prompt_enabled
                                ? _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      maxlength: "1000",
                                      "show-word-limit": "",
                                    },
                                    on: { blur: _vm.handleInputConfirm },
                                    model: {
                                      value:
                                        _vm.transcription_settings
                                          .summary_custom_prompt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.transcription_settings,
                                          "summary_custom_prompt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "transcription_settings.summary_custom_prompt",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.transcription_settings?.summarization_enabled &&
              _vm.transcription_settings?.call_transcription_enabled
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "pb-1",
                      attrs: { prop: "sync_summaries_to_notes" },
                    },
                    [
                      _c("span", {
                        staticClass: "page-anchor",
                        attrs: { id: "sync_summaries_to_notes" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-label" }, [
                        _c("h5", [
                          _vm._v("Sync summaries to notes (CRM activity sync)"),
                        ]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "Include call summaries in the notes during the CRM activity syncing to provide a quick overview of the conversation."
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "switch-label" },
                        [
                          _c("el-switch", {
                            attrs: { "active-color": "#00a344" },
                            on: { change: _vm.updateSetting },
                            model: {
                              value:
                                _vm.transcription_settings
                                  .sync_summaries_to_notes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.transcription_settings,
                                  "sync_summaries_to_notes",
                                  $$v
                                )
                              },
                              expression:
                                "transcription_settings.sync_summaries_to_notes",
                            },
                          }),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v("Enable summary sync with notes"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "pb-1",
                  attrs: { prop: "overusage_restriction_enabled" },
                },
                [
                  _c("span", {
                    staticClass: "page-anchor",
                    attrs: { id: "overusage_restriction_enabled" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("Overusage Billing Restriction")]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n                        Automatically pause the call transcription feature once your account exceeds the allotted minutes of Smart Transcription included in your plan. This ensures you won't incur additional charges of $0.03 per minute for extra transcription minutes.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "switch-label" },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#00a344" },
                        on: { change: _vm.updateSetting },
                        model: {
                          value:
                            _vm.transcription_settings
                              .overusage_restriction_enabled,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.transcription_settings,
                              "overusage_restriction_enabled",
                              $$v
                            )
                          },
                          expression:
                            "transcription_settings.overusage_restriction_enabled",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v(
                          "\n                        Turn off call transcriptions automatically when monthly plan limit is reached\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "pb-1", attrs: { prop: "minimum_talk_time" } },
                [
                  _c("span", {
                    staticClass: "page-anchor",
                    attrs: { id: "minimum_talk_time" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("Minimum Talk-Time")]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n                        Calls will only be transcribed when talk time is greater than this limit.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    attrs: {
                      min: 0,
                      max: _vm.max_talk_time,
                      step: 15,
                      size: "mini",
                    },
                    on: { change: _vm.handleInputConfirm },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.transcription_settings.minimum_talk_time,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.transcription_settings,
                          "minimum_talk_time",
                          $$v
                        )
                      },
                      expression: "transcription_settings.minimum_talk_time",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-2" }, [_vm._v("Seconds")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-sm" }, [
                    _vm._v(
                      "\n                    Current limit is 5 minutes. Contact support if you need to increase this limit.\n                "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.company_clone.transcription_enabled
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "pb-1",
                      attrs: { prop: "min_max_communication_duration" },
                    },
                    [
                      _c("span", {
                        staticClass: "page-anchor",
                        attrs: { id: "minimum_talk_time" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-label" }, [
                        _c("h5", [_vm._v("Sync transcriptions with CRM")]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "\n                        Transcriptions will only be synced when talk time is between this limit.\n                    "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        attrs: {
                          min: 0,
                          max: _vm.transcription_settings
                            .transcription_sync_end_seconds,
                          step: 15,
                          size: "mini",
                        },
                        on: { change: _vm.handleInputConfirm },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value:
                            _vm.transcription_settings
                              .transcription_sync_start_seconds,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.transcription_settings,
                              "transcription_sync_start_seconds",
                              $$v
                            )
                          },
                          expression:
                            "transcription_settings.transcription_sync_start_seconds",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [_vm._v("Seconds")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [_vm._v("AND")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }),
                      _vm._v(" "),
                      _c("el-input-number", {
                        attrs: {
                          min: _vm.transcription_settings
                            .transcription_sync_start_seconds,
                          step: 15,
                          size: "mini",
                        },
                        on: { change: _vm.handleInputConfirm },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value:
                            _vm.transcription_settings
                              .transcription_sync_end_seconds,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.transcription_settings,
                              "transcription_sync_end_seconds",
                              $$v
                            )
                          },
                          expression:
                            "transcription_settings.transcription_sync_end_seconds",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [_vm._v("Seconds")]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "pb-1", attrs: { prop: "custom_keywords" } },
                [
                  _c("span", {
                    staticClass: "page-anchor",
                    attrs: { id: "custom_keywords" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("Custom Keywords")]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n                        Custom Keywords will be counted in Smart Transcription Panel.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.transcription_settings.custom_keywords,
                    function (custom_keyword) {
                      return _c(
                        "el-tag",
                        {
                          key: custom_keyword,
                          staticClass: "m-1",
                          staticStyle: { "font-size": "15px!important" },
                          attrs: {
                            closable: "",
                            type: "warning",
                            "disable-transitions": false,
                          },
                          on: {
                            close: function ($event) {
                              return _vm.handleKeywordsClose(custom_keyword)
                            },
                          },
                        },
                        [_c("b", [_vm._v(_vm._s(custom_keyword))])]
                      )
                    }
                  ),
                  _vm._v(" "),
                  _vm.keyword_input_visible
                    ? _c("el-input", {
                        ref: "saveKeywordInput",
                        staticStyle: { width: "120px" },
                        attrs: { size: "medium" },
                        on: { blur: _vm.handleInputConfirm },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                            return _vm.handleInputConfirm.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.custom_keyword_value,
                          callback: function ($$v) {
                            _vm.custom_keyword_value = $$v
                          },
                          expression: "custom_keyword_value",
                        },
                      })
                    : _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.showKeywordInput },
                        },
                        [
                          _vm._v(
                            "\n                    + New Keyword\n                "
                          ),
                        ]
                      ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "pb-1", attrs: { prop: "custom_dictionary" } },
                [
                  _c("span", {
                    staticClass: "page-anchor",
                    attrs: { id: "custom_dictionary" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("Custom Dictionary")]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n                        Custom Dictionary words will be boosted to match them in the\n                        transcription.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.transcription_settings.custom_dictionary,
                    function (dictionary_word) {
                      return _c(
                        "el-tag",
                        {
                          key: dictionary_word,
                          staticClass: "m-1",
                          staticStyle: { "font-size": "15px" },
                          attrs: { closable: "", "disable-transitions": false },
                          on: {
                            close: function ($event) {
                              return _vm.handleDictionaryClose(dictionary_word)
                            },
                          },
                        },
                        [_c("b", [_vm._v(_vm._s(dictionary_word))])]
                      )
                    }
                  ),
                  _vm._v(" "),
                  _vm.dictionary_input_visible
                    ? _c("el-input", {
                        ref: "saveDictionaryWordInput",
                        staticStyle: { width: "120px" },
                        attrs: { size: "medium" },
                        on: { blur: _vm.handleInputConfirm },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                            return _vm.handleInputConfirm.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.dictionary_word_value,
                          callback: function ($$v) {
                            _vm.dictionary_word_value = $$v
                          },
                          expression: "dictionary_word_value",
                        },
                      })
                    : _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.showDictionaryInput },
                        },
                        [
                          _vm._v(
                            "\n                    + New Word\n                "
                          ),
                        ]
                      ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "pb-1",
                  attrs: { prop: "automatic_redaction_enabled" },
                },
                [
                  _c("span", {
                    staticClass: "page-anchor",
                    attrs: { id: "automatic_redaction_enabled" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("Automatic Redaction")]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n                        Sensitive data in conversations can be redacted by enabling this\n                        feature.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "switch-label" },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#00a344" },
                        on: { change: _vm.updateSetting },
                        model: {
                          value:
                            _vm.transcription_settings
                              .automatic_redaction_enabled,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.transcription_settings,
                              "automatic_redaction_enabled",
                              $$v
                            )
                          },
                          expression:
                            "transcription_settings.automatic_redaction_enabled",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [_vm._v("Enable automatic redaction")]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "pb-1",
                  attrs: { prop: "content_moderation_enabled" },
                },
                [
                  _c("span", {
                    staticClass: "page-anchor",
                    attrs: { id: "content_moderation_enabled" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-label" }, [
                    _c("h5", [_vm._v("Sensitive Content Moderation")]),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n                        By enabling this Feature, any conversations regarding sensitive\n                        issues like drugs, racism and other inappropiate subjects will be\n                        flagged automatically.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "switch-label" },
                    [
                      _c("el-switch", {
                        attrs: { "active-color": "#00a344" },
                        on: { change: _vm.updateSetting },
                        model: {
                          value:
                            _vm.transcription_settings
                              .content_moderation_enabled,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.transcription_settings,
                              "content_moderation_enabled",
                              $$v
                            )
                          },
                          expression:
                            "transcription_settings.content_moderation_enabled",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v("Enable sensitive content moderation"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-2 d-none d-md-block ml-auto" }, [
            _c(
              "div",
              {
                staticClass:
                  "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
              },
              [
                _c("h6", { staticClass: "list-group-item" }, [
                  _vm._v("On This Page"),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "list-group-item list-group-item-action",
                    attrs: { href: "#call_transcription_enabled" },
                    on: {
                      click: function ($event) {
                        return _vm.shine("call_transcription_enabled")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Call Transcription\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.transcription_settings.call_transcription_enabled
                  ? _c(
                      "a",
                      {
                        staticClass: "list-group-item list-group-item-action",
                        attrs: { href: "#summarization_enabled" },
                        on: {
                          click: function ($event) {
                            return _vm.shine("summarization_enabled")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Summarization Settings\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.transcription_settings.summarization_enabled &&
                _vm.transcription_settings.call_transcription_enabled
                  ? _c(
                      "a",
                      {
                        staticClass: "list-group-item list-group-item-action",
                        attrs: { href: "#summary_length" },
                        on: {
                          click: function ($event) {
                            return _vm.shine("summary_length")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Call Summary Length\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.transcription_settings.summarization_enabled &&
                _vm.transcription_settings.call_transcription_enabled
                  ? _c(
                      "a",
                      {
                        staticClass: "list-group-item list-group-item-action",
                        attrs: { href: "#summary_highlights" },
                        on: {
                          click: function ($event) {
                            return _vm.shine("summary_highlights")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Call Summary Highlights\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.transcription_settings.summarization_enabled &&
                _vm.transcription_settings.call_transcription_enabled
                  ? _c(
                      "a",
                      {
                        staticClass: "list-group-item list-group-item-action",
                        attrs: { href: "#sync_summaries_to_notes" },
                        on: {
                          click: function ($event) {
                            return _vm.shine("sync_summaries_to_notes")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Sync summaries to notes (CRM activity sync)\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "list-group-item list-group-item-action",
                    attrs: { href: "#overusage_restriction_enabled" },
                    on: {
                      click: function ($event) {
                        return _vm.shine("overusage_restriction_enabled")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                   Overusage Billing Restriction\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "list-group-item list-group-item-action",
                    attrs: { href: "#minimum_talk_time" },
                    on: {
                      click: function ($event) {
                        return _vm.shine("minimum_talk_time")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Minimum Talk-Time\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "list-group-item list-group-item-action",
                    attrs: { href: "#min_max_communication_duration" },
                    on: {
                      click: function ($event) {
                        return _vm.shine("min_max_communication_duration")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Sync transcriptions with Zoho CRM\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "list-group-item list-group-item-action",
                    attrs: { href: "#custom_keywords" },
                    on: {
                      click: function ($event) {
                        return _vm.shine("custom_keywords")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Custom Keywords\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "list-group-item list-group-item-action",
                    attrs: { href: "#custom_dictionary" },
                    on: {
                      click: function ($event) {
                        return _vm.shine("custom_dictionary")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Custom Dictionary\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "list-group-item list-group-item-action",
                    attrs: { href: "#automatic_redaction_enabled" },
                    on: {
                      click: function ($event) {
                        return _vm.shine("automatic_redaction_enabled")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Automatic Redaction\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "list-group-item list-group-item-action",
                    attrs: { href: "#content_moderation_enabled" },
                    on: {
                      click: function ($event) {
                        return _vm.shine("content_moderation_enabled")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Sensitive Content Moderation\n                "
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: `Users Transcription Status`,
                visible: _vm.users_enablement.display_dialog,
                width: "40%",
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(
                    _vm.users_enablement,
                    "display_dialog",
                    $event
                  )
                },
                close: _vm.closeUserTranscriptionManagementDialog,
              },
            },
            [_c("account-transcription-user-enablement")],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }