<template>
    <div class="app-body"
         id="view">
        <div class="teams p-3" 
             v-if="team?.id">
            <div class="mb-3">
                <h5 class="pt-2">
                    Users
                </h5>

                <div class="d-flex align-items-center justify-content-between pt-1">
                    <div class="d-flex align-items-center w-50">
                        <el-input class="w-50 mr-3"
                                  placeholder="Search Users"
                                  prefix-icon="el-icon-search"
                                  clearable 
                                  v-model="searchUsers" />
                        <div class="d-flex align-items-center"
                             v-if="selectedUsers.length && !isDeleteUsersModalVisible">
                            <span class="font-weight-600 mr-2">{{ selectedUsersLabel }}</span>
                            <el-dropdown trigger="click">
                                <el-button icon="el-icon-more" 
                                           size="mini"/>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <span class="el-dropdown-menu__item--delete"
                                              @click="handleRemoveUsersModal()">
                                            Remove users from {{ team.name }}
                                        </span>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                    <div class="d-flex w-50 justify-content-end">
                        <el-button class="w-25"
                                   slot="reference"
                                   type="success"
                                   data-testid="team-creator-open-modal-button"
                                   @click="openAddUsersModal">
                            Add Users
                        </el-button>
                    </div>
                </div>
            </div>
            <el-table class="w-full mb-3"
                      ref="company-users-team-list"
                      max-height="350"
                      stripe
                      :data="filteredUsers"
                      v-loading="isLoading"
                      @selection-change="handleSelectedUsersChange">
                <el-table-column type="selection"
                                 width="55"
                                 :show-header="false">
                </el-table-column>

                <el-table-column prop="full_name"
                                 label="Name">
                    <template v-slot="scope">
                        {{ scope.row?.full_name || '-' }}
                    </template>
                </el-table-column>

                <el-table-column align="center"
                                 label="Operations"
                                 width="180">
                    <template v-slot="scope">
                        <div slot="reference">
                            <div class="row">
                                <div class="col-12">
                                    <router-link :to="{ name: 'User Activity', params: {user_id: scope.row.id }}">
                                        <button class="btn btn-sm btn-block purplish">
                                            <i class="fa fa-signal pull-left"></i>
                                            Activity
                                        </button>
                                    </router-link>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <button class="btn btn-sm btn-block danger"
                                            @click="handleRemoveUsersModal(scope.row)">
                                        <i class="fa fa-trash pull-left"></i>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <div class="mb-3">
                <h5 class="pt-3">
                    Associated Ring Groups
                </h5>

                <div class="d-flex align-items-center justify-content-between pt-1">
                    <el-input class="w-50"
                              placeholder="Search Ring Groups"
                              prefix-icon="el-icon-search"
                              clearable 
                              v-model="searchRingGroups" />
                </div>
            </div>

            <el-table class="w-full"
                      ref="company-ring-group-team-list"
                      max-height="350"
                      stripe
                      :data="filteredRingGroups"
                      v-loading="isLoading">
                <el-table-column prop="name"
                                 label="Name">
                    <template v-slot="scope">
                        {{ scope.row?.name || '-' }}
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog width="30%"
                       center
                       :visible.sync="isDeleteUsersModalVisible"
                       @close="closeDeleteUsersModal">
                <div class="dialog--title mt-4"
                     slot="title">
                    {{ deleteUsersModalConfig.title }}
                </div>
                <p class="break-word text-center"
                   v-html="deleteUsersModalConfig.description" />
                <p class="break-word text-center">Are you sure?</p>
                <div class="d-flex justify-content-center mt-5 row">
                    <el-button class="mb-2"
                               type="default"
                               slot="reference"
                               @click="closeDeleteUsersModal">
                        No, Keep User
                    </el-button>
                    <el-button class="mb-2"
                               type="danger"
                               slot="reference"
                               data-testid="team-delete-users-button"
                               :disabled="isLoading"
                               @click="onDeleteUsers">
                        {{ deleteUsersModalConfig.buttonText }}
                    </el-button>
                </div>
            </el-dialog>

            <el-dialog width="30%"
                       center
                       :visible.sync="isAddUsersModalVisible"
                       :loading="isLoading">
                <div class="dialog--title mt-4"
                     slot="title">
                    {{ addUsersModalConfig.title }}
                </div>
                <el-select class="w-full"
                           multiple
                           filterable
                           remote
                           placeholder="Search Users"
                           collapse-tags
                           data-testid="team-add-users-select"
                           v-model="selectedUsersToAdd">
                    <el-option :key="user.id"
                               :label="user.full_name + ' - ' + user.email"
                               :value="user.id" 
                               v-for="user in filteredUsersToAdd" />
                </el-select>
                <div class="d-flex justify-content-center mt-5 row">
                    <el-button class="mb-2"
                               type="default"
                               slot="reference"
                               @click="closeAddUsersModal">
                        Cancel
                    </el-button>
                    <el-button class="mb-2"
                               type="success"
                               slot="reference"
                               data-testid="team-add-users-button"
                               :disabled="isLoading"
                               @click="onAddUsers">
                        Add Users
                    </el-button>
                </div>
            </el-dialog>

            <el-dialog class="ring-group--dialog"
                       width="40%"
                       center
                       :visible.sync="isAddRingGroupsModalVisible">
                <div class="dialog--title mt-4"
                     slot="title">
                    {{ addRingGroupsModalConfig.title }}
                </div>
                <div class="ring-group mt-5">
                    <div class="d-flex align-items-center justify-content-between ring-group--divider"
                         :key="ringGroup.id"
                         v-for="ringGroup in filteredRingGroupsToAdd">
                        <div class="ring-group--title">{{ ringGroup.name }}</div>
                        <el-button type="success" 
                                   icon="el-icon-check"
                                   :disabled="isLoading"
                                   :loading="isLoading"
                                   data-testid="team-add-ring-groups-button"
                                   v-if="!team.ring_groups.find(rg => rg.id === ringGroup.id)"
                                   @click="onAddRingGroup(ringGroup)" />
                        <el-button type="danger" 
                                   icon="el-icon-close"
                                   :disabled="isLoading"
                                   :loading="isLoading"
                                   data-testid="team-remove-ring-groups-button"
                                   v-else
                                   @click="onRemoveRingGroup(ringGroup)" />
                    </div>
                    <!-- loading -->
                    <div class="ring-group d-flex align-items-center justify-content-center"
                         v-if="isLoading && !ringGroupsToAdd.length">
                        <el-spinner />
                    </div>
                </div>
            </el-dialog>

            <el-dialog width="30%"
                       center
                       :visible.sync="isRemoveRingGroupModalVisible">
                <div class="dialog--title mt-4"
                     slot="title">
                    {{ removeRingGroupModalConfig.title }}
                </div>
                <p class="break-word text-center"
                   v-html="removeRingGroupModalConfig.description" />
                <p class="break-word text-center">Are you sure?</p>
                <div class="d-flex justify-content-center mt-5 row">
                    <el-button class="mb-2"
                               type="default"
                               slot="reference"
                               @click="closeRemoveRingGroupModal">
                        No, Keep Ring Group
                    </el-button>
                    <el-button class="mb-2"
                               type="danger"
                               slot="reference"
                               data-testid="team-remove-ring-group-button"
                               :disabled="isLoading"
                               @click="onRemoveRingGroups">
                        {{ removeRingGroupModalConfig.buttonText }}
                    </el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import auth from '../../auth'
import { acl_mixin } from '../../mixins'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'EditTeamMembers',

    props: {
        selectedTeam: {
            type: Object,
            required: false,
            default: () => { return {} }
        }
    },

    mixins: [acl_mixin],

    data() {
        return {
            auth: auth,
            team: {},
            isLoading: false,
            searchUsers: '',
            selectedUsersToAdd: [],
            searchUsersToAddQuery: '',
            searchRingGroups: '',
            selectedUsers: [],
            selectedRingGroups: [],
            ringGroupsToAdd: [],
            searchRingGroupsToAddQuery: '',
            isDeleteUsersModalVisible: false,
            isAddUsersModalVisible: false,
            isAddRingGroupsModalVisible: false,
            isRemoveRingGroupModalVisible: false,
            selectedRingGroup: null,
            pagination: {
                page: 1,
                size: 20,
                paginate: true
            },
        }
    },

    computed: {
        ...mapState(['users']),

        deleteUsersModalConfig() {
            if (this.selectedUsers.length === 1) {
                return {
                    title: 'Remove User from Team',
                    description: `You're about to remove <strong>${this.selectedUsers[0].full_name}</strong> from the ${this.team.name} Team. This action can't be undone.`,
                    buttonText: 'Yes, Remove User'
                }
            }

            return {
                title: 'Remove Users from Team',
                description: `You're about to remove <strong>${this.selectedUsers.length} Users</strong> from the ${this.team.name} Team. This action can't be undone.`,
                buttonText: 'Yes, Remove Users'
            }
        },

        selectedUsersLabel() {
            if (this.selectedUsers.length === 1) {
                return `${this.selectedUsers.length} User Selected`
            }

            return `${this.selectedUsers.length} Users Selected`
        },

        addUsersModalConfig() {
            return {
                title: `Add Users to ${this.team.name} Team`,
            }
        },

        addRingGroupsModalConfig() {
            return {
                title: `Add Ring Groups to ${this.team.name} Team`,
                loadingRingGroupsSelectLabel: this.ringGroupsToAdd.length ? 'Loadings more ring groups...' : 'Loading ring groups...'
            }
        },

        removeRingGroupModalConfig() {
            if (this.selectedRingGroups.length === 1) {
                return {
                    title: `Remove Ring Group from ${this.team.name} Team`,
                    description: `You're about to remove <strong>${this.selectedRingGroups[0].name}</strong> from the ${this.team.name} Team. This action can't be undone.`,
                    buttonText: 'Yes, Remove Ring Group'
                }
            }

            return {
                title: `Remove Ring Groups from ${this.team.name} Team`,
                description: `You're about to remove <strong>${this.selectedRingGroups.length} Ring Groups</strong> from the ${this.team.name} Team. This action can't be undone.`,
                buttonText: 'Yes, Remove Ring Groups'
            }
        },

        filteredUsers() {
            return this.team?.users?.filter(user => {
                return user.full_name.toLowerCase().includes(this.searchUsers.toLowerCase())
            })
        },

        filteredUsersToAdd() {
            // Discard extensions as possible users to be added to the team
            return this.users.filter(user => 
                !this.team.users.find(u => u.id === user.id) &&
                !user.is_destination
            )
        },

        filteredRingGroups() {
            return this.team?.ring_groups?.filter(rg => {
                return rg.name.toLowerCase().includes(this.searchRingGroups.toLowerCase())
            })
        },

        filteredRingGroupsToAdd() {
            return this.ringGroupsToAdd?.filter(rg => {
                return rg.name.toLowerCase().includes(this.searchRingGroupsToAddQuery.toLowerCase())
            })
        }
    },

    methods: {
        ...mapActions(['setCurrentTeam']),

        backToTeamsList() {
            this.$emit('back-to-teams-list')
        },

        handleSelectedUsersChange(val) {
            this.selectedUsers = val
        },

        handleSelectedRingGroupsChange(val) {
            this.selectedRingGroups = val
        },

        handleRemoveUsersModal(user = null) {
            if (user) {
                this.cleanSelectedUsers()
                this.selectedUsers.push(user)
            }

            if (!this.selectedUsers.length) {
                return
            }

            this.isDeleteUsersModalVisible = true
        },

        handleRemoveRingGroupsModal(ringGroup = null) {
            if (ringGroup && !this.selectedRingGroups.includes(ringGroup)) {
                this.selectedRingGroups.push(ringGroup)
            }

            if (!this.selectedRingGroups.length) {
                return
            }

            this.isRemoveRingGroupModalVisible = true
        },

        closeDeleteUsersModal() {
            this.isDeleteUsersModalVisible = false
            this.cleanSelectedUsers()
        },

        cleanSelectedUsers() {
            this.selectedUsers = []
            this.$refs['company-users-team-list'].clearSelection()
        },

        onDeleteUsers() {
            this.isLoading = true
            const users_ids = this.selectedUsers.map(user => user.id)

            this.$APIV2.Teams.removeUsers(this.team.id, { users_ids })
                .then(res => {
                    this.closeDeleteUsersModal()

                    this.$notify({
                        title: 'Success',
                        message: 'User(s) removed from the team successfully.',
                        type: 'success',
                        showClose: true
                    })

                    this.selectedUsers = []
                    this.fetchTeam(this.team.id)
                })
                .catch(err => {
                    this.closeDeleteUsersModal()

                    this.$notify({
                        title: 'Error',
                        message: 'An error occurred while removing user(s) from the team.',
                        type: 'error',
                        showClose: true
                    })

                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        openAddUsersModal() {
            this.isAddUsersModalVisible = true
        },

        closeAddUsersModal() {
            this.isAddUsersModalVisible = false
            this.selectedUsersToAdd = []
        },

        onAddUsers() {
            this.isLoading = true
            const users_ids = [...this.selectedUsersToAdd]

            this.$APIV2.Teams.addUsers(this.team.id, { users_ids })
                .then(res => {
                    this.selectedUsersToAdd = []
                    this.closeAddUsersModal()
                    this.fetchTeam(this.team.id)

                    this.$notify({
                        title: 'Success',
                        message: 'User(s) added to the team successfully.',
                        type: 'success',
                        showClose: true
                    })
                })
                .catch(err => {
                    this.closeAddUsersModal()

                    this.$notify({
                        title: 'Error',
                        message: 'An error occurred while adding user(s) to the team.',
                        type: 'error',
                        showClose: true
                    })

                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        fetchRingGroups() {
            this.isLoading = true

            const params = {
                search: this.searchRingGroupsToAddQuery,
                ...this.pagination
            }

            this.$APIV1.RingGroup.index(params)
                .then(res => {
                    this.ringGroupsToAdd = res.data
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        fetchTeam(teamId) {
            this.isLoading = true

            this.$APIV2.Teams.show(teamId)
                .then(res => {
                    this.team = res.data
                    this.setCurrentTeam(this.team)
                })
                .catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.isLoading = false
                })
        },

        openAddRingGroupsModal() {
            if (!this.ringGroupsToAdd.length) {
                this.pagination.page = 1
                this.fetchRingGroups()
            }

            this.isAddRingGroupsModalVisible = true
        },

        closeAddRingGroupsModal() {
            this.isAddRingGroupsModalVisible = false
        },

        openRemoveRingGroupModal(ringGroup) {
            this.selectedRingGroup = ringGroup
            this.isRemoveRingGroupModalVisible = true
        },

        closeRemoveRingGroupModal() {
            this.isRemoveRingGroupModalVisible = false
            this.selectedRingGroup = null
            this.selectedRingGroups = []
        },

        onAddRingGroup(ringGroup) {
            this.isLoading = true

            const data = {
                team_id: this.team.id,
            }

            this.$APIV1.RingGroup.addTeam(ringGroup.id, data)
                .then(res => {
                    this.$notify({
                        title: 'Success',
                        message: 'Ring Group added to the team successfully.',
                        type: 'success',
                        showClose: true
                    })

                    this.fetchTeam(this.team.id)
                })
                .catch(err => {
                    this.$notify({
                        title: 'Error',
                        message: 'An error occurred while adding ring group to the team.',
                        type: 'error',
                        showClose: true
                    })

                    console.log(err)
                    this.isLoading = false
                })
        },

        onRemoveRingGroup(ringGroup) {
            this.isLoading = true

            const data = {
                team_id: this.team.id,
            }

            this.$APIV1.RingGroup.removeTeam(ringGroup.id, data)
                .then(res => {
                    this.$notify({
                        title: 'Success',
                        message: 'Ring Group removed from the team successfully.',
                        type: 'success',
                        showClose: true
                    })

                    this.closeRemoveRingGroupModal()
                    this.fetchTeam(this.team.id)
                })
                .catch(err => {
                    this.$notify({
                        title: 'Error',
                        message: 'An error occurred while removing ring group from the team.',
                        type: 'error',
                        showClose: true
                    })

                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        onRemoveRingGroups() {
            this.isLoading = true

            const ring_groups_ids = this.selectedRingGroups.map(rg => rg.id)

            this.$APIV1.RingGroup.removeRingGroupsFromTeam({ ring_groups_ids })
                .then(res => {
                    this.closeRemoveRingGroupModal()

                    this.$notify({
                        title: 'Success',
                        message: 'Ring Group(s) removed from the team successfully.',
                        type: 'success',
                        showClose: true
                    })

                    this.selectedRingGroups = []
                    this.fetchTeam(this.team.id)
                })
                .catch(err => {
                    this.closeRemoveRingGroupModal()

                    this.$notify({
                        title: 'Error',
                        message: 'An error occurred while removing ring group(s) from the team.',
                        type: 'error',
                        showClose: true
                    })

                    console.log(err)
                    this.isLoading = false
                })
        }
    },

    created() {
        if (!this.selectedTeam?.id && this.$route.params.team_id) {
            this.fetchTeam(this.$route.params.team_id)
        }
    },

    watch: {
        selectedTeam: {
            handler(val) {
                this.team = val
            },
            immediate: true,
            deep: true
        },
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                let auth = {
                    user: {
                        profile: res.data.user
                    }
                }

                // if talk is not enabled just redirect to our dashboard
                if (!res.data.user.company.talk_enabled) {
                    next({name: 'Dashboard'})
                }

                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next(vm => {
                        if (!vm.hasRole('Company Admin') && !vm.hasRole('Company Supervisor')) {
                            next({name: 'Dashboard'})
                        }
                    })
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    },

    beforeRouteLeave(to, from, next) {
        this.setCurrentTeam({})
        next()
    }
}
</script>
