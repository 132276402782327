<template>
    <div class="filter-div white compact no-bottom-margin bold-label no-select">
        <el-form v-if="!show_custom_date_range">
            <el-form-item>
                <el-select class="filter-select"
                           placeholder="Select Date Range"
                           popper-class=""
                           v-model="date_range"
                           @change="changeDateRange">
                    <el-option :key="index"
                               :label="item.value"
                               :value="item.value"
                               v-for="(item, index) in date_ranges">
                        <span>{{ item.label }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="block"
             v-if="show_custom_date_range">
            <el-date-picker end-placeholder="End date"
                            range-separator="-"
                            start-placeholder="Start date"
                            type="daterange"
                            v-model="custom_date_range"
                            unlink-panels
                            @change="changeCustomDateRange">
            </el-date-picker>
            <div class="pull-right ml-2">
                <a @click.prevent="clearCustomDateRange">
                    <span class="fa fa-close"></span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {date_mixin} from "../../mixins"
import {mapState} from 'vuex'

export default {
    name: "date-range-selector",
    mixins: [date_mixin],
    props: {
        filter: {
            required: true
        }
    },
    data() {
        return {
            show_custom_date_range: false,
            custom_date_range: '',
            default_date_range_key: 1,
            date_range: '',
            date_ranges: [],
            from_date: '',
            to_date: ''
        }
    },

    computed: {
        ...mapState('cache', ['current_company'])
    },

    mounted() {
        this.date_ranges = [
            {
                value: 'custom',
                label: 'Custom'
            },
            {
                value: this.thirtyDaysAgo().tz(this.current_company.timezone).format('MM/DD/YYYY') + ' - ' + this.localizedMoment().tz(this.current_company.timezone).format('MM/DD/YYYY'),
                label: 'Recent (Last 30 Days + Today)'
            },
            {
                value: this.localizedMoment().subtract(7, 'days').tz(this.current_company.timezone).format('MM/DD/YYYY') + ' - ' + this.localizedMoment().tz(this.current_company.timezone).format('MM/DD/YYYY'),
                label: 'This Week'
            },
            {
                value: this.localizedMoment().tz(this.current_company.timezone).format('MM/DD/YYYY') + ' - ' + this.localizedMoment().tz(this.current_company.timezone).format('MM/DD/YYYY'),
                label: 'Today'
            },
            {
                value: this.localizedMoment().subtract(1, 'days').tz(this.current_company.timezone).format('MM/DD/YYYY') + ' - ' + this.localizedMoment().subtract(1, 'days').tz(this.current_company.timezone).format('MM/DD/YYYY'),
                label: 'Yesterday'
            },
            {
                value: this.localizedMoment().subtract(31, 'days').tz(this.current_company.timezone).format('MM/DD/YYYY') + ' - ' + this.localizedMoment().subtract(1, 'days').tz(this.current_company.timezone).format('MM/DD/YYYY'),
                label: 'Last 30 Days'
            },
            {
                value: this.localizedMoment().startOf('month').tz(this.current_company.timezone).format('MM/DD/YYYY') + ' - ' + this.localizedMoment().endOf('month').tz(this.current_company.timezone).format('MM/DD/YYYY'),
                label: 'This Month'
            },
            {
                value: this.localizedMoment().subtract(1, 'months').startOf('month').tz(this.current_company.timezone).format('MM/DD/YYYY') + ' - ' + this.localizedMoment().subtract(1, 'months').endOf('month').tz(this.current_company.timezone).format('MM/DD/YYYY'),
                label: 'Last Month'
            },
            {
                value: this.localizedMoment().subtract(3, 'years').tz(this.current_company.timezone).format('MM/DD/YYYY') + ' - ' + this.localizedMoment().tz(this.current_company.timezone).format('MM/DD/YYYY'),
                label: 'All Time'
            }
        ]

        if (!this.date_range) {
            this.date_range = this.date_ranges[this.default_date_range_key]['value'];
            this.changeDateRange()
        }
    },

    methods: {
        changeDateRange() {
            switch (this.date_range) {
                case 'custom':
                    this.resetDateRange()
                    this.show_custom_date_range = true;
                    break;
                default:
                    this.show_custom_date_range = false;
                    let ranges = this.date_range.split(' - ')
                    this.from_date = this.localizedMoment(ranges[0], 'MM/DD/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss')
                    this.to_date = this.localizedMoment(ranges[1], 'MM/DD/YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss')
                    break;
            }

            this.updateStateDateRanges()
        },

        changeCustomDateRange() {
            this.date_range = this.localizedMoment(this.custom_date_range[0]).format('MM/DD/YYYY') + ' - ' + this.localizedMoment(this.custom_date_range[1]).format('MM/DD/YYYY')
            let ranges =  this.date_range.split(' - ')
            this.from_date = this.localizedMoment(ranges[0], 'MM/DD/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss')
            this.to_date = this.localizedMoment(ranges[1], 'MM/DD/YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss')

            this.updateStateDateRanges()
        },

        resetDateRange() {
            this.from_date = ''
            this.to_date = ''
        },

        clearCustomDateRange() {
            this.date_range = this.date_ranges[this.default_date_range_key]['value'];
            this.show_custom_date_range = false;

            this.updateStateDateRanges()
        },

        updateStateDateRanges() {
            this.filter.setDateRange(this.from_date, this.to_date)
        }
    }
}
</script>
