// Missing chargebee subscription
export const ISSUE_CHARGEBEE_SUBSCRIPTION = 1
// No Aloware subscription
export const ISSUE_NO_SUBSCRIPTION = 2
// Cancelled Aloware subscription
export const ISSUE_CANCELLED_SUBSCRIPTION = 3
// No Aloware plan
export const ISSUE_NO_PLAN = 4
// No chargebee customer
export const ISSUE_CHARGEBEE_CUSTOMER = 5
// No payment source
export const ISSUE_NO_CARD = 6
// Reached max negative balance
export const ISSUE_MAX_NEGATIVE_BALANCE = 7
// Missing brand registration
export const ISSUE_BRAND_NOT_REGISTERED = 8
// Failed brand registration
export const ISSUE_BRAND_REGISTRATION_FAILED = 9
// Missing A2P 10DLC registration
export const ISSUE_A2P_10DLC_NOT_REGISTERED = 10
// Incomplete A2P 10DLC registration
export const ISSUE_A2P_10DLC_INCOMPLETE = 11
// Invoice is in active dunning
export const ISSUE_ACTIVE_DUNNING = 12;
// Failed brand registration but TCR score is 0
export const ISSUE_BRAND_REGISTRATION_FAILED_TCR_ZERO = 13;
// Toll free SMS verification issue
export const ISSUE_TOLL_FREE_SMS = 14;
