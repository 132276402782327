export default Object.freeze({
    packages: [
        {
            label: 'Standard Short Code',
            label_price: '$1,500 per month',
            value: 1,
        },
        {
            label: 'Vanity Short Code',
            label_price: '$2,000 per month',
            value: 2,
        }
    ]
});
