var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body sold-report-container", attrs: { id: "view" } },
    [_c("iframe", { attrs: { src: _vm.source } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }