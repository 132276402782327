var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: _vm.sequence_form_key }, [
    [
      _vm.SequenceTypes.TYPE_CALL_LEAD,
      _vm.SequenceTypes.TYPE_SMS_LEAD,
      _vm.SequenceTypes.TYPE_CONFERENCE,
      _vm.SequenceTypes.TYPE_RVM,
      _vm.SequenceTypes.TYPE_MMS,
      _vm.SequenceTypes.TYPE_CALL_RING_GROUP,
      _vm.SequenceTypes.TYPE_SMS_RING_GROUP,
      _vm.SequenceTypes.TYPE_AUTO_DIAL,
      _vm.SequenceTypes.TYPE_BIRTHDAY,
      _vm.SequenceTypes.TYPE_EMAIL_LEAD,
      _vm.SequenceTypes.TYPE_EMAIL_RING_GROUP,
      _vm.SequenceTypes.TYPE_PREDICTIVE_DIAL,
    ].includes(_vm.sequence.type)
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _vm.hasExtraStep
                ? _c("div", { staticClass: "b-b nav-active-greenish" }, [
                    _c("ul", { staticClass: "nav nav-tabs" }, [
                      [
                        _vm.SequenceTypes.TYPE_EMAIL_LEAD,
                        _vm.SequenceTypes.TYPE_EMAIL_RING_GROUP,
                      ].includes(_vm.sequence.type)
                        ? _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "border border-right-0 rounded-top-left",
                                class: _vm.messageNavClass.read,
                                attrs: {
                                  href: "#",
                                  "data-toggle": "tab",
                                  "data-target": "#messagebody",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-robot" }),
                                _vm._v(
                                  "\n                            Send\n                        "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      ![
                        _vm.SequenceTypes.TYPE_SMS_LEAD,
                        _vm.SequenceTypes.TYPE_RVM,
                        _vm.SequenceTypes.TYPE_MMS,
                        _vm.SequenceTypes.TYPE_SMS_RING_GROUP,
                        _vm.SequenceTypes.TYPE_WEBHOOK,
                        _vm.SequenceTypes.TYPE_EMAIL_LEAD,
                        _vm.SequenceTypes.TYPE_EMAIL_RING_GROUP,
                      ].includes(_vm.sequence.type)
                        ? _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "border border-right-0 rounded-top-left",
                                class: _vm.messageNavClass.read,
                                attrs: {
                                  href: "#",
                                  "data-toggle": "tab",
                                  "data-target": "#messagetts",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-robot" }),
                                _vm._v(
                                  "\n                            Text to Speech\n                        "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      [
                        _vm.SequenceTypes.TYPE_SMS_LEAD,
                        _vm.SequenceTypes.TYPE_SMS_RING_GROUP,
                      ].includes(_vm.sequence.type)
                        ? _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "border border-right-0 rounded-top-left",
                                class: _vm.messageNavClass.read,
                                attrs: {
                                  href: "#",
                                  "data-toggle": "tab",
                                  "data-target": "#messagetts",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Message\n                        "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasPermissionTo("upload file sequence") &&
                      [
                        _vm.SequenceTypes.TYPE_CALL_LEAD,
                        _vm.SequenceTypes.TYPE_RVM,
                        _vm.SequenceTypes.TYPE_CALL_RING_GROUP,
                        _vm.SequenceTypes.TYPE_AUTO_DIAL,
                      ].includes(_vm.sequence.type)
                        ? _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "border rounded-top-right",
                                class: _vm.messageNavClass.play,
                                attrs: {
                                  href: "#",
                                  "data-toggle": "tab",
                                  "data-target": "#messagefile",
                                  "aria-expanded": "true",
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-play text-md" }),
                                _vm._v(
                                  "\n                            Play Recording\n                        "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasPermissionTo("upload file sequence") &&
                      _vm.sequence.type == _vm.SequenceTypes.TYPE_MMS
                        ? _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "border rounded-top",
                                class: _vm.messageNavClass.send,
                                attrs: {
                                  href: "#",
                                  "data-toggle": "tab",
                                  "data-target": "#mediafile",
                                  "aria-expanded": "true",
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("insert_photo"),
                                ]),
                                _vm._v(
                                  "\n                            Media file\n                        "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.sequence.type == _vm.SequenceTypes.TYPE_MMS
                        ? _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "border rounded-top-right",
                                class: _vm.messageNavClass.gif,
                                attrs: {
                                  href: "#",
                                  "data-toggle": "tab",
                                  "data-target": "#giffile",
                                  "aria-expanded": "true",
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("gif"),
                                ]),
                                _vm._v(
                                  "\n                            GIF file\n                        "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tab-content pt-2 border border-top-0" },
                [
                  [
                    _vm.SequenceTypes.TYPE_EMAIL_LEAD,
                    _vm.SequenceTypes.TYPE_EMAIL_RING_GROUP,
                  ].includes(_vm.sequence.type)
                    ? _c(
                        "div",
                        {
                          staticClass: "mx-0",
                          class: _vm.messageContainerClass.read,
                          attrs: { id: "messagebody", "aria-expanded": "true" },
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-right mb-2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex step-editor-email-buttons mx-2",
                                },
                                [
                                  _c("variable-dialog", {
                                    attrs: {
                                      in_input_group: false,
                                      has_agent: _vm.hasAgent,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("template-list-dialog", {
                                    staticClass: "ml-2",
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "no-border",
                              attrs: { prop: "message_body" },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "input-group" },
                                    [
                                      !_vm.edit_html
                                        ? _c("wysiwyg", {
                                            on: {
                                              input: function ($event) {
                                                return _vm.imposeMessageCharLimit(
                                                  "message_body"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.sequence.message_body,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sequence,
                                                  "message_body",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sequence.message_body",
                                            },
                                          })
                                        : _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              autosize: { minRows: 7 },
                                            },
                                            on: {
                                              imput: function ($event) {
                                                return _vm.imposeMessageCharLimit(
                                                  "message_body"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.sequence.message_body,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.sequence,
                                                  "message_body",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "sequence.message_body",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 mx-2" },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value: _vm.edit_html,
                                        callback: function ($$v) {
                                          _vm.edit_html = $$v
                                        },
                                        expression: "edit_html",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "el-form-item__label ml-2 float-none",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Edit in HTML\n                                    "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "ml-3" },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content:
                                                    "Tag's on visual mode will be visible to users",
                                                  placement: "top",
                                                  effect: "dark",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            (Warning: Don't write tags on visual editor mode)\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasPermissionTo("upload file sequence") &&
                  _vm.sequence.type == _vm.SequenceTypes.TYPE_MMS
                    ? _c(
                        "div",
                        {
                          staticClass: "mx-3 my-3",
                          class: _vm.messageContainerClass.send,
                          attrs: { id: "mediafile", "aria-expanded": "true" },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "no-border",
                              attrs: { prop: "message_file" },
                            },
                            [
                              _vm.sequence.message_file === null
                                ? _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action:
                                          "/api/v1/automations/sequences/upload/media",
                                        headers: _vm.headers,
                                        "on-success": _vm.onSuccessSendMedia,
                                        "on-error": _vm.onFailedSendMedia,
                                        "on-progress":
                                          _vm.progressUploadSendMedia,
                                        "before-upload":
                                          _vm.beforeUploadSendMedia,
                                        "file-list": _vm.uploadFileList.message,
                                        limit: 1,
                                        multiple: false,
                                        drag: "",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-upload",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "el-upload__text" },
                                        [
                                          _vm._v(
                                            "\n                                Drop file here or "
                                          ),
                                          _c("em", [_vm._v("click to upload")]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          attrs: { slot: "tip" },
                                          slot: "tip",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Media file such as jpg, png or gif (less than 5MB)\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.sequence.message_file === null
                                ? _c("el-progress", {
                                    attrs: {
                                      "text-inside": true,
                                      "stroke-width": 18,
                                      percentage: _vm.uploadPercentage.message,
                                      status: _vm.uploadStatus.message,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.sequence.message_file !== null
                                ? _c("vue-load-image", [
                                    _c("img", {
                                      staticClass:
                                        "img-fluid r-2x preview mb-2",
                                      attrs: {
                                        slot: "image",
                                        src:
                                          "/static/uploaded_file/" +
                                          _vm.sequence.message_file,
                                      },
                                      slot: "image",
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      attrs: {
                                        slot: "preloader",
                                        src: "/assets/images/loading.svg",
                                      },
                                      slot: "preloader",
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [_vm._v("Error!")]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "w-full text-right" }, [
                                _vm.sequence.message_file !== null &&
                                _vm.hasPermissionTo("delete file sequence")
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-danger",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.deleteFile(
                                              _vm.sequence.message_file
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.loading_message,
                                                expression: "loading_message",
                                              },
                                            ],
                                            staticClass:
                                              "material-icons loader",
                                          },
                                          [_vm._v("")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "i",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.loading_message,
                                                expression: "!loading_message",
                                              },
                                            ],
                                            staticClass: "material-icons",
                                          },
                                          [_vm._v("")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Remove file")]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.sequence.type == _vm.SequenceTypes.TYPE_MMS
                    ? _c(
                        "div",
                        {
                          staticClass: "mx-3 my-3",
                          class: _vm.messageContainerClass.gif,
                          attrs: { id: "giffile", "aria-expanded": "true" },
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: {
                                id: "search-gif",
                                "aria-expanded": "false",
                              },
                            },
                            [
                              _vm.sequence.gif_url !== null
                                ? _c("vue-load-image", [
                                    _c("img", {
                                      staticClass: "img-fluid d-block r-2x",
                                      attrs: {
                                        slot: "image",
                                        src: _vm.sequence.gif_url,
                                      },
                                      slot: "image",
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      attrs: {
                                        slot: "preloader",
                                        src: "/assets/images/loading.svg",
                                      },
                                      slot: "preloader",
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [_vm._v("Error!")]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "w-full text-right" }, [
                                _vm.sequence.gif_url !== null &&
                                _vm.hasPermissionTo("delete file sequence") &&
                                _vm.isValidImageUrl(_vm.sequence.gif_url)
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-danger",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.removeGif.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.loading_message,
                                                expression: "loading_message",
                                              },
                                            ],
                                            staticClass:
                                              "material-icons loader",
                                          },
                                          [_vm._v("")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "i",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.loading_message,
                                                expression: "!loading_message",
                                              },
                                            ],
                                            staticClass: "material-icons",
                                          },
                                          [_vm._v("")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Remove file")]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.sequence.gif_url == null,
                                      expression: "sequence.gif_url == null",
                                    },
                                  ],
                                  staticClass: "no-border",
                                  attrs: { label: "GIF:", prop: "file_name" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pos-rlt" },
                                    [
                                      _c("search-giphy", {
                                        on: { selected: _vm.setGif },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasMessageComposer
                    ? _c(
                        "div",
                        {
                          staticClass: "mx-3 my-3",
                          class: _vm.messageContainerClass.read,
                          attrs: { id: "messagetts", "aria-expanded": "true" },
                        },
                        [
                          ![
                            _vm.SequenceTypes.TYPE_CALL_RING_GROUP,
                            _vm.SequenceTypes.TYPE_CALL_LEAD,
                          ].includes(_vm.sequence.type)
                            ? _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-8" }, [
                                  _c("span", { staticClass: "pull-right" }, [
                                    _c("b", [_vm._v("Limit:")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.message_total_count) +
                                        " / 1600"
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "no-border",
                              attrs: {
                                error: _vm.errors.first("message_tts"),
                                prop: "message_tts",
                              },
                            },
                            [
                              _c("el-popover", {
                                ref: "popover_messagetext",
                                attrs: {
                                  placement: "bottom-start",
                                  title: "Title",
                                  width: "200",
                                  trigger: "hover",
                                  content: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.generatingUrls,
                                          expression: "generatingUrls",
                                        },
                                      ],
                                      staticClass: "input-group",
                                    },
                                    [
                                      _c("input-with-info-text", {
                                        staticClass: "form-control pt-2 pb-2",
                                        attrs: {
                                          "info-text":
                                            "[Text <b>STOP</b> to unsubscribe]",
                                          "model-value":
                                            _vm.sequence.message_tts,
                                          "is-loading": _vm.generatingUrls,
                                          "is-info-text-active":
                                            _vm.is_optout_active,
                                          rows: _vm.msg_field_rows,
                                          "left-pixels": 22,
                                          "bottom-pixels": 10,
                                          "label-size": 13,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.imposeMessageCharLimit(
                                              "message_tts"
                                            )
                                          },
                                          blur: _vm.detectLongUrls,
                                        },
                                        model: {
                                          value: _vm.sequence.message_tts,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sequence,
                                              "message_tts",
                                              $$v
                                            )
                                          },
                                          expression: "sequence.message_tts",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("variable-dialog", {
                                      attrs: {
                                        in_input_group: false,
                                        has_agent: _vm.hasAgent,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("template-list-dialog", {
                                      staticClass: "mt-2",
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              ![
                                _vm.SequenceTypes.TYPE_CALL_RING_GROUP,
                                _vm.SequenceTypes.TYPE_CALL_LEAD,
                              ].includes(_vm.sequence.type)
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-md-8" }, [
                                      _c(
                                        "span",
                                        { staticClass: "pull-right ml-4" },
                                        [
                                          _c("b", [_vm._v("Message parts:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.messageTtsCount) +
                                              " / 160"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "pull-right",
                                          class: _vm.messageCounterClass,
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Message(s): " +
                                              _vm._s(_vm.messageCount) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "checkbox-group" }, [
                                _c(
                                  "div",
                                  { staticClass: "checkbox-group-area" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass:
                                          "w-full checkbox-group-item mt-1",
                                        model: {
                                          value: _vm.sequence.confirm_message,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sequence,
                                              "confirm_message",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "sequence.confirm_message",
                                        },
                                      },
                                      [
                                        _c(
                                          "small",
                                          { staticClass: "ml-0 pl-0" },
                                          [
                                            _vm._v(
                                              "\n                                        Repeat message & require agent to press any key to connect to contact\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass:
                                          "w-full checkbox-group-item",
                                        attrs: {
                                          value: _vm.is_optout_active,
                                          disabled: _vm.isOptoutForced,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateIsOptoutActive(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              placement: "bottom",
                                              disabled: !_vm.isOptoutForced,
                                              content: _vm.optout_tooltip_text,
                                            },
                                          },
                                          [
                                            _c(
                                              "small",
                                              { staticClass: "ml-0 pl-0" },
                                              [
                                                _vm._v(
                                                  "\n                                            Add opt-out phrase for this message\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.sequence.confirm_message
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "alert alert-warning alert-aloware",
                                    },
                                    [
                                      _c("small", [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-circle",
                                        }),
                                        _vm._v(" Because "),
                                        _c("strong", [
                                          _vm._v("Repeat Message"),
                                        ]),
                                        _vm._v(
                                          " setting is enabled, add a prompt like “Press any key to connect” to the end of the message so the agent knows how to connect once they receive this message"
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasPermissionTo("upload file sequence") &&
                  [
                    _vm.SequenceTypes.TYPE_CALL_LEAD,
                    _vm.SequenceTypes.TYPE_RVM,
                    _vm.SequenceTypes.TYPE_CALL_RING_GROUP,
                    _vm.SequenceTypes.TYPE_AUTO_DIAL,
                  ].includes(_vm.sequence.type)
                    ? _c(
                        "div",
                        {
                          staticClass: "mx-3 my-3",
                          class: _vm.messageContainerClass.play,
                          attrs: { id: "messagefile", "aria-expanded": "true" },
                        },
                        [
                          _vm.sequence.message_file === null
                            ? _c("audio-recorder", {
                                staticClass: "audio-recorder-wrapper",
                                attrs: {
                                  action:
                                    "/api/v1/automations/sequences/upload-file/prompt",
                                  upload_type: "message_file",
                                },
                                on: {
                                  applyUploadedAudio: _vm.applyUploadedAudio,
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "audio-uploader-wrapper" },
                            [
                              _vm.sequence.message_file === null
                                ? _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action:
                                          "/api/v1/automations/sequences/upload-file/prompt",
                                        headers: _vm.headers,
                                        "on-success": _vm.onSuccessMessage,
                                        "on-error": _vm.onFailedMessage,
                                        "on-progress":
                                          _vm.progressUploadMessage,
                                        "before-upload":
                                          _vm.beforeUploadMessage,
                                        "file-list": _vm.uploadFileList.message,
                                        drag: "",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-upload",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "el-upload__text" },
                                        [
                                          _vm._v(
                                            "\n                                Drop file here or "
                                          ),
                                          _c("em", [_vm._v("click to upload")]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          attrs: { slot: "tip" },
                                          slot: "tip",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                MP3/WAV file (less than 8MB)\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.sequence.message_file === null
                                ? _c("el-progress", {
                                    attrs: {
                                      "text-inside": true,
                                      "stroke-width": 18,
                                      percentage: _vm.uploadPercentage.message,
                                      status: _vm.uploadStatus.message,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.sequence.message_file !== null
                                ? _c("audio", { attrs: { controls: "" } }, [
                                    _c("source", {
                                      attrs: {
                                        src:
                                          "/static/uploaded_file/" +
                                          _vm.sequence.message_file,
                                      },
                                    }),
                                    _vm._v(
                                      "\n                            Your browser does not support the audio element.\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.sequence.message_file !== null &&
                              _vm.hasPermissionTo("delete file sequence")
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-danger pull-right",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.deleteFile(
                                            _vm.sequence.message_file
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.loading_message,
                                              expression: "loading_message",
                                            },
                                          ],
                                          staticClass: "material-icons loader",
                                        },
                                        [_vm._v("")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "i",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.loading_message,
                                              expression: "!loading_message",
                                            },
                                          ],
                                          staticClass: "material-icons",
                                        },
                                        [_vm._v("")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Remove file")]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            {
                              staticClass:
                                "w-full checkbox-label-description mb-4",
                              attrs: { border: "" },
                              model: {
                                value: _vm.sequence.confirm_message,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sequence, "confirm_message", $$v)
                                },
                                expression: "sequence.confirm_message",
                              },
                            },
                            [
                              _c("small", [
                                _vm._v(
                                  "\n                            Repeat message & require agent to press any key to connect to contact\n                        "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.sequence.confirm_message
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "alert alert-warning alert-aloware",
                                },
                                [_vm._m(1)]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.sequence.type == _vm.SequenceTypes.TYPE_RVM
                ? _c("div", { staticClass: "row" }, [_vm._m(2)])
                : _vm._e(),
              _vm._v(" "),
              _vm.sequence.type == _vm.SequenceTypes.TYPE_MMS
                ? _c("div", { staticClass: "row" }, [_vm._m(3)])
                : _vm._e(),
              _vm._v(" "),
              _vm.sequence.type === _vm.SequenceTypes.TYPE_CALL_RING_GROUP
                ? _c("div", { staticClass: "row mt-3" }, [_vm._m(4)])
                : _vm._e(),
              _vm._v(" "),
              [
                _vm.SequenceTypes.TYPE_SMS_RING_GROUP,
                _vm.SequenceTypes.TYPE_EMAIL_RING_GROUP,
              ].includes(_vm.sequence.type)
                ? _c("div", { staticClass: "row" }, [_vm._m(5)])
                : _vm._e(),
              _vm._v(" "),
              _vm.sequence.type == _vm.SequenceTypes.TYPE_WEBHOOK
                ? _c("div", { staticClass: "row" }, [_vm._m(6)])
                : _vm._e(),
              _vm._v(" "),
              _vm.sequence.type == _vm.SequenceTypes.TYPE_BIRTHDAY &&
              !_vm.statics.whitelabel
                ? _c("div", { staticClass: "row" }, [_vm._m(7)])
                : _vm._e(),
              _vm._v(" "),
              _vm.sequence.type == _vm.SequenceTypes.TYPE_ADD_TAG_CONTACT
                ? _c("div", { staticClass: "row" }, [_vm._m(8)])
                : _vm._e(),
              _vm._v(" "),
              _vm.sequence.type == _vm.SequenceTypes.TYPE_REMOVE_TAG_CONTACT
                ? _c("div", { staticClass: "row" }, [_vm._m(9)])
                : _vm._e(),
              _vm._v(" "),
              _vm.sequence.type == _vm.SequenceTypes.TYPE_CHANGE_CONTACT_OWNER
                ? _c("div", { staticClass: "row" }, [_vm._m(10)])
                : _vm._e(),
              _vm._v(" "),
              _vm.sequence.type === _vm.SequenceTypes.TYPE_CALL_LEAD
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "no-border my-4",
                      attrs: { prop: "amd_enabled" },
                    },
                    [
                      _vm.sequence.type === _vm.SequenceTypes.TYPE_CALL_LEAD
                        ? _c(
                            "el-checkbox",
                            {
                              staticClass:
                                "w-full checkbox-label-description mb-4",
                              attrs: { border: "" },
                              model: {
                                value: _vm.sequence.amd_enabled,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sequence, "amd_enabled", $$v)
                                },
                                expression: "sequence.amd_enabled",
                              },
                            },
                            [
                              _c("label", [
                                _vm._v(
                                  "Detect answering machines and voicemails?"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("small", [
                                _vm._v(
                                  "\n                        Detects answering machines and voicemails\n                    "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.sequence.type == _vm.SequenceTypes.TYPE_CALL_LEAD &&
              _vm.sequence.amd_enabled
                ? _c("div", { staticClass: "b-b nav-active-greenish" }, [
                    _c("ul", { staticClass: "nav nav-tabs" }, [
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "border border-right-0 rounded-top-left",
                            class: _vm.amdVoicemailNavClass.read,
                            attrs: {
                              href: "#",
                              "data-toggle": "tab",
                              "data-target": "#amd-voicemail-tts",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-robot" }),
                            _vm._v(
                              "\n                            Text to Speech\n                        "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.hasPermissionTo("upload file sequence")
                        ? _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "border rounded-top-right",
                                class: _vm.amdVoicemailNavClass.play,
                                attrs: {
                                  href: "#",
                                  "data-toggle": "tab",
                                  "data-target": "#amd-voicemail-file",
                                  "aria-expanded": "true",
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-play text-md" }),
                                _vm._v(
                                  "\n                            Play Recording\n                        "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.sequence.type == _vm.SequenceTypes.TYPE_CALL_LEAD &&
              _vm.sequence.amd_enabled
                ? _c(
                    "div",
                    { staticClass: "tab-content pt-2 border border-top-0" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mx-3 mb-5",
                          class: _vm.amdVoicemailContainerClass.read,
                          attrs: {
                            id: "amd-voicemail-tts",
                            "aria-expanded": "true",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "no-border mb-4",
                              attrs: {
                                label: "Voicemail text:",
                                error: _vm.errors.first("amd_voicemail_tts"),
                                prop: "amd_voicemail_tts",
                              },
                            },
                            [
                              _c("el-popover", {
                                ref: "popover_amd_voicemail_text",
                                attrs: {
                                  placement: "bottom-start",
                                  title: "Title",
                                  width: "200",
                                  trigger: "hover",
                                  content: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-8" }, [
                                  _c(
                                    "div",
                                    { staticClass: "input-group" },
                                    [
                                      _c("el-input", {
                                        staticClass: "form-control pt-2 pb-2",
                                        attrs: {
                                          type: "textarea",
                                          maxlength: "190",
                                          rows: 4,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.imposeMessageCharLimit(
                                              "amd_voicemail_tts"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.sequence.amd_voicemail_tts,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.sequence,
                                              "amd_voicemail_tts",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "sequence.amd_voicemail_tts",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("variable-dialog", {
                                      attrs: {
                                        in_input_group: false,
                                        has_agent: _vm.hasAgent,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("template-list-dialog", {
                                      staticClass: "mt-2",
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.hasPermissionTo("upload file sequence")
                        ? _c(
                            "div",
                            {
                              staticClass: "mx-3 mb-3",
                              class: _vm.amdVoicemailContainerClass.play,
                              attrs: {
                                id: "amd-voicemail-file",
                                "aria-expanded": "true",
                              },
                            },
                            [
                              _vm.sequence.amd_voicemail_file === null
                                ? _c("audio-recorder", {
                                    staticClass: "audio-recorder-wrapper",
                                    attrs: {
                                      action:
                                        "/api/v1/automations/sequences/upload-file/amd-voicemail",
                                      upload_type: "amd_voicemail_file",
                                    },
                                    on: {
                                      applyUploadedAudio:
                                        _vm.applyUploadedAudio,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "audio-uploader-wrapper" },
                                [
                                  _vm.sequence.amd_voicemail_file === null
                                    ? _c(
                                        "el-upload",
                                        {
                                          attrs: {
                                            action:
                                              "/api/v1/automations/sequences/upload-file/amd-voicemail",
                                            headers: _vm.headers,
                                            "on-success":
                                              _vm.onSuccessAMDVoicemail,
                                            "on-error":
                                              _vm.onFailedAMDVoicemail,
                                            "on-progress":
                                              _vm.progressUploadAMDVoicemail,
                                            "before-upload":
                                              _vm.beforeUploadAMDVoicemail,
                                            "file-list":
                                              _vm.uploadFileList.amd_voicemail,
                                            drag: "",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-upload",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "el-upload__text" },
                                            [
                                              _vm._v(
                                                "\n                                Drop file here or "
                                              ),
                                              _c("em", [
                                                _vm._v("click to upload"),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "el-upload__tip",
                                              attrs: { slot: "tip" },
                                              slot: "tip",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                MP3/WAV file (less than 8MB)\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.sequence.amd_voicemail_file === null
                                    ? _c("el-progress", {
                                        attrs: {
                                          "text-inside": true,
                                          "stroke-width": 18,
                                          percentage:
                                            _vm.uploadPercentage.amd_voicemail,
                                          status:
                                            _vm.uploadStatus.amd_voicemail,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.sequence.amd_voicemail_file !== null
                                    ? _c("audio", { attrs: { controls: "" } }, [
                                        _c("source", {
                                          attrs: {
                                            src:
                                              "/static/uploaded_file/" +
                                              _vm.sequence.amd_voicemail_file,
                                          },
                                        }),
                                        _vm._v(
                                          "\n                            Your browser does not support the audio element.\n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.sequence.amd_voicemail_file !== null &&
                                  _vm.hasPermissionTo("delete file sequence")
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-danger pull-right",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.deleteFile(
                                                _vm.sequence.amd_voicemail_file,
                                                "amd_voicemail"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.loading_amd_voicemail,
                                                  expression:
                                                    "loading_amd_voicemail",
                                                },
                                              ],
                                              staticClass:
                                                "material-icons loader",
                                            },
                                            [_vm._v("")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "i",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.loading_amd_voicemail,
                                                  expression:
                                                    "!loading_amd_voicemail",
                                                },
                                              ],
                                              staticClass: "material-icons",
                                            },
                                            [_vm._v("")]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Remove file")]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.sequence.type == _vm.SequenceTypes.TYPE_MMS
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "b-b nav-active-greenish" }, [
              _c("ul", { staticClass: "nav nav-tabs" }, [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "border border-right-0 rounded-top-left",
                      class: { "nav-link": true, active: true },
                      attrs: {
                        href: "#",
                        "data-toggle": "tab",
                        "data-target": "#messagetts",
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Message\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tab-content p-2 border border-top-0" }, [
              _c(
                "div",
                {
                  staticClass: "mx-3 my-3",
                  class: _vm.messageContainerClass.read,
                  attrs: { id: "messagetts", "aria-expanded": "true" },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-8" }, [
                      _c("span", { staticClass: "pull-right" }, [
                        _c("b", [_vm._v("Limit:")]),
                        _vm._v(
                          " " + _vm._s(_vm.message_total_count) + " / 1600"
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        error: _vm.errors.first("message_tts"),
                        prop: "message_tts",
                      },
                    },
                    [
                      _c("el-popover", {
                        ref: "popover_messagetext",
                        attrs: {
                          placement: "bottom-start",
                          title: "Title",
                          width: "200",
                          trigger: "hover",
                          content: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _c(
                            "div",
                            { staticClass: "input-group" },
                            [
                              _c("input-with-info-text", {
                                staticClass: "form-control pt-2 pb-2",
                                attrs: {
                                  "info-text":
                                    "[Text <b>STOP</b> to unsubscribe]",
                                  "model-value": _vm.sequence.message_tts,
                                  "is-loading": _vm.generatingUrls,
                                  "is-info-text-active": _vm.is_optout_active,
                                  rows: _vm.msg_field_rows,
                                  "left-pixels": 22,
                                  "bottom-pixels": 10,
                                  "label-size": 13,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.imposeMessageCharLimit(
                                      "message_tts"
                                    )
                                  },
                                  blur: _vm.detectLongUrls,
                                },
                                model: {
                                  value: _vm.sequence.message_tts,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sequence, "message_tts", $$v)
                                  },
                                  expression: "sequence.message_tts",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-4" },
                          [
                            _c("variable-dialog", {
                              attrs: {
                                in_input_group: false,
                                has_agent: _vm.hasAgent,
                              },
                            }),
                            _vm._v(" "),
                            _c("template-list-dialog", { staticClass: "mt-2" }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-8" }, [
                          _c("span", { staticClass: "pull-right ml-4" }, [
                            _c("b", [_vm._v("Message parts:")]),
                            _vm._v(
                              " " + _vm._s(_vm.messageTtsCount) + " / 160"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "pull-right",
                              class: _vm.messageCounterClass,
                            },
                            [
                              _vm._v(
                                "\n                                    Message(s): " +
                                  _vm._s(_vm.messageCount) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "checkbox-group" }, [
                        _c(
                          "div",
                          { staticClass: "checkbox-group-area" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "w-full checkbox-group-item",
                                attrs: {
                                  value: _vm.is_optout_active,
                                  disabled:
                                    _vm.current_company.force_opt_out_phrase,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateIsOptoutActive($event)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "bottom",
                                      disabled: !_vm.isOptoutForced,
                                      content: _vm.optout_tooltip_text,
                                    },
                                  },
                                  [
                                    _c("small", { staticClass: "ml-0 pl-0" }, [
                                      _vm._v(
                                        "\n                                            Add opt-out phrase for this message\n                                        "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex flex-grow-1 align-items-baseline mx-2" },
      [
        _c(
          "label",
          {
            staticClass: "el-form-item__label",
            attrs: { for: "message_body" },
          },
          [
            _vm._v(
              "\n                            Message body:\n                        "
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [
      _c("i", { staticClass: "fas fa-exclamation-circle" }),
      _vm._v(" Because "),
      _c("strong", [_vm._v("Repeat Message")]),
      _vm._v(
        " setting is enabled, make sure the audio message contains a prompt like “Press any key to connect” so the agent knows how to connect once they receive this message"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "mb-0 mb-3" }, [
        _c("strong", [
          _vm._v("Please let us know before using this step type."),
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-muted" }, [
          _vm._v("Some jurisdictions do not allow Ringless Voicemail"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "mb-0 mb-3" }, [
        _c("strong", [_vm._v("Only image files are supported.")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "lh-1p4" }, [
        _vm._v(
          "\n                        This text-to-speech prompt automatically plays immediately as soon as the call gets connected.\n                        It’s important to make sure that the forwarding number does not have any prompts as the\n                        text-to-speech will play while the prompt is also playing causing the agent to miss the\n                        text-to-speech prompt.\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "mb-0 mb-3" }, [
        _c("strong", [
          _vm._v(
            "This message will be sent to all your agents. Use it as a reminder or\n                            call for action."
          ),
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-muted" }, [
          _vm._v(
            'Example: "Hey [AgentName], dont forget to take your lunch break after 10 straight calls."'
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "mb-0 mb-3" }, [
        _c("strong", [
          _vm._v("Triggers a webhook with the contact information attached."),
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-muted" }, [
          _vm._v(
            "Consider letting our support know. We might already have a direct integration."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "mb-0 mb-3" }, [
        _c("strong", [
          _vm._v("Did you know Aloware was born in November 2017?"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "mb-0 mb-3" }, [
        _c("strong", [_vm._v("Attach a specific tag to this contact.")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-muted" }, [
          _vm._v('Use this sequence to mark the lead as "unresponsive".'),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "mb-0 mb-3" }, [
        _c("strong", [_vm._v("Detach a specific tag from this contact.")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-muted" }, [
          _vm._v("Use this sequence remove the lead's mark."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "mb-0 mb-3" }, [
        _c("strong", [_vm._v("Assigns this lead to another agent.")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }