<template>
    <div class="card summary-card-hover h-100"
         v-loading="loading">
        <div class="row">
            <div class="col-12 p-1">
                <div class="d-inline-block pt-3 pl-4 align-bottom">
                    <i style="color: #03c04c"
                       class="fas fa-file-import">
                    </i>
                </div>
                <div class="d-inline-block pl-2">
                    <strong class="text-md title">Leads by Sources</strong>
                </div>
            </div>
        </div>
        <div class="card-body py-0" style="height: 150px;overflow-y: scroll">
            <table v-if="lead_sources.length > 0"
                   data-testid="lead-source-table"
                   class="table table-sm table-aloware"
                   style="font-size: 14px">
                <thead data-testid="lead-source-head">
                <tr data-testid="lead-source-head-row">
                    <th data-testid="lead-source-head-source-column" 
                        class="text-greyish">
                        Source</th>
                    <th data-testid="lead-source-head-contacts-column" 
                        class="text-greyish">
                        Contacts
                    </th>
                </tr>
                </thead>
                <tbody data-testid="lead-source-body">
                <tr v-for="lead_source in lead_sources"
                    data-testid="lead-source-body-row"
                    class="countup-hover"
                    :key="lead_source.id">
                    <td class="text-greyish" 
                        data-testid="lead-source-body-source-column">
                        <router-link class="d-block"
                                     :to="{ name: 'Contacts', query: {lead_source_id: lead_source.id, from_date: filter.from_date, to_date: filter.to_date}}">
                            {{ lead_source.name }}
                        </router-link>
                    </td>
                    <td class="text-black font-weight-bold hover-value" 
                        data-testid="lead-source-body-contacts-column">
                        <router-link class="d-block"
                                     :to="{ name: 'Contacts', query: {lead_source_id: lead_source.id, from_date: filter.from_date, to_date: filter.to_date}}">
                            {{ lead_source.contacts | numFormat }}
                        </router-link>
                    </td>
                </tr>
                </tbody>
            </table>
            <div v-else class="py-3 text-center">
                <small class="text-dead metric-label">
                    No lead source in this date range.
                </small>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "lead-source-widget",

        data() {
            return {
                reporting_url: localStorage.getItem('reporting_url'),
                loading: true,
                lead_sources: []
            }
        },

        computed: {
            ...mapGetters({
                filter: 'getFilter'
            }),
        },

        mounted() {
            this.fetchLeadSourceSummary()
        },

        created() {
            VueEvent.listen('update_summary', () => {
                this.fetchLeadSourceSummary()
            })
        },

        methods: {
            fetchLeadSourceSummary() {
                this.loading = true
                axios.get(this.reporting_url + '/api/v1/analytics/dashboard/lead-source-summary', {
                    params: this.filter
                }).then(res => {
                    this.lead_sources = res.data
                    this.loading = false
                }).catch(err => {
                    console.log(err)
                })
            }
        }
    }
</script>
