<template>
    <div class="card p-3 h-100"
         v-loading="loading">
        <div class="d-flex mb-3">
            <img src="/assets/images/app-icons/new/appointment_icon.svg" class="mr-2" />
            <h6 class="mb-0 align-self-center">Appointments</h6>
        </div>

        <div v-loading="metrics == {}"
             class="pt-5">
            <div class="row mb-5">
                <div class="col-6">
                    <metric-widget label="Sold"
                                   :metric="metrics.simpsocial_sold">
                    </metric-widget>
                </div>
                <div class="col-6">
                    <metric-widget label="Appointments Set"
                                   description="Number of appointments set from this date range."
                                   :metric="metrics.appointment_set">
                    </metric-widget>
                </div>
            </div>
            <br/>
            <div class="row">
                <div class="col-6">
                    <metric-widget label="Appointments Shown"
                                   description="Number of appointments shown from this date range."
                                   :metric="metrics.appointment_shown">
                    </metric-widget>
                </div>
                <div class="col-6">
                    <metric-widget label="Appointments Canceled"
                                   description="Number of appointments canceled from this date range."
                                   :metric="metrics.appointment_canceled">
                    </metric-widget>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MetricWidget from "./metric-widget"
import DashboardFilter from "../../../filters/dashboard-filter"

export default {
    name: "appointment-metrics-widget",

    components: {MetricWidget},

    data() {
        return {
            loading: false,
            metrics: {},
        }
    },

    computed: {
        kpiFilters() {
            return DashboardFilter.kpiFilters()
        }
    },

    mounted() {
        this.fetchAppointmentMetrics()
    },

    methods: {
        fetchAppointmentMetrics() {
            this.loading = true
            axios.get(`/api/v1/kpis/communication/appointments`, {
                params: {
                    aggregates: [
                        15, // APPOINTMENT_SET
                        16, // APPOINTMENT_SHOWN
                        17, // APPOINTMENT_CANCELLED
                        18  // SIMPSOCIAL_SOLD
                    ],
                    filters: DashboardFilter.get()
                }
            }).then(res => {
                this.loading = false
                this.metrics = res.data
            })
        }
    },

    watch: {
        kpiFilters() {
            this.fetchAppointmentMetrics()
        }
    }
}
</script>
