<template>
    <div>
        <el-tabs v-model="active_tab_name">
            <el-tab-pane name="addons"
                         label="Current Addons">
                <el-table style="width: 100%"
                          :data="addons" 
                          v-loading="loading_current">
                    <el-table-column label="Addon">
                        <template #default="{ row }">
                            {{ row.id | prettifyCamelCase | ucwords | fixCountryLetters }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Unit Price"> 
                        <template #default="{ row }">
                            {{ row.unit_price | toCurrency }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Quantity"> 
                        <template #default="{ row }">
                            {{ row.quantity }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="totalPriceColumnName"> 
                        <template #default="{ row }">
                            {{ row.amount | toCurrency }}
                        </template>
                    </el-table-column>
                </el-table>
                <div class="row px-3 mt-3">
                    <p class="mb-0 font-weight-bold">Need a Local Presence package? 
                        <a class="text-primary"
                           @click.prevent="goToLines">
                           Go to lines page.
                        </a>
                    </p>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import TERM_NAMES from '../../constants/plan-term-name'

export default {
    props: {
        visible: Boolean
    },

    data () {
        return {
            addons: [],
            available_addons: [],
            owned_addons: [],
            loading_current: false,
            loading_available: false,
            active_tab_name: 'addons',
            TERM_NAMES
        }
    },

    methods: {
        fetchCurrentAddons () {
            this.loading_current = true
            axios.get('/api/v1/chargebee-addons')
                .then(res => {
                    this.addons = res.data
                })
                .finally(() => {
                    this.loading_current = false
                });
        },

        goToLines () {
            this.$router.push({ name: 'Lines' })
        },
    },

    computed: {
        ...mapState('cache', ['current_company']),

        totalPriceColumnName () {
            let term = this.current_company.subscription?.plan?.term
            let term_description = this.TERM_NAMES[term];

            if (!term) {
                return 'Recurring Price'
            }

            return `Recurring ${term_description} Price`
        }
    },

    watch: {
        visible: {
            handler (visible) {
                if (visible) {
                    this.fetchCurrentAddons()
                }
            },
            immediate: true
        },

        active_tab_name: {
            handler (name) {
                switch (name) {
                    case 'addons':
                        this.fetchCurrentAddons()
                        break;
                }
            }
        }
    }
}
</script>
