var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "import-settings" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6 pr-4" }, [
        _c(
          "div",
          { staticClass: "form-label mb-4", attrs: { scope: "label" } },
          [
            _c("label", [_vm._v("DNC List")]),
            _vm._v(" "),
            _vm.import_contact.is_dnc
              ? _c("small", [
                  _c("i", {
                    staticClass: "fa fa-exclamation-triangle text-danger",
                  }),
                  _vm._v(
                    "\n                    The imported contacts were set to DNC (Do Not Call) and this is not reversible.\n                "
                  ),
                ])
              : _c("small", [
                  _vm._v(
                    "\n                    The imported contacts were not treated as a DNC list.\n                "
                  ),
                ]),
          ]
        ),
        _vm._v(" "),
        !_vm.import_contact.is_dnc
          ? _c("div", [
              _c(
                "div",
                { staticClass: "form-label mb-4", attrs: { scope: "label" } },
                [
                  _c("label", [_vm._v("Update Existing Contacts")]),
                  _vm._v(" "),
                  _vm.import_contact.update_existing
                    ? _c("small", [
                        _vm._v(
                          "\n                        The original existing contact data was overridden with what was in the CSV file.\n                    "
                        ),
                      ])
                    : _c("small", [
                        _vm._v(
                          "\n                        Existing contacts were not updated with data from the CSV file.\n                    "
                        ),
                      ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-label mb-4", attrs: { scope: "label" } },
                [
                  _c("label", [_vm._v("Save Unknown Columns")]),
                  _vm._v(" "),
                  _vm.import_contact.unknown_columns_to_notes
                    ? _c("small", [
                        _vm._v(
                          "\n                        Columns that were not mapped to contact fields were saved to the contact's notes.\n                    "
                        ),
                      ])
                    : _c("small", [
                        _vm._v(
                          "\n                        Columns that were not mapped to contact fields were discarded.\n                    "
                        ),
                      ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-label mb-4", attrs: { scope: "label" } },
                [
                  _c("label", [_vm._v("Multiple Contacts")]),
                  _vm._v(" "),
                  _vm.import_contact.cascade_contacts
                    ? _c("small", [
                        _vm._v(
                          "\n                        A new contact was created for each phone number found in the row.\n                    "
                        ),
                      ])
                    : _c("small", [
                        _vm._v(
                          "\n                        A single contact was created per row, using the first phone number found.\n                    "
                        ),
                      ]),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.import_contact.is_dnc
        ? _c("div", { staticClass: "col-md-6" }, [
            _c(
              "div",
              { staticClass: "form-label mb-4", attrs: { scope: "label" } },
              [
                _c("label", [_vm._v("Assigned to")]),
                _vm._v(" "),
                _c("div", [
                  _vm.import_contact.user
                    ? _c("strong", [_vm._v("User:")])
                    : _vm.import_contact.ring_group
                    ? _c("strong", [_vm._v("Ring Group:")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.getAssignedToText))]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-label mb-4", attrs: { scope: "label" } },
              [
                _c("label", [_vm._v("Sequence")]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.getDisplayValue(_vm.import_contact.workflow?.name)
                      ) +
                      "\n                "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-label mb-4", attrs: { scope: "label" } },
              [
                _c("label", [_vm._v("Line")]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.getDisplayValue(_vm.import_contact.campaign?.name)
                      ) +
                      "\n                "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-label mb-4", attrs: { scope: "label" } },
              [
                _c("label", [_vm._v("Tags")]),
                _vm._v(" "),
                _vm._l(_vm.import_contact.tags, function (tag) {
                  return _c(
                    "div",
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            placement: "top",
                            content: tag?.name,
                          },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Contacts",
                                  query: { tag_id: tag?.id },
                                },
                              },
                            },
                            [
                              _c("span", { style: _vm.tagColorStyle(tag) }, [
                                _c("i", { staticClass: "fa fa-tag" }),
                                _vm._v(
                                  " \n                                    " +
                                    _vm._s(_vm._f("strLimit")(tag?.name, 40)) +
                                    "\n                                "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.import_contact.tags?.length === 0
                  ? _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.getDisplayValue(null)) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }