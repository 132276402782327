var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "40vw",
        top: "4vh",
        visible: _vm.modal_visible,
        "append-to-body": true,
        "close-on-click-modal": false,
        "before-close": _vm.beforeClose,
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("h5", { staticClass: "mb-0" }, [
          _vm.mode === _vm.MODE_ADD
            ? _c("span", [_vm._v("Add New")])
            : _vm._e(),
          _vm._v(" "),
          _vm.mode === _vm.MODE_EDIT ? _c("span", [_vm._v("Edit")]) : _vm._e(),
          _vm._v("\n                Custom Field\n            "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        { ref: "attribute_dictionary_form", staticClass: "form-aloware" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "no-border",
              attrs: { error: _vm.errors.first("name"), prop: "name" },
            },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Name")]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "Name of the custom field" },
                model: {
                  value: _vm.attribute_dictionary.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.attribute_dictionary, "name", $$v)
                  },
                  expression: "attribute_dictionary.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              key: _vm.key,
              staticClass: "no-border",
              attrs: { error: _vm.errors.first("slug"), prop: "name" },
            },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Key")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    'Only lowercase characters, numbers, and underscores are allowed. Example: "middle_name"'
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "Key of the custom field" },
                model: {
                  value: _vm.attribute_dictionary.slug,
                  callback: function ($$v) {
                    _vm.$set(_vm.attribute_dictionary, "slug", $$v)
                  },
                  expression: "attribute_dictionary.slug",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Description")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v("Describe what this custom field is for."),
                ]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { type: "textarea", rows: 2 },
                model: {
                  value: _vm.attribute_dictionary.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.attribute_dictionary, "description", $$v)
                  },
                  expression: "attribute_dictionary.description",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Data Type")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v("Select the proper data type of this custom field"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w-full mb-4",
                  model: {
                    value: _vm.attribute_dictionary.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.attribute_dictionary, "type", $$v)
                    },
                    expression: "attribute_dictionary.type",
                  },
                },
                _vm._l(_vm.AttributeDictionary.TYPES, function (type) {
                  return _c("el-option", {
                    key: type.key,
                    attrs: { label: type.label, value: type.key },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _vm.attribute_dictionary.type ===
                _vm.AttributeDictionary.TYPE_DROPDOWN && false
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "no-border",
                      attrs: { error: _vm.errors.first("type_values") },
                    },
                    [
                      _c("div", { staticClass: "form-label" }, [
                        _c("h5", [_vm._v("Dropdown Options")]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "Please add the drop down options. You can add as many as you want."
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.attribute_dictionary.type_values,
                        function (value, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "d-flex mb-1" },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-grow-1 mr-1" },
                                [
                                  _c("el-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      placeholder: "Input option name here...",
                                    },
                                    model: {
                                      value:
                                        _vm.attribute_dictionary.type_values[i],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.attribute_dictionary.type_values,
                                          i,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "attribute_dictionary.type_values[i]",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "text-danger",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeDropdownOption(i)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-remove" })]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "text-center mt-2" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "medium", type: "success" },
                              on: { click: _vm.addDropdownOption },
                            },
                            [
                              _c("i", { staticClass: "fa fa-plus" }),
                              _vm._v(" "),
                              _c("strong", [_vm._v("Add Option")]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              staticClass: "no-border",
              attrs: { error: _vm.errors.first("default_value") },
            },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Default Value")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "The default value of the custom field. Leave it blank if you don't need a default value."
                  ),
                ]),
                _vm._v(" "),
                _vm.attribute_dictionary.type ===
                _vm.AttributeDictionary.TYPE_DROPDOWN
                  ? _c("small", [
                      _vm._v(
                        "\n                        Make sure the default value matches one of the options above.\n                    "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.attribute_dictionary.default_value,
                  callback: function ($$v) {
                    _vm.$set(_vm.attribute_dictionary, "default_value", $$v)
                  },
                  expression: "attribute_dictionary.default_value",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex mt-4" },
            [
              _c("el-button", { on: { click: _vm.beforeClose } }, [
                _vm._v("Close"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml-auto",
                  attrs: { type: "success", loading: _vm.saving },
                  on: { click: _vm.save },
                },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.saving ? "Saving..." : "Save")),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }