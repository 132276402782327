<template>
    <span v-if="in_input_group"
          :class="[is_append ? 'input-group-append' : 'input-group-prepend']">
        <el-popover placement="top-start"
                    width="400"
                    trigger="click"
                    class="p-0"
                    popper-class="variable-popover"
                    @show="popoverShow(1)">
            <variables-dropdown ref="variables_dropdown1"></variables-dropdown>
            <button slot="reference"
                    class="btn btn-block bluish w-full h-full"
                    :disabled="disabled"
                    :class="[is_append ? 'no-left-border-radius' : 'no-right-border-radius']"
                    v-if="!short"
                    data-testid="add-variable-in-dialog-button-group"
                    @click.prevent>
                <i class="material-icons">add</i>
                Add Variable
            </button>
            <button slot="reference"
                    class="btn btn-block bluish w-full h-full"
                    :disabled="disabled"
                    :class="[is_append ? 'no-left-border-radius' : 'no-right-border-radius']"
                    v-else
                    data-testid="add-in-dialog-button-group"
                    @click.prevent>
                <i class="material-icons">add</i>
            </button>
        </el-popover>
    </span>
    <span v-else>
        <el-popover placement="top-start"
                    width="400"
                    trigger="click"
                    class="p-0"
                    popper-class="variable-popover"
                    @show="popoverShow(2)">
            <variables-dropdown ref="variables_dropdown2"></variables-dropdown>
            <button slot="reference"
                    :class="'btn bluish mb-2 ' + btn_class"
                    :disabled="disabled"
                    v-if="!short"
                    data-testid="add-variable-in-dialog-button"
                    @click.prevent>
                <i class="material-icons">add</i>
                Add Variable
            </button>
            <button slot="reference"
                    class="btn bluish"
                    :disabled="disabled"
                    v-else
                    data-testid="add-in-dialog-button"
                    @click.prevent>
                <i class="material-icons">add</i>
            </button>
        </el-popover>
    </span>
</template>

<script>
import VariablesDropdown from '../components/variables-dropdown'

export default {
    components: {
        VariablesDropdown
    },

    props: {
        short: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        is_append: {
            type: Boolean,
            default: true
        },

        in_input_group: {
            type: Boolean,
            default: true
        },

        btn_class: {
            type: String,
            default: ''
        }
    },

    methods: {
        popoverShow(index) {
            if (this.$refs[('variables_dropdown' + index)]) {
                this.$refs[('variables_dropdown' + index)].focusToSelector()
            }
        }
    }
}
</script>
