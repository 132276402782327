<template>
    <el-popover placement="bottom"
                title="Event Legends"
                width="280"
                trigger="click"
                content="">
        <div>
            <table id="table-calendar-legend" style="font-size: 16px" class="w-100">
                <tr>
                    <td class="pr-2"><i class="fas fa-square event-color-type-12 status-8"></i></td>
                    <td>Upcoming Appointment</td>
                </tr>
                <tr>
                    <td class="pr-2"><i class="fas fa-square event-color-type-12"></i></td>
                    <td>Past Appointment</td>
                </tr>
                <tr>
                    <td class="pr-2"><i class="fas fa-square event-color-type-12 status-4"></i></td>
                    <td>Completed Appointment</td>
                </tr>
                <tr>
                    <td class="pr-2"><i class="fas fa-square event-color-type-12 status-5"></i></td>
                    <td>Canceled Appointment</td>
                </tr>
                <tr>
                    <td class="pr-2"><i class="fas fa-square event-color-type-12 status-14"></i></td>
                    <td>Missed Appointment</td>
                </tr>
                <tr>
                    <td colspan="2"><hr/></td>
                </tr>
                <tr>
                    <td class="pr-2"><i class="fas fa-square event-color-type-13 status-8"></i></td>
                    <td>Upcoming Reminder</td>
                </tr>
                <tr>
                    <td class="pr-2"><i class="fas fa-square event-color-type-13 status-8 is_past"></i></td>
                    <td>Past Reminder</td>
                </tr>
                <tr>
                    <td class="pr-2"><i class="fas fa-square event-color-type-13 status-4"></i></td>
                    <td>Completed Reminder</td>
                </tr>
                <tr>
                    <td class="pr-2"><i class="fas fa-square event-color-type-13 status-5"></i></td>
                    <td>Canceled Reminder</td>
                </tr>
                <tr>
                    <td class="pr-2"><i class="fas fa-square event-color-type-13 status-3"></i></td>
                    <td>Missed Reminder</td>
                </tr>
            </table>
        </div>
        <el-button type="text" class="btn-direction" slot="reference" @click="legend = !legend"
                   style="margin-right: 0.25rem;">
            <i class="far fa-question-circle"></i>
        </el-button>
    </el-popover>
</template>

<script>
    export default {
        data() {
            return {
                legend: false
            }
        }
    }
</script>
