<template>
    <div class="w-full"
         v-if="contact_id && campaign_id && phone_number">
        <el-select v-model="message"
                   placeholder="Select Template"
                   style="max-width: 170px;"
                   :loading="loading_templates"
                   default-first-option
                   clearable
                   filterable>
            <el-option-group key="Agent Templates"
                             label="Agent Templates"
                             v-if="agentTemplates && agentTemplates.length > 0">
                <el-option
                    v-for="template in agentTemplates"
                    :key="template.id"
                    :label="template.name"
                    :value="template.body">
                    <div class="media">
                        <div class="media-body">
                            <label>{{ template.name }}</label>
                            <small class="text-xxs">{{ template.body }}</small>
                        </div>
                    </div>
                </el-option>
            </el-option-group>
            <el-option-group key="Account Templates"
                             label="Account Templates"
                             v-if="accountTemplates && accountTemplates.length > 0">
                <el-option
                    v-for="template in accountTemplates"
                    :key="template.id"
                    :label="template.name"
                    :value="template.body">
                    <div class="media">
                        <div class="media-body">
                            <label>{{ template.name }}</label>
                            <small class="text-xxs">{{ template.body }}</small>
                        </div>
                    </div>
                </el-option>
            </el-option-group>
        </el-select>
        <button class="btn btn-md dark-greenish pull-right"
                :disabled="loading_send_message || !message || message.length === 0"
                @click="sendMessage">
            <i class="material-icons loader"
               v-show="loading_send_message">
                &#xE863;
            </i>
            Send
        </button>
    </div>
</template>

<script>
export default {
    props: {
        contact_id: {
            required: true
        },

        campaign_id: {
            required: true
        },

        phone_number: {
            required: true
        }
    },

    data() {
        return {
            message: null,
            templates: [],
            loading_templates: false,
            loading_send_message: false
        }
    },

    computed: {
        agentTemplates() {
            return this.templates.filter((template) => template.is_on_user)
        },

        accountTemplates() {
            return this.templates.filter((template) => template.is_on_company)
        }
    },

    created() {
        this.fetchTemplates()
    },

    methods: {
        fetchTemplates() {
            this.loading_templates = true
            axios.get('/api/v1/sms-template').then(res => {
                this.loading_templates = false
                this.templates = res.data
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_templates = false
            })
        },

        sendMessage() {
            this.loading_send_message = true
            axios.post('/api/v1/campaign/send-message/' + this.campaign_id + '/' + this.contact_id, {
                message: this.message,
                phone_number: this.phone_number
            }).then(res => {
                this.message = null
                this.loading_send_message = false
                this.$notify({
                    offset: 95,
                    title: 'Contact',
                    message: 'Message sent.',
                    type: 'success',
                    showClose: true,
                })
                this.$emit('messageSent')
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_send_message = false
            })
        },
    }
}
</script>
