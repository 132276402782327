<template>
    <el-button v-if="hasEnabledIntegration"
               type="success"
               size="small"
               :loading="loading"
               @click="checkIntegrations">{{ title }}
    </el-button>
</template>

<script>
import {mapState} from "vuex"
import isEmpty from 'lodash/isEmpty'
import {kyc_mixin} from '../mixins'

const FACEBOOK = 'facebook'
const PIPEDRIVE = 'pipedrive'
const HUBSPOT = 'hubspot'
const HELPSCOUT = 'helpscout'
const ZOHO = 'zoho'
const GUESTY = 'guesty'

export default {
    name: "integration-list-crm-tester",

    mixins: [
        kyc_mixin
    ],

    props: {
        title: {
            type: String,
            default: "Test CRM Integrations"
        }
    },
    data() {
        return {
            loading: false,
            enabled: {
                [FACEBOOK]: false,
                [PIPEDRIVE]: false,
                [HUBSPOT]: false,
                [HELPSCOUT]: false,
                [ZOHO]: false,
                [GUESTY]: false
            }
        }
    },
    computed: {
        ...mapState({
            current_company: state => state.cache.current_company
        }),
        hasEnabledIntegration() {
            return true
        }
    },
    mounted() {
        this.fetchDetails()
    },
    methods: {
        getByName(name) {
            return axios.get('/api/v1/integration/' + name)
                .then(response => response.data)
                .then(this.getParsedData)
                .catch(() => null)
        },
        getParsedData({integration}) {
            if (integration && integration.credentials) {
                return JSON.parse(integration.credentials)
            }
            return null
        },
        isEnabled(key, credentials) {
            if (isEmpty(credentials) || typeof credentials !== 'object') return false;

            let hasAccessToken = false

            if ([FACEBOOK, PIPEDRIVE, HUBSPOT, HELPSCOUT, ZOHO].includes(key)) {
                hasAccessToken = !isEmpty(credentials.access_token)
            }

            if (key === GUESTY) {
                hasAccessToken = !isEmpty(credentials.api_token)
            }

            const isEnabledByCompany = `${name}_integration_enabled`

            return isEnabledByCompany && hasAccessToken
        },
        fetchDetails: async function () {
            try {
                this.loading = true

                const response = await Promise.all([
                    this.getByName(FACEBOOK),
                    // this.getByName(PIPEDRIVE),
                    this.getByName(HUBSPOT),
                    // this.getByName(HELPSCOUT),
                    // this.getByName(ZOHO),
                    // this.getByName(GUESTY)
                ])

                this.enabled = {
                    [FACEBOOK]: this.isEnabled(FACEBOOK, response[0]),
                    // [PIPEDRIVE]: this.isEnabled(PIPEDRIVE, response[1]),
                    [HUBSPOT]: this.isEnabled(HUBSPOT, response[1]),
                    // [HELPSCOUT]: this.isEnabled(HELPSCOUT, response[3]),
                    // [ZOHO]: this.isEnabled(ZOHO, response[4]),
                    // [GUESTY]: this.isEnabled(GUESTY, response[5])
                }

                this.loading = false
            } catch (err) {
                console.error(err)
                this.loading = true
            }
        },
        checkIntegrations() {
            return this.performChecking().finally(this.fetchDetails)
        },
        async performChecking() {
            try {
                this.loading = true

                let promises = []

                if (this.enabled[FACEBOOK]) {
                    promises.push(this.checkStatus(FACEBOOK))
                }

                if (this.enabled[PIPEDRIVE]) {
                    promises.push(this.checkStatus(PIPEDRIVE))
                }

                if (this.enabled[HUBSPOT]) {
                    promises.push(this.checkStatus(HUBSPOT))
                }

                if (this.enabled[HELPSCOUT]) {
                    promises.push(this.checkStatus(HELPSCOUT))
                }

                if (this.enabled[ZOHO]) {
                    promises.push(this.checkStatus(ZOHO))
                }

                if (this.enabled[GUESTY]) {
                    promises.push(this.checkStatus(GUESTY))
                }

                await Promise.all(promises)

                this.loading = false
            } catch (err) {
                console.error(err)
                this.loading = true
            }
        },
        async checkStatus(name) {
            try {
                await axios.get(`/api/v1/integration/${name}/status`, {
                    headers: {
                        'Accept': 'application/json'
                    }
                })

                this.$notify.success({
                    offset: 95,
                    title: 'Connection Success',
                    message: `Your ${name} connection is working!`,
                    duration: 5000,
                })

            } catch (err) {
                this.$notify.error({
                    offset: 95,
                    title: 'Connection Error',
                    message: `Unable to connect to ${name} service!`,
                    duration: 5000,
                })
            }
        },
    }
}
</script>
