<template>
    <div class="w-full width-500">
        <div class="login-brand">
            <img :src="statics.logo"
                 alt="."/>
        </div>

        <div class="dashboard-intro mt-3 mb-3">
            <input-group>
                <template v-slot:content>
                    <input-field label="Email"
                                 type="email"
                                 placeholder="youremail@domain.com"
                                 :col-span=24
                                 v-model="user.email" />
                </template>
            </input-group>

            <input-group>
                <template v-slot:content>
                    <password-input :col-span=24
                                    v-model="user.password"
                                    @valid="updatePasswordValid($event)" />
                </template>
            </input-group>

            <input-group>
                <template v-slot:content>
                    <password-confirmation-input :col-span=24
                                                 :main-password="user.password"
                                                 v-model="user.password_confirmation"
                                                 @match="updatePasswordsMatch($event)" />
                </template>
            </input-group>

            <vue-recaptcha ref="recaptcha"
                           class="g-recaptcha"
                           :sitekey="site_key"
                           @verify="onVerify"
                           @expired="onExpired">
            </vue-recaptcha>

            <el-button type="success"
                       class="p-x-md btn-block mt-2"
                       :disabled="isButtonDisabled"
                       @click="resetPass">
                <i class="material-icons loader pull-right"
                   v-show="loading">&#xE863;</i>
                Reset Password
                <i class="el-icon-arrow-right el-icon-right pull-right"
                   v-show="!loading"></i>
            </el-button>
        </div>

        <static-assets-preloader :assets="statics"></static-assets-preloader>
    </div>
</template>

<script>
    import auth from '../auth'
    import {VueRecaptcha} from "vue-recaptcha"
    import PasswordInput from '../components/password-input'
    import PasswordConfirmationInput from '../components/password-confirmation-input'

    export default {
        components: {
            'vue-recaptcha': VueRecaptcha,
            PasswordInput,
            PasswordConfirmationInput,
        },

        props: {
            statics: {required: true},
            token: {token: true}
        },

        data() {
            return {
                user: {
                    email: null,
                    password: null,
                    password_confirmation: null,
                    recaptcha_response: null
                },
                loading: false,
                site_key: localStorage.getItem('recaptcha_site_key'),
                password_valid: false,
                passwords_match: false,
            }
        },

        computed: {
            isButtonDisabled() {
                return this.loading || !this.user.email || !this.password_valid || !this.passwords_match || !this.user.recaptcha_response
            },
        },

        methods: {
            reset() {
                this.error = false
            },

            resetPass(event) {
                event.preventDefault()
                this.loading = true
                auth.resetPass(this.user.email, this.user.password, this.user.password_confirmation, this.token, this.user.recaptcha_response)
                    .then(res => {
                        // success
                        this.$notify({
                            title: 'Auth',
                            message: res.data.data,
                            type: 'success',
                            showClose: true,
                        })
                        this.success = true
                        setTimeout(() => {
                            this.$router.push({name: 'Login'})
                        }, 1000)
                    })
                    .catch(err => {
                        // error
                        this.$root.handleErrors(err.response)
                        this.loading = false
                    })
            },

            onVerify(response) {
                this.user.recaptcha_response = response
            },

            onExpired() {
                this.user.recaptcha_response = null
            },

            updatePasswordValid ($event) {
                this.password_valid = $event
            },

            updatePasswordsMatch($event) {
                this.passwords_match = $event
            },
        },
    }
</script>
