export const TRIGGER_VARIABLE_TALK_TIME = {
    id: 1,
    name: 'Talk Time',
    value: 'talk_time',
}

export const TRIGGER_VARIABLE_WAIT_TIME = {
    id: 2,
    name: 'Wait Time',
    value: 'wait_time',
}

export const TRIGGER_VARIABLE_DURATION = {
    id: 3,
    name: 'Duration',
    value: 'duration',
}

export const TRIGGER_VARIABLE_HAS_ALL_KEYWORDS = {
    id: 4,
    name: 'Has All Keywords',
    value: 'has_all_keywords',
}

export const TRIGGER_VARIABLE_HAS_ANY_KEYWORDS = {
    id: 5,
    name: 'Has Any Keywords',
    value: 'has_any_keywords',
}

export const TRIGGER_VARIABLE_LAST_TRANSFERRED_LEG = {
    id: 6,
    name: 'Last Transferred Leg',
    value: 'last_transferred_leg',
}

export const TRIGGER_VARIABLE_FIRST_TIME_MESSAGE = {
    id: 7,
    name: 'First Time Message',
    value: 'first_time_message',
}

export const TRIGGER_VARIABLES = [
    TRIGGER_VARIABLE_TALK_TIME,
    TRIGGER_VARIABLE_WAIT_TIME,
    TRIGGER_VARIABLE_DURATION,
    TRIGGER_VARIABLE_HAS_ALL_KEYWORDS,
    TRIGGER_VARIABLE_HAS_ANY_KEYWORDS,
    TRIGGER_VARIABLE_LAST_TRANSFERRED_LEG,
    TRIGGER_VARIABLE_FIRST_TIME_MESSAGE
]
