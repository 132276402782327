import Vue from 'vue'
import linkify from 'vue-linkify'

Vue.directive('focus', {
    inserted: function (el) {
        el.focus()
    },
    update: function (el) {
        Vue.nextTick(function () {
            el.focus()
        })
    }
})

Vue.directive('linkified', linkify)

// This variable will hold the reference to
// document's click handler
let handleOutsideClick, 
    handleMouseMove, 
    handleMouseDown,
    handleMouseUp, 
    moved = false,
    clickedAndDragged = false

Vue.directive('closable', {
  bind (el, binding, vnode) {
    handleMouseMove = () => {
      moved = true
    }

    handleMouseDown = () => {
      moved = false
    }

    handleMouseUp = () => {
      if (moved) {
        clickedAndDragged = true
      }
    }

    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude = [] } = binding.value

      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName]
          
          // See if this excluded element
          // is the same element the user just clicked on
          if (excludedEl) {
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        }
      })

      // check if clicked element has these classes
      // these are for elements that you cannot set refs to
      const { classList } = e.target

      if (classList && (
        classList.contains('confirm-transfer-button') || 
        classList.contains('cancel-transfer-button') || 
        classList.contains('el-message-box__close') || 
        classList.contains('el-icon-close')
      )) {
        clickedOnExcludedEl = true
      }

      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl && !clickedAndDragged) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }

      clickedAndDragged = false
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('mouseup', handleMouseUp)
  },

  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mousedown', handleMouseDown)
    document.removeEventListener('mouseup', handleMouseUp)
  }
})

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});