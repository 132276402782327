var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "35vw",
        top: "10vh",
        "append-to-body": "",
        title: "Contact Assignments",
        "before-close": _vm.closeAssignDialog,
        visible: _vm.show_dialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show_dialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.assigning_contacts,
              expression: "assigning_contacts",
            },
          ],
        },
        [
          _c(
            "assign-contact",
            {
              ref: "assign_contact",
              attrs: {
                value: _vm.assigning_form.id,
                assign_to: _vm.assigning_form.assign_to,
                is_force: _vm.assigning_form.force,
              },
              on: {
                "update:value": function ($event) {
                  return _vm.$set(_vm.assigning_form, "id", $event)
                },
                "update:assign_to": function ($event) {
                  return _vm.$set(_vm.assigning_form, "assign_to", $event)
                },
                "update:is_force": function ($event) {
                  return _vm.$set(_vm.assigning_form, "force", $event)
                },
              },
            },
            [
              _c("span", { attrs: { slot: "titleText" }, slot: "titleText" }, [
                _vm._v(
                  "\n                Assign Contacts in " +
                    _vm._s(_vm.contactListName) +
                    " list to\n            "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container-footer d-flex mb-0 mt-3" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.closeAssignDialog },
                },
                [_vm._v("\n                Close\n            ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml-auto",
                  attrs: {
                    disabled: _vm.disableAssignContactButton,
                    type: "success",
                    size: "small",
                  },
                  on: { click: _vm.assignContact },
                },
                [_vm._v("\n                Assign Contact\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }