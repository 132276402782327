var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sms-reminders-wrapper mt-4 border-0 p-0" }, [
    _c(
      "div",
      { staticClass: "text-center" },
      [
        !_vm.campaign_id
          ? _c(
              "div",
              [
                _c(
                  "el-select",
                  {
                    staticClass: "p-1",
                    attrs: { filterable: "", placeholder: "Send from" },
                    model: {
                      value: _vm.selected_campaign,
                      callback: function ($$v) {
                        _vm.selected_campaign = $$v
                      },
                      expression: "selected_campaign",
                    },
                  },
                  _vm._l(_vm.campaigns, function (campaign) {
                    return _c("el-option", {
                      key: campaign.id,
                      attrs: { label: campaign.name, value: campaign.id },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-tooltip",
          {
            attrs: {
              effect: "dark mb-4",
              placement: "top",
              content:
                "The contact must have a timezone to send an SMS reminder.",
              disabled: !_vm.disableSendSmsReminderButton,
            },
          },
          [
            _c(
              "span",
              [
                _vm.show_send_sms_reminder_button
                  ? _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        attrs: {
                          disabled: _vm.disableSendSmsReminderButton,
                          type: "primary",
                        },
                        on: { click: _vm.sendDefaultSmsReminder },
                      },
                      [
                        _vm._v(
                          "\n                    Send SMS reminder now\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }