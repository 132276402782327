<template>
    <el-form class="form-aloware">
        <div class="row">
            <div class="col-6">
                <el-form-item class="no-border"
                              :error="errors.first('name')">
                    <div class="form-label">
                        <h5>Name</h5>
                        <small>
                            Specify a name for this automated sequence.
                        </small>
                    </div>
                    <el-input
                        v-model="value.name"
                        class="w-full">
                    </el-input>
                </el-form-item>
                <el-form-item :error="errors.first('description')">
                    <div class="form-label">
                        <h5>Description</h5>
                        <small>
                            Describe what this sequence is about.
                        </small>
                    </div>
                    <el-input
                        type="textarea"
                        v-model="value.description">
                    </el-input>
                </el-form-item>

                <el-form-item class="no-border"
                              :error="errors.first('process_on')">
                    <div class="form-label">
                        <h5>Process On</h5>
                        <small>
                            Choose if you want the sequence to process everyday or business days only.
                        </small>
                    </div>
                    <el-radio-group v-model="value.send_on"
                                    size="medium"
                                    class="mb-3">
                        <el-radio-button :label="Workflow.SEND_ON_ANY_DAYS">Everyday</el-radio-button>
                        <el-radio-button :label="Workflow.SEND_ON_BIZ_DAYS">Business Days</el-radio-button>
                    </el-radio-group>
                    <el-checkbox name="process-holidays"
                                 class="w-full checkbox-label-description mt-2"
                                 v-model="value.process_on_holidays"
                                 border>
                        <label class="mb-1">
                            <span class="mr-1">Run on national holidays</span>
                            <!-- Hide the pop up from SimpSocial Accounts -->
                            <el-popover
                                placement="right"
                                class="ml-auto"
                                trigger="hover"
                                :disabled="current_company.reseller_id === 357">
                                <div>
                                    <span>See</span>
                                    <a class="text-blue-6"
                                       href="https://support.aloware.com/en/articles/9037395-aloware-sequence-explained-how-it-works-and-how-to-get-started"
                                       target="_blank">
                                       <u>list of holidays</u>
                                    </a>
                                </div>
                                <i class="material-icons text-blue-6 mr-2"
                                   style="cursor: pointer;font-size: 15px"
                                   slot="reference">
                                    info_outline
                                </i>
                            </el-popover>
                        </label>
                        <small>
                            Enabling this will make this sequence run on (US) holidays.
                        </small>
                    </el-checkbox>
                </el-form-item>

                <el-form-item class="no-border" :error="errors.first('always_open')">
                    <div class="form-label">
                        <h5>Day Schedule</h5>
                        <small>
                            Choose if you want the sequence to process on a specific time range or process at any time.
                        </small>
                    </div>
                    <el-radio-group v-model="value.always_open"
                                    size="medium">
                        <el-radio-button :label="true">Anytime</el-radio-button>
                        <el-radio-button :label="false">Set Time Range</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item class="no-border"
                              :error="errors.first('prevent_enrollment_for_contacts_with_inbound_communications')">
                    <el-checkbox name="process-holidays"
                                 class="w-full checkbox-label-description mt-2"
                                 v-model="value.prevent_enrollment_for_contacts_with_inbound_communications"
                                 border>
                        <label class="mb-1 break-word">Prevent Enrollment If Contact is Communicating (or has Already Communicated) with an Agent</label>
                        <small>
                            When enabled, if a contact decides to text or call your lines immediately after submitting a form etc., they won’t be enrolled into this sequence.
                        </small>
                    </el-checkbox>
                </el-form-item>

                <el-form-item
                    class="no-border"
                    v-if="!value.always_open">
                    <div class="form-label">
                        <h5>Specify time</h5>
                        <small>
                            Choose the start and end time for this sequence to process.
                        </small>
                    </div>

                    <div class="row mb-4">
                        <div class="col-6">
                            <el-form-item class="no-border"
                                          :error="errors.first('converted_open')">
                                <strong>Start time</strong>
                                <el-time-select
                                    placeholder="Start time"
                                    class="input-block"
                                    v-model="value.converted_open"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '00:30',
                                      end: '24:00',
                                    }"
                                    :clearable="false">
                                </el-time-select>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item class="no-border"
                                          :error="errors.first('converted_close')">
                                <strong>End time</strong>
                                <el-time-select
                                    placeholder="End time"
                                    class="input-block"
                                    v-model="value.converted_close"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '00:30',
                                      end: '24:00',
                                      minTime: value.converted_open
                                    }"
                                    :clearable="false">
                                </el-time-select>
                            </el-form-item>
                        </div>
                    </div>

                    <div>
                        <el-checkbox name="events"
                                     class="w-full checkbox-label-description"
                                     v-model="value.do_not_enroll_after_hours"
                                     border>
                            <label>Do not enroll leads that come in outside the time range.</label>
                            <small>
                                If you want leads to be enrolled anytime, leave this unchecked.
                            </small>
                        </el-checkbox>
                    </div>
                </el-form-item>
            </div>
            <div class="col-6">
                <el-form-item
                    class="no-border"
                    :error="errors.first('campaign_id')">
                    <div class="form-label">
                        <h5>Outbound Line</h5>
                        <small>
                            Selects what line to use to send outbound calls and messages from.
                        </small>
                    </div>
                    <campaign-selector v-model="value.campaign_id"
                                       check_blocked_messaging
                                       is_automated
                                       :show_paused="false"
                                       @change="campaignChange"
                                       @trigger_validation="validateCampaignId">
                    </campaign-selector>
                </el-form-item>

                <el-form-item :error="errors.first('batch_size')">
                    <div class="form-label">
                        <h5>Throttle</h5>
                        <small>
                            Determines how many contacts are processed per minute. For most live cases, <strong>20 per minute</strong> is a good selection. If you need a higher volume, please contact
                            us.
                        </small>
                    </div>
                    <el-select
                        v-model="value.batch_size">
                        <el-option
                            v-for="throttle in throttles"
                            :key="throttle.value"
                            :value="throttle.value"
                            :label="throttle.label">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item class="no-border">
                    <el-checkbox name="events"
                                 class="w-full checkbox-label-description"
                                 v-model="value.disenroll_on_response"
                                 border>
                        <label>Remove lead if they respond.</label>
                        <small>
                            Automatically remove lead when they respond to a message or pick up a call.
                        </small>
                    </el-checkbox>
                </el-form-item>

                <el-form-item class="no-border">
                    <el-checkbox name="events"
                                 class="w-full checkbox-label-description"
                                 v-model="value.disenroll_on_opt_out"
                                 border>
                        <label>Remove lead if they opt-out.</label>
                        <small>
                            Automatically remove lead upon enrollment time if they have chosen opt-out.
                        </small>
                    </el-checkbox>
                </el-form-item>

                <el-form-item class="no-border">
                    <el-checkbox name="events"
                                 class="w-full checkbox-label-description"
                                 v-model="value.disenroll_on_calls"
                                 border>
                        <label>Remove lead if a user manually calls an enrolled lead.</label>
                        <small>
                            Automatically remove lead when an agent/user manually calls the lead.
                        </small>
                    </el-checkbox>
                </el-form-item>

                <el-form-item class="no-border">
                    <el-tooltip class="item"
                                effect="dark"
                                content="To enable this field, you must first disable Use contact's last communication line field"
                                :disabled="!value.use_last_communication_campaign">
                        <el-checkbox name="events"
                                     class="w-full checkbox-label-description"
                                     :disabled="value.use_last_communication_campaign"
                                     v-model="value.use_contact_owner_campaign"
                                     border>
                            <label>Use contact's owner line.</label>
                            <small>
                                Use contact's owner line instead of the selected one (selected is the fallback).
                            </small>
                        </el-checkbox>
                    </el-tooltip>
                </el-form-item>

                <el-form-item class="no-border">
                    <el-tooltip class="item"
                                effect="dark"
                                content="To enable this field, you must first disable Use contact's owner line field"
                                :disabled="!value.use_contact_owner_campaign">
                        <el-checkbox name="events"
                                     class="w-full checkbox-label-description"
                                     :disabled="value.use_contact_owner_campaign"
                                     v-model="value.use_last_communication_campaign"
                                     border>
                            <label>Use contact's last communication line.</label>
                            <small>
                                Use contact's last communication line instead of the selected one (selected is the fallback).
                            </small>
                        </el-checkbox>
                    </el-tooltip>
                </el-form-item>
            </div>
        </div>
        <hr/>
        <div class="d-flex">
            <el-button
                v-if="mode === 'add'"
                @click="$emit('onAddClose')">
                Cancel
            </el-button>
            <div class="ml-auto">
                <el-button
                    v-if="mode === 'edit'"
                    type="success"
                    :loading="saving"
                    @click="update(value)">
                    <strong>Save Settings</strong>
                </el-button>

                <el-button
                    v-if="mode === 'add'"
                    type="success"
                    :loading="saving"
                    @click="createWorkflow(value)">
                    <strong>
                        Save & Continue
                        <i class="fa fa-arrow-right"></i>
                    </strong>
                </el-button>
            </div>
        </div>
    </el-form>
</template>

<script>
import * as Workflow from "../../constants/workflow"
import CampaignSelector from "../campaign-selector"
import ErrorBag from "../../ErrorBag"
import {mapState, mapActions} from "vuex"

export default {
    name: "workflow-settings-manager",
    components: {CampaignSelector},

    props: {
        value: {
            type: Object,
            required: true
        },

        mode: {
            type: String,
            default: 'edit'
        }
    },

    data() {
        return {
            saving: false,
            errors: new ErrorBag({}),
            Workflow
        }
    },

    computed: {
        ...mapState(['workflows']),
        ...mapState('cache', ['current_company']),

        throttles() {
            let throttles = [
                {
                    value: 1,
                    label: '1 per minute'
                },
                {
                    value: 2,
                    label: '2 per minute'
                },
                {
                    value: 5,
                    label: '5 per minute'
                }
            ];

            for (let i = 10; i <= 250; i += 10) {
                throttles.push({
                    value: i,
                    label: i + ' per minute'
                })
            }

            return throttles
        }
    },

    methods: {
        update(workflow) {
            this.saving = true
            axios.put(`/api/v1/automations/workflows/${workflow.id}`, workflow).then(res => {
                this.saving = false
                this.notify(res.data.message, 'success')
                this.errors = new ErrorBag({})
                let updated_workflow = res.data.workflow
                this.updateWorkflow(updated_workflow)
                this.$emit('input', updated_workflow)
                this.$emit('saved')
            }).catch(err => {
                if (err.response.status === 422) {
                    this.errors = new ErrorBag(err.response.data.errors)
                }
                this.saving = false
                this.$root.handleErrors(err.response)
            })
        },

        createWorkflow(workflow) {
            this.saving = true

            axios.post(`/api/v1/automations/workflows`, workflow).then(res => {
                this.saving = false
                this.$router.push({
                    name: 'Sequence Manager',
                    params: {
                        workflow_id: res.data.workflow.id
                    },
                    query: {
                        tab: 'steps'
                    }
                })

                this.$emit('saved')
                this.notify(res.data.message, 'success')
            }).catch(err => {
                this.saving = false
                if (err.response.status === 422) {
                    this.errors = new ErrorBag(err.response.data.errors)
                    return
                }
                this.$root.handleErrors(err.response)
            })
        },

        campaignChange(campaign_id) {
            this.value.campaign_id = campaign_id
        },

        notify(message, type) {
            this.$notify({
                offset: 95,
                title: 'Sequences',
                message: message,
                type: type,
                duration: 6000,
            });
        },

        validateCampaignId() {
            let errors = {
                campaign_id: ["Please provide a valid outbound line for this sequence."]
            }

            this.errors = new ErrorBag(errors)
        },

        ...mapActions(['updateWorkflow'])
    }
}
</script>
