<template>
    <div v-if="checkPermission">
        <!--send a new sms-->
        <el-dialog :title="dialogTitle"
                   class="dialog-padding auto"
                   :width="isLargeEnough ? '500px' : '100%'"
                   :visible.sync="hide_dialog"
                   :before-close="beforeCloseModal"
                   append-to-body>
            <el-form ref="script_form"
                     class="p-0"
                     label-position="top"
                     :rules="rules_script"
                     :model="script"
                     :submit.prevent.native="doOperation">
                <el-form-item prop="title"
                              label="Title">
                    <el-input v-model="script.title"
                              @input="preValidateForm('script_form')">
                    </el-input>
                </el-form-item>
                <el-form-item prop="text"
                              label="Text Body">
                    <vue-editor v-model="script.text"
                                :editorToolbar="customToolbar"
                                @text-change="preValidateForm('script_form')">
                    </vue-editor>
                </el-form-item>
            </el-form>

            <div class="row pb-3">
                <div class="col-8">
                    <variable-dialog :in_input_group="false"
                                     :has_agent="true">
                    </variable-dialog>
                </div>
                <div class="col-4">
                    <el-button type="success"
                               :disabled="loading_btn || !validated"
                               class="pull-right ml-2"
                               @click="doOperation">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="material-icons"
                           v-show="!loading_btn">
                            save
                        </i>
                        <span>Save</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <slot name="trigger">
            <button slot="reference"
                    :class="triggerButtonClass"
                    @click.prevent="hide_dialog = true">
                <i :class="triggerButtonIcon"></i>
                {{ triggerButtonTitle }}
            </button>
        </slot>
    </div>
</template>

<script>
import auth from '../auth'
import {
    acl_mixin,
    form_validation_mixin
} from '../mixins'
import {mapActions, mapState} from 'vuex'
import VariableDialog from './variable-dialog'
import {VueEditor} from 'vue2-editor'

export default {
    components: {VariableDialog, VueEditor},

    mixins: [
        acl_mixin,
        form_validation_mixin
    ],

    props: {
        script_id: {
            required: false
        },
    },

    data() {
        return {
            auth: auth,
            loading_btn: false,
            hide_dialog: false,
            script: {
                title: null,
                text: null
            },
            rules_script: {
                title: [
                    {
                        required: true,
                        message: 'Please provide a title',
                        trigger: 'blur'
                    },
                ],
                text: [
                    {
                        required: true,
                        message: 'Please provide text body',
                        trigger: 'blur'
                    }
                ],
            },
            customToolbar: [
                ['bold', 'italic', 'underline', 'strike', 'link'],
            ]
        }
    },

    computed: {
        ...mapState(['scripts']),
        ...mapState('cache', ['current_company']),

        checkPermission() {
            if (this.script_id) {
                this.script = _.clone(this.scripts.find((script) => script.id == this.script_id))
                return this.hasPermissionTo('update script')
            }

            return this.hasPermissionTo('create script')
        },

        dialogTitle() {
            if (this.script_id) {
                return 'Update Script'
            }

            return 'Create Script'
        },

        triggerButtonTitle() {
            if (this.script_id) {
                return 'Edit'
            }

            return 'New Script'
        },

        triggerButtonIcon() {
            if (this.script_id) {
                return 'fa fa-edit'
            }

            return 'fa fa-plus'
        },

        triggerButtonClass() {
            if (this.script_id) {
                return 'btn btn-sm btn-block light-blue-500'
            }

            return 'btn btn-sm btn-block success'
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        }
    },

    methods: {
        doOperation() {
            if (this.script_id) {
                return this.updateRemoteScript()
            }

            return this.createRemoteScript()
        },

        createRemoteScript() {
            if (this.validateForm('script_form')) {
                this.loading_btn = true
                const script = {
                    title: this.script.title,
                    text: this.script.text,
                }
                axios.post('/api/v1/script', script).then(res => {
                    this.loading_btn = false
                    this.hide_dialog = false
                    this.newScript(res.data)
                    this.$emit('success', res.data)
                    this.resetScript()
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_btn = false
                })
            }
        },

        updateRemoteScript() {
            if (this.validateForm('script_form')) {
                this.loading_btn = true
                const script = {
                    title: this.script.title,
                    text: this.script.text,
                }
                axios.patch('/api/v1/script/' + this.script.id, script).then(res => {
                    this.loading_btn = false
                    this.hide_dialog = false
                    this.updateScript(res.data)
                    this.$emit('success', res.data)
                    this.resetScript()
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_btn = false
                })
            }
        },

        resetScript() {
            this.script = {
                title: null,
                text: null
            }
        },

        beforeCloseModal(done) {
            if (this.script.title || this.script.text) {
                this.$confirm('Are you sure you want to leave?', 'Warning', {
                    confirmButtonText: 'Yes, Leave',
                    cancelButtonText: 'No, Stay',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(res => {
                    this.resetScript()
                    done()
                }).catch(() => {

                })
            } else {
                this.resetScript()
                done()
            }
        },

        ...mapActions(['newScript', 'updateScript'])
    },

    watch: {
        hide_dialog() {
            if (this.hide_dialog) {
                this.$emit('hide')
            } else {
                this.$emit('show')
            }
        }
    }
}
</script>
