<template>
    <div :class="!show_tags ? 'h-full' : ''"
         data-testid="communication-tags-wrapper">
        <div class="row"
             :class="!show_tags ? 'h-full' : ''"
             v-if="hasPermissionTo('tag communication')">
            <div class="col-12">
                <el-popover
                        ref="popover_tags"
                        title="Tags:"
                        :placement="small ? 'top' : 'left'"
                        :width="265"
                        v-model="is_open"
                        data-testid="communication-tags-popover"
                        trigge="click">
                    <slot name="trigger"
                          slot="reference">
                        <el-button type="text text-dark-greenish text-sm p-0"
                                   data-testid="add-tags-btn">
                            <i class="el-icon-circle-plus-outline"></i>
                            Add Tags
                        </el-button>
                    </slot>
                    <tag-creator ref="addTag"
                                 :category_prop="category"
                                 data-testid="tag-creator"
                                 @success="addTagToCommunication"
                                 @show="showAdd"
                                 @hide="hideAdd">
                    </tag-creator>
                    <div class="row"
                         v-if="hasPermissionTo(['list tag', 'view tag']) && is_open"
                         v-show="!hide_add">
                        <div class="col-12">
                            <tag-selector v-model="communication.tag_ids"
                                          :category_prop="category"
                                          :multiple="true"
                                          data-testid="tag-selector"
                                          @change="changeTags($event, communication)">
                            </tag-selector>
                        </div>
                    </div>
                </el-popover>
            </div>
        </div>
        <div class="row"
             data-testid="communication-tags"
             v-if="communication.tags && communication.tags.length > 0 && show_tags"
             v-loading="loading_tag">
            <div class="col-12">
                <div class="width-300"
                     v-for="tag in communication.tags">
                    <i class="material-icons"
                       :style="{ color: tag.color }">label</i>
                    <span class="ml-1 text-grey-900">{{ tag.name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin} from '../mixins'
    import {mapState} from 'vuex'
    import TagCreator from './tag-creator'
    import * as TagCategory from '../constants/tag-categories'

    export default {
        mixins: [acl_mixin],

        components: {TagCreator},

        props: {
            communication: {
                required: true
            },

            show_tags: {
                default: true,
                type: Boolean,
                required: false
            },

            small: {
                default: false,
                type: Boolean,
                required: false
            },
        },

        data() {
            return {
                auth: auth,
                loading_tag: false,
                hide_add: false,
                is_open: false,
                category: TagCategory.CAT_COMMUNICATIONS,
            }
        },

        computed: {
            ...mapState('cache', ['current_company'])
        },

        created() {
            if (this.communication && !this.communication.tag_ids) {
                if (this.communication.tags) {
                    this.communication.tag_ids = this.communication.tags.map((o) => o.id)
                }
            }
        },

        methods: {
            showAdd() {
                this.hide_add = false
            },

            hideAdd() {
                this.hide_add = true
            },

            saveTags() {
                this.loading_tag = true
                axios.post('/api/v1/communication/' + this.communication.id + '/tag', {
                    tags: this.communication.tag_ids
                }).then(res => {
                    this.communication.tags = res.data
                    this.communication.tag_ids = this.communication.tags.map((o) => o.id)
                    this.loading_tag = false
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_tag = false
                    if (this.communication.tags) {
                        this.communication.tag_ids = this.communication.tags.map((o) => o.id)
                    }
                })
            },

            addTagToCommunication(tag) {
                if (this.hasPermissionTo('tag communication')) {
                    this.communication.tags.push(tag)
                    this.communication.tag_ids = this.communication.tags.map((o) => o.id)
                    this.saveTags()
                }
            },

            changeTags(event, model) {
                if (this.hasPermissionTo('tag communication')) {
                    model.tag_ids = event
                    this.saveTags()
                }
            }
        }
    }
</script>
