var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["summary-card-" + _vm.status] + " h-100" }, [
    _c(
      "a",
      {
        class: { "cursor-initial": !_vm.is_whole_click },
        attrs: { href: "#" },
      },
      [
        _c(
          "div",
          {
            staticClass: "card summary-card-hover",
            class: [
              _vm.is_transcription ? "" : "h-100",
              {
                "border-0 shadow-none pb-3 bg-transparent d-flex justify-content-center":
                  _vm.no_borders,
              },
            ],
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  class: [
                    _vm.hasExtraAggregatesData ? "col-10" : "col-12",
                    _vm.chart_type != null ? "pb-3" : "",
                  ],
                },
                [
                  _vm.img_src || _vm.icon || _vm.title
                    ? _c("div", { staticClass: "col-12 p-1" }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-inline-block pt-3 align-bottom",
                            class: [_vm.no_borders ? "pl-0" : "pl-2"],
                          },
                          [
                            _vm.img_src
                              ? _c("img", {
                                  staticClass: "w-24",
                                  attrs: {
                                    "data-testid": "summary-report-card-img",
                                    src: _vm.img_src,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.icon
                              ? _c(
                                  "i",
                                  {
                                    staticClass: "material-icons",
                                    staticStyle: { color: "#03c04c" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.icon) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.title
                          ? _c("div", { staticClass: "d-inline-block pl-2" }, [
                              _c("strong", { staticClass: "text-md title" }, [
                                _vm._v(_vm._s(_vm.title)),
                              ]),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm.chart_type != null
                      ? _c(
                          "div",
                          {
                            class: _vm.chartContainerClass,
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeCardStatus()
                              },
                            },
                          },
                          [
                            _c("highcharts", {
                              ref: "highchart",
                              staticStyle: { height: "150px" },
                              attrs: {
                                options: _vm.graphOptions,
                                "data-testid": "summary-report-card-graph",
                                id: _vm.graph_id,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.hide_labels
                      ? _c(
                          "div",
                          {
                            class:
                              (_vm.chart_type != null
                                ? "pl-0"
                                : "text-center mt-3") + " col",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                class: {
                                  "summary-numbers-v-align-30":
                                    _vm.chart_type != null &&
                                    _vm.summary.data_length <= 2,
                                },
                              },
                              [
                                _vm._l(
                                  _vm.summary.data,
                                  function (item, index) {
                                    return [
                                      typeof item.show_data === "undefined" ||
                                      (typeof item.show_data !== "undefined" &&
                                        item.show_data)
                                        ? _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "mt-3 countup-hover",
                                              class: [
                                                _vm.summary.data_length == 1
                                                  ? "col-12"
                                                  : _vm.dataColumnClass,
                                                !item.clickable
                                                  ? "unclickable"
                                                  : "",
                                              ],
                                              attrs: {
                                                "data-testid":
                                                  "summary-report-card-countup-template",
                                              },
                                              on: {
                                                mouseover: function ($event) {
                                                  return _vm.mouseHoverEvent(
                                                    "over",
                                                    index
                                                  )
                                                },
                                                mouseleave: function ($event) {
                                                  return _vm.mouseHoverEvent(
                                                    "leave",
                                                    index
                                                  )
                                                },
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.changeCardStatus(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "m-a-0 mb-2 text-lg metric-value",
                                                },
                                                [
                                                  typeof item.count !==
                                                    "undefined" &&
                                                  item.count !== null
                                                    ? _c("ICountUp", {
                                                        ref: "countup-" + index,
                                                        refInFor: true,
                                                        attrs: {
                                                          duration:
                                                            _vm.count_up
                                                              .duration,
                                                          endVal:
                                                            _vm.chart_type ==
                                                              "pie" &&
                                                            !_vm.is_donut
                                                              ? parseFloat(
                                                                  item.count
                                                                )
                                                              : parseInt(
                                                                  item.count
                                                                ),
                                                          options:
                                                            _vm.countUpOptions(
                                                              index
                                                            ),
                                                          "data-testid":
                                                            "summary-report-card-countup",
                                                          startVal:
                                                            _vm.count_up
                                                              .startVal,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  item.count == null
                                                    ? _c("span", [_vm._v("-")])
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "text-muted text-sm metric-label",
                                                  class: [
                                                    _vm.is_transcription
                                                      ? "mb-2"
                                                      : "",
                                                  ],
                                                },
                                                [_vm._v(_vm._s(item.label))]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.hasExtraAggregatesData
                ? _c(
                    "div",
                    { class: _vm.classesFormultipleExtraData },
                    _vm._l(_vm.extra_aggregates, function (extra_aggregate) {
                      return _c(
                        "div",
                        { key: extra_aggregate.title, staticClass: "row" },
                        [
                          extra_aggregate.type === "SMS"
                            ? _c("summary-report-sms", {
                                attrs: {
                                  "data-testid": "summary-report-sms-component",
                                  title: extra_aggregate.title,
                                  data: extra_aggregate.data,
                                  img_src: extra_aggregate.img_src,
                                  icons: extra_aggregate.icons,
                                  is_donut: false,
                                  chart_type: null,
                                  loading: _vm.loading,
                                  card_index: 0,
                                  campaign_id: _vm.campaign_id,
                                  workflow_id: _vm.workflow_id,
                                  ring_group_id: _vm.ring_group_id,
                                  user_id: _vm.user_id,
                                },
                              })
                            : _c("summary-report-unreturned", {
                                attrs: {
                                  "data-testid": "summary-report-sms-component",
                                  title: extra_aggregate.title,
                                  data: extra_aggregate.data,
                                  img_src: extra_aggregate.img_src,
                                  icons: extra_aggregate.icons,
                                  is_donut: false,
                                  chart_type: null,
                                  loading: _vm.loading,
                                  card_index: 0,
                                  campaign_id: _vm.campaign_id,
                                  workflow_id: _vm.workflow_id,
                                  ring_group_id: _vm.ring_group_id,
                                  user_id: _vm.user_id,
                                },
                              }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }