var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "el-dialog-compliance-wizard",
        width: "60vw",
        top: "4vh",
        "append-to-body": "",
        "custom-class": "dialog-no-border",
        visible: _vm.compliance_wizard_dialog_visible,
        "before-close": () => _vm.closeComplianceWizard(),
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.compliance_wizard_dialog_visible = $event
        },
        open: _vm.validateCurrentStepForm,
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("h5", { staticClass: "mb-0" }, [
          _c("i", { staticClass: "fas fa-hat-wizard" }),
          _vm._v(
            "\n            " + _vm._s(_vm.compliaceWizardTitle) + "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-steps",
        {
          staticClass: "mb-3",
          attrs: {
            active: _vm.current_step,
            "finish-status": "success",
            "align-center": "",
          },
        },
        [
          _c("el-step", { attrs: { title: "Messaging Service Registration" } }),
          _vm._v(" "),
          _c("el-step", { attrs: { title: "A2P Use Case Registration" } }),
          _vm._v(" "),
          _c("el-step", { attrs: { title: "Finish" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("steps", {
        attrs: {
          "current-step": _vm.current_step,
          "alert-message": _vm.alertMessage,
          selected_lines: _vm.selected_lines,
          selected_messaging_service: _vm.messaging_service,
        },
        on: { alert: _vm.updateAlert, next: _vm.goNext },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }