<template>
    <div class="dropdown-menu pull-right w-xl text-color box-shadow-z2 dropdown-menu-right dropdown-menu-overlay dropdown-menu-scale p-3 allow-select">
        <span class="text-md" 
              v-if="serviceCode" 
              @click="noClose($event)">
            Service code:
            <b>{{ serviceCode }}</b>
            <br />
        </span>

        <span class="text-md" @click="noClose($event)">
            Role:
            <b>{{ roleName }}</b>
            <br />
        </span>

        <template v-if="showSalesRepInfo">
            <hr />

            <span class="text-md" @click="noClose($event)">
                Your Sales Rep is:
                <b>{{ salesRepInfo.name }}</b>
                <br />
            </span>

            <span class="text-md" 
                  v-if="salesRepInfo.email" 
                  @click="noClose($event)">
                <span>Email:</span>
                <a :href="'mailto:' + salesRepInfo.email">
                    <b>{{ salesRepInfo.email }}</b>
                </a>
                <br />
            </span>

            <span class="text-md"
                  v-if="salesRepInfo.phone"
                  @click="noClose($event)">
                Phone:
                <b>{{ salesRepInfo.phone }}</b>
                <br />
            </span>
        </template>

        <template v-if="showCsmInfo">
            <hr />

            <span class="text-md" @click="noClose($event)">
                Your CSM is:
                <b>{{ csmInfo.name }}</b>
                <br />
            </span>

            <span class="text-md" 
                  v-if="csmInfo.email" 
                  @click="noClose($event)" >
                <span>Email:</span>
                <a :href="'mailto:' + csmInfo.email">
                    <b>{{ csmInfo.email }}</b>
                </a>
                <br />
            </span>

            <span class="text-md"
                  v-if="csmInfo.phone"
                  @click="noClose($event)" >
                Phone:
                <b>{{ csmInfo.phone }}</b>
                <br />
            </span>
        </template>

        <slot name="customLegend">
        </slot>

        <hr v-if="showDivider" />

        <a class="btn btn-block btn-success"
           href="https://aloware.com/setup-guide"
           target="_blank"
           v-if="showSetupGuide"
           >
            Setup Guide
        </a>

        <button class="btn btn-block btn-success"
                v-if="showRequestSolutionEngineerButton" 
                @click="handleRequestSolutionEngineer" >
            Request a Solution Engineer
        </button>

        <button class="btn btn-block btn-success" 
                v-if="showSalesRepInfo" 
                :disabled="!salesRepInfo.calendar_link"
                @click="handleRequestSalesCall" >
            Request a Sales Rep call
        </button>

        <button class="btn btn-block btn-success" 
                v-if="showCsmInfo" 
                :disabled="!csmInfo.calendar_link"
                @click="handleRequestCSMCall" >
            Request a CSM Call
        </button>
        
        <a class="btn btn-block btn-success"
           target="_blank"
           :href="FORM_URL" 
           v-if="showOrderTrainingButton" >
            Order Training
            <span class="text-xs">(paid)</span>
        </a>

        <hr v-if="debuggingInfo.env !== 'production'" />
        <p class="mb-0 mt-2 text-xs" v-if="debuggingInfo.env !== 'production'">
            Environment:
            <span class="_600 text-break">{{ debuggingInfo.env }}</span>
            <br />
            Branch:
            <span class="_600 text-break">{{ debuggingInfo.branch }}</span>
            <br />
            Fake Number Purchase:
            <span class="_600 text-break">
                {{ debuggingInfo.fake_number_purchase | fixBooleanType }}
            </span>
            <br />
            Fake Power Dialer Calls:
            <span class="_600 text-break">
                {{ debuggingInfo.fake_power_dialer_calls | fixBooleanType }}
            </span>
            <br />
            Fake Text Messages:
            <span class="_600 text-break">
                {{ debuggingInfo.fake_text_messages | fixBooleanType }}
            </span>
            <br />
            Fake Toll-Free Verification:
            <span class="_600 text-break">
                {{ debuggingInfo.fake_toll_free_verification | fixBooleanType }}
            </span>
            <br />
            Mock A2P Brands:
            <span class="_600 text-break">
                {{ debuggingInfo.mock_a2p_brand_registration | fixBooleanType }}
            </span>
            <br />
            Mock A2P Campaigns:
            <span class="_600 text-break">
                {{
                    debuggingInfo.mock_a2p_campaign_registration
                        | fixBooleanType
                }}
            </span>
        </p>
    </div>
</template>
<script>
    import { ORDER_TRAINING_FORM_URL } from "../../../constants";

    export default {
        name: 'HelpDropdown',

        props: {
            csmInfo: {
                type: Object,
                default: () => {},
            },
            
            debuggingInfo: {
                type: Object,
                default: () => {},
            },

            showRequestSolutionEngineerButton:{
                type: Boolean,
                default: false,
            },
            
            roleName: {
                type: String,
                default: '',
            },
            
            salesRepInfo: {
                type: Object,
                default: () => {},
            },
            
            serviceCode: {
                type: String,
                default: '',
            },
            
            showCsmInfo: {
                type: Boolean,
                default: false,
            },

            showOrderTrainingButton: {
                type: Boolean,
                default: false,
            },

            showSalesRepInfo: {
                type: Boolean,
                default: false,
            },
            
            showSetupGuide: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                FORM_URL: ORDER_TRAINING_FORM_URL,
            };
        },

        computed: {
            showDivider(){
                return this.showSetupGuide || 
                    this.showRequestSolutionEngineerButton || 
                    this.showSalesRepInfo || 
                    this.showCsmInfo || 
                    this.showOrderTrainingButton
            }
        },
 
        methods: {
            noClose(event) {
                if (event) {
                    event.stopPropagation();
                }
            },

            handleRequestSalesCall() { 
                const { calendar_link } = this.salesRepInfo;
                window.open(calendar_link, '_blank');
            },
            
            handleRequestSolutionEngineer() {
                window.Intercom && window.Intercom('show')
            },
            
            handleRequestCSMCall() {
                const { calendar_link } = this.csmInfo;
                window.open(calendar_link, '_blank');
            }
        },
    };
</script>
