var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body", attrs: { id: "view" } }, [
    _vm.team?.id
      ? _c(
          "div",
          { staticClass: "teams p-3" },
          [
            _c("div", { staticClass: "mb-3" }, [
              _c("h5", { staticClass: "pt-2" }, [
                _vm._v("\n                Users\n            "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between pt-1",
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center w-50" },
                    [
                      _c("el-input", {
                        staticClass: "w-50 mr-3",
                        attrs: {
                          placeholder: "Search Users",
                          "prefix-icon": "el-icon-search",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchUsers,
                          callback: function ($$v) {
                            _vm.searchUsers = $$v
                          },
                          expression: "searchUsers",
                        },
                      }),
                      _vm._v(" "),
                      _vm.selectedUsers.length && !_vm.isDeleteUsersModalVisible
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "span",
                                { staticClass: "font-weight-600 mr-2" },
                                [_vm._v(_vm._s(_vm.selectedUsersLabel))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                { attrs: { trigger: "click" } },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      icon: "el-icon-more",
                                      size: "mini",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c("el-dropdown-item", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "el-dropdown-menu__item--delete",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemoveUsersModal()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Remove users from " +
                                                _vm._s(_vm.team.name) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex w-50 justify-content-end" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "w-25",
                          attrs: {
                            slot: "reference",
                            type: "success",
                            "data-testid": "team-creator-open-modal-button",
                          },
                          on: { click: _vm.openAddUsersModal },
                          slot: "reference",
                        },
                        [
                          _vm._v(
                            "\n                        Add Users\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                ref: "company-users-team-list",
                staticClass: "w-full mb-3",
                attrs: {
                  "max-height": "350",
                  stripe: "",
                  data: _vm.filteredUsers,
                },
                on: { "selection-change": _vm.handleSelectedUsersChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "selection",
                    width: "55",
                    "show-header": false,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "full_name", label: "Name" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                    " +
                                _vm._s(scope.row?.full_name || "-") +
                                "\n                "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1590200516
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { align: "center", label: "Operations", width: "180" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "User Activity",
                                              params: { user_id: scope.row.id },
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-block purplish",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-signal pull-left",
                                              }),
                                              _vm._v(
                                                "\n                                        Activity\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-2" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-block danger",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemoveUsersModal(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-trash pull-left",
                                        }),
                                        _vm._v(
                                          "\n                                    Delete\n                                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3862559256
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("h5", { staticClass: "pt-3" }, [
                _vm._v(
                  "\n                Associated Ring Groups\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between pt-1",
                },
                [
                  _c("el-input", {
                    staticClass: "w-50",
                    attrs: {
                      placeholder: "Search Ring Groups",
                      "prefix-icon": "el-icon-search",
                      clearable: "",
                    },
                    model: {
                      value: _vm.searchRingGroups,
                      callback: function ($$v) {
                        _vm.searchRingGroups = $$v
                      },
                      expression: "searchRingGroups",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                ref: "company-ring-group-team-list",
                staticClass: "w-full",
                attrs: {
                  "max-height": "350",
                  stripe: "",
                  data: _vm.filteredRingGroups,
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "name", label: "Name" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                    " +
                                _vm._s(scope.row?.name || "-") +
                                "\n                "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2102841672
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  width: "30%",
                  center: "",
                  visible: _vm.isDeleteUsersModalVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.isDeleteUsersModalVisible = $event
                  },
                  close: _vm.closeDeleteUsersModal,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "dialog--title mt-4",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.deleteUsersModalConfig.title) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass: "break-word text-center",
                  domProps: {
                    innerHTML: _vm._s(_vm.deleteUsersModalConfig.description),
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "break-word text-center" }, [
                  _vm._v("Are you sure?"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center mt-5 row" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "mb-2",
                        attrs: { slot: "reference", type: "default" },
                        on: { click: _vm.closeDeleteUsersModal },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          "\n                    No, Keep User\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mb-2",
                        attrs: {
                          slot: "reference",
                          type: "danger",
                          "data-testid": "team-delete-users-button",
                          disabled: _vm.isLoading,
                        },
                        on: { click: _vm.onDeleteUsers },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.deleteUsersModalConfig.buttonText) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  width: "30%",
                  center: "",
                  visible: _vm.isAddUsersModalVisible,
                  loading: _vm.isLoading,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.isAddUsersModalVisible = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "dialog--title mt-4",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.addUsersModalConfig.title) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    attrs: {
                      multiple: "",
                      filterable: "",
                      remote: "",
                      placeholder: "Search Users",
                      "collapse-tags": "",
                      "data-testid": "team-add-users-select",
                    },
                    model: {
                      value: _vm.selectedUsersToAdd,
                      callback: function ($$v) {
                        _vm.selectedUsersToAdd = $$v
                      },
                      expression: "selectedUsersToAdd",
                    },
                  },
                  _vm._l(_vm.filteredUsersToAdd, function (user) {
                    return _c("el-option", {
                      key: user.id,
                      attrs: {
                        label: user.full_name + " - " + user.email,
                        value: user.id,
                      },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center mt-5 row" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "mb-2",
                        attrs: { slot: "reference", type: "default" },
                        on: { click: _vm.closeAddUsersModal },
                        slot: "reference",
                      },
                      [_vm._v("\n                    Cancel\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mb-2",
                        attrs: {
                          slot: "reference",
                          type: "success",
                          "data-testid": "team-add-users-button",
                          disabled: _vm.isLoading,
                        },
                        on: { click: _vm.onAddUsers },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          "\n                    Add Users\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "ring-group--dialog",
                attrs: {
                  width: "40%",
                  center: "",
                  visible: _vm.isAddRingGroupsModalVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.isAddRingGroupsModalVisible = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "dialog--title mt-4",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.addRingGroupsModalConfig.title) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ring-group mt-5" },
                  [
                    _vm._l(_vm.filteredRingGroupsToAdd, function (ringGroup) {
                      return _c(
                        "div",
                        {
                          key: ringGroup.id,
                          staticClass:
                            "d-flex align-items-center justify-content-between ring-group--divider",
                        },
                        [
                          _c("div", { staticClass: "ring-group--title" }, [
                            _vm._v(_vm._s(ringGroup.name)),
                          ]),
                          _vm._v(" "),
                          !_vm.team.ring_groups.find(
                            (rg) => rg.id === ringGroup.id
                          )
                            ? _c("el-button", {
                                attrs: {
                                  type: "success",
                                  icon: "el-icon-check",
                                  disabled: _vm.isLoading,
                                  loading: _vm.isLoading,
                                  "data-testid": "team-add-ring-groups-button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onAddRingGroup(ringGroup)
                                  },
                                },
                              })
                            : _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-close",
                                  disabled: _vm.isLoading,
                                  loading: _vm.isLoading,
                                  "data-testid":
                                    "team-remove-ring-groups-button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onRemoveRingGroup(ringGroup)
                                  },
                                },
                              }),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.isLoading && !_vm.ringGroupsToAdd.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ring-group d-flex align-items-center justify-content-center",
                          },
                          [_c("el-spinner")],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  width: "30%",
                  center: "",
                  visible: _vm.isRemoveRingGroupModalVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.isRemoveRingGroupModalVisible = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "dialog--title mt-4",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.removeRingGroupModalConfig.title) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass: "break-word text-center",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.removeRingGroupModalConfig.description
                    ),
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "break-word text-center" }, [
                  _vm._v("Are you sure?"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center mt-5 row" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "mb-2",
                        attrs: { slot: "reference", type: "default" },
                        on: { click: _vm.closeRemoveRingGroupModal },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          "\n                    No, Keep Ring Group\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mb-2",
                        attrs: {
                          slot: "reference",
                          type: "danger",
                          "data-testid": "team-remove-ring-group-button",
                          disabled: _vm.isLoading,
                        },
                        on: { click: _vm.onRemoveRingGroups },
                        slot: "reference",
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.removeRingGroupModalConfig.buttonText) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }