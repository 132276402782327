<template>
    <!-- This is a current workaround for the Pipedrive-integration auth.js issue-->
    <el-select v-model="workflow_id"
               class="w-full"
               :loading="loading"
               remote
               placeholder="Choose a sequence"
               filterable
               clearable>
        <el-option-group key="Active Sequences"
                         label="Active Sequences"
                         v-if="activeWorkflowsAlphabeticalOrder && activeWorkflowsAlphabeticalOrder.length > 0">
            <el-option
                v-for="workflow in activeWorkflowsAlphabeticalOrder"
                :key="workflow.id"
                :label="workflow.name"
                :value="workflow.id">
            </el-option>
        </el-option-group>
        <el-option-group key="Paused Sequences"
                         label="Paused Sequences"
                         v-if="pausedWorkflowsAlphabeticalOrder && pausedWorkflowsAlphabeticalOrder.length > 0">
            <el-option
                v-for="workflow in pausedWorkflowsAlphabeticalOrder"
                :key="workflow.id"
                :label="workflow.name"
                :value="workflow.id">
            </el-option>
        </el-option-group>
    </el-select>
</template>

<script>
import {mapState} from 'vuex'
import {acl_mixin} from '../../../mixins'

export default {
    name: 'pipedrive-app-extension-workflow-selector',
    mixins: [acl_mixin],

    props: {
        value: {
            required: false
        },
        exclude: {
            required: false
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: () => ({
        workflow_id: null
    }),

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            workflows: state => state.workflows
        }),

        availableWorkflows() {
            if (this.workflows) {
                return this.workflows.filter((workflow) => {
                    return workflow.id !== this.exclude
                })
            }

            return []
        },

        workflowsAlphabeticalOrder() {
            if (this.availableWorkflows) {
                let workflows = _.clone(this.availableWorkflows)
                return workflows.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },

        activeWorkflowsAlphabeticalOrder() {
            if (this.workflowsAlphabeticalOrder.length) {
                let workflows = _.clone(this.workflowsAlphabeticalOrder)
                return workflows.filter(workflow => workflow.active === true)
            }

            return []
        },

        pausedWorkflowsAlphabeticalOrder() {
            if (this.workflowsAlphabeticalOrder.length) {
                let workflows = _.clone(this.workflowsAlphabeticalOrder)
                return workflows.filter(workflow => workflow.active === false)
            }

            return []
        }
    },

    watch: {
        value() {
            this.workflow_id = this.value
        },

        workflow_id(val) {
            if (this.value !== undefined && this.workflow_id !== this.value) {
                this.$emit('change', val)

                // since we are utilizing the v-model two way binding in this component
                // we should try to consider updating/syncing with the parent's data variable whenever
                // data changed in this component
                this.$emit('input', val)
            }
        }
    }
}
</script>
