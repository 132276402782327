var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isSimpSocial
    ? _c("el-alert", { attrs: { type: "warning", closable: false } }, [
        _c("div", { staticClass: "upgrade-now no-select" }, [
          _c(
            "p",
            { staticClass: "info-text" },
            [
              _c("span", [_vm._v(_vm._s(_vm.text))]),
              _vm._v(" "),
              _vm.kbLink
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "large", type: "text" },
                      on: { click: _vm.openKnowledgeBaseLink },
                    },
                    [_vm._v("\n                Learn More\n            ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-row mb-3" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "large",
                    disabled: _vm.disabled,
                    round: "",
                  },
                  on: { click: _vm.checkClick },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.buttonText) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }