var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _vm._v(
        "\n    Please register your business for carrier compliance.\n    "
      ),
      _c("div", [
        _c(
          "button",
          {
            staticClass:
              "btn rounded greenish btn-register btn-sm mt-2 pull-right",
          },
          [_vm._v("\n            Register\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn rounded bluish btn-remind-later btn-sm mt-2 pull-left",
          },
          [_vm._v("\n            Remind me later\n        ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }