<template>
    <div v-loading="loading">
        <strong class="text-md">Your account API token is: </strong>
        <div class="input-group width-300">
            <el-popover v-if="!allowedToEnableIntegrationsPage()"
                        ref="api-popover"
                        placement="top"
                        width="200"
                        class="custom-popover"
                        popper-class="btn-primary"
                        trigger="hover">
                <custom-message-display :config="customMessage('integrations.api')" />
            </el-popover>
            <input v-if="!isTrialKYC"
                   class="form-control text-md"
                   disabled
                   :value="current_company.form_capture_token"
                   @click="copyApiToken"
                   ref="api_token"
                   :type="companyTokenElementType"
                   style="resize: none; background-color: #eff0f1;">
            <input v-if="isTrialKYC"
                   disabled
                   class="form-control text-md"
                   :type="companyTokenElementType"
                   ref="api_token"
                   @click="copyApiToken"
                   style="resize: none; background-color: #eff0f1;">
            <span class="input-group-append">
                <button class="btn"
                        :disabled="isTrialKYC"
                        type="button"
                        @click="toggleCompanyTokenVisibility">
                    <i class="fa fa-eye-slash"></i>
                </button>
                <button class="btn greenish"
                        v-popover:api-popover
                        :disabled="isTrialKYC"
                        type="button"
                        @click="copyApiToken">
                    Copy
                </button>
            </span>
        </div>
        <div v-if="!isTrialKYC"
             class="mt-2">
            <el-button @click="resetToken">
                Generate new token
            </el-button>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex"
import {kyc_mixin, company_token_mixin} from '../../mixins'
import CustomMessageDisplay from '../kyc/custom-message-display'

export default {
    name: "company-form-token",

    components: {
        CustomMessageDisplay
    },

    computed: {
        companyTokenElementType() {
            return this.companyTokenVisibility ? 'text' : 'password'
        },
    },

    mixins: [
        kyc_mixin,
        company_token_mixin
    ],

    data() {
        return {
            loading: false
        }
    },

    methods: {
        ...mapActions('cache', ['setCurrentCompany']),

        copyApiToken() {
            navigator.clipboard.writeText(this.current_company.form_capture_token);
        },

        resetToken() {
            this.$confirm('Are you sure you want to reset your API token? WARNING: the current token will invalidated any application using it. ARE YOU SURE?', 'Form Capture Token Reset', {
                confirmButtonText: 'Yes',
                confirmButtonClass: 'bg-red border-danger',
                cancelButtonText: 'No',
                customClass: 'width-400 fixed form-capture-token-reset-pop-up',
                type: 'warning'
            }).then(() => {
                this.loading = true
                axios.post('/api/v1/company/form-token-reset')
                    .then(res => {
                        this.loading = false
                        this.$notify({
                            offset: 95,
                            title: 'Integrations',
                            message: res.data.message,
                            type: 'success'
                        })
                        // Update current company state
                        let company = this.current_company
                        company.form_capture_token = res.data.form_capture_token
                        this.setCurrentCompany(company)
                    })
                    .catch(err => {
                        this.loading = false
                        this.$root.handleErrors(err.response)
                    })
            })
        }
    }
}
</script>
