var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list broadcast")
    ? _c(
        "div",
        [
          _c(
            "el-select",
            {
              staticClass: "w-full",
              attrs: {
                placeholder: _vm.placeholder,
                multiple: _vm.multiple,
                "multiple-limit": _vm.multiple ? _vm.multipleLimit : 0,
                size: _vm.size,
                filterable: "",
                clearable: "",
                "data-testid": "broadcast-selector",
                "default-first-option": "",
                "collapse-tags": "",
              },
              model: {
                value: _vm.broadcast_id,
                callback: function ($$v) {
                  _vm.broadcast_id = $$v
                },
                expression: "broadcast_id",
              },
            },
            _vm._l(_vm.broadcastsAlphabeticalOrder, function (broadcast) {
              return _c("el-option", {
                key: broadcast.id,
                attrs: { label: broadcast.name, value: broadcast.id },
              })
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }