<template>
    <div v-on-clickaway="closeAllMenus">
        <div class="d-flex">
            <!-- Section filter -->
            <div class="mr-2"
                 v-if="shouldDisplayTranscriptionSections">
                <el-radio-group
                    v-model="section"
                    data-testid="comm-advanced-filters-section-radio"
                    @change="changeSectionFromBtn">
                    <el-radio-button label="Activities"/>
                    <el-radio-button label="Transcriptions"/>
                </el-radio-group>
            </div>
            <div class="mr-2">
                <date-selector :default_date_range="default_date_range"
                               @clearDefaultDateRange="clearDefaultDateRange">
                </date-selector>
            </div>

            <div class="align-self-center mr-2">
                <!-- Textual date filters -->
                <span v-if="filter.from_date"
                      class="call-log-head"
                      v-visible="!loading">
                    From
                    <strong>{{ filter.from_date | fixDateTimeCompanyTimeZone }}</strong>
                    To
                    <strong>{{ filter.to_date | fixDateTimeCompanyTimeZone }}</strong>
                </span>
                <span v-if="!filter.from_date"
                      class="call-log-head"
                      v-visible="!loading">
                    <strong>All Time</strong>
                </span>
            </div>

            <!-- Reset Filters button -->
            <div class="mr-2">
                <button @click="resetOurFilters(false)"
                        class="btn btn-md blackish p-x-md"
                        style="height: 40px"
                        data-testid="comm-advanced-filters-reset-button"
                        v-show="changed">
                    Reset
                </button>
            </div>

            <!-- Filters button -->
            <div class="mr-2 no-select filters-menu"
                 v-if="!only_date_filter">
                <button :class="[changed ? 'deep-orange' : '']"
                        :disabled="loading"
                        data-testid="comm-advanced-filters-button"
                        @click="toggleFiltersMenu"
                        class="btn btn-md white"
                        style="height: 40px"
                        v-if="checkSize()">
                    Filters
                </button>

                <button :class="[changed ? 'deep-orange' : '']"
                        :disabled="loading"
                        data-testid="comm-advanced-i-filters-button"
                        @click="toggleFiltersMenu"
                        class="btn btn-md white"
                        style="height: 40px"
                        v-else>
                    <i class="fa fa-filter"></i>
                </button>

                <el-dialog :before-close="closeFiltersMenu"
                           :visible.sync="filters_menu_visible"
                           :width="isLargeEnough ? '50%' : '100%'"
                           append-to-body
                           title="Filters"
                           data-testid="comm-advanced-filters-dialog"
                           top="10vh">
                    <div class="row nav-padding lean-bottom-margin bold-label right-align">
                        <div class="col-12"
                             v-if="!only_campaign_selector">
                            <span class="filter-header text-muted">Handling</span>
                            <div class="dropdown-divider"></div>
                            <el-form label-position="left"
                                     data-testid="comm-advanced-filters-direction-form"
                                     label-width="100px">
                                <el-form-item label="Direction">
                                    <el-select @change="changeDirection"
                                               class="filter-select"
                                               placeholder="Select Direction"
                                               data-testid="comm-advanced-filters-direction-select"
                                               v-model="direction">
                                        <el-option :key="item.value + '-' + item.label"
                                                   :label="item.label"
                                                   :value="item.value"
                                                   v-for="item in directions">
                                            <span>
                                                <i class="material-icons"
                                                   v-if="item.icon">{{ item.icon }}</i>
                                                {{ item.label }}
                                            </span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <el-form label-position="left"
                                     data-testid="comm-advanced-filters-answer-status-form"
                                     label-width="120px">
                                <el-form-item label="Answer Status">
                                    <el-select @change="changeAnswerStatus"
                                               class="filter-select"
                                               placeholder="Select Answer State"
                                               data-testid="comm-advanced-filters-answer-status-select"
                                               v-model="answer_state">
                                        <el-option :key="item.value + '-' + item.label"
                                                   :label="item.label"
                                                   :value="item.value"
                                                   v-for="item in answer_status">
                                            <span>
                                                <i :class="item.icon_class"
                                                   class="material-icons"
                                                   v-if="item.icon">{{ item.icon }}</i>
                                                {{ item.label }}
                                            </span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <el-form label-position="left"
                                     label-width="100px"
                                     data-testid="comm-advanced-filters-min-talk-time-form"
                                     v-show="!show_custom_min_talk_time">
                                <el-form-item label="Talk Time">
                                    <el-select @change="changeMinTalkTime"
                                               class="filter-select"
                                               placeholder="Select Talk Time"
                                               data-testid="comm-advanced-filters-min-talk-time-select"
                                               v-model="min_talk_time">
                                        <el-option :key="item.value + '-' + item.label"
                                                   :label="item.label"
                                                   :value="item.value"
                                                   v-for="item in min_talk_times">
                                            <span>{{ item.label }}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <div class="row"
                                 v-show="show_custom_min_talk_time">
                                <div class="col-12 el-form--label-left">
                                    <label class="el-form-item__label"
                                           style="width: 250px;">Talk Time (Seconds):</label>
                                    <div class="pull-right">
                                        <el-input-number :min="0"
                                                         size="mini"
                                                         data-testid="comm-advanced-filters-custom-min-talk-time-input"
                                                         v-model="custom_min_talk_time"></el-input-number>
                                        <el-button @click.prevent="changeCustomMinTalkTime"
                                                   class="filter-button"
                                                   size="mini"
                                                   data-testid="comm-advanced-filters-custom-min-talk-time-ok-button"
                                                   type="success">
                                            Ok
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                            <el-form label-position="left"
                                     data-testid="comm-advanced-filters-change-type-form"
                                     label-width="100px">
                                <el-form-item label="Type">
                                    <el-select @change="changeType"
                                               class="filter-select"
                                               placeholder="Select Type"
                                               data-testid="comm-advanced-filters-change-type-select"
                                               v-model="type">
                                        <el-option :key="item.value + '-' + item.label"
                                                   :label="item.label"
                                                   :value="item.value"
                                                   v-for="item in types">
                                            <span>
                                                <i class="material-icons"
                                                   v-if="item.icon">{{ item.icon }}</i>
                                                {{ item.label }}
                                            </span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <el-form label-position="left"
                                     data-testid="comm-advanced-filters-transfer-type-form"
                                     label-width="100px">
                                <el-form-item label="Transfer Type">
                                    <el-select @change="changeTransferType"
                                               class="filter-select"
                                               placeholder="Select Type"
                                               data-testid="comm-advanced-filters-transfer-type-select"
                                               v-model="transfer_type"
                                               clearable>
                                        <el-option :key="item.value + '-' + item.label"
                                                   :label="item.label"
                                                   :value="item.value"
                                                   v-for="item in transfer_types">
                                            <span>
                                                {{ item.label }}
                                            </span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <el-form label-position="left"
                                     data-testid="comm-advanced-filters-callback-status-form"
                                     label-width="100px">
                                <el-form-item label="Callback Status">
                                    <el-select @change="changeCallbackStatus"
                                               class="filter-select"
                                               placeholder="Select Status"
                                               data-testid="comm-advanced-filters-callback-status-select"
                                               v-model="callback_status"
                                               clearable>
                                        <el-option :key="item.value + '-' + item.label"
                                                   :label="item.label"
                                                   :value="item.value"
                                                   v-for="item in callback_statuses">
                                    <span>
                                        {{ item.label }}
                                    </span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <el-form label-position="left"
                                     label-width="130px"
                                     data-testid="comm-advanced-filters-international-form">
                                <el-form-item label="International Only">
                                    <el-switch active-color="#00a344"
                                               data-testid="comm-advanced-filters-international-switch"
                                               :active-value="1"
                                               :inactive-value="0"
                                               v-model="has_international"
                                               @change="changeInternational">
                                    </el-switch>
                                </el-form-item>
                            </el-form>
                            <span class="filter-header text-muted">Properties</span>
                            <div class="dropdown-divider"></div>
                            <el-form label-position="left"
                                     label-width="150px"
                                     data-testid="comm-advanced-filters-tags-form"
                                     v-show="show_tags">
                                <el-form-item label="Tag">
                                    <tag-selector class="filter-select"
                                                  v-model="filter_tags"
                                                  :multiple="true"
                                                  :value="filter_tags"
                                                  :category_prop="TagCategory.CAT_COMMUNICATIONS"
                                                  loaded
                                                  data-testid="comm-advanced-filters-tags-select"
                                                  @change="changeTags">
                                    </tag-selector>
                                </el-form-item>
                            </el-form>
                            <el-form label-position="left"
                                     data-testid="comm-advanced-filters-call-disposition-form"
                                     label-width="150px">
                                <el-form-item label="Call Disposition">
                                    <call-disposition-selector :multiple="true"
                                                               :value="filter_call_dispositions"
                                                               :placeholder="'Call Dispositions'"
                                                               @change="changeCallDispositions"
                                                               class="filter-select"
                                                               v-model="filter_call_dispositions">
                                    </call-disposition-selector>
                                </el-form-item>
                            </el-form>
                            <el-form label-position="left"
                                     label-width="200px"
                                     data-testid="comm-advanced-filters-not-disposed-form"
                                     v-show="show_tags">
                                <el-form-item label="Not Disposed">
                                    <el-switch v-model="not_disposed"
                                               :disabled="!['all', 'call'].includes(type)"
                                               active-color="#00a344"
                                               :active-value="1"
                                               :inactive-value="0"
                                               data-testid="comm-advanced-filters-not-disposed-switch"
                                               @change="changeNotDisposed">
                                    </el-switch>
                                </el-form-item>
                            </el-form>
                            <el-form
                                    v-show="show_tags"
                                    label-position="left"
                                    data-testid="comm-advanced-filters-unread-only-form"
                                    label-width="200px">
                                <el-form-item
                                        label="Unread Only">
                                    <el-switch
                                            v-model="unread_only"
                                            active-color="#00a344"
                                            :active-value="1"
                                            :disabled="!['all'].includes(type)"
                                            :inactive-value="0"
                                            data-testid="comm-advanced-filters-unread-only-switch"
                                            @change="changeUnreadOnly">
                                    </el-switch>
                                </el-form-item>
                            </el-form>
                            <el-form label-position="left"
                                     data-testid="comm-advanced-filters-first-time-only-form"
                                     label-width="200px">
                                <el-form-item label="First-time Conversations">
                                    <el-switch :active-value="1"
                                               :inactive-value="0"
                                               data-testid="comm-advanced-filters-first-time-only-switch"
                                               @change="changeFirstTimeCaller"
                                               active-color="#00a344"
                                               v-model="first_time_only">
                                    </el-switch>
                                </el-form-item>
                            </el-form>
                            <el-form label-position="left"
                                     data-testid="comm-advanced-filters-untagged-form"
                                     label-width="200px">
                                <el-form-item label="Untagged Conversations">
                                    <el-switch :active-value="1"
                                               :inactive-value="0"
                                               data-testid="comm-advanced-filters-untagged-switch"
                                               @change="changeUntagged"
                                               active-color="#00a344"
                                               v-model="untagged_only">
                                    </el-switch>
                                </el-form-item>
                            </el-form>
                            <el-form label-position="left"
                                     data-testid="comm-advanced-filters-exclude-automated-communications-form"
                                     label-width="228px">
                                <el-form-item label="Exclude automated communications">
                                    <el-switch :active-value="1"
                                               :inactive-value="0"
                                               data-testid="comm-advanced-filters-exclude-automated-communications-switch"
                                               @change="changedExcludeAutomatedCommunications"
                                               active-color="#00a344"
                                               v-model="exclude_automated_communications">
                                    </el-switch>
                                </el-form-item>
                            </el-form>
                            <el-form label-position="left"
                                     data-testid="comm-advanced-filters-creator-type-form"
                                     label-width="219px">
                                <el-form-item label="Creator Type">
                                    <el-select @change="changeCreatorType"
                                               class="filter-select"
                                               placeholder="Select Creator Type"
                                               data-testid="comm-advanced-filters-creator-type-select"
                                               v-model="creator_type"
                                               clearable>
                                        <el-option :key="item.value + '-' + item.label"
                                                   :label="item.label"
                                                   :value="item.value"
                                                   v-for="item in creator_types">
                                            <span>
                                                {{ item.label }}
                                            </span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <div class="mt-2"
                                 v-if="!campaign_id && hasPermissionTo('list campaign')">
                                <span class="filter-header text-muted">Attribution</span>
                                <div class="dropdown-divider"></div>
                                <el-form label-position="left"
                                         data-testid="comm-advanced-filters-campaign-form"
                                         label-width="150px">
                                    <el-form-item label="Lines">
                                        <campaign-selector :multiple="true"
                                                           :value="filter_campaigns"
                                                           @change="changeCampaigns"
                                                           class="filter-select"
                                                           v-model="filter_campaigns">
                                        </campaign-selector>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="mt-2"
                                 v-if="!user_id">
                                <span class="filter-header text-muted"
                                      v-if="campaign_id">
                                    Attribution
                                </span>
                                <div class="dropdown-divider"
                                     v-if="campaign_id"></div>
                                <el-form label-position="left"
                                         label-width="150px"
                                         data-testid="comm-advanced-filters-users-form"
                                         v-if="hasPermissionTo('list user')">
                                    <el-form-item label="Users">
                                        <user-selector :multiple="true"
                                                       :value="filter_users"
                                                       data-testid="comm-advanced-filters-users-select"
                                                       @change="changeUsers"
                                                       class="filter-select"
                                                       v-model="filter_users">
                                        </user-selector>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="mt-2"
                                 v-if="!team_id && hasPermissionTo('list team')">
                                <el-form label-position="left"
                                         label-width="150px">
                                    <el-form-item label="Teams" data-testid="comm-advanced-filter-teams-item">
                                        <team-selector class="filter-select"
                                                       :multiple="true"
                                                       :value="filter_teams"
                                                       @change="changeTeams">
                                        </team-selector>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="mt-2"
                                 v-if="!ring_group_id && hasPermissionTo('list ring group')">
                                <el-form label-position="left"
                                         label-width="150px">
                                    <el-form-item label="Ring Groups"
                                                  data-testid="comm-advanced-filter-ring-groups-item">
                                        <ring-group-selector :multiple="true"
                                                             :value="filter_ring_groups"
                                                             @change="changeRingGroups"
                                                             class="filter-select"
                                                             v-model="filter_ring_groups">
                                        </ring-group-selector>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="mt-2">
                                <el-form label-position="left"
                                         data-testid="comm-advanced-filters-incoming-numbers-form"
                                         label-width="150px">
                                    <el-form-item label="Numbers"
                                                  data-testid="comm-advanced-filter-incoming-numbers-item">
                                        <el-select @change="changeIncomingNumbers"
                                                   class="filter-select"
                                                   collapse-tags
                                                   filterable
                                                   multiple
                                                   data-testid="comm-advanced-filters-incoming-numbers-select"
                                                   placeholder="Select Numbers"
                                                   v-model="filter_incoming_numbers">
                                            <el-option :key="index + '-' + item.phone_number"
                                                       :label="getFixPhone(item.phone_number)"
                                                       :value="item.id"
                                                       v-for="(item, index) in incomingNumbers">
                                                <span>{{ getFixPhone(item.phone_number) }}</span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="mt-2"
                                 v-if="!workflow_id && hasPermissionTo('list workflow')">
                                <el-form label-position="left"
                                         data-testid="comm-advanced-filters-sequences-form"
                                         label-width="150px">
                                    <el-form-item label="Sequences"
                                                  data-testid="comm-advanced-filter-sequences-item">
                                        <workflow-selector class="filter-select"
                                                           v-model="filter_workflows"
                                                           :multiple="true"
                                                           :value="filter_workflows"
                                                           @change="changeWorkflows">
                                        </workflow-selector>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="mt-2"
                                 v-if="!broadcast_id && hasPermissionTo('list broadcast')">
                                <el-form label-position="left"
                                         data-testid="comm-advanced-filters-broadcasts-form"
                                         label-width="150px">
                                    <el-form-item label="Broadcasts"
                                                  data-testid="comm-advanced-filter-broadcast-item">
                                        <broadcast-selector class="filter-select"
                                                            v-model="filter_broadcasts"
                                                            :multiple="true"
                                                            :value="filter_broadcasts"
                                                            @change="changeBroadcasts">
                                        </broadcast-selector>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div class="col-12"
                             v-if="only_campaign_selector">
                            <div class="mt-2"
                                 v-if="!campaign_id">
                                <span class="filter-header text-muted">Attribution</span>
                                <div class="dropdown-divider"></div>
                                <el-form label-position="left"
                                         data-testid="comm-advanced-filters-campaign-attribution-form"
                                         label-width="150px">
                                    <el-form-item label="Lines"
                                                  data-testid="comm-advanced-filter-attribution-item">
                                        <campaign-selector :multiple="true"
                                                           :value="filter_campaigns"
                                                           @change="changeCampaigns"
                                                           class="filter-select"
                                                           v-model="filter_campaigns">
                                        </campaign-selector>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div class="col-12 mt-2 mb-4"
                             v-if="hasPermissionTo('list contact list item')">
                            <span class="filter-header text-muted">
                                Contact
                            </span>
                            <div class="dropdown-divider"></div>
                            <el-form label-position="left"
                                     label-width="150px"
                                     data-testid="comm-advanced-filters-contact-list-form">
                                <el-form-item label="Contact Lists">
                                    <el-select class="filter-select"
                                               placeholder="Select Contact List"
                                               data-testid="comm-advanced-filters-contact-lists-select"
                                               multiple
                                               filterable
                                               v-model="filter_contact_lists"
                                               clearable
                                               @change="changeContactLists">
                                        <el-option :key="item.id + '-' + item.name"
                                                   :label="item.name"
                                                   :value="item.id"
                                                   v-for="item in contact_lists">
                                            <span>
                                                {{ item.name }}
                                            </span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="col-12 mt-3">
                            <button @click="applyFilters"
                                    data-testid="comm-advanced-filters-apply-button"
                                    class="btn greenish p-x-md pull-right">
                                Apply
                            </button>

                            <button @click="resetOurFilters"
                                    data-testid="comm-advanced-filters-reset-button"
                                    class="btn deep-orange p-x-md pull-right mr-2">
                                Reset Filters
                            </button>
                        </div>
                    </div>
                </el-dialog>
            </div>

            <!-- Saved Filters dropdown -->
            <div class="mr-2 dropdown-toggle-caret-none"
                 v-if="hasPermissionTo('list filter') && !only_date_filter">
                <button :disabled="loading"
                        data-testid="comm-advanced-filters-saved-filters-button"
                        @click="toggleSavedFiltersMenu"
                        class="btn btn-sm white dropdown-toggle"
                        v-if="checkSize()">
                    Saved Filters <i class="el-icon-arrow-down"></i>
                </button>

                <button :disabled="loading"
                        data-testid="comm-advanced-filters-saved-filters-i-button"
                        @click="toggleSavedFiltersMenu"
                        class="btn white dropdown-toggle"
                        v-else>
                    <i class="fa fa-save"></i>
                </button>

                <div class="saved-filters-menu dropdown-menu mega-dropdown-menu dropdown-menu-overlay lean-bottom-margin bold-label right-align no-select"
                     style="left: auto"
                     :class="saved_filters_menu_visible ? 'show' : ''"
                     data-testid="comm-advanced-filters-saved-filters-dropdown-menu"
                     @click="noClose($event)">
                    <div class="row nav-padding">
                        <div class="col-12">
                            <span class="filter-header text-muted">
                                My Filters
                                <a @click.prevent="saveFilter('user')"
                                   class="pull-right text-dark-greenish"
                                   data-testid="comm-advanced-filters-save-filter-button"
                                   v-if="hasPermissionTo('create filter')">
                                    Save Filter
                                </a>
                            </span>
                            <div class="dropdown-divider"></div>
                            <ul class="list inset">
                                <li :key="filter.id + '-user-filters'"
                                    class="filters pb-2 pt-2"
                                    v-for="filter in user_filters">
                                    <div class="pull-right"
                                         v-if="edit.id != filter.id">
                                        <a @click.prevent="setAsDefaultFilter(filter)"
                                           data-testid="comm-advanced-filters-set-as-default-filter-button"
                                           class="btn btn-xs"
                                           href="#"
                                           v-if="canChangeDefaultFilter && (!defaultFilter || defaultFilter.id != filter.id)">
                                            <i class="fa fa-spinner fa-spin"
                                               v-if="savingDefaultFilter == filter"></i>
                                            Set as Default
                                        </a>

                                        <a @click.prevent="unsetDefaultFilter()"
                                           class="btn btn-xs"
                                           data-testid="comm-advanced-filters-unset-default-filter-button"
                                           href="#"
                                           v-if="canChangeDefaultFilter && (defaultFilter && defaultFilter.id === filter.id)">
                                            <i class="fa fa-spinner fa-spin"
                                               v-if="unsettingDefaultFilter && defaultFilter == filter"></i>
                                            Unset Default
                                        </a>

                                        <a @click.prevent="loadFilter($event, filter)"
                                           class="btn btn-xs btn-icon"
                                           data-testid="comm-advanced-filters-done-button"
                                           href="#">
                                            <i class="material-icons">done</i>
                                        </a>

                                        <a @click.prevent="toggleEdit(filter.id, filter.name)"
                                           class="btn btn-xs btn-icon"
                                           data-testid="comm-advanced-filters-edit-button"
                                           href="#"
                                           v-if="hasPermissionTo('update filter')">
                                            <i class="material-icons">edit</i>
                                        </a>

                                        <a @click.prevent="deleteCustomFilter($event, filter)"
                                           class="btn btn-xs btn-icon"
                                           data-testid="comm-advanced-filters-delete-button"
                                           href="#"
                                           v-if="hasPermissionTo('archive filter')">
                                            <i class="material-icons">delete</i>
                                        </a>
                                    </div>
                                    <div class="pull-right"
                                         v-else>
                                        <a @click.prevent="cancelEditFilter(filter.id)"
                                           class="btn btn-xs"
                                           data-testid="comm-advanced-filters-cancel-button"
                                           href="#"
                                           v-if="hasPermissionTo('update filter')">
                                            Cancel
                                        </a>

                                        <a @click.prevent="updateFields(filter.id)"
                                           class="btn btn-xs greenish"
                                           data-testid="comm-advanced-filters-save-button"
                                           href="#"
                                           v-if="hasPermissionTo('update filter')">
                                            <i class="material-icons loader"
                                               v-show="loading_btn">&#xE863;</i>
                                            <i class="fa fa-floppy-o"
                                               v-show="!loading_btn"></i>
                                            Save
                                        </a>
                                    </div>
                                    <div>
                                        <div v-if="edit.id != filter.id">
                                            <el-badge :hidden="!defaultFilter || defaultFilter.id != filter.id"
                                                      class="default-filter-indicator mt-1"
                                                      data-testid="comm-advanced-filters-name-badget"
                                                      value="Default">
                                                <a :title="filter.name"
                                                   data-testid="comm-advanced-filters-name-link"
                                                   @click.prevent="loadFilter($event, filter)"
                                                   class="text-blackish"
                                                   href="#">
                                                    {{ filter.name }}
                                                </a>
                                            </el-badge>
                                        </div>
                                        <input :ref="'name_' + filter.id"
                                               class="form-control inline filter-input"
                                               placeholder="Label"
                                               type="text"
                                               v-else
                                               v-focus
                                               data-testid="comm-advanced-filter-name-input"
                                               v-model="filter.name"
                                               v-on:keyup.enter="updateFields(filter.id)"/>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="col-12 mt-3">
                            <span class="filter-header text-muted">
                                Company Filters
                                <a @click.prevent="saveFilter('company')"
                                   class="pull-right text-dark-greenish"
                                   data-testid="comm-advanced-filters-save-company-filter-button"
                                   v-if="hasPermissionTo('create filter') && hasRole('Company Admin')">
                                    Save Filter
                                </a>
                            </span>
                            <div class="dropdown-divider"></div>
                            <ul class="list inset">
                                <li :key="filter.id + '-company-filters'"
                                    class="filters pb-2 pt-2"
                                    v-for="filter in company_filters">
                                    <div class="pull-right"
                                         v-if="edit.id != filter.id">
                                        <a @click.prevent="setAsDefaultFilter(filter) && hasRole('Company Admin')"
                                           class="btn btn-xs"
                                           data-testid="comm-advanced-filters-set-as-default-link-item"
                                           href="#"
                                           v-if="canChangeDefaultFilter && (!defaultFilter || defaultFilter.id != filter.id)">
                                            <i class="fa fa-spinner fa-spin"
                                               v-if="savingDefaultFilter == filter"></i>
                                            Set as Default
                                        </a>

                                        <a @click.prevent="unsetDefaultFilter()"
                                           class="btn btn-xs"
                                           data-testid="comm-advanced-filters-unset-default-link-item"
                                           href="#"
                                           v-if="canChangeDefaultFilter && (defaultFilter && defaultFilter.id === filter.id) && hasRole('Company Admin')">
                                            <i class="fa fa-spinner fa-spin"
                                               v-if="unsettingDefaultFilter && defaultFilter == filter"></i>
                                            Unset Default
                                        </a>

                                        <a @click.prevent="loadFilter($event, filter)"
                                           class="btn btn-xs btn-icon"
                                           data-testid="comm-advanced-filters-done-link-item"
                                           href="#">
                                            <i class="material-icons">done</i>
                                        </a>

                                        <a @click.prevent="toggleEdit(filter.id, filter.name)"
                                           class="btn btn-xs btn-icon"
                                           data-testid="comm-advanced-filters-edit-link-item"
                                           href="#"
                                           v-if="hasPermissionTo('update filter') && hasRole('Company Admin')">
                                            <i class="material-icons">edit</i>
                                        </a>

                                        <a @click.prevent="deleteCustomFilter($event, filter)"
                                           class="btn btn-xs btn-icon"
                                           data-testid="comm-advanced-filters-delete-link-item"
                                           href="#"
                                           v-if="hasPermissionTo('archive filter') && hasRole('Company Admin')">
                                            <i class="material-icons">delete</i>
                                        </a>
                                    </div>
                                    <div class="pull-right"
                                         v-else>
                                        <a @click.prevent="cancelEditFilter(filter.id)"
                                           class="btn btn-xs"
                                           data-testid="comm-advanced-filters-cancel-link-item"
                                           href="#"
                                           v-if="hasPermissionTo('update filter')">
                                            Cancel
                                        </a>

                                        <a @click.prevent="updateFields(filter.id)"
                                           class="btn btn-xs greenish"
                                           data-testid="comm-advanced-filters-save-link-item"
                                           href="#"
                                           v-if="hasPermissionTo('update filter')">
                                            <i class="material-icons loader"
                                               v-show="loading_btn">&#xE863;</i>
                                            <i class="fa fa-floppy-o"
                                               v-show="!loading_btn"></i>
                                            Save
                                        </a>
                                    </div>
                                    <div>
                                        <div v-if="edit.id != filter.id">
                                            <el-badge :hidden="!defaultFilter || defaultFilter.id != filter.id"
                                                      class="default-filter-indicator mt-1"
                                                      data-testid="comm-advanced-filters-company-filter-name-badget"
                                                      value="Default">
                                                <a :title="filter.name"
                                                   data-testid="comm-advanced-filters-company-filter-name-link"
                                                   @click.prevent="loadFilter($event, filter)"
                                                   class="text-blackish"
                                                   href="#">
                                                    {{ filter.name }}
                                                </a>
                                            </el-badge>
                                        </div>
                                        <input :ref="'name_' + filter.id"
                                               class="form-control inline filter-input"
                                               data-testid="comm-advanced-filters-company-filter-name-input"
                                               placeholder="Label"
                                               type="text"
                                               v-else
                                               v-focus
                                               v-model="filter.name"
                                               v-on:keyup.enter="updateFields(filter.id)"/>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dropdown dropdown-toggle-caret-none"
                 v-if="!no_exports && hasRole('Company Admin') && only_date_filter">
                <button :disabled="loading"
                        data-testid="comm-advanced-filters-export-to-csv-button"
                        @click="exportTo('csv')"
                        class="btn btn-outline b-dark-greenish text-dark-greenish">
                    Export <i class="el-icon-arrow-down"></i>
                </button>
            </div>

            <div class="dropdown dropdown-toggle-caret-none ml-auto"
                 v-if="!no_exports && hasRole('Company Admin') && !only_date_filter">
                <button :disabled="loading"
                        data-testid="comm-advanced-filters-toggle-export-menu-button"
                        @click="toggleExportsMenu"
                        class="btn btn-outline b-dark-greenish text-dark-greenish dropdown-toggle">
                    Export <i class="el-icon-arrow-down"></i>
                </button>
                <div :class="exports_menu_visible ? 'show' : ''"
                     class="dropdown-menu dropdown-menu-right">
                    <a @click.prevent="exportTo('csv')"
                       data-testid="comm-advanced-filters-cvs-current-view-link"
                       class="dropdown-item">
                        <i class="fa fa-table"></i>
                        CSV Current View</a>
                    <a @click.prevent="exportTo('csv-all')"
                       data-testid="comm-advanced-filters-cvs-all-columns-link"
                       class="dropdown-item">
                        <i class="fa fa-table"></i>
                        CSV All Columns
                    </a>
                </div>
            </div>
        </div>

        <el-dialog :before-close="handleClose"
                   :visible.sync="dialogVisible"
                   append-to-body
                   class="dialog-padding"
                   title="Create a new Filter"
                   data-testid="comm-advanced-filters-create-filter-dialog"
                   v-if="!only_date_filter"
                   width="30%">
            <el-form :model="custom_filter"
                     :rules="rules"
                     data-testid="comm-advanced-filters-create-filter-form"
                     @submit.prevent.native="createFilter"
                     label-position="top"
                     label-width="100px"
                     ref="create_filter">
                <el-form-item label="Save your current filter"
                              data-testid="comm-advanced-filters-create-filter-item"
                              prop="name">
                    <el-input @input="preValidateForm('create_filter')"
                              data-testid="comm-advanced-filters-create-filter-input"
                              placeholder="Name your filter"
                              v-model="custom_filter.name">
                    </el-input>
                </el-form-item>
            </el-form>

            <span class="dialog-footer"
                  slot="footer">
                <el-button  data-testid="comm-advanced-filters-create-filter-cancel-button"
                            @click="dialogVisible = false">Cancel</el-button>

                <el-button :loading="loading_btn"
                           data-testid="comm-advanced-filters-save-filter-cancel-button"
                           @click="createFilter"
                           type="success">
                    Save
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapGetters, mapState} from 'vuex'
import {mixin as clickaway} from 'vue-clickaway'
import {
    acl_mixin,
    date_mixin,
    form_validation_mixin,
    report_mixin
} from '../mixins'
import {Notification} from 'element-ui'
import RingGroupSelector from './ring-group-selector'
import TeamSelector from './team-selector'
import DateSelector from '../components/date-selector'
import * as Roles from './../constants/roles'
import * as CommunicationTransferTypes from '../constants/communication-transfer-types'
import * as CallbackStatus from '../constants/callback-status'
import * as TagCategory from '../constants/tag-categories'
import * as CreatorTypes from '../constants/creator-types'

export default {
    components: {RingGroupSelector, DateSelector, TeamSelector},

    mixins: [
        date_mixin,
        acl_mixin,
        clickaway,
        form_validation_mixin,
        report_mixin
    ],

    props: {
        campaign_id: {required: false},
        workflow_id: {required: false},
        broadcast_id: {required: false},
        ring_group_id: {required: false},
        user_id: {required: false},
        team_id: {required: false},
        tag_id: {required: false},
        call_disposition_id: {required: false},
        only_campaign_selector: {
            default: false,
            type: Boolean,
            required: false
        },
        only_date_filter: {
            default: false,
            type: Boolean,
            required: false
        },
        report_category: {
            default: null,
            required: false
        },
        no_exports: {
            default: false,
            type: Boolean,
            required: false
        },
        export_type: {
            default: null,
            required: false
        }
    },

    data() {
        return {
            reporting_url: localStorage.getItem('reporting_url'),
            app_env: localStorage.getItem('app_env'),
            auth: auth,
            loading: false,
            changed: false,
            directions: [{
                value: 'all',
                label: 'All'
            }, {
                value: 'inbound',
                label: 'Inbound',
                icon: 'call_received'
            }, {
                value: 'outbound',
                label: 'Outbound',
                icon: 'call_made'
            }],
            direction: 'all',
            answer_status: [{
                value: 'all',
                label: 'All',
                icon_class: null
            }, {
                value: 'answered',
                label: 'Answered',
                icon: 'call_end',
                icon_class: 'text-green-500'
            }, {
                value: 'missed',
                label: 'Missed',
                icon: 'phone_missed',
                icon_class: 'text-red-500'
            }, {
                value: 'abandoned',
                label: 'Abandoned',
                icon: 'phone_missed',
                icon_class: 'text-purple-500'
            }, {
                value: 'voicemail',
                label: 'Voicemail',
                icon: 'voicemail',
                icon_class: 'text-deep-purple-500'
            }, {
                value: 'in-progress',
                label: 'In Progress',
                icon: 'phone_in_talk',
                icon_class: 'text-indigo-500'
            }, {
                value: 'failed',
                label: 'Failed',
                icon: 'phone_locked',
                icon_class: 'text-red-500'
            }, {
                value: 'queued',
                label: 'Queued',
                icon: 'hourglass_empty',
                icon_class: 'text-indigo-500'
            }, {
                value: 'hold',
                label: 'Parked',
                icon: 'phone_paused',
                icon_class: 'text-indigo-500'
            }, {
                value: 'deadend',
                label: 'Dead-end',
                icon: 'block',
                icon_class: 'text-orange-500'
            }],
            answer_state: 'all',
            transfer_types: [{
                value: CommunicationTransferTypes.TRANSFER_TYPE_COLD,
                label: "Cold Transfer"
            }, {
                value: CommunicationTransferTypes.TRANSFER_TYPE_WARM,
                label: "Warm Transfer"
            }, {
                value: CommunicationTransferTypes.TRANSFER_TYPE_CONF,
                label: "Conference"
            }],
            types: [{
                value: 'all',
                label: 'All'
            }, {
                value: 'call',
                label: 'Call',
                icon: 'call'
            }, {
                value: 'sms',
                label: 'Text Message',
                icon: 'sms'
            }, {
                value: 'email',
                label: 'Email',
                icon: 'email'
            }, {
                value: 'fax',
                label: 'Fax',
                icon: 'print'
            }],
            type: 'all',
            transfer_type: null,
            callback_statuses: [{
                value: CallbackStatus.CALLBACK_STATUS_INITIATED,
                label: "Initiated"
            }, {
                value: CallbackStatus.CALLBACK_STATUS_REQUESTED,
                label: "Requested"
            }],
            min_talk_time: '0',
            custom_min_talk_time: 0,
            show_custom_min_talk_time: false,
            show_tags: true,
            callback_status: null,
            transcription_keyword: null,
            transcription_category: null,
            filter_tags: [],
            filter_call_dispositions: [],
            first_time_only: 0,
            untagged_only: 0,
            exclude_automated_communications: 0,
            creator_type: null,
            creator_types: [{
                value: CreatorTypes.CREATOR_TYPE_MANUAL,
                label: "Manual"
            }, {
                value: CreatorTypes.CREATOR_TYPE_API,
                label: "API"
            }, {
                value: CreatorTypes.CREATOR_TYPE_WORKFLOW,
                label: "Workflow"
            }, {
                value: CreatorTypes.CREATOR_TYPE_BROADCAST,
                label: "Broadcast"
            }, {
                value: CreatorTypes.CREATOR_TYPE_POWER_DIALER,
                label: "Power Dialer"
            }, {
                value: CreatorTypes.CREATOR_TYPE_SMS_REMINDER,
                label: "SMS Reminder"
            }, {
                value: CreatorTypes.CREATOR_TYPE_APP_NOTIFICATIONS,
                label: "App Notifications"
            }, {
                value: CreatorTypes.CREATOR_TYPE_HUBSPOT,
                label: "HubSpot"
            }, {
                value: CreatorTypes.CREATOR_TYPE_ZAPIER,
                label: "Zapier"
            }, {
                value: CreatorTypes.CREATOR_TYPE_GOHIGHLEVEL,
                label: "HighLevel"
            }, {
                value: CreatorTypes.CREATOR_TYPE_ALOAI,
                label: "AloAi"
            }],
            filter_campaigns: [],
            filter_workflows: [],
            filter_broadcasts: [],
            filter_contact_lists: [],
            filter_ring_groups: [],
            filter_incoming_numbers: [],
            filter_users: [],
            filter_teams: [],
            filter_owners: [],
            prop_counter: 0,
            custom_filter: {
                name: null,
                filter: null,
                filter_scope: null
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Please provide a name for your filter',
                        trigger: 'blur'
                    }
                ]
            },
            edit: {
                id: null,
                name: false,
                previous_name: ''
            },
            dialogVisible: false,
            loading_btn: false,
            savingDefaultFilter: null,
            unsettingDefaultFilter: false,
            filters_menu_visible: false,
            saved_filters_menu_visible: false,
            exports_menu_visible: false,
            table_fields: [{
                text: 'Number',
                val: 'incoming_number'
            }, {
                text: 'Ring Group',
                val: 'ring_group'
            }, {
                text: 'Sequence',
                val: 'workflow'
            }, {
                text: 'Broadcast',
                val: 'broadcast'
            }, {
                text: 'Start Time',
                val: 'created_at'
            }, {
                text: 'Talk Time',
                val: 'talk_time'
            }, {
                text: 'Duration',
                val: 'duration'
            }, {
                text: 'Resolution',
                val: 'resolution2'
            }, {
                text: 'Wait Time',
                val: 'wait_time'
            }, {
                text: 'Contact',
                val: 'lead_number'
            }, {
                text: 'Location',
                val: 'lead_location'
            }, {
                text: 'Lines',
                val: 'line'
            }, {
                text: 'User',
                val: 'user'
            }, {
                text: 'Attempting',
                val: 'attempting_users'
            }, {
                text: 'Transferred From',
                val: 'transfer_prior_user_ids'
            }, {
                text: 'Transferred To',
                val: 'transfer_target_user_ids'
            }, {
                text: 'Cold Transferred?',
                val: 'in_cold_transfer'
            }, {
                text: 'Recording',
                val: 'recording_url'
            }, {
                text: 'Voicemail',
                val: 'voicemail_url'
            }, {
                text: 'Tags',
                val: 'tags'
            }, {
                text: 'Notes',
                val: 'notes'
            }, {
                text: 'Operations',
                val: 'operations'
            }, {
                text: 'Transfer Type',
                val: 'transfer_type'
            }, {
                text: 'Callback Status',
                val: 'callback_status'
            }, {
                text: 'Queue Resolution',
                val: 'queue_resolution2'
            }, {
                text: 'Email',
                val: 'email'
            }, {
                text: 'Type',
                val: 'type',
            }, {
                text: 'Direction',
                val: 'direction'
            }, {
                text: 'Disposition Status',
                val: 'disposition_status'
            }, {
                text: 'Creator Type',
                val: 'creator_type'
            }],
            is_filters_applied: false,
            default_filter: [],
            exclude_from_filter_changes: ['search_fields', 'search_text', 'page', 'changed'],
            filters_changes: [],
            custom_menu_filter: {},
            default_date_range: null,
            default_filter_for_comparison: null,
            excluded_filters: [
                'report_type',
                'search_text',
                'search_fields',
                'page',
                'per_page',
                'chart_period',
                'export_type',
                'date_field',
                'from_date',
                'to_date',
                'states_limit',
                'changed'
            ],
            filtered_allowed: [
                'campaign_id',
                'workflow_id',
                'broadcast_id',
                'ring_group_id',
                'user_id',
                'team_id',
                'tag_id',
                'call_disposition_id',
                'not_disposed'
            ],
            baseline: null,
            sub_categories: [
                'users_activity_reporting',
                'lines_activity_reporting',
                'ring_groups_activity_reporting',
                'sequences_activity_reporting',
                'broadcasts_activity_reporting',
                'contacts_activity_reporting',
            ],
            loading_tags: false,
            tags: [],
            not_disposed: 0,
            unread_only: 0,
            has_international: 0,
            TagCategory,
            CreatorTypes,
            tags_filters_to_text: false
        }
    },

    computed: {
        ...mapGetters({
            filter: 'getFilter',
            transcription_reporting_data: 'getTranscriptionReportingData',
            transcription_page: 'getTranscriptionPage',
        }),

        section: {
            get() {
                return this.$store.state.section
            },
            set(new_section) {
                this.setSection(new_section)
            }
        },

        ...mapState({
            campaigns: state => state.campaigns,
            workflows: state => state.workflows,
            broadcasts: state => state.broadcasts,
            ring_groups: state => state.ring_groups,
            contact_lists: state => state.contact_lists,
            users: state => state.users,
            teams: state => state.teams,
            filters: state => state.filters,
            call_dispositions: state => state.call_dispositions,
            transcription_report_loading: state => state.transcription_report_loading,
        }),

        ...mapState('cache', ['current_company', 'comm_table_fields']),

        shouldInitializeFiltersText() {
            return !this.$root.loading && !this.loading_tags
        },

        allTableFields() {
            return _.map(this.table_fields, item => {
                return item.val
            })
        },

        user_filters: function () {
            if (this.filters && this.filters.length > 0) {
                return this.filters.filter(filter => {
                    return !filter.is_on_company
                })
            }

            return []
        },

        company_filters: function () {
            if (this.filters && this.filters.length > 0) {
                return this.filters.filter(filter => {
                    return filter.is_on_company
                })
            }

            return []
        },

        campaign() {
            if (!this.campaign_id) {
                return null
            }
            if (this.campaigns) {
                return this.campaigns.find(item => {
                    return item.id == this.campaign_id
                })
            }

            return null
        },

        workflow() {
            if (!this.workflow_id) {
                return null
            }
            if (this.workflows) {
                return this.workflows.find(item => {
                    return item.id == this.workflow_id
                })
            }

            return null
        },

        broadcast() {
            if (!this.broadcast_id) {
                return null
            }
            if (this.broadcasts) {
                return this.broadcasts.find(item => {
                    return item.id == this.broadcast_id
                })
            }

            return null
        },

        ringGroup() {
            if (!this.ring_group_id) {
                return null
            }
            if (this.ring_groups) {
                return this.ring_groups.find(item => {
                    return item.id == this.ring_group_id
                })
            }

            return null
        },

        team() {
            if (!this.team_id) {
                return null
            }
            if (this.teams) {
                return this.teams.find(item => {
                    return item.id == this.team_id
                })
            }

            return null
        },

        canChangeDefaultFilter() {
            if (this.user_id) {
                return false
            }
            return this.hasRole(Roles.COMPANY_ADMIN)
        },

        incomingNumbers() {
            // in line activity
            if (this.campaign && this.campaign.incoming_numbers && this.campaign.incoming_numbers.length > 0) {
                let incoming_numbers = _.clone(this.campaign.incoming_numbers)

                return incoming_numbers.sort((a, b) => {
                    let textA = a.phone_number.toString()
                    let textB = b.phone_number.toString()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            // in dashboard and reports
            if (this.campaigns && this.campaigns.length > 0) {
                let campaigns = _.clone(this.campaigns)
                let incoming_numbers = []
                incoming_numbers = campaigns.map(campaign => (campaign.incoming_numbers && campaign.incoming_numbers.length > 0) ? campaign.incoming_numbers : null).filter(o => o !== null)

                if (incoming_numbers.length > 0) {
                    incoming_numbers = _.flatten(incoming_numbers)
                    return incoming_numbers.sort((a, b) => {
                        let textA = a.phone_number.toString()
                        let textB = b.phone_number.toString()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })
                }
            }

            return []
        },

        defaultFilter() {
            if (this.default_filter_for_comparison) {
                if (this.campaign) {
                    return this.filters.find(item => {
                        return item.id == this.campaign.default_filter_id
                    })
                }
                if (!this.user_id && this.current_company) {
                    return this.filters.find(item => {
                        return item.id == this.current_company.default_filter_id
                    })
                }
            }

            return null
        },

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        min_talk_times() {
            return [
                { value: 'Custom', label: 'Custom' },
                { value: '0', label: 'Any Talk Time' },
                { value: '15', label: 'Greater than 15 seconds' },
                { value: '30', label: 'Greater than 30 seconds' },
                { value: '60', label: 'Greater than 60 seconds' },
                { value: '90', label: 'Greater than 90 seconds' },
                { value: '120', label: `Greater than ${this.current_company.qualified_calls_threshold1} minutes` },
                { value: '300', label: `Greater than ${this.current_company.qualified_calls_threshold2} minutes` }
            ];
        }
    },

    created() {
        this.default_filter_for_comparison = _.clone(this.filter)
        if (this.hasPermissionTo('list filter')) {
            this.getFilters().then(() => {
                // set default filter
                // don't load for workflow, broadcast, ring group and user activity pages
                // default filters applies to the dashboard and line activity
                if (this.defaultFilter && !this.workflow_id && !this.broadcast_id && !this.ring_group_id && !this.user_id && !this.team_id) {
                    this.loadFilter(null, this.defaultFilter)
                }
            })
        }

        setTimeout(() => {
            if (this.tag_id) {
                this.changeTags([this.tag_id])
                this.default_date_range = 'All Time - nochange'
                VueEvent.fire('change_date_range', 'All Time')
                this.applyFilters(false)
            }
            if (this.call_disposition_id) {
                this.changeCallDispositions([this.call_disposition_id])
                this.default_date_range = 'All Time - nochange'
                VueEvent.fire('change_date_range', 'All Time')
                this.applyFilters(false)
            }
        }, 500)

        VueEvent.listen('contact_activity_report_baseline_change', (contact_activity_report_baseline) => {
            this.baseline = contact_activity_report_baseline
        })

        VueEvent.listen('update_summary', (should_update_filters_text = true) => {
            if (should_update_filters_text) {
                this.overallFiltersToText()
            }
        })
    },

    mounted() {
        // set default filter
        if (this.defaultFilter && !this.workflow_id && !this.broadcast_id && !this.ring_group_id && !this.user_id) {
            this.loadFilter(null, this.defaultFilter)
        }

        this.overallFiltersToText()

        if (this.$route.hash === '#transcriptions' && this.section !== 'Transcriptions') {
            this.section = 'Transcriptions'
        } else if (this.section !== 'Activities') {
            this.section = 'Activities'
        }
    },

    methods: {
        async getTags(ids = []) {
            if (this.hasPermissionTo('list tag')) {
                if (!ids.length) {
                    return
                }
                this.loading_tags = true
                let params = {
                    full_load: true,
                    tag_ids: ids
                }
                return axios.get('/api/v1/tag', {params}).then(res => {
                    this.tags = res.data
                    this.loading_tags = false
                }).catch(err => {
                    console.log(err)
                    this.loading_tags = false
                })
            }
        },

        getTagsByIds (ids = []) {
            if (this.hasPermissionTo('list tag')) {
                if (!ids.length) {
                    return
                }
                this.loading_tags = true
                let params = {
                    full_load: true,
                    tag_ids: ids
                }
                axios.get('/api/v1/tag', {params}).then(res => {
                    this.tags = res.data
                    this.loading_tags = false
                }).catch(err => {
                    console.log(err)
                    this.loading_tags = false
                })
            }
        },

        closeAllMenus() {
            if (this.saved_filters_menu_visible) {
                this.saved_filters_menu_visible = false
            }
            if (this.exports_menu_visible) {
                this.exports_menu_visible = false
            }
        },

        closeFiltersMenu(done) {
            if (this.hasFilterMenuChanges(this.default_filter) && !this.is_filters_applied) {
                this.$confirm(`Are you sure you want to close the filters?`, 'Filters', {
                    confirmButtonText: 'Yes, I\'m sure',
                    cancelButtonText: 'No, I\'m not',
                    type: 'warning',
                    customClass: 'width-500 fixed'
                }).then(() => {
                    this.custom_menu_filter = _.clone(this.filter)
                    this.resetModels()
                    done()
                }).catch((err) => {
                    console.log(err)
                })
            } else {
                done()
            }
        },

        toggleExportsMenu() {
            let old_exports_menu_visible = _.clone(this.exports_menu_visible)
            this.closeAllMenus()
            this.exports_menu_visible = !old_exports_menu_visible
        },

        toggleFiltersMenu() {
            this.filters_menu_visible = !this.filters_menu_visible
        },

        toggleSavedFiltersMenu() {
            let old_saved_filters_menu_visible = _.clone(this.saved_filters_menu_visible)
            this.closeAllMenus()
            this.saved_filters_menu_visible = !old_saved_filters_menu_visible
        },

        hasFilterMenuChanges(filter_to_compare) {
            let cleaned_filter = Object.assign({}, this.custom_menu_filter)
            let cleaned_filters_changes = Object.assign({}, filter_to_compare)
            for (let excluded_index of this.exclude_from_filter_changes) {
                delete cleaned_filter[excluded_index]
                delete cleaned_filters_changes[excluded_index]
            }
            if (JSON.stringify(cleaned_filter) !== JSON.stringify(cleaned_filters_changes)) {
                return true
            }
            return false
        },

        resetModels() {
            this.answer_state = this.custom_menu_filter.answer_status
            this.type = this.custom_menu_filter.type
            this.transfer_type = this.custom_menu_filter.transfer_type
            this.direction = this.custom_menu_filter.direction
            this.first_time_only = this.custom_menu_filter.first_time_only
            this.untagged_only = this.custom_menu_filter.untagged_only
            this.exclude_automated_communications = this.custom_menu_filter.exclude_automated_communications
            this.creator_type = this.custom_menu_filter.creator_type
            this.filter_tags = this.custom_menu_filter.tags
            this.filter_call_dispositions = this.custom_menu_filter.call_dispositions
            this.min_talk_time = this.custom_menu_filter.min_talk_time.toString()
            this.filter_campaigns = this.custom_menu_filter.campaigns
            this.filter_workflows = this.custom_menu_filter.workflows
            this.filter_broadcasts = this.custom_menu_filter.broadcasts
            this.filter_ring_groups = this.custom_menu_filter.ring_groups
            this.filter_incoming_numbers = this.custom_menu_filter.incoming_numbers
            this.filter_users = this.custom_menu_filter.users
            this.filter_contact_lists = this.custom_menu_filter.contact_lists
            this.filter_teams = this.custom_menu_filter.teams
            this.filter_owners = this.custom_menu_filter.owners
            this.changed = this.custom_menu_filter.changed
            this.callback_status = this.custom_menu_filter.callback_status
            this.has_international = this.custom_menu_filter.has_international
            this.not_disposed = this.custom_menu_filter.not_disposed
            this.transcription_keyword = null
            this.transcription_category = null
        },

        applyFilters(should_toggle = true) {
            if (this.hasFilterMenuChanges(this.default_filter)) {
                this.changed = true
            } else {
                this.changed = false
            }
            if (!this.is_filters_applied) {
                let exclude_fields = [
                    'from_date',
                    'to_date'
                ]

                for (let index in this.custom_menu_filter) {
                    if (!exclude_fields.includes(index)) {
                        this.$set(this.filter, index, this.custom_menu_filter[index])
                    }
                }
                this.filter.changed = true
                this.is_filters_applied = true
                VueEvent.fire('update_summary', false)
            }
            this.overallFiltersToText()
            if (should_toggle) {
                this.toggleFiltersMenu()
            }
        },

        getFilters() {
            this.loading = true
            return axios.get('/api/v1/filter').then(res => {
                this.setFilters(res.data)
                this.default_filter = Object.assign({}, this.filter)
                this.filter_changes = this.default_filter
                this.loading = false
                return Promise.resolve(res)
            }).catch(err => {
                console.log(err)
                this.loading = false
                return Promise.reject(err)
            })
        },

        changeDirection(direction) {
            this.custom_menu_filter.direction = direction
            this.is_filters_applied = false
        },

        changeAnswerStatus(answer_status) {
            this.custom_menu_filter.answer_status = answer_status
            this.is_filters_applied = false
        },

        changeType(type) {
            this.custom_menu_filter.type = type
            this.is_filters_applied = false
        },

        changeTransferType(transfer_type) {
            this.custom_menu_filter.transfer_type = transfer_type
            this.is_filters_applied = false
        },

        changeMinTalkTime(min_talk_time) {
            if (min_talk_time == "Custom") {
                // show chooser
                this.show_custom_min_talk_time = true
            } else {
                this.custom_menu_filter.min_talk_time = min_talk_time
            }
            this.is_filters_applied = false
        },

        changeCallbackStatus(callback_status) {
            this.custom_menu_filter.callback_status = callback_status
            this.is_filters_applied = false
        },

        changeInternational(has_international) {
            this.custom_menu_filter.has_international = has_international
            this.is_filters_applied = false
        },

        changeCustomMinTalkTime() {
            this.custom_menu_filter.min_talk_time = this.custom_min_talk_time
            this.min_talk_time = this.custom_min_talk_time
            // reset chooser data
            this.show_custom_min_talk_time = false
            this.custom_min_talk_time = 0
            this.is_filters_applied = false
        },

        changeTags(tags) {
            this.custom_menu_filter.tags = tags
            this.is_filters_applied = false
        },

        changeFirstTimeCaller(first_time_only) {
            this.custom_menu_filter.first_time_only = first_time_only ? 1 : 0
            this.is_filters_applied = false
        },

        changeUntagged(untagged_only) {
            this.custom_menu_filter.untagged_only = untagged_only ? 1 : 0
            this.is_filters_applied = false
        },

        changedExcludeAutomatedCommunications(exclude_automated_communications) {
            this.custom_menu_filter.exclude_automated_communications = exclude_automated_communications ? 1 : 0
            this.is_filters_applied = false
        },

        changeCreatorType(creator_type) {
            this.custom_menu_filter.creator_type = creator_type
            this.is_filters_applied = false
        },

        changeCampaigns(filter_campaigns) {
            this.custom_menu_filter.campaigns = filter_campaigns
            this.is_filters_applied = false
        },

        changeWorkflows(filter_workflows) {
            this.custom_menu_filter.workflows = filter_workflows
            this.is_filters_applied = false
        },

        changeBroadcasts(filter_broadcasts) {
            this.custom_menu_filter.broadcasts = filter_broadcasts
            this.is_filters_applied = false
        },

        changeRingGroups(filter_ring_groups) {
            this.custom_menu_filter.ring_groups = filter_ring_groups
            this.is_filters_applied = false
        },

        changeTeams(filter_teams, rawTeams) {
            this.custom_menu_filter.teams = filter_teams
            this.is_filters_applied = false
        },

        changeIncomingNumbers(filter_incoming_numbers) {
            this.custom_menu_filter.incoming_numbers = filter_incoming_numbers
            this.is_filters_applied = false
        },

        changeUsers(filter_users) {
            this.custom_menu_filter.users = filter_users
            this.is_filters_applied = false
        },

        changeContactLists(filter_contact_lists) {
            this.custom_menu_filter.contact_lists = filter_contact_lists
            this.is_filters_applied = false
        },

        changeOwners(filter_owners) {
            this.custom_menu_filter.owners = filter_owners
            this.is_filters_applied = false
        },

        changeSectionFromBtn(section) {
            this.section = section
        },

        changeSection(section) {
            let hash = ''
            switch (section) {
                case 'Transcriptions':
                    this.show_tags = false
                    hash = '#transcriptions'
                    break
                default:
                    this.show_tags = true
                    break
            }

            history.pushState(
                {},
                null,
                this.$route.path + hash
            )
        },

        changeCallDispositions(call_disposition_ids) {
            this.custom_menu_filter.call_dispositions = call_disposition_ids
            this.is_filters_applied = false
        },

        noClose(event) {
            if (event) {
                event.stopPropagation()
            }
        },

        getFixPhone(phoneNumber, format = 'NATIONAL') {
            return this.$options.filters.fixPhone(phoneNumber, format).toString()
        },

        saveFilter(filter_scope) {
            this.custom_filter.name = null
            this.custom_filter.filter = _.merge(this.custom_filter.filter, this.filter)
            if (this.custom_filter.filter.from_date) {
                delete this.custom_filter.filter.from_date
            }
            if (this.custom_filter.filter.to_date) {
                delete this.custom_filter.filter.to_date
            }
            this.custom_filter.filter_scope = filter_scope
            this.dialogVisible = true
        },

        updateCampaignDefaultFilter(filter_id) {
            let updated_campaign = this.campaigns.find(campaign => {
                return campaign.id == this.campaign.id
            })
            if (updated_campaign) {
                updated_campaign.default_filter_id = filter_id
                this.updateCampaign(updated_campaign)
            }
        },

        setAsDefaultFilter(filter) {
            if (this.savingDefaultFilter || this.unsettingDefaultFilter) {
                return
            }
            this.savingDefaultFilter = filter
            this.loading = true
            if (this.campaign_id) {
                axios.post(`/api/v1/campaign/${this.campaign.id}/set-default-filter`, {
                    filter_id: filter.id,
                }).then(() => {
                    this.updateCampaignDefaultFilter(filter.id)
                    this.loadFilter(null, filter)
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                }).then(() => {
                    this.savingDefaultFilter = null
                    this.loading = false
                })
            } else {
                axios.post('/api/v1/set-default-filter', {
                    filter_id: filter.id,
                }).then(() => {
                    this.setCurrentCompanyDefaultFilterId(filter.id)
                    this.loadFilter(null, filter)
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                }).then(() => {
                    this.savingDefaultFilter = null
                    this.loading = false
                })
            }
        },

        unsetDefaultFilter() {
            if (this.unsettingDefaultFilter) {
                return
            }
            this.loading = true
            this.unsettingDefaultFilter = true
            if (this.campaign_id) {
                axios.post(`/api/v1/campaign/${this.campaign.id}/set-default-filter`, {
                    filter_id: null,
                }).then(() => {
                    this.resetOurFilters(false)
                    this.updateCampaignDefaultFilter(null)
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                }).then(() => {
                    this.unsettingDefaultFilter = false
                    this.loading = false
                })
            } else {
                axios.post('/api/v1/set-default-filter', {
                    filter_id: null,
                }).then(() => {
                    this.resetOurFilters(false)
                    this.setCurrentCompanyDefaultFilterId()
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                }).then(() => {
                    this.unsettingDefaultFilter = false
                    this.loading = false
                })
            }
        },

        loadFilter(event, filter) {
            // we should not load the default filter in reports
            if (this.report_category) {
                return
            }

            if (event) {
                event.stopPropagation()
            }

            let my_filter = filter.filter

            if (this.campaign_id) {
                my_filter.campaign_id = this.campaign_id
            }
            if (this.workflow_id) {
                my_filter.workflow_id = this.workflow_id
            }
            if (this.broadcast_id) {
                my_filter.broadcast_id = this.broadcast_id
            }
            if (this.ring_group_id) {
                my_filter.ring_group_id = this.ring_group_id
            }
            if (this.user_id) {
                my_filter.user_id = this.user_id
            }
            if (this.team_id) {
                my_filter.team_id = this.team_id
            }
            if (this.tag_id) {
                my_filter.tags = [this.tag_id]
            }
            if (this.call_disposition_id) {
                my_filter.call_dispositions = [this.call_disposition_id]
            }

            if (my_filter.from_date) {
                delete my_filter.from_date
            }
            if (my_filter.to_date) {
                delete my_filter.to_date
            }
            if (!_.get(my_filter, 'incoming_numbers', false)) {
                my_filter.incoming_numbers = []
            }
            my_filter = _.extend({}, my_filter, {changed: true})

            this.default_filter = Object.assign({}, my_filter)
            this.custom_menu_filter = _.clone(my_filter)
            this.setFilter(my_filter)
            VueEvent.fire('update_summary', false)
            this.overallFiltersToText()
        },

        deleteCustomFilter(event, filter) {
            if (event) {
                event.stopPropagation()
            }

            this.loading = true
            let params = {
                company_id: this.current_company.id
            }
            this.$confirm('This operation will remove filter. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                axios.delete('/api/v1/filter/' + filter.id, {
                    params
                }).then(res => {
                    this.$notify({
                        offset: 95,
                        title: 'Filter',
                        type: 'success',
                        message: 'Filter has been deleted successfully.',
                        showClose: true
                    })
                    this.deleteFilter(filter)
                    this.loading = false
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading = false
                })
            }).catch(() => {
                // delete canceled
                this.loading = false
            })
        },

        handleClose(done) {
            if (this.filter.name) {
                this.$confirm('Are you sure? all unsaved changes will be lost', 'Warning', {
                    customClass: 'width-500 fixed'
                }).then(res => {
                    this.resetCustomFilter()
                    this.preValidateForm('create_filter', true)
                    done()
                }).catch(err => {
                    console.log(err)
                })
            } else {
                this.resetCustomFilter()
                this.preValidateForm('create_filter', true)
                done()
            }
        },

        resetCustomFilter() {
            this.custom_filter.name = null
            this.custom_filter.filter = null
            this.custom_filter.filter_scope = null
        },

        createFilter() {
            if (this.validateForm('create_filter') == true) {
                this.loading_btn = true
                let params = Object.assign(this.custom_filter, {
                    company_id: this.current_company.id
                })
                axios.post('/api/v1/filter', params).then(res => {
                    this.$notify({
                        offset: 95,
                        title: 'Filter',
                        message: 'Filter has been created successfully.',
                        type: 'success',
                        showClose: true
                    })
                    this.loading_btn = false
                    // push to filters list
                    this.newFilter(res.data)
                    this.resetCustomFilter()
                    this.dialogVisible = false
                }).catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_btn = false
                    this.resetCustomFilter()
                    this.dialogVisible = false
                })
            } else {
                return false
            }
        },

        updateFields: function (filter_id) {
            this.loading_btn = true
            let params = {
                name: this.$refs['name_' + filter_id][0].value,
                company_id: this.current_company.id
            }
            axios.patch('/api/v1/filter/' + filter_id, params).then(res => {
                this.updateFilter(res.data)
                this.$notify({
                    offset: 95,
                    title: 'Filter',
                    message: 'Selected filter has been updated.',
                    type: 'success',
                    showClose: true
                })
                this.resetEditFilter()
                this.loading_btn = false
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.cancelEditFilter(filter_id)
                this.loading_btn = false
            })
        },

        toggleEdit(filter_id, name) {
            this.edit.id = filter_id
            this.edit.name = true
            this.edit.previous_name = name
        },

        cancelEditFilter(filter_id) {
            if (this.filters && this.filters.length > 0) {
                let found_filter = this.filters.find(o => o.id === filter_id)
                if (found_filter) {
                    found_filter.name = this.edit.previous_name
                    this.resetEditFilter()
                }
            }
        },

        resetEditFilter() {
            this.edit.id = null
            this.edit.name = false
            this.edit.previous_name = ''
        },

        resetOurFilters(should_toggle = true) {
            VueEvent.fire('update_summary')
            this.resetFilters()
            if (this.campaign_id) {
                this.filter.campaign_id = this.campaign_id
            }
            if (this.workflow_id) {
                this.filter.workflow_id = this.workflow_id
            }
            if (this.broadcast_id) {
                this.filter.broadcast_id = this.broadcast_id
            }
            if (this.ring_group_id) {
                this.filter.ring_group_id = this.ring_group_id
            }
            if (this.user_id) {
                this.filter.user_id = this.user_id
            }
            if(this.team_id) {
                this.filter.team_id = this.team_id
            }
            if (this.tag_id) {
                this.filter.tags = [this.tag_id]
            }
            if (this.call_disposition_id) {
                this.filter.call_dispositions = [this.call_disposition_id]
            }
            this.custom_menu_filter = _.clone(this.filter)
            this.default_filter = Object.assign({}, this.default_filter_for_comparison)
            this.resetModels()
            VueEvent.fire('reset_date_range')
            this.is_filters_applied = false
            if (should_toggle) {
                this.toggleFiltersMenu()
            }
            this.overallFiltersToText()
        },

        checkSize() {
            let valid_sizes = ['xxl', 'xl']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        checkBlur(e) {
            if (e.currentTarget.getAttribute('aria-expanded') == 'true') {
                e.currentTarget.blur()
            }
        },

        exportTo(type) {
            let exportParams = _.cloneDeep(this.filter)
            exportParams.checked_table_fields = this.comm_table_fields
            exportParams.page = null
            exportParams.per_page = null

            switch (type) {
                case 'csv-all':
                    exportParams.export_type = 'csv'
                    exportParams.all_table_fields = true
                    break
                case 'csv':
                    exportParams.export_type = 'csv'
                    break
            }

            let url = '/api/v1/reports/communications'

            if (this.sub_categories.includes(this.report_category)) {
                // date_field must always be created_at for contacts activity reporting
                exportParams.date_field = 'created_at'
                // this.baseline is used by reports having baseline filter
                // like activity reports' contacts subcategory
                exportParams.baseline = this.baseline ? this.baseline : this.report_category.replace('_activity_reporting', '').replace(/_/g, '-')
                delete exportParams.checked_table_fields
                url = this.reporting_url + '/api/v1/reports/activity-reporting'
            }

            delete exportParams.timezone
            delete exportParams.contact_timezone

            axios.get(url, {
                params: exportParams
            }).then(res => {
                this.$notify.info({
                    offset: 95,
                    title: 'Exports',
                    dangerouslyUseHTMLString: true,
                    message: `Your export is on its way. You can check the progress from the export page or <b class="text-dark-greenish pointer">click here</b>.`,
                    duration: 3000,
                    onClick: () => {
                        this.$router.push({name: 'Account', query: {tab: 'exports'}}).catch((err) => {
                        })
                        Notification.closeAll()
                    }
                })
            }).catch(err => {
                this.$root.handleErrors(err.response)
            })
        },

        addMissingOrRemoveRedundantIdFilters(cleaned_filter) {
            let ids = [
                'campaign_id',
                'workflow_id',
                'broadcast_id',
                'ring_group_id',
                'user_id',
                'team_id',
                'tag_id',
                'call_disposition_id'
            ]
            for (let index of ids) {
                if (typeof index.replace !== 'function') {
                    return {}
                }

                const id_name = index.replace('_id', 's')

                if (this[ids[index]] &&
                    typeof cleaned_filter[ids[index]] === 'undefined' &&
                    !this[id_name].find(item => item.id === parseInt(this[ids[index]]))) {
                    // add in the missing ids if filtered by it
                    cleaned_filter[ids[index]] = this[ids[index]]
                } else if (this[ids[index]] &&
                    typeof cleaned_filter[ids[index]] !== 'undefined' &&
                    this[id_name].find(item => item.id === parseInt(this[ids[index]]))) {
                    // remove the ids to prevent redundancy
                    delete cleaned_filter[ids[index]]
                }
            }
            return cleaned_filter
        },

        async overallFiltersToText() {
            if (!this.shouldInitializeFiltersText) {
                setTimeout(() => {
                    this.overallFiltersToText()
                }, 500)
                return
            }
            // all active filters
            let active_filters = []
            // a flag for determining if filtered by id
            let is_filtered_by_id = this.campaign_id || this.workflow_id || this.broadcast_id || this.ring_group_id || this.user_id || this.tag_id || this.call_disposition_id || this.team_id
            // we only need the active filters
            let cleaned_filter = _.clone(this.filter)
            cleaned_filter = _.pickBy(cleaned_filter, _.identity)
            cleaned_filter = this.addMissingOrRemoveRedundantIdFilters(cleaned_filter)
            for (let index in cleaned_filter) {
                // compare the filter's old value and new value
                // initial value of filter
                let default_filter_value = this.default_filter_for_comparison[index]
                // the current value of the filter
                let custom_menu_filter_value = cleaned_filter[index]
                // now we check if there's any change in the filter's value or is filtered by id
                // and if the filter is not excluded
                if (!this.excluded_filters.includes(index) &&
                    ((typeof default_filter_value != 'undefined' && default_filter_value != custom_menu_filter_value) ||
                        (is_filtered_by_id && this.filtered_allowed.includes(index)))
                ) {
                    // now, we determine/adjust the filter name
                    let filter_name = index
                    switch (index) {
                        case 'min_talk_time':
                            filter_name = 'talk time'
                            break
                        case 'campaigns':
                            filter_name = 'lines'
                            break
                        case 'workflows':
                            filter_name = 'sequences'
                            break
                        case 'first_time_only':
                            filter_name = 'first-time conversations'
                            break
                        case 'untagged_only':
                            filter_name = 'untagged conversations'
                            break
                        case 'campaign_id':
                            filter_name = 'line'
                            break
                        case 'call_disposition_id':
                            filter_name = 'call disposition'
                            break
                        case 'ring_group_id':
                            filter_name = 'ring group'
                            break
                        case 'tag_id':
                            filter_name = 'tag'
                            break
                        case 'user_id':
                            filter_name = 'user'
                            break
                        case 'team_id':
                            filter_name = 'team'
                            break
                        case 'workflow_id':
                            filter_name = 'sequence'
                            break
                        case 'broadcast_id':
                            filter_name = 'broadcast'
                            break
                        case 'has_international':
                            filter_name = 'international only'
                            break
                    }
                    // just replace the underscores with space
                    filter_name = filter_name.replace(/_/g, ' ')

                    let value = cleaned_filter[index]
                    let found = null
                    let new_value = []
                    //now, we need to determine/adjust the value
                    switch (index) {
                        case 'incoming_numbers':
                            for (let id in value) {
                                found = this.incomingNumbers.find(number => number.id === parseInt(value[id]))
                                new_value.push(found ? found.phone_number : value[id])
                            }
                            break
                        case 'call_disposition_id':
                        case 'campaign_id':
                        case 'ring_group_id':
                        case 'tag_id':
                        case 'user_id':
                        case 'team_id':
                        case 'workflow_id':
                        case 'broadcast_id':
                            if (typeof index.replace === 'function') {
                                const id_name = index.replace('_id', 's')
                                found = this[id_name].find(item => item.id === parseInt(value))
                                value = found && id_name == 'users' ? (found.first_name + ' ' + found.last_name) : (found ? found.name : value)
                            } else {
                                value = ''
                            }

                            break
                        case 'users':
                            for (let id in value) {
                                found = this.users.find(user => user.id === parseInt(value[id]))
                                new_value.push(found ? (found.first_name + ' ' + found.last_name) : value[id])
                            }
                            break
                        case 'teams':
                            for (let id in value) {
                                found = this.teams.find(team => team.id === parseInt(value[id]))
                                new_value.push(found ? found.name : value[id])
                            }
                            break
                        case 'campaigns':
                            for (let id in value) {
                                found = this.campaigns.find(campaign => campaign.id === parseInt(value[id]))
                                new_value.push(found ? found.name : value[id])
                            }
                            break
                        case 'workflows':
                            for (let id in value) {
                                found = this.workflows.find(workflow => workflow.id === parseInt(value[id]))
                                new_value.push(found ? found.name : value[id])
                            }
                            break
                        case 'broadcasts':
                            for (let id in value) {
                                found = this.broadcasts.find(broadcast => broadcast.id === parseInt(value[id]))
                                new_value.push(found ? found.name : value[id])
                            }
                            break
                        case 'ring_groups':
                            for (let id in value) {
                                found = this.ring_groups.find(ring_group => ring_group.id === parseInt(value[id]))
                                new_value.push(found ? found.name : value[id])
                            }
                            break
                        case 'contact_lists':
                            for (let id in value) {
                                found = this.contact_lists.find(contact_list => contact_list.id === parseInt(value[id]))
                                new_value.push(found ? found.name : value[id])
                            }
                            break
                        case 'tags':
                            await this.getTags(value)
                            for (let id in value) {
                                found = this.tags.find(o => o.id === parseInt(value[id]))
                                new_value.push(found !== undefined ? found.name : value[id])
                            }
                            break
                        case 'call_dispositions':
                            for (let id in value) {
                                found = this.call_dispositions.find(tag => tag.id === parseInt(value[id]))
                                new_value.push(found ? found.name : value[id])
                            }
                            break
                        case 'min_talk_time':
                            found = this.min_talk_times.find(talk_time => parseInt(talk_time.value) === parseInt(value))
                            value = found ? found.label : (value + (parseInt(value) > 1 ? ' seconds' : ' second'))
                            break
                        case 'transfer_type':
                            found = this.transfer_types.find(type => type.value === value)
                            value = found ? found.label : value
                            break
                        case 'callback_status':
                            found = this.callback_statuses.find(type => type.value === value)
                            value = found ? found.label : value
                            break
                        case 'type':
                            found = this.types.find(type => type.value === value)
                            value = found ? found.label : value
                            break
                        case 'creator_type':
                            found = this.creator_types.find(type => type.value === value)
                            value = found ? found.label : value
                            break
                    }
                    // find out if filter's value is not empty
                    let has_value = false
                    if ((typeof value !== 'object' && value) || (typeof value === 'object' && value.length > 0)) {
                        value = [value]
                        has_value = true
                    }
                    if (new_value.length) {
                        value = new_value
                        has_value = true
                    }
                    // add the filter if value is not empty
                    if (has_value) {
                        active_filters.push({
                            name: this.$options.filters.capitalize(filter_name),
                            value: value
                        })
                    }
                }

                // now we check if there's any change in the filter's value
                // and if the filter is not excluded
                // and the default filter value is undefined
                // and not specifically loading for certain id
                const undefined_default_filter = typeof default_filter_value === 'undefined'
                const filter_has_changes = default_filter_value !== custom_menu_filter_value
                if (!this.excluded_filters.includes(index) &&
                    (undefined_default_filter && filter_has_changes) &&
                    !is_filtered_by_id
                ) {
                    // now, we determine/adjust the filter name
                    let filter_name = ''
                    switch (index) {
                        case 'unread_only':
                            filter_name = 'Unread Conversations'
                            break
                        default:
                            filter_name = index
                            break
                    }

                    active_filters.push({
                        name: this.$options.filters.capitalize(filter_name),
                        value: cleaned_filter[index]
                    })
                }
            }

            VueEvent.fire('update_summary_filter_text', active_filters)
        },

        clearDefaultDateRange() {
            VueEvent.fire('update_summary')
            this.default_date_range = null
        },

        changeNotDisposed(not_disposed) {
            this.custom_menu_filter.not_disposed = not_disposed
            this.custom_menu_filter.disposition_statuses = []

            this.is_filters_applied = false
        },

        changeUnreadOnly(unread_only) {
            this.custom_menu_filter.unread_only = unread_only
            this.custom_menu_filter.disposition_statuses = []

            this.is_filters_applied = false
        },

        ...mapActions(['setFilters', 'resetFilters', 'newFilter', 'updateFilter', 'deleteFilter', 'setFilter', 'updateCampaign', 'setSection']),
        ...mapActions('cache', ['setCurrentCompanyDefaultFilterId'])
    },

    watch: {
        'filter.answer_status': function () {
            this.answer_state = this.filter.answer_status
        },

        'filter.type': function () {
            this.type = this.filter.type
        },

        'filter.transfer_type': function () {
            this.transfer_type = this.filter.transfer_type
        },

        'filter.direction': function () {
            this.direction = this.filter.direction
        },

        'filter.first_time_only': function () {
            this.first_time_only = this.filter.first_time_only
        },

        'filter.untagged_only': function () {
            this.untagged_only = this.filter.untagged_only
        },

        'filter.unread_only': function () {
            this.unread_only = this.filter.unread_only
        },

        'filter.exclude_automated_communications': function () {
            this.exclude_automated_communications = this.filter.exclude_automated_communications
        },

        'filter.creator_type': function () {
            this.creator_type = this.filter.creator_type
        },

        'filter.tags': function () {
            this.filter_tags = this.filter.tags
        },

        'filter.call_dispositions': function () {
            this.filter_call_dispositions = this.filter.call_dispositions
        },

        'filter.min_talk_time': function () {
            this.min_talk_time = this.filter.min_talk_time.toString()
        },

        'filter.campaigns': function () {
            this.filter_campaigns = this.filter.campaigns
        },

        'filter.workflows': function () {
            this.filter_workflows = this.filter.workflows
        },

        'filter.broadcasts': function () {
            this.filter_broadcasts = this.filter.broadcasts
        },

        'filter.ring_groups': function () {
            this.filter_ring_groups = this.filter.ring_groups
        },

        'filter.contact_lists': function () {
            this.filter_contact_lists = this.filter.contact_lists
        },

        'filter.incoming_numbers': function () {
            this.filter_incoming_numbers = this.filter.incoming_numbers
        },

        'filter.users': function () {
            this.filter_users = this.filter.users
        },

        'filter.teams': function () {
            this.filter_teams = this.filter.teams
        },

        'filter.owners': function () {
            this.filter_owners = this.filter.owners
        },

        'filter.changed': function () {
            this.changed = this.filter.changed
        },

        'filter.callback_status': function () {
            this.callback_status = this.filter.callback_status
        },

        'filter.transcription_keyword': function () {
            this.transcription_keyword = this.filter.transcription_keyword
        },

        'filter.transcription_category': function () {
            this.transcription_category = this.filter.transcription_category
        },

        'filter.has_international': function () {
            this.has_international = this.filter.has_international
        },

        'section': function () {
            if (this.section === 'Transcriptions') {
                history.pushState(
                    {},
                    null,
                    this.$route.path + '#' + this.section.toLowerCase()
                )
            } else {
                history.pushState(
                    {},
                    null,
                    this.$route.path.substring(this.$route.path.indexOf("#"))
                )
            }
        },

        $route({params, query, hash}) {
            if (hash === '#transcriptions') {
                this.section = 'Transcriptions'
            } else {
                this.section = 'Activities'
            }
            this.resetOurFilters(false)
        },

        tag_id() {
            setTimeout(() => {
                if (this.tag_id) {
                    this.changeTags([this.tag_id])
                    this.applyFilters(false)
                    VueEvent.fire('change_date_range', 'All Time')
                }
            }, 500)
        },

        call_disposition_id() {
            setTimeout(() => {
                if (this.call_disposition_id) {
                    this.changeCallDispositions([this.call_disposition_id])
                    this.applyFilters(false)
                    VueEvent.fire('change_date_range', 'All Time')
                }
            }, 500)
        },

        campaigns() {
            if (this.campaigns) {
                this.overallFiltersToText()
            }
        },

        workflows() {
            if (this.workflows) {
                this.overallFiltersToText()
            }
        },

        broadcasts() {
            if (this.broadcasts) {
                this.overallFiltersToText()
            }
        },

        ring_groups() {
            if (this.ring_groups) {
                this.overallFiltersToText()
            }
        },

        contact_lists() {
            if (this.contact_lists) {
                this.overallFiltersToText()
            }
        },

        users() {
            if (this.users) {
                this.overallFiltersToText()
            }
        },

        teams() {
            if (this.teams) {
                this.overallFiltersToText()
            }
        },

        call_dispositions() {
            if (this.call_dispositions) {
                this.overallFiltersToText()
            }
        },

        custom_menu_filter: {
            deep: true,
            handler: function () {
            }
        }
    },

    beforeDestroy() {
        clearInterval(this.$options.date_range_interval)
    }
}
</script>
