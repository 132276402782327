export default Object.freeze([
  {
    label: 'USA & Canada',
    value: 'USA_AND_CANADA'
  },
  {
    label: 'Latin America',
    value: 'LATIN_AMERICA'
  },
  {
    label: 'Europe',
    value: 'EUROPE'
  },
  {
    label: 'Asia',
    value: 'ASIA'
  },
  {
    label: 'Africa',
    value: 'AFRICA'
  }
])
