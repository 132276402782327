var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full width-500" },
    [
      _c("div", { staticClass: "login-brand" }, [
        _c("img", { attrs: { src: _vm.statics.logo, alt: "." } }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dashboard-intro mt-3 mb-3" },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-position": "top",
                "label-width": "300px",
                model: _vm.forgot_form,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.forgotPass.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Email", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: { id: "email", type: "email", autocomplete: "on" },
                    model: {
                      value: _vm.forgot_form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.forgot_form, "email", $$v)
                      },
                      expression: "forgot_form.email",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("vue-recaptcha", {
                ref: "recaptcha",
                staticClass: "g-recaptcha",
                attrs: { sitekey: _vm.site_key },
                on: { verify: _vm.onVerify, expired: _vm.onExpired },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "p-x-md btn-block mt-2",
                  attrs: {
                    type: "success",
                    disabled:
                      _vm.loading || !_vm.forgot_form.recaptcha_response,
                  },
                  on: { click: _vm.forgotPass },
                },
                [
                  _c(
                    "i",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "material-icons loader pull-right",
                    },
                    [_vm._v("")]
                  ),
                  _vm._v("\n                Send Reset Link\n                "),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loading,
                        expression: "!loading",
                      },
                    ],
                    staticClass: "el-icon-arrow-right el-icon-right pull-right",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "p-v-lg text-center" }, [
        _c(
          "div",
          [
            _vm._v("\n            Already have an account?\n            "),
            _c(
              "router-link",
              {
                staticClass: "text-greenish _600",
                attrs: { to: { name: "Login" }, exact: "" },
              },
              [_vm._v("\n                Sign in\n            ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("static-assets-preloader", { attrs: { assets: _vm.statics } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }