var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "40vw",
        top: "4vh",
        visible: _vm.modal_visible,
        "append-to-body": true,
        "close-on-click-modal": true,
        "before-close": _vm.handleClose,
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("h5", { staticClass: "mb-0" }, [
          _vm.mode === _vm.MODE_ADD
            ? _c("span", [_vm._v("Add New")])
            : _vm._e(),
          _vm._v(" "),
          _vm.mode === _vm.MODE_EDIT ? _c("span", [_vm._v("Edit")]) : _vm._e(),
          _vm._v("\n            Service\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        { ref: "service_form", staticClass: "form-aloware" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "no-border",
              attrs: { error: _vm.errors.first("name"), prop: "name" },
            },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Name")]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "Name of service" },
                model: {
                  value: _vm.service.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.service, "name", $$v)
                  },
                  expression: "service.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "no-border" },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Duration (Minutes)")]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { placeholder: "15", type: "number" },
                model: {
                  value: _vm.service.duration,
                  callback: function ($$v) {
                    _vm.$set(_vm.service, "duration", $$v)
                  },
                  expression: "service.duration",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "no-border" },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Description")]),
                _vm._v(" "),
                _c("small", [_vm._v("Describe what this service is for.")]),
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { type: "textarea", rows: 2 },
                model: {
                  value: _vm.service.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.service, "description", $$v)
                  },
                  expression: "service.description",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "no-border" },
            [
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Users")]),
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { display: "block!important" },
                  attrs: {
                    placeholder: "Add one or more users",
                    multiple: "",
                    filterable: "",
                    "default-first-option": "",
                    disabled: _vm.loading,
                    "filter-method": _vm.filterOptions,
                  },
                  on: { "visible-change": _vm.resetFilteredText },
                  model: {
                    value: _vm.service.users,
                    callback: function ($$v) {
                      _vm.$set(_vm.service, "users", $$v)
                    },
                    expression: "service.users",
                  },
                },
                [
                  _vm.normalUsers && _vm.normalUsers.length > 0
                    ? _c(
                        "el-option-group",
                        { key: "Users", attrs: { label: "Users" } },
                        _vm._l(_vm.normalUsers, function (user) {
                          return _c(
                            "el-option",
                            {
                              key: user.id,
                              staticClass: "p-0",
                              attrs: { label: user.name, value: user.id },
                            },
                            [
                              _c("div", { staticClass: "media" }, [
                                _c("div", { staticClass: "media-body" }, [
                                  _c("label", [_vm._v(_vm._s(user.name))]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex mt-4" },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("Close"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml-auto",
                  attrs: { type: "success" },
                  on: { click: _vm.handleSave },
                },
                [_c("strong", [_vm._v("Save")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }