import { render, staticRenderFns } from "./graph-data-switch.vue?vue&type=template&id=855dd722"
import script from "./graph-data-switch.vue?vue&type=script&lang=js"
export * from "./graph-data-switch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/app/releases/20250403222514Z/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('855dd722')) {
      api.createRecord('855dd722', component.options)
    } else {
      api.reload('855dd722', component.options)
    }
    module.hot.accept("./graph-data-switch.vue?vue&type=template&id=855dd722", function () {
      api.rerender('855dd722', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/user/components/graph-data-switch.vue"
export default component.exports