<template>
    <el-dialog
        width="35vw"
        top="10vh"
        append-to-body
        title="Contact Assignments"
        :before-close="closeAssignDialog"
        :visible.sync="show_dialog">
        <div v-loading="assigning_contacts">
            <assign-contact
                :value.sync="assigning_form.id"
                :assign_to.sync="assigning_form.assign_to"
                :is_force.sync="assigning_form.force"
                ref="assign_contact">
                <span slot="titleText">
                    Assign Contacts in {{ contactListName }} list to
                </span>
            </assign-contact>

            <div class="container-footer d-flex mb-0 mt-3">
                <el-button
                    size="small"
                    @click="closeAssignDialog">
                    Close
                </el-button>

                <el-button
                    @click="assignContact"
                    :disabled="disableAssignContactButton"
                    type="success"
                    size="small"
                    class="ml-auto">
                    Assign Contact
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import AssignContact from '../assign-contact'

    export default {
        name: "admin-list-assign-contact-form",

        components: {
            AssignContact
        },

        props: {
            contact_list: {}
        },

        data() {
            return {
                assigning_form: {
                    assign_to: null,
                    id: null,
                    force: false
                },
                show_dialog: false,
                assigning_contacts: false,
            }
        },

        computed: {
            disableAssignContactButton () {
                return !this.assigning_form.assign_to || !this.assigning_form.id
            },

            contactListName() {
                return this.contact_list ? this.contact_list.name : ''
            }
        },

        methods: {
            assignContact() {
                this.assigning_contacts = true

                this.$APIV2.ContactList.assignContacts(this.contact_list.id, this.assigning_form)
                    .then(res => {
                        if (res.data.success) {
                            this.assigning_contacts = false

                            this.$notify({
                                offset: 75,
                                title: 'Contact List',
                                message: res.data.message,
                                type: 'success',
                                showClose: true,
                                duration: 5000
                            })

                            setTimeout(_ => {
                                this.closeAssignDialog()
                            }, 200)
                        }
                    })
                    .catch(err => {
                        let status = err.response.status

                        if ([404, 422].includes(status)) {
                            this.$root.handleErrors(err.response)
                        }

                        this.assigning_contacts = false
                    })
            },

            closeAssignDialog() {
                this.show_dialog = false
                this.resetAssigningForm()

                this.$emit('closed')
            },

            resetAssigningForm() {
                // reset the fields of the child assignment form
                if (this.$refs.assign_contact) {
                    this.$refs.assign_contact.resetFields()
                }

                this.assigning_form.id = null
                this.assigning_form.assign_to = null
                this.assigning_form.force = false
            }
        }
    }
</script>
