<template>
    <div>
        <div class="row mb-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div v-if="empty_overall_stats"
                             class="row">
                            <div class="col-12">
                                <el-alert
                                    title="Empty results"
                                    type="info"
                                    description="The current filters didn't match any search result."
                                    data-tes="transcription-reporting-empty-results-alert"
                                    :closable="false"
                                    class="mb-3">
                                </el-alert>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <h5 class="text-greyish mb-0 align-self-end">Overall Stats</h5>
                                <summary-report-card :data="overallStats"
                                                     :chart_type="null"
                                                     :col="4"
                                                     :is_transcription="true"
                                                     data-testid="overall-stats-summary-report-card"
                                                     v-loading="overall_stat_loading"/>
                            </div>
                            <div v-if="sentimentCharts"
                                 class="col-4">
                                <h5 class="text-greyish mb-0 align-self-end">
                                    Agent Sentiment
                                </h5>
                                <highcharts ref="agent_chart"
                                            data-testid="agent-sentiment-highcharts"
                                            :options="sentimentCharts.agent_chart_options"/>
                            </div>
                            <div v-if="sentimentCharts"
                                 class="col-4">
                                <h5 class="text-greyish mb-0 align-self-end">
                                    Customer Sentiment
                                </h5>
                                <highcharts ref="customer_chart"
                                            data-testid="customer-sentiment-highcharts"
                                            :options="sentimentCharts.contact_chart_options"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-12">
                <div class="card">
                    <div class="padding">
                        <div class="row">
                            <div class="col-8">

                                <div class="row">
                                    <div class="col-6">
                                        <h5 class="text-greyish mb-0 align-self-end">
                                            Word Analytics
                                        </h5>
                                    </div>
                                    <div class="col-6 text-right">
                                        <router-link :to="{ name: 'Account', query: { tab: 'transcription-settings' }, hash: '#custom_keywords'}"
                                                     data-testid="word-analytics-router-link"
                                                     v-if="hasRole('Company Admin')">
                                            <el-tooltip v-if="!isEmpty(customKeywordsTable)"
                                                        class="item"
                                                        effect="dark"
                                                        content="Modify your custom keywords"
                                                        data-testid="word-analytics-edit-tooltip"
                                                        placement="bottom">
                                                <el-button size="mini"
                                                           data-testid="word-analytics-edit-button">
                                                    Edit
                                                </el-button>
                                            </el-tooltip>
                                            <el-tooltip v-else
                                                        class="item"
                                                        effect="dark"
                                                        content="Add your custom keywords"
                                                        data-testid="word-analytics-add-tooltip"
                                                        placement="bottom">
                                                <el-button size="mini"
                                                           type="success"
                                                           data-testid="word-analytics-add-button">
                                                    Add
                                                </el-button>
                                            </el-tooltip>
                                        </router-link>
                                    </div>
                                </div>

                                <el-row>
                                        <el-col :span="7" style="padding-left: 0!important;padding-right: 0!important;">
                                            <el-table v-loading="custom_keyword_table_loading"
                                                      :data="customKeywordsTable"
                                                      :default-sort="{prop: 'value', order: 'descending'}"
                                                      style="width: 100%"
                                                      data-testid="word-analytics-table"
                                                      height="400">
                                                    <template slot="empty">{{ empty_keywords_text }}</template>
                                                <el-table-column prop="name"
                                                                 label="Keywords"
                                                                 sortable
                                                                 align="center"
                                                                 data-testid="word-analytics-name-column"
                                                                 header-align="center"/>
                                                <el-table-column prop="value"
                                                                 label="Occurrences"
                                                                 sortable
                                                                 align="center"
                                                                 data-testid="word-analytics-value-column"
                                                                 header-align="center"/>
                                            </el-table>
                                        </el-col>
                                        <el-col :span="10">
                                            <highcharts ref="custom_keyword_chart"
                                                        data-testid="word-analytics-highcharts"
                                                        :options="customKeywords"
                                                        :loading="loading"/>
                                        </el-col>
                                        <el-col :span="7" style="padding-left: 0!important;padding-right: 0!important;">
                                            <el-table v-loading="custom_keyword_table_loading"
                                                      :data="highlights"
                                                      :default-sort="{prop: 'value', order: 'descending'}"
                                                      style="width: 100%"
                                                      data-testid="word-analytics-table-1"
                                                      height="400">
                                                <template slot="empty">{{ empty_highlights_text }}</template>
                                                <el-table-column prop="name"
                                                                 label="Highlights"
                                                                 sortable
                                                                 align="center"
                                                                 style="font-size: 10px!important;color: blue"
                                                                 data-testid="word-analytics-name-column-1"
                                                                 header-align="center"/>
                                                <el-table-column prop="value"
                                                                 label="Occurrences"
                                                                 sortable
                                                                 align="center"
                                                                 data-testid="word-analytics-value-column-1"
                                                                 header-align="center"/>
                                            </el-table>
                                        </el-col>
                                    </el-row>
                            </div>
                            <div class="col-4">
                                <h5 class="text-greyish mb-0 align-self-end">
                                    Top Categories
                                </h5>
                                <highcharts ref="top_categories_chart"
                                            :loading="loading"
                                            data-testid="top-categories-highcharts"
                                            :options="top5Categories"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-12">
                <div class="card">
                    <div class="padding">
                        <div class="row">
                            <div class="col-12">
                                <h5 class="text-greyish mb-0 align-self-end">
                                    Transcription History
                                </h5>
                            </div>
                            <div class="col-12">
                                <p class="mb-0">
                                    {{ getTranscriptionHistoryCount }} transcriptions
                                </p>
                            </div>
                            <div class="col-12">
                                <el-input clearable
                                          v-model="q"
                                          placeholder="Look up a word"
                                          ref="saveTagInput"
                                          size="medium"
                                          style="width: 250px"
                                          data-testid="transcription-history-search-input"
                                          @change="onEnter"
                                          @clear="fetchHistoryTable">
                                    <i slot="suffix"
                                       class="el-input__icon el-icon-search"/>
                                </el-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <el-table :data="getTranscriptionHistory"
                                          v-loading="history_table_loading"
                                          class="w-full"
                                          data-testid="transcription-history-table"
                                          fit
                                          stripe>
                                    <template slot="empty">No Transcriptions to display.</template>
                                    <el-table-column width="55"
                                                     data-testid="transcription-history-column">
                                        <template v-slot="scope">
                                            <el-tooltip class="item"
                                                        effect="dark"
                                                        :content="dispositionTooltipData(scope.row.communication.disposition_status2, scope.row.communication.direction, scope.row.communication.type, scope.row.communication.callback_status)"
                                                        data-testid="transcription-history-column-tooltip"
                                                        placement="right">
                                                <router-link :to="{ name: 'Communication', params: {communication_id: scope.row.communication.id }}"
                                                             data-testid="transcription-history-column-router-link">
                                                    <div :state="scope.row.communication.disposition_status2"
                                                         class="status-icon centered-content"
                                                         v-html="stateToIcon(scope.row.communication.disposition_status2, scope.row.communication.direction, scope.row.communication.type, scope.row.communication.callback_status)">
                                                    </div>
                                                </router-link>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Agent"
                                                     data-testid="transcription-history-agent-column"
                                                     min-width="162">
                                        <template v-slot="scope">
                                            <div class="row">
                                                <div v-if="scope.row.agent"
                                                     class="col-12 d-flex">
                                                    <router-link :to="{ name: 'User Activity', params: { user_id: scope.row.agent.id }}"
                                                                 data-testid="user-activity-router-link">
                                                        <span :title="scope.row.agent.full_name"
                                                              class="text-dark-greenish">
                                                            {{ scope.row.agent.full_name }}
                                                        </span>
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 d-flex">
                                                    <router-link :to="{ name: 'Line Activity', params: { campaign_id: scope.row.communication.campaign_id }}"
                                                                 data-testid="line-activity-router-link"
                                                                 v-if="scope.row.communication.campaign_id">
                                                        <a v-if="getCampaign(scope.row.communication.campaign_id)"
                                                           data-testid="user-activity-link"
                                                           class="text-dark-greenish">
                                                            {{ getCampaign(scope.row.communication.campaign_id).name }}
                                                        </a>
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div v-if="scope.row.communication.type !== 5"
                                                     class="col-12 d-flex">
                                                    <span class="text-greyish">
                                                        {{ scope.row.communication.incoming_number | fixPhone }}
                                                    </span>
                                                </div>
                                                <div v-else
                                                     class="col-12 d-flex">
                                                    <span class="text-greyish">
                                                        {{ scope.row.communication.incoming_number }}
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        label="Talk Time"
                                        prop="talk_time"
                                        data-testid="transcription-history-talk-time-column"
                                        width="120">
                                        <template v-slot="scope">
                                            <div class="row"
                                                v-if="scope.row.communication.type === CommunicationTypes.CALL">
                                                <div class="col-12 d-flex">
                                                    <span>
                                                        {{ scope.row.communication.talk_time | fixDuration }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 d-flex">
                                                    <span>{{ getVisibleStatus(scope.row.communication) }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        label="Duration"
                                        prop="duration"
                                        data-testid="transcription-history-duration-column"
                                        width="110">
                                        <template v-slot="scope">
                                            <div class="row"
                                                v-if="![CommunicationTypes.SMS, CommunicationTypes.EMAIL].includes(scope.row.communication.type)">
                                                <div class="col-12 d-flex">
                                                    <span>{{ scope.row.communication.duration | fixDuration }}</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 d-flex">
                                                    <span>{{ getVisibleStatus(scope.row.communication) }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Contact"
                                                     data-testid="transcription-history-contact-column"
                                                     min-width="162">
                                        <template v-slot="scope">
                                            <div class="row d-flex align-items-center">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div v-if="scope.row.contact"
                                                            class="col-12 d-flex">
                                                            <a target="_blank"
                                                               :href="getContactTalkRedirectURL(scope.row.contact.id)">
                                                                <el-tooltip class="item pull-left"
                                                                            effect="dark"
                                                                            content="Click For More Info"
                                                                            data-testid="more-info-contact-tooltip"
                                                                            placement="top">
                                                                                <span class="text-dark-greenish"
                                                                                      :title="scope.row.contact.name | fixContactName">
                                                                                      {{ scope.row.contact.name | fixContactName }}
                                                                                </span>
                                                                </el-tooltip>
                                                                <i v-if="scope.row.communication.first_time_caller"
                                                                   class="on b-white bottom caller-status pull-left"
                                                                   title="First Time Conversation"/>
                                                            </a>
                                                        </div>
                                                        <div v-else
                                                             class="col-12 d-flex">
                                                            <span class="text-dark-greenish pull-left">
                                                                {{ scope.row.communication.lead_number | fixPhone }}
                                                            </span>
                                                            <i v-if="scope.row.communication.first_time_caller"
                                                               class="on b-white bottom caller-status pull-left"
                                                               title="First Time Conversation"/>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 d-flex">
                                                            <span class="text-greyish">
                                                                {{ scope.row.communication.lead_number | fixPhone }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div v-if="scope.row.contact && scope.row.contact.company_name"
                                                         class="row">
                                                        <div class="col-12 d-flex text-pink-500 text-xs">
                                                            <i class="material-icons">business_center</i>
                                                            <span class="ml-1">
                                                                {{ scope.row.contact.company_name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Start Time"
                                                     data-testid="transcription-history-start-time-column"
                                                     prop="created_at"
                                                     min-width="162">
                                        <template v-slot="scope">
                                            <span class="text-greyish">
                                                {{ scope.row.communication.created_at | fixDateTime }}
                                            </span>
                                            <div v-if="scope.row.communication.call_disposition_id"
                                                 class="row">
                                                <div class="col-12 d-flex text-xs">
                                                    <i class="fa fa-bolt"
                                                       :style="{ color: $options.filters.callDispositionColor(scope.row.call_disposition_id) }"/>
                                                    <span class="ml-1 text-grey-900">
                                                        {{ scope.row.communication.call_disposition_id | callDispositionName }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row mt-2"
                                                 v-if="scope.row.communication.has_recording || scope.row.recording_is_deleted">
                                                <div class="col-12">
                                                    <communication-audio :communication="scope.row.communication"
                                                                         data-testid="has-recording-communication-audio"
                                                                         :type="UploadedFileTypes.TYPE_CALL_RECORDING"/>
                                                </div>
                                            </div>
                                            <div class="row mt-2"
                                                 v-if="scope.row.communication.has_voicemail">
                                                <div class="col-12">
                                                    <communication-audio :communication="scope.row.communication"
                                                                         data-testid="has-voiceemail-communication-audio"
                                                                         :type="UploadedFileTypes.TYPE_CALL_VOICEMAIL"/>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Agent Sentiment"
                                                     data-testid="transcription-history-agent-sentiment-column"
                                                     min-width="162">
                                        <template v-slot="scope">
                                            <span class="text-greyish">
                                                <span :class="sentiment_colors[scope.row.agent_sentiment]">
                                                    {{ scope.row.agent_sentiment }}
                                                </span>
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Contact Sentiment"
                                                     data-testid="transcription-history-contact-sentiment-column"
                                                     min-width="162">
                                        <template v-slot="scope">
                                            <span class="text-greyish">
                                                <span :class="sentiment_colors[scope.row.contact_sentiment]">
                                                    {{ scope.row.contact_sentiment }}
                                                </span>
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Operations"
                                                     data-testid="transcription-history-operations-column"
                                                     width="250">
                                        <template v-slot="scope">
                                            <el-tooltip class="item"
                                                        effect="dark"
                                                        content="Call Details"
                                                        data-testid="transcription-history-operations-tooltip"
                                                        placement="bottom">
                                                <button class="btn btn-sm greyish btn-icon opaq"
                                                        data-testid="go-to-communication-info-button"
                                                        @click="gotoCommunicationInfo(scope.row.communication.id)">
                                                    <i class="material-icons">info_outline</i>
                                                </button>
                                            </el-tooltip>
                                            <communication-transcription-button class="d-inline"
                                                                               v-if="!scope.row.communication.transcription_is_deleted && scope.row.communication.has_transcription"
                                                                               :communication="scope.row.communication"
                                                                               :button_text="'Show Transcription'"
                                                                               :single_button="true"/>
                                        </template>
                                    </el-table-column>

                                </el-table>
                            </div>
                            <div class="col-12">
                                <el-pagination background
                                               class="pull-right pt-4"
                                               layout="sizes, prev, pager, next"
                                               :hide-on-single-page="true"
                                               :page-sizes="page_sizes"
                                               :page-size.sync="max_per_page"
                                               :total="getTranscriptionHistoryCount"
                                               :page-count="getTotalPages"
                                               :current-page.sync="transcription_page"
                                               data-testid="transcription-reporting-components-pagination"
                                               @current-change="handleCurrentChange"
                                               @size-change="handleSizeChange"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import * as CommunicationTypes from '../constants/communication-types'
import {
    acl_mixin,
    contact_talk_mixin,
    communication_mixin,
    communication_info_mixin,
    report_mixin
} from '../mixins'
import * as AnswerTypes from '../constants/answer-types'
import * as UploadedFileTypes from '../constants/uploaded-file-types'
import * as CommunicationCurrentStatus from '../constants/communication-current-status'
import * as CommunicationDispositionStatus from '../constants/communication-disposition-status'
import _ from "lodash"

export default {
    props: {
        campaign_id: {
            default: null,
            required: false,
        },
        user_id: {
            default: null,
            required: false,
        },
        ring_group_id: {
            default: null,
            required: false,
        },
        broadcast_id: {
            default: null,
            required: false,
        }
    },

    mixins: [
        acl_mixin,
        contact_talk_mixin,
        communication_mixin,
        communication_info_mixin,
        report_mixin
    ],

    data() {
        return {
            colors: [
                '#72bcd4',
                '#86c5da',
                '#99cfe0',
                '#add8e6',
                '#c1e1ec',
                '#d4ebf2'
            ],
            bubble_colors: [
                '#4ee44e',
                '#64e764',
                '#7aeb7a',
                '#90ee90',
                '#a6f1a6',
                '#bcf5bc'
            ],
            highlight_colors: [
                '#b34600',
                '#cc5000',
                '#e65a00',
                '#ff6400',
                '#ff741a',
                '#ff8333',
                '#ff934d',
            ],
            sentiment_colors: {
                'POSITIVE': 'text-green-500',
                'NEGATIVE': 'text-red-500',
                'NEUTRAL': 'text-dark-500',
            },
            q: '',
            max_per_page: 20,
            page_sizes: [10, 20, 30, 40, 50],
            loading: true,
            show_custom_keywords: true,
            sentiment_chart: [],
            transcription_reporting_data: [],
            transcription_history_data: [],
            transcription_source: null,
            transcription_history_source: null,
            custom_keyword_table_loading: true,
            overall_stat_loading: true,
            history_table_loading: true,
            empty_overall_stats: false,
            empty_keywords_text: 'No Custom Keywords',
            empty_highlights_text: 'No Highights',
            CommunicationTypes,
            AnswerTypes,
            UploadedFileTypes,
            CommunicationCurrentStatus,
            CommunicationDispositionStatus
        }
    },

    computed: {
        ...mapState({
            campaigns: (state) => state.campaigns,
            section: (state) => state.section,
        }),

        ...mapGetters({
            filter: 'getFilter',
        }),

        transcription_page: {
            get() {
                return this.getTranscriptionPage()
            },
            set(page) {
                this.setTranscriptionPage(page)
            },
        },

        highlights() {
            return this.mapHighlight()
        },

        overallStats() {
            let overall_stats = null
            let transcriptions_overall_stats = this.transcription_reporting_data?.overall_stats
            if (transcriptions_overall_stats) {
                overall_stats = {
                    title: 'Overall Stats',
                    data: [
                        {
                            label: 'Transcribed calls',
                            count: transcriptions_overall_stats?.calls ?? 0,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true,
                        },
                        {
                            label: 'Custom Keyword Occurrences',
                            count: transcriptions_overall_stats?.custom_keyword_occurrence ?? 0,
                            is_seconds: false,
                            show_in_chart: false,
                            clickable: true,
                        },
                        {
                            label: 'Avg. Talk Time',
                            count: transcriptions_overall_stats?.avg_talk_time ?? 0,
                            is_seconds: true,
                            show_in_chart: true,
                            clickable: true,
                        },
                        {
                            label: 'Agent Negative Calls',
                            count: transcriptions_overall_stats?.call_speaker_sentiment?.agent?.NEGATIVE ?? 0,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true,
                        },
                        {
                            label: 'Agent Positive Calls',
                            count: transcriptions_overall_stats?.call_speaker_sentiment?.agent?.POSITIVE ?? 0,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true,
                        },
                        {
                            label: 'Agent Talk Time Ratio',
                            count: (transcriptions_overall_stats?.speakers_talk_time_ratio.agent) * 100 ?? 0,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true,
                            percentage_format: true
                        },
                        {
                            label: 'Contact Negative Calls',
                            count: transcriptions_overall_stats?.call_speaker_sentiment?.contact?.NEGATIVE ?? 0,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true,
                        },
                        {
                            label: 'Contact Positive Calls',
                            count: transcriptions_overall_stats?.call_speaker_sentiment?.contact?.POSITIVE ?? 0,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true,
                        },
                        {
                            label: 'Contact Talk Time Ratio',
                            count: (transcriptions_overall_stats?.speakers_talk_time_ratio.contact) * 100 ?? 0,
                            is_seconds: false,
                            show_in_chart: true,
                            clickable: true,
                            percentage_format: true
                        },
                    ],
                }
                if(!transcriptions_overall_stats?.calls) {
                    this.empty_overall_stats = true;
                } else {
                    this.empty_overall_stats = false;
                }
                return overall_stats.data
            }
            return []
        },

        sentimentCharts() {
            let transcriptions_speaker_sentiment = this.sentiment_chart.speaker
            let transcriptions_company_sentiment = this.sentiment_chart.company
            let agent_chart_options = {
                chart: {
                    type: 'column',
                },
                title: {
                    text: null,
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    title: {
                        text: '<b>Sentiment (%)</b>',
                    },
                },
                xAxis: {
                    categories: ['Positive', 'Neutral', 'Negative'],
                    crosshair: true,
                },
                tooltip: {
                    enabled: true,
                    formatter: function () {
                        if (this.series.name == 'Average of Peers') {
                            return 'Total <b>' + this.x + '</b> transcribed calls for the last 3 months'
                        } else if (this.series.name == 'Agent') {
                            return 'Total <b>' + this.x + '</b> transcribed calls for the selected filters'
                        }
                    },
                },
                exporting: {
                    enabled: false,
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        groupPadding: 0.1,
                        pointPadding: 0.1,
                        dataLabels: {
                            allowOverlap: true,
                            enabled: true,
                            format: '{point.y}%',
                        },
                    },
                },
                series: [
                    {
                        name: 'Agent',
                        color: '#3b66e4',
                        data: [
                            transcriptions_speaker_sentiment?.AGENT?.ratio?.POSITIVE,
                            transcriptions_speaker_sentiment?.AGENT?.ratio?.NEUTRAL,
                            transcriptions_speaker_sentiment?.AGENT?.ratio?.NEGATIVE
                        ],
                    },
                    {
                        name: 'Average of Peers',
                        color: '#ea4536',
                        data: [
                            !this.isEmpty(transcriptions_speaker_sentiment) ? transcriptions_company_sentiment?.AGENT?.ratio?.POSITIVE: 0,
                            !this.isEmpty(transcriptions_speaker_sentiment) ? transcriptions_company_sentiment?.AGENT?.ratio?.NEUTRAL : 0,
                            !this.isEmpty(transcriptions_speaker_sentiment) ? transcriptions_company_sentiment?.AGENT?.ratio?.NEGATIVE : 0
                        ],
                    },
                ],
            }
            let contact_chart_options = {
                chart: {
                    type: 'column',
                },
                title: {
                    text: null,
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    title: {
                        text: '<b>Sentiment (%)</b>',
                    },
                },
                xAxis: {
                    categories: ['Positive', 'Neutral', 'Negative'],
                    crosshair: true,
                },
                tooltip: {
                    enabled: true,
                    formatter: function () {
                        if (this.series.name == 'Average of Peers') {
                            return 'Total <b>' + this.x + '</b> transcribed calls for the last 3 months'
                        } else if (this.series.name == 'Customer') {
                            return 'Total <b>' + this.x + '</b> transcribed calls for the selected filters'
                        }
                    },
                },
                exporting: {
                    enabled: false,
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        groupPadding: 0.1,
                        pointPadding: 0.1,
                        dataLabels: {
                            allowOverlap: true,
                            enabled: true,
                            format: '{point.y}%',
                        },
                    },
                },
                series: [
                    {
                        name: 'Customer',
                        color: '#3b66e4',
                        data: [
                            transcriptions_speaker_sentiment?.CONTACT?.ratio?.POSITIVE,
                            transcriptions_speaker_sentiment?.CONTACT?.ratio?.NEUTRAL,
                            transcriptions_speaker_sentiment?.CONTACT?.ratio?.NEGATIVE
                        ],
                    },
                    {
                        name: 'Average of Peers',
                        color: '#ea4536',
                        data: [
                            !this.isEmpty(transcriptions_speaker_sentiment) ? transcriptions_company_sentiment?.CONTACT?.ratio?.POSITIVE : 0,
                            !this.isEmpty(transcriptions_speaker_sentiment) ? transcriptions_company_sentiment?.CONTACT?.ratio?.NEUTRAL : 0,
                            !this.isEmpty(transcriptions_speaker_sentiment) ? transcriptions_company_sentiment?.CONTACT?.ratio?.NEGATIVE : 0,
                        ],
                    },
                ],
            }
            return {
                agent_chart_options,
                contact_chart_options,
            }
        },

        customKeywords() {
            let custom_keywords_frequency = this.transcription_reporting_data?.custom_keywords_frequency
            let highlights_frequency = this.mapHighlight()
            let keyword_series = []

            if (custom_keywords_frequency) {
                for (let [idx, serie] of custom_keywords_frequency.entries()) {
                    if (serie.value == 0) {
                        continue
                    }

                    let keyword = {
                        id: serie.name,
                        name: this.capitalizeFirstLetter(serie.name),
                        value: serie.value,
                        type: 'custom' // use in filters
                    }

                    // summarize keyword name
                    keyword.name = this.summarizeText(keyword.name)

                    // if we have enough colors, pick by id
                    if (idx < this.bubble_colors.length) {
                        keyword.color = this.bubble_colors[idx]
                    } else {
                        // otherwise pick random color
                        keyword.color = this.bubble_colors[Math.floor(Math.random() * this.bubble_colors.length)]
                    }

                    keyword_series.push(keyword)
                }
            }

            // sanity check for highlights
            if (highlights_frequency) {

                let formatted_highlight = []

                const _this = this
                highlights_frequency.forEach(function (highlight, index) {
                    // summarize highlight name
                    highlight.name = _this.summarizeText(highlight.name)
                    // add highlight as a type
                    highlight.type = 'highlight' // use in filters

                    formatted_highlight[index] = highlight
                })

                keyword_series = keyword_series.concat(formatted_highlight)
            }

            if (keyword_series.length === 0) {
                this.show_custom_keywords = false
                this.empty_keywords_text = ''
                this.empty_highlights_text = ''
            } else {
                this.empty_keywords_text = 'No Custom Keywords'
                this.empty_highlights_text = 'No Highlights'
                this.show_custom_keywords = true
            }

            const _this = this

            let bubble_chart_options = {
                chart: {
                    type: 'packedbubble',
                },
                title: {
                    text: null,
                },
                tooltip: {
                    useHTML: true,
                    headerFormat: '',
                    pointFormat: '<b>{point.id}:</b> {point.value}'
                },
                legend: {
                    enabled: false,
                },
                exporting: {
                    enabled: false,
                },
                plotOptions: {
                    packedbubble: {
                        showInLegend: true,
                        allowPointSelect: true,
                        minSize: '50%',
                        maxSize: '91%',
                        zMin: 0,
                        zMax: 100,
                        layoutAlgorithm: {
                            splitSeries: false,
                            gravitationalConstant: 0.01,
                            seriesInteraction: true,
                            dragBetweenSeries: false,
                            parentNodeLimit: true,
                            enableSimulation: false
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{point.name} <br> {point.value}',
                            style: {
                                color: 'black',
                                textOutline: 'none',
                                fontWeight: 'bold',
                            },
                        },
                        states: {
                            select: {
                                color: 'red',
                                borderColor: 'black',
                                dashStyle: 'dot'
                            }
                        },
                        point: {
                            events: {
                                click: function () {
                                    _this.filter.transcription_keyword = {'value' : this.id, 'type' : this.type}
                                    _this.filter.changed = true
                                    _this.fetchSentimentCharts()
                                    _this.fetchHistoryTable()
                                    _this.getTranscriptionReportingPanelData()
                                }
                            }
                        }
                    },
                },
                series: [
                    {
                        colorByPoint: true,
                        data: keyword_series,
                    },
                ],
                lang: {
                    noData: "No Custom Keywords' or Highlights' frequency to display."
                },
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                }
            }
            return bubble_chart_options
        },

        customKeywordsTable() {
            let custom_keywords_frequency = this.transcription_reporting_data?.custom_keywords_frequency
            // sanity check
            if (!custom_keywords_frequency) {
                return []
            }
            let keyword_series = []
            for (let [idx, serie] of custom_keywords_frequency.entries()) {
                let keyword = {
                    name: serie.name,
                    value: serie.value,
                }
                keyword_series.push(keyword)
            }
            return keyword_series
        },

        top5Categories() {
            let top_categories = this.transcription_reporting_data?.top_categories
            const _this = this
            // sanity check
            if (!top_categories) {
                return {}
            }
            let categories_series = []
            for (let [idx, serie] of top_categories.entries()) {
                let category = {
                    id: serie.name,
                    name: serie.name,
                    y: serie.value,
                }
                if (idx < 5) {
                    category.color = this.colors[idx]
                }
                categories_series.push(category)
            }

            let top_5_chart_options = {
                chart: {
                    type: 'column',
                },
                title: {
                    text: null,
                },
                xAxis: {
                    type: 'category',
                },
                yAxis: {
                    title: {
                        text: 'Frequency',
                    },
                },
                legend: {
                    enabled: false,
                },
                exporting: {
                    enabled: false,
                },
                plotOptions: {
                    column: {
                        showInLegend: true,
                        allowPointSelect: true,
                        cursor: 'pointer',
                        states: {
                            select: {
                                color: 'red',
                                borderColor: 'black',
                                dashStyle: 'dot'
                            }
                        }
                    },
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}',
                        },
                        point: {
                            events: {
                                click: function () {
                                    _this.filter.transcription_category = this.name
                                    _this.filter.changed = true
                                    _this.fetchSentimentCharts()
                                    _this.fetchHistoryTable()
                                    _this.getTranscriptionReportingPanelData()
                                }
                            }
                        }
                    },
                },
                tooltip: {
                    enabled: true,
                    formatter: function () {
                        return 'This category appears in <b>' + this.y + '</b> transcriptions'
                    },
                },
                series: [
                    {
                        name: 'Category',
                        colorByPoint: true,
                        data: categories_series,
                    },
                ],
                lang: {
                    noData: "No Categories to display."
                },
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                }
            }
            return top_5_chart_options
        },

        getTranscriptionHistory() {
            return this.transcription_history_data?.data ?? []
        },

        getTranscriptionHistoryCount() {
            return this.transcription_history_data?.count ?? 0
        },

        getTotalPages() {
            return (this.transcription_history_data?.count ?? 0) / this.max_per_page
        },
    },

    mounted() {
        this.transcription_source = this.CancelToken.source()
        this.transcription_history_source = this.CancelToken.source()
        this.fetchSentimentCharts()
        this.getTranscriptionReportingPanelData()
        this.fetchHistoryTable()
    },

    beforeDestroy() {
        this.$store.commit('SET_SECTION', 'Activities')
    },

    created() {
        VueEvent.listen('content_resized', () => {
            setTimeout(() => {
                this.$refs.top_categories_chart.chart.reflow()
            }, 50)
        })
    },

    methods: {
        ...mapGetters(['getTranscriptionPage']),
        ...mapActions(['setTranscriptionHistory', 'setTranscriptionPage']),

        onEnter() {
            this.handleOnEnter()
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        summarizeText(text ,length = 8) {
            // find the words with more than 8 characters
            if (text.length > length) {
                // summarize it by keeping the first 5 characters
                return text.slice(0, 5) + "..."
            }

            return text
        },

        fetchSentimentCharts() {
            this.$refs.agent_chart.chart.showLoading()
            this.$refs.customer_chart.chart.showLoading()
            this.$refs.top_categories_chart.chart.showLoading()
            this.source.cancel('fetchSentimentCharts canceled by the user.')
            this.source = this.CancelToken.source()

            let params = {...this.filter}

            if (this.campaign_id) {
                params.campaign_id = this.campaign_id
            }
            if (this.user_id) {
                params.user_id = this.user_id
            }
            if (this.ring_group_id) {
                params.ring_group_id = this.ring_group_id
            }
            if (this.broadcast_id) {
                params.broadcast_id = this.broadcast_id
            }

            delete params.timezone

            axios.get(`/api/v1/transcription/report/sentiment-chart`, {
                params,
                cancelToken: this.source.token,
            }).then((res) => {
                this.sentiment_chart = res.data
            }).catch((err) => {
                if (err.response && err.response.status === 403) {
                    this.$refs.agent_chart.chart.hideLoading()
                    this.$refs.customer_chart.chart.hideLoading()
                    this.$refs.top_categories_chart.chart.hideLoading()
                    return
                }

                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message)
                } else {
                    console.log(err)
                }
            })
        },

        // format highlights
        mapHighlight() {

            let highlights_frequency = this.transcription_reporting_data?.highlights
            let formatted_highlights = []

            // sanity check
            if (!highlights_frequency){
                return formatted_highlights;
            }

            for (let [idx, serie] of highlights_frequency.entries()) {
                // ignore hightlights with zero frequency
                if (serie.value == 0) {
                    continue
                }
                let keyword = {
                    id: serie.name,
                    name: this.capitalizeFirstLetter(serie.name),
                    value: serie.value,
                }

                // if we have enough colors, pick by id
                if (idx < this.colors.length) {
                    keyword.color = this.colors[idx]
                } else {
                    // otherwise pick last color
                    keyword.color = this.colors.at(-1)
                }

                formatted_highlights.push(keyword)
            }

            return formatted_highlights
        },

        getTranscriptionReportingPanelData() {
            this.custom_keyword_table_loading = true
            this.overall_stat_loading = true
            this.loading = true
            this.show_custom_keywords = true
            this.$refs.custom_keyword_chart.chart.showLoading()
            this.transcription_source.cancel('getTranscriptionReportingPanelData canceled by the user.')
            this.transcription_source = this.CancelToken.source()

            let params = {...this.filter}

            if (this.campaign_id) {
                params.campaign_id = this.campaign_id
            }
            if (this.user_id) {
                params.user_id = this.user_id
            }
            if (this.ring_group_id) {
                params.ring_group_id = this.ring_group_id
            }
            if (this.broadcast_id) {
                params.broadcast_id = this.broadcast_id
            }

            delete params.timezone

            axios.get(`/api/v1/transcription/report`, {
                params,
                cancelToken: this.transcription_source.token,
            }).then((res) => {
                this.custom_keyword_table_loading = false
                this.loading = false
                this.overall_stat_loading = false
                this.transcription_reporting_data = res.data.reporting_panel_data

                if(this.transcription_reporting_data?.overall_stats?.calls === 0) {
                    this.empty_overall_stats = true;
                }
            }).catch((err) => {
                if (err.response && err.response.status === 403) {
                    this.custom_keyword_table_loading = false
                    this.loading = false
                    this.overall_stat_loading = false
                    this.$refs.custom_keyword_chart.chart.hideLoading()
                    return
                }

                if (axios.isCancel(err)) {
                    console.log('Request canceled', err)
                } else {
                    console.log(err)
                }
            })
        },

        handleOnEnter() {
            this.setTranscriptionPage(1)
            this.fetchHistoryTable()
        },

        handleCurrentChange(val) {
            this.setTranscriptionPage(parseInt(val))
            this.fetchHistoryTable()
        },

        fetchHistoryTable() {
            this.history_table_loading = true
            this.transcription_history_source.cancel('fetchHistoryTable canceled by the user.')
            this.transcription_history_source = this.CancelToken.source()

            let params = {...this.filter}

            delete params.timezone

            axios.get(`/api/v1/transcription/report/history`, {
                params: {
                    ...params,
                    page: this.transcription_page,
                    count: this.max_per_page,
                    q: this.q,
                },
                cancelToken: this.transcription_history_source.token,
            }).then((res) => {
                this.transcription_history_data = res.data
                this.history_table_loading = false
            }).catch((err) => {
                if (err.response && err.response.status === 403) {
                    this.history_table_loading = false
                    return
                }

                this.$root.handleErrors(err.response)
            })
        },

        checkSize() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        getCampaign(id) {
            if (!id) {
                return null
            }
            let found = this.campaigns.find(campaign => campaign.id === id)
            if (found) {
                return found
            }
            return null
        },

        handleSizeChange(val) {
            this.setTranscriptionPage(1)
            this.max_per_page = val
            this.fetchHistoryTable()
        },

        gotoCommunicationInfo(communication_id) {
            this.$router.push({name: 'Communication', params: {communication_id: communication_id}})
        },

        sleep(time) {
            return new Promise((resolve) => setTimeout(resolve, time))
        },

        getVisibleStatus(communication) {
            if (communication.current_status2 != CommunicationCurrentStatus.CURRENT_STATUS_COMPLETED_NEW) {
                return this.$options.filters.capitalize(this.$options.filters.replaceDash(this.$options.filters.translateCurrentStatusText(communication.current_status2)))
            }

            return this.$options.filters.capitalize(this.$options.filters.replaceDash(this.$options.filters.translateDispositionStatusText(communication.disposition_status2)))
        },

        /**
         * Encapsulates lodash _.isEmpty() function.
         * @public
         *
         * @param {Object} data
         *
         * @returns {boolean}
         */
        isEmpty(data) {
            return _.isEmpty(data)
        },
    },
    watch: {
        top5Categories(newQuestion, oldQuestion) {
            this.sleep(100).then(() => {

                const category = this.filter.transcription_category
                const selected_item = this.$refs.top_categories_chart.chart.get(category)

                if (category && selected_item) {
                    selected_item.selected = true
                }
                this.$refs.top_categories_chart.chart.redraw()
            })
        },
        customKeywords(newQuestion, oldQuestion) {
            this.sleep(100).then(() => {
                const keyword = this.filter.transcription_keyword

                // sanity check: we don't have any selected keyword at first
                if (!keyword) {
                    return
                }

                const selected_item = this.$refs.custom_keyword_chart.chart.get(keyword.value)

                if (selected_item) {
                    selected_item.selected = true
                }

                this.$refs.custom_keyword_chart.chart.redraw()
            })
        }
    },

}
</script>
