<template>
    <div class="w-full width-500">
        <div class="login-brand">
            <img :src="statics.logo"
                 alt="."/>
        </div>

        <div class="dashboard-intro mt-3 mb-3">
            <el-form label-position="top"
                     label-width="300px"
                     :model="forgot_form"
                     @submit.prevent.native="forgotPass">
                <el-form-item label="Email"
                              prop="email">
                    <el-input id="email"
                              type="email"
                              v-model="forgot_form.email"
                              autocomplete="on">
                    </el-input>
                </el-form-item>

                <vue-recaptcha ref="recaptcha"
                               class="g-recaptcha"
                               :sitekey="site_key"
                               @verify="onVerify"
                               @expired="onExpired">
                </vue-recaptcha>

                <el-button type="success"
                           @click="forgotPass"
                           :disabled="loading || !forgot_form.recaptcha_response"
                           class="p-x-md btn-block mt-2">
                    <i class="material-icons loader pull-right"
                       v-show="loading">&#xE863;</i>
                    Send Reset Link
                    <i class="el-icon-arrow-right el-icon-right pull-right"
                       v-show="!loading"></i>
                </el-button>
            </el-form>
        </div>

        <div class="p-v-lg text-center">
            <div>
                Already have an account?
                <router-link :to="{ name: 'Login' }"
                             class="text-greenish _600"
                             exact>
                    Sign in
                </router-link>
            </div>
        </div>

        <static-assets-preloader :assets="statics"></static-assets-preloader>
    </div>
</template>

<script>
    import auth from '../auth'
    import {VueRecaptcha} from "vue-recaptcha"

    export default {
        components: {
            'vue-recaptcha': VueRecaptcha,
        },

        props: {
            statics: {required: true}
        },

        data() {
            return {
                forgot_form: {
                    email: null,
                    recaptcha_response: null,
                },
                password: null,
                loading: false,
                register_status: false,
                site_key: localStorage.getItem('recaptcha_site_key'),
            }
        },

        created() {
            this.register_status = localStorage.getItem('register_status')
        },

        methods: {
            reset() {
                this.error = false
            },

            forgotPass(event) {
                event.preventDefault()
                this.loading = true
                auth.forgotPass(this.forgot_form.email, this.forgot_form.recaptcha_response)
                    .then(res => {
                        this.$notify({
                            title: 'Auth',
                            message: res.data.data,
                            type: 'success',
                            showClose: true,
                        })
                        this.loading = false
                    })
                    .catch(err => {
                        let status = err.response.status

                        // default message
                        let message = 'Failed to send reset link.'

                        // validation error
                        if (status === 422) {
                            message = err.response.data.errors.email[0]
                        }

                        // controller handled messages
                        if ([403, 404].includes(status)) {
                            message = err.response.data.error
                        }

                        this.$notify({
                            title: 'Auth',
                            message: message,
                            type: 'error',
                            showClose: true,
                        })
                        this.loading = false
                    })
            },

            onVerify(response) {
                this.forgot_form.recaptcha_response = response
            },

            onExpired() {
                this.forgot_form.recaptcha_response = null
            },
        }
    }
</script>
