var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row nav-padding" },
    [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "pull-left font-120" }, [
          _c("strong", [_vm._v(_vm._s(_vm.dataCount))]),
          _vm._v(
            " " +
              _vm._s(
                _vm.dataCount > 1 ? "Call dispositions" : "Call disposition"
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pull-right" },
          [
            _c("call-disposition-creator", {
              ref: "addCallDisposition",
              staticClass: "pull-left",
              scopedSlots: _vm._u([
                {
                  key: "trigger",
                  fn: function () {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-md rounded greenish mb-2",
                          on: {
                            click: function ($event) {
                              _vm.$refs.addCallDisposition.hide_add = true
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus" }),
                          _vm._v(
                            "\n                        Add Call Disposition\n                    "
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.$root.loading_call_disposition,
              expression: "$root.loading_call_disposition",
            },
          ],
          ref: "call_disposition_form",
          staticClass: "w-full",
          attrs: {
            rules: _vm.rules_call_disposition,
            model: _vm.editingCallDisposition,
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.preventSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "w-full",
              attrs: {
                data: _vm.paginatedData,
                "default-sort": { prop: "name", order: "ascending" },
                fit: "",
                stripe: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "100", sortable: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "text-greyish" }, [
                          _vm._v(_vm._s(scope.row.id)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "is_external", sortable: "", "min-width": 120 },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("span", [_vm._v("Synced with CRM")]),
                        _vm._v(" "),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "right",
                              width: "500",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c("strong", [_vm._v("Aloware Only")]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Call Dispositions are not synced with your CRM, and they are in Aloware only."
                                ),
                              ]),
                              _vm._v(" "),
                              _c("strong", [_vm._v("CRM & Aloware")]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Call Dispositions are in sync with your CRM."
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                                You must manually sync the Call Dispositions for the new CRM Call Outcomes to appear in Aloware. To do that please go to Integrations > {Your CRM Settings} > Pull Call Outcomes\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                                If you don’t manually sync, the new CRM Call Outcomes will appear once Aloware performs its daily sync at 6 PM PT.\n                            "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "ml-1 fas fa-info-circle",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            }),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n                    " +
                            _vm._s(scope.row.is_external ? "Yes" : "No") +
                            "\n                "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "Name",
                  "min-width": 200,
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            [
                              (!_vm.editingCallDisposition ||
                                _vm.editingCallDisposition.id !=
                                  scope.row.id) &&
                              scope.row.is_external
                                ? _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      style: { color: scope.row.color },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                lock\n                            "
                                      ),
                                    ]
                                  )
                                : (!_vm.editingCallDisposition ||
                                    _vm.editingCallDisposition.id !=
                                      scope.row.id) &&
                                  !scope.row.is_external
                                ? _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      style: { color: scope.row.color },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                label\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editingCallDisposition &&
                              _vm.editingCallDisposition.id == scope.row.id
                                ? _c("el-color-picker", {
                                    staticClass: "d-block",
                                    on: {
                                      "active-change":
                                        _vm.updateCallDispositionColor,
                                    },
                                    model: {
                                      value: _vm.editingCallDisposition.color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editingCallDisposition,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editingCallDisposition.color",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            _vm._v(" "),
                            !_vm.editingCallDisposition ||
                            _vm.editingCallDisposition.id != scope.row.id ||
                            scope.row.is_external
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-greyish ml-2 align-self-center",
                                    attrs: { title: scope.row.name },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(scope.row.name) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _c(
                                  "el-form-item",
                                  {
                                    staticClass: "ml-2 flex-fill",
                                    attrs: { prop: "name" },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "Name" },
                                      model: {
                                        value: _vm.editingCallDisposition.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editingCallDisposition,
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "editingCallDisposition.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "description",
                  label: "Description",
                  "min-width": 200,
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !_vm.editingCallDisposition ||
                        _vm.editingCallDisposition.id != scope.row.id
                          ? _c("span", { staticClass: "text-greyish" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(scope.row.description || "-") +
                                  "\n                    "
                              ),
                            ])
                          : _c(
                              "el-form-item",
                              { attrs: { prop: "description" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "Description",
                                    type: "textarea",
                                    rows: "2",
                                    maxlength: "190",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value:
                                      _vm.editingCallDisposition.description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editingCallDisposition,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editingCallDisposition.description",
                                  },
                                }),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "contacts_count",
                  label: "Number Of Communications",
                  "min-width": 250,
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !_vm.loaded_call_disposition.includes(scope.row.id)
                          ? _c("i", {
                              staticClass: "fa fa-spin fa-spinner pull-left",
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loaded_call_disposition.includes(scope.row.id)
                          ? _c(
                              "button",
                              {
                                staticClass: "btn b-bluish text-bluish btn-sm",
                                on: {
                                  click: function ($event) {
                                    return _vm.showCommunications(scope.row.id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Show Communications\n                        "
                                ),
                                _c(
                                  "b",
                                  {
                                    staticClass: "label rounded danger m-l-xs",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.communications_count)
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Operations",
                  align: "center",
                  width: "150",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          !_vm.editingCallDisposition ||
                          _vm.editingCallDisposition.id != scope.row.id
                            ? _c("div", [
                                _vm.hasPermissionTo("update disposition status")
                                  ? _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-12" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-block info",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editCallDisposition(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-pencil pull-left",
                                            }),
                                            _vm._v(
                                              "\n                                        Edit\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasPermissionTo(
                                  "delete disposition status"
                                ) && !scope.row.is_external
                                  ? _c("div", { staticClass: "row mt-2" }, [
                                      _c("div", { staticClass: "col-12" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-block danger",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteCallDispositionRemote(
                                                  scope.row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.loading_delete_call_disposition ==
                                                      scope.row.id,
                                                    expression:
                                                      "loading_delete_call_disposition == scope.row.id",
                                                  },
                                                ],
                                                staticClass:
                                                  "material-icons loader pull-left",
                                              },
                                              [_vm._v("")]
                                            ),
                                            _vm._v(" "),
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.loading_delete_call_disposition,
                                                  expression:
                                                    "!loading_delete_call_disposition",
                                                },
                                              ],
                                              staticClass:
                                                "fa fa-trash pull-left",
                                            }),
                                            _vm._v(
                                              "\n                                        Delete\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ])
                            : _c("div", [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _vm.hasPermissionTo(
                                      "update disposition status"
                                    )
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-block primary",
                                            attrs: {
                                              disabled: _vm.loading_btn,
                                            },
                                            on: {
                                              click: _vm.saveCallDisposition,
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.loading_btn,
                                                    expression: "loading_btn",
                                                  },
                                                ],
                                                staticClass:
                                                  "material-icons loader pull-left",
                                              },
                                              [_vm._v("")]
                                            ),
                                            _vm._v(" "),
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.loading_btn,
                                                  expression: "!loading_btn",
                                                },
                                              ],
                                              staticClass:
                                                "fa fa-save pull-left",
                                            }),
                                            _vm._v(
                                              "\n                                        Save\n                                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row mt-2" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-block danger",
                                        on: {
                                          click: _vm.cancelEditCallDisposition,
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-close pull-left",
                                        }),
                                        _vm._v(
                                          "\n                                        Cancel\n                                    "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.pageCount >= 1
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("el-pagination", {
                staticClass: "pull-right",
                attrs: {
                  background: "",
                  layout: "sizes, prev, pager, next",
                  "page-sizes": [10, 20, 50, 100, 250],
                  "page-size": 20,
                  "current-page": _vm.pagination.current_page,
                  total: _vm.dataCount,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.pagination, "current_page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.pagination, "current_page", $event)
                  },
                  "current-change": _vm.changePage,
                  "size-change": _vm.handleSizeChange,
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }