<template>
 <div class="d-flex justify-content-center">
    <el-alert class="alert w-auto"
              :class="`alert-${type} border-${type}`"
              :closable="dimisable" 
              :type="mappedTypeForElement"
              >
      <slot></slot>
    </el-alert>
</div>
</template>
<script>
  export default {
    name: 'al-alert',
    props: {
      
      dimisable: {
        default: false,
        type: Boolean
      },
      // success/warning/info/error
      type: {
        default: 'warning',
        type: String,
        validator: function (value) {
          // The value must match one of these strings
          return ['success', 'warning', 'info', 'danger'].includes(value);
        }
      },
    },

    computed: {
      // the type for ElementUI component is "error" meanwhile in boostrap it is "danger"
      mappedTypeForElement() {
        return this.type === 'danger' ? 'error' : this.type;
      }
    }
  }
</script>