var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12 col-md-8 mx-auto" }, [
      _c(
        "div",
        { staticClass: "text-center mb-2" },
        [
          _c(
            "el-button",
            {
              staticClass: "mb-3",
              attrs: {
                type: "success",
                disabled: _vm.isViewOnlyAccess(),
                "data-testid": "refresh-button",
              },
              on: { click: _vm.runDiagnosis },
            },
            [
              _vm.loading
                ? _c("i", { staticClass: "material-icons loader" }, [
                    _vm._v("\n                    \n                "),
                  ])
                : _c("i", { staticClass: "material-icons" }, [
                    _vm._v("refresh\n                "),
                  ]),
              _vm._v("\n                Refresh\n            "),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "mh-100",
        },
        [
          _vm.user_result
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", {
                  staticClass: "fas mr-2 mb-2",
                  class: _vm.user_result.agent_is_eligible_to_take_call
                    ? "fa-phone color-999"
                    : "fa-phone-slash text-danger",
                  staticStyle: { "font-size": "40px" },
                }),
                _vm._v(" "),
                _c("h6", [_vm._v(_vm._s(_vm.user_result.model.full_name))]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Time of test: " +
                      _vm._s(
                        _vm._f("momentFormat")(
                          new Date(),
                          "MM/DD h:mma z",
                          true
                        )
                      )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "card p-2 mb-3" }, [
            _vm.user_result
              ? _c(
                  "table",
                  { staticClass: "table table-sm m-0 table-routing-test" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass:
                              "pt-3 pr-0 d-flex flex-column align-items-center",
                          },
                          [
                            _vm.user_result.model.is_destination
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "btn btn-xs text-white cursor-default btn-block",
                                    class: [
                                      _vm.$options.filters.agentStatusClass(1),
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Available\n                                "
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass:
                                      "btn btn-xs text-white cursor-default btn-block w-auto",
                                    class: [
                                      _vm.$options.filters.agentStatusClass(
                                        _vm.user_result.model.agent_status
                                      ),
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.AgentStatusLabels.LABELS.find(
                                            (label) =>
                                              label.value ===
                                              _vm.user_result.model.agent_status
                                          ).label
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                            _vm._v(" "),
                            _vm.authUserIsCompanyAdmin && !_vm.userIsAvailable
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "text-center mt-2",
                                    attrs: { type: "success" },
                                    on: {
                                      click: _vm.handleResetStatusToAvailable,
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("refresh"),
                                    ]),
                                    _vm._v(
                                      "\n                                    Reset status to available\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "ul",
                            { staticClass: "list-unstyled" },
                            _vm._l(_vm.user_result.results, function (result) {
                              return _c("li", { key: result.key }, [
                                result.value
                                  ? _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons text-dark-greenish",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            check\n                                        "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons text-danger",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            close\n                                        "
                                        ),
                                      ]
                                    ),
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(result.label) +
                                    "\n                                    "
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "td-can-take-calls text-center" },
                          [
                            _vm.user_result.agent_is_eligible_to_take_call
                              ? _c(
                                  "strong",
                                  { staticClass: "text-dark-greenish" },
                                  [
                                    _vm._v(
                                      "\n                                    Yes\n                                "
                                    ),
                                  ]
                                )
                              : _c("strong", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                                    No\n                                "
                                  ),
                                ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-center color-999 mb-4" }, [
            _vm._v(
              "\n                Please note that this utility does not look into contact / communication ownership.\n            "
            ),
          ]),
          _vm._v(" "),
          _vm.on_going_calls.length > 0
            ? _c("div", [
                _c("h5", [
                  _vm._v(
                    "\n                    Ongoing Calls\n                "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.on_going_calls, function (ongoing_call) {
            return _c("div", { staticClass: "card py-2 px-3 mb-3" }, [
              _c("div", { staticClass: "media" }, [
                _c("i", {
                  staticClass: "align-self-center mr-3",
                  class:
                    ongoing_call.direction ===
                    _vm.CommunicationDirection.OUTBOUND
                      ? "fa fa-arrow-circle-up"
                      : "fa fa-arrow-circle-down",
                  staticStyle: { "font-size": "35px", color: "#acacac" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "media-body" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        ongoing_call.direction ===
                          _vm.CommunicationDirection.OUTBOUND
                          ? "Outbound"
                          : "Inbound"
                      ) +
                      " call with\n                        "
                  ),
                  ongoing_call.contact && ongoing_call.contact.name
                    ? _c("div", [
                        _c("h6", { staticClass: "mb-0" }, [
                          _vm._v(
                            _vm._s(ongoing_call.contact.name) +
                              " (" +
                              _vm._s(
                                _vm._f("fixPhone")(
                                  ongoing_call.contact.phone_number
                                )
                              ) +
                              ")"
                          ),
                        ]),
                      ])
                    : _c("div", [
                        _c("h6", { staticClass: "mb-0" }, [
                          _vm._v(
                            _vm._s(_vm._f("fixPhone")(ongoing_call.lead_number))
                          ),
                        ]),
                      ]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-md btn-danger",
                    attrs: { "data-testid": "terminate-button" },
                    on: {
                      click: function ($event) {
                        return _vm.terminateCall(ongoing_call.id)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Terminate\n                    "
                    ),
                  ]
                ),
              ]),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center py-2" }, [
      _c("i", {
        staticClass: "fas fa-arrow-down",
        staticStyle: { "font-size": "30px", color: "#acacac" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Diagnosis")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center" }, [_vm._v("Can take calls?")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }