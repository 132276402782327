var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "pl-2 pr-2 clear" }, [
        _c("div", { staticClass: "row box" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "box-header" },
              [
                _c("p", { staticClass: "text-muted text-xl mb-0" }, [
                  _vm._v("Phone Numbers"),
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("strong", { staticClass: "text-md" }, [
                  _vm._v("Rent number from:"),
                ]),
                _vm._v(" "),
                _c("country-selector", {
                  attrs: {
                    initial_countries: _vm.countries,
                    disabled: _vm.disable_country_phone_number_selector,
                  },
                  on: { changed: _vm.changeCountryPhoneNumber },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "box-body" },
              [
                _vm.country_phone_number
                  ? _c("el-divider", [
                      _c("span", { staticClass: "text-lg" }, [
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(
                            _vm._s(_vm.getCountryName(_vm.country_phone_number))
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading_phone_number,
                        expression: "loading_phone_number",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: {
                      data: _vm.phone_number_prices,
                      "cell-class-name": "text-md _600",
                      fit: "",
                      stripe: "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "Type", prop: "type_label" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "Price",
                        prop: "price",
                        formatter: _vm.formatPhoneNumberPrice,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { align: "right" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addPhoneNumberRequestTicket(
                                        scope.row.type
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        scope.row.price
                                          ? "Request Phone Number"
                                          : "Request Price"
                                      ) +
                                      "\n                          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pt-3 pl-2 pr-2 clear" }, [
        _c("div", { staticClass: "row box" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "box-header" },
              [
                _c("p", { staticClass: "text-muted text-xl mb-0" }, [
                  _vm._v("Call Rates"),
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("strong", { staticClass: "text-md" }, [
                  _vm._v("Make calls to:"),
                ]),
                _vm._v(" "),
                _c("country-selector", {
                  attrs: {
                    initial_countries: _vm.countries,
                    disabled: _vm.disable_country_call_selector,
                  },
                  on: { changed: _vm.changeCountryCall },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "box-body" },
              [
                _vm.country_call
                  ? _c("el-divider", [
                      _c("span", { staticClass: "text-lg" }, [
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.getCountryName(_vm.country_call))),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading_call,
                        expression: "loading_call",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: { data: _vm.call_pricing_info, stripe: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c("strong", [_vm._v("Origination Prefixes:")]),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml-2" }, [
                                _vm._v(
                                  _vm._s(
                                    props.row.origination_prefixes.join(", ")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("strong", [_vm._v("Destination Prefixes:")]),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml-2" }, [
                                _vm._v(
                                  _vm._s(
                                    props.row.destination_prefixes.join(", ")
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "friendly_name",
                        label: "Route",
                        "min-width": "300",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "current_price",
                        label: "Price starts from",
                        width: "150",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _c("span", { staticClass: "text-md _600" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toCurrency")(
                                        _vm._f("fixRounding")(
                                          scope.row.current_price
                                        )
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v("/min"),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pt-3 pl-2 pr-2 clear" }, [
        _c("div", { staticClass: "row box" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "box-header" },
              [
                _c("p", { staticClass: "text-muted text-xl mb-0" }, [
                  _vm._v("SMS Rates"),
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("strong", { staticClass: "text-md" }, [
                  _vm._v("Send messages to:"),
                ]),
                _vm._v(" "),
                _c("country-selector", {
                  attrs: {
                    initial_countries: _vm.countries,
                    disabled: _vm.disable_country_sms_selector,
                  },
                  on: { changed: _vm.changeCountrySms },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "box-body" },
              [
                _vm.country_sms
                  ? _c("el-divider", [
                      _c("span", { staticClass: "text-lg" }, [
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.getCountryName(_vm.country_sms))),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading_sms,
                        expression: "loading_sms",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: { data: _vm.sms_pricing_info, stripe: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "carrier",
                        label: "Carrier",
                        "min-width": "300",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mobile",
                        label: "To Mobile",
                        width: "150",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _c("span", { staticClass: "text-md _600" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toCurrency")(
                                        _vm._f("fixRounding")(scope.row.mobile)
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v("/message"),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "local", label: "To Local", width: "150" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _c("span", { staticClass: "text-md _600" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toCurrency")(
                                        _vm._f("fixRounding")(scope.row.local)
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v("/message"),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }