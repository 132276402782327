<template>
    <el-dialog :append-to-body="true"
               :before-close="beforeClose"
               :close-on-click-modal="false"
               :visible.sync="import_dialog_visible"
               class="import-wizard dialog-no-border"
               title="Import Ring Group Wizard"
               top="4vh"
               width="60vw">
        <div slot="title">
            <h5 class="mb-0">
                <i class="fas fa-h-wizard"></i>
                Import Ring Group Wizard
            </h5>
        </div>

        <el-steps :active="current_step"
                  align-center
                  class="mb-3"
                  finish-status="success">
            <el-step title="Settings"></el-step>
            <el-step title="Select Columns"></el-step>
            <el-step title="Finish"></el-step>
        </el-steps>

        <el-form :model="import_model"
                 @submit.prevent=""
                 label-position="top"
                 ref="form"
                 v-loading="uploading">
            <div>
                <div v-if="current_step === 1">
                    <div class="row">
                        <div class="col-md-5">
                            <el-form-item>
                                <div class="form-label">
                                    <label>Choose or create a Ring Group</label>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <el-radio v-model="import_model.create_ring_group"
                                                  class="w-full new-ring-group p-2 d-flex align-items-center"
                                                  :label="true"
                                                  border>
                                            Create new Ring Group
                                        </el-radio>
                                    </div>
                                    <div class="col">
                                        <el-radio v-model="import_model.create_ring_group"
                                                  class="w-full choose-ring-group p-2 d-flex align-items-center"
                                                  :label="false"
                                                  border>
                                            Choose a Ring Group
                                        </el-radio>
                                    </div>
                                </div>
                            </el-form-item>

                            <el-divider></el-divider>

                            <div v-if="import_model.create_ring_group">
                                <el-form-item :error="firstError('name')">
                                    <div class="form-label">
                                        <label>Name</label>
                                    </div>
                                    <el-input clearable
                                              placeholder="Name of this Ring Group"
                                              v-model="import_model.name">
                                    </el-input>
                                </el-form-item>
                                <el-form-item :error="firstError('description')">
                                    <div class="form-label">
                                        <label>Description</label>
                                    </div>
                                    <el-input :autosize="{ minRows: 3, maxRows: 5}"
                                              placeholder="Description of this new Ring Group"
                                              type="textarea"
                                              v-model="import_model.description">
                                    </el-input>
                                </el-form-item>
                            </div>

                            <div v-if="!import_model.create_ring_group">
                                <el-form-item :error="firstError('ring_group_id')">
                                    <ring-group-selector @change="ringGroupChange" class="mb-2"></ring-group-selector>

                                    <div class="alert alert-warning alert-aloware p-1"
                                         v-if="!import_model.create_ring_group && import_model.ring_group_id > 0">
                                        <i class="fa fa-exclamation-triangle"></i> This will override the ring group's
                                        users and layers
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <el-form-item>
                                <div class="form-label">
                                    <label>Import File</label>
                                </div>
                                <div class="step-0">
                                    <div class="container-template-download text-center"
                                         style="width:340px;">
                                        <p class="text-sm">To get started with a file template, click link below.</p>
                                        <el-link type="success"
                                                 target="_blank"
                                                 class="text-md mt-2"
                                                 href="/templates/Ring-Groups-Template.csv">
                                            <i class="fa fa-cloud-download"></i>
                                            Download Ring Groups Template
                                        </el-link>
                                    </div>
                                    <el-upload :auto-upload="true"
                                               :before-upload="beforeUpload"
                                               :data="upload_data"
                                               :headers="headers"
                                               :multiple="false"
                                               :on-error="columnChooserFailed"
                                               :on-success="renderColumnChooser"
                                               :show-file-list="false"
                                               action="/api/v1/import-ring-group/parse"
                                               drag
                                               ref="file_upload">
                                        <div class="dz-label">
                                            <i class="fas fa-file-csv"></i>
                                            <p>Drop your CSV file here or</p>

                                            <el-button class="mb-2"
                                                       type="success"
                                                       v-if="!uploading">
                                    <span>
                                        <i class="fa fa-upload"></i>
                                        Upload From Computer and Start
                                    </span>
                                            </el-button>
                                            <div class="text-danger"
                                                 style="word-break: break-word"
                                                 v-if="upload_errors.file > 0">
                                                <i class="fa fa-exclamation-circle"></i>
                                                {{ upload_error }}
                                            </div>
                                        </div>
                                    </el-upload>
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div v-if="current_step === 2">
                    <el-form-item>
                        <div class="alert alert-success alert-aloware">
                            <div class="media">
                                <i class="fas fa-info-circle alert-icon"></i>
                                <div class="media-body lh-1">
                                    <p class="mb-0">
                                        We've fetched the first 10 rows in your file so you can assign each columns to
                                        its appropriate property. Take Note that email or user ID is required
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered table-csv table-striped table-sm">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th v-for="(th, i) in header_map">
                                        <el-select @change="headerMapChange"
                                                   class="select-table-header"
                                                   clearable
                                                   placeholder="Select proper column type"
                                                   v-model="header_map[i]">
                                            <el-option :key="uf.value"
                                                       :label="uf.label"
                                                       :value="uf.value"
                                                       v-for="uf in user_fields">
                                            </el-option>
                                        </el-select>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(row, index) in data_map"
                                    v-show="!(ignore_first_row && index === 0)">
                                    <td>
                                        {{ index>0 ? index+1 : '' }}
                                        <el-tooltip class="item"
                                                    content="Remove this first row"
                                                    effect="dark"
                                                    placement="right">
                                            <el-button @click="ignore_first_row = true"
                                                       class="px-1"
                                                       size="mini"
                                                       v-if="index === 0">
                                                <i class="fa fa-trash-o"></i>
                                            </el-button>
                                        </el-tooltip>
                                    </td>
                                    <td v-for="col in row">{{ col }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-form-item>

                    <div class="d-flex">
                        <el-button @click="current_step = 1">
                            <i class="fa fa-arrow-circle-left"></i> Back to Settings
                        </el-button>
                        <el-button :disabled="!importable"
                                   @click="startImport"
                                   class="ml-auto"
                                   type="success">
                            <strong><i class="fas fa-file-import"></i> Start Import</strong>
                        </el-button>
                    </div>
                </div>
                <div v-if="current_step === 3">
                    <div class="container-import-summary">
                        <h2>
                            <i class="fa fa-check"></i>
                            Importing users to {{ import_model.ring_group.name }}...
                        </h2>

                        <i class="fas fa-file-import"></i>

                        <p class="lead">This might take a moment. </p>
                        <p class="lead">
                            You can see the progress of the import in Account &raquo; Imports &raquo; Ring Groups page.
                        </p>
                    </div>
                    <div class="d-flex">
                        <el-button @click="closeImportWizard">Close</el-button>
                        <div class="ml-auto">
                            <router-link
                                    :to="{ name: 'Ring Group Dialog', params: { ring_group_id: import_model.ring_group.id }}">
                                <el-button>Ring Group Settings</el-button>
                            </router-link>
                            <router-link
                                    :to="{ name: 'Account', query: { tab: 'imports', type: ImportType.TYPE_RING_GROUPS }}"
                                    v-if="$route.name !== 'Account'">
                                <el-button type="success">Go to Account &raquo; Imports &raquo; Ring Groups</el-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
    import auth from "../../auth";
    import RingGroupSelector from "../../components/ring-group-selector"
    import * as ImportType from "../../constants/import-types"

    export default {
        name: "import-ring-group-wizard",

        components: {
            RingGroupSelector
        },

        data() {
            return {
                import_dialog_visible: false,
                current_step: 1,
                uploading: false,
                upload_errors: {},
                ignore_first_row: false,
                default_import_model: {
                    ring_group_id: '',
                    create_ring_group: true,
                    ring_group: {},
                    import: {},
                    name: '',
                    description: ''
                },
                import_model: {},
                header_map: [],
                data_map: [],
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + auth.user.profile.api_token
                },
                user_fields: [
                    {
                        value: 'user_id',
                        label: 'User ID'
                    },
                    {
                        value: 'email',
                        label: 'Email'
                    },
                    {
                        value: 'layer',
                        label: 'Layer'
                    },
                ],
                ImportType
            }
        },

        computed: {
            upload_data: function () {
                return {
                    create_ring_group: this.import_model.create_ring_group ? 1 : 0,
                    ring_group_id: this.import_model.ring_group_id,
                    name: this.import_model.name,
                    description: this.import_model.description
                }
            },

            importable: function () {
                return _.indexOf(this.header_map, 'email') >= 0 || _.indexOf(this.header_map, 'user_id') >= 0
            }
        },

        methods: {
            start() {
                this.import_dialog_visible = true
                this.import_model = _.clone(this.default_import_model)
            },

            rerun(import_data) {
                this.current_step = 2

                this.import_dialog_visible = true
                this.import_model = _.clone(this.default_import_model)

                this.import_model.import = import_data
                this.import_model.ring_group = import_data.ring_group
                this.import_model.ring_group_id = import_data.ring_group.id
                this.header_map = import_data.header_map

                this.uploading = true
                axios.post(`/api/v1/import-ring-group/rerun/${this.import_model.import.id}`).then(res => {
                    let response = res.data
                    this.header_map = response.header_map
                    this.ignore_first_row = response.import.ignore_first_row
                    this.data_map = response.data

                    this.uploading = false
                }).catch(err => {

                });
            },

            beforeUpload() {
                this.uploading = true
                this.upload_errors = {}
            },

            renderColumnChooser(response, file, fileList) {
                this.uploading = false
                this.upload_errors = {}

                this.import_model.ring_group = response.ring_group
                this.import_model.import = response.import
                this.import_model.ring_group_id = response.ring_group.id

                this.header_map = response.header_map
                this.data_map = response.data

                this.current_step = 2
            },

            columnChooserFailed(err, file, fileList) {
                this.uploading = false

                let error = JSON.parse(err.message)
                if (typeof error.errors !== 'undefined') {
                    this.upload_errors = error.errors
                } else {
                    this.upload_errors = error
                    error.status = 500
                    this.$root.handleErrors(error)
                }
            },

            firstError(field) {
                let first = ''
                if (this.upload_errors && field in this.upload_errors) {
                    first = _.head(this.upload_errors[field])
                }
                return first
            },

            headerMapChange() {

            },

            ringGroupChange(ring_group_id) {
                this.import_model.ring_group_id = ring_group_id
            },

            startImport() {
                this.uploading = true
                axios.post(`/api/v1/import-ring-group/start/${this.import_model.ring_group_id}/${this.import_model.import.id}`, {
                    ignore_first_row: this.ignore_first_row ? 1 : 0,
                    header_map: this.header_map
                }).then(res => {
                    this.uploading = false
                    this.current_step = 3
                }).catch(err => {

                })
            },

            closeImportWizard() {
                this.current_step = 1;
                this.import_dialog_visible = false
                this.uploading = false
                this.upload_errors = {}
                this.$emit('onClose')
            },

            beforeClose() {
                if (_.isEqual(this.import_model, this.default_import_model)) {
                    this.closeImportWizard()
                } else {
                    this.$confirm(`Are you sure you want to close this import wizard?`, 'Event', {
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        type: 'warning',
                        customClass: 'width-500 fixed'
                    }).then(() => {
                        this.closeImportWizard()
                    }).catch(err => {
                        console.log(err)
                    })
                }
            },
        }
    }
</script>
