var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tooltip",
        { staticClass: "item pointer", attrs: { placement: "top" } },
        [
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _vm._v("From: " + _vm._s(_vm._f("fixDateTimeFull")(_vm.from_time))),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.timeago))]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }