<template>
    <div class="none">
        <img :src="assets.logo">
        <img :src="assets.logo_inverse">
        <img :src="assets.logo_square_inverse">
    </div>
</template>

<script>
    export default {
        props: {
            assets: {
                required: true
            }
        },
    }
</script>
