var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom",
        title: "Event Legends",
        width: "280",
        trigger: "click",
        content: "",
      },
    },
    [
      _c("div", [
        _c(
          "table",
          {
            staticClass: "w-100",
            staticStyle: { "font-size": "16px" },
            attrs: { id: "table-calendar-legend" },
          },
          [
            _c("tr", [
              _c("td", { staticClass: "pr-2" }, [
                _c("i", {
                  staticClass: "fas fa-square event-color-type-12 status-8",
                }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("Upcoming Appointment")]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "pr-2" }, [
                _c("i", { staticClass: "fas fa-square event-color-type-12" }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("Past Appointment")]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "pr-2" }, [
                _c("i", {
                  staticClass: "fas fa-square event-color-type-12 status-4",
                }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("Completed Appointment")]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "pr-2" }, [
                _c("i", {
                  staticClass: "fas fa-square event-color-type-12 status-5",
                }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("Canceled Appointment")]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "pr-2" }, [
                _c("i", {
                  staticClass: "fas fa-square event-color-type-12 status-14",
                }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("Missed Appointment")]),
            ]),
            _vm._v(" "),
            _c("tr", [_c("td", { attrs: { colspan: "2" } }, [_c("hr")])]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "pr-2" }, [
                _c("i", {
                  staticClass: "fas fa-square event-color-type-13 status-8",
                }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("Upcoming Reminder")]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "pr-2" }, [
                _c("i", {
                  staticClass:
                    "fas fa-square event-color-type-13 status-8 is_past",
                }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("Past Reminder")]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "pr-2" }, [
                _c("i", {
                  staticClass: "fas fa-square event-color-type-13 status-4",
                }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("Completed Reminder")]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "pr-2" }, [
                _c("i", {
                  staticClass: "fas fa-square event-color-type-13 status-5",
                }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("Canceled Reminder")]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", { staticClass: "pr-2" }, [
                _c("i", {
                  staticClass: "fas fa-square event-color-type-13 status-3",
                }),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("Missed Reminder")]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "btn-direction",
          staticStyle: { "margin-right": "0.25rem" },
          attrs: { slot: "reference", type: "text" },
          on: {
            click: function ($event) {
              _vm.legend = !_vm.legend
            },
          },
          slot: "reference",
        },
        [_c("i", { staticClass: "far fa-question-circle" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }