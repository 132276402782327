export default Object.freeze([
    {
        name: 'None',
        value: 'NONE',
    },
    {
        name: 'NASDAQ',
        value: 'NASDAQ',
    },
    {
        name: 'NYSE',
        value: 'NYSE',
    },
    {
        name: 'AMEX',
        value: 'AMEX',
    },
    {
        name: 'AMX',
        value: 'AMX',
    },
    {
        name: 'ASX',
        value: 'ASX',
    },
    {
        name: 'B3',
        value: 'B3',
    },
    {
        name: 'BME',
        value: 'BME',
    },
    {
        name: 'BSE',
        value: 'BSE',
    },
    {
        name: 'FRA',
        value: 'FRA',
    },
    {
        name: 'ICEX',
        value: 'ICEX',
    },
    {
        name: 'JPX',
        value: 'JPX',
    },
    {
        name: 'JSE',
        value: 'JSE',
    },
    {
        name: 'KRX',
        value: 'KRX',
    },
    {
        name: 'LON',
        value: 'LON',
    },
    {
        name: 'NSE',
        value: 'NSE',
    },
    {
        name: 'OMX',
        value: 'OMX',
    },
    {
        name: 'SEHK',
        value: 'SEHK',
    },
    {
        name: 'SGX',
        value: 'SGX',
    },
    {
        name: 'SSE',
        value: 'SSE',
    },
    {
        name: 'STO',
        value: 'STO',
    },
    {
        name: 'SWX',
        value: 'SWX',
    },
    {
        name: 'SZSE',
        value: 'SZSE',
    },
    {
        name: 'TSX',
        value: 'TSX',
    },
    {
        name: 'TWSE',
        value: 'TWSE',
    },
    {
        name: 'VSE',
        value: 'VSE',
    },
    {
        name: 'Other',
        value: 'OTHER',
    },
])
