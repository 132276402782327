var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-testid": "line-wizard-wrapper" } },
    [
      _c(
        "el-steps",
        {
          staticClass: "mb-3",
          attrs: {
            active: _vm.current_step,
            "align-center": "",
            "finish-status": "success",
            "data-testid": "line-wizard-steps",
          },
        },
        [
          _c("el-step", {
            attrs: { title: "Name", "data-testid": "name-step" },
          }),
          _vm._v(" "),
          _c("el-step", {
            attrs: {
              title: "Search Phone Number",
              "data-testid": "search-step",
            },
          }),
          _vm._v(" "),
          _c("el-step", {
            attrs: {
              title: "Line Registration",
              "data-testid": "line-registration-step",
            },
          }),
          _vm._v(" "),
          _c("el-step", {
            attrs: { title: "Finish", "data-testid": "finish-step" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.current_step === _vm.Steps.STEP_NAME,
                expression: "current_step === Steps.STEP_NAME",
              },
            ],
            staticClass: "step-1",
          },
          [
            _c(
              "el-form",
              {
                ref: "campaign_info",
                attrs: {
                  "label-position": "top",
                  "label-width": "300px",
                  rules: _vm.rules_0,
                  model: _vm.campaign,
                  "data-testid": "name-step-form",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _vm.is_fax
                  ? [
                      _c("p", { staticClass: "form-head" }, [
                        _vm._v(
                          "\n                        Name\n                    "
                        ),
                      ]),
                    ]
                  : [
                      _c("p", { staticClass: "form-head" }, [
                        _vm._v(
                          "\n                        Name and Call Routing\n                    "
                        ),
                      ]),
                    ],
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "data-testid": "name-step-item",
                      label: "Name",
                      prop: "name",
                    },
                  },
                  [
                    _vm.is_fax
                      ? _c("em", { staticClass: "text-muted" }, [
                          _vm._v(
                            "\n                        Example: Office Fax\n                    "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.is_fax
                      ? [
                          _c("em", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n                            Example: Local Support Number\n                        "
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-8 col-12" },
                        [
                          _c("el-input", {
                            ref: "campaign_name",
                            attrs: { "data-testid": "campaing-name-input" },
                            on: { input: _vm.preValidateForm },
                            model: {
                              value: _vm.campaign.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.campaign, "name", $$v)
                              },
                              expression: "campaign.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c("el-popover", {
                            ref: "popover_name",
                            attrs: {
                              placement: "right-start",
                              title: "Line Name",
                              width: "200",
                              trigger: "hover",
                              "data-testid": "line-name-popover",
                              content:
                                "Use a name that is short and easily identifiable in your reports.",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "popover",
                                  rawName: "v-popover:popover_name",
                                  arg: "popover_name",
                                },
                              ],
                              staticClass: "form-hover-help",
                            },
                            [
                              _vm._v(
                                "\n                                What should I name this?\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                !_vm.is_fax
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "call_router_behavior",
                            "data-testid": "call-router-behavior-item",
                            label: "Call Routing",
                          },
                        },
                        [
                          _c("el-popover", {
                            ref: "popover_ring_group",
                            attrs: {
                              placement: "bottom",
                              title: _vm._f("humanReadableCallRouterBehavior")(
                                _vm.CampaignCallRouterBehavior
                                  .CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_RING_GROUP
                              ),
                              width: "400",
                              trigger: "hover",
                              content:
                                "Connect calls to a Ring Group without a queue.",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-popover", {
                            ref: "popover_user",
                            attrs: {
                              placement: "bottom",
                              title: _vm._f("humanReadableCallRouterBehavior")(
                                _vm.CampaignCallRouterBehavior
                                  .CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_USER
                              ),
                              width: "400",
                              trigger: "hover",
                              content: "Connect calls to a User directly.",
                              "data-testid": "connect-user-popover",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-popover", {
                            ref: "popover_dead_end",
                            attrs: {
                              placement: "bottom",
                              title: _vm._f("humanReadableCallRouterBehavior")(
                                _vm.CampaignCallRouterBehavior
                                  .CALL_ROUTER_BEHAVIOR_MODE_DEAD_END
                              ),
                              width: "400",
                              trigger: "hover",
                              "data-test": "dead-end-popover",
                              content:
                                "Do not connect calls & hangup after playing greeting (not recommended).",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                size: "small",
                                "data-test": "call-router-behavior-radio-group",
                              },
                              on: { change: _vm.changeCallRouterBehavior },
                              model: {
                                value: _vm.call_router_behavior,
                                callback: function ($$v) {
                                  _vm.call_router_behavior = $$v
                                },
                                expression: "call_router_behavior",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                {
                                  directives: [
                                    {
                                      name: "popover",
                                      rawName: "v-popover:popover_ring_group",
                                      arg: "popover_ring_group",
                                    },
                                  ],
                                  attrs: {
                                    label: 1,
                                    "data-test": "ring-group-radio",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f(
                                          "humanReadableCallRouterBehavior"
                                        )(
                                          _vm.CampaignCallRouterBehavior
                                            .CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_RING_GROUP
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                {
                                  directives: [
                                    {
                                      name: "popover",
                                      rawName: "v-popover:popover_user",
                                      arg: "popover_user",
                                    },
                                  ],
                                  attrs: {
                                    label: 2,
                                    "data-testid": "forward-user-radio",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f(
                                          "humanReadableCallRouterBehavior"
                                        )(
                                          _vm.CampaignCallRouterBehavior
                                            .CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_USER
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                {
                                  directives: [
                                    {
                                      name: "popover",
                                      rawName: "v-popover:popover_dead_end",
                                      arg: "popover_dead_end",
                                    },
                                  ],
                                  attrs: {
                                    label: 3,
                                    "data-test": "mode-dead-end-ratio",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f(
                                          "humanReadableCallRouterBehavior"
                                        )(
                                          _vm.CampaignCallRouterBehavior
                                            .CALL_ROUTER_BEHAVIOR_MODE_DEAD_END
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.call_router_behavior === 1
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: "Ring Group",
                                prop: "ring_group_id",
                                "data-test": "ring-group-item",
                              },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-8 col-12" },
                                  [
                                    _vm.hasPermissionTo("list ring group")
                                      ? _c("ring-group-selector", {
                                          staticStyle: { width: "300px" },
                                          attrs: {
                                            default_first: true,
                                            "data-test": "ring-group-selector",
                                          },
                                          on: { change: _vm.changeRingGroup },
                                          model: {
                                            value: _vm.campaign.ring_group_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.campaign,
                                                "ring_group_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "campaign.ring_group_id",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-4 col-12" },
                                  [
                                    _c("el-popover", {
                                      ref: "popover_ring_group_id",
                                      attrs: {
                                        placement: "right-start",
                                        title: "Ring Group",
                                        width: "200",
                                        trigger: "hover",
                                        "data-test": "ring-group-popover",
                                        content:
                                          "Delivers the call to a Ring Group when a customer rings.",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "popover",
                                            rawName:
                                              "v-popover:popover_ring_group_id",
                                            arg: "popover_ring_group_id",
                                          },
                                        ],
                                        staticClass: "form-hover-help",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Which ring group do I use?\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.call_router_behavior === 2
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: "User",
                                prop: "user_id",
                                "data-testid": "user-item",
                              },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-8 col-12" },
                                  [
                                    _c("user-selector", {
                                      staticClass: "pull-left",
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        ignore_focus_mode: true,
                                        "data-testid": "user-selector",
                                      },
                                      on: { change: _vm.changeUser },
                                      model: {
                                        value: _vm.campaign.user_id,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.campaign, "user_id", $$v)
                                        },
                                        expression: "campaign.user_id",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-popover", {
                                      ref: "popover_call_waiting",
                                      attrs: {
                                        placement: "top-start",
                                        title: "Call Waiting",
                                        width: "200",
                                        trigger: "hover",
                                        "data-testid": "call-waiting-popover",
                                        content:
                                          "Place new calls on hold while on a call",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "pull-right mt-1 mr-3" },
                                      [
                                        _c("el-switch", {
                                          attrs: {
                                            "data-testid":
                                              "call-waiting-switch",
                                            "active-color": "#00BF50",
                                          },
                                          model: {
                                            value:
                                              _vm.campaign.has_call_waiting,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.campaign,
                                                "has_call_waiting",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "campaign.has_call_waiting",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "popover",
                                                rawName:
                                                  "v-popover:popover_call_waiting",
                                                arg: "popover_call_waiting",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Call Waiting\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-md-4 col-12" },
                                  [
                                    _c("el-popover", {
                                      ref: "popover_user_id",
                                      attrs: {
                                        placement: "right-start",
                                        title: "User",
                                        width: "200",
                                        trigger: "hover",
                                        "data-test": "user-popover",
                                        content:
                                          "Delivers the call to a User when a customer rings.",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "popover",
                                            rawName:
                                              "v-popover:popover_user_id",
                                            arg: "popover_user_id",
                                          },
                                        ],
                                        staticClass: "form-hover-help",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Which user do I use?\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-right mt-4" },
                  [
                    _vm.current_step >= 2
                      ? _c(
                          "el-button",
                          {
                            staticClass: "pull-left",
                            attrs: {
                              icon: "el-icon-arrow-left",
                              type: "info",
                              "data-testid": "back-button",
                            },
                            on: { click: _vm.backForm },
                          },
                          [
                            _vm._v(
                              "\n                        Back\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          "data-testid": "next-phone-button",
                          disabled: !_vm.validated,
                        },
                        on: { click: _vm.submitForm },
                      },
                      [
                        _vm._v(
                          "\n                        Next: Select Phone Number\n                        "
                        ),
                        _c("i", {
                          staticClass: "el-icon-arrow-right el-icon-right",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.current_step === _vm.Steps.STEP_PHONE_NUMBER
          ? _c(
              "div",
              { attrs: { "data-test": "phone-number-step", id: "step_2" } },
              [
                _c("p", { staticClass: "form-head" }, [
                  _vm._v(
                    "\n                Country, Local or Toll-free number, Search with prefix\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "country_code",
                        staticClass: "mb-4",
                        attrs: {
                          "label-position": "top",
                          "data-testid": "country-code-form",
                          model: _vm.campaign,
                          rules: _vm.rules_1,
                          inline: true,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "w-100",
                            attrs: {
                              prop: "country_code",
                              "data-testid": "country-code-item",
                              label: "Country",
                            },
                          },
                          [
                            _c("country-selector", {
                              attrs: {
                                show_prefix: true,
                                supported_country_codes:
                                  _vm.supported_country_codes,
                                initial_country_code: _vm.initial_country_code,
                                force_initial_country_code: true,
                                show_tips: true,
                                disabled: _vm.loading,
                                phone_number_type: _vm.phone_number_type,
                                "data-testid": "country-selector",
                              },
                              on: { changed: _vm.changeCountry },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.campaign.country_code !== null
                      ? _c("div", [
                          _c("div", { staticClass: "row mb-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _vm.campaign.country_code !== null
                                  ? _c(
                                      "el-form",
                                      {
                                        ref: "campaign_number_search",
                                        staticClass: "mb-2",
                                        attrs: {
                                          "label-position": "top",
                                          inline: true,
                                          "data-testid": "number-search-form",
                                        },
                                        nativeOn: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                            return _vm.changeAreaCode.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass:
                                              "d-inline-block width-300 fixed",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label d-inline-block col-12",
                                              },
                                              [_vm._v("Number")]
                                            ),
                                            _vm._v(" "),
                                            _c("el-input", {
                                              attrs: {
                                                type: "text",
                                                pattern: "\\d*",
                                                maxlength:
                                                  _vm.area_code_max_length,
                                                clearable: "",
                                                "data-testid":
                                                  "number-search-input",
                                              },
                                              nativeOn: {
                                                keyup: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return _vm.changeAreaCode.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.number_search,
                                                callback: function ($$v) {
                                                  _vm.number_search = $$v
                                                },
                                                expression: "number_search",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass:
                                              "col-3 d-inline-block px-0",
                                            attrs: {
                                              "data-testid": "match-to-item",
                                            },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "el-form-item__label d-inline-block col-12",
                                              },
                                              [_vm._v("Match To")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  "default-first-option": "",
                                                  filterable: "",
                                                  autocomplete: "off",
                                                  "data-testid":
                                                    "match-to-select",
                                                },
                                                on: {
                                                  change: _vm.changeMatchTo,
                                                },
                                                model: {
                                                  value: _vm.match_to,
                                                  callback: function ($$v) {
                                                    _vm.match_to = $$v
                                                  },
                                                  expression: "match_to",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "area code",
                                                    label: "Area Code",
                                                    disabled:
                                                      _vm.disableAreaCode,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "el-option",
                                                  {
                                                    attrs: {
                                                      value: "left",
                                                      label:
                                                        "First part of number",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            First part of number\n                                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-option",
                                                  {
                                                    attrs: {
                                                      value: "any",
                                                      label:
                                                        "Anywhere in number",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            Anywhere in number\n                                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-option",
                                                  {
                                                    attrs: {
                                                      value: "right",
                                                      label:
                                                        "Last part of number",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            Last part of number\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass:
                                              "col-2 d-inline-block pl-0 pr-2 align-bottom",
                                            attrs: {
                                              "data-testid": "area-code-item",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  "data-testid":
                                                    "search-button",
                                                  disabled: _vm.loading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.changeAreaCode.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-search",
                                                }),
                                                _vm._v(
                                                  "\n                                        Search\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      "label-position": "left",
                                      "data-testid": "type-form",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { staticClass: "mb-0 mt-3" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "el-form-item__label col-12 px-0",
                                          },
                                          [_vm._v("Type")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                ["US", "CA"].includes(_vm.campaign.country_code)
                                  ? _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "no-select mb-2",
                                        attrs: {
                                          size: "small",
                                          "data-testid":
                                            "phone-number-type-radio-group",
                                        },
                                        on: {
                                          change: _vm.changePhoneNumberType,
                                        },
                                        model: {
                                          value: _vm.phone_number_type,
                                          callback: function ($$v) {
                                            _vm.phone_number_type = $$v
                                          },
                                          expression: "phone_number_type",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-button",
                                          {
                                            attrs: {
                                              label:
                                                _vm.IncomingNumberTypes
                                                  .LOCAL_PHONE_NUMBERS,
                                              "data-testid": "local-radio",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Local\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio-button",
                                          {
                                            attrs: {
                                              label:
                                                _vm.IncomingNumberTypes
                                                  .TOLL_FREE_NUMBERS,
                                              "data-testid": "toll-free-radio",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Toll-free\n                                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "no-select mb-2",
                                        attrs: {
                                          size: "small",
                                          "data-testid":
                                            "phone-number-type-radio-group",
                                        },
                                        on: {
                                          change: _vm.changePhoneNumberType,
                                        },
                                        model: {
                                          value: _vm.phone_number_type,
                                          callback: function ($$v) {
                                            _vm.phone_number_type = $$v
                                          },
                                          expression: "phone_number_type",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: _vm.local_tooltip,
                                              placement: "top",
                                              "data-testid": "local-tooltip",
                                              disabled:
                                                !_vm.shouldDisableLocalOption(
                                                  _vm.campaign.country_code
                                                ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              {
                                                attrs: {
                                                  label:
                                                    _vm.IncomingNumberTypes
                                                      .LOCAL_PHONE_NUMBERS,
                                                  disabled:
                                                    _vm.shouldDisableLocalOption(
                                                      _vm.campaign.country_code
                                                    ),
                                                  "data-testid": "local-radio",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Local\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: _vm.mobile_tooltip,
                                              placement: "top",
                                              "data-testid": "mobile-tooltip",
                                              disabled:
                                                !_vm.shouldDisableMobileOption(
                                                  _vm.campaign.country_code
                                                ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-button",
                                              {
                                                attrs: {
                                                  label:
                                                    _vm.IncomingNumberTypes
                                                      .MOBILE_PHONE_NUMBERS,
                                                  disabled:
                                                    _vm.shouldDisableMobileOption(
                                                      _vm.campaign.country_code
                                                    ),
                                                  "data-testid": "mobile-radio",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Mobile\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.loading,
                                        expression: "loading",
                                      },
                                    ],
                                    ref: "campaign_incoming_number",
                                    attrs: {
                                      "label-position": "top",
                                      "label-width": "300px",
                                      rules: _vm.rules_2,
                                      model: _vm.campaign,
                                      "data-testid": "incoming-number-form",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "incoming_number",
                                          "data-testid": "incoming-number-item",
                                        },
                                      },
                                      [
                                        [
                                          _vm.IncomingNumberTypes
                                            .LOCAL_PHONE_NUMBERS,
                                          _vm.IncomingNumberTypes
                                            .MOBILE_PHONE_NUMBERS,
                                        ].includes(_vm.phone_number_type)
                                          ? _c("div", [
                                              !_vm.changed
                                                ? _c("div", [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "el-form-item__label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                Phone Numbers "
                                                        ),
                                                        _vm.local_phone_number !==
                                                        null
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "to " +
                                                                  _vm._s(
                                                                    _vm.local_phone_number
                                                                  )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _vm._v(
                                                          "\n                                                (" +
                                                            _vm._s(
                                                              _vm._f(
                                                                "toCurrency"
                                                              )(
                                                                _vm._f(
                                                                  "fixRounding"
                                                                )(
                                                                  _vm.auth.user
                                                                    .profile
                                                                    .rate
                                                                    .local_pn,
                                                                  2
                                                                )
                                                              )
                                                            ) +
                                                            "/mo surcharge)\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _c("div", [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "el-form-item__label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Phone Numbers " +
                                                            _vm._s(
                                                              _vm.searched_string
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                              _vm._v(" "),
                                              _vm.available_numbers
                                                ? _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "el-form-item__content",
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio-group",
                                                          {
                                                            staticClass:
                                                              "w-full",
                                                            attrs: {
                                                              "data-testid":
                                                                "incoming-number-radio-group",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.changeNumber,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.campaign
                                                                  .incoming_number,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.campaign,
                                                                    "incoming_number",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "campaign.incoming_number",
                                                            },
                                                          },
                                                          [
                                                            _vm._l(
                                                              Math.ceil(
                                                                _vm
                                                                  .available_numbers
                                                                  .length / 4
                                                              ),
                                                              function (i) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row",
                                                                  },
                                                                  _vm._l(
                                                                    _vm.available_numbers.slice(
                                                                      (i - 1) *
                                                                        4,
                                                                      i * 4
                                                                    ),
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-md-3 col-6",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-radio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    item.sid,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                " +
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "fixPhone"
                                                                                    )(
                                                                                      item.phone_number
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm
                                                              .available_numbers
                                                              .length === 0
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-12",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "b-l pl-2 text-sm text-muted",
                                                                            attrs:
                                                                              {
                                                                                "data-testid":
                                                                                  "no-numbers-available",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                No numbers available\n                                                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.phone_number_type ===
                                        _vm.IncomingNumberTypes
                                          .TOLL_FREE_NUMBERS
                                          ? _c("div", [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "el-form-item__label",
                                                },
                                                [
                                                  _vm._v(
                                                    "Toll-Free Numbers (" +
                                                      _vm._s(
                                                        _vm._f("toCurrency")(
                                                          _vm._f("fixRounding")(
                                                            _vm.auth.user
                                                              .profile.rate
                                                              .tollfree_pn,
                                                            2
                                                          )
                                                        )
                                                      ) +
                                                      "/mo surcharge)"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "el-form-item__content",
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      staticClass: "w-full",
                                                      attrs: {
                                                        "data-testid":
                                                          "toll-free-number-radio-group",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeNumber,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.campaign
                                                            .incoming_number,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.campaign,
                                                            "incoming_number",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "campaign.incoming_number",
                                                      },
                                                    },
                                                    [
                                                      _vm._l(
                                                        Math.ceil(
                                                          _vm
                                                            .available_tollfree_numbers
                                                            .length / 4
                                                        ),
                                                        function (i) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            _vm._l(
                                                              _vm.available_tollfree_numbers.slice(
                                                                (i - 1) * 4,
                                                                i * 4
                                                              ),
                                                              function (item) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-3 col-6",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-radio",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            item.sid,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                            " +
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "fixPhone"
                                                                              )(
                                                                                item.phone_number
                                                                              )
                                                                            ) +
                                                                            "\n                                                        "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      _vm
                                                        .available_tollfree_numbers
                                                        .length === 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "b-l pl-2 text-sm text-muted",
                                                                      attrs: {
                                                                        "data-testid":
                                                                          "no-numbers-available",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                                            No numbers available\n                                                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-right mt-4" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-left",
                        attrs: {
                          icon: "el-icon-arrow-left",
                          type: "info",
                          "data-testid": "back-button",
                        },
                        on: { click: _vm.backForm },
                      },
                      [_vm._v("\n                    Back\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                          "data-testid": "activate-fax-line-btn",
                          disabled: !_vm.validated || _vm.loading,
                        },
                        on: { click: _vm.submitForm },
                      },
                      [
                        _vm.is_fax
                          ? _c("span", [
                              _vm._v(
                                "\n                        Activate Fax\n                    "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n                        Activate Line\n                    "
                              ),
                            ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-arrow-right el-icon-right",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.current_step === _vm.Steps.STEP_LINE_REGISTRATION
          ? _c(
              "div",
              { staticClass: "text-center", attrs: { id: "step_3" } },
              [
                _c("LineRegistration", {
                  attrs: {
                    "data-testid": "line-registration-component",
                    campaign: _vm.campaign_created,
                    available_messaging_services:
                      _vm.available_messaging_services,
                    available_messaging_services_loading:
                      _vm.available_messaging_services_loading,
                  },
                  on: { "step-changed": _vm.stepChanged },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.current_step === _vm.Steps.STEP_FINISH
          ? _c(
              "div",
              { staticClass: "text-center", attrs: { id: "step_4" } },
              [
                _c("line-details", {
                  attrs: {
                    campaign: _vm.campaign_created,
                    "data-testid": "line-details-component",
                  },
                  on: { close: _vm.close },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }