var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list workflow")
    ? _c(
        "div",
        [
          _c(
            "el-select",
            {
              staticClass: "w-full",
              attrs: {
                placeholder: _vm.placeholder,
                multiple: _vm.multiple,
                "multiple-limit": _vm.multiple ? _vm.multipleLimit : 0,
                size: _vm.size,
                filterable: "",
                clearable: "",
                "data-testid": "workflow-selector",
                "default-first-option": "",
                "collapse-tags": "",
              },
              model: {
                value: _vm.workflow_id,
                callback: function ($$v) {
                  _vm.workflow_id = $$v
                },
                expression: "workflow_id",
              },
            },
            [
              _vm.activeWorkflowsAlphabeticalOrder &&
              _vm.activeWorkflowsAlphabeticalOrder.length > 0
                ? _c(
                    "el-option-group",
                    {
                      key: "Active Sequences",
                      attrs: { label: "Active Sequences" },
                    },
                    _vm._l(
                      _vm.activeWorkflowsAlphabeticalOrder,
                      function (workflow) {
                        return _c("el-option", {
                          key: workflow.id,
                          attrs: { label: workflow.name, value: workflow.id },
                        })
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pausedWorkflowsAlphabeticalOrder &&
              _vm.pausedWorkflowsAlphabeticalOrder.length > 0
                ? _c(
                    "el-option-group",
                    {
                      key: "Paused Sequences",
                      attrs: { label: "Paused Sequences" },
                    },
                    _vm._l(
                      _vm.pausedWorkflowsAlphabeticalOrder,
                      function (workflow) {
                        return _c("el-option", {
                          key: workflow.id,
                          attrs: { label: workflow.name, value: workflow.id },
                        })
                      }
                    ),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }