var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "toast-kyc",
        visible: _vm.show,
        "modal-append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        "append-to-body": true,
        top: "20px",
        width: "80%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _vm._v(
            "\n        Your trial account access has changed based on your business information submitted, to access the new permissions, \n        "
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "text" },
              on: { click: _vm.reload },
            },
            [_vm._v("\n            Reload Now\n        ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }