<template>
  <div class="app-body" id="view">
    <div class="insights">
      <div id="insights-filters" class="padding mt-5 pt-3" style="position: relative; overflow: hidden;">
        <div class="row mt-2">
          <div class="col-12 text-center">
            <!-- `selectedInsight` watcher calls `loadInsight` method -->
            <el-radio-group class="col-12 no-select pull-left mt-1"
                            size="small"
                            v-model="selectedInsight"
                            >
              <el-radio-button :key="insight.id"
                               :label="insight"
                               v-for="(insight) in insights">
                {{insight.name}}
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div id="insights-show" v-if="loadedInsightUrl">
        <insights-show :url="loadedInsightUrl"></insights-show>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '../../auth'
import {mapGetters, mapState} from 'vuex'
import {acl_mixin,  date_mixin, scroll_mixin, styling_mixin} from '../../mixins'
import InsightsShow from "./insights-show.vue"

export default {
  name: 'insights-index',

  mixins: [
    styling_mixin,
    date_mixin,
    acl_mixin,
    scroll_mixin
  ],

  components: {InsightsShow},

  props: {
    slug: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      auth: auth,
      env: null,
      loading: false,
      selectedInsight: null,
      loadedInsightUrl: null,
      insights: [],
    }
  },

  computed: {
    ...mapState('cache', ['current_company']),
    ...mapGetters('cache', ['isTrial', 'isSimpSocial']),
  },

  /**
   * Fetches the build information and sets the environment variable.
   * Called before the component is created.
   */
  beforeCreate() {
    axios.get('/build-info.json')
        .then(res => {
          this.env = res.data.env
        })
        .catch(err => {
          console.log(err)
        })
  },

  /**
   * Sets the title and fetches insights when the component is mounted.
   */
  mounted() {
    this.setTitle()
    this.fetchInsights()
  },

  /**
   * Checks user authentication and permissions before entering the route.
   * Redirects to appropriate pages based on user role and permissions.
   */
  beforeRouteEnter(to, from, next) {
    auth.check()
        .then((res) => {
          next(vm => {
            if (!vm.hasPermissionTo('list insights')) {
              next(vm.goBack())
            }
          })

          if (res.data.user.is_reseller) {
            // redirect to account management portal if the company is a reseller
            next({name: 'Account Management Portal'})
          } else {
            next()
          }
        })
        .catch((err) => {
          next({name: 'Login', query: {redirect: to.fullPath}})
        })
  },

  watch: {
    selectedInsight: function () {
      this.getInsightUrl()
    }
  },

  methods: {
    /**
     * Initializes the selected insight based on the provided slug or the number of insights available.
     *
     * If a slug is provided in the url, it searches for an insight with a matching slug and sets it as the selected insight.
     * If no slug is provided and there is exactly one insight available, it sets that insight as the selected insight.
     */
    initializeSelectedInsight() {
      if (this.slug) {
        this.selectedInsight = this.insights.find(insight => insight.slug === this.slug)
      } else if (this.insights.length === 1) {
        this.selectedInsight = this.insights[0]
      }
    },

    /**
     * Loads the selected insight by making an API call.
     * Sets the `loadedInsight` and updates the URL with the selected insight's slug.
     */
    getInsightUrl() {
      this.loading = true
      this.loadedInsightUrl = null

      axios.get('/api/v2/insights/' + this.selectedInsight.id + '/url')
          .then(response => {
            this.loadedInsightUrl = response.data.url
          })
          .catch(error => {
            console.error('Error fetching insight url:', error)
          })
      this.loading = false

      // check if the current route is `/insights` and the selected insight has a slug
      this.$router.push({
        name: 'View Insight',
        params: {
          slug: this.selectedInsight.slug
        }
      })
    },

    /**
     * Fetches the list of insights by making an API call.
     * Initializes the selected insight after fetching the insights.
     */
    fetchInsights() {
      this.loading = true
      axios.get('/api/v2/insights')
          .then(response => {
            this.insights = response.data
            this.initializeSelectedInsight()
          })
          .catch(error => {
            console.error('Error fetching insights:', error)
          })

      this.loading = false
    },

    /**
     * Sets the page title by making an API call to fetch static data.
     * If an error occurs, sets a default title and handles the error.
     */
    setTitle() {
      axios.get('/get-statics')
          .then(res => {
            let name = res.data.name
            this.setPageTitle('Insights - ' + name)
          })
          .catch(err => {
            this.setPageTitle('Insights - Aloware')
            console.log(err)
            this.$root.handleErrors(err.response)
          })
    },
  },

}
</script>
