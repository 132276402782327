<template>
    <div class="filter-attribution"
         v-on-clickaway="closeRingGroupList">
        <el-badge type="success"
                  class="filter-counter"
                  :value="ringGroups.length"
                  :hidden="ringGroups.length === 0">
            <el-button size="small"
                       round
                       plain
                       @click="toggleRingGroupList">
                Ring Groups
            </el-button>
        </el-badge>
        <div class="filter-attribution-data-list"
             v-if="show_ring_group_list">
            <div class="d-flex flex-row justify-content-end mb-2">
                <el-button size="small"
                           class="border-0" @click="closeRingGroupList">
                    Close
                </el-button>
            </div>
            <ring-group-selector class="w-100 filter-select"
                           place-holder="Filter Ring Groups"
                           :multiple="true"
                           :value="ringGroups"
                           @change="setTempRingGroups">
            </ring-group-selector>
            <div class="d-flex flex-row justify-content-between mt-4">
                <el-button type="default" size="small" round @click="clear">
                    Clear
                </el-button>
                <el-button type="success" size="small" round @click="apply">
                    Apply
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import DashboardFilter from '../../../filters/dashboard-filter'
import RingGroupSelector from '../../ring-group-selector'
import {mixin as clickaway} from 'vue-clickaway'

export default {
    mixins: [clickaway],

    components: {RingGroupSelector},

    data() {
        return {
            show_ring_group_list: false,
            temp_ring_groups: []
        }
    },

    computed: {
        ringGroups() {
            return DashboardFilter.ringGroups()
        }
    },

    methods: {
        toggleRingGroupList() {
            this.show_ring_group_list = !this.show_ring_group_list
        },

        clear() {
            this.temp_ring_groups = []
            this.apply()
        },

        apply() {
            DashboardFilter.setRingGroups(this.temp_ring_groups)
            this.show_ring_group_list = false
        },

        setTempRingGroups(ring_groups) {
            this.temp_ring_groups = ring_groups
        },

        closeRingGroupList() {
            this.show_ring_group_list = false
        }
    }
}
</script>
<style lang="scss">
.filter-attribution {
    position: relative;
    z-index: 901;
}

.filter-counter sup.el-badge__content {
    top: 3px !important;
    right: 14px !important;
}

.filter-attribution-data-list {
    padding: 1rem;
    border: solid 1px #ddd;
    border-radius: 5px;
    position: absolute;
    top: 40px;
    left: 0;
    background-color: white;
    width: 20rem;
}
</style>
