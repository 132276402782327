var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-alert-box mb-3" },
    [
      _c(
        "el-alert",
        { attrs: { type: "info", closable: false, "show-icon": "" } },
        [
          _c("div", { staticClass: "no-select" }, [
            _c(
              "p",
              { staticClass: "info-text" },
              [
                _c("span", [_vm._v(_vm._s(_vm.text))]),
                _vm._v(" "),
                _vm.kbLink && !_vm.isSimpSocial
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "large", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openLink(_vm.kbLink)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Learn More\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }