<template>
    <div v-if="hasPermissionTo('list sms template')"
         class="w-100">
        <template-list template_scope="user"
                       class="pt-2"
                       :short="false"
                       :user_id="user_id"
                       v-if="user_id"/>
        <template-list template_scope="company"
                       class="pt-2"
                       :short="false"
                       v-else/>
    </div>
</template>

<script>
import auth from '../../auth'
import {acl_mixin} from '../../mixins'
import TemplateDialog from '../template-dialog'
import TemplateList from '../template-list'

export default {
    components: {TemplateList, TemplateDialog},

    mixins: [acl_mixin],

    props: {
        user_id: {
            type: Number,
            required: false,
            default: undefined
        }
    },

    data () {
        return {
            auth: auth
        }
    }
}
</script>
