<template>
    <div>
        <div class="vertical-center text-center mb-4">
			<span v-if="filter.from_date"
                  class="call-log-head">
				{{ total_locations || 0 }} User Locations from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
			</span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
				{{ total_locations || 0 }} User Locations
                <strong>All Time</strong>
			</span>
        </div>
        <div class="placeholder text-center d-flex align-items-center justify-content-center"
             :class="{ blink: loading }"
             v-if="loading || campaigns.length == 0"
             style="height: 450px">
            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div style="height: 450px"
             v-else>
            <highmaps v-bind:id="graph_id"
                      v-show="is_done && total_locations > 0"
                      :options="options"
                      style="height: 450px"
                      ref="highchart">
            </highmaps>
            <div class="el-table__empty-block"
                 v-show="is_done && total_locations <= 0">
                <span class="el-table__empty-text"
                      style="color: #606266;">
                    No Data
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {map_graph_mixin, report_mixin} from '../mixins'
    import auth from '../auth'
    import usAll from '../usAll.json'
    import caAll from '../caAll.json'

    export default {
        props: {
            report_index: {
                default: 0
            }
        },

        mixins: [report_mixin, map_graph_mixin],

        data() {
            return {
                auth: auth,
                loading: false,
                graph_id: 'user-location-graph',
                report_type: 'location_v_user',
                options: {
                    chart: {
                        map: auth.user.profile.country == 'US' ? usAll : caAll
                    },
                    tooltip: {
                        formatter: function () {
                            //there is no data fetched when map is zooming so put on try and catch statements
                            try {
                                let toReturn = '<b>' + this.series.name + '</b> <br>'
                                toReturn += 'has ' + '<b>' + this.point.value.toLocaleString() + '</b> Caller Number Locations to'
                                toReturn += ' <b>' + this.point.name + '</b>'

                                return toReturn
                            } catch (err) {
                                //console.log('Map is zooming')
                            }
                        }
                    },
                }
            }
        },
    }
</script>
