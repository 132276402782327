var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _vm.shouldShowAloAis
        ? _c(
            "div",
            { staticClass: "container-listing" },
            [
              _c("div", { staticClass: "container-header d-flex" }, [
                _c("h3", { staticClass: "listing-heading" }, [_vm._v("AloAi")]),
                _vm._v(" "),
                _c("div", { staticClass: "ml-auto" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success", round: "", size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                name: "AloAi: Create new chatbot",
                              })
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus mr-1" }),
                          _vm._v(" "),
                          _c("strong", [_vm._v("New Chatbot")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("alo-ai-table", {
                attrs: { chatbots: _vm.chatbots },
                on: {
                  "chatbot-update": _vm.onChatbotUpdate,
                  "chatbot-delete": _vm.onChatbotDelete,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.shouldShowAloAis && _vm.shouldShowUpgradeNow
        ? _c("upgrade-now-page", {
            staticClass: "mt-5",
            attrs: {
              "image-link": "/assets/images/AloAi.svg",
              text: "The leading conversational AI supercharging your path to explosive sales growth",
              "extra-text":
                "Enhance your team’s efficiency and lower operational expenses by automating conversations throughout the entire customer journey.Our AI bot replaces most BDRs and SDRs and will escalate automatically to humans.",
              "title-text": "AloAi Text Bot",
              "kb-link": "https://aloware.com/ai-bot/",
              "custom-link":
                "https://share.hsforms.com/1RLbs7DggS1i2Lhwld-nwlQ2qfyk",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }