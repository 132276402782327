<template>
    <div>
        <div class="form-label">
            <h5>Filters</h5>
            <small>
                Filters events that will be sent upstream to Webhook.
                Note that if you select a filter but no options for that filter is selected,
                no events will be sent.
            </small>
        </div>

        <div class="py-2">
            <div class="switch-label">
                <el-switch v-model="webhook.filters.status"
                           active-color="#00a344">
                </el-switch>
                <label>Enable Filters</label>
            </div>
        </div>

        <div v-show="webhook.filters.status">
            <div class="card mb-3">
                <div class="py-2 px-3 d-flex">
                    <div class="switch-label">
                        <el-switch v-model="webhook.filters.filter_direction"
                                   active-color="#00a344">
                        </el-switch>
                        <label>Direction</label>
                    </div>
                </div>
                <div v-if="webhook.filters.filter_direction"
                     class="card-body pt-0 px-3">
                    <p class="mb-0">Filter by communication direction.</p>
                    <div class="row">
                        <div class="col">
                            <el-checkbox-group v-model="webhook.filters.direction">
                                <el-checkbox :label="CommunicationDirections.INBOUND"
                                             class="mr-2"
                                             border>
                                    Inbound
                                </el-checkbox>
                                <el-checkbox :label="CommunicationDirections.OUTBOUND"
                                             border>
                                    Outbound
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="py-2 px-3 d-flex">
                    <div class="switch-label">
                        <el-switch v-model="webhook.filters.filter_type"
                                   active-color="#00a344">
                        </el-switch>
                        <label>Type</label>
                    </div>
                </div>
                <div v-if="webhook.filters.filter_type"
                     class="card-body pt-0 px-3">
                    <p class="mb-0">Filter by communication type.</p>
                    <div>
                        <el-select v-model="webhook.filters.type"
                                   class="w-full"
                                   multiple>
                            <el-option v-for="type in filtered_communication_types"
                                       :value="type"
                                       :label="type | fixCommType"
                                       :key="type">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="py-2 px-3 d-flex">
                    <div class="switch-label">
                        <el-switch v-model="webhook.filters.filter_communication_disposition_status"
                                   active-color="#00a344">
                        </el-switch>
                        <label>Communication Disposition Status</label>
                    </div>
                </div>
                <div v-if="webhook.filters.filter_communication_disposition_status"
                     class="card-body pt-0 px-3">
                    <p class="mb-0">Filter by communication disposition status.</p>
                    <div>
                        <el-select v-model="webhook.filters.communication_disposition_statuses"
                                   class="w-full"
                                   multiple>
                            <el-option v-for="status in filtered_communication_disposition_statuses"
                                       :value="status"
                                       :label="status | translateDispositionStatusText | fixName"
                                       :key="status">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="py-2 px-3 d-flex">
                    <div class="switch-label">
                        <el-switch v-model="webhook.filters.filter_disposed_contacts"
                                   active-color="#00a344">
                        </el-switch>
                        <label>Contacts</label>
                    </div>
                </div>
                <div v-if="webhook.filters.filter_disposed_contacts"
                     class="card-body pt-0 px-3">
                    <p class="mb-0">Filter by contact type.</p>
                    <div>
                        <el-radio-group v-model="webhook.filters.contacts">
                            <el-radio label="all"
                                      border>
                                All
                            </el-radio>
                            <el-radio label="disposed_contacts"
                                      border>
                                Disposed contacts
                            </el-radio>
                            <el-radio label="selected_disposition_statuses"
                                      border>
                                Selected dispositions
                            </el-radio>
                        </el-radio-group>
                        <disposition-status-selector
                            v-show="webhook.filters.contacts === 'selected_disposition_statuses'"
                            v-model="webhook.filters.disposition_status_ids"
                            select_style="width: 100%;"
                            multiple
                            clearable
                            @change="changeSyncContactsWithDisposition">
                        </disposition-status-selector>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="py-2 px-3 d-flex">
                    <div class="switch-label">
                        <el-switch v-model="webhook.filters.filter_lines"
                                   active-color="#00a344">
                        </el-switch>
                        <label>Skip lines</label>
                    </div>
                </div>
                <div v-if="webhook.filters.filter_lines"
                     class="card-body pt-0 px-3">
                    <p class="mb-0">Here you can exclude the lines you don't want to send to this integration.</p>
                    <div>
                        <campaign-selector select_style="width: 100%;"
                                           multiple
                                           v-model="webhook.filters.skip_line_ids"
                                           @change="changeSkipLines">
                        </campaign-selector>
                    </div>
                </div>
            </div>

            <div class="card mb-3">
                <div class="py-2 px-3 d-flex">
                    <div class="switch-label">
                        <el-switch v-model="webhook.filters.filter_call_duration"
                                   active-color="#00a344">
                        </el-switch>
                        <label>Duration</label>
                    </div>
                </div>
                <div v-if="webhook.filters.filter_call_duration"
                     class="card-body pt-0 px-3">
                    <p class="mb-1">
                        <span>Don’t send calls with</span>
                        <el-select size="mini"
                                   v-model="webhook.filters.call_duration_type"
                                   placeholder="Select Duration Type">
                            <el-option
                                v-for="item in call_type_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </p>
                    <p>
                        <span>that is less than</span>
                        <el-input-number size="mini"
                                         placeholder="Duration"
                                         v-model="webhook.filters.call_duration">
                        </el-input-number>
                        <span>minutes duration</span>
                    </p>
                </div>
            </div>

            <div class="card mb-3">
                <div class="py-2 px-3 d-flex">
                    <div class="switch-label">
                        <el-switch v-model="webhook.filters.filter_qualified_only"
                                   active-color="#00a344">
                        </el-switch>
                        <label>Qualified Contacts</label>
                    </div>
                </div>
                <div v-if="webhook.filters.filter_qualified_only"
                     class="card-body pt-0 px-3">
                    <p class="mb-1">
                        Only create contacts when a qualified communication passes the filters
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DispositionStatusSelector from "../../disposition-status-selector"
import CampaignSelector from "../../campaign-selector"
import * as CommunicationDirections from "../../../constants/communication-direction"
import * as CommunicationTypes from "../../../constants/communication-types"
import * as CommunicationDispositionStatus from "../../../constants/communication-disposition-status"

export default {
    name: "webhook-filter-manager",

    components: {CampaignSelector, DispositionStatusSelector},

    props: {
        value: {
            required: true
        }
    },

    data() {
        return {
            webhook: this.value,
            filtered_communication_types: [
                CommunicationTypes.CALL,
                CommunicationTypes.SMS,
                CommunicationTypes.RVM,
                CommunicationTypes.EMAIL,
                CommunicationTypes.NOTE,
                CommunicationTypes.SYSNOTE,
                CommunicationTypes.APPOINTMENT,
                CommunicationTypes.REMINDER
            ],
            filtered_communication_disposition_statuses: [
                CommunicationDispositionStatus.DISPOSITION_STATUS_COMPLETED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_MISSED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_FAILED_NEW,
                CommunicationDispositionStatus.DISPOSITION_STATUS_PLACED_NEW
            ],
            call_type_options: [
                {
                    value: 'duration',
                    label: 'Duration'
                }, {
                    value: 'talk_time',
                    label: 'Talk Time'
                }
            ],
            CommunicationDirections,
        }
    },

    methods: {
        changeSyncContactsWithDisposition(val) {
            this.webhook.filters.disposition_status_ids = val
        },

        changeSkipLines(val) {
            this.webhook.filters.skip_line_ids = val
        }
    }
}
</script>
