<template>
    <el-popover trigger="click"
                placement="top"
                content="asd"
                width="380">
        <div class="popover-metric">
            <div class="mb-4">
                <h6>{{ label }}</h6>
                <p>{{ description }}</p>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <div class="text-center">
                        <h4  v-if="format === 'numFormat'"
                             class="mb-0">
                            {{ metric.previous_value | numFormat }}
                        </h4>
                        <h4 v-if="format === 'fixFullDuration'"
                            class="mb-0">
                            {{ metric.previous_value | fixFullDuration}}
                        </h4>
                        <label class="m-0 d-block">Previous period value</label>
                        <p class="mt-1" style="font-size: .77rem;">{{ metric.previous_date_range }}</p>
                    </div>
                </div>
                <div class="col">
                    <div class="text-center">
                        <h4 v-if="format === 'numFormat'"
                            class="mb-0">
                            {{ metric.current_value | numFormat}}
                        </h4>
                        <h4 v-if="format === 'fixFullDuration'"
                            class="mb-0">
                            {{ metric.current_value | fixFullDuration}}
                        </h4>
                        <label class="m-0 d-block">Current period value</label>
                        <p class="mt-1" style="font-size: .77rem;">{{ metric.current_date_range }}</p>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <el-button type="success"
                           size="small"
                           class="px-4">
                    Open Communication Logs
                </el-button>
            </div>
        </div>

        <div slot="reference"
             :class="'metric-widget' + (is_card ? ' card p-3':'') + (no_border ? ' no-border' : '')">
            <div>
                <div class="mb-1">
                    <h2>
                        <span v-if="format === 'numFormat'">{{ metric.current_value | numFormat }}</span>
                        <span v-if="format === 'fixFullDuration'">{{ metric.current_value | fixFullDuration }}</span>
                    </h2>
                </div>
                <div class="mb-1"
                     :class="difference_class">
                    <span v-if="format === 'numFormat'">{{ ( metric.difference > 0 ? '+' : '') }}{{ metric.difference | numFormat }}</span>
                    <span v-if="format === 'fixFullDuration'">{{ ( metric.difference > 0 ? '+' : '') }}{{ metric.difference | fixFullDuration }}</span>

                    <small>({{ metric.percentage }}%)</small>

                    <i v-if="metric.difference > 0" class="fa fa-caret-up"></i>
                    <i v-if="metric.difference < 0" class="fa fa-caret-down"></i>
                </div>
                <p>{{ label }}</p>
            </div>
        </div>
    </el-popover>
</template>

<script>
    export default {
        name: "metric-widget",
        props: {
            label: {
                required: true
            },
            metric: {
                default: function() {
                    return {}
                },
                required: true
            },
            is_card: {
                default: false,
                type: Boolean
            },
            description: {
                required: false,
                default: ''
            },
            format: {
                default: 'numFormat'
            },
            no_border: {
                required: false,
                default: false,
                type: Boolean
            }
        },

        computed: {
            loading() {
                return _.isEmpty(this.metric)
            },

            difference_class() {
                let type = 'decrease';
                if (this.metric.difference > 0 || (this.metric.difference < 0 && this.metric.inverse)) {
                    type = 'increase'
                }

                if (this.metric.difference < 0 || (this.metric.difference > 0 && this.metric.inverse)) {
                    type = 'decrease'
                }

                if (this.metric.difference === 0) {
                    type = 'equal'
                }

                return 'difference ' + type
            },
        }
    }
</script>
