<template>
    <div class="filter-attribution"
         v-on-clickaway="closePhoneNumberList">
        <el-badge type="success"
                  class="filter-counter"
                  :value="phoneNumbers.length"
                  :hidden="phoneNumbers.length === 0">
            <el-button size="small"
                       round
                       plain
                       @click="togglePhoneNumberList">
                Phone Numbers
            </el-button>
        </el-badge>
        <div class="filter-attribution-data-list"
             v-if="show_phone_number_list">
            <div class="d-flex flex-row justify-content-end mb-2">
                <el-button size="small"
                           class="border-0" @click="closePhone_numberList">
                    Close
                </el-button>
            </div>
            <el-select class="w-100 filter-select"
                       collapse-tags
                       filterable
                       multiple
                       placeholder="Select Numbers"
                       v-model="temp_phone_numbers"
                       @change="setTempPhoneNumbers">
                <el-option :key="index + '-' + item.phone_number"
                           :label="getFixPhone(item.phone_number)"
                           :value="item.id"
                           v-for="(item, index) in incomingNumbers">
                    <span>{{ getFixPhone(item.phone_number) }}</span>
                </el-option>
            </el-select>
            <div class="d-flex flex-row justify-content-between mt-4">
                <el-button type="default" size="small" round @click="clear">
                    Clear
                </el-button>
                <el-button type="success" size="small" round @click="apply">
                    Apply
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import DashboardFilter from '../../../filters/dashboard-filter'
import {mixin as clickaway} from 'vue-clickaway'

export default {
    mixins: [clickaway],
    data() {
        return {
            show_phone_number_list: false,
            temp_phone_numbers: [],
            campaigns: []
        }
    },

    computed: {
        phoneNumbers() {
            return DashboardFilter.phoneNumbers()
        },

        incomingNumbers() {
            let campaigns = _.clone(this.campaigns)
            let incoming_numbers = []
            incoming_numbers = campaigns.map(campaign => (campaign.incoming_numbers && campaign.incoming_numbers.length > 0) ? campaign.incoming_numbers : null).filter(o => o !== null)
            if (incoming_numbers.length > 0) {
                incoming_numbers = _.flatten(incoming_numbers)
                return incoming_numbers.sort((a, b) => {
                    let textA = a.phone_number.toString()
                    let textB = b.phone_number.toString()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }
            return []
        }
    },

    mounted() {
        this.getCampaigns()
    },

    methods: {
        togglePhoneNumberList() {
            this.show_phone_number_list = !this.show_phone_number_list
        },

        clear() {
            this.temp_phone_numbers = []
            this.apply()
        },

        apply() {
            DashboardFilter.setPhoneNumbers(this.temp_phone_numbers)
            this.show_phone_number_list = false
        },

        setTempPhoneNumbers(phone_numbers) {
            this.temp_phone_numbers = phone_numbers
        },

        closePhoneNumberList() {
            this.show_phone_number_list = false
        },

        getFixPhone(phoneNumber, format = 'NATIONAL') {
            return this.$options.filters.fixPhone(phoneNumber, format)
        },

        getCampaigns() {
            axios.get('api/v1/campaign')
                .then(res => {
                    this.campaigns = res.data
                })
        },
    }
}
</script>
<style lang="scss">
    .filter-attribution {
        position: relative;
        z-index: 901;
    }

    .filter-counter sup.el-badge__content {
        top: 3px !important;
        right: 14px !important;
    }

    .filter-attribution-data-list {
        padding: 1rem;
        border: solid 1px #ddd;
        border-radius: 5px;
        position: absolute;
        top: 40px;
        left: 0;
        background-color: white;
        width: 20rem;
    }
</style>
