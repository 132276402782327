<template>
    <div class="app-body"
         id="view"
         v-if="campaign">
        <el-dialog :title="campaign.name"
                   :visible.sync="dialogVisible"
                   width="50%"
                   v-if="hasPermissionTo('view campaign')">
            <campaign-details :campaign="campaign"></campaign-details>
        </el-dialog>

        <div class="fixed-header padding pt-0 pb-0" :style="{top: (top_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="col-12 p-2">
                    <div class="d-flex">
                        <div class="d-flex flex-fill">
                            <comm-advanced-filters-wrapper :campaign_id="campaign_id"></comm-advanced-filters-wrapper>
                        </div>
                        <div class="ml-auto">
                            <router-link
                                :to="{ name: 'Line Settings', params: {campaign_id: campaign_id }}"
                                v-if="hasPermissionTo('update campaign')">
                                <button class="btn btn-md greenish ml-2"
                                        style="height: 40px;">
                                    <i class="fa fa-cogs"></i>
                                    Settings
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="section === 'Activities'">
            <div class="padding pt-3"
                 v-if="hasPermissionTo('list report')">
                <div class="row">
                    <div class="col-12 p-0 mt-5">
                        <new-summary-report :campaign_id="campaign_id"></new-summary-report>
                    </div>
                </div>
            </div>

            <div class="padding"
                 v-if="hasPermissionTo('list report') && show_graph">
                <div class="row box">
                    <div class="col-12">
                        <div class="no-effect">
                            <div class="box-body">
                                <activity-graph base="campaign"
                                                :campaign_id="campaign_id"
                                                :is_first_load="reports_first_load">
                                </activity-graph>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="padding"
                 v-if="hasPermissionTo('list communication')">
                <div class="row box">
                    <div class="col-12">
                        <div class="no-effect">
                            <div class="box-body">
                                <comm-log-table :campaign_id="campaign_id"></comm-log-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="section === 'Transcriptions' && shouldDisplayTranscriptionSections">
            <div class="padding mt-5 pt-3">
                <transcription-reporting-panel :campaign_id="campaign_id"/>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import auth from '../../auth'
import {
    acl_mixin,
    chargebee_mixin,
    filter_mixin,
    scroll_mixin,
    styling_mixin
} from '../../mixins'
import * as AnswerTypes from '../../constants/answer-types'
import * as CampaignCallRouterBehavior from '../../constants/campaign-call-router-behaviors'
import TranscriptionReportingPanel from '../../components/transcription-reporting-panel'

export default {
    components: {
        'transcription-reporting-panel': TranscriptionReportingPanel
    },

    mixins: [
        styling_mixin,
        chargebee_mixin,
        scroll_mixin,
        filter_mixin,
        acl_mixin
    ],

    props: {
        campaign_id: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            campaign: null,
            dialogVisible: false,
            reports_first_load: true,
            AnswerTypes,
            CampaignCallRouterBehavior
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            ring_groups: state => state.ring_groups,
            show_graph: state => state.cache.show_graph
        }),

        ...mapGetters({
            section: 'getSection'
        }),

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        }
    },

    mounted() {
        this.setTitle()
        if (this.hasPermissionTo('view campaign')) {
            this.getCampaign(this.campaign_id).then(() => {
                VueEvent.fire('page_title_updated', this.campaign.name)
            })
        }
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Line Activity - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Line Activity - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        getCampaign: function (campaign_id) {
            return axios.get('/api/v1/campaign/' + campaign_id, {
                mode: 'no-cors',
            }).then(res => {
                this.campaign = res.data
                return Promise.resolve(res)
            }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
                return Promise.reject(err)
            })
        },

        editCampaign: function (campaign_id) {
            this.$router.push({name: 'Line Settings', params: {campaign_id: campaign_id}})
        },

        reset: function () {
            this.campaign = null
        },

        handleCommand(command) {
            if (command === 'view') {
                this.dialogVisible = true
            } else if (command === 'edit') {
                this.editCampaign(this.campaign.id)
            }
        },

        getRingGroup(id) {
            if (!id) {
                return null
            }
            let found = this.ring_groups.find(ring_group => ring_group.id === id)
            if (found) {
                return found
            }

            return null
        }
    },

    watch: {
        $route({params, query}) {
            this.reset()
            if (this.hasPermissionTo('view campaign')) {
                this.getCampaign(this.campaign_id).then(() => {
                    VueEvent.fire('page_title_updated', this.campaign.name)
                })
            }
        },
        'show_graph': function () {
            this.reports_first_load = false
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
