<template>
    <div v-loading="isAppLoading">
        <slot></slot>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: "app-wrapper",

    computed: {
        ...mapGetters([
            'isAppLoading',
            'userLoggedOut'
        ])
    },

    watch: {
        userLoggedOut() {
            if (this.userLoggedOut) {
                setTimeout(() => {
                    window.location.href = '/'
                }, 1000)
            }
        }
    }
}
</script>
