var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isAdminOrAgentOrSupervisor
      ? _c("div", [
          _c("div", { staticClass: "pb-0 pt-0" }, [
            _c("h5", { staticClass: "text-dark mb-1" }, [
              _vm._v("Contact Center Overview"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "margin mb-0" }, [
              _c(
                "span",
                [
                  _vm._v(
                    "\n                    for Today\n                    "
                  ),
                  _c("updating-date"),
                  _vm._v(
                    "\n                    (refresh to update results)\n                    "
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-xs greenish pointer",
                      attrs: { disabled: _vm.isLoading },
                      on: { click: _vm.reloadContactCenterAndAgentInfo },
                    },
                    [
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isLoading,
                              expression: "isLoading",
                            },
                          ],
                          staticClass: "material-icons loader",
                        },
                        [
                          _vm._v(
                            "\n                            \n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isLoading,
                              expression: "!isLoading",
                            },
                          ],
                          staticClass: "material-icons",
                        },
                        [_vm._v("refresh")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pb-0 pt-2" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/total-calls.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c(
                                "h4",
                                { staticClass: "m-a-0 mb-0 text-lg" },
                                [
                                  _vm.loading_contact_center_summary
                                    ? _c("span", { staticClass: "mockup" }, [
                                        _vm._v("1000"),
                                      ])
                                    : _c("ICountUp", {
                                        attrs: {
                                          startVal: _vm.count_up.startVal,
                                          endVal:
                                            _vm.contact_center_summary
                                              .total_calls,
                                          duration: _vm.count_up.duration,
                                          options: _vm.count_up.options,
                                        },
                                      }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Total Calls")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-answered-calls-green.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c(
                                "h4",
                                { staticClass: "m-a-0 mb-0 text-lg" },
                                [
                                  _vm.loading_contact_center_summary
                                    ? _c("span", { staticClass: "mockup" }, [
                                        _vm._v("1000"),
                                      ])
                                    : _c("ICountUp", {
                                        attrs: {
                                          startVal: _vm.count_up.startVal,
                                          endVal:
                                            _vm.contact_center_summary
                                              .answered_calls,
                                          duration: _vm.count_up.duration,
                                          options: _vm.count_up.options,
                                        },
                                      }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Answered Calls")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/missed-calls.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c(
                                "h4",
                                { staticClass: "m-a-0 mb-0 text-lg" },
                                [
                                  _vm.loading_contact_center_summary
                                    ? _c("span", { staticClass: "mockup" }, [
                                        _vm._v("1000"),
                                      ])
                                    : _c("ICountUp", {
                                        attrs: {
                                          startVal: _vm.count_up.startVal,
                                          endVal:
                                            _vm.contact_center_summary
                                              .missed_calls,
                                          duration: _vm.count_up.duration,
                                          options: _vm.count_up.options,
                                        },
                                      }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Missed Calls")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/abandoned-calls.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c(
                                "h4",
                                { staticClass: "m-a-0 mb-0 text-lg" },
                                [
                                  _vm.loading_contact_center_summary
                                    ? _c("span", { staticClass: "mockup" }, [
                                        _vm._v("1000"),
                                      ])
                                    : _c("ICountUp", {
                                        attrs: {
                                          startVal: _vm.count_up.startVal,
                                          endVal:
                                            _vm.contact_center_summary
                                              .abandoned_calls,
                                          duration: _vm.count_up.duration,
                                          options: _vm.count_up.options,
                                        },
                                      }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Abandoned Calls")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pb-0 pt-2" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/avg-talk-time.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c("h4", { staticClass: "m-a-0 mb-0 text-lg" }, [
                                _vm.loading_contact_center_summary
                                  ? _c("span", { staticClass: "mockup" }, [
                                      _vm._v("1000"),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixFullDuration")(
                                            _vm.contact_center_summary
                                              .average_talk_time
                                          )
                                        )
                                      ),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Average Talk Time")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/avg-wait-time.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c("h4", { staticClass: "m-a-0 mb-0 text-lg" }, [
                                _vm.loading_contact_center_summary
                                  ? _c("span", { staticClass: "mockup" }, [
                                      _vm._v("1000"),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixFullDuration")(
                                            _vm.contact_center_summary
                                              .average_wait_time
                                          )
                                        )
                                      ),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Average Wait Time")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/total-occupancy.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c("h4", { staticClass: "m-a-0 mb-0 text-lg" }, [
                                _vm.loading_contact_center_summary
                                  ? _c("span", { staticClass: "mockup" }, [
                                      _vm._v("1000"),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixFullDuration")(
                                            _vm.contact_center_summary
                                              .total_occupancy
                                          )
                                        )
                                      ),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Total Occupancy")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pb-0 pt-2" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _vm.shouldShowCalendar
                    ? _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.goToCalendar.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "box p-a overview-boxes" },
                                [
                                  _c("div", { staticClass: "pull-left m-r" }, [
                                    _c(
                                      "span",
                                      { staticClass: "w-48 text-center" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/assets/images/app-icons/appointments-set.svg",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "pull-left mt-2" }, [
                                    _c(
                                      "h4",
                                      { staticClass: "m-a-0 mb-0 text-lg" },
                                      [
                                        _vm.loading_contact_center_summary
                                          ? _c(
                                              "span",
                                              { staticClass: "mockup" },
                                              [_vm._v("1000")]
                                            )
                                          : _c("ICountUp", {
                                              attrs: {
                                                startVal: _vm.count_up.startVal,
                                                endVal:
                                                  _vm.contact_center_summary
                                                    .appointments,
                                                duration: _vm.count_up.duration,
                                                options: _vm.count_up.options,
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      { staticClass: "text-muted text-xs" },
                                      [_vm._v("Appointments Set")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shouldShowCalendar
                    ? _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.goToCalendar.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "box p-a overview-boxes" },
                                [
                                  _c("div", { staticClass: "pull-left m-r" }, [
                                    _c(
                                      "span",
                                      { staticClass: "w-48 text-center" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/assets/images/app-icons/reminders-set.svg",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "pull-left mt-2" }, [
                                    _c(
                                      "h4",
                                      { staticClass: "m-a-0 mb-0 text-lg" },
                                      [
                                        _vm.loading_contact_center_summary
                                          ? _c(
                                              "span",
                                              { staticClass: "mockup" },
                                              [_vm._v("1000")]
                                            )
                                          : _c("ICountUp", {
                                              attrs: {
                                                startVal: _vm.count_up.startVal,
                                                endVal:
                                                  _vm.contact_center_summary
                                                    .reminders,
                                                duration: _vm.count_up.duration,
                                                options: _vm.count_up.options,
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      { staticClass: "text-muted text-xs" },
                                      [_vm._v("Reminders Set")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-inbound-communications-green.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c(
                                "h4",
                                { staticClass: "m-a-0 mb-0 text-lg" },
                                [
                                  _vm.loading_contact_center_summary
                                    ? _c("span", { staticClass: "mockup" }, [
                                        _vm._v("1000"),
                                      ])
                                    : _c("ICountUp", {
                                        attrs: {
                                          startVal: _vm.count_up.startVal,
                                          endVal:
                                            _vm.contact_center_summary
                                              .sms_received,
                                          duration: _vm.count_up.duration,
                                          options: _vm.count_up.options,
                                        },
                                      }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Text Received")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/icon-outbound-communications-green.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c(
                                "h4",
                                { staticClass: "m-a-0 mb-0 text-lg" },
                                [
                                  _vm.loading_contact_center_summary
                                    ? _c("span", { staticClass: "mockup" }, [
                                        _vm._v("1000"),
                                      ])
                                    : _c("ICountUp", {
                                        attrs: {
                                          startVal: _vm.count_up.startVal,
                                          endVal:
                                            _vm.contact_center_summary.sms_sent,
                                          duration: _vm.count_up.duration,
                                          options: _vm.count_up.options,
                                        },
                                      }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Text Sent")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pb-0 pt-2" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/email-sent.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c(
                                "h4",
                                { staticClass: "m-a-0 mb-0 text-lg" },
                                [
                                  _vm.loading_contact_center_summary
                                    ? _c("span", { staticClass: "mockup" }, [
                                        _vm._v("1000"),
                                      ])
                                    : _c("ICountUp", {
                                        attrs: {
                                          startVal: _vm.count_up.startVal,
                                          endVal:
                                            _vm.contact_center_summary
                                              .emails_sent,
                                          duration: _vm.count_up.duration,
                                          options: _vm.count_up.options,
                                        },
                                      }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Email Sent")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/email-received.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c(
                                "h4",
                                { staticClass: "m-a-0 mb-0 text-lg" },
                                [
                                  _vm.loading_contact_center_summary
                                    ? _c("span", { staticClass: "mockup" }, [
                                        _vm._v("1000"),
                                      ])
                                    : _c("ICountUp", {
                                        attrs: {
                                          startVal: _vm.count_up.startVal,
                                          endVal:
                                            _vm.contact_center_summary
                                              .emails_received,
                                          duration: _vm.count_up.duration,
                                          options: _vm.count_up.options,
                                        },
                                      }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Email Received")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/fax-sent.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c(
                                "h4",
                                { staticClass: "m-a-0 mb-0 text-lg" },
                                [
                                  _vm.loading_contact_center_summary
                                    ? _c("span", { staticClass: "mockup" }, [
                                        _vm._v("1000"),
                                      ])
                                    : _c("ICountUp", {
                                        attrs: {
                                          startVal: _vm.count_up.startVal,
                                          endVal:
                                            _vm.contact_center_summary
                                              .faxes_sent,
                                          duration: _vm.count_up.duration,
                                          options: _vm.count_up.options,
                                        },
                                      }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Faxes Sent")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "box p-a overview-boxes" }, [
                            _c("div", { staticClass: "pull-left m-r" }, [
                              _c("span", { staticClass: "w-48 text-center" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/assets/images/app-icons/fax-received.svg",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "pull-left mt-2" }, [
                              _c(
                                "h4",
                                { staticClass: "m-a-0 mb-0 text-lg" },
                                [
                                  _vm.loading_contact_center_summary
                                    ? _c("span", { staticClass: "mockup" }, [
                                        _vm._v("1000"),
                                      ])
                                    : _c("ICountUp", {
                                        attrs: {
                                          startVal: _vm.count_up.startVal,
                                          endVal:
                                            _vm.contact_center_summary
                                              .faxes_received,
                                          duration: _vm.count_up.duration,
                                          options: _vm.count_up.options,
                                        },
                                      }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "text-muted text-xs" },
                                [_vm._v("Faxes Received")]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pb-0 pt-3" },
            [
              _c("agents-condensed-summary-report", {
                attrs: {
                  ring_group_id: _vm.ring_group_id,
                  user_id: _vm.user_id,
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }