<template>
  <div class="d-flex flex-row align-items-center"
       v-if="pipedriveEnabled">
    <el-select v-model="filter_id"
               class="d-flex flex-grow-1"
               :placeholder="placeholder"
               :multiple="multiple"
               :multiple-limit="multiple ? multipleLimit : 0"
               :loading="loading_pipedrive_filters"
               :disabled="!loading_pipedrive_filters && !getPipedriveFilters.length"
               filterable
               clearable
               default-first-option
               collapse-tags>
      <el-option
          v-for="list in filtersAlphabeticalOrder"
          :key="list.id"
          :label="list.name"
          :value="list.id">
      </el-option>
    </el-select>
    <el-button type="primary"
               icon="fa fa-refresh"
               size="mini"
               class="ml-2 w-30"
               :disabled="loading_pipedrive_filters"
               :loading="loading_pipedrive_filters"
               circle
               @click="handleFetchPipedriveFilters">
    </el-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import auth from '../auth'
import { acl_mixin } from '../mixins'
import { handleVuexApiCall } from '../utils/integration-helpers'

export default {
  mixins: [acl_mixin],

  props: {
    value: {
      required: false
    },

    exclude: {
      required: false
    },

    multiple: {
      type: Boolean,
      required: false,
      default: false
    },

    multipleLimit: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: () => ({
    auth: auth,
    filter_id: null,
    loading_pipedrive_filters: false,
    pipedrive_filters: []
  }),

  computed: {
    ...mapGetters(['cache/getCurrentCompany', 'getPipedriveFilters']),

    pipedriveEnabled() {
      return this['cache/getCurrentCompany'] && this['cache/getCurrentCompany'].pipedrive_integration_enabled
    },

    placeholder() {
      if (this.loading_pipedrive_filters) {
        return 'Loading ...'
      }

      return 'Pipedrive Custom Filter'
    },

    filtersAlphabeticalOrder() {
      if (this.getPipedriveFilters) {
        return this.getPipedriveFilters.sort((a, b) => {
          let textA = a.name.toUpperCase()
          let textB = b.name.toUpperCase()
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        })
      }

      return []
    },

    activeFilter() {
      if (this.getPipedriveFilters && this.filter_id) {
        return this.getPipedriveFilters.find(pipedrive_filter => pipedrive_filter.id === this.filter_id)
      }

      return null
    }
  },

  async mounted() {
    if (this.pipedriveEnabled) {
      await this.handleFetchPipedriveFilters()
    }
  },

  methods: {
    ...mapActions(['fetchPipedriveFilters']),
    async handleFetchPipedriveFilters() {
      this.pipedrive_filters = []
      this.loading_pipedrive_filters = true

      await handleVuexApiCall(this.fetchPipedriveFilters)

      this.loading_pipedrive_filters = false
    }
  },

  watch: {
    value() {
      this.filter_id = this.value
    },

    filter_id(val) {
      if (this.value !== undefined && this.filter_id !== this.value) {
        this.$emit('selectedFilter', this.activeFilter)
        this.$emit('change', val)
      }
    }
  }
}
</script>
