<template>
    <div class="w-full width-500">
        <div class="login-brand">
            <img :src="statics.logo"
                 alt="."/>
        </div>

        <div class="dashboard-intro mt-3 mb-3">
            <el-form label-position="top"
                     label-width="300px"
                     :model="user"
                     v-if="!magic_link"
                     @submit.prevent.native="login">
                <el-form-item label="Email"
                              prop="email">
                    <el-input id="email"
                              type="email"
                              v-model="user.email"
                              autocomplete="on"
                              @keyup.enter.native="login">
                    </el-input>
                </el-form-item>

                <el-form-item label="Password"
                              prop="password">
                    <el-input id="password"
                              type="password"
                              v-model="user.password"
                              autocomplete="on"
                              @keyup.enter.native="login">
                    </el-input>
                </el-form-item>

                <div class="m-b-md">
                    <label class="md-check no-select">
                        <input type="checkbox"
                               v-model="user.remember_me"/>
                        <i class="greenish"></i> Keep me signed in
                    </label>
                </div>

                <vue-recaptcha ref="recaptcha"
                               class="g-recaptcha"
                               :sitekey="site_key"
                               v-if="site_key"
                               @verify="onVerify"
                               @expired="onExpired">
                </vue-recaptcha>

                <el-button type="success"
                           @click="login"
                           :disabled=" site_key !== '' && (loading || !user.recaptcha_response)"
                           class="p-x-md btn-block mt-2">
                    <i class="material-icons loader pull-right"
                       v-show="loading">&#xE863;</i>
                    Sign in
                    <i class="el-icon-arrow-right el-icon-right pull-right"
                       v-show="!loading"></i>
                </el-button>
            </el-form>
            <div class="text-center"
                 v-else>
                <h4 class="text-black">2FA Email Sent</h4>
                <p v-html="error"></p>
                <div class="mb-2">
                    <security-code v-model="token"
                                   ref="security_code"
                                   class="mb-2"
                                   @input="clearError"
                                   @completed="verifyToken">
                    </security-code>
                    <p v-if="verification_message.length > 0"
                           :class="'text-' + verification_message_type">
                        {{ verification_message }}
                    </p>
                </div>
                <h4 class="text-black mt-3">Go check your email!</h4>
            </div>
        </div>

        <div class="p-v-lg text-center mb-5">
            <div class="mb-2"
                 v-if="!statics.whitelabel && register_status">
                New to Aloware?
                <router-link :to="{ name: 'Register' }"
                             class="text-greenish _600">
                    Sign up
                </router-link>
            </div>
            <div class="mb-2">
                <router-link :to="{ name: 'Forgot' }"
                             class="text-greenish _600">
                    Forgot password?
                </router-link>
            </div>
        </div>

        <div class="text-center"
             v-if="!statics.whitelabel">
            <a class="text-primary"
               href="https://support.aloware.com/en/articles/9037819-troubleshooting-aloware-authentication-issues">
                Click here if you have trouble authenticating
            </a>
        </div>

        <div class="text-center mt-3"
             v-if="statics.whitelabel && statics.host === 'app.simpsocial.com'">
            <div class="text-black-dk font-weight-bold letter-spacing-1">Download our new and improved app</div>
            <div class="d-flex justify-content-center mt-2 store-icons">
                <a href="https://apps.apple.com/us/app/simpsocial-mobile/id1584474667"
                   target="_blank">
                    <div class="ios-container">
                        <el-image
                            src="assets/images/logos/app-store-badge.png"
                            fit="fill"
                            alt="google-badge">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.simpsocial.mobile"
                   target="_blank">
                    <div class="ml-2">
                        <el-image
                            src="assets/images/logos/google-play-store-badge.png"
                            fit="fill"
                            alt="google-badge">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                </a>
            </div>
        </div>

        <static-assets-preloader :assets="statics"></static-assets-preloader>
    </div>
</template>

<script>
import auth from '../auth'
import {acl_mixin, user_mixin} from "../mixins"
import {mapActions, mapState} from 'vuex'
import * as AppTypes from '../constants/aloware-app-types'
import * as Roles from '../constants/roles'
import {VueRecaptcha} from "vue-recaptcha"

export default {
    components: {
        'vue-recaptcha': VueRecaptcha,
    },

    props: {
        statics: {required: true}
    },

    mixins: [user_mixin, acl_mixin],

    data() {
        return {
            site_key: localStorage.getItem('recaptcha_site_key'),
            user: {
                email: null,
                password: null,
                remember_me: null,
                recaptcha_response: null,
            },
            register_status: false,
            loading: false,
            sb: null,
            chargebeeInstance: null,
            magic_link: false,
            error: null,
            token: '',
            resending_validation: false,
            verification_message_type: 'success',
            verification_message: '',
            verification_request_sent: false,
            AppTypes
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company
        })
    },

    created() {
        // stringify sb object on app.js file
        // this object contains all utm_fields
        this.sb = JSON.stringify(this.$root.sb)
        if (localStorage.getItem('billing_status') == true) {
            this.chargebeeInstance = this.$root.chargebeeInstance
        }
        this.register_status = localStorage.getItem('register_status')
    },

    mounted() {
        setInterval(() => {
            let chrome_password = $('#password').val()
            if (chrome_password != '' && this.user.password != chrome_password) {
                this.user.password = chrome_password
            }
            let chrome_email = $('#email').val()
            if (chrome_email != '' && this.user.email != chrome_email) {
                this.user.email = chrome_email
            }
        }, 50)
    },

    methods: {
        verifyToken() {
            axios.post(`verify-token/${this.token}`).then(res => {
                this.$notify({
                    offset: 95,
                    title: 'Successful Verification',
                    message: 'Login verified',
                    type: 'success'
                })
                localStorage.setItem('api_token', res.data.meta.token)
                this.clearError()

                // force redirect to talk
                if (res.data.redirect_to_talk) {
                    window.location.href = this.getTalkUrl()

                    return
                }

                window.location.href = this.getRedirectUrl(res.data.data)
            }).catch(err => {
                console.log(err)
                this.verification_message = err.response.data.message
                this.verification_message_type = 'danger'
            })
        },

        clearError () {
            this.verification_message_type = 'success'
            this.verification_message = ''
        },

        onVerify(response) {
            this.user.recaptcha_response = response
        },

        onExpired() {
            this.user.recaptcha_response = null
        },

        login() {
            this.loading = true
            auth.login(this.user.email, this.user.password, this.user.remember_me, this.user.recaptcha_response)
                .then(res => {
                    this.$notify({
                        title: 'Auth',
                        message: res.data.data.msg,
                        type: 'success',
                        showClose: true,
                    })
                    let usage = res.data.data.usage
                    let talk_enabled = res.data.data.company.talk_enabled
                    let force_talk = res.data.data.company.force_talk

                    if (localStorage.getItem('billing_status') == true) {
                        let portal_session = res.data.data.portal_session
                        // put portal session for using on app-header component
                    }

                    this.setCurrentCompany(res.data.data.company)
                    this.resetVuex()
                    this.setUsage(usage)
                    localStorage.setItem('company_id', res.data.data.company.id)
                    localStorage.setItem('current_user_id', res.data.data.user_id)

                    // alo talk enabled in company settings?
                    if (talk_enabled) {
                        let roles = res.data.data.user_roles
                        let is_admin = roles.some(role => [Roles.BILLING_ADMIN, Roles.COMPANY_ADMIN].includes(role))

                        // if the agent's selected default app is alo talk and alo talk is enabled then redirect to AloTalk
                        if (res.data.data.default_app === AppTypes.APP_ALOWARE_TALK && !is_admin) {
                            window.location.href = this.getTalkUrl()

                            return
                        } else {
                            // if default app is not Alo Talk then let's introduce Aloware Talk
                            localStorage.setItem(`show_default_app_selection_${res.data.data.company.id}_${res.data.data.user_id}`, true)
                            localStorage.setItem(`show_default_app_selection_${res.data.data.company.id}`, true)
                        }

                        // if forced talk and it's an agent then redirect to AloTalk
                        if (force_talk && !is_admin) {
                            window.location.href = this.getTalkUrl()

                            return
                        }
                    }
                    this.$tabEvent.emit('user_logged_in')
                    window.location.href = this.getRedirectUrl(res.data.data)
                })
                .catch(err => {
                    this.loading = false
                    if (err.response) {
                        if (err.response.data.type == 1 && this.statics && this.statics.host !== 'app.simpsocial.com') {
                            let href = ' Need an Aloware account? <a class="text-dark-greenish" href="https://aloware.com/get-demo"><span class="_600">Click here</span></a>'
                            this.$notify({
                                title: 'Auth',
                                dangerouslyUseHTMLString: true,
                                message: err.response.data.error + href,
                                type: 'error',
                                showClose: true,
                            })
                            return
                        }

                        if (err.response.data.type == 2 && this.statics && this.statics.host !== 'app.simpsocial.com') {
                            let href = ' Need to reset your password? <a class="text-dark-greenish" href="https://app.aloware.com/forgot"><span class="_600">Click here</span></a>'
                            this.$notify({
                                title: 'Auth',
                                dangerouslyUseHTMLString: true,
                                message: err.response.data.error + href,
                                type: 'error',
                                showClose: true,
                            })
                            return
                        }

                        if (err.response.data.type == 1 && this.statics && this.statics.host === 'app.simpsocial.com') {
                            let href = ' Need a SimpSocial account? <a class="text-dark-greenish" href="https://calendly.com/simpsocial-demo/30min?back=1&month=2021-08"><span class="_600">Click here</span></a>'
                            this.$notify({
                                title: 'Auth',
                                dangerouslyUseHTMLString: true,
                                message: err.response.data.error + href,
                                type: 'error',
                                showClose: true,
                            })
                            return
                        }

                        if (err.response.data.type == 2 && this.statics && this.statics.host === 'app.simpsocial.com') {
                            let href = ' Need to reset your password? <a class="text-dark-greenish" href="https://app.simpsocial.com/forgot"><span class="_600">Click here</span></a>'
                            this.$notify({
                                title: 'Auth',
                                dangerouslyUseHTMLString: true,
                                message: err.response.data.error + href,
                                type: 'error',
                                showClose: true,
                            })
                            return
                        }

                        if (err.response.data.type == 10) {
                            this.magic_link = true
                            this.error = err.response.data.error
                            return
                        }

                        if ([3, 4, 5, 6, 7].includes(err.response.data.type)) {
                            this.$notify({
                                title: 'Auth',
                                dangerouslyUseHTMLString: true,
                                message: err.response.data.error,
                                type: 'error',
                                showClose: true,
                            })
                            return
                        }

                        if (err.response.status === 422) {
                            let {email, password} = err.response.data.errors
                            let message = ''
                            if (email && email.length > 0) {
                                message += email[0] + '<br>'
                            }
                            if (password && password.length > 0) {
                                message += password[0]
                            }
                            this.$notify({
                                title: 'Auth',
                                dangerouslyUseHTMLString: true,
                                message,
                                type: 'error',
                                showClose: true,
                            })
                            return
                        }
                    }
                })
        },

        ...mapActions(['resetVuex', 'setUsage']),
        ...mapActions('cache', ['setCurrentCompany'])
    }
}
</script>
