var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "calendarSettingsForm",
          attrs: { model: _vm.calendar_settings },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm("companySettingsForm")
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "Automatically Set Contact Disposition Upon Appointment Creation"
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  staticClass: "mt-2",
                  on: { change: _vm.autoSetOnCreationChanged },
                  model: {
                    value: _vm.calendar_settings.auto_set_on_creation_enabled,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.calendar_settings,
                        "auto_set_on_creation_enabled",
                        $$v
                      )
                    },
                    expression:
                      "calendar_settings.auto_set_on_creation_enabled",
                  },
                },
                [
                  _c(
                    "el-radio",
                    { staticClass: "w-100", attrs: { label: false } },
                    [_vm._v("\n                    Disabled\n                ")]
                  ),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v(
                      "\n                    Enabled\n                    "
                    ),
                    _vm.calendar_settings.auto_set_on_creation_enabled
                      ? _c(
                          "div",
                          { staticClass: "d-flex align-items-center ml-4" },
                          [
                            _c("span", { staticClass: "ml-1 mr-1" }, [
                              _vm._v(
                                "If appointment is created, change Contact Disposition to"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "mb-0 pb-0 ml-1",
                                attrs: {
                                  error: _vm.errors.first(
                                    "disposition_on_creation_id"
                                  ),
                                  prop: "disposition_on_creation_id",
                                },
                              },
                              [
                                _c("disposition-status-selector", {
                                  attrs: {
                                    value:
                                      _vm.calendar_settings
                                        .disposition_on_creation_id,
                                    is_disabled:
                                      !_vm.calendar_settings
                                        .auto_set_on_creation_enabled,
                                    show_not_disposed: false,
                                    size: "mini",
                                    clearable: "",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.calendar_settings,
                                        "disposition_on_creation_id",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.errors.has("auto_set_on_creation_enabled")
                ? _c("span", { staticClass: "el-form-item__error" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.errors.first("auto_set_on_creation_enabled")
                        ) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "Automatically Set Contact Disposition if Appointment is set to Completed"
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  staticClass: "mt-2",
                  on: { change: _vm.autoSetOnMissedChanged },
                  model: {
                    value: _vm.calendar_settings.auto_set_on_completed_enabled,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.calendar_settings,
                        "auto_set_on_completed_enabled",
                        $$v
                      )
                    },
                    expression:
                      "calendar_settings.auto_set_on_completed_enabled",
                  },
                },
                [
                  _c(
                    "el-radio",
                    { staticClass: "w-100", attrs: { label: false } },
                    [_vm._v("\n                    Disabled\n                ")]
                  ),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v(
                      "\n                    Enabled\n                    "
                    ),
                    _vm.calendar_settings.auto_set_on_completed_enabled
                      ? _c(
                          "div",
                          { staticClass: "ml-4" },
                          [
                            _c("div", { staticClass: "d-flex ml-1" }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-top": "10px !important",
                                  },
                                },
                                [_vm._v("If: ")]
                              ),
                              _vm._v(" "),
                              _c("ul", [
                                _c(
                                  "li",
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Contact Disposition is not set to"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass:
                                          "mb-0 pb-0 ml-2 d-inline-block",
                                        attrs: {
                                          error: _vm.errors.first(
                                            "disposition_not_set_to_id"
                                          ),
                                          prop: "disposition_not_set_to_id",
                                        },
                                      },
                                      [
                                        _c("disposition-status-selector", {
                                          attrs: {
                                            value:
                                              _vm.calendar_settings
                                                .disposition_not_set_to_id,
                                            is_disabled:
                                              !_vm.calendar_settings
                                                .auto_set_on_completed_enabled,
                                            show_not_disposed: false,
                                            size: "mini",
                                            clearable: "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.calendar_settings,
                                                "disposition_not_set_to_id",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("li", { staticClass: "mt-4" }, [
                                  _vm._v(
                                    "\n                                    And the appointment date (day) has already occured\n                                "
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center mt-2" },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Then change the Contact Disposition to"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "mb-0 pb-0 ml-2",
                                    attrs: {
                                      error: _vm.errors.first(
                                        "contact_disposition_on_completed_id"
                                      ),
                                      prop: "contact_disposition_on_completed_id",
                                    },
                                  },
                                  [
                                    _c("disposition-status-selector", {
                                      attrs: {
                                        value:
                                          _vm.calendar_settings
                                            .contact_disposition_on_completed_id,
                                        is_disabled:
                                          !_vm.calendar_settings
                                            .auto_set_on_completed_enabled,
                                        show_not_disposed: false,
                                        size: "mini",
                                        clearable: "",
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.calendar_settings,
                                            "contact_disposition_on_completed_id",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-alert", {
                              staticClass: "mt-3",
                              attrs: {
                                closable: false,
                                title:
                                  "* Automation runs at midnight (based on account's timezone)",
                                type: "success",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.errors.has("auto_set_on_completed_enabled")
                ? _c("span", { staticClass: "el-form-item__error" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.errors.first("auto_set_on_completed_enabled")
                        ) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "pull-left mt-5",
          attrs: { type: "success", disabled: _vm.loading },
          on: { click: _vm.submitCalendarSettings },
        },
        [
          _c(
            "i",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "material-icons loader pull-right",
            },
            [_vm._v("\n            \n        ")]
          ),
          _vm._v("\n        Submit\n    "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }