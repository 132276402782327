var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-testid": "number-guard-wizard-wrapper" } },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "el-steps",
            {
              staticClass: "mb-3",
              attrs: {
                active: _vm.current_step,
                "align-center": "",
                "finish-status": "success",
              },
            },
            [
              _c("el-step", {
                attrs: { title: "Information", "data-testid": "info-step" },
              }),
              _vm._v(" "),
              _c("el-step", {
                attrs: {
                  title: "Select your package",
                  "data-testid": "info-step",
                },
              }),
              _vm._v(" "),
              _c("el-step", {
                attrs: {
                  title: "Confirmation",
                  "data-testid": "confirmation-step",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.current_step === 0
            ? _c("div", { staticClass: "step-1 row" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 text-right mt-4" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: { click: _vm.nextStep },
                      },
                      [_vm._v("\n                    Next\n                ")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.current_step === 1
            ? _c("div", { staticClass: "step-2 row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c("b", [_vm._v("Choose your Package")]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              key: _vm.current_step,
                              staticClass: "w-full",
                              attrs: {
                                id: "package-select",
                                placeholder: "Select Package",
                              },
                              model: {
                                value: _vm.selected_package,
                                callback: function ($$v) {
                                  _vm.selected_package = $$v
                                },
                                expression: "selected_package",
                              },
                            },
                            _vm._l(_vm.computedPackages, function (option) {
                              return _c(
                                "el-option",
                                {
                                  key: option.value,
                                  attrs: {
                                    value: option.value,
                                    label: _vm.formattedPackageLabel,
                                    disabled: option.disabled,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "wz-option-label" },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(option.label) +
                                          "\n                                            "
                                      ),
                                      option.value == 1
                                        ? _c(
                                            "span",
                                            { staticClass: "wz-option-number" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.localPresenceLines
                                                  ) +
                                                  " Numbers\n                                            "
                                              ),
                                            ]
                                          )
                                        : option.value == 2
                                        ? _c(
                                            "span",
                                            { staticClass: "wz-option-number" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.nonLocalPresenceLines
                                                  ) +
                                                  " Numbers\n                                            "
                                              ),
                                            ]
                                          )
                                        : option.value == 3
                                        ? _c(
                                            "span",
                                            { staticClass: "wz-option-number" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(_vm.totalLines) +
                                                  " Numbers\n                                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("b", [_vm._v("Choose your Frequency")]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "w-full",
                              attrs: { placeholder: "Select Frequency" },
                              model: {
                                value: _vm.selected_frequency,
                                callback: function ($$v) {
                                  _vm.selected_frequency = $$v
                                },
                                expression: "selected_frequency",
                              },
                            },
                            _vm._l(_vm.frequencies, function (frequency) {
                              return _c(
                                "el-option",
                                {
                                  key: frequency.value,
                                  attrs: {
                                    value: frequency.value,
                                    label: frequency.label,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(frequency.label) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 d-flex justify-content-between mt-4" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "info" },
                        on: { click: _vm.previousStep },
                      },
                      [_vm._v("\n                    Back\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success", disabled: !_vm.isValidStep2 },
                        on: { click: _vm.nextStep },
                      },
                      [_vm._v("\n                    Next\n                ")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.current_step === 2
            ? _c("div", { staticClass: "step-3 row" }, [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 d-flex justify-content-between mt-4" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "info" },
                        on: { click: _vm.previousStep },
                      },
                      [_vm._v("\n                    Back\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "pull-right",
                        attrs: { type: "success", disabled: _vm.loading },
                        on: { click: _vm.confirmAndSubmit },
                      },
                      [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            staticClass: "material-icons loader pull-right",
                          },
                          [_vm._v("")]
                        ),
                        _vm._v(" Accept and Submit\n                "),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.current_step === 3
            ? _c("div", { staticClass: "step-4 text-center row" }, [_vm._m(4)])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "p",
        {
          staticClass:
            "form-head font-weight-bold mb-3 description wz-text-wrap",
        },
        [_c("b", [_vm._v("Are your outbound calls showing up as spam?")])]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-divider" }),
      _vm._v(" "),
      _c("p", { staticClass: "wz-text-wrap" }, [
        _c("b", [_vm._v("NumberGuard")]),
        _vm._v(" is a paid service by Aloware that provides:"),
        _c("br"),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _vm._v(
              "Carrier Validation: Verifies your phone numbers against US carrier databases and their analytical providers."
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "Real-World Testing: Tests calls to real mobile phones and networks to check how the phone displays your number."
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "Mislabeling Resolution: Negotiates with US carriers to remove spam mislabeling (or CNAM records) from your numbers."
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "Caller ID Whitelisting: Whitelists your outbound caller ID with mobile phone carriers to minimize spam risks."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      {
        staticClass: "form-head font-weight-bold mb-3 description wz-text-wrap",
      },
      [_c("b", [_vm._v("Pricing Information")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-responsive" }, [
      _c("table", { staticClass: "wz-pricing-table" }, [
        _c("thead", [
          _c("tr", [
            _c("th", [_c("b", [_vm._v("Quantity of Phone Numbers")])]),
            _vm._v(" "),
            _c("th", [_c("b", [_vm._v("Price per Month")])]),
          ]),
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("1 - 20")]),
            _vm._v(" "),
            _c("td", [_vm._v("$100/mo")]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("21 - 100")]),
            _vm._v(" "),
            _c("td", [_vm._v("$250/mo")]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("101 - 300")]),
            _vm._v(" "),
            _c("td", [_vm._v("$500/mo")]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("300+")]),
            _vm._v(" "),
            _c("td", [_vm._v("$1,000/mo")]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "form-head font-weight-bold mb-3 wz-text-wrap" }, [
        _c("b", [_vm._v("NumberGuard - Terms of Service")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-divider" }),
      _vm._v(" "),
      _c("p", { staticClass: "wz-text-wrap mt-3" }, [
        _c("i", { staticClass: "fa fa-clock-o" }),
        _vm._v(
          " Limited-time offer: Opt-in now for a one-time scan and cleanup of your phone numbers."
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          "\n                    By subscribing to this product, you consent to having your phone numbers scanned for risks and authorize Aloware to contact you regarding this order.\n                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("p", { staticClass: "wz-text-wrap" }, [
        _c("b", [
          _vm._v("Your order for NumberGuard has been successfully submitted!"),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "wz-text-wrap" }, [
        _vm._v(
          "Our team will reach out to you within 1-2 business days. Thank you!"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }