<template>
    <div>
        Your {{ compliance_model.class_name }} has been {{ compliance_model.status_name }}.
        <div>
            <button class="btn rounded greenish btn-status btn-sm mt-2 pull-right">
                Check Status
            </button>
        </div>
    </div>
</template>

<script>
    import {html_mixin} from '../mixins'

    export default {
        mixins: [html_mixin],

        props: ['compliance_model', 'status_page_link']
    }
</script>
