var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-4 mt-2" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-end mb-3" },
        [
          _c("el-button", {
            attrs: {
              type: "primary",
              title: "Add instruction",
              icon: "el-icon-plus",
              size: "small",
              plain: "",
              circle: "",
            },
            on: {
              click: function ($event) {
                _vm.dialogVisible = true
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.instructions.length > 0
        ? _c(
            "div",
            { staticClass: "instructions-card" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.instructions },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "role", label: "Role" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "content", label: "Content" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-button", {
                                attrs: {
                                  plain: "",
                                  circle: "",
                                  icon: "el-icon-edit",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openEditDialog(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("el-button", {
                                attrs: {
                                  plain: "",
                                  circle: "",
                                  icon: "el-icon-delete",
                                  size: "mini",
                                  type: "danger",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmDelete(scope.$index)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1720413161
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.instructions.length > 0
        ? _c(
            "el-tooltip",
            { attrs: { content: "Show JSON output", placement: "top" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "active-value": true,
                  "inactive-value": false,
                },
                model: {
                  value: _vm.show_code,
                  callback: function ($$v) {
                    _vm.show_code = $$v
                  },
                  expression: "show_code",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.show_code
        ? _c("pre", { staticClass: "mt-3" }, [_vm._v(_vm._s(_vm.instructions))])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "container-body pb-4",
          attrs: {
            visible: _vm.dialogVisible,
            title: `${_vm.isEditMode ? "Edit" : "Add"} Instruction`,
            width: "50%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.currentInstruction } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Role" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.currentInstruction.role,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentInstruction, "role", $$v)
                      },
                      expression: "currentInstruction.role",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Content" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.currentInstruction.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentInstruction, "content", $$v)
                      },
                      expression: "currentInstruction.content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveInstruction },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }