<template>
    <div>
        <el-select placeholder="Filter by Ring Group"
                   v-model="ring_group_id_filter"
                   class="no-select"
                   :clearable="clearable"
                   filterable
                   @change="filterRingGroup">
            <el-option-group key="Queued Ring Groups"
                             label="Queued Ring Groups">
                <el-option
                        v-for="item in queued_ring_groups"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-option-group>
            <el-option-group key="Regular Ring Groups"
                             label="Regular Ring Groups">
                <el-option
                        v-for="item in regular_ring_groups"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-option-group>
        </el-select>
    </div>
</template>

<script>
import {acl_mixin} from '../../mixins'
import {mapState} from 'vuex'

export default {
    mixins: [acl_mixin],

    props: {
        ring_group_id: { required: true },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            selected_ring_group_id: null
        }
    },

    computed: {
        ...mapState({
            ring_groups: state => state.ring_groups,
        }),

        ring_group_id_filter: {
            get: function () {
                return this.ring_group_id
            },
            set: function (value) {
                this.selected_ring_group_id = value
            }
        },

        queued_ring_groups() {
            return this.ring_groups.filter(ring_group => ring_group.should_queue === true)
        },

        regular_ring_groups() {
            return this.ring_groups.filter(ring_group => ring_group.should_queue === false)
        },
    },

    methods: {
        filterRingGroup() {
            VueEvent.fire('filtered_ring_group', this.selected_ring_group_id)
        },
    }
}
</script>