var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body" }, [
    _c(
      "div",
      {
        staticClass: "text-center pos-rlt p-y-md",
        staticStyle: { overflow: "hidden" },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("a", { staticClass: "mx-auto d-block" }, [
              !_vm.loading_whitelabel
                ? _c("img", {
                    attrs: {
                      src: _vm.statics.logo,
                      height: "70px",
                      alt: "logo",
                    },
                  })
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col-md-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c(
              "p",
              {
                staticClass: "h5 m-y-lg font-bold text-justify",
                staticStyle: { "line-height": "30px" },
              },
              [
                _vm._v(
                  "Hey! It looks like that your " +
                    _vm._s(_vm.current_company.name) +
                    " - #" +
                    _vm._s(_vm.current_company.id) +
                    " account has been suspended. Please\n                    contact our support at\n                    "
                ),
                _vm.current_company && _vm.current_company.reseller_id != 357
                  ? _c("span", { staticClass: "text-dark-greenish" }, [
                      _vm._v(
                        "\n                        support@aloware.com\n                    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.current_company && _vm.current_company.reseller_id == 357
                  ? _c("span", { staticClass: "text-dark-greenish" }, [
                      _vm._v(
                        "\n                        support@simpsocial.com\n                    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v("\n                    or call\n                    "),
                _vm.current_company && _vm.current_company.reseller_id != 357
                  ? _c("span", { staticClass: "text-dark-greenish" }, [
                      _vm._v(
                        "\n                        855-256-2001\n                    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.current_company && _vm.current_company.reseller_id == 357
                  ? _c("span", { staticClass: "text-dark-greenish" }, [
                      _vm._v(
                        "\n                        888-829-1110\n                    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(
                  "\n                    for assistance.\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.current_company && _vm.current_company.reseller_id != 357
              ? _c(
                  "a",
                  {
                    staticClass: "btn greenish p-x-md",
                    attrs: { href: "mailto:support@aloware.com" },
                  },
                  [
                    _vm._v(
                      "\n                    Send an Email\n                "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.current_company && _vm.current_company.reseller_id == 357
              ? _c(
                  "a",
                  {
                    staticClass: "btn greenish p-x-md",
                    attrs: { href: "mailto:support@simpsocial.com" },
                  },
                  [
                    _vm._v(
                      "\n                    Send an Email\n                "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.current_company && _vm.current_company.reseller_id != 357
              ? _c(
                  "a",
                  {
                    staticClass: "btn new-blue p-x-md ml-2",
                    attrs: { href: "tel:+18552562001" },
                  },
                  [_vm._v("\n                    Call us\n                ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.current_company && _vm.current_company.reseller_id == 357
              ? _c(
                  "a",
                  {
                    staticClass: "btn new-blue p-x-md ml-2",
                    attrs: { href: "tel:+18888291110" },
                  },
                  [_vm._v("\n                    Call us\n                ")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }