var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "py-2" }, [
      _c(
        "div",
        { staticClass: "switch-label" },
        [
          _c("el-switch", {
            attrs: { "active-color": "#00a344" },
            model: {
              value: _vm.webhook.filters.status,
              callback: function ($$v) {
                _vm.$set(_vm.webhook.filters, "status", $$v)
              },
              expression: "webhook.filters.status",
            },
          }),
          _vm._v(" "),
          _c("label", [_vm._v("Enable Filters")]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.webhook.filters.status,
            expression: "webhook.filters.status",
          },
        ],
      },
      [
        _c("div", { staticClass: "card mb-3" }, [
          _c("div", { staticClass: "py-2 px-3 d-flex" }, [
            _c(
              "div",
              { staticClass: "switch-label" },
              [
                _c("el-switch", {
                  attrs: { "active-color": "#00a344" },
                  model: {
                    value: _vm.webhook.filters.filter_direction,
                    callback: function ($$v) {
                      _vm.$set(_vm.webhook.filters, "filter_direction", $$v)
                    },
                    expression: "webhook.filters.filter_direction",
                  },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("Direction")]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.webhook.filters.filter_direction
            ? _c("div", { staticClass: "card-body pt-0 px-3" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v("Filter by communication direction."),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.webhook.filters.direction,
                            callback: function ($$v) {
                              _vm.$set(_vm.webhook.filters, "direction", $$v)
                            },
                            expression: "webhook.filters.direction",
                          },
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                label: _vm.CommunicationDirections.INBOUND,
                                border: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Inbound\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                label: _vm.CommunicationDirections.OUTBOUND,
                                border: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Outbound\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card mb-3" }, [
          _c("div", { staticClass: "py-2 px-3 d-flex" }, [
            _c(
              "div",
              { staticClass: "switch-label" },
              [
                _c("el-switch", {
                  attrs: { "active-color": "#00a344" },
                  model: {
                    value: _vm.webhook.filters.filter_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.webhook.filters, "filter_type", $$v)
                    },
                    expression: "webhook.filters.filter_type",
                  },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("Type")]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.webhook.filters.filter_type
            ? _c("div", { staticClass: "card-body pt-0 px-3" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v("Filter by communication type."),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: { multiple: "" },
                        model: {
                          value: _vm.webhook.filters.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.webhook.filters, "type", $$v)
                          },
                          expression: "webhook.filters.type",
                        },
                      },
                      _vm._l(_vm.filtered_communication_types, function (type) {
                        return _c("el-option", {
                          key: type,
                          attrs: {
                            value: type,
                            label: _vm._f("fixCommType")(type),
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card mb-3" }, [
          _c("div", { staticClass: "py-2 px-3 d-flex" }, [
            _c(
              "div",
              { staticClass: "switch-label" },
              [
                _c("el-switch", {
                  attrs: { "active-color": "#00a344" },
                  model: {
                    value:
                      _vm.webhook.filters
                        .filter_communication_disposition_status,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.webhook.filters,
                        "filter_communication_disposition_status",
                        $$v
                      )
                    },
                    expression:
                      "webhook.filters.filter_communication_disposition_status",
                  },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("Communication Disposition Status")]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.webhook.filters.filter_communication_disposition_status
            ? _c("div", { staticClass: "card-body pt-0 px-3" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v("Filter by communication disposition status."),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: { multiple: "" },
                        model: {
                          value:
                            _vm.webhook.filters
                              .communication_disposition_statuses,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.webhook.filters,
                              "communication_disposition_statuses",
                              $$v
                            )
                          },
                          expression:
                            "webhook.filters.communication_disposition_statuses",
                        },
                      },
                      _vm._l(
                        _vm.filtered_communication_disposition_statuses,
                        function (status) {
                          return _c("el-option", {
                            key: status,
                            attrs: {
                              value: status,
                              label: _vm._f("fixName")(
                                _vm._f("translateDispositionStatusText")(status)
                              ),
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card mb-3" }, [
          _c("div", { staticClass: "py-2 px-3 d-flex" }, [
            _c(
              "div",
              { staticClass: "switch-label" },
              [
                _c("el-switch", {
                  attrs: { "active-color": "#00a344" },
                  model: {
                    value: _vm.webhook.filters.filter_disposed_contacts,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.webhook.filters,
                        "filter_disposed_contacts",
                        $$v
                      )
                    },
                    expression: "webhook.filters.filter_disposed_contacts",
                  },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("Contacts")]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.webhook.filters.filter_disposed_contacts
            ? _c("div", { staticClass: "card-body pt-0 px-3" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v("Filter by contact type."),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.webhook.filters.contacts,
                          callback: function ($$v) {
                            _vm.$set(_vm.webhook.filters, "contacts", $$v)
                          },
                          expression: "webhook.filters.contacts",
                        },
                      },
                      [
                        _c(
                          "el-radio",
                          { attrs: { label: "all", border: "" } },
                          [
                            _vm._v(
                              "\n                            All\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          { attrs: { label: "disposed_contacts", border: "" } },
                          [
                            _vm._v(
                              "\n                            Disposed contacts\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: {
                              label: "selected_disposition_statuses",
                              border: "",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            Selected dispositions\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("disposition-status-selector", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.webhook.filters.contacts ===
                            "selected_disposition_statuses",
                          expression:
                            "webhook.filters.contacts === 'selected_disposition_statuses'",
                        },
                      ],
                      attrs: {
                        select_style: "width: 100%;",
                        multiple: "",
                        clearable: "",
                      },
                      on: { change: _vm.changeSyncContactsWithDisposition },
                      model: {
                        value: _vm.webhook.filters.disposition_status_ids,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.webhook.filters,
                            "disposition_status_ids",
                            $$v
                          )
                        },
                        expression: "webhook.filters.disposition_status_ids",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card mb-3" }, [
          _c("div", { staticClass: "py-2 px-3 d-flex" }, [
            _c(
              "div",
              { staticClass: "switch-label" },
              [
                _c("el-switch", {
                  attrs: { "active-color": "#00a344" },
                  model: {
                    value: _vm.webhook.filters.filter_lines,
                    callback: function ($$v) {
                      _vm.$set(_vm.webhook.filters, "filter_lines", $$v)
                    },
                    expression: "webhook.filters.filter_lines",
                  },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("Skip lines")]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.webhook.filters.filter_lines
            ? _c("div", { staticClass: "card-body pt-0 px-3" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "Here you can exclude the lines you don't want to send to this integration."
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("campaign-selector", {
                      attrs: { select_style: "width: 100%;", multiple: "" },
                      on: { change: _vm.changeSkipLines },
                      model: {
                        value: _vm.webhook.filters.skip_line_ids,
                        callback: function ($$v) {
                          _vm.$set(_vm.webhook.filters, "skip_line_ids", $$v)
                        },
                        expression: "webhook.filters.skip_line_ids",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card mb-3" }, [
          _c("div", { staticClass: "py-2 px-3 d-flex" }, [
            _c(
              "div",
              { staticClass: "switch-label" },
              [
                _c("el-switch", {
                  attrs: { "active-color": "#00a344" },
                  model: {
                    value: _vm.webhook.filters.filter_call_duration,
                    callback: function ($$v) {
                      _vm.$set(_vm.webhook.filters, "filter_call_duration", $$v)
                    },
                    expression: "webhook.filters.filter_call_duration",
                  },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("Duration")]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.webhook.filters.filter_call_duration
            ? _c("div", { staticClass: "card-body pt-0 px-3" }, [
                _c(
                  "p",
                  { staticClass: "mb-1" },
                  [
                    _c("span", [_vm._v("Don’t send calls with")]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          size: "mini",
                          placeholder: "Select Duration Type",
                        },
                        model: {
                          value: _vm.webhook.filters.call_duration_type,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.webhook.filters,
                              "call_duration_type",
                              $$v
                            )
                          },
                          expression: "webhook.filters.call_duration_type",
                        },
                      },
                      _vm._l(_vm.call_type_options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  [
                    _c("span", [_vm._v("that is less than")]),
                    _vm._v(" "),
                    _c("el-input-number", {
                      attrs: { size: "mini", placeholder: "Duration" },
                      model: {
                        value: _vm.webhook.filters.call_duration,
                        callback: function ($$v) {
                          _vm.$set(_vm.webhook.filters, "call_duration", $$v)
                        },
                        expression: "webhook.filters.call_duration",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("minutes duration")]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card mb-3" }, [
          _c("div", { staticClass: "py-2 px-3 d-flex" }, [
            _c(
              "div",
              { staticClass: "switch-label" },
              [
                _c("el-switch", {
                  attrs: { "active-color": "#00a344" },
                  model: {
                    value: _vm.webhook.filters.filter_qualified_only,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.webhook.filters,
                        "filter_qualified_only",
                        $$v
                      )
                    },
                    expression: "webhook.filters.filter_qualified_only",
                  },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("Qualified Contacts")]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.webhook.filters.filter_qualified_only
            ? _c("div", { staticClass: "card-body pt-0 px-3" }, [
                _c("p", { staticClass: "mb-1" }, [
                  _vm._v(
                    "\n                    Only create contacts when a qualified communication passes the filters\n                "
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label" }, [
      _c("h5", [_vm._v("Filters")]),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "\n            Filters events that will be sent upstream to Webhook.\n            Note that if you select a filter but no options for that filter is selected,\n            no events will be sent.\n        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }