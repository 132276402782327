var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c(
        "el-form-item",
        { staticClass: "no-border", attrs: { prop: "extension" } },
        [
          _c("div", { staticClass: "form-label" }, [
            _c("label", [_vm._v(_vm._s(_vm.label))]),
          ]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              ref: "extensionSelect",
              attrs: {
                placeholder: "Extension",
                autocomplete: "off",
                "default-first-option": "",
                filterable: "",
                clearable: "",
                disabled: _vm.disabled,
                "filter-method": _vm.filterExtensions,
                value: _vm.value,
                "data-testid": "extension-selector",
              },
              on: { input: _vm.onInput, change: _vm.onChange },
            },
            [
              _vm._l(_vm.options, function (extension) {
                return _c("el-option", {
                  key: extension,
                  attrs: { label: extension, value: extension },
                })
              }),
              _vm._v(" "),
              !_vm.options.length
                ? _c(
                    "el-option",
                    {
                      key: 0,
                      attrs: {
                        label: "No extensions found matching the search value",
                        value: 0,
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.noOptionsLabel) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }