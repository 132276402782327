<template>
    <el-select ref="alo-ai-bots-selector"
               v-model="aloai_bot_id"
               v-loading="loading"
               class="w-full"
               :placeholder="placeholder"
               :multiple="multiple"
               :clearable="clearable"
               :collapse-tags="!no_collapse"
               :size="size"
               filterable
               default-first-option
               @change="selectAloAiBot">
        <el-option v-for="bot in aloAiBotsAlphabeticalOrder"
                   :key="bot.id"
                   :label="bot.name"
                   :value="bot.id">
            <span class="ml-2">{{ bot.name }}</span>
        </el-option>
  </el-select>
</template>

<script>
import { mapState } from 'vuex'
import { clone } from 'lodash'

export default {
    data() {
        return {
            aloai_bot_id: this.value,
            loading: false,
            aloai_bots: [],
            params: {
                page: 1,
                size: 100
            }
        }
    },

    props: {
        value: {
            required: false,
            type: [Number, String]
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        no_collapse: {
            type: Boolean,
            required: false,
            default: false
        },

        clearable: {
            type: Boolean,
            required: false,
            default: false
        },

        size: {
            type: String,
            default: '',
            required: false
        }
    },

    computed: {
        ...mapState('cache', [
            'current_company'
        ]),

        placeholder() {
            return this.multiple ? 'Select AloAi Bots' : 'Select AloAi Bot'
        },

        aloAiBotsAlphabeticalOrder() {
            let bots = clone(this.aloai_bots)

            return bots.sort((a, b) => {
                let textA = a.name.toUpperCase()
                let textB = b.name.toUpperCase()
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
            })
        }
    },

    mounted() {
        this.getAloAiBots()
    },

    methods: {
        selectAloAiBot(bot) {
            this.aloai_bot_id = bot
            this.$emit('change', bot)
        },

        async getAloAiBots() {
            this.loading = true
            let res = null

            do {
                res = await axios.get('/api/v1/aloai/chat-bot', { params: this.params })
                this.aloai_bots.push(...res.data.data)
                this.params.page++
            } while (res.data.next_page_url)

            this.loading = false
        }
    },

    watch: {
        value() {
            this.aloai_bot_id = this.value
        }
  }
}
</script>
