<template>
    <div v-if="hasPermissionTo('create sms template')">
        <el-dialog class="dialog-padding auto"
                   :title="title"
                   :width="isLargeEnough ? '500px' : '100%'"
                   :visible.sync="dialogVisible"
                   append-to-body
                   @open="initTemplate"
                   @close="resetTemplate">
            <el-form ref="create_template"
                     class="p-0"
                     label-position="top"
                     :rules="rules_template"
                     :model="template"
                     data-testid="create-sms-template-form"
                     @submit.prevent.native="saveTemplate">
                <el-form-item prop="name"
                              label="Name">
                    <el-input v-model="template.name"
                              maxlength="190"
                              show-word-limit
                              ref="templateName"
                              data-testid="sms-create-template-name-input"
                              @input="preValidateForm('create_template')">
                    </el-input>
                </el-form-item>
                <el-form-item prop="body"
                              label="Template Body">
                    <el-input v-model="template.body"
                              type="textarea"
                              rows="4"
                              data-testid="sms-create-template-body-input"
                              @input="preValidateForm('create_template')">
                    </el-input>
                </el-form-item>
                <div class="row pb-3">
                    <div class="col-8">
                        <variable-dialog :in_input_group="false"></variable-dialog>
                    </div>
                    <div class="col-4">
                        <el-button type="success"
                                   :disabled="loading_btn || !validated"
                                   class="pull-right ml-2"
                                   data-testid="sms-create-template-save-button"
                                   @click="saveTemplate">
                            <i class="material-icons loader"
                               v-show="loading_btn">&#xE863;</i>
                            <i class="material-icons"
                               v-show="!loading_btn">
                                save
                            </i>
                            <span>Save</span>
                        </el-button>
                    </div>
                </div>
            </el-form>
        </el-dialog>
        <slot name="trigger">
            <el-button slot="reference"
                       ref="editButton"
                       type="text"
                       :size="buttonSize"
                       v-if="short"
                       data-testid="sms-create-template-new-button"
                       @click="dialogVisible = true">
                <i class="material-icons">add</i>
                New
            </el-button>
            <button slot="reference"
                    ref="editButton"
                    class="btn btn-sm btn-block success"
                    v-else
                    data-testid="sms-create-template-new-template-button"
                    @click.prevent="dialogVisible = true">
                <i :class="'fa fa-plus'"></i>
                New Template
            </button>
        </slot>
    </div>
</template>

<script>
    import auth from '../auth'
    import {
        acl_mixin,
        form_validation_mixin
    } from '../mixins'
    import {mapGetters, mapActions} from 'vuex'
    import VariableDialog from './variable-dialog'
    import * as Roles from '../constants/roles'

    export default {
        components: {VariableDialog},
        mixins: [
            acl_mixin,
            form_validation_mixin
        ],

        props: {
            short: {
                type: Boolean,
                default: true
            },
            template_scope: {
                default: 'user',
                validator: function(value) {
                    return ['user', 'company'].indexOf(value) !== -1;
                }
            },
            buttonSize: {
                default: 'mini'
            },
            template_id: {
                required: false,
            },
            user_id: {
                type: Number,
                required: false,
                default: undefined
            }
        },

        data() {
            return {
                auth: auth,
                loading_btn: false,
                template: {
                    name: null,
                    body: null
                },
                rules_template: {
                    name: [
                        {
                            required: true,
                            message: 'Please provide the template name',
                            trigger: 'blur'
                        },
                        {
                            max: 190,
                            message: 'Name is too long',
                            trigger: 'blur'
                        }
                    ],
                    body: [
                        {
                            required: true,
                            message: 'Please provide template body',
                            trigger: 'blur'
                        }
                    ]
                },
                dialogVisible: false,
                Roles
            }
        },

        computed: {
            ...mapGetters({
                smsTemplates: 'getSmsTemplates',
            }),

            title() {
                const action = this.template_id ? 'Edit' : 'Create'

                if (this.template_scope == 'user') {
                    return action + ' SMS template'
                }

                return action + ' account SMS template'
            },

            isLargeEnough() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            },

            isCompanyAdmin() {
                return this.hasRole(Roles.COMPANY_ADMIN) || this.hasRole(Roles.BILLING_ADMIN)
            },

            isCurrentUser() {
                const user_id = _.get(this.auth, 'user.profile.id', null)
                return parseInt(user_id) === this.user_id
            }
        },

        methods: {
            initTemplate() {
                // keep tempalte object empty if we're not editting
                if (!this.template_id) {
                    return
                }

                const found = this.smsTemplates.find(template => template.id === this.template_id)

                if (found) {
                    // populate the tempalte object with values
                    this.template = {
                        name: found.name,
                        body: found.body,
                        company_id: found.company_id
                    }

                    setTimeout(() => {
                        this.$refs.templateName.focus()
                        this.preValidateForm('create_template')
                        this.validated = false
                    })
                }
            },

            saveTemplate() {
                if (!this.validateForm('create_template')) {
                    return
                }

                this.loading_btn = true
                let params = _.assign({template_scope: this.template_scope}, this.template)

                let method = 'patch'
                let url = '/api/v1/sms-template/' + this.template_id
                let message = 'Template updated successfully.'

                // if we're editing the template...
                if (!this.template_id) {
                    method = 'post'
                    url = '/api/v1/sms-template'
                    message = 'Template created successfully.'
                }

                // submit the user id when an admin is creating/editing
                // a template in the user settings -> sms template and
                // template scope is user
                if (this.isCompanyAdmin &&
                    !this.isCurrentUser &&
                    this.template_scope == 'user') {
                    params.user_id = this.user_id
                }

                axios[method](url, params)
                    .then(res => {
                        this.loading_btn = false
                        this.dialogVisible = false
                        this.$notify({
                            offset: 95,
                            title: 'SMS Template',
                            message: message,
                            type: 'success'
                        })
                        if (!this.template_id) {
                            this.newSmsTemplate(res.data)
                            return
                        }
                        this.updateSmsTemplate(res.data)
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_btn = false
                    })
            },

            resetTemplate() {
                this.template = {
                    name: null,
                    body: null,
                }
                this.validated = false

                if (this.template_id) {
                    this.$emit('clearEdit')
                }
            },

            edit() {
                this.dialogVisible = true
            },

            ...mapActions(['newSmsTemplate', 'updateSmsTemplate'])
        }
    }
</script>
