export const TRIGGER_OPERATION_APPLY_TAG_TO_COMMUNICATION = {
    id: 1,
    name: 'Apply tags to communication',
    value: 'tag'
}

export const TRIGGER_OPERATION_APPLY_TAG_TO_CONTACT = {
    id: 2,
    name: 'Apply tags to contact',
    value: 'tag_contact'
}

export const TRIGGER_OPERATION_ENROLL_IN_SEQUENCE = {
    id: 3,
    name: 'Enroll in sequence',
    value: 'enroll'
}

export const TRIGGER_OPERATION_DISPOSE_CONTACT = {
    id: 4,
    name: 'Dispose contact',
    value: 'dispose'
}

export const TRIGGER_OPERATION_ADD_TO_CONTACT_LIST = {
    id: 5,
    name: 'Add contact to list',
    value: 'add_to_list'
}

export const TRIGGER_OPERATION_REMOVE_FROM_CONTACT_LIST = {
    id: 6,
    name: 'Remove contact from list',
    value: 'remove_from_list'
}

export const TRIGGER_OPERATION_REMOVE_FROM_ALL_CONTACT_LISTS = {
    id: 7,
    name: 'Remove contact from all lists',
    value: 'remove_from_all_lists'
}

export const TRIGGER_OPERATIONS = [
    TRIGGER_OPERATION_APPLY_TAG_TO_COMMUNICATION,
    TRIGGER_OPERATION_APPLY_TAG_TO_CONTACT,
    TRIGGER_OPERATION_ENROLL_IN_SEQUENCE,
    TRIGGER_OPERATION_DISPOSE_CONTACT,
    TRIGGER_OPERATION_ADD_TO_CONTACT_LIST,
    TRIGGER_OPERATION_REMOVE_FROM_CONTACT_LIST,
    TRIGGER_OPERATION_REMOVE_FROM_ALL_CONTACT_LISTS
]
