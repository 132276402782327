var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "div",
        { staticClass: "app-body", attrs: { id: "view" } },
        [
          _c(
            "div",
            {
              staticClass: "fixed-header padding pt-0 pb-0",
              style: { top: _vm.top_offset + 50 + "px" },
            },
            [
              _c("div", { staticClass: "row mb-0 mt-0" }, [
                _c("div", { staticClass: "col-12 p-2" }, [
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-fill" },
                      [
                        _c("comm-advanced-filters-wrapper", {
                          attrs: {
                            "data-testid": "activity-comm-advance-filters",
                            user_id: _vm.user_id,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _vm.hasPermissionTo("update user") ||
                        (_vm.hasPermissionTo("update profile") &&
                          _vm.auth.user.profile.id == _vm.user_id)
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "User Dialog",
                                    params: { user_id: _vm.user_id },
                                  },
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-md greenish ml-2",
                                    staticStyle: { height: "40px" },
                                    attrs: {
                                      "data-testid": "activity-settings-button",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-cogs" }),
                                    _vm._v(
                                      "\n                                Settings\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.section === "Activities",
                  expression: "section === 'Activities'",
                },
              ],
            },
            [
              _c("div", { staticClass: "padding" }, [
                _vm.hasPermissionTo("list report")
                  ? _c("div", { staticClass: "padding" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 p-0 mt-5" },
                          [
                            _c("new-summary-report", {
                              attrs: {
                                user_id: _vm.user_id,
                                "data-testid": "activity-new-summary-report",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasPermissionTo("list report") && _vm.show_graph
                  ? _c("div", { staticClass: "padding" }, [
                      _c("div", { staticClass: "row box" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "no-effect" }, [
                            _c(
                              "div",
                              { staticClass: "box-header pb-0" },
                              [
                                _c("activity-graph", {
                                  attrs: {
                                    base: "user",
                                    "data-testid": "user-activity-graph",
                                    user_id: _vm.user_id,
                                    is_first_load: _vm.reports_first_load,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasPermissionTo("list communication")
                  ? _c("div", { staticClass: "padding" }, [
                      _c("div", { staticClass: "row box" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "no-effect" }, [
                            _c(
                              "div",
                              { staticClass: "box-body" },
                              [
                                _c("comm-log-table", {
                                  attrs: {
                                    user_id: _vm.user_id,
                                    "data-testid": "activity-comm-log-table",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.section === "Transcriptions" &&
          _vm.shouldDisplayTranscriptionSections
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "padding mt-5 pt-3" },
                  [
                    _c("transcription-reporting-panel", {
                      attrs: {
                        user_id: _vm.user_id,
                        "data-testid": "activity-transcription-reporting-panel",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-backtop", {
            ref: "backtop",
            attrs: {
              target: "html",
              "data-testid": "activity-backtop",
              bottom: 20,
              right: 20,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }