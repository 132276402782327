var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    { attrs: { placement: _vm.placement, trigger: "hover" } },
    [
      _vm.workflow
        ? _c(
            "div",
            {
              staticClass: "cursor-pointer d-inline-block _400",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _vm.workflow.real_status === _vm.Workflow.CURRENT_STATUS_INVALID
                ? _c("div", [
                    _c("i", {
                      staticClass:
                        "fa fa-exclamation-triangle text-danger mr-1",
                    }),
                    _vm._v(" "),
                    _vm.is_long_message
                      ? _c("span", [
                          _vm._v(
                            "This sequence is invalid and will not process enrolled contacts. "
                          ),
                        ])
                      : _c("span", [_vm._v("Invalid")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "badge badge-pill badge-danger" },
                      [_vm._v(_vm._s(_vm.failed_diagnosis_count))]
                    ),
                  ])
                : _c("div", [
                    _vm.workflow.active
                      ? _c("div", [
                          _vm.workflow.real_status ===
                            _vm.Workflow.CURRENT_STATUS_RUNNING ||
                          _vm.workflow.real_status ===
                            _vm.Workflow.CURRENT_STATUS_PENDING
                            ? _c("div", [
                                _c("i", {
                                  staticClass:
                                    "fa fa-play-circle text-success mr-1",
                                }),
                                _vm._v(" "),
                                _vm.is_long_message
                                  ? _c("span", [
                                      _vm._v(
                                        "This sequence is active and running."
                                      ),
                                    ])
                                  : _c("span", [_vm._v("Running")]),
                              ])
                            : _vm._e(),
                        ])
                      : _c("div", [
                          _c("i", {
                            staticClass: "fa fa-pause-circle text-warning mr-1",
                          }),
                          _vm._v(" "),
                          _vm.is_long_message
                            ? _c("span", [_vm._v("This sequence is paused.")])
                            : _c("span", [_vm._v("Paused")]),
                        ]),
                  ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "p-2" }, [
        _c("h6", { staticClass: "text-dark mb-2" }, [_vm._v("Diagnosis")]),
        _vm._v(" "),
        _c(
          "table",
          _vm._l(_vm.workflow.diagnosis, function (diagnosis) {
            return _c("tr", { key: diagnosis.name }, [
              _c("td", { staticClass: "pr-2" }, [
                diagnosis.value
                  ? _c("i", { staticClass: "fa fa-check text-success" })
                  : _vm._e(),
                _vm._v(" "),
                !diagnosis.value
                  ? _c("i", { staticClass: "fa fa-remove text-danger" })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n                    " +
                    _vm._s(diagnosis.name) +
                    "\n                "
                ),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }