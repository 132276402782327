var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.message ? _c("p", [_vm._v(_vm._s(_vm.data.message))]) : _vm._e(),
    _vm._v(" "),
    _vm.data.error ? _c("p", [_vm._v(_vm._s(_vm.data.error))]) : _vm._e(),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    !_vm.data.success && _vm.data.error_code
      ? _c("p", [_vm._v("Error code: " + _vm._s(_vm.data.error_code))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }