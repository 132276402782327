<template>
    <div>
        <div class="vertical-center text-left mb-4"
             v-if="series_name == 'campaign'">
            <span v-if="filter.from_date"
                  class="call-log-head">
                Lines Talk Time from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
            </span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
                Lines Talk Time
                <strong>All Time</strong>
            </span>
        </div>
        <div class="vertical-center text-left mb-4"
             v-else-if="series_name == 'user'">
            <span v-if="filter.from_date"
                  class="call-log-head">
                Users Talk Time from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
            </span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
                Users Talk Time
                <strong>All Time</strong>
            </span>
        </div>
        <div class="placeholder text-center d-flex align-items-center justify-content-center"
             :class="{ blink: loading }"
             v-if="loading || campaigns.length == 0"
             style="height: 450px">
            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div style="height: 450px"
             v-else>
            <graph-data-switch :series="options.series"
                               :type="type">
                <highcharts v-bind:id="graph_id"
                            v-show="is_done && options.series.length > 0"
                            :options="options"
                            style="height: 450px"
                            ref="highchart">
                </highcharts>
                <div class="el-table__empty-block"
                     v-show="is_done && !options.series.length">
                    <span class="el-table__empty-text"
                          style="color: #606266;">
                        No Data
                    </span>
                </div>
            </graph-data-switch>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {graph_mixin, report_mixin} from '../mixins'

    export default {
        props: {
            series_name: {
                default: 'campaign'
            },
            stacked: {
                type: Boolean,
                default: false
            },
            report_index: {
                default: 0
            },
            type: {}
        },

        mixins: [report_mixin, graph_mixin],

        data() {
            return {
                auth: auth,
                loading: false,
                aggregated_counts: [],
                axisLabels: [],
                graph_id: '',
                report_type: 'talktime_v_campaign',
                options: {
                    chart: {
                        type: 'column',
                    },
                    plotOptions: {
                        column: {
                            // cursor: 'pointer',
                            stacking: null,
                            crisp: false,
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    if (this.y != 0) {
                                        return this.y
                                    }
                                },
                                crop: true,
                                style: {
                                    fontSize: '14px'
                                }
                            }
                        },
                    },
                    xAxis: {
                        type: "category",
                    },
                    yAxis: {
                        minTickInterval: 1,
                        title: {
                            text: 'Number of Calls',
                            style: {
                                'font-size': '14px',
                                'color': '#090A0D'
                            }
                        },
                        min: 0,
                        tickInterval: 1
                    },
                    tooltip: {
                        headerFormat: "",
                        pointFormatter: function () {
                            let toReturn = '<b>' + this.name + '</b> has <b>' + this.y.toLocaleString() + '</b>'
                            toReturn += this.y > 1 ? ' calls' : ' call'

                            if (this.series.name == '1Hr+') {
                                toReturn += ' that lasts more than an hour'
                                return toReturn
                            }

                            toReturn += ' that lasts ' + this.series.name.replace('Mins', 'minutes')
                            return toReturn
                        },
                        split: false,
                        enabled: true
                    }
                }
            }
        },

        mounted() {
            this.setGraphId()
            this.setReportType()
            this.setIsStacked()
            this.getCommunications()
        },

        methods: {
            generateGraphData: function () {
                // setup the data attrs
                let data_series = []
                let xAxisLabels = []
                let seriesLabels = []
                let seriesNames = []

                //get distinct series labels
                this.aggregated_counts.forEach((campaign_data, index) => {
                    //do not include nulls
                    if (campaign_data.series_name == null) {
                        return
                    }
                    //do not include empty data
                    if (Object.keys(campaign_data.data).length > 0) {
                        for (let key in campaign_data.data) {
                            if (seriesNames.indexOf(campaign_data.data[key].name) < 0) {
                                seriesLabels.push({
                                    name: campaign_data.data[key].name,
                                    color: campaign_data.data[key].color,
                                })
                                seriesNames.push(campaign_data.data[key].name)
                            }
                        }
                    }
                })

                //loop on all tags for the chart series
                seriesLabels.forEach(axisLabel => {
                    let campaigns = []
                    this.aggregated_counts.forEach(campaign_data => {
                        //do not include empty data
                        if (Object.keys(campaign_data.data).length > 0) {
                            let labelValue = '',
                                yVal = 0

                            for (let key in campaign_data.data) {
                                //set equivalent y axis
                                if (campaign_data.data[key].name == axisLabel.name) {
                                    labelValue = campaign_data.series_name
                                    yVal = parseFloat(campaign_data.data[key].count)
                                    break
                                }
                            }
                            if (labelValue !== '' && labelValue !== null) {
                                campaigns.push({
                                    name: labelValue,
                                    y: yVal,
                                })
                            }
                            //set distinct xaxis labels
                            if (xAxisLabels.indexOf(campaign_data.series_name) < 0) {
                                xAxisLabels.push(campaign_data.series_name)
                            }
                        }
                    })

                    //add chart series
                    data_series.push({
                        name: this.getSeriesLabel(axisLabel.name),
                        point_index: axisLabel.name,
                        color: axisLabel.color,
                        data: campaigns,
                    })
                })

                this.axisLabels = xAxisLabels
                //this should update xaxis labels
                //this.options.xAxis.categories = xAxisLabels
                //this.options.xAxis.setCategories(xAxisLabels)

                if (data_series.length) {
                    this.options.series = data_series
                } else {
                    this.options.series = []
                }
                this.$emit('reportLoaded', this.report_index)
            },
            getSeriesLabel: function (seriesVal) {
                if (seriesVal > 11) {
                    return '1Hr+'
                }
                //return (seriesVal * 5 > 0) ? seriesVal * 5 + ' Mins' : '0'
                return (seriesVal * 5) + '-' + ((seriesVal * 5) + 5) + ' Mins'
            },
            setReportType: function () {
                switch (this.series_name) {
                    case 'user':
                        this.report_type = 'talktime_v_user'
                        break
                    default: //campaign
                        this.report_type = 'talktime_v_campaign'
                        break
                }
            },
            setGraphId: function () {
                this.graph_id = 'talktime-' + this.series_name
                if (this.stacked) {
                    this.graph_id += '-stacked'
                }
                this.graph_id += '-inverse-graph'
            },
            setIsStacked: function () {
                if (!this.stacked) {
                    this.options.plotOptions.column.stacking = null
                } else {
                    this.options.plotOptions.column.stacking = 'normal'
                }
            },
        },
        watch: {
            series_name: {
                handler: function (newValue, oldValue) {
                    this.setGraphId()
                    this.setReportType()
                    this.getCommunications()
                }
            },
            stacked: {
                handler: function (newValue, oldValue) {
                    this.setGraphId()
                    this.setIsStacked()
                    this.getCommunications()
                }
            },
        },
    }
</script>
