export const actionFilters = [
    {
        filter: {
            label: 'add_number',
        },
        name: 'Add number',
    },
    {
        filter: {
            label: 'transferred_in',
        },
        name: 'Transferred in',
    },
    {
        filter: {
            label: 'transferred_out',
        },
        name: 'Transferred out',
    },
    {
        filter: {
            attribute: 'default_callerid',
            relation: 'IncomingNumber',
            type: 'relation_updated'
        },
        name: 'Set / Unset Default Call Mask',
    },
    {
        filter: {
            attribute: 'do_not_use',
            relation: 'IncomingNumber',
            type: 'relation_updated'
        },
        name: 'Pause / Activate',
    },
    {
        filter: {
            type: 'model_created',
        },
        name: 'Line Created',
    },
    {
        filter: {
            attribute: 'greeting_tts',
            type: 'model_updated',
        },
        name: 'Set Greeting Text-to-Speech',
    },
    {
        filter: {
            attribute: 'delete_number',
        },
        name: 'Delete number',
    },
    {
        filter: {
            attribute: 'should_greet',
            type: 'model_updated',
        },
        name: 'Enable / Disabled Welcome message to callers.',
    },
    {
        filter: {
            attribute: 'should_ask_for_text_authorization',
            type: 'model_updated',
        },
        name: 'Enable / Disabled TCPA',
    },
    {
        filter: {
            attribute: 'should_record',
            type: 'model_updated'
        },
        name: 'Enable / Disabled Call Recordings',
    },
    {
        filter: {
            attribute: 'closed_hours_voice_prompt',
            type: 'model_updated',
        },
        name: 'Set Closed hours voice prompt',
    },
    {
        filter: {
            attribute: 'closed_hours_auto_reply_text',
            type: 'model_updated',
        },
        name: 'Set Closed hours auto reply text',
    },
    {
        filter: {
            attribute: 'record_tts',
            type: 'model_updated',
        },
        name: 'Set Recording Text-to-Speech',
    },
    {
        filter: {
            attribute: 'ask_for_text_authorization_tts',
            type: 'model_updated',
        },
        name: 'Set TCPA Prompt Text-to-Speech',
    },
    {
        filter: {
            attribute: 'call_router_behavior',
            type: 'model_updated',
        },
        name: 'Set Call router behavior',
    },
    {
        filter: {
            attribute: 'call_waiting_ring_group_id',
            type: 'model_updated',
        },
        name: 'Enable / Disabled Call Waiting',
    },
    {
        filter: {
            attribute: 'csat_id',
            type: 'model_updated',
        },
        name: 'Enable / Disabled CSAT',
    },
    {
        filter: {
            attribute: 'last_called_agent_missed_call_notification',
            type: 'model_updated',
        },
        name: 'Enable / Disabled Notify the last agent who called the contact',
    },
    {
        filter: {
            attribute: 'contact_owner_missed_call_notification',
            type: 'model_updated',
        },
        name: 'Enable / Disabled Notify the contact owner',
    },
    {
        filter: {
            attribute: 'should_message_if_missed',
            type: 'model_updated',
        },
        name: 'Enable / Disabled Text message after missed call',
    },
    {
        filter: {
            attribute: 'missed_call_message',
            type: 'model_updated',
        },
        name: 'Set Missed call message',
    },
    {
        filter: {
            attribute: 'should_whisper',
            type: 'model_updated',
        },
        name: 'Enable / Disabled Whisper',
    },
    {
        filter: {
            attribute: 'whisper_tts',
            type: 'model_updated',
        },
        name: 'Set Whisper Message',
    },
    {
        filter: {
            attribute: 'caller_id_option',
            type: 'model_updated',
        },
        name: 'Enable / Disabled Caller ID Suppression',
    },
    {
        filter: {
            attribute: 'should_block_spam',
            type: 'model_updated',
        },
        name: 'Enable / Disabled Spam Detection',
    },
    {
        filter: {
            attribute: 'triggers',
            type: 'model_updated',
        },
        name: 'Set Triggers',
    }
];

export const textConfigs = [
    {
        match: {
            label: 'add_number'
        },
        text: '`Number <strong>${audit.changes.phone_number}</strong> added`',
        icon: 'added'
    },
    {
        match: {
            label: 'delete_number'
        },
        text: '`Number <strong>${audit.changes.phone_number}</strong> unrented`',
        icon: 'deleted'
    },
    {
        match: {
            label: 'do_not_use'
        },
        text: '`Number <strong>${audit.related.phone_number}</strong> set to <i>Do Not Use</i>`',
        icon: 'deleted'
    },
    {
        match: {
            label: 'activate'
        },
        text: '`Number <strong>${audit.related.phone_number}</strong> re-activated`',
        icon: 'added'
    },
    {
        match: {
            label: 'default_callerid_set'
        },
        text: '`Number <strong>${audit.related.phone_number}</strong> set as <strong>Default CallerID</strong>`',
        icon: 'edited'
    },
    {
        match: {
            label: 'default_callerid_unset'
        },
        text: '`Number <strong>${audit.related.phone_number}</strong> removed as <strong>Default CallerID</strong>`',
        icon: 'deleted'
    },
    {
        match: {
            label: 'transferred_in'
        },
        text: '`Number ${audit.related.phone_number} transferred from Line #${audit.changes.campaign_id}`',
        icon: 'transferred'
    },
    {
        match: {
            label: 'transferred_out'
        },
        text: '`Number ${audit.related.phone_number} transferred to Line #${audit.changes.campaign_id}`',
        icon: 'transferred'
    },
    {
        match: {
            event_type: 'model_created'
        },
        text: '`Line <strong>${audit.changes.name}</strong> was created by <strong>${audit.user?audit.user.name:\'No user\'}</strong>`',
        icon: 'added'
    },
    {
        match: {
            event_type: 'model_deleted'
        },
        text: '`The Line was deleted by <strong>${audit.user?audit.user.name:\'No user\'}</strong>`',
        icon: 'deleted'
    },
    {
        match: {
            event_type: 'relation_created'
        },
        text: '`Relation created with <strong>${audit.related_type} #${audit.related_id}</strong>`',
        icon: 'added'
    },
    {
        match: {
            event_type: 'relation_updated'
        },
        text: '`Related Model <strong>${audit.related_type} #${audit.related_id}</strong> updated`',
        icon: 'edited'
    },
    {
        match: {
            event_type: 'relation_deleted'
        },
        text: '`Relation deleted for <strong>${audit.related_type} #${audit.related_id}</strong>`',
        icon: 'deleted'
    },
    {
        match: {
            attribute: 'active'
        },
        text: '`Line <strong>${change[1] ? \'Paused\' : \'Activated (unpaused)\'}</strong>`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'name'
        },
        text: '`Name changed from "<strong>${change[0]}</strong>" to "<strong>${change[1]}</strong>"`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'description'
        },
        text: '`Description changed from "<strong>${change[0]}</strong>" to "<strong>${change[1]}</strong>"`',
        icon: 'description_changed'
    },
    {
        match: {
            attribute: 'send_long_messages_as_mms'
        },
        text: '`${change[1] ? \'Enabled\' : \'Disabled\'} sending long text messages as MMS`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'should_greet'
        },
        text: '`${change[1] ? \'Enabled\' : \'Disabled\'} Caller Greeting/Welcome Message`',
        icon: 'description_changed'
    },
    {
        match: {
            attribute: 'greeting_tts'
        },
        text: '`Greeting/Welcome TTS message ${change[0]?.trim() ? `changed from "${change[0]}" to` : \'set to\'} "${change[1]}"`',
        icon: 'defined_messages'
    },
    {
        match: {
            attribute: 'greeting_file'
        },
        text: '`Greeting/Welcome audio file ${change[0] ? \'added\' : \'changed\'}`',
        icon: 'defined_messages'
    },
    {
        match: {
            attribute: 'should_record'
        },
        text: '`${change[1] ? \'Enabled\' : \'Disabled\'} Call Recording`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'record_tts'
        },
        text: '`Recording Notice TTS message ${change[0]?.trim() ? `changed from "${change[0]}" to` : \'set to\'} "${change[1]}"`',
        icon: 'description_changed'
    },
    {
        match: {
            attribute: 'record_file'
        },
        text: '`Recording Notice audio file ${change[0] ? \'added\' : \'changed\'}`',
        icon: 'description_changed'
    },
    {
        match: {
            attribute: 'operating_hours'
        },
        text: '`<strong>Operating Hours</strong> updated`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'closed_hours_voice_prompt'
        },
        text: '`<strong>Closed Hours Voice Prompt</strong> updated`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'closed_hours_auto_reply_text'
        },
        text: '`<strong>Closed Hours Auto-Reply Text</strong> updated`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'should_ask_for_text_authorization'
        },
        text: '`${change[1] ? \'Enabled\' : \'Disabled\'} TCPA - Ask for Text Authorization`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'ivr_id'
        },
        text: '`Line\'s IVR changed\n from <strong>${change[0] ? `${change[0]}` : \'None\'}</strong>\n to <strong>${change[1] ? `${change[1]}` : \'None\'}</strong>`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'updated_at'
        },
        text: '`The update time has changed`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'ring_group_id'
        },
        text: '`Ring group changed from <strong>${change[0] ? `${change[0]}` : \'None\'}</strong>\n to <strong>${change[1] ? `${change[1]}` : \'None\'}</strong>`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'call_router_behavior'
        },
        text: '`Call router behavior changed\n from <strong>${change[0] ? `#${change[0]}` : \'None\'}</strong>\n to <strong>${change[1] ? `#${change[1]}` : \'None\'}</strong>`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'ask_for_text_authorization_tts'
        },
        text: '`The text for text message authorization has changed from <strong>${change[0]}</strong> to <strong>${change[1]}</strong>`',
        icon: 'description_changed'
    },
    {
        match: {
            attribute: 'call_waiting_ring_group_id'
        },
        text: '`${change[1] ? \'Enabled\' : \'Disabled\'} Call waiting`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'user_id'
        },
        text: '`Calls reassigned from user <strong>${change[0] ? `${change[0]}` : \'None\'}</strong> to <strong>${change[1] ? `${change[1]}` : \'None\'}</strong>`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'csat_id'
        },
        text: '`${change[1] ? \'Enabled\' : \'Disabled\'} Customer Satisfaction collection for all calls to this line`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'last_called_agent_missed_call_notification'
        },
        text: '`${change[1] ? \'Enabled\' : \'Disabled\'} Notify the last agent who called contact`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'contact_owner_missed_call_notification'
        },
        text: '`${change[1] ? \'Enabled\' : \'Disabled\'} Notify the contact owner`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'missed_call_message'
        },
        text: '`Message after missed call has changed from <strong>${change[0]}</strong> to <strong>${change[1]}</strong>`',
        icon: 'description_changed'
    },
    {
        match: {
            attribute: 'should_message_if_missed'
        },
        text: '`${change[1] ? \'Enabled\' : \'Disabled\'} Text message after missed call`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'whisper_tts'
        },
        text: '`Whisper message has changed from <strong>${change[0]}</strong> to <strong>${change[1]}</strong>`',
        icon: 'description_changed'
    },
    {
        match: {
            attribute: 'caller_id_option'
        },
        text: '`${change[1] ? \'Enabled\' : \'Disabled\'} Caller ID suppression`',
        icon: 'edited'
    },
    {
        match: {
            attribute: 'should_block_spam'
        },
        text: '`${change[1] ? \'Enabled\' : \'Disabled\'} Spam Detection`',
        icon: 'edited'
    },
    {
        match: {
            event_type_generic: 'model_updated'
        },
        text: '`Line Attribute <strong>${attribute}</strong> changed`',
        icon: 'edited'
    }
];
