var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-form-item", { attrs: { prop: _vm.id } }, [
    _c("span", { staticClass: "page-anchor", attrs: { id: _vm.id } }),
    _vm._v(" "),
    _c("div", { staticClass: "form-label" }, [
      _c("h5", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("small", [
        _vm._v("Prevent agents from overriding call recording settings."),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "switch-label" },
      [
        _c("el-switch", {
          attrs: { "active-color": "#00a344" },
          on: {
            change: function ($event) {
              return _vm.$emit("change", _vm.text)
            },
          },
          model: {
            value: _vm.text,
            callback: function ($$v) {
              _vm.text = $$v
            },
            expression: "text",
          },
        }),
        _vm._v(" "),
        _c("label", [_vm._t("default")], 2),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }