<template>
    <div class="col-md-4 d-sm-none d-md-block box-card help-card p-3">
        <div v-if="report_type_index === 0 && category == 'lines'">
            <div class="text item">
                <strong>Overall Line Traffic</strong>
                <br>
                <small>
                    You're viewing a comparison of all calls and messages, across your lines. You may
                    change the time frame of this
                    report using the date selectors above.
                </small>
                <br>
                <small>
                    Clicking on the "slice of the pie"
                    will drill down into that line and show you
                    traffic info.
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> You can determine the total inbound and outbound calls & texts by
                    clicking on the area labels.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 1 && category == 'lines'">
            <div class="text item">
                <strong>Lines by Tags</strong>
                <br>
                <small>
                    Sometimes you want to see the performance of a certain tag across each of your
                    lines. This is really useful
                    when you're only interested in "converted"
                    calls or "qualified"
                    calls. This report
                    allows you
                    to show & hide tags and tune the view.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 2 && category == 'lines'">
            <div class="text item">
                <strong>Disposition by Lines</strong>
                <br>
                <small>
                    Although every business strives for a 100% answer rate on phone calls, reaching that
                    metric takes time.
                    This report shows how your calls have been answered (or missed) across your lines.
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> A call is considered abandoned when caller hangs up before reaching
                    your agent. Missed calls
                    happen when they hangup while waiting.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 3 && category == 'lines'">
            <div class="text item">
                <strong>Call Talk Times</strong>
                <br>
                <small>
                    This report shows how long your calls typically take across 5-minute bins.
                    For a lot of businesses, having longer calls means more conversions / sales.
                    <br>
                    For support centers, longer calls usually means they can improve their rep performance.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 4 && category == 'lines'">
            <div class="text item">
                <strong>Lines by Average Talk Time</strong>
                <br>
                <small>

                </small>
            </div>
        </div>
        <div v-if="report_type_index === 5 && category == 'lines'">
            <div class="text item">
                <strong>Numbers Map</strong>
                <br>
                <small>
                    If you have multiple locations in the US, and have assigned each location a
                    unique local phone number, this report shows you the individual state / region
                    performance of your numbers.
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> for city-level data, or Canadian provinces, please contact us.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 0 && category == 'ring_groups'">
            <div class="text item">
                <strong>Overall Ring Group Traffic</strong>
                <br>
                <small>
                    You're viewing a comparison of all calls and messages, across your ring groups.
                    You may change the time frame of this report using the date selectors above.
                    Clicking on the "slice of the pie" will drill down into that line and show you traffic info.
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> You can determine the total inbound and outbound calls & texts by
                    clicking on the area labels.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 1 && category == 'ring_groups'">
            <div class="text item">
                <strong>Ring Groups by Tags</strong>
                <br>
                <small>
                    Sometimes you want to see the performance of a certain tag across each of your ring groups.
                    This is really useful when you're only interested in "converted" calls or "qualified" calls.
                    This report allows you to show & hide tags and tune the view.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 2 && category == 'ring_groups'">
            <div class="text item">
                <strong>Disposition by Ring Groups</strong>
                <br>
                <small>
                    Although every business strives for a 100% answer rate on phone calls, reaching that
                    metric takes time. This report shows how your calls have been answered (or missed) across
                    your ring groups.
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> A call is considered abandoned when caller hangs up before reaching
                    your agent. Missed calls
                    happen when they hangup while waiting.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 3 && category == 'ring_groups'">
            <div class="text item">
                <strong>Call Talk Times</strong>
                <br>
                <small>
                    This report shows how long your calls typically take across 5-minute bins.
                    For a lot of businesses, having longer calls means more conversions / sales.
                    For support centers, longer calls usually means they can improve their rep performance.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 4 && category == 'ring_groups'">
            <div class="text item">
                <strong>Ring Groups by Average Talk Time</strong>
                <br>
                <small>
                    -
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 0 && category == 'callers'">
            <div class="text item">
                <strong>First Time Conversations</strong>
                <br>
                <small>
                    The chart shows the total number of calls & texts from new numbers per line.
                </small>
                <br>
                <small>
                    Clicking on the "slice of the pie"
                    will drill down into that line and show you
                    traffic info.
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> You can determine the total inbound and outbound calls & texts by
                    clicking on the area labels.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 1 && category == 'callers'">
            <div class="text item">
                <strong>Tags by Lines</strong>
                <br>
                <small>
                    Tags are powerful tools to categorize almost everything. When you tag your calls, they
                    will
                    show up here.
                    Try clicking on your line names to show/hide them.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 2 && category == 'callers'">
            <div class="text item">
                <strong>Call Status by Lines</strong>
                <br>
                <small>
                    Be it for back office intelligence or selling leads to your partners, You may want to
                    see
                    how your calls are "ending"
                    (or being disposed, in telco talk). This report shows an
                    overview
                    of call disposition broken down by lines.
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> Hovering over each "stack"
                    shows you the exact number of calls &
                    texts.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 3 && category == 'callers'">
            <div class="text item">
                <strong>Tags by Users</strong>
                <br>
                <small>
                    Your user performance is broken down by your tags in this report. You can see
                    how your users (rep / call buyer) are performing across your tags.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 4 && category == 'callers'">
            <div class="text item">
                <strong>Call Results by Users</strong>
                <br>
                <small>
                    Be it for back office intelligence or selling leads to your partners, You may want to
                    see
                    how your calls are "ending"
                    (or being disposed, in telco talk). This report shows an
                    overview
                    of call disposition broken down by users.
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> Hovering over each "stack"
                    shows you the exact number of calls &
                    texts.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 5 && category == 'callers'">
            <div class="text item">
                <strong>Line Call Talk Times</strong>
                <br>
                <small>
                    This report shows how long your calls typically take across 5-minute bins.
                    For a lot of businesses, having longer calls means more conversions / sales.
                    <br>
                    For support centers, longer calls usually means they can improve their rep performance.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 6 && category == 'callers'">
            <div class="text item">
                <strong>User Call Talk Times</strong>
                <br>
                <small>
                    This report shows how long your calls typically take across 5-minute bins.
                    For a lot of businesses, having longer calls means more conversions / sales.
                    <br>
                    For support centers, longer calls usually means they can improve their rep performance.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 7 && category == 'callers'">
            <div class="text item">
                <strong>Time of Day Call Traffic</strong>
                <br>
                <small>
                    The following graph shows what time of day you are receiving most of your call traffic.
                    This report is exceptionally useful to businesses that experience a surge in calls
                    during certain hours of the day.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 8 && category == 'callers'">
            <div class="text item">
                <strong>Caller Number Map</strong>
                <br>
                <small>
                    This report shows you where you are receiving more calls based on caller phone number's
                    area code
                    and our other data sources (called CNAM).
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> If you need city-level data or looking to capture names and zipcodes,
                    please contact us.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 0 && category == 'users'">
            <div class="text item">
                <strong>Overall User Traffic</strong>
                <br>
                <small>
                    You're viewing a comparison of all calls and messages by users, across your
                    lines. You may change the time frame of this
                    report using the date selectors above.
                </small>
                <br>
                <small>
                    Clicking on the "slice of the pie"
                    will drill down into that user and show you
                    traffic info.
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> You can determine the total inbound and outbound calls & texts by
                    clicking on the area labels.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 1 && category == 'users'">
            <div class="text item">
                <strong>Tags by Users</strong>
                <br>
                <small>
                    Your user performance is broken down by your tags in this report. You can see
                    how your users (rep / call buyer) are performing across your tags.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 2 && category == 'users'">
            <div class="text item">
                <strong>Call Results by Users</strong>
                <br>
                <small>
                    Be it for back office intelligence or selling leads to your partners, You may want to
                    see
                    how your calls are "ending"
                    (or being disposed, in telco talk). This report shows an
                    overview
                    of call disposition broken down by users.
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> Hovering over each "stack"
                    shows you the exact number of calls &
                    texts.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 3 && category == 'users'">
            <div class="text item">
                <strong>Call Talk Times</strong>
                <br>
                <small>
                    This report shows how long your calls typically take across 5-minute bins.
                    For a lot of businesses, having longer calls means more conversions / sales.
                    <br>
                    For support centers, longer calls usually means they can improve their rep performance.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 4 && category == 'users'">
            <div class="text item">
                <strong>Users by Average Talk Time</strong>
                <br>
                <small>

                </small>
            </div>
        </div>
        <div v-if="report_type_index === 5 && category == 'users'">
            <div class="text item">
                <strong>User Map</strong>
                <br>
                <small>
                    If you are routing your calls to different offices / buyers across the US, and have
                    assigned
                    each of them a unique local phone number, this report shows you how each location is
                    receiving
                    your call traffic.
                </small>
            </div>

            <div class="text item card-bottom">
                <small><b>Pro tip:</b> for geo-routing assistance, please contact us.</small>
            </div>
        </div>
        <div v-if="report_type_index === 0 && category == 'performance'">
            <div class="text item">
                <strong>Agent Status</strong>
                <br>
                <small>
                    Users are broken down by their status within this period of time. Times are reported in
                    HH:mm:ss format.
                </small>
                <br>
                <small>Note that:</small>
                <br>
                <small>- All means all users aggregated report</small>
            </div>
        </div>
        <div v-if="report_type_index === 1 && category == 'performance'">
            <div class="text item">
                <strong>Users by Performance</strong>
                <br>
                <small>
                    Using the data above, Users are broken down by their performance over all your
                    calls within this period of time.
                    <br>
                    Note that: <br>
                    - All means all calls attempted <br>
                    - Answered is every call that is not missed <br>
                    - Billable are all calls that are qualified, per user settings <br>
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 2 && category == 'performance'">
            <div class="text item">
                <strong>Users by Average Talk Time</strong>
                <br>
                <small>

                </small>
            </div>
        </div>
        <div v-if="report_type_index === 3 && category == 'performance'">
            <div class="text item">
                <strong>Users by Average Wait Time</strong>
                <br>
                <small>

                </small>
            </div>
        </div>
        <div v-if="report_type_index === 4 && category == 'performance'">
            <div class="text item">
                <strong>Lines by Average Wait Time</strong>
                <br>
                <small>

                </small>
            </div>
        </div>
        <div v-if="report_type_index === 0 && category == 'leads'">
            <div class="text item">
                <strong>Contact Phone Numbers</strong>
                <br>
                <small>
                    This report shows the total number of contact phone numbers created per day in the selected date
                    range.
                </small>
            </div>
        </div>
        <div v-if="report_type_index === 1 && category == 'leads'">
            <div class="text item">
                <strong>New Contacts vs DNC Contacts</strong>
                <br>
                <small>
                    This report shows the total number of new contacts vs DNC contacts per day in the
                    selected date range.
                </small>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            report_type_index: {
                type: Number,
                default: 0
            },
            category: {
                type: String,
                default: 'lines'
            }
        },
    }
</script>
