<template>
    <div>
        <div class="vertical-center text-left mb-4">
			<span v-if="filter.from_date"
                  class="call-log-head">
				Contact Phone Numbers from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
			</span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
				Contact Phone Numbers
                <strong>All Time</strong>
			</span>
        </div>
        <div class="placeholder text-center d-flex align-items-center justify-content-center"
             :class="{ blink: loading }"
             v-if="loading || aggregated_counts.length === 0"
             style="height: 450px">
            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div style="height: 450px"
             v-else>
            <graph-data-switch :series="options.series"
                               :type="type"
                               :report_type="'leads_intake'">
                <highcharts v-bind:id="graph_id"
                            v-show="is_done && options.series.length > 0"
                            :options="options"
                            style="height: 450px"
                            ref="highchart">
                </highcharts>
                <div class="el-table__empty-block"
                     v-show="is_done && options.series.length == 0">
                    <span class="el-table__empty-text"
                          style="color: #606266;">
                        No Data
                    </span>
                </div>
            </graph-data-switch>
        </div>
    </div>
</template>

<script>
    import {date_mixin, graph_mixin, report_mixin} from '../mixins'
    import auth from '../auth'

    export default {
        props: {
            report_index: {
                default: 0
            },
            type: {}
        },

        mixins: [date_mixin, report_mixin, graph_mixin],

        data() {
            return {
                auth: auth,
                loading: false,
                aggregated_counts: [],
                graph_id: 'leads-intake-counts-graph',
                options: {
                    rangeSelector: {
                        enabled: false
                    },

                    plotOptions: {
                        spline: {
                            marker: {
                                enabled: true,
                                radius: 3
                            }
                        },
                    },

                    time: {
                        useUTC: false,
                        timezone: window.timezone
                    },

                    // force the plot to show all ticks daily
                    xAxis: {
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            millisecond: '%e %b', // always use day as highest resolution.
                            second: '%e %b', // always use day as highest resolution.
                            minute: '%e %b', // always use day as highest resolution.
                            hour: '%H:%M',
                            day: '%e %b', // always use day as highest resolution.
                            week: '%e %b', // always use day as highest resolution.
                            month: '%b \'%y',
                            year: '%Y'
                        },
                        // minRange: 1 * 24 * 3600000, // 1 day
                        labels: {
                            rotation: 45,
                            // step: 1,
                            style: {
                                fontSize: '14px'
                            }
                        }
                    },

                    yAxis: {
                        allowDecimals: false,
                        offset: 20,
                        title: {
                            text: 'Number Of Leads',
                            style: {
                                'font-size': '14px',
                                'color': '#090A0D'
                            }
                        }
                    },

                    tooltip: {
                        useHTML: true,
                        shared: true,
                        style: {
                            color: '#2e3e4e',
                            fontSize: '14px'
                        },
                        formatter: function () {
                            let s = []
                            s.push(`<strong class="mb-0">New Contact Phone Numbers:</strong> <span class="text-grey-900" style="font-size: 0.875rem"><b>${this.y.toLocaleString()}</b></span>`)
                            s.push(`<span class="text-grey-900 mt-0 mb-3" style="font-size: 0.65rem">${moment(this.x).format('dddd, MMMM D YYYY').toString()}</span>`)
                            s = s.join("</br>")
                            return s
                        }
                    },

                    legend: {
                        enabled: false,
                    },

                    credits: {
                        enabled: false
                    },

                    exporting: {
                        sourceWidth: 0,
                        sourceHeight: 0
                    },

                    series: []
                },
                report_type: 'leads_intake'
            }
        },

        mounted() {
            this.getLeadsIntakeReport()
        },

        methods: {
            generateGraphData: function () {
                if (!Array.isArray(this.aggregated_counts)) {
                    console.log("Non json response provided")
                    // stop execution
                    return Promise.reject("Non json response provided")
                }

                // loop over what you got and setup the plots.
                let keys = Object.keys(this.aggregated_counts)
                let min_overall, max_overall

                // prepare min & max of time range
                let min, max
                // create a base series based on this with all ticks available.
                let base_series = []
                // arrange this in hash table with timezone in mind.
                let base_data = {}

                for (let index in keys) {
                    // get the data from API response.
                    let series_data = this.aggregated_counts[keys[index]]

                    let ts = series_data.timestamp // seconds
                    // removes time from datetime this will fix timezone issues
                    let date = parseInt(this.localizedMoment(ts, 'YYYY-MM-DD HH:mm:ss').startOf('day').format('x') / 1000)
                    // push to hash table
                    if (base_data[date]) {
                        base_data[date] = base_data[date] + series_data.counts
                    } else {
                        base_data[date] = series_data.counts
                    }

                    if (min == undefined || min > date) {
                        min = date
                    }
                    if (max == undefined || max < date) {
                        max = date
                    }

                    if (min_overall > min || !min_overall) {
                        min_overall = min
                    }

                    if (max_overall < max || !max_overall) {
                        max_overall = max
                    }
                }

                // convert min, max to moment objects
                min = moment.unix(min)
                max = moment.unix(max)

                // when period is daily
                if (this.filter.chart_period == "day") {
                    // force the plot to show all ticks
                    // this.options.xAxis.tickInterval = 24 * 3600 * 1000 // 1 day
                    // this.options.xAxis.labels.step = 1 // 1 day
                    this.options.xAxis.tickInterval = undefined // let the plot decide
                    this.options.xAxis.labels.step = undefined // let the plot decide
                    if (this.filter.from_date) {
                        min = moment(this.filter.from_date).startOf('day') // moment
                    }
                    if (this.filter.to_date) {
                        max = moment(this.filter.to_date).startOf('day') // moment object
                    }
                }
                // week
                if (this.filter.chart_period == "week") {
                    // force the plot to show all ticks
                    this.options.xAxis.tickInterval = undefined // let the plot decide
                    this.options.xAxis.labels.step = undefined // let the plot decide
                    // set the plot to use definitive time-ticks based on period selected
                    if (this.filter.from_date) {
                        min = moment(this.filter.from_date).startOf('isoWeek').startOf('day') // moment object
                    }
                    if (this.filter.to_date) {
                        max = moment(this.filter.to_date).startOf('isoWeek').startOf('day') // moment object
                    }
                    // ^^ we use isoWeek because we want it to start on Monday
                }
                // month to month handling
                if (this.filter.chart_period == "month") {
                    // force the plot to show all ticks
                    this.options.xAxis.tickInterval = undefined // let the plot decide
                    this.options.xAxis.labels.step = undefined // let the plot decide
                    // set the plot to use definitive time-ticks based on period selected
                    if (this.filter.from_date) {
                        min = moment(this.filter.from_date).startOf('month').startOf('day') // moment object
                    }
                    if (this.filter.to_date) {
                        max = moment(this.filter.to_date).startOf('month').startOf('day') // moment object
                    }
                }

                // print each day
                while (min <= max) {
                    let base_point = []
                    let min_unix_timestamp = min.format('X') // seconds
                    // is there a match in our data?
                    if (base_data[min_unix_timestamp]) {
                        base_point = [min_unix_timestamp * 1000, base_data[min_unix_timestamp]]
                    } else {
                        base_point = [min_unix_timestamp * 1000, 0]
                    }
                    base_series.push(base_point)
                    min = min.add(1, this.filter.chart_period)
                }

                let point_start = this.getPointStart(base_series)

                // form the series
                let series = {
                    // type can be line, column, spline
                    type: 'spline',
                    name: 'Leads',
                    data: base_series,
                    pointStart: point_start
                }
                // have high charts re-draw this.
                this.options.series.push(series)

                if (!this.filter.from_date && !this.filter.to_date) {
                    this.options.xAxis.min = min_overall * 1000
                    this.options.xAxis.max = max_overall * 1000
                } else {
                    this.options.xAxis.min = undefined
                    this.options.xAxis.max = undefined
                }

                this.options.xAxis.tickInterval = moment.duration(1, this.filter.chart_period).asMilliseconds()

                this.$emit('reportLoaded', this.report_index)
            },
        },
    }
</script>
