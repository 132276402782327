var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-listing px-0 py-3" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "container-body" }, [
      _c(
        "div",
        {
          staticClass:
            "container-listing-tools d-flex flex-fill flex-wrap flex-lg-nowrap mb-1",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-wrap flex-lg-nowrap mr-auto mr-5" },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex mr-2 search-toolbar",
                  staticStyle: { width: "400px" },
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder:
                          "Search contact ID, phone number, or name...",
                        clearable: "",
                      },
                      model: {
                        value: _vm.filters.search,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "search", $$v)
                        },
                        expression: "filters.search",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        attrs: { slot: "prefix" },
                        slot: "prefix",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  ref: "admin_selector",
                  staticClass: "mr-2",
                  attrs: {
                    autocomplete: "off",
                    "default-first-option": "",
                    filterable: "",
                    clearable: "",
                    placeholder: "Select an event type",
                  },
                  on: { clear: _vm.clearEventTypeSelect },
                  model: {
                    value: _vm.filters.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "type", $$v)
                    },
                    expression: "filters.type",
                  },
                },
                _vm._l(_vm.event_types, function (event_type) {
                  return _c(
                    "el-option",
                    {
                      key: event_type.id,
                      attrs: { label: event_type.label, value: event_type.id },
                    },
                    [_c("span", [_vm._v(_vm._s(event_type.label))])]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("date-selector", {
                staticClass: "mr-2",
                attrs: { default_date_range: "All Time" },
                on: {
                  clearDefaultDateRange: _vm.clearDefaultDateRange,
                  clearCustomDateRange: _vm.clearCustomDateRange,
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { round: "", size: "medium" },
                  on: { click: _vm.getLogs },
                },
                [
                  _c("i", { staticClass: "fa fa-refresh" }),
                  _vm._v(" Refresh List\n                "),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex mt-2 mt-md-0 flex-wrap flex-lg-nowrap" },
            [
              _c("div", { staticClass: "mr-2 align-self-center" }, [
                _c("span", { staticClass: "text-grey" }, [
                  _vm._v("Total Logs:"),
                ]),
                _vm._v(" "),
                _c("strong", [_vm._v(_vm._s(_vm._f("numFormat")(_vm.total)))]),
              ]),
              _vm._v(" "),
              _c("el-pagination", {
                staticClass: "align-self-center",
                attrs: {
                  layout: "prev, pager, next",
                  "current-page": _vm.filters.page,
                  "page-sizes": _vm.sizes,
                  "page-size": _vm.filters.size,
                  total: _vm.total,
                  "pager-count": 5,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.filters, "page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.filters, "page", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.filters, "size", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.filters, "size", $event)
                  },
                  "size-change": _vm.getLogs,
                  "current-change": _vm.getLogs,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.is_logs_loading,
              expression: "is_logs_loading",
            },
          ],
          staticClass: "table-responsive",
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.logs, "show-header": true } },
            [
              _c("el-table-column", {
                attrs: { label: "Contact" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.contact_id
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: _vm.getContactTalkRedirectURL(
                                    scope.row.contact_id
                                  ),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "hover-lower-opacity" },
                                  [
                                    _c("h6", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("contactFullName")(scope.row)
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("small", { staticClass: "text-grey" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("fixPhone")(
                                            scope.row.contact_phone_number
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("br"),
                                  ]
                                ),
                              ]
                            )
                          : _c("h6", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                            Deleted Contact\n                        "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Action" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        ![1, 2].includes(scope.row.type)
                          ? [
                              _c("span", [_vm._v(_vm._s(scope.row.action))]),
                              _vm._v(" "),
                              scope.row.data.sequence
                                ? _c("div", { staticClass: "d-flex" }, [
                                    _c("div"),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass: "mr-3",
                                      attrs: {
                                        src: scope.row.data.sequence.img_src,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("h6", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          _vm._s(scope.row.data.sequence.name)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        { staticClass: "text-grey" },
                                        [
                                          _vm._v(
                                            "Step #" +
                                              _vm._s(
                                                scope.row.data.sequence.order
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          : [_c("span", [_vm._v("-")])],
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Event" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "d-flex align-items-top" }, [
                          _c("i", {
                            staticClass: "b-white bottom workflow-status mr-2",
                            class: scope.row.data.failed
                              ? "bg-workflow-error"
                              : "bg-workflow-success",
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              scope.row.communication_id
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass: "flex-grow-1 workflow-link",
                                      attrs: {
                                        to: {
                                          name: "Communication",
                                          params: {
                                            communication_id:
                                              scope.row.communication_id,
                                          },
                                        },
                                        tag: "a",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row.event)),
                                      ]),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "fa fa-external-link fa-xs",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                    ]
                                  )
                                : [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.event)),
                                    ]),
                                  ],
                              _vm._v(" "),
                              scope.row.data.reason
                                ? [
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("small", {
                                      staticClass: "text-muted",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          scope.row.data.reason
                                        ),
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Time" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("momentFormat")(
                                scope.row.created_at,
                                "MMM D, YYYY h:mma z",
                                true
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("template", { slot: "empty" }, [
                _c(
                  "div",
                  { staticClass: "text-center mx-auto w-50 py-5" },
                  [
                    _c(
                      "div",
                      { staticClass: "w-75 mx-auto" },
                      [_c("img-empty")],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "lead" }, [
                      _vm.filters.search
                        ? _c("span", [
                            _vm._v(
                              '\n                                We could not find an activity log that matches "' +
                                _vm._s(_vm.filters.search) +
                                '".\n                            '
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              "There are no activity history for this sequence."
                            ),
                          ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { round: "", size: "medium" },
                        on: { click: _vm.getLogs },
                      },
                      [
                        _c("i", { staticClass: "fa fa-refresh" }),
                        _vm._v(" Refresh List\n                        "),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container-footer d-flex justify-content-center" },
      [
        _c("el-pagination", {
          attrs: {
            layout: "sizes, prev, pager, next",
            "current-page": _vm.filters.page,
            "page-sizes": _vm.sizes,
            "page-size": _vm.filters.size,
            total: _vm.total,
            "pager-count": 5,
            "hide-on-single-page": true,
          },
          on: {
            "update:currentPage": function ($event) {
              return _vm.$set(_vm.filters, "page", $event)
            },
            "update:current-page": function ($event) {
              return _vm.$set(_vm.filters, "page", $event)
            },
            "update:pageSize": function ($event) {
              return _vm.$set(_vm.filters, "size", $event)
            },
            "update:page-size": function ($event) {
              return _vm.$set(_vm.filters, "size", $event)
            },
            "size-change": _vm.getLogs,
            "current-change": _vm.getLogs,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container-header d-flex" }, [
      _c("h4", { staticClass: "listing-heading" }, [
        _vm._v("Sequence Activity History"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }