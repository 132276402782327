var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-3" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading || _vm.loading_whitelabel,
              expression: "loading || loading_whitelabel",
            },
          ],
          ref: "triggers_form",
          staticClass: "form-aloware",
        },
        [
          _c("div", { staticClass: "mb-5" }, [
            _c("h4", [_vm._v("Lead Intake By Email")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                Use this email address to capture leads via email. " +
                  _vm._s(_vm.statics.name) +
                  " automatically enrolls contacts "
              ),
              _c("br"),
              _vm._v(
                "\n                to this sequence using its best guess algorithm on all incoming emails to this address.\n            "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.show_install_email_service
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "success", plain: "" },
                        on: { click: _vm.installEmailService },
                      },
                      [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading_install_email_service,
                                expression: "loading_install_email_service",
                              },
                            ],
                            staticClass: "material-icons loader pull-left",
                          },
                          [_vm._v("")]
                        ),
                        _vm._v(" "),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.loading_install_email_service,
                              expression: "!loading_install_email_service",
                            },
                          ],
                          staticClass: "fa fa-plus",
                        }),
                        _vm._v(
                          "\n                    Install Email Service\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.show_install_email_service
                  ? _c(
                      "p",
                      { staticClass: "text-md _600 text-dark-greenish" },
                      [
                        _c("input-readonly-with-copy", {
                          model: {
                            value: _vm.mailgun_intake_route_string,
                            callback: function ($$v) {
                              _vm.mailgun_intake_route_string = $$v
                            },
                            expression: "mailgun_intake_route_string",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("h4", [_vm._v("Triggers")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                    Here you can define how this sequence is applied to your contacts, based on their disposition status.\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown",
                    {
                      staticClass: "mb-4 mr-3",
                      attrs: { trigger: "click", placement: "right" },
                      scopedSlots: _vm._u([
                        {
                          key: "dropdown",
                          fn: function () {
                            return [
                              _c(
                                "el-dropdown-menu",
                                [
                                  _vm.canAddContactDispositionTrigger
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.addContactDispositionTrigger.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons mr-1",
                                            },
                                            [_vm._v("contact_phone")]
                                          ),
                                          _vm._v(
                                            "\n                                Add Contact Disposition Trigger\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.canAddCallDispositionTrigger
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.addCallDispositionTrigger.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons mr-1",
                                            },
                                            [_vm._v("contact_phone")]
                                          ),
                                          _vm._v(
                                            "\n                                Add Call Disposition Trigger\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.canAddContactTrigger
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.addAutomationContactTrigger.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons mr-1",
                                            },
                                            [_vm._v("contact_phone")]
                                          ),
                                          _vm._v(
                                            "\n                                Add Contact Trigger\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.canAddCallTrigger
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.addAutomationCallTrigger.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons mr-1",
                                            },
                                            [_vm._v("call")]
                                          ),
                                          _vm._v(
                                            "\n                                Add Call Trigger\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.addContactDNCTrigger.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons mr-1" },
                                        [_vm._v("contact_phone")]
                                      ),
                                      _vm._v(
                                        "\n                                Add Contact DNC Trigger\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.canAddAppointmentTrigger
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.addAppointmentTrigger.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons mr-1",
                                            },
                                            [_vm._v("contact_phone")]
                                          ),
                                          _vm._v(
                                            "\n                                Add Appointment Trigger\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.addContactTaggedTrigger.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons mr-1" },
                                        [_vm._v("label")]
                                      ),
                                      _vm._v(
                                        "\n                                Add Contact Tagged Trigger\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.addContactTagRemovedTrigger.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons mr-1" },
                                        [_vm._v("label")]
                                      ),
                                      _vm._v(
                                        "\n                                Add Contact Tag Removed Trigger\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.addContactAddedToListTrigger.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons mr-1" },
                                        [_vm._v("label")]
                                      ),
                                      _vm._v(
                                        "\n                                Add Contact added to List Trigger\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.addContactRemovedFromListTrigger.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons mr-1" },
                                        [_vm._v("label")]
                                      ),
                                      _vm._v(
                                        "\n                                Add Contact Removed from List Trigger\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "success", plain: "" } },
                        [
                          _c("strong", [
                            _c("i", { staticClass: "fa fa-plus" }),
                            _vm._v(" Add Trigger"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right",
                        width: "400",
                        trigger: "click",
                      },
                      model: {
                        value: _vm.show_copy_trigger_popover,
                        callback: function ($$v) {
                          _vm.show_copy_trigger_popover = $$v
                        },
                        expression: "show_copy_trigger_popover",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-2" },
                        [
                          _c("div", { staticClass: "mb-2" }, [
                            _c("h6", { staticClass: "mb-0" }, [
                              _c("i", { staticClass: "fa fa-copy" }),
                              _vm._v(" Copy Triggers"),
                            ]),
                            _vm._v(" "),
                            _c("small", [
                              _vm._v(
                                "Select the sequence you want to copy the triggers from."
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("workflow-selector", {
                            on: { change: _vm.copyWorkflowTriggers },
                            model: {
                              value: _vm.copy_trigger_workflow_id,
                              callback: function ($$v) {
                                _vm.copy_trigger_workflow_id = $$v
                              },
                              expression: "copy_trigger_workflow_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", plain: "" },
                          slot: "reference",
                        },
                        [
                          _c("i", { staticClass: "fa fa-copy" }),
                          _vm._v(
                            " Copy from other Sequence\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.workflow_triggers, function (trigger, idx) {
                return _c(
                  "div",
                  { key: trigger.id, staticClass: "card shadow-sm mb-3" },
                  [
                    _c("div", { staticClass: "d-flex p-3" }, [
                      _c("div", [
                        ![
                          "contact",
                          "disposition",
                          "call disposition",
                          "appointment",
                          "dnc status",
                          "tag",
                          "remove_tag",
                          "contact_list",
                          "remove_contact_list",
                        ].includes(trigger.type)
                          ? _c("h6", { staticClass: "text-md _600" }, [
                              _vm._v(
                                "\n                            #" +
                                  _vm._s(idx + 1) +
                                  " - " +
                                  _vm._s(_vm._f("fixCommType")(trigger.type)) +
                                  " Trigger\n                        "
                              ),
                            ])
                          : _c("h6", { staticClass: "text-md _600" }, [
                              _vm._v(
                                "\n                            #" +
                                  _vm._s(idx + 1) +
                                  " - " +
                                  _vm._s(_vm.getTriggerTypeName(trigger.type)) +
                                  " Trigger\n                        "
                              ),
                            ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "el-button-group",
                            [
                              (trigger.type === "contact" &&
                                !_vm.automation_trigger) ||
                              (trigger.type != "contact" &&
                                trigger.type != "disposition") ||
                              (trigger.type == "disposition" &&
                                trigger.disposition_status_id != 0) ||
                              (trigger.type == "call disposition" &&
                                trigger.call_disposition_id != 0)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.duplicate(trigger, idx)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-copy" }),
                                      _vm._v(
                                        " Duplicate\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "text-danger",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeAt(idx)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-trash-o" }),
                                  _vm._v(
                                    " Delete\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "px-3 pb-3" },
                      [
                        _c("trigger", {
                          attrs: {
                            trigger: trigger,
                            automation_trigger: _vm.automation_trigger,
                            disable_any_contact_disposition:
                              _vm.contactDispositionTriggerIndex > 0,
                            disable_any_call_disposition:
                              _vm.callDispositionTriggerIndex > 0,
                            used_contact_disposition_statuses:
                              _vm.usedContactDispositionStatuses,
                          },
                          on: {
                            "trigger-validated": _vm.updateTrigger,
                            valid: function ($event) {
                              return _vm.updateValidation(true)
                            },
                            invalid: function ($event) {
                              return _vm.updateValidation(false)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    round: "",
                    disabled: _vm.disable_save_trigger,
                  },
                  on: { click: _vm.saveTriggers },
                },
                [
                  _c("strong", [
                    _c("i", { staticClass: "fa fa-save mr-1" }),
                    _vm._v(" Save Triggers\n                "),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }