var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        trigger: "click",
        placement: "top",
        content: "asd",
        width: "380",
      },
    },
    [
      _c("div", { staticClass: "popover-metric" }, [
        _c("div", { staticClass: "mb-4" }, [
          _c("h6", [_vm._v(_vm._s(_vm.label))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.description))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "text-center" }, [
              _vm.format === "numFormat"
                ? _c("h4", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("numFormat")(_vm.metric.previous_value)) +
                        "\n                    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.format === "fixFullDuration"
                ? _c("h4", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("fixFullDuration")(_vm.metric.previous_value)
                        ) +
                        "\n                    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("label", { staticClass: "m-0 d-block" }, [
                _vm._v("Previous period value"),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "mt-1", staticStyle: { "font-size": ".77rem" } },
                [_vm._v(_vm._s(_vm.metric.previous_date_range))]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "text-center" }, [
              _vm.format === "numFormat"
                ? _c("h4", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("numFormat")(_vm.metric.current_value)) +
                        "\n                    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.format === "fixFullDuration"
                ? _c("h4", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("fixFullDuration")(_vm.metric.current_value)
                        ) +
                        "\n                    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("label", { staticClass: "m-0 d-block" }, [
                _vm._v("Current period value"),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "mt-1", staticStyle: { "font-size": ".77rem" } },
                [_vm._v(_vm._s(_vm.metric.current_date_range))]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "el-button",
              {
                staticClass: "px-4",
                attrs: { type: "success", size: "small" },
              },
              [
                _vm._v(
                  "\n                Open Communication Logs\n            "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          class:
            "metric-widget" +
            (_vm.is_card ? " card p-3" : "") +
            (_vm.no_border ? " no-border" : ""),
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [
          _c("div", [
            _c("div", { staticClass: "mb-1" }, [
              _c("h2", [
                _vm.format === "numFormat"
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("numFormat")(_vm.metric.current_value))
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.format === "fixFullDuration"
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("fixFullDuration")(_vm.metric.current_value)
                        )
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-1", class: _vm.difference_class }, [
              _vm.format === "numFormat"
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.metric.difference > 0 ? "+" : "") +
                        _vm._s(_vm._f("numFormat")(_vm.metric.difference))
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.format === "fixFullDuration"
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.metric.difference > 0 ? "+" : "") +
                        _vm._s(_vm._f("fixFullDuration")(_vm.metric.difference))
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("small", [_vm._v("(" + _vm._s(_vm.metric.percentage) + "%)")]),
              _vm._v(" "),
              _vm.metric.difference > 0
                ? _c("i", { staticClass: "fa fa-caret-up" })
                : _vm._e(),
              _vm._v(" "),
              _vm.metric.difference < 0
                ? _c("i", { staticClass: "fa fa-caret-down" })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.label))]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }