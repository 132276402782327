<template>
    <div>
        <div :class="{ blink: loading }"
             class="placeholder text-center d-flex align-items-center justify-content-center"
             style="height: 250px"
             v-if="!data_loaded || loading">

            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div class="force-scrollbar-view"
             style="height: 250px"
             v-else>
             <highcharts :options="graphOptions"
                        :style="{height: '250px'}"
                        ref="highchart"
                        v-bind:id="graph_id"
                        v-show="is_done && options.series.length > 0">
             </highcharts>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {mapGetters} from 'vuex'

    export default {
        props: {
            chart_data: {
                required: true,
                default: []
            },
            type: {
                required: true,
                default: 'all_calls'
            },
            data_loaded: {
                required: true,
                default: false
            },
            chart_type: {
                required: false,
                default: 'bar'
            },
            title: {
                required: false,
                default: ''
            },
            categories: {
                required: true,
                default: []
            }
        },

        data() {
            return {
                auth: auth,
                is_done: false,
                loading: true,
                axisLabels: [],
                graph_id: this.type.replace(/_/g, ' ') + '_csat_graph',
                options: {
                    chart: {
                        type: this.chart_type,
                    },
                    title: {
                        text: this.title
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 2,
                            borderWidth: 0
                        },
                        series: {
                            cursor: 'pointer',
                            pointWidth: 10,
                            point: {
                                events: {
                                    click: (e) => {
                                        this.pointClick(e)
                                    },
                                }
                            }
                        }
                    },
                    xAxis: {
                        categories: this.categories,
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Number of calls'
                        },
                    },
                    legend: {
                        enabled: true,
                        symbolRadius: 3
                    },
                },
                graph_label: 'All Calls',
            }
        },

        computed: {
            ...mapGetters({
                filter: 'getFilter'
            }),

            graphOptions() {
                return this.options
            },
        },

        mounted() {
            this.generateGraphData()
        },

        methods: {
            pointClick(event) {
                this.$emit('clicked', {
                    user_id: event.point.series.userOptions.user_id,
                    csat_ratio: event.point.series.userOptions.csat_ratio,
                })
                console.log(event.point.series.userOptions.user_id)
            },
            generateGraphData: function () {
                this.loading = true
                this.is_done = false

                this.options.series = []
                let chartSeries  = this.chart_data

                // Show chart when no data available
                if(this.chart_data.length === 0) {
                    this.options.series = [];
                    chartSeries.push({
                        data: [0,0],
                        name: ' - ',
                        csat_ratio: 0,
                        user_id: "754"
                    })
                }

                this.options.series = chartSeries

                this.is_done = true
                this.loading = false
            },
        },

        watch: {
            data_loaded(new_val, old_val) {
                if(new_val && !old_val) {
                    this.generateGraphData()
                }
            },
        },

        destroyed() {
            this.loading = true
            this.is_done = false
        }
    }
</script>
