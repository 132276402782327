<template>
  <div class="textarea-container"
       :class="{ 'with-label': isInfoTextActive }">
      <el-input ref="input"
                type="textarea"
                :autosize="{ minRows: 2 }"
                :placeholder="placeholder"
                :rows="rows"
                :value="modelValue"
                v-loading="isLoading"
                @blur="emitBlurEvent"
                @input="emitUpdateEvent">
      </el-input>
      <label class="textarea-label"
             :style="getLabelStyle()"
             v-if="isInfoTextActive"
             v-html="infoText">
      </label>
  </div>
</template>

<script>
export default {
    name: 'InputWithInfoText',
    
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        infoText: {
            type: String,
            default: ''
        },
        isInfoTextActive: {
            type: Boolean,
            default: false
        },
        rows: {
            type: Number,
            default: 2,
        },
        leftPixels: {
            type: Number,
            default: 6,
        },
        bottomPixels: {
            type: Number,
            default: 2,
        },
        labelSize: {
            type: Number,
            default: 14,
        }
    },

    watch: {
        isInfoTextActive() {
            this.$nextTick(() => {
                this.$refs.input.resizeTextarea()
            })
        }
    },

    methods: {
        emitUpdateEvent(value) {
            this.$emit('update:modelValue', value)
            this.$emit('input', value)
        },
        emitBlurEvent() {
            this.$emit('blur')
        },
        getLabelStyle() {
            return {
                'left': `${this.leftPixels}px`,
                'bottom': `${this.bottomPixels}px`,
                'font-size': `${this.labelSize}px`
            }
        },
    }
}
</script>
