<template>
  <el-form v-if="hasPermissionToListWorkflows"
           ref="pipedrive_extension_enroll_to_sequence"
           :rules="form_rules"
           :model="model"
           class="form-aloware text-left mb-1"
           @submit.native.prevent>
    <!-- Start Workflow/Sequence Selector -->
    <el-form-item class="no-border" prop="workflow_id">
      <div class="form-label">
        <h5>Select Sequence</h5>
        <small>
          Choose the sequence you want to enroll the contacts
        </small>
      </div>
      <app-extension-workflow-selector v-model="model.workflow_id"
                                       :loading="loading"
                                       :multiple="false"
                                       @change="workflowChanged" />
    </el-form-item>
    <!-- End Workflow/Sequence Selector -->
    <!-- Start Help text -->
    <div class="mt-4">
      <ul class="list-unstyled">
        <li class="mb-2">
          <div class="d-flex flex-row">
            <div class="mr-2">
              <i class="fa fa-info-circle text-warning"></i>
            </div>
            <div>
              Please note that only valid workflows are shown in the selection menu
              <ul class="list-unstyled">
                <li><i class="fa fa-check mr-2"></i>Company is active</li>
                <li><i class="fa fa-check mr-2"></i>Line is active</li>
                <li><i class="fa fa-check mr-2"></i>Line has incoming numbers</li>
                <li><i class="fa fa-check mr-2"></i>Sufficient funds</li>
                <li><i class="fa fa-check mr-2"></i>Has Sequence steps</li>
              </ul>
            </div>
          </div>
        </li>
        <li class="mb-2">
          <div class="d-flex flex-row">
            <div class="mr-2">
              <i class="fa fa-info-circle text-warning"></i>
            </div>
            <div>
              Contacts that are already enrolled in a sequence will not be enrolled.
            </div>
          </div>
        </li>
        <li class="mb-2">
          <div class="d-flex flex-row">
            <div class="mr-2">
              <i class="fa fa-info-circle text-warning"></i>
            </div>
            <div>
              Contacts that have a DNC status will not be enrolled.
            </div>
          </div>
        </li>
        <li class="mb-2">
          <div class="d-flex flex-row">
            <div class="mr-2">
              <i class="fa fa-info-circle text-warning"></i>
            </div>
            <div>
              Having thousands of contacts will take time to enroll.
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- End help text -->
  </el-form>
</template>

<script>
import {
  SEND_ON_ANY_DAYS,
  SEND_ON_BIZ_DAYS,
  CURRENT_STATUS_INVALID,
  CURRENT_STATUS_PENDING,
  CURRENT_STATUS_RUNNING
} from '../../../constants/workflow'
import AppExtensionWorkflowSelector from './app-extension-workflow-selector'
import { mapActions } from 'vuex'
import { handleVuexApiCall } from '../../../utils/integration-helpers'
import { form_validation_mixin } from '../../../mixins.js'

export default {
  name: 'pipedrive-enroll-to-sequence',
  props: {
    authUser: {
      type: Object,
      required: true
    }
  },
  components: {
    AppExtensionWorkflowSelector
  },
  mixins: [
    form_validation_mixin
  ],
  data: () => ({
    // UI
    SEND_ON_ANY_DAYS,
    SEND_ON_BIZ_DAYS,
    CURRENT_STATUS_INVALID,
    CURRENT_STATUS_PENDING,
    CURRENT_STATUS_RUNNING,
    saving: false,
    loading: false,

    // Payload
    model: {
      workflow_id: null,
      p_name: null
    },

    // validation rules
    form_rules: {
      workflow_id: [
        {
          required: true,
          message: 'Please select a sequence',
          trigger: 'change'
        }
      ],
      p_name: [
        {
          required: true,
          message: 'This field is required',
          trigger: 'change'
        }
      ]
    }
  }),
  async created() {
    this.loading = true
    await handleVuexApiCall(this.fetchWorkflowsForAppExtension)
    this.loading = false
  },
  methods: {
    ...mapActions(['fetchWorkflowsForAppExtension', 'updateEnrollToSequenceFormInputs']),
    hasPermissionToListWorkflows() {
      if (!this.authUser.user_permissions) return false
      return this.authUser.user_permissions.includes('list workflow')
    },
    workflowChanged(workflow_id) {
      this.validateForm('pipedrive_extension_enroll_to_sequence')
      this.model.workflow_id = workflow_id
    },
    handleUpdateEnrollToSequenceFormInputs() {
      this.updateEnrollToSequenceFormInputs({ workflow_id: this.model.workflow_id })
    },
    runFormValidation() {
      return this.validateForm('pipedrive_extension_enroll_to_sequence')
    }
  }
}
</script>
