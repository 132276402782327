<template>
    <router-link :to="{ name: 'Dashboard' }"
                 tag="li"
                 active-class="active green-gradient rounded"
                 v-if="!isSupervisor">
        <a>
            <span class="nav-icon">
                <img src="/assets/images/app-icons/icon-dashboard-grey.svg">
            </span>
            <span class="nav-icon active">
                <img src="/assets/images/app-icons/icon-dashboard-white.svg">
            </span>
            <span class="nav-text">Dashboard</span>
        </a>
    </router-link>
</template>

<script>
import { acl_mixin } from '../../mixins'

export default {
    mixins: [
        acl_mixin
    ]    
}
</script>
