var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "property-card" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center mb-8",
        },
        [
          _c("h6", { staticClass: "text-dark" }, [_vm._v("Properties (*)")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex gap-2" },
            [
              _vm.propertyLength === 0
                ? _c("el-alert", {
                    attrs: {
                      title:
                        "You need add properties to the function. These properties will be used as variables in the function.",
                      type: "warning",
                      closable: false,
                      "show-icon": "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml-2",
                  attrs: {
                    type: "primary",
                    round: "",
                    plain: "",
                    size: "small",
                    icon: "el-icon-plus",
                    title: "Add property",
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = true
                    },
                  },
                },
                [_vm._v("\n                Property\n            ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.propertyLength > 0
        ? _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.propertyList },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "key", label: "Property", width: "180" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "type", label: "Type", width: "180" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "description", label: "Description" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-end" },
                            [
                              _c("el-button", {
                                attrs: {
                                  plain: "",
                                  circle: "",
                                  icon: "el-icon-edit",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editProperty(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("el-button", {
                                attrs: {
                                  plain: "",
                                  circle: "",
                                  icon: "el-icon-delete",
                                  size: "mini",
                                  type: "danger",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmDelete(scope.row.key)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2660938298
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-blank",
          attrs: {
            visible: _vm.dialogVisible,
            "before-close": _vm.clearValues,
            width: "50%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "container-listing rounded" }, [
            _c("div", { staticClass: "container-header d-flex" }, [
              _vm.isEdit
                ? _c("h4", { staticClass: "listing-heading" }, [
                    _vm._v("Edit property"),
                  ])
                : _c("h4", { staticClass: "listing-heading" }, [
                    _vm._v("Add property"),
                  ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container-body pb-4" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "w-full mr-3" },
                      [
                        _c("div", { staticClass: "form-label" }, [
                          _c("h5", { staticClass: "text-dark" }, [
                            _vm._v("Property key"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.newPropertyKey,
                            callback: function ($$v) {
                              _vm.newPropertyKey = $$v
                            },
                            expression: "newPropertyKey",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c("div", { staticClass: "form-label" }, [
                          _c("h5", { staticClass: "text-dark" }, [
                            _vm._v("Property type"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            attrs: { size: "100%" },
                            model: {
                              value: _vm.newPropertyType,
                              callback: function ($$v) {
                                _vm.newPropertyType = $$v
                              },
                              expression: "newPropertyType",
                            },
                          },
                          _vm._l(
                            ["string", "number", "boolean"],
                            function (type) {
                              return _c("el-option", {
                                key: type,
                                attrs: { label: type, value: type },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c("div", { staticClass: "form-label" }, [
                      _c("h5", { staticClass: "text-dark" }, [
                        _vm._v("Property description"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.newPropertyDescription,
                        callback: function ($$v) {
                          _vm.newPropertyDescription = $$v
                        },
                        expression: "newPropertyDescription",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "d-flex justify-content-end" },
                  [
                    _c("el-button", { on: { click: _vm.clearValues } }, [
                      _vm._v("Cancel"),
                    ]),
                    _vm._v(" "),
                    _vm.isEdit
                      ? _c(
                          "el-button",
                          {
                            staticClass: "mr-2",
                            attrs: { type: "primary" },
                            on: { click: _vm.updateProperty },
                          },
                          [_vm._v("Update")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "mr-2",
                            attrs: { type: "primary" },
                            on: { click: _vm.onAddProperty },
                          },
                          [_vm._v("Add")]
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }