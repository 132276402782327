var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "d-flex flex-row mb-3 py-2",
      },
      [
        _c(
          "div",
          {
            staticClass: "card mr-3",
            staticStyle: {
              "max-height": "18.8rem",
              "min-width": "30rem",
              "overflow-x": "hidden",
              "overflow-y": "auto",
            },
          },
          [
            _c("table", { staticClass: "table table-bordered" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.summary.dispositions, function (disposition) {
                  return _c("tr", [
                    _c("td", [
                      _c(
                        "span",
                        { staticClass: "mr-2" },
                        [
                          true
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    placement: "top-start",
                                    disabled: !_vm.isExternalDisposition(
                                      disposition[0]
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Pulled from CRM\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "i",
                                    {
                                      staticClass: "material-icons",
                                      style: {
                                        color: _vm.getDispositionColor(
                                          disposition[0]
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.isExternalDisposition(
                                              disposition[0]
                                            )
                                              ? "lock"
                                              : "label"
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          false
                            ? _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  style: { color: _vm.scope.row.color },
                                },
                                [_vm._v("label")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm._f("keyLabel")(disposition[0])) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(disposition[1]))]),
                  ])
                }),
                0
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-row justify-content-around" }, [
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c(
              "div",
              {
                staticClass: "card p-2 mr-3 mb-3",
                staticStyle: { height: "max-content", width: "15rem" },
              },
              [
                _c(
                  "div",
                  { staticClass: "card-body text-center d-flex flex-column" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row justify-content-center" },
                      [
                        _c("div", [
                          _c("div", { staticClass: "mb-2" }, [
                            _c("h2", { staticClass: "text-black" }, [
                              _c("span", [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(this.summary.total) +
                                    "\n                                        "
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "break-word" }, [
                            _vm._v("Total Calls"),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "card p-2 mr-3",
                staticStyle: { height: "max-content", width: "15rem" },
              },
              [
                _c(
                  "div",
                  { staticClass: "card-body text-center d-flex flex-column" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row justify-content-center" },
                      [
                        _c("div", [
                          _c("div", { staticClass: "mb-2" }, [
                            _c("h2", { staticClass: "text-black" }, [
                              _c("span", [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(this.summary.outbound_total) +
                                    "\n                                        "
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "break-word" }, [
                            _vm._v("Outbound Calls"),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "card p-2 mr-3",
              staticStyle: { height: "max-content", width: "15rem" },
            },
            [
              _c(
                "div",
                { staticClass: "card-body text-center d-flex flex-column" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row justify-content-center" },
                    [
                      _c("div", [
                        _c("div", { staticClass: "mb-2" }, [
                          _c("h2", { staticClass: "text-black" }, [
                            _c("span", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(this.summary.inbound_total) +
                                  "\n                                    "
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "break-word" }, [
                          _vm._v("Inbound Calls"),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "p-4" }, [
          _c(
            "div",
            { staticClass: "d-flex flex-row justify-content-between" },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column" },
                [
                  _c("label", { staticClass: "break-word" }, [
                    _vm._v("Group By"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.group_by,
                        callback: function ($$v) {
                          _vm.group_by = $$v
                        },
                        expression: "group_by",
                      },
                    },
                    _vm._l(_vm.group_by_list, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: {
                          value: item,
                          label: _vm._f("groupByLabel")(item),
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "success", size: "small" },
                  on: { click: _vm.exportReport },
                },
                [
                  _vm._v(
                    "\n                        Export\n                    "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-2 p-4" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading_table,
                    expression: "loading_table",
                  },
                ],
                staticStyle: { "min-height": "500px" },
              },
              [
                _vm.data.length > 0
                  ? _c(
                      "el-table",
                      {
                        ref: "activity-reporting-2",
                        staticClass: "w-full mt-3 report-dispositions-table",
                        attrs: {
                          "default-sort": {
                            prop: _vm.group_by,
                            order: "descending",
                          },
                          data: _vm.data,
                        },
                      },
                      _vm._l(_vm.dataKeys, function (key) {
                        return _c("el-table-column", {
                          key: key,
                          attrs: {
                            fixed: ["day", "name"].includes(key)
                              ? "left"
                              : false,
                            sortable: "",
                            "sort-method": _vm.sortTable,
                            "min-width": "150",
                            prop: key,
                            label: _vm._f("keyLabel")(key),
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.length === 0
                  ? _c(
                      "div",
                      { staticClass: "p-2 text-center" },
                      [
                        _c("img-empty"),
                        _vm._v(" "),
                        _c("p", { staticClass: "lead" }, [
                          _vm._v("No data found from the selected date range"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _vm.pagination.total > _vm.pagination.per_page
                  ? _c("el-pagination", {
                      staticClass: "pull-right",
                      attrs: {
                        layout: "sizes, prev, pager, next",
                        background: "",
                        total: _vm.pagination.total,
                        "page-size": _vm.pagination.per_page,
                        "page-sizes": [20, 35, 50, 100, 250],
                      },
                      on: {
                        "next-click": _vm.nextPage,
                        "prev-click": _vm.prevPage,
                        "current-change": _vm.changePage,
                        "size-change": _vm.paginationChangeSize,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "break-word" }, [
          _vm._v("Call Disposition Status"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "break-word" }, [_vm._v("Total")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }