<template>
    <div class="row nav-padding">
        <label class="ml-2 help-text">
            Scripts can be displayed to reps and agents while they
            are on a call with a customer.
        </label>
        <!-- Line Scripts -->
        <div class="col-12">
            <el-table :data="paginatedData"
                      :default-sort="{prop: 'id', order: 'ascending'}"
                      class="w-full"
                      fit
                      stripe>
                <el-table-column
                    prop="id"
                    label="ID"
                    width="60">
                    <template v-slot="scope">
                        <span class="text-greyish">{{ scope.row.id }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="title"
                                 label="Title"
                                 width="150">
                </el-table-column>
                <el-table-column label="Text"
                                 min-width="300">
                    <template v-slot="scope">
                        <span v-html="$options.filters.fixHtml($options.filters.truncate(scope.row.text, 100))"></span>
                    </template>
                </el-table-column>
                <el-table-column
                    width="150"
                    align="right">
                    <template v-slot:header>
                        <script-dialog/>
                    </template>
                    <template v-slot="scope">
                        <div class="row mt-2"
                             v-if="hasPermissionTo('update script') && !scope.row.deleted_at">
                            <div class="col-12">
                                <script-dialog :script_id="scope.row.id"/>
                            </div>
                        </div>
                        <div class="row mt-2"
                             v-if="hasPermissionTo('restore script') && scope.row.deleted_at">
                            <div class="col-12">
                                <button class="btn btn-sm btn-block warning"
                                        @click.prevent="restoreScript(scope.row)">
                                    <i v-if="loading_restore_script"
                                       class="fa fa-spin fa-spinner"></i>
                                    <i v-else
                                       class="fa fa-history"></i>
                                    Restore
                                </button>
                            </div>
                        </div>
                        <div class="row mt-2"
                             v-if="hasPermissionTo('archive script') && !scope.row.deleted_at">
                            <div class="col-12">
                                <button class="btn btn-sm btn-block danger"
                                        @click.prevent="archiveScript(scope.row)">
                                    <i v-if="loading_archive_script"
                                       class="fa fa-spin fa-spinner"></i>
                                    <i v-else
                                       class="fa fa-trash"></i>
                                    Archive
                                </button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="mt-3"
                 v-if="pageCount >= 1">
                <el-pagination class="pull-right"
                               background
                               layout="sizes, prev, pager, next"
                               :page-sizes="[10, 20, 50, 100, 250]"
                               :page-size="20"
                               :current-page.sync="pagination.current_page"
                               :total="dataCount"
                               @current-change="changePage"
                               @size-change="handleSizeChange">
                </el-pagination>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapState} from 'vuex'
import {acl_mixin, paginator_mixin, scroll_mixin} from '../mixins'
import ScriptDialog from "../components/script-dialog"

export default {
    mixins: [scroll_mixin, paginator_mixin, acl_mixin],

    components: {
        ScriptDialog
    },

    data() {
        return {
            auth: auth,
            dataKey: 'filteredScripts',
            scripts_pagination: {
                current_page: 1,
                per_page: 20
            },
            loading_archive_script: false,
            loading_restore_script: false
        }
    },

    computed: {
        ...mapState({scripts: state => state.scripts}),

        filteredScripts() {
            return this.scripts
        },
    },

    methods: {
        archiveScript(script) {
            this.loading_archive_script = true
            axios.delete('/api/v1/script/' + script.id).then(res => {
                this.loading_archive_script = false
                this.$notify({
                    offset: 95,
                    title: 'Script',
                    message: 'Script has been archived.',
                    type: 'success',
                    showClose: true
                })
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_archive_script = false
            })
        },

        restoreScript(script) {
            this.loading_restore_script = true
            axios.post('/api/v1/script/' + script.id + '/restore').then(res => {
                this.loading_restore_script = false
                this.$notify({
                    offset: 95,
                    title: 'Line',
                    message: 'Line has been restored.',
                    type: 'success',
                    showClose: true
                })
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_restore_script = false
            })
        },
    },
}
</script>
