var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row b-b" }, [
      _c("div", { staticClass: "col-12 p-3" }, [_c("company-form-token")], 1),
    ]),
    _vm._v(" "),
    !_vm.statics.whitelabel
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 p-3" }, [
            _c(
              "a",
              {
                staticClass: "help",
                attrs: {
                  href: "https://support.aloware.com/en/articles/9020058-aloware-lead-api-documentation",
                  title: "help",
                  target: "_blank",
                },
              },
              [
                _c(
                  "el-button",
                  { staticClass: "p-0", attrs: { type: "text" } },
                  [
                    _c("i", { staticClass: "material-icons text-lg mr-1" }, [
                      _vm._v("help"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-md" }, [
                      _vm._v("Read our tutorial"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 p-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("p", [
          _c("strong", [_vm._v("Example POST URL:")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "text-dark-greenish _600" }, [
            _vm._v(
              _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                "/api/v1/webhook/forms"
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c(
          "pre",
          {
            directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
            key: _vm.renderRefreshToken + "_la_a",
          },
          [
            _c("code", { staticClass: "json" }, [
              _vm._v("{\n    "),
              _c("span", { staticClass: "text-dark-greenish _600" }, [
                _vm._v("// Authentication Token"),
              ]),
              _vm._v(
                '\n    "api_token": "' +
                  _vm._s(_vm.companyToken) +
                  '", // Required\n    '
              ),
              _c("span", { staticClass: "text-dark-greenish _600" }, [
                _vm._v("// Lead Properties"),
              ]),
              _vm._v(
                '\n    "phone_number": "8181234567", // Required\n    "other_phone_numbers": [{"label":"mobile", "phone_number": "8181234567"}, ...], // Optional | Array of objects/arrays that includes the following keys; phone_number (required), label (optional)\n    "company_name": "' +
                  _vm._s(_vm.statics.name) +
                  '", // Optional\n    "name": "John Doe", // Optional\n    "first_name": "John", // Optional\n    "last_name": "Doe", // Optional\n    "lead_source": "Google Ads", // Optional\n    "email": "john.doe@gmail.com", // Optional\n    "date_of_birth": "MM/DD/YYYY", // Optional\n    "timezone": "America/Los_Angeles", // Optional\n    "city": "Los Angeles", // Optional\n    "state": "CA", // Optional\n    "zipcode": "90045", // Optional\n    "country": "US", // Optional\n    "address": "1234 First Street", // Optional\n    "website": "https://www.aloware.com", // Optional\n    "notes": "This is a test note", // Optional\n    "csf1": "Custom data", // Optional | (Custom Field 1)\n    "csf2": "Custom data", // Optional | (Custom Field 2)\n    '
              ),
              _c("span", { staticClass: "text-dark-greenish _600" }, [
                _vm._v(
                  "// Ownership. Please use at once only one field user_id or user_email."
                ),
              ]),
              _vm._v(
                '\n    "user_id": "1234", // Optional | Set an owner for this lead. If left blank, the contact will not be owned by any user.\n    "user_email": "user@example.com", // Optional | Set an owner for this lead by his email. If left blank, the contact will not be owned by any user.\n    '
              ),
              _c("span", { staticClass: "text-dark-greenish _600" }, [
                _vm._v("// Distribution"),
              ]),
              _vm._v(
                '\n    "distribute_to_ring_group": true, // Optional | Should we distribute the contact to a ring group? If true, we will randomly assign it to an agent/user within a ring group and override `user_id` in ownership.\n    "ring_group_id": "5", // Optional | Will be checked if `distribute_to_ring_group` is TRUE.\n    "check_available_users": true, // Optional | Will be checked if `distribute_to_ring_group` is TRUE. | Should we check if user is eligible or active? if value is true and no active user found, contact will not be distributed within a ring group.\n    "check_available_users_with_fallback": true, // Optional | Will be checked if `distribute_to_ring_group` is TRUE. | Should we check if user is eligible or active? if value is true and no active user found, contact will be distributed within the ring group. Active users will be prioritized using this property.\n    '
              ),
              _c("span", { staticClass: "text-dark-greenish _600" }, [
                _vm._v("// PowerDialer "),
              ]),
              _vm._v(
                '\n    "add_to_powerdialer": true, // Optional | Should we add the contact to the PowerDialer of agent/user where it is distributed? If the lead is already in someone’s PowerDialer, it will be skipped.\n    "powerdialer_position": "top", // Optional | Options: top, bottom | Default: top | Will be checked if `add_to_powerdialer` is TRUE. | Combined with the option above, should this lead go to the top of the PowerDialer or bottom?\n    '
              ),
              _c("span", { staticClass: "text-dark-greenish _600" }, [
                _vm._v("// Sequences"),
              ]),
              _vm._v(
                '\n    "sequence_id": "4567", // Optional | Sequences\n    "force_update_sequence": false, // Optional | Should we disenroll this lead from other sequences, and push them to a new one?\n    '
              ),
              _c("span", { staticClass: "text-dark-greenish _600" }, [
                _vm._v("// Action Flags"),
              ]),
              _vm._v(
                '\n    "force_update": true, // Optional | Should we override existing data and update the contact information?\n    '
              ),
              _c("span", { staticClass: "text-dark-greenish _600" }, [
                _vm._v("// Relationship Fields"),
              ]),
              _vm._v(
                '\n    "line_id": "1234", // Optional | Line\n    "tag_id": "1234", // Optional | Tag\n    "disposition_status_id": "1234", // Optional | Disposition Status\n}'
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c(
          "pre",
          { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
          [
            _c("code", { staticClass: "json" }, [
              _vm._v(
                '{\n    "Accept": "application/json",\n    "Content-Type": "application/json"\n}'
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _c(
          "pre",
          { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
          [
            _c("code", { staticClass: "json" }, [
              _vm._v('{\n    "message": "Contact created."\n}'),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        _c(
          "pre",
          { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
          [
            _c("code", { staticClass: "json" }, [
              _vm._v('{\n    "message": "Contact updated."\n}'),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _c(
          "pre",
          { directives: [{ name: "highlightjs", rawName: "v-highlightjs" }] },
          [
            _c("code", { staticClass: "json" }, [
              _vm._v(
                '{\n    "message": "The given data was invalid.",\n    "errors": {\n        "phone_number": [\n            "The phone number has already been taken."\n        ]\n    }\n}'
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(8),
        _vm._v(" "),
        !_vm.loading_whitelabel
          ? _c(
              "pre",
              {
                directives: [{ name: "highlightjs", rawName: "v-highlightjs" }],
                key: _vm.renderRefreshToken + "_la_b",
                staticClass: "mb-3",
              },
              [
                _c("code", { staticClass: "bash" }, [
                  _vm._v(
                    "curl --location --request POST '" +
                      _vm._s(_vm._f("fixDomain")(_vm.statics.domain)) +
                      "/api/v1/webhook/forms' \\\n--header 'Content-Type: application/json' \\\n--header 'Accept: application/json' \\\n--data-raw '{\n    \"api_token\": \"" +
                      _vm._s(_vm.companyToken) +
                      '",\n    "phone_number": "8181234567",\n    "company_name": "Aloware",\n    "name": "John Doe",\n    "first_name": "John",\n    "last_name": "Doe",\n    "lead_source": "Google Ads",\n    "email": "john.doe@gmail.com",\n    "date_of_birth": "MM/DD/YYYY",\n    "timezone": "America/Los_Angeles",\n    "city": "Los Angeles",\n    "state": "CA",\n    "zipcode": "90045",\n    "country": "US",\n    "address": "1234 First Street",\n    "website": "https://www.aloware.com",\n    "notes": "This is a test note",\n    "csf1": "Custom data",\n    "csf2": "Custom data",\n    "force_update": true,\n    "force_update_sequence": false,\n    "distribute_to_ring_group": true,\n    "ring_group_id": "1234",\n    "check_available_users": true,\n    "add_to_powerdialer": true,\n    "powerdialer_position": "bottom",\n    "line_id": "1234",\n    "sequence_id": "4567",\n    "user_id": "1234",\n    "tag_id": "1234",\n    "disposition_status_id": "1234"\n}\''
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n                    If you are looking for a "),
          _c("span", { staticClass: "_600" }, [_vm._v("Postman")]),
          _vm._v(" example please check this link:\n                    "),
          _c("br"),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "el-button--text",
              attrs: {
                href:
                  _vm.statics.domain +
                  "/static-data/lead-api.postman_collection.json",
                target: "_blank",
              },
            },
            [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.statics.domain) +
                  "/static-data/lead-api.postman_collection.json\n                    "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        !_vm.statics.whitelabel
          ? _c("p", { staticClass: "_600" }, [
              _vm._v(
                "\n                    If you need more API functions, please contact our support at support@aloware.com.\n                "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2" }, [
      _c("strong", { staticClass: "integration-api-titles" }, [
        _vm._v(
          "Create a new contact or Update an existing contact (Lead API / Form Capture API)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-md" }, [
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("POST /api/v1/webhook/forms"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Required Fields:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("api_token"),
      ]),
      _vm._v(",\n                    "),
      _c("span", { staticClass: "text-dark-greenish _600" }, [
        _vm._v("phone_number"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Example POST body:")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-muted" }, [
        _vm._v(
          "\n                                            The code sample below represents some example JSON with\n                                            standard fields to pass in the body of your request in\n                                            order to create a new contact:\n                                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("Example POST header:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 201 response on contact creation, Example\n                        success\n                        response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 200 response on contact update if force_update\n                        is true, Example success\n                        response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [
        _vm._v(
          "Returns an HTTP 400 on failure with a detailed response, Example\n                        failure\n                        response:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-2" }, [
      _c("strong", [_vm._v("cURL Example:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }