var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "card summary-card-hover h-100",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card-body pt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col text-center countup-hover unclickable" },
            [
              _c("h4", { staticClass: "text-black metric-value" }, [
                _vm._v(
                  _vm._s(_vm._f("numFormat")(_vm.broadcast_summary.count))
                ),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "text-muted metric-label" }, [
                _vm._v("Sent"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col text-center countup-hover unclickable" },
            [
              _c("h4", { staticClass: "text-black metric-value" }, [
                _vm._v(
                  _vm._s(_vm._f("numFormat")(_vm.broadcast_summary.recipients))
                ),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "text-muted metric-label" }, [
                _vm._v("Total Recipients"),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-3" }, [
      _c("img", {
        staticClass: "w-24",
        attrs: {
          "data-testid": "broadcast-summary-img",
          src: "/assets/images/app-icons/new/broadcast.png",
        },
      }),
      _vm._v(" "),
      _c("strong", { staticClass: "text-md title pl-2" }, [
        _vm._v("Broadcast"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }