<template>
    <el-dialog
        width="40vw"
        top="4vh"
        :visible="modal_visible"
        :append-to-body="true"
        :close-on-click-modal="true"
        :before-close="handleClose">

        <div slot="title">
            <h5 class="mb-0">
                <span v-if="mode === MODE_ADD">Add New</span>
                <span v-if="mode === MODE_EDIT">Edit</span>
                Service
            </h5>
        </div>

        <el-form
            class="form-aloware"
            ref="service_form">

            <el-form-item
                class="no-border"
                :error="errors.first('name')"
                prop="name">
                <div class="form-label">
                    <h5>Name</h5>
                </div>
                <el-input
                    v-model="service.name"
                    placeholder="Name of service">
                </el-input>
            </el-form-item>

            <el-form-item class="no-border">
                <div class="form-label">
                    <h5>Duration (Minutes)</h5>
                </div>
                <el-input
                    v-model="service.duration"
                    placeholder="15"
                    type="number">
                </el-input>
            </el-form-item>

            <el-form-item class="no-border">
                <div class="form-label">
                    <h5>Description</h5>
                    <small>Describe what this service is for.</small>
                </div>
                <el-input
                    v-model="service.description"
                    type="textarea"
                    :rows="2">
                </el-input>
            </el-form-item>

            <el-form-item class="no-border">
                <div class="form-label">
                    <h5>Users</h5>
                </div>
                <el-select
                    placeholder="Add one or more users"
                    style="display: block!important;"
                    multiple
                    filterable
                    default-first-option
                    v-model="service.users"
                    :disabled="loading"
                    :filter-method="filterOptions"
                    @visible-change="resetFilteredText">

                    <el-option-group
                        key="Users"
                        label="Users"
                        v-if="normalUsers && normalUsers.length > 0">
                        <el-option
                            v-for="user in normalUsers"
                            class="p-0"
                            :key="user.id"
                            :label="user.name"
                            :value="user.id">
                            <div class="media">
                                <div class="media-body">
                                    <label>{{ user.name }}</label>
                                </div>
                            </div>
                        </el-option>
                    </el-option-group>

                </el-select>
            </el-form-item>


            <div class="d-flex mt-4">
                <el-button @click="handleClose">Close</el-button>
                <el-button
                    type="success"
                    class="ml-auto"
                    @click="handleSave">
                    <strong>Save</strong>
                </el-button>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
import {mapState} from 'vuex'
import ErrorBag from "../../../ErrorBag"
import {acl_mixin, user_info_mixin} from "../../../mixins"

export default {
    name: "calendar-services-modal",

    mixins: [
        acl_mixin,
        user_info_mixin
    ],

    data() {
        return {
            modal_visible: false,
            MODE_ADD: 1,
            MODE_EDIT: 2,
            loading: false,
            mode: this.MODE_ADD,
            list: {},
            service: {},
            filtered_text: null,
            errors: new ErrorBag({})
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        filteredUsers() {
            if (this.users) {
                let filtered_users = this.users.filter((user) =>
                    !(user.role_names && user.role_names.length === 1 && user.read_only_access) && user.enabled
                )

                if (this.filtered_text) {
                    return filtered_users.filter((user) =>
                        (user.name && user.name.toLowerCase().includes(this.filtered_text.toLowerCase())) ||
                        (user.phone_number && user.phone_number.includes(this.filtered_text)) ||
                        (user.email && user.email.toLowerCase().includes(this.filtered_text.toLowerCase()))
                    )
                }

                return filtered_users
            }

            return []
        },

        normalUsers() {
            return this.filteredUsers.filter((user) => {
                return user.active
            })
        }
    },

    created() {
        this.$emit('callback')
    },

    methods: {

        add() {
            this.modal_visible = true
            this.mode = this.MODE_ADD
        },

        edit(calendar_service) {
            this.modal_visible = true
            this.mode = this.MODE_EDIT
            this.service = calendar_service
        },

        handleClose() {
            this.modal_visible = false
            this.service = {}
        },

        handleSave() {
            if (this.mode === this.MODE_ADD) {
                this.create()
            } else if (this.mode === this.MODE_EDIT) {
                this.update()
            }
        },

        create() {
            axios.post('/api/v1/calendar/service', this.service).then(res => {
                this.$notify({
                    offset: 95,
                    title: 'Calendar Service',
                    message: 'Calendar service has been created successfully.',
                    type: 'success',
                    showClose: true,
                })
                this.handleClose()

                this.$emit('callback')
            }).catch(err => {
                if (err.response.status === 422) {
                    this.errors = new ErrorBag(err.response.data.errors)
                }
            })
        },

        update() {
            axios.put(`/api/v1/calendar/service/${this.service.id}`, this.service).then(res => {
                this.$notify({
                    offset: 95,
                    title: 'Calendar Service',
                    message: 'Calendar service has been updated successfully.',
                    type: 'success',
                    showClose: true,
                })
                this.handleClose()

                this.$emit('callback')
            }).catch(err => {
                if (err.response.status === 422) {
                    this.errors = new ErrorBag(err.response.data.errors)
                }
            })
        },

        filterOptions(filterValue) {
            this.filtered_text = filterValue
        },

        resetFilteredText() {
            this.filtered_text = null
        }
    }
}
</script>
