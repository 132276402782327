<template>
    <div>
        <div class="vertical-center text-left mb-4">
			<span v-if="filter.from_date"
                  class="call-log-head">
				{{ title_prefix }}Contacts from
                <strong>{{ filter.from_date | fixDateTimeFull }}</strong>
                to
                <strong>{{ filter.to_date | fixDateTimeFull }}</strong>
			</span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
                {{ title_prefix }}Contacts
                <strong>All Time</strong>
            </span>
        </div>
        <div v-if="loading"
             :class="{ blink: loading }"
             class="placeholder text-center d-flex align-items-center justify-content-center"
             style="height: 450px">
            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div style="height: 450px">
            <template v-if="report_type == 'new_contacts_vs_dnc'">
                <highcharts v-show="is_done && options.series.length > 0"
                            v-bind:id="graph_id"
                            ref="highchart"
                            :options="options"
                            style="height: 450px">
                </highcharts>
            </template>
            <template v-else>
                <graph-data-switch :report_type="report_type"
                                   :series="options.series"
                                   :type="type">
                    <highcharts v-show="is_done && options.series.length > 0"
                                v-bind:id="graph_id"
                                ref="highchart"
                                :options="options"
                                style="height: 450px">
                    </highcharts>
                    <div v-show="is_done && options.series.length == 0"
                         class="el-table__empty-block">
                        <span class="el-table__empty-text"
                              style="color: #606266;">
                            No Data
                        </span>
                    </div>
                </graph-data-switch>
            </template>
        </div>
        <div v-if="report_type == 'new_contacts_vs_dnc'"
             class="mt-2">
            <div class="col-12">
                <el-table ref="new-contacts-vs-dnc-table"
                          v-loading="!is_done"
                          :data="sortedPaginatedData"
                          class="w-full mt-3 activity-reporting-table"
                          fit
                          stripe
                          @sort-change="sortChange">
                    <el-table-column fixed="left"
                                     label="Date"
                                     prop="date"
                                     sortable
                                     width="auto">
                        <template v-slot="scope">
                            {{ scope.row.date }}
                        </template>
                    </el-table-column>
                    <el-table-column label="New Contacts"
                                     prop="new_contacts"
                                     sortable
                                     width="auto">
                        <template v-slot="scope">
                            {{ scope.row.new_contacts }}
                        </template>
                    </el-table-column>
                    <el-table-column label="DNC Contacts"
                                     prop="dnc_contacts"
                                     sortable
                                     width="auto">
                        <template v-slot="scope">
                            {{ scope.row.dnc_contacts }}
                        </template>
                    </el-table-column>
                </el-table>
                <div class="mt-3 d-inline-block w-100 pb-2">
                    <el-pagination :page-size="per_page"
                                   :page-sizes="[10, 20, 50, 100, 250]"
                                   :total="total"
                                   background
                                   class="pull-right"
                                   layout="sizes, prev, pager, next"
                                   @current-change="changePage"
                                   @size-change="handleSizeChange">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {date_mixin, graph_mixin, report_mixin, custom_highcharts_mixin} from '../mixins'
import auth from '../auth'

export default {
    props: {
        report_index: {
            default: 0
        },
        report_type_override: {
            default: 'leads_created_vs_dnc'
        },
        title_prefix: {
            default: ''
        },
        report_data: {
            required: false,
            default: {}
        },
        type: {}
    },

    mixins: [date_mixin, report_mixin, graph_mixin, custom_highcharts_mixin],

    data() {
        return {
            auth: auth,
            loading: false,
            aggregated_counts: [],
            graph_id: 'leads_created_vs_dnc',
            report_type: this.report_type_override,
            options: {
                chart: {
                    events: {
                        load: (event) => {
                            // when is chart ready
                            this.updateChartYAxis(event.target)
                        }
                    }
                },

                plotOptions: {
                    spline: {
                        marker: {
                            enabled: true,
                            radius: 3
                        }
                    },
                    series: {
                        connectNulls: true,
                        events: {
                            legendItemClick: function (e) {
                                // Upon cmd-click of a legend item, rather than toggling visibility, we want to hide all other items.
                                let hideAllOthers = e.browserEvent.metaKey
                                if (hideAllOthers) {
                                    if (!this.visible) {
                                        return true
                                    }

                                    let seriesIndex = this.index
                                    let series = this.chart.series

                                    for (let i = 0; i < series.length; i++) {
                                        // rather than calling 'show()' and 'hide()' on the series', we use setVisible and then
                                        // call chart.redraw --- this is significantly faster since it involves fewer chart redraws
                                        if (series[i].index !== seriesIndex) {
                                            if (series[i].visible) {
                                                series[i].setVisible(false, false)
                                            } else {
                                                series[i].setVisible(true, false)
                                            }
                                        }
                                    }
                                    this.chart.redraw()
                                    return false
                                }
                            }
                        }
                    }
                },

                time: {
                    useUTC: false,
                    timezone: window.timezone
                },

                // force the plot to show all ticks daily
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%e %b', // always use day as highest resolution.
                        second: '%e %b', // always use day as highest resolution.
                        minute: '%e %b', // always use day as highest resolution.
                        hour: '%H:%M',
                        day: '%e %b', // always use day as highest resolution.
                        week: '%e %b', // always use day as highest resolution.
                        month: '%b \'%y',
                        year: '%Y'
                    },
                    // minRange: 1 * 24 * 3600000, // 1 day
                    labels: {
                        rotation: 45,
                        // step: 1,
                        style: {
                            fontSize: '14px'
                        }
                    }
                },

                yAxis: {
                    type: 'logarithmic',
                    custom: {
                        allowNegativeLog: true
                    },
                    tickInterval: 1,
                    allowDecimals: false,
                    title: {
                        text: "Number of Contacts",
                        style: {
                            'font-size': '14px',
                            'color': '#090A0D'
                        }
                    }
                },

                tooltip: {
                    useHTML: true,
                    shared: true,
                    crosshairs: true,
                    style: {
                        color: '#2e3e4e',
                        fontSize: '14px'
                    },
                    formatter: function () {
                        let s = []
                        try {
                            s.push(`<strong class="mb-0">Number Of Contacts</strong>`)
                            s.push(`<span class="text-grey-900 mt-0 mb-3" style="font-size: 0.65rem">${moment(this.x).format('dddd, MMMM D YYYY').toString()}</span>`)
                            for (let point of this.points.filter((a) => a.y !== 0).sort((a, b) => b.y - a.y)) {
                                let point_name = point.series.name.toString();
                                point_name = point_name.length > 0 && point_name[(point_name.length - 1)].toLowerCase() == 's' && point.y <= 1 ? point_name.substring(0, (point_name.length - 1)) : point_name
                                s.push(`<i class="fa fa-circle" style="color: ${point.series.color};"></i><span class="text-grey-900" style="font-size: 0.875rem"> ${point_name}: <b>${point.y.toLocaleString()}</b></span>`)
                            }
                            s = s.join("</br>")
                        } catch (err) {
                            // handle the error
                        }
                        return s
                    }
                },

                legend: {
                    layout: "horizontal",
                    enabled: true,
                    verticalAlign: "bottom",
                    floating: false
                },

                credits: {
                    enabled: false
                },

                exporting: {
                    sourceWidth: 0,
                    sourceHeight: 0
                },

                series: []
            },
            dataSort: 'date',
            dataSortType: 'ASC',
            per_page: 20,
            current_page: 1,
            full_series_data: []
        }
    },

    computed: {
        highchartSeries() {
            if (this.full_series_data.length == 0) {
                return []
            }
            let data = []
            let first_column_name = 'Date'
            let first_column_variable_name = this.toVariableName(first_column_name)
            let series_variable_name_first_row = ''
            let series_variable_name_second_row = ''

            // first, we create the data for the first series
            let series_raw = this.full_series_data[0]
            for (let column in series_raw.data) {
                let reduced_row = {}
                series_variable_name_first_row = this.toVariableName(series_raw.name)
                reduced_row[first_column_variable_name] = this.$options.filters.fixFullDateLocal(series_raw.data[column][0])
                reduced_row[series_variable_name_first_row] = series_raw.data[column][1]
                data.push(reduced_row)
            }

            // then, we check for existing dates in the data
            // and add the second series' counts if date is found
            // if not found, create a new data for the second series
            for (let column in this.full_series_data[1].data) {
                let found = false
                series_variable_name_first_row = this.toVariableName(this.full_series_data[0].name)
                series_variable_name_second_row = this.toVariableName(this.full_series_data[1].name)
                for (let index in data) {
                    if (this.$options.filters.fixFullDateLocal(this.full_series_data[1].data[column][0]) === data[index][first_column_variable_name]) {
                        series_variable_name_second_row = this.toVariableName(this.full_series_data[1].name)
                        data[index][series_variable_name_second_row] = this.full_series_data[1].data[column][1]
                        found = true
                        break
                    }
                }
                if (!found) {
                    let reduced_row = {}
                    reduced_row[first_column_name] = this.$options.filters.fixFullDateLocal(this.full_series_data[1].data[column][0])
                    reduced_row[series_variable_name_first_row] = 0
                    reduced_row[series_variable_name_second_row] = this.full_series_data[1].data[column][1]
                    data.push(reduced_row)
                }
            }
            // we then check if there are missing series, just add 0 count
            for (let index in data) {
                series_variable_name_second_row = this.toVariableName(this.full_series_data[1].name)
                if (!data[index][series_variable_name_second_row]) {
                    data[index][series_variable_name_second_row] = 0
                }
            }
            // lastly, we sort the data
            data.sort((a, b) => moment(a[first_column_variable_name]).valueOf() - moment(b[first_column_variable_name]).valueOf())
            return data
        },

        sortedPaginatedData() {
            if (this.highchartSeries.length) {
                let page_number = this.current_page - 1
                if (this.dataSort) {
                    if (this.dataSortType) {
                        switch (this.dataSortType) {
                            case 'ASC':
                                return this.highchartSeries.sort((a, b) => parseFloat(a[this.dataSort]) - parseFloat(b[this.dataSort])).slice(page_number * this.per_page, (page_number + 1) * this.per_page)
                            case 'DESC':
                                return this.highchartSeries.sort((a, b) => parseFloat(b[this.dataSort]) - parseFloat(a[this.dataSort])).slice(page_number * this.per_page, (page_number + 1) * this.per_page)
                            default:
                                return this.highchartSeries.slice(page_number * this.per_page, (page_number + 1) * this.per_page)
                        }
                    }
                }
                return this.highchartSeries.slice(page_number * this.per_page, (page_number + 1) * this.per_page)
            }

            return []
        },

        total() {
            return this.highchartSeries.length
        }
    },

    created() {
        VueEvent.listen('copy_overall_baseline' + this.$vnode.key, (data) => {
            this.copy()
        })
    },

    mounted() {
        this.filter.report_type = !this.report_type ? this.filter.report_type : this.report_type
        if (_.isEmpty(this.report_data)) {
            this.getLeadsIntakeReport().then(() => {
                this.$emit('reportLoaded', this.report_index)
            })
        } else {
            this.aggregated_counts = this.report_data

            this.generateGraphData(false)

            this.loading = false
            this.is_done = true
            this.$nextTick(() => {
                let highcharts_container = document.getElementById(this.graph_id)

                if (highcharts_container) {
                    this.options.exporting.sourceWidth = highcharts_container.clientWidth
                    this.options.exporting.sourceHeight = highcharts_container.clientHeight
                }
            })
            this.$emit('reportLoaded', this.report_index)
        }
    },

    methods: {
        generateGraphData(should_emit = true) {
            if (should_emit) {
                this.$emit('reportData', this.aggregated_counts)
            }

            let labels = {
                created_counts: 'Created Contacts',
                dnc_counts: "DNC Contacts",
                new_contacts: 'New Contacts',
                dnc_contacts: 'DNC Contacts'
            }
            let colors = {
                'created_counts': null,
                'dnc_counts': '#C4183C',
                'new_contacts': null,
                'dnc_contacts': '#C4183C',
            }
            let metric_types = [
                'dnc_contacts', 'new_contacts'
            ]

            let min_overall, max_overall
            let all_series = []

            for (let aggregate_key in this.aggregated_counts) {
                if (aggregate_key.includes('data')) {
                    continue
                }
                if (this.aggregated_counts[aggregate_key].length === 0) {
                    console.log("Empty aggregate data")
                }
                // loop over what you got and setup the plots.
                let keys = Object.keys(this.aggregated_counts[aggregate_key])

                // prepare min & max of time range
                let min, max
                // create a base series based on this with all ticks available.
                let base_series = []
                // arrange this in hash table with timezone in mind.
                let base_data = {}

                for (let index in keys) {
                    // get the data from API response.
                    let series_data = this.aggregated_counts[aggregate_key][keys[index]]

                    let ts = series_data.timestamp // seconds
                    // removes time from datetime this will fix timezone issues
                    let date = parseInt(this.localizedMoment(ts, 'YYYY-MM-DD HH:mm:ss').startOf('day').format('x') / 1000)
                    // push to hash table
                    if (base_data[date]) {
                        base_data[date] = base_data[date] + series_data.counts
                    } else {
                        base_data[date] = series_data.counts
                    }

                    if (min == undefined || min > date) {
                        min = date
                    }
                    if (max == undefined || max < date) {
                        max = date
                    }

                    if (min_overall > min || !min_overall) {
                        min_overall = min
                    }

                    if (max_overall < max || !max_overall) {
                        max_overall = max
                    }
                }

                // convert min, max to moment objects
                min = moment.unix(min)
                max = moment.unix(max)

                // when period is daily
                if (this.filter.chart_period == "day") {
                    // force the plot to show all ticks
                    // this.options.xAxis.tickInterval = 24 * 3600 * 1000 // 1 day
                    // this.options.xAxis.labels.step = 1 // 1 day
                    this.options.xAxis.tickInterval = undefined // let the plot decide
                    this.options.xAxis.labels.step = undefined // let the plot decide
                    if (this.filter.from_date) {
                        min = moment(this.filter.from_date).startOf('day') // moment
                    }
                    if (this.filter.to_date) {
                        max = moment(this.filter.to_date).startOf('day') // moment object
                    }
                }
                // week
                if (this.filter.chart_period == "week") {
                    // force the plot to show all ticks
                    this.options.xAxis.tickInterval = undefined // let the plot decide
                    this.options.xAxis.labels.step = undefined // let the plot decide
                    // set the plot to use definitive time-ticks based on period selected
                    if (this.filter.from_date) {
                        min = moment(this.filter.from_date).startOf('isoWeek').startOf('day') // moment object
                    }
                    if (this.filter.to_date) {
                        max = moment(this.filter.to_date).startOf('isoWeek').startOf('day') // moment object
                    }
                    // ^^ we use isoWeek because we want it to start on Monday
                }
                // month to month handling
                if (this.filter.chart_period == "month") {
                    // force the plot to show all ticks
                    this.options.xAxis.tickInterval = undefined // let the plot decide
                    this.options.xAxis.labels.step = undefined // let the plot decide
                    // set the plot to use definitive time-ticks based on period selected
                    if (this.filter.from_date) {
                        min = moment(this.filter.from_date).startOf('month').startOf('day') // moment object
                    }
                    if (this.filter.to_date) {
                        max = moment(this.filter.to_date).startOf('month').startOf('day') // moment object
                    }
                }

                // print each day
                while (min <= max) {
                    let base_point = []
                    let min_unix_timestamp = min.format('X') // seconds
                    // is there a match in our data?
                    if (base_data[min_unix_timestamp]) {
                        base_point = [min_unix_timestamp * 1000, base_data[min_unix_timestamp]]
                    } else {
                        base_point = [min_unix_timestamp * 1000, 0]
                    }
                    base_series.push(base_point)
                    min = min.add(1, this.filter.chart_period)
                }

                let point_start = this.getPointStart(base_series)

                // form the series
                let series = {
                    // type can be line, column, spline
                    type: 'spline',
                    name: labels[aggregate_key],
                    data: base_series,
                    pointStart: point_start,
                    showInLegend: (Object.keys(base_series).length > 0) ? true : false
                }
                if (colors[aggregate_key]) {
                    series.color = colors[aggregate_key]
                }

                all_series.push(series)
            }

            this.full_series_data = _.cloneDeep(all_series)
            if (this.type != 'overview') {
                let metric_index = metric_types.indexOf(this.type)
                if (typeof all_series[metric_index] !== 'undefined') {
                    delete all_series[metric_index]
                    all_series = _.compact(all_series)
                }
            }
            // have high charts re-draw this.
            this.$set(this.options, 'series', all_series)

            if (!this.filter.from_date && !this.filter.to_date) {
                this.options.xAxis.min = min_overall * 1000
                this.options.xAxis.max = max_overall * 1000
            } else {
                this.options.xAxis.min = undefined
                this.options.xAxis.max = undefined
            }

            this.options.xAxis.tickInterval = moment.duration(1, this.filter.chart_period).asMilliseconds()

            this.$emit('reportLoaded', this.report_index)
        },

        sortChange(data) {
            if (data.order) {
                this.dataSort = data.prop
                switch (data.order) {
                    case 'descending':
                        this.dataSortType = 'DESC'
                        break
                    case 'ascending':
                        this.dataSortType = 'ASC'
                        break
                }
            } else {
                this.dataSort = null
                this.dataSortType = null
            }
        },

        changePage(val) {
            this.current_page = val
        },

        handleSizeChange(val) {
            this.current_page = 1
            this.per_page = val
        },

        toVariableName(name) {
            return name.toLowerCase().replace(/ /g, '_')
        },

        copy() {
            if (this.highchartSeries.length == 0) {
                return
            }
            let first_data_keys = _.clone(_.first(this.highchartSeries))
            let data_keys = _.clone(first_data_keys)
            first_data_keys = Object.keys(first_data_keys)
            data_keys = Object.keys(data_keys)
            let copied = ''
            for (let index in data_keys) {
                data_keys[index] = this.$options.filters.ucwords(data_keys[index].replace(/_/g, ' ')).replace('Dnc', 'DNC')
                copied += data_keys[index] + '\t'
            }
            copied += '\n'

            let report = _.clone(this.highchartSeries)
            report.sort((a, b) => {
                return b[this.dataSort] - a[this.dataSort]
            })
            if (report.length) {
                for (let key in report) {
                    for (let data_key in first_data_keys) {
                        copied += report[key][first_data_keys[data_key]] + '\t'
                    }
                    copied += '\n'
                }
            }

            const el = document.createElement('textarea')
            el.value = copied
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)

            this.$notify({
                offset: 95,
                title: 'Reports',
                dangerouslyUseHTMLString: true,
                message: 'Data has been copied to your clipboard.',
                type: 'success',
                showClose: true
            })
        },

        updateChartYAxis(chart) {
            if (this.type == 'overview') {
                let maxYAxisValue = Math.max(chart.yAxis[0].getExtremes().max)
                this.$emit('topGraphMaxYAxisValueLoaded', maxYAxisValue)
            }
        },
    },

    watch: {
        type() {
            this.generateGraphData(false)
        }
    }
}
</script>
