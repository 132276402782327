export const IntakeSources = [
    {
        'code': 'form-capture',
        'name': 'Form Capture'
    },
    {
        'code': 'email-capture',
        'name': 'Email Capture'
    },
    {
        'code': 'hubspot',
        'name': 'HubSpot'
    },
    {
        'code': 'pipedrive',
        'name': 'Pipedrive'
    },
    {
        'code': 'zoho',
        'name': 'ZohoCRM'
    },
    {
        'code': 'guesty',
        'name': 'Guesty'
    },
    {
        'code': 'helpscout',
        'name': 'Help Scout'
    },
    {
        'code': 'facebook',
        'name': 'Facebook'
    },
    {
        'code': 'imports',
        'name': 'Imports'
    },
    {
        'code': 'voip-call',
        'name': 'Call'
    },
    {
        'code': 'voip-sms',
        'name': 'Sms'
    },
    {
        'code': 'ui',
        'name': 'Manual'
    },
]
