const base_path = `/api/v1/user`

export default {
    /**
     * Endpoint for fetching user teams.
     * @param userId {Number} - User ID
     * @handler Api/V1/User/UserController@getUserTeams
     */
    getUserTeams(userId, params) {
        return axios.get(`${base_path}/${userId}/teams`, { params })
    },

    /**
     * Endpoint for associating teams to a user.
     * @param userId {Number} - User ID
     * @param teamsIds {Array} - Array of team IDs
     * @handler Api/V1/User/UserController@associateTeams
     * @method POST
     */
    associateTeams(userId, teams_ids) { 
        return axios.post(`${base_path}/${userId}/teams`, { teams_ids })
    },

    /**
     * Endpoint for disassociating teams from a user.
     * @param userId {Number} - User ID
     * @param teamsIds {Array} - Array of team IDs
     * @handler Api/V1/User/UserController@disassociateTeams
     * @method DELETE
     */
    disassociateTeams(userId, teams_ids) {
        return axios.delete(`${base_path}/${userId}/teams`, { data: { teams_ids } })
    }
}
