<template>
    <div class="row no-gutter"
         :class="[ no_padding ? '' : 'pl-3 pr-3' ]"
         v-if="contact && contact.id">
        <!-- Start HubSpot JIT Card -->
        <div class="col-12"
             v-loading="loading_integration_data.hubspot"
             style="min-height: 60px;"
             v-if="hubspotEnabled">
            <el-card shadow="never"
                     class="small-paddings"
                     v-if="integration_data.hubspot && !isEmpty(integration_data.hubspot)">
                <div slot="header"
                     class="clearfix">
                    <a class="d-flex align-items-center"
                       target="_blank"
                       data-testid="hubspot-contact-link"
                       :href="hubspotContactLink">
                        <button class="md-btn white hubspot-btn small"></button>
                        <strong class="l-h-1x ml-2">HubSpot</strong>
                    </a>
                </div>
                <table class="mb-2 w-100">
                    <tr v-if="integration_data.hubspot.properties.firstname && integration_data.hubspot.properties.lastname">
                        <td class="text-muted">Name</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.hubspot.properties.firstname.value + ' ' + integration_data.hubspot.properties.lastname.value }}</span>
                        </td>
                    </tr>
                    <tr v-if="integration_data.hubspot.properties.email">
                        <td class="text-muted">Email</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.hubspot.properties.email.value }}</span>
                        </td>
                    </tr>
                    <tr v-if="integration_data.hubspot.properties.company">
                        <td class="text-muted">Company</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.hubspot.properties.company.value }}</span>
                        </td>
                    </tr>
                    <tr v-if="integration_data.hubspot.properties.hubspot_owner">
                        <td class="text-muted">Owner</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.hubspot.properties.hubspot_owner.firstName + ' ' + integration_data.hubspot.properties.hubspot_owner.lastName }}</span>
                        </td>
                    </tr>
                </table>

                <template v-if="integration_data.hubspot.properties.deals">
                    <div class="b-a p-2 rounded mb-2"
                         v-for="(deal, index) in integration_data.hubspot.properties.deals"
                         :key="index">
                        <table class="w-100">
                            <tr v-if="deal.properties.dealname">
                                <td colspan="2">
                                    <a target="_blank"
                                       data-testid="hubspot-contact-base-link"
                                       :href="hubspotContactBaseLink + 'deal/' + deal.dealId">
                                        <strong class="text-md">{{ deal.properties.dealname.value }}</strong>
                                    </a>
                                </td>
                            </tr>
                            <tr v-if="deal.properties.amount">
                                <td class="text-muted">Amount</td>
                                <td class="text-right _400">
                                    <span>{{ deal.properties.amount.value | toCurrency }}</span>
                                </td>
                            </tr>
                            <tr v-if="deal.properties.pipeline">
                                <td class="text-muted">Pipeline</td>
                                <td class="text-right _400">
                                    <span>{{ deal.properties.pipeline.label }}</span>
                                </td>
                            </tr>
                            <tr v-if="deal.properties.dealstage">
                                <td class="text-muted">Stage</td>
                                <td class="text-right _400">
                                    <span>{{ deal.properties.dealstage.label }}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </template>

                <div class="row no-gutter centered-content mt-3 mb-3"
                     v-if="integration_data.hubspot.properties.email && integration_data.hubspot.properties.email.value && false">
                    <el-popover placement="right"
                                trigger="click"
                                width="400"
                                v-model="enroll_hubspot_popover_visibility"
                                @hide="resetHubspotContactEnroll">
                        <el-form ref="enroll_to_hubspot_workflow"
                                 class="mt-2 p-0"
                                 label-position="top"
                                 :rules="rules_hubspot_workflow"
                                 :model="hubspot_workflow"
                                 data-testid="enroll-to-hubspot-workflow"
                                 @submit.prevent.native="enrollHubspotWorkflow">
                            <el-form-item label="Select a HubSpot workflow"
                                          prop="workflow_id">
                                <hubspot-workflow-selector v-model="hubspot_workflow.workflow_id"
                                                           ref="hubspotWorkflowSelector"
                                                           :value="hubspot_workflow.workflow_id"
                                                           data-testid="hubspot-worklow-select"
                                                           @change="hubspotWorkflowChanged">
                                </hubspot-workflow-selector>
                            </el-form-item>
                            <div class="row no-gutter mt-2 centered-content">
                                <button class="btn btn-block greenish"
                                        :disabled="loading_hubspot_enroll_contact || !validated"
                                        data-testid="hubspot-enroll-button"
                                        @click.prevent="enrollHubspotWorkflow">
                                    <i class="material-icons loader"
                                       v-show="loading_hubspot_enroll_contact">&#xE863;</i>
                                    <i class="fa fa-plus"
                                       v-show="!loading_hubspot_enroll_contact"></i>
                                    Enroll
                                </button>
                            </div>
                        </el-form>
                        <button class="md-btn md-raised deep-orange pl-3 pr-3"
                                data-testid="hubspot-enroll-to-workflow-button"
                                slot="reference">
                            <i class="fa fa-user-plus mr-2"></i>
                            Enroll to workflow
                        </button>
                    </el-popover>
                </div>
            </el-card>

            <div class="mt-2 centered-content"
                 v-if="!dialer_mode">
                <el-button
                    class="bold text-deep-orange width-300 fixed"
                    size="small"
                    type="deep-orange"
                    :disabled="loading_hubspot_sync"
                    plain
                    round
                    data-testid="sync-hubspot-button"
                    @click.prevent="syncHubspot">
                    <strong>
                        <i class="material-icons loader"
                           v-show="loading_hubspot_sync">&#xE863;</i>
                        <i class="material-icons"
                           v-show="!loading_hubspot_sync">sync</i>
                        <slot>Sync with HubSpot</slot>
                    </strong>
                </el-button>

                <el-popover
                    placement="right"
                    width="350"
                    trigger="hover">
                    <div class="_400">
                        <p>Click on this button to sync the data for this contact between Aloware and HubSpot. You'll want to click on this button if:</p>
                        <ol>
                            <li>The contact was recently merged in HubSpot with another contact.</li>
                            <li>You notice any inconsistencies between Aloware and HubSpot data on this contact.</li>
                        </ol>
                    </div>
                    <i class="material-icons text-blue cursor-pointer ml-2"
                       data-testid="info-sync-hubspot"
                       slot="reference">info_outline</i>
                </el-popover>
            </div>
        </div>
        <!-- End HubSpot JIT card -->
        <!-- Start Pipedrive JIT Card -->
        <div class="col-12"
             v-loading="loading_integration_data.pipedrive"
             style="min-height: 60px;"
             v-if="pipedriveEnabled">
            <el-card shadow="never"
                     class="small-paddings"
                     v-if="integration_data.pipedrive && !isEmpty(integration_data.pipedrive)">
                <!-- Start Header -->
                <div slot="header"
                     class="clearfix">
                    <div class="d-flex align-items-center">
                        <button class="md-btn white pipedrive-btn small"></button>
                        <strong class="l-h-1x ml-2">Pipedrive</strong>
                    </div>
                </div>
                <!-- End header -->
                <!-- Start main info -->
                <table class="mb-2 w-100">
                    <tr v-if="integration_data.pipedrive.contact_details.name">
                        <td class="text-muted">Name</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.pipedrive.contact_details.name }}</span>
                        </td>
                    </tr>
                    <tr
                        v-if="integration_data.pipedrive.contact_details.email
                && integration_data.pipedrive.contact_details.email.length > 0
                && integration_data.pipedrive.contact_details.email[0].value">
                        <td class="text-muted">Email</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.pipedrive.contact_details.email[0].value }}</span>
                        </td>
                    </tr>
                    <tr v-if="integration_data.pipedrive.contact_details.org_name">
                        <td class="text-muted">Company</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.pipedrive.contact_details.org_name }}</span>
                        </td>
                    </tr>
                    <tr v-if="integration_data.pipedrive.contact_details.owner_name">
                        <td class="text-muted">Owner</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.pipedrive.contact_details.owner_name }}</span>
                        </td>
                    </tr>
                </table>
                <!-- End main info -->
                <!-- Start Deals -->
                <template v-if="integration_data.pipedrive.deals && integration_data.pipedrive.deals.length > 0">
                    <div class="b-a p-2 rounded mb-2"
                         v-for="(deal, index) in integration_data.pipedrive.deals"
                         :key="index">
                        <table class="w-100">
                            <tr v-if="deal.title">
                                <td colspan="2">
                                    <a target="_blank" :href="hubspotContactBaseLink">
                                        <strong class="text-md">{{ deal.title }}</strong>
                                    </a>
                                </td>
                            </tr>
                            <tr v-if="deal.formatted_value">
                                <td class="text-muted">Amount</td>
                                <td class="text-right _400">
                                    <span>{{ deal.formatted_value }}</span>
                                </td>
                            </tr>
                            <tr v-if="deal.pipeline_details.name">
                                <td class="text-muted">Pipeline</td>
                                <td class="text-right _400">
                                    <span>{{ deal.pipeline_details.name }}</span>
                                </td>
                            </tr>
                            <tr v-if="deal.stage_details.name">
                                <td class="text-muted">Stage</td>
                                <td class="text-right _400">
                                    <span>{{ deal.stage_details.name }}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </template>
                <!-- End Deals -->
            </el-card>
            <!-- Start Sync Button -->
            <div class="mt-2 centered-content"
                 v-if="!dialer_mode">
                <el-button
                    class="bold width-300 fixed"
                    size="small"
                    :disabled="loading_pipedrive_sync"
                    type="deep-blue"
                    plain
                    round
                    data-testid="sync-pipedrive-button"
                    @click.prevent="syncPipedrive">
                    <strong>
                        <i class="material-icons loader"
                           v-show="loading_pipedrive_sync">&#xE863;</i>
                        <i class="material-icons"
                           v-show="!loading_pipedrive_sync">sync</i>
                        <slot>Sync with Pipedrive</slot>
                    </strong>
                </el-button>

                <el-popover
                    placement="right"
                    width="350"
                    trigger="hover">
                    <div class="_400">
                        <p>Click on this button to sync the data for this contact between Aloware and Pipedrive. You'll want to click on this button if:</p>
                        <ol>
                            <li>You want to add this contact in Pipedrive. Note that
                                "<span class="font-weight-bold">Don't create new contacts</span>"
                                filter must be disabled in the integration settings.
                            </li>
                            <li>You notice any inconsistencies between Aloware and Pipedrive data on this contact.</li>
                        </ol>
                    </div>
                    <i class="material-icons text-blue cursor-pointer ml-2"
                       data-testid="info-sync-pipedrive"
                       slot="reference">info_outline</i>
                </el-popover>
            </div>
            <!-- End Sync Button -->
        </div>
        <!-- End Pipedrive JIT Card -->
        <!-- Start GHL JIT Card -->
        <div class="col-12"
             v-loading="loading_integration_data.gohighlevel"
             style="min-height: 60px;"
             v-if="goHighLevelEnabled">
            <el-card shadow="never"
                     class="small-paddings"
                     v-if="integration_data.gohighlevel && !isEmpty(integration_data.gohighlevel)">
                <!-- Start Header -->
                <div slot="header"
                     class="clearfix">
                    <div class="d-flex align-items-center">
                        <a class="d-flex align-items-center"
                           target="_blank"
                           data-testid="gohighlevel-contact-link"
                           :href="goHighLevelContactLink">
                            <button class="md-btn white gohighlevel-btn small"></button>
                        </a>
                        <strong class="l-h-1x ml-2">GoHighLevel</strong>
                    </div>
                </div>
                <!-- End header -->
                <!-- Start main info -->
                <table class="mb-2 w-100">
                    <tr v-if="integration_data.gohighlevel.contact_details.contact.firstName">
                        <td class="text-muted">First Name</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.gohighlevel.contact_details.contact.firstName }}</span>
                        </td>
                    </tr>
                    <tr v-if="integration_data.gohighlevel.contact_details.contact.lastName">
                        <td class="text-muted">Last Name</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.gohighlevel.contact_details.contact.lastName }}</span>
                        </td>
                    </tr>
                    <tr
                        v-if="integration_data.gohighlevel.contact_details.contact.email
                && integration_data.gohighlevel.contact_details.contact.email.length > 0">
                        <td class="text-muted">Email</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.gohighlevel.contact_details.contact.email }}</span>
                        </td>
                    </tr>
                    <tr v-if="integration_data.gohighlevel.contact_details.contact.phone">
                        <td class="text-muted">Phone</td>
                        <td class="text-right _400">
                            <span>{{ integration_data.gohighlevel.contact_details.contact.phone }}</span>
                        </td>
                    </tr>
                </table>
                <!-- End main info -->
            </el-card>
            <!-- Start Sync Button -->
            <div class="mt-2 centered-content"
                 v-if="!dialer_mode">
                <el-button
                    class="bold width-300 fixed"
                    size="small"
                    :disabled="loading_gohighlevel_sync"
                    type="deep-blue"
                    plain
                    round
                    data-testid="info-sync-gohighlevel"
                    @click.prevent="syncGHL">
                    <strong>
                        <i class="material-icons loader"
                           v-show="loading_gohighlevel_sync">&#xE863;</i>
                        <i class="material-icons"
                           v-show="!loading_gohighlevel_sync">sync</i>
                        <slot>Sync with GoHighLevel</slot>
                    </strong>
                </el-button>

                <el-popover
                    placement="right"
                    width="350"
                    trigger="hover">
                    <div class="_400">
                        <p>Click on this button to sync the data for this contact between Aloware and GoHighLevel. You'll want to click on this button if:</p>
                        <ol>
                            <li>You want to add this contact in GoHighLevel. Note that
                                "<span class="font-weight-bold">Don't create new contacts</span>"
                                filter must be disabled in the integration settings.
                            </li>
                            <li>You notice any inconsistencies between Aloware and GoHighLevel data on this contact.</li>
                        </ol>
                    </div>
                    <i class="material-icons text-blue cursor-pointer ml-2"
                       data-testid="info-sync-gohighlevel"
                       slot="reference">info_outline</i>
                </el-popover>
            </div>
            <!-- End Sync Button -->
        </div>
        <!-- End GHL JIT Card -->
    </div>
</template>

<script>
import auth from '../auth'
import {mapState, mapActions} from 'vuex'
import {
    acl_mixin,
    form_validation_mixin
} from "../mixins"
import HubspotWorkflowSelector from "./hubspot-workflow-selector"

export default {
    mixins: [
        acl_mixin,
        form_validation_mixin
    ],

    components: {HubspotWorkflowSelector},

    props: {
        contact: {
            required: true
        },

        dialer_mode: {
            type: Boolean,
            required: false,
            default: false
        },

        no_padding: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data() {
        return {
            auth: auth,
            current_contact_id: null,
            loading_integration_data: {
                hubspot: false,
                pipedrive: false,
                zoho: false,
                helpscout: false,
                guesty: false,
                gohighlevel: false,
            },
            integration_data: {
                hubspot: {},
                pipedrive: {},
                zoho: {},
                helpscout: {},
                guesty: {},
                gohighlevel: {},
            },
            enroll_hubspot_popover_visibility: false,
            hubspot_workflow: {
                email: null,
                workflow_id: null
            },
            hubspot_autosync_ran: true, // auto-sync when contact page is opened. Turn to "false" to re-enable
            hubspot_ui_domain: 'app.hubspot.com',
            loading_hubspot_enroll_contact: false,
            loading_hubspot_sync: false,
            loading_pipedrive_sync: false,
            loading_gohighlevel_sync: false,
            rules_hubspot_workflow: {
                workflow_id: [
                    {
                        required: true,
                        message: 'Please select a HubSpot workflow',
                        trigger: 'change'
                    }
                ]
            }
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),

        hubspotEnabled() {
            if (this.current_company &&
                this.current_company.hubspot_integration_enabled) {
                return true
            }

            return false
        },

        hubspotContactLink() {
            if (this.current_company &&
                this.current_company.hubspot_integration_enabled &&
                this.current_company.hubspot_marketing_portal_id &&
                this.integration_data &&
                this.integration_data.hubspot) {
                const ui_domain = this.current_company.hubspot_company_ui_domain ? this.current_company.hubspot_company_ui_domain : 'app.hubspot.com'
                return `https://${ui_domain}/contacts/${this.current_company.hubspot_marketing_portal_id}/contact/${this.integration_data.hubspot['canonical-vid']}`
            }

            return false
        },

        hubspotContactBaseLink() {
            if (this.current_company &&
                this.current_company.hubspot_integration_enabled &&
                this.current_company.hubspot_marketing_portal_id) {
                const ui_domain = this.current_company.hubspot_company_ui_domain ? this.current_company.hubspot_company_ui_domain : 'app.hubspot.com'
                return `https://${ui_domain}/contacts/${this.current_company.hubspot_marketing_portal_id}/`
            }

            return false
        },

        goHighLevelContactLink() {
            if (this.current_company &&
                this.current_company.gohighlevel_integration_enabled &&
                this.integration_data &&
                this.integration_data.gohighlevel) {
                const ghl_contact_link = this.current_company.gohighlevel_contact_link ? this.current_company.gohighlevel_contact_link : ''
                const ghl_contact_id = this.integration_data.gohighlevel.contact_details.contact.id
                return ghl_contact_link + ghl_contact_id
            }

            return false
        },

        pipedriveEnabled() {
            return this.current_company && this.current_company.pipedrive_integration_enabled
        },

        goHighLevelEnabled() {
            return this.current_company && this.current_company.gohighlevel_integration_enabled
        },

        zohoEnabled() {
            if (this.current_company &&
                this.current_company.zoho_integration_enabled) {
                return true
            }

            return false
        },

        helpscoutEnabled() {
            if (this.current_company &&
                this.current_company.helpscout_integration_enabled) {
                return true
            }

            return false
        },

        guestyEnabled() {
            if (this.current_company &&
                this.current_company.guesty_integration_enabled) {
                return true
            }

            return false
        }
    },

    created() {
        this.fetchCardData()
    },

    methods: {
        ...mapActions(['fetchPipedriveIntegrationSettingsFilters']),
        fetchContactIntegrationData(contact_id, integration_name, force = false) {
            this.loading_integration_data[integration_name] = true
            axios.get('/api/v1/contact/' + contact_id + '/integration-data', {
                params: {
                    integration_name: integration_name,
                    dialer_mode: +this.dialer_mode,
                    force: force
                }
            }).then(res => {
                this.loading_integration_data[integration_name] = false
                this.integration_data[integration_name] = res.data
            }).catch(err => {
                this.loading_integration_data[integration_name] = false
            })
        },

        isEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object
        },

        fetchCardData(force = false) {
            if (this.contact && this.contact.id) {
                this.current_contact_id = this.contact.id
                if (this.hubspotEnabled) {
                    this.fetchContactIntegrationData(this.contact.id, 'hubspot', force)
                    this.autoSyncHubSpotOnce()
                } else if (this.pipedriveEnabled) {
                    this.fetchContactIntegrationData(this.contact.id, 'pipedrive', force)
                } else if (this.goHighLevelEnabled) {
                    this.fetchContactIntegrationData(this.contact.id, 'gohighlevel', force)
                }
            }
        },

        resetHubspotContactEnroll() {
            this.hubspot_workflow.workflow_id = null
            this.hubspot_workflow.email = null
            this.validated = false
            this.resetForm('enroll_to_hubspot_workflow')
        },

        hubspotWorkflowChanged(workflow_id) {
            this.hubspot_workflow.workflow_id = workflow_id
            this.preValidateForm('enroll_to_hubspot_workflow')
        },

        enrollHubspotWorkflow() {
            this.loading_hubspot_enroll_contact = true
            this.hubspot_workflow.email = this.integration_data.hubspot.properties.email.value
            axios.post(`/api/v1/integration/hubspot/enroll-contact`, this.hubspot_workflow).then((res) => {
                this.loading_hubspot_enroll_contact = false
                this.resetHubspotContactEnroll()
                this.enroll_hubspot_popover_visibility = false
                this.$notify({
                    offset: 95,
                    title: 'HubSpot',
                    message: 'Contact has been enrolled to the workflow.',
                    type: 'success',
                    showClose: true,
                })
                this.$emit('success')
            }).catch((err) => {
                this.$root.handleErrors(err.response)
                this.loading_hubspot_enroll_contact = false
                this.resetHubspotContactEnroll()
            })
        },

        syncHubspot() {
            if (this.contact && this.contact.id && this.hubspotEnabled) {
                this.loading_hubspot_sync = true
                axios.post('/api/v1/contact/' + this.contact.id + '/sync-hubspot').then(res => {
                    this.loading_hubspot_sync = false
                    this.$notify({
                        offset: 95,
                        title: 'HubSpot',
                        message: 'Contact has been successfully synced.',
                        type: 'success',
                        showClose: true,
                    })
                    this.fetchCardData(true)
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_hubspot_sync = false
                })
            }
        },

        autoSyncHubSpotOnce() {
          // we enable autosync when a contact is opened for these companies
          if (this.hubspot_autosync_ran) return

          if (this.contact?.id && this.hubspotEnabled) {
            this.loading_hubspot_sync = true
            axios.post('/api/v1/contact/' + this.contact.id + '/sync-hubspot').then(res => {
              this.loading_hubspot_sync = false
            }).catch(err => {
              this.$root.handleErrors(err.response)
              this.loading_hubspot_sync = false
            }).finally(() => this.hubspot_autosync_ran = true)
          }
        },

        async syncPipedrive() {
            this.loading_pipedrive_sync = true
            // We don't want to proceed if integration card data is empty and Don't create contact filter is enabled
            const integrationCardData = this.integration_data['pipedrive']
            const contactNotInPipedrive = !integrationCardData || Object.keys(integrationCardData).length === 0

            if (contactNotInPipedrive) {
                // Only call the API if contact not in Pipedrive
                const shouldCreateContact = !(await this.fetchPipedriveIntegrationSettingsFilters())['filter_never_create_new_contact']
                // Don't proceed if don't create contact filter is enabled
                if (!shouldCreateContact) {

                    this.$notify({
                        offset: 95,
                        title: 'Pipedrive',
                        message: 'Cannot create a new contact in Pipedrive. Please check the filter options in the integration settings page',
                        type: 'warning',
                        duration: 6000,
                        showClose: true,
                    })

                    return this.loading_pipedrive_sync = false
                }
            }

            // Proceed otherwise
            axios.post('/api/v1/contact/' + this.contact.id + '/sync-pipedrive').then(res => {
                this.loading_pipedrive_sync = false
                this.$notify({
                    offset: 95,
                    title: 'Pipedrive',
                    message: 'Contact has been successfully synced.',
                    type: 'success',
                    showClose: true,
                })
                this.fetchCardData(true)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_pipedrive_sync = false
            })
        },

        async syncGHL() {
            this.loading_gohighlevel_sync = true

            axios.post('/api/v1/contact/' + this.contact.id + '/sync-gohighlevel').then(res => {
                this.loading_gohighlevel_sync = false
                this.$notify({
                    offset: 95,
                    title: 'GoHighLevel',
                    message: 'Contact has been successfully synced.',
                    type: 'success',
                    showClose: true,
                })
                this.fetchCardData(true)
            }).catch(err => {
                this.$root.handleErrors(err.response)
                this.loading_gohighlevel_sync = false
            })
        },
    },

    watch: {
        contact() {
            if (this.contact && (!this.current_contact_id || this.current_contact_id != this.contact.id)) {
                this.fetchCardData()
            }
        }
    }
}
</script>
