<template>
    <div v-if="hasPermissionTo('list broadcast')">
        <el-select v-model="broadcast_id"
                   class="w-full"
                   :placeholder="placeholder"
                   :multiple="multiple"
                   :multiple-limit="multiple ? multipleLimit : 0"
                   :size="size"
                   filterable
                   clearable
                   data-testid="broadcast-selector"
                   default-first-option
                   collapse-tags>
            <el-option
                v-for="broadcast in broadcastsAlphabeticalOrder"
                :key="broadcast.id"
                :label="broadcast.name"
                :value="broadcast.id">
            </el-option>
        </el-select>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../auth'
import {acl_mixin} from '../mixins'

export default {
    mixins: [acl_mixin],

    props: {
        value: {
            required: false,
        },

        exclude: {
            required: false,
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        multipleLimit: {
            type: Number,
            required: false,
            default: 0
        },

        size: {
            type: String,
            default: '',
            required: false
        }
    },

    data() {
        return {
            auth: auth,
            broadcast_id: this.value
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            broadcasts: state => state.broadcasts
        }),

        placeholder() {
            if (this.multiple) {
                return 'Select Broadcasts'
            }

            return 'Select Broadcast'
        },

        broadcastsAlphabeticalOrder() {
            if (this.broadcasts) {
                return this.broadcasts.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },
    },

    watch: {
        value() {
            this.broadcast_id = this.value
        },

        broadcast_id(val) {
            if (this.value !== undefined && this.broadcast_id !== this.value) {
                this.$emit('change', val)
            }
        }
    }
}
</script>
