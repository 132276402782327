<template>
    <div>
        <MessagingServiceRegistration
            v-show="currentStep === 1"
            :alert-message="alertMessage"
            :selected_lines="selected_lines"
            @alert="submitAlert"
            @next="goToNextStep"
        />
        <UseCaseRegistration
            v-if="messaging_service"
            v-show="currentStep === 2"
            :alert-message="alertMessage"
            :selected_lines="selected_lines"
            :selected_messaging_service="messaging_service"
            @alert="submitAlert"
            @next="goToNextStep"
        />
    </div>
</template>

<script>
import { compliance_mixin } from '../../../../mixins'
import MessagingServiceRegistration from './messaging-service-registration.vue'
import UseCaseRegistration from './use-case-registration.vue'

export default {
    name: 'Steps',
    mixins: [compliance_mixin],
    components: {
        MessagingServiceRegistration,
        UseCaseRegistration,
    },
    props: {
        selected_lines: {
            type: Array,
            default: () => [],
        },
        selected_messaging_service: {
            type: Object,
            default: null,
        },
    },
    created() {
        VueEvent.listen('edit_messaging_service', (messaging_service) => {
            this.messaging_service = messaging_service
        })

        VueEvent.listen('compliance_wizard_closed', () => {
            this.messaging_service = null
        })

        if (this.selected_messaging_service) {
            this.messaging_service = this.selected_messaging_service
        }
    },
    methods: {
        goToNextStep(messaging_service) {
            this.$emit('next', messaging_service);
            this.messaging_service = messaging_service;
        },
    }
}
</script>
