<template>
    <el-col class="p-2 pr-4" :span="colSpan">
        <label class="d-flex justify-content-start w-100 mb-1 text-weight-medium">
            {{ label }}
        </label>
        <div class="phone-row">
            <el-input class="col-md-3 pl-0"
                      rounded
                      outlined
                      placeholder="+1"
                      mask="+###"
                      type="text"
                      autocomplete="off"
                      :disabled="disabled"
                      v-model="countryCode">
            </el-input>
            <el-input class="col-md-9 pr-0"
                      ref="inputRef"
                      type="text"
                      rounded
                      outlined
                      autocomplete="off"
                      :placeholder="placeholder"
                      :rules="rules"
                      :mask="phoneMask"
                      :value="formattedValue"
                      :disabled="disabled"
                      @input="emitUpdateEvent">
            </el-input>
        </div>

        <slot name="hint"></slot>
    </el-col>
</template>

<script>
import {phone_masker_mixin} from '../../mixins'

export default {
    name: 'PhoneNumberField',

    mixins: [phone_masker_mixin],

    props: {
        label: {
            type: String,
            required: true
        },

        placeholder: {
            type: String,
            required: true
        },

        value: {
            type: String,
            default: ''
        },

        colMd: {
            type: String,
            default: 'col-md-5'
        },

        paddingClasses: {
            type: String,
            default: ''
        },

        rules: {
            type: Array,
            default: () => []
        },

        type: {
            type: String,
            default: 'text'
        },

        isPassword: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        colSpan: {
            type: Number,
            default: 8
        }
    },

    data() {
        return {
            innerValue: this.value,
            countryCode: '+1',
            formattedValue: this.value
        }
    },

    computed: {
        phoneMask() {
            return this.getMaskByCountry(this.countryCode?.replace('+', ''))
        }
    },

    methods: {
        emitUpdateEvent(value) {
            this.innerValue = value
            this.$emit('input', this.innerValue)
        },

        validate() {
            if (this.$refs.inputRef && typeof this.$refs.inputRef.validate === 'function') {
                return this.$refs.inputRef.validate()
            }
        }
    },

    watch: {
        value: {
            handler(value) {
                if (value && value.includes('//')) {
                    const [countryCode, phoneNumber] = value.split('//')
                    this.countryCode = countryCode
                    this.innerValue = phoneNumber
                    this.formattedValue = phoneNumber
                } else {
                    this.innerValue = value
                }
            },
            immediate: true
        }
    }
}
</script>
