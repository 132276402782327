var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "useCaseRegistrationForm",
      attrs: {
        model: _vm.useCaseRegistrationForm,
        rules: _vm.rules,
        autocomplete: "off",
        "label-position": "top",
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("h5", [_vm._v("A2P Use Case Registration")]),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-divider" }),
            _vm._v(" "),
            !_vm.selected_messaging_service?.a2p_use_case
              ? _c("el-alert", {
                  staticClass: "mb-2",
                  attrs: {
                    title: "Important",
                    description:
                      "Purposefully misleading your campaign will result in violations and possible termination.",
                    type: "info",
                    "show-icon": "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.selected_messaging_service?.a2p_use_case?.prevetting_status ===
            _vm.ComplianceStatuses.STATUS_REJECTED
              ? _c(
                  "el-alert",
                  {
                    staticClass: "mb-2",
                    attrs: {
                      description:
                        _vm.selected_messaging_service?.a2p_use_case
                          ?.failure_reason,
                      title: "A2P Campaign Pre-Vetting Rejected",
                      type: "error",
                      "show-icon": "",
                      closable: false,
                    },
                  },
                  [
                    _c("p", { staticClass: "mb-0" }, [
                      _c("span", { staticClass: "text-dark" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.selected_messaging_service?.a2p_use_case
                                ?.failure_reason
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.selected_messaging_service?.a2p_use_case?.errors
              ? _c(
                  "el-alert",
                  {
                    staticClass: "mb-2",
                    attrs: {
                      type: "warning",
                      "show-icon": "",
                      closable: false,
                    },
                  },
                  [
                    _c("template", { slot: "title" }, [
                      _c("span", { staticClass: "text-dark" }, [
                        _vm._v("Your A2P Campaign has been rejected."),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-dark" }, [
                      _vm._v("Please note that "),
                      _c("b", [
                        _vm._v("monthly recurring charges will still apply"),
                      ]),
                      _vm._v(
                        " despite the campaign status. Please review the rejection details, make the necessary adjustments and resubmit the campaign."
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(
              _vm.selected_messaging_service?.a2p_use_case?.errors,
              function (error) {
                return _c("el-alert", {
                  key: error.error_code,
                  staticClass: "mb-2",
                  attrs: {
                    description: `Error Code ${error.error_code}: ${error.description}`,
                    title: _vm.fixToUcFirst(error.fields[0]),
                    type: "error",
                    "show-icon": "",
                    closable: false,
                  },
                })
              }
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "Use Case", prop: "use_case" } },
              [
                _c("span", { staticClass: "page-anchor" }),
                _vm._v(" "),
                !_vm.isSimpSocial
                  ? _c("p", { staticClass: "line-height-normal" }, [
                      _vm._v("Read our "),
                      _c(
                        "a",
                        {
                          staticClass: "font-weight-bold text-blue",
                          attrs: {
                            target: "_blank",
                            href: "https://support.aloware.com/en/articles/9031988-a2p-10dlc-compliance-campaign-registration",
                          },
                        },
                        [_vm._v("Easy Guide")]
                      ),
                      _vm._v(" on what Use Case to choose."),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "switch-label" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.shouldDisableForm },
                        on: {
                          change: function ($event) {
                            return _vm.useCaseChanged()
                          },
                        },
                        model: {
                          value: _vm.useCaseRegistrationForm.use_case,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.useCaseRegistrationForm,
                              "use_case",
                              $$v
                            )
                          },
                          expression: "useCaseRegistrationForm.use_case",
                        },
                      },
                      _vm._l(_vm.selectableUseCases, function (use_case) {
                        return _c(
                          "el-radio",
                          {
                            key: use_case.value,
                            staticClass: "d-flex",
                            attrs: { label: use_case.value },
                          },
                          [
                            _c("div", [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(use_case.title)),
                              ]),
                              _vm._v(
                                ": " +
                                  _vm._s(use_case.description) +
                                  "\n                            "
                              ),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "Unregistered Lines" } },
              [
                _c("span", { staticClass: "page-anchor" }),
                _vm._v(" "),
                _c("p", { staticClass: "line-height-normal" }, [
                  _vm._v(
                    "You can register up to 400 numbers on a single campaign."
                  ),
                ]),
                _vm._v(" "),
                _c("campaign-selector", {
                  attrs: {
                    multiple: "",
                    collapsable: _vm.shouldCollapseLines,
                    clearable: "",
                    only_10_dlc: "",
                    value: _vm.line_ids,
                    is_a2p_campaign_wizard: true,
                    show_paused: true,
                    unregistered_lines_only: true,
                    disabled:
                      _vm.shouldUnregisteredLines || _vm.shouldDisableForm,
                  },
                  on: { change: _vm.addLine, input: _vm.addLine },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "Campaign Description", prop: "description" } },
              [
                _c("span", { staticClass: "page-anchor" }),
                _vm._v(" "),
                _c("p", { staticClass: "line-height-normal" }, [
                  _vm._v(
                    "Describe what you will be using this campaign for including the campaign's purpose, audience and sender. "
                  ),
                  !_vm.isSimpSocial
                    ? _c(
                        "a",
                        {
                          staticClass: "font-weight-bold text-blue",
                          attrs: {
                            target: "_blank",
                            href: "https://support.aloware.com/en/articles/9031981-a2p-10dlc-compliance-brand-registration-guide",
                          },
                        },
                        [_vm._v("Learn more")]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { disabled: _vm.shouldDisableForm },
                  on: {
                    input: function ($event) {
                      return _vm.preValidateForm("useCaseRegistrationForm")
                    },
                  },
                  model: {
                    value: _vm.useCaseRegistrationForm.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.useCaseRegistrationForm, "description", $$v)
                    },
                    expression: "useCaseRegistrationForm.description",
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "line-height-normal mb-0" }, [
                  _c("b", [_vm._v("* Example")]),
                  _vm._v(": "),
                  _c("span", { staticClass: "font-italic" }, [
                    _vm._v(
                      "Provide assistance to our current customers, mainly conversational communication."
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  prop: "message_flow",
                  label:
                    "How do end-users consent to receive messages? (40-2048 characters)",
                },
              },
              [
                _c("span", { staticClass: "page-anchor" }),
                _vm._v(" "),
                _c("p", { staticClass: "line-height-normal" }, [
                  _vm._v(
                    "Describe how users opt-in to your campaign, therefore giving consent to the sender to receive their messages."
                  ),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { type: "textarea", disabled: _vm.shouldDisableForm },
                  on: {
                    input: function ($event) {
                      return _vm.preValidateForm("useCaseRegistrationForm")
                    },
                  },
                  model: {
                    value: _vm.useCaseRegistrationForm.message_flow,
                    callback: function ($$v) {
                      _vm.$set(_vm.useCaseRegistrationForm, "message_flow", $$v)
                    },
                    expression: "useCaseRegistrationForm.message_flow",
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "line-height-normal mb-0" }, [
                  _c("b", [_vm._v("* Example")]),
                  _vm._v(": "),
                  _c("span", { staticClass: "font-italic" }, [
                    _vm._v(
                      "End users opt-in by visiting www.examplewebsite.com and adding their number. They then check a box agreeing to receive text messages from Example Brand. Additionally, end users can also opt-in by texting START to (111) 222-3333 to opt-in."
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "popover",
                          rawName: "v-popover:popover",
                          arg: "popover",
                        },
                      ],
                      staticClass: "font-weight-bold text-blue ml-1",
                    },
                    [_vm._v("Learn more")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-popover",
                  {
                    ref: "popover",
                    attrs: {
                      placement: "top-start",
                      title: "How do end-users consent to receive messages?",
                      width: "800",
                      trigger: "hover",
                    },
                  },
                  [
                    _c("p", { staticClass: "line-height-normal" }, [
                      _vm._v(
                        "This field should describe how and when consumers opt-in to the campaign, therefore giving consent to the sender to receive their messages. The call-to-action must be explicitly clear and inform consumers of the nature of the program. If multiple opt-in methods can be used for the same, list them all."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("ul", [
                      _c("li", [_vm._v("Provide website where opt-in occurs")]),
                      _vm._v(" "),
                      _c("li", [_vm._v("List all opt-in methods")]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("Include an opt-in message if users can text"),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "If a website is provided, it must be accessible with privacy policy and terms"
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "line-height-normal" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Successful Example:"),
                      ]),
                      _vm._v(
                        '\n                    "Customers provide opt-in specifically when enrolling into our website, or in person by requesting SMS capabilities within the store. Opt-in during website is a self-service process and occurs at acme.com/signup. By submitting, you authorize ACME to send text messages with offers and other information. Message/data rates apply. Consent is not a condition of purchase. [Link to terms and conditions]."'
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "line-height-normal" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Failure Example:"),
                      ]),
                      _vm._v(
                        '\n                    "Customers sign up"\n                    (Where and how the customer provides opt-in is unclear, campaign will be rejected.)'
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "sample_message_1" } },
              [
                _c(
                  "template",
                  { slot: "label" },
                  [
                    _vm._v(
                      "\n                    Sample Message #1\n                    "
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content:
                            "Click to pick a random message from your Outbound SMS",
                          placement: "right",
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons hover-lower-opacity-primary text-primary",
                            on: {
                              click: function ($event) {
                                return _vm.randomizeCommunicationSamples(
                                  "sample_message_1"
                                )
                              },
                            },
                          },
                          [_vm._v("sync")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "page-anchor" }),
                _vm._v(" "),
                _c("p", { staticClass: "line-height-normal" }, [
                  _vm._v(
                    "Make sure to include compliant opt-out language in your sample messages, such as "
                  ),
                  _c("i", [_vm._v("Text STOP to unsubscribe")]),
                  _vm._v(". "),
                  _c("b", { staticClass: "text-primary" }, [
                    _vm._v(
                      "Failure to include opt-out language will result in failed registration."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { type: "textarea", disabled: _vm.shouldDisableForm },
                  on: {
                    input: function ($event) {
                      return _vm.preValidateForm("useCaseRegistrationForm")
                    },
                  },
                  model: {
                    value: _vm.useCaseRegistrationForm.sample_message_1,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.useCaseRegistrationForm,
                        "sample_message_1",
                        $$v
                      )
                    },
                    expression: "useCaseRegistrationForm.sample_message_1",
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "line-height-normal mb-0" }, [
                  _c("b", [_vm._v("* Example")]),
                  _vm._v(": "),
                  _c("span", { staticClass: "font-italic" }, [
                    _vm._v(
                      "Hi {Customer Name}, this is {Agent Name} from {Company Name}. Thank you for reaching out. Reply with your question or call us at {Phone Number}. For account updates, visit {Company Website}. Reply STOP to opt-out."
                    ),
                  ]),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "sample_message_2" } },
              [
                _c(
                  "template",
                  { slot: "label" },
                  [
                    _vm._v(
                      "\n                    Sample Message #2\n                    "
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content:
                            "Click to pick a random message from your Outbound SMS",
                          placement: "right",
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons hover-lower-opacity-primary text-primary",
                            on: {
                              click: function ($event) {
                                return _vm.randomizeCommunicationSamples(
                                  "sample_message_2"
                                )
                              },
                            },
                          },
                          [_vm._v("sync")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "page-anchor" }),
                _vm._v(" "),
                _c("p", { staticClass: "line-height-normal" }, [
                  _vm._v(
                    "Please provide another example of the message that you intend to send within this campaign."
                  ),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { type: "textarea", disabled: _vm.shouldDisableForm },
                  on: {
                    input: function ($event) {
                      return _vm.preValidateForm("useCaseRegistrationForm")
                    },
                  },
                  model: {
                    value: _vm.useCaseRegistrationForm.sample_message_2,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.useCaseRegistrationForm,
                        "sample_message_2",
                        $$v
                      )
                    },
                    expression: "useCaseRegistrationForm.sample_message_2",
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "line-height-normal mb-0" }, [
                  _c("b", [_vm._v("* Example")]),
                  _vm._v(": "),
                  _c("span", { staticClass: "font-italic" }, [
                    _vm._v(
                      "🌟 Exclusive Offer from {Company Name}! Unlock 20% off your next purchase with code WELCOME20. Shop now at {Company Website}. Reply STOP to opt-out."
                    ),
                  ]),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: { prop: "message_contents", label: "Message Contents" },
              },
              [
                _c("span", { staticClass: "page-anchor" }),
                _vm._v(" "),
                _c(
                  "el-checkbox",
                  {
                    attrs: { disabled: _vm.shouldDisableForm },
                    model: {
                      value: _vm.useCaseRegistrationForm.has_embedded_links,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.useCaseRegistrationForm,
                          "has_embedded_links",
                          $$v
                        )
                      },
                      expression: "useCaseRegistrationForm.has_embedded_links",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Messages will include embedded links.\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-checkbox",
                  {
                    attrs: { disabled: _vm.shouldDisableForm },
                    model: {
                      value: _vm.useCaseRegistrationForm.has_embedded_phone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.useCaseRegistrationForm,
                          "has_embedded_phone",
                          $$v
                        )
                      },
                      expression: "useCaseRegistrationForm.has_embedded_phone",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Messages will include phone numbers.\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  prop: "message_restrictions",
                  label: "Message Restrictions",
                },
              },
              [
                _c("span", { staticClass: "page-anchor" }),
                _vm._v(" "),
                _c(
                  "el-checkbox",
                  {
                    attrs: { disabled: _vm.shouldDisableForm },
                    model: {
                      value: _vm.useCaseRegistrationForm.direct_lending,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.useCaseRegistrationForm,
                          "direct_lending",
                          $$v
                        )
                      },
                      expression: "useCaseRegistrationForm.direct_lending",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Message will include content related to Direct Lending or other Loan Arrangement.\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(
                    "High Risk Loans, Indirect Loans and Debt Collection are prohibited under A2P 10DLC Guidelines"
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "el-checkbox",
                  {
                    attrs: { disabled: _vm.shouldDisableForm },
                    model: {
                      value: _vm.useCaseRegistrationForm.age_gated,
                      callback: function ($$v) {
                        _vm.$set(_vm.useCaseRegistrationForm, "age_gated", $$v)
                      },
                      expression: "useCaseRegistrationForm.age_gated",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Messages will include age-gated content.\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(
                    "Sex, Hate, Alcohol, Firearms, Tobacco (SHAFT) and Cannabis content are prohibited under A2P 10DLC and "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "font-weight-bold text-blue",
                      attrs: {
                        target: "_blank",
                        href: "https://www.ctia.org/the-wireless-industry/industry-commitments/messaging-interoperability-sms-mms",
                      },
                    },
                    [_vm._v("CTIA Guidelines")]
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "mt-4",
                attrs: {
                  prop: "accepted_campaign_fee",
                  label: "One-time Per Campaign Fee",
                },
              },
              [
                _c("p", { staticClass: "form-label mb-0" }, [
                  _vm._v(
                    "\n                    A non-refundable one-time fee of $15 is charged to manually vet each A2P Campaign. The manual vetting process is an industry standard to ensure the quality of messages through A2P 10DLC registered channels.\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("el-checkbox", {
                  attrs: {
                    label: "I agree to a one-time non-refundable $15 fee.",
                    disabled: _vm.shouldDisableForm,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.preValidateForm("useCaseRegistrationForm")
                    },
                  },
                  model: {
                    value: _vm.useCaseRegistrationForm.accepted_campaign_fee,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.useCaseRegistrationForm,
                        "accepted_campaign_fee",
                        $$v
                      )
                    },
                    expression: "useCaseRegistrationForm.accepted_campaign_fee",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  prop: "agree_terms_and_conditions",
                  label: "Agree Terms and Conditions",
                },
              },
              [
                _c("span", { staticClass: "page-anchor" }),
                _vm._v(" "),
                _c("el-checkbox", {
                  attrs: {
                    label:
                      "I agree to the terms and conditions and monthly recurring $10 carrier fee.",
                    disabled: _vm.shouldDisableForm,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.preValidateForm("useCaseRegistrationForm")
                    },
                  },
                  model: {
                    value:
                      _vm.useCaseRegistrationForm.agree_terms_and_conditions,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.useCaseRegistrationForm,
                        "agree_terms_and_conditions",
                        $$v
                      )
                    },
                    expression:
                      "useCaseRegistrationForm.agree_terms_and_conditions",
                  },
                }),
                _vm._v(" "),
                _c("el-popover", {
                  ref: "agree_terms_and_conditions_learn_more",
                  attrs: {
                    placement: "right-start",
                    title: "Do you want to add an additional campaign?",
                    width: "400",
                    trigger: "hover",
                    content:
                      "The allowed message volume depends on the trust score of your business. Ranges can start from 2k - 10k per day. Your messages will be capped once you reach your daily limit. Limits may be increased as your brand score improves.",
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "popover",
                        rawName:
                          "v-popover:agree_terms_and_conditions_learn_more",
                        arg: "agree_terms_and_conditions_learn_more",
                      },
                    ],
                    staticClass: "form-hover-help",
                  },
                  [_vm._v("\n                    Learn more\n                ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row w-full" }, [
              _c(
                "div",
                { staticClass: "col-md-8 mb-3 mb-md-0 flex-3" },
                [
                  _c("alert-message", {
                    attrs: { "alert-message": _vm.alertMessage },
                    on: { alert: _vm.submitAlert },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-full flex-1" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "w-full",
                      attrs: {
                        type: "success",
                        loading: _vm.loading,
                        disabled: !_vm.validated || _vm.shouldDisableForm,
                      },
                      on: { click: _vm.openUseCaseRegistrationConfirmation },
                    },
                    [
                      _vm._v("\n                        Finish "),
                      _c("i", {
                        staticClass: "el-icon-arrow-right el-icon-right",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }