import * as Carriers from '../../constants/carriers'
import { Device as TwilioDevice } from '@twilio/voice-sdk'
import * as Events from '../../constants/webrtc-events'
import DeviceError from './twilio/error'
import Call from './twilio/call'

export default class Device {
    constructor(carrier) {
        this.carrier = carrier
        this._callbacks = {
            unregistered: [],
            registering: [],
            registered: [],
            incoming: [],
            error: [],
            tokenWillExpire: [],
        }
        this._device = null
        this._is_initialized = false
        this._is_registered = false
        this._first_init = true
    }

    _executeCallback(cb_name, args = []) {
        if (!this._callbacks[cb_name]) {
            this._callbacks[cb_name] = []
        }

        this._callbacks[cb_name].forEach(cb => {
            cb.apply(null, args)
        })
    }

    _createCall(call) {
        if (!call) {
            return undefined
        }
        return new Call(this, call)
    }

    initialize(token, deviceOptions = {}) {
        if (this._is_initialized) {
            return
        }
        this._device = (this.carrier === Carriers.TWILIO) ? new TwilioDevice(token, deviceOptions) : null
        this._initEvents()
        this._is_initialized = true
        // we need to register the device for at least one time,
        // so we can get the events on the device
        if (this._first_init) {
            this.register()
            this._first_init = false
        }
    }

    register() {
        if (!this._device) {
            return
        }
        this._device.register()
            .then(() => {
                this._is_registered = true
            })
            .catch((error) => {
                this._executeCallback(Events.ERROR, [error])
            })
    }

    on(event, handler) {
        this._callbacks[event].push(handler)
    }

    _initEvents() {
        this._device.on(Events.UNREGISTERED, () => {
            this._is_registered = false
            this._executeCallback(Events.UNREGISTERED, [])
        })
        this._device.on(Events.REGISTERING, () => {
            this._is_registered = false
            this._executeCallback(Events.REGISTERING, [])
        })
        this._device.on(Events.REGISTERED, () => {
            this._is_registered = true
            this._executeCallback(Events.REGISTERED, [this._device])
        })
        this._device.on(Events.TOKEN_WILL_EXPIRE, () => {
            this._executeCallback(Events.TOKEN_WILL_EXPIRE, [this._device])
        })
        this._device.on(Events.INCOMING, (call) => {
            this._executeCallback(Events.INCOMING, [this._createCall(call)])
        })
        this._device.on(Events.ERROR, (error, call) => {
            this._executeCallback(Events.ERROR, [new DeviceError(error), call])
        })
    }
}
