var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.voicemail_drop_files.length
            ? _c(
                "el-table",
                {
                  staticClass: "w-full mt-3",
                  attrs: { data: _vm.voicemail_drop_files, fit: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "Title",
                      prop: "name",
                      "min-width": 150,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.getExcludedVoicemailDropListDisplay(scope.row)
                                ? _c("span", [_vm._v(_vm._s(scope.row.name))])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.voicemail_drop_edit.editing &&
                              _vm.voicemail_drop_edit.data.id === scope.row.id
                                ? _c(
                                    "el-form",
                                    {
                                      ref: "vm_drop_edit_form",
                                      attrs: {
                                        autocomplete: "off",
                                        "label-position": "top",
                                        model: _vm.voicemail_drop_edit,
                                        rules: _vm.rules_edit,
                                      },
                                      nativeOn: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.updateVmDropFile(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass:
                                            "p-0 mb-0 border-bottom-0",
                                          attrs: { prop: "title" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "w-100",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Title",
                                              maxlength: 20,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.customPreValidateForm(
                                                  "vm_drop_edit_form"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.voicemail_drop_edit.title,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.voicemail_drop_edit,
                                                  "title",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "voicemail_drop_edit.title",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2349168579
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Audio", "min-width": 150 },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.uploaded_file
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-start align-items-center",
                                    },
                                    [
                                      _c("audio", { attrs: { controls: "" } }, [
                                        _c("source", {
                                          attrs: {
                                            src: `/static/uploaded_file/${scope.row.uploaded_file.uuid}`,
                                          },
                                        }),
                                        _vm._v(
                                          "\n                                Your browser does not support the audio element.\n                            "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      57740271
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Operation", "min-width": 150 },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.getExcludedVoicemailDropListDisplay(scope.row)
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm light-blue-500",
                                      attrs: {
                                        disabled: _vm.isViewOnlyAccess(),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.enableUpdateVoicemailDropFile(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-pencil" }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Edit")]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.voicemail_drop_edit.editing &&
                              _vm.voicemail_drop_edit.data.id === scope.row.id
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm greenish",
                                      attrs: {
                                        disabled:
                                          _vm.isViewOnlyAccess() ||
                                          _vm.voicemail_drop_edit.loading ||
                                          !_vm.edit_validated,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.updateVmDropFile(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.voicemail_drop_edit.loading
                                        ? _c("i", {
                                            staticClass:
                                              "fa fa-spin fa-spinner",
                                          })
                                        : _c("i", {
                                            staticClass: "fa fa-floppy-o",
                                          }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Save")]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.voicemail_drop_edit.editing &&
                              _vm.voicemail_drop_edit.data.id === scope.row.id
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm purple-500 ml-2",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.disableUpdateVoicemailDropFile.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-times" }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Cancel")]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm danger ml-2",
                                  attrs: {
                                    disabled:
                                      _vm.isViewOnlyAccess() ||
                                      _vm.voicemail_drop_file_delete.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.deleteVmDropFile(scope.row.id)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.voicemail_drop_file_delete.id ===
                                            scope.row.id &&
                                          _vm.voicemail_drop_file_delete
                                            .loading,
                                        expression:
                                          "voicemail_drop_file_delete.id === scope.row.id && voicemail_drop_file_delete.loading",
                                      },
                                    ],
                                    staticClass: "fa fa-spin fa-spinner",
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.voicemail_drop_file_delete.id !==
                                          scope.row.id,
                                        expression:
                                          "voicemail_drop_file_delete.id !== scope.row.id",
                                      },
                                    ],
                                    staticClass: "fa fa-trash",
                                  }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Remove")]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2873043199
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tab-content pt-2 border border-top-0" },
                [
                  _c("div", { staticClass: "my-3 mx-3" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "mx-3 tab-pane animated fadeIn text-muted active",
                        attrs: {
                          id: "voicemail-drop-file",
                          "aria-expanded": "true",
                        },
                      },
                      [
                        _c(
                          "el-form",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            ref: "vm_drop_form",
                            attrs: {
                              autocomplete: "off",
                              "label-position": "top",
                              model: _vm.voicemail_drop_file_upload,
                              rules: _vm.rules_upload,
                            },
                            nativeOn: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.postVmDropFile.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Title",
                                  prop: "vm_drop_file_title",
                                },
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-6" },
                                    [
                                      _c("el-input", {
                                        staticClass: "width-200",
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.isViewOnlyAccess(),
                                          maxlength: 20,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.customPreValidateForm(
                                              "vm_drop_form"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.voicemail_drop_file_upload
                                              .vm_drop_file_title,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.voicemail_drop_file_upload,
                                              "vm_drop_file_title",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "voicemail_drop_file_upload.vm_drop_file_title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                !_vm.recording_selected
                                  ? _c("audio-recorder", {
                                      staticClass: "audio-recorder-wrapper",
                                      attrs: {
                                        upload_text: "Select",
                                        action:
                                          "/api/v1/user/pre-recorded-voicemail",
                                        upload_type: "voicemail_file",
                                      },
                                      on: {
                                        applyUploadedAudio:
                                          _vm.applyUploadedLiveAudio,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "audio-uploader-wrapper" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "mb-0",
                                        attrs: { prop: "data" },
                                      },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            attrs: {
                                              drag: "",
                                              action:
                                                "/api/v1/user/pre-recorded-voicemail",
                                              limit: 1,
                                              disabled: _vm.isViewOnlyAccess(),
                                              headers: _vm.headers,
                                              "on-success":
                                                _vm.onSuccessUploadPrerecordedVoicemail,
                                              "on-error":
                                                _vm.onFailedUploadPrerecordedVoicemail,
                                              "on-progress":
                                                _vm.progressUploadPrerecordedVoicemail,
                                              "before-upload":
                                                _vm.beforeUploadPrerecordedVoicemail,
                                              "on-remove":
                                                _vm.onPreRecordedFileRemove,
                                              "file-list":
                                                _vm.uploadFileList.voicemail,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-upload",
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "el-upload__text",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                            Drop file here or\n                                                            "
                                                ),
                                                _c("em", [
                                                  _vm._v("click to upload"),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "el-upload__tip",
                                                attrs: { slot: "tip" },
                                                slot: "tip",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                            MP3/WAV file (less than 8MB)\n                                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.voicemail_drop_file_upload.data ==
                                        null
                                          ? _c("el-progress", {
                                              attrs: {
                                                "text-inside": true,
                                                "stroke-width": 18,
                                                percentage:
                                                  _vm.uploadPercentage
                                                    .voicemail,
                                                status:
                                                  _vm.uploadStatus.voicemail,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row mt-3" }, [
                                      _c("div", { staticClass: "col-12" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn greenish pull-right",
                                            attrs: {
                                              disabled:
                                                _vm.isViewOnlyAccess() ||
                                                !_vm.add_validated ||
                                                _vm.voicemail_drop_file_upload
                                                  .loading,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.postVmDropFile.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm.voicemail_drop_file_upload
                                              .loading
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-spin fa-spinner",
                                                })
                                              : _c("i", {
                                                  staticClass: "fa fa-plus",
                                                }),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("Add")]),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "b-b nav-active-greenish" }, [
      _c("ul", { staticClass: "nav nav-tabs" }, [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "border rounded-top nav-link active",
              attrs: {
                href: "#",
                "data-toggle": "tab",
                "data-target": "#voicemail-drop-file",
                "aria-expanded": "false",
              },
            },
            [
              _c("i", { staticClass: "fa fa-upload text-md" }),
              _vm._v(
                "\n                                        Upload Recording\n                                    "
              ),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }