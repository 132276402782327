var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "h-100",
      class: ["summary-card-" + _vm.status],
      attrs: { "data-testid": "summary-report-unreturned-wrapper" },
    },
    [
      _c("a", { staticClass: "w-100 cursor-initial", attrs: { href: "#" } }, [
        _c(
          "div",
          {
            staticClass:
              "card summary-card-hover h-100 border-0 shadow-none bg-transparent",
          },
          [
            _c("div", { staticClass: "w-100 p-1 pl-3" }, [
              _c(
                "div",
                { staticClass: "d-inline-block align-bottom" },
                [
                  _vm.img_src
                    ? _c("img", {
                        attrs: {
                          "data-testid": "summary-report-unreturned-img",
                          src: _vm.img_src,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.icons, function (icon) {
                    return _vm.icons.length
                      ? [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              class: { "img-with-icon": _vm.img_src },
                              staticStyle: { color: "#03c04c" },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(icon) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e()
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-inline-block pl-2" }, [
                _c("strong", { staticClass: "text-md title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center pt-4 w-100" },
              [
                _vm._l(_vm.data, function (item, index) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "mt-2 countup-hover",
                        class: _vm.classesForMultipleAgreggateData(item),
                        attrs: {
                          "data-testid": "summary-report-unreturned-card",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.filtering()
                          },
                        },
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "m-a-0 mb-2 text-lg metric-value" },
                          [
                            typeof item.count !== "undefined" &&
                            item.count !== null
                              ? _c("ICountUp", {
                                  ref: "countup-" + index,
                                  refInFor: true,
                                  attrs: {
                                    duration: _vm.count_up.duration,
                                    endVal: parseInt(item.count),
                                    options: _vm.countUpOptions(index),
                                    startVal: _vm.count_up.startVal,
                                    "data-testid":
                                      "summary-report-unreturned-countup",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            item.count === null
                              ? _c("span", [
                                  _vm._v(
                                    "\n                                -\n                            "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "small",
                          {
                            staticClass:
                              "text-muted text-sm sms-text metric-label",
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.label) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }