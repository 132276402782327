export const USE_CASE_SALES = 1
export const USE_CASE_QUESTION_AND_ANSWER = 2
export const USE_CASE_SUPPORT = 3

export const MODEL_GPT_35_T = 1
export const MODEL_GPT_35_T_1106 = 2
export const MODEL_GPT_4_1106_PREVIEW = 3
export const MODEL_GPT_4 = 4
export const MODEL_GPT_4_T = 5
export const MODEL_GPT_4O = 6
export const MODEL_GPT_4O_MINI = 7
