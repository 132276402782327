var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "transition-show": "scale",
        "transition-hide": "scale",
        persistent: "",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "el-card",
        {
          staticClass: "bg-white text-black text-center pt-lg",
          staticStyle: { width: "635px" },
        },
        [
          _c("div", [
            _c("div", { staticClass: "text-h5" }, [
              _vm._v("\n        Welcome!! 🎉\n      "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-h6 pt-lg" }, [
              _vm._v(
                "\n        Looks like you already have an account with us. 🎉\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-h6 pt-lg" }, [
              _vm._v(
                "\n        Please login to your account to continue. 🚀\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex justify-content-center bg-white pb-lg",
              attrs: { "text-align": "center" },
            },
            [
              _c("el-button", {
                directives: [{ name: "close-popup", rawName: "v-close-popup" }],
                attrs: {
                  label: "Go to Login",
                  color: "primary",
                  "text-color": "white",
                  rounded: "",
                },
                on: { click: _vm.openLoginPage },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }