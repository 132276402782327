<template>
    <el-popover placement="right-start"
                width="350"
                trigger="click"
                @show="resetForm">
        <el-form>
            <h6 class="mb-2 text-black">Convert to List</h6>
            <el-form-item class="mb-3">
                <el-tabs v-model="tab_name">
                    <!-- antes User -->
                    <el-tab-pane label="User List"
                                 name="user_list">
                        <p class="lh-1p4 mb-2 text-dark">Convert into a list of contacts for this user</p>
                        <user-selector ref="userSelector"
                                       clearable
                                       :hide_extensions="true"
                                       v-model="user_id"
                                       @change="changeUserId">
                        </user-selector>
                    </el-tab-pane>
                    <!-- antes RG -->
                    <el-tab-pane label="Public List"
                                 name="public_list">
                        <p class="lh-1p4 mb-2 text-dark">Convert into a public list of contacts</p>
                    </el-tab-pane>
                </el-tabs>
            </el-form-item>

            <div>
                <el-button type="success"
                           size="small"
                           class="w-full"
                           :disabled="unassignable"
                           @click="convertTag">
                    <i class="fas fa-address-book-alt"></i> Convert
                </el-button>
            </div>
        </el-form>
        <button class="btn btn-block btn-dark-warning text-white btn-sm"
                slot="reference"
                :disabled="!enabled">
            <i class="fas fa-address-book pull-left"></i>
            <slot>Convert to List</slot>
        </button>
    </el-popover>
</template>

<script>
import auth from "../auth"
import RingGroupSelector from "../components/ring-group-selector"

export default {
    name: "convert-to-contacts-list",

    props: {
        tag: {
            type: Object,
            required: true
        },
        enabled: {
            type: Boolean,
            required: true,
            default: true
        }
    },

    components: {
        RingGroupSelector
    },

    data() {
        return {
            auth: auth,
            tab_name: 'user_list',
            user_id: null,
            force: false
        }
    },

    computed: {
        model() {
            return {
                'user_list': 'user list',
                'public_list': 'public list'
            }[this.tab_name]
        },

        unassignable() {
            return (this.tab_name === 'user_list' && !this.user_id)
        }
    },

    methods: {
        convertTag() {
            this.$confirm(`Are you sure you want the contacts under this tag to be part of a new ${this.model}?`, 'Event', {
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: 'warning',
                customClass: 'width-500 fixed'
            }).then(() => {
                const list_type = this.tab_name
                const show_in_public_folder = list_type === 'public_list' ? 1 : 0

                const data = {
                    source_tag_id: this.tag.id,
                    name: this.tag.name,
                    user_id: list_type === 'user_list' ? this.user_id : null,
                    show_in_public_folder: show_in_public_folder
                }

                axios.post(`/api/v2/contacts-list/convert`, data).then(res => {
                    const list = res.data.data
                    list.show_in_public_folder = show_in_public_folder
                    this.$emit('list-converted', list)
                    this.resetForm()
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    console.log(err)
                })
            })
        },

        changeUserId(user_id) {
            this.user_id = user_id
            this.tab_name = 'user_list'
        },

        resetForm() {
            this.list_name = ''
            this.user_id = null
            this.tab_name = 'user_list'
        }
    },
}
</script>
