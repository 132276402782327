<template>
    <div class="filter-attribution"
         v-on-clickaway="closeLineList">
        <el-badge type="success"
                  class="filter-counter"
                  :value="lines.length"
                  :hidden="lines.length === 0">
            <el-button size="small"
                       round
                       plain
                       @click="toggleLineList">
                Lines
            </el-button>
        </el-badge>
        <div class="filter-attribution-data-list"
             v-if="show_line_list">
            <div class="d-flex flex-row justify-content-end mb-2">
                <el-button size="small"
                           class="border-0" @click="closeLineList">
                    Close
                </el-button>
            </div>
            <campaign-selector class="w-100 filter-select"
                               place-holder="Filter lines"
                               :multiple="true"
                               :value="lines"
                               @change="setTempLines">
            </campaign-selector>
            <div class="d-flex flex-row justify-content-between mt-4">
                <el-button type="default" size="small" round @click="clear">
                    Clear
                </el-button>
                <el-button type="success" size="small" round @click="apply">
                    Apply
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import DashboardFilter from '../../../filters/dashboard-filter'
import {mixin as clickaway} from 'vue-clickaway'

export default {
    mixins: [clickaway],
    data() {
        return {
            show_line_list: false,
            temp_lines: []
        }
    },

    computed: {
        lines() {
            return DashboardFilter.lines()
        }
    },

    methods: {
        toggleLineList() {
            this.show_line_list = !this.show_line_list
        },

        clear() {
            this.temp_lines = []
            this.apply()
        },

        apply() {
            DashboardFilter.setLines(this.temp_lines)
            this.show_line_list = false
        },

        setTempLines(lines) {
            this.temp_lines = lines
        },

        closeLineList() {
            this.show_line_list = false
        }
    }
}
</script>
<style lang="scss">
    .filter-attribution {
        position: relative;
        z-index: 901;
    }

    .filter-counter sup.el-badge__content {
        top: 3px !important;
        right: 14px !important;
    }

    .filter-attribution-data-list {
        padding: 1rem;
        border: solid 1px #ddd;
        border-radius: 5px;
        position: absolute;
        top: 40px;
        left: 0;
        background-color: white;
        width: 20rem;
    }
</style>
