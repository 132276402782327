var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body bg-auto h-v w-full clear" }, [
    _c(
      "div",
      {
        staticClass:
          "row d-flex justify-content-center align-items-center h-full",
      },
      [
        _c("div", { staticClass: "col-md-4 col-12 text-center my-auto" }, [
          _c("a", { staticClass: "navbar-brand mx-auto mb-5 d-block" }, [
            !_vm.loading_whitelabel
              ? _c("img", { attrs: { src: _vm.statics.logo, alt: "." } })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "img-responsive mx-auto",
            staticStyle: { "max-width": "200px" },
            attrs: { src: "/assets/images/404.png" },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "h5 m-y-lg font-bold text-greyish" }, [
            _vm._v("Sorry! the page you are looking for doesn't exist."),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn greenish p-x-md width-200 mx-auto",
              attrs: { href: "/" },
            },
            [_vm._v("Go to the Dashboard")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }