<template>
    <el-dialog
        width="35vw"
        top="10vh"
        append-to-body
        title="Import From HubSpot List"
        :before-close="closeHubSpotListImport"
        :visible.sync="show_dialog">

        <!-- WAT-876: deprecation banner -->
        <al-alert>
            <span class="mr-5 text-dark break-word" 
                  v-html="TAGS_DEPRECATION_IMPORT_CONTACTS_MESSAGE" />
        </al-alert>

        <div v-loading="loading">
            <el-form
                ref="import_hubspot_form"
                class="mt-2 p-0"
                label-position="top"
                :rules="rules"
                :model="hubspot_list_form"
                @submit.prevent.native="importHubspotList">
                <el-form-item
                    prop="list_id"
                    label="Select a HubSpot list"
                    class="mb-3">
                    <hubspot-list-selector
                        :value.sync="hubspot_list_form.list_id"
                        ref="hubspotListSelector">
                    </hubspot-list-selector>
                </el-form-item>
            </el-form>

            <div class="container-footer d-flex mb-0 mt-5">
                <el-button
                    size="small"
                    @click="closeHubSpotListImport">
                    Close
                </el-button>

                <el-button
                    @click="checkIfHubSpotListAlreadyExist"
                    :disabled="disableImportButton"
                    type="success"
                    size="small"
                    class="ml-auto"
                    :loading="checking">
                    Import
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import HubspotListSelector from '../hubspot-list-selector'
    import Alert from '../alert'
    import { TAGS_DEPRECATION_IMPORT_CONTACTS_MESSAGE } from '../../constants/deprecation.messages';

    export default {
        name: "admin-list-import-from-hubspot-list",

        components: {
            HubspotListSelector,
            'al-alert': Alert
        },

        data() {
            return {
                loading: false,
                show_dialog: false,
                checking: false,
                hubspot_list_form: {
                    list_id: null
                },
                rules: {
                    list_id: {required: true, message:'Please select a HubSpot list.' , trigger: 'change'}
                },
                TAGS_DEPRECATION_IMPORT_CONTACTS_MESSAGE
            }
        },

        computed: {
            disableImportButton() {
                return !this.hubspot_list_form.list_id
            }
        },

        methods: {
            checkIfHubSpotListAlreadyExist() {
                this.checking = true

                this.$APIV2.ContactList.checkListNameIfExist(this.hubspot_list_form.list_id)
                .then(res => {
                    this.checking = false
                    if (!res.data.exists) {
                        this.importHubspotList()
                    } else {
                        return this.contactExistsConfirmation(res.data.contact_list)
                    }
                })
                .catch(err => {
                    this.checking = false
                    console.log(err)
                })
            },

            importHubspotList() {
                this.loading = true

                this.$APIV2.ContactList.importFromHubspotList(this.hubspot_list_form.list_id)
                .then(res => {
                    this.$notify({
                        offset: 75,
                        title: 'Contact List',
                        message: res.data.message,
                        type: 'success',
                        showClose: true,
                    })

                    this.$emit('importStarted')

                    this.closeHubSpotListImport()
                })
                .catch(err => {
                    this.$notify({
                        offset: 75,
                        title: 'Contact List',
                        message: err.response.data.message,
                        type: 'error',
                        showClose: true,
                    })

                    this.closeHubSpotListImport()
                })
            },

            closeHubSpotListImport() {
                this.loading = false
                this.hubspot_list_form.list_id = null

                if (this.$refs.import_hubspot_form) {
                    // set a delay so it will conflict when we clear the field's data
                    setTimeout(_ => {
                        this.$refs.import_hubspot_form.clearValidate()
                    }, 200)
                }

                this.show_dialog = false
            },

            contactExistsConfirmation(contact_list) {
                let display = contact_list.name

                this.$confirm(
                    `To prevent the current list from being replaced, click <b>Cancel</b> and rename this list before importing.`,
                    `${display} already exists. Importing ${display} from HubSpot will replace this current list.`,
                    {
                        confirmButtonText: `Replace`,
                        cancelButtonText: 'Cancel',
                        customClass: 'width-500 fixed custom-confirmation-dialog-class',
                        confirmButtonClass: 'el-button--default w-50',
                        cancelButtonClass: 'el-button--default w-50',
                        dangerouslyUseHTMLString: true,
                    }
                ).then(() => {
                    this.importHubspotList()
                }).catch(() => {})
            },
        }
    }
</script>
