var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasTimestamps
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "min-height-40-vh max-height-60-vh scroll-y",
          },
          [
            _c(
              "el-timeline",
              [
                _vm.import_contact
                  ? _c(
                      "el-timeline-item",
                      {
                        attrs: {
                          color: "#00A344",
                          size: "normal",
                          timestamp: _vm._f("fixDateTime")(
                            _vm.import_contact.created_at
                          ),
                        },
                      },
                      [_vm._v("\n                Created\n            ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.import_timestamps, function (record) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: record.id,
                      attrs: {
                        color: record.status_color,
                        size: "normal",
                        timestamp: _vm._f("fixDateTime")(record.logged_at),
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(record.status_name) +
                          "\n            "
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ],
          1
        )
      : _c("div", { staticClass: "el-table__empty-block" }, [
          _c(
            "div",
            {
              staticClass:
                "text-center el-table__empty-text import-log-empty-text",
            },
            [
              _c("img-empty", { staticClass: "mx-auto w-100" }),
              _vm._v(" "),
              _c("div", [_vm._v("No timeline for this import.")]),
              _vm._v(" "),
              _c("small", { staticClass: "text-italic import-log-note" }, [
                _vm._v(
                  "Import status timeline starts at October 15, 2021. Previous imports will not show any status timeline."
                ),
              ]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }