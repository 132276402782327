<template>
    <div class="ml-4 mt-2">
        <div class="d-flex justify-content-end mb-3">
            <el-button type="primary" title="Add instruction" icon="el-icon-plus" size="small" plain circle @click="dialogVisible = true"></el-button>
        </div>
        <div class="instructions-card" v-if="instructions.length > 0">
            <el-table :data="instructions" style="width: 100%">
                <el-table-column prop="role" label="Role"></el-table-column>
                <el-table-column prop="content" label="Content"></el-table-column>
                <el-table-column width="100">
                    <template v-slot="scope">
                        <el-button plain circle icon="el-icon-edit" size="mini" @click="openEditDialog(scope.$index, scope.row)"></el-button>
                        <el-button plain circle icon="el-icon-delete" size="mini" type="danger" @click="confirmDelete(scope.$index)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-tooltip content="Show JSON output" placement="top" v-if="instructions.length > 0">
            <el-switch
                v-model="show_code"
                active-color="#13ce66"
                :active-value="true"
                :inactive-value="false">
            </el-switch>
        </el-tooltip>
        <pre class="mt-3" v-if="show_code">{{ instructions }}</pre>
        <el-dialog
            :visible.sync="dialogVisible"
            :title="`${isEditMode ? 'Edit' : 'Add'} Instruction`"
            class="container-body pb-4"
            width="50%"
            append-to-body>
            <el-form :model="currentInstruction">
                <el-form-item label="Role">
                    <el-input v-model="currentInstruction.role"></el-input>
                </el-form-item>
                <el-form-item label="Content">
                    <el-input v-model="currentInstruction.content" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="saveInstruction">Save</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                instructions: [],
                dialogVisible: false,
                currentInstruction: { role: '', content: '' },
                isEditMode: false,
                editIndex: -1,
                show_code: false
            };
        },
        methods: {
            openEditDialog(index, row) {
                this.currentInstruction = { ...row };
                this.dialogVisible = true;
                this.isEditMode = true;
                this.editIndex = index;
            },
            saveInstruction() {
                if (this.isEditMode) {
                    this.$set(this.instructions, this.editIndex, { ...this.currentInstruction });
                } else {
                    this.instructions.push({ ...this.currentInstruction });
                }
                this.resetDialog();
                this.$emit('handdle-instructions', this.instructions)
            },
            confirmDelete(index) {
                this.$confirm('Are you sure you want to delete this instruction?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.instructions.splice(index, 1);
                    this.$emit('handdle-instructions', this.instructions)
                }).catch(() => {
                    // Handle cancel
                });
            },
            resetDialog() {
                this.dialogVisible = false;
                this.currentInstruction = { role: '', content: '' };
                this.isEditMode = false;
                this.editIndex = -1;
            }
        }
    };
</script>
<style scoped>
.instructions-card{
    border: 1px solid #ebeef5;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
}
</style>
