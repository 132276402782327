<template>
    <div class="wizard" 
         data-testid="campaign-wizard-wrapper">
        <div class="wizard-header" 
             data-testid="wizard-header">
            <h2 v-if="success">Congratulations!</h2>
            <h2 v-if="!success && !is_fax">New Line</h2>
            <h2 v-if="!success && is_fax">New Fax</h2>
        </div>

        <div class="row">
            <div class="col-sm-10 col-8">
                <el-progress :percentage="percentage"
                             :show-text="false"
                             :stroke-width="9"
                             data-testid="wizard-el-progress"
                             status="success"
                             style="margin-top: 4px">
                </el-progress>
            </div>
            <div class="col-sm-2 col-4">
                <small class="text-muted" 
                       data-testid="wizard-percentage">
                    <span>{{ percentage }}% </span>
                    Complete
                </small>
            </div>
        </div>

        <div id="step_start" 
             data-testid="wizard-steps"
             v-if="step == 'start'">
            <div class="row mt-4" 
                 data-testid="wizard-purpose">
                <div class="col-12 text-center">
                    <p class="form-head-message">What is the purpose of this line?</p>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 text-center">
                            <img src="/assets/images/Contact-Center.png"
                                 data-testid="contact-center-image"
                                 class="w-300 pointer"
                                 @click="setContactCenter">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-center">
                            <el-button type="primary"
                                       data-testid="contact-center-button"
                                       @click="setContactCenter"
                                       plain>
                                Contact Center
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 text-center">
                            <img src="/assets/images/Call-Tracking.png"
                                 class="w-300 pointer"
                                 data-testid="call-tracking-image"
                                 @click="setCallTracking">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-center">
                            <el-button type="warning"
                                       data-testid="call-tracking-button"
                                       @click="setCallTracking"
                                       plain>
                                Call Tracking
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="step_0" 
             data-testid="wizard-step-0"
             v-if="step == 0">
            <div class="row mt-4" 
                 data-testid="tracking-phone-number">
                <div class="col-12 text-center">
                    <p class="form-head-message">Will you use this tracking phone number online?</p>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 text-center">
                            <img src="/assets/images/item-add-number-online.png"
                                 class="w-200 r-2x _600 pointer"
                                 data-testid="online-image"
                                 @click="setOnline">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-center">
                            <el-button type="success"
                                       data-testid="online-button"
                                       @click="setOnline"
                                       plain>
                                Yes, I'll use it online
                            </el-button>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 text-center">
                            <small class="text-muted">
                                Your website, PPC ads, Social Media Referrals
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 text-center">
                            <img src="/assets/images/item-add-number-offline.png"
                                 data-testid="offline-image"
                                 class="w-200 r-2x _600 pointer"
                                 @click="setOffline">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-center">
                            <el-button type="success"
                                       data-testid="set-offline-button"
                                       @click="setOffline"
                                       plain>
                                No, I'll use it offline
                            </el-button>
                        </div>
                    </div>
                    <div class="row mt-2" 
                         data-testid="tv-radio-print">
                        <div class="col-12 text-center">
                            <small class="text-muted">
                                On TV, Radio, Print Ads, Billboards
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <div class="text-right mt-4">
                        <el-button class="pull-left"
                                   icon="el-icon-arrow-left"
                                   type="info"
                                   data-testid="back-button"
                                   @click="backForm">
                            Back
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <div id="step_1_2" 
             data-testid="wizard-step-1-2"
             v-if="step == 1.2">
            <div class="row mt-4" 
                data-testid="display-tracking-number">
                <div class="col-12 text-center">
                    <p class="form-head-message">Where will you display this tracking number?</p>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 text-center">
                            <img src="/assets/images/item-add-number-your-website.png"
                                 class="w-200 r-2x _600 pointer"
                                 data-testid="your-website-image"
                                 @click="setOnlineOur">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-center">
                            <el-button type="success"
                                       data-testid="your-website-button"
                                       @click="setOnlineOur"
                                       plain>
                                On my website
                            </el-button>
                        </div>
                    </div>
                    <div class="row mt-2" 
                         data-testid="provide-code">
                        <div class="col-12 text-center">
                            <small class="text-muted">We'll provide the code to install call tracking.</small>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 text-center">
                            <img src="/assets/images/item-add-number-other-website.png"
                                 class="w-200 r-2x _600 pointer"
                                 data-testid="other-website-image"
                                 @click="setOnlineOther">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-center">
                            <el-button type="success"
                                       data-testid="other-website-button"
                                       @click="setOnlineOther"
                                       plain>
                                Somewhere else
                            </el-button>
                        </div>
                    </div>
                    <div class="row mt-2" 
                         data-testid="adwords-email">
                        <div class="col-12 text-center">
                            <small class="text-muted">Google AdWords, Email signatures, and more</small>
                        </div>
                    </div>
                </div>
                <div class="text-right mt-4">
                    <el-button class="pull-left"
                               icon="el-icon-arrow-left"
                               type="info"
                               data-testid="back-button"
                               @click="backForm">
                        Back
                    </el-button>
                </div>
            </div>
        </div>

        <div id="step_1_5" 
             data-testid="wizard-step-1-5"
             v-if="step == 1.5">
            <div class="row mt-4" 
                 data-testid="track-on-your-website">
                <div class="col-12 text-center">
                    <p class="form-head-message">What do you want to track on your website?</p>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 text-center">
                            <span class="w-200 r-2x _600 blue"
                                  data-testid="visitor-type-1-span"
                                  @click="setOnlineType(1)">
                                Visitor
                            </span>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-center">
                            <el-button type="success"
                                       data-testid="visitor-type-1-button"
                                       @click="setOnlineType(1)"
                                       plain>
                                Track visitor activity
                            </el-button>
                        </div>
                    </div>
                    <div class="row mt-2" 
                         data-testid="create-8-phone-numbers">
                        <div class="col-12 text-center">
                            <small class="text-muted">
                                Create 8+ phone numbers that correlate calls to website visitors, PPC keywords, and more
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 text-center">
                            <span class="w-200 r-2x _600 purple"
                                  data-testid="visitor-type-2-span"
                                  @click="setOnlineType(2)">
                                Campaign
                            </span>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-center">
                            <el-button type="success"
                                       data-testid="visitor-type-2-button"
                                       @click="setOnlineType(2)"
                                       plain>
                                Track a marketing campaign
                            </el-button>
                        </div>
                    </div>
                    <div class="row mt-2" 
                         data-testid="create-one-phone-number">
                        <div class="col-12 text-center">
                            <small class="text-muted">
                                Create one phone number that tracks all callers to single marketing campaign.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="text-right mt-4">
                    <el-button class="pull-left"
                               data-testid="back-button"
                               icon="el-icon-arrow-left"
                               type="info"
                               @click="backForm">
                        Back
                    </el-button>
                </div>
            </div>
        </div>

        <div id="step_2"
             data-testid="wizard-step-2"
             v-if="step == 2">
            <el-form ref="campaign_info"
                     label-position="top"
                     label-width="300px"
                     data-testid="campaign-info-form"
                     :rules="rules_2"
                     :model="campaign">
                <template v-if="is_fax">
                    <p class="form-head">
                        Name
                    </p>
                </template>
                <template v-else>
                    <p class="form-head"
                       v-if="campaign.type == TYPE_OFFLINE || (campaign.type == TYPE_ONLINE && (campaign.subtype == SUBTYPE_ONLINE_MARKETING || campaign.subtype == SUBTYPE_ONLINE_EXTERNAL))">
                        Name, Ring Group and Call Routing
                    </p>
                    <p class="form-head"
                       v-else>
                        Pool Name, Pool Size & Ring Group
                    </p>
                </template>
                <div class="dropdown-divider"></div>
                <el-form-item
                        data-testid="campaign-info-name-item"
                        :label="(campaign.type == TYPE_ONLINE && campaign.subtype == SUBTYPE_ONLINE_VISITORS) ? 'Pool Name' : 'Name'"
                        prop="name">
                    <em class="text-muted"
                        v-if="is_fax">
                        Example: Office Fax
                    </em>
                    <template v-if="!is_fax">
                        <em class="text-muted"
                            v-if="campaign.type == TYPE_OFFLINE && purpose == 'Call Tracking'">
                            Example: Radio Ad
                        </em>
                        <em class="text-muted"
                            v-if="campaign.type == TYPE_OFFLINE && purpose == 'Contact Center'">
                            Example: Local Support Number
                        </em>
                        <em class="text-muted"
                            v-if="campaign.type == TYPE_ONLINE && campaign.subtype == SUBTYPE_ONLINE_EXTERNAL">
                            Example: External
                        </em>
                        <em class="text-muted"
                            v-if="campaign.type == TYPE_ONLINE && campaign.subtype == SUBTYPE_ONLINE_VISITORS">
                            Example: Visitor Tracking
                        </em>
                        <em class="text-muted"
                            v-if="campaign.type == TYPE_ONLINE && campaign.subtype == SUBTYPE_ONLINE_MARKETING">
                            Example: Facebook referral
                        </em>
                    </template>
                    <div class="row">
                        <div class="col-md-8 col-12">
                            <el-input v-model="campaign.name"
                                      data-testid="campaign-info-name-input"
                                      @input="customPreValidateForm">
                            </el-input>
                        </div>
                        <div class="col-md-4">
                            <el-popover
                                    ref="popover_name"
                                    placement="right-start"
                                    title="Line Name"
                                    width="200"
                                    trigger="hover"
                                    data-testid="popover-line-name"
                                    content="Use a name that is short and easily identifiable in your reports.">
                            </el-popover>
                            <span class="form-hover-help"
                                  v-popover:popover_name>
                                What should I name this?
                            </span>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item label="Pool Size"
                              prop="pool_size"
                              data-testid="campaign-info-pool-size-item"
                              v-if="campaign.type == TYPE_ONLINE && campaign.subtype == SUBTYPE_ONLINE_VISITORS">
                    <div class="row">
                        <div class="col-md-8 col-12 pt-2">
                            Add
                            <el-input-number v-model="campaign.pool_size"
                                             size="mini"
                                             data-testid="campaign-info-pool-size-input"
                                             :min="1">
                            </el-input-number>
                            new numbers.
                        </div>
                        <div class="col-md-4 col-12">
                            <el-popover
                                    ref="popover_size"
                                    placement="right-start"
                                    title="Line Pool Size"
                                    width="200"
                                    trigger="hover"
                                    data-testid="popover-line-pool-size"
                                    content="Each visitor will be assigned a unique number for 30 minutes. If you expect a higher visit rate, choose a larger pool size.">
                            </el-popover>
                            <span class="form-hover-help"
                                  data-testid="pool-size-help"
                                  v-popover:popover_size>
                                How do I select a pool size?
                            </span>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item v-if="!is_fax"
                              prop="call_router_behavior"
                              data-testid="campaign-info-call-router-behavior-item"
                              label="Call Routing">
                    <el-popover placement="right-start"
                                :title="CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD | humanReadableCallRouterBehavior"
                                width="400"
                                trigger="hover"
                                data-testid="popover-fast-forward"
                                content="Connect calls without a queue (best for call tracking)."
                                ref="popover_fast_forward">
                    </el-popover>

                    <el-popover placement="right-start"
                                :title="CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END | humanReadableCallRouterBehavior"
                                width="400"
                                trigger="hover"
                                data-testid="popover-dead-end"
                                content="Do not connect calls & hangup after playing greeting (not recommended)."
                                ref="popover_dead_end">
                    </el-popover>

                    <el-radio-group v-model="campaign.call_router_behavior"
                                    data-testid="campaign-info-call-router-behavior-radio"
                                    size="small">
                        <el-radio-button :label="CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD"
                                         data-testid="fast-forward-radio"
                                         v-popover:popover_fast_forward>
                            {{ CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD |
                            humanReadableCallRouterBehavior }}
                        </el-radio-button>
                        <el-radio-button :label="CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END"
                                         data-testid="dead-end-radio"
                                         v-popover:popover_dead_end>
                            {{ CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END |
                            humanReadableCallRouterBehavior }}
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="Ring Group"
                              prop="ring_group_id"
                              data-testid="campaign-info-ring-group-item"
                              v-if="campaign.call_router_behavior && campaign.call_router_behavior !== CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END"
                              class="mb-4">
                    <div class="row"
                         v-show="!hide_add">
                        <div class="col-md-8 col-12">
                            <ring-group-selector
                                    data-testid="campaign-info-ring-group-selector"
                                    v-if="hasPermissionTo('list ring group')"
                                    v-model="campaign.ring_group_id"
                                    :default_first="true"
                                    style="width: 300px"
                                    @change="changeRingGroup">
                            </ring-group-selector>
                        </div>
                        <div class="col-md-4 col-12">
                            <el-popover
                                    ref="popover_ring_group_id"
                                    placement="right-start"
                                    title="Ring Group"
                                    width="200"
                                    trigger="hover"
                                    data-testid="popover-ring-group"
                                    content="Where to deliver the call when a customer rings.">
                            </el-popover>
                            <span class="form-hover-help"
                                  v-popover:popover_ring_group_id>
                                Which ring group do I use?
                            </span>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item label="Dial Mode"
                              prop="dial_mode"
                              data-testid="campaign-info-dial-mode-item"
                              v-if="campaign.call_router_behavior && campaign.call_router_behavior !== CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END">
                    <div class="row">
                        <div class="col-md-8 col-12">
                            <el-radio-group v-model="campaign.dial_mode"
                                            size="small"
                                            data-testid="campaign-info-dial-mode-radio">
                                            class="no-select mt-2">
                                <el-radio-button :label="DIAL_MODE_SIMUL">
                                    {{ DIAL_MODE_SIMUL | humanReadableDialMode }}
                                </el-radio-button>
                                <el-radio-button :label="DIAL_MODE_RAND">
                                    {{ DIAL_MODE_RAND | humanReadableDialMode }}
                                </el-radio-button>
                                <el-radio-button :label="DIAL_MODE_ROUND">
                                    {{ DIAL_MODE_ROUND | humanReadableDialMode }}
                                </el-radio-button>
                                <el-radio-button :label="DIAL_MODE_LONGEST_AVAILABLE">
                                    {{ DIAL_MODE_LONGEST_AVAILABLE | humanReadableDialMode }}
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                        <div class="col-md-4">
                            <el-popover
                                    ref="popover_dial_mode"
                                    placement="right-start"
                                    title="Dial Modes"
                                    width="200"
                                    trigger="hover"
                                    data-testid="popover-dial-mode"
                                    content="Choose how your calls are routed to the users in your ring group. You can change this settings after creating your campaign.">
                            </el-popover>
                            <span class="form-hover-help"
                                  v-popover:popover_dial_mode>
                                What are dial modes?
                            </span>
                        </div>
                    </div>
                </el-form-item>

                <div class="text-right mt-4">
                    <el-button class="pull-left"
                               icon="el-icon-arrow-left"
                               type="info"
                               data-testid="back-button"
                               @click="backForm">
                        Back
                    </el-button>
                    <el-button type="success"
                               data-testid="next-phone-button"
                               @click="submitForm"
                               :disabled="!validated || !enabledToAddLines()"
                               v-if="campaign.type == TYPE_OFFLINE || campaign.subtype == SUBTYPE_ONLINE_EXTERNAL">
                        Next: Select a Phone Number
                        <i class="el-icon-arrow-right el-icon-right"></i>
                    </el-button>
                    <el-button type="success"
                               data-testid="next-tracking-button"
                               @click="submitForm"
                               :disabled="!validated || !enabledToAddLines()"
                               v-else>
                        Next: Select Tracking Sources
                        <i class="el-icon-arrow-right el-icon-right"></i>
                    </el-button>
                </div>
            </el-form>
        </div>

        <div id="step_2_5"
             data-testid="wizard-step-2-5"
             v-if="step == 2.5">
            <p class="form-head">Which visitors do you want to track?</p>
            <div class="dropdown-divider"></div>
            <el-form ref="campaign_tracking"
                     label-position="top"
                     label-width="300px"
                     data-testid="campaign-tracking-form"
                     :rules="rules_2_5"
                     :model="campaign">
                <el-form-item prop="visitor_tracking_mode"
                              data-testid="tracking-phone-item">
                    <!--Paid Search (google, yahoo, bing, all) mode: ppc_only, options: yahoo-->
                    <!--Organic Search (google, yahoo, bing, all) mode: organic_only, options: all-->
                    <!--Social Media (facebook, instagram, twitter, linkedin, all) mode: sm_only, options: fb-->
                    <!--Direct Only mode: direct_only-->
                    <!--Specific Landing Page (text field) mode: lp_only, options: /xys-sale-->
                    <!--Specific Referrer (text field) mode: ref_only, options: www.nytimes.com-->
                    <!--Specific Landing Params (text field) mode: utm_only, options: utm_source=xys-sale-->
                    <!--All Except (direct, organic, direct & organic) mode: all_except, options: direct-->
                    <div class="row mt-2"
                         data-testid="always-swap">
                        <div class="col-md-4">
                            <p class="text">
                                <b>Always Swap</b>
                            </p>
                        </div>
                        <div class="col-md-8 col-12">
                            <input type="radio"
                                   value="all"
                                   id="all"
                                   data-testid="tracking-mode-all-input"
                                   v-model="campaign.visitor_tracking_mode"
                                   @change="changeCampaignTracking">
                            <label for="all">Show the tracking number to all visitors, regardless of source</label>
                        </div>
                    </div>
                    <div class="row mt-2"
                         data-testid="direct-visit">
                        <div class="col-md-4">
                            <p class="text">
                                <b>Direct Visit</b>
                            </p>
                        </div>
                        <div class="col-md-8 col-12">
                            <input type="radio"
                                   value="direct_only"
                                   id="direct_only"
                                   data-testid="tracking-mode-direct-input"
                                   v-model="campaign.visitor_tracking_mode"
                                   @change="changeCampaignTracking">
                            <label for="direct_only">Show the tracking number to all direct visitors</label>
                        </div>
                    </div>
                    <div class="row mt-2"
                         data-testid="social-media">
                        <div class="col-md-4">
                            <p class="text mt-wizard">
                                <b>Social Media</b>
                            </p>
                        </div>
                        <div class="col-md-8 col-12">
                            <input type="radio"
                                   value="sm_only"
                                   id="sm_only"
                                   data-testid="tracking-mode-sm-input"
                                   v-model="campaign.visitor_tracking_mode"
                                   @change="changeCampaignTracking">
                            <label for="sm_only">Visitors from</label>
                            <el-form-item prop="visitor_tracking_options.sm_only"
                                          data-testid="tracking-mode-sm-options-item"
                                          class="inline">
                                <el-select v-model="sm_only"
                                           placeholder="Select"
                                           style="width:150px"
                                           data-testid="tracking-mode-sm-options-select"
                                           :disabled="campaign.visitor_tracking_mode != 'sm_only'"
                                           v-if="campaign.visitor_tracking_mode != 'sm_only'">
                                    <el-option
                                            v-for="(item, index) in social_media_sites"
                                            :key="index"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                                <el-select v-model="campaign.visitor_tracking_options"
                                           placeholder="Select"
                                           data-testid="tracking-mode-sm-options-select"
                                           style="width:150px"
                                           :disabled="campaign.visitor_tracking_mode != 'sm_only'"
                                           @change="customPreValidateForm"
                                           v-else>
                                    <el-option
                                            v-for="(item, index) in social_media_sites"
                                            :key="index"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mt-3"
                         data-testid="paid-search">
                        <div class="col-md-4">
                            <p class="text mt-wizard">
                                <b>Paid Search</b>
                            </p>
                        </div>
                        <div class="col-md-8 col-12">
                            <input type="radio"
                                   value="ppc_only"
                                   id="ppc_only"
                                   v-model="campaign.visitor_tracking_mode"
                                   @change="changeCampaignTracking">
                            <label for="ppc_only">Visitors from</label>
                            <el-form-item prop="visitor_tracking_options.ppc_only"
                                          data-testid="tracking-mode-ppc-options-item"
                                          class="inline">
                                <el-select v-model="ppc_only"
                                           placeholder="Select"
                                           style="width:150px"
                                           data-testid="tracking-mode-ppc-options-select"
                                           :disabled="campaign.visitor_tracking_mode != 'ppc_only'"
                                           v-if="campaign.visitor_tracking_mode != 'ppc_only'">
                                    <el-option
                                            v-for="(item, index) in search_engines"
                                            :key="index"
                                            :label="item | ucfirst"
                                            :value="item">
                                    </el-option>
                                </el-select>
                                <el-select v-model="campaign.visitor_tracking_options"
                                           placeholder="Select"
                                           style="width:150px"
                                           data-testid="tracking-mode-ppc-options-select"
                                           :disabled="campaign.visitor_tracking_mode != 'ppc_only'"
                                           @change="customPreValidateForm"
                                           v-else>
                                    <el-option
                                            v-for="(item, index) in search_engines"
                                            :key="index"
                                            :label="item | ucfirst"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mt-3"
                         data-testid="organic-search">
                        <div class="col-md-4">
                            <p class="text mt-wizard">
                                <b>Organic Search</b>
                            </p>
                        </div>
                        <div class="col-md-8 col-12">
                            <input type="radio"
                                   value="organic_only"
                                   id="organic_only"
                                   data-testid="tracking-mode-organic-input"
                                   v-model="campaign.visitor_tracking_mode"
                                   @change="changeCampaignTracking">
                            <label for="organic_only">Visitors from</label>
                            <el-form-item prop="visitor_tracking_options.organic_only"
                                          data-testid="tracking-mode-organic-options-item"
                                          class="inline">
                                <el-select v-model="organic_only"
                                           placeholder="Select"
                                           style="width:150px"
                                           data-testid="tracking-mode-organic-options-select"
                                           :disabled="campaign.visitor_tracking_mode != 'organic_only'"
                                           v-if="campaign.visitor_tracking_mode != 'organic_only'">
                                    <el-option
                                            v-for="(item, index) in search_engines"
                                            :key="index"
                                            :label="item | ucfirst"
                                            :value="item">
                                    </el-option>
                                </el-select>
                                <el-select v-model="campaign.visitor_tracking_options"
                                           placeholder="Select"
                                           data-testid="tracking-mode-organic-options-select"
                                           style="width:150px"
                                           :disabled="campaign.visitor_tracking_mode != 'organic_only'"
                                           @change="customPreValidateForm"
                                           v-else>
                                    <el-option
                                            v-for="(item, index) in search_engines"
                                            :key="index"
                                            :label="item | ucfirst"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mt-3"
                         data-testid="web-referrals">
                        <div class="col-md-4">
                            <p class="text mt-wizard">
                                <b>Web Referrals</b>
                            </p>
                        </div>
                        <div class="col-md-8 col-12">
                            <input type="radio"
                                   value="ref_only"
                                   id="ref_only"
                                   data-testid="tracking-mode-ref-input"
                                   v-model="campaign.visitor_tracking_mode"
                                   @change="changeCampaignTracking">
                            <label for="ref_only">Visitors from</label>
                            <el-form-item prop="visitor_tracking_options.ref_only"
                                          data-testid="tracking-mode-ref-options-item"
                                          class="inline">
                                <el-input clearable
                                          style="width:250px"
                                          placeholder='yelp.com'
                                          :disabled="campaign.visitor_tracking_mode != 'ref_only'"
                                          data-testid="tracking-mode-ref-options-input"
                                          @input="customPreValidateForm"
                                          v-if="campaign.visitor_tracking_mode != 'ref_only'">
                                </el-input>
                                <el-input v-model="campaign.visitor_tracking_options"
                                          clearable
                                          style="width:250px"
                                          placeholder='yelp.com'
                                          data-testid="tracking-mode-ref-options-input"
                                          :disabled="campaign.visitor_tracking_mode != 'ref_only'"
                                          @input="customPreValidateForm"
                                          v-else>
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mt-3"
                         data-testid="landing-page">
                        <div class="col-md-4">
                            <p class="text mt-wizard">
                                <b>Landing Page</b>
                            </p>
                        </div>
                        <div class="col-md-8 col-12">
                            <input type="radio"
                                   value="lp_only"
                                   id="lp_only"
                                   data-testid="tracking-mode-lp-input"
                                   v-model="campaign.visitor_tracking_mode"
                                   @change="changeCampaignTracking">
                            <label for="lp_only">Visitors who land on</label>
                            <el-form-item prop="visitor_tracking_options.lp_only"
                                          data-testid="tracking-mode-lp-options-item"
                                          class="inline">
                                <el-input clearable
                                          style="width:250px"
                                          placeholder='landingpage.com'
                                          :disabled="campaign.visitor_tracking_mode != 'lp_only'"
                                          data-testid="tracking-mode-lp-options-input"
                                          @input="customPreValidateForm"
                                          v-if="campaign.visitor_tracking_mode != 'lp_only'">
                                </el-input>
                                <el-input v-model="campaign.visitor_tracking_options"
                                          clearable
                                          style="width:250px"
                                          placeholder='landingpage.com'
                                          data-testid="tracking-mode-lp-options-input"
                                          :disabled="campaign.visitor_tracking_mode != 'lp_only'"
                                          @input="customPreValidateForm"
                                          v-else>
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mt-3"
                         data-testid="landing-params">
                        <div class="col-md-4">
                            <p class="text mt-wizard">
                                <b>Landing Params</b>
                            </p>
                        </div>
                        <div class="col-md-8 col-12">
                            <input type="radio"
                                   value="utm_only"
                                   id="utm_only"
                                   data-testid="tracking-mode-utm-input"
                                   v-model="campaign.visitor_tracking_mode"
                                   @change="changeCampaignTracking">
                            <label for="utm_only">Visitors to a landing page containing</label>
                            <el-form-item prop="visitor_tracking_options.utm_only"
                                          data-testid="tracking-mode-utm-options-item"
                                          class="inline">
                                <el-input clearable
                                          style="width:180px"
                                          placeholder='utm_source=example'
                                          data-testid="tracking-mode-utm-options-input"
                                          :disabled="campaign.visitor_tracking_mode != 'utm_only'"
                                          @input="customPreValidateForm"
                                          v-if="campaign.visitor_tracking_mode != 'utm_only'">
                                </el-input>
                                <el-input v-model="campaign.visitor_tracking_options"
                                          clearable
                                          style="width:180px"
                                          placeholder='utm_source=example'
                                          data-testid="visitor-tracking-options-input"
                                          :disabled="campaign.visitor_tracking_mode != 'utm_only'"
                                          @input="customPreValidateForm"
                                          v-else>
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <div class="text-right mt-4">
                <el-button class="pull-left"
                           icon="el-icon-arrow-left"
                           type="info"
                           data-testid="back-button"
                           @click="backForm">
                    Back
                </el-button>
                <el-button type="success"
                           data-testid="next-other-options-button"
                           @click="submitForm"
                           :disabled="!validated">
                    Next: Other Options
                    <i class="el-icon-arrow-right el-icon-right"></i>
                </el-button>
            </div>
        </div>

        <div id="step_3"
             data-testid="wizard-step-3"
             v-if="step == 3">
            <p class="form-head"
               v-if="campaign.type == TYPE_OFFLINE || (campaign.type == TYPE_ONLINE && (campaign.subtype == SUBTYPE_ONLINE_MARKETING || campaign.subtype == SUBTYPE_ONLINE_EXTERNAL))">
                Phone Number
            </p>
            <p class="form-head"
               v-else>Fill Pool With</p>
            <div class="dropdown-divider"></div>
            <div v-if="campaign.type == TYPE_OFFLINE || (campaign.type == TYPE_ONLINE && (campaign.subtype == SUBTYPE_ONLINE_MARKETING || campaign.subtype == SUBTYPE_ONLINE_EXTERNAL))">
                <el-form ref="campaign_area_code"
                         class="text-right"
                         data-testid="campaign-area-code-form"
                         :inline="true"
                         @submit.prevent.native="changeAreaCode">
                    <el-form-item label="Area Code:">
                        <el-input class="area-code"
                                  type="text"
                                  pattern="\d*"
                                  :maxlength="3"
                                  data-testid="area-code-input"
                                  v-model="area_code">
                        </el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary pull-right"
                                   data-testid="search-button"
                                   @click.prevent="changeAreaCode"
                                   :disabled="loading">
                            <i class="fa fa-search"></i>
                            Search
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-form ref="campaign_incoming_number"
                         label-position="top"
                         label-width="300px"
                         :rules="rules_3"
                         :model="campaign"
                         data-testid="campaign-incoming-number-form"
                         v-loading="loading">
                    <el-form-item prop="incoming_number"
                                  data-testid="incoming-numbr-item">
                        <div v-if="!changed">
                            <label class="el-form-item__label">
                                Local Phone Numbers to {{ campaign_first_user }}
                            </label>
                        </div>
                        <div v-else>
                            <label class="el-form-item__label">Phone Numbers in the {{ changed_area_code }} area
                                code</label>
                            <small class="text-muted text-dark-greenish">
                                Warning: These numbers may not be local to {{ campaign_first_user }}
                            </small>
                        </div>

                        <div v-if="available_numbers">
                            <div class="el-form-item__content">
                                <el-radio-group v-model="campaign.incoming_number"
                                                class="w-full"
                                                data-testid="incoming-number-radio"
                                                @change="changeNumber(false)">
                                    <div class="row"
                                         v-for="i in Math.ceil(available_numbers.length / 4)">
                                        <div class="col-md-3 col-6"
                                             v-for="item in available_numbers.slice((i - 1) * 4, i * 4)">
                                            <el-radio :label="item.sid">
                                                {{ item.phone_number | fixPhone }}
                                            </el-radio>
                                        </div>
                                    </div>
                                </el-radio-group>
                            </div>
                        </div>

                        <div v-if="available_tollfree_numbers">
                            <label class="el-form-item__label">Toll-Free Numbers ({{ auth.user.profile.rate.tollfree_pn
                                | fixRounding | toCurrency }}/mo surcharge)</label>
                            <div class="el-form-item__content">
                                <el-radio-group v-model="campaign.incoming_number"
                                                class="w-full"
                                                data-testid="incoming-number-radio"
                                                @change="changeNumber(true)">
                                    <div class="row"
                                         v-for="i in Math.ceil(available_tollfree_numbers.length / 4)">
                                        <div class="col-md-3 col-6"
                                             v-for="item in available_tollfree_numbers.slice((i - 1) * 4, i * 4)">
                                            <el-radio :label="item.sid">
                                                {{ item.phone_number | fixPhone }}
                                            </el-radio>
                                        </div>
                                    </div>
                                </el-radio-group>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <el-form ref="campaign_incoming_number"
                     label-position="top"
                     label-width="300px"
                     :rules="rules_3"
                     :model="campaign"
                     data-testid="campaign-incoming-number-form"
                     v-loading="loading"
                     v-else>
                <el-form-item prop="pool_options.type"
                              data-testid="pool-options-item">
                    <el-radio-group v-model="campaign.pool_options.type"
                                    class="w-full"
                                    data-testid="pool-options-radio"
                                    @change="changeNumberPoolOption">
                        <div class="row">
                            <div class="col-12">
                                <el-radio value="local_to_user"
                                          label="local_to_user"
                                          data-testid="local-to-user-radio"
                                          :disabled="available_numbers.length < campaign.pool_size">
                                    Numbers local to {{ campaign_first_user }}
                                </el-radio>
                                <div class="row"
                                     data-testid="no-number-available">
                                    <div class="col-12">
                                        <span class="b-l pl-2 text-sm text-muted"
                                              v-if="available_numbers.length < campaign.pool_size">
                                            No numbers available
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-12">
                                <el-radio value="tollfree_area_code"
                                          data-testid="tollfree-area-code-radio"
                                          label="tollfree_area_code">
                                    Toll-free numbers ({{ auth.user.profile.rate.tollfree_pn | fixRounding | toCurrency }} per month
                                    surcharge)
                                </el-radio>
                                <el-form-item label="Area Code:"
                                              data-testid="tollfree-area-code-item"
                                              prop="pool_options.area_code"
                                              class="b-l pl-2">
                                    <div class="row">
                                        <div class="col-12">
                                            <el-select v-model="tollfree_area_code"
                                                       :disabled="campaign.pool_options.type != 'tollfree_area_code'"
                                                       data-testid="tollfree-area-code-select"
                                                       @change="changeTollFreeAreaCode">
                                                <el-option
                                                        v-for="area_code in tollfree_area_codes"
                                                        :key="area_code"
                                                        :label="area_code"
                                                        :value="area_code">
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>

                        <el-button type="text"
                                   class="mt-4"
                                   data-testid="advanced-button"
                                   v-show="!advanced"
                                   @click="advanced = true">
                            Show Advanced Options
                        </el-button>
                        <transition name="el-fade-in-linear"
                                    data-testid="transition">
                            <div v-show="advanced">
                                <div class="row mt-4">
                                    <div class="col-12">
                                        <el-radio value="local_area_code"
                                                  data-testid="local-area-code-radio"
                                                  label="local_area_code">
                                            Numbers in a specific area code
                                        </el-radio>

                                        <div class="row">
                                            <div class="col-12">
                                                <el-form-item label="Area Code:"
                                                              data-testid="area-code-item"
                                                              class="b-l pl-2">
                                                    <el-input class="area-code"
                                                              type="text"
                                                              pattern="\d*"
                                                              :maxlength="3"
                                                              v-model="area_code"
                                                              data-testid="area-code-input"
                                                              :disabled="campaign.pool_options.type != 'local_area_code'"
                                                              @input="resetChangeAreaCode">
                                                    </el-input>
                                                    <el-button type="success"
                                                               class="ml-2"
                                                               data-testid="search-area-code-button"
                                                               :disabled="campaign.pool_options.type != 'local_area_code'"
                                                               @click="searchIncomingNumberOnCampaignAreaCode(campaign.pool_size)"
                                                               @change="search_area_code_done = false">
                                                        Check Area Code
                                                    </el-button>
                                                    <span class="text-success"
                                                          data-testid="number-available"
                                                          v-if="area_code_available_numbers.length >= campaign.pool_size && search_area_code_done">
                                                        <i class="el-alert__icon el-icon-success"></i>
                                                        Numbers available
                                                    </span>
                                                    <span class="text-danger"
                                                          data-testid="no-number-available"
                                                          v-if="area_code_available_numbers.length < campaign.pool_size && search_area_code_done">
                                                        <i class="el-alert__icon el-icon-error"></i>
                                                        No numbers available
                                                    </span>
                                                </el-form-item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </el-radio-group>
                </el-form-item>
            </el-form>

            <div class="text-right mt-4">
                <el-button class="pull-left"
                           icon="el-icon-arrow-left"
                           type="info"
                           data-testid="back-button"
                           @click="backForm">
                    Back
                </el-button>
                <el-button type="success"
                           data-testid="activated-fax"
                           @click="submitForm"
                           :disabled="!validated">
                    <span v-if="is_fax">
                        Activate Fax
                    </span>
                    <span v-else>
                        Next: Other Options
                    </span>
                    <i class="el-icon-arrow-right el-icon-right"></i>
                </el-button>
            </div>
        </div>

        <div id="step_4"
             data-testid="wizard-step-4"
             v-if="step == 4">
            <el-form ref="campaign_rest"
                     label-position="top"
                     label-width="300px"
                     data-testid="campaign-rest-form"
                     :rules="rules_4"
                     :model="campaign">
                <p class="form-head">Whisper & Recording</p>
                <div class="dropdown-divider"></div>

                <el-form-item label="Call Greeting"
                              data-testid="campaign-rest-greeting-item"
                              prop="should_greet">
                    <div class="row">
                        <div class="col-md-1 col-1 centered-content">
                            <el-checkbox-group data-testid="should-greet-checkbox"
                                               v-model="campaign.should_greet"
                                               @change="customPreValidateForm">
                                <el-checkbox name="should_greet"></el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <div class="col-md-5 col-11">
                            <el-input type="textarea"
                                      data-testid="greeting-tts-input"
                                      v-model="campaign.greeting_tts"
                                      :disabled="!campaign.should_greet">
                            </el-input>
                        </div>
                        <div class="col-md-6 col-12">
                            <el-popover
                                    ref="popover_greeting_tts"
                                    placement="right-start"
                                    title="Greeting Message"
                                    width="200"
                                    trigger="hover"
                                    data-testid="popover-greeting-tts"
                                    content="This message is read / played to the caller before connecting them to you. You can upload an audio file later on.">
                            </el-popover>
                            <span class="form-hover-help"
                                  v-popover:popover_greeting_tts>What is a greeting message?</span>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item label="Whisper Message"
                              data-testid="campaign-rest-whisper-item"
                              prop="should_whisper">
                    <div class="row">
                        <div class="col-1 centered-content">
                            <el-checkbox-group v-model="campaign.should_whisper"
                                               data-testid="should-whisper-checkbox"
                                               @change="customPreValidateForm">
                                <el-checkbox name="should_whisper"></el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <div class="col-5">
                            <el-input data-testid="whisper-tts-input"
                                      v-model="campaign.whisper_tts"
                                      :disabled="!campaign.should_whisper"></el-input>
                        </div>
                        <div class="col-6">
                            <el-popover
                                    ref="popover_whisper_message"
                                    placement="right-start"
                                    title="Whisper Message"
                                    width="200"
                                    trigger="hover"
                                    data-testid="popover-whisper-message"
                                    content="This messaged is played to you / your staff when you pick up the call, but
                                    not to the caller. Use it as a way to identify the line, for example 'a new call from Facebook Referral'.">
                            </el-popover>
                            <span class="form-hover-help"
                                  v-popover:popover_whisper_message>
                                What is a whisper message?
                            </span>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item label="Call Recording Notice"
                              data-testid="campaign-rest-recording-item"
                              prop="should_record">
                    <div class="row">
                        <div class="col-1 centered-content">
                            <el-checkbox-group data-testid="should-record-checkbox"
                                               v-model="campaign.should_record"
                                               @change="customPreValidateForm">
                                <el-checkbox name="should_record"></el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <div class="col-5">
                            <el-input type="textarea"
                                      data-testid="record-tts-input"
                                      v-model="campaign.record_tts"
                                      :disabled="!campaign.should_record">
                            </el-input>
                        </div>
                        <div class="col-6">
                            <el-popover
                                    ref="popover_record_tts"
                                    placement="right-start"
                                    title="Call Recording Notice"
                                    width="200"
                                    trigger="hover"
                                    data-testid="popover-record-tts"
                                    content="In the United States, you *must* let the caller know that their conversation is being recorded.">
                            </el-popover>
                            <span class="form-hover-help"
                                  v-popover:popover_record_tts>
                                Important note!
                            </span>
                        </div>
                    </div>
                </el-form-item>
                <div class="text-right mt-4">
                    <el-button class="pull-left"
                               icon="el-icon-arrow-left"
                               type="info"
                               data-testid="back-button"
                               @click="backForm">
                        Back
                    </el-button>
                    <el-button type="success"
                               data-testid="next-activate-button"
                               @click="submitForm"
                               :loading="loading"
                               :disabled="!validated">
                        Activate Line
                        <i class="el-icon-arrow-right el-icon-right"></i>
                    </el-button>
                </div>
            </el-form>
        </div>

        <div id="step_5"
             data-testid="wizard-step-5"
             v-if="step == 5"
             class="text-center">
            <campaign-details :campaign="campaign_created"
                              :is_fax="is_fax"
                              data-testid="campaign-details"
                              @close="close">
            </campaign-details>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {mapActions, mapState} from 'vuex'
    import {
        acl_mixin,
        validator_mixin,
        form_validation_mixin,
        kyc_mixin,
        teams_mixin,
        campaign_mixin
    } from '../mixins'
    import {search_engines, social_media_sites} from './../constants/options.js'
    import * as CampaignCallRouterBehavior from "../constants/campaign-call-router-behaviors"
    import RingGroupSelector from './ring-group-selector'

    export default {
        mixins: [
            acl_mixin,
            validator_mixin,
            form_validation_mixin,
            kyc_mixin,
            teams_mixin,
            campaign_mixin
        ],

        props: {
            is_fax: {
                required: false,
                type: Boolean
            }
        },

        components: {RingGroupSelector},

        data() {
            return {
                auth: auth,
                statics: {
                    logo: null,
                    logo_inverse: null,
                    logo_square: null,
                    logo_square_inverse: null,
                    host: null,
                    referer: null,
                    name: null,
                    domain: null,
                    whitelabel: false,
                    path: null
                },
                loading_whitelabel: true,
                step: 'start',
                percentage: 0,
                purpose: null,
                campaign: {
                    name: null,
                    type: null,
                    subtype: null,
                    is_fax: false,
                    ring_group_id: null,
                    // DNI details
                    pool_size: 8,
                    pool_options: {
                        type: null, // local_to_user, tollfree_area_code, local_area_code
                        area_code: null,
                    },
                    dial_mode: 0,
                    visitor_tracking_mode: null,
                    visitor_tracking_options: null,
                    // end DNI only options
                    incoming_number: null,
                    should_greet: false,
                    greeting_tts: 'Hi! Thanks for calling.',
                    should_whisper: false,
                    whisper_tts: 'Hey! A new call from ',
                    should_record: false,
                    record_tts: 'This call may be recorded or monitored for quality assurance purposes.',
                    call_router_behavior: null
                },
                area_code: null,
                changed_area_code: null,
                available_numbers: [],
                available_tollfree_numbers: [],
                area_code_available_numbers: [],
                loading: false,
                changed: false,
                success: false,
                campaign_created: null,
                tollfree_area_codes: [800, 833, 844, 855, 866, 877, 888],
                tollfree_area_code: null,
                advanced: false,
                search_area_code_done: false,
                search_engines,
                social_media_sites,
                sm_only: null,
                ppc_only: null,
                organic_only: null,
                hide_add: false,
                campaign_first_user: null,
                TYPE_OFFLINE: 0,
                TYPE_ONLINE: 1,
                SUBTYPE_ONLINE_EXTERNAL: 0,
                SUBTYPE_ONLINE_VISITORS: 1,
                SUBTYPE_ONLINE_MARKETING: 2,
                LOCAL_PHONE_NUMBERS: 0,
                TOLL_FREE_NUMBERS: 1,
                LOCAL_MINUTES: 2,
                TOLL_FREE_MINUTES: 3,
                DIAL_MODE_SIMUL: 0,
                DIAL_MODE_RAND: 1,
                DIAL_MODE_ROUND: 2,
                DIAL_MODE_LONGEST_AVAILABLE: 3,
                SMS: 4,
                CampaignCallRouterBehavior,
                rules_2: {
                    name: [
                        {
                            required: true,
                            message: 'Please provide a line name',
                            trigger: 'blur'
                        },
                    ],
                    call_router_behavior: [
                        {
                            required: true,
                            message: 'Please select a routing behavior',
                            trigger: 'change'
                        },
                    ],
                    ring_group_id: [
                        {
                            validator: (rule, value, callback) => {
                                if (this.campaign.call_router_behavior === CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END) {
                                    callback()
                                } else {
                                    if (!value) {
                                        callback(new Error('Please select a ring group'))
                                    }

                                    callback()
                                }
                            },
                            trigger: 'blur'
                        }
                    ],
                },
                rules_2_5: {
                    visitor_tracking_mode: [
                        {
                            required: true,
                            message: 'Please select a tracking mode',
                            trigger: 'blur'
                        }
                    ],
                    'visitor_tracking_options.sm_only': [
                        {
                            validator: this.socialMediaValidator,
                            trigger: 'blur'
                        }
                    ],
                    'visitor_tracking_options.ppc_only': [
                        {
                            validator: this.ppcValidator,
                            trigger: 'blur'
                        }
                    ],
                    'visitor_tracking_options.organic_only': [
                        {
                            validator: this.organicValidator,
                            trigger: 'blur'
                        }
                    ],
                    'visitor_tracking_options.ref_only': [
                        {
                            validator: this.referrerValidator,
                            trigger: 'blur'
                        }
                    ],
                    'visitor_tracking_options.lp_only': [
                        {
                            validator: this.landingPageValidator,
                            trigger: 'blur'
                        }
                    ],
                    'visitor_tracking_options.utm_only': [
                        {
                            validator: this.landingParamValidator,
                            trigger: 'blur'
                        }
                    ],
                },
                rules_3: {
                    incoming_number: [
                        {
                            required: true,
                            message: 'Please add a number to your campaign',
                            trigger: 'blur'
                        }
                    ],
                    'pool_options.type': [
                        {
                            validator: this.poolNumberTypeValidator,
                            trigger: 'blur'
                        }
                    ],
                    'pool_options.area_code': [
                        {
                            validator: this.poolNumberTollFreeAreaCodeValidator,
                            trigger: 'blur'
                        }
                    ],
                },
                rules_4: {
                    should_whisper: [
                        {
                            type: 'array',
                            validator: this.whisperValidator,
                            trigger: 'change'
                        },
                    ],
                    should_greet: [
                        {
                            type: 'array',
                            validator: this.greetingValidator,
                            trigger: 'change'
                        },
                    ],
                    should_record: [
                        {
                            type: 'array',
                            validator: this.recordValidator,
                            trigger: 'change'
                        },
                    ],
                },
            }
        },

        computed: {
            ...mapState({
                current_company: state => state.cache.current_company,
                users: state => state.users,
                ring_groups: state => state.ring_groups
            }),

            firstUser() {
                return this.getCampaignFirstUserPhoneNumber(this.campaign, this.ring_groups, this.users, this.all_teams)
            }
        },

        created() {
            this.getStatics()
        },

        mounted() {
            if (this.ring_groups.length) {
                this.campaign.ring_group_id = this.ring_groups[0].id
            }
            if (this.is_fax) {
                this.setContactCenter()
                this.campaign.is_fax = true
                this.percentage = 33
                this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_DEAD_END
            }
        },

        methods: {
            getStatics() {
                this.loading_whitelabel = true
                axios.get('/get-statics')
                    .then(res => {
                        this.statics = res.data
                        this.campaign.whisper_tts = this.campaign.whisper_tts + this.statics.name + '!'
                        this.loading_whitelabel = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.$root.handleErrors(err.response)
                        this.loading_whitelabel = false
                    })
            },

            changedIsDeadEnd() {
                setTimeout(() => {
                    this.customPreValidateForm()
                }, 100)
            },

            createCampaign: function () {
                return axios.post('/api/v1/campaign', this.campaign)
                    .then(res => {
                        this.newCampaign(res.data)

                        return Promise.resolve(res)
                    })
                    .catch(err => {
                        return Promise.reject(err)
                    })
            },

            searchIncomingNumber: function (limit) {
                this.available_numbers = []
                this.available_tollfree_numbers = []
                this.loading = true

                axios.get('/api/v1/available-numbers', {
                    params: {
                        area_code: this.area_code,
                        limit: limit
                    }
                }).then(res => {
                    this.available_numbers = res.data

                    axios.get('/api/v1/available-numbers', {
                        params: {
                            is_tollfree: true,
                            area_code: this.changed_area_code,
                            limit: 8
                        }
                    }).then(res => {
                        this.available_tollfree_numbers = res.data
                        this.loading = false
                    }).catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading = false
                    })
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading = false
                })
            },

            searchIncomingNumberOnCampaignAreaCode: function (limit) {
                this.area_code_available_numbers = []
                this.loading = true
                this.search_area_code_done = false

                axios.get('/api/v1/available-numbers', {
                    params: {
                        area_code: this.area_code,
                        limit: limit
                    }
                }).then(res => {
                    this.area_code_available_numbers = res.data
                    this.loading = false
                    this.search_area_code_done = true
                    this.campaign.pool_options.area_code = this.area_code
                    this.customPreValidateForm()
                }).catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading = false
                })
            },

            setOffline() {
                this.campaign.type = this.TYPE_OFFLINE
                this.submitForm()
            },

            setOnline() {
                this.campaign.type = this.TYPE_ONLINE
                this.submitForm()
            },

            setOnlineOther() {
                this.campaign.subtype = this.SUBTYPE_ONLINE_EXTERNAL
                this.submitForm()
            },

            setOnlineOur() {
                this.campaign.subtype = this.SUBTYPE_ONLINE_MARKETING
                this.submitForm()
            },

            setOnlineType(type) {
                this.campaign.subtype = type
                this.submitForm()
            },

            setContactCenter() {
                this.purpose = 'Contact Center'
                this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD
                this.submitForm()
            },

            setCallTracking() {
                this.purpose = 'Call Tracking'
                this.campaign.call_router_behavior = CampaignCallRouterBehavior.CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD
                this.submitForm()
            },

            submitForm() {
                let formName = ''
                switch (this.step) {
                    case 'start':
                        if (this.purpose == 'Call Tracking') {
                            this.step = 0
                            this.percentage = 5
                        }

                        if (this.purpose == 'Contact Center') {
                            this.step = 2
                            this.percentage = 40
                            this.campaign.type = this.TYPE_OFFLINE
                        }

                        // resets validation
                        this.validated = false
                        break
                    case 0:
                        if (this.campaign.type == this.TYPE_OFFLINE) {
                            // offline campaign is selected
                            this.step = 2
                            this.percentage = 40
                        } else {
                            // overriden @ARR 04.05.18
                            // online campaign is selected
                            this.step = 1.2 // go directly to two
                            this.percentage = 15
                            // set sub type as campaign tracking
                        }

                        // resets validation
                        this.validated = false
                        break
                    case 1.2:
                        if (this.campaign.subtype == this.SUBTYPE_ONLINE_EXTERNAL) {
                            // is external online
                            this.step = 2
                            this.percentage = 40
                        } else {
                            // is internal online
                            this.step = 2
                            this.percentage = 40
                        }
                        // resets validation
                        this.validated = false
                        break
                    case 1.5:
                        // not used. @ARR 04.05.18, overriden above.
                        // visitor is selected
                        this.step = 2
                        this.percentage = 40
                        // resets validation
                        this.validated = false
                        break
                    case 2:
                        formName = 'campaign_info'
                        if (this.validateForm(formName)) {
                            this.campaign_first_user = this.firstUser

                            if (this.campaign.type == this.TYPE_OFFLINE || (this.campaign.type == this.TYPE_ONLINE && this.campaign.subtype == this.SUBTYPE_ONLINE_EXTERNAL)) {
                                // Offline mode is selected and user + line name is filled
                                this.area_code = this.getAreaCode(this.campaign_first_user)
                                this.searchIncomingNumber(12)
                                this.step = 3
                                this.percentage = 70
                            }

                            if (this.campaign.type == this.TYPE_ONLINE && (this.campaign.subtype == this.SUBTYPE_ONLINE_MARKETING || this.campaign.subtype == this.SUBTYPE_ONLINE_VISITORS)) {
                                // Online mode is selected and user + pool name + pool size is filled
                                this.step = 2.5
                                this.percentage = 55
                            }
                        }
                        // resets validation
                        this.validated = false
                        break
                    case 2.5:
                        formName = 'campaign_tracking'
                        if (this.validateForm(formName)) {
                            this.area_code = this.getAreaCode(this.campaign_first_user)
                            this.searchIncomingNumber(12)
                            this.step = 3
                            this.percentage = 70
                        }
                        // resets validation
                        this.validated = false
                        break
                    case 3:
                        formName = 'campaign_incoming_number'
                        if (this.validateForm(formName)) {
                            if (this.is_fax) {
                                this.loading = true
                                this.createCampaign()
                                .then(res => {
                                    this.campaign_created = res.data
                                    this.success = true
                                    this.loading = false
                                    this.percentage = 100
                                    this.step = 5
                                    this.$emit('created')
                                })
                                .catch(err => {
                                    this.$root.handleErrors(err.response)
                                    this.loading = false
                                })
                            }
                            if (!this.is_fax) {
                                this.percentage = 85
                                this.step = 4
                            }
                        }
                        // resets validation
                        this.validated = true
                        break
                    case 4:
                        formName = 'campaign_rest'
                        if (this.validateForm(formName)) {
                            this.loading = true
                            this.createCampaign()
                                .then(res => {
                                    this.campaign_created = res.data
                                    this.success = true
                                    this.loading = false
                                    this.percentage = 100
                                    this.step = 5
                                    this.$emit('created')
                                })
                                .catch(err => {
                                    this.$root.handleErrors(err.response)
                                    this.loading = false
                                })
                        }
                        break
                    default:
                        break
                }
            },

            backForm() {
                switch (this.step) {
                    case 0:
                        this.step = 'start'
                        this.percentage = 0
                        this.purpose = null
                        this.validated = true
                        break
                    case 1.2:
                        this.step = 0
                        this.percentage = 5
                        this.campaign.subtype = null
                        this.validated = true
                        break
                    case 1.5:
                        this.step = 1.2
                        this.percentage = 15
                        this.validated = true
                        break
                    case 2:
                        if (this.purpose == 'Contact Center') {
                            this.step = 'start'
                            this.percentage = 0
                            this.purpose = null
                            this.campaign.type = null
                        }

                        if (this.purpose == 'Call Tracking') {
                            if (this.campaign.type == this.TYPE_OFFLINE) {
                                this.campaign.type = null
                                this.step = 0
                                this.percentage = 5
                            } else {
                                this.step = 1.2
                                this.percentage = 15
                            }
                        }

                        this.campaign.subtype = null
                        this.campaign.ring_group_id = null
                        this.campaign.name = null
                        this.campaign_first_user = null

                        this.validated = true
                        break
                    case 2.5:
                        this.step = 2
                        this.percentage = 40
                        this.campaign.visitor_tracking_mode = null
                        this.campaign.visitor_tracking_options = null
                        this.validated = true
                        break
                    case 3:
                        this.campaign.incoming_number = null
                        if (this.campaign.type == this.TYPE_OFFLINE || this.campaign.subtype == this.SUBTYPE_ONLINE_EXTERNAL) {
                            this.step = 2
                            this.percentage = 40
                        } else {
                            this.step = 2.5
                            this.percentage = 55
                        }
                        this.validated = true
                        break
                    case 4:
                        if (this.campaign.type == this.TYPE_ONLINE) {
                            this.campaign.pool_options.type = null
                        }
                        this.step = 3
                        this.percentage = 70
                        this.validated = true
                        break
                    default:
                        break
                }
            },

            customPreValidateForm(reset = false) {
                let form_name = ''
                switch (this.step) {
                    case 2:
                        form_name = 'campaign_info'
                        break
                    case 2.5:
                        form_name = 'campaign_tracking'
                        break
                    case 3:
                        form_name = 'campaign_incoming_number'
                        break
                    case 4:
                        form_name = 'campaign_rest'
                        break
                    default:
                        break
                }

                this.preValidateForm(form_name, reset)
            },

            changeCampaignTracking() {
                this.campaign.visitor_tracking_options = null
                this.customPreValidateForm()
            },

            changeAreaCode() {
                if (this.area_code == this.getAreaCode(this.campaign_first_user)) {
                    this.changed_area_code = null
                    this.changed = false
                } else {
                    this.changed_area_code = this.area_code
                    this.changed = true
                }

                this.customPreValidateForm()
                this.searchIncomingNumber(12)
            },

            changeNumberType(is_tollfree) {
                this.campaign.is_tollfree = is_tollfree
            },

            changeNumber(is_tollfree) {
                this.changeNumberType(is_tollfree)
                this.customPreValidateForm()
            },

            changeNumberPoolOption(val) {
                // local_to_user, tollfree_area_code, local_area_code
                this.validated = false
                this.campaign.pool_options.area_code = null
                if (val == 'local_area_code') {
                    this.campaign.pool_options.area_code = this.getAreaCode(this.campaign_first_user)
                    this.changeNumberType(false)
                    this.searchIncomingNumberOnCampaignAreaCode(this.campaign.pool_size)
                } else if (val == 'tollfree_area_code') {
                    this.tollfree_area_code = null
                    this.campaign.pool_options.area_code = null
                    this.changeNumberType(true)
                    this.customPreValidateForm()
                } else {
                    this.campaign.pool_options.area_code = this.getAreaCode(this.campaign_first_user)
                    this.changeNumberType(false)
                    this.customPreValidateForm()
                }
            },

            changeTollFreeAreaCode(val) {
                this.campaign.pool_options.area_code = val
                this.customPreValidateForm()
            },

            resetChangeAreaCode() {
                this.validated = false
                this.search_area_code_done = false
            },

            getFixPhone(phoneNumber, format = 'NATIONAL') {
                return this.$options.filters.fixPhone(phoneNumber, format)
            },

            getAreaCode(phoneNumber) {
                if (phoneNumber) {
                    return this.$options.filters.areaCode(phoneNumber)
                }
            },

            updateHideAdd(val) {
                this.hide_add = val
                if (val === true) {
                    this.customPreValidateForm(true)
                } else {
                    this.customPreValidateForm()
                }
            },

            close: function () {
                this.$parent.$parent.prop_counter += 1
                this.$parent.$parent.dialogVisible = false
            },

            changeRingGroup(id) {
                this.campaign.ring_group_id = id
                this.customPreValidateForm()
            },

            ...mapActions(['newCampaign'])
        },

        watch: {
            'campaign.call_router_behavior': function () {
                setTimeout(() => {
                    this.customPreValidateForm()
                }, 100)
            }
        }
    }
</script>
