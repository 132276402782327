<template>
    <div>
        <div class="row p-relative mb-3">
            <div class="col-3">
                <outbound-connection-rate-widget ref="outbound_connection_rate_widget"/>
            </div>
            <div class="col-9 ">
                <outbound-graph-widget />
            </div>
        </div>
        <div class="row mb-3" v-loading="loading">
            <div class="col-3">
                <div class="card p-3 h-100"
                     v-loading="loading">
                    <div class="d-flex mb-3">
                        <i class="fa fa-phone text-greenish mr-2 align-self-center"></i>
                        <h6 class="mb-0 align-self-center">Call Dispositions</h6>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <metric-widget label="Connected"
                                           description="Communications disposed as connected"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.outbound_call_disposition_connected">
                            </metric-widget>
                        </div>
                        <div class="col-md-6 mb-3">
                            <metric-widget label="Busy"
                                           description="Communications disposed as busy"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.outbound_call_disposition_busy">
                            </metric-widget>
                        </div>
                        <div class="col-md-6 mb-3">
                            <metric-widget label="No Answer"
                                           description="Communications disposed as no answer"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.outbound_call_disposition_no_answer">
                            </metric-widget>
                        </div>
                        <div class="col-md-6 mb-3">
                            <metric-widget label="Left Live Message"
                                           description="Communications disposed as left live message"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.outbound_call_disposition_left_live_message">
                            </metric-widget>
                        </div>
                        <div class="col-md-6 mb-3">
                            <metric-widget label="Left Voicemail"
                                           description="Communications disposed as left voicemail"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.outbound_call_disposition_left_voicemail">
                            </metric-widget>
                        </div>
                        <div class="col-md-6 mb-3">
                            <metric-widget label="Wrong Number"
                                           description="Communications disposed as wrong number"
                                           :no_border="true"
                                           :is_card="true"
                                           :metric="metrics.outbound_call_disposition_wrong_number">
                            </metric-widget>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="row">
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Average Talk Time"
                                       :is_card="true"
                                       format="fixFullDuration"
                                       :metric="metrics.outbound_average_call_talk_time">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Calls Above 5 Minutes"
                                       description="The number of outbound calls that has a duration of more than 5 minutes."
                                       :is_card="true"
                                       :metric="metrics.outbound_calls_above_five_minutes">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Calls Above 2 Minutes"
                                       description="The number of outbound calls that has a duration of more than 2 minutes."
                                       :is_card="true"
                                       :metric="metrics.outbound_calls_above_two_minutes">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Calls Below 30 Seconds"
                                       description="The number of outbound calls that has a duration of less than 30 seconds."
                                       :is_card="true"
                                       :metric="metrics.outbound_calls_thirty_seconds_and_below">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Warm Transferred Calls"
                                       description="The number of outbound calls that were warm transferred"
                                       :is_card="true"
                                       :metric="metrics.outbound_calls_warm_transferred">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Cold Transferred Calls"
                                       description="The number of outbound calls that were cold transferred"
                                       :is_card="true"
                                       :metric="metrics.outbound_calls_cold_transferred">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="Untagged"
                                       description="The number of total untagged outbound communications"
                                       :is_card="true"
                                       :metric="metrics.outbound_untagged">
                        </metric-widget>
                    </div>
                    <div class="col-md-2 mb-3">
                        <metric-widget label="First Time"
                                       description="The number of total first time communications"
                                       :is_card="true"
                                       :metric="metrics.outbound_first_time">
                        </metric-widget>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <outbound-sms-rate-widget></outbound-sms-rate-widget>
            </div>
            <div class="col-md-9">
                <div class="row h-100">
                    <div class="col-md-7">
                        <broadcast-metrics-widget></broadcast-metrics-widget>
                    </div>
                    <div class="col-md-5">
                        <appointment-metrics-widget></appointment-metrics-widget>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OutboundConnectionRateWidget from "./widgets/outbound-connection-rate-widget"
import OutboundGraphWidget from "./widgets/outbound-graph-widget"
import MetricWidget from "./widgets/metric-widget"
import OutboundSmsRateWidget from "./widgets/outbound-sms-rate-widget"
import AppointmentMetricsWidget from "./widgets/appointment-metrics-widget"
import BroadcastMetricsWidget from "./widgets/broadcast-metrics-widget"
import DashboardFilters from "../../filters/dashboard-filter"

export default {
        name: "outbound-dashboard",

        components: {
            BroadcastMetricsWidget,
            AppointmentMetricsWidget,
            OutboundSmsRateWidget, MetricWidget, OutboundGraphWidget, OutboundConnectionRateWidget},

        data() {
            return {
                loading: false,
                metrics: {},
            }
        },

        computed: {
            kpiFilters() {
                return DashboardFilters.kpiFilters()
            }
        },

        mounted() {
            this.loadOutboundDashboardKpis()
        },

        methods: {
            loadOutboundDashboardKpis() {
                this.loading = true
                axios.get('/api/v1/kpis/communication/outbound/calls', {
                    params: {
                        aggregates: [
                            3, // AVERAGE_OUTBOUND_CALLS_TALK_TIME
                            4, // OUTBOUND_CALLS_ABOVE_FIVE_MINUTES
                            5, // OUTBOUND_CALLS_ABOVE_TWO_MINUTES
                            6, // OUTBOUND_CALLS_BELOW_THIRTY_SECONDS
                            7, // COLD_TRANSFERRED_OUTBOUND_CALLS
                            8, // WARM_TRANSFERRED_OUTBOUND_CALLS
                            35, // OUTBOUND_UNTAGGED
                            37, // OUTBOUND_FIRST_TIME
                        ],
                        filters: DashboardFilters.get()
                    }
                }).then(res => {
                    this.loading = false
                    this.metrics = res.data
                })
            }
        },

        watch: {
            kpiFilters() {
                this.loadOutboundDashboardKpis()
            },

        }
    }
</script>
