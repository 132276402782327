<template>
    <div>
        <div class="vertical-center text-left mb-4"
             v-if="series_name == 'campaign'">
            <span v-if="filter.from_date"
                  class="call-log-head">
                Lines Average Talk Time from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
            </span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
                Lines Average Talk Time
                <strong>All Time</strong>
            </span>
        </div>
        <div class="vertical-center text-left mb-4"
             v-else-if="series_name == 'ring_group'">
            <span v-if="filter.from_date"
                  class="call-log-head">
                Ring Groups Average Talk Time from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
            </span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
                Ring Groups Average Talk Time
                <strong>All Time</strong>
            </span>
        </div>
        <div class="vertical-center text-left mb-4"
             v-else-if="series_name == 'user'">
            <span v-if="filter.from_date"
                  class="call-log-head">
                Users Average Talk Time from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
            </span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
                Users Average Talk Time
                <strong>All Time</strong>
            </span>
        </div>
        <div class="placeholder text-center d-flex align-items-center justify-content-center"
             :class="{ blink: loading }"
             v-if="loading || campaigns.length == 0"
             style="height: 450px">
            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div style="height: 450px"
             v-else>
            <graph-data-switch :series="options.series"
                               :type="type"
                               :calls_only="true">
                <highcharts v-bind:id="graph_id"
                            v-show="is_done && options.series.length > 0"
                            :options="options"
                            style="height: 450px"
                            ref="highchart">
                </highcharts>
                <div class="el-table__empty-block"
                     v-show="is_done && (options.series.length == 0 || options.series[0].data.length == 0)">
                    <span class="el-table__empty-text"
                          style="color: #606266;">
                        No Data
                    </span>
                </div>
            </graph-data-switch>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {graph_mixin, report_mixin} from '../mixins'

    export default {
        props: {
            series_name: {
                default: 'campaign'
            },
            stacked: {
                type: Boolean,
                default: false
            },
            report_index: {
                default: 0
            },
            type: {}
        },

        mixins: [report_mixin, graph_mixin],

        data() {
            return {
                auth: auth,
                loading: false,
                axisLabels: [],
                graph_id: '',
                report_type: 'avg_talktime_v_campaign',
                options: {
                    chart: {
                        type: 'column',
                    },
                    plotOptions: {
                        column: {
                            stacking: null,
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    if (this.y != 0) {
                                        return this.y
                                    }
                                },
                                crop: false,
                                style: {
                                    fontSize: '14px'
                                }
                            }
                        }
                    },
                    xAxis: {
                        labels: {
                            enabled: false
                        }
                    },
                    yAxis: {
                        title: {
                            text: 'Average Talk Time (minutes)',
                            style: {
                                'font-size': '14px',
                                'color': '#090A0D'
                            }
                        }
                    },
                    tooltip: {
                        pointFormatter: function () {
                            var toReturn = '<b>' + this.series.name + '</b><br>'
                            toReturn += 'has ' + '<b>' + this.y + ' minutes</b> average talk time'

                            return toReturn
                        },
                    },
                }
            }
        },

        mounted() {
            this.setGraphId()
            this.setReportType()
            this.setIsStacked()
            this.getCommunications()
        },

        methods: {
            generateGraphData: function () {
                let data_series = []

                //get distinct series labels
                this.aggregated_counts.forEach((campaign_data, index) => {
                    //do not include nulls
                    if (campaign_data.series_name == null) {
                        return
                    }
                    //do not include empty data
                    if (Object.keys(campaign_data.data).length > 0) {
                        let yVal = 0
                        for (let key in campaign_data.data) {
                            yVal = parseFloat(campaign_data.data[key].name)
                        }

                        //add chart series
                        data_series.push({
                            name: campaign_data.series_name,
                            data: [yVal],
                        })
                    }

                    if (data_series.length) {
                        this.options.series = data_series
                    } else {
                        this.options.series = []
                    }
                })

                this.$emit('reportLoaded', this.report_index)
            },
            setReportType: function () {
                switch (this.series_name) {
                    case 'user':
                        this.report_type = 'avg_talktime_v_user'
                        break
                    case 'ring_group':
                        this.report_type = 'avg_talktime_v_ring_group'
                        break
                    default: //campaign
                        this.report_type = 'avg_talktime_v_campaign'
                        break
                }
            },
            setGraphId: function () {
                this.graph_id = 'avg-talktime-' + this.series_name
                if (this.stacked) {
                    this.graph_id += '-stacked'
                }
                this.graph_id += '-graph'
            },
            setIsStacked: function () {
                if (!this.stacked) {
                    this.options.plotOptions.column.stacking = null
                } else {
                    this.options.plotOptions.column.stacking = 'normal'
                }
            },
        },
        watch: {
            series_name: {
                handler: function (newValue, oldValue) {
                    this.setGraphId()
                    this.setReportType()
                    this.getCommunications()
                }
            },
            stacked: {
                handler: function (newValue, oldValue) {
                    this.setGraphId()
                    this.setIsStacked()
                    this.getCommunications()
                }
            },
        },
    }
</script>
