<template>
    <div>
        <el-select ref="variable_selector"
                   v-model="variable"
                   class="w-full"
                   :placeholder="'Select a Variable'"
                   :filter-method="filterOptions"
                   default-first-option
                   filterable
                   clearable
                   @visible-change="emitVisibleChangeEvent"
                   autocomplete="off">
            <el-option-group v-if="filteredContactVariables.length"
                             key="Contact Variables"
                             label="Contact Variables">
                <el-option
                    v-for="variable in filteredContactVariables"
                    :key="variable.value"
                    :label="variable.label"
                    :value="variable.value">
                    <div class="media">
                        <div class="media-body">
                            <label>{{ variable.label }}</label>
                            <small>{{ variable.description }}</small>
                        </div>
                    </div>
                </el-option>
            </el-option-group>
            <el-option-group v-if="filteredAgentVariables.length"
                             key="Agent Variables"
                             label="Agent Variables">
                <el-option
                    v-for="variable in filteredAgentVariables"
                    :key="variable.value"
                    :label="variable.label"
                    :value="variable.value">
                    <div class="media">
                        <div class="media-body">
                            <label>{{ variable.label }}</label>
                            <small>{{ variable.description }}</small>
                        </div>
                    </div>
                </el-option>
            </el-option-group>
            <el-option-group v-if="filteredLineVariables.length"
                             key="Line Variables"
                             label="Line Variables">
                <el-option
                    v-for="variable in filteredLineVariables"
                    :key="variable.value"
                    :label="variable.label"
                    :value="variable.value">
                    <div class="media">
                        <div class="media-body">
                            <label>{{ variable.label }}</label>
                            <small>{{ variable.description }}</small>
                        </div>
                    </div>
                </el-option>
            </el-option-group>
            <el-option-group v-if="filteredAccountVariables.length"
                             key="Account Variables"
                             label="Account Variables">
                <el-option
                    v-for="variable in filteredAccountVariables"
                    :key="variable.value"
                    :label="variable.label"
                    :value="variable.value">
                    <div class="media">
                        <div class="media-body">
                            <label>{{ variable.label }}</label>
                            <small>{{ variable.description }}</small>
                        </div>
                    </div>
                </el-option>
            </el-option-group>
            <el-option-group v-if="filteredCSFVariables.length"
                             key="CSF Variables"
                             label="CSF Variables">
                <el-option
                    v-for="variable in filteredCSFVariables"
                    :key="variable.value"
                    :label="variable.label"
                    :value="variable.value">
                    <div class="media">
                        <div class="media-body">
                            <label>{{ variable.label }}</label>
                            <small v-if="variable.description">
                                {{ variable.description }}
                            </small>
                        </div>
                    </div>
                </el-option>
            </el-option-group>
        </el-select>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    props: {
        mode: {
            required: false,
            type: String,
            default: 'default'
        }
    },

    data() {
        return {
            filtered_text: null,
            variable: null,
            contact_variables: [
                {
                    label: '[FirstName]',
                    value: '[FirstName]',
                    description: 'to include contact’s first name'
                },
                {
                    label: '[LastName]',
                    value: '[LastName]',
                    description: 'to include contact’s last name'
                },
                {
                    label: '[FullName]',
                    value: '[FullName]',
                    description: 'to include contact’s full name'
                },
                {
                    label: '[Email]',
                    value: '[Email]',
                    description: 'to include contact’s email address'
                },
                {
                    label: '[Website]',
                    value: '[Website]',
                    description: 'to include contact’s website'
                },
                {
                    label: '[Address]',
                    value: '[Address]',
                    description: 'to include contact’s address'
                },
                {
                    label: '[CompanyName]',
                    value: '[CompanyName]',
                    description: 'to include contact’s company name'
                },
                {
                    label: '[ContactNotes]',
                    value: '[ContactNotes]',
                    description: 'to include contact’s notes'
                },
                {
                    label: '[OwnerName]',
                    value: '[OwnerName]',
                    description: 'to include lead owner’s full name'
                },
                {
                    label: '[OwnerFirstName]',
                    value: '[OwnerFirstName]',
                    description: 'to include contact owner’s first name'
                },
                {
                    label: '[OwnerLastName]',
                    value: '[OwnerLastName]',
                    description: 'to include contact owner’s last name'
                },
                {
                    label: '[LeadNumber]',
                    value: '[LeadNumber]',
                    description: 'to include contact’s phone number'
                },
                {
                    label: '[NationalPhoneNumber]',
                    value: '[NationalPhoneNumber]',
                    description: 'to include contact’s phone number in National format'
                },
                {
                    label: '[InternationalPhoneNumber]',
                    value: '[InternationalPhoneNumber]',
                    description: 'to include contact’s phone number in International format'
                },
                {
                    label: '[E164PhoneNumber]',
                    value: '[E164PhoneNumber]',
                    description: 'to include contact’s phone number in E164 format'
                },
                {
                    label: '[City]',
                    value: '[City]',
                    description: 'to include contact’s city'
                },
                {
                    label: '[State]',
                    value: '[State]',
                    description: 'to include contact’s state (abbr)'
                },
                {
                    label: '[FullState]',
                    value: '[FullState]',
                    description: 'to include contact’s state full name'
                },
                {
                    label: '[ZipCode]',
                    value: '[ZipCode]',
                    description: 'to include contact’s zip code'
                },
                {
                    label: '[Country]',
                    value: '[Country]',
                    description: 'to include contact’s country'
                },
                {
                    label: '[DateOfBirth]',
                    value: '[DateOfBirth]',
                    description: 'to include contact’s date of birth'
                },
                {
                    label: '[ContactId]',
                    value: '[ContactId]',
                    description: 'to include the id of the contact (this is mostly used in tracking individual contacts through links)'
                },
                {
                    label: '[LeadSource]',
                    value: '[LeadSource]',
                    description: 'to include contact’s lead source'
                },
            ],
            agent_variables: [
                {
                    label: '[AgentName]',
                    value: '[AgentName]',
                    description: 'to include agent’s full name'
                },
                {
                    label: '[AgentFirstName]',
                    value: '[AgentFirstName]',
                    description: 'to include agent’s first name'
                },
                {
                    label: '[AgentLastName]',
                    value: '[AgentLastName]',
                    description: 'to include agent’s last name'
                },
            ],
            line_variables: [
                {
                    label: '[LineName]',
                    value: '[LineName]',
                    description: 'to include name of this line'
                },
                {
                    label: '[TrackingNumber]',
                    value: '[TrackingNumber]',
                    description: 'to include tracking number of this line'
                },
            ],
            account_variables: [
                {
                    label: '[AccountName]',
                    value: '[AccountName]',
                    description: 'to include the name of your account'
                },
                {
                    label: '[CompanyAddress]',
                    value: '[CompanyAddress]',
                    description: 'to include the full address of your company'
                },
                {
                    label: '[CompanyWebsite]',
                    value: '[CompanyWebsite]',
                    description: 'to include the website of your company'
                }
            ],
            csf_variables: [
                {
                    label: '[CSF1]',
                    value: '[CSF1]',
                    description: 'to include contact’s custom field 1'
                },
                {
                    label: '[CSF2]',
                    value: '[CSF2]',
                    description: 'to include contact’s custom field 2'
                }
            ]
        }
    },

    computed: {
        ...mapGetters({
            attribute_dictionaries: 'getAttributeDictionaries',
        }),

        ...mapGetters('cache', ['isSimpSocial']),

        ...mapState('cache', ['current_company']),

        filteredContactVariables() {
            if (this.filtered_text) {
                return this.contact_variables.filter((variable) =>
                    (variable.label && variable.label.toLowerCase().includes(this.filtered_text.toLowerCase()))
                )
            }
            return this.contact_variables
        },

        filteredAgentVariables() {
            if (this.filtered_text) {
                return this.agent_variables.filter((variable) =>
                    (variable.label && variable.label.toLowerCase().includes(this.filtered_text.toLowerCase()))
                )
            }
            return this.agent_variables
        },

        filteredLineVariables() {
            if (this.filtered_text) {
                return this.line_variables.filter((variable) =>
                    (variable.label && variable.label.toLowerCase().includes(this.filtered_text.toLowerCase()))
                )
            }
            return this.line_variables
        },

        filteredAccountVariables() {
            if (this.filtered_text) {
                return this.account_variables.filter((variable) =>
                    (variable.label && variable.label.toLowerCase().includes(this.filtered_text.toLowerCase()))
                )
            }
            return this.account_variables
        },

        filteredCSFVariables() {
            let csf_variables = Object.assign([], this.csf_variables)
            if (this.attribute_dictionaries && this.attribute_dictionaries.length) {
                for (let index in this.attribute_dictionaries) {
                    csf_variables.push({
                        label: '[csf:' + this.attribute_dictionaries[index].slug + ']',
                        value: '[csf:' + this.attribute_dictionaries[index].slug + ']',
                        description: this.attribute_dictionaries[index].description
                    })
                }
            }
            if (this.filtered_text) {
                return csf_variables.filter((variable) =>
                    (variable.label && variable.label.toLowerCase().includes(this.filtered_text.toLowerCase()))
                )
            }
            return csf_variables
        }
    },

    mounted() {
        this.configureCarVinVariable()
    },

    methods: {
        emitVisibleChangeEvent() {
            if (this.mode === 'default') {
                this.copyText()
            }

            if (this.mode === 'return') {
                if (this.variable) {
                    this.$emit('variable-selected', this.variable.trim())
                }
            }

            this.filtered_text = null
            this.variable = null
        },

        filterOptions(filterValue) {
            this.filtered_text = filterValue
        },

        copyText() {
            if (!this.variable) {
                return
            }
            this.$copyText(this.variable.trim())
                .then((res) => {
                    this.$message({
                        message: 'Copied to clipboard!',
                        type: 'success',
                        customClass: 'variable-copied-notify',
                        showClose: true
                    })
                }, (err) => {
                    this.$message({
                        message: 'Cannot copy to clipboard!',
                        type: 'error',
                        customClass: 'variable-copied-notify',
                        showClose: true
                    })
                    console.log(err)
                })
        },

        focusToSelector() {
            this.$nextTick(() => this.$refs.variable_selector.focus())
        },

        configureCarVinVariable() {
            if (this.isSimpSocial) {
                this.account_variables.push({
                    label: '[VehicleURL]',
                    value: '[VehicleURL vin=""]',
                    description: 'to include car\'s short URL based on VIN'
                })
            }
        }
    }
}
</script>
