var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: !_vm.show_tags ? "h-full" : "",
      attrs: { "data-testid": "communication-tags-wrapper" },
    },
    [
      _vm.hasPermissionTo("tag communication")
        ? _c(
            "div",
            { staticClass: "row", class: !_vm.show_tags ? "h-full" : "" },
            [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "el-popover",
                    {
                      ref: "popover_tags",
                      attrs: {
                        title: "Tags:",
                        placement: _vm.small ? "top" : "left",
                        width: 265,
                        "data-testid": "communication-tags-popover",
                        trigge: "click",
                      },
                      model: {
                        value: _vm.is_open,
                        callback: function ($$v) {
                          _vm.is_open = $$v
                        },
                        expression: "is_open",
                      },
                    },
                    [
                      _vm._t(
                        "trigger",
                        function () {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text text-dark-greenish text-sm p-0",
                                  "data-testid": "add-tags-btn",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-plus-outline",
                                }),
                                _vm._v(
                                  "\n                        Add Tags\n                    "
                                ),
                              ]
                            ),
                          ]
                        },
                        { slot: "reference" }
                      ),
                      _vm._v(" "),
                      _c("tag-creator", {
                        ref: "addTag",
                        attrs: {
                          category_prop: _vm.category,
                          "data-testid": "tag-creator",
                        },
                        on: {
                          success: _vm.addTagToCommunication,
                          show: _vm.showAdd,
                          hide: _vm.hideAdd,
                        },
                      }),
                      _vm._v(" "),
                      _vm.hasPermissionTo(["list tag", "view tag"]) &&
                      _vm.is_open
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.hide_add,
                                  expression: "!hide_add",
                                },
                              ],
                              staticClass: "row",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c("tag-selector", {
                                    attrs: {
                                      category_prop: _vm.category,
                                      multiple: true,
                                      "data-testid": "tag-selector",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeTags(
                                          $event,
                                          _vm.communication
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.communication.tag_ids,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.communication,
                                          "tag_ids",
                                          $$v
                                        )
                                      },
                                      expression: "communication.tag_ids",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.communication.tags &&
      _vm.communication.tags.length > 0 &&
      _vm.show_tags
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading_tag,
                  expression: "loading_tag",
                },
              ],
              staticClass: "row",
              attrs: { "data-testid": "communication-tags" },
            },
            [
              _c(
                "div",
                { staticClass: "col-12" },
                _vm._l(_vm.communication.tags, function (tag) {
                  return _c("div", { staticClass: "width-300" }, [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        style: { color: tag.color },
                      },
                      [_vm._v("label")]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-1 text-grey-900" }, [
                      _vm._v(_vm._s(tag.name)),
                    ]),
                  ])
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }