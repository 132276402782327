<template>
    <div v-bind="$attrs">
        <img src="/assets/images/img-empty.png" class="img-fluid">
    </div>
</template>

<script>
    export default {
        name: "img-empty"
    }
</script>
