var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "none" }, [
    _c("img", { attrs: { src: _vm.assets.logo } }),
    _vm._v(" "),
    _c("img", { attrs: { src: _vm.assets.logo_inverse } }),
    _vm._v(" "),
    _c("img", { attrs: { src: _vm.assets.logo_square_inverse } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }