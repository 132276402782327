<template>
    <div data-testid="alo-talk-info-wrapper">
        <h4
            class="listing-heading d-flex align-items-center alo-default-app-selector-title"
            slot="title"
            data-testid="new-name-talk">
            New {{ name }} Talk
            <img
                src="/assets/images/app-icons/tada.png"
                alt="tada-icon"
                class="ml-2"
                data-testid="tada-icon-img"
            >
        </h4>

        <div class="break-word" 
             data-testid="talk-introduction">
            <p>We're introducing <b>{{ name }} Talk</b>, our newest experience for agents with an improved UI and powerful features:</p>

            <ul data-testid="alo-talk-ul">
                <li data-testid="alo-talk-ul-li">
                    <el-link
                        type="primary"
                        href="https://support.aloware.com/en/articles/9037639-managing-inbox-and-task-in-aloware-talk"
                        target="_blank"
                        class="text-decoration-underline"
                        data-testid="li-inbox-task-manager"
                        :underline="false">
                        Inbox + Task Manager
                    </el-link>
                    - Easily see and manage missed calls, messages, and more
                </li>
                <li data-testid="alo-talk-ul-li">
                    <el-link
                        type="primary"
                        href="https://support.aloware.com/en/articles/9034179-creating-contact-lists-for-users-in-aloware-talk"
                        target="_blank"
                        class="text-decoration-underline"
                        data-testid="li-contact-lists"
                        :underline="false">
                        Contact Lists
                    </el-link>
                    - Intuitively manage contacts. Create powerful dynamic list
                </li>
                <li data-testid="alo-talk-ul-li">
                    <el-link
                        type="primary"
                        href="https://support.aloware.com/en/articles/9037639-managing-inbox-and-task-in-aloware-talk"
                        target="_blank"
                        class="text-decoration-underline"
                        data-testid="li-simple-views"
                        :underline="false">
                        Simple Views
                    </el-link>
                    - Easily filter and switch between views across different channels
                </li>
            </ul>

            <p data-testid="switch-aloware-classic">You can switch back to {{ alowareClassic }} at any time with the toggle in the top right of your screen.</p>
        </div>
    </div>
</template>

<script>
import {helper_mixin} from '../mixins'
export default {
    name: "alo-talk-info",

    mixins: [helper_mixin],

    data() {
        return {
            statics: null,
        }
    },

    computed: {
        name() {
            return this.statics?.whitelabel ? this.statics.name : 'Aloware'
        }
    },

    methods: {
        getStatics() {
            axios.get('/get-statics')
                .then(res => this.statics = res.data)
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        }
    },

    async created() {
        await this.getStatics()
    },
}
</script>
