// source: https://www.twilio.com/docs/messaging/services/smart-encoding-char-list
export const SMART_ENCODING_CHARS = [
    "00AB",
    "00BB",
    "201C",
    "201D",
    "02BA",
    "02EE",
    "201F",
    "275D",
    "275E",
    "301D",
    "301E",
    "FF02",
    "2018",
    "2019",
    "02BB",
    "02C8",
    "02BC",
    "02BD",
    "02B9",
    "201B",
    "FF07",
    "00B4",
    "0301",
    "02CA",
    "0060",
    "02CB",
    "275B",
    "275C",
    "0313",
    "0314",
    "FE10",
    "FE11",
    "00F7",
    "00BC",
    "00BD",
    "00BE",
    "29F8",
    "0337",
    "0338",
    "2044",
    "2215",
    "FF0F",
    "29F9",
    "29F5",
    "20E5",
    "FE68",
    "FF3C",
    "0332",
    "FF3F",
    "20D2",
    "20D3",
    "2223",
    "FF5C",
    "23B8",
    "23B9",
    "23D0",
    "239C",
    "239F",
    "23BC",
    "23BD",
    "2015",
    "FE63",
    "FF0D",
    "2010",
    "2022",
    "2043",
    "FE6B",
    "FF20",
    "FE69",
    "FF04",
    "01C3",
    "FE15",
    "FE57",
    "FF01",
    "FE5F",
    "FF03",
    "FE6A",
    "FF05",
    "FE60",
    "FF06",
    "201A",
    "0326",
    "FE50",
    "3001",
    "FE51",
    "FF0C",
    "FF64",
    "2768",
    "276A",
    "FE59",
    "FF08",
    "27EE",
    "2985",
    "2769",
    "276B",
    "FE5A",
    "FF09",
    "27EF",
    "2986",
    "204E",
    "2217",
    "229B",
    "2722",
    "2723",
    "2724",
    "2725",
    "2731",
    "2732",
    "2733",
    "273A",
    "273B",
    "273C",
    "273D",
    "2743",
    "2749",
    "274A",
    "274B",
    "29C6",
    "FE61",
    "FF0A",
    "02D6",
    "FE62",
    "FF0B",
    "3002",
    "FE52",
    "FF0E",
    "FF61",
    "FF10",
    "FF11",
    "FF12",
    "FF13",
    "FF14",
    "FF15",
    "FF16",
    "FF17",
    "FF18",
    "FF19",
    "02D0",
    "02F8",
    "2982",
    "A789",
    "FE13",
    "FF1A",
    "204F",
    "FE14",
    "FE54",
    "FF1B",
    "FE64",
    "FF1C",
    "0347",
    "A78A",
    "FE66",
    "FF1D",
    "FE65",
    "FF1E",
    "FE16",
    "FE56",
    "FF1F",
    "FF21",
    "1D00",
    "FF22",
    "0299",
    "FF23",
    "1D04",
    "FF24",
    "1D05",
    "FF25",
    "1D07",
    "FF26",
    "A730",
    "FF27",
    "0262",
    "FF28",
    "029C",
    "FF29",
    "026A",
    "FF2A",
    "1D0A",
    "FF2B",
    "1D0B",
    "FF2C",
    "029F",
    "FF2D",
    "1D0D",
    "FF2E",
    "0274",
    "FF2F",
    "1D0F",
    "FF30",
    "1D18",
    "FF31",
    "FF32",
    "0280",
    "FF33",
    "A731",
    "FF34",
    "1D1B",
    "FF35",
    "1D1C",
    "FF36",
    "1D20",
    "FF37",
    "1D21",
    "FF38",
    "FF39",
    "028F",
    "FF3A",
    "1D22",
    "02C6",
    "0302",
    "FF3E",
    "1DCD",
    "2774",
    "FE5B",
    "FF5B",
    "2775",
    "FE5C",
    "FF5D",
    "FF3B",
    "FF3D",
    "02DC",
    "02F7",
    "0303",
    "0330",
    "0334",
    "223C",
    "FF5E",
    "00A0",
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "200A",
    "200B",
    "202F",
    "205F",
    "3000",
    "FEFF",
    "008D",
    "009F",
    "0080",
    "0090",
    "009B",
    "0010",
    "0009",
    "0000",
    "0003",
    "0004",
    "0017",
    "0019",
    "0011",
    "0012",
    "0013",
    "0014",
    "2017",
    "0333",
    "2014",
    "2013",
    "2039",
    "203A",
    "203C",
    "201E",
    "2026",
    "2028",
    "2029",
    "2060",
    "0009", // TAB
    "0000",
    "0003",
    "0004",
    "0017",
    "0019",
    "0011",
    "0012",
    "0013",
    "0014",
    "2017",
    "2014",
    "2013",
    "2039",
    "203A",
    "203C",
    "201E",
    "2026",
    "2028",
    "2029",
    "2060",
];

// These are one ucs-2 characters which replace by multiple ascii characters
// For example => ½ will be replaced by 1/2 which is 3 characters and not 1
export const SMART_ENCODING_CHAR_COUNT = [
    {key: "203C", value: 2},
    {key: "2026", value: 3},
    {key: "00BC", value: 3},
    {key: "00BD", value: 3},
    {key: "00BE", value: 3},
];

export const SMS_ENCODING_TYPE_GSM_7 ="GSM-7";
export const SMS_ENCODING_TYPE_GSM_7_EXTENSION ="GSM-7 Extension";
export const SMS_ENCODING_TYPE_UCS_2 = "UCS-2";
export const SMS_ENCODING_TYPE_SEGMENT_LENGTH = {
    "GSM-7" : 160,
    "UCS-2" : 70,
};
export const SMS_CONCATENATED_ENCODING_TYPE_SEGMENT_LENGTH = {
    "GSM-7" : 153,
    "UCS-2" : 67,
};
export const SMS_GSM_7_CHARS = [
    "@", "£", "$", "¥", "è", "é", "ù", "ì", "ò", "Ç", "\n", "Ø", "ø", "\r", "Å", "å",
    "Δ", "_", "Φ", "Γ", "Λ", "Ω", "Π", "Ψ", "Σ", "Θ", "Ξ", "\x1b", "Æ", "æ", "ß", "É",
    " ", "!", "\"", "#", "¤", "%", "&", "'", "(", ")", "*", "+", ",", "-", ".", "/",
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ":", ";", "<", "=", ">", "?",
    "¡", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O",
    "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "Ä", "Ö", "Ñ", "Ü", "§",
    "¿", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o",
    "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "ä", "ö", "ñ", "ü", "à"
];

export const SMS_GSM_7_EXTENSION_CHARS = [
    "|", "^", "€", "{", "}", "[", "~", "]", "\\","\\f",
];

export const ENCODING_CHAR_COUNT = {
    "GSM-7" : 1,
    "UCS-2" : 2,
};
