var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.profile && _vm.company && !_vm.loading_whitelabel
    ? _c(
        "div",
        { staticClass: "app-body", attrs: { id: "view" } },
        [
          _c(
            "div",
            { staticClass: "container-fluid pt-3 d-relative" },
            [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading_company,
                          expression: "loading_company",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between mb-2" },
                        [
                          _c("h5", { staticClass: "_300 mb-0" }, [
                            _vm._v("Account"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              !_vm.isComplianceTabSelected &&
                              _vm.isTrial &&
                              !_vm.isSimpSocial
                                ? _c("video-modal", {
                                    staticClass: "pr-4",
                                    attrs: {
                                      title:
                                        "Exploring your Account Settings ⚙️",
                                      "cookie-name": "account",
                                      notes:
                                        "🔥 Master your experience in <strong>Account Settings</strong>!</br></br>Customize, tweak, and command your preferences to perfection.</br></br>Your epic journey of personalization begins now! 💥",
                                      "video-url":
                                        "https://www.youtube.com/embed/75nMW_ZkpA4?si=bIa0KSTaEidiiI-X",
                                      "learn-more-link":
                                        "https://support.aloware.com/en/articles/9037890-frequently-asked-questions-account-menu",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.loading_whitelabel &&
                              !_vm.statics.whitelabel &&
                              !_vm.isSimpSocial
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "ml-auto",
                                      attrs: {
                                        href: "https://meetings.hubspot.com/alwr/aloware-demo",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "success",
                                            size: "small",
                                            round: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            { staticClass: "material-icons" },
                                            [_vm._v("perm_contact_calendar")]
                                          ),
                                          _vm._v(
                                            "\n                                    Book a Demo\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tabs",
                        {
                          ref: "tabs",
                          attrs: {
                            "tab-position": "left",
                            type: "border-card",
                            id: "account-settings-tab",
                          },
                          on: { "tab-click": _vm.handleClick },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _vm.company && _vm.hasPermissionTo("update company")
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticClass: "px-3 py-2",
                                  attrs: {
                                    label: "General Settings",
                                    name: "settings",
                                  },
                                },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "companySettingsForm",
                                      staticClass: "form-aloware",
                                      attrs: {
                                        model: _vm.company_clone,
                                        rules: _vm.rules.company,
                                        "label-position": "top",
                                      },
                                      nativeOn: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.submitForm(
                                            "companySettingsForm"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-6" },
                                          [
                                            _vm.hasRole("Company Admin")
                                              ? _c(
                                                  "el-row",
                                                  { staticClass: "pb-3" },
                                                  [
                                                    _c(
                                                      "el-alert",
                                                      {
                                                        attrs: {
                                                          "show-icon": "",
                                                          closable: false,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "d-block mb-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Communication functionality and contact information are now only accessible in Aloware Talk. Click "
                                                            ),
                                                            _c("b", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: _vm.talk2Url,
                                                                    target:
                                                                      "_blank",
                                                                  },
                                                                },
                                                                [_vm._v("here")]
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              " to navigate to it."
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "name" } },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: { id: "name" },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [_vm._v("Name")]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Your main account name. Please contact us if you wish to\n                                                    change the legal owner of the account."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "Name of the company",
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.updateCompanySettings,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company_clone.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company_clone,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company_clone.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "timezone" } },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: { id: "timezone" },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v("Timezone"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    Account's main timezone. Sequences, business hours, and some\n                                                    more controls follow this timezone.\n                                                    This is also the base timezone for your reports.\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder:
                                                        "Select Your Timezone",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.updateCompanySettings,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company_clone
                                                          .timezone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company_clone,
                                                          "timezone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company_clone.timezone",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      _vm.timezones,
                                                      function (timezone) {
                                                        return _c("el-option", {
                                                          key: timezone.value,
                                                          attrs: {
                                                            label:
                                                              timezone.name,
                                                            value:
                                                              timezone.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.timezone.found
                                                      ? _c("el-option", {
                                                          attrs: {
                                                            label:
                                                              _vm.timezone.text,
                                                            value:
                                                              _vm.timezone
                                                                .value,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "private_inbox_toggle",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "private_inbox_toggle",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v("Private Lines"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Restrict agents from seeing other agents’ personal lines."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "switch-label",
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      attrs: {
                                                        "active-color":
                                                          "#00a344",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.updateCompanySettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.company_clone
                                                            .private_inbox_enabled,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.company_clone,
                                                            "private_inbox_enabled",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "company_clone.private_inbox_enabled",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", [
                                                      _vm._v(
                                                        "Enable Private Lines"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _vm.auth.user &&
                                                _vm.auth.user.profile
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mt-2 pointer text-blue",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                "/users/dialog/" +
                                                                _vm.auth.user
                                                                  .profile.id +
                                                                "?tab=visibility",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    Do you want to restrict individual agent line? Go to user's settings\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "send_long_messages_as_mms_toggle",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "send_long_messages_as_mms_toggle",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Send long text messages as MMS"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Messages longer than 160 characters will automatically send as an MMS rather than multiple SMS segments."
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "This setting can also be made on individual Line Settings."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "switch-label",
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      attrs: {
                                                        "active-color":
                                                          "#00a344",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.updateCompanySettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.company_clone
                                                            .send_long_messages_as_mms,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.company_clone,
                                                            "send_long_messages_as_mms",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "company_clone.send_long_messages_as_mms",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", [
                                                      _vm._v(
                                                        "Enable Send long text messages as MMS"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "h4",
                                              { staticClass: "mb-4 _500" },
                                              [_vm._v("Broadcast Settings")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "broadcasts_business_hours",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "broadcasts_business_hours",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Business Hours for Broadcasts"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Broadcast can only be run during business hours you set. This prevents users from accidentally sending Broadcasts late at night."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "form-inline",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-label mb-0",
                                                      },
                                                      [
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass:
                                                              "text-black",
                                                          },
                                                          [_vm._v("Timezone: ")]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "ml-2 font-weight-normal",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.companyTimezone
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "form-inline",
                                                  },
                                                  [
                                                    _c("time-range-picker", {
                                                      attrs: {
                                                        default_start_time:
                                                          _vm.company_clone
                                                            .broadcast_open,
                                                        default_end_time:
                                                          _vm.company_clone
                                                            .broadcast_close,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.updateBroadcastHours,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.broadcast_hours,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.broadcast_hours =
                                                            $$v
                                                        },
                                                        expression:
                                                          "broadcast_hours",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "sequence" } },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: { id: "sequence" },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Sequence Reenrollment"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Allow triggers to disenroll contacts from the current sequence and enroll in a different sequence? Note: Contacts can only be enrolled in one sequence at a time"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "switch-label",
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      attrs: {
                                                        "active-color":
                                                          "#00a344",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.updateCompanySettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.company_clone
                                                            .sequence_allow_reenrollment,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.company_clone,
                                                            "sequence_allow_reenrollment",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "company_clone.sequence_allow_reenrollment",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", [
                                                      _vm._v(
                                                        "Allow reenrollment"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "task_status_logs",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "task_status_logs",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Show Task Status Logs"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        'In the inbox, Admins can choose to hide the display of task status changes from the communication inbox. For example, "Open to Pending by System". By default users see every change, but if disabled then these logs will be hidden.'
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "switch-label",
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      attrs: {
                                                        "active-color":
                                                          "#00a344",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.updateCompanySettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.company_clone
                                                            .task_status_logs,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.company_clone,
                                                            "task_status_logs",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "company_clone.task_status_logs",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", [
                                                      _vm._v(
                                                        "Task Status Changes"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-2 d-none d-md-block ml-auto",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "list-group-item",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                On This Page\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: { href: "#name" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine("name")
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Name\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#timezone",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "timezone"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Timezone\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#private_inbox_toggle",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "private_inbox_toggle"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Private Lines\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#send_long_messages_as_mms_toggle",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "send_long_messages_as_mms_toggle"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Send long text messages as MMS\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#broadcasts_business_hours",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "broadcasts_business_hours"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Business Hours for Broadcasts\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#sequence",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "sequence"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Sequence Reenrollment\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#task_status_logs",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "task_status_logs"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                               Show Task Status Logs\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              staticClass: "px-3 py-2",
                              attrs: {
                                label: "Compliance",
                                name: "compliance",
                              },
                            },
                            [_c("compliance-tab")],
                            1
                          ),
                          _vm._v(" "),
                          _vm.company && _vm.hasPermissionTo("update company")
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticClass: "px-3 py-2",
                                  attrs: {
                                    label: "AloAi Voice Analytics",
                                    name: "transcription-settings",
                                    disabled:
                                      !_vm.company.transcription_enabled,
                                  },
                                },
                                [
                                  _c("account-transcription-settings", {
                                    ref: "account_transcription_settings",
                                    attrs: { company_clone: _vm.company_clone },
                                    on: { "update-parent": _vm.updateParent },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.company && _vm.hasPermissionTo("update company")
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticClass: "px-3 py-2",
                                  attrs: {
                                    label: "Contact Settings",
                                    name: "contact-settings",
                                  },
                                },
                                [
                                  _c("account-contact-settings", {
                                    ref: "account_contact_setting",
                                    attrs: { company_clone: _vm.company_clone },
                                    on: { "update-parent": _vm.updateParent },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              staticClass: "px-3 py-2",
                              attrs: {
                                label: "Calling Settings",
                                name: "calling-settings",
                              },
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "companySettingsForm",
                                  staticClass: "form-aloware",
                                  attrs: {
                                    model: _vm.company_clone,
                                    rules: _vm.rules.company,
                                    "label-position": "top",
                                  },
                                  nativeOn: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.submitForm(
                                        "companySettingsForm"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "force_users_always_available",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "force_users_always_available",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c(
                                                  "h5",
                                                  [
                                                    _vm._v(
                                                      "\n                                                    Force Users to Always Available\n                                                    "
                                                    ),
                                                    _c(
                                                      "el-popover",
                                                      {
                                                        attrs: {
                                                          placement: "right",
                                                          width: "450",
                                                          trigger: "hover",
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-flex font-weight-bold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Turning on 'Force Users to Always Available' Setting:"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "mb-0",
                                                            },
                                                            [
                                                              _c("li", [
                                                                _vm._v(
                                                                  "Users' statuses are set to 'Available' even after they login"
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("li", [
                                                                _vm._v(
                                                                  "Users cannot manually change their status"
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("li", [
                                                                _vm._v(
                                                                  "User's application will receive inbound calls until they logout"
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("li", [
                                                                _vm._v(
                                                                  "Admins can still manually change agent's statuses in Wallboard"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-info-circle text-primary ml-2",
                                                          attrs: {
                                                            slot: "reference",
                                                          },
                                                          slot: "reference",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _c(
                                                    "span",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _vm._v(
                                                        "Set all users' statuses to "
                                                      ),
                                                      _c(
                                                        "b",
                                                        { staticClass: "pl-1" },
                                                        [_vm._v("Available")]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _vm._v(
                                                        "Remove users' ability to manually change their status to "
                                                      ),
                                                      _c(
                                                        "b",
                                                        { staticClass: "pl-1" },
                                                        [_vm._v("Busy / Break")]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _vm._v(
                                                        "Admins can still manually change agents' statuses in Wallboard"
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "switch-label" },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.updateCompanySettings,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company_clone
                                                        .force_users_always_available,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company_clone,
                                                        "force_users_always_available",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company_clone.force_users_always_available",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v(
                                                    "Enable force users to always available"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { prop: "wrap_up_seconds" },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: { id: "wrap_up_seconds" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v("Wrap Up Duration"),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                    Time to keep the dialer in wrap-up before making the agent\n                                                    available again.\n                                                    Agents can override the setting in their profile.\n                                                "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "w-full",
                                                attrs: {
                                                  placeholder:
                                                    "Select wrap up seconds",
                                                },
                                                on: {
                                                  change:
                                                    _vm.updateCompanySettings,
                                                },
                                                model: {
                                                  value:
                                                    _vm.company_clone
                                                      .wrap_up_seconds,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.company_clone,
                                                      "wrap_up_seconds",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "company_clone.wrap_up_seconds",
                                                },
                                              },
                                              _vm._l(
                                                _vm.wrap_up_seconds,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "force_wrap_up" } },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: { id: "force_wrap_up" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v(
                                                    "Force Wrap Up Settings"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "Force wrap up duration on agents. This prevents the agents\n                                                    from overriding settings."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "switch-label" },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.updateCompanySettings,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company_clone
                                                        .force_wrap_up,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company_clone,
                                                        "force_wrap_up",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company_clone.force_wrap_up",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v(
                                                    "Enable force wrap up"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "RecordingMode",
                                          {
                                            attrs: {
                                              id: "inbound_call_recording_mode",
                                              title: "Inbound Call Recordings",
                                              options:
                                                _vm.inbound_recording_mode_options,
                                            },
                                            on: {
                                              change:
                                                _vm.updateInboundCallRecording,
                                            },
                                            model: {
                                              value:
                                                _vm.company_clone
                                                  .inbound_call_recording_mode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.company_clone,
                                                  "inbound_call_recording_mode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "company_clone.inbound_call_recording_mode",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            Force call recording for all inbound calls across Lines. By\n                                            default, this setting is configured inside each line.\n                                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.company_clone
                                          .inbound_call_recording_mode ===
                                        _vm.InboundCallRecordingModes
                                          .INBOUND_CALL_RECORDING_MODE_ALWAYS
                                          ? _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "You can record or upload an audio file for incoming phone call notification."
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "b-b nav-active-greenish",
                                                    },
                                                    [
                                                      _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "nav nav-tabs",
                                                        },
                                                        [
                                                          _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "nav-item",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "border border-right-0 rounded-top-left",
                                                                  class:
                                                                    _vm
                                                                      .recordNavClass
                                                                      .inbound_read,
                                                                  attrs: {
                                                                    href: "",
                                                                    "data-toggle":
                                                                      "tab",
                                                                    "data-target":
                                                                      "#inbound-record-tts",
                                                                    "aria-expanded":
                                                                      "true",
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-robot",
                                                                  }),
                                                                  _vm._v(
                                                                    "\n                                                                Text to Speech\n                                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.hasPermissionTo(
                                                            "update company"
                                                          )
                                                            ? _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "nav-item",
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "border rounded-top-right",
                                                                      class:
                                                                        _vm
                                                                          .recordNavClass
                                                                          .inbound_play,
                                                                      attrs: {
                                                                        href: "",
                                                                        "data-toggle":
                                                                          "tab",
                                                                        "data-target":
                                                                          "#inbound-record-file",
                                                                        "aria-expanded":
                                                                          "false",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-play text-md",
                                                                      }),
                                                                      _vm._v(
                                                                        "\n                                                                Play Recording\n                                                            "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tab-content p-a m-b-md border border-top-0",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            _vm
                                                              .recordContainerClass
                                                              .inbound_read,
                                                          attrs: {
                                                            id: "inbound-record-tts",
                                                            "aria-expanded":
                                                              "true",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Recording Message",
                                                                prop: "should_record",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-12",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input-group",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm
                                                                                        .company_clone
                                                                                        .inbound_record_tts,
                                                                                    expression:
                                                                                      "company_clone.inbound_record_tts",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "form-control pt-2 pb-2",
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .company_clone
                                                                                      .inbound_record_tts,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  _vm.updateInboundCallRecording,
                                                                                input:
                                                                                  [
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.$set(
                                                                                        _vm.company_clone,
                                                                                        "inbound_record_tts",
                                                                                        $event
                                                                                          .target
                                                                                          .value
                                                                                      )
                                                                                    },
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.preValidateForm(
                                                                                        "companySettingsForm"
                                                                                      )
                                                                                    },
                                                                                  ],
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "variable-dialog"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasPermissionTo(
                                                        "update company"
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              class:
                                                                _vm
                                                                  .recordContainerClass
                                                                  .inbound_play,
                                                              attrs: {
                                                                id: "inbound-record-file",
                                                                "aria-expanded":
                                                                  "false",
                                                              },
                                                            },
                                                            [
                                                              _vm.company_clone
                                                                .inbound_record_file ===
                                                              null
                                                                ? _c(
                                                                    "audio-recorder",
                                                                    {
                                                                      attrs: {
                                                                        responsive: true,
                                                                        action:
                                                                          "/api/v1/company/" +
                                                                          _vm
                                                                            .company_clone
                                                                            .id +
                                                                          "/upload-ivr/inbound_recording_notices",
                                                                        upload_type:
                                                                          "inbound_record_file",
                                                                      },
                                                                      on: {
                                                                        applyUploadedAudio:
                                                                          _vm.applyUploadedAudio,
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "audio-uploader-wrapper",
                                                                },
                                                                [
                                                                  _vm
                                                                    .company_clone
                                                                    .inbound_record_file ===
                                                                  null
                                                                    ? _c(
                                                                        "el-upload",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              drag: "",
                                                                              headers:
                                                                                _vm.headers,
                                                                              action:
                                                                                "/api/v1/company/" +
                                                                                _vm
                                                                                  .company_clone
                                                                                  .id +
                                                                                "/upload-ivr/inbound_recording_notices",
                                                                              "on-success":
                                                                                _vm.onSuccessUploadInboundRecording,
                                                                              "on-error":
                                                                                _vm.onFailedUploadRecording,
                                                                              "on-progress":
                                                                                _vm.progressUploadRecording,
                                                                              "before-upload":
                                                                                _vm.beforeUploadInboundRecording,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-upload",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "el-upload__text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                    Drop file here or "
                                                                              ),
                                                                              _c(
                                                                                "em",
                                                                                [
                                                                                  _vm._v(
                                                                                    "click to\n                                                                    upload"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "el-upload__tip",
                                                                              attrs:
                                                                                {
                                                                                  slot: "tip",
                                                                                },
                                                                              slot: "tip",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                    MP3/WAV file (less than 8MB)\n                                                                "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm
                                                                    .company_clone
                                                                    .inbound_record_file ===
                                                                  null
                                                                    ? _c(
                                                                        "el-progress",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "text-inside": true,
                                                                              "stroke-width": 18,
                                                                              percentage:
                                                                                _vm
                                                                                  .uploadPercentage
                                                                                  .record,
                                                                              status:
                                                                                _vm
                                                                                  .uploadStatus
                                                                                  .record,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex justify-content-start align-items-center",
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .company_clone
                                                                        .inbound_record_file !==
                                                                      null
                                                                        ? _c(
                                                                            "audio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  controls:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "source",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      src:
                                                                                        "/static/uploaded_file/" +
                                                                                        _vm
                                                                                          .company_clone
                                                                                          .inbound_record_file,
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                "\n                                                                    Your browser does not support the audio element.\n                                                                "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm
                                                                        .company_clone
                                                                        .inbound_record_file !==
                                                                        null &&
                                                                      _vm.hasPermissionTo(
                                                                        "update company"
                                                                      )
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-sm btn-danger ml-3",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.deleteFile(
                                                                                      "inbound_recording_notices"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "show",
                                                                                        rawName:
                                                                                          "v-show",
                                                                                        value:
                                                                                          _vm.loading_inbound_recording,
                                                                                        expression:
                                                                                          "loading_inbound_recording",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "material-icons loader pull-left",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    ""
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "show",
                                                                                        rawName:
                                                                                          "v-show",
                                                                                        value:
                                                                                          !_vm.loading_inbound_recording,
                                                                                        expression:
                                                                                          "!loading_inbound_recording",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "material-icons pull-left",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    ""
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Remove file"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "RecordingMode",
                                          {
                                            attrs: {
                                              id: "outbound_call_recording_mode",
                                              title: "Outbound Call Recordings",
                                              options:
                                                _vm.outbound_recording_mode_options,
                                            },
                                            on: {
                                              change:
                                                _vm.updateOutboundCallRecording,
                                            },
                                            model: {
                                              value:
                                                _vm.company_clone
                                                  .outbound_call_recording_mode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.company_clone,
                                                  "outbound_call_recording_mode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "company_clone.outbound_call_recording_mode",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            Force call recording for all outbound calls across all agents.\n                                            By default, this setting is configured per agent.\n                                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "RecordingForce",
                                          {
                                            attrs: {
                                              id: "force_outbound_recording",
                                              title:
                                                "Force Outbound Call Recording Settings",
                                            },
                                            on: {
                                              change: _vm.updateCompanySettings,
                                            },
                                            model: {
                                              value:
                                                _vm.company_clone
                                                  .force_outbound_recording,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.company_clone,
                                                  "force_outbound_recording",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "company_clone.force_outbound_recording",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            Enable force outbound call recording\n                                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.company_clone
                                          .outbound_call_recording_mode ===
                                        _vm.OutboundCallRecordingModes
                                          .OUTBOUND_CALL_RECORDING_MODE_ALWAYS
                                          ? _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "You can record or upload an audio file for outgoing phone call notifications."
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "b-b nav-active-greenish",
                                                    },
                                                    [
                                                      _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "nav nav-tabs",
                                                        },
                                                        [
                                                          _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "nav-item",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "border border-right-0 rounded-top-left",
                                                                  class:
                                                                    _vm
                                                                      .recordNavClass
                                                                      .outbound_read,
                                                                  attrs: {
                                                                    href: "",
                                                                    "data-toggle":
                                                                      "tab",
                                                                    "data-target":
                                                                      "#outbound-record-tts",
                                                                    "aria-expanded":
                                                                      "true",
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-robot",
                                                                  }),
                                                                  _vm._v(
                                                                    "\n                                                                Text to Speech\n                                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.hasPermissionTo(
                                                            "update company"
                                                          )
                                                            ? _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "nav-item",
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "border rounded-top-right",
                                                                      class:
                                                                        _vm
                                                                          .recordNavClass
                                                                          .outbound_play,
                                                                      attrs: {
                                                                        href: "",
                                                                        "data-toggle":
                                                                          "tab",
                                                                        "data-target":
                                                                          "#outbound-record-file",
                                                                        "aria-expanded":
                                                                          "false",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fa fa-play text-md",
                                                                      }),
                                                                      _vm._v(
                                                                        "\n                                                                Play Recording\n                                                            "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tab-content p-a m-b-md border border-top-0",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            _vm
                                                              .recordContainerClass
                                                              .outbound_read,
                                                          attrs: {
                                                            id: "outbound-record-tts",
                                                            "aria-expanded":
                                                              "true",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Recording Message",
                                                                prop: "should_record",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-12",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input-group",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm
                                                                                        .company_clone
                                                                                        .outbound_record_tts,
                                                                                    expression:
                                                                                      "company_clone.outbound_record_tts",
                                                                                  },
                                                                                ],
                                                                              staticClass:
                                                                                "form-control pt-2 pb-2",
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .company_clone
                                                                                      .outbound_record_tts,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  _vm.updateOutboundCallRecording,
                                                                                input:
                                                                                  [
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        $event
                                                                                          .target
                                                                                          .composing
                                                                                      )
                                                                                        return
                                                                                      _vm.$set(
                                                                                        _vm.company_clone,
                                                                                        "outbound_record_tts",
                                                                                        $event
                                                                                          .target
                                                                                          .value
                                                                                      )
                                                                                    },
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.preValidateForm(
                                                                                        "companySettingsForm"
                                                                                      )
                                                                                    },
                                                                                  ],
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "variable-dialog"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.hasPermissionTo(
                                                        "update company"
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              class:
                                                                _vm
                                                                  .recordContainerClass
                                                                  .outbound_play,
                                                              attrs: {
                                                                id: "outbound-record-file",
                                                                "aria-expanded":
                                                                  "false",
                                                              },
                                                            },
                                                            [
                                                              _vm.company_clone
                                                                .outbound_record_file ===
                                                              null
                                                                ? _c(
                                                                    "audio-recorder",
                                                                    {
                                                                      attrs: {
                                                                        responsive: true,
                                                                        action:
                                                                          "/api/v1/company/" +
                                                                          _vm
                                                                            .company_clone
                                                                            .id +
                                                                          "/upload-ivr/outbound_recording_notices",
                                                                        upload_type:
                                                                          "outbound_record_file",
                                                                      },
                                                                      on: {
                                                                        applyUploadedAudio:
                                                                          _vm.applyUploadedAudio,
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "audio-uploader-wrapper",
                                                                },
                                                                [
                                                                  _vm
                                                                    .company_clone
                                                                    .outbound_record_file ===
                                                                  null
                                                                    ? _c(
                                                                        "el-upload",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              drag: "",
                                                                              headers:
                                                                                _vm.headers,
                                                                              action:
                                                                                "/api/v1/company/" +
                                                                                _vm
                                                                                  .company_clone
                                                                                  .id +
                                                                                "/upload-ivr/outbound_recording_notices",
                                                                              "on-success":
                                                                                _vm.onSuccessUploadOutboundRecording,
                                                                              "on-error":
                                                                                _vm.onFailedUploadRecording,
                                                                              "on-progress":
                                                                                _vm.progressUploadRecording,
                                                                              "before-upload":
                                                                                _vm.beforeUploadOutboundRecording,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-upload",
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "el-upload__text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                    Drop file here or "
                                                                              ),
                                                                              _c(
                                                                                "em",
                                                                                [
                                                                                  _vm._v(
                                                                                    "click to upload"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "el-upload__tip",
                                                                              attrs:
                                                                                {
                                                                                  slot: "tip",
                                                                                },
                                                                              slot: "tip",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                                    MP3/WAV file (less than 8MB)\n                                                                "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm
                                                                    .company_clone
                                                                    .outbound_record_file ===
                                                                  null
                                                                    ? _c(
                                                                        "el-progress",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "text-inside": true,
                                                                              "stroke-width": 18,
                                                                              percentage:
                                                                                _vm
                                                                                  .uploadPercentage
                                                                                  .record,
                                                                              status:
                                                                                _vm
                                                                                  .uploadStatus
                                                                                  .record,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex justify-content-start align-items-center",
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .company_clone
                                                                        .outbound_record_file !==
                                                                      null
                                                                        ? _c(
                                                                            "audio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  controls:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "source",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      src:
                                                                                        "/static/uploaded_file/" +
                                                                                        _vm
                                                                                          .company_clone
                                                                                          .outbound_record_file,
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                "\n                                                                    Your browser does not support the audio element.\n                                                                "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm
                                                                        .company_clone
                                                                        .outbound_record_file !==
                                                                        null &&
                                                                      _vm.hasPermissionTo(
                                                                        "update company"
                                                                      )
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-sm btn-danger ml-3",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.deleteFile(
                                                                                      "outbound_recording_notices"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "show",
                                                                                        rawName:
                                                                                          "v-show",
                                                                                        value:
                                                                                          _vm.loading_outbound_recording,
                                                                                        expression:
                                                                                          "loading_outbound_recording",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "material-icons loader pull-left",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    ""
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "show",
                                                                                        rawName:
                                                                                          "v-show",
                                                                                        value:
                                                                                          !_vm.loading_outbound_recording,
                                                                                        expression:
                                                                                          "!loading_outbound_recording",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "material-icons pull-left",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    ""
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Remove file"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "default_outbound_campaign_id",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "default_outbound_campaign_id",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v(
                                                    "Default Outbound Line"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                    Choose an outbound line for your outbound calls.\n                                                    You can also let the agents choose this setting on their\n                                                    profile.\n                                                "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("campaign-selector", {
                                              attrs: {
                                                class_prop:
                                                  "no-select mb-3 w-full",
                                                placeholder: "Always ask",
                                                show_paused: false,
                                                clearable: "",
                                                filterable: "",
                                              },
                                              on: {
                                                change:
                                                  _vm.updateDefaultOutboundCampaignId,
                                              },
                                              model: {
                                                value:
                                                  _vm.company_clone
                                                    .default_outbound_campaign_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company_clone,
                                                    "default_outbound_campaign_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "company_clone.default_outbound_campaign_id",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "alert alert-warning alert-aloware d-inline-block lh-1 p-2",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-exclamation-triangle",
                                                }),
                                                _vm._v(
                                                  "\n                                                If no line is selected, your agents will be prompted to choose a\n                                                line before making an outbound call on the dialer.\n                                            "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "wait_voice_enabled",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "wait_voice_enabled",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v("Wait Voice"),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "Make or prevent agents from hearing "
                                                  ),
                                                  _c("i", [
                                                    _vm._v("Connecting..."),
                                                  ]),
                                                  _vm._v(
                                                    " at the beginning of outbound calls."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "switch-label" },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.updateCompanySettings,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company_clone
                                                        .wait_voice_enabled,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company_clone,
                                                        "wait_voice_enabled",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company_clone.wait_voice_enabled",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v("Enable Wait Voice"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "force_outbound_line",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "force_outbound_line",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v(
                                                    "Force Outbound Line Settings"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "Prevent agents from overriding these settings."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "switch-label" },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.updateCompanySettings,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company_clone
                                                        .force_outbound_line,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company_clone,
                                                        "force_outbound_line",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company_clone.force_outbound_line",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v(
                                                    "Enable force outbound line settings on users"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-form-item", [
                                          _c("span", {
                                            staticClass: "page-anchor",
                                            attrs: {
                                              id: "force_call_disposition",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c("h5", [
                                                _vm._v(
                                                  "Force Call Disposition (Beta)"
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  "\n                                                    If this option is turned on, wrap-up timer will be\n                                                    paused, back button, callback, and agent status dropdown\n                                                    will be locked until the agent disposes of the call.\n                                                "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "switch-label" },
                                            [
                                              _c("el-switch", {
                                                attrs: {
                                                  "active-color": "#00a344",
                                                },
                                                on: {
                                                  change:
                                                    _vm.updateCompanySettings,
                                                },
                                                model: {
                                                  value:
                                                    _vm.company_clone
                                                      .force_call_disposition,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.company_clone,
                                                      "force_call_disposition",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "company_clone.force_call_disposition",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("label", [
                                                _vm._v(
                                                  "Enable force call disposition"
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-form-item", [
                                          _c("span", {
                                            staticClass: "page-anchor",
                                            attrs: {
                                              id: "force_contact_disposition",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c("h5", [
                                                _vm._v(
                                                  "Force Contact Disposition (Beta)"
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  "\n                                                    If this option is turned on, wrap-up timer will be\n                                                    paused, back button, callback, and agent status dropdown\n                                                    will be locked until the agent disposes of the contact.\n                                                "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "switch-label" },
                                            [
                                              _c("el-switch", {
                                                attrs: {
                                                  "active-color": "#00a344",
                                                },
                                                on: {
                                                  change:
                                                    _vm.updateCompanySettings,
                                                },
                                                model: {
                                                  value:
                                                    _vm.company_clone
                                                      .force_contact_disposition,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.company_clone,
                                                      "force_contact_disposition",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "company_clone.force_contact_disposition",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("label", [
                                                _vm._v(
                                                  "Enable force contact disposition"
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        false
                                          ? _c("el-form-item", [
                                              _c("span", {
                                                staticClass: "page-anchor",
                                                attrs: {
                                                  id: "show_call_missed_modal",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-label" },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      "Missed-Call Status "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "label indigo",
                                                      },
                                                      [_vm._v("Talk Only")]
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("small", [
                                                    _vm._v(
                                                      "\n                                                    When a call is missed, the agent status will change to Busy.\n                                                    However, if this option is enabled, it will allow agents to mark\n                                                    themselves as available with a pop-up after the missed call.\n                                                "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "switch-label" },
                                                [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#00a344",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.updateCompanySettings,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company_clone
                                                          .show_call_missed_modal,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company_clone,
                                                          "show_call_missed_modal",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company_clone.show_call_missed_modal",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("label", [
                                                    _vm._v(
                                                      "Show missed call pop-up"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("el-form-item", [
                                          _c("span", {
                                            staticClass: "page-anchor",
                                            attrs: { id: "hold_music" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c("h5", [_vm._v("Hold Music")]),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  "\n                                                    Here you can customize the hold music for the entire account. Leave blank to use our default hold music.\n                                                "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "switch-label" },
                                            [
                                              _c("el-switch", {
                                                attrs: {
                                                  "active-color": "#00a344",
                                                },
                                                on: {
                                                  change:
                                                    _vm.checkHoldMusicFile,
                                                },
                                                model: {
                                                  value:
                                                    _vm.show_custom_hold_music,
                                                  callback: function ($$v) {
                                                    _vm.show_custom_hold_music =
                                                      $$v
                                                  },
                                                  expression:
                                                    "show_custom_hold_music",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "el-form-item__label ml-2",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    Customize hold music\n                                                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.show_custom_hold_music
                                            ? _c(
                                                "div",
                                                [
                                                  _vm.company_clone
                                                    .hold_music_file === null
                                                    ? _c("audio-recorder", {
                                                        staticClass:
                                                          "audio-recorder-wrapper",
                                                        attrs: {
                                                          action:
                                                            "/api/v1/company/" +
                                                            _vm.company_clone
                                                              .id +
                                                            "/upload-ivr/hold_musics",
                                                          upload_type:
                                                            "hold_music_file",
                                                        },
                                                        on: {
                                                          applyUploadedAudio:
                                                            _vm.applyUploadedAudio,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "audio-uploader-wrapper",
                                                    },
                                                    [
                                                      _vm.company_clone
                                                        .hold_music_file ===
                                                      null
                                                        ? _c(
                                                            "el-upload",
                                                            {
                                                              attrs: {
                                                                action:
                                                                  "/api/v1/company/" +
                                                                  _vm
                                                                    .company_clone
                                                                    .id +
                                                                  "/upload-ivr/hold_musics",
                                                                headers:
                                                                  _vm.headers,
                                                                "on-success":
                                                                  _vm.onSuccessUploadHoldMusic,
                                                                "on-error":
                                                                  _vm.onFailedUploadHoldMusic,
                                                                "on-progress":
                                                                  _vm.progressUploadHoldMusic,
                                                                "before-upload":
                                                                  _vm.beforeUploadHoldMusic,
                                                                "file-list":
                                                                  _vm
                                                                    .uploadFileList
                                                                    .holdMusic,
                                                                drag: "",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-upload",
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "el-upload__text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                            Drop file here or "
                                                                  ),
                                                                  _c("em", [
                                                                    _vm._v(
                                                                      "click to upload"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "el-upload__tip",
                                                                  attrs: {
                                                                    slot: "tip",
                                                                  },
                                                                  slot: "tip",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                            MP3/WAV file (less than 8MB)\n                                                        "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.company_clone
                                                        .hold_music_file ===
                                                      null
                                                        ? _c("el-progress", {
                                                            attrs: {
                                                              "text-inside": true,
                                                              "stroke-width": 18,
                                                              percentage:
                                                                _vm
                                                                  .uploadPercentage
                                                                  .holdMusic,
                                                              status:
                                                                _vm.uploadStatus
                                                                  .holdMusic,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.company_clone
                                                        .hold_music_file !==
                                                      null
                                                        ? _c(
                                                            "audio",
                                                            {
                                                              attrs: {
                                                                controls: "",
                                                              },
                                                            },
                                                            [
                                                              _c("source", {
                                                                attrs: {
                                                                  src:
                                                                    "/static/uploaded_file/" +
                                                                    _vm
                                                                      .company_clone
                                                                      .hold_music_file,
                                                                },
                                                              }),
                                                              _vm._v(
                                                                "\n                                                        Your browser does not support the audio element.\n                                                    "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.company_clone
                                                        .hold_music_file !==
                                                      null
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-sm btn-danger pull-right",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.deleteFile(
                                                                      "hold_musics"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.loading_hold_music,
                                                                      expression:
                                                                        "loading_hold_music",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "material-icons loader pull-left",
                                                                },
                                                                [_vm._v("")]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "i",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        !_vm.loading_hold_music,
                                                                      expression:
                                                                        "!loading_hold_music",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "material-icons pull-left",
                                                                },
                                                                [_vm._v("")]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Remove file"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-form-item", [
                                          _c("span", {
                                            staticClass: "page-anchor",
                                            attrs: {
                                              id: "dual_channel_recording",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "form-label" },
                                            [
                                              _c("h5", [
                                                _vm._v(
                                                  "Call Recording Channels"
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("small", [
                                                _vm._v(
                                                  "\n                                                    Enable dual-channel recording for calls\n                                                "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "switch-label" },
                                            [
                                              _c("el-switch", {
                                                attrs: {
                                                  "active-color": "#00a344",
                                                },
                                                on: {
                                                  change:
                                                    _vm.updateCompanySettings,
                                                },
                                                model: {
                                                  value:
                                                    _vm.company_clone
                                                      .dual_channel_recording,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.company_clone,
                                                      "dual_channel_recording",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "company_clone.dual_channel_recording",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("label", [
                                                _vm._v(
                                                  "Enable dual-channel recording for calls"
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "mt-3",
                                            attrs: {
                                              prop: "caller_id_spoofing",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: { id: "call_masking" },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v("Call Masking"),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "Enable to use lead’s phone number for all transfers (inbound and outbound)"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "switch-label" },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.updateCompanySettings,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company_clone
                                                        .caller_id_spoofing,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company_clone,
                                                        "caller_id_spoofing",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company_clone.caller_id_spoofing",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v("Enable Call Masking"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "mt-3",
                                            attrs: {
                                              prop: "fishing_mode_notification_sound",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "notification_sound_of_fishing_mode",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v(
                                                    "Notification Sound of Fishing Mode"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "When Fishing Mode is turned on, the sound of the notification for each user during an incoming call can make a single notification sound or ring like a normal call."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "switch-label" },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.updateCompanySettings,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company_clone
                                                        .fishing_mode_notification_sound,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company_clone,
                                                        "fishing_mode_notification_sound",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company_clone.fishing_mode_notification_sound",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v("Normal Ring Enabled"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "call_recording_retention",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "call_recording_retention",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v(
                                                    "Call records retention duration"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "retention-duration-feature-overview"
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  placement: "top",
                                                  effect: "dark",
                                                  disabled:
                                                    _vm.hasRole(
                                                      "Billing Admin"
                                                    ),
                                                  content:
                                                    "Disabled - only Billing Admins can change this setting",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "width-300",
                                                    attrs: {
                                                      disabled:
                                                        !_vm.hasRole(
                                                          "Billing Admin"
                                                        ),
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.updateCompanySettings,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company_clone
                                                          .call_recording_retention,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company_clone,
                                                          "call_recording_retention",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company_clone.call_recording_retention",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        value:
                                                          _vm
                                                            .CallRecordsRetentionDurations
                                                            .CALL_RECORDS_RETENTION_DURATIONS_INDEFINITE,
                                                        label: "Indefinite",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value:
                                                          _vm
                                                            .CallRecordsRetentionDurations
                                                            .CALL_RECORDS_RETENTION_DURATIONS_FOLLOW_LINE,
                                                        label:
                                                          "Follow Line’s Setting",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value:
                                                          _vm
                                                            .CallRecordsRetentionDurations
                                                            .CALL_RECORDS_RETENTION_DURATIONS_10_DAYS,
                                                        label: "10 days",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value:
                                                          _vm
                                                            .CallRecordsRetentionDurations
                                                            .CALL_RECORDS_RETENTION_DURATIONS_30_DAYS,
                                                        label: "30 days",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        value:
                                                          _vm
                                                            .CallRecordsRetentionDurations
                                                            .CALL_RECORDS_RETENTION_DURATIONS_60_DAYS,
                                                        label: "60 days",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-2 d-none d-md-block ml-auto",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
                                          },
                                          [
                                            _c(
                                              "h6",
                                              {
                                                staticClass: "list-group-item",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                On This Page\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#force_users_always_available",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "force_users_always_available"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Force Users to Always Available\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#wrap_up_seconds",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "wrap_up_seconds"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Wrap Up Duration\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#force_wrap_up",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "force_wrap_up"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Force Wrap Up Settings\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#inbound_call_recording_mode",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "inbound_call_recording_mode"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Inbound Call Recordings\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#outbound_call_recording_mode",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "outbound_call_recording_mode"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Outbound Call Recordings\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#force_outbound_recording",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "force_outbound_recording"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Force Outbound Call Recording\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#default_outbound_campaign_id",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "default_outbound_campaign_id"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Default Outbound Line\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#wait_voice_enabled",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "wait_voice_enabled"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Wait Voice\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#force_outbound_line",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "force_outbound_line"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Force Outbound Line Settings\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#force_call_disposition",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "force_call_disposition"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Force Call Disposition\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#force_contact_disposition",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "force_contact_disposition"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Force Contact Disposition\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            false
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#show_call_missed_modal",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "show_call_missed_modal"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Missed-Call Status\n                                            "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: { href: "#hold_music" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "hold_music"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Hold Music\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#dual_channel_recording",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "dual_channel_recording"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Call Recording Channels\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#call_masking",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "call_masking"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Call Masking\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#notification_sound_of_fishing_mode",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "notification_sound_of_fishing_mode"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Notification Sound of Fishing Mode\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#call_recording_retention",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "call_recording_retention"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Call records retention duration\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              staticClass: "px-3 py-2",
                              attrs: {
                                label: "Inbox Settings",
                                name: "inbox-filters",
                              },
                            },
                            [
                              _c("inbox-filter-index", {
                                attrs: { company_clone: _vm.company_clone },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              staticClass: "px-3 py-2",
                              attrs: {
                                label: "PowerDialer Settings",
                                name: "power-dialer-settings",
                                disabled: !_vm.profile.auto_dialer_enabled,
                              },
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "companySettingsForm",
                                  staticClass: "form-aloware",
                                  attrs: {
                                    model: _vm.company_clone,
                                    rules: _vm.rules.company,
                                    "label-position": "top",
                                  },
                                  nativeOn: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.submitForm(
                                        "companySettingsForm"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-9" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "row mb-3" },
                                          [
                                            _c(
                                              "el-alert",
                                              { attrs: { "show-icon": "" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "d-block mb-1",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("Attention:"),
                                                    ]),
                                                    _vm._v(
                                                      " The Power Dialer settings on this page will affect the Power Dialing sessions of all users.\n                                                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass:
                                              "no-border pb-2 width-500",
                                            attrs: {
                                              prop: "default_power_dialer_campaign_id",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "default_power_dialer_campaign_id",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v(
                                                    "Default PowerDialer Line"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                    Choose a line (Caller ID) for your PowerDialer calls.\n                                                    You can also let the agents choose this setting on their\n                                                    PowerDialer.\n                                                "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("campaign-selector", {
                                              attrs: {
                                                class_prop:
                                                  "no-select mb-3 w-full",
                                                placeholder: "Always ask",
                                                show_paused: false,
                                                clearable: "",
                                                filterable: "",
                                              },
                                              on: {
                                                change:
                                                  _vm.updateDefaultPowerDialerCampaignId,
                                              },
                                              model: {
                                                value:
                                                  _vm.company_clone
                                                    .default_power_dialer_campaign_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.company_clone,
                                                    "default_power_dialer_campaign_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "company_clone.default_power_dialer_campaign_id",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "no-border",
                                            attrs: {
                                              prop: "disable_power_dialer_add",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "disable_power_dialer_add",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v("Master Control"),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                    By selecting this option, only admins can add/clear tasks on the agents' PowerDialer.\n                                                "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "switch-label" },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.updateCompanySettings,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company_clone
                                                        .disable_power_dialer_add,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company_clone,
                                                        "disable_power_dialer_add",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company_clone.disable_power_dialer_add",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v(
                                                    "Enable PowerDialer master control"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        !_vm.isCompanyPartOfAlowareDemoCompanies()
                                          ? _c(
                                              "el-form-item",
                                              {
                                                staticClass: "no-border pb-2",
                                                attrs: {
                                                  prop: "pd_force_redial",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "pd_force_redial",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Force Double Dial"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                    By selecting this option, agents are required to redial a contact when they don't pick the call up.\n                                                "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "switch-label",
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      attrs: {
                                                        "active-color":
                                                          "#00a344",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.updateCompanySettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.company_clone
                                                            .pd_force_redial,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.company_clone,
                                                            "pd_force_redial",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "company_clone.pd_force_redial",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", [
                                                      _vm._v(
                                                        "Require Agent Redial"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.isCompanyPartOfAlowareDemoCompanies(),
                                                expression:
                                                  "isCompanyPartOfAlowareDemoCompanies()",
                                              },
                                            ],
                                            staticClass: "form-label",
                                          },
                                          [
                                            _c("h5", { staticClass: "mb-3" }, [
                                              _vm._v("Redial Settings"),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.isCompanyPartOfAlowareDemoCompanies(),
                                                expression:
                                                  "isCompanyPartOfAlowareDemoCompanies()",
                                              },
                                            ],
                                            staticClass:
                                              "p-3 border rounded mb-4",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass:
                                                  "no-border pb-2 width-500",
                                                attrs: {
                                                  prop: "power_dialer_min_redials",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "power_dialer_min_redials",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Minimum Number of Redials"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                        Choose how many times a contact has to be redialed until a successful call disposition is achieved, select 0 to disable the redial requirement.\n                                                    "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder:
                                                        "Select a number of minimum redials",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.updateCompanySettings,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company_clone
                                                          .power_dialer_settings
                                                          .min_redials,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company_clone
                                                            .power_dialer_settings,
                                                          "min_redials",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company_clone.power_dialer_settings.min_redials",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.MIN_REDIAL_OPTIONS,
                                                    function (redial_option) {
                                                      return _c("el-option", {
                                                        key: redial_option.index,
                                                        attrs: {
                                                          label:
                                                            redial_option.label,
                                                          value:
                                                            redial_option.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass:
                                                  "no-border pb-2 width-500",
                                                attrs: {
                                                  prop: "power_dialer_successful_call_disposition_ids",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "power_dialer_successful_call_disposition_ids",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Select Successful Call Dispositions"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "Select the dispositions that won't require the contact to be redialed, meaning that the call was successfully answered."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "500px",
                                                      "max-width": "100%",
                                                    },
                                                    attrs: { multiple: "" },
                                                    on: {
                                                      change:
                                                        _vm.updateCompanySettings,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.company_clone
                                                          .power_dialer_settings
                                                          .successful_call_disposition_ids,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.company_clone
                                                            .power_dialer_settings,
                                                          "successful_call_disposition_ids",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "company_clone.power_dialer_settings.successful_call_disposition_ids",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.call_dispositions,
                                                    function (
                                                      call_disposition
                                                    ) {
                                                      return _c(
                                                        "el-option",
                                                        {
                                                          key: call_disposition.id,
                                                          attrs: {
                                                            label:
                                                              call_disposition.name,
                                                            value:
                                                              call_disposition.id,
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-bolt",
                                                            style: {
                                                              color:
                                                                call_disposition.color,
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  call_disposition.name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "no-border pb-2",
                                                attrs: {
                                                  prop: "power_dialer_force_immediate_redial",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "power_dialer_force_immediate_redial",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Force Immediate Redial"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                        Whenever a Redial is required, immediately redial the contact, instead of pushing to the bottom of the queue.\n                                                    "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "switch-label",
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticStyle: {
                                                          "margin-right":
                                                            "7px !important",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "No, push to bottom of the queue"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-switch", {
                                                      attrs: {
                                                        "active-color":
                                                          "#00a344",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.updateCompanySettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.company_clone
                                                            .power_dialer_settings
                                                            .force_immediate_redial,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.company_clone
                                                              .power_dialer_settings,
                                                            "force_immediate_redial",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "company_clone.power_dialer_settings.force_immediate_redial",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", [
                                                      _vm._v(
                                                        "Yes, push to top of the queue"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass:
                                                  "no-border pb-1 mb-0",
                                                attrs: {
                                                  prop: "power_dialer_force_sms",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "page-anchor",
                                                  attrs: {
                                                    id: "power_dialer_force_sms",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "form-label" },
                                                  [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Force SMS Sending on Unsuccessful Calls"
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("small", [
                                                      _vm._v(
                                                        "\n                                                        Force Agents to send a SMS if the selected call disposition is not one of successful call dispositions.\n                                                    "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "switch-label",
                                                  },
                                                  [
                                                    _c("el-switch", {
                                                      attrs: {
                                                        "active-color":
                                                          "#00a344",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.updateCompanySettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.company_clone
                                                            .power_dialer_settings
                                                            .force_sms,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.company_clone
                                                              .power_dialer_settings,
                                                            "force_sms",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "company_clone.power_dialer_settings.force_sms",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", [
                                                      _vm._v(
                                                        "Force SMS sending"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "no-border pb-2",
                                            attrs: {
                                              prop: "pd_remove_manually_dialed_contact",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "pd_remove_manually_dialed_contact",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v(
                                                    "Remove Manually Dialed Contacts"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                    Automatically remove any contact from the PowerDialer list who is manually dialed anywhere in the platform to eliminate the chance for a double call.\n                                                "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "switch-label" },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.updateCompanySettings,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company_clone
                                                        .pd_remove_manually_dialed_contact,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company_clone,
                                                        "pd_remove_manually_dialed_contact",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company_clone.pd_remove_manually_dialed_contact",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v(
                                                    "Remove manually dialed contacts from PowerDialer"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "switch-label" },
                                              [
                                                _c("el-switch", {
                                                  attrs: {
                                                    "active-color": "#00a344",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.updateCompanySettings,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.company_clone
                                                        .pd_remove_include_called_from_sequence,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.company_clone,
                                                        "pd_remove_include_called_from_sequence",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "company_clone.pd_remove_include_called_from_sequence",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", [
                                                  _vm._v(
                                                    "Include contacts called from Sequence"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { staticClass: "no-border pb-2" },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "power_dialer_open_close_settings",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "row width-500" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-label",
                                                      },
                                                      [
                                                        _c("h5", [
                                                          _vm._v("Open Time"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("small", [
                                                          _vm._v(
                                                            "\n                                                            Choose the open time\n                                                        "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-time-select", {
                                                      staticClass:
                                                        "w-full w-100",
                                                      attrs: {
                                                        "picker-options": {
                                                          start: "06:00",
                                                          step: "00:15",
                                                          end: "24:00",
                                                        },
                                                        placeholder:
                                                          "Select open time",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.updateCompanySettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.company_clone
                                                            .power_dialer_settings
                                                            .open_time,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.company_clone
                                                              .power_dialer_settings,
                                                            "open_time",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "company_clone.power_dialer_settings.open_time",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-label",
                                                      },
                                                      [
                                                        _c("h5", [
                                                          _vm._v("Close Time"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("small", [
                                                          _vm._v(
                                                            "\n                                                            Choose the close time\n                                                        "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-time-select", {
                                                      staticClass:
                                                        "w-full w-100",
                                                      attrs: {
                                                        "picker-options": {
                                                          start: "06:00",
                                                          step: "00:15",
                                                          end: "24:00",
                                                        },
                                                        placeholder:
                                                          "Select close time",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.updateCompanySettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.company_clone
                                                            .power_dialer_settings
                                                            .close_time,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.company_clone
                                                              .power_dialer_settings,
                                                            "close_time",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "company_clone.power_dialer_settings.close_time",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          { staticClass: "no-border pb-2" },
                                          [
                                            _c("span", {
                                              staticClass: "page-anchor",
                                              attrs: {
                                                id: "power_dialer_triggers",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-label" },
                                              [
                                                _c("h5", [
                                                  _vm._v(
                                                    "PowerDialer Triggers"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                                                    Here you can define triggers for the PowerDialer calls\n                                                "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                _c("triggers", {
                                                  attrs: {
                                                    triggers:
                                                      _vm.company_clone
                                                        .power_dialer_triggers,
                                                    power_dialer_trigger: true,
                                                  },
                                                  on: {
                                                    save: _vm.saveTriggers,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        [
                                          _c("el-divider"),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "page-anchor",
                                            attrs: {
                                              id: "power_dialer_session_setting",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("power-dialer-session-setting"),
                                        ],
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-2 d-none d-md-block ml-auto",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
                                          },
                                          [
                                            _c(
                                              "h6",
                                              {
                                                staticClass: "list-group-item",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                On This Page\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#default_power_dialer_campaign_id",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "default_power_dialer_campaign_id"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Default PowerDialer Line\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#disable_power_dialer_add",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "disable_power_dialer_add"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                PowerDialer Master Control\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            !_vm.isCompanyPartOfAlowareDemoCompanies()
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action",
                                                    attrs: {
                                                      href: "#pd_force_redial",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.shine(
                                                          "pd_force_redial"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                               PowerDialer Force Double Dial\n                                            "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.isCompanyPartOfAlowareDemoCompanies(),
                                                    expression:
                                                      "isCompanyPartOfAlowareDemoCompanies()",
                                                  },
                                                ],
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#power_dialer_min_redials",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "power_dialer_min_redials"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                PowerDialer Minimum Number of Redials\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.isCompanyPartOfAlowareDemoCompanies(),
                                                    expression:
                                                      "isCompanyPartOfAlowareDemoCompanies()",
                                                  },
                                                ],
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#power_dialer_successful_call_disposition_ids",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "power_dialer_successful_call_disposition_ids"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                PowerDialer Successful Call Dispositions\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.isCompanyPartOfAlowareDemoCompanies(),
                                                    expression:
                                                      "isCompanyPartOfAlowareDemoCompanies()",
                                                  },
                                                ],
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#power_dialer_force_immediate_redial",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "power_dialer_force_immediate_redial"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                PowerDialer Force immediate Redial\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.isCompanyPartOfAlowareDemoCompanies(),
                                                    expression:
                                                      "isCompanyPartOfAlowareDemoCompanies()",
                                                  },
                                                ],
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#power_dialer_force_sms",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "power_dialer_force_sms"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                PowerDialer Force SMS Sending\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#pd_remove_manually_dialed_contact",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "pd_remove_manually_dialed_contact"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Remove Manually Dialed Contacts from PowerDialer\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#power_dialer_open_close_settings",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "power_dialer_open_close_settings"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                PowerDialer Open/Close Time\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#power_dialer_triggers",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "power_dialer_triggers"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                PowerDialer Triggers\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "list-group-item list-group-item-action",
                                                attrs: {
                                                  href: "#power_dialer_session_setting",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.shine(
                                                      "power_dialer_session_setting"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                PowerDialer Session Settings\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              staticClass: "px-3 py-2",
                              attrs: {
                                label: "Reporting Settings",
                                name: "reporting-settings",
                              },
                            },
                            [
                              _c("account-report-settings", {
                                ref: "account_report_settings",
                                attrs: { company_clone: _vm.company_clone },
                                on: { "update-parent": _vm.updateParent },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.company &&
                          _vm.hasPermissionTo("list disposition status")
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "Contact Dispositions",
                                    name: "contact_dispositions",
                                  },
                                },
                                [_c("account-contact-dispositions")],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.company &&
                          _vm.hasPermissionTo("list disposition status")
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "Call Dispositions",
                                    name: "call_dispositions",
                                  },
                                },
                                [_c("account-call-dispositions")],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.company && _vm.hasPermissionTo("list lead source")
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "Lead Sources",
                                    name: "lead_sources",
                                  },
                                },
                                [_c("lead-sources-list")],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canSeeBilling
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: { label: "Billing", name: "billing" },
                                },
                                [
                                  _c("account-billing", {
                                    attrs: { company: _vm.company },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isSimpSocial ||
                          (_vm.isSimpSocial && _vm.hasRole("Billing Admin"))
                            ? _c(
                                "el-tab-pane",
                                { attrs: { label: "Usage", name: "usage" } },
                                [
                                  _vm.load_usage
                                    ? _c("company-usage", {
                                        attrs: { company: _vm.current_company },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "International Pricing",
                                name: "international_pricing",
                              },
                            },
                            [_c("account-international-pricing")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "Exports",
                                name: "exports",
                                lazy: true,
                              },
                            },
                            [_c("account-exports", { ref: "exportListing" })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "Scripts", name: "scripts" } },
                            [_c("account-script")],
                            1
                          ),
                          _vm._v(" "),
                          _vm.hasPermissionTo("list sms template")
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "SMS Templates",
                                    name: "sms-templates",
                                  },
                                },
                                [_c("sms-template-list")],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.current_company &&
                          !_vm.current_company.is_whitelabel
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "Shortened URLs",
                                    name: "shortened-urls",
                                    lazy: true,
                                  },
                                },
                                [
                                  _c("short-url-list", {
                                    on: { enabled: _vm.toggleUrlShortener },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "Custom Contact Fields",
                                name: "attribute-dictionary",
                              },
                            },
                            [_c("attribute-dictionary-list")],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: {
                                label: "Calendar Services",
                                name: "calendar-services",
                                lazy: "",
                              },
                            },
                            [_c("calendar-services-list")],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isSimpSocial
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "Calendar Settings",
                                    name: "calendar-settings",
                                    lazy: "",
                                  },
                                },
                                [_c("account-calendar-settings")],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("form-height-indicator", { attrs: { bottom: "20" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-backtop", {
            ref: "backtop",
            attrs: { target: "html", bottom: 20, right: 20 },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }