var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row nav-padding" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("company-plan-usage", { attrs: { company: _vm.company } }),
        _vm._v(" "),
        _c("div", { staticClass: "container-listing px-0" }, [
          _c("div", { staticClass: "container-body" }, [
            _c(
              "div",
              {
                staticClass:
                  "container-listing-tools d-flex flex-wrap justify-content-between mb-1",
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("alo-date-range-picker", {
                      model: {
                        value: _vm.date_range,
                        callback: function ($$v) {
                          _vm.date_range = $$v
                        },
                        expression: "date_range",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "medium" },
                        on: { click: _vm.showAllTimeData },
                      },
                      [
                        _vm._v(
                          "\n                            Show All-time\n                        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn blue-500 dropdown-toggle",
                      on: {
                        click: function ($event) {
                          _vm.export_options = !_vm.export_options
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Export Summary\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu dropdown-menu-right",
                      class: _vm.export_options ? "show" : "",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.exportToCsv.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-table" }),
                          _vm._v(
                            "\n                                CSV File\n                            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("company-usage-graph", {
              attrs: { date_range: _vm.date_range },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("company-usage-logs", { attrs: { date_range: _vm.date_range } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }