var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      staticClass: "no-select",
      class: [this.full_width ? "w-full" : ""],
      attrs: { placeholder: "Contact groups", filterable: "", clearable: "" },
      model: {
        value: _vm.target_contacts,
        callback: function ($$v) {
          _vm.target_contacts = $$v
        },
        expression: "target_contacts",
      },
    },
    [
      _c(
        "el-option-group",
        { key: "By Line", attrs: { label: "By Line" } },
        _vm._l(_vm.activeCampaignsAlphabeticalOrder, function (campaign) {
          return _c(
            "el-option",
            {
              key: campaign.id,
              attrs: { label: campaign.name, value: "campaign:" + campaign.id },
            },
            [_c("span", [_vm._v(_vm._s(campaign.name))])]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-option-group",
        { key: "By Tag", attrs: { label: "By Tag" } },
        _vm._l(_vm.tagsAlphabeticalOrder, function (tag) {
          return _c(
            "el-option",
            { key: tag.id, attrs: { label: tag.name, value: "tag:" + tag.id } },
            [
              _c("i", {
                staticClass: "fa fa-circle",
                style: { color: tag.color },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml-1 text-grey-900" }, [
                _vm._v(_vm._s(tag.name)),
              ]),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-option-group",
        {
          key: "By Disposition Status",
          attrs: { label: "By Disposition Status" },
        },
        _vm._l(_vm.disposition_statuses, function (disposition_status) {
          return _c(
            "el-option",
            {
              key: disposition_status.id,
              attrs: {
                label: disposition_status.name,
                value: "disposition_status:" + disposition_status.id,
              },
            },
            [
              disposition_status.is_external !== undefined
                ? [
                    disposition_status.is_external
                      ? _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            style: { color: disposition_status.color },
                          },
                          [_vm._v("lock")]
                        )
                      : _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            style: { color: disposition_status.color },
                          },
                          [_vm._v("label")]
                        ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "ml-2" }, [
                _vm._v(_vm._s(disposition_status.name)),
              ]),
            ],
            2
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-option-group",
        { key: "Other options", attrs: { label: "Other options" } },
        [
          _vm.allow_all_contacts
            ? _c(
                "el-option",
                {
                  key: _vm.activeCampaignsAlphabeticalOrder
                    ? _vm.activeCampaignsAlphabeticalOrder.length + 1
                    : 1,
                  attrs: { label: "All contacts", value: "all" },
                },
                [_c("span", [_vm._v("All contacts")])]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }