var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "stepper__content__header",
      class: _vm.isMobile ? "px-4" : "",
    },
    [
      _vm.icon
        ? _c("img", { attrs: { alt: "icon", src: _vm.icon } })
        : _vm._e(),
      _vm._v(" "),
      _c("h4", { staticClass: "text-h4 text-weight-bold" }, [
        _vm._v("\n        Welcome to " + _vm._s(_vm.company_name) + "!\n    "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-body1" }, [
        _vm._v("\n        " + _vm._s(_vm.description) + "\n    "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }