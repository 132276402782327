var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "d-flex flex-column card bg-white p-4",
      staticStyle: { width: "100%", "max-width": "1024px" },
    },
    [
      _c("div", { staticClass: "card-title mb-0" }, [
        _c("div", { staticClass: "d-flex flex-row justify-content-between" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticStyle: { cursor: "pointer" } }, [
            _c(
              "i",
              {
                staticClass: "material-icons hover",
                staticStyle: { "font-size": "25px", color: "#666" },
                on: { click: _vm.cancel },
              },
              [_vm._v("close")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          "border-bottom": "solid 1px #ddd",
          height: "2px",
          margin: "12px 0",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-row mt-2" },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm._l(_vm.category_list, function (cat) {
            return _c(
              "button",
              {
                key: cat.value,
                staticClass: "btn-xs btn-outline-primary mr-2",
                staticStyle: {
                  "border-radius": "5px",
                  outline: "none !important",
                  "box-shadow": "none",
                  "border-width": "1px",
                  "font-size": "13px",
                  padding: "3px 8px",
                },
                style:
                  cat.value === _vm.form.category
                    ? ""
                    : "border:solid 1px #ddd",
                on: {
                  click: function ($event) {
                    _vm.form.category = cat.value
                  },
                },
              },
              [_vm._v("\n            " + _vm._s(cat.label) + "\n        ")]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mt-3",
          staticStyle: {
            "font-size": "16px",
            "font-weight": "500",
            color: "black",
          },
        },
        [
          _vm._v(
            "\n        Provide specifics on any issues related to this communication:\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.message,
            expression: "form.message",
          },
        ],
        staticClass: "my-3 p-2",
        staticStyle: { border: "solid 1px #ddd", "border-radius": "5px" },
        attrs: { rows: "6" },
        domProps: { value: _vm.form.message },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "message", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-row justify-content-end" },
        [
          _c(
            "div",
            { staticClass: "d-flex mr-3 flex-column justify-content-center" },
            [
              _vm._v(
                "\n            Message will be sent to support@aloware.com\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: {
                "border-radius": "6px !important",
                padding: "7px 12px",
                "font-size": "14px !important",
              },
              attrs: {
                type: "primary",
                size: "small",
                disabled: !_vm.form.message,
              },
              on: { click: _vm.reportIssue },
            },
            [_vm._v("\n            Report\n        ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticStyle: { "font-weight": "500", color: "black" } }, [
        _vm._v("Report Issue"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex mr-2 flex-column justify-content-center" },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "16px",
              "font-weight": "500",
              color: "black",
            },
          },
          [_vm._v("Category:")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }