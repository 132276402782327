var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "contact-lists-selector",
      staticClass: "w-full",
      attrs: {
        placeholder: _vm.placeholder,
        multiple: _vm.multiple,
        clearable: _vm.clearable,
        "collapse-tags": !_vm.no_collapse,
        size: _vm.size,
        filterable: "",
        "default-first-option": "",
      },
      on: { change: _vm.selectContactList },
      model: {
        value: _vm.contact_list_id,
        callback: function ($$v) {
          _vm.contact_list_id = $$v
        },
        expression: "contact_list_id",
      },
    },
    _vm._l(_vm.contactListOrdered, function (contact_list) {
      return _c(
        "el-option",
        {
          key: contact_list.id,
          attrs: { label: contact_list.name, value: contact_list.id },
        },
        [
          _c("span", { staticClass: "ml-2" }, [
            _vm._v(_vm._s(contact_list.name)),
          ]),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }