var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showAlertAgentOnCall
        ? _c("div", { staticClass: "p-3" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "You're currently engaged in another call on Aloware Talk. Please complete your current conversation before initiating a new call."
              ),
            ]),
          ])
        : _vm.allowed
        ? _c("webrtc", {
            class: [_vm.small ? "small" : ""],
            attrs: {
              carrier_name: _vm.auth.user.profile.carrier_name,
              is_widget: true,
            },
            on: {
              callConnected: _vm.handleCallConnectedEvent,
              callCompleted: _vm.handleCallCompletedEvent,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [_vm._v("Call in Progress on Another Device")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }