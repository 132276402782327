<template>
    <div class="trial-banner button d-flex align-items-center">
        <el-button class="h-100"
                   type="primary" 
                   plain
                   :size="size"
                   data-testid="open-tutorial-video"
                   @click="openModal">
            Open Tutorial Video 🤩
        </el-button>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'VideoModalActivator',

    props: {
        size: {
            type: String,
            default: 'medium'
        }
    },

    methods: {
        ...mapActions(['setShouldShowVideoModal']),

        openModal() {
            this.setShouldShowVideoModal(true)
            this.$emit('open-modal')
        }
    }
}
</script>
