var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentUser
    ? _c(
        "div",
        { attrs: { "data-testid": "agent-status-dropdown-wrapper" } },
        [
          !_vm.forced_disabled_ui
            ? _c("p", { staticClass: "font-weight-normal mb-0 text-center" }, [
                _vm._v("Modify Agent's Status"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.AgentStatusLabels.LABELS, function (item) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !item.disabled,
                    expression: "!item.disabled",
                  },
                ],
                key: item.value,
                staticClass: "col px-0",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "dropdown-item cursor-pointer pt-2 pb-2",
                    class: [
                      item.disabled || _vm.isForcedDisabled(item.value)
                        ? "btn-disabled"
                        : "",
                    ],
                    attrs: {
                      disabled:
                        item.disabled || _vm.isForcedDisabled(item.value),
                      "data-testid": "change-agent-status-btn",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changeAgentStatus(item.value)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "b-white bottom status-badge",
                      class: [
                        _vm.$options.filters.agentStatusClass(item.value),
                      ],
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        class: [
                          _vm.currentUser.agent_status == item.value
                            ? "text-blue _600"
                            : "",
                        ],
                      },
                      [_vm._v(_vm._s(item.label))]
                    ),
                    _vm._v(" "),
                    _vm.isForcedDisabled(item.value)
                      ? _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "left-bottom",
                              trigger: "hover",
                              "data-testid": "popover",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "badge badge-warning py-1 w-100" },
                              [
                                _vm._v(
                                  "\n                    Forced at account level\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "fa fa-info-circle pull-right",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }