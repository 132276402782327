export default Object.freeze([
    { 
        label: 'HubSpot', 
        name: 'hubspot', 
    },
    { 
        label: 'GoHighLevel', 
        name: 'gohighlevel', 
    },
    { 
        label: 'Pipedrive', 
        name: 'pipedrive', 
    },
    {
        label: 'Zoho',
        name: 'zoho',
    }
])
