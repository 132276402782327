var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.data_loaded || _vm.loading
      ? _c(
          "div",
          {
            staticClass:
              "placeholder text-center d-flex align-items-center justify-content-center",
            class: { blink: _vm.loading },
            staticStyle: { height: "450px" },
          },
          [
            _c(
              "i",
              { staticClass: "material-icons loader text-greenish text-4x" },
              [_vm._v("\n            \n        ")]
            ),
          ]
        )
      : _c(
          "div",
          {
            staticClass: "force-scrollbar-view",
            staticStyle: { height: "450px" },
          },
          [
            _c(
              "graph-data-switch",
              {
                attrs: {
                  report_type: "activity_reporting",
                  series: _vm.options.series,
                  table_label: _vm.graph_label,
                },
              },
              [
                _c("highcharts", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.is_done && _vm.options.series.length > 0,
                      expression: "is_done && options.series.length > 0",
                    },
                  ],
                  ref: "highchart",
                  class: {
                    "overflow-y-scroll":
                      _vm.chart_type == "bar" && _vm.data.length > 25,
                  },
                  style: { height: "450px" },
                  attrs: { id: _vm.graph_id, options: _vm.graphOptions },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.is_done && !_vm.options.series.length,
                        expression: "is_done && !options.series.length",
                      },
                    ],
                    staticClass: "el-table__empty-block",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "el-table__empty-text",
                        staticStyle: { color: "#606266" },
                      },
                      [
                        _vm._v(
                          "\n                    No Data\n                "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }