var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "350px",
        title: "Delete Tag",
        visible: _vm.dialog_visible,
        "before-close": _vm.toggleDialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialog_visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "ruleForm", attrs: { model: _vm.ruleForm, rules: _vm.rules } },
        [
          _c(
            "span",
            { staticClass: "text-md text-left mb-2 block break-word" },
            [
              _vm._v(
                "\n            Deleting a tag will remove it from all contacts and communications. Continue?\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.delete_all_checked
            ? [
                _c("p", { staticClass: "text-left block break-word" }, [
                  _vm._v("\n                You're about to delete "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.tagged_contacts_count) + " contacts"),
                  ]),
                  _vm._v(
                    ".\n                Use the text field below to confirm the number of contacts you want to delete.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "confirmed_contacts_count" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Confirm number of contacts here" },
                      model: {
                        value: _vm.ruleForm.confirmed_contacts_count,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "confirmed_contacts_count",
                            _vm._n($$v)
                          )
                        },
                        expression: "ruleForm.confirmed_contacts_count",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.tagged_contacts_count && !_vm.isSimpSocial
            ? _c(
                "el-checkbox",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.delete_all_checked,
                      expression: "!delete_all_checked",
                    },
                  ],
                  staticClass: "break-word",
                  model: {
                    value: _vm.delete_all_checked,
                    callback: function ($$v) {
                      _vm.delete_all_checked = $$v
                    },
                    expression: "delete_all_checked",
                  },
                },
                [
                  _vm._v(
                    "\n            Do you also want to delete the tagged contacts of this tag?\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-2 text-right" },
            [
              _c("el-button", { on: { click: _vm.toggleDialog } }, [
                _vm._v("Cancel"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", disabled: _vm.isFormValid },
                  on: { click: _vm.triggerDelete },
                },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }