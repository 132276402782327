<template>
    <div class="app-body bg-auto h-v w-full clear">
        <div class="row d-flex justify-content-center align-items-center h-full">
            <div class="col-md-4 col-12 text-center my-auto">
                <!-- brand -->
                <a class="navbar-brand mx-auto mb-5 d-block">
                    <img :src="statics.logo"
                         v-if="!loading_whitelabel"
                         alt=".">
                </a>
                <!-- / brand -->
                <img class="img-responsive mx-auto"
                     src="/assets/images/404.png"
                     style="max-width: 200px;"/>
                <p class="h5 m-y-lg font-bold text-greyish">Sorry! the page you are looking for doesn't exist.</p>
                <a href="/"
                   class="btn greenish p-x-md width-200 mx-auto">Go to the Dashboard</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                statics: {
                    logo: null,
                    logo_inverse: null,
                    logo_square: null,
                    logo_square_inverse: null,
                    host: null,
                    referer: null,
                    name: null,
                    domain: null,
                    whitelabel: false,
                    path: null
                },
                loading_whitelabel: true,
            }
        },

        created() {
            this.getStatics()
        },

        methods: {
            getStatics() {
                this.loading_whitelabel = true
                axios.get('/get-statics')
                    .then(res => {
                        this.statics = res.data
                        document.title = 'Not Found - ' + this.statics.name + ' Cloud Contact Center'
                        this.loading_whitelabel = false
                    })
                    .catch(err => {
                        document.title = 'Not Found | Aloware Cloud Contact Center'
                        console.log(err)
                        this.$root.handleErrors(err.response)
                        this.loading_whitelabel = false
                    })
            }
        }
    }
</script>
