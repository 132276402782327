var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "textarea-container",
      class: { "with-label": _vm.isInfoTextActive },
    },
    [
      _c("el-input", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        ref: "input",
        attrs: {
          type: "textarea",
          autosize: { minRows: 2 },
          placeholder: _vm.placeholder,
          rows: _vm.rows,
          value: _vm.modelValue,
        },
        on: { blur: _vm.emitBlurEvent, input: _vm.emitUpdateEvent },
      }),
      _vm._v(" "),
      _vm.isInfoTextActive
        ? _c("label", {
            staticClass: "textarea-label",
            style: _vm.getLabelStyle(),
            domProps: { innerHTML: _vm._s(_vm.infoText) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }