var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list lead source")
    ? _c(
        "div",
        [
          _c(
            "el-select",
            _vm._b(
              {
                staticClass: "w-full",
                attrs: {
                  "default-first-option": "",
                  filterable: "",
                  clearable: "",
                  placeholder: "Select Lead Source",
                  "data-testid": "lead-source-selector",
                },
                on: { change: _vm.leadSourceChange },
                model: {
                  value: _vm.selected_lead_source,
                  callback: function ($$v) {
                    _vm.selected_lead_source = $$v
                  },
                  expression: "selected_lead_source",
                },
              },
              "el-select",
              _vm.$attrs,
              false
            ),
            _vm._l(_vm.lead_sources, function (lead_source) {
              return _c(
                "el-option",
                {
                  key: lead_source.id,
                  attrs: { label: lead_source.name, value: lead_source.id },
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(lead_source.name) + "\n        "
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }