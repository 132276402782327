<template>
    <div class="px-3">
        <div class="container-listing min-height-screen px-0">
            <div class="container-header d-flex">
                <div class="listing-heading">
                    <h3 class="mb-0">Usage History</h3>
                    <p
                        v-if="sub_heading"
                        class="mb-0">
                        {{ sub_heading }}
                    </p>
                </div>
            </div>

            <div class="container-body">
                <div class="container-listing-tools d-flex flex-wrap mb-1">
                    <div class="d-flex">
                        <el-button
                            style="margin-right: 10px;"
                            size="medium"
                            @click="refresh">
                            <i class="fa fa-refresh"></i> Refresh
                        </el-button>
                        <el-select
                            v-model="filters.resources_names"
                            multiple
                            collapse-tags
                            collapse-tags-tooltip
                            filterable
                            placeholder="Filter by item"
                            ref="selectFilter"
                            style="width: 400px"
                            :clearable=true
                            :disabled="resource_filter_disabled"
                            @change="itemFilterChanged"
                            @clear="filters.resources_names = null"
                            >
                            <el-option
                                v-for="item in usage_resource_names"
                                :key="item.name"
                                :label="item.label"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="ml-auto">
                        <alo-simple-pagination
                            v-if="usage_paginator"
                            :pagination="usage_paginator"
                            @prev-click="prevPage"
                            @next-click="nextPage">
                        </alo-simple-pagination>
                    </div>
                </div>

                <div
                    class="min-height-40-vh"
                    v-loading="is_usage_logs_loading">
                    <div
                        v-if="usage_paginator.data.length <= 0"
                        class="text-center">
                        <div class="w-50 mx-auto py-5">
                            <img-empty></img-empty>
                            <p class="lead">No usage logs in this date range</p>
                        </div>
                    </div>
                    <table
                        v-if="usage_paginator.data.length > 0"
                        class="table table-sm">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Unit Price (USD)</th>
                            <th>Cost (USD)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(usage, i) in usage_paginator.data"
                            :class="getUsageRowClass(usage)"
                            :key="i">
                            <td>{{ usage.date | fixFullDateLocal }}</td>
                            <td>{{ usage.resource_name }}</td>
                            <td>{{ usage.quantity | removeSign }} {{ usage.resource | fixUnit }}</td>
                            <td v-if="usage.credit > 0">{{ usage.unit_price | fixRounding(3, usage.resource_name) | toCurrency }}</td>
                            <td v-else>{{ 0 | fixRounding(3, usage.resource_name) | toCurrency }}</td>
                            <td>{{ usage.credit | fixRounding | removeSign | toCurrency | fixCreditInfo }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <alo-simple-pagination
                        :pagination="usage_paginator"
                        @prev-click="prevPage"
                        @next-click="nextPage">
                    </alo-simple-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick } from "vue"
import AloSimplePagination from "../../alo-simple-pagination"
import ImgEmpty from "../../misc/img-empty"
import usageResources from "../../../constants/usage_resouce_names"

export default {
    name: "company-usage-logs",

    components: {AloSimplePagination, ImgEmpty},

    props: {
        date_range: {
            required: false
        }
    },

    data() {
        return {
            is_usage_logs_loading: false,
            usage_paginator: {
                data: [],
            },
            sub_heading: null,
            usage_resource_names: usageResources,
            resource_filter_disabled: true,
            filters: {
                page: 1,
                from_date: null,
                to_date: null,
                resources_names: null,
            }
        }
    },

    mounted() {
        this.fetchCompanyUsage()
    },

    methods: {
        fetchCompanyUsage() {
            this.prepareFilters()
            this.is_usage_logs_loading = true

            axios.get(`/api/v1/company/usage/logs`, {
                params: this.filters
            }).then(res => {
                this.usage_paginator = res.data.usage
                this.sub_heading = res.data.message
                this.is_usage_logs_loading = false
                this.resource_filter_disabled = false
            })
        },

        nextPage() {
            this.filters.page++
            this.fetchCompanyUsage()
        },

        prevPage() {
            this.filters.page--
            this.fetchCompanyUsage()
        },

        refresh() {
            this.filters.page = 1
            this.fetchCompanyUsage()
        },

        itemFilterChanged() {
            // Closing the select dropdown
            nextTick(() => {
                this.$refs.selectFilter.blur();
            });
            this.resource_filter_disabled = true
            this.refresh()
        },

        prepareFilters() {
            if (Array.isArray(this.date_range)) {
                this.filters.from_date = this.date_range[0]
                this.filters.to_date = this.date_range[1]
            } else {
                this.filters.from_date = null
                this.filters.to_date = null
            }
        },

        getUsageRowClass(usage) {
            if (['credits', 'promo_credit'].includes(usage.resource)) {
                return 'table-success'
            } else if (usage.resource === 'user') {
                return 'table-info'
            }

            return ''
        },
    },

    watch: {
        'date_range': function (new_date_range) {
            this.refresh()
        }
    }
}
</script>
