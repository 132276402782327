<template>
    <el-dialog
        class="dialog-blank alo-default-app-selector-dialog"
        width="550px"
        append-to-body
        :before-close="closeDialog"
        :visible="visible && !shouldForceTalk && !hasRole('Company Admin') && !isSimpSocial"
        :close-on-click-modal="false"
        @close="closeNotifier(AppTypes.APP_ALOWARE_CLASSIC)">
        <div class="alo-default-app-selector-body">
            <alo-talk-info></alo-talk-info>

            <el-divider></el-divider>

            <div class="row">
                <div class="col-sm-6">
                    <el-button
                        @click="closeNotifier(AppTypes.APP_ALOWARE_CLASSIC)"
                        size="large"
                        class="w-100 d-flex justify-content-center align-items-center">
                        Continue to {{ alowareClassic }}
                    </el-button>
                </div>
                <div class="col-sm-6">
                    <el-button
                        @click="closeNotifier(AppTypes.APP_ALOWARE_TALK)"
                        size="large"
                        type="primary"
                        class="w-100 d-flex justify-content-center align-items-center">
                        {{ buttonText }}
                    </el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import {mapState, mapGetters} from "vuex"
    import { acl_mixin, helper_mixin } from '../mixins'
    import auth from "../auth"
    import AloTalkInfo from './alo-talk-info'
    import * as AppTypes from '../constants/aloware-app-types'

    export default {
        name: "default-app-selector",

        props: {
            show: {
                type: Boolean,
                required: true
            }
        },

        components: {
            AloTalkInfo
        },

        mixins: [
            acl_mixin,
            helper_mixin
        ],

        data() {
            return {
                auth,
                user: auth.user,
                visible: false,
                AppTypes
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),
            ...mapGetters('cache', ['isSimpSocial']),

            buttonText() {
                return this.isSimpSocial ? 'Try Talk' : 'Try Aloware Talk'
            }
        },

        methods: {
            closeNotifier(value) {
                // turn off notifier company and user level
                localStorage.setItem(`show_default_app_selection_${this.current_company.id}`, false)
                localStorage.setItem(`show_default_app_selection_${this.current_company.id}_${this.user.profile.id}`, false)

                this.$root.show_default_app_selection = false

                // redirect to alo talk
                if (value === AppTypes.APP_ALOWARE_TALK) {
                    console.log('redirecting 2')
                    const is_impersonating = localStorage.getItem('impersonate') == 'true'
                    window.location.href = this.getTalkUrl() + (is_impersonating ? '?impersonating=1' : '')
                }

                // stay in the site
                if (value === AppTypes.APP_ALOWARE_CLASSIC) {
                    setTimeout(_ => {
                        this.closeDialog()
                    }, 300)
                }
            },

            closeDialog() {
                this.visible = false
                this.$emit('close-dialog')
            }
        },

        watch: {
            show: {
                handler(value) {
                    this.visible = value
                },
                immediate: true
            }
        },
    }
</script>
