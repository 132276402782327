<template>
    <div class="align-self-center">
        <div id="advanced_filters"
             class="dropdown"
             ref="advanced_filters_desktop"
             :class="custom_class"
             v-show="checkSize()">
            <contact-advanced-filters ref="filters"
                                      :campaign_id="campaign_id"
                                      :tag_id="tag_id"
                                      :disposition_status_id="disposition_status_id"
                                      :lead_source_id="lead_source_id"
                                      :hide_reset_filter="hide_reset_filter"
                                      :default_date_range="default_date_range"
                                      :has_sort_communications="has_sort_communications"
                                      :broadcast="broadcast"
                                      @changed="updateChanged"
                                      @resetFilters="resetContactFilters">
            </contact-advanced-filters>
        </div>
        <el-popover placement="bottom-end"
                    width="100%"
                    trigger="click"
                    v-if="!checkSize()"
                    @show="popover = true"
                    @hide="popover = false">
            <div ref="advanced_filters_mobile"></div>
            <el-button slot="reference"
                       size="mini"
                       type="primary"
                       class="mt-1"
                       icon="el-icon-more-outline"
                       plain>
            </el-button>
        </el-popover>
    </div>
</template>

<script>
    import auth from '../auth'

    export default {
        props: {
            campaign_id: {required: false},
            tag_id: {required: false},
            disposition_status_id: {required: false},
            lead_source_id: {required: false},
            only_campaign_selector: {
                default: false,
                type: Boolean,
                required: false
            },
            hide_reset_filter: {
                default: false,
                type: Boolean,
                required: false
            },
            broadcast: {
                default: false,
                type: Boolean,
                required: false
            },
            custom_class: {
                default: 'ml-auto',
                type: String,
                required: false
            },
            default_date_range: {
                default: null,
                required: false
            },
            has_sort_communications: {
                default: false
            },
        },

        data() {
            return {
                auth: auth,
                changed: false,
                popover: false
            }
        },

        methods: {
            checkSize() {
                let valid_sizes = ['xxl', 'xl']
                if (valid_sizes.includes(this.$mq)) {
                    this.popover = false // force to hide popover
                    return true
                }
                return false
            },

            updateChanged(changed) {
                this.changed = changed
            },

            resetContactFilters() {
                this.$emit('resetFilters')
            }
        },

        watch: {
            changed() {
                this.$emit('changed', this.changed)
            },

            popover(state) {
                // remove from old parent
                this.$refs.filters.$el.parentNode.removeChild(this.$refs.filters.$el)

                if (state) {
                    // move to popover body
                    this.$refs.advanced_filters_mobile.appendChild(this.$refs.filters.$el)
                } else {
                    // move to default header
                    this.$refs.advanced_filters_desktop.appendChild(this.$refs.filters.$el)
                }
            }
        }
    }
</script>
