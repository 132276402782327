<template>
  <div class="container"
       v-if="!isLoading">
    <div class="row mt-5">
      <div class="col-sm-12 col-md-8 mx-auto">
        <!-- Start Alert Banner -->
        <div v-if="showLoginBanner"
             class="alert alert-warning"
             role="alert">
          <h6 class="alert-heading mt-1">
            <i class="glyphicon glyphicon-log-in mt-1 mr-1" aria-hidden="true"></i>
            GO TO LOGIN PAGE
          </h6>
          <p>It looks like you're not logged in to Aloware. Open a new tab by clicking
            <a href="/login" target="_blank"
               class="font-weight-bold text-decoration-underline">
              here,
            </a>
            enter your credentials and go back to this tab
          </p>
        </div>
        <!-- End Alert Banner -->

        <!-- Start Forbidden Alert Banner for Enroll to sequence -->
        <div v-if="!actionIsAllowed && !hasSequenceAccess && authUser"
             class="alert alert-warning"
             role="alert">
          <h6 class="alert-heading mt-1">
            <i class="glyphicon glyphicon-lock mt-1 mr-1" aria-hidden="true"></i>
            FORBIDDEN
          </h6>
          <p>
            Sorry, but it looks like you don't have enough permissions to proceed with this action.
            Please check if your Aloware account has access to <b>Sequences+</b> or
            contact technical support.
          </p>
        </div>
        <!-- End Forbidden Alert Banner for Enroll to sequence-->
        <!-- Start Card -->
        <div class="card text-center" v-else>
          <div class="card-body">
            <h3 class="card-title">
              <span class="text-success">Aloware & Pipedrive Integrations</span>
            </h3>
            <h6 class="card-subtitle">
              <span>{{ basicAction.cardSubtitle }}</span>
            </h6>

            <!-- Start card text -->
            <div v-if="actionIsAllowed">
              <hr />
              <p class="card-text mt-3">
                Click the button below to proceed adding contacts to Aloware. Your newly added contacts
                will be tagged as
                <span class="badge badge-dark">{{ model.tag_name }}</span>
              </p>
              <hr />
            </div>
            <div v-if="!actionIsAllowed">
              <hr />
              <p class="card-text mt-3">
                Sorry! It seems that you're not allowed to proceed with this action.
                Please resolve the error messages shown and try again.
              </p>
              <hr />
            </div>
            <!-- End card text -->

            <!-- Start pipedrive app action forms -->
            <div class="px-3">
              <template v-if="basicAction.showPowerDialerForm && actionIsAllowed">
                <add-to-power-dialer ref="basicActionPowerDialerForm" />
              </template>
              <template v-if="actionIsAllowed && authUser">
                <enroll-to-sequence v-if="basicAction.showSequenceForm"
                                    ref="basicActionEnrollToSequenceForm"
                                    :auth-user="authUser" />
              </template>
            </div>
            <!-- End pipedrive app action form-->

            <!-- Start action button -->
            <template v-if="!isLoading">
              <button id="add-to-power-dialer-btn"
                      class="btn btn-success text-white mt-3 mb-1"
                      @click="handleBasicActionRedirect"
                      type="button"
                      :disabled="!actionIsAllowed">
                <i :class="`${basicAction.actionButtonIcon} mr-1`" aria-hidden="true"></i>
                {{ basicAction.actionButtonText }}
              </button>
            </template>
            <template v-else>
              <button class="btn btn-success mt-3 mb-1" type="button" disabled>
                <i class="fa fa-spinner fa-spin mr-2"></i>
                <span>Loading...</span>
              </button>
            </template>
            <!-- End action button -->
          </div>
        </div>
        <!-- End Card -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { handleVuexApiCall, ErrorCode, sleep } from '../../../utils/integration-helpers'
import AddToPowerDialer from '../../../components/integrations/pipedrive-app-extensions/add-to-power-dialer'
import EnrollToSequence from '../../../components/integrations/pipedrive-app-extensions/enroll-to-sequence'
import { form_validation_mixin } from '../../../mixins'
import auth from '../../../auth'

export default {
  name: 'integration-extensions-pipedrive',
  components: {
    AddToPowerDialer,
    EnrollToSequence
  },
  mixins: [
    form_validation_mixin
  ],
  data: () => ({
    isLoading: false,
    showLoginBanner: false,
    authUser: null,
    actionIsAllowed: true, // sometimes actions are not allowed for some accounts
    hasSequenceAccess: false,

    basicAction: {
      cardSubtitle: '',
      actionButtonText: '',
      actionButtonIcon: '',
      notificationTitle: '',
      notificationMessage: '',
      redirectUri: '',
      showPowerDialerForm: false,
      showSequenceForm: false,
      vuexRedirectAction: '',
      vuexFormInputGetter: '',
      formRef: {
        childName: '',
        methodName: ''
      }
    },
    model: {
      tag_name: ''
    }
  }),
  computed: {
    ...mapGetters(['getPowerDialerFormInputs', 'getEnrollToSequenceFormInputs'])
  },
  methods: {
    ...mapActions(['processPowerDialerRedirect', 'processEnrollToSequenceRedirect', 'fetchAuthUserForAppExtension']),
    async handleBasicActionRedirect() {
      // check if for is valid
      const formName = this.basicAction.formRef.childName
      let formIsValid = true
      if (this.$refs[formName].runFormValidation !== undefined) { // we may not need ui validation on other forms
        formIsValid = this.$refs[formName].runFormValidation()
      }
      if (!formIsValid) return

      this.isLoading = true

      // Ask the basic action form to update their inputs in vuex
      this.$refs[this.basicAction.formRef.childName][this.basicAction.formRef.methodName]()

      // Combine basicAction model and basicAction form inputs then send to a vuex action
      const basicActionModelAndFormInputs = { ...this.model, ...this[this.basicAction.vuexFormInputGetter] }
      const vuexActionName = this.basicAction.vuexRedirectAction // ie. processPowerDialerRedirect, processEnrollToSequenceRedirect, processBroadcastRedirect
      const result = await handleVuexApiCall(this[vuexActionName], basicActionModelAndFormInputs)

      await sleep(500)

      if (!result.success) return this.handleError(result)

      // Redirect to the a page (power dialer, sequences, broadcast)
      await this.$router.replace(this.getBasicActionRedirectUri(vuexActionName))
      this.isLoading = false

      this.$notify({
        duration: 8000,
        offset: 175,
        title: this.basicAction.notificationTitle,
        message: this.basicAction.notificationMessage,
        type: 'success',
        showClose: true
      })
    },
    handleError(result) {
      this.$notify({
        duration: 8000,
        offset: 175,
        title: 'Error',
        message: result.error.message,
        type: 'error',
        showClose: true
      })

      // Show login help if not authenticated
      if (result.error.code === ErrorCode.AUTHENTICATION_ERROR) {
        this.showLoginBanner = true
        this.actionIsAllowed = false
      }

      this.isLoading = false
    },
    async handleBasicActionComponentSwitch(urlParam) {
      switch (urlParam) {
        case 'add-to-power-dialer':
          this.basicAction.actionButtonText = 'Proceed to Power Dialer'
          this.basicAction.actionButtonIcon = 'glyphicon glyphicon-earphone'
          this.basicAction.notificationTitle = 'PowerDialer'
          this.basicAction.notificationMessage = 'Processing contacts. This may take a while. The task queue will automatically populate when we\'re done'
          this.basicAction.cardSubtitle = 'ADD TO POWER DIALER'
          this.showBasicActionForm('power-dialer')
          this.basicAction.vuexRedirectAction = 'processPowerDialerRedirect'
          this.basicAction.formRef.childName = 'basicActionPowerDialerForm'
          this.basicAction.formRef.methodName = 'handleUpdatePowerDialerFormInputs'
          this.basicAction.vuexFormInputGetter = 'getPowerDialerFormInputs'
          break
        case 'enroll-to-sequence':
          this.basicAction.actionButtonText = 'Enroll to Sequence'
          this.basicAction.actionButtonIcon = 'fa fa-cog'
          this.basicAction.notificationTitle = 'Sequences'
          this.basicAction.notificationMessage = 'Processing contacts. This may take a while. Contacts will slowly be enrolled when we\'re done'
          this.basicAction.cardSubtitle = 'ENROLL TO SEQUENCE'
          this.showBasicActionForm('sequence')
          this.basicAction.vuexRedirectAction = 'processEnrollToSequenceRedirect'
          this.basicAction.formRef.childName = 'basicActionEnrollToSequenceForm'
          this.basicAction.formRef.methodName = 'handleUpdateEnrollToSequenceFormInputs'
          this.basicAction.vuexFormInputGetter = 'getEnrollToSequenceFormInputs'
          break
        default:
          // Redirect to home if none are provided
          await this.$router.replace('/')
      }
    },
    getBasicActionRedirectUri(vuexActionName) {
      let redirectUri
      switch (vuexActionName) {
        case 'processPowerDialerRedirect':
          redirectUri = '/power-dialer'
          break
        case 'processEnrollToSequenceRedirect':
          redirectUri = '/sequences2/manager/' + this.getEnrollToSequenceFormInputs.workflow_id + '?tab=contacts'
          break
        default:
          redirectUri = '/'
      }
      return redirectUri
    },
    showBasicActionForm(basicActionName) {
      switch (basicActionName) {
        case 'power-dialer':
          this.basicAction.showPowerDialerForm = true
          this.basicAction.showSequenceForm = false
          break
        case 'sequence':
          this.basicAction.showSequenceForm = true
          this.basicAction.showPowerDialerForm = false
          break
        default:
          throw new Error('Invalid param passed')
      }
    },
    async getAuthUser() {
      const res = await handleVuexApiCall(this.fetchAuthUserForAppExtension)
      if (!res.success) return this.handleError(res)

      this.authUser = res.data
      this.showLoginBanner = false
    }
  },
  watch: {
    '$route.params.basic_action': {
      handler: async function (urlParam) {
        await this.handleBasicActionComponentSwitch(urlParam)
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    this.isLoading = true

    // Get the query params send by Pipedrive
    this.model = JSON.parse(JSON.stringify(this.$route.query)) // deep clone query params from Pipedrive

    // Create the tag name
    this.model.tag_name = 'Pipedrive Extensions: Basic Action ' + moment().format('lll')

    // Check if there is an authenticated user
    if (!localStorage.getItem('api_token')) {
      this.showLoginBanner = !localStorage.getItem('api_token')
      this.actionIsAllowed = false
    }

    // Get auth user
    if (localStorage.getItem('api_token')) {
      await this.getAuthUser()
    }

    // For enrolling to sequence, we need to check if the user has enough permission
    if (this.$route.path === '/integrations/pipedrive/app-extensions/enroll-to-sequence' && this.authUser) {
      // Rules taken from app-sidebar.vue
      const automationEnabled = this.authUser.automation_enabled
      const hasViewAccess = this.authUser.user_permissions.includes('view workflow')
      const isCompanyAdmin = this.authUser.user_roles.includes('Company Admin')
      const readOnlyAccess = this.authUser.read_only_access
      // SimpSocial rule
      const customBlockRule = this.authUser.company.reseller_id === 357 && !this.authUser.user_roles.includes('Billing Admin')

      this.hasSequenceAccess = automationEnabled && hasViewAccess && isCompanyAdmin && !readOnlyAccess && !customBlockRule
      this.actionIsAllowed = this.actionIsAllowed && this.hasSequenceAccess
    }

    this.isLoading = false

    // listen for a log in on a different tab
    this.$tabEvent.on('user_logged_in', async () => {
      location.reload() // so we can recreate child components that need to load data from the api
    })
  },
  destroyed() {
    this.$tabEvent.off('user_logged_in')
  }
}
</script>
