var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row nav-padding inbox-filter" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "el-form",
          {
            ref: "companySettingsForm",
            staticClass: "form-aloware",
            attrs: { "label-position": "top", model: _vm.company_clone },
          },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "contact_status_control_enabled" } },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "contact_status_control_enabled" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [
                    _vm._v(
                      "\n                        Contact Task Status\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("small", [
                    _c("span", { staticClass: "d-flex" }, [
                      _vm._v(
                        "Allows for the transitioning of contacts between different statuses."
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "switch-label" },
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#00a344",
                        disabled:
                          _vm.loading || !_vm.hasPermissionTo("update company"),
                      },
                      on: { change: _vm.onChangeContactStatusControlEnabled },
                      model: {
                        value: _vm.company_clone.contact_status_control_enabled,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.company_clone,
                            "contact_status_control_enabled",
                            $$v
                          )
                        },
                        expression:
                          "company_clone.contact_status_control_enabled",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", [_vm._v("Enable Contact Task Status")]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-3" },
          _vm._l(_vm.categories, function (category, index) {
            return _c(
              "el-card",
              {
                key: index,
                staticClass: "box-card",
                class: `${index > 0 ? "mt-5" : ""}`,
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", { staticClass: "settings-title" }, [
                      _vm._v(_vm._s(category.label)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "blue create-settings-button pull-right",
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showForm(category.type)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v(" Create Filter\n                    "),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "inbox-settings-table",
                    attrs: {
                      "row-class-name": "inbox-table-row",
                      "show-header": false,
                      data: category.data,
                      fit: "",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "empty" },
                      [
                        _c("img-empty", {
                          staticClass: "mx-auto inbox-empty-image",
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "alo-empty-text mb-3" }, [
                          _c("div", { staticClass: "empty-text-subject" }, [
                            _vm._v(
                              "No " +
                                _vm._s(category.label) +
                                " filter settings."
                            ),
                          ]),
                          _vm._v(" "),
                          _c("small", { staticClass: "empty-text-info" }, [
                            _vm._v("Click the "),
                            _c("b", [_vm._v("Create Filter")]),
                            _vm._v(" to create one."),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-table-column", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c("div", [
                                    _c("i", {
                                      staticClass: "el-icon-s-operation",
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.name)),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "ml-3 d-flex" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass:
                                            "pt-0 pb-0 action-button update-button",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showForm(
                                                scope.row.type,
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "material-icons font-14-400",
                                                },
                                                [_vm._v("create")]
                                              ),
                                              _vm._v(
                                                "Edit Filter\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "ml-auto" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass:
                                            "pt-0 pb-0 action-button delete-button",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteFilter(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-delete text-danger",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          }),
          1
        ),
        _vm._v(" "),
        _c("inbox-filter-form", {
          attrs: {
            visible: _vm.show_form,
            type: _vm.selected_type,
            model: _vm.selected_model,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_form = $event
            },
            closed: _vm.closeForm,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-label" }, [
      _c("h5", [_vm._v("Company Wide Inbox Filters")]),
      _vm._v(" "),
      _c("small", [
        _vm._v("Create company wide filters your users can select from."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }