<template>
    <div class="_400">
        <div
            v-for="callIssue in filteredCallIssues"
        >
            <div class="border-top text-left px-4 py-3">
                <label class="form-control-label"> Agent: </label>
                {{ user?.full_name ?? '-' }}
                <div v-if="callIssue.data.name === RTT_EVENT">
                    <network-signal-strength
                        :value-issue="callIssue.data.avg"
                        :issue="networkIssues[callIssue.data.name]"
                        :title="networkIssues[callIssue.data.name].trigger"
                    />
                    <label class="form-control-label">Max outbound Latency:</label>
                    {{ callIssue.data.max }} ms <br/>
                    <label class="form-control-label">Avg outbound Latency:</label>
                    {{ callIssue.data.avg }} ms
                    <br/>

                    {{ networkIssues[callIssue.data.name].details }}
                </div>
                <div v-else-if="callIssue.data.name === MOS_EVENT">
                    <network-signal-strength
                        :value-issue="callIssue.data.avg"
                        :issue="networkIssues[callIssue.data.name]"
                        :title="networkIssues[callIssue.data.name].trigger"
                    />
                    <label class="form-control-label">Outbound MOS:</label>
                    {{ callIssue.data.avg }}
                    <br/>

                    {{ networkIssues[callIssue.data.name].details }}
                </div>
                <div v-else-if="callIssue.data.name === JITTER_EVENT">
                    <network-signal-strength
                        :value-issue="callIssue.data.max"
                        :issue="networkIssues[callIssue.data.name]"
                        :title="networkIssues[callIssue.data.name].trigger"
                    />
                    <label class="form-control-label">Max inbound Jitter:</label>
                    {{ callIssue.data.max }} ms <br/>
                    <label class="form-control-label">Avg inbound Jitter:</label>
                    {{ callIssue.data.avg }} ms
                    <br/>

                    {{ networkIssues[callIssue.data.name].details }}
                </div>
                <div v-else-if="callIssue.data.name === BYTES_RECEIVED_EVENT">
                    <network-signal-strength
                        :value-issue="callIssue.data.min"
                        :issue="networkIssues[callIssue.data.name]"
                        :title="networkIssues[callIssue.data.name].trigger"
                    />
                    <label class="form-control-label">Bytes received:</label>
                    {{ callIssue.data.min }}
                    <br/>

                    {{ networkIssues[callIssue.data.name].details }}
                </div>
                <div v-else-if="callIssue.data.name === BYTES_SENT_EVENT">
                    <network-signal-strength
                        :value-issue="callIssue.data.min"
                        :issue="networkIssues[callIssue.data.name]"
                        :title="networkIssues[callIssue.data.name].trigger"
                    />
                    <label class="form-control-label">Bytes sent:</label>
                    {{ callIssue.data.min }}
                    <br/>

                    {{ networkIssues[callIssue.data.name].details }}
                </div>
                <div v-else-if="callIssue.event_name === HIGH_PACKET_LOSS_EVENT">
                    <network-signal-strength :value-issue="callIssue.data.avg"
                                             :issue="networkIssues[callIssue.event_name]"
                                             :title="networkIssues[callIssue.event_name].trigger"
                    />
                    <label class="form-control-label">Inbound Packet Loss:</label>
                    {{ callIssue.data.avg }}%
                    <br/><br/>

                    {{ networkIssues[callIssue.event_name].details }}
                </div>
                <div v-else-if="callIssue.event_name === HIGH_PACKETS_LOST_FRACTION_EVENT">
                    <network-signal-strength :value-issue="callIssue.data.avg"
                                             :issue="networkIssues[callIssue.event_name]"
                                             :title="networkIssues[callIssue.event_name].trigger"
                    />
                    <label class="form-control-label">Inbound Packet Loss Fraction:</label>
                    {{ callIssue.data.avg }}%
                    <br/><br/>

                    {{ networkIssues[callIssue.event_name].details }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NetworkSignalStrength from './network-signal-strength'
import {
    networkIssues,
    RTT_EVENT,
    MOS_EVENT,
    JITTER_EVENT,
    BYTES_RECEIVED_EVENT,
    BYTES_SENT_EVENT,
    HIGH_PACKET_LOSS_EVENT,
    HIGH_PACKETS_LOST_FRACTION_EVENT,
} from '../../constants/network-logs/network-issues'

export default {
    name: 'network-logs-display',

    components: {NetworkSignalStrength},

    props: {
        callIssues: {
            required: true,
        },
        user:       {
            required: true,
        },
    },

    data() {
        return {
            networkIssues,
            RTT_EVENT,
            MOS_EVENT,
            JITTER_EVENT,
            BYTES_RECEIVED_EVENT,
            BYTES_SENT_EVENT,
            HIGH_PACKET_LOSS_EVENT,
            HIGH_PACKETS_LOST_FRACTION_EVENT
        }
    },

    methods: {
        calculateAvgMetrics(threshold, values) {
            // If no threshold or values, return 'N/A'
            if (!threshold || !values || values.length === 0) return 'N/A'

            values = values.filter((value) => {
                if (threshold.name === 'max' || threshold.name === 'maxAverage') {
                    return value > threshold.value
                } else if (threshold.name === 'min' || threshold.name === 'minAverage') {
                    return value < threshold.value
                }
            })

            const sum = values.reduce((accum, current) => accum + current, 0)

            return parseFloat((sum / values.length).toFixed(2))
        },
    },

    computed: {
        filteredCallIssues() {
            return this.mapCallIssues.filter((callIssue) => {
                return (
                    this.networkIssues[callIssue.data.name] ||
                    this.networkIssues[callIssue.event_name]
                );
            });
        },
        mapCallIssues() {
            return this.callIssues.map(issue => {
                // Ensure issue.data exists and handle missing fields
                const data = issue.data || {}

                // Check if values is a valid array, otherwise default to empty
                const values = Array.isArray(data.values) ? data.values : []

                // If no values, thresholds, or data, handle it gracefully
                const max = values.length ? Math.max(...values).toFixed(2) : 'N/A'
                const min = values.length ? Math.min(...values).toFixed(2) : 'N/A'
                const avg = values.length && data.threshold ? this.calculateAvgMetrics(data.threshold, values) : 'N/A'

                return {
                    ...issue,
                    data: {
                        ...data,
                        max: max,
                        min: min,
                        avg: avg,
                    },
                }
            })
        },
    },
}
</script>

<style scoped>
.form-control-label {
    padding-top: 0.2rem;
    padding-bottom: 0.5rem;
    margin-right: 4px;
}
</style>
