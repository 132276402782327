<template>
    <el-popover ref="popover_notes"
                title="Notes:"
                :placement="small ? 'top' : 'left'"
                :width="small ? 265 : 400"
                trigger="click"
                data-test="communication-note-popover"
                @show="showNote">
        <slot name="trigger"
              slot="reference">
            <el-button type="text text-greyish opacity-4 hover-opacity text-md m-0 p-0"
                       data-testid="communication-note-btn"
                       v-if="!communication.notes">
                <i class="el-icon-document"></i>
            </el-button>
            <el-button type="text text-dark-greenish hover-text-blackish text-md m-0 p-0"
                       data-testid="communication-note-btn"
                       v-else>
                <i class="el-icon-document"></i>
            </el-button>
        </slot>
        <div class="row">
            <div class="col-12">
                <el-input type="textarea"
                          placeholder="Write your call notes here..."
                          ref="communication_notes"
                          data-testid="communication-note-input"
                          v-model="note"
                          :autosize="{ minRows: 3, maxRows: 5}"
                          :disabled="!hasPermissionTo('note communication') || loading_btn"
                          autofocus>
                </el-input>

                <div class="mt-1 text-right h-24">
                    <span class="text-muted"
                          data-testid="saving"
                          v-show="loading_btn">
                        <i class="material-icons loader text-dark-greenish">&#xE863;</i>
                        saving
                    </span>
                    <span class="text-muted"
                          data-testid="saved"
                          v-show="!loading_btn && loading">
                        saved
                    </span>
                    <span class="text-deep-orange _600"
                          data-testid="unsaved-changes"
                          v-if="hasUnsavedChanges && !loading_btn">
                        unsaved changes
                    </span>
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12">
                <el-button class="pull-right"
                           type="success"
                           size="small"
                           data-testid="save-note-btn"
                           :loading="loading_btn"
                           :disabled="loading_btn"
                           @click="saveNote">
                    Save
                </el-button>
            </div>
        </div>
    </el-popover>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin} from '../mixins'
    import {mapState} from 'vuex'

    export default {
        mixins: [acl_mixin],

        props: {
            communication: {
                required: true
            },

            small: {
                default: false,
                type: Boolean,
                required: false
            }
        },

        data() {
            return {
                auth: auth,
                loading: false,
                loading_btn: false,
                note: null,
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            hasUnsavedChanges() {
                if (this.communication && this.note != this.communication.notes) {
                    return true
                }

                return false
            }
        },

        methods: {
            showNote() {
                this.note = this.communication.notes
            },

            changeNote: _.debounce(function () {
                if (this.hasPermissionTo('note communication')) {
                    this.saveNote()
                }
            }, 2000),

            saveNote() {
                this.loading_btn = true
                axios.patch('/api/v1/communication/' + this.communication.id, {
                    notes: this.note
                }).then(res => {
                    this.communication.notes = this.note
                    this.loading_btn = false
                    this.loading = true
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                }).catch(err => {
                    this.note = null
                    this.$root.handleErrors(err.response)
                    this.loading_btn = false
                }).then(() => {
                    if (this.$refs.communication_notes) {
                        this.$refs.communication_notes.focus()
                    }
                })
            },
        },

        watch: {
            'communication.notes': function() {
                this.showNote()
            }
        }
    }
</script>
