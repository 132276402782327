<template>
    <div class="d-flex flex-column card bg-white p-4"
         style="width: 100%;max-width: 1024px"
         v-loading="loading">
        <div class="card-title mb-0">
            <div class="d-flex flex-row justify-content-between">
                <div>
                    <h3 style="font-weight: 500;color: black">Report Issue</h3>
                </div>
                <div style="cursor: pointer">
                    <i class="material-icons hover"
                       style="font-size: 25px; color: #666;"
                       @click="cancel">close</i>
                </div>
            </div>
        </div>
        <div style="border-bottom: solid 1px #ddd; height: 2px; margin: 12px 0"></div>
        <div class="d-flex flex-row mt-2">
            <div class="d-flex mr-2 flex-column justify-content-center">
                <span style="font-size: 16px;font-weight: 500;color: black;">Category:</span>
            </div>
            <button class="btn-xs btn-outline-primary mr-2"
                    style="border-radius: 5px;outline: none !important; box-shadow: none;border-width: 1px;font-size: 13px;padding: 3px 8px;"
                    :style="cat.value === form.category ? '' : 'border:solid 1px #ddd'"
                    v-for="cat in category_list"
                    :key="cat.value"
                    @click="form.category = cat.value">
                {{cat.label}}
            </button>
        </div>
        <div class="mt-3" style="font-size: 16px;font-weight: 500;color: black;">
            Provide specifics on any issues related to this communication:
        </div>
        <textarea class="my-3 p-2"
                  style="border:solid 1px #ddd; border-radius:5px"
                  rows="6"
                  v-model="form.message"></textarea>
        <div class="d-flex flex-row justify-content-end">
            <div class="d-flex mr-3 flex-column justify-content-center">
                Message will be sent to support@aloware.com
            </div>
            <el-button style="border-radius:6px !important;padding: 7px 12px;font-size: 14px !important;"
                       type="primary"
                       size="small"
                       :disabled="!form.message"
                       @click="reportIssue">
                Report
            </el-button>
        </div>
    </div>
</template>
<script>
import * as CustomerIssues from '../../constants/customer-issues'

export default {
        name: "customer-report-issue-form",

        data() {
            return {
                loading: false,
                form: {
                    category: CustomerIssues.DATA_ACCURACY,
                    message: null,
                    communication_id: null
                },
                category_list: [
                    {
                        label: 'Data Accuracy',
                        value: CustomerIssues.DATA_ACCURACY
                    },
                    {
                        label: 'Call Quality',
                        value: CustomerIssues.CALL_QUALITY
                    },
                    {
                        label: 'Other',
                        value: CustomerIssues.OTHER
                    }
                ],
                CustomerIssues
            }
        },

        props: {
            communication_id: {
                required: true
            }
        },

        methods: {
            cancel() {
                this.resetForm()
                this.$emit('cancel')
            },

            resetForm() {
                this.form = {
                    category: CustomerIssues.DATA_ACCURACY,
                    message: null,
                    communication_id: null
                }
            },

            reportIssue() {
                this.loading = true

                axios.post('/api/v1/communications/' + this.communication_id + '/report-issue', this.form)
                .then(res => {
                    this.loading = false
                    this.$emit('submitted', res.data)
                })
                .catch(err => {
                    this.loading = false
                    this.$root.handleErrors(err.response)
                })
            }
        }
    }
</script>

<style scoped>

</style>
