<template>
    <div v-if="isDataValid">
        <!-- If 'single_button' is true, let's show this component with a single icon button. -->
        <el-tooltip v-if="single_button"
                    class="item"
                    effect="dark"
                    content="Show Transcription"
                    data-testid="communication-transcription-tooltip"
                    placement="bottom">
                    <!--
                        The Smart Transcription modal will be shown on click.
                        @event click
                    -->
            <button class="btn btn-sm blue btn-icon opaq"
                    data-testid="communication-transcription-button"
                    @click="openTranscriptionTalkLink">
                <i class="material-icons">chat</i>
            </button>
        </el-tooltip>

        <!--
            If 'single_button' is false, let's show this component with a clickable icon + button_text.
            The Smart Transcription modal will be shown on click.
            @event click
        -->
        <span v-else class="text text-dark-greenish m-0 p-0 pointer"
              style="font-size: 14px!important; position: relative; top: 10px; bottom: 10;"
              data-test="communication-smart-transcription-button"
              @click="openTranscriptionTalkLink">
            <i class="fa fa-file-text" style="margin-left: 1px"/>
            <span style="margin-left: 5px">{{ this.button_text }}</span>
        </span>
    </div>
</template>

<script>

import {acl_mixin} from '../mixins'

export default {
    name: "communication-transcription-button",

    mixins: [acl_mixin],

    props: {
        communication: {
            required: true
        },
        button_text: {
            type: String,
            default: "Show Transcription"
        },
        single_button: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        /**
         * Validates if the required data is available to show the button.
         * @returns {Boolean}
         */
        isDataValid() {
            const baseUrl = this.getTalkUrl()
            return baseUrl && this.communication && this.communication.id
        }
    },

    methods: {
        /**
         * Opens the transcription link in a new tab.
         * @public
         */
        openTranscriptionTalkLink() {
            const baseUrl = this.getTalkUrl()
            const contactId = this.communication.contact_id
            const communicationId = this.communication.id

            let url = `${baseUrl}/contacts/${contactId}/communications/${communicationId}?showTranscription=true`

            // if contact is deleted
            if (!this.communication.contact) {
                url = `${baseUrl}/communication/${communicationId}?showTranscription=true`
            }

            window.open(url, '_blank')
        },
    }
}
</script>
