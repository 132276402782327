<template>
    <div class="d-flex flex-row align-items-center"
         v-if="hubspotEnabled">
        <el-select class="d-flex flex-grow-1"
                   popper-class="hubspotListSelector"
                   filterable
                   clearable
                   default-first-option
                   collapse-tags
                   remote
                   v-model="list_id"
                   :remote-method="fetchHubspotLists"
                   :placeholder="placeholder"
                   :multiple="multiple"
                   :multiple-limit="multiple ? multipleLimit : 0"
                   :loading="loading_hubspot_lists"
                   :reserve-keyword="true"
                   @visible-change="onDropdownVisibleChange"
                   @clear="onClear">
            <el-option
                v-for="list in hubspot_lists"
                :key="list.listId"
                :label="list.name"
                :value="list.listId">
            </el-option>
            <el-option value=""
                       v-if="is_loading_more"
                       disabled
                       class="loading-option">
                Loading More...
            </el-option>
        </el-select>
        <el-button type="primary"
                   icon="fa fa-refresh"
                   size="mini"
                   class="ml-2 w-30"
                   :disabled="loading_hubspot_lists"
                   :loading="loading_hubspot_lists"
                   circle
                   @click="getHubspotLists">
        </el-button>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../auth'
import {acl_mixin} from '../mixins'
import { debounce } from 'lodash'

export default {
    mixins: [acl_mixin],

    props: {
        value: {
            required: false,
        },

        exclude: {
            required: false,
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        multipleLimit: {
            type: Number,
            required: false,
            default: 0
        },
    },

    data() {
        return {
            auth: auth,
            list_id: this.value,
            loading_hubspot_lists: false,
            hubspot_lists: [],
            offset: 0,
            search_query: null,
            cancel_token_source: null,
            scroll_disabled: true,
            is_loading_more: false
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company
        }),

        hubspotEnabled() {
            if (this.current_company &&
                this.current_company.hubspot_integration_enabled) {
                return true
            }

            return false
        },

        placeholder() {
            if (this.loading_hubspot_lists) {
                return 'Loading ...'
            }

            return 'HubSpot lists'
        },

        activeList() {
            if (this.hubspot_lists && this.list_id) {
                return this.hubspot_lists.find(hubspot_list => hubspot_list.listId === this.list_id)
            }

            return
        }
    },

    mounted() {
        if (this.hubspotEnabled) {
            this.getHubspotLists()
        }

        this.debouncedFetchHubspotLists = debounce(this.fetchHubspotLists, 2000);
    },

    methods: {
        // load more items if we're almost reached end of the list
        handleScroll(event) {
            const { scrollTop, scrollHeight, clientHeight } = event.target;

            if (scrollTop + clientHeight >= scrollHeight - 10 && !this.scroll_disabled) {
                this.is_loading_more = true
                this.debouncedFetchHubspotLists(this.search_query, this.offset + 20);
            }
        },
        // Enable/disable scrolling listener when dropdown visibility changed
        onDropdownVisibleChange(visible) {
            const dropdownClass = `.hubspotListSelector .el-select-dropdown__wrap`;
            this.$nextTick(() => {
                const dropdown = document.querySelector(dropdownClass);
                if (!dropdown) {
                    return
                }

                visible
                    ? dropdown.addEventListener('scroll', (event) => this.handleScroll(event))
                    : dropdown.removeEventListener('scroll', (event) => this.handleScroll(event))
            });
        },
        getHubspotLists() {
            this.list_id = null
            this.hubspot_lists = []
            this.fetchHubspotLists(null, 0, true)
        },

        fetchHubspotLists(search = null, offset = 0, force = false) {
            // Do not make the request if we are already loaded all the lists
            if (
                search === this.search_query &&
                offset === this.offset &&
                force === false
            ) {
                return
            }

            const payload = {
                params: {
                    offset,
                    search,
                }
            };

            // Cancel the previous request if it's search
            if (this.search_query !== search) {
                if (this.cancel_token_source) {
                    this.cancel_token_source.cancel('New search initiated')
                }

                // Create a new CancelToken for the current request
                this.cancel_token_source = axios.CancelToken.source()

                payload.cancelToken = this.cancel_token_source.token
                offset = 0
                this.search_query = search
            }

            this.offset = offset

            if (!offset) {
                // do not block the UI if we are loading more
                this.loading_hubspot_lists = true
            }

            return axios.get('/api/v1/integration/hubspot/lists', payload)
                .then((res) => {
                    if (offset) {
                        this.hubspot_lists.push(...res.data.lists)
                    } else {
                        this.hubspot_lists = res.data.lists
                    }

                    this.loading_hubspot_lists = false
                    this.scroll_disabled = !res.data.hasMore
                    this.is_loading_more = false
                })
                .catch((err) => {
                    this.is_loading_more = false
                    if (axios.isCancel(err)) {
                        console.log('Request canceled:', err.message);
                    } else {
                        // Handle other errors
                        this.loading_hubspot_lists = false;
                        this.$root.handleErrors(err.response);
                        console.log(err);
                }
            })
        },

        onClear() {
            this.list_id = null
            this.hubspot_lists = []
            this.fetchHubspotLists(null, 0, true)
        }
    },

    watch: {
        value() {
            this.list_id = this.value
        },

        list_id(val) {
            if (this.value !== undefined && this.list_id !== this.value) {
                this.$emit('selectedList', this.activeList)
                this.$emit('change', val)
                this.$emit('update:value', val) // auto sync without calling any method
            }
        }
    }
}
</script>

<style scoped>
.loading-option {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-option::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s ease-in-out infinite;
    margin-right: 5px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>

