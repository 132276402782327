<template>
    <div>
        <div class="vertical-center text-left mb-4"
             v-if="series_name == 'campaign'">
			<span v-if="filter.from_date"
                  class="call-log-head">
				Talk Time per Line from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
			</span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
				Talk Time per Line
                <strong>All Time</strong>
			</span>
        </div>
        <div class="vertical-center text-left mb-4"
             v-else-if="series_name == 'ring_group'">
            <span v-if="filter.from_date"
                  class="call-log-head">
				Talk Time per Ring Group from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
			</span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
				Talk Time per Ring Group
                <strong>All Time</strong>
			</span>
        </div>
        <div class="vertical-center text-left mb-4"
             v-else-if="series_name == 'user'">
            <span v-if="filter.from_date"
                  class="call-log-head">
                Talk Time per User from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
            </span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
                Talk Time per User
                <strong>All Time</strong>
            </span>
        </div>
        <div class="placeholder text-center d-flex align-items-center justify-content-center"
             :class="{ blink: loading }"
             v-if="loading || campaigns.length == 0"
             style="height: 450px">
            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div style="height: 450px"
             v-else>
            <graph-data-switch :series="options.series"
                               :type="type">
                <highcharts v-bind:id="graph_id"
                            v-show="is_done && options.series.length > 0"
                            :options="options"
                            style="height: 450px"
                            ref="highchart">
                </highcharts>
                <div class="el-table__empty-block"
                     v-show="is_done && !options.series.length">
                    <span class="el-table__empty-text"
                          style="color: #606266;">
                        No Data
                    </span>
                </div>
            </graph-data-switch>
        </div>
    </div>
</template>

<script>
    import {date_mixin, graph_mixin, report_mixin} from '../mixins'
    import auth from '../auth'

    export default {
        props: {
            series_name: {
                default: 'campaign'
            },
            stacked: {
                type: Boolean,
                default: false
            },
            report_index: {
                default: 0
            },
            type: {}
        },

        mixins: [report_mixin, graph_mixin, date_mixin],

        data() {
            let self = this
            return {
                auth: auth,
                loading: false,
                aggregated_counts: [],
                graph_id: '',
                report_type: 'talktime_v_campaign',
                options: {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'column'
                    },
                    plotOptions: {
                        column: {
                            stacking: null,
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    if (this.y != 0) {
                                        return this.y
                                    }
                                },
                                crop: false,
                                style: {
                                    fontSize: '14px'
                                }
                            }
                        }
                    },
                    xAxis: {
                        type: 'datetime',
                        tickInterval: 300 * 1000, //5 minutes
                        min: this.localizedMoment().startOf('day').hour(0).valueOf(), //12am
                        max: this.localizedMoment().startOf('day').hour(1).valueOf(), //1am
                        labels: {
                            formatter: function () {
                                if (this.isLast) {
                                    return '1Hr+'
                                }
                                if (this.isFirst) {
                                    return '0'
                                }

                                return self.localizedMoment(this.value).format('m') + ' Mins'
                            }
                        }
                    },
                    yAxis: {
                        title: {
                            text: 'Number Of Calls',
                            style: {
                                'font-size': '14px',
                                'color': '#090A0D'
                            }
                        },
                        min: 0,
                        tickInterval: 1
                    },
                    tooltip: {
                        crosshairs: false,
                        formatter: function () {
                            let time = self.localizedMoment(this.x).format('m')
                            let hour = self.localizedMoment(this.x).format('H')
                            let end = self.localizedMoment(this.x).add(5, 'minutes').format('m')

                            let toReturn = '<b>' + this.series.name + '</b> has <b>' + this.y.toLocaleString() + '</b>'
                            toReturn += this.point.y > 1 ? ' calls' : ' call'

                            if (hour > 0) {
                                toReturn += ' that lasts more than an hour'
                                return toReturn
                            }
                            toReturn += ' that lasts ' + time + ' - ' + end + ' minutes'
                            return toReturn

                        },
                    },
                }
            }
        },

        mounted() {
            this.setGraphId()
            this.setReportType()
            this.setIsStacked()
            this.getCommunications()
        },

        methods: {
            generateGraphData: function () {
                let keys = Object.keys(this.aggregated_counts)
                for (let index in keys) {
                    let campaign_data = this.aggregated_counts[keys[index]]

                    //do not include nulls
                    if (campaign_data.series_name == null) {
                        continue
                    }

                    //add campaign data
                    let temp_data = []

                    //segregate data values to respective xaxis values
                    for (let key in campaign_data.data) {
                        //get the minutes
                        let m = parseInt(campaign_data.data[key].name) * 5 //5 minutes

                        //set date as today and set the hour
                        let xValue = this.localizedMoment().startOf('day').hour(0).minute(m).valueOf()

                        //segregate record based on xaxis label
                        if (campaign_data.data[key].count > 0) {
                            //if more than 1 hour
                            if (campaign_data.data[key].name >= 12) {
                                xValue = this.localizedMoment().startOf('day').hour(1).valueOf()
                            }

                            if (xValue in temp_data) {
                                temp_data[xValue] += campaign_data.data[key].count
                            } else {
                                temp_data[xValue] = campaign_data.data[key].count
                            }

                        }

                    }
                    let new_temp_data = []
                    if (Object.keys(temp_data).length > 0) {
                        for (let group_num = 0; group_num <= 12; group_num++) {
                            let mins = parseInt(group_num) * 5 //5 minutes
                            let timestamp = this.localizedMoment().startOf('day').hour(0).minute(mins).valueOf()
                            if (timestamp in temp_data) {
                                new_temp_data[timestamp] = temp_data[timestamp]
                            } else {
                                new_temp_data[timestamp] = 0
                            }
                        }
                    }

                    //reformat data to pass on highchart
                    let paired_data = []
                    for (let timestamp_key in new_temp_data) {
                        paired_data.push([parseInt(timestamp_key), new_temp_data[timestamp_key]])
                    }

                    let series = {
                        type: 'column',
                        name: campaign_data.series_name,
                        // color: "#0033A0",
                        data: paired_data
                    }

                    // have high charts re-draw this.
                    if (paired_data.length > 0) {
                        this.options.series.push(series)
                    }
                }

                this.$emit('reportLoaded', this.report_index)
            },
            setReportType: function () {
                switch (this.series_name) {
                    case 'user':
                        this.report_type = 'talktime_v_user'
                        break
                    case 'ring_group':
                        this.report_type = 'talktime_v_ring_group'
                        break
                    default: //campaign
                        this.report_type = 'talktime_v_campaign'
                        break
                }
            },
            setGraphId: function () {
                this.graph_id = 'talktime-' + this.series_name
                if (this.stacked) {
                    this.graph_id += '-stacked'
                }
                this.graph_id += '-graph'
            },
            setIsStacked: function () {
                if (!this.stacked) {
                    this.options.plotOptions.column.stacking = null
                } else {
                    this.options.plotOptions.column.stacking = 'normal'
                }
            },
        },
        watch: {
            series_name: {
                handler: function (newValue, oldValue) {
                    this.setGraphId()
                    this.setReportType()
                    this.getCommunications()
                }
            },
            stacked: {
                handler: function (newValue, oldValue) {
                    this.setGraphId()
                    this.setIsStacked()
                    this.getCommunications()
                }
            },
        },
    }
</script>
