var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.triggers, function (trigger, idx) {
        return _c(
          "el-card",
          { key: trigger.id, staticClass: "box-card mb-3" },
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                !["contact", "disposition", "call disposition"].includes(
                  trigger.type
                )
                  ? _c("span", { staticClass: "text-md _600" }, [
                      _vm._v(
                        "\n                #" +
                          _vm._s(idx + 1) +
                          " - " +
                          _vm._s(_vm._f("fixCommType")(trigger.type)) +
                          " Trigger\n            "
                      ),
                    ])
                  : _c("span", { staticClass: "text-md _600" }, [
                      _vm._v(
                        "\n                #" +
                          _vm._s(idx + 1) +
                          " - " +
                          _vm._s(_vm._f("fixName")(trigger.type)) +
                          " Trigger\n            "
                      ),
                    ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [
                    _c(
                      "el-dropdown",
                      {
                        attrs: { trigger: "click" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "dropdown",
                              fn: function () {
                                return [
                                  _c("el-dropdown-menu", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column px-3",
                                      },
                                      [
                                        (trigger.type == "contact" &&
                                          !_vm.automation_trigger &&
                                          !_vm.power_dialer_trigger) ||
                                        (trigger.type != "contact" &&
                                          trigger.type != "disposition" &&
                                          !_vm.power_dialer_trigger &&
                                          !_vm.automation_trigger) ||
                                        (trigger.type == "disposition" &&
                                          trigger.disposition_status_id != 0) ||
                                        (trigger.type == "call disposition" &&
                                          trigger.call_disposition_id != 0)
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "text-blue text-left",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.duplicate(
                                                      trigger,
                                                      idx
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                  },
                                                  [_vm._v("content_copy")]
                                                ),
                                                _vm._v(
                                                  "\n                                    Duplicate\n                                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        trigger.valid &&
                                        _vm.triggerCanBeCopied(trigger)
                                          ? [
                                              _c(
                                                "el-popover",
                                                {
                                                  ref:
                                                    "copy_form_" + trigger.id,
                                                  refInFor: true,
                                                  attrs: {
                                                    placement: "left-start",
                                                    width: "250",
                                                    trigger: "click",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "loading",
                                                          rawName: "v-loading",
                                                          value:
                                                            _vm.copy_trigger_loading,
                                                          expression:
                                                            "copy_trigger_loading",
                                                        },
                                                      ],
                                                    },
                                                    [
                                                      _c("campaign-selector", {
                                                        on: {
                                                          change:
                                                            _vm.updateCopyTriggerToCampaign,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.copy_trigger_to_campaign,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.copy_trigger_to_campaign =
                                                              $$v
                                                          },
                                                          expression:
                                                            "copy_trigger_to_campaign",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticClass:
                                                            "btn btn-success mt-2 w-full",
                                                          attrs: {
                                                            type: "success",
                                                            disabled:
                                                              !_vm.copy_trigger_to_campaign,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.copyTriggerToLine(
                                                                trigger
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                Copy\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "text-success ml-0 text-green text-left",
                                                      attrs: {
                                                        slot: "reference",
                                                        type: "text",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _c(
                                                        "i",
                                                        {
                                                          staticClass:
                                                            "material-icons",
                                                        },
                                                        [_vm._v("phone")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                            Copy to line\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass:
                                              "text-danger ml-0 text-red text-left",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeAt(idx)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "i",
                                              { staticClass: "material-icons" },
                                              [_vm._v("delete")]
                                            ),
                                            _vm._v(
                                              "\n                                    Delete\n                                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "px-3 py-2 border-0",
                            attrs: { size: "medium" },
                          },
                          [_c("i", { staticClass: "fa fa-ellipsis-h" })]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("trigger", {
              attrs: {
                trigger: trigger,
                automation_trigger: _vm.automation_trigger,
                power_dialer_trigger: _vm.power_dialer_trigger,
                disable_any_contact_disposition:
                  _vm.contactDispositionTriggerIndex > 0,
                disable_any_call_disposition:
                  _vm.callDispositionTriggerIndex > 0,
                used_contact_disposition_statuses:
                  _vm.usedContactDispositionStatuses,
              },
              on: {
                valid: function ($event) {
                  return _vm.validateTrigger(true)
                },
                invalid: function ($event) {
                  return _vm.validateTrigger(false)
                },
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          !_vm.automation_trigger && !_vm.power_dialer_trigger
            ? [
                _c(
                  "el-button",
                  {
                    attrs: { type: "success", size: "medium", plain: "" },
                    on: { click: _vm.addCallTrigger },
                  },
                  [
                    _c("i", { staticClass: "material-icons mr-1" }, [
                      _vm._v("call"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "_700 pointer" }, [
                      _vm._v("Add Call Trigger"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "success", size: "medium", plain: "" },
                    on: { click: _vm.addSmsTrigger },
                  },
                  [
                    _c("i", { staticClass: "material-icons mr-1" }, [
                      _vm._v("sms"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "_700 pointer" }, [
                      _vm._v("Add SMS Trigger"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.has_email_intake
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "medium", plain: "" },
                        on: { click: _vm.addEmailTrigger },
                      },
                      [
                        _c("i", { staticClass: "material-icons mr-1" }, [
                          _vm._v("email"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "_700 pointer" }, [
                          _vm._v("Add Email Trigger"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAddCallDispositionTrigger
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "medium", plain: "" },
                        on: { click: _vm.addCallDispositionTrigger },
                      },
                      [
                        _c("i", { staticClass: "material-icons mr-1" }, [
                          _vm._v("contact_phone"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "_700 pointer" }, [
                          _vm._v("Add Call Disposition Trigger"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAddContactTrigger
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "medium", plain: "" },
                        on: { click: _vm.addContactTrigger },
                      },
                      [
                        _c("i", { staticClass: "material-icons mr-1" }, [
                          _vm._v("contact_phone"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "_700 pointer" }, [
                          _vm._v("Add Contact Trigger"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.automation_trigger
            ? [
                _vm.canAddContactDispositionTrigger
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "medium", plain: "" },
                        on: { click: _vm.addContactDispositionTrigger },
                      },
                      [
                        _c("i", { staticClass: "material-icons mr-1" }, [
                          _vm._v("contact_phone"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "_700 pointer" }, [
                          _vm._v("Add Contact Disposition Trigger"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAddCallDispositionTrigger
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "medium", plain: "" },
                        on: { click: _vm.addCallDispositionTrigger },
                      },
                      [
                        _c("i", { staticClass: "material-icons mr-1" }, [
                          _vm._v("contact_phone"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "_700 pointer" }, [
                          _vm._v("Add Call Disposition Trigger"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAddContactTrigger
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "medium", plain: "" },
                        on: { click: _vm.addAutomationContactTrigger },
                      },
                      [
                        _c("i", { staticClass: "material-icons mr-1" }, [
                          _vm._v("contact_phone"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "_700 pointer" }, [
                          _vm._v("Add Contact Trigger"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAddCallTrigger
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "medium", plain: "" },
                        on: { click: _vm.addAutomationCallTrigger },
                      },
                      [
                        _c("i", { staticClass: "material-icons mr-1" }, [
                          _vm._v("call"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "_700 pointer" }, [
                          _vm._v("Add Call Trigger"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.power_dialer_trigger
            ? [
                _c(
                  "div",
                  { staticClass: "pull-left" },
                  [
                    _vm.canAddCallTrigger
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              size: "medium",
                              plain: "",
                            },
                            on: { click: _vm.addPowerDialerCallTrigger },
                          },
                          [
                            _c("i", { staticClass: "material-icons mr-1" }, [
                              _vm._v("call"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "_700 pointer" }, [
                              _vm._v("Add Call Trigger"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canAddCallDispositionTrigger
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              size: "medium",
                              plain: "",
                            },
                            on: { click: _vm.addCallDispositionTrigger },
                          },
                          [
                            _c("i", { staticClass: "material-icons mr-1" }, [
                              _vm._v("contact_phone"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "_700 pointer" }, [
                              _vm._v("Add Call Disposition Trigger"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "medium" },
                        on: { click: _vm.saveTriggers },
                      },
                      [
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading_btn,
                                expression: "loading_btn",
                              },
                            ],
                            staticClass: "material-icons loader",
                          },
                          [_vm._v("")]
                        ),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loading_btn,
                                expression: "!loading_btn",
                              },
                            ],
                            staticClass: "material-icons",
                          },
                          [
                            _vm._v(
                              "\n                        save\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "_700 pointer" }, [
                          _vm._v("Save Triggers"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }