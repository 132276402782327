export default Object.freeze([
    {
        name: 'Automotive',
        value: 'AUTOMOTIVE',
    },
    {
        name: 'Agriculture',
        value: 'AGRICULTURE',
    },
    {
        name: 'Banking',
        value: 'BANKING',
    },
    {
        name: 'Consumer',
        value: 'CONSUMER',
    },
    {
        name: 'Education',
        value: 'EDUCATION',
    },
    {
        name: 'Engineering',
        value: 'ENGINEERING',
    },
    {
        name: 'Energy',
        value: 'ENERGY',
    },
    {
        name: 'Oil & Gas',
        value: 'OIL_AND_GAS',
    },
    {
        name: 'Fast moving consumer goods',
        value: 'FAST_MOVING_CONSUMER_GOODS',
    },
    {
        name: 'Financial',
        value: 'FINANCIAL',
    },
    {
        name: 'Fintech',
        value: 'FINTECH',
    },
    {
        name: 'Food & Beverage',
        value: 'FOOD_AND_BEVERAGE',
    },
    {
        name: 'Government',
        value: 'GOVERNMENT',
    },
    {
        name: 'Healthcare',
        value: 'HEALTHCARE',
    },
    {
        name: 'Hospitality',
        value: 'HOSPITALITY',
    },
    {
        name: 'Insurance',
        value: 'INSURANCE',
    },
    {
        name: 'Legal',
        value: 'LEGAL',
    },
    {
        name: 'Manufacturing',
        value: 'MANUFACTURING',
    },
    {
        name: 'Media',
        value: 'MEDIA',
    },
    {
        name: 'Online',
        value: 'ONLINE',
    },
    {
        name: 'Raw materials',
        value: 'RAW_MATERIALS',
    },
    {
        name: 'Real Estate',
        value: 'REAL_ESTATE',
    },
    {
        name: 'Religion',
        value: 'RELIGION',
    },
    {
        name: 'Retail',
        value: 'RETAIL',
    },
    {
        name: 'Jewelry',
        value: 'JEWELRY',
    },
    {
        name: 'Technology',
        value: 'TECHNOLOGY',
    },
    {
        name: 'Telecommunications',
        value: 'TELECOMMUNICATIONS',
    },
    {
        name: 'Transportation',
        value: 'TRANSPORTATION',
    },
    {
        name: 'Travel',
        value: 'TRAVEL',
    },
    {
        name: 'Electronics',
        value: 'ELECTRONICS',
    },
    {
        name: 'Not for profit',
        value: 'NOT_FOR_PROFIT',
    },
])