var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "businessRegistrationForm",
      attrs: {
        model: _vm.businessRegistrationForm,
        rules: _vm.rules,
        "label-position": "top",
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _vm.isA2pRejected
          ? _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "el-alert",
                  {
                    attrs: {
                      "show-icon": "",
                      type: "warning",
                      closable: false,
                    },
                  },
                  [
                    _c("p", { staticClass: "mb-0" }, [
                      _c("span", { staticClass: "text-dark" }, [
                        _vm._v(
                          "\n                        Your A2P Brand Pre-Vetting was "
                        ),
                        _c("b", [_vm._v("Rejected")]),
                        _vm._v(
                          ": " +
                            _vm._s(_vm.a2pFailureReason) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        this.a2p_brand?.status === _vm.ComplianceBundleStatuses.STATUS_REJECTED
          ? _c(
              "div",
              { staticClass: "col-12" },
              _vm._l(_vm.a2p_brand.errors, function (error) {
                return _c("el-alert", {
                  key: error.error_code,
                  staticClass: "mb-2",
                  attrs: {
                    description: `Error Code ${error.registrationerrorcode}: ${error.registrationerrordescription}`,
                    title: _vm.fixToUcFirst(error.registrationerrorfields[0]),
                    type: "error",
                    "show-icon": "",
                    closable: false,
                  },
                })
              }),
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 mt-3" }, [
          _c("h5", { staticClass: "mb-0" }, [_vm._v("Business Information")]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-divider" }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _vm.isSimpSocial
              ? _c("div", { staticClass: "col-12 mb-3" }, [
                  _c("h6", [_vm._v("Fee Disclosure")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "There is a passthrough fee of $44 to register the A2P Brand. If the information submitted does not match your legal business registration, it will be rejected by\n                        TCR and will incur a passthrough fee of $10 to appeal."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("span", {
                        staticClass: "page-anchor",
                        attrs: { id: "accepted_compliance_one_time_fee" },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "accepted_compliance_one_time_fee" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                disabled: _vm.disableBusinessRegistrationForm,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.preValidateForm(
                                    "businessRegistrationForm"
                                  )
                                },
                              },
                              model: {
                                value:
                                  _vm.businessRegistrationForm
                                    .accepted_compliance_one_time_fee,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.businessRegistrationForm,
                                    "accepted_compliance_one_time_fee",
                                    $$v
                                  )
                                },
                                expression:
                                  "businessRegistrationForm.accepted_compliance_one_time_fee",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                I agree to the one-time non-refundable $44 Brand Registration Fee and $10 Appeal Fee in case the A2P Brand is rejected.\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Legal Name", prop: "customer_name" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "customer_name" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        change: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value: _vm.businessRegistrationForm.customer_name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "customer_name",
                            $$v
                          )
                        },
                        expression: "businessRegistrationForm.customer_name",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Business Type", prop: "business_type" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "business_type" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: {
                          disabled: _vm.disableBusinessRegistrationForm,
                          placeholder: "Select Business Type",
                          filterable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.preValidateForm(
                              "businessRegistrationForm"
                            )
                          },
                        },
                        model: {
                          value: _vm.businessRegistrationForm.business_type,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.businessRegistrationForm,
                              "business_type",
                              $$v
                            )
                          },
                          expression: "businessRegistrationForm.business_type",
                        },
                      },
                      _vm._l(_vm.businessTypes, function (businessType) {
                        return _c("el-option", {
                          key: businessType.id + businessType.value,
                          attrs: {
                            label: businessType.value,
                            value: businessType.value,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "Company Status", prop: "company_status" },
                  },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "company_status" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        attrs: {
                          disabled: _vm.disableBusinessRegistrationForm,
                          size: "small",
                        },
                        model: {
                          value: _vm.businessRegistrationForm.company_status,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.businessRegistrationForm,
                              "company_status",
                              $$v
                            )
                          },
                          expression: "businessRegistrationForm.company_status",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 1 } }, [
                          _vm._v("Private"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: 2 } }, [
                          _vm._v("Public"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.businessRegistrationForm.company_status === 2,
                    expression: "businessRegistrationForm.company_status === 2",
                  },
                ],
                staticClass: "col-12 col-sm-6 col-md-2",
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "stock_ticker", label: "Stock Ticker" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "stock_ticker" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        change: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value: _vm.businessRegistrationForm.stock_ticker,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "stock_ticker",
                            $$v
                          )
                        },
                        expression: "businessRegistrationForm.stock_ticker",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.businessRegistrationForm.company_status === 2,
                    expression: "businessRegistrationForm.company_status === 2",
                  },
                ],
                staticClass: "col-12 col-sm-6 col-md-2",
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: { prop: "stock_exchange", label: "Stock Exchange" },
                  },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "stock_exchange" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: {
                          disabled: _vm.disableBusinessRegistrationForm,
                          placeholder: "Select Stock Exchange",
                          filterable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.preValidateForm(
                              "customerProfileRegistrationForm"
                            )
                          },
                        },
                        model: {
                          value: _vm.businessRegistrationForm.stock_exchange,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.businessRegistrationForm,
                              "stock_exchange",
                              $$v
                            )
                          },
                          expression: "businessRegistrationForm.stock_exchange",
                        },
                      },
                      _vm._l(_vm.stockExchanges, function (stock_exchange) {
                        return _c("el-option", {
                          key: stock_exchange.id + stock_exchange.value,
                          attrs: {
                            label: stock_exchange.name,
                            value: stock_exchange.value,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Business ID Type",
                      prop: "business_registration_identifier",
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "business_registration_identifier" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: {
                          disabled: _vm.disableBusinessRegistrationForm,
                          placeholder: "Select Business ID Type",
                          filterable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.preValidateForm(
                              "businessRegistrationForm"
                            )
                          },
                        },
                        model: {
                          value:
                            _vm.businessRegistrationForm
                              .business_registration_identifier,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.businessRegistrationForm,
                              "business_registration_identifier",
                              $$v
                            )
                          },
                          expression:
                            "businessRegistrationForm.business_registration_identifier",
                        },
                      },
                      _vm._l(
                        _vm.businessRegistrationIdentifiers,
                        function (businessRegistrationIdentifier) {
                          return _c("el-option", {
                            key: businessRegistrationIdentifier.value,
                            attrs: {
                              label: businessRegistrationIdentifier.name,
                              value: businessRegistrationIdentifier.value,
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.businessRegistrationForm
                        .business_registration_identifier === "Other",
                    expression:
                      "businessRegistrationForm.business_registration_identifier === 'Other'",
                  },
                ],
                staticClass: "col-12 col-sm-6 col-md-4",
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "other_identifier",
                      label: "Name of Other Registration Type",
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "other_identifier" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        change: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value: _vm.businessRegistrationForm.other_identifier,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "other_identifier",
                            $$v
                          )
                        },
                        expression: "businessRegistrationForm.other_identifier",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Business Industry",
                      prop: "business_industry",
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "business_industry" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: {
                          disabled: _vm.disableBusinessRegistrationForm,
                          placeholder: "Select Business Industry",
                          filterable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.preValidateForm(
                              "businessRegistrationForm"
                            )
                          },
                        },
                        model: {
                          value: _vm.businessRegistrationForm.business_industry,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.businessRegistrationForm,
                              "business_industry",
                              $$v
                            )
                          },
                          expression:
                            "businessRegistrationForm.business_industry",
                        },
                      },
                      _vm._l(
                        _vm.businessIndustries,
                        function (businessIndustry) {
                          return _c("el-option", {
                            key: businessIndustry.value,
                            attrs: {
                              label: businessIndustry.name,
                              value: businessIndustry.value,
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Business Registration Number",
                      prop: "business_registration_number",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "label" },
                      [
                        _c("span", [_vm._v("Business Registration Number")]),
                        _vm._v(" "),
                        _vm.showCanadianBusinessRegistrationNumberTooltip
                          ? _c(
                              "el-tooltip",
                              { attrs: { effect: "dark", placement: "top" } },
                              [
                                _c("div", {
                                  attrs: { slot: "content" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.showCanadianBusinessRegistrationNumberContent
                                    ),
                                  },
                                  slot: "content",
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass:
                                    "fa fa-exclamation-triangle text-blue",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "business_registration_number" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        change: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value:
                          _vm.businessRegistrationForm
                            .business_registration_number,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "business_registration_number",
                            $$v
                          )
                        },
                        expression:
                          "businessRegistrationForm.business_registration_number",
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Business Regions of Operation",
                      prop: "business_regions_of_operation",
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "business_regions_of_operation" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: {
                          disabled: _vm.disableBusinessRegistrationForm,
                          placeholder: "Select Business Regions of Operation",
                          multiple: "",
                          filterable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.preValidateForm(
                              "businessRegistrationForm"
                            )
                          },
                        },
                        model: {
                          value:
                            _vm.businessRegistrationForm
                              .business_regions_of_operation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.businessRegistrationForm,
                              "business_regions_of_operation",
                              $$v
                            )
                          },
                          expression:
                            "businessRegistrationForm.business_regions_of_operation",
                        },
                      },
                      _vm._l(
                        _vm.businessRegionsOfOperations,
                        function (businessRegionsOfOperation) {
                          return _c("el-option", {
                            key: businessRegionsOfOperation.value,
                            attrs: {
                              label: businessRegionsOfOperation.name,
                              value: businessRegionsOfOperation.value,
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "website_url" } },
                  [
                    _c(
                      "template",
                      { slot: "label" },
                      [
                        _c("span", [_vm._v("Website URL")]),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "Domain of Website URL needs to match the domain of the email provided below.",
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fa fa-exclamation-triangle text-blue",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "website_url" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        placeholder: "https://example.com",
                        disabled: _vm.disableBusinessRegistrationForm,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                        blur: () => {
                          _vm.website_url = _vm.website_url
                        },
                      },
                      model: {
                        value: _vm.businessRegistrationForm.website_url,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "website_url",
                            $$v
                          )
                        },
                        expression: "businessRegistrationForm.website_url",
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "business_registration_file" } },
                  [
                    _c(
                      "template",
                      { slot: "label" },
                      [
                        _c("span", [_vm._v("Business Registration Documents")]),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "Provide valid Business Registration Documents for your country. Providing an invalid document will result in immediate Rejection.",
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-exclamation-circle text-blue",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-upload",
                      {
                        staticClass: "text-left",
                        attrs: {
                          action:
                            "/api/v1/compliance/upload-business-registration-document",
                          headers: _vm.headers,
                          "on-success": _vm.onSuccessMessage,
                          "on-error": _vm.onFailedMessage,
                          "on-progress": _vm.progressUploadMessage,
                          "before-upload": _vm.beforeUploadMessage,
                          "file-list": _vm.upload_file_list.upload,
                          "show-file-list": true,
                          multiple: true,
                          disabled:
                            _vm.loading_upload ||
                            _vm.disableBusinessRegistrationForm,
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-upload" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "el-upload__text" }, [
                          _vm._v("Drop file here or "),
                          _c("em", [_vm._v("click to upload")]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip",
                          },
                          [_vm._v("PDF file with a size less than 2MB")]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12" }, [
          _c("h5", { staticClass: "mb-0" }, [_vm._v("Address Information")]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-divider" }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Legal Country", prop: "iso_country" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "iso_country" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: {
                          disabled: _vm.disableBusinessRegistrationForm,
                          placeholder: "Select Country",
                          filterable: "",
                        },
                        on: { change: _vm.handleCountryChange },
                        model: {
                          value: _vm.businessRegistrationForm.iso_country,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.businessRegistrationForm,
                              "iso_country",
                              $$v
                            )
                          },
                          expression: "businessRegistrationForm.iso_country",
                        },
                      },
                      _vm._l(_vm.countries, function (country) {
                        return _c("el-option", {
                          key: country.id,
                          attrs: { label: country.name, value: country.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "State/Province/Region", prop: "region" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "region" },
                    }),
                    _vm._v(" "),
                    ["US", "CA"].includes(
                      _vm.businessRegistrationForm.iso_country
                    )
                      ? _c(
                          "el-select",
                          {
                            staticClass: "w-full",
                            attrs: {
                              placeholder: "Select State/Province/Region",
                              filterable: "",
                              disabled: _vm.disableBusinessRegistrationForm,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.preValidateForm(
                                  "businessRegistrationForm"
                                )
                              },
                            },
                            model: {
                              value: _vm.businessRegistrationForm.region,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.businessRegistrationForm,
                                  "region",
                                  $$v
                                )
                              },
                              expression: "businessRegistrationForm.region",
                            },
                          },
                          _vm._l(_vm.states, function (state) {
                            return _c("el-option", {
                              key: state.value,
                              attrs: { label: state.label, value: state.value },
                            })
                          }),
                          1
                        )
                      : _c("el-input", {
                          attrs: {
                            disabled: _vm.disableBusinessRegistrationForm,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.preValidateForm(
                                "businessRegistrationForm"
                              )
                            },
                          },
                          model: {
                            value: _vm.businessRegistrationForm.region,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.businessRegistrationForm,
                                "region",
                                $$v
                              )
                            },
                            expression: "businessRegistrationForm.region",
                          },
                        }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "City", prop: "city" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "city" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        input: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value: _vm.businessRegistrationForm.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.businessRegistrationForm, "city", $$v)
                        },
                        expression: "businessRegistrationForm.city",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Street", prop: "street" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "street" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        input: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value: _vm.businessRegistrationForm.street,
                        callback: function ($$v) {
                          _vm.$set(_vm.businessRegistrationForm, "street", $$v)
                        },
                        expression: "businessRegistrationForm.street",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Street 2", prop: "street_secondary" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "street_secondary" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        input: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value: _vm.businessRegistrationForm.street_secondary,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "street_secondary",
                            $$v
                          )
                        },
                        expression: "businessRegistrationForm.street_secondary",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Postal Code", prop: "postal_code" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "postal_code" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        input: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value: _vm.businessRegistrationForm.postal_code,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "postal_code",
                            $$v
                          )
                        },
                        expression: "businessRegistrationForm.postal_code",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12" }, [
          _c("h5", { staticClass: "mb-0" }, [_vm._v("Point of Contact")]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-divider" }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "First Name", prop: "auth_rep_first_name" },
                  },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "auth_rep_first_name" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        input: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value: _vm.businessRegistrationForm.auth_rep_first_name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "auth_rep_first_name",
                            $$v
                          )
                        },
                        expression:
                          "businessRegistrationForm.auth_rep_first_name",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Last Name", prop: "auth_rep_last_name" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "auth_rep_last_name" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        input: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value: _vm.businessRegistrationForm.auth_rep_last_name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "auth_rep_last_name",
                            $$v
                          )
                        },
                        expression:
                          "businessRegistrationForm.auth_rep_last_name",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Email", prop: "auth_rep_email" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "auth_rep_email" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        input: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value: _vm.businessRegistrationForm.auth_rep_email,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "auth_rep_email",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "businessRegistrationForm.auth_rep_email",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Work Cell",
                      prop: "auth_rep_phone_number",
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "auth_rep_phone_number" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        input: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value:
                          _vm.businessRegistrationForm.auth_rep_phone_number,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "auth_rep_phone_number",
                            $$v
                          )
                        },
                        expression:
                          "businessRegistrationForm.auth_rep_phone_number",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "Title", prop: "auth_rep_business_title" },
                  },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "auth_rep_business_title" },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { disabled: _vm.disableBusinessRegistrationForm },
                      on: {
                        input: function ($event) {
                          return _vm.preValidateForm("businessRegistrationForm")
                        },
                      },
                      model: {
                        value:
                          _vm.businessRegistrationForm.auth_rep_business_title,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.businessRegistrationForm,
                            "auth_rep_business_title",
                            $$v
                          )
                        },
                        expression:
                          "businessRegistrationForm.auth_rep_business_title",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Job Position",
                      prop: "auth_rep_job_position",
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "auth_rep_job_position" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-full",
                        attrs: {
                          disabled: _vm.disableBusinessRegistrationForm,
                          placeholder: "Select Job Position",
                          filterable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.preValidateForm(
                              "businessRegistrationForm"
                            )
                          },
                        },
                        model: {
                          value:
                            _vm.businessRegistrationForm.auth_rep_job_position,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.businessRegistrationForm,
                              "auth_rep_job_position",
                              $$v
                            )
                          },
                          expression:
                            "businessRegistrationForm.auth_rep_job_position",
                        },
                      },
                      _vm._l(_vm.authRepJobPositions, function (jobPosition) {
                        return _c("el-option", {
                          key: jobPosition.id + jobPosition.value,
                          attrs: {
                            label: jobPosition.value,
                            value: jobPosition.value,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12" }, [
          _c("h5", { staticClass: "mb-0" }, [_vm._v("Planning to Use")]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-divider" }),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "want_local_presence_phone_numbers" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "want_local_presence_phone_numbers" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        attrs: {
                          disabled: _vm.disableBusinessRegistrationForm,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.preValidateForm(
                              "businessRegistrationForm"
                            )
                          },
                        },
                        model: {
                          value:
                            _vm.businessRegistrationForm
                              .want_local_presence_phone_numbers,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.businessRegistrationForm,
                              "want_local_presence_phone_numbers",
                              $$v
                            )
                          },
                          expression:
                            "businessRegistrationForm.want_local_presence_phone_numbers",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Local Presence Numbers\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    !_vm.isSimpSocial
                      ? _c(
                          "a",
                          {
                            staticClass: "text-bluish",
                            attrs: {
                              href: "https://support.aloware.com/en/articles/9020267-step-by-step-guide-setting-up-local-presence-with-aloware",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            Learn more\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "want_toll_free_numbers" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "want_toll_free_numbers" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        attrs: {
                          disabled: _vm.disableBusinessRegistrationForm,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.preValidateForm(
                              "businessRegistrationForm"
                            )
                          },
                        },
                        model: {
                          value:
                            _vm.businessRegistrationForm.want_toll_free_numbers,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.businessRegistrationForm,
                              "want_toll_free_numbers",
                              $$v
                            )
                          },
                          expression:
                            "businessRegistrationForm.want_toll_free_numbers",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Toll-Free Numbers\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    !_vm.isSimpSocial
                      ? _c(
                          "a",
                          {
                            staticClass: "text-bluish",
                            attrs: {
                              href: "https://support.aloware.com/en/articles/9032116-understanding-toll-free-number-registry-use-cases-acceptable-and-restricted-categories",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            Learn more\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 col-md-4" },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "want_international_numbers" } },
                  [
                    _c("span", {
                      staticClass: "page-anchor",
                      attrs: { id: "want_international_numbers" },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        attrs: {
                          disabled: _vm.disableBusinessRegistrationForm,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.preValidateForm(
                              "businessRegistrationForm"
                            )
                          },
                        },
                        model: {
                          value:
                            _vm.businessRegistrationForm
                              .want_international_numbers,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.businessRegistrationForm,
                              "want_international_numbers",
                              $$v
                            )
                          },
                          expression:
                            "businessRegistrationForm.want_international_numbers",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            International Numbers\n                        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "row w-full" }, [
            _c(
              "div",
              { staticClass: "col-md-4 mb-3 mb-md-0 flex-3" },
              [
                _c("alert-message", {
                  attrs: { "alert-message": _vm.alertMessage },
                  on: { alert: _vm.submitAlert },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-4 w-full flex-1" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "w-full",
                    attrs: {
                      loading: _vm.loading,
                      disabled: _vm.disableBusinessRegistrationForm,
                      type: "info",
                    },
                    on: { click: _vm.saveAndCompleteLater },
                  },
                  [
                    _vm._v(
                      "\n                        Save & Complete Later\n                    "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-4 w-full flex-1" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "w-full",
                    attrs: {
                      loading: _vm.loading,
                      disabled: _vm.disableSubmitButton,
                      type: "success",
                    },
                    on: { click: _vm.submitBusinessRegistrationForm },
                  },
                  [
                    _vm._v(
                      "\n                        Submit\n                    "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }