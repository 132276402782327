var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _c("welcome-to-aloware"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fixed-header padding pt-0 pb-0",
          style: { top: _vm.top_offset + 50 + "px" },
        },
        [
          _c("div", { staticClass: "row mb-0 mt-0" }, [
            _c("div", { staticClass: "col-12 p-2" }, [
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-fill" },
                  [
                    _c("comm-advanced-filters-wrapper", {
                      attrs: {
                        no_exports: true,
                        only_date_filter:
                          !_vm.isNotReportingWithSubCategories(),
                        report_category: _vm.sub_category_export,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex ml-auto" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: _vm.closeAllMenus,
                                expression: "closeAllMenus",
                              },
                            ],
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "report-settings-btn pull-left btn btn-sm greenish dropdown-toggle mr-2 ml-2",
                                on: { click: _vm.toggleReportsMenu },
                              },
                              [_c("i", { staticClass: "fa fa-cog" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "reports-menu dropdown-menu mega-dropdown-menu dropdown-menu-overlay lean-bottom-margin bold-label left-align dropdown-menu-right no-select",
                                class: _vm.reports_menu_visible ? "show" : "",
                                staticStyle: { width: "725px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.noClose($event)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "row nav-padding" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 d-flex flex-wrap" },
                                    [
                                      _vm._m(0),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "w-50 text-right" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-xs btn-danger mr-2",
                                              on: {
                                                click: _vm.resetReportSettings,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                    Reset\n                                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-xs greenish",
                                              attrs: {
                                                disabled:
                                                  _vm.loading_reports_settings ||
                                                  !_vm.reportsHasChanges,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateReportsSettings(
                                                    $event,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.loading_reports_settings,
                                                      expression:
                                                        "loading_reports_settings",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "material-icons loader",
                                                },
                                                [_vm._v("")]
                                              ),
                                              _vm._v(
                                                "\n                                                    Save\n                                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "dropdown-divider break",
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "row pr-3" },
                                  _vm._l(
                                    _vm.reports,
                                    function (report, category) {
                                      return report.show &&
                                        !_vm.isNotReportingWithSubCategories(
                                          category
                                        )
                                        ? _c(
                                            "div",
                                            {
                                              key: category,
                                              staticClass: "col-12 p-0",
                                            },
                                            [
                                              _c(
                                                "ul",
                                                {
                                                  staticClass: "px-5",
                                                  class: report.class,
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "filter-header text-muted mb-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    " +
                                                          _vm._s(
                                                            _vm._f("ucwords")(
                                                              category.replace(
                                                                "_",
                                                                " "
                                                              )
                                                            )
                                                          ) +
                                                          "\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-checkbox-group",
                                                    {
                                                      model: {
                                                        value: report.settings,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            report,
                                                            "settings",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "report.settings",
                                                      },
                                                    },
                                                    _vm._l(
                                                      report.data,
                                                      function (report_type) {
                                                        return report_type.menu_show &&
                                                          report_type.active
                                                          ? _c(
                                                              "el-checkbox",
                                                              {
                                                                key:
                                                                  category +
                                                                  " - " +
                                                                  report_type.value,
                                                                staticClass:
                                                                  "w-50 mr-0",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.loading_reports_settings,
                                                                  label:
                                                                    report_type.value,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                        " +
                                                                    _vm._s(
                                                                      report_type.label
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isEmptyReports()
                          ? _c("div", { staticClass: "ml-3 mt-2" }, [
                              _vm._v(
                                "\n                                Please customize your report dashboard\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isTrial && !_vm.isSimpSocial
                          ? _c("video-modal-activator", { staticClass: "pl-4" })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.reports, function (report, category) {
        return _vm.hasPermissionTo("list report") &&
          !_vm.isEmptyReports() &&
          _vm.report_category == category
          ? _c(
              "div",
              {
                staticClass: "padding mt-5 pt-3",
                staticStyle: { position: "relative", overflow: "hidden" },
              },
              [
                _vm.report_category !== "activity_reporting"
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("el-alert", {
                            staticClass: "big _400",
                            attrs: {
                              description:
                                "Please be advised that these reports will be retired on Sep 15th. Please use the new activity reports instead.",
                              effect: "dark",
                              "show-icon": "",
                              title: "Note:",
                              type: "error",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 text-center" },
                    [
                      !_vm.isNotReportingWithSubCategories() &&
                      !_vm.isEmptyReports(_vm.report_category)
                        ? _c(
                            "el-radio-group",
                            {
                              staticClass: "col-12 no-select pull-left mt-1",
                              attrs: { size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.changeSubCategory(false)
                                },
                              },
                              model: {
                                value: _vm.sub_category,
                                callback: function ($$v) {
                                  _vm.sub_category = $$v
                                },
                                expression: "sub_category",
                              },
                            },
                            _vm._l(
                              _vm.reports[_vm.report_category].data,
                              function (data, index) {
                                return _vm.auth.user.profile.reports_settings[
                                  _vm.report_category
                                ].includes(index) && data.active
                                  ? _c("el-radio-button", {
                                      key: data.label + "-" + index,
                                      attrs: {
                                        label: _vm._f("ucwords")(
                                          data.label.replace("-", " ")
                                        ),
                                      },
                                    })
                                  : _vm._e()
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.sub_category == "Users"
                  ? _c("div", { staticClass: "row ring-group-banner-msg" }, [
                      _vm._m(1, true),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(report.data, function (report_type, index) {
                  return _c(
                    "div",
                    [
                      _vm.report_category == category &&
                      !_vm.auth.user.profile.lead_gen_tools_enabled &&
                      category == "performance" &&
                      [1, 2].includes(index) &&
                      _vm.auth.user.profile.reports_settings[category].includes(
                        index
                      ) &&
                      _vm.reports[category].queue[index].started
                        ? void 0
                        : _vm.report_category == category &&
                          _vm.auth.user.profile.reports_settings[
                            category
                          ].includes(index) &&
                          _vm.reports[category].queue[index].started
                        ? _c(
                            "div",
                            {
                              staticClass: "row mt-3 mb-3",
                              class: {
                                box:
                                  _vm.report_category != "activity_reporting" ||
                                  _vm.sub_category != "Contacts",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "col-12",
                                  class: {
                                    "col-md-8":
                                      _vm.isNotReportingWithSubCategories(),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "box-body":
                                          _vm.report_category !=
                                            "activity_reporting" &&
                                          _vm.sub_category != "Contacts",
                                      },
                                    },
                                    [
                                      _c(report_type.component, {
                                        key: _vm.report_key,
                                        tag: "component",
                                        attrs: {
                                          base: !_vm.isNotReportingWithSubCategories()
                                            ? _vm.sub_category
                                            : report_type.base,
                                          report_index: index,
                                          series_name: report_type.series_name,
                                          stacked: report_type.stacked,
                                        },
                                        on: {
                                          reportFiltersChanged:
                                            _vm.reportFiltersChanged,
                                          reportLoaded: _vm.queueReports,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isNotReportingWithSubCategories()
                                ? _c("reports-descriptions", {
                                    attrs: {
                                      category: category,
                                      report_type_index: index,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "csat-calls-panel",
                    class: { "csat-panel-enter": _vm.csat_calls_panel.enabled },
                  },
                  [
                    _c("h3", [
                      _vm._v(
                        "CSAT Score " + _vm._s(_vm.csat_calls_panel.score)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "fa fa-close pull-left",
                      on: { click: _vm.closeCsatCallsPanel },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "b-a p-2 rounded mb-2" }, [
                      _c("table", { staticClass: "table w-100" }, [
                        _vm._m(2, true),
                        _vm._v(" "),
                        !_vm.csat_calls_panel.loading
                          ? _c(
                              "tbody",
                              _vm._l(
                                _vm.csat_calls_panel.calls.data,
                                function (call) {
                                  return _c("tr", { key: call.id }, [
                                    _c("td", { staticClass: "link" }, [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "/communication/" + call.id,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              call.campaign
                                                ? call.campaign.name
                                                : "-"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("span", { staticClass: "talk-time" }, [
                                        _vm._v(
                                          "Talk time: " +
                                            _vm._s(call.talk_time_formatted)
                                        ),
                                      ]),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            )
                          : _c("tbody", [_vm._m(3, true)]),
                        _vm._v(" "),
                        _c("tfoot", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "1" },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "success",
                                      size: "small",
                                      disabled:
                                        !_vm.csat_calls_panel.calls
                                          .prev_page_url ||
                                        _vm.csat_calls_panel.loading,
                                      icon: "el-icon-arrow-left",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCsatCallsPanelCalls(
                                          _vm.csat_calls_panel.calls
                                            .prev_page_url
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Prev Page\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "1" },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "success",
                                      size: "small",
                                      disabled:
                                        !_vm.csat_calls_panel.calls
                                          .next_page_url ||
                                        _vm.csat_calls_panel.loading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCsatCallsPanelCalls(
                                          _vm.csat_calls_panel.calls
                                            .next_page_url
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Next Page\n                                    "
                                    ),
                                    _c("i", {
                                      staticClass: "el-icon-arrow-right",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              2
            )
          : _vm._e()
      }),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
      _vm._v(" "),
      _vm.isTrial && !_vm.isSimpSocial
        ? _c("video-modal", {
            staticClass: "pl-2",
            attrs: {
              title: "📊 Mastering Aloware Reporting: Data-Driven Insights",
              "cookie-name": "reports",
              notes:
                "🔥 Power through data with the <strong>Reporting Feature!</strong> </br></br> 📊 Dive deep into metrics, uncover hidden patterns, and strategize with precision. </br></br> Elevate your data narrative and shine in the analytics arena now! 💥📈",
              "video-url":
                "https://www.youtube.com/embed/l0d2odTjHWU?si=alANQbZXsIVe4XMV",
              "learn-more-link":
                "https://support.aloware.com/en/articles/9033817-generating-reports-in-aloware",
              "has-activator-button": true,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-50 d-inline-block" }, [
      _c("span", { staticClass: "filter-header text-muted w-50" }, [
        _vm._v(
          "\n                                                    My Reports\n                                                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-center col-12 col-md-8 offset-md-2",
      },
      [
        _c("div", { staticClass: "tags-deprecation-banner py-2 px-3" }, [
          _c("div", { staticClass: "left-content" }, [
            _c("p", { staticClass: "trial--text" }, [
              _vm._v("\n                            We are soon replacing "),
              _c("strong", [_vm._v("Ring Group")]),
              _vm._v(" reporting with "),
              _c("strong", [_vm._v("Team")]),
              _vm._v(
                " reporting. Consider using a Team filter for your user report.\n                        "
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Call")]),
        _vm._v(" "),
        _c("th", [_vm._v("Talk Time")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "text-center" }, [
      _c("td", { attrs: { colspan: "2" } }, [
        _c(
          "i",
          {
            staticClass: "material-icons loader mt-5",
            staticStyle: { "font-size": "42px" },
          },
          [_vm._v("")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }