var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.ready,
          expression: "!ready",
        },
      ],
      staticClass: "waveform-container",
    },
    [
      _c(
        "button",
        {
          staticClass: "play-button",
          attrs: {
            disabled: !_vm.ready,
            "data-testid": "waveform-play-button",
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.handlePlay.apply(null, arguments)
            },
          },
        },
        [
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.playing,
                expression: "playing",
              },
            ],
            staticClass: "fa fa-pause",
          }),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.playing,
                expression: "!playing",
              },
            ],
            staticClass: "fa fa-play",
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "waveform" } }),
      _vm._v(" "),
      _c("div", { staticClass: "waveform-timeline" }, [
        _c("span", { staticClass: "text-xxs" }, [
          _vm._v(
            _vm._s(_vm._f("fixDuration")(_vm.current_time)) +
              " / " +
              _vm._s(_vm._f("fixDuration")(_vm.duration))
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("vue-wave-surfer", {
        ref: "surf",
        attrs: {
          "data-testid": "waveform-surfer",
          src: _vm.remote_url,
          options: _vm.options,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }