export const COMMUNICATION = 'communication'
export const CONTACTS = 'contacts'
export const BROADCASTS_ACTIVITY_REPORTING = 'broadcasts_activity_reporting'
export const CONTACTS_ACTIVITY_REPORTING_LINES = 'contacts_activity_reporting_lines'
export const CONTACTS_ACTIVITY_REPORTING_AGENTS = 'contacts_activity_reporting_agents'
export const CONTACTS_ACTIVITY_REPORTING_NEW_CONTACTS_VS_DNC = 'contacts_activity_reporting_new_contacts_vs_dnc';
export const LINES_ACTIVITY_REPORTING = 'lines_activity_reporting'
export const RING_GROUPS_ACTIVITY_REPORTING = 'ring_groups_activity_reporting'
export const SEQUENCES_ACTIVITY_REPORTING = 'sequences_activity_reporting'
export const USERS_ACTIVITY_REPORTING = 'users_activity_reporting'
