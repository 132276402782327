var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row nav-padding" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("div", { staticClass: "d-flex mb-3" }, [
            _c("h4", { staticClass: "mb-0" }, [_vm._v("Calendar Services")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-auto" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "success", size: "medium", round: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$refs.calendar_service_modal.add()
                      },
                    },
                  },
                  [_c("strong", [_vm._v("Add New")])]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { data: _vm.services, stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", sortable: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "text-greyish" }, [
                          _vm._v(_vm._s(scope.row.id)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Name", prop: "name", sortable: "" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Duration",
                  prop: "duration",
                  sortable: "",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Description", prop: "description" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Number of users",
                  prop: "users_count",
                  sortable: "",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { padding: "0", width: "80px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-dropdown",
                          {
                            attrs: { trigger: "click" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "dropdown",
                                  fn: function () {
                                    return [
                                      _c(
                                        "el-dropdown-menu",
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.editAttributeDictionary(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-edit",
                                              }),
                                              _vm._v(
                                                " Edit\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: { divided: "" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.deleteAttributeDictionary(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-trash-o",
                                              }),
                                              _vm._v(
                                                " Delete\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("el-button", { attrs: { size: "small" } }, [
                              _c("i", { staticClass: "fa fa-ellipsis-h" }),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("calendar-services-modal", {
        ref: "calendar_service_modal",
        on: { callback: _vm.getCalendarServices },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }