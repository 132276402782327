<template>
    <iframe title="email"
            v-if="!loading_url"
            :src="url">
    </iframe>
</template>

<script>
import _ from 'lodash'

export default {
    props: {
        contact_id: Number
    },

    data() {
        return {
            url: '',
            loading_url: true
        }
    },

    created() {
        axios.get('/integrations/simpsocial/email-source').then(res => {
            this.url = this.getUrl(_.get(res.data, 'source', ''))
            this.loading_url = false
        })
    },

    methods: {
        getUrl(base_url) {
            if (base_url === '') {
                return base_url
            }

            let url = new URL(base_url)

            if (this.contact_id) {
                url.searchParams.append('contact_id', this.contact_id)
            }

            return url.href
        }
    }
}
</script>
