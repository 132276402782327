var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container-account-imports app-body",
      attrs: { id: "view" },
    },
    [
      _c("div", { staticClass: "container-listing min-height-screen" }, [
        _c("div", { staticClass: "container-header" }, [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("h3", { staticClass: "listing-heading" }, [_vm._v("Lists")]),
              _vm._v(" "),
              !_vm.enabledToImportContacts()
                ? _c(
                    "el-popover",
                    {
                      ref: "import-contacts-popover",
                      staticClass: "custom-popover",
                      attrs: {
                        placement: "top",
                        width: "200",
                        "popper-class": "btn-primary",
                        trigger: "hover",
                      },
                    },
                    [
                      _c("custom-message-display", {
                        attrs: { config: _vm.customMessage("contacts.import") },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-dropdown",
                {
                  directives: [
                    {
                      name: "popover",
                      rawName: "v-popover:import-contacts-popover",
                      arg: "import-contacts-popover",
                    },
                  ],
                  ref: "importSelection",
                  staticClass: "ml-auto",
                  attrs: {
                    size: "medium",
                    trigger: "click",
                    disabled: !_vm.enabledToImportContacts(),
                  },
                  on: { command: _vm.importContact },
                  scopedSlots: _vm._u([
                    {
                      key: "dropdown",
                      fn: function () {
                        return [
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "account-import-selection-container",
                            },
                            [
                              _c(
                                "el-dropdown-item",
                                {
                                  staticClass: "d-flex align-items-center",
                                  attrs: { command: "import_from_hubspot" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "icon-container hubspot-icon-container",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fab fa-hubspot item-icon",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v("Import from HubSpot"),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                {
                                  staticClass: "d-flex align-items-center",
                                  attrs: { command: "import_from_csv" },
                                },
                                [
                                  _c("img", {
                                    staticClass: "csv-icon",
                                    attrs: {
                                      src: "/assets/images/icons/csv-svg-icon-12.svg",
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v("Import CSV"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "import-selection-button",
                      attrs: { type: "primary" },
                    },
                    [
                      _vm._v("\n                        Create List "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right ml-2",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isTrial && !_vm.isSimpSocial
                ? _c("video-modal", {
                    staticClass: "pl-2",
                    attrs: {
                      title: "🚀 Mastering List Management in Aloware",
                      "cookie-name": "lists",
                      notes:
                        "🔥 Unleash the power of <strong>Aloware Lists!</strong> </br></br> 📊 Organize, categorize, and skyrocket your contact management game. </br></br> Dive in and watch your efficiency soar! 💥📈",
                      "video-url":
                        "https://www.youtube.com/embed/aSdCAyV0bW4?si=EGKB-l66moF9q9HZ",
                      "learn-more-link":
                        "https://support.aloware.com/en/articles/9031374-user-s-guide-simplifying-contact-imports-for-admins-and-agents",
                      "activator-size": "small",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "mb-1 text-grey-8 _600" }, [
            _vm._v(
              "\n                Create public lists that agents can access in " +
                _vm._s(_vm.applicationName) +
                " Talk.\n                "
            ),
            !_vm.isTrial && !_vm.isSimpSocial
              ? _c(
                  "a",
                  {
                    staticClass: "text-blue _600",
                    attrs: {
                      href: "https://support.aloware.com/en/articles/9034179-creating-contact-lists-for-users-in-aloware-talk",
                      target: "_blank",
                    },
                  },
                  [_vm._v("\n                    Learn more\n                ")]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container-body" }, [
          _c(
            "div",
            { staticClass: "container-listing-tools d-flex flex-wrap mb-1" },
            [
              _c("div", { staticClass: "d-flex flex-wrap mr-auto" }, [
                _c(
                  "div",
                  { staticClass: "d-flex mr-2 search-toolbar" },
                  [
                    _c(
                      "el-input",
                      {
                        ref: "contactListSearch",
                        attrs: {
                          id: "search-input",
                          placeholder: "Search contact lists...",
                          clearable: "",
                        },
                        on: { change: _vm.searchContactLists },
                        nativeOn: {
                          keyup: function ($event) {
                            $event.preventDefault()
                            return _vm.searchContactLists.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.filters.search,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "search", $$v)
                          },
                          expression: "filters.search",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-input__icon el-icon-search",
                          attrs: { slot: "prefix" },
                          slot: "prefix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex mt-2 mt-xl-0 flex-wrap flex-lg-nowrap" },
                [
                  _c("div", { staticClass: "mr-2 align-self-center" }, [
                    _c("span", { staticClass: "text-grey" }, [
                      _vm._v("Total Contacts List:"),
                    ]),
                    _vm._v(" "),
                    _c("strong", [_vm._v(_vm._s(_vm.pagination.total))]),
                  ]),
                  _vm._v(" "),
                  _c("el-pagination", {
                    staticClass: "align-self-center",
                    attrs: {
                      layout: "prev, pager, next",
                      "current-page": _vm.filters.page,
                      "page-size": _vm.filters.size,
                      total: _vm.pagination.total,
                      "pager-count": 5,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.filters, "page", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.filters, "page", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.filters, "size", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.filters, "size", $event)
                      },
                      "size-change": function ($event) {
                        return _vm.fetchContactList(1)
                      },
                      "current-change": _vm.fetchContactList,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "table-responsive",
            },
            [
              _vm.pagination.data.length > 0
                ? _c("table", { staticClass: "table" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.pagination.data, function (contact_list) {
                        return _c("tr", [
                          _c(
                            "td",
                            [
                              contact_list.show_in_public_folder
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "p-0 text-blue",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openAdminList(contact_list)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons text-blue",
                                        },
                                        [_vm._v("launch")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(contact_list.name) +
                                          "\n                                "
                                      ),
                                    ]
                                  )
                                : _c("span", { staticClass: "_400" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(contact_list.name) +
                                        "\n                                "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("fixDateTime")(contact_list.created_at)
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("numFormat")(
                                    contact_list?.no_of_contacts ?? 0
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  contact_list.type ===
                                    _vm.ContactListTypes.STATIC_LIST
                                    ? "Static"
                                    : "Dynamic"
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("el-switch", {
                                attrs: { "active-color": "#13ce66" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleShowInPublicFolder(
                                      contact_list
                                    )
                                  },
                                },
                                model: {
                                  value: contact_list.show_in_public_folder,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      contact_list,
                                      "show_in_public_folder",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "contact_list.show_in_public_folder",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("ucwords")(contact_list.source_name)
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "span",
                              {
                                class: `import-status-${
                                  contact_list.import_status + 1
                                }`,
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm._f("ucwords")(
                                        contact_list.import_status_name
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("fixDateTime")(
                                    contact_list.imported_at
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "el-dropdown",
                                {
                                  attrs: { trigger: "click" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "dropdown",
                                        fn: function () {
                                          return [
                                            _c(
                                              "el-dropdown-menu",
                                              [
                                                contact_list.source === 3
                                                  ? _c(
                                                      "el-dropdown-item",
                                                      {
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showPullHubspotListLogModal(
                                                              contact_list
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-info-circle",
                                                        }),
                                                        _vm._v(
                                                          " View Logs\n                                            "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.shouldShowSplitOption(
                                                  contact_list
                                                )
                                                  ? _c(
                                                      "el-dropdown-item",
                                                      {
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showSplitListForm(
                                                              contact_list
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-clone",
                                                        }),
                                                        _vm._v(
                                                          " Split\n                                            "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.showAssignContactForm(
                                                          contact_list
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-sign-in-alt",
                                                    }),
                                                    _vm._v(
                                                      " Assign Contacts\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.stageToEditContactList(
                                                          contact_list
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fas fa-pen",
                                                    }),
                                                    _vm._v(
                                                      " Rename\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    staticClass: "text-danger",
                                                    attrs: { divided: "" },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.deleteContactList(
                                                          contact_list
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-trash-o",
                                                    }),
                                                    _vm._v(
                                                      " Delete\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "px-3 py-2",
                                      attrs: { size: "small" },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-ellipsis-h",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.pagination.data.length <= 0
                ? _c("div", { staticClass: "text-center mx-auto w-50 py-5" }, [
                    _c(
                      "div",
                      { staticClass: "w-75 mx-auto" },
                      [_c("img-empty")],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "lead" }, [
                      _vm._v(
                        "\n                        We can't seem to find contact list\n                        "
                      ),
                      _vm.filters.search.length > 0
                        ? _c("span", [
                            _vm._v(
                              'that matches "' +
                                _vm._s(_vm.filters.search) +
                                '"'
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container-footer d-flex mb-5" }, [
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c("el-pagination", {
                attrs: {
                  layout: "sizes, prev, pager, next",
                  "current-page": _vm.filters.page,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.filters.size,
                  "hide-on-single-page": true,
                  total: _vm.pagination.total,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.filters, "page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.filters, "page", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.filters, "size", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.filters, "size", $event)
                  },
                  "size-change": function ($event) {
                    return _vm.fetchContactList(1)
                  },
                  "current-change": _vm.fetchContactList,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "35vw",
            top: "10vh",
            "append-to-body": "",
            title: "Rename Contact List",
            "before-close": _vm.closeDialog,
            visible: _vm.show_dialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_dialog = $event
            },
          },
        },
        [
          _vm.selected_model
            ? _c(
                "el-form",
                { ref: "rename_form", attrs: { model: _vm.selected_model } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Name",
                        prop: "name",
                        error: _vm.errors.first("name"),
                        rules: {
                          required: true,
                          message: "Please specify the name.",
                          trigger: ["blur", "change"],
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          placeholder: "Static List One...",
                        },
                        model: {
                          value: _vm.selected_model.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.selected_model, "name", $$v)
                          },
                          expression: "selected_model.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "flex-grow-1",
                  attrs: { size: "small" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("\n                Cancel\n            ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.isNameChanged,
                    size: "small",
                    type: "success",
                  },
                  on: { click: _vm.updateContactListName },
                },
                [_vm._v("\n                Save\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("import-wizard", {
        ref: "csv_import",
        attrs: { is_contact_list: true },
        on: { importStarted: _vm.handleImportStarted },
      }),
      _vm._v(" "),
      _c("admin-list-import-from-hubspot-list", {
        ref: "import_from_hubspot_list",
        attrs: { contact_list: _vm.selected_model },
        on: { importStarted: _vm.handleImportStarted },
      }),
      _vm._v(" "),
      _c("admin-list-assign-contact-form", {
        ref: "assign_contact_form",
        attrs: { contact_list: _vm.selected_model },
        on: { closed: _vm.closeAssignmentForm },
      }),
      _vm._v(" "),
      _c("admin-split-list-form", {
        ref: "split_list_form",
        attrs: { contact_list: _vm.selected_model },
        on: { closed: _vm.closeSplitListForm, hidden: _vm.hideSplitListForm },
      }),
      _vm._v(" "),
      _c("pull-hubspot-list-logs", {
        ref: "pull_hubspot_list_logs",
        attrs: { contactList: _vm.selectedContactList },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Date Created")]),
        _vm._v(" "),
        _c("th", [_vm._v("# of Contacts")]),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Show in Public Folder")]),
        _vm._v(" "),
        _c("th", [_vm._v("Source")]),
        _vm._v(" "),
        _c("th", [_vm._v("Import Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Imported At")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }