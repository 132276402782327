var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      staticClass: "p-0",
      attrs: {
        width: "400",
        trigger: "click",
        "popper-class": "variable-popover",
        placement: _vm.popoverPlacement,
      },
      on: { show: _vm.popoverShow, hide: _vm.popoverHide },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("contact-lists-selector", {
              attrs: {
                user_id: _vm.current_user_id,
                module_type: _vm.ContactListModuleTypes.PUBLIC_CONTACT_LIST,
                multiple: true,
                value: _vm.selected_lists,
                list_type: _vm.ContactListTypes.STATIC_LIST,
              },
              on: { change: _vm.emitSelectedLists },
              model: {
                value: _vm.selected_lists,
                callback: function ($$v) {
                  _vm.selected_lists = $$v
                },
                expression: "selected_lists",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          class: _vm.variableBtnClass,
          attrs: {
            slot: "reference",
            id: "tags-popover-trigger",
            size: "mini",
            circle: "",
            disabled: _vm.disabled,
          },
          slot: "reference",
        },
        [_c("i", { class: "aloai-fa-btn-custom " + _vm.icon })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }