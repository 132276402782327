var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.app_loading
    ? _c(
        "div",
        { staticClass: "trial-banner d-flex align-items-center" },
        [
          !_vm.hasActivatorButton
            ? _c("video-modal-activator", {
                attrs: { size: _vm.activatorSize },
                on: { "open-modal": _vm.openModal },
              })
            : _vm._t("activator"),
          _vm._v(" "),
          _vm.showModal
            ? _c("div", { staticClass: "video-modal" }, [
                _c("div", {
                  staticClass: "overlay",
                  on: { click: _vm.closeModal },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _vm.title
                      ? _c("h3", [_vm._v(_vm._s(_vm.title))])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "close-button",
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v("✖")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _c("iframe", {
                      attrs: {
                        src: _vm.videoUrl,
                        frameborder: "0",
                        allowfullscreen: "",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _vm.notes
                    ? _c("div", { staticClass: "modal-notes" }, [
                        _c("p", { domProps: { innerHTML: _vm._s(_vm.notes) } }),
                        _vm._v(" "),
                        _vm.learnMoreLink
                          ? _c("div", { staticClass: "modal-link" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.learnMoreLink,
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        Learn more\n                    "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }