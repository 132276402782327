<template>
    <el-upload drag
               :action="action"
               :headers="headers"
               :on-success="onSuccessUpload"
               :on-error="onFailedUpload"
               :on-progress="progressUpload"
               :before-upload="beforeUpload"
               :file-list="uploadFiles"
               :on-change="onChangeFileList"
               :on-remove="onChangeFileList"
               :before-remove="beforeRemove"
               :disabled="disabled"
               multiple>
        <div :class="[ disabled ? 'is-disabled' : '' ]">
            <template v-if="disabled">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    Upload is disabled at this stage
                </div>
            </template>
            <template v-else>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    Drop file here or <em>click to upload</em>
                </div>
            </template>
        </div>
    </el-upload>
</template>

<script>
export default {
    name: "multi-upload",

    props: {
        action: {
            required: true,
            type: String
        },

        deleteAction: {
            required: true,
            type: String
        },

        files: {
            required: false,
            type: Array,
            default: []
        },

        disabled: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('api_token'),
                'X-Socket-Id': window.Echo.socketId()
            },
            uploadFiles: this.files,
            uploadPercentage: 0,
            uploadFileList: [],
            uploadStatus: 'success',
        }
    },

    methods: {
        onChangeFileList(file, fileList) {
            this.uploadFileList = fileList
            this.beforeUpload()
            this.$emit('change', this.uploadFileList.map((item) => {
                if (!item.response) {
                    return {}
                }

                return {
                    file_id: item.response.file.file_id,
                    name: item.response.file.name,
                    url: item.response.file.url,
                    uuid: item.response.file.uuid
                }
            }))
        },

        onSuccessUpload() {
            this.$notify({
                offset: 95,
                title: 'File Upload',
                message: 'File has been uploaded successfully.',
                type: 'success',
                showClose: true,
            })
            this.uploadStatus = 'success'
        },

        onFailedUpload(err) {
            this.$root.handleUploadErrors(err.message)
            this.uploadStatus = 'exception'
            this.uploadPercentage = 0
        },

        beforeUpload() {
            this.uploadStatus = 'success'
            this.uploadPercentage = 0
        },

        progressUpload(event) {
            this.uploadPercentage = parseInt(event.percent)
        },

        deleteFile(uuid) {
            return axios.delete(this.deleteAction, {
                data: {
                    uuid: uuid,
                }
            }).then((res) => {
                this.$notify({
                    offset: 95,
                    title: 'File Delete',
                    message: res.data.message,
                    type: 'success',
                    showClose: true,
                })
            })
        },

        beforeRemove(file) {
            let file_uuid = file.uuid || file.response.file.uuid
            let file_name = file.name || file_uuid
            return this.$confirm(`Remove ${file_name} ?`).then(() => {
                return this.deleteFile(file_uuid)
            })
        },
    },

    watch: {
        files() {
            this.uploadFiles = this.files
        }
    }
}
</script>
