<template>
    <div>
        <div class="row b-b">
            <div class="col-12 p-3">
                <company-form-token></company-form-token>
            </div>
        </div>
        <div class="row"
             v-if="!statics.whitelabel">
            <div class="col-12 p-3">
                <a href="https://support.aloware.com/en/articles/9020058-aloware-lead-api-documentation"
                   title="help"
                   class="help"
                   target="_blank">
                    <el-button type="text"
                               class="p-0">
                        <i class="material-icons text-lg mr-1">help</i>
                        <span class="text-md">Read our tutorial</span>
                    </el-button>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-3">
                <div class="mb-2">
                    <strong class="integration-api-titles">Create a new contact or Update an existing contact (Lead API / Form Capture API)</strong>
                </div>

                <p class="text-md">
                    <span class="text-dark-greenish _600">POST /api/v1/webhook/forms</span>
                </p>

                <p>
                    <strong>Required Fields:</strong>
                    <br>
                    <span class="text-dark-greenish _600">api_token</span>,
                    <span class="text-dark-greenish _600">phone_number</span>
                </p>

                <p>
                    <strong>Example POST URL:</strong>
                    <br>
                    <span class="text-dark-greenish _600">{{ statics.domain | fixDomain }}/api/v1/webhook/forms</span>
                </p>

                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                                            The code sample below represents some example JSON with
                                            standard fields to pass in the body of your request in
                                            order to create a new contact:
                                        </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_la_a'"><code class="json">{
    <span class="text-dark-greenish _600">// Authentication Token</span>
    "api_token": "{{ companyToken }}", // Required
    <span class="text-dark-greenish _600">// Lead Properties</span>
    "phone_number": "8181234567", // Required
    "other_phone_numbers": [{"label":"mobile", "phone_number": "8181234567"}, ...], // Optional | Array of objects/arrays that includes the following keys; phone_number (required), label (optional)
    "company_name": "{{ statics.name }}", // Optional
    "name": "John Doe", // Optional
    "first_name": "John", // Optional
    "last_name": "Doe", // Optional
    "lead_source": "Google Ads", // Optional
    "email": "john.doe@gmail.com", // Optional
    "date_of_birth": "MM/DD/YYYY", // Optional
    "timezone": "America/Los_Angeles", // Optional
    "city": "Los Angeles", // Optional
    "state": "CA", // Optional
    "zipcode": "90045", // Optional
    "country": "US", // Optional
    "address": "1234 First Street", // Optional
    "website": "https://www.aloware.com", // Optional
    "notes": "This is a test note", // Optional
    "csf1": "Custom data", // Optional | (Custom Field 1)
    "csf2": "Custom data", // Optional | (Custom Field 2)
    <span class="text-dark-greenish _600">// Ownership. Please use at once only one field user_id or user_email.</span>
    "user_id": "1234", // Optional | Set an owner for this lead. If left blank, the contact will not be owned by any user.
    "user_email": "user@example.com", // Optional | Set an owner for this lead by his email. If left blank, the contact will not be owned by any user.
    <span class="text-dark-greenish _600">// Distribution</span>
    "distribute_to_ring_group": true, // Optional | Should we distribute the contact to a ring group? If true, we will randomly assign it to an agent/user within a ring group and override `user_id` in ownership.
    "ring_group_id": "5", // Optional | Will be checked if `distribute_to_ring_group` is TRUE.
    "check_available_users": true, // Optional | Will be checked if `distribute_to_ring_group` is TRUE. | Should we check if user is eligible or active? if value is true and no active user found, contact will not be distributed within a ring group.
    "check_available_users_with_fallback": true, // Optional | Will be checked if `distribute_to_ring_group` is TRUE. | Should we check if user is eligible or active? if value is true and no active user found, contact will be distributed within the ring group. Active users will be prioritized using this property.
    <span class="text-dark-greenish _600">// PowerDialer </span>
    "add_to_powerdialer": true, // Optional | Should we add the contact to the PowerDialer of agent/user where it is distributed? If the lead is already in someone’s PowerDialer, it will be skipped.
    "powerdialer_position": "top", // Optional | Options: top, bottom | Default: top | Will be checked if `add_to_powerdialer` is TRUE. | Combined with the option above, should this lead go to the top of the PowerDialer or bottom?
    <span class="text-dark-greenish _600">// Sequences</span>
    "sequence_id": "4567", // Optional | Sequences
    "force_update_sequence": false, // Optional | Should we disenroll this lead from other sequences, and push them to a new one?
    <span class="text-dark-greenish _600">// Action Flags</span>
    "force_update": true, // Optional | Should we override existing data and update the contact information?
    <span class="text-dark-greenish _600">// Relationship Fields</span>
    "line_id": "1234", // Optional | Line
    "tag_id": "1234", // Optional | Tag
    "disposition_status_id": "1234", // Optional | Disposition Status
}</code></pre>
                <p class="mb-2">
                    <strong>Example POST header:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "Accept": "application/json",
    "Content-Type": "application/json"
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 201 response on contact creation, Example
                        success
                        response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "Contact created."
}</code></pre>
                <p class="mb-2">
                    <strong>Returns an HTTP 200 response on contact update if force_update
                        is true, Example success
                        response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "Contact updated."
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 400 on failure with a detailed response, Example
                        failure
                        response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "The given data was invalid.",
    "errors": {
        "phone_number": [
            "The phone number has already been taken."
        ]
    }
}</code></pre>

                <p class="mb-2">
                    <strong>cURL Example:</strong>
                </p>
                <pre class="mb-3"
                     v-highlightjs
                     :key="renderRefreshToken + '_la_b'"
                     v-if="!loading_whitelabel"><code class="bash">curl --location --request POST '{{ statics.domain | fixDomain }}/api/v1/webhook/forms' \
--header 'Content-Type: application/json' \
--header 'Accept: application/json' \
--data-raw '{
    "api_token": "{{ companyToken }}",
    "phone_number": "8181234567",
    "company_name": "Aloware",
    "name": "John Doe",
    "first_name": "John",
    "last_name": "Doe",
    "lead_source": "Google Ads",
    "email": "john.doe@gmail.com",
    "date_of_birth": "MM/DD/YYYY",
    "timezone": "America/Los_Angeles",
    "city": "Los Angeles",
    "state": "CA",
    "zipcode": "90045",
    "country": "US",
    "address": "1234 First Street",
    "website": "https://www.aloware.com",
    "notes": "This is a test note",
    "csf1": "Custom data",
    "csf2": "Custom data",
    "force_update": true,
    "force_update_sequence": false,
    "distribute_to_ring_group": true,
    "ring_group_id": "1234",
    "check_available_users": true,
    "add_to_powerdialer": true,
    "powerdialer_position": "bottom",
    "line_id": "1234",
    "sequence_id": "4567",
    "user_id": "1234",
    "tag_id": "1234",
    "disposition_status_id": "1234"
}'</code></pre>

                <p>
                    If you are looking for a <span class="_600">Postman</span> example please check this link:
                    <br>
                    <a class="el-button--text"
                       :href="statics.domain + '/static-data/lead-api.postman_collection.json'"
                       target="_blank">
                        {{ statics.domain }}/static-data/lead-api.postman_collection.json
                    </a>
                </p>

                <p class="_600"
                   v-if="!statics.whitelabel">
                    If you need more API functions, please contact our support at support@aloware.com.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {company_token_mixin} from '../mixins'
import CompanyFormToken from './account/company-form-token'

export default {
    mixins: [
        company_token_mixin
    ],
    components: {
        CompanyFormToken
    },

    data() {
        return {
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
        }
    },

    created() {
        this.getStatics()
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        }
    }
}
</script>
