import store from './store'
import auth from './auth'
import * as Roles from './constants/roles'
import _ from "lodash"
import LaravelEcho from 'laravel-echo'

function hasRole(roles) {
    // if user is logged out of the system when session expires
    if (!auth.user.profile.user_roles) {
        return false
    }

    if (Array.isArray(roles)) {
        for (let role of roles) {
            if (!auth.user.profile.user_roles.find(o => o == role)) {
                return false
            }
        }
        return true
    } else {
        return auth.user.profile.user_roles.find(o => o == roles)
    }
}

export default {
    initEcho(broadcastDriver) {
        console.log("Connecting to ws driver: " + broadcastDriver)
        try {
            const wsDriversConfig = JSON.parse(localStorage.getItem('ws_drivers'));
            return new LaravelEcho({
                broadcaster: 'pusher',
                key: wsDriversConfig[broadcastDriver].key,
                cluster: wsDriversConfig[broadcastDriver].cluster || '',
                wsHost: wsDriversConfig[broadcastDriver].ws_host,
                wssHost: wsDriversConfig[broadcastDriver].ws_host,
                forceTLS: true,
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('api_token'),
                        'Requested-From': 'api',
                        'driver': broadcastDriver,
                    },
                },
                enabledTransports: ['ws', 'wss'],
                disableStats: true,
            })
        } catch (e) {
            console.error("Error to connect to ws driver", e)
        }
    },
    registerEchoFallback(broadcastDriver, user) {
        // unbind the error event to avoid multiple event listeners
        window.Echo.connector.pusher.connection.unbind('error')
        window.Echo.connector.pusher.connection.bind('error', (err) => {
            console.error("Error to connect to ws driver", err)
            if (window.fallbackDriver) {
                console.log("Fallback driver already started", window.fallbackDriver)
                return;
            }
            // Define the fallback driver, only pusher and soketi exists today
            window.fallbackDriver = broadcastDriver === 'pusher' ? 'soketi' : 'pusher'
            console.log('Error to connect to: ' + broadcastDriver, 'Connecting to fallback driver: ' + window.fallbackDriver, err)

            // Try to connect with fallback driver
            window.Echo = this.initEcho(window.fallbackDriver)
            this.listen(user)
        })
    },
    listen(user) {
        Echo.private('user-' + user.profile.id)
            .listen('.user.status.updated', (event) => {
                store.commit('SET_USER_STATUS', event.status)
            })
            .listen('.user.in-app.contact.contact_assigned', (event) => {
                let contact = event.contact
                if (event.tags) {
                    contact.tags = event.tags
                    contact.tag_ids = contact.tags.map((a) => a.id)
                }
                if (event.user) {
                    contact.user = event.user
                }
            })
            .listen('.user.in-app.custom_message', (event) => {
                let user = event.user
                let message = event.message
                VueEvent.fire('new_in_app_custom_message', { user, message })
            })
            .listen('.user.in-app.campaign.new.caller.id', (event) => {
                const validation = event.validation
                VueEvent.fire('in_app_new_caller_id_return', { validation })
            })
            .listen('.user.in-app.communication.new_call', (event) => {
                let campaign = store.state.campaigns.find(campaign => campaign.id === event.communication.campaign_id)
                if (campaign) {
                    let communication = event.communication
                    communication.campaign = campaign
                    if (event.tags) {
                        communication.tags = event.tags
                        communication.tag_ids = communication.tags.map((a) => a.id)
                    }
                    if (event.contact) {
                        communication.contact = event.contact
                    }
                    if (event.contact_tags && communication && communication.contact) {
                        communication.contact.tags = event.contact_tags
                    }
                    if (event.owner) {
                        communication.owner = event.owner
                    }
                    if (event.is_call_waiting) {
                        communication.is_call_waiting = event.is_call_waiting
                    }
                    VueEvent.fire('new_in_app_call', communication)
                }
            })
            .listen('.user.in-app.communication.answered_call', (event) => {
                let campaign = store.state.campaigns.find(campaign => campaign.id === event.communication.campaign_id)
                if (campaign) {
                    let communication = event.communication
                    communication.campaign = campaign
                    if (event.tags) {
                        communication.tags = event.tags
                        communication.tag_ids = communication.tags.map((a) => a.id)
                    }
                    if (event.contact) {
                        communication.contact = event.contact
                    }
                    if (event.contact_tags && communication && communication.contact) {
                        communication.contact.tags = event.contact_tags
                    }
                    if (event.owner) {
                        communication.owner = event.owner
                    }
                    VueEvent.fire('answered_in_app_call', communication)
                }
            })
            .listen('.user.in-app.communication.new_sms', (event) => {
                let campaign = store.state.campaigns.find(campaign => campaign.id === event.communication.campaign_id)
                if (campaign) {
                    let communication = event.communication
                    communication.campaign = campaign
                    if (event.tags) {
                        communication.tags = event.tags
                        communication.tag_ids = communication.tags.map((a) => a.id)
                    }
                    if (event.contact) {
                        communication.contact = event.contact
                    }
                    if (event.contact_tags && communication && communication.contact) {
                        communication.contact.tags = event.contact_tags
                    }
                    if (event.owner) {
                        communication.owner = event.owner
                    }
                    VueEvent.fire('new_in_app_sms', communication)
                }
            })
            .listen('.user.in-app.communication.new_voicemail', (event) => {
                let campaign = store.state.campaigns.find(campaign => campaign.id === event.communication.campaign_id)
                if (campaign) {
                    let communication = event.communication
                    if (event.tags) {
                        communication.tags = event.tags
                        communication.tag_ids = event.tags.map((a) => a.id)
                    }
                    if (event.contact) {
                        communication.contact = event.contact
                    }
                    if (event.contact_tags && communication && communication.contact) {
                        communication.contact.tags = event.contact_tags
                    }
                    if (event.owner) {
                        communication.owner = event.owner
                    }
                    VueEvent.fire('new_in_app_voicemail', communication)
                }
            })
            .listen('.user.in-app.communication.new_fax', (event) => {
                let campaign = store.state.campaigns.find(campaign => campaign.id === event.communication.campaign_id)
                if (campaign) {
                    let communication = event.communication
                    communication.campaign = campaign
                    if (event.tags) {
                        communication.tags = event.tags
                        communication.tag_ids = communication.tags.map((a) => a.id)
                    }
                    if (event.contact) {
                        communication.contact = event.contact
                    }
                    if (event.contact_tags && communication && communication.contact) {
                        communication.contact.tags = event.contact_tags
                    }
                    if (event.owner) {
                        communication.owner = event.owner
                    }
                    VueEvent.fire('new_in_app_fax', communication)
                }
            })
            .listen('.user.in-app.compliance.registration', (compliance_model) => {
                VueEvent.fire('compliance_registration_updated', compliance_model)
            })
            .listen('.user.in-app.compliance.invitation', () => {
                VueEvent.fire('compliance_invitation')
            })
            .listen('.user.desktop.contact.contact_assigned', (event) => {
                let contact = event.contact
                if (event.tags) {
                    contact.tags = event.tags
                    contact.tag_ids = contact.tags.map((a) => a.id)
                }
                if (event.user) {
                    contact.user = event.user
                }
            })
            .listen('.user.desktop.custom_message', (event) => {
                let user = event.user
                let message = event.message
                VueEvent.fire('new_desktop_custom_message', { user, message })
            })
            .listen('.user.desktop.communication.new_call', (event) => {
                let campaign = store.state.campaigns.find(campaign => campaign.id === event.communication.campaign_id)
                if (campaign) {
                    let communication = event.communication
                    if (event.tags) {
                        communication.tags = event.tags
                        communication.tag_ids = event.tags.map((a) => a.id)
                    }
                    if (event.contact) {
                        communication.contact = event.contact
                    }
                    if (event.contact_tags && communication && communication.contact) {
                        communication.contact.tags = event.contact_tags
                    }
                    if (event.owner) {
                        communication.owner = event.owner
                    }
                    VueEvent.fire('new_desktop_call', communication)
                }
            })
            .listen('.user.desktop.communication.new_sms', (event) => {
                let campaign = store.state.campaigns.find(campaign => campaign.id === event.communication.campaign_id)
                if (campaign) {
                    let communication = event.communication
                    if (event.tags) {
                        communication.tags = event.tags
                        communication.tag_ids = event.tags.map((a) => a.id)
                    }
                    if (event.contact) {
                        communication.contact = event.contact
                    }
                    if (event.contact_tags && communication && communication.contact) {
                        communication.contact.tags = event.contact_tags
                    }
                    if (event.owner) {
                        communication.owner = event.owner
                    }
                    VueEvent.fire('new_desktop_sms', communication)
                }
            })
            .listen('.user.desktop.communication.new_voicemail', (event) => {
                let campaign = store.state.campaigns.find(campaign => campaign.id === event.communication.campaign_id)
                if (campaign) {
                    let communication = event.communication
                    if (event.tags) {
                        communication.tags = event.tags
                        communication.tag_ids = event.tags.map((a) => a.id)
                    }
                    if (event.contact) {
                        communication.contact = event.contact
                    }
                    if (event.contact_tags && communication && communication.contact) {
                        communication.contact.tags = event.contact_tags
                    }
                    if (event.owner) {
                        communication.owner = event.owner
                    }
                    VueEvent.fire('new_desktop_voicemail', communication)
                }
            })
            .listen('.user.desktop.communication.new_fax', (event) => {
                let campaign = store.state.campaigns.find(campaign => campaign.id === event.communication.campaign_id)
                if (campaign) {
                    let communication = event.communication
                    if (event.tags) {
                        communication.tags = event.tags
                        communication.tag_ids = event.tags.map((a) => a.id)
                    }
                    if (event.contact) {
                        communication.contact = event.contact
                    }
                    if (event.contact_tags && communication && communication.contact) {
                        communication.contact.tags = event.contact_tags
                    }
                    if (event.owner) {
                        communication.owner = event.owner
                    }
                    VueEvent.fire('new_desktop_fax', communication)
                }
            })
            .listen('.bulk_contacts.deleted', (event) => {
                VueEvent.fire('bulk_contacts_deleted', event)
            })
            .listen('.integration.pipedrive.app_extension.power_dialer_completed', event => {
                VueEvent.fire('integration_pipedrive_app_extension_power_dialer_completed', event)
            })
            .listen('.integration.pipedrive.app_extension.sequence_completed', event => {
                VueEvent.fire('integration_pipedrive_app_extension_sequence_completed', event)
            })
            .listen('.user.customer_profiles', ({ data }) => {
                VueEvent.fire('customer_profiles', data)
            })
            .listen('.user.messaging_services', ({ data }) => {
                VueEvent.fire('messaging_services', data)
            })
            .listen('.user.logout', event => {
                VueEvent.fire('user_logout', event)
            })
            .listen('.communication.created', (event) => {
                let communication = event.communication
                if (event.tags) {
                    communication.tags = event.tags
                    communication.tag_ids = event.tags.map((a) => a.id)
                }
                if (event.teams) {
                    communication.teams = event.teams
                }
                if (event.contact) {
                    communication.contact = event.contact
                }
                if (event.owner) {
                    communication.owner = event.owner
                }
                if (event.contact_tags && communication && communication.contact) {
                    communication.contact.tags = event.contact_tags
                }
                let campaign = store.state.campaigns.find(campaign => campaign.id === communication.campaign_id)
                if (campaign) {
                    communication.campaign = campaign
                }
                VueEvent.fire('new_communication', communication)
            })
            .listen('.communication.updated', (event) => {
                let communication = event.communication
                if (event.tags) {
                    communication.tags = event.tags
                    communication.tag_ids = event.tags.map((a) => a.id)
                }
                if (event.contact) {
                    communication.contact = event.contact
                }
                if (event.owner) {
                    communication.owner = event.owner
                }
                if (event.contact_tags && communication && communication.contact) {
                    communication.contact.tags = event.contact_tags
                }
                let campaign = store.state.campaigns.find(campaign => campaign.id === communication.campaign_id)
                if (campaign) {
                    communication.campaign = campaign
                }
                VueEvent.fire('update_communication', communication)
            })
            .notification((notification) => {
                if (!user.profile.sleep_mode) {
                    let event

                    switch (notification.type) {
                        case "App\\Notifications\\MentionNotification":
                            event = 'mention'
                            break
                    }

                    if (event) {
                        VueEvent.fire(event, notification)
                    }
                }
            })
        Echo.private('company-' + user.profile.company_id)
            .listen('.contact-list.import-csv', (event) => {
                VueEvent.fire('contact_list_import_csv')
            })
            .listen('.contact-list.import-hubspot', (event) => {
                VueEvent.fire('contact_list_import_hubspot')
            })
            .listen('.contact-list.created', (event) => {
                let contact_list = event.contact_list
                if (contact_list) {
                    VueEvent.fire('contact_list_created', contact_list)
                }
            })
            // listen for typing event
            .listenForWhisper('typing', (event) => {
                VueEvent.fire('typing', event)
            })
            .listen('.company.updated', (event) => {
                if (store.state.cache.current_company && store.state.cache.current_company.id === event.company.id) {
                    // update company
                    event.company = _.merge(store.state.cache.current_company, event.company)
                    store.dispatch('cache/setCurrentCompany', event.company)
                }
            })
            .listen('.communication.created', (event) => {
                let communication = event.communication
                if (event.tags) {
                    communication.tags = event.tags
                    communication.tag_ids = event.tags.map((a) => a.id)
                }
                if (event.teams) {
                    communication.teams = event.teams
                }
                if (event.contact) {
                    communication.contact = event.contact
                }
                if (event.owner) {
                    communication.owner = event.owner
                }
                if (event.contact_tags && communication && communication.contact) {
                    communication.contact.tags = event.contact_tags
                }
                let campaign = store.state.campaigns.find(campaign => campaign.id === communication.campaign_id)
                if (campaign) {
                    communication.campaign = campaign
                }
                VueEvent.fire('new_communication', communication)
            })
            .listen('.communication.updated', (event) => {
                let communication = event.communication
                if (event.tags) {
                    communication.tags = event.tags
                    communication.tag_ids = event.tags.map((a) => a.id)
                }
                if (event.contact) {
                    communication.contact = event.contact
                }
                if (event.owner) {
                    communication.owner = event.owner
                }
                if (event.contact_tags && communication && communication.contact) {
                    communication.contact.tags = event.contact_tags
                }
                let campaign = store.state.campaigns.find(campaign => campaign.id === communication.campaign_id)
                if (campaign) {
                    communication.campaign = campaign
                }
                VueEvent.fire('update_communication', communication)
            })
            .listen('.communication.deleted', (event) => {
                VueEvent.fire('delete_communication', event.communication)
            })
            .listen('.incoming_number.created', (event) => {
                let campaign = store.state.campaigns.find(campaign => campaign.id === event.incoming_number.campaign_id)
                if (campaign) {
                    if (!campaign.incoming_number) {
                        campaign.incoming_number = event.incoming_number.phone_number
                    }
                    if (!campaign.incoming_numbers) {
                        campaign.incoming_numbers = [event.incoming_number]
                    } else {
                        campaign.incoming_numbers.push(event.incoming_number)
                    }
                    store.commit('UPDATE_CAMPAIGN', campaign)
                }
            })
            .listen('.ring_group.created', (event) => {
                let ring_group = event.ring_group
                store.commit('NEW_RING_GROUP', ring_group)
                VueEvent.fire('ring_group_created', ring_group)
            })
            .listen('.ring_group.updated', (event) => {
                let ring_group = event.ring_group
                store.commit('UPDATE_RING_GROUP', ring_group)
                VueEvent.fire('ring_group_updated', ring_group)
            })
            .listen('.ring_group.deleted', (event) => {
                store.commit('DELETE_RING_GROUP', event.ring_group)
                VueEvent.fire('ring_group_deleted', event.ring_group)
            })
            .listen('.campaign.created', (event) => {
                let campaign = event.campaign
                store.commit('NEW_CAMPAIGN', campaign)
                VueEvent.fire('campaign_created', campaign)
            })
            .listen('.campaign.updated', (event) => {
                let campaign = event.campaign
                store.commit('UPDATE_CAMPAIGN', campaign)
                VueEvent.fire('campaign_updated', campaign)
            })
            .listen('.campaign.deleted', (event) => {
                store.commit('DELETE_CAMPAIGN', event.campaign)
                VueEvent.fire('campaign_deleted', event.campaign)
            })
            .listen('.tag.created', (event) => {
                let tag = event.tag
                VueEvent.fire('tag_created', tag)
            })
            .listen('.tag.updated', (event) => {
                let tag = event.tag
                if (!tag.name.includes('_deleted_')) {
                    VueEvent.fire('tag_updated', tag)
                }
            })
            .listen('.tag.deleting', (event) => {
                let tag = event.tag
                VueEvent.fire('tag_deleting', tag)
            })
            .listen('.disposition_status.created', (event) => {
                let disposition_status = event.disposition_status
                store.commit('NEW_DISPOSITION_STATUS', disposition_status)
                VueEvent.fire('disposition_status_created', disposition_status)
            })
            .listen('.disposition_status.updated', (event) => {
                let disposition_status = event.disposition_status
                store.commit('UPDATE_DISPOSITION_STATUS', disposition_status)
                VueEvent.fire('disposition_status_updated', disposition_status)
            })
            .listen('.disposition_status.deleted', (event) => {
                let disposition_status = event.disposition_status
                store.commit('DELETE_DISPOSITION_STATUS', disposition_status)
                VueEvent.fire('disposition_status_deleted', disposition_status)
            })
            .listen('.call_disposition.bulk_created', (event) => {
                let call_dispositions = event.call_dispositions
                store.commit('NEW_BULK_CALL_DISPOSITION', call_dispositions)
                VueEvent.fire('call_disposition_bulk_created', call_dispositions)
            })
            .listen('.call_disposition.created', (event) => {
                let call_disposition = event.call_disposition
                store.commit('NEW_CALL_DISPOSITION', call_disposition)
                VueEvent.fire('call_disposition_created', call_disposition)
            })
            .listen('.call_disposition.updated', (event) => {
                let call_disposition = event.call_disposition
                store.commit('UPDATE_CALL_DISPOSITION', call_disposition)
                VueEvent.fire('call_disposition_updated', call_disposition)
            })
            .listen('.call_disposition.deleted', (event) => {
                let call_disposition = event.call_disposition
                store.commit('DELETE_CALL_DISPOSITION', call_disposition)
                VueEvent.fire('call_disposition_deleted', call_disposition)
            })
            .listen('.activity_type.created', (event) => {
                let activity_type = event.activity_type
                store.commit('NEW_ACTIVITY_TYPE', activity_type)
                VueEvent.fire('activity_type_created', activity_type)
            })
            .listen('.activity_type.deleted', (event) => {
                let activity_type = event.activity_type
                store.commit('DELETE_ACTIVITY_TYPE', activity_type)
                VueEvent.fire('activity_type_deleted', activity_type)
            })
            .listen('.contact.created', (event) => {
                let contact = event.contact
                if (contact) {
                    if (event.user) {
                        contact.user = event.user
                    }
                    if (event.tags) {
                        contact.tags = event.tags
                        contact.tag_ids = contact.tags.map((a) => a.id)
                    }
                }
            })
            .listen('.contact.updated', (event) => {
                let contact = event.contact
                if (contact) {
                    if (event.user) {
                        contact.user = event.user
                    }
                    if (event.tags) {
                        contact.tags = event.tags
                        contact.tag_ids = contact.tags.map((a) => a.id)
                    }
                    VueEvent.fire('contact_updated', contact)
                }
            })
            .listen('.contact.deleted', (event) => {
                let contact = event.contact
                if (contact) {
                    VueEvent.fire('contact_deleted', contact)
                }
            })
            .listen('.credit.added', (event) => {
                VueEvent.fire('credits_added', event.amount)
            })
            .listen('.plan.changed', (event) => {
                VueEvent.fire('plan_changed', event.plan)
            })
            .listen('.filter.created', (event) => {
                if (!store.state.filters.find((o) => {
                    return o.id === event.filter.id
                })) {
                    store.commit('NEW_FILTER', event.filter)
                }
            })
            .listen('.filter.updated', (event) => {
                store.commit('UPDATE_FILTER', event.filter)
            })
            .listen('.filter.deleted', (event) => {
                if (store.state.filters.find((o) => {
                    return o.id === event.filter.id
                })) {
                    store.commit('DELETE_FILTER', event.filter)
                }
            })
            .listen('.export.created', event => {
                if (!auth.user.profile || (event.export.user_id !== auth.user.profile.id && !hasRole(Roles.COMPANY_ADMIN))) {
                    return
                }
                if (event.export.user_id !== auth.user.profile.id && store.state.exports.scope === 'user') {
                    return
                }
                if (event.export.user_id === auth.user.profile.id && store.state.exports.scope === 'company') {
                    return
                }
                store.commit('NEW_EXPORT', event.export)
                VueEvent.fire('export_created', event.export)
            })
            .listen('.export.updated', event => {
                if (!auth.user.profile || (event.export.user_id !== auth.user.profile.id && !hasRole(Roles.COMPANY_ADMIN))) {
                    return
                }
                if (event.export.user_id !== auth.user.profile.id && store.state.exports.scope === 'user') {
                    return
                }
                if (event.export.user_id === auth.user.profile.id && store.state.exports.scope === 'company') {
                    return
                }
                store.commit('UPDATE_EXPORT', event.export)
                VueEvent.fire('export_updated', event.export)
            })
            .listen('.export.deleted', event => {
                if (!auth.user.profile || (event.export.user_id !== auth.user.profile.id && !hasRole(Roles.COMPANY_ADMIN))) {
                    return
                }
                if (event.export.user_id !== auth.user.profile.id && store.state.exports.scope === 'user') {
                    return
                }
                if (event.export.user_id === auth.user.profile.id && store.state.exports.scope === 'company') {
                    return
                }
                store.commit('DELETE_EXPORT', event.export)
                VueEvent.fire('export_deleted', event.export)
            })
            .listen('.workflow.created', (event) => {
                store.commit('NEW_WORKFLOW', event.workflow)
                VueEvent.fire('workflow_created', event.workflow)
            })
            .listen('.workflow.updated', (event) => {
                let workflow = event.workflow
                store.commit('UPDATE_WORKFLOW', workflow)
                VueEvent.fire('workflow_updated', workflow)
            })
            .listen('.workflow.deleted', (event) => {
                store.commit('DELETE_WORKFLOW', event.workflow)
                VueEvent.fire('workflow_deleted', event.workflow)
            })
            .listen('.broadcasts.created', (event) => {
                store.commit('NEW_BROADCAST', event.broadcaster)
                VueEvent.fire('broadcasts_created', event.broadcaster)
            })
            .listen('.broadcasts.updated', (event) => {
                let broadcast = event.broadcaster
                store.commit('UPDATE_BROADCAST', broadcast)
                VueEvent.fire('broadcasts_updated', broadcast)
            })
            .listen('.broadcasts.deleted', (event) => {
                store.commit('DELETE_BROADCAST', event.broadcaster)
                VueEvent.fire('broadcasts_deleted', event.broadcaster)
            })
            .listen('.user.created', (event) => {
                store.commit('NEW_USER', event.user)
                if (store.state.cache.current_company && event.user.company_id && event.user.company_id == store.state.cache.current_company.id) {
                    VueEvent.fire('user_created', event.user)
                }
            })
            .listen('.user.updated', (event) => {
                if (!auth.user.profile || !event.user) {
                    return
                }
                let user = event.user
                store.commit('UPDATE_USER', user)
                if (auth.user.profile.id == event.user.id) {
                    auth.user.profile = _.extend({}, auth.user.profile, user)
                }
                if (store.state.cache.current_company && event.user.company_id && event.user.company_id == store.state.cache.current_company.id) {
                    VueEvent.fire('user_updated', user)
                }
            })
            .listen('.user.deleted', (event) => {
                store.commit('DELETE_USER', event.user)
                if (store.state.cache.current_company && event.user.company_id && event.user.company_id == store.state.cache.current_company.id) {
                    VueEvent.fire('user_deleted', event.user)
                }
            })
            .listen('.script.deleted', (event) => {
                store.commit('DELETE_SCRIPT', event.script)
                VueEvent.fire('script_deleted', event.script)
            })
            .listen('.cnam_called_id.created', (event) => {
                VueEvent.fire('cnam_called_id_created', event.cnam_called_id)
            })
            .listen('.cnam_called_id.updated', (event) => {
                VueEvent.fire('cnam_called_id_updated', event.cnam_called_id)
            })
            .listen('.kyc_status_updated', (event) => {
                if (store.state.cache.current_company && store.state.cache.current_company.id === event.company.id) {
                    setInterval(() => {
                        if (sessionStorage.getItem('on_call') === 'false') {
                            store.dispatch('cache/setCurrentCompany', event.company)
                            VueEvent.fire('kyc_status_updated', event.company)
                        }
                    }, 10000)
                }
            })

        Echo.private('cache-agent-status-user-' + user.profile.id)
            .listen('.agent_status.updated', (event) => {
                // make sure this event occurs only for the logged user
                if (!auth.user.profile || !event.user_id || event.user_id !== auth.user.profile.id) {
                    return
                }
                store.commit('UPDATE_AGENT_STATUS', event)
                if (store.state.cache.current_company && event.company_id && event.company_id == store.state.cache.current_company.id) {
                    VueEvent.fire('agent_status_updated', event)
                }
            })
    },
    // This test listener is going to be removed in the future, this is just a soketi stress testing
    soketiTestListen(user, echoSoketi) {
        const userEvents = [
            '.user.status.updated',
            '.user.in-app.contact.contact_assigned',
            '.user.in-app.custom_message',
            '.user.in-app.campaign.new.caller.id',
            '.user.in-app.communication.new_call',
            '.user.in-app.communication.answered_call',
            '.user.in-app.communication.new_sms',
            '.user.in-app.communication.new_voicemail',
            '.user.in-app.communication.new_fax',
            '.user.in-app.compliance.registration',
            '.user.in-app.compliance.invitation',
            '.user.desktop.contact.contact_assigned',
            '.user.desktop.custom_message',
            '.user.desktop.communication.new_call',
            '.user.desktop.communication.new_sms',
            '.user.desktop.communication.new_voicemail',
            '.user.desktop.communication.new_fax',
            '.bulk_contacts.deleted',
            '.integration.pipedrive.app_extension.power_dialer_completed',
            '.integration.pipedrive.app_extension.sequence_completed',
            '.user.customer_profiles',
            '.user.messaging_services',
            '.user.logout',
            '.communication.created',
            '.communication.updated'
        ]

        const companyEvents = [
            '.contact-list.import-csv',
            '.contact-list.import-hubspot',
            '.contact-list.created',
            'typing',
            '.company.updated',
            '.communication.created',
            '.communication.updated',
            '.communication.deleted',
            '.incoming_number.created',
            '.ring_group.created',
            '.ring_group.updated',
            '.ring_group.deleted',
            '.campaign.created',
            '.campaign.updated',
            '.campaign.deleted',
            '.tag.created',
            '.tag.updated',
            '.tag.deleting',
            '.disposition_status.created',
            '.disposition_status.updated',
            '.disposition_status.deleted',
            '.call_disposition.bulk_created',
            '.call_disposition.created',
            '.call_disposition.updated',
            '.call_disposition.deleted',
            '.activity_type.created',
            '.activity_type.deleted',
            '.contact.created',
            '.contact.updated',
            '.contact.deleted',
            '.credit.added',
            '.plan.changed',
            '.filter.created',
            '.filter.updated',
            '.filter.deleted',
            '.export.created',
            '.export.updated',
            '.export.deleted',
            '.workflow.created',
            '.workflow.updated',
            '.workflow.deleted',
            '.broadcasts.created',
            '.broadcasts.updated',
            '.broadcasts.deleted',
            '.user.created',
            '.user.updated',
            '.user.deleted',
            '.script.deleted',
            '.cnam_called_id.created',
            '.cnam_called_id.updated',
            '.kyc_status_updated'
        ]

        userEvents.forEach(event => {
            echoSoketi.private('user-' + user.profile.id).listen(event, (data) => {})
        })

        companyEvents.forEach(event => {
            echoSoketi.private('company-' + user.profile.company_id).listen(event, (data) => {})
        })

        echoSoketi.private('cache-agent-status-user-' + user.profile.id)
            .listen('.agent_status.updated', (event) => {})
    },
    leave(user) {
        if (!user || !user.profile) {
            return
        }
        Echo.leave('user-' + user.profile.id)
        Echo.leave('company-' + user.profile.company_id)
        Echo.leave('cache-agent-status-user-' + user.profile.id)

        if (window.EchoSoketi) {
            window.EchoSoketi.leave('user-' + user.profile.id)
            window.EchoSoketi.leave('company-' + user.profile.company_id)
            window.EchoSoketi.leave('cache-agent-status-user-' + user.profile.id)
        }
    }
}
