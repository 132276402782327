var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt-5 pt-1" },
      [
        _c("upgrade-now-page", {
          staticClass: "mt-5",
          attrs: {
            "image-link": "/assets/images/aloai-voice-bot.svg",
            "title-text": "AloAi Voice Bot",
            text: "Seamlessly handle calls, answer FAQs, route queries, and create personalized experiences at scale.",
            "extra-text":
              "And yes, it can also do outbound calls for you — coming soon!",
            "show-button": false,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }