<template>
    <div v-if="hasPermissionTo('create contact list')">
        <!--create new contact list-->
        <el-dialog class="dialog-padding auto"
                   :title="title"
                   :width="isLargeEnough ? '30%' : '100%'"
                   :visible.sync="hide_add"
                   :before-close="beforeCloseModal"
                   data-testid="contact-list-creator-dialog"
                   append-to-body>
            <el-form ref="add_list"
                     class="mt-2 p-0"
                     label-position="top"
                     :rules="rules_contact_list"
                     :model="contactList"
                     data-testid="contact-list-creator-form"
                     @submit.prevent.native="createList">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item prop="name"
                                      label="Name">
                            <div>
                                <el-input v-model="contactList.name"
                                          data-testid="contact-list-creator-name-input"
                                          @input="preValidateForm('add_list')">
                                </el-input>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <div class="row pb-3">
                <div class="col-12">
                    <el-button type="success"
                               class="pull-right ml-2"
                               :disabled="loading_btn"
                               data-testid="contact-list-creator-add-button"
                               @click="createList">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="fa fa-plus"
                           v-show="!loading_btn"></i>
                        <span>Add</span>
                    </el-button>

                    <el-button type="info"
                               class="pull-right"
                               data-testid="contact-list-creator-cancel-input"
                               @click="hide_add = false">
                        <span class="fa fa-close"></span>
                        <span>Cancel</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <slot name="trigger">
            <el-button slot="reference"
                       type="text text-sm text-dark-greenish"
                       data-testid="contact-list-creator-add-slot"
                       @click="hide_add = true">
                <i class="el-icon-circle-plus-outline"></i>
                Add Contact List
            </el-button>
        </slot>
    </div>
</template>

<script>
    import auth from '../auth'
    import * as ContactListModuleTypes from '../constants/contact-list-module-types'
    import * as ContactListTypes from '../constants/contact-list-types'
    import {
        acl_mixin,
        form_validation_mixin
    } from '../mixins'
    import {mapState} from 'vuex'

    export default {
        mixins: [
            acl_mixin,
            form_validation_mixin
        ],

        props: {
            title: {
                required: false,
                type: String,
                default: 'Create a new static contact list'
            },
        },

        data() {
            return {
                auth: auth,
                loading_btn: false,
                hide_add: false,
                contactList: {
                    name: null,
                    type: ContactListTypes.STATIC_LIST,
                    module_type: ContactListModuleTypes.CONTACT_LIST,
                    show_in_public_folder: true,
                    use_default_headers: true
                },
                rules_contact_list: {
                    name: [
                        {
                            required: true,
                            message: 'Please provide a list name',
                            trigger: 'blur'
                        }
                    ],
                }
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            isLargeEnough() {
                const valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            },
        },
        methods: {
            createList() {
                if (this.validateForm('add_list')) {
                    this.loading_btn = true
                    const url = '/api/v2/contacts-list'
                    const data = _.clone(this.contactList)
                    axios.post(url, data)
                        .then(res => {
                            this.loading_btn = false
                            this.hide_add = false
                            this.$emit('success', res.data.data)
                            this.resetContactList()
                        })
                        .catch(err => {
                            this.$root.handleErrors(err.response)
                            this.loading_btn = false
                            this.resetContactList()
                        })
                }
            },

            resetContactList() {
                this.contactList = {
                    ...this.contactList,
                    name: null,
                }
            },

            beforeCloseModal(done) {
                if (this.contactList.name) {
                    this.$confirm('Are you sure you want to leave? Your changes will not be saved.', 'Warning', {
                        confirmButtonText: 'Yes, Leave',
                        cancelButtonText: 'No, Stay',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(res => {
                        this.resetContactList()
                        done()
                    })
                } else {
                    this.resetContactList()
                    done()
                }
            },
        },

        watch: {
            hide_add() {
                if (this.hide_add) {
                    this.$emit('hide')
                } else {
                    this.$emit('show')
                }
            }
        }
    }
</script>
