var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { "data-testid": "line-registration-wrapper" } }, [
    _c(
      "div",
      { staticClass: "container-campaign-summary mt-5 mb-2" },
      [
        !_vm.requiresRegistration && !_vm.registered
          ? _c(
              "h4",
              {
                staticClass: "font-weight-bold text-dark",
                attrs: { "data-testid": "not-registration-title" },
              },
              [
                _c("i", { staticClass: "fa fa-check text-greenish" }),
                _vm._v(
                  "\n            This Line does not require registration, yet.\n        "
                ),
              ]
            )
          : _vm.requiresRegistration && !_vm.registered
          ? [
              _vm.hasUseCase
                ? _c(
                    "h4",
                    {
                      staticClass: "font-weight-bold text-dark",
                      attrs: { "data-testid": "register-line-title" },
                    },
                    [
                      _c("i", { staticClass: "fa fa-cancel text-greenish" }),
                      _vm._v(
                        "\n                Register this Line to enable the ability to Send SMS.\n            "
                      ),
                    ]
                  )
                : !_vm.hasUseCase && !_vm.current_company.is_trial
                ? _c(
                    "h5",
                    {
                      staticClass: "font-weight-bold text-dark break-word",
                      attrs: { "data-testid": "proceed-title" },
                    },
                    [
                      _c("i", { staticClass: "fa fa-cancel text-greenish" }),
                      _vm._v(
                        "\n                It looks like you don't have any existing messaging services to assign to. To proceed, please "
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "text-blue",
                          attrs: {
                            to: {
                              name: "Account",
                              query: { tab: "compliance" },
                            },
                          },
                        },
                        [_vm._v("create a new messaging service")]
                      ),
                      _vm._v(".\n            "),
                    ],
                    1
                  )
                : !_vm.hasUseCase && _vm.current_company.is_trial
                ? _c(
                    "h5",
                    {
                      staticClass: "font-weight-bold text-dark break-word",
                      attrs: { "data-testid": "sorry-title" },
                    },
                    [
                      _c("i", { staticClass: "fa fa-cancel text-greenish" }),
                      _vm._v(
                        "\n                Sorry, trial accounts are not eligible to register within the new line wizard. To unlock this feature, please upgrade your account.\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          : _vm.registered
          ? _c(
              "h4",
              {
                staticClass: "font-weight-bold text-dark",
                attrs: { "data-testid": "almost-ready-title" },
              },
              [
                _c("i", { staticClass: "fa fa-cancel text-greenish" }),
                _vm._v(
                  "\n            Your line is almost ready to be used!\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.requiresRegistration && _vm.hasUseCase
          ? _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.available_messaging_services_loading,
                    expression: "available_messaging_services_loading",
                  },
                ],
                attrs: {
                  data: _vm.filteredMessagingServices,
                  "data-testid": "messaging-services-table",
                  "max-height": 300,
                },
              },
              [
                _c("el-table-column", {
                  attrs: { "min-width": "100", "data-testid": "first-column" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                clearable: "",
                                size: "mini",
                                placeholder: "Type to search a Use Case",
                              },
                              model: {
                                value: _vm.messaging_service_search,
                                callback: function ($$v) {
                                  _vm.messaging_service_search = $$v
                                },
                                expression: "messaging_service_search",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                    #" +
                                _vm._s(scope.row.id) +
                                " - " +
                                _vm._s(
                                  _vm.getUseCaseTitle(
                                    scope.row?.a2p_use_case?.use_case
                                  )
                                ) +
                                "\n                    "
                            ),
                            _vm.messagingServiceHasLocalPresenceLines(scope.row)
                              ? _c(
                                  "span",
                                  { staticClass: "label ml-1 text-xxs blue" },
                                  [
                                    _vm._v(
                                      "\n                        For Local Presence\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2008835370
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    "data-testid": "second-column",
                    label: "10DLC Numbers",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !_vm.possibleToAttachLine(scope.row) &&
                            !_vm.messagingServiceHasLocalPresenceLines(
                              scope.row
                            )
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "right",
                                      "data-testid": "10dlc-tooltip",
                                      content: _vm._f("statusExplanation")(
                                        scope.row.status
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: "danger",
                                          effect: "dark",
                                          "data-testid": "10dlc-tag",
                                          size: "mini",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Not Available [" +
                                            _vm._s(
                                              scope.row.incoming_numbers
                                                ?.length +
                                                _vm.selectedLinesToAttachIncomingNumbersCount ??
                                                "0"
                                            ) +
                                            " / 400]\n                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.incoming_numbers?.length ?? "0"
                                    ) + " / 400"
                                  ),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1413422224
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { align: "center", "data-testid": "third-column" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  disabled: _vm.possibleToAttachLine(scope.row),
                                  placement: "right",
                                  "data-testid": "attach-tooltip",
                                  content: _vm.reason,
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-icon btn-sm btn-primary",
                                    attrs: {
                                      loading:
                                        _vm.attaching_line_to_messaging_service,
                                      "data-testid": "attach-button",
                                      disabled:
                                        !_vm.possibleToAttachLine(scope.row) ||
                                        _vm.attaching_line_to_messaging_service ||
                                        _vm.registered,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.attachLineToCampaign(
                                          scope.row.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons text-white",
                                      },
                                      [_vm._v("add")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3537650915
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "mt-4",
            attrs: {
              type: "success",
              "data-testid": "next-line-button",
              disabled:
                !_vm.registered && _vm.requiresRegistration && _vm.hasUseCase,
            },
            on: { click: _vm.goToLineDetails },
          },
          [
            _vm._v("\n            Next: Line Details\n            "),
            _c("i", { staticClass: "el-icon-arrow-right el-icon-right" }),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }