var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "New List Created",
            width: "30%",
            center: "",
            visible: _vm.converted_list_dialog,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.converted_list_dialog = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "\n            The tag has been successfully converted to a contact list.\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.converted_list && _vm.converted_list.show_in_public_folder
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openConvertedList },
                    },
                    [_vm._v("\n                Go to list\n            ")]
                  )
                : _c(
                    "router-link",
                    { attrs: { to: { name: "Lists" } } },
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "primary", icon: "el-icon-tickets" } },
                        [
                          _vm._v(
                            "\n                    Go to lists\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading_tags,
              expression: "loading_tags",
            },
          ],
          ref: "tags_table_index",
          staticClass: "w-full",
          attrs: {
            data: _vm.tags,
            "default-sort": { prop: _vm.order_by, order: _vm.order },
            stripe: "",
          },
          on: { "sort-change": _vm.onSortChange },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "100", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "text-greyish" }, [
                      _vm._v(_vm._s(scope.row.id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "updated_at",
              label: "Date",
              width: "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "text-greyish" }, [
                      _vm._v(
                        _vm._s(_vm._f("fixDateTime")(scope.row.updated_at))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "color",
              label: "Color",
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !_vm.editingTag || _vm.editingTag.id != scope.row.id
                      ? _c("i", {
                          staticClass: "fa fa-circle",
                          style: { color: scope.row.color },
                        })
                      : _c("el-color-picker", {
                          staticClass: "d-block",
                          on: { "active-change": _vm.changeUpdateTagColor },
                          model: {
                            value: _vm.editingTag.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingTag, "color", $$v)
                            },
                            expression: "editingTag.color",
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "Name",
              "min-width": 200,
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !_vm.editingTag || _vm.editingTag.id != scope.row.id
                      ? _c(
                          "span",
                          {
                            staticClass: "text-greyish",
                            attrs: { title: scope.row.name },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(scope.row.name) +
                                "\n                "
                            ),
                          ]
                        )
                      : _c("el-input", {
                          attrs: { placeholder: "Name" },
                          model: {
                            value: _vm.editingTag.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingTag, "name", $$v)
                            },
                            expression: "editingTag.name",
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Description", "min-width": 200 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !_vm.editingTag || _vm.editingTag.id != scope.row.id
                      ? _c("span", { staticClass: "text-greyish" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(scope.row.description || "-") +
                              "\n                "
                          ),
                        ])
                      : _c("el-input", {
                          attrs: {
                            placeholder: "Description",
                            type: "textarea",
                            rows: "2",
                            maxlength: "190",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.editingTag.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.editingTag, "description", $$v)
                            },
                            expression: "editingTag.description",
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          !_vm.hasReporterAccess && _vm.filter === "communication"
            ? _c("el-table-column", {
                attrs: {
                  prop: "communications_count",
                  label: "Number Of Communications",
                  "min-width": 200,
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.type === 2
                            ? _c("span", [_vm._v("-")])
                            : [
                                _vm.loading_extend_tag[scope.row.id]
                                  ? _c("i", {
                                      staticClass:
                                        "fa fa-spin fa-spinner pull-left",
                                    })
                                  : _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Click to refresh",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "b",
                                          {
                                            staticClass:
                                              "label danger rounded m-l-xs pointer",
                                            on: {
                                              click: function ($event) {
                                                return _vm.extendTag(
                                                  scope.row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  scope.row.communications_count
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                              ],
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  565477962
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasReporterAccess &&
          (_vm.filter === "contact" || _vm.filter === "import")
            ? _c("el-table-column", {
                attrs: {
                  prop: "contacts_count",
                  label: "Number Of Contacts",
                  "min-width": 200,
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.type === 2
                            ? _c("span", [_vm._v("-")])
                            : [
                                _vm.loading_extend_tag[scope.row.id]
                                  ? _c("i", {
                                      staticClass:
                                        "fa fa-spin fa-spinner pull-left",
                                    })
                                  : _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Click to refresh",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "b",
                                          {
                                            staticClass:
                                              "label danger rounded m-l-xs pointer",
                                            on: {
                                              click: function ($event) {
                                                return _vm.extendTag(
                                                  scope.row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  scope.row.contacts_count
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                              ],
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2936840010
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasReporterAccess
            ? _c("el-table-column", {
                attrs: {
                  label: "Operations",
                  align: "center",
                  width: "190",
                  fixed: "right",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.company_id
                            ? _c("div", [
                                !_vm.editingTag ||
                                _vm.editingTag.id != scope.row.id
                                  ? _c(
                                      "div",
                                      [
                                        scope.row.communications_count
                                          ? _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-12" },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "Dashboard",
                                                            query: {
                                                              tag_id:
                                                                scope.row.id,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-sm btn-block purplish",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-signal pull-left",
                                                            }),
                                                            _vm._v(
                                                              "\n                                        Communications\n                                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.contacts_count
                                          ? _c(
                                              "div",
                                              { staticClass: "row mb-2" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-12" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            _vm.talk2Url +
                                                            "/contacts?tag_id=" +
                                                            scope.row.id,
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-sm btn-block light-blue-500",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fa fa-user pull-left",
                                                            }),
                                                            _vm._v(
                                                              "\n                                        Contacts\n                                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasRole("Company Admin")
                                          ? [
                                              scope.row.contacts_count > 50
                                                ? _c(
                                                    "div",
                                                    { staticClass: "row mb-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-12",
                                                        },
                                                        [
                                                          _c("tag-splitter", {
                                                            attrs: {
                                                              contacts_count:
                                                                scope.row
                                                                  .contacts_count,
                                                              tag_id:
                                                                scope.row.id,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "row mb-2" },
                                                [
                                                  _vm.tag_filter == "contact"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-12",
                                                        },
                                                        [
                                                          _c(
                                                            "convert-to-contacts-list",
                                                            {
                                                              attrs: {
                                                                tag: scope.row,
                                                                enabled:
                                                                  scope.row
                                                                    .contacts_count >
                                                                  0,
                                                              },
                                                              on: {
                                                                "list-converted":
                                                                  _vm.onListConverted,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              scope.row.contacts_count
                                                ? _c(
                                                    "div",
                                                    { staticClass: "row mb-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-12",
                                                        },
                                                        [
                                                          _c(
                                                            "assign-contacts-by-tag",
                                                            {
                                                              attrs: {
                                                                tag: scope.row,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              scope.row.contacts_count &&
                                              _vm.current_company &&
                                              _vm.current_company
                                                .automation_enabled
                                                ? _c(
                                                    "div",
                                                    { staticClass: "row mb-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-12",
                                                        },
                                                        [
                                                          _c(
                                                            "workflow-bulk-enroller",
                                                            {
                                                              attrs: {
                                                                model: "tag",
                                                                id: scope.row
                                                                  .id,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                        Enroll Contacts\n                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.hasPermissionTo("update tag")
                                                ? _c(
                                                    "div",
                                                    { staticClass: "row mb-2" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-12",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-sm btn-block info",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.editTag(
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-pencil pull-left",
                                                              }),
                                                              _vm._v(
                                                                "\n                                        Edit\n                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.hasPermissionTo("delete tag")
                                                ? _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-12",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-sm btn-block danger",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.toggleDialog(
                                                                      scope.row
                                                                        .id,
                                                                      scope.row
                                                                        .contacts_count
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-trash pull-left",
                                                              }),
                                                              _vm._v(
                                                                "\n                                        Delete\n                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _c("div", [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col-12" }, [
                                          _vm.hasPermissionTo("update tag")
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-block primary",
                                                  attrs: {
                                                    disabled: _vm.loading_save,
                                                  },
                                                  on: { click: _vm.saveTag },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.loading_save,
                                                          expression:
                                                            "loading_save",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "material-icons loader",
                                                    },
                                                    [_vm._v("")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          !_vm.loading_save,
                                                        expression:
                                                          "!loading_save",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "fa fa-save pull-left",
                                                  }),
                                                  _vm._v(
                                                    "\n                                    Save\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row mt-2" }, [
                                        _c("div", { staticClass: "col-12" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-block danger",
                                              on: { click: _vm.cancelEdit },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-close pull-left",
                                              }),
                                              _vm._v(
                                                "\n                                    Cancel\n                                "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                              ])
                            : _c("div", { style: _vm.getStyle(scope.row.id) }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "Dashboard",
                                              query: { tag_id: scope.row.id },
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-sm btn-block purplish",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-signal pull-left",
                                              }),
                                              _vm._v(
                                                "\n                                    Communications\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2267251105
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.last_page >= 1
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("el-pagination", {
                staticClass: "pull-right",
                attrs: {
                  background: "",
                  layout: "sizes, prev, pager, next",
                  "page-sizes": [10, 20, 50, 100, 250],
                  "page-size": _vm.per_page,
                  total: _vm.total,
                  "current-page": _vm.current_page,
                },
                on: {
                  "current-change": _vm.onPageChange,
                  "size-change": _vm.onSizeChange,
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }