<template>
    <div class="filter-attribution"
         v-on-clickaway="closeUserList">
        <el-badge type="success"
                  class="filter-counter"
                  :value="users.length"
                  :hidden="users.length === 0">
            <el-button size="small"
                       round
                       plain
                       @click="toggleUserList">
                Users
            </el-button>
        </el-badge>
        <div class="filter-attribution-data-list"
             v-if="show_user_list">
            <div class="d-flex flex-row justify-content-end mb-2">
                <el-button size="small"
                           class="border-0"
                           @click="closeUserList">
                    Close
                </el-button>
            </div>
            <user-selector class="w-100 filter-select"
                           place-holder="Filter Users"
                           :multiple="true"
                           :value="users"
                           @change="setTempUsers">
            </user-selector>
            <div class="d-flex flex-row justify-content-between mt-4">
                <el-button type="default" size="small" round @click="clear">
                    Clear
                </el-button>
                <el-button type="success" size="small" round @click="apply">
                    Apply
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
    import DashboardFilter from '../../../filters/dashboard-filter'
    import {mixin as clickaway} from 'vue-clickaway'

    export default {
        mixins: [clickaway],
        data() {
            return {
                show_user_list: false,
                temp_users: []
            }
        },

        computed: {
            users() {
                return DashboardFilter.users()
            }
        },

        methods: {
            toggleUserList() {
                this.show_user_list = !this.show_user_list
            },

            clear() {
                this.temp_users = []
                this.apply()
            },

            apply() {
                DashboardFilter.setUsers(this.temp_users)
                this.show_user_list = false
            },

            setTempUsers(users) {
                this.temp_users = users
            },

            closeUserList() {
                this.show_user_list = false
            }
        }
    }
</script>
<style lang="scss">
    .filter-attribution {
        position: relative;
        z-index: 901;
    }

    .filter-counter sup.el-badge__content {
        top: 3px !important;
        right: 14px !important;
    }

    .filter-attribution-data-list {
        padding: 1rem;
        border: solid 1px #ddd;
        border-radius: 5px;
        position: absolute;
        top: 40px;
        left: 0;
        background-color: white;
        width: 20rem;
    }
</style>
