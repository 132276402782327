<template>
    <div class="app-body"
         id="view">
        <el-dialog :visible.sync="show_help"
                   append-to-body
                   title="What are ring groups?"
                   top="10vh">
            <p class="text-md _600 break-word">Ring Groups are a set of Users (Agents & Reps) that receive and
                answer your calls in a predefined manner. Each ring group has a queue.</p>
            <p class="text-md _600 break-word">Lines route your calls to teams, group of agents, or queues that you
                create here. Queue parameters are defined within each ring group.</p>
        </el-dialog>

        <div class="fixed-header padding pt-0 pb-0" :style="{top: (top_offset+50) + 'px'}">
            <div class="row mb-0 mt-0">
                <div class="d-flex flex-fill flex-wrap p-2">
                    <div class="d-flex flex-fill flex-xl-grow-0 mr-auto pr-lg-2 search-toolbar">
                        <el-input ref="ringGroupSearch"
                                  placeholder="Search"
                                  prefix-icon="el-icon-search"
                                  clearable
                                  autofocus
                                  v-model="search_text">
                        </el-input>
                    </div>

                    <div class="d-flex flex-wrap">
                        <div class="mt-2 mt-lg-0 mr-2"
                             v-if="!hasRole('Company Agent') && hasPermissionTo('create ring group')">
                            <button class="btn btn-md rounded greenish"
                                    @click="addRingGroup">
                                <i class="fa fa-plus"></i>
                                Add Ring Group
                            </button>
                        </div>

                        <div class="mt-2 mt-lg-0 mr-2"
                             v-if="!hasRole('Company Agent') && hasPermissionTo('create ring group') && !isCompanyKYC">
                            <button class="btn btn-md rounded blue"
                                    @click="importRingGroup">
                                <i class="fas fa-file-import"></i>
                                Import Ring Group
                            </button>
                        </div>

                        <div class="mt-3 mt-lg-0 mr-2"
                             v-if="isTrial && !isSimpSocial">
                            <video-modal-activator />
                        </div>

                        <div class="mt-2 mt-lg-0 mr-2 mr-lg-0 d-flex flex-column justify-content-center text-blue"
                             v-else>
                            <i class="material-icons text-lg cursor-pointer"
                               @click="toggleHelp">
                                info_outline
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="padding mt-5 pt-3"
             v-if="hasPermissionTo('list report') && !search_text">
            <div class="row box">
                <div class="col-12">
                    <div class="no-effect">
                        <div class="box-header pb-0">
                            <activity-graph base="ring_group"
                                            :default_date_range="7"
                                            :is_first_load="reports_first_load">
                            </activity-graph>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="padding">
            <div v-if="hasPermissionTo('list ring group')">
                <div class="row box">
                    <div class="col-12">
                        <div class="no-effect">
                            <div class="box-header">
                                <div class="row">
                                    <div class="col-12">
                                        <span class="pull-left font-120">
                                            <strong>{{ dataCount || 0 }}</strong>
                                            {{ dataCount > 1 ? 'ring groups' : 'ring group' }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body">
                                <div v-on:mouseleave="tableLeave">
                                    <el-table :data="paginatedData"
                                              :default-sort="{prop: 'name', order: 'ascending'}"
                                              @cell-mouse-enter="cellEnter"
                                              class="w-full"
                                              fit
                                              stripe
                                              v-loading="loading">
                                        <el-table-column label="ID"
                                                         prop="id"
                                                         sortable
                                                         width="100">
                                            <template v-slot="scope">
                                                <span class="text-greyish">{{ scope.row.id }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Name"
                                                         prop="name"
                                                         sortable
                                                         width="300">
                                            <template v-slot="scope">
                                                <router-link
                                                    :to="{ name: 'Ring Group Dialog', params: {ring_group_id: scope.row.id }}">
                                                    <span :title="scope.row.name"
                                                          class="text-dark-greenish text-ellipsis">
                                                        {{ scope.row.name }}
                                                    </span>
                                                </router-link>
                                            </template>
                                        </el-table-column>
                                        <el-table-column :min-width="200"
                                                         label="Connected Lines"
                                                         v-if="hasPermissionTo('list campaign')">
                                            <template v-slot="scope">
                                                <template v-if="scope.row.campaign_ids.length">
                                                    <ul>
                                                        <li v-for="(campaign_id, index) in scope.row.campaign_ids"
                                                            v-if="(!showAllLines(scope.row.id) && index < 5) || showAllLines(scope.row.id)"
                                                            :key="index">
                                                            <span class="text-greyish">{{ campaign_id | campaignName(campaigns) }}</span>
                                                        </li>
                                                        <template v-if="scope.row.campaign_ids.length > 5">
                                                            <li style="list-style-type: none"
                                                                v-if="showAllLines(scope.row.id)">
                                                                <a class="text-blue"
                                                                   @click.prevent="seeMoreLinesToggle(scope.row.id, false)">Show less</a>
                                                            </li>
                                                            <li style="list-style-type: none"
                                                                v-if="!showAllLines(scope.row.id)">
                                                                <a class="text-blue"
                                                                   @click.prevent="seeMoreLinesToggle(scope.row.id)">and {{ scope.row.campaign_ids.length - 5 }} more, click to expand</a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </template>
                                                <span class="text-greyish" v-else>-</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center"
                                                         label="Users"
                                                         prop="user_ids"
                                                         sortable
                                                         width="150">
                                            <template v-slot="scope">
                                                <template v-if="scope.row.user_ids">
                                                    <span class="text-dark-greenish"
                                                          v-if="scope.row.user_ids.length > 1">
                                                        {{ scope.row.user_ids.length }} Users
                                                    </span>
                                                    <template
                                                        v-else-if="scope.row.user_ids.length == 1 && getUser(scope.row.user_ids[0])">
                                                        <span :title="getUserName(getUser(scope.row.user_ids[0]))"
                                                              class="text-dark-greenish"
                                                              v-if="getUser(scope.row.user_ids[0])">
                                                            {{ getUserName(getUser(scope.row.user_ids[0])) }}
                                                        </span>
                                                    </template>
                                                    <span class="text-dark-greenish"
                                                          v-else>
                                                        -
                                                    </span>
                                                </template>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center"
                                                         label="Active Users"
                                                         sortable
                                                         width="200">
                                            <template v-slot="scope">
                                                <span :class="[getActiveUsers(scope.row?.user_ids, scope.row?.team_ids) == 0 ? 'text-danger' : 'text-greyish']"
                                                      class="text-ellipsis"
                                                      v-if="scope.row.user_ids">
                                                    {{ getActiveUsers(scope.row?.user_ids, scope.row?.team_ids) }}
                                                </span>
                                                <span class="text-muted"
                                                      v-else>
                                                    -
                                                </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center"
                                                         label="Available Users"
                                                         sortable
                                                         width="200">
                                            <template v-slot="scope">
                                                <span :class="[getAvailableUsers(scope.row?.user_ids, scope.row?.team_ids) == 0 ? 'text-danger' : 'text-greyish']"
                                                      class="text-ellipsis"
                                                      v-if="scope.row.user_ids">
                                                    {{ getAvailableUsers(scope.row?.user_ids, scope.row?.team_ids) }}
                                                </span>
                                                <span class="text-muted"
                                                      v-else>
                                                    -
                                                </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Dial Mode"
                                                         prop="dial_mode"
                                                         sortable
                                                         width="150">
                                            <template v-slot="scope">
                                                <span class="text-greyish text-ellipsis">
                                                    {{ scope.row.dial_mode | humanReadableDialMode }}
                                                </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center"
                                                         fixed="right"
                                                         label="Operations"
                                                         v-if="!hasRole('Company Agent')"
                                                         width="180">
                                            <template v-slot="scope">
                                                <div v-bind:style="getStyle(scope.row.id)"
                                                     slot="reference">
                                                    <div class="row"
                                                        v-if="hasPermissionTo(['view ring group', 'list communication', 'view communication'])">
                                                        <div class="col-12">
                                                            <router-link
                                                                :to="{ name: 'Ring Group Activity', params: {ring_group_id: scope.row.id }}">
                                                                <button class="btn btn-sm btn-block purplish">
                                                                    <i class="fa fa-signal pull-left"></i>
                                                                    Activity
                                                                </button>
                                                            </router-link>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-2"
                                                        v-if="hasPermissionTo('update ring group') || hasPermissionTo('delete ring group')">
                                                        <div class="col-12">
                                                            <router-link
                                                                :to="{ name: 'Ring Group Dialog', params: { ring_group_id: scope.row.id }}">
                                                                <button class="btn btn-sm btn-block info">
                                                                    <i class="fa fa-cogs pull-left"></i>
                                                                    Settings
                                                                </button>
                                                            </router-link>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-2"
                                                        v-if="hasPermissionTo('delete ring group')">
                                                        <div class="col-12">
                                                            <button @click="deleteRingGroupRemote(scope.row.id)"
                                                                    class="btn btn-sm btn-block danger">
                                                                <i class="fa fa-trash pull-left"></i>
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                                <div class="mt-3"
                                     v-if="pageCount >= 1">
                                    <el-pagination :current-page.sync="pagination.current_page"
                                                   :page-size="20"
                                                   :page-sizes="[10, 20, 50, 100, 250]"
                                                   :total="dataCount"
                                                   @current-change="changePage"
                                                   @size-change="handleSizeChange"
                                                   background
                                                   class="pull-right"
                                                   layout="sizes, prev, pager, next">
                                    </el-pagination>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>

        <import-ring-group-wizard
            v-if="auth.user && auth.user.profile"
            ref="import_ring_group_wizard"
        />

        <video-modal class="pl-2"
                     title="🔥 Dive into Ring Groups!"
                     cookie-name="ring-group"
                     notes="🔥 Dive into Ring Groups! A select squad of Agents & Reps awaiting your calls with precision. </br></br>Each group, its own unique queue. With Lines, steer those calls to dream teams, agent clusters, or the perfect queue. </br></br>Customize that queue magic within each group! 💥📞🔥"
                     video-url="https://www.youtube.com/embed/4_Y5MAUjh3k?si=xPantGBLf_VQC9T-"
                     learn-more-link="https://support.aloware.com/en/articles/9031514-mapping-ring-groups-for-inbound-calls-in-aloware"
                     :has-activator-button="true"
                     v-if="isTrial && !isSimpSocial"/>
    </div>
</template>

<script>
import auth from '../../auth'
import {
    acl_mixin,
    chargebee_mixin,
    paginator_mixin,
    scroll_mixin,
    styling_mixin,
    kyc_mixin,
    teams_mixin
} from '../../mixins'
import {mapActions, mapState, mapGetters} from 'vuex'
import * as AnswerTypes from '../../constants/answer-types'
import * as AgentStatus from '../../constants/agent-status'
import ImportRingGroupWizard from '../../components/import-wizard/import-ring-group-wizard'
import VideoModal from '../../components/video-modal.vue'
import VideoModalActivator from '../../components/video-modal-activator.vue'

export default {
    components: { ImportRingGroupWizard, VideoModal, VideoModalActivator },

    mixins: [
        styling_mixin,
        chargebee_mixin,
        paginator_mixin,
        scroll_mixin,
        acl_mixin,
        kyc_mixin,
        teams_mixin
    ],

    data() {
        return {
            auth: auth,
            loading: false,
            loading_btn: false,
            loading_clear_new_leads: false,
            search_text: '',
            search_fields: ['id', 'name', 'description'],
            visible_row_id: null,
            editingRingGroup: null,
            dataKey: 'visibleRingGroups',
            dataSort: 'name',
            dataSortType: 'ASC',
            show_help: false,
            reports_first_load: true,
            AgentStatus,
            AnswerTypes,
            see_more_lines: []
        }
    },

    computed: {
        ...mapState(['campaigns', 'ring_groups', 'users']),
        ...mapState('cache', ['current_company', 'show_graph']),
        ...mapGetters('cache', ['isTrial', 'isSimpSocial']),

        visibleRingGroups() {
            if (this.ring_groups) {
                // exclude call waiting ring groups
                const ring_groups = this.ring_groups.filter(ring_group => !ring_group.call_waiting)
                if (this.search_text !== '') {
                    let filtered = []
                    for (let ring_group of ring_groups) {
                        let keys = this.search_fields

                        for (let key of keys) {
                            if (key.indexOf('.') !== -1) {
                                let mixed = key.split(".")
                                if (ring_group[mixed[0]]) {
                                    if (ring_group[mixed[0]][mixed[1]].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                        filtered.push(ring_group)
                                        break
                                    }
                                }
                            } else if (ring_group[key]) {
                                if (ring_group[key].toString().toLowerCase().indexOf(this.search_text.toString().toLowerCase()) !== -1) {
                                    filtered.push(ring_group)
                                    break
                                }
                            }
                        }
                    }
                    return filtered
                }

                return ring_groups
            }

            return []
        }
    },

    mounted() {
        this.setTitle()
        if (!this.hasPermissionTo('list ring group')) {
            this.goBack()
        }
        // focus ring group search input on page visit
        if (this.$refs.ringGroupSearch) {
            this.$refs.ringGroupSearch.focus()
        }
    },

    methods: {
        seeMoreLinesToggle(ring_group_id, see_more = true) {
            if (see_more) {
                if (!this.see_more_lines.includes(ring_group_id)) {
                    this.see_more_lines.push(ring_group_id)
                }
            }

            if (!see_more) {
                let item_index = this.see_more_lines.indexOf(ring_group_id)

                if (item_index > -1) {
                    this.see_more_lines.splice(item_index, 1)
                }
            }
        },

        showAllLines(ring_group_id) {
            return this.see_more_lines.includes(ring_group_id);
        },

        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Ring Groups - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Ring Groups - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        cellEnter: _.throttle(function (row, column, cell, event) {
            this.visible_row_id = row.id
        }, 50),

        tableLeave() {
            this.visible_row_id = null
        },

        getStyle(id) {
            let opacity = 0.5
            if (id == this.visible_row_id) {
                opacity = 1
            }

            return {
                opacity: opacity
            }
        },

        getAvailableUsers(user_ids, team_ids) {
            let available_count = 0

            const unique_users_ids = this.getUniqueUserIds(user_ids || [], team_ids || [])

            for (let i = 0; i < unique_users_ids.length; i++) {
                let user = this.getUser(unique_users_ids[i])

                if (this.isUserAvailable(user)) {
                    available_count++
                }
            }

            return available_count
        },

        isUserAvailable(user){
            //TODO: move this to a constant file after verify difference between user and agent statuses
            const USER_STATUS_ONLINE = 'online'

            if(!user || !user.active) {
                return false
            }

            // browser based agents
            if (user.answer_by == AnswerTypes.BY_BROWSER && user.status == USER_STATUS_ONLINE && user.agent_status == AgentStatus.AGENT_STATUS_ACCEPTING_CALLS) {
                return true
            }

            // answer by phone number or ip phone agents
            if ([AnswerTypes.BY_PHONE_NUMBER, AnswerTypes.BY_IP_PHONE].includes(user.answer_by)) {
                return true
            }

            // normal users
            if (user.agent_status == AgentStatus.AGENT_STATUS_ACCEPTING_CALLS) {
                return true
            }

            return false
        },

        getActiveUsers(user_ids, team_ids) {
            let active_count = 0

            const unique_users_ids = this.getUniqueUserIds(user_ids || [], team_ids || [])

            for (let i = 0; i < unique_users_ids.length; i++) {
                let user = this.getUser(unique_users_ids[i])
                // user active check
                if (user && user.active == true) {
                    active_count++
                }
            }

            return active_count
        },

        getUniqueUserIds(user_ids = [], team_ids = []){
            let uniqueUserIds = []

            // Add user IDs from user_ids array
            if (user_ids && user_ids.length) {
                uniqueUserIds.push(...user_ids)
            }
            // Add user IDs from each team in team_ids array
            if (team_ids && team_ids.length) {
                team_ids.forEach(team_id => {
                    let foundTeam = this.all_teams.find(team => team.id === team_id)
                    if (foundTeam && foundTeam.users) {
                        foundTeam.users.forEach(({ id }) => {
                            uniqueUserIds.push(id)
                        })
                    }
                })
            }

            // Remove duplicates by converting to a Set and back to an array
            uniqueUserIds = [...new Set(uniqueUserIds)]

            return uniqueUserIds
        },

        getUser(id) {
            if (!id) {
                return null
            }
            let found = this.users.find(user => user.id === id)
            if (found) {
                return found
            }

            return null
        },

        getUserName(user) {
            if (!user) {
                return
            }

            if (user.answer_by !== undefined) {
                switch (user.answer_by) {
                    case AnswerTypes.BY_BROWSER:
                        return user.name + ' - Browser / Apps'
                    case AnswerTypes.BY_IP_PHONE:
                        return user.name + ' - SIP (IP Phone)'
                    case AnswerTypes.BY_PHONE_NUMBER:
                        return user.name + ' - Phone Number (' + user.phone_number + ')'
                    case AnswerTypes.BY_NONE:
                        return user.name + ' - Will Not Answer'
                }
            }
            if (user.name !== '' && user.name && !user.sip_uri) {
                return user.name + ' (' + user.phone_number + ')'
            } else if (user.name !== '' && user.name && user.sip_uri) {
                return user.name + ' - SIP'
            } else if (!user.name && user.sip_uri) {
                return 'No Name - SIP'
            } else {
                return 'No Name (' + user.phone_number + ')'
            }
        },

        addRingGroup() {
            this.$router.push({name: 'Ring Group Dialog'})
        },

        deleteRingGroupRemote(ring_group_id) {
            if (this.ring_groups) {
                let ring_group = this.ring_groups.find(ring_group => ring_group.id === ring_group_id)
                this.$confirm('Deleting a ring group will remove it from all lines and IVRs and this could be a breaking change. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                    customClass: 'large-box width-500 fixed'
                }).then(() => {
                    this.loading = true
                    axios.delete('/api/v1/ring-group/' + ring_group_id)
                        .then(res => {
                            this.deleteRingGroup(ring_group)
                            this.loading = false
                        })
                        .catch(err => {
                            this.$root.handleErrors(err.response)
                            this.loading = false
                        })
                }).catch(() => {

                })
            }
        },

        cancelEdit() {
            this.editingRingGroup = null
        },

        toggleHelp() {
            this.show_help = !this.show_help
        },

        importRingGroup() {
            this.$refs.import_ring_group_wizard.start();
        },

        ...mapActions(['deleteRingGroup'])
    },

    watch: {
        'show_graph': function () {
            this.reports_first_load = false
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check()
            .then((res) => {
                if (res.data.user.is_reseller) {
                    // redirect to account management portal if the company is a reseller
                    next({name: 'Account Management Portal'})
                } else {
                    next()
                }
            })
            .catch((err) => {
                next({name: 'Login', query: {redirect: to.fullPath}})
            })
    }
}
</script>
