var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      key: _vm.dialog_key,
      staticClass: "dialog-blank",
      attrs: {
        width: "32vw",
        top: "7vh",
        "append-to-body": "",
        "show-close": false,
        visible: _vm.visible,
      },
    },
    [
      _c("div", { staticClass: "container-listing rounded p-4" }, [
        _c("div", { staticClass: "container-header d-flex" }, [
          _c("h5", { staticClass: "listing-heading" }, [
            _vm._v("Enroll Contacts"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-dialog-close",
                  attrs: { type: "text", size: "large" },
                  on: { click: _vm.close },
                },
                [_c("i", { staticClass: "fa fa-remove" })]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.workflow && _vm.workflow.is_enrollable
          ? _c(
              "div",
              { staticClass: "container-body" },
              [
                _c("p", { staticClass: "lead mb-2 lh-1" }, [
                  _vm._v(
                    "\n                Select a contact or a group of contacts you want to enroll to "
                  ),
                  _c("strong", [_vm._v(_vm._s(_vm.workflow.name))]),
                  _vm._v(" sequence.\n            "),
                ]),
                _vm._v(" "),
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.tab,
                      callback: function ($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "Contact Group",
                          name: "contact_group",
                        },
                      },
                      [
                        _c("contact-group-selector", {
                          attrs: {
                            full_width: "",
                            tag_category: _vm.TagCategories.CAT_CONTACTS,
                          },
                          on: { change: _vm.contactGroupChanged },
                          model: {
                            value: _vm.contact_group,
                            callback: function ($$v) {
                              _vm.contact_group = $$v
                            },
                            expression: "contact_group",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "Single Contact", name: "contact" } },
                      [
                        _c("contact-select", {
                          attrs: { label: "Search contact" },
                          on: { "select-contacts": _vm.selectContact },
                          model: {
                            value: _vm.selected_contact,
                            callback: function ($$v) {
                              _vm.selected_contact = $$v
                            },
                            expression: "selected_contact",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-4 text-center mb-3" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "mb-2",
                        attrs: {
                          disabled: !_vm.is_enrollable,
                          type: "success",
                          size: "medium",
                          round: "",
                        },
                        on: { click: _vm.enrollContactsToWorkflow },
                      },
                      [
                        _c("strong", [
                          _c("i", { staticClass: "fa fa-plus" }),
                          _vm._v(
                            " Enroll Contact" +
                              _vm._s(_vm.tab === "contact_group" ? "s" : "") +
                              "\n                    "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c("ul", { staticClass: "list-unstyled" }, [
                    _c("li", [
                      _c("i", {
                        staticClass: "fa fa-info-circle text-warning",
                      }),
                      _vm._v(
                        "\n                        Contacts that are already enrolled in a sequence will not be enrolled.\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("i", {
                        staticClass: "fa fa-info-circle text-warning",
                      }),
                      _vm._v(
                        "\n                        Contacts the has a DNC status will not be enrolled.\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("i", {
                        staticClass: "fa fa-info-circle text-warning",
                      }),
                      _vm._v(
                        "\n                        Contact groups that has thousands of contacts will take time to enroll.\n                    "
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.workflow && !_vm.workflow.is_enrollable
          ? _c(
              "div",
              { staticClass: "container-body text-center py-5" },
              [
                _c("img-empty", { staticClass: "w-75 mx-auto mb-3" }),
                _vm._v(" "),
                _c("p", { staticClass: "lead" }, [
                  _c("i", { staticClass: "fa fa-ban text-danger" }),
                  _vm._v(" Enrollment is only allowed between "),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.workflow.converted_open))]),
                  _vm._v("\n                and "),
                  _c("strong", [_vm._v(_vm._s(_vm.workflow.converted_close))]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }