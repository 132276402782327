var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "popover_notes",
      attrs: {
        title: "Notes:",
        placement: _vm.small ? "top" : "left",
        width: _vm.small ? 265 : 400,
        trigger: "click",
        "data-test": "communication-note-popover",
      },
      on: { show: _vm.showNote },
    },
    [
      _vm._t(
        "trigger",
        function () {
          return [
            !_vm.communication.notes
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "text text-greyish opacity-4 hover-opacity text-md m-0 p-0",
                      "data-testid": "communication-note-btn",
                    },
                  },
                  [_c("i", { staticClass: "el-icon-document" })]
                )
              : _c(
                  "el-button",
                  {
                    attrs: {
                      type: "text text-dark-greenish hover-text-blackish text-md m-0 p-0",
                      "data-testid": "communication-note-btn",
                    },
                  },
                  [_c("i", { staticClass: "el-icon-document" })]
                ),
          ]
        },
        { slot: "reference" }
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("el-input", {
              ref: "communication_notes",
              attrs: {
                type: "textarea",
                placeholder: "Write your call notes here...",
                "data-testid": "communication-note-input",
                autosize: { minRows: 3, maxRows: 5 },
                disabled:
                  !_vm.hasPermissionTo("note communication") || _vm.loading_btn,
                autofocus: "",
              },
              model: {
                value: _vm.note,
                callback: function ($$v) {
                  _vm.note = $$v
                },
                expression: "note",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mt-1 text-right h-24" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading_btn,
                      expression: "loading_btn",
                    },
                  ],
                  staticClass: "text-muted",
                  attrs: { "data-testid": "saving" },
                },
                [
                  _c(
                    "i",
                    { staticClass: "material-icons loader text-dark-greenish" },
                    [_vm._v("")]
                  ),
                  _vm._v("\n                    saving\n                "),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading_btn && _vm.loading,
                      expression: "!loading_btn && loading",
                    },
                  ],
                  staticClass: "text-muted",
                  attrs: { "data-testid": "saved" },
                },
                [_vm._v("\n                    saved\n                ")]
              ),
              _vm._v(" "),
              _vm.hasUnsavedChanges && !_vm.loading_btn
                ? _c(
                    "span",
                    {
                      staticClass: "text-deep-orange _600",
                      attrs: { "data-testid": "unsaved-changes" },
                    },
                    [
                      _vm._v(
                        "\n                    unsaved changes\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-1" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "el-button",
              {
                staticClass: "pull-right",
                attrs: {
                  type: "success",
                  size: "small",
                  "data-testid": "save-note-btn",
                  loading: _vm.loading_btn,
                  disabled: _vm.loading_btn,
                },
                on: { click: _vm.saveNote },
              },
              [_vm._v("\n                Save\n            ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }