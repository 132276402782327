<template>
    <div class="app-body"
         id="view">
        <div
            class="container-listing min-height-screen d-flex flex-column"
            :v-loading="workflow_loading">
            <div v-if="workflow">
                <div class="d-flex flex-row w-100 justify-content-center mb-3"
                     v-if="holiday_name && !workflow.process_on_holidays">
                    <el-alert class="sequence-holiday-banner"
                              type="warning"
                              show-icon
                              center
                              :closable="false">
                        <span style="font-size: 14px">This sequence is turned off today to respect {{ holiday_name }}</span>
                    </el-alert>
                </div>
                <div class="container-header d-flex flex-row w-100 justify-content-between">
                    <div class="align-self-center mr-3 d-flex flex-row">
                        <router-link :to="{ name: 'Sequences 2' }">
                            <el-button round size="medium">
                                <i class="fa fa-arrow-left"></i> Back
                            </el-button>
                        </router-link>
                    </div>

                    <div class="d-flex flex-row justify-content-between flex-grow-1">
                        <div class="ml-2">
                            <h3 class="listing-heading">
                                {{ workflow.name }}
                            </h3>
                            <small class="text-grey"
                                   style="font-size: 16px">
                                #{{ workflow.id }}
                            </small>
                        </div>
                        <div class="d-flex">
                            <div class="sequence-switch"
                                 :class="sequence_switch_class">
                                <workflow-status
                                    class="mr-3"
                                    placement="bottom"
                                    :is_long_message="true"
                                    :key="workflow_status_key"
                                    :workflow="workflow">
                                </workflow-status>
                                <el-switch
                                    v-model="workflow.active"
                                    active-color="#00BF50"
                                    :disabled="workflow.real_status === Workflow.CURRENT_STATUS_INVALID && !workflow.active"
                                    @change="changeActiveStatus(workflow)">
                                </el-switch>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-body flex-grow-1">
                    <el-tabs v-model="tab" @tab-click="tabClicked">
                        <el-tab-pane label="Settings"
                                     name="settings">
                            <div class="row">
                                <div class="col-10 mx-auto py-4">
                                    <workflow-settings-manager v-model="workflow"
                                                               @saved="fetchWorkflowStatus">
                                    </workflow-settings-manager>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="Steps"
                                     name="steps">
                            <workflow-steps-list v-model="workflow"
                                                 @step-changed="fetchWorkflowStatus">
                            </workflow-steps-list>
                        </el-tab-pane>
                        <el-tab-pane label="Intake & Triggers"
                                     name="triggers">
                            <workflow-trigger-intake-manager v-model="workflow"/>
                        </el-tab-pane>
                        <el-tab-pane label="Contacts"
                                     name="contacts">
                            <workflow-contacts :workflow="workflow"
                                               ref="workflow_contacts">
                            </workflow-contacts>
                        </el-tab-pane>
                        <el-tab-pane label="History"
                                     name="history">
                            <sequence-logs :workflow="workflow"/>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>

        <form-height-indicator :bottom="20"/>
    </div>
</template>

<script>
import auth from '../../auth'
import {acl_mixin} from '../../mixins'
import WorkflowSettingsManager from './workflow-settings-manager'
import WorkflowStepsList from './workflow-steps-list'
import FormHeightIndicator from '../form-height-indicator'
import WorkflowTriggerIntakeManager from './workflow-trigger-intake-manager'
import WorkflowContacts from './workflow-contacts'
import WorkflowStatus from './workflow-status'
import SequenceLogs from './sequence-logs.vue'
import * as Workflow from '../../constants/workflow'

export default {
    name: "workflow-manager",

    components: {
        WorkflowStatus,
        WorkflowContacts,
        WorkflowTriggerIntakeManager,
        FormHeightIndicator,
        WorkflowStepsList,
        WorkflowSettingsManager,
        SequenceLogs
    },

    mixins: [acl_mixin],

    data() {
        return {
            Workflow,
            auth: auth,
            workflow: null,
            workflow_status_key: 1,
            workflow_loading: true,
            tab: 'settings',
            holiday_name: false
        }
    },

    created() {
        this.getStatics()
        this.getHoliday()
        this.fetchWorkflow(this.$route.params.workflow_id)

        if (this.$route.query.tab) {
            this.tab = this.$route.query.tab
        }
    },

    computed: {
        sequence_switch_class() {
            if (this.workflow.real_status === Workflow.CURRENT_STATUS_INVALID) {
                return 'invalid'
            }

            if (!this.workflow.active) {
                return 'paused'
            }

            return 'running'
        }
    },

    methods: {
        fetchWorkflow(workflow_id) {
            this.workflow_loading = true
            axios.get(`/api/v1/automations/workflows/${workflow_id}`).then(res => {
                this.workflow = res.data
                this.workflow_loading = false
            }).catch(err => {
                this.$router.push({
                    name: 'Sequences 2'
                })
            })
        },

        fetchWorkflowStatus() {
            axios.get(`/api/v1/automations/workflows/${this.workflow.id}/status`).then(res => {
                this.workflow.real_status = res.data.real_status
                this.workflow.diagnosis = res.data.diagnosis

                this.workflow_status_key++
            })
        },

        tabClicked(tab) {
            this.$router.replace({query: {tab: tab.name}}).catch(err => {
                // I need this to catch
            })

            if (this.tab === 'contacts') {
                this.$refs.workflow_contacts.fetchSequenceContacts()
            }
        },

        changeActiveStatus(workflow) {
            let status_action = !workflow.active ? 'pause' : 'activate';

            this.$confirm(`Are you sure you want to ${status_action} this sequence?`, 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: 'width-500 fixed',
                confirmButtonClass: 'el-button--success',
                type: 'warning'
            }).then(() => {
                axios.put(`/api/v1/automations/workflows/${workflow.id}/active-status`, {
                    active: workflow.active
                }).then(res => {
                    workflow.active = res.data.workflow.active

                    this.fetchWorkflowStatus()
                })
            }).catch(() => {
                workflow.active = !workflow.active
            });
        },

        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics').then(res => {
                this.statics = res.data
                this.setPageTitle('Sequence Manager - ' + this.statics.name)
                this.loading_whitelabel = false
            }).catch(err => {
                this.setPageTitle('Sequence Manager - Aloware')
                console.log(err)
                this.$root.handleErrors(err.response)
                this.loading_whitelabel = false
            })
        },

        getHoliday() {
            this.workflow_loading = true
            let user = this.auth.user.profile
            axios.get('/api/v1/company/' + user.company_id + '/holiday').then(res => {
                this.workflow_loading = false
                this.holiday_name = res.data.holiday_name
            }).catch(err => {
                this.workflow_loading = false
                console.log(err)
                this.$root.handleErrors(err.response)
            })
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check().then((res) => {
            next()
        }).catch((err) => {
            next({name: 'Login', query: {redirect: to.fullPath}})
        })
    },

    beforeCreate() {
        axios.get('/build-info.json').then(res => {
            this.env = res.data.env
        }).catch(err => {
            console.log(err)
        })
    }
}
</script>

