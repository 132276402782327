<template>
    <div v-if="hasPermissionTo('list user')">
        <div v-if="!show_only">
            <el-select v-model="user_id"
                       class="w-full"
                       :style="'width: ' + width"
                       :multiple="multiple"
                       :disabled="disabled || (auth.user.profile.focus_mode && user_id !== null && !ignore_focus_mode)"
                       :filter-method="filterOptions"
                       default-first-option
                       filterable
                       clearable
                       :placeholder="placeholder"
                       :size="size"
                       :collapse-tags="collapseTags"
                       data-testid="contact-details-owner-selector"
                       @clear="emitClearEvent"
                       @visible-change="emitVisibleChangeEvent"
                       @remove-tag="emitRemoveTagEvent"
                       autocomplete="off">
                <el-option-group key="Users"
                                 label="Users"
                                 v-if="normalUsers && normalUsers.length > 0">
                    <el-option
                        v-for="user in normalUsers"
                        :key="user.id"
                        :label="user.name"
                        :value="user.id">
                        <div class="media">
                            <div class="media-body">
                                <label>{{ user.name }}</label>
                                <small>{{ user.email }} - {{ getLabel(user) }}</small>
                            </div>
                        </div>
                    </el-option>
                </el-option-group>
                <el-option-group key="Extensions"
                                 label="Extensions"
                                 v-if="!hide_extensions && extensionUsers && extensionUsers.length > 0">
                    <el-option
                        v-for="user in extensionUsers"
                        :key="user.id"
                        :label="user.name"
                        :value="user.id">
                        <div class="media">
                            <div class="media-body">
                                <label>{{ user.name }}</label>
                                <small>{{ getLabel(user) }}</small>
                            </div>
                        </div>
                    </el-option>
                </el-option-group>
            </el-select>
        </div>
        <div v-if="show_only && selectedUser">
            <span>{{ selectedUser.name }}</span>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../auth'
import {acl_mixin} from '../mixins'
import * as AnswerTypes from '../constants/answer-types'

export default {
    mixins: [acl_mixin],

    props: {
        value: {
            required: false,
            default: null
        },

        multiple: {
            type: Boolean,
            required: false,
            default: false
        },

        show_only: {
            default: false,
            type: Boolean,
            required: false
        },

        disabled: {
            default: false,
            type: Boolean,
            required: false
        },

        ignore_focus_mode: {
            default: false,
            type: Boolean,
            required: false
        },

        hide_extensions: {
            default: false,
            type: Boolean,
            required: false
        },

        collapseTags: {
            default: false,
        },

        size: {
            default: ''
        },

        width: {
            default: ''
        },

        placeholder: {
            default: 'Select'
        }
    },

    data() {
        return {
            auth: auth,
            user_id: this.value,
            filtered_text: null,
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company,
            users: state => state.users
        }),

        selectedUser() {
            if (this.user_id) {
                return this.users.find(user => user.id == this.user_id)
            }

            return null
        },

        availableUsers() {
            if (this.users.length > 0 && this.auth.user.profile.focus_mode && !this.ignore_focus_mode) {
                return this.users.filter(user => user.id == this.auth.user.profile.id)
            } else {
                return this.users
            }
        },

        filteredUsers() {
            if (this.availableUsers) {
                let filtered_users = this.availableUsers.filter((user) =>
                    !(user.role_names && user.role_names.length === 1 && user.read_only_access)
                )

                if (this.filtered_text) {
                    return filtered_users.filter((user) =>
                        (user.name && user.name.toLowerCase().includes(this.filtered_text.toLowerCase())) ||
                        (user.phone_number && user.phone_number.includes(this.filtered_text)) ||
                        (user.email && user.email.toLowerCase().includes(this.filtered_text.toLowerCase()))
                    )
                }

                return filtered_users
            }

            return []
        },

        normalUsers() {
            return this.filteredUsers.filter((user) => !user.is_destination)
        },

        extensionUsers() {
            return this.filteredUsers.filter((user) => user.is_destination)
        },
    },

    methods: {
        emitVisibleChangeEvent(visible) {
            this.filtered_text = null
            this.$emit('visible-change', visible)
        },

        emitRemoveTagEvent(removed_user_id) {
            this.$emit('remove-tag', removed_user_id)
        },

        emitClearEvent() {
            this.$emit('clear')
        },

        getLabel(user) {
            if (!user) {
                return
            }

            switch (user.answer_by) {
                case AnswerTypes.BY_PHONE_NUMBER:
                    return 'Phone Number (' + user.phone_number + ')'
                case AnswerTypes.BY_BROWSER:
                    return 'Browser / Apps'
                case AnswerTypes.BY_IP_PHONE:
                    return 'SIP (IP Phone)'
                case AnswerTypes.BY_NONE:
                    return 'Will Not Answer'
            }
        },

        filterOptions(filterValue) {
            this.filtered_text = filterValue
        },
    },

    watch: {
        value() {
            this.user_id = this.value
        },

        user_id(val) {
            if (this.value !== undefined && this.user_id !== this.value) {
                this.$emit('change', val)
            }
        }
    }
}
</script>
