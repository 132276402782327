var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isSimpSocial
    ? _c(
        "div",
        { staticClass: "upgrade-now no-select" },
        [
          _c("h3", { staticClass: "title-text mt-4" }, [
            _vm._v(_vm._s(_vm.titleText)),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "mt-3 mb-3",
            attrs: { src: _vm.imageLink },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "info-text font-weight-bold" }, [
            _vm._v(_vm._s(_vm.text)),
          ]),
          _vm._v(" "),
          _vm.extraText
            ? _c("p", { staticClass: "info-text" }, [
                _vm._v("\n        " + _vm._s(_vm.extraText) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-row mb-4 mt-3" },
            [
              _vm.kbLink
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "large", type: "text" },
                      on: { click: _vm.openKnowledgeBaseLink },
                    },
                    [_vm._v("\n            Learn More\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showButton
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "large",
                        loading: _vm.request_in_progress,
                        round: "",
                      },
                      on: { click: _vm.requestAloAiFeature },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(_vm.buttonText) + "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "Welcome onboard!",
                visible: _vm.display_dialog,
                width: "20%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.display_dialog = $event
                },
                close: _vm.hideRequestAloAIDialog,
              },
            },
            [
              _c("div", { staticClass: "dialog-text" }, [
                _c("img", {
                  staticStyle: { width: "50px" },
                  attrs: {
                    src: "/assets/images/app-icons/aloai-text-bot-gray.svg",
                    alt: "aloai-icon",
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(
                    "\n                We have notified your Account Manager about your request. They should be in touch within a day or two. Thank you for your interest in AloAi.\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    { on: { click: _vm.hideRequestAloAIDialog } },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }