<template>
    <div class="teams-tab">
        <div class="header">
            <h5 class="title">{{ user.first_name }} Team</h5>

            <div class="d-flex w-50 justify-content-end">
                <el-button class="w-25"
                           slot="reference"
                           type="success"
                           data-testid="team-creator-open-modal-button"
                           @click="openAddUserToTeamModal">
                    Add to new team
                </el-button>
            </div>
        </div>

        <div class="actions-bar">
            <div class="d-flex w-50 align-items-center">
                <el-input class="w-60 mr-2"
                          placeholder="Search Teams"
                          prefix-icon="el-icon-search"
                          clearable 
                          v-model="searchInput" />
                <div class="d-flex align-items-center"
                     v-if="selectedTeamsToRemove.length">
                    <span class="text mr-2">{{ selectedTeamsLabel }}</span>
                    <el-dropdown trigger="click">
                        <el-button icon="el-icon-more" 
                                   size="mini"/>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <span class="el-dropdown-menu__item--delete"
                                      @click="openRemoveUserFromTeamModal()">
                                    Remove {{ user.first_name }} from teams
                                </span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end"
                 v-if="teams.length">
                <span class="text">Total Teams: {{ teams.length }}</span>
            </div>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-end py-3">
            <el-pagination layout="sizes, prev, pager, next"
                           :current-page.sync="teamsPagination.page"
                           :page-sizes="[10, 20, 50, 100]"
                           :page-size.sync="teamsPagination.size"
                           :total="teamsPagination.total"
                           @size-change="paginateTeams(1)"
                           @current-change="paginateTeams">
            </el-pagination>
        </div> -->
        <el-table class="w-full"
                  ref="company-teams-list"
                  stripe
                  :data="filteredTeams"
                  v-loading="isLoadingData"
                  @selection-change="handleSelectedTeamsToRemoveChange">
            <el-table-column type="selection"
                             width="55"
                             :show-header="false">
            </el-table-column>
            <el-table-column label="Team"
                             prop="name"
                             sortable>
                <template v-slot="scope">
                    {{ scope.row?.name }}
                </template>
            </el-table-column>
            <el-table-column label="Description"
                             prop="description"
                             sortable
                             show-overflow-tooltip>
                <template v-slot="scope">
                    {{ scope.row?.description || '-' }}
                </template>
            </el-table-column>
            <el-table-column label="Ring Groups"
                             prop="ring_groups"
                             align="center"
                             sortable>
                <template v-slot="scope">
                    <span>{{ scope.row.total_ring_groups || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Users"
                             prop="users"
                             align="center"
                             sortable>
                <template v-slot="scope">
                    <span>{{ scope.row.total_users || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Created by"
                             prop="created_by"
                             sort-by="created_by"
                             sortable>
                <template v-slot="scope">
                    {{ getFullName(scope.row?.created_by) }}
                </template>
            </el-table-column>

            <el-table-column label="Created on"
                             prop="created_at"
                             sortable>
                <template v-slot="scope">
                    {{ scope.row?.created_at | fixFullDateLocal }}
                </template>
            </el-table-column>

            <el-table-column label=""
                             align="right">
                <template v-slot="scope">
                    <el-dropdown trigger="click">
                        <el-button icon="el-icon-more" 
                                   size="mini"/>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <span class="el-dropdown-menu__item--delete"
                                      @click="openRemoveUserFromTeamModal(scope.row)">
                                    Remove {{ user.first_name }} from team
                                </span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <!-- <div class="d-flex align-items-center justify-content-end py-3">
            <el-pagination layout="sizes, prev, pager, next"
                           :current-page.sync="teamsPagination.page"
                           :page-sizes="[10, 20, 50, 100]"
                           :page-size.sync="teamsPagination.size"
                           :total="teamsPagination.total"
                           @size-change="paginateTeams(1)"
                           @current-change="paginateTeams">
            </el-pagination>
        </div> -->

        <add-user-to-team-modal :is-visible.sync="isAddUserToTeamModalVisible"
                                :user="user" 
                                :teams="teams"
                                :is-loading-user-teams="isLoadingUserTeams"
                                @fetch-user-teams="fetchUserTeams(user?.id)"
                                @close="closeAddUserToTeamModal" />

        <el-dialog width="30%"
                   center
                   :visible.sync="isRemoveUserFromTeamModalVisible"
                   @close="closeRemoveUserFromTeamModal">
            <div class="dialog--title mt-4"
                 slot="title">
                {{ removeUserFromTeamModalConfig.title }}
            </div>
            <p class="break-word text-center"
               v-html="removeUserFromTeamModalConfig.description" />
            <p class="break-word text-center">Are you sure?</p>
            <div class="d-flex justify-content-center row mt-5">
                <el-button class="mb-2"
                           type="default"
                           slot="reference"
                           @click="closeRemoveUserFromTeamModal">
                    No, Keep Ring Group
                </el-button>
                <el-button class="mb-2"
                           type="danger"
                           slot="reference"
                           data-testid="team-remove-ring-group-button"
                           :disabled="isLoadingData"
                           @click="onRemoveTeamsFromUser">
                    {{ removeUserFromTeamModalConfig.buttonText }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import AddUserToTeamModal from '../teams/add-user-to-team-modal.vue'
import { teams_mixin } from '../../mixins';

export default {
    name: 'TeamsTab',

    components: {
        AddUserToTeamModal
    },

    mixins: [teams_mixin],

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            isLoading: false,
            searchInput: '',
            isRemoveUserFromTeamModalVisible: false,
            teamsPagination: {
                page: 1,
                size: 20,
                total: 0
            },
            selectedTeamsToRemove: []
        }
    },

    computed: {
        filteredTeams() {
            if (this.searchInput.length > 0) {
                return this.teams.filter(team => {
                    return team.name.toLowerCase().includes(this.searchInput.toLowerCase())
                })
            }

            return this.teams
        },

        removeUserFromTeamModalConfig() {
            if (this.selectedTeamsToRemove.length === 1) {
                return {
                    title: `Remove ${this.user.first_name} from ${this.selectedTeamsToRemove[0].name} Team`,
                    description: `You're about to remove <strong>${this.user.first_name}</strong> from the ${this.selectedTeamsToRemove[0].name} Team. This action can't be undone.`,
                    buttonText: 'Yes, Remove Team'
                }
            }

            return {
                title: `Remove ${this.user.first_name} from Teams`,
                description: `You're about to remove <strong>${this.user.first_name}</strong> from the selected Teams. This action can't be undone.`,
                buttonText: 'Yes, Remove Teams'
            }
        },

        selectedTeamsLabel() {
            if (this.selectedTeamsToRemove.length === 1) {
                return `${this.selectedTeamsToRemove.length} Team Selected`
            }

            return `${this.selectedTeamsToRemove.length} Teams Selected`
        },

        isLoadingData() {
            return this.isLoading || this.isLoadingUserTeams
        }
    },

    methods: {
        handleSelectedTeamsToRemoveChange(val) {
            this.selectedTeamsToRemove = val
        },

        openRemoveUserFromTeamModal(team) {
            if (team && !this.selectedTeamsToRemove.includes(team)) {
                this.selectedTeamsToRemove.push(team)
            }

            if (!this.selectedTeamsToRemove.length) {
                return
            }

            this.isRemoveUserFromTeamModalVisible = true
        },

        closeRemoveUserFromTeamModal() {
            this.isRemoveUserFromTeamModalVisible = false
            this.selectedTeamsToRemove = []
        },

        paginateTeams(page) {
            this.teamsPagination.page = page
            this.fetchUserTeams(this.user?.id)
        },

        onRemoveTeamsFromUser() {
            this.isLoading = true
            const teams_ids = this.selectedTeamsToRemove.map(team => team.id)

            this.$APIV1.User.disassociateTeams(this.user?.id, teams_ids)
                .then(response => {
                    this.selectedTeamsToRemove = []
                    this.fetchUserTeams(this.user?.id)
                    this.closeRemoveUserFromTeamModal()

                    this.$notify({
                        title: 'Success',
                        message: `User has been removed from selected teams`,
                        type: 'success'
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.isLoading = false
                }).finally(() => {
                    this.isLoading = false
                })
        },

        getFullName(object) {
            if (!object) {
                return '-'
            }

            return `${object?.first_name} ${object?.last_name}`
        },

        isUserInTeam(team) {
            return this.teams.some(t => t.id === team.id)
        }
    },

    mounted() {
        this.fetchUserTeams(this.user?.id)
    }
}
</script>
