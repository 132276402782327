var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isSupervisor
    ? _c(
        "router-link",
        {
          attrs: {
            to: { name: "Dashboard" },
            tag: "li",
            "active-class": "active green-gradient rounded",
          },
        },
        [
          _c("a", [
            _c("span", { staticClass: "nav-icon" }, [
              _c("img", {
                attrs: {
                  src: "/assets/images/app-icons/icon-dashboard-grey.svg",
                },
              }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "nav-icon active" }, [
              _c("img", {
                attrs: {
                  src: "/assets/images/app-icons/icon-dashboard-white.svg",
                },
              }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "nav-text" }, [_vm._v("Dashboard")]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }