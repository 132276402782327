<template>
    <div>
        <hr />
        <p>
            <span>
                Your CSM is:
                <b>{{ csmInfo.name }}</b>
            </span>
        </p>
        <p>
            <span>Email:</span>
            <a :href="'mailto:' + csmInfo.email">
                <b>{{ csmInfo.email }}</b>
            </a>
        </p>
        <p>
            <span>Phone:</span>
            <b>{{ csmInfo.phone }}</b>
        </p>

        <!-- request a CSM call -->
        <div>
            <button class="btn btn-success btn-xs" 
                    :disabled="!hasCalendar"
                    @click="handleRequestAction" >
                Request a CSM Call
            </button>
            <a class="btn btn-success btn-xs text-white text-reset text-decoration-none" 
               target="_blank"
               :href="FORM_URL" >
                Order Training
                <span class="text-xs">(paid)</span>
            </a>
        </div>
        <hr />
    </div>
</template>
<script>
    import { ORDER_TRAINING_FORM_URL } from "../constants"

    export default {
        name: 'CsmInfo',

        props: {
            csmInfo: {
                type: Object,
                default: () => {},
            },
        },

        computed: {
            hasCalendar() {
                return this.csmInfo.calendar_link;
            },
        },

        data() {
            return {
                FORM_URL: ORDER_TRAINING_FORM_URL,
            };
        },

        methods: {
            handleRequestAction() {
                const { calendar_link } = this.csmInfo;
                window.open(calendar_link, '_blank');
                return;
            },
        },
    };
</script>
