<template>
    <div>
        <div v-if="isAdminOrAgentOrSupervisor">
            <!-- Contact Center -->
            <div class="pb-0 pt-0">
                <h5 class="text-dark mb-1">Contact Center Overview</h5>
                <div class="margin mb-0">
                    <span>
                        for Today
                        <updating-date></updating-date>
                        (refresh to update results)
                        <button class="btn btn-xs greenish pointer"
                                :disabled="isLoading"
                                @click="reloadContactCenterAndAgentInfo">
                            <i class="material-icons loader"
                               v-show="isLoading">
                                &#xE863;
                            </i>
                            <i class="material-icons"
                               v-show="!isLoading">refresh</i>
                        </button>
                    </span>
                </div>
            </div>

            <!-- Contact Center -->
            <div class="pb-0 pt-2">
                <el-row :gutter="24">
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/total-calls.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.total_calls"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Total Calls</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/icon-answered-calls-green.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.answered_calls"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Answered Calls</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/missed-calls.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.missed_calls"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Missed Calls</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/abandoned-calls.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.abandoned_calls"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Abandoned Calls</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                </el-row>
            </div>

            <!-- Contact Center -->
            <div class="pb-0 pt-2">
                <el-row :gutter="24">
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/avg-talk-time.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <span v-else>{{ contact_center_summary.average_talk_time | fixFullDuration }}</span>
                                    </h4>
                                    <small class="text-muted text-xs">Average Talk Time</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/avg-wait-time.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <span v-else>{{ contact_center_summary.average_wait_time | fixFullDuration }}</span>
                                    </h4>
                                    <small class="text-muted text-xs">Average Wait Time</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/total-occupancy.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <span v-else>{{ contact_center_summary.total_occupancy | fixFullDuration }}</span>
                                    </h4>
                                    <small class="text-muted text-xs">Total Occupancy</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                </el-row>
            </div>

            <!-- Contact Center -->
            <div class="pb-0 pt-2">
                <el-row :gutter="24">
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6"
                            v-if="shouldShowCalendar">
                        <a href="#"
                           @click.prevent="goToCalendar">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/appointments-set.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.appointments"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Appointments Set</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6"
                            v-if="shouldShowCalendar">
                        <a href="#"
                           @click.prevent="goToCalendar">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/reminders-set.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.reminders"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Reminders Set</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/icon-inbound-communications-green.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.sms_received"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Text Received</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/icon-outbound-communications-green.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.sms_sent"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Text Sent</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                </el-row>
            </div>

            <!-- Contact Center -->
            <div class="pb-0 pt-2">
                <el-row :gutter="24">
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/email-sent.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.emails_sent"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Email Sent</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/email-received.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.emails_received"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Email Received</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/fax-sent.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.faxes_sent"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Faxes Sent</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                    <el-col :xs="24"
                            :sm="12"
                            :md="6"
                            :lg="6"
                            :xl="6">
                        <a href="#"
                           @click.prevent="">
                            <div class="box p-a overview-boxes">
                                <div class="pull-left m-r">
                                    <span class="w-48 text-center">
                                        <img src="/assets/images/app-icons/fax-received.svg">
                                    </span>
                                </div>
                                <div class="pull-left mt-2">
                                    <h4 class="m-a-0 mb-0 text-lg">
                                        <span v-if="loading_contact_center_summary"
                                              class="mockup">1000</span>
                                        <ICountUp
                                            :startVal="count_up.startVal"
                                            :endVal="contact_center_summary.faxes_received"
                                            :duration="count_up.duration"
                                            :options="count_up.options"
                                            v-else
                                        />
                                    </h4>
                                    <small class="text-muted text-xs">Faxes Received</small>
                                </div>
                            </div>
                        </a>
                    </el-col>
                </el-row>
            </div>

            <!-- New Wallboard-->
            <div class="pb-0 pt-3">
                <agents-condensed-summary-report
                    :ring_group_id="ring_group_id"
                    :user_id="user_id">
                </agents-condensed-summary-report>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {
    acl_mixin, 
    calendar_talk_mixin
} from '../mixins'
import {mapActions, mapGetters, mapState} from 'vuex'
import ICountUp from 'vue-countup-v2'
import UpdatingDate from "./updating-date"
import AgentsCondensedSummaryReport from '../components/wallboard/agents-condensed-summary-report'

export default {
    mixins: [
        acl_mixin,
        calendar_talk_mixin
    ],

    components: {
        UpdatingDate,
        ICountUp,
        AgentsCondensedSummaryReport
    },

    props: {
        ring_group_id: {required: false},
        user_id: {required: false},
    },

    data() {
        let easingFn = function (t, b, c, d) {
            let ts = (t /= d) * t
            let tc = ts * t
            return b + c * (tc + -3 * ts + 3 * t)
        }
        return {
            auth: auth,
            profile: auth.user.profile,
            CancelToken: null,
            source: null,
            loading: false,
            loading_contact_center_summary: false,
            text: 'Overall',
            contact_center_summary: {
                abandoned_calls: 0,
                answered_calls: 0,
                appointments: 0,
                average_talk_time: 0,
                average_wait_time: 0,
                emails_received: 0,
                emails_sent: 0,
                faxes_received: 0,
                faxes_sent: 0,
                missed_calls: 0,
                reminders: 0,
                sms_received: 0,
                sms_sent: 0,
                total_calls: 0,
                total_occupancy: 0
            },
            count_up: {
                startVal: 0,
                duration: 2.5,
                options: {
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true,
                },
                decimal_options: {
                    decimalPlaces: 2,
                    suffix: '%',
                    useEasing: true,
                    easingFn: easingFn,
                    useGrouping: true,
                }
            },
        }
    },

    computed: {
        ...mapState('cache', ['current_company']),
        ...mapGetters({
            filter: 'getFilter'
        }),

        isLargeEnough() {
            let valid_sizes = ['xxl', 'xl', 'lg', 'md']
            if (valid_sizes.includes(this.$mq)) {
                return true
            }
            return false
        },

        isLoading() {
            return this.loading || this.loading_contact_center_summary || this.$root.loading_users || this.$root.loading_extensions
        }
    },

    created() {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()
        this.text = 'Overall'
        if (this.user_id) {
            this.text = 'User'
        } else if (this.ring_group_id) {
            this.text = 'Ring Group'
        }
    },

    mounted() {
        this.filter.timezone = window.timezone
        this.filter.ring_group_id = this.ring_group_id
        this.filter.user_id = this.user_id

        // load contact center info on page mount
        this.getContactCenterInfo()
    },

    methods: {
        reloadContactCenterAndAgentInfo() {
            if (this.isLoading) {
                return
            }

            this.loading = true

            // reload the users list
            this.$root.getUsers().then(() => {
                this.loading = false
                this.getContactCenterInfo()
                this.$root.getExtensions()
            })
        },

        getContactCenterInfo() {
            this.loading_contact_center_summary = true
            axios.post('/api/v1/contact-center/summary-report', {
                ring_group_id: this.ring_group_id,
                user_id: this.user_id,
                timezone: window.timezone
            }).then(res => {
                this.loading_contact_center_summary = false
                this.contact_center_summary = res.data
            }).catch(err => {
                this.loading_contact_center_summary = false
                //this.$root.handleErrors(err.response)
                console.log(err)
            })
        },

        goToCalendar() {
            window.location.href = this.getCalendarTalkRedirectURL()
        },

        ...mapActions(['resetFilters'])
    },

    watch: {
        'ring_group_id': function () {
            this.reloadContactCenterAndAgentInfo()
        },

        'user_id': function () {
            this.reloadContactCenterAndAgentInfo()
        },
    },
}
</script>
