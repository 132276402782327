<template>
    <div :class="'header-notification' + ' hn-' + type"
         v-if="visible && !closed">
        <div class="d-flex">
            <div class="flex-grow-1 align-self-center notification-body">
                <slot>
                    There is no notification provided.
                </slot>
            </div>
            <el-button @click="destroy"
                       class="btn-close ml-2"
                       size="small"
                       type="text"
                       v-if="closable">
                <i class="fa fa-close"></i>
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "header-notifications",

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: 'warning'
        }
    },

    data() {
        return {
            closed: false,
        }
    },

    methods: {
        destroy() {
            this.closed = true

            this.$emit('afterClose')
        }
    }
}
</script>

<style scoped>
.notification-body {
    font-size: 13px;
    width: 75%;
}
</style>
