<template>
    <div class="row nav-padding">
        <div class="col-12">
            <div class="pull-left font-120">
                <strong>{{ dataCount }}</strong> {{ dataCount > 1 ? 'Contact dispositions' : 'Contact disposition' }}
            </div>
            <div class="pull-right">
                <disposition-status-creator ref="addContactDisposition"
                                            class="pull-left">
                    <template v-slot:trigger>
                        <button class="btn btn-md rounded greenish mb-2"
                                @click="$refs.addContactDisposition.hide_add = true">
                            <i class="fa fa-plus"></i>
                            Add Contact Disposition
                        </button>
                    </template>
                </disposition-status-creator>
            </div>
        </div>

        <el-form
            ref="contact_disposition_form"
            class="w-full"
            :rules="rules_contact_disposition"
            :model="editingContactDisposition"
            @submit.prevent.native="preventSubmit">
            <el-table
                :data="paginatedData"
                :default-sort="{prop: 'name', order: 'ascending'}"
                v-loading="$root.loading_disposition_statuses"
                class="w-full"
                fit
                stripe>
                <el-table-column
                    prop="id"
                    label="ID"
                    width="100"
                    sortable>
                    <template v-slot="scope">
                        <span class="text-greyish">{{ scope.row.id }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="is_external"
                    sortable
                    :min-width="120">
                    <template v-slot:header>
                        <span>Synced with CRM</span>
                        <el-popover
                            placement="right"
                            width="500"
                            trigger="hover">
                            <div>
                                <strong>Aloware Only</strong>
                                <p>Contact Dispositions are not synced with your CRM, and they are in Aloware only.</p>

                                <strong>CRM & Aloware</strong>
                                <p>Contact Dispositions are in sync with your CRM.</p>

                                <p>
                                    You must manually sync the Contact Dispositions for the new CRM Lead Statuses to appear in Aloware. To do that please go to Integrations > {Your CRM Settings} >
                                    Pull Lead Statuses
                                </p>

                                <p>
                                    If you don’t manually sync, the new CRM Lead Statuses will appear once Aloware performs its daily sync at 6 PM PT.
                                </p>
                            </div>
                            <i
                                class="ml-1 fas fa-info-circle"
                                slot="reference">
                            </i>
                        </el-popover>
                    </template>

                    <template v-slot="scope">
                        {{ scope.row.is_external ? "Yes" : "No" }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="Name"
                    :min-width="200"
                    sortable>
                    <template v-slot="scope">
                        <div class="d-flex">
                            <template>
                                <i
                                    v-if="(!editingContactDisposition || editingContactDisposition.id != scope.row.id) && scope.row.is_external"
                                    class="material-icons"
                                    :style="{ color: scope.row.color }">
                                    lock
                                </i>
                                <i v-else-if="(!editingContactDisposition || editingContactDisposition.id != scope.row.id) && !scope.row.is_external"
                                   class="material-icons"
                                   :style="{ color: scope.row.color }">
                                    label
                                </i>
                                <el-color-picker
                                    v-if="editingContactDisposition && editingContactDisposition.id == scope.row.id"
                                    v-model="editingContactDisposition.color"
                                    class="d-block"
                                    @active-change="updateContactDispositionColor">
                                </el-color-picker>
                            </template>

                            <span
                                v-if="!editingContactDisposition || editingContactDisposition.id != scope.row.id || scope.row.is_external"
                                class="text-greyish ml-2 align-self-center"
                                :title="scope.row.name">
                                {{ scope.row.name }}
                            </span>
                            <el-form-item
                                class="ml-2 flex-fill"
                                prop="name"
                                v-else>
                                <el-input
                                    v-model="editingContactDisposition.name"
                                    placeholder="Name">
                                </el-input>
                            </el-form-item>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="description"
                    label="Description"
                    :min-width="200"
                    sortable>
                    <template v-slot="scope">
                        <span
                            v-if="!editingContactDisposition || editingContactDisposition.id != scope.row.id"
                            class="text-greyish">
                            {{ scope.row.description || '-' }}
                        </span>
                        <el-form-item
                            v-else
                            prop="description">
                            <el-input
                                v-model="editingContactDisposition.description"
                                placeholder="Description"
                                type="textarea"
                                rows="2">
                            </el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="contacts_count"
                    label="Number Of Contacts"
                    :min-width="250"
                    sortable>
                    <template v-slot="scope">
                        <i
                            v-if="!loaded_contact_disposition.includes(scope.row.id)"
                            class="fa fa-spin fa-spinner pull-left">
                        </i>
                        <button
                            v-if="loaded_contact_disposition.includes(scope.row.id)"
                            class="btn b-bluish text-bluish btn-sm"
                            @click="showContacts(scope.row.id)">
                            Show Contacts
                            <b class="label rounded danger m-l-xs">{{ scope.row.contacts_count }}</b>
                        </button>
                    </template>
                </el-table-column>
                <el-table-column
                    label="Operations"
                    align="center"
                    width="150"
                    fixed="right">
                    <template v-slot="scope">
                        <div>
                            <div v-if="!editingContactDisposition || editingContactDisposition.id != scope.row.id">
                                <div class="row"
                                     v-if="hasPermissionTo('update disposition status')">
                                    <div class="col-12">
                                        <button
                                            class="btn btn-sm btn-block info"
                                            @click="editDispositionStatus(scope.row)">
                                            <i class="fa fa-pencil pull-left"></i>
                                            Edit
                                        </button>
                                    </div>
                                </div>

                                <div
                                    v-if="hasPermissionTo('delete disposition status') && !scope.row.is_external"
                                    class="row mt-2">
                                    <div class="col-12">
                                        <button
                                            class="btn btn-sm btn-block danger"
                                            @click="deleteContactDispositionRemote(scope.row.id)">
                                            <i class="material-icons loader pull-left"
                                               v-show="loading_delete_contact_disposition == scope.row.id">&#xE863;</i>
                                            <i class="fa fa-trash pull-left"
                                               v-show="!loading_delete_contact_disposition"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="row">
                                    <div class="col-12">
                                        <button
                                            class="btn btn-sm btn-block primary"
                                            :disabled="loading_btn"
                                            v-if="hasPermissionTo('update disposition status')"
                                            @click="saveContactDisposition">
                                            <i
                                                class="material-icons loader pull-left"
                                                v-show="loading_btn">&#xE863;</i>
                                            <i
                                                class="fa fa-save pull-left"
                                                v-show="!loading_btn"></i>
                                            Save
                                        </button>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-12">
                                        <button
                                            class="btn btn-sm btn-block danger"
                                            @click="cancelEditContactDisposition">
                                            <i class="fa fa-close pull-left"></i>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>
        <div class="mt-3"
             v-if="pageCount >= 1">
            <el-pagination
                class="pull-right"
                background
                layout="sizes, prev, pager, next"
                :page-sizes="[10, 20, 50, 100, 250]"
                :page-size="20"
                :current-page.sync="pagination.current_page"
                :total="dataCount"
                @current-change="changePage"
                @size-change="handleSizeChange">
            </el-pagination>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapActions, mapState} from 'vuex'
import {
    acl_mixin,
    paginator_mixin,
    form_validation_mixin
} from '../mixins'
import DispositionStatusCreator from "../components/disposition-status-creator"

export default {
    mixins: [
        acl_mixin,
        paginator_mixin,
        form_validation_mixin
    ],

    components: {
        DispositionStatusCreator
    },

    data() {
        return {
            reporting_url: localStorage.getItem('reporting_url'),
            auth: auth,
            company: null,
            loading_delete: false,
            loading: false,
            loading_btn: false,
            loading_delete_contact_disposition: null,
            loaded_contact_disposition: [],
            editingContactDisposition: null,
            dataKey: 'contactDispositionAlphabeticalOrder',
            rules_contact_disposition: {
                name: [
                    {
                        required: true,
                        message: 'Please provide a contact disposition name',
                        trigger: 'blur'
                    },
                    {
                        max: 190,
                        message: 'Name is too long'
                    }
                ],
                description: [
                    {
                        max: 190,
                        message: 'Description is too long'
                    }
                ],
            }
        }
    },

    computed: {
        ...mapState(['disposition_statuses', 'disposition_statuses_loaded']),
        ...mapState('cache', ['current_company']),

        contactDispositionAlphabeticalOrder() {
            if (this.disposition_statuses) {
                return this.disposition_statuses.sort((a, b) => {
                    let textA = a.name.toUpperCase()
                    let textB = b.name.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                })
            }

            return []
        },
    },

    created() {
        // in case event has already been dispatched, this will be the fallback
        if (this.disposition_statuses_loaded) {
            this.getFullContactDisposition()
        }

        VueEvent.listen('disposition_status_loaded', () => {
              this.getFullContactDisposition()
        })
    },

    methods: {
        cancelEditContactDisposition() {
            this.editingContactDisposition = null
        },

        editDispositionStatus(disposition_status) {
            this.editingContactDisposition = {
                id: disposition_status.id,
                color: disposition_status.color,
                name: disposition_status.name,
                description: disposition_status.description
            }
        },

        showContacts(contact_disposition_id) {
            this.$router.push({
                name: 'Contacts',
                query: {
                    disposition_status_id: contact_disposition_id
                }
            }).catch(err => {
            })
        },

        getFullContactDisposition() {
            axios.get(this.reporting_url + '/api/v1/disposition-status-extended', {params: {extended_form: true}})
                .then(res => {
                    res.data.forEach((contact_disposition) => {
                        this.disposition_statuses.map((original_contact_disposition) => {
                            if (contact_disposition.id == original_contact_disposition.id) {
                                this.loaded_contact_disposition.push(original_contact_disposition.id)
                                original_contact_disposition['contacts_count'] = contact_disposition.contacts_count
                            }
                            return original_contact_disposition
                        })
                    })
                })
                .catch(err => {
                    console.log(err.response)
                    this.$root.handleErrors(err.response)
                })
        },

        updateContactDispositionColor(val) {
            this.editingContactDisposition.color = val
        },

        saveContactDisposition() {
            if (!this.validateForm('contact_disposition_form')) {
                this.preValidateForm('contact_disposition_form')
                return
            }

            this.loading_btn = true
            axios.patch('/api/v1/disposition-status/' + this.editingContactDisposition.id, this.editingContactDisposition)
                .then(res => {
                    this.loading_btn = false
                    this.updateDispositionStatus(res.data)
                    this.$notify({
                        offset: 95,
                        title: 'Account',
                        message: 'Contact disposition has been successfully updated.',
                        type: 'success',
                        showClose: true,
                    })
                    this.cancelEditContactDisposition()
                })
                .catch(err => {
                    this.$root.handleErrors(err.response)
                    this.loading_btn = false
                    this.cancelEditContactDisposition()
                })
        },

        deleteContactDispositionRemote: function (contact_disposition_id) {
            if (this.disposition_statuses) {
                let disposition_status = this.disposition_statuses.find(disposition_status => disposition_status.id === contact_disposition_id)
                this.$confirm('Deleting a contact disposition will remove it from all contacts. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    customClass: 'width-500 fixed',
                    type: 'warning'
                }).then(() => {
                    this.loading_delete_contact_disposition = contact_disposition_id
                    axios.delete('/api/v1/disposition-status/' + contact_disposition_id)
                        .then(res => {
                            this.deleteDispositionStatus(disposition_status)
                            this.$notify({
                                offset: 95,
                                title: 'Account',
                                message: 'Contact disposition has been deleted.',
                                type: 'success',
                                showClose: true,
                            })
                            this.loading_delete_contact_disposition = null
                        })
                        .catch(err => {
                            this.$root.handleErrors(err.response)
                            this.loading_delete_contact_disposition = null
                        })
                }).catch(() => {

                })
            }
        },

        preventSubmit() {
            return
        },

        ...mapActions(['updateDispositionStatus', 'deleteDispositionStatus']),
    }
}
</script>
