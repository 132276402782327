<template>
    <div class="ordered-user-team-selector"
         v-if="hasPermissionTo('list user')">
        <div class="text-center">
            <i class="fas fa-arrow-down" style="font-size: 30px;color: #acacac"></i>
        </div>
        <draggable tag="ul"
                   class="list-ring-group list-unstyled"
                   handle=".handle"
                   :list="list"
                   @change="changed">
            <li v-for="(element, idx) in list"
                :key="idx">
                <div class="list-ring-group-item d-flex align-items-center">
                    <el-tooltip class="item"
                                effect="dark"
                                content="Drag up or down to change the order of the ring group layers"
                                placement="top">
                        <el-button size="small"
                                   class="handle mr-3 draggable">
                            <i class="fas fa-arrows-alt-v"></i>
                        </el-button>
                    </el-tooltip>
                    <div class="layer-label">Layer {{ (idx + 1) }}</div>
                    <div class="w-100">
                        <div class="d-flex align-items-center">
                            <div class="select-label">Users:</div>
                            <el-tooltip class="item"
                                        effect="dark"
                                        content="Select one or more users for this layer."
                                        placement="top">
                                <el-select placeholder="Add one or more users"
                                           class="flex-grow-1 w-100"
                                           multiple
                                           filterable
                                           default-first-option
                                           :disabled="loading"
                                           :filter-method="filterOptions"
                                           v-model="list[idx].user_ids"
                                           @change="changed"
                                           @visible-change="resetFilteredText">
                                    <el-option-group key="Users"
                                                     label="Users"
                                                     v-if="normalUsers && normalUsers.length > 0">
                                        <el-option class="p-0"
                                                   :key="`user-${user.id}`"
                                                   :label="user.name"
                                                   :value="user.id"
                                                   v-for="user in normalUsers">
                                            <div class="media">
                                                <div class="media-body">
                                                    <label>{{ user.name }}</label>
                                                    <small>{{ user.email }} - {{ getLabel(user) }}</small>
                                                </div>
                                            </div>
                                        </el-option>
                                    </el-option-group>
                                    <el-option-group key="Extensions"
                                                     label="Extensions"
                                                     v-if="extensionUsers && extensionUsers.length > 0">
                                        <el-option class="p-0"
                                                   :key="`user-${user.id}`"
                                                   :label="user.name"
                                                   :value="user.id"
                                                   v-for="user in extensionUsers">
                                            <div class="media">
                                                <div class="media-body">
                                                    <label>{{ user.name }}</label>
                                                    <small>{{ getLabel(user) }}</small>
                                                </div>
                                            </div>
                                        </el-option>
                                    </el-option-group>
                                    <el-option-group label="Paused Users"
                                                     v-if="pausedUsers.length">
                                        <el-option class="p-0"
                                                   :key="`user-${user.id}`"
                                                   :label="user.name"
                                                   :value="user.id"
                                                   v-for="user in pausedUsers">
                                            <div class="media">
                                                <div class="media-body text-danger">
                                                    <label>{{ user.name }}</label>
                                                    <small>{{ user.email }} - {{ getLabel(user) }}</small>
                                                </div>
                                            </div>
                                        </el-option>
                                    </el-option-group>
                                    <el-option-group label="Paused Extensions"
                                                     v-if="pausedExtensions.length">
                                        <el-option class="p-0"
                                                   :key="user.id"
                                                   :label="user.name"
                                                   :value="user.id"
                                                   v-for="user in pausedExtensions">
                                            <div class="media">
                                                <div class="media-body text-danger">
                                                    <label>{{ user.name }}</label>
                                                    <small>{{ getLabel(user) }}</small>
                                                </div>
                                            </div>
                                        </el-option>
                                    </el-option-group>
                                </el-select>
                            </el-tooltip>
                        </div>

                        <div class="d-flex align-items-center mt-3">
                            <div class="select-label">Teams:</div>
                            <el-tooltip class="item"
                                        effect="dark"
                                        content="Select one or more teams for this layer."
                                        placement="top">
                                <el-select placeholder="Add one or more teams"
                                           class="teams-select flex-grow-1 w-100"
                                           multiple
                                           filterable
                                           default-first-option
                                           popper-class="teams-select-popper"
                                           :disabled="loading"
                                           :filter-method="filterOptions"
                                           v-model="list[idx].team_ids"
                                           @change="changed"
                                           @visible-change="resetFilteredText">
                                    <el-option-group label="Teams"
                                                     v-if="filteredTeams && filteredTeams.length > 0">
                                        <el-option class="p-0"
                                                   :key="team.id"
                                                   :label="team.name"
                                                   :value="team.id"
                                                   v-for="team in filteredTeams">
                                            <div class="media">
                                                <div class="media-body">
                                                    <label>{{ team.name }}</label>
                                                </div>
                                            </div>
                                        </el-option>
                                    </el-option-group>
                                </el-select>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center ml-3">
                        <el-tooltip class="item d-block mb-3"
                                    effect="dark"
                                    :content="tooltipMessage(list[idx].user_ids)"
                                    placement="top"
                                    v-if="ringGroup.id">
                            <dialog-team-creator :disabled="disabledDialogTeam"
                                                 :ringGroupName="ringGroup.name"
                                                 :layer="idx+1"
                                                 :teams="selectedTeams(list[idx].team_ids)"
                                                 :users="selectedUsers(list[idx].user_ids)"
                                                 @addToTeam="addRingGroupToTeam($event, idx)"/>
                        </el-tooltip>
                        <el-tooltip class="item d-block"
                                    effect="dark"
                                    content="Remove this layer"
                                    placement="top">
                            <i class="fa fa-times close text-danger"
                               v-visible="idx > 0"
                               @click="removeAt(idx)">
                            </i>
                        </el-tooltip>
                    </div>
                </div>
                <div class="text-center">
                    <i class="fas fa-arrow-down"
                       style="font-size: 30px;color: #acacac"></i>
                </div>
            </li>
        </draggable>

        <div class="text-center">
            <el-button type="success"
                       plain
                       @click.prevent="add">
                <i class="fa fa-plus"></i>
                Add Another Layer
            </el-button>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {mapState} from 'vuex'
import {
    acl_mixin,
    user_info_mixin,
    teams_mixin
} from '../mixins'
import draggable from 'vuedraggable'
import * as AnswerTypes from '../constants/answer-types'
import DialogTeamCreator from './dialog-team-creator'

export default {
    mixins: [
        acl_mixin,
        user_info_mixin,
        teams_mixin
    ],

    components: {
        DialogTeamCreator,
        draggable,
    },

    props: {
        ordered_user_ids_value: {
            type: Object | Array,
            default: () => ({})
        },

        ordered_team_ids_value: {
            type: Object | Array,
            default: () => ({})
        },

        disabledDialogTeam: {
            type: Boolean,
            default: false
        },

        ringGroup: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            ordered_user_ids: this.ordered_user_ids_value,
            ordered_team_ids: this.ordered_team_ids_value,
            list: [],
            loading: false,
            dragging: false,
            filtered_text: null,
            active_tab: 'users',
            AnswerTypes
        }
    },

    computed: {
        ...mapState({
            current_company: state => state.cache.current_company
        }),

        filteredUsers() {
            if (this.users) {
                let filtered_users = this.users.filter((user) =>
                    !(user.role_names && user.role_names.length === 1 && user.read_only_access) &&
                    user.answer_by !== AnswerTypes.BY_NONE && user.enabled
                )

                if (this.filtered_text) {
                    return filtered_users.filter((user) =>
                        (user.name && user.name.toLowerCase().includes(this.filtered_text.toLowerCase())) ||
                        (user.phone_number && user.phone_number.includes(this.filtered_text)) ||
                        (user.email && user.email.toLowerCase().includes(this.filtered_text.toLowerCase()))
                    )
                }

                return filtered_users
            }

            return []
        },

        normalUsers() {
            return this.filteredUsers.filter((user) => {
                return !user.is_destination && user.active
            })
        },

        extensionUsers() {
            return this.filteredUsers.filter((user) => {
                return user.is_destination && user.active
            })
        },

        pausedUsers() {
            return this.filteredUsers.filter((user) => {
                return !user.is_destination && !user.active
            })
        },

        pausedExtensions() {
            return this.filteredUsers.filter((user) => {
                return user.is_destination && !user.active
            })
        },

        filteredTeams() {
            if (this.all_teams && this.filtered_text) {
                return this.all_teams.filter((team) =>
                    team.name.toLowerCase().includes(this.filtered_text.toLowerCase())
                )
            }

            return this.all_teams
        }
    },

    created() {
        this.fixList()
        this.$on('fixList', this.fixList)
    },

    methods: {
        getLabel(user) {
            if (!user) {
                return
            }

            switch (user.answer_by) {
                case AnswerTypes.BY_PHONE_NUMBER:
                    return 'Phone Number (' + user.phone_number + ')'
                case AnswerTypes.BY_BROWSER:
                    return 'Browser / Apps'
                case AnswerTypes.BY_IP_PHONE:
                    return 'SIP (IP Phone)'
                case AnswerTypes.BY_NONE:
                    return 'Will Not Answer'
            }
        },

        removeAt(idx) {
            this.list.splice(idx, 1)
        },

        add() {
            this.list.push([])
        },

        fixList() {
            let keys = [...new Set([
                ...Object.keys(this.ordered_user_ids),
                ...Object.keys(this.ordered_team_ids)
            ])];

            keys.sort((a, b) => a - b);

            keys.forEach((key) => {
                if (!this.ordered_user_ids[key]) {
                    this.ordered_user_ids[key] = []
                }
                if (!this.ordered_team_ids[key]) {
                    this.ordered_team_ids[key] = []
                }
            })

            this.list = Array.from(keys).map((key) => {
                return {
                    user_ids: this.ordered_user_ids[key],
                    team_ids: this.ordered_team_ids[key]
                }
            })
        },

        changed() {
            let results = {}

            for (let i = 0; i < this.list.length; i++) {
                results[i + 1] = this.list[i]
            }

            this.ordered_user_ids = Object.keys(results).reduce((acc, key) => {
                acc[key] = results[key].user_ids
                return acc
            }, {})

            this.ordered_team_ids = Object.keys(results).reduce((acc, key) => {
                acc[key] = results[key].team_ids
                return acc
            }, {})

            this.$emit('change', {
                ordered_user_ids: this.ordered_user_ids,
                ordered_team_ids: this.ordered_team_ids
            })
        },

        addRingGroupToTeam (teamName, idx) {
            const ordered_team_user_ids = {
                [idx+1] : this.list[idx].user_ids
            }

            this.$emit('ringGroupToTeam', ordered_team_user_ids, teamName)
        },

        tooltipMessage (list) {
            return list?.length > 0 ? 'Convert users into a Team' : 'Add users to convert to a Team'
        },

        filterOptions(filterValue) {
            this.filtered_text = filterValue
        },

        resetFilteredText() {
            this.filtered_text = null
        },

        selectedUsers (usersIds = []) {
            return this.filteredUsers.filter((user) => usersIds.includes(user.id))
        },

        selectedTeams (teamsIds = []) {
            return this.filteredTeams.filter((team) => teamsIds.includes(team.id))
        }
    },

    watch: {
        ordered_user_ids_value(val) {
            this.ordered_user_ids = val
        },

        ordered_team_ids_value(val) {
            this.ordered_team_ids = val
        },

        list: {
            handler(val) {
                this.changed()
            },
            deep: true
        }
    }
}
</script>
