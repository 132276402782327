var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-blank alo-default-app-selector-dialog",
      attrs: {
        width: "550px",
        "append-to-body": "",
        "before-close": _vm.closeDialog,
        visible:
          _vm.visible &&
          !_vm.shouldForceTalk &&
          !_vm.hasRole("Company Admin") &&
          !_vm.isSimpSocial,
        "close-on-click-modal": false,
      },
      on: {
        close: function ($event) {
          return _vm.closeNotifier(_vm.AppTypes.APP_ALOWARE_CLASSIC)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "alo-default-app-selector-body" },
        [
          _c("alo-talk-info"),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c(
                  "el-button",
                  {
                    staticClass:
                      "w-100 d-flex justify-content-center align-items-center",
                    attrs: { size: "large" },
                    on: {
                      click: function ($event) {
                        return _vm.closeNotifier(
                          _vm.AppTypes.APP_ALOWARE_CLASSIC
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Continue to " +
                        _vm._s(_vm.alowareClassic) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c(
                  "el-button",
                  {
                    staticClass:
                      "w-100 d-flex justify-content-center align-items-center",
                    attrs: { size: "large", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.closeNotifier(_vm.AppTypes.APP_ALOWARE_TALK)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.buttonText) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }