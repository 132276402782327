var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.notEmptyEnabledFilters
    ? _c("div", { staticClass: "py-2" }, [
        _c(
          "div",
          { staticClass: "col-12 p-0" },
          [
            _c("small", [_vm._v("Active Filters:")]),
            _vm._v(" "),
            _vm._l(_vm.enabled_filters_text, function (filter, index) {
              return (typeof filter.value !== "object" && filter.value) ||
                (typeof filter.value === "object" &&
                  filter.value &&
                  filter.value.length)
                ? _c(
                    "span",
                    {
                      staticClass:
                        "btn btn-xs indigo-A200 mr-1 mb-1 text-left white-space-normal",
                      staticStyle: { "max-width": "100%" },
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm._f("ucwords")(filter.name))),
                      ]),
                      _vm._v(" "),
                      _vm._l(filter.value, function (value) {
                        return !_vm.flag_filters.includes(filter.name)
                          ? _c(
                              "b",
                              {
                                staticClass:
                                  "white-space-initial d-inline label label-sm rounded success m-l-xs",
                              },
                              [
                                typeof value == "string"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm._f("capitalize")(value))
                                      ),
                                    ])
                                  : _c("span", [_vm._v(_vm._s(value))]),
                              ]
                            )
                          : _vm._e()
                      }),
                    ],
                    2
                  )
                : _vm._e()
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }