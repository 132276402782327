var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: { placement: "right-start", width: "220", trigger: "click" },
      model: {
        value: _vm.show_popover,
        callback: function ($$v) {
          _vm.show_popover = $$v
        },
        expression: "show_popover",
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-form-item",
            { staticClass: "mb-3" },
            [
              _c(
                "div",
                {
                  staticClass: "form-label text-dark",
                  attrs: { scope: "label" },
                },
                [
                  _c("h6", { staticClass: "mb-2 text-black" }, [
                    _vm._v("Split tag"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "lh-1p4" }, [
                    _vm._v(
                      "\n                    Split tag into smaller tags.\n                "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-label text-dark",
                  attrs: { scope: "label" },
                },
                [
                  _c("h6", { staticClass: "mb-2 text-black" }, [
                    _vm._v("Page size:"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.option_selected,
                    callback: function ($$v) {
                      _vm.option_selected = $$v
                    },
                    expression: "option_selected",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.allowSplit,
                      expression: "!allowSplit",
                    },
                  ],
                  staticClass: "text-red",
                },
                [
                  _vm._v(
                    "\n                Contact count is less than or equal to page size.\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "w-full",
                  attrs: {
                    type: "success",
                    size: "small",
                    disabled: !_vm.allowSplit,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.splitTag()
                    },
                  },
                },
                [_vm._v("\n                Split\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-block btn-warning btn-sm text-white bg-deep-orange",
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [
          _c("i", { staticClass: "fas fa-clone pull-left" }),
          _vm._v(" "),
          _vm._t("default", function () {
            return [_vm._v("Split")]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }