var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.filter_options.filters_only
    ? _c("div", [
        _c("div", { staticClass: "nav-active-greenish" }, [
          _c(
            "ul",
            { staticClass: "nav nav-tabs" },
            [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "border border-right-0 rounded-top-left",
                    class: _vm.filtersNavClass.tag,
                    attrs: {
                      href: "",
                      "data-toggle": "tab",
                      "aria-expanded": "false",
                      "data-target": _vm.tag_selector.target,
                    },
                    on: { click: _vm.resetContactFiltersSelector },
                  },
                  [_vm._v("\n                    By Tag\n                ")]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "border border-right-0 rounded-0",
                    class: _vm.filtersNavClass.filter,
                    attrs: {
                      href: "",
                      "data-toggle": "tab",
                      "aria-expanded": "true",
                      "data-target": _vm.contact_filter.target,
                    },
                    on: { click: _vm.resetTagSelector },
                  },
                  [_vm._v("\n                    Filter\n                ")]
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _vm.shouldShowIntegrations
                  ? _c(
                      "a",
                      {
                        staticClass: "border rounded-top-right",
                        class: _vm.filtersNavClass.filter,
                        attrs: {
                          href: "",
                          "data-toggle": "tab",
                          "aria-expanded": "true",
                          "data-target": _vm.integrations.target,
                        },
                        on: { click: _vm.resetSelectors },
                      },
                      [
                        _vm._v(
                          "\n                    Integrations\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("el-popover", {
                ref: "popover_contacts",
                staticClass: "ml-2 p-0",
                attrs: {
                  placement: "right",
                  title: "Contacts",
                  width: "400",
                  trigger: "hover",
                  content:
                    "Filter group of contacts that will receive the bulk message by using the tag selector.",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tab-content p-a border" }, [
            _c(
              "div",
              {
                class: _vm.filtersContainerClass.tag,
                attrs: { id: "by-tags", "aria-expanded": "false" },
              },
              [
                _c("tag-selector", {
                  attrs: {
                    value: null,
                    multiple: false,
                    clearable: true,
                    category_prop: _vm.tag_category,
                  },
                  on: { change: _vm.contactTagsGroupChanged },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "clearfix",
                class: _vm.filtersContainerClass.filter,
                attrs: { id: "by-filters", "aria-expanded": "true" },
              },
              [
                _c("contact-advanced-filters-wrapper", {
                  attrs: {
                    custom_class: "pull-left",
                    default_date_range: _vm.default_date_range,
                    broadcast: true,
                  },
                  on: {
                    changed: _vm.contactFilterChanged,
                    resetFilters: _vm.resetContactFilters,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.shouldShowIntegrations
              ? _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    class: _vm.filtersContainerClass.integration,
                    attrs: { id: "by-integrations", "aria-expanded": "true" },
                  },
                  [
                    _vm.hubspotEnabled
                      ? _c(
                          "el-form",
                          {
                            ref: "add_hubspot_list",
                            attrs: {
                              "label-width": "100px",
                              "label-position": "top",
                              model: _vm.hubspot_list,
                              rules: _vm.rules_hubspot_list,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "mb-3",
                                attrs: {
                                  prop: "list_id",
                                  label: "Select a HubSpot list",
                                },
                              },
                              [
                                _c("hubspot-list-selector", {
                                  attrs: { value: _vm.hubspot_list.list_id },
                                  on: { selectedList: _vm.selectedListChanged },
                                  model: {
                                    value: _vm.hubspot_list.list_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.hubspot_list, "list_id", $$v)
                                    },
                                    expression: "hubspot_list.list_id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.zohoEnabled
                      ? _c(
                          "el-form",
                          {
                            ref: "add_zoho_view",
                            attrs: {
                              "label-width": "100px",
                              "label-position": "top",
                              model: _vm.zoho_view,
                              rules: _vm.rules_zoho_view,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "mb-3",
                                attrs: {
                                  prop: "view_id",
                                  label: "Select a Zoho custom view",
                                },
                              },
                              [
                                _c("zoho-view-selector", {
                                  attrs: { value: _vm.zoho_view.view_id },
                                  on: { selectedView: _vm.selectedViewChanged },
                                  model: {
                                    value: _vm.zoho_view.view_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.zoho_view, "view_id", $$v)
                                    },
                                    expression: "zoho_view.view_id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pipedriveEnabled
                      ? _c(
                          "el-form",
                          {
                            ref: "add_pipedrive_filter",
                            attrs: {
                              "label-width": "100px",
                              "label-position": "top",
                              model: _vm.pipedrive_filter,
                              rules: _vm.rules_pipedrive_filter,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "mb-3",
                                attrs: {
                                  prop: "filter_id",
                                  label: "Select a Pipedrive list",
                                },
                              },
                              [
                                _c("pipedrive-filter-selector", {
                                  attrs: {
                                    value: _vm.pipedrive_filter.filter_id,
                                  },
                                  on: {
                                    selectedFilter: _vm.selectedFilterChanged,
                                  },
                                  model: {
                                    value: _vm.pipedrive_filter.filter_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pipedrive_filter,
                                        "filter_id",
                                        $$v
                                      )
                                    },
                                    expression: "pipedrive_filter.filter_id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "popover",
                rawName: "v-popover:popover_contacts",
                arg: "popover_contacts",
              },
            ],
            staticClass: "form-hover-help",
          },
          [_vm._v("\n        What is this?\n    ")]
        ),
      ])
    : _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("contact-advanced-filters-wrapper", {
              attrs: {
                custom_class: "pull-left",
                hide_reset_filter: true,
                broadcast: true,
                default_date_range: _vm.default_date_range,
              },
              on: {
                changed: _vm.contactFilterChanged,
                resetFilters: _vm.resetContactFilters,
              },
            }),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }