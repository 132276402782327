var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show_help,
            title: "What are tags?",
            top: "10vh",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_help = $event
            },
          },
        },
        [
          _c("p", { staticClass: "text-md _600 break-word" }, [
            _vm._v(
              "Tags help you categorize and segment your audience in a way like Lists,\n            but with the added benefit of having the same person in multiple places."
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-md _600 break-word" }, [
            _vm._v(
              "The entire audience of a Tag can be enrolled in a sequence. Your contact imports show up as a new tag with the date of upload."
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("delete-tag-contacts-dialog", {
        attrs: {
          show_hide_dialog: _vm.dialog_visibility,
          tag_id: _vm.tag.id,
          tagged_contacts_count: _vm.tag.tagged_contacts_count,
        },
        on: {
          "toggle-dialog": _vm.toggleDialog,
          "dialog-delete-btn-clicked": _vm.deleteTagRemote,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "fixed-header padding pt-0 pb-0",
          style: { top: _vm.top_offset + 50 + "px" },
        },
        [
          _c("div", { staticClass: "row mb-0 mt-0" }, [
            _c("div", { staticClass: "d-flex flex-fill flex-wrap p-2" }, [
              _c(
                "div",
                { staticClass: "d-flex flex-fill flex-wrap mr-auto pr-xl-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mr-sm-2 flex-fill flex-xl-grow-0 search-toolbar",
                    },
                    [
                      _c("el-input", {
                        ref: "searchBar",
                        attrs: {
                          placeholder: "Search",
                          "prefix-icon": "el-icon-search",
                          clearable: "",
                          autofocus: "",
                        },
                        on: { input: _vm.search },
                        model: {
                          value: _vm.search_text,
                          callback: function ($$v) {
                            _vm.search_text = $$v
                          },
                          expression: "search_text",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "no-select mt-2 mt-md-0 mr-2 flex-sm-shrink-0",
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "no-select",
                          on: { change: _vm.onFilterChange },
                          model: {
                            value: _vm.tag_filter,
                            callback: function ($$v) {
                              _vm.tag_filter = $$v
                            },
                            expression: "tag_filter",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "communication" } },
                            [_vm._v("Communication Tags")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "contact" } },
                            [_vm._v("Contact Tags")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "import" } },
                            [_vm._v("Import Tags")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-wrap" },
                [
                  _c("tag-creator", {
                    ref: "addTag",
                    staticClass: "mr-2 mt-2 mt-xl-0",
                    attrs: { id: "add-tag" },
                    on: { success: _vm.addTag },
                    scopedSlots: _vm._u([
                      {
                        key: "trigger",
                        fn: function () {
                          return [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-xl rounded greenish h-100",
                                on: { click: _vm.hideAddTagButton },
                              },
                              [
                                _c("i", { staticClass: "fa fa-plus" }),
                                _vm._v(
                                  "\n                                Add Tag\n                            "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("hubspot-import-list", {
                    ref: "importHubspotList",
                    staticClass: "mr-2 mt-2 mt-xl-0",
                    attrs: { id: "import-hubspot-list" },
                    scopedSlots: _vm._u([
                      {
                        key: "trigger",
                        fn: function () {
                          return [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-xl rounded bluish",
                                on: { click: _vm.hideImportHubspotListButton },
                              },
                              [
                                _c("i", { staticClass: "fa fa-plus" }),
                                _vm._v(
                                  "\n                                Import HubSpot List\n                            "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.isTrial && !_vm.isSimpSocial
                    ? _c("video-modal-activator", { staticClass: "pr-2" })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isCompanyKYC
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 mt-xl-0 mr-2 mr-xl-0 d-flex flex-column justify-content-center text-blue",
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons text-lg cursor-pointer",
                              on: { click: _vm.toggleHelp },
                            },
                            [
                              _vm._v(
                                "\n                            info_outline\n                        "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "padding mt-5 pt-3" }, [
        _vm.hasPermissionTo("list tag")
          ? _c("div", [
              _c("div", { staticClass: "row box" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "no-effect" }, [
                    _c(
                      "div",
                      { staticClass: "box-header" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("span", { staticClass: "pull-left font-120" }, [
                              _c("strong", [_vm._v(_vm._s(_vm.tags_count))]),
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(_vm.tags_count > 1 ? "tags" : "tag") +
                                  "\n                                    "
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.shouldShowTagsDeprecationBanner
                          ? _c("al-alert", [
                              _c("span", {
                                staticClass: "mr-5 text-dark",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.TAGS_DEPRECATION_MESSAGE
                                  ),
                                },
                              }),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "box-body" },
                      [
                        _c("tags-table", {
                          ref: "tags_table",
                          attrs: {
                            tag_filter: _vm.tag_filter,
                            search_text: _vm.search_text,
                          },
                          on: {
                            "toggle-dialog-triggered":
                              _vm.onToggleDialogTriggered,
                            "tags-count": _vm.setTagsCount,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("el-backtop", {
        ref: "backtop",
        attrs: { target: "html", bottom: 20, right: 20 },
      }),
      _vm._v(" "),
      _vm.isTrial && !_vm.isSimpSocial
        ? _c("video-modal", {
            staticClass: "pl-2",
            attrs: {
              title:
                "🏷️ Mastering Aloware Tags: Organize Your Contacts Effectively",
              "cookie-name": "tags",
              notes:
                "🌟 <strong>Tags</strong> = Your Ultimate Categorizer! </br></br> 🌟 Like Lists, but leveled up! Place the same person everywhere. </br></br> Bonus? Enroll a whole Tag audience in a sequence. </br></br> Plus, new contacts? They flash in with a fresh tag stamped with their upload date. 🚀",
              "video-url":
                "https://www.youtube.com/embed/VUYVx0XGeLc?si=EgN3EQJBGay15kGz",
              "learn-more-link":
                "https://support.aloware.com/en/articles/9031892-tags-in-aloware-admin",
              "has-activator-button": true,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }