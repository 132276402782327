<template>
    <el-button size="small"
               :disabled="disabled"
               @click="changeState">
        <i class="fas fa-arrows-alt-h"
           v-if="state === 0"></i>
        <i class="fas fa-arrow-left"
           v-if="state === 1"></i>
        <i class="fas fa-arrow-right"
           v-if="state === 2"></i>
    </el-button>
</template>

<script>
export default {
    props: {
        initialState: {
            type: Number,
            default: 1, // Bi-directional as default
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            state: (this.initialState - 1),
        }
    },
    watch: {
        initialState(newValue) {
            if (newValue - 1 !== this.state) {
                this.state = newValue - 1
            }
        }
    },
    methods: {
        changeState() {
            this.state = (this.state + 1) % 3
            // states are 1, 2, 3
            this.$emit('change', this.state + 1)
        },
    },
}
</script>
