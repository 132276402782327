<template>
    <div class="custom-message-display" 
         data-testid="custom-message-display-wrapper">
        {{ config.prefix }}
        <router-link v-if="config.showLink" :to="config.linkRoute">
            <u data-testid="config-link-text">{{ config.linkText }}</u>
        </router-link>
        <span class="suffix"
              data-testid="config-suffix"
              :class="{ 'negative--margin': shouldUseNegativeMargin }">
            {{ config.suffix }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'CustomMessageDisplay',

    props: {
        config: {
            type: Object,
            required: true,
            default: () => {
                return {
                    prefix: '',
                    suffix: '',
                    showLink: false,
                    linkText: '',
                    linkRoute: null,
                };
            },
        }
    },

    computed: {
        shouldUseNegativeMargin() {
            return this.config.suffix === '.'
        }
    }
};
</script>
