// KYC form not submitted, aka “clicking around“
export const KYC_STATUS_ONE = 1

export const KYC_STATUS_REJECTED = 2

export const KYC_STATUS_PENDING = 3

export const KYC_STATUS_APPROVED = 5

export const KYC_STATUS_FRAUD_SUSPECTED = 6

export const KYC_FILLED_BUT_NOT_REVIEWED = [
  KYC_STATUS_PENDING
]
