var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-fill" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.checkSize(),
              expression: "checkSize()",
            },
          ],
          ref: "advanced_filters_desktop",
          staticClass: "dropdown",
          attrs: {
            id: "advanced_filters",
            "data-testid": "advanced-filters-dropdown",
          },
        },
        [
          _c("comm-advanced-filters", {
            ref: "filters",
            attrs: {
              campaign_id: _vm.campaign_id,
              workflow_id: _vm.workflow_id,
              broadcast_id: _vm.broadcast_id,
              ring_group_id: _vm.ring_group_id,
              user_id: _vm.user_id,
              team_id: _vm.team_id,
              tag_id: _vm.tag_id,
              call_disposition_id: _vm.call_disposition_id,
              only_date_filter: _vm.only_date_filter,
              report_category: _vm.report_category,
              no_exports: _vm.no_exports,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.checkSize()
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-end",
                width: "100%",
                trigger: "click",
                "data-testid": "advanced-filters-wrapper-popover",
              },
              on: {
                show: function ($event) {
                  _vm.popover = true
                },
                hide: function ($event) {
                  _vm.popover = false
                },
              },
            },
            [
              _c("div", { ref: "advanced_filters_mobile" }),
              _vm._v(" "),
              _c("el-button", {
                staticClass: "pull-right mt-1",
                attrs: {
                  slot: "reference",
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-more-outline",
                  "data-testid": "advanced-filters-button",
                  plain: "",
                },
                slot: "reference",
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }