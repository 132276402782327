import * as CompanyStatus  from './../../../constants/company-status'

export default function () {
    return {
        shouldRedirectToLogin: false,
        fieldErrors: {},
        form: {
            // Account Registration/Creation
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            country: '',
            company_name: '',
            password: '',
            password_confirmation: '',

            // Business Information
            customer_name: '',
            business_type: '',
            company_status: CompanyStatus.COMPANY_STATUS_PRIVATE,
            business_registration_identifier: '',
            business_registration_number: '',
            business_regions_of_operation: '',
            business_industry: '',
            website_url: '',

            // Address Information
            street: '',
            region: '',
            city: '',
            iso_country: '',
            postal_code: '',

            // Authorized representative information
            auth_rep_first_name: '',
            auth_rep_last_name: '',
            auth_rep_email: '',
            auth_rep_phone_number: '',
            auth_rep_business_title: '',
            auth_rep_job_position: '',

            agreed_to_terms: false,
            timezone: '',
            kyc_filled: 0
        }
    }
}
