<template>
    <div class="info-alert-box mb-3">
        <el-alert type="info"
                  :closable="false"
                  show-icon>
            <div class="no-select">
                <p class="info-text">
                    <span>{{ text }}</span>
                    <el-button
                        v-if="kbLink && !isSimpSocial"
                        size="large"
                        type="text"
                        @click="openLink(kbLink)">
                        Learn More
                    </el-button>
                </p>
            </div>
        </el-alert>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    props: {
        kbLink: {
            type: String,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        text: {
            type: String,
            required: true
        },
    },

    computed: {
        ...mapGetters('cache', ['isSimpSocial']),
    },

    methods: {
        openLink(link) {
            window.open(link, '_blank')
        },
    }
}
</script>

<style scoped>
.el-button--text {
    text-decoration: underline;
}

.el-button {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
}
</style>
