var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "width-500", staticStyle: { height: "400px" } },
    [
      _c("div", { staticClass: "row d-flex align-items-center" }, [
        _c(
          "div",
          { staticClass: "col-8" },
          [
            _c("el-input", {
              attrs: { placeholder: "Search", "prefix-icon": "el-icon-search" },
              on: { input: _vm.findGif },
              model: {
                value: _vm.search_text,
                callback: function ($$v) {
                  _vm.search_text = $$v
                },
                expression: "search_text",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3 height-400" },
        [
          _vm.gifs_arr.length == 0
            ? _c(
                "el-row",
                { staticClass: "full-height" },
                [
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.gifs_loading,
                          expression: "gifs_loading",
                        },
                      ],
                      staticClass: "full-height",
                    },
                    [
                      !_vm.gifs_loading
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex full-height justify-content-center align-items-center",
                            },
                            [_c("span", [_vm._v("No results found")])]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.gifs_arr.length > 0
            ? _c("vue-masonry-gallery", {
                attrs: {
                  imgsArr: _vm.gifs_arr,
                  width: 500,
                  height: 400,
                  "img-width": 140,
                },
                on: {
                  scrollReachBottom: _vm.loadMoreGiphy,
                  click: _vm.selected,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4" }, [
      _c("img", {
        staticClass: "width-120",
        attrs: { src: "/assets/images/giphy.png" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }