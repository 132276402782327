<template>
    <el-dialog
        class="dialog-blank"
        width="70vw"
        top="5vh"
        append-to-body
        :show-close="false"
        :visible="visible">
        <div class="container-listing rounded p-4">
            <div class="container-header d-flex">
                <h4 class="listing-heading">New Sequence</h4>
                <div class="ml-auto">
                    <el-button type="text"
                               class="btn-dialog-close"
                               size="large"
                               @click="close">
                        <i class="fa fa-remove"></i>
                    </el-button>
                </div>
            </div>
            <div class="container-body">
                <workflow-settings-manager
                    v-model="workflow"
                    :mode="'add'"
                    @onAddClose="close"
                    @saved="close">
                </workflow-settings-manager>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import WorkflowSettingsManager from "./workflow-settings-manager"
import * as Workflow from "../../constants/workflow"

export default {
    name: "workflow-creator",

    components: {WorkflowSettingsManager},

    data() {
        return {
            visible: false,
            workflow: null,
            Workflow,
            batch_size: 0
        }
    },

    methods: {
        addWorkflow() {
            this.workflow = {
                always_open: true,
                send_on: Workflow.SEND_ON_ANY_DAYS,
                campaign_id: null,
                batch_size: 20
            }

            this.visible = true
        },

        close() {
            this.visible = false
        },
    }
}
</script>

