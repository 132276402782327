var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      staticClass: "text-left",
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
          return (() => false).apply(null, arguments)
        },
      },
    },
    [
      _c("el-form-item", { staticClass: "mb-2" }, [
        _c(
          "div",
          { staticClass: "form-label mb-1", attrs: { scope: "label" } },
          [_c("label", [_vm._v("Push contacts to")])]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c(
                "el-radio",
                {
                  staticClass: "w-full",
                  attrs: {
                    label: _vm.DIRECTION_BOTTOM,
                    value: _vm.DIRECTION_BOTTOM,
                    border: "",
                    size: "medium",
                  },
                  model: {
                    value: _vm.model.direction,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "direction", $$v)
                    },
                    expression: "model.direction",
                  },
                },
                [_vm._v("\n          Bottom\n        ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c(
                "el-radio",
                {
                  staticClass: "w-full",
                  attrs: {
                    label: _vm.DIRECTION_TOP,
                    value: _vm.DIRECTION_TOP,
                    border: "",
                    size: "medium",
                  },
                  model: {
                    value: _vm.model.direction,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "direction", $$v)
                    },
                    expression: "model.direction",
                  },
                },
                [_vm._v("\n          Top\n        ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mb-2" }, [
        _c(
          "div",
          { staticClass: "form-label", attrs: { scope: "label" } },
          [
            _c("el-checkbox", {
              staticClass: "w-full",
              attrs: { label: "Add multiple phone numbers?" },
              model: {
                value: _vm.model.multiple_phone_numbers,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "multiple_phone_numbers", $$v)
                },
                expression: "model.multiple_phone_numbers",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mb-2" }, [
        _c(
          "div",
          { staticClass: "form-label", attrs: { scope: "label" } },
          [
            _c("el-checkbox", {
              staticClass: "w-full",
              attrs: { label: "Prevent duplicate task creation" },
              model: {
                value: _vm.model.prevent_duplicates,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "prevent_duplicates", $$v)
                },
                expression: "model.prevent_duplicates",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mb-2" }, [
        _c(
          "div",
          { staticClass: "form-label", attrs: { scope: "label" } },
          [
            _c("el-checkbox", {
              staticClass: "w-full",
              attrs: { label: "Add your own contacts?" },
              model: {
                value: _vm.model.own_contacts_only,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "own_contacts_only", $$v)
                },
                expression: "model.own_contacts_only",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mb-2" }, [
        _c(
          "div",
          { staticClass: "form-label", attrs: { scope: "label" } },
          [
            _c("el-checkbox", {
              staticClass: "w-full",
              attrs: { label: "Future scheduled task?" },
              model: {
                value: _vm.model.future_scheduled,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "future_scheduled", $$v)
                },
                expression: "model.future_scheduled",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.model.future_scheduled
        ? _c(
            "el-form-item",
            { attrs: { label: "Scheduled time:" } },
            [
              _c("v-date-picker", {
                attrs: {
                  mode: "dateTime",
                  "title-position": "left",
                  color: "green",
                  "min-date": new Date(),
                  masks: _vm.masks,
                  popover: _vm.popover_config,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ inputValue, inputEvents }) {
                        return [
                          _c(
                            "input",
                            _vm._g(
                              {
                                staticClass: "px-2 py-1 border rounded",
                                domProps: { value: inputValue },
                              },
                              inputEvents
                            )
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  667095556
                ),
                model: {
                  value: _vm.model.future_scheduled_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "future_scheduled_time", $$v)
                  },
                  expression: "model.future_scheduled_time",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mb-2" }, [
        _c(
          "div",
          {
            class: `form-label ${
              !_vm.hasInternationalAccess ? "d-inline-flex" : ""
            }`,
            attrs: { scope: "label" },
          },
          [
            _c("el-checkbox", {
              staticClass: "w-full",
              attrs: {
                disabled: !_vm.hasInternationalAccess,
                label: "Allow international phone numbers",
              },
              model: {
                value: _vm.model.allow_international_phone_numbers,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "allow_international_phone_numbers", $$v)
                },
                expression: "model.allow_international_phone_numbers",
              },
            }),
            _vm._v(" "),
            !_vm.hasInternationalAccess
              ? _c(
                  "el-tooltip",
                  {
                    staticClass: "item ml-2",
                    attrs: {
                      effect: "dark",
                      content: "This account has no international access",
                      placement: "top",
                    },
                  },
                  [_c("i", { staticClass: "fas fa-info-circle" })]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }