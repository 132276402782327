<template>
    <div v-if="hasPermissionTo('create disposition status')">
        <!--create new disposition status-->
        <el-dialog title="Create a new disposition status"
                   class="dialog-padding auto"
                   :width="isLargeEnough ? '50%' : '100%'"
                   :visible.sync="hide_add"
                   :before-close="beforeCloseModal"
                   append-to-body>
            <el-form ref="add_disposition_status"
                     class="mt-2 p-0"
                     label-position="top"
                     :rules="rules_disposition_status"
                     :model="disposition_status"
                     @submit.prevent.native="addDispositionStatus">
                <el-form-item prop="color"
                              label="Choose a color">
                    <el-color-picker v-model="disposition_status.color"
                                     class="d-block"
                                     @active-change="changetagColor"
                                     @change="preValidateForm('add_disposition_status')">
                    </el-color-picker>
                </el-form-item>
                <el-form-item prop="name"
                              label="Name">
                    <div class="width-300">
                        <el-form-item prop="name">
                            <el-input v-model="disposition_status.name"
                                      maxlength="190"
                                      show-word-limit
                                      @input="preValidateForm('add_disposition_status')">
                            </el-input>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item prop="description"
                              label="Description (Optional)">
                    <div class="width-300">
                        <el-form-item prop="description">
                            <el-input v-model="disposition_status.description"
                                      type="textarea"
                                      rows="2"
                                      maxlength="190"
                                      show-word-limit
                                      @input="preValidateForm('add_disposition_status')">
                            </el-input>
                        </el-form-item>
                    </div>
                </el-form-item>
            </el-form>

            <div class="row pb-3">
                <div class="col-12">
                    <el-button type="success"
                               :disabled="loading_btn"
                               class="pull-right ml-2"
                               @click="addDispositionStatus">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="fa fa-plus"
                           v-show="!loading_btn"></i>
                        <span>Add</span>
                    </el-button>

                    <el-button type="info"
                               class="pull-right"
                               @click="hide_add = false">
                        <span class="fa fa-close"></span>
                        <span>Cancel</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <slot name="trigger">
            <el-button slot="reference"
                       type="text text-sm text-dark-greenish"
                       @click="hide_add = true">
                <i class="el-icon-circle-plus-outline"></i>
                Add Disposition Status
            </el-button>
        </slot>
    </div>
</template>

<script>
    import auth from '../auth'
    import {
        acl_mixin,
        form_validation_mixin
    } from '../mixins'
    import {mapActions, mapState} from 'vuex'

    export default {
        mixins: [
            acl_mixin,
            form_validation_mixin
        ],

        data() {
            return {
                auth: auth,
                loading_btn: false,
                hide_add: false,
                disposition_status: {
                    color: '#D3D3D3',
                    name: null,
                    description: null,
                },
                rules_disposition_status: {
                    color: [
                        {
                            required: true,
                            message: 'Please select a disposition status color',
                            trigger: 'blur'
                        }
                    ],
                    name: [
                        {
                            required: true,
                            message: 'Please provide a disposition status name',
                            trigger: 'blur'
                        },
                        {
                            max: 190,
                            message: 'Name is too long',
                            trigger: 'blur'
                        }
                    ],
                    description: [
                        {
                            max: 190,
                            message: 'Description is too long',
                            trigger: 'blur'
                        }
                    ]
                }
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            isLargeEnough() {
                let valid_sizes = ['xxl', 'xl', 'lg', 'md']
                if (valid_sizes.includes(this.$mq)) {
                    return true
                }
                return false
            }
        },

        methods: {
            addDispositionStatus() {
                if (this.validateForm('add_disposition_status')) {
                    this.loading_btn = true
                    axios.post('/api/v1/disposition-status', this.disposition_status)
                        .then(res => {
                            this.loading_btn = false
                            this.hide_add = false
                            this.$notify({
                                offset: 95,
                                title: 'Account',
                                message: 'Contact disposition has been successfully created.',
                                type: 'success',
                                showClose: true
                            })
                            this.newDispositionStatus(res.data)
                            this.$emit('success', res.data)
                            this.resetDispositionStatus()
                        })
                        .catch(err => {
                            this.$root.handleErrors(err.response)
                            this.loading_btn = false
                            this.resetDispositionStatus()
                        })
                }
            },

            resetDispositionStatus() {
                this.disposition_status = {
                    color: '#D3D3D3',
                    name: null,
                    description: null
                }
            },

            changetagColor(val) {
                this.disposition_status.color = val
            },

            beforeCloseModal(done) {
                if (this.disposition_status.color || this.disposition_status.name) {
                    this.$confirm('Are you sure you want to leave? Your changes will not be saved.', 'Warning', {
                        confirmButtonText: 'Yes, Leave',
                        cancelButtonText: 'No, Stay',
                        customClass: 'width-500 fixed',
                        type: 'warning'
                    }).then(res => {
                        this.resetDispositionStatus()
                        done()
                    }).catch(() => {

                    })
                } else {
                    this.resetDispositionStatus()
                    done()
                }
            },

            ...mapActions(['newDispositionStatus'])
        },

        watch: {
            hide_add() {
                if (this.hide_add) {
                    this.$emit('hide')
                } else {
                    this.$emit('show')
                    this.preValidateForm('add_disposition_status', true)
                }
            }
        }
    }
</script>
