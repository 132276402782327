<template>
    <div>
        <div class="vertical-center text-left mb-4">
			<span v-if="filter.from_date"
                  class="call-log-head">
				Line's Tags Stacked from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
			</span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
				Line Tags
                <strong>All Time</strong>
			</span>
        </div>
        <div class="placeholder text-center d-flex align-items-center justify-content-center"
             :class="{ blink: loading }"
             v-if="loading || campaigns.length == 0"
             style="height: 450px">
            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div style="height: 450px"
             v-else>
            <graph-data-switch :series="options.series"
                               :type="type">
                <highcharts v-bind:id="graph_id"
                            v-show="is_done && options.series.length > 0"
                            :options="options"
                            style="height: 450px"
                            ref="highchart">
                </highcharts>
                <div class="el-table__empty-block"
                     v-show="is_done && !options.series.length">
                    <span class="el-table__empty-text"
                          style="color: #606266;">
                        No Data
                    </span>
                </div>
            </graph-data-switch>
        </div>
    </div>
</template>

<script>
    import {graph_mixin, report_mixin} from '../mixins'
    import auth from '../auth'

    export default {
        props: ['type'],

        mixins: [report_mixin, graph_mixin],

        data() {
            return {
                auth: auth,
                loading: false,
                axisLabels: [],
                graph_id: 'campaign-tags-stackedcolumninverse-graph',
                report_type: 'tag_v_campaign',
                options: {
                    chart: {
                        type: 'bar',
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    if (this.y != 0) {
                                        return this.y
                                    }
                                },
                                crop: false,
                                style: {
                                    fontSize: '14px'
                                }
                            }
                        }
                    },
                    xAxis: {
                        type: "category",
                    },
                    yAxis: {
                        //tickInterval: 1,
                        title: {
                            text: 'Number Of Calls & Texts',
                            style: {
                                'font-size': '14px',
                                'color': '#090A0D'
                            }
                        },
                        min: 1,
                        startOnTick: false
                    },
                    tooltip: {
                        pointFormatter: function () {
                            var toReturn = 'The tag ' + '<b>' + this.series.name + '</b> of line <b>' + this.name + '</b><br>'
                            toReturn += 'has ' + '<b>' + this.y.toLocaleString() + '</b> Calls & Texts<br>'

                            return toReturn
                        },
                    },
                }
            }
        },

        methods: {
            generateGraphData: function () {
                // setup the data attrs
                let data_series = []
                let xAxisLabels = []
                let seriesLabels = []
                let seriesNames = []

                //get distinct series labels
                this.aggregated_counts.forEach((campaign_data, index) => {
                    //do not include empty data
                    if (Object.keys(campaign_data.data).length > 0) {
                        for (let key in campaign_data.data) {
                            if (seriesNames.indexOf(campaign_data.data[key].name) < 0) {
                                seriesLabels.push({
                                    name: campaign_data.data[key].name,
                                    color: campaign_data.data[key].color,
                                })
                                seriesNames.push(campaign_data.data[key].name)
                            }
                        }
                    }
                })

                //loop on all tags for the chart series
                seriesLabels.forEach(axisLabel => {
                    let campaigns = []
                    this.aggregated_counts.forEach(campaign_data => {
                        //do not include empty data
                        if (Object.keys(campaign_data.data).length > 0) {
                            let labelValue = '',
                                yVal = 0
                            for (let key in campaign_data.data) {
                                //set equivalent y axis
                                if (campaign_data.data[key].name == axisLabel.name) {
                                    labelValue = campaign_data.campaign_name
                                    yVal = parseFloat(campaign_data.data[key].count)
                                    break
                                }
                            }
                            if (labelValue !== '') {
                                campaigns.push({
                                    name: labelValue,
                                    y: yVal,
                                })
                            }
                            //set distinct xaxis labels
                            if (xAxisLabels.indexOf(campaign_data.campaign_name) < 0) {
                                xAxisLabels.push(campaign_data.campaign_name)
                            }
                        }
                    })
                    //add chart series
                    data_series.push({
                        name: (typeof axisLabel === 'string' ? axisLabel.capitalize() : axisLabel.name.capitalize()),
                        color: axisLabel.color,
                        data: campaigns,
                    })
                })
                this.axisLabels = xAxisLabels
                //this should update xaxis labels
                //this.options.xAxis.categories = xAxisLabels
                //this.options.xAxis.setCategories(xAxisLabels)
                if (data_series.length) {
                    this.options.series = data_series
                } else {
                    this.options.series = []
                }
            }
        }
    }
</script>
