import VueRouter from 'vue-router'

let routes = [
    {
        name: 'Admin Dashboard',
        path: '/admin-dashboard',
        redirect: '/admin-dashboard/outbound'
    },
    {
        name: 'Outbound Dashboard',
        path: '/admin-dashboard/outbound',
        component: require('../components/dashboard/dashboard-outbound').default
    },
    {
        name: 'Inbound Dashboard',
        path: '/admin-dashboard/inbound',
        component: require('../components/dashboard/dashboard-inbound').default
    },
    {
        name: 'Dashboard',
        path: '/dashboard',
        component: require('../views/dashboard').default
    },
    {
        name: 'Wallboard',
        path: '/wallboard',
        component: require('../views/wallboard').default
    },
    {
        name: 'Integrations',
        path: '/integrations',
        props: true,
        component: require('../views/integrations').default
    },
    {
        name: 'Account',
        path: '/account',
        props: true,
        component: require('../views/account').default
    },
    {
        name: 'Dealer Profile',
        path: '/dealer-profile',
        props: true,
        component: require('../views/simpsocial/dealer-profile').default
    },
    {
        name: 'Reports',
        path: '/reports',
        redirect: '/reports/',
        component: require('../views/report/default').default,
        props: true,
        children: [
            {
                path: ':report_category?',
                props: true,
                component: require('../views/report/show').default
            }
        ]
    },
    {
        name: 'Insights',
        path: '/insights',
        redirect: '/insights/',
        component: require('../views/insights/insights-default.vue').default,
        props: true,
        children: [
            {
                name: 'View Insight',
                path: ':slug?',
                props: true,
                component: require('../views/insights/insights-index.vue').default
            }
        ],
    },
    {
        name: 'Lines',
        path: '/lines',
        alias: '/campaigns',
        redirect: '/lines/',
        component: require('../views/line/default').default,
        props: true,
        children: [
            {
                path: '',
                props: true,
                component: require('../views/line/index').default
            },
            {
                name: 'Line Settings',
                path: ':campaign_id/settings',
                props: true,
                component: require('../views/line/settings').default
            },
            {
                name: 'Line Activity',
                path: ':campaign_id/activity',
                props: true,
                component: require('../views/line/activity').default
            }
        ]
    },
    {
        name: 'Users',
        path: '/users',
        redirect: '/users/',
        component: require('../views/user/default').default,
        props: true,
        children: [
            {
                path: '',
                props: true,
                component: require('../views/user/index').default
            },
            {
                name: 'User Activity',
                path: ':user_id/activity',
                props: true,
                component: require('../views/user/activity').default
            },
            {
                name: 'User Dialog',
                path: 'dialog/:user_id?',
                props: true,
                component: require('../views/user/dialog').default
            },
            {
                name: 'User Timeline',
                path: 'timeline/:user_id',
                props: true,
                component: require('../views/user/timeline').default
            }
        ]
    },
    {
        name: 'Imports',
        path: '/imports',
        component: require('../views/imports').default
    },
    {
        name: 'Ring Groups',
        path: '/ring-groups',
        redirect: '/ring-groups/',
        component: require('../views/ring-group/default').default,
        props: true,
        children: [
            {
                path: '',
                props: true,
                component: require('../views/ring-group/index').default
            },
            {
                name: 'Ring Group Activity',
                path: ':ring_group_id/activity',
                props: true,
                component: require('../views/ring-group/activity').default
            },
            {
                name: 'Ring Group Dialog',
                path: 'dialog/:ring_group_id?',
                props: true,
                component: require('../views/ring-group/dialog').default
            }
        ]
    },
    {
        // The route definition needs to exist because we have <router-link>s pointing to Contacts from multiple Classic pages (like Communications), and a <router-link> needs a working route to construct the actual <a> link.
        path: '/contacts',
        redirect: '/contacts/',
        children: [
            {
                name: 'Contacts',
                path: ''
            },
            {
                name: 'Contact',
                path: ':contact_id'
            }
        ]
    },
    {
        name: 'Lists',
        path: '/lists',
        redirect: '/lists/',
        component: require('../views/admin-list/default').default,
        props: true,
        children: [
            {
                path: '',
                props: true,
                component: require('../views/admin-list/index').default
            },
        ]
    },
    {
        name: 'Teams',
        path: '/teams',
        redirect: '/teams/',
        component: require('../views/teams/default').default,
        props: true,
        children: [
            {
                path: '',
                props: true,
                component: require('../views/teams/index').default
            },
            {
                name: 'Team',
                path: ':team_id',
                props: true,
                component: require('../components/teams/edit-team-members').default
            }
        ]
    },
    {
        name: 'AloAi Voice Analytics',
        path: '/aloai-voice-analytics',
        props: true,
        component: require('../views/aloai-voice-analytics/index').default,
    },
    {
        name: 'AloAi Voice Bot',
        path: '/aloai-voice-bot',
        props: true,
        component: require('../views/aloai-voice-bot/index').default,
    },
    {
        name: 'AloAi',
        path: '/aloai',
        props: true,
        component: require('../views/aloai/index').default,
    },
    {
        name: 'AloAi: Create new chatbot',
        path: '/aloai/create',
        props: true,
        component: require('../views/aloai/aloaibot').default
    },
    {
        name: 'AloAi: Edit chatbot',
        path: '/aloai/:bot_id',
        props: true,
        component: require('../views/aloai/aloaibot').default
    },
    {
        name: 'Tags',
        path: '/tags',
        component: require('../views/tag/index').default
    },
    {
        name: 'Login',
        path: '/',
        alias: '/login',
        component: require('../views/login').default
    },
    {
        name: 'SSU',
        path: '/signup',
        components: {
            signup: require('../views/signup').default
        }
    },
    {
        name: 'Account Registration',
        path: '/account-registration/:verification_token',
        component: require('../views/account-registration').default,
        meta: { isCompanyRegistration: true },
    },
    {
        name: 'User Registration',
        path: '/user-registration/:verification_token',
        component: require('../views/account-registration').default,
        meta: { isCompanyRegistration: false },
    },
    {
        name: 'Forgot',
        path: '/forgot',
        component: require('../views/forgot').default
    },
    {
        name: 'Reset',
        path: '/reset/:token',
        props: true,
        component: require('../views/reset').default
    },
    {
        name: 'Webhooks',
        path: '/integrations/webhooks',
        component: require('../components/integrations/webhooks/webhook-index').default
    },
    {
        name: 'Suspended',
        path: '/suspended',
        component: require('../views/errors/suspended').default
    },
    {
        name: 'Canceled',
        path: '/canceled',
        component: require('../views/errors/canceled').default
    },
    {
        name: 'Broadcast',
        path: '/broadcast',
        redirect: '/broadcast/',
        component: require('../views/broadcast/default').default,
        props: true,
        children: [
            {
                name: 'Broadcasts',
                path: '',
                props: true,
                component: require('../views/broadcast/index').default
            },
            {
                name: 'Bulk Message',
                path: 'bulk-message',
                component: require('../views/broadcast/bulk-message').default
            },
            {
                name: 'Bulk RVM',
                path: 'bulk-rvm',
                component: require('../views/broadcast/bulk-rvm').default
            },
            {
                name: 'Broadcast Activity',
                path: ':broadcast_id/activity',
                props: true,
                component: require('../views/broadcast/activity').default
            },
        ]
    },
    {
        name: 'Sequences 2',
        path: '/sequences2',
        component: require('../components/automations/workflow-list').default,
    },
    {
        name: 'Sequence Manager',
        path: '/sequences2/manager/:workflow_id',
        component: require('../components/automations/workflow-manager').default
    },
    {
        name: 'Communication',
        path: '/communication/:communication_id',
        props: true,
        component: require('../views/communication').default
    },
    {
        path: '/integrations/:provider/callback',
        beforeEnter: (to, from, next) => {
            let path = to.fullPath
            path = path.replace('/callback', '/response')
            let api_token = localStorage.getItem('api_token')
            if (!api_token) {
                next({name: 'Login', query: {redirect: path}})
            } else {
                window.location.href = path
            }
        },
    },
    {
        name: 'Account Selector',
        path: '/account-selector',
        props: true,
        component: require('../views/account-selector').default
    },
    {
        name: 'Pipedrive App Panel Request',
        path: '/integrations/pipedrive/app-extensions/app-panel/:action_request',
        props: true,
        component: require('../views/integrations-extensions/pipedrive/app-panel-request').default
    },
    {
        name: 'Pipedrive App Extensions',
        path: '/integrations/pipedrive/app-extensions/:basic_action',
        props: true,
        component: require('../views/integrations-extensions/pipedrive/basic-action').default
    },
    {
        name: 'SMS Segment Calculator',
        path: '/sms-segment-calculator',
        component: require('../views/public/sms-segment-calculator.vue').default
    },
    {
        path: '*',
        component: require('../views/errors/404').default
    }
]

/**
 * Root level implementation of catching. The implementation is suggested it self by one of its core developer.
 * The purpose of this is to override the implementation of VueRouter's push method to globally catch navigation failure issue.
 *
 * @link https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 * @link https://github.com/vuejs/vue-router/issues/2872
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }

    return originalPush.call(this, location)
        .catch((err) => {
            // navigation failure
            if (VueRouter.isNavigationFailure(err)) {
                console.log('Navigation Failure: ', err)
                // resolve err
                return err
            }

            // rethrow error
            return Promise.reject(err)
        })
}


let router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    }
})

// save the route name in localstorage
router.afterEach((to, from) => {
    localStorage.setItem('PREVIOUS_ROUTE_NAME', from.name)
});

export default router
