// Two-Factor authentication (2FA)
export const USE_CASE_2FA = '2FA'
// Account Notification
export const USE_CASE_ACCOUNT_NOTIFICATION = 'ACCOUNT_NOTIFICATION'
// Customer Care
export const USE_CASE_CUSTOMER_CARE = 'CUSTOMER_CARE'
// Delivery Notification
export const USE_CASE_DELIVERY_NOTIFICATION = 'DELIVERY_NOTIFICATION'
// Fraud Alert Messaging
export const USE_CASE_FRAUD_ALERT = 'FRAUD_ALERT'
// Higher Education
export const USE_CASE_HIGHER_EDUCATION = 'HIGHER_EDUCATION'
// Low Volume Mixed
export const USE_CASE_LOW_VOLUME = 'LOW_VOLUME'
// Marketing
export const USE_CASE_MARKETING = 'MARKETING'
// Mixed
export const USE_CASE_MIXED = 'MIXED'
// Polling and voting
export const USE_CASE_POLLING_VOTING = 'POLLING_VOTING'
// Public Service Announcement
export const USE_CASE_PUBLIC_SERVICE_ANNOUNCEMENT = 'PUBLIC_SERVICE_ANNOUNCEMENT'
// Security Alert
export const USE_CASE_SECURITY_ALERT = 'SECURITY_ALERT'

export const USE_CASES = Object.freeze([
    {
        value: USE_CASE_CUSTOMER_CARE,
        title: 'Customer Care',
        description: 'Support, account management, and other avenues of customer interaction.',
    },
    {
        value: USE_CASE_MARKETING,
        title: 'Marketing',
        description: 'Promotional content such as sales and limited time offers.',
    },
    {
        value: USE_CASE_MIXED,
        title: 'Mixed',
        description: 'A campaign that covers multiple use cases such as Customer Care and Delivery Notifications.  Note - mixed campaigns are likely to have lower throughput and a higher cost per message.',
    },
    {
        value: USE_CASE_DELIVERY_NOTIFICATION,
        title: 'Delivery Notification',
        description: 'Information about the status of a delivery.',
    },
    {
        value: USE_CASE_FRAUD_ALERT,
        title: 'Fraud Alert Messaging',
        description: 'Messaging about potential fraudulent activity such as spending alerts.',
    },
    {
        value: USE_CASE_HIGHER_EDUCATION,
        title: 'Higher Education',
        description: 'Message campaigns from colleges, universities, and other education institutions.',
    },
    {
        value: USE_CASE_LOW_VOLUME,
        title: 'Low Volume Mixed',
        description: 'A campaign that covers multiple use cases such as Customer Care and Delivery Notifications.  Note - mixed campaigns are likely to have lower throughput and a higher cost per message.',
    },
    {
        value: USE_CASE_2FA,
        title: 'Two-Factor Authentication (2FA)',
        description: 'Any authentication or account verification such as OTP.',
    },
    {
        value: USE_CASE_ACCOUNT_NOTIFICATION,
        title: 'Account Notification',
        description: 'Notifications about the status of an account or related to being a part of an account.',
    },
    {
        value: USE_CASE_POLLING_VOTING,
        title: 'Polling and Voting',
        description: 'For conducting polling and voting, such as customer surveys. Not for political use.',
    },
    {
        value: USE_CASE_PUBLIC_SERVICE_ANNOUNCEMENT,
        title: 'Public Service Announcement',
        description: 'PSAs to raise audience awareness about a given topic.',
    },
    {
        value: USE_CASE_SECURITY_ALERT,
        title: 'Security Alert',
        description: 'Notification of a compromised system (software or hardware related).',
    },
])