<template>
    <div class="import-settings">
        <div class="row">
            <div class="col-md-6 pr-4">
                <div class="form-label mb-4"
                     scope="label">
                    <label>DNC List</label>
                    <small v-if="import_contact.is_dnc">
                        <i class="fa fa-exclamation-triangle text-danger"></i>
                        The imported contacts were set to DNC (Do Not Call) and this is not reversible.
                    </small>
                    <small v-else>
                        The imported contacts were not treated as a DNC list.
                    </small>
                </div>
                <div v-if="!import_contact.is_dnc">
                    <div class="form-label mb-4"
                         scope="label">
                        <label>Update Existing Contacts</label>
                        <small v-if="import_contact.update_existing">
                            The original existing contact data was overridden with what was in the CSV file.
                        </small>
                        <small v-else>
                            Existing contacts were not updated with data from the CSV file.
                        </small>
                    </div>
                    <div class="form-label mb-4"
                         scope="label">
                        <label>Save Unknown Columns</label>
                        <small v-if="import_contact.unknown_columns_to_notes">
                            Columns that were not mapped to contact fields were saved to the contact's notes.
                        </small>
                        <small v-else>
                            Columns that were not mapped to contact fields were discarded.
                        </small>
                    </div>
                    <div class="form-label mb-4"
                         scope="label">
                        <label>Multiple Contacts</label>
                        <small v-if="import_contact.cascade_contacts">
                            A new contact was created for each phone number found in the row.
                        </small>
                        <small v-else>
                            A single contact was created per row, using the first phone number found.
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-md-6"
                 v-if="!import_contact.is_dnc">
                <div class="form-label mb-4"
                     scope="label">
                    <label>Assigned to</label>
                    <div>
                        <strong v-if="import_contact.user">User:</strong>
                        <strong v-else-if="import_contact.ring_group">Ring Group:</strong>
                        <span>{{ getAssignedToText }}</span>
                    </div>
                </div>
                <div class="form-label mb-4"
                     scope="label">
                    <label>Sequence</label>
                    <div>
                        {{ getDisplayValue(import_contact.workflow?.name) }}
                    </div>
                </div>
                <div class="form-label mb-4"
                     scope="label">
                    <label>Line</label>
                    <div>
                        {{ getDisplayValue(import_contact.campaign?.name) }}
                    </div>
                </div>
                <div class="form-label mb-4"
                     scope="label">
                    <label>Tags</label>
                    <div v-for="tag in import_contact.tags">
                        <el-tooltip class="item"
                                    effect="dark"
                                    placement="top"
                                    :content="tag?.name">
                            <router-link :to="{ name: 'Contacts', query: { 'tag_id': tag?.id } }">
                                <span :style="tagColorStyle(tag)">
                                    <i class="fa fa-tag"></i> 
                                        {{ tag?.name | strLimit(40) }}
                                    </span>
                            </router-link>
                        </el-tooltip>
                    </div>
                    <div v-if="import_contact.tags?.length === 0">
                        {{ getDisplayValue(null) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImportWizard',

    props: {
        import_contact: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        getAssignedToText() {
            const { user, ring_group } = this.import_contact;

            if (user) {
                return this.getDisplayValue(user?.full_name)
            } else if (ring_group) {
                return this.getDisplayValue(ring_group?.name)
            } else {
                return 'Unassigned'
            }
        }
    },

    methods: {
        tagColorStyle (tag) {
            if (tag.color) {
                return `color: ${tag.color}`
            }

            return ''
        },

        getDisplayValue (value) {
            return value ?? 'N/A'
        },
    }
}
</script>
