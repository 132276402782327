<template>
    <div>
        <div class="row b-b">
            <div class="col-12 p-3">
                <company-form-token></company-form-token>
            </div>
        </div>
        <!-- POST /api/v1/webhook/powerdialer-remove-contact-from-lists -->
        <div class="row b-b">
            <div class="col-12 p-3">
                <div class="mb-2">
                    <strong class="integration-api-titles">Remove a contact from all Power Dialer lists</strong>
                </div>

                <p class="text-md">
                    <span class="text-dark-greenish _600">POST /api/v1/webhook/powerdialer-remove-contact-from-lists</span>
                </p>

                <p>
                    <strong>Required Fields:</strong>
                    <br>
                    <span class="text-dark-greenish _600">api_token</span>,
                    <span class="text-dark-greenish _600">contact_id</span>
                </p>

                <p>
                    <strong>Example POST URL:</strong>
                    <br>
                    <span class="text-dark-greenish _600">{{ statics.domain | fixDomain }}/api/v1/webhook/powerdialer-remove-contact-from-lists</span>
                </p>

                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to remove a contact from Power Dialer lists:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_pd_a'"><code class="json">{
    <span class="text-dark-greenish _600">// Authentication Token</span>
    "api_token": "{{ companyToken }}", // Required
    "contact_id": "44500" // Required
}</code></pre>
                <p class="mb-2">
                    <strong>Example POST header:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "Accept": "application/json",
    "Content-Type": "application/json"
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 200 response on contact removed from Power Dialer list, Example
                        success
                        response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "The contact has been removed from the Power Dialer lists."
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 404 response on contact does not exists, Example
                        failure
                        response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "The requested contact does not exist.",
}</code></pre>

                <p class="mb-2">
                    <strong>cURL Example:</strong>
                </p>
                <pre class="mb-3" v-highlightjs :key="renderRefreshToken + '_pd_b'" v-if="!loading_whitelabel"><code class="bash">curl --location --request POST '{{ statics.domain | fixDomain }}/api/v1/webhook/powerdialer-remove-contact-from-lists' \
--header 'Content-Type: application/json' \
--header 'Accept: application/json' \
--data-raw '{
    "api_token": "{{ companyToken }}",
    "contact_id": "44500"
}'</code></pre>
            </div>
        </div>

        <!-- POST /api/v1/webhook/powerdialer-clear-list -->
        <div class="row b-b">
            <div class="col-12 p-3">
                <div class="mb-2">
                    <strong class="integration-api-titles">Remove all contacts from a Power Dialer list</strong>
                </div>

                <p class="text-md">
                    <span class="text-dark-greenish _600">POST /api/v1/webhook/powerdialer-clear-list</span>
                </p>

                <p>
                    <strong>Required Fields:</strong>
                    <br>
                    <span class="text-dark-greenish _600">api_token</span>,
                    <span class="text-dark-greenish _600">list_id</span>
                </p>

                <p>
                    <strong>Example POST URL:</strong>
                    <br>
                    <span class="text-dark-greenish _600">{{ statics.domain | fixDomain }}/api/v1/webhook/powerdialer-clear-list</span>
                </p>

                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to clear one or many Power Dialer lists:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_pd_c'"><code class="json">{
    <span class="text-dark-greenish _600">// Authentication Token</span>
    "api_token": "{{ companyToken }}", // Required
    "list_id": "50" // Required - Power Dialer list ID
}</code></pre>
                <p class="mb-2">
                    <strong>Example POST header:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "Accept": "application/json",
    "Content-Type": "application/json"
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 200 response on Power Dialer list clearing process initiated, Example
                        success
                        response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "Power Dialer lists clearing process has been initiated."
}</code></pre>
                <p class="mb-2">
                    <strong>cURL Example:</strong>
                </p>
                <pre class="mb-3" v-highlightjs :key="renderRefreshToken + '_pd_d'" v-if="!loading_whitelabel"><code class="bash">curl --location --request POST '{{ statics.domain | fixDomain }}/api/v1/webhook/powerdialer-clear-list' \
--header 'Content-Type: application/json' \
--header 'Accept: application/json' \
--data-raw '{
    "api_token": "{{ companyToken }}",
    "list_id": "50"
}'</code></pre>
            </div>
        </div>

        <!-- POST /api/v1/webhook/powerdialer-clear-user-lists -->
        <div class="row b-b">
            <div class="col-12 p-3">
                <div class="mb-2">
                    <strong class="integration-api-titles">Remove all contacts from Power Dialer user lists</strong>
                </div>

                <p class="text-md">
                    <span class="text-dark-greenish _600">POST /api/v1/webhook/powerdialer-clear-user-lists</span>
                </p>

                <p>
                    <strong>Required Fields:</strong>
                    <br>
                    <span class="text-dark-greenish _600">api_token</span>,
                    <span class="text-dark-greenish _600">user_id</span>
                </p>

                <p>
                    <strong>Example POST URL:</strong>
                    <br>
                    <span class="text-dark-greenish _600">{{ statics.domain | fixDomain }}/api/v1/webhook/powerdialer-clear-user-lists</span>
                </p>

                <p class="mb-2">
                    <strong>Example POST body:</strong>
                    <br>
                    <span class="text-muted">
                        The code sample below represents some example JSON with
                        standard fields to pass in the body of your request in
                        order to clear one Power Dialer lists:
                    </span>
                </p>
                <pre v-highlightjs :key="renderRefreshToken + '_pd_e'"><code class="json">{
    <span class="text-dark-greenish _600">// Authentication Token</span>
    "api_token": "{{ companyToken }}", // Required
    "user_id": "1" // Required - Power Dialer owner user ID
}</code></pre>
                <p class="mb-2">
                    <strong>Example POST header:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "Accept": "application/json",
    "Content-Type": "application/json"
}</code></pre>

                <p class="mb-2">
                    <strong>Returns an HTTP 200 response on Power Dialer list clearing process initiated, Example
                        success
                        response:</strong>
                </p>
                <pre v-highlightjs><code class="json">{
    "message": "Power Dialer user lists clearing process has been initiated."
}</code></pre>

                <p class="mb-2">
                    <strong>cURL Example:</strong>
                </p>
                <pre class="mb-3" v-highlightjs :key="renderRefreshToken + '_pd_f'" v-if="!loading_whitelabel"><code class="bash">curl --location --request POST '{{ statics.domain | fixDomain }}/api/v1/webhook/powerdialer-clear-user-lists' \
--header 'Content-Type: application/json' \
--header 'Accept: application/json' \
--data-raw '{
    "api_token": "{{ companyToken }}",
    "user_id": "50"
}'</code></pre>
            </div>
        </div>

        <p>
            If you are looking for a <span class="_600">Postman</span> example please check this link:
            <br>
            <a class="el-button--text" :href="statics.domain + '/static-data/power-dialer-api.postman_collection.json'"
                target="_blank">
                {{ statics.domain }}/static-data/power-dialer-api.postman_collection.json
            </a>
        </p>
    </div>
</template>

<script>
import {company_token_mixin} from '../mixins'
import CompanyFormToken from './account/company-form-token'

export default {
    mixins: [
        company_token_mixin
    ],

    components: {
        CompanyFormToken
    },

    data() {
        return {
            statics: {
                logo: null,
                logo_inverse: null,
                logo_square: null,
                logo_square_inverse: null,
                host: null,
                referer: null,
                name: null,
                domain: null,
                whitelabel: false,
                path: null
            },
            loading_whitelabel: true,
        }
    },

    created() {
        this.getStatics()
    },

    methods: {
        getStatics() {
            this.loading_whitelabel = true
            axios.get('/get-statics')
                .then(res => {
                    this.statics = res.data
                    this.loading_whitelabel = false
                })
                .catch(err => {
                    console.log(err)
                    this.$root.handleErrors(err.response)
                    this.loading_whitelabel = false
                })
        }
    }
}
</script>
