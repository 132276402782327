<template>
    <div>
        <div class="vertical-center text-center mb-4">
            <span v-if="filter.from_date"
                  class="call-log-head">
                Call Time of the Day per Line from
                <strong>{{ filter.from_date | fixFullDateLocal }}</strong>
                to
                <strong>{{ filter.to_date | fixFullDateLocal }}</strong>
            </span>
            <span v-if="!filter.from_date"
                  class="call-log-head">
                Call Time of the Day per Line
                <strong>All Time</strong>
            </span>
        </div>
        <div class="placeholder text-center d-flex align-items-center justify-content-center"
             :class="{ blink: loading }"
             v-if="loading || campaigns.length == 0"
             style="height: 450px">
            <i class="material-icons loader text-greenish text-4x">
                &#xE863;
            </i>
        </div>
        <div style="height: 450px"
             v-else>
            <highcharts v-bind:id="graph_id"
                        v-show="is_done && options.series.length > 0"
                        :options="options"
                        style="height: 450px"
                        ref="highchart">
            </highcharts>
            <div class="el-table__empty-block"
                 v-show="is_done && !options.series.length">
                <span class="el-table__empty-text"
                      style="color: #606266;">
                    No Data
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {date_mixin, graph_mixin, report_mixin} from '../mixins'
    import {mapState} from 'vuex'
    import auth from '../auth'

    export default {
        props: {
            report_index: {
                default: 0
            }
        },

        mixins: [report_mixin, date_mixin, graph_mixin],

        data() {
            let self = this
            return {
                auth: auth,
                loading: false,
                graph_id: 'call-time-per-campaign-graph',
                report_type: 'daytime_v_campaign',
                chart_period: '1H',
                options: {
                    chart: {
                        type: 'column'
                    },
                    xAxis: {
                        type: 'datetime',
                        tickInterval: 3600 * 1000,
                        min: moment("00:00", "hh:mm", this.current_company_timezone).valueOf(), //12am
                        max: moment("23:00", "hh:mm", this.current_company_timezone).valueOf(), //11pm
                        labels: {
                            formatter: function () {
                                return moment(this.value).format('h:00A')
                            },
                            style: {
                                fontSize: '14px'
                            }
                        }
                    },
                    yAxis: {
                        min: 0,
                        tickInterval: 1
                    },
                    tooltip: {
                        crosshairs: false,
                        formatter: function () {
                            let time = moment(this.x).format('h:00A')

                            let toReturn = '<b>' + this.series.name + '</b> has <b>' + this.point.y.toLocaleString() + '</b>'
                            toReturn += this.point.y > 1 ? ' calls' : ' call'

                            let limitEnd = (time == '11:00PM') ? ' 12:00AM' : moment(this.x).add(1, 'hour').format('h:00A')

                            toReturn += ' that started between ' + time + ' and ' + limitEnd
                            return toReturn

                        },
                    },
                    time: {
                        timezone: 'UTC'
                    }
                }
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            current_company_timezone() {
                return this.current_company.timezone
            }
        },

        methods: {
            generateGraphData: function () {
                // loop over what you got and setup the plots.
                let keys = Object.keys(this.aggregated_counts)
                for (let index in keys) {
                    let campaign_data = this.aggregated_counts[keys[index]]

                    //add campaign data
                    let temp_data = []

                    //segregate data values to respective xaxis values
                    for (let key in campaign_data.data) {
                        //get the hour of the day
                        let t = moment(campaign_data.data[key].timestamp * 1000).tz(this.current_company_timezone).format('H')

                        //set date as today and set the hour
                        let xValue = parseInt(moment(t + ":00", "h:mm", this.current_company_timezone).valueOf())

                        // segregate record based on xaxis label
                        if (campaign_data.data[key].count > 0) {
                            if (xValue in temp_data) {
                                temp_data[xValue] += campaign_data.data[key].count
                            } else {
                                temp_data[xValue] = campaign_data.data[key].count
                            }
                        }
                    }

                    let new_temp_data = []
                    if (Object.keys(temp_data).length > 0) {
                        for (let group_num = 0; group_num <= 23; group_num++) {
                            let timestamp = parseInt(moment(group_num + ":00", "h:mm", this.current_company_timezone).valueOf())
                            if (timestamp in temp_data) {
                                new_temp_data[timestamp] = temp_data[timestamp]
                            } else {
                                new_temp_data[timestamp] = 0
                            }
                        }
                    }

                    //reformat data to pass on highchart
                    let paired_data = []
                    for (let timestamp_key in new_temp_data) {
                        paired_data.push([parseInt(timestamp_key), new_temp_data[timestamp_key]])
                    }

                    let series = {
                        type: 'column',
                        name: campaign_data.label,
                        // color: "#0033A0",
                        data: paired_data
                    }

                    // have high charts re-draw this.
                    if (paired_data.length > 0) {
                        this.options.series.push(series)
                    }
                }

                this.$emit('reportLoaded', this.report_index)
            }
        }
    }
</script>
