<template>
    <div data-testid="campaign-details-wrapper">
        <template v-if="!campaign.is_fax">
            <p class="form-head-message text-left"
                data-testid="tracking-type-template">
                Tracking Type:
            </p>
            <p class="text-left"
               v-text="campaignTrackingType">
            </p>
        </template>
        <p class="form-head-message text-left"
           data-testid="your-numbers">
           Your numbers:
        </p>
        <p class="text-left">
            <span v-for="(ins, index) in campaign.incoming_numbers"
                  :key="index"
                  class="ins_item">
                <span>{{ ins.phone_number | fixPhone }}</span>
            </span>
        </p>

        <template v-if="hasPermissionTo('list ring group') && !campaign.is_fax">
            <p class="form-head-message text-left mt-3"
               data-testid="routing-call-to-template">
               We are routing calls to:
            </p>
            <p class="text-left">
                <template v-if="campaign.ring_group_id && getRingGroup(campaign.ring_group_id)">
                    <span class="dn_item">
                        <router-link :to="{ name: 'Ring Group Dialog', params: {ring_group_id: campaign.ring_group_id }}">
                            <span class="text-dark-greenish _400">
                                {{ getRingGroup(campaign.ring_group_id).name | fixName }}
                            </span>
                        </router-link>
                    </span>
                </template>
            </p>
        </template>

        <template v-if="hasPermissionTo(['list user', 'list ring group']) && !campaign.is_fax">
            <p class="form-head-message text-left mt-3"
               data-testid="users-template">
               Users:
            </p>
            <p class="text-left">
                <template v-if="campaign.ring_group_id && getRingGroup(campaign.ring_group_id)">
                    <span v-for="user_id in getRingGroup(campaign.ring_group_id).user_ids"
                          v-if="getUser(user_id)"
                          :key="user_id"
                          class="dn_item">
                        <router-link
                                :to="{ name: 'User Dialog', params: {user_id: user_id }}">
                            <span class="text-dark-greenish _400">
                                {{ getUserName(getUser(user_id)) }}
                            </span>
                        </router-link>
                    </span>
                </template>
            </p>
        </template>

        <el-tabs class="text-left mt-4"
                 tab-position="left"
                 data-testid="review-number-tabs"
                 style="height: 350px;">
            <el-tab-pane label="Review Number"
                         data-testid="review-number-pane">
                <div class="row p-2"
                     data-testid="campaign-name">
                    <div class="col-4">
                        <strong>Name:</strong>
                    </div>
                    <div class="col-8">
                        <span>{{ campaign.name }}</span>
                    </div>
                </div>
                <template v-if="!campaign.is_fax">
                    <div class="row p-2"
                         data-testid="campaign-dial-mode">
                        <div class="col-4">
                            <strong>Dial Mode:</strong>
                        </div>
                        <div class="col-8">
                            <span>{{ campaign.dial_mode | humanReadableDialMode }}</span>
                        </div>
                    </div>
                    <div class="row p-2"
                         data-testid="campaign-whispering">
                        <div class="col-4">
                            <strong>Whispering:</strong>
                        </div>
                        <div class="col-8">
                            <span>{{ campaign.should_whisper | humanReadableBool }}</span>
                        </div>
                    </div>
                    <div class="row p-2"
                         data-testid="campaign-whisper-message">
                        <div class="col-4">
                            <strong>Whisper Message:</strong>
                        </div>
                        <div class="col-8">
                            <span>"{{ campaign.whisper_tts }}"</span>
                        </div>
                    </div>
                    <div class="row p-2"
                         data-testid="campaign-greeting">
                        <div class="col-4">
                            <strong>Greeting:</strong>
                        </div>
                        <div class="col-8">
                            <span>{{ campaign.should_greet | humanReadableBool }}</span>
                        </div>
                    </div>
                    <div class="row p-2"
                         data-testid="campaign-greeting-message">
                        <div class="col-4">
                            <strong>Greeting Message:</strong>
                        </div>
                        <div class="col-8">
                            <span>"{{ campaign.greeting_tts }}"</span>
                        </div>
                    </div>
                    <div class="row p-2"
                         data-testid="campaign-recording">
                        <div class="col-4">
                            <strong>Recording:</strong>
                        </div>
                        <div class="col-8">
                            <span>{{ campaign.should_record | humanReadableBool }}</span>
                        </div>
                    </div>
                    <div class="row p-2"
                         data-testid="campaign-recording-message">
                        <div class="col-4">
                            <strong>Recording Message:</strong>
                        </div>
                        <div class="col-8">
                            <span>"{{ campaign.record_tts }}"</span>
                        </div>
                    </div>
                </template>
            </el-tab-pane>
            <el-tab-pane label="Suggested Next Steps"
                         data-testid="suggested-nex-steps-pane">
                <div class="row p-2" v-if="campaign.is_fax">
                    <div class="col-12">
                        <span>Send a fax to: <b>{{ campaign.incoming_number | fixPhone }}</b> and make sure you receive the fax in your dashboard.</span>
                    </div>
                </div>
                <div class="row p-2" v-else>
                    <div class="col-12">
                        <span>Give yourself a call :) Call the tracking number: <b>{{ campaign.incoming_number | fixPhone }}</b> and make sure it rings you on the other end.</span>
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-12">
                        <span>Checkout the Activity tab:</span>
                        <router-link :to="{ name: 'Line Activity', params: {campaign_id: campaign.id }}"
                                     target="_blank">
                            <el-button type="text"
                                       class="text-dark-greenish">
                                Go to Activity Dashboard
                            </el-button>
                        </router-link>
                        <span v-if="!campaign.is_fax">. As soon as your calls rake in, we will fill this page up.</span>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

        <span slot="footer"
              data-testid="campaign-details-footer"
              class="dialog-footer clear">
            <router-link :to="{ name: 'Line Settings', params: {campaign_id: campaign.id }}"
                         class="pull-right"
                         v-if="hasPermissionTo('update campaign')">
                <el-button type="success"
                           data-testid="edit-configuration-btn"
                           @click="$emit('close')">
                    Edit Configuration
                </el-button>
            </router-link>
            <router-link
                    v-if="$route.name != 'Line Activity' || ($route.name == 'Line Activity' && $route.params.campaign_id && $route.params.campaign_id != campaign.id)"
                    :to="{ name: 'Line Activity', params: {campaign_id: campaign.id }}"
                    class="pull-right mr-2">
                <el-button data-testid="go-to-activity-dashboard-btn"
                           @click="$emit('close')">
                Go to Activity Dashboard
                </el-button>
            </router-link>
        </span>
    </div>
</template>

<script>
    import auth from '../auth'
    import {
        acl_mixin,
        campaign_mixin,
        user_info_mixin
    } from '../mixins'
    import * as CampaignTypes from '../constants/campaign-types'
    import * as CampaignSubtypes from '../constants/campaign-subtypes'
    import * as AnswerTypes from '../constants/answer-types'
    import {mapState} from "vuex"

    export default {
        mixins: [
            acl_mixin,
            campaign_mixin,
            user_info_mixin
        ],

        props: {
            campaign: {
                required: true
            },
        },

        computed: {
            ...mapState([
                'ring_groups'
            ]),
        },

        data() {
            return {
                auth: auth,
                DIAL_MODE_SIMUL: 0,
                DIAL_MODE_RAND: 1,
                DIAL_MODE_ROUND: 2,
                DIAL_MODE_LONGEST_AVAILABLE: 3,
                CampaignTypes,
                CampaignSubtypes,
                AnswerTypes
            }
        },

        methods: {
            getRingGroup(id) {
                if (!id) {
                    return null
                }
                let found = this.ring_groups.find(ring_group => ring_group.id === id)
                if (found) {
                    return found
                }

                return null
            }
        }
    }
</script>
