<template>
    <div class="w-full">
        <el-select v-model="activity_type"
                   class="w-full"
                   :placeholder="placeholder"
                   :disabled="loading_activity_type"
                   :size="size"
                   filterable
                   @change="changeActivityType">
            <el-option
                v-for="activity_type in activity_types"
                :key="activity_type"
                :label="activity_type"
                :value="activity_type">
                <template>
                    <span class="ml-2">{{ activity_type }}</span>
                </template>
            </el-option>
        </el-select>
    </div>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        props: {
            communication: {
                required: true,
            },

            size: {
                type: String,
                default: '',
                required: false
            }
        },

        data() {
            return {
                activity_type: null,
                loading_activity_type: false,
            }
        },

        computed: {
            ...mapState({
                activity_types: state => state.activity_types
            }),

            computedCommunication() {
                if (this.communication) {
                    return this.communication
                }

                return {
                    id: null,
                    public_metadata: {
                        activity_type: null
                    },
                }
            },

            placeholder() {
                return 'Select Activity Type'
            },
        },

        created() {
            this.activity_type = this.communication?.public_metadata?.activity_type
        },

        methods: {
            changeActivityType() {
                this.loading_activity_type = true
                axios.post('/api/v1/communication/' + this.communication.id + '/activity-type', {
                    activity_type: this.activity_type
                }).then((res) => {
                    this.loading_activity_type = false
                    // Notify only if not in widget
                    if (!this.$root.$data.is_widget) {
                        this.$notify({
                            offset: 95,
                            title: 'Communication',
                            message: 'Activity type updated.',
                            type: 'success',
                            showClose: true,
                        })
                    }
                    this.$emit('activityTypeChosen', res.data?.public_metadata?.activity_type)
                }).catch((err) => {
                    this.loading_activity_type = false
                    this.$root.handleErrors(err.response)
                })
            },
        },

        watch: {
            'computedCommunication.public_metadata.activity_type': function() {
                this.activity_type = this.communication.public_metadata?.activity_type
            }
        }
    }
</script>
