<template>
    <el-dialog width="25vw"
               top="10vh"
               append-to-body
               title="Split List"
               :before-close="hideDialog"
               :visible.sync="show_dialog">
        <div v-loading="splitting">
            <h6 class="mb-2">
                <span>
                    Split <span class="text-dark">{{ contactListName }}</span> list into smaller lists
                </span>
            </h6>
            <hr>
            <div scope="label"
                 class="form-label text-dark">
                <h6 class="mb-2 text-black">
                    Page size:
                </h6>
            </div>
            <el-select v-model="option_selected">
                <el-option :key="item.value"
                           :label="item.label"
                           :value="item.value"
                           :disabled="item.disabled"
                           v-for="item in options">
                </el-option>
            </el-select>
            <hr>
            <h6 class="mb-2 text-black">
                <span>
                    Do you want to keep the original List?
                </span>
            </h6>
            <el-radio border
                      :label="true"
                      v-model="keep_list">
                Keep
            </el-radio>
            <el-radio class="custom-red-radio" 
                      border
                      :label="false"
                      v-model="keep_list">
                Delete
            </el-radio>
            <hr>
            <div class="container-footer d-flex mb-0 mt-3">
                <el-button size="small"
                           @click="hideDialog">
                    Close
                </el-button>

                <el-button type="success"
                           size="small"
                           class="ml-auto"
                           :disabled="disableSplitButton"
                           @click="splitList">
                    Split
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "admin-split-list-form",

        props: {
            contact_list: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                keep_list: true,
                show_dialog: false,
                splitting: false,
                options: [
                    {
                        value: 50,
                        label: '50',
                        disabled: false
                    },
                    {
                        value: 100,
                        label: '100',
                        disabled: false
                    },
                    {
                        value: 200,
                        label: '200',
                        disabled: false
                    },
                    {
                        value: 500,
                        label: '500',
                        disabled: false
                    },
                    {
                        value: 1000,
                        label: '1000',
                        disabled: false
                    }
                ],
                option_selected: 50,
            }
        },

        computed: {
            disableSplitButton () {
                return !this.option_selected
            },

            contactListName() {
                return this.contact_list ? this.contact_list.name : ''
            }
        },

        methods: {
            disableSizeOptions () {
                // Set option as disabled if value >= this.contact_list.no_of_contacts
                this.options.forEach(option => {
                    if (option.value >= this.contact_list.no_of_contacts) {
                        option.disabled = true
                    }
                })
            },

            enableAllSizeOptions () {
                this.options.forEach(option => {
                    option.disabled = false
                })
            },

            splitList() {
                this.splitting = true

                const data = {
                    page_size: this.option_selected,
                    keep_original: this.keep_list
                }

                this.$APIV2.ContactList.splitList(this.contact_list.id, data)
                    .then(res => {
                        if (res.data.message) {
                            this.splitting = false

                            this.$notify({
                                offset: 75,
                                title: 'Contact List',
                                message: res.data.message,
                                type: 'success',
                                showClose: true,
                                duration: 5000
                            })

                            setTimeout(_ => {
                                this.closeSplitDialog()
                            }, 1000)
                        }
                    })
                    .catch(err => {
                        const status = err.response.status

                        if (status) {
                            this.$root.handleErrors(err.response)
                        }

                        this.splitting = false
                    })
            },

            resetSplitDialog() {
                this.show_dialog = false
                this.option_selected = 50
                this.enableAllSizeOptions()
            },

            closeSplitDialog() {
                this.resetSplitDialog()
                this.$emit('closed')
            },

            hideDialog() {
                this.resetSplitDialog()
                this.$emit('hidden')
            }
        },

        watch: {
            contact_list() {
                this.disableSizeOptions()
            }
        }
    }
    </script>
