var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !["activity_reporting", "new_contacts_vs_dnc"].includes(_vm.report_type)
        ? _c(
            "el-radio-group",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.type,
                  expression: "!type",
                },
              ],
              staticClass: "data-switch",
              attrs: { size: "mini" },
              on: { change: _vm.emitChange },
              model: {
                value: _vm.data_switch,
                callback: function ($$v) {
                  _vm.data_switch = $$v
                },
                expression: "data_switch",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "graph" } }, [
                _vm._v("Graph"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "table" } }, [
                _vm._v("Table"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data_switch == "graph" ? _vm._t("default") : _vm._e(),
      _vm._v(" "),
      _vm.report_type &&
      ["leads_intake"].includes(_vm.report_type) &&
      _vm.data_switch == "table"
        ? _c(
            "div",
            { staticStyle: { height: "450px" } },
            [
              _c(
                "el-table",
                {
                  staticClass: "w-full",
                  attrs: {
                    data: _vm.highcharts_series,
                    "max-height": 450,
                    stripe: "",
                  },
                },
                _vm._l(_vm.highcharts_series[0], function (val, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      fixed: index == "name" ? true : false,
                      label: _vm.findLabel(index),
                      "min-width": 150,
                      prop: index,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(scope.row[index] || 0) +
                                  "\n                "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm.data_switch == "table"
        ? _c(
            "div",
            { staticStyle: { height: "450px" } },
            [
              _c(
                "el-table",
                {
                  staticClass: "w-full",
                  attrs: {
                    data: _vm.highcharts_series,
                    "max-height": 450,
                    stripe: "",
                  },
                },
                _vm._l(_vm.highcharts_series[0], function (val, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: {
                      fixed: index == "name" ? true : false,
                      label: _vm.findLabel(index),
                      "min-width": 150,
                      prop: index,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(scope.row[index] || 0) +
                                  "\n                "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }