var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermissionTo("list team")
    ? _c(
        "div",
        [
          _vm.label
            ? _c("label", { staticClass: "p-0 m-0 _500" }, [
                _vm._v("\n        " + _vm._s(_vm.label) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-tooltip",
            {
              attrs: {
                effect: "dark",
                placement: "top",
                content: _vm.disabled_tooltip_content,
                disabled: !_vm.disabled,
              },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "w-full",
                  attrs: {
                    autocomplete: "off",
                    clearable: "",
                    filterable: "",
                    "default-first-option": _vm.default_first,
                    disabled: _vm.disabled,
                    multiple: _vm.multiple,
                    placeholder: _vm.placeholder,
                    size: _vm.size,
                    "collapse-tags": _vm.collapsable,
                    "data-testid": "team-selector",
                  },
                  on: { clear: _vm.teamChange, change: _vm.teamChange },
                  model: {
                    value: _vm.team_id,
                    callback: function ($$v) {
                      _vm.team_id = $$v
                    },
                    expression: "team_id",
                  },
                },
                _vm._l(_vm.team_list, function (team) {
                  return _c(
                    "el-option",
                    {
                      key: team.id,
                      attrs: { label: team.name, value: team.id },
                    },
                    [_c("span", [_vm._v(_vm._s(team.name))])]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }