var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.is_loading,
          expression: "is_loading",
        },
      ],
      staticClass: "container-fluid",
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-3" }, [
          _c("div", { staticClass: "card shadow-sm" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                { staticClass: "position-relative" },
                [
                  _c("highcharts", {
                    key: _vm.donut_chart_key,
                    ref: "chart",
                    attrs: { options: _vm.donut_chart_options },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: "mb-0",
                          staticStyle: {
                            "font-weight": "600",
                            "font-size": "30px",
                          },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm._f("toCurrency")(
                                  _vm._f("fixRounding")(
                                    _vm.total_usage
                                      ? _vm.total_usage.total_credit
                                      : 0
                                  )
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          "\n                                Total Cost\n                            "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center mb-3" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "px-4",
                      attrs: { type: "success", round: "", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.usage_summary_dialog.show()
                        },
                      },
                    },
                    [_c("strong", [_vm._v("Cost & Usage Summary")])]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-9" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body shadow-sm" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "mr-2 align-self-center",
                      on: { change: _vm.changeLineSeriesDataType },
                      model: {
                        value: _vm.current_series_data_type,
                        callback: function ($$v) {
                          _vm.current_series_data_type = $$v
                        },
                        expression: "current_series_data_type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        {
                          staticStyle: { height: "41px" },
                          attrs: { label: "credit" },
                        },
                        [_vm._v("Cost")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        {
                          staticStyle: { height: "41px" },
                          attrs: { label: "quantity" },
                        },
                        [_vm._v("Usage")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      staticClass: "align-self-center",
                      attrs: {
                        placement: "bottom-start",
                        width: "400",
                        trigger: "click",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-2" },
                        [
                          _c("div", [
                            _c("h6", { staticClass: "mb-0" }, [
                              _vm._v("Resources"),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "Choose the resources you want to cast in the line graph."
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.series_items, function (series_item) {
                            return _vm.series_items.length > 0
                              ? _c(
                                  "div",
                                  { key: series_item.key },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: series_item },
                                        on: {
                                          change: function ($event) {
                                            return _vm.toggleSeriesItem(
                                              series_item
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.current_series_item,
                                          callback: function ($$v) {
                                            _vm.current_series_item = $$v
                                          },
                                          expression: "current_series_item",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-circle mr-2",
                                          style: "color: " + series_item.color,
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(series_item.name) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e()
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.resources, function (resource, i) {
                            return _vm.series_items.length <= 0
                              ? _c(
                                  "div",
                                  { key: i },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: {
                                          disabled: true,
                                          label: resource,
                                        },
                                        model: {
                                          value: resource.value,
                                          callback: function ($$v) {
                                            _vm.$set(resource, "value", $$v)
                                          },
                                          expression: "resource.value",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-circle mr-2",
                                          style: "color: " + resource.color,
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(resource.name) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e()
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "41px" },
                          attrs: { slot: "reference", size: "medium" },
                          slot: "reference",
                        },
                        [
                          _vm.current_series_item
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "fa fa-circle mr-2",
                                  style:
                                    "color: " + _vm.current_series_item.color,
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.current_series_item.name) +
                                    "\n                                "
                                ),
                              ])
                            : _c("span", [
                                _c("i", {
                                  staticClass: "fa fa-circle mr-2",
                                  style: "color: #00BF50",
                                }),
                                _vm._v(
                                  " Total\n                                "
                                ),
                              ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "ml-2 fa fa-caret-down" }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.usages.labels.length > 1
                ? _c(
                    "div",
                    [
                      _c("highcharts", {
                        key: _vm.line_chart_key,
                        ref: "chart",
                        attrs: { options: _vm.line_chart_options },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "text-center" }, [
                    _c(
                      "div",
                      { staticClass: "w-50 mx-auto" },
                      [
                        _c("img-empty"),
                        _vm._v(" "),
                        _c("p", { staticClass: "lead" }, [
                          _vm._v("Data is not enough to render a line chart."),
                        ]),
                      ],
                      1
                    ),
                  ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("company-usage-summary-dialog", {
        ref: "usage_summary_dialog",
        attrs: { date_range: _vm.date_range, usages: _vm.usages.data },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }