var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "banner col-xl-4 xl-show md-hide sm-hide" }, [
    _c("div", { staticClass: "text-center items-center align-center" }, [
      _c("img", {
        attrs: { alt: _vm.getBannerTitle(), src: _vm.getBannerImageSrc() },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card text-center" }, [
        _c("h3", { staticClass: "mb-2 mt-2" }, [
          _vm._v(_vm._s(_vm.getBannerTitle())),
        ]),
        _vm._v(" "),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.getBannerDescription()) },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }