<template>
    <el-dialog
        class="dialog-blank inbox-filter-dialog"
        width="700px"
        top="5vh"
        append-to-body
        :before-close="closeDialog"
        :visible="visible">
        <el-form
            v-loading="loading"
            :model="filter_form"
            :rules="rules"
            ref="filter_form">
            <div class="row pl-3 pr-3">
                <div class="d-none d-sm-none d-md-flex col-md-4">
                    <el-form-item
                        prop="name"
                        class="mb-0 filter-name w-100"
                        :error="errors.first('name')">
                        <el-input
                            v-if="is_name_on_edit || !filter_form.name"
                            @blur="handleFilterNameBlur"
                            @focus="focusFilterName"
                            ref="filterName"
                            v-model="filter_form.name"
                            clearable
                            size="medium"
                            placeholder="(Unsaved) Filter">
                        </el-input>

                        <div v-else>
                            <span class="filter-name-text">{{ filter_form.name }}</span>
                            <el-button
                                @click="editFilterName"
                                type="text"
                                class="pt-0 pb-0 pl-2 filter-name-edit">
                                <i class="material-icons font-14-400">create</i>
                            </el-button>
                        </div>
                    </el-form-item>
                </div>

                <!-- Make sure responsiveness works -->
                <template>
                    <div class="d-none d-sm-none d-md-flex col-md-4 justify-content-center align-items-center">
                        <span class="dialog-title">{{ modalTitle }}</span>
                    </div>

                    <div class="d-flex d-sm-flex d-md-none col-6 col-sm-6 align-items-center">
                        <span class="dialog-title">{{ modalTitle }}</span>
                    </div>
                </template>

                <div class="col-6 col-sm-6 col-md-4 text-right">
                    <el-button
                        @click="closeDialog"
                        size="mini"
                        class="form-cancel-button">
                        Cancel
                    </el-button>

                    <el-tooltip
                        effect="light"
                        :disabled="!disableSaveButton"
                        :content="hasModel ? 'No change detected.' : 'Please input name and specify any filter.'"
                        placement="top">
                        <span class="disabled-wrapper el-button">
                            <el-button
                                @click="validateForm"
                                :disabled="disableSaveButton"
                                size="mini"
                                class="item form-save-button">
                                {{ hasModel ? 'Update' : 'Save as New' }}
                            </el-button>
                        </span>
                    </el-tooltip>
                </div>
            </div>

            <div class="d-flex d-sm-flex d-md-none col-md-12 mt-3 w-100">
                <el-form-item
                    v-if="is_name_on_edit || !filter_form.name"
                    prop="name"
                    class="mb-0 filter-name w-100"
                    :error="errors.first('name')">
                    <el-input
                        @blur="handleFilterNameBlur"
                        ref="filterName"
                        v-model="filter_form.name"
                        clearable
                        size="medium"
                        class="w-100"
                        placeholder="(Unsaved) Filter">
                    </el-input>
                </el-form-item>

                <div v-else>
                    <span class="filter-name-text">{{ filter_form.name }}</span>
                    <el-button
                        @click="editFilterName"
                        type="text"
                        class="pt-0 pb-0 pl-2 filter-name-edit">
                        <i class="material-icons font-14-400">create</i>
                    </el-button>
                </div>
            </div>

            <div class="inbox-main-form pl-3 pr-3">
                <div class="mt-4">
                    <div class="inbox-filter-section-title">Quick Access</div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <el-form-item
                                label="Time"
                                prop="time"
                                class="handling-section-override">
                                <el-select
                                    @visible-change="showDatePicker"
                                    v-model="range_type"
                                    size="medium"
                                    placeholder="Select"
                                    class="w-100 borderless-input input-pl-0">
                                    <el-option
                                        v-for="type in time_options"
                                        :key="type.value"
                                        :label="type.label"
                                        :value="type.value">
                                    </el-option>
                                </el-select>

                                <!--
                                    Lemme explain the reason behind this.
                                    We need to show a datepicker without showing the picker field
                                    now, we don't want to write a new datepicker form scratch so we utilized
                                    the el-date-picker component in Element UI, hide it and used its capabilities.
                                    We have have a date picker with no actual field.
                                -->
                                <el-date-picker
                                    v-model="hidden_date_value"
                                    type="daterange"
                                    size="medium"
                                    ref="timePicker"
                                    start-placeholder="Start date"
                                    end-placeholder="End date"
                                    class="hidden-date-field"
                                    :picker-options="pickerOptions"
                                    @change="dateTimePicked">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <el-form-item
                                label="My Contacts"
                                prop="my_contact"
                                class="override-switch">
                                <el-switch
                                    @change="handleMyContactsChange"
                                    v-model="filter_form.filter.my_contact"
                                    active-color="#00BF4A">
                                </el-switch>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <el-form-item
                                label="Lines"
                                prop="lines">
                                <campaign-selector
                                    v-model="filter_form.filter.campaigns"
                                    :multiple="true"
                                    :small="true"
                                    :clearable="true"
                                    size="medium"
                                    :collapsable="true"
                                    @change="addCampaign">
                                </campaign-selector>
                            </el-form-item>
                        </div>
                        <div
                            v-if="showRingGroupSelector"
                            class="col-sm-12 col-md-6">
                            <el-form-item
                                label="Ring Groups"
                                prop="ring_groups">
                                <ring-group-selector
                                    v-model="filter_form.filter.ring_groups"
                                    :multiple="true"
                                    size="medium"
                                    :collapsable="true"
                                    @change="addRingGroup">
                                </ring-group-selector>
                            </el-form-item>
                        </div>
                    </div>
                </div>

                <div class="mt-3"
                     v-if="type !== TYPE_INBOX">
                    <div class="inbox-filter-section-title">Handling</div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-3">
                            <el-form-item
                                label="Direction"
                                prop="direction"
                                class="handling-section-override">
                                <el-select
                                    @clear="directionSelectorCleared"
                                    v-model="filter_form.filter.direction"
                                    size="medium"
                                    clearable
                                    filterable
                                    placeholder="Select"
                                    class="w-100 borderless-input input-pl-0">
                                    <el-option label="All" :value="CommunicationDirection.DIRECTION_ALL_TEXT"></el-option>
                                    <el-option label="Inbound" :value="CommunicationDirection.DIRECTION_INBOUND_TEXT"></el-option>
                                    <el-option label="Outbound" :value="CommunicationDirection.DIRECTION_OUTBOUND_TEXT"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div
                            v-if="showAnswerStatusSelector"
                            class="col-sm-12 col-md-6 col-lg-3">
                            <el-form-item
                                label="Answer Status"
                                prop="answer_status"
                                class="handling-section-override">
                                <el-select
                                    @clear="answerStatusSelectorCleared"
                                    v-model="filter_form.filter.answer_status"
                                    size="medium"
                                    clearable
                                    filterable
                                    placeholder="Select"
                                    class="w-100 borderless-input input-pl-0">
                                    <el-option
                                        v-for="answer_status in AnswerStatuses.ANSWER_STATUSES"
                                        :key="answer_status.value"
                                        :label="answer_status.label"
                                        :value="answer_status.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div
                            v-if="showTalkTimeSelector"
                            class="col-sm-12 col-md-6 col-lg-3">
                            <el-form-item
                                label="Talk Time"
                                prop="talk_time"
                                class="handling-section-override">
                                <el-select
                                    @clear="talkTimeSelectorCleared"
                                    v-model="filter_form.filter.min_talk_time"
                                    size="medium"
                                    clearable
                                    filterable
                                    placeholder="Select"
                                    class="w-100 borderless-input input-pl-0">
                                    <el-option
                                        v-for="talk_time in TalkTimes.TALK_TIMES"
                                        :key="talk_time.value"
                                        :label="talk_time.label"
                                        :value="talk_time.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div
                            v-if="showTransferTypeSelector"
                            class="col-sm-12 col-md-6 col-lg-3">
                            <el-form-item
                                label="Transfer Type"
                                prop="transfer_type"
                                class="handling-section-override">
                                <el-select
                                    v-model="filter_form.filter.transfer_type"
                                    size="medium"
                                    clearable
                                    filterable
                                    placeholder="Select"
                                    class="w-100 borderless-input input-pl-0">
                                    <el-option label="Cold Transfer" :value="CommunicationTransferTypes.TRANSFER_TYPE_COLD"></el-option>
                                    <el-option label="Warm Transfer" :value="CommunicationTransferTypes.TRANSFER_TYPE_WARM"></el-option>
                                    <el-option label="Conference" :value="CommunicationTransferTypes.TRANSFER_TYPE_CONF"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div
                            v-if="showCallbackStatusSelector"
                            class="col-sm-12 col-md-6 col-lg-3">
                            <el-form-item
                                label="Callback Status"
                                prop="callback_status"
                                class="handling-section-override">
                                <el-select
                                    v-model="filter_form.filter.callback_status"
                                    size="medium"
                                    clearable
                                    filterable
                                    placeholder="Select"
                                    class="w-100 borderless-input input-pl-0">
                                    <el-option label="Initiated" :value="CallbackStatuses.CALLBACK_STATUS_INITIATED"></el-option>
                                    <el-option label="Requested" :value="CallbackStatuses.CALLBACK_STATUS_REQUESTED"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </div>

                <div class="mt-3"
                     v-if="type !== TYPE_INBOX">
                    <div class="inbox-filter-section-title">Properties</div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <el-form-item
                                label="Tags"
                                prop="tags">
                                <tag-selector
                                    v-model="filter_form.filter.tags"
                                    :multiple="true"
                                    size="medium"
                                    :clearable="true"
                                    @change="addTag">
                                </tag-selector>
                            </el-form-item>
                        </div>
                        <div
                            v-if="showCallDispositionStatusSelector"
                            class="col-sm-12 col-md-6">
                            <el-form-item
                                label="Call Dispositions"
                                prop="call_dispositions">
                                <call-disposition-selector
                                    v-model=filter_form.filter.call_dispositions
                                    :multiple="true"
                                    size="medium"
                                    :clearable="true"
                                    @change="addCallDisposition">
                                </call-disposition-selector>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-3">
                            <el-form-item
                                label="Show Only First-time Communications"
                                prop="show_first_time_communications"
                                class="override-switch">
                                <el-switch
                                    v-model="filter_form.filter.first_time_only"
                                    active-color="#00BF4A"
                                    class="w-100">
                                </el-switch>
                            </el-form-item>
                        </div>
                        <div class="col-sm-12 col-md-3">
                            <el-form-item
                                label="Show Only Untagged Communications"
                                prop="show_untagged_communications"
                                class="override-switch">
                                <el-switch
                                    v-model="filter_form.filter.untagged_only"
                                    active-color="#00BF4A"
                                    class="w-100">
                                </el-switch>
                            </el-form-item>
                        </div>
                        <div class="col-sm-12 col-md-3">
                            <el-form-item
                                label="Exclude Comm. Sent From Sequences"
                                prop="exclude_automated_communications"
                                class="override-switch">
                                <el-switch
                                    v-model="filter_form.filter.exclude_automated_communications"
                                    active-color="#00BF4A"
                                    class="w-100">
                                </el-switch>
                            </el-form-item>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-3">
                            <el-form-item
                                label="Creator Type"
                                prop="creator_type"
                                class="handling-section-override">
                                <el-select
                                    v-model="filter_form.filter.creator_type"
                                    size="medium"
                                    clearable
                                    filterable
                                    placeholder="Select"
                                    class="w-100 borderless-input input-pl-0">
                                    <el-option label="Manual" :value="CommunicationCreatorTypes.CREATOR_TYPE_MANUAL"></el-option>
                                    <el-option label="API" :value="CommunicationCreatorTypes.CREATOR_TYPE_API"></el-option>
                                    <el-option label="Workflow" :value="CommunicationCreatorTypes.CREATOR_TYPE_WORKFLOW"></el-option>
                                    <el-option label="Broadcast" :value="CommunicationCreatorTypes.CREATOR_TYPE_BROADCAST"></el-option>
                                    <el-option label="Power Dialer" :value="CommunicationCreatorTypes.CREATOR_TYPE_POWER_DIALER"></el-option>
                                    <el-option label="SMS Reminder" :value="CommunicationCreatorTypes.CREATOR_TYPE_SMS_REMINDER"></el-option>
                                    <el-option label="App Notifications" :value="CommunicationCreatorTypes.CREATOR_TYPE_APP_NOTIFICATIONS"></el-option>
                                    <el-option label="HubSpot" :value="CommunicationCreatorTypes.CREATOR_TYPE_HUBSPOT"></el-option>
                                    <el-option label="Zapier" :value="CommunicationCreatorTypes.CREATOR_TYPE_ZAPIER"></el-option>
                                    <el-option label="HighLevel" :value="CommunicationCreatorTypes.CREATOR_TYPE_GOHIGHLEVEL"></el-option>
                                    <el-option label="AloAi" :value="CommunicationCreatorTypes.CREATOR_TYPE_ALOAI"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </div>

                <div class="mt-3">
                    <div class="inbox-filter-section-title">Attribution</div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <el-form-item
                                label="Line Phone Numbers"
                                prop="phone_numbers">
                                <el-select
                                    v-model="filter_form.filter.incoming_numbers"
                                    size="medium"
                                    multiple
                                    clearable
                                    filterable
                                    collapse-tags
                                    placeholder="Select Line Phone Numbers"
                                    class="w-100">
                                    <el-option
                                        v-for="number in incomingNumbers"
                                        :key="number.id"
                                        :label="number.phone_number | fixPhone('NATIONAL')"
                                        :value="number.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div
                            v-if="showCommOwnerSelector"
                            class="col-sm-12 col-md-6">
                            <el-form-item
                                prop="users"
                                class="display-grid">
                                <span
                                    slot="label"
                                    class="d-flex">
                                    Communication Owners
                                    <el-popover
                                        placement="right"
                                        width="355"
                                        trigger="hover">
                                        <div class="text-black">
                                            <div><strong>Who is the communication owner?</strong></div>

                                            <div class="mt-2">
                                                <div>
                                                    <strong>For outbound communication:</strong>
                                                </div>
                                                <span>Calls, SMS, fax, emails:</span>
                                                <ul class="mb-0">
                                                    <li>The agent that send the communication</li>
                                                </ul>
                                            </div>

                                            <div>
                                                <div>
                                                    <strong>For inbound communication:</strong>
                                                </div>
                                                <div>Calls:</div>
                                                <ul class="mb-0">
                                                    <li>The agent that answered the call</li>
                                                </ul>

                                                <div>SMS, fax, email:</div>
                                                <ul class="mb-0">
                                                    <li>The most recent assigned contact owner owns all of these inbound communications</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <i
                                            class="fas fa-info-circle ml-2"
                                            slot="reference">
                                        </i>
                                    </el-popover>
                                </span>
                                <user-selector
                                    v-model="filter_form.filter.users"
                                    size="medium"
                                    :multiple="true"
                                    placeholder="Select Communication Owners"
                                    :hide_extensions="true"
                                    @change="addUser">
                                </user-selector>
                            </el-form-item>
                        </div>

                        <div class="col-sm-12 col-md-6">
                            <el-form-item
                                label="Sequences"
                                prop="sequences">
                                <workflow-selector
                                    v-model="filter_form.filter.workflows"
                                    :multiple="true"
                                    size="medium"
                                    @change="addWorkflow">
                                </workflow-selector>
                            </el-form-item>
                        </div>

                        <div
                            class="col-sm-12 col-md-6">
                            <el-form-item
                                label="Contact Owners"
                                prop="contact_owner">
                                <user-selector
                                    v-model="filter_form.filter.contact_owner"
                                    size="medium"
                                    :multiple="true"
                                    placeholder="Select Contact Owners"
                                    :hide_extensions="true"
                                    @change="addContactOwner">
                                </user-selector>
                            </el-form-item>
                        </div>

                        <div
                            v-if="showBroadcastSelector"
                            class="col-sm-12 col-md-6">
                            <el-form-item
                                label="Broadcasts"
                                prop="broadcasts">
                                <broadcast-selector
                                    v-model="filter_form.filter.broadcasts"
                                    :multiple="true"
                                    size="medium"
                                    @change="addBroadcast">
                                </broadcast-selector>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
    import auth from '../../../auth'
    import {date_mixin} from "../../../mixins"
    import {mapState} from "vuex"
    import cloneDeep from 'lodash/cloneDeep'
    import ErrorBag from '../../../ErrorBag'
    import TagSelector from '../../tag-selector'
    import UserSelector from '../../user-selector'
    import CampaignSelector from '../../campaign-selector'
    import WorkflowSelector from '../../workflow-selector'
    import BroadcastSelector from '../../broadcast-selector'
    import RingGroupSelector from '../../ring-group-selector'
    import CallDispositionSelector from '../../call-disposition-selector'
    import * as TalkTimes from '../../../constants/talk-times'
    import * as AnswerStatuses from '../../../constants/answer-statuses'
    import * as CallbackStatuses from '../../../constants/callback-status'
    import * as CommunicationDirection from '../../../constants/communication-direction'
    import {TYPE_CALLS, TYPE_INBOX, TYPE_MESSAGES, TYPE_VOICEMAILS} from '../../../constants/filter-types'
    import * as CommunicationTransferTypes from '../../../constants/communication-transfer-types'
    import * as CommunicationCreatorTypes from '../../../constants/creator-types'

    export default {
        name: "inbox-filter-form",

        mixins: [date_mixin],

        props: {
            visible: {
                type: Boolean,
                required: true
            },

            type: {
                type: Number|null,
                required: true
            },

            model: {}
        },

        components: {
            TagSelector,
            UserSelector,
            CampaignSelector,
            WorkflowSelector,
            BroadcastSelector,
            RingGroupSelector,
            CallDispositionSelector
        },

        data() {
            const custom_default = [
                this.localizedMoment().subtract(1, 'day'),
                this.localizedMoment()
            ]
            return {
                hidden_date_value: [],
                range_type: 'all_time',
                auth: auth,
                loading: false,
                is_name_on_edit: true,
                filter_form: this.getDefaultValues(),
                filter_form_copy: this.getDefaultValues(),
                rules: {
                    name: {required: true, message: 'Please enter the filter name.', trigger: 'blur'}
                },
                errors: new ErrorBag({}),
                time_options: [
                    {label: 'All Time', value: 'all_time'},
                    {label: 'Custom', value: 'custom_range'},
                ],
                pickerOptions: {
                    shortcuts: [
                        {
                            text: 'All Time',
                            onClick(picker) {
                                picker.$emit('pick', []);
                            }
                        },
                        {
                            text: 'Custom Range',
                            onClick(picker) {
                                picker.$emit('pick', custom_default);
                            }
                        }
                    ]
                },
                CommunicationTransferTypes,
                CommunicationDirection,
                CallbackStatuses,
                AnswerStatuses,
                TalkTimes,
                CommunicationCreatorTypes,
                TYPE_INBOX,
            }
        },

        computed: {
            ...mapState(['campaigns', 'users']),
            ...mapState('cache', ['current_company']),

            modalTitle() {
                if (this.type === TYPE_CALLS) {
                    return 'Call & Recordings Filter'
                }
                if (this.type === TYPE_MESSAGES) {
                    return 'Messages Filter'
                }
                if (this.type === TYPE_VOICEMAILS) {
                    return 'Voicemails Filter'
                }
            },

            showRingGroupSelector() {
                return [TYPE_CALLS, TYPE_VOICEMAILS].includes(this.type)
            },

            showAnswerStatusSelector() {
                return [TYPE_CALLS].includes(this.type)
            },

            showTalkTimeSelector() {
                return [TYPE_CALLS].includes(this.type)
            },

            showTransferTypeSelector() {
                return [TYPE_CALLS].includes(this.type)
            },

            showCallbackStatusSelector() {
                return [TYPE_CALLS].includes(this.type)
            },

            showCallDispositionStatusSelector() {
                return [TYPE_CALLS].includes(this.type)
            },

            showCommOwnerSelector() {
                return [TYPE_CALLS, TYPE_MESSAGES].includes(this.type)
            },

            showBroadcastSelector() {
                return [TYPE_MESSAGES].includes(this.type)
            },

            incomingNumbers() {
                let campaigns = cloneDeep(this.campaigns)

                if (campaigns && campaigns.length > 0) {
                    let incoming_numbers = campaigns.reduce((acc, cur) => {
                        if (cur.incoming_numbers && cur.incoming_numbers.length) {
                            acc.push(...cur.incoming_numbers)
                        }

                        return acc
                    }, [])

                    if (incoming_numbers.length > 0) {
                        return incoming_numbers.sort((a, b) => {
                            let textA = a.phone_number.toString()
                            let textB = b.phone_number.toString()
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                        })
                    }
                }

                return []
            },

            hasValueChange() {
                return !_.isEqual(this.filter_form.filter, this.filter_form_copy.filter)
            },

            disableSaveButton() {
                if (this.hasModel) {
                    return !this.hasValueChange && this.filter_form.name === this.filter_form_copy.name
                }
                if (!this.hasModel) {
                    return !this.hasValueChange || !this.filter_form.name === this.filter_form_copy.name
                }

                return true
            },

            hasModel() {
                return !!(this.model && this.model.id)
            }
        },

        methods: {
            validateForm() {
                this.$refs.filter_form.validate(valid => {
                    if (valid) {
                        this.errors.clearAll()
                        if (this.hasModel) {
                            this.updateFilter()

                            return
                        }

                        this.saveFilter()
                    }
                })
            },

            saveFilter() {
                this.loading = true

                let post_data = cloneDeep(this.filter_form)

                this.$APIV2.Filter.createFilter(post_data)
                .then(res => {
                    if (res.data.filter) {
                        VueEvent.fire('inbox-filter-created', {
                            data: res.data.filter,
                            message: res.data.message
                        })

                        setTimeout(_ => {
                            this.closeDialog()
                        }, 300)
                    }
                    this.loading = false
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.status === 422) {
                        this.notification('error', 'Validation error. Please check the form.')

                        this.errors = new ErrorBag(err.response.data.errors)
                    }

                    console.log(err)
                    this.loading = false
                })
            },

            updateFilter() {
                this.loading = true

                let post_data = cloneDeep(this.filter_form)

                this.$APIV2.Filter.updateFilter(this.model.id, post_data)
                    .then(res => {
                        if (res.data.filter) {
                            VueEvent.fire('inbox-filter-updated', {
                                data: res.data.filter,
                                message: res.data.message
                            })

                            setTimeout(_ => {
                                this.closeDialog()
                            }, 300)
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        if (err.response.status === 422) {
                            this.notification('error', 'Validation error. Please check the form.')

                            this.errors = new ErrorBag(err.response.data.errors)
                        }

                        console.log(err)
                        this.loading = false
                    })
            },

            addContactOwner(id) {
                this.filter_form.filter.contact_owner = id
                this.filter_form.filter.my_contact = false
            },

            addUser(ids) {
                this.filter_form.filter.users = cloneDeep(ids)
            },

            addWorkflow(workflow_ids) {
                this.filter_form.filter.workflows = cloneDeep(workflow_ids)
            },

            addBroadcast(broadcast_ids) {
                this.filter_form.filter.broadcasts = cloneDeep(broadcast_ids)
            },

            addRingGroup(ring_group_ids) {
                this.filter_form.filter.ring_groups = !ring_group_ids ? [] : ring_group_ids
            },

            addCampaign(campaign_ids) {
                this.filter_form.filter.campaigns = cloneDeep(campaign_ids)
            },

            addCallDisposition(call_disposition_ids) {
                this.filter_form.filter.call_dispositions = cloneDeep(call_disposition_ids)
            },

            addTag(tag_ids) {
                this.filter_form.filter.tags = cloneDeep(tag_ids)
            },

            directionSelectorCleared() {
                this.filter_form.filter.direction = 'all'
            },

            answerStatusSelectorCleared() {
                this.filter_form.filter.answer_status = 'all'
            },

            talkTimeSelectorCleared() {
                this.filter_form.filter.min_talk_time = '0'
            },

            closeDialog() {
                // reset the form
                this.resetForm()

                // update the parent variable
                this.$emit('update:visible', false)

                // fire an event where the parent could utilize
                this.$emit('closed')
            },

            resetForm() {
                this.filter_form = this.getDefaultValues()
                this.filter_form_copy = this.getDefaultValues()

                this.errors.clearAll()

                if (this.$refs.filter_form) {
                    this.$refs.filter_form.clearValidate()
                }

                this.range_type = "all_time"
                this.time_options[1].label = 'Custom Range'
                this.hidden_date_value = []
            },

            getDefaultValues() {
                return {
                    name: '',
                    type: null,
                    scope: 'company',
                    filter: {
                        campaigns: [],
                        ring_groups: [],
                        direction: 'all',
                        answer_status: 'all',
                        min_talk_time: '0',
                        transfer_type: '',
                        callback_status: '',
                        tags: [],
                        call_dispositions: [],
                        first_time_only: false,
                        untagged_only: false,
                        exclude_automated_communications: false,
                        creator_type: null,
                        incoming_numbers: [],
                        users: [],
                        workflows: [],
                        broadcasts: [],
                        my_contact: false,
                        contact_owner: [],
                        from_date: null,
                        to_date: null,
                    }
                }
            },

            initialize() {
                if (this.hasModel) {
                    this.filter_form.name = this.model.name
                    this.filter_form.type = this.model.type
                    this.filter_form.filter = {...this.filter_form.filter, ...this.model.filter}

                    if (this.model.filter.to_date && this.model.filter.from_date) {
                        this.range_type = 'custom_range'
                        let start = this.localizedMoment(this.model.filter.from_date).startOf('day')
                        let end = this.localizedMoment(this.model.filter.to_date).endOf('day')
                        this.time_options[1].label = `${start.format('MM/DD/YYYY')} - ${end.format('MM/DD/YYYY')}`
                        this.filter_form.filter.from_date = start.format('YYYY-MM-DD HH:mm:ss')
                        this.filter_form.filter.to_date = end.format('YYYY-MM-DD HH:mm:ss')
                        this.hidden_date_value = [this.filter_form.filter.from_date, this.filter_form.filter.to_date]
                    }

                    this.filter_form_copy = cloneDeep(this.filter_form)
                }
            },

            focusFilterName() {
                this.is_name_on_edit = true
            },

            editFilterName() {
                this.is_name_on_edit = true
                setTimeout(_ => {
                    if (this.$refs.filterName) {
                        this.$refs.filterName.focus()
                    }
                }, 300)
            },

            handleFilterNameBlur() {
                if (this.filter_form.name) {
                    this.is_name_on_edit = false
                }
            },

            handleMyContactsChange(val) {
                if (val) {
                    this.filter_form.filter.contact_owner = []
                }
            },

            showDatePicker(val) {
                if (this.$refs.timePicker) {
                    this.$nextTick(_ => {
                        val ? this.$refs.timePicker.focus() : this.$refs.timePicker.blur()
                    })
                }
            },

            dateTimePicked(datetime) {
                if (datetime.length <= 0) {
                    this.range_type = "all_time"
                    this.filter_form.filter.to_date = null
                    this.filter_form.filter.from_date = null
                } else {
                    let start = this.localizedMoment(datetime[0]).startOf('day')
                    let end = this.localizedMoment(datetime[1]).endOf('day')
                    this.range_type = "custom_range"
                    this.time_options[1].label = `${start.format('MM/DD/YYYY')} - ${end.format('MM/DD/YYYY')}`
                    this.filter_form.filter.from_date = start.format('YYYY-MM-DD HH:mm:ss')
                    this.filter_form.filter.to_date = end.format('YYYY-MM-DD HH:mm:ss')
                }
            },

            notification(type, message) {
                this.$notify({
                    offset: 75,
                    title: 'Filter',
                    message: message,
                    type: type,
                    dangerouslyUseHTMLString: true,
                    showClose: true,
                    duration: 5000
                })
            },
        },

        watch: {
            type() {
                this.filter_form.type = this.type
            },

            'filter_form.type'(value) {
                this.filter_form_copy.type = value
            },

            model() {
                if (this.hasModel) {
                    this.is_name_on_edit = false
                    this.initialize()
                }
            }
        }
    }
</script>
