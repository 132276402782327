var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-content-center" },
    [
      _c(
        "el-alert",
        {
          staticClass: "alert w-auto",
          class: `alert-${_vm.type} border-${_vm.type}`,
          attrs: { closable: _vm.dimisable, type: _vm.mappedTypeForElement },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }