var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-3" }, [
    _c(
      "div",
      { staticClass: "d-flex justify-content-center" },
      [_vm._t("filters")],
      2
    ),
    _vm._v(" "),
    _c("iframe", {
      staticClass: "border-0 w-100 insights-iframe",
      attrs: {
        frameborder: "0",
        allowTransparency: "true",
        src: _vm.urlWithQuery,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }