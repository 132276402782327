<template>
    <div>
        <el-table class="w-full"
                  row-key="id"
                  :data="chatbotsData"
                  :expand-row-keys="expandableChatbotsId"
                  stripe>
            <el-table-column type="expand">
                <template v-slot="scope">
                    <div class="row align-items-top">
                        <div class="col-md-6">
                            <span class="text-greyish break-word">
                                <span>{{ scope.row.description ?? '-' }}</span>
                            </span>
                        </div>
                        <div class="col-md-6 b-l">
                            <p>
                                <b>Created By:</b> <span>{{ scope.row.created_by ? (scope.row.created_by.name + ' - #' + scope.row.created_by.id) : '-' }}</span>
                            </p>
                            <p>
                                <b>Last Modified By:</b> <span>{{ scope.row.modified_by ? (scope.row.modified_by.name + ' - #' + scope.row.modified_by.id) : '-' }}</span>
                            </p>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                label="Name"
                align="left"
                :min-width="150">
                <template v-slot="scope">
                    <div class="d-flex">
                        <el-switch
                            class="mr-4 align-self-center"
                            v-model="scope.row.enabled"
                            active-color="#00BF50"
                            @change="updateChatbotEnabled(scope.row)">
                        </el-switch>
                        <div>
                            <router-link :to="{
                                    name: 'AloAi: Edit chatbot',
                                    params: {bot_id: scope.row.id}
                                }">
                                <div class="hover-lower-opacity">
                                    <p class="mb-0">
                                        <b>{{ scope.row.name }}</b>
                                    </p>
                                    <span>#{{ scope.row.id }}</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                width="100"
                label="Type"
                align="center">
                <template v-slot="scope">
                    <span class="ml-2 d-inline-block label blue"
                          v-if="scope.row.is_assistant">
                        Assistant
                    </span>
                    <span class="ml-2 d-inline-block label deep-orange"
                          v-else>
                        Chat
                    </span>
                </template>
            </el-table-column>

            <el-table-column
                width="150"
                label="Model"
                align="left">
                <template v-slot="scope">
                    <span>{{ scope.row.model_text }}</span>
                </template>
            </el-table-column>

            <el-table-column
                width="150"
                label="Use Case"
                align="left">
                <template v-slot="scope">
                    <span>{{ scope.row.use_case_text }}</span>
                </template>
            </el-table-column>

            <el-table-column
                min-width="100"
                label="Lines"
                align="left">
                <template v-slot="scope">
                    <a>
                        <!-- One line -->
                        <span class="text-dark-greenish"
                              v-if="scope.row.campaigns.length === 1">
                            <span class="hover-lower-opacity">
                                <span>{{ scope.row.campaigns[0]?.name }}</span>
                                <br/>
                                <!-- One phone number -->
                                <span v-if="scope.row.campaigns[0].incoming_numbers.length === 1">
                                    {{ scope.row.campaigns[0]?.incoming_numbers[0]?.phone_number | fixPhone }}
                                </span>
                                <!-- Multiple phone numbers -->
                                <span v-else>
                                    {{ scope.row.campaigns[0]?.incoming_numbers.length }} Numbers
                                </span>
                            </span>
                        </span>
                        <!-- Multiple lines -->
                        <span class="text-dark-greenish"
                              v-else>
                            <router-link :to="{
                                    name: 'AloAi: Edit chatbot',
                                    params: {bot_id: scope.row.id},
                                }">
                                <div class="hover-lower-opacity">
                                    <span>{{ scope.row.campaigns.length }} Lines</span>
                                </div>
                            </router-link>
                        </span>
                    </a>
                </template>
            </el-table-column>

            <el-table-column
                align="left"
                label="Status"
                :min-width="40">
                <template v-slot="scope">
                    <span v-if="scope.row.enabled" class="text-green">
                        <i class="fa fa-play-circle text-success mr-1"></i>
                        <span>Running</span>
                    </span>
                    <span v-else class="text-red">
                        <i class="fa fa-pause-circle text-warning mr-1"></i>
                        <span>Paused</span>
                    </span>
                </template>
            </el-table-column>

            <el-table-column
                align="right"
                :min-width="40">
                <template v-slot="scope">
                    <div class="d-flex">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Modify"
                            placement="top">
                            <div
                                class="hover-lower-opacity-warning ml-4 mr-4"
                                @click="handleEdit(scope.row)"
                                disabled="true">
                                <i style="font-size:17px" class="fa fa-pencil" aria-hidden="true"/>
                            </div>
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Delete"
                            placement="top">
                            <div
                                class="hover-lower-opacity-danger"
                                @click="handleDelete(scope.row)">
                                <i style="font-size:17px" class="fa fa-trash-o" aria-hidden="true"/>
                            </div>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import axios from 'axios'
import * as AloAi from '../../constants/aloai'

const NOT_BUILD = 1
const BUILD_SUCCESS = 2
const BUILD_IN_PROGRESS = 3
const BUILD_FAILED = 4

export default {
    props: {
        chatbots: {
            required: false,
            default: []
        }
    },

    data() {
        return {
            NOT_BUILD,
            BUILD_SUCCESS,
            BUILD_IN_PROGRESS,
            BUILD_FAILED,
            delete_chatbot_visible: false,
            chatbot: null,
            count: 0,
            export_disabled: false,
            delete_disabled: false,
            // Todo: implement both search and pagination
            search: '',
            filters: {
                page: 1,
                size: 5,
            }
        }
    },

    computed: {
        expandableChatbotsId() {
            // check if loaded
            if (this.chatbotsData) {
                return this.chatbotsData.map(item => {
                    return item.id
                })
            }
        },

        // Todo: implement search logic
        chatbotsData() {
            if (!this.chatbots) {
                return this.chatbots
            }
            return this.chatbots.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
        }
    },

    methods: {
        updateChatbotEnabled(chatbot) {
            this.setLoading(true)

            // Get only required parameters
            let payload = _.pick(chatbot, ['instructions', 'enabled', 'model', 'name', 'ttl', 'use_case'])

            let campaign_ids = []
            // We only need campaign ids in the request.
            if (chatbot) {
                chatbot.campaigns.map(campaign => campaign_ids.push(campaign.id))
            }
            if (payload.use_case === AloAi.USE_CASE_SALES) {
                payload.opener = chatbot.opener
            }
            payload.campaign_ids = campaign_ids

            axios.patch(`/api/v1/aloai/chat-bot/${chatbot.id}`, payload).then(res => {
                this.setLoading(false)
                this.$notify.success({
                    offset: 95,
                    title: 'Chatbots',
                    message: 'Chatbot updated successfully.',
                })
                this.$emit('chatbot-update', chatbot)
            }).catch(err => {
                console.log(err)
                this.setLoading(false)
                this.$emit('chatbot-update', chatbot)
                this.$root.handleErrors(err.response)
            })
        },

        handleEdit(row) {
            // Each row is a chatbot object
            this.chatbot = row
            // Goes to the Chatbot Settings page
            this.$router.push({
                name: 'AloAi: Edit chatbot',
                params: {
                    bot_id: this.chatbot.id
                }
            })
        },

        handleDelete(row) {
            this.$emit('chatbot-delete', row.id)
        },

        setLoading(loading) {
            this.export_disabled = loading
            this.delete_disabled = loading
        }
    },
}
</script>
